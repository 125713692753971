export const distributionWaterLineData ={
    "type": "FeatureCollection",
    "features": [
      {
        "id": 0,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8199731791547,
            21.01933487511,
            105.82109376006544,
            21.019423389950056
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8199731791547,
              21.019423389950056
            ],
            [
              105.82025773483221,
              21.019422707197066
            ],
            [
              105.82026884795378,
              21.019421746768693
            ],
            [
              105.82035084446798,
              21.019414658439878
            ],
            [
              105.82036095384464,
              21.019414501298535
            ],
            [
              105.82053881926656,
              21.019411743026176
            ],
            [
              105.82071982640352,
              21.01941161315393
            ],
            [
              105.82079163957245,
              21.01941156110615
            ],
            [
              105.82081048259047,
              21.019405072629716
            ],
            [
              105.8208116482179,
              21.019404671391946
            ],
            [
              105.82102229149992,
              21.01935428808773
            ],
            [
              105.82107796695922,
              21.019339164894028
            ],
            [
              105.82109376006544,
              21.01933487511
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 148, Nguyễn Phúc Lai",
          "maTaiSan": "04.O12.DOPP.1",
          "duongKinh": 100,
          "chieudaiQL": 117.65,
          "chatLieu": "G-CI",
          "from_latitude": 21.019423389950056,
          "to_latitude": 21.01933487511,
          "to_longitude": 105.82109376006544,
          "from_longitude": 105.8199731791547
        }
      },
      {
        "id": 1,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82411996316317,
            21.021052856527998,
            105.82450526592974,
            21.021771860919532
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82450526592974,
              21.021771860919532
            ],
            [
              105.82445505132875,
              21.021677850018257
            ],
            [
              105.82413401068561,
              21.021076804336587
            ],
            [
              105.82411996316317,
              21.021052856527998
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 217, La Thành",
          "maTaiSan": "04.O12.DOPP.2",
          "duongKinh": 100,
          "chieudaiQL": 80.62,
          "chatLieu": "G-CI",
          "from_latitude": 21.021771860919532,
          "to_latitude": 21.021052856527998,
          "to_longitude": 105.82411996316317,
          "from_longitude": 105.82450526592974
        }
      },
      {
        "id": 2,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81989185857158,
            21.02117882273978,
            105.82576020255603,
            21.023317299687626
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82576020255603,
              21.02117882273978
            ],
            [
              105.8256887747565,
              21.021211266512598
            ],
            [
              105.8256639908444,
              21.021221295204768
            ],
            [
              105.82538943390833,
              21.02137091299158
            ],
            [
              105.82537685573242,
              21.021377665123236
            ],
            [
              105.82523308684617,
              21.021443605402002
            ],
            [
              105.82516410164264,
              21.02147524498053
            ],
            [
              105.82487580250886,
              21.021603291858774
            ],
            [
              105.82480661685831,
              21.02163402001011
            ],
            [
              105.82471426153307,
              21.021679362669516
            ],
            [
              105.82453966142113,
              21.021767482442566
            ],
            [
              105.82447265801596,
              21.021801298849873
            ],
            [
              105.82424933308468,
              21.02191281231365
            ],
            [
              105.82423101438002,
              21.02192195953605
            ],
            [
              105.82406205888296,
              21.02200120618922
            ],
            [
              105.82403542107312,
              21.022013700405832
            ],
            [
              105.8239768650638,
              21.022040251697682
            ],
            [
              105.82392174298708,
              21.02206482001307
            ],
            [
              105.82390757021285,
              21.022071136279603
            ],
            [
              105.82369634136248,
              21.022168467604146
            ],
            [
              105.82367999558937,
              21.022175684052982
            ],
            [
              105.82367851388328,
              21.022176337938113
            ],
            [
              105.82353087019754,
              21.022241519707837
            ],
            [
              105.82352921671676,
              21.02224225029436
            ],
            [
              105.82352393669713,
              21.02224458089155
            ],
            [
              105.82341088612031,
              21.022291790675876
            ],
            [
              105.82337918936571,
              21.022305026444656
            ],
            [
              105.82321837214671,
              21.022369877915292
            ],
            [
              105.82321705809957,
              21.022370407243095
            ],
            [
              105.82319684447151,
              21.022378558238106
            ],
            [
              105.82312250033085,
              21.022409354945943
            ],
            [
              105.82302873172283,
              21.02244819761742
            ],
            [
              105.82300576963193,
              21.022457564986713
            ],
            [
              105.82286569148354,
              21.02251470609986
            ],
            [
              105.82284374475948,
              21.02252311201923
            ],
            [
              105.8227582530911,
              21.022555852049912
            ],
            [
              105.82253804328504,
              21.022640321025968
            ],
            [
              105.82243733575214,
              21.022677616290444
            ],
            [
              105.82240138535063,
              21.022690928449588
            ],
            [
              105.8221634310847,
              21.02277605539486
            ],
            [
              105.82204288781871,
              21.02281293147485
            ],
            [
              105.82195571694207,
              21.02278860437623
            ],
            [
              105.82195557913425,
              21.022788559880915
            ],
            [
              105.82174816782776,
              21.022841453349436
            ],
            [
              105.82173829990585,
              21.022843055477917
            ],
            [
              105.82172760155336,
              21.02284478987603
            ],
            [
              105.82152970818339,
              21.022922829878155
            ],
            [
              105.8214347283201,
              21.022966003992387
            ],
            [
              105.8214067138564,
              21.02297873748787
            ],
            [
              105.82129627000332,
              21.023028939243705
            ],
            [
              105.82122263614141,
              21.02307327919681
            ],
            [
              105.82119951231533,
              21.023081785430602
            ],
            [
              105.82116581156997,
              21.023094179632643
            ],
            [
              105.8210721560714,
              21.023125746119195
            ],
            [
              105.82106697818527,
              21.023127416793674
            ],
            [
              105.82102766577512,
              21.023140094575524
            ],
            [
              105.82093243264936,
              21.023173259183455
            ],
            [
              105.82092996120245,
              21.023174119241833
            ],
            [
              105.82076059271866,
              21.023196086598947
            ],
            [
              105.82039213992925,
              21.023249543251563
            ],
            [
              105.82011274166877,
              21.023297491780784
            ],
            [
              105.82008420178778,
              21.02330143288227
            ],
            [
              105.81996921357313,
              21.023317299687626
            ],
            [
              105.81996448808499,
              21.023316031759354
            ],
            [
              105.81989185857158,
              21.023296443177014
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_La Thành",
          "maTaiSan": "04.O12.DOPP.3",
          "duongKinh": 150,
          "chieudaiQL": 668.5,
          "chatLieu": "G-CI",
          "from_latitude": 21.02117882273978,
          "to_latitude": 21.023296443177014,
          "to_longitude": 105.81989185857158,
          "from_longitude": 105.82576020255603
        }
      },
      {
        "id": 3,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81801730929331,
            21.020601228687756,
            105.81909643459387,
            21.02208808984319
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81909643459387,
              21.02208808984319
            ],
            [
              105.81902459744563,
              21.022068668665028
            ],
            [
              105.81885926993691,
              21.021849436885105
            ],
            [
              105.8186523317031,
              21.021539480989052
            ],
            [
              105.8184658156985,
              21.02126011456967
            ],
            [
              105.81839080355032,
              21.02118852175073
            ],
            [
              105.81823569105657,
              21.020971018594057
            ],
            [
              105.8182068604058,
              21.020930591558432
            ],
            [
              105.81818175998409,
              21.020895395225416
            ],
            [
              105.81801730929331,
              21.02064191968791
            ],
            [
              105.81802551611665,
              21.020601228687756
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D200_Láng Hạ",
          "maTaiSan": "04.O12.DOPP.4",
          "duongKinh": 200,
          "chieudaiQL": 199.46,
          "chatLieu": "G-CI",
          "from_latitude": 21.02208808984319,
          "to_latitude": 21.020601228687756,
          "to_longitude": 105.81802551611665,
          "from_longitude": 105.81909643459387
        }
      },
      {
        "id": 4,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82101432836181,
            21.019139390223806,
            105.82230923799037,
            21.022843055477917
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82173829990585,
              21.022843055477917
            ],
            [
              105.82173910213518,
              21.022824261791005
            ],
            [
              105.8219368247214,
              21.022772671543226
            ],
            [
              105.82194018332355,
              21.022755531050798
            ],
            [
              105.82199250599005,
              21.022549756337593
            ],
            [
              105.8219977896277,
              21.02253895441283
            ],
            [
              105.82205995075137,
              21.022411862728926
            ],
            [
              105.8220638981272,
              21.02240175190601
            ],
            [
              105.82206979120589,
              21.02238665761319
            ],
            [
              105.82207469767424,
              21.022374089895813
            ],
            [
              105.82209940456276,
              21.022310805172804
            ],
            [
              105.82210843106569,
              21.0222876868283
            ],
            [
              105.82210924216822,
              21.0222856091009
            ],
            [
              105.8221211781864,
              21.022255035013384
            ],
            [
              105.82212223783466,
              21.02225232021344
            ],
            [
              105.82214871963296,
              21.02218449090328
            ],
            [
              105.8221519033351,
              21.022176337447643
            ],
            [
              105.82215191757126,
              21.022176302153014
            ],
            [
              105.82215343446278,
              21.02217241456176
            ],
            [
              105.82215530426404,
              21.022167624748256
            ],
            [
              105.82215879343136,
              21.02215868852546
            ],
            [
              105.82216013293285,
              21.02215525701753
            ],
            [
              105.8221622133461,
              21.022149929669645
            ],
            [
              105.82222186614894,
              21.021982461398864
            ],
            [
              105.82223304203848,
              21.02195361975294
            ],
            [
              105.82223371551156,
              21.021951881400035
            ],
            [
              105.82223438329214,
              21.02195015752627
            ],
            [
              105.82223562874545,
              21.021946943246657
            ],
            [
              105.82230060852535,
              21.021779244165074
            ],
            [
              105.82230306403179,
              21.02176215138619
            ],
            [
              105.82230360331302,
              21.021758396778036
            ],
            [
              105.82230407772417,
              21.021755092290064
            ],
            [
              105.82230500990488,
              21.02174860623333
            ],
            [
              105.82230923799037,
              21.02171917133306
            ],
            [
              105.82223326370384,
              21.021598796263124
            ],
            [
              105.82223158490726,
              21.02159613805524
            ],
            [
              105.8222133389404,
              21.02156926947493
            ],
            [
              105.8221364769992,
              21.021419084055882
            ],
            [
              105.82206235240994,
              21.021297744343528
            ],
            [
              105.82203152390798,
              21.02125210519068
            ],
            [
              105.82194097344099,
              21.021088583751993
            ],
            [
              105.82192699970432,
              21.02106083552339
            ],
            [
              105.82192539984824,
              21.02105765938195
            ],
            [
              105.82187604141687,
              21.020983322111093
            ],
            [
              105.82185139736616,
              21.02092992413124
            ],
            [
              105.82184718113741,
              21.020913618689278
            ],
            [
              105.82184598308973,
              21.020908983701652
            ],
            [
              105.82184473886035,
              21.020904171904807
            ],
            [
              105.82180517603757,
              21.020751151501642
            ],
            [
              105.82179812438103,
              21.02073773743578
            ],
            [
              105.82178171910815,
              21.020706527132027
            ],
            [
              105.8217051646476,
              21.020558922316983
            ],
            [
              105.82170197803171,
              21.020551620674894
            ],
            [
              105.82168905717099,
              21.02052201120618
            ],
            [
              105.82167173292156,
              21.020482311389134
            ],
            [
              105.82167085365629,
              21.02047932234462
            ],
            [
              105.8216687500778,
              21.020472169921735
            ],
            [
              105.82166727552666,
              21.02046833472008
            ],
            [
              105.82166281186,
              21.02045672453401
            ],
            [
              105.82164961711175,
              21.02044085626285
            ],
            [
              105.82164717447202,
              21.02043485076839
            ],
            [
              105.82161137064762,
              21.02034683811666
            ],
            [
              105.821604728033,
              21.020331172128643
            ],
            [
              105.82160387237161,
              21.020329154841153
            ],
            [
              105.82160334415923,
              21.02032790913376
            ],
            [
              105.82156703690796,
              21.02024228703221
            ],
            [
              105.82151498335209,
              21.020143690252883
            ],
            [
              105.82150859014631,
              21.020131579961003
            ],
            [
              105.82147690991785,
              21.02007156995608
            ],
            [
              105.82131783785267,
              21.019740983174067
            ],
            [
              105.82120602480695,
              21.01950823046096
            ],
            [
              105.82118335135148,
              21.0194746829919
            ],
            [
              105.82116446603315,
              21.019446741623767
            ],
            [
              105.82115888859036,
              21.01943848867129
            ],
            [
              105.82109912342285,
              21.01935006045845
            ],
            [
              105.82109376006544,
              21.01933487511
            ],
            [
              105.82109180292149,
              21.019329335133513
            ],
            [
              105.8210902219539,
              21.019324858245252
            ],
            [
              105.82108073032963,
              21.019297987911024
            ],
            [
              105.82103289112317,
              21.019162545164512
            ],
            [
              105.82101432836181,
              21.019139390223806
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Nguyễn Phúc Lai",
          "maTaiSan": "04.O12.DOPP.5",
          "duongKinh": 150,
          "chieudaiQL": 448.15,
          "chatLieu": "G-CI",
          "from_latitude": 21.022843055477917,
          "to_latitude": 21.019139390223806,
          "to_longitude": 105.82101432836181,
          "from_longitude": 105.82173829990585
        }
      },
      {
        "id": 5,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82137778607579,
            21.02154682839888,
            105.82205995075137,
            21.022411862728926
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82137778607579,
              21.02154682839888
            ],
            [
              105.82138310139479,
              21.021554452153577
            ],
            [
              105.82142394365304,
              21.02161303145934
            ],
            [
              105.82145509234866,
              21.021664984534723
            ],
            [
              105.82146812793326,
              21.021686726361693
            ],
            [
              105.82153837959709,
              21.021803901298696
            ],
            [
              105.8215909643394,
              21.02189117804602
            ],
            [
              105.821604121695,
              21.021913015014665
            ],
            [
              105.82160535073794,
              21.02191505488502
            ],
            [
              105.82170097155284,
              21.02207375947032
            ],
            [
              105.82172139169177,
              21.02210819837774
            ],
            [
              105.8217379056913,
              21.022136049958423
            ],
            [
              105.82186204827012,
              21.022345417687156
            ],
            [
              105.82196766462803,
              21.02237303286448
            ],
            [
              105.82202680934348,
              21.02238849748128
            ],
            [
              105.82203913390288,
              21.022400364112034
            ],
            [
              105.82204994401783,
              21.022410774835276
            ],
            [
              105.82205995075137,
              21.022411862728926
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 12, Nguyễn Phúc Lai",
          "maTaiSan": "04.O12.DOPP.6",
          "duongKinh": 100,
          "chieudaiQL": 124.52,
          "chatLieu": "G-CI",
          "from_latitude": 21.02154682839888,
          "to_latitude": 21.022411862728926,
          "to_longitude": 105.82205995075137,
          "from_longitude": 105.82137778607579
        }
      },
      {
        "id": 6,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82113497561184,
            21.020983322111093,
            105.82187604141687,
            21.02115565373031
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82187604141687,
              21.020983322111093
            ],
            [
              105.82186855267054,
              21.020985818683915
            ],
            [
              105.82180651579206,
              21.021006497918442
            ],
            [
              105.82169316409323,
              21.021041176650765
            ],
            [
              105.8216136744787,
              21.02106175406191
            ],
            [
              105.82152438361086,
              21.02108116122632
            ],
            [
              105.82152371295263,
              21.021081307175244
            ],
            [
              105.82152011826378,
              21.0210818718921
            ],
            [
              105.82151586037968,
              21.021082540070076
            ],
            [
              105.82134695583171,
              21.02112079177997
            ],
            [
              105.8213298039437,
              21.021124676281683
            ],
            [
              105.82132159021128,
              21.021126535944166
            ],
            [
              105.82125126143602,
              21.021136233851042
            ],
            [
              105.82123969569527,
              21.02113782879053
            ],
            [
              105.82113497561184,
              21.02115565373031
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 44, Nguyễn Phúc Lai",
          "maTaiSan": "04.O12.DOPP.7",
          "duongKinh": 100,
          "chieudaiQL": 79.91,
          "chatLieu": "G-CI",
          "from_latitude": 21.020983322111093,
          "to_latitude": 21.02115565373031,
          "to_longitude": 105.82113497561184,
          "from_longitude": 105.82187604141687
        }
      },
      {
        "id": 7,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82150859014631,
            21.01919566729095,
            105.82470582466307,
            21.020131579961003
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82470582466307,
              21.01919566729095
            ],
            [
              105.82469749106308,
              21.01919949221022
            ],
            [
              105.82464431217225,
              21.019228753508322
            ],
            [
              105.82460267362676,
              21.019251664266246
            ],
            [
              105.82443221028471,
              21.019358600598963
            ],
            [
              105.82436383698163,
              21.01940149287412
            ],
            [
              105.82435546549557,
              21.01940700428353
            ],
            [
              105.82407635358167,
              21.019590751424317
            ],
            [
              105.82396561642942,
              21.019681495750568
            ],
            [
              105.82368294636413,
              21.019855996017363
            ],
            [
              105.82357710802015,
              21.01992133301771
            ],
            [
              105.82337396573405,
              21.020026272348463
            ],
            [
              105.82334817497852,
              21.020029524155838
            ],
            [
              105.82334012311851,
              21.020030539517013
            ],
            [
              105.82333506090244,
              21.0200311773102
            ],
            [
              105.8233148312767,
              21.020033728388313
            ],
            [
              105.82312425187126,
              21.020026841427097
            ],
            [
              105.82304849156908,
              21.020024103630494
            ],
            [
              105.82302614737813,
              21.02002329587258
            ],
            [
              105.82295120337334,
              21.020023462470963
            ],
            [
              105.822919102928,
              21.020023533771088
            ],
            [
              105.82288908896307,
              21.020023600390797
            ],
            [
              105.8227280235498,
              21.020051581958153
            ],
            [
              105.82270867854943,
              21.020054942492603
            ],
            [
              105.8225289570688,
              21.02010106146674
            ],
            [
              105.82252581489132,
              21.020101866983833
            ],
            [
              105.82248847096513,
              21.020111450142743
            ],
            [
              105.82243701876111,
              21.020118918410443
            ],
            [
              105.82225102615777,
              21.0201245315267
            ],
            [
              105.82215235568665,
              21.02011847813955
            ],
            [
              105.82199109041329,
              21.020099867054597
            ],
            [
              105.82193716495223,
              21.02009364337441
            ],
            [
              105.82191273283463,
              21.020090823235737
            ],
            [
              105.82167904145045,
              21.02010651407375
            ],
            [
              105.82157347318922,
              21.020121438585313
            ],
            [
              105.82151972597346,
              21.020129839855734
            ],
            [
              105.82150859014631,
              21.020131579961003
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 34, Hoàng Cầu",
          "maTaiSan": "04.O12.DOPP.8",
          "duongKinh": 100,
          "chieudaiQL": 362.53,
          "chatLieu": "G-CI",
          "from_latitude": 21.01919566729095,
          "to_latitude": 21.020131579961003,
          "to_longitude": 105.82150859014631,
          "from_longitude": 105.82470582466307
        }
      },
      {
        "id": 8,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82223326370384,
            21.020513975198583,
            105.82413386973045,
            21.021598796263124
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82413386973045,
              21.020513975198583
            ],
            [
              105.82408274830192,
              21.020590218496185
            ],
            [
              105.82407160477844,
              21.020604911968686
            ],
            [
              105.82406621256608,
              21.02061202209286
            ],
            [
              105.82398533599905,
              21.020718663968456
            ],
            [
              105.82392211696789,
              21.02078183020984
            ],
            [
              105.82392126426825,
              21.02078315937257
            ],
            [
              105.82391147991245,
              21.020798365606307
            ],
            [
              105.82390724014451,
              21.02080495349824
            ],
            [
              105.8238367608351,
              21.02091448313784
            ],
            [
              105.82371382132361,
              21.021019661258606
            ],
            [
              105.82367419557075,
              21.021047303990983
            ],
            [
              105.82363268482494,
              21.021076261906686
            ],
            [
              105.82358393126795,
              21.02109714572011
            ],
            [
              105.82357710073178,
              21.02109915234172
            ],
            [
              105.82347476466086,
              21.02112921943017
            ],
            [
              105.82346354428755,
              21.021132739429063
            ],
            [
              105.82322327118833,
              21.021208105204437
            ],
            [
              105.82320980962028,
              21.021211664937173
            ],
            [
              105.82308852102751,
              21.0212437437805
            ],
            [
              105.82299547488807,
              21.02125862485354
            ],
            [
              105.8229927654581,
              21.02125905796326
            ],
            [
              105.82298863598243,
              21.021259635234166
            ],
            [
              105.82298548120453,
              21.02126007682115
            ],
            [
              105.82291759766194,
              21.021269567584547
            ],
            [
              105.82287262957156,
              21.021276475332456
            ],
            [
              105.82284049110106,
              21.021281411584447
            ],
            [
              105.82283278594247,
              21.0212844394402
            ],
            [
              105.82282510382073,
              21.021287458151917
            ],
            [
              105.82273951097207,
              21.021321092841358
            ],
            [
              105.82273757842647,
              21.02132215625389
            ],
            [
              105.82255863318778,
              21.021420618005234
            ],
            [
              105.8225460325849,
              21.021427165007317
            ],
            [
              105.82240188622585,
              21.02150205572393
            ],
            [
              105.8222354830642,
              21.021597407211495
            ],
            [
              105.82223326370384,
              21.021598796263124
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 16, Hoàng Cầu",
          "maTaiSan": "04.O12.DOPP.9",
          "duongKinh": 100,
          "chieudaiQL": 237.54,
          "chatLieu": "G-DI",
          "from_latitude": 21.020513975198583,
          "to_latitude": 21.021598796263124,
          "to_longitude": 105.82223326370384,
          "from_longitude": 105.82413386973045
        }
      },
      {
        "id": 9,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8151215269022,
            21.013972552634733,
            105.81882612971003,
            21.016646367887695
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81882612971003,
              21.013972552634733
            ],
            [
              105.81882610092687,
              21.013972566321666
            ],
            [
              105.81882606830597,
              21.01397258183353
            ],
            [
              105.81882057715168,
              21.01397654994501
            ],
            [
              105.81882054836849,
              21.013976563631932
            ],
            [
              105.8188205157476,
              21.01397657914379
            ],
            [
              105.81881429056034,
              21.013981061111206
            ],
            [
              105.81881191362879,
              21.013985465848652
            ],
            [
              105.81881186664833,
              21.0139854940748
            ],
            [
              105.81876770792154,
              21.014016259157763
            ],
            [
              105.81870590297476,
              21.01408604727872
            ],
            [
              105.81870586366482,
              21.014086070951794
            ],
            [
              105.81780009770837,
              21.014743586137136
            ],
            [
              105.81779638436039,
              21.014746710185786
            ],
            [
              105.81779635941477,
              21.014746722047647
            ],
            [
              105.81609932109104,
              21.01594812048619
            ],
            [
              105.81609929328886,
              21.015948137780875
            ],
            [
              105.8160920144267,
              21.015953293095162
            ],
            [
              105.81560823247482,
              21.016295933046035
            ],
            [
              105.8155358491137,
              21.016348312289665
            ],
            [
              105.81552657781235,
              21.01635502074808
            ],
            [
              105.81515107130753,
              21.01662674641328
            ],
            [
              105.8151215269022,
              21.016646367887695
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D200_Thái Hà",
          "maTaiSan": "04.O12.DOPP.10",
          "duongKinh": 200,
          "chieudaiQL": 507.38,
          "chatLieu": "G-CI",
          "from_latitude": 21.013972552634733,
          "to_latitude": 21.016646367887695,
          "to_longitude": 105.8151215269022,
          "from_longitude": 105.81882612971003
        }
      },
      {
        "id": 10,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81666125259159,
            21.015374352966838,
            105.81834752221148,
            21.01695119584254
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81666125259159,
              21.01695119584254
            ],
            [
              105.81666363269524,
              21.016949120542066
            ],
            [
              105.81669327736601,
              21.016927447996682
            ],
            [
              105.81680352792269,
              21.01684685569809
            ],
            [
              105.81681592009171,
              21.016836853471048
            ],
            [
              105.81681594503773,
              21.01683684160931
            ],
            [
              105.81686751255843,
              21.016795407737202
            ],
            [
              105.81687702897915,
              21.01678814344829
            ],
            [
              105.81697128312247,
              21.016716558064573
            ],
            [
              105.8169781336193,
              21.016710993801563
            ],
            [
              105.81699893581163,
              21.01669405774201
            ],
            [
              105.81699896075762,
              21.016694045880264
            ],
            [
              105.81700447532504,
              21.01668958093714
            ],
            [
              105.81700456639764,
              21.016689523596437
            ],
            [
              105.81701591333469,
              21.016680306763593
            ],
            [
              105.81702311724702,
              21.016674459897803
            ],
            [
              105.81702315752966,
              21.016674438026865
            ],
            [
              105.81714673055995,
              21.016574049624506
            ],
            [
              105.81730230783909,
              21.01645256876676
            ],
            [
              105.81738805766325,
              21.016379160658477
            ],
            [
              105.8174648195167,
              21.01631379016285
            ],
            [
              105.81747283106797,
              21.01630770094594
            ],
            [
              105.81747286752733,
              21.016307683609426
            ],
            [
              105.81747294136373,
              21.016307640802943
            ],
            [
              105.8175488439308,
              21.016250175833296
            ],
            [
              105.81756135504516,
              21.01624118911325
            ],
            [
              105.8175613915045,
              21.016241171776716
            ],
            [
              105.81756147017998,
              21.01624113436631
            ],
            [
              105.81760920115103,
              21.01620695266328
            ],
            [
              105.8176618951896,
              21.016165261145083
            ],
            [
              105.81775577966899,
              21.016089531305823
            ],
            [
              105.817771779499,
              21.01607785695801
            ],
            [
              105.81777181595828,
              21.01607783962144
            ],
            [
              105.81777189463358,
              21.01607780221094
            ],
            [
              105.81778189091186,
              21.0160705265344
            ],
            [
              105.81783734915386,
              21.0160116630659
            ],
            [
              105.81783738561315,
              21.016011645729318
            ],
            [
              105.81783744695424,
              21.016011604789206
            ],
            [
              105.81784767854846,
              21.0160005597131
            ],
            [
              105.81788957352417,
              21.015956015261708
            ],
            [
              105.81794131032228,
              21.015853829188867
            ],
            [
              105.81794365693395,
              21.015851969000327
            ],
            [
              105.81794369339316,
              21.015851951663713
            ],
            [
              105.81795124682485,
              21.015846003724757
            ],
            [
              105.8179512832841,
              21.01584598638813
            ],
            [
              105.81795132261426,
              21.015845966328044
            ],
            [
              105.81805647277258,
              21.0157373895924
            ],
            [
              105.81810287626814,
              21.015683152556317
            ],
            [
              105.81824429264414,
              21.015517862890995
            ],
            [
              105.81834749351182,
              21.01537438200816
            ],
            [
              105.81834752221148,
              21.015374352966838
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D110_Ngõ 87, Láng Hạ",
          "maTaiSan": "04.O12.DOPP.11",
          "duongKinh": 110,
          "chieudaiQL": 250.21,
          "chatLieu": "HDPE",
          "from_latitude": 21.01695119584254,
          "to_latitude": 21.015374352966838,
          "to_longitude": 105.81834752221148,
          "from_longitude": 105.81666125259159
        }
      },
      {
        "id": 11,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8180695413808,
            21.01429406112407,
            105.81903025860285,
            21.015061106581083
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8180695413808,
              21.015061106581083
            ],
            [
              105.81810310329264,
              21.015036937412752
            ],
            [
              105.81815106266201,
              21.015001337304962
            ],
            [
              105.81816206307607,
              21.0149919387106
            ],
            [
              105.81818941239858,
              21.01496858159671
            ],
            [
              105.81819347111835,
              21.014965464910983
            ],
            [
              105.81825164348723,
              21.014918788156244
            ],
            [
              105.81829802382249,
              21.014882933666907
            ],
            [
              105.81833396292386,
              21.014850713393727
            ],
            [
              105.81833730710213,
              21.01484833536873
            ],
            [
              105.81834040406852,
              21.014846134662076
            ],
            [
              105.81837724093353,
              21.014819963492087
            ],
            [
              105.81844854790985,
              21.014766674190234
            ],
            [
              105.81848673287232,
              21.01473477095366
            ],
            [
              105.81864859153697,
              21.014598670678268
            ],
            [
              105.81865015171519,
              21.01459777529856
            ],
            [
              105.81865395196925,
              21.014595597395974
            ],
            [
              105.81865453613511,
              21.014595118584275
            ],
            [
              105.81879415501683,
              21.014481294000248
            ],
            [
              105.81879643499288,
              21.0144795677989
            ],
            [
              105.81882746105279,
              21.014456112504554
            ],
            [
              105.81885865220582,
              21.01443065485833
            ],
            [
              105.81892763306278,
              21.01437740004254
            ],
            [
              105.8189615096409,
              21.014349605448263
            ],
            [
              105.8189690615031,
              21.014343038763126
            ],
            [
              105.81896908644853,
              21.01434302690109
            ],
            [
              105.8190004533341,
              21.014315761025998
            ],
            [
              105.81901598161842,
              21.01430445464375
            ],
            [
              105.81901862029142,
              21.014302534325378
            ],
            [
              105.81903025860285,
              21.01429406112407
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 278, Thái Hà",
          "maTaiSan": "04.O12.DOPP.12",
          "duongKinh": 100,
          "chieudaiQL": 132.38,
          "chatLieu": "G-CI",
          "from_latitude": 21.015061106581083,
          "to_latitude": 21.01429406112407,
          "to_longitude": 105.81903025860285,
          "from_longitude": 105.8180695413808
        }
      },
      {
        "id": 12,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81512146549632,
            21.01664639708519,
            105.81802551611665,
            21.020601228687756
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81512146549632,
              21.01664639708519
            ],
            [
              105.81516218697396,
              21.016704162094342
            ],
            [
              105.81571875385347,
              21.017493666505263
            ],
            [
              105.81573808125555,
              21.017518755813512
            ],
            [
              105.81592611154385,
              21.01776284099584
            ],
            [
              105.81625958213135,
              21.01822551369643
            ],
            [
              105.81627206349977,
              21.018242830998233
            ],
            [
              105.81648382363899,
              21.018536638097856
            ],
            [
              105.81713546010616,
              21.019440746693892
            ],
            [
              105.81714090752894,
              21.0194483049242
            ],
            [
              105.81748045743628,
              21.0199194053524
            ],
            [
              105.8175509587017,
              21.020010566126654
            ],
            [
              105.81786490215104,
              21.02041650261219
            ],
            [
              105.81796974066698,
              21.02056536603085
            ],
            [
              105.8179745589822,
              21.020572207384614
            ],
            [
              105.81802155358008,
              21.02059290825047
            ],
            [
              105.81802551611665,
              21.020601228687756
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D200_Láng Hạ",
          "maTaiSan": "04.O12.DOPP.13",
          "duongKinh": 200,
          "chieudaiQL": 530.14,
          "chatLieu": "G-CI",
          "from_latitude": 21.01664639708519,
          "to_latitude": 21.020601228687756,
          "to_longitude": 105.81802551611665,
          "from_longitude": 105.81512146549632
        }
      },
      {
        "id": 13,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81772002187189,
            21.0190607478401,
            105.81932988845456,
            21.020684674467567
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81772002187189,
              21.020684674467567
            ],
            [
              105.8178300793116,
              21.020647834716524
            ],
            [
              105.81784888039483,
              21.020641540956664
            ],
            [
              105.81785221427351,
              21.02064042479646
            ],
            [
              105.81785324459,
              21.0206400802292
            ],
            [
              105.81796530393699,
              21.020625305631953
            ],
            [
              105.81802551611665,
              21.020601228687756
            ],
            [
              105.81805012546397,
              21.020591388904204
            ],
            [
              105.81848560971486,
              21.020380737082213
            ],
            [
              105.81849586087088,
              21.02037605239483
            ],
            [
              105.81854722316037,
              21.02035258146852
            ],
            [
              105.8185604109705,
              21.02030573446919
            ],
            [
              105.81922830410635,
              21.02002621930833
            ],
            [
              105.8192669601149,
              21.020010779437765
            ],
            [
              105.81926139832035,
              21.019991993818895
            ],
            [
              105.81922718877813,
              21.01988527253325
            ],
            [
              105.81921505383679,
              21.019839713508702
            ],
            [
              105.81914455912016,
              21.019575039937674
            ],
            [
              105.81911780733394,
              21.01944532448733
            ],
            [
              105.81916085412477,
              21.019357245870815
            ],
            [
              105.81917646136296,
              21.019330893133617
            ],
            [
              105.81926510147906,
              21.019181222465853
            ],
            [
              105.81926874410331,
              21.019174471366245
            ],
            [
              105.81929760609547,
              21.019120986878733
            ],
            [
              105.81932988845456,
              21.0190607478401
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D200_Ngõ 29, Láng Hạ",
          "maTaiSan": "04.O12.DOPP.14",
          "duongKinh": 200,
          "chieudaiQL": 291,
          "chatLieu": "G-CI",
          "from_latitude": 21.020684674467567,
          "to_latitude": 21.0190607478401,
          "to_longitude": 105.81932988845456,
          "from_longitude": 105.81772002187189
        }
      },
      {
        "id": 14,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81722779655232,
            21.01724826450916,
            105.81932988845456,
            21.0190607478401
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81932988845456,
              21.0190607478401
            ],
            [
              105.81932545852784,
              21.01905844156155
            ],
            [
              105.81892505728833,
              21.018849830158178
            ],
            [
              105.8188509039833,
              21.018810782949082
            ],
            [
              105.8188116094222,
              21.018790091296626
            ],
            [
              105.81854306143231,
              21.018612154593818
            ],
            [
              105.81839109840764,
              21.018476504790915
            ],
            [
              105.81834760808304,
              21.01843768196092
            ],
            [
              105.81801255645979,
              21.018138591984982
            ],
            [
              105.81792232341455,
              21.018058043416424
            ],
            [
              105.81786106031389,
              21.018003355816944
            ],
            [
              105.81754911201797,
              21.017631398827586
            ],
            [
              105.81753847196407,
              21.017618711747346
            ],
            [
              105.81723218173846,
              21.0172534975168
            ],
            [
              105.81722779655232,
              21.01724826450916
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Mai Anh Tuấn",
          "maTaiSan": "04.O12.DOPP.15",
          "duongKinh": 100,
          "chieudaiQL": 298.87,
          "chatLieu": "G-CI",
          "from_latitude": 21.0190607478401,
          "to_latitude": 21.01724826450916,
          "to_longitude": 105.81722779655232,
          "from_longitude": 105.81932988845456
        }
      },
      {
        "id": 15,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81722779655232,
            21.016684894311332,
            105.81826090205142,
            21.01724826450916
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81722779655232,
              21.01724826450916
            ],
            [
              105.81757983059539,
              21.016739103775105
            ],
            [
              105.81783624301538,
              21.016684894311332
            ],
            [
              105.81826090205142,
              21.01693829038074
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Đặng Tiến Đông",
          "maTaiSan": "04.O12.DOPP.16",
          "duongKinh": 100,
          "chieudaiQL": 146.84,
          "chatLieu": "G-CI",
          "from_latitude": 21.01724826450916,
          "to_latitude": 21.01693829038074,
          "to_longitude": 105.81826090205142,
          "from_longitude": 105.81722779655232
        }
      },
      {
        "id": 16,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81573808125555,
            21.016940549345,
            105.81666125259159,
            21.017518755813512
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81573808125555,
              21.017518755813512
            ],
            [
              105.81574403463215,
              21.01751501500063
            ],
            [
              105.81608708368697,
              21.01729944022407
            ],
            [
              105.81619983736158,
              21.017227774635412
            ],
            [
              105.81660778444163,
              21.016968478345568
            ],
            [
              105.81661159404896,
              21.016966058376827
            ],
            [
              105.81664999561997,
              21.016940549345
            ],
            [
              105.81666125259159,
              21.01695119584254
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D110_Ngõ 87, Láng Hạ",
          "maTaiSan": "04.O12.DOPP.17",
          "duongKinh": 110,
          "chieudaiQL": 115.21,
          "chatLieu": "HDPE",
          "from_latitude": 21.017518755813512,
          "to_latitude": 21.01695119584254,
          "to_longitude": 105.81666125259159,
          "from_longitude": 105.81573808125555
        }
      },
      {
        "id": 17,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81747590207897,
            21.01831552618832,
            105.81814739044259,
            21.019196504904112
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81747590207897,
              21.01831552618832
            ],
            [
              105.81748423226033,
              21.018326400754777
            ],
            [
              105.81749933690611,
              21.018344047678852
            ],
            [
              105.81754155770844,
              21.018393374140746
            ],
            [
              105.81754372222176,
              21.018396394065466
            ],
            [
              105.8176341945735,
              21.018522598911527
            ],
            [
              105.81764309559324,
              21.018534071371633
            ],
            [
              105.8177823480792,
              21.018713548834526
            ],
            [
              105.81782805362663,
              21.018785161851955
            ],
            [
              105.81783439757372,
              21.01879306710543
            ],
            [
              105.81783519856097,
              21.018794065836584
            ],
            [
              105.81784007613597,
              21.018800143685517
            ],
            [
              105.81785102646651,
              21.01881378847754
            ],
            [
              105.81785214342872,
              21.018815222851362
            ],
            [
              105.81785241757727,
              21.018815573791876
            ],
            [
              105.81811870998571,
              21.019157441888748
            ],
            [
              105.81814739044259,
              21.019196504904112
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 57, Láng Hạ",
          "maTaiSan": "04.O12.DOPP.18",
          "duongKinh": 100,
          "chieudaiQL": 119.94,
          "chatLieu": "G-CI",
          "from_latitude": 21.01831552618832,
          "to_latitude": 21.019196504904112,
          "to_longitude": 105.81814739044259,
          "from_longitude": 105.81747590207897
        }
      },
      {
        "id": 18,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81627206349977,
            21.01765994821666,
            105.8171597498784,
            21.018242830998233
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81627206349977,
              21.018242830998233
            ],
            [
              105.81628479860267,
              21.018233242646346
            ],
            [
              105.81651888825164,
              21.018056978896695
            ],
            [
              105.81652422265881,
              21.018053511995852
            ],
            [
              105.81684857516801,
              21.017842698731396
            ],
            [
              105.81707824919705,
              21.017715886364712
            ],
            [
              105.8171215876926,
              21.0176907446104
            ],
            [
              105.81712524692833,
              21.017687913480707
            ],
            [
              105.81714314365931,
              21.01767406775031
            ],
            [
              105.81714946320292,
              21.01766850512715
            ],
            [
              105.8171597498784,
              21.01765994821666
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 81, Láng Hạ",
          "maTaiSan": "04.O12.DOPP.19",
          "duongKinh": 100,
          "chieudaiQL": 112.35,
          "chatLieu": "G-CI",
          "from_latitude": 21.018242830998233,
          "to_latitude": 21.01765994821666,
          "to_longitude": 105.8171597498784,
          "from_longitude": 105.81627206349977
        }
      },
      {
        "id": 19,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81714090752894,
            21.019126935128874,
            105.81847982956023,
            21.0194483049242
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81714090752894,
              21.0194483049242
            ],
            [
              105.81715074047243,
              21.019445786459258
            ],
            [
              105.81774023408433,
              21.019294778310908
            ],
            [
              105.81814739044259,
              21.019196504904112
            ],
            [
              105.81816431601418,
              21.019192420395296
            ],
            [
              105.81817095923604,
              21.019190816828015
            ],
            [
              105.81824196821604,
              21.01917367796344
            ],
            [
              105.81827915679938,
              21.019164701644304
            ],
            [
              105.81840876449736,
              21.019140309711297
            ],
            [
              105.81841467257013,
              21.01913919741412
            ],
            [
              105.81847982956023,
              21.019126935128874
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 57, Láng Hạ",
          "maTaiSan": "04.O12.DOPP.20",
          "duongKinh": 100,
          "chieudaiQL": 145.44,
          "chatLieu": "G-CI",
          "from_latitude": 21.0194483049242,
          "to_latitude": 21.019126935128874,
          "to_longitude": 105.81847982956023,
          "from_longitude": 105.81714090752894
        }
      },
      {
        "id": 20,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81889761344145,
            21.01935083590807,
            105.81922830410635,
            21.02002621930833
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81922830410635,
              21.02002621930833
            ],
            [
              105.81922812181031,
              21.020025778508405
            ],
            [
              105.81921873658058,
              21.020002484329808
            ],
            [
              105.81910005557981,
              21.019716257880834
            ],
            [
              105.81909883606548,
              21.019713311104823
            ],
            [
              105.81908745291639,
              21.019685797629776
            ],
            [
              105.81903321372423,
              21.019676159431018
            ],
            [
              105.81902811096967,
              21.019663916373858
            ],
            [
              105.81902502397246,
              21.01965650942272
            ],
            [
              105.8189085265614,
              21.019377019401727
            ],
            [
              105.81890591720466,
              21.01937075995621
            ],
            [
              105.8189046441987,
              21.0193677050488
            ],
            [
              105.81890323765644,
              21.019364330138682
            ],
            [
              105.81890272104272,
              21.019363089785564
            ],
            [
              105.81889761344145,
              21.01935083590807
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 29, Láng Hạ",
          "maTaiSan": "04.O12.DOPP.21",
          "duongKinh": 100,
          "chieudaiQL": 84.85,
          "chatLieu": "G-CI",
          "from_latitude": 21.02002621930833,
          "to_latitude": 21.01935083590807,
          "to_longitude": 105.81889761344145,
          "from_longitude": 105.81922830410635
        }
      },
      {
        "id": 21,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81849586087088,
            21.02037605239483,
            105.8193420700552,
            21.02196105296922
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81849586087088,
              21.02037605239483
            ],
            [
              105.81851654186379,
              21.02041052207864
            ],
            [
              105.8185683642809,
              21.020496895762978
            ],
            [
              105.81869700828544,
              21.02074844490155
            ],
            [
              105.81870279138472,
              21.020757027527083
            ],
            [
              105.81872751820924,
              21.02079372764532
            ],
            [
              105.818738845715,
              21.020815978306068
            ],
            [
              105.81882721481273,
              21.02098956948792
            ],
            [
              105.81897346461744,
              21.021250770406944
            ],
            [
              105.81898277104021,
              21.021268257226716
            ],
            [
              105.81898430575033,
              21.021271141065686
            ],
            [
              105.8190379360454,
              21.02137190963278
            ],
            [
              105.81915164499648,
              21.021595987284048
            ],
            [
              105.81923377410331,
              21.021750981023363
            ],
            [
              105.81933132824176,
              21.02194021579434
            ],
            [
              105.8193420700552,
              21.02196105296922
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 5, Láng Hạ",
          "maTaiSan": "04.O12.DOPP.22",
          "duongKinh": 100,
          "chieudaiQL": 196.43,
          "chatLieu": "G-CI",
          "from_latitude": 21.02037605239483,
          "to_latitude": 21.02196105296922,
          "to_longitude": 105.8193420700552,
          "from_longitude": 105.81849586087088
        }
      },
      {
        "id": 22,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81909643459387,
            21.02196105296922,
            105.8193420700552,
            21.02208808984319
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81909643459387,
              21.02208808984319
            ],
            [
              105.81909982477089,
              21.022086885773977
            ],
            [
              105.81930183282556,
              21.021980681519494
            ],
            [
              105.8193420700552,
              21.02196105296922
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 5, Láng Hạ",
          "maTaiSan": "04.O12.DOPP.23",
          "duongKinh": 100,
          "chieudaiQL": 33.68,
          "chatLieu": "G-CI",
          "from_latitude": 21.02208808984319,
          "to_latitude": 21.02196105296922,
          "to_longitude": 105.8193420700552,
          "from_longitude": 105.81909643459387
        }
      },
      {
        "id": 23,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8193420700552,
            21.02164882030633,
            105.82008328893419,
            21.02196105296922
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8193420700552,
              21.02196105296922
            ],
            [
              105.81939981400849,
              21.02193449722762
            ],
            [
              105.8194046857393,
              21.021932257240074
            ],
            [
              105.81948280299868,
              21.021907029515116
            ],
            [
              105.81956701089855,
              21.02187395046599
            ],
            [
              105.81957063170785,
              21.021872527598713
            ],
            [
              105.81957306510259,
              21.021871572001704
            ],
            [
              105.81957399410196,
              21.02187117010564
            ],
            [
              105.81966133602353,
              21.021833327647812
            ],
            [
              105.81968921453209,
              21.021821249934447
            ],
            [
              105.81969254182945,
              21.021819807704915
            ],
            [
              105.81969555722748,
              21.021818501559185
            ],
            [
              105.8198161567206,
              21.021766250347042
            ],
            [
              105.81981690721011,
              21.0217659251811
            ],
            [
              105.81981976485545,
              21.02176462160005
            ],
            [
              105.81982725057445,
              21.021761205645678
            ],
            [
              105.82001384934328,
              21.021676053176925
            ],
            [
              105.82007637953396,
              21.02165207542837
            ],
            [
              105.82008328893419,
              21.02164882030633
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 5, Láng Hạ",
          "maTaiSan": "04.O12.DOPP.24",
          "duongKinh": 100,
          "chieudaiQL": 84.99,
          "chatLieu": "G-CI",
          "from_latitude": 21.02196105296922,
          "to_latitude": 21.02164882030633,
          "to_longitude": 105.82008328893419,
          "from_longitude": 105.8193420700552
        }
      },
      {
        "id": 24,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81966133602353,
            21.021833327647812,
            105.82014771309349,
            21.022526881964417
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81966133602353,
              21.021833327647812
            ],
            [
              105.81966404314635,
              21.021837044024217
            ],
            [
              105.81977973617062,
              21.021995866181022
            ],
            [
              105.81978516832218,
              21.022003580649866
            ],
            [
              105.81978676801626,
              21.022005853586432
            ],
            [
              105.81978841035732,
              21.022008185027143
            ],
            [
              105.81981917965061,
              21.022051884752507
            ],
            [
              105.81991348381011,
              21.02218580949532
            ],
            [
              105.81999628192904,
              21.022306375836262
            ],
            [
              105.82014771309349,
              21.022526881964417
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 5, Láng Hạ",
          "maTaiSan": "04.O12.DOPP.25",
          "duongKinh": 100,
          "chieudaiQL": 93.68,
          "chatLieu": "G-CI",
          "from_latitude": 21.021833327647812,
          "to_latitude": 21.022526881964417,
          "to_longitude": 105.82014771309349,
          "from_longitude": 105.81966133602353
        }
      },
      {
        "id": 25,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82013472817837,
            21.02149516559953,
            105.82106697818527,
            21.023127416793674
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82013472817837,
              21.02149516559953
            ],
            [
              105.82013600073601,
              21.02149830630446
            ],
            [
              105.82014344013253,
              21.021516676381513
            ],
            [
              105.82018568446371,
              21.021620981398094
            ],
            [
              105.82019014105087,
              21.021633245593662
            ],
            [
              105.82021912403812,
              21.02171300704611
            ],
            [
              105.82026521754555,
              21.021839855167254
            ],
            [
              105.82027639231778,
              21.021866837550622
            ],
            [
              105.82028008739685,
              21.02187670644633
            ],
            [
              105.82029314409253,
              21.021910423240445
            ],
            [
              105.82029433940949,
              21.021913511026007
            ],
            [
              105.82038664494264,
              21.022151883391935
            ],
            [
              105.82039743221968,
              21.022175550083883
            ],
            [
              105.82039922669478,
              21.022179487624147
            ],
            [
              105.82041446755208,
              21.022212927419318
            ],
            [
              105.82042380277203,
              21.022237041887653
            ],
            [
              105.82043470802684,
              21.02226521420364
            ],
            [
              105.82049583897994,
              21.02245294844922
            ],
            [
              105.82050546872333,
              21.022478668330365
            ],
            [
              105.82050798438951,
              21.02248518742362
            ],
            [
              105.82050908999993,
              21.02248805344632
            ],
            [
              105.82051016340607,
              21.022490835624318
            ],
            [
              105.82051429113572,
              21.022501533394365
            ],
            [
              105.8205638971044,
              21.022630092336254
            ],
            [
              105.82058995338147,
              21.022642392309447
            ],
            [
              105.82060116155748,
              21.022647683526483
            ],
            [
              105.82065795452958,
              21.02267252280935
            ],
            [
              105.8206925433033,
              21.022701636700802
            ],
            [
              105.82072441156814,
              21.02272846048562
            ],
            [
              105.82080626761204,
              21.02284335145771
            ],
            [
              105.82080988941566,
              21.022848434497263
            ],
            [
              105.82091283700474,
              21.02296312517213
            ],
            [
              105.82101302742042,
              21.023039115010132
            ],
            [
              105.82104867008394,
              21.023097456649456
            ],
            [
              105.82106486694063,
              21.023123961307203
            ],
            [
              105.82106697818527,
              21.023127416793674
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 371, La Thành",
          "maTaiSan": "04.O12.DOPP.26",
          "duongKinh": 100,
          "chieudaiQL": 207.03,
          "chatLieu": "G-CI",
          "from_latitude": 21.02149516559953,
          "to_latitude": 21.023127416793674,
          "to_longitude": 105.82106697818527,
          "from_longitude": 105.82013472817837
        }
      },
      {
        "id": 26,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82101187565458,
            21.018041411104043,
            105.82406751880589,
            21.019139390223806
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82101432836181,
              21.019139390223806
            ],
            [
              105.82101350821588,
              21.01913067894943
            ],
            [
              105.82101187565458,
              21.01911747985061
            ],
            [
              105.82108604425227,
              21.019089442485456
            ],
            [
              105.82110445494675,
              21.019081836063595
            ],
            [
              105.82111374283433,
              21.019077998582524
            ],
            [
              105.82152058103675,
              21.018909910144394
            ],
            [
              105.82152284709294,
              21.018908973393522
            ],
            [
              105.8216753448296,
              21.018845967513418
            ],
            [
              105.82181051926742,
              21.01884155619921
            ],
            [
              105.82181239763761,
              21.018841494737448
            ],
            [
              105.82187701533847,
              21.0188393869351
            ],
            [
              105.82225685637289,
              21.0187223222062
            ],
            [
              105.82228367087235,
              21.018714058133938
            ],
            [
              105.82243198756024,
              21.018673534134823
            ],
            [
              105.82246449824781,
              21.018664650881625
            ],
            [
              105.82246524792734,
              21.018664357321324
            ],
            [
              105.82284212884329,
              21.018522885994297
            ],
            [
              105.82290761676005,
              21.018498303131945
            ],
            [
              105.82293916017126,
              21.01848548278943
            ],
            [
              105.82334612862269,
              21.018320078349596
            ],
            [
              105.82361918994972,
              21.018219979687405
            ],
            [
              105.82364156423348,
              21.01821177754817
            ],
            [
              105.82406458945695,
              21.018042600391656
            ],
            [
              105.82406751880589,
              21.018041411104043
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Mai Anh Tuấn",
          "maTaiSan": "04.O12.DOPP.27",
          "duongKinh": 150,
          "chieudaiQL": 349.66,
          "chatLieu": "G-CI",
          "from_latitude": 21.019139390223806,
          "to_latitude": 21.018041411104043,
          "to_longitude": 105.82406751880589,
          "from_longitude": 105.82101432836181
        }
      },
      {
        "id": 27,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82116446603315,
            21.019127834746094,
            105.82172656389942,
            21.019446741623767
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82116446603315,
              21.019446741623767
            ],
            [
              105.82123604610567,
              21.019394460539665
            ],
            [
              105.82138016017781,
              21.019313223114143
            ],
            [
              105.82146618370375,
              21.019265107421923
            ],
            [
              105.8214672184871,
              21.019264527972755
            ],
            [
              105.82156098606113,
              21.019212079091556
            ],
            [
              105.82169960792942,
              21.019141549484633
            ],
            [
              105.8216996328754,
              21.019141537622193
            ],
            [
              105.82172656389942,
              21.019127834746094
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 91, Nguyễn Phúc Lai",
          "maTaiSan": "04.O12.DOPP.28",
          "duongKinh": 100,
          "chieudaiQL": 68.36,
          "chatLieu": "G-CI",
          "from_latitude": 21.019446741623767,
          "to_latitude": 21.019127834746094,
          "to_longitude": 105.82172656389942,
          "from_longitude": 105.82116446603315
        }
      },
      {
        "id": 28,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81932988845456,
            21.0190607478401,
            105.82101432836181,
            21.01918599668164
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82101432836181,
              21.019139390223806
            ],
            [
              105.82100261257254,
              21.019141488061436
            ],
            [
              105.82095737719614,
              21.019146391416243
            ],
            [
              105.82074041905204,
              21.019169909724692
            ],
            [
              105.82064397636954,
              21.019171976442493
            ],
            [
              105.82055822848096,
              21.019173813580714
            ],
            [
              105.82026128061246,
              21.019180174649428
            ],
            [
              105.82002039771547,
              21.01918599668164
            ],
            [
              105.81997602673513,
              21.01918362192511
            ],
            [
              105.81994554381808,
              21.0191819904085
            ],
            [
              105.81983769524682,
              21.019169299632917
            ],
            [
              105.81970469592919,
              21.019146880298695
            ],
            [
              105.81970337682715,
              21.019146658138784
            ],
            [
              105.81964127981041,
              21.019136190896244
            ],
            [
              105.81957327413276,
              21.019120916222732
            ],
            [
              105.81937447260024,
              21.019076263689914
            ],
            [
              105.81933594414167,
              21.01906271028751
            ],
            [
              105.81932988845456,
              21.0190607478401
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Mai Anh Tuấn",
          "maTaiSan": "04.O12.DOPP.29",
          "duongKinh": 150,
          "chieudaiQL": 179.64,
          "chatLieu": "G-CI",
          "from_latitude": 21.019139390223806,
          "to_latitude": 21.0190607478401,
          "to_longitude": 105.81932988845456,
          "from_longitude": 105.82101432836181
        }
      },
      {
        "id": 29,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81826090205142,
            21.015962682859364,
            105.81966253948774,
            21.01693829038074
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81826090205142,
              21.01693829038074
            ],
            [
              105.81827827306206,
              21.01678174709731
            ],
            [
              105.81831014409,
              21.01678488425273
            ],
            [
              105.81858830698842,
              21.016812302967484
            ],
            [
              105.8185889758697,
              21.01680707548297
            ],
            [
              105.8185987683675,
              21.016729678697004
            ],
            [
              105.81862399952902,
              21.01668866186971
            ],
            [
              105.8186343418438,
              21.016671644979755
            ],
            [
              105.81866287678628,
              21.016646048401665
            ],
            [
              105.81867219325126,
              21.01661150932256
            ],
            [
              105.81869004358602,
              21.016545331578502
            ],
            [
              105.81869050419158,
              21.016542657615894
            ],
            [
              105.81869548702474,
              21.016513747527934
            ],
            [
              105.81869640730801,
              21.016508405929706
            ],
            [
              105.81881732710387,
              21.01652345793532
            ],
            [
              105.81883294471217,
              21.01640054657969
            ],
            [
              105.8189116838397,
              21.016401274934708
            ],
            [
              105.81891381679945,
              21.01633138920704
            ],
            [
              105.81891382818038,
              21.016331007087157
            ],
            [
              105.81891407948761,
              21.01632277025208
            ],
            [
              105.81891527899099,
              21.016283473192694
            ],
            [
              105.81891545201317,
              21.016277811835092
            ],
            [
              105.81891553610456,
              21.0162750665229
            ],
            [
              105.81910324157914,
              21.016278321335708
            ],
            [
              105.8191331024183,
              21.016032286613566
            ],
            [
              105.81913737688954,
              21.015993462490123
            ],
            [
              105.81914001268237,
              21.015969520601647
            ],
            [
              105.81928109921795,
              21.01596643954804
            ],
            [
              105.81930256242586,
              21.01596633076688
            ],
            [
              105.81955534552391,
              21.01596514483149
            ],
            [
              105.81966253948774,
              21.015962682859364
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D110_Đặng Tiến Đông",
          "maTaiSan": "04.O12.DOPP.30",
          "duongKinh": 110,
          "chieudaiQL": 244.53,
          "chatLieu": "HDPE",
          "from_latitude": 21.01693829038074,
          "to_latitude": 21.015962682859364,
          "to_longitude": 105.81966253948774,
          "from_longitude": 105.81826090205142
        }
      },
      {
        "id": 30,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81826090205142,
            21.01538773411328,
            105.82010007958225,
            21.017123166213654
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81826090205142,
              21.01693829038074
            ],
            [
              105.81853714117706,
              21.017079369692038
            ],
            [
              105.8185522087711,
              21.017087056817584
            ],
            [
              105.81879673033013,
              21.017123166213654
            ],
            [
              105.8188180005711,
              21.017101072063447
            ],
            [
              105.81917875194812,
              21.016675792269755
            ],
            [
              105.8191787768939,
              21.016675780407677
            ],
            [
              105.8192812912319,
              21.016554924511517
            ],
            [
              105.81952434277004,
              21.016270189323016
            ],
            [
              105.81952436771576,
              21.01627017746091
            ],
            [
              105.81966724336179,
              21.016102796780764
            ],
            [
              105.81966406592312,
              21.016007539697572
            ],
            [
              105.81966256443339,
              21.015962670997215
            ],
            [
              105.81966151573371,
              21.015931304263333
            ],
            [
              105.81986879693574,
              21.01567439775748
            ],
            [
              105.81987239512631,
              21.015669920282043
            ],
            [
              105.81987242007189,
              21.01566990841989
            ],
            [
              105.81998735007114,
              21.015527443094715
            ],
            [
              105.81998737501672,
              21.01552743123254
            ],
            [
              105.81996983291913,
              21.015466864985193
            ],
            [
              105.81998746803818,
              21.015456289909647
            ],
            [
              105.82010007958225,
              21.01538773411328
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D110_Đặng Tiến Đông",
          "maTaiSan": "04.O12.DOPP.31",
          "duongKinh": 110,
          "chieudaiQL": 299.46,
          "chatLieu": "HDPE",
          "from_latitude": 21.01693829038074,
          "to_latitude": 21.01538773411328,
          "to_longitude": 105.82010007958225,
          "from_longitude": 105.81826090205142
        }
      },
      {
        "id": 31,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82406751880589,
            21.018041411104043,
            105.82553812592057,
            21.020632219056782
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82553812592057,
              21.020632219056782
            ],
            [
              105.82547871319474,
              21.02053951490061
            ],
            [
              105.82523725073133,
              21.02016274891741
            ],
            [
              105.82511091265313,
              21.019937911235985
            ],
            [
              105.8249348558324,
              21.019624588891794
            ],
            [
              105.82477575532626,
              21.019322638829806
            ],
            [
              105.82470582466307,
              21.01919566729095
            ],
            [
              105.82452554558827,
              21.018868337818777
            ],
            [
              105.82447785049631,
              21.018765591297143
            ],
            [
              105.82420654286432,
              21.018258910038615
            ],
            [
              105.82406751880589,
              21.018041411104043
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Hoàng Cầu",
          "maTaiSan": "04.O12.DOPP.32",
          "duongKinh": 150,
          "chieudaiQL": 330.96,
          "chatLieu": "G-DI",
          "from_latitude": 21.020632219056782,
          "to_latitude": 21.018041411104043,
          "to_longitude": 105.82406751880589,
          "from_longitude": 105.82553812592057
        }
      },
      {
        "id": 32,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81884800990248,
            21.014055044186712,
            105.82010007958225,
            21.01538773411328
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82010007958225,
              21.01538773411328
            ],
            [
              105.81998313522958,
              21.015264620712774
            ],
            [
              105.81961549712318,
              21.014879214855252
            ],
            [
              105.8195472813847,
              21.01480453677048
            ],
            [
              105.81912442483893,
              21.014372439665735
            ],
            [
              105.81884800990248,
              21.01408976754155
            ],
            [
              105.81889410936101,
              21.014055044186712
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Hoàng Cầu",
          "maTaiSan": "04.O12.DOPP.33",
          "duongKinh": 150,
          "chieudaiQL": 204.97,
          "chatLieu": "G-CI",
          "from_latitude": 21.01538773411328,
          "to_latitude": 21.014055044186712,
          "to_longitude": 105.81889410936101,
          "from_longitude": 105.82010007958225
        }
      },
      {
        "id": 33,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82010007958225,
            21.01538773411328,
            105.82406751880589,
            21.018041411104043
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82406751880589,
              21.018041411104043
            ],
            [
              105.8240547536176,
              21.018017240287254
            ],
            [
              105.82386719958684,
              21.017597321192884
            ],
            [
              105.82339492562063,
              21.016771477460242
            ],
            [
              105.82323317603415,
              21.016605629436967
            ],
            [
              105.8229483786441,
              21.016495858106335
            ],
            [
              105.82258815907409,
              21.016458623302604
            ],
            [
              105.82215164447568,
              21.016456391583954
            ],
            [
              105.82166639371444,
              21.016511719226983
            ],
            [
              105.82149678794987,
              21.01649547093706
            ],
            [
              105.82114602834548,
              21.016384604211208
            ],
            [
              105.82070520308685,
              21.016058135644677
            ],
            [
              105.82030394215725,
              21.015637519628104
            ],
            [
              105.82010007958225,
              21.01538773411328
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Hoàng Cầu",
          "maTaiSan": "04.O12.DOPP.34",
          "duongKinh": 150,
          "chieudaiQL": 554.33,
          "chatLieu": "G-CI",
          "from_latitude": 21.018041411104043,
          "to_latitude": 21.01538773411328,
          "to_longitude": 105.82010007958225,
          "from_longitude": 105.82406751880589
        }
      },
      {
        "id": 34,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81779635941477,
            21.014746722047647,
            105.8180695413808,
            21.015061106581083
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8180695413808,
              21.015061106581083
            ],
            [
              105.81806423067721,
              21.015055312622607
            ],
            [
              105.81793239003474,
              21.014911608050685
            ],
            [
              105.81791030280664,
              21.014890218324283
            ],
            [
              105.81790393579394,
              21.01488405008293
            ],
            [
              105.81789684098686,
              21.014877247661488
            ],
            [
              105.81779886671265,
              21.014750695035527
            ],
            [
              105.81779635941477,
              21.014746722047647
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 278, Thái Hà",
          "maTaiSan": "04.O12.DOPP.35",
          "duongKinh": 100,
          "chieudaiQL": 44.62,
          "chatLieu": "G-CI",
          "from_latitude": 21.015061106581083,
          "to_latitude": 21.014746722047647,
          "to_longitude": 105.81779635941477,
          "from_longitude": 105.8180695413808
        }
      },
      {
        "id": 35,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8160920144267,
            21.015953293095162,
            105.81642220150788,
            21.016459700346676
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8160920144267,
              21.015953293095162
            ],
            [
              105.81609426541627,
              21.015956130174153
            ],
            [
              105.81618240879615,
              21.01607026821151
            ],
            [
              105.81618496655037,
              21.016073440721392
            ],
            [
              105.81626729463999,
              21.016174763614632
            ],
            [
              105.81626734288335,
              21.016174790479983
            ],
            [
              105.81627239867127,
              21.01618164971992
            ],
            [
              105.81627237079037,
              21.016181652563372
            ],
            [
              105.81627233532718,
              21.016181676217453
            ],
            [
              105.81624875647978,
              21.016204889395283
            ],
            [
              105.81627595940425,
              21.016246626185897
            ],
            [
              105.81631162505127,
              21.0163117827991
            ],
            [
              105.81631782354475,
              21.016320039266798
            ],
            [
              105.8163447909251,
              21.016355960389188
            ],
            [
              105.81636377632198,
              21.016382024951263
            ],
            [
              105.81638736753519,
              21.01641445503426
            ],
            [
              105.8163886708497,
              21.016415669943633
            ],
            [
              105.81640245524495,
              21.016434529122392
            ],
            [
              105.81640725818433,
              21.016441235119153
            ],
            [
              105.81641527762979,
              21.016451254976836
            ],
            [
              105.81642220150788,
              21.016459700346676
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 364, Thái Hà",
          "maTaiSan": "04.O12.DOPP.36",
          "duongKinh": 100,
          "chieudaiQL": 69.96,
          "chatLieu": "G-DI",
          "from_latitude": 21.015953293095162,
          "to_latitude": 21.016459700346676,
          "to_longitude": 105.81642220150788,
          "from_longitude": 105.8160920144267
        }
      },
      {
        "id": 36,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81627239867127,
            21.01618164971992,
            105.81702311724702,
            21.016674459897803
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81627239867127,
              21.01618164971992
            ],
            [
              105.81629689229506,
              21.01618406123371
            ],
            [
              105.81630068893317,
              21.01618350288651
            ],
            [
              105.81630073978702,
              21.016183479158588
            ],
            [
              105.81630078871707,
              21.016183455439883
            ],
            [
              105.81632063804821,
              21.016183740456025
            ],
            [
              105.81635876814498,
              21.016189149372735
            ],
            [
              105.81638245446081,
              21.016192047071613
            ],
            [
              105.81639589853071,
              21.01619716256729
            ],
            [
              105.816436049998,
              21.01622277690338
            ],
            [
              105.81648104386376,
              21.016269059127133
            ],
            [
              105.81648430288514,
              21.016273115236054
            ],
            [
              105.81648813167207,
              21.016276695319153
            ],
            [
              105.81649263092545,
              21.016281157347393
            ],
            [
              105.81656533375467,
              21.016353258997235
            ],
            [
              105.8166480453567,
              21.016410635120913
            ],
            [
              105.81666521442813,
              21.016421286659355
            ],
            [
              105.8166652385597,
              21.016421301898383
            ],
            [
              105.81673817012219,
              21.01646542580161
            ],
            [
              105.81674789101245,
              21.016471335015115
            ],
            [
              105.816769226178,
              21.01648355712516
            ],
            [
              105.81681538788716,
              21.016509147027566
            ],
            [
              105.81688964282756,
              21.016559389294596
            ],
            [
              105.81694845710383,
              21.016609889934447
            ],
            [
              105.81701560030926,
              21.016667343339943
            ],
            [
              105.81702311724702,
              21.016674459897803
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 364, Thái Hà",
          "maTaiSan": "04.O12.DOPP.37",
          "duongKinh": 100,
          "chieudaiQL": 97.59,
          "chatLieu": "HDPE",
          "from_latitude": 21.01618164971992,
          "to_latitude": 21.016674459897803,
          "to_longitude": 105.81702311724702,
          "from_longitude": 105.81627239867127
        }
      },
      {
        "id": 37,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81618240879615,
            21.01488405008293,
            105.81790393579394,
            21.01607026821151
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81618240879615,
              21.01607026821151
            ],
            [
              105.81715868070434,
              21.01539755736558
            ],
            [
              105.81762549331371,
              21.015075907127976
            ],
            [
              105.81789569890634,
              21.01488972491714
            ],
            [
              105.81790389458449,
              21.0148840782811
            ],
            [
              105.81790393579394,
              21.01488405008293
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Thái Hà",
          "maTaiSan": "04.O12.DOPP.38",
          "duongKinh": 100,
          "chieudaiQL": 222.03,
          "chatLieu": "G-DI",
          "from_latitude": 21.01607026821151,
          "to_latitude": 21.01488405008293,
          "to_longitude": 105.81790393579394,
          "from_longitude": 105.81618240879615
        }
      },
      {
        "id": 38,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82574439829774,
            21.018617324456446,
            105.82907967745416,
            21.020490534110383
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82574439829774,
              21.020490534110383
            ],
            [
              105.82574793597799,
              21.020489230743262
            ],
            [
              105.82574796765248,
              21.020489217944345
            ],
            [
              105.82585868225752,
              21.02044163864241
            ],
            [
              105.82642199163408,
              21.020148654824343
            ],
            [
              105.82665924597909,
              21.020018923303724
            ],
            [
              105.82665927668151,
              21.02001890870291
            ],
            [
              105.82669158313101,
              21.02000124357305
            ],
            [
              105.82688765774921,
              21.019885220513192
            ],
            [
              105.82697750109581,
              21.01983439189615
            ],
            [
              105.82697753179816,
              21.019834377295272
            ],
            [
              105.8270272085312,
              21.019806275787182
            ],
            [
              105.82728117901956,
              21.019678850278154
            ],
            [
              105.82728120972185,
              21.019678835677215
            ],
            [
              105.82814209012484,
              21.01920809919107
            ],
            [
              105.82814212082697,
              21.01920808458999
            ],
            [
              105.82837236739802,
              21.019056279330083
            ],
            [
              105.82838169276653,
              21.019051252630323
            ],
            [
              105.82838172346857,
              21.01905123802921
            ],
            [
              105.82857018743228,
              21.018941842791975
            ],
            [
              105.8290243222417,
              21.018652583376667
            ],
            [
              105.82907967745416,
              21.018617324456446
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ô Chợ Dừa",
          "maTaiSan": "04.O12.DOPP.39",
          "duongKinh": 100,
          "chieudaiQL": 412.31,
          "chatLieu": "G-DI",
          "from_latitude": 21.020490534110383,
          "to_latitude": 21.018617324456446,
          "to_longitude": 105.82907967745416,
          "from_longitude": 105.82574439829774
        }
      },
      {
        "id": 39,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82564016967515,
            21.018933985006157,
            105.82939823667388,
            21.021089248165886
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82564016967515,
              21.021089248165886
            ],
            [
              105.82564820053085,
              21.02108685718423
            ],
            [
              105.82603685719111,
              21.020842592313638
            ],
            [
              105.82657015415383,
              21.020526558902194
            ],
            [
              105.82703116825392,
              21.02027536110641
            ],
            [
              105.8274803553429,
              21.020037065302894
            ],
            [
              105.82770617590539,
              21.019909481647996
            ],
            [
              105.8278046213616,
              21.01985386339156
            ],
            [
              105.82831235957072,
              21.019560863889026
            ],
            [
              105.82834756486143,
              21.01954054782161
            ],
            [
              105.82854283811251,
              21.019410761650406
            ],
            [
              105.82874567024062,
              21.019297709521734
            ],
            [
              105.82878687973687,
              21.019274740244583
            ],
            [
              105.82928207378784,
              21.019011813225116
            ],
            [
              105.82936180184822,
              21.018958396179247
            ],
            [
              105.82939823667388,
              21.018933985006157
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ô Chợ Dừa",
          "maTaiSan": "04.O12.DOPP.40",
          "duongKinh": 100,
          "chieudaiQL": 446.82,
          "chatLieu": "G-CI",
          "from_latitude": 21.021089248165886,
          "to_latitude": 21.018933985006157,
          "to_longitude": 105.82939823667388,
          "from_longitude": 105.82564016967515
        }
      },
      {
        "id": 40,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8190334946489,
            21.01382526872411,
            105.8215970665673,
            21.0163177067592
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8190334946489,
              21.01382526872411
            ],
            [
              105.81914110892788,
              21.013923484243612
            ],
            [
              105.81914300693325,
              21.013926148658715
            ],
            [
              105.8191447925195,
              21.013928660066384
            ],
            [
              105.81952738839402,
              21.014378591139042
            ],
            [
              105.82028861267855,
              21.015194780163064
            ],
            [
              105.8205865042456,
              21.01549371176023
            ],
            [
              105.82097026376547,
              21.015877203130774
            ],
            [
              105.82140343240897,
              21.016258028944723
            ],
            [
              105.8215970665673,
              21.0163177067592
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D200_Hoàng Cầu",
          "maTaiSan": "04.O12.DOPP.41",
          "duongKinh": 200,
          "chieudaiQL": 386.46,
          "chatLieu": "G-DI",
          "from_latitude": 21.01382526872411,
          "to_latitude": 21.0163177067592,
          "to_longitude": 105.8215970665673,
          "from_longitude": 105.8190334946489
        }
      },
      {
        "id": 41,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82912440674828,
            21.017313558184544,
            105.82941529321454,
            21.017843396533028
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82941529321454,
              21.017843396533028
            ],
            [
              105.82939613572489,
              21.017792042227008
            ],
            [
              105.82932915252738,
              21.01768094692273
            ],
            [
              105.82930565590762,
              21.01764197620208
            ],
            [
              105.8292870931812,
              21.017611189033975
            ],
            [
              105.82922499777054,
              21.017496605985603
            ],
            [
              105.82917692152049,
              21.017409793790474
            ],
            [
              105.82913780527579,
              21.017337663192013
            ],
            [
              105.82912440674828,
              21.017313558184544
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D200_Nguyễn Lương Bằng",
          "maTaiSan": "04.O12.DOPP.42",
          "duongKinh": 200,
          "chieudaiQL": 66.15,
          "chatLieu": "G-CI",
          "from_latitude": 21.017843396533028,
          "to_latitude": 21.017313558184544,
          "to_longitude": 105.82912440674828,
          "from_longitude": 105.82941529321454
        }
      },
      {
        "id": 42,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82882063347446,
            21.016774178939357,
            105.82912440674828,
            21.017313558184544
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82912440674828,
              21.017313558184544
            ],
            [
              105.8291157931798,
              21.017294365981638
            ],
            [
              105.82910079818792,
              21.01726110372513
            ],
            [
              105.829004530771,
              21.017096209468914
            ],
            [
              105.82893554676933,
              21.01697804876912
            ],
            [
              105.82882481662487,
              21.016781601136955
            ],
            [
              105.82882063347446,
              21.016774178939357
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Nguyễn Lương Bằng",
          "maTaiSan": "04.O12.DOPP.43",
          "duongKinh": 150,
          "chieudaiQL": 67.58,
          "chatLieu": "G-DI",
          "from_latitude": 21.017313558184544,
          "to_latitude": 21.016774178939357,
          "to_longitude": 105.82882063347446,
          "from_longitude": 105.82912440674828
        }
      },
      {
        "id": 43,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82640174388459,
            21.018093639790504,
            105.82861313933203,
            21.01889908089994
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82861313933203,
              21.01842482883831
            ],
            [
              105.8286041333728,
              21.01841470690541
            ],
            [
              105.82833692964157,
              21.018114378832404
            ],
            [
              105.82832403696374,
              21.018102257100228
            ],
            [
              105.82831487293578,
              21.018093639790504
            ],
            [
              105.82825711330541,
              21.018101287730968
            ],
            [
              105.82825420015331,
              21.018103313397685
            ],
            [
              105.82807128388691,
              21.018230529904624
            ],
            [
              105.82801252582478,
              21.01827357370609
            ],
            [
              105.8278886833205,
              21.01834633423827
            ],
            [
              105.82785906602116,
              21.018360337467083
            ],
            [
              105.8278420933261,
              21.01836836212663
            ],
            [
              105.82783689693657,
              21.018370818897612
            ],
            [
              105.82780531941255,
              21.0183768507565
            ],
            [
              105.82777198859105,
              21.018369700436608
            ],
            [
              105.82771840164287,
              21.0183614618344
            ],
            [
              105.82769239137248,
              21.01837105600145
            ],
            [
              105.82766483454026,
              21.018381221302032
            ],
            [
              105.82755292761226,
              21.01846616493873
            ],
            [
              105.82754398903509,
              21.01847294919475
            ],
            [
              105.82754246446493,
              21.01847401880456
            ],
            [
              105.82753510607483,
              21.018479178599275
            ],
            [
              105.82737644693077,
              21.018590450060362
            ],
            [
              105.82726065250702,
              21.018664947648837
            ],
            [
              105.82721029901667,
              21.018690441282832
            ],
            [
              105.82720414215339,
              21.018692996641075
            ],
            [
              105.82720247697293,
              21.018693688480226
            ],
            [
              105.82719544384828,
              21.018696607582974
            ],
            [
              105.82696707561972,
              21.018818469247584
            ],
            [
              105.82680641508439,
              21.01889908089994
            ],
            [
              105.8267933916309,
              21.018897951942098
            ],
            [
              105.82677359948529,
              21.01889623626853
            ],
            [
              105.826768566997,
              21.01889580008281
            ],
            [
              105.82676204564615,
              21.01889523474611
            ],
            [
              105.82675801600018,
              21.018894885815044
            ],
            [
              105.82675595738436,
              21.018894707046186
            ],
            [
              105.82675406820609,
              21.018894028873277
            ],
            [
              105.82672170836055,
              21.018882419825317
            ],
            [
              105.8265865107678,
              21.018649588491115
            ],
            [
              105.826584318765,
              21.018645802885025
            ],
            [
              105.82642491877326,
              21.01835710526933
            ],
            [
              105.82640174388459,
              21.018315132053353
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ Giếng",
          "maTaiSan": "04.O12.DOPP.44",
          "duongKinh": 100,
          "chieudaiQL": 314.43,
          "chatLieu": "G-DI",
          "from_latitude": 21.01842482883831,
          "to_latitude": 21.018315132053353,
          "to_longitude": 105.82640174388459,
          "from_longitude": 105.82861313933203
        }
      },
      {
        "id": 44,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82491602960522,
            21.018073085787375,
            105.82666940224101,
            21.019110333717357
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82491602960522,
              21.019110333717357
            ],
            [
              105.82491606606435,
              21.019110316379262
            ],
            [
              105.82491609676681,
              21.019110301778742
            ],
            [
              105.82504931581161,
              21.019046478656055
            ],
            [
              105.8252502856906,
              21.018942470115302
            ],
            [
              105.82553842824176,
              21.0187909730248
            ],
            [
              105.82579267521223,
              21.01865529833081
            ],
            [
              105.8259645945324,
              21.0185625839279
            ],
            [
              105.82611978701833,
              21.01847543587871
            ],
            [
              105.82622309321519,
              21.018417423965502
            ],
            [
              105.82626148346928,
              21.01839586627704
            ],
            [
              105.82640174388459,
              21.018315132053353
            ],
            [
              105.82640177169095,
              21.018315115660222
            ],
            [
              105.82640950149798,
              21.018310666754267
            ],
            [
              105.82646973661848,
              21.01827599554209
            ],
            [
              105.82659322979764,
              21.0182081512699
            ],
            [
              105.82663204519119,
              21.018144718795288
            ],
            [
              105.82665323145778,
              21.01810408900998
            ],
            [
              105.8266586343993,
              21.01809372804636
            ],
            [
              105.82666940224101,
              21.018073085787375
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Ngõ 31, Hoàng Cầu",
          "maTaiSan": "04.O12.DOPP.45",
          "duongKinh": 150,
          "chieudaiQL": 217.91,
          "chatLieu": "G-DI",
          "from_latitude": 21.019110333717357,
          "to_latitude": 21.018073085787375,
          "to_longitude": 105.82666940224101,
          "from_longitude": 105.82491602960522
        }
      },
      {
        "id": 45,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82666940224101,
            21.017208056089114,
            105.82912440674828,
            21.018073085787375
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82666940224101,
              21.018073085787375
            ],
            [
              105.82667929131247,
              21.018051479540265
            ],
            [
              105.82671206622292,
              21.0180352843959
            ],
            [
              105.82673443387782,
              21.01802068253342
            ],
            [
              105.82682107878527,
              21.017984011366522
            ],
            [
              105.82692421811964,
              21.017931439077266
            ],
            [
              105.82692755729491,
              21.01792973742642
            ],
            [
              105.82726537191006,
              21.017744685071566
            ],
            [
              105.82733080209599,
              21.017711059526217
            ],
            [
              105.82734722414786,
              21.017702620306217
            ],
            [
              105.82742585305544,
              21.017654142923973
            ],
            [
              105.82743571529544,
              21.017649493020322
            ],
            [
              105.82747870559469,
              21.01762922327152
            ],
            [
              105.82754661883058,
              21.017597579889465
            ],
            [
              105.82769662946512,
              21.017513255652602
            ],
            [
              105.82769855700107,
              21.0175121714358
            ],
            [
              105.82775480280051,
              21.01748055583698
            ],
            [
              105.82786815682431,
              21.01742405393468
            ],
            [
              105.82787971938465,
              21.017418290183354
            ],
            [
              105.82799498239389,
              21.017420264567928
            ],
            [
              105.82814835585282,
              21.0173744940142
            ],
            [
              105.82816517508823,
              21.0173672712349
            ],
            [
              105.82823412758025,
              21.01733765658932
            ],
            [
              105.82834234251646,
              21.0172907228786
            ],
            [
              105.82838692975328,
              21.0172713844844
            ],
            [
              105.82850926249183,
              21.01722346966529
            ],
            [
              105.82856379289821,
              21.017208056089114
            ],
            [
              105.82859211645001,
              21.017213968843915
            ],
            [
              105.8286184866829,
              21.017247811612922
            ],
            [
              105.82867121759978,
              21.017318985440337
            ],
            [
              105.82867936275494,
              21.01732862201768
            ],
            [
              105.82874000448997,
              21.017400262188914
            ],
            [
              105.82874828237665,
              21.01740884947132
            ],
            [
              105.82875920240909,
              21.017420162460958
            ],
            [
              105.8287604387942,
              21.01742143179191
            ],
            [
              105.82877277980319,
              21.01742616147396
            ],
            [
              105.8287790525811,
              21.017428233622983
            ],
            [
              105.8287806385544,
              21.01742835686408
            ],
            [
              105.82878205702835,
              21.01742845834056
            ],
            [
              105.82879049877178,
              21.017427007278762
            ],
            [
              105.82880608883339,
              21.01742261029487
            ],
            [
              105.82888283020833,
              21.01739701563358
            ],
            [
              105.82889502296204,
              21.01739336951697
            ],
            [
              105.82908513133586,
              21.017327475917064
            ],
            [
              105.82909990571018,
              21.017321872560075
            ],
            [
              105.82912440674828,
              21.017313558184544
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Ngõ 64, Nguyễn Lương Bằng",
          "maTaiSan": "04.O12.DOPP.46",
          "duongKinh": 150,
          "chieudaiQL": 291.99,
          "chatLieu": "G-DI",
          "from_latitude": 21.018073085787375,
          "to_latitude": 21.017313558184544,
          "to_longitude": 105.82912440674828,
          "from_longitude": 105.82666940224101
        }
      },
      {
        "id": 46,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8256563455252,
            21.017887238839652,
            105.82640950149798,
            21.018310666754267
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82640950149798,
              21.018310666754267
            ],
            [
              105.82635235399232,
              21.018219245282594
            ],
            [
              105.8263503285864,
              21.018215315250806
            ],
            [
              105.82631988720401,
              21.018179639383874
            ],
            [
              105.82631534092421,
              21.018172116822846
            ],
            [
              105.8262761515361,
              21.0181072803456
            ],
            [
              105.82618730231592,
              21.017973124018262
            ],
            [
              105.82613042221601,
              21.017887238839652
            ],
            [
              105.82606109506933,
              21.017924406339535
            ],
            [
              105.82588981609008,
              21.01801115642523
            ],
            [
              105.8258575200734,
              21.018027513475722
            ],
            [
              105.82568589516225,
              21.018125557170585
            ],
            [
              105.8256638506687,
              21.01813737539418
            ],
            [
              105.8256563455252,
              21.01814139866011
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 31, Hoàng Cầu",
          "maTaiSan": "04.O12.DOPP.47",
          "duongKinh": 100,
          "chieudaiQL": 113.03,
          "chatLieu": "G-DI",
          "from_latitude": 21.018310666754267,
          "to_latitude": 21.01814139866011,
          "to_longitude": 105.8256563455252,
          "from_longitude": 105.82640950149798
        }
      },
      {
        "id": 47,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82515498221576,
            21.016549887612552,
            105.82613268003756,
            21.01761224022326
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82515498221576,
              21.016549887612552
            ],
            [
              105.82517493280119,
              21.016582558983544
            ],
            [
              105.82522932639803,
              21.016673380070838
            ],
            [
              105.82579161666087,
              21.01761224022326
            ],
            [
              105.82605694059102,
              21.017494973344164
            ],
            [
              105.82607351024764,
              21.01748764991599
            ],
            [
              105.82613268003756,
              21.017461497806927
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Trần Quang Diệu",
          "maTaiSan": "04.O12.DOPP.48",
          "duongKinh": 100,
          "chieudaiQL": 172.27,
          "chatLieu": "G-CI",
          "from_latitude": 21.016549887612552,
          "to_latitude": 21.017461497806927,
          "to_longitude": 105.82613268003756,
          "from_longitude": 105.82515498221576
        }
      },
      {
        "id": 48,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82589802881596,
            21.015232919039853,
            105.82734722414786,
            21.017702620306217
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82589802881596,
              21.015232919039853
            ],
            [
              105.82605416462131,
              21.01549932534725
            ],
            [
              105.82605481620699,
              21.01550043766771
            ],
            [
              105.8260578575651,
              21.01550562548261
            ],
            [
              105.8260726983158,
              21.0155309485269
            ],
            [
              105.82607474628783,
              21.01553444309991
            ],
            [
              105.82634314807682,
              21.015935175465717
            ],
            [
              105.82649710399082,
              21.016170900274098
            ],
            [
              105.8265218915991,
              21.016213024628676
            ],
            [
              105.82653812731229,
              21.016240616074942
            ],
            [
              105.8267298742583,
              21.01656647420019
            ],
            [
              105.82675419122567,
              21.016609052415347
            ],
            [
              105.82689151270064,
              21.016849503843314
            ],
            [
              105.82690463138199,
              21.016872474141522
            ],
            [
              105.82696459199649,
              21.017042337766455
            ],
            [
              105.8269807417614,
              21.01707995538645
            ],
            [
              105.82708540783611,
              21.017257787565956
            ],
            [
              105.82712268244667,
              21.01732111845972
            ],
            [
              105.82719448078537,
              21.017443106756954
            ],
            [
              105.82719731138938,
              21.017447915314893
            ],
            [
              105.82733878862174,
              21.017688289205903
            ],
            [
              105.82734462172893,
              21.017698199028427
            ],
            [
              105.82734722414786,
              21.017702620306217
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngách 64/49, Nguyễn Lương Bằng",
          "maTaiSan": "04.O12.DOPP.49",
          "duongKinh": 100,
          "chieudaiQL": 312.32,
          "chatLieu": "G-DI",
          "from_latitude": 21.015232919039853,
          "to_latitude": 21.017702620306217,
          "to_longitude": 105.82734722414786,
          "from_longitude": 105.82589802881596
        }
      },
      {
        "id": 49,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82756537775397,
            21.016906320523088,
            105.82786815682431,
            21.01742405393468
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82786815682431,
              21.01742405393468
            ],
            [
              105.82784491512922,
              21.017384312210417
            ],
            [
              105.827776006833,
              21.01726648484715
            ],
            [
              105.82775100325513,
              21.017213735297155
            ],
            [
              105.82772134522196,
              21.017156350454805
            ],
            [
              105.82772038844267,
              21.017154498078227
            ],
            [
              105.82771424629367,
              21.017146808093784
            ],
            [
              105.82771154669591,
              21.017143428713318
            ],
            [
              105.82759318836752,
              21.01695387554773
            ],
            [
              105.82758762488169,
              21.01694436184019
            ],
            [
              105.82756973608626,
              21.01691377261058
            ],
            [
              105.82756537775397,
              21.016906320523088
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngách 64/33, Nguyễn Lương Bằng",
          "maTaiSan": "04.O12.DOPP.50",
          "duongKinh": 100,
          "chieudaiQL": 65.39,
          "chatLieu": "G-DI",
          "from_latitude": 21.01742405393468,
          "to_latitude": 21.016906320523088,
          "to_longitude": 105.82756537775397,
          "from_longitude": 105.82786815682431
        }
      },
      {
        "id": 50,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82778826250706,
            21.01505070782234,
            105.82882063347446,
            21.016774178939357
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82882063347446,
              21.016774178939357
            ],
            [
              105.82875570956526,
              21.016666707917913
            ],
            [
              105.82867740105874,
              21.016537957943605
            ],
            [
              105.82866607443447,
              21.016519549380085
            ],
            [
              105.82850767373313,
              21.01626209681939
            ],
            [
              105.82831076872827,
              21.015929510674383
            ],
            [
              105.8283005101448,
              21.015912182559806
            ],
            [
              105.82827265129359,
              21.01586512736464
            ],
            [
              105.82803062926823,
              21.015468353276695
            ],
            [
              105.82798452130888,
              21.015389687234983
            ],
            [
              105.82795430902593,
              21.015338141755745
            ],
            [
              105.82785209606304,
              21.01516375296498
            ],
            [
              105.8278054342466,
              21.015086311626188
            ],
            [
              105.82778826250706,
              21.01505070782234
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Nguyễn Lương Bằng",
          "maTaiSan": "04.O12.DOPP.51",
          "duongKinh": 150,
          "chieudaiQL": 216.86,
          "chatLieu": "G-DI",
          "from_latitude": 21.016774178939357,
          "to_latitude": 21.01505070782234,
          "to_longitude": 105.82778826250706,
          "from_longitude": 105.82882063347446
        }
      },
      {
        "id": 51,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82501090035127,
            21.010818253615433,
            105.82683509491314,
            21.013508969259277
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82683509491314,
              21.013508969259277
            ],
            [
              105.82677897947943,
              21.01338773274502
            ],
            [
              105.82675506573007,
              21.01334997306182
            ],
            [
              105.8265211941071,
              21.012980516505678
            ],
            [
              105.82643051115085,
              21.01281205910173
            ],
            [
              105.826417161755,
              21.01279077980111
            ],
            [
              105.826413066464,
              21.012784246785525
            ],
            [
              105.82630132049339,
              21.01260598682422
            ],
            [
              105.82626181648862,
              21.012542951918984
            ],
            [
              105.82622193768519,
              21.012475083852603
            ],
            [
              105.82615068346593,
              21.01235383298949
            ],
            [
              105.826133487624,
              21.012324550785852
            ],
            [
              105.82611524994756,
              21.012296475832834
            ],
            [
              105.82604945476353,
              21.012195138041328
            ],
            [
              105.82591748719047,
              21.01199186445946
            ],
            [
              105.82590499568792,
              21.01197455243502
            ],
            [
              105.82573052467835,
              21.01173252341416
            ],
            [
              105.82571546719691,
              21.01171213117874
            ],
            [
              105.82557209840144,
              21.011517842820048
            ],
            [
              105.82556051904703,
              21.01150213589123
            ],
            [
              105.82551771628201,
              21.011485598638345
            ],
            [
              105.82542921492302,
              21.011456978408948
            ],
            [
              105.82517889356235,
              21.011058539132808
            ],
            [
              105.82506777776325,
              21.010893259979262
            ],
            [
              105.82504339978337,
              21.010861361503817
            ],
            [
              105.82501445059725,
              21.010823473310786
            ],
            [
              105.82501090035127,
              21.010818253615433
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Tây Sơn",
          "maTaiSan": "04.O12.DOPP.52",
          "duongKinh": 150,
          "chieudaiQL": 359.69,
          "chatLieu": "G-DI",
          "from_latitude": 21.013508969259277,
          "to_latitude": 21.010818253615433,
          "to_longitude": 105.82501090035127,
          "from_longitude": 105.82683509491314
        }
      },
      {
        "id": 52,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82755176409093,
            21.015639823130044,
            105.82812334735802,
            21.016309691142027
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82755176409093,
              21.015639823130044
            ],
            [
              105.82764843714757,
              21.015782116722875
            ],
            [
              105.8277751188453,
              21.01571869187611
            ],
            [
              105.82779056721658,
              21.01571095735571
            ],
            [
              105.8278060294899,
              21.015727180828847
            ],
            [
              105.82786311178587,
              21.015814655060947
            ],
            [
              105.82786472319775,
              21.015817124767732
            ],
            [
              105.82792620996486,
              21.015912383219362
            ],
            [
              105.82792851524945,
              21.01591595509695
            ],
            [
              105.8279350346406,
              21.015932395496883
            ],
            [
              105.82793612917376,
              21.015935155847313
            ],
            [
              105.8279760798412,
              21.016002831603373
            ],
            [
              105.82801180157531,
              21.016057562126612
            ],
            [
              105.82801324321633,
              21.016059771625844
            ],
            [
              105.82806888241247,
              21.016152741704435
            ],
            [
              105.82807195142439,
              21.016157870636327
            ],
            [
              105.82805990293298,
              21.016164459602365
            ],
            [
              105.82803977593457,
              21.016176776402823
            ],
            [
              105.82806076509657,
              21.01621015862328
            ],
            [
              105.828083764098,
              21.016246737508833
            ],
            [
              105.8281137077497,
              21.01629436155202
            ],
            [
              105.82811551863283,
              21.016297241252733
            ],
            [
              105.82811621349352,
              21.016298347934633
            ],
            [
              105.82811737416316,
              21.01630019299413
            ],
            [
              105.8281199138685,
              21.016304232501675
            ],
            [
              105.82812334735802,
              21.016309691142027
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 180, Nguyễn Lương Bằng",
          "maTaiSan": "04.O12.DOPP.53",
          "duongKinh": 100,
          "chieudaiQL": 115.83,
          "chatLieu": "G-DI",
          "from_latitude": 21.015639823130044,
          "to_latitude": 21.016309691142027,
          "to_longitude": 105.82812334735802,
          "from_longitude": 105.82755176409093
        }
      },
      {
        "id": 53,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82741774008888,
            21.01528197835301,
            105.82756147143223,
            21.01574894027963
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82741774008888,
              21.01528197835301
            ],
            [
              105.82743272701792,
              21.015306909465696
            ],
            [
              105.82753931496285,
              21.01552200820353
            ],
            [
              105.82754134610177,
              21.015526107097866
            ],
            [
              105.82756147143223,
              21.015569636815577
            ],
            [
              105.82755176409093,
              21.015639823130044
            ],
            [
              105.82753667208435,
              21.01574894027963
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 180, Nguyễn Lương Bằng",
          "maTaiSan": "04.O12.DOPP.54",
          "duongKinh": 100,
          "chieudaiQL": 55.93,
          "chatLieu": "G-DI",
          "from_latitude": 21.01528197835301,
          "to_latitude": 21.01574894027963,
          "to_longitude": 105.82753667208435,
          "from_longitude": 105.82741774008888
        }
      },
      {
        "id": 54,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82741774008888,
            21.01505070782234,
            105.82778826250706,
            21.01528197835301
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82778826250706,
              21.01505070782234
            ],
            [
              105.82778584510268,
              21.01505261364883
            ],
            [
              105.8276824454048,
              21.015134121615862
            ],
            [
              105.82761647184896,
              21.015169646689912
            ],
            [
              105.82760038127084,
              21.01517831102733
            ],
            [
              105.82755802236399,
              21.01520235410822
            ],
            [
              105.82754388770024,
              21.015210376668378
            ],
            [
              105.82753837767528,
              21.015213504244077
            ],
            [
              105.8274196454899,
              21.015280896957176
            ],
            [
              105.82741774008888,
              21.01528197835301
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 180, Nguyễn Lương Bằng",
          "maTaiSan": "04.O12.DOPP.55",
          "duongKinh": 100,
          "chieudaiQL": 46.28,
          "chatLieu": "G-DI",
          "from_latitude": 21.01505070782234,
          "to_latitude": 21.01528197835301,
          "to_longitude": 105.82741774008888,
          "from_longitude": 105.82778826250706
        }
      },
      {
        "id": 55,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82716019456383,
            21.014843832979583,
            105.82741774008888,
            21.01528197835301
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82716019456383,
              21.014843832979583
            ],
            [
              105.82717313266465,
              21.01488693795325
            ],
            [
              105.82717573133911,
              21.01489121383544
            ],
            [
              105.82729384954095,
              21.015085599016988
            ],
            [
              105.82741774008888,
              21.01528197835301
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 180, Nguyễn Lương Bằng",
          "maTaiSan": "04.O12.DOPP.56",
          "duongKinh": 100,
          "chieudaiQL": 55.25,
          "chatLieu": "G-DI",
          "from_latitude": 21.014843832979583,
          "to_latitude": 21.01528197835301,
          "to_longitude": 105.82741774008888,
          "from_longitude": 105.82716019456383
        }
      },
      {
        "id": 56,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82659778649023,
            21.014843832979583,
            105.82716019456383,
            21.01514346308523
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82659778649023,
              21.01514346308523
            ],
            [
              105.82661001525955,
              21.015136948314378
            ],
            [
              105.82661765272317,
              21.015132879205737
            ],
            [
              105.82715735750524,
              21.01484534522462
            ],
            [
              105.82716019456383,
              21.014843832979583
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 180, Nguyễn Lương Bằng",
          "maTaiSan": "04.O12.DOPP.57",
          "duongKinh": 100,
          "chieudaiQL": 95.62,
          "chatLieu": "G-DI",
          "from_latitude": 21.01514346308523,
          "to_latitude": 21.014843832979583,
          "to_longitude": 105.82716019456383,
          "from_longitude": 105.82659778649023
        }
      },
      {
        "id": 57,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82618822200024,
            21.012784246785525,
            105.826413066464,
            21.013331967497162
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82636178128945,
              21.013331967497162
            ],
            [
              105.82631386389156,
              21.013254578760968
            ],
            [
              105.82629332002708,
              21.01322295541958
            ],
            [
              105.82633406159646,
              21.01319908490028
            ],
            [
              105.82631452630737,
              21.013174009569312
            ],
            [
              105.82625772142474,
              21.01310109520379
            ],
            [
              105.82622596802811,
              21.01306033686373
            ],
            [
              105.82620431137855,
              21.013021922135497
            ],
            [
              105.82618822200024,
              21.012993401408398
            ],
            [
              105.8262547051647,
              21.012956022808364
            ],
            [
              105.82625145923387,
              21.012950010438793
            ],
            [
              105.8262470675088,
              21.01294187563212
            ],
            [
              105.82621886955278,
              21.012897824924874
            ],
            [
              105.82622222000208,
              21.01289591820271
            ],
            [
              105.82632388152416,
              21.012842302160443
            ],
            [
              105.82636901016755,
              21.01281893058719
            ],
            [
              105.8263802473254,
              21.012807406843155
            ],
            [
              105.82638296168983,
              21.012805454433085
            ],
            [
              105.82640867674803,
              21.012786954301397
            ],
            [
              105.826413066464,
              21.012784246785525
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 90, Tây Sơn",
          "maTaiSan": "04.O12.DOPP.58",
          "duongKinh": 100,
          "chieudaiQL": 85.06,
          "chatLieu": "G-DI",
          "from_latitude": 21.013331967497162,
          "to_latitude": 21.012784246785525,
          "to_longitude": 105.826413066464,
          "from_longitude": 105.82636178128945
        }
      },
      {
        "id": 58,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82540871123943,
            21.013331967497162,
            105.82636178128945,
            21.01377756690689
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82636178128945,
              21.013331967497162
            ],
            [
              105.82634500130942,
              21.01334031532035
            ],
            [
              105.82633429750008,
              21.013345640340333
            ],
            [
              105.82628941977126,
              21.01336796655954
            ],
            [
              105.82628015010492,
              21.013372578288486
            ],
            [
              105.82620363384765,
              21.013410646009323
            ],
            [
              105.82617889745397,
              21.013422952454004
            ],
            [
              105.82616843825504,
              21.013428156146965
            ],
            [
              105.82613130176257,
              21.013445191572302
            ],
            [
              105.82610444439064,
              21.01345751191773
            ],
            [
              105.82602527144094,
              21.013488110133224
            ],
            [
              105.82597606991644,
              21.01350207713004
            ],
            [
              105.82597300712557,
              21.01350294645069
            ],
            [
              105.82595222552752,
              21.013510251468162
            ],
            [
              105.82588093064062,
              21.013543113697764
            ],
            [
              105.82587947317565,
              21.013543785546915
            ],
            [
              105.82587857804093,
              21.013544210795384
            ],
            [
              105.82587344036757,
              21.013546650960837
            ],
            [
              105.82586601415625,
              21.013550292686872
            ],
            [
              105.82584497103846,
              21.01355570037929
            ],
            [
              105.82566544456841,
              21.013635653657218
            ],
            [
              105.82566344289746,
              21.0136365449198
            ],
            [
              105.82566065917715,
              21.01363778500991
            ],
            [
              105.82565842912574,
              21.013638777638825
            ],
            [
              105.82565661169701,
              21.01363958762011
            ],
            [
              105.82559177587972,
              21.01367854316487
            ],
            [
              105.82554666075438,
              21.01370391602429
            ],
            [
              105.82545777109382,
              21.013753910042563
            ],
            [
              105.8254555490602,
              21.013754787017024
            ],
            [
              105.82543603453591,
              21.013762492277458
            ],
            [
              105.82543375299349,
              21.01376339302422
            ],
            [
              105.82540871123943,
              21.01377756690689
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 90, Tây Sơn",
          "maTaiSan": "04.O12.DOPP.59",
          "duongKinh": 100,
          "chieudaiQL": 110.99,
          "chatLieu": "G-DI",
          "from_latitude": 21.013331967497162,
          "to_latitude": 21.01377756690689,
          "to_longitude": 105.82540871123943,
          "from_longitude": 105.82636178128945
        }
      },
      {
        "id": 59,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82514699892214,
            21.01241086556501,
            105.82630132049339,
            21.012946295833213
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82630132049339,
              21.01260598682422
            ],
            [
              105.82629337330165,
              21.01260916322653
            ],
            [
              105.8260633393074,
              21.01270109146445
            ],
            [
              105.82606069119696,
              21.012702149353785
            ],
            [
              105.82605037998792,
              21.012706270254967
            ],
            [
              105.82603594280135,
              21.012712606929632
            ],
            [
              105.82591515497681,
              21.01276562106209
            ],
            [
              105.82579712209075,
              21.012817427674733
            ],
            [
              105.82579151129768,
              21.01281989000447
            ],
            [
              105.82578828895724,
              21.012821304741365
            ],
            [
              105.82568022529001,
              21.012868735532738
            ],
            [
              105.82566838859252,
              21.01287393052447
            ],
            [
              105.82553686992479,
              21.012924434014366
            ],
            [
              105.82552254905706,
              21.012929891242855
            ],
            [
              105.82547950486521,
              21.012946295833213
            ],
            [
              105.82545829070351,
              21.01291169047238
            ],
            [
              105.82545445670227,
              21.012905436165276
            ],
            [
              105.82536900701464,
              21.01278840958115
            ],
            [
              105.82536573491726,
              21.012783928289235
            ],
            [
              105.82535779728042,
              21.01277416046396
            ],
            [
              105.82535458387555,
              21.012770206371336
            ],
            [
              105.82535074386173,
              21.012765480349895
            ],
            [
              105.82528876676894,
              21.012663957546447
            ],
            [
              105.82527597047451,
              21.012642996113602
            ],
            [
              105.82523153898376,
              21.012568072203027
            ],
            [
              105.8252161663343,
              21.012542150095296
            ],
            [
              105.82514699892214,
              21.01241086556501
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 100, Tây Sơn",
          "maTaiSan": "04.O12.DOPP.60",
          "duongKinh": 100,
          "chieudaiQL": 161.8,
          "chatLieu": "G-DI",
          "from_latitude": 21.01260598682422,
          "to_latitude": 21.01241086556501,
          "to_longitude": 105.82514699892214,
          "from_longitude": 105.82630132049339
        }
      },
      {
        "id": 60,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82514699892214,
            21.01197455243502,
            105.82590499568792,
            21.01241086556501
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82590499568792,
              21.01197455243502
            ],
            [
              105.82589740321039,
              21.01197908012981
            ],
            [
              105.82585973419421,
              21.012001542852264
            ],
            [
              105.82571437699532,
              21.012093940238966
            ],
            [
              105.82570966278591,
              21.012096936537514
            ],
            [
              105.82563484276804,
              21.012134810600884
            ],
            [
              105.82562351697632,
              21.012140543238676
            ],
            [
              105.825607275709,
              21.0121497563743
            ],
            [
              105.82546868720031,
              21.012234284321742
            ],
            [
              105.82546463876322,
              21.012236081511933
            ],
            [
              105.82545916631902,
              21.012238510643087
            ],
            [
              105.82545807528801,
              21.01223899554929
            ],
            [
              105.82544869682347,
              21.012244630212475
            ],
            [
              105.82544640637514,
              21.012246006099275
            ],
            [
              105.8254437359942,
              21.012247736087502
            ],
            [
              105.82543986365094,
              21.01225024416532
            ],
            [
              105.82534035576424,
              21.012304880718645
            ],
            [
              105.82533509841764,
              21.012307767641627
            ],
            [
              105.8252649946731,
              21.01234392873334
            ],
            [
              105.82525632311724,
              21.012348848215087
            ],
            [
              105.82514699892214,
              21.01241086556501
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 136, Tây Sơn",
          "maTaiSan": "04.O12.DOPP.61",
          "duongKinh": 100,
          "chieudaiQL": 92.07,
          "chatLieu": "G-DI",
          "from_latitude": 21.01197455243502,
          "to_latitude": 21.01241086556501,
          "to_longitude": 105.82514699892214,
          "from_longitude": 105.82590499568792
        }
      },
      {
        "id": 61,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82104303713139,
            21.00968258867769,
            105.8239253506141,
            21.012189773428396
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8239253506141,
              21.00968258867769
            ],
            [
              105.82343378589208,
              21.00990765235663
            ],
            [
              105.82334895180928,
              21.009958116981743
            ],
            [
              105.82328919054657,
              21.00999366720827
            ],
            [
              105.82322302374902,
              21.010039525321027
            ],
            [
              105.8232131009786,
              21.010046402624912
            ],
            [
              105.82314364251181,
              21.010094541925532
            ],
            [
              105.82309751476032,
              21.010126511680202
            ],
            [
              105.82299573906936,
              21.010213217875833
            ],
            [
              105.82291407888009,
              21.01028278645264
            ],
            [
              105.82291154496885,
              21.010284844516065
            ],
            [
              105.82291142619616,
              21.010284936316378
            ],
            [
              105.82280262403903,
              21.01037313057648
            ],
            [
              105.82271595466518,
              21.010444737479617
            ],
            [
              105.8225996858129,
              21.010540799597845
            ],
            [
              105.82252869269745,
              21.01062578871119
            ],
            [
              105.82241905549493,
              21.01075704180462
            ],
            [
              105.82236655758767,
              21.010819890074092
            ],
            [
              105.82228805346993,
              21.0108981809297
            ],
            [
              105.82215181071345,
              21.01103405327201
            ],
            [
              105.82191705777394,
              21.01128410232193
            ],
            [
              105.82189465477312,
              21.011307965409436
            ],
            [
              105.82187834580066,
              21.0113294298945
            ],
            [
              105.82180178419866,
              21.011430193381308
            ],
            [
              105.82180041716592,
              21.011431745405353
            ],
            [
              105.82179479895846,
              21.011438125839433
            ],
            [
              105.82159113357028,
              21.01164438670061
            ],
            [
              105.82157414994977,
              21.011661586666882
            ],
            [
              105.82132816936597,
              21.011942178490415
            ],
            [
              105.82129743122587,
              21.01197571465465
            ],
            [
              105.8211825305677,
              21.012062029742957
            ],
            [
              105.82115028932928,
              21.01208738538103
            ],
            [
              105.8211502643844,
              21.012087397243366
            ],
            [
              105.82104691171587,
              21.012168678827567
            ],
            [
              105.8210468733391,
              21.01216869707729
            ],
            [
              105.821046218208,
              21.0121692123684
            ],
            [
              105.82104659186925,
              21.012182474457425
            ],
            [
              105.82104303713139,
              21.012189773428396
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Thái Hà",
          "maTaiSan": "04.O12.DOPP.62",
          "duongKinh": 150,
          "chieudaiQL": 405.72,
          "chatLieu": "G-DI",
          "from_latitude": 21.00968258867769,
          "to_latitude": 21.012189773428396,
          "to_longitude": 105.82104303713139,
          "from_longitude": 105.8239253506141
        }
      },
      {
        "id": 62,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82252902514644,
            21.010818253615433,
            105.82501090035127,
            21.013511809646264
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82501090035127,
              21.010818253615433
            ],
            [
              105.82500770701094,
              21.01081968806724
            ],
            [
              105.82500768206658,
              21.01081969993014
            ],
            [
              105.8250032854764,
              21.010821676732352
            ],
            [
              105.82479586727592,
              21.010993647715367
            ],
            [
              105.82479584233154,
              21.010993659578233
            ],
            [
              105.82459598140568,
              21.011214638907905
            ],
            [
              105.82454457821454,
              21.01127140561045
            ],
            [
              105.82454455327012,
              21.01127141747326
            ],
            [
              105.82437969139256,
              21.011453471306798
            ],
            [
              105.82436497445366,
              21.011469283139977
            ],
            [
              105.82436494950912,
              21.011469295002772
            ],
            [
              105.82416874114591,
              21.01168002982477
            ],
            [
              105.82416012373528,
              21.011689377509533
            ],
            [
              105.82416009879071,
              21.011689389372325
            ],
            [
              105.8239400925598,
              21.011927883366685
            ],
            [
              105.82391811528755,
              21.011952506071225
            ],
            [
              105.82391809034296,
              21.011952517933945
            ],
            [
              105.82381393231616,
              21.01206919257795
            ],
            [
              105.82379187526759,
              21.012092257586982
            ],
            [
              105.82379185032292,
              21.012092269449692
            ],
            [
              105.82364628419376,
              21.012244459732393
            ],
            [
              105.82363912846046,
              21.01225251321831
            ],
            [
              105.82363910351572,
              21.012252525081
            ],
            [
              105.82347902360529,
              21.01243256873876
            ],
            [
              105.82346351956815,
              21.012449421211254
            ],
            [
              105.82346349462337,
              21.012449433073915
            ],
            [
              105.8234626082982,
              21.012450411945142
            ],
            [
              105.82346258335345,
              21.01245042380782
            ],
            [
              105.82334247215442,
              21.01258094236632
            ],
            [
              105.82334029800467,
              21.012582831598674
            ],
            [
              105.82334027305984,
              21.012582843461335
            ],
            [
              105.82333838420604,
              21.012584485635436
            ],
            [
              105.82314506121686,
              21.01280198589293
            ],
            [
              105.82310742566195,
              21.012843460784065
            ],
            [
              105.8231074007171,
              21.012843472646686
            ],
            [
              105.8230274262786,
              21.012931603484386
            ],
            [
              105.82301064486215,
              21.012950938744392
            ],
            [
              105.8230106199173,
              21.012950950607
            ],
            [
              105.82289362858181,
              21.013085709777197
            ],
            [
              105.82282432077119,
              21.01316351473017
            ],
            [
              105.8228242958263,
              21.013163526592766
            ],
            [
              105.82275482830292,
              21.013241513839727
            ],
            [
              105.82272754805419,
              21.01327078656219
            ],
            [
              105.82272752310924,
              21.013270798424752
            ],
            [
              105.82252902514644,
              21.013511809646264
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Đặng Tiến Đông",
          "maTaiSan": "04.O12.DOPP.63",
          "duongKinh": 150,
          "chieudaiQL": 399.62,
          "chatLieu": "G-DI",
          "from_latitude": 21.010818253615433,
          "to_latitude": 21.013511809646264,
          "to_longitude": 105.82252902514644,
          "from_longitude": 105.82501090035127
        }
      },
      {
        "id": 63,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82324782955621,
            21.010379809835733,
            105.82487191385366,
            21.011971743855664
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82487191385366,
              21.0104024631135
            ],
            [
              105.82487054058105,
              21.010402872609625
            ],
            [
              105.82485767727758,
              21.01040676735551
            ],
            [
              105.82480173763614,
              21.010379809835733
            ],
            [
              105.82469175603433,
              21.01043383017861
            ],
            [
              105.82459119371134,
              21.01049667088217
            ],
            [
              105.82458840031418,
              21.010498416807703
            ],
            [
              105.82457918410422,
              21.01050433244244
            ],
            [
              105.82457743671756,
              21.01050545458863
            ],
            [
              105.82457514776486,
              21.010506924391773
            ],
            [
              105.82457039444095,
              21.01050997504169
            ],
            [
              105.82454954721322,
              21.010520329492326
            ],
            [
              105.82453346476048,
              21.010528316978913
            ],
            [
              105.82438870835442,
              21.01061984322226
            ],
            [
              105.8243717789399,
              21.01063054718752
            ],
            [
              105.8243295662572,
              21.01068124929375
            ],
            [
              105.82432323444753,
              21.01068885442773
            ],
            [
              105.82427176001192,
              21.010769759228452
            ],
            [
              105.82426578424167,
              21.01080592899111
            ],
            [
              105.82426346057464,
              21.0108199908438
            ],
            [
              105.82426067610838,
              21.010836845899494
            ],
            [
              105.82426525802478,
              21.010846014037636
            ],
            [
              105.82431626018176,
              21.01094806102397
            ],
            [
              105.8242544134176,
              21.011020040701904
            ],
            [
              105.82418943344246,
              21.011080950231797
            ],
            [
              105.82418382361799,
              21.011086208893488
            ],
            [
              105.82414264009365,
              21.011122220406197
            ],
            [
              105.82413696514934,
              21.011127182220175
            ],
            [
              105.82408028713515,
              21.011182102848796
            ],
            [
              105.82407301723997,
              21.011189148000366
            ],
            [
              105.82396327922233,
              21.01131079672493
            ],
            [
              105.82375946594028,
              21.011514060293003
            ],
            [
              105.8237502474324,
              21.011523253706205
            ],
            [
              105.82374948400759,
              21.011524015209393
            ],
            [
              105.82373891930911,
              21.011534173462778
            ],
            [
              105.82361749256593,
              21.011650916231744
            ],
            [
              105.82353801938281,
              21.01171208715979
            ],
            [
              105.82353250207167,
              21.01171633373905
            ],
            [
              105.8235324541855,
              21.011716371906353
            ],
            [
              105.82352425928443,
              21.011722965382848
            ],
            [
              105.82351983478662,
              21.011726525639617
            ],
            [
              105.82351711591994,
              21.011728712865548
            ],
            [
              105.82351625304659,
              21.01172940710702
            ],
            [
              105.8234936573589,
              21.01174757106636
            ],
            [
              105.82346964423817,
              21.0117668654968
            ],
            [
              105.82341471801779,
              21.011816293041754
            ],
            [
              105.82336061106913,
              21.01186094395448
            ],
            [
              105.82335651838062,
              21.011864321052972
            ],
            [
              105.82329935570458,
              21.0119191950036
            ],
            [
              105.8232745494568,
              21.011944366026448
            ],
            [
              105.82325990791142,
              21.01195922269215
            ],
            [
              105.82324931496007,
              21.011970203892815
            ],
            [
              105.82324782955621,
              21.011971743855664
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 178, Tây Sơn",
          "maTaiSan": "04.O12.DOPP.64",
          "duongKinh": 100,
          "chieudaiQL": 250.94,
          "chatLieu": "G-DI",
          "from_latitude": 21.0104024631135,
          "to_latitude": 21.011971743855664,
          "to_longitude": 105.82324782955621,
          "from_longitude": 105.82487191385366
        }
      },
      {
        "id": 64,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82291142619616,
            21.010284936316378,
            105.8232541750408,
            21.01102191753295
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82291142619616,
              21.010284936316378
            ],
            [
              105.82291155754179,
              21.010285032326497
            ],
            [
              105.82292995192046,
              21.01029974184374
            ],
            [
              105.82299700960803,
              21.010373121768247
            ],
            [
              105.82302944995614,
              21.01040663265726
            ],
            [
              105.8230340639641,
              21.010411672025256
            ],
            [
              105.82312401991209,
              21.010505564525058
            ],
            [
              105.82313598888628,
              21.010517833870384
            ],
            [
              105.8232541750408,
              21.010641361000687
            ],
            [
              105.82325414821216,
              21.01064138009825
            ],
            [
              105.82315527316963,
              21.010726117774556
            ],
            [
              105.82315689376682,
              21.010740805438832
            ],
            [
              105.82315689679618,
              21.010740831617746
            ],
            [
              105.82315801123464,
              21.010750925208146
            ],
            [
              105.82317195593812,
              21.01078159366245
            ],
            [
              105.82319985460965,
              21.010822877075714
            ],
            [
              105.82319982106763,
              21.01082289981867
            ],
            [
              105.82319652596377,
              21.01082560105356
            ],
            [
              105.8231964551066,
              21.010825660106107
            ],
            [
              105.82304077723326,
              21.0109573792816
            ],
            [
              105.82304075228869,
              21.010957391144217
            ],
            [
              105.82304072350652,
              21.01095740483183
            ],
            [
              105.82304070146745,
              21.01095742029329
            ],
            [
              105.82297531755874,
              21.01101211712471
            ],
            [
              105.82297529455776,
              21.0110121325908
            ],
            [
              105.82297526383203,
              21.0110121426749
            ],
            [
              105.82297524179786,
              21.01101215903956
            ],
            [
              105.82296365718749,
              21.01102191753295
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 16, Thái Hà",
          "maTaiSan": "04.O12.DOPP.65",
          "duongKinh": 100,
          "chieudaiQL": 110.21,
          "chatLieu": "G-DI",
          "from_latitude": 21.010284936316378,
          "to_latitude": 21.01102191753295,
          "to_longitude": 105.82296365718749,
          "from_longitude": 105.82291142619616
        }
      },
      {
        "id": 65,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8190334946489,
            21.012189773428396,
            105.82104303713139,
            21.01382526872411
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82104303713139,
              21.012189773428396
            ],
            [
              105.82102519633658,
              21.012195200298404
            ],
            [
              105.82093908749171,
              21.0122954061274
            ],
            [
              105.82081244741228,
              21.012442778874455
            ],
            [
              105.8207181584673,
              21.012512277298594
            ],
            [
              105.82069495722217,
              21.012529378010118
            ],
            [
              105.82035466919987,
              21.012830101956116
            ],
            [
              105.8198423307789,
              21.01324093583327
            ],
            [
              105.81966254331581,
              21.01332279534446
            ],
            [
              105.81951114825137,
              21.013422691756503
            ],
            [
              105.81946917683314,
              21.013450386345173
            ],
            [
              105.81939176080064,
              21.013514614656366
            ],
            [
              105.81931779297277,
              21.013575982065923
            ],
            [
              105.81918886879095,
              21.0136922231823
            ],
            [
              105.81916456799026,
              21.013714132350167
            ],
            [
              105.81909278369133,
              21.01377499779728
            ],
            [
              105.8190334946489,
              21.01382526872411
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D200_Thái Hà",
          "maTaiSan": "04.O12.DOPP.66",
          "duongKinh": 200,
          "chieudaiQL": 289.18,
          "chatLieu": "G-DI",
          "from_latitude": 21.012189773428396,
          "to_latitude": 21.01382526872411,
          "to_longitude": 105.8190334946489,
          "from_longitude": 105.82104303713139
        }
      },
      {
        "id": 66,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82228805346993,
            21.0108981809297,
            105.82274856767718,
            21.01136485891051
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82228805346993,
              21.0108981809297
            ],
            [
              105.82229880254467,
              21.010908138548604
            ],
            [
              105.82238785518531,
              21.01099063617239
            ],
            [
              105.8225380578947,
              21.01114532033008
            ],
            [
              105.82256788874783,
              21.01117604120285
            ],
            [
              105.82260772417978,
              21.01121706468674
            ],
            [
              105.82262514037677,
              21.011235456902543
            ],
            [
              105.82269960034183,
              21.01130551881529
            ],
            [
              105.82271043445488,
              21.011315712640478
            ],
            [
              105.82271352756938,
              21.01131862323767
            ],
            [
              105.82274856767718,
              21.01136485891051
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 70, Thái Hà",
          "maTaiSan": "04.O12.DOPP.67",
          "duongKinh": 100,
          "chieudaiQL": 70.46,
          "chatLieu": "G-DI",
          "from_latitude": 21.0108981809297,
          "to_latitude": 21.01136485891051,
          "to_longitude": 105.82274856767718,
          "from_longitude": 105.82228805346993
        }
      },
      {
        "id": 67,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82093352064182,
            21.012084947614344,
            105.82104303713139,
            21.012189773428396
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82104303713139,
              21.012189773428396
            ],
            [
              105.82104234285019,
              21.012189111100085
            ],
            [
              105.82099382451088,
              21.012142669136423
            ],
            [
              105.82095821155933,
              21.012108584266816
            ],
            [
              105.82093352064182,
              21.012084947614344
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D200_Thái Hà",
          "maTaiSan": "04.O12.DOPP.68",
          "duongKinh": 200,
          "chieudaiQL": 14.41,
          "chatLieu": "G-DI",
          "from_latitude": 21.012189773428396,
          "to_latitude": 21.012084947614344,
          "to_longitude": 105.82093352064182,
          "from_longitude": 105.82104303713139
        }
      },
      {
        "id": 68,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82104691171587,
            21.01213672601064,
            105.82258706540259,
            21.013558401103932
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82104691171587,
              21.012168678827567
            ],
            [
              105.82104833506536,
              21.012169680864197
            ],
            [
              105.82108379940578,
              21.01219347867263
            ],
            [
              105.82116955917347,
              21.01213672601064
            ],
            [
              105.82126979462237,
              21.012235983809635
            ],
            [
              105.82131038953446,
              21.012276761863742
            ],
            [
              105.8213398032017,
              21.012306307154116
            ],
            [
              105.82137633672419,
              21.012343005950953
            ],
            [
              105.82137788140186,
              21.01234455746436
            ],
            [
              105.82138954628633,
              21.012356274719586
            ],
            [
              105.82155602803955,
              21.0125411834903
            ],
            [
              105.82159552227414,
              21.012585049499094
            ],
            [
              105.82162468187613,
              21.0126184157101
            ],
            [
              105.82178650219667,
              21.012803575259323
            ],
            [
              105.82182128499566,
              21.01284092911834
            ],
            [
              105.82182683329513,
              21.012846888005292
            ],
            [
              105.82186065237069,
              21.012883206896063
            ],
            [
              105.82186563945343,
              21.012888562427122
            ],
            [
              105.82187175477745,
              21.012895130058272
            ],
            [
              105.82187406544708,
              21.012897612706723
            ],
            [
              105.82192070447027,
              21.01294769806463
            ],
            [
              105.822006778186,
              21.013038632822347
            ],
            [
              105.82202955562211,
              21.013062696746026
            ],
            [
              105.82211823114928,
              21.013156380940245
            ],
            [
              105.8221488360613,
              21.013186110903387
            ],
            [
              105.82215249062534,
              21.01318966099079
            ],
            [
              105.82225315707143,
              21.01328745194687
            ],
            [
              105.82227819543027,
              21.013313680781703
            ],
            [
              105.82227954213606,
              21.0133150869202
            ],
            [
              105.82229212321472,
              21.013328262004684
            ],
            [
              105.82250978921175,
              21.013515260835668
            ],
            [
              105.82250211291169,
              21.01352681499016
            ],
            [
              105.82251031721756,
              21.013532086302757
            ],
            [
              105.8225103712771,
              21.01353212126727
            ],
            [
              105.82252315123276,
              21.0135403330435
            ],
            [
              105.82253835845245,
              21.013526674994534
            ],
            [
              105.82258706540259,
              21.013558401103932
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D200_Trung Liệt",
          "maTaiSan": "04.O12.DOPP.69",
          "duongKinh": 200,
          "chieudaiQL": 213.76,
          "chatLieu": "G-CI",
          "from_latitude": 21.012168678827567,
          "to_latitude": 21.013558401103932,
          "to_longitude": 105.82258706540259,
          "from_longitude": 105.82104691171587
        }
      },
      {
        "id": 69,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82232363030815,
            21.012708500564486,
            105.8228242958263,
            21.013163526592766
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8228242958263,
              21.013163526592766
            ],
            [
              105.8228138207583,
              21.01315410601328
            ],
            [
              105.82270941807832,
              21.013060198368986
            ],
            [
              105.82261866365704,
              21.01297179848353
            ],
            [
              105.82260083057041,
              21.01295442803507
            ],
            [
              105.82251150369113,
              21.012867417574952
            ],
            [
              105.8224507817324,
              21.012811672192104
            ],
            [
              105.82243233641495,
              21.012794739136112
            ],
            [
              105.82242910058694,
              21.012791769611827
            ],
            [
              105.82239864500367,
              21.01278400997098
            ],
            [
              105.82236336040674,
              21.012748493112714
            ],
            [
              105.82234570311361,
              21.012730718043386
            ],
            [
              105.82232363030815,
              21.012708500564486
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 155, Đặng Tiến Đông",
          "maTaiSan": "04.O12.DOPP.70",
          "duongKinh": 100,
          "chieudaiQL": 72.45,
          "chatLieu": "G-DI",
          "from_latitude": 21.013163526592766,
          "to_latitude": 21.012708500564486,
          "to_longitude": 105.82232363030815,
          "from_longitude": 105.8228242958263
        }
      },
      {
        "id": 70,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81939176080064,
            21.013514614656366,
            105.82004280663952,
            21.01418188039584
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81939176080064,
              21.013514614656366
            ],
            [
              105.81941220179853,
              21.01353822593213
            ],
            [
              105.81955467176088,
              21.013695998326888
            ],
            [
              105.81961765859204,
              21.01377097168634
            ],
            [
              105.81962820545897,
              21.013783525416095
            ],
            [
              105.81964248932094,
              21.013801343215828
            ],
            [
              105.81964600411293,
              21.01380572771024
            ],
            [
              105.81981997005342,
              21.01398180648231
            ],
            [
              105.81982412838737,
              21.01398601535538
            ],
            [
              105.81989961442238,
              21.014053002553283
            ],
            [
              105.81990341129323,
              21.014056371358343
            ],
            [
              105.8199277151042,
              21.014079196208225
            ],
            [
              105.81997249092467,
              21.01412247442467
            ],
            [
              105.82003466477663,
              21.014175001821016
            ],
            [
              105.82004280663952,
              21.01418188039584
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 198, Thái Hà",
          "maTaiSan": "04.O12.DOPP.71",
          "duongKinh": 100,
          "chieudaiQL": 87.15,
          "chatLieu": "G-DI",
          "from_latitude": 21.013514614656366,
          "to_latitude": 21.01418188039584,
          "to_longitude": 105.82004280663952,
          "from_longitude": 105.81939176080064
        }
      },
      {
        "id": 71,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8198423307789,
            21.01324093583327,
            105.82104727892455,
            21.01458259308506
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8198423307789,
              21.01324093583327
            ],
            [
              105.81985597329928,
              21.013256870768537
            ],
            [
              105.82003644218086,
              21.013439421242406
            ],
            [
              105.82003977443607,
              21.013442791381078
            ],
            [
              105.82004548612822,
              21.013448558055394
            ],
            [
              105.82018461353084,
              21.013610650799208
            ],
            [
              105.82018784108143,
              21.013614398085206
            ],
            [
              105.82019509946986,
              21.01362524969322
            ],
            [
              105.82019923382735,
              21.013631406933992
            ],
            [
              105.82032363633371,
              21.013764114489415
            ],
            [
              105.82050077230525,
              21.01394366552417
            ],
            [
              105.8205059995421,
              21.01394896303336
            ],
            [
              105.82050755391636,
              21.01395052715258
            ],
            [
              105.82063855054125,
              21.014093351316948
            ],
            [
              105.82064326324708,
              21.01409848962566
            ],
            [
              105.8206598321572,
              21.014116541087983
            ],
            [
              105.82067753558904,
              21.0141286880999
            ],
            [
              105.82068129153342,
              21.01413125411554
            ],
            [
              105.82069724974814,
              21.014150031101344
            ],
            [
              105.82070559326236,
              21.014159828804164
            ],
            [
              105.82075485086399,
              21.014215572302675
            ],
            [
              105.82076575347243,
              21.01422789572516
            ],
            [
              105.8208466442589,
              21.014313533341575
            ],
            [
              105.82085744091977,
              21.01432495042881
            ],
            [
              105.82086188239897,
              21.014351362843033
            ],
            [
              105.8208663471584,
              21.014377810370547
            ],
            [
              105.82098500926405,
              21.014512127546606
            ],
            [
              105.82103463584268,
              21.014568283756393
            ],
            [
              105.82104727892455,
              21.01458259308506
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 178, Thái Hà",
          "maTaiSan": "04.O12.DOPP.72",
          "duongKinh": 100,
          "chieudaiQL": 194.79,
          "chatLieu": "G-DI",
          "from_latitude": 21.01324093583327,
          "to_latitude": 21.01458259308506,
          "to_longitude": 105.82104727892455,
          "from_longitude": 105.8198423307789
        }
      },
      {
        "id": 72,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82014665684774,
            21.014392699755717,
            105.82064273415143,
            21.014909938734377
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82064273415143,
              21.014909938734377
            ],
            [
              105.82064267423142,
              21.014909887539446
            ],
            [
              105.8205826384156,
              21.014858974977685
            ],
            [
              105.82051433546918,
              21.01479112340136
            ],
            [
              105.82051282762293,
              21.014789625897006
            ],
            [
              105.82047630903614,
              21.014746764145407
            ],
            [
              105.82047508432275,
              21.014745325791505
            ],
            [
              105.82046807575995,
              21.01473795493453
            ],
            [
              105.82033131405824,
              21.01459411432726
            ],
            [
              105.82032343572679,
              21.014585828172294
            ],
            [
              105.82031394051906,
              21.01457545522905
            ],
            [
              105.82016791650288,
              21.01441592492017
            ],
            [
              105.82015766650163,
              21.01440472734667
            ],
            [
              105.82014665684774,
              21.014392699755717
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 223, Đặng Tiến Đông",
          "maTaiSan": "04.O12.DOPP.73",
          "duongKinh": 100,
          "chieudaiQL": 77.08,
          "chatLieu": "G-DI",
          "from_latitude": 21.014909938734377,
          "to_latitude": 21.014392699755717,
          "to_longitude": 105.82014665684774,
          "from_longitude": 105.82064273415143
        }
      },
      {
        "id": 73,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82102440755394,
            21.014534182536945,
            105.82190819859791,
            21.016258567197944
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82146405091844,
              21.014534182536945
            ],
            [
              105.82146309309664,
              21.014537216584696
            ],
            [
              105.8214020708076,
              21.01466260452299
            ],
            [
              105.82138979675685,
              21.01468512884097
            ],
            [
              105.8213712191157,
              21.014719240378955
            ],
            [
              105.82128590484213,
              21.01484899884749
            ],
            [
              105.8212791076232,
              21.014859329349665
            ],
            [
              105.8211557117882,
              21.015047014878693
            ],
            [
              105.82102440755394,
              21.0152062272261
            ],
            [
              105.82103190781378,
              21.015222403051315
            ],
            [
              105.8210362181859,
              21.015231705358406
            ],
            [
              105.82104352100014,
              21.015247483812775
            ],
            [
              105.82104899364482,
              21.0152592923845
            ],
            [
              105.82109559379076,
              21.015359823352753
            ],
            [
              105.821227861088,
              21.015496155688364
            ],
            [
              105.82124769667442,
              21.015516612866968
            ],
            [
              105.82141852958306,
              21.015692685113912
            ],
            [
              105.82147449993838,
              21.015757831972202
            ],
            [
              105.82149950965096,
              21.01578105962217
            ],
            [
              105.82150081590532,
              21.015782278090168
            ],
            [
              105.8215049308473,
              21.015786098740552
            ],
            [
              105.82151962798409,
              21.015799735140984
            ],
            [
              105.82156617637052,
              21.015842957374815
            ],
            [
              105.82161004419535,
              21.015884836797024
            ],
            [
              105.82161787789745,
              21.015892325553548
            ],
            [
              105.82162690862606,
              21.01590093575567
            ],
            [
              105.82166430587658,
              21.01593866808515
            ],
            [
              105.82167555839023,
              21.015950032330963
            ],
            [
              105.82167695512487,
              21.015951436425237
            ],
            [
              105.82171932518577,
              21.01599417840659
            ],
            [
              105.82174107272154,
              21.016016120224943
            ],
            [
              105.82175980172359,
              21.01604596901585
            ],
            [
              105.82178084860057,
              21.016083467777058
            ],
            [
              105.82178154996284,
              21.016084716261414
            ],
            [
              105.82178383270514,
              21.016088764335628
            ],
            [
              105.82179459992767,
              21.016107939311908
            ],
            [
              105.82188764355561,
              21.01623131228222
            ],
            [
              105.82190819859791,
              21.016258567197944
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 24, Đặng Tiến Đông",
          "maTaiSan": "04.O12.DOPP.74",
          "duongKinh": 100,
          "chieudaiQL": 235.96,
          "chatLieu": "G-DI",
          "from_latitude": 21.014534182536945,
          "to_latitude": 21.016258567197944,
          "to_longitude": 105.82190819859791,
          "from_longitude": 105.82146405091844
        }
      },
      {
        "id": 74,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8245723296983,
            21.01526275828655,
            105.82521075138055,
            21.015593806150385
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82521075138055,
              21.01526275828655
            ],
            [
              105.8245723296983,
              21.015593806150385
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Võ Văn Dũng",
          "maTaiSan": "04.O12.DOPP.75",
          "duongKinh": 100,
          "chieudaiQL": 77.49,
          "chatLieu": "G-DI",
          "from_latitude": 21.01526275828655,
          "to_latitude": 21.015593806150385,
          "to_longitude": 105.8245723296983,
          "from_longitude": 105.82521075138055
        }
      },
      {
        "id": 75,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82323763091267,
            21.01218490893714,
            105.82496874813181,
            21.014158549498074
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82496874813181,
              21.01218490893714
            ],
            [
              105.82496163467374,
              21.012188741493883
            ],
            [
              105.82483678563959,
              21.012263448378295
            ],
            [
              105.82481758449367,
              21.0122749365648
            ],
            [
              105.82471289715652,
              21.012337579678977
            ],
            [
              105.8246089094051,
              21.012453138484524
            ],
            [
              105.8245965884615,
              21.01246683691779
            ],
            [
              105.8245274992895,
              21.01254360367037
            ],
            [
              105.82451752356342,
              21.01255469034401
            ],
            [
              105.82451383216639,
              21.012558797138826
            ],
            [
              105.8244858983478,
              21.01258965665577
            ],
            [
              105.82433140421877,
              21.012765530922554
            ],
            [
              105.82430603071968,
              21.012794421618253
            ],
            [
              105.82429487336454,
              21.01280596832702
            ],
            [
              105.82422617898848,
              21.012877087883883
            ],
            [
              105.82417234144455,
              21.012936830665417
            ],
            [
              105.8241472392704,
              21.01296469305557
            ],
            [
              105.82408570301574,
              21.013038373759407
            ],
            [
              105.82403555406017,
              21.01309841747336
            ],
            [
              105.82403224692925,
              21.013101036589347
            ],
            [
              105.82402633085347,
              21.01310571866505
            ],
            [
              105.82400974348684,
              21.013118856945567
            ],
            [
              105.82376936557375,
              21.013395063375963
            ],
            [
              105.82347433976472,
              21.01376011252017
            ],
            [
              105.82330962410688,
              21.013947269707707
            ],
            [
              105.82328219014379,
              21.014074906452574
            ],
            [
              105.82327460182348,
              21.014110197011455
            ],
            [
              105.82323772564551,
              21.014158458717027
            ],
            [
              105.82323769118547,
              21.01415848959347
            ],
            [
              105.82323764521735,
              21.014158526848192
            ],
            [
              105.82323763091267,
              21.014158549498074
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 136, Tây Sơn",
          "maTaiSan": "04.O12.DOPP.76",
          "duongKinh": 100,
          "chieudaiQL": 291.29,
          "chatLieu": "G-DI",
          "from_latitude": 21.01218490893714,
          "to_latitude": 21.014158549498074,
          "to_longitude": 105.82323763091267,
          "from_longitude": 105.82496874813181
        }
      },
      {
        "id": 76,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8228677996269,
            21.012223877146415,
            105.82334027305984,
            21.012582843461335
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82334027305984,
              21.012582843461335
            ],
            [
              105.82331765764303,
              21.01257605585434
            ],
            [
              105.82330005339576,
              21.01257234477452
            ],
            [
              105.82309406306105,
              21.01240628324281
            ],
            [
              105.82307102036077,
              21.0123877069451
            ],
            [
              105.8228677996269,
              21.012223877146415
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 117, Đặng Tiến Đông",
          "maTaiSan": "04.O12.DOPP.77",
          "duongKinh": 100,
          "chieudaiQL": 62.93,
          "chatLieu": "G-DI",
          "from_latitude": 21.012582843461335,
          "to_latitude": 21.012223877146415,
          "to_longitude": 105.8228677996269,
          "from_longitude": 105.82334027305984
        }
      },
      {
        "id": 77,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82094504962153,
            21.013403719320685,
            105.82184531295827,
            21.014238749182372
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82184531295827,
              21.014238749182372
            ],
            [
              105.82184529553018,
              21.014238728492323
            ],
            [
              105.82184216906386,
              21.014235006041663
            ],
            [
              105.82177086275287,
              21.014175765491135
            ],
            [
              105.82160896747679,
              21.014023740337006
            ],
            [
              105.82155942151826,
              21.013977215012176
            ],
            [
              105.82144801000146,
              21.013852844786765
            ],
            [
              105.82144157674469,
              21.013845663579076
            ],
            [
              105.82141793909553,
              21.013819276116486
            ],
            [
              105.82137942607336,
              21.01381587713977
            ],
            [
              105.82128611071549,
              21.013732116137213
            ],
            [
              105.82128200716973,
              21.013728432714398
            ],
            [
              105.82125397198436,
              21.013693674571865
            ],
            [
              105.82124203230698,
              21.013678870504837
            ],
            [
              105.82098427573052,
              21.013440061974233
            ],
            [
              105.82097115781765,
              21.01342790822074
            ],
            [
              105.82095701506886,
              21.01341480470001
            ],
            [
              105.82094504962153,
              21.013403719320685
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 183, Đặng Tiến Đông",
          "maTaiSan": "04.O12.DOPP.78",
          "duongKinh": 100,
          "chieudaiQL": 125.74,
          "chatLieu": "G-DI",
          "from_latitude": 21.014238749182372,
          "to_latitude": 21.013403719320685,
          "to_longitude": 105.82094504962153,
          "from_longitude": 105.82184531295827
        }
      },
      {
        "id": 78,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82319811695682,
            21.015566451721625,
            105.82455672097802,
            21.016273889682303
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82320537220076,
              21.016273889682303
            ],
            [
              105.82319811695682,
              21.016214949520563
            ],
            [
              105.82329310854234,
              21.01616706413971
            ],
            [
              105.82331079302237,
              21.016143080089165
            ],
            [
              105.82338822186081,
              21.01609598055337
            ],
            [
              105.82338826023826,
              21.016095962303098
            ],
            [
              105.8234157482843,
              21.016079243315104
            ],
            [
              105.82374755486279,
              21.015915517835246
            ],
            [
              105.82374758556472,
              21.015915503234964
            ],
            [
              105.82379921631473,
              21.01589002702359
            ],
            [
              105.82400281659768,
              21.01579071850571
            ],
            [
              105.824002854975,
              21.01579070025532
            ],
            [
              105.82401271440607,
              21.01578589069216
            ],
            [
              105.82401273935132,
              21.01578587882939
            ],
            [
              105.82401276429658,
              21.015785866966624
            ],
            [
              105.82402758270595,
              21.015778638930726
            ],
            [
              105.82402762108325,
              21.015778620680305
            ],
            [
              105.82406421811795,
              21.01576076998575
            ],
            [
              105.82420125020819,
              21.01569826949477
            ],
            [
              105.82441533076468,
              21.015600625853118
            ],
            [
              105.8244555028982,
              21.015592164854286
            ],
            [
              105.82448489166573,
              21.015585972622393
            ],
            [
              105.82448491661093,
              21.015585960759573
            ],
            [
              105.82449180383036,
              21.015584513833254
            ],
            [
              105.82455672097802,
              21.015566451721625
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D200_Võ Văn Dũng",
          "maTaiSan": "04.O12.DOPP.79",
          "duongKinh": 200,
          "chieudaiQL": 159.05,
          "chatLieu": "G-CI",
          "from_latitude": 21.016273889682303,
          "to_latitude": 21.015566451721625,
          "to_longitude": 105.82455672097802,
          "from_longitude": 105.82320537220076
        }
      },
      {
        "id": 79,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82460669758962,
            21.014827005454364,
            105.82491402786432,
            21.01537821422578
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82460669758962,
              21.014827005454364
            ],
            [
              105.82477537697204,
              21.01512398424451
            ],
            [
              105.82490009904757,
              21.015352681620023
            ],
            [
              105.82491402786432,
              21.01537821422578
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Trần Quang Diệu",
          "maTaiSan": "04.O12.DOPP.80",
          "duongKinh": 100,
          "chieudaiQL": 68.89,
          "chatLieu": "G-CI",
          "from_latitude": 21.014827005454364,
          "to_latitude": 21.01537821422578,
          "to_longitude": 105.82491402786432,
          "from_longitude": 105.82460669758962
        }
      },
      {
        "id": 80,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82115028932928,
            21.01208738538103,
            105.82252902514644,
            21.013511809646264
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82115028932928,
              21.01208738538103
            ],
            [
              105.82115787570096,
              21.012095245685
            ],
            [
              105.82135329457691,
              21.012297258531493
            ],
            [
              105.82138827631584,
              21.012333216041245
            ],
            [
              105.82139030687017,
              21.012335314371118
            ],
            [
              105.8214016965942,
              21.0123470221151
            ],
            [
              105.82140729460252,
              21.012352754970305
            ],
            [
              105.82151025490552,
              21.012458584891355
            ],
            [
              105.82157234082334,
              21.012528814512827
            ],
            [
              105.82164074941159,
              21.012606229459028
            ],
            [
              105.82167164439898,
              21.01264118870303
            ],
            [
              105.82167179536965,
              21.01264135507098
            ],
            [
              105.82167357819603,
              21.01264335794819
            ],
            [
              105.82181053686256,
              21.012798327941084
            ],
            [
              105.82183767252323,
              21.012825521298755
            ],
            [
              105.82184409963803,
              21.012831937489352
            ],
            [
              105.82187853793208,
              21.012866453254187
            ],
            [
              105.82188294166536,
              21.012870853278677
            ],
            [
              105.8218903206684,
              21.012878246677158
            ],
            [
              105.82190028417367,
              21.01288824062658
            ],
            [
              105.82203004008313,
              21.0130182204117
            ],
            [
              105.82205347039044,
              21.01304170762886
            ],
            [
              105.82206064048245,
              21.01304889067354
            ],
            [
              105.82216803617375,
              21.013169635396054
            ],
            [
              105.82217170725364,
              21.01317374179106
            ],
            [
              105.82219002459618,
              21.01319434981974
            ],
            [
              105.82225867880679,
              21.013266446010196
            ],
            [
              105.82229132194003,
              21.013300712036838
            ],
            [
              105.82229338366734,
              21.013302877043124
            ],
            [
              105.8223059379367,
              21.01331607483682
            ],
            [
              105.82235538943607,
              21.013368005313687
            ],
            [
              105.82251370326401,
              21.013499116605082
            ],
            [
              105.82252902514644,
              21.013511809646264
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Trung Liệt",
          "maTaiSan": "04.O12.DOPP.81",
          "duongKinh": 150,
          "chieudaiQL": 207.96,
          "chatLieu": "G-DI",
          "from_latitude": 21.01208738538103,
          "to_latitude": 21.013511809646264,
          "to_longitude": 105.82252902514644,
          "from_longitude": 105.82115028932928
        }
      },
      {
        "id": 81,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82258706540259,
            21.013558401103932,
            105.82318031713285,
            21.01407894724691
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82258706540259,
              21.013558401103932
            ],
            [
              105.82306269172693,
              21.01398922254223
            ],
            [
              105.82309439310771,
              21.0140137796581
            ],
            [
              105.82318031713285,
              21.01407894724691
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D200_Trần Quang Diệu",
          "maTaiSan": "04.O12.DOPP.82",
          "duongKinh": 200,
          "chieudaiQL": 89.36,
          "chatLieu": "G-CI",
          "from_latitude": 21.013558401103932,
          "to_latitude": 21.01407894724691,
          "to_longitude": 105.82318031713285,
          "from_longitude": 105.82258706540259
        }
      },
      {
        "id": 82,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82329243312276,
            21.01416400806922,
            105.82416538804904,
            21.01505906372124
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82329243312276,
              21.01416400806922
            ],
            [
              105.82332352694795,
              21.01418757001429
            ],
            [
              105.82338777942053,
              21.01423233008498
            ],
            [
              105.8233906733972,
              21.0142343474362
            ],
            [
              105.82340348433951,
              21.014243277961015
            ],
            [
              105.82361044848469,
              21.014418502941325
            ],
            [
              105.82392505605642,
              21.01467163716699
            ],
            [
              105.82393886587137,
              21.01468447829864
            ],
            [
              105.82404145178693,
              21.0148537410685
            ],
            [
              105.82415152834207,
              21.015035226070033
            ],
            [
              105.82415773932543,
              21.015045863990178
            ],
            [
              105.82416538804904,
              21.01505906372124
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D200_Trần Quang Diệu",
          "maTaiSan": "04.O12.DOPP.83",
          "duongKinh": 200,
          "chieudaiQL": 139.37,
          "chatLieu": "G-CI",
          "from_latitude": 21.01416400806922,
          "to_latitude": 21.01505906372124,
          "to_longitude": 105.82416538804904,
          "from_longitude": 105.82329243312276
        }
      },
      {
        "id": 83,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82244285469459,
            21.013624408784736,
            105.82448489166573,
            21.015585972622393
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82244285469459,
              21.013624408784736
            ],
            [
              105.82249050246207,
              21.013668570248896
            ],
            [
              105.822628814281,
              21.013692830633985
            ],
            [
              105.82286531343732,
              21.013875546040865
            ],
            [
              105.82303586215117,
              21.014005097378135
            ],
            [
              105.823129880388,
              21.014076680296004
            ],
            [
              105.82323772564551,
              21.014158458717027
            ],
            [
              105.82325817185193,
              21.014174131025054
            ],
            [
              105.82328269268383,
              21.014192587229378
            ],
            [
              105.82330240979701,
              21.014207565768796
            ],
            [
              105.8233367997934,
              21.014233688666366
            ],
            [
              105.8233983621666,
              21.01427659297425
            ],
            [
              105.82356446018959,
              21.014451911909454
            ],
            [
              105.82357359400073,
              21.014463175189647
            ],
            [
              105.8236095897748,
              21.014482503410818
            ],
            [
              105.82374750754859,
              21.014567794879625
            ],
            [
              105.82390362794285,
              21.014674244680773
            ],
            [
              105.82391505671744,
              21.014683186374157
            ],
            [
              105.82396017264274,
              21.014756971940926
            ],
            [
              105.82398375361323,
              21.014795173507476
            ],
            [
              105.82424240828895,
              21.015247957173834
            ],
            [
              105.82424762741844,
              21.01525472324763
            ],
            [
              105.82425994025058,
              21.015270311991447
            ],
            [
              105.82426981217701,
              21.01528493888219
            ],
            [
              105.82429296313599,
              21.015318904544237
            ],
            [
              105.82430643289707,
              21.015342667296874
            ],
            [
              105.82439664872035,
              21.01549907000052
            ],
            [
              105.82440345368012,
              21.01551013496761
            ],
            [
              105.82445050513303,
              21.015548497306995
            ],
            [
              105.82448489166573,
              21.015585972622393
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Trần Quang Diệu",
          "maTaiSan": "04.O12.DOPP.84",
          "duongKinh": 100,
          "chieudaiQL": 320.32,
          "chatLieu": "G-CI",
          "from_latitude": 21.013624408784736,
          "to_latitude": 21.015585972622393,
          "to_longitude": 105.82448489166573,
          "from_longitude": 105.82244285469459
        }
      },
      {
        "id": 84,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82288103936871,
            21.01518005058345,
            105.82294888472387,
            21.016274829159812
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82288347856874,
              21.016274829159812
            ],
            [
              105.82288103936871,
              21.01618019907734
            ],
            [
              105.82290696201393,
              21.01615779741434
            ],
            [
              105.82290698988474,
              21.0161577927634
            ],
            [
              105.82290684697418,
              21.016147875124254
            ],
            [
              105.8229046325904,
              21.015991829065566
            ],
            [
              105.82290358500705,
              21.015918708912064
            ],
            [
              105.82290394133744,
              21.015912822667573
            ],
            [
              105.82290533051982,
              21.015890054639176
            ],
            [
              105.82290584659701,
              21.01588137484546
            ],
            [
              105.82290142006296,
              21.015681898800196
            ],
            [
              105.82289774243382,
              21.015652592434776
            ],
            [
              105.82289599184026,
              21.01563876527758
            ],
            [
              105.82289605378303,
              21.015636379556586
            ],
            [
              105.82289877614063,
              21.015503835952657
            ],
            [
              105.8228985201968,
              21.015490146985226
            ],
            [
              105.82289753405512,
              21.01543979596936
            ],
            [
              105.82289807915271,
              21.01542711293463
            ],
            [
              105.82290462534151,
              21.015270922424182
            ],
            [
              105.82292271965613,
              21.01522889447887
            ],
            [
              105.8229459059802,
              21.01518748228891
            ],
            [
              105.82294593092544,
              21.01518747042629
            ],
            [
              105.82294888472387,
              21.01518005058345
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 95, Hoàng Cầu",
          "maTaiSan": "04.O12.DOPP.85",
          "duongKinh": 100,
          "chieudaiQL": 121.83,
          "chatLieu": "G-CI",
          "from_latitude": 21.016274829159812,
          "to_latitude": 21.01518005058345,
          "to_longitude": 105.82294888472387,
          "from_longitude": 105.82288347856874
        }
      },
      {
        "id": 85,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82229129497188,
            21.01483316759758,
            105.82241590764674,
            21.01630602072317
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82229141111883,
              21.01630602072317
            ],
            [
              105.82229140960683,
              21.016296620851172
            ],
            [
              105.82229140499511,
              21.01626595091071
            ],
            [
              105.82229805239896,
              21.016143371703002
            ],
            [
              105.82229482819376,
              21.015921976632416
            ],
            [
              105.82229131949042,
              21.015681143686557
            ],
            [
              105.82229129497188,
              21.015512608029063
            ],
            [
              105.82229159630752,
              21.015172591394958
            ],
            [
              105.82231496017565,
              21.01498947028272
            ],
            [
              105.82239864606478,
              21.014859894420194
            ],
            [
              105.82241260451248,
              21.014838282279573
            ],
            [
              105.82241590764674,
              21.01483316759758
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 86, Hoàng Cầu",
          "maTaiSan": "04.O12.DOPP.86",
          "duongKinh": 100,
          "chieudaiQL": 166.15,
          "chatLieu": "G-CI",
          "from_latitude": 21.01630602072317,
          "to_latitude": 21.01483316759758,
          "to_longitude": 105.82241590764674,
          "from_longitude": 105.82229141111883
        }
      },
      {
        "id": 86,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82306934988331,
            21.01500159000254,
            105.82374755486279,
            21.015915517835246
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82306934988331,
              21.01500159000254
            ],
            [
              105.82309313909494,
              21.015020801253247
            ],
            [
              105.82312769341368,
              21.015046339003845
            ],
            [
              105.82328564223226,
              21.015163073688377
            ],
            [
              105.82331846676728,
              21.01521432107996
            ],
            [
              105.82345658706983,
              21.015429801045435
            ],
            [
              105.8234637961894,
              21.015441080870403
            ],
            [
              105.82347954914488,
              21.015465659995915
            ],
            [
              105.82348071909324,
              21.015467454463423
            ],
            [
              105.82349698144174,
              21.015492829573684
            ],
            [
              105.82355575020296,
              21.01559096607502
            ],
            [
              105.82361089569862,
              21.015677240859173
            ],
            [
              105.82361873358498,
              21.015691257118487
            ],
            [
              105.82362042196516,
              21.015694267527557
            ],
            [
              105.82366191648043,
              21.015768248627854
            ],
            [
              105.82369350477816,
              21.015820997664267
            ],
            [
              105.82373469663561,
              21.01588784833338
            ],
            [
              105.82373744550826,
              21.01589864393406
            ],
            [
              105.82374273523182,
              21.01590810490863
            ],
            [
              105.82374314576758,
              21.015908766792258
            ],
            [
              105.82374755486279,
              21.015915517835246
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 20, Võ Văn Dũng",
          "maTaiSan": "04.O12.DOPP.87",
          "duongKinh": 100,
          "chieudaiQL": 127.89,
          "chatLieu": "G-CI",
          "from_latitude": 21.01500159000254,
          "to_latitude": 21.015915517835246,
          "to_longitude": 105.82374755486279,
          "from_longitude": 105.82306934988331
        }
      },
      {
        "id": 87,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82449180383036,
            21.015584513833254,
            105.82512214399136,
            21.016564689987632
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82449180383036,
              21.015584513833254
            ],
            [
              105.82451032189137,
              21.015621574689916
            ],
            [
              105.82466474479303,
              21.015819465554458
            ],
            [
              105.82467818102135,
              21.015841309080134
            ],
            [
              105.82468923655266,
              21.0158593218205
            ],
            [
              105.82470370996546,
              21.01588295231697
            ],
            [
              105.82484711165363,
              21.01611655940444
            ],
            [
              105.82484906750223,
              21.016119748245558
            ],
            [
              105.82498388361901,
              21.01633941307839
            ],
            [
              105.82499180986035,
              21.016352324194738
            ],
            [
              105.82504464484622,
              21.016438411873306
            ],
            [
              105.8250501755919,
              21.01644742724763
            ],
            [
              105.8251198405443,
              21.016560917545558
            ],
            [
              105.82512214399136,
              21.016564689987632
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Trần Quang Diệu",
          "maTaiSan": "04.O12.DOPP.88",
          "duongKinh": 100,
          "chieudaiQL": 125.27,
          "chatLieu": "G-CI",
          "from_latitude": 21.015584513833254,
          "to_latitude": 21.016564689987632,
          "to_longitude": 105.82512214399136,
          "from_longitude": 105.82449180383036
        }
      },
      {
        "id": 88,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82455672097802,
            21.015566451721625,
            105.82515498221576,
            21.016549887612552
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82455672097802,
              21.015566451721625
            ],
            [
              105.82456274015976,
              21.015578236748677
            ],
            [
              105.82456870815017,
              21.015587708861045
            ],
            [
              105.8245723296983,
              21.015593806150385
            ],
            [
              105.8245781688311,
              21.015603635661297
            ],
            [
              105.82489011422035,
              21.016128791037332
            ],
            [
              105.82489669592235,
              21.01613930338692
            ],
            [
              105.82490309968718,
              21.016149469760137
            ],
            [
              105.82515144030128,
              21.016544285822473
            ],
            [
              105.82515498221576,
              21.016549887612552
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Trần Quang Diệu",
          "maTaiSan": "04.O12.DOPP.89",
          "duongKinh": 100,
          "chieudaiQL": 127.35,
          "chatLieu": "G-CI",
          "from_latitude": 21.015566451721625,
          "to_latitude": 21.016549887612552,
          "to_longitude": 105.82515498221576,
          "from_longitude": 105.82455672097802
        }
      },
      {
        "id": 89,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82515498221576,
            21.016282136177043,
            105.82566525394213,
            21.016549887612552
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82515498221576,
              21.016549887612552
            ],
            [
              105.82516880634381,
              21.01654307346129
            ],
            [
              105.82522850687603,
              21.016528276260512
            ],
            [
              105.8254186220353,
              21.016433255858097
            ],
            [
              105.82542878203786,
              21.016428003980632
            ],
            [
              105.8254409861253,
              21.01642169534569
            ],
            [
              105.82550554509336,
              21.016388324019733
            ],
            [
              105.82551400903546,
              21.016382695592938
            ],
            [
              105.82566525394213,
              21.016282136177043
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 61, Hoàng Cầu",
          "maTaiSan": "04.O12.DOPP.90",
          "duongKinh": 100,
          "chieudaiQL": 60.63,
          "chatLieu": "G-DI",
          "from_latitude": 21.016549887612552,
          "to_latitude": 21.016282136177043,
          "to_longitude": 105.82566525394213,
          "from_longitude": 105.82515498221576
        }
      },
      {
        "id": 90,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82566525394213,
            21.016282136177043,
            105.82629368036137,
            21.017369257934646
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82566525394213,
              21.016282136177043
            ],
            [
              105.82580330444411,
              21.01650319184694
            ],
            [
              105.82581488347644,
              21.016521732160093
            ],
            [
              105.8258900848261,
              21.016660167640534
            ],
            [
              105.82590694936725,
              21.016691212740525
            ],
            [
              105.82598147186675,
              21.016818314176927
            ],
            [
              105.82598278280439,
              21.016820549618743
            ],
            [
              105.82604326476162,
              21.016923704222428
            ],
            [
              105.82604679264881,
              21.016929720641315
            ],
            [
              105.82609529461438,
              21.0170124428108
            ],
            [
              105.82616351553828,
              21.017135145334322
            ],
            [
              105.82616637682212,
              21.017140291568168
            ],
            [
              105.82629368036137,
              21.017369257934646
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 61, Hoàng Cầu",
          "maTaiSan": "04.O12.DOPP.91",
          "duongKinh": 100,
          "chieudaiQL": 136.96,
          "chatLieu": "G-DI",
          "from_latitude": 21.016282136177043,
          "to_latitude": 21.017369257934646,
          "to_longitude": 105.82629368036137,
          "from_longitude": 105.82566525394213
        }
      },
      {
        "id": 91,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82490309968718,
            21.015555277257725,
            105.82599300878674,
            21.016149469760137
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82490309968718,
              21.016149469760137
            ],
            [
              105.82492649041187,
              21.0161473981475
            ],
            [
              105.82515315658652,
              21.01601223576199
            ],
            [
              105.82515783960599,
              21.016009665950445
            ],
            [
              105.82517972118028,
              21.015997657103515
            ],
            [
              105.82518191067824,
              21.0159964551283
            ],
            [
              105.82525240622803,
              21.015957766716426
            ],
            [
              105.82531478822123,
              21.015923529896238
            ],
            [
              105.8255144639251,
              21.01581394366363
            ],
            [
              105.82553589181806,
              21.015802184457584
            ],
            [
              105.82567391680176,
              21.015729516685138
            ],
            [
              105.82577088967655,
              21.01567846265462
            ],
            [
              105.82577382853928,
              21.01567691561527
            ],
            [
              105.8257880189698,
              21.015668338347233
            ],
            [
              105.82579806569652,
              21.015662266869633
            ],
            [
              105.82599300878674,
              21.015555277257725
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 19, Trần Quang Diệu",
          "maTaiSan": "04.O12.DOPP.92",
          "duongKinh": 100,
          "chieudaiQL": 130.63,
          "chatLieu": "G-DI",
          "from_latitude": 21.016149469760137,
          "to_latitude": 21.015555277257725,
          "to_longitude": 105.82599300878674,
          "from_longitude": 105.82490309968718
        }
      },
      {
        "id": 92,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82401273935132,
            21.01578587882939,
            105.82460690313762,
            21.01687379284506
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82401273935132,
              21.01578587882939
            ],
            [
              105.82403780800796,
              21.01584585447708
            ],
            [
              105.82405469365267,
              21.015875574641747
            ],
            [
              105.82406077858059,
              21.01588725007805
            ],
            [
              105.82416805316399,
              21.01606861401685
            ],
            [
              105.8241866068289,
              21.016104279250147
            ],
            [
              105.8241871122215,
              21.016105222479684
            ],
            [
              105.82418763134889,
              21.016106214416904
            ],
            [
              105.82422761419765,
              21.016183016237907
            ],
            [
              105.82432745748957,
              21.016375103938035
            ],
            [
              105.82441094242822,
              21.01653572837491
            ],
            [
              105.82444008863794,
              21.016584601604695
            ],
            [
              105.82444799363282,
              21.01659785336623
            ],
            [
              105.82450687447607,
              21.01669658508286
            ],
            [
              105.82451510671346,
              21.016710392545335
            ],
            [
              105.82455500371573,
              21.01677729171906
            ],
            [
              105.82458718839969,
              21.01683711355171
            ],
            [
              105.82459580617765,
              21.016853156432074
            ],
            [
              105.82460690313762,
              21.01687379284506
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 25, Võ Văn Dũng",
          "maTaiSan": "04.O12.DOPP.93",
          "duongKinh": 100,
          "chieudaiQL": 130.49,
          "chatLieu": "G-CI",
          "from_latitude": 21.01578587882939,
          "to_latitude": 21.01687379284506,
          "to_longitude": 105.82460690313762,
          "from_longitude": 105.82401273935132
        }
      },
      {
        "id": 93,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82566525394213,
            21.015935175465717,
            105.82634314807682,
            21.016282136177043
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82566525394213,
              21.016282136177043
            ],
            [
              105.82568775185302,
              21.016270778209545
            ],
            [
              105.82572998015274,
              21.01624945944081
            ],
            [
              105.82577171920543,
              21.016228386907656
            ],
            [
              105.82597478207768,
              21.01613454498919
            ],
            [
              105.82599126678896,
              21.01612667011332
            ],
            [
              105.82611376227142,
              21.016068151449332
            ],
            [
              105.82612738542241,
              21.016060492778664
            ],
            [
              105.82634061952987,
              21.015936643747647
            ],
            [
              105.82634314807682,
              21.015935175465717
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 61, Hoàng Cầu",
          "maTaiSan": "04.O12.DOPP.94",
          "duongKinh": 100,
          "chieudaiQL": 78.71,
          "chatLieu": "G-DI",
          "from_latitude": 21.016282136177043,
          "to_latitude": 21.015935175465717,
          "to_longitude": 105.82634314807682,
          "from_longitude": 105.82566525394213
        }
      },
      {
        "id": 94,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82028888132213,
            21.01351170379588,
            105.82252924773269,
            21.015194652416003
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82252924773269,
              21.01351170379588
            ],
            [
              105.82251052534566,
              21.01353197510294
            ],
            [
              105.82251047072539,
              21.01353201330252
            ],
            [
              105.8225103712771,
              21.01353212126727
            ],
            [
              105.82248273125843,
              21.013561936724415
            ],
            [
              105.82244285469459,
              21.013624408784736
            ],
            [
              105.82241514166526,
              21.013653545357744
            ],
            [
              105.82231698486154,
              21.01375708971767
            ],
            [
              105.8223056958146,
              21.01376886997002
            ],
            [
              105.8223055388797,
              21.013769019760932
            ],
            [
              105.82229987959957,
              21.013774937958193
            ],
            [
              105.82229971508828,
              21.013775109463158
            ],
            [
              105.82214680169288,
              21.01393505481481
            ],
            [
              105.82209501107349,
              21.01398763622724
            ],
            [
              105.82209482070911,
              21.013987829534383
            ],
            [
              105.82185098820659,
              21.01423538795776
            ],
            [
              105.82184557862395,
              21.01423860518923
            ],
            [
              105.8218454634923,
              21.014238659938982
            ],
            [
              105.82184531295827,
              21.014238749182372
            ],
            [
              105.82181091379067,
              21.014259191883266
            ],
            [
              105.8216433075957,
              21.014394511741433
            ],
            [
              105.82149245632583,
              21.014521279176222
            ],
            [
              105.82147316366958,
              21.01453372305349
            ],
            [
              105.8214731281681,
              21.01453373948299
            ],
            [
              105.82146407586369,
              21.014534170674555
            ],
            [
              105.82146405091844,
              21.014534182536945
            ],
            [
              105.82125135580725,
              21.014542894008425
            ],
            [
              105.82123920720721,
              21.014544058199345
            ],
            [
              105.8212205655583,
              21.014545923924853
            ],
            [
              105.82121978500929,
              21.014546022531274
            ],
            [
              105.82107954090293,
              21.01455934639317
            ],
            [
              105.82104754756635,
              21.014582465336854
            ],
            [
              105.82102908787078,
              21.01459578308184
            ],
            [
              105.82102903898162,
              21.01459581402749
            ],
            [
              105.82096023748663,
              21.01464551099944
            ],
            [
              105.82096020486584,
              21.014645526511703
            ],
            [
              105.82096015113741,
              21.014645552061317
            ],
            [
              105.82093197825779,
              21.014665978997115
            ],
            [
              105.82081538455763,
              21.014750231847405
            ],
            [
              105.82074716272372,
              21.01482590365208
            ],
            [
              105.82064295054971,
              21.014909756141936
            ],
            [
              105.82064273415143,
              21.014909938734377
            ],
            [
              105.82046227109026,
              21.015062184539097
            ],
            [
              105.82046216087566,
              21.015062259135224
            ],
            [
              105.82036577177631,
              21.01513273939413
            ],
            [
              105.82036574683083,
              21.015132751256374
            ],
            [
              105.82036572963005,
              21.01513277211353
            ],
            [
              105.8203656683296,
              21.015132820280154
            ],
            [
              105.82031851721949,
              21.015170129619683
            ],
            [
              105.82028888132213,
              21.015194652416003
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Đặng Tiến Đông",
          "maTaiSan": "04.O12.DOPP.95",
          "duongKinh": 150,
          "chieudaiQL": 304.95,
          "chatLieu": "G-DI",
          "from_latitude": 21.01351170379588,
          "to_latitude": 21.015194652416003,
          "to_longitude": 105.82028888132213,
          "from_longitude": 105.82252924773269
        }
      },
      {
        "id": 95,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82180041716592,
            21.011431745405353,
            105.82247154421538,
            21.01184175461688
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82180041716592,
              21.011431745405353
            ],
            [
              105.82180261197311,
              21.011433802292213
            ],
            [
              105.82181433435211,
              21.011445838082086
            ],
            [
              105.8218314539936,
              21.01146208303547
            ],
            [
              105.82187635891695,
              21.01150597614763
            ],
            [
              105.82192111097739,
              21.011547069981177
            ],
            [
              105.82204921369988,
              21.011655911435806
            ],
            [
              105.82205039920568,
              21.011656516289953
            ],
            [
              105.82205197055546,
              21.011657317989986
            ],
            [
              105.82205337360773,
              21.01165830205173
            ],
            [
              105.82205446862508,
              21.011659069923894
            ],
            [
              105.82216824090946,
              21.01176070863367
            ],
            [
              105.82226312967902,
              21.01184175461688
            ],
            [
              105.82233752174207,
              21.011773576299436
            ],
            [
              105.82233893736074,
              21.011772816166516
            ],
            [
              105.82233928743027,
              21.01177262840989
            ],
            [
              105.82234054130315,
              21.011771841961853
            ],
            [
              105.8223410608744,
              21.011771516095532
            ],
            [
              105.82247154421538,
              21.011653199462543
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 98, Thái Hà",
          "maTaiSan": "04.O12.DOPP.96",
          "duongKinh": 100,
          "chieudaiQL": 96.26,
          "chatLieu": "G-DI",
          "from_latitude": 21.011431745405353,
          "to_latitude": 21.011653199462543,
          "to_longitude": 105.82247154421538,
          "from_longitude": 105.82180041716592
        }
      },
      {
        "id": 96,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82636178128945,
            21.013331967497162,
            105.82681656034565,
            21.01406792686631
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82636178128945,
              21.013331967497162
            ],
            [
              105.82636552527362,
              21.01335155745128
            ],
            [
              105.82638036878139,
              21.013381429113373
            ],
            [
              105.8263834376717,
              21.013387604920354
            ],
            [
              105.82642554772333,
              21.013446956414068
            ],
            [
              105.82650985572786,
              21.013577754040874
            ],
            [
              105.8265536242396,
              21.013645659123014
            ],
            [
              105.82666877343325,
              21.01382387794455
            ],
            [
              105.82667034418446,
              21.013826308119985
            ],
            [
              105.82667298992489,
              21.013830403137387
            ],
            [
              105.82668009083108,
              21.01384139341365
            ],
            [
              105.82675511091207,
              21.01394967524726
            ],
            [
              105.82680642350493,
              21.014030222180757
            ],
            [
              105.82681102092242,
              21.014037443712304
            ],
            [
              105.82681608616903,
              21.014065318847294
            ],
            [
              105.82681656034565,
              21.01406792686631
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 90, Tây Sơn",
          "maTaiSan": "04.O12.DOPP.97",
          "duongKinh": 100,
          "chieudaiQL": 93.56,
          "chatLieu": "G-DI",
          "from_latitude": 21.013331967497162,
          "to_latitude": 21.01406792686631,
          "to_longitude": 105.82681656034565,
          "from_longitude": 105.82636178128945
        }
      },
      {
        "id": 97,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82098828478493,
            21.0123470221151,
            105.8214016965942,
            21.012757336574396
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8214016965942,
              21.0123470221151
            ],
            [
              105.8213924551195,
              21.012353813834988
            ],
            [
              105.82138954628633,
              21.012356274719586
            ],
            [
              105.82138951274865,
              21.012356298365386
            ],
            [
              105.82136504792064,
              21.012376963077458
            ],
            [
              105.82129342916365,
              21.01244793031326
            ],
            [
              105.82126124036252,
              21.01248234870144
            ],
            [
              105.82121107320262,
              21.01253731285451
            ],
            [
              105.82119078449753,
              21.012559823454215
            ],
            [
              105.82119064873423,
              21.0125599749484
            ],
            [
              105.82118942299347,
              21.01256063118775
            ],
            [
              105.82118896070537,
              21.01256087909667
            ],
            [
              105.8211648479271,
              21.012585418733163
            ],
            [
              105.82116048231173,
              21.012590086002838
            ],
            [
              105.82106054378718,
              21.012679389845037
            ],
            [
              105.82104146229014,
              21.01269997434128
            ],
            [
              105.82101711837086,
              21.012728162304498
            ],
            [
              105.8210147879762,
              21.01273086065026
            ],
            [
              105.8210137499126,
              21.012732063338476
            ],
            [
              105.82101065873684,
              21.012735131162174
            ],
            [
              105.82098828478493,
              21.012757336574396
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 42, Trung Liệt",
          "maTaiSan": "04.O12.DOPP.98",
          "duongKinh": 100,
          "chieudaiQL": 62.61,
          "chatLieu": "G-DI",
          "from_latitude": 21.0123470221151,
          "to_latitude": 21.012757336574396,
          "to_longitude": 105.82098828478493,
          "from_longitude": 105.8214016965942
        }
      },
      {
        "id": 98,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82500768206658,
            21.01078128373874,
            105.82518988883781,
            21.010869964278204
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82518988883781,
              21.01078128373874
            ],
            [
              105.82504339978337,
              21.010861361503817
            ],
            [
              105.82503374815906,
              21.010866637983256
            ],
            [
              105.82502821912796,
              21.010869964278204
            ],
            [
              105.82500768206658,
              21.01081969993014
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Đặng Tiến Đông",
          "maTaiSan": "04.O12.DOPP.99",
          "duongKinh": 150,
          "chieudaiQL": 30.71,
          "chatLieu": "G-DI",
          "from_latitude": 21.01078128373874,
          "to_latitude": 21.01081969993014,
          "to_longitude": 105.82500768206658,
          "from_longitude": 105.82518988883781
        }
      },
      {
        "id": 99,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.825610587556,
            21.018062273942565,
            105.82910035871215,
            21.020335234519607
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.825610587556,
              21.020335234519607
            ],
            [
              105.82561065663687,
              21.02033520166819
            ],
            [
              105.8256319587746,
              21.020326346918903
            ],
            [
              105.82563198372044,
              21.020326335055874
            ],
            [
              105.82575373051993,
              21.020275688210926
            ],
            [
              105.82588179163075,
              21.020206359014548
            ],
            [
              105.82590530198688,
              21.020193410027378
            ],
            [
              105.82592171818202,
              21.02018436761124
            ],
            [
              105.82592174791763,
              21.02018435211201
            ],
            [
              105.82592832608705,
              21.020180727139973
            ],
            [
              105.82592835678957,
              21.02018071253928
            ],
            [
              105.82593078896107,
              21.020179372988075
            ],
            [
              105.82593789556799,
              21.0201754564176
            ],
            [
              105.82593792627053,
              21.02017544181691
            ],
            [
              105.82594653764147,
              21.020170696907467
            ],
            [
              105.82594656834405,
              21.020170682306766
            ],
            [
              105.82595825254215,
              21.02016424608936
            ],
            [
              105.82617132339425,
              21.020046893048026
            ],
            [
              105.82631694205442,
              21.01996903490343
            ],
            [
              105.82631697275687,
              21.019969020302682
            ],
            [
              105.82636043929075,
              21.01993639418651
            ],
            [
              105.82642961514027,
              21.019889600706364
            ],
            [
              105.82654285644162,
              21.019826015284153
            ],
            [
              105.82654288714404,
              21.019826000683356
            ],
            [
              105.82654415110198,
              21.01982529092896
            ],
            [
              105.82654660293156,
              21.019824378499344
            ],
            [
              105.82654663363401,
              21.01982436389855
            ],
            [
              105.8265487062642,
              21.019823592408212
            ],
            [
              105.82654873696659,
              21.019823577807415
            ],
            [
              105.82655283803747,
              21.01982204588013
            ],
            [
              105.8266463479529,
              21.01977565974657
            ],
            [
              105.82683109820923,
              21.019676583911938
            ],
            [
              105.82683112891159,
              21.019676569311095
            ],
            [
              105.82685197555443,
              21.019665389014968
            ],
            [
              105.82685200625679,
              21.01966537441412
            ],
            [
              105.82692119293775,
              21.01962827229712
            ],
            [
              105.82717461721633,
              21.01948434858018
            ],
            [
              105.82717464695165,
              21.01948433308075
            ],
            [
              105.82717935158728,
              21.01948165833321
            ],
            [
              105.82717938228954,
              21.019481643732313
            ],
            [
              105.82718226202354,
              21.01948000482615
            ],
            [
              105.82718229272582,
              21.019479990225243
            ],
            [
              105.82719535758048,
              21.019472570824753
            ],
            [
              105.82747185130518,
              21.019320492172994
            ],
            [
              105.82747188200736,
              21.019320477572027
            ],
            [
              105.82747506796456,
              21.019318723365043
            ],
            [
              105.82747509866674,
              21.01931870876408
            ],
            [
              105.82747808322034,
              21.01931706482703
            ],
            [
              105.82747811392257,
              21.01931705022608
            ],
            [
              105.82748698513564,
              21.01931216939632
            ],
            [
              105.82748701487088,
              21.01931215389684
            ],
            [
              105.82748713667287,
              21.019312087368878
            ],
            [
              105.82761184243356,
              21.019247235163604
            ],
            [
              105.82767629388916,
              21.019206112046465
            ],
            [
              105.8276763245913,
              21.01920609744548
            ],
            [
              105.82779902442414,
              21.019127813970243
            ],
            [
              105.82779905512629,
              21.01912779936923
            ],
            [
              105.82780060803084,
              21.019126808199772
            ],
            [
              105.82780338807655,
              21.019123842027224
            ],
            [
              105.82780341877864,
              21.019123827426203
            ],
            [
              105.82780490974957,
              21.019122246751923
            ],
            [
              105.82790585971485,
              21.019059155618013
            ],
            [
              105.82807967550197,
              21.01894560872421
            ],
            [
              105.82807970427022,
              21.018945592326123
            ],
            [
              105.82808531914169,
              21.01894191867215
            ],
            [
              105.82808534984375,
              21.018941904071095
            ],
            [
              105.82810354313362,
              21.0189300139624
            ],
            [
              105.82810357383566,
              21.01892999936134
            ],
            [
              105.82810813534181,
              21.018927019092278
            ],
            [
              105.82834568139855,
              21.0187678215696
            ],
            [
              105.82837011055486,
              21.018741392559225
            ],
            [
              105.82838887076964,
              21.01861968100905
            ],
            [
              105.82839072909078,
              21.01860763196581
            ],
            [
              105.82839073373584,
              21.018607602136758
            ],
            [
              105.82839500306396,
              21.018601013994743
            ],
            [
              105.8283950173682,
              21.018600991344446
            ],
            [
              105.82839775103348,
              21.018596772614945
            ],
            [
              105.82843349186825,
              21.01854162194459
            ],
            [
              105.82861313933203,
              21.01842482883831
            ],
            [
              105.82861925754709,
              21.018420856457716
            ],
            [
              105.8286346512593,
              21.018410848704544
            ],
            [
              105.82871584980515,
              21.018344673965775
            ],
            [
              105.82871588050699,
              21.01834465936461
            ],
            [
              105.8287179509299,
              21.018342971988904
            ],
            [
              105.82876644214684,
              21.018313034993355
            ],
            [
              105.82876647284868,
              21.018313020392174
            ],
            [
              105.82877273977104,
              21.018309152055895
            ],
            [
              105.82885116512301,
              21.0182600779819
            ],
            [
              105.82891451477404,
              21.018209455141204
            ],
            [
              105.82891454547591,
              21.018209440539998
            ],
            [
              105.8289537911312,
              21.018178086222264
            ],
            [
              105.8290738572405,
              21.018083217027144
            ],
            [
              105.82907388503652,
              21.01808319882717
            ],
            [
              105.82910035871215,
              21.018062273942565
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Đông Các",
          "maTaiSan": "04.O12.DOPP.100",
          "duongKinh": 150,
          "chieudaiQL": 451.72,
          "chatLieu": "G-DI",
          "from_latitude": 21.020335234519607,
          "to_latitude": 21.018062273942565,
          "to_longitude": 105.82910035871215,
          "from_longitude": 105.825610587556
        }
      },
      {
        "id": 100,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82906709324524,
            21.017843396533028,
            105.8300116066342,
            21.01894028127678
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8300116066342,
              21.018795297256297
            ],
            [
              105.82966702705862,
              21.018893447176723
            ],
            [
              105.8296110254397,
              21.018909401195707
            ],
            [
              105.82958935771528,
              21.018911906736577
            ],
            [
              105.82940548426238,
              21.01894028127678
            ],
            [
              105.82939823667388,
              21.018933985006157
            ],
            [
              105.82907967745416,
              21.018617324456446
            ],
            [
              105.82906709324524,
              21.018604815603627
            ],
            [
              105.8291455145799,
              21.018199880609174
            ],
            [
              105.82915919604451,
              21.018137516671846
            ],
            [
              105.82910035871215,
              21.018062273942565
            ],
            [
              105.82910038748005,
              21.018062257544287
            ],
            [
              105.8291325490191,
              21.018039390095556
            ],
            [
              105.82917471212785,
              21.018009416992104
            ],
            [
              105.82921339605653,
              21.017981919416936
            ],
            [
              105.8293616293755,
              21.017876550020304
            ],
            [
              105.82941529321454,
              21.017843396533028
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D200_Nguyễn Lương Bằng",
          "maTaiSan": "04.O12.DOPP.101",
          "duongKinh": 200,
          "chieudaiQL": 222.52,
          "chatLieu": "ST",
          "from_latitude": 21.018795297256297,
          "to_latitude": 21.017843396533028,
          "to_longitude": 105.82941529321454,
          "from_longitude": 105.8300116066342
        }
      },
      {
        "id": 101,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82587809032093,
            21.013577754040874,
            105.82650985572786,
            21.014245558033092
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82650985572786,
              21.013577754040874
            ],
            [
              105.82650394323689,
              21.013580805860176
            ],
            [
              105.8260674858652,
              21.013806098238476
            ],
            [
              105.82603804583151,
              21.01382177034156
            ],
            [
              105.8260334650971,
              21.013824208711245
            ],
            [
              105.82602977356969,
              21.01382776547393
            ],
            [
              105.82602862917899,
              21.013828868449785
            ],
            [
              105.82602065697058,
              21.01383607425457
            ],
            [
              105.82602884874284,
              21.013851622362203
            ],
            [
              105.82594884745112,
              21.013901456061657
            ],
            [
              105.82593191168267,
              21.01391332176525
            ],
            [
              105.8259192090528,
              21.01392216278768
            ],
            [
              105.82588322082515,
              21.01394721233387
            ],
            [
              105.82587809032093,
              21.01395078240097
            ],
            [
              105.82592433000966,
              21.0140410774686
            ],
            [
              105.82592759526122,
              21.0140474519433
            ],
            [
              105.82592899063428,
              21.014050177428885
            ],
            [
              105.82593106050633,
              21.01406306996743
            ],
            [
              105.82592285397178,
              21.014097952660098
            ],
            [
              105.8258985079027,
              21.014141517831604
            ],
            [
              105.82589277578809,
              21.0141544500497
            ],
            [
              105.8259070359081,
              21.014208175174026
            ],
            [
              105.8259074926864,
              21.014209897215505
            ],
            [
              105.8259085061098,
              21.014212261458372
            ],
            [
              105.82590905460357,
              21.01421351969958
            ],
            [
              105.82590943370218,
              21.014214411150032
            ],
            [
              105.82592282402481,
              21.014245558033092
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D115_Ngõ 90, Tây Sơn",
          "maTaiSan": "04.O12.DOPP.102",
          "duongKinh": 115,
          "chieudaiQL": 92.93,
          "chatLieu": "HDPE",
          "from_latitude": 21.013577754040874,
          "to_latitude": 21.014245558033092,
          "to_longitude": 105.82592282402481,
          "from_longitude": 105.82650985572786
        }
      },
      {
        "id": 102,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82318027875583,
            21.014057455020954,
            105.82330236024953,
            21.01416400806922
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82318027875583,
              21.014078965497138
            ],
            [
              105.82318031713285,
              21.01407894724691
            ],
            [
              105.82322716247074,
              21.014057455020954
            ],
            [
              105.82326400106629,
              21.014061241033637
            ],
            [
              105.82326939954801,
              21.014065297496153
            ],
            [
              105.82328219014379,
              21.014074906452574
            ],
            [
              105.82329393466259,
              21.014083730146538
            ],
            [
              105.82330236024953,
              21.014112833790183
            ],
            [
              105.82329243312276,
              21.01416400806922
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D200_Trần Quang Diệu",
          "maTaiSan": "04.O12.DOPP.103",
          "duongKinh": 200,
          "chieudaiQL": 22.36,
          "chatLieu": "G-CI",
          "from_latitude": 21.014078965497138,
          "to_latitude": 21.01416400806922,
          "to_longitude": 105.82329243312276,
          "from_longitude": 105.82318027875583
        }
      },
      {
        "id": 103,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82400075843057,
            21.016582558983544,
            105.82517493280119,
            21.017184563814563
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82400075843057,
              21.017184563814563
            ],
            [
              105.82400082750542,
              21.017184530060586
            ],
            [
              105.82460687819224,
              21.01687380470793
            ],
            [
              105.82460690313762,
              21.01687379284506
            ],
            [
              105.82517493280119,
              21.016582558983544
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D110_Ngõ 61, Hoàng Cầu",
          "maTaiSan": "04.O12.DOPP.104",
          "duongKinh": 110,
          "chieudaiQL": 139.08,
          "chatLieu": "HDPE",
          "from_latitude": 21.017184563814563,
          "to_latitude": 21.016582558983544,
          "to_longitude": 105.82517493280119,
          "from_longitude": 105.82400075843057
        }
      },
      {
        "id": 104,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82561065663687,
            21.02033520166819,
            105.82574439829774,
            21.020490534110383
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82561065663687,
              21.02033520166819
            ],
            [
              105.82569438789182,
              21.020490268364963
            ],
            [
              105.82573625866462,
              21.02047368872525
            ],
            [
              105.82573629801477,
              21.02047367227617
            ],
            [
              105.82574439829774,
              21.020490534110383
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D200_Hoàng Cầu",
          "maTaiSan": "04.O12.DOPP.105",
          "duongKinh": 200,
          "chieudaiQL": 24.97,
          "chatLieu": "G-CI",
          "from_latitude": 21.02033520166819,
          "to_latitude": 21.020490534110383,
          "to_longitude": 105.82574439829774,
          "from_longitude": 105.82561065663687
        }
      },
      {
        "id": 105,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82411996316317,
            21.020731241902126,
            105.82473368596933,
            21.021052856527998
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82411996316317,
              21.021052856527998
            ],
            [
              105.82473368596933,
              21.020731241902126
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 16, Hoàng Cầu",
          "maTaiSan": "04.O12.DOPP.106",
          "duongKinh": 100,
          "chieudaiQL": 73.06,
          "chatLieu": "G-CI",
          "from_latitude": 21.021052856527998,
          "to_latitude": 21.020731241902126,
          "to_longitude": 105.82473368596933,
          "from_longitude": 105.82411996316317
        }
      },
      {
        "id": 106,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8192669601149,
            21.01996192558392,
            105.81964590259717,
            21.020010779437765
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8192669601149,
              21.020010779437765
            ],
            [
              105.81929034082428,
              21.02000392875451
            ],
            [
              105.81964590259717,
              21.01996192558392
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D110_Ngõ 29, Láng Hạ",
          "maTaiSan": "04.O12.DOPP.107",
          "duongKinh": 110,
          "chieudaiQL": 40.44,
          "chatLieu": "HDPE",
          "from_latitude": 21.020010779437765,
          "to_latitude": 21.01996192558392,
          "to_longitude": 105.81964590259717,
          "from_longitude": 105.8192669601149
        }
      },
      {
        "id": 107,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82034315451524,
            21.020021698111677,
            105.82050746634827,
            21.020286845296027
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82034315451524,
              21.020021698111677
            ],
            [
              105.82035121103941,
              21.02003278520189
            ],
            [
              105.82035270661599,
              21.020034843668206
            ],
            [
              105.82042509037548,
              21.02013445915947
            ],
            [
              105.8204936999075,
              21.02025947258307
            ],
            [
              105.82049732265223,
              21.020266675500892
            ],
            [
              105.82050746634827,
              21.020286845296027
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngách 82/41, Nguyễn Phúc Lai",
          "maTaiSan": "04.O12.DOPP.108",
          "duongKinh": 100,
          "chieudaiQL": 30.08,
          "chatLieu": "G-CI",
          "from_latitude": 21.020021698111677,
          "to_latitude": 21.020286845296027,
          "to_longitude": 105.82050746634827,
          "from_longitude": 105.82034315451524
        }
      },
      {
        "id": 108,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81999819159132,
            21.02007156995608,
            105.82147690991785,
            21.020490097430958
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82147690991785,
              21.02007156995608
            ],
            [
              105.82144776870227,
              21.02008187017764
            ],
            [
              105.82133629141056,
              21.020121271609526
            ],
            [
              105.821214259245,
              21.02013571087903
            ],
            [
              105.82118848695106,
              21.02014006420774
            ],
            [
              105.82118716016528,
              21.0201403731939
            ],
            [
              105.82118140819168,
              21.02014171154404
            ],
            [
              105.82094206849561,
              21.020197391895156
            ],
            [
              105.8209398767561,
              21.02019785047334
            ],
            [
              105.82093101465463,
              21.020199701503106
            ],
            [
              105.82078807068388,
              21.0202184576538
            ],
            [
              105.82077026492479,
              21.02022079350911
            ],
            [
              105.82076167584827,
              21.020222613406254
            ],
            [
              105.82070910462417,
              21.020233752750265
            ],
            [
              105.82050746634827,
              21.020286845296027
            ],
            [
              105.82048465052212,
              21.020292851614226
            ],
            [
              105.82046833629236,
              21.020299145408085
            ],
            [
              105.82021620132709,
              21.02039641140532
            ],
            [
              105.82021353747908,
              21.020397438573852
            ],
            [
              105.82006282341152,
              21.020462288014905
            ],
            [
              105.81999819159132,
              21.020490097430958
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 82, Nguyễn Phúc Lai",
          "maTaiSan": "04.O12.DOPP.109",
          "duongKinh": 100,
          "chieudaiQL": 161.68,
          "chatLieu": "G-CI",
          "from_latitude": 21.02007156995608,
          "to_latitude": 21.020490097430958,
          "to_longitude": 105.81999819159132,
          "from_longitude": 105.82147690991785
        }
      },
      {
        "id": 109,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82536520921742,
            21.02053948574142,
            105.82570598310564,
            21.021196867754984
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82547876594258,
              21.02053948574142
            ],
            [
              105.82547871319474,
              21.02053951490061
            ],
            [
              105.82539580895468,
              21.02058482378667
            ],
            [
              105.82539283963229,
              21.020608817932757
            ],
            [
              105.82536520921742,
              21.02073347882208
            ],
            [
              105.82563641354851,
              21.021077611175826
            ],
            [
              105.82564016967515,
              21.021089248165886
            ],
            [
              105.82564084300272,
              21.02109133496143
            ],
            [
              105.8256409447759,
              21.021091648789902
            ],
            [
              105.82570276258497,
              21.021191657325957
            ],
            [
              105.82570598310564,
              21.021196867754984
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D300_Hoàng Cầu",
          "maTaiSan": "04.O12.DOPP.110",
          "duongKinh": 300,
          "chieudaiQL": 69.55,
          "chatLieu": "G-CI",
          "from_latitude": 21.02053948574142,
          "to_latitude": 21.021196867754984,
          "to_longitude": 105.82570598310564,
          "from_longitude": 105.82547876594258
        }
      },
      {
        "id": 110,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8255132403323,
            21.020490534110383,
            105.82574439829774,
            21.020593357519434
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82574439829774,
              21.020490534110383
            ],
            [
              105.82560385629152,
              21.020553050242246
            ],
            [
              105.82560291873965,
              21.02055346666374
            ],
            [
              105.8255132403323,
              21.020593357519434
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D300_Hoàng Cầu",
          "maTaiSan": "04.O12.DOPP.111",
          "duongKinh": 300,
          "chieudaiQL": 19.85,
          "chatLieu": "G-CI",
          "from_latitude": 21.020490534110383,
          "to_latitude": 21.020593357519434,
          "to_longitude": 105.8255132403323,
          "from_longitude": 105.82574439829774
        }
      },
      {
        "id": 111,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82564084300272,
            21.021071994056985,
            105.82576028315077,
            21.021178784413042
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82564084300272,
              21.02109133496143
            ],
            [
              105.82564551875797,
              21.0210895157494
            ],
            [
              105.82564865670601,
              21.021094755483723
            ],
            [
              105.82565143842908,
              21.021093127916888
            ],
            [
              105.82568980772461,
              21.021071994056985
            ],
            [
              105.82575333164263,
              21.021168251325577
            ],
            [
              105.82576028315077,
              21.021178784413042
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_La Thành",
          "maTaiSan": "04.O12.DOPP.112",
          "duongKinh": 150,
          "chieudaiQL": 11.13,
          "chatLieu": "G-DI",
          "from_latitude": 21.02109133496143,
          "to_latitude": 21.021178784413042,
          "to_longitude": 105.82576028315077,
          "from_longitude": 105.82564084300272
        }
      },
      {
        "id": 112,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82247154421538,
            21.011405019429482,
            105.822854407741,
            21.01169335986739
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82247154421538,
              21.011653199462543
            ],
            [
              105.822521576115,
              21.01169335986739
            ],
            [
              105.82255620927457,
              21.011657822077748
            ],
            [
              105.8225589593012,
              21.011654999747563
            ],
            [
              105.82256896366766,
              21.011644734080186
            ],
            [
              105.82264807648671,
              21.011581804947046
            ],
            [
              105.82265828690592,
              21.011573683442627
            ],
            [
              105.82269819152263,
              21.011539065817832
            ],
            [
              105.82269859361331,
              21.011538717034558
            ],
            [
              105.82270000265338,
              21.011537108800354
            ],
            [
              105.82270109146226,
              21.01153586701819
            ],
            [
              105.8228145436233,
              21.01143806347214
            ],
            [
              105.82281583896763,
              21.011436947142318
            ],
            [
              105.82282141956419,
              21.011432135762966
            ],
            [
              105.822854407741,
              21.011405019429482
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 98, Thái Hà",
          "maTaiSan": "04.O12.DOPP.113",
          "duongKinh": 100,
          "chieudaiQL": 55.69,
          "chatLieu": "G-DI",
          "from_latitude": 21.011653199462543,
          "to_latitude": 21.011405019429482,
          "to_longitude": 105.822854407741,
          "from_longitude": 105.82247154421538
        }
      },
      {
        "id": 113,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8215970665673,
            21.016273906107507,
            105.82320533766098,
            21.01632659210008
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8215970665673,
              21.0163177067592
            ],
            [
              105.82205397198669,
              21.01632659210008
            ],
            [
              105.82229141111883,
              21.01630602072317
            ],
            [
              105.82264336504738,
              21.01627551892061
            ],
            [
              105.82288347856874,
              21.016274829159812
            ],
            [
              105.82320533766098,
              21.016273906107507
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D200_Hoàng Cầu",
          "maTaiSan": "04.O12.DOPP.114",
          "duongKinh": 200,
          "chieudaiQL": 167.46,
          "chatLieu": "G-DI",
          "from_latitude": 21.0163177067592,
          "to_latitude": 21.016273906107507,
          "to_longitude": 105.82320533766098,
          "from_longitude": 105.8215970665673
        }
      },
      {
        "id": 114,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82294888472387,
            21.014463175189647,
            105.82357359400073,
            21.01518005058345
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82294888472387,
              21.01518005058345
            ],
            [
              105.82302027794323,
              21.015053695183816
            ],
            [
              105.82306608542174,
              21.015005334314743
            ],
            [
              105.82306934988331,
              21.01500159000254
            ],
            [
              105.82308057612383,
              21.014988701745022
            ],
            [
              105.82315882850389,
              21.01492681793786
            ],
            [
              105.82315886112448,
              21.01492680242518
            ],
            [
              105.82324565458703,
              21.014821301087782
            ],
            [
              105.82325920590151,
              21.01480650479262
            ],
            [
              105.82325923084667,
              21.01480649292996
            ],
            [
              105.82329173613279,
              21.014771023090933
            ],
            [
              105.82329176107788,
              21.014771011228273
            ],
            [
              105.82335216664843,
              21.014705120273934
            ],
            [
              105.82342013430612,
              21.014652443034453
            ],
            [
              105.82342015925124,
              21.014652431171786
            ],
            [
              105.82357359400073,
              21.014463175189647
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 84, Trần Quang Diệu",
          "maTaiSan": "04.O12.DOPP.115",
          "duongKinh": 100,
          "chieudaiQL": 108.38,
          "chatLieu": "G-CI",
          "from_latitude": 21.01518005058345,
          "to_latitude": 21.014463175189647,
          "to_longitude": 105.82357359400073,
          "from_longitude": 105.82294888472387
        }
      },
      {
        "id": 115,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82709877713995,
            21.013944689192858,
            105.82778826250706,
            21.01505070782234
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82778826250706,
              21.01505070782234
            ],
            [
              105.82771220618879,
              21.014943318503896
            ],
            [
              105.8276557374287,
              21.014866018764696
            ],
            [
              105.82763071517931,
              21.01483176642308
            ],
            [
              105.8274694096843,
              21.01456201409972
            ],
            [
              105.82739330468303,
              21.01443524704678
            ],
            [
              105.82723559039,
              21.014172546550846
            ],
            [
              105.82718569079553,
              21.01408889662752
            ],
            [
              105.82711188763797,
              21.013965172044312
            ],
            [
              105.82709950485571,
              21.01394582642971
            ],
            [
              105.82709877713995,
              21.013944689192858
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Nguyễn Lương Bằng",
          "maTaiSan": "04.O12.DOPP.116",
          "duongKinh": 150,
          "chieudaiQL": 144.08,
          "chatLieu": "G-DI",
          "from_latitude": 21.01505070782234,
          "to_latitude": 21.013944689192858,
          "to_longitude": 105.82709877713995,
          "from_longitude": 105.82778826250706
        }
      },
      {
        "id": 116,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81993455507333,
            21.019722026933888,
            105.81999819159132,
            21.020490097430958
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81999819159132,
              21.020490097430958
            ],
            [
              105.81995175468553,
              21.020336915477678
            ],
            [
              105.81993753618198,
              21.02029000810514
            ],
            [
              105.81993665798424,
              21.020236362485093
            ],
            [
              105.81993620300553,
              21.020208570589336
            ],
            [
              105.81993594888543,
              21.02019305889741
            ],
            [
              105.81993455507333,
              21.0201079202503
            ],
            [
              105.81993939923242,
              21.020047517103954
            ],
            [
              105.81994672889451,
              21.019956109561033
            ],
            [
              105.81994719180537,
              21.019950341130865
            ],
            [
              105.81994746556822,
              21.01994692561457
            ],
            [
              105.81994824633,
              21.01993718416476
            ],
            [
              105.81995457010113,
              21.019858329093793
            ],
            [
              105.81996338881008,
              21.01978995748897
            ],
            [
              105.81996559624615,
              21.01977284154206
            ],
            [
              105.81996601799027,
              21.019769572538316
            ],
            [
              105.81996649049779,
              21.0197659112895
            ],
            [
              105.81997215028183,
              21.019722026933888
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 82, Nguyễn Phúc Lai",
          "maTaiSan": "04.O12.DOPP.117",
          "duongKinh": 100,
          "chieudaiQL": 86.11,
          "chatLieu": "G-CI",
          "from_latitude": 21.020490097430958,
          "to_latitude": 21.019722026933888,
          "to_longitude": 105.81997215028183,
          "from_longitude": 105.81999819159132
        }
      },
      {
        "id": 117,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8239253506141,
            21.00968258867769,
            105.82501090035127,
            21.010818253615433
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82501090035127,
              21.010818253615433
            ],
            [
              105.82500713401168,
              21.010812745031938
            ],
            [
              105.82500105871938,
              21.010722151943835
            ],
            [
              105.82496816562065,
              21.01063140796938
            ],
            [
              105.82494075428733,
              21.010565823889245
            ],
            [
              105.82487782977609,
              21.010415272901245
            ],
            [
              105.82487191385366,
              21.0104024631135
            ],
            [
              105.82487023165524,
              21.01039882042534
            ],
            [
              105.82484546582096,
              21.010371760575875
            ],
            [
              105.8246986233502,
              21.010124657580352
            ],
            [
              105.82463579656383,
              21.01001893406199
            ],
            [
              105.82448377407943,
              21.009822609177228
            ],
            [
              105.8239253506141,
              21.00968258867769
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Tây Sơn",
          "maTaiSan": "04.O12.DOPP.118",
          "duongKinh": 150,
          "chieudaiQL": 180.19,
          "chatLieu": "G-DI",
          "from_latitude": 21.010818253615433,
          "to_latitude": 21.00968258867769,
          "to_longitude": 105.8239253506141,
          "from_longitude": 105.82501090035127
        }
      },
      {
        "id": 118,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82455672097802,
            21.01537821422578,
            105.82491402786432,
            21.015566451721625
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82491402786432,
              21.01537821422578
            ],
            [
              105.8245678712518,
              21.015560934063988
            ],
            [
              105.82455672097802,
              21.015566451721625
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Võ Văn Dũng",
          "maTaiSan": "04.O12.DOPP.119",
          "duongKinh": 150,
          "chieudaiQL": 44.41,
          "chatLieu": "G-CI",
          "from_latitude": 21.01537821422578,
          "to_latitude": 21.015566451721625,
          "to_longitude": 105.82455672097802,
          "from_longitude": 105.82491402786432
        }
      },
      {
        "id": 119,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82416538804904,
            21.014827005454364,
            105.82460669758962,
            21.01505906372124
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82416538804904,
              21.01505906372124
            ],
            [
              105.82459051306401,
              21.014835930095806
            ],
            [
              105.82460669758962,
              21.014827005454364
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Trần Quang Diệu",
          "maTaiSan": "04.O12.DOPP.120",
          "duongKinh": 150,
          "chieudaiQL": 46.68,
          "chatLieu": "G-CI",
          "from_latitude": 21.01505906372124,
          "to_latitude": 21.014827005454364,
          "to_longitude": 105.82460669758962,
          "from_longitude": 105.82416538804904
        }
      },
      {
        "id": 120,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82683509491314,
            21.013508969259277,
            105.82709877713995,
            21.013944689192858
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82709877713995,
              21.013944689192858
            ],
            [
              105.82709709188603,
              21.013941905513665
            ],
            [
              105.82708642892953,
              21.0139242857314
            ],
            [
              105.8269799646539,
              21.013730758670476
            ],
            [
              105.82697277210299,
              21.013721468723986
            ],
            [
              105.8269311281643,
              21.013667678080008
            ],
            [
              105.82692868183196,
              21.013663636291053
            ],
            [
              105.82692291741569,
              21.013654111788153
            ],
            [
              105.82690386415636,
              21.01362262760513
            ],
            [
              105.82683509491314,
              21.013508969259277
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Nguyễn Lương Bằng",
          "maTaiSan": "04.O12.DOPP.121",
          "duongKinh": 150,
          "chieudaiQL": 55.49,
          "chatLieu": "G-DI",
          "from_latitude": 21.013944689192858,
          "to_latitude": 21.013508969259277,
          "to_longitude": 105.82683509491314,
          "from_longitude": 105.82709877713995
        }
      },
      {
        "id": 121,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82496874813181,
            21.01218490893714,
            105.82514699892214,
            21.01241086556501
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82514699892214,
              21.01241086556501
            ],
            [
              105.82508058393299,
              21.01232929930822
            ],
            [
              105.82506380500352,
              21.012308622717786
            ],
            [
              105.82502784919437,
              21.012264313156134
            ],
            [
              105.82501977872728,
              21.01225347111755
            ],
            [
              105.82496874813181,
              21.01218490893714
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 136, Tây Sơn",
          "maTaiSan": "04.O12.DOPP.122",
          "duongKinh": 100,
          "chieudaiQL": 27.09,
          "chatLieu": "G-DI",
          "from_latitude": 21.01241086556501,
          "to_latitude": 21.01218490893714,
          "to_longitude": 105.82496874813181,
          "from_longitude": 105.82514699892214
        }
      },
      {
        "id": 122,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82681656034565,
            21.01406792686631,
            105.82716019456383,
            21.014843832979583
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82681656034565,
              21.01406792686631
            ],
            [
              105.82681794318967,
              21.01407553163227
            ],
            [
              105.82688999547376,
              21.0142001319532
            ],
            [
              105.82695568217211,
              21.014338578923784
            ],
            [
              105.82696903801671,
              21.014376691564188
            ],
            [
              105.82698174199574,
              21.014412942209994
            ],
            [
              105.82698407481455,
              21.014419597582037
            ],
            [
              105.82703770182752,
              21.014535337319728
            ],
            [
              105.82706243463302,
              21.014589704198052
            ],
            [
              105.8270641235286,
              21.014598904376808
            ],
            [
              105.82707408532788,
              21.014653168734203
            ],
            [
              105.82715981246402,
              21.014823559221533
            ],
            [
              105.82716019456383,
              21.014843832979583
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngách 180A/3, Nguyễn Lương Bằng",
          "maTaiSan": "04.O12.DOPP.123",
          "duongKinh": 100,
          "chieudaiQL": 93.55,
          "chatLieu": "G-DI",
          "from_latitude": 21.01406792686631,
          "to_latitude": 21.014843832979583,
          "to_longitude": 105.82716019456383,
          "from_longitude": 105.82681656034565
        }
      },
      {
        "id": 123,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82613268003756,
            21.017461497806927,
            105.82666940224101,
            21.018073085787375
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82613268003756,
              21.017461497806927
            ],
            [
              105.82615463844795,
              21.017496071428685
            ],
            [
              105.82634124439292,
              21.017705256314528
            ],
            [
              105.8263895217532,
              21.017759358957466
            ],
            [
              105.82665468822904,
              21.01805546490526
            ],
            [
              105.82666940224101,
              21.018073085787375
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngách 64/65, Nguyễn Lương Bằng",
          "maTaiSan": "04.O12.DOPP.124",
          "duongKinh": 100,
          "chieudaiQL": 87.79,
          "chatLieu": "G-DI",
          "from_latitude": 21.017461497806927,
          "to_latitude": 21.018073085787375,
          "to_longitude": 105.82666940224101,
          "from_longitude": 105.82613268003756
        }
      },
      {
        "id": 124,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82576020255603,
            21.018893447176723,
            105.82971857638518,
            21.02117882273978
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82966702705862,
              21.018893447176723
            ],
            [
              105.82968160170181,
              21.018945438887872
            ],
            [
              105.82971857638518,
              21.019077209789714
            ],
            [
              105.82966885618089,
              21.01909731512258
            ],
            [
              105.8296369689424,
              21.019115885597845
            ],
            [
              105.82960911278583,
              21.019132107893494
            ],
            [
              105.82960908881742,
              21.019132122461976
            ],
            [
              105.82960633876922,
              21.019133722840426
            ],
            [
              105.82960422062207,
              21.019134956137375
            ],
            [
              105.82959140627,
              21.019142418755354
            ],
            [
              105.82958988166118,
              21.019143305931948
            ],
            [
              105.82951331578779,
              21.01918789579994
            ],
            [
              105.82934673283829,
              21.019284909656662
            ],
            [
              105.82927094459001,
              21.019330808916905
            ],
            [
              105.82907545928991,
              21.019442460177498
            ],
            [
              105.82894353097174,
              21.01951868050089
            ],
            [
              105.8288978360341,
              21.019546738766884
            ],
            [
              105.8288916178488,
              21.019550557193735
            ],
            [
              105.82885918856144,
              21.019570471458348
            ],
            [
              105.82879331661584,
              21.01960900968218
            ],
            [
              105.82876754046616,
              21.01962409087188
            ],
            [
              105.82865346710695,
              21.019694614955835
            ],
            [
              105.8286519686837,
              21.019695542641852
            ],
            [
              105.8285499647516,
              21.01975888683479
            ],
            [
              105.82854553759573,
              21.0197616370423
            ],
            [
              105.82846630139554,
              21.01980687984695
            ],
            [
              105.8283535751648,
              21.01988031007639
            ],
            [
              105.82834705716563,
              21.01988419136331
            ],
            [
              105.82830537294504,
              21.019904504658793
            ],
            [
              105.82821138792667,
              21.019947691168298
            ],
            [
              105.82814988221091,
              21.019982259894103
            ],
            [
              105.82814199558585,
              21.019986188481788
            ],
            [
              105.82807531084671,
              21.020019405880383
            ],
            [
              105.82805104466281,
              21.020031493551386
            ],
            [
              105.82802613726781,
              21.020046097112964
            ],
            [
              105.82794205466357,
              21.020095400689158
            ],
            [
              105.8278670758707,
              21.020139617210734
            ],
            [
              105.82778506870359,
              21.020187980288604
            ],
            [
              105.82771565419088,
              21.020225115477462
            ],
            [
              105.82771247753817,
              21.020226815450453
            ],
            [
              105.82763828649617,
              21.020266506495204
            ],
            [
              105.82757655468077,
              21.020302162699974
            ],
            [
              105.82747055629127,
              21.02036333357189
            ],
            [
              105.82743039263855,
              21.02038503293848
            ],
            [
              105.82738171400199,
              21.020411333195717
            ],
            [
              105.82727849738814,
              21.020465863456298
            ],
            [
              105.82727109361477,
              21.0204694419146
            ],
            [
              105.82726868357636,
              21.0204706070546
            ],
            [
              105.82725156864852,
              21.02047887983642
            ],
            [
              105.8271149312847,
              21.020556447620944
            ],
            [
              105.82703402253213,
              21.020595450301382
            ],
            [
              105.82702865745786,
              21.020598036127755
            ],
            [
              105.8270236703981,
              21.020600440374192
            ],
            [
              105.82701272630788,
              21.02060571693727
            ],
            [
              105.82694651485131,
              21.020637634572967
            ],
            [
              105.82694385630153,
              21.020638916529506
            ],
            [
              105.82688170795728,
              21.020668875162393
            ],
            [
              105.82687692908682,
              21.020671179037773
            ],
            [
              105.82685994159384,
              21.020679368065
            ],
            [
              105.82665794162286,
              21.020778898110386
            ],
            [
              105.82638375318355,
              21.02090497761366
            ],
            [
              105.82631182443005,
              21.020936541624284
            ],
            [
              105.82621414241882,
              21.02097940630964
            ],
            [
              105.82606209709705,
              21.02104684798862
            ],
            [
              105.82603437919786,
              21.021059142723566
            ],
            [
              105.82583320434298,
              21.02114607142485
            ],
            [
              105.82582043470319,
              21.02115158800227
            ],
            [
              105.82576028315077,
              21.021178784413042
            ],
            [
              105.82576020255603,
              21.02117882273978
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_La Thành",
          "maTaiSan": "04.O12.DOPP.125",
          "duongKinh": 150,
          "chieudaiQL": 488.74,
          "chatLieu": "G-DI",
          "from_latitude": 21.018893447176723,
          "to_latitude": 21.02117882273978,
          "to_longitude": 105.82576020255603,
          "from_longitude": 105.82966702705862
        }
      },
      {
        "id": 125,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81908370769051,
            21.02208808984319,
            105.81989185857158,
            21.023296443177014
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81989185857158,
              21.023296443177014
            ],
            [
              105.81979822016388,
              21.023164419250385
            ],
            [
              105.81967864950339,
              21.022995767964044
            ],
            [
              105.81940897303852,
              21.02261539464006
            ],
            [
              105.81928595704653,
              21.022445085962165
            ],
            [
              105.81923014194074,
              21.02236781281663
            ],
            [
              105.81916716390852,
              21.022267460582235
            ],
            [
              105.81908370769051,
              21.022132839169036
            ],
            [
              105.81909913677056,
              21.02209266341455
            ],
            [
              105.81909643459387,
              21.02208808984319
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Láng Hạ",
          "maTaiSan": "04.O12.DOPP.126",
          "duongKinh": 150,
          "chieudaiQL": 158.36,
          "chatLieu": "G-CI",
          "from_latitude": 21.023296443177014,
          "to_latitude": 21.02208808984319,
          "to_longitude": 105.81909643459387,
          "from_longitude": 105.81989185857158
        }
      },
      {
        "id": 126,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82413386973045,
            21.019937911235985,
            105.82511091265313,
            21.020513975198583
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82511091265313,
              21.019937911235985
            ],
            [
              105.82510887338238,
              21.019938977902033
            ],
            [
              105.82508147987443,
              21.019955834905858
            ],
            [
              105.82505939383846,
              21.019969426291222
            ],
            [
              105.82493743080393,
              21.020028680527503
            ],
            [
              105.82481071582727,
              21.0200949803013
            ],
            [
              105.82480579989165,
              21.02009755213847
            ],
            [
              105.82472087650636,
              21.020141986229316
            ],
            [
              105.82468641431821,
              21.02008571434679
            ],
            [
              105.82446462576091,
              21.020208834267837
            ],
            [
              105.82445588409989,
              21.020213686315767
            ],
            [
              105.82444562811968,
              21.020219380283624
            ],
            [
              105.82444060370374,
              21.020222169410495
            ],
            [
              105.82443753341752,
              21.02022415151749
            ],
            [
              105.82443308759042,
              21.02022702183984
            ],
            [
              105.82442642076337,
              21.020231325507403
            ],
            [
              105.82441057950017,
              21.020241551834083
            ],
            [
              105.82440503280937,
              21.020245229555766
            ],
            [
              105.82429172198555,
              21.020320372503924
            ],
            [
              105.82423040293591,
              21.020388402550633
            ],
            [
              105.82422066753311,
              21.02039920353153
            ],
            [
              105.82421951768328,
              21.020400724716204
            ],
            [
              105.82413386973045,
              21.020513975198583
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 16, Hoàng Cầu",
          "maTaiSan": "04.O12.DOPP.127",
          "duongKinh": 100,
          "chieudaiQL": 130.7,
          "chatLieu": "G-CI",
          "from_latitude": 21.019937911235985,
          "to_latitude": 21.020513975198583,
          "to_longitude": 105.82413386973045,
          "from_longitude": 105.82511091265313
        }
      },
      {
        "id": 127,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81806963252093,
            21.0150610618847,
            105.81834752221148,
            21.015374352966838
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81834752221148,
              21.015374352966838
            ],
            [
              105.81833973770985,
              21.01535645321857
            ],
            [
              105.81833056941473,
              21.015346567236584
            ],
            [
              105.81818880457523,
              21.015185164908342
            ],
            [
              105.81818338487354,
              21.01517967044639
            ],
            [
              105.8181820819871,
              21.01517818187071
            ],
            [
              105.81818076921932,
              21.015176821600864
            ],
            [
              105.81816964953425,
              21.01516541402332
            ],
            [
              105.81813995045765,
              21.015137327374205
            ],
            [
              105.81806963252093,
              21.0150610618847
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 278, Thái Hà",
          "maTaiSan": "04.O12.DOPP.128",
          "duongKinh": 100,
          "chieudaiQL": 45.21,
          "chatLieu": "G-CI",
          "from_latitude": 21.015374352966838,
          "to_latitude": 21.0150610618847,
          "to_longitude": 105.81806963252093,
          "from_longitude": 105.81834752221148
        }
      },
      {
        "id": 128,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81666125259159,
            21.01695119584254,
            105.81722779655232,
            21.01725244091383
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81666125259159,
              21.01695119584254
            ],
            [
              105.81666271887153,
              21.01695248222544
            ],
            [
              105.81687135168475,
              21.017152713308704
            ],
            [
              105.81688286275451,
              21.017163027085843
            ],
            [
              105.8170963354852,
              21.01721118533379
            ],
            [
              105.81714403782715,
              21.017224641909646
            ],
            [
              105.81715058531375,
              21.0172264909789
            ],
            [
              105.8171669838309,
              21.01723110989482
            ],
            [
              105.81717252457724,
              21.017234095542992
            ],
            [
              105.81720657463899,
              21.01725244091383
            ],
            [
              105.81722779655232,
              21.01724826450916
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Mai Anh Tuấn",
          "maTaiSan": "04.O12.DOPP.129",
          "duongKinh": 100,
          "chieudaiQL": 69.19,
          "chatLieu": "G-CI",
          "from_latitude": 21.01695119584254,
          "to_latitude": 21.01724826450916,
          "to_longitude": 105.81722779655232,
          "from_longitude": 105.81666125259159
        }
      },
      {
        "id": 129,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81684857516801,
            21.017842698731396,
            105.81747590207897,
            21.018439514782
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81684857516801,
              21.017842698731396
            ],
            [
              105.8168683617669,
              21.017867985311966
            ],
            [
              105.8168732644602,
              21.01787338926298
            ],
            [
              105.81687396512751,
              21.01787416267398
            ],
            [
              105.81687400290345,
              21.01787421036362
            ],
            [
              105.81687462085476,
              21.017874985978267
            ],
            [
              105.81698368721193,
              21.018012017416456
            ],
            [
              105.81706731180076,
              21.01813401121115
            ],
            [
              105.81706801783407,
              21.018136475436993
            ],
            [
              105.81706959465936,
              21.01814198206599
            ],
            [
              105.81707007886267,
              21.018142927225746
            ],
            [
              105.81710233727958,
              21.018178450663132
            ],
            [
              105.81710657481503,
              21.01818376928539
            ],
            [
              105.81711113198064,
              21.018190018502803
            ],
            [
              105.81728305235582,
              21.018425772222844
            ],
            [
              105.81728593624932,
              21.01842982198671
            ],
            [
              105.8172871276145,
              21.018431495362293
            ],
            [
              105.81728963248857,
              21.01843501404301
            ],
            [
              105.81729283724812,
              21.018439514782
            ],
            [
              105.81739289491958,
              21.018369907339473
            ],
            [
              105.81747590207897,
              21.01831552618832
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 81, Láng Hạ",
          "maTaiSan": "04.O12.DOPP.130",
          "duongKinh": 100,
          "chieudaiQL": 102.99,
          "chatLieu": "G-CI",
          "from_latitude": 21.017842698731396,
          "to_latitude": 21.01831552618832,
          "to_longitude": 105.81747590207897,
          "from_longitude": 105.81684857516801
        }
      },
      {
        "id": 130,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80227102679511,
            21.019547005308617,
            105.8029930293625,
            21.019936094211936
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80227102679511,
              21.019547005308617
            ],
            [
              105.80227874901536,
              21.01955123574437
            ],
            [
              105.80245271503209,
              21.019646507949687
            ],
            [
              105.80251812724848,
              21.019685393198905
            ],
            [
              105.80254818415877,
              21.019701789525772
            ],
            [
              105.80257632790857,
              21.01971714261136
            ],
            [
              105.80267187107417,
              21.019765392123894
            ],
            [
              105.80270869570057,
              21.01978398836613
            ],
            [
              105.80272286182216,
              21.01979156735379
            ],
            [
              105.8027318203215,
              21.019796360065452
            ],
            [
              105.80277064305518,
              21.019817128163726
            ],
            [
              105.80290419095292,
              21.0198885701456
            ],
            [
              105.8029930293625,
              21.019936094211936
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D110_Đường Láng",
          "maTaiSan": "04.O9A.DOPP.131",
          "duongKinh": 110,
          "chieudaiQL": 82.81,
          "chatLieu": "PVC",
          "from_latitude": 21.019547005308617,
          "to_latitude": 21.019936094211936,
          "to_longitude": 105.8029930293625,
          "from_longitude": 105.80227102679511
        }
      },
      {
        "id": 131,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80322708862097,
            21.016532244816457,
            105.80579588034527,
            21.01825631422414
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80579588034527,
              21.016532244816457
            ],
            [
              105.80577534808593,
              21.016553110922583
            ],
            [
              105.80564569142555,
              21.016668701562857
            ],
            [
              105.8056359830071,
              21.016676679698723
            ],
            [
              105.8056316062208,
              21.016680276310485
            ],
            [
              105.80561607389686,
              21.016693040237072
            ],
            [
              105.80561357232942,
              21.016695096091286
            ],
            [
              105.80556715156312,
              21.01673324341437
            ],
            [
              105.80556171745133,
              21.016737709421577
            ],
            [
              105.80555688751883,
              21.016741114886152
            ],
            [
              105.80555402500977,
              21.016743131803302
            ],
            [
              105.8054130260783,
              21.01684249798029
            ],
            [
              105.8053748574458,
              21.01681877742326
            ],
            [
              105.8053672558955,
              21.016826259632726
            ],
            [
              105.8053604897014,
              21.016832917754996
            ],
            [
              105.80525118378318,
              21.016940498570914
            ],
            [
              105.80524041967821,
              21.016951092014953
            ],
            [
              105.80516719590521,
              21.017049088529642
            ],
            [
              105.8049536960605,
              21.01729991045574
            ],
            [
              105.80493398774924,
              21.01732306496212
            ],
            [
              105.8048612149133,
              21.01739922184026
            ],
            [
              105.80484814036019,
              21.01741290439759
            ],
            [
              105.80478417713607,
              21.01748388560868
            ],
            [
              105.80476299024554,
              21.01750739754395
            ],
            [
              105.80472730739258,
              21.017546995016787
            ],
            [
              105.80469919524262,
              21.01757819186347
            ],
            [
              105.80462743486638,
              21.01766147665957
            ],
            [
              105.80460408928388,
              21.01768857196186
            ],
            [
              105.80459217410655,
              21.017714497711058
            ],
            [
              105.8045900004545,
              21.01771922826867
            ],
            [
              105.80452576945588,
              21.017780865841853
            ],
            [
              105.80450390706999,
              21.0178018446727
            ],
            [
              105.80435216234562,
              21.017968112446944
            ],
            [
              105.80433698814481,
              21.01798473948694
            ],
            [
              105.80432121909628,
              21.018002017856897
            ],
            [
              105.80412897882839,
              21.01822469932434
            ],
            [
              105.8040936020262,
              21.018229579599602
            ],
            [
              105.80406864322421,
              21.01823302236155
            ],
            [
              105.80397766411106,
              21.01825456806579
            ],
            [
              105.80397169393474,
              21.01825631422414
            ],
            [
              105.80393201633152,
              21.018229993762063
            ],
            [
              105.8038754368901,
              21.018191424982216
            ],
            [
              105.80385305595091,
              21.01817616771239
            ],
            [
              105.80373628640633,
              21.018089594840067
            ],
            [
              105.80372120261765,
              21.01807841186597
            ],
            [
              105.80364558765126,
              21.018022351577194
            ],
            [
              105.80364210885838,
              21.018019772129815
            ],
            [
              105.80362840809879,
              21.018009614101548
            ],
            [
              105.80362539289285,
              21.018007379303953
            ],
            [
              105.80341397689986,
              21.017850636087577
            ],
            [
              105.80338465352082,
              21.01782889564181
            ],
            [
              105.80333823756891,
              21.01779448230583
            ],
            [
              105.80323446170935,
              21.017742208370496
            ],
            [
              105.80322708862097,
              21.01773849297432
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 157, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DOPP.132",
          "duongKinh": 100,
          "chieudaiQL": 368.22,
          "chatLieu": "G",
          "from_latitude": 21.016532244816457,
          "to_latitude": 21.01773849297432,
          "to_longitude": 105.80322708862097,
          "from_longitude": 105.80579588034527
        }
      },
      {
        "id": 132,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80573827900511,
            21.019618285160604,
            105.80859964667368,
            21.02179128995177
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80594254329549,
              21.019618285160604
            ],
            [
              105.80593230181532,
              21.01963557813214
            ],
            [
              105.8058974574115,
              21.019802407936147
            ],
            [
              105.80585450582473,
              21.01987979956701
            ],
            [
              105.80579539944672,
              21.01998629930232
            ],
            [
              105.80578061759728,
              21.02001293329028
            ],
            [
              105.80577967245064,
              21.020015706164365
            ],
            [
              105.80577738911826,
              21.020022401778398
            ],
            [
              105.80573827900511,
              21.020137116476665
            ],
            [
              105.80573891068434,
              21.020260589401634
            ],
            [
              105.80573952254586,
              21.020380015049717
            ],
            [
              105.8057395880001,
              21.02039273400984
            ],
            [
              105.80573984852563,
              21.020443548436635
            ],
            [
              105.80573998108468,
              21.02046929344667
            ],
            [
              105.80574003341285,
              21.020479390395195
            ],
            [
              105.80574050918452,
              21.020572412593413
            ],
            [
              105.80574097784152,
              21.02066375210884
            ],
            [
              105.805741047057,
              21.020677349892452
            ],
            [
              105.80574106839498,
              21.02068150012829
            ],
            [
              105.80574109489565,
              21.020686790214576
            ],
            [
              105.8057411559882,
              21.02069869719208
            ],
            [
              105.80574216597411,
              21.020895615278675
            ],
            [
              105.8057424528496,
              21.02095151114346
            ],
            [
              105.80574319417939,
              21.02109627986783
            ],
            [
              105.80574337158441,
              21.021130907016264
            ],
            [
              105.80576733632074,
              21.021130900073054
            ],
            [
              105.80589260190719,
              21.021130865716625
            ],
            [
              105.80590834655888,
              21.021130860667245
            ],
            [
              105.80590984962642,
              21.021150635160687
            ],
            [
              105.80593299911105,
              21.02145517462425
            ],
            [
              105.80593996697293,
              21.02154685907474
            ],
            [
              105.80593488966831,
              21.02175573491967
            ],
            [
              105.80610363001945,
              21.02175523787416
            ],
            [
              105.80613567983619,
              21.021755143784134
            ],
            [
              105.80634761243772,
              21.02175451855642
            ],
            [
              105.80640486672861,
              21.021783151341197
            ],
            [
              105.80646403087573,
              21.021783043345952
            ],
            [
              105.8065743108265,
              21.021787222634845
            ],
            [
              105.80661453753581,
              21.02178874710338
            ],
            [
              105.80668752686083,
              21.021789992438713
            ],
            [
              105.8067636005784,
              21.02179128995177
            ],
            [
              105.8070347322646,
              21.0217875713132
            ],
            [
              105.80706693605143,
              21.021787129477143
            ],
            [
              105.8070832596659,
              21.02178690569829
            ],
            [
              105.80730578874132,
              21.02178150239895
            ],
            [
              105.80735565830699,
              21.021781411111945
            ],
            [
              105.80742088528248,
              21.02174869093934
            ],
            [
              105.80744127710128,
              21.021738461719615
            ],
            [
              105.80747445373642,
              21.02172181939768
            ],
            [
              105.80749501821894,
              21.021702925583927
            ],
            [
              105.80751504451419,
              21.0217030644447
            ],
            [
              105.8075564881753,
              21.021703350909497
            ],
            [
              105.80779281570744,
              21.02168847795164
            ],
            [
              105.80781525206493,
              21.021687066229866
            ],
            [
              105.80793527036161,
              21.021676121696554
            ],
            [
              105.80793637060162,
              21.021621082760245
            ],
            [
              105.80793084871607,
              21.021467515890894
            ],
            [
              105.80792824322397,
              21.02139494392269
            ],
            [
              105.80792779509444,
              21.021382474272322
            ],
            [
              105.80792849151929,
              21.02134390039805
            ],
            [
              105.80792951020396,
              21.02128747177841
            ],
            [
              105.80793133822299,
              21.021261096049034
            ],
            [
              105.80794852464382,
              21.021013054703065
            ],
            [
              105.80802511443942,
              21.021015047406706
            ],
            [
              105.80809012427183,
              21.021016739218766
            ],
            [
              105.8082536783111,
              21.021020994909577
            ],
            [
              105.80851691922854,
              21.0208900474586
            ],
            [
              105.80852764534025,
              21.020884711683916
            ],
            [
              105.80858646956865,
              21.020881577857317
            ],
            [
              105.80859964667368,
              21.02087372306465
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D110_Ngõ 62, Nguyễn Chí Thanh",
          "maTaiSan": "04.O9A.DOPP.133",
          "duongKinh": 110,
          "chieudaiQL": 616.72,
          "chatLieu": "PVC",
          "from_latitude": 21.019618285160604,
          "to_latitude": 21.02087372306465,
          "to_longitude": 105.80859964667368,
          "from_longitude": 105.80594254329549
        }
      },
      {
        "id": 133,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80387129597887,
            21.01854916406753,
            105.8082878082045,
            21.02026169059717
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80387129597887,
              21.01854916406753
            ],
            [
              105.80405812948591,
              21.01864363590009
            ],
            [
              105.80405901786574,
              21.018644086025372
            ],
            [
              105.80412178382815,
              21.018675823271433
            ],
            [
              105.80414079467255,
              21.018685435894735
            ],
            [
              105.80428260298119,
              21.018757141249356
            ],
            [
              105.8043779467084,
              21.018805351914512
            ],
            [
              105.80438252164733,
              21.01880783403308
            ],
            [
              105.80448282302098,
              21.018862251670228
            ],
            [
              105.80448898464286,
              21.018864988218525
            ],
            [
              105.80450172925725,
              21.01887064629005
            ],
            [
              105.80456466456909,
              21.018898591712343
            ],
            [
              105.80457880575374,
              21.018905645887628
            ],
            [
              105.80458212378706,
              21.01890730116814
            ],
            [
              105.80462339760754,
              21.018927889250946
            ],
            [
              105.80463655688821,
              21.018934453096087
            ],
            [
              105.80467019827998,
              21.01895123402892
            ],
            [
              105.80471519140768,
              21.018973676889267
            ],
            [
              105.80473230629507,
              21.01898221363474
            ],
            [
              105.80477199579119,
              21.019005667895794
            ],
            [
              105.8048438321807,
              21.0190481176074
            ],
            [
              105.8048715303237,
              21.01906179873578
            ],
            [
              105.80489986504844,
              21.019075792977578
            ],
            [
              105.80494045016574,
              21.019095838688877
            ],
            [
              105.80498258475733,
              21.01910705431475
            ],
            [
              105.80505918522374,
              21.019145263306793
            ],
            [
              105.80507822807174,
              21.019155619027657
            ],
            [
              105.80509058357609,
              21.019162434125295
            ],
            [
              105.80509839284255,
              21.019166741697294
            ],
            [
              105.80510626385798,
              21.01917108329937
            ],
            [
              105.80522512995333,
              21.019236650592468
            ],
            [
              105.80525114638942,
              21.019250999877958
            ],
            [
              105.80525148697295,
              21.01925118794382
            ],
            [
              105.80525411225564,
              21.019252636037695
            ],
            [
              105.80525718328875,
              21.01925433040943
            ],
            [
              105.80526321825954,
              21.01925766004658
            ],
            [
              105.80526645041711,
              21.01925944307509
            ],
            [
              105.80558842953624,
              21.01943704332722
            ],
            [
              105.80566068819877,
              21.019476900218866
            ],
            [
              105.80572259142697,
              21.01951104623728
            ],
            [
              105.80574587319757,
              21.01952308890495
            ],
            [
              105.80574824580674,
              21.01952423741249
            ],
            [
              105.80576681388261,
              21.019533225103608
            ],
            [
              105.80581001177286,
              21.01955413525863
            ],
            [
              105.80584945978946,
              21.01957322961209
            ],
            [
              105.80586612785586,
              21.019581296870424
            ],
            [
              105.80594254329549,
              21.019618285160604
            ],
            [
              105.80598755348166,
              21.01964007184679
            ],
            [
              105.80614281692542,
              21.01971522539971
            ],
            [
              105.8062089019715,
              21.019747212569918
            ],
            [
              105.8062833239984,
              21.019783235565473
            ],
            [
              105.8063651378308,
              21.019822837305618
            ],
            [
              105.80638736714008,
              21.019833596867056
            ],
            [
              105.80656054529179,
              21.019930132138466
            ],
            [
              105.80659428848034,
              21.01994912600794
            ],
            [
              105.80663124476283,
              21.019969928252774
            ],
            [
              105.80672396115934,
              21.020015499939568
            ],
            [
              105.8067410997209,
              21.02002392436276
            ],
            [
              105.80678842597368,
              21.020040415956323
            ],
            [
              105.80690756008065,
              21.020090992577913
            ],
            [
              105.80691854669534,
              21.020095989509073
            ],
            [
              105.80699160821307,
              21.020129215918814
            ],
            [
              105.80702977545509,
              21.0201465737369
            ],
            [
              105.80706076636896,
              21.02016052880991
            ],
            [
              105.80707455885796,
              21.020166739002438
            ],
            [
              105.80717203303729,
              21.020210632244133
            ],
            [
              105.80723442957532,
              21.020238729137525
            ],
            [
              105.80724943996285,
              21.02024655302478
            ],
            [
              105.80725728086516,
              21.020250639955893
            ],
            [
              105.80726306893031,
              21.020253656367533
            ],
            [
              105.80726715627019,
              21.020255786653966
            ],
            [
              105.80727848255711,
              21.02026169059717
            ],
            [
              105.80734443686568,
              21.020212485799856
            ],
            [
              105.80740646750958,
              21.020170118075733
            ],
            [
              105.80741241009794,
              21.02016663682481
            ],
            [
              105.80754157430806,
              21.020090971690664
            ],
            [
              105.80760502877165,
              21.020059896527734
            ],
            [
              105.80767979595534,
              21.020023281415565
            ],
            [
              105.80777475191633,
              21.019992731322773
            ],
            [
              105.80781780875245,
              21.019977203574875
            ],
            [
              105.80793997236054,
              21.019933145556152
            ],
            [
              105.80805145384605,
              21.01988616657405
            ],
            [
              105.80807411254791,
              21.019876617485675
            ],
            [
              105.80810630675437,
              21.019863050694575
            ],
            [
              105.80810083296184,
              21.019847484263334
            ],
            [
              105.8080458747286,
              21.01975518247366
            ],
            [
              105.8081438376357,
              21.019715544061754
            ],
            [
              105.80825408783596,
              21.019670933647635
            ],
            [
              105.80825682731543,
              21.019669825014503
            ],
            [
              105.8082878082045,
              21.01965728912004
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D160_Pháo Đài Láng",
          "maTaiSan": "04.O9A.DOPP.134",
          "duongKinh": 160,
          "chieudaiQL": 542.8,
          "chatLieu": "PVC",
          "from_latitude": 21.01854916406753,
          "to_latitude": 21.01965728912004,
          "to_longitude": 105.8082878082045,
          "from_longitude": 105.80387129597887
        }
      },
      {
        "id": 134,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8038754368901,
            21.01664747597764,
            105.80519824351208,
            21.018191424982216
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8038754368901,
              21.018191424982216
            ],
            [
              105.8038806289307,
              21.018184132159185
            ],
            [
              105.80389509590125,
              21.018159380338282
            ],
            [
              105.80390481650355,
              21.018120977858135
            ],
            [
              105.80396088463083,
              21.018038864037496
            ],
            [
              105.8040275100193,
              21.017941289400206
            ],
            [
              105.80403815514416,
              21.017925699023394
            ],
            [
              105.80415888061131,
              21.017727611056948
            ],
            [
              105.8041931588508,
              21.017668213419395
            ],
            [
              105.80421675472229,
              21.01762732467389
            ],
            [
              105.80424572435555,
              21.017593487323378
            ],
            [
              105.804282390461,
              21.01755065986684
            ],
            [
              105.80439208234469,
              21.017467479472472
            ],
            [
              105.804396079359,
              21.017464448302437
            ],
            [
              105.80450323459084,
              21.017324804507254
            ],
            [
              105.80450678439412,
              21.0173201785399
            ],
            [
              105.80457211009126,
              21.01724118024412
            ],
            [
              105.80457999217799,
              21.017231648217624
            ],
            [
              105.8046365773465,
              21.01717252344602
            ],
            [
              105.80465098549469,
              21.017157468010648
            ],
            [
              105.80485681562237,
              21.016970027123598
            ],
            [
              105.80487019924688,
              21.01695783885169
            ],
            [
              105.80496743650491,
              21.016865252084358
            ],
            [
              105.8049841032983,
              21.016847696634315
            ],
            [
              105.8051014450987,
              21.016724097117464
            ],
            [
              105.80518403277505,
              21.016658251021987
            ],
            [
              105.80518872623097,
              21.016654508405946
            ],
            [
              105.80519175303262,
              21.01665209510273
            ],
            [
              105.80519824351208,
              21.01664747597764
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D110_Ngõ 159, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DOPP.135",
          "duongKinh": 110,
          "chieudaiQL": 220.46,
          "chatLieu": "PVC",
          "from_latitude": 21.018191424982216,
          "to_latitude": 21.01664747597764,
          "to_longitude": 105.80519824351208,
          "from_longitude": 105.8038754368901
        }
      },
      {
        "id": 135,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80452333911204,
            21.01588736592493,
            105.80463652319756,
            21.015992959221993
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80452333911204,
              21.01588736592493
            ],
            [
              105.80454929886432,
              21.01590812763626
            ],
            [
              105.8045575918157,
              21.015923377385644
            ],
            [
              105.80463652319756,
              21.015992959221993
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D160_Đường Láng",
          "maTaiSan": "04.O9A.DOPP.136",
          "duongKinh": 160,
          "chieudaiQL": 17.65,
          "chatLieu": "HDPE",
          "from_latitude": 21.01588736592493,
          "to_latitude": 21.015992959221993,
          "to_longitude": 105.80463652319756,
          "from_longitude": 105.80452333911204
        }
      },
      {
        "id": 136,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80757372224927,
            21.022139578159617,
            105.80761173409647,
            21.023143672922266
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80761173409647,
              21.023143672922266
            ],
            [
              105.80760972134321,
              21.023114689752482
            ],
            [
              105.80759802816752,
              21.02294623266805
            ],
            [
              105.80759761839832,
              21.02293713097488
            ],
            [
              105.80759701533138,
              21.022923754316388
            ],
            [
              105.80757863354918,
              21.02283532340856
            ],
            [
              105.80757578923581,
              21.022788938070917
            ],
            [
              105.80757491714697,
              21.022774729912815
            ],
            [
              105.80757372224927,
              21.022755241207673
            ],
            [
              105.80758429639377,
              21.02246625003511
            ],
            [
              105.80758004682075,
              21.022318806427354
            ],
            [
              105.80758015108773,
              21.022311195329365
            ],
            [
              105.80758027513252,
              21.02230207664917
            ],
            [
              105.8075875802744,
              21.02219577261959
            ],
            [
              105.8075869157559,
              21.022155624559876
            ],
            [
              105.80758665040258,
              21.022139578159617
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D90_Ngõ 33, Chùa Láng",
          "maTaiSan": "04.O9A.DOPP.137",
          "duongKinh": 90,
          "chieudaiQL": 116.43,
          "chatLieu": "PVC",
          "from_latitude": 21.023143672922266,
          "to_latitude": 21.022139578159617,
          "to_longitude": 105.80758665040258,
          "from_longitude": 105.80761173409647
        }
      },
      {
        "id": 137,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80145959859983,
            21.02740521255483,
            105.80307692356098,
            21.028916574331006
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80307692356098,
              21.028916574331006
            ],
            [
              105.80287885335945,
              21.028605268969027
            ],
            [
              105.80223699199178,
              21.027645065434285
            ],
            [
              105.80214764602883,
              21.027527430391785
            ],
            [
              105.8020563002117,
              21.027579353425466
            ],
            [
              105.80194180705021,
              21.02740521255483
            ],
            [
              105.80152331225183,
              21.027492545250162
            ],
            [
              105.80149678819495,
              21.02746227300071
            ],
            [
              105.80149174627931,
              21.027456519712246
            ],
            [
              105.80148526215055,
              21.027449119408864
            ],
            [
              105.80145959859983,
              21.02741983032656
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D110_Đê La Thành",
          "maTaiSan": "04.O9A.DOPP.139",
          "duongKinh": 110,
          "chieudaiQL": 270.4,
          "chatLieu": "PVC",
          "from_latitude": 21.028916574331006,
          "to_latitude": 21.02741983032656,
          "to_longitude": 105.80145959859983,
          "from_longitude": 105.80307692356098
        }
      },
      {
        "id": 138,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80443081831336,
            21.02822711917591,
            105.80464243234283,
            21.028821886452775
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80464243234283,
              21.028821886452775
            ],
            [
              105.80453935189682,
              21.028625757657338
            ],
            [
              105.80452236377845,
              21.028436355184958
            ],
            [
              105.80449813878174,
              21.028381994290047
            ],
            [
              105.80444406725186,
              21.028260633110257
            ],
            [
              105.80443081831336,
              21.02822711917591
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D200_Đê La Thành",
          "maTaiSan": "04.O9A.DOPP.140",
          "duongKinh": 200,
          "chieudaiQL": 62.8,
          "chatLieu": "G",
          "from_latitude": 21.028821886452775,
          "to_latitude": 21.02822711917591,
          "to_longitude": 105.80443081831336,
          "from_longitude": 105.80464243234283
        }
      },
      {
        "id": 139,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80022002220726,
            21.022494717410442,
            105.80030654055125,
            21.022556562726386
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80030654055125,
              21.022556562726386
            ],
            [
              105.80027950851111,
              21.022537239772703
            ],
            [
              105.80022791174302,
              21.02250035799284
            ],
            [
              105.80022002220726,
              21.022494717410442
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D160_Đường Láng",
          "maTaiSan": "04.O9A.DOPP.141",
          "duongKinh": 160,
          "chieudaiQL": 13.48,
          "chatLieu": "HDPE",
          "from_latitude": 21.022556562726386,
          "to_latitude": 21.022494717410442,
          "to_longitude": 105.80022002220726,
          "from_longitude": 105.80030654055125
        }
      },
      {
        "id": 140,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80144355158988,
            21.027776865071527,
            105.80599690421585,
            21.029448069264152
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80599690421585,
              21.027776865071527
            ],
            [
              105.80591765732625,
              21.027791620960826
            ],
            [
              105.80588090936112,
              21.027798463650363
            ],
            [
              105.80583947239053,
              21.027806179393554
            ],
            [
              105.80530565384078,
              21.027874868809384
            ],
            [
              105.80511757634748,
              21.027915243109756
            ],
            [
              105.80511104598955,
              21.0279166460322
            ],
            [
              105.80443330732466,
              21.028225912430123
            ],
            [
              105.80443081831336,
              21.02822711917591
            ],
            [
              105.80442817386545,
              21.028228402528125
            ],
            [
              105.80440899974778,
              21.02823843416404
            ],
            [
              105.80438844419305,
              21.028249188592714
            ],
            [
              105.80436264205788,
              21.028262687457264
            ],
            [
              105.80426956209507,
              21.028311386312385
            ],
            [
              105.8038822664334,
              21.028514015283744
            ],
            [
              105.80343312703475,
              21.028751325132237
            ],
            [
              105.80338683111046,
              21.028772803156908
            ],
            [
              105.80318112149187,
              21.02886823546916
            ],
            [
              105.80307692356098,
              21.028916574331006
            ],
            [
              105.8028388261606,
              21.029085516709166
            ],
            [
              105.80277078980076,
              21.029118252087716
            ],
            [
              105.80260121884236,
              21.02919983912435
            ],
            [
              105.80227103384883,
              21.029358703591317
            ],
            [
              105.80226949279714,
              21.029359445187076
            ],
            [
              105.8022658244982,
              21.029359790366556
            ],
            [
              105.80161820217315,
              21.029420469779584
            ],
            [
              105.80151981182084,
              21.029436018417453
            ],
            [
              105.80144355158988,
              21.029448069264152
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D200_Đê La Thành",
          "maTaiSan": "04.O9A.DOPP.142",
          "duongKinh": 200,
          "chieudaiQL": 520.03,
          "chatLieu": "G",
          "from_latitude": 21.027776865071527,
          "to_latitude": 21.029448069264152,
          "to_longitude": 105.80144355158988,
          "from_longitude": 105.80599690421585
        }
      },
      {
        "id": 141,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80120150244939,
            21.026201518961823,
            105.80166137768312,
            21.02638519007245
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80120150244939,
              21.02638519007245
            ],
            [
              105.80120525011712,
              21.026383688410586
            ],
            [
              105.80154564618022,
              21.02624727018971
            ],
            [
              105.801627906023,
              21.026214750983755
            ],
            [
              105.80165256697627,
              21.026205001797763
            ],
            [
              105.80166137768312,
              21.026201518961823
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D90_Ngõ 1194, Đường Láng",
          "maTaiSan": "04.O9A.DOPP.143",
          "duongKinh": 90,
          "chieudaiQL": 55,
          "chatLieu": "PVC",
          "from_latitude": 21.02638519007245,
          "to_latitude": 21.026201518961823,
          "to_longitude": 105.80166137768312,
          "from_longitude": 105.80120150244939
        }
      },
      {
        "id": 142,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80401343699975,
            21.02353334744239,
            105.80544530788437,
            21.027915243109756
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80544530788437,
              21.02353334744239
            ],
            [
              105.80543302298999,
              21.023643970745475
            ],
            [
              105.80540484935975,
              21.023894377084922
            ],
            [
              105.80539325758109,
              21.023997398237334
            ],
            [
              105.80539144330704,
              21.024013524943925
            ],
            [
              105.80538735106731,
              21.024049895662476
            ],
            [
              105.80538369179267,
              21.024180656238137
            ],
            [
              105.80538362244442,
              21.024183147671067
            ],
            [
              105.80538117533757,
              21.024270580047528
            ],
            [
              105.80538002200427,
              21.024311788088667
            ],
            [
              105.8053790236619,
              21.024347464926926
            ],
            [
              105.80537944153401,
              21.024361859508815
            ],
            [
              105.80538526986129,
              21.024562531108195
            ],
            [
              105.8053881876773,
              21.02466300779227
            ],
            [
              105.80538957223024,
              21.02471068628455
            ],
            [
              105.80539120228562,
              21.024766816930043
            ],
            [
              105.80539225157621,
              21.024783424140605
            ],
            [
              105.80540914998193,
              21.025050939118184
            ],
            [
              105.80541623967058,
              21.02516316510562
            ],
            [
              105.80541865255867,
              21.025371095847653
            ],
            [
              105.80541899474036,
              21.02540058734701
            ],
            [
              105.80541908451617,
              21.02540834475134
            ],
            [
              105.80541053663806,
              21.025413987959688
            ],
            [
              105.80535727271953,
              21.0254491535978
            ],
            [
              105.80529658053784,
              21.025489222796136
            ],
            [
              105.80478370806459,
              21.025759538253503
            ],
            [
              105.80405171969133,
              21.026145335528604
            ],
            [
              105.80401343699975,
              21.026158877987516
            ],
            [
              105.80401961516634,
              21.02617038391117
            ],
            [
              105.80410549640534,
              21.02633031719372
            ],
            [
              105.80421080534566,
              21.026495571600638
            ],
            [
              105.80440084052066,
              21.02679377874534
            ],
            [
              105.80511467942043,
              21.027910712684125
            ],
            [
              105.80511757634748,
              21.027915243109756
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D160_Ngõ 84, Chùa Láng",
          "maTaiSan": "04.O9A.DOPP.144",
          "duongKinh": 160,
          "chieudaiQL": 616.06,
          "chatLieu": "PVC",
          "from_latitude": 21.02353334744239,
          "to_latitude": 21.027915243109756,
          "to_longitude": 105.80511757634748,
          "from_longitude": 105.80544530788437
        }
      },
      {
        "id": 143,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80432746283343,
            21.023501659403124,
            105.8044017577408,
            21.024445407760272
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8044017577408,
              21.023501659403124
            ],
            [
              105.80440056896424,
              21.023510491377944
            ],
            [
              105.80434419057819,
              21.023929766778853
            ],
            [
              105.80434023043746,
              21.023984173430943
            ],
            [
              105.80433993925745,
              21.02398817520846
            ],
            [
              105.80433946167103,
              21.023994728585706
            ],
            [
              105.80433842235753,
              21.024009007225906
            ],
            [
              105.8043300611342,
              21.024137123710315
            ],
            [
              105.80432746283343,
              21.024176942194845
            ],
            [
              105.80432985128478,
              21.02428055291895
            ],
            [
              105.8043457671294,
              21.02430378817533
            ],
            [
              105.80436822869474,
              21.024336579341952
            ],
            [
              105.80437022256228,
              21.02434745110884
            ],
            [
              105.8043719518106,
              21.024356875348264
            ],
            [
              105.8043748157537,
              21.024378226776264
            ],
            [
              105.80437631814512,
              21.02441798612341
            ],
            [
              105.8043773551146,
              21.024445407760272
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D90_Ngõ 106, Chùa Láng",
          "maTaiSan": "04.O9A.DOPP.145",
          "duongKinh": 90,
          "chieudaiQL": 102.28,
          "chatLieu": "PVC",
          "from_latitude": 21.023501659403124,
          "to_latitude": 21.024445407760272,
          "to_longitude": 105.8043773551146,
          "from_longitude": 105.8044017577408
        }
      },
      {
        "id": 144,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80197075315638,
            21.02131585906769,
            105.80319019965742,
            21.021721701262113
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80197075315638,
              21.02131585906769
            ],
            [
              105.80204331274663,
              21.021343452020794
            ],
            [
              105.80213205247706,
              21.021373851747768
            ],
            [
              105.80214414362706,
              21.021377993840666
            ],
            [
              105.80217531218422,
              21.02139407788159
            ],
            [
              105.80218957442375,
              21.02140143787404
            ],
            [
              105.80240729377074,
              21.021471785916006
            ],
            [
              105.80244870164474,
              21.02148516520328
            ],
            [
              105.80246999243303,
              21.021491682718842
            ],
            [
              105.80248350055707,
              21.021495817198684
            ],
            [
              105.80253230865884,
              21.021510758460526
            ],
            [
              105.80256597732775,
              21.021521064415253
            ],
            [
              105.80268883397254,
              21.02156729273154
            ],
            [
              105.8026994164802,
              21.02157066241666
            ],
            [
              105.80280150078345,
              21.021608735871528
            ],
            [
              105.80280346136793,
              21.021609467265886
            ],
            [
              105.80282312503779,
              21.021616800806246
            ],
            [
              105.80283497013033,
              21.021621873433208
            ],
            [
              105.80284147070938,
              21.021624657212513
            ],
            [
              105.80284884098992,
              21.021627813533083
            ],
            [
              105.80286859534702,
              21.02163627296692
            ],
            [
              105.80288384114623,
              21.021642801801903
            ],
            [
              105.80304474887423,
              21.021699397244284
            ],
            [
              105.8031314647487,
              21.02171304747687
            ],
            [
              105.80317648059919,
              21.021719680488847
            ],
            [
              105.80318527138394,
              21.02172097485387
            ],
            [
              105.80319019965742,
              21.021721701262113
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D90_Ngõ 898, Đường Láng",
          "maTaiSan": "04.O9A.DOPP.146",
          "duongKinh": 90,
          "chieudaiQL": 134.86,
          "chatLieu": "PVC",
          "from_latitude": 21.02131585906769,
          "to_latitude": 21.021721701262113,
          "to_longitude": 105.80319019965742,
          "from_longitude": 105.80197075315638
        }
      },
      {
        "id": 145,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80544530788437,
            21.022623888257183,
            105.80997466323929,
            21.023539241337325
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80544530788437,
              21.02353334744239
            ],
            [
              105.80546362396271,
              21.023536522259732
            ],
            [
              105.80560486004565,
              21.023539241337325
            ],
            [
              105.80573708724329,
              21.02352051155498
            ],
            [
              105.80574307493443,
              21.023519663056785
            ],
            [
              105.80574746405226,
              21.023519041616254
            ],
            [
              105.8059241850668,
              21.023494008616115
            ],
            [
              105.80604896623419,
              21.023466308687627
            ],
            [
              105.80607721508419,
              21.023460037367588
            ],
            [
              105.80633232996713,
              21.02340340416093
            ],
            [
              105.80658829883156,
              21.023346580461432
            ],
            [
              105.80676939930656,
              21.02333404095628
            ],
            [
              105.80680531532722,
              21.02333155380372
            ],
            [
              105.80682131401325,
              21.02333044642287
            ],
            [
              105.80682765020863,
              21.023330007202578
            ],
            [
              105.80684215836139,
              21.023329002632863
            ],
            [
              105.80685789067438,
              21.02332791367359
            ],
            [
              105.80694176235087,
              21.023322106328678
            ],
            [
              105.80712618207026,
              21.023285524820785
            ],
            [
              105.8071448074827,
              21.023281829840066
            ],
            [
              105.8073824319855,
              21.023210428101244
            ],
            [
              105.80752656747248,
              21.023167117760437
            ],
            [
              105.80761173409647,
              21.023143672922266
            ],
            [
              105.80780048321174,
              21.023091714350155
            ],
            [
              105.80780839634423,
              21.023089536115222
            ],
            [
              105.80782715041396,
              21.023084374510187
            ],
            [
              105.80786921004606,
              21.02308053924133
            ],
            [
              105.80803380152624,
              21.02306553242241
            ],
            [
              105.8081042831619,
              21.02305224616705
            ],
            [
              105.80812542063586,
              21.02304826185363
            ],
            [
              105.80823670684552,
              21.02302728486457
            ],
            [
              105.8088824219948,
              21.022905564390943
            ],
            [
              105.80898816301304,
              21.022880295856964
            ],
            [
              105.80899586725812,
              21.02287845456195
            ],
            [
              105.80903297618852,
              21.02286958718761
            ],
            [
              105.80903488999664,
              21.022869129179735
            ],
            [
              105.80906353856942,
              21.022862283724884
            ],
            [
              105.80917093025798,
              21.02282167854207
            ],
            [
              105.80922356380803,
              21.022801777497136
            ],
            [
              105.80935643590814,
              21.02277175241258
            ],
            [
              105.80947889848363,
              21.022744079096604
            ],
            [
              105.80950139788145,
              21.022738995226376
            ],
            [
              105.80951540388155,
              21.02273711578608
            ],
            [
              105.8095772997987,
              21.02274526816766
            ],
            [
              105.8096180471927,
              21.022747324150174
            ],
            [
              105.80961993042537,
              21.02274672718423
            ],
            [
              105.80963733858094,
              21.02274121230422
            ],
            [
              105.80967711218072,
              21.022728611710242
            ],
            [
              105.80991643430302,
              21.022644382499166
            ],
            [
              105.80992593893122,
              21.02264103684378
            ],
            [
              105.80997466323929,
              21.022623888257183
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D225_Chùa Láng",
          "maTaiSan": "04.O9A.DOPP.147",
          "duongKinh": 225,
          "chieudaiQL": 472.81,
          "chatLieu": "PVC",
          "from_latitude": 21.02353334744239,
          "to_latitude": 21.022623888257183,
          "to_longitude": 105.80997466323929,
          "from_longitude": 105.80544530788437
        }
      },
      {
        "id": 146,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8002447409266,
            21.02261755324567,
            105.80544530788437,
            21.023551138169186
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8002447409266,
              21.02261755324567
            ],
            [
              105.80048030621568,
              21.022697472569924
            ],
            [
              105.80058022903529,
              21.022731373761296
            ],
            [
              105.80073912762943,
              21.022785281977338
            ],
            [
              105.80081880778052,
              21.02281708709039
            ],
            [
              105.80088111106359,
              21.022841955896716
            ],
            [
              105.8008972086774,
              21.022848381541674
            ],
            [
              105.80111705624495,
              21.022936136328603
            ],
            [
              105.80113409223044,
              21.022941918947357
            ],
            [
              105.80123726033928,
              21.022976937106566
            ],
            [
              105.80125082494811,
              21.0229815410941
            ],
            [
              105.80131676197162,
              21.023003921382266
            ],
            [
              105.80145966992467,
              21.023052427965744
            ],
            [
              105.80153818476981,
              21.023079078074446
            ],
            [
              105.80166084907158,
              21.02311397428352
            ],
            [
              105.8016838910833,
              21.023120528798078
            ],
            [
              105.80180855462123,
              21.02315599354179
            ],
            [
              105.80182498926264,
              21.02316066885586
            ],
            [
              105.80187866824599,
              21.023175938682257
            ],
            [
              105.8019999077413,
              21.02321042858377
            ],
            [
              105.80267363659294,
              21.023315614341566
            ],
            [
              105.80275432704865,
              21.023323078055842
            ],
            [
              105.80348980134146,
              21.023391102007242
            ],
            [
              105.80368801617865,
              21.02341734815597
            ],
            [
              105.80385694202326,
              21.02343673157796
            ],
            [
              105.803861113865,
              21.023437210442513
            ],
            [
              105.80406400550697,
              21.023460490421687
            ],
            [
              105.80422383042067,
              21.02348694181619
            ],
            [
              105.80424427166544,
              21.023490324394935
            ],
            [
              105.80430688440673,
              21.023495258906046
            ],
            [
              105.8044017577408,
              21.023501659403124
            ],
            [
              105.8044459070549,
              21.023504638082937
            ],
            [
              105.8044569670135,
              21.023505162933635
            ],
            [
              105.80446463892757,
              21.023505525872952
            ],
            [
              105.80481531963441,
              21.02352215447162
            ],
            [
              105.80489271625122,
              21.023525824739032
            ],
            [
              105.80492844561832,
              21.023529049979693
            ],
            [
              105.80517041363926,
              21.023550893619955
            ],
            [
              105.80526489706871,
              21.023551138169186
            ],
            [
              105.80542735337487,
              21.023532295304644
            ],
            [
              105.80542830701516,
              21.023532352208086
            ],
            [
              105.80544530788437,
              21.02353334744239
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D225_Chùa Láng",
          "maTaiSan": "04.O9A.DOPP.148",
          "duongKinh": 225,
          "chieudaiQL": 555.78,
          "chatLieu": "PVC",
          "from_latitude": 21.02261755324567,
          "to_latitude": 21.02353334744239,
          "to_longitude": 105.80544530788437,
          "from_longitude": 105.8002447409266
        }
      },
      {
        "id": 147,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80440659016116,
            21.022117073314607,
            105.8044576819627,
            21.023505162933635
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8044569670135,
              21.023505162933635
            ],
            [
              105.8044576819627,
              21.023497847011242
            ],
            [
              105.80445333338756,
              21.02331543341537
            ],
            [
              105.8044525340363,
              21.023281916566475
            ],
            [
              105.80445098969041,
              21.023194891446433
            ],
            [
              105.80445094533394,
              21.023192361712148
            ],
            [
              105.80445071098576,
              21.02317916393696
            ],
            [
              105.80444843469131,
              21.023158143922178
            ],
            [
              105.80444875667008,
              21.023153428447735
            ],
            [
              105.80445046369837,
              21.02312841177245
            ],
            [
              105.80445175272136,
              21.023105632565688
            ],
            [
              105.80445379918017,
              21.023069471149984
            ],
            [
              105.80443082296478,
              21.022809647703795
            ],
            [
              105.80443022486402,
              21.02280288173506
            ],
            [
              105.80442772768093,
              21.022634178510504
            ],
            [
              105.80442715463394,
              21.02259550315101
            ],
            [
              105.80442703065448,
              21.022587097385596
            ],
            [
              105.80441710122206,
              21.02235366869385
            ],
            [
              105.80441422479106,
              21.022286049415456
            ],
            [
              105.80441012540496,
              21.022189687948067
            ],
            [
              105.80440929313828,
              21.022170114395838
            ],
            [
              105.80440659016116,
              21.022117073314607
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D90_Ngõ 121, Chùa Láng",
          "maTaiSan": "04.O9A.DOPP.149",
          "duongKinh": 90,
          "chieudaiQL": 161.73,
          "chatLieu": "PVC",
          "from_latitude": 21.023505162933635,
          "to_latitude": 21.022117073314607,
          "to_longitude": 105.80440659016116,
          "from_longitude": 105.8044569670135
        }
      },
      {
        "id": 148,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.79996981223482,
            21.027414580096075,
            105.80148676101153,
            21.027728281672665
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80148676101153,
              21.027414580096075
            ],
            [
              105.80145959859983,
              21.02741983032656
            ],
            [
              105.80144246559504,
              21.027424609670856
            ],
            [
              105.80142894613047,
              21.02742744720258
            ],
            [
              105.80141151851855,
              21.027431104315454
            ],
            [
              105.80126859879772,
              21.027461093901586
            ],
            [
              105.80122081121968,
              21.0274711227127
            ],
            [
              105.80120870048023,
              21.027473663653225
            ],
            [
              105.80119580465504,
              21.02747636996982
            ],
            [
              105.8011598097307,
              21.02748392345231
            ],
            [
              105.80115330896301,
              21.027485287245035
            ],
            [
              105.80109810821088,
              21.02749516578516
            ],
            [
              105.80084638651712,
              21.027540210432154
            ],
            [
              105.8008338076997,
              21.02754265329224
            ],
            [
              105.80062445862914,
              21.02758331611046
            ],
            [
              105.80059422124572,
              21.02758959229816
            ],
            [
              105.80058457230422,
              21.02759159502214
            ],
            [
              105.80043844766048,
              21.02762192687915
            ],
            [
              105.80043115262372,
              21.0276240810958
            ],
            [
              105.80041049216598,
              21.027630183913736
            ],
            [
              105.80039398732119,
              21.027637669884403
            ],
            [
              105.80033978893455,
              21.027651342444674
            ],
            [
              105.80031048582913,
              21.027658734189476
            ],
            [
              105.80030023033925,
              21.027660289040803
            ],
            [
              105.8002510993356,
              21.02766773705695
            ],
            [
              105.80020759648183,
              21.027672004498633
            ],
            [
              105.80019909850304,
              21.027673901526473
            ],
            [
              105.80013323435446,
              21.02768860440381
            ],
            [
              105.80011605345533,
              21.027690553121563
            ],
            [
              105.80008430292303,
              21.027694154652
            ],
            [
              105.80007297333472,
              21.027696881515393
            ],
            [
              105.80006972395668,
              21.027697663644947
            ],
            [
              105.80006387116593,
              21.0277003936432
            ],
            [
              105.80005872923876,
              21.02770202919598
            ],
            [
              105.80004809484353,
              21.027705412144233
            ],
            [
              105.79996981223482,
              21.027728281672665
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D160_Ngõ 1194, Đường Láng",
          "maTaiSan": "04.O9A.DOPP.150",
          "duongKinh": 160,
          "chieudaiQL": 173.74,
          "chatLieu": "PVC",
          "from_latitude": 21.027414580096075,
          "to_latitude": 21.027728281672665,
          "to_longitude": 105.79996981223482,
          "from_longitude": 105.80148676101153
        }
      },
      {
        "id": 149,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.79903495318977,
            21.024201999559082,
            105.79970973233678,
            21.025778632816277
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.79921168268059,
              21.025778632816277
            ],
            [
              105.79920971001657,
              21.025767522428758
            ],
            [
              105.7992091635827,
              21.025764452210026
            ],
            [
              105.79920823611907,
              21.02576010480902
            ],
            [
              105.79920305729183,
              21.02573586027385
            ],
            [
              105.79920269538806,
              21.025734020289693
            ],
            [
              105.79915885549117,
              21.02559158740207
            ],
            [
              105.79915619767786,
              21.025581701399407
            ],
            [
              105.79915502874496,
              21.025577352422943
            ],
            [
              105.79915377542056,
              21.025572692228852
            ],
            [
              105.79914888378813,
              21.025554497076023
            ],
            [
              105.7991331196505,
              21.02550744334617
            ],
            [
              105.79913276674675,
              21.025506389129152
            ],
            [
              105.79910370718083,
              21.02538794880183
            ],
            [
              105.79909127209032,
              21.025326906462816
            ],
            [
              105.79908080972268,
              21.025275542893212
            ],
            [
              105.7990772307452,
              21.02525797563664
            ],
            [
              105.79903495318977,
              21.025041134534774
            ],
            [
              105.79921580678989,
              21.024990037165253
            ],
            [
              105.79924647830867,
              21.024981371054388
            ],
            [
              105.79925098523978,
              21.024980097997553
            ],
            [
              105.79941278188068,
              21.024930350889264
            ],
            [
              105.79943537018465,
              21.024923405456725
            ],
            [
              105.79956711219077,
              21.02488454971603
            ],
            [
              105.79956846835826,
              21.024835979398972
            ],
            [
              105.7995677009022,
              21.02475845701116
            ],
            [
              105.799561064509,
              21.024677189424953
            ],
            [
              105.79955866451837,
              21.024634476144744
            ],
            [
              105.79955844772647,
              21.024630623986763
            ],
            [
              105.79955819687105,
              21.024626156889585
            ],
            [
              105.79955926319658,
              21.024539832938864
            ],
            [
              105.79956629599103,
              21.024524247165747
            ],
            [
              105.79956955709568,
              21.02451701773969
            ],
            [
              105.79957813011922,
              21.024498017743017
            ],
            [
              105.79963113844917,
              21.024404266197692
            ],
            [
              105.79963505054819,
              21.02439734622707
            ],
            [
              105.79964436812223,
              21.02438086725313
            ],
            [
              105.79965180160927,
              21.024365017656073
            ],
            [
              105.79966834643935,
              21.024329736492575
            ],
            [
              105.79970023787257,
              21.024231302518196
            ],
            [
              105.79970190545845,
              21.024226155300877
            ],
            [
              105.79970334968458,
              21.02422169649149
            ],
            [
              105.79970836285915,
              21.02420622408059
            ],
            [
              105.79970973233678,
              21.024201999559082
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D110_Ngõ 1150, Đường Láng",
          "maTaiSan": "04.O9A.DOPP.151",
          "duongKinh": 110,
          "chieudaiQL": 218.38,
          "chatLieu": "PVC",
          "from_latitude": 21.025778632816277,
          "to_latitude": 21.024201999559082,
          "to_longitude": 105.79970973233678,
          "from_longitude": 105.79921168268059
        }
      },
      {
        "id": 150,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.79920971001657,
            21.023084557189865,
            105.80015085334969,
            21.025767522428758
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.79970053506271,
              21.023084557189865
            ],
            [
              105.7997067441075,
              21.023090034986822
            ],
            [
              105.79974673942004,
              21.02312530682043
            ],
            [
              105.7998666910864,
              21.023203218045758
            ],
            [
              105.7999123099969,
              21.02323284891549
            ],
            [
              105.79995066572367,
              21.02325776113992
            ],
            [
              105.79995833243484,
              21.02326274161746
            ],
            [
              105.79996181922986,
              21.023265005872275
            ],
            [
              105.80014587827438,
              21.023384555915808
            ],
            [
              105.8001494247937,
              21.02340035387744
            ],
            [
              105.80015085334969,
              21.023436225241447
            ],
            [
              105.80014971782467,
              21.023443185451267
            ],
            [
              105.80014293176178,
              21.023479026816027
            ],
            [
              105.8001427894701,
              21.023480124006127
            ],
            [
              105.80014240879935,
              21.023483060389854
            ],
            [
              105.80014226003898,
              21.02348420728802
            ],
            [
              105.80009883833613,
              21.023541673607124
            ],
            [
              105.8000275677045,
              21.023653741982
            ],
            [
              105.80002564661687,
              21.023659694269124
            ],
            [
              105.80002362794447,
              21.023682694569963
            ],
            [
              105.80001978733101,
              21.023726442494766
            ],
            [
              105.79999340370706,
              21.02383823286785
            ],
            [
              105.79998134231288,
              21.023888014194185
            ],
            [
              105.79996921051323,
              21.023956874768327
            ],
            [
              105.79993978657212,
              21.024075634300218
            ],
            [
              105.7999385632811,
              21.02408057168719
            ],
            [
              105.79992827460097,
              21.024100743130113
            ],
            [
              105.79992546513685,
              21.024106251592627
            ],
            [
              105.79990156746351,
              21.024159312209594
            ],
            [
              105.79989509022359,
              21.02418976434741
            ],
            [
              105.79988872083733,
              21.02423682636862
            ],
            [
              105.7998901708121,
              21.024301964978164
            ],
            [
              105.79990827959762,
              21.02437501339763
            ],
            [
              105.79990957737323,
              21.024380249637787
            ],
            [
              105.79990976456065,
              21.024396762502384
            ],
            [
              105.79991199489272,
              21.02459301384551
            ],
            [
              105.7999357775991,
              21.02463613054682
            ],
            [
              105.7999615492298,
              21.0246602212957
            ],
            [
              105.79999067550098,
              21.024817208281046
            ],
            [
              105.79999800611165,
              21.024856719893044
            ],
            [
              105.8000032695514,
              21.02488508544164
            ],
            [
              105.8000075290602,
              21.024923440913845
            ],
            [
              105.79993778891937,
              21.02494728273128
            ],
            [
              105.79992317253736,
              21.024951663497966
            ],
            [
              105.79992421354613,
              21.02495907863141
            ],
            [
              105.79992641460332,
              21.024974763696626
            ],
            [
              105.79992819966134,
              21.02498748631943
            ],
            [
              105.79993679500615,
              21.025069132153824
            ],
            [
              105.79993777094853,
              21.02507517016821
            ],
            [
              105.79993892002476,
              21.025082284920956
            ],
            [
              105.79991824529405,
              21.025114319455845
            ],
            [
              105.79987405695044,
              21.025129528126122
            ],
            [
              105.79986228539866,
              21.025133586603495
            ],
            [
              105.79985863694289,
              21.02521336434104
            ],
            [
              105.7998565874428,
              21.025258181385535
            ],
            [
              105.79985489689311,
              21.025358377349963
            ],
            [
              105.7998571114464,
              21.025405622090677
            ],
            [
              105.79985525682265,
              21.0254629840741
            ],
            [
              105.79985517254502,
              21.025465579448554
            ],
            [
              105.79986008516723,
              21.02553114795885
            ],
            [
              105.79985292014665,
              21.02553421379882
            ],
            [
              105.79953993936823,
              21.02564592920097
            ],
            [
              105.79921302148547,
              21.025766303764843
            ],
            [
              105.79920971001657,
              21.025767522428758
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D110_Ngõ 1002, Đường Láng",
          "maTaiSan": "04.O9A.DOPP.152",
          "duongKinh": 110,
          "chieudaiQL": 384.08,
          "chatLieu": "PVC",
          "from_latitude": 21.023084557189865,
          "to_latitude": 21.025767522428758,
          "to_longitude": 105.79920971001657,
          "from_longitude": 105.79970053506271
        }
      },
      {
        "id": 151,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.79898847019898,
            21.02571335281679,
            105.80101246188407,
            21.02671946210662
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.79898847019898,
              21.02671946210662
            ],
            [
              105.79899515615824,
              21.026715844872673
            ],
            [
              105.79904944764307,
              21.026686466955127
            ],
            [
              105.79933596199632,
              21.02660034606609
            ],
            [
              105.7993487305402,
              21.026596508743328
            ],
            [
              105.79938144427621,
              21.02658667506155
            ],
            [
              105.79947961235905,
              21.026557167530115
            ],
            [
              105.79948491993325,
              21.026555571862122
            ],
            [
              105.79948738739066,
              21.02655483045265
            ],
            [
              105.79956929750473,
              21.026530209902088
            ],
            [
              105.79967941186534,
              21.02649711084886
            ],
            [
              105.79968992327854,
              21.026493951601275
            ],
            [
              105.79969563136122,
              21.026492234817155
            ],
            [
              105.79970095045998,
              21.026490636378643
            ],
            [
              105.7997865295036,
              21.026464912790342
            ],
            [
              105.79980255718968,
              21.026435806718045
            ],
            [
              105.79985436749362,
              21.026341721129295
            ],
            [
              105.79984671378196,
              21.026265100958707
            ],
            [
              105.79984635808044,
              21.02626154029525
            ],
            [
              105.79983435268895,
              21.026141368807448
            ],
            [
              105.79983069606749,
              21.026104761862193
            ],
            [
              105.79981398622095,
              21.025937479278436
            ],
            [
              105.79983137250119,
              21.02588029986464
            ],
            [
              105.79998681729407,
              21.02584128352881
            ],
            [
              105.79999144220825,
              21.025840122800616
            ],
            [
              105.8000017488262,
              21.025837535339274
            ],
            [
              105.8000126597461,
              21.025834797808137
            ],
            [
              105.80002181734365,
              21.025832499364853
            ],
            [
              105.80004018997528,
              21.025831094219054
            ],
            [
              105.80004701167819,
              21.025830572576698
            ],
            [
              105.80006767103497,
              21.025828993185698
            ],
            [
              105.80026133859785,
              21.025813658288573
            ],
            [
              105.80026175112508,
              21.025813625637543
            ],
            [
              105.8004833947502,
              21.02579575986282
            ],
            [
              105.80049800983134,
              21.025794546677744
            ],
            [
              105.8005500035135,
              21.025790230174326
            ],
            [
              105.8006392529902,
              21.025777649844745
            ],
            [
              105.80073646188487,
              21.025763948117156
            ],
            [
              105.80074135289678,
              21.02576325850474
            ],
            [
              105.8007465897925,
              21.02576122596819
            ],
            [
              105.80076372515315,
              21.025754577002107
            ],
            [
              105.80080704306954,
              21.02573776715956
            ],
            [
              105.80083623723392,
              21.025733827993513
            ],
            [
              105.80091056086756,
              21.02572444478834
            ],
            [
              105.80091532326888,
              21.025723991519495
            ],
            [
              105.80098626410421,
              21.025717239964518
            ],
            [
              105.80099913037539,
              21.025715331357894
            ],
            [
              105.80101246188407,
              21.02571335281679
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D110_Ngõ Chùa Nền, Đường Láng",
          "maTaiSan": "04.O9A.DOPP.153",
          "duongKinh": 110,
          "chieudaiQL": 280.53,
          "chatLieu": "HDPE",
          "from_latitude": 21.02671946210662,
          "to_latitude": 21.02571335281679,
          "to_longitude": 105.80101246188407,
          "from_longitude": 105.79898847019898
        }
      },
      {
        "id": 152,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80099311729695,
            21.0229815410941,
            105.80148676101153,
            21.027414580096075
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80148676101153,
              21.027414580096075
            ],
            [
              105.80148484930994,
              21.02741058960854
            ],
            [
              105.80142111579882,
              21.027277467712896
            ],
            [
              105.80142029273946,
              21.02727574823094
            ],
            [
              105.80137966869438,
              21.02718199663929
            ],
            [
              105.80136313349671,
              21.027143835458798
            ],
            [
              105.80132927123205,
              21.02706568417163
            ],
            [
              105.8013262351797,
              21.027058678582332
            ],
            [
              105.80129426452805,
              21.0269848936141
            ],
            [
              105.80122851247323,
              21.026734125623804
            ],
            [
              105.80121093412089,
              21.026667085880398
            ],
            [
              105.80121027910727,
              21.026664587925193
            ],
            [
              105.80120667505356,
              21.026650842404255
            ],
            [
              105.80120599451183,
              21.0266482479238
            ],
            [
              105.80118640595462,
              21.026573539297157
            ],
            [
              105.80118829978109,
              21.026507768072705
            ],
            [
              105.8011901460164,
              21.026443712304033
            ],
            [
              105.80119933133687,
              21.02639637776115
            ],
            [
              105.80120150244939,
              21.02638519007245
            ],
            [
              105.80120050747995,
              21.026380643388705
            ],
            [
              105.80119830256153,
              21.02637056561109
            ],
            [
              105.80117880341707,
              21.026308258797577
            ],
            [
              105.80114795988287,
              21.0262003381607
            ],
            [
              105.80112044564903,
              21.026104069745916
            ],
            [
              105.80111861045604,
              21.026097649206193
            ],
            [
              105.80110149373681,
              21.0260377608433
            ],
            [
              105.80108221539601,
              21.02597031089038
            ],
            [
              105.80105451005954,
              21.025873368648103
            ],
            [
              105.8010531562172,
              21.02586863216885
            ],
            [
              105.80101563290431,
              21.025737344809453
            ],
            [
              105.80101347144252,
              21.02572099209027
            ],
            [
              105.80101246188407,
              21.02571335281679
            ],
            [
              105.80100139145704,
              21.025678849216785
            ],
            [
              105.80099959227705,
              21.025665302930456
            ],
            [
              105.80099563814451,
              21.02563554118461
            ],
            [
              105.80099311729695,
              21.025616562663846
            ],
            [
              105.80099407667456,
              21.025546389881466
            ],
            [
              105.80099984227425,
              21.025496930797697
            ],
            [
              105.80101926028604,
              21.025330370581162
            ],
            [
              105.80102255060403,
              21.025302151751028
            ],
            [
              105.8010261159239,
              21.025271567874604
            ],
            [
              105.8010285774558,
              21.025250457749006
            ],
            [
              105.80104077006172,
              21.025145881343043
            ],
            [
              105.80105213843063,
              21.025035403504905
            ],
            [
              105.80105273380715,
              21.025029622743062
            ],
            [
              105.80105857578835,
              21.02497285978393
            ],
            [
              105.80105938146939,
              21.02496502860426
            ],
            [
              105.8010746439999,
              21.024816742211495
            ],
            [
              105.80107685259063,
              21.024795286435886
            ],
            [
              105.80108395934113,
              21.024726234502488
            ],
            [
              105.80108441384412,
              21.02472181557047
            ],
            [
              105.8010941873281,
              21.02462685187865
            ],
            [
              105.8010965650432,
              21.024603741492992
            ],
            [
              105.80109742010497,
              21.02459543046559
            ],
            [
              105.80110232103058,
              21.024547813519415
            ],
            [
              105.80111249960584,
              21.02443594669046
            ],
            [
              105.8011128139505,
              21.024432483131847
            ],
            [
              105.80111315198893,
              21.02442877288004
            ],
            [
              105.8011144131766,
              21.024414911401777
            ],
            [
              105.80113351814197,
              21.024204930787104
            ],
            [
              105.80113866827637,
              21.024148325532703
            ],
            [
              105.801179552069,
              21.023698957894027
            ],
            [
              105.80118011543942,
              21.023692769925468
            ],
            [
              105.80118646765031,
              21.023622950178286
            ],
            [
              105.80119409452958,
              21.02352595257639
            ],
            [
              105.80121072550469,
              21.023314432747107
            ],
            [
              105.80121170646288,
              21.023301959947343
            ],
            [
              105.80121760372054,
              21.02322693702883
            ],
            [
              105.8012228101409,
              21.023160712108897
            ],
            [
              105.80123096932145,
              21.023056940482903
            ],
            [
              105.80124707990487,
              21.022995759309993
            ],
            [
              105.80125082494811,
              21.0229815410941
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D160_Ngõ 1194, Đường Láng",
          "maTaiSan": "04.O9A.DOPP.154",
          "duongKinh": 160,
          "chieudaiQL": 500.14,
          "chatLieu": "PVC",
          "from_latitude": 21.027414580096075,
          "to_latitude": 21.0229815410941,
          "to_longitude": 105.80125082494811,
          "from_longitude": 105.80148676101153
        }
      },
      {
        "id": 153,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80142894613047,
            21.02742744720258,
            105.80186782095844,
            21.02840022592255
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80142894613047,
              21.02742744720258
            ],
            [
              105.80142938764202,
              21.027437333672218
            ],
            [
              105.80145433386798,
              21.027566370613258
            ],
            [
              105.80145819544417,
              21.027586348998543
            ],
            [
              105.80148168142328,
              21.027714674747244
            ],
            [
              105.80148540550783,
              21.027735025909717
            ],
            [
              105.80148867571395,
              21.02775289442858
            ],
            [
              105.80147622732751,
              21.027795566536543
            ],
            [
              105.80147737793632,
              21.027802049909855
            ],
            [
              105.80149986210711,
              21.027928681399732
            ],
            [
              105.8015455316262,
              21.027984534972052
            ],
            [
              105.80155707829296,
              21.0279986576251
            ],
            [
              105.80162957616726,
              21.028087325465727
            ],
            [
              105.80166356646168,
              21.028125122515505
            ],
            [
              105.80166789823863,
              21.02812993978753
            ],
            [
              105.801688442234,
              21.02815278404299
            ],
            [
              105.80169172687435,
              21.02815643656976
            ],
            [
              105.80186051607933,
              21.028390112594106
            ],
            [
              105.80186782095844,
              21.02840022592255
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D90_Ngõ 1194, Đường Láng",
          "maTaiSan": "04.O9A.DOPP.155",
          "duongKinh": 90,
          "chieudaiQL": 93.05,
          "chatLieu": "PVC",
          "from_latitude": 21.02742744720258,
          "to_latitude": 21.02840022592255,
          "to_longitude": 105.80186782095844,
          "from_longitude": 105.80142894613047
        }
      },
      {
        "id": 154,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80780839634423,
            21.023089536115222,
            105.80799778303178,
            21.023546675306395
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80780839634423,
              21.023089536115222
            ],
            [
              105.80782895945154,
              21.02315231578479
            ],
            [
              105.80783256575586,
              21.023164420883198
            ],
            [
              105.80783459866203,
              21.023171246859373
            ],
            [
              105.80784300852825,
              21.02319948518665
            ],
            [
              105.80795229160093,
              21.02347905717026
            ],
            [
              105.80795775344868,
              21.023496845594845
            ],
            [
              105.80795838134202,
              21.023498934488675
            ],
            [
              105.80798336693174,
              21.02351317350863
            ],
            [
              105.80799778303178,
              21.023546675306395
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D90_Chùa Láng",
          "maTaiSan": "04.O9A.DOPP.156",
          "duongKinh": 90,
          "chieudaiQL": 48.15,
          "chatLieu": "PVC",
          "from_latitude": 21.023089536115222,
          "to_latitude": 21.023546675306395,
          "to_longitude": 105.80799778303178,
          "from_longitude": 105.80780839634423
        }
      },
      {
        "id": 155,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80684215836139,
            21.023329002632863,
            105.80693499450332,
            21.02609602302885
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80684215836139,
              21.023329002632863
            ],
            [
              105.80684401688562,
              21.023368680771686
            ],
            [
              105.80684679693282,
              21.02340226858526
            ],
            [
              105.80687383291377,
              21.02372906927783
            ],
            [
              105.8069177747082,
              21.02483473928118
            ],
            [
              105.80691986474979,
              21.024987844624548
            ],
            [
              105.80690915253616,
              21.02540370380716
            ],
            [
              105.80690820321195,
              21.025440555507473
            ],
            [
              105.80691920763603,
              21.02565736658474
            ],
            [
              105.80692130063032,
              21.025698591731395
            ],
            [
              105.80692541436022,
              21.0258149793599
            ],
            [
              105.8069292343461,
              21.025923082164994
            ],
            [
              105.80693110570112,
              21.02597603136028
            ],
            [
              105.80693477228662,
              21.02607976055689
            ],
            [
              105.80693499450332,
              21.02609602302885
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 80, Chùa Láng",
          "maTaiSan": "04.O9A.DOPP.157",
          "duongKinh": 100,
          "chieudaiQL": 306.6,
          "chatLieu": "PVC",
          "from_latitude": 21.023329002632863,
          "to_latitude": 21.02609602302885,
          "to_longitude": 105.80693499450332,
          "from_longitude": 105.80684215836139
        }
      },
      {
        "id": 156,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8092761026084,
            21.023611729288472,
            105.81016988829577,
            21.024064318916498
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8092761026084,
              21.024064318916498
            ],
            [
              105.80939188112121,
              21.02400577620744
            ],
            [
              105.80961177713027,
              21.023885764514244
            ],
            [
              105.80963601336761,
              21.023872536980218
            ],
            [
              105.80979240569414,
              21.023803251861406
            ],
            [
              105.81013829586095,
              21.023628351944676
            ],
            [
              105.81016988829577,
              21.023611729288472
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 54, Nguyễn Chí Thanh",
          "maTaiSan": "04.O9A.DOPP.158",
          "duongKinh": 100,
          "chieudaiQL": 110,
          "chatLieu": "G",
          "from_latitude": 21.024064318916498,
          "to_latitude": 21.023611729288472,
          "to_longitude": 105.81016988829577,
          "from_longitude": 105.8092761026084
        }
      },
      {
        "id": 157,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80979169661252,
            21.02506183049148,
            105.81046132429813,
            21.02618760033872
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81046132429813,
              21.02506183049148
            ],
            [
              105.81044258805784,
              21.025084375825344
            ],
            [
              105.810436440454,
              21.025091773652644
            ],
            [
              105.81036723645737,
              21.02514400920855
            ],
            [
              105.81027271038668,
              21.025221661179856
            ],
            [
              105.81026480702911,
              21.025228153299988
            ],
            [
              105.81012560579933,
              21.02536925120279
            ],
            [
              105.81010804506461,
              21.02538236572062
            ],
            [
              105.81006159552398,
              21.025417054146907
            ],
            [
              105.8099988164824,
              21.025438666631878
            ],
            [
              105.80998354421284,
              21.02544392389832
            ],
            [
              105.80995305253653,
              21.025461730836096
            ],
            [
              105.80991640324646,
              21.0254831330368
            ],
            [
              105.80979169661252,
              21.0255559580175
            ],
            [
              105.80987604736802,
              21.025740315188685
            ],
            [
              105.80988126821472,
              21.02574744569575
            ],
            [
              105.80988558290029,
              21.025753338579936
            ],
            [
              105.8099196390834,
              21.02579985248972
            ],
            [
              105.80992204419888,
              21.025804678722942
            ],
            [
              105.81000453025717,
              21.025970223239355
            ],
            [
              105.81000613122521,
              21.0259718603915
            ],
            [
              105.81000772928778,
              21.02597349394456
            ],
            [
              105.81000972617062,
              21.025975763276747
            ],
            [
              105.81005216317612,
              21.026024005757588
            ],
            [
              105.81009254234664,
              21.026054421993134
            ],
            [
              105.81024042590006,
              21.026165817961502
            ],
            [
              105.8102451796319,
              21.02616963854606
            ],
            [
              105.81024931500936,
              21.02617296530145
            ],
            [
              105.81026864294222,
              21.02618760033872
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D110_Đê La Thành",
          "maTaiSan": "04.O9A.DOPP.159",
          "duongKinh": 110,
          "chieudaiQL": 177.01,
          "chatLieu": "PVC",
          "from_latitude": 21.02506183049148,
          "to_latitude": 21.02618760033872,
          "to_longitude": 105.81026864294222,
          "from_longitude": 105.81046132429813
        }
      },
      {
        "id": 158,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80806468230843,
            21.026366421651712,
            105.80966998443046,
            21.026967928386984
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80806468230843,
              21.026366421651712
            ],
            [
              105.80813652366307,
              21.026443222257907
            ],
            [
              105.80840038704092,
              21.026725298986253
            ],
            [
              105.80844372085195,
              21.026827326634127
            ],
            [
              105.80844709320249,
              21.026835267136
            ],
            [
              105.80845616863132,
              21.026856635006908
            ],
            [
              105.80856788912766,
              21.026858589526373
            ],
            [
              105.809091403257,
              21.02687337378513
            ],
            [
              105.80954712677537,
              21.026880411529596
            ],
            [
              105.80964169262943,
              21.026944524034743
            ],
            [
              105.80964342007472,
              21.026946065748877
            ],
            [
              105.80965378672973,
              21.026955326862595
            ],
            [
              105.80966788961169,
              21.026967928386984
            ],
            [
              105.80966998443046,
              21.026964817632667
            ],
            [
              105.80966432327341,
              21.026960775546186
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Đê La Thành",
          "maTaiSan": "04.O9A.DOPP.160",
          "duongKinh": 100,
          "chieudaiQL": 194.12,
          "chatLieu": "G",
          "from_latitude": 21.026366421651712,
          "to_latitude": 21.026960775546186,
          "to_longitude": 105.80966432327341,
          "from_longitude": 105.80806468230843
        }
      },
      {
        "id": 159,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81073960807926,
            21.024547758475485,
            105.811134745581,
            21.024674328730335
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81073960807926,
              21.02460656896728
            ],
            [
              105.81076049179855,
              21.02459708397271
            ],
            [
              105.81086909823532,
              21.024547758475485
            ],
            [
              105.81093387212135,
              21.024674328730335
            ],
            [
              105.81097570626758,
              21.024655421599128
            ],
            [
              105.81102315916245,
              21.02463397398102
            ],
            [
              105.81105442463316,
              21.024619842917026
            ],
            [
              105.811134745581,
              21.024583540296515
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Nguyễn Chí Thanh",
          "maTaiSan": "04.O9A.DOPP.161",
          "duongKinh": 150,
          "chieudaiQL": 853.05,
          "chatLieu": "G",
          "from_latitude": 21.02460656896728,
          "to_latitude": 21.024583540296515,
          "to_longitude": 105.811134745581,
          "from_longitude": 105.81073960807926
        }
      },
      {
        "id": 160,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80599690421585,
            21.026960212937315,
            105.80965007377195,
            21.02835846966703
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80599690421585,
              21.027776865071527
            ],
            [
              105.80605638574919,
              21.028024977188874
            ],
            [
              105.80613106954821,
              21.028336445492936
            ],
            [
              105.80613757679046,
              21.02835846966703
            ],
            [
              105.80617541621424,
              21.028349912836976
            ],
            [
              105.80652359424674,
              21.028271179412588
            ],
            [
              105.80656593615129,
              21.028260883201945
            ],
            [
              105.80664518009353,
              21.028241614493094
            ],
            [
              105.80674487205009,
              21.02821810201886
            ],
            [
              105.80694299048793,
              21.028171375548443
            ],
            [
              105.80695544115011,
              21.028168905749112
            ],
            [
              105.80718365065545,
              21.02812363220628
            ],
            [
              105.80719092988035,
              21.028122129907636
            ],
            [
              105.80761041390177,
              21.028035573451678
            ],
            [
              105.80768223232924,
              21.028019807023476
            ],
            [
              105.8078807922978,
              21.027976216997114
            ],
            [
              105.80790430103917,
              21.027971189942324
            ],
            [
              105.80806766007349,
              21.02793625637562
            ],
            [
              105.80807621335931,
              21.027934286435805
            ],
            [
              105.80823925799343,
              21.027896725807548
            ],
            [
              105.80824434606203,
              21.027894931297546
            ],
            [
              105.80849938200014,
              21.027804964148274
            ],
            [
              105.80865100127394,
              21.027751858710214
            ],
            [
              105.80868435545702,
              21.027739558005177
            ],
            [
              105.80873818491693,
              21.027719707385145
            ],
            [
              105.80879219620547,
              21.0276970080067
            ],
            [
              105.80879540398874,
              21.027695479843455
            ],
            [
              105.8087983277528,
              21.02769408851513
            ],
            [
              105.80882641297175,
              21.027680903292882
            ],
            [
              105.80904236713066,
              21.027583088826216
            ],
            [
              105.80905372748867,
              21.027576829603245
            ],
            [
              105.80926353617694,
              21.027461232451888
            ],
            [
              105.80933039072711,
              21.027397823832953
            ],
            [
              105.80934983199755,
              21.027376775520583
            ],
            [
              105.80936796965106,
              21.027357137929666
            ],
            [
              105.80948782325366,
              21.02720995372815
            ],
            [
              105.80949818407971,
              21.027196221412947
            ],
            [
              105.80956423658596,
              21.027108677765845
            ],
            [
              105.80959775855395,
              21.027064250087935
            ],
            [
              105.80963488378438,
              21.026980201426976
            ],
            [
              105.80964450385379,
              21.026967542051562
            ],
            [
              105.80965007377195,
              21.026960212937315
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D225_Đê La Thành",
          "maTaiSan": "04.O9A.DOPP.162",
          "duongKinh": 225,
          "chieudaiQL": 472.91,
          "chatLieu": "PVC",
          "from_latitude": 21.027776865071527,
          "to_latitude": 21.026960212937315,
          "to_longitude": 105.80965007377195,
          "from_longitude": 105.80599690421585
        }
      },
      {
        "id": 161,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80962634324028,
            21.022748191500558,
            105.81102494808673,
            21.02618760033872
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80962634324028,
              21.022748191500558
            ],
            [
              105.80962751976178,
              21.022750687841814
            ],
            [
              105.80964150715303,
              21.022779033200322
            ],
            [
              105.8098253652579,
              21.022987294911488
            ],
            [
              105.80989265245638,
              21.023113914177994
            ],
            [
              105.81000304688833,
              21.023321619538667
            ],
            [
              105.81016988829577,
              21.023611729288472
            ],
            [
              105.81026132000086,
              21.02377071253883
            ],
            [
              105.81035540404213,
              21.02393430852389
            ],
            [
              105.8104192650032,
              21.024045350544423
            ],
            [
              105.81043188797423,
              21.024067002184044
            ],
            [
              105.81045259936568,
              21.024102527696634
            ],
            [
              105.81051074375678,
              21.024202259602966
            ],
            [
              105.81051419662542,
              21.024208180967072
            ],
            [
              105.81051525793288,
              21.02421000223574
            ],
            [
              105.81051060239218,
              21.02426864390439
            ],
            [
              105.81051821640007,
              21.024288716177914
            ],
            [
              105.81073960807926,
              21.02460656896728
            ],
            [
              105.81078076834174,
              21.02466566332233
            ],
            [
              105.81090774427489,
              21.02482000302764
            ],
            [
              105.81097858888803,
              21.02492725227259
            ],
            [
              105.81102494808673,
              21.025130229409235
            ],
            [
              105.81100713542095,
              21.025187980919522
            ],
            [
              105.81095200700358,
              21.025366729704213
            ],
            [
              105.81094779041601,
              21.025380400289137
            ],
            [
              105.8109436629306,
              21.02538528927063
            ],
            [
              105.81079685111091,
              21.025559166900408
            ],
            [
              105.81079364890657,
              21.025562959464917
            ],
            [
              105.81063825480983,
              21.025747000352112
            ],
            [
              105.81061394098133,
              21.025775983400727
            ],
            [
              105.81061306829895,
              21.02577702446569
            ],
            [
              105.81046044642586,
              21.025958959205703
            ],
            [
              105.81044017098837,
              21.02598312865282
            ],
            [
              105.81028948047333,
              21.02616276074767
            ],
            [
              105.8102708930122,
              21.026184917870754
            ],
            [
              105.81026864294222,
              21.02618760033872
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Nguyễn Chí Thanh",
          "maTaiSan": "04.O9A.DOPP.163",
          "duongKinh": 150,
          "chieudaiQL": 438.68,
          "chatLieu": "G",
          "from_latitude": 21.022748191500558,
          "to_latitude": 21.02618760033872,
          "to_longitude": 105.81026864294222,
          "from_longitude": 105.80962634324028
        }
      },
      {
        "id": 162,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80275131791295,
            21.018631726036375,
            105.80358316543206,
            21.019010521215687
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80275131791295,
              21.018631726036375
            ],
            [
              105.80275307460937,
              21.018632653492393
            ],
            [
              105.80300770061643,
              21.0187667807966
            ],
            [
              105.8030163997434,
              21.01877111136287
            ],
            [
              105.80301782243245,
              21.01877182000474
            ],
            [
              105.80303102014483,
              21.018778390117717
            ],
            [
              105.80305745608081,
              21.0187915500203
            ],
            [
              105.80316291753867,
              21.018853620637298
            ],
            [
              105.8031979906401,
              21.0188704917523
            ],
            [
              105.80324840232511,
              21.01889474026903
            ],
            [
              105.80326819087792,
              21.01890156646793
            ],
            [
              105.80335223321042,
              21.018930556162314
            ],
            [
              105.80337200268204,
              21.018936153143613
            ],
            [
              105.803473558604,
              21.018978976120607
            ],
            [
              105.80358316543206,
              21.019010521215687
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D90_Đường Láng",
          "maTaiSan": "04.O9A.DOPP.164",
          "duongKinh": 90,
          "chieudaiQL": 96.35,
          "chatLieu": "PVC",
          "from_latitude": 21.018631726036375,
          "to_latitude": 21.019010521215687,
          "to_longitude": 105.80358316543206,
          "from_longitude": 105.80275131791295
        }
      },
      {
        "id": 163,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.7982261268768,
            21.015839960498404,
            105.80480890322319,
            21.027728281672665
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80480890322319,
              21.015839960498404
            ],
            [
              105.80464064816482,
              21.015989400858878
            ],
            [
              105.80463652319756,
              21.015992959221993
            ],
            [
              105.80463649825064,
              21.015992971081978
            ],
            [
              105.80463145598407,
              21.015997320496428
            ],
            [
              105.80457546107847,
              21.016054682979384
            ],
            [
              105.80437638756894,
              21.016258629708904
            ],
            [
              105.8043425623524,
              21.016291937259435
            ],
            [
              105.8043384926827,
              21.016295944259298
            ],
            [
              105.8043146392232,
              21.016326066838875
            ],
            [
              105.80425593674913,
              21.016400216802428
            ],
            [
              105.80424101775007,
              21.01641884418316
            ],
            [
              105.80405419774179,
              21.016640587668284
            ],
            [
              105.80402917892434,
              21.0166702902964
            ],
            [
              105.80400309210017,
              21.01670228223617
            ],
            [
              105.80393156145192,
              21.016790019485722
            ],
            [
              105.80390654499601,
              21.016820710218713
            ],
            [
              105.80385398232117,
              21.016889846112157
            ],
            [
              105.80380444006131,
              21.016955021508743
            ],
            [
              105.80375079102188,
              21.01702142210609
            ],
            [
              105.80369154688631,
              21.017094760633295
            ],
            [
              105.80364553446185,
              21.017159503824256
            ],
            [
              105.80348246530035,
              21.017388982672607
            ],
            [
              105.80347142450447,
              21.01740451978789
            ],
            [
              105.8034638170486,
              21.01741523279554
            ],
            [
              105.80341928182541,
              21.017471504786503
            ],
            [
              105.80333356224493,
              21.01757982747431
            ],
            [
              105.80329924020256,
              21.01762320547383
            ],
            [
              105.80322708862097,
              21.01773849297432
            ],
            [
              105.80318719339263,
              21.017802253353477
            ],
            [
              105.80317208659133,
              21.017828074852506
            ],
            [
              105.80303057113944,
              21.018070052481807
            ],
            [
              105.8029108759681,
              21.018298486050792
            ],
            [
              105.80276945779248,
              21.01859383846646
            ],
            [
              105.80275131791295,
              21.018631726036375
            ],
            [
              105.80274845638608,
              21.018637714403976
            ],
            [
              105.80274633296352,
              21.018642137604377
            ],
            [
              105.80257634583543,
              21.018997180933226
            ],
            [
              105.80238234360596,
              21.019358375470194
            ],
            [
              105.80227455123772,
              21.01954103278995
            ],
            [
              105.80227102679511,
              21.019547005308617
            ],
            [
              105.80226615437458,
              21.019555266645973
            ],
            [
              105.80226095788969,
              21.019565708101425
            ],
            [
              105.80202339632672,
              21.020043300946483
            ],
            [
              105.80191371067849,
              21.02023779838006
            ],
            [
              105.80184287039582,
              21.020363429248356
            ],
            [
              105.80166792309039,
              21.02066212995372
            ],
            [
              105.80156946322678,
              21.020830250544964
            ],
            [
              105.80151402270181,
              21.02091449227984
            ],
            [
              105.80143861114801,
              21.02102909170788
            ],
            [
              105.8014289639124,
              21.021043752321006
            ],
            [
              105.8014073755084,
              21.021076566303854
            ],
            [
              105.80133367672116,
              21.021210900410427
            ],
            [
              105.8012471345184,
              21.021368657021704
            ],
            [
              105.80123269259731,
              21.021394993678644
            ],
            [
              105.80098827039849,
              21.021809102359605
            ],
            [
              105.80094475927643,
              21.02186534813834
            ],
            [
              105.80089045325137,
              21.021935556278756
            ],
            [
              105.80086693549806,
              21.021965966754802
            ],
            [
              105.8007398387147,
              21.022111604855947
            ],
            [
              105.80064077465777,
              21.022225129357274
            ],
            [
              105.80035837668771,
              21.022504737173637
            ],
            [
              105.80030751394864,
              21.02255509581743
            ],
            [
              105.80030654055125,
              21.022556562726386
            ],
            [
              105.8002585688022,
              21.02260390451446
            ],
            [
              105.8002447409266,
              21.02261755324567
            ],
            [
              105.80022527662727,
              21.022636764389084
            ],
            [
              105.80002265336256,
              21.02282214895637
            ],
            [
              105.79996312555711,
              21.0228766155025
            ],
            [
              105.79987104427788,
              21.022949531849548
            ],
            [
              105.79987062373847,
              21.022949864409362
            ],
            [
              105.7998034719118,
              21.023003041718418
            ],
            [
              105.79970053506271,
              21.023084557189865
            ],
            [
              105.79968989901333,
              21.02309298194461
            ],
            [
              105.79959561498386,
              21.02316761109288
            ],
            [
              105.79944471322149,
              21.023287057228593
            ],
            [
              105.79940501294541,
              21.023318484740876
            ],
            [
              105.79912730963903,
              21.023555436087996
            ],
            [
              105.79893018716734,
              21.0237236358065
            ],
            [
              105.79888390411885,
              21.023764343196472
            ],
            [
              105.79879743637203,
              21.023840403855406
            ],
            [
              105.79851500884273,
              21.024097974584123
            ],
            [
              105.79849347811813,
              21.024117614338007
            ],
            [
              105.79839921997323,
              21.024252255118732
            ],
            [
              105.79839243566184,
              21.024261954235392
            ],
            [
              105.7982809448033,
              21.02447207205181
            ],
            [
              105.79827752273223,
              21.024478531755403
            ],
            [
              105.79827185438073,
              21.024499985680933
            ],
            [
              105.7982288095012,
              21.024663109591646
            ],
            [
              105.7982261268768,
              21.024673302485105
            ],
            [
              105.79823959865244,
              21.025019651340425
            ],
            [
              105.79824028053926,
              21.02503696395669
            ],
            [
              105.79827595589852,
              21.025243549394993
            ],
            [
              105.79828882894576,
              21.02531807045061
            ],
            [
              105.79829175588428,
              21.02533496968717
            ],
            [
              105.79833894932355,
              21.02547594372356
            ],
            [
              105.79836365880581,
              21.025549713718743
            ],
            [
              105.7984586853787,
              21.02576890683988
            ],
            [
              105.79849456838355,
              21.02585164761315
            ],
            [
              105.79854357574158,
              21.025962491255076
            ],
            [
              105.79854997808073,
              21.025976970671767
            ],
            [
              105.79857134374623,
              21.026025264481188
            ],
            [
              105.79859066466089,
              21.026057754146315
            ],
            [
              105.79867646596799,
              21.026201962773325
            ],
            [
              105.79873461983709,
              21.026299702693976
            ],
            [
              105.79888935519467,
              21.026559768918457
            ],
            [
              105.79889803517136,
              21.02657434058782
            ],
            [
              105.79898847019898,
              21.02671946210662
            ],
            [
              105.7990248597377,
              21.026777833042118
            ],
            [
              105.79909320602509,
              21.026890440860882
            ],
            [
              105.79914478626131,
              21.026975411845093
            ],
            [
              105.79917790582785,
              21.027029954868535
            ],
            [
              105.799189722608,
              21.027044273326617
            ],
            [
              105.79952635668366,
              21.027343433778917
            ],
            [
              105.79955999429478,
              21.027373315222203
            ],
            [
              105.7995922462469,
              21.027399701353996
            ],
            [
              105.79993281128101,
              21.02767821722397
            ],
            [
              105.79997008585688,
              21.027725834440936
            ],
            [
              105.79996989003273,
              21.027727537045866
            ],
            [
              105.79996981223482,
              21.027728281672665
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D160_Đường Láng",
          "maTaiSan": "04.O9A.DOPP.165",
          "duongKinh": 160,
          "chieudaiQL": 1596.07,
          "chatLieu": "HDPE",
          "from_latitude": 21.015839960498404,
          "to_latitude": 21.027728281672665,
          "to_longitude": 105.79996981223482,
          "from_longitude": 105.80480890322319
        }
      },
      {
        "id": 164,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8086017409821,
            21.02087748589384,
            105.80962070801537,
            21.022749277811734
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8086017409821,
              21.02087748589384
            ],
            [
              105.80878147319976,
              21.021193299641627
            ],
            [
              105.80888483322897,
              21.021374917940694
            ],
            [
              105.808948425409,
              21.02148665827377
            ],
            [
              105.80898720775996,
              21.021554803945463
            ],
            [
              105.80901740495807,
              21.021606100072585
            ],
            [
              105.80916695146058,
              21.021860137004587
            ],
            [
              105.80917258839608,
              21.021869712407074
            ],
            [
              105.80918899241072,
              21.021897578441326
            ],
            [
              105.80920490128102,
              21.021924603214245
            ],
            [
              105.8094037036183,
              21.022281237186288
            ],
            [
              105.80942559876222,
              21.022320514640644
            ],
            [
              105.80958905241418,
              21.022615568286895
            ],
            [
              105.80960893606131,
              21.02270043940259
            ],
            [
              105.80961932032054,
              21.02274472943826
            ],
            [
              105.80961993042537,
              21.02274672718423
            ],
            [
              105.80962070801537,
              21.022749277811734
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Nguyễn Chí Thanh",
          "maTaiSan": "04.O9A.DOPP.166",
          "duongKinh": 150,
          "chieudaiQL": 238,
          "chatLieu": "PVC",
          "from_latitude": 21.02087748589384,
          "to_latitude": 21.022749277811734,
          "to_longitude": 105.80962070801537,
          "from_longitude": 105.8086017409821
        }
      },
      {
        "id": 165,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80939671096617,
            21.02523945438754,
            105.81022732533104,
            21.0261921733335
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81022732533104,
              21.0261921733335
            ],
            [
              105.81008215538884,
              21.026075400159318
            ],
            [
              105.81007640366275,
              21.026071505743044
            ],
            [
              105.81007302137526,
              21.02606921591421
            ],
            [
              105.8100396474824,
              21.026046621677384
            ],
            [
              105.81002623516684,
              21.02603754128601
            ],
            [
              105.809997606157,
              21.025989709936294
            ],
            [
              105.80989473856033,
              21.025817846855617
            ],
            [
              105.80988602494263,
              21.025803288579965
            ],
            [
              105.80979278006353,
              21.02558040052382
            ],
            [
              105.80978229836364,
              21.025555345218915
            ],
            [
              105.80977333628584,
              21.025537527467215
            ],
            [
              105.80962342007793,
              21.02523945438754
            ],
            [
              105.80952664979492,
              21.025288711931037
            ],
            [
              105.80946894036126,
              21.025319567207
            ],
            [
              105.80944756765857,
              21.02533099360469
            ],
            [
              105.80939671096617,
              21.02535818526439
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Đê La Thành",
          "maTaiSan": "04.O9A.DOPP.167",
          "duongKinh": 150,
          "chieudaiQL": 120,
          "chatLieu": "G",
          "from_latitude": 21.0261921733335,
          "to_latitude": 21.02535818526439,
          "to_longitude": 105.80939671096617,
          "from_longitude": 105.81022732533104
        }
      },
      {
        "id": 166,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80813777917858,
            21.026859747970445,
            105.80965007377195,
            21.026960212937315
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80965007377195,
              21.026960212937315
            ],
            [
              105.80963275453095,
              21.0269532466068
            ],
            [
              105.80958120116982,
              21.026920113267426
            ],
            [
              105.80953870702481,
              21.026895514739046
            ],
            [
              105.80890177257888,
              21.026878447011285
            ],
            [
              105.80813777917858,
              21.026859747970445
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Đê La Thành",
          "maTaiSan": "04.O9A.DOPP.168",
          "duongKinh": 150,
          "chieudaiQL": 159.29,
          "chatLieu": "G",
          "from_latitude": 21.026960212937315,
          "to_latitude": 21.026859747970445,
          "to_longitude": 105.80813777917858,
          "from_longitude": 105.80965007377195
        }
      },
      {
        "id": 167,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80579588034527,
            21.016532244816457,
            105.80807411254791,
            21.019876617485675
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80807411254791,
              21.019876617485675
            ],
            [
              105.80805533383827,
              21.01984499614304
            ],
            [
              105.80803452815361,
              21.019809961190205
            ],
            [
              105.80756920161953,
              21.0190263917836
            ],
            [
              105.80752806057727,
              21.01895711344232
            ],
            [
              105.8074358467348,
              21.018801832714338
            ],
            [
              105.80742724771554,
              21.018787352320064
            ],
            [
              105.80736485974936,
              21.018682295821442
            ],
            [
              105.80729096156605,
              21.018557855531753
            ],
            [
              105.8072415683649,
              21.018474680353496
            ],
            [
              105.80723998699679,
              21.018472017009813
            ],
            [
              105.8071965334146,
              21.01839553365353
            ],
            [
              105.80705135891226,
              21.018140007114688
            ],
            [
              105.80700719366988,
              21.018062270686517
            ],
            [
              105.80694797461183,
              21.01795803627001
            ],
            [
              105.80690445529127,
              21.01788143571842
            ],
            [
              105.80686995899417,
              21.01782071809147
            ],
            [
              105.80685800120351,
              21.01779967086138
            ],
            [
              105.80672259763436,
              21.01756134029244
            ],
            [
              105.80643260997157,
              21.017118212663696
            ],
            [
              105.80618592670314,
              21.01689119634735
            ],
            [
              105.80617754224173,
              21.016883480953876
            ],
            [
              105.80617279945204,
              21.016879115541446
            ],
            [
              105.8059997624041,
              21.01671987418193
            ],
            [
              105.80593862161038,
              21.016663607235454
            ],
            [
              105.80579588034527,
              21.016532244816457
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D160_Nguyễn Chí Thanh",
          "maTaiSan": "04.O9A.DOPP.169",
          "duongKinh": 160,
          "chieudaiQL": 438.59,
          "chatLieu": "PVC",
          "from_latitude": 21.019876617485675,
          "to_latitude": 21.016532244816457,
          "to_longitude": 105.80579588034527,
          "from_longitude": 105.80807411254791
        }
      },
      {
        "id": 168,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80965007377195,
            21.02616963854606,
            105.8102451796319,
            21.026960212937315
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80965007377195,
              21.026960212937315
            ],
            [
              105.80965378672973,
              21.026955326862595
            ],
            [
              105.80965937288374,
              21.026947975993295
            ],
            [
              105.8096688066491,
              21.026935555955696
            ],
            [
              105.8096699281163,
              21.026934086484157
            ],
            [
              105.80968110736157,
              21.02692547878813
            ],
            [
              105.80969993177652,
              21.026901384981446
            ],
            [
              105.80981124150193,
              21.026758911833618
            ],
            [
              105.80982215075925,
              21.026743603465494
            ],
            [
              105.8098968274371,
              21.026638809430512
            ],
            [
              105.80997420014658,
              21.026538395822463
            ],
            [
              105.81009310786322,
              21.026394779856272
            ],
            [
              105.81010756582539,
              21.026377317529533
            ],
            [
              105.81022053752645,
              21.026200741173835
            ],
            [
              105.81022732533104,
              21.0261921733335
            ],
            [
              105.81023010198953,
              21.026188669129773
            ],
            [
              105.8102451796319,
              21.02616963854606
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D200_Đê La Thành",
          "maTaiSan": "04.O9A.DOPP.170",
          "duongKinh": 200,
          "chieudaiQL": 107.42,
          "chatLieu": "G",
          "from_latitude": 21.026960212937315,
          "to_latitude": 21.02616963854606,
          "to_longitude": 105.8102451796319,
          "from_longitude": 105.80965007377195
        }
      },
      {
        "id": 169,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80966386632419,
            21.024810303584903,
            105.80998354421284,
            21.02544392389832
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80998354421284,
              21.02544392389832
            ],
            [
              105.80995402260766,
              21.02538541069838
            ],
            [
              105.80992690192458,
              21.025331656907014
            ],
            [
              105.80988950621925,
              21.025249965530392
            ],
            [
              105.80985080459034,
              21.025176902574778
            ],
            [
              105.80984871090111,
              21.02517291576189
            ],
            [
              105.80984618660638,
              21.025168107255798
            ],
            [
              105.8098453691524,
              21.025166550370866
            ],
            [
              105.80983459839686,
              21.02514603696995
            ],
            [
              105.80980013510514,
              21.025080399124786
            ],
            [
              105.80976068434485,
              21.025002203958575
            ],
            [
              105.80975688708243,
              21.02499467828014
            ],
            [
              105.8096906932584,
              21.024876143110387
            ],
            [
              105.8096866707095,
              21.024867870630022
            ],
            [
              105.8096848690775,
              21.024864166037844
            ],
            [
              105.80967385983865,
              21.024841523948986
            ],
            [
              105.80966386632419,
              21.024810303584903
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D110_Ngõ 54, Nguyễn Chí Thanh",
          "maTaiSan": "04.O9A.DOPP.171",
          "duongKinh": 110,
          "chieudaiQL": 77.62,
          "chatLieu": "PVC",
          "from_latitude": 21.02544392389832,
          "to_latitude": 21.024810303584903,
          "to_longitude": 105.80966386632419,
          "from_longitude": 105.80998354421284
        }
      },
      {
        "id": 170,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8092761026084,
            21.024064318916498,
            105.80966386632419,
            21.024810303584903
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80966386632419,
              21.024810303584903
            ],
            [
              105.80962902573872,
              21.02473979187056
            ],
            [
              105.80962103676933,
              21.024726155935895
            ],
            [
              105.8095609651121,
              21.024623613017198
            ],
            [
              105.80953610887397,
              21.02457851755329
            ],
            [
              105.80943062094583,
              21.0243885767463
            ],
            [
              105.80936973532461,
              21.024248540114286
            ],
            [
              105.80936613216758,
              21.02424025388695
            ],
            [
              105.8092761026084,
              21.024064318916498
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 54, Nguyễn Chí Thanh",
          "maTaiSan": "04.O9A.DOPP.172",
          "duongKinh": 100,
          "chieudaiQL": 95,
          "chatLieu": "G",
          "from_latitude": 21.024810303584903,
          "to_latitude": 21.024064318916498,
          "to_longitude": 105.8092761026084,
          "from_longitude": 105.80966386632419
        }
      },
      {
        "id": 171,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80480890322319,
            21.015839960498404,
            105.80579588034527,
            21.016532244816457
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80579588034527,
              21.016532244816457
            ],
            [
              105.80556481063842,
              21.016319595311646
            ],
            [
              105.80556137296442,
              21.016316431318717
            ],
            [
              105.80539773182112,
              21.016165834780523
            ],
            [
              105.80528946806322,
              21.016067526750025
            ],
            [
              105.80528430224383,
              21.01606283544108
            ],
            [
              105.80511994456805,
              21.01591359052931
            ],
            [
              105.8049644219409,
              21.01587677649856
            ],
            [
              105.80494318699823,
              21.01587175023993
            ],
            [
              105.80480890322319,
              21.015839960498404
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Nguyễn Chí Thanh",
          "maTaiSan": "04.O9A.DOPP.173",
          "duongKinh": 100,
          "chieudaiQL": 131.48,
          "chatLieu": "G",
          "from_latitude": 21.016532244816457,
          "to_latitude": 21.015839960498404,
          "to_longitude": 105.80480890322319,
          "from_longitude": 105.80579588034527
        }
      },
      {
        "id": 172,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.79996981223482,
            21.027728281672665,
            105.80144355158988,
            21.029448069264152
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.79996981223482,
              21.027728281672665
            ],
            [
              105.7999698833395,
              21.02772988818283
            ],
            [
              105.80005340583534,
              21.027850270528706
            ],
            [
              105.80012696125162,
              21.027956289609463
            ],
            [
              105.80020592127975,
              21.028053993385065
            ],
            [
              105.80041614614775,
              21.0283141220786
            ],
            [
              105.80044827164915,
              21.02835387371029
            ],
            [
              105.80055985244712,
              21.028477516226552
            ],
            [
              105.80070942650966,
              21.028643260812956
            ],
            [
              105.80084771298132,
              21.02879649540618
            ],
            [
              105.80092806857812,
              21.02888118122743
            ],
            [
              105.80122495077444,
              21.02919406016437
            ],
            [
              105.80133714466554,
              21.029312297927024
            ],
            [
              105.80144355158988,
              21.029448069264152
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D160_Đường Láng",
          "maTaiSan": "04.O9A.DOPP.174",
          "duongKinh": 160,
          "chieudaiQL": 1837.49,
          "chatLieu": "HDPE",
          "from_latitude": 21.027728281672665,
          "to_latitude": 21.029448069264152,
          "to_longitude": 105.80144355158988,
          "from_longitude": 105.79996981223482
        }
      },
      {
        "id": 173,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80113409223044,
            21.01825631422414,
            105.80397169393474,
            21.022941918947357
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80397169393474,
              21.01825631422414
            ],
            [
              105.80396219682892,
              21.01827090832869
            ],
            [
              105.80392119999821,
              21.01837946883992
            ],
            [
              105.80391907396688,
              21.018384654392044
            ],
            [
              105.80391203346198,
              21.0184088960203
            ],
            [
              105.80387129597887,
              21.01854916406753
            ],
            [
              105.80375848735062,
              21.018686572510628
            ],
            [
              105.8037382374976,
              21.018711238740014
            ],
            [
              105.80369415283891,
              21.018764935372744
            ],
            [
              105.80367604569518,
              21.018786991074023
            ],
            [
              105.80366678760736,
              21.018798270084755
            ],
            [
              105.80356030904117,
              21.018978001829403
            ],
            [
              105.80357960656394,
              21.01900054198728
            ],
            [
              105.80358473804702,
              21.019006535060942
            ],
            [
              105.80358316543206,
              21.019010521215687
            ],
            [
              105.80354540695808,
              21.01910631274232
            ],
            [
              105.80353824152591,
              21.01911766857124
            ],
            [
              105.8035310055091,
              21.019129136733724
            ],
            [
              105.803448021099,
              21.019260652333337
            ],
            [
              105.8034222371249,
              21.019301515182743
            ],
            [
              105.80341248860297,
              21.019316964900646
            ],
            [
              105.80336789930519,
              21.019381742849067
            ],
            [
              105.80335038888273,
              21.019408187200888
            ],
            [
              105.8033501932619,
              21.01940848257753
            ],
            [
              105.80334885585228,
              21.019409328897083
            ],
            [
              105.80334859604172,
              21.019409493608613
            ],
            [
              105.80333289234753,
              21.01943776388515
            ],
            [
              105.8032888850537,
              21.01951225510453
            ],
            [
              105.80328727012369,
              21.019514988677262
            ],
            [
              105.8032320399084,
              21.019610028963374
            ],
            [
              105.80319386032879,
              21.01967809326002
            ],
            [
              105.80318898163988,
              21.01968679181508
            ],
            [
              105.80314771900335,
              21.01974014440639
            ],
            [
              105.80314398564454,
              21.019746798060392
            ],
            [
              105.80312553322739,
              21.019779681444472
            ],
            [
              105.80307777719634,
              21.01986230402145
            ],
            [
              105.80304820344806,
              21.019889983107372
            ],
            [
              105.80300823107564,
              21.019922205747893
            ],
            [
              105.8030059638767,
              21.019924032845232
            ],
            [
              105.80300068045817,
              21.01992829212654
            ],
            [
              105.8029930293625,
              21.019936094211936
            ],
            [
              105.80294539125627,
              21.01998758368395
            ],
            [
              105.80285803251898,
              21.020073775732946
            ],
            [
              105.80283316666441,
              21.02009913564962
            ],
            [
              105.80282578249843,
              21.02010666820881
            ],
            [
              105.80279492355581,
              21.020136700811676
            ],
            [
              105.80270155430459,
              21.020227576395186
            ],
            [
              105.80263290487112,
              21.020294391577494
            ],
            [
              105.80262890179108,
              21.0202977478994
            ],
            [
              105.802579471408,
              21.020342429057493
            ],
            [
              105.80257425772557,
              21.020347141418256
            ],
            [
              105.80255716105057,
              21.02035783322446
            ],
            [
              105.8025542396854,
              21.020360916181783
            ],
            [
              105.80255088404142,
              21.02036445822218
            ],
            [
              105.80252462267842,
              21.02039978188173
            ],
            [
              105.80240395304712,
              21.02051722634674
            ],
            [
              105.8023715526729,
              21.020556394184705
            ],
            [
              105.80236498829814,
              21.020564330214174
            ],
            [
              105.80231943905254,
              21.020642404354412
            ],
            [
              105.80231453126042,
              21.020650712827806
            ],
            [
              105.80230763385381,
              21.020662390699368
            ],
            [
              105.80223923139383,
              21.020770585120953
            ],
            [
              105.80220884940039,
              21.020822926947666
            ],
            [
              105.80219299878677,
              21.020850234071336
            ],
            [
              105.80214759579906,
              21.02094956769385
            ],
            [
              105.80214325455697,
              21.02095840910584
            ],
            [
              105.80208419765458,
              21.021078689767545
            ],
            [
              105.80207683884048,
              21.021093675625917
            ],
            [
              105.80205837853191,
              21.02113127198875
            ],
            [
              105.80204534247294,
              21.02115873382823
            ],
            [
              105.80203767675965,
              21.0211764136592
            ],
            [
              105.80203295587913,
              21.02118729996371
            ],
            [
              105.80202870245428,
              21.02119961297295
            ],
            [
              105.8020280686993,
              21.021201446807435
            ],
            [
              105.80197823768646,
              21.021300093133924
            ],
            [
              105.80197221619952,
              21.021312777576394
            ],
            [
              105.80197075315638,
              21.02131585906769
            ],
            [
              105.80196800401303,
              21.021321650891885
            ],
            [
              105.80193496953581,
              21.021383326506463
            ],
            [
              105.80185200583274,
              21.021512364612892
            ],
            [
              105.80184969704003,
              21.021515954995536
            ],
            [
              105.80181891272603,
              21.021554097175443
            ],
            [
              105.80179965940896,
              21.021586975166105
            ],
            [
              105.80178586072842,
              21.02161053772701
            ],
            [
              105.80178531641107,
              21.021611467909537
            ],
            [
              105.8017511192806,
              21.021669282245362
            ],
            [
              105.80173413152264,
              21.021695705910673
            ],
            [
              105.80173269758569,
              21.02169793551742
            ],
            [
              105.80167621031283,
              21.021785794251723
            ],
            [
              105.80165698834504,
              21.021819868858323
            ],
            [
              105.80163541925334,
              21.02185810486443
            ],
            [
              105.80160863464616,
              21.021905586517992
            ],
            [
              105.80157804297268,
              21.02195981607208
            ],
            [
              105.80147955236146,
              21.0221344111061
            ],
            [
              105.80147812579128,
              21.022136940548272
            ],
            [
              105.801434023991,
              21.022215122352495
            ],
            [
              105.80139314880381,
              21.022313132894617
            ],
            [
              105.80137085099214,
              21.022366600751223
            ],
            [
              105.801360603872,
              21.02238909157378
            ],
            [
              105.80135362933054,
              21.02240688463549
            ],
            [
              105.80132768129204,
              21.02246135330153
            ],
            [
              105.80130819666623,
              21.02251705242154
            ],
            [
              105.80130582083046,
              21.022522683508402
            ],
            [
              105.80130280946373,
              21.022529818756738
            ],
            [
              105.80130189465875,
              21.022531987204385
            ],
            [
              105.8012790571169,
              21.022578435886324
            ],
            [
              105.80127541852625,
              21.022586470092698
            ],
            [
              105.80121122731971,
              21.022728204675634
            ],
            [
              105.80115247346556,
              21.022857934091096
            ],
            [
              105.80114907105333,
              21.022905491600163
            ],
            [
              105.80113857833453,
              21.022931001256318
            ],
            [
              105.80113409223044,
              21.022941918947357
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D110_Ngõ 102, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DOPP.175",
          "duongKinh": 110,
          "chieudaiQL": 605.96,
          "chatLieu": "PVC",
          "from_latitude": 21.01825631422414,
          "to_latitude": 21.022941918947357,
          "to_longitude": 105.80113409223044,
          "from_longitude": 105.80397169393474
        }
      },
      {
        "id": 174,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80143861114801,
            21.02102909170788,
            105.80197221619952,
            21.021312777576394
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80197221619952,
              21.021312777576394
            ],
            [
              105.80163460382562,
              21.021134796185546
            ],
            [
              105.80144455792728,
              21.021032300863013
            ],
            [
              105.80143861114801,
              21.02102909170788
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D110_Ngõ 898, Đường Láng",
          "maTaiSan": "04.O9A.DOPP.176",
          "duongKinh": 110,
          "chieudaiQL": 60.31,
          "chatLieu": "HDPE",
          "from_latitude": 21.021312777576394,
          "to_latitude": 21.02102909170788,
          "to_longitude": 105.80143861114801,
          "from_longitude": 105.80197221619952
        }
      },
      {
        "id": 175,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80275432704865,
            21.019252636037695,
            105.80525411225564,
            21.023323078055842
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80275432704865,
              21.023323078055842
            ],
            [
              105.80276308475186,
              21.023303982198176
            ],
            [
              105.8029335781573,
              21.02265685280724
            ],
            [
              105.8029728713637,
              21.022507732039447
            ],
            [
              105.80297670280918,
              21.02249387286688
            ],
            [
              105.80303543156663,
              21.022281464993
            ],
            [
              105.80319019965742,
              21.021721701262113
            ],
            [
              105.80325299980542,
              21.021509274085172
            ],
            [
              105.80332752299869,
              21.021231227009405
            ],
            [
              105.80336455320058,
              21.021073899255544
            ],
            [
              105.8033846443762,
              21.0210269086983
            ],
            [
              105.80342287240839,
              21.020937500825536
            ],
            [
              105.80352667632624,
              21.020763890339285
            ],
            [
              105.8035290246257,
              21.020759962843815
            ],
            [
              105.80353921724391,
              21.020762208896187
            ],
            [
              105.80360790194683,
              21.02080612819559
            ],
            [
              105.80370839279107,
              21.020870385132266
            ],
            [
              105.80379124241513,
              21.020904023707573
            ],
            [
              105.80387250270869,
              21.020815963228877
            ],
            [
              105.80387589546267,
              21.02081934785268
            ],
            [
              105.8039311534719,
              21.02087447451425
            ],
            [
              105.80395761020046,
              21.02090990960164
            ],
            [
              105.80399952265434,
              21.02092913002054
            ],
            [
              105.80417122242376,
              21.020982499451833
            ],
            [
              105.80426475470016,
              21.020995822436785
            ],
            [
              105.80437918163824,
              21.021000095559213
            ],
            [
              105.80446815182161,
              21.020836715101023
            ],
            [
              105.80451975108583,
              21.020763713238832
            ],
            [
              105.80453074896687,
              21.02074876064989
            ],
            [
              105.80431627682738,
              21.020608001380268
            ],
            [
              105.80431617165138,
              21.02060596870874
            ],
            [
              105.80431591209376,
              21.020600979594914
            ],
            [
              105.80431536919484,
              21.02059051283273
            ],
            [
              105.8043434419893,
              21.020545226756862
            ],
            [
              105.80437263635365,
              21.0204981334306
            ],
            [
              105.80440469662187,
              21.020446415561626
            ],
            [
              105.80452631592068,
              21.02020237043485
            ],
            [
              105.80453195203822,
              21.020191060638503
            ],
            [
              105.80454745807143,
              21.020177795635725
            ],
            [
              105.80464388003791,
              21.020216472852486
            ],
            [
              105.8047264409994,
              21.02006351784761
            ],
            [
              105.80473127560633,
              21.020054506942195
            ],
            [
              105.8047340005573,
              21.020049429644768
            ],
            [
              105.80482195165735,
              21.02004729287975
            ],
            [
              105.80492190485093,
              21.019881452220588
            ],
            [
              105.80492650538547,
              21.019873819841997
            ],
            [
              105.80495970680016,
              21.0198542875603
            ],
            [
              105.8051095534163,
              21.019607685998185
            ],
            [
              105.80511413757846,
              21.019598799106987
            ],
            [
              105.80516616093041,
              21.019497955722777
            ],
            [
              105.80523756562313,
              21.019290462118935
            ],
            [
              105.80524781792424,
              21.019267469759814
            ],
            [
              105.8052517254975,
              21.01925870439093
            ],
            [
              105.80525411225564,
              21.019252636037695
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D110_Ngõ 157, Chùa Láng",
          "maTaiSan": "04.O9A.DOPP.177",
          "duongKinh": 110,
          "chieudaiQL": 659.83,
          "chatLieu": "HDPE",
          "from_latitude": 21.023323078055842,
          "to_latitude": 21.019252636037695,
          "to_longitude": 105.80525411225564,
          "from_longitude": 105.80275432704865
        }
      },
      {
        "id": 176,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80805145384605,
            21.01988616657405,
            105.8086017409821,
            21.02087748589384
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80805145384605,
              21.01988616657405
            ],
            [
              105.80805463342803,
              21.0198946083993
            ],
            [
              105.80859964667368,
              21.02087372306465
            ],
            [
              105.8086017409821,
              21.02087748589384
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Nguyễn Chí Thanh",
          "maTaiSan": "04.O9A.DOPP.178",
          "duongKinh": 100,
          "chieudaiQL": 127.63,
          "chatLieu": "G",
          "from_latitude": 21.01988616657405,
          "to_latitude": 21.02087748589384,
          "to_longitude": 105.8086017409821,
          "from_longitude": 105.80805145384605
        }
      },
      {
        "id": 177,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.79854997808073,
            21.025778632816277,
            105.79921168268059,
            21.025976970671767
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.79854997808073,
              21.025976970671767
            ],
            [
              105.79855848313942,
              21.025974429692145
            ],
            [
              105.79891355703481,
              21.025868296283047
            ],
            [
              105.79899631969944,
              21.02584340465324
            ],
            [
              105.79921168268059,
              21.025778632816277
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D110_Ngõ 1150, Đường Láng",
          "maTaiSan": "04.O9A.DOPP.179",
          "duongKinh": 110,
          "chieudaiQL": 69.73,
          "chatLieu": "PVC",
          "from_latitude": 21.025976970671767,
          "to_latitude": 21.025778632816277,
          "to_longitude": 105.79921168268059,
          "from_longitude": 105.79854997808073
        }
      },
      {
        "id": 178,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82578432206317,
            21.02123593220507,
            105.82800348104574,
            21.02715636114903
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82578432206317,
              21.02123593220507
            ],
            [
              105.82578679327456,
              21.021242027818364
            ],
            [
              105.82581489499616,
              21.02131129523092
            ],
            [
              105.82593084606758,
              21.021492481972505
            ],
            [
              105.82597680953663,
              21.02156430635914
            ],
            [
              105.82607501107177,
              21.021735101764936
            ],
            [
              105.8261626112554,
              21.021887459575037
            ],
            [
              105.82620283823003,
              21.02196793123402
            ],
            [
              105.82627437650933,
              21.02211103667804
            ],
            [
              105.82627439507213,
              21.02211527813402
            ],
            [
              105.82627442428196,
              21.02212197540793
            ],
            [
              105.82641311524038,
              21.02242609858161
            ],
            [
              105.82654938551445,
              21.022905784231284
            ],
            [
              105.82691162786875,
              21.024169017256508
            ],
            [
              105.82694320405265,
              21.024292627228885
            ],
            [
              105.8270125323499,
              21.02456404341402
            ],
            [
              105.82702242546678,
              21.024602750881787
            ],
            [
              105.82702379754981,
              21.024607160979542
            ],
            [
              105.82702457029515,
              21.024609650122642
            ],
            [
              105.82706527438519,
              21.024779174466442
            ],
            [
              105.82708763717997,
              21.024849314029503
            ],
            [
              105.82715645742506,
              21.02506516168461
            ],
            [
              105.82720385882159,
              21.025294759405085
            ],
            [
              105.82721903397942,
              21.025368262287063
            ],
            [
              105.82722228588119,
              21.02538401496842
            ],
            [
              105.82733852820952,
              21.02594705364185
            ],
            [
              105.82739923311298,
              21.026192738914546
            ],
            [
              105.82740130735182,
              21.02620113332861
            ],
            [
              105.82740250540313,
              21.02620468349535
            ],
            [
              105.82740372307298,
              21.026208302211696
            ],
            [
              105.82768459542932,
              21.02679049351137
            ],
            [
              105.82772421857857,
              21.02688473278658
            ],
            [
              105.82772651299607,
              21.026897978246012
            ],
            [
              105.82789002599003,
              21.027035437962034
            ],
            [
              105.82800348104574,
              21.02715636114903
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Hào Nam",
          "maTaiSan": "04.O13.DOPP.180",
          "duongKinh": 150,
          "chieudaiQL": 699.48,
          "chatLieu": "G-DI",
          "from_latitude": 21.02123593220507,
          "to_latitude": 21.02715636114903,
          "to_longitude": 105.82800348104574,
          "from_longitude": 105.82578432206317
        }
      },
      {
        "id": 179,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83085887543957,
            21.025493148597022,
            105.83125963047914,
            21.02607179005609
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83085887543957,
              21.025493148597022
            ],
            [
              105.8309194335908,
              21.02558899642367
            ],
            [
              105.8309412759996,
              21.02562356628402
            ],
            [
              105.83097299012042,
              21.02565269114127
            ],
            [
              105.83100914311893,
              21.025652867644922
            ],
            [
              105.83123702351428,
              21.025620686662958
            ],
            [
              105.8312438264478,
              21.0256541106391
            ],
            [
              105.83124834117515,
              21.025681533036398
            ],
            [
              105.83125963047914,
              21.025750102113083
            ],
            [
              105.83098987266563,
              21.025847310355083
            ],
            [
              105.83099106965835,
              21.02585256398025
            ],
            [
              105.83099554305953,
              21.02587219085919
            ],
            [
              105.83100283242882,
              21.025904172729025
            ],
            [
              105.83100903628373,
              21.02593157423386
            ],
            [
              105.83104078430114,
              21.02607179005609
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ An Trạch 2, An Trạch",
          "maTaiSan": "04.O13.DOPP.181",
          "duongKinh": 100,
          "chieudaiQL": 138,
          "chatLieu": "G-CI",
          "from_latitude": 21.025493148597022,
          "to_latitude": 21.02607179005609,
          "to_longitude": 105.83104078430114,
          "from_longitude": 105.83085887543957
        }
      },
      {
        "id": 180,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8264959161313,
            21.02620468349535,
            105.82740250540313,
            21.026553535865553
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8264959161313,
              21.026553535865553
            ],
            [
              105.82650094387971,
              21.026551669754657
            ],
            [
              105.82654070984232,
              21.026536910175345
            ],
            [
              105.82655757524113,
              21.0265307079511
            ],
            [
              105.82657143755897,
              21.02652560870775
            ],
            [
              105.8265977096363,
              21.026515947471182
            ],
            [
              105.82676099579449,
              21.02645589583926
            ],
            [
              105.8268835016075,
              21.02641524580925
            ],
            [
              105.82690066750185,
              21.026409984154
            ],
            [
              105.8269044648591,
              21.026408820400455
            ],
            [
              105.8271488822973,
              21.026333898804562
            ],
            [
              105.82716228731461,
              21.026329789866157
            ],
            [
              105.82716608370559,
              21.02632862611157
            ],
            [
              105.82730743988874,
              21.026254520777524
            ],
            [
              105.82736206155623,
              21.02622574565912
            ],
            [
              105.82739907486396,
              21.026206247410094
            ],
            [
              105.82740250540313,
              21.02620468349535
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D200_Vũ Thạch",
          "maTaiSan": "04.O13.DOPP.182",
          "duongKinh": 200,
          "chieudaiQL": 102,
          "chatLieu": "G-CI",
          "from_latitude": 21.026553535865553,
          "to_latitude": 21.02620468349535,
          "to_longitude": 105.82740250540313,
          "from_longitude": 105.8264959161313
        }
      },
      {
        "id": 181,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82830458389878,
            21.02717149063346,
            105.82901832424345,
            21.027490300206995
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82830458389878,
              21.02717149063346
            ],
            [
              105.82839522224383,
              21.027207960292852
            ],
            [
              105.82862175850991,
              21.0272991092239
            ],
            [
              105.82874062284213,
              21.027351134676994
            ],
            [
              105.82886269126895,
              21.027412299508917
            ],
            [
              105.82889032760885,
              21.027426149424393
            ],
            [
              105.82890799297205,
              21.027435006140394
            ],
            [
              105.82892183817351,
              21.027441947672777
            ],
            [
              105.82900867061842,
              21.0274854626322
            ],
            [
              105.82901832424345,
              21.027490300206995
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D200_An Trạch",
          "maTaiSan": "04.O13.DOPP.183",
          "duongKinh": 200,
          "chieudaiQL": 90,
          "chatLieu": "G-DI",
          "from_latitude": 21.02717149063346,
          "to_latitude": 21.027490300206995,
          "to_longitude": 105.82901832424345,
          "from_longitude": 105.82830458389878
        }
      },
      {
        "id": 182,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82602715487866,
            21.02550377855653,
            105.8264959161313,
            21.026553535865553
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8264959161313,
              21.026553535865553
            ],
            [
              105.82649050340648,
              21.026541062443407
            ],
            [
              105.82615781984805,
              21.025774385019233
            ],
            [
              105.82602715487866,
              21.02550377855653
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Ngõ 127, Hào Nam",
          "maTaiSan": "04.O13.DOPP.184",
          "duongKinh": 150,
          "chieudaiQL": 126.73,
          "chatLieu": "G-CI",
          "from_latitude": 21.026553535865553,
          "to_latitude": 21.02550377855653,
          "to_longitude": 105.82602715487866,
          "from_longitude": 105.8264959161313
        }
      },
      {
        "id": 183,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82494047003782,
            21.02211527813402,
            105.82627439507213,
            21.023510129864544
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82627439507213,
              21.02211527813402
            ],
            [
              105.82627248561921,
              21.022115815796887
            ],
            [
              105.82538985879636,
              21.02236419253429
            ],
            [
              105.82548026591945,
              21.022677511712338
            ],
            [
              105.82548086904477,
              21.022679601558384
            ],
            [
              105.82548554303236,
              21.022695798995358
            ],
            [
              105.82549932081457,
              21.02274354616808
            ],
            [
              105.82550160094266,
              21.022751450065538
            ],
            [
              105.8255330891379,
              21.02286057395634
            ],
            [
              105.8256139491666,
              21.0229385660692
            ],
            [
              105.82570584592361,
              21.023246052049313
            ],
            [
              105.82556636081763,
              21.02328067057126
            ],
            [
              105.82526292872402,
              21.02335597913235
            ],
            [
              105.82519560191481,
              21.02338444037328
            ],
            [
              105.82518095303541,
              21.023396026667438
            ],
            [
              105.8251051387717,
              21.023455990199274
            ],
            [
              105.82507427629511,
              21.02346613681967
            ],
            [
              105.82506263324966,
              21.023469965170868
            ],
            [
              105.82495112019387,
              21.023506628788983
            ],
            [
              105.82494047003782,
              21.023510129864544
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 45, Hào Nam",
          "maTaiSan": "04.O13.DOPP.185",
          "duongKinh": 100,
          "chieudaiQL": 290.66,
          "chatLieu": "G-CI",
          "from_latitude": 21.02211527813402,
          "to_latitude": 21.023510129864544,
          "to_longitude": 105.82494047003782,
          "from_longitude": 105.82627439507213
        }
      },
      {
        "id": 184,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82602715487866,
            21.02506516168461,
            105.82715645742506,
            21.02550377855653
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82602715487866,
              21.02550377855653
            ],
            [
              105.82607549140714,
              21.025483664692512
            ],
            [
              105.82603054195923,
              21.025252336771878
            ],
            [
              105.82602788466001,
              21.025232037050603
            ],
            [
              105.82605502951981,
              21.02521197915529
            ],
            [
              105.8260963812475,
              21.025193329922708
            ],
            [
              105.82611898977194,
              21.025183133786296
            ],
            [
              105.826129267247,
              21.02517849909152
            ],
            [
              105.82613325540481,
              21.02517670036492
            ],
            [
              105.82613735392069,
              21.025174852327982
            ],
            [
              105.82619713790025,
              21.025147890570754
            ],
            [
              105.82620179778532,
              21.025145788708578
            ],
            [
              105.82622647042756,
              21.02513466214419
            ],
            [
              105.82625980702201,
              21.025123395934887
            ],
            [
              105.82632574996117,
              21.0251284262467
            ],
            [
              105.82639277924058,
              21.025167531739534
            ],
            [
              105.82644507918815,
              21.025188628025727
            ],
            [
              105.82648230080869,
              21.02519402636201
            ],
            [
              105.82650093616758,
              21.02519672856904
            ],
            [
              105.82656909357607,
              21.025191273323365
            ],
            [
              105.82663338493323,
              21.0251811491046
            ],
            [
              105.82667730401427,
              21.02517119795526
            ],
            [
              105.8267233157072,
              21.02516077236457
            ],
            [
              105.82690284955247,
              21.025120094401544
            ],
            [
              105.82700276283295,
              21.02509883168865
            ],
            [
              105.82713279442504,
              21.025069913026233
            ],
            [
              105.82715645742506,
              21.02506516168461
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 127, Hào Nam",
          "maTaiSan": "04.O13.DOPP.186",
          "duongKinh": 100,
          "chieudaiQL": 166.36,
          "chatLieu": "G-CI",
          "from_latitude": 21.02550377855653,
          "to_latitude": 21.02506516168461,
          "to_longitude": 105.82715645742506,
          "from_longitude": 105.82602715487866
        }
      },
      {
        "id": 185,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82468600259209,
            21.023878945749956,
            105.82602715487866,
            21.025585744516857
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82468600259209,
              21.023955374207176
            ],
            [
              105.824688980835,
              21.02395588363647
            ],
            [
              105.8247243279437,
              21.023963870166778
            ],
            [
              105.82472637624562,
              21.02396460991121
            ],
            [
              105.82472819415075,
              21.0239652622568
            ],
            [
              105.82473608947024,
              21.02396810616269
            ],
            [
              105.82473740905014,
              21.02396858118281
            ],
            [
              105.82478644310169,
              21.02395972843217
            ],
            [
              105.82489677030695,
              21.02394315568763
            ],
            [
              105.82507534391071,
              21.02391152290921
            ],
            [
              105.82507695271556,
              21.023911237814296
            ],
            [
              105.82508494332853,
              21.023908497502518
            ],
            [
              105.82508861409435,
              21.023907238662826
            ],
            [
              105.82513939832639,
              21.023896004547808
            ],
            [
              105.82523388069124,
              21.023878945749956
            ],
            [
              105.82534820823932,
              21.024080904019804
            ],
            [
              105.82537265298087,
              21.024142856837774
            ],
            [
              105.82538219732751,
              21.024167046761995
            ],
            [
              105.82538511536478,
              21.02417444266052
            ],
            [
              105.82539801823884,
              21.024215619499817
            ],
            [
              105.82540971928891,
              21.024260585781104
            ],
            [
              105.82542032505567,
              21.02430423053909
            ],
            [
              105.82542192156349,
              21.024310797367235
            ],
            [
              105.8254250329211,
              21.024323601742555
            ],
            [
              105.82545163769247,
              21.024387002770684
            ],
            [
              105.82546418413675,
              21.02441690095298
            ],
            [
              105.82548719939724,
              21.024487403391497
            ],
            [
              105.82549152338889,
              21.024500649878924
            ],
            [
              105.8255078611234,
              21.024545058032334
            ],
            [
              105.82549375157485,
              21.02459311216396
            ],
            [
              105.82547775810622,
              21.02463359466935
            ],
            [
              105.8254754136902,
              21.024639529399455
            ],
            [
              105.82547411813906,
              21.02465075826088
            ],
            [
              105.82547698298474,
              21.024656877254756
            ],
            [
              105.82548293561861,
              21.02466959557925
            ],
            [
              105.82554011704207,
              21.024750200934907
            ],
            [
              105.82555321179848,
              21.024803145849685
            ],
            [
              105.82555361519384,
              21.024804776019394
            ],
            [
              105.82555729036356,
              21.024811825916203
            ],
            [
              105.82555909489585,
              21.024815288250853
            ],
            [
              105.82557866975478,
              21.02489584852552
            ],
            [
              105.8255832207687,
              21.02491031235812
            ],
            [
              105.82560025170446,
              21.024978566793482
            ],
            [
              105.82560416489639,
              21.02499424946262
            ],
            [
              105.82563971882392,
              21.025074674348396
            ],
            [
              105.82564205285473,
              21.02507995501318
            ],
            [
              105.82567295434849,
              21.025175881045538
            ],
            [
              105.82568628178619,
              21.02522442068983
            ],
            [
              105.82569876398529,
              21.025269879922615
            ],
            [
              105.82571065816495,
              21.025313198655883
            ],
            [
              105.82572158440375,
              21.025352992283924
            ],
            [
              105.82574387899669,
              21.02540455754187
            ],
            [
              105.82574558150942,
              21.025408707723624
            ],
            [
              105.82574718552947,
              21.025412616319272
            ],
            [
              105.8257506900052,
              21.025421157360892
            ],
            [
              105.82575957357453,
              21.02546106025846
            ],
            [
              105.82577892298063,
              21.025504495430514
            ],
            [
              105.82578249091145,
              21.025512505066818
            ],
            [
              105.82581511659359,
              21.025585744516857
            ],
            [
              105.82591963984993,
              21.0255534045684
            ],
            [
              105.82602715487866,
              21.02550377855653
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Ngõ 127, Hào Nam",
          "maTaiSan": "04.O13.DOPP.187",
          "duongKinh": 150,
          "chieudaiQL": 285.52,
          "chatLieu": "G-CI",
          "from_latitude": 21.023955374207176,
          "to_latitude": 21.02550377855653,
          "to_longitude": 105.82602715487866,
          "from_longitude": 105.82468600259209
        }
      },
      {
        "id": 186,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82512780895519,
            21.025074674348396,
            105.82563971882392,
            21.025282595560956
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82563971882392,
              21.025074674348396
            ],
            [
              105.82563719585391,
              21.02507543717695
            ],
            [
              105.82554142369251,
              21.025104371759134
            ],
            [
              105.82544281306232,
              21.025140023802386
            ],
            [
              105.8254366781002,
              21.025142242093775
            ],
            [
              105.82537246753233,
              21.025171773068948
            ],
            [
              105.82528598983482,
              21.025222679418437
            ],
            [
              105.82520782620875,
              21.02526371919646
            ],
            [
              105.82520120945526,
              21.025267193493907
            ],
            [
              105.82519326233047,
              21.025268933731414
            ],
            [
              105.825190200122,
              21.025269603428104
            ],
            [
              105.82515415027933,
              21.025277496404065
            ],
            [
              105.82514574077425,
              21.025279124173238
            ],
            [
              105.82514501331514,
              21.025279264993046
            ],
            [
              105.82512780895519,
              21.025282595560956
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngách 127/38, Hào Nam",
          "maTaiSan": "04.O13.DOPP.188",
          "duongKinh": 100,
          "chieudaiQL": 55,
          "chatLieu": "G-CI",
          "from_latitude": 21.025074674348396,
          "to_latitude": 21.025282595560956,
          "to_longitude": 105.82512780895519,
          "from_longitude": 105.82563971882392
        }
      },
      {
        "id": 187,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82237156113322,
            21.025543222133358,
            105.82482920976338,
            21.027581760256968
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82237156113322,
              21.025543222133358
            ],
            [
              105.82237753726766,
              21.025548339814364
            ],
            [
              105.82240481118102,
              21.02557169630627
            ],
            [
              105.82244798293215,
              21.025604831005115
            ],
            [
              105.8224782982331,
              21.025628098711426
            ],
            [
              105.82250034865763,
              21.02564622367628
            ],
            [
              105.82255116090565,
              21.025687990553656
            ],
            [
              105.82256139564268,
              21.025696403371075
            ],
            [
              105.82264454133617,
              21.025773668654736
            ],
            [
              105.82265268625144,
              21.025779912092094
            ],
            [
              105.82277234438538,
              21.025871629322452
            ],
            [
              105.82285554043989,
              21.025957856958676
            ],
            [
              105.82305997975398,
              21.02610622951861
            ],
            [
              105.82334065331202,
              21.026347540447627
            ],
            [
              105.8237440828381,
              21.026694389792137
            ],
            [
              105.82397271397097,
              21.02689110203618
            ],
            [
              105.82426196023754,
              21.027139965925144
            ],
            [
              105.8246820589475,
              21.027489602542808
            ],
            [
              105.82473431241709,
              21.027533091410756
            ],
            [
              105.82475358607971,
              21.02754912230693
            ],
            [
              105.82478376785662,
              21.027562148554974
            ],
            [
              105.82482920976338,
              21.027581760256968
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D160_Giảng Võ",
          "maTaiSan": "04.O13.DOPP.189",
          "duongKinh": 160,
          "chieudaiQL": 335.99,
          "chatLieu": "HDPE",
          "from_latitude": 21.025543222133358,
          "to_latitude": 21.027581760256968,
          "to_longitude": 105.82482920976338,
          "from_longitude": 105.82237156113322
        }
      },
      {
        "id": 188,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82800348104574,
            21.02715636114903,
            105.8284841602298,
            21.02926565098292
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8284841602298,
              21.02926565098292
            ],
            [
              105.82845985460082,
              21.029098373956806
            ],
            [
              105.82843876719262,
              21.029025820434985
            ],
            [
              105.82843628020261,
              21.029017262759943
            ],
            [
              105.82843543423753,
              21.029014241080223
            ],
            [
              105.82839263007475,
              21.028861254651762
            ],
            [
              105.82839204076973,
              21.028856281660175
            ],
            [
              105.828388035639,
              21.028822470003508
            ],
            [
              105.8283816974156,
              21.028788101584446
            ],
            [
              105.82834127897203,
              21.028568923365366
            ],
            [
              105.82833402599172,
              21.02854275525643
            ],
            [
              105.82832983263812,
              21.028527626805207
            ],
            [
              105.8283117173947,
              21.028462271026882
            ],
            [
              105.82827483294353,
              21.028265952430367
            ],
            [
              105.82827204753129,
              21.02825474343521
            ],
            [
              105.828269847516,
              21.028245886294464
            ],
            [
              105.82819601968201,
              21.02794873370704
            ],
            [
              105.82819399854323,
              21.02794473955695
            ],
            [
              105.8281929276692,
              21.027942623098365
            ],
            [
              105.82819229157583,
              21.027941368004775
            ],
            [
              105.82818685453893,
              21.02793062353393
            ],
            [
              105.82818652740434,
              21.027929395838775
            ],
            [
              105.82815044933682,
              21.027793829561784
            ],
            [
              105.82812217855641,
              21.027687602627726
            ],
            [
              105.82812049640933,
              21.027680538572234
            ],
            [
              105.82811556550959,
              21.027657971135287
            ],
            [
              105.82808299063255,
              21.02750888637694
            ],
            [
              105.82808288688167,
              21.027507868044964
            ],
            [
              105.82808266824426,
              21.027505731177342
            ],
            [
              105.82808230420916,
              21.027502176955338
            ],
            [
              105.82808204281825,
              21.02749961487718
            ],
            [
              105.82807545710153,
              21.027435221752054
            ],
            [
              105.82807155661725,
              21.02742102850856
            ],
            [
              105.82801871704142,
              21.027228749955484
            ],
            [
              105.82800348104574,
              21.02715636114903
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Ngõ 35, Cát Linh",
          "maTaiSan": "04.O13.DOPP.190",
          "duongKinh": 150,
          "chieudaiQL": 323,
          "chatLieu": "G-CI",
          "from_latitude": 21.02926565098292,
          "to_latitude": 21.02715636114903,
          "to_longitude": 105.82800348104574,
          "from_longitude": 105.8284841602298
        }
      },
      {
        "id": 189,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8284841602298,
            21.02871036547711,
            105.83141349200541,
            21.02926565098292
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8284841602298,
              21.02926565098292
            ],
            [
              105.82879401198494,
              21.029208023690085
            ],
            [
              105.82881113861698,
              21.0292048385402
            ],
            [
              105.8290416162595,
              21.029161977901616
            ],
            [
              105.82936926208943,
              21.029101042820926
            ],
            [
              105.8295974003724,
              21.029049951831304
            ],
            [
              105.8298546462309,
              21.029000215130026
            ],
            [
              105.82989472247202,
              21.028993488327714
            ],
            [
              105.8302218016214,
              21.02893858792459
            ],
            [
              105.83053548679747,
              21.02887527465978
            ],
            [
              105.83055660293925,
              21.028871757334553
            ],
            [
              105.83066358689466,
              21.028853938008606
            ],
            [
              105.83067720041021,
              21.02885167034876
            ],
            [
              105.83084745391412,
              21.028823313709477
            ],
            [
              105.83091709273587,
              21.028809599395757
            ],
            [
              105.83127699608347,
              21.028738722038554
            ],
            [
              105.83128660675978,
              21.028736828865767
            ],
            [
              105.83129171336276,
              21.028735823000066
            ],
            [
              105.83130258483892,
              21.02873368250192
            ],
            [
              105.83139479191564,
              21.028714296513183
            ],
            [
              105.83141349200541,
              21.02871036547711
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Cát Linh",
          "maTaiSan": "04.O13.DOPP.191",
          "duongKinh": 150,
          "chieudaiQL": 310,
          "chatLieu": "G-DI",
          "from_latitude": 21.02926565098292,
          "to_latitude": 21.02871036547711,
          "to_longitude": 105.83141349200541,
          "from_longitude": 105.8284841602298
        }
      },
      {
        "id": 190,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82894215506175,
            21.023128493953028,
            105.82922311997739,
            21.025398155615896
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82914125588427,
              21.023128493953028
            ],
            [
              105.82919030609968,
              21.023269306010583
            ],
            [
              105.82922066521941,
              21.02338476353316
            ],
            [
              105.82922231494841,
              21.023408578944395
            ],
            [
              105.82922292235672,
              21.02341734719794
            ],
            [
              105.82922311997739,
              21.023420204940575
            ],
            [
              105.8292169605479,
              21.02347381064683
            ],
            [
              105.829192847309,
              21.02354459741272
            ],
            [
              105.82919832689993,
              21.023582188198972
            ],
            [
              105.82920184667371,
              21.02360634042765
            ],
            [
              105.82920236061871,
              21.023609863207845
            ],
            [
              105.82920633733443,
              21.023637145591664
            ],
            [
              105.82921401177299,
              21.023689793269718
            ],
            [
              105.8292090523309,
              21.023707291234043
            ],
            [
              105.82920356447815,
              21.0237266524173
            ],
            [
              105.82919250007748,
              21.02376568863463
            ],
            [
              105.82916695832816,
              21.023855801432976
            ],
            [
              105.82916020040597,
              21.023879643381722
            ],
            [
              105.82915562324388,
              21.023939132201267
            ],
            [
              105.82913939197088,
              21.024018874842856
            ],
            [
              105.8291388309879,
              21.02402163241293
            ],
            [
              105.82909202955587,
              21.024070764766932
            ],
            [
              105.82909081586547,
              21.024075697774002
            ],
            [
              105.82908610408045,
              21.02409484291242
            ],
            [
              105.82906334712116,
              21.024212778599736
            ],
            [
              105.82905835414154,
              21.024238656841277
            ],
            [
              105.82902126119215,
              21.02440315950676
            ],
            [
              105.82901350671682,
              21.024427544629173
            ],
            [
              105.82900268390978,
              21.02446157669506
            ],
            [
              105.8289824603569,
              21.024553694893758
            ],
            [
              105.82898086703095,
              21.024560954650624
            ],
            [
              105.82895852801167,
              21.024620361136495
            ],
            [
              105.82894362457355,
              21.024659993210147
            ],
            [
              105.8289454247863,
              21.024757483918975
            ],
            [
              105.82894558391182,
              21.024766080938353
            ],
            [
              105.82894572673958,
              21.024773818167326
            ],
            [
              105.8289458954405,
              21.024782929074803
            ],
            [
              105.8289460122553,
              21.024789267335645
            ],
            [
              105.82894603846808,
              21.02479070243177
            ],
            [
              105.82894239044549,
              21.024856271737807
            ],
            [
              105.82894215506175,
              21.02486050268588
            ],
            [
              105.82894552392693,
              21.024894421316432
            ],
            [
              105.82897166223079,
              21.02496016427321
            ],
            [
              105.8289985452497,
              21.025045759180262
            ],
            [
              105.82905444455267,
              21.025207804267325
            ],
            [
              105.82906191494295,
              21.02522945969016
            ],
            [
              105.82909440688985,
              21.02533129791963
            ],
            [
              105.82910359107153,
              21.02536764767356
            ],
            [
              105.82910740075913,
              21.025394987770838
            ],
            [
              105.829108834343,
              21.025398155615896
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ Thổ Quan 1, Hào Nam",
          "maTaiSan": "04.O13.DOPP.192",
          "duongKinh": 100,
          "chieudaiQL": 271,
          "chatLieu": "G-CI",
          "from_latitude": 21.023128493953028,
          "to_latitude": 21.025398155615896,
          "to_longitude": 105.829108834343,
          "from_longitude": 105.82914125588427
        }
      },
      {
        "id": 191,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82884842012483,
            21.021954071561634,
            105.82903469430235,
            21.022671872381284
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82884842012483,
              21.021954071561634
            ],
            [
              105.82885240368705,
              21.021963470978708
            ],
            [
              105.82887047285789,
              21.021991549090227
            ],
            [
              105.82888403533676,
              21.022030621538047
            ],
            [
              105.82894476382364,
              21.022227550149633
            ],
            [
              105.82894673172663,
              21.02223393268102
            ],
            [
              105.82896469812417,
              21.0222860424816
            ],
            [
              105.82896820106755,
              21.022296202043254
            ],
            [
              105.82898678274135,
              21.022381993709196
            ],
            [
              105.82900357001978,
              21.022522882703015
            ],
            [
              105.82900387567943,
              21.022525447274923
            ],
            [
              105.82900413648981,
              21.0225350373481
            ],
            [
              105.82900425831117,
              21.02253949778172
            ],
            [
              105.82902917555525,
              21.022637275964815
            ],
            [
              105.82903469430235,
              21.022671872381284
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D200_Ngõ Thổ Quan 1, Hào Nam",
          "maTaiSan": "04.O13.DOPP.193",
          "duongKinh": 200,
          "chieudaiQL": 90,
          "chatLieu": "G-CI",
          "from_latitude": 21.021954071561634,
          "to_latitude": 21.022671872381284,
          "to_longitude": 105.82903469430235,
          "from_longitude": 105.82884842012483
        }
      },
      {
        "id": 192,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82884842012483,
            21.02176000501965,
            105.82927861994833,
            21.021954071561634
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82927861994833,
              21.02176000501965
            ],
            [
              105.82926211940811,
              21.021767529847633
            ],
            [
              105.82919207558571,
              21.021799474264785
            ],
            [
              105.829065165457,
              21.021857352854248
            ],
            [
              105.82905428502833,
              21.021862314915957
            ],
            [
              105.82893937119161,
              21.02191472191364
            ],
            [
              105.82891816220558,
              21.021923898170595
            ],
            [
              105.82884842012483,
              21.021954071561634
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D200_Ngõ Thổ Quan 1, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DOPP.194",
          "duongKinh": 200,
          "chieudaiQL": 50,
          "chatLieu": "G-CI",
          "from_latitude": 21.02176000501965,
          "to_latitude": 21.021954071561634,
          "to_longitude": 105.82884842012483,
          "from_longitude": 105.82927861994833
        }
      },
      {
        "id": 193,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.828007300413,
            21.021954071561634,
            105.82884842012483,
            21.022202573790693
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82884842012483,
              21.021954071561634
            ],
            [
              105.82874596212946,
              21.02197816034158
            ],
            [
              105.8287084647404,
              21.02196328718625
            ],
            [
              105.82868319660592,
              21.021968710421046
            ],
            [
              105.82866275476646,
              21.021973098526637
            ],
            [
              105.82857874959849,
              21.021991130599957
            ],
            [
              105.82854627132552,
              21.0219981027437
            ],
            [
              105.82843848055057,
              21.02202124002861
            ],
            [
              105.82843295662533,
              21.022061242767702
            ],
            [
              105.82839203723488,
              21.02207139831578
            ],
            [
              105.82838137292806,
              21.022074045220382
            ],
            [
              105.82835215928434,
              21.02208392336063
            ],
            [
              105.82824511200805,
              21.02212011934421
            ],
            [
              105.82812608642341,
              21.022158462742357
            ],
            [
              105.82810892925815,
              21.02216399001458
            ],
            [
              105.828007300413,
              21.022202573790693
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ Thổ Quan 1, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DOPP.195",
          "duongKinh": 100,
          "chieudaiQL": 99,
          "chatLieu": "G-CI",
          "from_latitude": 21.021954071561634,
          "to_latitude": 21.022202573790693,
          "to_longitude": 105.828007300413,
          "from_longitude": 105.82884842012483
        }
      },
      {
        "id": 194,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82969322232188,
            21.022532845029414,
            105.83063923079075,
            21.02287472020859
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83063923079075,
              21.022532845029414
            ],
            [
              105.83061588865185,
              21.02254297939726
            ],
            [
              105.83052269427704,
              21.02258422863099
            ],
            [
              105.83051594243386,
              21.022584805341555
            ],
            [
              105.83051275978036,
              21.02258507739818
            ],
            [
              105.83049062461158,
              21.02259724172379
            ],
            [
              105.830479238688,
              21.02259743280287
            ],
            [
              105.83045835953851,
              21.022597901459164
            ],
            [
              105.83043839223319,
              21.02259834940275
            ],
            [
              105.83041989238777,
              21.022605476750737
            ],
            [
              105.83040584087153,
              21.022610889735617
            ],
            [
              105.83034886301866,
              21.022632840786102
            ],
            [
              105.83032260417895,
              21.022648024831007
            ],
            [
              105.83022987300883,
              21.022724831598854
            ],
            [
              105.83020852253793,
              21.02273886444299
            ],
            [
              105.83018650305272,
              21.022743610885044
            ],
            [
              105.83017432086517,
              21.022745414473693
            ],
            [
              105.83017330018649,
              21.022746106809564
            ],
            [
              105.83016757575491,
              21.022749988809863
            ],
            [
              105.83016028328557,
              21.02275456491419
            ],
            [
              105.8301059451947,
              21.022772029329456
            ],
            [
              105.83009607707739,
              21.022762803181433
            ],
            [
              105.83008955184314,
              21.02275670303623
            ],
            [
              105.82999424087701,
              21.02275469063182
            ],
            [
              105.82993108293412,
              21.022780083183203
            ],
            [
              105.82992373822334,
              21.022785998111434
            ],
            [
              105.82991364871003,
              21.022794124881425
            ],
            [
              105.82973795261336,
              21.02285646008609
            ],
            [
              105.82970998263029,
              21.02286770529483
            ],
            [
              105.82969322232188,
              21.02287472020859
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngách 60, ngõ Thịnh Hào 3, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DOPP.196",
          "duongKinh": 100,
          "chieudaiQL": 108.69,
          "chatLieu": "G-CI",
          "from_latitude": 21.022532845029414,
          "to_latitude": 21.02287472020859,
          "to_longitude": 105.82969322232188,
          "from_longitude": 105.83063923079075
        }
      },
      {
        "id": 195,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82858685342714,
            21.020040624497817,
            105.82898793117751,
            21.021111399959473
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82858685342714,
              21.020040624497817
            ],
            [
              105.82859881027252,
              21.020073980164582
            ],
            [
              105.8286010711068,
              21.020080286308126
            ],
            [
              105.82860684833491,
              21.02013332354585
            ],
            [
              105.82860910714668,
              21.020154061430294
            ],
            [
              105.82862003842588,
              21.020181649578806
            ],
            [
              105.82864311656482,
              21.02023989452966
            ],
            [
              105.82864738078746,
              21.020250655554506
            ],
            [
              105.8286946184294,
              21.020385954353184
            ],
            [
              105.82869920652963,
              21.020399095603022
            ],
            [
              105.82871037111937,
              21.020442436659298
            ],
            [
              105.82871137974514,
              21.020446353548625
            ],
            [
              105.82876316617363,
              21.02055945779114
            ],
            [
              105.82876594615138,
              21.020565529273462
            ],
            [
              105.8288043699121,
              21.020650780823576
            ],
            [
              105.82883674869814,
              21.02073202982228
            ],
            [
              105.82885896874943,
              21.020801901616323
            ],
            [
              105.82889214929209,
              21.020910714024925
            ],
            [
              105.82889878646338,
              21.020931519081856
            ],
            [
              105.82889978106978,
              21.02093463849026
            ],
            [
              105.82890237885026,
              21.02093924582667
            ],
            [
              105.82891983080113,
              21.02097019649312
            ],
            [
              105.82895181764452,
              21.021033595131463
            ],
            [
              105.82895817475355,
              21.02104619575132
            ],
            [
              105.82898303902167,
              21.02109912458802
            ],
            [
              105.82898402232541,
              21.021101591019217
            ],
            [
              105.82898793117751,
              21.021111399959473
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ Cẩm Văn, La Thành",
          "maTaiSan": "04.O13.DOPP.197",
          "duongKinh": 100,
          "chieudaiQL": 137,
          "chatLieu": "G-CI",
          "from_latitude": 21.020040624497817,
          "to_latitude": 21.021111399959473,
          "to_longitude": 105.82898793117751,
          "from_longitude": 105.82858685342714
        }
      },
      {
        "id": 196,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82927861994833,
            21.02130221132159,
            105.83020209098112,
            21.02176000501965
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83020209098112,
              21.02130221132159
            ],
            [
              105.83008731636608,
              21.021359109228854
            ],
            [
              105.82982640306132,
              21.021488452400497
            ],
            [
              105.82964598695088,
              21.021577890991146
            ],
            [
              105.82927861994833,
              21.02176000501965
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D200_Ngõ Thổ Quan 1, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DOPP.198",
          "duongKinh": 200,
          "chieudaiQL": 127,
          "chatLieu": "G-CI",
          "from_latitude": 21.02130221132159,
          "to_latitude": 21.02176000501965,
          "to_longitude": 105.82927861994833,
          "from_longitude": 105.83020209098112
        }
      },
      {
        "id": 197,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8316316944928,
            21.023795442024916,
            105.83194270649982,
            21.024498405990098
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8316316944928,
              21.023795442024916
            ],
            [
              105.83163170716368,
              21.023795471769358
            ],
            [
              105.8316375296826,
              21.023809446886997
            ],
            [
              105.83164420043342,
              21.023825459143406
            ],
            [
              105.83167578739712,
              21.023906799896743
            ],
            [
              105.83167641491823,
              21.023908415405927
            ],
            [
              105.83168621618998,
              21.02393365414197
            ],
            [
              105.83173159030841,
              21.024036991421582
            ],
            [
              105.83174839462298,
              21.024082122837314
            ],
            [
              105.83175391598986,
              21.024096952066305
            ],
            [
              105.83176060442823,
              21.024113546809993
            ],
            [
              105.83176341363163,
              21.024120516800682
            ],
            [
              105.83180428706804,
              21.024209701769855
            ],
            [
              105.83181097344779,
              21.02422575007051
            ],
            [
              105.83184305951674,
              21.02430564047466
            ],
            [
              105.83184468660278,
              21.024309692508925
            ],
            [
              105.83184609017547,
              21.024312471187145
            ],
            [
              105.83188857880769,
              21.024396610949996
            ],
            [
              105.83192258188026,
              21.02442651205156
            ],
            [
              105.83194270649982,
              21.024498405990098
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngách 162B/130, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DOPP.199",
          "duongKinh": 100,
          "chieudaiQL": 85,
          "chatLieu": "G-CI",
          "from_latitude": 21.023795442024916,
          "to_latitude": 21.024498405990098,
          "to_longitude": 105.83194270649982,
          "from_longitude": 105.8316316944928
        }
      },
      {
        "id": 198,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82969322232188,
            21.02287472020859,
            105.83029702551585,
            21.024143441492463
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83029702551585,
              21.024143441492463
            ],
            [
              105.83025528861147,
              21.024073100708325
            ],
            [
              105.83021883880373,
              21.023971480730395
            ],
            [
              105.83018060037378,
              21.023888390090818
            ],
            [
              105.83016770862544,
              21.023858138998335
            ],
            [
              105.83016552345087,
              21.02385301122049
            ],
            [
              105.83015081397164,
              21.023818493134304
            ],
            [
              105.83002780223599,
              21.023529835865226
            ],
            [
              105.82998643814547,
              21.02340427274232
            ],
            [
              105.82997466137773,
              21.02338061695523
            ],
            [
              105.82997176047611,
              21.023374789958215
            ],
            [
              105.82997090440584,
              21.023373069880076
            ],
            [
              105.82991890381501,
              21.023279674597575
            ],
            [
              105.82983980509377,
              21.023137609384843
            ],
            [
              105.82982704060507,
              21.023114683694125
            ],
            [
              105.82979317299903,
              21.023053855979626
            ],
            [
              105.82973782650936,
              21.022954661995737
            ],
            [
              105.82969801321659,
              21.02288518959846
            ],
            [
              105.82969322232188,
              21.02287472020859
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ Thịnh Hào 3, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DOPP.200",
          "duongKinh": 100,
          "chieudaiQL": 148.76,
          "chatLieu": "G-CI",
          "from_latitude": 21.024143441492463,
          "to_latitude": 21.02287472020859,
          "to_longitude": 105.82969322232188,
          "from_longitude": 105.83029702551585
        }
      },
      {
        "id": 199,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83029677662842,
            21.024139368512017,
            105.8303704544865,
            21.025205572011682
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8303704544865,
              21.025205572011682
            ],
            [
              105.83036925153704,
              21.025201148473226
            ],
            [
              105.83036620280612,
              21.025189830603562
            ],
            [
              105.83036206309272,
              21.025174504131783
            ],
            [
              105.83035833293205,
              21.02516069398025
            ],
            [
              105.83034144014704,
              21.025098148691225
            ],
            [
              105.83032064614973,
              21.024981524728005
            ],
            [
              105.83031937391138,
              21.02497883300833
            ],
            [
              105.83031307403503,
              21.02496550236833
            ],
            [
              105.83031264000951,
              21.02496338823271
            ],
            [
              105.83030767280272,
              21.024939169039058
            ],
            [
              105.83029677662842,
              21.02481017405603
            ],
            [
              105.8302971576476,
              21.02461164257497
            ],
            [
              105.83029822942152,
              21.024591860341303
            ],
            [
              105.8302995866173,
              21.024566814528836
            ],
            [
              105.83029997150464,
              21.024559708789948
            ],
            [
              105.83029972258342,
              21.02453960152059
            ],
            [
              105.83029904970824,
              21.024485152116497
            ],
            [
              105.8303163474788,
              21.024348475749793
            ],
            [
              105.83031902619398,
              21.024338843331897
            ],
            [
              105.83033190758636,
              21.024292519997633
            ],
            [
              105.8303497000414,
              21.02425524647745
            ],
            [
              105.83035587627892,
              21.024241568601983
            ],
            [
              105.8303571858467,
              21.02423866918038
            ],
            [
              105.8303578482237,
              21.024237200916417
            ],
            [
              105.83035112619703,
              21.024218509893633
            ],
            [
              105.83032144458183,
              21.024168776947537
            ],
            [
              105.83032096879163,
              21.024167979916793
            ],
            [
              105.8303146754453,
              21.024154084727495
            ],
            [
              105.83030801103446,
              21.024139368512017
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngách 74, ngõ Thịnh Hào 1, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DOPP.201",
          "duongKinh": 100,
          "chieudaiQL": 125.39,
          "chatLieu": "G-CI",
          "from_latitude": 21.025205572011682,
          "to_latitude": 21.024139368512017,
          "to_longitude": 105.83030801103446,
          "from_longitude": 105.8303704544865
        }
      },
      {
        "id": 200,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83325365347633,
            21.02582931481307,
            105.83333283065879,
            21.025898722959813
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83325365347633,
              21.025855105504025
            ],
            [
              105.83330214223028,
              21.02582931481307
            ],
            [
              105.83332857754336,
              21.02588269947809
            ],
            [
              105.83333283065879,
              21.025892063131693
            ],
            [
              105.83331506196993,
              21.025898722959813
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D160_Tôn Đức Thắng",
          "maTaiSan": "04.O13.DOPP.202",
          "duongKinh": 160,
          "chieudaiQL": 13.94,
          "chatLieu": "HDPE",
          "from_latitude": 21.025855105504025,
          "to_latitude": 21.025898722959813,
          "to_longitude": 105.83331506196993,
          "from_longitude": 105.83325365347633
        }
      },
      {
        "id": 201,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83178732656386,
            21.022662148802837,
            105.83407924943388,
            21.027783750689697
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83407924943388,
              21.027783750689697
            ],
            [
              105.83407923484829,
              21.027783722761303
            ],
            [
              105.83406671455245,
              21.02774349679496
            ],
            [
              105.83406091607624,
              21.027731146558608
            ],
            [
              105.83405014279325,
              21.0277082078471
            ],
            [
              105.83405199086249,
              21.02768660271072
            ],
            [
              105.83405678451358,
              21.02763057212075
            ],
            [
              105.83395736290998,
              21.027412454365376
            ],
            [
              105.83376282421591,
              21.026985656994675
            ],
            [
              105.83343142749683,
              21.026258601296572
            ],
            [
              105.8334256706716,
              21.026245589669465
            ],
            [
              105.83331764865315,
              21.026038360415075
            ],
            [
              105.83330413566567,
              21.025970914269223
            ],
            [
              105.83325365347633,
              21.025855105504025
            ],
            [
              105.83316002155743,
              21.025655310504437
            ],
            [
              105.83315544563226,
              21.025644981074578
            ],
            [
              105.83301682368433,
              21.025332049988474
            ],
            [
              105.83300745267135,
              21.025310895419363
            ],
            [
              105.83299312873822,
              21.02527856061757
            ],
            [
              105.83297838978716,
              21.025245287590977
            ],
            [
              105.83284062787004,
              21.024934297657794
            ],
            [
              105.83263424108817,
              21.024478388899926
            ],
            [
              105.83262030787428,
              21.02444761020759
            ],
            [
              105.83259633334028,
              21.02439465142602
            ],
            [
              105.83240154352029,
              21.02396379336541
            ],
            [
              105.83235582759885,
              21.02388196732796
            ],
            [
              105.83219737495982,
              21.023598355381676
            ],
            [
              105.83219189198954,
              21.02358854158289
            ],
            [
              105.83213038984465,
              21.023478939089
            ],
            [
              105.83212093054965,
              21.023461246046747
            ],
            [
              105.83212066225627,
              21.02346074426351
            ],
            [
              105.83211471993631,
              21.023449628453466
            ],
            [
              105.83211247875883,
              21.023444647298437
            ],
            [
              105.83208009032279,
              21.023372665240096
            ],
            [
              105.83202718034015,
              21.023227439953327
            ],
            [
              105.8319940822304,
              21.02313659389473
            ],
            [
              105.83197539071995,
              21.023096521655862
            ],
            [
              105.8318990622221,
              21.022932884839232
            ],
            [
              105.83183254447704,
              21.022790279447626
            ],
            [
              105.83178732656386,
              21.022662148802837
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D160_Tôn Đức Thắng",
          "maTaiSan": "04.O13.DOPP.203",
          "duongKinh": 160,
          "chieudaiQL": 618.28,
          "chatLieu": "HDPE",
          "from_latitude": 21.027783750689697,
          "to_latitude": 21.022662148802837,
          "to_longitude": 105.83178732656386,
          "from_longitude": 105.83407924943388
        }
      },
      {
        "id": 202,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83125590176405,
            21.025970914269223,
            105.83330413566567,
            21.026792960245935
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83125590176405,
              21.026792960245935
            ],
            [
              105.83126035792253,
              21.026791249434673
            ],
            [
              105.83147487443999,
              21.026708880743097
            ],
            [
              105.83148379634628,
              21.026705455449427
            ],
            [
              105.8315134131475,
              21.02669408356262
            ],
            [
              105.83162757486681,
              21.0266502482425
            ],
            [
              105.83164064434958,
              21.02664498175834
            ],
            [
              105.83167310977338,
              21.02663190146533
            ],
            [
              105.83190760684286,
              21.026537421644047
            ],
            [
              105.83197283706465,
              21.026511140262137
            ],
            [
              105.8320853950846,
              21.026465789651756
            ],
            [
              105.83220090015179,
              21.02641948790363
            ],
            [
              105.8325250950838,
              21.02628952861172
            ],
            [
              105.83257788688591,
              21.02626836594839
            ],
            [
              105.83262291333881,
              21.026250315862885
            ],
            [
              105.83291568200215,
              21.02611209527355
            ],
            [
              105.83295136327988,
              21.02609401319628
            ],
            [
              105.83301949296813,
              21.02605948885203
            ],
            [
              105.83303929897825,
              21.02605070914351
            ],
            [
              105.83316955086082,
              21.025992971056876
            ],
            [
              105.83325011129324,
              21.02598559081992
            ],
            [
              105.83330413566567,
              21.025970914269223
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D160_Đoàn Thị Điểm",
          "maTaiSan": "04.O13.DOPP.204",
          "duongKinh": 160,
          "chieudaiQL": 231.56,
          "chatLieu": "HDPE",
          "from_latitude": 21.026792960245935,
          "to_latitude": 21.025970914269223,
          "to_longitude": 105.83330413566567,
          "from_longitude": 105.83125590176405
        }
      },
      {
        "id": 203,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83190760684286,
            21.026537421644047,
            105.8326130270899,
            21.028364969938945
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8326130270899,
              21.028364969938945
            ],
            [
              105.8325953866804,
              21.028319269198672
            ],
            [
              105.83245816999514,
              21.027963782752337
            ],
            [
              105.83240089294864,
              21.027815394326563
            ],
            [
              105.83239720833076,
              21.027805848127286
            ],
            [
              105.83207965099405,
              21.026983147353075
            ],
            [
              105.83203101661404,
              21.026857146233986
            ],
            [
              105.83198069377833,
              21.026726772721638
            ],
            [
              105.8319196508028,
              21.026568558272732
            ],
            [
              105.83190760684286,
              21.026537421644047
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D110_Bích Câu",
          "maTaiSan": "04.O13.DOPP.205",
          "duongKinh": 110,
          "chieudaiQL": 205.24,
          "chatLieu": "HDPE",
          "from_latitude": 21.028364969938945,
          "to_latitude": 21.026537421644047,
          "to_longitude": 105.83190760684286,
          "from_longitude": 105.8326130270899
        }
      },
      {
        "id": 204,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82898402232541,
            21.020605504192204,
            105.83005690558895,
            21.021101591019217
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83005690558895,
              21.02076287908072
            ],
            [
              105.83002282819048,
              21.02072245806242
            ],
            [
              105.82996103335996,
              21.020644804064077
            ],
            [
              105.82995597183977,
              21.020638612767574
            ],
            [
              105.82995253841972,
              21.02063441416995
            ],
            [
              105.82993245506499,
              21.020605504192204
            ],
            [
              105.82983263324334,
              21.020674483658613
            ],
            [
              105.82983255468886,
              21.02067454275146
            ],
            [
              105.82978001219168,
              21.020713885231686
            ],
            [
              105.82977434503897,
              21.020717154551857
            ],
            [
              105.82975908112475,
              21.020725960487834
            ],
            [
              105.82971509088809,
              21.02075198472635
            ],
            [
              105.82965925840186,
              21.02079515392474
            ],
            [
              105.82959220557571,
              21.0208414749883
            ],
            [
              105.82958522141307,
              21.02084629795
            ],
            [
              105.82957978239281,
              21.02084750860493
            ],
            [
              105.82956437617109,
              21.020850940126675
            ],
            [
              105.82954242124838,
              21.02086192112572
            ],
            [
              105.8294958208639,
              21.020887032233325
            ],
            [
              105.8294423415073,
              21.020916857384588
            ],
            [
              105.82938749827363,
              21.020947418072392
            ],
            [
              105.82938414278462,
              21.020948785658387
            ],
            [
              105.82930382122633,
              21.020981521775038
            ],
            [
              105.8292932457401,
              21.020985600816186
            ],
            [
              105.82922851610398,
              21.02101056630563
            ],
            [
              105.82920534445802,
              21.021017064166017
            ],
            [
              105.82918991735906,
              21.021020378337038
            ],
            [
              105.82915129428184,
              21.02103431550679
            ],
            [
              105.82914305829523,
              21.021037871005923
            ],
            [
              105.82906017155689,
              21.021073656156624
            ],
            [
              105.82900022688698,
              21.02109448043568
            ],
            [
              105.82898402232541,
              21.021101591019217
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngách 43, ngõ Thổ Quan 3, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DOPP.206",
          "duongKinh": 100,
          "chieudaiQL": 156,
          "chatLieu": "G-CI",
          "from_latitude": 21.02076287908072,
          "to_latitude": 21.021101591019217,
          "to_longitude": 105.82898402232541,
          "from_longitude": 105.83005690558895
        }
      },
      {
        "id": 205,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83005690558895,
            21.020358075101118,
            105.83072228586768,
            21.02076287908072
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83072228586768,
              21.020358075101118
            ],
            [
              105.83068274352242,
              21.02037341077621
            ],
            [
              105.83064200465363,
              21.020386172675398
            ],
            [
              105.83056031420598,
              21.020441900416873
            ],
            [
              105.83040309782248,
              21.020554991237976
            ],
            [
              105.83040295601508,
              21.020555093091442
            ],
            [
              105.83040094991229,
              21.020555716174773
            ],
            [
              105.83040030073914,
              21.020555918053432
            ],
            [
              105.83025486278466,
              21.020645262363725
            ],
            [
              105.83023671248841,
              21.020660926164688
            ],
            [
              105.83023244442079,
              21.020664609573625
            ],
            [
              105.83013790933263,
              21.020714061884448
            ],
            [
              105.83010193146308,
              21.02073696621728
            ],
            [
              105.83009327058058,
              21.020742479316784
            ],
            [
              105.83005690558895,
              21.02076287908072
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ Thổ Quan 3, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DOPP.207",
          "duongKinh": 100,
          "chieudaiQL": 89,
          "chatLieu": "G-CI",
          "from_latitude": 21.020358075101118,
          "to_latitude": 21.02076287908072,
          "to_longitude": 105.83005690558895,
          "from_longitude": 105.83072228586768
        }
      },
      {
        "id": 206,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82997319780507,
            21.01908978427823,
            105.83052197725499,
            21.019921930376064
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83052197725499,
              21.019921930376064
            ],
            [
              105.83044723464542,
              21.019759188239007
            ],
            [
              105.83043530871501,
              21.019733220940445
            ],
            [
              105.83034101144021,
              21.019527898641545
            ],
            [
              105.83028717940367,
              21.01941068074951
            ],
            [
              105.83026281841394,
              21.01935763673878
            ],
            [
              105.83024243064641,
              21.019327062729257
            ],
            [
              105.83012503965045,
              21.01915102174671
            ],
            [
              105.82997319780507,
              21.01908978427823
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D160_Tôn Đức Thắng",
          "maTaiSan": "04.O13.DOPP.208",
          "duongKinh": 160,
          "chieudaiQL": 123,
          "chatLieu": "HDPE",
          "from_latitude": 21.019921930376064,
          "to_latitude": 21.01908978427823,
          "to_longitude": 105.82997319780507,
          "from_longitude": 105.83052197725499
        }
      },
      {
        "id": 207,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83046530549501,
            21.022165500256683,
            105.83068225310454,
            21.022596153277263
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83046530549501,
              21.022165500256683
            ],
            [
              105.8304716818974,
              21.022180683941535
            ],
            [
              105.83047924371431,
              21.022198692543945
            ],
            [
              105.83048415105581,
              21.022210380696176
            ],
            [
              105.83048451661583,
              21.02221125052299
            ],
            [
              105.83048682015752,
              21.02221673630029
            ],
            [
              105.83052487395729,
              21.02230736068881
            ],
            [
              105.83052808312925,
              21.022315003459642
            ],
            [
              105.83053049390504,
              21.02232074432514
            ],
            [
              105.83054598563447,
              21.022350962830142
            ],
            [
              105.83054684434072,
              21.022352637731455
            ],
            [
              105.8305474229773,
              21.02235376754952
            ],
            [
              105.83056254807947,
              21.02238326977948
            ],
            [
              105.83060983048054,
              21.02247549746373
            ],
            [
              105.83061120265937,
              21.022478173339575
            ],
            [
              105.83063923079075,
              21.022532845029414
            ],
            [
              105.83068225310454,
              21.022596153277263
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ Thịnh Hào 3, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DOPP.209",
          "duongKinh": 100,
          "chieudaiQL": 53,
          "chatLieu": "G-CI",
          "from_latitude": 21.022165500256683,
          "to_latitude": 21.022596153277263,
          "to_longitude": 105.83068225310454,
          "from_longitude": 105.83046530549501
        }
      },
      {
        "id": 208,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83201882422915,
            21.025332049988474,
            105.83301682368433,
            21.02569358793233
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83301682368433,
              21.025332049988474
            ],
            [
              105.83298878672511,
              21.025340451815712
            ],
            [
              105.83287251956173,
              21.025373017965126
            ],
            [
              105.83277667951228,
              21.025400227246582
            ],
            [
              105.83251883585206,
              21.02550003771843
            ],
            [
              105.83224501845851,
              21.025606029967296
            ],
            [
              105.83201882422915,
              21.02569358793233
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D110_Phan Văn Trị",
          "maTaiSan": "04.O13.DOPP.210",
          "duongKinh": 110,
          "chieudaiQL": 109.33,
          "chatLieu": "HDPE",
          "from_latitude": 21.025332049988474,
          "to_latitude": 21.02569358793233,
          "to_longitude": 105.83201882422915,
          "from_longitude": 105.83301682368433
        }
      },
      {
        "id": 209,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83034674416811,
            21.025632683450674,
            105.8306059598376,
            21.026137343293524
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8306059598376,
              21.026137343293524
            ],
            [
              105.83055414931137,
              21.026022944672814
            ],
            [
              105.83054488930156,
              21.02600249844988
            ],
            [
              105.83055947891896,
              21.02594829877646
            ],
            [
              105.83052781486934,
              21.025883929789273
            ],
            [
              105.8305113280868,
              21.02584295334472
            ],
            [
              105.83047174287297,
              21.02579280997153
            ],
            [
              105.83044005728048,
              21.025752672770082
            ],
            [
              105.8303507903031,
              21.02563788613779
            ],
            [
              105.83034876335766,
              21.02563527939383
            ],
            [
              105.83034674416811,
              21.025632683450674
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngách 29, Ngõ An Trạch 1, An Trạch",
          "maTaiSan": "04.O13.DOPP.211",
          "duongKinh": 100,
          "chieudaiQL": 64,
          "chatLieu": "G-CI",
          "from_latitude": 21.026137343293524,
          "to_latitude": 21.025632683450674,
          "to_longitude": 105.83034674416811,
          "from_longitude": 105.8306059598376
        }
      },
      {
        "id": 210,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82760183901858,
            21.025398155615896,
            105.829108834343,
            21.02571976213658
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.829108834343,
              21.025398155615896
            ],
            [
              105.82910739727235,
              21.025398532040803
            ],
            [
              105.82909935024655,
              21.025400638752743
            ],
            [
              105.82909362981583,
              21.02540213618716
            ],
            [
              105.82899957361519,
              21.02542675595672
            ],
            [
              105.82895957675373,
              21.025439150775117
            ],
            [
              105.82895596197889,
              21.02544027123203
            ],
            [
              105.8289525661647,
              21.025441323782957
            ],
            [
              105.82891262974015,
              21.02545090565472
            ],
            [
              105.8288713046861,
              21.025451705007228
            ],
            [
              105.82885606172246,
              21.02545082368717
            ],
            [
              105.82884268589353,
              21.025449574685737
            ],
            [
              105.82883660579328,
              21.025449006383564
            ],
            [
              105.82880282708066,
              21.025453185839403
            ],
            [
              105.82872191463272,
              21.02546493096728
            ],
            [
              105.82869118571952,
              21.025468288056697
            ],
            [
              105.82850236697396,
              21.025499080377262
            ],
            [
              105.82849023256381,
              21.02550034800663
            ],
            [
              105.82836436482552,
              21.02551349338346
            ],
            [
              105.82832194125504,
              21.02552249652371
            ],
            [
              105.82831780084396,
              21.025523374753547
            ],
            [
              105.82827493814871,
              21.02553247124672
            ],
            [
              105.82821689287776,
              21.025541694056614
            ],
            [
              105.82802555589674,
              21.025577277981636
            ],
            [
              105.82793861260697,
              21.025619522388503
            ],
            [
              105.8278939067472,
              21.025634034463195
            ],
            [
              105.8278048061323,
              21.025662957708963
            ],
            [
              105.82767358634317,
              21.02569854124969
            ],
            [
              105.8276678738702,
              21.025700090985104
            ],
            [
              105.82760183901858,
              21.02571976213658
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Hào Nam",
          "maTaiSan": "04.O13.DOPP.212",
          "duongKinh": 100,
          "chieudaiQL": 192,
          "chatLieu": "G-CI",
          "from_latitude": 21.025398155615896,
          "to_latitude": 21.02571976213658,
          "to_longitude": 105.82760183901858,
          "from_longitude": 105.829108834343
        }
      },
      {
        "id": 211,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82724865310348,
            21.023470339677793,
            105.82738473053502,
            21.024162115297205
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82724865310348,
              21.023470339677793
            ],
            [
              105.82725037903398,
              21.023479951532654
            ],
            [
              105.82726375875421,
              21.023554469356117
            ],
            [
              105.82726807959448,
              21.023599376557637
            ],
            [
              105.82727939263505,
              21.02373990850047
            ],
            [
              105.82729832992621,
              21.0237816700243
            ],
            [
              105.82732953373339,
              21.02385048307631
            ],
            [
              105.82734537850908,
              21.023935002048685
            ],
            [
              105.82736252074315,
              21.024108897846848
            ],
            [
              105.82738473053502,
              21.024162115297205
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Ngõ 46, Hào Nam",
          "maTaiSan": "04.O13.DOPP.213",
          "duongKinh": 150,
          "chieudaiQL": 92,
          "chatLieu": "G-CI",
          "from_latitude": 21.023470339677793,
          "to_latitude": 21.024162115297205,
          "to_longitude": 105.82738473053502,
          "from_longitude": 105.82724865310348
        }
      },
      {
        "id": 212,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82766467422452,
            21.024666407090855,
            105.82847335844853,
            21.02481662628333
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82766467422452,
              21.02481662628333
            ],
            [
              105.82779438990403,
              21.024775338998367
            ],
            [
              105.82783470057166,
              21.024765431312225
            ],
            [
              105.82796865693277,
              21.02472367525501
            ],
            [
              105.8279864785696,
              21.02471891701232
            ],
            [
              105.82802086251242,
              21.024709735426587
            ],
            [
              105.8280251812801,
              21.024708582659002
            ],
            [
              105.82814503994828,
              21.02467074193715
            ],
            [
              105.82816271672613,
              21.024666407090855
            ],
            [
              105.82823406622569,
              21.02468437883973
            ],
            [
              105.82829337195295,
              21.024705209212314
            ],
            [
              105.82829707994742,
              21.024706511667652
            ],
            [
              105.82831960900106,
              21.02474848679394
            ],
            [
              105.82833142146525,
              21.024745547070996
            ],
            [
              105.82839257901159,
              21.024730327013284
            ],
            [
              105.82842743853539,
              21.024721736448072
            ],
            [
              105.82847335844853,
              21.02471041935487
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngách 146/58, Hào Nam",
          "maTaiSan": "04.O13.DOPP.214",
          "duongKinh": 100,
          "chieudaiQL": 102,
          "chatLieu": "G-CI",
          "from_latitude": 21.02481662628333,
          "to_latitude": 21.02471041935487,
          "to_longitude": 105.82847335844853,
          "from_longitude": 105.82766467422452
        }
      },
      {
        "id": 213,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82743254463759,
            21.024943624885182,
            105.82849023256381,
            21.02550034800663
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82743254463759,
              21.025148546560008
            ],
            [
              105.82747671367672,
              21.025133349866504
            ],
            [
              105.8276257194474,
              21.025082087564392
            ],
            [
              105.82765132059328,
              21.02507328027528
            ],
            [
              105.82766957831701,
              21.025066998911733
            ],
            [
              105.8278475842796,
              21.025053006849785
            ],
            [
              105.82794461006404,
              21.02502697868182
            ],
            [
              105.82800551675747,
              21.02500811366246
            ],
            [
              105.82801271774075,
              21.025005578476875
            ],
            [
              105.8280141617733,
              21.025005069253293
            ],
            [
              105.82809282335188,
              21.02497737093006
            ],
            [
              105.82813041133373,
              21.024968808686793
            ],
            [
              105.82813896427523,
              21.024966860331343
            ],
            [
              105.82822067071156,
              21.02494824982176
            ],
            [
              105.82824097409407,
              21.024943624885182
            ],
            [
              105.82826502356677,
              21.025001351173668
            ],
            [
              105.82826902856509,
              21.025010964559733
            ],
            [
              105.82831769108525,
              21.025127770117628
            ],
            [
              105.82832802348308,
              21.025155202209763
            ],
            [
              105.82837024563767,
              21.02525218262601
            ],
            [
              105.82837701357947,
              21.025267727575486
            ],
            [
              105.8284040746497,
              21.025329883938277
            ],
            [
              105.82844570612333,
              21.02533649235915
            ],
            [
              105.82845480833475,
              21.025363955724806
            ],
            [
              105.82847015512412,
              21.025410261547812
            ],
            [
              105.82848862983248,
              21.0254867631875
            ],
            [
              105.82849023256381,
              21.02550034800663
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 126, Hào Nam",
          "maTaiSan": "04.O13.DOPP.215",
          "duongKinh": 100,
          "chieudaiQL": 183,
          "chatLieu": "G-CI",
          "from_latitude": 21.025148546560008,
          "to_latitude": 21.02550034800663,
          "to_longitude": 105.82849023256381,
          "from_longitude": 105.82743254463759
        }
      },
      {
        "id": 214,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82724865310348,
            21.023136875525864,
            105.82856968062237,
            21.023470339677793
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82724865310348,
              21.023470339677793
            ],
            [
              105.82726251590744,
              21.023461722321148
            ],
            [
              105.82742890029681,
              21.023420037493455
            ],
            [
              105.82747385393594,
              21.02340834873377
            ],
            [
              105.82752360017773,
              21.02339195161576
            ],
            [
              105.82758056070001,
              21.02337317731738
            ],
            [
              105.82761718392702,
              21.023336121336573
            ],
            [
              105.82763697116158,
              21.02330721307545
            ],
            [
              105.82777999355001,
              21.023269037719583
            ],
            [
              105.82779673014065,
              21.0232029901298
            ],
            [
              105.82795855156965,
              21.02315454559048
            ],
            [
              105.82797707189081,
              21.023149000855927
            ],
            [
              105.82800751267253,
              21.02313988725148
            ],
            [
              105.8280261422151,
              21.023136875525864
            ],
            [
              105.82804001508836,
              21.02314577249311
            ],
            [
              105.82804646538115,
              21.02316479100624
            ],
            [
              105.82807152193273,
              21.023208282132533
            ],
            [
              105.8281479402506,
              21.023219261784764
            ],
            [
              105.82818064278938,
              21.023264508564992
            ],
            [
              105.82823539363628,
              21.023269618850385
            ],
            [
              105.82830532086656,
              21.023257329548425
            ],
            [
              105.82851226248857,
              21.023211370800418
            ],
            [
              105.82852362577016,
              21.023212817508583
            ],
            [
              105.82853707032778,
              21.023214528657455
            ],
            [
              105.8285449805319,
              21.02321553515398
            ],
            [
              105.82856968062237,
              21.023258555552214
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngách 46/12, Hào Nam",
          "maTaiSan": "04.O13.DOPP.216",
          "duongKinh": 100,
          "chieudaiQL": 175,
          "chatLieu": "G-CI",
          "from_latitude": 21.023470339677793,
          "to_latitude": 21.023258555552214,
          "to_longitude": 105.82856968062237,
          "from_longitude": 105.82724865310348
        }
      },
      {
        "id": 215,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82714264878685,
            21.022671872381284,
            105.82903469430235,
            21.023111027726205
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82714264878685,
              21.02310310987106
            ],
            [
              105.82718309780189,
              21.023111027726205
            ],
            [
              105.8272656745145,
              21.02307124548172
            ],
            [
              105.82729755624835,
              21.023057574546925
            ],
            [
              105.82730900660881,
              21.023052664918666
            ],
            [
              105.82732204625098,
              21.023047072849796
            ],
            [
              105.82734316154571,
              21.02303725775828
            ],
            [
              105.82736672746245,
              21.023026303518154
            ],
            [
              105.82741294776136,
              21.02300743791185
            ],
            [
              105.8275934631097,
              21.02297166896014
            ],
            [
              105.82772961614593,
              21.022922254831226
            ],
            [
              105.82779952625174,
              21.022909323630568
            ],
            [
              105.82782594134628,
              21.022904437777974
            ],
            [
              105.82784761004763,
              21.02290043037789
            ],
            [
              105.8278994657695,
              21.022890838622903
            ],
            [
              105.82793004848436,
              21.022884266920812
            ],
            [
              105.82804284775163,
              21.022860029815998
            ],
            [
              105.828157297153,
              21.022822797131198
            ],
            [
              105.82816028355468,
              21.022821825171434
            ],
            [
              105.82820392825722,
              21.022807626213392
            ],
            [
              105.82827756846697,
              21.022792364399308
            ],
            [
              105.82829643415577,
              21.02279066388113
            ],
            [
              105.82830902987094,
              21.02278952860528
            ],
            [
              105.8283644595881,
              21.022787304071496
            ],
            [
              105.82836694384105,
              21.022787204359393
            ],
            [
              105.82845289673877,
              21.022773735919447
            ],
            [
              105.82846599607026,
              21.022771472760763
            ],
            [
              105.82847452264873,
              21.022769999616305
            ],
            [
              105.82859111627964,
              21.02274985424627
            ],
            [
              105.82865496127548,
              21.02274238423967
            ],
            [
              105.82866672546079,
              21.022741008214847
            ],
            [
              105.82872799572579,
              21.022737458979396
            ],
            [
              105.82875006018052,
              21.02273544385774
            ],
            [
              105.82875231585423,
              21.022735237770068
            ],
            [
              105.82876727988443,
              21.02272799686249
            ],
            [
              105.82877285698004,
              21.02272529885078
            ],
            [
              105.82879085380338,
              21.02272112948231
            ],
            [
              105.82893441522958,
              21.022687870415865
            ],
            [
              105.8290212441832,
              21.02267401897069
            ],
            [
              105.82903469430235,
              21.022671872381284
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 44, Hào Nam",
          "maTaiSan": "04.O13.DOPP.217",
          "duongKinh": 100,
          "chieudaiQL": 226,
          "chatLieu": "G-CI",
          "from_latitude": 21.02310310987106,
          "to_latitude": 21.022671872381284,
          "to_longitude": 105.82903469430235,
          "from_longitude": 105.82714264878685
        }
      },
      {
        "id": 216,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82669348502667,
            21.022275459673082,
            105.82769885328895,
            21.022597476997177
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82669348502667,
              21.022597476997177
            ],
            [
              105.82690787482078,
              21.02252951937812
            ],
            [
              105.82690820900883,
              21.022529413882477
            ],
            [
              105.82691957707911,
              21.022525809841383
            ],
            [
              105.82696614411162,
              21.022511048734103
            ],
            [
              105.82705141807259,
              21.022484018765496
            ],
            [
              105.82716321651236,
              21.022448579896658
            ],
            [
              105.8273036664703,
              21.022404059666123
            ],
            [
              105.82737134700454,
              21.022381804659748
            ],
            [
              105.82737156401771,
              21.022381733152535
            ],
            [
              105.82737269963403,
              21.022380599500195
            ],
            [
              105.8273729301994,
              21.022380368959436
            ],
            [
              105.82751653936512,
              21.02233570944655
            ],
            [
              105.82756667922555,
              21.022322196213196
            ],
            [
              105.82756746975804,
              21.022321982818553
            ],
            [
              105.82758125099312,
              21.022314818221126
            ],
            [
              105.8276393258224,
              21.022294436169346
            ],
            [
              105.82764961159667,
              21.02229082650814
            ],
            [
              105.82769084902226,
              21.02227779281633
            ],
            [
              105.82769885328895,
              21.022275459673082
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 32A, Hào Nam",
          "maTaiSan": "04.O13.DOPP.218",
          "duongKinh": 100,
          "chieudaiQL": 110.1,
          "chatLieu": "G-CI",
          "from_latitude": 21.022597476997177,
          "to_latitude": 21.022275459673082,
          "to_longitude": 105.82769885328895,
          "from_longitude": 105.82669348502667
        }
      },
      {
        "id": 217,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8257668652097,
            21.02652548386938,
            105.82578586726338,
            21.026791009070894
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8257787268227,
              21.02652548386938
            ],
            [
              105.8257668652097,
              21.026588171085972
            ],
            [
              105.82578056453475,
              21.026692489367516
            ],
            [
              105.82578570397966,
              21.026787563157463
            ],
            [
              105.82578586726338,
              21.026791009070894
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Ngõ 8, Vũ Thạnh",
          "maTaiSan": "04.O13.DOPP.219",
          "duongKinh": 150,
          "chieudaiQL": 26,
          "chatLieu": "G-CI",
          "from_latitude": 21.02652548386938,
          "to_latitude": 21.026791009070894,
          "to_longitude": 105.82578586726338,
          "from_longitude": 105.8257787268227
        }
      },
      {
        "id": 218,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82525708410532,
            21.026960237754967,
            105.82556049314216,
            21.027175979792553
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82556049314216,
              21.026960237754967
            ],
            [
              105.82553146334364,
              21.026977503827975
            ],
            [
              105.82545034294623,
              21.02702786040066
            ],
            [
              105.82529545960689,
              21.027142929713715
            ],
            [
              105.8252881340235,
              21.027148371970465
            ],
            [
              105.82526353552845,
              21.02717024371512
            ],
            [
              105.8252605161207,
              21.02717292830124
            ],
            [
              105.82525708410532,
              21.027175979792553
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Vũ Thạch",
          "maTaiSan": "04.O13.DOPP.220",
          "duongKinh": 150,
          "chieudaiQL": 40,
          "chatLieu": "G-CI",
          "from_latitude": 21.026960237754967,
          "to_latitude": 21.027175979792553,
          "to_longitude": 105.82525708410532,
          "from_longitude": 105.82556049314216
        }
      },
      {
        "id": 219,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8248354279992,
            21.027019678347838,
            105.82522648191079,
            21.027585389963058
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8248354279992,
              21.027585389963058
            ],
            [
              105.82484959736712,
              21.027569977247087
            ],
            [
              105.8251175189275,
              21.027278350235257
            ],
            [
              105.82522648191079,
              21.02717622404213
            ],
            [
              105.82508726725345,
              21.027049697478084
            ],
            [
              105.82505422239967,
              21.027019678347838
            ],
            [
              105.82502007663454,
              21.02705513843125
            ],
            [
              105.8250001950567,
              21.027037016836985
            ],
            [
              105.82501025715683,
              21.02702293461407
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Vũ Thạch",
          "maTaiSan": "04.O13.DOPP.221",
          "duongKinh": 150,
          "chieudaiQL": 116,
          "chatLieu": "G-CI",
          "from_latitude": 21.027585389963058,
          "to_latitude": 21.02702293461407,
          "to_longitude": 105.82501025715683,
          "from_longitude": 105.8248354279992
        }
      },
      {
        "id": 220,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82568789334182,
            21.026037625383285,
            105.82615355044786,
            21.026234551766702
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82568789334182,
              21.026234551766702
            ],
            [
              105.82569927621954,
              21.0262297374066
            ],
            [
              105.8258025148796,
              21.02618607731499
            ],
            [
              105.82599634706452,
              21.026104105845985
            ],
            [
              105.82614335771191,
              21.026041936314527
            ],
            [
              105.82614338265869,
              21.026041924451405
            ],
            [
              105.82615355044786,
              21.026037625383285
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 8, Vũ Thạnh",
          "maTaiSan": "04.O13.DOPP.222",
          "duongKinh": 100,
          "chieudaiQL": 65,
          "chatLieu": "G-CI",
          "from_latitude": 21.026234551766702,
          "to_latitude": 21.026037625383285,
          "to_longitude": 105.82615355044786,
          "from_longitude": 105.82568789334182
        }
      },
      {
        "id": 221,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8257787268227,
            21.026308764715726,
            105.82631077719901,
            21.02652548386938
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8257787268227,
              21.02652548386938
            ],
            [
              105.82578535941413,
              21.02652278172919
            ],
            [
              105.82590938024622,
              21.02647226543219
            ],
            [
              105.82608214040059,
              21.026401895153366
            ],
            [
              105.82624008411263,
              21.026337559780984
            ],
            [
              105.82625892696714,
              21.026329884754567
            ],
            [
              105.82631077719901,
              21.026308764715726
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 8, Vũ Thạnh",
          "maTaiSan": "04.O13.DOPP.223",
          "duongKinh": 100,
          "chieudaiQL": 72,
          "chatLieu": "G-CI",
          "from_latitude": 21.02652548386938,
          "to_latitude": 21.026308764715726,
          "to_longitude": 105.82631077719901,
          "from_longitude": 105.8257787268227
        }
      },
      {
        "id": 222,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82497448159346,
            21.02702293461407,
            105.82510128199198,
            21.027098896200652
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82510128199198,
              21.02703451942789
            ],
            [
              105.82508726725345,
              21.027049697478084
            ],
            [
              105.82508277257521,
              21.027054562378385
            ],
            [
              105.82504183189552,
              21.027098896200652
            ],
            [
              105.82497448159346,
              21.02703512016368
            ],
            [
              105.82501025715683,
              21.02702293461407
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Ngõ 2, Vũ Thạnh",
          "maTaiSan": "04.O13.DOPP.224",
          "duongKinh": 150,
          "chieudaiQL": 10.28,
          "chatLieu": "G-CI",
          "from_latitude": 21.02703451942789,
          "to_latitude": 21.02702293461407,
          "to_longitude": 105.82501025715683,
          "from_longitude": 105.82510128199198
        }
      },
      {
        "id": 223,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82556662818895,
            21.025776488781563,
            105.82604139056409,
            21.025990262645838
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82556662818895,
              21.025990262645838
            ],
            [
              105.82556939959004,
              21.025989015386543
            ],
            [
              105.82556942453684,
              21.02598900352351
            ],
            [
              105.82574411365707,
              21.025910345270642
            ],
            [
              105.8257441386038,
              21.025910333407584
            ],
            [
              105.82581383897872,
              21.0258789502117
            ],
            [
              105.82594960169283,
              21.025817819081382
            ],
            [
              105.82594962663957,
              21.025817807218296
            ],
            [
              105.82604139056409,
              21.025776488781563
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 8, Vũ Thạnh",
          "maTaiSan": "04.O13.DOPP.225",
          "duongKinh": 100,
          "chieudaiQL": 65,
          "chatLieu": "G-CI",
          "from_latitude": 21.025990262645838,
          "to_latitude": 21.025776488781563,
          "to_longitude": 105.82604139056409,
          "from_longitude": 105.82556662818895
        }
      },
      {
        "id": 224,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82403622863326,
            21.026480324469706,
            105.8249597755301,
            21.027079049510185
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82403622863326,
              21.026480324469706
            ],
            [
              105.82406494784811,
              21.026505070063592
            ],
            [
              105.82419534531836,
              21.02661742799598
            ],
            [
              105.82424261239927,
              21.026658145716862
            ],
            [
              105.82433588154511,
              21.02673852234607
            ],
            [
              105.82435371796986,
              21.026753881034704
            ],
            [
              105.82458494783921,
              21.02695312150686
            ],
            [
              105.82473108422336,
              21.027079049510185
            ],
            [
              105.82491864967024,
              21.026901970216514
            ],
            [
              105.8249597755301,
              21.02686314429471
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 2, Vũ Thạnh",
          "maTaiSan": "04.O13.DOPP.226",
          "duongKinh": 100,
          "chieudaiQL": 123,
          "chatLieu": "G-CI",
          "from_latitude": 21.026480324469706,
          "to_latitude": 21.02686314429471,
          "to_longitude": 105.8249597755301,
          "from_longitude": 105.82403622863326
        }
      },
      {
        "id": 225,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82203705925173,
            21.02279817029291,
            105.82285839846881,
            21.025548339814364
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82222016708147,
              21.02279817029291
            ],
            [
              105.82222572283753,
              21.02280857388806
            ],
            [
              105.82223001508521,
              21.022816619848932
            ],
            [
              105.82235541302043,
              21.02305135632554
            ],
            [
              105.82203705925173,
              21.02316247376848
            ],
            [
              105.8220416128305,
              21.023175372406882
            ],
            [
              105.82209768252639,
              21.02333420193326
            ],
            [
              105.82215705809712,
              21.02349660487396
            ],
            [
              105.82209630359273,
              21.023557122040987
            ],
            [
              105.82209899033178,
              21.023592719637943
            ],
            [
              105.82213407185127,
              21.023680904519217
            ],
            [
              105.82216391141546,
              21.023755912279125
            ],
            [
              105.82224184710938,
              21.02378081951288
            ],
            [
              105.82227779966031,
              21.02382959240506
            ],
            [
              105.82230297826167,
              21.023904911703468
            ],
            [
              105.82230728260988,
              21.023917788053534
            ],
            [
              105.82233411619818,
              21.02399106256969
            ],
            [
              105.822336441527,
              21.023997411842462
            ],
            [
              105.82238415642253,
              21.02412106757541
            ],
            [
              105.8224195221417,
              21.024196470365418
            ],
            [
              105.82243296449276,
              21.02422513065079
            ],
            [
              105.82246671550931,
              21.02430850407525
            ],
            [
              105.82248019682059,
              21.024341807651123
            ],
            [
              105.82258920463562,
              21.02458207861441
            ],
            [
              105.82263641873472,
              21.024667863523423
            ],
            [
              105.82266160637465,
              21.024716542970804
            ],
            [
              105.82283966251339,
              21.02493320450789
            ],
            [
              105.8228463168491,
              21.02494130224588
            ],
            [
              105.82285839846881,
              21.024956003400245
            ],
            [
              105.82284116081479,
              21.02505282858796
            ],
            [
              105.82283629942941,
              21.025063271719493
            ],
            [
              105.82280815507909,
              21.025123726197467
            ],
            [
              105.82275706402713,
              21.025182761621323
            ],
            [
              105.82275244758777,
              21.02518647905091
            ],
            [
              105.82274883775715,
              21.02518938503014
            ],
            [
              105.822745459713,
              21.025192105629927
            ],
            [
              105.82272959428826,
              21.025204880831712
            ],
            [
              105.82270962636899,
              21.025222469296615
            ],
            [
              105.82269396279636,
              21.025236266873353
            ],
            [
              105.82268525165686,
              21.02524428991479
            ],
            [
              105.82257141622264,
              21.025349139626012
            ],
            [
              105.82254389761673,
              21.025375863688208
            ],
            [
              105.8225413627771,
              21.025378324592968
            ],
            [
              105.8225337247768,
              21.025385741791542
            ],
            [
              105.82252960243808,
              21.02538974495455
            ],
            [
              105.82252612983679,
              21.025393117233147
            ],
            [
              105.822409079457,
              21.025506787936145
            ],
            [
              105.82238016713055,
              21.025545838710496
            ],
            [
              105.82237753726766,
              21.025548339814364
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 189, Giảng Võ",
          "maTaiSan": "04.O13.DOPP.227",
          "duongKinh": 100,
          "chieudaiQL": 377.85,
          "chatLieu": "G-CI",
          "from_latitude": 21.02279817029291,
          "to_latitude": 21.025548339814364,
          "to_longitude": 105.82237753726766,
          "from_longitude": 105.82222016708147
        }
      },
      {
        "id": 226,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82120204770725,
            21.023147928177114,
            105.82202836359697,
            21.024260631632757
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82120204770725,
              21.023147928177114
            ],
            [
              105.82120318958664,
              21.02315091414852
            ],
            [
              105.82122189661743,
              21.02319978228974
            ],
            [
              105.82133309573807,
              21.02337980021903
            ],
            [
              105.82138103885572,
              21.023446265607085
            ],
            [
              105.82140762040773,
              21.023483116207142
            ],
            [
              105.82141181188545,
              21.02348892719102
            ],
            [
              105.82150711149465,
              21.023666980486027
            ],
            [
              105.82154779584566,
              21.02376280953175
            ],
            [
              105.82156779006351,
              21.023797254115788
            ],
            [
              105.82161339637605,
              21.023815376515913
            ],
            [
              105.82164355024705,
              21.02382735845819
            ],
            [
              105.82184000260418,
              21.023922693152596
            ],
            [
              105.82194953635313,
              21.024110867579118
            ],
            [
              105.8219535355077,
              21.024117738058333
            ],
            [
              105.82195518644087,
              21.024120573434463
            ],
            [
              105.82195742127158,
              21.024124982117737
            ],
            [
              105.82195888295962,
              21.024127866279404
            ],
            [
              105.82198397671598,
              21.024177376357187
            ],
            [
              105.82200931550841,
              21.02422490356871
            ],
            [
              105.82202631950545,
              21.024256798286757
            ],
            [
              105.82202836359697,
              21.024260631632757
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 466, La Thành",
          "maTaiSan": "04.O13.DOPP.228",
          "duongKinh": 100,
          "chieudaiQL": 156.02,
          "chatLieu": "G-CI",
          "from_latitude": 21.023147928177114,
          "to_latitude": 21.024260631632757,
          "to_longitude": 105.82202836359697,
          "from_longitude": 105.82120204770725
        }
      },
      {
        "id": 227,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82610467859578,
            21.01902437036964,
            105.8301299064107,
            21.021097444004962
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8301299064107,
              21.01902437036964
            ],
            [
              105.82999876535453,
              21.019073377994417
            ],
            [
              105.82997319780507,
              21.01908978427823
            ],
            [
              105.82993489679686,
              21.019114361838618
            ],
            [
              105.82986732205026,
              21.019122888284652
            ],
            [
              105.8298619820908,
              21.01912356194808
            ],
            [
              105.82985369770684,
              21.019124607652717
            ],
            [
              105.82980947842603,
              21.019130187622128
            ],
            [
              105.82954718938042,
              21.019239908119243
            ],
            [
              105.8295462018078,
              21.019240321193056
            ],
            [
              105.82950606890006,
              21.019262598949762
            ],
            [
              105.82946182851127,
              21.01928715659532
            ],
            [
              105.82925877096527,
              21.019399871952125
            ],
            [
              105.82925450621187,
              21.019402239321586
            ],
            [
              105.82924593834217,
              21.019406995021406
            ],
            [
              105.8288271919733,
              21.019639436863518
            ],
            [
              105.82852098956509,
              21.019809405352646
            ],
            [
              105.82847664640273,
              21.01983544363616
            ],
            [
              105.82832936630182,
              21.019921923938956
            ],
            [
              105.82831168884412,
              21.01993230407861
            ],
            [
              105.82830614478975,
              21.019935568234658
            ],
            [
              105.82800471735766,
              21.020113032823602
            ],
            [
              105.82779303367259,
              21.020237972981032
            ],
            [
              105.8276357673342,
              21.020323195236035
            ],
            [
              105.82761459520852,
              21.020334668050513
            ],
            [
              105.82751799382648,
              21.020391572458543
            ],
            [
              105.82744969097136,
              21.020431806519696
            ],
            [
              105.82738121836825,
              21.020472141637967
            ],
            [
              105.82736012238878,
              21.020483997922092
            ],
            [
              105.82735154225837,
              21.020488820427616
            ],
            [
              105.8273361753136,
              21.020497456844495
            ],
            [
              105.82732759676188,
              21.020502042696144
            ],
            [
              105.8273163853686,
              21.020508035932895
            ],
            [
              105.82729998022837,
              21.020516805654136
            ],
            [
              105.82712890779797,
              21.02060825550226
            ],
            [
              105.8270925534437,
              21.020627689946686
            ],
            [
              105.8269892642083,
              21.020677471231718
            ],
            [
              105.82694612866622,
              21.02069826093698
            ],
            [
              105.82692619766264,
              21.020707866990083
            ],
            [
              105.82686026338723,
              21.020739645955835
            ],
            [
              105.82681205150796,
              21.020762881851997
            ],
            [
              105.82668218648443,
              21.020826939354166
            ],
            [
              105.82665172192077,
              21.020841966245026
            ],
            [
              105.82664920167576,
              21.020843209589483
            ],
            [
              105.82664250436149,
              21.020846512717025
            ],
            [
              105.8266409262078,
              21.02084729083795
            ],
            [
              105.82659023346375,
              21.020872295502997
            ],
            [
              105.82648348145175,
              21.020919055769475
            ],
            [
              105.82634430451554,
              21.020980018654836
            ],
            [
              105.82625023708815,
              21.02102122283385
            ],
            [
              105.82615104567225,
              21.021073167716466
            ],
            [
              105.82611351387153,
              21.021092822643865
            ],
            [
              105.82610467859578,
              21.021097444004962
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Đường La Thành",
          "maTaiSan": "04.O13.DOPP.229",
          "duongKinh": 100,
          "chieudaiQL": 483.81,
          "chatLieu": "G",
          "from_latitude": 21.01902437036964,
          "to_latitude": 21.021097444004962,
          "to_longitude": 105.82610467859578,
          "from_longitude": 105.8301299064107
        }
      },
      {
        "id": 228,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82438325175809,
            21.02399763253362,
            105.82458129219386,
            21.024742740255952
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82458129219386,
              21.024742740255952
            ],
            [
              105.82457997864782,
              21.02473636714408
            ],
            [
              105.82457850497563,
              21.02472921532507
            ],
            [
              105.82457811161451,
              21.024727308717114
            ],
            [
              105.82457793864478,
              21.024726468652958
            ],
            [
              105.82457728456868,
              21.024722460600465
            ],
            [
              105.82457635674068,
              21.024716779295453
            ],
            [
              105.82457399635176,
              21.024702325585743
            ],
            [
              105.82456122577605,
              21.024624126638606
            ],
            [
              105.82455141476672,
              21.024572072553738
            ],
            [
              105.82455083046719,
              21.024567807646733
            ],
            [
              105.82454186888609,
              21.024502455771422
            ],
            [
              105.82453886379407,
              21.024495492021508
            ],
            [
              105.8245142833128,
              21.024438543604813
            ],
            [
              105.82449459343745,
              21.024375787218993
            ],
            [
              105.82449236329691,
              21.02436734745371
            ],
            [
              105.82448276685156,
              21.024331036444053
            ],
            [
              105.82445845090193,
              21.02424381152173
            ],
            [
              105.82443655901636,
              21.024178945027728
            ],
            [
              105.82443010568575,
              21.02415982522166
            ],
            [
              105.82441685870768,
              21.0241205765489
            ],
            [
              105.82438492268628,
              21.024003746496422
            ],
            [
              105.82438325175809,
              21.02399763253362
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngách 318/100, La Thành",
          "maTaiSan": "04.O13.DOPP.230",
          "duongKinh": 100,
          "chieudaiQL": 78.52,
          "chatLieu": "G-CI",
          "from_latitude": 21.024742740255952,
          "to_latitude": 21.02399763253362,
          "to_longitude": 105.82438325175809,
          "from_longitude": 105.82458129219386
        }
      },
      {
        "id": 229,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82396399516641,
            21.024117154910645,
            105.82416891662433,
            21.025333310628053
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82396399516641,
              21.024117154910645
            ],
            [
              105.82397545376372,
              21.024173572667884
            ],
            [
              105.82397630210458,
              21.024177748685442
            ],
            [
              105.82400659678636,
              21.024307373735866
            ],
            [
              105.8240072341814,
              21.02431010019509
            ],
            [
              105.82400795274702,
              21.024312542648218
            ],
            [
              105.82400848114645,
              21.024314341119705
            ],
            [
              105.82402703208844,
              21.024431757259983
            ],
            [
              105.82403690539711,
              21.024495356107348
            ],
            [
              105.82405164235867,
              21.024558392161783
            ],
            [
              105.82406118564842,
              21.024599049775762
            ],
            [
              105.82406324049391,
              21.024607804718894
            ],
            [
              105.82407218206008,
              21.02467760239693
            ],
            [
              105.82409406987209,
              21.02477761887694
            ],
            [
              105.82410084100503,
              21.024805903989673
            ],
            [
              105.82410292737777,
              21.024814618134073
            ],
            [
              105.82410874344572,
              21.02486481470343
            ],
            [
              105.82411027544724,
              21.024878031402974
            ],
            [
              105.82411302751623,
              21.02492997068929
            ],
            [
              105.82411729751412,
              21.02498941564845
            ],
            [
              105.82413086025471,
              21.02509975477758
            ],
            [
              105.82413125933456,
              21.025106027550397
            ],
            [
              105.82413169631243,
              21.025112894461877
            ],
            [
              105.82413031934475,
              21.02518650677715
            ],
            [
              105.82414040122124,
              21.025221877903665
            ],
            [
              105.8241425386533,
              21.025229378767825
            ],
            [
              105.82416617124628,
              21.025322492454197
            ],
            [
              105.82416891662433,
              21.025333310628053
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 318, La Thành",
          "maTaiSan": "04.O13.DOPP.231",
          "duongKinh": 100,
          "chieudaiQL": 130,
          "chatLieu": "G-CI",
          "from_latitude": 21.024117154910645,
          "to_latitude": 21.025333310628053,
          "to_longitude": 105.82416891662433,
          "from_longitude": 105.82396399516641
        }
      },
      {
        "id": 230,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82396399516641,
            21.023942744883865,
            105.82468600259209,
            21.024117154910645
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82468600259209,
              21.023955374207176
            ],
            [
              105.82468297978711,
              21.023954458542487
            ],
            [
              105.82464436449831,
              21.023942744883865
            ],
            [
              105.82438325175809,
              21.02399763253362
            ],
            [
              105.82437551436837,
              21.023995867200128
            ],
            [
              105.82423747545305,
              21.024033181001155
            ],
            [
              105.82417336213854,
              21.02405148937575
            ],
            [
              105.82416034756962,
              21.024055205990663
            ],
            [
              105.82403123574652,
              21.024089528238886
            ],
            [
              105.82397674734145,
              21.024103850939717
            ],
            [
              105.82397323408915,
              21.024107516991876
            ],
            [
              105.82396399516641,
              21.024117154910645
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Ngõ 318, La Thành",
          "maTaiSan": "04.O13.DOPP.232",
          "duongKinh": 150,
          "chieudaiQL": 78.1,
          "chatLieu": "G-CI",
          "from_latitude": 21.023955374207176,
          "to_latitude": 21.024117154910645,
          "to_longitude": 105.82396399516641,
          "from_longitude": 105.82468600259209
        }
      },
      {
        "id": 231,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8336792057762,
            21.02807462017436,
            105.83435072207578,
            21.029122459059593
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8336792057762,
              21.02807462017436
            ],
            [
              105.83375349371342,
              21.028167334028936
            ],
            [
              105.83376829899672,
              21.028185810936026
            ],
            [
              105.83379037476672,
              21.028220119118046
            ],
            [
              105.8337991093983,
              21.028233694188508
            ],
            [
              105.83380548558796,
              21.028247864305193
            ],
            [
              105.83383265232102,
              21.0282899450454
            ],
            [
              105.83395784213845,
              21.02848386827294
            ],
            [
              105.83397166717592,
              21.028505283636974
            ],
            [
              105.83400936197684,
              21.028583606933818
            ],
            [
              105.83402226853777,
              21.02861042446049
            ],
            [
              105.83416803793759,
              21.028820161365953
            ],
            [
              105.83417968604901,
              21.02883692133827
            ],
            [
              105.83432535171832,
              21.0290773718026
            ],
            [
              105.83435072207578,
              21.029122459059593
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Phan Phù Tiên",
          "maTaiSan": "04.O13.DOPP.233",
          "duongKinh": 100,
          "chieudaiQL": 135,
          "chatLieu": "G-CI",
          "from_latitude": 21.02807462017436,
          "to_latitude": 21.029122459059593,
          "to_longitude": 105.83435072207578,
          "from_longitude": 105.8336792057762
        }
      },
      {
        "id": 232,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83197018662172,
            21.029169310059263,
            105.83281672920972,
            21.0312768868854
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83197018662172,
              21.029169310059263
            ],
            [
              105.83211208655709,
              21.029575576646067
            ],
            [
              105.83237059995477,
              21.030315706998458
            ],
            [
              105.83239647246945,
              21.030389781952422
            ],
            [
              105.83259425817047,
              21.030956014360303
            ],
            [
              105.83261591278433,
              21.031018038343632
            ],
            [
              105.83268024362354,
              21.03121101941674
            ],
            [
              105.8328147181177,
              21.031271788105276
            ],
            [
              105.83281672920972,
              21.0312768868854
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D225_Trịnh Hoài Đức",
          "maTaiSan": "04.O13.DOPP.234",
          "duongKinh": 225,
          "chieudaiQL": 233,
          "chatLieu": "HDPE",
          "from_latitude": 21.029169310059263,
          "to_latitude": 21.0312768868854,
          "to_longitude": 105.83281672920972,
          "from_longitude": 105.83197018662172
        }
      },
      {
        "id": 233,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83170619631284,
            21.03020583215815,
            105.8321390622397,
            21.031550283101925
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8321390622397,
              21.031550283101925
            ],
            [
              105.83210100271592,
              21.03142616686736
            ],
            [
              105.83200980980625,
              21.03114883762182
            ],
            [
              105.83199593858537,
              21.031105755806283
            ],
            [
              105.83195347231766,
              21.030973858141866
            ],
            [
              105.83190460601284,
              21.03082208292482
            ],
            [
              105.83185516405572,
              21.030668491412488
            ],
            [
              105.83181715109748,
              21.03055042465204
            ],
            [
              105.83173594713078,
              21.030298236336083
            ],
            [
              105.83172755204929,
              21.030272160692466
            ],
            [
              105.83172591057891,
              21.030267088992552
            ],
            [
              105.83171932829896,
              21.030246620725
            ],
            [
              105.83170619631284,
              21.03020583215815
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Lý Văn Phức",
          "maTaiSan": "04.O13.DOPP.235",
          "duongKinh": 100,
          "chieudaiQL": 183,
          "chatLieu": "G-DI",
          "from_latitude": 21.031550283101925,
          "to_latitude": 21.03020583215815,
          "to_longitude": 105.83170619631284,
          "from_longitude": 105.8321390622397
        }
      },
      {
        "id": 234,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82770530698093,
            21.029396817436844,
            105.82785692555251,
            21.029478044720094
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82785692555251,
              21.029396817436844
            ],
            [
              105.82770530698093,
              21.029424975630267
            ],
            [
              105.82770893516782,
              21.029442989036347
            ],
            [
              105.82771641033382,
              21.029478044720094
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Cát Linh",
          "maTaiSan": "04.O13.DOPP.236",
          "duongKinh": 150,
          "chieudaiQL": 17.54,
          "chatLieu": "G-DI",
          "from_latitude": 21.029396817436844,
          "to_latitude": 21.029478044720094,
          "to_longitude": 105.82771641033382,
          "from_longitude": 105.82785692555251
        }
      },
      {
        "id": 235,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82785405563227,
            21.029382673368428,
            105.82788692449186,
            21.02954992867157
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82785405563227,
              21.029382673368428
            ],
            [
              105.82785692555251,
              21.029396817436844
            ],
            [
              105.82785782449467,
              21.029401246085094
            ],
            [
              105.82786128776398,
              21.029418311001642
            ],
            [
              105.8278648717257,
              21.02943597778106
            ],
            [
              105.82787119679723,
              21.02946867712495
            ],
            [
              105.82788066490035,
              21.029517590299672
            ],
            [
              105.82788692449186,
              21.02954992867157
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Cát Linh",
          "maTaiSan": "04.O13.DOPP.237",
          "duongKinh": 150,
          "chieudaiQL": 10.61,
          "chatLieu": "G-DI",
          "from_latitude": 21.029382673368428,
          "to_latitude": 21.02954992867157,
          "to_longitude": 105.82788692449186,
          "from_longitude": 105.82785405563227
        }
      },
      {
        "id": 236,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83356385051188,
            21.02883568583955,
            105.83483136400869,
            21.029539189766414
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83483136400869,
              21.02883568583955
            ],
            [
              105.83482483714059,
              21.02883991154865
            ],
            [
              105.83469339621556,
              21.028915415947974
            ],
            [
              105.83467671912834,
              21.028926048361097
            ],
            [
              105.83445371065281,
              21.02906822364638
            ],
            [
              105.83444703761363,
              21.029071599222515
            ],
            [
              105.83437642289938,
              21.02910731776637
            ],
            [
              105.83436423459527,
              21.02911449858503
            ],
            [
              105.83435072207578,
              21.029122459059593
            ],
            [
              105.83419603952161,
              21.029213591791848
            ],
            [
              105.83409699000683,
              21.029293890109052
            ],
            [
              105.83396940762253,
              21.02936726059326
            ],
            [
              105.83396067920029,
              21.02937228016257
            ],
            [
              105.83395734394713,
              21.02937419780539
            ],
            [
              105.8338864981293,
              21.029414939206735
            ],
            [
              105.83367336869604,
              21.029509817443202
            ],
            [
              105.83359655871583,
              21.029530418088846
            ],
            [
              105.83356385051188,
              21.029539189766414
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ Hàng Bột",
          "maTaiSan": "04.O13.DOPP.238",
          "duongKinh": 100,
          "chieudaiQL": 172,
          "chatLieu": "G-CI",
          "from_latitude": 21.02883568583955,
          "to_latitude": 21.029539189766414,
          "to_longitude": 105.83356385051188,
          "from_longitude": 105.83483136400869
        }
      },
      {
        "id": 237,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83197018662172,
            21.028923201283995,
            105.83305149300935,
            21.029169310059263
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83197018662172,
              21.029169310059263
            ],
            [
              105.83202492325064,
              21.02915706819989
            ],
            [
              105.8329927937998,
              21.02894060222281
            ],
            [
              105.83305149300935,
              21.028923201283995
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ Hàng Cháo, Hàng Cháo",
          "maTaiSan": "04.O13.DOPP.239",
          "duongKinh": 100,
          "chieudaiQL": 160,
          "chatLieu": "G-CI",
          "from_latitude": 21.029169310059263,
          "to_latitude": 21.028923201283995,
          "to_longitude": 105.83305149300935,
          "from_longitude": 105.83197018662172
        }
      },
      {
        "id": 238,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83408057064835,
            21.029173624573545,
            105.8350788488718,
            21.03095788204522
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83408057064835,
              21.03095788204522
            ],
            [
              105.83413042437256,
              21.030833789336175
            ],
            [
              105.8343151497337,
              21.030515344098653
            ],
            [
              105.83433176142242,
              21.03048670628589
            ],
            [
              105.8345159825349,
              21.030191365052943
            ],
            [
              105.83452265436318,
              21.03017964279458
            ],
            [
              105.83455031124448,
              21.030130999456745
            ],
            [
              105.83461151656381,
              21.03002337265498
            ],
            [
              105.83463308774785,
              21.02998736817018
            ],
            [
              105.83466851215229,
              21.029928218330646
            ],
            [
              105.83467858905756,
              21.029911745559385
            ],
            [
              105.83475730041924,
              21.029783081609587
            ],
            [
              105.83477953153603,
              21.02974017685589
            ],
            [
              105.83478877928246,
              21.02972234608873
            ],
            [
              105.83479768282537,
              21.029683479138797
            ],
            [
              105.83480648544942,
              21.029668293386813
            ],
            [
              105.8348066598254,
              21.029667992660634
            ],
            [
              105.83481629151007,
              21.02965138839048
            ],
            [
              105.83496342024769,
              21.0293977427441
            ],
            [
              105.83496788233919,
              21.02939087440217
            ],
            [
              105.83501511722478,
              21.02931807750751
            ],
            [
              105.83506082770658,
              21.029193385275008
            ],
            [
              105.8350788488718,
              21.029173624573545
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D90_Hàng Cháo",
          "maTaiSan": "04.O13.DOPP.240",
          "duongKinh": 90,
          "chieudaiQL": 211,
          "chatLieu": "HDPE",
          "from_latitude": 21.03095788204522,
          "to_latitude": 21.029173624573545,
          "to_longitude": 105.8350788488718,
          "from_longitude": 105.83408057064835
        }
      },
      {
        "id": 239,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83162244218433,
            21.028708565352286,
            105.83166026714179,
            21.02878868244221
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83165157455134,
              21.028708565352286
            ],
            [
              105.83165161382288,
              21.028708707869566
            ],
            [
              105.83166026714179,
              21.028737305868535
            ],
            [
              105.8316311450681,
              21.028744283896078
            ],
            [
              105.83162244218433,
              21.028746285551485
            ],
            [
              105.83164838551191,
              21.02878868244221
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D200_Cát Linh",
          "maTaiSan": "04.O13.DOPP.241",
          "duongKinh": 200,
          "chieudaiQL": 22.06,
          "chatLieu": "HDPE",
          "from_latitude": 21.028708565352286,
          "to_latitude": 21.02878868244221,
          "to_longitude": 105.83164838551191,
          "from_longitude": 105.83165157455134
        }
      },
      {
        "id": 240,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82889954975417,
            21.027441947672777,
            105.82955917002936,
            21.027809070475296
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82892183817351,
              21.027441947672777
            ],
            [
              105.82889954975417,
              21.027493181523724
            ],
            [
              105.82891441953973,
              21.02750030322324
            ],
            [
              105.82900409353417,
              21.027543247043234
            ],
            [
              105.8290148745172,
              21.027548409702256
            ],
            [
              105.82903726023142,
              21.027559130227356
            ],
            [
              105.82904638890996,
              21.02756350158617
            ],
            [
              105.82955917002936,
              21.027809070475296
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D200_An Trạch",
          "maTaiSan": "04.O13.DOPP.242",
          "duongKinh": 200,
          "chieudaiQL": 95,
          "chatLieu": "ST",
          "from_latitude": 21.027441947672777,
          "to_latitude": 21.027809070475296,
          "to_longitude": 105.82955917002936,
          "from_longitude": 105.82892183817351
        }
      },
      {
        "id": 241,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82459900411038,
            21.027581760256968,
            105.82482920976338,
            21.027775134484433
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82482920976338,
              21.027581760256968
            ],
            [
              105.8248275200326,
              21.027584479031244
            ],
            [
              105.82481327207037,
              21.02760029496502
            ],
            [
              105.82477528602035,
              21.027642447558758
            ],
            [
              105.8246045674955,
              21.027770945445585
            ],
            [
              105.82459900411038,
              21.027775134484433
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D200_Giảng Võ",
          "maTaiSan": "04.O13.DOPP.243",
          "duongKinh": 200,
          "chieudaiQL": 29.42,
          "chatLieu": "HDPE",
          "from_latitude": 21.027581760256968,
          "to_latitude": 21.027775134484433,
          "to_longitude": 105.82459900411038,
          "from_longitude": 105.82482920976338
        }
      },
      {
        "id": 242,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82732759676188,
            21.020502042696144,
            105.82779087525157,
            21.021173628165386
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82732759676188,
              21.020502042696144
            ],
            [
              105.82733541757695,
              21.020513900134038
            ],
            [
              105.82735645113605,
              21.020531005172828
            ],
            [
              105.82736347682605,
              21.020538056802025
            ],
            [
              105.82779087525157,
              21.021173628165386
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 170, La Thành",
          "maTaiSan": "04.O13.DOPP.244",
          "duongKinh": 100,
          "chieudaiQL": 88.54,
          "chatLieu": "G-CI",
          "from_latitude": 21.020502042696144,
          "to_latitude": 21.021173628165386,
          "to_longitude": 105.82779087525157,
          "from_longitude": 105.82732759676188
        }
      },
      {
        "id": 243,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82343430460787,
            21.02230931007542,
            105.82359707982371,
            21.022748944759762
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82343430460787,
              21.02230931007542
            ],
            [
              105.82343456853383,
              21.022313926991153
            ],
            [
              105.82343575379828,
              21.022334615962208
            ],
            [
              105.82343575781968,
              21.022334647555635
            ],
            [
              105.82343704779703,
              21.02235706208415
            ],
            [
              105.82355744202484,
              21.022651880058117
            ],
            [
              105.82356300302177,
              21.022665499065532
            ],
            [
              105.82356437010716,
              21.022668846123057
            ],
            [
              105.8235691637006,
              21.022680585620456
            ],
            [
              105.82359707982371,
              21.022748944759762
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 360, La Thành",
          "maTaiSan": "04.O13.DOPP.245",
          "duongKinh": 100,
          "chieudaiQL": 49.07,
          "chatLieu": "G-CI",
          "from_latitude": 21.02230931007542,
          "to_latitude": 21.022748944759762,
          "to_longitude": 105.82359707982371,
          "from_longitude": 105.82343430460787
        }
      },
      {
        "id": 244,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82234203834801,
            21.0227519176021,
            105.82249898825003,
            21.023183177943995
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82234203834801,
              21.0227519176021
            ],
            [
              105.8223468827061,
              21.02276433521367
            ],
            [
              105.8223472260287,
              21.022770279489986
            ],
            [
              105.82235104968946,
              21.022836012236876
            ],
            [
              105.82249898825003,
              21.023183177943995
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 418, La Thành",
          "maTaiSan": "04.O13.DOPP.246",
          "duongKinh": 100,
          "chieudaiQL": 50.39,
          "chatLieu": "G-CI",
          "from_latitude": 21.0227519176021,
          "to_latitude": 21.023183177943995,
          "to_longitude": 105.82249898825003,
          "from_longitude": 105.82234203834801
        }
      },
      {
        "id": 245,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82604646049954,
            21.021040937009374,
            105.82608425408289,
            21.0211261865896
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82605936331463,
              21.021040937009374
            ],
            [
              105.82606209709705,
              21.02104684798862
            ],
            [
              105.826082061585,
              21.021090099529534
            ],
            [
              105.82608425408289,
              21.02109484346019
            ],
            [
              105.82606982791415,
              21.021103498680667
            ],
            [
              105.8260654702586,
              21.02110700629421
            ],
            [
              105.82604646049954,
              21.02111849461081
            ],
            [
              105.82604784708059,
              21.02112100787602
            ],
            [
              105.82605071535443,
              21.0211261865896
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D200_Đường La Thành",
          "maTaiSan": "04.O13.DOPP.247",
          "duongKinh": 200,
          "chieudaiQL": 25.46,
          "chatLieu": "G-CI",
          "from_latitude": 21.021040937009374,
          "to_latitude": 21.0211261865896,
          "to_longitude": 105.82605071535443,
          "from_longitude": 105.82605936331463
        }
      },
      {
        "id": 246,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82508619693864,
            21.02734480253825,
            105.82569998601807,
            21.027807001313775
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82508619693864,
              21.02734480253825
            ],
            [
              105.82510047465159,
              21.02735554938192
            ],
            [
              105.82511145428938,
              21.027357154428895
            ],
            [
              105.82511721154098,
              21.027362499779397
            ],
            [
              105.82511986002808,
              21.027364958152067
            ],
            [
              105.82511920183703,
              21.027369645470472
            ],
            [
              105.82512945601574,
              21.027377348998446
            ],
            [
              105.82532386064959,
              21.027523761321973
            ],
            [
              105.82541916246117,
              21.027595535976715
            ],
            [
              105.82543774000578,
              21.027609525270677
            ],
            [
              105.82546607050496,
              21.02763085921206
            ],
            [
              105.82563349075205,
              21.027756922425986
            ],
            [
              105.82566003518343,
              21.02777692100634
            ],
            [
              105.82569998601807,
              21.027807001313775
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 44, Vũ Thạnh",
          "maTaiSan": "04.O13.DOPP.248",
          "duongKinh": 100,
          "chieudaiQL": 92,
          "chatLieu": "G-CI",
          "from_latitude": 21.02734480253825,
          "to_latitude": 21.027807001313775,
          "to_longitude": 105.82569998601807,
          "from_longitude": 105.82508619693864
        }
      },
      {
        "id": 247,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83028913606483,
            21.023425271026927,
            105.83220502693386,
            21.024146366713914
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83220502693386,
              21.023425271026927
            ],
            [
              105.83212066225627,
              21.02346074426351
            ],
            [
              105.83205623630876,
              21.023487834639983
            ],
            [
              105.83205538827498,
              21.02348583363103
            ],
            [
              105.83204821919966,
              21.02347346382237
            ],
            [
              105.83188852642006,
              21.023545074030203
            ],
            [
              105.8317402132978,
              21.023619367017066
            ],
            [
              105.8317150166849,
              21.023631988150424
            ],
            [
              105.83168942099346,
              21.023644809941022
            ],
            [
              105.83158175337414,
              21.023690264593615
            ],
            [
              105.8314689800375,
              21.02373287640359
            ],
            [
              105.83143177537706,
              21.02374454820249
            ],
            [
              105.83142869371622,
              21.023745515265876
            ],
            [
              105.83142475256578,
              21.02374675117557
            ],
            [
              105.83140088576783,
              21.023754239061624
            ],
            [
              105.83137810618237,
              21.023761236598332
            ],
            [
              105.83131693357501,
              21.023780028231098
            ],
            [
              105.83131550937199,
              21.023780466030498
            ],
            [
              105.83118707883436,
              21.023820007354256
            ],
            [
              105.83116407075126,
              21.023827090882612
            ],
            [
              105.83109827111635,
              21.023847133435602
            ],
            [
              105.83092672427651,
              21.023899385880313
            ],
            [
              105.8306835241241,
              21.023973466818163
            ],
            [
              105.83067846489524,
              21.02397500804235
            ],
            [
              105.8306139078453,
              21.02399467221372
            ],
            [
              105.83056290097248,
              21.024035100349817
            ],
            [
              105.83055004028583,
              21.024046072299445
            ],
            [
              105.83054837333941,
              21.024047494889324
            ],
            [
              105.8305413560673,
              21.024053481406597
            ],
            [
              105.8305302207339,
              21.02406298170561
            ],
            [
              105.83037143480296,
              21.02411585412232
            ],
            [
              105.83030801103446,
              21.024139368512017
            ],
            [
              105.83029702551585,
              21.024143441492463
            ],
            [
              105.83028913606483,
              21.024146366713914
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D200_Ngõ Thịnh Hào 1, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DOPP.249",
          "duongKinh": 200,
          "chieudaiQL": 215.45,
          "chatLieu": "G-DI",
          "from_latitude": 21.023425271026927,
          "to_latitude": 21.024146366713914,
          "to_longitude": 105.83028913606483,
          "from_longitude": 105.83220502693386
        }
      },
      {
        "id": 248,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83257788688591,
            21.02626836594839,
            105.83406091607624,
            21.0280121948121
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83257788688591,
              21.02626836594839
            ],
            [
              105.83259198945855,
              21.026301546379404
            ],
            [
              105.83274313171661,
              21.026543244241033
            ],
            [
              105.8327641223469,
              21.026578160340346
            ],
            [
              105.83278565270376,
              21.026628147694755
            ],
            [
              105.83280078691931,
              21.026663284854703
            ],
            [
              105.83288677987473,
              21.026862932277783
            ],
            [
              105.83303795911107,
              21.02721392151942
            ],
            [
              105.83305067062847,
              21.02724343350073
            ],
            [
              105.83310881641991,
              21.027378430771915
            ],
            [
              105.83338179661764,
              21.0280121948121
            ],
            [
              105.83377957856712,
              21.02784542555501
            ],
            [
              105.83406091607624,
              21.027731146558608
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D110_Đặng Trần Côn",
          "maTaiSan": "04.O13.DOPP.250",
          "duongKinh": 110,
          "chieudaiQL": 291.55,
          "chatLieu": "HDPE",
          "from_latitude": 21.02626836594839,
          "to_latitude": 21.027731146558608,
          "to_longitude": 105.83406091607624,
          "from_longitude": 105.83257788688591
        }
      },
      {
        "id": 249,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83411879066047,
            21.027766551750876,
            105.83483136400869,
            21.02883568583955
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83411879066047,
              21.027766551750876
            ],
            [
              105.83411999596593,
              21.027769127251133
            ],
            [
              105.83413270374916,
              21.02779619232839
            ],
            [
              105.83415604171556,
              21.027856433944844
            ],
            [
              105.83415777810879,
              21.027860920769935
            ],
            [
              105.83426852244902,
              21.028043113121115
            ],
            [
              105.83432818279218,
              21.028123125997887
            ],
            [
              105.83434814626004,
              21.02815124293694
            ],
            [
              105.83438770089288,
              21.028206953861165
            ],
            [
              105.83446776343092,
              21.0283231824709
            ],
            [
              105.83456216317087,
              21.028454635855528
            ],
            [
              105.83458017493888,
              21.02848001473738
            ],
            [
              105.8346770968835,
              21.028616576946735
            ],
            [
              105.83483136400869,
              21.02883568583955
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D160_Tôn Đức Thắng",
          "maTaiSan": "04.O13.DOPP.251",
          "duongKinh": 160,
          "chieudaiQL": 119,
          "chatLieu": "HDPE",
          "from_latitude": 21.027766551750876,
          "to_latitude": 21.02883568583955,
          "to_longitude": 105.83483136400869,
          "from_longitude": 105.83411879066047
        }
      },
      {
        "id": 250,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82823927952752,
            21.029032120871832,
            105.83048952649966,
            21.02947978266905
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82823927952752,
              21.02947978266905
            ],
            [
              105.82838362498431,
              21.02945247052911
            ],
            [
              105.82919702038271,
              21.029278601344316
            ],
            [
              105.82945832798389,
              21.029226346201163
            ],
            [
              105.8297372481358,
              21.029159145780614
            ],
            [
              105.82999722956785,
              21.029105834099425
            ],
            [
              105.83005582446549,
              21.02910512898131
            ],
            [
              105.8300678005309,
              21.02910266613728
            ],
            [
              105.83006967146008,
              21.029102281262986
            ],
            [
              105.83008753706251,
              21.029098607346945
            ],
            [
              105.83009904963895,
              21.029096703149317
            ],
            [
              105.83048952649966,
              21.029032120871832
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D160_Cát Linh",
          "maTaiSan": "04.O13.DOPP.252",
          "duongKinh": 160,
          "chieudaiQL": 240,
          "chatLieu": "PVC",
          "from_latitude": 21.02947978266905,
          "to_latitude": 21.029032120871832,
          "to_longitude": 105.83048952649966,
          "from_longitude": 105.82823927952752
        }
      },
      {
        "id": 251,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83068225310454,
            21.022225519129684,
            105.83156828875003,
            21.022596153277263
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83156828875003,
              21.022225519129684
            ],
            [
              105.83156247654578,
              21.022226747028153
            ],
            [
              105.83155045433901,
              21.022229286059577
            ],
            [
              105.83152294725058,
              21.02223878246105
            ],
            [
              105.83144308961106,
              21.02227801421503
            ],
            [
              105.83142596779746,
              21.022286425641592
            ],
            [
              105.8312831747841,
              21.022349831760028
            ],
            [
              105.83128301547019,
              21.022349899377286
            ],
            [
              105.83121298489722,
              21.022379514236423
            ],
            [
              105.83120953562381,
              21.022380972639436
            ],
            [
              105.83114361356677,
              21.022403805853784
            ],
            [
              105.8310682748705,
              21.022436049686497
            ],
            [
              105.83104092713475,
              21.022447754520172
            ],
            [
              105.83093108017493,
              21.022494748555644
            ],
            [
              105.83090108186234,
              21.02251126063218
            ],
            [
              105.83089766090784,
              21.02251314340616
            ],
            [
              105.83080175173082,
              21.022553490183032
            ],
            [
              105.83079372154391,
              21.02255686772549
            ],
            [
              105.83077819673814,
              21.022562014260092
            ],
            [
              105.83076079566754,
              21.02256778324726
            ],
            [
              105.83068225310454,
              21.022596153277263
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ Thịnh Hào 3, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DOPP.253",
          "duongKinh": 100,
          "chieudaiQL": 100.89,
          "chatLieu": "G-CI",
          "from_latitude": 21.022225519129684,
          "to_latitude": 21.022596153277263,
          "to_longitude": 105.83068225310454,
          "from_longitude": 105.83156828875003
        }
      },
      {
        "id": 252,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82766809303648,
            21.025637714979833,
            105.82918704528385,
            21.02594330936471
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82766809303648,
              21.02594330936471
            ],
            [
              105.82771689800943,
              21.025926688236932
            ],
            [
              105.82774141310354,
              21.025921175761493
            ],
            [
              105.82800264025849,
              21.02586244364485
            ],
            [
              105.8280422275637,
              21.025853542907363
            ],
            [
              105.82820290183521,
              21.02581740647967
            ],
            [
              105.82841521455572,
              21.025802708401518
            ],
            [
              105.82855889093771,
              21.02578047739
            ],
            [
              105.82858099329226,
              21.025777057579397
            ],
            [
              105.82885280751273,
              21.025719174910446
            ],
            [
              105.82889175540232,
              21.025711740317615
            ],
            [
              105.82894995563328,
              21.025704964259862
            ],
            [
              105.82897904656315,
              21.025696436017732
            ],
            [
              105.82901826346273,
              21.025684939187617
            ],
            [
              105.82914007932827,
              21.025649879176285
            ],
            [
              105.82918704528385,
              21.025637714979833
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 160, Hào Nam",
          "maTaiSan": "04.O13.DOPP.254",
          "duongKinh": 100,
          "chieudaiQL": 190,
          "chatLieu": "G-CI",
          "from_latitude": 21.02594330936471,
          "to_latitude": 21.025637714979833,
          "to_longitude": 105.82918704528385,
          "from_longitude": 105.82766809303648
        }
      },
      {
        "id": 253,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82788770719317,
            21.023258555552214,
            105.82856968062237,
            21.024059243061348
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82856968062237,
              21.023258555552214
            ],
            [
              105.82856906610161,
              21.023262767573627
            ],
            [
              105.8285646637942,
              21.023293010922643
            ],
            [
              105.82856325724431,
              21.023413798803134
            ],
            [
              105.8284486241881,
              21.02342046572872
            ],
            [
              105.82840794133068,
              21.02342283162963
            ],
            [
              105.82838387339693,
              21.02342423143634
            ],
            [
              105.82837821945942,
              21.023437403605463
            ],
            [
              105.82837032713074,
              21.023506147648867
            ],
            [
              105.82835799742003,
              21.023577902074845
            ],
            [
              105.82834497145821,
              21.02363890428958
            ],
            [
              105.82829617182526,
              21.023638583764882
            ],
            [
              105.82829279127175,
              21.023638561389962
            ],
            [
              105.82828517927118,
              21.02364278039216
            ],
            [
              105.82827494505437,
              21.023716684217717
            ],
            [
              105.82827258511551,
              21.02373406562726
            ],
            [
              105.82825556340602,
              21.023739764774486
            ],
            [
              105.82825031423722,
              21.023741406206685
            ],
            [
              105.82820996212023,
              21.023754030182868
            ],
            [
              105.82815348849446,
              21.023762911107074
            ],
            [
              105.82798389624234,
              21.023818104637225
            ],
            [
              105.82794112409945,
              21.02384508354313
            ],
            [
              105.82789512128097,
              21.023874099582287
            ],
            [
              105.82788770719317,
              21.02391416479246
            ],
            [
              105.82792471670753,
              21.024059243061348
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngách 86/20, Hào Nam",
          "maTaiSan": "04.O13.DOPP.255",
          "duongKinh": 100,
          "chieudaiQL": 155,
          "chatLieu": "G-CI",
          "from_latitude": 21.023258555552214,
          "to_latitude": 21.024059243061348,
          "to_longitude": 105.82792471670753,
          "from_longitude": 105.82856968062237
        }
      },
      {
        "id": 254,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82738473053502,
            21.023990190784843,
            105.82852914421764,
            21.024162115297205
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82852914421764,
              21.023990190784843
            ],
            [
              105.82851975505046,
              21.02399086336264
            ],
            [
              105.82850547128069,
              21.023991885847906
            ],
            [
              105.82848005676755,
              21.02399368784292
            ],
            [
              105.82843295875803,
              21.023997018938427
            ],
            [
              105.82831454804328,
              21.024018235267295
            ],
            [
              105.82830613894185,
              21.02401974215597
            ],
            [
              105.8281906132457,
              21.024036786804857
            ],
            [
              105.82808837718639,
              21.02405186989708
            ],
            [
              105.82803937203961,
              21.024059476101012
            ],
            [
              105.82803274670967,
              21.024060504614702
            ],
            [
              105.82792471670753,
              21.024059243061348
            ],
            [
              105.82780784214371,
              21.024073486795604
            ],
            [
              105.82768951223969,
              21.0240903767559
            ],
            [
              105.82762928701511,
              21.024098795252197
            ],
            [
              105.82762176286548,
              21.02410046399425
            ],
            [
              105.82757112210166,
              21.024111696342597
            ],
            [
              105.82753588249429,
              21.024118162388188
            ],
            [
              105.82753400192999,
              21.024118708957374
            ],
            [
              105.82752842267021,
              21.024120331199757
            ],
            [
              105.82740889573013,
              21.02415966380143
            ],
            [
              105.82738473053502,
              21.024162115297205
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 86, Hào Nam",
          "maTaiSan": "04.O13.DOPP.256",
          "duongKinh": 100,
          "chieudaiQL": 131,
          "chatLieu": "G-CI",
          "from_latitude": 21.023990190784843,
          "to_latitude": 21.024162115297205,
          "to_longitude": 105.82738473053502,
          "from_longitude": 105.82852914421764
        }
      },
      {
        "id": 255,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82387884680634,
            21.026025699778113,
            105.82491864967024,
            21.026901970216514
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82387884680634,
              21.026025699778113
            ],
            [
              105.82392656965337,
              21.026075353707032
            ],
            [
              105.82392830064302,
              21.02607716622277
            ],
            [
              105.82399220264675,
              21.026143641993084
            ],
            [
              105.824038171742,
              21.02618627909908
            ],
            [
              105.82418875089725,
              21.026325942206174
            ],
            [
              105.8241904633458,
              21.026327530809038
            ],
            [
              105.82419635973048,
              21.026332999260926
            ],
            [
              105.82436007429324,
              21.02646108964797
            ],
            [
              105.82436300756666,
              21.026463384072446
            ],
            [
              105.82440035371255,
              21.026492603914587
            ],
            [
              105.82472161049667,
              21.026733378824268
            ],
            [
              105.82491864967024,
              21.026901970216514
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 2, Vũ Thạnh",
          "maTaiSan": "04.O13.DOPP.257",
          "duongKinh": 100,
          "chieudaiQL": 170,
          "chatLieu": "G-CI",
          "from_latitude": 21.026025699778113,
          "to_latitude": 21.026901970216514,
          "to_longitude": 105.82491864967024,
          "from_longitude": 105.82387884680634
        }
      },
      {
        "id": 256,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82007495381258,
            21.023147928177114,
            105.82120204770725,
            21.023407419590495
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82120204770725,
              21.023147928177114
            ],
            [
              105.8211460330333,
              21.023157223660675
            ],
            [
              105.82102132649565,
              21.023181418868425
            ],
            [
              105.82085133049387,
              21.02321439967156
            ],
            [
              105.8208512430542,
              21.023214418158087
            ],
            [
              105.82080666858118,
              21.02322306399419
            ],
            [
              105.82077716977103,
              21.023228787387996
            ],
            [
              105.82052517810773,
              21.023277675554098
            ],
            [
              105.82025890434835,
              21.023329333781344
            ],
            [
              105.8202081580048,
              21.02335087568574
            ],
            [
              105.82016269800721,
              21.023370173759616
            ],
            [
              105.82007495381258,
              21.023407419590495
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Đường La Thành",
          "maTaiSan": "04.O13.DOPP.258",
          "duongKinh": 150,
          "chieudaiQL": 119.84,
          "chatLieu": "G-DI",
          "from_latitude": 21.023147928177114,
          "to_latitude": 21.023407419590495,
          "to_longitude": 105.82007495381258,
          "from_longitude": 105.82120204770725
        }
      },
      {
        "id": 257,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8275498419013,
            21.02688473278658,
            105.82772421857857,
            21.029299385488454
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82756862728085,
              21.029299385488454
            ],
            [
              105.82758802078342,
              21.02925356627034
            ],
            [
              105.8275498419013,
              21.029135612141705
            ],
            [
              105.8275563426987,
              21.028689787774912
            ],
            [
              105.82761363427038,
              21.027885726783445
            ],
            [
              105.82768707813763,
              21.026969738090532
            ],
            [
              105.82769174680702,
              21.026894474952453
            ],
            [
              105.82772228690578,
              21.026885263349357
            ],
            [
              105.82772421857857,
              21.02688473278658
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Hào Nam",
          "maTaiSan": "04.O13.DOPP.259",
          "duongKinh": 100,
          "chieudaiQL": 306,
          "chatLieu": "G-DI",
          "from_latitude": 21.029299385488454,
          "to_latitude": 21.02688473278658,
          "to_longitude": 105.82772421857857,
          "from_longitude": 105.82756862728085
        }
      },
      {
        "id": 258,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82727884355502,
            21.02622574565912,
            105.82756878386192,
            21.029213550442314
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82727884355502,
              21.029213550442314
            ],
            [
              105.82735215356905,
              21.028928736773555
            ],
            [
              105.82747102165372,
              21.027983596025845
            ],
            [
              105.82749789184273,
              21.02766383127062
            ],
            [
              105.82756878386192,
              21.02661691994982
            ],
            [
              105.82749460665242,
              21.02646501710429
            ],
            [
              105.82736206155623,
              21.02622574565912
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Hào Nam",
          "maTaiSan": "04.O13.DOPP.260",
          "duongKinh": 100,
          "chieudaiQL": 361,
          "chatLieu": "G-DI",
          "from_latitude": 21.029213550442314,
          "to_latitude": 21.02622574565912,
          "to_longitude": 105.82736206155623,
          "from_longitude": 105.82727884355502
        }
      },
      {
        "id": 259,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82707127232723,
            21.029213550442314,
            105.82766614058933,
            21.02941776525803
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82766614058933,
              21.02941776525803
            ],
            [
              105.82760280385084,
              21.029323191627736
            ],
            [
              105.82756862728085,
              21.029299385488454
            ],
            [
              105.82753534596084,
              21.02927620272594
            ],
            [
              105.82727884355502,
              21.029213550442314
            ],
            [
              105.82708412893369,
              21.029260839395246
            ],
            [
              105.82707127232723,
              21.02926692372766
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Hào Nam",
          "maTaiSan": "04.O13.DOPP.261",
          "duongKinh": 150,
          "chieudaiQL": 63,
          "chatLieu": "ST",
          "from_latitude": 21.02941776525803,
          "to_latitude": 21.02926692372766,
          "to_longitude": 105.82707127232723,
          "from_longitude": 105.82766614058933
        }
      },
      {
        "id": 260,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82883504725422,
            21.030647986476296,
            105.82986028742036,
            21.031764395841954
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82986028742036,
              21.031764395841954
            ],
            [
              105.82985542443909,
              21.031729845979868
            ],
            [
              105.82980762595707,
              21.031390253076175
            ],
            [
              105.82965964069201,
              21.03128065459497
            ],
            [
              105.82963250000897,
              21.031260554815212
            ],
            [
              105.82961849110191,
              21.03125017919327
            ],
            [
              105.82937690413459,
              21.031065027242924
            ],
            [
              105.82937232948642,
              21.031061520661858
            ],
            [
              105.82936615878948,
              21.031056791675002
            ],
            [
              105.82935923064295,
              21.031051481996737
            ],
            [
              105.82921289430624,
              21.03093932977548
            ],
            [
              105.82919664782389,
              21.030926878581923
            ],
            [
              105.82900499439864,
              21.030779994208118
            ],
            [
              105.82896250149345,
              21.030746978096115
            ],
            [
              105.82894788986756,
              21.0307356280658
            ],
            [
              105.82883504725422,
              21.030647986476296
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D110_Giảng Võ",
          "maTaiSan": "04.O13.DOPP.262",
          "duongKinh": 110,
          "chieudaiQL": 221,
          "chatLieu": "PVC",
          "from_latitude": 21.031764395841954,
          "to_latitude": 21.030647986476296,
          "to_longitude": 105.82883504725422,
          "from_longitude": 105.82986028742036
        }
      },
      {
        "id": 261,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83356385051188,
            21.029539189766414,
            105.83403994829197,
            21.030971666031387
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83356385051188,
              21.029539189766414
            ],
            [
              105.83372142746592,
              21.030014106971667
            ],
            [
              105.83379159927767,
              21.030153917840508
            ],
            [
              105.83383280975237,
              21.030248333801627
            ],
            [
              105.83387590780977,
              21.030347076867645
            ],
            [
              105.83388864709057,
              21.030378731072638
            ],
            [
              105.83396321377681,
              21.030564008425458
            ],
            [
              105.83403994829197,
              21.030754676376805
            ],
            [
              105.83403216559542,
              21.030971666031387
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ Hàng Cháo, Hàng Cháo",
          "maTaiSan": "04.O13.DOPP.263",
          "duongKinh": 100,
          "chieudaiQL": 173,
          "chatLieu": "G-CI",
          "from_latitude": 21.029539189766414,
          "to_latitude": 21.030971666031387,
          "to_longitude": 105.83403216559542,
          "from_longitude": 105.83356385051188
        }
      },
      {
        "id": 262,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83332684272233,
            21.02898613721353,
            105.83356385051188,
            21.029539189766414
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83332684272233,
              21.02898613721353
            ],
            [
              105.83335242883773,
              21.029045863016528
            ],
            [
              105.83341713601536,
              21.02919683489442
            ],
            [
              105.83342047231372,
              21.029204620585674
            ],
            [
              105.83356385051188,
              21.029539189766414
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ Hàng Cháo, Hàng Cháo",
          "maTaiSan": "04.O13.DOPP.264",
          "duongKinh": 100,
          "chieudaiQL": 59,
          "chatLieu": "G-CI",
          "from_latitude": 21.02898613721353,
          "to_latitude": 21.029539189766414,
          "to_longitude": 105.83356385051188,
          "from_longitude": 105.83332684272233
        }
      },
      {
        "id": 263,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83305149300935,
            21.028923201283995,
            105.83332684272233,
            21.02898613721353
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83305149300935,
              21.028923201283995
            ],
            [
              105.83332684272233,
              21.02898613721353
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ Hàng Cháo, Hàng Cháo",
          "maTaiSan": "04.O13.DOPP.265",
          "duongKinh": 100,
          "chieudaiQL": 32,
          "chatLieu": "G-CI",
          "from_latitude": 21.028923201283995,
          "to_latitude": 21.02898613721353,
          "to_longitude": 105.83332684272233,
          "from_longitude": 105.83305149300935
        }
      },
      {
        "id": 264,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82757545889703,
            21.02926565098292,
            105.8284841602298,
            21.029435689439843
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82757545889703,
              21.029435689439843
            ],
            [
              105.82761787931182,
              21.029426777698365
            ],
            [
              105.82766614058933,
              21.02941776525803
            ],
            [
              105.82778037114691,
              21.029396431427312
            ],
            [
              105.82785405563227,
              21.029382673368428
            ],
            [
              105.82790950540644,
              21.029372316186308
            ],
            [
              105.82792994662523,
              21.02936850079309
            ],
            [
              105.8280145599533,
              21.02935269964866
            ],
            [
              105.8284841602298,
              21.02926565098292
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Cát Linh",
          "maTaiSan": "04.O13.DOPP.266",
          "duongKinh": 150,
          "chieudaiQL": 90,
          "chatLieu": "G-DI",
          "from_latitude": 21.029435689439843,
          "to_latitude": 21.02926565098292,
          "to_longitude": 105.8284841602298,
          "from_longitude": 105.82757545889703
        }
      },
      {
        "id": 265,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82409669360194,
            21.022019689090268,
            105.82470946223941,
            21.023955374207176
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82409669360194,
              21.022019689090268
            ],
            [
              105.82409827335398,
              21.022023047758342
            ],
            [
              105.82409859066398,
              21.02202372183376
            ],
            [
              105.82409879211164,
              21.022024143567346
            ],
            [
              105.82416307243416,
              21.022160462153725
            ],
            [
              105.82416382856573,
              21.02216204003802
            ],
            [
              105.82416467227117,
              21.022163799046314
            ],
            [
              105.82416539142652,
              21.022165300335576
            ],
            [
              105.8241673104635,
              21.022169304971655
            ],
            [
              105.82423717908136,
              21.022315076701894
            ],
            [
              105.82424860182223,
              21.02233890805218
            ],
            [
              105.82429648338608,
              21.022438806697505
            ],
            [
              105.82435500064665,
              21.022563017385654
            ],
            [
              105.82436417925378,
              21.022582498830005
            ],
            [
              105.82436999886843,
              21.022594852039827
            ],
            [
              105.82438516621364,
              21.022639599288997
            ],
            [
              105.8243986868432,
              21.022680532067355
            ],
            [
              105.82441101351863,
              21.022728262887867
            ],
            [
              105.82441205834823,
              21.022732307886944
            ],
            [
              105.82443880434332,
              21.02283587215197
            ],
            [
              105.82446104772943,
              21.02293688585042
            ],
            [
              105.82448050074858,
              21.023008217724467
            ],
            [
              105.82448290218684,
              21.023017023371
            ],
            [
              105.82449180577318,
              21.023084624576498
            ],
            [
              105.82449246433957,
              21.02308962615665
            ],
            [
              105.82451279447103,
              21.02316143609522
            ],
            [
              105.82461500019338,
              21.023458160485962
            ],
            [
              105.82461775799752,
              21.023466166853776
            ],
            [
              105.82462461324512,
              21.023484728318827
            ],
            [
              105.82464204337792,
              21.02353192675724
            ],
            [
              105.82468472254187,
              21.02363346003375
            ],
            [
              105.82469974433404,
              21.023669196469537
            ],
            [
              105.82470308088683,
              21.02367713409373
            ],
            [
              105.82470538280894,
              21.02369180305461
            ],
            [
              105.82470664689038,
              21.023699852792664
            ],
            [
              105.82470946223941,
              21.0237177943592
            ],
            [
              105.82468135083059,
              21.02384159668364
            ],
            [
              105.82468090970912,
              21.023907260600794
            ],
            [
              105.82468600259209,
              21.023955374207176
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Ngõ 318, La Thành",
          "maTaiSan": "04.O13.DOPP.267",
          "duongKinh": 150,
          "chieudaiQL": 222.53,
          "chatLieu": "G-CI",
          "from_latitude": 21.022019689090268,
          "to_latitude": 21.023955374207176,
          "to_longitude": 105.82468600259209,
          "from_longitude": 105.82409669360194
        }
      },
      {
        "id": 266,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82561282194797,
            21.02696377092947,
            105.8263314190113,
            21.02756008240523
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82561282194797,
              21.02696377092947
            ],
            [
              105.82562445341753,
              21.026973165804172
            ],
            [
              105.82574399922319,
              21.02706966758662
            ],
            [
              105.8259038038108,
              21.027179501356883
            ],
            [
              105.82591963499134,
              21.027190382392263
            ],
            [
              105.82602693475431,
              21.027262271853495
            ],
            [
              105.8260416181209,
              21.027310834669834
            ],
            [
              105.82607831921732,
              21.02734283467508
            ],
            [
              105.82607907997478,
              21.02734349755938
            ],
            [
              105.826085390321,
              21.027349000060095
            ],
            [
              105.82611164666935,
              21.02737468215799
            ],
            [
              105.82613739689377,
              21.02739737613297
            ],
            [
              105.8261875277786,
              21.027445614979285
            ],
            [
              105.82619570690564,
              21.02745348478061
            ],
            [
              105.82625645733009,
              21.027503088266116
            ],
            [
              105.8263314190113,
              21.02756008240523
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 29, Vũ Thạnh",
          "maTaiSan": "04.O13.DOPP.268",
          "duongKinh": 100,
          "chieudaiQL": 111,
          "chatLieu": "G-CI",
          "from_latitude": 21.02696377092947,
          "to_latitude": 21.02756008240523,
          "to_longitude": 105.8263314190113,
          "from_longitude": 105.82561282194797
        }
      },
      {
        "id": 267,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82556049314216,
            21.026960237754967,
            105.82612012166855,
            21.027386316070125
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82556049314216,
              21.026960237754967
            ],
            [
              105.8255857446519,
              21.026979220179093
            ],
            [
              105.825600362375,
              21.026990218233507
            ],
            [
              105.82567832392802,
              21.027033040063394
            ],
            [
              105.82583170906479,
              21.027148138358235
            ],
            [
              105.82583678928846,
              21.027151950586322
            ],
            [
              105.82590873069903,
              21.027198086570106
            ],
            [
              105.82600220690767,
              21.02725803300362
            ],
            [
              105.82600721552865,
              21.027261244929612
            ],
            [
              105.82603935102033,
              21.027313344905547
            ],
            [
              105.82606934897046,
              21.02733804238478
            ],
            [
              105.82607583833182,
              21.02734338504869
            ],
            [
              105.82607710913908,
              21.02734443022907
            ],
            [
              105.82607793347732,
              21.027345109062566
            ],
            [
              105.82607844953297,
              21.027345533781027
            ],
            [
              105.8260799890072,
              21.02734680165601
            ],
            [
              105.82608257605428,
              21.02734893153788
            ],
            [
              105.82610676493408,
              21.027373547939167
            ],
            [
              105.82612012166855,
              21.027386316070125
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 29, Vũ Thạnh",
          "maTaiSan": "04.O13.DOPP.269",
          "duongKinh": 100,
          "chieudaiQL": 76,
          "chatLieu": "G-CI",
          "from_latitude": 21.026960237754967,
          "to_latitude": 21.027386316070125,
          "to_longitude": 105.82612012166855,
          "from_longitude": 105.82556049314216
        }
      },
      {
        "id": 268,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82612815947456,
            21.02756008240523,
            105.82643328158471,
            21.02803795236077
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8263314190113,
              21.02756008240523
            ],
            [
              105.82621925368711,
              21.027695665841485
            ],
            [
              105.82619847883954,
              21.027720777975727
            ],
            [
              105.82612815947456,
              21.027792371402267
            ],
            [
              105.82613694864214,
              21.027806718879667
            ],
            [
              105.82615065077393,
              21.02782908570437
            ],
            [
              105.8263943145554,
              21.028010290803127
            ],
            [
              105.82641629803624,
              21.028025896463575
            ],
            [
              105.82643328158471,
              21.02803795236077
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D110_Ngõ 29, Vũ Thạnh",
          "maTaiSan": "04.O13.DOPP.270",
          "duongKinh": 110,
          "chieudaiQL": 68,
          "chatLieu": "HDPE",
          "from_latitude": 21.02756008240523,
          "to_latitude": 21.02803795236077,
          "to_longitude": 105.82643328158471,
          "from_longitude": 105.8263314190113
        }
      },
      {
        "id": 269,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82578586726338,
            21.026534549215693,
            105.82643624968377,
            21.026791009070894
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82578586726338,
              21.026791009070894
            ],
            [
              105.82578879983099,
              21.026789852250854
            ],
            [
              105.82579809094342,
              21.026786188813997
            ],
            [
              105.82590770182684,
              21.026742966481173
            ],
            [
              105.82609416411579,
              21.026669441510332
            ],
            [
              105.82610682741795,
              21.026664447483434
            ],
            [
              105.82622451394116,
              21.026618039788254
            ],
            [
              105.8263837342979,
              21.02655525704281
            ],
            [
              105.82638500235328,
              21.02655475681677
            ],
            [
              105.82643624968377,
              21.026534549215693
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Vũ Thạch",
          "maTaiSan": "04.O13.DOPP.271",
          "duongKinh": 100,
          "chieudaiQL": 76,
          "chatLieu": "G-DI",
          "from_latitude": 21.026791009070894,
          "to_latitude": 21.026534549215693,
          "to_longitude": 105.82643624968377,
          "from_longitude": 105.82578586726338
        }
      },
      {
        "id": 270,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82556662818895,
            21.025990262645838,
            105.82568789334182,
            21.026234551766702
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82556662818895,
              21.025990262645838
            ],
            [
              105.82564084585093,
              21.02613977536956
            ],
            [
              105.825647475465,
              21.02615312897743
            ],
            [
              105.82568308576646,
              21.026224867246494
            ],
            [
              105.82568789334182,
              21.026234551766702
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Ngõ 8, Vũ Thạnh",
          "maTaiSan": "04.O13.DOPP.272",
          "duongKinh": 150,
          "chieudaiQL": 43,
          "chatLieu": "G-CI",
          "from_latitude": 21.025990262645838,
          "to_latitude": 21.026234551766702,
          "to_longitude": 105.82568789334182,
          "from_longitude": 105.82556662818895
        }
      },
      {
        "id": 271,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82568789334182,
            21.026234551766702,
            105.82578799517256,
            21.02652548386938
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82568789334182,
              21.026234551766702
            ],
            [
              105.82572660503841,
              21.026331237395592
            ],
            [
              105.8257577942204,
              21.026404406143154
            ],
            [
              105.82578799517256,
              21.026475253878008
            ],
            [
              105.8257787268227,
              21.02652548386938
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Ngõ 8, Vũ Thạnh",
          "maTaiSan": "04.O13.DOPP.273",
          "duongKinh": 150,
          "chieudaiQL": 38,
          "chatLieu": "G-CI",
          "from_latitude": 21.026234551766702,
          "to_latitude": 21.02652548386938,
          "to_longitude": 105.8257787268227,
          "from_longitude": 105.82568789334182
        }
      },
      {
        "id": 272,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82556049314216,
            21.026791009070894,
            105.82578770205184,
            21.026960237754967
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82578586726338,
              21.026791009070894
            ],
            [
              105.82578770205184,
              21.0268251059344
            ],
            [
              105.82564755182779,
              21.026908460430395
            ],
            [
              105.82564751922479,
              21.02690847955641
            ],
            [
              105.82557856401091,
              21.026949490164903
            ],
            [
              105.82556049314216,
              21.026960237754967
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Vũ Thạch",
          "maTaiSan": "04.O13.DOPP.274",
          "duongKinh": 150,
          "chieudaiQL": 40,
          "chatLieu": "G-CI",
          "from_latitude": 21.026791009070894,
          "to_latitude": 21.026960237754967,
          "to_longitude": 105.82556049314216,
          "from_longitude": 105.82578586726338
        }
      },
      {
        "id": 273,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8249597755301,
            21.02686314429471,
            105.82525708410532,
            21.027175979792553
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8249597755301,
              21.02686314429471
            ],
            [
              105.8250715448695,
              21.027007532636286
            ],
            [
              105.82510128199198,
              21.02703451942789
            ],
            [
              105.82525622072525,
              21.027175198176735
            ],
            [
              105.82525708410532,
              21.027175979792553
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Ngõ 2, Vũ Thạnh",
          "maTaiSan": "04.O13.DOPP.275",
          "duongKinh": 150,
          "chieudaiQL": 30,
          "chatLieu": "G-CI",
          "from_latitude": 21.02686314429471,
          "to_latitude": 21.027175979792553,
          "to_longitude": 105.82525708410532,
          "from_longitude": 105.8249597755301
        }
      },
      {
        "id": 274,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82508619693864,
            21.027175979792553,
            105.82525708410532,
            21.02734480253825
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82525708410532,
              21.027175979792553
            ],
            [
              105.8252459636644,
              21.027186716200795
            ],
            [
              105.82520671263718,
              21.027227092962455
            ],
            [
              105.82508619693864,
              21.02734480253825
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Vũ Thạch",
          "maTaiSan": "04.O13.DOPP.276",
          "duongKinh": 150,
          "chieudaiQL": 34,
          "chatLieu": "G-CI",
          "from_latitude": 21.027175979792553,
          "to_latitude": 21.02734480253825,
          "to_longitude": 105.82508619693864,
          "from_longitude": 105.82525708410532
        }
      },
      {
        "id": 275,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82120204770725,
            21.022736086488443,
            105.82238375263238,
            21.023147928177114
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82238375263238,
              21.022736086488443
            ],
            [
              105.82234203834801,
              21.0227519176021
            ],
            [
              105.82231704125888,
              21.02276140401004
            ],
            [
              105.82222016708147,
              21.02279817029291
            ],
            [
              105.82220639126483,
              21.02280339792126
            ],
            [
              105.82207791974808,
              21.02284474086965
            ],
            [
              105.82194612774441,
              21.02289036118978
            ],
            [
              105.82191585570898,
              21.022900840386956
            ],
            [
              105.82168852148214,
              21.02297953406912
            ],
            [
              105.82158833499376,
              21.023014214194706
            ],
            [
              105.82151001238358,
              21.023041325301712
            ],
            [
              105.82148174030871,
              21.023051111924143
            ],
            [
              105.82120204770725,
              21.023147928177114
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Đường La Thành",
          "maTaiSan": "04.O13.DOPP.277",
          "duongKinh": 150,
          "chieudaiQL": 126.91,
          "chatLieu": "G-DI",
          "from_latitude": 21.022736086488443,
          "to_latitude": 21.023147928177114,
          "to_longitude": 105.82120204770725,
          "from_longitude": 105.82238375263238
        }
      },
      {
        "id": 276,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8240589141966,
            21.021995259232042,
            105.82409238478368,
            21.022026668045875
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8240589141966,
              21.021995259232042
            ],
            [
              105.82406205888296,
              21.02200120618922
            ],
            [
              105.82407549722092,
              21.022026668045875
            ],
            [
              105.82409039471608,
              21.022017792125514
            ],
            [
              105.82409238478368,
              21.02202159409589
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Đường La Thành",
          "maTaiSan": "04.O13.DOPP.278",
          "duongKinh": 150,
          "chieudaiQL": 9.43,
          "chatLieu": "G-CI",
          "from_latitude": 21.021995259232042,
          "to_latitude": 21.02202159409589,
          "to_longitude": 105.82409238478368,
          "from_longitude": 105.8240589141966
        }
      },
      {
        "id": 277,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82238375263238,
            21.022048960375454,
            105.82405763841132,
            21.022736086488443
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82405763841132,
              21.022048960375454
            ],
            [
              105.82390687072417,
              21.02211365540647
            ],
            [
              105.82389241353783,
              21.022119858339902
            ],
            [
              105.82375976998347,
              21.022176776289335
            ],
            [
              105.82373293626038,
              21.02218829050556
            ],
            [
              105.82344583907114,
              21.022304636039703
            ],
            [
              105.82343430460787,
              21.02230931007542
            ],
            [
              105.82341332814534,
              21.02231781072426
            ],
            [
              105.82340993794698,
              21.022319355394853
            ],
            [
              105.82340308654886,
              21.022322478498896
            ],
            [
              105.82329488910337,
              21.022371796326585
            ],
            [
              105.82313257417307,
              21.022448075355683
            ],
            [
              105.82300675683254,
              21.022507201502314
            ],
            [
              105.82297064893699,
              21.02252194796102
            ],
            [
              105.82295249372054,
              21.022529361871108
            ],
            [
              105.8229492406366,
              21.02253047593381
            ],
            [
              105.82274673775389,
              21.022599783757112
            ],
            [
              105.82272241830643,
              21.022608107203403
            ],
            [
              105.82257010873764,
              21.022670943373885
            ],
            [
              105.822464432729,
              21.022705466296173
            ],
            [
              105.82238375263238,
              21.022736086488443
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Đường La Thành",
          "maTaiSan": "04.O13.DOPP.279",
          "duongKinh": 100,
          "chieudaiQL": 195.31,
          "chatLieu": "G-CI",
          "from_latitude": 21.022048960375454,
          "to_latitude": 21.022736086488443,
          "to_longitude": 105.82238375263238,
          "from_longitude": 105.82405763841132
        }
      },
      {
        "id": 278,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82405763841132,
            21.021995691129007,
            105.82415339545089,
            21.022048960375454
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82415339545089,
              21.021995691129007
            ],
            [
              105.82410430936584,
              21.022016321997413
            ],
            [
              105.82409669360194,
              21.022019689090268
            ],
            [
              105.82409238478368,
              21.02202159409589
            ],
            [
              105.8240871892593,
              21.022023892687884
            ],
            [
              105.82407563204511,
              21.02203369629512
            ],
            [
              105.82407353317106,
              21.022035476786606
            ],
            [
              105.82405763841132,
              21.022048960375454
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Đường La Thành",
          "maTaiSan": "04.O13.DOPP.280",
          "duongKinh": 150,
          "chieudaiQL": 11.54,
          "chatLieu": "G-CI",
          "from_latitude": 21.021995691129007,
          "to_latitude": 21.022048960375454,
          "to_longitude": 105.82405763841132,
          "from_longitude": 105.82415339545089
        }
      },
      {
        "id": 279,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82416382856573,
            21.02196793123402,
            105.82620283823003,
            21.022412457411534
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82620283823003,
              21.02196793123402
            ],
            [
              105.8261869683681,
              21.021973151286677
            ],
            [
              105.82598333045966,
              21.022040129065203
            ],
            [
              105.82583344168101,
              21.022089578680692
            ],
            [
              105.8258296266679,
              21.022090837332435
            ],
            [
              105.8257406717049,
              21.022110449208448
            ],
            [
              105.82569898404066,
              21.022118960360586
            ],
            [
              105.82567383729805,
              21.022124094433696
            ],
            [
              105.8255587558177,
              21.022158721811568
            ],
            [
              105.82548087687537,
              21.02218005553405
            ],
            [
              105.82546457003347,
              21.022147496623454
            ],
            [
              105.8254637124427,
              21.022145314979895
            ],
            [
              105.82546287240083,
              21.022143175702762
            ],
            [
              105.825457639023,
              21.022129857751516
            ],
            [
              105.82545361043925,
              21.022131289947513
            ],
            [
              105.82529700199237,
              21.02219210809167
            ],
            [
              105.8252886390955,
              21.022195356023914
            ],
            [
              105.82520773724929,
              21.022226773448395
            ],
            [
              105.82513905212784,
              21.022257244600958
            ],
            [
              105.82508589223367,
              21.02229153331605
            ],
            [
              105.8250821221869,
              21.022292749281583
            ],
            [
              105.82504940628048,
              21.02229923778748
            ],
            [
              105.82504415766566,
              21.022300278472873
            ],
            [
              105.82493663441458,
              21.022339669417164
            ],
            [
              105.82493110225727,
              21.022341453925737
            ],
            [
              105.82492733988856,
              21.022342667141004
            ],
            [
              105.82491361645674,
              21.02234427640956
            ],
            [
              105.82491023823101,
              21.022344672148684
            ],
            [
              105.82490353177636,
              21.022345458868127
            ],
            [
              105.82468728993449,
              21.022412457411534
            ],
            [
              105.8246352449016,
              21.022311901649932
            ],
            [
              105.82463340661113,
              21.022312611465683
            ],
            [
              105.82446278802806,
              21.022378457295737
            ],
            [
              105.82433676304645,
              21.022105036703316
            ],
            [
              105.82416382856573,
              21.02216204003802
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 39, Hào Nam",
          "maTaiSan": "04.O13.DOPP.281",
          "duongKinh": 100,
          "chieudaiQL": 256.99,
          "chatLieu": "G-CI",
          "from_latitude": 21.02196793123402,
          "to_latitude": 21.02216204003802,
          "to_longitude": 105.82416382856573,
          "from_longitude": 105.82620283823003
        }
      },
      {
        "id": 280,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82415339545089,
            21.02126588194102,
            105.82576355058647,
            21.021995691129007
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82576355058647,
              21.02126588194102
            ],
            [
              105.82560164978138,
              21.02133497790452
            ],
            [
              105.82539129348937,
              21.021424755178458
            ],
            [
              105.82533796850335,
              21.021447512382906
            ],
            [
              105.82533478222685,
              21.021448871843397
            ],
            [
              105.82516605933095,
              21.02152088269759
            ],
            [
              105.82514472852287,
              21.021529982306085
            ],
            [
              105.82495253634401,
              21.02161200525561
            ],
            [
              105.82456035931534,
              21.02180508996867
            ],
            [
              105.82452361030599,
              21.02182302101807
            ],
            [
              105.82449758071276,
              21.021835721905312
            ],
            [
              105.8244695526763,
              21.021849398023974
            ],
            [
              105.82444105740016,
              21.021863301306116
            ],
            [
              105.82424601128002,
              21.021958471115067
            ],
            [
              105.82418792830423,
              21.021981813734563
            ],
            [
              105.82417435781636,
              21.02198726723351
            ],
            [
              105.82415339545089,
              21.021995691129007
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Đường La Thành",
          "maTaiSan": "04.O13.DOPP.282",
          "duongKinh": 100,
          "chieudaiQL": 185.92,
          "chatLieu": "G-CI",
          "from_latitude": 21.02126588194102,
          "to_latitude": 21.021995691129007,
          "to_longitude": 105.82415339545089,
          "from_longitude": 105.82576355058647
        }
      },
      {
        "id": 281,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82576355058647,
            21.0211194549621,
            105.82606259519797,
            21.02126588194102
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82606259519797,
              21.0211194549621
            ],
            [
              105.82605071535443,
              21.0211261865896
            ],
            [
              105.82591897349191,
              21.02118075161022
            ],
            [
              105.82586498561551,
              21.021203112065777
            ],
            [
              105.82579127414512,
              21.021233642195803
            ],
            [
              105.82578432206317,
              21.02123593220507
            ],
            [
              105.82577688987213,
              21.021238381706173
            ],
            [
              105.82577173066674,
              21.0212490124273
            ],
            [
              105.82576355058647,
              21.02126588194102
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D200_Đường La Thành",
          "maTaiSan": "04.O13.DOPP.283",
          "duongKinh": 200,
          "chieudaiQL": 34.55,
          "chatLieu": "G-CI",
          "from_latitude": 21.0211194549621,
          "to_latitude": 21.02126588194102,
          "to_longitude": 105.82576355058647,
          "from_longitude": 105.82606259519797
        }
      },
      {
        "id": 282,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82560025170446,
            21.02463500644817,
            105.82627826114597,
            21.024978566793482
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82624203129768,
              21.02463500644817
            ],
            [
              105.82624286320028,
              21.024639329757907
            ],
            [
              105.82625302138655,
              21.024692130814962
            ],
            [
              105.82625527150934,
              21.024703828389644
            ],
            [
              105.82626969819874,
              21.024778818108018
            ],
            [
              105.82627826114597,
              21.02482074847009
            ],
            [
              105.82626254363379,
              21.024823119024532
            ],
            [
              105.82625060395584,
              21.02482497896592
            ],
            [
              105.82617419655544,
              21.02483688344259
            ],
            [
              105.82609022029682,
              21.024850910197113
            ],
            [
              105.82604495988551,
              21.02486044770896
            ],
            [
              105.82600857822273,
              21.024871643761816
            ],
            [
              105.82600468670081,
              21.024872472855517
            ],
            [
              105.82590172712645,
              21.02489519854896
            ],
            [
              105.82586505110892,
              21.02490448026062
            ],
            [
              105.82585835435671,
              21.024906174714705
            ],
            [
              105.8256998639191,
              21.02494628605504
            ],
            [
              105.82565173969881,
              21.024958464849686
            ],
            [
              105.82560484883227,
              21.024976772348737
            ],
            [
              105.82560025170446,
              21.024978566793482
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngách 127/23, Hào Nam",
          "maTaiSan": "04.O13.DOPP.284",
          "duongKinh": 100,
          "chieudaiQL": 80.29,
          "chatLieu": "G-CI",
          "from_latitude": 21.02463500644817,
          "to_latitude": 21.024978566793482,
          "to_longitude": 105.82560025170446,
          "from_longitude": 105.82624203129768
        }
      },
      {
        "id": 283,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82847664640273,
            21.01983544363616,
            105.82858685342714,
            21.020040624497817
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82847664640273,
              21.01983544363616
            ],
            [
              105.82849081025805,
              21.019854750637393
            ],
            [
              105.82849127936437,
              21.019855386932775
            ],
            [
              105.82849861445598,
              21.0198620233309
            ],
            [
              105.82853771264466,
              21.019897383836756
            ],
            [
              105.82857941400883,
              21.02001893907466
            ],
            [
              105.82858685342714,
              21.020040624497817
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ Cẩm Văn, La Thành",
          "maTaiSan": "04.O13.DOPP.285",
          "duongKinh": 100,
          "chieudaiQL": 26,
          "chatLieu": "G-DI",
          "from_latitude": 21.01983544363616,
          "to_latitude": 21.020040624497817,
          "to_longitude": 105.82858685342714,
          "from_longitude": 105.82847664640273
        }
      },
      {
        "id": 284,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82898793117751,
            21.021111399959473,
            105.82926211940811,
            21.021767529847633
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82898793117751,
              21.021111399959473
            ],
            [
              105.82899573351818,
              21.02113954887433
            ],
            [
              105.8290131141673,
              21.021191350825
            ],
            [
              105.8290137450396,
              21.021193231877454
            ],
            [
              105.82905915649938,
              21.02128606800008
            ],
            [
              105.82908853314967,
              21.021346768751393
            ],
            [
              105.82909431631509,
              21.021360298871397
            ],
            [
              105.82910227767748,
              21.02137892611058
            ],
            [
              105.82911206575952,
              21.021410066755205
            ],
            [
              105.82913172710934,
              21.02147261588466
            ],
            [
              105.82915303611131,
              21.02153912484597
            ],
            [
              105.82915576226247,
              21.021547633483443
            ],
            [
              105.82919978204092,
              21.02163561609031
            ],
            [
              105.82922103029922,
              21.021678084200055
            ],
            [
              105.82925118542265,
              21.02174488872778
            ],
            [
              105.82926211940811,
              21.021767529847633
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ Cẩm Văn, La Thành",
          "maTaiSan": "04.O13.DOPP.286",
          "duongKinh": 100,
          "chieudaiQL": 91,
          "chatLieu": "G-CI",
          "from_latitude": 21.021111399959473,
          "to_latitude": 21.021767529847633,
          "to_longitude": 105.82926211940811,
          "from_longitude": 105.82898793117751
        }
      },
      {
        "id": 285,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82997674390468,
            21.02076287908072,
            105.83020209098112,
            21.02130221132159
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83005690558895,
              21.02076287908072
            ],
            [
              105.82997674390468,
              21.020822715989897
            ],
            [
              105.82998125046947,
              21.020829793347254
            ],
            [
              105.82998674101532,
              21.02083841403044
            ],
            [
              105.82998751151845,
              21.020839624204903
            ],
            [
              105.83000070805993,
              21.020860346627178
            ],
            [
              105.83003109961571,
              21.020927951927607
            ],
            [
              105.8300377866797,
              21.02094366521383
            ],
            [
              105.83003896503037,
              21.020946434170227
            ],
            [
              105.83007074084345,
              21.021008294519763
            ],
            [
              105.83009584783454,
              21.02106538044545
            ],
            [
              105.83009664670028,
              21.021067195641496
            ],
            [
              105.8301031601948,
              21.02107553313479
            ],
            [
              105.83011724124918,
              21.021116315464983
            ],
            [
              105.83013880950914,
              21.021179369097712
            ],
            [
              105.83013912827903,
              21.021180302379598
            ],
            [
              105.83017246430971,
              21.02124351715411
            ],
            [
              105.83019485489638,
              21.021287421109133
            ],
            [
              105.83020209098112,
              21.02130221132159
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ Thổ Quan 2, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DOPP.287",
          "duongKinh": 100,
          "chieudaiQL": 80,
          "chatLieu": "G-CI",
          "from_latitude": 21.02076287908072,
          "to_latitude": 21.02130221132159,
          "to_longitude": 105.83020209098112,
          "from_longitude": 105.83005690558895
        }
      },
      {
        "id": 286,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83020209098112,
            21.020888087321826,
            105.83102702023733,
            21.02130221132159
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83102702023733,
              21.020888087321826
            ],
            [
              105.83100064267005,
              21.020895493473247
            ],
            [
              105.83096161405426,
              21.020906515614328
            ],
            [
              105.8309572295216,
              21.020907754580875
            ],
            [
              105.83088564622636,
              21.020932382949116
            ],
            [
              105.8307702745475,
              21.020985592741695
            ],
            [
              105.83059825075455,
              21.02106493030476
            ],
            [
              105.83057352898157,
              21.021079857586816
            ],
            [
              105.8304701692045,
              21.021142269288948
            ],
            [
              105.83040878511862,
              21.02117933384223
            ],
            [
              105.83026443018983,
              21.021266497015468
            ],
            [
              105.83021991744899,
              21.021293373869934
            ],
            [
              105.83020209098112,
              21.02130221132159
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D200_Ngõ Thổ Quan 1, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DOPP.288",
          "duongKinh": 200,
          "chieudaiQL": 88,
          "chatLieu": "G-DI",
          "from_latitude": 21.020888087321826,
          "to_latitude": 21.02130221132159,
          "to_longitude": 105.83020209098112,
          "from_longitude": 105.83102702023733
        }
      },
      {
        "id": 287,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.828007300413,
            21.022202573790693,
            105.82807531216096,
            21.022465904900287
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82807531216096,
              21.022465904900287
            ],
            [
              105.8280707074765,
              21.022449066817835
            ],
            [
              105.82807021165792,
              21.02244725284633
            ],
            [
              105.82806998192143,
              21.022446413965586
            ],
            [
              105.82806961275902,
              21.02244506363507
            ],
            [
              105.82805116456099,
              21.022377600350026
            ],
            [
              105.82803909018567,
              21.02232942240192
            ],
            [
              105.828007300413,
              21.022202573790693
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ Thổ Quan 1, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DOPP.289",
          "duongKinh": 100,
          "chieudaiQL": 34.07,
          "chatLieu": "G-DI",
          "from_latitude": 21.022465904900287,
          "to_latitude": 21.022202573790693,
          "to_longitude": 105.828007300413,
          "from_longitude": 105.82807531216096
        }
      },
      {
        "id": 288,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82689642884397,
            21.02310310987106,
            105.82714264878685,
            21.02314312051439
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82689642884397,
              21.02314312051439
            ],
            [
              105.82689938484062,
              21.023142563304543
            ],
            [
              105.82702746881003,
              21.023118378099795
            ],
            [
              105.82711300162086,
              21.023104904242597
            ],
            [
              105.82714264878685,
              21.02310310987106
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Ngõ 44, Hào Nam",
          "maTaiSan": "04.O13.DOPP.290",
          "duongKinh": 150,
          "chieudaiQL": 35,
          "chatLieu": "G-CI",
          "from_latitude": 21.02314312051439,
          "to_latitude": 21.02310310987106,
          "to_longitude": 105.82714264878685,
          "from_longitude": 105.82689642884397
        }
      },
      {
        "id": 289,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82903469430235,
            21.022671872381284,
            105.82914125588427,
            21.023128493953028
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82903469430235,
              21.022671872381284
            ],
            [
              105.8290375695114,
              21.022746135057833
            ],
            [
              105.82906360522037,
              21.02288768618189
            ],
            [
              105.82906450975437,
              21.022892606154194
            ],
            [
              105.8290883596384,
              21.022976875475905
            ],
            [
              105.82909237451479,
              21.0229910600172
            ],
            [
              105.82909923879737,
              21.023009942791795
            ],
            [
              105.82911651037479,
              21.023057453121332
            ],
            [
              105.82914125588427,
              21.023128493953028
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Ngõ Thổ Quan 1, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DOPP.291",
          "duongKinh": 150,
          "chieudaiQL": 60,
          "chatLieu": "G-CI",
          "from_latitude": 21.022671872381284,
          "to_latitude": 21.023128493953028,
          "to_longitude": 105.82914125588427,
          "from_longitude": 105.82903469430235
        }
      },
      {
        "id": 290,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82856968062237,
            21.023128493953028,
            105.82914125588427,
            21.023262529505683
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82856968062237,
              21.023258555552214
            ],
            [
              105.82860553126628,
              21.023260348119177
            ],
            [
              105.8286143455025,
              21.02326078930544
            ],
            [
              105.82869980927839,
              21.023252420014877
            ],
            [
              105.8288199024268,
              21.02326158965278
            ],
            [
              105.82883221816539,
              21.023262529505683
            ],
            [
              105.82887419136209,
              21.023258142121744
            ],
            [
              105.82909546636674,
              21.02315790216947
            ],
            [
              105.82912543737791,
              21.02313865376555
            ],
            [
              105.82914125588427,
              21.023128493953028
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngách 127, ngõ Thổ Quan 1, Hào Nam",
          "maTaiSan": "04.O13.DOPP.292",
          "duongKinh": 100,
          "chieudaiQL": 70,
          "chatLieu": "G-CI",
          "from_latitude": 21.023258555552214,
          "to_latitude": 21.023128493953028,
          "to_longitude": 105.82914125588427,
          "from_longitude": 105.82856968062237
        }
      },
      {
        "id": 291,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8277388690905,
            21.025890853072127,
            105.82929847261838,
            21.026288192713242
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82929847261838,
              21.025890853072127
            ],
            [
              105.82921815033765,
              21.0259103678095
            ],
            [
              105.82907449304548,
              21.025945271524655
            ],
            [
              105.82897266225658,
              21.02597001228636
            ],
            [
              105.82894415500758,
              21.02597693807301
            ],
            [
              105.82889225534142,
              21.025989547155483
            ],
            [
              105.82877111845607,
              21.026008971581994
            ],
            [
              105.82863930601664,
              21.026030108067868
            ],
            [
              105.82858332172651,
              21.026039775286726
            ],
            [
              105.82853250423886,
              21.02604855112248
            ],
            [
              105.82844470595792,
              21.02607640978157
            ],
            [
              105.8282858619487,
              21.026120689130288
            ],
            [
              105.82822201579461,
              21.026138486512135
            ],
            [
              105.82809870683165,
              21.026178002435973
            ],
            [
              105.82804269316333,
              21.0261959530951
            ],
            [
              105.82801895770577,
              21.02620172014097
            ],
            [
              105.82774311008446,
              21.026286884072363
            ],
            [
              105.8277388690905,
              21.026288192713242
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 168, Hào Nam",
          "maTaiSan": "04.O13.DOPP.293",
          "duongKinh": 100,
          "chieudaiQL": 191,
          "chatLieu": "G-CI",
          "from_latitude": 21.025890853072127,
          "to_latitude": 21.026288192713242,
          "to_longitude": 105.8277388690905,
          "from_longitude": 105.82929847261838
        }
      },
      {
        "id": 292,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82918704528385,
            21.025637714979833,
            105.82929847261838,
            21.025890853072127
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82918704528385,
              21.025637714979833
            ],
            [
              105.82918785233929,
              21.02564031233129
            ],
            [
              105.82919153505607,
              21.02565217087371
            ],
            [
              105.82922766151123,
              21.02568372229048
            ],
            [
              105.82924091490818,
              21.025724573734
            ],
            [
              105.82925692234298,
              21.025773913798563
            ],
            [
              105.82929044688666,
              21.0258649886358
            ],
            [
              105.82929847261838,
              21.025890853072127
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngách 168/48, Hào Nam",
          "maTaiSan": "04.O13.DOPP.294",
          "duongKinh": 100,
          "chieudaiQL": 40,
          "chatLieu": "G-CI",
          "from_latitude": 21.025637714979833,
          "to_latitude": 21.025890853072127,
          "to_longitude": 105.82929847261838,
          "from_longitude": 105.82918704528385
        }
      },
      {
        "id": 293,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.829108834343,
            21.025398155615896,
            105.82918704528385,
            21.025637714979833
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.829108834343,
              21.025398155615896
            ],
            [
              105.82910978305686,
              21.02540131950224
            ],
            [
              105.8291230140138,
              21.025445429901307
            ],
            [
              105.82918642408828,
              21.025635848333987
            ],
            [
              105.82918704528385,
              21.025637714979833
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ Thổ Quan 1, Hào Nam",
          "maTaiSan": "04.O13.DOPP.295",
          "duongKinh": 100,
          "chieudaiQL": 37,
          "chatLieu": "G-CI",
          "from_latitude": 21.025398155615896,
          "to_latitude": 21.025637714979833,
          "to_longitude": 105.82918704528385,
          "from_longitude": 105.829108834343
        }
      },
      {
        "id": 294,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82738473053502,
            21.024162115297205,
            105.82766890481612,
            21.02507328027528
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82738473053502,
              21.024162115297205
            ],
            [
              105.82738750115965,
              21.024168754973232
            ],
            [
              105.82740657378366,
              21.024214453882834
            ],
            [
              105.82745909084747,
              21.024340290381133
            ],
            [
              105.82748870780154,
              21.024374984568855
            ],
            [
              105.82756637473052,
              21.024546885964632
            ],
            [
              105.82757088529988,
              21.024560961549593
            ],
            [
              105.82757600959931,
              21.02457694988784
            ],
            [
              105.82757384637765,
              21.024627719844414
            ],
            [
              105.82757435337858,
              21.024636613238833
            ],
            [
              105.82757655175591,
              21.02467516753251
            ],
            [
              105.82763471598172,
              21.024767531872723
            ],
            [
              105.82766467422452,
              21.02481662628333
            ],
            [
              105.82766890481612,
              21.02503265255078
            ],
            [
              105.82765171877936,
              21.02507047743739
            ],
            [
              105.82765132059328,
              21.02507328027528
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Ngõ 46, Hào Nam",
          "maTaiSan": "04.O13.DOPP.296",
          "duongKinh": 150,
          "chieudaiQL": 121,
          "chatLieu": "G-CI",
          "from_latitude": 21.024162115297205,
          "to_latitude": 21.02507328027528,
          "to_longitude": 105.82765132059328,
          "from_longitude": 105.82738473053502
        }
      },
      {
        "id": 295,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82713917001318,
            21.02310310987106,
            105.82724865310348,
            21.023470339677793
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82714264878685,
              21.02310310987106
            ],
            [
              105.82714149655777,
              21.023119717654808
            ],
            [
              105.82713917001318,
              21.023153289200344
            ],
            [
              105.82714535126514,
              21.02322190876072
            ],
            [
              105.82716669922125,
              21.023308315834008
            ],
            [
              105.82718121422924,
              21.02333701115192
            ],
            [
              105.82719337168052,
              21.02336104735788
            ],
            [
              105.82724865310348,
              21.023470339677793
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Ngõ 46, Hào Nam",
          "maTaiSan": "04.O13.DOPP.297",
          "duongKinh": 150,
          "chieudaiQL": 50,
          "chatLieu": "G-CI",
          "from_latitude": 21.02310310987106,
          "to_latitude": 21.023470339677793,
          "to_longitude": 105.82724865310348,
          "from_longitude": 105.82714264878685
        }
      },
      {
        "id": 296,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82606259519797,
            21.0211194549621,
            105.82669348502667,
            21.022597476997177
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82606259519797,
              21.0211194549621
            ],
            [
              105.82606326187587,
              21.021121730562207
            ],
            [
              105.82609398194256,
              21.0212263997773
            ],
            [
              105.8261439819674,
              21.021430945235217
            ],
            [
              105.82616951593054,
              21.021535400264153
            ],
            [
              105.82621127264962,
              21.02162517946006
            ],
            [
              105.8262234123121,
              21.021651279704795
            ],
            [
              105.82626533361865,
              21.021741412149265
            ],
            [
              105.82647841621353,
              21.022126736940628
            ],
            [
              105.82655207526484,
              21.022259936214358
            ],
            [
              105.82656819398618,
              21.022292877088503
            ],
            [
              105.8265690666564,
              21.02229499903983
            ],
            [
              105.82658479906772,
              21.022333247334572
            ],
            [
              105.82669348502667,
              21.022597476997177
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D200_Hào Nam",
          "maTaiSan": "04.O13.DOPP.298",
          "duongKinh": 200,
          "chieudaiQL": 175.6,
          "chatLieu": "G-CI",
          "from_latitude": 21.0211194549621,
          "to_latitude": 21.022597476997177,
          "to_longitude": 105.82669348502667,
          "from_longitude": 105.82606259519797
        }
      },
      {
        "id": 297,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82669348502667,
            21.022597476997177,
            105.82689642884397,
            21.02314312051439
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82669348502667,
              21.022597476997177
            ],
            [
              105.82669891989511,
              21.022617655728634
            ],
            [
              105.8267598045614,
              21.02284372517637
            ],
            [
              105.82676075522501,
              21.022847254875323
            ],
            [
              105.82676416307456,
              21.022859906946078
            ],
            [
              105.82678275899084,
              21.02292895482379
            ],
            [
              105.82678413657597,
              21.02293366928509
            ],
            [
              105.82684353041068,
              21.02303832176732
            ],
            [
              105.82686000365256,
              21.023070629528835
            ],
            [
              105.82689587448805,
              21.023140981662824
            ],
            [
              105.82689642884397,
              21.02314312051439
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D200_Hào Nam",
          "maTaiSan": "04.O13.DOPP.299",
          "duongKinh": 200,
          "chieudaiQL": 62.87,
          "chatLieu": "G-CI",
          "from_latitude": 21.022597476997177,
          "to_latitude": 21.02314312051439,
          "to_longitude": 105.82689642884397,
          "from_longitude": 105.82669348502667
        }
      },
      {
        "id": 298,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82689642884397,
            21.02314312051439,
            105.82743254463759,
            21.025148546560008
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82689642884397,
              21.02314312051439
            ],
            [
              105.8268974421607,
              21.023147892745456
            ],
            [
              105.82694244658002,
              21.023362396735095
            ],
            [
              105.82695745469658,
              21.02343393415704
            ],
            [
              105.82707558761112,
              21.023900392100202
            ],
            [
              105.82714114786302,
              21.024159262708366
            ],
            [
              105.82724087148699,
              21.024505972827313
            ],
            [
              105.82726830573604,
              21.024601351803227
            ],
            [
              105.82736564173338,
              21.02492564545273
            ],
            [
              105.82743254463759,
              21.025148546560008
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D200_Hào Nam",
          "maTaiSan": "04.O13.DOPP.300",
          "duongKinh": 200,
          "chieudaiQL": 261,
          "chatLieu": "G-CI",
          "from_latitude": 21.02314312051439,
          "to_latitude": 21.025148546560008,
          "to_longitude": 105.82743254463759,
          "from_longitude": 105.82689642884397
        }
      },
      {
        "id": 299,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82743254463759,
            21.025148546560008,
            105.8277388690905,
            21.026288192713242
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82743254463759,
              21.025148546560008
            ],
            [
              105.82753808488758,
              21.025504652374455
            ],
            [
              105.82760183901858,
              21.02571976213658
            ],
            [
              105.82766809303648,
              21.02594330936471
            ],
            [
              105.82767910827305,
              21.02598047583758
            ],
            [
              105.82773876584176,
              21.026277159423483
            ],
            [
              105.8277388690905,
              21.026288192713242
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D200_Hào Nam",
          "maTaiSan": "04.O13.DOPP.301",
          "duongKinh": 200,
          "chieudaiQL": 149,
          "chatLieu": "G-CI",
          "from_latitude": 21.025148546560008,
          "to_latitude": 21.026288192713242,
          "to_longitude": 105.8277388690905,
          "from_longitude": 105.82743254463759
        }
      },
      {
        "id": 300,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8277388690905,
            21.026288192713242,
            105.82789588337951,
            21.026995320727607
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8277388690905,
              21.026288192713242
            ],
            [
              105.82781581572134,
              21.026691940731713
            ],
            [
              105.82789588337951,
              21.026995320727607
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D200_Hào Nam",
          "maTaiSan": "04.O13.DOPP.302",
          "duongKinh": 200,
          "chieudaiQL": 83,
          "chatLieu": "ST",
          "from_latitude": 21.026288192713242,
          "to_latitude": 21.026995320727607,
          "to_longitude": 105.82789588337951,
          "from_longitude": 105.8277388690905
        }
      },
      {
        "id": 301,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82789588337951,
            21.026995320727607,
            105.82830458389878,
            21.02717149063346
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82789588337951,
              21.026995320727607
            ],
            [
              105.82830220977011,
              21.027170467102255
            ],
            [
              105.82830458389878,
              21.02717149063346
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D200_An Trạch",
          "maTaiSan": "04.O13.DOPP.303",
          "duongKinh": 200,
          "chieudaiQL": 47,
          "chatLieu": "ST",
          "from_latitude": 21.026995320727607,
          "to_latitude": 21.02717149063346,
          "to_longitude": 105.82830458389878,
          "from_longitude": 105.82789588337951
        }
      },
      {
        "id": 302,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82901832424345,
            21.026598683355633,
            105.83114293818265,
            21.027490300206995
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83114293818265,
              21.026598683355633
            ],
            [
              105.83113422296216,
              21.02660468542274
            ],
            [
              105.83108620795582,
              21.026628241328964
            ],
            [
              105.83089206622272,
              21.02673083794471
            ],
            [
              105.83088415187315,
              21.026735020605983
            ],
            [
              105.8308703470839,
              21.026742316557105
            ],
            [
              105.83086221307484,
              21.02674661500008
            ],
            [
              105.83071860649818,
              21.02682250546458
            ],
            [
              105.83068299217358,
              21.026838689986686
            ],
            [
              105.83049960620606,
              21.02692202837293
            ],
            [
              105.83048543827101,
              21.026922474209048
            ],
            [
              105.83046398053129,
              21.026923148933932
            ],
            [
              105.8304440800179,
              21.026923775406484
            ],
            [
              105.83039118197027,
              21.026904322660084
            ],
            [
              105.83033254114179,
              21.026874348279332
            ],
            [
              105.8302895043124,
              21.026863512882905
            ],
            [
              105.83027779186892,
              21.026860564138218
            ],
            [
              105.83027178183339,
              21.02686101980333
            ],
            [
              105.8302653910024,
              21.02686150442414
            ],
            [
              105.83026061759229,
              21.02686186643851
            ],
            [
              105.8301744894763,
              21.026855893899622
            ],
            [
              105.83008333141892,
              21.026837484988498
            ],
            [
              105.83007085049753,
              21.02683195946898
            ],
            [
              105.83002770249325,
              21.026812854627213
            ],
            [
              105.8298726175121,
              21.026852223447122
            ],
            [
              105.82975089482572,
              21.02691555536866
            ],
            [
              105.82970529460891,
              21.026954491858046
            ],
            [
              105.82966900333054,
              21.027035243749825
            ],
            [
              105.82965701899523,
              21.027061909398988
            ],
            [
              105.82959895923369,
              21.027127530989386
            ],
            [
              105.82958395438419,
              21.02714328506545
            ],
            [
              105.82958042889908,
              21.027146987418984
            ],
            [
              105.82957717577604,
              21.027148797995423
            ],
            [
              105.82924589284002,
              21.027333164057303
            ],
            [
              105.82916888387997,
              21.02737875949254
            ],
            [
              105.82916772076658,
              21.0273794480014
            ],
            [
              105.82916532550153,
              21.02738086600086
            ],
            [
              105.8291517217267,
              21.027367831063373
            ],
            [
              105.82915040654386,
              21.0273688545066
            ],
            [
              105.82914549545377,
              21.027372678316983
            ],
            [
              105.82903703944638,
              21.027457110312554
            ],
            [
              105.8290211898705,
              21.0274852173419
            ],
            [
              105.82901832424345,
              21.027490300206995
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Ngõ An Trạch 1, An Trạch",
          "maTaiSan": "04.O13.DOPP.304",
          "duongKinh": 150,
          "chieudaiQL": 341,
          "chatLieu": "G-CI",
          "from_latitude": 21.026598683355633,
          "to_latitude": 21.027490300206995,
          "to_longitude": 105.82901832424345,
          "from_longitude": 105.83114293818265
        }
      },
      {
        "id": 303,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83114293818265,
            21.026598683355633,
            105.83125590176405,
            21.026792960245935
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83114293818265,
              21.026598683355633
            ],
            [
              105.83114601127221,
              21.026603967551466
            ],
            [
              105.83118714744452,
              21.026674713838787
            ],
            [
              105.83119435718183,
              21.026687113284535
            ],
            [
              105.8311976216786,
              21.0266927379624
            ],
            [
              105.83123070795783,
              21.026749631094738
            ],
            [
              105.83124285953801,
              21.02677053101048
            ],
            [
              105.83124828796008,
              21.026779864586217
            ],
            [
              105.83125590176405,
              21.026792960245935
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D160_Ngõ An Trạch 2, An Trạch",
          "maTaiSan": "04.O13.DOPP.305",
          "duongKinh": 160,
          "chieudaiQL": 25,
          "chatLieu": "HDPE",
          "from_latitude": 21.026598683355633,
          "to_latitude": 21.026792960245935,
          "to_longitude": 105.83125590176405,
          "from_longitude": 105.83114293818265
        }
      },
      {
        "id": 304,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83034674416811,
            21.025493148597022,
            105.83085887543957,
            21.025632683450674
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83034674416811,
              21.025632683450674
            ],
            [
              105.83042188200181,
              21.02559599159762
            ],
            [
              105.83055110295615,
              21.025605217446994
            ],
            [
              105.83055899063866,
              21.02560578047248
            ],
            [
              105.83056600255333,
              21.025606280936138
            ],
            [
              105.83058735862133,
              21.02560187457767
            ],
            [
              105.83077744589434,
              21.025522826226034
            ],
            [
              105.83082500257507,
              21.025504803996807
            ],
            [
              105.83085301744512,
              21.02549516430978
            ],
            [
              105.83085887543957,
              21.025493148597022
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngách 86, Ngõ An Trạch 2, An Trạch",
          "maTaiSan": "04.O13.DOPP.306",
          "duongKinh": 100,
          "chieudaiQL": 62,
          "chatLieu": "G-CI",
          "from_latitude": 21.025632683450674,
          "to_latitude": 21.025493148597022,
          "to_longitude": 105.83085887543957,
          "from_longitude": 105.83034674416811
        }
      },
      {
        "id": 305,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83104078430114,
            21.02607179005609,
            105.83116461444945,
            21.026598683355633
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83104078430114,
              21.02607179005609
            ],
            [
              105.83105406442074,
              21.026100013234732
            ],
            [
              105.83112211510506,
              21.02624463007003
            ],
            [
              105.83112384403061,
              21.02624831038745
            ],
            [
              105.8311252479048,
              21.026265370848424
            ],
            [
              105.83112751932121,
              21.02629298214591
            ],
            [
              105.83114457592728,
              21.026388112148823
            ],
            [
              105.83115235386242,
              21.02640513694028
            ],
            [
              105.83116276001641,
              21.026438994917555
            ],
            [
              105.83116461444945,
              21.026484223830277
            ],
            [
              105.83115534274899,
              21.026533522856163
            ],
            [
              105.83115487696065,
              21.02653661415891
            ],
            [
              105.83114878819181,
              21.026577001774147
            ],
            [
              105.83114293818265,
              21.026598683355633
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ An Trạch 2, An Trạch",
          "maTaiSan": "04.O13.DOPP.307",
          "duongKinh": 100,
          "chieudaiQL": 69,
          "chatLieu": "G-CI",
          "from_latitude": 21.02607179005609,
          "to_latitude": 21.026598683355633,
          "to_longitude": 105.83114293818265,
          "from_longitude": 105.83104078430114
        }
      },
      {
        "id": 306,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82955917002936,
            21.027809070475296,
            105.82992046949855,
            21.02856474312767
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82955917002936,
              21.027809070475296
            ],
            [
              105.82959262793744,
              21.027830792374104
            ],
            [
              105.82959739466375,
              21.0278441657402
            ],
            [
              105.82960620421966,
              21.02789238293218
            ],
            [
              105.82965151979221,
              21.028023054381364
            ],
            [
              105.82969034058911,
              21.028113005837607
            ],
            [
              105.82975090684384,
              21.028112924376934
            ],
            [
              105.82975562069288,
              21.02811291763348
            ],
            [
              105.82976949327399,
              21.02811366464884
            ],
            [
              105.82982035031876,
              21.02823547908437
            ],
            [
              105.82982083486408,
              21.02823663916921
            ],
            [
              105.82982247960067,
              21.028240579135414
            ],
            [
              105.82982305773236,
              21.028241961859898
            ],
            [
              105.82982495400822,
              21.0282465048555
            ],
            [
              105.82985609348209,
              21.02836117344643
            ],
            [
              105.82988392155606,
              21.028463646600507
            ],
            [
              105.82990882483632,
              21.028532532281538
            ],
            [
              105.82990976371485,
              21.02853512898453
            ],
            [
              105.8299103987475,
              21.02853688446243
            ],
            [
              105.82991090580363,
              21.028538287404327
            ],
            [
              105.82992046949855,
              21.02856474312767
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D200_Ngõ 27, Cát Linh",
          "maTaiSan": "04.O13.DOPP.308",
          "duongKinh": 200,
          "chieudaiQL": 125,
          "chatLieu": "ST",
          "from_latitude": 21.027809070475296,
          "to_latitude": 21.02856474312767,
          "to_longitude": 105.82992046949855,
          "from_longitude": 105.82955917002936
        }
      },
      {
        "id": 307,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82986557270623,
            21.028993488327714,
            105.82989500082138,
            21.02905489303207
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8298671628031,
              21.02905489303207
            ],
            [
              105.82986557270623,
              21.029044316813547
            ],
            [
              105.82986599828556,
              21.02902770173752
            ],
            [
              105.82989500082138,
              21.02902517026062
            ],
            [
              105.82989496151484,
              21.02899895798881
            ],
            [
              105.82989472247202,
              21.028993488327714
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Cát Linh",
          "maTaiSan": "04.O13.DOPP.309",
          "duongKinh": 150,
          "chieudaiQL": 18.59,
          "chatLieu": "G-DI",
          "from_latitude": 21.02905489303207,
          "to_latitude": 21.028993488327714,
          "to_longitude": 105.82989472247202,
          "from_longitude": 105.8298671628031
        }
      },
      {
        "id": 308,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82989472247202,
            21.02856474312767,
            105.82992046949855,
            21.028993488327714
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82992046949855,
              21.02856474312767
            ],
            [
              105.82991967163743,
              21.02858327214678
            ],
            [
              105.82991938950278,
              21.028589827317386
            ],
            [
              105.82991701214621,
              21.02864504397338
            ],
            [
              105.82990778919029,
              21.028859271402624
            ],
            [
              105.8299064622949,
              21.02888997755957
            ],
            [
              105.82990335414915,
              21.02896228587534
            ],
            [
              105.82989472247202,
              21.028993488327714
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D200_Ngõ 27, Cát Linh",
          "maTaiSan": "04.O13.DOPP.310",
          "duongKinh": 200,
          "chieudaiQL": 49,
          "chatLieu": "ST",
          "from_latitude": 21.02856474312767,
          "to_latitude": 21.028993488327714,
          "to_longitude": 105.82989472247202,
          "from_longitude": 105.82992046949855
        }
      },
      {
        "id": 309,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83146772446929,
            21.02765788610606,
            105.83174552585848,
            21.028621939186714
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83146772446929,
              21.02765788610606
            ],
            [
              105.83146777911708,
              21.027658199257537
            ],
            [
              105.83158276924021,
              21.02819656262301
            ],
            [
              105.83169888339368,
              21.02850267805129
            ],
            [
              105.83171740539713,
              21.028550052768427
            ],
            [
              105.83174552585848,
              21.028621939186714
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D110_An Trạch",
          "maTaiSan": "04.O13.DOPP.311",
          "duongKinh": 110,
          "chieudaiQL": 112,
          "chatLieu": "HDPE",
          "from_latitude": 21.02765788610606,
          "to_latitude": 21.028621939186714,
          "to_longitude": 105.83174552585848,
          "from_longitude": 105.83146772446929
        }
      },
      {
        "id": 310,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83141349200541,
            21.028561825666355,
            105.83179689115263,
            21.02871036547711
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83141349200541,
              21.02871036547711
            ],
            [
              105.83161020752277,
              21.028669008660728
            ],
            [
              105.83174552585848,
              21.028621939186714
            ],
            [
              105.83179689115263,
              21.02859699921532
            ],
            [
              105.83178142389625,
              21.028561825666355
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Cát Linh",
          "maTaiSan": "04.O13.DOPP.312",
          "duongKinh": 150,
          "chieudaiQL": 35,
          "chatLieu": "G-DI",
          "from_latitude": 21.02871036547711,
          "to_latitude": 21.028561825666355,
          "to_longitude": 105.83178142389625,
          "from_longitude": 105.83141349200541
        }
      },
      {
        "id": 311,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82986028742036,
            21.031764395841954,
            105.83007118117172,
            21.03212306164144
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83007118117172,
              21.03212306164144
            ],
            [
              105.83004005455695,
              21.03207725848812
            ],
            [
              105.82993293692356,
              21.031983637494264
            ],
            [
              105.8298869573741,
              21.031844880189773
            ],
            [
              105.82986028742036,
              21.031764395841954
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D110_Giảng Võ",
          "maTaiSan": "04.O13.DOPP.313",
          "duongKinh": 110,
          "chieudaiQL": 49,
          "chatLieu": "PVC",
          "from_latitude": 21.03212306164144,
          "to_latitude": 21.031764395841954,
          "to_longitude": 105.82986028742036,
          "from_longitude": 105.83007118117172
        }
      },
      {
        "id": 312,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82762227123135,
            21.029705938445453,
            105.82883504725422,
            21.030647986476296
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82883504725422,
              21.030647986476296
            ],
            [
              105.82762227123135,
              21.029705938445453
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D110_Giảng Võ",
          "maTaiSan": "04.O13.DOPP.314",
          "duongKinh": 110,
          "chieudaiQL": 220,
          "chatLieu": "PVC",
          "from_latitude": 21.030647986476296,
          "to_latitude": 21.029705938445453,
          "to_longitude": 105.82762227123135,
          "from_longitude": 105.82883504725422
        }
      },
      {
        "id": 313,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82993554563282,
            21.031550283101925,
            105.8321390622397,
            21.0321545164859
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8321390622397,
              21.031550283101925
            ],
            [
              105.8318570201343,
              21.031638489210682
            ],
            [
              105.83157769200986,
              21.03171428574765
            ],
            [
              105.8314744943985,
              21.03174228728373
            ],
            [
              105.83086645272749,
              21.03190727275463
            ],
            [
              105.8307275657714,
              21.03194495828871
            ],
            [
              105.83025096597142,
              21.032074277726984
            ],
            [
              105.83007118117172,
              21.03212306164144
            ],
            [
              105.82999148981109,
              21.03214468457541
            ],
            [
              105.82993554563282,
              21.0321545164859
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D160_Nguyễn Thái Học",
          "maTaiSan": "04.O13.DOPP.315",
          "duongKinh": 160,
          "chieudaiQL": 309,
          "chatLieu": "HDPE",
          "from_latitude": 21.031550283101925,
          "to_latitude": 21.0321545164859,
          "to_longitude": 105.82993554563282,
          "from_longitude": 105.8321390622397
        }
      },
      {
        "id": 314,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8321390622397,
            21.0312768868854,
            105.83281672920972,
            21.031550283101925
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83281672920972,
              21.0312768868854
            ],
            [
              105.83281074824319,
              21.03127931423887
            ],
            [
              105.83255680999262,
              21.03143313125661
            ],
            [
              105.83250486440842,
              21.031449353693887
            ],
            [
              105.8324781084278,
              21.031457709450365
            ],
            [
              105.83244584758476,
              21.031467783892005
            ],
            [
              105.83232550694757,
              21.03150014482404
            ],
            [
              105.83223842527488,
              21.031523562644843
            ],
            [
              105.8321390622397,
              21.031550283101925
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D160_Nguyễn Thái Học",
          "maTaiSan": "04.O13.DOPP.316",
          "duongKinh": 160,
          "chieudaiQL": 65,
          "chatLieu": "HDPE",
          "from_latitude": 21.0312768868854,
          "to_latitude": 21.031550283101925,
          "to_longitude": 105.8321390622397,
          "from_longitude": 105.83281672920972
        }
      },
      {
        "id": 315,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83281672920972,
            21.030971666031387,
            105.83403216559542,
            21.0312768868854
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83403216559542,
              21.030971666031387
            ],
            [
              105.83366736462258,
              21.031085796173002
            ],
            [
              105.83317786624227,
              21.03122354354573
            ],
            [
              105.83306102778292,
              21.03125642253576
            ],
            [
              105.83289610729298,
              21.031260336685765
            ],
            [
              105.83284410113059,
              21.03127117992332
            ],
            [
              105.83281672920972,
              21.0312768868854
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D160_Nguyễn Thái Học",
          "maTaiSan": "04.O13.DOPP.317",
          "duongKinh": 160,
          "chieudaiQL": 178,
          "chatLieu": "HDPE",
          "from_latitude": 21.030971666031387,
          "to_latitude": 21.0312768868854,
          "to_longitude": 105.83281672920972,
          "from_longitude": 105.83403216559542
        }
      },
      {
        "id": 316,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83403216559542,
            21.030382102183324,
            105.83590225978367,
            21.030971666031387
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83590225978367,
              21.030382102183324
            ],
            [
              105.83569915697707,
              21.03047238051274
            ],
            [
              105.83567224855317,
              21.030480763705608
            ],
            [
              105.8354653881489,
              21.03054522263657
            ],
            [
              105.83532802855163,
              21.030585844352448
            ],
            [
              105.83502584888657,
              21.03067520951951
            ],
            [
              105.83500070332538,
              21.030682645501834
            ],
            [
              105.8345861330623,
              21.03080524763606
            ],
            [
              105.83457081072007,
              21.030809907604944
            ],
            [
              105.83445443811327,
              21.030845301629217
            ],
            [
              105.83443790469182,
              21.03085033062563
            ],
            [
              105.83442586309353,
              21.030853992677542
            ],
            [
              105.8341389025846,
              21.03094127012824
            ],
            [
              105.83408057064835,
              21.03095788204522
            ],
            [
              105.83403216559542,
              21.030971666031387
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D160_Nguyễn Thái Học",
          "maTaiSan": "04.O13.DOPP.318",
          "duongKinh": 160,
          "chieudaiQL": 215,
          "chatLieu": "HDPE",
          "from_latitude": 21.030382102183324,
          "to_latitude": 21.030971666031387,
          "to_longitude": 105.83403216559542,
          "from_longitude": 105.83590225978367
        }
      },
      {
        "id": 317,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83512445518383,
            21.029235723887158,
            105.83590225978367,
            21.030382102183324
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83512445518383,
              21.029235723887158
            ],
            [
              105.8351922685622,
              21.029346276803455
            ],
            [
              105.83532650432468,
              21.029539725461127
            ],
            [
              105.8353482795786,
              21.0295711057438
            ],
            [
              105.83539172208987,
              21.029633298702947
            ],
            [
              105.83546938766465,
              21.029744486828307
            ],
            [
              105.83547234734803,
              21.029748716530637
            ],
            [
              105.83560242426907,
              21.02993461456093
            ],
            [
              105.8356690581022,
              21.030029843556644
            ],
            [
              105.83575477878695,
              21.03015234997358
            ],
            [
              105.83577911000796,
              21.0301871218775
            ],
            [
              105.83585733947766,
              21.03029892122191
            ],
            [
              105.83590225978367,
              21.030382102183324
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D160_Tôn Đức Thắng",
          "maTaiSan": "04.O13.DOPP.319",
          "duongKinh": 160,
          "chieudaiQL": 173,
          "chatLieu": "HDPE",
          "from_latitude": 21.029235723887158,
          "to_latitude": 21.030382102183324,
          "to_longitude": 105.83590225978367,
          "from_longitude": 105.83512445518383
        }
      },
      {
        "id": 318,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83483136400869,
            21.02883568583955,
            105.83512445518383,
            21.029235723887158
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83483136400869,
              21.02883568583955
            ],
            [
              105.83494205631051,
              21.028987357130287
            ],
            [
              105.8350788488718,
              21.029173624573545
            ],
            [
              105.8350947805886,
              21.029195317620243
            ],
            [
              105.83512445518383,
              21.029235723887158
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D160_Tôn Đức Thắng",
          "maTaiSan": "04.O13.DOPP.320",
          "duongKinh": 160,
          "chieudaiQL": 53,
          "chatLieu": "HDPE",
          "from_latitude": 21.02883568583955,
          "to_latitude": 21.029235723887158,
          "to_longitude": 105.83512445518383,
          "from_longitude": 105.83483136400869
        }
      },
      {
        "id": 319,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83138368795615,
            21.027856433944844,
            105.83415604171556,
            21.028854037068978
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83138368795615,
              21.028854037068978
            ],
            [
              105.83143847125345,
              21.028840507174653
            ],
            [
              105.8316461618047,
              21.028789290348847
            ],
            [
              105.83164838551191,
              21.02878868244221
            ],
            [
              105.8316506092291,
              21.02878807634194
            ],
            [
              105.83171197143773,
              21.02877171780361
            ],
            [
              105.83173799911965,
              21.02878475498056
            ],
            [
              105.83175415470807,
              21.02877979222648
            ],
            [
              105.83176645819223,
              21.02877601256896
            ],
            [
              105.83177176497935,
              21.02875577878671
            ],
            [
              105.83217023901067,
              21.02864955380171
            ],
            [
              105.83225598297112,
              21.028620796421805
            ],
            [
              105.83314314746706,
              21.02826596514751
            ],
            [
              105.83315107515234,
              21.02826279482952
            ],
            [
              105.83315439539258,
              21.028261467081208
            ],
            [
              105.83318500033005,
              21.028249225673704
            ],
            [
              105.83332062707453,
              21.028194980056313
            ],
            [
              105.8336792057762,
              21.02807462017436
            ],
            [
              105.83415006262577,
              21.027859170235082
            ],
            [
              105.83415604171556,
              21.027856433944844
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D160_Cát Linh",
          "maTaiSan": "04.O13.DOPP.321",
          "duongKinh": 160,
          "chieudaiQL": 300,
          "chatLieu": "HDPE",
          "from_latitude": 21.028854037068978,
          "to_latitude": 21.027856433944844,
          "to_longitude": 105.83415604171556,
          "from_longitude": 105.83138368795615
        }
      },
      {
        "id": 320,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83048952649966,
            21.028854037068978,
            105.83138368795615,
            21.029032120871832
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83048952649966,
              21.029032120871832
            ],
            [
              105.83052591065018,
              21.029024558407087
            ],
            [
              105.8308101351559,
              21.02896199097595
            ],
            [
              105.83085619802883,
              21.02895474786043
            ],
            [
              105.83085975633695,
              21.028954188539476
            ],
            [
              105.83087412701447,
              21.02895192890596
            ],
            [
              105.83124808453685,
              21.028893127674426
            ],
            [
              105.831263612727,
              21.028888651257994
            ],
            [
              105.83138368795615,
              21.028854037068978
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D160_Cát Linh",
          "maTaiSan": "04.O13.DOPP.322",
          "duongKinh": 160,
          "chieudaiQL": 96,
          "chatLieu": "HDPE",
          "from_latitude": 21.029032120871832,
          "to_latitude": 21.028854037068978,
          "to_longitude": 105.83138368795615,
          "from_longitude": 105.83048952649966
        }
      },
      {
        "id": 321,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82762227123135,
            21.02947978266905,
            105.82823927952752,
            21.029705938445453
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82762227123135,
              21.029705938445453
            ],
            [
              105.82764407329748,
              21.029599768501477
            ],
            [
              105.82788692449186,
              21.02954992867157
            ],
            [
              105.82814981777867,
              21.02949597515248
            ],
            [
              105.82818170662333,
              21.02949067597648
            ],
            [
              105.82823927952752,
              21.02947978266905
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D160_Cát Linh",
          "maTaiSan": "04.O13.DOPP.323",
          "duongKinh": 160,
          "chieudaiQL": 78,
          "chatLieu": "PVC",
          "from_latitude": 21.029705938445453,
          "to_latitude": 21.02947978266905,
          "to_longitude": 105.82823927952752,
          "from_longitude": 105.82762227123135
        }
      },
      {
        "id": 322,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83168713624087,
            21.028701214406556,
            105.83197018662172,
            21.029169310059263
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83168713624087,
              21.028701214406556
            ],
            [
              105.83171099081663,
              21.028729182569496
            ],
            [
              105.83175415470807,
              21.02877979222648
            ],
            [
              105.8318595459519,
              21.02890336245024
            ],
            [
              105.83186702418409,
              21.028929961931922
            ],
            [
              105.83188008470476,
              21.02895858171146
            ],
            [
              105.83192874013491,
              21.02906521038388
            ],
            [
              105.83193422997763,
              21.029079000136644
            ],
            [
              105.83197018662172,
              21.029169310059263
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D225_Trịnh Hoài Đức",
          "maTaiSan": "04.O13.DOPP.324",
          "duongKinh": 225,
          "chieudaiQL": 89,
          "chatLieu": "HDPE",
          "from_latitude": 21.028701214406556,
          "to_latitude": 21.029169310059263,
          "to_longitude": 105.83197018662172,
          "from_longitude": 105.83168713624087
        }
      },
      {
        "id": 323,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82987645457439,
            21.019921930376064,
            105.83052197725499,
            21.020083255493432
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83052197725499,
              21.019921930376064
            ],
            [
              105.8304837971452,
              21.01994404076389
            ],
            [
              105.83028282397903,
              21.020022156359254
            ],
            [
              105.83028059989064,
              21.020023020762835
            ],
            [
              105.83023711608601,
              21.02001675236902
            ],
            [
              105.83014032992669,
              21.01994048043323
            ],
            [
              105.83014003620728,
              21.019940248834644
            ],
            [
              105.83011917251923,
              21.019923807186927
            ],
            [
              105.83010413205824,
              21.019933687802183
            ],
            [
              105.82996287315987,
              21.020026485265806
            ],
            [
              105.82994669304561,
              21.020037114782365
            ],
            [
              105.82991843318831,
              21.02005567935637
            ],
            [
              105.8299042610841,
              21.020064989395788
            ],
            [
              105.82987645457439,
              21.020083255493432
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 278, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DOPP.325",
          "duongKinh": 100,
          "chieudaiQL": 75,
          "chatLieu": "G-CI",
          "from_latitude": 21.019921930376064,
          "to_latitude": 21.020083255493432,
          "to_longitude": 105.82987645457439,
          "from_longitude": 105.83052197725499
        }
      },
      {
        "id": 324,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82987020469032,
            21.019941814539276,
            105.83053110964842,
            21.02010881021822
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83053110964842,
              21.019941814539276
            ],
            [
              105.83049391810756,
              21.01996508164978
            ],
            [
              105.83028936000998,
              21.02004325811113
            ],
            [
              105.83028796426504,
              21.02004379150412
            ],
            [
              105.83028454088215,
              21.020045227180475
            ],
            [
              105.83024105707095,
              21.020038958787893
            ],
            [
              105.83013995014316,
              21.019959282912865
            ],
            [
              105.83013703131017,
              21.019956982192205
            ],
            [
              105.83011546286524,
              21.019967890020567
            ],
            [
              105.83009525275355,
              21.019978110185995
            ],
            [
              105.82995213803795,
              21.02004446304669
            ],
            [
              105.82994747101462,
              21.020046627364277
            ],
            [
              105.82993092747313,
              21.020056945233613
            ],
            [
              105.82987925156306,
              21.020101085959162
            ],
            [
              105.82987546582541,
              21.020104316296763
            ],
            [
              105.82987239435619,
              21.020106939795298
            ],
            [
              105.82987020469032,
              21.02010881021822
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 278, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DOPP.326",
          "duongKinh": 100,
          "chieudaiQL": 75,
          "chatLieu": "G-CI",
          "from_latitude": 21.019941814539276,
          "to_latitude": 21.02010881021822,
          "to_longitude": 105.82987020469032,
          "from_longitude": 105.83053110964842
        }
      },
      {
        "id": 325,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83052197725499,
            21.019921930376064,
            105.83071081359223,
            21.02033309644835
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83071081359223,
              21.02033309644835
            ],
            [
              105.83062482030715,
              21.0201458569936
            ],
            [
              105.83061772489059,
              21.020130408562213
            ],
            [
              105.83053110964842,
              21.019941814539276
            ],
            [
              105.83052197725499,
              21.019921930376064
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D160_Tôn Đức Thắng",
          "maTaiSan": "04.O13.DOPP.327",
          "duongKinh": 160,
          "chieudaiQL": 54,
          "chatLieu": "HDPE",
          "from_latitude": 21.02033309644835,
          "to_latitude": 21.019921930376064,
          "to_longitude": 105.83052197725499,
          "from_longitude": 105.83071081359223
        }
      },
      {
        "id": 326,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83071081359223,
            21.02033309644835,
            105.83096161405426,
            21.020906515614328
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83096161405426,
              21.020906515614328
            ],
            [
              105.830949895024,
              21.020884414957603
            ],
            [
              105.83086188664689,
              21.02066174115259
            ],
            [
              105.830817070167,
              21.020564292565968
            ],
            [
              105.83081414712564,
              21.020557937302513
            ],
            [
              105.83080142770807,
              21.02053028217137
            ],
            [
              105.83076315066151,
              21.020447052706263
            ],
            [
              105.83072228586768,
              21.020358075101118
            ],
            [
              105.83071081359223,
              21.02033309644835
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D160_Tôn Đức Thắng",
          "maTaiSan": "04.O13.DOPP.328",
          "duongKinh": 160,
          "chieudaiQL": 77,
          "chatLieu": "HDPE",
          "from_latitude": 21.020906515614328,
          "to_latitude": 21.02033309644835,
          "to_longitude": 105.83071081359223,
          "from_longitude": 105.83096161405426
        }
      },
      {
        "id": 327,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82919030609968,
            21.023034848833493,
            105.82970461136112,
            21.023269306010583
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82970461136112,
              21.023034848833493
            ],
            [
              105.8294044988619,
              21.02313778268015
            ],
            [
              105.82939626545492,
              21.023140606569203
            ],
            [
              105.8292937641832,
              21.02318830529805
            ],
            [
              105.8292899634721,
              21.023193366533512
            ],
            [
              105.82926249732792,
              21.023229979893074
            ],
            [
              105.82922217785715,
              21.023251944191465
            ],
            [
              105.82920086223284,
              21.023261350426132
            ],
            [
              105.82919030609968,
              21.023269306010583
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngách 130, ngõ Thịnh Hào 1, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DOPP.329",
          "duongKinh": 100,
          "chieudaiQL": 60.33,
          "chatLieu": "G-CI",
          "from_latitude": 21.023034848833493,
          "to_latitude": 21.023269306010583,
          "to_longitude": 105.82919030609968,
          "from_longitude": 105.82970461136112
        }
      },
      {
        "id": 328,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83096161405426,
            21.020906515614328,
            105.83156828875003,
            21.022225519129684
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83156828875003,
              21.022225519129684
            ],
            [
              105.83155700789045,
              21.02219677406895
            ],
            [
              105.83152049852032,
              21.022098010647152
            ],
            [
              105.83151617986822,
              21.022086328681148
            ],
            [
              105.83149351720314,
              21.022025024726375
            ],
            [
              105.83145180366792,
              21.021935637809523
            ],
            [
              105.83139893362048,
              21.02182238893294
            ],
            [
              105.83129569359697,
              21.02160120702192
            ],
            [
              105.83127913448892,
              21.02156812516019
            ],
            [
              105.83126960350053,
              21.02154908479978
            ],
            [
              105.83121269872971,
              21.021435401249057
            ],
            [
              105.8310433886837,
              21.02107876748942
            ],
            [
              105.8310088979982,
              21.02100861544724
            ],
            [
              105.83096409404759,
              21.020917457807403
            ],
            [
              105.83096161405426,
              21.020906515614328
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D160_Tôn Đức Thắng",
          "maTaiSan": "04.O13.DOPP.330",
          "duongKinh": 160,
          "chieudaiQL": 159.03,
          "chatLieu": "HDPE",
          "from_latitude": 21.022225519129684,
          "to_latitude": 21.020906515614328,
          "to_longitude": 105.83096161405426,
          "from_longitude": 105.83156828875003
        }
      },
      {
        "id": 329,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83156828875003,
            21.022225519129684,
            105.83178732656386,
            21.022662148802837
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83178732656386,
              21.022662148802837
            ],
            [
              105.83166202022853,
              21.022422726077725
            ],
            [
              105.83165335991752,
              21.02240476083939
            ],
            [
              105.83158532411012,
              21.02226361874965
            ],
            [
              105.83156828875003,
              21.022225519129684
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D160_Tôn Đức Thắng",
          "maTaiSan": "04.O13.DOPP.331",
          "duongKinh": 160,
          "chieudaiQL": 53.36,
          "chatLieu": "HDPE",
          "from_latitude": 21.022662148802837,
          "to_latitude": 21.022225519129684,
          "to_longitude": 105.83156828875003,
          "from_longitude": 105.83178732656386
        }
      },
      {
        "id": 330,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8319947047123,
            21.02527856061757,
            105.83299312873822,
            21.025643280744838
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83299312873822,
              21.02527856061757
            ],
            [
              105.8329475914535,
              21.02529152724197
            ],
            [
              105.83284997276436,
              21.02531770295421
            ],
            [
              105.8327121525113,
              21.025355385629997
            ],
            [
              105.8319947047123,
              21.025643280744838
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Phan Văn Trị",
          "maTaiSan": "04.O13.DOPP.332",
          "duongKinh": 100,
          "chieudaiQL": 111.14,
          "chatLieu": "G",
          "from_latitude": 21.02527856061757,
          "to_latitude": 21.025643280744838,
          "to_longitude": 105.8319947047123,
          "from_longitude": 105.83299312873822
        }
      },
      {
        "id": 331,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83032998076794,
            21.022662148802837,
            105.83178732656386,
            21.02333229160765
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83178732656386,
              21.022662148802837
            ],
            [
              105.83178448888366,
              21.02266321945293
            ],
            [
              105.83169212308765,
              21.022698059600845
            ],
            [
              105.83153249098419,
              21.02278101727624
            ],
            [
              105.8315081920569,
              21.02279364482655
            ],
            [
              105.83148960815282,
              21.022803302632393
            ],
            [
              105.83143212599308,
              21.02283317507653
            ],
            [
              105.83129294241618,
              21.02289529815196
            ],
            [
              105.83127271385469,
              21.022904327200006
            ],
            [
              105.8311939088002,
              21.02293950093344
            ],
            [
              105.83117934960104,
              21.022945999429275
            ],
            [
              105.83116283114562,
              21.02295428865708
            ],
            [
              105.83112452470114,
              21.022973510402778
            ],
            [
              105.8309326041534,
              21.02307612592498
            ],
            [
              105.83092849476091,
              21.023110880400417
            ],
            [
              105.83092774125754,
              21.023117254523587
            ],
            [
              105.8309241543815,
              21.023143705813528
            ],
            [
              105.83091779766791,
              21.023190589829976
            ],
            [
              105.8309168637683,
              21.023190971037106
            ],
            [
              105.83091067294752,
              21.02319349508471
            ],
            [
              105.83080138045189,
              21.023238054860023
            ],
            [
              105.83074293771548,
              21.023190975240723
            ],
            [
              105.83068534848198,
              21.023174662482667
            ],
            [
              105.83051954187488,
              21.02323671625891
            ],
            [
              105.83051631533068,
              21.023237924270884
            ],
            [
              105.83049370851863,
              21.023249883160165
            ],
            [
              105.83040871210686,
              21.023294848821344
            ],
            [
              105.83037994903344,
              21.02330810859064
            ],
            [
              105.83035946003871,
              21.02331755338433
            ],
            [
              105.83033001626549,
              21.02333227427309
            ],
            [
              105.83032998076794,
              21.02333229160765
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ Thịnh Hào 2, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DOPP.333",
          "duongKinh": 100,
          "chieudaiQL": 178.68,
          "chatLieu": "G-CI",
          "from_latitude": 21.022662148802837,
          "to_latitude": 21.02333229160765,
          "to_longitude": 105.83032998076794,
          "from_longitude": 105.83178732656386
        }
      },
      {
        "id": 332,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83027252758066,
            21.022812137050654,
            105.83112452470114,
            21.023059642019252
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83112452470114,
              21.022973510402778
            ],
            [
              105.83104882735068,
              21.02286299504178
            ],
            [
              105.83100509332905,
              21.02286224318175
            ],
            [
              105.83100140377101,
              21.022862179918413
            ],
            [
              105.83095133448745,
              21.022861319901335
            ],
            [
              105.83091556473201,
              21.022812137050654
            ],
            [
              105.83082207182592,
              21.02286792620923
            ],
            [
              105.83075175020444,
              21.02291295854333
            ],
            [
              105.83064565275168,
              21.022966052532524
            ],
            [
              105.83059909032217,
              21.022978993717427
            ],
            [
              105.83054009068269,
              21.022990599238724
            ],
            [
              105.83052932434056,
              21.02299271748724
            ],
            [
              105.8304888754251,
              21.02299647551179
            ],
            [
              105.83042734911683,
              21.023013552420945
            ],
            [
              105.83035188935975,
              21.023034616424805
            ],
            [
              105.83027252758066,
              21.023059642019252
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngách 23, ngõ Thịnh Hào 2, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DOPP.334",
          "duongKinh": 100,
          "chieudaiQL": 104.39,
          "chatLieu": "G-CI",
          "from_latitude": 21.022973510402778,
          "to_latitude": 21.023059642019252,
          "to_longitude": 105.83027252758066,
          "from_longitude": 105.83112452470114
        }
      },
      {
        "id": 333,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8267113077854,
            21.024607160979542,
            105.82702379754981,
            21.024687427952465
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82702379754981,
              21.024607160979542
            ],
            [
              105.82702151138449,
              21.02460794887024
            ],
            [
              105.82686165670017,
              21.024655590872143
            ],
            [
              105.82685140417954,
              21.02465776239401
            ],
            [
              105.82683067909032,
              21.024662150763746
            ],
            [
              105.8267113077854,
              21.024687427952465
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 101, Hào Nam",
          "maTaiSan": "04.O13.DOPP.335",
          "duongKinh": 100,
          "chieudaiQL": 45.09,
          "chatLieu": "G-CI",
          "from_latitude": 21.024607160979542,
          "to_latitude": 21.024687427952465,
          "to_longitude": 105.8267113077854,
          "from_longitude": 105.82702379754981
        }
      },
      {
        "id": 334,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82661850099674,
            21.0243586860587,
            105.82690292435868,
            21.025014262794432
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82661850099674,
              21.0243586860587
            ],
            [
              105.82661964996944,
              21.02436293737382
            ],
            [
              105.82662051611874,
              21.02436614232254
            ],
            [
              105.82663046892608,
              21.024402959980318
            ],
            [
              105.82663623044607,
              21.024424270667733
            ],
            [
              105.82664461693481,
              21.024455294523047
            ],
            [
              105.82664476621177,
              21.024455848377634
            ],
            [
              105.8266616825763,
              21.024518426485734
            ],
            [
              105.82666277163644,
              21.024522454091816
            ],
            [
              105.82668753245127,
              21.024614051718157
            ],
            [
              105.82668995315143,
              21.024623007169218
            ],
            [
              105.8267113077854,
              21.024687427952465
            ],
            [
              105.82671833537633,
              21.024708923965054
            ],
            [
              105.82673136604873,
              21.024748777746396
            ],
            [
              105.82673141498093,
              21.02475329002052
            ],
            [
              105.8267315731226,
              21.024767837496476
            ],
            [
              105.82674331342527,
              21.024829086819597
            ],
            [
              105.82675473718281,
              21.024894686708215
            ],
            [
              105.82676312495516,
              21.02494285466119
            ],
            [
              105.82676678874292,
              21.02496091309107
            ],
            [
              105.82677672111147,
              21.02500273199155
            ],
            [
              105.82677815802623,
              21.025008781129287
            ],
            [
              105.82677946001787,
              21.025014262794432
            ],
            [
              105.82689539054124,
              21.024991260343764
            ],
            [
              105.82690292435868,
              21.024989765004715
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 101, Hào Nam",
          "maTaiSan": "04.O13.DOPP.336",
          "duongKinh": 100,
          "chieudaiQL": 72.91,
          "chatLieu": "G-CI",
          "from_latitude": 21.0243586860587,
          "to_latitude": 21.024989765004715,
          "to_longitude": 105.82690292435868,
          "from_longitude": 105.82661850099674
        }
      },
      {
        "id": 335,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82006467303565,
            21.023405932609094,
            105.82237156113322,
            21.025543222133358
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82007495381258,
              21.023407419590495
            ],
            [
              105.82007220016241,
              21.023405932609094
            ],
            [
              105.82006467303565,
              21.023412168647738
            ],
            [
              105.82007893497132,
              21.023543983556248
            ],
            [
              105.82008161792652,
              21.023568775921465
            ],
            [
              105.8201859824066,
              21.023688725102154
            ],
            [
              105.82025049609152,
              21.023762872328714
            ],
            [
              105.8204347300612,
              21.023905817941888
            ],
            [
              105.820450600088,
              21.023918130937517
            ],
            [
              105.82045751055968,
              21.0239234925705
            ],
            [
              105.82046502580823,
              21.02392932367291
            ],
            [
              105.82053027584759,
              21.023979950837276
            ],
            [
              105.82053385619733,
              21.023982729048498
            ],
            [
              105.8205604267114,
              21.02400334434599
            ],
            [
              105.82062441670891,
              21.02406875433008
            ],
            [
              105.82068986866288,
              21.024123032197895
            ],
            [
              105.82070788199621,
              21.024137970439075
            ],
            [
              105.82071680652365,
              21.024145371761698
            ],
            [
              105.82072010284789,
              21.02414810527482
            ],
            [
              105.82090760892915,
              21.024303600233452
            ],
            [
              105.82091322747563,
              21.02430825905018
            ],
            [
              105.82113428201646,
              21.024491575553512
            ],
            [
              105.821146485526,
              21.024501695085494
            ],
            [
              105.82114968716232,
              21.02450435046625
            ],
            [
              105.82141241871851,
              21.024722226904153
            ],
            [
              105.82142529198009,
              21.024732903180027
            ],
            [
              105.82142664298782,
              21.024734022977395
            ],
            [
              105.8220116232444,
              21.02523498425757
            ],
            [
              105.82202977291267,
              21.025250526851607
            ],
            [
              105.82237156113322,
              21.025543222133358
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D160_Giảng Võ",
          "maTaiSan": "04.O13.DOPP.337",
          "duongKinh": 160,
          "chieudaiQL": 344.42,
          "chatLieu": "HDPE",
          "from_latitude": 21.023407419590495,
          "to_latitude": 21.025543222133358,
          "to_longitude": 105.82237156113322,
          "from_longitude": 105.82007495381258
        }
      },
      {
        "id": 336,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82482920976338,
            21.027581760256968,
            105.82707127232723,
            21.02926692372766
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82482920976338,
              21.027581760256968
            ],
            [
              105.8248354279992,
              21.027585389963058
            ],
            [
              105.82484464607325,
              21.027590817890538
            ],
            [
              105.82488166001357,
              21.027624807348772
            ],
            [
              105.8249064812484,
              21.0276475836973
            ],
            [
              105.82512060202143,
              21.027844231907295
            ],
            [
              105.8251453450396,
              21.027866941759218
            ],
            [
              105.82537098169648,
              21.028051516343798
            ],
            [
              105.82539397646914,
              21.0280671777467
            ],
            [
              105.825396896568,
              21.028069342151145
            ],
            [
              105.82540258042583,
              21.028073581396267
            ],
            [
              105.8256562037996,
              21.028262345274108
            ],
            [
              105.82588959835361,
              21.028428045609957
            ],
            [
              105.82590764907302,
              21.028440851471736
            ],
            [
              105.82595268425388,
              21.028472822755965
            ],
            [
              105.82616427883625,
              21.028634986777973
            ],
            [
              105.82617071689202,
              21.028639911492032
            ],
            [
              105.82641688285324,
              21.028802363339103
            ],
            [
              105.82654879228593,
              21.02889600710466
            ],
            [
              105.82679586745898,
              21.029071408001233
            ],
            [
              105.82707127232723,
              21.02926692372766
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D160_Giảng Võ",
          "maTaiSan": "04.O13.DOPP.338",
          "duongKinh": 160,
          "chieudaiQL": 360,
          "chatLieu": "HDPE",
          "from_latitude": 21.027581760256968,
          "to_latitude": 21.02926692372766,
          "to_longitude": 105.82707127232723,
          "from_longitude": 105.82482920976338
        }
      },
      {
        "id": 337,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82488166001357,
            21.02696377092947,
            105.82561282194797,
            21.027624807348772
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82488166001357,
              21.027624807348772
            ],
            [
              105.82490293733562,
              21.02760145963996
            ],
            [
              105.82507297118987,
              21.02741209645411
            ],
            [
              105.82511721154098,
              21.027362499779397
            ],
            [
              105.82512076445813,
              21.02735851647918
            ],
            [
              105.82520076530497,
              21.02726882913935
            ],
            [
              105.82521790344651,
              21.02725238044022
            ],
            [
              105.82524252212202,
              21.027228753637747
            ],
            [
              105.82528506239127,
              21.027192943868585
            ],
            [
              105.8252888323644,
              21.02718977060764
            ],
            [
              105.8253122489179,
              21.027170063303345
            ],
            [
              105.82531935604281,
              21.02716408197728
            ],
            [
              105.82532712101352,
              21.027157545587716
            ],
            [
              105.82545169769048,
              21.027065813659995
            ],
            [
              105.82551268627265,
              21.027020904616943
            ],
            [
              105.8255857446519,
              21.026979220179093
            ],
            [
              105.8255943670012,
              21.026974300904147
            ],
            [
              105.82561282194797,
              21.02696377092947
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D200_Vũ Thạch",
          "maTaiSan": "04.O13.DOPP.339",
          "duongKinh": 200,
          "chieudaiQL": 116,
          "chatLieu": "G-CI",
          "from_latitude": 21.027624807348772,
          "to_latitude": 21.02696377092947,
          "to_longitude": 105.82561282194797,
          "from_longitude": 105.82488166001357
        }
      },
      {
        "id": 338,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82561282194797,
            21.026553535865553,
            105.8264959161313,
            21.02696377092947
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82561282194797,
              21.02696377092947
            ],
            [
              105.82566451669743,
              21.02693427623058
            ],
            [
              105.82589376633248,
              21.02678333425
            ],
            [
              105.82592068220903,
              21.026774743020347
            ],
            [
              105.82604202545599,
              21.02673601316424
            ],
            [
              105.82614132987437,
              21.026706168732773
            ],
            [
              105.82617748227844,
              21.02669065092116
            ],
            [
              105.82624804741025,
              21.026657284375496
            ],
            [
              105.8264070869409,
              21.02659258223013
            ],
            [
              105.82641058978292,
              21.026591042370622
            ],
            [
              105.8264959161313,
              21.026553535865553
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D200_Vũ Thạch",
          "maTaiSan": "04.O13.DOPP.340",
          "duongKinh": 200,
          "chieudaiQL": 243,
          "chatLieu": "G-CI",
          "from_latitude": 21.02696377092947,
          "to_latitude": 21.026553535865553,
          "to_longitude": 105.8264959161313,
          "from_longitude": 105.82561282194797
        }
      },
      {
        "id": 339,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83494877410632,
            21.030059122069968,
            105.83532802855163,
            21.030585844352448
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83532802855163,
              21.030585844352448
            ],
            [
              105.8352592237515,
              21.030390906470103
            ],
            [
              105.83525253014481,
              21.030374889079845
            ],
            [
              105.83523523209021,
              21.030333498055075
            ],
            [
              105.83523176778826,
              21.03032365278108
            ],
            [
              105.83521258220941,
              21.030269135453842
            ],
            [
              105.8352020589015,
              21.030249170820767
            ],
            [
              105.83517336540957,
              21.03021585457581
            ],
            [
              105.8351552718857,
              21.030202103361603
            ],
            [
              105.83511712845669,
              21.030173114798526
            ],
            [
              105.83510384272672,
              21.03016411891778
            ],
            [
              105.83509459447833,
              21.030157857133382
            ],
            [
              105.83506872812728,
              21.030144170277854
            ],
            [
              105.83505846159997,
              21.03013661827059
            ],
            [
              105.83500327469604,
              21.030096024773737
            ],
            [
              105.83494877410632,
              21.030059122069968
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D90_Ngõ 139A, Nguyễn Thái Học",
          "maTaiSan": "04.O13.DOPP.341",
          "duongKinh": 90,
          "chieudaiQL": 73.39,
          "chatLieu": "PVC",
          "from_latitude": 21.030585844352448,
          "to_latitude": 21.030059122069968,
          "to_longitude": 105.83494877410632,
          "from_longitude": 105.83532802855163
        }
      },
      {
        "id": 340,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82283966251339,
            21.024830203052893,
            105.82339931093782,
            21.02524412193077
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82283966251339,
              21.02493320450789
            ],
            [
              105.82284801300167,
              21.024931163460774
            ],
            [
              105.82299554721745,
              21.024895101841164
            ],
            [
              105.82320517197934,
              21.024830203052893
            ],
            [
              105.82323987085184,
              21.024835914187083
            ],
            [
              105.82326627536054,
              21.02490363757732
            ],
            [
              105.82326679055303,
              21.02490495921204
            ],
            [
              105.82332152090349,
              21.0250453373186
            ],
            [
              105.82332754025059,
              21.025060774108564
            ],
            [
              105.82332845160053,
              21.025063112663567
            ],
            [
              105.82333704710194,
              21.02507117749988
            ],
            [
              105.82333909516711,
              21.025073098680824
            ],
            [
              105.8233882500877,
              21.025216313686272
            ],
            [
              105.82338827746447,
              21.025216393940788
            ],
            [
              105.8233895092822,
              21.025218403976154
            ],
            [
              105.82339013926372,
              21.025219431506336
            ],
            [
              105.82339931093782,
              21.02524412193077
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 189, Giảng Võ",
          "maTaiSan": "04.O13.DOPP.342",
          "duongKinh": 100,
          "chieudaiQL": 90,
          "chatLieu": "G-CI",
          "from_latitude": 21.02493320450789,
          "to_latitude": 21.02524412193077,
          "to_longitude": 105.82339931093782,
          "from_longitude": 105.82283966251339
        }
      },
      {
        "id": 341,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8215715545799,
            21.02316247376848,
            105.82203705925173,
            21.02332978577827
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82203705925173,
              21.02316247376848
            ],
            [
              105.82200243434635,
              21.02317491414988
            ],
            [
              105.8219020669074,
              21.023215903465804
            ],
            [
              105.82177728259565,
              21.023260117798884
            ],
            [
              105.82177631959378,
              21.02326045935514
            ],
            [
              105.82177218165052,
              21.023260731348582
            ],
            [
              105.82177044300157,
              21.02326084542665
            ],
            [
              105.82171742483227,
              21.023277321718467
            ],
            [
              105.82169402538453,
              21.023285738125214
            ],
            [
              105.8215715545799,
              21.02332978577827
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 418, La Thành",
          "maTaiSan": "04.O13.DOPP.343",
          "duongKinh": 100,
          "chieudaiQL": 50,
          "chatLieu": "G-CI",
          "from_latitude": 21.02316247376848,
          "to_latitude": 21.02332978577827,
          "to_longitude": 105.8215715545799,
          "from_longitude": 105.82203705925173
        }
      },
      {
        "id": 342,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8306059598376,
            21.02607179005609,
            105.83104078430114,
            21.026137343293524
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83104078430114,
              21.02607179005609
            ],
            [
              105.83089368791202,
              21.02610404488526
            ],
            [
              105.83089151480185,
              21.02610422892183
            ],
            [
              105.83076324070103,
              21.026114916618013
            ],
            [
              105.83065333448384,
              21.026125493727296
            ],
            [
              105.83061090154054,
              21.02613610703035
            ],
            [
              105.8306059598376,
              21.026137343293524
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngách 20, Ngõ An Trạch 2, An Trạch",
          "maTaiSan": "04.O13.DOPP.344",
          "duongKinh": 100,
          "chieudaiQL": 46,
          "chatLieu": "G-CI",
          "from_latitude": 21.02607179005609,
          "to_latitude": 21.026137343293524,
          "to_longitude": 105.8306059598376,
          "from_longitude": 105.83104078430114
        }
      },
      {
        "id": 343,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83194270649982,
            21.024390431947133,
            105.83263424108817,
            21.024501484927228
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83194270649982,
              21.024498405990098
            ],
            [
              105.83194880926044,
              21.02449630233424
            ],
            [
              105.8319539480295,
              21.024494532167424
            ],
            [
              105.83198843498377,
              21.024482648643417
            ],
            [
              105.83202681084074,
              21.024472247245612
            ],
            [
              105.83227688965353,
              21.024404465034923
            ],
            [
              105.83230699061069,
              21.02439739361162
            ],
            [
              105.8323366256074,
              21.024390431947133
            ],
            [
              105.83235780681024,
              21.024423187575135
            ],
            [
              105.83241981286426,
              21.024474264897936
            ],
            [
              105.83247341526554,
              21.024501484927228
            ],
            [
              105.83256575874616,
              21.024474936944593
            ],
            [
              105.83260998379937,
              21.024483647905146
            ],
            [
              105.83263424108817,
              21.024478388899926
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 162A, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DOPP.345",
          "duongKinh": 100,
          "chieudaiQL": 78,
          "chatLieu": "G-CI",
          "from_latitude": 21.024498405990098,
          "to_latitude": 21.024478388899926,
          "to_longitude": 105.83263424108817,
          "from_longitude": 105.83194270649982
        }
      },
      {
        "id": 344,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8316316944928,
            21.023598355381676,
            105.83219737495982,
            21.023795442024916
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83219737495982,
              21.023598355381676
            ],
            [
              105.8321938550705,
              21.02359991982773
            ],
            [
              105.83215106126491,
              21.02360169630882
            ],
            [
              105.83209863258932,
              21.023620186210618
            ],
            [
              105.83206860325501,
              21.023630777107755
            ],
            [
              105.83190951754095,
              21.023692195096263
            ],
            [
              105.83189382840224,
              21.023698252084916
            ],
            [
              105.83181899680176,
              21.023725574819913
            ],
            [
              105.83175725894841,
              21.023749801364243
            ],
            [
              105.83172451344417,
              21.02376170341856
            ],
            [
              105.8316316944928,
              21.023795442024916
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 162B, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DOPP.346",
          "duongKinh": 100,
          "chieudaiQL": 62.5,
          "chatLieu": "G-CI",
          "from_latitude": 21.023598355381676,
          "to_latitude": 21.023795442024916,
          "to_longitude": 105.8316316944928,
          "from_longitude": 105.83219737495982
        }
      },
      {
        "id": 345,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83067689151467,
            21.022596153277263,
            105.83082207182592,
            21.02286792620923
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83068225310454,
              21.022596153277263
            ],
            [
              105.83067689151467,
              21.02261985839731
            ],
            [
              105.83072837511192,
              21.02271036204199
            ],
            [
              105.83073247654634,
              21.022717571418386
            ],
            [
              105.83078477137381,
              21.022809501788352
            ],
            [
              105.83080271514044,
              21.022837607387956
            ],
            [
              105.83082207182592,
              21.02286792620923
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngách 56, ngõ Thịnh Hào 3, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DOPP.347",
          "duongKinh": 100,
          "chieudaiQL": 34,
          "chatLieu": "G-CI",
          "from_latitude": 21.022596153277263,
          "to_latitude": 21.02286792620923,
          "to_longitude": 105.83082207182592,
          "from_longitude": 105.83068225310454
        }
      },
      {
        "id": 346,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82965113036812,
            21.02287472020859,
            105.82970461136112,
            21.023034848833493
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82969322232188,
              21.02287472020859
            ],
            [
              105.82968478205231,
              21.022878403183697
            ],
            [
              105.82965113036812,
              21.02289308667555
            ],
            [
              105.82970461136112,
              21.023034848833493
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ Thịnh Hào 1, Tôn Đức Thắng",
          "maTaiSan": "04.O13.DOPP.348",
          "duongKinh": 100,
          "chieudaiQL": 22.4,
          "chatLieu": "G-CI",
          "from_latitude": 21.02287472020859,
          "to_latitude": 21.023034848833493,
          "to_longitude": 105.82970461136112,
          "from_longitude": 105.82969322232188
        }
      },
      {
        "id": 347,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82685297782433,
            21.009873614128907,
            105.82725288333367,
            21.010907526759844
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82685297782433,
              21.009873614128907
            ],
            [
              105.82691258731153,
              21.009973358611223
            ],
            [
              105.82694019452909,
              21.010024086942394
            ],
            [
              105.82695578226058,
              21.010052728373545
            ],
            [
              105.82706621377052,
              21.01027946060436
            ],
            [
              105.82710409812357,
              21.010371201431578
            ],
            [
              105.82714791634105,
              21.010458025142977
            ],
            [
              105.82719148661248,
              21.010544356884502
            ],
            [
              105.82725288333367,
              21.010657096287645
            ],
            [
              105.82724692898555,
              21.010659511559037
            ],
            [
              105.82724483383224,
              21.01066036174519
            ],
            [
              105.82724141939599,
              21.010661642861415
            ],
            [
              105.8272305089444,
              21.01066573693697
            ],
            [
              105.82717393911193,
              21.0106880452423
            ],
            [
              105.82708137466356,
              21.010732503081876
            ],
            [
              105.8271318650563,
              21.010826881461718
            ],
            [
              105.82717500777662,
              21.010907526759844
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngách 167/37, Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DOPP.349",
          "duongKinh": 100,
          "chieudaiQL": 100,
          "chatLieu": "G-DI",
          "from_latitude": 21.009873614128907,
          "to_latitude": 21.010907526759844,
          "to_longitude": 105.82717500777662,
          "from_longitude": 105.82685297782433
        }
      },
      {
        "id": 348,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83089856401067,
            21.00755465269879,
            105.8327160108201,
            21.01052078783428
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8327160108201,
              21.00755465269879
            ],
            [
              105.83263371534927,
              21.007651826964835
            ],
            [
              105.83262022670773,
              21.007667754506482
            ],
            [
              105.83250731299988,
              21.00779330793037
            ],
            [
              105.83246998065478,
              21.007834819453606
            ],
            [
              105.8322580451609,
              21.008052117200574
            ],
            [
              105.83224374432689,
              21.00806677965363
            ],
            [
              105.83219112001316,
              21.008122390288392
            ],
            [
              105.83207675307492,
              21.008243245643367
            ],
            [
              105.83203035668082,
              21.00829673932789
            ],
            [
              105.8319103488731,
              21.008435103187296
            ],
            [
              105.83189315914447,
              21.00845492270229
            ],
            [
              105.83178191014846,
              21.008583756174804
            ],
            [
              105.8317657091178,
              21.008602517683702
            ],
            [
              105.83163842553934,
              21.00880813046986
            ],
            [
              105.83148742243345,
              21.009203496738117
            ],
            [
              105.83148407550233,
              21.009212259930262
            ],
            [
              105.83142619823411,
              21.009349746327292
            ],
            [
              105.83140746087751,
              21.00939425761456
            ],
            [
              105.8312276350613,
              21.009781087190113
            ],
            [
              105.83108935382393,
              21.010137866590192
            ],
            [
              105.83108147589634,
              21.010158193322752
            ],
            [
              105.83092906333583,
              21.01048678510029
            ],
            [
              105.83092873920201,
              21.010487484876577
            ],
            [
              105.83092546819493,
              21.010489820333042
            ],
            [
              105.83091953745277,
              21.010494053809314
            ],
            [
              105.83089856401067,
              21.01052078783428
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DOPP.350",
          "duongKinh": 150,
          "chieudaiQL": 384,
          "chatLieu": "G",
          "from_latitude": 21.00755465269879,
          "to_latitude": 21.01052078783428,
          "to_longitude": 105.83089856401067,
          "from_longitude": 105.8327160108201
        }
      },
      {
        "id": 349,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83275485419145,
            21.007580052821528,
            105.83684717565818,
            21.011067152764642
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83275485419145,
              21.007580052821528
            ],
            [
              105.83289515959277,
              21.00769876089909
            ],
            [
              105.8329106736103,
              21.007711887420005
            ],
            [
              105.83299160108056,
              21.00778035685822
            ],
            [
              105.83308296711151,
              21.007857851670206
            ],
            [
              105.83320425998991,
              21.007960729581466
            ],
            [
              105.83320816537349,
              21.007964042459793
            ],
            [
              105.83324372551222,
              21.00799420318332
            ],
            [
              105.83345951074847,
              21.008171902968744
            ],
            [
              105.83353987496514,
              21.00823879875841
            ],
            [
              105.83354335868799,
              21.008241699113327
            ],
            [
              105.8336313291888,
              21.008314926243635
            ],
            [
              105.83367794726723,
              21.008353029859865
            ],
            [
              105.83380978152384,
              21.008460785473446
            ],
            [
              105.83384486032638,
              21.00848373616601
            ],
            [
              105.83384747849519,
              21.008485449401054
            ],
            [
              105.83387721477828,
              21.008504904588396
            ],
            [
              105.83402800523079,
              21.00862977809452
            ],
            [
              105.83403345856541,
              21.008631229044354
            ],
            [
              105.83403473207458,
              21.008631568738156
            ],
            [
              105.83404559619841,
              21.00864308309992
            ],
            [
              105.83426083333616,
              21.008842621767453
            ],
            [
              105.83428372511503,
              21.008863843723322
            ],
            [
              105.83430136288963,
              21.008880195050043
            ],
            [
              105.83446417994587,
              21.009006155373193
            ],
            [
              105.83454743324577,
              21.009070561650667
            ],
            [
              105.83458043075585,
              21.009098872192823
            ],
            [
              105.83458540537607,
              21.009103141728392
            ],
            [
              105.83463011410785,
              21.009141506430357
            ],
            [
              105.8346942177204,
              21.00919650010682
            ],
            [
              105.83492524705451,
              21.009380489067418
            ],
            [
              105.83492781515459,
              21.009382534014147
            ],
            [
              105.83498410857803,
              21.009427365772243
            ],
            [
              105.8350732558043,
              21.009498844915104
            ],
            [
              105.83522595654748,
              21.00962128004477
            ],
            [
              105.83522773050794,
              21.0096227020463
            ],
            [
              105.83526020291849,
              21.00964873889779
            ],
            [
              105.83533304536876,
              21.009705550970033
            ],
            [
              105.8354660427041,
              21.009809278348296
            ],
            [
              105.83547005393228,
              21.009812407296852
            ],
            [
              105.8355020210472,
              21.009837339226223
            ],
            [
              105.83572312903618,
              21.010024546065765
            ],
            [
              105.83577201541294,
              21.010065937410978
            ],
            [
              105.83578004477066,
              21.01007204803306
            ],
            [
              105.83578685499079,
              21.01007722167583
            ],
            [
              105.83588534902545,
              21.01015219148632
            ],
            [
              105.83593236314886,
              21.010187972113876
            ],
            [
              105.836014470374,
              21.010259663250682
            ],
            [
              105.83601699417642,
              21.010261866461907
            ],
            [
              105.836043013245,
              21.010284584798317
            ],
            [
              105.83608757664774,
              21.01032349535322
            ],
            [
              105.83609026864416,
              21.01032584586575
            ],
            [
              105.83613336378859,
              21.01036347291366
            ],
            [
              105.836171812166,
              21.01039474330677
            ],
            [
              105.83645816820201,
              21.010695386039394
            ],
            [
              105.83673541513268,
              21.010977910997774
            ],
            [
              105.83684717565818,
              21.011067152764642
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D160_Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DOPP.351",
          "duongKinh": 160,
          "chieudaiQL": 574.91,
          "chatLieu": "PVC",
          "from_latitude": 21.007580052821528,
          "to_latitude": 21.011067152764642,
          "to_longitude": 105.83684717565818,
          "from_longitude": 105.83275485419145
        }
      },
      {
        "id": 350,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8308340840614,
            21.007580052821528,
            105.83275485419145,
            21.01087781180884
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83275485419145,
              21.007580052821528
            ],
            [
              105.83268915215136,
              21.007668546303425
            ],
            [
              105.8326833101126,
              21.0076714019847
            ],
            [
              105.83267642445782,
              21.007675129868794
            ],
            [
              105.83239313812133,
              21.007953550417355
            ],
            [
              105.8319099989213,
              21.008467231735942
            ],
            [
              105.83178487222473,
              21.008620790212174
            ],
            [
              105.83169172537941,
              21.008833012178258
            ],
            [
              105.83147509760272,
              21.009326554632032
            ],
            [
              105.83136710291046,
              21.00957260974917
            ],
            [
              105.83134127778729,
              21.009631448301242
            ],
            [
              105.83124546877302,
              21.00986201220011
            ],
            [
              105.83112497238,
              21.010151983462386
            ],
            [
              105.83108843770535,
              21.010239904863937
            ],
            [
              105.83107357584383,
              21.01029035486472
            ],
            [
              105.8310735559396,
              21.010290408252253
            ],
            [
              105.83105660851916,
              21.01034795243676
            ],
            [
              105.83101559086332,
              21.010429545137384
            ],
            [
              105.83098608638242,
              21.0104882462517
            ],
            [
              105.83098119893498,
              21.010497969860513
            ],
            [
              105.83097762594291,
              21.01050656705023
            ],
            [
              105.83097543412475,
              21.01051184265659
            ],
            [
              105.83095731099296,
              21.010555455800045
            ],
            [
              105.83094821904837,
              21.01057733568071
            ],
            [
              105.8308340840614,
              21.01087781180884
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D90_Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DOPP.352",
          "duongKinh": 90,
          "chieudaiQL": 421.95,
          "chatLieu": "PVC",
          "from_latitude": 21.007580052821528,
          "to_latitude": 21.01087781180884,
          "to_longitude": 105.8308340840614,
          "from_longitude": 105.83275485419145
        }
      },
      {
        "id": 351,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.831795213926,
            21.009608220539402,
            105.83372325359764,
            21.011768953345182
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.833172823981,
              21.009608220539402
            ],
            [
              105.83317781457441,
              21.009611386296815
            ],
            [
              105.83321358502025,
              21.00967311099958
            ],
            [
              105.83325369081739,
              21.009745755234338
            ],
            [
              105.83327969589438,
              21.00977054698007
            ],
            [
              105.83369466438764,
              21.01012098909608
            ],
            [
              105.83372325359764,
              21.010218543680036
            ],
            [
              105.83352436610159,
              21.01042340848571
            ],
            [
              105.83335040556767,
              21.010602596040208
            ],
            [
              105.83322503054418,
              21.01073173896969
            ],
            [
              105.83297615343906,
              21.010546800001325
            ],
            [
              105.8329631881699,
              21.010560010816963
            ],
            [
              105.83291647970869,
              21.01060760459327
            ],
            [
              105.83283481515225,
              21.010690816529884
            ],
            [
              105.83282914122199,
              21.010696597845943
            ],
            [
              105.83236473549518,
              21.011169801087505
            ],
            [
              105.83202794896968,
              21.011524110792624
            ],
            [
              105.831795213926,
              21.011768953345182
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D160_Ngõ 4A, Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DOPP.353",
          "duongKinh": 160,
          "chieudaiQL": 523.42,
          "chatLieu": "PVC",
          "from_latitude": 21.009608220539402,
          "to_latitude": 21.011768953345182,
          "to_longitude": 105.831795213926,
          "from_longitude": 105.833172823981
        }
      },
      {
        "id": 352,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82694662353896,
            21.011532781122792,
            105.83060288077544,
            21.013424552752692
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82694662353896,
              21.013424552752692
            ],
            [
              105.82695033207004,
              21.013422690340395
            ],
            [
              105.82695038483,
              21.013422663890395
            ],
            [
              105.82696445918641,
              21.013415590965547
            ],
            [
              105.82705000445758,
              21.013377981212784
            ],
            [
              105.82711704559723,
              21.0133404496308
            ],
            [
              105.82711960697712,
              21.013339013694875
            ],
            [
              105.82711965973212,
              21.013338986341626
            ],
            [
              105.82755887472244,
              21.013093100626193
            ],
            [
              105.82772133273431,
              21.012996523261297
            ],
            [
              105.82772138646091,
              21.01299649770959
            ],
            [
              105.8277314804567,
              21.0129904972481
            ],
            [
              105.82794339608543,
              21.012873656353463
            ],
            [
              105.82796882111961,
              21.012859633790505
            ],
            [
              105.82796887484609,
              21.012859608238752
            ],
            [
              105.82807431033822,
              21.01280147564138
            ],
            [
              105.8282088101947,
              21.01272488408721
            ],
            [
              105.82820886392108,
              21.01272485853536
            ],
            [
              105.8284132368519,
              21.01259416578508
            ],
            [
              105.82841329057818,
              21.012594140233173
            ],
            [
              105.82841564280783,
              21.012592633941782
            ],
            [
              105.82841569362353,
              21.012592603887924
            ],
            [
              105.82843605509505,
              21.01258259997564
            ],
            [
              105.82843610882139,
              21.012582574423732
            ],
            [
              105.82847570841292,
              21.012563121223177
            ],
            [
              105.82859382383901,
              21.01251079844467
            ],
            [
              105.82859387756532,
              21.012510772892693
            ],
            [
              105.82860847245637,
              21.012502535752244
            ],
            [
              105.82860852521077,
              21.01250250839856
            ],
            [
              105.82869477224051,
              21.012453846164163
            ],
            [
              105.8286975138454,
              21.012452130230617
            ],
            [
              105.82869756563287,
              21.01245210197838
            ],
            [
              105.82870390203705,
              21.012448142987935
            ],
            [
              105.82873447970184,
              21.01243321096013
            ],
            [
              105.82873453342299,
              21.012433184504914
            ],
            [
              105.82873633312128,
              21.01243230502949
            ],
            [
              105.828743877287,
              21.012428621058298
            ],
            [
              105.82874468600083,
              21.012428226022497
            ],
            [
              105.82874473972207,
              21.012428199567296
            ],
            [
              105.8287602511186,
              21.01242062543415
            ],
            [
              105.82881330850925,
              21.012395547251394
            ],
            [
              105.8288399389012,
              21.012382961142436
            ],
            [
              105.82883999262741,
              21.01238293559039
            ],
            [
              105.82884905810657,
              21.01237865078459
            ],
            [
              105.82884911183274,
              21.012378625232543
            ],
            [
              105.82915946289756,
              21.01223193674895
            ],
            [
              105.82915951662368,
              21.012231911196807
            ],
            [
              105.82916690983306,
              21.012228416717186
            ],
            [
              105.82924720359881,
              21.012204707738906
            ],
            [
              105.8292522569713,
              21.01220339330429
            ],
            [
              105.82925231458488,
              21.012203374958982
            ],
            [
              105.82925506650975,
              21.012202657934207
            ],
            [
              105.82933974793467,
              21.01218058585175
            ],
            [
              105.82933981323335,
              21.01218056566251
            ],
            [
              105.82938171832821,
              21.01216964192259
            ],
            [
              105.82949242049281,
              21.0121119073153
            ],
            [
              105.82949244543201,
              21.01211189454856
            ],
            [
              105.82979755048115,
              21.011952776826824
            ],
            [
              105.82979760420695,
              21.011952751274503
            ],
            [
              105.83003179512588,
              21.011830612896592
            ],
            [
              105.83003184885165,
              21.011830587344203
            ],
            [
              105.8301199827958,
              21.011784623976883
            ],
            [
              105.83027557615416,
              21.011703475926947
            ],
            [
              105.83027562987981,
              21.011703450374473
            ],
            [
              105.83028460362998,
              21.011698770328877
            ],
            [
              105.83045082794979,
              21.011612078635764
            ],
            [
              105.83045088167539,
              21.011612053083226
            ],
            [
              105.83060282801678,
              21.01153280757389
            ],
            [
              105.83060288077544,
              21.011532781122792
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Hồ Đắc Di",
          "maTaiSan": "04.O16B.DOPP.354",
          "duongKinh": 100,
          "chieudaiQL": 436.97,
          "chatLieu": "G-DI",
          "from_latitude": 21.013424552752692,
          "to_latitude": 21.011532781122792,
          "to_longitude": 105.83060288077544,
          "from_longitude": 105.82694662353896
        }
      },
      {
        "id": 353,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8308340840614,
            21.01087781180884,
            105.83179283201846,
            21.0117716909235
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8308340840614,
              21.01087781180884
            ],
            [
              105.8308394186494,
              21.010898802920913
            ],
            [
              105.83086717294204,
              21.011007953196803
            ],
            [
              105.83097021116585,
              21.011093334158936
            ],
            [
              105.83098539145477,
              21.011105913313674
            ],
            [
              105.83107531748145,
              21.01118042830819
            ],
            [
              105.8312532091353,
              21.01132402307816
            ],
            [
              105.83129260720422,
              21.011355823759462
            ],
            [
              105.8313115269848,
              21.01137109609726
            ],
            [
              105.83156938073212,
              21.011579234529258
            ],
            [
              105.83162302580743,
              21.01162543878734
            ],
            [
              105.8316384592931,
              21.011638731093825
            ],
            [
              105.83179283201846,
              21.0117716909235
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D110_Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DOPP.355",
          "duongKinh": 110,
          "chieudaiQL": 142.93,
          "chatLieu": "PVC",
          "from_latitude": 21.01087781180884,
          "to_latitude": 21.0117716909235,
          "to_longitude": 105.83179283201846,
          "from_longitude": 105.8308340840614
        }
      },
      {
        "id": 354,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83171788037812,
            21.008823842155273,
            105.83244825698203,
            21.01063232391495
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83171788037812,
              21.008823842155273
            ],
            [
              105.83172142330642,
              21.00882527993602
            ],
            [
              105.83210154424634,
              21.00897945662506
            ],
            [
              105.83223138358939,
              21.008987424917997
            ],
            [
              105.8323315681788,
              21.00900449002805
            ],
            [
              105.8324109382384,
              21.009035232376895
            ],
            [
              105.83241928934963,
              21.0090374757842
            ],
            [
              105.83242568689529,
              21.00903920668286
            ],
            [
              105.83244825698203,
              21.009053977821495
            ],
            [
              105.83242995076168,
              21.00913054077517
            ],
            [
              105.83236960586527,
              21.009266411024093
            ],
            [
              105.8321012637962,
              21.009809656952168
            ],
            [
              105.8319782754198,
              21.01011949656641
            ],
            [
              105.8318806431711,
              21.010412484400078
            ],
            [
              105.8317871865826,
              21.01063232391495
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Ngõ 4A, Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DOPP.356",
          "duongKinh": 150,
          "chieudaiQL": 267.37,
          "chatLieu": "G",
          "from_latitude": 21.008823842155273,
          "to_latitude": 21.01063232391495,
          "to_longitude": 105.8317871865826,
          "from_longitude": 105.83171788037812
        }
      },
      {
        "id": 355,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82895622575815,
            21.01688022802033,
            105.82903451323293,
            21.01694715452087
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82895622575815,
              21.01689729629991
            ],
            [
              105.82895624973129,
              21.01689728263471
            ],
            [
              105.82898381672713,
              21.016881712182965
            ],
            [
              105.82898579217155,
              21.01688059700888
            ],
            [
              105.82898644521713,
              21.01688022802033
            ],
            [
              105.82902569699013,
              21.01694715452087
            ],
            [
              105.82903451323293,
              21.0169422829146
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DOPP.357",
          "duongKinh": 150,
          "chieudaiQL": 14.42,
          "chatLieu": "G-DI",
          "from_latitude": 21.01689729629991,
          "to_latitude": 21.0169422829146,
          "to_longitude": 105.82903451323293,
          "from_longitude": 105.82895622575815
        }
      },
      {
        "id": 356,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82772133273431,
            21.012996523261297,
            105.82791944035979,
            21.013322106299796
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82772133273431,
              21.012996523261297
            ],
            [
              105.82772170928361,
              21.012997125687672
            ],
            [
              105.82773472434013,
              21.013017919667348
            ],
            [
              105.8277373316231,
              21.013022015758462
            ],
            [
              105.8277416574784,
              21.013028832134715
            ],
            [
              105.82776223660511,
              21.013062787251997
            ],
            [
              105.82791944035979,
              21.013322106299796
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Hồ Đắc Di",
          "maTaiSan": "04.O16B.DOPP.358",
          "duongKinh": 100,
          "chieudaiQL": 42.13,
          "chatLieu": "G-DI",
          "from_latitude": 21.012996523261297,
          "to_latitude": 21.013322106299796,
          "to_longitude": 105.82791944035979,
          "from_longitude": 105.82772133273431
        }
      },
      {
        "id": 357,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83100317815286,
            21.01740867827338,
            105.83133643534573,
            21.0176982678081
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83100317815286,
              21.01740867827338
            ],
            [
              105.8311522489404,
              21.01751864384829
            ],
            [
              105.8311722439945,
              21.017533393367657
            ],
            [
              105.83132633351164,
              21.017688131506976
            ],
            [
              105.83133643534573,
              21.0176982678081
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Xã Đàn",
          "maTaiSan": "04.O16B.DOPP.359",
          "duongKinh": 100,
          "chieudaiQL": 45,
          "chatLieu": "G-DI",
          "from_latitude": 21.01740867827338,
          "to_latitude": 21.0176982678081,
          "to_longitude": 105.83133643534573,
          "from_longitude": 105.83100317815286
        }
      },
      {
        "id": 358,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83018779635822,
            21.0176982678081,
            105.83133643534573,
            21.01871960889997
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83018779635822,
              21.01871960889997
            ],
            [
              105.83037316512825,
              21.018580549551448
            ],
            [
              105.83038776565655,
              21.01856959622499
            ],
            [
              105.83046721737502,
              21.018509993837025
            ],
            [
              105.83048442262822,
              21.018497086755936
            ],
            [
              105.83055728148207,
              21.01844243005791
            ],
            [
              105.83070347693342,
              21.018333603786655
            ],
            [
              105.8307664456024,
              21.018286730517413
            ],
            [
              105.8307681585108,
              21.018285454927515
            ],
            [
              105.83088535982967,
              21.01816767552934
            ],
            [
              105.83109772291444,
              21.01794779035672
            ],
            [
              105.83133643534573,
              21.0176982678081
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Đê La Thành",
          "maTaiSan": "04.O16B.DOPP.360",
          "duongKinh": 100,
          "chieudaiQL": 165,
          "chatLieu": "G-DI",
          "from_latitude": 21.01871960889997,
          "to_latitude": 21.0176982678081,
          "to_longitude": 105.83133643534573,
          "from_longitude": 105.83018779635822
        }
      },
      {
        "id": 359,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83668874678244,
            21.011775203723094,
            105.83693114365796,
            21.011936766371058
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83668874678244,
              21.011775203723094
            ],
            [
              105.83687924472089,
              21.011901605299652
            ],
            [
              105.83693114365796,
              21.011936766371058
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Xã Đàn",
          "maTaiSan": "04.O16B.DOPP.361",
          "duongKinh": 100,
          "chieudaiQL": 20,
          "chatLieu": "G-DI",
          "from_latitude": 21.011775203723094,
          "to_latitude": 21.011936766371058,
          "to_longitude": 105.83693114365796,
          "from_longitude": 105.83668874678244
        }
      },
      {
        "id": 360,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8300584057211,
            21.01797721146238,
            105.83026417623475,
            21.01871960889997
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83026417623475,
              21.01797721146238
            ],
            [
              105.83024000512174,
              21.018070153872635
            ],
            [
              105.83017718980692,
              21.018222426245288
            ],
            [
              105.83017484152937,
              21.01822813434582
            ],
            [
              105.83010740996116,
              21.018381155414815
            ],
            [
              105.8300584057211,
              21.018579406762615
            ],
            [
              105.83015427603547,
              21.018683295744736
            ],
            [
              105.83018779635822,
              21.01871960889997
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D200_Xã Đàn",
          "maTaiSan": "04.O16B.DOPP.362",
          "duongKinh": 200,
          "chieudaiQL": 160,
          "chatLieu": "G-DI",
          "from_latitude": 21.01797721146238,
          "to_latitude": 21.01871960889997,
          "to_longitude": 105.83018779635822,
          "from_longitude": 105.83026417623475
        }
      },
      {
        "id": 361,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83026417623475,
            21.01740867827338,
            105.83100317815286,
            21.01797721146238
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83026417623475,
              21.01797721146238
            ],
            [
              105.83029025911263,
              21.017957145465477
            ],
            [
              105.83029576304524,
              21.01795291047617
            ],
            [
              105.83100317815286,
              21.01740867827338
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D200_Xã Đàn",
          "maTaiSan": "04.O16B.DOPP.363",
          "duongKinh": 200,
          "chieudaiQL": 105,
          "chatLieu": "G-DI",
          "from_latitude": 21.01797721146238,
          "to_latitude": 21.01740867827338,
          "to_longitude": 105.83100317815286,
          "from_longitude": 105.83026417623475
        }
      },
      {
        "id": 362,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83100317815286,
            21.014398416303923,
            105.83423847600298,
            21.01740867827338
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83423847600298,
              21.014398416303923
            ],
            [
              105.83345894725403,
              21.015173338842665
            ],
            [
              105.83320760796174,
              21.015439780243582
            ],
            [
              105.8326421166218,
              21.016006057252905
            ],
            [
              105.83218966385753,
              21.01645913566602
            ],
            [
              105.83217412629588,
              21.016471570496563
            ],
            [
              105.83188985472944,
              21.01669907413273
            ],
            [
              105.8318871961727,
              21.01670120158254
            ],
            [
              105.8317664195162,
              21.016797859137068
            ],
            [
              105.83176177470219,
              21.016801576939947
            ],
            [
              105.83145159137838,
              21.017049816345693
            ],
            [
              105.83130647136345,
              21.017165954092256
            ],
            [
              105.83100317815286,
              21.01740867827338
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D200_Xã Đàn",
          "maTaiSan": "04.O16B.DOPP.364",
          "duongKinh": 200,
          "chieudaiQL": 475,
          "chatLieu": "G-DI",
          "from_latitude": 21.014398416303923,
          "to_latitude": 21.01740867827338,
          "to_longitude": 105.83100317815286,
          "from_longitude": 105.83423847600298
        }
      },
      {
        "id": 363,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83423847600298,
            21.011775203723094,
            105.83668874678244,
            21.014398416303923
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83668874678244,
              21.011775203723094
            ],
            [
              105.83559012821608,
              21.012970797855342
            ],
            [
              105.83441832724681,
              21.014170911635077
            ],
            [
              105.8342602714908,
              21.014362113602616
            ],
            [
              105.83424676274616,
              21.01438383826839
            ],
            [
              105.83423847600298,
              21.014398416303923
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D200_Xã Đàn",
          "maTaiSan": "04.O16B.DOPP.365",
          "duongKinh": 200,
          "chieudaiQL": 390,
          "chatLieu": "G-DI",
          "from_latitude": 21.011775203723094,
          "to_latitude": 21.014398416303923,
          "to_longitude": 105.83423847600298,
          "from_longitude": 105.83668874678244
        }
      },
      {
        "id": 364,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83668874678244,
            21.011170625375524,
            105.83700332976771,
            21.011775203723094
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83668874678244,
              21.011775203723094
            ],
            [
              105.83679203740195,
              21.011702872269424
            ],
            [
              105.83698897574126,
              21.011418476285098
            ],
            [
              105.83699313328707,
              21.01141730061007
            ],
            [
              105.83700332976771,
              21.011407688918013
            ],
            [
              105.83699585563032,
              21.011389738868512
            ],
            [
              105.83676377250751,
              21.011170625375524
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D200_Xã Đàn",
          "maTaiSan": "04.O16B.DOPP.366",
          "duongKinh": 200,
          "chieudaiQL": 75,
          "chatLieu": "G-DI",
          "from_latitude": 21.011775203723094,
          "to_latitude": 21.011170625375524,
          "to_longitude": 105.83676377250751,
          "from_longitude": 105.83668874678244
        }
      },
      {
        "id": 365,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82938321939395,
            21.01401442119368,
            105.83051391511273,
            21.017512638271782
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83051391511273,
              21.01401442119368
            ],
            [
              105.8305087757914,
              21.01401776834372
            ],
            [
              105.83042160668897,
              21.014074546059835
            ],
            [
              105.83036139637055,
              21.01423208786538
            ],
            [
              105.83035906714403,
              21.014238271875897
            ],
            [
              105.83033194018647,
              21.014310286561162
            ],
            [
              105.83029994447384,
              21.014395261900745
            ],
            [
              105.83028042193645,
              21.014441978960956
            ],
            [
              105.83021783847074,
              21.014591739269555
            ],
            [
              105.8301933936394,
              21.014664171683936
            ],
            [
              105.83015774115677,
              21.01476977283274
            ],
            [
              105.83015630924064,
              21.01477488575299
            ],
            [
              105.83007404025959,
              21.01506870084062
            ],
            [
              105.83007314242629,
              21.015071592832772
            ],
            [
              105.83003269766027,
              21.015213743772467
            ],
            [
              105.8300024391281,
              21.01546478482859
            ],
            [
              105.83000633126044,
              21.015553119346738
            ],
            [
              105.83002715622345,
              21.015829858125155
            ],
            [
              105.83004079812412,
              21.015995285028975
            ],
            [
              105.83004486064239,
              21.016142907321026
            ],
            [
              105.83000615376218,
              21.016383956549117
            ],
            [
              105.82997615317605,
              21.01653597482658
            ],
            [
              105.82997273131019,
              21.016553316284774
            ],
            [
              105.8299207282496,
              21.01681453524714
            ],
            [
              105.82985296131751,
              21.017018851124213
            ],
            [
              105.82984528436424,
              21.017029692049807
            ],
            [
              105.82983992300399,
              21.017037263746193
            ],
            [
              105.82980316658691,
              21.017089170749394
            ],
            [
              105.82972504262973,
              21.0171880376779
            ],
            [
              105.82965847451503,
              21.017270917128318
            ],
            [
              105.8295483939942,
              21.0173827484648
            ],
            [
              105.82948496438675,
              21.017435336435277
            ],
            [
              105.82942839351678,
              21.01747819843202
            ],
            [
              105.82941068765825,
              21.017490683331882
            ],
            [
              105.82940813306473,
              21.017492485076154
            ],
            [
              105.82938521415149,
              21.017510665838017
            ],
            [
              105.82938321939395,
              21.017512638271782
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Trần Hữu Tước",
          "maTaiSan": "04.O16B.DOPP.367",
          "duongKinh": 100,
          "chieudaiQL": 425,
          "chatLieu": "G-DI",
          "from_latitude": 21.01401442119368,
          "to_latitude": 21.017512638271782,
          "to_longitude": 105.82938321939395,
          "from_longitude": 105.83051391511273
        }
      },
      {
        "id": 366,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82945742082805,
            21.017536233591716,
            105.83024458811886,
            21.017716376061912
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83024458811886,
              21.01755114920196
            ],
            [
              105.8302328775031,
              21.017536233591716
            ],
            [
              105.82996267485429,
              21.01760652595642
            ],
            [
              105.8299491952099,
              21.01761003210515
            ],
            [
              105.82991164453655,
              21.017625680322
            ],
            [
              105.82981240283145,
              21.017671373575197
            ],
            [
              105.82969718212442,
              21.017716376061912
            ],
            [
              105.82952805723643,
              21.017687771960546
            ],
            [
              105.82950567096185,
              21.01765094372434
            ],
            [
              105.82948511338995,
              21.017612380504527
            ],
            [
              105.82945742082805,
              21.0176254370717
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DOPP.368",
          "duongKinh": 150,
          "chieudaiQL": 100,
          "chatLieu": "G-DI",
          "from_latitude": 21.01755114920196,
          "to_latitude": 21.0176254370717,
          "to_longitude": 105.82945742082805,
          "from_longitude": 105.83024458811886
        }
      },
      {
        "id": 367,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82950567096185,
            21.01735899233993,
            105.83005343471346,
            21.01765094372434
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82950567096185,
              21.01765094372434
            ],
            [
              105.82952003125625,
              21.017643518737966
            ],
            [
              105.82953499537408,
              21.01763534745162
            ],
            [
              105.82977599482216,
              21.01750374456983
            ],
            [
              105.82981005960683,
              21.017485182421183
            ],
            [
              105.82981255699461,
              21.017483821723957
            ],
            [
              105.82981466980038,
              21.017482670644455
            ],
            [
              105.82981627142514,
              21.017481797542107
            ],
            [
              105.82981952550664,
              21.01748002399267
            ],
            [
              105.82998592674576,
              21.017389350342366
            ],
            [
              105.8300246318558,
              21.017374427187118
            ],
            [
              105.83005011908946,
              21.017360768903668
            ],
            [
              105.83005343471346,
              21.01735899233993
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DOPP.369",
          "duongKinh": 100,
          "chieudaiQL": 70,
          "chatLieu": "G-DI",
          "from_latitude": 21.01765094372434,
          "to_latitude": 21.01735899233993,
          "to_longitude": 105.83005343471346,
          "from_longitude": 105.82950567096185
        }
      },
      {
        "id": 368,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83001390327489,
            21.014008714803587,
            105.83049352241116,
            21.015053792953463
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83049352241116,
              21.014008714803587
            ],
            [
              105.83048779893511,
              21.01401253538487
            ],
            [
              105.83048015676844,
              21.01401763616965
            ],
            [
              105.83039615044429,
              21.014073713790008
            ],
            [
              105.83036226788735,
              21.014149894611744
            ],
            [
              105.83028897141254,
              21.014338373409018
            ],
            [
              105.83028462830009,
              21.014348974083536
            ],
            [
              105.83018350168287,
              21.014595789786824
            ],
            [
              105.8301607929043,
              21.014655321085897
            ],
            [
              105.83010071335971,
              21.014812768229486
            ],
            [
              105.83007837060435,
              21.014893780045693
            ],
            [
              105.83004956537934,
              21.01499822686224
            ],
            [
              105.83001390327489,
              21.015053792953463
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Trần Hữu Tước",
          "maTaiSan": "04.O16B.DOPP.370",
          "duongKinh": 100,
          "chieudaiQL": 148,
          "chatLieu": "G-DI",
          "from_latitude": 21.014008714803587,
          "to_latitude": 21.015053792953463,
          "to_longitude": 105.83001390327489,
          "from_longitude": 105.83049352241116
        }
      },
      {
        "id": 369,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82815886094397,
            21.014889369164855,
            105.82972652997285,
            21.015432762542368
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82972652997285,
              21.014958772668496
            ],
            [
              105.82970903476722,
              21.01496476418176
            ],
            [
              105.82964837229918,
              21.014984230068656
            ],
            [
              105.82961052583678,
              21.014993941843976
            ],
            [
              105.8294887554608,
              21.015048181758875
            ],
            [
              105.82945486535588,
              21.015063277305856
            ],
            [
              105.8294491433579,
              21.01505415643118
            ],
            [
              105.82944434429083,
              21.015046506024007
            ],
            [
              105.82944315253482,
              21.01504460693151
            ],
            [
              105.82943907066104,
              21.01503810012407
            ],
            [
              105.82938048313817,
              21.014936850546775
            ],
            [
              105.8293664018623,
              21.014912751584763
            ],
            [
              105.82935077968874,
              21.014889369164855
            ],
            [
              105.82927566115825,
              21.01492586615747
            ],
            [
              105.82916806495862,
              21.01498326759356
            ],
            [
              105.82915952318484,
              21.014988001479754
            ],
            [
              105.82915588571807,
              21.014990018045438
            ],
            [
              105.82915338221373,
              21.014990788052692
            ],
            [
              105.82908563299948,
              21.015011629568708
            ],
            [
              105.82902940315722,
              21.015025725637848
            ],
            [
              105.82901897404116,
              21.01502906329483
            ],
            [
              105.82900495324405,
              21.015006151748704
            ],
            [
              105.82893431484554,
              21.015011416705992
            ],
            [
              105.82884518560087,
              21.01501797481438
            ],
            [
              105.82879855738429,
              21.01502068764595
            ],
            [
              105.82878826822113,
              21.015021674414037
            ],
            [
              105.82875391363734,
              21.01502496782999
            ],
            [
              105.82873712666198,
              21.015032754116884
            ],
            [
              105.82871169270074,
              21.01504973942317
            ],
            [
              105.82867234305171,
              21.01507693573019
            ],
            [
              105.82866716587556,
              21.01507988739791
            ],
            [
              105.82866320939138,
              21.01508214215227
            ],
            [
              105.82866127560523,
              21.015083481119287
            ],
            [
              105.82861727095334,
              21.015113952602533
            ],
            [
              105.82858771049764,
              21.015136378245845
            ],
            [
              105.82856718496153,
              21.01515052427154
            ],
            [
              105.8285525114719,
              21.015161296247296
            ],
            [
              105.82853799474954,
              21.01519435455159
            ],
            [
              105.82852673959623,
              21.0152301364546
            ],
            [
              105.82852022587366,
              21.015233536304265
            ],
            [
              105.82851677861456,
              21.0152353351562
            ],
            [
              105.82851268399727,
              21.015237486708923
            ],
            [
              105.82842328727399,
              21.015284470671205
            ],
            [
              105.82842003390587,
              21.015286542254497
            ],
            [
              105.82840365682354,
              21.01529696935198
            ],
            [
              105.8283876670695,
              21.015306291723434
            ],
            [
              105.8283288292501,
              21.015340596390278
            ],
            [
              105.82817093077958,
              21.01542621772473
            ],
            [
              105.82816417129018,
              21.01542988340264
            ],
            [
              105.82815886094397,
              21.015432762542368
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 115, Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DOPP.371",
          "duongKinh": 100,
          "chieudaiQL": 198,
          "chatLieu": "G-DI",
          "from_latitude": 21.014958772668496,
          "to_latitude": 21.015432762542368,
          "to_longitude": 105.82815886094397,
          "from_longitude": 105.82972652997285
        }
      },
      {
        "id": 370,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82972652997285,
            21.014958772668496,
            105.82982585666207,
            21.015468356916333
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82981324022344,
              21.015468356916333
            ],
            [
              105.82982161996806,
              21.015442506377468
            ],
            [
              105.82982585666207,
              21.01534049982068
            ],
            [
              105.82981852320852,
              21.01529674451148
            ],
            [
              105.82979165913653,
              21.015171180880387
            ],
            [
              105.82977455944108,
              21.01508417347557
            ],
            [
              105.82975750554738,
              21.015002833584074
            ],
            [
              105.82972652997285,
              21.014958772668496
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Ngõ 115, Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DOPP.372",
          "duongKinh": 150,
          "chieudaiQL": 58,
          "chatLieu": "G-DI",
          "from_latitude": 21.015468356916333,
          "to_latitude": 21.014958772668496,
          "to_longitude": 105.82972652997285,
          "from_longitude": 105.82981324022344
        }
      },
      {
        "id": 371,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82742702392768,
            21.0140182429627,
            105.82845402599189,
            21.014244767035255
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82845402599189,
              21.014022791935048
            ],
            [
              105.82845399722963,
              21.014022809236376
            ],
            [
              105.82844375125569,
              21.014029862739264
            ],
            [
              105.82842337010597,
              21.01404017023602
            ],
            [
              105.82842333940495,
              21.014040184837118
            ],
            [
              105.82841512248504,
              21.014044340845782
            ],
            [
              105.82832115136762,
              21.014087817207965
            ],
            [
              105.82831305553644,
              21.014091779331988
            ],
            [
              105.82807694383158,
              21.01418435137963
            ],
            [
              105.8280544432205,
              21.014175961535113
            ],
            [
              105.82797861935556,
              21.014147756095245
            ],
            [
              105.82796198467518,
              21.014128575755322
            ],
            [
              105.82791431143805,
              21.014078367095948
            ],
            [
              105.82787444238127,
              21.014034612835903
            ],
            [
              105.82786661577434,
              21.014026877756812
            ],
            [
              105.82785788191484,
              21.0140182429627
            ],
            [
              105.8276909105329,
              21.014096345268612
            ],
            [
              105.82769087791293,
              21.014096360782144
            ],
            [
              105.82764981624727,
              21.014115567116768
            ],
            [
              105.82746751794853,
              21.01421907744813
            ],
            [
              105.82744406166972,
              21.014233093076705
            ],
            [
              105.82742702392768,
              21.014244767035255
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ Liên Việt, Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DOPP.373",
          "duongKinh": 100,
          "chieudaiQL": 125,
          "chatLieu": "G-DI",
          "from_latitude": 21.014022791935048,
          "to_latitude": 21.014244767035255,
          "to_longitude": 105.82742702392768,
          "from_longitude": 105.82845402599189
        }
      },
      {
        "id": 372,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82742702392768,
            21.014244767035255,
            105.8281539851376,
            21.01541433969819
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82742702392768,
              21.014244767035255
            ],
            [
              105.82780852867201,
              21.014878345988883
            ],
            [
              105.82781595931823,
              21.014890669576026
            ],
            [
              105.82787526153737,
              21.014989144245476
            ],
            [
              105.8281539851376,
              21.01541433969819
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DOPP.374",
          "duongKinh": 150,
          "chieudaiQL": 155,
          "chatLieu": "G-DI",
          "from_latitude": 21.014244767035255,
          "to_latitude": 21.01541433969819,
          "to_longitude": 105.8281539851376,
          "from_longitude": 105.82742702392768
        }
      },
      {
        "id": 373,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82852806318621,
            21.01546797100927,
            105.82981324022344,
            21.016078226345474
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82981324022344,
              21.015468356916333
            ],
            [
              105.82978643565284,
              21.01546797100927
            ],
            [
              105.829692075366,
              21.015487258043912
            ],
            [
              105.82963402901939,
              21.01549782975643
            ],
            [
              105.82963183802828,
              21.015498405859873
            ],
            [
              105.82955310457335,
              21.0155191222908
            ],
            [
              105.82954390541678,
              21.015508310375694
            ],
            [
              105.8295248657074,
              21.015491595088697
            ],
            [
              105.82935219200918,
              21.015571744643413
            ],
            [
              105.8293498269056,
              21.015572842753148
            ],
            [
              105.82933059902801,
              21.015581767320093
            ],
            [
              105.8292976891799,
              21.015598286962963
            ],
            [
              105.82928290866552,
              21.015605705846358
            ],
            [
              105.82928059872941,
              21.015606865105582
            ],
            [
              105.82927496151962,
              21.01560977658261
            ],
            [
              105.82926747305346,
              21.015613643663468
            ],
            [
              105.82926575032889,
              21.01561453270545
            ],
            [
              105.82926004213681,
              21.015617480657028
            ],
            [
              105.82917494333573,
              21.015658192838956
            ],
            [
              105.82912182196249,
              21.015684767209393
            ],
            [
              105.82906542748145,
              21.015712977900716
            ],
            [
              105.82905889230929,
              21.015717371423662
            ],
            [
              105.82902968288009,
              21.015737006272726
            ],
            [
              105.82892662554325,
              21.015800699901725
            ],
            [
              105.82888377174523,
              21.015816296046435
            ],
            [
              105.82886090242827,
              21.015824618690512
            ],
            [
              105.82885445217875,
              21.015830456049756
            ],
            [
              105.82877717644106,
              21.015900388109518
            ],
            [
              105.82871720693647,
              21.015954658806987
            ],
            [
              105.82868193311866,
              21.015982261588384
            ],
            [
              105.82858564360758,
              21.01605760979063
            ],
            [
              105.82857632421376,
              21.01606490266167
            ],
            [
              105.82854890627102,
              21.016067385484597
            ],
            [
              105.82852806318621,
              21.016078226345474
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 85, Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DOPP.375",
          "duongKinh": 100,
          "chieudaiQL": 152,
          "chatLieu": "G-DI",
          "from_latitude": 21.015468356916333,
          "to_latitude": 21.016078226345474,
          "to_longitude": 105.82852806318621,
          "from_longitude": 105.82981324022344
        }
      },
      {
        "id": 374,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82904959574655,
            21.015468356916333,
            105.82991597151103,
            21.016969286304228
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82904959574655,
              21.016969286304228
            ],
            [
              105.82906093378043,
              21.016963814407344
            ],
            [
              105.82908360642237,
              21.016955035659304
            ],
            [
              105.82909302391049,
              21.016951387699915
            ],
            [
              105.82915908463488,
              21.016930166003533
            ],
            [
              105.82922848678317,
              21.016863816403674
            ],
            [
              105.82927844311722,
              21.016806855758073
            ],
            [
              105.829305935508,
              21.016747704091827
            ],
            [
              105.8292972633288,
              21.016712516060952
            ],
            [
              105.8292973268041,
              21.016709208140217
            ],
            [
              105.82929755424824,
              21.0166976285854
            ],
            [
              105.8293017339779,
              21.016666675450836
            ],
            [
              105.82930204155709,
              21.016664369823527
            ],
            [
              105.82930254823042,
              21.016659971355697
            ],
            [
              105.82930358944581,
              21.01665099544557
            ],
            [
              105.82930761196825,
              21.016632842683986
            ],
            [
              105.82933108099107,
              21.016572908562523
            ],
            [
              105.82942830378698,
              21.016468384827004
            ],
            [
              105.82942891368113,
              21.01646786701503
            ],
            [
              105.82944191049856,
              21.01646804210064
            ],
            [
              105.82946384060506,
              21.016467819565467
            ],
            [
              105.82950356182359,
              21.016467419055065
            ],
            [
              105.8295074853681,
              21.01646737915005
            ],
            [
              105.82955112239517,
              21.016466931535376
            ],
            [
              105.82958210363027,
              21.016472833878996
            ],
            [
              105.82963830163189,
              21.016477067825925
            ],
            [
              105.82964998836302,
              21.016477952897002
            ],
            [
              105.82971616103949,
              21.016482933915253
            ],
            [
              105.82982259586629,
              21.01648026872042
            ],
            [
              105.82988149682782,
              21.01636521759758
            ],
            [
              105.82989132736037,
              21.01631627083111
            ],
            [
              105.8299026688937,
              21.016243643157402
            ],
            [
              105.82991597151103,
              21.016094813440137
            ],
            [
              105.82989087062776,
              21.01590530643469
            ],
            [
              105.82988944087168,
              21.015896904381044
            ],
            [
              105.82988768835202,
              21.015886496935078
            ],
            [
              105.82988633392553,
              21.015878496449215
            ],
            [
              105.82988227880217,
              21.015854578048412
            ],
            [
              105.82985850804243,
              21.015714041938796
            ],
            [
              105.82985529487972,
              21.015696602781514
            ],
            [
              105.82984810317971,
              21.015657610395387
            ],
            [
              105.82984420348463,
              21.01563639820108
            ],
            [
              105.82982518802315,
              21.015552612022464
            ],
            [
              105.82980150507957,
              21.01550113349507
            ],
            [
              105.82981324022344,
              21.015468356916333
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Ngõ 73, Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DOPP.376",
          "duongKinh": 150,
          "chieudaiQL": 226,
          "chatLieu": "G-DI",
          "from_latitude": 21.016969286304228,
          "to_latitude": 21.015468356916333,
          "to_longitude": 105.82981324022344,
          "from_longitude": 105.82904959574655
        }
      },
      {
        "id": 375,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82815186421338,
            21.01541433969819,
            105.82853687816394,
            21.01609577012062
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8281539851376,
              21.01541433969819
            ],
            [
              105.82815186421338,
              21.015420638279696
            ],
            [
              105.82815247509961,
              21.015421693886562
            ],
            [
              105.82815886094397,
              21.015432762542368
            ],
            [
              105.82829678011973,
              21.01567186231276
            ],
            [
              105.82830035621043,
              21.01567806180415
            ],
            [
              105.82831762381623,
              21.01570799643736
            ],
            [
              105.82842839933019,
              21.015887850183397
            ],
            [
              105.8284501407754,
              21.01592314894999
            ],
            [
              105.82852806318621,
              21.016078226345474
            ],
            [
              105.82853687816394,
              21.01609577012062
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DOPP.377",
          "duongKinh": 150,
          "chieudaiQL": 85,
          "chatLieu": "G-DI",
          "from_latitude": 21.01541433969819,
          "to_latitude": 21.01609577012062,
          "to_longitude": 105.82853687816394,
          "from_longitude": 105.8281539851376
        }
      },
      {
        "id": 376,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82904959574655,
            21.016969286304228,
            105.82937301700967,
            21.017495426473683
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82904959574655,
              21.016969286304228
            ],
            [
              105.8290564263383,
              21.016977779464103
            ],
            [
              105.82910665116967,
              21.017040195050225
            ],
            [
              105.8291243274344,
              21.017070736370083
            ],
            [
              105.82917714936113,
              21.017161995518798
            ],
            [
              105.82918590372462,
              21.0171755093581
            ],
            [
              105.82919152814073,
              21.017184192648926
            ],
            [
              105.82922592079908,
              21.017237285497302
            ],
            [
              105.82923445783835,
              21.017250463490026
            ],
            [
              105.82926069866137,
              21.017290972588686
            ],
            [
              105.82929866631936,
              21.017354044891192
            ],
            [
              105.82937301700967,
              21.017495426473683
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DOPP.378",
          "duongKinh": 150,
          "chieudaiQL": 70,
          "chatLieu": "G-DI",
          "from_latitude": 21.016969286304228,
          "to_latitude": 21.017495426473683,
          "to_longitude": 105.82937301700967,
          "from_longitude": 105.82904959574655
        }
      },
      {
        "id": 377,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83131751476475,
            21.015502280582854,
            105.83205937329453,
            21.016071530744316
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83131751476475,
              21.015502280582854
            ],
            [
              105.83132274259984,
              21.015509344451857
            ],
            [
              105.83133714369009,
              21.015549772890857
            ],
            [
              105.83147779091207,
              21.01568574261689
            ],
            [
              105.83148984800675,
              21.01569739942204
            ],
            [
              105.83149659367005,
              21.01570392025434
            ],
            [
              105.8316261047671,
              21.015829123638145
            ],
            [
              105.83164239735119,
              21.015844779982196
            ],
            [
              105.83165018727175,
              21.015852265766934
            ],
            [
              105.83180179931865,
              21.015997961723656
            ],
            [
              105.83186831049984,
              21.015998619225325
            ],
            [
              105.83190375297413,
              21.01596684659154
            ],
            [
              105.83192226004638,
              21.015950255075033
            ],
            [
              105.83193006204527,
              21.01595731356181
            ],
            [
              105.8319707339662,
              21.015994109861257
            ],
            [
              105.8320458975533,
              21.016058885566498
            ],
            [
              105.83205937329453,
              21.016071530744316
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngách 106, Ngõ Xã Đàn 2",
          "maTaiSan": "04.O16B.DOPP.379",
          "duongKinh": 100,
          "chieudaiQL": 108,
          "chatLieu": "G-DI",
          "from_latitude": 21.015502280582854,
          "to_latitude": 21.016071530744316,
          "to_longitude": 105.83205937329453,
          "from_longitude": 105.83131751476475
        }
      },
      {
        "id": 378,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83035816430709,
            21.0160610772916,
            105.83074555636257,
            21.016635431779886
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83035816430709,
              21.016635431779886
            ],
            [
              105.8303638513143,
              21.01662570067347
            ],
            [
              105.83036579907802,
              21.01662236820095
            ],
            [
              105.83038337658354,
              21.016592292618064
            ],
            [
              105.83040630572441,
              21.01655498317395
            ],
            [
              105.8304294112236,
              21.016517386543644
            ],
            [
              105.83045056515587,
              21.016482436854485
            ],
            [
              105.83045306450808,
              21.016478307753502
            ],
            [
              105.83048518251564,
              21.016415015525713
            ],
            [
              105.83049884922657,
              21.016389722632358
            ],
            [
              105.83050563926076,
              21.01637715723543
            ],
            [
              105.83053562173419,
              21.016331414250775
            ],
            [
              105.83056871700457,
              21.01628288677562
            ],
            [
              105.83057733316373,
              21.016269346009643
            ],
            [
              105.83057852960123,
              21.016268264427225
            ],
            [
              105.83057894500192,
              21.016267888463933
            ],
            [
              105.83061212927666,
              21.01621007719761
            ],
            [
              105.83062153005055,
              21.01619369918128
            ],
            [
              105.83070492218528,
              21.016101737487496
            ],
            [
              105.83074555636257,
              21.0160610772916
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Ngõ Xã Đàn 2",
          "maTaiSan": "04.O16B.DOPP.380",
          "duongKinh": 150,
          "chieudaiQL": 82,
          "chatLieu": "G-DI",
          "from_latitude": 21.016635431779886,
          "to_latitude": 21.0160610772916,
          "to_longitude": 105.83074555636257,
          "from_longitude": 105.83035816430709
        }
      },
      {
        "id": 379,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8312118574619,
            21.0146174726333,
            105.83124469649002,
            21.014634772439678
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83124469649002,
              21.014634772439678
            ],
            [
              105.83121562190503,
              21.014619455788935
            ],
            [
              105.8312118574619,
              21.0146174726333
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngách 150, Ngõ Xã Đàn 2",
          "maTaiSan": "04.O16B.DOPP.381",
          "duongKinh": 100,
          "chieudaiQL": 5,
          "chatLieu": "G-DI",
          "from_latitude": 21.014634772439678,
          "to_latitude": 21.0146174726333,
          "to_longitude": 105.8312118574619,
          "from_longitude": 105.83124469649002
        }
      },
      {
        "id": 380,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83230432954993,
            21.014805356711033,
            105.8332156662577,
            21.015518559625686
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8332156662577,
              21.014937441174226
            ],
            [
              105.83306340909652,
              21.014805356711033
            ],
            [
              105.83302652218083,
              21.014808524288917
            ],
            [
              105.83296303551086,
              21.014948638088516
            ],
            [
              105.83278760380271,
              21.015095870827974
            ],
            [
              105.83244948434802,
              21.015393859233324
            ],
            [
              105.83230432954993,
              21.015518559625686
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Xã Đàn",
          "maTaiSan": "04.O16B.DOPP.382",
          "duongKinh": 100,
          "chieudaiQL": 110,
          "chatLieu": "G-DI",
          "from_latitude": 21.014937441174226,
          "to_latitude": 21.015518559625686,
          "to_longitude": 105.83230432954993,
          "from_longitude": 105.8332156662577
        }
      },
      {
        "id": 381,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83170600410678,
            21.01506659769727,
            105.83230432954993,
            21.01551965307136
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83170600410678,
              21.01513671269688
            ],
            [
              105.83171738358419,
              21.015126093849066
            ],
            [
              105.83172504422744,
              21.015118945307254
            ],
            [
              105.83178274701586,
              21.01506659769727
            ],
            [
              105.83181704645206,
              21.015098126881803
            ],
            [
              105.83188025744747,
              21.015153530547096
            ],
            [
              105.83195446379456,
              21.015216636413324
            ],
            [
              105.83195752468104,
              21.015219239894858
            ],
            [
              105.83201786088442,
              21.015277304012717
            ],
            [
              105.83212720498108,
              21.015383422951004
            ],
            [
              105.83213611085098,
              21.01539162131954
            ],
            [
              105.83227633942201,
              21.01551965307136
            ],
            [
              105.83230432954993,
              21.015518559625686
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ Xã Đàn 2",
          "maTaiSan": "04.O16B.DOPP.383",
          "duongKinh": 100,
          "chieudaiQL": 88,
          "chatLieu": "G-DI",
          "from_latitude": 21.01513671269688,
          "to_latitude": 21.015518559625686,
          "to_longitude": 105.83230432954993,
          "from_longitude": 105.83170600410678
        }
      },
      {
        "id": 382,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83174203276646,
            21.012805666846756,
            105.83243987245096,
            21.013546451949928
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83174203276646,
              21.013546451949928
            ],
            [
              105.83175923402139,
              21.013523749293167
            ],
            [
              105.83177311018821,
              21.01350543540367
            ],
            [
              105.83192877825049,
              21.013335969003393
            ],
            [
              105.83194988546161,
              21.013315109680384
            ],
            [
              105.83205927805855,
              21.01319300153367
            ],
            [
              105.83206666413413,
              21.013184756902163
            ],
            [
              105.83217780104016,
              21.01308667497671
            ],
            [
              105.8321811925238,
              21.01308368226179
            ],
            [
              105.83231316148152,
              21.012940504194976
            ],
            [
              105.8323825133002,
              21.012866703814176
            ],
            [
              105.83239452313386,
              21.01285392403567
            ],
            [
              105.83239686217762,
              21.012851435045725
            ],
            [
              105.83240065785316,
              21.01284639725371
            ],
            [
              105.83243987245096,
              21.012805666846756
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ Xã Đàn 2",
          "maTaiSan": "04.O16B.DOPP.384",
          "duongKinh": 100,
          "chieudaiQL": 90,
          "chatLieu": "G-DI",
          "from_latitude": 21.013546451949928,
          "to_latitude": 21.012805666846756,
          "to_longitude": 105.83243987245096,
          "from_longitude": 105.83174203276646
        }
      },
      {
        "id": 383,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83131751476475,
            21.01513671269688,
            105.83170600410678,
            21.015502280582854
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83170600410678,
              21.01513671269688
            ],
            [
              105.8316936639718,
              21.01514981739957
            ],
            [
              105.83168132191082,
              21.01516292211084
            ],
            [
              105.83167511927758,
              21.015177827646607
            ],
            [
              105.83153367232673,
              21.015316765776674
            ],
            [
              105.83153170242583,
              21.01531870017755
            ],
            [
              105.83151362414115,
              21.01533645832938
            ],
            [
              105.8314866712559,
              21.015362932620647
            ],
            [
              105.8313644756751,
              21.015478528415226
            ],
            [
              105.83135642224433,
              21.01548614673155
            ],
            [
              105.83133969578402,
              21.015491470785044
            ],
            [
              105.8313236568539,
              21.01549657560655
            ],
            [
              105.83131751476475,
              21.015502280582854
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Ngõ Xã Đàn 2",
          "maTaiSan": "04.O16B.DOPP.385",
          "duongKinh": 150,
          "chieudaiQL": 54,
          "chatLieu": "G-DI",
          "from_latitude": 21.01513671269688,
          "to_latitude": 21.015502280582854,
          "to_longitude": 105.83131751476475,
          "from_longitude": 105.83170600410678
        }
      },
      {
        "id": 384,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83124469649002,
            21.014634772439678,
            105.83170600410678,
            21.01513671269688
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83124469649002,
              21.014634772439678
            ],
            [
              105.83124814316723,
              21.01463912630694
            ],
            [
              105.83124987170056,
              21.014641893472866
            ],
            [
              105.83126155696843,
              21.014660591852767
            ],
            [
              105.83129980557294,
              21.014717925966416
            ],
            [
              105.83142775732455,
              21.014843481292257
            ],
            [
              105.83148685173231,
              21.014909949867437
            ],
            [
              105.8314989290664,
              21.014923534058365
            ],
            [
              105.83158858813181,
              21.015024381704436
            ],
            [
              105.83160816918445,
              21.015046405977518
            ],
            [
              105.83170600410678,
              21.01513671269688
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ Xã Đàn 2",
          "maTaiSan": "04.O16B.DOPP.386",
          "duongKinh": 100,
          "chieudaiQL": 74,
          "chatLieu": "G-DI",
          "from_latitude": 21.014634772439678,
          "to_latitude": 21.01513671269688,
          "to_longitude": 105.83170600410678,
          "from_longitude": 105.83124469649002
        }
      },
      {
        "id": 385,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8311218561644,
            21.015502280582854,
            105.83131751476475,
            21.01568946711183
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8311218561644,
              21.01568946711183
            ],
            [
              105.83131751476475,
              21.015502280582854
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Ngõ Xã Đàn 2",
          "maTaiSan": "04.O16B.DOPP.387",
          "duongKinh": 150,
          "chieudaiQL": 28,
          "chatLieu": "G-DI",
          "from_latitude": 21.01568946711183,
          "to_latitude": 21.015502280582854,
          "to_longitude": 105.83131751476475,
          "from_longitude": 105.8311218561644
        }
      },
      {
        "id": 386,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83243987245096,
            21.012805666846756,
            105.8330158058098,
            21.01330661562602
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8330158058098,
              21.01330661562602
            ],
            [
              105.83268025687794,
              21.013012402205085
            ],
            [
              105.83243987245096,
              21.012805666846756
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Nam Đồng",
          "maTaiSan": "04.O16B.DOPP.388",
          "duongKinh": 150,
          "chieudaiQL": 94,
          "chatLieu": "G-DI",
          "from_latitude": 21.01330661562602,
          "to_latitude": 21.012805666846756,
          "to_longitude": 105.83243987245096,
          "from_longitude": 105.8330158058098
        }
      },
      {
        "id": 387,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83271946915436,
            21.01330661562602,
            105.8330158058098,
            21.01363495603327
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8330158058098,
              21.01330661562602
            ],
            [
              105.83296026326947,
              21.013361842474716
            ],
            [
              105.83288858776699,
              21.01344313768022
            ],
            [
              105.83284655094904,
              21.01349081606276
            ],
            [
              105.83282039646065,
              21.013520481353886
            ],
            [
              105.83281989269979,
              21.013521052851534
            ],
            [
              105.83281852862176,
              21.013522599527253
            ],
            [
              105.8328147977282,
              21.013526831334392
            ],
            [
              105.83271946915436,
              21.01363495603327
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Nam Đồng",
          "maTaiSan": "04.O16B.DOPP.389",
          "duongKinh": 100,
          "chieudaiQL": 40,
          "chatLieu": "G-DI",
          "from_latitude": 21.01330661562602,
          "to_latitude": 21.01363495603327,
          "to_longitude": 105.83271946915436,
          "from_longitude": 105.8330158058098
        }
      },
      {
        "id": 388,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8330158058098,
            21.01330661562602,
            105.83393665577223,
            21.014192399025365
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8330158058098,
              21.01330661562602
            ],
            [
              105.83323769050938,
              21.013509596586843
            ],
            [
              105.83325018202993,
              21.01352102353099
            ],
            [
              105.83334528782841,
              21.01360802694996
            ],
            [
              105.83360682641904,
              21.013837585658777
            ],
            [
              105.83367269136795,
              21.013895396301884
            ],
            [
              105.8338282007642,
              21.01405281782804
            ],
            [
              105.83386158276875,
              21.014086609156667
            ],
            [
              105.83387191108264,
              21.014101163682692
            ],
            [
              105.83393665577223,
              21.014192399025365
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Nam Đồng",
          "maTaiSan": "04.O16B.DOPP.390",
          "duongKinh": 150,
          "chieudaiQL": 135,
          "chatLieu": "G-DI",
          "from_latitude": 21.01330661562602,
          "to_latitude": 21.014192399025365,
          "to_longitude": 105.83393665577223,
          "from_longitude": 105.8330158058098
        }
      },
      {
        "id": 389,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83172447149306,
            21.013546451949928,
            105.83184092816182,
            21.01401308314757
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83184092816182,
              21.01401308314757
            ],
            [
              105.83172447149306,
              21.01370131086833
            ],
            [
              105.83174203276646,
              21.013546451949928
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ Xã Đàn 2",
          "maTaiSan": "04.O16B.DOPP.391",
          "duongKinh": 100,
          "chieudaiQL": 63,
          "chatLieu": "G-DI",
          "from_latitude": 21.01401308314757,
          "to_latitude": 21.013546451949928,
          "to_longitude": 105.83174203276646,
          "from_longitude": 105.83184092816182
        }
      },
      {
        "id": 390,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83124987170056,
            21.01401308314757,
            105.83184092816182,
            21.014641893472866
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83184092816182,
              21.01401308314757
            ],
            [
              105.83183589723787,
              21.014028166315413
            ],
            [
              105.83182289781236,
              21.01406720674884
            ],
            [
              105.83153503082004,
              21.01432685684699
            ],
            [
              105.83150847105485,
              21.014350813735852
            ],
            [
              105.83132102529026,
              21.014529340851706
            ],
            [
              105.83128332192902,
              21.014590869423998
            ],
            [
              105.83125079408953,
              21.014640486257417
            ],
            [
              105.83124987170056,
              21.014641893472866
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ Xã Đàn 2",
          "maTaiSan": "04.O16B.DOPP.392",
          "duongKinh": 100,
          "chieudaiQL": 110,
          "chatLieu": "G-DI",
          "from_latitude": 21.01401308314757,
          "to_latitude": 21.014641893472866,
          "to_longitude": 105.83124987170056,
          "from_longitude": 105.83184092816182
        }
      },
      {
        "id": 391,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83081338115079,
            21.014432099956828,
            105.8312118574619,
            21.0146174726333
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8312118574619,
              21.0146174726333
            ],
            [
              105.83110890909109,
              21.014516317559288
            ],
            [
              105.83110465813938,
              21.01450708297539
            ],
            [
              105.83110348878071,
              21.0145045415923
            ],
            [
              105.83106585792656,
              21.014465969820854
            ],
            [
              105.83106376248848,
              21.014463822251184
            ],
            [
              105.8310175806441,
              21.014444795581195
            ],
            [
              105.83085632741391,
              21.014435391137255
            ],
            [
              105.83083876267679,
              21.014434366849137
            ],
            [
              105.83081338115079,
              21.014432099956828
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngách 150, Ngõ Xã Đàn 2",
          "maTaiSan": "04.O16B.DOPP.393",
          "duongKinh": 100,
          "chieudaiQL": 50,
          "chatLieu": "G-DI",
          "from_latitude": 21.0146174726333,
          "to_latitude": 21.014432099956828,
          "to_longitude": 105.83081338115079,
          "from_longitude": 105.8312118574619
        }
      },
      {
        "id": 392,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83074555636257,
            21.01568946711183,
            105.8311218561644,
            21.0160610772916
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83074555636257,
              21.0160610772916
            ],
            [
              105.83082086604439,
              21.016001401053547
            ],
            [
              105.83091356158639,
              21.015907659635847
            ],
            [
              105.83091518681536,
              21.0159060159573
            ],
            [
              105.83092571993853,
              21.01589332026298
            ],
            [
              105.83092773043397,
              21.01589089702573
            ],
            [
              105.83092999989148,
              21.015888162717413
            ],
            [
              105.83094375611365,
              21.01587158276639
            ],
            [
              105.83104160741726,
              21.015745882573896
            ],
            [
              105.83104246711189,
              21.015744964310592
            ],
            [
              105.83105788517612,
              21.015728492749066
            ],
            [
              105.8310613465149,
              21.015725419326763
            ],
            [
              105.83106915003835,
              21.015720782887712
            ],
            [
              105.8311218561644,
              21.01568946711183
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Ngõ Xã Đàn 2",
          "maTaiSan": "04.O16B.DOPP.394",
          "duongKinh": 150,
          "chieudaiQL": 50,
          "chatLieu": "G-DI",
          "from_latitude": 21.0160610772916,
          "to_latitude": 21.01568946711183,
          "to_longitude": 105.8311218561644,
          "from_longitude": 105.83074555636257
        }
      },
      {
        "id": 393,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83068945602928,
            21.015289651805173,
            105.8311218561644,
            21.01568946711183
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83068945602928,
              21.01530464447284
            ],
            [
              105.83069374316986,
              21.015303912701896
            ],
            [
              105.83069753057782,
              21.01530326646763
            ],
            [
              105.83077732657813,
              21.015289651805173
            ],
            [
              105.83085376993944,
              21.01533346879843
            ],
            [
              105.83096038728554,
              21.0154724295455
            ],
            [
              105.83101269024924,
              21.01554441312444
            ],
            [
              105.83104431244303,
              21.01559015604828
            ],
            [
              105.83109382579295,
              21.015661778387216
            ],
            [
              105.83111831901066,
              21.015685975361933
            ],
            [
              105.8311218561644,
              21.01568946711183
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngách 106, Ngõ Xã Đàn 2",
          "maTaiSan": "04.O16B.DOPP.395",
          "duongKinh": 100,
          "chieudaiQL": 65,
          "chatLieu": "G-DI",
          "from_latitude": 21.01530464447284,
          "to_latitude": 21.01568946711183,
          "to_longitude": 105.8311218561644,
          "from_longitude": 105.83068945602928
        }
      },
      {
        "id": 394,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83499887315931,
            21.010103433196495,
            105.83553609988346,
            21.010572765548446
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83553609988346,
              21.010572765548446
            ],
            [
              105.83528682126645,
              21.01040081134576
            ],
            [
              105.83528564286505,
              21.010399243712254
            ],
            [
              105.83521607745786,
              21.010306693902216
            ],
            [
              105.8350482616592,
              21.01015194241359
            ],
            [
              105.83501746928333,
              21.01012169818771
            ],
            [
              105.83499887315931,
              21.010103433196495
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 46A, Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DOPP.396",
          "duongKinh": 100,
          "chieudaiQL": 75,
          "chatLieu": "G-DI",
          "from_latitude": 21.010572765548446,
          "to_latitude": 21.010103433196495,
          "to_longitude": 105.83499887315931,
          "from_longitude": 105.83553609988346
        }
      },
      {
        "id": 395,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83550574284206,
            21.010606737670585,
            105.83563399306207,
            21.01070354058327
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83563399306207,
              21.01070354058327
            ],
            [
              105.83562440284061,
              21.010699562908815
            ],
            [
              105.83558909671947,
              21.010676526115038
            ],
            [
              105.83557515914283,
              21.01066485714802
            ],
            [
              105.83550574284206,
              21.010606737670585
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 46, Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DOPP.397",
          "duongKinh": 100,
          "chieudaiQL": 20,
          "chatLieu": "G-DI",
          "from_latitude": 21.01070354058327,
          "to_latitude": 21.010606737670585,
          "to_longitude": 105.83550574284206,
          "from_longitude": 105.83563399306207
        }
      },
      {
        "id": 396,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83300167651998,
            21.012387927958912,
            105.83336870659933,
            21.012773822625217
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83336870659933,
              21.012387927958912
            ],
            [
              105.83331960543578,
              21.01244158246624
            ],
            [
              105.83323688346151,
              21.012531977709372
            ],
            [
              105.83320649496444,
              21.012563221853842
            ],
            [
              105.83310350955689,
              21.012669116250994
            ],
            [
              105.83309759769956,
              21.012675194999353
            ],
            [
              105.83300167651998,
              21.012773822625217
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 6, Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DOPP.398",
          "duongKinh": 100,
          "chieudaiQL": 65,
          "chatLieu": "G-DI",
          "from_latitude": 21.012387927958912,
          "to_latitude": 21.012773822625217,
          "to_longitude": 105.83300167651998,
          "from_longitude": 105.83336870659933
        }
      },
      {
        "id": 397,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8350797884665,
            21.01097029228766,
            105.83535888351408,
            21.01138543769445
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8350797884665,
              21.01138543769445
            ],
            [
              105.8351610405943,
              21.01126476186875
            ],
            [
              105.83516319896373,
              21.011261278375216
            ],
            [
              105.83516732120285,
              21.0112546221489
            ],
            [
              105.83520433734951,
              21.011182566338242
            ],
            [
              105.83520697611826,
              21.011177428487812
            ],
            [
              105.83521090472843,
              21.011170721727076
            ],
            [
              105.83524766668248,
              21.011107958630095
            ],
            [
              105.83529015351336,
              21.0110440934885
            ],
            [
              105.83535888351408,
              21.01097029228766
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 46, Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DOPP.399",
          "duongKinh": 100,
          "chieudaiQL": 64,
          "chatLieu": "G-DI",
          "from_latitude": 21.01138543769445,
          "to_latitude": 21.01097029228766,
          "to_longitude": 105.83535888351408,
          "from_longitude": 105.8350797884665
        }
      },
      {
        "id": 398,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83550574284206,
            21.01015219148632,
            105.83588534902545,
            21.010606737670585
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83550574284206,
              21.010606737670585
            ],
            [
              105.83552857945334,
              21.010581181693176
            ],
            [
              105.83553609988346,
              21.010572765548446
            ],
            [
              105.83565707379277,
              21.010437376916098
            ],
            [
              105.83574044893608,
              21.010352291013188
            ],
            [
              105.83574130688403,
              21.010351415187422
            ],
            [
              105.83574410692061,
              21.010350540661918
            ],
            [
              105.83574460912158,
              21.010350383743944
            ],
            [
              105.83581289904988,
              21.01026554079946
            ],
            [
              105.83588132392276,
              21.010158488671664
            ],
            [
              105.83588534902545,
              21.01015219148632
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 46, Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DOPP.400",
          "duongKinh": 100,
          "chieudaiQL": 58,
          "chatLieu": "G-DI",
          "from_latitude": 21.010606737670585,
          "to_latitude": 21.01015219148632,
          "to_longitude": 105.83588534902545,
          "from_longitude": 105.83550574284206
        }
      },
      {
        "id": 399,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83481782485528,
            21.01138543769445,
            105.8350797884665,
            21.01186906794754
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83489162261115,
              21.01186906794754
            ],
            [
              105.83488929224086,
              21.011867401190045
            ],
            [
              105.83483604620366,
              21.0118293115126
            ],
            [
              105.83481782485528,
              21.01175293298561
            ],
            [
              105.83483207175239,
              21.011705227002814
            ],
            [
              105.83491927839907,
              21.011604242181832
            ],
            [
              105.83492296950722,
              21.011599967171076
            ],
            [
              105.83492501194523,
              21.011597601538522
            ],
            [
              105.83492588454624,
              21.01159659106422
            ],
            [
              105.83492920004609,
              21.01159275144436
            ],
            [
              105.83494113224502,
              21.01157610426502
            ],
            [
              105.83495374786737,
              21.011558504441922
            ],
            [
              105.83503244188279,
              21.01145327734125
            ],
            [
              105.83503643200433,
              21.01144794137791
            ],
            [
              105.83504005149909,
              21.01144047923539
            ],
            [
              105.83504070959182,
              21.011439122974856
            ],
            [
              105.83505077980459,
              21.01142840466213
            ],
            [
              105.83505475108925,
              21.011424177951053
            ],
            [
              105.8350797884665,
              21.01138543769445
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 46, Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DOPP.401",
          "duongKinh": 100,
          "chieudaiQL": 64,
          "chatLieu": "G-DI",
          "from_latitude": 21.01186906794754,
          "to_latitude": 21.01138543769445,
          "to_longitude": 105.8350797884665,
          "from_longitude": 105.83489162261115
        }
      },
      {
        "id": 400,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83489162261115,
            21.01186906794754,
            105.83545855006885,
            21.012595280694278
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83489162261115,
              21.01186906794754
            ],
            [
              105.83493248425977,
              21.011907022376754
            ],
            [
              105.83505736349365,
              21.012002221844412
            ],
            [
              105.83513937695373,
              21.01205419282097
            ],
            [
              105.83530856174981,
              21.01216140338957
            ],
            [
              105.83530181559632,
              21.012172242689374
            ],
            [
              105.83524821337743,
              21.012258365471357
            ],
            [
              105.8352476542285,
              21.01227110097638
            ],
            [
              105.83524697984771,
              21.0122864636248
            ],
            [
              105.83533184158676,
              21.012400076449342
            ],
            [
              105.83532966940561,
              21.01241161948748
            ],
            [
              105.83539459451617,
              21.012524181249013
            ],
            [
              105.83545855006885,
              21.012595280694278
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Xã Đàn",
          "maTaiSan": "04.O16B.DOPP.402",
          "duongKinh": 150,
          "chieudaiQL": 115,
          "chatLieu": "G-DI",
          "from_latitude": 21.01186906794754,
          "to_latitude": 21.012595280694278,
          "to_longitude": 105.83545855006885,
          "from_longitude": 105.83489162261115
        }
      },
      {
        "id": 401,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83556841419919,
            21.011256910341555,
            105.8360276744291,
            21.011723583624853
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83556841419919,
              21.011723583624853
            ],
            [
              105.83562300929407,
              21.011723563905655
            ],
            [
              105.83575285067117,
              21.011572938506575
            ],
            [
              105.83578829119108,
              21.01153182486244
            ],
            [
              105.83582063336853,
              21.011495198296615
            ],
            [
              105.8358542497249,
              21.01145713024163
            ],
            [
              105.83601490598883,
              21.01127346957324
            ],
            [
              105.83601898542702,
              21.01126880605153
            ],
            [
              105.8360276744291,
              21.011256910341555
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 46, Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DOPP.403",
          "duongKinh": 100,
          "chieudaiQL": 72.46,
          "chatLieu": "G-DI",
          "from_latitude": 21.011723583624853,
          "to_latitude": 21.011256910341555,
          "to_longitude": 105.8360276744291,
          "from_longitude": 105.83556841419919
        }
      },
      {
        "id": 402,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8350797884665,
            21.01138543769445,
            105.83556841419919,
            21.011723583624853
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8350797884665,
              21.01138543769445
            ],
            [
              105.83524107973778,
              21.01148241111059
            ],
            [
              105.83545519659542,
              21.011664049816762
            ],
            [
              105.83545643368258,
              21.01166509870931
            ],
            [
              105.83546806523245,
              21.0116749662328
            ],
            [
              105.83556841419919,
              21.011723583624853
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 46, Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DOPP.404",
          "duongKinh": 100,
          "chieudaiQL": 54,
          "chatLieu": "G-DI",
          "from_latitude": 21.01138543769445,
          "to_latitude": 21.011723583624853,
          "to_longitude": 105.83556841419919,
          "from_longitude": 105.8350797884665
        }
      },
      {
        "id": 403,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83499070511877,
            21.009705550970033,
            105.83533304536876,
            21.010525010139293
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83506158460263,
              21.010525010139293
            ],
            [
              105.83506471870884,
              21.010520729730246
            ],
            [
              105.83508817092567,
              21.010477705126526
            ],
            [
              105.83509386855877,
              21.010469287107522
            ],
            [
              105.83512835776752,
              21.010418336726726
            ],
            [
              105.83515557310986,
              21.010393491823308
            ],
            [
              105.83519050341248,
              21.010349903259332
            ],
            [
              105.83521355407142,
              21.010318214277792
            ],
            [
              105.83520575439057,
              21.0103113717925
            ],
            [
              105.83513935417979,
              21.010253119028253
            ],
            [
              105.83511826702286,
              21.01023335698777
            ],
            [
              105.83501742527255,
              21.010138851551112
            ],
            [
              105.83501746928333,
              21.01012169818771
            ],
            [
              105.83501750516815,
              21.010107751313186
            ],
            [
              105.83499070511877,
              21.010081558326583
            ],
            [
              105.8351356078668,
              21.00991981665744
            ],
            [
              105.83519724244343,
              21.0098510198917
            ],
            [
              105.8353208576887,
              21.009718605496513
            ],
            [
              105.83533304536876,
              21.009705550970033
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 46A, Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DOPP.405",
          "duongKinh": 100,
          "chieudaiQL": 120,
          "chatLieu": "G-DI",
          "from_latitude": 21.010525010139293,
          "to_latitude": 21.009705550970033,
          "to_longitude": 105.83533304536876,
          "from_longitude": 105.83506158460263
        }
      },
      {
        "id": 404,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83446898993476,
            21.010103433196495,
            105.83499887315931,
            21.01069858884309
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83499887315931,
              21.010103433196495
            ],
            [
              105.8349626450935,
              21.010143709641206
            ],
            [
              105.83495975132087,
              21.010146926676452
            ],
            [
              105.83485531732437,
              21.010263030213046
            ],
            [
              105.83485031000211,
              21.010270351935297
            ],
            [
              105.834690916913,
              21.010503402719323
            ],
            [
              105.83468395357912,
              21.01051358370685
            ],
            [
              105.83463465378443,
              21.010490186318588
            ],
            [
              105.83459807272035,
              21.01050231450702
            ],
            [
              105.83449107700918,
              21.010665004294594
            ],
            [
              105.83446898993476,
              21.01069858884309
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 46A, Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DOPP.406",
          "duongKinh": 100,
          "chieudaiQL": 90,
          "chatLieu": "G-DI",
          "from_latitude": 21.010103433196495,
          "to_latitude": 21.01069858884309,
          "to_longitude": 105.83446898993476,
          "from_longitude": 105.83499887315931
        }
      },
      {
        "id": 405,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83336870659933,
            21.011990707706467,
            105.83374112206312,
            21.012387927958912
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83374112206312,
              21.011990707706467
            ],
            [
              105.83373355265118,
              21.012002976270452
            ],
            [
              105.83370389623357,
              21.012056177024498
            ],
            [
              105.83367771382954,
              21.012080571355444
            ],
            [
              105.83367769276339,
              21.012080588619927
            ],
            [
              105.83358208265665,
              21.012170318266648
            ],
            [
              105.83355961577662,
              21.012191366896282
            ],
            [
              105.83348217025133,
              21.012263940159595
            ],
            [
              105.83336870659933,
              21.012387927958912
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 6, Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DOPP.407",
          "duongKinh": 100,
          "chieudaiQL": 60,
          "chatLieu": "G-DI",
          "from_latitude": 21.011990707706467,
          "to_latitude": 21.012387927958912,
          "to_longitude": 105.83336870659933,
          "from_longitude": 105.83374112206312
        }
      },
      {
        "id": 406,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83336870659933,
            21.012387927958912,
            105.83390921785163,
            21.012754799952898
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83336870659933,
              21.012387927958912
            ],
            [
              105.83352002720383,
              21.012511482261772
            ],
            [
              105.83359112450192,
              21.01257953429
            ],
            [
              105.83362936804114,
              21.01259660574791
            ],
            [
              105.8336298415192,
              21.012596817492472
            ],
            [
              105.83365352774516,
              21.01260373666206
            ],
            [
              105.83365428215066,
              21.01260395696561
            ],
            [
              105.83371380895252,
              21.012639004882896
            ],
            [
              105.83379323046317,
              21.012687605727454
            ],
            [
              105.83385010948011,
              21.012719075347377
            ],
            [
              105.83387681450539,
              21.012735215166224
            ],
            [
              105.83387987309835,
              21.012737063529514
            ],
            [
              105.83388390747275,
              21.012739502335805
            ],
            [
              105.83390374924987,
              21.0127514947579
            ],
            [
              105.83390921785163,
              21.012754799952898
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 6, Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DOPP.408",
          "duongKinh": 100,
          "chieudaiQL": 72,
          "chatLieu": "G-DI",
          "from_latitude": 21.012387927958912,
          "to_latitude": 21.012754799952898,
          "to_longitude": 105.83390921785163,
          "from_longitude": 105.83336870659933
        }
      },
      {
        "id": 407,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82694662353896,
            21.013424552752692,
            105.82742702392768,
            21.014244767035255
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82694662353896,
              21.013424552752692
            ],
            [
              105.82695491696842,
              21.013436542512608
            ],
            [
              105.82697293998412,
              21.013462615954673
            ],
            [
              105.82697445840617,
              21.013464801607427
            ],
            [
              105.826980893883,
              21.013475482589527
            ],
            [
              105.82698383633439,
              21.01348035474094
            ],
            [
              105.82716648099773,
              21.01380854285671
            ],
            [
              105.82742702392768,
              21.014244767035255
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DOPP.409",
          "duongKinh": 150,
          "chieudaiQL": 100,
          "chatLieu": "G-DI",
          "from_latitude": 21.013424552752692,
          "to_latitude": 21.014244767035255,
          "to_longitude": 105.82742702392768,
          "from_longitude": 105.82694662353896
        }
      },
      {
        "id": 408,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82501518717642,
            21.010141027737248,
            105.82528079602451,
            21.010540874500464
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82501518717642,
              21.010141027737248
            ],
            [
              105.82520043193858,
              21.01050541698209
            ],
            [
              105.82528079602451,
              21.010540874500464
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DOPP.410",
          "duongKinh": 150,
          "chieudaiQL": 55,
          "chatLieu": "G-DI",
          "from_latitude": 21.010141027737248,
          "to_latitude": 21.010540874500464,
          "to_longitude": 105.82528079602451,
          "from_longitude": 105.82501518717642
        }
      },
      {
        "id": 409,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82732650588713,
            21.00900724206997,
            105.82775294761299,
            21.009656870350284
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82732650588713,
              21.009656870350284
            ],
            [
              105.82746362729037,
              21.009588099460757
            ],
            [
              105.82753902260544,
              21.009549856929738
            ],
            [
              105.82757063738867,
              21.009533821752743
            ],
            [
              105.82761809935614,
              21.0095097484604
            ],
            [
              105.82775294761299,
              21.009443086594395
            ],
            [
              105.82764369985914,
              21.00922350801496
            ],
            [
              105.8276226499455,
              21.0091829182345
            ],
            [
              105.82759533883761,
              21.00913025476345
            ],
            [
              105.82752467283774,
              21.00900724206997
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 167, Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DOPP.411",
          "duongKinh": 100,
          "chieudaiQL": 104,
          "chatLieu": "G-DI",
          "from_latitude": 21.009656870350284,
          "to_latitude": 21.00900724206997,
          "to_longitude": 105.82752467283774,
          "from_longitude": 105.82732650588713
        }
      },
      {
        "id": 410,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82528079602451,
            21.009880075441284,
            105.82683874074243,
            21.010540874500464
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82528079602451,
              21.010540874500464
            ],
            [
              105.82546782139711,
              21.010466244165357
            ],
            [
              105.82572695323229,
              21.01036284010508
            ],
            [
              105.82573678730732,
              21.010358915735758
            ],
            [
              105.82590436483027,
              21.01029204498758
            ],
            [
              105.82623540348436,
              21.010147133823455
            ],
            [
              105.82627896619327,
              21.010127055830996
            ],
            [
              105.82630184277264,
              21.010116511576516
            ],
            [
              105.82644763088827,
              21.010049317078515
            ],
            [
              105.82648951842457,
              21.010031778010138
            ],
            [
              105.82652021982437,
              21.01001892320397
            ],
            [
              105.82667241100081,
              21.009955198323404
            ],
            [
              105.82683874074243,
              21.009880075441284
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Ngõ 167, Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DOPP.412",
          "duongKinh": 150,
          "chieudaiQL": 185,
          "chatLieu": "G-DI",
          "from_latitude": 21.010540874500464,
          "to_latitude": 21.009880075441284,
          "to_longitude": 105.82683874074243,
          "from_longitude": 105.82528079602451
        }
      },
      {
        "id": 411,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82484845208428,
            21.00898073662295,
            105.82751562624051,
            21.010208434566398
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82484845208428,
              21.010208434566398
            ],
            [
              105.82485009053913,
              21.010207795268684
            ],
            [
              105.82485399997582,
              21.010206267929423
            ],
            [
              105.82491609986707,
              21.010182014224537
            ],
            [
              105.82497942928556,
              21.01015727853609
            ],
            [
              105.82497949838181,
              21.010157249297844
            ],
            [
              105.82497954501379,
              21.010157333071863
            ],
            [
              105.82497962754161,
              21.010157297445904
            ],
            [
              105.82498759435165,
              21.01015385817285
            ],
            [
              105.82499826634727,
              21.010149251460284
            ],
            [
              105.82501518717642,
              21.010141027737248
            ],
            [
              105.82520439757329,
              21.01004907044151
            ],
            [
              105.82536804968015,
              21.00997716389816
            ],
            [
              105.82549548639271,
              21.010014068483446
            ],
            [
              105.82553219869105,
              21.010023919813083
            ],
            [
              105.8261517940831,
              21.00990818471817
            ],
            [
              105.8265985765881,
              21.00970049304575
            ],
            [
              105.82670497182171,
              21.009655006349657
            ],
            [
              105.82720278868615,
              21.00942751033512
            ],
            [
              105.82726873892196,
              21.009398304552583
            ],
            [
              105.82726646892836,
              21.00938593325619
            ],
            [
              105.82726138058896,
              21.009358211778316
            ],
            [
              105.82722065588742,
              21.00913630454832
            ],
            [
              105.82722694738136,
              21.00913346402697
            ],
            [
              105.82744593568327,
              21.009034583540267
            ],
            [
              105.82750224938383,
              21.0090072878847
            ],
            [
              105.82751167576929,
              21.009002718688304
            ],
            [
              105.82750611420178,
              21.00898651836597
            ],
            [
              105.82751562624051,
              21.00898073662295
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DOPP.413",
          "duongKinh": 100,
          "chieudaiQL": 328.16,
          "chatLieu": "G-DI",
          "from_latitude": 21.010208434566398,
          "to_latitude": 21.00898073662295,
          "to_longitude": 105.82751562624051,
          "from_longitude": 105.82484845208428
        }
      },
      {
        "id": 412,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82853687816394,
            21.01609577012062,
            105.82904959574655,
            21.016969286304228
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82853687816394,
              21.01609577012062
            ],
            [
              105.82871319067802,
              21.016386581851663
            ],
            [
              105.82873449835498,
              21.016422389398457
            ],
            [
              105.82878992459293,
              21.01651553548398
            ],
            [
              105.82888023560821,
              21.01666730704541
            ],
            [
              105.82898584185955,
              21.016855126713374
            ],
            [
              105.82903451323293,
              21.0169422829146
            ],
            [
              105.82904959574655,
              21.016969286304228
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DOPP.414",
          "duongKinh": 150,
          "chieudaiQL": 115,
          "chatLieu": "G-DI",
          "from_latitude": 21.01609577012062,
          "to_latitude": 21.016969286304228,
          "to_longitude": 105.82904959574655,
          "from_longitude": 105.82853687816394
        }
      },
      {
        "id": 413,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82456237926986,
            21.007752224744266,
            105.82806731625327,
            21.010153809516485
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82806731625327,
              21.007752224744266
            ],
            [
              105.82805953794004,
              21.007755098723152
            ],
            [
              105.82802875264336,
              21.007764669166974
            ],
            [
              105.82802872769976,
              21.00776468103028
            ],
            [
              105.82796043221012,
              21.007786725976672
            ],
            [
              105.82787941680276,
              21.007823958207037
            ],
            [
              105.8274842912419,
              21.00800094510853
            ],
            [
              105.82727913843803,
              21.00809164196146
            ],
            [
              105.82727911349433,
              21.008091653824664
            ],
            [
              105.82709850385513,
              21.008171499917754
            ],
            [
              105.8270984789114,
              21.00817151178093
            ],
            [
              105.82707954502489,
              21.008179880971166
            ],
            [
              105.82705314486967,
              21.008191552512613
            ],
            [
              105.82675409489423,
              21.00833432265305
            ],
            [
              105.82675406995044,
              21.008334334516174
            ],
            [
              105.82669852255225,
              21.008360853941472
            ],
            [
              105.82652899338164,
              21.008432165221773
            ],
            [
              105.82652896843781,
              21.008432177084867
            ],
            [
              105.82645863720462,
              21.008461760626805
            ],
            [
              105.82645861226075,
              21.00846177248987
            ],
            [
              105.82645271796854,
              21.008464250666204
            ],
            [
              105.82641475826527,
              21.008480217995267
            ],
            [
              105.82629833619855,
              21.008533072027326
            ],
            [
              105.82629831125467,
              21.008533083890402
            ],
            [
              105.82623509594316,
              21.008561781950636
            ],
            [
              105.82614488523056,
              21.00860273686302
            ],
            [
              105.8261448602867,
              21.00860274872606
            ],
            [
              105.82611163489082,
              21.008617831327243
            ],
            [
              105.82603118752834,
              21.0086511345185
            ],
            [
              105.8260311625844,
              21.00865114638152
            ],
            [
              105.8260145913298,
              21.0086580055347
            ],
            [
              105.8258319446352,
              21.00873535013676
            ],
            [
              105.82576435378661,
              21.00876148061217
            ],
            [
              105.82565182032504,
              21.008811035726765
            ],
            [
              105.82565179538105,
              21.008811047589745
            ],
            [
              105.82564399407411,
              21.008814481572884
            ],
            [
              105.82564396913014,
              21.008814493435843
            ],
            [
              105.82534628162693,
              21.0089455803469
            ],
            [
              105.82534625668292,
              21.008945592209823
            ],
            [
              105.82519021402365,
              21.009014305935487
            ],
            [
              105.8251901890796,
              21.009014317798407
            ],
            [
              105.82504964624746,
              21.00907620461592
            ],
            [
              105.82499537021683,
              21.00911275579882
            ],
            [
              105.82483551978963,
              21.009182126248824
            ],
            [
              105.8248354948455,
              21.009182138111683
            ],
            [
              105.82479937424092,
              21.00919781256145
            ],
            [
              105.82458266967275,
              21.009291856300813
            ],
            [
              105.82456237926986,
              21.00934224268265
            ],
            [
              105.82461179661462,
              21.00944530077106
            ],
            [
              105.82463248215464,
              21.009491313878513
            ],
            [
              105.82469473665083,
              21.009629813772115
            ],
            [
              105.82469874678206,
              21.00963873357413
            ],
            [
              105.82470756367906,
              21.009658355899774
            ],
            [
              105.82479312500315,
              21.009805572739616
            ],
            [
              105.82481663211225,
              21.009846023621215
            ],
            [
              105.82491190021665,
              21.009976445710205
            ],
            [
              105.82497234685255,
              21.010092910995585
            ],
            [
              105.82498569592653,
              21.01014990221111
            ],
            [
              105.82498757003653,
              21.010153809516485
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Chùa Bộc",
          "maTaiSan": "04.O16B.DOPP.415",
          "duongKinh": 100,
          "chieudaiQL": 470,
          "chatLieu": "G-DI",
          "from_latitude": 21.007752224744266,
          "to_latitude": 21.010153809516485,
          "to_longitude": 105.82498757003653,
          "from_longitude": 105.82806731625327
        }
      },
      {
        "id": 414,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82799456169808,
            21.00759151198737,
            105.82807648748815,
            21.00776468103028
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82804185511583,
              21.00759151198737
            ],
            [
              105.82807583465299,
              21.00766244412792
            ],
            [
              105.82807648748815,
              21.007663786758844
            ],
            [
              105.82799456169808,
              21.007701968246597
            ],
            [
              105.82801425405677,
              21.007740254142707
            ],
            [
              105.82802153856994,
              21.007754399356166
            ],
            [
              105.82802872769976,
              21.00776468103028
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Chùa Bộc",
          "maTaiSan": "04.O16B.DOPP.416",
          "duongKinh": 150,
          "chieudaiQL": 23.91,
          "chatLieu": "G-DI",
          "from_latitude": 21.00759151198737,
          "to_latitude": 21.00776468103028,
          "to_longitude": 105.82802872769976,
          "from_longitude": 105.82804185511583
        }
      },
      {
        "id": 415,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82806731625327,
            21.00638829090945,
            105.831075167656,
            21.007752224744266
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.831075167656,
              21.00638829090945
            ],
            [
              105.83098234157926,
              21.006402353986886
            ],
            [
              105.8308081502634,
              21.006455345860385
            ],
            [
              105.83080812532023,
              21.006455357724086
            ],
            [
              105.83056307021143,
              21.006570544587763
            ],
            [
              105.83016073267872,
              21.006759588362474
            ],
            [
              105.8301253379021,
              21.006777763214547
            ],
            [
              105.83012531295884,
              21.006777775078156
            ],
            [
              105.82989373699469,
              21.006896688805412
            ],
            [
              105.82964533363221,
              21.007014410710642
            ],
            [
              105.82961781767892,
              21.007027744596684
            ],
            [
              105.82961779273559,
              21.007027756460214
            ],
            [
              105.82952514998887,
              21.007072648590743
            ],
            [
              105.82925646818234,
              21.007178812647496
            ],
            [
              105.8292090151845,
              21.007200672523453
            ],
            [
              105.82920899024103,
              21.00720068438693
            ],
            [
              105.82898251204927,
              21.007305013865455
            ],
            [
              105.82896103239881,
              21.007327509476326
            ],
            [
              105.82845242714149,
              21.00755850061639
            ],
            [
              105.82826565842137,
              21.00764418925941
            ],
            [
              105.82807482096909,
              21.007749623064292
            ],
            [
              105.82806731625327,
              21.007752224744266
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Chùa Bộc",
          "maTaiSan": "04.O16B.DOPP.417",
          "duongKinh": 150,
          "chieudaiQL": 353,
          "chatLieu": "G-DI",
          "from_latitude": 21.00638829090945,
          "to_latitude": 21.007752224744266,
          "to_longitude": 105.82806731625327,
          "from_longitude": 105.831075167656
        }
      },
      {
        "id": 416,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82783002540397,
            21.008238235539988,
            105.82827946145105,
            21.008929103696087
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82783002540397,
              21.008929103696087
            ],
            [
              105.82784456994065,
              21.008921905581808
            ],
            [
              105.82784754369675,
              21.00892043421106
            ],
            [
              105.82786357894953,
              21.008912499036303
            ],
            [
              105.82793267473868,
              21.00887990053854
            ],
            [
              105.82797570367336,
              21.008860468722855
            ],
            [
              105.82823736104662,
              21.0087311612877
            ],
            [
              105.82824429653283,
              21.008719005331326
            ],
            [
              105.82826130674722,
              21.008689223554576
            ],
            [
              105.82825537646306,
              21.00866792543653
            ],
            [
              105.82822625400443,
              21.00856333265566
            ],
            [
              105.8281205745226,
              21.008376457276512
            ],
            [
              105.82812904937649,
              21.008341009559203
            ],
            [
              105.828246006644,
              21.008261714253294
            ],
            [
              105.82827946145105,
              21.008238235539988
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Chùa Bộc",
          "maTaiSan": "04.O16B.DOPP.418",
          "duongKinh": 100,
          "chieudaiQL": 105,
          "chatLieu": "G-DI",
          "from_latitude": 21.008929103696087,
          "to_latitude": 21.008238235539988,
          "to_longitude": 105.82827946145105,
          "from_longitude": 105.82783002540397
        }
      },
      {
        "id": 417,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83110348985038,
            21.014632539712874,
            105.8313644756751,
            21.015478528415226
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83124469649002,
              21.014634772439678
            ],
            [
              105.83124370587953,
              21.014635498956558
            ],
            [
              105.83123848554158,
              21.014639328846684
            ],
            [
              105.83119976290874,
              21.014632539712874
            ],
            [
              105.83117497173878,
              21.014650368560183
            ],
            [
              105.83116428680896,
              21.01465977907675
            ],
            [
              105.83114824044311,
              21.0146753324261
            ],
            [
              105.83113149536149,
              21.014690648929406
            ],
            [
              105.83112680081942,
              21.01469458463466
            ],
            [
              105.83112466563445,
              21.014696375322252
            ],
            [
              105.83111114405772,
              21.01470771272399
            ],
            [
              105.83111048313927,
              21.01471811659159
            ],
            [
              105.83110383730563,
              21.01482265944562
            ],
            [
              105.83110378032002,
              21.014854562543402
            ],
            [
              105.83110348985038,
              21.015016375862658
            ],
            [
              105.83111495838442,
              21.0150725176092
            ],
            [
              105.83112854127639,
              21.015095156530677
            ],
            [
              105.83116297764805,
              21.015124166086622
            ],
            [
              105.83120287553218,
              21.01515953294805
            ],
            [
              105.83115796874588,
              21.015221882493172
            ],
            [
              105.83121102871114,
              21.01529038670417
            ],
            [
              105.83122648891835,
              21.01531034651058
            ],
            [
              105.83122899054139,
              21.015313577769778
            ],
            [
              105.83122933545361,
              21.015314022277842
            ],
            [
              105.83123066183221,
              21.01531573463493
            ],
            [
              105.83128891015717,
              21.015390937980808
            ],
            [
              105.83132477699462,
              21.015439380625825
            ],
            [
              105.83133786773512,
              21.01545877483819
            ],
            [
              105.83133812088111,
              21.015459150246095
            ],
            [
              105.83135922175423,
              21.015473353318793
            ],
            [
              105.8313644756751,
              21.015478528415226
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngách 108, Ngõ Xã Đàn 2",
          "maTaiSan": "04.O16B.DOPP.419",
          "duongKinh": 100,
          "chieudaiQL": 112,
          "chatLieu": "G-DI",
          "from_latitude": 21.014634772439678,
          "to_latitude": 21.015478528415226,
          "to_longitude": 105.8313644756751,
          "from_longitude": 105.83124469649002
        }
      },
      {
        "id": 418,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82498757003653,
            21.010153809516485,
            105.82695706705842,
            21.01343500651352
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82498757003653,
              21.010153809516485
            ],
            [
              105.82498759435165,
              21.01015385817285
            ],
            [
              105.82498860317168,
              21.010155918058377
            ],
            [
              105.82500137168063,
              21.01018411992652
            ],
            [
              105.82519182655965,
              21.01051203149429
            ],
            [
              105.82525318139375,
              21.01053488455096
            ],
            [
              105.82527115743629,
              21.010567926821963
            ],
            [
              105.82520186131596,
              21.01062416449153
            ],
            [
              105.82521305893444,
              21.01064551304809
            ],
            [
              105.82522326598702,
              21.01066497324449
            ],
            [
              105.82524345955834,
              21.01070174683159
            ],
            [
              105.82531499834714,
              21.010831121284124
            ],
            [
              105.82532356397232,
              21.010857321182918
            ],
            [
              105.82533691452669,
              21.010879921095068
            ],
            [
              105.8253438752694,
              21.01089170515038
            ],
            [
              105.8254503390681,
              21.0110719279859
            ],
            [
              105.82546648114618,
              21.011099252671155
            ],
            [
              105.82549840144935,
              21.011145986117146
            ],
            [
              105.82552317104557,
              21.011182250626174
            ],
            [
              105.82552957605046,
              21.011191626657066
            ],
            [
              105.82565477698516,
              21.011374927862438
            ],
            [
              105.82567697538035,
              21.011396669197527
            ],
            [
              105.8256821475738,
              21.011401737394117
            ],
            [
              105.82568448995079,
              21.011404031965117
            ],
            [
              105.82568483538437,
              21.01140457583774
            ],
            [
              105.82572020879152,
              21.01146016939079
            ],
            [
              105.82580904211967,
              21.01159679748788
            ],
            [
              105.82582450980779,
              21.011620588372104
            ],
            [
              105.82584724082923,
              21.01165554943016
            ],
            [
              105.82585369507868,
              21.011665476176344
            ],
            [
              105.82592534081112,
              21.01176953779274
            ],
            [
              105.82598937107598,
              21.011871136212594
            ],
            [
              105.82605937615773,
              21.011982218378893
            ],
            [
              105.82613926674168,
              21.012110031751956
            ],
            [
              105.82614939355582,
              21.012126234335327
            ],
            [
              105.82615328026932,
              21.01213245224208
            ],
            [
              105.8261911342606,
              21.01219301131914
            ],
            [
              105.8262281597669,
              21.012246004469365
            ],
            [
              105.82625319350966,
              21.012287023964706
            ],
            [
              105.82627053810765,
              21.012316939498326
            ],
            [
              105.8263021876312,
              21.012371524930828
            ],
            [
              105.82630691126357,
              21.01237967077033
            ],
            [
              105.82631021120702,
              21.01238536223186
            ],
            [
              105.82631298189018,
              21.01239014310179
            ],
            [
              105.82642148101174,
              21.01256574438105
            ],
            [
              105.82644559487282,
              21.012604771278163
            ],
            [
              105.82644886764805,
              21.01261006906189
            ],
            [
              105.82645043318321,
              21.012612603136493
            ],
            [
              105.82657595460394,
              21.012815758537776
            ],
            [
              105.82657905162209,
              21.01282092981752
            ],
            [
              105.8265868786545,
              21.01283400302558
            ],
            [
              105.82661971878217,
              21.012888849960326
            ],
            [
              105.82662394976775,
              21.012895916117976
            ],
            [
              105.82672586151797,
              21.0130661216896
            ],
            [
              105.8267448961631,
              21.013097913988197
            ],
            [
              105.82683478902429,
              21.01324544187441
            ],
            [
              105.82688627203031,
              21.013321517901634
            ],
            [
              105.82693172948234,
              21.013388673128983
            ],
            [
              105.82695033207004,
              21.013422690340395
            ],
            [
              105.82695706705842,
              21.01343500651352
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DOPP.420",
          "duongKinh": 100,
          "chieudaiQL": 425,
          "chatLieu": "G-DI",
          "from_latitude": 21.010153809516485,
          "to_latitude": 21.01343500651352,
          "to_longitude": 105.82695706705842,
          "from_longitude": 105.82498757003653
        }
      },
      {
        "id": 419,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82924116559106,
            21.01218398735803,
            105.82984074203146,
            21.01385241981823
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82924116559106,
              21.01218398735803
            ],
            [
              105.8292522569713,
              21.01220339330429
            ],
            [
              105.82930813451448,
              21.01230116252106
            ],
            [
              105.82931278515599,
              21.01230930049931
            ],
            [
              105.82931676363914,
              21.012316260334146
            ],
            [
              105.82936680950122,
              21.012403824403275
            ],
            [
              105.82945194413449,
              21.012552782179135
            ],
            [
              105.82945662101777,
              21.012560966089367
            ],
            [
              105.82946253544705,
              21.01257133157935
            ],
            [
              105.82957243682442,
              21.012763607383757
            ],
            [
              105.82959480334439,
              21.0128027407804
            ],
            [
              105.82960038162156,
              21.012812500936064
            ],
            [
              105.82960095760858,
              21.012813507934045
            ],
            [
              105.82960214650413,
              21.01281558858868
            ],
            [
              105.82971016980487,
              21.01300459383325
            ],
            [
              105.82984074203146,
              21.013236245014273
            ],
            [
              105.82966364227977,
              21.013332031379818
            ],
            [
              105.82962055773274,
              21.01335533423576
            ],
            [
              105.82960031880869,
              21.01336628065974
            ],
            [
              105.82945278834455,
              21.01344607373258
            ],
            [
              105.82943472836998,
              21.01345584164417
            ],
            [
              105.82947784454333,
              21.013526447956757
            ],
            [
              105.82950358208099,
              21.01356859976328
            ],
            [
              105.82951772344904,
              21.013591757266493
            ],
            [
              105.8295784650064,
              21.0136912296124
            ],
            [
              105.82963334546142,
              21.013781104837484
            ],
            [
              105.8296351131404,
              21.01378400008839
            ],
            [
              105.82965180072661,
              21.013811324387845
            ],
            [
              105.8296768947645,
              21.01385241981823
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Trần Hữu Tước",
          "maTaiSan": "04.O16B.DOPP.421",
          "duongKinh": 100,
          "chieudaiQL": 231.45,
          "chatLieu": "G-CI",
          "from_latitude": 21.01218398735803,
          "to_latitude": 21.01385241981823,
          "to_longitude": 105.8296768947645,
          "from_longitude": 105.82924116559106
        }
      },
      {
        "id": 420,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82754454373176,
            21.01002108361214,
            105.82784394932072,
            21.01016493447304
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82754454373176,
              21.01016493447304
            ],
            [
              105.8277002632263,
              21.01009011887164
            ],
            [
              105.82773498819896,
              21.010073434469195
            ],
            [
              105.82779241543118,
              21.01004584155264
            ],
            [
              105.82779821763503,
              21.010043053970566
            ],
            [
              105.82780778340722,
              21.01003845878834
            ],
            [
              105.82781766288507,
              21.01003371214389
            ],
            [
              105.82784394932072,
              21.01002108361214
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DOPP.422",
          "duongKinh": 150,
          "chieudaiQL": 31.9,
          "chatLieu": "G",
          "from_latitude": 21.01016493447304,
          "to_latitude": 21.01002108361214,
          "to_longitude": 105.82784394932072,
          "from_longitude": 105.82754454373176
        }
      },
      {
        "id": 421,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82700851341001,
            21.0109580208756,
            105.82773910547165,
            21.011265141150933
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82700851341001,
              21.011265141150933
            ],
            [
              105.82701499634008,
              21.011259843832665
            ],
            [
              105.82701647992099,
              21.01125955210575
            ],
            [
              105.82701748601593,
              21.011258842699288
            ],
            [
              105.82701872654471,
              21.011257560411146
            ],
            [
              105.82702338834565,
              21.011252744331173
            ],
            [
              105.82705632281075,
              21.011229295468
            ],
            [
              105.8270949197511,
              21.01120579649426
            ],
            [
              105.82709832059783,
              21.011203967188354
            ],
            [
              105.82709835896367,
              21.011203947130895
            ],
            [
              105.82713167217683,
              21.011186041318975
            ],
            [
              105.82739147064292,
              21.011046886308325
            ],
            [
              105.8273915013435,
              21.011046871707425
            ],
            [
              105.82739152628768,
              21.011046859844193
            ],
            [
              105.82754695686558,
              21.01096360782767
            ],
            [
              105.82755857379229,
              21.0109580208756
            ],
            [
              105.82759116189047,
              21.01100625543925
            ],
            [
              105.82760976967243,
              21.011037059790922
            ],
            [
              105.82766025276173,
              21.011120270544712
            ],
            [
              105.82767256197621,
              21.011135205130252
            ],
            [
              105.82773907665,
              21.01113583448512
            ],
            [
              105.82773910547165,
              21.01113582802231
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DOPP.423",
          "duongKinh": 100,
          "chieudaiQL": 96.88,
          "chatLieu": "G-DI",
          "from_latitude": 21.011265141150933,
          "to_latitude": 21.01113582802231,
          "to_longitude": 105.82773910547165,
          "from_longitude": 105.82700851341001
        }
      },
      {
        "id": 422,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82822081848273,
            21.010794521993475,
            105.83019576925476,
            21.011836386331506
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82822081848273,
              21.011836386331506
            ],
            [
              105.82822084148829,
              21.01183637176791
            ],
            [
              105.8282258705772,
              21.011833098384688
            ],
            [
              105.82830417938223,
              21.011775675762888
            ],
            [
              105.82830757202059,
              21.01177323137599
            ],
            [
              105.82848498524352,
              21.011680373165312
            ],
            [
              105.82848501018776,
              21.011680361301917
            ],
            [
              105.82848629542976,
              21.01167968756001
            ],
            [
              105.82848632037397,
              21.011679675696634
            ],
            [
              105.82848895895742,
              21.011678292655425
            ],
            [
              105.82848898390164,
              21.011678280792033
            ],
            [
              105.82849285019337,
              21.011676256805117
            ],
            [
              105.82883906096744,
              21.01149504824853
            ],
            [
              105.82883908591155,
              21.011495036385085
            ],
            [
              105.82886746199536,
              21.01148018370678
            ],
            [
              105.82919108326102,
              21.01131079933199
            ],
            [
              105.82922255770858,
              21.01129457665858
            ],
            [
              105.82922258265269,
              21.01129456479509
            ],
            [
              105.8292496439216,
              21.01128061717209
            ],
            [
              105.82962335425408,
              21.01108800981758
            ],
            [
              105.82962337919813,
              21.01108799795404
            ],
            [
              105.82964966441564,
              21.011074449665834
            ],
            [
              105.82986248111514,
              21.010964763758338
            ],
            [
              105.82986250605911,
              21.01096475189474
            ],
            [
              105.82992289048053,
              21.01093061713099
            ],
            [
              105.82993991111425,
              21.010920996050025
            ],
            [
              105.83019576925476,
              21.010794521993475
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DOPP.424",
          "duongKinh": 150,
          "chieudaiQL": 234.31,
          "chatLieu": "G",
          "from_latitude": 21.011836386331506,
          "to_latitude": 21.010794521993475,
          "to_longitude": 105.83019576925476,
          "from_longitude": 105.82822081848273
        }
      },
      {
        "id": 423,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8282012430411,
            21.01044670846785,
            105.82990839333453,
            21.011804756701054
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8282012430411,
              21.011804756701054
            ],
            [
              105.8282078309229,
              21.011800527343794
            ],
            [
              105.82829070781499,
              21.011769756565542
            ],
            [
              105.82835896117638,
              21.01173244984121
            ],
            [
              105.82860981331825,
              21.011595335375876
            ],
            [
              105.8286248046676,
              21.011587141466364
            ],
            [
              105.828848637984,
              21.011464794108008
            ],
            [
              105.82888971259013,
              21.011442343052245
            ],
            [
              105.82907366778079,
              21.011341793021018
            ],
            [
              105.82925367449855,
              21.011250019718148
            ],
            [
              105.82926893930951,
              21.011242237195088
            ],
            [
              105.82965562268791,
              21.01104509106338
            ],
            [
              105.82966095682077,
              21.011042371635686
            ],
            [
              105.82970109853218,
              21.011021905712635
            ],
            [
              105.8298469694116,
              21.01094389811572
            ],
            [
              105.82990839333453,
              21.010910227965105
            ],
            [
              105.82990188647827,
              21.0109000487319
            ],
            [
              105.82984976836288,
              21.010792214774874
            ],
            [
              105.82979838875633,
              21.010669715130796
            ],
            [
              105.82979625208716,
              21.010664621420666
            ],
            [
              105.82973598934404,
              21.01058246791634
            ],
            [
              105.82965764654779,
              21.01044670846785
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DOPP.425",
          "duongKinh": 150,
          "chieudaiQL": 261.53,
          "chatLieu": "G",
          "from_latitude": 21.011804756701054,
          "to_latitude": 21.01044670846785,
          "to_longitude": 105.82965764654779,
          "from_longitude": 105.8282012430411
        }
      },
      {
        "id": 424,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82754454373176,
            21.01016493447304,
            105.8282012430411,
            21.01185040357551
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8282012430411,
              21.011804756701054
            ],
            [
              105.82819365536189,
              21.011808047956386
            ],
            [
              105.82818308846858,
              21.011824725974
            ],
            [
              105.82814156300735,
              21.01185040357551
            ],
            [
              105.828129439867,
              21.01183208012147
            ],
            [
              105.82807733019773,
              21.011753321316217
            ],
            [
              105.82806757291708,
              21.01173857395744
            ],
            [
              105.82806585698152,
              21.011735981016958
            ],
            [
              105.82805476594189,
              21.011719216921687
            ],
            [
              105.82793405870908,
              21.01153677726455
            ],
            [
              105.82792465639683,
              21.01151993720069
            ],
            [
              105.82792282218392,
              21.011516652962847
            ],
            [
              105.82792186329276,
              21.01151493427301
            ],
            [
              105.82777368147389,
              21.011249531357475
            ],
            [
              105.82772932330792,
              21.011170082590876
            ],
            [
              105.82773907665,
              21.01113583448512
            ],
            [
              105.8277485568175,
              21.0111024602242
            ],
            [
              105.8277893429825,
              21.011028937907998
            ],
            [
              105.82779386800593,
              21.011020781439306
            ],
            [
              105.82779682245295,
              21.011015454289804
            ],
            [
              105.82779893400595,
              21.011011648660865
            ],
            [
              105.8278011407953,
              21.01100767005236
            ],
            [
              105.82781684550888,
              21.010979362411717
            ],
            [
              105.82788123539277,
              21.010886406136663
            ],
            [
              105.82789348885875,
              21.010860034636377
            ],
            [
              105.8278967695263,
              21.010852989768235
            ],
            [
              105.82790261396008,
              21.010840418230156
            ],
            [
              105.82790796809903,
              21.010811162876173
            ],
            [
              105.82790359954257,
              21.010803395611376
            ],
            [
              105.82788664599803,
              21.010773249824492
            ],
            [
              105.82788194131881,
              21.01076488354895
            ],
            [
              105.82784293527084,
              21.010707047356696
            ],
            [
              105.82781733729863,
              21.010661247351372
            ],
            [
              105.8278018079325,
              21.010633462885384
            ],
            [
              105.82775435865403,
              21.010538023079658
            ],
            [
              105.82774519413167,
              21.010521726490342
            ],
            [
              105.82774156192721,
              21.010515266220043
            ],
            [
              105.82773796375264,
              21.010508871719654
            ],
            [
              105.82773763638032,
              21.010508289827932
            ],
            [
              105.82773555070605,
              21.010504580487623
            ],
            [
              105.82773301332321,
              21.010500069473164
            ],
            [
              105.8276171805719,
              21.01029409844737
            ],
            [
              105.82760463648691,
              21.010271795572955
            ],
            [
              105.82759954520631,
              21.010262741103556
            ],
            [
              105.82759736045098,
              21.010258855210786
            ],
            [
              105.82759597324893,
              21.01025638892375
            ],
            [
              105.82759594896287,
              21.010256345687015
            ],
            [
              105.82759227792282,
              21.01024981785988
            ],
            [
              105.82754454373176,
              21.01016493447304
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DOPP.426",
          "duongKinh": 150,
          "chieudaiQL": 221.25,
          "chatLieu": "G-DI",
          "from_latitude": 21.011804756701054,
          "to_latitude": 21.01016493447304,
          "to_longitude": 105.82754454373176,
          "from_longitude": 105.8282012430411
        }
      },
      {
        "id": 425,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83005989358381,
            21.01014693151126,
            105.83025212517074,
            21.010794521993475
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83019576925476,
              21.010794521993475
            ],
            [
              105.8301920307534,
              21.010788341394278
            ],
            [
              105.83015147298835,
              21.01070647035863
            ],
            [
              105.83014897674177,
              21.01070227982697
            ],
            [
              105.83014825806802,
              21.010699648623266
            ],
            [
              105.83014567218528,
              21.010690036464045
            ],
            [
              105.83012797096977,
              21.010557521134135
            ],
            [
              105.83023392161735,
              21.010502364481376
            ],
            [
              105.83023395999257,
              21.01050234622961
            ],
            [
              105.83025208776249,
              21.010492908491308
            ],
            [
              105.83025212517074,
              21.01049288934105
            ],
            [
              105.83023310704154,
              21.010458413872477
            ],
            [
              105.83023199936991,
              21.01045640779285
            ],
            [
              105.83023029995194,
              21.01045332705193
            ],
            [
              105.83021946798996,
              21.01043369138363
            ],
            [
              105.83017699992806,
              21.01035302915266
            ],
            [
              105.83017270801054,
              21.010344875897328
            ],
            [
              105.83015108478168,
              21.010315387192325
            ],
            [
              105.830149755983,
              21.01031357457797
            ],
            [
              105.83014623975228,
              21.01030723031203
            ],
            [
              105.83014140116883,
              21.010298499851647
            ],
            [
              105.83012755150452,
              21.01027464909139
            ],
            [
              105.83012123123176,
              21.010263767148718
            ],
            [
              105.8301099765916,
              21.010242488759825
            ],
            [
              105.8301047270786,
              21.01023354804478
            ],
            [
              105.83009695575116,
              21.010220313953585
            ],
            [
              105.83009454199608,
              21.010215384179386
            ],
            [
              105.8300700482845,
              21.010165343011213
            ],
            [
              105.83006594597815,
              21.0101579041827
            ],
            [
              105.83006442632312,
              21.010155149530725
            ],
            [
              105.83006386179652,
              21.010154126219838
            ],
            [
              105.83005989358381,
              21.01014693151126
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Hồ Đắc Di",
          "maTaiSan": "04.O16B.DOPP.427",
          "duongKinh": 100,
          "chieudaiQL": 86.75,
          "chatLieu": "G",
          "from_latitude": 21.010794521993475,
          "to_latitude": 21.01014693151126,
          "to_longitude": 105.83005989358381,
          "from_longitude": 105.83019576925476
        }
      },
      {
        "id": 426,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83019576925476,
            21.010794521993475,
            105.83033182053896,
            21.011104976090888
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83019576925476,
              21.010794521993475
            ],
            [
              105.8301958333436,
              21.01079463097112
            ],
            [
              105.83019585666864,
              21.01079467421216
            ],
            [
              105.83019905066095,
              21.01080084211304
            ],
            [
              105.83021995650353,
              21.01086407430887
            ],
            [
              105.8302210614012,
              21.010866969176135
            ],
            [
              105.8302226938631,
              21.010870812566164
            ],
            [
              105.83027971188262,
              21.011005003910103
            ],
            [
              105.83028427067563,
              21.011013751085013
            ],
            [
              105.83028843666524,
              21.011021740563553
            ],
            [
              105.83028996891096,
              21.0110246821192
            ],
            [
              105.8302903587743,
              21.011025429894282
            ],
            [
              105.83029328130164,
              21.01103103731113
            ],
            [
              105.83033182053896,
              21.011104976090888
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Hồ Đắc Di",
          "maTaiSan": "04.O16B.DOPP.428",
          "duongKinh": 100,
          "chieudaiQL": 36.6,
          "chatLieu": "G",
          "from_latitude": 21.010794521993475,
          "to_latitude": 21.011104976090888,
          "to_longitude": 105.83033182053896,
          "from_longitude": 105.83019576925476
        }
      },
      {
        "id": 427,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82883906096744,
            21.01149504824853,
            105.82905301858656,
            21.011891525585572
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82883906096744,
              21.01149504824853
            ],
            [
              105.82884393618284,
              21.01150136153459
            ],
            [
              105.82885682708508,
              21.01153014958598
            ],
            [
              105.82888823542142,
              21.01158377466276
            ],
            [
              105.8288921944811,
              21.011590534087645
            ],
            [
              105.82892464884858,
              21.011662338709268
            ],
            [
              105.82892693383039,
              21.011666248481667
            ],
            [
              105.82892750095166,
              21.01166721939647
            ],
            [
              105.82892820305767,
              21.011668421782733
            ],
            [
              105.82892992382897,
              21.01167136514223
            ],
            [
              105.82893062108069,
              21.011672559423122
            ],
            [
              105.82893524931714,
              21.011680479845104
            ],
            [
              105.82900560091765,
              21.01180655170131
            ],
            [
              105.82900661909201,
              21.011808377579637
            ],
            [
              105.82900757410493,
              21.011810088152767
            ],
            [
              105.8290085981006,
              21.011811923034983
            ],
            [
              105.82902465075695,
              21.011840690247162
            ],
            [
              105.82903526088158,
              21.011859703844895
            ],
            [
              105.82903945791168,
              21.01186722533221
            ],
            [
              105.82904107356724,
              21.011870119525124
            ],
            [
              105.82904622464986,
              21.011879351589826
            ],
            [
              105.82905301858656,
              21.011891525585572
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DOPP.429",
          "duongKinh": 100,
          "chieudaiQL": 48.13,
          "chatLieu": "G",
          "from_latitude": 21.01149504824853,
          "to_latitude": 21.011891525585572,
          "to_longitude": 105.82905301858656,
          "from_longitude": 105.82883906096744
        }
      },
      {
        "id": 428,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82922255770858,
            21.01129457665858,
            105.82944718586369,
            21.011670928195112
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82922255770858,
              21.01129457665858
            ],
            [
              105.8292281061582,
              21.011301565878572
            ],
            [
              105.82926724342411,
              21.011364744574895
            ],
            [
              105.8292808469456,
              21.01138670524777
            ],
            [
              105.82931763046416,
              21.011462587532485
            ],
            [
              105.82931974481481,
              21.011466950647304
            ],
            [
              105.82932350450574,
              21.011474705485217
            ],
            [
              105.82932750843115,
              21.011482965842394
            ],
            [
              105.82939215721034,
              21.011594097070585
            ],
            [
              105.82940653405677,
              21.011616389059082
            ],
            [
              105.82940751786272,
              21.011617913425034
            ],
            [
              105.82940926136652,
              21.01162061731379
            ],
            [
              105.82941354852402,
              21.01162269009389
            ],
            [
              105.82941413125126,
              21.01162352544704
            ],
            [
              105.82944718586369,
              21.011670928195112
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DOPP.430",
          "duongKinh": 100,
          "chieudaiQL": 47.32,
          "chatLieu": "G",
          "from_latitude": 21.01129457665858,
          "to_latitude": 21.011670928195112,
          "to_longitude": 105.82944718586369,
          "from_longitude": 105.82922255770858
        }
      },
      {
        "id": 429,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82992289048053,
            21.01093061713099,
            105.83021672748681,
            21.011454066312435
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82992289048053,
              21.01093061713099
            ],
            [
              105.82992689781325,
              21.010938620941303
            ],
            [
              105.82994000768845,
              21.010969580098127
            ],
            [
              105.82999008191771,
              21.01105725134535
            ],
            [
              105.82999248494858,
              21.01106145768866
            ],
            [
              105.82999397009182,
              21.011064058057503
            ],
            [
              105.82999673348888,
              21.01106889670751
            ],
            [
              105.83003453616207,
              21.01113508224759
            ],
            [
              105.8300456082078,
              21.01115446746385
            ],
            [
              105.83008089133367,
              21.01121624308874
            ],
            [
              105.83008360130817,
              21.011220987159003
            ],
            [
              105.83013184380022,
              21.01130545090597
            ],
            [
              105.83016140786015,
              21.011357212347928
            ],
            [
              105.83016777097515,
              21.011368352399913
            ],
            [
              105.83017048095532,
              21.01137309646851
            ],
            [
              105.83021672748681,
              21.011454066312435
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Hồ Đắc Di",
          "maTaiSan": "04.O16B.DOPP.431",
          "duongKinh": 100,
          "chieudaiQL": 65.21,
          "chatLieu": "G",
          "from_latitude": 21.01093061713099,
          "to_latitude": 21.011454066312435,
          "to_longitude": 105.83021672748681,
          "from_longitude": 105.82992289048053
        }
      },
      {
        "id": 430,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8282012430411,
            21.011804756701054,
            105.82839987205234,
            21.012141959413977
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82839987205234,
              21.012141959413977
            ],
            [
              105.8283439627254,
              21.012055041390756
            ],
            [
              105.82830674399536,
              21.011995399207056
            ],
            [
              105.82823774783654,
              21.01186699374237
            ],
            [
              105.82822446672337,
              21.01184225670442
            ],
            [
              105.82822081848273,
              21.011836386331506
            ],
            [
              105.8282012430411,
              21.011804756701054
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D200_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DOPP.432",
          "duongKinh": 200,
          "chieudaiQL": 39.63,
          "chatLieu": "G",
          "from_latitude": 21.012141959413977,
          "to_latitude": 21.011804756701054,
          "to_longitude": 105.8282012430411,
          "from_longitude": 105.82839987205234
        }
      },
      {
        "id": 431,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82802087272911,
            21.011451997120123,
            105.82835896117638,
            21.01173244984121
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82835896117638,
              21.01173244984121
            ],
            [
              105.8283429358493,
              21.011714008109102
            ],
            [
              105.82828682496266,
              21.01164943504521
            ],
            [
              105.8282847436278,
              21.01164703988576
            ],
            [
              105.82828341835322,
              21.011645515368752
            ],
            [
              105.82821623753459,
              21.011543773314987
            ],
            [
              105.8280533155764,
              21.011466548280097
            ],
            [
              105.8280493858191,
              21.01146474198392
            ],
            [
              105.8280472287029,
              21.01146377790031
            ],
            [
              105.82802934401667,
              21.011455783769016
            ],
            [
              105.82802527953613,
              21.01145396728915
            ],
            [
              105.82802087272911,
              21.011451997120123
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DOPP.433",
          "duongKinh": 100,
          "chieudaiQL": 55.98,
          "chatLieu": "G",
          "from_latitude": 21.01173244984121,
          "to_latitude": 21.011451997120123,
          "to_longitude": 105.82802087272911,
          "from_longitude": 105.82835896117638
        }
      },
      {
        "id": 432,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82829993770227,
            21.01121159017257,
            105.82888971259013,
            21.011442343052245
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82888971259013,
              21.011442343052245
            ],
            [
              105.82888095991716,
              21.01143167794513
            ],
            [
              105.8288300581728,
              21.011432143571888
            ],
            [
              105.82876632029469,
              21.01140101995453
            ],
            [
              105.82875911538812,
              21.011397501755486
            ],
            [
              105.82872934765327,
              21.011382966605957
            ],
            [
              105.8287197961512,
              21.01137901002742
            ],
            [
              105.82871696068312,
              21.011377835191603
            ],
            [
              105.82871348022263,
              21.011376393432467
            ],
            [
              105.8284402006184,
              21.011263184258457
            ],
            [
              105.82841225039225,
              21.011252903429877
            ],
            [
              105.82840916408664,
              21.011251767745485
            ],
            [
              105.82840599488945,
              21.011250601754888
            ],
            [
              105.82840373269512,
              21.011249770058342
            ],
            [
              105.82829993770227,
              21.01121159017257
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DOPP.434",
          "duongKinh": 100,
          "chieudaiQL": 64.69,
          "chatLieu": "G",
          "from_latitude": 21.011442343052245,
          "to_latitude": 21.01121159017257,
          "to_longitude": 105.82829993770227,
          "from_longitude": 105.82888971259013
        }
      },
      {
        "id": 433,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82873784517477,
            21.011009092606205,
            105.82926893930951,
            21.011242237195088
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82926893930951,
              21.011242237195088
            ],
            [
              105.82926110925803,
              21.011233690192277
            ],
            [
              105.82924588351143,
              21.011226960392897
            ],
            [
              105.8292075655639,
              21.01121002647888
            ],
            [
              105.8291060791299,
              21.011172219710918
            ],
            [
              105.8291033561299,
              21.0111712051077
            ],
            [
              105.8291003516761,
              21.011170086198394
            ],
            [
              105.82909611729394,
              21.01116850902372
            ],
            [
              105.8288007621832,
              21.011035481011685
            ],
            [
              105.82879740253803,
              21.011033968213912
            ],
            [
              105.8287946089398,
              21.011032710078428
            ],
            [
              105.82878307099978,
              21.01102751311321
            ],
            [
              105.82874074029756,
              21.011010271667494
            ],
            [
              105.82873784517477,
              21.011009092606205
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DOPP.435",
          "duongKinh": 100,
          "chieudaiQL": 59.19,
          "chatLieu": "G",
          "from_latitude": 21.011242237195088,
          "to_latitude": 21.011009092606205,
          "to_longitude": 105.82873784517477,
          "from_longitude": 105.82926893930951
        }
      },
      {
        "id": 434,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82875932468005,
            21.010733336328506,
            105.82965562268791,
            21.01104509106338
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82965562268791,
              21.01104509106338
            ],
            [
              105.82964964234706,
              21.011031668478655
            ],
            [
              105.82950211660925,
              21.01096576215244
            ],
            [
              105.82949915527989,
              21.010964439814064
            ],
            [
              105.82949653916313,
              21.01096327114763
            ],
            [
              105.82949094821092,
              21.010960772982685
            ],
            [
              105.82919608937331,
              21.010835722331468
            ],
            [
              105.82919339355968,
              21.010834578435706
            ],
            [
              105.82919212664306,
              21.010834040867344
            ],
            [
              105.82916115086257,
              21.01082090445175
            ],
            [
              105.82899768504254,
              21.01073919572962
            ],
            [
              105.8289875237941,
              21.010733336328506
            ],
            [
              105.82898071564541,
              21.01073616227111
            ],
            [
              105.82892112276562,
              21.010760902911056
            ],
            [
              105.82890569505223,
              21.010770185551266
            ],
            [
              105.82887835524478,
              21.010786635508428
            ],
            [
              105.82887571977456,
              21.01078788215296
            ],
            [
              105.82877011785024,
              21.010843088666526
            ],
            [
              105.82875932468005,
              21.010848731300733
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DOPP.436",
          "duongKinh": 100,
          "chieudaiQL": 107.49,
          "chatLieu": "G",
          "from_latitude": 21.01104509106338,
          "to_latitude": 21.010848731300733,
          "to_longitude": 105.82875932468005,
          "from_longitude": 105.82965562268791
        }
      },
      {
        "id": 435,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8290917725781,
            21.01044670846785,
            105.82965764654779,
            21.010653662464104
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82965764654779,
              21.01044670846785
            ],
            [
              105.82963578360301,
              21.01045683003232
            ],
            [
              105.82963515420893,
              21.010457122133264
            ],
            [
              105.82955420650504,
              21.01049459781655
            ],
            [
              105.82955331230131,
              21.01049501224045
            ],
            [
              105.82954503130036,
              21.01049884615974
            ],
            [
              105.82953939262131,
              21.010501456874557
            ],
            [
              105.82953226967598,
              21.010504754954088
            ],
            [
              105.82953070097425,
              21.010505480666062
            ],
            [
              105.82953058967595,
              21.01050553178929
            ],
            [
              105.8294886579226,
              21.01052494510542
            ],
            [
              105.82933713012262,
              21.01053296910939
            ],
            [
              105.82927178636311,
              21.01055374473513
            ],
            [
              105.82925167594848,
              21.01056490725912
            ],
            [
              105.8292165625499,
              21.01058439747508
            ],
            [
              105.82914562605052,
              21.010627941732263
            ],
            [
              105.82913588516993,
              21.010633920681624
            ],
            [
              105.8290917725781,
              21.010653662464104
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DOPP.437",
          "duongKinh": 100,
          "chieudaiQL": 64.28,
          "chatLieu": "G",
          "from_latitude": 21.01044670846785,
          "to_latitude": 21.010653662464104,
          "to_longitude": 105.8290917725781,
          "from_longitude": 105.82965764654779
        }
      },
      {
        "id": 436,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82965764654779,
            21.010275683357868,
            105.82998088623991,
            21.01044670846785
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82965764654779,
              21.01044670846785
            ],
            [
              105.8296707372768,
              21.0104397819305
            ],
            [
              105.82969522305018,
              21.010426826798383
            ],
            [
              105.82970108122026,
              21.0104237272663
            ],
            [
              105.82972201352936,
              21.010412651908727
            ],
            [
              105.8298393869026,
              21.010350549994065
            ],
            [
              105.82984513573227,
              21.010347508796848
            ],
            [
              105.8298609147933,
              21.010339160102482
            ],
            [
              105.82986674226623,
              21.010336076972607
            ],
            [
              105.82987910214803,
              21.010329537854957
            ],
            [
              105.82988476273306,
              21.010326542248073
            ],
            [
              105.82991161168023,
              21.010312336334565
            ],
            [
              105.82992204377506,
              21.010306817325766
            ],
            [
              105.82992288586034,
              21.010306371541272
            ],
            [
              105.82998088623991,
              21.010275683357868
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DOPP.438",
          "duongKinh": 100,
          "chieudaiQL": 38.57,
          "chatLieu": "G",
          "from_latitude": 21.01044670846785,
          "to_latitude": 21.010275683357868,
          "to_longitude": 105.82998088623991,
          "from_longitude": 105.82965764654779
        }
      },
      {
        "id": 437,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82784394932072,
            21.01002108361214,
            105.82828703593921,
            21.01081944779992
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82784394932072,
              21.01002108361214
            ],
            [
              105.82785176360673,
              21.010035341862135
            ],
            [
              105.82785500875367,
              21.010092989848577
            ],
            [
              105.82788627354252,
              21.01014774215939
            ],
            [
              105.8278876556896,
              21.010150163307234
            ],
            [
              105.82790045435857,
              21.01017257692163
            ],
            [
              105.82797422595935,
              21.010307908765157
            ],
            [
              105.82797557906851,
              21.010310122311424
            ],
            [
              105.82797696517069,
              21.01031238808427
            ],
            [
              105.82797733014132,
              21.010312985147422
            ],
            [
              105.82797970437719,
              21.010316866500116
            ],
            [
              105.82800788177894,
              21.01036294567379
            ],
            [
              105.82804964993328,
              21.01042749742576
            ],
            [
              105.82805157670896,
              21.010430475018822
            ],
            [
              105.82805240330299,
              21.01043175267446
            ],
            [
              105.82807579143484,
              21.010473999267905
            ],
            [
              105.82807852968554,
              21.01047847768656
            ],
            [
              105.82807987599989,
              21.010480679523003
            ],
            [
              105.82808126889827,
              21.010482956100503
            ],
            [
              105.82818259971184,
              21.01064866199382
            ],
            [
              105.82818415569496,
              21.010651206938014
            ],
            [
              105.82818593686845,
              21.010654119302462
            ],
            [
              105.82818762195335,
              21.01065687587643
            ],
            [
              105.82819102802702,
              21.010662445752793
            ],
            [
              105.82819376725656,
              21.010666925970998
            ],
            [
              105.82819650647627,
              21.010671404382748
            ],
            [
              105.82827497244757,
              21.01079971954522
            ],
            [
              105.82827701571098,
              21.010803061471677
            ],
            [
              105.82827900946477,
              21.01080632144553
            ],
            [
              105.82828216122887,
              21.010811475267193
            ],
            [
              105.82828385893647,
              21.01081425255282
            ],
            [
              105.82828620212914,
              21.01081808347102
            ],
            [
              105.82828703593921,
              21.01081944779992
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DOPP.439",
          "duongKinh": 150,
          "chieudaiQL": 99.76,
          "chatLieu": "G-DI",
          "from_latitude": 21.01002108361214,
          "to_latitude": 21.01081944779992,
          "to_longitude": 105.82828703593921,
          "from_longitude": 105.82784394932072
        }
      },
      {
        "id": 438,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82780778340722,
            21.01003845878834,
            105.82817867918011,
            21.010659501724916
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82780778340722,
              21.01003845878834
            ],
            [
              105.82781505426695,
              21.010050459811428
            ],
            [
              105.8278601460937,
              21.010138109009162
            ],
            [
              105.8278692936531,
              21.010155517547425
            ],
            [
              105.82787094819042,
              21.010158667177052
            ],
            [
              105.82787883394953,
              21.010173673852183
            ],
            [
              105.82788298706467,
              21.01018040345665
            ],
            [
              105.82803880524553,
              21.010432868394037
            ],
            [
              105.82804081047671,
              21.010436118379772
            ],
            [
              105.82804932443844,
              21.010449913471987
            ],
            [
              105.82805312330315,
              21.010456068537177
            ],
            [
              105.82817466192263,
              21.010652992757993
            ],
            [
              105.82817593047697,
              21.01065504774613
            ],
            [
              105.82817867918011,
              21.010659501724916
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DOPP.440",
          "duongKinh": 150,
          "chieudaiQL": 78.88,
          "chatLieu": "G-DI",
          "from_latitude": 21.01003845878834,
          "to_latitude": 21.010659501724916,
          "to_longitude": 105.82817867918011,
          "from_longitude": 105.82780778340722
        }
      },
      {
        "id": 439,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82740091352153,
            21.010004995854402,
            105.82754454373176,
            21.01016493447304
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82754454373176,
              21.01016493447304
            ],
            [
              105.82754135349819,
              21.010159251532688
            ],
            [
              105.82753634576882,
              21.01015034568709
            ],
            [
              105.82751375361255,
              21.010161517367624
            ],
            [
              105.82751092682305,
              21.010156678079092
            ],
            [
              105.82742265817804,
              21.010004995854402
            ],
            [
              105.82740091352153,
              21.01001478146246
            ],
            [
              105.8274591159597,
              21.01010803567401
            ],
            [
              105.82743077462953,
              21.010121321752795
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DOPP.441",
          "duongKinh": 150,
          "chieudaiQL": 26.74,
          "chatLieu": "G",
          "from_latitude": 21.01016493447304,
          "to_latitude": 21.010121321752795,
          "to_longitude": 105.82743077462953,
          "from_longitude": 105.82754454373176
        }
      },
      {
        "id": 440,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82962335425408,
            21.01108800981758,
            105.82984066723336,
            21.011458705263884
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82962335425408,
              21.01108800981758
            ],
            [
              105.82962766932194,
              21.011095480134678
            ],
            [
              105.82971744508906,
              21.011250086540382
            ],
            [
              105.82971861532761,
              21.011252100447145
            ],
            [
              105.82971880762369,
              21.01125243279981
            ],
            [
              105.82972375664544,
              21.011260954990597
            ],
            [
              105.82983121803157,
              21.01144368553992
            ],
            [
              105.8298318657085,
              21.011444785220004
            ],
            [
              105.82983282704167,
              21.011446419886585
            ],
            [
              105.82983401269351,
              21.011448437330156
            ],
            [
              105.82983721325026,
              21.011453879011803
            ],
            [
              105.82984066723336,
              21.011458705263884
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DOPP.442",
          "duongKinh": 100,
          "chieudaiQL": 44.79,
          "chatLieu": "G",
          "from_latitude": 21.01108800981758,
          "to_latitude": 21.011458705263884,
          "to_longitude": 105.82984066723336,
          "from_longitude": 105.82962335425408
        }
      },
      {
        "id": 441,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8284132368519,
            21.01259416578508,
            105.82874435945028,
            21.013147275796907
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8284132368519,
              21.01259416578508
            ],
            [
              105.82841468046277,
              21.012596563143976
            ],
            [
              105.82842311800894,
              21.01262087755106
            ],
            [
              105.82842896819999,
              21.012637738489396
            ],
            [
              105.82843890551354,
              21.01265545746326
            ],
            [
              105.82869341876157,
              21.01308089565016
            ],
            [
              105.82873492126302,
              21.01313546963873
            ],
            [
              105.82873555011389,
              21.013136295737077
            ],
            [
              105.82873933590344,
              21.013139717684627
            ],
            [
              105.82874069708856,
              21.013140948473993
            ],
            [
              105.82874435945028,
              21.013147275796907
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 14, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DOPP.443",
          "duongKinh": 100,
          "chieudaiQL": 68.83,
          "chatLieu": "G-DI",
          "from_latitude": 21.01259416578508,
          "to_latitude": 21.013147275796907,
          "to_longitude": 105.82874435945028,
          "from_longitude": 105.8284132368519
        }
      },
      {
        "id": 442,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82821472699254,
            21.011865889151256,
            105.82859382383901,
            21.01251079844467
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82859382383901,
              21.01251079844467
            ],
            [
              105.82844697250488,
              21.012260980311982
            ],
            [
              105.82844634910086,
              21.012259920250287
            ],
            [
              105.82832455970718,
              21.012052734170332
            ],
            [
              105.82832075616263,
              21.012046263910683
            ],
            [
              105.82823665098249,
              21.011903185930095
            ],
            [
              105.828236010103,
              21.011902096146095
            ],
            [
              105.82821472699254,
              21.011865889151256
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DOPP.444",
          "duongKinh": 100,
          "chieudaiQL": 90,
          "chatLieu": "G-DI",
          "from_latitude": 21.01251079844467,
          "to_latitude": 21.011865889151256,
          "to_longitude": 105.82821472699254,
          "from_longitude": 105.82859382383901
        }
      },
      {
        "id": 443,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82732650588713,
            21.009656870350284,
            105.82743418598227,
            21.01012766565843
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82732650588713,
              21.009656870350284
            ],
            [
              105.82733528403949,
              21.009674288845826
            ],
            [
              105.82738834049559,
              21.009779645149674
            ],
            [
              105.82735394457848,
              21.00981395886096
            ],
            [
              105.82737660691402,
              21.009856862123332
            ],
            [
              105.82743418598227,
              21.009965818356104
            ],
            [
              105.82734489467995,
              21.01001171460674
            ],
            [
              105.8273680066752,
              21.010051728970726
            ],
            [
              105.82741450151751,
              21.01012766565843
            ],
            [
              105.82743077462953,
              21.010121321752795
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 167, Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DOPP.445",
          "duongKinh": 100,
          "chieudaiQL": 54.65,
          "chatLieu": "G-DI",
          "from_latitude": 21.009656870350284,
          "to_latitude": 21.010121321752795,
          "to_longitude": 105.82743077462953,
          "from_longitude": 105.82732650588713
        }
      },
      {
        "id": 444,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82884905810657,
            21.01237865078459,
            105.82932573015715,
            21.01321089608733
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82932573015715,
              21.01321089608733
            ],
            [
              105.82932520927802,
              21.013209939400493
            ],
            [
              105.8292792106412,
              21.013125457285476
            ],
            [
              105.82925626089863,
              21.01308330630339
            ],
            [
              105.82921720700475,
              21.013016849416925
            ],
            [
              105.82917138283611,
              21.01293867544933
            ],
            [
              105.82916937013,
              21.012934954032048
            ],
            [
              105.82912578730357,
              21.012854346675777
            ],
            [
              105.82903269726103,
              21.012693640402613
            ],
            [
              105.82903240978557,
              21.012693143222638
            ],
            [
              105.82889277210867,
              21.012453630488
            ],
            [
              105.82884905810657,
              21.01237865078459
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 10, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DOPP.446",
          "duongKinh": 100,
          "chieudaiQL": 104.63,
          "chatLieu": "G-DI",
          "from_latitude": 21.01321089608733,
          "to_latitude": 21.01237865078459,
          "to_longitude": 105.82884905810657,
          "from_longitude": 105.82932573015715
        }
      },
      {
        "id": 445,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82975750554738,
            21.014983869337858,
            105.83001390327489,
            21.015053792953463
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82975750554738,
              21.015002833584074
            ],
            [
              105.82978456949463,
              21.014995173203776
            ],
            [
              105.8298238288746,
              21.014983869337858
            ],
            [
              105.82986552133615,
              21.014998363262542
            ],
            [
              105.82990454258818,
              21.01501287020686
            ],
            [
              105.83001390327489,
              21.015053792953463
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 115, Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DOPP.447",
          "duongKinh": 100,
          "chieudaiQL": 35,
          "chatLieu": "G-DI",
          "from_latitude": 21.015002833584074,
          "to_latitude": 21.015053792953463,
          "to_longitude": 105.83001390327489,
          "from_longitude": 105.82975750554738
        }
      },
      {
        "id": 446,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82937301700967,
            21.015053792953463,
            105.83001390327489,
            21.017495426473683
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82937301700967,
              21.017495426473683
            ],
            [
              105.82937572220823,
              21.01749372244723
            ],
            [
              105.82940676869478,
              21.017474161674407
            ],
            [
              105.82947636862494,
              21.01742841809378
            ],
            [
              105.82955059877622,
              21.01736639927177
            ],
            [
              105.82965473288884,
              21.017258112287056
            ],
            [
              105.82977110135465,
              21.017097571793382
            ],
            [
              105.8298024972694,
              21.017054258938874
            ],
            [
              105.8298403313638,
              21.01699319249102
            ],
            [
              105.82984853564695,
              21.016979950930295
            ],
            [
              105.82994223204231,
              21.016635215997628
            ],
            [
              105.8299427034255,
              21.01663348311836
            ],
            [
              105.829946044483,
              21.01661370605643
            ],
            [
              105.82999136966022,
              21.016345363409332
            ],
            [
              105.83000179313812,
              21.016259467313716
            ],
            [
              105.83001327733726,
              21.01616482733701
            ],
            [
              105.82998344703427,
              21.015773596301838
            ],
            [
              105.82998186630508,
              21.015752861443247
            ],
            [
              105.82997415036063,
              21.015675458349737
            ],
            [
              105.82995437059009,
              21.015477024099432
            ],
            [
              105.8299633580566,
              21.015368038627404
            ],
            [
              105.82997120884913,
              21.01527284914463
            ],
            [
              105.82997226714437,
              21.015260014568117
            ],
            [
              105.83001390327489,
              21.015053792953463
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Trần Hữu Tước",
          "maTaiSan": "04.O16B.DOPP.448",
          "duongKinh": 100,
          "chieudaiQL": 270,
          "chatLieu": "G-DI",
          "from_latitude": 21.017495426473683,
          "to_latitude": 21.015053792953463,
          "to_longitude": 105.83001390327489,
          "from_longitude": 105.82937301700967
        }
      },
      {
        "id": 447,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82950388117382,
            21.014303612001132,
            105.82991709749199,
            21.014613706207697
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82991709749199,
              21.014304679283207
            ],
            [
              105.82991212925785,
              21.014304354872724
            ],
            [
              105.8299007349469,
              21.014303612001132
            ],
            [
              105.82978925541707,
              21.01439496784352
            ],
            [
              105.82969412219879,
              21.014482564160573
            ],
            [
              105.82969188077442,
              21.014484628122833
            ],
            [
              105.82968586472312,
              21.01449016803977
            ],
            [
              105.82968461959024,
              21.0144913139824
            ],
            [
              105.82964960185897,
              21.01452355738154
            ],
            [
              105.82963479045588,
              21.01453719605468
            ],
            [
              105.82957492809507,
              21.014573812973
            ],
            [
              105.82950388117382,
              21.014613706207697
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 115, Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DOPP.449",
          "duongKinh": 100,
          "chieudaiQL": 70,
          "chatLieu": "G-DI",
          "from_latitude": 21.014304679283207,
          "to_latitude": 21.014613706207697,
          "to_longitude": 105.82950388117382,
          "from_longitude": 105.82991709749199
        }
      },
      {
        "id": 448,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82930414809977,
            21.014270483199503,
            105.82950388117382,
            21.014613706207697
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82950388117382,
              21.014613706207697
            ],
            [
              105.82942592983999,
              21.01448022098886
            ],
            [
              105.8294105501366,
              21.014453885648983
            ],
            [
              105.82939104411689,
              21.01442048190824
            ],
            [
              105.82938790374136,
              21.014414277605596
            ],
            [
              105.82938696760878,
              21.01441285416941
            ],
            [
              105.8293835732675,
              21.014407690717377
            ],
            [
              105.82936256590502,
              21.014371716485694
            ],
            [
              105.8293174015596,
              21.014293450850936
            ],
            [
              105.82930635376816,
              21.014274304834704
            ],
            [
              105.82930414809977,
              21.014270483199503
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 115, Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DOPP.450",
          "duongKinh": 100,
          "chieudaiQL": 50,
          "chatLieu": "G-DI",
          "from_latitude": 21.014613706207697,
          "to_latitude": 21.014270483199503,
          "to_longitude": 105.82930414809977,
          "from_longitude": 105.82950388117382
        }
      },
      {
        "id": 449,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82950388117382,
            21.014613706207697,
            105.82972652997285,
            21.014958772668496
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82972652997285,
              21.014958772668496
            ],
            [
              105.82971325303518,
              21.014941147747393
            ],
            [
              105.82964366528923,
              21.014861916547442
            ],
            [
              105.82961196042412,
              21.014825818388086
            ],
            [
              105.8296009990494,
              21.014816919969704
            ],
            [
              105.82958897054013,
              21.01479856546667
            ],
            [
              105.8295860407822,
              21.01479409523765
            ],
            [
              105.82958323423688,
              21.014789118601495
            ],
            [
              105.82957794366644,
              21.014779736401398
            ],
            [
              105.82953898338855,
              21.014691702910078
            ],
            [
              105.82950388117382,
              21.014613706207697
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 115, Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DOPP.451",
          "duongKinh": 100,
          "chieudaiQL": 50,
          "chatLieu": "G-DI",
          "from_latitude": 21.014958772668496,
          "to_latitude": 21.014613706207697,
          "to_longitude": 105.82950388117382,
          "from_longitude": 105.82972652997285
        }
      },
      {
        "id": 450,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82695491696842,
            21.012254975153258,
            105.828703019647,
            21.013436542512608
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82695491696842,
              21.013436542512608
            ],
            [
              105.82695706705842,
              21.01343500651352
            ],
            [
              105.82695929574021,
              21.013433418648546
            ],
            [
              105.82713332190855,
              21.0133557307693
            ],
            [
              105.82739070907216,
              21.013220137088812
            ],
            [
              105.8277373316231,
              21.013022015758462
            ],
            [
              105.82789124696693,
              21.01293404147377
            ],
            [
              105.82842008148573,
              21.01262266536785
            ],
            [
              105.82842311800894,
              21.01262087755106
            ],
            [
              105.82856796782218,
              21.012535589650096
            ],
            [
              105.82860898446282,
              21.012513033260227
            ],
            [
              105.8286126910151,
              21.0125109946933
            ],
            [
              105.828703019647,
              21.012461320835563
            ],
            [
              105.8286975138454,
              21.012452130230617
            ],
            [
              105.82869067202894,
              21.01244069168045
            ],
            [
              105.82868837978951,
              21.012436862327828
            ],
            [
              105.82868757011839,
              21.012435516851692
            ],
            [
              105.82863088640235,
              21.012340804252275
            ],
            [
              105.8285824887108,
              21.012254975153258
            ],
            [
              105.82857069203007,
              21.01226512343939
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Hồ Đắc Di",
          "maTaiSan": "04.O16B.DOPP.452",
          "duongKinh": 150,
          "chieudaiQL": 228.06,
          "chatLieu": "G-DI",
          "from_latitude": 21.013436542512608,
          "to_latitude": 21.01226512343939,
          "to_longitude": 105.82857069203007,
          "from_longitude": 105.82695491696842
        }
      },
      {
        "id": 451,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82848067075847,
            21.012022161993357,
            105.82862985554789,
            21.012082514910148
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82862985554789,
              21.012022161993357
            ],
            [
              105.8286028682654,
              21.012032994838524
            ],
            [
              105.82859692910267,
              21.012035378470863
            ],
            [
              105.82858296612346,
              21.012040983241473
            ],
            [
              105.82849424914802,
              21.01207659227836
            ],
            [
              105.82848067075847,
              21.012082514910148
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DOPP.453",
          "duongKinh": 100,
          "chieudaiQL": 16.89,
          "chatLieu": "G",
          "from_latitude": 21.012022161993357,
          "to_latitude": 21.012082514910148,
          "to_longitude": 105.82848067075847,
          "from_longitude": 105.82862985554789
        }
      },
      {
        "id": 452,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82839987205234,
            21.012082514910148,
            105.82848067075847,
            21.012141959413977
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82848067075847,
              21.012082514910148
            ],
            [
              105.82843519366482,
              21.01210434782303
            ],
            [
              105.82841117569201,
              21.012127183603464
            ],
            [
              105.82840210241585,
              21.012140856555007
            ],
            [
              105.82839987205234,
              21.012141959413977
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D250_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DOPP.454",
          "duongKinh": 250,
          "chieudaiQL": 8.64,
          "chatLieu": "G",
          "from_latitude": 21.012082514910148,
          "to_latitude": 21.012141959413977,
          "to_longitude": 105.82839987205234,
          "from_longitude": 105.82848067075847
        }
      },
      {
        "id": 453,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82848067075847,
            21.012082514910148,
            105.82857069203007,
            21.01226512343939
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82848067075847,
              21.012082514910148
            ],
            [
              105.82848730193946,
              21.012101105335926
            ],
            [
              105.82857069203007,
              21.01226512343939
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D250_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DOPP.455",
          "duongKinh": 250,
          "chieudaiQL": 4.13,
          "chatLieu": "G",
          "from_latitude": 21.012082514910148,
          "to_latitude": 21.01226512343939,
          "to_longitude": 105.82857069203007,
          "from_longitude": 105.82848067075847
        }
      },
      {
        "id": 454,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82884286760759,
            21.007034119792696,
            105.83203262684063,
            21.008774874596636
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82884286760759,
              21.008270462639587
            ],
            [
              105.82924526759177,
              21.008085414711154
            ],
            [
              105.82933020995205,
              21.008218352204313
            ],
            [
              105.82936597683826,
              21.00828395534793
            ],
            [
              105.82936835762845,
              21.00828832619871
            ],
            [
              105.82946155561471,
              21.008459278108568
            ],
            [
              105.82959653624404,
              21.008565287539003
            ],
            [
              105.82961591084722,
              21.00859854742338
            ],
            [
              105.82968539102279,
              21.008717847313566
            ],
            [
              105.82972874793548,
              21.008749951545713
            ],
            [
              105.82984435567369,
              21.008773376612687
            ],
            [
              105.82984438264093,
              21.008773382803763
            ],
            [
              105.829851675311,
              21.008774874596636
            ],
            [
              105.82998551863655,
              21.008584228250573
            ],
            [
              105.83003270170765,
              21.008550032303646
            ],
            [
              105.83003272665124,
              21.008550020440044
            ],
            [
              105.83008585443963,
              21.008511522320735
            ],
            [
              105.83018728133673,
              21.008438020688253
            ],
            [
              105.83018730628025,
              21.008438008824623
            ],
            [
              105.83028334215858,
              21.00836841821181
            ],
            [
              105.83028808621913,
              21.008366682549074
            ],
            [
              105.8304526921259,
              21.008306448893666
            ],
            [
              105.83045271706936,
              21.00830643703
            ],
            [
              105.83059441948612,
              21.00825458223435
            ],
            [
              105.8307831782971,
              21.008185507925997
            ],
            [
              105.83086427009296,
              21.008150362214867
            ],
            [
              105.83086429503636,
              21.00815035035114
            ],
            [
              105.83087921948815,
              21.008143882644468
            ],
            [
              105.83117160909441,
              21.008024112357344
            ],
            [
              105.83120778681892,
              21.00800816171971
            ],
            [
              105.83120781176233,
              21.008008149855954
            ],
            [
              105.83133123604172,
              21.007953730057466
            ],
            [
              105.83144294562412,
              21.00790507940699
            ],
            [
              105.83144297056744,
              21.007905067543188
            ],
            [
              105.83144562091346,
              21.00790391472332
            ],
            [
              105.8314456458568,
              21.00790390285952
            ],
            [
              105.83145817978541,
              21.00789844394404
            ],
            [
              105.83160280449417,
              21.007761519072446
            ],
            [
              105.83160695279788,
              21.007755214155456
            ],
            [
              105.83160697774119,
              21.007755202291644
            ],
            [
              105.83160798489659,
              21.007753651047302
            ],
            [
              105.83160800983988,
              21.007753639183477
            ],
            [
              105.83170182780258,
              21.00761156843246
            ],
            [
              105.83185863561684,
              21.0073617519298
            ],
            [
              105.83189088445816,
              21.007310348869037
            ],
            [
              105.83189090940135,
              21.00731033700517
            ],
            [
              105.8320325236512,
              21.0071420529505
            ],
            [
              105.83203262684063,
              21.00714192780084
            ],
            [
              105.83203153259373,
              21.007116320373754
            ],
            [
              105.83202133007548,
              21.007034119792696
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 82, Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DOPP.456",
          "duongKinh": 100,
          "chieudaiQL": 498,
          "chatLieu": "G-DI",
          "from_latitude": 21.008270462639587,
          "to_latitude": 21.007034119792696,
          "to_longitude": 105.83202133007548,
          "from_longitude": 105.82884286760759
        }
      },
      {
        "id": 455,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82806731625327,
            21.007752224744266,
            105.82884286760759,
            21.008472452329887
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82806731625327,
              21.007752224744266
            ],
            [
              105.82806957818086,
              21.007758247291243
            ],
            [
              105.82827946145105,
              21.008238235539988
            ],
            [
              105.82841190057749,
              21.008472452329887
            ],
            [
              105.82847191502131,
              21.008444593701913
            ],
            [
              105.8286443525066,
              21.00837181034903
            ],
            [
              105.82884286760759,
              21.008270462639587
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Chùa Bộc",
          "maTaiSan": "04.O16B.DOPP.457",
          "duongKinh": 150,
          "chieudaiQL": 138,
          "chatLieu": "G-DI",
          "from_latitude": 21.007752224744266,
          "to_latitude": 21.008270462639587,
          "to_longitude": 105.82884286760759,
          "from_longitude": 105.82806731625327
        }
      },
      {
        "id": 456,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82884286760759,
            21.008220139480965,
            105.82924079769866,
            21.008629998244853
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82884286760759,
              21.008270462639587
            ],
            [
              105.82885797854314,
              21.00830121886568
            ],
            [
              105.82890284433974,
              21.00831660176628
            ],
            [
              105.82912042006,
              21.008220139480965
            ],
            [
              105.8291256183426,
              21.00823045157921
            ],
            [
              105.8291678085913,
              21.008314147609536
            ],
            [
              105.82920619132925,
              21.008390287724545
            ],
            [
              105.82924079769866,
              21.008458939517535
            ],
            [
              105.82911998722112,
              21.008516078257472
            ],
            [
              105.8291777069566,
              21.008629998244853
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 82, Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DOPP.458",
          "duongKinh": 100,
          "chieudaiQL": 60,
          "chatLieu": "G-DI",
          "from_latitude": 21.008270462639587,
          "to_latitude": 21.008629998244853,
          "to_longitude": 105.8291777069566,
          "from_longitude": 105.82884286760759
        }
      },
      {
        "id": 457,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82497234685255,
            21.00877339595459,
            105.82722051075274,
            21.010092910995585
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82497234685255,
              21.010092910995585
            ],
            [
              105.82497237276361,
              21.01009290003125
            ],
            [
              105.82500804213082,
              21.010076996502466
            ],
            [
              105.82529992943853,
              21.009946863163606
            ],
            [
              105.8252123032535,
              21.00977742639305
            ],
            [
              105.82515620652612,
              21.009666602426314
            ],
            [
              105.82512020292484,
              21.009581477168542
            ],
            [
              105.82530575129223,
              21.00949121753422
            ],
            [
              105.82534399985735,
              21.009472611620637
            ],
            [
              105.82575920718976,
              21.009281378529575
            ],
            [
              105.82578292229961,
              21.009270456341582
            ],
            [
              105.82607206141722,
              21.009136181125484
            ],
            [
              105.82625826182229,
              21.00904855202643
            ],
            [
              105.82628909819897,
              21.009034039189316
            ],
            [
              105.82632506158446,
              21.009002080014888
            ],
            [
              105.82649039021118,
              21.008909863367386
            ],
            [
              105.82649299471792,
              21.008908410974605
            ],
            [
              105.82655360943951,
              21.008877144929823
            ],
            [
              105.82658373736943,
              21.00889468286652
            ],
            [
              105.82662100399965,
              21.00891457605907
            ],
            [
              105.8266354736133,
              21.008921354036232
            ],
            [
              105.82667195708856,
              21.008904549310607
            ],
            [
              105.82670072362006,
              21.008891299230875
            ],
            [
              105.8267314311304,
              21.008877154554703
            ],
            [
              105.82693675190778,
              21.00877339595459
            ],
            [
              105.82694221033371,
              21.008776653561974
            ],
            [
              105.82696892439611,
              21.00879259756625
            ],
            [
              105.82705357055876,
              21.008912392111064
            ],
            [
              105.82709890670115,
              21.00896772923351
            ],
            [
              105.82711895669061,
              21.008992203157376
            ],
            [
              105.82713564526374,
              21.009020763355874
            ],
            [
              105.82719380753963,
              21.009130216344
            ],
            [
              105.82719467550756,
              21.009131847868087
            ],
            [
              105.8271959526952,
              21.009134252371314
            ],
            [
              105.82722051075274,
              21.009180466707633
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DOPP.459",
          "duongKinh": 100,
          "chieudaiQL": 428,
          "chatLieu": "G-DI",
          "from_latitude": 21.010092910995585,
          "to_latitude": 21.009180466707633,
          "to_longitude": 105.82722051075274,
          "from_longitude": 105.82497234685255
        }
      },
      {
        "id": 458,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83202228431081,
            21.008390901243537,
            105.83295279110125,
            21.009139592449866
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83295279110125,
              21.009139592449866
            ],
            [
              105.83294877834825,
              21.00913634964075
            ],
            [
              105.83293862538093,
              21.0091281763715
            ],
            [
              105.83265353494716,
              21.00889866645655
            ],
            [
              105.83263532768154,
              21.00888400857876
            ],
            [
              105.83251933327881,
              21.008790629779604
            ],
            [
              105.83241696260667,
              21.00870822889911
            ],
            [
              105.83235143700053,
              21.00865264696446
            ],
            [
              105.83233361587277,
              21.008637533744324
            ],
            [
              105.83203207377876,
              21.008399456606284
            ],
            [
              105.83202228431081,
              21.008390901243537
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 4, Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DOPP.460",
          "duongKinh": 100,
          "chieudaiQL": 127.49,
          "chatLieu": "G",
          "from_latitude": 21.009139592449866,
          "to_latitude": 21.008390901243537,
          "to_longitude": 105.83202228431081,
          "from_longitude": 105.83295279110125
        }
      },
      {
        "id": 459,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83163842553934,
            21.00880813046986,
            105.8317096591575,
            21.008841384141917
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8317096591575,
              21.008841384141917
            ],
            [
              105.83169172537941,
              21.008833012178258
            ],
            [
              105.83168689380743,
              21.008830759662633
            ],
            [
              105.83163842553934,
              21.00880813046986
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DOPP.461",
          "duongKinh": 150,
          "chieudaiQL": 9.43,
          "chatLieu": "G",
          "from_latitude": 21.008841384141917,
          "to_latitude": 21.00880813046986,
          "to_longitude": 105.83163842553934,
          "from_longitude": 105.8317096591575
        }
      },
      {
        "id": 460,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83112497238,
            21.010151983462386,
            105.8318806431711,
            21.010412484400078
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8318806431711,
              21.010412484400078
            ],
            [
              105.83171633322667,
              21.010355315856913
            ],
            [
              105.83171313546437,
              21.010354203357934
            ],
            [
              105.83169417441154,
              21.01034760674437
            ],
            [
              105.83134068315239,
              21.010224616703145
            ],
            [
              105.8313212286794,
              21.010217847231814
            ],
            [
              105.83120308420955,
              21.010176741409754
            ],
            [
              105.8311360151731,
              21.010155484622967
            ],
            [
              105.83113049186271,
              21.010153735858562
            ],
            [
              105.83112497238,
              21.010151983462386
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 4B, Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DOPP.462",
          "duongKinh": 100,
          "chieudaiQL": 72.59,
          "chatLieu": "G",
          "from_latitude": 21.010412484400078,
          "to_latitude": 21.010151983462386,
          "to_longitude": 105.83112497238,
          "from_longitude": 105.8318806431711
        }
      },
      {
        "id": 461,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83124546877302,
            21.00986201220011,
            105.8319782754198,
            21.01011949656641
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8319782754198,
              21.01011949656641
            ],
            [
              105.83182754131386,
              21.010066534001954
            ],
            [
              105.83180909747637,
              21.010060053194326
            ],
            [
              105.8316223827561,
              21.009994447743175
            ],
            [
              105.83145799216928,
              21.00993668680997
            ],
            [
              105.8314392977795,
              21.009930117766135
            ],
            [
              105.83125759405138,
              21.009866274001052
            ],
            [
              105.83125118590968,
              21.0098640237562
            ],
            [
              105.83124546877302,
              21.00986201220011
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 4B, Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DOPP.463",
          "duongKinh": 100,
          "chieudaiQL": 70.33,
          "chatLieu": "G",
          "from_latitude": 21.01011949656641,
          "to_latitude": 21.00986201220011,
          "to_longitude": 105.83124546877302,
          "from_longitude": 105.8319782754198
        }
      },
      {
        "id": 462,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83136710291046,
            21.00957260974917,
            105.8321012637962,
            21.009809656952168
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8321012637962,
              21.009809656952168
            ],
            [
              105.83195356225252,
              21.009761967143817
            ],
            [
              105.83193329047798,
              21.009755421220845
            ],
            [
              105.83176441533027,
              21.009700895276374
            ],
            [
              105.83157355137419,
              21.009639267971735
            ],
            [
              105.83155959907536,
              21.009634763121042
            ],
            [
              105.83137816722699,
              21.009576183048228
            ],
            [
              105.83137193741699,
              21.009574173097555
            ],
            [
              105.83136710291046,
              21.00957260974917
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 4B, Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DOPP.464",
          "duongKinh": 100,
          "chieudaiQL": 69.75,
          "chatLieu": "G",
          "from_latitude": 21.009809656952168,
          "to_latitude": 21.00957260974917,
          "to_longitude": 105.83136710291046,
          "from_longitude": 105.8321012637962
        }
      },
      {
        "id": 463,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83230091072215,
            21.0090374757842,
            105.83241928934963,
            21.0091040354413
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83241928934963,
              21.0090374757842
            ],
            [
              105.83241804670374,
              21.00906784017955
            ],
            [
              105.83237338712846,
              21.009099268690424
            ],
            [
              105.83230091072215,
              21.0091040354413
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Ngõ 4A, Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DOPP.465",
          "duongKinh": 150,
          "chieudaiQL": 2.89,
          "chatLieu": "G",
          "from_latitude": 21.0090374757842,
          "to_latitude": 21.0091040354413,
          "to_longitude": 105.83230091072215,
          "from_longitude": 105.83241928934963
        }
      },
      {
        "id": 464,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8314899075561,
            21.007998316052177,
            105.83236821672236,
            21.00935547491173
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8314899075561,
              21.00935547491173
            ],
            [
              105.8314934283531,
              21.00934666841839
            ],
            [
              105.83150142839658,
              21.009326697845136
            ],
            [
              105.83156516206466,
              21.009167598420195
            ],
            [
              105.83157117597143,
              21.009154952774786
            ],
            [
              105.83163057126828,
              21.009030200718644
            ],
            [
              105.83165434531612,
              21.00897307834213
            ],
            [
              105.83166211023304,
              21.00895442671714
            ],
            [
              105.83169788204967,
              21.008868513184222
            ],
            [
              105.8317096591575,
              21.008841384141917
            ],
            [
              105.83171577387583,
              21.008827320837703
            ],
            [
              105.83171788037812,
              21.008823842155273
            ],
            [
              105.83176514340539,
              21.008746000574398
            ],
            [
              105.83180981373043,
              21.00864796739673
            ],
            [
              105.83202228431081,
              21.008390901243537
            ],
            [
              105.83206731825818,
              21.008328134480912
            ],
            [
              105.8320801341396,
              21.008314542399354
            ],
            [
              105.83221933027228,
              21.008166959300194
            ],
            [
              105.83234191559262,
              21.008028102110128
            ],
            [
              105.83234447353887,
              21.008025205599402
            ],
            [
              105.83235676522474,
              21.008011281868292
            ],
            [
              105.83236821672236,
              21.007998316052177
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DOPP.466",
          "duongKinh": 100,
          "chieudaiQL": 175.9,
          "chatLieu": "G",
          "from_latitude": 21.00935547491173,
          "to_latitude": 21.007998316052177,
          "to_longitude": 105.83236821672236,
          "from_longitude": 105.8314899075561
        }
      },
      {
        "id": 465,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83165415666612,
            21.01120022057529,
            105.83202794896968,
            21.011524110792624
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83202794896968,
              21.011524110792624
            ],
            [
              105.83165415666612,
              21.01120022057529
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 4A, Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DOPP.467",
          "duongKinh": 100,
          "chieudaiQL": 52.88,
          "chatLieu": "PVC",
          "from_latitude": 21.011524110792624,
          "to_latitude": 21.01120022057529,
          "to_longitude": 105.83165415666612,
          "from_longitude": 105.83202794896968
        }
      },
      {
        "id": 466,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83220023708138,
            21.009922478990323,
            105.8329631881699,
            21.010560010816963
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8329631881699,
              21.010560010816963
            ],
            [
              105.83267405415906,
              21.01031840768952
            ],
            [
              105.83266198595109,
              21.010308323521354
            ],
            [
              105.83255378092012,
              21.01021790538562
            ],
            [
              105.83237785585956,
              21.010070899885264
            ],
            [
              105.83236193956101,
              21.010057600066933
            ],
            [
              105.83220023708138,
              21.009922478990323
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 4B, Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DOPP.468",
          "duongKinh": 100,
          "chieudaiQL": 106.18,
          "chatLieu": "PVC",
          "from_latitude": 21.010560010816963,
          "to_latitude": 21.009922478990323,
          "to_longitude": 105.83220023708138,
          "from_longitude": 105.8329631881699
        }
      },
      {
        "id": 467,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83304841884578,
            21.00869380073513,
            105.83361215116912,
            21.00915623470648
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83361215116912,
              21.00915623470648
            ],
            [
              105.83358890658232,
              21.009141448062476
            ],
            [
              105.83344846092163,
              21.009015671772005
            ],
            [
              105.83344498175919,
              21.009012556425862
            ],
            [
              105.83342859614338,
              21.00899788248816
            ],
            [
              105.83331152839409,
              21.008900991440342
            ],
            [
              105.83327359200418,
              21.008869593215387
            ],
            [
              105.83314782560393,
              21.008771407867595
            ],
            [
              105.83314690589471,
              21.00877068978994
            ],
            [
              105.83304841884578,
              21.00869380073513
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 46C, Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DOPP.469",
          "duongKinh": 100,
          "chieudaiQL": 77.82,
          "chatLieu": "PVC",
          "from_latitude": 21.00915623470648,
          "to_latitude": 21.00869380073513,
          "to_longitude": 105.83304841884578,
          "from_longitude": 105.83361215116912
        }
      },
      {
        "id": 468,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83327113186554,
            21.008469026546656,
            105.83398939618478,
            21.008893908243166
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83398939618478,
              21.0087504611949
            ],
            [
              105.83397297982638,
              21.008737874814443
            ],
            [
              105.83395686049933,
              21.00871971497914
            ],
            [
              105.83378950786083,
              21.008893908243166
            ],
            [
              105.83365495046309,
              21.008783619777383
            ],
            [
              105.8336519936071,
              21.00878119645704
            ],
            [
              105.83357735135073,
              21.00872001631677
            ],
            [
              105.83336114948013,
              21.00854280921014
            ],
            [
              105.83335840617904,
              21.008540560064326
            ],
            [
              105.83327113186554,
              21.008469026546656
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 46C, Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DOPP.470",
          "duongKinh": 100,
          "chieudaiQL": 76.51,
          "chatLieu": "PVC",
          "from_latitude": 21.0087504611949,
          "to_latitude": 21.008469026546656,
          "to_longitude": 105.83327113186554,
          "from_longitude": 105.83398939618478
        }
      },
      {
        "id": 469,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83360616292268,
            21.009162906360512,
            105.83407887008202,
            21.00953650704547
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83360616292268,
              21.009162906360512
            ],
            [
              105.83362853583601,
              21.009179388110084
            ],
            [
              105.83370647966247,
              21.00922638856503
            ],
            [
              105.83376344123691,
              21.009273825078164
            ],
            [
              105.83389151310612,
              21.00938048083338
            ],
            [
              105.83404910101689,
              21.009511715807818
            ],
            [
              105.83405188609832,
              21.00951403518825
            ],
            [
              105.83407887008202,
              21.00953650704547
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 46C, Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DOPP.471",
          "duongKinh": 100,
          "chieudaiQL": 64.25,
          "chatLieu": "PVC",
          "from_latitude": 21.009162906360512,
          "to_latitude": 21.00953650704547,
          "to_longitude": 105.83407887008202,
          "from_longitude": 105.83360616292268
        }
      },
      {
        "id": 470,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83386685114208,
            21.008754942116028,
            105.83434186640768,
            21.009347619690782
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83398515317268,
              21.008754942116028
            ],
            [
              105.83401060465559,
              21.008769962665397
            ],
            [
              105.83402240045973,
              21.008777943560506
            ],
            [
              105.83386685114208,
              21.008948831051704
            ],
            [
              105.83396938653176,
              21.009034912672703
            ],
            [
              105.83398580187006,
              21.009048694024322
            ],
            [
              105.83407629599141,
              21.00912466698042
            ],
            [
              105.83426852671387,
              21.00928604961661
            ],
            [
              105.8342811054696,
              21.009296609877648
            ],
            [
              105.83434186640768,
              21.009347619690782
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 46C, Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DOPP.472",
          "duongKinh": 100,
          "chieudaiQL": 71.32,
          "chatLieu": "PVC",
          "from_latitude": 21.008754942116028,
          "to_latitude": 21.009347619690782,
          "to_longitude": 105.83434186640768,
          "from_longitude": 105.83398515317268
        }
      },
      {
        "id": 471,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83400200432395,
            21.00939638913695,
            105.8351356078668,
            21.00991981665744
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8351356078668,
              21.00991981665744
            ],
            [
              105.83450342125613,
              21.009400806059695
            ],
            [
              105.83442994734833,
              21.00939641177824
            ],
            [
              105.83442959132064,
              21.00939638913695
            ],
            [
              105.83400200432395,
              21.009850154832744
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 46B, Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DOPP.473",
          "duongKinh": 100,
          "chieudaiQL": 162.08,
          "chatLieu": "G-DI",
          "from_latitude": 21.00991981665744,
          "to_latitude": 21.009850154832744,
          "to_longitude": 105.83400200432395,
          "from_longitude": 105.8351356078668
        }
      },
      {
        "id": 472,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83333655378786,
            21.00946331371158,
            105.83413120943653,
            21.010110008989237
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83333655378786,
              21.00946331371158
            ],
            [
              105.83398680008666,
              21.00999249545921
            ],
            [
              105.83413120943653,
              21.010110008989237
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 46C, Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DOPP.474",
          "duongKinh": 100,
          "chieudaiQL": 109.32,
          "chatLieu": "PVC",
          "from_latitude": 21.00946331371158,
          "to_latitude": 21.010110008989237,
          "to_longitude": 105.83413120943653,
          "from_longitude": 105.83333655378786
        }
      },
      {
        "id": 473,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83535888351408,
            21.01070354058327,
            105.83563399306207,
            21.01097029228766
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83535888351408,
              21.01097029228766
            ],
            [
              105.8353671193716,
              21.010961656766955
            ],
            [
              105.8355504808995,
              21.01078652169342
            ],
            [
              105.83559756267155,
              21.01072302681897
            ],
            [
              105.8356325381884,
              21.010704319081988
            ],
            [
              105.83563399306207,
              21.01070354058327
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 46, Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DOPP.475",
          "duongKinh": 100,
          "chieudaiQL": 40,
          "chatLieu": "G-DI",
          "from_latitude": 21.01097029228766,
          "to_latitude": 21.01070354058327,
          "to_longitude": 105.83563399306207,
          "from_longitude": 105.83535888351408
        }
      },
      {
        "id": 474,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83497327385177,
            21.01069140567694,
            105.83535888351408,
            21.01097029228766
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83535888351408,
              21.01097029228766
            ],
            [
              105.83528607577801,
              21.010898061129005
            ],
            [
              105.83526618748262,
              21.010883215802455
            ],
            [
              105.83518543080528,
              21.010822936250506
            ],
            [
              105.83518104650041,
              21.01082052809442
            ],
            [
              105.83511344973006,
              21.010783407635163
            ],
            [
              105.83508469764195,
              21.01076685804733
            ],
            [
              105.8350306930807,
              21.010730854072477
            ],
            [
              105.83499485620878,
              21.01070525008242
            ],
            [
              105.83498779188646,
              21.010700739719173
            ],
            [
              105.8349770737698,
              21.01069389619
            ],
            [
              105.83497427789503,
              21.01069206371731
            ],
            [
              105.83497327385177,
              21.01069140567694
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 46, Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DOPP.476",
          "duongKinh": 100,
          "chieudaiQL": 50,
          "chatLieu": "G-DI",
          "from_latitude": 21.01097029228766,
          "to_latitude": 21.01069140567694,
          "to_longitude": 105.83497327385177,
          "from_longitude": 105.83535888351408
        }
      },
      {
        "id": 475,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83374112206312,
            21.011471399524975,
            105.83393590189034,
            21.011990707706467
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83393590189034,
              21.011471399524975
            ],
            [
              105.8339351905558,
              21.011474254492935
            ],
            [
              105.83393388173427,
              21.011479506838988
            ],
            [
              105.83393106180428,
              21.01149082542506
            ],
            [
              105.83392429796999,
              21.01152185906696
            ],
            [
              105.8339230213629,
              21.01152771551265
            ],
            [
              105.83387128373244,
              21.011639284338344
            ],
            [
              105.83386622201631,
              21.011652758166004
            ],
            [
              105.83386586561365,
              21.01165370739575
            ],
            [
              105.83384806094249,
              21.01169875990488
            ],
            [
              105.8338272633495,
              21.011756893133306
            ],
            [
              105.83380955739817,
              21.011806385409425
            ],
            [
              105.83377874950608,
              21.011904736815783
            ],
            [
              105.83377699358418,
              21.011908640126737
            ],
            [
              105.83375150224293,
              21.01196529518606
            ],
            [
              105.83374290140289,
              21.011986344539554
            ],
            [
              105.83374112206312,
              21.011990707706467
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 6, Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DOPP.477",
          "duongKinh": 100,
          "chieudaiQL": 90,
          "chatLieu": "G-DI",
          "from_latitude": 21.011471399524975,
          "to_latitude": 21.011990707706467,
          "to_longitude": 105.83374112206312,
          "from_longitude": 105.83393590189034
        }
      },
      {
        "id": 476,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83374112206312,
            21.01175713705697,
            105.83488929224086,
            21.012104851812985
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83488929224086,
              21.011867401190045
            ],
            [
              105.83488642169551,
              21.011872551011752
            ],
            [
              105.83487956153125,
              21.011884858595586
            ],
            [
              105.83477992715248,
              21.012015298018778
            ],
            [
              105.83477218340656,
              21.012025435871028
            ],
            [
              105.83476970582407,
              21.012023935124354
            ],
            [
              105.8346638193601,
              21.011959792697617
            ],
            [
              105.83464884577572,
              21.011950721898604
            ],
            [
              105.83454499976463,
              21.011887814029446
            ],
            [
              105.83442682219054,
              21.01180828206819
            ],
            [
              105.8343555148081,
              21.011767486228806
            ],
            [
              105.83435377687638,
              21.011766492171027
            ],
            [
              105.83434625027617,
              21.011762405855436
            ],
            [
              105.83433654606517,
              21.01175713705697
            ],
            [
              105.83429707830999,
              21.011765001669293
            ],
            [
              105.83427773337594,
              21.011787675339303
            ],
            [
              105.83424920145306,
              21.011821117138073
            ],
            [
              105.83419443578182,
              21.011912948272304
            ],
            [
              105.83412207796985,
              21.012051210107405
            ],
            [
              105.83411951571614,
              21.01205610550261
            ],
            [
              105.83408278234053,
              21.01210337250031
            ],
            [
              105.83408007742868,
              21.012103543824114
            ],
            [
              105.83407263579362,
              21.012104014753877
            ],
            [
              105.83405941605211,
              21.012104851812985
            ],
            [
              105.8340570317079,
              21.012103337953572
            ],
            [
              105.83392436370049,
              21.01201909048192
            ],
            [
              105.83387170788822,
              21.012018279123513
            ],
            [
              105.83381715169253,
              21.012017532158186
            ],
            [
              105.83375779105721,
              21.01199659183008
            ],
            [
              105.83374112206312,
              21.011990707706467
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Ngõ 6, Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DOPP.478",
          "duongKinh": 150,
          "chieudaiQL": 165,
          "chatLieu": "G-DI",
          "from_latitude": 21.011867401190045,
          "to_latitude": 21.011990707706467,
          "to_longitude": 105.83374112206312,
          "from_longitude": 105.83488929224086
        }
      },
      {
        "id": 477,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83298594946164,
            21.012773822625217,
            105.83390777059104,
            21.013578387428932
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83300167651998,
              21.012773822625217
            ],
            [
              105.83298594946164,
              21.012789989673223
            ],
            [
              105.83298717135536,
              21.012815675027355
            ],
            [
              105.83298766514794,
              21.01282612834419
            ],
            [
              105.8329931547117,
              21.0128306130785
            ],
            [
              105.83320827228924,
              21.013006364293698
            ],
            [
              105.8332359530617,
              21.013028979171626
            ],
            [
              105.83325223047517,
              21.013042278027203
            ],
            [
              105.83333552981706,
              21.01310633594864
            ],
            [
              105.83338652991364,
              21.013146338377705
            ],
            [
              105.83339215086397,
              21.01315074748708
            ],
            [
              105.83346964074768,
              21.01321799869452
            ],
            [
              105.83349643426044,
              21.01324122574402
            ],
            [
              105.83352392564747,
              21.01326505724062
            ],
            [
              105.83353327297084,
              21.013272734022358
            ],
            [
              105.83355836570573,
              21.013293342815178
            ],
            [
              105.8336833656195,
              21.01339600150896
            ],
            [
              105.83369709771686,
              21.013407715889162
            ],
            [
              105.83374967663462,
              21.013446872273345
            ],
            [
              105.8337938125495,
              21.013485121629706
            ],
            [
              105.83380095267052,
              21.013491309858857
            ],
            [
              105.83381582324174,
              21.013506575549414
            ],
            [
              105.83382974088525,
              21.01352086319992
            ],
            [
              105.83383436561725,
              21.013524808402224
            ],
            [
              105.8338733747797,
              21.013558085317985
            ],
            [
              105.833893271197,
              21.013570716049347
            ],
            [
              105.83390067766408,
              21.013574634067634
            ],
            [
              105.83390777059104,
              21.013578387428932
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DOPP.479",
          "duongKinh": 100,
          "chieudaiQL": 133,
          "chatLieu": "G-DI",
          "from_latitude": 21.012773822625217,
          "to_latitude": 21.013578387428932,
          "to_longitude": 105.83390777059104,
          "from_longitude": 105.83300167651998
        }
      },
      {
        "id": 478,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83396867669232,
            21.014158536429765,
            105.83441228207035,
            21.014584307504602
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83396867669232,
              21.014158536429765
            ],
            [
              105.8340525539061,
              21.014238471631653
            ],
            [
              105.8342358438707,
              21.01439594172912
            ],
            [
              105.83423847600298,
              21.014398416303923
            ],
            [
              105.83424360878605,
              21.01440324687243
            ],
            [
              105.83439593456806,
              21.01456848731039
            ],
            [
              105.83441228207035,
              21.014584307504602
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D200_Xã Đàn",
          "maTaiSan": "04.O16B.DOPP.480",
          "duongKinh": 200,
          "chieudaiQL": 70,
          "chatLieu": "G-DI",
          "from_latitude": 21.014158536429765,
          "to_latitude": 21.014584307504602,
          "to_longitude": 105.83441228207035,
          "from_longitude": 105.83396867669232
        }
      },
      {
        "id": 479,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83441228207035,
            21.011936766371058,
            105.83693114365796,
            21.014584307504602
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83441228207035,
              21.014584307504602
            ],
            [
              105.83449213140824,
              21.014496557785726
            ],
            [
              105.83452259653738,
              21.014467485294514
            ],
            [
              105.83452719720515,
              21.014463292539595
            ],
            [
              105.83452952193927,
              21.014461173010172
            ],
            [
              105.83455499750022,
              21.014431576730093
            ],
            [
              105.83457665564904,
              21.014403970911598
            ],
            [
              105.83458883640175,
              21.014390631047714
            ],
            [
              105.83480680933228,
              21.014151909701994
            ],
            [
              105.83487512196666,
              21.014074382211792
            ],
            [
              105.83487687506806,
              21.014072392837544
            ],
            [
              105.83487691617343,
              21.01407234657138
            ],
            [
              105.8350191561053,
              21.013910918802154
            ],
            [
              105.83502865122104,
              21.013899331699665
            ],
            [
              105.83503050858371,
              21.01389706452221
            ],
            [
              105.83517407401897,
              21.013721868593077
            ],
            [
              105.83525426918808,
              21.01362400433252
            ],
            [
              105.83538909572084,
              21.01346620388383
            ],
            [
              105.83545381616598,
              21.013399772763645
            ],
            [
              105.83548487889594,
              21.013370472270765
            ],
            [
              105.83548779551202,
              21.013367721178064
            ],
            [
              105.83552773285555,
              21.013323902020428
            ],
            [
              105.83574290686829,
              21.013103037383416
            ],
            [
              105.83588213632072,
              21.012991371521018
            ],
            [
              105.83594118724932,
              21.01294401076133
            ],
            [
              105.83594340897105,
              21.012942228622343
            ],
            [
              105.83605969789478,
              21.012848959303657
            ],
            [
              105.83609215129492,
              21.012822932383067
            ],
            [
              105.83611291429307,
              21.01281205759387
            ],
            [
              105.83611997978002,
              21.01280639759066
            ],
            [
              105.8361251079574,
              21.012802289815006
            ],
            [
              105.83615439406438,
              21.012768554973814
            ],
            [
              105.83619009691617,
              21.01272650150714
            ],
            [
              105.83624238624124,
              21.012673948722536
            ],
            [
              105.83638231051617,
              21.012533319865483
            ],
            [
              105.83641344835783,
              21.012499612911665
            ],
            [
              105.8364323965203,
              21.012483455916655
            ],
            [
              105.83644190130785,
              21.01247381152761
            ],
            [
              105.8364442590914,
              21.01247141903689
            ],
            [
              105.83647473921081,
              21.012435860082384
            ],
            [
              105.83652124985963,
              21.01238291743151
            ],
            [
              105.83652294221993,
              21.012381082791126
            ],
            [
              105.83657859457051,
              21.012320840481134
            ],
            [
              105.83662002329218,
              21.01227599400565
            ],
            [
              105.83667101309643,
              21.012227122705802
            ],
            [
              105.83668542448729,
              21.012216599726393
            ],
            [
              105.83669207886206,
              21.012211743785446
            ],
            [
              105.83670757340377,
              21.012196053549577
            ],
            [
              105.8367156147941,
              21.012187909372315
            ],
            [
              105.8367526247454,
              21.012149849747217
            ],
            [
              105.8367684140937,
              21.012133209670488
            ],
            [
              105.83677691406896,
              21.012123987494785
            ],
            [
              105.83677697814261,
              21.012123920341068
            ],
            [
              105.83679236322085,
              21.012107233476236
            ],
            [
              105.83681064440027,
              21.012087403597125
            ],
            [
              105.836851528446,
              21.01204305652011
            ],
            [
              105.83687432064983,
              21.012018338777363
            ],
            [
              105.83688983038971,
              21.011996068459986
            ],
            [
              105.83688986381387,
              21.011996024940803
            ],
            [
              105.83692216587713,
              21.011949653479835
            ],
            [
              105.83693114365796,
              21.011936766371058
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Kim Hoa",
          "maTaiSan": "04.O16B.DOPP.481",
          "duongKinh": 100,
          "chieudaiQL": 405,
          "chatLieu": "G-DI",
          "from_latitude": 21.014584307504602,
          "to_latitude": 21.011936766371058,
          "to_longitude": 105.83693114365796,
          "from_longitude": 105.83441228207035
        }
      },
      {
        "id": 480,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83133643534573,
            21.014584307504602,
            105.83441228207035,
            21.0176982678081
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83441228207035,
              21.014584307504602
            ],
            [
              105.83432773629619,
              21.014678593372235
            ],
            [
              105.83421849849749,
              21.014785140517446
            ],
            [
              105.83408398526808,
              21.014915295354186
            ],
            [
              105.83402828796692,
              21.014969188339755
            ],
            [
              105.83388325555642,
              21.01510053446121
            ],
            [
              105.83367497090399,
              21.015313744394874
            ],
            [
              105.83365258182477,
              21.015333986081277
            ],
            [
              105.83345746962839,
              21.015534967486303
            ],
            [
              105.83342458861979,
              21.01557147794429
            ],
            [
              105.83332090235082,
              21.015691449973087
            ],
            [
              105.83330593336974,
              21.015709234628286
            ],
            [
              105.83329603315944,
              21.015718593561438
            ],
            [
              105.83318889868285,
              21.015829658542042
            ],
            [
              105.83318881453624,
              21.01582974927663
            ],
            [
              105.83314980136028,
              21.015873071127643
            ],
            [
              105.83301098065621,
              21.016028005446945
            ],
            [
              105.83297519049017,
              21.016060698586983
            ],
            [
              105.83292235403424,
              21.016113533091495
            ],
            [
              105.83286068319224,
              21.0161795536543
            ],
            [
              105.83279713255531,
              21.01624849991076
            ],
            [
              105.83272888673739,
              21.016320585252398
            ],
            [
              105.83261364541765,
              21.016438633561286
            ],
            [
              105.83248516500039,
              21.016561069414994
            ],
            [
              105.8322965067687,
              21.016733891545304
            ],
            [
              105.83226148452323,
              21.016767908525328
            ],
            [
              105.83226153140662,
              21.016767863134728
            ],
            [
              105.83220418917986,
              21.01682355740156
            ],
            [
              105.8318824246771,
              21.017131620617445
            ],
            [
              105.83187685161684,
              21.017136906444946
            ],
            [
              105.83182039481206,
              21.017190474583117
            ],
            [
              105.83181708160728,
              21.01719389684459
            ],
            [
              105.83172701578897,
              21.01728693402016
            ],
            [
              105.83172689813375,
              21.017287053821075
            ],
            [
              105.83163816075164,
              21.017378720587253
            ],
            [
              105.83150659247025,
              21.017514629783918
            ],
            [
              105.83147265689443,
              21.017551254326893
            ],
            [
              105.83133643534573,
              21.0176982678081
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Đê La Thành",
          "maTaiSan": "04.O16B.DOPP.482",
          "duongKinh": 100,
          "chieudaiQL": 470,
          "chatLieu": "G-DI",
          "from_latitude": 21.014584307504602,
          "to_latitude": 21.0176982678081,
          "to_longitude": 105.83133643534573,
          "from_longitude": 105.83441228207035
        }
      },
      {
        "id": 481,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83074555636257,
            21.0160610772916,
            105.83143630500636,
            21.01661945105541
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83074555636257,
              21.0160610772916
            ],
            [
              105.83075958329233,
              21.01607408839987
            ],
            [
              105.83082961292067,
              21.016137543920387
            ],
            [
              105.83083038436082,
              21.016138232023433
            ],
            [
              105.83084834082516,
              21.016148115935607
            ],
            [
              105.83093877114592,
              21.016224206346795
            ],
            [
              105.8310025989101,
              21.016279955056042
            ],
            [
              105.83100377174729,
              21.01628121203585
            ],
            [
              105.83100448095972,
              21.01628195373231
            ],
            [
              105.83107719351116,
              21.016337920044165
            ],
            [
              105.8310890743183,
              21.016349700576058
            ],
            [
              105.83110779724107,
              21.01636387916335
            ],
            [
              105.83117227570492,
              21.016412739340854
            ],
            [
              105.83118665147956,
              21.016423767031217
            ],
            [
              105.83119173579796,
              21.01642766760648
            ],
            [
              105.8311946880976,
              21.016429941954435
            ],
            [
              105.83137514808645,
              21.016557424698775
            ],
            [
              105.83143630500636,
              21.01661945105541
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngách 95, Ngõ Xã Đàn 2",
          "maTaiSan": "04.O16B.DOPP.483",
          "duongKinh": 100,
          "chieudaiQL": 100,
          "chatLieu": "G-DI",
          "from_latitude": 21.0160610772916,
          "to_latitude": 21.01661945105541,
          "to_longitude": 105.83143630500636,
          "from_longitude": 105.83074555636257
        }
      },
      {
        "id": 482,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82683874074243,
            21.009656870350284,
            105.82732650588713,
            21.009880075441284
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82683874074243,
              21.009880075441284
            ],
            [
              105.82685297782433,
              21.009873614128907
            ],
            [
              105.82712828158205,
              21.009748675063715
            ],
            [
              105.82728968422742,
              21.00967533699225
            ],
            [
              105.82731107160323,
              21.00966461107104
            ],
            [
              105.82732650588713,
              21.009656870350284
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 167, Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DOPP.484",
          "duongKinh": 100,
          "chieudaiQL": 60,
          "chatLieu": "G-DI",
          "from_latitude": 21.009880075441284,
          "to_latitude": 21.009656870350284,
          "to_longitude": 105.82732650588713,
          "from_longitude": 105.82683874074243
        }
      },
      {
        "id": 483,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.831795213926,
            21.011768953345182,
            105.83300167651998,
            21.012773822625217
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.831795213926,
              21.011768953345182
            ],
            [
              105.8321155274023,
              21.01203247933456
            ],
            [
              105.83214936649541,
              21.012060558604706
            ],
            [
              105.83271048501777,
              21.012526100530746
            ],
            [
              105.83295645930433,
              21.012727857573307
            ],
            [
              105.83300167651998,
              21.012773822625217
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D160_Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DOPP.485",
          "duongKinh": 160,
          "chieudaiQL": 167.69,
          "chatLieu": "PVC",
          "from_latitude": 21.011768953345182,
          "to_latitude": 21.012773822625217,
          "to_longitude": 105.83300167651998,
          "from_longitude": 105.831795213926
        }
      },
      {
        "id": 484,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82861312561438,
            21.011440594527816,
            105.83102705678854,
            21.012478045604656
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83102705678854,
              21.011444248738226
            ],
            [
              105.830992423163,
              21.011440594527816
            ],
            [
              105.83088663651033,
              21.01146029473445
            ],
            [
              105.8307028441104,
              21.011555990158584
            ],
            [
              105.83033748264019,
              21.011766691382704
            ],
            [
              105.82978648025558,
              21.012054421132884
            ],
            [
              105.8294763055716,
              21.012213864327492
            ],
            [
              105.82933974793467,
              21.01218058585175
            ],
            [
              105.82927979083703,
              21.012165978465855
            ],
            [
              105.82924116559106,
              21.01218398735803
            ],
            [
              105.82915484640526,
              21.012224232148128
            ],
            [
              105.82900504746722,
              21.0122940732909
            ],
            [
              105.82883957845468,
              21.01236664431589
            ],
            [
              105.8288335005224,
              21.01236915779395
            ],
            [
              105.82877414157832,
              21.01239370487017
            ],
            [
              105.82873850867809,
              21.012411703591997
            ],
            [
              105.82872163275711,
              21.012420227336982
            ],
            [
              105.82869741768499,
              21.012432458438436
            ],
            [
              105.82868837978951,
              21.012436862327828
            ],
            [
              105.82864384360111,
              21.01245856155397
            ],
            [
              105.82861312561438,
              21.012478045604656
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Hồ Đắc Di",
          "maTaiSan": "04.O16B.DOPP.486",
          "duongKinh": 150,
          "chieudaiQL": 282.8,
          "chatLieu": "G-CI",
          "from_latitude": 21.011444248738226,
          "to_latitude": 21.012478045604656,
          "to_longitude": 105.82861312561438,
          "from_longitude": 105.83102705678854
        }
      },
      {
        "id": 485,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82839987205234,
            21.012141959413977,
            105.82861312561438,
            21.012478045604656
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82861312561438,
              21.012478045604656
            ],
            [
              105.8286055027343,
              21.012466086080387
            ],
            [
              105.82852883248549,
              21.012345794771985
            ],
            [
              105.82841060444136,
              21.01215455593891
            ],
            [
              105.8284055158542,
              21.012148971679967
            ],
            [
              105.82839987205234,
              21.012141959413977
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Ngõ 119, Hồ Đắc Di",
          "maTaiSan": "04.O16B.DOPP.487",
          "duongKinh": 150,
          "chieudaiQL": 45.6,
          "chatLieu": "G-CI",
          "from_latitude": 21.012478045604656,
          "to_latitude": 21.012141959413977,
          "to_longitude": 105.82839987205234,
          "from_longitude": 105.82861312561438
        }
      },
      {
        "id": 486,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83684717565818,
            21.01072156488227,
            105.83710804521812,
            21.011067152764642
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83684717565818,
              21.011067152764642
            ],
            [
              105.83691011271566,
              21.010983772751057
            ],
            [
              105.83710804521812,
              21.01072156488227
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D200_Xã Đàn",
          "maTaiSan": "04.O16B.DOPP.488",
          "duongKinh": 200,
          "chieudaiQL": 46.9,
          "chatLieu": "G-DI",
          "from_latitude": 21.011067152764642,
          "to_latitude": 21.01072156488227,
          "to_longitude": 105.83710804521812,
          "from_longitude": 105.83684717565818
        }
      },
      {
        "id": 487,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83663281454763,
            21.011067152764642,
            105.83684717565818,
            21.011415737917687
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83663281454763,
              21.011415737917687
            ],
            [
              105.83673326216388,
              21.011208469684696
            ],
            [
              105.83676377250751,
              21.011170625375524
            ],
            [
              105.83684717565818,
              21.011067152764642
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D200_Xã Đàn",
          "maTaiSan": "04.O16B.DOPP.489",
          "duongKinh": 200,
          "chieudaiQL": 44,
          "chatLieu": "G-DI",
          "from_latitude": 21.011415737917687,
          "to_latitude": 21.011067152764642,
          "to_longitude": 105.83684717565818,
          "from_longitude": 105.83663281454763
        }
      },
      {
        "id": 488,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83396867669232,
            21.012595280694278,
            105.83545855006885,
            21.014158536429765
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83545855006885,
              21.012595280694278
            ],
            [
              105.83529487138641,
              21.012762539597134
            ],
            [
              105.83517106312597,
              21.012889056889215
            ],
            [
              105.83515661744,
              21.012903817843316
            ],
            [
              105.8351122559899,
              21.01294914985438
            ],
            [
              105.83396867669232,
              21.014158536429765
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D200_Xã Đàn",
          "maTaiSan": "04.O16B.DOPP.490",
          "duongKinh": 200,
          "chieudaiQL": 220,
          "chatLieu": "G-DI",
          "from_latitude": 21.012595280694278,
          "to_latitude": 21.014158536429765,
          "to_longitude": 105.83396867669232,
          "from_longitude": 105.83545855006885
        }
      },
      {
        "id": 489,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83074605492627,
            21.01661945105541,
            105.83143630500636,
            21.017196609497688
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83143630500636,
              21.01661945105541
            ],
            [
              105.83106186340527,
              21.016913669955045
            ],
            [
              105.83074605492627,
              21.017196609497688
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D200_Xã Đàn",
          "maTaiSan": "04.O16B.DOPP.491",
          "duongKinh": 200,
          "chieudaiQL": 100,
          "chatLieu": "G-DI",
          "from_latitude": 21.01661945105541,
          "to_latitude": 21.017196609497688,
          "to_longitude": 105.83074605492627,
          "from_longitude": 105.83143630500636
        }
      },
      {
        "id": 490,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82959043949207,
            21.017691407016102,
            105.83004218036658,
            21.018003714901536
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83004218036658,
              21.017691407016102
            ],
            [
              105.82966941581326,
              21.017949113648452
            ],
            [
              105.82959609721497,
              21.017999801635685
            ],
            [
              105.82959043949207,
              21.018003714901536
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D200_Xã Đàn",
          "maTaiSan": "04.O16B.DOPP.492",
          "duongKinh": 200,
          "chieudaiQL": 70,
          "chatLieu": "G-DI",
          "from_latitude": 21.017691407016102,
          "to_latitude": 21.018003714901536,
          "to_longitude": 105.82959043949207,
          "from_longitude": 105.83004218036658
        }
      },
      {
        "id": 491,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83004218036658,
            21.017691407016102,
            105.83026417623475,
            21.01797721146238
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83026417623475,
              21.01797721146238
            ],
            [
              105.83004218036658,
              21.017691407016102
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D200_Xã Đàn",
          "maTaiSan": "04.O16B.DOPP.493",
          "duongKinh": 200,
          "chieudaiQL": 39.16,
          "chatLieu": "G-DI",
          "from_latitude": 21.01797721146238,
          "to_latitude": 21.017691407016102,
          "to_longitude": 105.83004218036658,
          "from_longitude": 105.83026417623475
        }
      },
      {
        "id": 492,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83545855006885,
            21.011415737917687,
            105.83663281454763,
            21.012595280694278
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83663281454763,
              21.011415737917687
            ],
            [
              105.8365985580986,
              21.01144799972686
            ],
            [
              105.83653079343115,
              21.01151181693812
            ],
            [
              105.83646419897669,
              21.01157453185617
            ],
            [
              105.8363022562678,
              21.011738063670922
            ],
            [
              105.836204031408,
              21.01183725261591
            ],
            [
              105.83602234507288,
              21.012025504915123
            ],
            [
              105.83570801236709,
              21.012340359658133
            ],
            [
              105.83545855006885,
              21.012595280694278
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D200_Xã Đàn",
          "maTaiSan": "04.O16B.DOPP.494",
          "duongKinh": 200,
          "chieudaiQL": 175,
          "chatLieu": "G-DI",
          "from_latitude": 21.011415737917687,
          "to_latitude": 21.012595280694278,
          "to_longitude": 105.83545855006885,
          "from_longitude": 105.83663281454763
        }
      },
      {
        "id": 493,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8332156662577,
            21.014158536429765,
            105.83396867669232,
            21.014937441174226
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83396867669232,
              21.014158536429765
            ],
            [
              105.83393665577223,
              21.014192399025365
            ],
            [
              105.83381795619987,
              21.01431792749988
            ],
            [
              105.8332156662577,
              21.014937441174226
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D200_Xã Đàn",
          "maTaiSan": "04.O16B.DOPP.495",
          "duongKinh": 200,
          "chieudaiQL": 115,
          "chatLieu": "G-DI",
          "from_latitude": 21.014158536429765,
          "to_latitude": 21.014937441174226,
          "to_longitude": 105.8332156662577,
          "from_longitude": 105.83396867669232
        }
      },
      {
        "id": 494,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83205937329453,
            21.014937441174226,
            105.8332156662577,
            21.016071530744316
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8332156662577,
              21.014937441174226
            ],
            [
              105.8326416286563,
              21.01552600915479
            ],
            [
              105.83239014891602,
              21.015808594325048
            ],
            [
              105.83239012397145,
              21.015808606188994
            ],
            [
              105.83237280363596,
              21.015828078655368
            ],
            [
              105.8322499339686,
              21.01595423074646
            ],
            [
              105.83207165984025,
              21.01606315104713
            ],
            [
              105.83205937329453,
              21.016071530744316
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D200_Xã Đàn",
          "maTaiSan": "04.O16B.DOPP.496",
          "duongKinh": 200,
          "chieudaiQL": 200,
          "chatLieu": "G-DI",
          "from_latitude": 21.014937441174226,
          "to_latitude": 21.016071530744316,
          "to_longitude": 105.83205937329453,
          "from_longitude": 105.8332156662577
        }
      },
      {
        "id": 495,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83143630500636,
            21.016071530744316,
            105.83205937329453,
            21.01661945105541
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83205937329453,
              21.016071530744316
            ],
            [
              105.83143630500636,
              21.01661945105541
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D200_Xã Đàn",
          "maTaiSan": "04.O16B.DOPP.497",
          "duongKinh": 200,
          "chieudaiQL": 100,
          "chatLieu": "G-DI",
          "from_latitude": 21.016071530744316,
          "to_latitude": 21.01661945105541,
          "to_longitude": 105.83143630500636,
          "from_longitude": 105.83205937329453
        }
      },
      {
        "id": 496,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83004218036658,
            21.017196609497688,
            105.83074605492627,
            21.017691407016102
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83074605492627,
              21.017196609497688
            ],
            [
              105.8306609880495,
              21.01726331528538
            ],
            [
              105.83056916125689,
              21.01733675416011
            ],
            [
              105.83032726817486,
              21.01753021084271
            ],
            [
              105.83031408966586,
              21.017533548453482
            ],
            [
              105.83024458811886,
              21.01755114920196
            ],
            [
              105.83004218036658,
              21.017691407016102
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D200_Xã Đàn",
          "maTaiSan": "04.O16B.DOPP.498",
          "duongKinh": 200,
          "chieudaiQL": 70,
          "chatLieu": "G-DI",
          "from_latitude": 21.017196609497688,
          "to_latitude": 21.017691407016102,
          "to_longitude": 105.83004218036658,
          "from_longitude": 105.83074605492627
        }
      },
      {
        "id": 497,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83035816430709,
            21.016635431779886,
            105.83074605492627,
            21.017196609497688
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83074605492627,
              21.017196609497688
            ],
            [
              105.830735936927,
              21.0171878831721
            ],
            [
              105.83073107992983,
              21.017183692441858
            ],
            [
              105.83069304203786,
              21.017155973950043
            ],
            [
              105.8306737415516,
              21.017141909236948
            ],
            [
              105.83065635196256,
              21.017126365643804
            ],
            [
              105.83065020064129,
              21.01712086703849
            ],
            [
              105.83062850659051,
              21.017089561504363
            ],
            [
              105.83062548501582,
              21.017085201035126
            ],
            [
              105.8305927402702,
              21.017045929715966
            ],
            [
              105.83058575036563,
              21.017025012633457
            ],
            [
              105.83057457847414,
              21.016987866948263
            ],
            [
              105.83057342012243,
              21.016984014929143
            ],
            [
              105.83057312793501,
              21.01698284306624
            ],
            [
              105.8305719099639,
              21.016977952629272
            ],
            [
              105.8305643647829,
              21.01694726445741
            ],
            [
              105.83055134698762,
              21.01690231217498
            ],
            [
              105.8305281612221,
              21.01685176166448
            ],
            [
              105.83052163027554,
              21.016840936782046
            ],
            [
              105.83051875945368,
              21.016836178155504
            ],
            [
              105.83050238484164,
              21.016818041849497
            ],
            [
              105.83049609555286,
              21.01681107617054
            ],
            [
              105.83042045065321,
              21.0166933749379
            ],
            [
              105.83041984809628,
              21.016692437621767
            ],
            [
              105.83040921383953,
              21.01668045586149
            ],
            [
              105.83040781026216,
              21.016678873939615
            ],
            [
              105.83037452567507,
              21.01664319010837
            ],
            [
              105.83035816430709,
              21.016635431779886
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Ngõ Xã Đàn 2",
          "maTaiSan": "04.O16B.DOPP.499",
          "duongKinh": 150,
          "chieudaiQL": 92,
          "chatLieu": "G-DI",
          "from_latitude": 21.017196609497688,
          "to_latitude": 21.016635431779886,
          "to_longitude": 105.83035816430709,
          "from_longitude": 105.83074605492627
        }
      },
      {
        "id": 498,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82947935501845,
            21.017665578742907,
            105.82955391603474,
            21.017791881667947
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82947935501845,
              21.017665578742907
            ],
            [
              105.82955391603474,
              21.017791881667947
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DOPP.500",
          "duongKinh": 150,
          "chieudaiQL": 17,
          "chatLieu": "G-DI",
          "from_latitude": 21.017665578742907,
          "to_latitude": 21.017791881667947,
          "to_longitude": 105.82955391603474,
          "from_longitude": 105.82947935501845
        }
      },
      {
        "id": 499,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82937301700967,
            21.017495426473683,
            105.82947935501845,
            21.017665578742907
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82937301700967,
              21.017495426473683
            ],
            [
              105.82938321939395,
              21.017512638271782
            ],
            [
              105.82938962805181,
              21.017523450246426
            ],
            [
              105.82945742082805,
              21.0176254370717
            ],
            [
              105.8294673671224,
              21.01764365088621
            ],
            [
              105.82947935501845,
              21.017665578742907
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DOPP.501",
          "duongKinh": 150,
          "chieudaiQL": 24,
          "chatLieu": "G-DI",
          "from_latitude": 21.017495426473683,
          "to_latitude": 21.017665578742907,
          "to_longitude": 105.82947935501845,
          "from_longitude": 105.82937301700967
        }
      },
      {
        "id": 500,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83097762594291,
            21.01036694477103,
            105.8317871865826,
            21.01063232391495
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8317871865826,
              21.01063232391495
            ],
            [
              105.83107653951183,
              21.01036694477103
            ],
            [
              105.83103245903054,
              21.010469353722673
            ],
            [
              105.83101783864592,
              21.010496372863862
            ],
            [
              105.83100722493461,
              21.01051598676014
            ],
            [
              105.83098666049,
              21.010509754672032
            ],
            [
              105.83098274655792,
              21.0105083755922
            ],
            [
              105.83097762594291,
              21.01050656705023
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 4C, Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DOPP.502",
          "duongKinh": 100,
          "chieudaiQL": 94.59,
          "chatLieu": "G",
          "from_latitude": 21.01063232391495,
          "to_latitude": 21.01050656705023,
          "to_longitude": 105.83097762594291,
          "from_longitude": 105.8317871865826
        }
      },
      {
        "id": 501,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83226460677172,
            21.015518559625686,
            105.83231671022938,
            21.01561571334778
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83230432954993,
              21.015518559625686
            ],
            [
              105.83231671022938,
              21.015563905982905
            ],
            [
              105.83226460677172,
              21.01561571334778
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ Xã Đàn 2",
          "maTaiSan": "04.O16B.DOPP.503",
          "duongKinh": 100,
          "chieudaiQL": 13.07,
          "chatLieu": "G-DI",
          "from_latitude": 21.015518559625686,
          "to_latitude": 21.01561571334778,
          "to_longitude": 105.83226460677172,
          "from_longitude": 105.83230432954993
        }
      },
      {
        "id": 502,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83227361861566,
            21.007189580521118,
            105.8327160108201,
            21.00755465269879
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83227361861566,
              21.007189580521118
            ],
            [
              105.8323269828592,
              21.00723361825032
            ],
            [
              105.83261422651181,
              21.007470657551465
            ],
            [
              105.8327160108201,
              21.00755465269879
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DOPP.504",
          "duongKinh": 150,
          "chieudaiQL": 53.63,
          "chatLieu": "G",
          "from_latitude": 21.007189580521118,
          "to_latitude": 21.00755465269879,
          "to_longitude": 105.8327160108201,
          "from_longitude": 105.83227361861566
        }
      },
      {
        "id": 503,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83102705678854,
            21.011444248738226,
            105.831795213926,
            21.011956300535026
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.831795213926,
              21.011768953345182
            ],
            [
              105.83179283201846,
              21.0117716909235
            ],
            [
              105.8317833206144,
              21.01178261857652
            ],
            [
              105.83163220117707,
              21.011956300535026
            ],
            [
              105.83142931637028,
              21.011786219306387
            ],
            [
              105.83124656842608,
              21.01164022421531
            ],
            [
              105.8311520963851,
              21.011567160434165
            ],
            [
              105.83107819943864,
              21.01148797068742
            ],
            [
              105.83102705678854,
              21.011444248738226
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DOPP.505",
          "duongKinh": 150,
          "chieudaiQL": 111.58,
          "chatLieu": "G-CI",
          "from_latitude": 21.011768953345182,
          "to_latitude": 21.011444248738226,
          "to_longitude": 105.83102705678854,
          "from_longitude": 105.831795213926
        }
      },
      {
        "id": 504,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.831075167656,
            21.00638829090945,
            105.83202133007548,
            21.007034119792696
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83202133007548,
              21.007034119792696
            ],
            [
              105.83191462632074,
              21.006965537414818
            ],
            [
              105.83167989432562,
              21.00676817006645
            ],
            [
              105.83152277993871,
              21.006635219691418
            ],
            [
              105.83141923381054,
              21.006547581551896
            ],
            [
              105.83126986329482,
              21.00646035233661
            ],
            [
              105.831075167656,
              21.00638829090945
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DOPP.506",
          "duongKinh": 150,
          "chieudaiQL": 116,
          "chatLieu": "G-DI",
          "from_latitude": 21.007034119792696,
          "to_latitude": 21.00638829090945,
          "to_longitude": 105.831075167656,
          "from_longitude": 105.83202133007548
        }
      },
      {
        "id": 505,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.833172823981,
            21.008631229044354,
            105.83403345856541,
            21.009608220539402
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83403345856541,
              21.008631229044354
            ],
            [
              105.83403296126896,
              21.00863192154583
            ],
            [
              105.83401129220294,
              21.00866211147383
            ],
            [
              105.83400935660273,
              21.008729381445754
            ],
            [
              105.83398939618478,
              21.0087504611949
            ],
            [
              105.83398515317268,
              21.008754942116028
            ],
            [
              105.83383047574826,
              21.00891829240206
            ],
            [
              105.8338255738496,
              21.008923469321754
            ],
            [
              105.83373897101772,
              21.009014927925833
            ],
            [
              105.83361646645106,
              21.00915142647261
            ],
            [
              105.83361215116912,
              21.00915623470648
            ],
            [
              105.83360616292268,
              21.009162906360512
            ],
            [
              105.83333655378786,
              21.00946331371158
            ],
            [
              105.83319807260445,
              21.009580288411073
            ],
            [
              105.833172823981,
              21.009608220539402
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D160_Ngõ 46C, Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DOPP.507",
          "duongKinh": 160,
          "chieudaiQL": 523.42,
          "chatLieu": "PVC",
          "from_latitude": 21.008631229044354,
          "to_latitude": 21.009608220539402,
          "to_longitude": 105.833172823981,
          "from_longitude": 105.83403345856541
        }
      },
      {
        "id": 506,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83300908658795,
            21.016044111993658,
            105.8338009085484,
            21.016835855635524
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83300908658795,
              21.016044111993658
            ],
            [
              105.83301518615511,
              21.016046866769585
            ],
            [
              105.83306853260797,
              21.016103185360198
            ],
            [
              105.83308372560444,
              21.016115329787677
            ],
            [
              105.83308477009872,
              21.01611616467196
            ],
            [
              105.8331701858308,
              21.016184442946273
            ],
            [
              105.83325085998055,
              21.016249039370816
            ],
            [
              105.83325265719378,
              21.016264505517825
            ],
            [
              105.83332888305436,
              21.01633457916712
            ],
            [
              105.83337497858805,
              21.01636838687574
            ],
            [
              105.83343937229304,
              21.016428351735517
            ],
            [
              105.83344543697716,
              21.016433999694794
            ],
            [
              105.83344493103141,
              21.01644595093292
            ],
            [
              105.83344372104601,
              21.01647453940606
            ],
            [
              105.833485705861,
              21.01651398618154
            ],
            [
              105.83354931453444,
              21.016573050713887
            ],
            [
              105.83355736953075,
              21.01658164878406
            ],
            [
              105.83356368424283,
              21.01659662763082
            ],
            [
              105.83358420350656,
              21.016614638550287
            ],
            [
              105.83361398020394,
              21.01663709313159
            ],
            [
              105.83362237765094,
              21.016647593517934
            ],
            [
              105.83367127059418,
              21.016701807535078
            ],
            [
              105.83370130384863,
              21.016735108570433
            ],
            [
              105.83379178990766,
              21.01682229234607
            ],
            [
              105.83379539816883,
              21.016827657879915
            ],
            [
              105.8338009085484,
              21.016835855635524
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 216, Đê La Thành",
          "maTaiSan": "04.O16A.DOPP.508",
          "duongKinh": 100,
          "chieudaiQL": 123.36,
          "chatLieu": "G",
          "from_latitude": 21.016044111993658,
          "to_latitude": 21.016835855635524,
          "to_longitude": 105.8338009085484,
          "from_longitude": 105.83300908658795
        }
      },
      {
        "id": 507,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83585591444589,
            21.016624995262717,
            105.83708621714797,
            21.019041699666435
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83585591444589,
              21.016624995262717
            ],
            [
              105.83597246199136,
              21.016805780986783
            ],
            [
              105.83601546961464,
              21.016839964420427
            ],
            [
              105.83608129631115,
              21.01689551165818
            ],
            [
              105.83631282985137,
              21.01719417807414
            ],
            [
              105.8363442293641,
              21.017235691244167
            ],
            [
              105.83637952177943,
              21.01728235893606
            ],
            [
              105.83639431333917,
              21.01731645969687
            ],
            [
              105.8364701471361,
              21.017491292437597
            ],
            [
              105.83649504602329,
              21.01754869363741
            ],
            [
              105.8364983523959,
              21.017555275430997
            ],
            [
              105.8365138959632,
              21.017586219325853
            ],
            [
              105.83651882972904,
              21.01759603749732
            ],
            [
              105.83662494617903,
              21.017807286458623
            ],
            [
              105.83663888282017,
              21.01783503270059
            ],
            [
              105.8366617130261,
              21.017861285526212
            ],
            [
              105.83672903422453,
              21.017938712060115
            ],
            [
              105.83680843422319,
              21.01804759985733
            ],
            [
              105.83683022276522,
              21.018077485129364
            ],
            [
              105.8368302558436,
              21.018077551805206
            ],
            [
              105.8368333596206,
              21.018081782592613
            ],
            [
              105.83686473055197,
              21.018182760444013
            ],
            [
              105.83688018796151,
              21.01824409369171
            ],
            [
              105.83688033916758,
              21.0182446430112
            ],
            [
              105.83691741597941,
              21.01839155693916
            ],
            [
              105.83695893500715,
              21.018529477275592
            ],
            [
              105.83696222328079,
              21.01854315035787
            ],
            [
              105.83696838045026,
              21.018568606331566
            ],
            [
              105.83699931633856,
              21.018659163084223
            ],
            [
              105.83700748268514,
              21.018706788734708
            ],
            [
              105.83702587683072,
              21.018771009570386
            ],
            [
              105.83702283673168,
              21.018793703576605
            ],
            [
              105.83702283887736,
              21.018793743307903
            ],
            [
              105.8370211924345,
              21.018806056930792
            ],
            [
              105.83707767994709,
              21.019017258893097
            ],
            [
              105.83708543827234,
              21.01903761912123
            ],
            [
              105.83708621714797,
              21.019041699666435
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ Cống Trắng",
          "maTaiSan": "04.O16A.DOPP.509",
          "duongKinh": 100,
          "chieudaiQL": 300.8,
          "chatLieu": "G",
          "from_latitude": 21.016624995262717,
          "to_latitude": 21.019041699666435,
          "to_longitude": 105.83708621714797,
          "from_longitude": 105.83585591444589
        }
      },
      {
        "id": 508,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84015002339174,
            21.010695072831236,
            105.8411077497461,
            21.0137380520963
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84015002339174,
              21.01103139915043
            ],
            [
              105.84015505204383,
              21.011026211492716
            ],
            [
              105.84026370919543,
              21.010914101501438
            ],
            [
              105.84046205292039,
              21.010746133881348
            ],
            [
              105.84058401428777,
              21.010695072831236
            ],
            [
              105.84099925768894,
              21.010731297876745
            ],
            [
              105.84108777306443,
              21.010739020102516
            ],
            [
              105.84107625950543,
              21.010858692768295
            ],
            [
              105.84109854397312,
              21.012172624337747
            ],
            [
              105.84110183598354,
              21.012641238502983
            ],
            [
              105.8411077497461,
              21.013171522635783
            ],
            [
              105.8410932301617,
              21.013527964031187
            ],
            [
              105.84103950248542,
              21.0137380520963
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Hồ Ba Mẫu",
          "maTaiSan": "04.O16A.DOPP.510",
          "duongKinh": 100,
          "chieudaiQL": 444.61,
          "chatLieu": "G",
          "from_latitude": 21.01103139915043,
          "to_latitude": 21.0137380520963,
          "to_longitude": 105.84103950248542,
          "from_longitude": 105.84015002339174
        }
      },
      {
        "id": 509,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84103950248542,
            21.0137380520963,
            105.84128054008437,
            21.013754143614968
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84103950248542,
              21.0137380520963
            ],
            [
              105.84128054008437,
              21.013754143614968
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Hồ Ba Mẫu",
          "maTaiSan": "04.O16A.DOPP.511",
          "duongKinh": 100,
          "chieudaiQL": 25.12,
          "chatLieu": "G",
          "from_latitude": 21.0137380520963,
          "to_latitude": 21.013754143614968,
          "to_longitude": 105.84128054008437,
          "from_longitude": 105.84103950248542
        }
      },
      {
        "id": 510,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83065171343873,
            21.019013743633028,
            105.83068139850376,
            21.01907016088196
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83068139850376,
              21.019058051029102
            ],
            [
              105.83067658507052,
              21.019067988330953
            ],
            [
              105.83067644224631,
              21.019068253673293
            ],
            [
              105.83067541485622,
              21.01907016088196
            ],
            [
              105.83065925328295,
              21.01906344303522
            ],
            [
              105.83066022340748,
              21.019061617396535
            ],
            [
              105.83066377261953,
              21.01905494239431
            ],
            [
              105.83065766684622,
              21.01905212434182
            ],
            [
              105.83065171343873,
              21.01904937419018
            ],
            [
              105.83067134879632,
              21.019013743633028
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Khâm Thiên",
          "maTaiSan": "04.O16A.DOPP.512",
          "duongKinh": 150,
          "chieudaiQL": 14.78,
          "chatLieu": "G",
          "from_latitude": 21.019058051029102,
          "to_latitude": 21.019013743633028,
          "to_longitude": 105.83067134879632,
          "from_longitude": 105.83068139850376
        }
      },
      {
        "id": 511,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83067134879632,
            21.019013743633028,
            105.83715519033892,
            21.019363622245812
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83067134879632,
              21.019013743633028
            ],
            [
              105.83094507761012,
              21.019136402340013
            ],
            [
              105.83100877145694,
              21.019162197099078
            ],
            [
              105.83102626345317,
              21.019169263379553
            ],
            [
              105.83121721131928,
              21.01925235561984
            ],
            [
              105.83124134157543,
              21.019262847887266
            ],
            [
              105.83143829017362,
              21.019322825026986
            ],
            [
              105.83147369928824,
              21.019329124458825
            ],
            [
              105.83147372423343,
              21.019329112594974
            ],
            [
              105.83152274598136,
              21.0193378266355
            ],
            [
              105.8317200651549,
              21.019360689031842
            ],
            [
              105.8317200901001,
              21.019360677167946
            ],
            [
              105.83174546661432,
              21.019363622245812
            ],
            [
              105.83188977315153,
              21.01936255800025
            ],
            [
              105.83203359115726,
              21.019350771132558
            ],
            [
              105.8320336161024,
              21.019350759268637
            ],
            [
              105.8320943132527,
              21.019345785472005
            ],
            [
              105.83215677838852,
              21.01934044803751
            ],
            [
              105.83215680333363,
              21.019340436173565
            ],
            [
              105.83239653906435,
              21.01931993059043
            ],
            [
              105.83260726231369,
              21.019314557498003
            ],
            [
              105.83260728725877,
              21.019314545633975
            ],
            [
              105.8326396978743,
              21.019313722218122
            ],
            [
              105.83285422521041,
              21.01929856995569
            ],
            [
              105.83285425015553,
              21.019298558091634
            ],
            [
              105.83292859113044,
              21.019293309454508
            ],
            [
              105.83345686111234,
              21.01925450443505
            ],
            [
              105.83345688605738,
              21.019254492570923
            ],
            [
              105.8334670613867,
              21.01925374903861
            ],
            [
              105.83347815723832,
              21.01925316538216
            ],
            [
              105.83358207166133,
              21.019242026144205
            ],
            [
              105.83374421134953,
              21.019228077862092
            ],
            [
              105.83379856455446,
              21.019222813006014
            ],
            [
              105.83387852085438,
              21.019217818237063
            ],
            [
              105.83408066673876,
              21.019211184728587
            ],
            [
              105.8340806916837,
              21.019211172864352
            ],
            [
              105.8341145830504,
              21.01921006371456
            ],
            [
              105.8342109020359,
              21.019203126105815
            ],
            [
              105.83421092698089,
              21.019203114241584
            ],
            [
              105.83424788317433,
              21.019200453650612
            ],
            [
              105.83446500957723,
              21.019179985595564
            ],
            [
              105.83454036385099,
              21.019177764313188
            ],
            [
              105.83469232018552,
              21.01917438313666
            ],
            [
              105.83487222615288,
              21.019161828796403
            ],
            [
              105.83492528536694,
              21.0191598323785
            ],
            [
              105.83499207462864,
              21.019157318742735
            ],
            [
              105.83511006310647,
              21.019148396454124
            ],
            [
              105.83511008805137,
              21.01914838458975
            ],
            [
              105.83515657923901,
              21.01914487673456
            ],
            [
              105.83515660418388,
              21.019144864870185
            ],
            [
              105.83519662600044,
              21.019141840378285
            ],
            [
              105.83533279811945,
              21.019138574064
            ],
            [
              105.83533656963215,
              21.019138342382433
            ],
            [
              105.83543275468928,
              21.019132424482173
            ],
            [
              105.83548377387405,
              21.01912892952543
            ],
            [
              105.83548379881887,
              21.019128917660986
            ],
            [
              105.83554646477629,
              21.019124620321506
            ],
            [
              105.83555825866193,
              21.019123805488675
            ],
            [
              105.83566958852366,
              21.01911611590519
            ],
            [
              105.83578251686428,
              21.019107339945883
            ],
            [
              105.83581216028875,
              21.019106251330165
            ],
            [
              105.83581913502421,
              21.01910600209248
            ],
            [
              105.83581915996902,
              21.019105990228006
            ],
            [
              105.83593468234457,
              21.019099395353283
            ],
            [
              105.83620598295107,
              21.0190773807927
            ],
            [
              105.83625940614981,
              21.019073045530284
            ],
            [
              105.83625986009106,
              21.019073027040637
            ],
            [
              105.83630291123272,
              21.019071240168607
            ],
            [
              105.83664541212588,
              21.01905234955133
            ],
            [
              105.83664543707063,
              21.019052337686716
            ],
            [
              105.83708619220332,
              21.019041711531084
            ],
            [
              105.83708621714797,
              21.019041699666435
            ],
            [
              105.83709166677507,
              21.019041206785463
            ],
            [
              105.83709728278205,
              21.019040879278755
            ],
            [
              105.83709974939559,
              21.01904073436656
            ],
            [
              105.83711069478503,
              21.019040258698006
            ],
            [
              105.83715519033892,
              21.01903787921367
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Khâm Thiên",
          "maTaiSan": "04.O16A.DOPP.513",
          "duongKinh": 150,
          "chieudaiQL": 682.41,
          "chatLieu": "G",
          "from_latitude": 21.019013743633028,
          "to_latitude": 21.01903787921367,
          "to_longitude": 105.83715519033892,
          "from_longitude": 105.83067134879632
        }
      },
      {
        "id": 512,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83022470936265,
            21.018744772338405,
            105.83067134879632,
            21.019013743633028
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83022470936265,
              21.018744772338405
            ],
            [
              105.83033780413611,
              21.01883378773681
            ],
            [
              105.83046241920178,
              21.018901563478998
            ],
            [
              105.83048431840487,
              21.018915847648948
            ],
            [
              105.83050644723431,
              21.018927300891104
            ],
            [
              105.8305249081853,
              21.01893685539493
            ],
            [
              105.83063910226834,
              21.018996597114693
            ],
            [
              105.83066625756736,
              21.019011040755444
            ],
            [
              105.83067134879632,
              21.019013743633028
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Khâm Thiên",
          "maTaiSan": "04.O16A.DOPP.514",
          "duongKinh": 100,
          "chieudaiQL": 55.38,
          "chatLieu": "G",
          "from_latitude": 21.018744772338405,
          "to_latitude": 21.019013743633028,
          "to_longitude": 105.83067134879632,
          "from_longitude": 105.83022470936265
        }
      },
      {
        "id": 513,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83100877145694,
            21.01852530501638,
            105.83161338309684,
            21.019162197099078
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83161338309684,
              21.01852530501638
            ],
            [
              105.83152079809798,
              21.01860552500533
            ],
            [
              105.8314869918938,
              21.018634815638965
            ],
            [
              105.83141989961673,
              21.018689534865107
            ],
            [
              105.83140836066963,
              21.018694690869502
            ],
            [
              105.83140324419239,
              21.018696977551194
            ],
            [
              105.83137928693222,
              21.01870952120716
            ],
            [
              105.831361491055,
              21.01871530662616
            ],
            [
              105.83135779322292,
              21.018716508826042
            ],
            [
              105.83134218900207,
              21.018722699015356
            ],
            [
              105.83125201398514,
              21.018667994199227
            ],
            [
              105.83119863497448,
              21.018772647049595
            ],
            [
              105.8311508192449,
              21.018854798661447
            ],
            [
              105.83114889632188,
              21.01885810211787
            ],
            [
              105.83113800865787,
              21.018876807810948
            ],
            [
              105.83111486844719,
              21.018918758245924
            ],
            [
              105.83110745164919,
              21.018934189047062
            ],
            [
              105.83110266435061,
              21.01894414971636
            ],
            [
              105.83105482167727,
              21.019060168752635
            ],
            [
              105.83102328101761,
              21.019134652826043
            ],
            [
              105.83101218417995,
              21.019155717853398
            ],
            [
              105.83100877145694,
              21.019162197099078
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ Chiến Thắng",
          "maTaiSan": "04.O16A.DOPP.515",
          "duongKinh": 100,
          "chieudaiQL": 107.49,
          "chatLieu": "G",
          "from_latitude": 21.01852530501638,
          "to_latitude": 21.019162197099078,
          "to_longitude": 105.83100877145694,
          "from_longitude": 105.83161338309684
        }
      },
      {
        "id": 514,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83194041622237,
            21.01857377967488,
            105.83215641246021,
            21.019350771132558
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83203359115726,
              21.019350771132558
            ],
            [
              105.83203021662717,
              21.019339506362716
            ],
            [
              105.83201854933449,
              21.01927489254616
            ],
            [
              105.83198879785749,
              21.019169648908335
            ],
            [
              105.83197604365395,
              21.019112330329108
            ],
            [
              105.83197594498533,
              21.0191118846187
            ],
            [
              105.83197540329091,
              21.019110824172646
            ],
            [
              105.83197468848329,
              21.019109424057593
            ],
            [
              105.83194041622237,
              21.0189955740127
            ],
            [
              105.83194940214943,
              21.018992501552656
            ],
            [
              105.83195936549963,
              21.018989095537968
            ],
            [
              105.83201883515669,
              21.018973391143895
            ],
            [
              105.83214924302969,
              21.018875607824103
            ],
            [
              105.8321546362701,
              21.018864256809987
            ],
            [
              105.83215641246021,
              21.018860517803116
            ],
            [
              105.83214688588691,
              21.018827696963896
            ],
            [
              105.83213489088813,
              21.01878637259673
            ],
            [
              105.83212841012198,
              21.018767811419252
            ],
            [
              105.83210237742776,
              21.018681780160076
            ],
            [
              105.83209630182739,
              21.018654928105992
            ],
            [
              105.83209920105749,
              21.018641905682184
            ],
            [
              105.83207505762391,
              21.018580310048556
            ],
            [
              105.83207314272777,
              21.018576733612996
            ],
            [
              105.83207156123635,
              21.01857377967488
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 241, Khâm Thiên",
          "maTaiSan": "04.O16A.DOPP.516",
          "duongKinh": 100,
          "chieudaiQL": 108.52,
          "chatLieu": "G",
          "from_latitude": 21.019350771132558,
          "to_latitude": 21.01857377967488,
          "to_longitude": 105.83207156123635,
          "from_longitude": 105.83203359115726
        }
      },
      {
        "id": 515,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8326349795249,
            21.01841569500026,
            105.83294121422854,
            21.018710544994054
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83294121422854,
              21.018710544994054
            ],
            [
              105.83294051413844,
              21.018706253589333
            ],
            [
              105.83291676211749,
              21.01864158520574
            ],
            [
              105.8328692391003,
              21.018553683042455
            ],
            [
              105.8328250213664,
              21.018483267367525
            ],
            [
              105.83281418337064,
              21.018466008322132
            ],
            [
              105.83279332098503,
              21.018437687785937
            ],
            [
              105.83277711978509,
              21.01841569500026
            ],
            [
              105.83277495767643,
              21.018416800293107
            ],
            [
              105.83268970694775,
              21.01846039446245
            ],
            [
              105.8326772302729,
              21.018466264172833
            ],
            [
              105.83267343469043,
              21.018468049459255
            ],
            [
              105.83267267733814,
              21.018468343101528
            ],
            [
              105.8326604925064,
              21.018473066996574
            ],
            [
              105.83263780609217,
              21.01848369699516
            ],
            [
              105.8326349795249,
              21.018485021409248
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngách 24, Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DOPP.517",
          "duongKinh": 100,
          "chieudaiQL": 60.07,
          "chatLieu": "G",
          "from_latitude": 21.018710544994054,
          "to_latitude": 21.018485021409248,
          "to_longitude": 105.8326349795249,
          "from_longitude": 105.83294121422854
        }
      },
      {
        "id": 516,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83315665185516,
            21.016904668371556,
            105.83380476670365,
            21.01927686496888
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83380476670365,
              21.01927686496888
            ],
            [
              105.83380445651046,
              21.01927419023564
            ],
            [
              105.83380057747165,
              21.019240377175404
            ],
            [
              105.83379856455446,
              21.019222813006014
            ],
            [
              105.83379657429187,
              21.019205516983135
            ],
            [
              105.83378401937557,
              21.01913797037328
            ],
            [
              105.83373112958557,
              21.018952423708775
            ],
            [
              105.8337224968742,
              21.018919529038843
            ],
            [
              105.8337218197177,
              21.018916944619814
            ],
            [
              105.83365713825444,
              21.01867045284091
            ],
            [
              105.83365261342824,
              21.018656647553044
            ],
            [
              105.83365158201686,
              21.018653497644745
            ],
            [
              105.83364781752934,
              21.018642012626955
            ],
            [
              105.83364349383244,
              21.018625971775837
            ],
            [
              105.83363472368003,
              21.01859343725603
            ],
            [
              105.83355900600837,
              21.018396962270543
            ],
            [
              105.83353315670422,
              21.018337310827096
            ],
            [
              105.83349815928008,
              21.0182565497813
            ],
            [
              105.83348571173059,
              21.01821451237956
            ],
            [
              105.83347887473587,
              21.018191420621203
            ],
            [
              105.83345194082455,
              21.018107435285113
            ],
            [
              105.83343299880893,
              21.01804837049534
            ],
            [
              105.833423666819,
              21.0180296589514
            ],
            [
              105.83341809466997,
              21.01801848626635
            ],
            [
              105.83339125147432,
              21.017964662753183
            ],
            [
              105.8333869828752,
              21.01795610483841
            ],
            [
              105.83334096699481,
              21.017863838262947
            ],
            [
              105.83329615244308,
              21.017776728619513
            ],
            [
              105.83326184626415,
              21.017710045397944
            ],
            [
              105.83323209445416,
              21.01764794811261
            ],
            [
              105.83316136174658,
              21.017500316549395
            ],
            [
              105.83315665185516,
              21.01747240287851
            ],
            [
              105.83320559788852,
              21.017413905166713
            ],
            [
              105.83320770674369,
              21.017410489681005
            ],
            [
              105.83322268894463,
              21.017386222524806
            ],
            [
              105.83322475108136,
              21.01738288223537
            ],
            [
              105.8332335697487,
              21.017368598802246
            ],
            [
              105.83326757851862,
              21.01728550084817
            ],
            [
              105.83331704882468,
              21.01723446839929
            ],
            [
              105.83332833128428,
              21.01722282927637
            ],
            [
              105.83344936940739,
              21.017109648473703
            ],
            [
              105.83345087951275,
              21.01710823656109
            ],
            [
              105.83349133303322,
              21.017078259953152
            ],
            [
              105.83355796869868,
              21.01702888294614
            ],
            [
              105.83359307749419,
              21.017002866949518
            ],
            [
              105.83367569621869,
              21.016939371856484
            ],
            [
              105.83371196199904,
              21.016904668371556
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DOPP.518",
          "duongKinh": 150,
          "chieudaiQL": 290.57,
          "chatLieu": "G",
          "from_latitude": 21.01927686496888,
          "to_latitude": 21.016904668371556,
          "to_longitude": 105.83371196199904,
          "from_longitude": 105.83380476670365
        }
      },
      {
        "id": 517,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83371196199904,
            21.01569744058949,
            105.8351460324127,
            21.016904668371556
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83371196199904,
              21.016904668371556
            ],
            [
              105.83377021920838,
              21.016859832209757
            ],
            [
              105.83377810324438,
              21.016853764545356
            ],
            [
              105.8338009085484,
              21.016835855635524
            ],
            [
              105.83384743572869,
              21.016799319767053
            ],
            [
              105.83407683733395,
              21.016603857406153
            ],
            [
              105.83409918610872,
              21.01658378386045
            ],
            [
              105.83409961683148,
              21.016583396974983
            ],
            [
              105.83410304140368,
              21.016579774512024
            ],
            [
              105.8341040197133,
              21.01657873913618
            ],
            [
              105.83418792206763,
              21.016505711201077
            ],
            [
              105.8342102493596,
              21.01648627722971
            ],
            [
              105.8342112736817,
              21.016485386143586
            ],
            [
              105.83427964763897,
              21.016434554278387
            ],
            [
              105.83428925188207,
              21.016429750798757
            ],
            [
              105.83431650291998,
              21.016416122982235
            ],
            [
              105.83440720852893,
              21.016337842747326
            ],
            [
              105.8344147763394,
              21.01633131144491
            ],
            [
              105.83446622510448,
              21.016286930253397
            ],
            [
              105.83446904544306,
              21.016284496680985
            ],
            [
              105.83449217492851,
              21.016264544804553
            ],
            [
              105.83456839789685,
              21.016212462199313
            ],
            [
              105.83461164184789,
              21.016164216575493
            ],
            [
              105.83465130974056,
              21.016131093109337
            ],
            [
              105.8346550745745,
              21.016127945868934
            ],
            [
              105.83468764267178,
              21.016100753476575
            ],
            [
              105.83484479097078,
              21.015969418576844
            ],
            [
              105.834881265108,
              21.01590622161381
            ],
            [
              105.83508681153812,
              21.015740256001642
            ],
            [
              105.8351460324127,
              21.01569744058949
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DOPP.519",
          "duongKinh": 150,
          "chieudaiQL": 203.06,
          "chatLieu": "G",
          "from_latitude": 21.016904668371556,
          "to_latitude": 21.01569744058949,
          "to_longitude": 105.8351460324127,
          "from_longitude": 105.83371196199904
        }
      },
      {
        "id": 518,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83365158201686,
            21.01832992256081,
            105.83432115803704,
            21.018653497644745
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83365158201686,
              21.018653497644745
            ],
            [
              105.8336541424655,
              21.01865275529142
            ],
            [
              105.83368262296771,
              21.018640444765303
            ],
            [
              105.83368830793935,
              21.01863798813341
            ],
            [
              105.83374803878115,
              21.018602188732707
            ],
            [
              105.83379638746506,
              21.01857205051273
            ],
            [
              105.83380715121827,
              21.01856243892961
            ],
            [
              105.83381576019339,
              21.018579819027334
            ],
            [
              105.83382434386913,
              21.01859714686154
            ],
            [
              105.83390115959486,
              21.018562147919283
            ],
            [
              105.83396971570086,
              21.01853091252743
            ],
            [
              105.83403681677889,
              21.018497507471807
            ],
            [
              105.83403957284608,
              21.018496135509523
            ],
            [
              105.83409480819218,
              21.018456333263707
            ],
            [
              105.83412155745711,
              21.0184388981305
            ],
            [
              105.83413164889105,
              21.018433285688822
            ],
            [
              105.8342178536564,
              21.018381259950246
            ],
            [
              105.83423002920226,
              21.018373839866
            ],
            [
              105.83423817059393,
              21.018364049633146
            ],
            [
              105.83424089626195,
              21.01836274736505
            ],
            [
              105.83426101593828,
              21.018353135936007
            ],
            [
              105.83431373806738,
              21.018332786947827
            ],
            [
              105.83432115803704,
              21.01832992256081
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngách 15, Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DOPP.520",
          "duongKinh": 100,
          "chieudaiQL": 82.38,
          "chatLieu": "G",
          "from_latitude": 21.018653497644745,
          "to_latitude": 21.01832992256081,
          "to_longitude": 105.83432115803704,
          "from_longitude": 105.83365158201686
        }
      },
      {
        "id": 519,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83353315670422,
            21.01793535063239,
            105.83445083309043,
            21.018337310827096
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83353315670422,
              21.018337310827096
            ],
            [
              105.83353716315376,
              21.01833677545452
            ],
            [
              105.83358039394372,
              21.018330993434546
            ],
            [
              105.8336604134333,
              21.018303358246957
            ],
            [
              105.83371944693046,
              21.018277976453962
            ],
            [
              105.83373136275651,
              21.018273669286003
            ],
            [
              105.83376600836709,
              21.018255480153467
            ],
            [
              105.83378856863406,
              21.018243636691988
            ],
            [
              105.83381492749346,
              21.01822709319628
            ],
            [
              105.83389759204788,
              21.018182293605793
            ],
            [
              105.83390844969387,
              21.01816241279795
            ],
            [
              105.83394680203585,
              21.018142734106263
            ],
            [
              105.83395159428119,
              21.018140275519944
            ],
            [
              105.8340009717454,
              21.018116825998394
            ],
            [
              105.83407553489381,
              21.01808141622137
            ],
            [
              105.83413153750384,
              21.01804641806793
            ],
            [
              105.83413327428208,
              21.018044770199513
            ],
            [
              105.83413695948471,
              21.01804165045679
            ],
            [
              105.83413723474867,
              21.01804150368793
            ],
            [
              105.83413943400988,
              21.018040335873525
            ],
            [
              105.83414764956905,
              21.018036272627267
            ],
            [
              105.83415340676987,
              21.018033836400512
            ],
            [
              105.8341738916395,
              21.018028838668474
            ],
            [
              105.8342843383353,
              21.018001892179505
            ],
            [
              105.83428584476431,
              21.018002413177804
            ],
            [
              105.8343080296698,
              21.018010088362686
            ],
            [
              105.83431064754491,
              21.018008777590047
            ],
            [
              105.83434929983316,
              21.017989428822144
            ],
            [
              105.83440867854753,
              21.017958190402283
            ],
            [
              105.83444952109646,
              21.017936061583054
            ],
            [
              105.83445083309043,
              21.01793535063239
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngách 25, Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DOPP.521",
          "duongKinh": 100,
          "chieudaiQL": 105.92,
          "chatLieu": "G",
          "from_latitude": 21.018337310827096,
          "to_latitude": 21.01793535063239,
          "to_longitude": 105.83445083309043,
          "from_longitude": 105.83353315670422
        }
      },
      {
        "id": 520,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83341809466997,
            21.017676101801577,
            105.83388865087858,
            21.01801848626635
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83341809466997,
              21.01801848626635
            ],
            [
              105.83342230786997,
              21.018016901240095
            ],
            [
              105.83343237026786,
              21.01801298794732
            ],
            [
              105.8334382274421,
              21.018010710222164
            ],
            [
              105.83347080473223,
              21.017998041856128
            ],
            [
              105.83353528364371,
              21.017966755263604
            ],
            [
              105.83360953557157,
              21.01793072669898
            ],
            [
              105.83362238564192,
              21.017923169675438
            ],
            [
              105.83363837967264,
              21.017913763698328
            ],
            [
              105.83367804676533,
              21.017868781112682
            ],
            [
              105.83380775821468,
              21.01778884859897
            ],
            [
              105.83381775455862,
              21.01777773058599
            ],
            [
              105.83382437225474,
              21.017770369385683
            ],
            [
              105.8338582456492,
              21.017732693340765
            ],
            [
              105.83388369136571,
              21.017684979519007
            ],
            [
              105.83388643224531,
              21.017679840284558
            ],
            [
              105.83388865087858,
              21.017676101801577
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngách 35, Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DOPP.522",
          "duongKinh": 100,
          "chieudaiQL": 63.75,
          "chatLieu": "G",
          "from_latitude": 21.01801848626635,
          "to_latitude": 21.017676101801577,
          "to_longitude": 105.83388865087858,
          "from_longitude": 105.83341809466997
        }
      },
      {
        "id": 521,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83288965866903,
            21.016711008247746,
            105.83355796869868,
            21.01702888294614
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83355796869868,
              21.01702888294614
            ],
            [
              105.83355594164578,
              21.01702676397794
            ],
            [
              105.83352284984483,
              21.01700601014615
            ],
            [
              105.83345023202376,
              21.01692697519467
            ],
            [
              105.83341480680538,
              21.016893451240353
            ],
            [
              105.83340929956935,
              21.016888239517044
            ],
            [
              105.83338767388693,
              21.016867775420124
            ],
            [
              105.8333024331651,
              21.01678001024775
            ],
            [
              105.83328002422628,
              21.016754996816974
            ],
            [
              105.83323460310955,
              21.016711008247746
            ],
            [
              105.83312257109691,
              21.01681730557588
            ],
            [
              105.83304928964802,
              21.01688060113497
            ],
            [
              105.83304258072614,
              21.016885566497383
            ],
            [
              105.83303091070904,
              21.016876094621676
            ],
            [
              105.83295360859529,
              21.01681335217253
            ],
            [
              105.83294259960395,
              21.016804082085844
            ],
            [
              105.83288965866903,
              21.016759505208928
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngách 68, Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DOPP.523",
          "duongKinh": 100,
          "chieudaiQL": 99.23,
          "chatLieu": "G",
          "from_latitude": 21.01702888294614,
          "to_latitude": 21.016759505208928,
          "to_longitude": 105.83288965866903,
          "from_longitude": 105.83355796869868
        }
      },
      {
        "id": 522,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83371196199904,
            21.016904668371556,
            105.83449927965115,
            21.017358697305152
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83371196199904,
              21.016904668371556
            ],
            [
              105.83371506466057,
              21.016908718580197
            ],
            [
              105.83380650482594,
              21.01702803981683
            ],
            [
              105.8338283875612,
              21.01705458400891
            ],
            [
              105.83386828622952,
              21.017112206557098
            ],
            [
              105.83388335394176,
              21.017133968152837
            ],
            [
              105.83399268238621,
              21.01725962442256
            ],
            [
              105.83409299039094,
              21.01735095353827
            ],
            [
              105.83412919403648,
              21.017358697305152
            ],
            [
              105.834144181918,
              21.01735018137588
            ],
            [
              105.83414570083957,
              21.017349317671275
            ],
            [
              105.83414871472009,
              21.017347605734344
            ],
            [
              105.83415088570878,
              21.017346372123228
            ],
            [
              105.83421292000934,
              21.017288473825747
            ],
            [
              105.83426203990268,
              21.017242468370444
            ],
            [
              105.83427143265328,
              21.017221424145525
            ],
            [
              105.8342734782368,
              21.01721684199157
            ],
            [
              105.83445813529903,
              21.017122580846866
            ],
            [
              105.834460905535,
              21.01712116726055
            ],
            [
              105.83449927965115,
              21.017110822266158
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngách 79, Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DOPP.524",
          "duongKinh": 100,
          "chieudaiQL": 114.37,
          "chatLieu": "G",
          "from_latitude": 21.016904668371556,
          "to_latitude": 21.017110822266158,
          "to_longitude": 105.83449927965115,
          "from_longitude": 105.83371196199904
        }
      },
      {
        "id": 523,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83418792206763,
            21.016505711201077,
            105.83436901930506,
            21.01681317221187
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83418792206763,
              21.016505711201077
            ],
            [
              105.83421903227993,
              21.016530795648418
            ],
            [
              105.83427555940567,
              21.01660109912613
            ],
            [
              105.83428094284982,
              21.016609992971272
            ],
            [
              105.8342849350367,
              21.016616586803533
            ],
            [
              105.83429936260491,
              21.016648987874643
            ],
            [
              105.83431468969165,
              21.016682616531053
            ],
            [
              105.83431439558571,
              21.016699262594834
            ],
            [
              105.83435215898935,
              21.016782334819176
            ],
            [
              105.83435763428582,
              21.016792348209272
            ],
            [
              105.83436761403671,
              21.0168106022046
            ],
            [
              105.83436901930506,
              21.01681317221187
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngách 107, Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DOPP.525",
          "duongKinh": 100,
          "chieudaiQL": 37.93,
          "chatLieu": "G",
          "from_latitude": 21.016505711201077,
          "to_latitude": 21.01681317221187,
          "to_longitude": 105.83436901930506,
          "from_longitude": 105.83418792206763
        }
      },
      {
        "id": 524,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83415127023532,
            21.016098824268624,
            105.83429909585165,
            21.016429750798757
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83428925188207,
              21.016429750798757
            ],
            [
              105.83428488451625,
              21.016426610930434
            ],
            [
              105.83424902896547,
              21.016400818152803
            ],
            [
              105.83420769796192,
              21.016343184647024
            ],
            [
              105.83418906491715,
              21.016306300709072
            ],
            [
              105.8341690448811,
              21.016266670540926
            ],
            [
              105.83415325101254,
              21.01624059511396
            ],
            [
              105.83415137034471,
              21.016220863458507
            ],
            [
              105.83415127023532,
              21.01621981349915
            ],
            [
              105.83415315906362,
              21.016193446337738
            ],
            [
              105.83420015158718,
              21.016164010151517
            ],
            [
              105.83420159300348,
              21.01616287676254
            ],
            [
              105.83420319052863,
              21.01616161976953
            ],
            [
              105.83420504281261,
              21.01616016191466
            ],
            [
              105.83428717376452,
              21.016106295498464
            ],
            [
              105.83429900671021,
              21.01609888160892
            ],
            [
              105.83429909585165,
              21.016098824268624
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngách 114, Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DOPP.526",
          "duongKinh": 100,
          "chieudaiQL": 51.71,
          "chatLieu": "G",
          "from_latitude": 21.016429750798757,
          "to_latitude": 21.016098824268624,
          "to_longitude": 105.83429909585165,
          "from_longitude": 105.83428925188207
        }
      },
      {
        "id": 525,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83497819716489,
            21.018260007922894,
            105.83604985049952,
            21.01914487673456
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83604985049952,
              21.018260007922894
            ],
            [
              105.83603941111785,
              21.01826496463905
            ],
            [
              105.83601328676478,
              21.018286084859728
            ],
            [
              105.83588301183293,
              21.01830671249278
            ],
            [
              105.8358154043471,
              21.018317855374544
            ],
            [
              105.83580194166416,
              21.01832007390391
            ],
            [
              105.83576088567229,
              21.018326840250968
            ],
            [
              105.8357014259576,
              21.018338335959918
            ],
            [
              105.8356616341236,
              21.01834150575703
            ],
            [
              105.83562340425613,
              21.018339970203453
            ],
            [
              105.83554233380364,
              21.018336713072465
            ],
            [
              105.83553878202277,
              21.018338222644147
            ],
            [
              105.83540409826459,
              21.018395448533724
            ],
            [
              105.83539778645307,
              21.01839778907475
            ],
            [
              105.83537975821922,
              21.01840603372298
            ],
            [
              105.83537642296429,
              21.018407559389303
            ],
            [
              105.8352526230748,
              21.018464173520638
            ],
            [
              105.83524599038775,
              21.018482135087307
            ],
            [
              105.8352317503216,
              21.018487963060036
            ],
            [
              105.83522583336186,
              21.018490385655987
            ],
            [
              105.83519085368137,
              21.01850470189041
            ],
            [
              105.83515885535822,
              21.018518374836713
            ],
            [
              105.83515881180257,
              21.018538019273304
            ],
            [
              105.83515878751803,
              21.01854765047197
            ],
            [
              105.83515862256809,
              21.018613728452937
            ],
            [
              105.83515944221256,
              21.018617884679397
            ],
            [
              105.83517453856257,
              21.01869447532648
            ],
            [
              105.83505639703571,
              21.018745468777166
            ],
            [
              105.83502656855019,
              21.018758747178058
            ],
            [
              105.83497819716489,
              21.018780279933168
            ],
            [
              105.83498202560209,
              21.018793556601217
            ],
            [
              105.83499393514774,
              21.01884392695477
            ],
            [
              105.834993903162,
              21.018846993557855
            ],
            [
              105.83499378062646,
              21.018858848976656
            ],
            [
              105.83502662136615,
              21.01892320416977
            ],
            [
              105.83502930176323,
              21.018928455905794
            ],
            [
              105.83504011469397,
              21.018927686569697
            ],
            [
              105.83509776906712,
              21.01903019948
            ],
            [
              105.83510038747136,
              21.019034855391112
            ],
            [
              105.83515003224676,
              21.01913470334843
            ],
            [
              105.83515657923901,
              21.01914487673456
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ Toàn Thắng",
          "maTaiSan": "04.O16A.DOPP.527",
          "duongKinh": 100,
          "chieudaiQL": 185.78,
          "chatLieu": "G",
          "from_latitude": 21.018260007922894,
          "to_latitude": 21.01914487673456,
          "to_longitude": 105.83515657923901,
          "from_longitude": 105.83604985049952
        }
      },
      {
        "id": 526,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83509776906712,
            21.01881254406946,
            105.83546843036753,
            21.01903019948
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83509776906712,
              21.01903019948
            ],
            [
              105.83511203931806,
              21.019021490084043
            ],
            [
              105.8351730962283,
              21.01898771593388
            ],
            [
              105.83524829197384,
              21.018945307065056
            ],
            [
              105.83528018971091,
              21.018926340797552
            ],
            [
              105.8353030087299,
              21.018912772514444
            ],
            [
              105.83536597673795,
              21.018875331758327
            ],
            [
              105.83544524940247,
              21.018825889248866
            ],
            [
              105.83545689670797,
              21.018818624317063
            ],
            [
              105.83546843036753,
              21.01881254406946
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ Toàn Thắng",
          "maTaiSan": "04.O16A.DOPP.528",
          "duongKinh": 100,
          "chieudaiQL": 45.5,
          "chatLieu": "G",
          "from_latitude": 21.01903019948,
          "to_latitude": 21.01881254406946,
          "to_longitude": 105.83546843036753,
          "from_longitude": 105.83509776906712
        }
      },
      {
        "id": 527,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83493622535504,
            21.017806756475895,
            105.83562340425613,
            21.018339970203453
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83562340425613,
              21.018339970203453
            ],
            [
              105.83562236816236,
              21.018330980083935
            ],
            [
              105.83561895735463,
              21.018301334958256
            ],
            [
              105.83557605993408,
              21.01808118517514
            ],
            [
              105.83556875375908,
              21.018051115697695
            ],
            [
              105.83556016965139,
              21.018015783086916
            ],
            [
              105.83544196126034,
              21.018021152555622
            ],
            [
              105.83538681223855,
              21.018025584542855
            ],
            [
              105.83537295632709,
              21.018011085385687
            ],
            [
              105.8353234466175,
              21.01789917244509
            ],
            [
              105.83531677925205,
              21.017898121315206
            ],
            [
              105.83525228440445,
              21.017887952464463
            ],
            [
              105.83521199757342,
              21.017881675955017
            ],
            [
              105.83521112240432,
              21.017881540252084
            ],
            [
              105.83517546751537,
              21.017868380100918
            ],
            [
              105.83512621109517,
              21.017806756475895
            ],
            [
              105.83503059027676,
              21.01786013403938
            ],
            [
              105.83501261245735,
              21.01787016949419
            ],
            [
              105.83494608101562,
              21.01790109367975
            ],
            [
              105.83493794088207,
              21.01790487659409
            ],
            [
              105.83493622535504,
              21.01790567443211
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngách 22/17, ngõ Toàn Thắng",
          "maTaiSan": "04.O16A.DOPP.529",
          "duongKinh": 100,
          "chieudaiQL": 123.93,
          "chatLieu": "G",
          "from_latitude": 21.018339970203453,
          "to_latitude": 21.01790567443211,
          "to_longitude": 105.83493622535504,
          "from_longitude": 105.83562340425613
        }
      },
      {
        "id": 528,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83555031741528,
            21.017798437760863,
            105.83593719955925,
            21.01787662837526
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83593719955925,
              21.017798437760863
            ],
            [
              105.83591968749734,
              21.017802623547993
            ],
            [
              105.83587160782746,
              21.017817562480722
            ],
            [
              105.83582461403272,
              21.01783126497037
            ],
            [
              105.83569362984649,
              21.01785125645097
            ],
            [
              105.83568534542077,
              21.017852791081157
            ],
            [
              105.83563992763358,
              21.0178612064331
            ],
            [
              105.83555031741528,
              21.01787662837526
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ Lệnh Cư",
          "maTaiSan": "04.O16A.DOPP.530",
          "duongKinh": 100,
          "chieudaiQL": 41.16,
          "chatLieu": "G",
          "from_latitude": 21.017798437760863,
          "to_latitude": 21.01787662837526,
          "to_longitude": 105.83555031741528,
          "from_longitude": 105.83593719955925
        }
      },
      {
        "id": 529,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83550881618166,
            21.01736270474246,
            105.83585183085243,
            21.01741424051163
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83585183085243,
              21.01736270474246
            ],
            [
              105.8356955725081,
              21.017382977554718
            ],
            [
              105.83568795458075,
              21.017383966072476
            ],
            [
              105.8356711400848,
              21.017386445828645
            ],
            [
              105.83553992377384,
              21.01740908205898
            ],
            [
              105.83553144754056,
              21.017410544462006
            ],
            [
              105.83550881618166,
              21.01741424051163
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ Lệnh Cư",
          "maTaiSan": "04.O16A.DOPP.531",
          "duongKinh": 100,
          "chieudaiQL": 35.9,
          "chatLieu": "G",
          "from_latitude": 21.01736270474246,
          "to_latitude": 21.01741424051163,
          "to_longitude": 105.83550881618166,
          "from_longitude": 105.83585183085243
        }
      },
      {
        "id": 530,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8319373842925,
            21.01709376957173,
            105.83237429136706,
            21.017563253926475
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8319373842925,
              21.01709376957173
            ],
            [
              105.83194172352032,
              21.017096870799893
            ],
            [
              105.83195069363379,
              21.017103280474462
            ],
            [
              105.83200491212996,
              21.01714200482364
            ],
            [
              105.83206818723507,
              21.017193533231485
            ],
            [
              105.83211163312993,
              21.017225702241205
            ],
            [
              105.83213168915141,
              21.017237040771494
            ],
            [
              105.8321791464968,
              21.017292459922132
            ],
            [
              105.832211716599,
              21.017335192933267
            ],
            [
              105.83229369234839,
              21.01744275073645
            ],
            [
              105.83231432094591,
              21.017481508346723
            ],
            [
              105.83234428251154,
              21.017521675385108
            ],
            [
              105.8323543807841,
              21.017535666605053
            ],
            [
              105.83237121836027,
              21.017558996709482
            ],
            [
              105.83237429136706,
              21.017563253926475
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 268, Đê La Thành",
          "maTaiSan": "04.O16A.DOPP.532",
          "duongKinh": 100,
          "chieudaiQL": 70.05,
          "chatLieu": "G",
          "from_latitude": 21.01709376957173,
          "to_latitude": 21.017563253926475,
          "to_longitude": 105.83237429136706,
          "from_longitude": 105.8319373842925
        }
      },
      {
        "id": 531,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83385161477135,
            21.015154019544532,
            105.83467844188976,
            21.0156679087855
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83385161477135,
              21.015154019544532
            ],
            [
              105.83385553643025,
              21.015157454255093
            ],
            [
              105.83385616089535,
              21.015158003967997
            ],
            [
              105.83386167746944,
              21.015162828150036
            ],
            [
              105.83386643358055,
              21.015184340426767
            ],
            [
              105.83387650605934,
              21.01522986055317
            ],
            [
              105.83388684845443,
              21.01524675977649
            ],
            [
              105.8338989032641,
              21.01526645782817
            ],
            [
              105.83397666705896,
              21.01533048199263
            ],
            [
              105.83398375819236,
              21.015335281290614
            ],
            [
              105.83399870242229,
              21.015345245569314
            ],
            [
              105.83403807913149,
              21.01535508916883
            ],
            [
              105.83405307536812,
              21.015359008807913
            ],
            [
              105.83411825655308,
              21.015409258089843
            ],
            [
              105.83412096543378,
              21.0154113466125
            ],
            [
              105.83416240467905,
              21.015454595770183
            ],
            [
              105.8341710284669,
              21.015463596572157
            ],
            [
              105.83417917136387,
              21.01547174618422
            ],
            [
              105.834180316674,
              21.01547289308324
            ],
            [
              105.8342054541854,
              21.0155002585665
            ],
            [
              105.83421510988437,
              21.015510770820416
            ],
            [
              105.8342563104072,
              21.015558823569165
            ],
            [
              105.83426711419473,
              21.015569424125353
            ],
            [
              105.83428833948166,
              21.015583807764212
            ],
            [
              105.83430550313344,
              21.01558982849059
            ],
            [
              105.83432122747493,
              21.015585254222927
            ],
            [
              105.83433743163273,
              21.015568713487305
            ],
            [
              105.83435164278012,
              21.015561187673956
            ],
            [
              105.8343661451534,
              21.015569528281397
            ],
            [
              105.83438273919387,
              21.0155878004226
            ],
            [
              105.83441244930547,
              21.015625969497517
            ],
            [
              105.83443157075004,
              21.015643446141837
            ],
            [
              105.83444577790785,
              21.015657854246456
            ],
            [
              105.83445611976249,
              21.01566443770616
            ],
            [
              105.834467723679,
              21.0156679087855
            ],
            [
              105.83447651244647,
              21.015664542713736
            ],
            [
              105.83447994117552,
              21.015663228865346
            ],
            [
              105.83460748761948,
              21.015614367397422
            ],
            [
              105.83464298572106,
              21.015590294830368
            ],
            [
              105.83464936397618,
              21.01558625683469
            ],
            [
              105.83465820285468,
              21.015580661349663
            ],
            [
              105.83466492835991,
              21.01558094357939
            ],
            [
              105.83467093128478,
              21.01558119593459
            ],
            [
              105.83467844188976,
              21.01559136010491
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 164, Đê La Thành",
          "maTaiSan": "04.O16A.DOPP.533",
          "duongKinh": 100,
          "chieudaiQL": 115.2,
          "chatLieu": "G",
          "from_latitude": 21.015154019544532,
          "to_latitude": 21.01559136010491,
          "to_longitude": 105.83467844188976,
          "from_longitude": 105.83385161477135
        }
      },
      {
        "id": 532,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83726832642722,
            21.018395737236492,
            105.83747820391429,
            21.01899811079354
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83747418439927,
              21.01899811079354
            ],
            [
              105.83747820391429,
              21.018972870288103
            ],
            [
              105.8374778870456,
              21.018971595592976
            ],
            [
              105.83745930637492,
              21.018897095239502
            ],
            [
              105.83742294870424,
              21.018827579677346
            ],
            [
              105.83737424420612,
              21.018749422323737
            ],
            [
              105.8373739073856,
              21.01874888114428
            ],
            [
              105.83736854543854,
              21.01874422883972
            ],
            [
              105.8373680882168,
              21.01874383186551
            ],
            [
              105.83732710850455,
              21.01867536328731
            ],
            [
              105.83731121072111,
              21.018640124586565
            ],
            [
              105.83729008669079,
              21.018600586972457
            ],
            [
              105.83728345085406,
              21.018586926761444
            ],
            [
              105.83727947849562,
              21.018578748654154
            ],
            [
              105.83727775298804,
              21.018537783301966
            ],
            [
              105.83727748266729,
              21.018535024380494
            ],
            [
              105.83727851570266,
              21.018471770144643
            ],
            [
              105.83727871491949,
              21.01845955306188
            ],
            [
              105.83726832642722,
              21.018395737236492
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ Cống Trắng",
          "maTaiSan": "04.O16A.DOPP.534",
          "duongKinh": 100,
          "chieudaiQL": 72.94,
          "chatLieu": "G",
          "from_latitude": 21.01899811079354,
          "to_latitude": 21.018395737236492,
          "to_longitude": 105.83726832642722,
          "from_longitude": 105.83747418439927
        }
      },
      {
        "id": 533,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83454146890088,
            21.014436366560446,
            105.83580917770972,
            21.01516775937597
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83454146890088,
              21.014471511428827
            ],
            [
              105.83454490138591,
              21.0144753730389
            ],
            [
              105.83455413973397,
              21.014484705905257
            ],
            [
              105.834557229474,
              21.014487822229462
            ],
            [
              105.83461372875011,
              21.014436366560446
            ],
            [
              105.83464694299029,
              21.014450678702033
            ],
            [
              105.83471241970385,
              21.014490460652947
            ],
            [
              105.83471584420616,
              21.01449254113953
            ],
            [
              105.83477005134716,
              21.014519423405712
            ],
            [
              105.8348060291894,
              21.014544960779936
            ],
            [
              105.83483111774399,
              21.0145713166099
            ],
            [
              105.83483607124525,
              21.0145833368044
            ],
            [
              105.83483362659956,
              21.014607984252187
            ],
            [
              105.8348137809441,
              21.014673823740168
            ],
            [
              105.83481374017134,
              21.014686720181643
            ],
            [
              105.83483221706996,
              21.014715436061977
            ],
            [
              105.83484257082911,
              21.0147328093633
            ],
            [
              105.83484421796975,
              21.01474669287563
            ],
            [
              105.83484754560105,
              21.014753489570406
            ],
            [
              105.83486477588269,
              21.01477166822268
            ],
            [
              105.83491684633081,
              21.014832010942698
            ],
            [
              105.83494422756556,
              21.014864301429576
            ],
            [
              105.83494715857366,
              21.01486775813754
            ],
            [
              105.8349501457445,
              21.014871281408443
            ],
            [
              105.83496454740886,
              21.01488538413041
            ],
            [
              105.83496557555034,
              21.01488639159936
            ],
            [
              105.83496751092649,
              21.014888287001767
            ],
            [
              105.8349691039128,
              21.014889847181728
            ],
            [
              105.83497215350776,
              21.014892833630448
            ],
            [
              105.83499314411803,
              21.014911895231904
            ],
            [
              105.83499749770652,
              21.014915848956385
            ],
            [
              105.83503360724139,
              21.01494601104422
            ],
            [
              105.83505661548746,
              21.014966189958738
            ],
            [
              105.83508680734666,
              21.014997235530224
            ],
            [
              105.83511149961065,
              21.01502547377259
            ],
            [
              105.8351415751187,
              21.01506308815349
            ],
            [
              105.83516669495525,
              21.01509676350632
            ],
            [
              105.83518841792821,
              21.015127104567426
            ],
            [
              105.83523179602861,
              21.01515020293788
            ],
            [
              105.83524457528549,
              21.015159699025038
            ],
            [
              105.83525287666542,
              21.01516503516561
            ],
            [
              105.83526205809966,
              21.01516775937597
            ],
            [
              105.83530202416638,
              21.015148004734385
            ],
            [
              105.83533604448631,
              21.015127208955935
            ],
            [
              105.83533938640689,
              21.015125165711524
            ],
            [
              105.83539193293569,
              21.015100460125314
            ],
            [
              105.8354451390983,
              21.015070876581554
            ],
            [
              105.83550155058879,
              21.01503010168413
            ],
            [
              105.83552269047043,
              21.015013085892154
            ],
            [
              105.8355242542953,
              21.01501182724628
            ],
            [
              105.83552772884403,
              21.015009590960133
            ],
            [
              105.83552869309047,
              21.01500897022558
            ],
            [
              105.83553370410087,
              21.01500574368547
            ],
            [
              105.83553778154,
              21.015003118761435
            ],
            [
              105.83555064151426,
              21.014998610836788
            ],
            [
              105.83557513059611,
              21.014994676248175
            ],
            [
              105.83559963114651,
              21.014988311022655
            ],
            [
              105.83561662326103,
              21.01499324317136
            ],
            [
              105.83562747163226,
              21.01500216793989
            ],
            [
              105.83564887090745,
              21.015016004103092
            ],
            [
              105.83564949302672,
              21.015016477046693
            ],
            [
              105.83566735956296,
              21.015030046043627
            ],
            [
              105.83573888671096,
              21.0150788635265
            ],
            [
              105.835748770933,
              21.015088941924493
            ],
            [
              105.83576710428429,
              21.015107218932588
            ],
            [
              105.83577888969339,
              21.01511547070161
            ],
            [
              105.8358028289318,
              21.015139569447783
            ],
            [
              105.83580834676128,
              21.015145126073772
            ],
            [
              105.83580917770972,
              21.01514596018382
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 126, Đê La Thành",
          "maTaiSan": "04.O16A.DOPP.535",
          "duongKinh": 100,
          "chieudaiQL": 196.98,
          "chatLieu": "G",
          "from_latitude": 21.014471511428827,
          "to_latitude": 21.01514596018382,
          "to_longitude": 105.83580917770972,
          "from_longitude": 105.83454146890088
        }
      },
      {
        "id": 534,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83515714866789,
            21.01377295857787,
            105.83575199621,
            21.014406792894864
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83515714866789,
              21.01377295857787
            ],
            [
              105.83516516084926,
              21.01377871614489
            ],
            [
              105.83517473092647,
              21.01378837160772
            ],
            [
              105.83532363437243,
              21.013938555905046
            ],
            [
              105.83532443678506,
              21.013938931319853
            ],
            [
              105.83532651803593,
              21.01393990381006
            ],
            [
              105.8353270229124,
              21.013940570620747
            ],
            [
              105.83536902245137,
              21.013996007565204
            ],
            [
              105.83555856528774,
              21.01424453660972
            ],
            [
              105.83560731337346,
              21.01430281711594
            ],
            [
              105.83560980694385,
              21.014305798155203
            ],
            [
              105.83562587232257,
              21.014325004901625
            ],
            [
              105.83563462551157,
              21.01432614965055
            ],
            [
              105.83568998634571,
              21.01433339256319
            ],
            [
              105.83569211451378,
              21.01433587862251
            ],
            [
              105.83569377890177,
              21.01433782321866
            ],
            [
              105.83571980779435,
              21.01436822525782
            ],
            [
              105.83575199621,
              21.014406792894864
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 96, Đê La Thành",
          "maTaiSan": "04.O16A.DOPP.536",
          "duongKinh": 100,
          "chieudaiQL": 97.94,
          "chatLieu": "G",
          "from_latitude": 21.01377295857787,
          "to_latitude": 21.014406792894864,
          "to_longitude": 105.83575199621,
          "from_longitude": 105.83515714866789
        }
      },
      {
        "id": 535,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83558485048333,
            21.014384451948146,
            105.83685811336053,
            21.015656991642384
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83685811336053,
              21.014384451948146
            ],
            [
              105.83682621892589,
              21.014404148277187
            ],
            [
              105.83676791010134,
              21.014448707505842
            ],
            [
              105.83667942022544,
              21.01451042375695
            ],
            [
              105.83664669438609,
              21.01453145549475
            ],
            [
              105.83663012728674,
              21.014538046500807
            ],
            [
              105.83662249713164,
              21.014538712665473
            ],
            [
              105.83661594307438,
              21.014536220382077
            ],
            [
              105.83657996294997,
              21.014526539514165
            ],
            [
              105.83656083770643,
              21.01453910076008
            ],
            [
              105.83655972690072,
              21.01453983060786
            ],
            [
              105.83655629976367,
              21.01454193932669
            ],
            [
              105.83653828596096,
              21.014553022849512
            ],
            [
              105.83653661363572,
              21.01455414203811
            ],
            [
              105.83648343656994,
              21.014589733115848
            ],
            [
              105.83641566185003,
              21.014643031398673
            ],
            [
              105.83637542825797,
              21.014675826557212
            ],
            [
              105.83637376567007,
              21.014676449826215
            ],
            [
              105.83636845921401,
              21.014678439522154
            ],
            [
              105.83636638673912,
              21.014679216547723
            ],
            [
              105.83634090827343,
              21.01469253338741
            ],
            [
              105.83630763155753,
              21.014712773835782
            ],
            [
              105.83624208326079,
              21.01477067206839
            ],
            [
              105.83620897819748,
              21.01481049355918
            ],
            [
              105.83619460279775,
              21.014834079660606
            ],
            [
              105.83619023108027,
              21.01484603274134
            ],
            [
              105.836187983644,
              21.01485217939049
            ],
            [
              105.83617606697867,
              21.01488198565427
            ],
            [
              105.83612753958641,
              21.014920146930002
            ],
            [
              105.83606483491472,
              21.01497171577736
            ],
            [
              105.83602718838269,
              21.015000129247294
            ],
            [
              105.83602473975476,
              21.015001418439038
            ],
            [
              105.83602388901588,
              21.015001866102708
            ],
            [
              105.8360212260037,
              21.015003178025268
            ],
            [
              105.83601008186082,
              21.015008672907026
            ],
            [
              105.8359291513806,
              21.015060933715155
            ],
            [
              105.83592466859538,
              21.015064008639307
            ],
            [
              105.83589984745245,
              21.015081027212194
            ],
            [
              105.83584131675582,
              21.015122808906924
            ],
            [
              105.83580917770972,
              21.01514596018382
            ],
            [
              105.83580686105371,
              21.015147628981683
            ],
            [
              105.83580401761921,
              21.015149535205765
            ],
            [
              105.83579801832576,
              21.015153556930223
            ],
            [
              105.83573825613527,
              21.015192719870477
            ],
            [
              105.83573179419098,
              21.015197957799
            ],
            [
              105.83572609450246,
              21.01520257779029
            ],
            [
              105.83563137055147,
              21.015285729654906
            ],
            [
              105.83560659262798,
              21.01531967078737
            ],
            [
              105.83559952177913,
              21.015333026270095
            ],
            [
              105.8355906922342,
              21.01535203012371
            ],
            [
              105.83558686914606,
              21.01536284423911
            ],
            [
              105.83558584447185,
              21.015365741396398
            ],
            [
              105.83558505465778,
              21.01536797443344
            ],
            [
              105.83558485048333,
              21.015372420203676
            ],
            [
              105.83558492961603,
              21.015373501877896
            ],
            [
              105.83558666895937,
              21.0153973610409
            ],
            [
              105.83560197603694,
              21.015458625637553
            ],
            [
              105.83562402573317,
              21.015487672518905
            ],
            [
              105.83566111577257,
              21.015520947578715
            ],
            [
              105.8356754855586,
              21.015530168464423
            ],
            [
              105.835676170534,
              21.015531218259493
            ],
            [
              105.83567684095499,
              21.01553224554541
            ],
            [
              105.83567731311352,
              21.015532908902546
            ],
            [
              105.83570060112926,
              21.01556563213619
            ],
            [
              105.83571954210879,
              21.015588849509246
            ],
            [
              105.83574024759595,
              21.015615402851907
            ],
            [
              105.83575283732795,
              21.01563593090869
            ],
            [
              105.83577369558633,
              21.015655717442513
            ],
            [
              105.83577448185481,
              21.015656991642384
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 260, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DOPP.537",
          "duongKinh": 100,
          "chieudaiQL": 216.37,
          "chatLieu": "G",
          "from_latitude": 21.014384451948146,
          "to_latitude": 21.015656991642384,
          "to_longitude": 105.83577448185481,
          "from_longitude": 105.83685811336053
        }
      },
      {
        "id": 536,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83632853356838,
            21.014564402954655,
            105.83698521084106,
            21.015059423436853
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83632853356838,
              21.015059423436853
            ],
            [
              105.83633525580011,
              21.015051358921085
            ],
            [
              105.8363880517293,
              21.015007414031253
            ],
            [
              105.83639327843382,
              21.01500354060056
            ],
            [
              105.83641116764538,
              21.014990285449798
            ],
            [
              105.83650394363512,
              21.01492310633014
            ],
            [
              105.8365958436378,
              21.014851862443685
            ],
            [
              105.8366762542075,
              21.014788026354545
            ],
            [
              105.83669146244351,
              21.01477666396953
            ],
            [
              105.836797359609,
              21.014697540961567
            ],
            [
              105.8368211377615,
              21.01467791978318
            ],
            [
              105.83687747931056,
              21.01463142827995
            ],
            [
              105.83696439274664,
              21.014572157484427
            ],
            [
              105.83697834374149,
              21.014567354151747
            ],
            [
              105.83698047609126,
              21.01456662017904
            ],
            [
              105.83698521084106,
              21.014564402954655
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 256, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DOPP.538",
          "duongKinh": 100,
          "chieudaiQL": 87.85,
          "chatLieu": "G",
          "from_latitude": 21.015059423436853,
          "to_latitude": 21.014564402954655,
          "to_longitude": 105.83698521084106,
          "from_longitude": 105.83632853356838
        }
      },
      {
        "id": 537,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83610098011086,
            21.01488198565427,
            105.83645948907655,
            21.01565142122616
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83617606697867,
              21.01488198565427
            ],
            [
              105.83619029930017,
              21.014895197618937
            ],
            [
              105.83621478403342,
              21.014917925240866
            ],
            [
              105.83626111719063,
              21.01496930503513
            ],
            [
              105.8362916623055,
              21.015009491989353
            ],
            [
              105.83631547908139,
              21.01504474066507
            ],
            [
              105.83632853356838,
              21.015059423436853
            ],
            [
              105.83628745351676,
              21.015100013047725
            ],
            [
              105.83627624812986,
              21.015109360499665
            ],
            [
              105.8362726451277,
              21.015112365172907
            ],
            [
              105.83626917809173,
              21.01511525717794
            ],
            [
              105.83622081135319,
              21.015154982075643
            ],
            [
              105.83616595900357,
              21.01519052439101
            ],
            [
              105.83615868094786,
              21.01519689718325
            ],
            [
              105.83610098011086,
              21.01524742261537
            ],
            [
              105.83614307646474,
              21.01529871035654
            ],
            [
              105.83625962920068,
              21.01544363880344
            ],
            [
              105.83627736636242,
              21.0154650402265
            ],
            [
              105.83632602713213,
              21.01552375447612
            ],
            [
              105.83635672122173,
              21.015560789330515
            ],
            [
              105.836456388501,
              21.015648270669494
            ],
            [
              105.83645948907655,
              21.01565142122616
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 256, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DOPP.539",
          "duongKinh": 100,
          "chieudaiQL": 116.62,
          "chatLieu": "G",
          "from_latitude": 21.01488198565427,
          "to_latitude": 21.01565142122616,
          "to_longitude": 105.83645948907655,
          "from_longitude": 105.83617606697867
        }
      },
      {
        "id": 538,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83577448185481,
            21.01505937500099,
            105.8373336585533,
            21.015934603699886
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83577448185481,
              21.015656991642384
            ],
            [
              105.83584965818874,
              21.01561038896862
            ],
            [
              105.83586674107009,
              21.015597934478013
            ],
            [
              105.83587445236702,
              21.01559231285817
            ],
            [
              105.83599302539406,
              21.01553155473053
            ],
            [
              105.83606547752967,
              21.015634255688052
            ],
            [
              105.83611291897277,
              21.015701503331197
            ],
            [
              105.83612505543067,
              21.015718706107876
            ],
            [
              105.8362004189634,
              21.01581957901752
            ],
            [
              105.83626081439886,
              21.015904844589805
            ],
            [
              105.83629054046025,
              21.015885221340458
            ],
            [
              105.83629300069835,
              21.015883596991394
            ],
            [
              105.8363226232425,
              21.015864041987328
            ],
            [
              105.83638141113963,
              21.015934603699886
            ],
            [
              105.83639343994095,
              21.01592610844877
            ],
            [
              105.83639630610519,
              21.015924137070904
            ],
            [
              105.8364873832675,
              21.015861505004484
            ],
            [
              105.8364893812064,
              21.015859951570707
            ],
            [
              105.83651058846938,
              21.015843461905824
            ],
            [
              105.83651418495191,
              21.01584066590428
            ],
            [
              105.83646482976548,
              21.01578217461339
            ],
            [
              105.83643015051359,
              21.01573741603298
            ],
            [
              105.83645948907655,
              21.01565142122616
            ],
            [
              105.83651161485628,
              21.01560646888292
            ],
            [
              105.83658739014642,
              21.015541745169926
            ],
            [
              105.83664588649776,
              21.015507785312543
            ],
            [
              105.83667068568845,
              21.01549338789671
            ],
            [
              105.83667382777806,
              21.015491564189453
            ],
            [
              105.83667668317378,
              21.015489906278887
            ],
            [
              105.83669335531647,
              21.015480227529018
            ],
            [
              105.83680350068668,
              21.01541021069767
            ],
            [
              105.83686284733167,
              21.01537263820503
            ],
            [
              105.83687472969392,
              21.01536549486513
            ],
            [
              105.83688460545847,
              21.01535955816714
            ],
            [
              105.83688778169761,
              21.015357649385006
            ],
            [
              105.83694613506671,
              21.015322570132955
            ],
            [
              105.83703560431475,
              21.015263226205917
            ],
            [
              105.83704110674056,
              21.015260497590624
            ],
            [
              105.8370687003967,
              21.01524681256214
            ],
            [
              105.83707267197576,
              21.015243493543412
            ],
            [
              105.8370797457488,
              21.01523758145936
            ],
            [
              105.83717345997798,
              21.015169633236617
            ],
            [
              105.83717404860079,
              21.015168929438197
            ],
            [
              105.83717653209742,
              21.015165960964787
            ],
            [
              105.83717713356869,
              21.01516549103819
            ],
            [
              105.83722534041733,
              21.01512784317494
            ],
            [
              105.83728824193989,
              21.015086614858216
            ],
            [
              105.83733286185634,
              21.01505985311475
            ],
            [
              105.8373336585533,
              21.01505937500099
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 218, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DOPP.540",
          "duongKinh": 100,
          "chieudaiQL": 249.24,
          "chatLieu": "G",
          "from_latitude": 21.015656991642384,
          "to_latitude": 21.01505937500099,
          "to_longitude": 105.8373336585533,
          "from_longitude": 105.83577448185481
        }
      },
      {
        "id": 539,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83661262537447,
            21.015628400422806,
            105.83766326325149,
            21.01626156573604
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83661262537447,
              21.01626156573604
            ],
            [
              105.8366666291602,
              21.016226640735212
            ],
            [
              105.83671143054082,
              21.01619766806347
            ],
            [
              105.83678508259798,
              21.016147695321305
            ],
            [
              105.83678845541155,
              21.016145499251742
            ],
            [
              105.83679039054033,
              21.01614423967127
            ],
            [
              105.83679312885494,
              21.016142456786284
            ],
            [
              105.83681957079479,
              21.016125241336713
            ],
            [
              105.8368810619455,
              21.0160888884756
            ],
            [
              105.83690936130293,
              21.016070109426792
            ],
            [
              105.83691325998544,
              21.016067513349274
            ],
            [
              105.83698645316849,
              21.01601876574576
            ],
            [
              105.83696378155817,
              21.015963142032312
            ],
            [
              105.83699991560098,
              21.015938109339775
            ],
            [
              105.83702632015536,
              21.01591816991931
            ],
            [
              105.83703696819308,
              21.015911164511135
            ],
            [
              105.8370414545553,
              21.015908213281975
            ],
            [
              105.83708668809439,
              21.015878453487083
            ],
            [
              105.83712843727339,
              21.01585210423877
            ],
            [
              105.83713781248174,
              21.015846101343772
            ],
            [
              105.83713901733435,
              21.015845330384284
            ],
            [
              105.83714253226265,
              21.015843263672938
            ],
            [
              105.83715015274059,
              21.015838784118596
            ],
            [
              105.83717886371113,
              21.015863261113754
            ],
            [
              105.83722560391071,
              21.015917656290604
            ],
            [
              105.83729732981435,
              21.015870433194657
            ],
            [
              105.83734274149207,
              21.015842612581924
            ],
            [
              105.83739569928917,
              21.015811299079044
            ],
            [
              105.83739723682275,
              21.015810157061605
            ],
            [
              105.83739907037652,
              21.015808796813296
            ],
            [
              105.83740080128943,
              21.01580766016655
            ],
            [
              105.83742483187544,
              21.01579188629874
            ],
            [
              105.8374904426108,
              21.01574827902448
            ],
            [
              105.83750277880277,
              21.015740065796102
            ],
            [
              105.83755163939766,
              21.015705780034526
            ],
            [
              105.83766208846473,
              21.015629214592632
            ],
            [
              105.83766326325149,
              21.015628400422806
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 178, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DOPP.541",
          "duongKinh": 100,
          "chieudaiQL": 145.07,
          "chatLieu": "G",
          "from_latitude": 21.01626156573604,
          "to_latitude": 21.015628400422806,
          "to_longitude": 105.83766326325149,
          "from_longitude": 105.83661262537447
        }
      },
      {
        "id": 540,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83715346862076,
            21.0160669332964,
            105.8379274693479,
            21.016314653996247
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8379274693479,
              21.0160669332964
            ],
            [
              105.83791529289813,
              21.016071886033338
            ],
            [
              105.8378956125932,
              21.016081185008755
            ],
            [
              105.83783273305018,
              21.016101247293765
            ],
            [
              105.83772010382621,
              21.01613696624812
            ],
            [
              105.83771606410092,
              21.016138247040907
            ],
            [
              105.83771268598338,
              21.01613931864072
            ],
            [
              105.83770142526386,
              21.016142889436935
            ],
            [
              105.83767995912936,
              21.01614969705743
            ],
            [
              105.83766271036033,
              21.01615516700924
            ],
            [
              105.83756702806323,
              21.016188222812314
            ],
            [
              105.83748373336188,
              21.016224167511574
            ],
            [
              105.83747201154108,
              21.016214415298773
            ],
            [
              105.83744627755091,
              21.016180960107853
            ],
            [
              105.83740252076552,
              21.016159409603496
            ],
            [
              105.83736006248388,
              21.01618298047939
            ],
            [
              105.83735108462994,
              21.016187447756547
            ],
            [
              105.83734531407964,
              21.016187125708022
            ],
            [
              105.83734261980986,
              21.016186975485475
            ],
            [
              105.837341541137,
              21.016186914859272
            ],
            [
              105.83733927795058,
              21.016186788708406
            ],
            [
              105.8373347062482,
              21.016188238303826
            ],
            [
              105.8372473908451,
              21.016258351773594
            ],
            [
              105.83724488880802,
              21.016260241889864
            ],
            [
              105.83724156201158,
              21.016262753870283
            ],
            [
              105.83717855034585,
              21.016302088659813
            ],
            [
              105.83717828098064,
              21.016302257081808
            ],
            [
              105.8371739561328,
              21.01630467642414
            ],
            [
              105.83716797998058,
              21.016308019761524
            ],
            [
              105.83715346862076,
              21.016314653996247
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 154, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DOPP.542",
          "duongKinh": 100,
          "chieudaiQL": 95.04,
          "chatLieu": "G",
          "from_latitude": 21.0160669332964,
          "to_latitude": 21.016314653996247,
          "to_longitude": 105.83715346862076,
          "from_longitude": 105.8379274693479
        }
      },
      {
        "id": 541,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83755225618012,
            21.016356692936185,
            105.83810056749618,
            21.016563565246038
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83755225618012,
              21.016528557177637
            ],
            [
              105.83755230258409,
              21.016528598497484
            ],
            [
              105.83755551978643,
              21.01653143865267
            ],
            [
              105.83755991830523,
              21.016535321634883
            ],
            [
              105.83758328022219,
              21.016550515359388
            ],
            [
              105.83759179907861,
              21.016556009277064
            ],
            [
              105.83759859562875,
              21.01656039257959
            ],
            [
              105.83760472610662,
              21.016553678538717
            ],
            [
              105.8376143310305,
              21.01654315926961
            ],
            [
              105.83762685873312,
              21.0165294390351
            ],
            [
              105.8376452633785,
              21.01652544377869
            ],
            [
              105.83765664566292,
              21.016540189768822
            ],
            [
              105.83767975027331,
              21.016563565246038
            ],
            [
              105.83774639141878,
              21.01651494893499
            ],
            [
              105.83775446155119,
              21.016509165593487
            ],
            [
              105.83785760835147,
              21.0164352408746
            ],
            [
              105.83784670180786,
              21.01641818301149
            ],
            [
              105.83786039584905,
              21.01640902913591
            ],
            [
              105.83791985455287,
              21.016369283469544
            ],
            [
              105.83792384014814,
              21.016372259832806
            ],
            [
              105.83792922736464,
              21.016376282451535
            ],
            [
              105.83796498745726,
              21.0164076089586
            ],
            [
              105.83798285487742,
              21.016416104286325
            ],
            [
              105.83807937251198,
              21.016370539901807
            ],
            [
              105.8380939925859,
              21.0163609885436
            ],
            [
              105.83810056749618,
              21.016356692936185
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 138, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DOPP.543",
          "duongKinh": 100,
          "chieudaiQL": 82.34,
          "chatLieu": "G",
          "from_latitude": 21.016528557177637,
          "to_latitude": 21.016356692936185,
          "to_longitude": 105.83810056749618,
          "from_longitude": 105.83755225618012
        }
      },
      {
        "id": 542,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83863941432524,
            21.01510979495824,
            105.83908440729415,
            21.015369399752533
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83908440729415,
              21.01510979495824
            ],
            [
              105.83908279731781,
              21.01511105205715
            ],
            [
              105.83904194212587,
              21.01513340872622
            ],
            [
              105.83900866464123,
              21.01515191279869
            ],
            [
              105.83899474341608,
              21.01515979975676
            ],
            [
              105.83897338698762,
              21.015168986989444
            ],
            [
              105.83891981686308,
              21.015198726960445
            ],
            [
              105.83891902093059,
              21.015199168948776
            ],
            [
              105.83891784618457,
              21.015199988546165
            ],
            [
              105.8389105284326,
              21.015205089909344
            ],
            [
              105.83877979948404,
              21.01527865896884
            ],
            [
              105.83877675779982,
              21.0152803702194
            ],
            [
              105.83877344378749,
              21.015282235457367
            ],
            [
              105.83876169994265,
              21.015288844438974
            ],
            [
              105.83865952661773,
              21.01535583896084
            ],
            [
              105.83863941432524,
              21.015369399752533
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 143, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DOPP.544",
          "duongKinh": 100,
          "chieudaiQL": 57.96,
          "chatLieu": "G",
          "from_latitude": 21.01510979495824,
          "to_latitude": 21.015369399752533,
          "to_longitude": 105.83863941432524,
          "from_longitude": 105.83908440729415
        }
      },
      {
        "id": 543,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83815152875373,
            21.013491417539697,
            105.8391029554318,
            21.01485527916403
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83815152875373,
              21.013693254206224
            ],
            [
              105.83815328400131,
              21.013691657691428
            ],
            [
              105.83819710876438,
              21.013651805609648
            ],
            [
              105.83833602663071,
              21.013499265715712
            ],
            [
              105.8383582195274,
              21.013491417539697
            ],
            [
              105.83837363596355,
              21.0134930866054
            ],
            [
              105.83837936768202,
              21.013493707780576
            ],
            [
              105.83848915331278,
              21.013586381312
            ],
            [
              105.83849006760332,
              21.013587152675733
            ],
            [
              105.8384915115955,
              21.013588211363945
            ],
            [
              105.83850279404383,
              21.013596481681507
            ],
            [
              105.8386311432021,
              21.01370863017211
            ],
            [
              105.83864244949748,
              21.013718238037153
            ],
            [
              105.83869640232254,
              21.013764084388363
            ],
            [
              105.83882270104368,
              21.01385302726416
            ],
            [
              105.83885150441495,
              21.01387547308244
            ],
            [
              105.83885488298628,
              21.013878105580904
            ],
            [
              105.83886017354345,
              21.01388222890758
            ],
            [
              105.8388740810508,
              21.013893065864192
            ],
            [
              105.83896897957713,
              21.0139852262725
            ],
            [
              105.8390417446253,
              21.014050443315146
            ],
            [
              105.83908635976276,
              21.01405526960179
            ],
            [
              105.83908907312525,
              21.014093233175004
            ],
            [
              105.83908925129843,
              21.01409550751746
            ],
            [
              105.83908942842383,
              21.01409776651027
            ],
            [
              105.8391023892187,
              21.014263332185507
            ],
            [
              105.83910205661901,
              21.014273518579053
            ],
            [
              105.83909898211759,
              21.014451811179303
            ],
            [
              105.83909850891763,
              21.014594723407964
            ],
            [
              105.83909874857895,
              21.01459956350614
            ],
            [
              105.8391029554318,
              21.014684399740837
            ],
            [
              105.83910059251798,
              21.014687420936724
            ],
            [
              105.83904152067942,
              21.01476296436707
            ],
            [
              105.83908593105039,
              21.014825382115813
            ],
            [
              105.83909581307822,
              21.014844833981947
            ],
            [
              105.83909777493471,
              21.01484956261861
            ],
            [
              105.8390992609401,
              21.014853141991686
            ],
            [
              105.83910014729048,
              21.01485527916403
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 119, Hồ Ba Mẫu",
          "maTaiSan": "04.O16A.DOPP.545",
          "duongKinh": 100,
          "chieudaiQL": 227.79,
          "chatLieu": "G",
          "from_latitude": 21.013693254206224,
          "to_latitude": 21.01485527916403,
          "to_longitude": 105.83910014729048,
          "from_longitude": 105.83815152875373
        }
      },
      {
        "id": 544,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83675624277939,
            21.013494070873442,
            105.83766781393248,
            21.014252277464816
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83675624277939,
              21.014252277464816
            ],
            [
              105.83675976025674,
              21.0142508457161
            ],
            [
              105.8367772401261,
              21.014243726338933
            ],
            [
              105.83677928534713,
              21.014242893442677
            ],
            [
              105.83687541455218,
              21.014181504469917
            ],
            [
              105.8368872288959,
              21.014171668953715
            ],
            [
              105.83690642290206,
              21.0141631700375
            ],
            [
              105.83691595691806,
              21.01415909656557
            ],
            [
              105.83695281788448,
              21.014139947530463
            ],
            [
              105.83695970877227,
              21.014135918671165
            ],
            [
              105.836969966006,
              21.014129922286884
            ],
            [
              105.8369915306481,
              21.014108403462597
            ],
            [
              105.83701020118872,
              21.014080394755116
            ],
            [
              105.83701973238524,
              21.014062198475887
            ],
            [
              105.83706342547957,
              21.014028670797416
            ],
            [
              105.8371275100595,
              21.013979220997992
            ],
            [
              105.83713029171268,
              21.013976420864548
            ],
            [
              105.83713130853101,
              21.013975397927734
            ],
            [
              105.83713252048291,
              21.01397417803114
            ],
            [
              105.83713282138513,
              21.013973973325147
            ],
            [
              105.83719438606394,
              21.013932004661907
            ],
            [
              105.83725204020216,
              21.013889985672517
            ],
            [
              105.83738283063612,
              21.013796140100855
            ],
            [
              105.83741273546995,
              21.01377314495387
            ],
            [
              105.83742612396149,
              21.013761332625002
            ],
            [
              105.83742827098013,
              21.013759437931594
            ],
            [
              105.83744738166342,
              21.01374257641279
            ],
            [
              105.83745227011937,
              21.013739122808765
            ],
            [
              105.83750599620363,
              21.013701172225215
            ],
            [
              105.8375554357828,
              21.013664918207553
            ],
            [
              105.83755653773724,
              21.013663983365184
            ],
            [
              105.83761365701012,
              21.01361355335671
            ],
            [
              105.83761685876833,
              21.013611227146644
            ],
            [
              105.83765091890292,
              21.01358648544105
            ],
            [
              105.83766534396675,
              21.01357168283461
            ],
            [
              105.83766781393248,
              21.013561474175432
            ],
            [
              105.8376111622058,
              21.013494070873442
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 241, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DOPP.546",
          "duongKinh": 100,
          "chieudaiQL": 132.28,
          "chatLieu": "G",
          "from_latitude": 21.014252277464816,
          "to_latitude": 21.013494070873442,
          "to_longitude": 105.8376111622058,
          "from_longitude": 105.83675624277939
        }
      },
      {
        "id": 545,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83756548257836,
            21.0141530741005,
            105.83832431719225,
            21.015085206050333
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83756548257836,
              21.0141530741005
            ],
            [
              105.83758375838536,
              21.014187386134477
            ],
            [
              105.83761792718091,
              21.01425736140793
            ],
            [
              105.83763375230986,
              21.014288451957906
            ],
            [
              105.83764603871865,
              21.01431062162193
            ],
            [
              105.83765658436876,
              21.014330610439956
            ],
            [
              105.83767765596518,
              21.01436809165906
            ],
            [
              105.83768449199083,
              21.014380250624605
            ],
            [
              105.8376859543996,
              21.014383917740243
            ],
            [
              105.8376960010595,
              21.01440911700277
            ],
            [
              105.8376967045515,
              21.01441020824671
            ],
            [
              105.83769804167838,
              21.014412283594854
            ],
            [
              105.8377002103932,
              21.01441565097519
            ],
            [
              105.8377294544888,
              21.01446104303004
            ],
            [
              105.83777530146676,
              21.014516936402227
            ],
            [
              105.83779246697645,
              21.01452966140062
            ],
            [
              105.83779915023102,
              21.01453461519094
            ],
            [
              105.83780344765887,
              21.014537803184464
            ],
            [
              105.83781469318969,
              21.0145477239181
            ],
            [
              105.83782409040788,
              21.014556014401077
            ],
            [
              105.83786572985444,
              21.01460517054329
            ],
            [
              105.83792215661957,
              21.01466733253185
            ],
            [
              105.8379231090204,
              21.01466838190376
            ],
            [
              105.83792676249736,
              21.0146714935817
            ],
            [
              105.83793001389533,
              21.014674262208224
            ],
            [
              105.83795698625082,
              21.01470746736629
            ],
            [
              105.83798724293814,
              21.01475604908775
            ],
            [
              105.83799036547789,
              21.014761062859563
            ],
            [
              105.8379943217914,
              21.014767414715532
            ],
            [
              105.83801485318826,
              21.014800293007976
            ],
            [
              105.83803909559957,
              21.01483848655617
            ],
            [
              105.83807476738068,
              21.014888332293406
            ],
            [
              105.83810269963465,
              21.014912896971932
            ],
            [
              105.83814959331897,
              21.014946899054483
            ],
            [
              105.8382299519142,
              21.01491209031889
            ],
            [
              105.83825461094524,
              21.01495648602695
            ],
            [
              105.83828126263509,
              21.015012328405867
            ],
            [
              105.83831030087794,
              21.015054741627686
            ],
            [
              105.83832121437693,
              21.015070127563195
            ],
            [
              105.83832431719225,
              21.015085206050333
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 165, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DOPP.547",
          "duongKinh": 100,
          "chieudaiQL": 139.12,
          "chatLieu": "G",
          "from_latitude": 21.0141530741005,
          "to_latitude": 21.015085206050333,
          "to_longitude": 105.83832431719225,
          "from_longitude": 105.83756548257836
        }
      },
      {
        "id": 546,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83792953263593,
            21.015369399752533,
            105.83863941432524,
            21.016070364417295
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83863941432524,
              21.015369399752533
            ],
            [
              105.83858263912471,
              21.015399524838248
            ],
            [
              105.83851528278836,
              21.015452049623185
            ],
            [
              105.83851014683,
              21.01545752228009
            ],
            [
              105.83850400681533,
              21.015464063883226
            ],
            [
              105.8384893247185,
              21.015479707825474
            ],
            [
              105.83844400187212,
              21.015531793409316
            ],
            [
              105.83844183221291,
              21.015534286161337
            ],
            [
              105.83841026778263,
              21.015563462301106
            ],
            [
              105.83840658623537,
              21.01556686482411
            ],
            [
              105.8383443541548,
              21.015634563743873
            ],
            [
              105.8382991611107,
              21.015675812673788
            ],
            [
              105.8382834023979,
              21.015688672748524
            ],
            [
              105.8382921304764,
              21.015706649962674
            ],
            [
              105.83832106143144,
              21.015793178063657
            ],
            [
              105.83832241637343,
              21.015797232281777
            ],
            [
              105.83833069288957,
              21.01581410075312
            ],
            [
              105.83827285684752,
              21.015852043854885
            ],
            [
              105.83818987458405,
              21.015905619186096
            ],
            [
              105.83818863047998,
              21.015906422862354
            ],
            [
              105.83818550410373,
              21.015911917221864
            ],
            [
              105.83818480477031,
              21.01591243279533
            ],
            [
              105.83818196048442,
              21.01591452783653
            ],
            [
              105.83818087220482,
              21.015915329841786
            ],
            [
              105.83816520384313,
              21.015927494881883
            ],
            [
              105.83812915505844,
              21.015957247644664
            ],
            [
              105.83809151939204,
              21.015981378422737
            ],
            [
              105.83803226211143,
              21.016018401739217
            ],
            [
              105.83796766592884,
              21.01605523275278
            ],
            [
              105.83794433365976,
              21.01606204056553
            ],
            [
              105.83792953263593,
              21.016070364417295
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 143, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DOPP.548",
          "duongKinh": 100,
          "chieudaiQL": 115.31,
          "chatLieu": "G",
          "from_latitude": 21.015369399752533,
          "to_latitude": 21.016070364417295,
          "to_longitude": 105.83792953263593,
          "from_longitude": 105.83863941432524
        }
      },
      {
        "id": 547,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83715695910372,
            21.014383917740243,
            105.8376859543996,
            21.01479926154035
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83715695910372,
              21.01479926154035
            ],
            [
              105.83717474745713,
              21.014790702670616
            ],
            [
              105.83720939428859,
              21.014766230933805
            ],
            [
              105.8372408724517,
              21.01474640200105
            ],
            [
              105.83736351286903,
              21.01467076489306
            ],
            [
              105.83737030662455,
              21.014666393269856
            ],
            [
              105.8374096553741,
              21.01464107190155
            ],
            [
              105.8373676548591,
              21.01457925060465
            ],
            [
              105.83734972199869,
              21.0145462347943
            ],
            [
              105.83734766815502,
              21.014542249038648
            ],
            [
              105.83737505509517,
              21.01453102265438
            ],
            [
              105.83746192589462,
              21.01448966185386
            ],
            [
              105.8374989282269,
              21.014484460496266
            ],
            [
              105.83751893705225,
              21.01448751606015
            ],
            [
              105.83752280827052,
              21.014488107583066
            ],
            [
              105.83752295597482,
              21.014488030081903
            ],
            [
              105.83754307249109,
              21.014477467201655
            ],
            [
              105.83767366895883,
              21.014391379247314
            ],
            [
              105.83768279076672,
              21.014385839119694
            ],
            [
              105.8376859543996,
              21.014383917740243
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DOPP.549",
          "duongKinh": 100,
          "chieudaiQL": 82.96,
          "chatLieu": "G",
          "from_latitude": 21.01479926154035,
          "to_latitude": 21.014383917740243,
          "to_longitude": 105.8376859543996,
          "from_longitude": 105.83715695910372
        }
      },
      {
        "id": 548,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83779915023102,
            21.01388222890758,
            105.83886017354345,
            21.01453461519094
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83779915023102,
              21.01453461519094
            ],
            [
              105.83780126945457,
              21.014532565275143
            ],
            [
              105.83784611521004,
              21.014485833811325
            ],
            [
              105.83787994311005,
              21.01444463103828
            ],
            [
              105.83792874509018,
              21.01439081505107
            ],
            [
              105.8379300306626,
              21.014389171174226
            ],
            [
              105.83793218155752,
              21.014386420198946
            ],
            [
              105.8379331180743,
              21.014385659587955
            ],
            [
              105.83799878995633,
              21.014332297913906
            ],
            [
              105.83801298087849,
              21.014323617581336
            ],
            [
              105.83801937143708,
              21.014320258626768
            ],
            [
              105.83802658724704,
              21.01432118953461
            ],
            [
              105.83803151792547,
              21.014322882277106
            ],
            [
              105.83805995884683,
              21.014355008963925
            ],
            [
              105.83808639293036,
              21.014382094701933
            ],
            [
              105.83809921543897,
              21.014386400433352
            ],
            [
              105.83814873119202,
              21.014340869756968
            ],
            [
              105.83816690709163,
              21.014322326444276
            ],
            [
              105.8381946547212,
              21.01429854604848
            ],
            [
              105.8382237241237,
              21.014274265975974
            ],
            [
              105.83823061878506,
              21.01426661421562
            ],
            [
              105.83824984103835,
              21.014245280196178
            ],
            [
              105.83827824335565,
              21.014216099797807
            ],
            [
              105.83828200079773,
              21.014213029290463
            ],
            [
              105.8383285900888,
              21.014179172702626
            ],
            [
              105.8383317438288,
              21.01417772103675
            ],
            [
              105.83833338162805,
              21.014176967806854
            ],
            [
              105.83833370714461,
              21.014176692526018
            ],
            [
              105.8383585698619,
              21.014155658572466
            ],
            [
              105.83836971662468,
              21.0141458406972
            ],
            [
              105.83839839285132,
              21.01412058343856
            ],
            [
              105.83840855850595,
              21.014114907161783
            ],
            [
              105.83842059354332,
              21.01411635172323
            ],
            [
              105.83843369540966,
              21.01412950855247
            ],
            [
              105.83846361539018,
              21.01415605137697
            ],
            [
              105.83850366115857,
              21.01419525265498
            ],
            [
              105.83854126201607,
              21.014163953077784
            ],
            [
              105.83856153749338,
              21.01414485111959
            ],
            [
              105.83856308478171,
              21.014143393818852
            ],
            [
              105.83856533920807,
              21.01414127006637
            ],
            [
              105.83857657975759,
              21.014130680362353
            ],
            [
              105.8386452543109,
              21.014068350890657
            ],
            [
              105.83868688457513,
              21.0140305065304
            ],
            [
              105.83872919239273,
              21.01399100953319
            ],
            [
              105.83873459953972,
              21.013988458366534
            ],
            [
              105.83873752089607,
              21.013987080096836
            ],
            [
              105.8387572920941,
              21.01397775167987
            ],
            [
              105.83878860126656,
              21.01395477647006
            ],
            [
              105.83881684714015,
              21.013928955846794
            ],
            [
              105.83885925563165,
              21.013883218850662
            ],
            [
              105.83886017354345,
              21.01388222890758
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngách 165/97, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DOPP.550",
          "duongKinh": 100,
          "chieudaiQL": 153.5,
          "chatLieu": "G",
          "from_latitude": 21.01453461519094,
          "to_latitude": 21.01388222890758,
          "to_longitude": 105.83886017354345,
          "from_longitude": 105.83779915023102
        }
      },
      {
        "id": 549,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8382299519142,
            21.014598049653458,
            105.8387021722846,
            21.01491209031889
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8382299519142,
              21.01491209031889
            ],
            [
              105.8382436345557,
              21.01490126912011
            ],
            [
              105.83825104743558,
              21.014893393515543
            ],
            [
              105.83825438858538,
              21.014889843641207
            ],
            [
              105.83829505633386,
              21.014853041917792
            ],
            [
              105.83832271080209,
              21.014838568779687
            ],
            [
              105.83833496983165,
              21.014833954318238
            ],
            [
              105.83833725250018,
              21.014833094943015
            ],
            [
              105.83833965324068,
              21.014832191631207
            ],
            [
              105.8383492609917,
              21.014828574747163
            ],
            [
              105.8383799846909,
              21.01482214052457
            ],
            [
              105.83842074108117,
              21.01482925850596
            ],
            [
              105.83849085823468,
              21.014794858699656
            ],
            [
              105.83851063278256,
              21.014785935839804
            ],
            [
              105.83852980904352,
              21.01477805192438
            ],
            [
              105.83856295295121,
              21.014760951310738
            ],
            [
              105.83856609997002,
              21.014759328060894
            ],
            [
              105.83856855412512,
              21.01475799996737
            ],
            [
              105.8385701087057,
              21.014757157726095
            ],
            [
              105.83859107761738,
              21.01473836576437
            ],
            [
              105.83861181980484,
              21.014719454788917
            ],
            [
              105.83861247825674,
              21.01471885451228
            ],
            [
              105.83861425929538,
              21.014717224446287
            ],
            [
              105.83861444017616,
              21.014717059167936
            ],
            [
              105.83861782826453,
              21.01471210002599
            ],
            [
              105.83862036909528,
              21.01470838157385
            ],
            [
              105.8386209196195,
              21.014707574990883
            ],
            [
              105.83864770609809,
              21.014665525232335
            ],
            [
              105.83867199329168,
              21.01463015358287
            ],
            [
              105.8386725725058,
              21.014629317051934
            ],
            [
              105.83870075582199,
              21.014599508117
            ],
            [
              105.83870197721791,
              21.01459825835658
            ],
            [
              105.8387021722846,
              21.014598049653458
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngách 165/88, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DOPP.551",
          "duongKinh": 100,
          "chieudaiQL": 63.02,
          "chatLieu": "G",
          "from_latitude": 21.01491209031889,
          "to_latitude": 21.014598049653458,
          "to_longitude": 105.8387021722846,
          "from_longitude": 105.8382299519142
        }
      },
      {
        "id": 550,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83763943223974,
            21.015070127563195,
            105.83832121437693,
            21.015589125447118
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83763943223974,
              21.015589125447118
            ],
            [
              105.83764249340692,
              21.01558779889394
            ],
            [
              105.83767803932425,
              21.015559806382836
            ],
            [
              105.83773677823433,
              21.015517479301735
            ],
            [
              105.83777836949908,
              21.01548115185726
            ],
            [
              105.83778618517951,
              21.015470638680643
            ],
            [
              105.83782902082271,
              21.015416339970248
            ],
            [
              105.83787828521699,
              21.015362416942715
            ],
            [
              105.83787964042722,
              21.015361700334328
            ],
            [
              105.83788193363868,
              21.015360487752442
            ],
            [
              105.83788252621407,
              21.01535997541568
            ],
            [
              105.83795360572334,
              21.015298550232554
            ],
            [
              105.8379569646061,
              21.015295933306856
            ],
            [
              105.83796638039347,
              21.015288597009217
            ],
            [
              105.83802937750472,
              21.01524642588957
            ],
            [
              105.8380937992161,
              21.015206150822618
            ],
            [
              105.83809958774863,
              21.01520384437634
            ],
            [
              105.83811360941493,
              21.01519825750687
            ],
            [
              105.83811600602732,
              21.015197302734627
            ],
            [
              105.83811928085154,
              21.01519599770122
            ],
            [
              105.83813880886773,
              21.01518821686928
            ],
            [
              105.8381489654034,
              21.01518261561887
            ],
            [
              105.83817975337965,
              21.015165635070172
            ],
            [
              105.83820561983909,
              21.01514877992457
            ],
            [
              105.83832121437693,
              21.015070127563195
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 165, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DOPP.552",
          "duongKinh": 100,
          "chieudaiQL": 92.49,
          "chatLieu": "G",
          "from_latitude": 21.015589125447118,
          "to_latitude": 21.015070127563195,
          "to_longitude": 105.83832121437693,
          "from_longitude": 105.83763943223974
        }
      },
      {
        "id": 551,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83730519300148,
            21.01886741365116,
            105.83943379049477,
            21.019008198027482
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83943379049477,
              21.01886741365116
            ],
            [
              105.83943266743579,
              21.018867504096143
            ],
            [
              105.83918187999822,
              21.018887517686846
            ],
            [
              105.83916406883412,
              21.01888862529216
            ],
            [
              105.83914872434501,
              21.01888942808297
            ],
            [
              105.83897414567168,
              21.018898562727127
            ],
            [
              105.83894646865525,
              21.01890001070934
            ],
            [
              105.83866154007134,
              21.018918786496616
            ],
            [
              105.8384472997898,
              21.018932904124956
            ],
            [
              105.83830080519144,
              21.018944765581836
            ],
            [
              105.83811168435808,
              21.018960077703206
            ],
            [
              105.83800736600347,
              21.018966301824893
            ],
            [
              105.83792146395145,
              21.01897142807337
            ],
            [
              105.83791736449115,
              21.01897167226614
            ],
            [
              105.8377872146254,
              21.018979437941304
            ],
            [
              105.83747418439927,
              21.01899811079354
            ],
            [
              105.83746350447915,
              21.01899875227482
            ],
            [
              105.83745254181164,
              21.018999405986094
            ],
            [
              105.83730519300148,
              21.019008198027482
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Khâm Thiên",
          "maTaiSan": "04.O16A.DOPP.553",
          "duongKinh": 150,
          "chieudaiQL": 219.27,
          "chatLieu": "G",
          "from_latitude": 21.01886741365116,
          "to_latitude": 21.019008198027482,
          "to_longitude": 105.83730519300148,
          "from_longitude": 105.83943379049477
        }
      },
      {
        "id": 552,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83584490564847,
            21.01306257587748,
            105.83810056749618,
            21.016356692936185
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83584490564847,
              21.01306257587748
            ],
            [
              105.83585539858157,
              21.013074198508665
            ],
            [
              105.8358631252883,
              21.013082759248782
            ],
            [
              105.83598715405218,
              21.01322011474276
            ],
            [
              105.83605877322462,
              21.013297666857262
            ],
            [
              105.83607211478842,
              21.013312113407693
            ],
            [
              105.8360984059801,
              21.013340582109148
            ],
            [
              105.83610692326012,
              21.013349805531924
            ],
            [
              105.8361119924012,
              21.013355294807383
            ],
            [
              105.83614317855313,
              21.01338906395535
            ],
            [
              105.83624187634514,
              21.013504639630305
            ],
            [
              105.83625175681614,
              21.013518372467693
            ],
            [
              105.83625894247048,
              21.013529949907568
            ],
            [
              105.836264214998,
              21.013538445916
            ],
            [
              105.83626441882899,
              21.01353877368788
            ],
            [
              105.83627689605309,
              21.013556740721977
            ],
            [
              105.83627802673821,
              21.013558370000816
            ],
            [
              105.83627865898536,
              21.013559280053194
            ],
            [
              105.83627925345169,
              21.01356014242037
            ],
            [
              105.83629574725256,
              21.013584073573018
            ],
            [
              105.83635531305167,
              21.01366613949846
            ],
            [
              105.83637243600307,
              21.013689730647275
            ],
            [
              105.83637699979636,
              21.01369601813892
            ],
            [
              105.83640476757603,
              21.013728955910068
            ],
            [
              105.83640978354934,
              21.01373577408028
            ],
            [
              105.83644486924328,
              21.013783472492406
            ],
            [
              105.83648629967581,
              21.013841668590274
            ],
            [
              105.8364903920172,
              21.013847417364936
            ],
            [
              105.8364982237947,
              21.01385841897235
            ],
            [
              105.83650289881629,
              21.01386498591294
            ],
            [
              105.83655953039417,
              21.013944533542773
            ],
            [
              105.8365613753945,
              21.01394712575741
            ],
            [
              105.83656604499839,
              21.01395427259275
            ],
            [
              105.83660953431111,
              21.01403045347306
            ],
            [
              105.83662328860176,
              21.014054547995638
            ],
            [
              105.83663553767929,
              21.014074883472908
            ],
            [
              105.836653508368,
              21.014104717120926
            ],
            [
              105.83665873870106,
              21.014113401193512
            ],
            [
              105.83675624277939,
              21.014252277464816
            ],
            [
              105.83677829123383,
              21.014283682524074
            ],
            [
              105.83685811336053,
              21.014384451948146
            ],
            [
              105.83686402564999,
              21.014391916016013
            ],
            [
              105.83687261436765,
              21.01440375207285
            ],
            [
              105.83691351407732,
              21.014462106017987
            ],
            [
              105.83691548633931,
              21.01446491979545
            ],
            [
              105.83696736735928,
              21.01453894303614
            ],
            [
              105.83697741388984,
              21.01455327745958
            ],
            [
              105.83697811882247,
              21.01455428289322
            ],
            [
              105.83698521084106,
              21.014564402954655
            ],
            [
              105.83698586729062,
              21.014565339078494
            ],
            [
              105.83702837701794,
              21.014623459265806
            ],
            [
              105.83715695910372,
              21.01479926154035
            ],
            [
              105.83718311156039,
              21.014835017951583
            ],
            [
              105.8371861184156,
              21.014839129083846
            ],
            [
              105.83719233083934,
              21.014847623144096
            ],
            [
              105.83727351988135,
              21.014975000931003
            ],
            [
              105.83728010521877,
              21.014985332116822
            ],
            [
              105.83731215882139,
              21.015026364099647
            ],
            [
              105.8373336585533,
              21.01505937500099
            ],
            [
              105.83734070547585,
              21.01507019616957
            ],
            [
              105.83735164968259,
              21.015097648190512
            ],
            [
              105.83735662793708,
              21.015108721603852
            ],
            [
              105.83735882144786,
              21.0151136008634
            ],
            [
              105.83736332437371,
              21.015123616230827
            ],
            [
              105.83740757048338,
              21.015192380390232
            ],
            [
              105.83741477411994,
              21.015204218717475
            ],
            [
              105.83748799285422,
              21.015318482867468
            ],
            [
              105.8374900995674,
              21.015321770168153
            ],
            [
              105.83751560682703,
              21.015374597923213
            ],
            [
              105.83751749675949,
              21.015378512224967
            ],
            [
              105.83756051988955,
              21.015444256558197
            ],
            [
              105.83756624837325,
              21.015455138400156
            ],
            [
              105.83756924387258,
              21.01546082931954
            ],
            [
              105.8375695131859,
              21.015461341024967
            ],
            [
              105.83762493234889,
              21.015566618945602
            ],
            [
              105.83763943223974,
              21.015589125447118
            ],
            [
              105.83765143057786,
              21.01560774865861
            ],
            [
              105.83766326325149,
              21.015628400422806
            ],
            [
              105.83766890808009,
              21.01563825209393
            ],
            [
              105.83771248047626,
              21.015718783068614
            ],
            [
              105.837739171755,
              21.015762777745035
            ],
            [
              105.83774211350848,
              21.015767627120677
            ],
            [
              105.83776203586487,
              21.01580046508183
            ],
            [
              105.8377821670861,
              21.01583229040083
            ],
            [
              105.83778976518457,
              21.015844301992548
            ],
            [
              105.83779423727754,
              21.015851371181398
            ],
            [
              105.83779767384019,
              21.015856803408376
            ],
            [
              105.83779869869909,
              21.01585842416463
            ],
            [
              105.83782967633503,
              21.01590739706108
            ],
            [
              105.83786697587735,
              21.01596636279574
            ],
            [
              105.8379274693479,
              21.0160669332964
            ],
            [
              105.83792953263593,
              21.016070364417295
            ],
            [
              105.83793249404874,
              21.016075286853432
            ],
            [
              105.83798141476319,
              21.016156617854797
            ],
            [
              105.83803142507139,
              21.016244086298418
            ],
            [
              105.83806864307245,
              21.016306505408604
            ],
            [
              105.83806899750685,
              21.016307100693584
            ],
            [
              105.83806937390551,
              21.01630956102775
            ],
            [
              105.83806982404373,
              21.016310240744318
            ],
            [
              105.83810056749618,
              21.016356692936185
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D250_Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DOPP.554",
          "duongKinh": 250,
          "chieudaiQL": 432.97,
          "chatLieu": "G",
          "from_latitude": 21.01306257587748,
          "to_latitude": 21.016356692936185,
          "to_longitude": 105.83810056749618,
          "from_longitude": 105.83584490564847
        }
      },
      {
        "id": 553,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83810056749618,
            21.016356692936185,
            105.83943379049477,
            21.01886741365116
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83810056749618,
              21.016356692936185
            ],
            [
              105.83812824115316,
              21.016397417547836
            ],
            [
              105.83813488309896,
              21.016408044673966
            ],
            [
              105.83820003534336,
              21.016512180618612
            ],
            [
              105.83820316265266,
              21.016517179005923
            ],
            [
              105.83820913385875,
              21.016526723758393
            ],
            [
              105.83822659421392,
              21.016554634958865
            ],
            [
              105.838227733714,
              21.01655645656619
            ],
            [
              105.8382301505329,
              21.016560318585153
            ],
            [
              105.83823392719071,
              21.016566356092675
            ],
            [
              105.83823993334273,
              21.016575956671577
            ],
            [
              105.83827178189533,
              21.01663647199745
            ],
            [
              105.83827567582757,
              21.016643870987586
            ],
            [
              105.83828033406901,
              21.016651536275383
            ],
            [
              105.83828352009792,
              21.016657057338247
            ],
            [
              105.83831990349199,
              21.01671485599113
            ],
            [
              105.83833454244066,
              21.0167403450873
            ],
            [
              105.8383725586573,
              21.01681472970868
            ],
            [
              105.83837473140437,
              21.016818981314298
            ],
            [
              105.83837690609566,
              21.016823236523184
            ],
            [
              105.83839200491144,
              21.016849420636003
            ],
            [
              105.83841160211047,
              21.016883407205455
            ],
            [
              105.83842925861094,
              21.016916622048345
            ],
            [
              105.8384373237106,
              21.016931793491466
            ],
            [
              105.83848014887535,
              21.01699328796067
            ],
            [
              105.83848455920618,
              21.01699962130556
            ],
            [
              105.83850107312963,
              21.01702805107238
            ],
            [
              105.83854507537511,
              21.017118208043048
            ],
            [
              105.83856593362212,
              21.017160945129874
            ],
            [
              105.83862256062281,
              21.017280708937616
            ],
            [
              105.83862281955898,
              21.01728125591796
            ],
            [
              105.83862341232215,
              21.01728800188342
            ],
            [
              105.8386235119867,
              21.017289138550776
            ],
            [
              105.83865335335305,
              21.01736996815507
            ],
            [
              105.83865757091534,
              21.01738139120732
            ],
            [
              105.83867483615784,
              21.01741153427821
            ],
            [
              105.83871268665469,
              21.01752434095388
            ],
            [
              105.83880310588967,
              21.017690657324486
            ],
            [
              105.83880750515463,
              21.01769603781315
            ],
            [
              105.83881110049785,
              21.017700434139034
            ],
            [
              105.83881402365995,
              21.017704010039303
            ],
            [
              105.83885162312579,
              21.017758486828136
            ],
            [
              105.838866445678,
              21.017779961942054
            ],
            [
              105.83893041557134,
              21.01789763277558
            ],
            [
              105.83896758786217,
              21.017966009542917
            ],
            [
              105.83903531809173,
              21.01810567243946
            ],
            [
              105.83903832715895,
              21.018111877195405
            ],
            [
              105.83906859020715,
              21.018187710705288
            ],
            [
              105.8390750049821,
              21.018203786250027
            ],
            [
              105.83909234563575,
              21.018253736590722
            ],
            [
              105.83910014971526,
              21.018276216361954
            ],
            [
              105.83914104974906,
              21.01836264458539
            ],
            [
              105.83918070928397,
              21.01843573017628
            ],
            [
              105.8391948379699,
              21.018472603220822
            ],
            [
              105.83919753036184,
              21.018479630565686
            ],
            [
              105.83921445492555,
              21.018523800506195
            ],
            [
              105.83925884046961,
              21.018609406727503
            ],
            [
              105.83928403076668,
              21.01864921124507
            ],
            [
              105.83928716689293,
              21.018651301185884
            ],
            [
              105.8392922070948,
              21.018654659793395
            ],
            [
              105.83929285111734,
              21.018655088356695
            ],
            [
              105.8393605472564,
              21.018750734475663
            ],
            [
              105.8394000052952,
              21.018805999499044
            ],
            [
              105.83943159123899,
              21.01883540794356
            ],
            [
              105.83943170805573,
              21.018837545296915
            ],
            [
              105.83943187213663,
              21.018840564866313
            ],
            [
              105.83943379049477,
              21.01886741365116
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D250_Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DOPP.555",
          "duongKinh": 250,
          "chieudaiQL": 308.37,
          "chatLieu": "G",
          "from_latitude": 21.016356692936185,
          "to_latitude": 21.01886741365116,
          "to_longitude": 105.83943379049477,
          "from_longitude": 105.83810056749618
        }
      },
      {
        "id": 554,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83856478404891,
            21.01810567243946,
            105.83903531809173,
            21.018496693957577
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83903531809173,
              21.01810567243946
            ],
            [
              105.83903011717254,
              21.018109174599054
            ],
            [
              105.8389838331116,
              21.018140342798752
            ],
            [
              105.83880468626475,
              21.018234657501445
            ],
            [
              105.83878954737915,
              21.018242627226634
            ],
            [
              105.83876445066544,
              21.018255639972857
            ],
            [
              105.838763258416,
              21.01825625823704
            ],
            [
              105.83871943579473,
              21.01827898083187
            ],
            [
              105.83869225989292,
              21.01829949154262
            ],
            [
              105.83865422031589,
              21.01832820017513
            ],
            [
              105.83863607274569,
              21.018342420575742
            ],
            [
              105.83862573303917,
              21.018345920057275
            ],
            [
              105.83862331925528,
              21.018346736729157
            ],
            [
              105.83864579074856,
              21.018373619686756
            ],
            [
              105.83862709816825,
              21.018396173204682
            ],
            [
              105.83860863490455,
              21.018418450106964
            ],
            [
              105.83860853659289,
              21.018424952898332
            ],
            [
              105.83856478404891,
              21.018496693957577
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ Hồ Dài",
          "maTaiSan": "04.O16A.DOPP.556",
          "duongKinh": 100,
          "chieudaiQL": 69.18,
          "chatLieu": "G",
          "from_latitude": 21.01810567243946,
          "to_latitude": 21.018496693957577,
          "to_longitude": 105.83856478404891,
          "from_longitude": 105.83903531809173
        }
      },
      {
        "id": 555,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83900757506625,
            21.017349869826553,
            105.8393429672309,
            21.018048893740822
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8393429672309,
              21.018048893740822
            ],
            [
              105.83925346093355,
              21.01795630693396
            ],
            [
              105.83916908662779,
              21.017815560169776
            ],
            [
              105.83917635981814,
              21.017810726367582
            ],
            [
              105.8392009769048,
              21.01779436406651
            ],
            [
              105.83920703677613,
              21.017790336504618
            ],
            [
              105.83924460548137,
              21.017765367615723
            ],
            [
              105.83933966890307,
              21.017703439659766
            ],
            [
              105.83933625639631,
              21.01769957353884
            ],
            [
              105.83924765617556,
              21.01759919736463
            ],
            [
              105.83921205668344,
              21.017551973619994
            ],
            [
              105.83918983263057,
              21.01754644262236
            ],
            [
              105.83918397814473,
              21.017541788288373
            ],
            [
              105.83918252234342,
              21.0175401741835
            ],
            [
              105.83918080229012,
              21.017538266931265
            ],
            [
              105.83918036457897,
              21.017537402899052
            ],
            [
              105.83917964380537,
              21.01753597845813
            ],
            [
              105.83917907431433,
              21.01753528759169
            ],
            [
              105.83917672275406,
              21.017532436876298
            ],
            [
              105.83914243238118,
              21.01749086354724
            ],
            [
              105.8390771503037,
              21.01742062933891
            ],
            [
              105.83902534327372,
              21.017367940602014
            ],
            [
              105.8390221845453,
              21.017364728059675
            ],
            [
              105.83902015870328,
              21.01736266786258
            ],
            [
              105.83900757506625,
              21.017349869826553
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ Gia Tự A",
          "maTaiSan": "04.O16A.DOPP.557",
          "duongKinh": 100,
          "chieudaiQL": 106.23,
          "chatLieu": "G",
          "from_latitude": 21.018048893740822,
          "to_latitude": 21.017349869826553,
          "to_longitude": 105.83900757506625,
          "from_longitude": 105.8393429672309
        }
      },
      {
        "id": 556,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83726832642722,
            21.017758486828136,
            105.83885162312579,
            21.018395737236492
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83885162312579,
              21.017758486828136
            ],
            [
              105.83884902888863,
              21.017759887875272
            ],
            [
              105.83882262043188,
              21.017776389023723
            ],
            [
              105.8388095305401,
              21.017783497809628
            ],
            [
              105.83874880371107,
              21.017822559979535
            ],
            [
              105.83870242805068,
              21.017849384951976
            ],
            [
              105.83869529900876,
              21.017853508089896
            ],
            [
              105.83861534932731,
              21.017893405628328
            ],
            [
              105.8385536200167,
              21.01792024663727
            ],
            [
              105.8385484591589,
              21.017922656587455
            ],
            [
              105.83853715683014,
              21.017931880923555
            ],
            [
              105.83851291183956,
              21.017940486218503
            ],
            [
              105.83842514828038,
              21.01797886141491
            ],
            [
              105.83840764987362,
              21.0179865121555
            ],
            [
              105.8383643816949,
              21.018004648969246
            ],
            [
              105.83834952792752,
              21.018010277900988
            ],
            [
              105.83833984715712,
              21.018013946633168
            ],
            [
              105.8381660120537,
              21.018070244002057
            ],
            [
              105.83815326809747,
              21.01807437129936
            ],
            [
              105.83812852277099,
              21.018082791135278
            ],
            [
              105.8381070850761,
              21.018090085513034
            ],
            [
              105.83806966414096,
              21.01810143823503
            ],
            [
              105.83798501532829,
              21.01813226678498
            ],
            [
              105.83794607010446,
              21.018146450466475
            ],
            [
              105.83787001383968,
              21.018172881289704
            ],
            [
              105.83786757809615,
              21.018173727865452
            ],
            [
              105.83782785909068,
              21.018184749532338
            ],
            [
              105.83775913753391,
              21.01820788354443
            ],
            [
              105.83767768351808,
              21.018238245502246
            ],
            [
              105.83767164444453,
              21.018240496267538
            ],
            [
              105.83765402352472,
              21.018247064570296
            ],
            [
              105.8376500483547,
              21.018248546463873
            ],
            [
              105.8376462402168,
              21.01824996611566
            ],
            [
              105.8376402395437,
              21.018252203142413
            ],
            [
              105.8375423802428,
              21.01828806154657
            ],
            [
              105.83752151089918,
              21.018296370986924
            ],
            [
              105.83749904153171,
              21.01830531694587
            ],
            [
              105.83746713759628,
              21.01831913527392
            ],
            [
              105.83743811447044,
              21.018331705610514
            ],
            [
              105.8373851672101,
              21.018353689692095
            ],
            [
              105.83733526322418,
              21.018367861246418
            ],
            [
              105.83726832642722,
              21.018395737236492
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ Khâm Đức",
          "maTaiSan": "04.O16A.DOPP.558",
          "duongKinh": 100,
          "chieudaiQL": 178.91,
          "chatLieu": "G",
          "from_latitude": 21.017758486828136,
          "to_latitude": 21.018395737236492,
          "to_longitude": 105.83726832642722,
          "from_longitude": 105.83885162312579
        }
      },
      {
        "id": 557,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83854507537511,
            21.016733090316535,
            105.83939480086391,
            21.017118208043048
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83939480086391,
              21.016960195788855
            ],
            [
              105.83937210896502,
              21.01693327517253
            ],
            [
              105.83936856374437,
              21.01692906828772
            ],
            [
              105.83936190230412,
              21.0169211654322
            ],
            [
              105.83933338542816,
              21.01689674838293
            ],
            [
              105.83932687624183,
              21.016891642961564
            ],
            [
              105.83926841803381,
              21.01684232000292
            ],
            [
              105.83924362862251,
              21.016818229579947
            ],
            [
              105.83924331428122,
              21.016817924034623
            ],
            [
              105.83924157434214,
              21.016816243589872
            ],
            [
              105.83923898135811,
              21.016813739097262
            ],
            [
              105.83920239623812,
              21.0167784075299
            ],
            [
              105.83915944047503,
              21.016739172936347
            ],
            [
              105.83915567483925,
              21.016735456681182
            ],
            [
              105.8391532779053,
              21.016733090316535
            ],
            [
              105.83914312352604,
              21.01673994800136
            ],
            [
              105.83908375066676,
              21.016780044115997
            ],
            [
              105.8390364331576,
              21.01681813159731
            ],
            [
              105.83903075543746,
              21.016822715460048
            ],
            [
              105.83902862952613,
              21.016824432134534
            ],
            [
              105.83901245573105,
              21.01683583923059
            ],
            [
              105.83893661343114,
              21.01689108229124
            ],
            [
              105.83891665449244,
              21.016903714751514
            ],
            [
              105.83887949506968,
              21.01692822180674
            ],
            [
              105.83885807558758,
              21.016942348168673
            ],
            [
              105.83885240924705,
              21.016946216616393
            ],
            [
              105.83877396302681,
              21.01699977167664
            ],
            [
              105.83868893738642,
              21.017051323272405
            ],
            [
              105.83867539774191,
              21.017059532584295
            ],
            [
              105.83865920952144,
              21.01706682101884
            ],
            [
              105.83854987974547,
              21.017116045527594
            ],
            [
              105.83854507537511,
              21.017118208043048
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ Vạn Ứng",
          "maTaiSan": "04.O16A.DOPP.559",
          "duongKinh": 100,
          "chieudaiQL": 112.9,
          "chatLieu": "G",
          "from_latitude": 21.016960195788855,
          "to_latitude": 21.017118208043048,
          "to_longitude": 105.83854507537511,
          "from_longitude": 105.83939480086391
        }
      },
      {
        "id": 558,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83862603407019,
            21.016555880946882,
            105.83885807558758,
            21.016942348168673
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83885807558758,
              21.016942348168673
            ],
            [
              105.83882828311043,
              21.016904318515245
            ],
            [
              105.8387563383884,
              21.01679578420389
            ],
            [
              105.83873276504654,
              21.016748142471382
            ],
            [
              105.83873522029705,
              21.016736339683625
            ],
            [
              105.83872472468853,
              21.016718992629723
            ],
            [
              105.83872065752279,
              21.016712271019287
            ],
            [
              105.83871893705712,
              21.016709427120766
            ],
            [
              105.83871701949421,
              21.016706257226932
            ],
            [
              105.83870969101967,
              21.016694144997317
            ],
            [
              105.83865802278967,
              21.016608750619213
            ],
            [
              105.8386546847808,
              21.01660323302258
            ],
            [
              105.83862603407019,
              21.016555880946882
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ Xã Đằng",
          "maTaiSan": "04.O16A.DOPP.560",
          "duongKinh": 100,
          "chieudaiQL": 49.51,
          "chatLieu": "G",
          "from_latitude": 21.016942348168673,
          "to_latitude": 21.016555880946882,
          "to_longitude": 105.83862603407019,
          "from_longitude": 105.83885807558758
        }
      },
      {
        "id": 559,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83720561620774,
            21.01741153427821,
            105.83867483615784,
            21.018144758163064
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83867483615784,
              21.01741153427821
            ],
            [
              105.83867315556456,
              21.01741238165831
            ],
            [
              105.83861010735639,
              21.017443302724306
            ],
            [
              105.8384947967756,
              21.017490446756266
            ],
            [
              105.83845657782453,
              21.017507861413918
            ],
            [
              105.83843454377396,
              21.017507232078486
            ],
            [
              105.83840114914926,
              21.017509189562904
            ],
            [
              105.83832588610106,
              21.0175337569334
            ],
            [
              105.83825810297719,
              21.017561393600047
            ],
            [
              105.83825483742757,
              21.017562473688816
            ],
            [
              105.83824956896376,
              21.017564217580926
            ],
            [
              105.83824721942162,
              21.017564995092066
            ],
            [
              105.8382392221428,
              21.017567641202252
            ],
            [
              105.83822874042067,
              21.01757067981113
            ],
            [
              105.83822085051884,
              21.017571368105514
            ],
            [
              105.83819311344294,
              21.0175840595838
            ],
            [
              105.83816954632623,
              21.017594842774475
            ],
            [
              105.83813132787903,
              21.017617695668488
            ],
            [
              105.83809561635782,
              21.017641943426167
            ],
            [
              105.83808861652554,
              21.017649221766224
            ],
            [
              105.83807991327254,
              21.017654296502965
            ],
            [
              105.83800580251685,
              21.017699352205753
            ],
            [
              105.83800328019646,
              21.017700885659796
            ],
            [
              105.83784157257352,
              21.017801429690817
            ],
            [
              105.83783912026323,
              21.01780292576553
            ],
            [
              105.83783639759864,
              21.017804585752014
            ],
            [
              105.83783576282858,
              21.017804951071096
            ],
            [
              105.83779286312988,
              21.01782964513674
            ],
            [
              105.83776467312715,
              21.01784065697507
            ],
            [
              105.83773476205491,
              21.01785818862477
            ],
            [
              105.83772982123185,
              21.017861933334895
            ],
            [
              105.83770861918121,
              21.01787800312758
            ],
            [
              105.83770595209012,
              21.017879796515516
            ],
            [
              105.83767603048132,
              21.017899922265908
            ],
            [
              105.83765817743614,
              21.017886968528416
            ],
            [
              105.83763965305876,
              21.017896941011955
            ],
            [
              105.83753459963611,
              21.017976911250035
            ],
            [
              105.83750504765189,
              21.017972108883345
            ],
            [
              105.83744841679402,
              21.01800383703108
            ],
            [
              105.83744674381667,
              21.01800467262
            ],
            [
              105.83743237579088,
              21.01801185081992
            ],
            [
              105.83742894257837,
              21.0180142612641
            ],
            [
              105.83740611067452,
              21.01803028575355
            ],
            [
              105.83738540275382,
              21.018041141473244
            ],
            [
              105.8373784497751,
              21.018044243950765
            ],
            [
              105.83737727814903,
              21.01804476636213
            ],
            [
              105.83729932352892,
              21.018089567914313
            ],
            [
              105.83723190476127,
              21.018131547390887
            ],
            [
              105.83721813265169,
              21.018138468834458
            ],
            [
              105.83721129215684,
              21.018141905847795
            ],
            [
              105.83720561620774,
              21.018144758163064
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 80, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DOPP.561",
          "duongKinh": 100,
          "chieudaiQL": 177.05,
          "chatLieu": "G",
          "from_latitude": 21.01741153427821,
          "to_latitude": 21.018144758163064,
          "to_longitude": 105.83720561620774,
          "from_longitude": 105.83867483615784
        }
      },
      {
        "id": 560,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83778287721475,
            21.01699328796067,
            105.83848014887535,
            21.01735247419987
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83848014887535,
              21.01699328796067
            ],
            [
              105.8384782677015,
              21.016994734261534
            ],
            [
              105.8383976797909,
              21.0170365451541
            ],
            [
              105.83832284018021,
              21.01707383866062
            ],
            [
              105.83826704685686,
              21.0170985331515
            ],
            [
              105.83820573562569,
              21.017120959720646
            ],
            [
              105.8381937844741,
              21.017126822721828
            ],
            [
              105.83815908219245,
              21.017143846953243
            ],
            [
              105.83815296422485,
              21.01714711876603
            ],
            [
              105.83812759682965,
              21.017161419843795
            ],
            [
              105.83812660529905,
              21.017161978404992
            ],
            [
              105.83812471824078,
              21.017162889119344
            ],
            [
              105.83811970175981,
              21.017165310990013
            ],
            [
              105.83810873946946,
              21.017171171823254
            ],
            [
              105.83809554121335,
              21.017177821347108
            ],
            [
              105.83808345871506,
              21.017184281115565
            ],
            [
              105.83806193821398,
              21.017201523999866
            ],
            [
              105.8380199631139,
              21.017223628537018
            ],
            [
              105.83801654807628,
              21.01722532716257
            ],
            [
              105.83801294789782,
              21.017227117925692
            ],
            [
              105.83798574848254,
              21.017240650331832
            ],
            [
              105.837978822988,
              21.017253828159223
            ],
            [
              105.83795961216472,
              21.017265056828283
            ],
            [
              105.83795013139536,
              21.017270597838554
            ],
            [
              105.83794860592072,
              21.017271489608707
            ],
            [
              105.83794427111842,
              21.017274023729268
            ],
            [
              105.83790815732739,
              21.017295132016006
            ],
            [
              105.83778287721475,
              21.01735247419987
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 100, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DOPP.562",
          "duongKinh": 100,
          "chieudaiQL": 83.49,
          "chatLieu": "G",
          "from_latitude": 21.01699328796067,
          "to_latitude": 21.01735247419987,
          "to_longitude": 105.83778287721475,
          "from_longitude": 105.83848014887535
        }
      },
      {
        "id": 561,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83714461355504,
            21.01735247419987,
            105.83800580251685,
            21.017699352205753
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83800580251685,
              21.017699352205753
            ],
            [
              105.83800106117971,
              21.0176928298993
            ],
            [
              105.83793606858892,
              21.017603396697123
            ],
            [
              105.83789457333795,
              21.017548012175098
            ],
            [
              105.83788163989733,
              21.017529752175786
            ],
            [
              105.8378654505585,
              21.01750689544742
            ],
            [
              105.83786292067377,
              21.017501564435573
            ],
            [
              105.83785960233868,
              21.01749457356869
            ],
            [
              105.83785641443521,
              21.017487856639367
            ],
            [
              105.837853843661,
              21.01748243821604
            ],
            [
              105.83782310935575,
              21.01741767850095
            ],
            [
              105.83778287721475,
              21.01735247419987
            ],
            [
              105.83769866712413,
              21.017384975015965
            ],
            [
              105.83761151314074,
              21.0174119110724
            ],
            [
              105.83758641596093,
              21.01741966778751
            ],
            [
              105.8375826794712,
              21.017420822440048
            ],
            [
              105.83757838409666,
              21.01742215055354
            ],
            [
              105.83756674153696,
              21.017425748513435
            ],
            [
              105.83742143774363,
              21.017456644174956
            ],
            [
              105.83735599082291,
              21.01746907856602
            ],
            [
              105.8373429260373,
              21.01747156080403
            ],
            [
              105.8373094891751,
              21.01747956717277
            ],
            [
              105.83730581463554,
              21.01748044693436
            ],
            [
              105.8372526197976,
              21.01749318498073
            ],
            [
              105.83724941794526,
              21.01749275274001
            ],
            [
              105.8372474463919,
              21.017492486959956
            ],
            [
              105.83723479779403,
              21.017493703531173
            ],
            [
              105.83714461355504,
              21.017509788481625
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 100, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DOPP.563",
          "duongKinh": 100,
          "chieudaiQL": 113.49,
          "chatLieu": "G",
          "from_latitude": 21.017699352205753,
          "to_latitude": 21.017509788481625,
          "to_longitude": 105.83714461355504,
          "from_longitude": 105.83800580251685
        }
      },
      {
        "id": 562,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.837205744966,
            21.01706192371072,
            105.8373667298965,
            21.01747956717277
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8373667298965,
              21.01706192371072
            ],
            [
              105.83736663870582,
              21.017061958482106
            ],
            [
              105.83736217820936,
              21.017063689337363
            ],
            [
              105.83723515528182,
              21.017112979327514
            ],
            [
              105.83722847076972,
              21.017115572864963
            ],
            [
              105.837205744966,
              21.017124390882802
            ],
            [
              105.83724014569015,
              21.01721548152611
            ],
            [
              105.83723442627019,
              21.017221913532126
            ],
            [
              105.83726864307484,
              21.017296248462017
            ],
            [
              105.8372825941472,
              21.017333460366693
            ],
            [
              105.83726550559608,
              21.017340431554654
            ],
            [
              105.8373094891751,
              21.01747956717277
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 100, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DOPP.564",
          "duongKinh": 100,
          "chieudaiQL": 62.16,
          "chatLieu": "G",
          "from_latitude": 21.01706192371072,
          "to_latitude": 21.01747956717277,
          "to_longitude": 105.8373094891751,
          "from_longitude": 105.8373667298965
        }
      },
      {
        "id": 563,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83885455253593,
            21.018430475250856,
            105.83897414567168,
            21.018898562727127
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83897414567168,
              21.018898562727127
            ],
            [
              105.838973329119,
              21.018890851419364
            ],
            [
              105.83896942612682,
              21.01880137567234
            ],
            [
              105.83896265014351,
              21.018737586415348
            ],
            [
              105.83896258269439,
              21.01873601875199
            ],
            [
              105.83896189655299,
              21.018719979080064
            ],
            [
              105.83896012588379,
              21.018678589466496
            ],
            [
              105.83894959745761,
              21.018588170007618
            ],
            [
              105.83894399695906,
              21.01854638379829
            ],
            [
              105.83891598044056,
              21.01850415265467
            ],
            [
              105.83890214147623,
              21.018485871916308
            ],
            [
              105.8388792112532,
              21.018455582491228
            ],
            [
              105.83885455253593,
              21.018430475250856
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ Hồ Dài",
          "maTaiSan": "04.O16A.DOPP.565",
          "duongKinh": 100,
          "chieudaiQL": 54.99,
          "chatLieu": "G",
          "from_latitude": 21.018898562727127,
          "to_latitude": 21.018430475250856,
          "to_longitude": 105.83885455253593,
          "from_longitude": 105.83897414567168
        }
      },
      {
        "id": 564,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83798501532829,
            21.01813226678498,
            105.83830080519144,
            21.018944765581836
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83798501532829,
              21.01813226678498
            ],
            [
              105.8379859439332,
              21.018134390205724
            ],
            [
              105.83798628400044,
              21.018135168012602
            ],
            [
              105.83801522169746,
              21.0182013211792
            ],
            [
              105.83802781967742,
              21.018219441914543
            ],
            [
              105.83804319396263,
              21.018261854721707
            ],
            [
              105.8380597136589,
              21.018335202715374
            ],
            [
              105.83804137524407,
              21.018342135882282
            ],
            [
              105.83804656603822,
              21.018403264877215
            ],
            [
              105.83805028235811,
              21.018425696193773
            ],
            [
              105.8380544200325,
              21.018460180760947
            ],
            [
              105.8380545853483,
              21.018461877103825
            ],
            [
              105.83805496188116,
              21.01846573743331
            ],
            [
              105.83805769769982,
              21.018493800663535
            ],
            [
              105.83806055133991,
              21.01852366795404
            ],
            [
              105.83806577513758,
              21.0185664279242
            ],
            [
              105.83805603396735,
              21.01857117358141
            ],
            [
              105.83806026922908,
              21.01860297960874
            ],
            [
              105.83806215790331,
              21.01861715904468
            ],
            [
              105.83806225090076,
              21.018617791746326
            ],
            [
              105.83806262987648,
              21.018620367679624
            ],
            [
              105.83811660123887,
              21.01861415404681
            ],
            [
              105.83826910144116,
              21.0185937629248
            ],
            [
              105.83826977334823,
              21.01859918076205
            ],
            [
              105.8382702206333,
              21.018602790549238
            ],
            [
              105.8382766187433,
              21.01871141050071
            ],
            [
              105.83827812532667,
              21.018727788456204
            ],
            [
              105.83828779681654,
              21.018832935637615
            ],
            [
              105.8383000949326,
              21.018921989782857
            ],
            [
              105.83830080519144,
              21.018944765581836
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ Sân Quần",
          "maTaiSan": "04.O16A.DOPP.566",
          "duongKinh": 100,
          "chieudaiQL": 120.55,
          "chatLieu": "G",
          "from_latitude": 21.01813226678498,
          "to_latitude": 21.018944765581836,
          "to_longitude": 105.83830080519144,
          "from_longitude": 105.83798501532829
        }
      },
      {
        "id": 565,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83766456186459,
            21.018240496267538,
            105.8377872146254,
            21.018979437941304
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83767164444453,
              21.018240496267538
            ],
            [
              105.83770544001456,
              21.018339048591447
            ],
            [
              105.83770661053644,
              21.018342461529187
            ],
            [
              105.83770629853733,
              21.01834378086909
            ],
            [
              105.8377025149689,
              21.018359760368238
            ],
            [
              105.83769941557415,
              21.018372852504662
            ],
            [
              105.83769837711264,
              21.01837723825375
            ],
            [
              105.83767387401029,
              21.018414571734915
            ],
            [
              105.8376697482993,
              21.018427508150893
            ],
            [
              105.83766912813127,
              21.018429454942286
            ],
            [
              105.83766456186459,
              21.018443772750228
            ],
            [
              105.83767038962557,
              21.018496516707216
            ],
            [
              105.83767161654656,
              21.018507615801543
            ],
            [
              105.83767703557675,
              21.018543154454893
            ],
            [
              105.83768637010881,
              21.018639203365296
            ],
            [
              105.83768674568098,
              21.018643065506968
            ],
            [
              105.83769414241613,
              21.018688921880045
            ],
            [
              105.83769463864523,
              21.018691996719618
            ],
            [
              105.83769630726657,
              21.018743595093365
            ],
            [
              105.83770345425873,
              21.018744222864147
            ],
            [
              105.83772328782457,
              21.018810688263667
            ],
            [
              105.83773462833119,
              21.018838069641728
            ],
            [
              105.83774800443955,
              21.018880105733896
            ],
            [
              105.83775673524734,
              21.01887805486973
            ],
            [
              105.83776972868554,
              21.018906002554697
            ],
            [
              105.83778565528283,
              21.018953951229438
            ],
            [
              105.8377872146254,
              21.018979437941304
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ Tô Tiền",
          "maTaiSan": "04.O16A.DOPP.567",
          "duongKinh": 100,
          "chieudaiQL": 87.75,
          "chatLieu": "G",
          "from_latitude": 21.018240496267538,
          "to_latitude": 21.018979437941304,
          "to_longitude": 105.8377872146254,
          "from_longitude": 105.83767164444453
        }
      },
      {
        "id": 566,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84001298936242,
            21.016751857305607,
            105.84013239786043,
            21.01881778116515
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84013239786043,
              21.01881778116515
            ],
            [
              105.84012974523677,
              21.018804982946747
            ],
            [
              105.84011464754737,
              21.01861166294843
            ],
            [
              105.84011186255945,
              21.01856458805788
            ],
            [
              105.84011123136557,
              21.018553914172184
            ],
            [
              105.84011032926895,
              21.018538669507883
            ],
            [
              105.84011022512384,
              21.018536906930272
            ],
            [
              105.84010988937587,
              21.018531235442694
            ],
            [
              105.84010103176453,
              21.018381502176936
            ],
            [
              105.84009934121389,
              21.018352924410063
            ],
            [
              105.84009807570742,
              21.018331534201863
            ],
            [
              105.84009448822795,
              21.018273941623434
            ],
            [
              105.84009383610196,
              21.01826348009812
            ],
            [
              105.84008786618543,
              21.018167647774952
            ],
            [
              105.84008576808323,
              21.01813397332383
            ],
            [
              105.8400889361286,
              21.01804851184384
            ],
            [
              105.84008890737685,
              21.017991172655638
            ],
            [
              105.84008890692924,
              21.017990405821195
            ],
            [
              105.84008890207537,
              21.017980952709163
            ],
            [
              105.84007992232438,
              21.017847525926637
            ],
            [
              105.84007898215083,
              21.01783355318732
            ],
            [
              105.8400671584246,
              21.017771484257143
            ],
            [
              105.84003481344112,
              21.017696876049627
            ],
            [
              105.84003247188171,
              21.017684650741288
            ],
            [
              105.84002765197192,
              21.017659478221187
            ],
            [
              105.84002061533592,
              21.01762272738698
            ],
            [
              105.84001507185603,
              21.01753139732177
            ],
            [
              105.8400144846051,
              21.017457977187046
            ],
            [
              105.84001368471482,
              21.017358098170266
            ],
            [
              105.84001365334505,
              21.01735610400775
            ],
            [
              105.8400135846079,
              21.017351731842357
            ],
            [
              105.8400135349823,
              21.01734853919243
            ],
            [
              105.84001346207958,
              21.01734393853207
            ],
            [
              105.84001307739311,
              21.017319394430565
            ],
            [
              105.84001298936242,
              21.01731381023452
            ],
            [
              105.84001875100783,
              21.017240398489935
            ],
            [
              105.84003167250351,
              21.017139008365096
            ],
            [
              105.84003009048818,
              21.01711949751738
            ],
            [
              105.84002795867147,
              21.017093193530883
            ],
            [
              105.8400223766343,
              21.01702434398005
            ],
            [
              105.84003135997715,
              21.016884492364703
            ],
            [
              105.84003020393247,
              21.016848462164518
            ],
            [
              105.8400301653854,
              21.016847247519216
            ],
            [
              105.84003002390118,
              21.016845344222673
            ],
            [
              105.84002971796976,
              21.016841216195385
            ],
            [
              105.84002557783559,
              21.01677287074706
            ],
            [
              105.84002556692128,
              21.016751857305607
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Ngõ 1, Khâm Thiên",
          "maTaiSan": "04.O16A.DOPP.568",
          "duongKinh": 150,
          "chieudaiQL": 230.91,
          "chatLieu": "G",
          "from_latitude": 21.01881778116515,
          "to_latitude": 21.016751857305607,
          "to_longitude": 105.84002556692128,
          "from_longitude": 105.84013239786043
        }
      },
      {
        "id": 567,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84002795867147,
            21.017075164515507,
            105.84058763552842,
            21.017095038343555
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84002795867147,
              21.017093193530883
            ],
            [
              105.84012323235963,
              21.01709298047248
            ],
            [
              105.84014584478473,
              21.017095038343555
            ],
            [
              105.84016689235159,
              21.01709421723778
            ],
            [
              105.84022582874134,
              21.01709077300529
            ],
            [
              105.84022914517439,
              21.01709019482024
            ],
            [
              105.84023133338286,
              21.017089812787685
            ],
            [
              105.84023349132205,
              21.01708917890525
            ],
            [
              105.8402346869775,
              21.017088827968436
            ],
            [
              105.84036541814338,
              21.017085387650802
            ],
            [
              105.84039130323957,
              21.01708437913708
            ],
            [
              105.84046566463621,
              21.01708286468041
            ],
            [
              105.84056076194938,
              21.017075728137893
            ],
            [
              105.84056562401045,
              21.0170756264371
            ],
            [
              105.84058763552842,
              21.017075164515507
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngách 1/49, Khâm Thiên",
          "maTaiSan": "04.O16A.DOPP.569",
          "duongKinh": 100,
          "chieudaiQL": 60.65,
          "chatLieu": "G",
          "from_latitude": 21.017093193530883,
          "to_latitude": 21.017075164515507,
          "to_longitude": 105.84058763552842,
          "from_longitude": 105.84002795867147
        }
      },
      {
        "id": 568,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84002556692128,
            21.016742380835836,
            105.84120882626898,
            21.01675333015399
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84120882626898,
              21.016742863657043
            ],
            [
              105.84120525702407,
              21.01674278556439
            ],
            [
              105.84120245068998,
              21.016742723570196
            ],
            [
              105.84119098196342,
              21.016742472280317
            ],
            [
              105.8411868248968,
              21.016742380835836
            ],
            [
              105.84106970194445,
              21.016748607780762
            ],
            [
              105.84106356900048,
              21.016748336424968
            ],
            [
              105.84094824198596,
              21.016743240631655
            ],
            [
              105.84093567396236,
              21.01674371191798
            ],
            [
              105.8409254256546,
              21.01674409586605
            ],
            [
              105.8408747534625,
              21.01674599381533
            ],
            [
              105.84086835494425,
              21.016746051635263
            ],
            [
              105.8408043745654,
              21.016746628894357
            ],
            [
              105.8405081879506,
              21.016746862313667
            ],
            [
              105.84042931984207,
              21.01675040510749
            ],
            [
              105.84038643568347,
              21.016752331275605
            ],
            [
              105.84036659694269,
              21.01675239676032
            ],
            [
              105.84026524565441,
              21.016752733902766
            ],
            [
              105.84026145589188,
              21.01675278604044
            ],
            [
              105.84022187980365,
              21.01675333015399
            ],
            [
              105.8402143300833,
              21.016753259957987
            ],
            [
              105.84005260076157,
              21.016751750009114
            ],
            [
              105.8400407094874,
              21.016751796985478
            ],
            [
              105.84003047368121,
              21.016751837593027
            ],
            [
              105.84002556692128,
              21.016751857305607
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 222, Lê Duẩn",
          "maTaiSan": "04.O16A.DOPP.570",
          "duongKinh": 100,
          "chieudaiQL": 125.29,
          "chatLieu": "G",
          "from_latitude": 21.016742863657043,
          "to_latitude": 21.016751857305607,
          "to_longitude": 105.84002556692128,
          "from_longitude": 105.84120882626898
        }
      },
      {
        "id": 569,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83971577523894,
            21.01661550248201,
            105.84002972780063,
            21.016619125187866
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84002972780063,
              21.01661591335995
            ],
            [
              105.84001507608538,
              21.01661550248201
            ],
            [
              105.83988359426107,
              21.016616406360292
            ],
            [
              105.83978261665615,
              21.016619042781354
            ],
            [
              105.83977964757722,
              21.016619120663396
            ],
            [
              105.83977946290968,
              21.016619125187866
            ],
            [
              105.83977606253825,
              21.01661759565442
            ],
            [
              105.83977544341077,
              21.016617316904874
            ],
            [
              105.83972177053704,
              21.016617940422037
            ],
            [
              105.83971577523894,
              21.01661768369281
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngách 1/32, Khâm Thiên",
          "maTaiSan": "04.O16A.DOPP.571",
          "duongKinh": 100,
          "chieudaiQL": 30.13,
          "chatLieu": "G",
          "from_latitude": 21.01661591335995,
          "to_latitude": 21.01661768369281,
          "to_longitude": 105.83971577523894,
          "from_longitude": 105.84002972780063
        }
      },
      {
        "id": 570,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84002170708533,
            21.016009068272567,
            105.84015079043074,
            21.016751857305607
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84002556692128,
              21.016751857305607
            ],
            [
              105.84002487524444,
              21.016730724387173
            ],
            [
              105.84002972780063,
              21.01661591335995
            ],
            [
              105.8400321409026,
              21.016558817716536
            ],
            [
              105.84003023992341,
              21.016514644116832
            ],
            [
              105.84002760585955,
              21.01645345108225
            ],
            [
              105.84002180426846,
              21.01631945760979
            ],
            [
              105.84002170708533,
              21.01631721990164
            ],
            [
              105.84002860672189,
              21.01630032355227
            ],
            [
              105.84002973025468,
              21.016297573108123
            ],
            [
              105.84003433728994,
              21.01628629167786
            ],
            [
              105.84005016552821,
              21.01623399274677
            ],
            [
              105.84008131634617,
              21.01616518128897
            ],
            [
              105.84008484258355,
              21.016156380930216
            ],
            [
              105.8400896466843,
              21.016144390398118
            ],
            [
              105.84009511011638,
              21.016130754549554
            ],
            [
              105.84009554840881,
              21.016129661291153
            ],
            [
              105.8400964420602,
              21.016127430432082
            ],
            [
              105.84012285626734,
              21.016073047869735
            ],
            [
              105.8401251480187,
              21.01606779785571
            ],
            [
              105.84012669422042,
              21.016064256809504
            ],
            [
              105.84012777314629,
              21.016061786584327
            ],
            [
              105.84015079043074,
              21.016009068272567
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 1, Khâm Thiên",
          "maTaiSan": "04.O16A.DOPP.572",
          "duongKinh": 100,
          "chieudaiQL": 83.91,
          "chatLieu": "G",
          "from_latitude": 21.016751857305607,
          "to_latitude": 21.016009068272567,
          "to_longitude": 105.84015079043074,
          "from_longitude": 105.84002556692128
        }
      },
      {
        "id": 571,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83730861655486,
            21.012163254465392,
            105.8389920538285,
            21.013083061987
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83730861655486,
              21.013083061987
            ],
            [
              105.83731920124345,
              21.013065261539012
            ],
            [
              105.83732538612485,
              21.013057114727655
            ],
            [
              105.83732739904983,
              21.013054462890285
            ],
            [
              105.83732930121185,
              21.013051957920336
            ],
            [
              105.83736800405937,
              21.01299078354633
            ],
            [
              105.83737763597975,
              21.01297583113426
            ],
            [
              105.83742097772648,
              21.01292147855268
            ],
            [
              105.83742770631169,
              21.012913200803943
            ],
            [
              105.83745537781735,
              21.012893383349024
            ],
            [
              105.83746884498132,
              21.012889474747173
            ],
            [
              105.83751753662591,
              21.01287953354515
            ],
            [
              105.83752114644044,
              21.012878796809833
            ],
            [
              105.8375690823095,
              21.01284995753736
            ],
            [
              105.83757322196644,
              21.01284746684074
            ],
            [
              105.83757350094908,
              21.01284729927412
            ],
            [
              105.8375834630241,
              21.012847035266667
            ],
            [
              105.8376109586704,
              21.012846205331975
            ],
            [
              105.83763567883122,
              21.012835148298972
            ],
            [
              105.83765647096165,
              21.012813490488885
            ],
            [
              105.83766920388287,
              21.01280022808207
            ],
            [
              105.83768882157241,
              21.01277582663531
            ],
            [
              105.83772648077723,
              21.012720554473955
            ],
            [
              105.83773904242918,
              21.01270211833314
            ],
            [
              105.8377417251066,
              21.01269486397518
            ],
            [
              105.83774980070079,
              21.012682687588466
            ],
            [
              105.83775126586816,
              21.012680478313545
            ],
            [
              105.83778499514563,
              21.012636149084692
            ],
            [
              105.8378014728325,
              21.012609977411977
            ],
            [
              105.83781153640854,
              21.012588275765346
            ],
            [
              105.83782050850755,
              21.012555776929492
            ],
            [
              105.83782102895873,
              21.012553892047226
            ],
            [
              105.83782176455853,
              21.012552294234144
            ],
            [
              105.83782297250252,
              21.01254967074202
            ],
            [
              105.83783528095584,
              21.0125229334031
            ],
            [
              105.83785810846086,
              21.0124949086543
            ],
            [
              105.83788949384146,
              21.01246528369814
            ],
            [
              105.83791608018787,
              21.012449259637997
            ],
            [
              105.83792619408793,
              21.012445355772705
            ],
            [
              105.83794353749886,
              21.012442785591578
            ],
            [
              105.83796552435639,
              21.01245197600496
            ],
            [
              105.83796560920447,
              21.01245201171607
            ],
            [
              105.8379702516687,
              21.012453952462135
            ],
            [
              105.83797246635989,
              21.012454878198607
            ],
            [
              105.83797508835738,
              21.012456222831634
            ],
            [
              105.83798114461433,
              21.01245932899972
            ],
            [
              105.8380763142547,
              21.012378850849515
            ],
            [
              105.8380907193746,
              21.012366188046116
            ],
            [
              105.83811359391547,
              21.012346078890786
            ],
            [
              105.83813290880366,
              21.01232552481596
            ],
            [
              105.83813317583703,
              21.012317364675372
            ],
            [
              105.83813339768687,
              21.012310586690546
            ],
            [
              105.83812050233088,
              21.01224999237482
            ],
            [
              105.83813153169464,
              21.012235711360297
            ],
            [
              105.83813815010473,
              21.012230452703214
            ],
            [
              105.83814801978211,
              21.012222611066967
            ],
            [
              105.83817264569616,
              21.012202630567206
            ],
            [
              105.83821825980354,
              21.012209543142436
            ],
            [
              105.83823640741566,
              21.012213528185452
            ],
            [
              105.8382558487811,
              21.012224223228717
            ],
            [
              105.8383101975809,
              21.012254120450237
            ],
            [
              105.83839499184825,
              21.012307579083927
            ],
            [
              105.8384037025601,
              21.012310608028518
            ],
            [
              105.83841281613401,
              21.01231377679378
            ],
            [
              105.83842713042837,
              21.012310858393686
            ],
            [
              105.83844334665224,
              21.012298686120047
            ],
            [
              105.83847851867782,
              21.012272285878055
            ],
            [
              105.83857188180087,
              21.01220976704087
            ],
            [
              105.83858361447221,
              21.012202576455355
            ],
            [
              105.83859125187892,
              21.012197896241883
            ],
            [
              105.83859911360436,
              21.012193078535674
            ],
            [
              105.83861184420996,
              21.012185276966438
            ],
            [
              105.83864778036278,
              21.012163254465392
            ],
            [
              105.83869018755578,
              21.01216485098749
            ],
            [
              105.83882670707818,
              21.012232448218672
            ],
            [
              105.83884333844578,
              21.01224068311538
            ],
            [
              105.83888460115615,
              21.012261233067665
            ],
            [
              105.8389920538285,
              21.01231474678102
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D110_Ngõ 241, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DOPP.573",
          "duongKinh": 110,
          "chieudaiQL": 243.19,
          "chatLieu": "PVC",
          "from_latitude": 21.013083061987,
          "to_latitude": 21.01231474678102,
          "to_longitude": 105.8389920538285,
          "from_longitude": 105.83730861655486
        }
      },
      {
        "id": 572,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84024837857585,
            21.00854174409066,
            105.84081856102549,
            21.00940314226827
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84024837857585,
              21.00854174409066
            ],
            [
              105.8402515484508,
              21.008544056951568
            ],
            [
              105.84027509661594,
              21.008565207999457
            ],
            [
              105.84027787989255,
              21.00856770702652
            ],
            [
              105.84027935709588,
              21.008569034698038
            ],
            [
              105.84029086146099,
              21.00857936858482
            ],
            [
              105.84034981644825,
              21.008634115440046
            ],
            [
              105.84038472209144,
              21.00866341075972
            ],
            [
              105.840428257481,
              21.008701229631964
            ],
            [
              105.8404468856861,
              21.008717411928263
            ],
            [
              105.84046170261504,
              21.008730283760972
            ],
            [
              105.84046384845222,
              21.008732147353403
            ],
            [
              105.84046408859334,
              21.00873243158625
            ],
            [
              105.84046536677421,
              21.008733946303195
            ],
            [
              105.84049397473272,
              21.00876783759757
            ],
            [
              105.84052112606673,
              21.008799952213263
            ],
            [
              105.84056004609621,
              21.00885231678802
            ],
            [
              105.84059410834755,
              21.008900687807124
            ],
            [
              105.84061781758005,
              21.008934259147775
            ],
            [
              105.8406536948056,
              21.00897590661894
            ],
            [
              105.84066774533657,
              21.008991387116758
            ],
            [
              105.8406869091939,
              21.009020240780437
            ],
            [
              105.8407098819572,
              21.009057814318552
            ],
            [
              105.84073649792214,
              21.009096850238247
            ],
            [
              105.84074286507489,
              21.00910618792565
            ],
            [
              105.8407462741506,
              21.009111188493268
            ],
            [
              105.84077230649183,
              21.009148376583664
            ],
            [
              105.84077298137692,
              21.00914934150556
            ],
            [
              105.8407761214117,
              21.00915662494604
            ],
            [
              105.84077770019996,
              21.009160286945473
            ],
            [
              105.8408034022953,
              21.009210633025436
            ],
            [
              105.84081856102549,
              21.00923539317822
            ],
            [
              105.840770586734,
              21.0092735367261
            ],
            [
              105.84075122141618,
              21.009286845670786
            ],
            [
              105.8407420792888,
              21.009304649129064
            ],
            [
              105.84074817612654,
              21.009328400899417
            ],
            [
              105.84075910439402,
              21.009347658742417
            ],
            [
              105.84076039258552,
              21.00934992850276
            ],
            [
              105.84076472930478,
              21.009357570030048
            ],
            [
              105.8407804677867,
              21.009386743184063
            ],
            [
              105.84078921289266,
              21.00940314226827
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D90_Ngõ 26, Kim Hoa",
          "maTaiSan": "04.O16A.DOPP.574",
          "duongKinh": 90,
          "chieudaiQL": 121.36,
          "chatLieu": "PVC",
          "from_latitude": 21.00854174409066,
          "to_latitude": 21.00940314226827,
          "to_longitude": 105.84078921289266,
          "from_longitude": 105.84024837857585
        }
      },
      {
        "id": 573,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8399216259081,
            21.008877598024664,
            105.84049908720677,
            21.009326095251783
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8399216259081,
              21.008877598024664
            ],
            [
              105.83992332417009,
              21.008879409639647
            ],
            [
              105.83994304906466,
              21.008894246193822
            ],
            [
              105.83994469216566,
              21.008895482726917
            ],
            [
              105.84001066069142,
              21.008961807835213
            ],
            [
              105.84004812572805,
              21.008996046580634
            ],
            [
              105.84010834049145,
              21.008947645401594
            ],
            [
              105.8401275716285,
              21.00893218661453
            ],
            [
              105.8401310061155,
              21.008928330052147
            ],
            [
              105.84015167042992,
              21.008945848132523
            ],
            [
              105.84016486288674,
              21.00895703175568
            ],
            [
              105.84023113091875,
              21.009029067286153
            ],
            [
              105.84026920871163,
              21.009069139590498
            ],
            [
              105.84027975447403,
              21.009078336534227
            ],
            [
              105.84029163387284,
              21.00908933153401
            ],
            [
              105.84032186953819,
              21.00911277264189
            ],
            [
              105.8403511634661,
              21.009135689105193
            ],
            [
              105.84035811367912,
              21.00914316870566
            ],
            [
              105.84035956237112,
              21.00914472864565
            ],
            [
              105.84036130086264,
              21.009147710635936
            ],
            [
              105.84036367357444,
              21.009151782395563
            ],
            [
              105.84039527288438,
              21.009190961760464
            ],
            [
              105.84040663683496,
              21.009205926258
            ],
            [
              105.84044226944219,
              21.00924511459753
            ],
            [
              105.84047876782729,
              21.009291009608727
            ],
            [
              105.84048034262493,
              21.009293445052254
            ],
            [
              105.84048170978173,
              21.009295559973484
            ],
            [
              105.8404870852502,
              21.009303873796057
            ],
            [
              105.84049908720677,
              21.009326095251783
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D90_Ngõ 50, Kim Hoa",
          "maTaiSan": "04.O16A.DOPP.575",
          "duongKinh": 90,
          "chieudaiQL": 86.75,
          "chatLieu": "PVC",
          "from_latitude": 21.008877598024664,
          "to_latitude": 21.009326095251783,
          "to_longitude": 105.84049908720677,
          "from_longitude": 105.8399216259081
        }
      },
      {
        "id": 574,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83926423022191,
            21.009535734273022,
            105.84026107116505,
            21.01054474554433
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83926423022191,
              21.009535734273022
            ],
            [
              105.83926561380372,
              21.009536832995924
            ],
            [
              105.83927095666849,
              21.00953812444945
            ],
            [
              105.8392719911468,
              21.00953837495992
            ],
            [
              105.83930355051561,
              21.009559443273112
            ],
            [
              105.83935516288427,
              21.009601031476254
            ],
            [
              105.83939915900179,
              21.009635918275034
            ],
            [
              105.83941593908393,
              21.00964946244806
            ],
            [
              105.83946831327977,
              21.009684163383838
            ],
            [
              105.8394968081694,
              21.009705759808217
            ],
            [
              105.8395246243854,
              21.0097289781538
            ],
            [
              105.83955312524317,
              21.0097512944085
            ],
            [
              105.8395538933171,
              21.009751895779367
            ],
            [
              105.83955666105801,
              21.009753335412103
            ],
            [
              105.83955941239661,
              21.00975476609346
            ],
            [
              105.83958931521838,
              21.00977372807547
            ],
            [
              105.83959073231009,
              21.009774626114446
            ],
            [
              105.8396362148586,
              21.0098116100269
            ],
            [
              105.83967832241095,
              21.00985173844592
            ],
            [
              105.83970247908032,
              21.009872798047706
            ],
            [
              105.83972891132363,
              21.009899306407274
            ],
            [
              105.83973168047068,
              21.009917839549693
            ],
            [
              105.83973210719934,
              21.009920697955202
            ],
            [
              105.83981022021928,
              21.009990140735205
            ],
            [
              105.83980879167281,
              21.00999510197052
            ],
            [
              105.83980800568494,
              21.009997830877154
            ],
            [
              105.83980134983662,
              21.01001029299433
            ],
            [
              105.83976976002467,
              21.01004912134372
            ],
            [
              105.83976305075531,
              21.010057367472236
            ],
            [
              105.83975171790011,
              21.01007012995832
            ],
            [
              105.83974792394216,
              21.010076964411486
            ],
            [
              105.83974796450438,
              21.010084211682827
            ],
            [
              105.83974990254382,
              21.01009277100738
            ],
            [
              105.83974404778004,
              21.010101593691484
            ],
            [
              105.8397362070216,
              21.010119412354182
            ],
            [
              105.83973501540318,
              21.010124771644826
            ],
            [
              105.83973789753493,
              21.010130492896042
            ],
            [
              105.83974905697,
              21.01013881884345
            ],
            [
              105.83976806266091,
              21.010159008757977
            ],
            [
              105.83978696563085,
              21.010179577628104
            ],
            [
              105.83980126795325,
              21.010189512961645
            ],
            [
              105.83981254517875,
              21.01019671290586
            ],
            [
              105.83983871601556,
              21.010208641786445
            ],
            [
              105.83985786236586,
              21.01022714919245
            ],
            [
              105.83987157148519,
              21.010241086924413
            ],
            [
              105.83988677257842,
              21.01025606683774
            ],
            [
              105.83990109569845,
              21.010277790942244
            ],
            [
              105.83991018649145,
              21.010292371981084
            ],
            [
              105.83991488182703,
              21.010312700253348
            ],
            [
              105.83990350021121,
              21.01033599819724
            ],
            [
              105.83989779988948,
              21.0103476661836
            ],
            [
              105.83989628778959,
              21.01035076086742
            ],
            [
              105.83989406339539,
              21.010355315064416
            ],
            [
              105.83987449362196,
              21.010395373908132
            ],
            [
              105.83996276279821,
              21.01045228662846
            ],
            [
              105.84002342245319,
              21.010381876216584
            ],
            [
              105.84003751293366,
              21.01036565158948
            ],
            [
              105.84005241924646,
              21.010370583669904
            ],
            [
              105.84007000669885,
              21.010381928952672
            ],
            [
              105.8401671889542,
              21.010465987319012
            ],
            [
              105.84020810640592,
              21.010501035415444
            ],
            [
              105.84025384078875,
              21.010538778420234
            ],
            [
              105.84025662586559,
              21.010541076921385
            ],
            [
              105.84026107116505,
              21.01054474554433
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D110_Ngõ 88, Kim Hoa",
          "maTaiSan": "04.O16A.DOPP.576",
          "duongKinh": 110,
          "chieudaiQL": 186.47,
          "chatLieu": "PVC",
          "from_latitude": 21.009535734273022,
          "to_latitude": 21.01054474554433,
          "to_longitude": 105.84026107116505,
          "from_longitude": 105.83926423022191
        }
      },
      {
        "id": 575,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83786400160254,
            21.00810764328086,
            105.84073563975363,
            21.010626721561014
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83786400160254,
              21.010626721561014
            ],
            [
              105.83805989598166,
              21.010384384578018
            ],
            [
              105.83806941137367,
              21.010388046354
            ],
            [
              105.8380694374099,
              21.01038805796769
            ],
            [
              105.83808644572463,
              21.010394593931803
            ],
            [
              105.83825842122617,
              21.010460680282772
            ],
            [
              105.83829478674146,
              21.01046512383732
            ],
            [
              105.83836310835645,
              21.010410134935626
            ],
            [
              105.83836690138968,
              21.01040708141375
            ],
            [
              105.83837210771678,
              21.01040286931748
            ],
            [
              105.83837213841605,
              21.010402854714652
            ],
            [
              105.83848315684197,
              21.01031326241825
            ],
            [
              105.83858730090418,
              21.010218936711354
            ],
            [
              105.83859011086132,
              21.01021622673859
            ],
            [
              105.83859014156056,
              21.010216212135695
            ],
            [
              105.83861371371356,
              21.010197934833645
            ],
            [
              105.83861544239157,
              21.010195206705447
            ],
            [
              105.8386154673347,
              21.010195194840602
            ],
            [
              105.83863463912587,
              21.010173536775362
            ],
            [
              105.83879941137377,
              21.010010697012355
            ],
            [
              105.83880776928318,
              21.010002259439048
            ],
            [
              105.8388077999823,
              21.010002244836134
            ],
            [
              105.83881480728999,
              21.009995166950297
            ],
            [
              105.83881483798916,
              21.00999515234737
            ],
            [
              105.83889765558273,
              21.009911705245337
            ],
            [
              105.83890219846303,
              21.009907201953062
            ],
            [
              105.83890222916213,
              21.009907187350134
            ],
            [
              105.83890300297405,
              21.00990640676232
            ],
            [
              105.83890303367319,
              21.00990639215938
            ],
            [
              105.83891008861879,
              21.009899406163836
            ],
            [
              105.83891011931792,
              21.0098993915609
            ],
            [
              105.83901890641796,
              21.00979191163471
            ],
            [
              105.83910372007462,
              21.00970439911757
            ],
            [
              105.83910375077366,
              21.009704384514595
            ],
            [
              105.83914323013431,
              21.00966365673691
            ],
            [
              105.83922044712693,
              21.009583980064868
            ],
            [
              105.83922047782599,
              21.009583965461882
            ],
            [
              105.83924255295182,
              21.009561191982407
            ],
            [
              105.83926423022191,
              21.009535734273022
            ],
            [
              105.83926425512448,
              21.009535715182494
            ],
            [
              105.83928684219656,
              21.009509199823604
            ],
            [
              105.83932294253466,
              21.009473497954296
            ],
            [
              105.83932297323368,
              21.00947348335128
            ],
            [
              105.83936681832188,
              21.0094301408338
            ],
            [
              105.83945849438129,
              21.009326754452573
            ],
            [
              105.83948384253219,
              21.0092998135779
            ],
            [
              105.83948387323112,
              21.009299798974872
            ],
            [
              105.83951111859717,
              21.009270860740084
            ],
            [
              105.83952238906419,
              21.00925984812343
            ],
            [
              105.83952241976313,
              21.009259833520407
            ],
            [
              105.8395287145783,
              21.00925367589428
            ],
            [
              105.83952874527715,
              21.009253661291247
            ],
            [
              105.83967063491144,
              21.00911521589638
            ],
            [
              105.83967592687192,
              21.00911016334071
            ],
            [
              105.83967595757082,
              21.00911014873765
            ],
            [
              105.8397116345197,
              21.009076171327642
            ],
            [
              105.83971166521859,
              21.009076156724582
            ],
            [
              105.83971626445424,
              21.00907176332684
            ],
            [
              105.83971629515307,
              21.009071748723784
            ],
            [
              105.83990758527229,
              21.00888964588583
            ],
            [
              105.8399216259081,
              21.008877598024664
            ],
            [
              105.8399216566069,
              21.00887758342155
            ],
            [
              105.83996001554348,
              21.008844698270885
            ],
            [
              105.83999055419702,
              21.00882691839916
            ],
            [
              105.83999058489583,
              21.00882690379603
            ],
            [
              105.83999582224783,
              21.008821722339317
            ],
            [
              105.83999619714004,
              21.008821334811998
            ],
            [
              105.83999622783882,
              21.008821320208884
            ],
            [
              105.8399983179105,
              21.008819236992142
            ],
            [
              105.83999834860933,
              21.008819222389018
            ],
            [
              105.84009468132389,
              21.008723907391698
            ],
            [
              105.84022143980259,
              21.008571447761753
            ],
            [
              105.84024837857585,
              21.00854174409066
            ],
            [
              105.84024840927451,
              21.00854172948749
            ],
            [
              105.84027797233874,
              21.00850915324611
            ],
            [
              105.84030764961364,
              21.00848185849178
            ],
            [
              105.84030768031238,
              21.008481843888603
            ],
            [
              105.8403751997714,
              21.008419773282736
            ],
            [
              105.84049920582623,
              21.008316096645437
            ],
            [
              105.8405719949257,
              21.008253097616954
            ],
            [
              105.84057202562435,
              21.008253083013727
            ],
            [
              105.8406552362847,
              21.008181078811997
            ],
            [
              105.84066626179377,
              21.008171526943496
            ],
            [
              105.84066629249236,
              21.00817151234026
            ],
            [
              105.84068402836122,
              21.008156164750723
            ],
            [
              105.84070607309181,
              21.008135425301322
            ],
            [
              105.84070610379041,
              21.00813541069808
            ],
            [
              105.8407256579235,
              21.00811702741723
            ],
            [
              105.84073561388962,
              21.00810766237634
            ],
            [
              105.84073563975363,
              21.00810764328086
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D225_Kim Hoa",
          "maTaiSan": "04.O16A.DOPP.577",
          "duongKinh": 225,
          "chieudaiQL": 424.17,
          "chatLieu": "PVC",
          "from_latitude": 21.010626721561014,
          "to_latitude": 21.00810764328086,
          "to_longitude": 105.84073563975363,
          "from_longitude": 105.83786400160254
        }
      },
      {
        "id": 576,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83444074211893,
            21.01306257587748,
            105.83584490564847,
            21.01458593372725
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83444074211893,
              21.01458593372725
            ],
            [
              105.8344846078737,
              21.01453610354279
            ],
            [
              105.83454146890088,
              21.014471511428827
            ],
            [
              105.83462161719858,
              21.01438046522174
            ],
            [
              105.83464721948239,
              21.01435428701232
            ],
            [
              105.83481351695553,
              21.014184251072706
            ],
            [
              105.83491240971675,
              21.01406705653524
            ],
            [
              105.83493757175073,
              21.014038706382735
            ],
            [
              105.83501370591651,
              21.01395292562754
            ],
            [
              105.83505614594148,
              21.01390071507713
            ],
            [
              105.83506254796546,
              21.013892840052446
            ],
            [
              105.83512897281994,
              21.013811123542208
            ],
            [
              105.83515714866789,
              21.01377295857787
            ],
            [
              105.83516872849914,
              21.013757268723836
            ],
            [
              105.83516877625718,
              21.01375720797336
            ],
            [
              105.83518683391296,
              21.013732747621706
            ],
            [
              105.83525663108536,
              21.013649467286236
            ],
            [
              105.83525955801562,
              21.013645975502406
            ],
            [
              105.83530436973635,
              21.01359251312256
            ],
            [
              105.8353318950669,
              21.01355967222096
            ],
            [
              105.83533953559429,
              21.01355154813837
            ],
            [
              105.83544986120103,
              21.013434226720648
            ],
            [
              105.83561274867036,
              21.013263154523973
            ],
            [
              105.83562762959922,
              21.013250031650387
            ],
            [
              105.835630179635,
              21.013247783645408
            ],
            [
              105.83569963926298,
              21.013186532637388
            ],
            [
              105.83579937511121,
              21.013101425942946
            ],
            [
              105.83584490564847,
              21.01306257587748
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Đê La Thành",
          "maTaiSan": "04.O16A.DOPP.578",
          "duongKinh": 150,
          "chieudaiQL": 222.14,
          "chatLieu": "G",
          "from_latitude": 21.01458593372725,
          "to_latitude": 21.01306257587748,
          "to_longitude": 105.83584490564847,
          "from_longitude": 105.83444074211893
        }
      },
      {
        "id": 577,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83952865996568,
            21.01526293557582,
            105.84131049790808,
            21.015618831931313
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84131049790808,
              21.01527468792022
            ],
            [
              105.84128062311149,
              21.01527107914437
            ],
            [
              105.84123413504697,
              21.015265461548086
            ],
            [
              105.84113788146601,
              21.015263461723215
            ],
            [
              105.84097562409562,
              21.015263043600978
            ],
            [
              105.84096637183629,
              21.01526297462624
            ],
            [
              105.84096266342871,
              21.015262946892637
            ],
            [
              105.84096111561486,
              21.01526293557582
            ],
            [
              105.8409101670704,
              21.01526366521316
            ],
            [
              105.84088176176499,
              21.015264111803045
            ],
            [
              105.84088038632481,
              21.01526413395603
            ],
            [
              105.84087469122089,
              21.01526422352346
            ],
            [
              105.84072459146783,
              21.01526530584243
            ],
            [
              105.84070712295926,
              21.0152821605099
            ],
            [
              105.84065222223387,
              21.01528862790837
            ],
            [
              105.84062292575838,
              21.01530599352655
            ],
            [
              105.84060429936052,
              21.01534555823602
            ],
            [
              105.84060403699215,
              21.01534611591808
            ],
            [
              105.84060300849431,
              21.01534745506105
            ],
            [
              105.84060049980144,
              21.015350721770837
            ],
            [
              105.84058097081659,
              21.01537667574534
            ],
            [
              105.84055466967696,
              21.015406725876385
            ],
            [
              105.84052984472841,
              21.01541628269646
            ],
            [
              105.84044509303766,
              21.015410188246967
            ],
            [
              105.84043779088655,
              21.01540896703229
            ],
            [
              105.84043447680253,
              21.015408412566426
            ],
            [
              105.84043367898143,
              21.015408345152952
            ],
            [
              105.84043239515918,
              21.015408237688792
            ],
            [
              105.84043017781602,
              21.015408050836346
            ],
            [
              105.8404284426314,
              21.0154079049568
            ],
            [
              105.84034367744536,
              21.015380513413575
            ],
            [
              105.84024689108878,
              21.015351076687118
            ],
            [
              105.84017275729559,
              21.01533502671599
            ],
            [
              105.8401544145309,
              21.015337836905324
            ],
            [
              105.84013579015935,
              21.015340690031607
            ],
            [
              105.8401307597858,
              21.015354697677854
            ],
            [
              105.84012973684601,
              21.015357545174126
            ],
            [
              105.84010940884842,
              21.015414148517806
            ],
            [
              105.8401018109974,
              21.015443944545805
            ],
            [
              105.84006854630607,
              21.01551143780443
            ],
            [
              105.84005584069162,
              21.015535244485307
            ],
            [
              105.8400523765654,
              21.015541735897383
            ],
            [
              105.84003505097448,
              21.01556042604636
            ],
            [
              105.84002382305036,
              21.015567773209895
            ],
            [
              105.83999380976222,
              21.015592002245782
            ],
            [
              105.83995422506742,
              21.015613973505726
            ],
            [
              105.83994508688892,
              21.015616415777792
            ],
            [
              105.83991251581317,
              21.015618831931313
            ],
            [
              105.83988163785367,
              21.015614224381903
            ],
            [
              105.83987901823868,
              21.015613833573266
            ],
            [
              105.83986911068159,
              21.01561235513265
            ],
            [
              105.839833517718,
              21.015604039633736
            ],
            [
              105.83983152583036,
              21.015603574370935
            ],
            [
              105.83982847237566,
              21.015603010477744
            ],
            [
              105.83981727830567,
              21.01560094406366
            ],
            [
              105.83979489527722,
              21.015595662307472
            ],
            [
              105.83974175727337,
              21.01558488630368
            ],
            [
              105.83969875710649,
              21.015571187995054
            ],
            [
              105.8395801243264,
              21.015521317600566
            ],
            [
              105.83954465444958,
              21.015504567129344
            ],
            [
              105.83954345945521,
              21.015504003092133
            ],
            [
              105.839542520044,
              21.015503558825674
            ],
            [
              105.83952865996568,
              21.01548851357791
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Ngõ Hoàng An A",
          "maTaiSan": "04.O16A.DOPP.579",
          "duongKinh": 150,
          "chieudaiQL": 218.55,
          "chatLieu": "G",
          "from_latitude": 21.01527468792022,
          "to_latitude": 21.01548851357791,
          "to_longitude": 105.83952865996568,
          "from_longitude": 105.84131049790808
        }
      },
      {
        "id": 578,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83995247769343,
            21.00976772327534,
            105.84116259656226,
            21.011026211492716
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84116259656226,
              21.00976772327534
            ],
            [
              105.8411624583566,
              21.009767778151964
            ],
            [
              105.84116090255603,
              21.009768391002634
            ],
            [
              105.84114997745652,
              21.00977339106156
            ],
            [
              105.8411489297515,
              21.009773870433076
            ],
            [
              105.84114637354506,
              21.009775507065434
            ],
            [
              105.84114495790446,
              21.00977641367419
            ],
            [
              105.84110170490335,
              21.009801755163963
            ],
            [
              105.84109577408283,
              21.00980523027856
            ],
            [
              105.84107935709996,
              21.009814448437744
            ],
            [
              105.84100943780385,
              21.00986440720682
            ],
            [
              105.8409970312378,
              21.00987487547076
            ],
            [
              105.84099425729589,
              21.00988005471859
            ],
            [
              105.84099235978591,
              21.009881483964673
            ],
            [
              105.84098726304435,
              21.00988532346884
            ],
            [
              105.84096012651716,
              21.009906016765804
            ],
            [
              105.84093492085468,
              21.009927285805777
            ],
            [
              105.84089702303636,
              21.00995698412719
            ],
            [
              105.8408917375297,
              21.009961126238274
            ],
            [
              105.84087414566739,
              21.009974912368982
            ],
            [
              105.84079998590201,
              21.010033061671326
            ],
            [
              105.84069942902772,
              21.01011136222481
            ],
            [
              105.8406797240469,
              21.01012707451435
            ],
            [
              105.8406764384436,
              21.010129693838334
            ],
            [
              105.84066851795355,
              21.010136009472554
            ],
            [
              105.84065573124589,
              21.010150400486467
            ],
            [
              105.84063993927498,
              21.01016817446864
            ],
            [
              105.84062284447073,
              21.010184500694702
            ],
            [
              105.84061291246947,
              21.01019284587253
            ],
            [
              105.8405657484088,
              21.010236430001143
            ],
            [
              105.84056430053126,
              21.010237764892697
            ],
            [
              105.84056184787215,
              21.010240030552314
            ],
            [
              105.84054037876037,
              21.010259868436187
            ],
            [
              105.84050847987051,
              21.010289343752223
            ],
            [
              105.84044092819502,
              21.01034803019863
            ],
            [
              105.84039264091807,
              21.01039065247588
            ],
            [
              105.84039062664269,
              21.010392532097804
            ],
            [
              105.84038508618538,
              21.01039770151551
            ],
            [
              105.84034596520205,
              21.01043420523121
            ],
            [
              105.84033572453912,
              21.01044841474565
            ],
            [
              105.8403122260835,
              21.010481020664546
            ],
            [
              105.84028516281991,
              21.010520155526656
            ],
            [
              105.84027023286744,
              21.01053539528859
            ],
            [
              105.84026746493902,
              21.010538219790245
            ],
            [
              105.84026107116505,
              21.01054474554433
            ],
            [
              105.84025916242939,
              21.01054657767596
            ],
            [
              105.84025435758222,
              21.010551189780777
            ],
            [
              105.84024830034076,
              21.010557004841683
            ],
            [
              105.84023703545535,
              21.010567818769182
            ],
            [
              105.84020584795873,
              21.01059775748514
            ],
            [
              105.84016624297307,
              21.0106268625327
            ],
            [
              105.84015760564685,
              21.010623184798725
            ],
            [
              105.84004567612003,
              21.010733731138174
            ],
            [
              105.84004475968318,
              21.010734636176892
            ],
            [
              105.84004439563012,
              21.0107350706185
            ],
            [
              105.84004436301227,
              21.01073508613434
            ],
            [
              105.84004209028718,
              21.010737708575256
            ],
            [
              105.83999073482802,
              21.010796747537583
            ],
            [
              105.83997542246598,
              21.01081207406563
            ],
            [
              105.83995728701537,
              21.010836353737353
            ],
            [
              105.83995247769343,
              21.010844980687473
            ],
            [
              105.84001685900014,
              21.010908040304482
            ],
            [
              105.84002033805403,
              21.01091144815553
            ],
            [
              105.84003944431123,
              21.010930161669403
            ],
            [
              105.84011464714209,
              21.010989443829803
            ],
            [
              105.84011474962722,
              21.010989537259096
            ],
            [
              105.84011818387839,
              21.01099267345875
            ],
            [
              105.84014458608117,
              21.01101668808913
            ],
            [
              105.84015505204383,
              21.011026211492716
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D110_Ngõ 378, Lê Duẩn",
          "maTaiSan": "04.O16A.DOPP.580",
          "duongKinh": 110,
          "chieudaiQL": 204.16,
          "chatLieu": "PVC",
          "from_latitude": 21.00976772327534,
          "to_latitude": 21.011026211492716,
          "to_longitude": 105.84015505204383,
          "from_longitude": 105.84116259656226
        }
      },
      {
        "id": 579,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83973595593531,
            21.010844980687473,
            105.83995247769343,
            21.011100735991885
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83995247769343,
              21.010844980687473
            ],
            [
              105.83993133762962,
              21.010882903034076
            ],
            [
              105.83992011132604,
              21.01090233531595
            ],
            [
              105.8399008746193,
              21.01092612545102
            ],
            [
              105.83988963942157,
              21.010937956240436
            ],
            [
              105.83984920995633,
              21.010977891331677
            ],
            [
              105.83977468501307,
              21.011054134491598
            ],
            [
              105.83975536587174,
              21.011076609020257
            ],
            [
              105.83975298144077,
              21.011079382847214
            ],
            [
              105.83975086173163,
              21.011081848271488
            ],
            [
              105.8397435335515,
              21.011090373840595
            ],
            [
              105.83973595593531,
              21.011100735991885
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D110_Ngõ 378, Lê Duẩn",
          "maTaiSan": "04.O16A.DOPP.581",
          "duongKinh": 110,
          "chieudaiQL": 35.89,
          "chatLieu": "PVC",
          "from_latitude": 21.010844980687473,
          "to_latitude": 21.011100735991885,
          "to_longitude": 105.83973595593531,
          "from_longitude": 105.83995247769343
        }
      },
      {
        "id": 580,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83861544239157,
            21.010195206705447,
            105.83938678241103,
            21.01076109424387
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83861544239157,
              21.010195206705447
            ],
            [
              105.83862311692549,
              21.01019839158796
            ],
            [
              105.8386651640199,
              21.01023254934184
            ],
            [
              105.83869991048,
              21.01025916772471
            ],
            [
              105.83874069645941,
              21.010291243419598
            ],
            [
              105.83876085010894,
              21.010308257473632
            ],
            [
              105.83884034440887,
              21.010369652487682
            ],
            [
              105.83886585709767,
              21.010385336757583
            ],
            [
              105.83889109574547,
              21.010400866116644
            ],
            [
              105.83891444742008,
              21.010416444516583
            ],
            [
              105.83893459268965,
              21.01042988904735
            ],
            [
              105.83902235756612,
              21.010491112228287
            ],
            [
              105.8390884418361,
              21.010529399213215
            ],
            [
              105.83909270149573,
              21.01053187020423
            ],
            [
              105.83920758730532,
              21.010599789728072
            ],
            [
              105.83921206428033,
              21.010602418611704
            ],
            [
              105.83921488717746,
              21.01060408649323
            ],
            [
              105.83921849955757,
              21.010606227383505
            ],
            [
              105.83927294167023,
              21.010654771822768
            ],
            [
              105.83933582322132,
              21.010708413058072
            ],
            [
              105.83933915022055,
              21.010711123481368
            ],
            [
              105.83936852858918,
              21.010738613616187
            ],
            [
              105.83938678241103,
              21.01076109424387
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D90_Ngõ 126, Kim Hoa",
          "maTaiSan": "04.O16A.DOPP.582",
          "duongKinh": 90,
          "chieudaiQL": 101.14,
          "chatLieu": "PVC",
          "from_latitude": 21.010195206705447,
          "to_latitude": 21.01076109424387,
          "to_longitude": 105.83938678241103,
          "from_longitude": 105.83861544239157
        }
      },
      {
        "id": 581,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83837210771678,
            21.01040286931748,
            105.8390039927701,
            21.010886022908565
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8390039927701,
              21.010886022908565
            ],
            [
              105.83900317318243,
              21.01088532695085
            ],
            [
              105.83896169227975,
              21.010850117855504
            ],
            [
              105.83893391142094,
              21.010826537057056
            ],
            [
              105.83892708992275,
              21.010820747601393
            ],
            [
              105.83891010988536,
              21.010803801020966
            ],
            [
              105.83887919391175,
              21.010772943939273
            ],
            [
              105.83881599487654,
              21.010711183206467
            ],
            [
              105.83880282014913,
              21.010721763489375
            ],
            [
              105.83879101221619,
              21.010726481430304
            ],
            [
              105.83878921142465,
              21.010727838821094
            ],
            [
              105.83871945371284,
              21.010780419748126
            ],
            [
              105.83861534798537,
              21.010673943212936
            ],
            [
              105.83857185684427,
              21.010622899561916
            ],
            [
              105.83856941006658,
              21.0106200285275
            ],
            [
              105.83856790152706,
              21.01061825841604
            ],
            [
              105.83856661277746,
              21.01061674554362
            ],
            [
              105.83840119876199,
              21.01044611689027
            ],
            [
              105.83838346529915,
              21.010419638625113
            ],
            [
              105.8383784319924,
              21.01041212330599
            ],
            [
              105.83837420282906,
              21.01040581163591
            ],
            [
              105.83837210771678,
              21.01040286931748
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D90_Ngõ 138, Kim Hoa",
          "maTaiSan": "04.O16A.DOPP.583",
          "duongKinh": 90,
          "chieudaiQL": 95.9,
          "chatLieu": "PVC",
          "from_latitude": 21.010886022908565,
          "to_latitude": 21.01040286931748,
          "to_longitude": 105.83837210771678,
          "from_longitude": 105.8390039927701
        }
      },
      {
        "id": 582,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83783420903946,
            21.010880878475813,
            105.83933987295552,
            21.01164945156189
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83783420903946,
              21.010880878475813
            ],
            [
              105.83783873424079,
              21.010881810902866
            ],
            [
              105.83788615141087,
              21.01088147264807
            ],
            [
              105.83789860835202,
              21.010884447949035
            ],
            [
              105.83790144205928,
              21.01088512496206
            ],
            [
              105.83798235340706,
              21.010953269515582
            ],
            [
              105.83799892288032,
              21.01096664505675
            ],
            [
              105.8380009821486,
              21.01096919736313
            ],
            [
              105.83802952323865,
              21.011004567071538
            ],
            [
              105.83805252097592,
              21.011046326389483
            ],
            [
              105.83806908907596,
              21.011078911707656
            ],
            [
              105.83807089809726,
              21.011082469631173
            ],
            [
              105.83807575132518,
              21.011092013584438
            ],
            [
              105.8380803837801,
              21.011101124173933
            ],
            [
              105.8380834649831,
              21.011104778799012
            ],
            [
              105.83808733246528,
              21.011109366195097
            ],
            [
              105.83816240749084,
              21.011146920996264
            ],
            [
              105.83820163470324,
              21.01116149729271
            ],
            [
              105.83823297966214,
              21.01116154159338
            ],
            [
              105.83825297801702,
              21.011153676249542
            ],
            [
              105.83827545641095,
              21.011134147993964
            ],
            [
              105.83830877393552,
              21.01109815926401
            ],
            [
              105.83832762409276,
              21.011076237267645
            ],
            [
              105.83833863768488,
              21.01106259489817
            ],
            [
              105.83837203784643,
              21.011027973231048
            ],
            [
              105.83843737773431,
              21.01106885096673
            ],
            [
              105.83844298698615,
              21.011072360354405
            ],
            [
              105.83846663365162,
              21.011095616573105
            ],
            [
              105.83853934514653,
              21.01115464707571
            ],
            [
              105.8385537942923,
              21.01116692655961
            ],
            [
              105.83857873574415,
              21.011188122448537
            ],
            [
              105.83863043569431,
              21.011232059708387
            ],
            [
              105.83863858174865,
              21.011238709742607
            ],
            [
              105.83868543960926,
              21.011273720799316
            ],
            [
              105.83872492350726,
              21.011304775034983
            ],
            [
              105.83872792149043,
              21.01130713302781
            ],
            [
              105.83880042956629,
              21.0113667334455
            ],
            [
              105.83886596057114,
              21.011408886321185
            ],
            [
              105.83887676608364,
              21.01141583755428
            ],
            [
              105.83888235095982,
              21.011418768080055
            ],
            [
              105.83888467879196,
              21.011419802537397
            ],
            [
              105.8389499723852,
              21.011444949739936
            ],
            [
              105.83900721725264,
              21.01147345595277
            ],
            [
              105.83901566807168,
              21.011477663953265
            ],
            [
              105.83901937211648,
              21.01147950813749
            ],
            [
              105.83903465324224,
              21.011487118322417
            ],
            [
              105.83912351210778,
              21.011539159272292
            ],
            [
              105.83919590827485,
              21.01158369247778
            ],
            [
              105.83920987033345,
              21.01159176347365
            ],
            [
              105.83929120204186,
              21.011629473868716
            ],
            [
              105.83932327652047,
              21.01164263911784
            ],
            [
              105.83933987295552,
              21.01164945156189
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D90_Ngõ 105, Kim Hoa",
          "maTaiSan": "04.O16A.DOPP.584",
          "duongKinh": 90,
          "chieudaiQL": 192.55,
          "chatLieu": "PVC",
          "from_latitude": 21.010880878475813,
          "to_latitude": 21.01164945156189,
          "to_longitude": 105.83933987295552,
          "from_longitude": 105.83783420903946
        }
      },
      {
        "id": 583,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83925820693993,
            21.01548851357791,
            105.83969886952423,
            21.016880472427125
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83952865996568,
              21.01548851357791
            ],
            [
              105.83952749002538,
              21.01548933225252
            ],
            [
              105.83951546052984,
              21.0155072889918
            ],
            [
              105.83949938929904,
              21.01553372436454
            ],
            [
              105.8394803576268,
              21.0155648720105
            ],
            [
              105.83947159104713,
              21.015585070705743
            ],
            [
              105.83945866911651,
              21.015617854649634
            ],
            [
              105.83943456796385,
              21.015670477062233
            ],
            [
              105.83943417131393,
              21.0156709333409
            ],
            [
              105.83943158400446,
              21.01567390868085
            ],
            [
              105.83943137826901,
              21.015674273695144
            ],
            [
              105.83940221505149,
              21.015725976308058
            ],
            [
              105.8393987858313,
              21.015732866760278
            ],
            [
              105.83936893582711,
              21.01578251701912
            ],
            [
              105.83934304243,
              21.015825151381563
            ],
            [
              105.83932840705131,
              21.015851025075715
            ],
            [
              105.83931681208614,
              21.01587152223278
            ],
            [
              105.83930051686201,
              21.0159009176538
            ],
            [
              105.83929307475947,
              21.015922307480814
            ],
            [
              105.83928729224122,
              21.015938927324736
            ],
            [
              105.83927005105397,
              21.01598441743741
            ],
            [
              105.83926878743902,
              21.015987750244108
            ],
            [
              105.83925872502996,
              21.016026507537067
            ],
            [
              105.83925844867126,
              21.016045539824475
            ],
            [
              105.83925838621562,
              21.016049850316225
            ],
            [
              105.83925820693993,
              21.01606216484978
            ],
            [
              105.83925845921674,
              21.01606392850443
            ],
            [
              105.83926297521812,
              21.016095507314795
            ],
            [
              105.83928441473144,
              21.016148080265218
            ],
            [
              105.83929265768477,
              21.016167351426063
            ],
            [
              105.8393019841247,
              21.016216595236017
            ],
            [
              105.83930632316793,
              21.016249897364926
            ],
            [
              105.83931150592969,
              21.016273417934467
            ],
            [
              105.83931253042448,
              21.016278064482165
            ],
            [
              105.83931392352437,
              21.016283259274548
            ],
            [
              105.83931512011752,
              21.01628771800587
            ],
            [
              105.83931680794251,
              21.016294010567428
            ],
            [
              105.83931779921004,
              21.016297704378005
            ],
            [
              105.83932371738737,
              21.016319765322127
            ],
            [
              105.83934868975803,
              21.016374500310548
            ],
            [
              105.8393605284087,
              21.01639427669319
            ],
            [
              105.83936497178652,
              21.016401699139827
            ],
            [
              105.83936770520111,
              21.016406264997578
            ],
            [
              105.83937464992879,
              21.016417866963657
            ],
            [
              105.83939058823451,
              21.016444493035724
            ],
            [
              105.83939980296746,
              21.01646293836477
            ],
            [
              105.83941011894906,
              21.016483586641368
            ],
            [
              105.8394376191788,
              21.016538632495227
            ],
            [
              105.83947371058838,
              21.016619812274765
            ],
            [
              105.83948621430946,
              21.016646866250955
            ],
            [
              105.83950618948286,
              21.01666963097969
            ],
            [
              105.83954969819482,
              21.016711057588523
            ],
            [
              105.83961396596892,
              21.016776081416626
            ],
            [
              105.8396634475337,
              21.016827145717617
            ],
            [
              105.83966638980321,
              21.016830182284725
            ],
            [
              105.83966729117277,
              21.01683121834939
            ],
            [
              105.83967079501261,
              21.016835249303494
            ],
            [
              105.83969202231998,
              21.01685966614666
            ],
            [
              105.83969593760654,
              21.01686846047692
            ],
            [
              105.83969751383594,
              21.016874918874368
            ],
            [
              105.83969886952423,
              21.016880472427125
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D110_Ngõ Hồ Bãi Cát",
          "maTaiSan": "04.O16A.DOPP.585",
          "duongKinh": 110,
          "chieudaiQL": 174.02,
          "chatLieu": "PVC",
          "from_latitude": 21.01548851357791,
          "to_latitude": 21.016880472427125,
          "to_longitude": 105.83969886952423,
          "from_longitude": 105.83952865996568
        }
      },
      {
        "id": 584,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83969886952423,
            21.016880472427125,
            105.83995750147602,
            21.018630033779583
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83969886952423,
              21.016880472427125
            ],
            [
              105.83970663154633,
              21.016912276357992
            ],
            [
              105.83972179949025,
              21.01697877087179
            ],
            [
              105.83973540399168,
              21.01703524731936
            ],
            [
              105.83974744006535,
              21.01709335821292
            ],
            [
              105.83975801781433,
              21.017120195866273
            ],
            [
              105.83977125886126,
              21.01716673141883
            ],
            [
              105.83978719821886,
              21.017217913379834
            ],
            [
              105.83979436137291,
              21.01726490435125
            ],
            [
              105.83979759057732,
              21.017282590687756
            ],
            [
              105.83980015985635,
              21.017296663774985
            ],
            [
              105.83980504420401,
              21.017369430445246
            ],
            [
              105.83980995688624,
              21.017415989945057
            ],
            [
              105.839814734272,
              21.01746312456245
            ],
            [
              105.83982227041119,
              21.01752415339529
            ],
            [
              105.83983862403275,
              21.017589522943474
            ],
            [
              105.83984699818342,
              21.017629274917617
            ],
            [
              105.83985917839337,
              21.017690775785976
            ],
            [
              105.83986960560904,
              21.017734008580867
            ],
            [
              105.83988144676287,
              21.0177741254988
            ],
            [
              105.83989054719827,
              21.01780631751626
            ],
            [
              105.83991687416862,
              21.017865465183164
            ],
            [
              105.8399265412819,
              21.01791896956967
            ],
            [
              105.8399310430354,
              21.017957585433976
            ],
            [
              105.83993367665452,
              21.01801663601613
            ],
            [
              105.83993847090466,
              21.018095797940596
            ],
            [
              105.83994027734333,
              21.01814816604301
            ],
            [
              105.8399437089497,
              21.018213664408062
            ],
            [
              105.83994952779724,
              21.018301604215186
            ],
            [
              105.83995243945112,
              21.018376973764614
            ],
            [
              105.83995750147602,
              21.01843953311705
            ],
            [
              105.83995651204833,
              21.01848082976183
            ],
            [
              105.83995287452143,
              21.018518548265934
            ],
            [
              105.83994771888591,
              21.018542844237743
            ],
            [
              105.83992883951264,
              21.01858027761397
            ],
            [
              105.83988259762417,
              21.01861724905729
            ],
            [
              105.83986435140959,
              21.018628096533952
            ],
            [
              105.83986109257373,
              21.018630033779583
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ Hồ Bãi Cát",
          "maTaiSan": "04.O16A.DOPP.586",
          "duongKinh": 100,
          "chieudaiQL": 200.57,
          "chatLieu": "G",
          "from_latitude": 21.016880472427125,
          "to_latitude": 21.018630033779583,
          "to_longitude": 105.83986109257373,
          "from_longitude": 105.83969886952423
        }
      },
      {
        "id": 585,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83980026700937,
            21.018489396248313,
            105.83993858959559,
            21.018839235087206
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83993858959559,
              21.018839235087206
            ],
            [
              105.83993432190108,
              21.01882043208234
            ],
            [
              105.83990378076554,
              21.01871462755783
            ],
            [
              105.83990283704345,
              21.01871135622634
            ],
            [
              105.83986741813999,
              21.018642356838026
            ],
            [
              105.83986109257373,
              21.018630033779583
            ],
            [
              105.83985921501765,
              21.018623023197467
            ],
            [
              105.8398559315555,
              21.01861077182875
            ],
            [
              105.83985503356773,
              21.01860742169068
            ],
            [
              105.83983839886639,
              21.018602164864667
            ],
            [
              105.83983442771928,
              21.018597319709325
            ],
            [
              105.83982254950128,
              21.01857911065935
            ],
            [
              105.83981037341516,
              21.01856044604793
            ],
            [
              105.83980771381779,
              21.018555672997355
            ],
            [
              105.83980208075472,
              21.018545563027725
            ],
            [
              105.83980178243866,
              21.018545027985787
            ],
            [
              105.83980139180379,
              21.01854432630327
            ],
            [
              105.83980086069457,
              21.018536919783855
            ],
            [
              105.83980026700937,
              21.018528645575696
            ],
            [
              105.83980507665515,
              21.01851384961285
            ],
            [
              105.83981174362354,
              21.018489396248313
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ Hồ Bãi Cát",
          "maTaiSan": "04.O16A.DOPP.587",
          "duongKinh": 100,
          "chieudaiQL": 41.87,
          "chatLieu": "G",
          "from_latitude": 21.018839235087206,
          "to_latitude": 21.018489396248313,
          "to_longitude": 105.83981174362354,
          "from_longitude": 105.83993858959559
        }
      },
      {
        "id": 586,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83880241706426,
            21.01606011585244,
            105.83925845921674,
            21.016265084665847
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83925845921674,
              21.01606392850443
            ],
            [
              105.839237615762,
              21.01606011585244
            ],
            [
              105.83913779279584,
              21.016104699121268
            ],
            [
              105.83912996869566,
              21.01610819344723
            ],
            [
              105.83911575438266,
              21.01611460147863
            ],
            [
              105.83908273764753,
              21.016134357054714
            ],
            [
              105.83903797228348,
              21.01616142526113
            ],
            [
              105.83903195390332,
              21.01616512112823
            ],
            [
              105.83903061180301,
              21.016165946068003
            ],
            [
              105.83902943031,
              21.016164700930016
            ],
            [
              105.83902832235188,
              21.016163532203397
            ],
            [
              105.83902183746353,
              21.016156228202238
            ],
            [
              105.83901843674148,
              21.016152397240617
            ],
            [
              105.8389702994058,
              21.016182841148634
            ],
            [
              105.83896980636077,
              21.01618274603191
            ],
            [
              105.8389668432914,
              21.01618217716167
            ],
            [
              105.83896506083055,
              21.016181835500998
            ],
            [
              105.838960975895,
              21.016181050873268
            ],
            [
              105.8389265962178,
              21.016197223729876
            ],
            [
              105.83886494093176,
              21.016231319163822
            ],
            [
              105.83881816287848,
              21.0162590322047
            ],
            [
              105.83881740457319,
              21.016259323168768
            ],
            [
              105.83880241706426,
              21.016265084665847
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D110_Ngõ Hồ Bãi Cát",
          "maTaiSan": "04.O16A.DOPP.588",
          "duongKinh": 110,
          "chieudaiQL": 53.16,
          "chatLieu": "PVC",
          "from_latitude": 21.01606392850443,
          "to_latitude": 21.016265084665847,
          "to_longitude": 105.83880241706426,
          "from_longitude": 105.83925845921674
        }
      },
      {
        "id": 587,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84087509478145,
            21.01674409586605,
            105.84093354379672,
            21.017255723519124
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8409254256546,
              21.01674409586605
            ],
            [
              105.84092542437577,
              21.016745583481267
            ],
            [
              105.84092542047586,
              21.016751750709727
            ],
            [
              105.84092541405423,
              21.016761242715894
            ],
            [
              105.84092700345856,
              21.016819282315495
            ],
            [
              105.84093066207839,
              21.01682706537066
            ],
            [
              105.84093354379672,
              21.01683319485122
            ],
            [
              105.84093282604088,
              21.016889614633463
            ],
            [
              105.84092901577804,
              21.01689407461743
            ],
            [
              105.84092712354885,
              21.016896289642432
            ],
            [
              105.84092357827545,
              21.01694887025028
            ],
            [
              105.84092919885701,
              21.01696121301397
            ],
            [
              105.84093049218797,
              21.0169906363472
            ],
            [
              105.84093100055314,
              21.017002213157557
            ],
            [
              105.84093237223344,
              21.01703342900163
            ],
            [
              105.84091234992958,
              21.017085516606866
            ],
            [
              105.84089644931319,
              21.017130871080564
            ],
            [
              105.8408872164973,
              21.017165174172856
            ],
            [
              105.84088298653134,
              21.01717404396034
            ],
            [
              105.84088050883229,
              21.01717923980612
            ],
            [
              105.8408777525621,
              21.017185020511118
            ],
            [
              105.84087509478145,
              21.017255723519124
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngách 222/16, Lê Duẩn",
          "maTaiSan": "04.O16A.DOPP.589",
          "duongKinh": 100,
          "chieudaiQL": 59.07,
          "chatLieu": "G",
          "from_latitude": 21.01674409586605,
          "to_latitude": 21.017255723519124,
          "to_longitude": 105.84087509478145,
          "from_longitude": 105.8409254256546
        }
      },
      {
        "id": 588,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83943379049477,
            21.01876776931753,
            105.84067125538216,
            21.01886741365116
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84067125538216,
              21.01876776931753
            ],
            [
              105.84066971595661,
              21.018767888021795
            ],
            [
              105.84066632845277,
              21.018768149536218
            ],
            [
              105.84065917459192,
              21.018768700630588
            ],
            [
              105.84065347200846,
              21.018769195778574
            ],
            [
              105.84065195670196,
              21.018769327008616
            ],
            [
              105.84027197961352,
              21.018802330359815
            ],
            [
              105.84024031146363,
              21.018805835938185
            ],
            [
              105.84022900298858,
              21.018807087663188
            ],
            [
              105.84013239786043,
              21.01881778116515
            ],
            [
              105.84012445510481,
              21.01881866039052
            ],
            [
              105.8400365204671,
              21.01882839424488
            ],
            [
              105.83993858959559,
              21.018839235087206
            ],
            [
              105.8399236337136,
              21.018840890478142
            ],
            [
              105.83988521487076,
              21.01884514286941
            ],
            [
              105.83964839778555,
              21.018858924571482
            ],
            [
              105.83958323433632,
              21.01886037794397
            ],
            [
              105.83950787927182,
              21.018862059524146
            ],
            [
              105.83944310802264,
              21.018860886140235
            ],
            [
              105.83943913282269,
              21.018860815434596
            ],
            [
              105.83943379049477,
              21.01886741365116
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Khâm Thiên",
          "maTaiSan": "04.O16A.DOPP.590",
          "duongKinh": 150,
          "chieudaiQL": 130.71,
          "chatLieu": "G",
          "from_latitude": 21.01876776931753,
          "to_latitude": 21.01886741365116,
          "to_longitude": 105.83943379049477,
          "from_longitude": 105.84067125538216
        }
      },
      {
        "id": 589,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8391948379699,
            21.017941331911285,
            105.83976437873754,
            21.018472603220822
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8391948379699,
              21.018472603220822
            ],
            [
              105.83920698316102,
              21.018468742540687
            ],
            [
              105.83924612773377,
              21.018454091525154
            ],
            [
              105.83933255231898,
              21.01840867194934
            ],
            [
              105.83936215761652,
              21.018393413137716
            ],
            [
              105.83939055528377,
              21.01838186891557
            ],
            [
              105.83941153776762,
              21.018373339206963
            ],
            [
              105.83945353264876,
              21.018341174125386
            ],
            [
              105.83946354118572,
              21.01833350785206
            ],
            [
              105.83950701805324,
              21.018284952777393
            ],
            [
              105.839521489668,
              21.018240616183398
            ],
            [
              105.83954576178371,
              21.018194758062986
            ],
            [
              105.83957213012373,
              21.018165422195825
            ],
            [
              105.83960346579296,
              21.018124885321086
            ],
            [
              105.8396803247331,
              21.018041720111373
            ],
            [
              105.83970288529184,
              21.01801730842676
            ],
            [
              105.83970675295343,
              21.018013123668783
            ],
            [
              105.83973956764908,
              21.017964614845113
            ],
            [
              105.83974155517798,
              21.017962749812973
            ],
            [
              105.8397481397633,
              21.01795657087334
            ],
            [
              105.83975068039138,
              21.01795418646697
            ],
            [
              105.83976437873754,
              21.017941331911285
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ Đình Tương Thuận",
          "maTaiSan": "04.O16A.DOPP.591",
          "duongKinh": 100,
          "chieudaiQL": 85.94,
          "chatLieu": "G",
          "from_latitude": 21.018472603220822,
          "to_latitude": 21.017941331911285,
          "to_longitude": 105.83976437873754,
          "from_longitude": 105.8391948379699
        }
      },
      {
        "id": 590,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8400144846051,
            21.017421068474242,
            105.84056842999127,
            21.017459846793905
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8400144846051,
              21.017457977187046
            ],
            [
              105.84003782598076,
              21.017458099476443
            ],
            [
              105.84018506020085,
              21.017458872622566
            ],
            [
              105.84023992070944,
              21.017459160759692
            ],
            [
              105.84024328089076,
              21.017459178485538
            ],
            [
              105.84025016959963,
              21.017459214911984
            ],
            [
              105.84026812583369,
              21.017459308671647
            ],
            [
              105.84037074554061,
              21.017459846793905
            ],
            [
              105.84037181496035,
              21.01743765292506
            ],
            [
              105.84037193644615,
              21.01743512781552
            ],
            [
              105.84037207955588,
              21.017432169955168
            ],
            [
              105.8403723251299,
              21.01742706914273
            ],
            [
              105.84037257242029,
              21.017421931289654
            ],
            [
              105.84039638924263,
              21.017421068474242
            ],
            [
              105.84040328661195,
              21.01742110575497
            ],
            [
              105.8404118626285,
              21.01742115190381
            ],
            [
              105.84056842999127,
              21.017421992417276
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngách 1/33, Khâm Thiên",
          "maTaiSan": "04.O16A.DOPP.592",
          "duongKinh": 100,
          "chieudaiQL": 61.69,
          "chatLieu": "G",
          "from_latitude": 21.017457977187046,
          "to_latitude": 21.017421992417276,
          "to_longitude": 105.84056842999127,
          "from_longitude": 105.8400144846051
        }
      },
      {
        "id": 591,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83022470936265,
            21.01467280857358,
            105.83435031814552,
            21.018744772338405
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83435031814552,
              21.01467280857358
            ],
            [
              105.8341587220188,
              21.01485153791748
            ],
            [
              105.83415230841258,
              21.01485751890431
            ],
            [
              105.8341303594159,
              21.014877996041317
            ],
            [
              105.83389762139419,
              21.015106475912106
            ],
            [
              105.8338892520766,
              21.015114692021093
            ],
            [
              105.83385161477135,
              21.015154019544532
            ],
            [
              105.8338254855469,
              21.01518132112413
            ],
            [
              105.83367798402166,
              21.015329035676917
            ],
            [
              105.8336474279612,
              21.015359634919058
            ],
            [
              105.83349917785338,
              21.01550512654277
            ],
            [
              105.83348149960278,
              21.015522475583186
            ],
            [
              105.83344102991026,
              21.0155676463088
            ],
            [
              105.83344099260654,
              21.015567684426976
            ],
            [
              105.8334006510078,
              21.015612714516088
            ],
            [
              105.83340062518181,
              21.015612740836122
            ],
            [
              105.83331569435423,
              21.015707540238903
            ],
            [
              105.83324414526395,
              21.01578144569756
            ],
            [
              105.83322573169383,
              21.015800465667017
            ],
            [
              105.83303411702191,
              21.01601290043443
            ],
            [
              105.83301037209998,
              21.016042618087997
            ],
            [
              105.83300908658795,
              21.016044111993658
            ],
            [
              105.8330025411,
              21.016061524798
            ],
            [
              105.8329819856515,
              21.016075598332016
            ],
            [
              105.83295199909597,
              21.016110760467946
            ],
            [
              105.83293321533081,
              21.016130341315883
            ],
            [
              105.83276044106178,
              21.016300617656785
            ],
            [
              105.83275784935498,
              21.016303173824298
            ],
            [
              105.83275311932911,
              21.01630834264952
            ],
            [
              105.8327311347775,
              21.01633236404228
            ],
            [
              105.83263080346855,
              21.01643826687661
            ],
            [
              105.83256959978907,
              21.016497167640715
            ],
            [
              105.83256947826239,
              21.016497282945014
            ],
            [
              105.83248609997011,
              21.016577527015787
            ],
            [
              105.83243101912431,
              21.01662588694283
            ],
            [
              105.83238626443324,
              21.01666518395088
            ],
            [
              105.83236323677403,
              21.01668638692119
            ],
            [
              105.83232918767371,
              21.016717737349587
            ],
            [
              105.83223398328039,
              21.016806529475172
            ],
            [
              105.83215473190236,
              21.016880452304942
            ],
            [
              105.83215161306413,
              21.01688336149337
            ],
            [
              105.83214404517004,
              21.01689042108522
            ],
            [
              105.83200286845997,
              21.017030240740564
            ],
            [
              105.83199622042241,
              21.017036825247242
            ],
            [
              105.8319373842925,
              21.01709376957173
            ],
            [
              105.8319156149567,
              21.01711483987897
            ],
            [
              105.83177873258019,
              21.01725182300444
            ],
            [
              105.83176590835802,
              21.017264915629276
            ],
            [
              105.83175930063507,
              21.017271661607232
            ],
            [
              105.83167927167352,
              21.017354978856297
            ],
            [
              105.8316378937608,
              21.017397360841606
            ],
            [
              105.83162365105488,
              21.017412436646698
            ],
            [
              105.83161972529496,
              21.017416591704723
            ],
            [
              105.83155254505928,
              21.017491082997257
            ],
            [
              105.83155143028247,
              21.017492319541965
            ],
            [
              105.83154478175678,
              21.017499690743065
            ],
            [
              105.83151127851649,
              21.017535174213542
            ],
            [
              105.831453732975,
              21.017591950392713
            ],
            [
              105.83144927658876,
              21.017596347395056
            ],
            [
              105.83136884970513,
              21.017692453404795
            ],
            [
              105.831322684489,
              21.01774481857409
            ],
            [
              105.8313151440265,
              21.017753371027457
            ],
            [
              105.8311977510312,
              21.01787046609749
            ],
            [
              105.83118952085637,
              21.017878675077174
            ],
            [
              105.83108647468012,
              21.01798161779379
            ],
            [
              105.83107593519557,
              21.01799214669325
            ],
            [
              105.83094530175617,
              21.018129600842748
            ],
            [
              105.83091957661362,
              21.018149461965116
            ],
            [
              105.8309187815916,
              21.018150075525245
            ],
            [
              105.83090792045834,
              21.018158460826605
            ],
            [
              105.83077161565723,
              21.018297242290917
            ],
            [
              105.83067482421828,
              21.018377382115176
            ],
            [
              105.83067405243038,
              21.018378022657526
            ],
            [
              105.83060090218329,
              21.018434243405903
            ],
            [
              105.83059284355171,
              21.01844043642547
            ],
            [
              105.83049040684313,
              21.018517390941494
            ],
            [
              105.83048679433517,
              21.018520104704596
            ],
            [
              105.83039184792479,
              21.018603945814863
            ],
            [
              105.83032948200113,
              21.018653050587346
            ],
            [
              105.83024297538346,
              21.01872364075606
            ],
            [
              105.83022470936265,
              21.018744772338405
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Đê La Thành",
          "maTaiSan": "04.O16A.DOPP.593",
          "duongKinh": 100,
          "chieudaiQL": 623.02,
          "chatLieu": "G",
          "from_latitude": 21.01467280857358,
          "to_latitude": 21.018744772338405,
          "to_longitude": 105.83022470936265,
          "from_longitude": 105.83435031814552
        }
      },
      {
        "id": 592,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83246183452938,
            21.018656647553044,
            105.83365261342824,
            21.0188664957993
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83246183452938,
              21.0188664957993
            ],
            [
              105.83252243201615,
              21.018831304061028
            ],
            [
              105.8325401643253,
              21.018821005419284
            ],
            [
              105.83262968742966,
              21.018785915041313
            ],
            [
              105.83271386956245,
              21.018763099390977
            ],
            [
              105.832727388472,
              21.018759902636294
            ],
            [
              105.83281849218942,
              21.01873836001767
            ],
            [
              105.83283361836949,
              21.01873582828806
            ],
            [
              105.83294121422854,
              21.01871054499405
            ],
            [
              105.83296882496461,
              21.018707372250965
            ],
            [
              105.83297154652941,
              21.018707059956967
            ],
            [
              105.83299725384305,
              21.01871866063803
            ],
            [
              105.83309853582166,
              21.018730515336426
            ],
            [
              105.8331670676836,
              21.018740409611592
            ],
            [
              105.83317172151519,
              21.018741081397348
            ],
            [
              105.83319289588786,
              21.018742599877527
            ],
            [
              105.83319962649632,
              21.01874308265197
            ],
            [
              105.8332091507788,
              21.018743765806672
            ],
            [
              105.8332408569539,
              21.018743952825506
            ],
            [
              105.83327814156274,
              21.018742763095094
            ],
            [
              105.83330435628372,
              21.018736955204375
            ],
            [
              105.83331905708316,
              21.018733697516687
            ],
            [
              105.83342187882155,
              21.018713809687178
            ],
            [
              105.8334341411713,
              21.018711438257753
            ],
            [
              105.83355040279774,
              21.01868124284118
            ],
            [
              105.83364839179174,
              21.018658275078227
            ],
            [
              105.83365261342824,
              21.018656647553044
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngách 24, Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DOPP.594",
          "duongKinh": 100,
          "chieudaiQL": 129.98,
          "chatLieu": "G",
          "from_latitude": 21.0188664957993,
          "to_latitude": 21.018656647553044,
          "to_longitude": 105.83365261342824,
          "from_longitude": 105.83246183452938
        }
      },
      {
        "id": 593,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.831453732975,
            21.017591950392713,
            105.83195932604312,
            21.01801455056532
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.831453732975,
              21.017591950392713
            ],
            [
              105.83145464025158,
              21.017592846473573
            ],
            [
              105.83146418973502,
              21.017602253854637
            ],
            [
              105.83146537463217,
              21.017603080836672
            ],
            [
              105.83154169269031,
              21.017656143492353
            ],
            [
              105.83161602591672,
              21.017706361624285
            ],
            [
              105.83168200457173,
              21.01773731052297
            ],
            [
              105.83174347826153,
              21.017766146197864
            ],
            [
              105.83181583293096,
              21.017847940716667
            ],
            [
              105.83183074123853,
              21.0178664959269
            ],
            [
              105.8318537637624,
              21.01789583118759
            ],
            [
              105.83186605103342,
              21.017911487214377
            ],
            [
              105.8318828064108,
              21.017932836997076
            ],
            [
              105.83189844094387,
              21.01795415522692
            ],
            [
              105.83194365352007,
              21.017999392520476
            ],
            [
              105.83195486655659,
              21.018010237799167
            ],
            [
              105.83195687337086,
              21.018012178046956
            ],
            [
              105.83195932604312,
              21.01801455056532
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 298, Đê La Thành",
          "maTaiSan": "04.O16A.DOPP.595",
          "duongKinh": 100,
          "chieudaiQL": 71.19,
          "chatLieu": "G",
          "from_latitude": 21.017591950392713,
          "to_latitude": 21.01801455056532,
          "to_longitude": 105.83195932604312,
          "from_longitude": 105.831453732975
        }
      },
      {
        "id": 594,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83440720852893,
            21.016337842747326,
            105.83595415766283,
            21.017348754010076
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83440720852893,
              21.016337842747326
            ],
            [
              105.83442087047814,
              21.016349856367224
            ],
            [
              105.83447189571041,
              21.01640795439629
            ],
            [
              105.83455675367313,
              21.01649029698735
            ],
            [
              105.83456339127306,
              21.016487912429188
            ],
            [
              105.83464176466305,
              21.01645975649605
            ],
            [
              105.83474241475074,
              21.016432479351828
            ],
            [
              105.83479640724553,
              21.01641617315262
            ],
            [
              105.83479819153342,
              21.016415634204357
            ],
            [
              105.8348024448238,
              21.01641434972234
            ],
            [
              105.83484228134341,
              21.01641981384295
            ],
            [
              105.83484858446147,
              21.016419899427685
            ],
            [
              105.83485785539013,
              21.01642002464156
            ],
            [
              105.83490391207178,
              21.01645024450808
            ],
            [
              105.83491382196931,
              21.0164580159908
            ],
            [
              105.83496130098598,
              21.016495248720844
            ],
            [
              105.83500893235859,
              21.01652476805571
            ],
            [
              105.83503457711562,
              21.016546772083675
            ],
            [
              105.83505294572485,
              21.01657343723508
            ],
            [
              105.83505619545615,
              21.016578154179495
            ],
            [
              105.83506025010533,
              21.016584039558097
            ],
            [
              105.83510494595701,
              21.016657665075375
            ],
            [
              105.83513815583017,
              21.016740371982802
            ],
            [
              105.83514348459434,
              21.01675463844587
            ],
            [
              105.83514453823945,
              21.01675745946246
            ],
            [
              105.83516320930303,
              21.016796799869137
            ],
            [
              105.83517157900208,
              21.01681093398327
            ],
            [
              105.83518914305787,
              21.016850996981216
            ],
            [
              105.83520908874067,
              21.01688239449186
            ],
            [
              105.83521021297547,
              21.01688182626364
            ],
            [
              105.83531749586636,
              21.016827610061718
            ],
            [
              105.835333685065,
              21.016817534586064
            ],
            [
              105.8353375416004,
              21.01681513475018
            ],
            [
              105.83534362021489,
              21.01681135110222
            ],
            [
              105.83537330614689,
              21.016800531027993
            ],
            [
              105.83548215508162,
              21.016739774013608
            ],
            [
              105.83558144656045,
              21.016678416263105
            ],
            [
              105.83558699123189,
              21.0166832565052
            ],
            [
              105.83560117769358,
              21.01669563867516
            ],
            [
              105.83564815246213,
              21.016735383742482
            ],
            [
              105.83565960640131,
              21.01674489352793
            ],
            [
              105.83569125285919,
              21.016760510175565
            ],
            [
              105.83569786254701,
              21.016760195900538
            ],
            [
              105.83572483983576,
              21.016758911765187
            ],
            [
              105.8357672778552,
              21.01681241802882
            ],
            [
              105.83578203676852,
              21.01683102690807
            ],
            [
              105.83579965252892,
              21.01685323735693
            ],
            [
              105.83586299254004,
              21.016930880730644
            ],
            [
              105.83590304268945,
              21.016996738538246
            ],
            [
              105.83588337209788,
              21.017007998138922
            ],
            [
              105.83591055454525,
              21.017048004762813
            ],
            [
              105.83591085516012,
              21.017048955284
            ],
            [
              105.83591319054949,
              21.017056338505228
            ],
            [
              105.83591351381156,
              21.017057555366268
            ],
            [
              105.83593108571448,
              21.01712366632403
            ],
            [
              105.83593646502038,
              21.017146467076127
            ],
            [
              105.83594533005392,
              21.01718404460845
            ],
            [
              105.83595350497725,
              21.01723915440285
            ],
            [
              105.83595415766283,
              21.017243556219906
            ],
            [
              105.83594927319885,
              21.01726017877264
            ],
            [
              105.83594741437096,
              21.01726650234704
            ],
            [
              105.8359478728142,
              21.017348754010076
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Ngách 119, Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DOPP.596",
          "duongKinh": 150,
          "chieudaiQL": 262.4,
          "chatLieu": "G",
          "from_latitude": 21.016337842747326,
          "to_latitude": 21.017348754010076,
          "to_longitude": 105.8359478728142,
          "from_longitude": 105.83440720852893
        }
      },
      {
        "id": 595,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83325085998055,
            21.015921114642968,
            105.83376796858754,
            21.016249039370816
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83325085998055,
              21.016249039370816
            ],
            [
              105.83339097614952,
              21.01608090962716
            ],
            [
              105.83339437606003,
              21.016076829363985
            ],
            [
              105.8333931903248,
              21.016065982927262
            ],
            [
              105.83341872447143,
              21.016037320456057
            ],
            [
              105.83345810688414,
              21.015993113382482
            ],
            [
              105.83347055290464,
              21.015996483759093
            ],
            [
              105.8335710209798,
              21.01607736394137
            ],
            [
              105.83357463141306,
              21.016077932444023
            ],
            [
              105.8335869059164,
              21.016079863912015
            ],
            [
              105.83359657128017,
              21.016081384551246
            ],
            [
              105.83360767619146,
              21.016069319056022
            ],
            [
              105.833698986572,
              21.01597676347114
            ],
            [
              105.83371052839243,
              21.015966119310583
            ],
            [
              105.83372909700442,
              21.015954233102242
            ],
            [
              105.83376796858754,
              21.015921114642968
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngách 216/8, Đê La Thành",
          "maTaiSan": "04.O16A.DOPP.597",
          "duongKinh": 100,
          "chieudaiQL": 79.06,
          "chatLieu": "G",
          "from_latitude": 21.016249039370816,
          "to_latitude": 21.015921114642968,
          "to_longitude": 105.83376796858754,
          "from_longitude": 105.83325085998055
        }
      },
      {
        "id": 596,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83505294572485,
            21.016469456693834,
            105.83522194178504,
            21.01657343723508
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83505294572485,
              21.01657343723508
            ],
            [
              105.83511971185891,
              21.016552810432902
            ],
            [
              105.835177673765,
              21.01651222492343
            ],
            [
              105.83520998852816,
              21.01648100077936
            ],
            [
              105.83521741231887,
              21.01647383247278
            ],
            [
              105.83522194178504,
              21.016469456693834
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngách 119, Ngõ Thổ Quan",
          "maTaiSan": "04.O16A.DOPP.598",
          "duongKinh": 100,
          "chieudaiQL": 16.62,
          "chatLieu": "G",
          "from_latitude": 21.01657343723508,
          "to_latitude": 21.016469456693834,
          "to_longitude": 105.83522194178504,
          "from_longitude": 105.83505294572485
        }
      },
      {
        "id": 597,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83618372115166,
            21.018531639751263,
            105.83695449707243,
            21.01876673705046
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83618372115166,
              21.018648450709982
            ],
            [
              105.8362056660092,
              21.018644612521793
            ],
            [
              105.83632932758798,
              21.018631548230942
            ],
            [
              105.83636370398322,
              21.018627917160803
            ],
            [
              105.83641106955493,
              21.018621852157978
            ],
            [
              105.83649272471281,
              21.018611081636383
            ],
            [
              105.83649485291737,
              21.01861080110719
            ],
            [
              105.83650257313015,
              21.01860313361098
            ],
            [
              105.83650626029903,
              21.0186027876092
            ],
            [
              105.83653485486143,
              21.01860010712827
            ],
            [
              105.83653972744807,
              21.018622577055524
            ],
            [
              105.83657369790797,
              21.01876673705046
            ],
            [
              105.83671592413747,
              21.018742601587107
            ],
            [
              105.83676947045862,
              21.018726566103545
            ],
            [
              105.83680489127734,
              21.018711336024655
            ],
            [
              105.83681432854266,
              21.01870883554092
            ],
            [
              105.83682280814973,
              21.01870658815428
            ],
            [
              105.83686521723278,
              21.018694573484755
            ],
            [
              105.83683570829432,
              21.018577004195183
            ],
            [
              105.83683361274787,
              21.018568656070126
            ],
            [
              105.83684705126723,
              21.01856434751774
            ],
            [
              105.83692399985274,
              21.018539675845236
            ],
            [
              105.8369332854244,
              21.01853757532648
            ],
            [
              105.83693800555213,
              21.018536507976755
            ],
            [
              105.83694038343098,
              21.01853580531132
            ],
            [
              105.83694666139053,
              21.018533952621052
            ],
            [
              105.83695449707243,
              21.018531639751263
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ Lệnh Cư",
          "maTaiSan": "04.O16A.DOPP.599",
          "duongKinh": 100,
          "chieudaiQL": 114.98,
          "chatLieu": "G",
          "from_latitude": 21.018648450709982,
          "to_latitude": 21.018531639751263,
          "to_longitude": 105.83695449707243,
          "from_longitude": 105.83618372115166
        }
      },
      {
        "id": 598,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84066626179377,
            21.00816871458495,
            105.84088133336272,
            21.008595357311954
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84066626179377,
              21.008171526943496
            ],
            [
              105.8406736466305,
              21.00817692516337
            ],
            [
              105.84069710435558,
              21.00816871458495
            ],
            [
              105.84069894081948,
              21.008171844217053
            ],
            [
              105.84070040046318,
              21.00817432990529
            ],
            [
              105.8407010618261,
              21.008175457474888
            ],
            [
              105.84072809091701,
              21.00821357820375
            ],
            [
              105.84077844970601,
              21.008282779226516
            ],
            [
              105.84078146547449,
              21.00828694354546
            ],
            [
              105.84079793622769,
              21.008321508045242
            ],
            [
              105.84080831616687,
              21.008340769501604
            ],
            [
              105.84080884395935,
              21.00834174869866
            ],
            [
              105.8408112693056,
              21.00834783257603
            ],
            [
              105.84081196295239,
              21.008349571468457
            ],
            [
              105.84082941033682,
              21.008382301206822
            ],
            [
              105.8408330506526,
              21.00838908991821
            ],
            [
              105.84086945868404,
              21.00847020201224
            ],
            [
              105.84087209634022,
              21.008483961342254
            ],
            [
              105.84087355588322,
              21.008491577342912
            ],
            [
              105.84087444665964,
              21.00849622364299
            ],
            [
              105.84087519078824,
              21.008500104733532
            ],
            [
              105.84087656212918,
              21.008507257815918
            ],
            [
              105.84087759262833,
              21.008512635037185
            ],
            [
              105.84088089415002,
              21.00858563988634
            ],
            [
              105.84088133336272,
              21.008595357311954
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D90_Ngõ 2, Kim Hoa",
          "maTaiSan": "04.O16A.DOPP.600",
          "duongKinh": 90,
          "chieudaiQL": 53.94,
          "chatLieu": "PVC",
          "from_latitude": 21.008171526943496,
          "to_latitude": 21.008595357311954,
          "to_longitude": 105.84088133336272,
          "from_longitude": 105.84066626179377
        }
      },
      {
        "id": 599,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83584490564847,
            21.01247597338005,
            105.83646275808233,
            21.01306257587748
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83584490564847,
              21.01306257587748
            ],
            [
              105.83587660893048,
              21.01303794726006
            ],
            [
              105.8358792901368,
              21.013036281186974
            ],
            [
              105.83588257142493,
              21.013034241843727
            ],
            [
              105.83598407570418,
              21.012938718500237
            ],
            [
              105.83599443570725,
              21.012930629802014
            ],
            [
              105.83611267874377,
              21.012838312781078
            ],
            [
              105.83626510336725,
              21.012673863264656
            ],
            [
              105.8362854589702,
              21.012653586082962
            ],
            [
              105.83629077578566,
              21.012648290538234
            ],
            [
              105.83643325145691,
              21.012506364715236
            ],
            [
              105.8364553515355,
              21.01248360139346
            ],
            [
              105.83646275808233,
              21.01247597338005
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Kim Hoa",
          "maTaiSan": "04.O16A.DOPP.601",
          "duongKinh": 150,
          "chieudaiQL": 91.65,
          "chatLieu": "G",
          "from_latitude": 21.01306257587748,
          "to_latitude": 21.01247597338005,
          "to_longitude": 105.83646275808233,
          "from_longitude": 105.83584490564847
        }
      },
      {
        "id": 600,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83943170805573,
            21.0188372668674,
            105.83944356418156,
            21.018936365366034
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83944356418156,
              21.018936365366034
            ],
            [
              105.83944329390738,
              21.018927091498647
            ],
            [
              105.83943769131179,
              21.01890678397448
            ],
            [
              105.8394370016759,
              21.018893222768536
            ],
            [
              105.83943601075144,
              21.018873611452506
            ],
            [
              105.8394433788364,
              21.018867850462765
            ],
            [
              105.83944310802264,
              21.018860886140235
            ],
            [
              105.83944218893524,
              21.0188372668674
            ],
            [
              105.83943170805573,
              21.018837545296915
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D250_Khâm Thiên",
          "maTaiSan": "04.O16A.DOPP.602",
          "duongKinh": 250,
          "chieudaiQL": 14.39,
          "chatLieu": "G",
          "from_latitude": 21.018936365366034,
          "to_latitude": 21.018837545296915,
          "to_longitude": 105.83943170805573,
          "from_longitude": 105.83944356418156
        }
      },
      {
        "id": 601,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84045713964463,
            21.01567822807475,
            105.84131947798562,
            21.01618027401336
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84131947798562,
              21.01618027401336
            ],
            [
              105.84116262359929,
              21.016177075450486
            ],
            [
              105.84090968341025,
              21.01616965043368
            ],
            [
              105.8408721820834,
              21.016138397296118
            ],
            [
              105.84075992914933,
              21.01604856470872
            ],
            [
              105.84071735184787,
              21.016013727339082
            ],
            [
              105.84068659713814,
              21.015988564673
            ],
            [
              105.84066518336493,
              21.015971044985456
            ],
            [
              105.84064898992003,
              21.015957796134018
            ],
            [
              105.84059124389124,
              21.015959006366593
            ],
            [
              105.84049895969,
              21.01596093908633
            ],
            [
              105.84047563669476,
              21.015943798224914
            ],
            [
              105.84046980462732,
              21.015939191693775
            ],
            [
              105.84045932218396,
              21.015930913952
            ],
            [
              105.8404592836554,
              21.01592644137938
            ],
            [
              105.84045919932488,
              21.015916713175258
            ],
            [
              105.8404577660616,
              21.01575070957412
            ],
            [
              105.84045729527062,
              21.015696216809648
            ],
            [
              105.84045713964463,
              21.01567822807475
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 226, Lê Duẩn",
          "maTaiSan": "04.O16A.DOPP.603",
          "duongKinh": 100,
          "chieudaiQL": 129.72,
          "chatLieu": "G",
          "from_latitude": 21.01618027401336,
          "to_latitude": 21.01567822807475,
          "to_longitude": 105.84045713964463,
          "from_longitude": 105.84131947798562
        }
      },
      {
        "id": 602,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83998852106613,
            21.014577984909547,
            105.84130342506066,
            21.014895891436417
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84130342506066,
              21.014593504510078
            ],
            [
              105.84128064008031,
              21.014594916916778
            ],
            [
              105.84126939448375,
              21.01459034169624
            ],
            [
              105.84126586963475,
              21.014588908548546
            ],
            [
              105.84122388222548,
              21.014589219125273
            ],
            [
              105.8411690718396,
              21.014591161989696
            ],
            [
              105.84112389303334,
              21.014588435424745
            ],
            [
              105.84109166781887,
              21.01458541177841
            ],
            [
              105.84108708562907,
              21.014584289143652
            ],
            [
              105.8410810209907,
              21.014582802612505
            ],
            [
              105.84106987712255,
              21.014580071258166
            ],
            [
              105.84099878081737,
              21.014577984909547
            ],
            [
              105.84092659129601,
              21.01458182095234
            ],
            [
              105.84092290820345,
              21.014582016188502
            ],
            [
              105.84082689268942,
              21.014581738348717
            ],
            [
              105.84073955149778,
              21.014588777959368
            ],
            [
              105.84069413558777,
              21.0145988393887
            ],
            [
              105.84067755376348,
              21.01468138022248
            ],
            [
              105.84067641955909,
              21.014687028264877
            ],
            [
              105.84057463377668,
              21.014669315870773
            ],
            [
              105.84054724319105,
              21.014675558793794
            ],
            [
              105.84053525842435,
              21.01467828973938
            ],
            [
              105.84050355895488,
              21.014731671508326
            ],
            [
              105.8404892998822,
              21.01475031493885
            ],
            [
              105.8404659368776,
              21.01477767428916
            ],
            [
              105.8404518888889,
              21.014791587394875
            ],
            [
              105.8404502951529,
              21.014793165070536
            ],
            [
              105.84043164059794,
              21.014816199102622
            ],
            [
              105.84039973548211,
              21.014845249023736
            ],
            [
              105.84038748915945,
              21.01485280627213
            ],
            [
              105.8403716499407,
              21.014862580616356
            ],
            [
              105.84031812657713,
              21.014879132817942
            ],
            [
              105.84021998745622,
              21.014892517355868
            ],
            [
              105.84018548428374,
              21.01489412116549
            ],
            [
              105.84018117753844,
              21.014894432368628
            ],
            [
              105.84016098516211,
              21.014895891436417
            ],
            [
              105.84007170276183,
              21.01489145761314
            ],
            [
              105.84005252066125,
              21.014891071812883
            ],
            [
              105.83998852106613,
              21.014866091218828
            ],
            [
              105.83999299868525,
              21.014851631983653
            ],
            [
              105.84000527029775,
              21.014811999357114
            ],
            [
              105.84002396769756,
              21.014755722912103
            ],
            [
              105.84002501818806,
              21.0147516116703
            ],
            [
              105.84002563661826,
              21.014749190686896
            ],
            [
              105.84002633896723,
              21.01474474968089
            ],
            [
              105.84002723365234,
              21.01473909740544
            ],
            [
              105.84003510806068,
              21.014700286743793
            ],
            [
              105.8400357628735,
              21.014697320033584
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 304, Lê Duẩn",
          "maTaiSan": "04.O16A.DOPP.604",
          "duongKinh": 100,
          "chieudaiQL": 176.15,
          "chatLieu": "G",
          "from_latitude": 21.014593504510078,
          "to_latitude": 21.014697320033584,
          "to_longitude": 105.8400357628735,
          "from_longitude": 105.84130342506066
        }
      },
      {
        "id": 603,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83725794580916,
            21.01166048623382,
            105.83812050233088,
            21.01224999237482
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83725794580916,
              21.01166048623382
            ],
            [
              105.8372625788458,
              21.011664018528336
            ],
            [
              105.83728935793178,
              21.01168443782485
            ],
            [
              105.8373769152761,
              21.01171604252094
            ],
            [
              105.83738747621237,
              21.01171985454176
            ],
            [
              105.83744322656267,
              21.011744136130574
            ],
            [
              105.83754866449118,
              21.011821319761847
            ],
            [
              105.8375523907782,
              21.011824048125487
            ],
            [
              105.83756166881885,
              21.01183083986486
            ],
            [
              105.83771769193945,
              21.01195850307573
            ],
            [
              105.83775385675735,
              21.011988094394233
            ],
            [
              105.83781152657228,
              21.01203568133789
            ],
            [
              105.83790425572478,
              21.01210315442347
            ],
            [
              105.83790755020026,
              21.012105551615495
            ],
            [
              105.83791954333078,
              21.012114678347135
            ],
            [
              105.83792364987801,
              21.01211780315149
            ],
            [
              105.83792408748442,
              21.01211813609271
            ],
            [
              105.83792528909618,
              21.01212006307543
            ],
            [
              105.83792598794055,
              21.012121185051477
            ],
            [
              105.83792922379712,
              21.012123592467205
            ],
            [
              105.8379385614159,
              21.012130539245966
            ],
            [
              105.83812050233088,
              21.01224999237482
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D110_Ngõ 198, Kim Hoa",
          "maTaiSan": "04.O16A.DOPP.605",
          "duongKinh": 110,
          "chieudaiQL": 107.43,
          "chatLieu": "PVC",
          "from_latitude": 21.01166048623382,
          "to_latitude": 21.01224999237482,
          "to_longitude": 105.83812050233088,
          "from_longitude": 105.83725794580916
        }
      },
      {
        "id": 604,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84129738004673,
            21.014223506293938,
            105.84140566554946,
            21.01618516470852
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84140566554946,
              21.016183317952066
            ],
            [
              105.84131953042872,
              21.01618516470852
            ],
            [
              105.84131947798562,
              21.01618027401336
            ],
            [
              105.84131446236431,
              21.015674515395823
            ],
            [
              105.84131412420551,
              21.01563960838618
            ],
            [
              105.84131049790808,
              21.01527468792022
            ],
            [
              105.8413104147819,
              21.015265526935732
            ],
            [
              105.84131040475327,
              21.015264253440243
            ],
            [
              105.84130840550611,
              21.014993384800363
            ],
            [
              105.84130838371964,
              21.014991386977737
            ],
            [
              105.84130834982467,
              21.014986887285918
            ],
            [
              105.84130822459791,
              21.014968847822413
            ],
            [
              105.8413078097056,
              21.014912540211796
            ],
            [
              105.84130711702944,
              21.014818710457167
            ],
            [
              105.84130379701166,
              21.014615744550234
            ],
            [
              105.84130342506066,
              21.014593504510078
            ],
            [
              105.84130331691405,
              21.014586399385344
            ],
            [
              105.84130291981708,
              21.014562591473407
            ],
            [
              105.84130287739364,
              21.014560001238088
            ],
            [
              105.8412992860899,
              21.014340218643564
            ],
            [
              105.8412977530688,
              21.014246281038147
            ],
            [
              105.8412974618343,
              21.01422848445782
            ],
            [
              105.84129738004673,
              21.014223506293938
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Lê Duẩn",
          "maTaiSan": "04.O16A.DOPP.606",
          "duongKinh": 150,
          "chieudaiQL": 226.16,
          "chatLieu": "G",
          "from_latitude": 21.016183317952066,
          "to_latitude": 21.014223506293938,
          "to_longitude": 105.84129738004673,
          "from_longitude": 105.84140566554946
        }
      },
      {
        "id": 605,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83646275808233,
            21.010626721561014,
            105.83786400160254,
            21.01247597338005
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83646275808233,
              21.01247597338005
            ],
            [
              105.83651587318846,
              21.01243664598595
            ],
            [
              105.83654936677894,
              21.012394671050743
            ],
            [
              105.83664765717194,
              21.012271508199905
            ],
            [
              105.83665449309818,
              21.012264536918927
            ],
            [
              105.83673654711261,
              21.012180857026983
            ],
            [
              105.83674449538383,
              21.012172746725646
            ],
            [
              105.83687479807152,
              21.012039777173833
            ],
            [
              105.83687943070795,
              21.012035132588608
            ],
            [
              105.83691351690908,
              21.012000963950065
            ],
            [
              105.83697665515031,
              21.011930825999507
            ],
            [
              105.83700884805219,
              21.01189506794835
            ],
            [
              105.83700901071612,
              21.011894913600003
            ],
            [
              105.83701190904515,
              21.011892170701305
            ],
            [
              105.83712923996347,
              21.011781109259097
            ],
            [
              105.8372551505477,
              21.011663425532312
            ],
            [
              105.83725658499675,
              21.01166191731485
            ],
            [
              105.83725794580916,
              21.01166048623382
            ],
            [
              105.83745362502492,
              21.01145467104681
            ],
            [
              105.8375531520599,
              21.011315830666668
            ],
            [
              105.83756416427738,
              21.011302443061275
            ],
            [
              105.83756614295756,
              21.01130003706697
            ],
            [
              105.83758394154329,
              21.011278398518982
            ],
            [
              105.83759810747466,
              21.01126098055462
            ],
            [
              105.83769135624243,
              21.01114632322593
            ],
            [
              105.83773126812655,
              21.011084727338847
            ],
            [
              105.83781345074813,
              21.010971934489547
            ],
            [
              105.83781664508342,
              21.01096226058191
            ],
            [
              105.83783327224513,
              21.01091190162462
            ],
            [
              105.83783420903946,
              21.010880878475813
            ],
            [
              105.83784139942952,
              21.010795469387002
            ],
            [
              105.83784515961078,
              21.01076947684487
            ],
            [
              105.83785361356495,
              21.010711044462795
            ],
            [
              105.83785384099914,
              21.010670297981147
            ],
            [
              105.83785393718823,
              21.010653073015682
            ],
            [
              105.83786400160254,
              21.010626721561014
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D225_Kim Hoa",
          "maTaiSan": "04.O16A.DOPP.607",
          "duongKinh": 225,
          "chieudaiQL": 261.91,
          "chatLieu": "PVC",
          "from_latitude": 21.01247597338005,
          "to_latitude": 21.010626721561014,
          "to_longitude": 105.83786400160254,
          "from_longitude": 105.83646275808233
        }
      },
      {
        "id": 606,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83755535064127,
            21.01009082963647,
            105.83805989598166,
            21.010384384578018
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83805989598166,
              21.010384384578018
            ],
            [
              105.83805906630643,
              21.010383947890613
            ],
            [
              105.83787862749384,
              21.010281914926075
            ],
            [
              105.83764224442221,
              21.010141400693836
            ],
            [
              105.83755535064127,
              21.01009082963647
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D90_Xã Đàn",
          "maTaiSan": "04.O16A.DOPP.608",
          "duongKinh": 90,
          "chieudaiQL": 61.7,
          "chatLieu": "PVC",
          "from_latitude": 21.010384384578018,
          "to_latitude": 21.01009082963647,
          "to_longitude": 105.83755535064127,
          "from_longitude": 105.83805989598166
        }
      },
      {
        "id": 607,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8396616024933,
            21.0137380520963,
            105.84103950248542,
            21.014307547096994
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84103950248542,
              21.0137380520963
            ],
            [
              105.84092727663597,
              21.013773539892085
            ],
            [
              105.84087036461891,
              21.013836552767053
            ],
            [
              105.84077886149876,
              21.01397142994619
            ],
            [
              105.84060837791993,
              21.014205276958478
            ],
            [
              105.84059917080344,
              21.014211878949027
            ],
            [
              105.84046578838516,
              21.014307547096994
            ],
            [
              105.84004574111748,
              21.01428871104728
            ],
            [
              105.83998351898684,
              21.01426451108363
            ],
            [
              105.83976516894083,
              21.014179568627476
            ],
            [
              105.8396616024933,
              21.014009838336793
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Hồ Ba Mẫu",
          "maTaiSan": "04.O16A.DOPP.609",
          "duongKinh": 100,
          "chieudaiQL": 186.14,
          "chatLieu": "G",
          "from_latitude": 21.0137380520963,
          "to_latitude": 21.014009838336793,
          "to_longitude": 105.8396616024933,
          "from_longitude": 105.84103950248542
        }
      },
      {
        "id": 608,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84128054008437,
            21.013754143614968,
            105.84140515067665,
            21.016077474463383
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84128054008437,
              21.013754143614968
            ],
            [
              105.84128938023198,
              21.014209535950407
            ],
            [
              105.84128954452001,
              21.014217732795174
            ],
            [
              105.84128954872591,
              21.014228602166835
            ],
            [
              105.84128955605154,
              21.014246202345085
            ],
            [
              105.84128959294202,
              21.014339909800974
            ],
            [
              105.8412992860899,
              21.014340218643564
            ],
            [
              105.84132855029465,
              21.014341157802733
            ],
            [
              105.84133792989586,
              21.014762343261253
            ],
            [
              105.84134796483349,
              21.015658597500614
            ],
            [
              105.84134852723578,
              21.016075261604907
            ],
            [
              105.84140507658917,
              21.01607747121691
            ],
            [
              105.84140515067665,
              21.016077474463383
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Lê Duẩn",
          "maTaiSan": "04.O16A.DOPP.610",
          "duongKinh": 100,
          "chieudaiQL": 266.82,
          "chatLieu": "G",
          "from_latitude": 21.013754143614968,
          "to_latitude": 21.016077474463383,
          "to_longitude": 105.84140515067665,
          "from_longitude": 105.84128054008437
        }
      },
      {
        "id": 609,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8389920538285,
            21.01231474678102,
            105.83934387612007,
            21.013105282739634
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8389920538285,
              21.01231474678102
            ],
            [
              105.83918167447882,
              21.012328740166563
            ],
            [
              105.83916960433658,
              21.012563291129617
            ],
            [
              105.83917055429859,
              21.012576630677394
            ],
            [
              105.83918054387522,
              21.012716963207822
            ],
            [
              105.83918080689047,
              21.01272064796812
            ],
            [
              105.83918727211916,
              21.01281147410147
            ],
            [
              105.83918839752164,
              21.01282728671415
            ],
            [
              105.83931574617394,
              21.013054984572104
            ],
            [
              105.83931695975637,
              21.0130571553555
            ],
            [
              105.83932001459041,
              21.013062617436713
            ],
            [
              105.83933643239324,
              21.013091974325167
            ],
            [
              105.83934387612007,
              21.013105282739634
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D110_Hồ Ba Mẫu",
          "maTaiSan": "04.O16A.DOPP.611",
          "duongKinh": 110,
          "chieudaiQL": 109.83,
          "chatLieu": "PVC",
          "from_latitude": 21.01231474678102,
          "to_latitude": 21.013105282739634,
          "to_longitude": 105.83934387612007,
          "from_longitude": 105.8389920538285
        }
      },
      {
        "id": 610,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83705032244961,
            21.01845955306188,
            105.83727871491949,
            21.01851703460801
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83727871491949,
              21.01845955306188
            ],
            [
              105.83723287711827,
              21.01847218264138
            ],
            [
              105.83721987802436,
              21.018475764675546
            ],
            [
              105.83711738834593,
              21.018502791326892
            ],
            [
              105.83708354745937,
              21.018509978614922
            ],
            [
              105.83705032244961,
              21.01851703460801
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ Cống Trắng",
          "maTaiSan": "04.O16A.DOPP.612",
          "duongKinh": 100,
          "chieudaiQL": 24.36,
          "chatLieu": "G",
          "from_latitude": 21.01845955306188,
          "to_latitude": 21.01851703460801,
          "to_longitude": 105.83705032244961,
          "from_longitude": 105.83727871491949
        }
      },
      {
        "id": 611,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83604985049952,
            21.018260007922894,
            105.83625987125659,
            21.01907857826932
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83604985049952,
              21.018260007922894
            ],
            [
              105.83605378276114,
              21.01830380128609
            ],
            [
              105.83605546722157,
              21.018307439322875
            ],
            [
              105.83610569235174,
              21.01841590180643
            ],
            [
              105.8361108622988,
              21.01842874282197
            ],
            [
              105.83617253891231,
              21.018581942584145
            ],
            [
              105.83617636652941,
              21.018600402829286
            ],
            [
              105.83618372115166,
              21.018648450709982
            ],
            [
              105.83620204029211,
              21.018768120934382
            ],
            [
              105.83620909191866,
              21.018796329180997
            ],
            [
              105.8362111384565,
              21.018804519491145
            ],
            [
              105.83623022618691,
              21.018880877245337
            ],
            [
              105.83623063556136,
              21.018884252387288
            ],
            [
              105.83624005903553,
              21.018946383096125
            ],
            [
              105.8362516627602,
              21.019016529133562
            ],
            [
              105.83625503649436,
              21.01903604928197
            ],
            [
              105.83625932639491,
              21.019072371214083
            ],
            [
              105.83625984775205,
              21.019072711876134
            ],
            [
              105.83625986009106,
              21.019073027040637
            ],
            [
              105.83625987125659,
              21.01907330427549
            ],
            [
              105.8362594045836,
              21.01907362721419
            ],
            [
              105.8362594244016,
              21.019074593566383
            ],
            [
              105.83622536895466,
              21.019076973881795
            ],
            [
              105.83620602329664,
              21.01907857826932
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D200_Ngõ Lệnh Cư",
          "maTaiSan": "04.O16A.DOPP.613",
          "duongKinh": 200,
          "chieudaiQL": 99,
          "chatLieu": "G",
          "from_latitude": 21.018260007922894,
          "to_latitude": 21.01907857826932,
          "to_longitude": 105.83620602329664,
          "from_longitude": 105.83604985049952
        }
      },
      {
        "id": 612,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8372974401284,
            21.013083061987,
            105.83815152875373,
            21.013693254206224
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83730861655486,
              21.013083061987
            ],
            [
              105.8372974401284,
              21.013111692296942
            ],
            [
              105.83736484247578,
              21.013188316210357
            ],
            [
              105.8373674175243,
              21.013191243532553
            ],
            [
              105.83743034269416,
              21.013262786234186
            ],
            [
              105.83746565279948,
              21.01330291792073
            ],
            [
              105.83748876310064,
              21.013331029024805
            ],
            [
              105.83749102745574,
              21.013333784455156
            ],
            [
              105.83749220902027,
              21.01333522108844
            ],
            [
              105.83749458861757,
              21.013338115048054
            ],
            [
              105.83750435690112,
              21.013349998558798
            ],
            [
              105.83751943651546,
              21.013375421450302
            ],
            [
              105.83755911145178,
              21.01342512567832
            ],
            [
              105.83759412962783,
              21.0134725685666
            ],
            [
              105.83759552729215,
              21.01347446206995
            ],
            [
              105.8376111622058,
              21.013494070873442
            ],
            [
              105.83761315225183,
              21.013492661982042
            ],
            [
              105.83765043542009,
              21.01346269099941
            ],
            [
              105.83765135016363,
              21.013462166238746
            ],
            [
              105.8377031241443,
              21.013432445450732
            ],
            [
              105.83774453004914,
              21.01341883321866
            ],
            [
              105.83775668470147,
              21.01341483712029
            ],
            [
              105.83779176602715,
              21.013403303627417
            ],
            [
              105.83783813676021,
              21.013383729129693
            ],
            [
              105.83784003153637,
              21.013385758376376
            ],
            [
              105.837840906346,
              21.013386695228192
            ],
            [
              105.83785572683811,
              21.01340256957406
            ],
            [
              105.83789591027288,
              21.013444783486992
            ],
            [
              105.8379335111749,
              21.01349702234881
            ],
            [
              105.8379361335258,
              21.013500665685665
            ],
            [
              105.83798056279869,
              21.01354819310851
            ],
            [
              105.83798367942829,
              21.013551637107266
            ],
            [
              105.83798625664414,
              21.013554432538037
            ],
            [
              105.83804289350712,
              21.01361586998656
            ],
            [
              105.83806776055997,
              21.013640387456668
            ],
            [
              105.83809560229892,
              21.013659813246825
            ],
            [
              105.83811729107227,
              21.01367346612648
            ],
            [
              105.83813950340387,
              21.013684928815167
            ],
            [
              105.838146987259,
              21.013690109874563
            ],
            [
              105.83815152875373,
              21.013693254206224
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngách 241/61, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DOPP.614",
          "duongKinh": 100,
          "chieudaiQL": 132.02,
          "chatLieu": "G",
          "from_latitude": 21.013083061987,
          "to_latitude": 21.013693254206224,
          "to_longitude": 105.83815152875373,
          "from_longitude": 105.83730861655486
        }
      },
      {
        "id": 613,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8364553515355,
            21.01248360139346,
            105.83730861655486,
            21.013083061987
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8364553515355,
              21.01248360139346
            ],
            [
              105.83645893331526,
              21.01248715332894
            ],
            [
              105.83646456183024,
              21.01249273558681
            ],
            [
              105.83652965563869,
              21.012557249028777
            ],
            [
              105.83657209228822,
              21.012593950657056
            ],
            [
              105.83658149998013,
              21.012602084003436
            ],
            [
              105.83659533459344,
              21.012614044222587
            ],
            [
              105.83661432702412,
              21.012633079327948
            ],
            [
              105.83662255994915,
              21.01264030167525
            ],
            [
              105.83662435315588,
              21.012646028333847
            ],
            [
              105.83662870151066,
              21.01265991480424
            ],
            [
              105.83663467998909,
              21.01267357016256
            ],
            [
              105.83671248928297,
              21.012738899842464
            ],
            [
              105.83678953752795,
              21.012800392716656
            ],
            [
              105.83679131756966,
              21.012801860734402
            ],
            [
              105.83679412679203,
              21.012804178140918
            ],
            [
              105.83687163205427,
              21.012868092079188
            ],
            [
              105.83692118915913,
              21.01291120219513
            ],
            [
              105.83701793903205,
              21.01298017790895
            ],
            [
              105.83702010716435,
              21.012981723498722
            ],
            [
              105.83702513684484,
              21.01298530894361
            ],
            [
              105.83702784365119,
              21.01298254440595
            ],
            [
              105.83708714785281,
              21.012922029258707
            ],
            [
              105.83709026212988,
              21.012918851748328
            ],
            [
              105.83709336077821,
              21.012915286831483
            ],
            [
              105.83715332096756,
              21.012965437813534
            ],
            [
              105.83730861655486,
              21.013083061987
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 28, Kim Hoa",
          "maTaiSan": "04.O16A.DOPP.615",
          "duongKinh": 100,
          "chieudaiQL": 120.55,
          "chatLieu": "G",
          "from_latitude": 21.01248360139346,
          "to_latitude": 21.013083061987,
          "to_longitude": 105.83730861655486,
          "from_longitude": 105.8364553515355
        }
      },
      {
        "id": 614,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83713130853101,
            21.013975397927734,
            105.83756548257836,
            21.014177688057593
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83713130853101,
              21.013975397927734
            ],
            [
              105.83725971670876,
              21.014177688057593
            ],
            [
              105.83727247634246,
              21.014172287210812
            ],
            [
              105.83734194560458,
              21.014139694804012
            ],
            [
              105.83737302022115,
              21.014124455311723
            ],
            [
              105.83737619550884,
              21.014122898783263
            ],
            [
              105.83738075506187,
              21.014120662539653
            ],
            [
              105.83743049119909,
              21.01410321132133
            ],
            [
              105.83745859777403,
              21.01409529440999
            ],
            [
              105.83747627962018,
              21.014112645925778
            ],
            [
              105.83755541265718,
              21.014148510003555
            ],
            [
              105.83755826718384,
              21.014149803819972
            ],
            [
              105.83756548257836,
              21.0141530741005
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngách 241/21, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DOPP.616",
          "duongKinh": 100,
          "chieudaiQL": 60.94,
          "chatLieu": "G",
          "from_latitude": 21.013975397927734,
          "to_latitude": 21.0141530741005,
          "to_longitude": 105.83756548257836,
          "from_longitude": 105.83713130853101
        }
      },
      {
        "id": 615,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83832431719225,
            21.015085206050333,
            105.83863941432524,
            21.015369399752533
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83832431719225,
              21.015085206050333
            ],
            [
              105.83832560558284,
              21.015091467173093
            ],
            [
              105.83832576825041,
              21.015091829467597
            ],
            [
              105.83834057509542,
              21.015124842504232
            ],
            [
              105.83835498215917,
              21.015141228252514
            ],
            [
              105.83839745795596,
              21.015189540132077
            ],
            [
              105.83844247204327,
              21.01523177885798
            ],
            [
              105.83844896354883,
              21.015237869821565
            ],
            [
              105.83851284142861,
              21.01529649939809
            ],
            [
              105.83853297892269,
              21.015314633528124
            ],
            [
              105.83858925672321,
              21.015354181991686
            ],
            [
              105.83862831202563,
              21.01536034275692
            ],
            [
              105.83863941432524,
              21.015369399752533
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 165, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DOPP.617",
          "duongKinh": 100,
          "chieudaiQL": 45.33,
          "chatLieu": "G",
          "from_latitude": 21.015085206050333,
          "to_latitude": 21.015369399752533,
          "to_longitude": 105.83863941432524,
          "from_longitude": 105.83832431719225
        }
      },
      {
        "id": 616,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83903921258437,
            21.01485527916403,
            105.83952865996568,
            21.01548851357791
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83910014729048,
              21.01485527916403
            ],
            [
              105.83904903023493,
              21.014911159921986
            ],
            [
              105.83903921258437,
              21.014939502731032
            ],
            [
              105.83904161551754,
              21.014968742689266
            ],
            [
              105.83904824705938,
              21.014989548262637
            ],
            [
              105.83907229363649,
              21.015023462242198
            ],
            [
              105.83909442164185,
              21.01505436529973
            ],
            [
              105.83910337405769,
              21.015066866925572
            ],
            [
              105.83910712119865,
              21.015072099787933
            ],
            [
              105.83911506921032,
              21.015083199144083
            ],
            [
              105.83911756859747,
              21.015086689518892
            ],
            [
              105.83910916041293,
              21.015092547750744
            ],
            [
              105.83910534966192,
              21.01509520305614
            ],
            [
              105.83910135301856,
              21.015097987536095
            ],
            [
              105.83908440729415,
              21.01510979495824
            ],
            [
              105.83909953870851,
              21.015135799112464
            ],
            [
              105.83913904176369,
              21.01518537913642
            ],
            [
              105.83916898532796,
              21.01522295458683
            ],
            [
              105.83918102927085,
              21.015250389313334
            ],
            [
              105.83918325780412,
              21.015255843728347
            ],
            [
              105.83919256280879,
              21.01526069781978
            ],
            [
              105.83919506440081,
              21.015262002380695
            ],
            [
              105.83921039233303,
              21.01526999798093
            ],
            [
              105.83921689039003,
              21.015269861153683
            ],
            [
              105.83923715773766,
              21.015271818722194
            ],
            [
              105.8392901176237,
              21.01527571500799
            ],
            [
              105.83931749404984,
              21.01527946110509
            ],
            [
              105.83932361021492,
              21.01528035492968
            ],
            [
              105.83932537488128,
              21.01528061267489
            ],
            [
              105.83932543925326,
              21.01528059880896
            ],
            [
              105.8393267574077,
              21.015280309596907
            ],
            [
              105.83932695762081,
              21.015280332996213
            ],
            [
              105.83933941802248,
              21.0152817934555
            ],
            [
              105.83934990287217,
              21.015286222621516
            ],
            [
              105.83936193006983,
              21.015293296042575
            ],
            [
              105.83937810619328,
              21.015307779815267
            ],
            [
              105.83942721465453,
              21.015361205271077
            ],
            [
              105.8394320211175,
              21.015366991994362
            ],
            [
              105.83948934040019,
              21.01543600630177
            ],
            [
              105.83952865996568,
              21.01548851357791
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 143, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DOPP.618",
          "duongKinh": 100,
          "chieudaiQL": 104.69,
          "chatLieu": "G",
          "from_latitude": 21.01485527916403,
          "to_latitude": 21.01548851357791,
          "to_longitude": 105.83952865996568,
          "from_longitude": 105.83910014729048
        }
      },
      {
        "id": 617,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83720561620774,
            21.018144758163064,
            105.83727630574903,
            21.018395737236492
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83720561620774,
              21.018144758163064
            ],
            [
              105.83720967865587,
              21.01815649814448
            ],
            [
              105.83723360169824,
              21.018225641417217
            ],
            [
              105.8372354708618,
              21.018230999173955
            ],
            [
              105.83724994808291,
              21.01827249608915
            ],
            [
              105.8372690077511,
              21.018340128714037
            ],
            [
              105.83727630574903,
              21.01837314988304
            ],
            [
              105.83726934726954,
              21.018392845508625
            ],
            [
              105.83726832642722,
              21.018395737236492
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ Cống Trắng",
          "maTaiSan": "04.O16A.DOPP.619",
          "duongKinh": 100,
          "chieudaiQL": 27.58,
          "chatLieu": "G",
          "from_latitude": 21.018144758163064,
          "to_latitude": 21.018395737236492,
          "to_longitude": 105.83726832642722,
          "from_longitude": 105.83720561620774
        }
      },
      {
        "id": 618,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8362832913508,
            21.01626156573604,
            105.83672787687007,
            21.016944710970904
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8362832913508,
              21.016944710970904
            ],
            [
              105.83628548298891,
              21.01694338961891
            ],
            [
              105.83635055969775,
              21.016904608608392
            ],
            [
              105.83641849357085,
              21.01685896799045
            ],
            [
              105.83648752038498,
              21.01681146855638
            ],
            [
              105.83648841732932,
              21.01681068014754
            ],
            [
              105.83649059795373,
              21.016808764525667
            ],
            [
              105.83649273659204,
              21.01680759968946
            ],
            [
              105.8365157360722,
              21.016795074492425
            ],
            [
              105.83659755601472,
              21.016752803195512
            ],
            [
              105.83665268582622,
              21.016724153679007
            ],
            [
              105.83666683987826,
              21.016715017505582
            ],
            [
              105.83667332958535,
              21.016711474756622
            ],
            [
              105.83672787687007,
              21.016681697244792
            ],
            [
              105.83671598274155,
              21.016662782618926
            ],
            [
              105.83668984088699,
              21.016621207527383
            ],
            [
              105.83665354847375,
              21.016557530779686
            ],
            [
              105.83665238517429,
              21.016554888247576
            ],
            [
              105.83663519489785,
              21.016515847827538
            ],
            [
              105.83660293207716,
              21.016462858069097
            ],
            [
              105.83659371299174,
              21.016440467316965
            ],
            [
              105.83659403942255,
              21.016431386502546
            ],
            [
              105.8365975218902,
              21.016424531067162
            ],
            [
              105.836658431888,
              21.01638777649621
            ],
            [
              105.83667556384181,
              21.016377045938434
            ],
            [
              105.83667125822294,
              21.01636894622528
            ],
            [
              105.83665565341185,
              21.016339593216728
            ],
            [
              105.83662912121002,
              21.0162918320432
            ],
            [
              105.83661774841195,
              21.01626945278636
            ],
            [
              105.83661432431572,
              21.01626418034422
            ],
            [
              105.83661262537447,
              21.01626156573604
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngách 178/80, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DOPP.620",
          "duongKinh": 100,
          "chieudaiQL": 112.97,
          "chatLieu": "G",
          "from_latitude": 21.016944710970904,
          "to_latitude": 21.01626156573604,
          "to_longitude": 105.83661262537447,
          "from_longitude": 105.8362832913508
        }
      },
      {
        "id": 619,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8357659058673,
            21.015656991642384,
            105.83602421223732,
            21.016070348203485
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83577448185481,
              21.015656991642384
            ],
            [
              105.8357659058673,
              21.01566176757156
            ],
            [
              105.8357723653052,
              21.01567748355603
            ],
            [
              105.83577361106838,
              21.015680428270116
            ],
            [
              105.8357785872976,
              21.015692190901834
            ],
            [
              105.83578055537365,
              21.015696843677564
            ],
            [
              105.8358033322543,
              21.015739508487066
            ],
            [
              105.83584259082164,
              21.015785769345047
            ],
            [
              105.83585745416278,
              21.015803283904827
            ],
            [
              105.83589179509542,
              21.015832930615588
            ],
            [
              105.83589409461148,
              21.015834916346304
            ],
            [
              105.83589642892244,
              21.01583693171243
            ],
            [
              105.83593297953317,
              21.015880748179246
            ],
            [
              105.83594272165182,
              21.01589241512974
            ],
            [
              105.83594338200919,
              21.015893362076977
            ],
            [
              105.83595545132475,
              21.015913361577894
            ],
            [
              105.83596172731912,
              21.015933610834843
            ],
            [
              105.83597032154205,
              21.01595247128306
            ],
            [
              105.83597668851694,
              21.015965398555966
            ],
            [
              105.83597333661963,
              21.015971866757898
            ],
            [
              105.83596762353315,
              21.01598288887723
            ],
            [
              105.83596705297371,
              21.015994601983987
            ],
            [
              105.83598541958501,
              21.016024861876097
            ],
            [
              105.83600646539078,
              21.016051530928817
            ],
            [
              105.83601253550911,
              21.016057967282585
            ],
            [
              105.83602373615201,
              21.016069843834206
            ],
            [
              105.83602421223732,
              21.016070348203485
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 218, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DOPP.621",
          "duongKinh": 100,
          "chieudaiQL": 55.52,
          "chatLieu": "G",
          "from_latitude": 21.015656991642384,
          "to_latitude": 21.016070348203485,
          "to_longitude": 105.83602421223732,
          "from_longitude": 105.83577448185481
        }
      },
      {
        "id": 620,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83575199621,
            21.01438378449396,
            105.83619023108027,
            21.01484603274134
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83575199621,
              21.014406792894864
            ],
            [
              105.83576443546775,
              21.01439653438517
            ],
            [
              105.83576637733346,
              21.014394932461318
            ],
            [
              105.83576709260845,
              21.014394342754947
            ],
            [
              105.83578557495218,
              21.01438378449396
            ],
            [
              105.83578971685178,
              21.014388761688302
            ],
            [
              105.83579239741293,
              21.01439198206386
            ],
            [
              105.8358095359153,
              21.014412149067073
            ],
            [
              105.8358111680878,
              21.014414526464805
            ],
            [
              105.83586305077462,
              21.014483941799366
            ],
            [
              105.83591106129487,
              21.014562943094468
            ],
            [
              105.83591462171361,
              21.014590273431637
            ],
            [
              105.83593065415579,
              21.014617366361193
            ],
            [
              105.83593696195281,
              21.01462676322565
            ],
            [
              105.83593838589098,
              21.014628885129362
            ],
            [
              105.835939995093,
              21.014631282509455
            ],
            [
              105.83596059656672,
              21.01466197401312
            ],
            [
              105.83597558030955,
              21.014668834443725
            ],
            [
              105.83597731261612,
              21.014671227605636
            ],
            [
              105.83598706763428,
              21.014684705458972
            ],
            [
              105.8360140924294,
              21.01472515092671
            ],
            [
              105.83605405821925,
              21.014767517181014
            ],
            [
              105.83609196336563,
              21.014791897708626
            ],
            [
              105.83609900839704,
              21.014796185015815
            ],
            [
              105.83612873768016,
              21.014814276820243
            ],
            [
              105.83618017823247,
              21.0148419444788
            ],
            [
              105.8361886306526,
              21.0148453830599
            ],
            [
              105.83619023108027,
              21.01484603274134
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngách 260/7, Ngõ Chợ Khâm Thiên",
          "maTaiSan": "04.O16A.DOPP.622",
          "duongKinh": 100,
          "chieudaiQL": 70.87,
          "chatLieu": "G",
          "from_latitude": 21.014406792894864,
          "to_latitude": 21.01484603274134,
          "to_longitude": 105.83619023108027,
          "from_longitude": 105.83575199621
        }
      },
      {
        "id": 621,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83108647468012,
            21.01798161779379,
            105.83161338309684,
            21.01852530501638
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83108647468012,
              21.01798161779379
            ],
            [
              105.83108816486612,
              21.017983186567147
            ],
            [
              105.83109137892603,
              21.017986169571635
            ],
            [
              105.831179252564,
              21.01806762028939
            ],
            [
              105.83127931698517,
              21.018173514974546
            ],
            [
              105.83136317239624,
              21.018264619021576
            ],
            [
              105.83136341917985,
              21.01826488697651
            ],
            [
              105.83136451375513,
              21.018267293237518
            ],
            [
              105.83136470754204,
              21.018267718612588
            ],
            [
              105.83146068175225,
              21.01836680208127
            ],
            [
              105.83152977057198,
              21.018435207915534
            ],
            [
              105.83161338309684,
              21.01852530501638
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 316, Đê La Thành",
          "maTaiSan": "04.O16A.DOPP.623",
          "duongKinh": 100,
          "chieudaiQL": 79.66,
          "chatLieu": "G",
          "from_latitude": 21.01798161779379,
          "to_latitude": 21.01852530501638,
          "to_longitude": 105.83161338309684,
          "from_longitude": 105.83108647468012
        }
      },
      {
        "id": 622,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83246183452938,
            21.0188664957993,
            105.83260726231369,
            21.019314557498003
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83260726231369,
              21.019314557498003
            ],
            [
              105.83260717376506,
              21.01931039045339
            ],
            [
              105.8326067799555,
              21.019291611633093
            ],
            [
              105.83258157058265,
              21.01919384444922
            ],
            [
              105.83253741869285,
              21.01907690599584
            ],
            [
              105.83252474028549,
              21.019043327446845
            ],
            [
              105.83246692289123,
              21.018898311372002
            ],
            [
              105.83246183452938,
              21.0188664957993
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 231, Khâm Thiên",
          "maTaiSan": "04.O16A.DOPP.624",
          "duongKinh": 100,
          "chieudaiQL": 51.62,
          "chatLieu": "G",
          "from_latitude": 21.019314557498003,
          "to_latitude": 21.0188664957993,
          "to_longitude": 105.83246183452938,
          "from_longitude": 105.83260726231369
        }
      },
      {
        "id": 623,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83585183085243,
            21.017348754010076,
            105.83604985049952,
            21.018260007922894
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8359478728142,
              21.017348754010076
            ],
            [
              105.83593707245262,
              21.017349858435065
            ],
            [
              105.83592907115582,
              21.01735075400882
            ],
            [
              105.83587633162067,
              21.01735665740195
            ],
            [
              105.83585183085243,
              21.01736270474246
            ],
            [
              105.8358534454109,
              21.01737417046631
            ],
            [
              105.83587388794284,
              21.017432370433752
            ],
            [
              105.8358770412944,
              21.017444640594828
            ],
            [
              105.8358842782589,
              21.017448478963974
            ],
            [
              105.83588716260115,
              21.01747565274216
            ],
            [
              105.83588775755746,
              21.017481260647294
            ],
            [
              105.83589703394809,
              21.0175686525844
            ],
            [
              105.83589770096206,
              21.017574934843804
            ],
            [
              105.8358985756341,
              21.01757981427893
            ],
            [
              105.83590688150677,
              21.017626142284353
            ],
            [
              105.83590959538739,
              21.01765673108625
            ],
            [
              105.83591878222683,
              21.01771027423209
            ],
            [
              105.83592108491324,
              21.017723696569604
            ],
            [
              105.83592410742125,
              21.017741311749266
            ],
            [
              105.83592770507926,
              21.01775701107388
            ],
            [
              105.83593719955925,
              21.017798437760863
            ],
            [
              105.83593943762703,
              21.017808205067418
            ],
            [
              105.83594277303322,
              21.01782276039201
            ],
            [
              105.83594621034116,
              21.01784075559641
            ],
            [
              105.83595669957987,
              21.017895659819658
            ],
            [
              105.83596577420947,
              21.017935105990183
            ],
            [
              105.83596631642806,
              21.017937464353448
            ],
            [
              105.83597545768431,
              21.017978680514947
            ],
            [
              105.83598056511948,
              21.01800171111407
            ],
            [
              105.83598057894389,
              21.018001775175048
            ],
            [
              105.83598216896048,
              21.018009870184652
            ],
            [
              105.83598452427975,
              21.018021860651704
            ],
            [
              105.83599068041354,
              21.01805285975897
            ],
            [
              105.83599881602443,
              21.018093827957628
            ],
            [
              105.83600024717592,
              21.018101034071915
            ],
            [
              105.8360179677901,
              21.018152374757346
            ],
            [
              105.83604985049952,
              21.018260007922894
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Ngõ Lệnh Cư",
          "maTaiSan": "04.O16A.DOPP.625",
          "duongKinh": 150,
          "chieudaiQL": 113.23,
          "chatLieu": "G",
          "from_latitude": 21.017348754010076,
          "to_latitude": 21.018260007922894,
          "to_longitude": 105.83604985049952,
          "from_longitude": 105.8359478728142
        }
      },
      {
        "id": 624,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83909234563575,
            21.018048893740822,
            105.8393429672309,
            21.018253736590722
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83909234563575,
              21.018253736590722
            ],
            [
              105.8390993506126,
              21.018250476626562
            ],
            [
              105.83911685540703,
              21.018234856642437
            ],
            [
              105.83915327489726,
              21.018202357846242
            ],
            [
              105.83919024431673,
              21.01817118226183
            ],
            [
              105.83921089234777,
              21.018153771024856
            ],
            [
              105.83921162138613,
              21.018153233623195
            ],
            [
              105.83929714379947,
              21.018090162305878
            ],
            [
              105.8393429672309,
              21.018048893740822
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ Miếu Chợ",
          "maTaiSan": "04.O16A.DOPP.626",
          "duongKinh": 100,
          "chieudaiQL": 34.72,
          "chatLieu": "G",
          "from_latitude": 21.018253736590722,
          "to_latitude": 21.018048893740822,
          "to_longitude": 105.8393429672309,
          "from_longitude": 105.83909234563575
        }
      },
      {
        "id": 625,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83871268665469,
            21.016960195788855,
            105.83939480086391,
            21.01752434095388
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83871268665469,
              21.01752434095388
            ],
            [
              105.83871501430839,
              21.0175230902551
            ],
            [
              105.83879777791064,
              21.017478628359054
            ],
            [
              105.83885976970781,
              21.017445441729283
            ],
            [
              105.83892476211909,
              21.01740440300859
            ],
            [
              105.83897182696145,
              21.01737441871696
            ],
            [
              105.83898182444031,
              21.017368049555976
            ],
            [
              105.83898603077479,
              21.01736507978599
            ],
            [
              105.83900334477555,
              21.01735285597322
            ],
            [
              105.83900757506625,
              21.017349869826553
            ],
            [
              105.83901204665342,
              21.01734720042442
            ],
            [
              105.83904464207173,
              21.01732774319224
            ],
            [
              105.83907854068148,
              21.017297411036207
            ],
            [
              105.83911270164518,
              21.0172611894648
            ],
            [
              105.83913641795685,
              21.017234971125138
            ],
            [
              105.83914956532412,
              21.017220437533688
            ],
            [
              105.83919656821229,
              21.01716667702903
            ],
            [
              105.83919852096581,
              21.01716444275627
            ],
            [
              105.83920070790366,
              21.017161941780508
            ],
            [
              105.83921519921607,
              21.017145366591667
            ],
            [
              105.83925687298554,
              21.017097125766334
            ],
            [
              105.83930141070763,
              21.017055980978085
            ],
            [
              105.83930509656643,
              21.017052143060774
            ],
            [
              105.8393519813956,
              21.017003948761637
            ],
            [
              105.83939480086391,
              21.016960195788855
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ Chùa Mỹ Giang",
          "maTaiSan": "04.O16A.DOPP.627",
          "duongKinh": 100,
          "chieudaiQL": 94.13,
          "chatLieu": "G",
          "from_latitude": 21.01752434095388,
          "to_latitude": 21.016960195788855,
          "to_longitude": 105.83939480086391,
          "from_longitude": 105.83871268665469
        }
      },
      {
        "id": 626,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81078450973644,
            21.014805943793974,
            105.8136383220223,
            21.016505433053663
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8136383220223,
              21.014805943793974
            ],
            [
              105.81363805942902,
              21.014806123888974
            ],
            [
              105.81363369418833,
              21.014809142579328
            ],
            [
              105.81343093371065,
              21.014949289655345
            ],
            [
              105.8133590731446,
              21.014998944960745
            ],
            [
              105.81318458752281,
              21.01512723809383
            ],
            [
              105.81315553833676,
              21.0151485973829
            ],
            [
              105.81298685327906,
              21.01525904370881
            ],
            [
              105.81291397187618,
              21.015306760866693
            ],
            [
              105.81271284200012,
              21.015435816713403
            ],
            [
              105.812703469019,
              21.015440816598325
            ],
            [
              105.81269955466232,
              21.015442905496208
            ],
            [
              105.8126572889634,
              21.015465453259225
            ],
            [
              105.81261402873757,
              21.0154866048552
            ],
            [
              105.81258872975044,
              21.015527094578573
            ],
            [
              105.81235676153412,
              21.015664523911212
            ],
            [
              105.81223406208096,
              21.015703063405628
            ],
            [
              105.81219454301826,
              21.01571307919871
            ],
            [
              105.81211588144367,
              21.015733015927722
            ],
            [
              105.8118169421321,
              21.015905297351257
            ],
            [
              105.81177332614055,
              21.015930433674107
            ],
            [
              105.81176646388096,
              21.01593438823543
            ],
            [
              105.81156632370468,
              21.0160616270724
            ],
            [
              105.81150924279149,
              21.016092714804643
            ],
            [
              105.81145931014832,
              21.016122396418954
            ],
            [
              105.81145916727682,
              21.016122481100666
            ],
            [
              105.811451548336,
              21.016126507000983
            ],
            [
              105.81138578542253,
              21.016163743734154
            ],
            [
              105.8113742197988,
              21.016170292203363
            ],
            [
              105.81136577555732,
              21.016174861263426
            ],
            [
              105.81135537083397,
              21.016180490127624
            ],
            [
              105.8113522929604,
              21.01618215500483
            ],
            [
              105.8112639453043,
              21.016231634274792
            ],
            [
              105.8112068256717,
              21.01626465771172
            ],
            [
              105.81118853818793,
              21.016275872685657
            ],
            [
              105.81115466980856,
              21.016296643137334
            ],
            [
              105.81087449222221,
              21.016452602481298
            ],
            [
              105.81081575559203,
              21.016487087695893
            ],
            [
              105.81081043943588,
              21.01649020930016
            ],
            [
              105.8107956445206,
              21.016498895708985
            ],
            [
              105.81078450973644,
              21.016505433053663
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D110_Hoàng Ngọc Phách",
          "maTaiSan": "04.O9B.DOPP.628",
          "duongKinh": 110,
          "chieudaiQL": 354.89,
          "chatLieu": "PVC",
          "from_latitude": 21.014805943793974,
          "to_latitude": 21.016505433053663,
          "to_longitude": 105.81078450973644,
          "from_longitude": 105.8136383220223
        }
      },
      {
        "id": 627,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81184839812751,
            21.014858175762132,
            105.813671543933,
            21.015941285414595
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.813671543933,
              21.014858175762132
            ],
            [
              105.81366701790716,
              21.01486099109274
            ],
            [
              105.8134579585667,
              21.014991015137035
            ],
            [
              105.81336808397946,
              21.015046912076258
            ],
            [
              105.81319145575041,
              21.015160417871865
            ],
            [
              105.81318189373869,
              21.015166563076107
            ],
            [
              105.81312395893808,
              21.015203792852088
            ],
            [
              105.81293396639289,
              21.015328352743
            ],
            [
              105.81283240158787,
              21.01539111109202
            ],
            [
              105.81282548531865,
              21.015395384792505
            ],
            [
              105.81274540077804,
              21.01545197528681
            ],
            [
              105.81266222488463,
              21.015506505795813
            ],
            [
              105.81264181862986,
              21.01551988433844
            ],
            [
              105.81263848388626,
              21.01552207072475
            ],
            [
              105.81261000387975,
              21.015540741907444
            ],
            [
              105.81259669677999,
              21.01554897968334
            ],
            [
              105.81258853228117,
              21.015554034306337
            ],
            [
              105.81257639577788,
              21.015561546616624
            ],
            [
              105.81239046145579,
              21.015676646898072
            ],
            [
              105.8123706225262,
              21.015688927987412
            ],
            [
              105.81215380633131,
              21.015753333655407
            ],
            [
              105.81199124924949,
              21.015853635420395
            ],
            [
              105.81185934768119,
              21.01593502075798
            ],
            [
              105.81184839812751,
              21.015941285414595
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D110_Hoàng Ngọc Phách",
          "maTaiSan": "04.O9B.DOPP.629",
          "duongKinh": 110,
          "chieudaiQL": 222.2,
          "chatLieu": "PVC",
          "from_latitude": 21.014858175762132,
          "to_latitude": 21.015941285414595,
          "to_longitude": 105.81184839812751,
          "from_longitude": 105.813671543933
        }
      },
      {
        "id": 628,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81233100377136,
            21.01490218846371,
            105.812703469019,
            21.015440816598325
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.812703469019,
              21.015440816598325
            ],
            [
              105.81270255050573,
              21.015439421885354
            ],
            [
              105.81268549910459,
              21.01541348855602
            ],
            [
              105.81268453014141,
              21.015412014599832
            ],
            [
              105.81268326050379,
              21.015410084143156
            ],
            [
              105.81261787424005,
              21.015325674554422
            ],
            [
              105.81260701765525,
              21.01530842416757
            ],
            [
              105.81250514112273,
              21.015146550016922
            ],
            [
              105.81249896775572,
              21.01513809005557
            ],
            [
              105.81240001076033,
              21.015002469578175
            ],
            [
              105.81238902944295,
              21.014986512290882
            ],
            [
              105.81233100377136,
              21.01490218846371
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D90_Hoàng Ngọc Phách",
          "maTaiSan": "04.O9B.DOPP.630",
          "duongKinh": 90,
          "chieudaiQL": 69.59,
          "chatLieu": "PVC",
          "from_latitude": 21.015440816598325,
          "to_latitude": 21.01490218846371,
          "to_longitude": 105.81233100377136,
          "from_longitude": 105.812703469019
        }
      },
      {
        "id": 629,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8126062636833,
            21.014707222707173,
            105.81298685327906,
            21.01525904370881
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81298685327906,
              21.01525904370881
            ],
            [
              105.81297674551577,
              21.01524223505972
            ],
            [
              105.81297337134944,
              21.015236613232215
            ],
            [
              105.81285131044638,
              21.01505526640131
            ],
            [
              105.81281200195833,
              21.01499688074816
            ],
            [
              105.81281006529571,
              21.014993987927888
            ],
            [
              105.81273151826602,
              21.014883006852475
            ],
            [
              105.81271596740274,
              21.014860952946517
            ],
            [
              105.81271412463852,
              21.014858345999865
            ],
            [
              105.81271249800024,
              21.014856036989368
            ],
            [
              105.81262836014648,
              21.01473667260749
            ],
            [
              105.81262766986463,
              21.01473567874036
            ],
            [
              105.8126233359945,
              21.01472993142391
            ],
            [
              105.8126062636833,
              21.014707222707173
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D110_Ngõ 9, Hoàng Ngọc Phách",
          "maTaiSan": "04.O9B.DOPP.631",
          "duongKinh": 110,
          "chieudaiQL": 70.89,
          "chatLieu": "PVC",
          "from_latitude": 21.01525904370881,
          "to_latitude": 21.014707222707173,
          "to_longitude": 105.8126062636833,
          "from_longitude": 105.81298685327906
        }
      },
      {
        "id": 630,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8090430806232,
            21.014873837417696,
            105.80941019478827,
            21.015538375224477
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8090430806232,
              21.014873837417696
            ],
            [
              105.80914126767601,
              21.015008348110662
            ],
            [
              105.80920651920295,
              21.01508795636643
            ],
            [
              105.80921379702409,
              21.015096834813708
            ],
            [
              105.80921556807195,
              21.015098996849066
            ],
            [
              105.80923703653093,
              21.015129652390186
            ],
            [
              105.80934413666746,
              21.01540632039598
            ],
            [
              105.80934684347957,
              21.015412187539102
            ],
            [
              105.80940259170889,
              21.015532867378024
            ],
            [
              105.80941019478827,
              21.015538375224477
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D110_Vũ Ngọc Phan",
          "maTaiSan": "04.O9B.DOPP.632",
          "duongKinh": 110,
          "chieudaiQL": 83.09,
          "chatLieu": "PVC",
          "from_latitude": 21.014873837417696,
          "to_latitude": 21.015538375224477,
          "to_longitude": 105.80941019478827,
          "from_longitude": 105.8090430806232
        }
      },
      {
        "id": 631,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81018561632952,
            21.01457804592324,
            105.81061190977707,
            21.01612951550245
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81018561632952,
              21.01612951550245
            ],
            [
              105.81019663557764,
              21.016105328755778
            ],
            [
              105.81019690941967,
              21.01610473674011
            ],
            [
              105.81020210106146,
              21.01609334488306
            ],
            [
              105.81022367818623,
              21.0160265206227
            ],
            [
              105.81034701224092,
              21.015747760454477
            ],
            [
              105.81037554908335,
              21.01568826975821
            ],
            [
              105.81037658592449,
              21.01568610881257
            ],
            [
              105.81043421885312,
              21.015565959577007
            ],
            [
              105.8104539752693,
              21.015526451274088
            ],
            [
              105.81045556930451,
              21.0155232652188
            ],
            [
              105.8105120235027,
              21.01541037178986
            ],
            [
              105.81054338788977,
              21.01534332473346
            ],
            [
              105.81061190977707,
              21.01519684502746
            ],
            [
              105.81058439750615,
              21.01516500452423
            ],
            [
              105.81056148651544,
              21.015131443121145
            ],
            [
              105.81045381694528,
              21.01497372573843
            ],
            [
              105.81043026273812,
              21.014935939365603
            ],
            [
              105.81035706077408,
              21.01481850249979
            ],
            [
              105.81030770389889,
              21.014745680824507
            ],
            [
              105.8102927191027,
              21.014722930310644
            ],
            [
              105.81026383678302,
              21.01467908242838
            ],
            [
              105.81020516437408,
              21.014590007223386
            ],
            [
              105.81019716477242,
              21.01457804592324
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D110_Ngõ 73, Nguyên Hồng",
          "maTaiSan": "04.O9B.DOPP.633",
          "duongKinh": 110,
          "chieudaiQL": 193.97,
          "chatLieu": "PVC",
          "from_latitude": 21.01612951550245,
          "to_latitude": 21.01457804592324,
          "to_longitude": 105.81019716477242,
          "from_longitude": 105.81018561632952
        }
      },
      {
        "id": 632,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80923703653093,
            21.013180816639824,
            105.81259048533371,
            21.015129652390186
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81259048533371,
              21.013180816639824
            ],
            [
              105.81258192354919,
              21.01318584606084
            ],
            [
              105.81254882515113,
              21.013205291672087
            ],
            [
              105.8115728680431,
              21.013778641428814
            ],
            [
              105.81146059641766,
              21.01384459777968
            ],
            [
              105.81128724762002,
              21.013932197632464
            ],
            [
              105.81127978420619,
              21.013935969877508
            ],
            [
              105.81124878231381,
              21.013955207439512
            ],
            [
              105.8112215692472,
              21.01397209389139
            ],
            [
              105.81114536621348,
              21.014015521346067
            ],
            [
              105.8110978758271,
              21.014042585385774
            ],
            [
              105.81086022887536,
              21.014176398831918
            ],
            [
              105.8108582600224,
              21.014177507444604
            ],
            [
              105.81081279993735,
              21.01420310482294
            ],
            [
              105.81057358002987,
              21.014343564107858
            ],
            [
              105.81050517085738,
              21.014383731432087
            ],
            [
              105.8104588794315,
              21.014410911522912
            ],
            [
              105.81036769377026,
              21.0144669694058
            ],
            [
              105.81019716477242,
              21.01457804592324
            ],
            [
              105.80938391994594,
              21.015083000098443
            ],
            [
              105.80924472932651,
              21.015124871137683
            ],
            [
              105.80923703653093,
              21.015129652390186
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D90_Vũ Ngọc Phan",
          "maTaiSan": "04.O9B.DOPP.634",
          "duongKinh": 90,
          "chieudaiQL": 405.37,
          "chatLieu": "PVC",
          "from_latitude": 21.013180816639824,
          "to_latitude": 21.015129652390186,
          "to_longitude": 105.80923703653093,
          "from_longitude": 105.81259048533371
        }
      },
      {
        "id": 633,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81239046145579,
            21.015676646898072,
            105.81286611563023,
            21.01615879506293
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81286611563023,
              21.016073077021808
            ],
            [
              105.8128536784118,
              21.016080308078575
            ],
            [
              105.81271867840275,
              21.01615879506293
            ],
            [
              105.81266897205317,
              21.016090829746197
            ],
            [
              105.81265028795484,
              21.016062017498804
            ],
            [
              105.81255681225417,
              21.01591786616752
            ],
            [
              105.81254039518689,
              21.015894501287125
            ],
            [
              105.81243450594764,
              21.015743797535773
            ],
            [
              105.81240059107711,
              21.015692169280648
            ],
            [
              105.8123906806588,
              21.015676980045832
            ],
            [
              105.81239046145579,
              21.015676646898072
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D90_Hoàng Ngọc Phách",
          "maTaiSan": "04.O9B.DOPP.635",
          "duongKinh": 90,
          "chieudaiQL": 82.41,
          "chatLieu": "PVC",
          "from_latitude": 21.016073077021808,
          "to_latitude": 21.015676646898072,
          "to_longitude": 105.81239046145579,
          "from_longitude": 105.81286611563023
        }
      },
      {
        "id": 634,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81380677652048,
            21.015902363002667,
            105.8143229301351,
            21.01620030309826
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8143229301351,
              21.015902363002667
            ],
            [
              105.81430503837304,
              21.015912174600473
            ],
            [
              105.81422970687544,
              21.01595348389835
            ],
            [
              105.81383481974888,
              21.01618393252203
            ],
            [
              105.81380684553366,
              21.01620025760673
            ],
            [
              105.81380677652048,
              21.01620030309826
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D90_Láng Hạ",
          "maTaiSan": "04.O9B.DOPP.636",
          "duongKinh": 90,
          "chieudaiQL": 59.17,
          "chatLieu": "PVC",
          "from_latitude": 21.015902363002667,
          "to_latitude": 21.01620030309826,
          "to_longitude": 105.81380677652048,
          "from_longitude": 105.8143229301351
        }
      },
      {
        "id": 635,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81126176198,
            21.016619002259052,
            105.81225196894425,
            21.017178361747508
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81225196894425,
              21.016619002259052
            ],
            [
              105.81209977469756,
              21.016694013046628
            ],
            [
              105.81206365961177,
              21.016715028338467
            ],
            [
              105.81202300879121,
              21.016738682828397
            ],
            [
              105.81195931685764,
              21.01677574551147
            ],
            [
              105.81189579418071,
              21.016821868549982
            ],
            [
              105.81188846940113,
              21.016826040292568
            ],
            [
              105.81180188392634,
              21.016875356078778
            ],
            [
              105.8117565235617,
              21.016900655178418
            ],
            [
              105.81175255691755,
              21.016902868047357
            ],
            [
              105.81173183430877,
              21.01691525354132
            ],
            [
              105.81173099806745,
              21.016915729919862
            ],
            [
              105.81153847914906,
              21.017025382655103
            ],
            [
              105.81151742346732,
              21.017037375001824
            ],
            [
              105.81151339856852,
              21.017039667627344
            ],
            [
              105.81150298292913,
              21.017045600038315
            ],
            [
              105.81149051695802,
              21.01705270016771
            ],
            [
              105.81148770710352,
              21.017054300543197
            ],
            [
              105.81147748326075,
              21.01706012365036
            ],
            [
              105.81133536687246,
              21.01714106778256
            ],
            [
              105.81127046590791,
              21.01717395222773
            ],
            [
              105.81126176198,
              21.017178361747508
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D90_Ngõ 47, Nguyên Hồng",
          "maTaiSan": "04.O9B.DOPP.637",
          "duongKinh": 90,
          "chieudaiQL": 117,
          "chatLieu": "PVC",
          "from_latitude": 21.016619002259052,
          "to_latitude": 21.017178361747508,
          "to_longitude": 105.81126176198,
          "from_longitude": 105.81225196894425
        }
      },
      {
        "id": 636,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81184839812751,
            21.015941285414595,
            105.81308295285763,
            21.017437607927974
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81184839812751,
              21.015941285414595
            ],
            [
              105.81186010658317,
              21.015960777210353
            ],
            [
              105.8118660203398,
              21.015970679985546
            ],
            [
              105.81187099364674,
              21.01601121120705
            ],
            [
              105.81193103595992,
              21.016102681829626
            ],
            [
              105.81194916516097,
              21.01613030093355
            ],
            [
              105.81195431331415,
              21.016137910449356
            ],
            [
              105.81196840794541,
              21.016158742980835
            ],
            [
              105.81201693705208,
              21.016241186556734
            ],
            [
              105.8120533122719,
              21.01629773296164
            ],
            [
              105.81205628468575,
              21.016302353238
            ],
            [
              105.8120869950981,
              21.01634576302475
            ],
            [
              105.81208963273889,
              21.016349491605688
            ],
            [
              105.81211906420292,
              21.0163988417009
            ],
            [
              105.81212322876344,
              21.016405825452857
            ],
            [
              105.81220833706443,
              21.01654832250938
            ],
            [
              105.81222029118835,
              21.016567687554765
            ],
            [
              105.81225196894425,
              21.016619002259052
            ],
            [
              105.81229971271982,
              21.016696344444984
            ],
            [
              105.8123533491873,
              21.01678323088999
            ],
            [
              105.81236024222157,
              21.016794367294963
            ],
            [
              105.81252973588946,
              21.0168318962732
            ],
            [
              105.81253330226134,
              21.016832685822326
            ],
            [
              105.81254332537286,
              21.016834034340476
            ],
            [
              105.81254667443423,
              21.01683448531345
            ],
            [
              105.81274638637147,
              21.016882046410995
            ],
            [
              105.81277715649281,
              21.016889374521394
            ],
            [
              105.8128304019544,
              21.01697859842045
            ],
            [
              105.81283258131819,
              21.016982249694337
            ],
            [
              105.81286047820429,
              21.017028996650385
            ],
            [
              105.81286400693634,
              21.0170349094821
            ],
            [
              105.81286916461049,
              21.01704355131151
            ],
            [
              105.81293943930274,
              21.01716537801323
            ],
            [
              105.81294415581687,
              21.017173554979212
            ],
            [
              105.81295746499947,
              21.0171931871701
            ],
            [
              105.81296579184725,
              21.017205470118647
            ],
            [
              105.81306721278119,
              21.01740644140278
            ],
            [
              105.81307386777071,
              21.01741961027336
            ],
            [
              105.81308295285763,
              21.017437607927974
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D110_Ngõ 9, Huỳnh Thúc Kháng",
          "maTaiSan": "04.O9B.DOPP.638",
          "duongKinh": 110,
          "chieudaiQL": 217.37,
          "chatLieu": "PVC",
          "from_latitude": 21.015941285414595,
          "to_latitude": 21.017437607927974,
          "to_longitude": 105.81308295285763,
          "from_longitude": 105.81184839812751
        }
      },
      {
        "id": 637,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81087449222221,
            21.016452602481298,
            105.81178081721959,
            21.018066114669942
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81178081721959,
              21.018066114669942
            ],
            [
              105.81178057605,
              21.01806563349697
            ],
            [
              105.81177884017059,
              21.018062162545554
            ],
            [
              105.81177567934917,
              21.018055795418523
            ],
            [
              105.81159921669969,
              21.017790674620947
            ],
            [
              105.81159314022938,
              21.01777968964566
            ],
            [
              105.81156354282308,
              21.01772618270193
            ],
            [
              105.81149997070206,
              21.017611256623233
            ],
            [
              105.81128114114475,
              21.017215653050137
            ],
            [
              105.81126176198,
              21.017178361747508
            ],
            [
              105.81121318873574,
              21.017084896076344
            ],
            [
              105.81106124135773,
              21.016792513006223
            ],
            [
              105.81103815341602,
              21.016750489276493
            ],
            [
              105.81087449222221,
              21.016452602481298
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D110_Nguyên Hồng",
          "maTaiSan": "04.O9B.DOPP.639",
          "duongKinh": 110,
          "chieudaiQL": 200.28,
          "chatLieu": "PVC",
          "from_latitude": 21.018066114669942,
          "to_latitude": 21.016452602481298,
          "to_longitude": 105.81087449222221,
          "from_longitude": 105.81178081721959
        }
      },
      {
        "id": 638,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80823438184461,
            21.016533881538642,
            105.81474954287235,
            21.019591290849746
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81474954287235,
              21.016533881538642
            ],
            [
              105.81474286177955,
              21.016540895486713
            ],
            [
              105.81466896130723,
              21.016618479888134
            ],
            [
              105.8137012478673,
              21.017125960841472
            ],
            [
              105.81366756769619,
              21.017143622879527
            ],
            [
              105.81364169557892,
              21.017157190267053
            ],
            [
              105.81351834956587,
              21.017219094887217
            ],
            [
              105.81337957917685,
              21.01728873976819
            ],
            [
              105.81330698301963,
              21.017325173522362
            ],
            [
              105.81308295285763,
              21.017437607927974
            ],
            [
              105.81306094171468,
              21.017448654834173
            ],
            [
              105.8127930318017,
              21.017582409730956
            ],
            [
              105.81264699354381,
              21.017655319601655
            ],
            [
              105.81262249920594,
              21.017667548898476
            ],
            [
              105.81243651879792,
              21.01776039937277
            ],
            [
              105.81219048344207,
              21.01787920883771
            ],
            [
              105.81209750637198,
              21.017924106899713
            ],
            [
              105.81193967827399,
              21.018000321373055
            ],
            [
              105.81183997007139,
              21.018042699805275
            ],
            [
              105.81179174420065,
              21.0180616936113
            ],
            [
              105.81178081721959,
              21.018066114669942
            ],
            [
              105.81176878917373,
              21.018066872972437
            ],
            [
              105.81171479511578,
              21.018101456000995
            ],
            [
              105.81171040170227,
              21.01810427064946
            ],
            [
              105.81156883668535,
              21.01817277942367
            ],
            [
              105.81137537278188,
              21.01826365839975
            ],
            [
              105.81126417885706,
              21.018315891311538
            ],
            [
              105.81119871410898,
              21.018347078552527
            ],
            [
              105.81114331434158,
              21.01837347040855
            ],
            [
              105.81107263856529,
              21.01840713923084
            ],
            [
              105.81084188798457,
              21.01851838344575
            ],
            [
              105.8107752449297,
              21.01855051098567
            ],
            [
              105.81066196615691,
              21.018596009044554
            ],
            [
              105.81065505241334,
              21.018599364969
            ],
            [
              105.8105207668462,
              21.018664547742052
            ],
            [
              105.810500341252,
              21.01867446225154
            ],
            [
              105.80999835089051,
              21.01891812907904
            ],
            [
              105.80999207706535,
              21.018921173929257
            ],
            [
              105.80990856966811,
              21.01896170830199
            ],
            [
              105.80987838641295,
              21.01897635966134
            ],
            [
              105.80969250676402,
              21.019066584661093
            ],
            [
              105.80966692209975,
              21.019079002991756
            ],
            [
              105.80936681577941,
              21.019224673136126
            ],
            [
              105.80934188348739,
              21.019236774894857
            ],
            [
              105.8088898374845,
              21.0194561939889
            ],
            [
              105.80861151022138,
              21.019591290849746
            ],
            [
              105.80833658780053,
              21.01950526614373
            ],
            [
              105.80829040482324,
              21.019532048650223
            ],
            [
              105.80828579889514,
              21.019534719705916
            ],
            [
              105.80823438184461,
              21.019564537529433
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D160_Huỳnh Thúc Kháng",
          "maTaiSan": "04.O9B.DOPP.640",
          "duongKinh": 160,
          "chieudaiQL": 755.92,
          "chatLieu": "PVC",
          "from_latitude": 21.016533881538642,
          "to_latitude": 21.019564537529433,
          "to_longitude": 105.80823438184461,
          "from_longitude": 105.81474954287235
        }
      },
      {
        "id": 639,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80769928528639,
            21.01739863779459,
            105.81000972201545,
            21.018560615858807
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80769928528639,
              21.018560615858807
            ],
            [
              105.80772331576019,
              21.018549137333167
            ],
            [
              105.80773266409155,
              21.01854467254302
            ],
            [
              105.80775890810706,
              21.018532136659733
            ],
            [
              105.8078344058193,
              21.018491456206416
            ],
            [
              105.80808311327628,
              21.01835764121804
            ],
            [
              105.80809131727578,
              21.018355218625533
            ],
            [
              105.80812218360091,
              21.01834610474128
            ],
            [
              105.80813357361075,
              21.018339933966946
            ],
            [
              105.8083602101602,
              21.01821715069494
            ],
            [
              105.80850152324993,
              21.018146225094576
            ],
            [
              105.80852603207816,
              21.018134462377642
            ],
            [
              105.80854950371189,
              21.018123197749958
            ],
            [
              105.8086128210178,
              21.018084182600983
            ],
            [
              105.80866248586024,
              21.018057949789206
            ],
            [
              105.80872810643064,
              21.018023289857197
            ],
            [
              105.80874869652142,
              21.01801319051833
            ],
            [
              105.80887289587827,
              21.017955435568663
            ],
            [
              105.80887788467678,
              21.017952599193308
            ],
            [
              105.8089351129273,
              21.017920057417335
            ],
            [
              105.80894878681266,
              21.01791304295268
            ],
            [
              105.8090317270422,
              21.01787049574628
            ],
            [
              105.80906563074538,
              21.01785365548308
            ],
            [
              105.8092797556699,
              21.017747295221266
            ],
            [
              105.80930898942766,
              21.017733559846153
            ],
            [
              105.80931489103222,
              21.017729818596795
            ],
            [
              105.8093163530266,
              21.01772907280041
            ],
            [
              105.80951949997407,
              21.017625423576693
            ],
            [
              105.80953142422267,
              21.017619339588723
            ],
            [
              105.80955749640371,
              21.01760603741181
            ],
            [
              105.80975081406474,
              21.017512063111862
            ],
            [
              105.80976242139677,
              21.017507149394437
            ],
            [
              105.80989789429377,
              21.017449802505638
            ],
            [
              105.80990537698676,
              21.017446355387438
            ],
            [
              105.81000672916483,
              21.017399666370586
            ],
            [
              105.81000972201545,
              21.01739863779459
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D90_Ngõ 97, Nguyễn Chí Thanh",
          "maTaiSan": "04.O9B.DOPP.641",
          "duongKinh": 90,
          "chieudaiQL": 269.23,
          "chatLieu": "PVC",
          "from_latitude": 21.018560615858807,
          "to_latitude": 21.01739863779459,
          "to_longitude": 105.81000972201545,
          "from_longitude": 105.80769928528639
        }
      },
      {
        "id": 640,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80769928528639,
            21.018560615858807,
            105.80829040482324,
            21.019532048650223
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80769928528639,
              21.018560615858807
            ],
            [
              105.8077780456631,
              21.01871025824692
            ],
            [
              105.80811474046256,
              21.01930771378628
            ],
            [
              105.80829040482324,
              21.019532048650223
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D110_Nguyễn Chí Thanh",
          "maTaiSan": "04.O9B.DOPP.642",
          "duongKinh": 110,
          "chieudaiQL": 121.73,
          "chatLieu": "PVC",
          "from_latitude": 21.018560615858807,
          "to_latitude": 21.019532048650223,
          "to_longitude": 105.80829040482324,
          "from_longitude": 105.80769928528639
        }
      },
      {
        "id": 641,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81029539159559,
            21.017402822673034,
            105.81131410963228,
            21.01790653111597
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81131410963228,
              21.017402822673034
            ],
            [
              105.81129631861292,
              21.01741176728665
            ],
            [
              105.81112437734528,
              21.017498213241897
            ],
            [
              105.8111119212192,
              21.017504476005087
            ],
            [
              105.81086387386674,
              21.01762918402054
            ],
            [
              105.81085435235832,
              21.017633971353863
            ],
            [
              105.81080333846383,
              21.01766376813577
            ],
            [
              105.81079666913489,
              21.017667663933935
            ],
            [
              105.81064118069625,
              21.017741145540125
            ],
            [
              105.81055655086742,
              21.017783693438933
            ],
            [
              105.81055275088907,
              21.017785603808388
            ],
            [
              105.81054976539748,
              21.01778710474741
            ],
            [
              105.81054295690437,
              21.01779052790728
            ],
            [
              105.81052014360748,
              21.017801998314045
            ],
            [
              105.81039885122787,
              21.017862978672262
            ],
            [
              105.81029715470014,
              21.017905789296368
            ],
            [
              105.81029539159559,
              21.01790653111597
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D90_Ngõ 26, Nguyên Hồng",
          "maTaiSan": "04.O9B.DOPP.643",
          "duongKinh": 90,
          "chieudaiQL": 118.95,
          "chatLieu": "PVC",
          "from_latitude": 21.017402822673034,
          "to_latitude": 21.01790653111597,
          "to_longitude": 105.81029539159559,
          "from_longitude": 105.81131410963228
        }
      },
      {
        "id": 642,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.809042534177,
            21.015742188055388,
            105.81065505241334,
            21.018599364969
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.809042534177,
              21.015742188055388
            ],
            [
              105.80906589676351,
              21.015786040716268
            ],
            [
              105.80906631075459,
              21.01578682004045
            ],
            [
              105.80906930857128,
              21.01579260180367
            ],
            [
              105.80908454076383,
              21.015821996302982
            ],
            [
              105.80918705829541,
              21.016005913733743
            ],
            [
              105.80920149928448,
              21.016031821907674
            ],
            [
              105.80921144391343,
              21.016049662149886
            ],
            [
              105.80922040958292,
              21.016066309366497
            ],
            [
              105.80925477573538,
              21.016140965759924
            ],
            [
              105.80931096604476,
              21.01625297070759
            ],
            [
              105.80934000673508,
              21.016314393918506
            ],
            [
              105.80939976883538,
              21.016421398725697
            ],
            [
              105.80940518108784,
              21.016431088104266
            ],
            [
              105.80945954370611,
              21.016506156020384
            ],
            [
              105.80949433730582,
              21.016564614527407
            ],
            [
              105.80952601191817,
              21.01661783378638
            ],
            [
              105.80956048599126,
              21.016679282581954
            ],
            [
              105.80958316664915,
              21.016719709747782
            ],
            [
              105.80964621213349,
              21.016760941974642
            ],
            [
              105.80965108447535,
              21.016769687330108
            ],
            [
              105.80966060962568,
              21.01678678256801
            ],
            [
              105.80984777643283,
              21.017135163870257
            ],
            [
              105.80985554919839,
              21.017149631816334
            ],
            [
              105.80995194951456,
              21.017312504602067
            ],
            [
              105.80997395874174,
              21.017349689603797
            ],
            [
              105.81000972201545,
              21.01739863779459
            ],
            [
              105.81001537292748,
              21.01740637142761
            ],
            [
              105.81010839607674,
              21.01756513063015
            ],
            [
              105.81011205250428,
              21.017571371688366
            ],
            [
              105.81012551214435,
              21.01759434262859
            ],
            [
              105.81016224065546,
              21.01767682306064
            ],
            [
              105.81019516997773,
              21.0177339300177
            ],
            [
              105.81021196626294,
              21.017763058625405
            ],
            [
              105.81027231378297,
              21.01786352813856
            ],
            [
              105.81028439203526,
              21.01788363699013
            ],
            [
              105.81029539159559,
              21.01790653111597
            ],
            [
              105.81029728680078,
              21.017910476422834
            ],
            [
              105.81030605745951,
              21.01792873134454
            ],
            [
              105.8104559585475,
              21.018210436004285
            ],
            [
              105.81045938715438,
              21.01821687955906
            ],
            [
              105.81047211568833,
              21.018246412291614
            ],
            [
              105.8104927554686,
              21.018275963330463
            ],
            [
              105.81053061269198,
              21.01833654222638
            ],
            [
              105.81055071447359,
              21.018368709763536
            ],
            [
              105.81064689439613,
              21.018584127544983
            ],
            [
              105.81065505241334,
              21.018599364969
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D110_Ngõ 49, Huỳnh Thúc Kháng",
          "maTaiSan": "04.O9B.DOPP.644",
          "duongKinh": 110,
          "chieudaiQL": 358.89,
          "chatLieu": "PVC",
          "from_latitude": 21.015742188055388,
          "to_latitude": 21.018599364969,
          "to_longitude": 105.81065505241334,
          "from_longitude": 105.809042534177
        }
      },
      {
        "id": 643,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80941019478827,
            21.015538375224477,
            105.81171479511578,
            21.018101456000995
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80941019478827,
              21.015538375224477
            ],
            [
              105.80945447475808,
              21.015572406860972
            ],
            [
              105.80993670408084,
              21.015942905006067
            ],
            [
              105.81015227404042,
              21.016104519365673
            ],
            [
              105.81017322150826,
              21.016120223403092
            ],
            [
              105.81018561632952,
              21.01612951550245
            ],
            [
              105.81020906923511,
              21.016147098061204
            ],
            [
              105.81021564478344,
              21.016152028180645
            ],
            [
              105.81041659224564,
              21.01630267798277
            ],
            [
              105.81058356843964,
              21.01641442476353
            ],
            [
              105.81072792174457,
              21.01651103087949
            ],
            [
              105.81077401183876,
              21.01654259313174
            ],
            [
              105.81085182029557,
              21.016595875873865
            ],
            [
              105.81094237113082,
              21.01671096356971
            ],
            [
              105.81098587821255,
              21.016792641962684
            ],
            [
              105.8110025331553,
              21.016823910012885
            ],
            [
              105.81108207618999,
              21.016973241812497
            ],
            [
              105.81130354035513,
              21.017386049547916
            ],
            [
              105.81131410963228,
              21.017402822673034
            ],
            [
              105.8113989453483,
              21.0175374597247
            ],
            [
              105.81145179673027,
              21.017621335220323
            ],
            [
              105.81146404137934,
              21.017643726552247
            ],
            [
              105.81171372499628,
              21.01810031129769
            ],
            [
              105.81171479511578,
              21.018101456000995
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Nguyên Hồng",
          "maTaiSan": "04.O9B.DOPP.645",
          "duongKinh": 100,
          "chieudaiQL": 378.94,
          "chatLieu": "G",
          "from_latitude": 21.015538375224477,
          "to_latitude": 21.018101456000995,
          "to_longitude": 105.81171479511578,
          "from_longitude": 105.80941019478827
        }
      },
      {
        "id": 644,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80942849035361,
            21.018189627249615,
            105.80990856966811,
            21.01896170830199
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80990856966811,
              21.01896170830199
            ],
            [
              105.80975159063507,
              21.018681954422846
            ],
            [
              105.8096227485172,
              21.0184487046662
            ],
            [
              105.80947999217284,
              21.018189627249615
            ],
            [
              105.80942849035361,
              21.01821519432596
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D40_Ngõ 55, Huỳnh Thúc Kháng",
          "maTaiSan": "04.O9B.DOPP.646",
          "duongKinh": 40,
          "chieudaiQL": 102.45,
          "chatLieu": "PVC",
          "from_latitude": 21.01896170830199,
          "to_latitude": 21.01821519432596,
          "to_longitude": 105.80942849035361,
          "from_longitude": 105.80990856966811
        }
      },
      {
        "id": 645,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80958829672237,
            21.017910476422834,
            105.81029728680078,
            21.018251936812835
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80958829672237,
              21.018251936812835
            ],
            [
              105.80959741195439,
              21.018247429676464
            ],
            [
              105.80961917875533,
              21.01823666708266
            ],
            [
              105.80980395752023,
              21.01814529851567
            ],
            [
              105.80981248078339,
              21.018140191732655
            ],
            [
              105.80985395125359,
              21.018115343397465
            ],
            [
              105.80986269961444,
              21.018111127930148
            ],
            [
              105.80999474630782,
              21.01804750808533
            ],
            [
              105.80999653141119,
              21.01804620796882
            ],
            [
              105.80999829638714,
              21.018044921496546
            ],
            [
              105.80999902851674,
              21.01804458020304
            ],
            [
              105.81013636169152,
              21.01798054669105
            ],
            [
              105.81014014109283,
              21.017978920945616
            ],
            [
              105.8102705169334,
              21.017922835452065
            ],
            [
              105.81029527076142,
              21.017911407318742
            ],
            [
              105.81029728680078,
              21.017910476422834
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D90_Ngõ 20, Huỳnh Thúc Kháng",
          "maTaiSan": "04.O9B.DOPP.647",
          "duongKinh": 90,
          "chieudaiQL": 81.52,
          "chatLieu": "PVC",
          "from_latitude": 21.018251936812835,
          "to_latitude": 21.017910476422834,
          "to_longitude": 105.81029728680078,
          "from_longitude": 105.80958829672237
        }
      },
      {
        "id": 646,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80662277107075,
            21.015538360626742,
            105.80941022549159,
            21.01692680954007
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80941022549159,
              21.015538360626742
            ],
            [
              105.80941019478827,
              21.015538375224477
            ],
            [
              105.80940875361364,
              21.01553887524396
            ],
            [
              105.80940872193382,
              21.01553888713665
            ],
            [
              105.80938040961354,
              21.01554878031722
            ],
            [
              105.80935009748238,
              21.015564212573835
            ],
            [
              105.80935006677903,
              21.01556422717156
            ],
            [
              105.8092961202391,
              21.015591694472906
            ],
            [
              105.80929608953572,
              21.015591709070616
            ],
            [
              105.809287810732,
              21.01559592498243
            ],
            [
              105.80911043482568,
              21.015703322559308
            ],
            [
              105.8090425648804,
              21.015742173457713
            ],
            [
              105.809042534177,
              21.015742188055388
            ],
            [
              105.8090295237002,
              21.015749639241417
            ],
            [
              105.80902345622471,
              21.015753112106346
            ],
            [
              105.80902342552133,
              21.015753126704023
            ],
            [
              105.80900839063149,
              21.015761736422448
            ],
            [
              105.80896805203406,
              21.0157848268979
            ],
            [
              105.80895707361313,
              21.01579111137982
            ],
            [
              105.80888467265503,
              21.01583224725352
            ],
            [
              105.80888464195158,
              21.015832261851166
            ],
            [
              105.8088811531077,
              21.01583424657483
            ],
            [
              105.80888112240427,
              21.015834261172486
            ],
            [
              105.80882991158458,
              21.015863360102863
            ],
            [
              105.80865047979181,
              21.01596530787315
            ],
            [
              105.80861055924514,
              21.015987988922973
            ],
            [
              105.8086105285416,
              21.01598800352055
            ],
            [
              105.80834385454675,
              21.016139519334185
            ],
            [
              105.80834382384319,
              21.01613953393173
            ],
            [
              105.80828947589563,
              21.016170415229098
            ],
            [
              105.80818543014264,
              21.01622953089927
            ],
            [
              105.80812773708547,
              21.01626230946342
            ],
            [
              105.80807840849181,
              21.0162903361327
            ],
            [
              105.80806899005323,
              21.016295687339476
            ],
            [
              105.80806896031639,
              21.016295702835627
            ],
            [
              105.80806149250762,
              21.016299946450342
            ],
            [
              105.80806146180396,
              21.01629996104784
            ],
            [
              105.80801286972192,
              21.016327572327942
            ],
            [
              105.8080128390183,
              21.016327586925446
            ],
            [
              105.80800970881958,
              21.016329367605074
            ],
            [
              105.80800967811592,
              21.01632938220256
            ],
            [
              105.80800690371467,
              21.01633096157867
            ],
            [
              105.80800687301105,
              21.016330976176164
            ],
            [
              105.80797339587683,
              21.016349999110087
            ],
            [
              105.80797336517323,
              21.01635001370756
            ],
            [
              105.80778348148479,
              21.016467120347055
            ],
            [
              105.80748608545775,
              21.01664820070342
            ],
            [
              105.80748326042449,
              21.01665020482923
            ],
            [
              105.80748322972075,
              21.01665021942662
            ],
            [
              105.80748047947431,
              21.016652178035955
            ],
            [
              105.80748044877053,
              21.01665219263336
            ],
            [
              105.8074795748515,
              21.016652820009416
            ],
            [
              105.8072012171442,
              21.016787100832246
            ],
            [
              105.80698899593351,
              21.016894396948594
            ],
            [
              105.80698896522965,
              21.016894411545902
            ],
            [
              105.80697076271876,
              21.016903615988497
            ],
            [
              105.80679605601212,
              21.016863149888174
            ],
            [
              105.80675472415943,
              21.016866770040075
            ],
            [
              105.8067546934555,
              21.016866784637347
            ],
            [
              105.8067279282117,
              21.01686911724781
            ],
            [
              105.80671365668202,
              21.016875189591314
            ],
            [
              105.80671362694491,
              21.016875205087217
            ],
            [
              105.8067118571483,
              21.016875955933934
            ],
            [
              105.8067118264444,
              21.016875970531203
            ],
            [
              105.80671082541548,
              21.01687639527962
            ],
            [
              105.80671079471162,
              21.016876409876883
            ],
            [
              105.80670840683916,
              21.0168774246877
            ],
            [
              105.80670838289791,
              21.016877444672257
            ],
            [
              105.8067069107342,
              21.016878983529846
            ],
            [
              105.80666099918095,
              21.01692680954007
            ],
            [
              105.80664624709692,
              21.01691310795419
            ],
            [
              105.80663087627454,
              21.016898831233995
            ],
            [
              105.80663085137137,
              21.01689885122311
            ],
            [
              105.80662277107075,
              21.016907093566296
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Trúc Khê",
          "maTaiSan": "04.O9B.DOPP.648",
          "duongKinh": 150,
          "chieudaiQL": 322.66,
          "chatLieu": "G",
          "from_latitude": 21.015538360626742,
          "to_latitude": 21.016907093566296,
          "to_longitude": 105.80662277107075,
          "from_longitude": 105.80941022549159
        }
      },
      {
        "id": 647,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80995194951456,
            21.016792641962684,
            105.81098587821255,
            21.017312504602067
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80995194951456,
              21.017312504602067
            ],
            [
              105.80995639967395,
              21.017310576698808
            ],
            [
              105.81012454383617,
              21.017228972329338
            ],
            [
              105.81014301355387,
              21.01722136138292
            ],
            [
              105.81024364722664,
              21.01717989179642
            ],
            [
              105.81026462563078,
              21.0171695971387
            ],
            [
              105.81043961954227,
              21.017083726408508
            ],
            [
              105.81047196379912,
              21.01706603613796
            ],
            [
              105.81047455940929,
              21.017064661703543
            ],
            [
              105.81052298100073,
              21.017039011449757
            ],
            [
              105.81065405810003,
              21.016969576237436
            ],
            [
              105.81069156414628,
              21.016955448952945
            ],
            [
              105.81074803776482,
              21.01693417795606
            ],
            [
              105.81076269002253,
              21.016927031331733
            ],
            [
              105.81076585226945,
              21.016925488901695
            ],
            [
              105.81089399996887,
              21.016862983490697
            ],
            [
              105.81098078086559,
              21.01679620330477
            ],
            [
              105.81098587821255,
              21.016792641962684
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D90_Ngõ 28, Nguyên Hồng",
          "maTaiSan": "04.O9B.DOPP.649",
          "duongKinh": 90,
          "chieudaiQL": 121.75,
          "chatLieu": "PVC",
          "from_latitude": 21.017312504602067,
          "to_latitude": 21.016792641962684,
          "to_longitude": 105.81098587821255,
          "from_longitude": 105.80995194951456
        }
      },
      {
        "id": 648,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80800687301105,
            21.016330976176164,
            105.80819828213671,
            21.016676625984886
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80800687301105,
              21.016330976176164
            ],
            [
              105.80801454995795,
              21.016344840408667
            ],
            [
              105.8080225037447,
              21.01635920281152
            ],
            [
              105.80810991879945,
              21.016517057706405
            ],
            [
              105.80814906228555,
              21.0165877445282
            ],
            [
              105.80819343873898,
              21.01666788044777
            ],
            [
              105.80819541941733,
              21.016671455953873
            ],
            [
              105.80819828213671,
              21.016676625984886
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D90_Trúc Khê",
          "maTaiSan": "04.O9B.DOPP.650",
          "duongKinh": 90,
          "chieudaiQL": 43.13,
          "chatLieu": "PVC",
          "from_latitude": 21.016330976176164,
          "to_latitude": 21.016676625984886,
          "to_longitude": 105.80819828213671,
          "from_longitude": 105.80800687301105
        }
      },
      {
        "id": 649,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80714227209299,
            21.017305838323352,
            105.80817969112555,
            21.017731050723196
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80715319436446,
              21.01771544530587
            ],
            [
              105.80714631533033,
              21.017717930211614
            ],
            [
              105.80714227209299,
              21.01771933404553
            ],
            [
              105.80715026935385,
              21.017731050723196
            ],
            [
              105.80717364776483,
              21.01772050840604
            ],
            [
              105.80720699088991,
              21.017703499554596
            ],
            [
              105.8079865426603,
              21.017305838323352
            ],
            [
              105.80798757812772,
              21.017307699476262
            ],
            [
              105.80798973358736,
              21.017311576735946
            ],
            [
              105.80809192415938,
              21.017495378937507
            ],
            [
              105.80816683797114,
              21.01763011900336
            ],
            [
              105.80817516648848,
              21.017645099229426
            ],
            [
              105.80817637107886,
              21.01764728293344
            ],
            [
              105.80817969112555,
              21.017653237503357
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D110_Ngõ 99, Nguyễn Chí Thanh",
          "maTaiSan": "04.O9B.DOPP.651",
          "duongKinh": 110,
          "chieudaiQL": 145.59,
          "chatLieu": "PVC",
          "from_latitude": 21.01771544530587,
          "to_latitude": 21.017653237503357,
          "to_longitude": 105.80817969112555,
          "from_longitude": 105.80715319436446
        }
      },
      {
        "id": 650,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80811474046256,
            21.019129822306358,
            105.8084536013668,
            21.01930771378628
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80811474046256,
              21.01930771378628
            ],
            [
              105.8081182129056,
              21.01930606063944
            ],
            [
              105.80818032527473,
              21.019263795224834
            ],
            [
              105.80822838407161,
              21.019240234685444
            ],
            [
              105.80825585733676,
              21.019226766171073
            ],
            [
              105.80826065640058,
              21.019224413038376
            ],
            [
              105.80826210226145,
              21.019223704359675
            ],
            [
              105.80833104396304,
              21.019189905823442
            ],
            [
              105.80840988405892,
              21.01915125504296
            ],
            [
              105.8084118604832,
              21.01915028642141
            ],
            [
              105.80841370738158,
              21.01914938073817
            ],
            [
              105.80841534896541,
              21.019148576289044
            ],
            [
              105.80843283070764,
              21.01914000556818
            ],
            [
              105.80843897200813,
              21.01913699482372
            ],
            [
              105.80844488304189,
              21.019134096270534
            ],
            [
              105.80844981162537,
              21.019131680194302
            ],
            [
              105.8084536013668,
              21.019129822306358
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D160_Nguyễn Chí Thanh",
          "maTaiSan": "04.O9B.DOPP.652",
          "duongKinh": 160,
          "chieudaiQL": 36.28,
          "chatLieu": "PVC",
          "from_latitude": 21.01930771378628,
          "to_latitude": 21.019129822306358,
          "to_longitude": 105.8084536013668,
          "from_longitude": 105.80811474046256
        }
      },
      {
        "id": 651,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8055360286441,
            21.0110521001591,
            105.81101849556741,
            21.015105246534485
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81101849556741,
              21.0110521001591
            ],
            [
              105.81088407210902,
              21.011094248690306
            ],
            [
              105.81087799919845,
              21.011098453258267
            ],
            [
              105.81083257932269,
              21.011129897035136
            ],
            [
              105.810603649261,
              21.011288385372644
            ],
            [
              105.81059967123898,
              21.011291139300912
            ],
            [
              105.81039654851378,
              21.011431759587413
            ],
            [
              105.81035209539,
              21.011462533859444
            ],
            [
              105.81025235994441,
              21.011531580157687
            ],
            [
              105.81005518113547,
              21.011666130950157
            ],
            [
              105.80986066722895,
              21.011798860573254
            ],
            [
              105.80985384639345,
              21.011803514860905
            ],
            [
              105.80978906041747,
              21.011847722846042
            ],
            [
              105.8097476290425,
              21.01187593818795
            ],
            [
              105.80950272836185,
              21.01204271686876
            ],
            [
              105.80948719275713,
              21.012053297140703
            ],
            [
              105.80933332591461,
              21.012158081084635
            ],
            [
              105.80932166700329,
              21.012166020827927
            ],
            [
              105.80925754057637,
              21.01220969167187
            ],
            [
              105.80918434765036,
              21.012259535826693
            ],
            [
              105.80904736739807,
              21.01235281968573
            ],
            [
              105.80878069660021,
              21.012541138117943
            ],
            [
              105.80871127926812,
              21.01259015935209
            ],
            [
              105.80812525377172,
              21.01299504333237
            ],
            [
              105.80803618738625,
              21.01306185820923
            ],
            [
              105.80770471268795,
              21.013310519509904
            ],
            [
              105.80735724111348,
              21.01357118053092
            ],
            [
              105.80733414110004,
              21.013588110983886
            ],
            [
              105.8071850344225,
              21.013689102190728
            ],
            [
              105.80717304825086,
              21.013698477672676
            ],
            [
              105.80701732268838,
              21.013820279834125
            ],
            [
              105.80694906670384,
              21.013870290224485
            ],
            [
              105.8069415011236,
              21.013875833999624
            ],
            [
              105.80686435029362,
              21.013932315730365
            ],
            [
              105.80677853028914,
              21.01400002954829
            ],
            [
              105.80676358565334,
              21.014011820718114
            ],
            [
              105.80666748285682,
              21.014087649079283
            ],
            [
              105.80665596641549,
              21.014096735975325
            ],
            [
              105.80661557025245,
              21.014128609171095
            ],
            [
              105.80640002549328,
              21.014311515137994
            ],
            [
              105.80638971288958,
              21.01432105153853
            ],
            [
              105.80622947488469,
              21.014469221596862
            ],
            [
              105.8061510781999,
              21.01453902794191
            ],
            [
              105.80613565714563,
              21.014552758743324
            ],
            [
              105.80601953772786,
              21.01465615512509
            ],
            [
              105.80601882928673,
              21.014656786225842
            ],
            [
              105.80592740853534,
              21.014738189316983
            ],
            [
              105.80589347407462,
              21.014770014577483
            ],
            [
              105.80589213028578,
              21.01477127462527
            ],
            [
              105.80562760851045,
              21.01501935835707
            ],
            [
              105.80560722000364,
              21.01503847962453
            ],
            [
              105.80556218667648,
              21.01508071470916
            ],
            [
              105.8055360286441,
              21.015105246534485
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D160_Láng",
          "maTaiSan": "04.O9B.DOPP.653",
          "duongKinh": 160,
          "chieudaiQL": 720.51,
          "chatLieu": "PVC",
          "from_latitude": 21.0110521001591,
          "to_latitude": 21.015105246534485,
          "to_longitude": 105.8055360286441,
          "from_longitude": 105.81101849556741
        }
      },
      {
        "id": 652,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80921375198469,
            21.013156989266427,
            105.81257520883814,
            21.01509686664093
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81257520883814,
              21.013156989266427
            ],
            [
              105.81256646392346,
              21.01316212885188
            ],
            [
              105.81253375105281,
              21.013181358553815
            ],
            [
              105.81252030095361,
              21.013189264615058
            ],
            [
              105.8122627610286,
              21.0133406443027
            ],
            [
              105.81225968496236,
              21.01334245279969
            ],
            [
              105.81216073389707,
              21.013400615272584
            ],
            [
              105.81215670086763,
              21.01340298562776
            ],
            [
              105.81203540791758,
              21.013474281037197
            ],
            [
              105.81203120420338,
              21.013476751559256
            ],
            [
              105.81202910714352,
              21.013477984539296
            ],
            [
              105.81201259721152,
              21.013487688138262
            ],
            [
              105.81169773641862,
              21.013672759107802
            ],
            [
              105.81167192915927,
              21.013687928207663
            ],
            [
              105.8115222528975,
              21.01377590581314
            ],
            [
              105.81107948218943,
              21.01402575830735
            ],
            [
              105.81106871065111,
              21.01403183690167
            ],
            [
              105.81043178518466,
              21.01439124542338
            ],
            [
              105.81039657651672,
              21.014412434879578
            ],
            [
              105.81036706416181,
              21.014430195637495
            ],
            [
              105.81006001160131,
              21.014614988926123
            ],
            [
              105.80995436032025,
              21.01467857252498
            ],
            [
              105.8099041133964,
              21.01470881337464
            ],
            [
              105.8095459576621,
              21.014923886803977
            ],
            [
              105.80954051918262,
              21.014927152563757
            ],
            [
              105.80935748813877,
              21.01503706297419
            ],
            [
              105.80924558942168,
              21.01507443435651
            ],
            [
              105.80921629817416,
              21.01509507245965
            ],
            [
              105.80921619561491,
              21.01509514068962
            ],
            [
              105.80921379702409,
              21.015096834813708
            ],
            [
              105.80921375198469,
              21.01509686664093
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D110_Vũ Ngọc Phan",
          "maTaiSan": "04.O9B.DOPP.654",
          "duongKinh": 110,
          "chieudaiQL": 405.68,
          "chatLieu": "PVC",
          "from_latitude": 21.013156989266427,
          "to_latitude": 21.01509686664093,
          "to_longitude": 105.80921375198469,
          "from_longitude": 105.81257520883814
        }
      },
      {
        "id": 653,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80611803650828,
            21.012644102097816,
            105.8098049339363,
            21.01607814742993
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8098049339363,
              21.012644102097816
            ],
            [
              105.80976855078929,
              21.012667378048977
            ],
            [
              105.80976354779318,
              21.01267057851575
            ],
            [
              105.80962192676152,
              21.01276324001407
            ],
            [
              105.80961931671283,
              21.012765313602355
            ],
            [
              105.80939582615432,
              21.012942830834096
            ],
            [
              105.80938914536598,
              21.012948137472794
            ],
            [
              105.80938456603242,
              21.01295177488278
            ],
            [
              105.80932253955007,
              21.013001042109853
            ],
            [
              105.80920842267079,
              21.01310876114831
            ],
            [
              105.80917224514427,
              21.013142910877253
            ],
            [
              105.8091653875161,
              21.013148962609325
            ],
            [
              105.80901503272932,
              21.013281652012957
            ],
            [
              105.80901133249579,
              21.013284917619018
            ],
            [
              105.80891066116888,
              21.013373760077265
            ],
            [
              105.8088985207473,
              21.013384474093208
            ],
            [
              105.80881543621611,
              21.013457796699132
            ],
            [
              105.80875611130624,
              21.013507761726547
            ],
            [
              105.80875031220432,
              21.01351264595503
            ],
            [
              105.80850949398553,
              21.013715467105417
            ],
            [
              105.80850447338108,
              21.013719695494956
            ],
            [
              105.80836766252398,
              21.01383491927832
            ],
            [
              105.80836016209261,
              21.013841296486095
            ],
            [
              105.80833327524695,
              21.013864156699007
            ],
            [
              105.80828745966747,
              21.01390310934537
            ],
            [
              105.80827737577519,
              21.013911682831093
            ],
            [
              105.8080599690527,
              21.014096528081488
            ],
            [
              105.80804593334085,
              21.01410846136762
            ],
            [
              105.80788838237937,
              21.014242414189823
            ],
            [
              105.80783255636086,
              21.014289878413063
            ],
            [
              105.80770451958335,
              21.014398737418787
            ],
            [
              105.80769061362834,
              21.014413162973476
            ],
            [
              105.80768833693882,
              21.01441552398405
            ],
            [
              105.80744324842068,
              21.014669762217444
            ],
            [
              105.8074357678563,
              21.014677522135802
            ],
            [
              105.80732677631755,
              21.014790581724732
            ],
            [
              105.8073245765562,
              21.014793297334716
            ],
            [
              105.80731913638995,
              21.01480001156209
            ],
            [
              105.80731345923438,
              21.01480701775365
            ],
            [
              105.80730103944337,
              21.01482234577773
            ],
            [
              105.80716756740117,
              21.014987075046566
            ],
            [
              105.80697111833082,
              21.015192291223382
            ],
            [
              105.80696012933184,
              21.015203770099767
            ],
            [
              105.80676740456477,
              21.015405095339542
            ],
            [
              105.806716288638,
              21.015464948150143
            ],
            [
              105.80671133983877,
              21.01547074325129
            ],
            [
              105.80661515694204,
              21.01558336773217
            ],
            [
              105.80647149729285,
              21.015737328610015
            ],
            [
              105.806452657508,
              21.01575751928481
            ],
            [
              105.80633535873936,
              21.015883228532655
            ],
            [
              105.80625396245274,
              21.015966531653884
            ],
            [
              105.80619068277774,
              21.016031475655844
            ],
            [
              105.80616542029057,
              21.016055297007895
            ],
            [
              105.80614046156151,
              21.016066908869274
            ],
            [
              105.80611803650828,
              21.01607814742993
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D110_Ngõ Láng Trung, Láng Hạ",
          "maTaiSan": "04.O9B.DOPP.655",
          "duongKinh": 110,
          "chieudaiQL": 541.96,
          "chatLieu": "PVC",
          "from_latitude": 21.012644102097816,
          "to_latitude": 21.01607814742993,
          "to_longitude": 105.80611803650828,
          "from_longitude": 105.8098049339363
        }
      },
      {
        "id": 654,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80731913638995,
            21.013656458213326,
            105.81071819658247,
            21.015593017403933
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81071819658247,
              21.013656458213326
            ],
            [
              105.81071037591536,
              21.013659982844654
            ],
            [
              105.8105465978373,
              21.01378083297491
            ],
            [
              105.81053838013491,
              21.013786896659855
            ],
            [
              105.8104249984354,
              21.01386914374765
            ],
            [
              105.81041511007578,
              21.01387631732382
            ],
            [
              105.81026055986453,
              21.013985158161336
            ],
            [
              105.8102540678348,
              21.013989617609425
            ],
            [
              105.81018124283271,
              21.014039641091507
            ],
            [
              105.81017042962428,
              21.014047629254236
            ],
            [
              105.81015193813208,
              21.01406128922301
            ],
            [
              105.81004748552965,
              21.014141381801146
            ],
            [
              105.81004120244481,
              21.014145881795102
            ],
            [
              105.81001113429134,
              21.014167418828002
            ],
            [
              105.80992299488744,
              21.01422825902035
            ],
            [
              105.80976894078636,
              21.01433469357288
            ],
            [
              105.80976808191531,
              21.01433525314645
            ],
            [
              105.80976570755774,
              21.014336799936288
            ],
            [
              105.80976526679625,
              21.01433711997066
            ],
            [
              105.80961239729672,
              21.014447959215026
            ],
            [
              105.80959380681699,
              21.014461654822256
            ],
            [
              105.8095298568025,
              21.01450876624508
            ],
            [
              105.80930945814518,
              21.0146591808545
            ],
            [
              105.80930094278308,
              21.01466554125356
            ],
            [
              105.80929943477395,
              21.01466666752753
            ],
            [
              105.80929903334159,
              21.014666967502606
            ],
            [
              105.8092267789322,
              21.01472356157628
            ],
            [
              105.80916095779604,
              21.014776927593363
            ],
            [
              105.80912872983416,
              21.014807379682168
            ],
            [
              105.8090430806232,
              21.014873837417696
            ],
            [
              105.80894709528485,
              21.01494831435046
            ],
            [
              105.80887966859525,
              21.015000632022122
            ],
            [
              105.80885020556494,
              21.015023492823442
            ],
            [
              105.80882769901233,
              21.0150409562713
            ],
            [
              105.80873521374416,
              21.015112718504213
            ],
            [
              105.80867174415829,
              21.0151585826827
            ],
            [
              105.80866382267546,
              21.015164307064683
            ],
            [
              105.80823511782943,
              21.015474099653012
            ],
            [
              105.80822795042063,
              21.015479278495594
            ],
            [
              105.80807055386745,
              21.015593017403933
            ],
            [
              105.8078926605333,
              21.01540573769864
            ],
            [
              105.80787957416781,
              21.015391960564187
            ],
            [
              105.80772336222928,
              21.015227505277053
            ],
            [
              105.80764218999747,
              21.0151453273569
            ],
            [
              105.80761012503552,
              21.01511286486765
            ],
            [
              105.80752087190649,
              21.015022507664877
            ],
            [
              105.80747710860815,
              21.014978201531264
            ],
            [
              105.80743707199461,
              21.01493766945542
            ],
            [
              105.80738170999832,
              21.014890735750217
            ],
            [
              105.80737060571231,
              21.01488132170002
            ],
            [
              105.80733174201741,
              21.014816889101056
            ],
            [
              105.80732524517563,
              21.01480600079139
            ],
            [
              105.80731913638995,
              21.01480001156209
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D110_Ngõ 25, Vũ Ngọc Phan",
          "maTaiSan": "04.O9B.DOPP.656",
          "duongKinh": 110,
          "chieudaiQL": 465.98,
          "chatLieu": "PVC",
          "from_latitude": 21.013656458213326,
          "to_latitude": 21.01480001156209,
          "to_longitude": 105.80731913638995,
          "from_longitude": 105.81071819658247
        }
      },
      {
        "id": 655,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80975824871557,
            21.011674039411528,
            105.80986066722895,
            21.011798860573254
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80975824871557,
              21.011674039411528
            ],
            [
              105.80976249658728,
              21.011679217280122
            ],
            [
              105.80976305141199,
              21.011679892964857
            ],
            [
              105.80978519610568,
              21.011706882646738
            ],
            [
              105.80981341969544,
              21.011741278777045
            ],
            [
              105.80986066722895,
              21.011798860573254
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D160_Láng",
          "maTaiSan": "04.O9B.DOPP.657",
          "duongKinh": 160,
          "chieudaiQL": 17.16,
          "chatLieu": "PVC",
          "from_latitude": 21.011674039411528,
          "to_latitude": 21.011798860573254,
          "to_longitude": 105.80986066722895,
          "from_longitude": 105.80975824871557
        }
      },
      {
        "id": 656,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8098049339363,
            21.012644102097816,
            105.81106871065111,
            21.01403183690167
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81106871065111,
              21.01403183690167
            ],
            [
              105.81106573457915,
              21.01402705946038
            ],
            [
              105.81103943211393,
              21.01398481980291
            ],
            [
              105.81071819658247,
              21.013656458213326
            ],
            [
              105.81068403545052,
              21.013621538674535
            ],
            [
              105.81053706805841,
              21.013456294050847
            ],
            [
              105.81053553892205,
              21.013454574361788
            ],
            [
              105.81030137081966,
              21.0131912857264
            ],
            [
              105.81028846746173,
              21.01317719990193
            ],
            [
              105.81025706654042,
              21.01314713281902
            ],
            [
              105.81025189252225,
              21.01314217886031
            ],
            [
              105.81022737418759,
              21.013118701470475
            ],
            [
              105.81022412203251,
              21.013116763272816
            ],
            [
              105.81018752837261,
              21.01309928752825
            ],
            [
              105.81018227986696,
              21.013096214444694
            ],
            [
              105.81017902596959,
              21.0130943096739
            ],
            [
              105.81012790058554,
              21.013032492244676
            ],
            [
              105.81003987768342,
              21.012934318464563
            ],
            [
              105.81000831134915,
              21.01289586654136
            ],
            [
              105.80999228761698,
              21.012875733074154
            ],
            [
              105.8099910070955,
              21.012874270519717
            ],
            [
              105.80997596118382,
              21.01285708053541
            ],
            [
              105.8099748387993,
              21.01285568858312
            ],
            [
              105.80991693310673,
              21.012783915454317
            ],
            [
              105.8098067710831,
              21.01264639568505
            ],
            [
              105.8098049339363,
              21.012644102097816
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D160_Ngõ 562, Đường Láng",
          "maTaiSan": "04.O9B.DOPP.658",
          "duongKinh": 160,
          "chieudaiQL": 205,
          "chatLieu": "PVC",
          "from_latitude": 21.01403183690167,
          "to_latitude": 21.012644102097816,
          "to_longitude": 105.8098049339363,
          "from_longitude": 105.81106871065111
        }
      },
      {
        "id": 657,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80933332591461,
            21.012158081084635,
            105.80976855078929,
            21.012667378048977
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80933332591461,
              21.012158081084635
            ],
            [
              105.80934259091825,
              21.012169455638823
            ],
            [
              105.80968906184789,
              21.01259474651736
            ],
            [
              105.80972406572127,
              21.01262674121956
            ],
            [
              105.80976795319702,
              21.012666799213505
            ],
            [
              105.80976855078929,
              21.012667378048977
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D160_Ngõ 562, Đường Láng",
          "maTaiSan": "04.O9B.DOPP.659",
          "duongKinh": 160,
          "chieudaiQL": 70.66,
          "chatLieu": "PVC",
          "from_latitude": 21.012158081084635,
          "to_latitude": 21.012667378048977,
          "to_longitude": 105.80976855078929,
          "from_longitude": 105.80933332591461
        }
      },
      {
        "id": 658,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81101849556741,
            21.0110521001591,
            105.81480227701293,
            21.01661255031675
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81101849556741,
              21.0110521001591
            ],
            [
              105.8110211079245,
              21.01105330977689
            ],
            [
              105.81111697789545,
              21.01111488928968
            ],
            [
              105.81114199464508,
              21.011130958611275
            ],
            [
              105.81127835456302,
              21.011342153665776
            ],
            [
              105.81152187016853,
              21.011691331467933
            ],
            [
              105.81156303000645,
              21.01175418234192
            ],
            [
              105.81156315902265,
              21.011754382243392
            ],
            [
              105.81157622608873,
              21.011774549535986
            ],
            [
              105.81159110514267,
              21.011797512710395
            ],
            [
              105.81164782776939,
              21.01188505586553
            ],
            [
              105.8116527079229,
              21.011892588096103
            ],
            [
              105.81165985597917,
              21.01190362126207
            ],
            [
              105.81167698699927,
              21.011927490462956
            ],
            [
              105.8116831696748,
              21.011936104869925
            ],
            [
              105.81175327852063,
              21.012033793323983
            ],
            [
              105.81178460283635,
              21.01206569314941
            ],
            [
              105.81180082395551,
              21.012087968865607
            ],
            [
              105.81190141862692,
              21.012226106515453
            ],
            [
              105.81192191263871,
              21.012254249978362
            ],
            [
              105.81205689686155,
              21.01243054380665
            ],
            [
              105.81207346184439,
              21.012452178366647
            ],
            [
              105.8121831101042,
              21.012591109009747
            ],
            [
              105.81220350228442,
              21.012616947881163
            ],
            [
              105.81222049480085,
              21.01263847831956
            ],
            [
              105.81224095637432,
              21.012662740720703
            ],
            [
              105.81224099514849,
              21.012662795632554
            ],
            [
              105.81231255596494,
              21.012747641114736
            ],
            [
              105.81251608225703,
              21.013062869792222
            ],
            [
              105.81254996479066,
              21.013116805958735
            ],
            [
              105.81257520883814,
              21.013156989266427
            ],
            [
              105.81258441400574,
              21.013171645349363
            ],
            [
              105.81259048533371,
              21.013180816639824
            ],
            [
              105.81274409143464,
              21.013412903670282
            ],
            [
              105.8128224526793,
              21.013536435123903
            ],
            [
              105.81287011872878,
              21.01361164323861
            ],
            [
              105.81287636114426,
              21.013621492927836
            ],
            [
              105.8130383101633,
              21.013880586067906
            ],
            [
              105.81322772410742,
              21.014172908495713
            ],
            [
              105.81332033845608,
              21.01431459328757
            ],
            [
              105.81334475279641,
              21.014351942487128
            ],
            [
              105.81352030755545,
              21.014622823123037
            ],
            [
              105.81354927402224,
              21.014666940089395
            ],
            [
              105.81356958081142,
              21.014697866166284
            ],
            [
              105.81359310155987,
              21.014734845661664
            ],
            [
              105.81362394934285,
              21.014783346015843
            ],
            [
              105.8136383220223,
              21.014805943793974
            ],
            [
              105.81365323614075,
              21.01482940337072
            ],
            [
              105.813671543933,
              21.014858175762132
            ],
            [
              105.81367778043848,
              21.014867980284496
            ],
            [
              105.81369449557066,
              21.014895211253666
            ],
            [
              105.81379756896719,
              21.015063131472676
            ],
            [
              105.81399113395675,
              21.015368595815154
            ],
            [
              105.81400776594617,
              21.01539484262802
            ],
            [
              105.81407083890424,
              21.015494377346954
            ],
            [
              105.81417656233586,
              21.015665487467718
            ],
            [
              105.8143229301351,
              21.015902363002667
            ],
            [
              105.81433171685325,
              21.015916600075677
            ],
            [
              105.81436161605713,
              21.01595861241385
            ],
            [
              105.81460562889028,
              21.016301480104115
            ],
            [
              105.81474954287235,
              21.016533881538642
            ],
            [
              105.81476020239256,
              21.0165510947852
            ],
            [
              105.81478496219616,
              21.01658726880086
            ],
            [
              105.81480227701293,
              21.01661255031675
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D160_Láng Hạ",
          "maTaiSan": "04.O9B.DOPP.660",
          "duongKinh": 160,
          "chieudaiQL": 735.79,
          "chatLieu": "PVC",
          "from_latitude": 21.0110521001591,
          "to_latitude": 21.01661255031675,
          "to_longitude": 105.81480227701293,
          "from_longitude": 105.81101849556741
        }
      },
      {
        "id": 659,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81005518113547,
            21.011666130950157,
            105.81116464035229,
            21.013239544212972
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81005518113547,
              21.011666130950157
            ],
            [
              105.81006601182428,
              21.011677799689725
            ],
            [
              105.81017596095388,
              21.011839937842474
            ],
            [
              105.81024561029415,
              21.011973132360836
            ],
            [
              105.81027957792999,
              21.01203055507475
            ],
            [
              105.81028349600066,
              21.01203717785993
            ],
            [
              105.81042113383319,
              21.012225906908576
            ],
            [
              105.81042932755717,
              21.012237142890122
            ],
            [
              105.81045876505456,
              21.01227219880234
            ],
            [
              105.81047681907647,
              21.012293698174027
            ],
            [
              105.81058668204423,
              21.01243794075981
            ],
            [
              105.81059738914514,
              21.012451998188
            ],
            [
              105.81083014121376,
              21.012767545899038
            ],
            [
              105.81083371496638,
              21.012772390046447
            ],
            [
              105.81087308002617,
              21.01281106387239
            ],
            [
              105.81087980301459,
              21.0128176687584
            ],
            [
              105.81102041003152,
              21.013011587518715
            ],
            [
              105.81102934752964,
              21.013023912829542
            ],
            [
              105.81109086842147,
              21.01311466142231
            ],
            [
              105.81109767050211,
              21.013124681933174
            ],
            [
              105.8111047751627,
              21.013135157131877
            ],
            [
              105.81115560791291,
              21.013226209553068
            ],
            [
              105.81115720283546,
              21.013229065184667
            ],
            [
              105.81116464035229,
              21.013239544212972
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D90_Ngõ 538, Đường Láng",
          "maTaiSan": "04.O9B.DOPP.661",
          "duongKinh": 90,
          "chieudaiQL": 208.3,
          "chatLieu": "PVC",
          "from_latitude": 21.011666130950157,
          "to_latitude": 21.013239544212972,
          "to_longitude": 105.81116464035229,
          "from_longitude": 105.81005518113547
        }
      },
      {
        "id": 660,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8055360286441,
            21.015105246534485,
            105.80671079471162,
            21.016876409876883
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80671079471162,
              21.016876409876883
            ],
            [
              105.8067091380974,
              21.016874238240533
            ],
            [
              105.8066832040394,
              21.016849933384158
            ],
            [
              105.80663818574594,
              21.01680771765573
            ],
            [
              105.80661683377247,
              21.016786509009414
            ],
            [
              105.80650804073481,
              21.016672649795165
            ],
            [
              105.80627364282849,
              21.016320848404007
            ],
            [
              105.80613427199768,
              21.016100122844076
            ],
            [
              105.80611803650828,
              21.01607814742993
            ],
            [
              105.8059749903559,
              21.01587206422578
            ],
            [
              105.80595918745074,
              21.01584787744614
            ],
            [
              105.80581987999457,
              21.015634658934587
            ],
            [
              105.80579552767576,
              21.015585454337362
            ],
            [
              105.80579192070095,
              21.015578169714423
            ],
            [
              105.80578725640171,
              21.015575691666932
            ],
            [
              105.80575080178677,
              21.01550031730825
            ],
            [
              105.80570985761646,
              21.015427051565222
            ],
            [
              105.80567447792343,
              21.01536374285508
            ],
            [
              105.80565045311462,
              21.01531764921092
            ],
            [
              105.80559472985053,
              21.015201225759427
            ],
            [
              105.80559324093855,
              21.015198749831637
            ],
            [
              105.80557048791555,
              21.01516090476652
            ],
            [
              105.80554830200774,
              21.01512458979321
            ],
            [
              105.80554299043754,
              21.0151162185207
            ],
            [
              105.8055360286441,
              21.015105246534485
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D160_Ngõ 107, Láng",
          "maTaiSan": "04.O9B.DOPP.662",
          "duongKinh": 160,
          "chieudaiQL": 246.08,
          "chatLieu": "PVC",
          "from_latitude": 21.016876409876883,
          "to_latitude": 21.015105246534485,
          "to_longitude": 105.8055360286441,
          "from_longitude": 105.80671079471162
        }
      },
      {
        "id": 661,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80546422062106,
            21.0151162185207,
            105.80554299043754,
            21.015201571812415
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80554299043754,
              21.0151162185207
            ],
            [
              105.80547600698229,
              21.015188801249163
            ],
            [
              105.80547335782468,
              21.01519167160961
            ],
            [
              105.80546422062106,
              21.015201571812415
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D160_Láng",
          "maTaiSan": "04.O9B.DOPP.663",
          "duongKinh": 160,
          "chieudaiQL": 3.48,
          "chatLieu": "PVC",
          "from_latitude": 21.0151162185207,
          "to_latitude": 21.015201571812415,
          "to_longitude": 105.80546422062106,
          "from_longitude": 105.80554299043754
        }
      },
      {
        "id": 662,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80677853028914,
            21.01400002954829,
            105.8073245765562,
            21.014793297334716
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8073245765562,
              21.014793297334716
            ],
            [
              105.80728292174089,
              21.014761285833895
            ],
            [
              105.8071629278892,
              21.01466903660302
            ],
            [
              105.80710547692495,
              21.014635721668146
            ],
            [
              105.80708738129331,
              21.014625227985622
            ],
            [
              105.8069624198963,
              21.01455276302223
            ],
            [
              105.80695326449928,
              21.014547453909096
            ],
            [
              105.80697315445032,
              21.014506154247066
            ],
            [
              105.80700438597722,
              21.01444042985253
            ],
            [
              105.80700532315566,
              21.014437616363928
            ],
            [
              105.80701139866632,
              21.014419366700153
            ],
            [
              105.8070293193072,
              21.0143914396513
            ],
            [
              105.80703146883643,
              21.01438957873826
            ],
            [
              105.80704958522095,
              21.014373898549028
            ],
            [
              105.8071099483383,
              21.014322742328158
            ],
            [
              105.80710838708688,
              21.014319517979857
            ],
            [
              105.80709121528835,
              21.014284057363877
            ],
            [
              105.80708317195288,
              21.014272858442588
            ],
            [
              105.80702761978785,
              21.014195516487202
            ],
            [
              105.80696917808284,
              21.01413767358435
            ],
            [
              105.80693709506413,
              21.014106614658633
            ],
            [
              105.80693591294774,
              21.014105917552694
            ],
            [
              105.8069010032606,
              21.014085311551536
            ],
            [
              105.80678363203121,
              21.014003900985145
            ],
            [
              105.80677853028914,
              21.01400002954829
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D90_Ngõ 594, Đường Láng",
          "maTaiSan": "04.O9B.DOPP.664",
          "duongKinh": 90,
          "chieudaiQL": 128.62,
          "chatLieu": "PVC",
          "from_latitude": 21.014793297334716,
          "to_latitude": 21.01400002954829,
          "to_longitude": 105.80677853028914,
          "from_longitude": 105.8073245765562
        }
      },
      {
        "id": 663,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81254996479066,
            21.013109677970917,
            105.81256257548858,
            21.013116805958735
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81254996479066,
              21.013116805958735
            ],
            [
              105.81256257548858,
              21.013109677970917
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D160_Láng Hạ",
          "maTaiSan": "04.O9B.DOPP.665",
          "duongKinh": 160,
          "chieudaiQL": 1.53,
          "chatLieu": "PVC",
          "from_latitude": 21.013116805958735,
          "to_latitude": 21.013109677970917,
          "to_longitude": 105.81256257548858,
          "from_longitude": 105.81254996479066
        }
      },
      {
        "id": 664,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81362394934285,
            21.0147786235361,
            105.81363784656723,
            21.014788689292594
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81362394934285,
              21.014783346015843
            ],
            [
              105.8136327215848,
              21.0147786235361
            ],
            [
              105.81363609078169,
              21.014784390793924
            ],
            [
              105.81363784656723,
              21.01478745246995
            ],
            [
              105.81363551673584,
              21.014788689292594
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D160_Láng Hạ",
          "maTaiSan": "04.O9B.DOPP.666",
          "duongKinh": 160,
          "chieudaiQL": 5.93,
          "chatLieu": "PVC",
          "from_latitude": 21.014783346015843,
          "to_latitude": 21.014788689292594,
          "to_longitude": 105.81363551673584,
          "from_longitude": 105.81362394934285
        }
      },
      {
        "id": 665,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81138453733313,
            21.015070498859256,
            105.81192302850853,
            21.01593438823543
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81176646388096,
              21.01593438823543
            ],
            [
              105.81171235277984,
              21.015853884249257
            ],
            [
              105.81154824208119,
              21.01560968993413
            ],
            [
              105.81154689510214,
              21.015607685772853
            ],
            [
              105.81148003978937,
              21.015508210566765
            ],
            [
              105.81138453733313,
              21.0153661086016
            ],
            [
              105.81139437698555,
              21.01536059172229
            ],
            [
              105.81140377260482,
              21.015355323541545
            ],
            [
              105.81146392199153,
              21.015321594625217
            ],
            [
              105.81169329001986,
              21.015192978154367
            ],
            [
              105.81171154511355,
              21.015182741480476
            ],
            [
              105.81190474746342,
              21.01508020096778
            ],
            [
              105.81192302850853,
              21.015070498859256
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D110_Ngõ 17, Hoàng Ngọc Phách",
          "maTaiSan": "04.O9B.DOPP.667",
          "duongKinh": 110,
          "chieudaiQL": 139.25,
          "chatLieu": "PVC",
          "from_latitude": 21.01593438823543,
          "to_latitude": 21.015070498859256,
          "to_longitude": 105.81192302850853,
          "from_longitude": 105.81176646388096
        }
      },
      {
        "id": 666,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81019690941967,
            21.01610473674011,
            105.81081575559203,
            21.016487087695893
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81081575559203,
              21.016487087695893
            ],
            [
              105.81081052291994,
              21.016484299078087
            ],
            [
              105.810723654086,
              21.01643797912552
            ],
            [
              105.81056212703372,
              21.016351836537797
            ],
            [
              105.81042416906362,
              21.016261473632753
            ],
            [
              105.81040727909614,
              21.016250410055957
            ],
            [
              105.81039335796682,
              21.016239753239844
            ],
            [
              105.81039237560464,
              21.016239001013773
            ],
            [
              105.81036424743323,
              21.016217468351893
            ],
            [
              105.81025674268543,
              21.01614504468588
            ],
            [
              105.81019690941967,
              21.01610473674011
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D110_Nguyên Hồng",
          "maTaiSan": "04.O9B.DOPP.668",
          "duongKinh": 110,
          "chieudaiQL": 76.31,
          "chatLieu": "PVC",
          "from_latitude": 21.016487087695893,
          "to_latitude": 21.01610473674011,
          "to_longitude": 105.81019690941967,
          "from_longitude": 105.81081575559203
        }
      },
      {
        "id": 667,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81111697789545,
            21.01096001506777,
            105.81133869599547,
            21.01111488928968
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81111697789545,
              21.01111488928968
            ],
            [
              105.8111960590807,
              21.01105965017558
            ],
            [
              105.81127863221728,
              21.01100197101386
            ],
            [
              105.8113355940096,
              21.01096218225301
            ],
            [
              105.81133869599547,
              21.01096001506777
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D160_Láng Hạ",
          "maTaiSan": "04.O9B.DOPP.669",
          "duongKinh": 160,
          "chieudaiQL": 32.26,
          "chatLieu": "PVC",
          "from_latitude": 21.01111488928968,
          "to_latitude": 21.01096001506777,
          "to_longitude": 105.81133869599547,
          "from_longitude": 105.81111697789545
        }
      },
      {
        "id": 668,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81141992346787,
            21.020998249302245,
            105.81199750656396,
            21.021830812813956
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81141992346787,
              21.021830812813956
            ],
            [
              105.81143337710739,
              21.021830698051097
            ],
            [
              105.81146672454804,
              21.02183041341017
            ],
            [
              105.81164791291278,
              21.021828866954706
            ],
            [
              105.8116545560419,
              21.021828809963782
            ],
            [
              105.81166231405808,
              21.02182874403944
            ],
            [
              105.81167654494206,
              21.021828622838658
            ],
            [
              105.81186529795683,
              21.02182701114912
            ],
            [
              105.81189660227787,
              21.021631673295612
            ],
            [
              105.81190658880908,
              21.02156935439174
            ],
            [
              105.81193627605083,
              21.021506569316056
            ],
            [
              105.8119474750893,
              21.02148288290094
            ],
            [
              105.81197382915627,
              21.021337687869433
            ],
            [
              105.81197481377595,
              21.02132357113765
            ],
            [
              105.81197946824764,
              21.021256852769366
            ],
            [
              105.81199750656396,
              21.020998249302245
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Nguyên Hồng",
          "maTaiSan": "04.O9C.DOPP.670",
          "duongKinh": 100,
          "chieudaiQL": 140.95,
          "chatLieu": "G",
          "from_latitude": 21.021830812813956,
          "to_latitude": 21.020998249302245,
          "to_longitude": 105.81199750656396,
          "from_longitude": 105.81141992346787
        }
      },
      {
        "id": 669,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81164603209727,
            21.02053091125655,
            105.81193778725506,
            21.020989642882515
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81191718794798,
              21.020989642882515
            ],
            [
              105.81191828820958,
              21.02097893889652
            ],
            [
              105.8119352477815,
              21.020813879757995
            ],
            [
              105.81193550367365,
              21.020811393756624
            ],
            [
              105.81193579057998,
              21.020808595090358
            ],
            [
              105.81193609214397,
              21.02080566086993
            ],
            [
              105.81193778725506,
              21.020789158934097
            ],
            [
              105.81192234107974,
              21.020720267602304
            ],
            [
              105.81192403496082,
              21.02070851303812
            ],
            [
              105.81192534502645,
              21.020699425731475
            ],
            [
              105.8119264993139,
              21.020691415816064
            ],
            [
              105.81192741349892,
              21.020685077115125
            ],
            [
              105.81190052430121,
              21.020654138940735
            ],
            [
              105.81190796420516,
              21.020594619548433
            ],
            [
              105.81190984790521,
              21.020579546522047
            ],
            [
              105.81191101059095,
              21.020570244950626
            ],
            [
              105.81191224334773,
              21.020560382140225
            ],
            [
              105.81166063011337,
              21.020532527022507
            ],
            [
              105.8116566692262,
              21.02053208888945
            ],
            [
              105.81165545833868,
              21.02053195466716
            ],
            [
              105.81165212791815,
              21.020531585783818
            ],
            [
              105.81164821804364,
              21.020531152826553
            ],
            [
              105.81164603209727,
              21.02053091125655
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Nguyên Hồng",
          "maTaiSan": "04.O9C.DOPP.671",
          "duongKinh": 100,
          "chieudaiQL": 50.94,
          "chatLieu": "G",
          "from_latitude": 21.020989642882515,
          "to_latitude": 21.02053091125655,
          "to_longitude": 105.81164603209727,
          "from_longitude": 105.81191718794798
        }
      },
      {
        "id": 670,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81068937911645,
            21.020087012072377,
            105.81229666272704,
            21.021014727153144
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81229666272704,
              21.020169834541168
            ],
            [
              105.81229117824003,
              21.020169424472748
            ],
            [
              105.81204598901871,
              21.02015104660768
            ],
            [
              105.81201135279719,
              21.02015450693102
            ],
            [
              105.81191013298984,
              21.02014484052545
            ],
            [
              105.81190075085621,
              21.020143944143932
            ],
            [
              105.81189667673345,
              21.020143555330993
            ],
            [
              105.81189327922472,
              21.020143231030552
            ],
            [
              105.81177351942536,
              21.020131794191222
            ],
            [
              105.81176632498168,
              21.020131106846218
            ],
            [
              105.81175493033268,
              21.02013001864107
            ],
            [
              105.8117523692091,
              21.020129774346564
            ],
            [
              105.81174947025717,
              21.020129497341557
            ],
            [
              105.81166154118816,
              21.020121100525085
            ],
            [
              105.81164616674315,
              21.0201196329793
            ],
            [
              105.81161917724346,
              21.020117055688793
            ],
            [
              105.81160313387598,
              21.020115522685497
            ],
            [
              105.81160232155472,
              21.02011544527083
            ],
            [
              105.81159976427931,
              21.020115200955654
            ],
            [
              105.81147097176935,
              21.020102901965103
            ],
            [
              105.8114579746112,
              21.020101659706974
            ],
            [
              105.8114541189691,
              21.020101291518575
            ],
            [
              105.81132536209049,
              21.02008899586001
            ],
            [
              105.81131161517386,
              21.020087682198817
            ],
            [
              105.81130850736895,
              21.020087385408132
            ],
            [
              105.81130459398146,
              21.020087012072377
            ],
            [
              105.81125724215437,
              21.02011033526192
            ],
            [
              105.81131275503009,
              21.020336952348266
            ],
            [
              105.81129528305705,
              21.020341454274405
            ],
            [
              105.81112259306909,
              21.02038594801641
            ],
            [
              105.81110505581456,
              21.02039474508404
            ],
            [
              105.81100852221007,
              21.020443168590205
            ],
            [
              105.81100393450879,
              21.020445470211477
            ],
            [
              105.81100120606355,
              21.020446838965675
            ],
            [
              105.81099401751365,
              21.020450445506516
            ],
            [
              105.81080846580345,
              21.02054352257356
            ],
            [
              105.8108053641581,
              21.02054507826541
            ],
            [
              105.81079379799071,
              21.020550880638112
            ],
            [
              105.81078555507271,
              21.02055501426203
            ],
            [
              105.8107559584994,
              21.020569862597895
            ],
            [
              105.81074111223764,
              21.020577310024933
            ],
            [
              105.81070711500693,
              21.020594363333093
            ],
            [
              105.81070357684987,
              21.02059613878158
            ],
            [
              105.81068937911645,
              21.02060326066081
            ],
            [
              105.81069024516738,
              21.020604735117583
            ],
            [
              105.8106927190435,
              21.02060894682157
            ],
            [
              105.81071643639397,
              21.020649320981097
            ],
            [
              105.81078775467078,
              21.020759183906204
            ],
            [
              105.81078931839605,
              21.02076159258756
            ],
            [
              105.81083651443154,
              21.02083429605372
            ],
            [
              105.81085933973456,
              21.020824255349208
            ],
            [
              105.81089508040765,
              21.020889979968818
            ],
            [
              105.81096133967003,
              21.020895740995524
            ],
            [
              105.81098577858207,
              21.020897865385034
            ],
            [
              105.81099667037138,
              21.020898812424495
            ],
            [
              105.8110060636624,
              21.020899629317412
            ],
            [
              105.81101002982646,
              21.020899974407715
            ],
            [
              105.81111944626196,
              21.020909487539193
            ],
            [
              105.81122379263996,
              21.020918559617918
            ],
            [
              105.81122606300372,
              21.020918756532414
            ],
            [
              105.81123318881883,
              21.02091937648442
            ],
            [
              105.81144660038971,
              21.020937930571954
            ],
            [
              105.81144946265137,
              21.020938179756655
            ],
            [
              105.81145885498444,
              21.020938996628974
            ],
            [
              105.8114909278837,
              21.020941784527984
            ],
            [
              105.81150181775436,
              21.020942731544068
            ],
            [
              105.81161713296095,
              21.020952757244586
            ],
            [
              105.81165390540411,
              21.02095595443137
            ],
            [
              105.81166078194994,
              21.020956552070896
            ],
            [
              105.81166608684906,
              21.020957012693366
            ],
            [
              105.8116690241825,
              21.020957268741945
            ],
            [
              105.81167785156923,
              21.020958035910745
            ],
            [
              105.81168724390436,
              21.020958852770352
            ],
            [
              105.81177582859065,
              21.020974183220336
            ],
            [
              105.81191718794798,
              21.020989642882515
            ],
            [
              105.81192065696355,
              21.02099002284073
            ],
            [
              105.81194856114521,
              21.020993074423142
            ],
            [
              105.81199750656396,
              21.020998249302245
            ],
            [
              105.81208480866023,
              21.02100747989237
            ],
            [
              105.81215655642814,
              21.021014727153144
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Nguyên Hồng",
          "maTaiSan": "04.O9C.DOPP.672",
          "duongKinh": 100,
          "chieudaiQL": 378.86,
          "chatLieu": "G",
          "from_latitude": 21.020169834541168,
          "to_latitude": 21.021014727153144,
          "to_longitude": 105.81215655642814,
          "from_longitude": 105.81229666272704
        }
      },
      {
        "id": 671,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80929222784297,
            21.01914550544212,
            105.81080934353444,
            21.01992983071853
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80929222784297,
              21.01992983071853
            ],
            [
              105.80932316790243,
              21.019913836369412
            ],
            [
              105.80933026953903,
              21.01991016437642
            ],
            [
              105.80933085183042,
              21.01990986354054
            ],
            [
              105.80933201065616,
              21.01990926460585
            ],
            [
              105.8094665733933,
              21.019839701662207
            ],
            [
              105.80947023980538,
              21.01983780640291
            ],
            [
              105.80947448178917,
              21.01983561304884
            ],
            [
              105.80948216475676,
              21.019831642024357
            ],
            [
              105.80953640115426,
              21.019803604222798
            ],
            [
              105.80961406025064,
              21.019756814096688
            ],
            [
              105.80961832521193,
              21.01975424488676
            ],
            [
              105.8097523843568,
              21.01969194788261
            ],
            [
              105.81003537252384,
              21.019545653146505
            ],
            [
              105.81004997934018,
              21.019545712749714
            ],
            [
              105.81005681850664,
              21.019534594859554
            ],
            [
              105.81014050667399,
              21.019491324572677
            ],
            [
              105.81080934353444,
              21.01914550544212
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Huỳnh Thúc Kháng",
          "maTaiSan": "04.O9C.DOPP.673",
          "duongKinh": 100,
          "chieudaiQL": 181.15,
          "chatLieu": "G",
          "from_latitude": 21.01992983071853,
          "to_latitude": 21.01914550544212,
          "to_longitude": 105.81080934353444,
          "from_longitude": 105.80929222784297
        }
      },
      {
        "id": 672,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81014050667399,
            21.019491324572677,
            105.81021006460581,
            21.01962554699068
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8101829907451,
              21.01962554699068
            ],
            [
              105.81021006460581,
              21.01961420174969
            ],
            [
              105.81016141442684,
              21.01952789967814
            ],
            [
              105.81014223433719,
              21.01949434667548
            ],
            [
              105.81014050667399,
              21.019491324572677
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Huỳnh Thúc Kháng",
          "maTaiSan": "04.O9C.DOPP.674",
          "duongKinh": 100,
          "chieudaiQL": 5,
          "chatLieu": "G",
          "from_latitude": 21.01962554699068,
          "to_latitude": 21.019491324572677,
          "to_longitude": 105.81014050667399,
          "from_longitude": 105.8101829907451
        }
      },
      {
        "id": 673,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81176158139759,
            21.019265272517966,
            105.81188269520833,
            21.020131794191222
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81177351942536,
              21.020131794191222
            ],
            [
              105.81177358473248,
              21.02013141903994
            ],
            [
              105.81178136260696,
              21.020086879878548
            ],
            [
              105.81178140458415,
              21.02008663761312
            ],
            [
              105.81178241618692,
              21.020080879223126
            ],
            [
              105.81180111924029,
              21.019951239973985
            ],
            [
              105.81182179673782,
              21.019821518137125
            ],
            [
              105.81182235822644,
              21.019818441772493
            ],
            [
              105.81182254323441,
              21.01981743288708
            ],
            [
              105.81185422819364,
              21.01964394498519
            ],
            [
              105.81185978433771,
              21.01961814756543
            ],
            [
              105.81186009305165,
              21.019616711765913
            ],
            [
              105.81186060542161,
              21.01961433563726
            ],
            [
              105.81187831731181,
              21.01953209709415
            ],
            [
              105.81188269520833,
              21.019507041427946
            ],
            [
              105.8118745896273,
              21.0194908608489
            ],
            [
              105.81187349364303,
              21.019488672138944
            ],
            [
              105.81180863642913,
              21.01935920406543
            ],
            [
              105.81180675858882,
              21.01935545650174
            ],
            [
              105.81180455594041,
              21.019351060163945
            ],
            [
              105.81180199250115,
              21.0193459429655
            ],
            [
              105.81176158139759,
              21.019265272517966
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Nguyên Hồng",
          "maTaiSan": "04.O9C.DOPP.675",
          "duongKinh": 100,
          "chieudaiQL": 102.1,
          "chatLieu": "G",
          "from_latitude": 21.020131794191222,
          "to_latitude": 21.019265272517966,
          "to_longitude": 105.81176158139759,
          "from_longitude": 105.81177351942536
        }
      },
      {
        "id": 674,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81109549596566,
            21.021420615625235,
            105.81193627605083,
            21.021506569316056
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81109549596566,
              21.021420615625235
            ],
            [
              105.8111078280936,
              21.02142231449954
            ],
            [
              105.81110988248471,
              21.021422597348682
            ],
            [
              105.81113995516225,
              21.02142673975587
            ],
            [
              105.81114954455495,
              21.02142770202759
            ],
            [
              105.81121869445728,
              21.02143464322893
            ],
            [
              105.81130124044454,
              21.02144292773491
            ],
            [
              105.81130328479637,
              21.021443132951994
            ],
            [
              105.8113304099972,
              21.021445855961403
            ],
            [
              105.81133999939198,
              21.021446818222323
            ],
            [
              105.81138881936225,
              21.021451718386725
            ],
            [
              105.81145909733286,
              21.02145877243127
            ],
            [
              105.81149181274078,
              21.02146205584344
            ],
            [
              105.81149295234368,
              21.021462170535777
            ],
            [
              105.81152152995912,
              21.02146503832741
            ],
            [
              105.81153107508165,
              21.021465996272575
            ],
            [
              105.8115918589531,
              21.02147209749265
            ],
            [
              105.81160201526387,
              21.021473116646685
            ],
            [
              105.81168255351258,
              21.02148120009433
            ],
            [
              105.81168505601434,
              21.021481450991555
            ],
            [
              105.81171374817689,
              21.021484330849233
            ],
            [
              105.81172333757578,
              21.021485293088382
            ],
            [
              105.8117271952806,
              21.021485680227542
            ],
            [
              105.81183600963475,
              21.021496551502498
            ],
            [
              105.81193627605083,
              21.021506569316056
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Nguyên Hồng",
          "maTaiSan": "04.O9C.DOPP.676",
          "duongKinh": 100,
          "chieudaiQL": 85.84,
          "chatLieu": "G",
          "from_latitude": 21.021420615625235,
          "to_latitude": 21.021506569316056,
          "to_longitude": 105.81193627605083,
          "from_longitude": 105.81109549596566
        }
      },
      {
        "id": 675,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.809606188971,
            21.01952861190016,
            105.8101829907451,
            21.01974247397107
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.809606188971,
              21.01974247397107
            ],
            [
              105.80961102441643,
              21.019740089917605
            ],
            [
              105.81003993254333,
              21.01952861190016
            ],
            [
              105.81004997934018,
              21.019545712749714
            ],
            [
              105.81013695973834,
              21.01969375854199
            ],
            [
              105.81016902856658,
              21.019677148987356
            ],
            [
              105.81015105623673,
              21.019639239383785
            ],
            [
              105.8101829907451,
              21.01962554699068
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Huỳnh Thúc Kháng",
          "maTaiSan": "04.O9C.DOPP.677",
          "duongKinh": 100,
          "chieudaiQL": 74.38,
          "chatLieu": "G",
          "from_latitude": 21.01974247397107,
          "to_latitude": 21.01962554699068,
          "to_longitude": 105.8101829907451,
          "from_longitude": 105.809606188971
        }
      },
      {
        "id": 676,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80889568106795,
            21.020657062640513,
            105.8116837635852,
            21.024739672729012
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80889568106795,
              21.020657062640513
            ],
            [
              105.80890230788366,
              21.020668078513065
            ],
            [
              105.80904755434763,
              21.02091716288259
            ],
            [
              105.8094430764873,
              21.02163201262616
            ],
            [
              105.80996430616378,
              21.02258910007466
            ],
            [
              105.80997286423127,
              21.022604816132475
            ],
            [
              105.80998614618964,
              21.022611371735486
            ],
            [
              105.80999828998054,
              21.02261736733702
            ],
            [
              105.81036608827591,
              21.02321530179114
            ],
            [
              105.8106620491174,
              21.023683935663424
            ],
            [
              105.8110598122892,
              21.02436492457554
            ],
            [
              105.81116357108391,
              21.02453147806417
            ],
            [
              105.81125352970447,
              21.024675887845667
            ],
            [
              105.81125591302036,
              21.024679703452268
            ],
            [
              105.81141657490188,
              21.024739672729012
            ],
            [
              105.81153131247231,
              21.024723662914965
            ],
            [
              105.81167192939921,
              21.02470404125002
            ],
            [
              105.81168082053105,
              21.024720984009626
            ],
            [
              105.8116837635852,
              21.024727969073496
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Nguyễn Chí Thanh",
          "maTaiSan": "04.O9C.DOPP.678",
          "duongKinh": 100,
          "chieudaiQL": 524.88,
          "chatLieu": "G",
          "from_latitude": 21.020657062640513,
          "to_latitude": 21.024727969073496,
          "to_longitude": 105.8116837635852,
          "from_longitude": 105.80889568106795
        }
      },
      {
        "id": 677,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8115070757925,
            21.02160271495949,
            105.81189660227787,
            21.021631673295612
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8115070757925,
              21.02160271495949
            ],
            [
              105.81160746889014,
              21.0216101789359
            ],
            [
              105.81189660227787,
              21.021631673295612
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Nguyên Hồng",
          "maTaiSan": "04.O9C.DOPP.679",
          "duongKinh": 100,
          "chieudaiQL": 40.73,
          "chatLieu": "G",
          "from_latitude": 21.02160271495949,
          "to_latitude": 21.021631673295612,
          "to_longitude": 105.81189660227787,
          "from_longitude": 105.8115070757925
        }
      },
      {
        "id": 678,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81082944273084,
            21.018506100043655,
            105.81139472308024,
            21.018803348065475
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81082944273084,
              21.018803348065475
            ],
            [
              105.8108321506498,
              21.018801924315486
            ],
            [
              105.8108377535379,
              21.018798978375116
            ],
            [
              105.81083926624856,
              21.018798182646357
            ],
            [
              105.81086833104126,
              21.018782899675276
            ],
            [
              105.8109869488949,
              21.01872052539765
            ],
            [
              105.81100473307383,
              21.018711173489606
            ],
            [
              105.81109915514004,
              21.018661522652167
            ],
            [
              105.81112242227645,
              21.01864928767782
            ],
            [
              105.81112971340114,
              21.018645453933882
            ],
            [
              105.81113317718955,
              21.018643632767535
            ],
            [
              105.81138052647094,
              21.01851356519725
            ],
            [
              105.81139469910026,
              21.018506112803227
            ],
            [
              105.81139472308024,
              21.018506100043655
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_Huỳnh Thúc Kháng",
          "maTaiSan": "04.O9C.DOPP.680",
          "duongKinh": 63,
          "chieudaiQL": 65.66,
          "chatLieu": "PVC",
          "from_latitude": 21.018803348065475,
          "to_latitude": 21.018506100043655,
          "to_longitude": 105.81139472308024,
          "from_longitude": 105.81082944273084
        }
      },
      {
        "id": 679,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80941549241741,
            21.01808408711639,
            105.81207924894305,
            21.019406164767556
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80941549241741,
              21.019406164767556
            ],
            [
              105.81134295859515,
              21.018449997243493
            ],
            [
              105.81136148643087,
              21.018440667042878
            ],
            [
              105.81136151137764,
              21.018440655181923
            ],
            [
              105.81155891283032,
              21.018341247215798
            ],
            [
              105.81180821553905,
              21.0182189266473
            ],
            [
              105.81183255389128,
              21.018206818337962
            ],
            [
              105.81204504828942,
              21.01810110280932
            ],
            [
              105.81204568339123,
              21.018100786357447
            ],
            [
              105.81207924894305,
              21.01808408711639
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Huỳnh Thúc Kháng",
          "maTaiSan": "04.O9C.DOPP.681",
          "duongKinh": 150,
          "chieudaiQL": 312.06,
          "chatLieu": "G",
          "from_latitude": 21.019406164767556,
          "to_latitude": 21.01808408711639,
          "to_longitude": 105.81207924894305,
          "from_longitude": 105.80941549241741
        }
      },
      {
        "id": 680,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80941549241741,
            21.019406164767556,
            105.80988439365306,
            21.020199504570073
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80988439365306,
              21.020199504570073
            ],
            [
              105.80987774402202,
              21.020188013747177
            ],
            [
              105.80986189700822,
              21.020162631707844
            ],
            [
              105.80964600880058,
              21.019815018713974
            ],
            [
              105.80961406025064,
              21.019756814096688
            ],
            [
              105.809606188971,
              21.01974247397107
            ],
            [
              105.80948216066547,
              21.019516516490942
            ],
            [
              105.80942062545252,
              21.019416321533797
            ],
            [
              105.80941549241741,
              21.019406164767556
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 18, Huỳnh Thúc Kháng",
          "maTaiSan": "04.O9C.DOPP.682",
          "duongKinh": 100,
          "chieudaiQL": 96.7,
          "chatLieu": "G",
          "from_latitude": 21.020199504570073,
          "to_latitude": 21.019406164767556,
          "to_longitude": 105.80941549241741,
          "from_longitude": 105.80988439365306
        }
      },
      {
        "id": 681,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80897697400148,
            21.01968616213428,
            105.81112050491893,
            21.020636273908526
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80897697400148,
              21.020636273908526
            ],
            [
              105.80899879336299,
              21.020626979785654
            ],
            [
              105.80904084078767,
              21.020609070199175
            ],
            [
              105.80937954681144,
              21.020435135533145
            ],
            [
              105.80988439365306,
              21.020199504570073
            ],
            [
              105.81022480288368,
              21.02001587664557
            ],
            [
              105.81046834033869,
              21.02001668819825
            ],
            [
              105.81065390754789,
              21.019922640464742
            ],
            [
              105.8111195539313,
              21.0196869470581
            ],
            [
              105.81112050491893,
              21.01968616213428
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 91, Nguyễn Chí Thanh",
          "maTaiSan": "04.O9C.DOPP.683",
          "duongKinh": 100,
          "chieudaiQL": 248.66,
          "chatLieu": "G",
          "from_latitude": 21.020636273908526,
          "to_latitude": 21.01968616213428,
          "to_longitude": 105.81112050491893,
          "from_longitude": 105.80897697400148
        }
      },
      {
        "id": 682,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81196017766196,
            21.022479613575697,
            105.81231194570731,
            21.02318016689354
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8122163097646,
              21.02318016689354
            ],
            [
              105.81221789897117,
              21.023168091280446
            ],
            [
              105.8122187818533,
              21.02315934833731
            ],
            [
              105.8122192018893,
              21.023155198710327
            ],
            [
              105.81223456734133,
              21.02300311574018
            ],
            [
              105.81223828917183,
              21.022973263455246
            ],
            [
              105.81223875384275,
              21.022969539938426
            ],
            [
              105.81225066702817,
              21.022873989306415
            ],
            [
              105.81225159520315,
              21.022868458024014
            ],
            [
              105.81227680343203,
              21.02271828894825
            ],
            [
              105.81227733042826,
              21.02271541597404
            ],
            [
              105.81227788356675,
              21.022712396552098
            ],
            [
              105.81229529647736,
              21.02261739590916
            ],
            [
              105.81230194263118,
              21.02258113390104
            ],
            [
              105.81231194570731,
              21.022502181063803
            ],
            [
              105.81198171149188,
              21.02248099839069
            ],
            [
              105.81196017766196,
              21.022479613575697
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Nguyên Hồng",
          "maTaiSan": "04.O9C.DOPP.684",
          "duongKinh": 100,
          "chieudaiQL": 112.06,
          "chatLieu": "G",
          "from_latitude": 21.02318016689354,
          "to_latitude": 21.022479613575697,
          "to_longitude": 105.81196017766196,
          "from_longitude": 105.8122163097646
        }
      },
      {
        "id": 683,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8113263889822,
            21.022202238723352,
            105.81197076103258,
            21.024921464642965
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81133989396373,
              21.024902152556226
            ],
            [
              105.8113283826549,
              21.024912993815956
            ],
            [
              105.8113263889822,
              21.024915762687147
            ],
            [
              105.81133341935436,
              21.024921464642965
            ],
            [
              105.81141871341151,
              21.024853249713505
            ],
            [
              105.81143371820036,
              21.02484125100342
            ],
            [
              105.81152677822737,
              21.024785269314616
            ],
            [
              105.81152764947524,
              21.024773479844338
            ],
            [
              105.81153131247231,
              21.024723662914965
            ],
            [
              105.81153554886548,
              21.024666034847044
            ],
            [
              105.81153704834281,
              21.02464572313656
            ],
            [
              105.8115930058493,
              21.02440651990653
            ],
            [
              105.81166997682261,
              21.02413405075808
            ],
            [
              105.81167824392163,
              21.024117116431526
            ],
            [
              105.81168582755852,
              21.024089260828852
            ],
            [
              105.81168884275861,
              21.02407403608613
            ],
            [
              105.8117823795196,
              21.023488966554503
            ],
            [
              105.81184605422425,
              21.0230269543294
            ],
            [
              105.81195944927062,
              21.022285414062125
            ],
            [
              105.81197076103258,
              21.022202238723352
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D225_Nguyên Hồng",
          "maTaiSan": "04.O9C.DOPP.685",
          "duongKinh": 225,
          "chieudaiQL": 313.71,
          "chatLieu": "PVC",
          "from_latitude": 21.024902152556226,
          "to_latitude": 21.022202238723352,
          "to_longitude": 105.81197076103258,
          "from_longitude": 105.81133989396373
        }
      },
      {
        "id": 684,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81236412580284,
            21.021919451164763,
            105.81325711733693,
            21.022012754019887
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81236412580284,
              21.021919451164763
            ],
            [
              105.81238392592154,
              21.021921519825792
            ],
            [
              105.81238721487313,
              21.0219218636015
            ],
            [
              105.81239005817486,
              21.02192216073141
            ],
            [
              105.81239209776855,
              21.02192237408694
            ],
            [
              105.81247398851147,
              21.021930930305412
            ],
            [
              105.81250588662472,
              21.021934263487477
            ],
            [
              105.81252667911083,
              21.021936435778557
            ],
            [
              105.81253438027878,
              21.021937239894093
            ],
            [
              105.81253516377603,
              21.021937321957854
            ],
            [
              105.81254763329905,
              21.021938624455935
            ],
            [
              105.81279417476223,
              21.02196438435433
            ],
            [
              105.81279842239181,
              21.02196482831522
            ],
            [
              105.81280023868047,
              21.02196501834578
            ],
            [
              105.81280249580453,
              21.021965254140763
            ],
            [
              105.81280266039697,
              21.02196527141878
            ],
            [
              105.81292910171632,
              21.02197848200928
            ],
            [
              105.81294273301687,
              21.021979906280603
            ],
            [
              105.81294703839889,
              21.021980356284303
            ],
            [
              105.81295121769118,
              21.021980793342163
            ],
            [
              105.81295730085951,
              21.02198142904243
            ],
            [
              105.81297273111959,
              21.021983040713337
            ],
            [
              105.81309128455896,
              21.021995427205052
            ],
            [
              105.81309977115866,
              21.021996314249947
            ],
            [
              105.81310340758507,
              21.021996694286187
            ],
            [
              105.81323485505341,
              21.022010428071724
            ],
            [
              105.81324334068768,
              21.0220113142108
            ],
            [
              105.81324667199317,
              21.02201166228341
            ],
            [
              105.81325464652991,
              21.02201249576876
            ],
            [
              105.81325711733693,
              21.022012754019887
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Nguyên Hồng",
          "maTaiSan": "04.O9C.DOPP.686",
          "duongKinh": 100,
          "chieudaiQL": 93.4,
          "chatLieu": "G",
          "from_latitude": 21.021919451164763,
          "to_latitude": 21.022012754019887,
          "to_longitude": 105.81325711733693,
          "from_longitude": 105.81236412580284
        }
      },
      {
        "id": 685,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81304490626323,
            21.0214224547282,
            105.81338244191286,
            21.021671252008435
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81304490626323,
              21.021671252008435
            ],
            [
              105.81307090242747,
              21.0214224547282
            ],
            [
              105.81309102127739,
              21.021424415204102
            ],
            [
              105.81332422688578,
              21.021447134615645
            ],
            [
              105.81332916540437,
              21.0214476159053
            ],
            [
              105.81333249464129,
              21.02144793959933
            ],
            [
              105.81333436667815,
              21.02144812213195
            ],
            [
              105.81334698391396,
              21.021449351607895
            ],
            [
              105.81338244191286,
              21.021452805799026
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_Nguyên Hồng",
          "maTaiSan": "04.O9C.DOPP.687",
          "duongKinh": 63,
          "chieudaiQL": 60.25,
          "chatLieu": "PVC",
          "from_latitude": 21.021671252008435,
          "to_latitude": 21.021452805799026,
          "to_longitude": 105.81338244191286,
          "from_longitude": 105.81304490626323
        }
      },
      {
        "id": 686,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81515089488614,
            21.021912786863115,
            105.81578495259294,
            21.021967513238096
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81515089488614,
              21.021912786863115
            ],
            [
              105.81517830270533,
              21.021915447383602
            ],
            [
              105.81533317865828,
              21.021930482613847
            ],
            [
              105.81541504698141,
              21.021938430549945
            ],
            [
              105.81547799434009,
              21.02194465096684
            ],
            [
              105.81549925976606,
              21.02194675198045
            ],
            [
              105.8155019037463,
              21.02194701298148
            ],
            [
              105.81550494043032,
              21.021947313648067
            ],
            [
              105.81570934902157,
              21.021967513238096
            ],
            [
              105.81578495259294,
              21.02194727964366
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_Thành Công",
          "maTaiSan": "04.O9C.DOPP.688",
          "duongKinh": 63,
          "chieudaiQL": 41.23,
          "chatLieu": "PVC",
          "from_latitude": 21.021912786863115,
          "to_latitude": 21.02194727964366,
          "to_longitude": 105.81578495259294,
          "from_longitude": 105.81515089488614
        }
      },
      {
        "id": 687,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81157798858736,
            21.018206818337962,
            105.8124296334142,
            21.02453580549765
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81183255389128,
              21.018206818337962
            ],
            [
              105.81184684660343,
              21.018232932002636
            ],
            [
              105.81227802712158,
              21.01902070235415
            ],
            [
              105.8124296334142,
              21.01920396588998
            ],
            [
              105.81237994539623,
              21.01950821256312
            ],
            [
              105.81230141874656,
              21.0201320153549
            ],
            [
              105.81230138832333,
              21.020132259371696
            ],
            [
              105.81229666272704,
              21.020169834541168
            ],
            [
              105.81229075918023,
              21.020216730324744
            ],
            [
              105.81225647660038,
              21.020339294057443
            ],
            [
              105.8122336627222,
              21.020420855183723
            ],
            [
              105.81221273275007,
              21.020582055750637
            ],
            [
              105.81221216852106,
              21.02058640116437
            ],
            [
              105.81219733514052,
              21.020700647219787
            ],
            [
              105.81215822973941,
              21.021001841844402
            ],
            [
              105.81215655642814,
              21.021014727153144
            ],
            [
              105.81211128775897,
              21.02136339503546
            ],
            [
              105.81208613468324,
              21.021579165278627
            ],
            [
              105.81205824197912,
              21.02181841083885
            ],
            [
              105.81205730246761,
              21.021826467599833
            ],
            [
              105.81205638778913,
              21.021834313790162
            ],
            [
              105.81204626471126,
              21.021921146952717
            ],
            [
              105.81200307549726,
              21.022205350526384
            ],
            [
              105.81199720219264,
              21.02224400238979
            ],
            [
              105.81197227934456,
              21.022408007523957
            ],
            [
              105.81196017766196,
              21.022479613575697
            ],
            [
              105.8118711414324,
              21.023006434278827
            ],
            [
              105.8118302474184,
              21.02325696625033
            ],
            [
              105.81182601941244,
              21.023282894613363
            ],
            [
              105.81182264520692,
              21.023303588309588
            ],
            [
              105.81179710891841,
              21.023571472029232
            ],
            [
              105.81170443048799,
              21.02404730202662
            ],
            [
              105.8116851668456,
              21.0240997775512
            ],
            [
              105.8116846519259,
              21.02410150788305
            ],
            [
              105.81165396975044,
              21.024204695418117
            ],
            [
              105.8116106898694,
              21.024393300781107
            ],
            [
              105.81157798858736,
              21.02453580549765
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D160_Nguyên Hồng",
          "maTaiSan": "04.O9C.DOPP.689",
          "duongKinh": 160,
          "chieudaiQL": 724.95,
          "chatLieu": "PVC",
          "from_latitude": 21.018206818337962,
          "to_latitude": 21.02453580549765,
          "to_longitude": 105.81157798858736,
          "from_longitude": 105.81183255389128
        }
      },
      {
        "id": 688,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81337835317228,
            21.02082085596331,
            105.81409583928595,
            21.020896794020206
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81337835317228,
              21.02082085596331
            ],
            [
              105.81339884795636,
              21.02082297176899
            ],
            [
              105.81341989907071,
              21.02082514452475
            ],
            [
              105.81342073357023,
              21.02082523085681
            ],
            [
              105.81342411391394,
              21.020825579595417
            ],
            [
              105.81356935324517,
              21.02084057132797
            ],
            [
              105.81379148872954,
              21.020863499330208
            ],
            [
              105.81379432236902,
              21.020863791967226
            ],
            [
              105.81381377765027,
              21.020865800695702
            ],
            [
              105.81404508498943,
              21.020889675322366
            ],
            [
              105.81406674442937,
              21.020891911021767
            ],
            [
              105.81407312958703,
              21.020896794020206
            ],
            [
              105.81408025811372,
              21.02089330505769
            ],
            [
              105.8140949778364,
              21.020894825191505
            ],
            [
              105.81409583928595,
              21.020894914100925
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Thành Công",
          "maTaiSan": "04.O9C.DOPP.690",
          "duongKinh": 100,
          "chieudaiQL": 75.41,
          "chatLieu": "G",
          "from_latitude": 21.02082085596331,
          "to_latitude": 21.020894914100925,
          "to_longitude": 105.81409583928595,
          "from_longitude": 105.81337835317228
        }
      },
      {
        "id": 689,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81533247173506,
            21.0204788660994,
            105.81610047634135,
            21.02292059725743
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81610047634135,
              21.02292059725743
            ],
            [
              105.8160958327902,
              21.022906273584585
            ],
            [
              105.81578495259294,
              21.02194727964366
            ],
            [
              105.81565993974498,
              21.021561647516382
            ],
            [
              105.81564365631922,
              21.021511417654263
            ],
            [
              105.8155581251872,
              21.021247569694598
            ],
            [
              105.81555046634661,
              21.021223944573208
            ],
            [
              105.81541660620813,
              21.020811008511448
            ],
            [
              105.81536745638869,
              21.020518968793823
            ],
            [
              105.81533247173506,
              21.0204788660994
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D160_Thành Công",
          "maTaiSan": "04.O9C.DOPP.691",
          "duongKinh": 160,
          "chieudaiQL": 261.81,
          "chatLieu": "PVC",
          "from_latitude": 21.02292059725743,
          "to_latitude": 21.0204788660994,
          "to_longitude": 105.81533247173506,
          "from_longitude": 105.81610047634135
        }
      },
      {
        "id": 690,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81393173621204,
            21.023424368567902,
            105.81454634912501,
            21.023488503406455
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81393173621204,
              21.023424368567902
            ],
            [
              105.81393178914952,
              21.023424373733928
            ],
            [
              105.81405753204612,
              21.0234364541416
            ],
            [
              105.81406115005534,
              21.023436801728202
            ],
            [
              105.81407789165252,
              21.023438410630362
            ],
            [
              105.81408451263684,
              21.023439046423853
            ],
            [
              105.81420843644342,
              21.023450952071215
            ],
            [
              105.8142253426276,
              21.023452576428944
            ],
            [
              105.81422861125272,
              21.023452890458735
            ],
            [
              105.81426868656034,
              21.023456740539814
            ],
            [
              105.81431737130646,
              21.023463177781558
            ],
            [
              105.81432140302033,
              21.02346371125308
            ],
            [
              105.81435242594797,
              21.023467197300768
            ],
            [
              105.81437896326706,
              21.023470180046136
            ],
            [
              105.81438513724382,
              21.02347087396832
            ],
            [
              105.81446482540387,
              21.02347968181133
            ],
            [
              105.81449891861693,
              21.02348344938767
            ],
            [
              105.81452297417314,
              21.02348610889821
            ],
            [
              105.81452814027622,
              21.023486679383723
            ],
            [
              105.81454634912501,
              21.023488503406455
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Thành Công",
          "maTaiSan": "04.O9C.DOPP.692",
          "duongKinh": 100,
          "chieudaiQL": 64.26,
          "chatLieu": "G",
          "from_latitude": 21.023424368567902,
          "to_latitude": 21.023488503406455,
          "to_longitude": 105.81454634912501,
          "from_longitude": 105.81393173621204
        }
      },
      {
        "id": 691,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8134325975884,
            21.0222699543362,
            105.81407645757868,
            21.02233727772895
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8134325975884,
              21.0222699543362
            ],
            [
              105.81344543774536,
              21.02227218712562
            ],
            [
              105.81345811600907,
              21.022273304299176
            ],
            [
              105.81346393782877,
              21.022273817488678
            ],
            [
              105.81352774012164,
              21.02227944111631
            ],
            [
              105.81360202668074,
              21.022288361026543
            ],
            [
              105.81360727194392,
              21.022288990775714
            ],
            [
              105.81373291955505,
              21.02230407784887
            ],
            [
              105.81374983613243,
              21.02230586930518
            ],
            [
              105.81375341291627,
              21.02230624780614
            ],
            [
              105.81381797985615,
              21.022313084313115
            ],
            [
              105.81382187620649,
              21.022313497412664
            ],
            [
              105.81383217823257,
              21.02231458799236
            ],
            [
              105.81388113283609,
              21.022319771303057
            ],
            [
              105.81388676752279,
              21.022320367567136
            ],
            [
              105.8139816822921,
              21.022330417892057
            ],
            [
              105.81404723258251,
              21.022335162246883
            ],
            [
              105.81407645757868,
              21.02233727772895
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Thành Công",
          "maTaiSan": "04.O9C.DOPP.693",
          "duongKinh": 100,
          "chieudaiQL": 67.46,
          "chatLieu": "G",
          "from_latitude": 21.0222699543362,
          "to_latitude": 21.02233727772895,
          "to_longitude": 105.81407645757868,
          "from_longitude": 105.8134325975884
        }
      },
      {
        "id": 692,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8153150801191,
            21.02155544013834,
            105.81565993974498,
            21.02158224457353
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8153150801191,
              21.02155544013834
            ],
            [
              105.81535823329529,
              21.021559649159318
            ],
            [
              105.81536330849785,
              21.02156014418599
            ],
            [
              105.81537332415593,
              21.02156112133646
            ],
            [
              105.8153828884077,
              21.021562054585704
            ],
            [
              105.8155898985588,
              21.02158224457353
            ],
            [
              105.81561414876154,
              21.021575113828654
            ],
            [
              105.81565960551104,
              21.02156174576449
            ],
            [
              105.81565993974498,
              21.021561647516382
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_Thành Công",
          "maTaiSan": "04.O9C.DOPP.694",
          "duongKinh": 63,
          "chieudaiQL": 36.35,
          "chatLieu": "PVC",
          "from_latitude": 21.02155544013834,
          "to_latitude": 21.021561647516382,
          "to_longitude": 105.81565993974498,
          "from_longitude": 105.8153150801191
        }
      },
      {
        "id": 693,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.814795631907,
            21.023370117429472,
            105.81533214468791,
            21.023421506898522
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.814795631907,
              21.023370117429472
            ],
            [
              105.814812852796,
              21.023371767318025
            ],
            [
              105.8148149866353,
              21.023371971160035
            ],
            [
              105.8148287011675,
              21.02337328468346
            ],
            [
              105.81500579951529,
              21.023390248672293
            ],
            [
              105.81501120678968,
              21.023390766503564
            ],
            [
              105.81501521460127,
              21.023391150137456
            ],
            [
              105.81501943416299,
              21.023391554433992
            ],
            [
              105.81503958198702,
              21.023393483822286
            ],
            [
              105.81509549695193,
              21.023398840255552
            ],
            [
              105.81519396654359,
              21.02340827219884
            ],
            [
              105.8152014229622,
              21.023408986138126
            ],
            [
              105.81520445192088,
              21.023409276007513
            ],
            [
              105.81520967824885,
              21.023409776635177
            ],
            [
              105.81533165863037,
              21.023421460454355
            ],
            [
              105.81533214468791,
              21.023421506898522
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_Thành Công",
          "maTaiSan": "04.O9C.DOPP.695",
          "duongKinh": 63,
          "chieudaiQL": 56.06,
          "chatLieu": "PVC",
          "from_latitude": 21.023370117429472,
          "to_latitude": 21.023421506898522,
          "to_longitude": 105.81533214468791,
          "from_longitude": 105.814795631907
        }
      },
      {
        "id": 694,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81387320080299,
            21.0205480474438,
            105.81418173691436,
            21.023977073316306
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.814142680053,
              21.0205480474438
            ],
            [
              105.81413969205853,
              21.02057017546517
            ],
            [
              105.81409583928595,
              21.020894914100925
            ],
            [
              105.814065175826,
              21.021121984122853
            ],
            [
              105.8140609467308,
              21.021153302099044
            ],
            [
              105.81405307787578,
              21.0212116205127
            ],
            [
              105.81402949985007,
              21.021386168257987
            ],
            [
              105.81418173691436,
              21.021407309454688
            ],
            [
              105.81418121737201,
              21.021411902143456
            ],
            [
              105.814139114579,
              21.02178381156729
            ],
            [
              105.81413807451818,
              21.021792995142555
            ],
            [
              105.81413715198222,
              21.021801145766325
            ],
            [
              105.81413608069563,
              21.021810602265692
            ],
            [
              105.81413548771556,
              21.021815843822985
            ],
            [
              105.81409618636533,
              21.02216301035344
            ],
            [
              105.81409323052331,
              21.022189116933685
            ],
            [
              105.81409020768908,
              21.022215819061316
            ],
            [
              105.81407808051362,
              21.022322938452515
            ],
            [
              105.81407645757868,
              21.02233727772895
            ],
            [
              105.81407218445548,
              21.022375019665684
            ],
            [
              105.81404639845393,
              21.02258835170597
            ],
            [
              105.81404094905497,
              21.022654843577918
            ],
            [
              105.81403795773518,
              21.022660065903573
            ],
            [
              105.81403622240268,
              21.022663094601292
            ],
            [
              105.81402359660008,
              21.022745005490446
            ],
            [
              105.81401050091435,
              21.02278909598281
            ],
            [
              105.81400144313893,
              21.022862154423546
            ],
            [
              105.81399743157417,
              21.022894566967285
            ],
            [
              105.81398886375167,
              21.022963607313304
            ],
            [
              105.81398645554108,
              21.022983033709814
            ],
            [
              105.81398621868733,
              21.02298494516914
            ],
            [
              105.81398580301888,
              21.022988295422117
            ],
            [
              105.81397810095038,
              21.023050420128385
            ],
            [
              105.81397648808834,
              21.02306342799791
            ],
            [
              105.81397188733163,
              21.023100542852028
            ],
            [
              105.81396963667008,
              21.02311868952426
            ],
            [
              105.81396940717815,
              21.023120539528854
            ],
            [
              105.81396904956799,
              21.02312342072894
            ],
            [
              105.81393173621204,
              21.023424368567902
            ],
            [
              105.81392675385324,
              21.023464556218823
            ],
            [
              105.81392663956575,
              21.023465477154353
            ],
            [
              105.81391143238301,
              21.02351331800691
            ],
            [
              105.8139033889804,
              21.023538623378354
            ],
            [
              105.8138856068204,
              21.023702239576306
            ],
            [
              105.8138842791928,
              21.0237144530459
            ],
            [
              105.8138828471174,
              21.02372762804911
            ],
            [
              105.81387697961567,
              21.02378162214918
            ],
            [
              105.8138747307077,
              21.02393070424457
            ],
            [
              105.81387453317765,
              21.02393986030106
            ],
            [
              105.81387444178624,
              21.023944113132217
            ],
            [
              105.81387391790604,
              21.023968466645336
            ],
            [
              105.81387320080299,
              21.023977073316306
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D160_Thành Công",
          "maTaiSan": "04.O9C.DOPP.696",
          "duongKinh": 160,
          "chieudaiQL": 401.67,
          "chatLieu": "PVC",
          "from_latitude": 21.0205480474438,
          "to_latitude": 21.023977073316306,
          "to_longitude": 105.81387320080299,
          "from_longitude": 105.814142680053
        }
      },
      {
        "id": 695,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81409323052331,
            21.022189116933685,
            105.81485908507346,
            21.022268988140855
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81409323052331,
              21.022189116933685
            ],
            [
              105.81409569074292,
              21.022189373416722
            ],
            [
              105.81411461306918,
              21.02219134672092
            ],
            [
              105.81412262707374,
              21.02219218268593
            ],
            [
              105.8142279208483,
              21.02220316410052
            ],
            [
              105.81423240815234,
              21.022203632168278
            ],
            [
              105.81425163271294,
              21.02220563652647
            ],
            [
              105.81442494160402,
              21.02222371130579
            ],
            [
              105.81444894031408,
              21.02222621484671
            ],
            [
              105.81458088556325,
              21.02223997500525
            ],
            [
              105.8146205061047,
              21.022244107191128
            ],
            [
              105.8146530819039,
              21.022247504463383
            ],
            [
              105.81465366904652,
              21.02224756578031
            ],
            [
              105.81468020594706,
              21.022250333518826
            ],
            [
              105.81475042718601,
              21.02225765652442
            ],
            [
              105.81481629876204,
              21.02226452524095
            ],
            [
              105.81481951649604,
              21.02226486118135
            ],
            [
              105.81484318025471,
              21.02226732923858
            ],
            [
              105.81484612270194,
              21.02226763578761
            ],
            [
              105.81485908507346,
              21.022268988140855
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Thành Công",
          "maTaiSan": "04.O9C.DOPP.697",
          "duongKinh": 100,
          "chieudaiQL": 80.1,
          "chatLieu": "G",
          "from_latitude": 21.022189116933685,
          "to_latitude": 21.022268988140855,
          "to_longitude": 105.81485908507346,
          "from_longitude": 105.81409323052331
        }
      },
      {
        "id": 696,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81403795773518,
            21.022660065903573,
            105.81450028026163,
            21.022724533296834
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81403795773518,
              21.022660065903573
            ],
            [
              105.81404048081302,
              21.0226695955214
            ],
            [
              105.8141183416435,
              21.02268565345929
            ],
            [
              105.8141789465685,
              21.0226918232389
            ],
            [
              105.8142962890314,
              21.022703768473754
            ],
            [
              105.81448631604938,
              21.022723111650247
            ],
            [
              105.81450028026163,
              21.022724533296834
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_Thành Công",
          "maTaiSan": "04.O9C.DOPP.698",
          "duongKinh": 63,
          "chieudaiQL": 48.58,
          "chatLieu": "PVC",
          "from_latitude": 21.022660065903573,
          "to_latitude": 21.022724533296834,
          "to_longitude": 105.81450028026163,
          "from_longitude": 105.81403795773518
        }
      },
      {
        "id": 697,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8152112854894,
            21.021229491431093,
            105.8155581251872,
            21.021257947822154
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8152112854894,
              21.021229491431093
            ],
            [
              105.81522376271843,
              21.021230626600055
            ],
            [
              105.8152421547752,
              21.02123240271022
            ],
            [
              105.81524865152426,
              21.021233030024277
            ],
            [
              105.81550668704683,
              21.021257947822154
            ],
            [
              105.81552431419553,
              21.021254391302463
            ],
            [
              105.81552765243752,
              21.021253717747477
            ],
            [
              105.8155581251872,
              21.021247569694598
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_Thành Công",
          "maTaiSan": "04.O9C.DOPP.699",
          "duongKinh": 63,
          "chieudaiQL": 35.56,
          "chatLieu": "PVC",
          "from_latitude": 21.021229491431093,
          "to_latitude": 21.021247569694598,
          "to_longitude": 105.8155581251872,
          "from_longitude": 105.8152112854894
        }
      },
      {
        "id": 698,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81332841496808,
            21.02114130340218,
            105.81405307787578,
            21.0212116205127
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81332841496808,
              21.02114130340218
            ],
            [
              105.81335818358804,
              21.021144192287824
            ],
            [
              105.81336411057876,
              21.02114477181798
            ],
            [
              105.81338144488743,
              21.02114645383331
            ],
            [
              105.81338590693157,
              21.021146882303196
            ],
            [
              105.81351078521318,
              21.02115900433954
            ],
            [
              105.8135237190298,
              21.021160254869617
            ],
            [
              105.81377269290795,
              21.021184555681376
            ],
            [
              105.81377462578126,
              21.02118460424051
            ],
            [
              105.81377465365337,
              21.021184599591024
            ],
            [
              105.81400782387017,
              21.021207234242343
            ],
            [
              105.81401574508197,
              21.021207998399838
            ],
            [
              105.81402963949684,
              21.0212093481652
            ],
            [
              105.81404401898037,
              21.02121074076931
            ],
            [
              105.81405202395584,
              21.021211518072626
            ],
            [
              105.81405307787578,
              21.0212116205127
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Thành Công",
          "maTaiSan": "04.O9C.DOPP.700",
          "duongKinh": 100,
          "chieudaiQL": 76.57,
          "chatLieu": "G",
          "from_latitude": 21.02114130340218,
          "to_latitude": 21.0212116205127,
          "to_longitude": 105.81405307787578,
          "from_longitude": 105.81332841496808
        }
      },
      {
        "id": 699,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81437609155154,
            21.021841020168313,
            105.81481991419227,
            21.021887515311022
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81437609155154,
              21.021841020168313
            ],
            [
              105.8143995881335,
              21.021843185605494
            ],
            [
              105.814578293586,
              21.02186219362849
            ],
            [
              105.81464414080102,
              21.021868861114026
            ],
            [
              105.81464416574805,
              21.021868849252595
            ],
            [
              105.81476415190147,
              21.021881647715475
            ],
            [
              105.81479319813627,
              21.02188469425707
            ],
            [
              105.81479323367952,
              21.02188468505453
            ],
            [
              105.81481991419227,
              21.021887515311022
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Thành Công",
          "maTaiSan": "04.O9C.DOPP.701",
          "duongKinh": 100,
          "chieudaiQL": 46.4,
          "chatLieu": "G",
          "from_latitude": 21.021841020168313,
          "to_latitude": 21.021887515311022,
          "to_longitude": 105.81481991419227,
          "from_longitude": 105.81437609155154
        }
      },
      {
        "id": 700,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81199720219264,
            21.02224400238979,
            105.8136953929907,
            21.02239172231272
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81199720219264,
              21.02224400238979
            ],
            [
              105.8120035488085,
              21.022244611575893
            ],
            [
              105.81202993625178,
              21.022247143797244
            ],
            [
              105.81214751708139,
              21.02225842822929
            ],
            [
              105.81229560901103,
              21.022269332028074
            ],
            [
              105.81231606219734,
              21.0222708375756
            ],
            [
              105.81234214330932,
              21.02227275791966
            ],
            [
              105.81270695159667,
              21.02231166312796
            ],
            [
              105.81272658740785,
              21.022313756919683
            ],
            [
              105.81274947949336,
              21.022316198246592
            ],
            [
              105.81291914717148,
              21.022332480086845
            ],
            [
              105.81292168139433,
              21.022332723587958
            ],
            [
              105.81309567354957,
              21.022349420841024
            ],
            [
              105.81311782994621,
              21.022351547698907
            ],
            [
              105.81311954413012,
              21.02235171202043
            ],
            [
              105.81312190895576,
              21.022351939167056
            ],
            [
              105.81320201518088,
              21.022348377728697
            ],
            [
              105.8134934717443,
              21.022367452020063
            ],
            [
              105.81349985773768,
              21.022367870380663
            ],
            [
              105.81350735617677,
              21.022368361097815
            ],
            [
              105.81351678407822,
              21.022368978070144
            ],
            [
              105.81366227790042,
              21.022386802789303
            ],
            [
              105.8136953929907,
              21.02239172231272
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Nguyên Hồng",
          "maTaiSan": "04.O9C.DOPP.702",
          "duongKinh": 100,
          "chieudaiQL": 175.03,
          "chatLieu": "G",
          "from_latitude": 21.02224400238979,
          "to_latitude": 21.02239172231272,
          "to_longitude": 105.8136953929907,
          "from_longitude": 105.81199720219264
        }
      },
      {
        "id": 701,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81219733514052,
            21.020700647219787,
            105.8129242711789,
            21.0207756849547
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81219733514052,
              21.020700647219787
            ],
            [
              105.8122128007716,
              21.020702243439985
            ],
            [
              105.81222936751763,
              21.0207039536269
            ],
            [
              105.8124643281282,
              21.020728207683813
            ],
            [
              105.8124837949279,
              21.020730217413668
            ],
            [
              105.81248517709332,
              21.020730359844155
            ],
            [
              105.81248762283327,
              21.02073061190427
            ],
            [
              105.81264478464405,
              21.02074683548468
            ],
            [
              105.81271145303089,
              21.02075371749015
            ],
            [
              105.81287567201711,
              21.020770668557642
            ],
            [
              105.81287806578356,
              21.020770915441098
            ],
            [
              105.81288102550882,
              21.02077122103931
            ],
            [
              105.81288559648095,
              21.020771693260293
            ],
            [
              105.8129242711789,
              21.0207756849547
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Nguyên Hồng",
          "maTaiSan": "04.O9C.DOPP.703",
          "duongKinh": 100,
          "chieudaiQL": 76.02,
          "chatLieu": "G",
          "from_latitude": 21.020700647219787,
          "to_latitude": 21.0207756849547,
          "to_longitude": 105.8129242711789,
          "from_longitude": 105.81219733514052
        }
      },
      {
        "id": 702,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81418121737201,
            21.021411902143456,
            105.81487029611677,
            21.021483937794724
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81418121737201,
              21.021411902143456
            ],
            [
              105.81418137714928,
              21.021411918539965
            ],
            [
              105.81420220808691,
              21.021414095862944
            ],
            [
              105.8142235597552,
              21.021416328497207
            ],
            [
              105.81426129068957,
              21.02142027291544
            ],
            [
              105.81427191310414,
              21.02142138361393
            ],
            [
              105.81440846541162,
              21.021435658389446
            ],
            [
              105.81442201005248,
              21.021437074829144
            ],
            [
              105.81443803222611,
              21.021438749469727
            ],
            [
              105.81444128555847,
              21.02143908976354
            ],
            [
              105.81446281625895,
              21.021441340478056
            ],
            [
              105.81448042948777,
              21.0214431818189
            ],
            [
              105.81449368055762,
              21.02144456714242
            ],
            [
              105.81458515981868,
              21.021454130499304
            ],
            [
              105.81458976646164,
              21.02145461243838
            ],
            [
              105.81462919434063,
              21.021458733809116
            ],
            [
              105.81465413719448,
              21.021461341190044
            ],
            [
              105.81467994920756,
              21.021464039243163
            ],
            [
              105.81469782713486,
              21.0214659081968
            ],
            [
              105.81477748083955,
              21.02147423466931
            ],
            [
              105.81481339648094,
              21.021477989853114
            ],
            [
              105.81483374424919,
              21.021480117025632
            ],
            [
              105.81484018065022,
              21.02148078980551
            ],
            [
              105.81484676238964,
              21.02148147724372
            ],
            [
              105.81487029611677,
              21.021483937794724
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Thành Công",
          "maTaiSan": "04.O9C.DOPP.704",
          "duongKinh": 100,
          "chieudaiQL": 72.06,
          "chatLieu": "G",
          "from_latitude": 21.021411902143456,
          "to_latitude": 21.021483937794724,
          "to_longitude": 105.81487029611677,
          "from_longitude": 105.81418121737201
        }
      },
      {
        "id": 703,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8152731962095,
            21.022830485547605,
            105.81611540701672,
            21.022908026928597
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8152731962095,
              21.022830485547605
            ],
            [
              105.81530583513953,
              21.02283361412956
            ],
            [
              105.8153189827205,
              21.022834873429208
            ],
            [
              105.81532200397204,
              21.022835163333788
            ],
            [
              105.81532594247366,
              21.022835540970437
            ],
            [
              105.81545029592988,
              21.022847459923128
            ],
            [
              105.81545450585249,
              21.022847863352283
            ],
            [
              105.81546441466729,
              21.022848813007037
            ],
            [
              105.81546839552034,
              21.022849194953448
            ],
            [
              105.8154891390632,
              21.02285118285368
            ],
            [
              105.81559433517245,
              21.02286126516298
            ],
            [
              105.8156059206442,
              21.022862375672805
            ],
            [
              105.81560836343853,
              21.022862609636427
            ],
            [
              105.81561307385685,
              21.022863061242276
            ],
            [
              105.81608224176583,
              21.022908026928597
            ],
            [
              105.8160958327902,
              21.022906273584585
            ],
            [
              105.81610654565306,
              21.022904892631
            ],
            [
              105.81611540701672,
              21.022903748174148
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D63_Thành Công",
          "maTaiSan": "04.O9C.DOPP.705",
          "duongKinh": 63,
          "chieudaiQL": 88.02,
          "chatLieu": "PVC",
          "from_latitude": 21.022830485547605,
          "to_latitude": 21.022903748174148,
          "to_longitude": 105.81611540701672,
          "from_longitude": 105.8152731962095
        }
      },
      {
        "id": 704,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.813784750592,
            21.01956583686054,
            105.81689033654291,
            21.022775513531634
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81689033654291,
              21.01956583686054
            ],
            [
              105.81683505849874,
              21.019593269581637
            ],
            [
              105.81682573436473,
              21.019597897330595
            ],
            [
              105.81680766066847,
              21.019609500265183
            ],
            [
              105.81679499259307,
              21.01961763323618
            ],
            [
              105.81669567640179,
              21.019681401037
            ],
            [
              105.81544069381212,
              21.02037136636598
            ],
            [
              105.81505101135735,
              21.02060380179521
            ],
            [
              105.81504509421002,
              21.020607331057608
            ],
            [
              105.81497387221414,
              21.02060158094882
            ],
            [
              105.81412375396357,
              21.020532939419653
            ],
            [
              105.81412179489591,
              21.020547023791
            ],
            [
              105.81411806373724,
              21.020573840410293
            ],
            [
              105.81407312958703,
              21.020896794020206
            ],
            [
              105.81403088461806,
              21.021200370447637
            ],
            [
              105.81402963949684,
              21.0212093481652
            ],
            [
              105.8140017614361,
              21.021409774082777
            ],
            [
              105.81415504202164,
              21.021430338565825
            ],
            [
              105.81411406935446,
              21.02177422897682
            ],
            [
              105.81411182995049,
              21.021793025922513
            ],
            [
              105.81411112611596,
              21.021798934592184
            ],
            [
              105.81411013522985,
              21.0218072535433
            ],
            [
              105.81404832836174,
              21.022325963050964
            ],
            [
              105.81404723258251,
              21.022335162246883
            ],
            [
              105.8140040437469,
              21.022697603763973
            ],
            [
              105.81381995195999,
              21.022686535919235
            ],
            [
              105.81378911514688,
              21.02268522836643
            ],
            [
              105.813784750592,
              21.0227688835879
            ],
            [
              105.81390758123104,
              21.022775513531634
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D225_Thành Công",
          "maTaiSan": "04.O9C.DOPP.706",
          "duongKinh": 225,
          "chieudaiQL": 601.6,
          "chatLieu": "PVC",
          "from_latitude": 21.01956583686054,
          "to_latitude": 21.022775513531634,
          "to_longitude": 105.81390758123104,
          "from_longitude": 105.81689033654291
        }
      },
      {
        "id": 705,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81323343192386,
            21.023659857368997,
            105.8138856068204,
            21.023702239576306
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81323343192386,
              21.023659857368997
            ],
            [
              105.81324515215427,
              21.02366061874823
            ],
            [
              105.8132505691196,
              21.023660971298423
            ],
            [
              105.81325815517684,
              21.02366146431544
            ],
            [
              105.81334726616507,
              21.023663722580093
            ],
            [
              105.81336005395747,
              21.02366514723427
            ],
            [
              105.81337645718172,
              21.023666975500948
            ],
            [
              105.81338263209096,
              21.023667663132386
            ],
            [
              105.81342333164449,
              21.02367219868541
            ],
            [
              105.81350630962623,
              21.02367759059096
            ],
            [
              105.81351212018406,
              21.02367796834697
            ],
            [
              105.81351416513844,
              21.023678101275408
            ],
            [
              105.81351554511876,
              21.023678190416746
            ],
            [
              105.81352031539807,
              21.023678500890192
            ],
            [
              105.81353271792024,
              21.023679305953763
            ],
            [
              105.81364256902502,
              21.023686446153555
            ],
            [
              105.81365113088648,
              21.023687002226783
            ],
            [
              105.81365676245035,
              21.023687368188956
            ],
            [
              105.81385683310295,
              21.023700369393875
            ],
            [
              105.8138856068204,
              21.023702239576306
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Thành Công",
          "maTaiSan": "04.O9C.DOPP.707",
          "duongKinh": 100,
          "chieudaiQL": 67.96,
          "chatLieu": "G",
          "from_latitude": 21.023659857368997,
          "to_latitude": 21.023702239576306,
          "to_longitude": 105.8138856068204,
          "from_longitude": 105.81323343192386
        }
      },
      {
        "id": 706,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81208613468324,
            21.021579165278627,
            105.81413608069563,
            21.021810602265692
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81208613468324,
              21.021579165278627
            ],
            [
              105.81245157602567,
              21.02161219166461
            ],
            [
              105.81247397801904,
              21.021614216275232
            ],
            [
              105.81260973741577,
              21.021626484794233
            ],
            [
              105.81264325991039,
              21.021629933030773
            ],
            [
              105.81265373208078,
              21.021631010225416
            ],
            [
              105.81284441320715,
              21.021650626738854
            ],
            [
              105.81286755690618,
              21.02165300814057
            ],
            [
              105.81300941155776,
              21.02166760102006
            ],
            [
              105.81304490626323,
              21.021671252008435
            ],
            [
              105.81311174820259,
              21.021678128498575
            ],
            [
              105.81313230177602,
              21.021680243148754
            ],
            [
              105.81313386682973,
              21.021680404570386
            ],
            [
              105.81320444429592,
              21.021687664198726
            ],
            [
              105.81325936740477,
              21.021693314128022
            ],
            [
              105.81327187819247,
              21.02169460102126
            ],
            [
              105.81327411893041,
              21.021694831469066
            ],
            [
              105.8132766609364,
              21.02169509299252
            ],
            [
              105.81353566355409,
              21.021721736113882
            ],
            [
              105.81355312938399,
              21.02172353309376
            ],
            [
              105.81373487373997,
              21.021742229409874
            ],
            [
              105.81374262007506,
              21.021743026028908
            ],
            [
              105.81390385544623,
              21.021772957978964
            ],
            [
              105.8139306934365,
              21.021777690722708
            ],
            [
              105.81394565211292,
              21.02178032882225
            ],
            [
              105.81406614288362,
              21.02180157596238
            ],
            [
              105.81407939574788,
              21.021803286470643
            ],
            [
              105.81411013522985,
              21.0218072535433
            ],
            [
              105.81413608069563,
              21.021810602265692
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Nguyên Hồng",
          "maTaiSan": "04.O9C.DOPP.708",
          "duongKinh": 100,
          "chieudaiQL": 215.27,
          "chatLieu": "G",
          "from_latitude": 21.021579165278627,
          "to_latitude": 21.021810602265692,
          "to_longitude": 105.81413608069563,
          "from_longitude": 105.81208613468324
        }
      },
      {
        "id": 707,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81184666864864,
            21.02248099839069,
            105.81198171149188,
            21.023240633382283
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81184666864864,
              21.023240633382283
            ],
            [
              105.81186733849597,
              21.02312436518981
            ],
            [
              105.81186869586583,
              21.023116726436818
            ],
            [
              105.81189023945974,
              21.022995537801922
            ],
            [
              105.81189158938615,
              21.022987946052062
            ],
            [
              105.81189292345296,
              21.022980445603896
            ],
            [
              105.81189745117663,
              21.022954975551034
            ],
            [
              105.81190917191856,
              21.02288904449723
            ],
            [
              105.81191208460993,
              21.022872662301218
            ],
            [
              105.81191484884823,
              21.022857111783253
            ],
            [
              105.81191547433765,
              21.022853595241738
            ],
            [
              105.81191620344039,
              21.022849493817272
            ],
            [
              105.81193783658927,
              21.02272780164944
            ],
            [
              105.81194006777598,
              21.02271525148832
            ],
            [
              105.8119405597535,
              21.022712483456555
            ],
            [
              105.81194223732653,
              21.022703043944677
            ],
            [
              105.81198171149188,
              21.02248099839069
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Nguyên Hồng",
          "maTaiSan": "04.O9C.DOPP.709",
          "duongKinh": 100,
          "chieudaiQL": 85.27,
          "chatLieu": "G",
          "from_latitude": 21.023240633382283,
          "to_latitude": 21.02248099839069,
          "to_longitude": 105.81198171149188,
          "from_longitude": 105.81184666864864
        }
      },
      {
        "id": 708,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81313711083327,
            21.023438756931235,
            105.81319080455823,
            21.023879327062193
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81313711083327,
              21.023879327062193
            ],
            [
              105.81314646622694,
              21.023820971781223
            ],
            [
              105.81314897609994,
              21.02380554620625
            ],
            [
              105.81315043119002,
              21.023796602717727
            ],
            [
              105.81315183415367,
              21.023787981027354
            ],
            [
              105.81316740358788,
              21.0236922999701
            ],
            [
              105.81317579899378,
              21.023658795255532
            ],
            [
              105.81317559872329,
              21.0236218695681
            ],
            [
              105.81319080455823,
              21.023438756931235
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Nguyên Hồng",
          "maTaiSan": "04.O9C.DOPP.710",
          "duongKinh": 100,
          "chieudaiQL": 48.68,
          "chatLieu": "G",
          "from_latitude": 21.023879327062193,
          "to_latitude": 21.023438756931235,
          "to_longitude": 105.81319080455823,
          "from_longitude": 105.81313711083327
        }
      },
      {
        "id": 709,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81573195169615,
            21.02171322768915,
            105.81638097442577,
            21.023915882381818
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81633997265011,
              21.023915882381818
            ],
            [
              105.81634422779705,
              21.023882712629934
            ],
            [
              105.81638097442577,
              21.023825371923294
            ],
            [
              105.81637924976677,
              21.023787245965227
            ],
            [
              105.81637747312949,
              21.023747877416337
            ],
            [
              105.81637554036033,
              21.023704921999684
            ],
            [
              105.81636289456043,
              21.023665975945413
            ],
            [
              105.81635911598705,
              21.023654338843233
            ],
            [
              105.81631720339901,
              21.023525255736793
            ],
            [
              105.81614741010503,
              21.02300231189482
            ],
            [
              105.81614559519372,
              21.022996722403192
            ],
            [
              105.81614253087409,
              21.02298728393427
            ],
            [
              105.81614211995735,
              21.022986019581893
            ],
            [
              105.81611540701672,
              21.022903748174148
            ],
            [
              105.81588409566176,
              21.022191328446212
            ],
            [
              105.8157734617907,
              21.021843673185355
            ],
            [
              105.8157698924189,
              21.021832455062338
            ],
            [
              105.81573195169615,
              21.02171322768915
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D160_Thành Công",
          "maTaiSan": "04.O9C.DOPP.711",
          "duongKinh": 160,
          "chieudaiQL": 249.65,
          "chatLieu": "PVC",
          "from_latitude": 21.023915882381818,
          "to_latitude": 21.02171322768915,
          "to_longitude": 105.81573195169615,
          "from_longitude": 105.81633997265011
        }
      },
      {
        "id": 710,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81182601941244,
            21.023282894613363,
            105.81234515677907,
            21.02335543613449
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81182601941244,
              21.023282894613363
            ],
            [
              105.81190099021391,
              21.02329196373093
            ],
            [
              105.81195803414444,
              21.023298864846232
            ],
            [
              105.81205234081739,
              21.02332638033242
            ],
            [
              105.81209061299863,
              21.023330178119075
            ],
            [
              105.81210634326067,
              21.02333173965801
            ],
            [
              105.812111855542,
              21.023332286886916
            ],
            [
              105.81234515677907,
              21.02335543613449
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Nguyên Hồng",
          "maTaiSan": "04.O9C.DOPP.712",
          "duongKinh": 100,
          "chieudaiQL": 54.62,
          "chatLieu": "G",
          "from_latitude": 21.023282894613363,
          "to_latitude": 21.02335543613449,
          "to_longitude": 105.81234515677907,
          "from_longitude": 105.81182601941244
        }
      },
      {
        "id": 711,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81257276378214,
            21.02337480696747,
            105.81391143238301,
            21.02351331800691
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81257276378214,
              21.02337480696747
            ],
            [
              105.8125891065818,
              21.02337649830942
            ],
            [
              105.81260793944509,
              21.023378446914496
            ],
            [
              105.81275460401592,
              21.023393622140347
            ],
            [
              105.81279457886149,
              21.02339775847033
            ],
            [
              105.81297735472624,
              21.023416670996752
            ],
            [
              105.81300290702211,
              21.02341931453671
            ],
            [
              105.81316575662066,
              21.023436165201957
            ],
            [
              105.81319080455823,
              21.023438756931235
            ],
            [
              105.81333541326474,
              21.023453719288437
            ],
            [
              105.81335207178498,
              21.02345544246617
            ],
            [
              105.813352112212,
              21.02345544678897
            ],
            [
              105.81335554459555,
              21.02345580160093
            ],
            [
              105.81353696244727,
              21.02347457279005
            ],
            [
              105.81354482247649,
              21.023475386032967
            ],
            [
              105.81373017423158,
              21.02349456361727
            ],
            [
              105.81374731017887,
              21.023496336858965
            ],
            [
              105.81374811292905,
              21.023496419727977
            ],
            [
              105.81375090427231,
              21.02349670895371
            ],
            [
              105.81391126008813,
              21.02351329986367
            ],
            [
              105.81391143238301,
              21.02351331800691
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Thành Công",
          "maTaiSan": "04.O9C.DOPP.713",
          "duongKinh": 100,
          "chieudaiQL": 140,
          "chatLieu": "G",
          "from_latitude": 21.02337480696747,
          "to_latitude": 21.02351331800691,
          "to_longitude": 105.81391143238301,
          "from_longitude": 105.81257276378214
        }
      },
      {
        "id": 712,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81297273111959,
            21.02166760102006,
            105.81300941155776,
            21.021983040713337
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81297273111959,
              21.021983040713337
            ],
            [
              105.81300927551557,
              21.021668768640414
            ],
            [
              105.81300941155776,
              21.02166760102006
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Nguyên Hồng",
          "maTaiSan": "04.O9C.DOPP.714",
          "duongKinh": 100,
          "chieudaiQL": 35.13,
          "chatLieu": "G",
          "from_latitude": 21.021983040713337,
          "to_latitude": 21.02166760102006,
          "to_longitude": 105.81300941155776,
          "from_longitude": 105.81297273111959
        }
      },
      {
        "id": 713,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81694235448603,
            21.02227613525532,
            105.81781406021773,
            21.02253222173316
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81694235448603,
              21.02253222173316
            ],
            [
              105.81700419375878,
              21.022520931523122
            ],
            [
              105.81702781474684,
              21.02251659727815
            ],
            [
              105.81711546292686,
              21.02250051426898
            ],
            [
              105.81715680531354,
              21.022495602609848
            ],
            [
              105.8171575936274,
              21.022495508499773
            ],
            [
              105.81715904504016,
              21.022495114945464
            ],
            [
              105.81721210853958,
              21.022480728109848
            ],
            [
              105.81730317751234,
              21.02245617797887
            ],
            [
              105.81730388068422,
              21.02245617098703
            ],
            [
              105.81730621626004,
              21.022456148925183
            ],
            [
              105.81730654752026,
              21.022456034430025
            ],
            [
              105.8173758292121,
              21.022432144438355
            ],
            [
              105.81739594925803,
              21.02242493935127
            ],
            [
              105.81744319813592,
              21.022408018837506
            ],
            [
              105.81756128754782,
              21.02236818256022
            ],
            [
              105.81757506256378,
              21.02236454769718
            ],
            [
              105.81760749435765,
              21.022355988177026
            ],
            [
              105.81764350534336,
              21.02234648383082
            ],
            [
              105.81772912350388,
              21.0223206879357
            ],
            [
              105.81774454648183,
              21.022290259043547
            ],
            [
              105.81780139436589,
              21.022278708932262
            ],
            [
              105.81780860220799,
              21.022277244467592
            ],
            [
              105.81781406021773,
              21.02227613525532
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Thành Công",
          "maTaiSan": "04.O9C.DOPP.715",
          "duongKinh": 150,
          "chieudaiQL": 95.76,
          "chatLieu": "G",
          "from_latitude": 21.02253222173316,
          "to_latitude": 21.02227613525532,
          "to_longitude": 105.81781406021773,
          "from_longitude": 105.81694235448603
        }
      },
      {
        "id": 714,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81562543183948,
            21.02135829513245,
            105.8157251998562,
            21.021644139708815
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8157251998562,
              21.021644139708815
            ],
            [
              105.81568315349206,
              21.021523672911066
            ],
            [
              105.8156756651448,
              21.021502217434325
            ],
            [
              105.81562543183948,
              21.02135829513245
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Thành Công",
          "maTaiSan": "04.O9C.DOPP.716",
          "duongKinh": 100,
          "chieudaiQL": 33.3,
          "chatLieu": "G",
          "from_latitude": 21.021644139708815,
          "to_latitude": 21.02135829513245,
          "to_longitude": 105.81562543183948,
          "from_longitude": 105.8157251998562
        }
      },
      {
        "id": 715,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81631720339901,
            21.023074566329853,
            105.81810077474874,
            21.023525255736793
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81810077474874,
              21.023074566329853
            ],
            [
              105.81791242215787,
              21.02312424735964
            ],
            [
              105.81789791999113,
              21.023148017766808
            ],
            [
              105.81789246721794,
              21.023155030446674
            ],
            [
              105.8176750923102,
              21.02322254318439
            ],
            [
              105.81766362750972,
              21.023226103724987
            ],
            [
              105.81744328040436,
              21.023275369023104
            ],
            [
              105.8174268293758,
              21.023298297016108
            ],
            [
              105.81734927877422,
              21.023318439545722
            ],
            [
              105.81723982734546,
              21.02334982605032
            ],
            [
              105.81721584491626,
              21.02335670284131
            ],
            [
              105.81719891076422,
              21.02335948033977
            ],
            [
              105.81710415676325,
              21.02337501947752
            ],
            [
              105.81705754301296,
              21.023377919677603
            ],
            [
              105.81701919897847,
              21.023366250833504
            ],
            [
              105.81693556650559,
              21.023377021608376
            ],
            [
              105.81682773497974,
              21.023404885614756
            ],
            [
              105.81679947974948,
              21.023412186610905
            ],
            [
              105.81673469619064,
              21.023428558900488
            ],
            [
              105.81674382067622,
              21.0234585826004
            ],
            [
              105.81663618444261,
              21.023491764652565
            ],
            [
              105.8165935799453,
              21.023504859164877
            ],
            [
              105.816578057554,
              21.023458810414002
            ],
            [
              105.81657426615655,
              21.023453299062577
            ],
            [
              105.81657223664426,
              21.023450348932293
            ],
            [
              105.81654508966582,
              21.02345783785633
            ],
            [
              105.81650577607226,
              21.02346933048064
            ],
            [
              105.81650227423194,
              21.023470354390092
            ],
            [
              105.8164448328969,
              21.02348520551412
            ],
            [
              105.81637489879434,
              21.023509356595756
            ],
            [
              105.8163719366385,
              21.02351391585167
            ],
            [
              105.81636872151205,
              21.023514594252774
            ],
            [
              105.81636648649359,
              21.023515066529733
            ],
            [
              105.81636208191442,
              21.023512838636634
            ],
            [
              105.81635028648675,
              21.023516110741237
            ],
            [
              105.81631791996307,
              21.023525057200512
            ],
            [
              105.81631720339901,
              21.023525255736793
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Thành Công",
          "maTaiSan": "04.O9C.DOPP.717",
          "duongKinh": 100,
          "chieudaiQL": 204.97,
          "chatLieu": "G",
          "from_latitude": 21.023074566329853,
          "to_latitude": 21.023525255736793,
          "to_longitude": 105.81631720339901,
          "from_longitude": 105.81810077474874
        }
      },
      {
        "id": 716,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8157251998562,
            21.02141353919859,
            105.81639038291269,
            21.021644139708815
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8157251998562,
              21.021644139708815
            ],
            [
              105.81573576775963,
              21.021640884376417
            ],
            [
              105.81574113430156,
              21.021639231023528
            ],
            [
              105.81576523838223,
              21.021624514753015
            ],
            [
              105.81590126490252,
              21.02158100056355
            ],
            [
              105.81590549601391,
              21.021579647104133
            ],
            [
              105.81591692558487,
              21.021575991110662
            ],
            [
              105.81601497666081,
              21.021540550490197
            ],
            [
              105.81603814740403,
              21.021533226154347
            ],
            [
              105.81604054533504,
              21.02153246768102
            ],
            [
              105.8160556339762,
              21.021527698508798
            ],
            [
              105.8161055414847,
              21.02150915786885
            ],
            [
              105.81610816334683,
              21.021508184255328
            ],
            [
              105.81617592387188,
              21.021484461023473
            ],
            [
              105.81617751099509,
              21.021483905148845
            ],
            [
              105.81618193727745,
              21.021482355649106
            ],
            [
              105.8163080871865,
              21.02144348505571
            ],
            [
              105.81631260570725,
              21.021442092271464
            ],
            [
              105.81631771005486,
              21.02144051964303
            ],
            [
              105.81639038291269,
              21.02141353919859
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Thành Công",
          "maTaiSan": "04.O9C.DOPP.718",
          "duongKinh": 100,
          "chieudaiQL": 74.41,
          "chatLieu": "G",
          "from_latitude": 21.021644139708815,
          "to_latitude": 21.02141353919859,
          "to_longitude": 105.81639038291269,
          "from_longitude": 105.8157251998562
        }
      },
      {
        "id": 717,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8172823868667,
            21.020994465671496,
            105.8179813778284,
            21.02147959428622
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8179813778284,
              21.020994465671496
            ],
            [
              105.8179764808848,
              21.020997725482612
            ],
            [
              105.81786706965411,
              21.021070541364715
            ],
            [
              105.8172823868667,
              21.02147959428622
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Láng Hạ",
          "maTaiSan": "04.O9C.DOPP.719",
          "duongKinh": 150,
          "chieudaiQL": 84.3,
          "chatLieu": "G",
          "from_latitude": 21.020994465671496,
          "to_latitude": 21.02147959428622,
          "to_longitude": 105.8172823868667,
          "from_longitude": 105.8179813778284
        }
      },
      {
        "id": 718,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81564365631922,
            21.021502217434325,
            105.8156756651448,
            21.021511417654263
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81564365631922,
              21.021511417654263
            ],
            [
              105.81565743531259,
              21.021507457766237
            ],
            [
              105.8156756651448,
              21.021502217434325
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Thành Công",
          "maTaiSan": "04.O9C.DOPP.720",
          "duongKinh": 100,
          "chieudaiQL": 3.48,
          "chatLieu": "G",
          "from_latitude": 21.021511417654263,
          "to_latitude": 21.021502217434325,
          "to_longitude": 105.8156756651448,
          "from_longitude": 105.81564365631922
        }
      },
      {
        "id": 719,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81541936354472,
            21.020048592919487,
            105.81672497583254,
            21.021093095439547
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81554037928241,
              21.021093095439547
            ],
            [
              105.8155368801866,
              21.0210822337475
            ],
            [
              105.81553634495661,
              21.021080573472364
            ],
            [
              105.81553349656136,
              21.021071731889396
            ],
            [
              105.8155325748281,
              21.021068872175146
            ],
            [
              105.81551432790012,
              21.021012235182855
            ],
            [
              105.81549447236472,
              21.020950608343625
            ],
            [
              105.81549329524674,
              21.02094695591671
            ],
            [
              105.81549162300291,
              21.020941764957865
            ],
            [
              105.81549003099464,
              21.02093682290505
            ],
            [
              105.81546515386087,
              21.02085960785281
            ],
            [
              105.81545223585017,
              21.020819513201502
            ],
            [
              105.81545097948059,
              21.020815615476295
            ],
            [
              105.81544813012415,
              21.02080677208951
            ],
            [
              105.81541936354472,
              21.020717484170433
            ],
            [
              105.81561855662326,
              21.020654217302198
            ],
            [
              105.8156505227574,
              21.02063684760514
            ],
            [
              105.8156542020078,
              21.02063484828161
            ],
            [
              105.81565740073253,
              21.020633110488497
            ],
            [
              105.8156584903111,
              21.020632518165936
            ],
            [
              105.81575477135902,
              21.02058020126443
            ],
            [
              105.81576316668183,
              21.020575639446008
            ],
            [
              105.81576698020864,
              21.02057356721796
            ],
            [
              105.81591171192187,
              21.020494923623247
            ],
            [
              105.8159149624333,
              21.02049315757696
            ],
            [
              105.81595739428393,
              21.020469869354656
            ],
            [
              105.81606715634462,
              21.020409628632176
            ],
            [
              105.8160752042004,
              21.020405211175706
            ],
            [
              105.8161177108157,
              21.020381882813837
            ],
            [
              105.81612241235095,
              21.020379302316663
            ],
            [
              105.81615386333831,
              21.020362040676925
            ],
            [
              105.81618394855427,
              21.020345528940926
            ],
            [
              105.81634241182539,
              21.020258559088475
            ],
            [
              105.8163592354655,
              21.020249325042805
            ],
            [
              105.81643382975516,
              21.020208385026898
            ],
            [
              105.81649458127136,
              21.020175042534753
            ],
            [
              105.81650262814445,
              21.020170625966596
            ],
            [
              105.81652599380669,
              21.02015780178698
            ],
            [
              105.81653636937742,
              21.020152106811103
            ],
            [
              105.81654833514345,
              21.020145539812603
            ],
            [
              105.8165536217181,
              21.02014263855945
            ],
            [
              105.81655604249342,
              21.02014131003341
            ],
            [
              105.81664198285033,
              21.020094142807615
            ],
            [
              105.81672497583254,
              21.020048592919487
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Thành Công",
          "maTaiSan": "04.O9C.DOPP.721",
          "duongKinh": 100,
          "chieudaiQL": 198.44,
          "chatLieu": "G",
          "from_latitude": 21.021093095439547,
          "to_latitude": 21.020048592919487,
          "to_longitude": 105.81672497583254,
          "from_longitude": 105.81554037928241
        }
      },
      {
        "id": 720,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81562543183948,
            21.020345528940926,
            105.81669299498633,
            21.02135829513245
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81562543183948,
              21.02135829513245
            ],
            [
              105.8158062799441,
              21.02130283226434
            ],
            [
              105.81581402247842,
              21.021300457576984
            ],
            [
              105.81586678157731,
              21.021284277011794
            ],
            [
              105.81587600981062,
              21.021281447194642
            ],
            [
              105.81594952736907,
              21.021258900441346
            ],
            [
              105.8159556199749,
              21.021257031340667
            ],
            [
              105.81603032837103,
              21.02123411935603
            ],
            [
              105.81603955659791,
              21.021231289530178
            ],
            [
              105.81604328860936,
              21.021230145301093
            ],
            [
              105.81608898411476,
              21.021216131075956
            ],
            [
              105.81609297446255,
              21.02121490702557
            ],
            [
              105.81612654333176,
              21.021204611752506
            ],
            [
              105.81613401695175,
              21.021202319631545
            ],
            [
              105.81614363796828,
              21.021199369592726
            ],
            [
              105.81617208996437,
              21.021190643381097
            ],
            [
              105.81618061807404,
              21.021188028262312
            ],
            [
              105.816218655541,
              21.021176362107674
            ],
            [
              105.81622985348565,
              21.02117292849621
            ],
            [
              105.81623452185194,
              21.021171496156352
            ],
            [
              105.81623650021994,
              21.021170889629513
            ],
            [
              105.81624588881883,
              21.0211680102488
            ],
            [
              105.81625484332872,
              21.021165263918665
            ],
            [
              105.81638404976675,
              21.021122266214554
            ],
            [
              105.81639505005536,
              21.021118221153102
            ],
            [
              105.81669299498633,
              21.02096686509136
            ],
            [
              105.81657768286453,
              21.020837502141603
            ],
            [
              105.8164793224801,
              21.02072715737765
            ],
            [
              105.81646768820002,
              21.020713519396608
            ],
            [
              105.8164519761023,
              21.02069510756164
            ],
            [
              105.81645028084509,
              21.020693115953918
            ],
            [
              105.81634257314806,
              21.020566863171624
            ],
            [
              105.8163198552158,
              21.020536466605318
            ],
            [
              105.81631382107322,
              21.020528393616168
            ],
            [
              105.81627002581699,
              21.020469797376126
            ],
            [
              105.81622883824596,
              21.02041468951893
            ],
            [
              105.8161922614501,
              21.02035834829875
            ],
            [
              105.81618394855427,
              21.020345528940926
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Thành Công",
          "maTaiSan": "04.O9C.DOPP.722",
          "duongKinh": 100,
          "chieudaiQL": 206.85,
          "chatLieu": "G",
          "from_latitude": 21.02135829513245,
          "to_latitude": 21.020345528940926,
          "to_longitude": 105.81618394855427,
          "from_longitude": 105.81562543183948
        }
      },
      {
        "id": 721,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81225647660038,
            21.019699940209463,
            105.81677158509392,
            21.020675653288233
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81677158509392,
              21.019699940209463
            ],
            [
              105.81662844023121,
              21.01978005276429
            ],
            [
              105.81660521986154,
              21.019793047872316
            ],
            [
              105.81620164400148,
              21.020018912032658
            ],
            [
              105.81533864685872,
              21.020467939061955
            ],
            [
              105.81533247173506,
              21.0204788660994
            ],
            [
              105.815221258339,
              21.020675653288233
            ],
            [
              105.81510179218193,
              21.020660301914436
            ],
            [
              105.81509243431165,
              21.020659099400127
            ],
            [
              105.81498898561814,
              21.02064580551704
            ],
            [
              105.81479093470213,
              21.02062402099977
            ],
            [
              105.81478577833646,
              21.02062345408559
            ],
            [
              105.81458792190197,
              21.02060169007603
            ],
            [
              105.81440924234607,
              21.02058203608701
            ],
            [
              105.81440559621957,
              21.020581635349988
            ],
            [
              105.81432275749586,
              21.020572522773872
            ],
            [
              105.81417307491176,
              21.02054953770588
            ],
            [
              105.814142680053,
              21.0205480474438
            ],
            [
              105.81412179489591,
              21.020547023791
            ],
            [
              105.81400952695667,
              21.02054152121047
            ],
            [
              105.81387579459336,
              21.020542762691747
            ],
            [
              105.8138508327417,
              21.020540169734286
            ],
            [
              105.81384800198431,
              21.020539875277787
            ],
            [
              105.81382902120072,
              21.02053790402378
            ],
            [
              105.81365648760624,
              21.02051998112721
            ],
            [
              105.81364325881225,
              21.020518607371216
            ],
            [
              105.81345736652293,
              21.020499296683948
            ],
            [
              105.81344238306451,
              21.02049773981929
            ],
            [
              105.81343518057426,
              21.020496992067862
            ],
            [
              105.8131979257068,
              21.02047234566837
            ],
            [
              105.81290120393582,
              21.02044152144855
            ],
            [
              105.81289749440548,
              21.020441135433632
            ],
            [
              105.81279862503285,
              21.020430864910793
            ],
            [
              105.81276018898916,
              21.020426872044744
            ],
            [
              105.81273665169277,
              21.02042442747102
            ],
            [
              105.81265992302593,
              21.020416456371642
            ],
            [
              105.81252828780646,
              21.020402780802332
            ],
            [
              105.81252620589304,
              21.02040256494031
            ],
            [
              105.81250400745833,
              21.020400259358333
            ],
            [
              105.81226612843014,
              21.02034167223122
            ],
            [
              105.81225647660038,
              21.020339294057443
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D160_Thành Công",
          "maTaiSan": "04.O9C.DOPP.723",
          "duongKinh": 160,
          "chieudaiQL": 514.92,
          "chatLieu": "PVC",
          "from_latitude": 21.019699940209463,
          "to_latitude": 21.020339294057443,
          "to_longitude": 105.81225647660038,
          "from_longitude": 105.81677158509392
        }
      },
      {
        "id": 722,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81657768286453,
            21.019793047872316,
            105.81715001491125,
            21.020837502141603
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81660521986154,
              21.019793047872316
            ],
            [
              105.81661329717711,
              21.019801998915835
            ],
            [
              105.81674392456564,
              21.01994983791853
            ],
            [
              105.81676368397144,
              21.019972200882165
            ],
            [
              105.81677458194683,
              21.019984535410796
            ],
            [
              105.81683380305431,
              21.020044663385086
            ],
            [
              105.8168988104051,
              21.020127033516776
            ],
            [
              105.81691501697205,
              21.020147569133687
            ],
            [
              105.81700357244462,
              21.020259776235438
            ],
            [
              105.8170419942105,
              21.020308458981773
            ],
            [
              105.81704346851133,
              21.020310327902816
            ],
            [
              105.81705136500202,
              21.020320332954828
            ],
            [
              105.81706894123305,
              21.020340121330186
            ],
            [
              105.81713753666413,
              21.020417311279353
            ],
            [
              105.81715001491125,
              21.020431354321403
            ],
            [
              105.81714489107107,
              21.02043499094124
            ],
            [
              105.81704422240385,
              21.020506429329423
            ],
            [
              105.81694397656123,
              21.020574272592107
            ],
            [
              105.81687097328273,
              21.02062367836378
            ],
            [
              105.81686534533202,
              21.020627487203118
            ],
            [
              105.81682198945524,
              21.02065224783017
            ],
            [
              105.81680521098764,
              21.020661830350406
            ],
            [
              105.81673450477892,
              21.020715469298935
            ],
            [
              105.81664019877957,
              21.02078701148592
            ],
            [
              105.81657768286453,
              21.020837502141603
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Láng Hạ",
          "maTaiSan": "04.O9C.DOPP.724",
          "duongKinh": 100,
          "chieudaiQL": 165.42,
          "chatLieu": "G",
          "from_latitude": 21.019793047872316,
          "to_latitude": 21.020837502141603,
          "to_longitude": 105.81657768286453,
          "from_longitude": 105.81660521986154
        }
      },
      {
        "id": 723,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81714489107107,
            21.02043499094124,
            105.81763571681648,
            21.02105750909719
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81714489107107,
              21.02043499094124
            ],
            [
              105.81716921426187,
              21.020465840327358
            ],
            [
              105.81717354710268,
              21.020471335519336
            ],
            [
              105.81726812189467,
              21.020591285818906
            ],
            [
              105.81727402108403,
              21.020598768710606
            ],
            [
              105.81727476114534,
              21.020599707220548
            ],
            [
              105.8172756348856,
              21.020600815798097
            ],
            [
              105.8173869339169,
              21.020741977437346
            ],
            [
              105.81739279242112,
              21.020749406326352
            ],
            [
              105.81739712140113,
              21.020754896111182
            ],
            [
              105.81739913817756,
              21.020757455197025
            ],
            [
              105.81741741310569,
              21.02078063450197
            ],
            [
              105.8174436069097,
              21.020813855761247
            ],
            [
              105.81748578766964,
              21.02086735349859
            ],
            [
              105.81748902981712,
              21.020871466566952
            ],
            [
              105.81749035108272,
              21.020873142025412
            ],
            [
              105.8174916936589,
              21.020874844478247
            ],
            [
              105.81761403112908,
              21.021030005304826
            ],
            [
              105.8176153465913,
              21.021031674467512
            ],
            [
              105.81761665623264,
              21.021033334625894
            ],
            [
              105.81762259226242,
              21.021040863391633
            ],
            [
              105.81763571681648,
              21.02105750909719
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Láng Hạ",
          "maTaiSan": "04.O9C.DOPP.725",
          "duongKinh": 100,
          "chieudaiQL": 85.75,
          "chatLieu": "G",
          "from_latitude": 21.02043499094124,
          "to_latitude": 21.02105750909719,
          "to_longitude": 105.81763571681648,
          "from_longitude": 105.81714489107107
        }
      },
      {
        "id": 724,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81682198945524,
            21.02065224783017,
            105.81725059345081,
            21.02119223185167
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81725059345081,
              21.02119223185167
            ],
            [
              105.81724000680995,
              21.021178269151196
            ],
            [
              105.81723434238806,
              21.021170798679826
            ],
            [
              105.8172281566719,
              21.021162639744254
            ],
            [
              105.81722730304091,
              21.02116151481191
            ],
            [
              105.8171792281344,
              21.021098108026216
            ],
            [
              105.81710863611536,
              21.02100500290545
            ],
            [
              105.816977407196,
              21.02083192373656
            ],
            [
              105.816972623564,
              21.020825615091074
            ],
            [
              105.81697174280058,
              21.0208244532553
            ],
            [
              105.8168240717144,
              21.020654644931074
            ],
            [
              105.81682198945524,
              21.02065224783017
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Láng Hạ",
          "maTaiSan": "04.O9C.DOPP.726",
          "duongKinh": 100,
          "chieudaiQL": 73.54,
          "chatLieu": "G",
          "from_latitude": 21.02119223185167,
          "to_latitude": 21.02065224783017,
          "to_longitude": 105.81682198945524,
          "from_longitude": 105.81725059345081
        }
      },
      {
        "id": 725,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81704422240385,
            21.020506429329423,
            105.81751200649828,
            21.02127371889302
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81729012938153,
              21.02127371889302
            ],
            [
              105.81729493176823,
              21.02127040265215
            ],
            [
              105.81729986544968,
              21.0212669954576
            ],
            [
              105.81730233420933,
              21.021265290947802
            ],
            [
              105.81730511539811,
              21.021263370871946
            ],
            [
              105.81731238845266,
              21.021258347401897
            ],
            [
              105.81732262287224,
              21.021251280150803
            ],
            [
              105.81736978125598,
              21.02121129349079
            ],
            [
              105.8174102078969,
              21.021182105633866
            ],
            [
              105.81741235523262,
              21.021180555312547
            ],
            [
              105.81751200649828,
              21.02110860765799
            ],
            [
              105.81740258650471,
              21.020967751410527
            ],
            [
              105.81740108103624,
              21.02096581399757
            ],
            [
              105.81739711972203,
              21.020960713543577
            ],
            [
              105.81731677953121,
              21.02085729263159
            ],
            [
              105.81731375115078,
              21.020853393500644
            ],
            [
              105.81731131178982,
              21.020850253862946
            ],
            [
              105.81727001311874,
              21.020797089649015
            ],
            [
              105.8172638352778,
              21.020789137515145
            ],
            [
              105.81723029066548,
              21.02074595462781
            ],
            [
              105.81722482294214,
              21.020738917662438
            ],
            [
              105.8172229338399,
              21.020736485316036
            ],
            [
              105.81722114259337,
              21.0207341807577
            ],
            [
              105.81713838459002,
              21.02062764439625
            ],
            [
              105.81713548701204,
              21.020623915343304
            ],
            [
              105.81713440004975,
              21.020622515143767
            ],
            [
              105.81713291783741,
              21.020620607422924
            ],
            [
              105.81704422240385,
              21.020506429329423
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Láng Hạ",
          "maTaiSan": "04.O9C.DOPP.727",
          "duongKinh": 100,
          "chieudaiQL": 112.99,
          "chatLieu": "G",
          "from_latitude": 21.02127371889302,
          "to_latitude": 21.020506429329423,
          "to_longitude": 105.81704422240385,
          "from_longitude": 105.81729012938153
        }
      },
      {
        "id": 726,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81492819282971,
            21.016843931322903,
            105.81965829136968,
            21.023386088014355
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81964288961763,
              21.023386088014355
            ],
            [
              105.81965829136968,
              21.023297279028966
            ],
            [
              105.81962542133819,
              21.023241522306346
            ],
            [
              105.81950549138908,
              21.023038089379607
            ],
            [
              105.81949181501066,
              21.023014890874542
            ],
            [
              105.8193773774433,
              21.022859697991986
            ],
            [
              105.81929795610144,
              21.022751991482036
            ],
            [
              105.81856267319571,
              21.021780496857883
            ],
            [
              105.81799598777727,
              21.021012182657913
            ],
            [
              105.8179813778284,
              21.020994465671496
            ],
            [
              105.81783149604581,
              21.020812708362673
            ],
            [
              105.8176962258858,
              21.020703785531555
            ],
            [
              105.81768243155604,
              21.02068497349796
            ],
            [
              105.81700764472741,
              21.019765080862904
            ],
            [
              105.81689745241283,
              21.01965611637285
            ],
            [
              105.81689742239097,
              21.019656079484715
            ],
            [
              105.8168886007785,
              21.01964735512626
            ],
            [
              105.81685008941454,
              21.019617326213492
            ],
            [
              105.81684189095621,
              21.019603972530355
            ],
            [
              105.81683897246306,
              21.01959940086064
            ],
            [
              105.81683505849874,
              21.019593269581637
            ],
            [
              105.81682525808269,
              21.019577920232262
            ],
            [
              105.81682211926203,
              21.01957334149116
            ],
            [
              105.81666139347048,
              21.01933898690128
            ],
            [
              105.81661564798785,
              21.019230293567073
            ],
            [
              105.81535190575869,
              21.017479689130816
            ],
            [
              105.81492819282971,
              21.016843931322903
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D160_Láng Hạ",
          "maTaiSan": "04.O9C.DOPP.728",
          "duongKinh": 160,
          "chieudaiQL": 875.06,
          "chatLieu": "HDPE",
          "from_latitude": 21.023386088014355,
          "to_latitude": 21.016843931322903,
          "to_longitude": 105.81492819282971,
          "from_longitude": 105.81964288961763
        }
      },
      {
        "id": 727,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81639038291269,
            21.021209007031455,
            105.81785775824281,
            21.022744908447518
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81639038291269,
              21.02141353919859
            ],
            [
              105.81639582933238,
              21.0214037672371
            ],
            [
              105.81640395751784,
              21.021383529356616
            ],
            [
              105.81640667767866,
              21.02137675835543
            ],
            [
              105.81643818864241,
              21.0213665939306
            ],
            [
              105.81699993944054,
              21.021211345178536
            ],
            [
              105.81700908951898,
              21.021209007031455
            ],
            [
              105.8172791663315,
              21.021476447563348
            ],
            [
              105.8172823868667,
              21.02147959428622
            ],
            [
              105.81733882363628,
              21.021534739682853
            ],
            [
              105.81744167783071,
              21.021635239717806
            ],
            [
              105.81754647079599,
              21.021747076702315
            ],
            [
              105.81755191069747,
              21.021752882690624
            ],
            [
              105.81767593624434,
              21.02186675514653
            ],
            [
              105.81772452362621,
              21.021949080104534
            ],
            [
              105.81774936567314,
              21.02198856806829
            ],
            [
              105.81776753815414,
              21.02205413083246
            ],
            [
              105.81777532959869,
              21.022082242433846
            ],
            [
              105.81778934459703,
              21.022156127643854
            ],
            [
              105.81779186629356,
              21.02216941914625
            ],
            [
              105.81780815730176,
              21.022231476494625
            ],
            [
              105.81781120370519,
              21.02224308185897
            ],
            [
              105.81781406021773,
              21.02227613525532
            ],
            [
              105.81782337017519,
              21.02238387976274
            ],
            [
              105.81782807232894,
              21.02242514725982
            ],
            [
              105.81784230119354,
              21.02262729594788
            ],
            [
              105.81784221210833,
              21.022653820531552
            ],
            [
              105.81784227171379,
              21.022654695471605
            ],
            [
              105.81784252166797,
              21.022658369499144
            ],
            [
              105.81785088728475,
              21.02271889875188
            ],
            [
              105.81785673276231,
              21.022741027697407
            ],
            [
              105.81785775824281,
              21.022744908447518
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Thành Công",
          "maTaiSan": "04.O9C.DOPP.729",
          "duongKinh": 150,
          "chieudaiQL": 261.99,
          "chatLieu": "G",
          "from_latitude": 21.02141353919859,
          "to_latitude": 21.022744908447518,
          "to_longitude": 105.81785775824281,
          "from_longitude": 105.81639038291269
        }
      },
      {
        "id": 728,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81130155571961,
            21.023386088014355,
            105.81964288961763,
            21.024906368511456
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81964288961763,
              21.023386088014355
            ],
            [
              105.81960392690564,
              21.02339220546031
            ],
            [
              105.81951831218754,
              21.02340565337728
            ],
            [
              105.81943812897478,
              21.02341824501757
            ],
            [
              105.81943201595811,
              21.023419202986744
            ],
            [
              105.81927331616464,
              21.023444127927277
            ],
            [
              105.81926486270723,
              21.0234454530357
            ],
            [
              105.81911284006344,
              21.023469328631016
            ],
            [
              105.81870672742886,
              21.023533104461897
            ],
            [
              105.81869706096674,
              21.023534619639648
            ],
            [
              105.81855640775159,
              21.023556707624653
            ],
            [
              105.81844348900471,
              21.023574273255438
            ],
            [
              105.81842808616737,
              21.023576665958625
            ],
            [
              105.81830860927543,
              21.023595248479147
            ],
            [
              105.8181954607185,
              21.02361055337318
            ],
            [
              105.818190971036,
              21.02361300826414
            ],
            [
              105.81818956207836,
              21.0236137791719
            ],
            [
              105.81812761138214,
              21.023623400291804
            ],
            [
              105.81805917762931,
              21.02363404431541
            ],
            [
              105.81805248152861,
              21.02363498426586
            ],
            [
              105.81803480791986,
              21.023637469146333
            ],
            [
              105.81785316827134,
              21.023662976399148
            ],
            [
              105.81784737477393,
              21.023663787355932
            ],
            [
              105.81769286265376,
              21.02368548785628
            ],
            [
              105.81768963089785,
              21.02368593874913
            ],
            [
              105.81753320773147,
              21.023707903906644
            ],
            [
              105.81751876584097,
              21.023709934231317
            ],
            [
              105.81722619415183,
              21.023751015443604
            ],
            [
              105.81720804661249,
              21.023753566645226
            ],
            [
              105.81719078435958,
              21.02375598804275
            ],
            [
              105.81699632817937,
              21.02382464530318
            ],
            [
              105.81668857039826,
              21.023871083329237
            ],
            [
              105.81655910506441,
              21.02389062575817
            ],
            [
              105.81642821410477,
              21.02390576718618
            ],
            [
              105.81634130756169,
              21.023915820877562
            ],
            [
              105.81633997265011,
              21.023915882381818
            ],
            [
              105.81633884546467,
              21.02391593205029
            ],
            [
              105.81633133800891,
              21.02391892434642
            ],
            [
              105.81632760875453,
              21.02392040998978
            ],
            [
              105.81620599949896,
              21.023937930958137
            ],
            [
              105.81599249291419,
              21.02396270039588
            ],
            [
              105.81468869214586,
              21.024176862879205
            ],
            [
              105.81404491287478,
              21.02430325859882
            ],
            [
              105.81393184235597,
              21.024326576449056
            ],
            [
              105.81352167800848,
              21.024411152600425
            ],
            [
              105.81348655394736,
              21.024415120486516
            ],
            [
              105.81325969909892,
              21.02444072443541
            ],
            [
              105.81322432264527,
              21.02444782315716
            ],
            [
              105.81309494394884,
              21.024473774569845
            ],
            [
              105.81308149539427,
              21.02447611138553
            ],
            [
              105.81287409348653,
              21.024512111000945
            ],
            [
              105.81283306995896,
              21.024519721666035
            ],
            [
              105.81263800072455,
              21.024555896892693
            ],
            [
              105.81258976339426,
              21.024563197839218
            ],
            [
              105.81244749857869,
              21.024584724003045
            ],
            [
              105.81237134477468,
              21.02459624354383
            ],
            [
              105.81228861877365,
              21.02460850799069
            ],
            [
              105.81214893813507,
              21.024629209562963
            ],
            [
              105.81189380018286,
              21.024681330942474
            ],
            [
              105.81187416871933,
              21.024685602990033
            ],
            [
              105.81169664461736,
              21.024724212492494
            ],
            [
              105.8116837635852,
              21.024727969073496
            ],
            [
              105.81152764947524,
              21.024773479844338
            ],
            [
              105.81139462530795,
              21.024812258753066
            ],
            [
              105.8113374230862,
              21.024875758486274
            ],
            [
              105.81130985523043,
              21.024906368511456
            ],
            [
              105.81130155571961,
              21.024901329255407
            ],
            [
              105.81130648000877,
              21.024896494271076
            ],
            [
              105.811319300536,
              21.024883507924258
            ],
            [
              105.8113250745342,
              21.024880143856446
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D160_La Thành",
          "maTaiSan": "04.O9C.DOPP.730",
          "duongKinh": 160,
          "chieudaiQL": 884.64,
          "chatLieu": "HDPE",
          "from_latitude": 21.023386088014355,
          "to_latitude": 21.024880143856446,
          "to_longitude": 105.8113250745342,
          "from_longitude": 105.81964288961763
        }
      },
      {
        "id": 729,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8138842791928,
            21.0236024618769,
            105.81465815519574,
            21.02378949981879
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8138842791928,
              21.0237144530459
            ],
            [
              105.81388462569711,
              21.023714487516838
            ],
            [
              105.81391129604229,
              21.0237171589874
            ],
            [
              105.81393322599779,
              21.02371935546166
            ],
            [
              105.81402471599884,
              21.0237285188989
            ],
            [
              105.81404359093193,
              21.023730409335215
            ],
            [
              105.81404782600087,
              21.023730833452444
            ],
            [
              105.81417207813045,
              21.023743278580902
            ],
            [
              105.8141908982051,
              21.023745163844016
            ],
            [
              105.81423381005293,
              21.023749461900966
            ],
            [
              105.81423786513182,
              21.02374986790735
            ],
            [
              105.81424436885374,
              21.02375051961043
            ],
            [
              105.81427240983123,
              21.023753328075156
            ],
            [
              105.8143221978978,
              21.02375831486676
            ],
            [
              105.81434033170035,
              21.023760131140914
            ],
            [
              105.81446712009756,
              21.023772829457222
            ],
            [
              105.81448189279904,
              21.023774309553367
            ],
            [
              105.81462525190116,
              21.023788668009637
            ],
            [
              105.81462999710521,
              21.023789142959217
            ],
            [
              105.81463356226469,
              21.02378949981879
            ],
            [
              105.8146546585424,
              21.02362905337441
            ],
            [
              105.81465492552834,
              21.023627023447414
            ],
            [
              105.81465512415558,
              21.023625514106698
            ],
            [
              105.8146560036123,
              21.023618822400465
            ],
            [
              105.81465815519574,
              21.0236024618769
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Thành Công",
          "maTaiSan": "04.O9C.DOPP.731",
          "duongKinh": 100,
          "chieudaiQL": 99.16,
          "chatLieu": "G",
          "from_latitude": 21.0237144530459,
          "to_latitude": 21.0236024618769,
          "to_longitude": 105.81465815519574,
          "from_longitude": 105.8138842791928
        }
      },
      {
        "id": 730,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80840729010968,
            21.019406164767556,
            105.80941549241741,
            21.019871392568525
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80840729010968,
              21.019871392568525
            ],
            [
              105.80853677745988,
              21.019813519666762
            ],
            [
              105.80923614606932,
              21.019489519855853
            ],
            [
              105.80930952318816,
              21.019455525502924
            ],
            [
              105.80941549241741,
              21.019406164767556
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D160_Huỳnh Thúc Kháng",
          "maTaiSan": "04.O9C.DOPP.732",
          "duongKinh": 160,
          "chieudaiQL": 117.79,
          "chatLieu": "PVC",
          "from_latitude": 21.019871392568525,
          "to_latitude": 21.019406164767556,
          "to_longitude": 105.80941549241741,
          "from_longitude": 105.80840729010968
        }
      },
      {
        "id": 731,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8099527206709,
            21.018440655181923,
            105.81180959569653,
            21.019889264965293
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81136151137764,
              21.018440655181923
            ],
            [
              105.81139472308024,
              21.018506100043655
            ],
            [
              105.81140623641647,
              21.01852879292922
            ],
            [
              105.81142198730504,
              21.018559863816694
            ],
            [
              105.81143723163541,
              21.018601851728224
            ],
            [
              105.8114443682505,
              21.018621555931514
            ],
            [
              105.81147737225274,
              21.01867740135209
            ],
            [
              105.81152382060486,
              21.01875599455847
            ],
            [
              105.81152569143975,
              21.018759161383638
            ],
            [
              105.81152740693298,
              21.018762064304017
            ],
            [
              105.81152954862624,
              21.01876568777366
            ],
            [
              105.81155926053668,
              21.018815962414955
            ],
            [
              105.81156399537643,
              21.01882397404014
            ],
            [
              105.811574460075,
              21.018841661708503
            ],
            [
              105.81157811726904,
              21.01884784943759
            ],
            [
              105.81158309384237,
              21.018856268167994
            ],
            [
              105.81158805004455,
              21.018864655382416
            ],
            [
              105.81171507828911,
              21.019079577572775
            ],
            [
              105.81171892599039,
              21.019086125680527
            ],
            [
              105.81178644497739,
              21.019200351766603
            ],
            [
              105.8117896760944,
              21.01920583881232
            ],
            [
              105.8117989714718,
              21.019222173589895
            ],
            [
              105.81180959569653,
              21.01924159829092
            ],
            [
              105.81176289290217,
              21.01926462587062
            ],
            [
              105.8117628679553,
              21.01926463773163
            ],
            [
              105.81176160634438,
              21.01926526065695
            ],
            [
              105.81176158139759,
              21.019265272517966
            ],
            [
              105.81170756025408,
              21.019291908176907
            ],
            [
              105.811428724786,
              21.019420000237634
            ],
            [
              105.81142081208628,
              21.01942363463679
            ],
            [
              105.81134394682977,
              21.019458945366534
            ],
            [
              105.81126641075932,
              21.019499537726855
            ],
            [
              105.81126638581236,
              21.019499549587792
            ],
            [
              105.81126172962166,
              21.019501987929463
            ],
            [
              105.81110061214825,
              21.01958633647367
            ],
            [
              105.81110058720127,
              21.019586348334602
            ],
            [
              105.81109636938127,
              21.019588556968092
            ],
            [
              105.811076124409,
              21.01959915623704
            ],
            [
              105.81110065031297,
              21.01964568289692
            ],
            [
              105.8111125436144,
              21.019669075794685
            ],
            [
              105.81067425264584,
              21.019889264965293
            ],
            [
              105.81058149219415,
              21.019735006608755
            ],
            [
              105.81057083137567,
              21.019717866267214
            ],
            [
              105.81045607972008,
              21.01976529691017
            ],
            [
              105.8104439148837,
              21.019768360812975
            ],
            [
              105.81044388993664,
              21.019768372673788
            ],
            [
              105.81044057178917,
              21.019769203194787
            ],
            [
              105.81044054684212,
              21.019769215055625
            ],
            [
              105.81041776287368,
              21.01977495433164
            ],
            [
              105.81038132634674,
              21.01977440533973
            ],
            [
              105.81037234564906,
              21.01977427018835
            ],
            [
              105.81020006631755,
              21.01977167483627
            ],
            [
              105.81015833258705,
              21.019765849077086
            ],
            [
              105.81007186495964,
              21.019810865003898
            ],
            [
              105.81006406129274,
              21.019814926952208
            ],
            [
              105.81005235340132,
              21.019821022595096
            ],
            [
              105.8100523284542,
              21.01982103445586
            ],
            [
              105.8100495897369,
              21.019822460147115
            ],
            [
              105.81004765009342,
              21.019823470160922
            ],
            [
              105.8099527206709,
              21.019872891627163
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Huỳnh Thúc Kháng",
          "maTaiSan": "04.O9C.DOPP.733",
          "duongKinh": 100,
          "chieudaiQL": 334.73,
          "chatLieu": "G",
          "from_latitude": 21.018440655181923,
          "to_latitude": 21.019872891627163,
          "to_longitude": 105.8099527206709,
          "from_longitude": 105.81136151137764
        }
      },
      {
        "id": 732,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81400144313893,
            21.022862154423546,
            105.81535707013255,
            21.02378652500099
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81530037045128,
              21.02378652500099
            ],
            [
              105.81533214468791,
              21.023421506898522
            ],
            [
              105.81535706191693,
              21.023135239697144
            ],
            [
              105.81535707013255,
              21.02313515836732
            ],
            [
              105.81524511953054,
              21.023125394615903
            ],
            [
              105.81524249205503,
              21.023125165145313
            ],
            [
              105.81524163162781,
              21.023125089785594
            ],
            [
              105.81522306214586,
              21.02312345633931
            ],
            [
              105.81515882256907,
              21.023117807455876
            ],
            [
              105.8151555309955,
              21.023117517940918
            ],
            [
              105.81505193017738,
              21.02310840772115
            ],
            [
              105.81504846632437,
              21.023108102771612
            ],
            [
              105.8150341980289,
              21.023106847919003
            ],
            [
              105.81486305646678,
              21.023091798252413
            ],
            [
              105.81485876779978,
              21.023091421379643
            ],
            [
              105.81484225411333,
              21.02308996862306
            ],
            [
              105.81472826947586,
              21.02307994511263
            ],
            [
              105.8146747651373,
              21.023054183041285
            ],
            [
              105.81447679640269,
              21.0230330563104
            ],
            [
              105.81444838782848,
              21.023030024732204
            ],
            [
              105.81422434379644,
              21.023006115982298
            ],
            [
              105.81423808428671,
              21.022890340465757
            ],
            [
              105.81403217732732,
              21.02286581533511
            ],
            [
              105.81400144313893,
              21.022862154423546
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D90_Thành Công",
          "maTaiSan": "04.O9C.DOPP.734",
          "duongKinh": 90,
          "chieudaiQL": 228.97,
          "chatLieu": "PVC",
          "from_latitude": 21.02378652500099,
          "to_latitude": 21.022862154423546,
          "to_longitude": 105.81400144313893,
          "from_longitude": 105.81530037045128
        }
      },
      {
        "id": 733,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81587107267572,
            21.021534739682853,
            105.81733882363628,
            21.02204305573753
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81587107267572,
              21.02204305573753
            ],
            [
              105.81587345801697,
              21.022042280165422
            ],
            [
              105.81587452104957,
              21.022041934549655
            ],
            [
              105.81588398367617,
              21.022038858832136
            ],
            [
              105.81588634693158,
              21.02203809059161
            ],
            [
              105.81595914403225,
              21.022014428183407
            ],
            [
              105.81601643040541,
              21.021995942489788
            ],
            [
              105.81627238022953,
              21.02184863814528
            ],
            [
              105.81642529796882,
              21.021769040746587
            ],
            [
              105.81646287367647,
              21.021774310451207
            ],
            [
              105.81647983285822,
              21.021776689433064
            ],
            [
              105.81655295516563,
              21.02184158210673
            ],
            [
              105.81655613029996,
              21.02183921937668
            ],
            [
              105.81665503375521,
              21.02176563734238
            ],
            [
              105.81665558849332,
              21.021765224613844
            ],
            [
              105.81666856112957,
              21.02175557366952
            ],
            [
              105.816755002515,
              21.02169126252323
            ],
            [
              105.81675947682227,
              21.021687933420708
            ],
            [
              105.81682449320195,
              21.02163956230261
            ],
            [
              105.81687319030692,
              21.02160333213236
            ],
            [
              105.81695114663867,
              21.021545334551362
            ],
            [
              105.81708852719443,
              21.02157367455862
            ],
            [
              105.81709293994116,
              21.021574585490566
            ],
            [
              105.81709768012583,
              21.02157556349465
            ],
            [
              105.81712702168996,
              21.021581615964973
            ],
            [
              105.8171883109955,
              21.02159092823653
            ],
            [
              105.81728718553097,
              21.021578490833384
            ],
            [
              105.81732198789031,
              21.021549003051984
            ],
            [
              105.8173278136574,
              21.021544066922793
            ],
            [
              105.81733882363628,
              21.021534739682853
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Thành Công",
          "maTaiSan": "04.O9C.DOPP.735",
          "duongKinh": 100,
          "chieudaiQL": 178.04,
          "chatLieu": "G",
          "from_latitude": 21.02204305573753,
          "to_latitude": 21.021534739682853,
          "to_longitude": 105.81733882363628,
          "from_longitude": 105.81587107267572
        }
      },
      {
        "id": 734,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81587107267572,
            21.02204305573753,
            105.81610600614897,
            21.02273737371282
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81610600614897,
              21.02273737371282
            ],
            [
              105.81610586329883,
              21.022736932720246
            ],
            [
              105.81606702956094,
              21.02261739360352
            ],
            [
              105.81606242843692,
              21.022602378756684
            ],
            [
              105.81593935919174,
              21.02227044977733
            ],
            [
              105.81589274215324,
              21.022106150591664
            ],
            [
              105.81587107267572,
              21.02204305573753
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Thành Công",
          "maTaiSan": "04.O9C.DOPP.736",
          "duongKinh": 150,
          "chieudaiQL": 80.71,
          "chatLieu": "G",
          "from_latitude": 21.02273737371282,
          "to_latitude": 21.02204305573753,
          "to_longitude": 105.81587107267572,
          "from_longitude": 105.81610600614897
        }
      },
      {
        "id": 735,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81785775824281,
            21.022744908447518,
            105.818190971036,
            21.02361300826414
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81785775824281,
              21.022744908447518
            ],
            [
              105.81786074726334,
              21.02274510904244
            ],
            [
              105.81786685233244,
              21.022745518653583
            ],
            [
              105.81792342634881,
              21.02275251034849
            ],
            [
              105.81795776012595,
              21.022756837883907
            ],
            [
              105.81797920227413,
              21.022767854386757
            ],
            [
              105.81798107859345,
              21.02276881813805
            ],
            [
              105.81798700101736,
              21.0227718967548
            ],
            [
              105.81800809797778,
              21.022822793274106
            ],
            [
              105.81802436258127,
              21.022869400124623
            ],
            [
              105.81802708133118,
              21.02287719092672
            ],
            [
              105.81807450997731,
              21.023013100737174
            ],
            [
              105.81807858419367,
              21.02302477431233
            ],
            [
              105.8180977419913,
              21.02306776155672
            ],
            [
              105.81810077474874,
              21.023074566329853
            ],
            [
              105.81810545054059,
              21.023125792916638
            ],
            [
              105.81811499838723,
              21.02318328975041
            ],
            [
              105.81811545595095,
              21.02318604780992
            ],
            [
              105.81811702949842,
              21.023195519603995
            ],
            [
              105.8181484076569,
              21.023364768811437
            ],
            [
              105.81814928198426,
              21.02337038809421
            ],
            [
              105.81815575108524,
              21.023495505338293
            ],
            [
              105.81816157602475,
              21.023527783021663
            ],
            [
              105.81816211567215,
              21.023530774621705
            ],
            [
              105.81817212693458,
              21.023554013448873
            ],
            [
              105.81818678112204,
              21.02360922493361
            ],
            [
              105.818190971036,
              21.02361300826414
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Thành Công",
          "maTaiSan": "04.O9C.DOPP.737",
          "duongKinh": 100,
          "chieudaiQL": 111.97,
          "chatLieu": "G",
          "from_latitude": 21.022744908447518,
          "to_latitude": 21.02361300826414,
          "to_longitude": 105.818190971036,
          "from_longitude": 105.81785775824281
        }
      },
      {
        "id": 736,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80938456603242,
            21.01295177488278,
            105.81041511007578,
            21.01387631732382
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80938456603242,
              21.01295177488278
            ],
            [
              105.80939295613813,
              21.012960905456325
            ],
            [
              105.80947171634408,
              21.013054814343732
            ],
            [
              105.80957361963578,
              21.013177069306753
            ],
            [
              105.80958745445122,
              21.013193007794207
            ],
            [
              105.80963842956032,
              21.013261409740984
            ],
            [
              105.80964974972353,
              21.013276599669954
            ],
            [
              105.80959380193582,
              21.013323787739296
            ],
            [
              105.80958848456578,
              21.013328273185735
            ],
            [
              105.80966132128826,
              21.013400390854407
            ],
            [
              105.80966243634771,
              21.013401494713438
            ],
            [
              105.80966465969927,
              21.013403696141076
            ],
            [
              105.80966849616637,
              21.013407494930302
            ],
            [
              105.80970525546103,
              21.013443891741957
            ],
            [
              105.80971794656641,
              21.0134555498384
            ],
            [
              105.80981382016564,
              21.01354362082673
            ],
            [
              105.80990444423419,
              21.01362253090709
            ],
            [
              105.80999423217817,
              21.01354639482827
            ],
            [
              105.81005541570252,
              21.013602885030675
            ],
            [
              105.81010360959024,
              21.013666893212644
            ],
            [
              105.81014164218296,
              21.0136428226209
            ],
            [
              105.81017118015652,
              21.013624128746585
            ],
            [
              105.81026201874194,
              21.013731135252183
            ],
            [
              105.81027299589773,
              21.013744065987705
            ],
            [
              105.81034515967484,
              21.013793011503793
            ],
            [
              105.81041053779482,
              21.013870933277754
            ],
            [
              105.81041511007578,
              21.01387631732382
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D110_Ngách 7, Ngõ Láng Trung, Láng Hạ",
          "maTaiSan": "04.O9B.DOPP.738",
          "duongKinh": 110,
          "chieudaiQL": 541.96,
          "chatLieu": "HDPE",
          "from_latitude": 21.01295177488278,
          "to_latitude": 21.01387631732382,
          "to_longitude": 105.81041511007578,
          "from_longitude": 105.80938456603242
        }
      },
      {
        "id": 737,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80902342552133,
            21.015753126704023,
            105.80956946456276,
            21.016729493693962
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80902342552133,
              21.015753126704023
            ],
            [
              105.80905360015011,
              21.015804211642596
            ],
            [
              105.80911999469923,
              21.01591661613955
            ],
            [
              105.8091595175931,
              21.01597715766046
            ],
            [
              105.80916083262731,
              21.015979843027836
            ],
            [
              105.80918799207588,
              21.01603528500376
            ],
            [
              105.8092458971437,
              21.016141351220273
            ],
            [
              105.80925648020049,
              21.01616073745985
            ],
            [
              105.80927038634803,
              21.016184144420635
            ],
            [
              105.80927075232516,
              21.016184760488223
            ],
            [
              105.809330217117,
              21.016311382157987
            ],
            [
              105.80939836854529,
              21.016432506972528
            ],
            [
              105.80943993457973,
              21.01648746938871
            ],
            [
              105.8094408350471,
              21.016488911173536
            ],
            [
              105.80944480468756,
              21.016495269984304
            ],
            [
              105.80946170400794,
              21.016522333406964
            ],
            [
              105.80956946456276,
              21.016729493693962
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D110_Ngõ 49, Huỳnh Thúc Kháng",
          "maTaiSan": "04.O9B.DOPP.739",
          "duongKinh": 110,
          "chieudaiQL": 122.14,
          "chatLieu": "HDPE",
          "from_latitude": 21.015753126704023,
          "to_latitude": 21.016729493693962,
          "to_longitude": 105.80956946456276,
          "from_longitude": 105.80902342552133
        }
      },
      {
        "id": 738,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81892698727623,
            21.013926148658715,
            105.81914300693325,
            21.014094889881534
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81914300693325,
              21.013926148658715
            ],
            [
              105.81899731676235,
              21.014042725067174
            ],
            [
              105.81895913655988,
              21.01407104348048
            ],
            [
              105.81895608517323,
              21.0140733072044
            ],
            [
              105.81892698727623,
              21.014094889881534
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D200_Thái Hà",
          "maTaiSan": "04.O12.DOPP.740",
          "duongKinh": 200,
          "chieudaiQL": 35.01,
          "chatLieu": "G-CI",
          "from_latitude": 21.013926148658715,
          "to_latitude": 21.014094889881534,
          "to_longitude": 105.81892698727623,
          "from_longitude": 105.81914300693325
        }
      },
      {
        "id": 739,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81872751459288,
            21.01386819772529,
            105.8188205157476,
            21.01397657914379
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81872751459288,
              21.01386819772529
            ],
            [
              105.81875349804633,
              21.013898483454586
            ],
            [
              105.81875863567369,
              21.013904469709825
            ],
            [
              105.81875871602435,
              21.0139045632588
            ],
            [
              105.8188205157476,
              21.01397657914379
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D200_Thái Hà",
          "maTaiSan": "04.O12.DOPP.741",
          "duongKinh": 200,
          "chieudaiQL": 15.4,
          "chatLieu": "G-CI",
          "from_latitude": 21.01386819772529,
          "to_latitude": 21.01397657914379,
          "to_longitude": 105.8188205157476,
          "from_longitude": 105.81872751459288
        }
      },
      {
        "id": 740,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81882606830597,
            21.01397258183353,
            105.81892698727623,
            21.014094889881534
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81892698727623,
              21.014094889881534
            ],
            [
              105.81889410936101,
              21.014055044186712
            ],
            [
              105.81884472328164,
              21.01399519106101
            ],
            [
              105.81882606830597,
              21.01397258183353
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D200_Hoàng Cầu",
          "maTaiSan": "04.O12.DOPP.742",
          "duongKinh": 200,
          "chieudaiQL": 17.13,
          "chatLieu": "G-CI",
          "from_latitude": 21.014094889881534,
          "to_latitude": 21.01397258183353,
          "to_longitude": 105.81882606830597,
          "from_longitude": 105.81892698727623
        }
      },
      {
        "id": 741,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82692868183196,
            21.013628231874566,
            105.82698995753283,
            21.013663636291053
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82692868183196,
              21.013663636291053
            ],
            [
              105.8269358211909,
              21.013659962494042
            ],
            [
              105.82698995753283,
              21.013628231874566
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D200_Nguyễn Lương Bằng",
          "maTaiSan": "04.O12.DOPP.743",
          "duongKinh": 200,
          "chieudaiQL": 7.48,
          "chatLieu": "G-DI",
          "from_latitude": 21.013663636291053,
          "to_latitude": 21.013628231874566,
          "to_longitude": 105.82698995753283,
          "from_longitude": 105.82692868183196
        }
      },
      {
        "id": 742,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82173829990585,
            21.022843055477917,
            105.82182046204166,
            21.022881305731627
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82173829990585,
              21.022843055477917
            ],
            [
              105.82174300442824,
              21.022861034946082
            ],
            [
              105.82174585477144,
              21.02287191408053
            ],
            [
              105.8217480058608,
              21.022876845237114
            ],
            [
              105.82179657632746,
              21.02286715920519
            ],
            [
              105.82181349682101,
              21.022863146608653
            ],
            [
              105.82182046204166,
              21.022881305731627
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Nguyễn Phúc Lai",
          "maTaiSan": "04.O12.DOPP.744",
          "duongKinh": 150,
          "chieudaiQL": 12.99,
          "chatLieu": "G-CI",
          "from_latitude": 21.022843055477917,
          "to_latitude": 21.022881305731627,
          "to_longitude": 105.82182046204166,
          "from_longitude": 105.82173829990585
        }
      },
      {
        "id": 743,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83585940123199,
            21.012961665376338,
            105.83592744868992,
            21.013036281186974
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83592744868992,
              21.01296395823332
            ],
            [
              105.83592552265603,
              21.012961665376338
            ],
            [
              105.83591662062577,
              21.012968148199995
            ],
            [
              105.83590346724867,
              21.01297883474276
            ],
            [
              105.83585940123199,
              21.013016382404373
            ],
            [
              105.83587301520689,
              21.013030007505947
            ],
            [
              105.8358792901368,
              21.013036281186974
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Kim Hoa",
          "maTaiSan": "04.O16A.DOPP.745",
          "duongKinh": 150,
          "chieudaiQL": 12.51,
          "chatLieu": "G",
          "from_latitude": 21.01296395823332,
          "to_latitude": 21.013036281186974,
          "to_longitude": 105.8358792901368,
          "from_longitude": 105.83592744868992
        }
      },
      {
        "id": 744,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80217572593389,
            21.01947674542304,
            105.80227102679511,
            21.019547005308617
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80217572593389,
              21.01947674542304
            ],
            [
              105.80219477033532,
              21.01948490119599
            ],
            [
              105.80227102679511,
              21.019547005308617
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D160_Đường Láng",
          "maTaiSan": "04.O9A.DOPP.746",
          "duongKinh": 160,
          "chieudaiQL": 12.66,
          "chatLieu": "HDPE",
          "from_latitude": 21.01947674542304,
          "to_latitude": 21.019547005308617,
          "to_longitude": 105.80227102679511,
          "from_longitude": 105.80217572593389
        }
      },
      {
        "id": 745,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80472730739258,
            21.017546995016787,
            105.80507253684787,
            21.017783983935765
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80472730739258,
              21.017546995016787
            ],
            [
              105.80473332073733,
              21.01755168775111
            ],
            [
              105.80476874012004,
              21.017574497449967
            ],
            [
              105.8049140377087,
              21.01767274797055
            ],
            [
              105.80493603135945,
              21.017689325648153
            ],
            [
              105.80498870951428,
              21.017729032540437
            ],
            [
              105.80506645992502,
              21.01777999513065
            ],
            [
              105.80507253684787,
              21.017783983935765
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D90_Ngõ 157, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DOPP.747",
          "duongKinh": 90,
          "chieudaiQL": 44.47,
          "chatLieu": "HDPE",
          "from_latitude": 21.017546995016787,
          "to_latitude": 21.017783983935765,
          "to_longitude": 105.80507253684787,
          "from_longitude": 105.80472730739258
        }
      },
      {
        "id": 746,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80545458883945,
            21.016879115541446,
            105.80617279945204,
            21.01775187209994
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80617279945204,
              21.016879115541446
            ],
            [
              105.8061556634086,
              21.016901511023704
            ],
            [
              105.80615469125,
              21.016920372347485
            ],
            [
              105.80606141926611,
              21.017030745440696
            ],
            [
              105.80599685611355,
              21.017104871364616
            ],
            [
              105.80598634144077,
              21.01711694225907
            ],
            [
              105.80588152930123,
              21.01723960597902
            ],
            [
              105.80587510997043,
              21.017247083513585
            ],
            [
              105.80585660750208,
              21.01726260734925
            ],
            [
              105.80582918487295,
              21.01729031103164
            ],
            [
              105.8058190728832,
              21.017300526582314
            ],
            [
              105.80579404325367,
              21.017331571711125
            ],
            [
              105.80574955788522,
              21.017386746628425
            ],
            [
              105.80570197518446,
              21.01744410576079
            ],
            [
              105.80566930634016,
              21.017482975598366
            ],
            [
              105.80564304064194,
              21.017514218947795
            ],
            [
              105.80564299284627,
              21.017514275174364
            ],
            [
              105.80563490924618,
              21.017523889493322
            ],
            [
              105.80557233360936,
              21.01759918998468
            ],
            [
              105.80545458883945,
              21.01775187209994
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D90_Nguyễn Chí Thanh",
          "maTaiSan": "04.O9A.DOPP.748",
          "duongKinh": 90,
          "chieudaiQL": 44.47,
          "chatLieu": "HDPE",
          "from_latitude": 21.016879115541446,
          "to_latitude": 21.01775187209994,
          "to_longitude": 105.80545458883945,
          "from_longitude": 105.80617279945204
        }
      },
      {
        "id": 747,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80353824152591,
            21.01911766857124,
            105.80490583580325,
            21.019867055818057
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80353824152591,
              21.01911766857124
            ],
            [
              105.80354654725774,
              21.0191225401955
            ],
            [
              105.8035618135898,
              21.019142639
            ],
            [
              105.803681056424,
              21.019214008861056
            ],
            [
              105.80375559658535,
              21.01925500019254
            ],
            [
              105.80382579263413,
              21.019299417192816
            ],
            [
              105.80382609571971,
              21.019299609051696
            ],
            [
              105.80382759511807,
              21.019299910870785
            ],
            [
              105.803828994368,
              21.019300193292086
            ],
            [
              105.80394335919333,
              21.019351621030133
            ],
            [
              105.80415473049052,
              21.019446064360753
            ],
            [
              105.80415664940014,
              21.019446921486175
            ],
            [
              105.8042297799739,
              21.019472563464372
            ],
            [
              105.8044073397461,
              21.01958697323801
            ],
            [
              105.80447605127925,
              21.019636847967256
            ],
            [
              105.8044766442333,
              21.01963727780979
            ],
            [
              105.80447882997593,
              21.01963838205752
            ],
            [
              105.80449032394372,
              21.019644190559053
            ],
            [
              105.80463912907662,
              21.01972760717716
            ],
            [
              105.80481371596007,
              21.01982390366285
            ],
            [
              105.80490583580325,
              21.019867055818057
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D90_Ngõ 102, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DOPP.749",
          "duongKinh": 90,
          "chieudaiQL": 44.47,
          "chatLieu": "HDPE",
          "from_latitude": 21.01911766857124,
          "to_latitude": 21.019867055818057,
          "to_longitude": 105.80490583580325,
          "from_longitude": 105.80353824152591
        }
      },
      {
        "id": 748,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8032888850537,
            21.01951225510453,
            105.80450333634742,
            21.020183721760304
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8032888850537,
              21.01951225510453
            ],
            [
              105.80329134579229,
              21.019513696780265
            ],
            [
              105.80331190692172,
              21.01953148362515
            ],
            [
              105.80353843764348,
              21.01967545697516
            ],
            [
              105.8035386750947,
              21.019675607596003
            ],
            [
              105.80354000587973,
              21.0196772948647
            ],
            [
              105.80354028966511,
              21.019677654815904
            ],
            [
              105.80364095863264,
              21.019735449277945
            ],
            [
              105.80368731087768,
              21.01976879699557
            ],
            [
              105.80369331458817,
              21.019773116775703
            ],
            [
              105.80374143789187,
              21.019797499687723
            ],
            [
              105.80376247749165,
              21.019808159541185
            ],
            [
              105.80386613128424,
              21.019849560599912
            ],
            [
              105.80390989037753,
              21.019871287818482
            ],
            [
              105.80395664852547,
              21.019898506936702
            ],
            [
              105.80403205987717,
              21.019942404219606
            ],
            [
              105.8040913220945,
              21.019972916078576
            ],
            [
              105.8040950629416,
              21.019974842144432
            ],
            [
              105.80409829156702,
              21.019976504178924
            ],
            [
              105.8041269861832,
              21.01998493837109
            ],
            [
              105.8041421457107,
              21.01998939369104
            ],
            [
              105.80414667411067,
              21.019990725320678
            ],
            [
              105.804168024055,
              21.019997000283446
            ],
            [
              105.80425903532581,
              21.02003345697352
            ],
            [
              105.80437084210396,
              21.020094226852933
            ],
            [
              105.80450333634742,
              21.020183721760304
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D90_Ngõ 102, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DOPP.750",
          "duongKinh": 90,
          "chieudaiQL": 44.47,
          "chatLieu": "HDPE",
          "from_latitude": 21.01951225510453,
          "to_latitude": 21.020183721760304,
          "to_longitude": 105.80450333634742,
          "from_longitude": 105.8032888850537
        }
      },
      {
        "id": 749,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80252462267842,
            21.02039978188173,
            105.80304297566161,
            21.020726110994865
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80252462267842,
              21.02039978188173
            ],
            [
              105.80253364798938,
              21.020404470416043
            ],
            [
              105.80261056200426,
              21.02045038176585
            ],
            [
              105.80261741004807,
              21.0204544699859
            ],
            [
              105.8026809774288,
              21.02048092278056
            ],
            [
              105.80268722782388,
              21.020483523489357
            ],
            [
              105.80280079690384,
              21.020536070315018
            ],
            [
              105.80283910141877,
              21.020559548717994
            ],
            [
              105.80281478525916,
              21.020618960497103
            ],
            [
              105.80281778904444,
              21.02062013226018
            ],
            [
              105.8028685979088,
              21.020639945945668
            ],
            [
              105.80293128132384,
              21.020666033394388
            ],
            [
              105.80293947058472,
              21.020669441460573
            ],
            [
              105.80304070837369,
              21.020724869823084
            ],
            [
              105.80304297566161,
              21.020726110994865
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D90_Ngõ 182, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DOPP.751",
          "duongKinh": 90,
          "chieudaiQL": 44.47,
          "chatLieu": "HDPE",
          "from_latitude": 21.02039978188173,
          "to_latitude": 21.020726110994865,
          "to_longitude": 105.80304297566161,
          "from_longitude": 105.80252462267842
        }
      },
      {
        "id": 750,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.79961664886756,
            21.027727537045866,
            105.79996989003273,
            21.027846129454378
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.79963694755044,
              21.027786024491096
            ],
            [
              105.79961664886756,
              21.02779719804791
            ],
            [
              105.79964782594136,
              21.027835951717393
            ],
            [
              105.7996547086619,
              21.027846129454378
            ],
            [
              105.79996989003273,
              21.027727537045866
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D160_Đường Láng",
          "maTaiSan": "04.O9A.DOPP.752",
          "duongKinh": 160,
          "chieudaiQL": 44.45,
          "chatLieu": "HDPE",
          "from_latitude": 21.027786024491096,
          "to_latitude": 21.027727537045866,
          "to_longitude": 105.79996989003273,
          "from_longitude": 105.79963694755044
        }
      },
      {
        "id": 751,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80590834655888,
            21.020916854911203,
            105.80593124964817,
            21.021130860667245
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80593124964817,
              21.020916854911203
            ],
            [
              105.80593096635016,
              21.020921956786935
            ],
            [
              105.80593034636618,
              21.020933145310803
            ],
            [
              105.80592997708804,
              21.020939790308816
            ],
            [
              105.80592209373172,
              21.02108191459312
            ],
            [
              105.80590834655888,
              21.021130860667245
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D110_Ngõ 62, Nguyễn Chí Thanh",
          "maTaiSan": "04.O9A.DOPP.753",
          "duongKinh": 110,
          "chieudaiQL": 24,
          "chatLieu": "PVC",
          "from_latitude": 21.020916854911203,
          "to_latitude": 21.021130860667245,
          "to_longitude": 105.80590834655888,
          "from_longitude": 105.80593124964817
        }
      },
      {
        "id": 752,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80947889848363,
            21.022744079096604,
            105.80962634324028,
            21.022774911291325
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80947889848363,
              21.022744079096604
            ],
            [
              105.80948771223858,
              21.022774911291325
            ],
            [
              105.80962070801537,
              21.022749277811734
            ],
            [
              105.80962634324028,
              21.022748191500558
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D225_Chùa Láng",
          "maTaiSan": "04.O9A.DOPP.754",
          "duongKinh": 225,
          "chieudaiQL": 18.24,
          "chatLieu": "PVC",
          "from_latitude": 21.022744079096604,
          "to_latitude": 21.022748191500558,
          "to_longitude": 105.80962634324028,
          "from_longitude": 105.80947889848363
        }
      },
      {
        "id": 753,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81023010198953,
            21.026188669129773,
            105.8102649324256,
            21.026214111489793
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81023010198953,
              21.026188669129773
            ],
            [
              105.8102334413156,
              21.026190662901357
            ],
            [
              105.8102509463254,
              21.02620424533328
            ],
            [
              105.8102649324256,
              21.026214111489793
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D200_Đê La Thành",
          "maTaiSan": "04.O9A.DOPP.755",
          "duongKinh": 200,
          "chieudaiQL": 4.59,
          "chatLieu": "G",
          "from_latitude": 21.026188669129773,
          "to_latitude": 21.026214111489793,
          "to_longitude": 105.8102649324256,
          "from_longitude": 105.81023010198953
        }
      },
      {
        "id": 754,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80964342007472,
            21.02693951514282,
            105.80964824671226,
            21.026946065748877
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80964342007472,
              21.026946065748877
            ],
            [
              105.80964824671226,
              21.02693951514282
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Đê La Thành",
          "maTaiSan": "04.O9A.DOPP.756",
          "duongKinh": 100,
          "chieudaiQL": 0.88,
          "chatLieu": "G",
          "from_latitude": 21.026946065748877,
          "to_latitude": 21.02693951514282,
          "to_longitude": 105.80964824671226,
          "from_longitude": 105.80964342007472
        }
      },
      {
        "id": 755,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8087543389891,
            21.0276893451811,
            105.80879540398874,
            21.027702558477586
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8087543389891,
              21.02769953103155
            ],
            [
              105.80875627455038,
              21.027702558477586
            ],
            [
              105.80876577011212,
              21.027698894986393
            ],
            [
              105.80879102352667,
              21.0276893451811
            ],
            [
              105.80879540398874,
              21.027695479843455
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D225_Đê La Thành",
          "maTaiSan": "04.O9A.DOPP.757",
          "duongKinh": 225,
          "chieudaiQL": 5.1,
          "chatLieu": "PVC",
          "from_latitude": 21.02769953103155,
          "to_latitude": 21.027695479843455,
          "to_longitude": 105.80879540398874,
          "from_longitude": 105.8087543389891
        }
      },
      {
        "id": 756,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80995713844717,
            21.02256478259888,
            105.81003421500746,
            21.022611371735486
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80995713844717,
              21.022592134731774
            ],
            [
              105.80996430616378,
              21.02258910007466
            ],
            [
              105.81002174579119,
              21.02256478259888
            ],
            [
              105.81003421500746,
              21.02258614326635
            ],
            [
              105.80998614618964,
              21.022611371735486
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Nguyễn Chí Thanh",
          "maTaiSan": "04.O9C.DOPP.758",
          "duongKinh": 100,
          "chieudaiQL": 15.78,
          "chatLieu": "G",
          "from_latitude": 21.022592134731774,
          "to_latitude": 21.022611371735486,
          "to_longitude": 105.80998614618964,
          "from_longitude": 105.80995713844717
        }
      },
      {
        "id": 757,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81197076103258,
            21.022202238723352,
            105.8137645730409,
            21.02264052836884
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81197076103258,
              21.022202238723352
            ],
            [
              105.81200307549726,
              21.022205350526384
            ],
            [
              105.81254526937003,
              21.022257567237087
            ],
            [
              105.81354299776388,
              21.022349584136812
            ],
            [
              105.8137645730409,
              21.022375051050357
            ],
            [
              105.8137385587822,
              21.02264052836884
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D225_Nguyên Hồng",
          "maTaiSan": "04.O9C.DOPP.759",
          "duongKinh": 225,
          "chieudaiQL": 216,
          "chatLieu": "PVC",
          "from_latitude": 21.022202238723352,
          "to_latitude": 21.02264052836884,
          "to_longitude": 105.8137385587822,
          "from_longitude": 105.81197076103258
        }
      },
      {
        "id": 758,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81679368715066,
            21.01957334149116,
            105.81682211926203,
            21.019609500265183
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81682211926203,
              21.01957334149116
            ],
            [
              105.81681752598075,
              21.019576539294455
            ],
            [
              105.81679368715066,
              21.01959024733353
            ],
            [
              105.81680766066847,
              21.019609500265183
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D225_Láng Hạ",
          "maTaiSan": "04.O9C.DOPP.760",
          "duongKinh": 225,
          "chieudaiQL": 6.07,
          "chatLieu": "PVC",
          "from_latitude": 21.01957334149116,
          "to_latitude": 21.019609500265183,
          "to_longitude": 105.81680766066847,
          "from_longitude": 105.81682211926203
        }
      },
      {
        "id": 759,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81679499259307,
            21.01959940086064,
            105.81683897246306,
            21.019620151055907
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81679499259307,
              21.01961763323618
            ],
            [
              105.81680710975586,
              21.019620151055907
            ],
            [
              105.81683605464588,
              21.01960148777842
            ],
            [
              105.81683897246306,
              21.01959940086064
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D225_Láng Hạ",
          "maTaiSan": "04.O9C.DOPP.761",
          "duongKinh": 225,
          "chieudaiQL": 5.32,
          "chatLieu": "PVC",
          "from_latitude": 21.01961763323618,
          "to_latitude": 21.01959940086064,
          "to_longitude": 105.81683897246306,
          "from_longitude": 105.81679499259307
        }
      },
      {
        "id": 760,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81951831218754,
            21.02340565337728,
            105.81955284145792,
            21.02342722749114
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81955193729534,
              21.02341704451997
            ],
            [
              105.81955284145792,
              21.023421776681978
            ],
            [
              105.81953097958484,
              21.023426054931818
            ],
            [
              105.81952363350905,
              21.02342722749114
            ],
            [
              105.81951831218754,
              21.02340565337728
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D160_La Thành",
          "maTaiSan": "04.O9C.DOPP.762",
          "duongKinh": 160,
          "chieudaiQL": 6.08,
          "chatLieu": "HDPE",
          "from_latitude": 21.02341704451997,
          "to_latitude": 21.02340565337728,
          "to_longitude": 105.81951831218754,
          "from_longitude": 105.81955193729534
        }
      },
      {
        "id": 761,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81389075752413,
            21.022775545465706,
            105.81399743157417,
            21.022894566967285
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81390562664839,
              21.022775545465706
            ],
            [
              105.81389075752413,
              21.022884059900672
            ],
            [
              105.81392550460062,
              21.02288726988588
            ],
            [
              105.81399743157417,
              21.022894566967285
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D225_Thành Công",
          "maTaiSan": "04.O9C.DOPP.763",
          "duongKinh": 225,
          "chieudaiQL": 11.15,
          "chatLieu": "PVC",
          "from_latitude": 21.022775545465706,
          "to_latitude": 21.022894566967285,
          "to_longitude": 105.81399743157417,
          "from_longitude": 105.81390562664839
        }
      },
      {
        "id": 762,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82606259519797,
            21.021097444004962,
            105.82610467859578,
            21.0211194549621
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82610467859578,
              21.021097444004962
            ],
            [
              105.82606259519797,
              21.0211194549621
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D200_Đường La Thành",
          "maTaiSan": "04.O13.DOPP.764",
          "duongKinh": 200,
          "chieudaiQL": 5,
          "chatLieu": "G",
          "from_latitude": 21.021097444004962,
          "to_latitude": 21.0211194549621,
          "to_longitude": 105.82606259519797,
          "from_longitude": 105.82610467859578
        }
      },
      {
        "id": 763,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82003870432341,
            21.023327072655373,
            105.82007495381258,
            21.023407419590495
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82003870432341,
              21.023327072655373
            ],
            [
              105.82006268254494,
              21.023392796816445
            ],
            [
              105.82006472263645,
              21.023398372530878
            ],
            [
              105.82007449264971,
              21.023406472523313
            ],
            [
              105.82007495381258,
              21.023407419590495
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Đường La Thành",
          "maTaiSan": "04.O13.DOPP.765",
          "duongKinh": 150,
          "chieudaiQL": 6.69,
          "chatLieu": "G-DI",
          "from_latitude": 21.023327072655373,
          "to_latitude": 21.023407419590495,
          "to_longitude": 105.82007495381258,
          "from_longitude": 105.82003870432341
        }
      },
      {
        "id": 764,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82689275869402,
            21.013441347661544,
            105.826980893883,
            21.01348089040172
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82689275869402,
              21.01346643610589
            ],
            [
              105.82691389382633,
              21.013456441255784
            ],
            [
              105.82691403390507,
              21.01345637554271
            ],
            [
              105.82691630869597,
              21.013455299583384
            ],
            [
              105.8269458138367,
              21.013441347661544
            ],
            [
              105.82696344249304,
              21.013467426634232
            ],
            [
              105.82697003922931,
              21.013477172897172
            ],
            [
              105.82697211920254,
              21.01348089040172
            ],
            [
              105.826980893883,
              21.013475482589527
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D200_Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DOPP.766",
          "duongKinh": 200,
          "chieudaiQL": 12.43,
          "chatLieu": "G-DI",
          "from_latitude": 21.01346643610589,
          "to_latitude": 21.013475482589527,
          "to_longitude": 105.826980893883,
          "from_longitude": 105.82689275869402
        }
      },
      {
        "id": 765,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8280755921814,
            21.015409771358456,
            105.8281539851376,
            21.01544066011445
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8280755921814,
              21.01544066011445
            ],
            [
              105.82808511135465,
              21.015436625136694
            ],
            [
              105.82812748649687,
              21.01541866326537
            ],
            [
              105.82814779450761,
              21.015410055123382
            ],
            [
              105.82814782806976,
              21.01541003599229
            ],
            [
              105.82814773577071,
              21.01540987024804
            ],
            [
              105.82814776359629,
              21.01540985746743
            ],
            [
              105.82814801130047,
              21.015409771358456
            ],
            [
              105.82815178279989,
              21.015415649257196
            ],
            [
              105.8281539851376,
              21.01541433969819
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Nguyễn Lương Bằng",
          "maTaiSan": "04.O16B.DOPP.767",
          "duongKinh": 150,
          "chieudaiQL": 9.53,
          "chatLieu": "G-DI",
          "from_latitude": 21.01544066011445,
          "to_latitude": 21.01541433969819,
          "to_longitude": 105.8281539851376,
          "from_longitude": 105.8280755921814
        }
      },
      {
        "id": 766,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80452576945588,
            21.017780865841853,
            105.80561324302215,
            21.018215010299876
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80452576945588,
              21.017780865841853
            ],
            [
              105.8045444688966,
              21.017798615283574
            ],
            [
              105.80469598555798,
              21.017892372813606
            ],
            [
              105.80474971210964,
              21.01792541892226
            ],
            [
              105.80480517787406,
              21.01795953524087
            ],
            [
              105.80488970735755,
              21.018012308425053
            ],
            [
              105.80490237865303,
              21.0180197308225
            ],
            [
              105.80490901778892,
              21.018023619424284
            ],
            [
              105.80491702625126,
              21.01802831084014
            ],
            [
              105.80496522196647,
              21.017997970929112
            ],
            [
              105.8050079708835,
              21.01797243666971
            ],
            [
              105.80502131705575,
              21.017969386533508
            ],
            [
              105.80503850903679,
              21.017971695120817
            ],
            [
              105.80508339114519,
              21.01799088498625
            ],
            [
              105.80509679021188,
              21.01799661307949
            ],
            [
              105.80514233753084,
              21.01801625139529
            ],
            [
              105.80522516206042,
              21.018066062659862
            ],
            [
              105.80522726521032,
              21.018067553871752
            ],
            [
              105.80527212182145,
              21.01809936372576
            ],
            [
              105.80533562396448,
              21.01814207940466
            ],
            [
              105.80537439602614,
              21.01815790739348
            ],
            [
              105.80545780766109,
              21.01819216486246
            ],
            [
              105.80551845485618,
              21.018215010299876
            ],
            [
              105.80559798079726,
              21.018208181943045
            ],
            [
              105.80561324302215,
              21.01820653172399
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D90_Ngõ 157, Pháo Đài Láng",
          "maTaiSan": "04.O9A.DOPP.768",
          "duongKinh": 90,
          "chieudaiQL": 130.51,
          "chatLieu": "HDPE",
          "from_latitude": 21.017780865841853,
          "to_latitude": 21.01820653172399,
          "to_longitude": 105.80561324302215,
          "from_longitude": 105.80452576945588
        }
      },
      {
        "id": 767,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80173413152264,
            21.021687959323447,
            105.80237034454805,
            21.021819553596753
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80173413152264,
              21.021695705910673
            ],
            [
              105.80174202588677,
              21.021699008842265
            ],
            [
              105.8018778247032,
              21.021755805042137
            ],
            [
              105.80190834257306,
              21.021740507683944
            ],
            [
              105.8019503291481,
              21.021693367086993
            ],
            [
              105.80195514572641,
              21.021687959323447
            ],
            [
              105.8020680958263,
              21.02171458052455
            ],
            [
              105.80208232976207,
              21.02173786549643
            ],
            [
              105.80208654789563,
              21.02174161297504
            ],
            [
              105.80209205278189,
              21.021743131538
            ],
            [
              105.8021014594467,
              21.02174572551178
            ],
            [
              105.80216932415244,
              21.02176114965606
            ],
            [
              105.8021718404529,
              21.021761823339872
            ],
            [
              105.80223289858624,
              21.021772826638355
            ],
            [
              105.80228265216267,
              21.021781793163992
            ],
            [
              105.80233877662637,
              21.02179842965288
            ],
            [
              105.80236933583444,
              21.02181639343952
            ],
            [
              105.80237034454805,
              21.021819553596753
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D90_Ngõ 185, Chùa Láng",
          "maTaiSan": "04.O9A.DOPP.769",
          "duongKinh": 90,
          "chieudaiQL": 120,
          "chatLieu": "HDPE",
          "from_latitude": 21.021695705910673,
          "to_latitude": 21.021819553596753,
          "to_longitude": 105.80237034454805,
          "from_longitude": 105.80173413152264
        }
      },
      {
        "id": 768,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80147955236146,
            21.0221344111061,
            105.8020230073716,
            21.022284532570485
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80147955236146,
              21.0221344111061
            ],
            [
              105.80163068534823,
              21.022198552554457
            ],
            [
              105.8017442667342,
              21.02224080498297
            ],
            [
              105.80175692662556,
              21.022245514358232
            ],
            [
              105.80177853159937,
              21.022251764927205
            ],
            [
              105.80181300860791,
              21.022261739344238
            ],
            [
              105.80189534270997,
              21.022284532570485
            ],
            [
              105.80193962103097,
              21.022187775811677
            ],
            [
              105.80202094389142,
              21.022216292090995
            ],
            [
              105.8020230073716,
              21.022217015783145
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D90_Ngõ 185, Chùa Láng",
          "maTaiSan": "04.O9A.DOPP.770",
          "duongKinh": 90,
          "chieudaiQL": 67,
          "chatLieu": "HDPE",
          "from_latitude": 21.0221344111061,
          "to_latitude": 21.022217015783145,
          "to_longitude": 105.8020230073716,
          "from_longitude": 105.80147955236146
        }
      },
      {
        "id": 769,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80191709827328,
            21.02211627082033,
            105.80193962103097,
            21.022187775811677
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8019346258912,
              21.02211627082033
            ],
            [
              105.80193123790119,
              21.0221267254164
            ],
            [
              105.80191709827328,
              21.022170362965092
            ],
            [
              105.80193962103097,
              21.022187775811677
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D90_Ngõ 185, Chùa Láng",
          "maTaiSan": "04.O9A.DOPP.771",
          "duongKinh": 90,
          "chieudaiQL": 9.29,
          "chatLieu": "HDPE",
          "from_latitude": 21.02211627082033,
          "to_latitude": 21.022187775811677,
          "to_longitude": 105.80193962103097,
          "from_longitude": 105.8019346258912
        }
      },
      {
        "id": 770,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83563399306207,
            21.01070354058327,
            105.8365985580986,
            21.01144799972686
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83563399306207,
              21.01070354058327
            ],
            [
              105.83571735616852,
              21.010768576146184
            ],
            [
              105.83572382854392,
              21.010773532869056
            ],
            [
              105.83572681356412,
              21.010775818722266
            ],
            [
              105.83579257136091,
              21.01081588344709
            ],
            [
              105.83583784312788,
              21.01084984091082
            ],
            [
              105.83584922936025,
              21.010864544577924
            ],
            [
              105.83586782726954,
              21.010875842886886
            ],
            [
              105.83591122819668,
              21.010902209786025
            ],
            [
              105.83591893413816,
              21.01090689128016
            ],
            [
              105.83592410360444,
              21.010910031687875
            ],
            [
              105.83604701072278,
              21.01098469718876
            ],
            [
              105.83607125526989,
              21.01099482605448
            ],
            [
              105.83623009886763,
              21.011100981901766
            ],
            [
              105.83626449315352,
              21.011130550642395
            ],
            [
              105.8363366270875,
              21.011182923666652
            ],
            [
              105.83633706653092,
              21.01118324215154
            ],
            [
              105.8363400171773,
              21.011185570613886
            ],
            [
              105.83634453106602,
              21.011189132423457
            ],
            [
              105.83637663390014,
              21.011213828666502
            ],
            [
              105.83645127449647,
              21.011286188519904
            ],
            [
              105.8365985580986,
              21.01144799972686
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Ngõ 46, Phạm Ngọc Thạch",
          "maTaiSan": "04.O16B.DOPP.772",
          "duongKinh": 150,
          "chieudaiQL": 145,
          "chatLieu": "G-DI",
          "from_latitude": 21.01070354058327,
          "to_latitude": 21.01144799972686,
          "to_longitude": 105.8365985580986,
          "from_longitude": 105.83563399306207
        }
      },
      {
        "id": 771,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83315207511933,
            21.01073173896969,
            105.83393590189034,
            21.011471399524975
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83322503054418,
              21.01073173896969
            ],
            [
              105.83315207511933,
              21.010835030462008
            ],
            [
              105.83322521361586,
              21.01089737695057
            ],
            [
              105.83333917636519,
              21.010994514424937
            ],
            [
              105.83335597673599,
              21.011008834072832
            ],
            [
              105.83358804801442,
              21.01120664895667
            ],
            [
              105.83363461137519,
              21.011246338878358
            ],
            [
              105.83364550596241,
              21.01125447685266
            ],
            [
              105.83366331459459,
              21.011267779916153
            ],
            [
              105.83393590189034,
              21.011471399524975
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 6, Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DOPP.773",
          "duongKinh": 100,
          "chieudaiQL": 90,
          "chatLieu": "G-DI",
          "from_latitude": 21.01073173896969,
          "to_latitude": 21.011471399524975,
          "to_longitude": 105.83393590189034,
          "from_longitude": 105.83322503054418
        }
      },
      {
        "id": 772,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83335040556767,
            21.010602596040208,
            105.8337003693957,
            21.01086153541132
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83335040556767,
              21.010602596040208
            ],
            [
              105.83343627337112,
              21.010669180355062
            ],
            [
              105.83351756882031,
              21.01073221295882
            ],
            [
              105.833519824583,
              21.010733961391995
            ],
            [
              105.83352484234108,
              21.0107378531976
            ],
            [
              105.83357557536038,
              21.01077719033038
            ],
            [
              105.8337003693957,
              21.01086153541132
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 4A, Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DOPP.774",
          "duongKinh": 100,
          "chieudaiQL": 90,
          "chatLieu": "G-DI",
          "from_latitude": 21.010602596040208,
          "to_latitude": 21.01086153541132,
          "to_longitude": 105.8337003693957,
          "from_longitude": 105.83335040556767
        }
      },
      {
        "id": 773,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83352436610159,
            21.01042340848571,
            105.83379883915923,
            21.010628287273924
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83352436610159,
              21.01042340848571
            ],
            [
              105.83360672534644,
              21.01048488861556
            ],
            [
              105.83369099035816,
              21.010547783752322
            ],
            [
              105.83369284394105,
              21.01054916744425
            ],
            [
              105.83369768798514,
              21.010552783709073
            ],
            [
              105.83379883915923,
              21.010628287273924
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 4A, Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DOPP.775",
          "duongKinh": 100,
          "chieudaiQL": 90,
          "chatLieu": "G-DI",
          "from_latitude": 21.01042340848571,
          "to_latitude": 21.010628287273924,
          "to_longitude": 105.83379883915923,
          "from_longitude": 105.83352436610159
        }
      },
      {
        "id": 774,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83372325359764,
            21.010218543680036,
            105.83400384247102,
            21.010422239664972
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83372325359764,
              21.010218543680036
            ],
            [
              105.83379615498012,
              21.010271470451492
            ],
            [
              105.83390820989237,
              21.010352814074132
            ],
            [
              105.8339117292682,
              21.010355369212434
            ],
            [
              105.83391432244431,
              21.010357252373947
            ],
            [
              105.83400384247102,
              21.010422239664972
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 4A, Đặng Văn Ngữ",
          "maTaiSan": "04.O16B.DOPP.776",
          "duongKinh": 100,
          "chieudaiQL": 90,
          "chatLieu": "G-DI",
          "from_latitude": 21.010218543680036,
          "to_latitude": 21.010422239664972,
          "to_longitude": 105.83400384247102,
          "from_longitude": 105.83372325359764
        }
      },
      {
        "id": 775,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83700332976771,
            21.011407688918013,
            105.837127043304,
            21.011524359382964
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.837127043304,
              21.011420015402752
            ],
            [
              105.83711912007671,
              21.011429500305532
            ],
            [
              105.83711637188185,
              21.01144725315657
            ],
            [
              105.83705667283026,
              21.011513960950925
            ],
            [
              105.83704720505382,
              21.011524359382964
            ],
            [
              105.83703401790862,
              21.011489302365916
            ],
            [
              105.8370159409214,
              21.01144123135421
            ],
            [
              105.83700332976771,
              21.011407688918013
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D200_Xã Đàn",
          "maTaiSan": "04.O16B.DOPP.777",
          "duongKinh": 200,
          "chieudaiQL": 28.18,
          "chatLieu": "G-DI",
          "from_latitude": 21.011420015402752,
          "to_latitude": 21.011407688918013,
          "to_longitude": 105.83700332976771,
          "from_longitude": 105.837127043304
        }
      },
      {
        "id": 776,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83751779809629,
            20.999560798032043,
            105.83761891318248,
            21.00103747426165
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83761891318248,
              20.999560798032043
            ],
            [
              105.8376186418962,
              20.999566305430875
            ],
            [
              105.83759722443604,
              21.00001444959891
            ],
            [
              105.83759629415117,
              21.000030102561126
            ],
            [
              105.83756962949144,
              21.00047853987886
            ],
            [
              105.83756812466389,
              21.000533784046635
            ],
            [
              105.83756202559265,
              21.00075770471914
            ],
            [
              105.83755538485721,
              21.000844726148095
            ],
            [
              105.83752502523207,
              21.00094521846742
            ],
            [
              105.83752038220732,
              21.001004482974935
            ],
            [
              105.83751779809629,
              21.00103747426165
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D200_Ngõ 102, Trường Chinh",
          "maTaiSan": "04.O17.DOPP.778",
          "duongKinh": 200,
          "chieudaiQL": 163.01,
          "chatLieu": "G-DI",
          "from_latitude": 20.999560798032043,
          "to_latitude": 21.00103747426165,
          "to_longitude": 105.83751779809629,
          "from_longitude": 105.83761891318248
        }
      },
      {
        "id": 777,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83483759216985,
            20.99932892543962,
            105.83593224692859,
            21.001165404771697
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83593224692859,
              20.99932892543962
            ],
            [
              105.835722987801,
              20.999627807914244
            ],
            [
              105.83564720436965,
              20.999736048612085
            ],
            [
              105.83556573102877,
              20.999884108829693
            ],
            [
              105.83544584458603,
              21.00010197639202
            ],
            [
              105.83534678642714,
              21.000281993141762
            ],
            [
              105.8352198558556,
              21.000512660413644
            ],
            [
              105.8350451608452,
              21.00083012709407
            ],
            [
              105.83499386496301,
              21.000923345065
            ],
            [
              105.83498627695998,
              21.00093509803057
            ],
            [
              105.83484516589405,
              21.0011536744631
            ],
            [
              105.83483759216985,
              21.001165404771697
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D200_Ngõ 120, Trường Chinh",
          "maTaiSan": "04.O17.DOPP.779",
          "duongKinh": 200,
          "chieudaiQL": 233.68,
          "chatLieu": "G-DI",
          "from_latitude": 20.99932892543962,
          "to_latitude": 21.001165404771697,
          "to_longitude": 105.83483759216985,
          "from_longitude": 105.83593224692859
        }
      },
      {
        "id": 778,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83554019927536,
            21.00287783923454,
            105.83604785644981,
            21.00289184991498
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83604785644981,
              21.00287783923454
            ],
            [
              105.83604740630415,
              21.00287783963911
            ],
            [
              105.8360205529229,
              21.002878299405868
            ],
            [
              105.83590308995001,
              21.002880313331417
            ],
            [
              105.83589648460098,
              21.002880433382607
            ],
            [
              105.83577306827115,
              21.002883924325513
            ],
            [
              105.83567343420255,
              21.002887316484166
            ],
            [
              105.83566938267816,
              21.002887454698
            ],
            [
              105.83554019927536,
              21.00289184991498
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 28B, Lương Định Của",
          "maTaiSan": "04.O17.DOPP.780",
          "duongKinh": 100,
          "chieudaiQL": 51.39,
          "chatLieu": "G-DI",
          "from_latitude": 21.00287783923454,
          "to_latitude": 21.00289184991498,
          "to_longitude": 105.83554019927536,
          "from_longitude": 105.83604785644981
        }
      },
      {
        "id": 779,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84024685698743,
            20.998724624751222,
            105.84049791619417,
            20.999141486336423
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84048889834278,
              20.999141486336423
            ],
            [
              105.84048911031063,
              20.99913364890746
            ],
            [
              105.84048918646519,
              20.999131961306666
            ],
            [
              105.84048977156871,
              20.999119647457455
            ],
            [
              105.84049002553773,
              20.999099898989417
            ],
            [
              105.84049019316328,
              20.999093418423694
            ],
            [
              105.84049215396875,
              20.99902137020433
            ],
            [
              105.84049227749679,
              20.999016805597105
            ],
            [
              105.84049282559425,
              20.998989635680964
            ],
            [
              105.84049428355834,
              20.998920199361248
            ],
            [
              105.84049431567752,
              20.998916831075565
            ],
            [
              105.84049558075445,
              20.998783908001084
            ],
            [
              105.84049771973926,
              20.99873286250101
            ],
            [
              105.84049791619417,
              20.99872792359829
            ],
            [
              105.84044476043807,
              20.998727168995686
            ],
            [
              105.84044472782284,
              20.998727184511534
            ],
            [
              105.84043837917034,
              20.99872707856337
            ],
            [
              105.84043834655505,
              20.99872709407921
            ],
            [
              105.84026611770211,
              20.99872464747718
            ],
            [
              105.84025954932807,
              20.998724637445502
            ],
            [
              105.84025223937034,
              20.998724624751222
            ],
            [
              105.84025220675507,
              20.998724640267017
            ],
            [
              105.84024685698743,
              20.99872462512339
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 78, Giải Phóng",
          "maTaiSan": "04.O17.DOPP.781",
          "duongKinh": 100,
          "chieudaiQL": 72.67,
          "chatLieu": "G-DI",
          "from_latitude": 20.999141486336423,
          "to_latitude": 20.99872462512339,
          "to_longitude": 105.84024685698743,
          "from_longitude": 105.84048889834278
        }
      },
      {
        "id": 780,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83599291297335,
            21.000833195353056,
            105.83613171166022,
            21.00151416477013
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83613171166022,
              21.000833195353056
            ],
            [
              105.8361284731783,
              21.000833202227827
            ],
            [
              105.8361199378998,
              21.000833220667925
            ],
            [
              105.83603907974062,
              21.0008335672288
            ],
            [
              105.83603902092939,
              21.00084459500221
            ],
            [
              105.83603855968462,
              21.000930504885297
            ],
            [
              105.83603324227325,
              21.000970681196527
            ],
            [
              105.83603315764884,
              21.00098173709655
            ],
            [
              105.83602876144643,
              21.001064873530016
            ],
            [
              105.83602701670246,
              21.001097866100224
            ],
            [
              105.83602466363902,
              21.0011423515938
            ],
            [
              105.83602435562167,
              21.00114818252611
            ],
            [
              105.83599479448212,
              21.001364609648125
            ],
            [
              105.83599291297335,
              21.001453699534224
            ],
            [
              105.83599608499104,
              21.00145643608483
            ],
            [
              105.83601216542249,
              21.001470305625762
            ],
            [
              105.83603395781658,
              21.00148910401438
            ],
            [
              105.83605757041711,
              21.001511425975984
            ],
            [
              105.83606046928548,
              21.00151416477013
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 36, Lương Định Của",
          "maTaiSan": "04.O17.DOPP.782",
          "duongKinh": 100,
          "chieudaiQL": 91.61,
          "chatLieu": "G-DI",
          "from_latitude": 21.000833195353056,
          "to_latitude": 21.00151416477013,
          "to_longitude": 105.83606046928548,
          "from_longitude": 105.83613171166022
        }
      },
      {
        "id": 781,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83607554586405,
            21.00161373012631,
            105.83613376220744,
            21.00347034823714
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83613217163239,
              21.00161373012631
            ],
            [
              105.83610143765772,
              21.001634607411823
            ],
            [
              105.83607554586405,
              21.001668826843574
            ],
            [
              105.83607759506599,
              21.00183002451243
            ],
            [
              105.8360873670724,
              21.00214976087626
            ],
            [
              105.83608795224161,
              21.00230868959798
            ],
            [
              105.83608892666592,
              21.002580493421174
            ],
            [
              105.83608967274229,
              21.002782291143838
            ],
            [
              105.83608899182228,
              21.002941335363268
            ],
            [
              105.83608734559202,
              21.003085426905212
            ],
            [
              105.8360873195207,
              21.003088689484223
            ],
            [
              105.83608732170097,
              21.00308873553802
            ],
            [
              105.83612407225212,
              21.003268815360318
            ],
            [
              105.83613376220744,
              21.00336364257597
            ],
            [
              105.83612400584173,
              21.003412461974566
            ],
            [
              105.83612353154682,
              21.003414861465156
            ],
            [
              105.8361221629345,
              21.00342161528332
            ],
            [
              105.83611790950458,
              21.003441627269268
            ],
            [
              105.83608327011974,
              21.00343403239456
            ],
            [
              105.83607675966577,
              21.003467595721066
            ],
            [
              105.83607668073654,
              21.00347034823714
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D200_Lương Định Của",
          "maTaiSan": "04.O17.DOPP.783",
          "duongKinh": 200,
          "chieudaiQL": 207.01,
          "chatLieu": "G-DI",
          "from_latitude": 21.00161373012631,
          "to_latitude": 21.00347034823714,
          "to_longitude": 105.83607668073654,
          "from_longitude": 105.83613217163239
        }
      },
      {
        "id": 782,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83917206243436,
            20.998557601959586,
            105.83921621548774,
            20.99910809716845
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83917206243436,
              20.998557601959586
            ],
            [
              105.83919224350636,
              20.998737245266277
            ],
            [
              105.83921621548774,
              20.99910809716845
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Ngõ 74, Trường Chinh",
          "maTaiSan": "04.O17.DOPP.784",
          "duongKinh": 150,
          "chieudaiQL": 62.8,
          "chatLieu": "G-DI",
          "from_latitude": 20.998557601959586,
          "to_latitude": 20.99910809716845,
          "to_longitude": 105.83921621548774,
          "from_longitude": 105.83917206243436
        }
      },
      {
        "id": 783,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83535813845502,
            21.002155613713068,
            105.8360394584223,
            21.00223960040685
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8360394584223,
              21.002237164699025
            ],
            [
              105.83603477668076,
              21.002237090145492
            ],
            [
              105.83590342224224,
              21.002234965963353
            ],
            [
              105.83588315179621,
              21.002235238022656
            ],
            [
              105.83575597526308,
              21.002237365467995
            ],
            [
              105.8357341118838,
              21.002238309201726
            ],
            [
              105.83571900869048,
              21.00223896141952
            ],
            [
              105.83570420840623,
              21.00223960040685
            ],
            [
              105.83569824784179,
              21.002239431864552
            ],
            [
              105.83560219871735,
              21.002236550627
            ],
            [
              105.83550774350057,
              21.002224014156607
            ],
            [
              105.83550826642066,
              21.002180756105666
            ],
            [
              105.83539193474105,
              21.002161273657872
            ],
            [
              105.83538704590957,
              21.002160454941485
            ],
            [
              105.83535813845502,
              21.002155613713068
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 28D, Lương Định Của",
          "maTaiSan": "04.O17.DOPP.785",
          "duongKinh": 100,
          "chieudaiQL": 77.38,
          "chatLieu": "G-DI",
          "from_latitude": 21.002237164699025,
          "to_latitude": 21.002155613713068,
          "to_longitude": 105.83535813845502,
          "from_longitude": 105.8360394584223
        }
      },
      {
        "id": 784,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83606046928548,
            21.00149822783932,
            105.8375005105948,
            21.001544476798248
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8375005105948,
              21.00149822783932
            ],
            [
              105.83738157794814,
              21.00150211243625
            ],
            [
              105.83735874212294,
              21.001502857933488
            ],
            [
              105.8372059249997,
              21.001507847541298
            ],
            [
              105.83720558649976,
              21.00150786004485
            ],
            [
              105.83716738999505,
              21.001544476798248
            ],
            [
              105.83701058496386,
              21.00153910958899
            ],
            [
              105.83700754591645,
              21.001539006209985
            ],
            [
              105.83695975229564,
              21.00153737063231
            ],
            [
              105.8369362730032,
              21.001536566592044
            ],
            [
              105.83693215648132,
              21.00153642605774
            ],
            [
              105.83693050082744,
              21.001536369165883
            ],
            [
              105.83681177581775,
              21.001532305765636
            ],
            [
              105.83680932745348,
              21.0015322220599
            ],
            [
              105.83661587815288,
              21.001542062494618
            ],
            [
              105.83661251649247,
              21.001542233468307
            ],
            [
              105.83660584941116,
              21.00154213888104
            ],
            [
              105.83660100613616,
              21.00154207055573
            ],
            [
              105.83659748838943,
              21.001542021005946
            ],
            [
              105.83649194456595,
              21.001539002663954
            ],
            [
              105.83641771478182,
              21.00153687993966
            ],
            [
              105.83641348721231,
              21.00153683387393
            ],
            [
              105.83638268608951,
              21.00153650108896
            ],
            [
              105.8363798292337,
              21.001536469964808
            ],
            [
              105.8362524329074,
              21.001531148134926
            ],
            [
              105.83624712958586,
              21.00153102876929
            ],
            [
              105.8362407204601,
              21.001530621287564
            ],
            [
              105.83606408937106,
              21.0015144558929
            ],
            [
              105.83606046928548,
              21.00151416477013
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D200_Ngõ 91, Lương Định Của",
          "maTaiSan": "04.O17.DOPP.786",
          "duongKinh": 200,
          "chieudaiQL": 148.03,
          "chatLieu": "G-DI",
          "from_latitude": 21.00149822783932,
          "to_latitude": 21.00151416477013,
          "to_longitude": 105.83606046928548,
          "from_longitude": 105.8375005105948
        }
      },
      {
        "id": 785,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8351957849993,
            21.001535056125753,
            105.83602971557545,
            21.001668652933414
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83602971557545,
              21.001666198652615
            ],
            [
              105.83602437420782,
              21.00166632966058
            ],
            [
              105.83599865695207,
              21.001666956353883
            ],
            [
              105.83592907101016,
              21.001668652933414
            ],
            [
              105.83566189978768,
              21.001618554051685
            ],
            [
              105.83560190900955,
              21.00160725022927
            ],
            [
              105.83559079283356,
              21.001605156023047
            ],
            [
              105.835568219626,
              21.00160090244284
            ],
            [
              105.83536383124918,
              21.00156238947312
            ],
            [
              105.83535371452406,
              21.00156048363671
            ],
            [
              105.83535015003126,
              21.001559811967898
            ],
            [
              105.83534003811552,
              21.001557906107085
            ],
            [
              105.83526720481133,
              21.001544182219757
            ],
            [
              105.83521877220657,
              21.001535056125753
            ],
            [
              105.83519967303194,
              21.001606486621885
            ],
            [
              105.83519923394,
              21.00160812722814
            ],
            [
              105.8351957849993,
              21.001620962732886
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 32, Lương Định Của",
          "maTaiSan": "04.O17.DOPP.787",
          "duongKinh": 100,
          "chieudaiQL": 96.34,
          "chatLieu": "G-DI",
          "from_latitude": 21.001666198652615,
          "to_latitude": 21.001620962732886,
          "to_longitude": 105.8351957849993,
          "from_longitude": 105.83602971557545
        }
      },
      {
        "id": 786,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83524845463894,
            21.00190511387785,
            105.83604302442589,
            21.001969044504513
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83604302442589,
              21.001951442433494
            ],
            [
              105.83603225623735,
              21.001952080612273
            ],
            [
              105.83588174526051,
              21.00196072842789
            ],
            [
              105.83587709667707,
              21.00196073409402
            ],
            [
              105.83586755135501,
              21.001960745741293
            ],
            [
              105.83561751404247,
              21.00196767183039
            ],
            [
              105.83558428051464,
              21.001967893683
            ],
            [
              105.83555847808793,
              21.001968089890656
            ],
            [
              105.83549906230304,
              21.001969044504513
            ],
            [
              105.83538143720321,
              21.001939037682614
            ],
            [
              105.83529363813331,
              21.00191664041983
            ],
            [
              105.83528687250258,
              21.0019149141358
            ],
            [
              105.83524845463894,
              21.00190511387785
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 30, Lương Định Của",
          "maTaiSan": "04.O17.DOPP.788",
          "duongKinh": 100,
          "chieudaiQL": 81.33,
          "chatLieu": "G-DI",
          "from_latitude": 21.001951442433494,
          "to_latitude": 21.00190511387785,
          "to_longitude": 105.83524845463894,
          "from_longitude": 105.83604302442589
        }
      },
      {
        "id": 787,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83504949665834,
            21.003160709583902,
            105.83612400584173,
            21.003412461974566
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83612400584173,
              21.003412461974566
            ],
            [
              105.8361239788803,
              21.003412456687652
            ],
            [
              105.83602700872244,
              21.003393727723896
            ],
            [
              105.8360225640228,
              21.003392872523072
            ],
            [
              105.83580386213293,
              21.003339081049756
            ],
            [
              105.83555112656612,
              21.003276919074086
            ],
            [
              105.83553256477732,
              21.0032726232246
            ],
            [
              105.83526625444652,
              21.003210927583467
            ],
            [
              105.83522028671972,
              21.003200278314534
            ],
            [
              105.83506648089802,
              21.00316464561362
            ],
            [
              105.83504949665834,
              21.003160709583902
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Phương Mai",
          "maTaiSan": "04.O17.DOPP.789",
          "duongKinh": 150,
          "chieudaiQL": 108.36,
          "chatLieu": "G-DI",
          "from_latitude": 21.003412461974566,
          "to_latitude": 21.003160709583902,
          "to_longitude": 105.83504949665834,
          "from_longitude": 105.83612400584173
        }
      },
      {
        "id": 788,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83670325786854,
            20.999294758884567,
            105.83779922645365,
            20.999361340797943
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83779922645365,
              20.999294758884567
            ],
            [
              105.83779466191419,
              20.99929481476776
            ],
            [
              105.83765024112091,
              20.999296556937967
            ],
            [
              105.83743018704598,
              20.99930008370484
            ],
            [
              105.83726071969893,
              20.999304160752626
            ],
            [
              105.8371394394003,
              20.999319496936348
            ],
            [
              105.83709761477076,
              20.99932478234684
            ],
            [
              105.83682659882767,
              20.999355381116686
            ],
            [
              105.83676150208954,
              20.999357804712773
            ],
            [
              105.8367614645791,
              20.999357804897148
            ],
            [
              105.83670325786854,
              20.999361340797943
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngách 102/25, Trường Chinh",
          "maTaiSan": "04.O17.DOPP.790",
          "duongKinh": 100,
          "chieudaiQL": 115.95,
          "chatLieu": "G-DI",
          "from_latitude": 20.999294758884567,
          "to_latitude": 20.999361340797943,
          "to_longitude": 105.83670325786854,
          "from_longitude": 105.83779922645365
        }
      },
      {
        "id": 789,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83917206243436,
            20.998464047275398,
            105.83955703153634,
            20.998612977918384
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83917206243436,
              20.998557601959586
            ],
            [
              105.83928163967029,
              20.998530972113507
            ],
            [
              105.83928796375704,
              20.998529437968607
            ],
            [
              105.83928799637236,
              20.99852942245298
            ],
            [
              105.83929318894855,
              20.998528160337774
            ],
            [
              105.83930583807805,
              20.998525091138976
            ],
            [
              105.8393211295779,
              20.998521375750787
            ],
            [
              105.83941217639375,
              20.998499249436094
            ],
            [
              105.83955703153634,
              20.998464047275398
            ],
            [
              105.83955190437202,
              20.99855337085231
            ],
            [
              105.83955005064905,
              20.998585654950528
            ],
            [
              105.8395498675231,
              20.99858885507832
            ],
            [
              105.83954948536301,
              20.998595506509012
            ],
            [
              105.83954909386567,
              20.9985991123094
            ],
            [
              105.83954866581564,
              20.998603061515723
            ],
            [
              105.83954758920042,
              20.998612977918384
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Trường Chinh",
          "maTaiSan": "04.O17.DOPP.791",
          "duongKinh": 100,
          "chieudaiQL": 64.09,
          "chatLieu": "G-DI",
          "from_latitude": 20.998557601959586,
          "to_latitude": 20.998612977918384,
          "to_longitude": 105.83954758920042,
          "from_longitude": 105.83917206243436
        }
      },
      {
        "id": 790,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83468971161936,
            21.0021840421926,
            105.83516882301281,
            21.002250471859387
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83468971161936,
              21.0021840421926
            ],
            [
              105.83469283443898,
              21.002184505589046
            ],
            [
              105.83478193882154,
              21.002197737143415
            ],
            [
              105.83478623607182,
              21.00219837542366
            ],
            [
              105.83481629949173,
              21.00220283985429
            ],
            [
              105.83482022227115,
              21.002203422163745
            ],
            [
              105.83507245602267,
              21.002235580784546
            ],
            [
              105.8350802335917,
              21.00223679901534
            ],
            [
              105.83513172904439,
              21.002244904824245
            ],
            [
              105.83513912249458,
              21.00224606171111
            ],
            [
              105.83516882301281,
              21.002250471859387
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Phương Mai",
          "maTaiSan": "04.O17.DOPP.792",
          "duongKinh": 100,
          "chieudaiQL": 50.09,
          "chatLieu": "G-DI",
          "from_latitude": 21.0021840421926,
          "to_latitude": 21.002250471859387,
          "to_longitude": 105.83516882301281,
          "from_longitude": 105.83468971161936
        }
      },
      {
        "id": 791,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83919700623038,
            20.999141034045145,
            105.84105292139544,
            20.999159907401346
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83919700623038,
              20.999141034045145
            ],
            [
              105.83919919185198,
              20.999141095528984
            ],
            [
              105.83921938186569,
              20.99914165445071
            ],
            [
              105.83945968526122,
              20.999148323200927
            ],
            [
              105.83953317779348,
              20.999147473945996
            ],
            [
              105.83964794074753,
              20.999146148335257
            ],
            [
              105.83978585856916,
              20.99915166055079
            ],
            [
              105.83987310796205,
              20.999152840574755
            ],
            [
              105.84000482323633,
              20.999154622182527
            ],
            [
              105.84010923988811,
              20.99915572279499
            ],
            [
              105.84017304471898,
              20.999156395135426
            ],
            [
              105.84023449677112,
              20.999157042032255
            ],
            [
              105.84033301845209,
              20.999158158054495
            ],
            [
              105.84085438430719,
              20.999159907401346
            ],
            [
              105.84105292139544,
              20.999157506778094
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Ngõ 78, Giải Phóng",
          "maTaiSan": "04.O17.DOPP.793",
          "duongKinh": 150,
          "chieudaiQL": 191.6,
          "chatLieu": "G-DI",
          "from_latitude": 20.999141034045145,
          "to_latitude": 20.999157506778094,
          "to_longitude": 105.84105292139544,
          "from_longitude": 105.83919700623038
        }
      },
      {
        "id": 792,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83825597685345,
            21.002460694815145,
            105.83829627700642,
            21.00328002243297
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83825597685345,
              21.002460694815145
            ],
            [
              105.83825639296967,
              21.002466485149835
            ],
            [
              105.83825676757336,
              21.002470870270358
            ],
            [
              105.83825682990648,
              21.002475831380032
            ],
            [
              105.838257107937,
              21.002637563339956
            ],
            [
              105.83827838358675,
              21.00298656145242
            ],
            [
              105.83829627700642,
              21.00328002243297
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Ngõ 15, Phương Mai",
          "maTaiSan": "04.O17.DOPP.794",
          "duongKinh": 150,
          "chieudaiQL": 88.54,
          "chatLieu": "G-DI",
          "from_latitude": 21.002460694815145,
          "to_latitude": 21.00328002243297,
          "to_longitude": 105.83829627700642,
          "from_longitude": 105.83825597685345
        }
      },
      {
        "id": 793,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83758303868828,
            20.999543733953743,
            105.8376639548668,
            21.000965896891532
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8376639548668,
              20.999543733953743
            ],
            [
              105.83766381212843,
              20.999545215946277
            ],
            [
              105.83766282115828,
              20.999562230369172
            ],
            [
              105.83763760597004,
              20.999993386390052
            ],
            [
              105.83763535033445,
              21.000031975160784
            ],
            [
              105.8376233347193,
              21.000237556960982
            ],
            [
              105.83761229791035,
              21.0004454940688
            ],
            [
              105.83760907256125,
              21.000506288894982
            ],
            [
              105.83760751117251,
              21.000535707410627
            ],
            [
              105.83760033592975,
              21.000669659462563
            ],
            [
              105.83760032023994,
              21.000669950378406
            ],
            [
              105.83760030890048,
              21.000670159079313
            ],
            [
              105.83759158503628,
              21.0008344409357
            ],
            [
              105.83758707827985,
              21.00092088012272
            ],
            [
              105.83758306233986,
              21.000965653807476
            ],
            [
              105.83758303868828,
              21.000965896891532
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D200_Ngõ 102, Trường Chinh",
          "maTaiSan": "04.O17.DOPP.795",
          "duongKinh": 200,
          "chieudaiQL": 157.09,
          "chatLieu": "G-DI",
          "from_latitude": 20.999543733953743,
          "to_latitude": 21.000965896891532,
          "to_longitude": 105.83758303868828,
          "from_longitude": 105.8376639548668
        }
      },
      {
        "id": 794,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83777975697888,
            20.998906604018153,
            105.83779922645365,
            20.99955128341065
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83777975697888,
              20.998906604018153
            ],
            [
              105.83778066496886,
              20.998917811291147
            ],
            [
              105.83778508130844,
              20.998972207102796
            ],
            [
              105.83778776349729,
              20.999005063191415
            ],
            [
              105.83779115154684,
              20.999046687031328
            ],
            [
              105.83779132796472,
              20.99908726177322
            ],
            [
              105.83779922645365,
              20.999294758884567
            ],
            [
              105.83779906933572,
              20.999317620300733
            ],
            [
              105.83779745470675,
              20.999547754801807
            ],
            [
              105.83779714737605,
              20.99955128341065
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D110_Ngõ 102, Trường Chinh",
          "maTaiSan": "04.O17.DOPP.796",
          "duongKinh": 110,
          "chieudaiQL": 72.53,
          "chatLieu": "PVC",
          "from_latitude": 20.998906604018153,
          "to_latitude": 20.99955128341065,
          "to_longitude": 105.83779714737605,
          "from_longitude": 105.83777975697888
        }
      },
      {
        "id": 795,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8373490714974,
            21.002718848122598,
            105.83829627700642,
            21.003282015326352
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83829627700642,
              21.00328002243297
            ],
            [
              105.83829056561099,
              21.003281419851334
            ],
            [
              105.83826373890403,
              21.003282015326352
            ],
            [
              105.83825310759079,
              21.00327927581482
            ],
            [
              105.83794360435893,
              21.003197295699874
            ],
            [
              105.83778411358703,
              21.003157387785443
            ],
            [
              105.83778219099109,
              21.00315672615212
            ],
            [
              105.83753480061719,
              21.003092426376288
            ],
            [
              105.8374567603128,
              21.003041891029373
            ],
            [
              105.83738670288584,
              21.00302038939127
            ],
            [
              105.83738291701786,
              21.00301935395243
            ],
            [
              105.8373490714974,
              21.00301008717121
            ],
            [
              105.83737178773617,
              21.002936660611436
            ],
            [
              105.837390928972,
              21.002867757828177
            ],
            [
              105.83739594814173,
              21.00285107314257
            ],
            [
              105.8374009834801,
              21.002834355861122
            ],
            [
              105.837401462394,
              21.00283278821535
            ],
            [
              105.83742547276789,
              21.002744451158904
            ],
            [
              105.83742629602983,
              21.002742712012527
            ],
            [
              105.83743268153886,
              21.002726981635476
            ],
            [
              105.83743597956152,
              21.002718848122598
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D90_Ngách 15/24, Phương Mai",
          "maTaiSan": "04.O17.DOPP.797",
          "duongKinh": 90,
          "chieudaiQL": 135.95,
          "chatLieu": "HDPE",
          "from_latitude": 21.00328002243297,
          "to_latitude": 21.002718848122598,
          "to_longitude": 105.83743597956152,
          "from_longitude": 105.83829627700642
        }
      },
      {
        "id": 796,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83441596377449,
            21.00235838962148,
            105.83460507444148,
            21.00300322202992
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83441596377449,
              21.00300322202992
            ],
            [
              105.83442626026145,
              21.00296426148465
            ],
            [
              105.83447905228255,
              21.002777174909994
            ],
            [
              105.83448987000848,
              21.00274823851646
            ],
            [
              105.83450731291836,
              21.002685209894338
            ],
            [
              105.83451682784462,
              21.002650831627474
            ],
            [
              105.83451691364341,
              21.00265052049709
            ],
            [
              105.83451765301979,
              21.002648046549204
            ],
            [
              105.83454254521088,
              21.002564732882245
            ],
            [
              105.83455012504439,
              21.00254111068328
            ],
            [
              105.8345700016385,
              21.002479163929127
            ],
            [
              105.83460507444148,
              21.00235838962148
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Phương Mai",
          "maTaiSan": "04.O17.DOPP.798",
          "duongKinh": 150,
          "chieudaiQL": 74.28,
          "chatLieu": "G-DI",
          "from_latitude": 21.00300322202992,
          "to_latitude": 21.00235838962148,
          "to_longitude": 105.83460507444148,
          "from_longitude": 105.83441596377449
        }
      },
      {
        "id": 797,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8356871590598,
            21.0010602093597,
            105.83602876144643,
            21.001064873530016
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83602876144643,
              21.001064873530016
            ],
            [
              105.83602139220936,
              21.00106477333549
            ],
            [
              105.83587768001615,
              21.001062810957162
            ],
            [
              105.83586893620219,
              21.00106269221548
            ],
            [
              105.83582401375384,
              21.00106207821554
            ],
            [
              105.83573355804286,
              21.001060842340173
            ],
            [
              105.83572479595436,
              21.0010607236808
            ],
            [
              105.8356871590598,
              21.0010602093597
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 36, Lương Định Của",
          "maTaiSan": "04.O17.DOPP.799",
          "duongKinh": 100,
          "chieudaiQL": 34.75,
          "chatLieu": "G-DI",
          "from_latitude": 21.001064873530016,
          "to_latitude": 21.0010602093597,
          "to_longitude": 105.8356871590598,
          "from_longitude": 105.83602876144643
        }
      },
      {
        "id": 798,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83765996873159,
            20.999482268229823,
            105.83920577739286,
            20.99957717813082
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83920577739286,
              20.999482268229823
            ],
            [
              105.8390466669982,
              20.999499431611724
            ],
            [
              105.8390301201311,
              20.999509157808184
            ],
            [
              105.83899264175058,
              20.999537795028623
            ],
            [
              105.83898896689296,
              20.99954060320347
            ],
            [
              105.83895083199432,
              20.99956226081436
            ],
            [
              105.8388902551773,
              20.999573687980284
            ],
            [
              105.83876369718364,
              20.999575577915028
            ],
            [
              105.83876367029342,
              20.99957558527333
            ],
            [
              105.83857655183665,
              20.99957702918864
            ],
            [
              105.83856426668719,
              20.999577090608245
            ],
            [
              105.83854576535701,
              20.99957717813082
            ],
            [
              105.83848491816657,
              20.999574818746954
            ],
            [
              105.83839558584664,
              20.99957134595439
            ],
            [
              105.83824563705187,
              20.999565662444517
            ],
            [
              105.83824276248816,
              20.999565553760352
            ],
            [
              105.83817820977382,
              20.999563095996603
            ],
            [
              105.83813241243305,
              20.999561777868877
            ],
            [
              105.83813153025478,
              20.999561746083167
            ],
            [
              105.83812797475733,
              20.999561645265693
            ],
            [
              105.83808098256078,
              20.999560280619725
            ],
            [
              105.8380592204593,
              20.999559650723203
            ],
            [
              105.8378033847519,
              20.999551263552423
            ],
            [
              105.83779714737605,
              20.99955128341065
            ],
            [
              105.8376639548668,
              20.999543733953743
            ],
            [
              105.83765996873159,
              20.999543507890664
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Ngách 74/3, Trường Chinh",
          "maTaiSan": "04.O17.DOPP.800",
          "duongKinh": 150,
          "chieudaiQL": 164.31,
          "chatLieu": "G-DI",
          "from_latitude": 20.999482268229823,
          "to_latitude": 20.999543507890664,
          "to_longitude": 105.83765996873159,
          "from_longitude": 105.83920577739286
        }
      },
      {
        "id": 799,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83607759506599,
            21.001829712833917,
            105.83696666388934,
            21.001977548938942
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83607759506599,
              21.00183002451243
            ],
            [
              105.83607883220495,
              21.001830060887496
            ],
            [
              105.83647235600631,
              21.001832152308705
            ],
            [
              105.83695131888854,
              21.001829774488883
            ],
            [
              105.83696440929207,
              21.001829712833917
            ],
            [
              105.83696624234187,
              21.001955767920233
            ],
            [
              105.83696629873405,
              21.00195967138393
            ],
            [
              105.8369663829441,
              21.001964423742923
            ],
            [
              105.83696666388934,
              21.001977548938942
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 89, Lương Định Của",
          "maTaiSan": "04.O17.DOPP.801",
          "duongKinh": 100,
          "chieudaiQL": 105.49,
          "chatLieu": "G-DI",
          "from_latitude": 21.00183002451243,
          "to_latitude": 21.001977548938942,
          "to_longitude": 105.83696666388934,
          "from_longitude": 105.83607759506599
        }
      },
      {
        "id": 800,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83768257898983,
            21.002004718871174,
            105.83825676757336,
            21.00247124710594
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83825676757336,
              21.002470870270358
            ],
            [
              105.8382565906127,
              21.002470873851518
            ],
            [
              105.83822793336752,
              21.00247124710594
            ],
            [
              105.8382252827238,
              21.002145242781253
            ],
            [
              105.83822447561235,
              21.002046012095636
            ],
            [
              105.83810619874912,
              21.002050449464928
            ],
            [
              105.8381023179347,
              21.002049632186228
            ],
            [
              105.837739395435,
              21.0020469495609
            ],
            [
              105.83771507535675,
              21.002047515445394
            ],
            [
              105.83769663293606,
              21.002023227249147
            ],
            [
              105.83769640910174,
              21.002022932092757
            ],
            [
              105.83768952747522,
              21.00201386957566
            ],
            [
              105.83768257898983,
              21.002004718871174
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D90_Ngõ 15, Phương Mai",
          "maTaiSan": "04.O17.DOPP.802",
          "duongKinh": 90,
          "chieudaiQL": 112.52,
          "chatLieu": "HDPE",
          "from_latitude": 21.002470870270358,
          "to_latitude": 21.002004718871174,
          "to_longitude": 105.83768257898983,
          "from_longitude": 105.83825676757336
        }
      },
      {
        "id": 801,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83592094373947,
            20.999543507890664,
            105.83765996873159,
            21.00014119858211
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83765996873159,
              20.999543507890664
            ],
            [
              105.83751674739544,
              20.999544441084677
            ],
            [
              105.83725086170726,
              20.99955315994409
            ],
            [
              105.83696709715225,
              20.999565626163424
            ],
            [
              105.83665327420539,
              20.999578423900132
            ],
            [
              105.83636650526081,
              20.999590118079947
            ],
            [
              105.83626501678647,
              20.99963662609256
            ],
            [
              105.83619209977252,
              20.999703898869576
            ],
            [
              105.83618718393888,
              20.99971134211421
            ],
            [
              105.8361274966914,
              20.999801719431908
            ],
            [
              105.8361058366083,
              20.99985723240748
            ],
            [
              105.83609236982565,
              20.99989174849083
            ],
            [
              105.83608403379098,
              21.000039956423393
            ],
            [
              105.8360621620935,
              21.00010549262765
            ],
            [
              105.83605399674548,
              21.000129959572437
            ],
            [
              105.8359947418869,
              21.00014119858211
            ],
            [
              105.8359261990836,
              21.00013249569345
            ],
            [
              105.83592281968662,
              21.000132066997708
            ],
            [
              105.83592094373947,
              21.000131828725667
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 36, Trường Chinh",
          "maTaiSan": "04.O17.DOPP.803",
          "duongKinh": 100,
          "chieudaiQL": 215.87,
          "chatLieu": "G-DI",
          "from_latitude": 20.999543507890664,
          "to_latitude": 21.000131828725667,
          "to_longitude": 105.83592094373947,
          "from_longitude": 105.83765996873159
        }
      },
      {
        "id": 802,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83403061905462,
            21.002250179876945,
            105.83457836491785,
            21.002368273615637
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83457836491785,
              21.002368273615637
            ],
            [
              105.83455945567826,
              21.00236374000424
            ],
            [
              105.83454922428216,
              21.00236128552229
            ],
            [
              105.83426196325152,
              21.00228977625053
            ],
            [
              105.83425955195031,
              21.002289267810543
            ],
            [
              105.83418972757632,
              21.002276159175008
            ],
            [
              105.83418728990662,
              21.002275752024524
            ],
            [
              105.83418726198862,
              21.002275747645218
            ],
            [
              105.83410271316188,
              21.002262385069002
            ],
            [
              105.83403061905462,
              21.002250179876945
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Phương Mai",
          "maTaiSan": "04.O17.DOPP.804",
          "duongKinh": 100,
          "chieudaiQL": 58.46,
          "chatLieu": "G-DI",
          "from_latitude": 21.002368273615637,
          "to_latitude": 21.002250179876945,
          "to_longitude": 105.83403061905462,
          "from_longitude": 105.83457836491785
        }
      },
      {
        "id": 803,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83482600950488,
            21.002909307267913,
            105.83509692517909,
            21.003160709583902
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83482600950488,
              21.002909307267913
            ],
            [
              105.8349156168026,
              21.002933847369164
            ],
            [
              105.83492246313675,
              21.00293572230297
            ],
            [
              105.83505754697094,
              21.002972707896706
            ],
            [
              105.83509006778826,
              21.002981612217944
            ],
            [
              105.83509692517909,
              21.002983571993575
            ],
            [
              105.83504949665834,
              21.003160709583902
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Phương Mai",
          "maTaiSan": "04.O17.DOPP.805",
          "duongKinh": 100,
          "chieudaiQL": 48.97,
          "chatLieu": "G-DI",
          "from_latitude": 21.002909307267913,
          "to_latitude": 21.003160709583902,
          "to_longitude": 105.83504949665834,
          "from_longitude": 105.83482600950488
        }
      },
      {
        "id": 804,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8362407204601,
            21.000905195845355,
            105.83625456961475,
            21.001530621287564
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8362407204601,
              21.001530621287564
            ],
            [
              105.8362408006984,
              21.00152844953866
            ],
            [
              105.83624090080012,
              21.001525699627685
            ],
            [
              105.83624802207996,
              21.001332635361514
            ],
            [
              105.83625190259927,
              21.001227396816937
            ],
            [
              105.83625337090575,
              21.00107711270257
            ],
            [
              105.83625430100756,
              21.000917408777983
            ],
            [
              105.83625456961475,
              21.000905195845355
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 91, Lương Định Của",
          "maTaiSan": "04.O17.DOPP.806",
          "duongKinh": 100,
          "chieudaiQL": 69.36,
          "chatLieu": "G-DI",
          "from_latitude": 21.001530621287564,
          "to_latitude": 21.000905195845355,
          "to_longitude": 105.83625456961475,
          "from_longitude": 105.8362407204601
        }
      },
      {
        "id": 805,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8391445276986,
            20.9985504513642,
            105.83921033260576,
            20.999482268229823
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8391445276986,
              20.9985504513642
            ],
            [
              105.83914786307699,
              20.99856193840435
            ],
            [
              105.83915849187287,
              20.99859852806725
            ],
            [
              105.83917453283946,
              20.998726308701855
            ],
            [
              105.83918001059834,
              20.99888687519936
            ],
            [
              105.83919700623038,
              20.999141034045145
            ],
            [
              105.83921033260576,
              20.999325864026755
            ],
            [
              105.83921031291881,
              20.999330429025335
            ],
            [
              105.83920577739286,
              20.999482268229823
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Ngõ 74, Trường Chinh",
          "maTaiSan": "04.O17.DOPP.807",
          "duongKinh": 150,
          "chieudaiQL": 103.7,
          "chatLieu": "G-DI",
          "from_latitude": 20.9985504513642,
          "to_latitude": 20.999482268229823,
          "to_longitude": 105.83920577739286,
          "from_longitude": 105.8391445276986
        }
      },
      {
        "id": 806,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83449129007855,
            21.002368273615637,
            105.83457836491785,
            21.002685209894338
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83450731291836,
              21.002685209894338
            ],
            [
              105.83450413262052,
              21.002684444195907
            ],
            [
              105.83449129007855,
              21.002681353093283
            ],
            [
              105.83450133820509,
              21.0026465151808
            ],
            [
              105.83450194685821,
              21.00264440316421
            ],
            [
              105.83453295650837,
              21.00253688195448
            ],
            [
              105.83453844484293,
              21.00251785297865
            ],
            [
              105.83454775499257,
              21.00248557119809
            ],
            [
              105.83457836491785,
              21.002368273615637
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Phương Mai",
          "maTaiSan": "04.O17.DOPP.808",
          "duongKinh": 100,
          "chieudaiQL": 36.27,
          "chatLieu": "G-DI",
          "from_latitude": 21.002685209894338,
          "to_latitude": 21.002368273615637,
          "to_longitude": 105.83457836491785,
          "from_longitude": 105.83450731291836
        }
      },
      {
        "id": 807,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83971087148126,
            20.998416546616212,
            105.83971461607348,
            20.998638492986768
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83971087148126,
              20.998416546616212
            ],
            [
              105.83971162185203,
              20.998426718653295
            ],
            [
              105.83971409321425,
              20.99846015651567
            ],
            [
              105.83971457426928,
              20.99852054018501
            ],
            [
              105.83971461607348,
              20.99852577868652
            ],
            [
              105.83971445493658,
              20.998638492986768
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Trường Chinh",
          "maTaiSan": "04.O17.DOPP.809",
          "duongKinh": 100,
          "chieudaiQL": 43.48,
          "chatLieu": "G-DI",
          "from_latitude": 20.998416546616212,
          "to_latitude": 20.998638492986768,
          "to_longitude": 105.83971445493658,
          "from_longitude": 105.83971087148126
        }
      },
      {
        "id": 808,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83447905228255,
            21.002777174909994,
            105.83466308364585,
            21.00282712601233
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83447905228255,
              21.002777174909994
            ],
            [
              105.83448121971477,
              21.002777761316377
            ],
            [
              105.83462078610972,
              21.00281536657894
            ],
            [
              105.83462651179742,
              21.0028170546328
            ],
            [
              105.83462664475071,
              21.002817093722815
            ],
            [
              105.83466308364585,
              21.00282712601233
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Phương Mai",
          "maTaiSan": "04.O17.DOPP.810",
          "duongKinh": 100,
          "chieudaiQL": 25.93,
          "chatLieu": "G-DI",
          "from_latitude": 21.002777174909994,
          "to_latitude": 21.00282712601233,
          "to_longitude": 105.83466308364585,
          "from_longitude": 105.83447905228255
        }
      },
      {
        "id": 809,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83970472533653,
            20.998781430912228,
            105.83970955368605,
            20.999123290325855
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83970472533653,
              20.999123290325855
            ],
            [
              105.8397049375089,
              20.99910946360798
            ],
            [
              105.83970827592915,
              20.99889295213688
            ],
            [
              105.83970864050717,
              20.998861147771105
            ],
            [
              105.83970864582717,
              20.998860723228923
            ],
            [
              105.83970873997463,
              20.99885244921955
            ],
            [
              105.83970879814497,
              20.99884737280568
            ],
            [
              105.83970955368605,
              20.998781430912228
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 78, Giải Phóng",
          "maTaiSan": "04.O17.DOPP.811",
          "duongKinh": 100,
          "chieudaiQL": 36.86,
          "chatLieu": "G-DI",
          "from_latitude": 20.999123290325855,
          "to_latitude": 20.998781430912228,
          "to_longitude": 105.83970955368605,
          "from_longitude": 105.83970472533653
        }
      },
      {
        "id": 810,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.834346111795,
            21.002303106386215,
            105.83460507444148,
            21.00235838962148
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83460507444148,
              21.00235838962148
            ],
            [
              105.83460358100317,
              21.002358100686
            ],
            [
              105.834569967012,
              21.00235132420731
            ],
            [
              105.83455369444565,
              21.002348043990377
            ],
            [
              105.83446120689351,
              21.002329401419296
            ],
            [
              105.8344578488466,
              21.00232866910729
            ],
            [
              105.834346111795,
              21.002303106386215
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Phương Mai",
          "maTaiSan": "04.O17.DOPP.812",
          "duongKinh": 100,
          "chieudaiQL": 28.8,
          "chatLieu": "G-DI",
          "from_latitude": 21.00235838962148,
          "to_latitude": 21.002303106386215,
          "to_longitude": 105.834346111795,
          "from_longitude": 105.83460507444148
        }
      },
      {
        "id": 811,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83625337090575,
            21.001074867540638,
            105.83651545772202,
            21.00107711270257
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83625337090575,
              21.00107711270257
            ],
            [
              105.83625877344011,
              21.001077087968177
            ],
            [
              105.83642040686611,
              21.001076318224907
            ],
            [
              105.83642400394956,
              21.001076280679154
            ],
            [
              105.83647971826203,
              21.001075511038316
            ],
            [
              105.83648299508069,
              21.001075478677997
            ],
            [
              105.83650068149403,
              21.00107514157253
            ],
            [
              105.83651545772202,
              21.001074867540638
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 91, Lương Định Của",
          "maTaiSan": "04.O17.DOPP.813",
          "duongKinh": 100,
          "chieudaiQL": 25.91,
          "chatLieu": "G-DI",
          "from_latitude": 21.00107711270257,
          "to_latitude": 21.001074867540638,
          "to_longitude": 105.83651545772202,
          "from_longitude": 105.83625337090575
        }
      },
      {
        "id": 812,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83756595149539,
            21.00346406592362,
            105.83829690579014,
            21.003761651526776
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83759348650806,
              21.00346406592362
            ],
            [
              105.83758917641133,
              21.003479231512458
            ],
            [
              105.83758790761688,
              21.00348369426987
            ],
            [
              105.83758718786233,
              21.003486228649106
            ],
            [
              105.83757219071089,
              21.003538996613877
            ],
            [
              105.83756595149539,
              21.003560951304287
            ],
            [
              105.83761975693703,
              21.003582500322484
            ],
            [
              105.83786884054203,
              21.00364550632232
            ],
            [
              105.83787068616657,
              21.003645990397974
            ],
            [
              105.83805106444926,
              21.003696612395988
            ],
            [
              105.83805815291863,
              21.00369860162197
            ],
            [
              105.83826169056334,
              21.00375362638911
            ],
            [
              105.83829690579014,
              21.003761651526776
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngách 15/2, Phương Mai",
          "maTaiSan": "04.O17.DOPP.814",
          "duongKinh": 100,
          "chieudaiQL": 90.33,
          "chatLieu": "G-DI",
          "from_latitude": 21.00346406592362,
          "to_latitude": 21.003761651526776,
          "to_longitude": 105.83829690579014,
          "from_longitude": 105.83759348650806
        }
      },
      {
        "id": 813,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83761891318248,
            20.99950528764866,
            105.83923421376484,
            20.999591470168205
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83923421376484,
              20.99950528764866
            ],
            [
              105.83920367847566,
              20.999507008889804
            ],
            [
              105.83919803718624,
              20.99950732753809
            ],
            [
              105.83906756443955,
              20.999514695187006
            ],
            [
              105.8389986001831,
              20.99954620630485
            ],
            [
              105.83899742494366,
              20.9995467440968
            ],
            [
              105.8389474564423,
              20.999569575511742
            ],
            [
              105.83889182615071,
              20.999591470168205
            ],
            [
              105.83877420500713,
              20.99958914136297
            ],
            [
              105.83876929321349,
              20.99958884853202
            ],
            [
              105.8387632242778,
              20.99958871766033
            ],
            [
              105.83854207907034,
              20.99958396867406
            ],
            [
              105.83853626326317,
              20.99958403977313
            ],
            [
              105.8384843063073,
              20.999582888468243
            ],
            [
              105.83827104379077,
              20.999578394754668
            ],
            [
              105.83825904447538,
              20.999578142228653
            ],
            [
              105.83824644588898,
              20.999577880910746
            ],
            [
              105.83824323682813,
              20.999577811809164
            ],
            [
              105.83816459963512,
              20.99957615229566
            ],
            [
              105.83813121751984,
              20.999575847878248
            ],
            [
              105.83812783453332,
              20.99957580853401
            ],
            [
              105.83812780959192,
              20.999575820398746
            ],
            [
              105.83808065132749,
              20.999575376957367
            ],
            [
              105.83805825966648,
              20.999575171063302
            ],
            [
              105.83766282115828,
              20.999562230369172
            ],
            [
              105.83762787811813,
              20.999561087211166
            ],
            [
              105.83761891318248,
              20.999560798032043
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D200_Ngách 74/3, Trường Chinh",
          "maTaiSan": "04.O17.DOPP.815",
          "duongKinh": 200,
          "chieudaiQL": 170.12,
          "chatLieu": "G-DI",
          "from_latitude": 20.99950528764866,
          "to_latitude": 20.999560798032043,
          "to_longitude": 105.83761891318248,
          "from_longitude": 105.83923421376484
        }
      },
      {
        "id": 814,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83534826945588,
            21.00268325680334,
            105.83604355039695,
            21.002688370281476
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83604355039695,
              21.00268325680334
            ],
            [
              105.83604102767053,
              21.002683295387797
            ],
            [
              105.83584153606967,
              21.002686172792565
            ],
            [
              105.83577503515993,
              21.002686120883133
            ],
            [
              105.83568171152824,
              21.00268632889664
            ],
            [
              105.83552400510372,
              21.002686679485727
            ],
            [
              105.83539066256064,
              21.002687054871355
            ],
            [
              105.83538816217683,
              21.002687133078414
            ],
            [
              105.83538685137727,
              21.002687170221336
            ],
            [
              105.83538548868425,
              21.00268721574795
            ],
            [
              105.83538262574187,
              21.002687304766567
            ],
            [
              105.83537969065738,
              21.002687393235984
            ],
            [
              105.83536703581784,
              21.0026877868271
            ],
            [
              105.83535940098243,
              21.002688023909677
            ],
            [
              105.83534826945588,
              21.002688370281476
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 28C, Lương Định Của",
          "maTaiSan": "04.O17.DOPP.816",
          "duongKinh": 100,
          "chieudaiQL": 70.96,
          "chatLieu": "G-DI",
          "from_latitude": 21.00268325680334,
          "to_latitude": 21.002688370281476,
          "to_longitude": 105.83534826945588,
          "from_longitude": 105.83604355039695
        }
      },
      {
        "id": 815,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83921654774484,
            20.99911161449706,
            105.84063814189648,
            20.9991454850408
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84063814189648,
              20.9991454850408
            ],
            [
              105.84051913754782,
              20.999142215875956
            ],
            [
              105.84050533635197,
              20.999141890210424
            ],
            [
              105.84049114748376,
              20.99914155471586
            ],
            [
              105.84048889834278,
              20.999141486336423
            ],
            [
              105.84048886572747,
              20.999141501852286
            ],
            [
              105.84033347598351,
              20.999137828710708
            ],
            [
              105.84017479924444,
              20.999134079520456
            ],
            [
              105.83990558498633,
              20.99912771790504
            ],
            [
              105.8397879597675,
              20.999124938197742
            ],
            [
              105.83972130288056,
              20.999123363006948
            ],
            [
              105.83970472533653,
              20.999123290325855
            ],
            [
              105.83946020968897,
              20.99912221920589
            ],
            [
              105.83921979375295,
              20.999111756559692
            ],
            [
              105.83921654774484,
              20.99911161449706
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Ngõ 78, Giải Phóng",
          "maTaiSan": "04.O17.DOPP.817",
          "duongKinh": 150,
          "chieudaiQL": 146.14,
          "chatLieu": "G-DI",
          "from_latitude": 20.9991454850408,
          "to_latitude": 20.99911161449706,
          "to_longitude": 105.83921654774484,
          "from_longitude": 105.84063814189648
        }
      },
      {
        "id": 816,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83758303868828,
            21.000928754586628,
            105.8380973888937,
            21.000965896891532
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83758303868828,
              21.000965896891532
            ],
            [
              105.83758549158453,
              21.000965760177063
            ],
            [
              105.83778659565745,
              21.00095511415023
            ],
            [
              105.83791386882007,
              21.00095051803437
            ],
            [
              105.83792672130221,
              21.000949547027407
            ],
            [
              105.83792772790957,
              21.00094946800792
            ],
            [
              105.83795866433454,
              21.00094184062728
            ],
            [
              105.83807148719158,
              21.00093198017403
            ],
            [
              105.83807612657112,
              21.00093136572018
            ],
            [
              105.8380973888937,
              21.000928754586628
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngách 102/70, Trường Chinh",
          "maTaiSan": "04.O17.DOPP.818",
          "duongKinh": 100,
          "chieudaiQL": 57.65,
          "chatLieu": "G-DI",
          "from_latitude": 21.000965896891532,
          "to_latitude": 21.000928754586628,
          "to_longitude": 105.8380973888937,
          "from_longitude": 105.83758303868828
        }
      },
      {
        "id": 817,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83612353154682,
            21.003414861465156,
            105.83827187484441,
            21.00395255402601
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83612353154682,
              21.003414861465156
            ],
            [
              105.83612856545555,
              21.003416160865555
            ],
            [
              105.83699796369064,
              21.003639149297893
            ],
            [
              105.83702244036225,
              21.00364488363886
            ],
            [
              105.83729689240317,
              21.00370918017513
            ],
            [
              105.8373003866002,
              21.003709999372823
            ],
            [
              105.83748937360143,
              21.003754273300892
            ],
            [
              105.83754632120456,
              21.00377008133419
            ],
            [
              105.83794216486453,
              21.00387393140613
            ],
            [
              105.83802044224656,
              21.003894467425805
            ],
            [
              105.83823438247228,
              21.00394446151857
            ],
            [
              105.83824697524726,
              21.00394740543409
            ],
            [
              105.83827187484441,
              21.00395255402601
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D200_Phương Mai",
          "maTaiSan": "04.O17.DOPP.819",
          "duongKinh": 200,
          "chieudaiQL": 128.03,
          "chatLieu": "G-DI",
          "from_latitude": 21.003414861465156,
          "to_latitude": 21.00395255402601,
          "to_longitude": 105.83827187484441,
          "from_longitude": 105.83612353154682
        }
      },
      {
        "id": 818,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.834346111795,
            21.002015092867314,
            105.83441890220189,
            21.002303106386215
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.834346111795,
              21.002303106386215
            ],
            [
              105.83434871376191,
              21.00229327195265
            ],
            [
              105.83434960564239,
              21.002289855193073
            ],
            [
              105.83440848284651,
              21.00207121802087
            ],
            [
              105.83440946504768,
              21.00206795797956
            ],
            [
              105.83441632189789,
              21.002030725906845
            ],
            [
              105.83441890220189,
              21.002015092867314
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Phương Mai",
          "maTaiSan": "04.O17.DOPP.820",
          "duongKinh": 100,
          "chieudaiQL": 32.38,
          "chatLieu": "G-DI",
          "from_latitude": 21.002303106386215,
          "to_latitude": 21.002015092867314,
          "to_longitude": 105.83441890220189,
          "from_longitude": 105.834346111795
        }
      },
      {
        "id": 819,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83396158863071,
            21.002653658596177,
            105.83448987000848,
            21.002780623534335
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83448987000848,
              21.00274823851646
            ],
            [
              105.83448732336541,
              21.00274760880798
            ],
            [
              105.83447328017641,
              21.002744127074262
            ],
            [
              105.83445765865628,
              21.002780623534335
            ],
            [
              105.83441314999324,
              21.002768717726994
            ],
            [
              105.83436636508813,
              21.002756202549442
            ],
            [
              105.83436110843537,
              21.00275479670371
            ],
            [
              105.83425978516016,
              21.002727692565884
            ],
            [
              105.83414229986136,
              21.002699025505393
            ],
            [
              105.83413227240064,
              21.002696578128553
            ],
            [
              105.83403430172395,
              21.0026726729092
            ],
            [
              105.83398028055714,
              21.002658546499454
            ],
            [
              105.83397607884834,
              21.002657447989023
            ],
            [
              105.83396158863071,
              21.002653658596177
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Phương Mai",
          "maTaiSan": "04.O17.DOPP.821",
          "duongKinh": 100,
          "chieudaiQL": 54.29,
          "chatLieu": "G-DI",
          "from_latitude": 21.00274823851646,
          "to_latitude": 21.002653658596177,
          "to_longitude": 105.83396158863071,
          "from_longitude": 105.83448987000848
        }
      },
      {
        "id": 820,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83561399753228,
            21.003118514323607,
            105.83605582889074,
            21.00321124168481
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83605582889074,
              21.003180131372265
            ],
            [
              105.83605517810993,
              21.003180029794645
            ],
            [
              105.83602557498598,
              21.003175355587302
            ],
            [
              105.83601600433371,
              21.00321124168481
            ],
            [
              105.8359045634222,
              21.00318564694857
            ],
            [
              105.83584975749561,
              21.003172984610053
            ],
            [
              105.83584408582328,
              21.003171673881294
            ],
            [
              105.83567975477806,
              21.00313370710094
            ],
            [
              105.83566899892982,
              21.00313122184409
            ],
            [
              105.83561399753228,
              21.003118514323607
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 28A, Lương Định Của",
          "maTaiSan": "04.O17.DOPP.822",
          "duongKinh": 100,
          "chieudaiQL": 45.37,
          "chatLieu": "G-DI",
          "from_latitude": 21.003180131372265,
          "to_latitude": 21.003118514323607,
          "to_longitude": 105.83561399753228,
          "from_longitude": 105.83605582889074
        }
      },
      {
        "id": 821,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83397563657924,
            21.002250179876945,
            105.83403061905462,
            21.002450784812233
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83397563657924,
              21.002450784812233
            ],
            [
              105.83398050361996,
              21.002434524574465
            ],
            [
              105.83398161704731,
              21.00243081769881
            ],
            [
              105.83398976832954,
              21.00240373788262
            ],
            [
              105.83401245011933,
              21.002326250986304
            ],
            [
              105.83403061905462,
              21.002250179876945
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Phương Mai",
          "maTaiSan": "04.O17.DOPP.823",
          "duongKinh": 100,
          "chieudaiQL": 25.21,
          "chatLieu": "G-DI",
          "from_latitude": 21.002450784812233,
          "to_latitude": 21.002250179876945,
          "to_longitude": 105.83403061905462,
          "from_longitude": 105.83397563657924
        }
      },
      {
        "id": 822,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8328113597755,
            21.005839700835324,
            105.83536564575701,
            21.006138589667337
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83536564575701,
              21.006138589667337
            ],
            [
              105.8353372987408,
              21.006137282739417
            ],
            [
              105.83515304358116,
              21.00612877991288
            ],
            [
              105.83502048688037,
              21.006132298077034
            ],
            [
              105.83488415257511,
              21.0060913264257
            ],
            [
              105.83477341045024,
              21.006058045651514
            ],
            [
              105.8347277046102,
              21.00604430957387
            ],
            [
              105.83408275517361,
              21.005890005936077
            ],
            [
              105.83392435550918,
              21.005857093636585
            ],
            [
              105.8337732991077,
              21.005849676695036
            ],
            [
              105.83358977583475,
              21.005839700835324
            ],
            [
              105.83340519872577,
              21.005850401162988
            ],
            [
              105.83325740323012,
              21.005859292697895
            ],
            [
              105.83309680644732,
              21.005869801219532
            ],
            [
              105.83295185906051,
              21.005887365774953
            ],
            [
              105.83290541667932,
              21.00588317087131
            ],
            [
              105.83287544696931,
              21.005880465149783
            ],
            [
              105.83282073313485,
              21.005875523087834
            ],
            [
              105.8328113597755,
              21.005874675664668
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Đông Tác",
          "maTaiSan": "04.O17.DOPP.824",
          "duongKinh": 150,
          "chieudaiQL": 259.49,
          "chatLieu": "G",
          "from_latitude": 21.006138589667337,
          "to_latitude": 21.005874675664668,
          "to_longitude": 105.8328113597755,
          "from_longitude": 105.83536564575701
        }
      },
      {
        "id": 823,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83238837840963,
            21.00555327127696,
            105.83285814633486,
            21.00706983409288
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83238837840963,
              21.00706983409288
            ],
            [
              105.8324083389246,
              21.00700923297692
            ],
            [
              105.83241162326051,
              21.00699926065408
            ],
            [
              105.8324228517015,
              21.006965172183243
            ],
            [
              105.83241188043195,
              21.006952678232654
            ],
            [
              105.83240603550483,
              21.00694633907894
            ],
            [
              105.83239564797391,
              21.00693364898173
            ],
            [
              105.83239347381951,
              21.00693099329297
            ],
            [
              105.83240973191624,
              21.00688979170572
            ],
            [
              105.83241260699691,
              21.006882512094382
            ],
            [
              105.83250265010832,
              21.00661194299255
            ],
            [
              105.8327346042365,
              21.00586637575309
            ],
            [
              105.83283685706003,
              21.005819024180905
            ],
            [
              105.83285814633486,
              21.005809166064626
            ],
            [
              105.83282456311156,
              21.00555327127696
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 110, Phạm Ngọc Thạch",
          "maTaiSan": "04.O17.DOPP.825",
          "duongKinh": 100,
          "chieudaiQL": 182.88,
          "chatLieu": "G",
          "from_latitude": 21.00706983409288,
          "to_latitude": 21.00555327127696,
          "to_longitude": 105.83282456311156,
          "from_longitude": 105.83238837840963
        }
      },
      {
        "id": 824,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83357902838557,
            21.004388649692697,
            105.83403353092288,
            21.005839700835324
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83358977583475,
              21.005839700835324
            ],
            [
              105.83358913133553,
              21.005820990960572
            ],
            [
              105.83358388022462,
              21.005668385975216
            ],
            [
              105.83357902838557,
              21.00556359038562
            ],
            [
              105.83358348437986,
              21.005387584045256
            ],
            [
              105.83358717120869,
              21.005374592052352
            ],
            [
              105.83358802123034,
              21.0053715991149
            ],
            [
              105.83362112816724,
              21.00526065450776
            ],
            [
              105.833678995395,
              21.00514813170946
            ],
            [
              105.83370015798545,
              21.005086233852925
            ],
            [
              105.83370448911994,
              21.00507356476597
            ],
            [
              105.833724857143,
              21.00501390989537
            ],
            [
              105.83372952770871,
              21.004975077208908
            ],
            [
              105.83373256661409,
              21.004943803483
            ],
            [
              105.83373376486027,
              21.004931466774213
            ],
            [
              105.83374598182643,
              21.00484633118316
            ],
            [
              105.83375756094962,
              21.004823332524527
            ],
            [
              105.8337955836801,
              21.004785782515235
            ],
            [
              105.83384647984637,
              21.00473262039596
            ],
            [
              105.83388765133269,
              21.004656229778064
            ],
            [
              105.83390850089437,
              21.00457697162309
            ],
            [
              105.83391233848008,
              21.004562383787825
            ],
            [
              105.83392749065418,
              21.004532245676067
            ],
            [
              105.83394594209994,
              21.004495543913354
            ],
            [
              105.83402242934632,
              21.004402198286737
            ],
            [
              105.83403353092288,
              21.004388649692697
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 19, Đông Tác",
          "maTaiSan": "04.O17.DOPP.826",
          "duongKinh": 100,
          "chieudaiQL": 172.77,
          "chatLieu": "G",
          "from_latitude": 21.005839700835324,
          "to_latitude": 21.004388649692697,
          "to_longitude": 105.83403353092288,
          "from_longitude": 105.83358977583475
        }
      },
      {
        "id": 825,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83942217685642,
            21.00779236028409,
            105.84013462931513,
            21.00791439168902
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84013462931513,
              21.00779236028409
            ],
            [
              105.8400906061444,
              21.007798616567786
            ],
            [
              105.84007796740953,
              21.007800413149095
            ],
            [
              105.83959061866008,
              21.007886393567194
            ],
            [
              105.83958756046232,
              21.007887016525054
            ],
            [
              105.83958421980128,
              21.007887698682786
            ],
            [
              105.83942217685642,
              21.00791439168902
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D160_Đào Duy Anh",
          "maTaiSan": "04.O17.DOPP.827",
          "duongKinh": 160,
          "chieudaiQL": 76.18,
          "chatLieu": "PVC",
          "from_latitude": 21.00779236028409,
          "to_latitude": 21.00791439168902,
          "to_longitude": 105.83942217685642,
          "from_longitude": 105.84013462931513
        }
      },
      {
        "id": 826,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83764205800139,
            21.00791439168902,
            105.83942217685642,
            21.00938797585589
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83942217685642,
              21.00791439168902
            ],
            [
              105.83893722244521,
              21.00799177781148
            ],
            [
              105.8387087262164,
              21.00822306830588
            ],
            [
              105.8387087012736,
              21.008223080170723
            ],
            [
              105.83848156425869,
              21.00845622883415
            ],
            [
              105.83848151245432,
              21.00845625347645
            ],
            [
              105.83848148751146,
              21.008456265341255
            ],
            [
              105.83832929511571,
              21.008626320999177
            ],
            [
              105.83820909757068,
              21.008782044631655
            ],
            [
              105.8380120766304,
              21.008966251255437
            ],
            [
              105.83788899898066,
              21.0090944865675
            ],
            [
              105.83767326774588,
              21.009356457748822
            ],
            [
              105.83767324280286,
              21.009356469613536
            ],
            [
              105.83764205800139,
              21.00938797585589
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D160_Đào Duy Anh",
          "maTaiSan": "04.O17.DOPP.828",
          "duongKinh": 160,
          "chieudaiQL": 255.24,
          "chatLieu": "PVC",
          "from_latitude": 21.00791439168902,
          "to_latitude": 21.00938797585589,
          "to_longitude": 105.83764205800139,
          "from_longitude": 105.83942217685642
        }
      },
      {
        "id": 827,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83958756046232,
            21.007887016525054,
            105.83959715372363,
            21.00795842599718
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83959715372363,
              21.00795842599718
            ],
            [
              105.83959673723797,
              21.00795533360478
            ],
            [
              105.83958756046232,
              21.007887016525054
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D160_Đào Duy Anh",
          "maTaiSan": "04.O17.DOPP.829",
          "duongKinh": 160,
          "chieudaiQL": 9.16,
          "chatLieu": "PVC",
          "from_latitude": 21.00795842599718,
          "to_latitude": 21.007887016525054,
          "to_longitude": 105.83958756046232,
          "from_longitude": 105.83959715372363
        }
      },
      {
        "id": 828,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84012306195655,
            21.004245396475785,
            105.84108265256822,
            21.00427116910819
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84108265256822,
              21.004245396475785
            ],
            [
              105.84104138214141,
              21.004247149444218
            ],
            [
              105.84099726238125,
              21.004248678030297
            ],
            [
              105.84087909043967,
              21.00425118124445
            ],
            [
              105.84087609191106,
              21.004251280961586
            ],
            [
              105.84076254369678,
              21.00425482525039
            ],
            [
              105.84075862543521,
              21.004254890670023
            ],
            [
              105.84074565786145,
              21.00425510827401
            ],
            [
              105.84070682628506,
              21.00425575530928
            ],
            [
              105.84053493430056,
              21.004259804394955
            ],
            [
              105.84053091793639,
              21.004259871197082
            ],
            [
              105.84029955180051,
              21.00426628212621
            ],
            [
              105.84029521062345,
              21.004266402010177
            ],
            [
              105.84027811808944,
              21.004266875685158
            ],
            [
              105.84013424572386,
              21.004270859195376
            ],
            [
              105.84012306195655,
              21.00427116910819
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Phương Mai",
          "maTaiSan": "04.O17.DOPP.830",
          "duongKinh": 100,
          "chieudaiQL": 99.78,
          "chatLieu": "G-DI",
          "from_latitude": 21.004245396475785,
          "to_latitude": 21.00427116910819,
          "to_longitude": 105.84012306195655,
          "from_longitude": 105.84108265256822
        }
      },
      {
        "id": 829,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83821342082202,
            21.004078461245467,
            105.83851256549103,
            21.005229952518853
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83821342082202,
              21.005229952518853
            ],
            [
              105.83822497932964,
              21.00519873252322
            ],
            [
              105.83823097083103,
              21.005182547931888
            ],
            [
              105.83823203982624,
              21.005179512347024
            ],
            [
              105.83824743996935,
              21.005135537956598
            ],
            [
              105.83825438550122,
              21.005113645702835
            ],
            [
              105.83825754446403,
              21.00510369105604
            ],
            [
              105.83829355197295,
              21.004990204127214
            ],
            [
              105.83830547702908,
              21.004944711365123
            ],
            [
              105.83830607129764,
              21.004942443149663
            ],
            [
              105.83830937606857,
              21.004929319270275
            ],
            [
              105.83833867040265,
              21.004807353404267
            ],
            [
              105.83837234981115,
              21.004667130722183
            ],
            [
              105.83837236500887,
              21.004667096324766
            ],
            [
              105.83838709415258,
              21.004604265887572
            ],
            [
              105.83839300324037,
              21.004583494216448
            ],
            [
              105.83843562872333,
              21.00441019019009
            ],
            [
              105.83846840518083,
              21.00427692676894
            ],
            [
              105.83851219086927,
              21.00408027315414
            ],
            [
              105.83851256549103,
              21.004078461245467
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Ngõ 6, Phương Mai",
          "maTaiSan": "04.O17.DOPP.831",
          "duongKinh": 150,
          "chieudaiQL": 130.36,
          "chatLieu": "G-DI",
          "from_latitude": 21.005229952518853,
          "to_latitude": 21.004078461245467,
          "to_longitude": 105.83851256549103,
          "from_longitude": 105.83821342082202
        }
      },
      {
        "id": 830,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83895285927714,
            21.004242386604908,
            105.83931540174618,
            21.0056646794716
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83931540174618,
              21.004242386604908
            ],
            [
              105.83931510653831,
              21.00424360560789
            ],
            [
              105.83922721301492,
              21.004620067810535
            ],
            [
              105.83917014015063,
              21.004841699837208
            ],
            [
              105.83916952945613,
              21.004844124394232
            ],
            [
              105.83915246542688,
              21.0049140467807
            ],
            [
              105.83913573938572,
              21.00497113628601
            ],
            [
              105.83905077879477,
              21.005290659085908
            ],
            [
              105.8390197601541,
              21.005407431882194
            ],
            [
              105.83896672881272,
              21.005601588588025
            ],
            [
              105.83895396839053,
              21.005660907556674
            ],
            [
              105.83895285927714,
              21.0056646794716
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D200_Ngõ 4, Phương Mai",
          "maTaiSan": "04.O17.DOPP.832",
          "duongKinh": 200,
          "chieudaiQL": 158.71,
          "chatLieu": "G-DI",
          "from_latitude": 21.004242386604908,
          "to_latitude": 21.0056646794716,
          "to_longitude": 105.83895285927714,
          "from_longitude": 105.83931540174618
        }
      },
      {
        "id": 831,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83763252753458,
            21.00584000067408,
            105.8388929730165,
            21.005991625352166
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8388929730165,
              21.00584000067408
            ],
            [
              105.8388929153461,
              21.005840008184137
            ],
            [
              105.83888985491183,
              21.00584039630106
            ],
            [
              105.83884590627878,
              21.005845958205065
            ],
            [
              105.83849790138709,
              21.00589000182534
            ],
            [
              105.8384937788262,
              21.00589052342598
            ],
            [
              105.83848206476027,
              21.005892006426546
            ],
            [
              105.83825583480318,
              21.0059176289407
            ],
            [
              105.83800564328176,
              21.005945965010177
            ],
            [
              105.83798443006258,
              21.005948367264445
            ],
            [
              105.83794160181664,
              21.00595321800143
            ],
            [
              105.83793462185106,
              21.005953970431005
            ],
            [
              105.83793250315453,
              21.005954198539527
            ],
            [
              105.83793110543009,
              21.005954349033917
            ],
            [
              105.83792549338679,
              21.005954953825146
            ],
            [
              105.83791623515356,
              21.005955952309293
            ],
            [
              105.83789704192695,
              21.005958365381783
            ],
            [
              105.83764249517652,
              21.005990372295255
            ],
            [
              105.83763252753458,
              21.005991625352166
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngách 4/15, Phương Mai",
          "maTaiSan": "04.O17.DOPP.833",
          "duongKinh": 100,
          "chieudaiQL": 131.66,
          "chatLieu": "G-DI",
          "from_latitude": 21.00584000067408,
          "to_latitude": 21.005991625352166,
          "to_longitude": 105.83763252753458,
          "from_longitude": 105.8388929730165
        }
      },
      {
        "id": 832,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83896672881272,
            21.005601588588025,
            105.8397052821228,
            21.005620337592315
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83896672881272,
              21.005601588588025
            ],
            [
              105.83896866921516,
              21.00560181566779
            ],
            [
              105.83912595486981,
              21.005619954692115
            ],
            [
              105.83912725568841,
              21.005620020536544
            ],
            [
              105.83913100317093,
              21.00562019805969
            ],
            [
              105.83913387030762,
              21.005620333858786
            ],
            [
              105.83913402903728,
              21.005620337592315
            ],
            [
              105.83929452391214,
              21.005617905949805
            ],
            [
              105.83933259923062,
              21.00561732437551
            ],
            [
              105.83933275408764,
              21.00561732361181
            ],
            [
              105.83934139819092,
              21.005617109369545
            ],
            [
              105.83934742826708,
              21.00561695588936
            ],
            [
              105.83943948594806,
              21.0056145201889
            ],
            [
              105.83945743376066,
              21.005613705472406
            ],
            [
              105.83945759723896,
              21.005613698343495
            ],
            [
              105.83946156448253,
              21.005613441227528
            ],
            [
              105.83949404152173,
              21.005614202325088
            ],
            [
              105.83962470060665,
              21.005614787080592
            ],
            [
              105.83965095230252,
              21.005615012537774
            ],
            [
              105.8397052821228,
              21.005614746300573
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 4, Phương Mai",
          "maTaiSan": "04.O17.DOPP.834",
          "duongKinh": 100,
          "chieudaiQL": 77.73,
          "chatLieu": "G-DI",
          "from_latitude": 21.005601588588025,
          "to_latitude": 21.005614746300573,
          "to_longitude": 105.8397052821228,
          "from_longitude": 105.83896672881272
        }
      },
      {
        "id": 833,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83917014015063,
            21.004841699837208,
            105.83994316158608,
            21.004871446915256
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83917014015063,
              21.004841699837208
            ],
            [
              105.83917389014155,
              21.00484250147604
            ],
            [
              105.83928966241358,
              21.004867628269547
            ],
            [
              105.83938526355232,
              21.004868187392745
            ],
            [
              105.83939418518094,
              21.004868243651014
            ],
            [
              105.83940006045451,
              21.004868277900815
            ],
            [
              105.83940287785116,
              21.00486829381221
            ],
            [
              105.83943419242289,
              21.00486847265966
            ],
            [
              105.83955384103426,
              21.004869175921215
            ],
            [
              105.83955699990938,
              21.00486919466174
            ],
            [
              105.83956233940754,
              21.004869226129458
            ],
            [
              105.83959320861621,
              21.00486940172517
            ],
            [
              105.83971472619137,
              21.004870115524884
            ],
            [
              105.83971716941187,
              21.00487012966426
            ],
            [
              105.83971901337055,
              21.004870140437596
            ],
            [
              105.83975330405615,
              21.004870337059003
            ],
            [
              105.83987351344503,
              21.004871042748196
            ],
            [
              105.83987547860059,
              21.004871053825
            ],
            [
              105.83987775830315,
              21.004871067865743
            ],
            [
              105.83994316158608,
              21.004871446915256
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 4, Phương Mai",
          "maTaiSan": "04.O17.DOPP.835",
          "duongKinh": 100,
          "chieudaiQL": 80.44,
          "chatLieu": "G-DI",
          "from_latitude": 21.004841699837208,
          "to_latitude": 21.004871446915256,
          "to_longitude": 105.83994316158608,
          "from_longitude": 105.83917014015063
        }
      },
      {
        "id": 834,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84012303711114,
            21.00427116910819,
            105.840187203026,
            21.005361198299894
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84012306195655,
              21.00427116910819
            ],
            [
              105.84012303711114,
              21.00427394755032
            ],
            [
              105.8401238429674,
              21.00461843440871
            ],
            [
              105.84012386202473,
              21.004626650047115
            ],
            [
              105.84012531489455,
              21.00484271862398
            ],
            [
              105.84016440951687,
              21.004933188632734
            ],
            [
              105.84016451869226,
              21.004942037889677
            ],
            [
              105.84016465914391,
              21.004946164933358
            ],
            [
              105.84016962655176,
              21.005091923591383
            ],
            [
              105.84017077759658,
              21.00512571567469
            ],
            [
              105.84017726147268,
              21.005137213330652
            ],
            [
              105.84018620336533,
              21.00515360065271
            ],
            [
              105.84018712017546,
              21.005343994085187
            ],
            [
              105.84018713247568,
              21.005346534795486
            ],
            [
              105.84018714603502,
              21.005349300402525
            ],
            [
              105.8401871646588,
              21.005353314241066
            ],
            [
              105.8401871928482,
              21.00535766222552
            ],
            [
              105.840187203026,
              21.005361198299894
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 2, Phương Mai",
          "maTaiSan": "04.O17.DOPP.836",
          "duongKinh": 100,
          "chieudaiQL": 122.09,
          "chatLieu": "G-DI",
          "from_latitude": 21.00427116910819,
          "to_latitude": 21.005361198299894,
          "to_longitude": 105.840187203026,
          "from_longitude": 105.84012306195655
        }
      },
      {
        "id": 835,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84017726147268,
            21.005136906220617,
            105.84071321354948,
            21.005139380113324
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84017726147268,
              21.005137213330652
            ],
            [
              105.84018165549155,
              21.005136906220617
            ],
            [
              105.84029450287393,
              21.00513691182047
            ],
            [
              105.8402971857336,
              21.005136949155297
            ],
            [
              105.8404635163069,
              21.005139264768545
            ],
            [
              105.84046850904922,
              21.005139380113324
            ],
            [
              105.84047326032452,
              21.00513931690866
            ],
            [
              105.84065762067416,
              21.00513846239688
            ],
            [
              105.84065982718944,
              21.00513846053046
            ],
            [
              105.84071321354948,
              21.005138422636165
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 2, Phương Mai",
          "maTaiSan": "04.O17.DOPP.837",
          "duongKinh": 100,
          "chieudaiQL": 56.51,
          "chatLieu": "G-DI",
          "from_latitude": 21.005137213330652,
          "to_latitude": 21.005138422636165,
          "to_longitude": 105.84071321354948,
          "from_longitude": 105.84017726147268
        }
      },
      {
        "id": 836,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84012386202473,
            21.004626650047115,
            105.84079902435123,
            21.005167377882202
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84012386202473,
              21.004626650047115
            ],
            [
              105.84012469478857,
              21.00462678412991
            ],
            [
              105.8403592367042,
              21.004655948409418
            ],
            [
              105.8404023372193,
              21.0046594370119
            ],
            [
              105.84050741457337,
              21.00466790883648
            ],
            [
              105.84077442976445,
              21.004673825750526
            ],
            [
              105.840775788557,
              21.004837221407815
            ],
            [
              105.84078137430528,
              21.00489494419069
            ],
            [
              105.84078681099254,
              21.00495112590483
            ],
            [
              105.84079902435123,
              21.004975429150832
            ],
            [
              105.84079798993174,
              21.005140862556985
            ],
            [
              105.84079793649029,
              21.005167377882202
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngách 2/2, Phương Mai",
          "maTaiSan": "04.O17.DOPP.838",
          "duongKinh": 100,
          "chieudaiQL": 122.29,
          "chatLieu": "G-DI",
          "from_latitude": 21.004626650047115,
          "to_latitude": 21.005167377882202,
          "to_longitude": 105.84079793649029,
          "from_longitude": 105.84012386202473
        }
      },
      {
        "id": 837,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83838709415258,
            21.00459871597836,
            105.83912475529209,
            21.00476511967679
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83838709415258,
              21.004604265887572
            ],
            [
              105.8383874939413,
              21.00460437591818
            ],
            [
              105.83838988967214,
              21.0046050153424
            ],
            [
              105.83842900047176,
              21.00459871597836
            ],
            [
              105.83856025644461,
              21.00463156996383
            ],
            [
              105.83858426306801,
              21.004636975808648
            ],
            [
              105.83860337037868,
              21.00464125327453
            ],
            [
              105.83862318827944,
              21.004645661817516
            ],
            [
              105.83864102444043,
              21.00464967276843
            ],
            [
              105.83878224081425,
              21.004681184986076
            ],
            [
              105.83879290101608,
              21.00468356664154
            ],
            [
              105.83880656998669,
              21.004686646112628
            ],
            [
              105.83882406326245,
              21.004690572927533
            ],
            [
              105.83897821054529,
              21.004729912726773
            ],
            [
              105.83905272467646,
              21.004748719966024
            ],
            [
              105.8390599024869,
              21.004750333869463
            ],
            [
              105.83909299954774,
              21.004757769534365
            ],
            [
              105.83912475529209,
              21.00476511967679
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 6, Phương Mai",
          "maTaiSan": "04.O17.DOPP.839",
          "duongKinh": 100,
          "chieudaiQL": 77.91,
          "chatLieu": "G-DI",
          "from_latitude": 21.004604265887572,
          "to_latitude": 21.00476511967679,
          "to_longitude": 105.83912475529209,
          "from_longitude": 105.83838709415258
        }
      },
      {
        "id": 838,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83765341496533,
            21.00443432719633,
            105.83839300324037,
            21.00460075568776
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83839300324037,
              21.004583494216448
            ],
            [
              105.83839271991489,
              21.004583398063772
            ],
            [
              105.8383900157333,
              21.004582469320507
            ],
            [
              105.83835670942109,
              21.00460075568776
            ],
            [
              105.8382213386117,
              21.004571510321536
            ],
            [
              105.83821525270953,
              21.00456975552097
            ],
            [
              105.83819888811877,
              21.00456503727822
            ],
            [
              105.83819250160239,
              21.00456250176432
            ],
            [
              105.83799152664474,
              21.004516334906985
            ],
            [
              105.83796884780939,
              21.00450910153821
            ],
            [
              105.83792113130403,
              21.00449388317889
            ],
            [
              105.8378685538279,
              21.00448336744186
            ],
            [
              105.83778895926598,
              21.00446350352246
            ],
            [
              105.83776424270106,
              21.004457900542175
            ],
            [
              105.83774727510358,
              21.00445405412591
            ],
            [
              105.83774672716744,
              21.00445394030685
            ],
            [
              105.83774304375618,
              21.00445317353412
            ],
            [
              105.83774279049027,
              21.00445312058719
            ],
            [
              105.83765806115937,
              21.004434914006968
            ],
            [
              105.83765341496533,
              21.00443432719633
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 6, Phương Mai",
          "maTaiSan": "04.O17.DOPP.840",
          "duongKinh": 100,
          "chieudaiQL": 80.41,
          "chatLieu": "G-DI",
          "from_latitude": 21.004583494216448,
          "to_latitude": 21.00443432719633,
          "to_longitude": 105.83765341496533,
          "from_longitude": 105.83839300324037
        }
      },
      {
        "id": 839,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83773164914095,
            21.004110185585212,
            105.83846840518083,
            21.00427692676894
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83846840518083,
              21.00427692676894
            ],
            [
              105.83846316088773,
              21.00427585518521
            ],
            [
              105.83830591001127,
              21.004243702696932
            ],
            [
              105.83829635257469,
              21.0042417428038
            ],
            [
              105.83824626791616,
              21.004229950388456
            ],
            [
              105.83824220351339,
              21.00422899401904
            ],
            [
              105.83823959728322,
              21.00422838001632
            ],
            [
              105.83818719149521,
              21.004216041724742
            ],
            [
              105.83793056003061,
              21.004155617770326
            ],
            [
              105.83790651264259,
              21.00414995641807
            ],
            [
              105.83789886732733,
              21.004148155992098
            ],
            [
              105.83788234967457,
              21.004144266728105
            ],
            [
              105.83773714542586,
              21.004111428467567
            ],
            [
              105.83773164914095,
              21.004110185585212
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 6, Phương Mai",
          "maTaiSan": "04.O17.DOPP.841",
          "duongKinh": 100,
          "chieudaiQL": 79.18,
          "chatLieu": "G-DI",
          "from_latitude": 21.00427692676894,
          "to_latitude": 21.004110185585212,
          "to_longitude": 105.83773164914095,
          "from_longitude": 105.83846840518083
        }
      },
      {
        "id": 840,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83829355197295,
            21.004990204127214,
            105.83902580846342,
            21.005149887230562
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83829355197295,
              21.004990204127214
            ],
            [
              105.83829445907683,
              21.004990387530192
            ],
            [
              105.83844746760914,
              21.00502885096202
            ],
            [
              105.83845843727795,
              21.005030839113594
            ],
            [
              105.8384658553947,
              21.005031906310098
            ],
            [
              105.83849478919909,
              21.00503842234279
            ],
            [
              105.8386899758873,
              21.005078115688253
            ],
            [
              105.83869304030371,
              21.00507878703858
            ],
            [
              105.8389069218918,
              21.005120876391757
            ],
            [
              105.83891097639862,
              21.005121950212384
            ],
            [
              105.83891517539381,
              21.005123061256157
            ],
            [
              105.83892004173815,
              21.005124654945444
            ],
            [
              105.83902580846342,
              21.005149887230562
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngách 4/7, Phương Mai",
          "maTaiSan": "04.O17.DOPP.842",
          "duongKinh": 100,
          "chieudaiQL": 77.39,
          "chatLieu": "G-DI",
          "from_latitude": 21.004990204127214,
          "to_latitude": 21.005149887230562,
          "to_longitude": 105.83902580846342,
          "from_longitude": 105.83829355197295
        }
      },
      {
        "id": 841,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83806705908364,
            21.005437188462665,
            105.83895396839053,
            21.005660907556674
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83895396839053,
              21.005660907556674
            ],
            [
              105.83895204818597,
              21.005660336248518
            ],
            [
              105.83878889470938,
              21.005611668981974
            ],
            [
              105.83874311702144,
              21.005598020149364
            ],
            [
              105.83873768807433,
              21.005596789614824
            ],
            [
              105.83863824790137,
              21.00557424649889
            ],
            [
              105.83863480893365,
              21.005573508348412
            ],
            [
              105.83851666840982,
              21.005547924018007
            ],
            [
              105.83847209664475,
              21.00553731936046
            ],
            [
              105.83846530353956,
              21.005535703538012
            ],
            [
              105.8382956685307,
              21.00549534397107
            ],
            [
              105.83824745319835,
              21.005483872942733
            ],
            [
              105.8382241584034,
              21.005478325355238
            ],
            [
              105.8382014128081,
              21.005472255705353
            ],
            [
              105.83819724746216,
              21.005471142671954
            ],
            [
              105.83812048815857,
              21.005450803414757
            ],
            [
              105.83810357455434,
              21.005446312772623
            ],
            [
              105.83810207714643,
              21.005446008533745
            ],
            [
              105.83809432913428,
              21.00544443262431
            ],
            [
              105.83806705908364,
              21.005437188462665
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngách 4/9, Phương Mai",
          "maTaiSan": "04.O17.DOPP.843",
          "duongKinh": 100,
          "chieudaiQL": 94.7,
          "chatLieu": "G-DI",
          "from_latitude": 21.005660907556674,
          "to_latitude": 21.005437188462665,
          "to_longitude": 105.83806705908364,
          "from_longitude": 105.83895396839053
        }
      },
      {
        "id": 842,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83879650771809,
            21.006628833905346,
            105.83949597188321,
            21.006683058441926
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83879650771809,
              21.006683058441926
            ],
            [
              105.83879860770105,
              21.006682922543558
            ],
            [
              105.83904809452234,
              21.006666679320123
            ],
            [
              105.83905272447998,
              21.006666069397667
            ],
            [
              105.83905987868013,
              21.006665508449345
            ],
            [
              105.83935288882358,
              21.00664536865243
            ],
            [
              105.83936445370666,
              21.006644032648758
            ],
            [
              105.83949597188321,
              21.006628833905346
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 4, Phương Mai",
          "maTaiSan": "04.O17.DOPP.844",
          "duongKinh": 100,
          "chieudaiQL": 72.92,
          "chatLieu": "G-DI",
          "from_latitude": 21.006683058441926,
          "to_latitude": 21.006628833905346,
          "to_longitude": 105.83949597188321,
          "from_longitude": 105.83879650771809
        }
      },
      {
        "id": 843,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83803159784783,
            21.006505904521347,
            105.83877358478341,
            21.006603999172697
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83877358478341,
              21.006505904521347
            ],
            [
              105.83877351168779,
              21.006505905784824
            ],
            [
              105.83877160929823,
              21.006505942257476
            ],
            [
              105.8387675505905,
              21.006506016454175
            ],
            [
              105.83875020097257,
              21.006530583868543
            ],
            [
              105.83858425833269,
              21.00654753682105
            ],
            [
              105.83854869685402,
              21.0065511695872
            ],
            [
              105.8384606871958,
              21.006560162162515
            ],
            [
              105.83845662462262,
              21.00656057778989
            ],
            [
              105.83823980835967,
              21.006582727999593
            ],
            [
              105.83820973220978,
              21.006585800775312
            ],
            [
              105.83815477107908,
              21.006591414939187
            ],
            [
              105.83815233891632,
              21.006591663562173
            ],
            [
              105.83803159784783,
              21.006603999172697
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngách 4/35, Phương Mai",
          "maTaiSan": "04.O17.DOPP.845",
          "duongKinh": 100,
          "chieudaiQL": 77.86,
          "chatLieu": "G-DI",
          "from_latitude": 21.006505904521347,
          "to_latitude": 21.006603999172697,
          "to_longitude": 105.83803159784783,
          "from_longitude": 105.83877358478341
        }
      },
      {
        "id": 844,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84012531489455,
            21.00484271862398,
            105.8406978710076,
            21.00485222318237
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84012531489455,
              21.00484271862398
            ],
            [
              105.84013988608145,
              21.00484389856776
            ],
            [
              105.84028280562771,
              21.004849679084387
            ],
            [
              105.84029809453754,
              21.00484968669834
            ],
            [
              105.84030939744142,
              21.00484968870039
            ],
            [
              105.8404579704985,
              21.004849739099484
            ],
            [
              105.84047314899348,
              21.004849782468703
            ],
            [
              105.84047672430889,
              21.004849792813147
            ],
            [
              105.8404842731616,
              21.004849815148173
            ],
            [
              105.84063381342123,
              21.004852021130827
            ],
            [
              105.84064794164752,
              21.00485208051053
            ],
            [
              105.84065864738983,
              21.004852110729157
            ],
            [
              105.8406978710076,
              21.00485222318237
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 2, Phương Mai",
          "maTaiSan": "04.O17.DOPP.846",
          "duongKinh": 100,
          "chieudaiQL": 59.4,
          "chatLieu": "G-DI",
          "from_latitude": 21.00484271862398,
          "to_latitude": 21.00485222318237,
          "to_longitude": 105.8406978710076,
          "from_longitude": 105.84012531489455
        }
      },
      {
        "id": 845,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83829301307222,
            21.004028392184935,
            105.83931540174618,
            21.004242386604908
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83931540174618,
              21.004242386604908
            ],
            [
              105.83916037439158,
              21.004217254648893
            ],
            [
              105.83915353511473,
              21.004216144885817
            ],
            [
              105.83913692228114,
              21.004212958019863
            ],
            [
              105.83893471652168,
              21.004173015235505
            ],
            [
              105.8389318219665,
              21.00417244330969
            ],
            [
              105.8387308228199,
              21.00413273278377
            ],
            [
              105.83871194735654,
              21.004128278962046
            ],
            [
              105.83870867269901,
              21.00412750477655
            ],
            [
              105.83870612328795,
              21.00412690314612
            ],
            [
              105.83851256549103,
              21.004078461245467
            ],
            [
              105.83830134614493,
              21.004031919785245
            ],
            [
              105.83829454679571,
              21.004028829017802
            ],
            [
              105.83829301307222,
              21.004028392184935
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D200_Phương Mai",
          "maTaiSan": "04.O17.DOPP.847",
          "duongKinh": 200,
          "chieudaiQL": 112.41,
          "chatLieu": "G-DI",
          "from_latitude": 21.004242386604908,
          "to_latitude": 21.004028392184935,
          "to_longitude": 105.83829301307222,
          "from_longitude": 105.83931540174618
        }
      },
      {
        "id": 846,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83931540174618,
            21.004242386604908,
            105.84012306195655,
            21.004272999961316
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84012306195655,
              21.00427116910819
            ],
            [
              105.84008389841931,
              21.004272999961316
            ],
            [
              105.83995170796462,
              21.00426943973053
            ],
            [
              105.83994785319938,
              21.00426933591514
            ],
            [
              105.83993497466503,
              21.004269136631343
            ],
            [
              105.83972068025162,
              21.004266895438633
            ],
            [
              105.83971744820379,
              21.004266861706967
            ],
            [
              105.83961657513235,
              21.004268818957048
            ],
            [
              105.83961651646541,
              21.004268820149672
            ],
            [
              105.83948273334697,
              21.00426219644604
            ],
            [
              105.83947669467796,
              21.004262007649622
            ],
            [
              105.83947377855895,
              21.004261880226156
            ],
            [
              105.8393253545374,
              21.004243661652744
            ],
            [
              105.83931540174618,
              21.004242386604908
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Phương Mai",
          "maTaiSan": "04.O17.DOPP.848",
          "duongKinh": 150,
          "chieudaiQL": 81.63,
          "chatLieu": "G-DI",
          "from_latitude": 21.00427116910819,
          "to_latitude": 21.004242386604908,
          "to_longitude": 105.83931540174618,
          "from_longitude": 105.84012306195655
        }
      },
      {
        "id": 847,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83561353714829,
            21.004267784426002,
            105.83581994719583,
            21.00499096196852
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83561353714829,
              21.00499096196852
            ],
            [
              105.83561611504787,
              21.004981477186067
            ],
            [
              105.83571554768194,
              21.004632985222017
            ],
            [
              105.83572079347783,
              21.004614635727954
            ],
            [
              105.83572715540124,
              21.004592378819027
            ],
            [
              105.83581714503346,
              21.00427758373214
            ],
            [
              105.83581994719583,
              21.004267784426002
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Lương Định Của",
          "maTaiSan": "04.O17.DOPP.849",
          "duongKinh": 150,
          "chieudaiQL": 81.81,
          "chatLieu": "G",
          "from_latitude": 21.00499096196852,
          "to_latitude": 21.004267784426002,
          "to_longitude": 105.83581994719583,
          "from_longitude": 105.83561353714829
        }
      },
      {
        "id": 848,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83819258134974,
            21.006699862946643,
            105.83879870527642,
            21.006955256349798
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83879870527642,
              21.006699862946643
            ],
            [
              105.8387986408727,
              21.006699870489857
            ],
            [
              105.83879221894428,
              21.006700654527606
            ],
            [
              105.83869294951997,
              21.006712754716556
            ],
            [
              105.83871707591369,
              21.006899065930018
            ],
            [
              105.83850496795796,
              21.00692299883236
            ],
            [
              105.83849958892266,
              21.006923651269535
            ],
            [
              105.83824628588893,
              21.0069498958431
            ],
            [
              105.8382266777832,
              21.006951930741796
            ],
            [
              105.83820244826339,
              21.006954213302787
            ],
            [
              105.83819258134974,
              21.006955256349798
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 4, Phương Mai",
          "maTaiSan": "04.O17.DOPP.850",
          "duongKinh": 100,
          "chieudaiQL": 99.74,
          "chatLieu": "G-DI",
          "from_latitude": 21.006699862946643,
          "to_latitude": 21.006955256349798,
          "to_longitude": 105.83819258134974,
          "from_longitude": 105.83879870527642
        }
      },
      {
        "id": 849,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83874651546755,
            21.0056646794716,
            105.83895285927714,
            21.006732436611337
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83895285927714,
              21.0056646794716
            ],
            [
              105.8388929730165,
              21.00584000067408
            ],
            [
              105.83886879373269,
              21.005895115408602
            ],
            [
              105.83885110662783,
              21.005935522543705
            ],
            [
              105.83879450052856,
              21.006079936352172
            ],
            [
              105.83878465778582,
              21.006101985612982
            ],
            [
              105.83877985374276,
              21.006112748632948
            ],
            [
              105.83875390354066,
              21.006218972025124
            ],
            [
              105.83875291436317,
              21.006228715470304
            ],
            [
              105.83875131517384,
              21.006250128877006
            ],
            [
              105.83874651546755,
              21.00627337173222
            ],
            [
              105.8387529810236,
              21.006328927936888
            ],
            [
              105.8387729458965,
              21.006501050127117
            ],
            [
              105.83877358478341,
              21.006505904521347
            ],
            [
              105.83877566116695,
              21.006523746526145
            ],
            [
              105.83877620476581,
              21.006527901390772
            ],
            [
              105.83879299092816,
              21.00665618406343
            ],
            [
              105.83879650371416,
              21.006683030461694
            ],
            [
              105.83879650771809,
              21.006683058441926
            ],
            [
              105.83879685138275,
              21.006685688745133
            ],
            [
              105.83879870527642,
              21.006699862946643
            ],
            [
              105.83880136497153,
              21.006720186846966
            ],
            [
              105.8388029676395,
              21.006732436611337
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Ngõ 4, Phương Mai",
          "maTaiSan": "04.O17.DOPP.851",
          "duongKinh": 150,
          "chieudaiQL": 123.72,
          "chatLieu": "G-DI",
          "from_latitude": 21.0056646794716,
          "to_latitude": 21.006732436611337,
          "to_longitude": 105.8388029676395,
          "from_longitude": 105.83895285927714
        }
      },
      {
        "id": 850,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83632276155689,
            21.006522350342237,
            105.83714811028969,
            21.007092798124084
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83632276155689,
              21.007092798124084
            ],
            [
              105.83633194741194,
              21.007083457892517
            ],
            [
              105.83634817380933,
              21.007066964743437
            ],
            [
              105.83646812874039,
              21.007010304790498
            ],
            [
              105.83667421539917,
              21.00692107826031
            ],
            [
              105.83677686011559,
              21.006879084861794
            ],
            [
              105.83683045494175,
              21.00685559580648
            ],
            [
              105.83693690827387,
              21.006737829194286
            ],
            [
              105.83708966281722,
              21.00658357752561
            ],
            [
              105.83710704625508,
              21.006565367004573
            ],
            [
              105.83710866334475,
              21.006563673632566
            ],
            [
              105.83714811028969,
              21.006522350342237
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 5, Hoàng Tích Trí",
          "maTaiSan": "04.O17.DOPP.852",
          "duongKinh": 100,
          "chieudaiQL": 109.03,
          "chatLieu": "G-DI",
          "from_latitude": 21.007092798124084,
          "to_latitude": 21.006522350342237,
          "to_longitude": 105.83714811028969,
          "from_longitude": 105.83632276155689
        }
      },
      {
        "id": 851,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83605389705446,
            21.006288401569655,
            105.83688602323669,
            21.006794750583456
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83605389705446,
              21.006794750583456
            ],
            [
              105.83605461611616,
              21.006793983825673
            ],
            [
              105.83606140534567,
              21.006786783380683
            ],
            [
              105.83617970724298,
              21.00666134392602
            ],
            [
              105.83618213911699,
              21.00665879843075
            ],
            [
              105.83629829458458,
              21.00654090324514
            ],
            [
              105.83636471076933,
              21.006516645914054
            ],
            [
              105.83639138900959,
              21.00650483791049
            ],
            [
              105.83639915372652,
              21.006501120011155
            ],
            [
              105.83641287669833,
              21.006505582242973
            ],
            [
              105.83659191080659,
              21.006561104160582
            ],
            [
              105.83661391642943,
              21.00656762023994
            ],
            [
              105.83674386608111,
              21.00643787889467
            ],
            [
              105.83674596608309,
              21.006435331410405
            ],
            [
              105.83687303022661,
              21.006303927771857
            ],
            [
              105.83688602323669,
              21.006288401569655
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngách 9/14, Lương Định Của",
          "maTaiSan": "04.O17.DOPP.853",
          "duongKinh": 100,
          "chieudaiQL": 117.56,
          "chatLieu": "G-DI",
          "from_latitude": 21.006794750583456,
          "to_latitude": 21.006288401569655,
          "to_longitude": 105.83688602323669,
          "from_longitude": 105.83605389705446
        }
      },
      {
        "id": 852,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83569291501063,
            21.005820078458633,
            105.83647689851502,
            21.006450833926614
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83647689851502,
              21.005867295218348
            ],
            [
              105.83645096984999,
              21.005859630528075
            ],
            [
              105.83633066340603,
              21.005824057520506
            ],
            [
              105.83626797268622,
              21.005830505697194
            ],
            [
              105.83626793431299,
              21.00583052395025
            ],
            [
              105.83606092685925,
              21.00585180123192
            ],
            [
              105.83586667965282,
              21.005820078458633
            ],
            [
              105.83586016345875,
              21.005852462187864
            ],
            [
              105.83586015690187,
              21.005852493832958
            ],
            [
              105.83585328615466,
              21.005885982141383
            ],
            [
              105.83585294717679,
              21.005887637611835
            ],
            [
              105.83585244722326,
              21.005890095033777
            ],
            [
              105.83582746073746,
              21.006092003711863
            ],
            [
              105.83589258611227,
              21.006312123230416
            ],
            [
              105.83589255543372,
              21.006312141445605
            ],
            [
              105.83574813343296,
              21.006412476471667
            ],
            [
              105.83574809505956,
              21.00641249472462
            ],
            [
              105.83569300506753,
              21.006450769355393
            ],
            [
              105.83569293800954,
              21.006450818458838
            ],
            [
              105.83569291501063,
              21.006450833926614
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 11, Lương Định Của",
          "maTaiSan": "04.O17.DOPP.854",
          "duongKinh": 100,
          "chieudaiQL": 149.78,
          "chatLieu": "G-DI",
          "from_latitude": 21.005867295218348,
          "to_latitude": 21.006450833926614,
          "to_longitude": 105.83569291501063,
          "from_longitude": 105.83647689851502
        }
      },
      {
        "id": 853,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83392151025166,
            21.00726242189102,
            105.8344078263579,
            21.007659691065985
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8344078263579,
              21.007659691065985
            ],
            [
              105.83439115235365,
              21.007646248824216
            ],
            [
              105.83428580821645,
              21.00755673809746
            ],
            [
              105.83428072260901,
              21.007552563933437
            ],
            [
              105.83427777444521,
              21.00755014419351
            ],
            [
              105.83426322205875,
              21.007538200827362
            ],
            [
              105.83425585503494,
              21.007532154621344
            ],
            [
              105.83425091437574,
              21.00752809987481
            ],
            [
              105.83412284241544,
              21.007425677748124
            ],
            [
              105.83412072066868,
              21.007424150857688
            ],
            [
              105.8341188326304,
              21.007422791724913
            ],
            [
              105.83395415878555,
              21.007288956737366
            ],
            [
              105.83395231614658,
              21.00728745828342
            ],
            [
              105.83392151025166,
              21.00726242189102
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Lương Định Của",
          "maTaiSan": "04.O17.DOPP.855",
          "duongKinh": 100,
          "chieudaiQL": 67.04,
          "chatLieu": "G",
          "from_latitude": 21.007659691065985,
          "to_latitude": 21.00726242189102,
          "to_longitude": 105.83392151025166,
          "from_longitude": 105.8344078263579
        }
      },
      {
        "id": 854,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83370751752278,
            21.007501566934003,
            105.83418895437715,
            21.00789471030013
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83418895437715,
              21.00789471030013
            ],
            [
              105.83418889743655,
              21.00789467625667
            ],
            [
              105.83407285795754,
              21.00779996286235
            ],
            [
              105.83406044235058,
              21.007789829006168
            ],
            [
              105.8339063997198,
              21.0076641034298
            ],
            [
              105.83389560360492,
              21.00765529207629
            ],
            [
              105.8338907722634,
              21.0076513487835
            ],
            [
              105.8338133607148,
              21.007588172618288
            ],
            [
              105.83380670177247,
              21.00758595621235
            ],
            [
              105.83380027650941,
              21.007580959436233
            ],
            [
              105.83379958127537,
              21.00757840039162
            ],
            [
              105.83379901288137,
              21.00757633749961
            ],
            [
              105.83373832287363,
              21.00752719497949
            ],
            [
              105.83373203755994,
              21.007522109902116
            ],
            [
              105.83372088982178,
              21.007512852259538
            ],
            [
              105.83370751752278,
              21.007501566934003
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Lương Định Của",
          "maTaiSan": "04.O17.DOPP.856",
          "duongKinh": 100,
          "chieudaiQL": 66.72,
          "chatLieu": "G",
          "from_latitude": 21.00789471030013,
          "to_latitude": 21.007501566934003,
          "to_longitude": 105.83370751752278,
          "from_longitude": 105.83418895437715
        }
      },
      {
        "id": 855,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83418895437715,
            21.007659691065985,
            105.8344078263579,
            21.00789471030013
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83418895437715,
              21.00789471030013
            ],
            [
              105.83428440396207,
              21.00779221947103
            ],
            [
              105.83429880591535,
              21.007776755195707
            ],
            [
              105.8344078263579,
              21.007659691065985
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Lương Định Của",
          "maTaiSan": "04.O17.DOPP.857",
          "duongKinh": 100,
          "chieudaiQL": 35.19,
          "chatLieu": "G",
          "from_latitude": 21.00789471030013,
          "to_latitude": 21.007659691065985,
          "to_longitude": 105.8344078263579,
          "from_longitude": 105.83418895437715
        }
      },
      {
        "id": 856,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83485380477217,
            21.007281958969866,
            105.83752069952969,
            21.009440726285032
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83485380477217,
              21.007281958969866
            ],
            [
              105.83485726175246,
              21.00728472033258
            ],
            [
              105.8355269510285,
              21.00781565086429
            ],
            [
              105.83558051839451,
              21.00785812937872
            ],
            [
              105.8356414487542,
              21.007907811973688
            ],
            [
              105.83621608057713,
              21.008376543300457
            ],
            [
              105.83752069952969,
              21.009440726285032
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D160_Hoàng Tích Trí",
          "maTaiSan": "04.O17.DOPP.858",
          "duongKinh": 160,
          "chieudaiQL": 361.38,
          "chatLieu": "PVC",
          "from_latitude": 21.007281958969866,
          "to_latitude": 21.009440726285032,
          "to_longitude": 105.83752069952969,
          "from_longitude": 105.83485380477217
        }
      },
      {
        "id": 857,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83694871117794,
            21.009286993545498,
            105.83752069952969,
            21.009624921859178
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83752069952969,
              21.009440726285032
            ],
            [
              105.8375206813802,
              21.009440748954976
            ],
            [
              105.8373571575923,
              21.009624921859178
            ],
            [
              105.83728895220011,
              21.00956870747018
            ],
            [
              105.83728887881566,
              21.00956865725067
            ],
            [
              105.837288860399,
              21.009568632050993
            ],
            [
              105.83728878115765,
              21.009568566505486
            ],
            [
              105.8371892862172,
              21.009486655734143
            ],
            [
              105.83718184879028,
              21.00948061452476
            ],
            [
              105.83717596779566,
              21.009475794946837
            ],
            [
              105.83716937304574,
              21.009470280687353
            ],
            [
              105.83698117059636,
              21.009315318898633
            ],
            [
              105.83696774263036,
              21.009303610504613
            ],
            [
              105.83696336685138,
              21.0092997960306
            ],
            [
              105.83696248727297,
              21.009299033518825
            ],
            [
              105.83696172755592,
              21.009298376094822
            ],
            [
              105.83696112590275,
              21.00929777479658
            ],
            [
              105.83694871117794,
              21.009286993545498
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Đào Duy Anh",
          "maTaiSan": "04.O17.DOPP.859",
          "duongKinh": 100,
          "chieudaiQL": 83.15,
          "chatLieu": "G",
          "from_latitude": 21.009440726285032,
          "to_latitude": 21.009286993545498,
          "to_longitude": 105.83694871117794,
          "from_longitude": 105.83752069952969
        }
      },
      {
        "id": 858,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83558051839451,
            21.00707018690605,
            105.83629406381462,
            21.00785812937872
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83629406381462,
              21.00707018690605
            ],
            [
              105.83629404565015,
              21.007070206866267
            ],
            [
              105.83629279998884,
              21.007071583180064
            ],
            [
              105.8355950144042,
              21.007842478471208
            ],
            [
              105.83558051839451,
              21.00785812937872
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D110_Ngõ 5, Hoàng Tích Trí",
          "maTaiSan": "04.O17.DOPP.860",
          "duongKinh": 110,
          "chieudaiQL": 114.53,
          "chatLieu": "PVC",
          "from_latitude": 21.00707018690605,
          "to_latitude": 21.00785812937872,
          "to_longitude": 105.83558051839451,
          "from_longitude": 105.83629406381462
        }
      },
      {
        "id": 859,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83568979122943,
            21.006447968879915,
            105.83634807608068,
            21.007112768674926
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83634807608068,
              21.007112768674926
            ],
            [
              105.83632498463675,
              21.00709455481106
            ],
            [
              105.83632276155689,
              21.007092798124084
            ],
            [
              105.83629406381462,
              21.00707018690605
            ],
            [
              105.83626202906622,
              21.007044942017696
            ],
            [
              105.83613850766194,
              21.006874992016716
            ],
            [
              105.8361375133888,
              21.00687314890285
            ],
            [
              105.83605389705446,
              21.006794750583456
            ],
            [
              105.83591664444721,
              21.006666093769876
            ],
            [
              105.83591167059116,
              21.006661434976973
            ],
            [
              105.83584212320861,
              21.006599147820012
            ],
            [
              105.83569300853152,
              21.006450873209296
            ],
            [
              105.83569293800954,
              21.006450818458838
            ],
            [
              105.83568979122943,
              21.006447968879915
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 9, Lương Định Của",
          "maTaiSan": "04.O17.DOPP.861",
          "duongKinh": 100,
          "chieudaiQL": 100.16,
          "chatLieu": "G-DI",
          "from_latitude": 21.007112768674926,
          "to_latitude": 21.006447968879915,
          "to_longitude": 105.83568979122943,
          "from_longitude": 105.83634807608068
        }
      },
      {
        "id": 860,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83634807608068,
            21.007112768674926,
            105.83727031744301,
            21.007662316140113
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83634807608068,
              21.007112768674926
            ],
            [
              105.8363977255338,
              21.00716454231227
            ],
            [
              105.83658673148283,
              21.0073139551271
            ],
            [
              105.83672895876136,
              21.007432017358845
            ],
            [
              105.83677490709813,
              21.00747015953758
            ],
            [
              105.836874977292,
              21.00755322752704
            ],
            [
              105.83700743512007,
              21.007662316140113
            ],
            [
              105.83704442546072,
              21.007656124146237
            ],
            [
              105.83723484738144,
              21.007629823980206
            ],
            [
              105.8372368600163,
              21.007629545820908
            ],
            [
              105.83727031744301,
              21.007624924706036
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 9, Lương Định Của",
          "maTaiSan": "04.O17.DOPP.862",
          "duongKinh": 100,
          "chieudaiQL": 120.4,
          "chatLieu": "G-DI",
          "from_latitude": 21.007112768674926,
          "to_latitude": 21.007624924706036,
          "to_longitude": 105.83727031744301,
          "from_longitude": 105.83634807608068
        }
      },
      {
        "id": 861,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83526665175968,
            21.008174331266975,
            105.8359289224064,
            21.008715061186358
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83526665175968,
              21.008174331266975
            ],
            [
              105.83528685567556,
              21.008190819832294
            ],
            [
              105.83528866454282,
              21.00819229495285
            ],
            [
              105.8352906811658,
              21.00819394066642
            ],
            [
              105.83529256249966,
              21.00819547414086
            ],
            [
              105.83530655418367,
              21.0082068872632
            ],
            [
              105.83531018264527,
              21.0082098636447
            ],
            [
              105.83549232585143,
              21.008358590562644
            ],
            [
              105.83549751189517,
              21.00836282561454
            ],
            [
              105.83549983966606,
              21.008364725410573
            ],
            [
              105.83549989088117,
              21.00836476761072
            ],
            [
              105.83571418269675,
              21.008539731384552
            ],
            [
              105.83571631528604,
              21.008541472265126
            ],
            [
              105.8357209090079,
              21.00854522338025
            ],
            [
              105.83575401393486,
              21.0085722514895
            ],
            [
              105.83591225588665,
              21.008701453775576
            ],
            [
              105.8359289224064,
              21.008715061186358
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 43, Phạm Ngọc Thạch",
          "maTaiSan": "04.O17.DOPP.863",
          "duongKinh": 100,
          "chieudaiQL": 91.24,
          "chatLieu": "G",
          "from_latitude": 21.008174331266975,
          "to_latitude": 21.008715061186358,
          "to_longitude": 105.8359289224064,
          "from_longitude": 105.83526665175968
        }
      },
      {
        "id": 862,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8359289224064,
            21.008715061186358,
            105.83645767245484,
            21.009146767921738
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8359289224064,
              21.008715061186358
            ],
            [
              105.83608695785726,
              21.008844091797613
            ],
            [
              105.83610770408625,
              21.008861031013527
            ],
            [
              105.83610903756706,
              21.008862120073715
            ],
            [
              105.83632833039037,
              21.009041165415706
            ],
            [
              105.8363308185923,
              21.00904319718525
            ],
            [
              105.83633216850359,
              21.009044299711217
            ],
            [
              105.83645767245484,
              21.009146767921738
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 43, Phạm Ngọc Thạch",
          "maTaiSan": "04.O17.DOPP.864",
          "duongKinh": 100,
          "chieudaiQL": 72.84,
          "chatLieu": "G",
          "from_latitude": 21.008715061186358,
          "to_latitude": 21.009146767921738,
          "to_longitude": 105.83645767245484,
          "from_longitude": 105.8359289224064
        }
      },
      {
        "id": 863,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83591225588665,
            21.008376543300457,
            105.83621608057713,
            21.008701453775576
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83591225588665,
              21.008701453775576
            ],
            [
              105.8362068150792,
              21.008386451143533
            ],
            [
              105.8362160614506,
              21.008376563265394
            ],
            [
              105.83621608057713,
              21.008376543300457
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 43, Phạm Ngọc Thạch",
          "maTaiSan": "04.O17.DOPP.865",
          "duongKinh": 100,
          "chieudaiQL": 47.87,
          "chatLieu": "G",
          "from_latitude": 21.008701453775576,
          "to_latitude": 21.008376543300457,
          "to_longitude": 105.83621608057713,
          "from_longitude": 105.83591225588665
        }
      },
      {
        "id": 864,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8359362846036,
            21.009167477876467,
            105.83663848562028,
            21.009952442369894
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83663848562028,
              21.009167477876467
            ],
            [
              105.83662977667426,
              21.00918128764726
            ],
            [
              105.83662784707582,
              21.009184386163078
            ],
            [
              105.8366269159218,
              21.00918593164266
            ],
            [
              105.83662587971766,
              21.009187785638403
            ],
            [
              105.83662462445932,
              21.00919003090425
            ],
            [
              105.83662350348985,
              21.00919203615527
            ],
            [
              105.83662120636173,
              21.009196154412397
            ],
            [
              105.83647565758018,
              21.009372557100733
            ],
            [
              105.83633636041937,
              21.009522317230168
            ],
            [
              105.83632860114416,
              21.009530663227597
            ],
            [
              105.8362128474103,
              21.009655105768747
            ],
            [
              105.83620746898703,
              21.009660892967002
            ],
            [
              105.83594754927722,
              21.00994033259214
            ],
            [
              105.8359362846036,
              21.009952442369894
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 21, Phạm Ngọc Thạch",
          "maTaiSan": "04.O17.DOPP.866",
          "duongKinh": 100,
          "chieudaiQL": 112.13,
          "chatLieu": "G",
          "from_latitude": 21.009167477876467,
          "to_latitude": 21.009952442369894,
          "to_longitude": 105.8359362846036,
          "from_longitude": 105.83663848562028
        }
      },
      {
        "id": 865,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8362128474103,
            21.009655105768747,
            105.8367097083987,
            21.01007603111098
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8367097083987,
              21.01007603111098
            ],
            [
              105.83667181859497,
              21.010044712931524
            ],
            [
              105.83666954573071,
              21.010042834560483
            ],
            [
              105.83666804015024,
              21.010041590093532
            ],
            [
              105.83666164385097,
              21.01003630335504
            ],
            [
              105.83644511983891,
              21.009857334325435
            ],
            [
              105.83644263921593,
              21.009855284456417
            ],
            [
              105.8364396164647,
              21.009852785639737
            ],
            [
              105.83622316974677,
              21.009664118002856
            ],
            [
              105.8362128474103,
              21.009655105768747
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 21, Phạm Ngọc Thạch",
          "maTaiSan": "04.O17.DOPP.867",
          "duongKinh": 100,
          "chieudaiQL": 69.56,
          "chatLieu": "G",
          "from_latitude": 21.01007603111098,
          "to_latitude": 21.009655105768747,
          "to_longitude": 105.8362128474103,
          "from_longitude": 105.8367097083987
        }
      },
      {
        "id": 866,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83647565758018,
            21.009372557100733,
            105.83696695775298,
            21.009791961821612
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83696695775298,
              21.009791961821612
            ],
            [
              105.83693664708099,
              21.009766907317626
            ],
            [
              105.83693400989925,
              21.00976472725832
            ],
            [
              105.83691844964872,
              21.00975186238285
            ],
            [
              105.83691710232522,
              21.009750708365413
            ],
            [
              105.8367061616325,
              21.009570074652693
            ],
            [
              105.83670285229056,
              21.009567240346847
            ],
            [
              105.83669982904252,
              21.009564652117824
            ],
            [
              105.83647974762388,
              21.0093761914439
            ],
            [
              105.83647565758018,
              21.009372557100733
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 21, Phạm Ngọc Thạch",
          "maTaiSan": "04.O17.DOPP.868",
          "duongKinh": 100,
          "chieudaiQL": 69.02,
          "chatLieu": "G",
          "from_latitude": 21.009791961821612,
          "to_latitude": 21.009372557100733,
          "to_longitude": 105.83647565758018,
          "from_longitude": 105.83696695775298
        }
      },
      {
        "id": 867,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83488944562623,
            21.00844275966907,
            105.83633636041937,
            21.009522317230168
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83633636041937,
              21.009522317230168
            ],
            [
              105.83609006829293,
              21.009320991057795
            ],
            [
              105.83608891169582,
              21.00932005648327
            ],
            [
              105.83608715992975,
              21.009318647930126
            ],
            [
              105.83585861274734,
              21.009133589812407
            ],
            [
              105.83585698353532,
              21.009132251106333
            ],
            [
              105.83585686564399,
              21.0091321541371
            ],
            [
              105.83567458649728,
              21.0089753173661
            ],
            [
              105.83566500462885,
              21.00896608378223
            ],
            [
              105.83548653031202,
              21.008821192231082
            ],
            [
              105.83548401209076,
              21.008819121757398
            ],
            [
              105.83525520168907,
              21.008632126649722
            ],
            [
              105.83525442284083,
              21.00863148647291
            ],
            [
              105.83524928301897,
              21.00862726112243
            ],
            [
              105.8352426183654,
              21.00862178415996
            ],
            [
              105.83523164975098,
              21.008612768710954
            ],
            [
              105.83501417611507,
              21.00844275966907
            ],
            [
              105.83488944562623,
              21.008568918152452
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Ngõ 43, Phạm Ngọc Thạch",
          "maTaiSan": "04.O17.DOPP.869",
          "duongKinh": 150,
          "chieudaiQL": 200.51,
          "chatLieu": "G-DI",
          "from_latitude": 21.009522317230168,
          "to_latitude": 21.008568918152452,
          "to_longitude": 105.83488944562623,
          "from_longitude": 105.83633636041937
        }
      },
      {
        "id": 868,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83459221417716,
            21.007560272106915,
            105.83462590297377,
            21.007588241457935
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83459221417716,
              21.007560272106915
            ],
            [
              105.83459586118077,
              21.007563301704074
            ],
            [
              105.83462590297377,
              21.007588241457935
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Lương Định Của",
          "maTaiSan": "04.O17.DOPP.870",
          "duongKinh": 100,
          "chieudaiQL": 0.41,
          "chatLieu": "G",
          "from_latitude": 21.007560272106915,
          "to_latitude": 21.007588241457935,
          "to_longitude": 105.83462590297377,
          "from_longitude": 105.83459221417716
        }
      },
      {
        "id": 869,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83447868416344,
            21.00747855060757,
            105.83473354237334,
            21.007752665785148
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83473354237334,
              21.00747855060757
            ],
            [
              105.83462590297377,
              21.007588241457935
            ],
            [
              105.83462401587138,
              21.00759016464752
            ],
            [
              105.83447868416344,
              21.007752665785148
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Lương Định Của",
          "maTaiSan": "04.O17.DOPP.871",
          "duongKinh": 100,
          "chieudaiQL": 40.42,
          "chatLieu": "G",
          "from_latitude": 21.00747855060757,
          "to_latitude": 21.007752665785148,
          "to_longitude": 105.83447868416344,
          "from_longitude": 105.83473354237334
        }
      },
      {
        "id": 870,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83447868416344,
            21.007752665785148,
            105.83496834812088,
            21.008162089598926
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83447868416344,
              21.007752665785148
            ],
            [
              105.83453123474781,
              21.007797609107932
            ],
            [
              105.83464942740751,
              21.007896162974113
            ],
            [
              105.83465189358088,
              21.007898219264803
            ],
            [
              105.83466347165236,
              21.00790787395852
            ],
            [
              105.83466892970395,
              21.007912424734993
            ],
            [
              105.83488418485013,
              21.008091911849906
            ],
            [
              105.83489468544481,
              21.00810066768432
            ],
            [
              105.83492918878643,
              21.00812943774604
            ],
            [
              105.83496834812088,
              21.008162089598926
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Lương Định Của",
          "maTaiSan": "04.O17.DOPP.872",
          "duongKinh": 100,
          "chieudaiQL": 67.71,
          "chatLieu": "G",
          "from_latitude": 21.007752665785148,
          "to_latitude": 21.008162089598926,
          "to_longitude": 105.83496834812088,
          "from_longitude": 105.83447868416344
        }
      },
      {
        "id": 871,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83473354237334,
            21.00747855060757,
            105.83519924012056,
            21.00786627392162
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83473354237334,
              21.00747855060757
            ],
            [
              105.83484606473985,
              21.00757834157534
            ],
            [
              105.83489003381182,
              21.00761782172568
            ],
            [
              105.83490197126928,
              21.007627413221226
            ],
            [
              105.83490569502824,
              21.007630406305925
            ],
            [
              105.83492147315484,
              21.0076430842465
            ],
            [
              105.8351371091067,
              21.00781635047492
            ],
            [
              105.83514261455791,
              21.007820774551803
            ],
            [
              105.83515251335336,
              21.007828728550216
            ],
            [
              105.83519924012056,
              21.00786627392162
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Lương Định Của",
          "maTaiSan": "04.O17.DOPP.873",
          "duongKinh": 100,
          "chieudaiQL": 64.37,
          "chatLieu": "G",
          "from_latitude": 21.00747855060757,
          "to_latitude": 21.00786627392162,
          "to_longitude": 105.83519924012056,
          "from_longitude": 105.83473354237334
        }
      },
      {
        "id": 872,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83428440396207,
            21.00779221947103,
            105.8343347464178,
            21.00783377574653
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83428440396207,
              21.00779221947103
            ],
            [
              105.834289750616,
              21.0077966326128
            ],
            [
              105.83431313752092,
              21.007815936403226
            ],
            [
              105.83432037428837,
              21.00782190737377
            ],
            [
              105.8343338235925,
              21.007833016142108
            ],
            [
              105.8343347464178,
              21.00783377574653
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Lương Định Của",
          "maTaiSan": "04.O17.DOPP.874",
          "duongKinh": 100,
          "chieudaiQL": 12.2,
          "chatLieu": "G",
          "from_latitude": 21.00779221947103,
          "to_latitude": 21.00783377574653,
          "to_longitude": 105.8343347464178,
          "from_longitude": 105.83428440396207
        }
      },
      {
        "id": 873,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83369631121332,
            21.006549952604292,
            105.83488927292376,
            21.007244211700215
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83369631121332,
              21.006549952604292
            ],
            [
              105.83370715589301,
              21.006558692381425
            ],
            [
              105.83371330964486,
              21.006563650751108
            ],
            [
              105.83372613988047,
              21.006573991251543
            ],
            [
              105.83379225438712,
              21.006627271086888
            ],
            [
              105.83379227950567,
              21.006627290834793
            ],
            [
              105.83393432289819,
              21.0067453067169
            ],
            [
              105.83396198197728,
              21.006767675048426
            ],
            [
              105.83406775828102,
              21.00685321963003
            ],
            [
              105.83424907761723,
              21.00666394515025
            ],
            [
              105.83440343648465,
              21.006789944385183
            ],
            [
              105.8344070163842,
              21.006792846058286
            ],
            [
              105.83456141083221,
              21.006917066515037
            ],
            [
              105.83456474906865,
              21.00691975259556
            ],
            [
              105.83456734428867,
              21.006921839868255
            ],
            [
              105.83470579092223,
              21.00703497813627
            ],
            [
              105.83472968501056,
              21.00705434351441
            ],
            [
              105.83473134017983,
              21.007055685718115
            ],
            [
              105.83484259742387,
              21.007142178396983
            ],
            [
              105.8348444989839,
              21.007143719906885
            ],
            [
              105.83486662628185,
              21.007159481933158
            ],
            [
              105.8348322978493,
              21.00719789831657
            ],
            [
              105.83488636064699,
              21.007241845988343
            ],
            [
              105.83488927292376,
              21.007244211700215
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Lương Định Của",
          "maTaiSan": "04.O17.DOPP.875",
          "duongKinh": 100,
          "chieudaiQL": 178.11,
          "chatLieu": "G",
          "from_latitude": 21.006549952604292,
          "to_latitude": 21.007244211700215,
          "to_longitude": 105.83488927292376,
          "from_longitude": 105.83369631121332
        }
      },
      {
        "id": 874,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83449677773615,
            21.00630667992259,
            105.83504578453297,
            21.007159481933158
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83464528256309,
              21.00630667992259
            ],
            [
              105.83464174346074,
              21.006310766310055
            ],
            [
              105.83463943726377,
              21.00631342949019
            ],
            [
              105.83449677773615,
              21.006478151203154
            ],
            [
              105.8345900231433,
              21.006558228259777
            ],
            [
              105.83459671973775,
              21.006570161351757
            ],
            [
              105.83459800794797,
              21.006572455549815
            ],
            [
              105.83462450481711,
              21.00661966997955
            ],
            [
              105.83475322723092,
              21.006718050236408
            ],
            [
              105.83475568215944,
              21.006719817549385
            ],
            [
              105.83475842004138,
              21.006721786700187
            ],
            [
              105.83490200609545,
              21.006839100505058
            ],
            [
              105.83491486747542,
              21.006849833662788
            ],
            [
              105.83491662916653,
              21.006851304503396
            ],
            [
              105.83491774918653,
              21.00685223926636
            ],
            [
              105.8349208087031,
              21.006854792126557
            ],
            [
              105.8349238885141,
              21.00685736204842
            ],
            [
              105.8350318760054,
              21.006959016834514
            ],
            [
              105.83504578453297,
              21.006971068068925
            ],
            [
              105.83486662628185,
              21.007159481933158
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Lương Định Của",
          "maTaiSan": "04.O17.DOPP.876",
          "duongKinh": 100,
          "chieudaiQL": 127.89,
          "chatLieu": "G",
          "from_latitude": 21.00630667992259,
          "to_latitude": 21.007159481933158,
          "to_longitude": 105.83486662628185,
          "from_longitude": 105.83464528256309
        }
      },
      {
        "id": 875,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83571554768194,
            21.004632985222017,
            105.83633326443537,
            21.00479088048747
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83633326443537,
              21.00479088048747
            ],
            [
              105.8362994403058,
              21.004782234822873
            ],
            [
              105.83629085110323,
              21.004780038834205
            ],
            [
              105.83628143544401,
              21.004777631938264
            ],
            [
              105.83602635742156,
              21.004712431167672
            ],
            [
              105.83600173706392,
              21.00470613829721
            ],
            [
              105.83598817178958,
              21.00470267150937
            ],
            [
              105.83571554768194,
              21.004632985222017
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Lương Định Của",
          "maTaiSan": "04.O17.DOPP.877",
          "duongKinh": 100,
          "chieudaiQL": 66.56,
          "chatLieu": "G",
          "from_latitude": 21.00479088048747,
          "to_latitude": 21.004632985222017,
          "to_longitude": 105.83571554768194,
          "from_longitude": 105.83633326443537
        }
      },
      {
        "id": 876,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83561611504787,
            21.004981477186067,
            105.8362324583454,
            21.005135865969645
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8362324583454,
              21.005135865969645
            ],
            [
              105.8361958484473,
              21.00512650778094
            ],
            [
              105.8361917113295,
              21.00512545055901
            ],
            [
              105.8359167628398,
              21.00505496404217
            ],
            [
              105.83590401340298,
              21.005051706402863
            ],
            [
              105.83590061220296,
              21.00505083704317
            ],
            [
              105.8358860470582,
              21.00504711502991
            ],
            [
              105.83561611504787,
              21.004981477186067
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 27, Lương Định Của",
          "maTaiSan": "04.O17.DOPP.878",
          "duongKinh": 100,
          "chieudaiQL": 66.56,
          "chatLieu": "G",
          "from_latitude": 21.005135865969645,
          "to_latitude": 21.004981477186067,
          "to_longitude": 105.83561611504787,
          "from_longitude": 105.8362324583454
        }
      },
      {
        "id": 877,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83581714503346,
            21.00427758373214,
            105.83650320686093,
            21.004452947167565
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83650320686093,
              21.004452947167565
            ],
            [
              105.83649229146765,
              21.004450158363397
            ],
            [
              105.83647470275919,
              21.004445661839863
            ],
            [
              105.83644692108571,
              21.00443856082788
            ],
            [
              105.8364413988487,
              21.004437149128325
            ],
            [
              105.83643953306003,
              21.004436672362257
            ],
            [
              105.8364208192967,
              21.004431888716972
            ],
            [
              105.8362741752295,
              21.004394406478873
            ],
            [
              105.83624523864937,
              21.004387009430584
            ],
            [
              105.8362432755753,
              21.004386507849997
            ],
            [
              105.83621917058677,
              21.004380345251523
            ],
            [
              105.83609809087869,
              21.004349395835455
            ],
            [
              105.83607363818908,
              21.00434331982723
            ],
            [
              105.8360418976437,
              21.00433503238867
            ],
            [
              105.83603989797078,
              21.00433452195321
            ],
            [
              105.83603660659195,
              21.00433368005626
            ],
            [
              105.8360173235816,
              21.00432875103296
            ],
            [
              105.83581714503346,
              21.00427758373214
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Lương Định Của",
          "maTaiSan": "04.O17.DOPP.879",
          "duongKinh": 100,
          "chieudaiQL": 73.92,
          "chatLieu": "G",
          "from_latitude": 21.004452947167565,
          "to_latitude": 21.00427758373214,
          "to_longitude": 105.83581714503346,
          "from_longitude": 105.83650320686093
        }
      },
      {
        "id": 878,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83398315195328,
            21.004835171402178,
            105.83553200956189,
            21.005228931302362
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83398315195328,
              21.004835171402178
            ],
            [
              105.8340387223144,
              21.00484822258066
            ],
            [
              105.8340411070662,
              21.00484879257193
            ],
            [
              105.8342473383358,
              21.00490193835093
            ],
            [
              105.83424938606296,
              21.004902441346026
            ],
            [
              105.83425524509454,
              21.004903878562686
            ],
            [
              105.83446420837791,
              21.00495515094105
            ],
            [
              105.83446635915908,
              21.004955677815314
            ],
            [
              105.83467321874416,
              21.00500604437596
            ],
            [
              105.83484957068019,
              21.005052400071158
            ],
            [
              105.83488437248583,
              21.005061295938095
            ],
            [
              105.83488619493484,
              21.005061762095
            ],
            [
              105.83505953649511,
              21.005106070271527
            ],
            [
              105.83509278751062,
              21.005114569962053
            ],
            [
              105.83509449147829,
              21.005115005085116
            ],
            [
              105.83509526207328,
              21.005115202723406
            ],
            [
              105.83523750363742,
              21.00515156227788
            ],
            [
              105.83526810768652,
              21.005159384859052
            ],
            [
              105.83530282766148,
              21.00516825936307
            ],
            [
              105.83530477631041,
              21.00516876012183
            ],
            [
              105.83550459251556,
              21.00522065415684
            ],
            [
              105.83550727486342,
              21.005221462924286
            ],
            [
              105.83553200956189,
              21.005228931302362
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 28, Lương Định Của",
          "maTaiSan": "04.O17.DOPP.880",
          "duongKinh": 100,
          "chieudaiQL": 164.1,
          "chatLieu": "G",
          "from_latitude": 21.004835171402178,
          "to_latitude": 21.005228931302362,
          "to_longitude": 105.83553200956189,
          "from_longitude": 105.83398315195328
        }
      },
      {
        "id": 879,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83410753002768,
            21.00442585493531,
            105.83562724685409,
            21.004805634976503
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83410753002768,
              21.00442585493531
            ],
            [
              105.83414748495017,
              21.00443606880197
            ],
            [
              105.83415059619716,
              21.004436864650764
            ],
            [
              105.83435052265611,
              21.004487377608843
            ],
            [
              105.834354081808,
              21.004488287774297
            ],
            [
              105.83435651397136,
              21.004488909012743
            ],
            [
              105.83449808418204,
              21.004526275081673
            ],
            [
              105.83454364228973,
              21.00453792099398
            ],
            [
              105.83454608408805,
              21.004538544892117
            ],
            [
              105.83498347375424,
              21.004647741217745
            ],
            [
              105.83498614288179,
              21.004648423607087
            ],
            [
              105.83518117653925,
              21.004699051704364
            ],
            [
              105.83518397088869,
              21.004699765992218
            ],
            [
              105.83518623352835,
              21.004700343792393
            ],
            [
              105.83521283619753,
              21.00470714369927
            ],
            [
              105.8353404001848,
              21.004738821529074
            ],
            [
              105.83537667954214,
              21.004748093915975
            ],
            [
              105.83537973204362,
              21.004748874675393
            ],
            [
              105.83556308788651,
              21.004797250096043
            ],
            [
              105.83561903712393,
              21.004804563412833
            ],
            [
              105.83562724685409,
              21.004805634976503
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Lương Định Của",
          "maTaiSan": "04.O17.DOPP.881",
          "duongKinh": 100,
          "chieudaiQL": 163.5,
          "chatLieu": "G",
          "from_latitude": 21.00442585493531,
          "to_latitude": 21.004805634976503,
          "to_longitude": 105.83562724685409,
          "from_longitude": 105.83410753002768
        }
      },
      {
        "id": 880,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83434620619138,
            21.004108356450534,
            105.83578405420717,
            21.004450989138427
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83434620619138,
              21.004108356450534
            ],
            [
              105.83437569552854,
              21.004115894729406
            ],
            [
              105.83437647863649,
              21.004116095019494
            ],
            [
              105.8344469727539,
              21.004134114718813
            ],
            [
              105.83444956481272,
              21.004134777623808
            ],
            [
              105.83445144214922,
              21.004135257065062
            ],
            [
              105.83446492065504,
              21.004138702726102
            ],
            [
              105.83462299862366,
              21.004179112025287
            ],
            [
              105.83465014058464,
              21.004186049379392
            ],
            [
              105.83466343126456,
              21.00418944626809
            ],
            [
              105.83466509284443,
              21.004189871668217
            ],
            [
              105.83467228337553,
              21.004191709696553
            ],
            [
              105.83509709909274,
              21.004300299938752
            ],
            [
              105.83510023827476,
              21.004301102858882
            ],
            [
              105.83528636690602,
              21.004348680090644
            ],
            [
              105.83529061187834,
              21.00434976480618
            ],
            [
              105.83529266549762,
              21.004350290340586
            ],
            [
              105.83545285240045,
              21.00439123676316
            ],
            [
              105.8354927226207,
              21.004401427243504
            ],
            [
              105.8354981331145,
              21.004402810619467
            ],
            [
              105.83568661613073,
              21.004450989138427
            ],
            [
              105.83574157948374,
              21.004252778285515
            ],
            [
              105.83577534364568,
              21.00426277287607
            ],
            [
              105.83578405420717,
              21.004265350360424
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Lương Định Của",
          "maTaiSan": "04.O17.DOPP.882",
          "duongKinh": 100,
          "chieudaiQL": 148.92,
          "chatLieu": "G",
          "from_latitude": 21.004108356450534,
          "to_latitude": 21.004265350360424,
          "to_longitude": 105.83578405420717,
          "from_longitude": 105.83434620619138
        }
      },
      {
        "id": 881,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8334448104448,
            21.003453195547213,
            105.83584087640611,
            21.004074694863128
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8334448104448,
              21.003453195547213
            ],
            [
              105.83346521850306,
              21.00345827290753
            ],
            [
              105.83348415866827,
              21.0034629862275
            ],
            [
              105.83364818478188,
              21.00350356875543
            ],
            [
              105.8338370754427,
              21.00355132196131
            ],
            [
              105.83386116580772,
              21.003557316072808
            ],
            [
              105.83386280804658,
              21.003557724414538
            ],
            [
              105.83388841118132,
              21.003564195240035
            ],
            [
              105.83413212894364,
              21.003634273689926
            ],
            [
              105.83430765018014,
              21.00369727691563
            ],
            [
              105.8343505335001,
              21.003707266838013
            ],
            [
              105.83436549954014,
              21.003710982506735
            ],
            [
              105.83440992396729,
              21.003725679698217
            ],
            [
              105.83455435301795,
              21.003758884115825
            ],
            [
              105.83456516732171,
              21.00376097896201
            ],
            [
              105.83458237683166,
              21.00376407483686
            ],
            [
              105.83471406275447,
              21.00380048121296
            ],
            [
              105.83474013874141,
              21.003807388556716
            ],
            [
              105.83476068138198,
              21.003812090260112
            ],
            [
              105.83476272697602,
              21.003812558034127
            ],
            [
              105.83518359404015,
              21.003924233007723
            ],
            [
              105.83520254761257,
              21.003929262196138
            ],
            [
              105.83538914384545,
              21.003978670583287
            ],
            [
              105.83540633657782,
              21.003983065294342
            ],
            [
              105.83558825342972,
              21.00402935500776
            ],
            [
              105.83559221710632,
              21.004030363419574
            ],
            [
              105.83559611533794,
              21.004031364926778
            ],
            [
              105.83575662004262,
              21.00407260215711
            ],
            [
              105.83581743630518,
              21.00406735474724
            ],
            [
              105.83582726198756,
              21.004070510237487
            ],
            [
              105.83584087640611,
              21.004074694863128
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Lương Định Của",
          "maTaiSan": "04.O17.DOPP.883",
          "duongKinh": 100,
          "chieudaiQL": 258.79,
          "chatLieu": "G",
          "from_latitude": 21.003453195547213,
          "to_latitude": 21.004074694863128,
          "to_longitude": 105.83584087640611,
          "from_longitude": 105.8334448104448
        }
      },
      {
        "id": 882,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83388177017252,
            21.003191742804784,
            105.83594332672293,
            21.003730939812556
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83388177017252,
              21.003191742804784
            ],
            [
              105.83389730123034,
              21.003195889297096
            ],
            [
              105.83389906500722,
              21.003196360268966
            ],
            [
              105.83397726650023,
              21.003217239967338
            ],
            [
              105.83400425875402,
              21.003235873134365
            ],
            [
              105.83401701058371,
              21.003244442782403
            ],
            [
              105.83402708404759,
              21.003247892520292
            ],
            [
              105.83407747065046,
              21.00326514833186
            ],
            [
              105.83420224778637,
              21.003301350525387
            ],
            [
              105.8342725594469,
              21.003319323333415
            ],
            [
              105.83427570341861,
              21.003320127149294
            ],
            [
              105.83429630682726,
              21.00332539312968
            ],
            [
              105.8344386197149,
              21.003361772031532
            ],
            [
              105.83445626795603,
              21.003366282931214
            ],
            [
              105.8346381609029,
              21.00341278064928
            ],
            [
              105.83465064336605,
              21.00341597105279
            ],
            [
              105.8346665645529,
              21.003420040592694
            ],
            [
              105.83481251957188,
              21.003457349703186
            ],
            [
              105.83484676424916,
              21.003466102000004
            ],
            [
              105.83485865337785,
              21.003469141749804
            ],
            [
              105.83487084688313,
              21.00347225858643
            ],
            [
              105.8348747758896,
              21.0034732626695
            ],
            [
              105.83488668042881,
              21.003476305954603
            ],
            [
              105.83526703830393,
              21.003573531835713
            ],
            [
              105.83529919476618,
              21.00358175325521
            ],
            [
              105.83530115108393,
              21.003582253073823
            ],
            [
              105.8354760755435,
              21.00362696678805
            ],
            [
              105.8355092019861,
              21.003635433599452
            ],
            [
              105.83551116408623,
              21.003635935193678
            ],
            [
              105.83551619792748,
              21.003637221967274
            ],
            [
              105.83565299591648,
              21.003672190778047
            ],
            [
              105.83568958616009,
              21.003681543773382
            ],
            [
              105.83569161760894,
              21.00368206218628
            ],
            [
              105.83572264038217,
              21.00368999192932
            ],
            [
              105.83587648090415,
              21.003729315327757
            ],
            [
              105.83591171714707,
              21.003723001250272
            ],
            [
              105.83591874278777,
              21.003724766874782
            ],
            [
              105.83594332672293,
              21.003730939812556
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Lương Định Của",
          "maTaiSan": "04.O17.DOPP.884",
          "duongKinh": 100,
          "chieudaiQL": 222.87,
          "chatLieu": "G",
          "from_latitude": 21.003191742804784,
          "to_latitude": 21.003730939812556,
          "to_longitude": 105.83594332672293,
          "from_longitude": 105.83388177017252
        }
      },
      {
        "id": 883,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83568333127079,
            21.004605059415393,
            105.83572079347783,
            21.004614635727954
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83572079347783,
              21.004614635727954
            ],
            [
              105.8357024303295,
              21.00460994150793
            ],
            [
              105.8356967934674,
              21.0046085005406
            ],
            [
              105.83568502945802,
              21.00460549365785
            ],
            [
              105.83568333127079,
              21.004605059415393
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Lương Định Của",
          "maTaiSan": "04.O17.DOPP.885",
          "duongKinh": 150,
          "chieudaiQL": 3.75,
          "chatLieu": "G",
          "from_latitude": 21.004614635727954,
          "to_latitude": 21.004605059415393,
          "to_longitude": 105.83568333127079,
          "from_longitude": 105.83572079347783
        }
      },
      {
        "id": 884,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83300790427128,
            21.006630853060464,
            105.83346908326772,
            21.0075773123126
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83300790427128,
              21.0075773123126
            ],
            [
              105.83302840632273,
              21.00755711699265
            ],
            [
              105.83325898349628,
              21.007329993892427
            ],
            [
              105.83327000011116,
              21.00718174500602
            ],
            [
              105.83329193337825,
              21.007156357229697
            ],
            [
              105.83346908326772,
              21.00695130745073
            ],
            [
              105.83336292582422,
              21.00686498125981
            ],
            [
              105.83329704248943,
              21.006811405674238
            ],
            [
              105.83328082988838,
              21.006798221186614
            ],
            [
              105.8332808066986,
              21.006798202332433
            ],
            [
              105.83327928680103,
              21.006796966935642
            ],
            [
              105.83308771766464,
              21.00664118338859
            ],
            [
              105.83307803490102,
              21.006633298657746
            ],
            [
              105.83307501352822,
              21.006630853060464
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Phạm Ngọc Thạch",
          "maTaiSan": "04.O17.DOPP.886",
          "duongKinh": 150,
          "chieudaiQL": 141.3,
          "chatLieu": "G",
          "from_latitude": 21.0075773123126,
          "to_latitude": 21.006630853060464,
          "to_longitude": 105.83307501352822,
          "from_longitude": 105.83300790427128
        }
      },
      {
        "id": 885,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83280450886184,
            21.006763758909592,
            105.83329193337825,
            21.007156357229697
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83329193337825,
              21.007156357229697
            ],
            [
              105.83328456434486,
              21.007151497828094
            ],
            [
              105.83313487442459,
              21.007030770322935
            ],
            [
              105.8331245737476,
              21.00702230152411
            ],
            [
              105.83312339776884,
              21.007021334506888
            ],
            [
              105.83311870931885,
              21.007017479910548
            ],
            [
              105.83310598904585,
              21.00700702140709
            ],
            [
              105.8329022495126,
              21.006843854653777
            ],
            [
              105.83288439158864,
              21.00682918695405
            ],
            [
              105.83280450886184,
              21.006763758909592
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Phạm Ngọc Thạch",
          "maTaiSan": "04.O17.DOPP.887",
          "duongKinh": 100,
          "chieudaiQL": 66.63,
          "chatLieu": "G",
          "from_latitude": 21.007156357229697,
          "to_latitude": 21.006763758909592,
          "to_longitude": 105.83280450886184,
          "from_longitude": 105.83329193337825
        }
      },
      {
        "id": 886,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84028817157603,
            21.00664798118245,
            105.84116033194805,
            21.00678679821511
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84116033194805,
              21.006648006156553
            ],
            [
              105.84113413506557,
              21.00664798118245
            ],
            [
              105.84108451167327,
              21.00678679821511
            ],
            [
              105.84096209733367,
              21.006782185230854
            ],
            [
              105.84094272485397,
              21.006781455409925
            ],
            [
              105.84063976336947,
              21.006770040567847
            ],
            [
              105.84042459029082,
              21.00675821697742
            ],
            [
              105.84035506452527,
              21.00675749178372
            ],
            [
              105.84035314738716,
              21.006757471443823
            ],
            [
              105.84028817157603,
              21.00675679420735
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 10, Giải Phóng",
          "maTaiSan": "04.O17.DOPP.888",
          "duongKinh": 100,
          "chieudaiQL": 101.79,
          "chatLieu": "G-DI",
          "from_latitude": 21.006648006156553,
          "to_latitude": 21.00675679420735,
          "to_longitude": 105.84028817157603,
          "from_longitude": 105.84116033194805
        }
      },
      {
        "id": 887,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84078933571621,
            21.00564436900848,
            105.84114124900513,
            21.00575766385924
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84114124900513,
              21.00572899645684
            ],
            [
              105.84114122210889,
              21.005729002912325
            ],
            [
              105.84112397453707,
              21.005729645442898
            ],
            [
              105.84111895153283,
              21.005729797621957
            ],
            [
              105.84111892659064,
              21.00572980948714
            ],
            [
              105.84111503162846,
              21.005729919962157
            ],
            [
              105.84111500668632,
              21.005729931827343
            ],
            [
              105.84106356990702,
              21.005731527313813
            ],
            [
              105.84102785358822,
              21.00573820522294
            ],
            [
              105.84099609122589,
              21.005744143716658
            ],
            [
              105.84099606628376,
              21.005744155581834
            ],
            [
              105.84099271474294,
              21.00574477459386
            ],
            [
              105.84099268980073,
              21.005744786459033
            ],
            [
              105.84097715929974,
              21.00574768397067
            ],
            [
              105.84090836589019,
              21.005751403751546
            ],
            [
              105.8408231072938,
              21.005756007816608
            ],
            [
              105.84082308235158,
              21.00575601968175
            ],
            [
              105.84079267043224,
              21.00575766385924
            ],
            [
              105.84079164197333,
              21.00573496277847
            ],
            [
              105.84079107310279,
              21.005652482220576
            ],
            [
              105.84079142534807,
              21.005649255968795
            ],
            [
              105.84079148750986,
              21.005644384571728
            ],
            [
              105.84078933571621,
              21.00564436900848
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 24, Giải Phóng",
          "maTaiSan": "04.O17.DOPP.889",
          "duongKinh": 100,
          "chieudaiQL": 49.33,
          "chatLieu": "G-DI",
          "from_latitude": 21.00572899645684,
          "to_latitude": 21.00564436900848,
          "to_longitude": 105.84078933571621,
          "from_longitude": 105.84114124900513
        }
      },
      {
        "id": 888,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84013462931513,
            21.005245442695884,
            105.84117231580908,
            21.00779236028409
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8411311727777,
              21.005245442695884
            ],
            [
              105.8411411839641,
              21.00572700245864
            ],
            [
              105.84114122210889,
              21.005729002912325
            ],
            [
              105.84116033194805,
              21.006648006156553
            ],
            [
              105.84117193817585,
              21.007206248018495
            ],
            [
              105.84117196419655,
              21.007214836649926
            ],
            [
              105.84117225528595,
              21.00730983544104
            ],
            [
              105.84117231580908,
              21.00732972685079
            ],
            [
              105.84050956275838,
              21.007727993151764
            ],
            [
              105.84013462931513,
              21.00779236028409
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D110_Giải Phóng",
          "maTaiSan": "04.O17.DOPP.890",
          "duongKinh": 110,
          "chieudaiQL": 352.19,
          "chatLieu": "PVC",
          "from_latitude": 21.005245442695884,
          "to_latitude": 21.00779236028409,
          "to_longitude": 105.84013462931513,
          "from_longitude": 105.8411311727777
        }
      },
      {
        "id": 889,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.836101080387,
            21.003478497412853,
            105.8369944433598,
            21.00370129796817
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.836101080387,
              21.003478497412853
            ],
            [
              105.83616723913245,
              21.00349648892548
            ],
            [
              105.83646717026993,
              21.003571494935176
            ],
            [
              105.83647267111331,
              21.003572870611073
            ],
            [
              105.83647577839987,
              21.003573647469317
            ],
            [
              105.8364920494416,
              21.003577659117134
            ],
            [
              105.8364964561988,
              21.00357878094344
            ],
            [
              105.83697785344296,
              21.00370129796817
            ],
            [
              105.8369944433598,
              21.00365002879787
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Phương Mai",
          "maTaiSan": "04.O17.DOPP.891",
          "duongKinh": 100,
          "chieudaiQL": 108.87,
          "chatLieu": "G-DI",
          "from_latitude": 21.003478497412853,
          "to_latitude": 21.00365002879787,
          "to_longitude": 105.8369944433598,
          "from_longitude": 105.836101080387
        }
      },
      {
        "id": 890,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83276907454348,
            21.004035935146234,
            105.83341058461383,
            21.005872768795147
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83281489947177,
              21.005872768795147
            ],
            [
              105.83284226283688,
              21.005858029731595
            ],
            [
              105.83283685706003,
              21.005819024180905
            ],
            [
              105.83283654342549,
              21.005816759521903
            ],
            [
              105.8327698517146,
              21.005267068234755
            ],
            [
              105.83276907454348,
              21.005260658229858
            ],
            [
              105.83291883166214,
              21.005245692372412
            ],
            [
              105.83291908194671,
              21.00524416830862
            ],
            [
              105.8329439214836,
              21.005121156533512
            ],
            [
              105.8329516179924,
              21.00506025950213
            ],
            [
              105.83295263798749,
              21.005052188702464
            ],
            [
              105.83295280753248,
              21.005050848388617
            ],
            [
              105.83294766955474,
              21.00502231172257
            ],
            [
              105.83291709887013,
              21.00489395756642
            ],
            [
              105.83291511190768,
              21.00488880445044
            ],
            [
              105.8328727882713,
              21.004779004060243
            ],
            [
              105.83286330333605,
              21.004754398727133
            ],
            [
              105.83282350933479,
              21.00464682413635
            ],
            [
              105.8328222432426,
              21.004639494329815
            ],
            [
              105.83281831073806,
              21.004616716147325
            ],
            [
              105.83280058404104,
              21.004528402347326
            ],
            [
              105.83280145697503,
              21.004528149688877
            ],
            [
              105.83303919549644,
              21.004458995022613
            ],
            [
              105.83304076160965,
              21.004430437286643
            ],
            [
              105.83304439978316,
              21.004349062300307
            ],
            [
              105.8331382246794,
              21.004309696596444
            ],
            [
              105.83328710149883,
              21.00424723319281
            ],
            [
              105.83334754605607,
              21.004198221763275
            ],
            [
              105.83337031734077,
              21.004179536330774
            ],
            [
              105.83340026944194,
              21.004152455475214
            ],
            [
              105.83341058461383,
              21.00414312883624
            ],
            [
              105.83333833531869,
              21.00407662490079
            ],
            [
              105.83332808323622,
              21.004066305157984
            ],
            [
              105.83330378595801,
              21.00404211198219
            ],
            [
              105.8332979717898,
              21.00403644741409
            ],
            [
              105.8332974466543,
              21.004035935146234
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 41, Đông Tác",
          "maTaiSan": "04.O17.DOPP.892",
          "duongKinh": 100,
          "chieudaiQL": 265.61,
          "chatLieu": "G",
          "from_latitude": 21.005872768795147,
          "to_latitude": 21.004035935146234,
          "to_longitude": 105.8332974466543,
          "from_longitude": 105.83281489947177
        }
      },
      {
        "id": 891,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83634807608068,
            21.007095949156998,
            105.83636436414446,
            21.007112768674926
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83634807608068,
              21.007112768674926
            ],
            [
              105.83636436414446,
              21.007095949156998
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 9, Lương Định Của",
          "maTaiSan": "04.O17.DOPP.893",
          "duongKinh": 100,
          "chieudaiQL": 2.52,
          "chatLieu": "G",
          "from_latitude": 21.007112768674926,
          "to_latitude": 21.007095949156998,
          "to_longitude": 105.83636436414446,
          "from_longitude": 105.83634807608068
        }
      },
      {
        "id": 892,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83543348520287,
            21.00925056948826,
            105.8358246471388,
            21.009568879876653
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83543348520287,
              21.00925056948826
            ],
            [
              105.8358246471388,
              21.009568879876653
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D80_Ngõ 43, Phạm Ngọc Thạch",
          "maTaiSan": "04.O17.DOPP.894",
          "duongKinh": 80,
          "chieudaiQL": 53.81,
          "chatLieu": "G",
          "from_latitude": 21.00925056948826,
          "to_latitude": 21.009568879876653,
          "to_longitude": 105.8358246471388,
          "from_longitude": 105.83543348520287
        }
      },
      {
        "id": 893,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83502694984271,
            21.008913215010743,
            105.83539196108505,
            21.009294443371484
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83539196108505,
              21.009294443371484
            ],
            [
              105.83534061455855,
              21.00925461030113
            ],
            [
              105.83538484501925,
              21.009199428314474
            ],
            [
              105.83502694984271,
              21.008913215010743
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D80_Ngõ 43, Phạm Ngọc Thạch",
          "maTaiSan": "04.O17.DOPP.895",
          "duongKinh": 80,
          "chieudaiQL": 63.44,
          "chatLieu": "G",
          "from_latitude": 21.009294443371484,
          "to_latitude": 21.008913215010743,
          "to_longitude": 105.83502694984271,
          "from_longitude": 105.83539196108505
        }
      },
      {
        "id": 894,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83652400108447,
            21.00938797585589,
            105.83764205800139,
            21.01041264570385
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83764205800139,
              21.00938797585589
            ],
            [
              105.83757950564528,
              21.00943962394729
            ],
            [
              105.83757928294635,
              21.00943987884957
            ],
            [
              105.83757271044263,
              21.009447484738683
            ],
            [
              105.83744940528993,
              21.009549917543758
            ],
            [
              105.83731934420398,
              21.00968486346731
            ],
            [
              105.83717258267082,
              21.009837097889534
            ],
            [
              105.83703092078113,
              21.009991219233143
            ],
            [
              105.83683649371892,
              21.010199011254095
            ],
            [
              105.83667514186033,
              21.010359323203208
            ],
            [
              105.83660284285966,
              21.010401499787964
            ],
            [
              105.83652400108447,
              21.01041264570385
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D160_Đào Duy Anh",
          "maTaiSan": "04.O17.DOPP.896",
          "duongKinh": 160,
          "chieudaiQL": 164.38,
          "chatLieu": "PVC",
          "from_latitude": 21.00938797585589,
          "to_latitude": 21.01041264570385,
          "to_longitude": 105.83652400108447,
          "from_longitude": 105.83764205800139
        }
      },
      {
        "id": 895,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83238837840963,
            21.00706983409288,
            105.83393353667032,
            21.008335342471508
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83393353667032,
              21.008335342471508
            ],
            [
              105.83390635162856,
              21.00831316872776
            ],
            [
              105.83388061599548,
              21.00829217652188
            ],
            [
              105.83300790427128,
              21.0075773123126
            ],
            [
              105.83247374672233,
              21.007139766078534
            ],
            [
              105.83246991545963,
              21.007136629851654
            ],
            [
              105.83238837840963,
              21.00706983409288
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D160_Phạm Ngọc Thạch",
          "maTaiSan": "04.O17.DOPP.897",
          "duongKinh": 160,
          "chieudaiQL": 213.16,
          "chatLieu": "PVC",
          "from_latitude": 21.008335342471508,
          "to_latitude": 21.00706983409288,
          "to_longitude": 105.83238837840963,
          "from_longitude": 105.83393353667032
        }
      },
      {
        "id": 896,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8306245556,
            21.004696107756253,
            105.83089839139983,
            21.00542837787505
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83089839139983,
              21.00542837787505
            ],
            [
              105.83089807090542,
              21.005427300987527
            ],
            [
              105.83089004837461,
              21.00540027859105
            ],
            [
              105.83089156522766,
              21.00539853789461
            ],
            [
              105.83089059156448,
              21.005395018259073
            ],
            [
              105.83088788906687,
              21.00539421493598
            ],
            [
              105.83088755667004,
              21.00539411539739
            ],
            [
              105.8308858494042,
              21.00539360437704
            ],
            [
              105.8308641175049,
              21.00537043703768
            ],
            [
              105.83084797633744,
              21.005330823555674
            ],
            [
              105.83084789442238,
              21.00533062163286
            ],
            [
              105.83083871451491,
              21.005308069530777
            ],
            [
              105.83079686232055,
              21.00519960757928
            ],
            [
              105.83078846690005,
              21.005177849193164
            ],
            [
              105.83073564130946,
              21.005027425407466
            ],
            [
              105.83069091962723,
              21.004894042057813
            ],
            [
              105.8306836471156,
              21.004872351345607
            ],
            [
              105.8306245556,
              21.004696107756253
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Tôn Thất Tùng",
          "maTaiSan": "04.O17.DOPP.898",
          "duongKinh": 100,
          "chieudaiQL": 198.58,
          "chatLieu": "G",
          "from_latitude": 21.00542837787505,
          "to_latitude": 21.004696107756253,
          "to_longitude": 105.8306245556,
          "from_longitude": 105.83089839139983
        }
      },
      {
        "id": 897,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83201431746917,
            21.00519813495844,
            105.83221238111685,
            21.00576120055631
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83221238111685,
              21.00576120055631
            ],
            [
              105.83217300595483,
              21.005643784930946
            ],
            [
              105.83217145673653,
              21.005639166182196
            ],
            [
              105.83216460846633,
              21.005618745210143
            ],
            [
              105.83215861045004,
              21.005601959178165
            ],
            [
              105.83214351330739,
              21.0055597069187
            ],
            [
              105.8321419031688,
              21.00555520317714
            ],
            [
              105.83213433407953,
              21.005534018888863
            ],
            [
              105.83213273955138,
              21.005529554812814
            ],
            [
              105.83211255503154,
              21.00547306819359
            ],
            [
              105.83209328012283,
              21.005419123318344
            ],
            [
              105.83209215067198,
              21.00541596212966
            ],
            [
              105.83209086392031,
              21.005412360935704
            ],
            [
              105.8320622847617,
              21.005332377278435
            ],
            [
              105.83206077330688,
              21.005328149440594
            ],
            [
              105.83202063493697,
              21.005215814532608
            ],
            [
              105.83202017558952,
              21.005214503487405
            ],
            [
              105.83201585726538,
              21.005202443948633
            ],
            [
              105.83201431746917,
              21.00519813495844
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Đông Tác",
          "maTaiSan": "04.O17.DOPP.899",
          "duongKinh": 100,
          "chieudaiQL": 65.66,
          "chatLieu": "G",
          "from_latitude": 21.00576120055631,
          "to_latitude": 21.00519813495844,
          "to_longitude": 105.83201431746917,
          "from_longitude": 105.83221238111685
        }
      },
      {
        "id": 898,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83115777271836,
            21.005362740248454,
            105.83162082817394,
            21.005795807826217
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83162082817394,
              21.005717361890834
            ],
            [
              105.8316185377252,
              21.005718188695845
            ],
            [
              105.83159661296341,
              21.005725512602012
            ],
            [
              105.83157259615193,
              21.00573353588876
            ],
            [
              105.83155901365105,
              21.005738070512347
            ],
            [
              105.83151807988015,
              21.005751735802946
            ],
            [
              105.83151641219321,
              21.00575229311227
            ],
            [
              105.83140749002476,
              21.0057886571232
            ],
            [
              105.83139390847033,
              21.005793190826015
            ],
            [
              105.83138607022791,
              21.005795807826217
            ],
            [
              105.83133575224174,
              21.005783576453236
            ],
            [
              105.83133252657967,
              21.005782791951038
            ],
            [
              105.83133188125592,
              21.005782635232173
            ],
            [
              105.83131497994545,
              21.005769572234314
            ],
            [
              105.83131396176987,
              21.00576878507827
            ],
            [
              105.83128901843766,
              21.0057495074209
            ],
            [
              105.83128561132136,
              21.00574687399688
            ],
            [
              105.83128472645645,
              21.005746190060524
            ],
            [
              105.83127654336501,
              21.005721106793306
            ],
            [
              105.83127211669171,
              21.005707538473356
            ],
            [
              105.8312356827249,
              21.00559585456856
            ],
            [
              105.83123501442732,
              21.005593807509076
            ],
            [
              105.83119175364713,
              21.00546119937601
            ],
            [
              105.83119122917337,
              21.005459590581832
            ],
            [
              105.8311657166695,
              21.00538477088351
            ],
            [
              105.83115778248188,
              21.00536276639433
            ],
            [
              105.83115777271836,
              21.005362740248454
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Đông Tác",
          "maTaiSan": "04.O17.DOPP.900",
          "duongKinh": 100,
          "chieudaiQL": 93.76,
          "chatLieu": "G",
          "from_latitude": 21.005717361890834,
          "to_latitude": 21.005362740248454,
          "to_longitude": 105.83115777271836,
          "from_longitude": 105.83162082817394
        }
      },
      {
        "id": 899,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83118825764889,
            21.005534018888863,
            105.83213433407953,
            21.006050353858424
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83118825764889,
              21.006050353858424
            ],
            [
              105.83119586093405,
              21.006048034272613
            ],
            [
              105.83169356859862,
              21.005916903022676
            ],
            [
              105.8316915993947,
              21.005911131095555
            ],
            [
              105.83162082817394,
              21.005717361890834
            ],
            [
              105.83162380785777,
              21.005716284234023
            ],
            [
              105.83174282245349,
              21.005670823149856
            ],
            [
              105.83174687970194,
              21.005669273256956
            ],
            [
              105.83187465404102,
              21.005623646434334
            ],
            [
              105.83187765011242,
              21.005622575918878
            ],
            [
              105.83202381167953,
              21.005570383884695
            ],
            [
              105.83202633832222,
              21.00556948095191
            ],
            [
              105.83213433407953,
              21.005534018888863
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Đông Tác",
          "maTaiSan": "04.O17.DOPP.901",
          "duongKinh": 100,
          "chieudaiQL": 124.32,
          "chatLieu": "G",
          "from_latitude": 21.006050353858424,
          "to_latitude": 21.005534018888863,
          "to_longitude": 105.83213433407953,
          "from_longitude": 105.83118825764889
        }
      },
      {
        "id": 900,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8292988409111,
            20.9993164114752,
            105.83593641580568,
            21.00083644732397
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83593641580568,
              20.99932327877359
            ],
            [
              105.83593263657625,
              20.999323071524792
            ],
            [
              105.83584558051444,
              20.999318288274356
            ],
            [
              105.83581152602824,
              20.9993164114752
            ],
            [
              105.83551409081502,
              20.999353693486416
            ],
            [
              105.83441478802543,
              20.999491481105313
            ],
            [
              105.83393627594964,
              20.99958185432334
            ],
            [
              105.83251055184441,
              20.99993176788538
            ],
            [
              105.83140762614474,
              21.00021078884164
            ],
            [
              105.83044825959536,
              21.000449672106072
            ],
            [
              105.82972846487175,
              21.0006684535528
            ],
            [
              105.82956414633338,
              21.00072163885665
            ],
            [
              105.8292988409111,
              21.00083644732397
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Trường Chinh",
          "maTaiSan": "04.O17.DOPP.902",
          "duongKinh": 150,
          "chieudaiQL": 710.99,
          "chatLieu": "G-DI",
          "from_latitude": 20.99932327877359,
          "to_latitude": 21.00083644732397,
          "to_longitude": 105.8292988409111,
          "from_longitude": 105.83593641580568
        }
      },
      {
        "id": 901,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82925107688388,
            21.00083644732397,
            105.82961778686798,
            21.001894362108228
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8292988409111,
              21.00083644732397
            ],
            [
              105.82926456396378,
              21.000912925250304
            ],
            [
              105.82925107688388,
              21.00094302596085
            ],
            [
              105.82930953083225,
              21.00121685342387
            ],
            [
              105.82961778686798,
              21.001894362108228
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D110_Tôn Thất Tùng",
          "maTaiSan": "04.O17.DOPP.903",
          "duongKinh": 110,
          "chieudaiQL": 127.83,
          "chatLieu": "HDPE",
          "from_latitude": 21.00083644732397,
          "to_latitude": 21.001894362108228,
          "to_longitude": 105.82961778686798,
          "from_longitude": 105.8292988409111
        }
      },
      {
        "id": 902,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83118825764889,
            21.006050353858424,
            105.83238837840963,
            21.00706983409288
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83238837840963,
              21.00706983409288
            ],
            [
              105.83222311326459,
              21.006929807744832
            ],
            [
              105.83222146068323,
              21.006928407696915
            ],
            [
              105.83212327799578,
              21.006845213843643
            ],
            [
              105.83189338265883,
              21.00663039358931
            ],
            [
              105.8315302435779,
              21.006314325717636
            ],
            [
              105.83118825764889,
              21.006050353858424
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D160_Phạm Ngọc Thạch",
          "maTaiSan": "04.O17.DOPP.904",
          "duongKinh": 160,
          "chieudaiQL": 166.71,
          "chatLieu": "PVC",
          "from_latitude": 21.00706983409288,
          "to_latitude": 21.006050353858424,
          "to_longitude": 105.83118825764889,
          "from_longitude": 105.83238837840963
        }
      },
      {
        "id": 903,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83124564713351,
            21.00424500121945,
            105.83140856217662,
            21.00529637375178
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83140856217662,
              21.00529637375178
            ],
            [
              105.83140818453053,
              21.005294356886004
            ],
            [
              105.83140486560697,
              21.00527656238147
            ],
            [
              105.83137654915011,
              21.00512481779699
            ],
            [
              105.83133931420771,
              21.0048377872807
            ],
            [
              105.83133757492291,
              21.00482437564048
            ],
            [
              105.83132495497703,
              21.004727090264772
            ],
            [
              105.83132465457871,
              21.00472547405379
            ],
            [
              105.8312938790291,
              21.00455483602227
            ],
            [
              105.83124564713351,
              21.00424500121945
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D160_Ngõ 1, Tôn Thất Tùng",
          "maTaiSan": "04.O17.DOPP.905",
          "duongKinh": 160,
          "chieudaiQL": 118.86,
          "chatLieu": "HDPE",
          "from_latitude": 21.00529637375178,
          "to_latitude": 21.00424500121945,
          "to_longitude": 105.83124564713351,
          "from_longitude": 105.83140856217662
        }
      },
      {
        "id": 904,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83140856217662,
            21.00519813495844,
            105.83201431746917,
            21.005317588615213
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83140856217662,
              21.00529637375178
            ],
            [
              105.8314096183185,
              21.005296201495685
            ],
            [
              105.83143490792202,
              21.005292051372024
            ],
            [
              105.8315032479832,
              21.005317588615213
            ],
            [
              105.83174965672887,
              21.00526178909239
            ],
            [
              105.83175280098686,
              21.005261077339092
            ],
            [
              105.83175667533564,
              21.005260197630587
            ],
            [
              105.83190760336313,
              21.00522392164042
            ],
            [
              105.83190980790202,
              21.005223388800133
            ],
            [
              105.83201431746917,
              21.00519813495844
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D110_Ngõ 1, Tôn Thất Tùng",
          "maTaiSan": "04.O17.DOPP.906",
          "duongKinh": 110,
          "chieudaiQL": 66.48,
          "chatLieu": "HDPE",
          "from_latitude": 21.00529637375178,
          "to_latitude": 21.00519813495844,
          "to_longitude": 105.83201431746917,
          "from_longitude": 105.83140856217662
        }
      },
      {
        "id": 905,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83137654915011,
            21.004965986824335,
            105.83202522984584,
            21.00512481779699
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83137654915011,
              21.00512481779699
            ],
            [
              105.83137950953619,
              21.0051239046261
            ],
            [
              105.83160647724132,
              21.005063340020907
            ],
            [
              105.83163582180309,
              21.005056716893236
            ],
            [
              105.83163724647001,
              21.00505639470598
            ],
            [
              105.8319904792153,
              21.00497409876814
            ],
            [
              105.83202129032138,
              21.00496690659929
            ],
            [
              105.83202522984584,
              21.004965986824335
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D90_Ngõ 1, Tôn Thất Tùng",
          "maTaiSan": "04.O17.DOPP.907",
          "duongKinh": 90,
          "chieudaiQL": 69.39,
          "chatLieu": "HDPE",
          "from_latitude": 21.00512481779699,
          "to_latitude": 21.004965986824335,
          "to_longitude": 105.83202522984584,
          "from_longitude": 105.83137654915011
        }
      },
      {
        "id": 906,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83133931420771,
            21.004680406190484,
            105.83199408556364,
            21.0048377872807
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83133931420771,
              21.0048377872807
            ],
            [
              105.83134566995552,
              21.004835480105836
            ],
            [
              105.83156754699723,
              21.00477959475265
            ],
            [
              105.83158984603429,
              21.004774397928834
            ],
            [
              105.83159403736902,
              21.004773442610997
            ],
            [
              105.83163203702604,
              21.004764784679555
            ],
            [
              105.83179818796985,
              21.00472606163978
            ],
            [
              105.83195424937472,
              21.00468969098546
            ],
            [
              105.83197033504743,
              21.004685940742387
            ],
            [
              105.83199408556364,
              21.004680406190484
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D90_Ngõ 1, Tôn Thất Tùng",
          "maTaiSan": "04.O17.DOPP.908",
          "duongKinh": 90,
          "chieudaiQL": 70.18,
          "chatLieu": "HDPE",
          "from_latitude": 21.0048377872807,
          "to_latitude": 21.004680406190484,
          "to_longitude": 105.83199408556364,
          "from_longitude": 105.83133931420771
        }
      },
      {
        "id": 907,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83089150150705,
            21.00482437564048,
            105.83133757492291,
            21.004921246332742
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83133757492291,
              21.00482437564048
            ],
            [
              105.8313351647147,
              21.00482476496165
            ],
            [
              105.83108453460403,
              21.004875827191047
            ],
            [
              105.83107538660845,
              21.004877971861614
            ],
            [
              105.83106549893925,
              21.004880290853183
            ],
            [
              105.83089150150705,
              21.004921246332742
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D90_Ngõ 1, Tôn Thất Tùng",
          "maTaiSan": "04.O17.DOPP.909",
          "duongKinh": 90,
          "chieudaiQL": 47.79,
          "chatLieu": "HDPE",
          "from_latitude": 21.00482437564048,
          "to_latitude": 21.004921246332742,
          "to_longitude": 105.83089150150705,
          "from_longitude": 105.83133757492291
        }
      },
      {
        "id": 908,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8312938790291,
            21.004398553017293,
            105.8319661102674,
            21.00455483602227
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8312938790291,
              21.00455483602227
            ],
            [
              105.83129700841705,
              21.004554046672848
            ],
            [
              105.83152690866186,
              21.004496025807097
            ],
            [
              105.83152694127938,
              21.004496010292893
            ],
            [
              105.83153554179651,
              21.00449401278977
            ],
            [
              105.83154995078051,
              21.004490756713952
            ],
            [
              105.83177392434557,
              21.00444081673692
            ],
            [
              105.83188334172584,
              21.004416417842968
            ],
            [
              105.83191265508779,
              21.004410091081713
            ],
            [
              105.83191269542493,
              21.004410080045833
            ],
            [
              105.83193771822759,
              21.004404679284207
            ],
            [
              105.8319661102674,
              21.004398553017293
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D90_Ngõ 1, Tôn Thất Tùng",
          "maTaiSan": "04.O17.DOPP.910",
          "duongKinh": 90,
          "chieudaiQL": 71.02,
          "chatLieu": "HDPE",
          "from_latitude": 21.00455483602227,
          "to_latitude": 21.004398553017293,
          "to_longitude": 105.8319661102674,
          "from_longitude": 105.8312938790291
        }
      },
      {
        "id": 909,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83124690464899,
            21.00409120112284,
            105.83194399193259,
            21.004244718690696
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83124690464899,
              21.004244718690696
            ],
            [
              105.8314839656403,
              21.00419123324693
            ],
            [
              105.83150578889139,
              21.0041866330834
            ],
            [
              105.83150985955433,
              21.004185775002764
            ],
            [
              105.8318701942991,
              21.0041068832491
            ],
            [
              105.83189205670433,
              21.00410223678077
            ],
            [
              105.83194399193259,
              21.00409120112284
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D90_Ngõ 1A, Tôn Thất Tùng",
          "maTaiSan": "04.O17.DOPP.911",
          "duongKinh": 90,
          "chieudaiQL": 72.8,
          "chatLieu": "HDPE",
          "from_latitude": 21.004244718690696,
          "to_latitude": 21.00409120112284,
          "to_longitude": 105.83194399193259,
          "from_longitude": 105.83124690464899
        }
      },
      {
        "id": 910,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83056712262257,
            21.004244718690696,
            105.83124690464899,
            21.00439057544045
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83056712262257,
              21.00439057544045
            ],
            [
              105.8306673076567,
              21.00436851024866
            ],
            [
              105.83068699627738,
              21.004364202413388
            ],
            [
              105.83068709811236,
              21.00436418023893
            ],
            [
              105.83105023256554,
              21.00428576788981
            ],
            [
              105.83107579539154,
              21.004280266166138
            ],
            [
              105.83108055787838,
              21.00427927736265
            ],
            [
              105.83124564713351,
              21.00424500121945
            ],
            [
              105.83124690464899,
              21.004244718690696
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D90_Ngõ 1A, Tôn Thất Tùng",
          "maTaiSan": "04.O17.DOPP.912",
          "duongKinh": 90,
          "chieudaiQL": 74.03,
          "chatLieu": "HDPE",
          "from_latitude": 21.00439057544045,
          "to_latitude": 21.004244718690696,
          "to_longitude": 105.83124690464899,
          "from_longitude": 105.83056712262257
        }
      },
      {
        "id": 911,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83087441063267,
            21.004725463060332,
            105.83132468625946,
            21.00483308907101
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83132468625946,
              21.004725463060332
            ],
            [
              105.83132465457871,
              21.00472547405379
            ],
            [
              105.83131907929629,
              21.004726809157145
            ],
            [
              105.83108553198558,
              21.004783406915557
            ],
            [
              105.83108550704277,
              21.004783418779283
            ],
            [
              105.83107781924662,
              21.004785230255
            ],
            [
              105.83106602092103,
              21.004787999350512
            ],
            [
              105.83106598250237,
              21.00478800947359
            ],
            [
              105.83087441063267,
              21.00483308907101
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D90_Ngõ 1, Tôn Thất Tùng",
          "maTaiSan": "04.O17.DOPP.913",
          "duongKinh": 90,
          "chieudaiQL": 48.29,
          "chatLieu": "HDPE",
          "from_latitude": 21.004725463060332,
          "to_latitude": 21.00483308907101,
          "to_longitude": 105.83087441063267,
          "from_longitude": 105.83132468625946
        }
      },
      {
        "id": 912,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83909487324532,
            20.999482268229823,
            105.83920577739286,
            20.99981918420688
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83920577739286,
              20.999482268229823
            ],
            [
              105.83919803718624,
              20.99950732753809
            ],
            [
              105.83916299335772,
              20.999620814437918
            ],
            [
              105.83915108405652,
              20.99966935828615
            ],
            [
              105.83915046808833,
              20.999671868676355
            ],
            [
              105.83913012331361,
              20.99975479383294
            ],
            [
              105.83910677500438,
              20.999791655746115
            ],
            [
              105.83910546032583,
              20.999794263515664
            ],
            [
              105.83910456832773,
              20.999796777072728
            ],
            [
              105.83909954347264,
              20.999809985319516
            ],
            [
              105.83909487324532,
              20.99981918420688
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 74, Trường Chinh",
          "maTaiSan": "04.O17.DOPP.914",
          "duongKinh": 100,
          "chieudaiQL": 39.2,
          "chatLieu": "G-DI",
          "from_latitude": 20.999482268229823,
          "to_latitude": 20.99981918420688,
          "to_longitude": 105.83909487324532,
          "from_longitude": 105.83920577739286
        }
      },
      {
        "id": 913,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84060801058034,
            20.998365609370943,
            105.84063887977194,
            20.9991454850408
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84060801058034,
              20.998365609370943
            ],
            [
              105.84060822795136,
              20.998373167390408
            ],
            [
              105.84061277158003,
              20.99855009221458
            ],
            [
              105.84062296699688,
              20.99875863633466
            ],
            [
              105.84062321467465,
              20.998763704915188
            ],
            [
              105.84062741486272,
              20.998869496113425
            ],
            [
              105.8406284561207,
              20.998895705278862
            ],
            [
              105.84062859158054,
              20.998899112478924
            ],
            [
              105.84063032706997,
              20.998942633044546
            ],
            [
              105.84063052331626,
              20.998950883941518
            ],
            [
              105.84063887977194,
              20.99911820128504
            ],
            [
              105.84063814189648,
              20.9991454850408
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 78, Giải Phóng",
          "maTaiSan": "04.O17.DOPP.915",
          "duongKinh": 100,
          "chieudaiQL": 81.83,
          "chatLieu": "G-DI",
          "from_latitude": 20.998365609370943,
          "to_latitude": 20.9991454850408,
          "to_longitude": 105.84063814189648,
          "from_longitude": 105.84060801058034
        }
      },
      {
        "id": 914,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83593224692859,
            20.9982444080632,
            105.84050158420563,
            20.99932892543962
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84050158420563,
              20.9982444080632
            ],
            [
              105.84050085697443,
              20.998244563395453
            ],
            [
              105.83987993316376,
              20.998377125948284
            ],
            [
              105.83971087148126,
              20.998416546616212
            ],
            [
              105.83930113491624,
              20.998513422071333
            ],
            [
              105.83928859100993,
              20.998516387783308
            ],
            [
              105.83928418685971,
              20.99851742923732
            ],
            [
              105.83927810077465,
              20.998518868273283
            ],
            [
              105.83915222751496,
              20.99854862953996
            ],
            [
              105.8391445276986,
              20.9985504513642
            ],
            [
              105.8390476930931,
              20.998573365676066
            ],
            [
              105.83795144862894,
              20.99885980468279
            ],
            [
              105.83777975697888,
              20.998906604018153
            ],
            [
              105.83716933489951,
              20.999072998687552
            ],
            [
              105.83629821179677,
              20.999275121152316
            ],
            [
              105.83629177566004,
              20.99927485470558
            ],
            [
              105.8359398133425,
              20.99931867731476
            ],
            [
              105.83593641580568,
              20.99932327877359
            ],
            [
              105.83593224692859,
              20.99932892543962
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D200_Trường Chinh",
          "maTaiSan": "04.O17.DOPP.916",
          "duongKinh": 200,
          "chieudaiQL": 494.22,
          "chatLieu": "G-DI",
          "from_latitude": 20.9982444080632,
          "to_latitude": 20.99932892543962,
          "to_longitude": 105.83593224692859,
          "from_longitude": 105.84050158420563
        }
      },
      {
        "id": 915,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83483759216985,
            21.001165404771697,
            105.83613217163239,
            21.00161373012631
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83483759216985,
              21.001165404771697
            ],
            [
              105.83484638477267,
              21.00119989017423
            ],
            [
              105.83485000843406,
              21.001204630597016
            ],
            [
              105.83489624605119,
              21.001265129513268
            ],
            [
              105.835059734712,
              21.001289182542557
            ],
            [
              105.83534058947576,
              21.00133533047017
            ],
            [
              105.83534185738706,
              21.00133553921587
            ],
            [
              105.83555187213696,
              21.001362974538985
            ],
            [
              105.83559123212265,
              21.001372191139946
            ],
            [
              105.83575277205017,
              21.00141002093428
            ],
            [
              105.83577707684395,
              21.00141570844051
            ],
            [
              105.83598506233726,
              21.00148702196099
            ],
            [
              105.83599574190407,
              21.001490683576186
            ],
            [
              105.83601569015325,
              21.00150873954018
            ],
            [
              105.83604244411192,
              21.001532955494852
            ],
            [
              105.83606264951932,
              21.00155124412538
            ],
            [
              105.83611865932397,
              21.001601936241197
            ],
            [
              105.83613058851861,
              21.001612364096218
            ],
            [
              105.83613217163239,
              21.00161373012631
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D200_Lương Định Của",
          "maTaiSan": "04.O17.DOPP.917",
          "duongKinh": 200,
          "chieudaiQL": 148.76,
          "chatLieu": "G-DI",
          "from_latitude": 21.001165404771697,
          "to_latitude": 21.00161373012631,
          "to_longitude": 105.83613217163239,
          "from_longitude": 105.83483759216985
        }
      },
      {
        "id": 916,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83602700872244,
            21.001951372858674,
            105.8360560262139,
            21.003393727723896
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8360430298095,
              21.001951372858674
            ],
            [
              105.83604302442589,
              21.001951442433494
            ],
            [
              105.8360400784502,
              21.002079434958073
            ],
            [
              105.83603995934996,
              21.002093971154768
            ],
            [
              105.83603993600339,
              21.002096859785322
            ],
            [
              105.8360394584223,
              21.002237164699025
            ],
            [
              105.83604523390954,
              21.002437921600066
            ],
            [
              105.8360455368819,
              21.002454151980935
            ],
            [
              105.83604556084268,
              21.002455863475937
            ],
            [
              105.83604585231915,
              21.00247106604238
            ],
            [
              105.83604582358585,
              21.00247281482846
            ],
            [
              105.83604493236355,
              21.002546625389794
            ],
            [
              105.83604337850637,
              21.002675881906793
            ],
            [
              105.83604355039695,
              21.00268325680334
            ],
            [
              105.83604794538859,
              21.002871703185853
            ],
            [
              105.83604785644981,
              21.00287783923454
            ],
            [
              105.83604673688103,
              21.002963321492626
            ],
            [
              105.83604505982852,
              21.00308997189949
            ],
            [
              105.8360560262139,
              21.003178775564614
            ],
            [
              105.83605582889074,
              21.003180131372265
            ],
            [
              105.83604325947354,
              21.00326994664074
            ],
            [
              105.83602722927276,
              21.003392052060345
            ],
            [
              105.83602700872244,
              21.003393727723896
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D225_Lương Định Của",
          "maTaiSan": "04.O17.DOPP.918",
          "duongKinh": 225,
          "chieudaiQL": 157.12,
          "chatLieu": "PVC",
          "from_latitude": 21.001951372858674,
          "to_latitude": 21.003393727723896,
          "to_longitude": 105.83602700872244,
          "from_longitude": 105.8360430298095
        }
      },
      {
        "id": 917,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83921621548774,
            20.99910809716845,
            105.83923675559828,
            20.99950528764866
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83921621548774,
              20.99910809716845
            ],
            [
              105.83921654774484,
              20.99911161449706
            ],
            [
              105.83921938186569,
              20.99914165445071
            ],
            [
              105.83923675559828,
              20.99932578615463
            ],
            [
              105.83923421376484,
              20.99950528764866
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D200_Ngõ 74, Trường Chinh",
          "maTaiSan": "04.O17.DOPP.919",
          "duongKinh": 200,
          "chieudaiQL": 42.53,
          "chatLieu": "G-DI",
          "from_latitude": 20.99910809716845,
          "to_latitude": 20.99950528764866,
          "to_longitude": 105.83923421376484,
          "from_longitude": 105.83921621548774
        }
      },
      {
        "id": 918,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84060134562604,
            20.999157506778094,
            105.84108663984314,
            20.99975088995778
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84105292139544,
              20.999157506778094
            ],
            [
              105.84105168185232,
              20.99920527996507
            ],
            [
              105.8410504413927,
              20.99925306128537
            ],
            [
              105.84108663984314,
              20.99972515357425
            ],
            [
              105.84067470461368,
              20.999726316748628
            ],
            [
              105.84067413666203,
              20.99975027850442
            ],
            [
              105.84060134562604,
              20.99975088995778
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Giải Phóng",
          "maTaiSan": "04.O17.DOPP.920",
          "duongKinh": 100,
          "chieudaiQL": 116.04,
          "chatLieu": "G-DI",
          "from_latitude": 20.999157506778094,
          "to_latitude": 20.99975088995778,
          "to_longitude": 105.84060134562604,
          "from_longitude": 105.84105292139544
        }
      },
      {
        "id": 919,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8375005105948,
            21.00103747426165,
            105.83752643679622,
            21.00149822783932
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83751779809629,
              21.00103747426165
            ],
            [
              105.83751974316473,
              21.00118042633328
            ],
            [
              105.83752323505638,
              21.001333062402065
            ],
            [
              105.8375233227134,
              21.00133687809876
            ],
            [
              105.83752643679622,
              21.001472997834725
            ],
            [
              105.8375005105948,
              21.00149822783932
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D200_Ngõ 102, Trường Chinh",
          "maTaiSan": "04.O17.DOPP.921",
          "duongKinh": 200,
          "chieudaiQL": 52.11,
          "chatLieu": "G-DI",
          "from_latitude": 21.00103747426165,
          "to_latitude": 21.00149822783932,
          "to_longitude": 105.8375005105948,
          "from_longitude": 105.83751779809629
        }
      },
      {
        "id": 920,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83750451895371,
            21.000965896891532,
            105.83758303868828,
            21.00150708230885
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83758303868828,
              21.000965896891532
            ],
            [
              105.83753860334811,
              21.001006034485357
            ],
            [
              105.83752882461181,
              21.00102970827001
            ],
            [
              105.83752700510733,
              21.001049414765774
            ],
            [
              105.83752573708664,
              21.001063150033467
            ],
            [
              105.8375303212528,
              21.001324494764862
            ],
            [
              105.83753074861892,
              21.001329721434885
            ],
            [
              105.837535072038,
              21.001437622067396
            ],
            [
              105.83753583757954,
              21.001471911064858
            ],
            [
              105.83753594015616,
              21.001476504365257
            ],
            [
              105.83750451895371,
              21.00150708230885
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D200_Ngõ 102, Trường Chinh",
          "maTaiSan": "04.O17.DOPP.922",
          "duongKinh": 200,
          "chieudaiQL": 61.57,
          "chatLieu": "G-DI",
          "from_latitude": 21.000965896891532,
          "to_latitude": 21.00150708230885,
          "to_longitude": 105.83750451895371,
          "from_longitude": 105.83758303868828
        }
      },
      {
        "id": 921,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83613217163239,
            21.00150708230885,
            105.83750451895371,
            21.00161373012631
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83750451895371,
              21.00150708230885
            ],
            [
              105.83738133379593,
              21.001511106151018
            ],
            [
              105.83722285398434,
              21.001521561049007
            ],
            [
              105.8372118567517,
              21.00153010274865
            ],
            [
              105.83716406378704,
              21.001567215603153
            ],
            [
              105.83712641894547,
              21.00159823957147
            ],
            [
              105.83701168796372,
              21.001596679379244
            ],
            [
              105.83700723043235,
              21.001596619104248
            ],
            [
              105.83692837614609,
              21.00159554631073
            ],
            [
              105.83692459965363,
              21.00159549533025
            ],
            [
              105.8368668228684,
              21.001595213971875
            ],
            [
              105.83681579650293,
              21.001593412699187
            ],
            [
              105.83681048029305,
              21.00159322476938
            ],
            [
              105.83680836862521,
              21.001593150247167
            ],
            [
              105.83680442331574,
              21.001593010674583
            ],
            [
              105.83660495389759,
              21.00158596688959
            ],
            [
              105.83659960209562,
              21.001585778224882
            ],
            [
              105.83659691416686,
              21.001585683048372
            ],
            [
              105.83659438978773,
              21.001585594293815
            ],
            [
              105.8364914192717,
              21.00158195724073
            ],
            [
              105.83641729315902,
              21.001582217620104
            ],
            [
              105.83641210121503,
              21.00158223590592
            ],
            [
              105.83620242764799,
              21.00158297249701
            ],
            [
              105.83613883253342,
              21.001610815214143
            ],
            [
              105.83613217163239,
              21.00161373012631
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D200_Ngõ 91, Lương Định Của",
          "maTaiSan": "04.O17.DOPP.923",
          "duongKinh": 200,
          "chieudaiQL": 146.81,
          "chatLieu": "G-DI",
          "from_latitude": 21.00150708230885,
          "to_latitude": 21.00161373012631,
          "to_longitude": 105.83613217163239,
          "from_longitude": 105.83750451895371
        }
      },
      {
        "id": 922,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83602971557545,
            21.00151416477013,
            105.83606046928548,
            21.001951372858674
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83606046928548,
              21.00151416477013
            ],
            [
              105.83604244411192,
              21.001532955494852
            ],
            [
              105.83603495442293,
              21.001540763634985
            ],
            [
              105.83602979668403,
              21.001652439415967
            ],
            [
              105.83602971557545,
              21.001666198652615
            ],
            [
              105.8360325442856,
              21.00172679933808
            ],
            [
              105.8360430298095,
              21.001951372858674
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D200_Lương Định Của",
          "maTaiSan": "04.O17.DOPP.924",
          "duongKinh": 200,
          "chieudaiQL": 51,
          "chatLieu": "G-DI",
          "from_latitude": 21.00151416477013,
          "to_latitude": 21.001951372858674,
          "to_longitude": 105.8360430298095,
          "from_longitude": 105.83606046928548
        }
      },
      {
        "id": 923,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83441596377449,
            21.00300322202992,
            105.83504949665834,
            21.003160709583902
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83504949665834,
              21.003160709583902
            ],
            [
              105.83489106542596,
              21.003121978768785
            ],
            [
              105.83487916935827,
              21.003119070022137
            ],
            [
              105.83467464301812,
              21.003069067929044
            ],
            [
              105.83451930834782,
              21.00303002080285
            ],
            [
              105.83441596377449,
              21.00300322202992
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D110_Phương Mai",
          "maTaiSan": "04.O17.DOPP.925",
          "duongKinh": 110,
          "chieudaiQL": 70.23,
          "chatLieu": "PVC",
          "from_latitude": 21.003160709583902,
          "to_latitude": 21.00300322202992,
          "to_longitude": 105.83441596377449,
          "from_longitude": 105.83504949665834
        }
      },
      {
        "id": 924,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83460507444148,
            21.0021840421926,
            105.83468971161936,
            21.00235838962148
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83460507444148,
              21.00235838962148
            ],
            [
              105.83460822526506,
              21.002354374687773
            ],
            [
              105.83461475231546,
              21.00234605829562
            ],
            [
              105.834637895918,
              21.0023165682941
            ],
            [
              105.83468971161936,
              21.0021840421926
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Phương Mai",
          "maTaiSan": "04.O17.DOPP.926",
          "duongKinh": 100,
          "chieudaiQL": 21.17,
          "chatLieu": "G-DI",
          "from_latitude": 21.00235838962148,
          "to_latitude": 21.0021840421926,
          "to_longitude": 105.83468971161936,
          "from_longitude": 105.83460507444148
        }
      },
      {
        "id": 925,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83827187484441,
            21.00328002243297,
            105.8383069077525,
            21.00395255402601
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83827187484441,
              21.00395255402601
            ],
            [
              105.83829191614026,
              21.003831482761928
            ],
            [
              105.83829690579014,
              21.003761651526776
            ],
            [
              105.83830195654157,
              21.003616138752164
            ],
            [
              105.8383069077525,
              21.00347071498159
            ],
            [
              105.83830653010793,
              21.00346182549268
            ],
            [
              105.83829627700642,
              21.00328002243297
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Ngõ 15, Phương Mai",
          "maTaiSan": "04.O17.DOPP.927",
          "duongKinh": 150,
          "chieudaiQL": 74.55,
          "chatLieu": "G-DI",
          "from_latitude": 21.00395255402601,
          "to_latitude": 21.00328002243297,
          "to_longitude": 105.83829627700642,
          "from_longitude": 105.83827187484441
        }
      },
      {
        "id": 926,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83236063725374,
            21.005872768795147,
            105.83281489947177,
            21.00701997893348
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83236063725374,
              21.00701997893348
            ],
            [
              105.83239564797391,
              21.00693364898173
            ],
            [
              105.83241293498378,
              21.006891006232575
            ],
            [
              105.83241628658777,
              21.006882756935504
            ],
            [
              105.83247783554457,
              21.00671129547909
            ],
            [
              105.83255454239749,
              21.006473391715982
            ],
            [
              105.83264566666561,
              21.006190774540364
            ],
            [
              105.83273418168928,
              21.005916247632584
            ],
            [
              105.8328113597755,
              21.005874675664668
            ],
            [
              105.83281489947177,
              21.005872768795147
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Ngõ 110, Phạm Ngọc Thạch",
          "maTaiSan": "04.O17.DOPP.928",
          "duongKinh": 150,
          "chieudaiQL": 138.49,
          "chatLieu": "G",
          "from_latitude": 21.00701997893348,
          "to_latitude": 21.005872768795147,
          "to_longitude": 105.83281489947177,
          "from_longitude": 105.83236063725374
        }
      },
      {
        "id": 927,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83091179246524,
            21.005296551730986,
            105.83140799854438,
            21.005427557353222
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83091179246524,
              21.005427557353222
            ],
            [
              105.83092247020178,
              21.00542495089936
            ],
            [
              105.83093519631281,
              21.005422443734595
            ],
            [
              105.8309604807198,
              21.00540814144273
            ],
            [
              105.83115720908555,
              21.005362918226858
            ],
            [
              105.83115778248188,
              21.00536276639433
            ],
            [
              105.83140799854438,
              21.005296551730986
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D160_Ngõ 1, Tôn Thất Tùng",
          "maTaiSan": "04.O17.DOPP.929",
          "duongKinh": 160,
          "chieudaiQL": 57.8,
          "chatLieu": "HDPE",
          "from_latitude": 21.005427557353222,
          "to_latitude": 21.005296551730986,
          "to_longitude": 105.83140799854438,
          "from_longitude": 105.83091179246524
        }
      },
      {
        "id": 928,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83076099324045,
            21.005424841440707,
            105.83118825764889,
            21.006050353858424
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83118825764889,
              21.006050353858424
            ],
            [
              105.83106367277041,
              21.00586505392795
            ],
            [
              105.83091179246524,
              21.005427557353222
            ],
            [
              105.83091084922181,
              21.005424841440707
            ],
            [
              105.83089858155024,
              21.005428323656375
            ],
            [
              105.83089839139983,
              21.00542837787505
            ],
            [
              105.8308364576083,
              21.00544595804063
            ],
            [
              105.8307717128177,
              21.005464335905746
            ],
            [
              105.83076104696381,
              21.005467352357034
            ],
            [
              105.83076099324045,
              21.005467377909596
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D160_Tôn Thất Tùng",
          "maTaiSan": "04.O17.DOPP.930",
          "duongKinh": 160,
          "chieudaiQL": 85.16,
          "chatLieu": "PVC",
          "from_latitude": 21.006050353858424,
          "to_latitude": 21.005467377909596,
          "to_longitude": 105.83076099324045,
          "from_longitude": 105.83118825764889
        }
      },
      {
        "id": 929,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83419120531893,
            21.00798764748729,
            105.83488944562623,
            21.008568918152452
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83488944562623,
              21.008568918152452
            ],
            [
              105.83466940002499,
              21.008389688505048
            ],
            [
              105.83464894702607,
              21.008373028571203
            ],
            [
              105.83463196680019,
              21.008359197665563
            ],
            [
              105.8344265858641,
              21.008191913172844
            ],
            [
              105.83441630479457,
              21.008183538296997
            ],
            [
              105.83440890802993,
              21.008177513019124
            ],
            [
              105.834400385568,
              21.008170571067243
            ],
            [
              105.83419514223681,
              21.00799109205866
            ],
            [
              105.83419120531893,
              21.00798764748729
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Lương Định Của",
          "maTaiSan": "04.O17.DOPP.931",
          "duongKinh": 150,
          "chieudaiQL": 92.18,
          "chatLieu": "G",
          "from_latitude": 21.008568918152452,
          "to_latitude": 21.00798764748729,
          "to_longitude": 105.83419120531893,
          "from_longitude": 105.83488944562623
        }
      },
      {
        "id": 930,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83647687171418,
            21.004953720590954,
            105.83678746170219,
            21.00586731883393
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83678746170219,
              21.004953720590954
            ],
            [
              105.83678409610947,
              21.004963205648842
            ],
            [
              105.83671392416156,
              21.005160948720185
            ],
            [
              105.83662982883185,
              21.005370918522484
            ],
            [
              105.83659671279284,
              21.00545360448599
            ],
            [
              105.83653464773681,
              21.005667863755342
            ],
            [
              105.83647689851502,
              21.005867295218348
            ],
            [
              105.83647687171418,
              21.00586731883393
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 38, Phương Mai",
          "maTaiSan": "04.O17.DOPP.932",
          "duongKinh": 100,
          "chieudaiQL": 108.6,
          "chatLieu": "G-DI",
          "from_latitude": 21.004953720590954,
          "to_latitude": 21.00586731883393,
          "to_longitude": 105.83647687171418,
          "from_longitude": 105.83678746170219
        }
      },
      {
        "id": 931,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83526623234533,
            21.00925056948826,
            105.83543348520287,
            21.00944679242957
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83526623234533,
              21.00944679242957
            ],
            [
              105.83527169275139,
              21.00944017569705
            ],
            [
              105.83539196108505,
              21.009294443371484
            ],
            [
              105.83543348520287,
              21.00925056948826
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 43, Phạm Ngọc Thạch",
          "maTaiSan": "04.O17.DOPP.933",
          "duongKinh": 100,
          "chieudaiQL": 23.52,
          "chatLieu": "G",
          "from_latitude": 21.00944679242957,
          "to_latitude": 21.00925056948826,
          "to_longitude": 105.83543348520287,
          "from_longitude": 105.83526623234533
        }
      },
      {
        "id": 932,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83602674694515,
            21.010011218797043,
            105.83652400108447,
            21.01041264570385
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83652400108447,
              21.01041264570385
            ],
            [
              105.8361820962463,
              21.01014368567874
            ],
            [
              105.83602674694515,
              21.010011218797043
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D160_Phạm Ngọc Thạch",
          "maTaiSan": "04.O17.DOPP.934",
          "duongKinh": 160,
          "chieudaiQL": 68.19,
          "chatLieu": "PVC",
          "from_latitude": 21.01041264570385,
          "to_latitude": 21.010011218797043,
          "to_longitude": 105.83602674694515,
          "from_longitude": 105.83652400108447
        }
      },
      {
        "id": 933,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8332974466543,
            21.003656213122564,
            105.83402041638074,
            21.004035935146234
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8332974466543,
              21.004035935146234
            ],
            [
              105.83329801849261,
              21.00403533079809
            ],
            [
              105.8333258909662,
              21.004005985814082
            ],
            [
              105.83335569127156,
              21.003974610870166
            ],
            [
              105.83341869571802,
              21.00390906146114
            ],
            [
              105.8334598915237,
              21.003867879473166
            ],
            [
              105.83346590058402,
              21.00386187340172
            ],
            [
              105.83347342290097,
              21.00385435333893
            ],
            [
              105.83347378682839,
              21.003854068847104
            ],
            [
              105.8335074109289,
              21.003827799154287
            ],
            [
              105.83362115089379,
              21.0037389391433
            ],
            [
              105.83363633818787,
              21.003724132241075
            ],
            [
              105.83369471285586,
              21.003667219476075
            ],
            [
              105.83372910489139,
              21.00366007811223
            ],
            [
              105.83377563809829,
              21.00370896669618
            ],
            [
              105.83378529035683,
              21.003719353479628
            ],
            [
              105.83378945902156,
              21.00372384828528
            ],
            [
              105.83383950019173,
              21.003778453344086
            ],
            [
              105.8338690048864,
              21.00377544105629
            ],
            [
              105.83390010905548,
              21.003750598085418
            ],
            [
              105.83397521637048,
              21.003690609053578
            ],
            [
              105.83402041638074,
              21.003656213122564
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 41, Đông Tác",
          "maTaiSan": "04.O17.DOPP.935",
          "duongKinh": 100,
          "chieudaiQL": 102.89,
          "chatLieu": "G",
          "from_latitude": 21.004035935146234,
          "to_latitude": 21.003656213122564,
          "to_longitude": 105.83402041638074,
          "from_longitude": 105.8332974466543
        }
      },
      {
        "id": 934,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82530112668735,
            21.007514704010298,
            105.82602041714787,
            21.00849071952873
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82530112668735,
              21.007514704010298
            ],
            [
              105.82531140005497,
              21.007536991896227
            ],
            [
              105.82534917485415,
              21.007618938227598
            ],
            [
              105.82536626513667,
              21.007654343130277
            ],
            [
              105.82537127108519,
              21.00766471318785
            ],
            [
              105.82541547449748,
              21.00775369968668
            ],
            [
              105.82541645070992,
              21.00775560437364
            ],
            [
              105.82550381556469,
              21.00793192947816
            ],
            [
              105.82554666328609,
              21.00801469656879
            ],
            [
              105.82559587106606,
              21.00811634365329
            ],
            [
              105.82561429578541,
              21.00815440295837
            ],
            [
              105.82562703139473,
              21.008179609846792
            ],
            [
              105.82573688136145,
              21.008383272976612
            ],
            [
              105.82575192538036,
              21.008396038445152
            ],
            [
              105.82576511003299,
              21.008407225061923
            ],
            [
              105.82580088744166,
              21.008425897279523
            ],
            [
              105.82587133969942,
              21.00839416283579
            ],
            [
              105.82593872148259,
              21.00836381075134
            ],
            [
              105.82594373163265,
              21.008372660638326
            ],
            [
              105.82601840902468,
              21.008487630304877
            ],
            [
              105.82602041714787,
              21.00849071952873
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 249, Chùa Bộc",
          "maTaiSan": "04.O18.DOPP.936",
          "duongKinh": 100,
          "chieudaiQL": 148.19,
          "chatLieu": "G",
          "from_latitude": 21.007514704010298,
          "to_latitude": 21.00849071952873,
          "to_longitude": 105.82602041714787,
          "from_longitude": 105.82530112668735
        }
      },
      {
        "id": 935,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82561031593482,
            21.00521325218162,
            105.82649962769568,
            21.006959778120933
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82649962769568,
              21.006959778120933
            ],
            [
              105.82649946141241,
              21.006959451057135
            ],
            [
              105.82634974777207,
              21.00665140995103
            ],
            [
              105.82622811649976,
              21.0064210561967
            ],
            [
              105.82616922327391,
              21.006309539759812
            ],
            [
              105.82616742978051,
              21.00630611981647
            ],
            [
              105.82610123187102,
              21.006180748986456
            ],
            [
              105.82609945807029,
              21.006177411140328
            ],
            [
              105.8259775556635,
              21.00593872079977
            ],
            [
              105.8259576865842,
              21.005899819495404
            ],
            [
              105.82584042885841,
              21.005670270637687
            ],
            [
              105.82582939741566,
              21.005648649424607
            ],
            [
              105.82576300067403,
              21.00551867655667
            ],
            [
              105.82568015318931,
              21.00538941120944
            ],
            [
              105.82567331785552,
              21.005382369391427
            ],
            [
              105.82565805032272,
              21.00530671508554
            ],
            [
              105.8256492368649,
              21.005288913684964
            ],
            [
              105.82563201059402,
              21.005254101068875
            ],
            [
              105.82562041341262,
              21.005232264906155
            ],
            [
              105.82561031593482,
              21.00521325218162
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Thủy Lợi",
          "maTaiSan": "04.O18.DOPP.937",
          "duongKinh": 150,
          "chieudaiQL": 213.54,
          "chatLieu": "G",
          "from_latitude": 21.006959778120933,
          "to_latitude": 21.00521325218162,
          "to_longitude": 105.82561031593482,
          "from_longitude": 105.82649962769568
        }
      },
      {
        "id": 936,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82568669520197,
            21.007482750073944,
            105.82650283499672,
            21.008265963971674
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82568669520197,
              21.0075831030047
            ],
            [
              105.82569133048185,
              21.007580995867478
            ],
            [
              105.82569220649404,
              21.00758059781028
            ],
            [
              105.82580427159476,
              21.007529651281974
            ],
            [
              105.8259074364288,
              21.007482750073944
            ],
            [
              105.82592061494147,
              21.007509261762426
            ],
            [
              105.82609692017587,
              21.00784790552657
            ],
            [
              105.8260983495994,
              21.00785018736459
            ],
            [
              105.82613790003137,
              21.007920507580693
            ],
            [
              105.82626991182441,
              21.008170527466337
            ],
            [
              105.82640671365101,
              21.00811740830012
            ],
            [
              105.82640974097876,
              21.008116232952645
            ],
            [
              105.82641017381032,
              21.008116928141817
            ],
            [
              105.8264237700726,
              21.008138750009824
            ],
            [
              105.82649912899194,
              21.008259706351343
            ],
            [
              105.82650283499672,
              21.008265963971674
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 203, Chùa Bộc",
          "maTaiSan": "04.O18.DOPP.938",
          "duongKinh": 100,
          "chieudaiQL": 143.3,
          "chatLieu": "G",
          "from_latitude": 21.0075831030047,
          "to_latitude": 21.008265963971674,
          "to_longitude": 105.82650283499672,
          "from_longitude": 105.82568669520197
        }
      },
      {
        "id": 937,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8294427308417,
            21.005387282458475,
            105.83071252168563,
            21.00576339367681
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83071252168563,
              21.005387282458475
            ],
            [
              105.8307079173179,
              21.005388866610208
            ],
            [
              105.83070026960857,
              21.00539149710034
            ],
            [
              105.83070016016312,
              21.005391534666803
            ],
            [
              105.83030638819322,
              21.005526973779705
            ],
            [
              105.8302111933073,
              21.0055425726272
            ],
            [
              105.83020637817734,
              21.005543269018943
            ],
            [
              105.83016297374903,
              21.005549543194164
            ],
            [
              105.83006713946541,
              21.00556241011111
            ],
            [
              105.82999567427197,
              21.005519980936477
            ],
            [
              105.82982934389572,
              21.005595043538108
            ],
            [
              105.82982189515685,
              21.0055985238586
            ],
            [
              105.82982025747373,
              21.005599289650444
            ],
            [
              105.82961517357823,
              21.005690633779807
            ],
            [
              105.82961174620586,
              21.005692160681228
            ],
            [
              105.82960850658165,
              21.005693546667267
            ],
            [
              105.82946266517828,
              21.00575407819581
            ],
            [
              105.82945937156964,
              21.00575561708747
            ],
            [
              105.8294503714736,
              21.005759823017865
            ],
            [
              105.8294427308417,
              21.00576339367681
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 6, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DOPP.939",
          "duongKinh": 100,
          "chieudaiQL": 146.19,
          "chatLieu": "G",
          "from_latitude": 21.005387282458475,
          "to_latitude": 21.00576339367681,
          "to_longitude": 105.8294427308417,
          "from_longitude": 105.83071252168563
        }
      },
      {
        "id": 938,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8267579597138,
            21.004752572070533,
            105.82737155867866,
            21.00503855285923
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82737155867866,
              21.004752572070533
            ],
            [
              105.82736817848787,
              21.004754108633097
            ],
            [
              105.82707664423344,
              21.00488663980725
            ],
            [
              105.826835040658,
              21.005001809074326
            ],
            [
              105.82676708556774,
              21.00503412065234
            ],
            [
              105.82676582688698,
              21.00503471838027
            ],
            [
              105.8267579597138,
              21.00503855285923
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Thủy Lợi",
          "maTaiSan": "04.O18.DOPP.940",
          "duongKinh": 100,
          "chieudaiQL": 71.23,
          "chatLieu": "G",
          "from_latitude": 21.004752572070533,
          "to_latitude": 21.00503855285923,
          "to_longitude": 105.8267579597138,
          "from_longitude": 105.82737155867866
        }
      },
      {
        "id": 939,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82657781664368,
            21.00509274043167,
            105.82754554338484,
            21.005601579226646
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82754554338484,
              21.00509274043167
            ],
            [
              105.82720246248364,
              21.00524700390214
            ],
            [
              105.82695723943652,
              21.00536628256135
            ],
            [
              105.82694491146047,
              21.005372279371283
            ],
            [
              105.82693340464425,
              21.005377876577185
            ],
            [
              105.82678359582258,
              21.005448570155266
            ],
            [
              105.82657781664368,
              21.005545675734282
            ],
            [
              105.82658027054711,
              21.00555059543592
            ],
            [
              105.82658752429947,
              21.005565138290404
            ],
            [
              105.8266007255947,
              21.005591606467878
            ],
            [
              105.8266056995468,
              21.005601579226646
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 95, Chùa Bộc",
          "maTaiSan": "04.O18.DOPP.941",
          "duongKinh": 100,
          "chieudaiQL": 119.6,
          "chatLieu": "G",
          "from_latitude": 21.00509274043167,
          "to_latitude": 21.005601579226646,
          "to_longitude": 105.8266056995468,
          "from_longitude": 105.82754554338484
        }
      },
      {
        "id": 940,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83029402561755,
            21.004170917279193,
            105.83071252168563,
            21.005387282458475
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83029402561755,
              21.004170917279193
            ],
            [
              105.83030383267342,
              21.00420323655955
            ],
            [
              105.8304346250602,
              21.004574381831162
            ],
            [
              105.83060229274655,
              21.005064826700693
            ],
            [
              105.83070520074816,
              21.005365884630873
            ],
            [
              105.83070526038563,
              21.005366058662464
            ],
            [
              105.83070850837484,
              21.00537522320164
            ],
            [
              105.83071023114582,
              21.005380099440856
            ],
            [
              105.83071217462096,
              21.00538627073255
            ],
            [
              105.83071222944918,
              21.005386444787625
            ],
            [
              105.83071252168563,
              21.005387282458475
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Tôn Thất Tùng",
          "maTaiSan": "04.O18.DOPP.942",
          "duongKinh": 100,
          "chieudaiQL": 142.89,
          "chatLieu": "G",
          "from_latitude": 21.004170917279193,
          "to_latitude": 21.005387282458475,
          "to_longitude": 105.83071252168563,
          "from_longitude": 105.83029402561755
        }
      },
      {
        "id": 941,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82668722793719,
            21.004612314956656,
            105.82736817848787,
            21.005372279371283
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82736817848787,
              21.004754108633097
            ],
            [
              105.82736764004052,
              21.00475309782993
            ],
            [
              105.82733864554038,
              21.004698494188208
            ],
            [
              105.82733620743605,
              21.0046934759688
            ],
            [
              105.82733453017073,
              21.004690028375546
            ],
            [
              105.82726369385749,
              21.00471820292029
            ],
            [
              105.82722331678899,
              21.00464023842273
            ],
            [
              105.82716872022712,
              21.004612314956656
            ],
            [
              105.8271103214526,
              21.004641119960105
            ],
            [
              105.82710782449827,
              21.004642351450723
            ],
            [
              105.82689704711721,
              21.004746316395707
            ],
            [
              105.82676334719804,
              21.004814893999576
            ],
            [
              105.82669649609691,
              21.004848198251267
            ],
            [
              105.82668791679909,
              21.004910292523693
            ],
            [
              105.82668722793719,
              21.00491532684422
            ],
            [
              105.8267579597138,
              21.00503855285923
            ],
            [
              105.82685520721671,
              21.005207990986815
            ],
            [
              105.8269282569048,
              21.005341777929633
            ],
            [
              105.82694491146047,
              21.005372279371283
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Thủy Lợi",
          "maTaiSan": "04.O18.DOPP.943",
          "duongKinh": 100,
          "chieudaiQL": 143.63,
          "chatLieu": "G",
          "from_latitude": 21.004754108633097,
          "to_latitude": 21.005372279371283,
          "to_longitude": 105.82694491146047,
          "from_longitude": 105.82736817848787
        }
      },
      {
        "id": 942,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82567331785552,
            21.00491532684422,
            105.82668722793719,
            21.005382369391427
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82567331785552,
              21.005382369391427
            ],
            [
              105.82581638694813,
              21.00531720019742
            ],
            [
              105.82582232504,
              21.005286343339158
            ],
            [
              105.82599554316998,
              21.00520549942734
            ],
            [
              105.82599773061541,
              21.005204478100172
            ],
            [
              105.82624998776272,
              21.005080896201992
            ],
            [
              105.82631536080362,
              21.005075566820487
            ],
            [
              105.82634709246045,
              21.0050749611464
            ],
            [
              105.82640962173319,
              21.005045615179583
            ],
            [
              105.82668722793719,
              21.00491532684422
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Thủy Lợi",
          "maTaiSan": "04.O18.DOPP.944",
          "duongKinh": 150,
          "chieudaiQL": 117.3,
          "chatLieu": "G",
          "from_latitude": 21.005382369391427,
          "to_latitude": 21.00491532684422,
          "to_longitude": 105.82668722793719,
          "from_longitude": 105.82567331785552
        }
      },
      {
        "id": 943,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82900400303156,
            21.00233526047487,
            105.82974028791921,
            21.00276827746028
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82974028791921,
              21.00233526047487
            ],
            [
              105.8297081783381,
              21.002344296616485
            ],
            [
              105.82965513434121,
              21.002359652603648
            ],
            [
              105.82965417019003,
              21.002359932787225
            ],
            [
              105.8295240085866,
              21.002388148998108
            ],
            [
              105.8295080119426,
              21.00239161676503
            ],
            [
              105.82947207304134,
              21.002399406103248
            ],
            [
              105.82900400303156,
              21.00251369465636
            ],
            [
              105.82900975864348,
              21.002553727446873
            ],
            [
              105.82901249442615,
              21.002572758661522
            ],
            [
              105.82903674127239,
              21.0027414076065
            ],
            [
              105.82903879076466,
              21.00275860770898
            ],
            [
              105.82903994357497,
              21.00276827746028
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Ngõ 22, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DOPP.945",
          "duongKinh": 150,
          "chieudaiQL": 107.37,
          "chatLieu": "G",
          "from_latitude": 21.00233526047487,
          "to_latitude": 21.00276827746028,
          "to_longitude": 105.82903994357497,
          "from_longitude": 105.82974028791921
        }
      },
      {
        "id": 944,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82746436621149,
            21.00286778248368,
            105.8280006860361,
            21.00289593379751
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8280006860361,
              21.00289593379751
            ],
            [
              105.82799412012861,
              21.002895883543612
            ],
            [
              105.82790511581123,
              21.00289520099349
            ],
            [
              105.82789976958931,
              21.002895160158314
            ],
            [
              105.827894876893,
              21.002895034020195
            ],
            [
              105.82789384275092,
              21.0028950074372
            ],
            [
              105.82778253250511,
              21.002892144537377
            ],
            [
              105.82772199719781,
              21.002890588211024
            ],
            [
              105.82767784462185,
              21.002889452588917
            ],
            [
              105.8276496255325,
              21.002888727223052
            ],
            [
              105.82746569249994,
              21.002867936810535
            ],
            [
              105.82746436621149,
              21.00286778248368
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Ngõ 22, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DOPP.946",
          "duongKinh": 150,
          "chieudaiQL": 55.44,
          "chatLieu": "G",
          "from_latitude": 21.00289593379751,
          "to_latitude": 21.00286778248368,
          "to_longitude": 105.82746436621149,
          "from_longitude": 105.8280006860361
        }
      },
      {
        "id": 945,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8265657418239,
            21.002580344804535,
            105.82746436621149,
            21.00286778248368
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82746436621149,
              21.00286778248368
            ],
            [
              105.82734539811479,
              21.002850513922393
            ],
            [
              105.82734536549722,
              21.002850529435815
            ],
            [
              105.82732810743188,
              21.002848005703513
            ],
            [
              105.82732807481432,
              21.002848021216916
            ],
            [
              105.8271939762602,
              21.00282856892569
            ],
            [
              105.82707599237864,
              21.00281150764958
            ],
            [
              105.82707595976105,
              21.002811523162947
            ],
            [
              105.82695335166653,
              21.00280028931156
            ],
            [
              105.82695331904891,
              21.002800304824888
            ],
            [
              105.82691459782792,
              21.002794929964633
            ],
            [
              105.82691456521029,
              21.00279494547795
            ],
            [
              105.82676443299435,
              21.002774165316037
            ],
            [
              105.8266921708183,
              21.00275497587527
            ],
            [
              105.82669213820063,
              21.002754991388557
            ],
            [
              105.82659578276606,
              21.002729383167786
            ],
            [
              105.82659377727573,
              21.002727108645082
            ],
            [
              105.82658203082596,
              21.00271343572988
            ],
            [
              105.82656989938174,
              21.002699367263343
            ],
            [
              105.82656585629347,
              21.00258523703097
            ],
            [
              105.82656580612381,
              21.002582938561115
            ],
            [
              105.82656578687488,
              21.002582237750197
            ],
            [
              105.8265657418239,
              21.002580344804535
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Ngõ 22, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DOPP.947",
          "duongKinh": 150,
          "chieudaiQL": 108.98,
          "chatLieu": "G",
          "from_latitude": 21.00286778248368,
          "to_latitude": 21.002580344804535,
          "to_longitude": 105.8265657418239,
          "from_longitude": 105.82746436621149
        }
      },
      {
        "id": 946,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82650107817263,
            21.00196964266153,
            105.8265657418239,
            21.002580344804535
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8265657418239,
              21.002580344804535
            ],
            [
              105.82656571235123,
              21.00257848610586
            ],
            [
              105.82656300517519,
              21.00248843936018
            ],
            [
              105.82655099482281,
              21.002394525008164
            ],
            [
              105.8265389403697,
              21.00229190014356
            ],
            [
              105.82653825556972,
              21.002285968361974
            ],
            [
              105.82650107817263,
              21.00196964266153
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Ngõ 22, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DOPP.948",
          "duongKinh": 150,
          "chieudaiQL": 67.74,
          "chatLieu": "G",
          "from_latitude": 21.002580344804535,
          "to_latitude": 21.00196964266153,
          "to_longitude": 105.82650107817263,
          "from_longitude": 105.8265657418239
        }
      },
      {
        "id": 947,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82896533198891,
            21.00186514723006,
            105.82946432682002,
            21.002147136733832
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82946432682002,
              21.00186514723006
            ],
            [
              105.82946196713299,
              21.00186631666546
            ],
            [
              105.8294283117847,
              21.00188298351968
            ],
            [
              105.82935547310481,
              21.001916279110315
            ],
            [
              105.82933703106136,
              21.00193829750679
            ],
            [
              105.82932911149857,
              21.001947754038973
            ],
            [
              105.82926090651344,
              21.00202919520189
            ],
            [
              105.82896533198891,
              21.002147136733832
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 54, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DOPP.949",
          "duongKinh": 100,
          "chieudaiQL": 59.34,
          "chatLieu": "G",
          "from_latitude": 21.00186514723006,
          "to_latitude": 21.002147136733832,
          "to_longitude": 105.82896533198891,
          "from_longitude": 105.82946432682002
        }
      },
      {
        "id": 948,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82605092410422,
            21.00252100362167,
            105.8265657418239,
            21.002605814154144
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82605092410422,
              21.00252100362167
            ],
            [
              105.82613957556937,
              21.002537477275368
            ],
            [
              105.82617548933229,
              21.002544126846836
            ],
            [
              105.82638099150542,
              21.002605814154144
            ],
            [
              105.82656163778974,
              21.002580904863663
            ],
            [
              105.8265657418239,
              21.002580344804535
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Ngõ 191, Khương Thượng",
          "maTaiSan": "04.O18.DOPP.950",
          "duongKinh": 150,
          "chieudaiQL": 54.88,
          "chatLieu": "G",
          "from_latitude": 21.00252100362167,
          "to_latitude": 21.002580344804535,
          "to_longitude": 105.8265657418239,
          "from_longitude": 105.82605092410422
        }
      },
      {
        "id": 949,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82799412012861,
            21.002895883543612,
            105.82818676528608,
            21.00397933965518
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82799412012861,
              21.002895883543612
            ],
            [
              105.82799479241336,
              21.00290406892952
            ],
            [
              105.82800910187602,
              21.00307806381195
            ],
            [
              105.82801225065887,
              21.003116360674206
            ],
            [
              105.82801567814437,
              21.00316106547917
            ],
            [
              105.82804487098376,
              21.00330167151843
            ],
            [
              105.82806810309414,
              21.0033990148797
            ],
            [
              105.82808368153708,
              21.003499638056702
            ],
            [
              105.8280855072445,
              21.003519007005373
            ],
            [
              105.828098362873,
              21.003655402180087
            ],
            [
              105.82809870626227,
              21.00365819238386
            ],
            [
              105.82810756695402,
              21.003734728466018
            ],
            [
              105.82818540123866,
              21.00374526887185
            ],
            [
              105.82818598229724,
              21.00381152496376
            ],
            [
              105.82818621320112,
              21.00383785651725
            ],
            [
              105.82818676528608,
              21.003900778938945
            ],
            [
              105.82818614530503,
              21.00393013953922
            ],
            [
              105.82818438663361,
              21.003960225551864
            ],
            [
              105.82818326957825,
              21.00397933965518
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 22, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DOPP.951",
          "duongKinh": 100,
          "chieudaiQL": 127.19,
          "chatLieu": "G",
          "from_latitude": 21.002895883543612,
          "to_latitude": 21.00397933965518,
          "to_longitude": 105.82818326957825,
          "from_longitude": 105.82799412012861
        }
      },
      {
        "id": 950,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82818676528608,
            21.003889737782952,
            105.82842296070137,
            21.004462295543362
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82839475477188,
              21.004462295543362
            ],
            [
              105.82839670704514,
              21.00444986576972
            ],
            [
              105.82840153240453,
              21.004419137089
            ],
            [
              105.82840996913986,
              21.004359229250277
            ],
            [
              105.8284199062576,
              21.00428866958027
            ],
            [
              105.82842296070137,
              21.004266981786216
            ],
            [
              105.82840640613186,
              21.004070428074172
            ],
            [
              105.82840602709432,
              21.00406030926229
            ],
            [
              105.82840465672777,
              21.004023765954734
            ],
            [
              105.8283973243002,
              21.00390105582314
            ],
            [
              105.8283967068362,
              21.003889737782952
            ],
            [
              105.82829847618476,
              21.003895757141944
            ],
            [
              105.82819114548563,
              21.00390058239974
            ],
            [
              105.82818676528608,
              21.003900778938945
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 10, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DOPP.952",
          "duongKinh": 100,
          "chieudaiQL": 86.55,
          "chatLieu": "G",
          "from_latitude": 21.004462295543362,
          "to_latitude": 21.003900778938945,
          "to_longitude": 105.82818676528608,
          "from_longitude": 105.82839475477188
        }
      },
      {
        "id": 951,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8280855072445,
            21.003483953915072,
            105.82889469775328,
            21.003519007005373
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82889469775328,
              21.003483953915072
            ],
            [
              105.8288093815023,
              21.003492966285606
            ],
            [
              105.82861474490035,
              21.003505262074093
            ],
            [
              105.82858018483964,
              21.003507100383317
            ],
            [
              105.82857598851734,
              21.00350732403711
            ],
            [
              105.82851556681095,
              21.0035105355821
            ],
            [
              105.82834524918783,
              21.00351504790215
            ],
            [
              105.82832005177677,
              21.003515127172275
            ],
            [
              105.82831757695232,
              21.003515134699867
            ],
            [
              105.828315449365,
              21.003515143247277
            ],
            [
              105.82830557218934,
              21.00351517324944
            ],
            [
              105.82826008467482,
              21.00351531331398
            ],
            [
              105.82817857231949,
              21.003515574461446
            ],
            [
              105.82808573131204,
              21.00351899868943
            ],
            [
              105.8280855072445,
              21.003519007005373
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 22, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DOPP.953",
          "duongKinh": 100,
          "chieudaiQL": 84.68,
          "chatLieu": "G",
          "from_latitude": 21.003483953915072,
          "to_latitude": 21.003519007005373,
          "to_longitude": 105.8280855072445,
          "from_longitude": 105.82889469775328
        }
      },
      {
        "id": 952,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82637775680351,
            21.003365394202092,
            105.82653455639607,
            21.00378398957635
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82652957753697,
              21.003365394202092
            ],
            [
              105.82653455639607,
              21.003366841382086
            ],
            [
              105.8265306173536,
              21.003381995888603
            ],
            [
              105.82652886447545,
              21.00338874066914
            ],
            [
              105.82651524546752,
              21.003441134367915
            ],
            [
              105.82649514519025,
              21.00351846151168
            ],
            [
              105.82647834534556,
              21.003583093123485
            ],
            [
              105.82641354150839,
              21.003709497395022
            ],
            [
              105.82641182284831,
              21.003712850390034
            ],
            [
              105.82640181824252,
              21.003732364415015
            ],
            [
              105.82638701690371,
              21.003764120586247
            ],
            [
              105.82637775680351,
              21.00378398957635
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 22, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DOPP.954",
          "duongKinh": 100,
          "chieudaiQL": 49.77,
          "chatLieu": "G",
          "from_latitude": 21.003365394202092,
          "to_latitude": 21.00378398957635,
          "to_longitude": 105.82637775680351,
          "from_longitude": 105.82652957753697
        }
      },
      {
        "id": 953,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82947172875491,
            21.00188247027836,
            105.82965513434121,
            21.002359652603648
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82965513434121,
              21.002359652603648
            ],
            [
              105.8296502315084,
              21.002346900357505
            ],
            [
              105.82948904185264,
              21.001927515642304
            ],
            [
              105.82947172875491,
              21.00188247027836
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D200_Tôn Thất Tùng",
          "maTaiSan": "04.O18.DOPP.955",
          "duongKinh": 200,
          "chieudaiQL": 54.82,
          "chatLieu": "G",
          "from_latitude": 21.002359652603648,
          "to_latitude": 21.00188247027836,
          "to_longitude": 105.82947172875491,
          "from_longitude": 105.82965513434121
        }
      },
      {
        "id": 954,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82862355129096,
            21.004170917279193,
            105.83029402561755,
            21.004701722834838
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83029402561755,
              21.004170917279193
            ],
            [
              105.83022293414025,
              21.004188111045224
            ],
            [
              105.83021628501028,
              21.004189718705295
            ],
            [
              105.82956867241663,
              21.004346324643734
            ],
            [
              105.82956658900294,
              21.004346844218066
            ],
            [
              105.82953318031491,
              21.004355156861806
            ],
            [
              105.8294405277321,
              21.004228808933977
            ],
            [
              105.82924002997302,
              21.00431533138631
            ],
            [
              105.8291195531119,
              21.0043673227507
            ],
            [
              105.82897841834664,
              21.004428226527864
            ],
            [
              105.82871609382399,
              21.00452601195829
            ],
            [
              105.82867468046162,
              21.004542699290067
            ],
            [
              105.82867529154085,
              21.00454571399539
            ],
            [
              105.8286977170693,
              21.00465538840522
            ],
            [
              105.82869945189374,
              21.00466960306972
            ],
            [
              105.8286884211015,
              21.004676205171425
            ],
            [
              105.82862355129096,
              21.004701722834838
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 10, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DOPP.956",
          "duongKinh": 100,
          "chieudaiQL": 208.12,
          "chatLieu": "G",
          "from_latitude": 21.004170917279193,
          "to_latitude": 21.004701722834838,
          "to_longitude": 105.82862355129096,
          "from_longitude": 105.83029402561755
        }
      },
      {
        "id": 955,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82694226466269,
            21.002800304824888,
            105.82695331904891,
            21.002942280815375
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82695331904891,
              21.002800304824888
            ],
            [
              105.8269532561347,
              21.002801106293084
            ],
            [
              105.82694226466269,
              21.002942280815375
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 22, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DOPP.957",
          "duongKinh": 100,
          "chieudaiQL": 17.18,
          "chatLieu": "G",
          "from_latitude": 21.002800304824888,
          "to_latitude": 21.002942280815375,
          "to_longitude": 105.82694226466269,
          "from_longitude": 105.82695331904891
        }
      },
      {
        "id": 956,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82652957753697,
            21.00291366893316,
            105.82694226466269,
            21.003365394202092
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82694226466269,
              21.002942280815375
            ],
            [
              105.82693778495826,
              21.002942219482147
            ],
            [
              105.82690813535957,
              21.002941105329768
            ],
            [
              105.8268264894144,
              21.002929809677493
            ],
            [
              105.82671177125027,
              21.00291366893316
            ],
            [
              105.82668434549683,
              21.002927927706985
            ],
            [
              105.82665994048581,
              21.002978256637707
            ],
            [
              105.82661240199496,
              21.00307629527769
            ],
            [
              105.82661192477572,
              21.00307784211384
            ],
            [
              105.82659435349088,
              21.003134766633018
            ],
            [
              105.8265756819279,
              21.003202887479002
            ],
            [
              105.82656152125736,
              21.003254552198033
            ],
            [
              105.82655517452196,
              21.003277711054963
            ],
            [
              105.82654573123793,
              21.00331216445029
            ],
            [
              105.82654243229175,
              21.00332303545492
            ],
            [
              105.82654168785112,
              21.003325487718946
            ],
            [
              105.82652957753697,
              21.003365394202092
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 22, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DOPP.958",
          "duongKinh": 100,
          "chieudaiQL": 78.51,
          "chatLieu": "G",
          "from_latitude": 21.002942280815375,
          "to_latitude": 21.003365394202092,
          "to_longitude": 105.82652957753697,
          "from_longitude": 105.82694226466269
        }
      },
      {
        "id": 957,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82746569249994,
            21.002867936810535,
            105.82751670606807,
            21.003785691957347
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82746569249994,
              21.002867936810535
            ],
            [
              105.82746582629653,
              21.00286952493661
            ],
            [
              105.82748089181725,
              21.003049570537364
            ],
            [
              105.82750065233829,
              21.003216728643483
            ],
            [
              105.82751649740246,
              21.00330978245001
            ],
            [
              105.82751656712671,
              21.0033754638798
            ],
            [
              105.82751670606807,
              21.003486148260343
            ],
            [
              105.82751536611443,
              21.00351177842299
            ],
            [
              105.82751517652792,
              21.003515423865842
            ],
            [
              105.82750740289661,
              21.003665130895705
            ],
            [
              105.82750738722191,
              21.0036654281336
            ],
            [
              105.82750662143147,
              21.00367482180489
            ],
            [
              105.8275059065756,
              21.00368368413105
            ],
            [
              105.82750012223309,
              21.003755439317587
            ],
            [
              105.82749682573169,
              21.00377878027886
            ],
            [
              105.82749658820048,
              21.003780533694094
            ],
            [
              105.82749585869288,
              21.003785691957347
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 22, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DOPP.959",
          "duongKinh": 100,
          "chieudaiQL": 101.53,
          "chatLieu": "G",
          "from_latitude": 21.002867936810535,
          "to_latitude": 21.003785691957347,
          "to_longitude": 105.82749585869288,
          "from_longitude": 105.82746569249994
        }
      },
      {
        "id": 958,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82889469775328,
            21.003483953915072,
            105.8289502052704,
            21.003729081278536
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82889469775328,
              21.003483953915072
            ],
            [
              105.82889489673661,
              21.003484628559715
            ],
            [
              105.8289230965481,
              21.003592260637134
            ],
            [
              105.8289502052704,
              21.003729081278536
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 10, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DOPP.960",
          "duongKinh": 100,
          "chieudaiQL": 27.14,
          "chatLieu": "G",
          "from_latitude": 21.003483953915072,
          "to_latitude": 21.003729081278536,
          "to_longitude": 105.8289502052704,
          "from_longitude": 105.82889469775328
        }
      },
      {
        "id": 959,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8288920423214,
            21.00371368067707,
            105.82906125602975,
            21.003867513975592
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8289502052704,
              21.003729081278536
            ],
            [
              105.82896550655772,
              21.003726294366878
            ],
            [
              105.82902850280091,
              21.003714819143724
            ],
            [
              105.8290367444725,
              21.00371368067707
            ],
            [
              105.8290526212983,
              21.003794714010457
            ],
            [
              105.82906125602975,
              21.003838786449425
            ],
            [
              105.82903791428794,
              21.003842748792625
            ],
            [
              105.8288920423214,
              21.003867513975592
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 10, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DOPP.961",
          "duongKinh": 100,
          "chieudaiQL": 41.58,
          "chatLieu": "G",
          "from_latitude": 21.003729081278536,
          "to_latitude": 21.003867513975592,
          "to_longitude": 105.8288920423214,
          "from_longitude": 105.8289502052704
        }
      },
      {
        "id": 960,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82956658900294,
            21.004346844218066,
            105.82993831941499,
            21.005111355567095
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82993831941499,
              21.005111355567095
            ],
            [
              105.82992922002443,
              21.00509255231156
            ],
            [
              105.82988505539996,
              21.005001511982446
            ],
            [
              105.8298447124135,
              21.00491831378646
            ],
            [
              105.82975931910863,
              21.004737287264707
            ],
            [
              105.82975920912716,
              21.004737053865224
            ],
            [
              105.82960722010328,
              21.004423406890517
            ],
            [
              105.82956658900294,
              21.004346844218066
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 10, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DOPP.962",
          "duongKinh": 100,
          "chieudaiQL": 91.96,
          "chatLieu": "G",
          "from_latitude": 21.005111355567095,
          "to_latitude": 21.004346844218066,
          "to_longitude": 105.82956658900294,
          "from_longitude": 105.82993831941499
        }
      },
      {
        "id": 961,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8280006860361,
            21.00276827746028,
            105.82903994357497,
            21.00289593379751
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82903994357497,
              21.00276827746028
            ],
            [
              105.82891801950589,
              21.00278512301977
            ],
            [
              105.82880894983973,
              21.00279834628479
            ],
            [
              105.82871841616607,
              21.002809322329266
            ],
            [
              105.82870235136393,
              21.00281090983549
            ],
            [
              105.8286481322549,
              21.002816260772686
            ],
            [
              105.82859579349312,
              21.002821423700304
            ],
            [
              105.82859277708678,
              21.002821941481525
            ],
            [
              105.82847998807345,
              21.00284130200544
            ],
            [
              105.82845965158705,
              21.00284479260544
            ],
            [
              105.82844792132715,
              21.00284680608805
            ],
            [
              105.82836587947058,
              21.002863296364605
            ],
            [
              105.82827945926941,
              21.002880665973414
            ],
            [
              105.82819245137647,
              21.00288322276597
            ],
            [
              105.8281594275481,
              21.002884191832642
            ],
            [
              105.82800877817209,
              21.00288861803616
            ],
            [
              105.8280006860361,
              21.00289593379751
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Ngõ 22, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DOPP.963",
          "duongKinh": 150,
          "chieudaiQL": 110.62,
          "chatLieu": "G",
          "from_latitude": 21.00276827746028,
          "to_latitude": 21.00289593379751,
          "to_longitude": 105.8280006860361,
          "from_longitude": 105.82903994357497
        }
      },
      {
        "id": 962,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82887397331183,
            21.003867513975592,
            105.8289614536161,
            21.004378366136894
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8288920423214,
              21.003867513975592
            ],
            [
              105.82891116228934,
              21.00398142704717
            ],
            [
              105.82887397331183,
              21.00398674119334
            ],
            [
              105.82888499153587,
              21.0040642593597
            ],
            [
              105.82889177761804,
              21.004112004297326
            ],
            [
              105.82890839268752,
              21.004218384911066
            ],
            [
              105.82891684131576,
              21.00427247685292
            ],
            [
              105.82892385919409,
              21.004298968637144
            ],
            [
              105.82893598936595,
              21.004324585586403
            ],
            [
              105.82895527367867,
              21.004365313900056
            ],
            [
              105.8289614536161,
              21.004378366136894
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 10, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DOPP.964",
          "duongKinh": 100,
          "chieudaiQL": 61.27,
          "chatLieu": "G",
          "from_latitude": 21.003867513975592,
          "to_latitude": 21.004378366136894,
          "to_longitude": 105.8289614536161,
          "from_longitude": 105.8288920423214
        }
      },
      {
        "id": 963,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82705113581537,
            21.003757571222856,
            105.82749585869288,
            21.00440809951313
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82749585869288,
              21.003785691957347
            ],
            [
              105.8274623492646,
              21.00377974543945
            ],
            [
              105.8273637140201,
              21.003762269676514
            ],
            [
              105.82733931565159,
              21.00375794710602
            ],
            [
              105.827338562744,
              21.003757814378453
            ],
            [
              105.82733711476347,
              21.003757571222856
            ],
            [
              105.82732983844981,
              21.003776796553094
            ],
            [
              105.82729669623045,
              21.003864166023426
            ],
            [
              105.82728433794455,
              21.00386062582804
            ],
            [
              105.82725486056046,
              21.003942094759967
            ],
            [
              105.82723737663862,
              21.003995295809364
            ],
            [
              105.82723675777228,
              21.003997145013273
            ],
            [
              105.82721457473384,
              21.004061442462618
            ],
            [
              105.8271976173876,
              21.004106874099516
            ],
            [
              105.8271229460204,
              21.00424756053404
            ],
            [
              105.8271146714173,
              21.00426315250824
            ],
            [
              105.82706772129603,
              21.004370227901028
            ],
            [
              105.82705113581537,
              21.00440809951313
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 22, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DOPP.965",
          "duongKinh": 100,
          "chieudaiQL": 96.07,
          "chatLieu": "G",
          "from_latitude": 21.003785691957347,
          "to_latitude": 21.00440809951313,
          "to_longitude": 105.82705113581537,
          "from_longitude": 105.82749585869288
        }
      },
      {
        "id": 964,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82346207341251,
            21.007243097600146,
            105.8239398163837,
            21.007604714264062
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82346207341251,
              21.007604714264062
            ],
            [
              105.82346340902784,
              21.007604107155235
            ],
            [
              105.82381746325196,
              21.007443076068963
            ],
            [
              105.8239398163837,
              21.0073867148775
            ],
            [
              105.82386996256945,
              21.007243097600146
            ],
            [
              105.8235658558984,
              21.007376638519418
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D160_Tây Sơn",
          "maTaiSan": "04.O18.DOPP.966",
          "duongKinh": 160,
          "chieudaiQL": 104.68,
          "chatLieu": "PVC",
          "from_latitude": 21.007604714264062,
          "to_latitude": 21.007376638519418,
          "to_longitude": 105.8235658558984,
          "from_longitude": 105.82346207341251
        }
      },
      {
        "id": 965,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82326104761405,
            21.00503727610689,
            105.82600986871813,
            21.00661374536544
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82600986871813,
              21.00503727610689
            ],
            [
              105.82592980912683,
              21.005072537331298
            ],
            [
              105.82582335816349,
              21.005119421947434
            ],
            [
              105.82572123468358,
              21.005164400468598
            ],
            [
              105.82561031593482,
              21.00521325218162
            ],
            [
              105.82561029099142,
              21.005213264044585
            ],
            [
              105.82556221194459,
              21.005234439490053
            ],
            [
              105.82547671094976,
              21.005270771934406
            ],
            [
              105.82546740882051,
              21.00527481834713
            ],
            [
              105.8254549679137,
              21.00528022940391
            ],
            [
              105.82528960177899,
              21.005353315721003
            ],
            [
              105.8252056954165,
              21.00539156959337
            ],
            [
              105.82514885367773,
              21.005417484192705
            ],
            [
              105.82470116922913,
              21.005621585898638
            ],
            [
              105.82440561022041,
              21.005761478950905
            ],
            [
              105.82381364038982,
              21.00601886854165
            ],
            [
              105.82363176854774,
              21.006118915036858
            ],
            [
              105.82353555473232,
              21.006172535565824
            ],
            [
              105.82350543713312,
              21.006189319651536
            ],
            [
              105.82340621503431,
              21.006321404486435
            ],
            [
              105.82334480187255,
              21.006440957432286
            ],
            [
              105.82333288171408,
              21.006464175657662
            ],
            [
              105.82328799694615,
              21.006551561467923
            ],
            [
              105.82326104761405,
              21.00661374536544
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Khương Thượng",
          "maTaiSan": "04.O18.DOPP.967",
          "duongKinh": 150,
          "chieudaiQL": 359,
          "chatLieu": "G",
          "from_latitude": 21.00503727610689,
          "to_latitude": 21.00661374536544,
          "to_longitude": 105.82326104761405,
          "from_longitude": 105.82600986871813
        }
      },
      {
        "id": 966,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8221152593502,
            21.00283261248194,
            105.82287244600828,
            21.00410483252345
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8221152593502,
              21.00283261248194
            ],
            [
              105.82211608532191,
              21.00283414488344
            ],
            [
              105.82213289640423,
              21.00286553215097
            ],
            [
              105.8221373442646,
              21.00287400372076
            ],
            [
              105.82220818103049,
              21.002973883740626
            ],
            [
              105.82220828779685,
              21.002974059354358
            ],
            [
              105.82224629156913,
              21.00303056145905
            ],
            [
              105.82224639349673,
              21.003030731676755
            ],
            [
              105.82227804968936,
              21.00308331552379
            ],
            [
              105.82227817655043,
              21.003083472072603
            ],
            [
              105.82228433599086,
              21.00309026439767
            ],
            [
              105.82228443119561,
              21.00309043645428
            ],
            [
              105.82231280138804,
              21.00314138235787
            ],
            [
              105.82232683745362,
              21.00316291434076
            ],
            [
              105.82233529983633,
              21.003175896197654
            ],
            [
              105.82240104521648,
              21.00328199686761
            ],
            [
              105.82240114907302,
              21.003282167979098
            ],
            [
              105.82240845360602,
              21.003293960455636
            ],
            [
              105.82240855357566,
              21.003294124360096
            ],
            [
              105.82241457474979,
              21.003303942258285
            ],
            [
              105.82241466803113,
              21.003304114324106
            ],
            [
              105.82248402113292,
              21.003431744027285
            ],
            [
              105.82248408947599,
              21.003431928858763
            ],
            [
              105.82248566597688,
              21.00343526589373
            ],
            [
              105.82248576310084,
              21.003435437037723
            ],
            [
              105.82263034223614,
              21.003690241634104
            ],
            [
              105.82263043936041,
              21.003690412778
            ],
            [
              105.82264063936698,
              21.003708389198316
            ],
            [
              105.82264073745309,
              21.00370856033754
            ],
            [
              105.82277408984156,
              21.003939954094708
            ],
            [
              105.82277418792799,
              21.00394012523385
            ],
            [
              105.82278335705732,
              21.00395603371959
            ],
            [
              105.82279293485338,
              21.00397507713937
            ],
            [
              105.82279304348542,
              21.003975241904907
            ],
            [
              105.82279903187701,
              21.003984333750573
            ],
            [
              105.82279912894225,
              21.003984494055896
            ],
            [
              105.82286532167265,
              21.00409311416681
            ],
            [
              105.82287192482467,
              21.004104157622322
            ],
            [
              105.82287244600828,
              21.00410483252345
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Ngõ 592, Trường Chinh",
          "maTaiSan": "04.O18.DOPP.968",
          "duongKinh": 150,
          "chieudaiQL": 158.66,
          "chatLieu": "G",
          "from_latitude": 21.00283261248194,
          "to_latitude": 21.00410483252345,
          "to_longitude": 105.82287244600828,
          "from_longitude": 105.8221152593502
        }
      },
      {
        "id": 967,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82319730533465,
            21.00395613217492,
            105.8237870975663,
            21.0048520361481
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82319730533465,
              21.00395613217492
            ],
            [
              105.82331441283685,
              21.004178747892233
            ],
            [
              105.82332755138495,
              21.004203724518966
            ],
            [
              105.82333401326254,
              21.004216711481448
            ],
            [
              105.82343209892112,
              21.004413836997823
            ],
            [
              105.82343748654645,
              21.004424394051014
            ],
            [
              105.82346544274293,
              21.004485667437724
            ],
            [
              105.82350969071632,
              21.00456983920036
            ],
            [
              105.82352196330449,
              21.00458949366575
            ],
            [
              105.82366873422322,
              21.004742933688537
            ],
            [
              105.8237870975663,
              21.0048520361481
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 554, Trường Chinh",
          "maTaiSan": "04.O18.DOPP.969",
          "duongKinh": 100,
          "chieudaiQL": 118.8,
          "chatLieu": "G",
          "from_latitude": 21.00395613217492,
          "to_latitude": 21.0048520361481,
          "to_longitude": 105.8237870975663,
          "from_longitude": 105.82319730533465
        }
      },
      {
        "id": 968,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83071252168563,
            21.005387282458475,
            105.83102697769888,
            21.006159902522867
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83071252168563,
              21.005387282458475
            ],
            [
              105.8308159017128,
              21.005682998120452
            ],
            [
              105.83081648128378,
              21.00568465632433
            ],
            [
              105.83082624885637,
              21.005712583366424
            ],
            [
              105.83083461695159,
              21.00573200523543
            ],
            [
              105.83083563391584,
              21.00573292156169
            ],
            [
              105.83083574218783,
              21.0057330194848
            ],
            [
              105.83090480702911,
              21.00579510789495
            ],
            [
              105.83102697769888,
              21.006159902522867
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Tôn Thất Tùng",
          "maTaiSan": "04.O18.DOPP.970",
          "duongKinh": 150,
          "chieudaiQL": 96.35,
          "chatLieu": "G",
          "from_latitude": 21.005387282458475,
          "to_latitude": 21.006159902522867,
          "to_longitude": 105.83102697769888,
          "from_longitude": 105.83071252168563
        }
      },
      {
        "id": 969,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82082125226579,
            21.002356108321525,
            105.82402146619297,
            21.003293644658555
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82402146619297,
              21.002356108321525
            ],
            [
              105.82400800748144,
              21.002360054644605
            ],
            [
              105.82333414498662,
              21.002531120728
            ],
            [
              105.82275340252853,
              21.002704039137484
            ],
            [
              105.82274702011254,
              21.002706228702415
            ],
            [
              105.8227379626097,
              21.002708212616152
            ],
            [
              105.82237521147822,
              21.00278850218219
            ],
            [
              105.82236166432085,
              21.002791945706505
            ],
            [
              105.82217573679203,
              21.002839762969344
            ],
            [
              105.8221152593502,
              21.00283261248194
            ],
            [
              105.82211521322685,
              21.00283262083375
            ],
            [
              105.82211517387091,
              21.002832635475485
            ],
            [
              105.8218830172003,
              21.002897099094646
            ],
            [
              105.8218503041552,
              21.002906182748188
            ],
            [
              105.82166167535982,
              21.002958559350223
            ],
            [
              105.82153002022781,
              21.00299776719101
            ],
            [
              105.82152311668203,
              21.002999822838596
            ],
            [
              105.82133526415258,
              21.00305576760545
            ],
            [
              105.82133435341729,
              21.003055969807043
            ],
            [
              105.82133226897666,
              21.003062632140473
            ],
            [
              105.82133105140306,
              21.003066524606393
            ],
            [
              105.82133020700451,
              21.003066721068503
            ],
            [
              105.82100913869722,
              21.00314339964823
            ],
            [
              105.82082125226579,
              21.003293644658555
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Trường Chinh",
          "maTaiSan": "04.O18.DOPP.971",
          "duongKinh": 150,
          "chieudaiQL": 350.75,
          "chatLieu": "G",
          "from_latitude": 21.002356108321525,
          "to_latitude": 21.003293644658555,
          "to_longitude": 105.82082125226579,
          "from_longitude": 105.82402146619297
        }
      },
      {
        "id": 970,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82408430512912,
            21.002235087424413,
            105.82494621555983,
            21.002475955975573
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82408430512912,
              21.002475955975573
            ],
            [
              105.82409705081055,
              21.002471253483854
            ],
            [
              105.82426716012974,
              21.0024084837505
            ],
            [
              105.82431012552037,
              21.002397512824825
            ],
            [
              105.82443302904869,
              21.002366129270047
            ],
            [
              105.82494621555983,
              21.002235087424413
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 199, Khương Thượng",
          "maTaiSan": "04.O18.DOPP.972",
          "duongKinh": 100,
          "chieudaiQL": 93.64,
          "chatLieu": "G",
          "from_latitude": 21.002475955975573,
          "to_latitude": 21.002235087424413,
          "to_longitude": 105.82494621555983,
          "from_longitude": 105.82408430512912
        }
      },
      {
        "id": 971,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82322199615821,
            21.006602571487,
            105.8235658558984,
            21.007376638519418
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82325508325167,
              21.006602571487
            ],
            [
              105.82325736034524,
              21.00660683815716
            ],
            [
              105.82326104761405,
              21.00661374536544
            ],
            [
              105.82327115312589,
              21.006632679612416
            ],
            [
              105.82322199615821,
              21.00674576753826
            ],
            [
              105.8235658558984,
              21.007376638519418
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D160_Tây Sơn",
          "maTaiSan": "04.O18.DOPP.973",
          "duongKinh": 160,
          "chieudaiQL": 88.93,
          "chatLieu": "PVC",
          "from_latitude": 21.006602571487,
          "to_latitude": 21.007376638519418,
          "to_longitude": 105.8235658558984,
          "from_longitude": 105.82325508325167
        }
      },
      {
        "id": 972,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82520415038745,
            21.002456716360175,
            105.82524166666212,
            21.003044705162793
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82520415038745,
              21.003044705162793
            ],
            [
              105.82520427919472,
              21.003040841434373
            ],
            [
              105.82520470703918,
              21.003028037013713
            ],
            [
              105.82520474668712,
              21.00302685088381
            ],
            [
              105.82520489783884,
              21.003022327690974
            ],
            [
              105.82520497957323,
              21.00301987412886
            ],
            [
              105.82520520172827,
              21.003013219878127
            ],
            [
              105.8252097546663,
              21.002876844775656
            ],
            [
              105.825210273143,
              21.002870028313744
            ],
            [
              105.82521396671056,
              21.00282139960576
            ],
            [
              105.82522361325613,
              21.002694404210903
            ],
            [
              105.82522563044701,
              21.002667844961483
            ],
            [
              105.82523829511489,
              21.002501107371916
            ],
            [
              105.82524166666212,
              21.002456716360175
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 191, Khương Thượng",
          "maTaiSan": "04.O18.DOPP.974",
          "duongKinh": 100,
          "chieudaiQL": 65.23,
          "chatLieu": "G",
          "from_latitude": 21.003044705162793,
          "to_latitude": 21.002456716360175,
          "to_longitude": 105.82524166666212,
          "from_longitude": 105.82520415038745
        }
      },
      {
        "id": 973,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82547705659651,
            21.00798130370474,
            105.82712288302848,
            21.008751763875956
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82712288302848,
              21.00798130370474
            ],
            [
              105.82668706402617,
              21.008182682204396
            ],
            [
              105.82650283499672,
              21.008265963971674
            ],
            [
              105.8264823601859,
              21.00827521942006
            ],
            [
              105.82622613398564,
              21.00839635070886
            ],
            [
              105.82602423548559,
              21.00848907879945
            ],
            [
              105.82602041714787,
              21.00849071952873
            ],
            [
              105.82580232038806,
              21.008591712834527
            ],
            [
              105.82578595165982,
              21.008599892403616
            ],
            [
              105.82576537266094,
              21.00861015808107
            ],
            [
              105.8256890529801,
              21.008648230895048
            ],
            [
              105.82552465116933,
              21.008728519681995
            ],
            [
              105.82551339743048,
              21.008734016215822
            ],
            [
              105.82547705659651,
              21.008751763875956
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Chùa Bộc",
          "maTaiSan": "04.O18.DOPP.975",
          "duongKinh": 150,
          "chieudaiQL": 191.58,
          "chatLieu": "G",
          "from_latitude": 21.00798130370474,
          "to_latitude": 21.008751763875956,
          "to_longitude": 105.82547705659651,
          "from_longitude": 105.82712288302848
        }
      },
      {
        "id": 974,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82419400697455,
            21.00245647760855,
            105.82524166666212,
            21.002689041793506
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82524166666212,
              21.002456716360175
            ],
            [
              105.82522030353717,
              21.00245647760855
            ],
            [
              105.82500761321099,
              21.002489656292692
            ],
            [
              105.82473258742027,
              21.002552859266068
            ],
            [
              105.82469718008824,
              21.002560995480543
            ],
            [
              105.82460651844545,
              21.002584085857656
            ],
            [
              105.82430569397069,
              21.002660701415845
            ],
            [
              105.82421128914326,
              21.002683004033706
            ],
            [
              105.82419400697455,
              21.002689041793506
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Ngõ 191, Khương Thượng",
          "maTaiSan": "04.O18.DOPP.976",
          "duongKinh": 150,
          "chieudaiQL": 111.86,
          "chatLieu": "G",
          "from_latitude": 21.002456716360175,
          "to_latitude": 21.002689041793506,
          "to_longitude": 105.82419400697455,
          "from_longitude": 105.82524166666212
        }
      },
      {
        "id": 975,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82650107817263,
            21.001541328266278,
            105.82931758704298,
            21.00196964266153
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82650107817263,
              21.00196964266153
            ],
            [
              105.82650643797257,
              21.001968836248384
            ],
            [
              105.82652126992306,
              21.001966604613774
            ],
            [
              105.8266505076049,
              21.001947146549448
            ],
            [
              105.82676055489465,
              21.001917821709508
            ],
            [
              105.82676832443937,
              21.00191550061367
            ],
            [
              105.82682693866039,
              21.00189944285077
            ],
            [
              105.82697379566048,
              21.001902552802264
            ],
            [
              105.82698353962658,
              21.001958146974367
            ],
            [
              105.82713860054083,
              21.00195728517587
            ],
            [
              105.8271734320341,
              21.001957286626855
            ],
            [
              105.82745876283356,
              21.001911717569165
            ],
            [
              105.82751505384606,
              21.00190538326701
            ],
            [
              105.82761775039795,
              21.001902562686393
            ],
            [
              105.82771464853484,
              21.001895312813474
            ],
            [
              105.82778162588707,
              21.001888144264754
            ],
            [
              105.82812480197865,
              21.00184170659187
            ],
            [
              105.82820218431364,
              21.001827518860804
            ],
            [
              105.8283222605276,
              21.001801357907713
            ],
            [
              105.82832251703533,
              21.001801302465893
            ],
            [
              105.82847162930155,
              21.001774582820328
            ],
            [
              105.82857508923468,
              21.001756043605408
            ],
            [
              105.82864094156045,
              21.0017430327057
            ],
            [
              105.82865273728132,
              21.001732856430653
            ],
            [
              105.82877627168108,
              21.00170434800074
            ],
            [
              105.82883534809092,
              21.001692967826116
            ],
            [
              105.82901943178844,
              21.001657505796114
            ],
            [
              105.82918596934802,
              21.001592614294168
            ],
            [
              105.8291923202261,
              21.001590140128588
            ],
            [
              105.82931513000493,
              21.001542285915534
            ],
            [
              105.82931758704298,
              21.001541328266278
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Ngõ 72, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DOPP.977",
          "duongKinh": 150,
          "chieudaiQL": 303.4,
          "chatLieu": "G",
          "from_latitude": 21.00196964266153,
          "to_latitude": 21.001541328266278,
          "to_longitude": 105.82931758704298,
          "from_longitude": 105.82650107817263
        }
      },
      {
        "id": 976,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82663518385012,
            21.006195866268246,
            105.82814707556498,
            21.006894642897358
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82663518385012,
              21.006894642897358
            ],
            [
              105.82663731067831,
              21.006893614630187
            ],
            [
              105.82679445258253,
              21.006817707325403
            ],
            [
              105.82695616551409,
              21.006739590956254
            ],
            [
              105.8270924894118,
              21.006676478488288
            ],
            [
              105.82734991453651,
              21.00655730285699
            ],
            [
              105.82739677793298,
              21.00653560713681
            ],
            [
              105.827746713556,
              21.00637360084067
            ],
            [
              105.82814552304741,
              21.00619655485567
            ],
            [
              105.82814707556498,
              21.006195866268246
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngách 95/8, Chùa Bộc",
          "maTaiSan": "04.O18.DOPP.978",
          "duongKinh": 100,
          "chieudaiQL": 173.28,
          "chatLieu": "G",
          "from_latitude": 21.006894642897358,
          "to_latitude": 21.006195866268246,
          "to_longitude": 105.82814707556498,
          "from_longitude": 105.82663518385012
        }
      },
      {
        "id": 977,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82814707556498,
            21.006195866268246,
            105.82869347752903,
            21.007259931568637
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82814707556498,
              21.006195866268246
            ],
            [
              105.82829430653976,
              21.006510820258004
            ],
            [
              105.82842995467433,
              21.00676539648923
            ],
            [
              105.82853111837098,
              21.006955255396342
            ],
            [
              105.82861025043206,
              21.007109933347365
            ],
            [
              105.8286808082206,
              21.00723618240412
            ],
            [
              105.82869347752903,
              21.007259931568637
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Ngõ 95, Chùa Bộc",
          "maTaiSan": "04.O18.DOPP.979",
          "duongKinh": 150,
          "chieudaiQL": 133.41,
          "chatLieu": "G",
          "from_latitude": 21.006195866268246,
          "to_latitude": 21.007259931568637,
          "to_longitude": 105.82869347752903,
          "from_longitude": 105.82814707556498
        }
      },
      {
        "id": 978,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82395444970486,
            21.002244786557767,
            105.82439707617596,
            21.003083477903676
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82395444970486,
              21.002244786557767
            ],
            [
              105.8240193354941,
              21.00235256895118
            ],
            [
              105.82402120992012,
              21.002355681432185
            ],
            [
              105.82402146619297,
              21.002356108321525
            ],
            [
              105.82402188262523,
              21.002356799984486
            ],
            [
              105.82402933183204,
              21.002369176078354
            ],
            [
              105.82408430512912,
              21.002475955975573
            ],
            [
              105.8241389236451,
              21.002582046600065
            ],
            [
              105.82419277029965,
              21.00268663887334
            ],
            [
              105.82419400697455,
              21.002689041793506
            ],
            [
              105.82421543870275,
              21.002730668943304
            ],
            [
              105.82421773996009,
              21.00273513871263
            ],
            [
              105.82421862954754,
              21.00273686679571
            ],
            [
              105.82422809221639,
              21.002755245916344
            ],
            [
              105.82427647896017,
              21.002849230896864
            ],
            [
              105.8243014603571,
              21.002897755954965
            ],
            [
              105.82439707617596,
              21.003083477903676
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Khương Thượng",
          "maTaiSan": "04.O18.DOPP.980",
          "duongKinh": 150,
          "chieudaiQL": 93.52,
          "chatLieu": "G",
          "from_latitude": 21.002244786557767,
          "to_latitude": 21.003083477903676,
          "to_longitude": 105.82439707617596,
          "from_longitude": 105.82395444970486
        }
      },
      {
        "id": 979,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8243900188778,
            21.003083477903676,
            105.8249626172611,
            21.004150138390298
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82439707617596,
              21.003083477903676
            ],
            [
              105.82439707220024,
              21.003083629665156
            ],
            [
              105.82439702130888,
              21.003085571851027
            ],
            [
              105.82439150772996,
              21.00329707936454
            ],
            [
              105.82439064250259,
              21.00333025820159
            ],
            [
              105.8243900188778,
              21.003354187721005
            ],
            [
              105.82439385377184,
              21.003359083608462
            ],
            [
              105.82455873479348,
              21.00356882909309
            ],
            [
              105.82469107171741,
              21.00378751145774
            ],
            [
              105.82479901244065,
              21.00387494471777
            ],
            [
              105.82482682320118,
              21.00391597544283
            ],
            [
              105.82491934484716,
              21.00411460560584
            ],
            [
              105.82492687985226,
              21.004114386634356
            ],
            [
              105.82492973290977,
              21.00411441706464
            ],
            [
              105.82494057754359,
              21.004119071221808
            ],
            [
              105.8249626172611,
              21.004150138390298
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Khương Thượng",
          "maTaiSan": "04.O18.DOPP.981",
          "duongKinh": 150,
          "chieudaiQL": 137.93,
          "chatLieu": "G",
          "from_latitude": 21.003083477903676,
          "to_latitude": 21.004150138390298,
          "to_longitude": 105.8249626172611,
          "from_longitude": 105.82439707617596
        }
      },
      {
        "id": 980,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8249626172611,
            21.003931823144132,
            105.82540149955796,
            21.004150138390298
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8249626172611,
              21.004150138390298
            ],
            [
              105.82511955246494,
              21.004073374698315
            ],
            [
              105.82516236724653,
              21.004052431744395
            ],
            [
              105.825164597716,
              21.00405136505813
            ],
            [
              105.82520924543245,
              21.004030005850996
            ],
            [
              105.82527930611874,
              21.003994226227736
            ],
            [
              105.82540083485226,
              21.003932162368166
            ],
            [
              105.82540149955796,
              21.003931823144132
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Khương Thượng",
          "maTaiSan": "04.O18.DOPP.982",
          "duongKinh": 150,
          "chieudaiQL": 49.57,
          "chatLieu": "G",
          "from_latitude": 21.004150138390298,
          "to_latitude": 21.003931823144132,
          "to_longitude": 105.82540149955796,
          "from_longitude": 105.8249626172611
        }
      },
      {
        "id": 981,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82540149955796,
            21.003931823144132,
            105.82555326372015,
            21.004173914353693
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82540149955796,
              21.003931823144132
            ],
            [
              105.82540400959356,
              21.003936292786058
            ],
            [
              105.82546474860378,
              21.004035844588497
            ],
            [
              105.82550586997448,
              21.004100156357914
            ],
            [
              105.8255259358607,
              21.00410163426418
            ],
            [
              105.82552728166223,
              21.00410393548259
            ],
            [
              105.82554227462752,
              21.00413145997056
            ],
            [
              105.82554564127999,
              21.004153341484304
            ],
            [
              105.82554876274106,
              21.00416177332604
            ],
            [
              105.8255515026287,
              21.004169208049053
            ],
            [
              105.82555326372015,
              21.004173914353693
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Khương Thượng",
          "maTaiSan": "04.O18.DOPP.983",
          "duongKinh": 150,
          "chieudaiQL": 30.53,
          "chatLieu": "G",
          "from_latitude": 21.003931823144132,
          "to_latitude": 21.004173914353693,
          "to_longitude": 105.82555326372015,
          "from_longitude": 105.82540149955796
        }
      },
      {
        "id": 982,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82555326372015,
            21.004173914353693,
            105.8258348032861,
            21.004668228820414
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82555326372015,
              21.004173914353693
            ],
            [
              105.82567389784697,
              21.00441972597984
            ],
            [
              105.82570616734759,
              21.004478266560035
            ],
            [
              105.82573321873856,
              21.004526840948966
            ],
            [
              105.82579602328366,
              21.00463949203242
            ],
            [
              105.8258292836424,
              21.00466430217673
            ],
            [
              105.8258348032861,
              21.004668228820414
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Khương Thượng",
          "maTaiSan": "04.O18.DOPP.984",
          "duongKinh": 150,
          "chieudaiQL": 61.77,
          "chatLieu": "G",
          "from_latitude": 21.004173914353693,
          "to_latitude": 21.004668228820414,
          "to_longitude": 105.8258348032861,
          "from_longitude": 105.82555326372015
        }
      },
      {
        "id": 983,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8258348032861,
            21.004668228820414,
            105.82600986871813,
            21.00503727610689
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8258348032861,
              21.004668228820414
            ],
            [
              105.82584976559617,
              21.00467897417323
            ],
            [
              105.82592098899599,
              21.00483836971553
            ],
            [
              105.82593319344552,
              21.004865681887505
            ],
            [
              105.82600272346785,
              21.005021285728123
            ],
            [
              105.82600986871813,
              21.00503727610689
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Khương Thượng",
          "maTaiSan": "04.O18.DOPP.985",
          "duongKinh": 150,
          "chieudaiQL": 46.28,
          "chatLieu": "G",
          "from_latitude": 21.004668228820414,
          "to_latitude": 21.00503727610689,
          "to_longitude": 105.82600986871813,
          "from_longitude": 105.8258348032861
        }
      },
      {
        "id": 984,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8275326746082,
            21.005068508908916,
            105.82814707556498,
            21.006195866268246
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8275326746082,
              21.005068508908916
            ],
            [
              105.82754021802235,
              21.005082716119325
            ],
            [
              105.82754344773444,
              21.005088776422955
            ],
            [
              105.82754554338484,
              21.00509274043167
            ],
            [
              105.82756163124427,
              21.00512302237267
            ],
            [
              105.82761060719305,
              21.005216409110837
            ],
            [
              105.82764434272657,
              21.005280737266503
            ],
            [
              105.82766127757915,
              21.005313029273317
            ],
            [
              105.82766338799243,
              21.00531705282147
            ],
            [
              105.82772780969795,
              21.00543989089136
            ],
            [
              105.82773010871072,
              21.005444275715238
            ],
            [
              105.82777119630697,
              21.00552262048741
            ],
            [
              105.82778982557605,
              21.005558142305965
            ],
            [
              105.82779838734398,
              21.00557455835852
            ],
            [
              105.82779844276214,
              21.00557466557298
            ],
            [
              105.82780028013376,
              21.005578188253043
            ],
            [
              105.82786789261117,
              21.0057078273927
            ],
            [
              105.82786946848367,
              21.005710850053035
            ],
            [
              105.82791998859565,
              21.005807716532193
            ],
            [
              105.82814686291084,
              21.006195503302674
            ],
            [
              105.82814707556498,
              21.006195866268246
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Ngõ 95, Chùa Bộc",
          "maTaiSan": "04.O18.DOPP.986",
          "duongKinh": 150,
          "chieudaiQL": 139.44,
          "chatLieu": "G",
          "from_latitude": 21.005068508908916,
          "to_latitude": 21.006195866268246,
          "to_longitude": 105.82814707556498,
          "from_longitude": 105.8275326746082
        }
      },
      {
        "id": 985,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82892976421037,
            21.00529686354349,
            105.82974874402333,
            21.006874796530404
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82892976421037,
              21.00529686354349
            ],
            [
              105.82900263792622,
              21.005433455819635
            ],
            [
              105.82946675702404,
              21.006346642546657
            ],
            [
              105.82961077987346,
              21.00662518472885
            ],
            [
              105.82969566037929,
              21.00677817671195
            ],
            [
              105.82973372403536,
              21.00676271396974
            ],
            [
              105.82973430706497,
              21.006764998999532
            ],
            [
              105.8297346354049,
              21.00676575610885
            ],
            [
              105.82974874402333,
              21.006794241016625
            ],
            [
              105.82971797430785,
              21.006807964717225
            ],
            [
              105.82972426093843,
              21.006820797817035
            ],
            [
              105.82974625954883,
              21.006874796530404
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 43, Chùa Bộc",
          "maTaiSan": "04.O18.DOPP.987",
          "duongKinh": 100,
          "chieudaiQL": 194.21,
          "chatLieu": "G",
          "from_latitude": 21.00529686354349,
          "to_latitude": 21.006874796530404,
          "to_longitude": 105.82974625954883,
          "from_longitude": 105.82892976421037
        }
      },
      {
        "id": 986,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82862355129096,
            21.004701722834838,
            105.82892973351112,
            21.00529687814463
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82862355129096,
              21.004701722834838
            ],
            [
              105.82867831257053,
              21.004805414906087
            ],
            [
              105.82872917208297,
              21.00490169962487
            ],
            [
              105.82873335209317,
              21.00491105657119
            ],
            [
              105.82883004957395,
              21.005101043693184
            ],
            [
              105.82892973351112,
              21.00529687814463
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 43, Chùa Bộc",
          "maTaiSan": "04.O18.DOPP.988",
          "duongKinh": 100,
          "chieudaiQL": 73.31,
          "chatLieu": "G",
          "from_latitude": 21.004701722834838,
          "to_latitude": 21.00529687814463,
          "to_longitude": 105.82892973351112,
          "from_longitude": 105.82862355129096
        }
      },
      {
        "id": 987,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82764434272657,
            21.00520648145225,
            105.82781106504444,
            21.005280737266503
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82764434272657,
              21.005280737266503
            ],
            [
              105.82781106504444,
              21.00520648145225
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D80_Ngõ 95, Chùa Bộc",
          "maTaiSan": "04.O18.DOPP.989",
          "duongKinh": 80,
          "chieudaiQL": 19.18,
          "chatLieu": "G",
          "from_latitude": 21.005280737266503,
          "to_latitude": 21.00520648145225,
          "to_longitude": 105.82781106504444,
          "from_longitude": 105.82764434272657
        }
      },
      {
        "id": 988,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82761060719305,
            21.00479256549736,
            105.8285143258101,
            21.005216409110837
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82761060719305,
              21.005216409110837
            ],
            [
              105.82762465679457,
              21.00520935071937
            ],
            [
              105.82777017513003,
              21.005136235861826
            ],
            [
              105.82785402657447,
              21.005094106170265
            ],
            [
              105.82795680661185,
              21.00504535131899
            ],
            [
              105.82795828306811,
              21.00504465136158
            ],
            [
              105.8281356224563,
              21.004960528224995
            ],
            [
              105.82814731134066,
              21.004955343731666
            ],
            [
              105.82837679215454,
              21.004853565067584
            ],
            [
              105.82847531560327,
              21.00480986702068
            ],
            [
              105.82850141932057,
              21.004798289749356
            ],
            [
              105.82850612767822,
              21.004796201150366
            ],
            [
              105.82851065852873,
              21.004794191996044
            ],
            [
              105.8285143258101,
              21.00479256549736
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 95, Chùa Bộc",
          "maTaiSan": "04.O18.DOPP.990",
          "duongKinh": 100,
          "chieudaiQL": 105.03,
          "chatLieu": "G",
          "from_latitude": 21.005216409110837,
          "to_latitude": 21.00479256549736,
          "to_longitude": 105.8285143258101,
          "from_longitude": 105.82761060719305
        }
      },
      {
        "id": 989,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8229541128312,
            21.006518312395,
            105.82325736034524,
            21.00664504808959
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8229541128312,
              21.00664504808959
            ],
            [
              105.82301011487196,
              21.006615999548494
            ],
            [
              105.82304059193633,
              21.006600191101054
            ],
            [
              105.8231984452799,
              21.006518312395
            ],
            [
              105.82323370784569,
              21.00658652608159
            ],
            [
              105.82324619612649,
              21.00661233410718
            ],
            [
              105.82325736034524,
              21.00660683815716
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Tây Sơn",
          "maTaiSan": "04.O18.DOPP.991",
          "duongKinh": 150,
          "chieudaiQL": 29.18,
          "chatLieu": "G",
          "from_latitude": 21.00664504808959,
          "to_latitude": 21.00660683815716,
          "to_longitude": 105.82325736034524,
          "from_longitude": 105.8229541128312
        }
      },
      {
        "id": 990,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82346555100926,
            21.003536574465297,
            105.82377853162807,
            21.003804841597184
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82352292601371,
              21.003804841597184
            ],
            [
              105.82346555100926,
              21.003691083980776
            ],
            [
              105.82347823814622,
              21.0036846295541
            ],
            [
              105.82361367972848,
              21.003615719112574
            ],
            [
              105.82366594781065,
              21.00359215297654
            ],
            [
              105.82366599386476,
              21.00359213197944
            ],
            [
              105.82377247005434,
              21.0035395672921
            ],
            [
              105.82377853162807,
              21.003536574465297
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 554, Trường Chinh",
          "maTaiSan": "04.O18.DOPP.992",
          "duongKinh": 100,
          "chieudaiQL": 50.34,
          "chatLieu": "G",
          "from_latitude": 21.003804841597184,
          "to_latitude": 21.003536574465297,
          "to_longitude": 105.82377853162807,
          "from_longitude": 105.82352292601371
        }
      },
      {
        "id": 991,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8288136728012,
            21.00099128130369,
            105.82931402849283,
            21.00153371965838
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8288136728012,
              21.00099128130369
            ],
            [
              105.82881467026087,
              21.000995118775137
            ],
            [
              105.82881522152516,
              21.000997232352137
            ],
            [
              105.82895986797324,
              21.001031639162132
            ],
            [
              105.82896021950074,
              21.001031721450392
            ],
            [
              105.82908752230894,
              21.001061998151684
            ],
            [
              105.82915145055631,
              21.00120691754881
            ],
            [
              105.8292002530162,
              21.001317549134235
            ],
            [
              105.82931402849283,
              21.00153371965838
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Tôn Thất Tùng",
          "maTaiSan": "04.O18.DOPP.993",
          "duongKinh": 150,
          "chieudaiQL": 70.28,
          "chatLieu": "G",
          "from_latitude": 21.00099128130369,
          "to_latitude": 21.00153371965838,
          "to_longitude": 105.82931402849283,
          "from_longitude": 105.8288136728012
        }
      },
      {
        "id": 992,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82712288302848,
            21.00676575610885,
            105.8297346354049,
            21.00798130370474
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82712288302848,
              21.00798130370474
            ],
            [
              105.82719118711982,
              21.007953450396723
            ],
            [
              105.82719740231362,
              21.007950736700096
            ],
            [
              105.82720159717589,
              21.007946211918657
            ],
            [
              105.82751384660475,
              21.00781263490913
            ],
            [
              105.82751387154846,
              21.007812623045886
            ],
            [
              105.82808939064319,
              21.007543591232974
            ],
            [
              105.82831425787177,
              21.007436325337654
            ],
            [
              105.82831428281533,
              21.0074363134743
            ],
            [
              105.82845315478254,
              21.007370068676384
            ],
            [
              105.82860210181444,
              21.007301808390192
            ],
            [
              105.82869347752903,
              21.007259931568637
            ],
            [
              105.82870199216825,
              21.007255933072152
            ],
            [
              105.82899217500483,
              21.007119207445882
            ],
            [
              105.82929173008438,
              21.00697834134295
            ],
            [
              105.82941970177177,
              21.00692108722006
            ],
            [
              105.82941972671513,
              21.00692107535656
            ],
            [
              105.82948487352566,
              21.006891928665578
            ],
            [
              105.8296432515033,
              21.0068147419064
            ],
            [
              105.82964327644665,
              21.00681473004287
            ],
            [
              105.82964529288803,
              21.006813747438144
            ],
            [
              105.82964531783138,
              21.006813735574603
            ],
            [
              105.8297262629971,
              21.00677059125304
            ],
            [
              105.8297346354049,
              21.00676575610885
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Chùa Bộc",
          "maTaiSan": "04.O18.DOPP.994",
          "duongKinh": 150,
          "chieudaiQL": 298.25,
          "chatLieu": "G",
          "from_latitude": 21.00798130370474,
          "to_latitude": 21.00676575610885,
          "to_longitude": 105.8297346354049,
          "from_longitude": 105.82712288302848
        }
      },
      {
        "id": 993,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82530112668735,
            21.006894642897358,
            105.82663518385012,
            21.007514704010298
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82663518385012,
              21.006894642897358
            ],
            [
              105.82649966032916,
              21.00695976531727
            ],
            [
              105.82649962769568,
              21.006959778120933
            ],
            [
              105.82649960275208,
              21.00695978998402
            ],
            [
              105.82644171925948,
              21.006984538595052
            ],
            [
              105.8263108630986,
              21.0070404885149
            ],
            [
              105.8260906523714,
              21.007143909441368
            ],
            [
              105.82584240177007,
              21.007260498821452
            ],
            [
              105.82551574135104,
              21.007413912518302
            ],
            [
              105.8254545503694,
              21.007442650141027
            ],
            [
              105.82530112668735,
              21.007514704010298
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngách 95/8, Chùa Bộc",
          "maTaiSan": "04.O18.DOPP.995",
          "duongKinh": 100,
          "chieudaiQL": 154.75,
          "chatLieu": "G",
          "from_latitude": 21.006894642897358,
          "to_latitude": 21.007514704010298,
          "to_longitude": 105.82530112668735,
          "from_longitude": 105.82663518385012
        }
      },
      {
        "id": 994,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8297346354049,
            21.006159902522867,
            105.83102697769888,
            21.00676575610885
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8297346354049,
              21.00676575610885
            ],
            [
              105.82973828799491,
              21.00676477184871
            ],
            [
              105.82981939185804,
              21.006733811233445
            ],
            [
              105.8298194168013,
              21.006733799369876
            ],
            [
              105.83017816086905,
              21.006580651902695
            ],
            [
              105.83029326519456,
              21.006527987778874
            ],
            [
              105.83039330320773,
              21.00648221825661
            ],
            [
              105.83060750236429,
              21.00637574481584
            ],
            [
              105.83060752730742,
              21.006375732952165
            ],
            [
              105.83086916069637,
              21.006240167798705
            ],
            [
              105.83086918563951,
              21.006240155934993
            ],
            [
              105.83100875145003,
              21.006169171909736
            ],
            [
              105.83102697769888,
              21.006159902522867
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Chùa Bộc",
          "maTaiSan": "04.O18.DOPP.996",
          "duongKinh": 150,
          "chieudaiQL": 152.02,
          "chatLieu": "G",
          "from_latitude": 21.00676575610885,
          "to_latitude": 21.006159902522867,
          "to_longitude": 105.83102697769888,
          "from_longitude": 105.8297346354049
        }
      },
      {
        "id": 995,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82082125226579,
            21.003293644658555,
            105.82283632096946,
            21.0057510881572
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82082125226579,
              21.003293644658555
            ],
            [
              105.82114916307245,
              21.00355582009425
            ],
            [
              105.82124037731856,
              21.00361100803087
            ],
            [
              105.82136783650748,
              21.0037523154235
            ],
            [
              105.82200684884472,
              21.004298277513886
            ],
            [
              105.82246991812399,
              21.00492838030725
            ],
            [
              105.82283632096946,
              21.0057510881572
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Tây Sơn",
          "maTaiSan": "04.O18.DOPP.997",
          "duongKinh": 150,
          "chieudaiQL": 347.84,
          "chatLieu": "G",
          "from_latitude": 21.003293644658555,
          "to_latitude": 21.0057510881572,
          "to_longitude": 105.82283632096946,
          "from_longitude": 105.82082125226579
        }
      },
      {
        "id": 996,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82402188262523,
            21.000978121192425,
            105.82884949422342,
            21.002356799984486
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82402188262523,
              21.002356799984486
            ],
            [
              105.82403629735838,
              21.002346360243898
            ],
            [
              105.82404141475564,
              21.002342917656108
            ],
            [
              105.8242640056307,
              21.0022629998791
            ],
            [
              105.82430995745229,
              21.002251488814323
            ],
            [
              105.82502275148995,
              21.00207293396425
            ],
            [
              105.82523109685685,
              21.002020742626136
            ],
            [
              105.82540057720729,
              21.001978287518295
            ],
            [
              105.82577221854596,
              21.001885188573237
            ],
            [
              105.82580660154727,
              21.001876575689806
            ],
            [
              105.8263916382666,
              21.00173001849119
            ],
            [
              105.82676007142223,
              21.001632537891584
            ],
            [
              105.8271855210182,
              21.00151997013226
            ],
            [
              105.82760211174828,
              21.001409744916693
            ],
            [
              105.82787125883534,
              21.001318232647296
            ],
            [
              105.82819676787153,
              21.00120755655993
            ],
            [
              105.82857540708824,
              21.001078814134363
            ],
            [
              105.82879201849008,
              21.00099923668212
            ],
            [
              105.8288136728012,
              21.00099128130369
            ],
            [
              105.82884491071444,
              21.000979804544667
            ],
            [
              105.82884949422342,
              21.000978121192425
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Trường Chinh",
          "maTaiSan": "04.O18.DOPP.998",
          "duongKinh": 150,
          "chieudaiQL": 544.57,
          "chatLieu": "G",
          "from_latitude": 21.002356799984486,
          "to_latitude": 21.000978121192425,
          "to_longitude": 105.82884949422342,
          "from_longitude": 105.82402188262523
        }
      },
      {
        "id": 997,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82486363513664,
            21.00452895207921,
            105.82528960177899,
            21.005353315721003
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82528960177899,
              21.005353315721003
            ],
            [
              105.82528901025445,
              21.005352446071807
            ],
            [
              105.82528070154328,
              21.00534019163169
            ],
            [
              105.82520608524308,
              21.00523015738045
            ],
            [
              105.82513039502275,
              21.005072081122904
            ],
            [
              105.82512878510862,
              21.005068803890186
            ],
            [
              105.82486463388882,
              21.004530937952048
            ],
            [
              105.82486363513664,
              21.00452895207921
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 44, Khương Thượng",
          "maTaiSan": "04.O18.DOPP.999",
          "duongKinh": 100,
          "chieudaiQL": 101.12,
          "chatLieu": "G",
          "from_latitude": 21.005353315721003,
          "to_latitude": 21.00452895207921,
          "to_longitude": 105.82486363513664,
          "from_longitude": 105.82528960177899
        }
      },
      {
        "id": 998,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8258348032861,
            21.0044061860315,
            105.82646289606976,
            21.004668228820414
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8258348032861,
              21.004668228820414
            ],
            [
              105.82583602940927,
              21.00466765745089
            ],
            [
              105.82605498284553,
              21.004576361623545
            ],
            [
              105.82610159376215,
              21.00455692646392
            ],
            [
              105.82613321183071,
              21.004543743042476
            ],
            [
              105.82641092794621,
              21.004427945109374
            ],
            [
              105.82643980516417,
              21.004415904877487
            ],
            [
              105.82644548240658,
              21.00441351537191
            ],
            [
              105.82646289606976,
              21.0044061860315
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 123, Khương Thượng",
          "maTaiSan": "04.O18.DOPP.1000",
          "duongKinh": 100,
          "chieudaiQL": 73.1,
          "chatLieu": "G",
          "from_latitude": 21.004668228820414,
          "to_latitude": 21.0044061860315,
          "to_longitude": 105.82646289606976,
          "from_longitude": 105.8258348032861
        }
      },
      {
        "id": 999,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82283632096946,
            21.0057510881572,
            105.82325508325167,
            21.006602571487
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82283632096946,
              21.0057510881572
            ],
            [
              105.82300934399416,
              21.00611588981405
            ],
            [
              105.82325508325167,
              21.006602571487
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Tây Sơn",
          "maTaiSan": "04.O18.DOPP.1001",
          "duongKinh": 150,
          "chieudaiQL": 104.26,
          "chatLieu": "G",
          "from_latitude": 21.0057510881572,
          "to_latitude": 21.006602571487,
          "to_longitude": 105.82325508325167,
          "from_longitude": 105.82283632096946
        }
      },
      {
        "id": 1000,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82240855357566,
            21.003021725989424,
            105.82296753467594,
            21.003294124360096
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82240855357566,
              21.003294124360096
            ],
            [
              105.82240858046744,
              21.003294117004334
            ],
            [
              105.82240889740756,
              21.003294030569553
            ],
            [
              105.82245822699004,
              21.003280449732536
            ],
            [
              105.8225944379433,
              21.003221145820326
            ],
            [
              105.82260012147472,
              21.00321867150843
            ],
            [
              105.8226040096475,
              21.003216978132965
            ],
            [
              105.82274794107315,
              21.00314990786759
            ],
            [
              105.82276001679956,
              21.00314474445282
            ],
            [
              105.82276475630735,
              21.00314271728043
            ],
            [
              105.82284377662484,
              21.00310372681903
            ],
            [
              105.82286079179448,
              21.003092478859674
            ],
            [
              105.8229549482823,
              21.003030233830653
            ],
            [
              105.82296382028179,
              21.003024236857236
            ],
            [
              105.82296753467594,
              21.003021725989424
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 592, Trường Chinh",
          "maTaiSan": "04.O18.DOPP.1002",
          "duongKinh": 100,
          "chieudaiQL": 66.49,
          "chatLieu": "G",
          "from_latitude": 21.003294124360096,
          "to_latitude": 21.003021725989424,
          "to_longitude": 105.82296753467594,
          "from_longitude": 105.82240855357566
        }
      },
      {
        "id": 1001,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82737155867866,
            21.004752572070533,
            105.8275326746082,
            21.005068508908916
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82737155867866,
              21.004752572070533
            ],
            [
              105.82743559842575,
              21.004885775755035
            ],
            [
              105.82745151527699,
              21.00491575415231
            ],
            [
              105.82752413105399,
              21.005052419719956
            ],
            [
              105.8275326746082,
              21.005068508908916
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Ngõ 95, Chùa Bộc",
          "maTaiSan": "04.O18.DOPP.1003",
          "duongKinh": 150,
          "chieudaiQL": 39.42,
          "chatLieu": "G",
          "from_latitude": 21.004752572070533,
          "to_latitude": 21.005068508908916,
          "to_longitude": 105.8275326746082,
          "from_longitude": 105.82737155867866
        }
      },
      {
        "id": 1002,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82663518385012,
            21.006894642897358,
            105.82720442601838,
            21.007962775086806
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82720442601838,
              21.007962775086806
            ],
            [
              105.82719740231362,
              21.007950736700096
            ],
            [
              105.8271944563083,
              21.007945723660335
            ],
            [
              105.82716293578738,
              21.00792471336617
            ],
            [
              105.82712993336331,
              21.007821751481252
            ],
            [
              105.82712941748156,
              21.007820822761893
            ],
            [
              105.82710137400309,
              21.007770280817503
            ],
            [
              105.82704711263099,
              21.007672488430735
            ],
            [
              105.82703865843725,
              21.007654830789082
            ],
            [
              105.82692290041902,
              21.00744437063744
            ],
            [
              105.8269211113402,
              21.007440884746362
            ],
            [
              105.82682152352977,
              21.00724572066603
            ],
            [
              105.82678516002765,
              21.007177206933235
            ],
            [
              105.82678308593968,
              21.00717331777945
            ],
            [
              105.8267818311181,
              21.00717095290522
            ],
            [
              105.82669950308836,
              21.007015836157453
            ],
            [
              105.82663518385012,
              21.006894642897358
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Ngõ 165, Chùa Bộc",
          "maTaiSan": "04.O18.DOPP.1004",
          "duongKinh": 150,
          "chieudaiQL": 132.83,
          "chatLieu": "G",
          "from_latitude": 21.007962775086806,
          "to_latitude": 21.006894642897358,
          "to_longitude": 105.82663518385012,
          "from_longitude": 105.82720442601838
        }
      },
      {
        "id": 1003,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82386996256945,
            21.00705269111461,
            105.82425922837444,
            21.007243097600146
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82386996256945,
              21.007243097600146
            ],
            [
              105.82425922837444,
              21.00705269111461
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D160_Tây Sơn",
          "maTaiSan": "04.O18.DOPP.1005",
          "duongKinh": 160,
          "chieudaiQL": 45.63,
          "chatLieu": "PVC",
          "from_latitude": 21.007243097600146,
          "to_latitude": 21.00705269111461,
          "to_longitude": 105.82425922837444,
          "from_longitude": 105.82386996256945
        }
      },
      {
        "id": 1004,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82401387973961,
            21.004119071221808,
            105.82494057754359,
            21.004662359795965
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82401387973961,
              21.004662359795965
            ],
            [
              105.82419191727107,
              21.004529867697048
            ],
            [
              105.82420190393745,
              21.004522438161864
            ],
            [
              105.82429891874322,
              21.004450268336317
            ],
            [
              105.82445965410264,
              21.004332203014524
            ],
            [
              105.824459679046,
              21.004332191151757
            ],
            [
              105.82469456019973,
              21.00424120468272
            ],
            [
              105.82471834728489,
              21.004231430310437
            ],
            [
              105.82492643139986,
              21.004127295927717
            ],
            [
              105.82493837709198,
              21.00412017479203
            ],
            [
              105.82494057754359,
              21.004119071221808
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Ngõ 554, Trường Chinh",
          "maTaiSan": "04.O18.DOPP.1006",
          "duongKinh": 150,
          "chieudaiQL": 116.37,
          "chatLieu": "G",
          "from_latitude": 21.004662359795965,
          "to_latitude": 21.004119071221808,
          "to_longitude": 105.82494057754359,
          "from_longitude": 105.82401387973961
        }
      },
      {
        "id": 1005,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82287244600828,
            21.003804841597184,
            105.82352292601371,
            21.00410483252345
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82287244600828,
              21.00410483252345
            ],
            [
              105.82301237750657,
              21.00403263115393
            ],
            [
              105.8230257151928,
              21.004026227807874
            ],
            [
              105.82318755176557,
              21.003960116517273
            ],
            [
              105.8231919914013,
              21.003958303041426
            ],
            [
              105.82319730533465,
              21.00395613217492
            ],
            [
              105.8232056263544,
              21.003952087018494
            ],
            [
              105.82337917427365,
              21.00386771615901
            ],
            [
              105.82339447542701,
              21.003861023285836
            ],
            [
              105.8235174651572,
              21.003807229959133
            ],
            [
              105.82352292601371,
              21.003804841597184
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Ngõ 554, Trường Chinh",
          "maTaiSan": "04.O18.DOPP.1007",
          "duongKinh": 150,
          "chieudaiQL": 76,
          "chatLieu": "G",
          "from_latitude": 21.00410483252345,
          "to_latitude": 21.003804841597184,
          "to_longitude": 105.82352292601371,
          "from_longitude": 105.82287244600828
        }
      },
      {
        "id": 1006,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82296753467594,
            21.003021725989424,
            105.82318747673536,
            21.003219513461513
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82296753467594,
              21.003021725989424
            ],
            [
              105.82307942620625,
              21.003098647588605
            ],
            [
              105.82308972764702,
              21.00310547675759
            ],
            [
              105.82312610095923,
              21.003135233834023
            ],
            [
              105.82317122326056,
              21.00319671782275
            ],
            [
              105.82317550973163,
              21.0032027586544
            ],
            [
              105.82317790596996,
              21.003206135976413
            ],
            [
              105.82318747673536,
              21.003219513461513
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 554, Trường Chinh",
          "maTaiSan": "04.O18.DOPP.1008",
          "duongKinh": 100,
          "chieudaiQL": 31.89,
          "chatLieu": "G",
          "from_latitude": 21.003021725989424,
          "to_latitude": 21.003219513461513,
          "to_longitude": 105.82318747673536,
          "from_longitude": 105.82296753467594
        }
      },
      {
        "id": 1007,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82931402849283,
            21.00153371965838,
            105.82947172875491,
            21.00188247027836
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82947172875491,
              21.00188247027836
            ],
            [
              105.82946432682002,
              21.00186514723006
            ],
            [
              105.8294640452046,
              21.001864488343486
            ],
            [
              105.82939231508333,
              21.001706215863543
            ],
            [
              105.82931758704298,
              21.001541328266278
            ],
            [
              105.82931402849283,
              21.00153371965838
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D200_Tôn Thất Tùng",
          "maTaiSan": "04.O18.DOPP.1009",
          "duongKinh": 200,
          "chieudaiQL": 42.78,
          "chatLieu": "G",
          "from_latitude": 21.00188247027836,
          "to_latitude": 21.00153371965838,
          "to_longitude": 105.82931402849283,
          "from_longitude": 105.82947172875491
        }
      },
      {
        "id": 1008,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82524166666212,
            21.002456716360175,
            105.82605092410422,
            21.00252100362167
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82605092410422,
              21.00252100362167
            ],
            [
              105.82581013380685,
              21.002483115543782
            ],
            [
              105.82561388459285,
              21.002468384806182
            ],
            [
              105.82549626383012,
              21.002459556288056
            ],
            [
              105.82524166666212,
              21.002456716360175
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Ngõ 191, Khương Thượng",
          "maTaiSan": "04.O18.DOPP.1010",
          "duongKinh": 150,
          "chieudaiQL": 84.59,
          "chatLieu": "G",
          "from_latitude": 21.00252100362167,
          "to_latitude": 21.002456716360175,
          "to_longitude": 105.82524166666212,
          "from_longitude": 105.82605092410422
        }
      },
      {
        "id": 1009,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8295088939119,
            21.00390258740018,
            105.83020871489086,
            21.004057688244604
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8295088939119,
              21.004057688244604
            ],
            [
              105.8301110497472,
              21.00392857628785
            ],
            [
              105.83011929659492,
              21.003926808194546
            ],
            [
              105.8302028080015,
              21.003903439954552
            ],
            [
              105.83020871489086,
              21.00390258740018
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Tôn Thất Tùng",
          "maTaiSan": "04.O18.DOPP.1011",
          "duongKinh": 100,
          "chieudaiQL": 72.94,
          "chatLieu": "G",
          "from_latitude": 21.004057688244604,
          "to_latitude": 21.00390258740018,
          "to_longitude": 105.83020871489086,
          "from_longitude": 105.8295088939119
        }
      },
      {
        "id": 1010,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82944584702915,
            21.00362791434942,
            105.83012464963905,
            21.003766574830077
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82944584702915,
              21.003766574830077
            ],
            [
              105.82999848714373,
              21.00364929659889
            ],
            [
              105.83000858962377,
              21.003647741656245
            ],
            [
              105.83011574979759,
              21.003631264467362
            ],
            [
              105.83012464963905,
              21.00362791434942
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D110_Tôn Thất Tùng",
          "maTaiSan": "04.O18.DOPP.1012",
          "duongKinh": 110,
          "chieudaiQL": 69.6,
          "chatLieu": "PVC",
          "from_latitude": 21.003766574830077,
          "to_latitude": 21.00362791434942,
          "to_longitude": 105.83012464963905,
          "from_longitude": 105.82944584702915
        }
      },
      {
        "id": 1011,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82936620875445,
            21.003368669013238,
            105.8300267423158,
            21.00349955901902
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82936620875445,
              21.00349955901902
            ],
            [
              105.82989628251183,
              21.003386760005423
            ],
            [
              105.829904693452,
              21.00338579680127
            ],
            [
              105.83001669755933,
              21.003373005613
            ],
            [
              105.8300267423158,
              21.003368669013238
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Tôn Thất Tùng",
          "maTaiSan": "04.O18.DOPP.1013",
          "duongKinh": 100,
          "chieudaiQL": 68.26,
          "chatLieu": "G",
          "from_latitude": 21.00349955901902,
          "to_latitude": 21.003368669013238,
          "to_longitude": 105.8300267423158,
          "from_longitude": 105.82936620875445
        }
      },
      {
        "id": 1012,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82931079967892,
            21.003086471884256,
            105.82993713526541,
            21.003220998383224
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82931079967892,
              21.003220998383224
            ],
            [
              105.82979315423142,
              21.00311732266727
            ],
            [
              105.82979780121175,
              21.003116323624745
            ],
            [
              105.82992065366581,
              21.003089918572254
            ],
            [
              105.82993713526541,
              21.003086471884256
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Tôn Thất Tùng",
          "maTaiSan": "04.O18.DOPP.1014",
          "duongKinh": 100,
          "chieudaiQL": 66.8,
          "chatLieu": "G",
          "from_latitude": 21.003220998383224,
          "to_latitude": 21.003086471884256,
          "to_longitude": 105.82993713526541,
          "from_longitude": 105.82931079967892
        }
      },
      {
        "id": 1013,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82918464605895,
            21.00252638018351,
            105.82971608999992,
            21.002622900059144
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82918464605895,
              21.002622900059144
            ],
            [
              105.8295727864083,
              21.002552324750827
            ],
            [
              105.8295821195162,
              21.002550628165444
            ],
            [
              105.82969037026659,
              21.002530945815614
            ],
            [
              105.82971604771943,
              21.00252638761542
            ],
            [
              105.82971608999992,
              21.00252638018351
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Tôn Thất Tùng",
          "maTaiSan": "04.O18.DOPP.1015",
          "duongKinh": 100,
          "chieudaiQL": 57.35,
          "chatLieu": "G",
          "from_latitude": 21.002622900059144,
          "to_latitude": 21.00252638018351,
          "to_longitude": 105.82971608999992,
          "from_longitude": 105.82918464605895
        }
      },
      {
        "id": 1014,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8297133714007,
            21.002517955490834,
            105.83076104696381,
            21.005467352357034
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8297133714007,
              21.002517955490834
            ],
            [
              105.82971608999992,
              21.00252638018351
            ],
            [
              105.82972537045356,
              21.00255100297184
            ],
            [
              105.82978433982234,
              21.002726389500822
            ],
            [
              105.82993713526541,
              21.003086471884256
            ],
            [
              105.8300267423158,
              21.003368669013238
            ],
            [
              105.83012464963905,
              21.00362791434942
            ],
            [
              105.830132985132,
              21.003657653979737
            ],
            [
              105.83020871489086,
              21.00390258740018
            ],
            [
              105.83032379924491,
              21.004195795924353
            ],
            [
              105.83032380707954,
              21.004195821176467
            ],
            [
              105.8307260382292,
              21.005369442797704
            ],
            [
              105.83073905972074,
              21.005407438486138
            ],
            [
              105.83074831019633,
              21.005432661992533
            ],
            [
              105.83074836694831,
              21.005432836038196
            ],
            [
              105.83076104696381,
              21.005467352357034
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D160_Tôn Thất Tùng",
          "maTaiSan": "04.O18.DOPP.1016",
          "duongKinh": 160,
          "chieudaiQL": 345.53,
          "chatLieu": "PVC",
          "from_latitude": 21.002517955490834,
          "to_latitude": 21.005467352357034,
          "to_longitude": 105.83076104696381,
          "from_longitude": 105.8297133714007
        }
      },
      {
        "id": 1015,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82910698475531,
            21.005369442797704,
            105.8307260382292,
            21.006008851010453
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8307260382292,
              21.005369442797704
            ],
            [
              105.83072598158029,
              21.005369461138738
            ],
            [
              105.83070850837484,
              21.00537522320164
            ],
            [
              105.83070840756106,
              21.005375256209852
            ],
            [
              105.8306955141238,
              21.00537950830435
            ],
            [
              105.83069540274965,
              21.005379544977
            ],
            [
              105.8302838012184,
              21.00551527428516
            ],
            [
              105.83022246861509,
              21.00553044419901
            ],
            [
              105.83015858174805,
              21.00554031106272
            ],
            [
              105.83007583230997,
              21.00555309157949
            ],
            [
              105.83006027859514,
              21.005542789366
            ],
            [
              105.83006001986602,
              21.005542618111612
            ],
            [
              105.82999824517853,
              21.005501687096235
            ],
            [
              105.82992637282554,
              21.0055325973463
            ],
            [
              105.82992293077768,
              21.005534078260812
            ],
            [
              105.82991896190957,
              21.005535784841122
            ],
            [
              105.82982190985305,
              21.005577523772278
            ],
            [
              105.82981864735585,
              21.005579121228752
            ],
            [
              105.82981116116736,
              21.005582786892763
            ],
            [
              105.82981069400132,
              21.00558301588034
            ],
            [
              105.82977831269366,
              21.005598873639144
            ],
            [
              105.82960873298097,
              21.00567812568151
            ],
            [
              105.82960488196993,
              21.005679925615727
            ],
            [
              105.82960125833254,
              21.005681618764047
            ],
            [
              105.82959690843361,
              21.005683651452614
            ],
            [
              105.82953356435098,
              21.005713254550095
            ],
            [
              105.8294527621137,
              21.00575101665065
            ],
            [
              105.82944676975956,
              21.005753823276176
            ],
            [
              105.82943213713304,
              21.00576067691593
            ],
            [
              105.82926079338166,
              21.005454781555443
            ],
            [
              105.82910698475531,
              21.005530092255054
            ],
            [
              105.82935967325666,
              21.006008851010453
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D90_Ngõ 6, Tôn Thất Tùng",
          "maTaiSan": "04.O18.DOPP.1017",
          "duongKinh": 90,
          "chieudaiQL": 265.13,
          "chatLieu": "HDPE",
          "from_latitude": 21.005369442797704,
          "to_latitude": 21.006008851010453,
          "to_longitude": 105.82935967325666,
          "from_longitude": 105.8307260382292
        }
      },
      {
        "id": 1016,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82754344773444,
            21.00489757693688,
            105.82795665217084,
            21.005088776422955
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82754344773444,
              21.005088776422955
            ],
            [
              105.82755305350209,
              21.00508433732492
            ],
            [
              105.8277685652639,
              21.004984745453314
            ],
            [
              105.82792998734155,
              21.004910149501956
            ],
            [
              105.8279382182273,
              21.004906344813442
            ],
            [
              105.8279454645784,
              21.00490296491435
            ],
            [
              105.8279465370989,
              21.00490244847062
            ],
            [
              105.82795665217084,
              21.00489757693688
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 95, Chùa Bộc",
          "maTaiSan": "04.O18.DOPP.1018",
          "duongKinh": 100,
          "chieudaiQL": 47.99,
          "chatLieu": "G",
          "from_latitude": 21.005088776422955,
          "to_latitude": 21.00489757693688,
          "to_longitude": 105.82795665217084,
          "from_longitude": 105.82754344773444
        }
      },
      {
        "id": 1017,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82352292601371,
            21.003804841597184,
            105.82401387973961,
            21.004662359795965
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82352292601371,
              21.003804841597184
            ],
            [
              105.82352759663125,
              21.003813396952356
            ],
            [
              105.82353785454475,
              21.003832264520934
            ],
            [
              105.82358198986705,
              21.003902948921592
            ],
            [
              105.82361729253581,
              21.003962083123035
            ],
            [
              105.8236920199702,
              21.004101459852766
            ],
            [
              105.82375864364491,
              21.004223102954903
            ],
            [
              105.82377839379541,
              21.0042564294975
            ],
            [
              105.8238458381668,
              21.004376335293536
            ],
            [
              105.82390137379818,
              21.0044781989284
            ],
            [
              105.82393465007833,
              21.00453663202184
            ],
            [
              105.82401036333563,
              21.004656787648372
            ],
            [
              105.82401387973961,
              21.004662359795965
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Ngõ 554, Trường Chinh",
          "maTaiSan": "04.O18.DOPP.1019",
          "duongKinh": 150,
          "chieudaiQL": 107.73,
          "chatLieu": "G",
          "from_latitude": 21.003804841597184,
          "to_latitude": 21.004662359795965,
          "to_longitude": 105.82401387973961,
          "from_longitude": 105.82352292601371
        }
      },
      {
        "id": 1018,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81544679276527,
            21.01433393446598,
            105.81632367740585,
            21.014958350907857
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81544679276527,
              21.014958350907857
            ],
            [
              105.81551706779058,
              21.014949475853538
            ],
            [
              105.81560839570183,
              21.014937941904435
            ],
            [
              105.8156170263786,
              21.01493090051339
            ],
            [
              105.81569804670335,
              21.014864804967456
            ],
            [
              105.81586228506887,
              21.014721278554582
            ],
            [
              105.81586414939737,
              21.014719705223772
            ],
            [
              105.81587536504429,
              21.01471023890257
            ],
            [
              105.81603861847648,
              21.014572448933375
            ],
            [
              105.81605139281424,
              21.014561461315093
            ],
            [
              105.81617555235393,
              21.014454668732387
            ],
            [
              105.81629011444856,
              21.014361650269425
            ],
            [
              105.81632039692808,
              21.0143370627288
            ],
            [
              105.81632367740585,
              21.01433393446598
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngách 165/30, Thái Hà",
          "maTaiSan": "04.O11.DOPP.1020",
          "duongKinh": 100,
          "chieudaiQL": 116.84,
          "chatLieu": "G",
          "from_latitude": 21.014958350907857,
          "to_latitude": 21.01433393446598,
          "to_longitude": 105.81632367740585,
          "from_longitude": 105.81544679276527
        }
      },
      {
        "id": 1019,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81608618239093,
            21.01282987334486,
            105.8176424504581,
            21.014056714329886
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81608618239093,
              21.014056714329886
            ],
            [
              105.8160866123207,
              21.01405635097655
            ],
            [
              105.81625550350381,
              21.013913576857714
            ],
            [
              105.81653018978602,
              21.01371649321702
            ],
            [
              105.81654299926927,
              21.01370721068928
            ],
            [
              105.81657783465715,
              21.013681967189555
            ],
            [
              105.81686440102295,
              21.013449234318337
            ],
            [
              105.81704135376415,
              21.013295887302604
            ],
            [
              105.81733382717773,
              21.01306799008997
            ],
            [
              105.81734583944375,
              21.013058237139035
            ],
            [
              105.81749390719254,
              21.01294118011772
            ],
            [
              105.81763843131574,
              21.01283283085864
            ],
            [
              105.81764084471412,
              21.01283105435409
            ],
            [
              105.8176424504581,
              21.01282987334486
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 151B, Thái Hà",
          "maTaiSan": "04.O11.DOPP.1021",
          "duongKinh": 100,
          "chieudaiQL": 210.11,
          "chatLieu": "G-DI",
          "from_latitude": 21.014056714329886,
          "to_latitude": 21.01282987334486,
          "to_longitude": 105.8176424504581,
          "from_longitude": 105.81608618239093
        }
      },
      {
        "id": 1020,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81131916262436,
            21.010891737380486,
            105.81514400654284,
            21.016347928554413
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81514400654284,
              21.016347928554413
            ],
            [
              105.81502204526083,
              21.016304351364354
            ],
            [
              105.81494877766225,
              21.016283535881747
            ],
            [
              105.8148978156882,
              21.016267261281424
            ],
            [
              105.81485355924251,
              21.016248482893708
            ],
            [
              105.81482807825097,
              21.016218437468957
            ],
            [
              105.8145510586873,
              21.01582427523499
            ],
            [
              105.81408343015369,
              21.01496998212532
            ],
            [
              105.81407404242657,
              21.01495621118903
            ],
            [
              105.81394596693953,
              21.01475903626975
            ],
            [
              105.81389347823098,
              21.014717839136164
            ],
            [
              105.8138084324693,
              21.014687134261543
            ],
            [
              105.81273308155149,
              21.012976007715036
            ],
            [
              105.81272445150856,
              21.01296293044594
            ],
            [
              105.81204766567157,
              21.011963232014548
            ],
            [
              105.81200548727601,
              21.01190240484365
            ],
            [
              105.81184972397146,
              21.011687830995577
            ],
            [
              105.81161806901622,
              21.01136871076307
            ],
            [
              105.81146834816204,
              21.01117219486058
            ],
            [
              105.81131916262436,
              21.010891737380486
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Láng Hạ",
          "maTaiSan": "04.O11.DOPP.1022",
          "duongKinh": 150,
          "chieudaiQL": 725.12,
          "chatLieu": "G",
          "from_latitude": 21.016347928554413,
          "to_latitude": 21.010891737380486,
          "to_longitude": 105.81131916262436,
          "from_longitude": 105.81514400654284
        }
      },
      {
        "id": 1021,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81514400654284,
            21.011190950566117,
            105.82173175483314,
            21.01634792855442
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81514400654284,
              21.01634792855442
            ],
            [
              105.81576482069666,
              21.01596024374477
            ],
            [
              105.81599613627604,
              21.01579708272512
            ],
            [
              105.81601557082914,
              21.015783375157447
            ],
            [
              105.81633282464395,
              21.015559595927613
            ],
            [
              105.81668051457834,
              21.015321224707005
            ],
            [
              105.81670312394718,
              21.015305850723273
            ],
            [
              105.81684630790124,
              21.01520737266947
            ],
            [
              105.81692838765628,
              21.015154485684175
            ],
            [
              105.81694515403248,
              21.015143678444545
            ],
            [
              105.81695377265959,
              21.015136907110183
            ],
            [
              105.8169626250183,
              21.015129955813954
            ],
            [
              105.81715044206621,
              21.014991675619164
            ],
            [
              105.81751732298886,
              21.014721562379957
            ],
            [
              105.81770891388024,
              21.014580504096724
            ],
            [
              105.81773796884303,
              21.01455911240901
            ],
            [
              105.8179391535822,
              21.01441098968581
            ],
            [
              105.8181211744724,
              21.01428634976813
            ],
            [
              105.81852755650672,
              21.01397500272496
            ],
            [
              105.81891452970363,
              21.013677703029202
            ],
            [
              105.81910296565326,
              21.013532936217622
            ],
            [
              105.81927190705544,
              21.013393312117017
            ],
            [
              105.81927638864539,
              21.01338960988561
            ],
            [
              105.81949120379163,
              21.01324591873559
            ],
            [
              105.81951999862663,
              21.013226659624074
            ],
            [
              105.819574760602,
              21.01318715513498
            ],
            [
              105.81980439479409,
              21.01302150611759
            ],
            [
              105.81994291307868,
              21.01291458930512
            ],
            [
              105.81996988987869,
              21.012893768168897
            ],
            [
              105.82021310866187,
              21.012706001760222
            ],
            [
              105.82034188214533,
              21.012602432906252
            ],
            [
              105.82034928236308,
              21.012596482894846
            ],
            [
              105.82042408861709,
              21.012533630622908
            ],
            [
              105.82051895962613,
              21.012453922179056
            ],
            [
              105.82057206963948,
              21.012400209452462
            ],
            [
              105.82057211078849,
              21.012400170415226
            ],
            [
              105.82074659257964,
              21.012223708965003
            ],
            [
              105.82089211681252,
              21.012055929774
            ],
            [
              105.82089295800316,
              21.01205496197221
            ],
            [
              105.82101567153691,
              21.011925940017075
            ],
            [
              105.8210234710509,
              21.01191773449884
            ],
            [
              105.82107010864722,
              21.011873812224973
            ],
            [
              105.82123669997245,
              21.01171691885275
            ],
            [
              105.82150120798302,
              21.011437947521216
            ],
            [
              105.82173175483314,
              21.011190950566117
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Thái Hà",
          "maTaiSan": "04.O11.DOPP.1023",
          "duongKinh": 150,
          "chieudaiQL": 895.52,
          "chatLieu": "G-DI",
          "from_latitude": 21.01634792855442,
          "to_latitude": 21.011190950566117,
          "to_longitude": 105.82173175483314,
          "from_longitude": 105.81514400654284
        }
      },
      {
        "id": 1022,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81720774844882,
            21.005892281000868,
            105.82257504057068,
            21.012160205854848
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82257504057068,
              21.005892281000868
            ],
            [
              105.82257259875232,
              21.00589305332029
            ],
            [
              105.82252852123428,
              21.00590524766349
            ],
            [
              105.82246226376624,
              21.005923579129956
            ],
            [
              105.8224403540411,
              21.005929879343192
            ],
            [
              105.82230173998045,
              21.005969738368023
            ],
            [
              105.82229793178152,
              21.005972348127838
            ],
            [
              105.82228683299601,
              21.005979953638697
            ],
            [
              105.82226801240502,
              21.00599987402997
            ],
            [
              105.82221350152237,
              21.006057570924025
            ],
            [
              105.82219316225441,
              21.00607909826049
            ],
            [
              105.82214436444222,
              21.00613074752107
            ],
            [
              105.82205625489716,
              21.00622265654622
            ],
            [
              105.82202571062585,
              21.00625464917723
            ],
            [
              105.82196379076105,
              21.006323862707553
            ],
            [
              105.82184515299409,
              21.006467962143898
            ],
            [
              105.82171758218712,
              21.006615431220663
            ],
            [
              105.82168418773999,
              21.00665392905217
            ],
            [
              105.82150673098702,
              21.006861224757422
            ],
            [
              105.8214499579399,
              21.00693259546358
            ],
            [
              105.82144552339736,
              21.006938170815282
            ],
            [
              105.82139972680389,
              21.006995742301964
            ],
            [
              105.82137518775173,
              21.00702659125445
            ],
            [
              105.82109351033863,
              21.00737671382636
            ],
            [
              105.82069140193181,
              21.007860797317402
            ],
            [
              105.82041279407693,
              21.0082021968988
            ],
            [
              105.82037433962373,
              21.008246541988758
            ],
            [
              105.82005248146389,
              21.008622225573596
            ],
            [
              105.81972037099465,
              21.00901943039851
            ],
            [
              105.81971323519961,
              21.009027976790204
            ],
            [
              105.8196599082738,
              21.009091837150525
            ],
            [
              105.81965351022673,
              21.00909949933924
            ],
            [
              105.8191123954626,
              21.00974750809206
            ],
            [
              105.81883451456572,
              21.01008027950156
            ],
            [
              105.81879615472387,
              21.010126974898572
            ],
            [
              105.81879609073636,
              21.01012705920666
            ],
            [
              105.81875643405903,
              21.010179717622794
            ],
            [
              105.81875629926205,
              21.01017987001366
            ],
            [
              105.81825540809615,
              21.010745941412534
            ],
            [
              105.81817797052473,
              21.010834821971784
            ],
            [
              105.81807783918156,
              21.010956810130814
            ],
            [
              105.81798070331264,
              21.011075138406373
            ],
            [
              105.81774064949839,
              21.011354609244883
            ],
            [
              105.81758337857384,
              21.01154414864786
            ],
            [
              105.81754202340797,
              21.011602293936242
            ],
            [
              105.81749957754026,
              21.011667349632535
            ],
            [
              105.81740184553739,
              21.011817139885316
            ],
            [
              105.81739368545398,
              21.01182964633991
            ],
            [
              105.81738539894616,
              21.011845255086055
            ],
            [
              105.81735930691934,
              21.011896973705596
            ],
            [
              105.81723826872627,
              21.01213688970808
            ],
            [
              105.81723750547583,
              21.01213769002096
            ],
            [
              105.81721746898975,
              21.01215875017405
            ],
            [
              105.81721509136386,
              21.012160205854848
            ],
            [
              105.81720774844882,
              21.012153738784008
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D200_Thái Thịnh",
          "maTaiSan": "04.O11.DOPP.1024",
          "duongKinh": 200,
          "chieudaiQL": 892.27,
          "chatLieu": "G",
          "from_latitude": 21.005892281000868,
          "to_latitude": 21.012153738784008,
          "to_longitude": 105.81720774844882,
          "from_longitude": 105.82257504057068
        }
      },
      {
        "id": 1023,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81371582761106,
            21.014771043807972,
            105.81393142473868,
            21.014914213407902
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81371582761106,
              21.014914213407902
            ],
            [
              105.81383111292116,
              21.01483765533173
            ],
            [
              105.81389817694887,
              21.014793124211167
            ],
            [
              105.81392788794531,
              21.014773392863212
            ],
            [
              105.81393142473868,
              21.014771043807972
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Láng Hạ",
          "maTaiSan": "04.O11.DOPP.1025",
          "duongKinh": 150,
          "chieudaiQL": 25.6,
          "chatLieu": "G",
          "from_latitude": 21.014914213407902,
          "to_latitude": 21.014771043807972,
          "to_longitude": 105.81393142473868,
          "from_longitude": 105.81371582761106
        }
      },
      {
        "id": 1024,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.816577885928,
            21.013465701237003,
            105.81816989812077,
            21.01467158576311
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81816989812077,
              21.013465701237003
            ],
            [
              105.81810693292138,
              21.01350760215392
            ],
            [
              105.81802850601305,
              21.01355979469797
            ],
            [
              105.81800825400332,
              21.013573272480137
            ],
            [
              105.81771954790743,
              21.01376540298197
            ],
            [
              105.81770702338163,
              21.013774184741955
            ],
            [
              105.81770055891984,
              21.01377871659367
            ],
            [
              105.81764981203249,
              21.013814294741636
            ],
            [
              105.81745948286215,
              21.01397472201945
            ],
            [
              105.81743379285331,
              21.01399594755638
            ],
            [
              105.81741013397033,
              21.014015499586005
            ],
            [
              105.81725928998611,
              21.014140154289844
            ],
            [
              105.81719446351185,
              21.01418940525601
            ],
            [
              105.81707262709565,
              21.014294619399493
            ],
            [
              105.81692462221446,
              21.014413276300036
            ],
            [
              105.8167295636816,
              21.01456099721627
            ],
            [
              105.81667481208704,
              21.01460246438536
            ],
            [
              105.81662654167003,
              21.014635571765936
            ],
            [
              105.81660336289784,
              21.01465147053668
            ],
            [
              105.81659928546435,
              21.014654688436586
            ],
            [
              105.816577885928,
              21.01467158576311
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 151A, Thái Hà",
          "maTaiSan": "04.O11.DOPP.1026",
          "duongKinh": 100,
          "chieudaiQL": 217.61,
          "chatLieu": "G-DI",
          "from_latitude": 21.013465701237003,
          "to_latitude": 21.01467158576311,
          "to_longitude": 105.816577885928,
          "from_longitude": 105.81816989812077
        }
      },
      {
        "id": 1025,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.816577885928,
            21.01467158576311,
            105.81695377265959,
            21.015136907110183
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.816577885928,
              21.01467158576311
            ],
            [
              105.8166502609572,
              21.014759770953447
            ],
            [
              105.81695043912435,
              21.0151329434964
            ],
            [
              105.81695377265959,
              21.015136907110183
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Ngõ 165, Thái Hà",
          "maTaiSan": "04.O11.DOPP.1027",
          "duongKinh": 150,
          "chieudaiQL": 62.3,
          "chatLieu": "G-DI",
          "from_latitude": 21.01467158576311,
          "to_latitude": 21.015136907110183,
          "to_longitude": 105.81695377265959,
          "from_longitude": 105.816577885928
        }
      },
      {
        "id": 1026,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81492428593064,
            21.01397393783628,
            105.81576482069666,
            21.01596024374477
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81492428593064,
              21.01397393783628
            ],
            [
              105.81493120234083,
              21.013980868597
            ],
            [
              105.81520066620665,
              21.01430558341929
            ],
            [
              105.81535097176305,
              21.01451297503152
            ],
            [
              105.81537126393899,
              21.014540972689076
            ],
            [
              105.81544679276527,
              21.014958350907857
            ],
            [
              105.81544831610076,
              21.01496677614222
            ],
            [
              105.8154466277803,
              21.015266499633235
            ],
            [
              105.81544588096514,
              21.015399143127418
            ],
            [
              105.81544525139539,
              21.015511063301258
            ],
            [
              105.81558030183895,
              21.01571697781887
            ],
            [
              105.81561662230605,
              21.015769782524266
            ],
            [
              105.81576439382303,
              21.015959578306358
            ],
            [
              105.81576482069666,
              21.01596024374477
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Thái Hà",
          "maTaiSan": "04.O11.DOPP.1028",
          "duongKinh": 100,
          "chieudaiQL": 241.35,
          "chatLieu": "G",
          "from_latitude": 21.01397393783628,
          "to_latitude": 21.01596024374477,
          "to_longitude": 105.81576482069666,
          "from_longitude": 105.81492428593064
        }
      },
      {
        "id": 1027,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81393144968465,
            21.014771031946637,
            105.81407915044181,
            21.01497778524165
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81393144968465,
              21.014771031946637
            ],
            [
              105.81395636046533,
              21.014814433057474
            ],
            [
              105.81405587456881,
              21.01497778524165
            ],
            [
              105.81406560866955,
              21.014972168432998
            ],
            [
              105.81407915044181,
              21.014963704179955
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Láng Hạ",
          "maTaiSan": "04.O11.DOPP.1029",
          "duongKinh": 150,
          "chieudaiQL": 35.68,
          "chatLieu": "G",
          "from_latitude": 21.014771031946637,
          "to_latitude": 21.014963704179955,
          "to_longitude": 105.81407915044181,
          "from_longitude": 105.81393144968465
        }
      },
      {
        "id": 1028,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81433574548164,
            21.00896027012521,
            105.81718753804375,
            21.012223574075257
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81718753804375,
              21.012223574075257
            ],
            [
              105.81718595336204,
              21.012220851228083
            ],
            [
              105.81718248301758,
              21.01221489394576
            ],
            [
              105.817055575526,
              21.011996107523785
            ],
            [
              105.81690839019768,
              21.011820233158794
            ],
            [
              105.8167455847345,
              21.01162457706564
            ],
            [
              105.81660457244762,
              21.011434688099264
            ],
            [
              105.81653202204267,
              21.011339593956972
            ],
            [
              105.81637007225905,
              21.011139783025964
            ],
            [
              105.81610418978723,
              21.01081782829396
            ],
            [
              105.815882412223,
              21.01053077825071
            ],
            [
              105.8157049885655,
              21.010306638291077
            ],
            [
              105.81567119734322,
              21.010260555882297
            ],
            [
              105.8154734025142,
              21.01003152567486
            ],
            [
              105.81544929049483,
              21.009999674163314
            ],
            [
              105.81544375648606,
              21.00999238543567
            ],
            [
              105.81542335913345,
              21.009965449552748
            ],
            [
              105.81522326778105,
              21.009771244971606
            ],
            [
              105.81515409151477,
              21.00971120908433
            ],
            [
              105.81515248235895,
              21.009709812272323
            ],
            [
              105.81505826896132,
              21.00962802459818
            ],
            [
              105.81495727869746,
              21.009537135876407
            ],
            [
              105.81491850838269,
              21.00949995678797
            ],
            [
              105.81489534924326,
              21.009477741688787
            ],
            [
              105.81485356489094,
              21.009437648750744
            ],
            [
              105.81470244754027,
              21.009304270856617
            ],
            [
              105.81460308479002,
              21.00922516938009
            ],
            [
              105.81457451217285,
              21.009202422855928
            ],
            [
              105.81447801046319,
              21.00910652742431
            ],
            [
              105.81438739875097,
              21.009013499497314
            ],
            [
              105.81433574548164,
              21.00896027012521
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D160_Yên Lãng",
          "maTaiSan": "04.O11.DOPP.1030",
          "duongKinh": 160,
          "chieudaiQL": 462.42,
          "chatLieu": "HDPE",
          "from_latitude": 21.012223574075257,
          "to_latitude": 21.00896027012521,
          "to_longitude": 105.81433574548164,
          "from_longitude": 105.81718753804375
        }
      },
      {
        "id": 1029,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81431894627785,
            21.01081259511204,
            105.8151975799179,
            21.01196624367097
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8151975799179,
              21.01196624367097
            ],
            [
              105.8151883059167,
              21.01195488394213
            ],
            [
              105.81514176838621,
              21.011897876543117
            ],
            [
              105.81509898038395,
              21.011845462148877
            ],
            [
              105.81501375719941,
              21.011730840639846
            ],
            [
              105.8149541997231,
              21.011658500020243
            ],
            [
              105.81484261348484,
              21.011522962168428
            ],
            [
              105.81472414361703,
              21.011369286046303
            ],
            [
              105.81464288775628,
              21.011256936824438
            ],
            [
              105.81450879217424,
              21.01107174312232
            ],
            [
              105.81443144051921,
              21.0109596532926
            ],
            [
              105.8144117571685,
              21.01093488523387
            ],
            [
              105.81432024423677,
              21.0108144305835
            ],
            [
              105.81431894627785,
              21.01081259511204
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D90_Ngõ 82, Yên Lãng",
          "maTaiSan": "04.O11.DOPP.1031",
          "duongKinh": 90,
          "chieudaiQL": 158.44,
          "chatLieu": "G",
          "from_latitude": 21.01196624367097,
          "to_latitude": 21.01081259511204,
          "to_longitude": 105.81431894627785,
          "from_longitude": 105.8151975799179
        }
      },
      {
        "id": 1030,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81483689002378,
            21.010428044607927,
            105.81528599937683,
            21.010971993431934
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81483689002378,
              21.010428044607927
            ],
            [
              105.81497554107682,
              21.01059076379962
            ],
            [
              105.81497619337314,
              21.01059238016409
            ],
            [
              105.81504104349712,
              21.01067528941176
            ],
            [
              105.81505939819657,
              21.01069751936618
            ],
            [
              105.81506103458906,
              21.01069950134018
            ],
            [
              105.81513866748993,
              21.010793541220764
            ],
            [
              105.81528599937683,
              21.010971993431934
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D90_Ngõ 26/15, Thái Thịnh 2",
          "maTaiSan": "04.O11.DOPP.1032",
          "duongKinh": 90,
          "chieudaiQL": 76.2,
          "chatLieu": "HDPE",
          "from_latitude": 21.010428044607927,
          "to_latitude": 21.010971993431934,
          "to_longitude": 105.81528599937683,
          "from_longitude": 105.81483689002378
        }
      },
      {
        "id": 1031,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81460227548953,
            21.00971120908433,
            105.81515409151477,
            21.009957517984734
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81460227548953,
              21.00982466414376
            ],
            [
              105.81476382037292,
              21.009957517984734
            ],
            [
              105.81483966886529,
              21.00989808907642
            ],
            [
              105.81487738797763,
              21.00991526760427
            ],
            [
              105.81513258351558,
              21.0097230965346
            ],
            [
              105.81515409151477,
              21.00971120908433
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D90_Ngõ 142, Yên Lãng",
          "maTaiSan": "04.O11.DOPP.1033",
          "duongKinh": 90,
          "chieudaiQL": 72.96,
          "chatLieu": "HDPE",
          "from_latitude": 21.00982466414376,
          "to_latitude": 21.00971120908433,
          "to_longitude": 105.81515409151477,
          "from_longitude": 105.81460227548953
        }
      },
      {
        "id": 1032,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81433574548164,
            21.003430495292537,
            105.82060208080564,
            21.00896027012521
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82060208080564,
              21.003652516212256
            ],
            [
              105.82055408373748,
              21.003630499290377
            ],
            [
              105.82037908934073,
              21.00357807494302
            ],
            [
              105.81969710808696,
              21.003430495292537
            ],
            [
              105.81954255030641,
              21.003465695901237
            ],
            [
              105.81945055589051,
              21.003486647525975
            ],
            [
              105.8189650721574,
              21.003730530012817
            ],
            [
              105.81886935977441,
              21.003778610524932
            ],
            [
              105.81882601187236,
              21.00381817634501
            ],
            [
              105.81873834741255,
              21.003898191454276
            ],
            [
              105.81833503743864,
              21.004266308888834
            ],
            [
              105.8183181709504,
              21.004277217885075
            ],
            [
              105.81810262050953,
              21.00441663050798
            ],
            [
              105.81810259556565,
              21.00441664236984
            ],
            [
              105.81762827600481,
              21.004723418016358
            ],
            [
              105.81749623112572,
              21.00480881939744
            ],
            [
              105.81747020094923,
              21.00484095123105
            ],
            [
              105.81720448071513,
              21.00517947117095
            ],
            [
              105.81717785439486,
              21.005217318745405
            ],
            [
              105.81696585799473,
              21.005518660003464
            ],
            [
              105.81695340092419,
              21.005537598180364
            ],
            [
              105.816868257142,
              21.005667033969594
            ],
            [
              105.81678539514212,
              21.005793000305403
            ],
            [
              105.81669470746242,
              21.005930862644327
            ],
            [
              105.81663039716997,
              21.006006142823423
            ],
            [
              105.81662868849523,
              21.006008901353276
            ],
            [
              105.81654877237231,
              21.006137877454062
            ],
            [
              105.816546802262,
              21.006140672463378
            ],
            [
              105.81651828621486,
              21.00618112307903
            ],
            [
              105.81645043077724,
              21.00627807149531
            ],
            [
              105.8163835789084,
              21.0063878264668
            ],
            [
              105.81627725941684,
              21.006553886504722
            ],
            [
              105.81629156028755,
              21.0065737973301
            ],
            [
              105.81628186889873,
              21.006589102957932
            ],
            [
              105.81628089022242,
              21.00659043630129
            ],
            [
              105.81627950956684,
              21.006592316219542
            ],
            [
              105.81625447714403,
              21.00658516800042
            ],
            [
              105.81625332382822,
              21.006586755988486
            ],
            [
              105.81599334332384,
              21.006956613023956
            ],
            [
              105.81580037732938,
              21.007231132412265
            ],
            [
              105.81569866579649,
              21.00738742598762
            ],
            [
              105.81569772594138,
              21.007390240436713
            ],
            [
              105.81569692992767,
              21.00739261974337
            ],
            [
              105.81550008989753,
              21.007630076227784
            ],
            [
              105.81543871588359,
              21.007704113517327
            ],
            [
              105.81535117347204,
              21.007716345386275
            ],
            [
              105.8153114851171,
              21.007765590783812
            ],
            [
              105.81531142970192,
              21.007765659694677
            ],
            [
              105.81529617405968,
              21.007784589486402
            ],
            [
              105.8152210461293,
              21.00787780671724
            ],
            [
              105.8152142977891,
              21.00788618036074
            ],
            [
              105.81524502595141,
              21.00792488192893
            ],
            [
              105.81518900679613,
              21.00799082132564
            ],
            [
              105.81498388191646,
              21.00823227397164
            ],
            [
              105.81496894014123,
              21.008249856421642
            ],
            [
              105.81471395340483,
              21.008543143570154
            ],
            [
              105.81451002468553,
              21.00877770463285
            ],
            [
              105.81449790585509,
              21.00879331713853
            ],
            [
              105.81442672494612,
              21.0088850103651
            ],
            [
              105.81441892466123,
              21.00889506359904
            ],
            [
              105.8144058843486,
              21.008913163484216
            ],
            [
              105.81433574548164,
              21.00896027012521
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Đường Láng",
          "maTaiSan": "04.O11.DOPP.1034",
          "duongKinh": 150,
          "chieudaiQL": 952.3,
          "chatLieu": "G",
          "from_latitude": 21.003652516212256,
          "to_latitude": 21.00896027012521,
          "to_longitude": 105.81433574548164,
          "from_longitude": 105.82060208080564
        }
      },
      {
        "id": 1033,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81272445150856,
            21.01217623016128,
            105.8140859471754,
            21.012962930445937
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81272445150856,
              21.012962930445937
            ],
            [
              105.81274993231146,
              21.012949690240685
            ],
            [
              105.81291072345564,
              21.012863573330964
            ],
            [
              105.81317296528421,
              21.01272312096615
            ],
            [
              105.81321919442323,
              21.01269794833419
            ],
            [
              105.81348072792173,
              21.012559312576514
            ],
            [
              105.81355114869588,
              21.012513637425673
            ],
            [
              105.81379376963021,
              21.012356273766446
            ],
            [
              105.81389750384545,
              21.012290823730726
            ],
            [
              105.81396447676472,
              21.012250097437164
            ],
            [
              105.81405906657325,
              21.012192576234668
            ],
            [
              105.8140859471754,
              21.01217623016128
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 105, Láng Hạ",
          "maTaiSan": "04.O11.DOPP.1035",
          "duongKinh": 100,
          "chieudaiQL": 166.85,
          "chatLieu": "G",
          "from_latitude": 21.012962930445937,
          "to_latitude": 21.01217623016128,
          "to_longitude": 105.8140859471754,
          "from_longitude": 105.81272445150856
        }
      },
      {
        "id": 1034,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81264134077769,
            21.012077605167306,
            105.81317296528421,
            21.01272312096615
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81317296528421,
              21.01272312096615
            ],
            [
              105.81317256517775,
              21.012717984411324
            ],
            [
              105.81317110223638,
              21.012699199733763
            ],
            [
              105.81316844927161,
              21.012665129987838
            ],
            [
              105.81305975418464,
              21.012547301194733
            ],
            [
              105.81293268350076,
              21.012409551800882
            ],
            [
              105.8129210777238,
              21.012373476299693
            ],
            [
              105.81291885773909,
              21.01236657630403
            ],
            [
              105.81291705938219,
              21.012364703470766
            ],
            [
              105.81280490362097,
              21.01224791981032
            ],
            [
              105.81270934237833,
              21.012148414153845
            ],
            [
              105.81264473238701,
              21.012081135948083
            ],
            [
              105.81264134077769,
              21.012077605167306
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngách 105/8, Láng Hạ",
          "maTaiSan": "04.O11.DOPP.1036",
          "duongKinh": 100,
          "chieudaiQL": 92.09,
          "chatLieu": "G",
          "from_latitude": 21.01272312096615,
          "to_latitude": 21.012077605167306,
          "to_longitude": 105.81264134077769,
          "from_longitude": 105.81317296528421
        }
      },
      {
        "id": 1035,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81191972030223,
            21.011072239189694,
            105.81255397105438,
            21.011700435788505
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81191972030223,
              21.011072239189694
            ],
            [
              105.81192454772419,
              21.011076606751097
            ],
            [
              105.8119665677894,
              21.011114628181474
            ],
            [
              105.81199645715418,
              21.0111423320987
            ],
            [
              105.81207940074519,
              21.011222096930144
            ],
            [
              105.81228088832195,
              21.01141586321471
            ],
            [
              105.81247577671535,
              21.011587070098223
            ],
            [
              105.81248554429156,
              21.011595650196586
            ],
            [
              105.81248799394523,
              21.011613171072725
            ],
            [
              105.81248999228065,
              21.011627461448175
            ],
            [
              105.81255397105438,
              21.011700435788505
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 151, Láng Hạ",
          "maTaiSan": "04.O11.DOPP.1037",
          "duongKinh": 100,
          "chieudaiQL": 96.61,
          "chatLieu": "G",
          "from_latitude": 21.011072239189694,
          "to_latitude": 21.011700435788505,
          "to_longitude": 105.81255397105438,
          "from_longitude": 105.81191972030223
        }
      },
      {
        "id": 1036,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81161804121913,
            21.009432123189356,
            105.81362785702622,
            21.01136872896023
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81362176831682,
              21.009432123189356
            ],
            [
              105.81362444433356,
              21.00943797867207
            ],
            [
              105.81362684583473,
              21.009443233013656
            ],
            [
              105.81362785702622,
              21.0094771570646
            ],
            [
              105.81357257970832,
              21.009596236580812
            ],
            [
              105.81356300232852,
              21.009606931436895
            ],
            [
              105.8135159947842,
              21.009658845242587
            ],
            [
              105.81349402184004,
              21.009683111655814
            ],
            [
              105.81343652172721,
              21.0097355416662
            ],
            [
              105.81326148881091,
              21.009895381762174
            ],
            [
              105.81315766432932,
              21.00998652777718
            ],
            [
              105.81308215336186,
              21.010052817977673
            ],
            [
              105.81294074442926,
              21.010195324906977
            ],
            [
              105.81290901242649,
              21.010232131331264
            ],
            [
              105.81281228592347,
              21.010344324596428
            ],
            [
              105.81275005034223,
              21.010414602224913
            ],
            [
              105.81272759818148,
              21.0104299984157
            ],
            [
              105.8127274697491,
              21.010430083932626
            ],
            [
              105.81264171936452,
              21.010487409530086
            ],
            [
              105.81261573893455,
              21.01050547533946
            ],
            [
              105.8125193343665,
              21.010575589089274
            ],
            [
              105.81243232728602,
              21.010646008371502
            ],
            [
              105.8123220097139,
              21.010736595089625
            ],
            [
              105.81229053017312,
              21.010751323478548
            ],
            [
              105.812272847102,
              21.01072870523274
            ],
            [
              105.81223648439591,
              21.010750801978894
            ],
            [
              105.81212490998482,
              21.010813772930337
            ],
            [
              105.81215517405543,
              21.010867701186793
            ],
            [
              105.81198662757348,
              21.01100853583967
            ],
            [
              105.81193445037606,
              21.011057190659404
            ],
            [
              105.81192686550676,
              21.01106426390758
            ],
            [
              105.81191972030223,
              21.011072239189694
            ],
            [
              105.81180644770319,
              21.01119866716395
            ],
            [
              105.81180126108985,
              21.011203825861667
            ],
            [
              105.81169749620025,
              21.01130702884321
            ],
            [
              105.81169367775465,
              21.01131082707113
            ],
            [
              105.81163748177961,
              21.011353846761192
            ],
            [
              105.81161806901622,
              21.011368710763062
            ],
            [
              105.81161804121913,
              21.01136872896023
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Ngõ 426, Đường Láng",
          "maTaiSan": "04.O11.DOPP.1038",
          "duongKinh": 150,
          "chieudaiQL": 312.33,
          "chatLieu": "G",
          "from_latitude": 21.009432123189356,
          "to_latitude": 21.01136872896023,
          "to_longitude": 105.81161804121913,
          "from_longitude": 105.81362176831682
        }
      },
      {
        "id": 1037,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81229053017312,
            21.010751323478548,
            105.81364393157271,
            21.011478878803985
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81229053017312,
              21.010751323478548
            ],
            [
              105.81229277000968,
              21.01075408298928
            ],
            [
              105.81241246827442,
              21.010863011786835
            ],
            [
              105.8124942182937,
              21.010919882555246
            ],
            [
              105.81250823592279,
              21.010929242621327
            ],
            [
              105.81252921216817,
              21.0109432490868
            ],
            [
              105.81259611091762,
              21.01098512486266
            ],
            [
              105.81261067552782,
              21.010994241145706
            ],
            [
              105.81269467891201,
              21.011044264513174
            ],
            [
              105.81272094660922,
              21.011054176635856
            ],
            [
              105.8128643724143,
              21.01109892304256
            ],
            [
              105.81288478115819,
              21.011105289955076
            ],
            [
              105.8128883809425,
              21.011106412632785
            ],
            [
              105.8128957395015,
              21.011108708712474
            ],
            [
              105.81289945876541,
              21.011109868754755
            ],
            [
              105.81297992964427,
              21.01114594945234
            ],
            [
              105.81309428707006,
              21.011222725738342
            ],
            [
              105.81311474307246,
              21.011239937463337
            ],
            [
              105.8131343978777,
              21.011249885636495
            ],
            [
              105.81326027761175,
              21.011313596638008
            ],
            [
              105.81329591600462,
              21.011328946480027
            ],
            [
              105.81337354279557,
              21.011362380570066
            ],
            [
              105.81355874105668,
              21.011443478547424
            ],
            [
              105.81357580456108,
              21.01145095066265
            ],
            [
              105.81364393157271,
              21.011478878803985
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 508, Đường Láng",
          "maTaiSan": "04.O11.DOPP.1039",
          "duongKinh": 100,
          "chieudaiQL": 161.52,
          "chatLieu": "G",
          "from_latitude": 21.010751323478548,
          "to_latitude": 21.011478878803985,
          "to_longitude": 105.81364393157271,
          "from_longitude": 105.81229053017312
        }
      },
      {
        "id": 1038,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81344266861818,
            21.011140881067274,
            105.81386143842488,
            21.011819186758014
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81386143842488,
              21.011140881067274
            ],
            [
              105.81375930747691,
              21.011306878030403
            ],
            [
              105.81364393157271,
              21.011478878803985
            ],
            [
              105.81362618469196,
              21.01153128227526
            ],
            [
              105.81359135613623,
              21.011585816846267
            ],
            [
              105.81356243740329,
              21.011631098480766
            ],
            [
              105.8135145555486,
              21.011697329031772
            ],
            [
              105.81345063194763,
              21.011804570579244
            ],
            [
              105.81345001857223,
              21.011805695321456
            ],
            [
              105.8134478632139,
              21.01180965273428
            ],
            [
              105.81344403726588,
              21.011816673755767
            ],
            [
              105.81344266861818,
              21.011819186758014
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 426/48, Đường Láng",
          "maTaiSan": "04.O11.DOPP.1040",
          "duongKinh": 100,
          "chieudaiQL": 88.08,
          "chatLieu": "G",
          "from_latitude": 21.011140881067274,
          "to_latitude": 21.011819186758014,
          "to_longitude": 105.81344266861818,
          "from_longitude": 105.81386143842488
        }
      },
      {
        "id": 1039,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81315766432932,
            21.00998652777718,
            105.81396503476492,
            21.010511882966597
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81315766432932,
              21.00998652777718
            ],
            [
              105.8131695932984,
              21.00999352228018
            ],
            [
              105.81321586767228,
              21.010025120087466
            ],
            [
              105.81347713611225,
              21.010186527437725
            ],
            [
              105.81355107137053,
              21.010227209459703
            ],
            [
              105.81361681963827,
              21.01027511848725
            ],
            [
              105.81365153519216,
              21.010299485993905
            ],
            [
              105.81367900502583,
              21.01031876683371
            ],
            [
              105.81376884859775,
              21.010381828757865
            ],
            [
              105.81382528853574,
              21.010427060787027
            ],
            [
              105.81396503476492,
              21.010511882966597
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 426/14, Đường Láng",
          "maTaiSan": "04.O11.DOPP.1041",
          "duongKinh": 100,
          "chieudaiQL": 102.07,
          "chatLieu": "G",
          "from_latitude": 21.00998652777718,
          "to_latitude": 21.010511882966597,
          "to_longitude": 105.81396503476492,
          "from_longitude": 105.81315766432932
        }
      },
      {
        "id": 1040,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8149541997231,
            21.01081782829396,
            105.81610418978723,
            21.011658500020243
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81610418978723,
              21.01081782829396
            ],
            [
              105.81575240881067,
              21.011068826873196
            ],
            [
              105.81553350372884,
              21.011245403236106
            ],
            [
              105.81551910172875,
              21.011258274618925
            ],
            [
              105.81550798791619,
              21.01126820649152
            ],
            [
              105.81550645634425,
              21.01126940699636
            ],
            [
              105.8154815403371,
              21.01128894762249
            ],
            [
              105.8154099580589,
              21.011340513644782
            ],
            [
              105.81537672904419,
              21.011362861584058
            ],
            [
              105.81519440583438,
              21.01148548040129
            ],
            [
              105.81518766149438,
              21.011490016208597
            ],
            [
              105.8151861507013,
              21.011493448488242
            ],
            [
              105.8151820605352,
              21.011493782406834
            ],
            [
              105.81514602641658,
              21.011519830719084
            ],
            [
              105.81513682050635,
              21.01152648554839
            ],
            [
              105.81496458321976,
              21.011650994138655
            ],
            [
              105.8149541997231,
              21.011658500020243
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D90_Ngõ 100, Yên Lãng",
          "maTaiSan": "04.O11.DOPP.1042",
          "duongKinh": 90,
          "chieudaiQL": 151.88,
          "chatLieu": "PVC",
          "from_latitude": 21.01081782829396,
          "to_latitude": 21.011658500020243,
          "to_longitude": 105.8149541997231,
          "from_longitude": 105.81610418978723
        }
      },
      {
        "id": 1041,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81450879217424,
            21.010675301273213,
            105.81504562036277,
            21.01107174312232
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81504101855192,
              21.010675301273213
            ],
            [
              105.81503891826316,
              21.010676813402004
            ],
            [
              105.81503716003704,
              21.01067807911747
            ],
            [
              105.81504562036277,
              21.010689651401876
            ],
            [
              105.81450879217424,
              21.01107174312232
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D90_Ngõ 104, Yên Lãng",
          "maTaiSan": "04.O11.DOPP.1043",
          "duongKinh": 90,
          "chieudaiQL": 70.63,
          "chatLieu": "HDPE",
          "from_latitude": 21.010675301273213,
          "to_latitude": 21.01107174312232,
          "to_longitude": 105.81450879217424,
          "from_longitude": 105.81504101855192
        }
      },
      {
        "id": 1042,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82065149148984,
            21.010029180520874,
            105.82173175483314,
            21.011190950566117
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82173175483314,
              21.011190950566117
            ],
            [
              105.82171313164469,
              21.01117152175999
            ],
            [
              105.82160034949223,
              21.0110538194594
            ],
            [
              105.8213550849335,
              21.010790135723713
            ],
            [
              105.82122319178914,
              21.010668847456053
            ],
            [
              105.82121333705905,
              21.01065637176847
            ],
            [
              105.82121326632759,
              21.01065627817417
            ],
            [
              105.82111025455313,
              21.010536553008862
            ],
            [
              105.82108941116562,
              21.01051232696238
            ],
            [
              105.82099356585697,
              21.01040263451145
            ],
            [
              105.82098574080754,
              21.010393678829438
            ],
            [
              105.82088161954232,
              21.010274514349724
            ],
            [
              105.82084036027992,
              21.0102201340546
            ],
            [
              105.82079880698116,
              21.010167562524813
            ],
            [
              105.82065454805411,
              21.010032455334777
            ],
            [
              105.82065386688562,
              21.010031726102298
            ],
            [
              105.82065346340632,
              21.010031293595713
            ],
            [
              105.82065274739779,
              21.010030525692468
            ],
            [
              105.82065149148984,
              21.010029180520874
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 41, Thái Hà",
          "maTaiSan": "04.O11.DOPP.1045",
          "duongKinh": 100,
          "chieudaiQL": 171.06,
          "chatLieu": "G",
          "from_latitude": 21.011190950566117,
          "to_latitude": 21.010029180520874,
          "to_longitude": 105.82065149148984,
          "from_longitude": 105.82173175483314
        }
      },
      {
        "id": 1043,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81749957754026,
            21.011667349632535,
            105.81927190705544,
            21.013393312117017
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81749957754026,
              21.011667349632535
            ],
            [
              105.81774145252025,
              21.011838478100266
            ],
            [
              105.81787922229707,
              21.011935951640094
            ],
            [
              105.81795497968254,
              21.011956904558208
            ],
            [
              105.8179587146655,
              21.011957937960645
            ],
            [
              105.81807857979815,
              21.01206921201128
            ],
            [
              105.81810156853166,
              21.01209018191445
            ],
            [
              105.81815219114108,
              21.01214535514565
            ],
            [
              105.8182008914497,
              21.012201684703264
            ],
            [
              105.81826405750289,
              21.012277308075415
            ],
            [
              105.81836754407344,
              21.012390262092268
            ],
            [
              105.81857284119984,
              21.012617253726866
            ],
            [
              105.81868118796733,
              21.01273868713775
            ],
            [
              105.81873211028665,
              21.01279705435723
            ],
            [
              105.81886613293995,
              21.012925184359688
            ],
            [
              105.81899644571524,
              21.013101244711624
            ],
            [
              105.81907226885549,
              21.01318537394113
            ],
            [
              105.81926928927275,
              21.013390423555606
            ],
            [
              105.81927190705544,
              21.013393312117017
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 133, Thái Hà",
          "maTaiSan": "04.O11.DOPP.1046",
          "duongKinh": 100,
          "chieudaiQL": 272.39,
          "chatLieu": "G-DI",
          "from_latitude": 21.011667349632535,
          "to_latitude": 21.013393312117017,
          "to_longitude": 105.81927190705544,
          "from_longitude": 105.81749957754026
        }
      },
      {
        "id": 1044,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82060208080564,
            21.003652516212256,
            105.82406778496143,
            21.008893927324287
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82060208080564,
              21.003652516212256
            ],
            [
              105.82060789585599,
              21.003656494897086
            ],
            [
              105.82062111907774,
              21.003665558265613
            ],
            [
              105.82079055891175,
              21.003760549384154
            ],
            [
              105.821133446812,
              21.004012326880126
            ],
            [
              105.82135451074541,
              21.004174650581643
            ],
            [
              105.8215853899005,
              21.004352586767382
            ],
            [
              105.82166502794666,
              21.00442572869715
            ],
            [
              105.82171509993354,
              21.00448722981904
            ],
            [
              105.8217502153258,
              21.004530360126168
            ],
            [
              105.82176491146564,
              21.004548410632985
            ],
            [
              105.82182807121347,
              21.004625987403877
            ],
            [
              105.8221212016267,
              21.00501598824172
            ],
            [
              105.82212920126193,
              21.005029815050456
            ],
            [
              105.82213266019878,
              21.005035793968506
            ],
            [
              105.82213845749057,
              21.00504581979365
            ],
            [
              105.82244320570041,
              21.00563057113824
            ],
            [
              105.8224465983167,
              21.005637080563886
            ],
            [
              105.82249646602078,
              21.00573277951689
            ],
            [
              105.82250922377186,
              21.005757246815783
            ],
            [
              105.82250923355869,
              21.005757277478217
            ],
            [
              105.82256209626259,
              21.005858701042637
            ],
            [
              105.8225675740891,
              21.005872904919148
            ],
            [
              105.82257504057068,
              21.005892281000868
            ],
            [
              105.82264284149021,
              21.0060681350655
            ],
            [
              105.82272499197245,
              21.006269797198037
            ],
            [
              105.8227965480945,
              21.006396819001285
            ],
            [
              105.82281298781771,
              21.006425984222712
            ],
            [
              105.8228148188797,
              21.00642925227961
            ],
            [
              105.8228192452532,
              21.006437110636462
            ],
            [
              105.82290771836861,
              21.006674156738484
            ],
            [
              105.82303377990273,
              21.00692797909952
            ],
            [
              105.82304619933203,
              21.006952984502725
            ],
            [
              105.82320476476792,
              21.00724474855194
            ],
            [
              105.82320662167483,
              21.007248165511314
            ],
            [
              105.8232479307695,
              21.007328113569486
            ],
            [
              105.82325236500508,
              21.007336694456725
            ],
            [
              105.82334535680486,
              21.007516663349065
            ],
            [
              105.82336543639646,
              21.00755421633067
            ],
            [
              105.8234692102811,
              21.007779232922466
            ],
            [
              105.82354920542737,
              21.007952689072834
            ],
            [
              105.82356605352975,
              21.00799153211759
            ],
            [
              105.82356714741023,
              21.007993498568947
            ],
            [
              105.82365564990907,
              21.008152788820276
            ],
            [
              105.8238138590526,
              21.008437539454917
            ],
            [
              105.82389406746961,
              21.008581900527254
            ],
            [
              105.82405519397173,
              21.008871898974526
            ],
            [
              105.82406778496143,
              21.008893927324287
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Tây Sơn",
          "maTaiSan": "04.O11.DOPP.1047",
          "duongKinh": 150,
          "chieudaiQL": 693.98,
          "chatLieu": "G",
          "from_latitude": 21.003652516212256,
          "to_latitude": 21.008893927324287,
          "to_longitude": 105.82406778496143,
          "from_longitude": 105.82060208080564
        }
      },
      {
        "id": 1045,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81807857979815,
            21.011920306097895,
            105.81949120379163,
            21.01324591873559
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81807857979815,
              21.01206921201128
            ],
            [
              105.8180836202423,
              21.012065154865265
            ],
            [
              105.81826355992979,
              21.011920306097895
            ],
            [
              105.81827810195584,
              21.011935256842378
            ],
            [
              105.81831857064446,
              21.011976861794082
            ],
            [
              105.8183222852339,
              21.011980680840836
            ],
            [
              105.8183305163338,
              21.011989142433915
            ],
            [
              105.81847340822785,
              21.01213605068678
            ],
            [
              105.81848794545682,
              21.012150996015507
            ],
            [
              105.8186115565336,
              21.012278079814735
            ],
            [
              105.81868525953757,
              21.012353853748287
            ],
            [
              105.81869728738471,
              21.01236584312555
            ],
            [
              105.818700110584,
              21.012368657547107
            ],
            [
              105.81870043272808,
              21.012368991997747
            ],
            [
              105.81876274783585,
              21.012433517698224
            ],
            [
              105.81886293097476,
              21.01253612637077
            ],
            [
              105.81887851788434,
              21.012551627515858
            ],
            [
              105.81903933192032,
              21.01271536193397
            ],
            [
              105.81908283614915,
              21.012759656339345
            ],
            [
              105.8192050080325,
              21.012888639763336
            ],
            [
              105.81921269578068,
              21.012896755280327
            ],
            [
              105.81921535349427,
              21.012899559650634
            ],
            [
              105.8192225004163,
              21.012907105124864
            ],
            [
              105.8193477859572,
              21.01303937474199
            ],
            [
              105.81945049232901,
              21.013179011340966
            ],
            [
              105.81946887988481,
              21.013231205197282
            ],
            [
              105.81947117057672,
              21.013232678167235
            ],
            [
              105.8194782193265,
              21.013237214548763
            ],
            [
              105.81949120379163,
              21.01324591873559
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 131, Thái Hà]",
          "maTaiSan": "04.O11.DOPP.1048",
          "duongKinh": 100,
          "chieudaiQL": 224.42,
          "chatLieu": "G-DI",
          "from_latitude": 21.01206921201128,
          "to_latitude": 21.01324591873559,
          "to_longitude": 105.81949120379163,
          "from_longitude": 105.81807857979815
        }
      },
      {
        "id": 1046,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8183222852339,
            21.011472991867667,
            105.82034188214533,
            21.012602432906252
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8183222852339,
              21.011980680840836
            ],
            [
              105.81843043216857,
              21.011884697652395
            ],
            [
              105.81844245465756,
              21.011874027802033
            ],
            [
              105.81847595180766,
              21.0118442977114
            ],
            [
              105.81849006838038,
              21.011828162289437
            ],
            [
              105.81858373674223,
              21.011720261015
            ],
            [
              105.8187104861932,
              21.01157588083458
            ],
            [
              105.81871907219185,
              21.011566178599068
            ],
            [
              105.81879108035996,
              21.011484803554247
            ],
            [
              105.8188016286807,
              21.011472991867667
            ],
            [
              105.81881189642512,
              21.011479918085957
            ],
            [
              105.81890328829651,
              21.01154398589092
            ],
            [
              105.8189309510445,
              21.01156337873295
            ],
            [
              105.81900119354215,
              21.011614077531934
            ],
            [
              105.81902612926021,
              21.011614435315717
            ],
            [
              105.81906606200904,
              21.01161388180784
            ],
            [
              105.81910372467104,
              21.011589901364648
            ],
            [
              105.81912083738762,
              21.011580614193
            ],
            [
              105.81923281222022,
              21.011485036602124
            ],
            [
              105.81930082295165,
              21.011534467953986
            ],
            [
              105.8193087739442,
              21.011540247358802
            ],
            [
              105.81932430218095,
              21.01155153338574
            ],
            [
              105.81935136723042,
              21.01157120452024
            ],
            [
              105.81947779918441,
              21.01169535487644
            ],
            [
              105.81956846087516,
              21.01179701364134
            ],
            [
              105.81958217888233,
              21.01181183004642
            ],
            [
              105.81976015534688,
              21.01200496337823
            ],
            [
              105.81994024076101,
              21.0121973863427
            ],
            [
              105.81994271057448,
              21.012198722959507
            ],
            [
              105.81996924527527,
              21.012225160814562
            ],
            [
              105.82014215972852,
              21.012408714087375
            ],
            [
              105.82021232521271,
              21.0124750900472
            ],
            [
              105.82033514763607,
              21.012596607946126
            ],
            [
              105.82034188214533,
              21.012602432906252
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 117, Thái Hà",
          "maTaiSan": "04.O11.DOPP.1049",
          "duongKinh": 100,
          "chieudaiQL": 302.59,
          "chatLieu": "G-DI",
          "from_latitude": 21.011980680840836,
          "to_latitude": 21.012602432906252,
          "to_longitude": 105.82034188214533,
          "from_longitude": 105.8183222852339
        }
      },
      {
        "id": 1047,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81825540809615,
            21.010745941412534,
            105.8185848429128,
            21.01093709310613
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81825540809615,
              21.010745941412534
            ],
            [
              105.81832882711636,
              21.01080456731844
            ],
            [
              105.81850091220433,
              21.01093709310613
            ],
            [
              105.81857547663697,
              21.010858127409495
            ],
            [
              105.8185848429128,
              21.01086732598504
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Thái Thịnh",
          "maTaiSan": "04.O11.DOPP.1050",
          "duongKinh": 100,
          "chieudaiQL": 45.19,
          "chatLieu": "G",
          "from_latitude": 21.010745941412534,
          "to_latitude": 21.01086732598504,
          "to_longitude": 105.8185848429128,
          "from_longitude": 105.81825540809615
        }
      },
      {
        "id": 1048,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81828005708546,
            21.010780349669183,
            105.8185848429128,
            21.010954972262866
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8185848429128,
              21.01086732598504
            ],
            [
              105.81850190047261,
              21.010954972262866
            ],
            [
              105.81828005708546,
              21.010780349669183
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Thái Thịnh",
          "maTaiSan": "04.O11.DOPP.1051",
          "duongKinh": 100,
          "chieudaiQL": 35.16,
          "chatLieu": "G",
          "from_latitude": 21.01086732598504,
          "to_latitude": 21.010780349669183,
          "to_longitude": 105.81828005708546,
          "from_longitude": 105.8185848429128
        }
      },
      {
        "id": 1049,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81763731830539,
            21.010780349669183,
            105.81828005708546,
            21.011534873443413
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81828005708546,
              21.010780349669183
            ],
            [
              105.81817827695174,
              21.01089983213043
            ],
            [
              105.81809508489744,
              21.010997493504295
            ],
            [
              105.81764510519567,
              21.011525732391664
            ],
            [
              105.81763731830539,
              21.011534873443413
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Thái Thịnh",
          "maTaiSan": "04.O11.DOPP.1052",
          "duongKinh": 100,
          "chieudaiQL": 106.97,
          "chatLieu": "G",
          "from_latitude": 21.010780349669183,
          "to_latitude": 21.011534873443413,
          "to_longitude": 105.81763731830539,
          "from_longitude": 105.81828005708546
        }
      },
      {
        "id": 1050,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81763731830539,
            21.011534873443413,
            105.81806914856476,
            21.011857240894717
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81763731830539,
              21.011534873443413
            ],
            [
              105.81767006425076,
              21.01155931911307
            ],
            [
              105.81806914856476,
              21.011857240894717
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Thái Thịnh",
          "maTaiSan": "04.O11.DOPP.1053",
          "duongKinh": 100,
          "chieudaiQL": 57.35,
          "chatLieu": "G",
          "from_latitude": 21.011534873443413,
          "to_latitude": 21.011857240894717,
          "to_longitude": 105.81806914856476,
          "from_longitude": 105.81763731830539
        }
      },
      {
        "id": 1051,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81930979561302,
            21.010673467568875,
            105.81972720001482,
            21.0110064177347
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81972720001482,
              21.010673467568875
            ],
            [
              105.81969426139491,
              21.01072029691698
            ],
            [
              105.8195982443656,
              21.010853352332347
            ],
            [
              105.81959083575535,
              21.010862862874426
            ],
            [
              105.81959080505402,
              21.01086287747395
            ],
            [
              105.81954381510903,
              21.01090096168106
            ],
            [
              105.81949063639807,
              21.01095228719154
            ],
            [
              105.81948696818915,
              21.010955903316514
            ],
            [
              105.8194424123777,
              21.0110064177347
            ],
            [
              105.81932792100163,
              21.01091692538686
            ],
            [
              105.81930979561302,
              21.010904922958005
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 136, Trung Liệt",
          "maTaiSan": "04.O11.DOPP.1054",
          "duongKinh": 100,
          "chieudaiQL": 65.01,
          "chatLieu": "G-DI",
          "from_latitude": 21.010673467568875,
          "to_latitude": 21.010904922958005,
          "to_longitude": 105.81930979561302,
          "from_longitude": 105.81972720001482
        }
      },
      {
        "id": 1052,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81879609073636,
            21.01012705920666,
            105.82101567153691,
            21.011925940017075
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81879609073636,
              21.01012705920666
            ],
            [
              105.81879670229205,
              21.01012754852316
            ],
            [
              105.81907854688642,
              21.01035236848294
            ],
            [
              105.81923635473437,
              21.01047665707339
            ],
            [
              105.81942395765624,
              21.010505086993387
            ],
            [
              105.81954680054373,
              21.010523704338624
            ],
            [
              105.81964241865505,
              21.010603075566564
            ],
            [
              105.81972720001482,
              21.010673467568875
            ],
            [
              105.81974930765156,
              21.010694967175283
            ],
            [
              105.81986002930635,
              21.010701009237373
            ],
            [
              105.81987527212132,
              21.01070196728079
            ],
            [
              105.81990561250248,
              21.010703883160136
            ],
            [
              105.81994802981588,
              21.01074684221112
            ],
            [
              105.82005332460356,
              21.010865723417766
            ],
            [
              105.82005765633548,
              21.010870346034633
            ],
            [
              105.82014564404679,
              21.010968885901498
            ],
            [
              105.82014566630836,
              21.010968911084547
            ],
            [
              105.82028385508677,
              21.011122663460092
            ],
            [
              105.8202838773484,
              21.01112268864314
            ],
            [
              105.82030570779617,
              21.011151169650233
            ],
            [
              105.82040940996562,
              21.011286361548233
            ],
            [
              105.82043061690071,
              21.011307586304735
            ],
            [
              105.8204381070767,
              21.011314932267844
            ],
            [
              105.8205795570627,
              21.011454032758454
            ],
            [
              105.82059418485899,
              21.01146845269245
            ],
            [
              105.82069247281237,
              21.011574262330274
            ],
            [
              105.82084540474278,
              21.01174009602129
            ],
            [
              105.82090333260578,
              21.011798011496605
            ],
            [
              105.82101241942007,
              21.011922270541696
            ],
            [
              105.82101567153691,
              21.011925940017075
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Trung Liệt",
          "maTaiSan": "04.O11.DOPP.1055",
          "duongKinh": 150,
          "chieudaiQL": 318.6,
          "chatLieu": "G-DI",
          "from_latitude": 21.01012705920666,
          "to_latitude": 21.011925940017075,
          "to_longitude": 105.82101567153691,
          "from_longitude": 105.81879609073636
        }
      },
      {
        "id": 1053,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81967110991289,
            21.011151169650233,
            105.82030570779617,
            21.011612719613414
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82030570779617,
              21.011151169650233
            ],
            [
              105.82030568478996,
              21.01115118421279
            ],
            [
              105.82030498479764,
              21.011151762943236
            ],
            [
              105.82028961850374,
              21.01116376775252
            ],
            [
              105.82026666342628,
              21.011181705412188
            ],
            [
              105.82016150229384,
              21.011260490530905
            ],
            [
              105.82007169339744,
              21.011314857810188
            ],
            [
              105.82007166942434,
              21.01131487147415
            ],
            [
              105.81998875457533,
              21.01136467941213
            ],
            [
              105.8199786415966,
              21.011372174353927
            ],
            [
              105.81967110991289,
              21.011612719613414
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 104, Trung Liệt",
          "maTaiSan": "04.O11.DOPP.1056",
          "duongKinh": 100,
          "chieudaiQL": 83.47,
          "chatLieu": "G-DI",
          "from_latitude": 21.011151169650233,
          "to_latitude": 21.011612719613414,
          "to_longitude": 105.81967110991289,
          "from_longitude": 105.82030570779617
        }
      },
      {
        "id": 1054,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81993882153769,
            21.011314911359648,
            105.82043813485775,
            21.01180623969557
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82043813485775,
              21.011314911359648
            ],
            [
              105.8204381070767,
              21.011314932267844
            ],
            [
              105.82043162530056,
              21.011321188564004
            ],
            [
              105.82043158791048,
              21.011321211324972
            ],
            [
              105.8203501963985,
              21.011377093521286
            ],
            [
              105.82032811956535,
              21.011417557060803
            ],
            [
              105.82018320550608,
              21.01154106475999
            ],
            [
              105.82014652872816,
              21.011572034375433
            ],
            [
              105.82009313588705,
              21.01161780083504
            ],
            [
              105.82006650112164,
              21.011641319182164
            ],
            [
              105.82006589035964,
              21.011641856836405
            ],
            [
              105.820040206998,
              21.01166450981851
            ],
            [
              105.8199499573781,
              21.011790706521477
            ],
            [
              105.81994785818723,
              21.01179364490063
            ],
            [
              105.81993888921481,
              21.011806126466006
            ],
            [
              105.81993886334251,
              21.01180614465523
            ],
            [
              105.81993882153769,
              21.01180623969557
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 98, Trung Liệt",
          "maTaiSan": "04.O11.DOPP.1057",
          "duongKinh": 100,
          "chieudaiQL": 76.43,
          "chatLieu": "G-DI",
          "from_latitude": 21.011314911359648,
          "to_latitude": 21.01180623969557,
          "to_longitude": 105.81993882153769,
          "from_longitude": 105.82043813485775
        }
      },
      {
        "id": 1055,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82043064184553,
            21.010656357205804,
            105.82121336006512,
            21.011307574442505
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82121336006512,
              21.010656357205804
            ],
            [
              105.82121333705905,
              21.01065637176847
            ],
            [
              105.821212673639,
              21.010656953035703
            ],
            [
              105.82111441026154,
              21.0107425934467
            ],
            [
              105.8210423819112,
              21.010811193382605
            ],
            [
              105.8210423569665,
              21.010811205244913
            ],
            [
              105.82085456134324,
              21.010989413910686
            ],
            [
              105.82085453639853,
              21.010989425772987
            ],
            [
              105.82085096514393,
              21.01099282378093
            ],
            [
              105.82082858245593,
              21.01101409437174
            ],
            [
              105.82082412655843,
              21.01101807742177
            ],
            [
              105.82063670742704,
              21.01117992027007
            ],
            [
              105.82062557570887,
              21.01118967913418
            ],
            [
              105.82045801726805,
              21.01129058280382
            ],
            [
              105.82045741043409,
              21.011290958762753
            ],
            [
              105.82045737208215,
              21.01129098152836
            ],
            [
              105.82044739342237,
              21.011297176105366
            ],
            [
              105.8204384788694,
              21.01130270980795
            ],
            [
              105.82043082590037,
              21.011307458845184
            ],
            [
              105.82043064184553,
              21.011307574442505
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 69, Trung Liệt",
          "maTaiSan": "04.O11.DOPP.1058",
          "duongKinh": 100,
          "chieudaiQL": 108.37,
          "chatLieu": "G-DI",
          "from_latitude": 21.010656357205804,
          "to_latitude": 21.011307574442505,
          "to_longitude": 105.82043064184553,
          "from_longitude": 105.82121336006512
        }
      },
      {
        "id": 1056,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8210508642609,
            21.00910969769954,
            105.82240824725847,
            21.01044674247429
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82240824725847,
              21.01044674247429
            ],
            [
              105.82240819214412,
              21.010446690353472
            ],
            [
              105.82240023362003,
              21.01043972248392
            ],
            [
              105.8223397041339,
              21.010386608994228
            ],
            [
              105.82218363121093,
              21.010208451010836
            ],
            [
              105.82200897475744,
              21.010018365202097
            ],
            [
              105.8220089582917,
              21.01001834450741
            ],
            [
              105.82200331282336,
              21.01001166170281
            ],
            [
              105.82191879720285,
              21.009911583192736
            ],
            [
              105.8218898061374,
              21.00988027284347
            ],
            [
              105.82188653556051,
              21.009876740763023
            ],
            [
              105.82186833934503,
              21.009857088614016
            ],
            [
              105.82186239521847,
              21.00985066918265
            ],
            [
              105.82175398631608,
              21.009733583498406
            ],
            [
              105.82168980822412,
              21.009646982157758
            ],
            [
              105.8216794953443,
              21.00963620496822
            ],
            [
              105.82166988652449,
              21.009626120766765
            ],
            [
              105.82160837564818,
              21.009561611197583
            ],
            [
              105.82148965377048,
              21.009471599687412
            ],
            [
              105.82141299300717,
              21.009399337614543
            ],
            [
              105.82142518421462,
              21.00935542978295
            ],
            [
              105.8214252030911,
              21.009355363756228
            ],
            [
              105.82142794525645,
              21.00934548637232
            ],
            [
              105.8212488177087,
              21.009146567855204
            ],
            [
              105.8212487688263,
              21.009146599704074
            ],
            [
              105.8211536604281,
              21.009217991838753
            ],
            [
              105.82105392149427,
              21.009112921019693
            ],
            [
              105.8210508642609,
              21.00910969769954
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 7, Thái Hà",
          "maTaiSan": "04.O11.DOPP.1059",
          "duongKinh": 100,
          "chieudaiQL": 221.67,
          "chatLieu": "G",
          "from_latitude": 21.01044674247429,
          "to_latitude": 21.00910969769954,
          "to_longitude": 105.8210508642609,
          "from_longitude": 105.82240824725847
        }
      },
      {
        "id": 1057,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82198200421716,
            21.007896257885168,
            105.82356714741023,
            21.008344069223178
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82356714741023,
              21.00799349856895
            ],
            [
              105.82356438676501,
              21.007994896576214
            ],
            [
              105.82351001730089,
              21.008022850393626
            ],
            [
              105.82339072464211,
              21.008109598640473
            ],
            [
              105.82333238253631,
              21.008155951826303
            ],
            [
              105.82332032746598,
              21.008167052102493
            ],
            [
              105.82312817217907,
              21.008337966307643
            ],
            [
              105.8231250371851,
              21.008344069223178
            ],
            [
              105.82297780820517,
              21.008197124394748
            ],
            [
              105.82287963314839,
              21.0080991392289
            ],
            [
              105.82287844085374,
              21.008097993379206
            ],
            [
              105.822877318175,
              21.008096913128515
            ],
            [
              105.82269584029203,
              21.00806324029106
            ],
            [
              105.82263263306713,
              21.008098950549797
            ],
            [
              105.82263095672322,
              21.00809987000958
            ],
            [
              105.82262951820879,
              21.00810065825491
            ],
            [
              105.8226293686035,
              21.008100740268592
            ],
            [
              105.82262749086208,
              21.008101769089116
            ],
            [
              105.82259561629127,
              21.00811924164714
            ],
            [
              105.82238126062094,
              21.0081184919674
            ],
            [
              105.82236227933547,
              21.008131425780483
            ],
            [
              105.82233479397858,
              21.008150153340665
            ],
            [
              105.82229860234258,
              21.00817481291158
            ],
            [
              105.82218987257382,
              21.00812451635771
            ],
            [
              105.82210255239234,
              21.008077511539764
            ],
            [
              105.82210194557308,
              21.008077184793166
            ],
            [
              105.82210209509644,
              21.008076912199385
            ],
            [
              105.82211265684174,
              21.008057634173603
            ],
            [
              105.82212556025992,
              21.008032641856364
            ],
            [
              105.82208196404768,
              21.007969174922696
            ],
            [
              105.82198200421716,
              21.007896257885168
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 252/53, Tây Sơn",
          "maTaiSan": "04.O11.DOPP.1061",
          "duongKinh": 100,
          "chieudaiQL": 151.52,
          "chatLieu": "G",
          "from_latitude": 21.00799349856895,
          "to_latitude": 21.007896257885168,
          "to_longitude": 105.82198200421716,
          "from_longitude": 105.82356714741023
        }
      },
      {
        "id": 1058,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82297094252732,
            21.008581900527254,
            105.82389406746961,
            21.009184386821477
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82389406746961,
              21.008581900527254
            ],
            [
              105.82385890676846,
              21.008604038139346
            ],
            [
              105.82371235589271,
              21.008719219864258
            ],
            [
              105.82361773116034,
              21.00879136338648
            ],
            [
              105.82358515324314,
              21.00881686470679
            ],
            [
              105.82350788077767,
              21.008871444938023
            ],
            [
              105.82344035062953,
              21.008923975539528
            ],
            [
              105.82338076437641,
              21.00886285344062
            ],
            [
              105.82323991699863,
              21.00897436807373
            ],
            [
              105.82322842572486,
              21.00898287425807
            ],
            [
              105.82315396690996,
              21.009041545889875
            ],
            [
              105.82314967374627,
              21.009044837242357
            ],
            [
              105.82314866702664,
              21.009045608951908
            ],
            [
              105.82314758559325,
              21.009046437926187
            ],
            [
              105.82306914768024,
              21.009106573029996
            ],
            [
              105.8230556863463,
              21.009117169767187
            ],
            [
              105.82297094252732,
              21.009184386821477
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 218, Tây Sơn",
          "maTaiSan": "04.O11.DOPP.1062",
          "duongKinh": 100,
          "chieudaiQL": 124.95,
          "chatLieu": "G",
          "from_latitude": 21.008581900527254,
          "to_latitude": 21.009184386821477,
          "to_longitude": 105.82297094252732,
          "from_longitude": 105.82389406746961
        }
      },
      {
        "id": 1059,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82198589264333,
            21.006425984222712,
            105.82281298781771,
            21.00731476378118
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82281298781771,
              21.006425984222712
            ],
            [
              105.82277176718247,
              21.006451379038918
            ],
            [
              105.8227679441178,
              21.006453734170922
            ],
            [
              105.8226735858386,
              21.00652009792453
            ],
            [
              105.8226390293919,
              21.006563099626607
            ],
            [
              105.82259369255358,
              21.00661950855131
            ],
            [
              105.82249867430785,
              21.006746605713477
            ],
            [
              105.8223501362881,
              21.006905213117772
            ],
            [
              105.82230614088081,
              21.006956146444768
            ],
            [
              105.82212554462778,
              21.00716511626953
            ],
            [
              105.82201113998205,
              21.007287328232685
            ],
            [
              105.82199133524806,
              21.007308488964906
            ],
            [
              105.82198589264333,
              21.00731476378118
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 306, Tây Sơn",
          "maTaiSan": "04.O11.DOPP.1063",
          "duongKinh": 100,
          "chieudaiQL": 131.59,
          "chatLieu": "G",
          "from_latitude": 21.006425984222712,
          "to_latitude": 21.00731476378118,
          "to_longitude": 105.82198589264333,
          "from_longitude": 105.82281298781771
        }
      },
      {
        "id": 1060,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82240824725847,
            21.008893927324287,
            105.82406778496143,
            21.01044674247429
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82240824725847,
              21.01044674247429
            ],
            [
              105.82240828068663,
              21.010446698957814
            ],
            [
              105.82241616830912,
              21.01043897708256
            ],
            [
              105.82288363003029,
              21.010028344678357
            ],
            [
              105.8231592121107,
              21.00985105322454
            ],
            [
              105.8231809608766,
              21.00983705818983
            ],
            [
              105.82318098582098,
              21.00983704632721
            ],
            [
              105.82327554744019,
              21.009776209031266
            ],
            [
              105.82351622975396,
              21.009654798714852
            ],
            [
              105.8236183480025,
              21.009603284806573
            ],
            [
              105.82368405906587,
              21.00957013715738
            ],
            [
              105.82376434195425,
              21.00952963803069
            ],
            [
              105.82389769774942,
              21.00946236678292
            ],
            [
              105.82396919850252,
              21.009223431357764
            ],
            [
              105.82406778496143,
              21.008893927324287
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Thái Hà",
          "maTaiSan": "04.O11.DOPP.1064",
          "duongKinh": 150,
          "chieudaiQL": 257.16,
          "chatLieu": "G",
          "from_latitude": 21.01044674247429,
          "to_latitude": 21.008893927324287,
          "to_longitude": 105.82406778496143,
          "from_longitude": 105.82240824725847
        }
      },
      {
        "id": 1061,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82243373310313,
            21.005866861699296,
            105.82258639181498,
            21.005929879343192
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82258639181498,
              21.005866861699296
            ],
            [
              105.8225786988091,
              21.00586933307983
            ],
            [
              105.8225675740891,
              21.005872904919148
            ],
            [
              105.82256072220346,
              21.005875105781463
            ],
            [
              105.82254230199356,
              21.005881021528683
            ],
            [
              105.8225069054322,
              21.005872510772107
            ],
            [
              105.8224992690173,
              21.005891977007067
            ],
            [
              105.82245476996457,
              21.005900795297066
            ],
            [
              105.82245309108126,
              21.005901598250567
            ],
            [
              105.82243373310313,
              21.005907000065292
            ],
            [
              105.8224403540411,
              21.005929879343192
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D200_Thái Thịnh",
          "maTaiSan": "04.O11.DOPP.1065",
          "duongKinh": 200,
          "chieudaiQL": 27.78,
          "chatLieu": "G",
          "from_latitude": 21.005866861699296,
          "to_latitude": 21.005929879343192,
          "to_longitude": 105.8224403540411,
          "from_longitude": 105.82258639181498
        }
      },
      {
        "id": 1062,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81810262050953,
            21.00441663050798,
            105.81957176102527,
            21.005920881947688
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81810262050953,
              21.00441663050798
            ],
            [
              105.81815418542257,
              21.004447712031705
            ],
            [
              105.81830023042882,
              21.004535705172422
            ],
            [
              105.8183388703631,
              21.004561062696215
            ],
            [
              105.81845452017829,
              21.004636999219915
            ],
            [
              105.81829810991579,
              21.004853499148275
            ],
            [
              105.81866506645882,
              21.005095794014895
            ],
            [
              105.81867888935813,
              21.00510493322897
            ],
            [
              105.8186872311761,
              21.005105370911235
            ],
            [
              105.81875796570777,
              21.005105970910506
            ],
            [
              105.81877124639186,
              21.005113573624136
            ],
            [
              105.81877337802203,
              21.005116240538374
            ],
            [
              105.81877361129159,
              21.005116331545477
            ],
            [
              105.81877391975273,
              21.005116452900626
            ],
            [
              105.8187770070347,
              21.00511639288717
            ],
            [
              105.81880232763415,
              21.005128079882425
            ],
            [
              105.8188957994589,
              21.00517126423333
            ],
            [
              105.81897491152532,
              21.005204046503664
            ],
            [
              105.81914882043567,
              21.00526315955316
            ],
            [
              105.81907719264913,
              21.005567173238042
            ],
            [
              105.81907673699682,
              21.005572158541213
            ],
            [
              105.8190762924387,
              21.005576885467725
            ],
            [
              105.81908176073445,
              21.005755243117868
            ],
            [
              105.81908178866503,
              21.00576053680418
            ],
            [
              105.81925142444416,
              21.005797411134836
            ],
            [
              105.81925144938819,
              21.005797399272783
            ],
            [
              105.81926356035041,
              21.005800038014
            ],
            [
              105.81933857035445,
              21.005842144904285
            ],
            [
              105.81956728676698,
              21.005919368909012
            ],
            [
              105.81957176102527,
              21.005920881947688
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 122, Đường Láng",
          "maTaiSan": "04.O11.DOPP.1066",
          "duongKinh": 100,
          "chieudaiQL": 282.9,
          "chatLieu": "G",
          "from_latitude": 21.00441663050798,
          "to_latitude": 21.005920881947688,
          "to_longitude": 105.81957176102527,
          "from_longitude": 105.81810262050953
        }
      },
      {
        "id": 1063,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81722728522962,
            21.011773593743236,
            105.81735930691934,
            21.011896973705596
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81735930691934,
              21.011896973705596
            ],
            [
              105.81735411983342,
              21.01189212661119
            ],
            [
              105.81723395447132,
              21.01177982650065
            ],
            [
              105.81722728522962,
              21.011773593743236
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D200_Thái Thịnh",
          "maTaiSan": "04.O11.DOPP.1067",
          "duongKinh": 200,
          "chieudaiQL": 18.6,
          "chatLieu": "G",
          "from_latitude": 21.011896973705596,
          "to_latitude": 21.011773593743236,
          "to_longitude": 105.81722728522962,
          "from_longitude": 105.81735930691934
        }
      },
      {
        "id": 1064,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81631271510666,
            21.010025350844906,
            105.81698254439337,
            21.010689082693943
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81631271510666,
              21.010689082693943
            ],
            [
              105.81631370164335,
              21.010688128668416
            ],
            [
              105.81631804012753,
              21.01068393674761
            ],
            [
              105.81633669827248,
              21.010665906416136
            ],
            [
              105.81651516512106,
              21.010494317437857
            ],
            [
              105.8165175770633,
              21.010491924051582
            ],
            [
              105.81652753571284,
              21.010482031078094
            ],
            [
              105.81653338423205,
              21.010476221450283
            ],
            [
              105.8167263934037,
              21.01027736808356
            ],
            [
              105.81673813700169,
              21.010265649236096
            ],
            [
              105.81674560640397,
              21.01025826562391
            ],
            [
              105.8167468760783,
              21.010257021203977
            ],
            [
              105.81675057315609,
              21.010253397772047
            ],
            [
              105.81696943491568,
              21.010038303753173
            ],
            [
              105.81697405426667,
              21.01003376362832
            ],
            [
              105.81697765368412,
              21.010030225563984
            ],
            [
              105.81698254439337,
              21.010025350844906
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D90_Ngách 48, Thái Thịnh 1",
          "maTaiSan": "04.O11.DOPP.1068",
          "duongKinh": 90,
          "chieudaiQL": 101.24,
          "chatLieu": "HDPE",
          "from_latitude": 21.010689082693943,
          "to_latitude": 21.010025350844906,
          "to_longitude": 105.81698254439337,
          "from_longitude": 105.81631271510666
        }
      },
      {
        "id": 1065,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8175826940261,
            21.009843243704005,
            105.81830067426176,
            21.0105583680535
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81830067426176,
              21.009843243704005
            ],
            [
              105.81814069277479,
              21.010004868817155
            ],
            [
              105.8180611228962,
              21.01008385838893
            ],
            [
              105.81805840770502,
              21.010086553125866
            ],
            [
              105.81805340785345,
              21.01009151601273
            ],
            [
              105.81795863931305,
              21.010185592059255
            ],
            [
              105.81794765991503,
              21.010196491700018
            ],
            [
              105.81793000830069,
              21.010214015169268
            ],
            [
              105.81791920588616,
              21.010224737828125
            ],
            [
              105.81788612511818,
              21.010257578329927
            ],
            [
              105.81782908540735,
              21.010314633905693
            ],
            [
              105.81782580013714,
              21.01031792028358
            ],
            [
              105.81782420437563,
              21.010319515827742
            ],
            [
              105.81781815522861,
              21.010325566721352
            ],
            [
              105.81763607805496,
              21.010504905098802
            ],
            [
              105.81763479119543,
              21.010506172188453
            ],
            [
              105.81763217466907,
              21.010508794186563
            ],
            [
              105.8175826940261,
              21.0105583680535
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D90_Ngách 21, Thái Thịnh 1",
          "maTaiSan": "04.O11.DOPP.1069",
          "duongKinh": 90,
          "chieudaiQL": 108.81,
          "chatLieu": "HDPE",
          "from_latitude": 21.009843243704005,
          "to_latitude": 21.0105583680535,
          "to_longitude": 105.8175826940261,
          "from_longitude": 105.81830067426176
        }
      },
      {
        "id": 1066,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81732274384773,
            21.009536268246546,
            105.81892370715003,
            21.011699629651574
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81732274384773,
              21.011699629651574
            ],
            [
              105.81797589117578,
              21.01093934135553
            ],
            [
              105.81799980940674,
              21.010910295084077
            ],
            [
              105.81800160973839,
              21.010908111456704
            ],
            [
              105.81842266947774,
              21.01039678461708
            ],
            [
              105.81865205433847,
              21.01012601982424
            ],
            [
              105.81866058580482,
              21.01012114560752
            ],
            [
              105.8186617206743,
              21.010118116140827
            ],
            [
              105.81866407764436,
              21.010111825561403
            ],
            [
              105.81867201418258,
              21.010102457938043
            ],
            [
              105.81892370715003,
              21.009805360249015
            ],
            [
              105.81876361531928,
              21.00967305804477
            ],
            [
              105.81872853512357,
              21.009703793867633
            ],
            [
              105.81867837420067,
              21.009661365809656
            ],
            [
              105.81867346515102,
              21.00965740167576
            ],
            [
              105.81866493497434,
              21.00965051947195
            ],
            [
              105.81852751780872,
              21.009536268246546
            ],
            [
              105.81844402690872,
              21.009621642800045
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Thái Thịnh",
          "maTaiSan": "04.O11.DOPP.1070",
          "duongKinh": 100,
          "chieudaiQL": 338.68,
          "chatLieu": "G",
          "from_latitude": 21.011699629651574,
          "to_latitude": 21.009621642800045,
          "to_longitude": 105.81844402690872,
          "from_longitude": 105.81732274384773
        }
      },
      {
        "id": 1067,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81660783346844,
            21.005996250541326,
            105.81688223930998,
            21.006117704124325
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81660783346844,
              21.005996250541326
            ],
            [
              105.81663024483842,
              21.006006077618665
            ],
            [
              105.81663033546391,
              21.006006116022654
            ],
            [
              105.81663039716997,
              21.006006142823423
            ],
            [
              105.81663051578262,
              21.006006198254447
            ],
            [
              105.81663062380015,
              21.006006251026655
            ],
            [
              105.81665460968402,
              21.0060180070679
            ],
            [
              105.81688223930998,
              21.006117704124325
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Đường Láng",
          "maTaiSan": "04.O11.DOPP.1071",
          "duongKinh": 150,
          "chieudaiQL": 32.95,
          "chatLieu": "G",
          "from_latitude": 21.005996250541326,
          "to_latitude": 21.006117704124325,
          "to_longitude": 105.81688223930998,
          "from_longitude": 105.81660783346844
        }
      },
      {
        "id": 1068,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8178160149889,
            21.00792507037885,
            105.8196599082738,
            21.009091837150525
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8196599082738,
              21.009091837150525
            ],
            [
              105.81965386757294,
              21.009087437720453
            ],
            [
              105.81957795164881,
              21.00903212388255
            ],
            [
              105.81957312874711,
              21.00902861097343
            ],
            [
              105.81929895832711,
              21.008828851893142
            ],
            [
              105.8191836650019,
              21.008740842725228
            ],
            [
              105.81911305685854,
              21.00868917383566
            ],
            [
              105.8189273118483,
              21.008553249528905
            ],
            [
              105.81885818696897,
              21.00846753433939
            ],
            [
              105.81879355673489,
              21.008387393893937
            ],
            [
              105.8186355549398,
              21.00825687254732
            ],
            [
              105.81856306367908,
              21.008204294793902
            ],
            [
              105.8185481046885,
              21.008216349873507
            ],
            [
              105.81850082967136,
              21.0082544487079
            ],
            [
              105.81842349832225,
              21.008272785836404
            ],
            [
              105.81841275729688,
              21.00827533311139
            ],
            [
              105.81838847566043,
              21.0082605872658
            ],
            [
              105.81826251619093,
              21.008184093408588
            ],
            [
              105.81820192573633,
              21.00814917870659
            ],
            [
              105.8180267334545,
              21.00802106703758
            ],
            [
              105.81801022188436,
              21.00801687774906
            ],
            [
              105.81798505864275,
              21.008010492748085
            ],
            [
              105.81795157316726,
              21.008001998060482
            ],
            [
              105.81784968717753,
              21.007944178031458
            ],
            [
              105.8178160149889,
              21.00792507037885
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Ngõ 51, Thái Thịnh",
          "maTaiSan": "04.O11.DOPP.1072",
          "duongKinh": 150,
          "chieudaiQL": 242.87,
          "chatLieu": "G",
          "from_latitude": 21.009091837150525,
          "to_latitude": 21.00792507037885,
          "to_longitude": 105.8178160149889,
          "from_longitude": 105.8196599082738
        }
      },
      {
        "id": 1069,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81664287922816,
            21.008349279868092,
            105.81739660875539,
            21.008633160571488
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81739660875539,
              21.008633160571488
            ],
            [
              105.81704361751879,
              21.008468016804173
            ],
            [
              105.8170321024505,
              21.008464440208442
            ],
            [
              105.81702558421058,
              21.008462414852108
            ],
            [
              105.8169858899319,
              21.00845008396766
            ],
            [
              105.81679866198978,
              21.00839192422273
            ],
            [
              105.81679797610217,
              21.00839173693361
            ],
            [
              105.81664779220735,
              21.00835062558595
            ],
            [
              105.81664287922816,
              21.008349279868092
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Thịnh Quang",
          "maTaiSan": "04.O11.DOPP.1073",
          "duongKinh": 100,
          "chieudaiQL": 84.79,
          "chatLieu": "G",
          "from_latitude": 21.008633160571488,
          "to_latitude": 21.008349279868092,
          "to_longitude": 105.81664287922816,
          "from_longitude": 105.81739660875539
        }
      },
      {
        "id": 1070,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81753180771703,
            21.007053494285437,
            105.81832290469056,
            21.007372374909473
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81831980430793,
              21.007323475051777
            ],
            [
              105.81831903408938,
              21.0073203463599
            ],
            [
              105.81830958550506,
              21.007281898012657
            ],
            [
              105.81832290469056,
              21.007136192934173
            ],
            [
              105.81831746852032,
              21.007134326810405
            ],
            [
              105.81830427658532,
              21.007129799132866
            ],
            [
              105.81808918947779,
              21.00705428641489
            ],
            [
              105.81808693434934,
              21.007053494285437
            ],
            [
              105.81807578833654,
              21.007069959522042
            ],
            [
              105.8179662536719,
              21.00723177007172
            ],
            [
              105.81789106550913,
              21.00726204455032
            ],
            [
              105.8177887039034,
              21.007261584458128
            ],
            [
              105.81773244180928,
              21.00726133093342
            ],
            [
              105.81769365184564,
              21.007261156926017
            ],
            [
              105.81766360852535,
              21.0072610221766
            ],
            [
              105.81760734268119,
              21.00730456888007
            ],
            [
              105.81753621682351,
              21.007368457201803
            ],
            [
              105.8175341175535,
              21.007370319807702
            ],
            [
              105.81753180771703,
              21.007372374909473
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngách 177, Thịnh Quang",
          "maTaiSan": "04.O11.DOPP.1074",
          "duongKinh": 100,
          "chieudaiQL": 120.5,
          "chatLieu": "G",
          "from_latitude": 21.007323475051777,
          "to_latitude": 21.007372374909473,
          "to_longitude": 105.81753180771703,
          "from_longitude": 105.81831980430793
        }
      },
      {
        "id": 1071,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81889186707932,
            21.00903212388255,
            105.81957795164881,
            21.00939873486567
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81957795164881,
              21.00903212388255
            ],
            [
              105.81957167413488,
              21.009040016714092
            ],
            [
              105.81937653308754,
              21.00928538252406
            ],
            [
              105.81914625473634,
              21.009138473792564
            ],
            [
              105.81913676567915,
              21.00913249493924
            ],
            [
              105.81905817082796,
              21.009217938343657
            ],
            [
              105.81903153999586,
              21.009246890327887
            ],
            [
              105.81890452368145,
              21.00938497470167
            ],
            [
              105.81889186707932,
              21.00939873486567
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D90_Ngõ 61, Thái Thịnh",
          "maTaiSan": "04.O11.DOPP.1075",
          "duongKinh": 90,
          "chieudaiQL": 103.21,
          "chatLieu": "HDPE",
          "from_latitude": 21.00903212388255,
          "to_latitude": 21.00939873486567,
          "to_longitude": 105.81889186707932,
          "from_longitude": 105.81957795164881
        }
      },
      {
        "id": 1072,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81569772594138,
            21.007390240436713,
            105.81664638083602,
            21.007927278366534
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81569772594138,
              21.007390240436713
            ],
            [
              105.81569797614377,
              21.007390437947784
            ],
            [
              105.81571340610216,
              21.007402530480277
            ],
            [
              105.81582348042814,
              21.007470255312963
            ],
            [
              105.81582503066447,
              21.007478687649588
            ],
            [
              105.81583195909761,
              21.00747832566061
            ],
            [
              105.81597063007858,
              21.007559599926168
            ],
            [
              105.81597304055799,
              21.007561012760128
            ],
            [
              105.81602503172938,
              21.007505676462753
            ],
            [
              105.8160358337333,
              21.007494179857602
            ],
            [
              105.81618944349894,
              21.00761410552189
            ],
            [
              105.81619043766801,
              21.0076149118531
            ],
            [
              105.81619506733989,
              21.007618644370208
            ],
            [
              105.81618756167546,
              21.00762836207399
            ],
            [
              105.81618579080452,
              21.007630654832013
            ],
            [
              105.81615668604724,
              21.00766833710903
            ],
            [
              105.8161278945552,
              21.007706318654122
            ],
            [
              105.8161697981362,
              21.007730871575696
            ],
            [
              105.81629794742393,
              21.00780595832518
            ],
            [
              105.81632504566657,
              21.007808412478852
            ],
            [
              105.81637408285565,
              21.007812841521414
            ],
            [
              105.81637527158657,
              21.007813341626907
            ],
            [
              105.81637827280967,
              21.007814602588297
            ],
            [
              105.8165080958444,
              21.00786916250383
            ],
            [
              105.81658663777395,
              21.007902170778976
            ],
            [
              105.81664638083602,
              21.007927278366534
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 302, Đường Láng",
          "maTaiSan": "04.O11.DOPP.1076",
          "duongKinh": 100,
          "chieudaiQL": 142.03,
          "chatLieu": "G",
          "from_latitude": 21.007390240436713,
          "to_latitude": 21.007927278366534,
          "to_longitude": 105.81664638083602,
          "from_longitude": 105.81569772594138
        }
      },
      {
        "id": 1073,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81628089022242,
            21.00659043630129,
            105.81699225475458,
            21.007268161016416
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81628089022242,
              21.00659043630129
            ],
            [
              105.8162827377742,
              21.006591456217222
            ],
            [
              105.81628304571196,
              21.00659165796669
            ],
            [
              105.81637753550108,
              21.006653946607187
            ],
            [
              105.81649089643138,
              21.006716921434283
            ],
            [
              105.81672988354963,
              21.006861781597316
            ],
            [
              105.81667262228679,
              21.00695062426529
            ],
            [
              105.81666169520997,
              21.006967578816056
            ],
            [
              105.81666983550649,
              21.00701527628407
            ],
            [
              105.81674512729812,
              21.00707824212196
            ],
            [
              105.81690180832886,
              21.00720927295412
            ],
            [
              105.81690189038201,
              21.007209326753962
            ],
            [
              105.81695996609646,
              21.00724712447366
            ],
            [
              105.8169616997845,
              21.007248252412573
            ],
            [
              105.81697958401875,
              21.007259892270852
            ],
            [
              105.81698546469772,
              21.007263725341787
            ],
            [
              105.81699225475458,
              21.007268161016416
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 242, Đường Láng",
          "maTaiSan": "04.O11.DOPP.1077",
          "duongKinh": 100,
          "chieudaiQL": 121.78,
          "chatLieu": "G",
          "from_latitude": 21.00659043630129,
          "to_latitude": 21.007268161016416,
          "to_longitude": 105.81699225475458,
          "from_longitude": 105.81628089022242
        }
      },
      {
        "id": 1074,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81739660875539,
            21.003558163195102,
            105.82062965649128,
            21.008662471840825
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82062643387356,
              21.003558163195102
            ],
            [
              105.82059211639363,
              21.00360563161123
            ],
            [
              105.82059876479352,
              21.003608272210723
            ],
            [
              105.82062183674529,
              21.003616497772235
            ],
            [
              105.82062965649128,
              21.003619980854374
            ],
            [
              105.82060789585599,
              21.003656494897086
            ],
            [
              105.82057001760744,
              21.003717699609396
            ],
            [
              105.82056943668476,
              21.003720522288475
            ],
            [
              105.82055611877836,
              21.0037859784088
            ],
            [
              105.82055139897261,
              21.00380921232079
            ],
            [
              105.82054975230034,
              21.00381731228063
            ],
            [
              105.82053946651182,
              21.003852041313618
            ],
            [
              105.82049435544806,
              21.004004473505816
            ],
            [
              105.82044797464233,
              21.00416112988218
            ],
            [
              105.82042460487877,
              21.004236214082926
            ],
            [
              105.82042227201542,
              21.0042437185088
            ],
            [
              105.82039444322433,
              21.004336085792488
            ],
            [
              105.82036421715209,
              21.00444881526005
            ],
            [
              105.82033891274894,
              21.004543212566
            ],
            [
              105.82032067269645,
              21.004611465300737
            ],
            [
              105.82031762123854,
              21.004623532684274
            ],
            [
              105.8203009415288,
              21.004689359954686
            ],
            [
              105.8202776454249,
              21.004781291694602
            ],
            [
              105.82027683192108,
              21.004784502978403
            ],
            [
              105.82024666994035,
              21.0049035339381
            ],
            [
              105.82022441832682,
              21.004951987363434
            ],
            [
              105.82019445307846,
              21.00501726806616
            ],
            [
              105.82015164828081,
              21.005066271238565
            ],
            [
              105.82014080656299,
              21.005078683255974
            ],
            [
              105.81999462631158,
              21.005248951520063
            ],
            [
              105.81997682188869,
              21.005269695933176
            ],
            [
              105.81994038429472,
              21.00528766687933
            ],
            [
              105.81994035935084,
              21.005287678741453
            ],
            [
              105.81989765013907,
              21.005309839258288
            ],
            [
              105.81986844622429,
              21.00534144299061
            ],
            [
              105.81984410144152,
              21.00540223100031
            ],
            [
              105.81979888322913,
              21.005515179814967
            ],
            [
              105.81979359234299,
              21.005525287132606
            ],
            [
              105.81976264512808,
              21.005584478413073
            ],
            [
              105.81975586357584,
              21.005597448013713
            ],
            [
              105.81972377145271,
              21.005655185185304
            ],
            [
              105.8197049459993,
              21.005689064716627
            ],
            [
              105.81957536612433,
              21.005912777082358
            ],
            [
              105.81957176102527,
              21.005920881947688
            ],
            [
              105.81956752668732,
              21.0059303645563
            ],
            [
              105.81952263738246,
              21.006013072542224
            ],
            [
              105.8194437010612,
              21.006137997849837
            ],
            [
              105.81926262510736,
              21.00642458957298
            ],
            [
              105.81920681902146,
              21.00651292023895
            ],
            [
              105.8191542808454,
              21.00659129151981
            ],
            [
              105.81892334041717,
              21.006935812986686
            ],
            [
              105.81888898455364,
              21.00698705856248
            ],
            [
              105.81888438957195,
              21.00699392172221
            ],
            [
              105.81887225419862,
              21.007012032934597
            ],
            [
              105.81878857397358,
              21.007218791572196
            ],
            [
              105.8187764038013,
              21.007248864395788
            ],
            [
              105.81876558227323,
              21.00727560323767
            ],
            [
              105.81875985736511,
              21.007289750042695
            ],
            [
              105.81869348180746,
              21.007453778561498
            ],
            [
              105.81868707096221,
              21.007456799990155
            ],
            [
              105.8186844450507,
              21.007455749522908
            ],
            [
              105.81867980626785,
              21.00745388318822
            ],
            [
              105.81849586670161,
              21.007379849685503
            ],
            [
              105.81843985539768,
              21.007361919014297
            ],
            [
              105.81831980430793,
              21.007323475051777
            ],
            [
              105.81828566286246,
              21.007346359921364
            ],
            [
              105.8182716474656,
              21.007355754948073
            ],
            [
              105.81824721258916,
              21.007374969387516
            ],
            [
              105.81823681685981,
              21.007383146617663
            ],
            [
              105.81815765979702,
              21.00745238478781
            ],
            [
              105.8181414073382,
              21.00746659934595
            ],
            [
              105.81813278666215,
              21.00747414119616
            ],
            [
              105.81811393918659,
              21.00750073522784
            ],
            [
              105.81798642839068,
              21.00768723072343
            ],
            [
              105.81798263393651,
              21.007692736976335
            ],
            [
              105.81782308528831,
              21.00791490850355
            ],
            [
              105.8178160149889,
              21.00792507037885
            ],
            [
              105.81774401162976,
              21.00805672499406
            ],
            [
              105.81774271541147,
              21.008059094970918
            ],
            [
              105.81773392957503,
              21.00807517129585
            ],
            [
              105.81771962926454,
              21.008101318015747
            ],
            [
              105.81767824462763,
              21.008207012117364
            ],
            [
              105.8176310839756,
              21.008337458026784
            ],
            [
              105.817617973027,
              21.008379761389882
            ],
            [
              105.8175980827425,
              21.00844397260692
            ],
            [
              105.81757939537937,
              21.008508994560753
            ],
            [
              105.81752368022042,
              21.008647347753918
            ],
            [
              105.81748427530493,
              21.008661485604367
            ],
            [
              105.81748166143782,
              21.008662471840825
            ],
            [
              105.81739660875539,
              21.008633160571488
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Thịnh Quang",
          "maTaiSan": "04.O11.DOPP.1078",
          "duongKinh": 150,
          "chieudaiQL": 703.86,
          "chatLieu": "G",
          "from_latitude": 21.003558163195102,
          "to_latitude": 21.008633160571488,
          "to_longitude": 105.81739660875539,
          "from_longitude": 105.82062643387356
        }
      },
      {
        "id": 1075,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81767579418396,
            21.00310422316091,
            105.8194719758208,
            21.00447393567621
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8194719758208,
              21.00310422316091
            ],
            [
              105.81910953744124,
              21.003207105818714
            ],
            [
              105.8189963876535,
              21.003292163580056
            ],
            [
              105.81883897812014,
              21.003410442684384
            ],
            [
              105.81882434853546,
              21.003421433096218
            ],
            [
              105.81862766581922,
              21.003564385657643
            ],
            [
              105.81858603313013,
              21.00359464443025
            ],
            [
              105.81851982989409,
              21.0036427625683
            ],
            [
              105.8183664052307,
              21.003754274970987
            ],
            [
              105.81836447079351,
              21.003756368020163
            ],
            [
              105.81815845729705,
              21.003979815491274
            ],
            [
              105.81796040366106,
              21.004194626638476
            ],
            [
              105.81794826143933,
              21.00420775651477
            ],
            [
              105.81783601990215,
              21.00432912889482
            ],
            [
              105.8177398479363,
              21.004416044408245
            ],
            [
              105.81767579418396,
              21.00447393567621
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Ngõ 47, Ngõ 41, Đường Láng",
          "maTaiSan": "04.O11.DOPP.1079",
          "duongKinh": 150,
          "chieudaiQL": 245.67,
          "chatLieu": "G",
          "from_latitude": 21.00310422316091,
          "to_latitude": 21.00447393567621,
          "to_longitude": 105.81767579418396,
          "from_longitude": 105.8194719758208
        }
      },
      {
        "id": 1076,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81829806969816,
            21.002779108531502,
            105.81875673083822,
            21.003564385657643
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81862766581922,
              21.003564385657643
            ],
            [
              105.81861990033256,
              21.00355514684881
            ],
            [
              105.8185724418469,
              21.00349867221174
            ],
            [
              105.81841786126688,
              21.00331473059574
            ],
            [
              105.81829806969816,
              21.003280414822722
            ],
            [
              105.81830312958358,
              21.003268333297918
            ],
            [
              105.81838190859627,
              21.0030802410526
            ],
            [
              105.81844418626441,
              21.003040761357326
            ],
            [
              105.81872129182995,
              21.00286509745505
            ],
            [
              105.81875673083822,
              21.002779108531502
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 41, Đường Láng",
          "maTaiSan": "04.O11.DOPP.1080",
          "duongKinh": 100,
          "chieudaiQL": 116.39,
          "chatLieu": "G",
          "from_latitude": 21.003564385657643,
          "to_latitude": 21.002779108531502,
          "to_longitude": 105.81875673083822,
          "from_longitude": 105.81862766581922
        }
      },
      {
        "id": 1077,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81779196294163,
            21.003553715564554,
            105.81836447079351,
            21.003756368020163
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81836447079351,
              21.003756368020163
            ],
            [
              105.81835946221965,
              21.003751930152408
            ],
            [
              105.81830111153006,
              21.003700200985087
            ],
            [
              105.81825148418714,
              21.003676732528415
            ],
            [
              105.81808388716715,
              21.003646186177395
            ],
            [
              105.81807851174864,
              21.00364520672014
            ],
            [
              105.81807211783348,
              21.00364371912405
            ],
            [
              105.81785230643553,
              21.00359256884597
            ],
            [
              105.81781172245002,
              21.003574364175957
            ],
            [
              105.81779734151873,
              21.003559335804848
            ],
            [
              105.81779247177639,
              21.003554246927933
            ],
            [
              105.81779196294163,
              21.003553715564554
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 47, Ngõ 41, Đường Láng",
          "maTaiSan": "04.O11.DOPP.1081",
          "duongKinh": 100,
          "chieudaiQL": 53.27,
          "chatLieu": "G",
          "from_latitude": 21.003756368020163,
          "to_latitude": 21.003553715564554,
          "to_longitude": 105.81779196294163,
          "from_longitude": 105.81836447079351
        }
      },
      {
        "id": 1078,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81851982989409,
            21.0036427625683,
            105.81873834741255,
            21.003898191454276
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81873834741255,
              21.003898191454276
            ],
            [
              105.8185539656754,
              21.0036826647232
            ],
            [
              105.81851982989409,
              21.0036427625683
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Ngõ 47, Ngõ 41, Đường Láng",
          "maTaiSan": "04.O11.DOPP.1082",
          "duongKinh": 150,
          "chieudaiQL": 40.6,
          "chatLieu": "G",
          "from_latitude": 21.003898191454276,
          "to_latitude": 21.0036427625683,
          "to_longitude": 105.81851982989409,
          "from_longitude": 105.81873834741255
        }
      },
      {
        "id": 1079,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8202326900745,
            21.00686525363432,
            105.82033237822122,
            21.00695332651558
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8202326900745,
              21.00686525363432
            ],
            [
              105.82028120845597,
              21.006916643779984
            ],
            [
              105.82031890812,
              21.006943670001704
            ],
            [
              105.82033237822122,
              21.00695332651558
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Vĩnh Hồ",
          "maTaiSan": "04.O11.DOPP.1083",
          "duongKinh": 150,
          "chieudaiQL": 6.69,
          "chatLieu": "G",
          "from_latitude": 21.00686525363432,
          "to_latitude": 21.00695332651558,
          "to_longitude": 105.82033237822122,
          "from_longitude": 105.8202326900745
        }
      },
      {
        "id": 1080,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81984127812409,
            21.00583702812664,
            105.8213101370559,
            21.007511467239038
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8213101370559,
              21.00583702812664
            ],
            [
              105.82092881135696,
              21.00626387996088
            ],
            [
              105.82086767131761,
              21.006333669217284
            ],
            [
              105.82072410193443,
              21.0064975487129
            ],
            [
              105.82064427645886,
              21.006588667404685
            ],
            [
              105.82053982786948,
              21.00671078252875
            ],
            [
              105.82033237822122,
              21.00695332651558
            ],
            [
              105.8203018641227,
              21.006988998453988
            ],
            [
              105.82026366175941,
              21.00703366114165
            ],
            [
              105.82022145754361,
              21.007083003764464
            ],
            [
              105.82009002716381,
              21.007231126143985
            ],
            [
              105.82005388662994,
              21.007271856132295
            ],
            [
              105.81989240885535,
              21.007453843358974
            ],
            [
              105.81984692617777,
              21.007505102079474
            ],
            [
              105.81984127812409,
              21.007511467239038
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Vĩnh Hồ",
          "maTaiSan": "04.O11.DOPP.1084",
          "duongKinh": 150,
          "chieudaiQL": 240.19,
          "chatLieu": "G",
          "from_latitude": 21.00583702812664,
          "to_latitude": 21.007511467239038,
          "to_longitude": 105.81984127812409,
          "from_longitude": 105.8213101370559
        }
      },
      {
        "id": 1081,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82025030957539,
            21.006129966438618,
            105.82072410193443,
            21.0064975487129
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82072410193443,
              21.0064975487129
            ],
            [
              105.82071582445906,
              21.006491126937863
            ],
            [
              105.82025030957539,
              21.006129966438618
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Vĩnh Hồ",
          "maTaiSan": "04.O11.DOPP.1085",
          "duongKinh": 100,
          "chieudaiQL": 63.89,
          "chatLieu": "G",
          "from_latitude": 21.0064975487129,
          "to_latitude": 21.006129966438618,
          "to_longitude": 105.82025030957539,
          "from_longitude": 105.82072410193443
        }
      },
      {
        "id": 1082,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82040455854985,
            21.005983446474048,
            105.82086767131761,
            21.006333669217284
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82086767131761,
              21.006333669217284
            ],
            [
              105.82086694409196,
              21.006333133497822
            ],
            [
              105.82079830789513,
              21.00628260196293
            ],
            [
              105.82079212601305,
              21.006278050605
            ],
            [
              105.82069236940235,
              21.006202221565943
            ],
            [
              105.82069038931968,
              21.006200716401
            ],
            [
              105.82044295852646,
              21.00601263614523
            ],
            [
              105.82044094464068,
              21.00601110494657
            ],
            [
              105.82040455854985,
              21.005983446474048
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Vĩnh Hồ",
          "maTaiSan": "04.O11.DOPP.1086",
          "duongKinh": 100,
          "chieudaiQL": 61.82,
          "chatLieu": "G",
          "from_latitude": 21.006333669217284,
          "to_latitude": 21.005983446474048,
          "to_longitude": 105.82040455854985,
          "from_longitude": 105.82086767131761
        }
      },
      {
        "id": 1083,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82059488015365,
            21.005786909624156,
            105.8209780605825,
            21.00628260196293
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82079830789513,
              21.00628260196293
            ],
            [
              105.82097522849058,
              21.00607763266301
            ],
            [
              105.8209780605825,
              21.00607435112298
            ],
            [
              105.82096821479404,
              21.00606696505161
            ],
            [
              105.8208625807845,
              21.005987724064994
            ],
            [
              105.82086010340781,
              21.005985866331933
            ],
            [
              105.82064229893216,
              21.005822480427227
            ],
            [
              105.8206357766307,
              21.005817588380754
            ],
            [
              105.82059488015365,
              21.005786909624156
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Vĩnh Hồ",
          "maTaiSan": "04.O11.DOPP.1087",
          "duongKinh": 100,
          "chieudaiQL": 80.67,
          "chatLieu": "G",
          "from_latitude": 21.00628260196293,
          "to_latitude": 21.005786909624156,
          "to_longitude": 105.82059488015365,
          "from_longitude": 105.82079830789513
        }
      },
      {
        "id": 1084,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8202326900745,
            21.005028209832247,
            105.82213185685093,
            21.00699657171123
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82213185685093,
              21.005028209832247
            ],
            [
              105.82212920126193,
              21.005029815050456
            ],
            [
              105.82207292859464,
              21.00506382061318
            ],
            [
              105.8220223787858,
              21.005090756050492
            ],
            [
              105.82202211120753,
              21.005090899149607
            ],
            [
              105.8220217592242,
              21.005091086011323
            ],
            [
              105.82200532456422,
              21.00509984359926
            ],
            [
              105.8219683177865,
              21.005122722248107
            ],
            [
              105.82192896137154,
              21.005169527902467
            ],
            [
              105.82184426033228,
              21.00527025302008
            ],
            [
              105.82180119881495,
              21.00532146983753
            ],
            [
              105.8217811427386,
              21.00532602501033
            ],
            [
              105.82178011267241,
              21.00532727282541
            ],
            [
              105.82177897080692,
              21.005328655761232
            ],
            [
              105.82177671889391,
              21.00535058255443
            ],
            [
              105.82175000369698,
              21.005382353421233
            ],
            [
              105.82173652778725,
              21.00538603049376
            ],
            [
              105.82173684509488,
              21.00539198303961
            ],
            [
              105.82173697260097,
              21.00539436513901
            ],
            [
              105.82173714734422,
              21.005397644818803
            ],
            [
              105.82162182889435,
              21.00553478496155
            ],
            [
              105.82151421761043,
              21.00565538404917
            ],
            [
              105.82151352259774,
              21.005656164179875
            ],
            [
              105.8215119279727,
              21.005657951235833
            ],
            [
              105.82149391770822,
              21.005668473159805
            ],
            [
              105.82149400564974,
              21.005678037014974
            ],
            [
              105.82144033137325,
              21.00573818821658
            ],
            [
              105.8214388285254,
              21.00573987276416
            ],
            [
              105.82112637464216,
              21.00609003281439
            ],
            [
              105.82111536371376,
              21.006093315881426
            ],
            [
              105.82111550762012,
              21.00610221288481
            ],
            [
              105.82110871130719,
              21.006109830078866
            ],
            [
              105.82109945177525,
              21.00612011193685
            ],
            [
              105.82058765265133,
              21.006688444534788
            ],
            [
              105.82055625486504,
              21.00672361948606
            ],
            [
              105.82031260936459,
              21.00699657171123
            ],
            [
              105.8203018641227,
              21.006988998453988
            ],
            [
              105.82024948106294,
              21.00695207497602
            ],
            [
              105.8202326900745,
              21.00686525363432
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D160_Vĩnh Hồ",
          "maTaiSan": "04.O11.DOPP.1088",
          "duongKinh": 160,
          "chieudaiQL": 301.51,
          "chatLieu": "PVC",
          "from_latitude": 21.005028209832247,
          "to_latitude": 21.00686525363432,
          "to_longitude": 105.8202326900745,
          "from_longitude": 105.82213185685093
        }
      },
      {
        "id": 1085,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8197623294084,
            21.00664482816328,
            105.82026366175941,
            21.00703366114165
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82026366175941,
              21.00703366114165
            ],
            [
              105.82025063263305,
              21.007023556302528
            ],
            [
              105.82016393410203,
              21.006956313340236
            ],
            [
              105.82014589252258,
              21.00694231986519
            ],
            [
              105.81985622120241,
              21.00671765115565
            ],
            [
              105.81984553364882,
              21.006709361330422
            ],
            [
              105.8197623294084,
              21.00664482816328
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Vĩnh Hồ",
          "maTaiSan": "04.O11.DOPP.1089",
          "duongKinh": 100,
          "chieudaiQL": 67.6,
          "chatLieu": "G",
          "from_latitude": 21.00703366114165,
          "to_latitude": 21.00664482816328,
          "to_longitude": 105.8197623294084,
          "from_longitude": 105.82026366175941
        }
      },
      {
        "id": 1086,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81956635110416,
            21.006887577930737,
            105.82005388662994,
            21.007271856132295
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82005388662994,
              21.007271856132295
            ],
            [
              105.81995474129978,
              21.00719370966194
            ],
            [
              105.81993281688969,
              21.007176428154942
            ],
            [
              105.81965130679548,
              21.0069545405676
            ],
            [
              105.81963259937935,
              21.00693979514495
            ],
            [
              105.81956635110416,
              21.006887577930737
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Vĩnh Hồ",
          "maTaiSan": "04.O11.DOPP.1090",
          "duongKinh": 100,
          "chieudaiQL": 65.76,
          "chatLieu": "G",
          "from_latitude": 21.007271856132295,
          "to_latitude": 21.006887577930737,
          "to_longitude": 105.81956635110416,
          "from_longitude": 105.82005388662994
        }
      },
      {
        "id": 1087,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81932763750228,
            21.007098431602287,
            105.81984692617777,
            21.007505102079474
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81984692617777,
              21.007505102079474
            ],
            [
              105.81974963541217,
              21.007428911079533
            ],
            [
              105.81973070915902,
              21.0074140890469
            ],
            [
              105.81944341256629,
              21.007189099312413
            ],
            [
              105.81942225294075,
              21.00717252760564
            ],
            [
              105.81932763750228,
              21.007098431602287
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Vĩnh Hồ",
          "maTaiSan": "04.O11.DOPP.1091",
          "duongKinh": 100,
          "chieudaiQL": 68.76,
          "chatLieu": "G",
          "from_latitude": 21.007505102079474,
          "to_latitude": 21.007098431602287,
          "to_longitude": 105.81932763750228,
          "from_longitude": 105.81984692617777
        }
      },
      {
        "id": 1088,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81984127812409,
            21.007511467239038,
            105.8204534665938,
            21.007979812203626
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81984127812409,
              21.007511467239038
            ],
            [
              105.81984743106189,
              21.00751617412489
            ],
            [
              105.81986564453824,
              21.007530108091572
            ],
            [
              105.81999246958254,
              21.007627134185373
            ],
            [
              105.82000204577318,
              21.0076344601899
            ],
            [
              105.82001411308718,
              21.00764369174328
            ],
            [
              105.8200345057275,
              21.007659293387984
            ],
            [
              105.8201597507305,
              21.007755110315202
            ],
            [
              105.82017053145101,
              21.007763358119618
            ],
            [
              105.82032372578642,
              21.007880556911164
            ],
            [
              105.82032654726174,
              21.007882714670384
            ],
            [
              105.82033728646752,
              21.007890931050742
            ],
            [
              105.82034209389568,
              21.007894608401237
            ],
            [
              105.8204534665938,
              21.007979812203626
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Vĩnh Hồ",
          "maTaiSan": "04.O11.DOPP.1092",
          "duongKinh": 100,
          "chieudaiQL": 82.09,
          "chatLieu": "G",
          "from_latitude": 21.007511467239038,
          "to_latitude": 21.007979812203626,
          "to_longitude": 105.8204534665938,
          "from_longitude": 105.81984127812409
        }
      },
      {
        "id": 1089,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8204534665938,
            21.00747867151216,
            105.82088390942617,
            21.007979812203626
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8204534665938,
              21.007979812203626
            ],
            [
              105.82069212214431,
              21.007703698149474
            ],
            [
              105.8206991326047,
              21.007695472065837
            ],
            [
              105.82088225596944,
              21.007480610588622
            ],
            [
              105.82088390942617,
              21.00747867151216
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Thái Thịnh",
          "maTaiSan": "04.O11.DOPP.1093",
          "duongKinh": 100,
          "chieudaiQL": 71.28,
          "chatLieu": "G",
          "from_latitude": 21.007979812203626,
          "to_latitude": 21.00747867151216,
          "to_longitude": 105.82088390942617,
          "from_longitude": 105.8204534665938
        }
      },
      {
        "id": 1090,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82027202508611,
            21.007298798606314,
            105.8206991326047,
            21.007695472065837
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8206991326047,
              21.007695472065837
            ],
            [
              105.82058535448742,
              21.007605024135415
            ],
            [
              105.8205763022457,
              21.007597827509368
            ],
            [
              105.82046691610452,
              21.007510870970584
            ],
            [
              105.82049242116442,
              21.007481078778675
            ],
            [
              105.8204533253366,
              21.00744872035294
            ],
            [
              105.82045138508933,
              21.007447114735502
            ],
            [
              105.82028946626009,
              21.007313099347638
            ],
            [
              105.82027450086055,
              21.007300712355125
            ],
            [
              105.82027202508611,
              21.007298798606314
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Thái Thịnh",
          "maTaiSan": "04.O11.DOPP.1094",
          "duongKinh": 100,
          "chieudaiQL": 66.16,
          "chatLieu": "G",
          "from_latitude": 21.007695472065837,
          "to_latitude": 21.007298798606314,
          "to_longitude": 105.82027202508611,
          "from_longitude": 105.8206991326047
        }
      },
      {
        "id": 1091,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82047062296257,
            21.007145957179077,
            105.82088390942617,
            21.00747867151216
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82088390942617,
              21.00747867151216
            ],
            [
              105.82083542698918,
              21.00743955082525
            ],
            [
              105.82078002854826,
              21.007394966434806
            ],
            [
              105.82076393073974,
              21.007382011398686
            ],
            [
              105.82061154271145,
              21.00725936986627
            ],
            [
              105.8205974081193,
              21.00724799412468
            ],
            [
              105.82049137750157,
              21.007162660770188
            ],
            [
              105.82048543576346,
              21.00715787882468
            ],
            [
              105.82047062296257,
              21.007145957179077
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Thái Thịnh",
          "maTaiSan": "04.O11.DOPP.1095",
          "duongKinh": 100,
          "chieudaiQL": 52.71,
          "chatLieu": "G",
          "from_latitude": 21.00747867151216,
          "to_latitude": 21.007145957179077,
          "to_longitude": 105.82047062296257,
          "from_longitude": 105.82088390942617
        }
      },
      {
        "id": 1092,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81969787328399,
            21.00503579396851,
            105.82213266019878,
            21.007719259156016
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82213266019878,
              21.00503579396851
            ],
            [
              105.82207689436778,
              21.005077513359453
            ],
            [
              105.82207200692915,
              21.005081169748475
            ],
            [
              105.82204549865162,
              21.005101000794955
            ],
            [
              105.82204401000342,
              21.00510211443907
            ],
            [
              105.82194485295696,
              21.005176293107947
            ],
            [
              105.82191537173732,
              21.005210920718756
            ],
            [
              105.8218543725698,
              21.005282561249913
            ],
            [
              105.8218020771755,
              21.00534398487252
            ],
            [
              105.82175052241816,
              21.00540453690189
            ],
            [
              105.82165998775166,
              21.00550761681325
            ],
            [
              105.82152402899581,
              21.00566456858198
            ],
            [
              105.8215098386726,
              21.005680950275654
            ],
            [
              105.82146504194337,
              21.005728520927956
            ],
            [
              105.82144968474117,
              21.005745760896016
            ],
            [
              105.82141824853895,
              21.005781049991093
            ],
            [
              105.82141119515434,
              21.00578896740581
            ],
            [
              105.82115828630526,
              21.00607287919538
            ],
            [
              105.82113076779727,
              21.00610414352176
            ],
            [
              105.82110980604821,
              21.006127959806182
            ],
            [
              105.82086706693065,
              21.006401486481433
            ],
            [
              105.82081086616432,
              21.006467601048175
            ],
            [
              105.82072930709008,
              21.006558126589493
            ],
            [
              105.8207132292372,
              21.00657623977795
            ],
            [
              105.82059485229935,
              21.006707962897764
            ],
            [
              105.8205708503055,
              21.00673502462418
            ],
            [
              105.81986564453824,
              21.00753010809158
            ],
            [
              105.81969787328399,
              21.007719259156016
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Vĩnh Hồ",
          "maTaiSan": "04.O11.DOPP.1096",
          "duongKinh": 100,
          "chieudaiQL": 383.4,
          "chatLieu": "G",
          "from_latitude": 21.00503579396851,
          "to_latitude": 21.007719259156016,
          "to_longitude": 105.81969787328399,
          "from_longitude": 105.82213266019878
        }
      },
      {
        "id": 1093,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81698340375279,
            21.009243165478306,
            105.81761812988941,
            21.010026127108162
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81698340375279,
              21.010026127108162
            ],
            [
              105.81699660632384,
              21.01001313581633
            ],
            [
              105.81699909107316,
              21.010010690589972
            ],
            [
              105.81700400412556,
              21.010005856408288
            ],
            [
              105.817207542892,
              21.009805573638374
            ],
            [
              105.81722123822082,
              21.009791931445665
            ],
            [
              105.81722627339539,
              21.009786916025735
            ],
            [
              105.81722847478915,
              21.0097847232544
            ],
            [
              105.8173061558936,
              21.00970734184698
            ],
            [
              105.81732843033393,
              21.00968238525061
            ],
            [
              105.81736847495485,
              21.009640657963867
            ],
            [
              105.81745236449551,
              21.00955047502593
            ],
            [
              105.81745370203518,
              21.009548977371086
            ],
            [
              105.81757626077047,
              21.009408120870397
            ],
            [
              105.81757418592322,
              21.009406526705767
            ],
            [
              105.81756952722446,
              21.00940295604675
            ],
            [
              105.81756070326979,
              21.00939616551979
            ],
            [
              105.81750538850179,
              21.009353661604894
            ],
            [
              105.81761812988941,
              21.009243165478306
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D90_Ngách 49, Thái Thịnh 1",
          "maTaiSan": "04.O11.DOPP.1097",
          "duongKinh": 90,
          "chieudaiQL": 115.28,
          "chatLieu": "HDPE",
          "from_latitude": 21.010026127108162,
          "to_latitude": 21.009243165478306,
          "to_longitude": 105.81761812988941,
          "from_longitude": 105.81698340375279
        }
      },
      {
        "id": 1094,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81710692873096,
            21.010555186132862,
            105.81757909069698,
            21.011029377180627
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81757909069698,
              21.010555186132862
            ],
            [
              105.81757583058739,
              21.010558502191778
            ],
            [
              105.81753724384745,
              21.010597722388738
            ],
            [
              105.8174430137886,
              21.010691352088486
            ],
            [
              105.81743745474303,
              21.010694320607215
            ],
            [
              105.81743714182439,
              21.010695148564068
            ],
            [
              105.81743592639117,
              21.010698403314006
            ],
            [
              105.81742757755966,
              21.0107066221502
            ],
            [
              105.81734445395942,
              21.010788494415294
            ],
            [
              105.81734227921038,
              21.010790636479484
            ],
            [
              105.81733757245976,
              21.0107952032277
            ],
            [
              105.8172468502994,
              21.01088866784457
            ],
            [
              105.81723801163608,
              21.01089755650993
            ],
            [
              105.81723353440525,
              21.010902055314205
            ],
            [
              105.8172318095198,
              21.01090378786064
            ],
            [
              105.81722288477707,
              21.010912767261466
            ],
            [
              105.81713607849888,
              21.011000066548462
            ],
            [
              105.81713495054123,
              21.011001192871074
            ],
            [
              105.81713432300297,
              21.011001830853917
            ],
            [
              105.81711856852549,
              21.011017668741076
            ],
            [
              105.81710692873096,
              21.011029377180627
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D90_Ngách 20, Thái Thịnh 1",
          "maTaiSan": "04.O11.DOPP.1098",
          "duongKinh": 90,
          "chieudaiQL": 72.05,
          "chatLieu": "HDPE",
          "from_latitude": 21.010555186132862,
          "to_latitude": 21.011029377180627,
          "to_longitude": 105.81710692873096,
          "from_longitude": 105.81757909069698
        }
      },
      {
        "id": 1095,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81498388191646,
            21.00823227397164,
            105.81807783918156,
            21.010956810130814
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81807783918156,
              21.010956810130814
            ],
            [
              105.81803418862968,
              21.010928925983944
            ],
            [
              105.81800160973839,
              21.010908111456704
            ],
            [
              105.81796213203417,
              21.010882884397958
            ],
            [
              105.81794575509743,
              21.01087098360951
            ],
            [
              105.81786965973555,
              21.01081563285844
            ],
            [
              105.8177983946879,
              21.010751729675427
            ],
            [
              105.81764681305819,
              21.010615773526176
            ],
            [
              105.81758535193435,
              21.010560691933822
            ],
            [
              105.8175826940261,
              21.0105583680535
            ],
            [
              105.81757909069698,
              21.010555186132862
            ],
            [
              105.81742941991514,
              21.010422427054255
            ],
            [
              105.81741348361088,
              21.010409111446002
            ],
            [
              105.81732151598457,
              21.01032951726374
            ],
            [
              105.81717913713919,
              21.01020192461532
            ],
            [
              105.8171725650382,
              21.010196029193423
            ],
            [
              105.81711493887484,
              21.010144388366097
            ],
            [
              105.81704104151501,
              21.010078148186636
            ],
            [
              105.81698340375279,
              21.010026127108162
            ],
            [
              105.81698254439337,
              21.010025350844906
            ],
            [
              105.816858030541,
              21.009912943917413
            ],
            [
              105.81685040944329,
              21.009906064482838
            ],
            [
              105.81681372777356,
              21.009872939441202
            ],
            [
              105.81678591135253,
              21.009847827731384
            ],
            [
              105.81670655535439,
              21.009776968294247
            ],
            [
              105.81669569172081,
              21.009767265568183
            ],
            [
              105.81656649876619,
              21.00965187582809
            ],
            [
              105.81650895741869,
              21.009600719407196
            ],
            [
              105.81648279163167,
              21.009577712375023
            ],
            [
              105.81643097331474,
              21.009532151062356
            ],
            [
              105.81642547856175,
              21.00952730902421
            ],
            [
              105.81642283903415,
              21.009524997681503
            ],
            [
              105.81626867150432,
              21.009377917623034
            ],
            [
              105.81623122571818,
              21.009342909267872
            ],
            [
              105.81613690619149,
              21.009258647860904
            ],
            [
              105.81612788106146,
              21.009250404024623
            ],
            [
              105.81612779696084,
              21.009250327653547
            ],
            [
              105.81612412067398,
              21.009246984371902
            ],
            [
              105.81612391664272,
              21.009246787543397
            ],
            [
              105.81611937517052,
              21.009242656279696
            ],
            [
              105.81611911797086,
              21.009242410931837
            ],
            [
              105.81596031424048,
              21.009102501860134
            ],
            [
              105.81595455274966,
              21.009097423535607
            ],
            [
              105.81595451801418,
              21.009097403831138
            ],
            [
              105.81583096042071,
              21.00899252172746
            ],
            [
              105.81569629376654,
              21.008878208878475
            ],
            [
              105.81567673399104,
              21.00886094077546
            ],
            [
              105.8156702490376,
              21.008855129777107
            ],
            [
              105.81566462629655,
              21.008850091422318
            ],
            [
              105.81553690980502,
              21.008735640350697
            ],
            [
              105.8153786468966,
              21.008593806035602
            ],
            [
              105.81535118382524,
              21.008567074698767
            ],
            [
              105.81514224298765,
              21.008363632564976
            ],
            [
              105.81511673034346,
              21.008338779592624
            ],
            [
              105.8150422575274,
              21.008279074291025
            ],
            [
              105.81498388191646,
              21.00823227397164
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D110_Thái Thịnh 1",
          "maTaiSan": "04.O11.DOPP.1099",
          "duongKinh": 110,
          "chieudaiQL": 441.15,
          "chatLieu": "HDPE",
          "from_latitude": 21.010956810130814,
          "to_latitude": 21.00823227397164,
          "to_longitude": 105.81498388191646,
          "from_longitude": 105.81807783918156
        }
      },
      {
        "id": 1096,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81937878375226,
            21.00425500543712,
            105.82036421715209,
            21.00444881526005
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81937878375226,
              21.00425500543712
            ],
            [
              105.81939685939211,
              21.00426086437045
            ],
            [
              105.81955324422006,
              21.004311552088858
            ],
            [
              105.81957890172487,
              21.00433087414482
            ],
            [
              105.81961941865939,
              21.00436138428525
            ],
            [
              105.81962802139212,
              21.00436786326335
            ],
            [
              105.8196529341186,
              21.004380083197084
            ],
            [
              105.81973721266955,
              21.0044214218143
            ],
            [
              105.8197744702676,
              21.004408152784848
            ],
            [
              105.81982706961055,
              21.004389419384363
            ],
            [
              105.82000276815191,
              21.004401396127538
            ],
            [
              105.82006543279459,
              21.004405788287812
            ],
            [
              105.8200797390684,
              21.004407097683533
            ],
            [
              105.82015788756053,
              21.004414252243688
            ],
            [
              105.8202230125944,
              21.004420214945515
            ],
            [
              105.82024571022119,
              21.004424871897214
            ],
            [
              105.82035502208628,
              21.004447302413986
            ],
            [
              105.82036396194796,
              21.004448758683512
            ],
            [
              105.82036421715209,
              21.00444881526005
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Thịnh Quang",
          "maTaiSan": "04.O11.DOPP.1100",
          "duongKinh": 100,
          "chieudaiQL": 108.27,
          "chatLieu": "G",
          "from_latitude": 21.00425500543712,
          "to_latitude": 21.00444881526005,
          "to_longitude": 105.82036421715209,
          "from_longitude": 105.81937878375226
        }
      },
      {
        "id": 1097,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81662868849523,
            21.006006251026655,
            105.81663062380015,
            21.006008997750236
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81663062380015,
              21.006006251026655
            ],
            [
              105.81663058184724,
              21.0060063189701
            ],
            [
              105.81662929546921,
              21.006008376374705
            ],
            [
              105.81662892852744,
              21.006008997750236
            ],
            [
              105.81662868849523,
              21.006008901353276
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Đường Láng",
          "maTaiSan": "04.O11.DOPP.1101",
          "duongKinh": 150,
          "chieudaiQL": 7,
          "chatLieu": "G",
          "from_latitude": 21.006006251026655,
          "to_latitude": 21.006008901353276,
          "to_longitude": 105.81662868849523,
          "from_longitude": 105.81663062380015
        }
      },
      {
        "id": 1098,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81853268187213,
            21.006305365745945,
            105.8191542808454,
            21.00659129151981
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8191542808454,
              21.00659129151981
            ],
            [
              105.81913265421966,
              21.00658042409971
            ],
            [
              105.81902329982383,
              21.006524022634732
            ],
            [
              105.81895278464991,
              21.006505927985565
            ],
            [
              105.81885326122304,
              21.00649896336098
            ],
            [
              105.81881575686907,
              21.00650889867101
            ],
            [
              105.81878019584367,
              21.00650038431631
            ],
            [
              105.81870784964984,
              21.006483061601323
            ],
            [
              105.81864831523285,
              21.00644695872601
            ],
            [
              105.81860360339435,
              21.00641984391318
            ],
            [
              105.81861805828693,
              21.006392114826518
            ],
            [
              105.81853268187213,
              21.006305365745945
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngách 141, Thịnh",
          "maTaiSan": "04.O11.DOPP.1102",
          "duongKinh": 100,
          "chieudaiQL": 78.5,
          "chatLieu": "G",
          "from_latitude": 21.00659129151981,
          "to_latitude": 21.006305365745945,
          "to_longitude": 105.81853268187213,
          "from_longitude": 105.8191542808454
        }
      },
      {
        "id": 1099,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81513482689077,
            21.008855129777107,
            105.8156702490376,
            21.009375748360572
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81513482689077,
              21.009375748360572
            ],
            [
              105.81517000913814,
              21.009341538953393
            ],
            [
              105.81556953019046,
              21.00895306454212
            ],
            [
              105.81565780056097,
              21.008867233996195
            ],
            [
              105.8156702490376,
              21.008855129777107
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D90_Thái Thịnh 1",
          "maTaiSan": "04.O11.DOPP.1103",
          "duongKinh": 90,
          "chieudaiQL": 80.13,
          "chatLieu": "HDPE",
          "from_latitude": 21.009375748360572,
          "to_latitude": 21.008855129777107,
          "to_longitude": 105.8156702490376,
          "from_longitude": 105.81513482689077
        }
      },
      {
        "id": 1100,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81589828508261,
            21.009250327653547,
            105.81612779696084,
            21.00947536021868
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81612779696084,
              21.009250327653547
            ],
            [
              105.81611436172207,
              21.0092635187054
            ],
            [
              105.81611433486826,
              21.009263533285846
            ],
            [
              105.81611279340974,
              21.009265033714954
            ],
            [
              105.81610902652554,
              21.009268731532675
            ],
            [
              105.81601608611318,
              21.009359857749708
            ],
            [
              105.81591632743792,
              21.009457668887023
            ],
            [
              105.81591619827238,
              21.009457795958312
            ],
            [
              105.81589828508261,
              21.00947536021868
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D90_Ngách 24, Thái Thịnh 1",
          "maTaiSan": "04.O11.DOPP.1104",
          "duongKinh": 90,
          "chieudaiQL": 33.52,
          "chatLieu": "HDPE",
          "from_latitude": 21.009250327653547,
          "to_latitude": 21.00947536021868,
          "to_longitude": 105.81589828508261,
          "from_longitude": 105.81612779696084
        }
      },
      {
        "id": 1101,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8159512172568,
            21.009524997681503,
            105.81642283903415,
            21.00999817047848
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81642283903415,
              21.009524997681503
            ],
            [
              105.81641539739535,
              21.009532707211786
            ],
            [
              105.81636872164536,
              21.00958106056342
            ],
            [
              105.81635829473336,
              21.009591862614897
            ],
            [
              105.81630885176122,
              21.00964308252435
            ],
            [
              105.81629858034648,
              21.009653294921534
            ],
            [
              105.81619956694497,
              21.00975159942244
            ],
            [
              105.81618974127389,
              21.00976135444829
            ],
            [
              105.8161853182905,
              21.009765746384353
            ],
            [
              105.81617457702393,
              21.00977641083565
            ],
            [
              105.81609430496813,
              21.009856107401067
            ],
            [
              105.81606996654862,
              21.009880271673282
            ],
            [
              105.81597287684639,
              21.009976665526892
            ],
            [
              105.81595969682088,
              21.009989750569368
            ],
            [
              105.8159512172568,
              21.00999817047848
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D90_Thái Thịnh 1",
          "maTaiSan": "04.O11.DOPP.1105",
          "duongKinh": 90,
          "chieudaiQL": 71.75,
          "chatLieu": "HDPE",
          "from_latitude": 21.009524997681503,
          "to_latitude": 21.00999817047848,
          "to_longitude": 105.8159512172568,
          "from_longitude": 105.81642283903415
        }
      },
      {
        "id": 1102,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8135159947842,
            21.009658845242587,
            105.81424187390292,
            21.01023684049584
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81424187390292,
              21.01023684049584
            ],
            [
              105.81405303260424,
              21.010118063497494
            ],
            [
              105.8139200018741,
              21.010028652468225
            ],
            [
              105.81390662474277,
              21.010013593694097
            ],
            [
              105.81390629859037,
              21.010013226736792
            ],
            [
              105.81390616890485,
              21.01001308103403
            ],
            [
              105.81390238471872,
              21.009981684840536
            ],
            [
              105.81376414974048,
              21.009889510306746
            ],
            [
              105.8136725139506,
              21.00982068079025
            ],
            [
              105.81364866637313,
              21.009786255351226
            ],
            [
              105.81362892558033,
              21.009742504306832
            ],
            [
              105.8135188660562,
              21.009660973071444
            ],
            [
              105.81351630769583,
              21.00965907677969
            ],
            [
              105.8135159947842,
              21.009658845242587
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 426/6, Đường Láng",
          "maTaiSan": "04.O11.DOPP.1106",
          "duongKinh": 100,
          "chieudaiQL": 100.75,
          "chatLieu": "G",
          "from_latitude": 21.01023684049584,
          "to_latitude": 21.009658845242587,
          "to_longitude": 105.8135159947842,
          "from_longitude": 105.81424187390292
        }
      },
      {
        "id": 1103,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8127274697491,
            21.010430083932626,
            105.81453965533684,
            21.01143198353156
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8127274697491,
              21.010430083932626
            ],
            [
              105.81272959693376,
              21.010431757391352
            ],
            [
              105.81273983285755,
              21.010439797819906
            ],
            [
              105.81276509231836,
              21.010459643949257
            ],
            [
              105.81291160156667,
              21.010525185998087
            ],
            [
              105.81300496002828,
              21.010581195386045
            ],
            [
              105.8130077690805,
              21.010583199777948
            ],
            [
              105.81318015269046,
              21.010706199697022
            ],
            [
              105.81319980801302,
              21.0107263715084
            ],
            [
              105.81321850056017,
              21.01075419103988
            ],
            [
              105.8132604736582,
              21.010822014400766
            ],
            [
              105.81327023590994,
              21.01083564714171
            ],
            [
              105.81331887311477,
              21.010871698276503
            ],
            [
              105.81337970829323,
              21.010897275056784
            ],
            [
              105.81365100151184,
              21.010989320393485
            ],
            [
              105.81377932352346,
              21.01104859803062
            ],
            [
              105.81379424802704,
              21.011057726037347
            ],
            [
              105.81385397293262,
              21.01113164242331
            ],
            [
              105.81386143842488,
              21.011140881067274
            ],
            [
              105.81395035950406,
              21.0111704998299
            ],
            [
              105.81404307437326,
              21.011210928298958
            ],
            [
              105.81418947754089,
              21.01128097230719
            ],
            [
              105.81425778394508,
              21.011310755478338
            ],
            [
              105.81433535050019,
              21.011344575993906
            ],
            [
              105.81443585573427,
              21.011391845946825
            ],
            [
              105.81449988522671,
              21.01141660495774
            ],
            [
              105.81451258124096,
              21.011421514650568
            ],
            [
              105.81453965533684,
              21.01143198353156
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 426/48, Đường Láng",
          "maTaiSan": "04.O11.DOPP.1107",
          "duongKinh": 100,
          "chieudaiQL": 222.82,
          "chatLieu": "G",
          "from_latitude": 21.010430083932626,
          "to_latitude": 21.01143198353156,
          "to_longitude": 105.81453965533684,
          "from_longitude": 105.8127274697491
        }
      },
      {
        "id": 1104,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81867346515102,
            21.00924668885089,
            105.81903830777587,
            21.00965740167576
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81867346515102,
              21.00965740167576
            ],
            [
              105.81868010094843,
              21.009649931669266
            ],
            [
              105.81903830777587,
              21.00924668885089
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 61, Thái Thịnh",
          "maTaiSan": "04.O11.DOPP.1108",
          "duongKinh": 100,
          "chieudaiQL": 61.05,
          "chatLieu": "G",
          "from_latitude": 21.00965740167576,
          "to_latitude": 21.00924668885089,
          "to_longitude": 105.81903830777587,
          "from_longitude": 105.81867346515102
        }
      },
      {
        "id": 1105,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82006768003794,
            21.005190065839596,
            105.82052409447753,
            21.005553408786408
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82052409447753,
              21.005553408786408
            ],
            [
              105.82047879792052,
              21.005517349004464
            ],
            [
              105.820458898627,
              21.00550150747508
            ],
            [
              105.82017479460377,
              21.005275337396426
            ],
            [
              105.8201555928833,
              21.00526005156595
            ],
            [
              105.8201288992721,
              21.005238805302955
            ],
            [
              105.82006768003794,
              21.005190065839596
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D90_Thịnh Quang",
          "maTaiSan": "04.O11.DOPP.1109",
          "duongKinh": 90,
          "chieudaiQL": 62.21,
          "chatLieu": "PVC",
          "from_latitude": 21.005553408786408,
          "to_latitude": 21.005190065839596,
          "to_longitude": 105.82006768003794,
          "from_longitude": 105.82052409447753
        }
      },
      {
        "id": 1106,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82004561731688,
            21.004342872105187,
            105.82076699978502,
            21.005213783430154
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82004561731688,
              21.005213783430154
            ],
            [
              105.82006768003794,
              21.005190065839596
            ],
            [
              105.8202322280024,
              21.004992863251474
            ],
            [
              105.82023876548571,
              21.0049813589419
            ],
            [
              105.82025592280608,
              21.004951167145407
            ],
            [
              105.82030234638978,
              21.004784135235347
            ],
            [
              105.82034612082249,
              21.00472848140096
            ],
            [
              105.82036795129041,
              21.004728107920762
            ],
            [
              105.82039260460712,
              21.004727691024723
            ],
            [
              105.82040882213346,
              21.004727414142955
            ],
            [
              105.82042591535965,
              21.00472580258344
            ],
            [
              105.82043342565248,
              21.004717360037002
            ],
            [
              105.82058255970436,
              21.00454972092184
            ],
            [
              105.82076699978502,
              21.004342872105187
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D110_Thịnh Quang",
          "maTaiSan": "04.O11.DOPP.1110",
          "duongKinh": 110,
          "chieudaiQL": 126.27,
          "chatLieu": "PVC",
          "from_latitude": 21.005213783430154,
          "to_latitude": 21.004342872105187,
          "to_longitude": 105.82076699978502,
          "from_longitude": 105.82004561731688
        }
      },
      {
        "id": 1107,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82058255970436,
            21.00454972092184,
            105.82103126007934,
            21.004899345390175
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82103126007934,
              21.004899345390175
            ],
            [
              105.82102303469085,
              21.00489293602072
            ],
            [
              105.82071620361785,
              21.004653856419097
            ],
            [
              105.82069875213331,
              21.004640257567562
            ],
            [
              105.82058255970436,
              21.00454972092184
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D90_Thịnh Quang",
          "maTaiSan": "04.O11.DOPP.1111",
          "duongKinh": 90,
          "chieudaiQL": 60.62,
          "chatLieu": "PVC",
          "from_latitude": 21.004899345390175,
          "to_latitude": 21.00454972092184,
          "to_longitude": 105.82058255970436,
          "from_longitude": 105.82103126007934
        }
      },
      {
        "id": 1108,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82034983585618,
            21.00468054982351,
            105.82085038086745,
            21.005105545958973
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82085038086745,
              21.005105545958973
            ],
            [
              105.82084314747004,
              21.005099895927692
            ],
            [
              105.82082379452117,
              21.005084754516197
            ],
            [
              105.82053857614217,
              21.004861606280233
            ],
            [
              105.82051837896772,
              21.004845804126017
            ],
            [
              105.82038748385565,
              21.004743395747695
            ],
            [
              105.82036795129041,
              21.004728107920762
            ],
            [
              105.82034983585618,
              21.004713929651004
            ],
            [
              105.82037347408583,
              21.00468054982351
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D90_Thịnh Quang",
          "maTaiSan": "04.O11.DOPP.1112",
          "duongKinh": 90,
          "chieudaiQL": 72.17,
          "chatLieu": "PVC",
          "from_latitude": 21.005105545958973,
          "to_latitude": 21.00468054982351,
          "to_longitude": 105.82037347408583,
          "from_longitude": 105.82085038086745
        }
      },
      {
        "id": 1109,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82023876548571,
            21.0049813589419,
            105.8206976025338,
            21.005338000456092
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8206976025338,
              21.005338000456092
            ],
            [
              105.82065987815994,
              21.005308678417418
            ],
            [
              105.82063995774813,
              21.005293194688726
            ],
            [
              105.82035330598539,
              21.005070389063086
            ],
            [
              105.8203342096111,
              21.005055545326204
            ],
            [
              105.82028537283729,
              21.00501759222106
            ],
            [
              105.82023876548571,
              21.0049813589419
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D90_Thịnh Quang",
          "maTaiSan": "04.O11.DOPP.1113",
          "duongKinh": 90,
          "chieudaiQL": 61.93,
          "chatLieu": "PVC",
          "from_latitude": 21.005338000456092,
          "to_latitude": 21.0049813589419,
          "to_longitude": 105.82023876548571,
          "from_longitude": 105.8206976025338
        }
      },
      {
        "id": 1110,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81719465329881,
            21.008633160571488,
            105.81875629926205,
            21.01017987001366
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81739660875539,
              21.008633160571488
            ],
            [
              105.81739194729938,
              21.008638437040236
            ],
            [
              105.81725026567302,
              21.008786029392216
            ],
            [
              105.81724825782902,
              21.008788081233764
            ],
            [
              105.81719465329881,
              21.008845306165377
            ],
            [
              105.81721363588234,
              21.008861827181445
            ],
            [
              105.81721719659593,
              21.008865141187766
            ],
            [
              105.81751414745406,
              21.009141434977085
            ],
            [
              105.81763474760514,
              21.009241568211454
            ],
            [
              105.81767413747053,
              21.00927653800503
            ],
            [
              105.81768649762228,
              21.009287435674093
            ],
            [
              105.81784519544863,
              21.009427357138495
            ],
            [
              105.81785836365651,
              21.00943896717049
            ],
            [
              105.81794476366906,
              21.00951364898869
            ],
            [
              105.81819635821412,
              21.009731121811246
            ],
            [
              105.8184303234497,
              21.009945545728815
            ],
            [
              105.81843496861597,
              21.009949802881316
            ],
            [
              105.8185118328712,
              21.010020248050573
            ],
            [
              105.81865980736534,
              21.0101168671493
            ],
            [
              105.8186617206743,
              21.010118116140827
            ],
            [
              105.81875556561168,
              21.01017939302643
            ],
            [
              105.81875629926205,
              21.01017987001366
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Ngõ 67, Thịnh Quang",
          "maTaiSan": "04.O11.DOPP.1114",
          "duongKinh": 150,
          "chieudaiQL": 251.09,
          "chatLieu": "G",
          "from_latitude": 21.008633160571488,
          "to_latitude": 21.01017987001366,
          "to_longitude": 105.81875629926205,
          "from_longitude": 105.81739660875539
        }
      },
      {
        "id": 1111,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81131916262436,
            21.009424424083946,
            105.81363971122376,
            21.010891737380483
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81363971122376,
              21.009424424083946
            ],
            [
              105.81362552415798,
              21.00942974690145
            ],
            [
              105.81362176831682,
              21.009432123189356
            ],
            [
              105.81355440961259,
              21.009474757889095
            ],
            [
              105.81339516147867,
              21.009568685399323
            ],
            [
              105.81308340621649,
              21.009752567892786
            ],
            [
              105.81269909180659,
              21.009979243624
            ],
            [
              105.81244703142768,
              21.010127914728447
            ],
            [
              105.81244577945704,
              21.01012870470862
            ],
            [
              105.81234792195724,
              21.010190711591413
            ],
            [
              105.81204290290236,
              21.01038555689073
            ],
            [
              105.81204088286475,
              21.010386794921953
            ],
            [
              105.81188525413977,
              21.010482279021158
            ],
            [
              105.81178646310012,
              21.01053699285858
            ],
            [
              105.81178605833384,
              21.010537207048404
            ],
            [
              105.81138458404784,
              21.01074943874397
            ],
            [
              105.81138177849198,
              21.0107509234818
            ],
            [
              105.81135321387205,
              21.01078049591801
            ],
            [
              105.81131916262436,
              21.010891737380483
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Đường Láng",
          "maTaiSan": "04.O11.DOPP.1115",
          "duongKinh": 100,
          "chieudaiQL": 291.81,
          "chatLieu": "G",
          "from_latitude": 21.009424424083946,
          "to_latitude": 21.010891737380483,
          "to_longitude": 105.81131916262436,
          "from_longitude": 105.81363971122376
        }
      },
      {
        "id": 1112,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81363971122376,
            21.00896027012521,
            105.81433574548164,
            21.009424424083946
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81433574548164,
              21.00896027012521
            ],
            [
              105.81422464719786,
              21.00903488184172
            ],
            [
              105.81417096115453,
              21.009070936368804
            ],
            [
              105.81407813858003,
              21.009133277804782
            ],
            [
              105.81389197180874,
              21.009258308058126
            ],
            [
              105.81370402111442,
              21.009384534390612
            ],
            [
              105.81364353825302,
              21.009422049261726
            ],
            [
              105.81364055293923,
              21.009423901606098
            ],
            [
              105.81363971122376,
              21.009424424083946
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Đường Láng",
          "maTaiSan": "04.O11.DOPP.1116",
          "duongKinh": 150,
          "chieudaiQL": 90.92,
          "chatLieu": "G",
          "from_latitude": 21.00896027012521,
          "to_latitude": 21.009424424083946,
          "to_longitude": 105.81363971122376,
          "from_longitude": 105.81433574548164
        }
      },
      {
        "id": 1113,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8151975799179,
            21.011139783025964,
            105.81637007225905,
            21.01196624367097
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81637007225905,
              21.011139783025964
            ],
            [
              105.81635333513876,
              21.01115200542068
            ],
            [
              105.81616554891113,
              21.011288512401105
            ],
            [
              105.8160801668041,
              21.011346003227544
            ],
            [
              105.81604670362482,
              21.01136931616243
            ],
            [
              105.81604326435055,
              21.011371711759598
            ],
            [
              105.816034314007,
              21.01137794811917
            ],
            [
              105.81602246389167,
              21.011386202648055
            ],
            [
              105.81590347402388,
              21.0114691033987
            ],
            [
              105.81589027270549,
              21.011478297432436
            ],
            [
              105.81587651272643,
              21.01148788433848
            ],
            [
              105.81586624568028,
              21.011495036553683
            ],
            [
              105.81577182506628,
              21.011560817944858
            ],
            [
              105.8157703167305,
              21.01156186930799
            ],
            [
              105.81575703970599,
              21.01157111879164
            ],
            [
              105.81575172601987,
              21.01157482040504
            ],
            [
              105.81570037923522,
              21.011610592332907
            ],
            [
              105.815645055956,
              21.01164974150978
            ],
            [
              105.8156385007132,
              21.01165437939628
            ],
            [
              105.81563046405535,
              21.01166006670898
            ],
            [
              105.81561632636482,
              21.011670072696674
            ],
            [
              105.81549526180618,
              21.01175574067214
            ],
            [
              105.81549026080921,
              21.011759280004426
            ],
            [
              105.81547939544575,
              21.011766968872514
            ],
            [
              105.81547787281984,
              21.011768046495252
            ],
            [
              105.81535723082916,
              21.011850787697725
            ],
            [
              105.81533954459579,
              21.01186291789882
            ],
            [
              105.81523353286155,
              21.011940286058135
            ],
            [
              105.81521878776681,
              21.011950929893572
            ],
            [
              105.8151975799179,
              21.01196624367097
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D110_Ngõ 82, Yên Lãng",
          "maTaiSan": "04.O11.DOPP.1117",
          "duongKinh": 110,
          "chieudaiQL": 153.38,
          "chatLieu": "HDPE",
          "from_latitude": 21.011139783025964,
          "to_latitude": 21.01196624367097,
          "to_longitude": 105.8151975799179,
          "from_longitude": 105.81637007225905
        }
      },
      {
        "id": 1114,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81431894627785,
            21.009999674163314,
            105.81544929049483,
            21.01081259511204
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81431894627785,
              21.01081259511204
            ],
            [
              105.81432346729757,
              21.010809059014115
            ],
            [
              105.81432349224282,
              21.01080904715277
            ],
            [
              105.81443367796719,
              21.01072292174948
            ],
            [
              105.81443815473664,
              21.01071424739342
            ],
            [
              105.81443833252828,
              21.01071421763915
            ],
            [
              105.81444784097783,
              21.01071261947914
            ],
            [
              105.81445686362234,
              21.010706052274127
            ],
            [
              105.81445688856755,
              21.010706040412774
            ],
            [
              105.81456020136301,
              21.010629670178435
            ],
            [
              105.81456022630819,
              21.01062965831705
            ],
            [
              105.8145606229881,
              21.010629372804416
            ],
            [
              105.81456064793333,
              21.010629360943017
            ],
            [
              105.8145702130167,
              21.0106226041673
            ],
            [
              105.81457023796195,
              21.010622592305918
            ],
            [
              105.81457115196966,
              21.010621919541457
            ],
            [
              105.81457117691487,
              21.010621907680072
            ],
            [
              105.81480404615003,
              21.01045185923965
            ],
            [
              105.8148300079726,
              21.01043289899644
            ],
            [
              105.81483003291778,
              21.010432887135025
            ],
            [
              105.81483385336233,
              21.01043018444571
            ],
            [
              105.81483387830754,
              21.010430172584293
            ],
            [
              105.81483686605036,
              21.010428058271152
            ],
            [
              105.81483689002378,
              21.010428044607927
            ],
            [
              105.81483691496895,
              21.010428032746518
            ],
            [
              105.81484590410406,
              21.010421677940762
            ],
            [
              105.81484592904923,
              21.010421666079342
            ],
            [
              105.81495535058252,
              21.010344422745774
            ],
            [
              105.81497778853002,
              21.010328581044
            ],
            [
              105.81497781347517,
              21.010328569182548
            ],
            [
              105.81510081511585,
              21.01024921219585
            ],
            [
              105.81511395436287,
              21.010239934520825
            ],
            [
              105.815113979308,
              21.010239922659363
            ],
            [
              105.81511577595455,
              21.01023864772687
            ],
            [
              105.81511580089968,
              21.010238635865417
            ],
            [
              105.81512306172722,
              21.010233391263085
            ],
            [
              105.81512308667234,
              21.010233379401612
            ],
            [
              105.81512950314463,
              21.01022870245821
            ],
            [
              105.81512952808973,
              21.010228690596737
            ],
            [
              105.81523617419145,
              21.01014347223148
            ],
            [
              105.81524347730868,
              21.010142294659225
            ],
            [
              105.81524692233474,
              21.010135649360976
            ],
            [
              105.8152688938257,
              21.010119654501608
            ],
            [
              105.8152689187708,
              21.010119642640124
            ],
            [
              105.81543034097298,
              21.010017547730804
            ],
            [
              105.81544929049483,
              21.009999674163314
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D90_Ngõ 120, Yên Lãng",
          "maTaiSan": "04.O11.DOPP.1118",
          "duongKinh": 90,
          "chieudaiQL": 148.9,
          "chatLieu": "HDPE",
          "from_latitude": 21.01081259511204,
          "to_latitude": 21.009999674163314,
          "to_longitude": 105.81544929049483,
          "from_longitude": 105.81431894627785
        }
      },
      {
        "id": 1115,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81472414361703,
            21.010971993431934,
            105.81528599937683,
            21.011369286046303
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81528599937683,
              21.010971993431934
            ],
            [
              105.81527808919412,
              21.010977585282824
            ],
            [
              105.81516066399345,
              21.01106061712063
            ],
            [
              105.81513125497392,
              21.011081413030208
            ],
            [
              105.81501021996496,
              21.011166999228635
            ],
            [
              105.81500900301064,
              21.01116785951153
            ],
            [
              105.81500200600624,
              21.011172806587265
            ],
            [
              105.81499960755467,
              21.011174502595924
            ],
            [
              105.81491135924817,
              21.011236903879464
            ],
            [
              105.81472414361703,
              21.011369286046303
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D90_Ngõ 82/71, Yên Lãng",
          "maTaiSan": "04.O11.DOPP.1119",
          "duongKinh": 90,
          "chieudaiQL": 74,
          "chatLieu": "HDPE",
          "from_latitude": 21.010971993431934,
          "to_latitude": 21.011369286046303,
          "to_longitude": 105.81472414361703,
          "from_longitude": 105.81528599937683
        }
      },
      {
        "id": 1116,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8138934590957,
            21.012153738784008,
            105.81720774844882,
            21.014717858195507
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81720774844882,
              21.012153738784008
            ],
            [
              105.8171865474703,
              21.01217028025788
            ],
            [
              105.8171971021309,
              21.01221575586552
            ],
            [
              105.81718753804375,
              21.012223574075257
            ],
            [
              105.81704461326461,
              21.012333056951736
            ],
            [
              105.81701617712265,
              21.012364867892604
            ],
            [
              105.81695946138468,
              21.01244629163115
            ],
            [
              105.81692930993424,
              21.012485491069313
            ],
            [
              105.81668846492607,
              21.012694074450796
            ],
            [
              105.81640483231122,
              21.012908552247875
            ],
            [
              105.81639230585328,
              21.0129182181815
            ],
            [
              105.8163142621619,
              21.01297601805689
            ],
            [
              105.81626835779298,
              21.013010409206903
            ],
            [
              105.81608739743118,
              21.013145942228398
            ],
            [
              105.81608149244924,
              21.01315023559352
            ],
            [
              105.81607441556024,
              21.0131553610329
            ],
            [
              105.8158991278217,
              21.013278606236774
            ],
            [
              105.81584566525213,
              21.0133172118534
            ],
            [
              105.8158373274231,
              21.01332325107363
            ],
            [
              105.8158207467634,
              21.013334933443545
            ],
            [
              105.8156233589651,
              21.013474037538426
            ],
            [
              105.81539341148125,
              21.01363632344745
            ],
            [
              105.81497971824805,
              21.01393678629337
            ],
            [
              105.81493092377838,
              21.013969432353964
            ],
            [
              105.81492428593064,
              21.01397393783628
            ],
            [
              105.81491648077112,
              21.01397923833074
            ],
            [
              105.81474092529174,
              21.01410791477574
            ],
            [
              105.81474081604661,
              21.01410799117005
            ],
            [
              105.81464833229695,
              21.014174785156605
            ],
            [
              105.81456361195012,
              21.014239050823214
            ],
            [
              105.81443932108218,
              21.014325207737638
            ],
            [
              105.81434737276165,
              21.014388794349838
            ],
            [
              105.81417421412168,
              21.014521671414627
            ],
            [
              105.814118375575,
              21.014564517695067
            ],
            [
              105.81390019860754,
              21.01470761225058
            ],
            [
              105.81389347823098,
              21.014717839136168
            ],
            [
              105.8138934590957,
              21.014717858195507
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D200_Thái Thịnh",
          "maTaiSan": "04.O11.DOPP.1120",
          "duongKinh": 200,
          "chieudaiQL": 448.95,
          "chatLieu": "G-DI",
          "from_latitude": 21.012153738784008,
          "to_latitude": 21.014717858195507,
          "to_longitude": 105.8138934590957,
          "from_longitude": 105.81720774844882
        }
      },
      {
        "id": 1117,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81608618239093,
            21.014056714329886,
            105.81659928546435,
            21.014654688436586
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81659928546435,
              21.014654688436586
            ],
            [
              105.8165969643127,
              21.014652036862028
            ],
            [
              105.81647722306454,
              21.014515119499524
            ],
            [
              105.81647327014318,
              21.014510815621232
            ],
            [
              105.81641744881497,
              21.014453836917507
            ],
            [
              105.81632367740585,
              21.01433393446598
            ],
            [
              105.81632016301837,
              21.01432943247925
            ],
            [
              105.81629946141983,
              21.014308244530824
            ],
            [
              105.81619095551895,
              21.014180065060305
            ],
            [
              105.81609202676684,
              21.014063199473117
            ],
            [
              105.81608618239093,
              21.014056714329886
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 165, Thái Hà",
          "maTaiSan": "04.O11.DOPP.1121",
          "duongKinh": 100,
          "chieudaiQL": 85.9,
          "chatLieu": "G-DI",
          "from_latitude": 21.014654688436586,
          "to_latitude": 21.014056714329886,
          "to_longitude": 105.81608618239093,
          "from_longitude": 105.81659928546435
        }
      },
      {
        "id": 1118,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82173175483314,
            21.01044674247429,
            105.82240824725847,
            21.011190950566117
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82173175483314,
              21.011190950566117
            ],
            [
              105.82189082136301,
              21.011028566976087
            ],
            [
              105.82207295440035,
              21.01082761419933
            ],
            [
              105.82216769565017,
              21.010718789973158
            ],
            [
              105.82227018233682,
              21.010601069416456
            ],
            [
              105.82227704250205,
              21.01059279251145
            ],
            [
              105.82232654607992,
              21.01053768855791
            ],
            [
              105.82240824725847,
              21.01044674247429
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D160_Thái Hà",
          "maTaiSan": "04.O11.DOPP.1122",
          "duongKinh": 160,
          "chieudaiQL": 108.38,
          "chatLieu": "PVC",
          "from_latitude": 21.011190950566117,
          "to_latitude": 21.01044674247429,
          "to_longitude": 105.82240824725847,
          "from_longitude": 105.82173175483314
        }
      },
      {
        "id": 1119,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81969787328399,
            21.00771925915601,
            105.82037433962373,
            21.008246541988758
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81969787328399,
              21.00771925915601
            ],
            [
              105.82035618448805,
              21.008232395541693
            ],
            [
              105.82037143098016,
              21.008244276263515
            ],
            [
              105.82037433962373,
              21.008246541988758
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 113, Vĩnh Hồ",
          "maTaiSan": "04.O11.DOPP.1123",
          "duongKinh": 100,
          "chieudaiQL": 92.52,
          "chatLieu": "G",
          "from_latitude": 21.00771925915601,
          "to_latitude": 21.008246541988758,
          "to_longitude": 105.82037433962373,
          "from_longitude": 105.81969787328399
        }
      },
      {
        "id": 1120,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82076699978502,
            21.004342872105187,
            105.82123969130028,
            21.00470909818925
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82076699978502,
              21.004342872105187
            ],
            [
              105.82089839645266,
              21.004444674423393
            ],
            [
              105.82111793448459,
              21.004614765404916
            ],
            [
              105.82120467897056,
              21.004681972477517
            ],
            [
              105.82123969130028,
              21.00470909818925
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D90_Thịnh Quang",
          "maTaiSan": "04.O11.DOPP.1124",
          "duongKinh": 90,
          "chieudaiQL": 57.75,
          "chatLieu": "PVC",
          "from_latitude": 21.004342872105187,
          "to_latitude": 21.00470909818925,
          "to_longitude": 105.82123969130028,
          "from_longitude": 105.82076699978502
        }
      },
      {
        "id": 1121,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83837236500887,
            21.004667096324766,
            105.83904202981803,
            21.004819799588518
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83837236500887,
              21.004667096324766
            ],
            [
              105.8383735813859,
              21.004667372139377
            ],
            [
              105.83854971374089,
              21.004706854228875
            ],
            [
              105.83855465140961,
              21.004707960739456
            ],
            [
              105.83855822248222,
              21.004708761467118
            ],
            [
              105.83857670380335,
              21.004712898407124
            ],
            [
              105.83877080832919,
              21.00475816087059
            ],
            [
              105.83877952746361,
              21.004760193512247
            ],
            [
              105.83892649497086,
              21.004793599325822
            ],
            [
              105.83900032718839,
              21.004810346079356
            ],
            [
              105.83900541518307,
              21.004811500609787
            ],
            [
              105.83904202981803,
              21.004819799588518
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 6, Phương Mai",
          "maTaiSan": "04.O17.DOPP.1125",
          "duongKinh": 100,
          "chieudaiQL": 72.96,
          "chatLieu": "G-DI",
          "from_latitude": 21.004667096324766,
          "to_latitude": 21.004819799588518,
          "to_longitude": 105.83904202981803,
          "from_longitude": 105.83837236500887
        }
      },
      {
        "id": 1122,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82123469604068,
            21.002632602914105,
            105.82139611012983,
            21.003062632140473
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82133226897666,
              21.003062632140473
            ],
            [
              105.82128167851674,
              21.00292453963181
            ],
            [
              105.82128150567782,
              21.002924066270975
            ],
            [
              105.82123469604068,
              21.00279628481852
            ],
            [
              105.82139611012983,
              21.00274849564626
            ],
            [
              105.8213874172489,
              21.0027167511894
            ],
            [
              105.82136437911838,
              21.002632602914105
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Trường Chinh",
          "maTaiSan": "04.O18.DOPP.1126",
          "duongKinh": 150,
          "chieudaiQL": 44.88,
          "chatLieu": "G",
          "from_latitude": 21.003062632140473,
          "to_latitude": 21.002632602914105,
          "to_longitude": 105.82136437911838,
          "from_longitude": 105.82133226897666
        }
      },
      {
        "id": 1123,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81429110182329,
            21.008643869567493,
            105.81449790585509,
            21.00879331713853
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81449790585509,
              21.00879331713853
            ],
            [
              105.81447664825068,
              21.008777956509416
            ],
            [
              105.8144635564878,
              21.00876849913724
            ],
            [
              105.81429110182329,
              21.008643869567493
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Đường Láng",
          "maTaiSan": "04.O11.DOPP.1127",
          "duongKinh": 150,
          "chieudaiQL": 27.13,
          "chatLieu": "G",
          "from_latitude": 21.00879331713853,
          "to_latitude": 21.008643869567493,
          "to_longitude": 105.81429110182329,
          "from_longitude": 105.81449790585509
        }
      },
      {
        "id": 1124,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82101747639302,
            21.011873812224973,
            105.82107425667435,
            21.011961457221762
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82107010864722,
              21.011873812224973
            ],
            [
              105.82107012607496,
              21.0118738329151
            ],
            [
              105.82107425667435,
              21.011878427571148
            ],
            [
              105.82106965486388,
              21.011882116879768
            ],
            [
              105.82103665237929,
              21.011913201727346
            ],
            [
              105.82101747639302,
              21.011931268478342
            ],
            [
              105.82104907411788,
              21.011961457221762
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Thái Hà",
          "maTaiSan": "04.O11.DOPP.1128",
          "duongKinh": 150,
          "chieudaiQL": 13.66,
          "chatLieu": "G-DI",
          "from_latitude": 21.011873812224973,
          "to_latitude": 21.011961457221762,
          "to_longitude": 105.82104907411788,
          "from_longitude": 105.82107010864722
        }
      },
      {
        "id": 1125,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82368405906587,
            21.009564895705214,
            105.82376189786882,
            21.009594832145915
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82368405906587,
              21.00957013715738
            ],
            [
              105.82369490505957,
              21.009587237843398
            ],
            [
              105.82372442769233,
              21.00957357182222
            ],
            [
              105.82374377484464,
              21.009564895705214
            ],
            [
              105.82375697643825,
              21.009586708861736
            ],
            [
              105.82376189786882,
              21.009594832145915
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Thái Hà",
          "maTaiSan": "04.O11.DOPP.1129",
          "duongKinh": 150,
          "chieudaiQL": 11.67,
          "chatLieu": "G",
          "from_latitude": 21.00957013715738,
          "to_latitude": 21.009594832145915,
          "to_longitude": 105.82376189786882,
          "from_longitude": 105.82368405906587
        }
      },
      {
        "id": 1126,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83961505048663,
            21.00406591700404,
            105.83961651646541,
            21.004268820149672
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83961651646541,
              21.004268820149672
            ],
            [
              105.83961649383825,
              21.00426580710306
            ],
            [
              105.83961505048663,
              21.00406591700404
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D90_Phương Mai",
          "maTaiSan": "04.O17.DOPP.1130",
          "duongKinh": 90,
          "chieudaiQL": 22.46,
          "chatLieu": "HDPE",
          "from_latitude": 21.004268820149672,
          "to_latitude": 21.00406591700404,
          "to_longitude": 105.83961505048663,
          "from_longitude": 105.83961651646541
        }
      },
      {
        "id": 1127,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83922517664784,
            21.004108871805595,
            105.83924819524644,
            21.004211687403092
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83924819524644,
              21.004108871805595
            ],
            [
              105.83922587952792,
              21.004208547940305
            ],
            [
              105.83922517664784,
              21.004211687403092
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D200_Phương Mai",
          "maTaiSan": "04.O17.DOPP.1131",
          "duongKinh": 200,
          "chieudaiQL": 11.63,
          "chatLieu": "G-DI",
          "from_latitude": 21.004108871805595,
          "to_latitude": 21.004211687403092,
          "to_longitude": 105.83922517664784,
          "from_longitude": 105.83924819524644
        }
      },
      {
        "id": 1128,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8352757364678,
            21.006191491053933,
            105.83542445211104,
            21.006352725962667
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83542445211104,
              21.006191491053933
            ],
            [
              105.83541481074168,
              21.006201943537512
            ],
            [
              105.8352757364678,
              21.006352725962667
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Đông Tác",
          "maTaiSan": "04.O17.DOPP.1132",
          "duongKinh": 100,
          "chieudaiQL": 23.62,
          "chatLieu": "G",
          "from_latitude": 21.006191491053933,
          "to_latitude": 21.006352725962667,
          "to_longitude": 105.8352757364678,
          "from_longitude": 105.83542445211104
        }
      },
      {
        "id": 1129,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81477630602348,
            21.016304351364354,
            105.81502204526083,
            21.01653616317516
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81477630602348,
              21.01653616317516
            ],
            [
              105.8149224048246,
              21.01648435515405
            ],
            [
              105.81498703986254,
              21.016364853778253
            ],
            [
              105.81499285695067,
              21.016354092854883
            ],
            [
              105.81502203095474,
              21.01630437401359
            ],
            [
              105.81502204526083,
              21.016304351364354
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Láng Hạ",
          "maTaiSan": "04.O11.DOPP.1133",
          "duongKinh": 150,
          "chieudaiQL": 37.22,
          "chatLieu": "G",
          "from_latitude": 21.01653616317516,
          "to_latitude": 21.016304351364354,
          "to_longitude": 105.81502204526083,
          "from_longitude": 105.81477630602348
        }
      },
      {
        "id": 1130,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84008319285707,
            21.00775651593627,
            105.8400906061444,
            21.007798616567786
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8400906061444,
              21.007798616567786
            ],
            [
              105.84009056645651,
              21.007798399989838
            ],
            [
              105.84008319285707,
              21.00775651593627
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Đào Duy Anh",
          "maTaiSan": "04.O17.DOPP.1134",
          "duongKinh": 100,
          "chieudaiQL": 4.72,
          "chatLieu": "G",
          "from_latitude": 21.007798616567786,
          "to_latitude": 21.00775651593627,
          "to_longitude": 105.84008319285707,
          "from_longitude": 105.8400906061444
        }
      },
      {
        "id": 1131,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8369944433598,
            21.00365002879787,
            105.83823438247228,
            21.00395414107949
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8369944433598,
              21.00365002879787
            ],
            [
              105.83748855210202,
              21.00377149823677
            ],
            [
              105.8382315095895,
              21.00395414107949
            ],
            [
              105.83823291783236,
              21.003950021759984
            ],
            [
              105.83823438247228,
              21.00394446151857
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Phương Mai",
          "maTaiSan": "04.O17.DOPP.1135",
          "duongKinh": 150,
          "chieudaiQL": 134.06,
          "chatLieu": "G-DI",
          "from_latitude": 21.00365002879787,
          "to_latitude": 21.00394446151857,
          "to_longitude": 105.83823438247228,
          "from_longitude": 105.8369944433598
        }
      },
      {
        "id": 1132,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83026589103585,
            21.003754309565117,
            105.83044989321209,
            21.003799161839744
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83044989321209,
              21.003754309565117
            ],
            [
              105.83035237945055,
              21.003778079524835
            ],
            [
              105.83026589103585,
              21.003799161839744
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D200_Ngõ 1A, Tôn Thất Tùng",
          "maTaiSan": "04.O17.DOPP.1136",
          "duongKinh": 200,
          "chieudaiQL": 19.76,
          "chatLieu": "G",
          "from_latitude": 21.003754309565117,
          "to_latitude": 21.003799161839744,
          "to_longitude": 105.83026589103585,
          "from_longitude": 105.83044989321209
        }
      },
      {
        "id": 1133,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83026740002282,
            21.00376757200634,
            105.83041038600109,
            21.003803477321167
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83041038600109,
              21.00376757200634
            ],
            [
              105.83035332397954,
              21.00378190098455
            ],
            [
              105.83026740002282,
              21.003803477321167
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 1A, Tôn Thất Tùng",
          "maTaiSan": "04.O17.DOPP.1137",
          "duongKinh": 100,
          "chieudaiQL": 15.39,
          "chatLieu": "G",
          "from_latitude": 21.00376757200634,
          "to_latitude": 21.003803477321167,
          "to_longitude": 105.83026740002282,
          "from_longitude": 105.83041038600109
        }
      },
      {
        "id": 1134,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82960280758583,
            21.001983067210574,
            105.82969726619024,
            21.0020101935703
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82969726619024,
              21.001983067210574
            ],
            [
              105.82962859714087,
              21.00200278786812
            ],
            [
              105.82960280758583,
              21.0020101935703
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Tôn Thất Tùng",
          "maTaiSan": "04.O17.DOPP.1138",
          "duongKinh": 100,
          "chieudaiQL": 10.27,
          "chatLieu": "G",
          "from_latitude": 21.001983067210574,
          "to_latitude": 21.0020101935703,
          "to_longitude": 105.82960280758583,
          "from_longitude": 105.82969726619024
        }
      },
      {
        "id": 1135,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8209280862892,
            21.00534586156257,
            105.82141132246967,
            21.005720142635685
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82141132246967,
              21.005720142635685
            ],
            [
              105.82124491986131,
              21.005580742116866
            ],
            [
              105.82108655695141,
              21.005459107067292
            ],
            [
              105.82099937557615,
              21.005398092783487
            ],
            [
              105.8209280862892,
              21.00534586156257
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Vĩnh Hồ",
          "maTaiSan": "04.O11.DOPP.1139",
          "duongKinh": 100,
          "chieudaiQL": 65.16,
          "chatLieu": "G",
          "from_latitude": 21.005720142635685,
          "to_latitude": 21.00534586156257,
          "to_longitude": 105.8209280862892,
          "from_longitude": 105.82141132246967
        }
      },
      {
        "id": 1136,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8213101370559,
            21.005311392580737,
            105.82177294056105,
            21.00583702812664
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8213101370559,
              21.00583702812664
            ],
            [
              105.82141132246967,
              21.005720142635685
            ],
            [
              105.82141439596258,
              21.00571668289098
            ],
            [
              105.82170981634518,
              21.005384057647706
            ],
            [
              105.82171549409001,
              21.005377522478863
            ],
            [
              105.82176749031649,
              21.00531766652333
            ],
            [
              105.82177294056105,
              21.005311392580737
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Vĩnh Hồ",
          "maTaiSan": "04.O11.DOPP.1140",
          "duongKinh": 150,
          "chieudaiQL": 74.51,
          "chatLieu": "G",
          "from_latitude": 21.00583702812664,
          "to_latitude": 21.005311392580737,
          "to_longitude": 105.82177294056105,
          "from_longitude": 105.8213101370559
        }
      },
      {
        "id": 1137,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82126927017764,
            21.004933290195957,
            105.82177294056105,
            21.005311392580737
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82177294056105,
              21.005311392580737
            ],
            [
              105.82153376296279,
              21.00513546129355
            ],
            [
              105.82153185558863,
              21.005134058755107
            ],
            [
              105.82152688095616,
              21.005130399403807
            ],
            [
              105.82141006781427,
              21.005041020674316
            ],
            [
              105.82140564681477,
              21.005037637744753
            ],
            [
              105.82131332845842,
              21.004967001029662
            ],
            [
              105.82131141111896,
              21.0049655344075
            ],
            [
              105.82126927017764,
              21.004933290195957
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Vĩnh Hồ",
          "maTaiSan": "04.O11.DOPP.1141",
          "duongKinh": 100,
          "chieudaiQL": 67.04,
          "chatLieu": "G",
          "from_latitude": 21.005311392580737,
          "to_latitude": 21.004933290195957,
          "to_longitude": 105.82126927017764,
          "from_longitude": 105.82177294056105
        }
      },
      {
        "id": 1138,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82139588563444,
            21.005130761587495,
            105.82170981634518,
            21.005384057647706
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82170981634518,
              21.005384057647706
            ],
            [
              105.82151711286849,
              21.005228574134232
            ],
            [
              105.82151511972843,
              21.005226966976878
            ],
            [
              105.82139588563444,
              21.005130761587495
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Vĩnh Hồ",
          "maTaiSan": "04.O11.DOPP.1142",
          "duongKinh": 100,
          "chieudaiQL": 43.03,
          "chatLieu": "G",
          "from_latitude": 21.005384057647706,
          "to_latitude": 21.005130761587495,
          "to_longitude": 105.82139588563444,
          "from_longitude": 105.82170981634518
        }
      },
      {
        "id": 1139,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82086326928173,
            21.005505145925586,
            105.8213101370559,
            21.00583702812664
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82086326928173,
              21.005505145925586
            ],
            [
              105.82087905890111,
              21.005516835218124
            ],
            [
              105.82088149190155,
              21.005518670584568
            ],
            [
              105.82110673553996,
              21.005685964699904
            ],
            [
              105.8211084672234,
              21.005687251574162
            ],
            [
              105.8213101370559,
              21.00583702812664
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Vĩnh Hồ",
          "maTaiSan": "04.O11.DOPP.1143",
          "duongKinh": 100,
          "chieudaiQL": 59.23,
          "chatLieu": "G",
          "from_latitude": 21.005505145925586,
          "to_latitude": 21.00583702812664,
          "to_longitude": 105.8213101370559,
          "from_longitude": 105.82086326928173
        }
      },
      {
        "id": 1140,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82053982786948,
            21.00671078252875,
            105.821044770077,
            21.007105354715346
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82053982786948,
              21.00671078252875
            ],
            [
              105.82055625486504,
              21.00672361948606
            ],
            [
              105.8205708503055,
              21.00673502462418
            ],
            [
              105.82075032157043,
              21.006875267774753
            ],
            [
              105.82075279648537,
              21.00687720048864
            ],
            [
              105.82096481598698,
              21.007042877581075
            ],
            [
              105.82096941130882,
              21.007046467421393
            ],
            [
              105.82097726789918,
              21.007052606696128
            ],
            [
              105.82097974282982,
              21.007054541212895
            ],
            [
              105.821044770077,
              21.007105354715346
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Vĩnh Hồ",
          "maTaiSan": "04.O11.DOPP.1144",
          "duongKinh": 100,
          "chieudaiQL": 68.29,
          "chatLieu": "G",
          "from_latitude": 21.00671078252875,
          "to_latitude": 21.007105354715346,
          "to_longitude": 105.821044770077,
          "from_longitude": 105.82053982786948
        }
      },
      {
        "id": 1141,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83498469903344,
            21.025318341257844,
            105.83584953898641,
            21.025642538043325
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83584953898641,
              21.025389304349158
            ],
            [
              105.8358421222399,
              21.02539211279399
            ],
            [
              105.83582641765155,
              21.025398160285366
            ],
            [
              105.83582342456027,
              21.025399312153866
            ],
            [
              105.83582216320198,
              21.025399797964713
            ],
            [
              105.83574379860192,
              21.025429969588153
            ],
            [
              105.835740021244,
              21.025431424276263
            ],
            [
              105.83571132132013,
              21.02536884548143
            ],
            [
              105.83568853563102,
              21.025333505932675
            ],
            [
              105.83565521944404,
              21.02532120786988
            ],
            [
              105.83565354404233,
              21.02532058926532
            ],
            [
              105.83565153799441,
              21.02531984854385
            ],
            [
              105.83564745456667,
              21.025318341257844
            ],
            [
              105.83558068379389,
              21.025342783594223
            ],
            [
              105.83557932499814,
              21.025343565236078
            ],
            [
              105.83557807552992,
              21.025344285824797
            ],
            [
              105.83557804579945,
              21.02534430222891
            ],
            [
              105.8355757457209,
              21.02534517610818
            ],
            [
              105.8354772954139,
              21.025382579050444
            ],
            [
              105.83536850547665,
              21.025431374502865
            ],
            [
              105.83536556298588,
              21.02543297592015
            ],
            [
              105.83511904676973,
              21.025567156492126
            ],
            [
              105.83511596712383,
              21.02556883264306
            ],
            [
              105.83509722550394,
              21.025579034455102
            ],
            [
              105.83499469615296,
              21.025637357746064
            ],
            [
              105.8349926237653,
              21.025638524047345
            ],
            [
              105.83498469903344,
              21.025642538043325
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Ngõ Thông Phong, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DOPP.1145",
          "duongKinh": 150,
          "chieudaiQL": 80,
          "chatLieu": "G-DI",
          "from_latitude": 21.025389304349158,
          "to_latitude": 21.025642538043325,
          "to_longitude": 105.83498469903344,
          "from_longitude": 105.83584953898641
        }
      },
      {
        "id": 1142,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8366814515889,
            21.019146749437446,
            105.83753690043953,
            21.01996004268123
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83668610920597,
              21.019146749437446
            ],
            [
              105.83668548398694,
              21.01915227662975
            ],
            [
              105.8366814515889,
              21.019187991870293
            ],
            [
              105.83668533772916,
              21.019220030879957
            ],
            [
              105.83670237861989,
              21.01926344090211
            ],
            [
              105.83675932788925,
              21.019375730462208
            ],
            [
              105.83676220404682,
              21.019381401204797
            ],
            [
              105.83676734629101,
              21.019388916021835
            ],
            [
              105.83684259852674,
              21.019498899002063
            ],
            [
              105.83684844684862,
              21.01950744634038
            ],
            [
              105.8368619969161,
              21.019516389335536
            ],
            [
              105.83687833308764,
              21.01953934207486
            ],
            [
              105.83687859389956,
              21.019539708403798
            ],
            [
              105.83688225180903,
              21.019542734271578
            ],
            [
              105.8368825571968,
              21.019542986575004
            ],
            [
              105.83690432037208,
              21.01957828045455
            ],
            [
              105.83696098129123,
              21.0196612617432
            ],
            [
              105.83697370117929,
              21.01967726660386
            ],
            [
              105.83697595355886,
              21.019680100676013
            ],
            [
              105.83698005143697,
              21.019685256887954
            ],
            [
              105.83700288319652,
              21.019708277910237
            ],
            [
              105.8370516409251,
              21.019751138016204
            ],
            [
              105.83706600571797,
              21.019762132721894
            ],
            [
              105.83707644617368,
              21.019768558361662
            ],
            [
              105.83712315429416,
              21.01983215029025
            ],
            [
              105.83713249282472,
              21.01984486507656
            ],
            [
              105.83715040039112,
              21.01986548244576
            ],
            [
              105.83717150447245,
              21.01989900650227
            ],
            [
              105.83719251972455,
              21.019929023775394
            ],
            [
              105.83719938323311,
              21.01993882700393
            ],
            [
              105.83721350492316,
              21.019958997838756
            ],
            [
              105.8372342795576,
              21.01996004268123
            ],
            [
              105.83723584402118,
              21.019959409046933
            ],
            [
              105.83725053271492,
              21.01995346218155
            ],
            [
              105.83729555866364,
              21.019932490796066
            ],
            [
              105.83730971015022,
              21.019939733635564
            ],
            [
              105.83733460899059,
              21.01992618284438
            ],
            [
              105.83737689827201,
              21.019903169172267
            ],
            [
              105.83740400771228,
              21.019888416136798
            ],
            [
              105.83740216207188,
              21.01988520884341
            ],
            [
              105.83739161662362,
              21.01986687564051
            ],
            [
              105.8373947080237,
              21.019864573459483
            ],
            [
              105.83744768140808,
              21.019825124502358
            ],
            [
              105.83750364512476,
              21.01980627688606
            ],
            [
              105.83752091828967,
              21.019791836906343
            ],
            [
              105.83752842598648,
              21.0197855604719
            ],
            [
              105.83753690043953,
              21.019782209334593
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ Liên Hoa, Khâm Thiên",
          "maTaiSan": "04.O14.DOPP.1146",
          "duongKinh": 100,
          "chieudaiQL": 141,
          "chatLieu": "G-DI",
          "from_latitude": 21.019146749437446,
          "to_latitude": 21.019782209334593,
          "to_longitude": 105.83753690043953,
          "from_longitude": 105.83668610920597
        }
      },
      {
        "id": 1143,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83682734522463,
            21.029221598838244,
            105.83843164741144,
            21.029658388854145
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83843164741144,
              21.029658388854145
            ],
            [
              105.83842060039686,
              21.029634126770123
            ],
            [
              105.83840760432778,
              21.029602986320853
            ],
            [
              105.83839867811955,
              21.029581564347996
            ],
            [
              105.83833813339187,
              21.029441446088118
            ],
            [
              105.83833186597346,
              21.02943717674592
            ],
            [
              105.83831731861005,
              21.029427266955647
            ],
            [
              105.83818900331259,
              21.029312437877405
            ],
            [
              105.83805553679248,
              21.029236360654732
            ],
            [
              105.83804996527789,
              21.02923318528713
            ],
            [
              105.83800064779307,
              21.029221598838244
            ],
            [
              105.83790060616661,
              21.029227989884923
            ],
            [
              105.83785350992581,
              21.029245474408953
            ],
            [
              105.83784566071411,
              21.029248388945877
            ],
            [
              105.83783919778328,
              21.029250788137457
            ],
            [
              105.83783564950757,
              21.02925210535826
            ],
            [
              105.837813779035,
              21.02926022470404
            ],
            [
              105.83781043524512,
              21.02926146594932
            ],
            [
              105.83776408442706,
              21.02927324834476
            ],
            [
              105.83773185439004,
              21.029280936399438
            ],
            [
              105.8377137530356,
              21.029285254466842
            ],
            [
              105.83764088092872,
              21.02931438742643
            ],
            [
              105.83759085680525,
              21.0293343855195
            ],
            [
              105.83758483932162,
              21.029336791539603
            ],
            [
              105.83753057842286,
              21.0293562721643
            ],
            [
              105.83748111259156,
              21.029423164602672
            ],
            [
              105.8373815808461,
              21.029453350044587
            ],
            [
              105.83735499127653,
              21.02946141399229
            ],
            [
              105.83733374631483,
              21.029467856525734
            ],
            [
              105.83728245626482,
              21.029483411489306
            ],
            [
              105.83722082943531,
              21.029502100935876
            ],
            [
              105.83721270734269,
              21.029504563370022
            ],
            [
              105.83721182185805,
              21.029504832374098
            ],
            [
              105.83720849215786,
              21.029505842313437
            ],
            [
              105.83720493388024,
              21.029506921119825
            ],
            [
              105.83712180548791,
              21.029523838595846
            ],
            [
              105.83711353574392,
              21.029526391171277
            ],
            [
              105.83705413780233,
              21.029544720941388
            ],
            [
              105.83704885384175,
              21.029546351076537
            ],
            [
              105.83686412332764,
              21.02959348097349
            ],
            [
              105.83682734522463,
              21.02960505161766
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Thanh Miến",
          "maTaiSan": "04.O14.DOPP.1147",
          "duongKinh": 100,
          "chieudaiQL": 191,
          "chatLieu": "G-DI",
          "from_latitude": 21.029658388854145,
          "to_latitude": 21.02960505161766,
          "to_longitude": 105.83682734522463,
          "from_longitude": 105.83843164741144
        }
      },
      {
        "id": 1144,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8379085460384,
            21.019042874119965,
            105.83863624627045,
            21.019925099841707
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83863422948585,
              21.019042874119965
            ],
            [
              105.83863491566323,
              21.019055313544413
            ],
            [
              105.83863624627045,
              21.01907943569775
            ],
            [
              105.83863568342896,
              21.019112962516967
            ],
            [
              105.83858992204802,
              21.01914787280501
            ],
            [
              105.83841121681512,
              21.01924211449537
            ],
            [
              105.83841217546275,
              21.019249953363683
            ],
            [
              105.83841452415349,
              21.019269150642604
            ],
            [
              105.8384189784049,
              21.019284876390866
            ],
            [
              105.8384428256429,
              21.019369060315306
            ],
            [
              105.83845514455918,
              21.01939832003706
            ],
            [
              105.83848002575048,
              21.019434467237573
            ],
            [
              105.83848454616351,
              21.019441033970914
            ],
            [
              105.83848172021483,
              21.019458807081474
            ],
            [
              105.83846735024116,
              21.019483373335706
            ],
            [
              105.83843797085393,
              21.019509327761025
            ],
            [
              105.83838498689045,
              21.019546988686983
            ],
            [
              105.83836821898113,
              21.01955232899416
            ],
            [
              105.83835784314284,
              21.01955282271168
            ],
            [
              105.83835513690521,
              21.019552951662053
            ],
            [
              105.83835422424632,
              21.019552994998712
            ],
            [
              105.8383179400359,
              21.019573128739495
            ],
            [
              105.83826321969958,
              21.019598918990173
            ],
            [
              105.83824527425062,
              21.019610955265154
            ],
            [
              105.83824143790211,
              21.019613528486353
            ],
            [
              105.83822323273445,
              21.019625739457844
            ],
            [
              105.83820741241082,
              21.019635910938916
            ],
            [
              105.83820428668695,
              21.019637920658983
            ],
            [
              105.8381960434354,
              21.019643219846113
            ],
            [
              105.83816116128656,
              21.019674291005384
            ],
            [
              105.83814657987074,
              21.019687544497714
            ],
            [
              105.8381158875862,
              21.019715441849865
            ],
            [
              105.83810869457517,
              21.019721563210986
            ],
            [
              105.83805722923748,
              21.01975833591303
            ],
            [
              105.83800996231135,
              21.019807063757668
            ],
            [
              105.8380059844214,
              21.019811164118895
            ],
            [
              105.8379529220276,
              21.01989886930617
            ],
            [
              105.8379085460384,
              21.019925099841707
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D200_Ngõ Trung Tiền, Khâm Thiên",
          "maTaiSan": "04.O14.DOPP.1148",
          "duongKinh": 200,
          "chieudaiQL": 144,
          "chatLieu": "G-DI",
          "from_latitude": 21.019042874119965,
          "to_latitude": 21.019925099841707,
          "to_longitude": 105.8379085460384,
          "from_longitude": 105.83863422948585
        }
      },
      {
        "id": 1145,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83668386869824,
            21.020248065239905,
            105.83754367798147,
            21.022332676997276
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83751923750343,
              21.020248065239905
            ],
            [
              105.83751987169734,
              21.020251900817595
            ],
            [
              105.83753680920525,
              21.02038042110505
            ],
            [
              105.83754112935446,
              21.020463602982613
            ],
            [
              105.83754135246812,
              21.020467897615323
            ],
            [
              105.8375415141125,
              21.020471003908487
            ],
            [
              105.83754367798147,
              21.020635903811847
            ],
            [
              105.83752722316963,
              21.020747967392854
            ],
            [
              105.8375160811371,
              21.02076268879844
            ],
            [
              105.83751277137358,
              21.02076706134222
            ],
            [
              105.83751231945926,
              21.020768993756082
            ],
            [
              105.83750068968288,
              21.020818675926307
            ],
            [
              105.83749956274207,
              21.020842187867736
            ],
            [
              105.83749556049705,
              21.020925643751095
            ],
            [
              105.83748592959427,
              21.02095825870165
            ],
            [
              105.83745933952319,
              21.02104829920033
            ],
            [
              105.83746015356083,
              21.021062288834457
            ],
            [
              105.83746497065597,
              21.021145057294653
            ],
            [
              105.83749869503339,
              21.021259003600587
            ],
            [
              105.83750705696582,
              21.02142288303921
            ],
            [
              105.8375038947781,
              21.021460914382388
            ],
            [
              105.83750118355465,
              21.02147496924423
            ],
            [
              105.83748902129791,
              21.02153800367609
            ],
            [
              105.83743750981125,
              21.021603683196375
            ],
            [
              105.83736669946033,
              21.021669592293655
            ],
            [
              105.83736591453123,
              21.021670288930355
            ],
            [
              105.83735721908386,
              21.02167801275118
            ],
            [
              105.83726686734744,
              21.021758266361267
            ],
            [
              105.83724783382596,
              21.021775172655445
            ],
            [
              105.837245110716,
              21.02177814501988
            ],
            [
              105.83714171270526,
              21.02189099418547
            ],
            [
              105.83703476955371,
              21.02199538127541
            ],
            [
              105.83703059449478,
              21.02200088167872
            ],
            [
              105.83694024149952,
              21.02211992586896
            ],
            [
              105.83686604502375,
              21.02221074426904
            ],
            [
              105.83685217354402,
              21.022225956868443
            ],
            [
              105.83684970195984,
              21.022228667860933
            ],
            [
              105.83684401204839,
              21.02223490732427
            ],
            [
              105.83680267486008,
              21.02228024026698
            ],
            [
              105.83677274375935,
              21.022298668766574
            ],
            [
              105.83671612789433,
              21.022316850085716
            ],
            [
              105.83671267709964,
              21.022318542541164
            ],
            [
              105.83670045782519,
              21.022324537733844
            ],
            [
              105.83668389556237,
              21.022332664220002
            ],
            [
              105.83668386869824,
              21.022332676997276
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D200_Văn Chương 2",
          "maTaiSan": "04.O14.DOPP.1149",
          "duongKinh": 200,
          "chieudaiQL": 275,
          "chatLieu": "G-DI",
          "from_latitude": 21.020248065239905,
          "to_latitude": 21.022332676997276,
          "to_longitude": 105.83668386869824,
          "from_longitude": 105.83751923750343
        }
      },
      {
        "id": 1146,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83657610264589,
            21.020204530430338,
            105.83751923750343,
            21.020576980856898
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83751923750343,
              21.020248065239905
            ],
            [
              105.8375154308869,
              21.020249077528597
            ],
            [
              105.83747348356685,
              21.020213105438366
            ],
            [
              105.83742904674416,
              21.020204530430338
            ],
            [
              105.83735621153667,
              21.020215212842874
            ],
            [
              105.83723186496174,
              21.020272240276157
            ],
            [
              105.83721546598919,
              21.020279760845124
            ],
            [
              105.83701824297633,
              21.020368039763905
            ],
            [
              105.83700342051154,
              21.020374674621817
            ],
            [
              105.83675036758605,
              21.020494853426495
            ],
            [
              105.83673698168623,
              21.02050121022784
            ],
            [
              105.83657610264589,
              21.020576980856898
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Văn Chương",
          "maTaiSan": "04.O14.DOPP.1150",
          "duongKinh": 150,
          "chieudaiQL": 106,
          "chatLieu": "G-DI",
          "from_latitude": 21.020248065239905,
          "to_latitude": 21.020576980856898,
          "to_longitude": 105.83657610264589,
          "from_longitude": 105.83751923750343
        }
      },
      {
        "id": 1147,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83502058840135,
            21.021446273775055,
            105.83503710205069,
            21.022334008298962
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83502865001904,
              21.021446273775055
            ],
            [
              105.83502575032811,
              21.021502361956728
            ],
            [
              105.83502103137366,
              21.021657748664843
            ],
            [
              105.83502058840135,
              21.021672348743902
            ],
            [
              105.83502296676423,
              21.021870377933535
            ],
            [
              105.83502324684785,
              21.02189372489526
            ],
            [
              105.83502390371248,
              21.02201098722306
            ],
            [
              105.83502484413519,
              21.022176921082938
            ],
            [
              105.83503644893321,
              21.022315882900354
            ],
            [
              105.83503710205069,
              21.022334008298962
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Văn Chương",
          "maTaiSan": "04.O14.DOPP.1151",
          "duongKinh": 100,
          "chieudaiQL": 102,
          "chatLieu": "G-DI",
          "from_latitude": 21.021446273775055,
          "to_latitude": 21.022334008298962,
          "to_longitude": 105.83503710205069,
          "from_longitude": 105.83502865001904
        }
      },
      {
        "id": 1148,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83502865001904,
            21.02075239438111,
            105.83612572035902,
            21.021512953924788
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83502865001904,
              21.021446273775055
            ],
            [
              105.83503174262012,
              21.021446247753783
            ],
            [
              105.83517741605695,
              21.021445005048687
            ],
            [
              105.83524815041805,
              21.02144663577486
            ],
            [
              105.83526050375106,
              21.021446921046767
            ],
            [
              105.83534938552727,
              21.021446957842695
            ],
            [
              105.83536025007328,
              21.02144713299998
            ],
            [
              105.83539376152008,
              21.021447673830775
            ],
            [
              105.83541402308721,
              21.021455529007895
            ],
            [
              105.83543334013167,
              21.021471523255585
            ],
            [
              105.8354442968602,
              21.02149998421526
            ],
            [
              105.83546720417796,
              21.02150858600162
            ],
            [
              105.83552967268261,
              21.021508554633783
            ],
            [
              105.83563949981628,
              21.021512953924788
            ],
            [
              105.8356452928274,
              21.021509847277393
            ],
            [
              105.83566363097817,
              21.021500015010087
            ],
            [
              105.83566949016635,
              21.02149687281103
            ],
            [
              105.83567094033772,
              21.021496095236305
            ],
            [
              105.8356764286591,
              21.021490618219183
            ],
            [
              105.83567990131093,
              21.021487152649122
            ],
            [
              105.83571085574137,
              21.021456262050595
            ],
            [
              105.83572620381098,
              21.02143740861729
            ],
            [
              105.83578294507058,
              21.021377901818298
            ],
            [
              105.83583306949942,
              21.02134215068914
            ],
            [
              105.83588397913363,
              21.021298162802335
            ],
            [
              105.83592670736684,
              21.021253693949
            ],
            [
              105.83593231296253,
              21.02124785957425
            ],
            [
              105.83598897437646,
              21.021194936700038
            ],
            [
              105.83599194678662,
              21.021192160935808
            ],
            [
              105.83600229440289,
              21.02118019836265
            ],
            [
              105.83603498888341,
              21.02114240123554
            ],
            [
              105.8360717579316,
              21.0210840140565
            ],
            [
              105.83609139343419,
              21.021048944715375
            ],
            [
              105.83609915450403,
              21.02103874529842
            ],
            [
              105.83612229135878,
              21.02103883298571
            ],
            [
              105.83612227609888,
              21.021037476418638
            ],
            [
              105.83612134553505,
              21.020955122340713
            ],
            [
              105.83612134175283,
              21.020954789069748
            ],
            [
              105.83612572035902,
              21.02092023368849
            ],
            [
              105.83612522018994,
              21.020810691361348
            ],
            [
              105.83611367611402,
              21.02075678409927
            ],
            [
              105.83611166517156,
              21.02075239438111
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 225, Văn Chương",
          "maTaiSan": "04.O14.DOPP.1152",
          "duongKinh": 100,
          "chieudaiQL": 174,
          "chatLieu": "G-CI",
          "from_latitude": 21.021446273775055,
          "to_latitude": 21.02075239438111,
          "to_longitude": 105.83611166517156,
          "from_longitude": 105.83502865001904
        }
      },
      {
        "id": 1149,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83597812600344,
            21.020576980856898,
            105.8368334531957,
            21.02237597849603
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83657610264589,
              21.020576980856898
            ],
            [
              105.83657682919639,
              21.02057978721255
            ],
            [
              105.83660642505289,
              21.02069392383698
            ],
            [
              105.83661495903549,
              21.020792441633233
            ],
            [
              105.83661693008516,
              21.02081519678861
            ],
            [
              105.83661921913836,
              21.020935986278797
            ],
            [
              105.83653898278104,
              21.02093799496908
            ],
            [
              105.83652900211521,
              21.020952784665468
            ],
            [
              105.83651902114829,
              21.021134615648307
            ],
            [
              105.83657206548976,
              21.021203807247357
            ],
            [
              105.83659386188019,
              21.021233124360812
            ],
            [
              105.83677996653213,
              21.021236153284278
            ],
            [
              105.8368334531957,
              21.021237023689785
            ],
            [
              105.83683304517258,
              21.0212432028435
            ],
            [
              105.83681960348581,
              21.021446598156516
            ],
            [
              105.83669309350178,
              21.021542819513044
            ],
            [
              105.83669181278017,
              21.021543794068766
            ],
            [
              105.83668840741392,
              21.02154638410419
            ],
            [
              105.83668708060634,
              21.0215478917885
            ],
            [
              105.83665765280469,
              21.021581330269456
            ],
            [
              105.83652574737029,
              21.021687639957165
            ],
            [
              105.83652503198915,
              21.021688216119298
            ],
            [
              105.83652325203809,
              21.021688852613796
            ],
            [
              105.83652137416479,
              21.02168952481559
            ],
            [
              105.8364761525586,
              21.021736926198756
            ],
            [
              105.83641640519885,
              21.021788486089008
            ],
            [
              105.83639853976099,
              21.021803902550392
            ],
            [
              105.83625315571977,
              21.021929363430345
            ],
            [
              105.83625047899643,
              21.02193151542432
            ],
            [
              105.83621569880643,
              21.02195947409637
            ],
            [
              105.83612185267732,
              21.02204547525167
            ],
            [
              105.83611396245172,
              21.02205270550546
            ],
            [
              105.83601708443844,
              21.02213498035716
            ],
            [
              105.83600784216331,
              21.02216214236588
            ],
            [
              105.83600389390834,
              21.02218503681475
            ],
            [
              105.835998288836,
              21.022217541577742
            ],
            [
              105.83599696842228,
              21.02222520198505
            ],
            [
              105.83599437973156,
              21.022240211834436
            ],
            [
              105.83597890568963,
              21.022369508485728
            ],
            [
              105.83597818436057,
              21.022375575371434
            ],
            [
              105.83597812600344,
              21.02237597849603
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 273, Văn Chương",
          "maTaiSan": "04.O14.DOPP.1153",
          "duongKinh": 100,
          "chieudaiQL": 275,
          "chatLieu": "G-CI",
          "from_latitude": 21.020576980856898,
          "to_latitude": 21.02237597849603,
          "to_longitude": 105.83597812600344,
          "from_longitude": 105.83657610264589
        }
      },
      {
        "id": 1150,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83330672601288,
            21.02257583904192,
            105.83421014935486,
            21.02296413429409
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83421014935486,
              21.02257583904192
            ],
            [
              105.83417176845364,
              21.02259005886894
            ],
            [
              105.83417137869377,
              21.0225902034899
            ],
            [
              105.83412944385206,
              21.022605739573827
            ],
            [
              105.83409955049575,
              21.022618229634123
            ],
            [
              105.83409404632336,
              21.02262052913834
            ],
            [
              105.83406489964902,
              21.02263270753155
            ],
            [
              105.83406389095,
              21.02263312886432
            ],
            [
              105.83398591979925,
              21.02266570662485
            ],
            [
              105.8339555600031,
              21.02267908771994
            ],
            [
              105.83395169173342,
              21.022680792102854
            ],
            [
              105.833910762563,
              21.022698830552763
            ],
            [
              105.8338850742276,
              21.02271015196138
            ],
            [
              105.83381757575883,
              21.02273990088674
            ],
            [
              105.83381259815673,
              21.022742094833067
            ],
            [
              105.83368798145183,
              21.02279701566569
            ],
            [
              105.83368580308849,
              21.02279797563176
            ],
            [
              105.83365969920024,
              21.022809480592095
            ],
            [
              105.83364164949647,
              21.022817436143296
            ],
            [
              105.83362540197172,
              21.02282459615145
            ],
            [
              105.83359946993563,
              21.022835868131896
            ],
            [
              105.83353010686054,
              21.022862991380254
            ],
            [
              105.83352234993538,
              21.02286602448585
            ],
            [
              105.83348725683373,
              21.022884030622315
            ],
            [
              105.8334683137392,
              21.022892042017418
            ],
            [
              105.83345530559984,
              21.022897544074066
            ],
            [
              105.83344639067859,
              21.022900446464046
            ],
            [
              105.83344133210436,
              21.022902093442795
            ],
            [
              105.83339904510582,
              21.022922839066087
            ],
            [
              105.83339639341433,
              21.022924140056872
            ],
            [
              105.83339034464466,
              21.022926769174497
            ],
            [
              105.83336833878822,
              21.02293585595114
            ],
            [
              105.83336662941733,
              21.022936561615722
            ],
            [
              105.83332983496034,
              21.022952048836046
            ],
            [
              105.83331314687018,
              21.022960776260952
            ],
            [
              105.83330672601288,
              21.02296413429409
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D200_Văn Chương",
          "maTaiSan": "04.O14.DOPP.1154",
          "duongKinh": 200,
          "chieudaiQL": 128,
          "chatLieu": "G-DI",
          "from_latitude": 21.02257583904192,
          "to_latitude": 21.02296413429409,
          "to_longitude": 105.83330672601288,
          "from_longitude": 105.83421014935486
        }
      },
      {
        "id": 1151,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83347107137011,
            21.025642538043325,
            105.83498469903344,
            21.026237427827503
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83347107137011,
              21.026237427827503
            ],
            [
              105.8334961452157,
              21.026228284442265
            ],
            [
              105.83349690366227,
              21.026228007951424
            ],
            [
              105.83358700992135,
              21.0261951505034
            ],
            [
              105.83363303605422,
              21.026178366993022
            ],
            [
              105.83390480036667,
              21.026079266951676
            ],
            [
              105.8339076238956,
              21.02607823788289
            ],
            [
              105.8339143410736,
              21.026075554886276
            ],
            [
              105.83418254614334,
              21.02596842865757
            ],
            [
              105.83419215660668,
              21.02596515339498
            ],
            [
              105.83440292921183,
              21.025916699231452
            ],
            [
              105.83441016383524,
              21.025915036125884
            ],
            [
              105.83449559900151,
              21.02589165335924
            ],
            [
              105.83458768445017,
              21.025838373731247
            ],
            [
              105.83459457520014,
              21.025834386505007
            ],
            [
              105.83459542980651,
              21.02583393069917
            ],
            [
              105.83459979203542,
              21.025831603361382
            ],
            [
              105.83460234817868,
              21.025830239594374
            ],
            [
              105.83472797032775,
              21.025763216240886
            ],
            [
              105.83473418851322,
              21.025759898889284
            ],
            [
              105.83474830583347,
              21.025759198241932
            ],
            [
              105.83475185742375,
              21.0257576218452
            ],
            [
              105.83476995125073,
              21.02574959188951
            ],
            [
              105.83489791087563,
              21.02568636131747
            ],
            [
              105.83489912063122,
              21.02568576376696
            ],
            [
              105.83490250813409,
              21.025684089721356
            ],
            [
              105.8349090106833,
              21.02568087664464
            ],
            [
              105.83496323865901,
              21.02565340892995
            ],
            [
              105.83498469903344,
              21.025642538043325
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D200_Ngõ Thông Phong, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DOPP.1155",
          "duongKinh": 200,
          "chieudaiQL": 178,
          "chatLieu": "G-DI",
          "from_latitude": 21.026237427827503,
          "to_latitude": 21.025642538043325,
          "to_longitude": 105.83498469903344,
          "from_longitude": 105.83347107137011
        }
      },
      {
        "id": 1152,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83583951953558,
            21.02237607488882,
            105.83594565010742,
            21.023265787449898
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83586197508866,
              21.023265787449898
            ],
            [
              105.83586196407404,
              21.02326570982681
            ],
            [
              105.83585310368463,
              21.023201081660268
            ],
            [
              105.83583951953558,
              21.023021966923007
            ],
            [
              105.83583971048691,
              21.02301549529074
            ],
            [
              105.83584192885004,
              21.023006872151797
            ],
            [
              105.83584227671825,
              21.02300542528447
            ],
            [
              105.83584402190581,
              21.022998722014588
            ],
            [
              105.83584425489575,
              21.022995649909326
            ],
            [
              105.83584543290281,
              21.022981423767714
            ],
            [
              105.8358452463268,
              21.022976083020236
            ],
            [
              105.83586099599431,
              21.0229548710901
            ],
            [
              105.83588193178221,
              21.022882422698917
            ],
            [
              105.83588194216233,
              21.022882386518955
            ],
            [
              105.83588518084224,
              21.02285316757637
            ],
            [
              105.83588777314256,
              21.022834832945247
            ],
            [
              105.83589678051635,
              21.022792788779107
            ],
            [
              105.83590243189253,
              21.022747876209955
            ],
            [
              105.83590837988355,
              21.022659538803776
            ],
            [
              105.83591089016701,
              21.02263184266224
            ],
            [
              105.83591089287667,
              21.0226318110361
            ],
            [
              105.83594526346832,
              21.02238022981145
            ],
            [
              105.83594562460279,
              21.02237633152969
            ],
            [
              105.83594565010742,
              21.02237607488882
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Ngách 33, Văn Chương 2",
          "maTaiSan": "04.O14.DOPP.1156",
          "duongKinh": 150,
          "chieudaiQL": 112,
          "chatLieu": "G-DI",
          "from_latitude": 21.023265787449898,
          "to_latitude": 21.02237607488882,
          "to_longitude": 105.83594565010742,
          "from_longitude": 105.83586197508866
        }
      },
      {
        "id": 1153,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83667492750651,
            21.022332676997276,
            105.83722451794729,
            21.023537538600046
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83668386869824,
              21.022332676997276
            ],
            [
              105.8366867722683,
              21.022338767601756
            ],
            [
              105.83672529287395,
              21.022419629770663
            ],
            [
              105.8367431624446,
              21.022471832179086
            ],
            [
              105.83673486712726,
              21.0224938682872
            ],
            [
              105.83673727001151,
              21.022518493693468
            ],
            [
              105.83673946179579,
              21.022540801563412
            ],
            [
              105.83673472467376,
              21.022565393450318
            ],
            [
              105.83672762600202,
              21.022576732214553
            ],
            [
              105.83670671519798,
              21.022610135128296
            ],
            [
              105.83668419776625,
              21.022642828783596
            ],
            [
              105.83667602757609,
              21.022671259095727
            ],
            [
              105.83667492750651,
              21.022693059290074
            ],
            [
              105.83667955816952,
              21.02270903530835
            ],
            [
              105.8366955531298,
              21.022764216740036
            ],
            [
              105.83670729015796,
              21.022801087292645
            ],
            [
              105.83670766623224,
              21.022802283116896
            ],
            [
              105.83670819994846,
              21.02280397403532
            ],
            [
              105.8367094140785,
              21.022807785988082
            ],
            [
              105.83673445368233,
              21.022886422084554
            ],
            [
              105.83673662852938,
              21.022893282205978
            ],
            [
              105.8367520721812,
              21.022921548476912
            ],
            [
              105.83675277780476,
              21.022937495028376
            ],
            [
              105.8367941801625,
              21.02305014639356
            ],
            [
              105.83682876203494,
              21.023119693353458
            ],
            [
              105.83683908488732,
              21.023139823283245
            ],
            [
              105.83686627539201,
              21.02314861605298
            ],
            [
              105.83687129447979,
              21.023150241545913
            ],
            [
              105.83687237863357,
              21.023150585758827
            ],
            [
              105.83687382562378,
              21.02315130483054
            ],
            [
              105.83693704768662,
              21.02318240513518
            ],
            [
              105.83701528469021,
              21.023217169964116
            ],
            [
              105.83706173385711,
              21.023237798599805
            ],
            [
              105.8370666415728,
              21.023239979214107
            ],
            [
              105.83707864839425,
              21.023248473645644
            ],
            [
              105.83709156021423,
              21.0232755357008
            ],
            [
              105.83715317527572,
              21.023404565886327
            ],
            [
              105.83717224454686,
              21.02345604788133
            ],
            [
              105.83717266418867,
              21.023459532257757
            ],
            [
              105.83717315392627,
              21.023463510352318
            ],
            [
              105.83717324728184,
              21.023464207181195
            ],
            [
              105.83719420715366,
              21.023503162998093
            ],
            [
              105.83720888314718,
              21.023518999227953
            ],
            [
              105.83722353775376,
              21.023534794916653
            ],
            [
              105.83722451794729,
              21.023537538600046
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Linh Quang",
          "maTaiSan": "04.O14.DOPP.1157",
          "duongKinh": 150,
          "chieudaiQL": 162,
          "chatLieu": "G-DI",
          "from_latitude": 21.022332676997276,
          "to_latitude": 21.023537538600046,
          "to_longitude": 105.83722451794729,
          "from_longitude": 105.83668386869824
        }
      },
      {
        "id": 1154,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83634166194018,
            21.023537538600046,
            105.83722451794729,
            21.023966062200287
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83722451794729,
              21.023537538600046
            ],
            [
              105.83722178564734,
              21.02353887075616
            ],
            [
              105.83717400336663,
              21.0235679169804
            ],
            [
              105.8370017785047,
              21.023654194215045
            ],
            [
              105.83688465356249,
              21.023705025933726
            ],
            [
              105.83668138918314,
              21.02378446824832
            ],
            [
              105.83663223103345,
              21.02380437053719
            ],
            [
              105.83653349631979,
              21.02385332949397
            ],
            [
              105.83634792822869,
              21.023962765329724
            ],
            [
              105.83634170031792,
              21.023966043947095
            ],
            [
              105.83634166194018,
              21.023966062200287
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Ngõ 46, Linh Quang",
          "maTaiSan": "04.O14.DOPP.1158",
          "duongKinh": 150,
          "chieudaiQL": 190,
          "chatLieu": "G-DI",
          "from_latitude": 21.023537538600046,
          "to_latitude": 21.023966062200287,
          "to_longitude": 105.83634166194018,
          "from_longitude": 105.83722451794729
        }
      },
      {
        "id": 1155,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83543009762435,
            21.023966062200287,
            105.83634166194018,
            21.02460238624788
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83634166194018,
              21.023966062200287
            ],
            [
              105.83633259102207,
              21.02397083427781
            ],
            [
              105.83623477239787,
              21.024022295006283
            ],
            [
              105.83613855880031,
              21.024058664860984
            ],
            [
              105.83609704983537,
              21.024074355597374
            ],
            [
              105.83596876311407,
              21.02411533321159
            ],
            [
              105.83593211833825,
              21.02412781703976
            ],
            [
              105.83592821429731,
              21.02412914680589
            ],
            [
              105.83592521569693,
              21.024130168638507
            ],
            [
              105.8359103628691,
              21.02413522833778
            ],
            [
              105.83583089557538,
              21.02416230102701
            ],
            [
              105.83571002982605,
              21.02423854356605
            ],
            [
              105.83570340235265,
              21.02424349237786
            ],
            [
              105.83557008633032,
              21.024343030520118
            ],
            [
              105.83549210020088,
              21.024404432723472
            ],
            [
              105.83548269948562,
              21.02441183385431
            ],
            [
              105.83543009762435,
              21.024453250345083
            ],
            [
              105.83548101028309,
              21.024567381757123
            ],
            [
              105.83549262815446,
              21.024593425132547
            ],
            [
              105.83549662515406,
              21.02460238624788
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 46, Linh Quang",
          "maTaiSan": "04.O14.DOPP.1159",
          "duongKinh": 100,
          "chieudaiQL": 128,
          "chatLieu": "G-CI",
          "from_latitude": 21.023966062200287,
          "to_latitude": 21.02460238624788,
          "to_longitude": 105.83549662515406,
          "from_longitude": 105.83634166194018
        }
      },
      {
        "id": 1156,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83364403213231,
            21.026335857873587,
            105.83385277352208,
            21.02681573057513
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83364403213231,
              21.026335857873587
            ],
            [
              105.83371453460998,
              21.0264979353381
            ],
            [
              105.83376197940167,
              21.026607004527584
            ],
            [
              105.83385277352208,
              21.02681573057513
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Tôn Đức Thắng",
          "maTaiSan": "04.O14.DOPP.1160",
          "duongKinh": 150,
          "chieudaiQL": 58,
          "chatLieu": "G-DI",
          "from_latitude": 21.026335857873587,
          "to_latitude": 21.02681573057513,
          "to_longitude": 105.83385277352208,
          "from_longitude": 105.83364403213231
        }
      },
      {
        "id": 1157,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83421913777688,
            21.02707189319702,
            105.83586782966717,
            21.02765266512752
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83421913777688,
              21.027605675930133
            ],
            [
              105.83433238106963,
              21.02765266512752
            ],
            [
              105.83459001343766,
              21.027557160367923
            ],
            [
              105.8346072085595,
              21.02755078502457
            ],
            [
              105.8347424184129,
              21.027500662658525
            ],
            [
              105.83475378421954,
              21.0274964493289
            ],
            [
              105.83475591452694,
              21.027495659389814
            ],
            [
              105.83476681087707,
              21.027491619976274
            ],
            [
              105.83479199824329,
              21.027482282550444
            ],
            [
              105.83494893388425,
              21.027429538794987
            ],
            [
              105.83496527743134,
              21.027423134178143
            ],
            [
              105.83497344344752,
              21.027419934607078
            ],
            [
              105.83520572593407,
              21.027328911455903
            ],
            [
              105.83534954969697,
              21.02727559406084
            ],
            [
              105.83571958653484,
              21.027129990919626
            ],
            [
              105.83572059731351,
              21.027129593050095
            ],
            [
              105.83573460164418,
              21.027124478820955
            ],
            [
              105.83574400922551,
              21.02712104279005
            ],
            [
              105.83584089608182,
              21.02708566063922
            ],
            [
              105.83586782966717,
              21.02707189319702
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Quốc Tử Giám",
          "maTaiSan": "04.O14.DOPP.1161",
          "duongKinh": 150,
          "chieudaiQL": 182,
          "chatLieu": "G-DI",
          "from_latitude": 21.027605675930133,
          "to_latitude": 21.02707189319702,
          "to_longitude": 105.83586782966717,
          "from_longitude": 105.83421913777688
        }
      },
      {
        "id": 1158,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83738249743953,
            21.025896612922484,
            105.83805010545618,
            21.026231335385457
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83738249743953,
              21.02621956119781
            ],
            [
              105.83743500301598,
              21.026231335385457
            ],
            [
              105.83748743343374,
              21.02620399675862
            ],
            [
              105.83776382181486,
              21.026059883732326
            ],
            [
              105.8378534763991,
              21.026010095363
            ],
            [
              105.83790058854413,
              21.02598393171397
            ],
            [
              105.8379566137556,
              21.02595125163881
            ],
            [
              105.838031756996,
              21.02590741917823
            ],
            [
              105.83804764364639,
              21.025898153307274
            ],
            [
              105.83805010545618,
              21.025896612922484
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Quốc Tử Giám",
          "maTaiSan": "04.O14.DOPP.1162",
          "duongKinh": 150,
          "chieudaiQL": 74,
          "chatLieu": "G-DI",
          "from_latitude": 21.02621956119781,
          "to_latitude": 21.025896612922484,
          "to_longitude": 105.83805010545618,
          "from_longitude": 105.83738249743953
        }
      },
      {
        "id": 1159,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83952546424874,
            21.018942370369537,
            105.83981013214259,
            21.020862200290512
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83978733387448,
              21.018942370369537
            ],
            [
              105.83978754154883,
              21.01894887887491
            ],
            [
              105.8397885656948,
              21.018983175531652
            ],
            [
              105.83979085007336,
              21.019054246123964
            ],
            [
              105.83979336508172,
              21.019145200038416
            ],
            [
              105.83979341329012,
              21.01914693760208
            ],
            [
              105.83979953953198,
              21.019368450873444
            ],
            [
              105.83980019658023,
              21.01953645619327
            ],
            [
              105.83980034511974,
              21.01957449019211
            ],
            [
              105.83980039321035,
              21.019586856887745
            ],
            [
              105.83980057125657,
              21.01963239291316
            ],
            [
              105.83980630535066,
              21.01964541979276
            ],
            [
              105.8398098295891,
              21.01965342391912
            ],
            [
              105.83980812439293,
              21.01988229285219
            ],
            [
              105.8398080459749,
              21.019892853725462
            ],
            [
              105.83981013214259,
              21.01996493960774
            ],
            [
              105.8397996948825,
              21.02005031953949
            ],
            [
              105.83970146484008,
              21.020073331774118
            ],
            [
              105.8396300178301,
              21.020090168283957
            ],
            [
              105.83953066000083,
              21.02009611416525
            ],
            [
              105.83952546424874,
              21.02009642522753
            ],
            [
              105.83953014335225,
              21.02028012948625
            ],
            [
              105.83953498755547,
              21.020470295487986
            ],
            [
              105.83953832907686,
              21.02060150649068
            ],
            [
              105.83954176042991,
              21.020736217940886
            ],
            [
              105.839543246126,
              21.020794539858656
            ],
            [
              105.83954416459937,
              21.020862200290512
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ Thiên Hùng, Khâm Thiên",
          "maTaiSan": "04.O14.DOPP.1163",
          "duongKinh": 100,
          "chieudaiQL": 231,
          "chatLieu": "G-DI",
          "from_latitude": 21.018942370369537,
          "to_latitude": 21.020862200290512,
          "to_longitude": 105.83954416459937,
          "from_longitude": 105.83978733387448
        }
      },
      {
        "id": 1160,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83862621181434,
            21.01938796867335,
            105.83919612484691,
            21.019829392793074
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83862621181434,
              21.019829392793074
            ],
            [
              105.83871821612296,
              21.01978764379628
            ],
            [
              105.83869922616414,
              21.019733144797467
            ],
            [
              105.83874468967657,
              21.01970497670329
            ],
            [
              105.83879471873948,
              21.019668398751254
            ],
            [
              105.83879877427226,
              21.019665973468857
            ],
            [
              105.83880062659301,
              21.019664866918298
            ],
            [
              105.83886248739229,
              21.01962788015175
            ],
            [
              105.83888616938903,
              21.01961643513375
            ],
            [
              105.83895482908088,
              21.019570641795635
            ],
            [
              105.83895637974595,
              21.01956960718264
            ],
            [
              105.83896330120407,
              21.01956499099308
            ],
            [
              105.83901680005535,
              21.019515534866432
            ],
            [
              105.83902041807573,
              21.01951279922162
            ],
            [
              105.83916704318476,
              21.019401948622523
            ],
            [
              105.83919612484691,
              21.01938796867335
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngách 9, Ngõ Tương Thuận, Khâm Thiên",
          "maTaiSan": "04.O14.DOPP.1164",
          "duongKinh": 100,
          "chieudaiQL": 76,
          "chatLieu": "G-DI",
          "from_latitude": 21.019829392793074,
          "to_latitude": 21.01938796867335,
          "to_longitude": 105.83919612484691,
          "from_longitude": 105.83862621181434
        }
      },
      {
        "id": 1161,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83907130421984,
            21.018983363216325,
            105.83919612484691,
            21.01938796867335
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83919612484691,
              21.01938796867335
            ],
            [
              105.83919183650605,
              21.01938282519813
            ],
            [
              105.8391848780115,
              21.019374459549418
            ],
            [
              105.83916958773426,
              21.01935607851415
            ],
            [
              105.83908288177626,
              21.019252112575444
            ],
            [
              105.83907130421984,
              21.019238978105854
            ],
            [
              105.83907260616647,
              21.019237848977042
            ],
            [
              105.8391601918159,
              21.019161908347826
            ],
            [
              105.83918211135946,
              21.01914168723421
            ],
            [
              105.8391727357843,
              21.01903813827482
            ],
            [
              105.83916350812319,
              21.018988870506423
            ],
            [
              105.83916268914777,
              21.018984510170867
            ],
            [
              105.83916247783294,
              21.018983363216325
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ Tương Thuận, Khâm Thiên",
          "maTaiSan": "04.O14.DOPP.1165",
          "duongKinh": 100,
          "chieudaiQL": 45,
          "chatLieu": "G-DI",
          "from_latitude": 21.01938796867335,
          "to_latitude": 21.018983363216325,
          "to_longitude": 105.83916247783294,
          "from_longitude": 105.83919612484691
        }
      },
      {
        "id": 1162,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83790854603842,
            21.01992509984172,
            105.83954945103896,
            21.023861424291542
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83790854603842,
              21.01992509984172
            ],
            [
              105.83791122865604,
              21.01992831745788
            ],
            [
              105.83807013600256,
              21.020118796707294
            ],
            [
              105.83810014657658,
              21.02015324953029
            ],
            [
              105.83810372205394,
              21.020157354225475
            ],
            [
              105.83823573603536,
              21.0203089104251
            ],
            [
              105.83824822748633,
              21.02032325166866
            ],
            [
              105.83826804418239,
              21.02034600195076
            ],
            [
              105.83841535810582,
              21.020512440879514
            ],
            [
              105.83841780348129,
              21.02051520352847
            ],
            [
              105.83842474366459,
              21.02052304452461
            ],
            [
              105.8385013384071,
              21.020609582344303
            ],
            [
              105.83856387951738,
              21.020680924032323
            ],
            [
              105.83863320256577,
              21.020726464198532
            ],
            [
              105.8386456268231,
              21.02073462588725
            ],
            [
              105.83868131400352,
              21.02075183788228
            ],
            [
              105.83868545684052,
              21.02075383706266
            ],
            [
              105.83869585201617,
              21.020758850180265
            ],
            [
              105.83875130587117,
              21.02080790176819
            ],
            [
              105.83880344396604,
              21.02086643185549
            ],
            [
              105.83892548315733,
              21.020991591184536
            ],
            [
              105.83892586049328,
              21.02099197861271
            ],
            [
              105.83892803948555,
              21.020994065150028
            ],
            [
              105.83892837895195,
              21.02099438953934
            ],
            [
              105.83893876781434,
              21.02100635116901
            ],
            [
              105.83894018624352,
              21.0210079853297
            ],
            [
              105.83894060547368,
              21.021008467389617
            ],
            [
              105.83901808291215,
              21.021087100108648
            ],
            [
              105.83904490050519,
              21.02112178707932
            ],
            [
              105.83905922596198,
              21.021140315586056
            ],
            [
              105.83908708135283,
              21.021176344135757
            ],
            [
              105.83910608641675,
              21.021206663712718
            ],
            [
              105.83910812571898,
              21.021209916094605
            ],
            [
              105.83911973938797,
              21.021228443521974
            ],
            [
              105.8392076969858,
              21.021345275043164
            ],
            [
              105.83921105640991,
              21.02135150154686
            ],
            [
              105.83921263502823,
              21.021354427427035
            ],
            [
              105.83921688415313,
              21.021375929367625
            ],
            [
              105.83921871171324,
              21.021385177485023
            ],
            [
              105.8392421875212,
              21.021513367217004
            ],
            [
              105.83924466724442,
              21.02155612079231
            ],
            [
              105.83924560316117,
              21.021572262191658
            ],
            [
              105.83924534237984,
              21.02158255931922
            ],
            [
              105.83924310109742,
              21.02167092457057
            ],
            [
              105.83923090323985,
              21.021727984459623
            ],
            [
              105.83923024796961,
              21.021731048716024
            ],
            [
              105.83921282487735,
              21.021812555732765
            ],
            [
              105.83920994041101,
              21.021826051473322
            ],
            [
              105.83919547956225,
              21.021893696558376
            ],
            [
              105.83918715519269,
              21.021932816482096
            ],
            [
              105.83916516079539,
              21.02201983312814
            ],
            [
              105.83916503937782,
              21.022020311532213
            ],
            [
              105.83916448832154,
              21.022022058374866
            ],
            [
              105.8391638209925,
              21.022024171596527
            ],
            [
              105.83912422710696,
              21.022166007679612
            ],
            [
              105.83912118381267,
              21.02217690924213
            ],
            [
              105.8391130737628,
              21.022283554871223
            ],
            [
              105.83912404868028,
              21.0223947182
            ],
            [
              105.83912408133801,
              21.022395053134648
            ],
            [
              105.839125389974,
              21.022397177381688
            ],
            [
              105.83912572490064,
              21.022397721276054
            ],
            [
              105.83916146415875,
              21.02255307183557
            ],
            [
              105.83919361458922,
              21.022656043217385
            ],
            [
              105.83919567456725,
              21.02266264193651
            ],
            [
              105.8392053374286,
              21.022707459154766
            ],
            [
              105.83920654528166,
              21.022713060403763
            ],
            [
              105.83920999096675,
              21.02272145873145
            ],
            [
              105.83923802554253,
              21.022789782902567
            ],
            [
              105.83925319768079,
              21.02282359786909
            ],
            [
              105.83932037226344,
              21.02294842329158
            ],
            [
              105.83932710596613,
              21.022960936732503
            ],
            [
              105.83933928825488,
              21.02298357370473
            ],
            [
              105.83934405763043,
              21.02299076691975
            ],
            [
              105.83938763980021,
              21.023056496147117
            ],
            [
              105.8393896480655,
              21.023059525581164
            ],
            [
              105.83939366846953,
              21.02306558894613
            ],
            [
              105.83944281633697,
              21.0231362322219
            ],
            [
              105.83944352040172,
              21.023137243969526
            ],
            [
              105.83944480842199,
              21.02313946405697
            ],
            [
              105.83944625768974,
              21.023141962444466
            ],
            [
              105.83947001350897,
              21.02318055461957
            ],
            [
              105.83950236098413,
              21.023234294786523
            ],
            [
              105.83952784947631,
              21.02328905602591
            ],
            [
              105.83953121458381,
              21.023296286876143
            ],
            [
              105.83954945103896,
              21.02345539260742
            ],
            [
              105.83954763696748,
              21.02366331082993
            ],
            [
              105.83954693562218,
              21.023742843067545
            ],
            [
              105.8395468155317,
              21.023757474064663
            ],
            [
              105.83954420005253,
              21.023821584187335
            ],
            [
              105.83954260337842,
              21.02384670708436
            ],
            [
              105.83954243977959,
              21.023850819362483
            ],
            [
              105.83954203548394,
              21.023861424291542
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D200_Ngõ 88, Trần Quý Cáp",
          "maTaiSan": "04.O14.DOPP.1166",
          "duongKinh": 200,
          "chieudaiQL": 496,
          "chatLieu": "G-DI",
          "from_latitude": 21.01992509984172,
          "to_latitude": 21.023861424291542,
          "to_longitude": 105.83954203548394,
          "from_longitude": 105.83790854603842
        }
      },
      {
        "id": 1163,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83736220280556,
            21.02358231394272,
            105.83954203548394,
            21.023874201922354
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83954203548394,
              21.02386142429155
            ],
            [
              105.83953690940726,
              21.02386131682167
            ],
            [
              105.83940308474057,
              21.023858475570854
            ],
            [
              105.8393435812921,
              21.023856648472325
            ],
            [
              105.83932665988942,
              21.02385612862824
            ],
            [
              105.83930086864011,
              21.023855037459654
            ],
            [
              105.83929659447756,
              21.023854857133458
            ],
            [
              105.83929288084053,
              21.02385462075088
            ],
            [
              105.83928962431241,
              21.023854413725285
            ],
            [
              105.83925086799147,
              21.02385360330257
            ],
            [
              105.8391488033437,
              21.02384594268836
            ],
            [
              105.83914140564681,
              21.023843879195102
            ],
            [
              105.83912379685114,
              21.023838966736236
            ],
            [
              105.83910419073511,
              21.02383349690575
            ],
            [
              105.83905341714066,
              21.02381152179429
            ],
            [
              105.83899119925098,
              21.023739603408508
            ],
            [
              105.8389777038186,
              21.023726418797256
            ],
            [
              105.83894747812488,
              21.023716096832956
            ],
            [
              105.83892958940638,
              21.02370946419106
            ],
            [
              105.83892636004941,
              21.023708267091948
            ],
            [
              105.83892525016552,
              21.023707624052523
            ],
            [
              105.8389230738225,
              21.02370636214647
            ],
            [
              105.83889316386414,
              21.023689025991448
            ],
            [
              105.83885636554213,
              21.023662824349852
            ],
            [
              105.83882342650865,
              21.02363280109676
            ],
            [
              105.83882066595727,
              21.023630284784215
            ],
            [
              105.83878857485924,
              21.023599475150668
            ],
            [
              105.83876714102067,
              21.02358231394272
            ],
            [
              105.83869837499122,
              21.023606332861437
            ],
            [
              105.83855917055185,
              21.023658259109105
            ],
            [
              105.83853632579697,
              21.023666780750386
            ],
            [
              105.8384561999272,
              21.02369223841549
            ],
            [
              105.83844892387587,
              21.0236945504077
            ],
            [
              105.83840430975067,
              21.023718630787457
            ],
            [
              105.8383954379425,
              21.02372630856323
            ],
            [
              105.8383825677032,
              21.023738558293132
            ],
            [
              105.83837635171543,
              21.02374447523945
            ],
            [
              105.83830291187483,
              21.023814372774325
            ],
            [
              105.83824638395866,
              21.023857641198994
            ],
            [
              105.83822936947931,
              21.023864562456264
            ],
            [
              105.83820567379051,
              21.023874201922354
            ],
            [
              105.83819211776586,
              21.023868237008262
            ],
            [
              105.83811591396086,
              21.023834704653314
            ],
            [
              105.83801872253407,
              21.023795328856533
            ],
            [
              105.83801824980972,
              21.023795087315573
            ],
            [
              105.8379858531486,
              21.023775333581153
            ],
            [
              105.83797721680916,
              21.02377006789101
            ],
            [
              105.83791548415618,
              21.023740299227306
            ],
            [
              105.83785118726412,
              21.02374864564262
            ],
            [
              105.83783383773554,
              21.023753280646797
            ],
            [
              105.83778751445465,
              21.02376865589353
            ],
            [
              105.83777578744419,
              21.02377254874582
            ],
            [
              105.83769864958971,
              21.02378996349237
            ],
            [
              105.83761670989188,
              21.023811586486623
            ],
            [
              105.83760041612642,
              21.023815962462017
            ],
            [
              105.83759511583021,
              21.023817386754867
            ],
            [
              105.83758661978838,
              21.02381966859005
            ],
            [
              105.83751378765629,
              21.023831211872178
            ],
            [
              105.83747646454846,
              21.023837262999546
            ],
            [
              105.83741306132032,
              21.02384975154834
            ],
            [
              105.83736366884203,
              21.02386953778114
            ],
            [
              105.83736220280556,
              21.023870060739643
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 51, Linh Quang",
          "maTaiSan": "04.O14.DOPP.1167",
          "duongKinh": 100,
          "chieudaiQL": 256,
          "chatLieu": "G-DI",
          "from_latitude": 21.02386142429155,
          "to_latitude": 21.023870060739643,
          "to_longitude": 105.83736220280556,
          "from_longitude": 105.83954203548394
        }
      },
      {
        "id": 1164,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83886920388818,
            21.024901022720265,
            105.83983897942436,
            21.02612151398104
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83983897942436,
              21.026074693725576
            ],
            [
              105.8398348461977,
              21.02607478278233
            ],
            [
              105.83980101316747,
              21.0260755776018
            ],
            [
              105.83979830741204,
              21.026075641543976
            ],
            [
              105.83979097695625,
              21.02607581413002
            ],
            [
              105.83976549044354,
              21.026080763194432
            ],
            [
              105.83974500763169,
              21.02608474006668
            ],
            [
              105.83959754327311,
              21.02612151398104
            ],
            [
              105.83959592363878,
              21.026116600317163
            ],
            [
              105.83959171161491,
              21.02610382244741
            ],
            [
              105.83953857102112,
              21.025989839776173
            ],
            [
              105.83951342430953,
              21.025935903330133
            ],
            [
              105.83940845036899,
              21.025697722328378
            ],
            [
              105.83939676755885,
              21.025671213503312
            ],
            [
              105.83936945023213,
              21.02562406914094
            ],
            [
              105.83934274828599,
              21.02561140497361
            ],
            [
              105.83931515554801,
              21.02559290134719
            ],
            [
              105.83924174009985,
              21.025419773535706
            ],
            [
              105.83921760627756,
              21.025362861338433
            ],
            [
              105.8392055325538,
              21.025334389917
            ],
            [
              105.83913255812907,
              21.025120386327735
            ],
            [
              105.83907448545,
              21.024948705551967
            ],
            [
              105.83906254975231,
              21.02491669551241
            ],
            [
              105.83905786279401,
              21.024904126806632
            ],
            [
              105.83905670545721,
              21.024901022720265
            ],
            [
              105.83886920388818,
              21.024943828324787
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 62, Trần Quý Cáp",
          "maTaiSan": "04.O14.DOPP.1168",
          "duongKinh": 100,
          "chieudaiQL": 198,
          "chatLieu": "G-CI",
          "from_latitude": 21.026074693725576,
          "to_latitude": 21.024943828324787,
          "to_longitude": 105.83886920388818,
          "from_longitude": 105.83983897942436
        }
      },
      {
        "id": 1165,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83872761285245,
            21.025146787182084,
            105.83906964311618,
            21.026559794333025
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83906964311618,
              21.026559794333025
            ],
            [
              105.83906922656124,
              21.026557730718057
            ],
            [
              105.83906907090994,
              21.02655570284791
            ],
            [
              105.83906896618218,
              21.026554351240293
            ],
            [
              105.83906741776377,
              21.026534315465792
            ],
            [
              105.83904503725739,
              21.026469616953026
            ],
            [
              105.83900005950368,
              21.026347204702905
            ],
            [
              105.83899980357316,
              21.02634651048407
            ],
            [
              105.83899992512444,
              21.026344508342852
            ],
            [
              105.83900001326478,
              21.026343068171013
            ],
            [
              105.83897176103119,
              21.02626501285336
            ],
            [
              105.8389645382289,
              21.02624505926417
            ],
            [
              105.83893695573252,
              21.026159465950645
            ],
            [
              105.83893257385878,
              21.026147613801044
            ],
            [
              105.83892982024247,
              21.026138437996867
            ],
            [
              105.83890932977437,
              21.026084150617013
            ],
            [
              105.83890899609791,
              21.026080737177814
            ],
            [
              105.83890514534028,
              21.026041394629075
            ],
            [
              105.83891171415779,
              21.025999151915798
            ],
            [
              105.83896697938168,
              21.025963416950646
            ],
            [
              105.83895078373567,
              21.025857292302828
            ],
            [
              105.83895007870755,
              21.025852672183518
            ],
            [
              105.83895006473449,
              21.025852581930167
            ],
            [
              105.83894857026475,
              21.025845791648127
            ],
            [
              105.83893994305207,
              21.02580660001679
            ],
            [
              105.83893844660376,
              21.025799799808908
            ],
            [
              105.83893442684112,
              21.02576188699175
            ],
            [
              105.83892429123043,
              21.025722353252853
            ],
            [
              105.8389037815924,
              21.02567322426802
            ],
            [
              105.83886088404286,
              21.0255492617034
            ],
            [
              105.83883104912418,
              21.02546304811807
            ],
            [
              105.83880940337961,
              21.025400498314717
            ],
            [
              105.83879774362565,
              21.025364332071504
            ],
            [
              105.83879666003038,
              21.025360996394717
            ],
            [
              105.83878887593269,
              21.025336822995673
            ],
            [
              105.83877712023826,
              21.025300357353704
            ],
            [
              105.83872761285245,
              21.025146787182084
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 35, Nguyễn Như Đổ",
          "maTaiSan": "04.O14.DOPP.1169",
          "duongKinh": 100,
          "chieudaiQL": 175,
          "chatLieu": "G-DI",
          "from_latitude": 21.026559794333025,
          "to_latitude": 21.025146787182084,
          "to_longitude": 105.83872761285245,
          "from_longitude": 105.83906964311618
        }
      },
      {
        "id": 1166,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83886088404286,
            21.025512991165797,
            105.83936945023213,
            21.025666040659654
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83936945023213,
              21.02562406914094
            ],
            [
              105.8393647610052,
              21.025625880666194
            ],
            [
              105.83932147564342,
              21.025636158911293
            ],
            [
              105.83931295595252,
              21.025638181726258
            ],
            [
              105.83921678744906,
              21.02564655771296
            ],
            [
              105.83917141799017,
              21.025654876275947
            ],
            [
              105.83911053392355,
              21.025666040659654
            ],
            [
              105.83909782318857,
              21.025631181170468
            ],
            [
              105.83909306130973,
              21.025618122387108
            ],
            [
              105.8390544477582,
              21.0255243027656
            ],
            [
              105.83905348615559,
              21.02552196725967
            ],
            [
              105.83905247480494,
              21.025519509160887
            ],
            [
              105.83905096119896,
              21.02551583147966
            ],
            [
              105.83904662140544,
              21.02551648063088
            ],
            [
              105.83904277316616,
              21.025514718461643
            ],
            [
              105.83903899630998,
              21.025512991165797
            ],
            [
              105.8389737048842,
              21.025525787690007
            ],
            [
              105.83888735700512,
              21.025543190623463
            ],
            [
              105.83886088404286,
              21.0255492617034
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 62, Trần Quý Cáp",
          "maTaiSan": "04.O14.DOPP.1170",
          "duongKinh": 100,
          "chieudaiQL": 69,
          "chatLieu": "G-CI",
          "from_latitude": 21.02562406914094,
          "to_latitude": 21.0255492617034,
          "to_longitude": 105.83886088404286,
          "from_longitude": 105.83936945023213
        }
      },
      {
        "id": 1167,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83899214928353,
            21.027746457816544,
            105.84036508659565,
            21.028128886846755
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83900820170068,
              21.027746457816544
            ],
            [
              105.83899214928353,
              21.027770130996988
            ],
            [
              105.83907750368171,
              21.02795706801294
            ],
            [
              105.83910358328424,
              21.02801418466497
            ],
            [
              105.83912911555484,
              21.028070104533473
            ],
            [
              105.83922215506409,
              21.028128886846755
            ],
            [
              105.83943409250217,
              21.028064901500127
            ],
            [
              105.83945653301718,
              21.028058126746135
            ],
            [
              105.83959561114014,
              21.028016137558694
            ],
            [
              105.83983228981072,
              21.027944680947012
            ],
            [
              105.8398441439092,
              21.027941102543853
            ],
            [
              105.8401621788068,
              21.02784508335796
            ],
            [
              105.84020434346577,
              21.027829905924786
            ],
            [
              105.84032123181885,
              21.027787829792786
            ],
            [
              105.8403574097302,
              21.027755473714922
            ],
            [
              105.84036508659565,
              21.02775238469175
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Nguyễn Khuyến",
          "maTaiSan": "04.O14.DOPP.1171",
          "duongKinh": 100,
          "chieudaiQL": 174,
          "chatLieu": "G-DI",
          "from_latitude": 21.027746457816544,
          "to_latitude": 21.02775238469175,
          "to_longitude": 105.84036508659565,
          "from_longitude": 105.83900820170068
        }
      },
      {
        "id": 1168,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83900820170068,
            21.02743743376871,
            105.84018887749758,
            21.027746457816555
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84018887749758,
              21.02743743376871
            ],
            [
              105.84011542289522,
              21.027464343287317
            ],
            [
              105.8401088968783,
              21.027466107908722
            ],
            [
              105.84010405314866,
              21.027465249390044
            ],
            [
              105.84010308536675,
              21.027465078042813
            ],
            [
              105.8401016271439,
              21.02746545466484
            ],
            [
              105.84009104396397,
              21.027468185002995
            ],
            [
              105.84001447370296,
              21.02749137685262
            ],
            [
              105.84000171119037,
              21.027495242462624
            ],
            [
              105.83983318603516,
              21.027537667335622
            ],
            [
              105.83981870605119,
              21.027541312898975
            ],
            [
              105.83977847270134,
              21.02755228884279
            ],
            [
              105.8397330591282,
              21.027564677707744
            ],
            [
              105.83967161442087,
              21.027583909049703
            ],
            [
              105.83966955355095,
              21.02758455328799
            ],
            [
              105.83966675131298,
              21.027585430605473
            ],
            [
              105.83964696731285,
              21.027589514213773
            ],
            [
              105.83964433732729,
              21.02759005739128
            ],
            [
              105.8396398085977,
              21.027590992007095
            ],
            [
              105.8396393742708,
              21.027591081764204
            ],
            [
              105.8396379266814,
              21.027591811489792
            ],
            [
              105.83963710551829,
              21.027592225607375
            ],
            [
              105.83954222395668,
              21.027617268944415
            ],
            [
              105.83949691183399,
              21.027629228215755
            ],
            [
              105.83944541585139,
              21.0276442736035
            ],
            [
              105.83944225119998,
              21.027645198770728
            ],
            [
              105.83936376280235,
              21.02766243401639
            ],
            [
              105.83933237872753,
              21.027670107349298
            ],
            [
              105.83933045157208,
              21.027670579311295
            ],
            [
              105.83932729567954,
              21.027671350885374
            ],
            [
              105.83932294275489,
              21.027672414689174
            ],
            [
              105.83927983528126,
              21.02768295489802
            ],
            [
              105.83916499288246,
              21.027710765565956
            ],
            [
              105.83900820170068,
              21.027746457816555
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngô Sĩ Liên",
          "maTaiSan": "04.O14.DOPP.1172",
          "duongKinh": 100,
          "chieudaiQL": 123,
          "chatLieu": "G-DI",
          "from_latitude": 21.02743743376871,
          "to_latitude": 21.027746457816555,
          "to_longitude": 105.83900820170068,
          "from_longitude": 105.84018887749758
        }
      },
      {
        "id": 1169,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83854629870117,
            21.02671239652155,
            105.83878273328232,
            21.027253491073573
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83854629870117,
              21.02671239652155
            ],
            [
              105.83858249390063,
              21.026795230531476
            ],
            [
              105.83864922171519,
              21.02694794352521
            ],
            [
              105.83865234104397,
              21.026955082568815
            ],
            [
              105.83868197904928,
              21.02702291023195
            ],
            [
              105.8386852815837,
              21.02703046836979
            ],
            [
              105.8387222516639,
              21.027115076063136
            ],
            [
              105.8387433639319,
              21.027163390991525
            ],
            [
              105.83874648326486,
              21.02717052912972
            ],
            [
              105.83875323257075,
              21.027185975270275
            ],
            [
              105.83878273328232,
              21.027253491073573
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Ngô Sĩ Liên",
          "maTaiSan": "04.O14.DOPP.1173",
          "duongKinh": 150,
          "chieudaiQL": 55,
          "chatLieu": "G-DI",
          "from_latitude": 21.02671239652155,
          "to_latitude": 21.027253491073573,
          "to_longitude": 105.83878273328232,
          "from_longitude": 105.83854629870117
        }
      },
      {
        "id": 1170,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84077887622931,
            21.027663739928695,
            105.84147609717849,
            21.027771216360545
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84147609717849,
              21.027663739928695
            ],
            [
              105.84145522015424,
              21.027666241290458
            ],
            [
              105.84141182121014,
              21.02767144182202
            ],
            [
              105.84126725096957,
              21.027688765549513
            ],
            [
              105.84107709364866,
              21.02771155152077
            ],
            [
              105.84107148118609,
              21.027712223277792
            ],
            [
              105.84097146443914,
              21.027724207825884
            ],
            [
              105.8408456600818,
              21.02775988309214
            ],
            [
              105.8407952850659,
              21.027768431884716
            ],
            [
              105.84077887622931,
              21.027771216360545
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D225_Nguyễn Khuyến",
          "maTaiSan": "04.O14.DOPP.1174",
          "duongKinh": 225,
          "chieudaiQL": 79,
          "chatLieu": "PVC",
          "from_latitude": 21.027663739928695,
          "to_latitude": 21.027771216360545,
          "to_longitude": 105.84077887622931,
          "from_longitude": 105.84147609717849
        }
      },
      {
        "id": 1171,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84072254060177,
            21.027771216360545,
            105.84086924459268,
            21.028973488436204
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84077887622931,
              21.027771216360545
            ],
            [
              105.8407802697752,
              21.02777491629452
            ],
            [
              105.8407762676656,
              21.027818800167843
            ],
            [
              105.8407756775867,
              21.027829540591526
            ],
            [
              105.84079257384533,
              21.02798242947831
            ],
            [
              105.84076150681001,
              21.028024498919407
            ],
            [
              105.840771507117,
              21.028087987545636
            ],
            [
              105.84078169095957,
              21.028152637710075
            ],
            [
              105.84072254060177,
              21.0281711888017
            ],
            [
              105.84072540614656,
              21.028187086700097
            ],
            [
              105.84074175076655,
              21.028277470820477
            ],
            [
              105.8407451995095,
              21.028294950086536
            ],
            [
              105.84074558961788,
              21.02829692440794
            ],
            [
              105.84074728836724,
              21.0283054695232
            ],
            [
              105.84074907237002,
              21.0283335021392
            ],
            [
              105.84076930883182,
              21.028443175192855
            ],
            [
              105.84076717551741,
              21.028446855532597
            ],
            [
              105.84075923306992,
              21.028460571393413
            ],
            [
              105.84075961449226,
              21.028464598781706
            ],
            [
              105.84077234654863,
              21.02863537759454
            ],
            [
              105.84077250087404,
              21.02863733592037
            ],
            [
              105.84077398303039,
              21.028650657441403
            ],
            [
              105.84077666427373,
              21.02867537169724
            ],
            [
              105.8408264377093,
              21.028835636289777
            ],
            [
              105.84086645096967,
              21.028943493599236
            ],
            [
              105.84086924459268,
              21.028973488436204
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D200_Nguyễn Thái Học",
          "maTaiSan": "04.O14.DOPP.1175",
          "duongKinh": 200,
          "chieudaiQL": 141,
          "chatLieu": "G-DI",
          "from_latitude": 21.027771216360545,
          "to_latitude": 21.028973488436204,
          "to_longitude": 105.84086924459268,
          "from_longitude": 105.84077887622931
        }
      },
      {
        "id": 1172,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8391765651706,
            21.027846164937863,
            105.84039258721737,
            21.02821825086587
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84039258721737,
              21.027846164937863
            ],
            [
              105.8403794395003,
              21.027848761646585
            ],
            [
              105.84036666559071,
              21.027851284249174
            ],
            [
              105.84033202254032,
              21.027862273342908
            ],
            [
              105.84024038623447,
              21.027891339340247
            ],
            [
              105.84019710984482,
              21.027905038281336
            ],
            [
              105.84017503230695,
              21.027912027208345
            ],
            [
              105.84008841611447,
              21.027939445231237
            ],
            [
              105.84007396247138,
              21.02794402010278
            ],
            [
              105.8399226190383,
              21.02798895786111
            ],
            [
              105.83987016585178,
              21.02800453197614
            ],
            [
              105.8396625015878,
              21.028064498193284
            ],
            [
              105.8396078032795,
              21.02808029376561
            ],
            [
              105.83960409877658,
              21.028081363407495
            ],
            [
              105.83947340674942,
              21.028122196627077
            ],
            [
              105.83941177816575,
              21.02814145136969
            ],
            [
              105.83936897215226,
              21.02815482533145
            ],
            [
              105.83935229687724,
              21.028160035235683
            ],
            [
              105.8391765651706,
              21.02821825086587
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D200_Nguyễn Khuyến",
          "maTaiSan": "04.O14.DOPP.1176",
          "duongKinh": 200,
          "chieudaiQL": 135,
          "chatLieu": "G-DI",
          "from_latitude": 21.027846164937863,
          "to_latitude": 21.02821825086587,
          "to_longitude": 105.8391765651706,
          "from_longitude": 105.84039258721737
        }
      },
      {
        "id": 1173,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83886539409394,
            21.027532338087873,
            105.8391765651706,
            21.02821825086587
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8391765651706,
              21.02821825086587
            ],
            [
              105.83913304941333,
              21.028121862351284
            ],
            [
              105.83913152801748,
              21.028118517097006
            ],
            [
              105.83910776121711,
              21.028057130343957
            ],
            [
              105.83907754813598,
              21.027994569592995
            ],
            [
              105.83902753047067,
              21.027891000895817
            ],
            [
              105.83899642994945,
              21.027821985561495
            ],
            [
              105.8389784147014,
              21.027782006589135
            ],
            [
              105.83892199318176,
              21.027656801139244
            ],
            [
              105.8389202719195,
              21.02765297996713
            ],
            [
              105.83889797257217,
              21.027603493120925
            ],
            [
              105.83886947888735,
              21.027540261780242
            ],
            [
              105.83886539409394,
              21.027532338087873
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D250_Ngô Sĩ Liên",
          "maTaiSan": "04.O14.DOPP.1177",
          "duongKinh": 250,
          "chieudaiQL": 74,
          "chatLieu": "G-DI",
          "from_latitude": 21.02821825086587,
          "to_latitude": 21.027532338087873,
          "to_longitude": 105.83886539409394,
          "from_longitude": 105.8391765651706
        }
      },
      {
        "id": 1174,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8375767021075,
            21.02745323013614,
            105.83826274219788,
            21.02769542508646
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83826274219788,
              21.02755069813005
            ],
            [
              105.83803430537209,
              21.027619143956457
            ],
            [
              105.83803282346587,
              21.027619587516114
            ],
            [
              105.83803058092371,
              21.027620259726756
            ],
            [
              105.83783696623935,
              21.02767827126379
            ],
            [
              105.83781795053086,
              21.027677311795685
            ],
            [
              105.8377724590567,
              21.027689915493262
            ],
            [
              105.83775577467047,
              21.027692291876285
            ],
            [
              105.8377516130152,
              21.027692810957795
            ],
            [
              105.83773067112315,
              21.02769542508646
            ],
            [
              105.83772461062809,
              21.027688229160415
            ],
            [
              105.83769080820234,
              21.027648090265664
            ],
            [
              105.83767203201708,
              21.027618893953
            ],
            [
              105.83766825387087,
              21.027615737736056
            ],
            [
              105.83766631598783,
              21.02761411877136
            ],
            [
              105.83766608394818,
              21.027613739657582
            ],
            [
              105.83762207521268,
              21.027541860290086
            ],
            [
              105.83758668028672,
              21.02746962054778
            ],
            [
              105.8375767021075,
              21.02745323013614
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 38, Ngô Sĩ Liên",
          "maTaiSan": "04.O14.DOPP.1178",
          "duongKinh": 100,
          "chieudaiQL": 94,
          "chatLieu": "G-DI",
          "from_latitude": 21.02755069813005,
          "to_latitude": 21.02745323013614,
          "to_longitude": 105.8375767021075,
          "from_longitude": 105.83826274219788
        }
      },
      {
        "id": 1175,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83810208653018,
            21.02707552683368,
            105.83826274219788,
            21.02755069813005
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83810208653018,
              21.02707552683368
            ],
            [
              105.83813463623959,
              21.027183946433798
            ],
            [
              105.83813602918994,
              21.02718858484312
            ],
            [
              105.83814411981233,
              21.02719686455176
            ],
            [
              105.8381482951506,
              21.02720034396082
            ],
            [
              105.83815818406958,
              21.02722312959523
            ],
            [
              105.83815891177953,
              21.027225615290423
            ],
            [
              105.83816028628429,
              21.02723039559638
            ],
            [
              105.83818954238093,
              21.027332319179582
            ],
            [
              105.83820016201012,
              21.027359672419394
            ],
            [
              105.83820989411608,
              21.027373679497746
            ],
            [
              105.83821063506224,
              21.02737474706769
            ],
            [
              105.83821227797456,
              21.02737660322124
            ],
            [
              105.83821288886065,
              21.027377292981914
            ],
            [
              105.83823644838873,
              21.027461051920707
            ],
            [
              105.83826274219788,
              21.02755069813005
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 38, Ngô Sĩ Liên",
          "maTaiSan": "04.O14.DOPP.1179",
          "duongKinh": 100,
          "chieudaiQL": 57,
          "chatLieu": "G-DI",
          "from_latitude": 21.02707552683368,
          "to_latitude": 21.02755069813005,
          "to_longitude": 105.83826274219788,
          "from_longitude": 105.83810208653018
        }
      },
      {
        "id": 1176,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83755547639949,
            21.02707552683368,
            105.83810208653018,
            21.02745323013614
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8375767021075,
              21.02745323013614
            ],
            [
              105.83755547639949,
              21.027427425733453
            ],
            [
              105.83755777283694,
              21.027426241136283
            ],
            [
              105.83756166161804,
              21.027424233989237
            ],
            [
              105.83759562852453,
              21.02740670764714
            ],
            [
              105.83762773736211,
              21.027390139738017
            ],
            [
              105.83758159884985,
              21.027225636309886
            ],
            [
              105.83760322930867,
              21.027219376111873
            ],
            [
              105.83762813492741,
              21.02721216855595
            ],
            [
              105.83765761558375,
              21.027202844789883
            ],
            [
              105.8377181738878,
              21.027183692602996
            ],
            [
              105.83778814636214,
              21.02716074564027
            ],
            [
              105.83783487788979,
              21.027145420744905
            ],
            [
              105.83784632502721,
              21.027141666551326
            ],
            [
              105.83785784802448,
              21.027137887596208
            ],
            [
              105.83792618424977,
              21.027116692762867
            ],
            [
              105.83793667413647,
              21.027114651274452
            ],
            [
              105.837992038477,
              21.02709945721709
            ],
            [
              105.83803430327505,
              21.027090657483274
            ],
            [
              105.83803612320078,
              21.02709027819321
            ],
            [
              105.83803878417713,
              21.027090288563485
            ],
            [
              105.83804421773,
              21.027090310560382
            ],
            [
              105.83809442114938,
              21.027078091829416
            ],
            [
              105.83809559165037,
              21.027077700384563
            ],
            [
              105.83810208653018,
              21.02707552683368
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 56, Ngô Sĩ Liên",
          "maTaiSan": "04.O14.DOPP.1180",
          "duongKinh": 100,
          "chieudaiQL": 85,
          "chatLieu": "G-DI",
          "from_latitude": 21.02745323013614,
          "to_latitude": 21.02707552683368,
          "to_longitude": 105.83810208653018,
          "from_longitude": 105.8375767021075
        }
      },
      {
        "id": 1177,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83788648305176,
            21.027532338087873,
            105.83886539409394,
            21.027850905025243
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83886539409394,
              21.027532338087873
            ],
            [
              105.83859856328968,
              21.027623758753652
            ],
            [
              105.83848817188068,
              21.027653434752146
            ],
            [
              105.83830036523747,
              21.027705979870365
            ],
            [
              105.83824131824481,
              21.027721884054504
            ],
            [
              105.83823042954928,
              21.02772521281444
            ],
            [
              105.83821628902963,
              21.027729534889776
            ],
            [
              105.83816598637367,
              21.02774692510092
            ],
            [
              105.83809041138623,
              21.027773052083322
            ],
            [
              105.83808107663137,
              21.02777643369115
            ],
            [
              105.8380703440097,
              21.0277803207667
            ],
            [
              105.83803546112175,
              21.027801783446407
            ],
            [
              105.83798289671309,
              21.027818311334535
            ],
            [
              105.83793594151125,
              21.027833239055926
            ],
            [
              105.83793341269697,
              21.027833984933828
            ],
            [
              105.8379283156874,
              21.027835487722257
            ],
            [
              105.83792481203058,
              21.027836520209135
            ],
            [
              105.8378918170326,
              21.027848942233245
            ],
            [
              105.83788648305176,
              21.027850905025243
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 34, Ngô Sĩ Liên",
          "maTaiSan": "04.O14.DOPP.1181",
          "duongKinh": 100,
          "chieudaiQL": 105,
          "chatLieu": "G-DI",
          "from_latitude": 21.027532338087873,
          "to_latitude": 21.027850905025243,
          "to_longitude": 105.83788648305176,
          "from_longitude": 105.83886539409394
        }
      },
      {
        "id": 1178,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83874648326486,
            21.027038997493726,
            105.83924367261542,
            21.02717052912972
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83874648326486,
              21.02717052912972
            ],
            [
              105.83876732469378,
              21.02716717021519
            ],
            [
              105.83878254357873,
              21.027163195936765
            ],
            [
              105.83908679718454,
              21.02708374321501
            ],
            [
              105.83908951089802,
              21.02708303524664
            ],
            [
              105.83912970261335,
              21.027072539284095
            ],
            [
              105.8392262764075,
              21.02704411700561
            ],
            [
              105.83924367261542,
              21.027038997493726
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngô Sĩ Liên",
          "maTaiSan": "04.O14.DOPP.1182",
          "duongKinh": 100,
          "chieudaiQL": 52,
          "chatLieu": "G-DI",
          "from_latitude": 21.02717052912972,
          "to_latitude": 21.027038997493726,
          "to_longitude": 105.83924367261542,
          "from_longitude": 105.83874648326486
        }
      },
      {
        "id": 1179,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83770496257347,
            21.02648556898887,
            105.83836133300557,
            21.02680805671328
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83836133300557,
              21.02648556898887
            ],
            [
              105.83833826222975,
              21.026495209917535
            ],
            [
              105.83827192245172,
              21.026522931690604
            ],
            [
              105.83826749410633,
              21.026524782545643
            ],
            [
              105.83825975937323,
              21.026528014468408
            ],
            [
              105.8382526983988,
              21.02653096578031
            ],
            [
              105.8381391170982,
              21.02657842907106
            ],
            [
              105.83801023780269,
              21.02664532193374
            ],
            [
              105.83784096255697,
              21.0267331797284
            ],
            [
              105.83783706618742,
              21.026735202273553
            ],
            [
              105.83783657026684,
              21.026735459425723
            ],
            [
              105.83783329355482,
              21.026737160080174
            ],
            [
              105.83783011180628,
              21.02673881149266
            ],
            [
              105.83782265479562,
              21.026742682412767
            ],
            [
              105.83773094463588,
              21.026793624380897
            ],
            [
              105.83772592794689,
              21.026796411149743
            ],
            [
              105.8377240060469,
              21.026797478291375
            ],
            [
              105.83772193933696,
              21.026798626533203
            ],
            [
              105.83770698037405,
              21.026806935809272
            ],
            [
              105.83770496257347,
              21.02680805671328
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ Phổ Giác, Ngô Sĩ Liên",
          "maTaiSan": "04.O14.DOPP.1183",
          "duongKinh": 100,
          "chieudaiQL": 77,
          "chatLieu": "G-DI",
          "from_latitude": 21.02648556898887,
          "to_latitude": 21.02680805671328,
          "to_longitude": 105.83770496257347,
          "from_longitude": 105.83836133300557
        }
      },
      {
        "id": 1180,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83588468841805,
            21.02720378929906,
            105.83620125847574,
            21.027967292664492
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83588468841805,
              21.02720378929906
            ],
            [
              105.83597051804426,
              21.02741079438574
            ],
            [
              105.83606694757455,
              21.02764336180572
            ],
            [
              105.83609284900449,
              21.027705830525527
            ],
            [
              105.83620125847574,
              21.027967292664492
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D200_Văn Miếu",
          "maTaiSan": "04.O14.DOPP.1184",
          "duongKinh": 200,
          "chieudaiQL": 84,
          "chatLieu": "G-DI",
          "from_latitude": 21.02720378929906,
          "to_latitude": 21.027967292664492,
          "to_longitude": 105.83620125847574,
          "from_longitude": 105.83588468841805
        }
      },
      {
        "id": 1181,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83705775500349,
            21.029658388854163,
            105.83843164741144,
            21.0300485037816
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83705775500349,
              21.030048237138637
            ],
            [
              105.83705826636783,
              21.0300485037816
            ],
            [
              105.83726641743829,
              21.029992671400663
            ],
            [
              105.83730975270421,
              21.029980225663888
            ],
            [
              105.83744296455589,
              21.02994196666588
            ],
            [
              105.83749561170329,
              21.02992684573902
            ],
            [
              105.83757710416036,
              21.029903440157454
            ],
            [
              105.83763882691254,
              21.029885712941873
            ],
            [
              105.83774919440496,
              21.02985401525319
            ],
            [
              105.83793032076112,
              21.029801993141337
            ],
            [
              105.8380539274377,
              21.029766492480498
            ],
            [
              105.838156808731,
              21.02973694406615
            ],
            [
              105.83816018867542,
              21.029735973602413
            ],
            [
              105.83840638361961,
              21.029665263206816
            ],
            [
              105.83843164741144,
              21.029658388854163
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Nguyễn Thái Học",
          "maTaiSan": "04.O14.DOPP.1185",
          "duongKinh": 150,
          "chieudaiQL": 145,
          "chatLieu": "G-DI",
          "from_latitude": 21.030048237138637,
          "to_latitude": 21.029658388854163,
          "to_longitude": 105.83843164741144,
          "from_longitude": 105.83705775500349
        }
      },
      {
        "id": 1182,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84016798396704,
            21.028973488436204,
            105.84086924459268,
            21.029177536965843
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84016798396704,
              21.029177536965843
            ],
            [
              105.84019899814045,
              21.02916852853103
            ],
            [
              105.84022150923387,
              21.029161965581643
            ],
            [
              105.8404875949804,
              21.029084413187178
            ],
            [
              105.84073512067094,
              21.02901226851673
            ],
            [
              105.84084621219766,
              21.028979889661276
            ],
            [
              105.84086924459268,
              21.028973488436204
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Nguyễn Thái Học",
          "maTaiSan": "04.O14.DOPP.1186",
          "duongKinh": 100,
          "chieudaiQL": 72,
          "chatLieu": "G-DI",
          "from_latitude": 21.029177536965843,
          "to_latitude": 21.028973488436204,
          "to_longitude": 105.84086924459268,
          "from_longitude": 105.84016798396704
        }
      },
      {
        "id": 1183,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84086924459268,
            21.02875049288147,
            105.84148855976694,
            21.028973488436204
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84086924459268,
              21.028973488436204
            ],
            [
              105.8410136419701,
              21.02893335589703
            ],
            [
              105.84101995068333,
              21.02893160224986
            ],
            [
              105.84102686933022,
              21.02892967939286
            ],
            [
              105.84117166139995,
              21.028889438189342
            ],
            [
              105.84117970001427,
              21.028887204496822
            ],
            [
              105.8414298924741,
              21.028776460897493
            ],
            [
              105.84148855976694,
              21.02875049288147
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Nguyễn Thái Học",
          "maTaiSan": "04.O14.DOPP.1187",
          "duongKinh": 150,
          "chieudaiQL": 71,
          "chatLieu": "G-DI",
          "from_latitude": 21.028973488436204,
          "to_latitude": 21.02875049288147,
          "to_longitude": 105.84148855976694,
          "from_longitude": 105.84086924459268
        }
      },
      {
        "id": 1184,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84147882052791,
            21.028124535927898,
            105.84148855976694,
            21.028750492881457
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84148855976694,
              21.028750492881457
            ],
            [
              105.84148782378968,
              21.02870319117275
            ],
            [
              105.84148546849634,
              21.028551829136024
            ],
            [
              105.84148533449182,
              21.028543210351117
            ],
            [
              105.84148332083775,
              21.028413803046075
            ],
            [
              105.84148318113516,
              21.02840485461316
            ],
            [
              105.84148311021976,
              21.02840027653143
            ],
            [
              105.8414830708233,
              21.02839771428537
            ],
            [
              105.84148303617367,
              21.028395483498322
            ],
            [
              105.84148300607303,
              21.028393548945594
            ],
            [
              105.84148290549228,
              21.028387627919443
            ],
            [
              105.84148160371828,
              21.028303457676827
            ],
            [
              105.84148068854108,
              21.028244625415958
            ],
            [
              105.84147882052791,
              21.028124535927898
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Lê Duẩn",
          "maTaiSan": "04.O14.DOPP.1188",
          "duongKinh": 100,
          "chieudaiQL": 55,
          "chatLieu": "G-DI",
          "from_latitude": 21.028750492881457,
          "to_latitude": 21.028124535927898,
          "to_longitude": 105.84147882052791,
          "from_longitude": 105.84148855976694
        }
      },
      {
        "id": 1185,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83947040408657,
            21.02872028468884,
            105.8396932035334,
            21.029265431801985
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8396932035334,
              21.029265431801985
            ],
            [
              105.83965290862959,
              21.02915147251622
            ],
            [
              105.83961314412056,
              21.029051126675256
            ],
            [
              105.83961193202984,
              21.02904804544631
            ],
            [
              105.83955364968973,
              21.02890099236357
            ],
            [
              105.83955064547281,
              21.028893466192994
            ],
            [
              105.83954995759935,
              21.028891743531332
            ],
            [
              105.8395494951033,
              21.02889058336764
            ],
            [
              105.83954853792535,
              21.028888184618868
            ],
            [
              105.83954849594748,
              21.028888075536212
            ],
            [
              105.83954818504448,
              21.02888735810624
            ],
            [
              105.83954807588464,
              21.028887105742935
            ],
            [
              105.83954675034208,
              21.02888403591221
            ],
            [
              105.83950041912932,
              21.028775770489528
            ],
            [
              105.83949968984822,
              21.028774555643004
            ],
            [
              105.83948176650084,
              21.028744676114435
            ],
            [
              105.83947745484478,
              21.028735421306717
            ],
            [
              105.83947617536772,
              21.028732674601493
            ],
            [
              105.83947040408657,
              21.02872028468884
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Yên Thế",
          "maTaiSan": "04.O14.DOPP.1189",
          "duongKinh": 100,
          "chieudaiQL": 67,
          "chatLieu": "G-DI",
          "from_latitude": 21.029265431801985,
          "to_latitude": 21.02872028468884,
          "to_longitude": 105.83947040408657,
          "from_longitude": 105.8396932035334
        }
      },
      {
        "id": 1186,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84007291915232,
            21.028061344808645,
            105.84022150923387,
            21.029161965581643
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84022150923387,
              21.029161965581643
            ],
            [
              105.84021139510668,
              21.029127572985896
            ],
            [
              105.840176370741,
              21.029013265339223
            ],
            [
              105.84017361862199,
              21.028868794088662
            ],
            [
              105.84018661269536,
              21.028771149365575
            ],
            [
              105.84018712900054,
              21.02876423084167
            ],
            [
              105.84018767940091,
              21.02875704479551
            ],
            [
              105.84018341336741,
              21.028734998347915
            ],
            [
              105.8401824365016,
              21.02872994693641
            ],
            [
              105.84018531458801,
              21.028669999402453
            ],
            [
              105.84018986933191,
              21.028575529654194
            ],
            [
              105.84019457772109,
              21.028534215583488
            ],
            [
              105.84019491552841,
              21.02853132269985
            ],
            [
              105.84019112481316,
              21.028515811426026
            ],
            [
              105.8401885053548,
              21.028452844485333
            ],
            [
              105.84018823493237,
              21.02841112146779
            ],
            [
              105.84018789672815,
              21.02840862301954
            ],
            [
              105.84018700429807,
              21.02840198693873
            ],
            [
              105.8401868044732,
              21.028400491286714
            ],
            [
              105.84018419649223,
              21.028380934055196
            ],
            [
              105.8401791847091,
              21.028362799533816
            ],
            [
              105.84015677101502,
              21.02829969916681
            ],
            [
              105.8401536908712,
              21.02829098745112
            ],
            [
              105.84014631229294,
              21.02827023534199
            ],
            [
              105.8401191709984,
              21.02819043516477
            ],
            [
              105.84011459692256,
              21.028179333676906
            ],
            [
              105.84008928682876,
              21.028107681447057
            ],
            [
              105.84007291915232,
              21.028061344808645
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ Yên Thế, Nguyễn Thái Học",
          "maTaiSan": "04.O14.DOPP.1190",
          "duongKinh": 100,
          "chieudaiQL": 121,
          "chatLieu": "G-DI",
          "from_latitude": 21.029161965581643,
          "to_latitude": 21.028061344808645,
          "to_longitude": 105.84007291915232,
          "from_longitude": 105.84022150923387
        }
      },
      {
        "id": 1187,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83650679784101,
            21.02821825086587,
            105.8391765651706,
            21.028761883381545
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83650679784101,
              21.028761883381545
            ],
            [
              105.83652943386836,
              21.028759068678273
            ],
            [
              105.83671681564836,
              21.028746212460717
            ],
            [
              105.83674827482785,
              21.028742103349135
            ],
            [
              105.8367636444022,
              21.02874009572391
            ],
            [
              105.83687079209147,
              21.02872610047078
            ],
            [
              105.83687909788866,
              21.028725015467725
            ],
            [
              105.83688416404088,
              21.028724353762072
            ],
            [
              105.83706377784532,
              21.028700892720636
            ],
            [
              105.83706712419968,
              21.02870045624901
            ],
            [
              105.83706882477097,
              21.028700233813836
            ],
            [
              105.83715462631231,
              21.02868902603419
            ],
            [
              105.8372115008051,
              21.028681597012117
            ],
            [
              105.83759483938987,
              21.02860543810061
            ],
            [
              105.83760279115553,
              21.028603493131918
            ],
            [
              105.83761149322798,
              21.02860136382242
            ],
            [
              105.83772371328149,
              21.028573912129175
            ],
            [
              105.83774036423118,
              21.028569838755992
            ],
            [
              105.83780774811834,
              21.02855335533983
            ],
            [
              105.8378142213506,
              21.02855177170944
            ],
            [
              105.8385082388947,
              21.028381997106646
            ],
            [
              105.83874614442648,
              21.028323798848934
            ],
            [
              105.83880314096976,
              21.028309856463714
            ],
            [
              105.8388076601088,
              21.028308750304507
            ],
            [
              105.83888012077446,
              21.028290974205266
            ],
            [
              105.83897817057182,
              21.028266920879446
            ],
            [
              105.8390399347887,
              21.028251768961827
            ],
            [
              105.8390496781843,
              21.028249378829706
            ],
            [
              105.83909034739084,
              21.02823940167215
            ],
            [
              105.8391110667533,
              21.02823431906244
            ],
            [
              105.8391765651706,
              21.02821825086587
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Nguyễn Khuyến",
          "maTaiSan": "04.O14.DOPP.1191",
          "duongKinh": 100,
          "chieudaiQL": 279,
          "chatLieu": "G-DI",
          "from_latitude": 21.028761883381545,
          "to_latitude": 21.02821825086587,
          "to_longitude": 105.8391765651706,
          "from_longitude": 105.83650679784101
        }
      },
      {
        "id": 1188,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83739165584512,
            21.02812025865717,
            105.83913304941333,
            21.028572503832322
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83913304941333,
              21.028121862351274
            ],
            [
              105.83911905176178,
              21.02812025865717
            ],
            [
              105.83905245927996,
              21.028157758473647
            ],
            [
              105.83902511602244,
              21.02816912496213
            ],
            [
              105.83899049760103,
              21.02818351520148
            ],
            [
              105.83886719627932,
              21.028215889849715
            ],
            [
              105.83884346548027,
              21.028222119023273
            ],
            [
              105.83880998204666,
              21.028230119650736
            ],
            [
              105.83868438065063,
              21.028260121006728
            ],
            [
              105.83840895334981,
              21.028330480913354
            ],
            [
              105.83835645890683,
              21.028343890650678
            ],
            [
              105.83809261691948,
              21.028413744032797
            ],
            [
              105.8380038235146,
              21.028434374991374
            ],
            [
              105.83770432725025,
              21.028503955486983
            ],
            [
              105.83769737797887,
              21.02850556765178
            ],
            [
              105.83758100449273,
              21.02853104637247
            ],
            [
              105.83756808308253,
              21.028533877493068
            ],
            [
              105.83750735777492,
              21.028547173062755
            ],
            [
              105.83749113295745,
              21.028550723154925
            ],
            [
              105.83739165584512,
              21.028572503832322
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Nguyễn Khuyến",
          "maTaiSan": "04.O14.DOPP.1192",
          "duongKinh": 100,
          "chieudaiQL": 189,
          "chatLieu": "G-DI",
          "from_latitude": 21.028121862351274,
          "to_latitude": 21.028572503832322,
          "to_longitude": 105.83739165584512,
          "from_longitude": 105.83913304941333
        }
      },
      {
        "id": 1189,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83697902663623,
            21.0281133562532,
            105.83701789491735,
            21.028638244802764
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83698018346956,
              21.028638244802764
            ],
            [
              105.83697902663623,
              21.028545295319617
            ],
            [
              105.8369968089722,
              21.028348614114883
            ],
            [
              105.83699724183727,
              21.028343782451657
            ],
            [
              105.83699741120923,
              21.028341897495103
            ],
            [
              105.83699747369313,
              21.02834119989951
            ],
            [
              105.83699893801895,
              21.028324857003305
            ],
            [
              105.83700181872544,
              21.028292716090203
            ],
            [
              105.83701002615655,
              21.028201143990014
            ],
            [
              105.83701043370415,
              21.02819660238587
            ],
            [
              105.83701112828348,
              21.028188850218033
            ],
            [
              105.83701699929962,
              21.028123352113067
            ],
            [
              105.83701789491735,
              21.0281133562532
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 221, Nguyễn Khuyến",
          "maTaiSan": "04.O14.DOPP.1193",
          "duongKinh": 100,
          "chieudaiQL": 50,
          "chatLieu": "G-DI",
          "from_latitude": 21.028638244802764,
          "to_latitude": 21.0281133562532,
          "to_longitude": 105.83701789491735,
          "from_longitude": 105.83698018346956
        }
      },
      {
        "id": 1190,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83710993784359,
            21.02762792938378,
            105.83739165584512,
            21.02857250383232
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83739165584512,
              21.02857250383232
            ],
            [
              105.8373845982368,
              21.02854645893385
            ],
            [
              105.83736181867363,
              21.028537691527756
            ],
            [
              105.83735425872797,
              21.028504727705347
            ],
            [
              105.83733415967572,
              21.028397166136052
            ],
            [
              105.83731321718686,
              21.0282934329607
            ],
            [
              105.83730763863093,
              21.02826694361467
            ],
            [
              105.83730749136917,
              21.028266241632444
            ],
            [
              105.83730773422529,
              21.028263734896562
            ],
            [
              105.83730787613273,
              21.028262272783312
            ],
            [
              105.83728007380279,
              21.028160652597848
            ],
            [
              105.83725328121257,
              21.02805775208332
            ],
            [
              105.83723767653616,
              21.02797166597162
            ],
            [
              105.83723612410135,
              21.02796310022383
            ],
            [
              105.83719978609244,
              21.027855308774033
            ],
            [
              105.83719412653706,
              21.027842213712603
            ],
            [
              105.83715785651783,
              21.02775829861286
            ],
            [
              105.83712192730293,
              21.027662879282087
            ],
            [
              105.83710993784359,
              21.02762792938378
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 115, Nguyễn Khuyến",
          "maTaiSan": "04.O14.DOPP.1194",
          "duongKinh": 100,
          "chieudaiQL": 113,
          "chatLieu": "G-DI",
          "from_latitude": 21.02857250383232,
          "to_latitude": 21.02762792938378,
          "to_longitude": 105.83710993784359,
          "from_longitude": 105.83739165584512
        }
      },
      {
        "id": 1191,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83620541450125,
            21.027627929383787,
            105.83710993784359,
            21.027977316067425
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83710993784359,
              21.027627929383787
            ],
            [
              105.83709714569902,
              21.027632707179162
            ],
            [
              105.83708810143311,
              21.02763608459327
            ],
            [
              105.8370541122396,
              21.02764877870383
            ],
            [
              105.83704823025046,
              21.027650975392252
            ],
            [
              105.83694264146379,
              21.02769040956354
            ],
            [
              105.83687986148601,
              21.027713856401846
            ],
            [
              105.83686450705122,
              21.027719590658116
            ],
            [
              105.83680323138674,
              21.027742475486683
            ],
            [
              105.83678272839961,
              21.027750132742778
            ],
            [
              105.83674378548525,
              21.027764676582557
            ],
            [
              105.83669146574601,
              21.027784216926225
            ],
            [
              105.8366506815063,
              21.027799448125425
            ],
            [
              105.83659425768114,
              21.027820520627383
            ],
            [
              105.83658381465332,
              21.027824420637106
            ],
            [
              105.83657234344764,
              21.027828704154835
            ],
            [
              105.83656621472197,
              21.027830993266335
            ],
            [
              105.83649264406517,
              21.027858469940675
            ],
            [
              105.83647404667593,
              21.027865415982184
            ],
            [
              105.83638490742625,
              21.027898706465134
            ],
            [
              105.83624892996052,
              21.02794948964466
            ],
            [
              105.83621632635537,
              21.027972953131222
            ],
            [
              105.83620541450125,
              21.027977316067425
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngô Tất Tố",
          "maTaiSan": "04.O14.DOPP.1195",
          "duongKinh": 100,
          "chieudaiQL": 102,
          "chatLieu": "G-DI",
          "from_latitude": 21.027627929383787,
          "to_latitude": 21.027977316067425,
          "to_longitude": 105.83620541450125,
          "from_longitude": 105.83710993784359
        }
      },
      {
        "id": 1192,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83559131510735,
            21.026122096488816,
            105.83799725904558,
            21.027315511953503
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83799725904558,
              21.026122096488816
            ],
            [
              105.83799479451304,
              21.026122978436852
            ],
            [
              105.83796933337959,
              21.026135235085416
            ],
            [
              105.83765574490964,
              21.026293908282575
            ],
            [
              105.83763499829281,
              21.026304405665627
            ],
            [
              105.8372759077061,
              21.026484011395244
            ],
            [
              105.83708082030006,
              21.026583359878178
            ],
            [
              105.83691099814422,
              21.026669842038046
            ],
            [
              105.8367663501968,
              21.026743504460793
            ],
            [
              105.83653608905847,
              21.026860763803988
            ],
            [
              105.83635523661032,
              21.02695600043222
            ],
            [
              105.83621022893391,
              21.02703236118852
            ],
            [
              105.83591077274403,
              21.027190054024143
            ],
            [
              105.83588468841805,
              21.02720378929906
            ],
            [
              105.83588436805208,
              21.027203958873308
            ],
            [
              105.83577867398479,
              21.02725212068645
            ],
            [
              105.835652437292,
              21.027309643229383
            ],
            [
              105.83563524519332,
              21.027315511953503
            ],
            [
              105.83559323316314,
              21.027214200623465
            ],
            [
              105.83559131510735,
              21.02720957561001
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D200_Quốc Tử Giám",
          "maTaiSan": "04.O14.DOPP.1196",
          "duongKinh": 200,
          "chieudaiQL": 325,
          "chatLieu": "G-DI",
          "from_latitude": 21.026122096488816,
          "to_latitude": 21.02720957561001,
          "to_longitude": 105.83559131510735,
          "from_longitude": 105.83799725904558
        }
      },
      {
        "id": 1193,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83701701685413,
            21.025740404529934,
            105.83738249743953,
            21.02621956119781
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83738249743953,
              21.02621956119781
            ],
            [
              105.8373246009686,
              21.026127555849254
            ],
            [
              105.8372572247377,
              21.026008978263533
            ],
            [
              105.8372504355726,
              21.02598963032824
            ],
            [
              105.83724266477272,
              21.02595867087368
            ],
            [
              105.8372325690192,
              21.025918445860555
            ],
            [
              105.83723159101467,
              21.025914546945337
            ],
            [
              105.83722366560674,
              21.025841948763908
            ],
            [
              105.8372204342972,
              21.025825077110035
            ],
            [
              105.83721960589486,
              21.025820749330435
            ],
            [
              105.83721921747173,
              21.02581872260364
            ],
            [
              105.83721644401302,
              21.025800633860573
            ],
            [
              105.8372108224459,
              21.02578209578516
            ],
            [
              105.83719583540915,
              21.025740404529934
            ],
            [
              105.83714150647346,
              21.02574127894189
            ],
            [
              105.83710814145165,
              21.025750076186295
            ],
            [
              105.83709916861254,
              21.02575244254022
            ],
            [
              105.83709723783237,
              21.025752951526485
            ],
            [
              105.83709455618751,
              21.025754059432725
            ],
            [
              105.83701701685413,
              21.025786086410722
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 23, Quốc Tử Giám",
          "maTaiSan": "04.O14.DOPP.1197",
          "duongKinh": 100,
          "chieudaiQL": 93,
          "chatLieu": "G-DI",
          "from_latitude": 21.02621956119781,
          "to_latitude": 21.025786086410722,
          "to_longitude": 105.83701701685413,
          "from_longitude": 105.83738249743953
        }
      },
      {
        "id": 1194,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83645904623286,
            21.02571381091809,
            105.83688115999786,
            21.02627503054326
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83645904623286,
              21.02571381091809
            ],
            [
              105.83650406740817,
              21.02578768900207
            ],
            [
              105.83652446156216,
              21.025817982146183
            ],
            [
              105.83652626390065,
              21.025820659466344
            ],
            [
              105.83655374712876,
              21.025865446983683
            ],
            [
              105.83656068256956,
              21.025875684321246
            ],
            [
              105.83656561401287,
              21.025882964428437
            ],
            [
              105.83656712622734,
              21.0258851960798
            ],
            [
              105.83659293372321,
              21.0259106854378
            ],
            [
              105.83659433140207,
              21.025912734298053
            ],
            [
              105.8366340988208,
              21.025971028684864
            ],
            [
              105.83665907271677,
              21.02600400714308
            ],
            [
              105.83665947688459,
              21.026004374572935
            ],
            [
              105.83668434884325,
              21.026027015236384
            ],
            [
              105.8367035009597,
              21.026047282367585
            ],
            [
              105.83670461424579,
              21.02604870578918
            ],
            [
              105.83673826295258,
              21.02609175223358
            ],
            [
              105.83675495489344,
              21.02612005750372
            ],
            [
              105.83675816360227,
              21.026124098090733
            ],
            [
              105.83678856026063,
              21.026159297968
            ],
            [
              105.83679167833462,
              21.02616347358054
            ],
            [
              105.83682715770175,
              21.026210985564642
            ],
            [
              105.83687136433771,
              21.02627503054326
            ],
            [
              105.83688115999786,
              21.02627064506059
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Lương Sử A",
          "maTaiSan": "04.O14.DOPP.1198",
          "duongKinh": 100,
          "chieudaiQL": 70,
          "chatLieu": "G-DI",
          "from_latitude": 21.02571381091809,
          "to_latitude": 21.02627064506059,
          "to_longitude": 105.83688115999786,
          "from_longitude": 105.83645904623286
        }
      },
      {
        "id": 1195,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8363227510275,
            21.025124502075986,
            105.8371683191343,
            21.025360666106685
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8363227510275,
              21.025360666106685
            ],
            [
              105.83660231837486,
              21.025287044036798
            ],
            [
              105.83666505626877,
              21.025268983449372
            ],
            [
              105.83692532526632,
              21.025187548981553
            ],
            [
              105.83715449602607,
              21.025128089078468
            ],
            [
              105.8371683191343,
              21.025124502075986
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngách 68, Lương Sử C",
          "maTaiSan": "04.O14.DOPP.1199",
          "duongKinh": 100,
          "chieudaiQL": 90.25,
          "chatLieu": "G-DI",
          "from_latitude": 21.025360666106685,
          "to_latitude": 21.025124502075986,
          "to_longitude": 105.8371683191343,
          "from_longitude": 105.8363227510275
        }
      },
      {
        "id": 1196,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8371267781298,
            21.024161801030335,
            105.83735682981204,
            21.02489352725283
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8373447568754,
              21.024161801030335
            ],
            [
              105.83735682981204,
              21.024234995701956
            ],
            [
              105.83734046098904,
              21.024290706714645
            ],
            [
              105.83733980864487,
              21.02429292553297
            ],
            [
              105.8373033751322,
              21.0244045454906
            ],
            [
              105.83728338116411,
              21.024451069597664
            ],
            [
              105.83725933518163,
              21.024507052333128
            ],
            [
              105.8372538750786,
              21.024515006803252
            ],
            [
              105.83725150477046,
              21.024518459752645
            ],
            [
              105.83725047133838,
              21.024519965093937
            ],
            [
              105.83724997800638,
              21.024520684681818
            ],
            [
              105.83722275000027,
              21.024560350995372
            ],
            [
              105.83716100900372,
              21.02462559938196
            ],
            [
              105.8371592607955,
              21.024627446949896
            ],
            [
              105.83715799076182,
              21.024628789068686
            ],
            [
              105.83715757283817,
              21.024629230995597
            ],
            [
              105.83715339550001,
              21.024633645739154
            ],
            [
              105.83713198825328,
              21.024672410873006
            ],
            [
              105.83713177519381,
              21.024687145298692
            ],
            [
              105.83713159608058,
              21.02469176436059
            ],
            [
              105.8371267781298,
              21.024816276619827
            ],
            [
              105.83712774434592,
              21.02489352725283
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D200_Linh Quang",
          "maTaiSan": "04.O14.DOPP.1200",
          "duongKinh": 200,
          "chieudaiQL": 92,
          "chatLieu": "G-DI",
          "from_latitude": 21.024161801030335,
          "to_latitude": 21.02489352725283,
          "to_longitude": 105.83712774434592,
          "from_longitude": 105.8373447568754
        }
      },
      {
        "id": 1197,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83805010545618,
            21.025896612922484,
            105.83854629870117,
            21.02671239652155
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83805010545618,
              21.025896612922484
            ],
            [
              105.83805111438024,
              21.025898295170983
            ],
            [
              105.83806261694876,
              21.025918241261408
            ],
            [
              105.83812074335064,
              21.025947722321334
            ],
            [
              105.83817711340012,
              21.02596571850587
            ],
            [
              105.8382520080566,
              21.02598961353612
            ],
            [
              105.83825445125402,
              21.025994895282533
            ],
            [
              105.8383892257453,
              21.026286267235776
            ],
            [
              105.83842088034999,
              21.02635470010968
            ],
            [
              105.83846199721415,
              21.026443591287254
            ],
            [
              105.83847951747806,
              21.026481470066923
            ],
            [
              105.83854413858904,
              21.026621174448824
            ],
            [
              105.83854629870117,
              21.02671239652155
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D200_Ngô Sĩ Liên",
          "maTaiSan": "04.O14.DOPP.1201",
          "duongKinh": 200,
          "chieudaiQL": 110,
          "chatLieu": "G-DI",
          "from_latitude": 21.025896612922484,
          "to_latitude": 21.02671239652155,
          "to_longitude": 105.83854629870117,
          "from_longitude": 105.83805010545618
        }
      },
      {
        "id": 1198,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83854629870117,
            21.02639015246947,
            105.8397129309006,
            21.02671239652155
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8397129309006,
              21.02639015246947
            ],
            [
              105.83966581912641,
              21.026404494331796
            ],
            [
              105.83966037960285,
              21.026406149699852
            ],
            [
              105.8396164024595,
              21.02641953762318
            ],
            [
              105.83958097288047,
              21.026427113549932
            ],
            [
              105.8395559983543,
              21.026432453862014
            ],
            [
              105.8393777112471,
              21.026484571062777
            ],
            [
              105.83936717428567,
              21.02648729932
            ],
            [
              105.83924969802959,
              21.026517714368175
            ],
            [
              105.83920269522089,
              21.026529884219322
            ],
            [
              105.83908204489222,
              21.026556284637362
            ],
            [
              105.83907782204834,
              21.026557208696016
            ],
            [
              105.83906964311618,
              21.026559794333025
            ],
            [
              105.83906209895,
              21.026562179935123
            ],
            [
              105.83905017769963,
              21.026565948289832
            ],
            [
              105.83904771666073,
              21.02656676701029
            ],
            [
              105.83893707081154,
              21.02660359534852
            ],
            [
              105.8388712861127,
              21.026620989876225
            ],
            [
              105.83872708785293,
              21.02665911893849
            ],
            [
              105.83856352258847,
              21.026704505938607
            ],
            [
              105.83855656010614,
              21.026707695228254
            ],
            [
              105.83854629870117,
              21.02671239652155
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D200_Nguyễn Như Đổ",
          "maTaiSan": "04.O14.DOPP.1202",
          "duongKinh": 200,
          "chieudaiQL": 129,
          "chatLieu": "G-DI",
          "from_latitude": 21.02639015246947,
          "to_latitude": 21.02671239652155,
          "to_longitude": 105.83854629870117,
          "from_longitude": 105.8397129309006
        }
      },
      {
        "id": 1199,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84141122422281,
            21.02415004031794,
            105.84149773886841,
            21.027663739928695
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84143582576866,
              21.02415004031794
            ],
            [
              105.84142294456106,
              21.02469577778685
            ],
            [
              105.84141575303424,
              21.024979002508324
            ],
            [
              105.8414157205661,
              21.024982130528254
            ],
            [
              105.84141569999687,
              21.024984636169066
            ],
            [
              105.8414149366432,
              21.025081221520246
            ],
            [
              105.84141122422281,
              21.02554622781227
            ],
            [
              105.84141525435415,
              21.025579879099542
            ],
            [
              105.84141883455611,
              21.025609638821013
            ],
            [
              105.84143918866485,
              21.025822705815628
            ],
            [
              105.84144571792173,
              21.026137067137228
            ],
            [
              105.84146287614058,
              21.02624605807324
            ],
            [
              105.84146329442697,
              21.026433178371065
            ],
            [
              105.84146330934217,
              21.026436521122907
            ],
            [
              105.8414659962871,
              21.026450586353192
            ],
            [
              105.84147170122851,
              21.02648045207187
            ],
            [
              105.84148220008093,
              21.02655440473451
            ],
            [
              105.84148257315655,
              21.02657373816738
            ],
            [
              105.8414827700754,
              21.026583968894712
            ],
            [
              105.84148347972368,
              21.02662075994823
            ],
            [
              105.84148457183974,
              21.026677357676157
            ],
            [
              105.84148661505012,
              21.0267832530833
            ],
            [
              105.8414885947352,
              21.026885885461503
            ],
            [
              105.84148887704278,
              21.026900530716155
            ],
            [
              105.84149130468154,
              21.027026408009498
            ],
            [
              105.84149305766975,
              21.02711727342434
            ],
            [
              105.84149339412505,
              21.02713470936721
            ],
            [
              105.84149418523594,
              21.02717572799819
            ],
            [
              105.84149427079103,
              21.02718017233125
            ],
            [
              105.84149434118083,
              21.02718380022648
            ],
            [
              105.84149613641475,
              21.02727687742162
            ],
            [
              105.84149773886841,
              21.027359946964754
            ],
            [
              105.84147596609704,
              21.027568755026152
            ],
            [
              105.84147567395891,
              21.027571552847572
            ],
            [
              105.84147161413959,
              21.02758935286528
            ],
            [
              105.84147609717849,
              21.027663739928695
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D110_Lê Duẩn",
          "maTaiSan": "04.O14.DOPP.1203",
          "duongKinh": 110,
          "chieudaiQL": 395,
          "chatLieu": "PVC",
          "from_latitude": 21.02415004031794,
          "to_latitude": 21.027663739928695,
          "to_longitude": 105.84147609717849,
          "from_longitude": 105.84143582576866
        }
      },
      {
        "id": 1200,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83719813093282,
            21.01903561699897,
            105.83863422948585,
            21.019109862558278
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83719813093282,
              21.019109862558278
            ],
            [
              105.83729616747412,
              21.019102882238677
            ],
            [
              105.83742482121933,
              21.019094524560433
            ],
            [
              105.83746654737651,
              21.01909181359645
            ],
            [
              105.83755611984085,
              21.019087284598694
            ],
            [
              105.83785483410824,
              21.01907217938645
            ],
            [
              105.83788543112382,
              21.019070632294785
            ],
            [
              105.83824548288942,
              21.019046796280048
            ],
            [
              105.83827056531494,
              21.01904513539104
            ],
            [
              105.83843408629876,
              21.01903561699897
            ],
            [
              105.83854277802828,
              21.019037359165168
            ],
            [
              105.83860064390801,
              21.01904084847532
            ],
            [
              105.83863422948585,
              21.019042874119965
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Khâm Thiên",
          "maTaiSan": "04.O14.DOPP.1204",
          "duongKinh": 150,
          "chieudaiQL": 151,
          "chatLieu": "G-DI",
          "from_latitude": 21.019109862558278,
          "to_latitude": 21.019042874119965,
          "to_longitude": 105.83863422948585,
          "from_longitude": 105.83719813093282
        }
      },
      {
        "id": 1201,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84128587631501,
            21.018935922245056,
            105.84145369611163,
            21.023610896273574
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84128587631501,
              21.018935922245056
            ],
            [
              105.84128750894403,
              21.018937327714795
            ],
            [
              105.84130255857418,
              21.018951699434016
            ],
            [
              105.84136516591211,
              21.019010909473682
            ],
            [
              105.84138264811457,
              21.019024725413168
            ],
            [
              105.84138183324664,
              21.01918382315166
            ],
            [
              105.84138143352786,
              21.019261917792598
            ],
            [
              105.84138916719799,
              21.019483695758808
            ],
            [
              105.8413943120922,
              21.019630861336964
            ],
            [
              105.84139492868854,
              21.01964851719974
            ],
            [
              105.84139519170489,
              21.01969539497832
            ],
            [
              105.84139555854657,
              21.01976077747549
            ],
            [
              105.8413978589888,
              21.020170781448517
            ],
            [
              105.84139870979956,
              21.020322417531
            ],
            [
              105.84140973003683,
              21.020583003342498
            ],
            [
              105.84141426637294,
              21.020690442748688
            ],
            [
              105.84142292090773,
              21.020894915787633
            ],
            [
              105.84142576793752,
              21.020962218903136
            ],
            [
              105.84143654440402,
              21.021217053270853
            ],
            [
              105.84145369611163,
              21.02162260487648
            ],
            [
              105.84139736608466,
              21.021912035857866
            ],
            [
              105.8413987146142,
              21.022033553291756
            ],
            [
              105.84140143630972,
              21.022278746734656
            ],
            [
              105.8414049906843,
              21.02259895050177
            ],
            [
              105.84140697861028,
              21.022778003659397
            ],
            [
              105.84140950989178,
              21.02300605214709
            ],
            [
              105.84141089087015,
              21.023130428104157
            ],
            [
              105.84141116575995,
              21.023155241882673
            ],
            [
              105.84141176859865,
              21.02320952438637
            ],
            [
              105.84140498317753,
              21.023355306471966
            ],
            [
              105.8414051015535,
              21.023359258388464
            ],
            [
              105.84140521222044,
              21.02336269369976
            ],
            [
              105.8414055384323,
              21.02337265372803
            ],
            [
              105.84141408544231,
              21.023418210637146
            ],
            [
              105.84141599071256,
              21.023589895696347
            ],
            [
              105.84141622400566,
              21.023610896273574
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D110_Lê Duẩn",
          "maTaiSan": "04.O14.DOPP.1205",
          "duongKinh": 110,
          "chieudaiQL": 519,
          "chatLieu": "PVC",
          "from_latitude": 21.018935922245056,
          "to_latitude": 21.023610896273574,
          "to_longitude": 105.84141622400566,
          "from_longitude": 105.84128587631501
        }
      },
      {
        "id": 1202,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83571958653484,
            21.027129990919626,
            105.83577867398479,
            21.02725212068645
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83577867398479,
              21.02725212068645
            ],
            [
              105.83577635867874,
              21.027247335047495
            ],
            [
              105.83571958653484,
              21.027129990919626
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D200_Quốc Tử Giám",
          "maTaiSan": "04.O14.DOPP.1206",
          "duongKinh": 200,
          "chieudaiQL": 15.14,
          "chatLieu": "G-DI",
          "from_latitude": 21.02725212068645,
          "to_latitude": 21.027129990919626,
          "to_longitude": 105.83571958653484,
          "from_longitude": 105.83577867398479
        }
      },
      {
        "id": 1203,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83606278230752,
            21.02571671027307,
            105.8365724817081,
            21.026577167822516
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8365724817081,
              21.026577167822516
            ],
            [
              105.83654167203387,
              21.026541918457248
            ],
            [
              105.83653311228827,
              21.02653212536746
            ],
            [
              105.83653091644106,
              21.026529613466945
            ],
            [
              105.83652869541623,
              21.026527071883873
            ],
            [
              105.83652548878086,
              21.02652340341052
            ],
            [
              105.83650789316282,
              21.02650175931881
            ],
            [
              105.83645929480437,
              21.02644198009188
            ],
            [
              105.83637641283936,
              21.02635845934245
            ],
            [
              105.83636419222756,
              21.026345135484274
            ],
            [
              105.8363629949433,
              21.0263438298926
            ],
            [
              105.83632625410715,
              21.026295213048286
            ],
            [
              105.83631758239358,
              21.02628379559872
            ],
            [
              105.836315724586,
              21.026281349774457
            ],
            [
              105.83629914193426,
              21.026259518226205
            ],
            [
              105.83624746357239,
              21.02621046178998
            ],
            [
              105.8362333873732,
              21.026190946418826
            ],
            [
              105.83622352090264,
              21.026174362079615
            ],
            [
              105.83621745218719,
              21.026167565359838
            ],
            [
              105.8361941173104,
              21.02614143153078
            ],
            [
              105.83615789302227,
              21.026090372500143
            ],
            [
              105.83615605546113,
              21.026087588768895
            ],
            [
              105.83612965530087,
              21.02604759107327
            ],
            [
              105.83611834923812,
              21.0260283375546
            ],
            [
              105.83609089634612,
              21.025978795248516
            ],
            [
              105.83608940735532,
              21.02597572438417
            ],
            [
              105.83607610475359,
              21.025948292959235
            ],
            [
              105.83607216785954,
              21.025927870565486
            ],
            [
              105.83606278230752,
              21.025879180586614
            ],
            [
              105.83610698854771,
              21.02585612434901
            ],
            [
              105.83611147382476,
              21.025853785529435
            ],
            [
              105.83611555527358,
              21.025851657339764
            ],
            [
              105.8361976557774,
              21.025811021395914
            ],
            [
              105.83620128091022,
              21.02580900744764
            ],
            [
              105.83624870685736,
              21.025782662061708
            ],
            [
              105.8362619165292,
              21.0257753234483
            ],
            [
              105.8362652411519,
              21.025773589169194
            ],
            [
              105.8362860761689,
              21.02576272300222
            ],
            [
              105.83629002991368,
              21.025763915685086
            ],
            [
              105.83631085764772,
              21.02577019634088
            ],
            [
              105.83632943725618,
              21.025775799793788
            ],
            [
              105.8364306036109,
              21.02572741425718
            ],
            [
              105.83645026927034,
              21.02571800891449
            ],
            [
              105.8364529844875,
              21.02571671027307
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Lương Sử B",
          "maTaiSan": "04.O14.DOPP.1207",
          "duongKinh": 100,
          "chieudaiQL": 155,
          "chatLieu": "G-DI",
          "from_latitude": 21.026577167822516,
          "to_latitude": 21.02571671027307,
          "to_longitude": 105.8364529844875,
          "from_longitude": 105.8365724817081
        }
      },
      {
        "id": 1204,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83359779377814,
            21.026178366993022,
            105.83364268866967,
            21.026217256429323
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83363303605422,
              21.026178366993022
            ],
            [
              105.83364268866967,
              21.02620083162179
            ],
            [
              105.83359779377814,
              21.026217256429323
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D200_Ngõ Thông Phong, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DOPP.1208",
          "duongKinh": 200,
          "chieudaiQL": 30.91,
          "chatLieu": "G-DI",
          "from_latitude": 21.026178366993022,
          "to_latitude": 21.026217256429323,
          "to_longitude": 105.83359779377814,
          "from_longitude": 105.83363303605422
        }
      },
      {
        "id": 1205,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8397129309006,
            21.02639015246947,
            105.8401888774976,
            21.027437433768718
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8397129309006,
              21.02639015246947
            ],
            [
              105.83971598477915,
              21.02639659181858
            ],
            [
              105.83981569856194,
              21.026606789629447
            ],
            [
              105.83982954248971,
              21.0266422811327
            ],
            [
              105.83986270979554,
              21.026727315602795
            ],
            [
              105.83988774194415,
              21.02679149148109
            ],
            [
              105.83991768353529,
              21.026868253899003
            ],
            [
              105.83992228340873,
              21.02687984933068
            ],
            [
              105.83996601260884,
              21.026990102869984
            ],
            [
              105.83996763326016,
              21.026994163882176
            ],
            [
              105.83997686716393,
              21.027017438572887
            ],
            [
              105.84002495615711,
              21.027138658258867
            ],
            [
              105.84010528941005,
              21.027341156781223
            ],
            [
              105.8401120682613,
              21.027358243872467
            ],
            [
              105.8401888774976,
              21.027437433768718
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Trần Quý Cáp",
          "maTaiSan": "04.O14.DOPP.1209",
          "duongKinh": 100,
          "chieudaiQL": 111,
          "chatLieu": "G-DI",
          "from_latitude": 21.02639015246947,
          "to_latitude": 21.027437433768718,
          "to_longitude": 105.8401888774976,
          "from_longitude": 105.8397129309006
        }
      },
      {
        "id": 1206,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83634166194018,
            21.023966062200287,
            105.8373447568754,
            21.024216609462655
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8373447568754,
              21.024161801030335
            ],
            [
              105.83732813218879,
              21.024163946745475
            ],
            [
              105.83731409113453,
              21.024163459488495
            ],
            [
              105.83720189165585,
              21.0241595697883
            ],
            [
              105.83713269442501,
              21.024158488731995
            ],
            [
              105.83712997111881,
              21.024158504846316
            ],
            [
              105.83712260540236,
              21.02415854923062
            ],
            [
              105.8371040877026,
              21.024158661150583
            ],
            [
              105.83701196478282,
              21.024159216622362
            ],
            [
              105.83693964089302,
              21.024159652935587
            ],
            [
              105.83676394487118,
              21.02415122148297
            ],
            [
              105.83673219259103,
              21.024149697706985
            ],
            [
              105.83670456258196,
              21.024148371321626
            ],
            [
              105.83670435498406,
              21.024158576056955
            ],
            [
              105.83670421295656,
              21.024165533381804
            ],
            [
              105.83670339415087,
              21.024205670332325
            ],
            [
              105.83670336628526,
              21.02420705511079
            ],
            [
              105.83670333752316,
              21.024208451635563
            ],
            [
              105.83670321750539,
              21.02421435388724
            ],
            [
              105.83670317141163,
              21.024216609462655
            ],
            [
              105.83662469995221,
              21.024215592794047
            ],
            [
              105.83657250319428,
              21.024214201171255
            ],
            [
              105.83656646442962,
              21.024212242880754
            ],
            [
              105.83655481983726,
              21.024201609607175
            ],
            [
              105.83647582470206,
              21.024119661211902
            ],
            [
              105.8364469276033,
              21.024089683544908
            ],
            [
              105.83641220032813,
              21.02404702348235
            ],
            [
              105.83637759922416,
              21.024008008201115
            ],
            [
              105.83635688032732,
              21.023985324472953
            ],
            [
              105.83634465659027,
              21.023969851851046
            ],
            [
              105.83634395516098,
              21.023968964722062
            ],
            [
              105.83634166194018,
              21.023966062200287
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 24, Linh Quang",
          "maTaiSan": "04.O14.DOPP.1210",
          "duongKinh": 100,
          "chieudaiQL": 124,
          "chatLieu": "G-CI",
          "from_latitude": 21.024161801030335,
          "to_latitude": 21.023966062200287,
          "to_longitude": 105.83634166194018,
          "from_longitude": 105.8373447568754
        }
      },
      {
        "id": 1207,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83916247783294,
            21.018942258839797,
            105.83978733387448,
            21.018983363216325
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83916247783294,
              21.018983363216325
            ],
            [
              105.8392657953744,
              21.018976910293237
            ],
            [
              105.839266037695,
              21.01897689374288
            ],
            [
              105.8393540433225,
              21.01897139514747
            ],
            [
              105.83940813449898,
              21.01896801573
            ],
            [
              105.8397819232996,
              21.018942447661004
            ],
            [
              105.83978467728691,
              21.018942258839797
            ],
            [
              105.83978733387448,
              21.018942370369537
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Khâm Thiên",
          "maTaiSan": "04.O14.DOPP.1211",
          "duongKinh": 150,
          "chieudaiQL": 69,
          "chatLieu": "G-DI",
          "from_latitude": 21.018983363216325,
          "to_latitude": 21.018942370369537,
          "to_longitude": 105.83978733387448,
          "from_longitude": 105.83916247783294
        }
      },
      {
        "id": 1208,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83978733387448,
            21.018877953118974,
            105.84128587631501,
            21.018942370369537
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83978733387448,
              21.018942370369537
            ],
            [
              105.84009393022015,
              21.01892408181871
            ],
            [
              105.84012923571564,
              21.018921978169654
            ],
            [
              105.84017184480203,
              21.018919433796395
            ],
            [
              105.84057782916857,
              21.018897839921625
            ],
            [
              105.84058101801162,
              21.018897660680643
            ],
            [
              105.84059416122392,
              21.018896974315652
            ],
            [
              105.84067341435357,
              21.018892759319552
            ],
            [
              105.84086179967065,
              21.01888737261097
            ],
            [
              105.84086195354398,
              21.0188873655278
            ],
            [
              105.84096880782853,
              21.018884308274853
            ],
            [
              105.84113450224768,
              21.018879564536725
            ],
            [
              105.84117636565284,
              21.018878369402234
            ],
            [
              105.84119095715633,
              21.018877953118974
            ],
            [
              105.84126687481684,
              21.01891912233377
            ],
            [
              105.84128587631501,
              21.018935922245056
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Khâm Thiên",
          "maTaiSan": "04.O14.DOPP.1212",
          "duongKinh": 150,
          "chieudaiQL": 159,
          "chatLieu": "G-DI",
          "from_latitude": 21.018942370369537,
          "to_latitude": 21.018935922245056,
          "to_longitude": 105.84128587631501,
          "from_longitude": 105.83978733387448
        }
      },
      {
        "id": 1209,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83953543993599,
            21.02386142429155,
            105.83984254539065,
            21.025081192957852
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83954203548394,
              21.02386142429155
            ],
            [
              105.83954143532024,
              21.023872440250663
            ],
            [
              105.83953633008731,
              21.024124474565603
            ],
            [
              105.83953543993599,
              21.024168399073847
            ],
            [
              105.83953661639353,
              21.024268030487505
            ],
            [
              105.83956233629799,
              21.024287118685223
            ],
            [
              105.83956578783766,
              21.02428968034778
            ],
            [
              105.83957038409537,
              21.024293091711293
            ],
            [
              105.83958013399673,
              21.024300328072048
            ],
            [
              105.83958805524702,
              21.024306206883544
            ],
            [
              105.83980064256804,
              21.024298087751838
            ],
            [
              105.83980977085324,
              21.024296876616233
            ],
            [
              105.83983363773535,
              21.024331888707213
            ],
            [
              105.83983467046708,
              21.024360831897386
            ],
            [
              105.83983498423545,
              21.024369618705528
            ],
            [
              105.83983983970867,
              21.024505684205565
            ],
            [
              105.83984179798301,
              21.02456057693031
            ],
            [
              105.83984254539065,
              21.024581517168585
            ],
            [
              105.83983763083538,
              21.02499629228222
            ],
            [
              105.83983808815972,
              21.025081192957852
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D200_Trần Quý Cáp",
          "maTaiSan": "04.O14.DOPP.1213",
          "duongKinh": 200,
          "chieudaiQL": 160,
          "chatLieu": "G-DI",
          "from_latitude": 21.02386142429155,
          "to_latitude": 21.025081192957852,
          "to_longitude": 105.83983808815972,
          "from_longitude": 105.83954203548394
        }
      },
      {
        "id": 1210,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83506246938181,
            21.02233762310178,
            105.8351463361797,
            21.023097202776892
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83506246938181,
              21.02233762310178
            ],
            [
              105.83506288167818,
              21.02233962533116
            ],
            [
              105.8350752231681,
              21.022399434927355
            ],
            [
              105.83510179316688,
              21.02249030697881
            ],
            [
              105.83513282076699,
              21.022577333790622
            ],
            [
              105.8351407707373,
              21.022615531829768
            ],
            [
              105.83514126084965,
              21.02262442346759
            ],
            [
              105.83514247121225,
              21.022646392055336
            ],
            [
              105.8351453005722,
              21.02269771830405
            ],
            [
              105.8351463361797,
              21.022768967637315
            ],
            [
              105.83513353759804,
              21.022780645938607
            ],
            [
              105.83513342146726,
              21.02279502944376
            ],
            [
              105.83513266583837,
              21.022888382230846
            ],
            [
              105.8351427356141,
              21.022904051588515
            ],
            [
              105.83514311996288,
              21.02294594031464
            ],
            [
              105.83514210328185,
              21.023027656344215
            ],
            [
              105.83514202343964,
              21.023034047044085
            ],
            [
              105.8351420939673,
              21.02304411854545
            ],
            [
              105.83514212384262,
              21.02304827322785
            ],
            [
              105.83514215819535,
              21.023053231757384
            ],
            [
              105.83514217997394,
              21.023056278224043
            ],
            [
              105.8351422029359,
              21.02305936442673
            ],
            [
              105.8351422274337,
              21.023064108037392
            ],
            [
              105.83514232459335,
              21.023076715660146
            ],
            [
              105.83514246852762,
              21.023097202776892
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Văn Chương",
          "maTaiSan": "04.O14.DOPP.1214",
          "duongKinh": 100,
          "chieudaiQL": 81,
          "chatLieu": "G-DI",
          "from_latitude": 21.02233762310178,
          "to_latitude": 21.023097202776892,
          "to_longitude": 105.83514246852762,
          "from_longitude": 105.83506246938181
        }
      },
      {
        "id": 1211,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8365176837782,
            21.024893452965898,
            105.83712774434592,
            21.02502482561466
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83712774434592,
              21.02489352725283
            ],
            [
              105.83699586695323,
              21.024893473636318
            ],
            [
              105.83699162839085,
              21.02489347191594
            ],
            [
              105.8369897563433,
              21.02489347119379
            ],
            [
              105.83697970058176,
              21.024893467392793
            ],
            [
              105.8369795697489,
              21.024893467133477
            ],
            [
              105.83697218930436,
              21.024893464617154
            ],
            [
              105.83694500805255,
              21.024893452965898
            ],
            [
              105.83691593737593,
              21.024902390711745
            ],
            [
              105.83690344901764,
              21.02490623035015
            ],
            [
              105.83689019429787,
              21.024910304983997
            ],
            [
              105.83655822587944,
              21.025012362227663
            ],
            [
              105.8365527787778,
              21.025014036501048
            ],
            [
              105.83654883558617,
              21.02501524905534
            ],
            [
              105.83654429409505,
              21.02501664519718
            ],
            [
              105.83652368020036,
              21.02502298244729
            ],
            [
              105.8365176837782,
              21.02502482561466
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Lương Sử C",
          "maTaiSan": "04.O14.DOPP.1215",
          "duongKinh": 100,
          "chieudaiQL": 70,
          "chatLieu": "G-DI",
          "from_latitude": 21.02489352725283,
          "to_latitude": 21.02502482561466,
          "to_longitude": 105.8365176837782,
          "from_longitude": 105.83712774434592
        }
      },
      {
        "id": 1212,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8364529844875,
            21.025291258528778,
            105.83725606391931,
            21.02571671027307
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83725606391931,
              21.025291258528778
            ],
            [
              105.83710694571508,
              21.025337633362945
            ],
            [
              105.8370243988203,
              21.02536886847444
            ],
            [
              105.83697688139551,
              21.025389807826762
            ],
            [
              105.83696955551815,
              21.025393035871733
            ],
            [
              105.83692449834325,
              21.0254080216997
            ],
            [
              105.83692109725857,
              21.025409153014525
            ],
            [
              105.83691684350522,
              21.025410568524624
            ],
            [
              105.83689089942396,
              21.025419197334475
            ],
            [
              105.83676228226601,
              21.025461975511227
            ],
            [
              105.83668282306398,
              21.02549869409095
            ],
            [
              105.83662930211436,
              21.025529187373206
            ],
            [
              105.83662055168811,
              21.025534172858126
            ],
            [
              105.83660756326188,
              21.02555787862106
            ],
            [
              105.83662585960013,
              21.025596330953775
            ],
            [
              105.83661405373938,
              21.02561681000559
            ],
            [
              105.83660904309019,
              21.0256255028881
            ],
            [
              105.83659123639781,
              21.025633076399416
            ],
            [
              105.83654457755719,
              21.02565292122032
            ],
            [
              105.83649573568796,
              21.025681861385603
            ],
            [
              105.83647846454863,
              21.0256978349367
            ],
            [
              105.83647626235398,
              21.025699872601397
            ],
            [
              105.83647024338836,
              21.02570543896471
            ],
            [
              105.83646781776791,
              21.02570726095468
            ],
            [
              105.83645904623286,
              21.02571381091809
            ],
            [
              105.83645708897514,
              21.025714747251936
            ],
            [
              105.8364529844875,
              21.02571671027307
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Lương Sử A",
          "maTaiSan": "04.O14.DOPP.1216",
          "duongKinh": 100,
          "chieudaiQL": 79,
          "chatLieu": "G-DI",
          "from_latitude": 21.025291258528778,
          "to_latitude": 21.02571671027307,
          "to_longitude": 105.8364529844875,
          "from_longitude": 105.83725606391931
        }
      },
      {
        "id": 1213,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83580373709252,
            21.026577225258748,
            105.83657237332184,
            21.02707189319702
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83586782966717,
              21.02707189319702
            ],
            [
              105.83580373709252,
              21.02696688155761
            ],
            [
              105.83592005796989,
              21.02690452031175
            ],
            [
              105.83592400099975,
              21.026902406354793
            ],
            [
              105.83597863656166,
              21.02687311631455
            ],
            [
              105.83596161011197,
              21.026844677129755
            ],
            [
              105.83599565736586,
              21.02682533071489
            ],
            [
              105.83596743691852,
              21.02677824713033
            ],
            [
              105.83602329907238,
              21.02674569492755
            ],
            [
              105.83602890475214,
              21.026742428410632
            ],
            [
              105.83603460991155,
              21.026743717262196
            ],
            [
              105.83604629378583,
              21.02674635684548
            ],
            [
              105.83628082963878,
              21.026622940638543
            ],
            [
              105.83633141760058,
              21.02670516877407
            ],
            [
              105.83633155060133,
              21.02670538489353
            ],
            [
              105.83635727622172,
              21.026691694671783
            ],
            [
              105.83657237332184,
              21.026577225258748
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D160_Quốc Tử Giám",
          "maTaiSan": "04.O14.DOPP.1217",
          "duongKinh": 160,
          "chieudaiQL": 128,
          "chatLieu": "PVC",
          "from_latitude": 21.02707189319702,
          "to_latitude": 21.026577225258748,
          "to_longitude": 105.83657237332184,
          "from_longitude": 105.83586782966717
        }
      },
      {
        "id": 1214,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83034436311131,
            21.019103335175327,
            105.83082853485156,
            21.020294291599615
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83058904552145,
              21.019104412010822
            ],
            [
              105.83058538507277,
              21.019103335175327
            ],
            [
              105.83051954966655,
              21.01911048409759
            ],
            [
              105.83046139916746,
              21.01913722978026
            ],
            [
              105.83045068199957,
              21.019143314740717
            ],
            [
              105.8303901946114,
              21.019177657091234
            ],
            [
              105.83035229887481,
              21.019247622528308
            ],
            [
              105.83034436311131,
              21.019262290800043
            ],
            [
              105.83035211237018,
              21.01929715810028
            ],
            [
              105.8304107002192,
              21.019414178433827
            ],
            [
              105.83042430226212,
              21.01944369354979
            ],
            [
              105.83042435591378,
              21.01944382786838
            ],
            [
              105.83043008483956,
              21.019456241823384
            ],
            [
              105.83043749912363,
              21.019472303804697
            ],
            [
              105.83046516071872,
              21.01953230185993
            ],
            [
              105.83057058762665,
              21.019754736433114
            ],
            [
              105.83057170696145,
              21.019757097416726
            ],
            [
              105.83062072856691,
              21.019858337163154
            ],
            [
              105.83065373872662,
              21.01992647782842
            ],
            [
              105.8306858917326,
              21.019992868609243
            ],
            [
              105.83077770015565,
              21.020186892659588
            ],
            [
              105.83082853485156,
              21.020294291599615
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D200_Tôn Đức Thắng",
          "maTaiSan": "04.O14.DOPP.1218",
          "duongKinh": 200,
          "chieudaiQL": 159,
          "chatLieu": "G-DI",
          "from_latitude": 21.019104412010822,
          "to_latitude": 21.020294291599615,
          "to_longitude": 105.83082853485156,
          "from_longitude": 105.83058904552145
        }
      },
      {
        "id": 1215,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83082853485156,
            21.020140026244057,
            105.83165342615914,
            21.02032826215528
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83082853485156,
              21.020294291599615
            ],
            [
              105.83084531968457,
              21.02028794484807
            ],
            [
              105.8309216540188,
              21.02025449498295
            ],
            [
              105.8309384399864,
              21.02024713931486
            ],
            [
              105.83103141489013,
              21.02020683844164
            ],
            [
              105.83108940172946,
              21.020188694766993
            ],
            [
              105.83119574256735,
              21.020155417911184
            ],
            [
              105.83123933045525,
              21.02026253600375
            ],
            [
              105.83126606904389,
              21.02032826215528
            ],
            [
              105.83127611486154,
              21.020323054745184
            ],
            [
              105.83127746542965,
              21.02032235446836
            ],
            [
              105.83127836327323,
              21.02032189304887
            ],
            [
              105.83127988270239,
              21.020321112463016
            ],
            [
              105.83128436808755,
              21.020318808093883
            ],
            [
              105.83141084603668,
              21.02026023723827
            ],
            [
              105.83146860649923,
              21.02023163249063
            ],
            [
              105.83147186535255,
              21.02023001875643
            ],
            [
              105.83158617762675,
              21.020171531628467
            ],
            [
              105.8316324877608,
              21.02014397183608
            ],
            [
              105.83165342615914,
              21.020140026244057
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 227, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DOPP.1219",
          "duongKinh": 100,
          "chieudaiQL": 107,
          "chatLieu": "G-DI",
          "from_latitude": 21.020294291599615,
          "to_latitude": 21.020140026244057,
          "to_longitude": 105.83165342615914,
          "from_longitude": 105.83082853485156
        }
      },
      {
        "id": 1216,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8314048481378,
            21.019423756104384,
            105.83165342615914,
            21.020140026244057
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83165342615914,
              21.020140026244057
            ],
            [
              105.83163645818091,
              21.020126868833778
            ],
            [
              105.83159558830526,
              21.020048152104177
            ],
            [
              105.8315700698526,
              21.019982854491204
            ],
            [
              105.83156966517524,
              21.019981002149343
            ],
            [
              105.83156942238786,
              21.019979894176387
            ],
            [
              105.83156905776414,
              21.019979193250155
            ],
            [
              105.83154174305508,
              21.019926680525888
            ],
            [
              105.8314930557683,
              21.019804309391702
            ],
            [
              105.8314913012792,
              21.019793576820106
            ],
            [
              105.83148799662493,
              21.019779815184627
            ],
            [
              105.8314879538189,
              21.019779728684295
            ],
            [
              105.83148646150502,
              21.019776723724682
            ],
            [
              105.83147583179698,
              21.01975885209357
            ],
            [
              105.83145857961492,
              21.019720970276552
            ],
            [
              105.83144200522506,
              21.01970574524567
            ],
            [
              105.83141651536933,
              21.01969052133826
            ],
            [
              105.83141361225455,
              21.019672839549497
            ],
            [
              105.83141273712788,
              21.019667507575306
            ],
            [
              105.83141009156431,
              21.019642492149384
            ],
            [
              105.83141034931498,
              21.01964022650523
            ],
            [
              105.83141067397713,
              21.0196373635025
            ],
            [
              105.83141056234996,
              21.01963648882335
            ],
            [
              105.8314071346029,
              21.019609718641828
            ],
            [
              105.8314048481378,
              21.01952211611637
            ],
            [
              105.83143282281348,
              21.01942683369133
            ],
            [
              105.83143362341815,
              21.019423756104384
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ Đoàn Kết, Khâm Thiên",
          "maTaiSan": "04.O14.DOPP.1220",
          "duongKinh": 100,
          "chieudaiQL": 83,
          "chatLieu": "G-DI",
          "from_latitude": 21.020140026244057,
          "to_latitude": 21.019423756104384,
          "to_longitude": 105.83143362341815,
          "from_longitude": 105.83165342615914
        }
      },
      {
        "id": 1217,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83165342615914,
            21.01998304490733,
            105.83234756856939,
            21.02017973137372
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83165342615914,
              21.020140026244057
            ],
            [
              105.83165424164613,
              21.020142234254042
            ],
            [
              105.8316681480009,
              21.02017973137372
            ],
            [
              105.83167042514953,
              21.02017924243417
            ],
            [
              105.83168105952159,
              21.020176957667612
            ],
            [
              105.83175732950667,
              21.020160568757895
            ],
            [
              105.831767455285,
              21.020170129553833
            ],
            [
              105.83183512937441,
              21.020159855021284
            ],
            [
              105.83188480307612,
              21.020105349071855
            ],
            [
              105.83189966130539,
              21.020100267122356
            ],
            [
              105.83190742022958,
              21.020097613437486
            ],
            [
              105.83191952347445,
              21.020096708825378
            ],
            [
              105.83194682428288,
              21.020094667690575
            ],
            [
              105.83199031962995,
              21.020102481908488
            ],
            [
              105.83205610989783,
              21.02012007646464
            ],
            [
              105.83206541294155,
              21.020122480502692
            ],
            [
              105.83207113394725,
              21.020121822066734
            ],
            [
              105.8320746332682,
              21.02012141927225
            ],
            [
              105.8320944367175,
              21.020112479830317
            ],
            [
              105.83210865152886,
              21.020108290686384
            ],
            [
              105.83215725692375,
              21.02010329020392
            ],
            [
              105.83216426949741,
              21.020102568543113
            ],
            [
              105.83221418831882,
              21.020088616986428
            ],
            [
              105.83221753461858,
              21.02008768203525
            ],
            [
              105.8322831498364,
              21.020060213681948
            ],
            [
              105.83227988402324,
              21.02005207805997
            ],
            [
              105.83226384178326,
              21.02001212112517
            ],
            [
              105.83226705623974,
              21.020011003463555
            ],
            [
              105.83233341700326,
              21.019992906011268
            ],
            [
              105.83234756856939,
              21.01998304490733
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngách 24, Kiến Thiết, Khâm Thiên",
          "maTaiSan": "04.O14.DOPP.1221",
          "duongKinh": 100,
          "chieudaiQL": 87,
          "chatLieu": "G-DI",
          "from_latitude": 21.020140026244057,
          "to_latitude": 21.01998304490733,
          "to_longitude": 105.83234756856939,
          "from_longitude": 105.83165342615914
        }
      },
      {
        "id": 1218,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83216245454628,
            21.019438847364608,
            105.83234756856939,
            21.01998304490733
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83216245454628,
              21.019438847364608
            ],
            [
              105.83216271798408,
              21.01944020904177
            ],
            [
              105.83217250788122,
              21.019490657726042
            ],
            [
              105.83219208895805,
              21.01958190142086
            ],
            [
              105.83219735132668,
              21.019606421706957
            ],
            [
              105.83219869827761,
              21.019612698850622
            ],
            [
              105.83221252673897,
              21.019659893325645
            ],
            [
              105.83222253504441,
              21.019694049474865
            ],
            [
              105.83225673062778,
              21.01977702579675
            ],
            [
              105.83226049351208,
              21.01978615524427
            ],
            [
              105.83226076946481,
              21.01978682498994
            ],
            [
              105.83226794128959,
              21.019804227553106
            ],
            [
              105.83228058978206,
              21.019833106787196
            ],
            [
              105.83228436620513,
              21.019841728555626
            ],
            [
              105.83231290084028,
              21.019906878521958
            ],
            [
              105.83232018710844,
              21.019922886846917
            ],
            [
              105.83233005113154,
              21.01994455842103
            ],
            [
              105.83234756856939,
              21.01998304490733
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Ngõ Kiến Thiết, Khâm Thiên",
          "maTaiSan": "04.O14.DOPP.1222",
          "duongKinh": 150,
          "chieudaiQL": 61,
          "chatLieu": "G-DI",
          "from_latitude": 21.019438847364608,
          "to_latitude": 21.01998304490733,
          "to_longitude": 105.83234756856939,
          "from_longitude": 105.83216245454628
        }
      },
      {
        "id": 1219,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83234756856939,
            21.01998304490733,
            105.83259214445285,
            21.02054478078175
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83234756856939,
              21.01998304490733
            ],
            [
              105.83235075399865,
              21.01999004285847
            ],
            [
              105.83236010602707,
              21.020009765067588
            ],
            [
              105.83236555391383,
              21.0200212536183
            ],
            [
              105.83236642992624,
              21.02002309913542
            ],
            [
              105.8323673229878,
              21.02002489669821
            ],
            [
              105.83236919567112,
              21.020028662109617
            ],
            [
              105.83237329612645,
              21.020036908355014
            ],
            [
              105.83240404397641,
              21.020104422049368
            ],
            [
              105.83240761112893,
              21.02011225361383
            ],
            [
              105.83246173312864,
              21.020216898273706
            ],
            [
              105.83247295915679,
              21.020240096847154
            ],
            [
              105.83248213868646,
              21.020259064796672
            ],
            [
              105.83252536245736,
              21.02036193915852
            ],
            [
              105.83254243522919,
              21.020408681461383
            ],
            [
              105.83257375009238,
              21.020494433863036
            ],
            [
              105.83259214445285,
              21.02054478078175
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Ngõ Kiến Thiết, Khâm Thiên",
          "maTaiSan": "04.O14.DOPP.1223",
          "duongKinh": 150,
          "chieudaiQL": 73,
          "chatLieu": "G-DI",
          "from_latitude": 21.01998304490733,
          "to_latitude": 21.02054478078175,
          "to_longitude": 105.83259214445285,
          "from_longitude": 105.83234756856939
        }
      },
      {
        "id": 1220,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83259214445285,
            21.019375571262557,
            105.83330548026403,
            21.02054478078175
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83259214445285,
              21.02054478078175
            ],
            [
              105.83261658152375,
              21.020531820023457
            ],
            [
              105.83270317587709,
              21.02050217500954
            ],
            [
              105.83270266435639,
              21.02049996732357
            ],
            [
              105.83270184890405,
              21.020496382805042
            ],
            [
              105.83268124227209,
              21.02040641504788
            ],
            [
              105.83265545029934,
              21.02032556362355
            ],
            [
              105.83266132513205,
              21.02032350261029
            ],
            [
              105.83267090593499,
              21.020320140876567
            ],
            [
              105.83270004390859,
              21.020309918252106
            ],
            [
              105.83271134695015,
              21.02029899170959
            ],
            [
              105.83285101141941,
              21.020257829926827
            ],
            [
              105.83297090699813,
              21.02023096985035
            ],
            [
              105.8329781699432,
              21.020236875693595
            ],
            [
              105.83301552026323,
              21.02023116956671
            ],
            [
              105.8330364858511,
              21.020236626237644
            ],
            [
              105.83304171934775,
              21.020239122409613
            ],
            [
              105.83308717146632,
              21.02026079667416
            ],
            [
              105.8331915629832,
              21.020277739273194
            ],
            [
              105.83320827000475,
              21.020277491243156
            ],
            [
              105.83327529587498,
              21.020249078053645
            ],
            [
              105.83328637278636,
              21.020242648832618
            ],
            [
              105.8332887421673,
              21.020241274259863
            ],
            [
              105.83330548026403,
              21.020231558470805
            ],
            [
              105.83327602305718,
              21.02014190602099
            ],
            [
              105.83319659545367,
              21.020171337375505
            ],
            [
              105.83318990590602,
              21.020155575458578
            ],
            [
              105.83318672050497,
              21.020148070815505
            ],
            [
              105.83318062359697,
              21.02013370599299
            ],
            [
              105.83316845785156,
              21.02012474508119
            ],
            [
              105.83313954110344,
              21.0200674226793
            ],
            [
              105.83312996198904,
              21.019994897324942
            ],
            [
              105.83312883311635,
              21.019991692794587
            ],
            [
              105.83311768127632,
              21.019960045141882
            ],
            [
              105.833188814632,
              21.019934924898312
            ],
            [
              105.83320037880263,
              21.019930840776645
            ],
            [
              105.83320611528347,
              21.01992881474534
            ],
            [
              105.83319666881769,
              21.01986874059583
            ],
            [
              105.8331892966884,
              21.019833831858023
            ],
            [
              105.83317860188482,
              21.019777962911274
            ],
            [
              105.83316514007852,
              21.019698126908274
            ],
            [
              105.83316539433882,
              21.019696796116595
            ],
            [
              105.83316577009816,
              21.0196948252469
            ],
            [
              105.83316561582289,
              21.019693721359094
            ],
            [
              105.83316133590313,
              21.01966304804723
            ],
            [
              105.83314147802095,
              21.019551177095135
            ],
            [
              105.83312958485526,
              21.019379503477754
            ],
            [
              105.83312931386205,
              21.019375571262557
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ Tiến Bộ, Khâm Thiên",
          "maTaiSan": "04.O14.DOPP.1224",
          "duongKinh": 100,
          "chieudaiQL": 226,
          "chatLieu": "G-DI",
          "from_latitude": 21.02054478078175,
          "to_latitude": 21.019375571262557,
          "to_longitude": 105.83312931386205,
          "from_longitude": 105.83259214445285
        }
      },
      {
        "id": 1221,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83435052492375,
            21.019298608514745,
            105.83456441073267,
            21.02065804191045
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83435052492375,
              21.019298608514745
            ],
            [
              105.83435097618491,
              21.01930156616718
            ],
            [
              105.83435132273182,
              21.01930731529614
            ],
            [
              105.83435332470633,
              21.01934019096788
            ],
            [
              105.8343548599791,
              21.01935229115833
            ],
            [
              105.83435577348044,
              21.01935948808447
            ],
            [
              105.83436888197797,
              21.019445912023144
            ],
            [
              105.83440278771334,
              21.019584824174014
            ],
            [
              105.83441597410598,
              21.019617121110304
            ],
            [
              105.8344160667513,
              21.01961734736512
            ],
            [
              105.83441755476531,
              21.01961905077249
            ],
            [
              105.83441782874705,
              21.01961936465373
            ],
            [
              105.83442797494128,
              21.019675274137334
            ],
            [
              105.83443243144723,
              21.019712223048515
            ],
            [
              105.83444240206882,
              21.01974107189197
            ],
            [
              105.8344431285028,
              21.019743173743972
            ],
            [
              105.83444388328527,
              21.019745356747084
            ],
            [
              105.83444461890744,
              21.019746862426032
            ],
            [
              105.83444587802727,
              21.01974943947051
            ],
            [
              105.83444863044676,
              21.019757089825436
            ],
            [
              105.83440418443148,
              21.019768941380974
            ],
            [
              105.8343602667053,
              21.019780652140465
            ],
            [
              105.83437744926125,
              21.01983493472801
            ],
            [
              105.83437930383548,
              21.01984079658816
            ],
            [
              105.83438982142926,
              21.019864389599498
            ],
            [
              105.83440916433092,
              21.019915861590714
            ],
            [
              105.83441038926028,
              21.01991799893291
            ],
            [
              105.83441244474278,
              21.019921587295023
            ],
            [
              105.83441273130875,
              21.019922088081945
            ],
            [
              105.83442147715282,
              21.019971818250482
            ],
            [
              105.83441145961396,
              21.020014585377524
            ],
            [
              105.83441043974453,
              21.020020775659436
            ],
            [
              105.83441276287013,
              21.0200492980178
            ],
            [
              105.83441476275695,
              21.020073840549596
            ],
            [
              105.83441969805699,
              21.020134424486344
            ],
            [
              105.83448714140224,
              21.020294661589222
            ],
            [
              105.8345360498643,
              21.020399786345685
            ],
            [
              105.83454975347559,
              21.020429240991607
            ],
            [
              105.83456012928578,
              21.02045154397727
            ],
            [
              105.83456441073267,
              21.020488312190995
            ],
            [
              105.83455761287469,
              21.02052554121481
            ],
            [
              105.83455373583743,
              21.020597737776086
            ],
            [
              105.83455358324082,
              21.020600567422587
            ],
            [
              105.83455329033143,
              21.02065804191045
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Ngõ Trung Tả, Khâm Thiên",
          "maTaiSan": "04.O14.DOPP.1225",
          "duongKinh": 150,
          "chieudaiQL": 163,
          "chatLieu": "G-DI",
          "from_latitude": 21.019298608514745,
          "to_latitude": 21.02065804191045,
          "to_longitude": 105.83455329033143,
          "from_longitude": 105.83435052492375
        }
      },
      {
        "id": 1222,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83455329033143,
            21.02065804191045,
            105.83463480628367,
            21.021246644263165
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83455329033143,
              21.02065804191045
            ],
            [
              105.8345567791763,
              21.020680991974334
            ],
            [
              105.8345576559887,
              21.020684358504557
            ],
            [
              105.83456895709446,
              21.02072772374654
            ],
            [
              105.83457465929885,
              21.020745982442893
            ],
            [
              105.83459387536853,
              21.020877645061944
            ],
            [
              105.83459401236776,
              21.02087858103305
            ],
            [
              105.83459536043615,
              21.02088175467037
            ],
            [
              105.83459614022122,
              21.020883588905146
            ],
            [
              105.83460337310834,
              21.020936965568698
            ],
            [
              105.83460605473478,
              21.02097319879086
            ],
            [
              105.83458342085444,
              21.02100333301466
            ],
            [
              105.8345950428059,
              21.02105836362281
            ],
            [
              105.83459506949224,
              21.021058491749674
            ],
            [
              105.83459647344004,
              21.021065137105808
            ],
            [
              105.83460127912788,
              21.02108789373851
            ],
            [
              105.83462927539776,
              21.02122045622975
            ],
            [
              105.83463480628367,
              21.021246644263165
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Ngõ Trung Tả, Khâm Thiên",
          "maTaiSan": "04.O14.DOPP.1226",
          "duongKinh": 150,
          "chieudaiQL": 69,
          "chatLieu": "G-DI",
          "from_latitude": 21.02065804191045,
          "to_latitude": 21.021246644263165,
          "to_longitude": 105.83463480628367,
          "from_longitude": 105.83455329033143
        }
      },
      {
        "id": 1223,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83360070492039,
            21.020299752319552,
            105.83455329033143,
            21.020742741464396
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83455329033143,
              21.02065804191045
            ],
            [
              105.83455031233115,
              21.02065826065024
            ],
            [
              105.8345443439448,
              21.020653706974244
            ],
            [
              105.83453382924473,
              21.02064568735212
            ],
            [
              105.83452381217614,
              21.020647400236875
            ],
            [
              105.8344040524083,
              21.02067844357309
            ],
            [
              105.83435567966733,
              21.02069173335014
            ],
            [
              105.8343514467421,
              21.02069289669199
            ],
            [
              105.83430102546876,
              21.020698663613153
            ],
            [
              105.83429583221873,
              21.020699258116633
            ],
            [
              105.83425994330625,
              21.020708464577893
            ],
            [
              105.83412633077795,
              21.020742741464396
            ],
            [
              105.83405415925108,
              21.020596292541924
            ],
            [
              105.83405269983002,
              21.0205933317035
            ],
            [
              105.83404766773742,
              21.020583121041703
            ],
            [
              105.83403511766569,
              21.02055765475454
            ],
            [
              105.83402431014821,
              21.020535724173754
            ],
            [
              105.83402224508148,
              21.02053145030704
            ],
            [
              105.83399998776919,
              21.020485395650564
            ],
            [
              105.8339792926473,
              21.020429125091457
            ],
            [
              105.83397633214712,
              21.020421073814617
            ],
            [
              105.83395813831737,
              21.020360334480145
            ],
            [
              105.83395122816839,
              21.02033726749703
            ],
            [
              105.83394336790938,
              21.020317716004133
            ],
            [
              105.83394155745357,
              21.020313212372155
            ],
            [
              105.83393978309192,
              21.020308798885612
            ],
            [
              105.83389663096563,
              21.02030651031746
            ],
            [
              105.83386671451888,
              21.020304390789047
            ],
            [
              105.83383755155894,
              21.020304222138908
            ],
            [
              105.83379249219078,
              21.02030337811704
            ],
            [
              105.83361926817943,
              21.020300131925303
            ],
            [
              105.83361669465201,
              21.020300084021635
            ],
            [
              105.83361555075412,
              21.020300062530925
            ],
            [
              105.83361444436177,
              21.020300039049943
            ],
            [
              105.83360737407146,
              21.020299895764605
            ],
            [
              105.83360286478828,
              21.020299797734555
            ],
            [
              105.83360070492039,
              21.020299752319552
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngách 85, Ngõ Trung Tả, Khâm Thiên",
          "maTaiSan": "04.O14.DOPP.1227",
          "duongKinh": 100,
          "chieudaiQL": 138,
          "chatLieu": "G-DI",
          "from_latitude": 21.02065804191045,
          "to_latitude": 21.020299752319552,
          "to_longitude": 105.83360070492039,
          "from_longitude": 105.83455329033143
        }
      },
      {
        "id": 1224,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83532711492211,
            21.01923060960626,
            105.83541237137345,
            21.020341566832663
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83534481099471,
              21.01923060960626
            ],
            [
              105.8353451496223,
              21.01923544832116
            ],
            [
              105.83534480364717,
              21.019238270788957
            ],
            [
              105.83534130595463,
              21.019266937329725
            ],
            [
              105.83534264340096,
              21.019281329056835
            ],
            [
              105.83534374247427,
              21.019293164927138
            ],
            [
              105.83536180807086,
              21.019416638165236
            ],
            [
              105.83536705325614,
              21.01945248846601
            ],
            [
              105.83537644773281,
              21.019518104008622
            ],
            [
              105.83537731449829,
              21.019524154965914
            ],
            [
              105.83540130822607,
              21.01963078737287
            ],
            [
              105.83540481974704,
              21.019646393193142
            ],
            [
              105.83539546470723,
              21.01965969756272
            ],
            [
              105.83536558596575,
              21.0196986910609
            ],
            [
              105.835363676881,
              21.019701182495957
            ],
            [
              105.8353554403113,
              21.019712363310354
            ],
            [
              105.83535341912143,
              21.019715107295355
            ],
            [
              105.83536270859736,
              21.019751681990215
            ],
            [
              105.83536408849767,
              21.019757115331828
            ],
            [
              105.83538560312243,
              21.019830046172892
            ],
            [
              105.83538568156165,
              21.01983031223875
            ],
            [
              105.83538550491026,
              21.019832619940434
            ],
            [
              105.83538549147242,
              21.01983279794155
            ],
            [
              105.83540626490814,
              21.019924979218985
            ],
            [
              105.83540687544728,
              21.01992768679568
            ],
            [
              105.83541237137345,
              21.01995207485146
            ],
            [
              105.83534405622747,
              21.01997429908379
            ],
            [
              105.83533521463394,
              21.01998186545641
            ],
            [
              105.83533541185992,
              21.020015544807602
            ],
            [
              105.83533547200031,
              21.02002582320136
            ],
            [
              105.835335599205,
              21.02004745027526
            ],
            [
              105.8353315774688,
              21.020109317385053
            ],
            [
              105.83532855996525,
              21.020123683529484
            ],
            [
              105.83532711492211,
              21.02013056054749
            ],
            [
              105.83534005261002,
              21.020205201764604
            ],
            [
              105.83536150646273,
              21.020262377063023
            ],
            [
              105.83537380143152,
              21.020316959021468
            ],
            [
              105.8353738610854,
              21.02031816995182
            ],
            [
              105.83537405285333,
              21.020322029389234
            ],
            [
              105.83537423158364,
              21.020325620633166
            ],
            [
              105.83537487614477,
              21.020327355270528
            ],
            [
              105.83538016147737,
              21.020341566832663
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ Hòa Bình, Khâm Thiên",
          "maTaiSan": "04.O14.DOPP.1228",
          "duongKinh": 100,
          "chieudaiQL": 137,
          "chatLieu": "G-DI",
          "from_latitude": 21.01923060960626,
          "to_latitude": 21.020341566832663,
          "to_longitude": 105.83538016147737,
          "from_longitude": 105.83534481099471
        }
      },
      {
        "id": 1225,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83616390946443,
            21.019170705523667,
            105.83640888525082,
            21.020245645477004
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83629527097864,
              21.019170705523667
            ],
            [
              105.8362955062864,
              21.019174778809923
            ],
            [
              105.83630096396818,
              21.01921074635437
            ],
            [
              105.83630133048601,
              21.019213163386077
            ],
            [
              105.83630333456986,
              21.01922236370749
            ],
            [
              105.83631520697674,
              21.019276855545677
            ],
            [
              105.83631631064179,
              21.01929312350463
            ],
            [
              105.83632110330782,
              21.01931023319573
            ],
            [
              105.83638772629575,
              21.019425717899612
            ],
            [
              105.83638931348545,
              21.019428469444467
            ],
            [
              105.83640888525082,
              21.019462395847057
            ],
            [
              105.83639531823589,
              21.019482144707705
            ],
            [
              105.83637301865538,
              21.01949967302528
            ],
            [
              105.83635295781433,
              21.019511728538006
            ],
            [
              105.8363468093618,
              21.01951542314748
            ],
            [
              105.83634146549988,
              21.0195371267946
            ],
            [
              105.83635059354141,
              21.019621816055555
            ],
            [
              105.83634783687425,
              21.019625494891812
            ],
            [
              105.83634277230968,
              21.019632251520292
            ],
            [
              105.83633501673559,
              21.019643075951365
            ],
            [
              105.83633426074567,
              21.019644131020886
            ],
            [
              105.83632378859176,
              21.01966599536485
            ],
            [
              105.83631253369057,
              21.019701539269157
            ],
            [
              105.83629133317258,
              21.01976073692222
            ],
            [
              105.83629054224556,
              21.019762944676966
            ],
            [
              105.83627443859336,
              21.019784781608113
            ],
            [
              105.8362247437326,
              21.019839838960657
            ],
            [
              105.83617560001174,
              21.019887127671307
            ],
            [
              105.83617391025028,
              21.01988875365172
            ],
            [
              105.83617303570809,
              21.019889595239274
            ],
            [
              105.83616390946443,
              21.019898376985854
            ],
            [
              105.8361800866352,
              21.0199573475294
            ],
            [
              105.83618850174042,
              21.019991822861837
            ],
            [
              105.83620398032865,
              21.020055239794146
            ],
            [
              105.83620663876943,
              21.0200627532896
            ],
            [
              105.83621919799988,
              21.02009825779333
            ],
            [
              105.83622379652775,
              21.02011125606143
            ],
            [
              105.83623648299796,
              21.02014180304554
            ],
            [
              105.83624946620125,
              21.020177776944813
            ],
            [
              105.83626854436508,
              21.02023288728714
            ],
            [
              105.83627044292385,
              21.0202373073627
            ],
            [
              105.83627243126561,
              21.02024195731888
            ],
            [
              105.8362740246476,
              21.020245645477004
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ Đại Đồng, Khâm Thiên",
          "maTaiSan": "04.O14.DOPP.1229",
          "duongKinh": 100,
          "chieudaiQL": 136,
          "chatLieu": "G-DI",
          "from_latitude": 21.019170705523667,
          "to_latitude": 21.020245645477004,
          "to_longitude": 105.8362740246476,
          "from_longitude": 105.83629527097864
        }
      },
      {
        "id": 1226,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83717250700906,
            21.019136702414208,
            105.83753690043953,
            21.019782209334593
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83717250700906,
              21.019136702414208
            ],
            [
              105.83721544505113,
              21.01930661147804
            ],
            [
              105.8372415164439,
              21.01937869950956
            ],
            [
              105.83729155592039,
              21.019467644747255
            ],
            [
              105.8372951508511,
              21.01947403452113
            ],
            [
              105.83729709803168,
              21.01947664970469
            ],
            [
              105.83739175277216,
              21.019603737011007
            ],
            [
              105.8375054814961,
              21.019743575994248
            ],
            [
              105.83752342022667,
              21.01976563290835
            ],
            [
              105.83753690043953,
              21.019782209334593
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D200_Văn Chương",
          "maTaiSan": "04.O14.DOPP.1230",
          "duongKinh": 200,
          "chieudaiQL": 81,
          "chatLieu": "G-DI",
          "from_latitude": 21.019136702414208,
          "to_latitude": 21.019782209334593,
          "to_longitude": 105.83753690043953,
          "from_longitude": 105.83717250700906
        }
      },
      {
        "id": 1227,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83498463584402,
            21.019782209334593,
            105.83766060517291,
            21.021216159619755
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83753690043953,
              21.019782209334593
            ],
            [
              105.83754578521072,
              21.019797381293426
            ],
            [
              105.83762546395792,
              21.01991007345267
            ],
            [
              105.83764968807233,
              21.019944335370926
            ],
            [
              105.83766060517291,
              21.019962205179127
            ],
            [
              105.83743145938423,
              21.020148207075746
            ],
            [
              105.83726135370127,
              21.020221699765386
            ],
            [
              105.83718725517076,
              21.020254739497076
            ],
            [
              105.83696170940027,
              21.020370323102203
            ],
            [
              105.83691596116947,
              21.020393767370688
            ],
            [
              105.83688489968206,
              21.020407804551663
            ],
            [
              105.83685113417918,
              21.020423062640543
            ],
            [
              105.83661943978309,
              21.020527925885652
            ],
            [
              105.83661776006481,
              21.02052876601638
            ],
            [
              105.83651451954029,
              21.020579079335814
            ],
            [
              105.83644537283298,
              21.020607282927088
            ],
            [
              105.83641054320253,
              21.02062148848852
            ],
            [
              105.83633979937473,
              21.020650343889894
            ],
            [
              105.83618089283415,
              21.0207036647639
            ],
            [
              105.8360867052742,
              21.020740029098857
            ],
            [
              105.83606840760707,
              21.020747093732496
            ],
            [
              105.83604210050709,
              21.02075725062837
            ],
            [
              105.83570523729146,
              21.02086170735298
            ],
            [
              105.83568136109123,
              21.02086911096631
            ],
            [
              105.83552319905769,
              21.020921126937488
            ],
            [
              105.83543053970676,
              21.02095385515944
            ],
            [
              105.83537244997916,
              21.020974373580785
            ],
            [
              105.83525048367505,
              21.021021530123864
            ],
            [
              105.83523029840508,
              21.021029260582125
            ],
            [
              105.83507147805359,
              21.021090087613892
            ],
            [
              105.83505233653773,
              21.02110369743111
            ],
            [
              105.83498596163018,
              21.021150891569075
            ],
            [
              105.83498543050732,
              21.021212890564215
            ],
            [
              105.83498463584402,
              21.021216159619755
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D200_Văn Chương",
          "maTaiSan": "04.O14.DOPP.1231",
          "duongKinh": 200,
          "chieudaiQL": 334,
          "chatLieu": "G-DI",
          "from_latitude": 21.019782209334593,
          "to_latitude": 21.021216159619755,
          "to_longitude": 105.83498463584402,
          "from_longitude": 105.83753690043953
        }
      },
      {
        "id": 1228,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83498384113038,
            21.021216159619755,
            105.83501515460159,
            21.022334374371365
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83498463584402,
              21.021216159619755
            ],
            [
              105.83498384113038,
              21.0212194196433
            ],
            [
              105.83498534398463,
              21.0212353586843
            ],
            [
              105.83498990689736,
              21.021283779796992
            ],
            [
              105.8349944482989,
              21.02134854936775
            ],
            [
              105.83500015655461,
              21.021487942988113
            ],
            [
              105.83500237755553,
              21.02156391760181
            ],
            [
              105.8350027709411,
              21.021577380932424
            ],
            [
              105.8350040021844,
              21.021619516605107
            ],
            [
              105.8350051372078,
              21.02168928778786
            ],
            [
              105.83500805213265,
              21.021868451416612
            ],
            [
              105.83500811753814,
              21.0218741504381
            ],
            [
              105.83500829989897,
              21.02188996860555
            ],
            [
              105.83501037728499,
              21.02207022743608
            ],
            [
              105.83501136976611,
              21.022125109669872
            ],
            [
              105.8350150482045,
              21.022328569874254
            ],
            [
              105.83501515460159,
              21.022334374371365
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D200_Văn Chương",
          "maTaiSan": "04.O14.DOPP.1232",
          "duongKinh": 200,
          "chieudaiQL": 133,
          "chatLieu": "G-DI",
          "from_latitude": 21.021216159619755,
          "to_latitude": 21.022334374371365,
          "to_longitude": 105.83501515460159,
          "from_longitude": 105.83498463584402
        }
      },
      {
        "id": 1229,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8323167889815,
            21.02296413429409,
            105.83330672601288,
            21.02349828360721
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83330672601288,
              21.02296413429409
            ],
            [
              105.83326511111132,
              21.022987423683833
            ],
            [
              105.83315160028187,
              21.023055707115883
            ],
            [
              105.83314399140957,
              21.023060284895784
            ],
            [
              105.8330936818245,
              21.023092019529347
            ],
            [
              105.83308717659642,
              21.02309612312757
            ],
            [
              105.83300842431302,
              21.023139000128396
            ],
            [
              105.83300637954346,
              21.023140112979775
            ],
            [
              105.83300375420832,
              21.023142003640825
            ],
            [
              105.83299902764179,
              21.02314540665471
            ],
            [
              105.83291149304328,
              21.023188229979915
            ],
            [
              105.83287216582555,
              21.023212768973977
            ],
            [
              105.83283077363764,
              21.023238597036162
            ],
            [
              105.83282672424713,
              21.023241124214994
            ],
            [
              105.8327191542477,
              21.02329780255664
            ],
            [
              105.83271602348456,
              21.02329945181752
            ],
            [
              105.83267174880969,
              21.02332816620771
            ],
            [
              105.83266656889055,
              21.02333152536777
            ],
            [
              105.83253924281738,
              21.023394681597612
            ],
            [
              105.83253810695531,
              21.02339524535022
            ],
            [
              105.83253676008161,
              21.02339644148939
            ],
            [
              105.8325339284939,
              21.02339895728093
            ],
            [
              105.83239786039336,
              21.023461187040407
            ],
            [
              105.83236510157595,
              21.023479160753137
            ],
            [
              105.8323167889815,
              21.02349828360721
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D200_Văn Chương",
          "maTaiSan": "04.O14.DOPP.1233",
          "duongKinh": 200,
          "chieudaiQL": 118,
          "chatLieu": "G-DI",
          "from_latitude": 21.02296413429409,
          "to_latitude": 21.02349828360721,
          "to_longitude": 105.8323167889815,
          "from_longitude": 105.83330672601288
        }
      },
      {
        "id": 1230,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83279508934964,
            21.022389510751054,
            105.83331314687018,
            21.022960776260952
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83331314687018,
              21.022960776260952
            ],
            [
              105.8333034876656,
              21.02294538477758
            ],
            [
              105.83326743864126,
              21.02289784677743
            ],
            [
              105.83322885131409,
              21.02284310083073
            ],
            [
              105.8331755911948,
              21.022767462017825
            ],
            [
              105.83317324774012,
              21.022765026663595
            ],
            [
              105.83313329965317,
              21.02272350604294
            ],
            [
              105.83313195926172,
              21.022723443060475
            ],
            [
              105.83312403239789,
              21.022723073632005
            ],
            [
              105.83309019575297,
              21.02272603936747
            ],
            [
              105.83304940016652,
              21.022681743656566
            ],
            [
              105.8330676491526,
              21.02266636720551
            ],
            [
              105.83305604735597,
              21.02265073141514
            ],
            [
              105.83300944926356,
              21.02258793266414
            ],
            [
              105.83300178103785,
              21.022579226107517
            ],
            [
              105.83296191906284,
              21.022533967479706
            ],
            [
              105.832954229656,
              21.02252542992737
            ],
            [
              105.832917199722,
              21.022484316765805
            ],
            [
              105.8329158022919,
              21.02248299045029
            ],
            [
              105.83291420854155,
              21.02248147722554
            ],
            [
              105.83287520036569,
              21.02244444276648
            ],
            [
              105.832872747272,
              21.022442530008174
            ],
            [
              105.83286331266793,
              21.022435173382256
            ],
            [
              105.83281822367147,
              21.02240001397248
            ],
            [
              105.83279508934964,
              21.022389510751054
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Văn Chương",
          "maTaiSan": "04.O14.DOPP.1234",
          "duongKinh": 100,
          "chieudaiQL": 86,
          "chatLieu": "G-DI",
          "from_latitude": 21.022960776260952,
          "to_latitude": 21.022389510751054,
          "to_longitude": 105.83279508934964,
          "from_longitude": 105.83331314687018
        }
      },
      {
        "id": 1231,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83188499011197,
            21.022197824075693,
            105.83279508934964,
            21.022560236351314
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83279508934964,
              21.022389510751054
            ],
            [
              105.83277889283086,
              21.022375543111693
            ],
            [
              105.83274973987221,
              21.022350400991602
            ],
            [
              105.83272574185223,
              21.022329704587786
            ],
            [
              105.8326506444298,
              21.02226344771569
            ],
            [
              105.83264793045254,
              21.02225907494508
            ],
            [
              105.83263496920544,
              21.02223819714225
            ],
            [
              105.8326106536473,
              21.022205698941082
            ],
            [
              105.83260140856459,
              21.022198166146392
            ],
            [
              105.83260098820418,
              21.022197824075693
            ],
            [
              105.83259951710515,
              21.022198301856825
            ],
            [
              105.83254010187203,
              21.02221761369406
            ],
            [
              105.8325128912227,
              21.022222446359233
            ],
            [
              105.8324848487609,
              21.022222828389374
            ],
            [
              105.83246150887136,
              21.022218780567652
            ],
            [
              105.83244907975585,
              21.022212480894595
            ],
            [
              105.83243735486782,
              21.022206538160443
            ],
            [
              105.83241536965362,
              21.022209631819074
            ],
            [
              105.83227721029867,
              21.022283827675903
            ],
            [
              105.83219458682319,
              21.022328200012865
            ],
            [
              105.83218970453113,
              21.022329889446578
            ],
            [
              105.83217222140577,
              21.022335938073827
            ],
            [
              105.8321692549496,
              21.022337550391548
            ],
            [
              105.83205357367349,
              21.022400427982177
            ],
            [
              105.83206353317262,
              21.02242627831386
            ],
            [
              105.83207330282424,
              21.022456574340353
            ],
            [
              105.8320695976005,
              21.022470848055956
            ],
            [
              105.83206104187269,
              21.022503808824315
            ],
            [
              105.83193987046596,
              21.02254641413609
            ],
            [
              105.83189690111445,
              21.02255481565083
            ],
            [
              105.83188499011197,
              21.022560236351314
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 163, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DOPP.1235",
          "duongKinh": 100,
          "chieudaiQL": 127,
          "chatLieu": "G-DI",
          "from_latitude": 21.022389510751054,
          "to_latitude": 21.022560236351314,
          "to_longitude": 105.83188499011197,
          "from_longitude": 105.83279508934964
        }
      },
      {
        "id": 1232,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83204546626442,
            21.022389510751054,
            105.83279508934964,
            21.02289365339612
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83279508934964,
              21.022389510751054
            ],
            [
              105.8326603316571,
              21.022505292767892
            ],
            [
              105.83264908109085,
              21.022514958151223
            ],
            [
              105.83264355490306,
              21.022519706357638
            ],
            [
              105.83259796980032,
              21.02256618904422
            ],
            [
              105.83255414030936,
              21.022602627401277
            ],
            [
              105.83255305156082,
              21.022603532342
            ],
            [
              105.83252785748058,
              21.022627134978038
            ],
            [
              105.83246071391781,
              21.022670778642237
            ],
            [
              105.83245794498448,
              21.022672275288784
            ],
            [
              105.8323705111605,
              21.022719542607547
            ],
            [
              105.83236580675342,
              21.022722085626114
            ],
            [
              105.83231959015647,
              21.022751360393926
            ],
            [
              105.83226617340077,
              21.022785195535914
            ],
            [
              105.83226105632777,
              21.022788436956866
            ],
            [
              105.83219182956906,
              21.022835137287437
            ],
            [
              105.8320774323264,
              21.02288313432157
            ],
            [
              105.83204642435844,
              21.022893299161286
            ],
            [
              105.83204546626442,
              21.02289365339612
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ Huy Văn, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DOPP.1236",
          "duongKinh": 100,
          "chieudaiQL": 97,
          "chatLieu": "G-DI",
          "from_latitude": 21.022389510751054,
          "to_latitude": 21.02289365339612,
          "to_longitude": 105.83204546626442,
          "from_longitude": 105.83279508934964
        }
      },
      {
        "id": 1233,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83136064795258,
            21.021238911338152,
            105.83221016531499,
            21.021408759243553
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83136064795258,
              21.021408759243553
            ],
            [
              105.83137310336436,
              21.021404726885947
            ],
            [
              105.83143872268535,
              21.021380642300883
            ],
            [
              105.831494484926,
              21.021361120194005
            ],
            [
              105.83149577819563,
              21.021360667678906
            ],
            [
              105.83149748275828,
              21.021360137282326
            ],
            [
              105.83150033201456,
              21.02135924993549
            ],
            [
              105.83155400408572,
              21.021336991321643
            ],
            [
              105.83156320152902,
              21.02133317720044
            ],
            [
              105.83160246998962,
              21.021323067810485
            ],
            [
              105.83161741328098,
              21.02131751579001
            ],
            [
              105.83162463148807,
              21.02131483405057
            ],
            [
              105.83168852810921,
              21.021275710073816
            ],
            [
              105.8316904752617,
              21.02127451732883
            ],
            [
              105.83171368772834,
              21.02126030449195
            ],
            [
              105.83175139780073,
              21.021244825793165
            ],
            [
              105.83180097230405,
              21.021239308535154
            ],
            [
              105.83180417850356,
              21.02123895143699
            ],
            [
              105.8318282834632,
              21.021238922070324
            ],
            [
              105.83183675742484,
              21.021238911338152
            ],
            [
              105.83184401807725,
              21.02124336214564
            ],
            [
              105.8318885549704,
              21.02127066196612
            ],
            [
              105.83189013412763,
              21.021271629725163
            ],
            [
              105.83191876738606,
              21.021280668249215
            ],
            [
              105.83193411721179,
              21.021289095224237
            ],
            [
              105.83201171884956,
              21.02132987468396
            ],
            [
              105.83201238165702,
              21.0213302227961
            ],
            [
              105.83201523438494,
              21.021331695549204
            ],
            [
              105.83201981013772,
              21.02133405858222
            ],
            [
              105.83208763797826,
              21.02135885538816
            ],
            [
              105.83213550552873,
              21.0213627941097
            ],
            [
              105.83214355611615,
              21.021363456529055
            ],
            [
              105.83221016531499,
              21.0213896664707
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 221, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DOPP.1237",
          "duongKinh": 100,
          "chieudaiQL": 94,
          "chatLieu": "G-DI",
          "from_latitude": 21.021408759243553,
          "to_latitude": 21.0213896664707,
          "to_longitude": 105.83221016531499,
          "from_longitude": 105.83136064795258
        }
      },
      {
        "id": 1234,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83221016531499,
            21.021102103284807,
            105.83252630133204,
            21.0213896664707
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83252630133204,
              21.021117050037066
            ],
            [
              105.83241542705669,
              21.021102103284807
            ],
            [
              105.83237916443807,
              21.021146273193295
            ],
            [
              105.83234180008871,
              21.021191784354617
            ],
            [
              105.83227953218208,
              21.021267630221892
            ],
            [
              105.8322178410391,
              21.02136906430229
            ],
            [
              105.83221016531499,
              21.0213896664707
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngách 221/56, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DOPP.1238",
          "duongKinh": 100,
          "chieudaiQL": 50,
          "chatLieu": "G-DI",
          "from_latitude": 21.021117050037066,
          "to_latitude": 21.0213896664707,
          "to_longitude": 105.83221016531499,
          "from_longitude": 105.83252630133204
        }
      },
      {
        "id": 1235,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83240298805953,
            21.02054478078175,
            105.83259214445285,
            21.021117050037066
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83252630133204,
              21.021117050037066
            ],
            [
              105.8325891657055,
              21.02101317862788
            ],
            [
              105.83252509392729,
              21.020977719135537
            ],
            [
              105.83251491770773,
              21.02097208675824
            ],
            [
              105.83250903063305,
              21.020968828738585
            ],
            [
              105.83240298805953,
              21.0209101413619
            ],
            [
              105.83240421026083,
              21.020905205581883
            ],
            [
              105.83240480490846,
              21.020902806417453
            ],
            [
              105.83240593397284,
              21.020898248641032
            ],
            [
              105.83240626517208,
              21.020896912957735
            ],
            [
              105.83240741397597,
              21.020892271988057
            ],
            [
              105.83244139777022,
              21.020755073886427
            ],
            [
              105.83244407647253,
              21.020744260922537
            ],
            [
              105.83245340060745,
              21.02070661496071
            ],
            [
              105.83246447279025,
              21.020651841630748
            ],
            [
              105.83246875334491,
              21.02063066355233
            ],
            [
              105.83247259975103,
              21.020619655195947
            ],
            [
              105.83247363948306,
              21.020616681208672
            ],
            [
              105.83248281671362,
              21.020599708061585
            ],
            [
              105.83248724346262,
              21.020591522150575
            ],
            [
              105.83249151380443,
              21.020584273648694
            ],
            [
              105.83255940934909,
              21.020556339703273
            ],
            [
              105.8325638521516,
              21.020554511508504
            ],
            [
              105.83257551438878,
              21.02054901972413
            ],
            [
              105.83259214445285,
              21.02054478078175
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngách 221/56, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DOPP.1239",
          "duongKinh": 100,
          "chieudaiQL": 84,
          "chatLieu": "G-DI",
          "from_latitude": 21.021117050037066,
          "to_latitude": 21.02054478078175,
          "to_longitude": 105.83259214445285,
          "from_longitude": 105.83252630133204
        }
      },
      {
        "id": 1236,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83252630133204,
            21.021078880237955,
            105.83297690569617,
            21.021221690854585
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83252630133204,
              21.021117050037066
            ],
            [
              105.83254312197374,
              21.021117496996364
            ],
            [
              105.83255645880197,
              21.021117851716173
            ],
            [
              105.83258238924928,
              21.02112608232688
            ],
            [
              105.83260005911262,
              21.02113169066375
            ],
            [
              105.83263913886216,
              21.021146868630154
            ],
            [
              105.83264212416384,
              21.021144497504125
            ],
            [
              105.83272474528047,
              21.021078880237955
            ],
            [
              105.83279190495591,
              21.021116915884022
            ],
            [
              105.83279310349508,
              21.021117594659884
            ],
            [
              105.83279734718144,
              21.021118537622623
            ],
            [
              105.83279861855536,
              21.021118820429574
            ],
            [
              105.83289910650876,
              21.021180710485822
            ],
            [
              105.83290131216174,
              21.02118206897332
            ],
            [
              105.8329074984506,
              21.021183599452
            ],
            [
              105.83291007334047,
              21.02118423626155
            ],
            [
              105.83291032528139,
              21.021184392188875
            ],
            [
              105.83292012791945,
              21.021190132048147
            ],
            [
              105.8329698968364,
              21.02121772118508
            ],
            [
              105.83297690569617,
              21.021221690854585
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngách 221/56, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DOPP.1240",
          "duongKinh": 100,
          "chieudaiQL": 55,
          "chatLieu": "G-DI",
          "from_latitude": 21.021117050037066,
          "to_latitude": 21.021221690854585,
          "to_longitude": 105.83297690569617,
          "from_longitude": 105.83252630133204
        }
      },
      {
        "id": 1237,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83385277352208,
            21.02596515339498,
            105.83439778296007,
            21.02681573057513
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83419215660668,
              21.02596515339498
            ],
            [
              105.8341959503382,
              21.025973032572676
            ],
            [
              105.83419935960609,
              21.025980107960752
            ],
            [
              105.8342033372679,
              21.025988366493127
            ],
            [
              105.83426480858382,
              21.02611599211042
            ],
            [
              105.83429038498751,
              21.026172792301825
            ],
            [
              105.83433642913913,
              21.026289592564986
            ],
            [
              105.8343383855691,
              21.02629455340542
            ],
            [
              105.83433929890518,
              21.026296871112105
            ],
            [
              105.834354834952,
              21.026349874624895
            ],
            [
              105.83436443894819,
              21.026370841902807
            ],
            [
              105.83439566560064,
              21.026455350539234
            ],
            [
              105.83439741068125,
              21.026486115704454
            ],
            [
              105.83439752586114,
              21.02648814287606
            ],
            [
              105.83439778296007,
              21.026492680312604
            ],
            [
              105.83439395237002,
              21.026547663869632
            ],
            [
              105.83438449729051,
              21.02658110875597
            ],
            [
              105.83437704599541,
              21.026588787489498
            ],
            [
              105.83437414295318,
              21.026591778757744
            ],
            [
              105.83433525916921,
              21.026617630113723
            ],
            [
              105.83432832659543,
              21.026620505667413
            ],
            [
              105.83418365329165,
              21.026680508409758
            ],
            [
              105.83415460059811,
              21.026692080310955
            ],
            [
              105.83415350921747,
              21.026692515598757
            ],
            [
              105.83414632163098,
              21.026695377944492
            ],
            [
              105.83414511122095,
              21.026695859880494
            ],
            [
              105.834090247333,
              21.026717712414683
            ],
            [
              105.83393577176611,
              21.026779241605706
            ],
            [
              105.83387095726361,
              21.02680325279746
            ],
            [
              105.83385277352208,
              21.02681573057513
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Hồ Giám",
          "maTaiSan": "04.O14.DOPP.1241",
          "duongKinh": 100,
          "chieudaiQL": 134,
          "chatLieu": "G-DI",
          "from_latitude": 21.02596515339498,
          "to_latitude": 21.02681573057513,
          "to_longitude": 105.83385277352208,
          "from_longitude": 105.83419215660668
        }
      },
      {
        "id": 1238,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83334059288913,
            21.02366272954306,
            105.83366303848193,
            21.024551223140033
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83334059288913,
              21.02366272954306
            ],
            [
              105.83335349733507,
              21.02369480844101
            ],
            [
              105.83335758048395,
              21.023719642447556
            ],
            [
              105.83335937215675,
              21.023726646299252
            ],
            [
              105.83336057282334,
              21.02373133808385
            ],
            [
              105.83337551973446,
              21.02376448271132
            ],
            [
              105.83339331401056,
              21.0237912004947
            ],
            [
              105.83339650887581,
              21.023798313985928
            ],
            [
              105.83340221146155,
              21.023810932080558
            ],
            [
              105.83342223228996,
              21.023865255924765
            ],
            [
              105.83343871431407,
              21.023910988480406
            ],
            [
              105.8334594924908,
              21.023968642406953
            ],
            [
              105.83345997516219,
              21.02396998223231
            ],
            [
              105.83346570459922,
              21.023987601343418
            ],
            [
              105.83346617929084,
              21.023989063143095
            ],
            [
              105.83348910784109,
              21.02405957656403
            ],
            [
              105.83350003382202,
              21.024093955719298
            ],
            [
              105.83352084207718,
              21.0241594332136
            ],
            [
              105.83353164697945,
              21.024190026644938
            ],
            [
              105.83353765100757,
              21.02420702660179
            ],
            [
              105.83353954422982,
              21.02421135641045
            ],
            [
              105.83354026915671,
              21.024213012079766
            ],
            [
              105.83357670898246,
              21.02429633291404
            ],
            [
              105.83360320125784,
              21.024356364095755
            ],
            [
              105.83361217344111,
              21.024381220193373
            ],
            [
              105.83361284174138,
              21.02438307213944
            ],
            [
              105.83361408175531,
              21.024387225407242
            ],
            [
              105.83363767588726,
              21.024476378078628
            ],
            [
              105.83364298074032,
              21.02449531164701
            ],
            [
              105.8336433929477,
              21.024496780978073
            ],
            [
              105.83364408587248,
              21.024499255124482
            ],
            [
              105.83365203875356,
              21.02452764240133
            ],
            [
              105.83366303848193,
              21.024551223140033
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Ngõ 28, Văn Hương",
          "maTaiSan": "04.O14.DOPP.1242",
          "duongKinh": 150,
          "chieudaiQL": 100,
          "chatLieu": "G-DI",
          "from_latitude": 21.02366272954306,
          "to_latitude": 21.024551223140033,
          "to_longitude": 105.83366303848193,
          "from_longitude": 105.83334059288913
        }
      },
      {
        "id": 1239,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83289895872252,
            21.024551223140026,
            105.83366303848193,
            21.024802629934804
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83366303848193,
              21.024551223140026
            ],
            [
              105.83362323110521,
              21.02456020844047
            ],
            [
              105.83352450661482,
              21.02458826774789
            ],
            [
              105.83344294606918,
              21.024613226118632
            ],
            [
              105.83341656687749,
              21.02462129834083
            ],
            [
              105.83338960344618,
              21.024634507346363
            ],
            [
              105.83338306045891,
              21.024637712434153
            ],
            [
              105.83334913730255,
              21.02464855476863
            ],
            [
              105.83332960214985,
              21.02465479873553
            ],
            [
              105.83327862856926,
              21.024665464472825
            ],
            [
              105.83325943408474,
              21.024669481219046
            ],
            [
              105.83316106282751,
              21.02470227328797
            ],
            [
              105.83315879783434,
              21.024703056640487
            ],
            [
              105.83313433898955,
              21.024711517733767
            ],
            [
              105.8331104571912,
              21.024719779094056
            ],
            [
              105.83302522532364,
              21.02475526245623
            ],
            [
              105.83300594727477,
              21.02476328809132
            ],
            [
              105.83292918412265,
              21.024800733276766
            ],
            [
              105.83292533417085,
              21.024802629934804
            ],
            [
              105.83290775970602,
              21.02478293801671
            ],
            [
              105.83289895872252,
              21.024784424554486
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D200_Văn Hương",
          "maTaiSan": "04.O14.DOPP.1243",
          "duongKinh": 200,
          "chieudaiQL": 90,
          "chatLieu": "G-DI",
          "from_latitude": 21.024551223140026,
          "to_latitude": 21.024784424554486,
          "to_longitude": 105.83289895872252,
          "from_longitude": 105.83366303848193
        }
      },
      {
        "id": 1240,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83497345924349,
            21.02395084864144,
            105.83633363713416,
            21.024832316828586
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83633363713416,
              21.023952285956824
            ],
            [
              105.83632230745974,
              21.02395084864144
            ],
            [
              105.83626438435472,
              21.023979000615185
            ],
            [
              105.83613859966312,
              21.02403856522882
            ],
            [
              105.83608158028898,
              21.02405921504103
            ],
            [
              105.83606685093258,
              21.02406381530919
            ],
            [
              105.83589834292738,
              21.0241164452706
            ],
            [
              105.83584965004378,
              21.024139777301013
            ],
            [
              105.8357666489375,
              21.024179547682664
            ],
            [
              105.83571032817524,
              21.024222689624406
            ],
            [
              105.83569196519946,
              21.02423675543362
            ],
            [
              105.83563964651117,
              21.024276832017964
            ],
            [
              105.83549683659513,
              21.02438622485631
            ],
            [
              105.83548739523953,
              21.024392892769548
            ],
            [
              105.8354119304983,
              21.024446192377802
            ],
            [
              105.83543167609963,
              21.024490512303323
            ],
            [
              105.83543313152268,
              21.02449377933914
            ],
            [
              105.8354352815352,
              21.024498605539613
            ],
            [
              105.83548307374666,
              21.024605875134757
            ],
            [
              105.83532214860956,
              21.024681521170404
            ],
            [
              105.83514749886031,
              21.024763617535715
            ],
            [
              105.83497345924349,
              21.024832316828586
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Ngõ 46, Linh Quang",
          "maTaiSan": "04.O14.DOPP.1244",
          "duongKinh": 150,
          "chieudaiQL": 192,
          "chatLieu": "G-DI",
          "from_latitude": 21.023952285956824,
          "to_latitude": 21.024832316828586,
          "to_longitude": 105.83497345924349,
          "from_longitude": 105.83633363713416
        }
      },
      {
        "id": 1241,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83421689319552,
            21.024196304245866,
            105.83497345924349,
            21.024832316828586
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83497345924349,
              21.024832316828586
            ],
            [
              105.83495844634902,
              21.02479777178293
            ],
            [
              105.83493889996635,
              21.02476156899664
            ],
            [
              105.83488775128033,
              21.024666837106725
            ],
            [
              105.83488670636578,
              21.024664903015253
            ],
            [
              105.83488540677493,
              21.024662495981623
            ],
            [
              105.83488242656371,
              21.02465697656074
            ],
            [
              105.8348790137955,
              21.024650654491023
            ],
            [
              105.83478946560058,
              21.024667417120778
            ],
            [
              105.83478031502713,
              21.02466913029034
            ],
            [
              105.83478012053135,
              21.02466875369755
            ],
            [
              105.8347799717409,
              21.02466846539633
            ],
            [
              105.83476889436123,
              21.024647039304917
            ],
            [
              105.8347324414512,
              21.02457653465511
            ],
            [
              105.83470503517282,
              21.024502057475733
            ],
            [
              105.83468978691477,
              21.024504876306864
            ],
            [
              105.8346756966652,
              21.024507480808673
            ],
            [
              105.83462336463222,
              21.024504445386782
            ],
            [
              105.83452515649381,
              21.024487310841828
            ],
            [
              105.83451865190993,
              21.024486175794284
            ],
            [
              105.83450112200691,
              21.02448229123949
            ],
            [
              105.83449020368516,
              21.0244778521785
            ],
            [
              105.83447932400448,
              21.024471886479137
            ],
            [
              105.8344729607818,
              21.024468667902394
            ],
            [
              105.8344687642956,
              21.0244665451431
            ],
            [
              105.83446247825192,
              21.024463365929098
            ],
            [
              105.83444210295724,
              21.024448797547763
            ],
            [
              105.83444012737726,
              21.024446614213673
            ],
            [
              105.83437112694698,
              21.0243384873195
            ],
            [
              105.83436097235212,
              21.024318471119507
            ],
            [
              105.8343601125143,
              21.024316775470773
            ],
            [
              105.83435679963144,
              21.024310246061205
            ],
            [
              105.83430400561663,
              21.024258061176113
            ],
            [
              105.8342474291059,
              21.024213832357713
            ],
            [
              105.83422119161767,
              21.024198941349283
            ],
            [
              105.83421689319552,
              21.024196304245866
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 53, Văn Hương",
          "maTaiSan": "04.O14.DOPP.1245",
          "duongKinh": 100,
          "chieudaiQL": 124,
          "chatLieu": "G-DI",
          "from_latitude": 21.024832316828586,
          "to_latitude": 21.024196304245866,
          "to_longitude": 105.83421689319552,
          "from_longitude": 105.83497345924349
        }
      },
      {
        "id": 1242,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83474469169164,
            21.024832316828586,
            105.83498469903344,
            21.025642538043325
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83497345924349,
              21.024832316828586
            ],
            [
              105.83475966591037,
              21.024916733879387
            ],
            [
              105.83474469169164,
              21.024922646718633
            ],
            [
              105.83486831056196,
              21.025242657179106
            ],
            [
              105.83483592876684,
              21.025306754432485
            ],
            [
              105.83484962434672,
              21.02535436295233
            ],
            [
              105.83487107172084,
              21.02542891902516
            ],
            [
              105.83487961469908,
              21.025427267541914
            ],
            [
              105.83497951629812,
              21.02563192351716
            ],
            [
              105.83498469903344,
              21.025642538043325
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D200_Ngách 64, Ngõ Thông Phong, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DOPP.1246",
          "duongKinh": 200,
          "chieudaiQL": 86,
          "chatLieu": "G-DI",
          "from_latitude": 21.024832316828586,
          "to_latitude": 21.025642538043325,
          "to_longitude": 105.83498469903344,
          "from_longitude": 105.83497345924349
        }
      },
      {
        "id": 1243,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83499469615296,
            21.025637357746064,
            105.83545772013997,
            21.025894548999386
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83499469615296,
              21.025637357746064
            ],
            [
              105.83500802228782,
              21.025655410074975
            ],
            [
              105.83503439560255,
              21.025691862936814
            ],
            [
              105.83511082043434,
              21.02576052644896
            ],
            [
              105.83512513695221,
              21.02577382565872
            ],
            [
              105.83525307089756,
              21.02589266130562
            ],
            [
              105.83525510258193,
              21.025894548999386
            ],
            [
              105.83527337975656,
              21.025888522342743
            ],
            [
              105.835277300409,
              21.025887229541045
            ],
            [
              105.83529427510776,
              21.025882079986843
            ],
            [
              105.8353627771356,
              21.02585389803632
            ],
            [
              105.83539270752763,
              21.025840191822578
            ],
            [
              105.8353990799224,
              21.02583727381746
            ],
            [
              105.83544142021007,
              21.02581788497108
            ],
            [
              105.83544713914849,
              21.02581526643161
            ],
            [
              105.83545229674542,
              21.025812904455414
            ],
            [
              105.83545772013997,
              21.025810421044564
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngách 41, Ngõ Thông Phong, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DOPP.1247",
          "duongKinh": 100,
          "chieudaiQL": 64,
          "chatLieu": "G-DI",
          "from_latitude": 21.025637357746064,
          "to_latitude": 21.025810421044564,
          "to_longitude": 105.83545772013997,
          "from_longitude": 105.83499469615296
        }
      },
      {
        "id": 1244,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83292533417085,
            21.024802629934804,
            105.83364403213231,
            21.026335857873597
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83292533417085,
              21.024802629934804
            ],
            [
              105.83292928142436,
              21.024811667228928
            ],
            [
              105.83296609775584,
              21.024915005065107
            ],
            [
              105.83299975113853,
              21.02497834082416
            ],
            [
              105.83300097996803,
              21.024981348863392
            ],
            [
              105.83311940934368,
              21.025237529997973
            ],
            [
              105.83312830398276,
              21.025256399028525
            ],
            [
              105.83312956074117,
              21.025259065511133
            ],
            [
              105.83313465785538,
              21.02526987832371
            ],
            [
              105.83313849918366,
              21.02527802645521
            ],
            [
              105.83313993895658,
              21.025281081330675
            ],
            [
              105.83315472896226,
              21.02531245551538
            ],
            [
              105.8331808112968,
              21.02536778477433
            ],
            [
              105.83318706295724,
              21.025381045987736
            ],
            [
              105.83323709578748,
              21.025487183459834
            ],
            [
              105.83323892787344,
              21.025491070087643
            ],
            [
              105.83334833818924,
              21.025723165592016
            ],
            [
              105.83336244402102,
              21.02575379704444
            ],
            [
              105.83336615014098,
              21.025761845574362
            ],
            [
              105.83342150119512,
              21.025872657295228
            ],
            [
              105.83351882265632,
              21.026064451357563
            ],
            [
              105.8335203477949,
              21.02606745793981
            ],
            [
              105.83358700992135,
              21.026195150503398
            ],
            [
              105.83359590314231,
              21.02621218596702
            ],
            [
              105.83359779377814,
              21.026217256429312
            ],
            [
              105.83360001454503,
              21.02622322127123
            ],
            [
              105.8336058552525,
              21.026238167185944
            ],
            [
              105.83361432793536,
              21.02625984727936
            ],
            [
              105.83364403213231,
              21.026335857873597
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Tôn Đức Thắng",
          "maTaiSan": "04.O14.DOPP.1248",
          "duongKinh": 150,
          "chieudaiQL": 211,
          "chatLieu": "G-DI",
          "from_latitude": 21.024802629934804,
          "to_latitude": 21.026335857873597,
          "to_longitude": 105.83364403213231,
          "from_longitude": 105.83292533417085
        }
      },
      {
        "id": 1245,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83082853485156,
            21.020294291599615,
            105.83136064795258,
            21.021408759243553
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83082853485156,
              21.020294291599615
            ],
            [
              105.83090153415397,
              21.02044922916184
            ],
            [
              105.83094818921172,
              21.020545331972627
            ],
            [
              105.8310598018757,
              21.020775189367217
            ],
            [
              105.83106763988759,
              21.020791309760632
            ],
            [
              105.83112071026297,
              21.020904369921467
            ],
            [
              105.83113176090664,
              21.02092788737809
            ],
            [
              105.83117931170989,
              21.021029168437654
            ],
            [
              105.83120830440703,
              21.02108987700046
            ],
            [
              105.83121233080529,
              21.021098305183866
            ],
            [
              105.8312167347199,
              21.02110750377933
            ],
            [
              105.83126110213769,
              21.02120039495954
            ],
            [
              105.83132642773704,
              21.02133711472908
            ],
            [
              105.83136064795258,
              21.021408759243553
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Tôn Đức Thắng",
          "maTaiSan": "04.O14.DOPP.1249",
          "duongKinh": 150,
          "chieudaiQL": 136,
          "chatLieu": "G-DI",
          "from_latitude": 21.020294291599615,
          "to_latitude": 21.021408759243553,
          "to_longitude": 105.83136064795258,
          "from_longitude": 105.83082853485156
        }
      },
      {
        "id": 1246,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83136064795258,
            21.021408759243553,
            105.83160729735586,
            21.02194025271495
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83136064795258,
              21.021408759243553
            ],
            [
              105.83139928996465,
              21.02149194973244
            ],
            [
              105.83144984878749,
              21.021600783211202
            ],
            [
              105.83150805351652,
              21.02172627810753
            ],
            [
              105.83151245523383,
              21.021735767542665
            ],
            [
              105.83154991029615,
              21.021816521473134
            ],
            [
              105.83160729735586,
              21.02194025271495
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Tôn Đức Thắng",
          "maTaiSan": "04.O14.DOPP.1250",
          "duongKinh": 150,
          "chieudaiQL": 65,
          "chatLieu": "G-DI",
          "from_latitude": 21.021408759243553,
          "to_latitude": 21.02194025271495,
          "to_longitude": 105.83160729735586,
          "from_longitude": 105.83136064795258
        }
      },
      {
        "id": 1247,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83188499011197,
            21.022560236351314,
            105.83204546626442,
            21.02289365339612
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83188499011197,
              21.022560236351314
            ],
            [
              105.83189465178936,
              21.022581825834013
            ],
            [
              105.83196648457196,
              21.02273034842283
            ],
            [
              105.83197364791017,
              21.0227451596805
            ],
            [
              105.83204546626442,
              21.02289365339612
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Tôn Đức Thắng",
          "maTaiSan": "04.O14.DOPP.1251",
          "duongKinh": 150,
          "chieudaiQL": 40,
          "chatLieu": "G-DI",
          "from_latitude": 21.022560236351314,
          "to_latitude": 21.02289365339612,
          "to_longitude": 105.83204546626442,
          "from_longitude": 105.83188499011197
        }
      },
      {
        "id": 1248,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83204546626442,
            21.02289365339612,
            105.83235336905716,
            21.02356907361992
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83204546626442,
              21.02289365339612
            ],
            [
              105.8320532855215,
              21.022909820793444
            ],
            [
              105.83205990588596,
              21.022924034058846
            ],
            [
              105.8321053429676,
              21.023032490419023
            ],
            [
              105.8321146707961,
              21.023054754421686
            ],
            [
              105.83221718997326,
              21.02326264380286
            ],
            [
              105.83222485963732,
              21.02327819682686
            ],
            [
              105.83224354572185,
              21.023318315125902
            ],
            [
              105.83226929096512,
              21.02337907277709
            ],
            [
              105.83229817204169,
              21.023447234161033
            ],
            [
              105.8323167889815,
              21.02349828360721
            ],
            [
              105.83234701713015,
              21.023555571698722
            ],
            [
              105.83235336905716,
              21.02356907361992
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Tôn Đức Thắng",
          "maTaiSan": "04.O14.DOPP.1252",
          "duongKinh": 150,
          "chieudaiQL": 85,
          "chatLieu": "G-DI",
          "from_latitude": 21.02289365339612,
          "to_latitude": 21.02356907361992,
          "to_longitude": 105.83235336905716,
          "from_longitude": 105.83204546626442
        }
      },
      {
        "id": 1249,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83235336905716,
            21.023569073619925,
            105.83290775970602,
            21.02478293801671
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83235336905716,
              21.023569073619925
            ],
            [
              105.8323626141781,
              21.023588729499075
            ],
            [
              105.8323921427329,
              21.023660524977824
            ],
            [
              105.83239574456088,
              21.023669214430154
            ],
            [
              105.83239679273598,
              21.023671742843764
            ],
            [
              105.83240049987057,
              21.023680686489776
            ],
            [
              105.8324102825825,
              21.02370152386142
            ],
            [
              105.83242327004417,
              21.023729183856716
            ],
            [
              105.83243836223795,
              21.023761329798766
            ],
            [
              105.83244771564495,
              21.023781252496555
            ],
            [
              105.83245170212832,
              21.02378974225859
            ],
            [
              105.83245598838391,
              21.02379920513201
            ],
            [
              105.83247098481358,
              21.023835584950582
            ],
            [
              105.83247742649368,
              21.023851213519492
            ],
            [
              105.8324788248158,
              21.023854604604058
            ],
            [
              105.83252602367381,
              21.023953836604843
            ],
            [
              105.83253000315386,
              21.02396262265641
            ],
            [
              105.8325347695762,
              21.023973147174914
            ],
            [
              105.8325528827398,
              21.024013137268966
            ],
            [
              105.83256683110353,
              21.024048386854815
            ],
            [
              105.83256735504648,
              21.024049711126068
            ],
            [
              105.8325711202132,
              21.024059227127378
            ],
            [
              105.83260111138192,
              21.024115377358655
            ],
            [
              105.83261161391303,
              21.024137115319395
            ],
            [
              105.83264760109932,
              21.024211606860188
            ],
            [
              105.83264795144501,
              21.0242123322403
            ],
            [
              105.83265231715382,
              21.024221893170047
            ],
            [
              105.83273093603584,
              21.02439406738286
            ],
            [
              105.83278728441181,
              21.024532375835445
            ],
            [
              105.83280508641613,
              21.02457607244458
            ],
            [
              105.83281104837101,
              21.024590705284282
            ],
            [
              105.83281539330295,
              21.0246013709535
            ],
            [
              105.83290775970602,
              21.02478293801671
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Tôn Đức Thắng",
          "maTaiSan": "04.O14.DOPP.1253",
          "duongKinh": 150,
          "chieudaiQL": 137,
          "chatLieu": "G-DI",
          "from_latitude": 21.023569073619925,
          "to_latitude": 21.02478293801671,
          "to_longitude": 105.83290775970602,
          "from_longitude": 105.83235336905716
        }
      },
      {
        "id": 1250,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83058904552145,
            21.019104412010822,
            105.83143362341815,
            21.019423756104384
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83058904552145,
              21.019104412010822
            ],
            [
              105.83059301304918,
              21.019105874830316
            ],
            [
              105.83060771530765,
              21.01911119890711
            ],
            [
              105.83066997888626,
              21.019133735620965
            ],
            [
              105.8306776302657,
              21.019136505480116
            ],
            [
              105.83070396381007,
              21.019146037776856
            ],
            [
              105.83071124152606,
              21.019149252039416
            ],
            [
              105.83082900809002,
              21.019201268056307
            ],
            [
              105.83092023899685,
              21.019239384722955
            ],
            [
              105.83094700112522,
              21.019250565982457
            ],
            [
              105.8309767532181,
              21.019262996242958
            ],
            [
              105.83107495531344,
              21.019304025088477
            ],
            [
              105.83115081170496,
              21.019331450302133
            ],
            [
              105.8313034788165,
              21.019386644606275
            ],
            [
              105.83133404194042,
              21.019397694324404
            ],
            [
              105.8313582473029,
              21.019404029566857
            ],
            [
              105.8313582954898,
              21.01940404558938
            ],
            [
              105.83142355729723,
              21.019421125437486
            ],
            [
              105.83143362341815,
              21.019423756104384
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D200_Khâm Thiên",
          "maTaiSan": "04.O14.DOPP.1254",
          "duongKinh": 200,
          "chieudaiQL": 90,
          "chatLieu": "G-DI",
          "from_latitude": 21.019104412010822,
          "to_latitude": 21.019423756104384,
          "to_longitude": 105.83143362341815,
          "from_longitude": 105.83058904552145
        }
      },
      {
        "id": 1251,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83143362341815,
            21.019423756104384,
            105.83216245454628,
            21.01945790536601
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83143362341815,
              21.019423756104384
            ],
            [
              105.83151220245323,
              21.019438547086207
            ],
            [
              105.83160034265286,
              21.019449733486972
            ],
            [
              105.83161498920643,
              21.019451592073693
            ],
            [
              105.83177058593141,
              21.01945790536601
            ],
            [
              105.83177111867339,
              21.019457872051376
            ],
            [
              105.83179196299702,
              21.0194565814821
            ],
            [
              105.83194156495237,
              21.019447318919337
            ],
            [
              105.83197244735996,
              21.01944637845886
            ],
            [
              105.83207613217682,
              21.01944321944042
            ],
            [
              105.83216245454628,
              21.019438847364608
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Khâm Thiên",
          "maTaiSan": "04.O14.DOPP.1255",
          "duongKinh": 150,
          "chieudaiQL": 79,
          "chatLieu": "G-DI",
          "from_latitude": 21.019423756104384,
          "to_latitude": 21.019438847364608,
          "to_longitude": 105.83216245454628,
          "from_longitude": 105.83143362341815
        }
      },
      {
        "id": 1252,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83216245454628,
            21.0193747900734,
            105.83312931386205,
            21.019438847364608
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83216245454628,
              21.019438847364608
            ],
            [
              105.83217748520212,
              21.019438501962068
            ],
            [
              105.83234138581707,
              21.019425100973148
            ],
            [
              105.83236443453151,
              21.019423216064503
            ],
            [
              105.83263430514405,
              21.019405379840048
            ],
            [
              105.83275103573472,
              21.019397664818182
            ],
            [
              105.832934249221,
              21.019385767419898
            ],
            [
              105.83295670196333,
              21.01938431167154
            ],
            [
              105.83310329040307,
              21.0193747900734
            ],
            [
              105.83312490158627,
              21.019375441131057
            ],
            [
              105.83312495160187,
              21.019375439982863
            ],
            [
              105.83312931386205,
              21.019375571262557
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Khâm Thiên",
          "maTaiSan": "04.O14.DOPP.1256",
          "duongKinh": 150,
          "chieudaiQL": 100,
          "chatLieu": "G-DI",
          "from_latitude": 21.019438847364608,
          "to_latitude": 21.019375571262557,
          "to_longitude": 105.83312931386205,
          "from_longitude": 105.83216245454628
        }
      },
      {
        "id": 1253,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83312931386205,
            21.019298608514745,
            105.83435052492375,
            21.019375610556914
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83312931386205,
              21.019375571262557
            ],
            [
              105.83322548035144,
              21.019375610556914
            ],
            [
              105.83331767401283,
              21.019365275855964
            ],
            [
              105.83341491478936,
              21.019354371733158
            ],
            [
              105.83352758921424,
              21.01934813476071
            ],
            [
              105.83353114833476,
              21.019347937578996
            ],
            [
              105.83359963809905,
              21.019344146220977
            ],
            [
              105.83373663946249,
              21.019336513638144
            ],
            [
              105.83381832981874,
              21.01933196200875
            ],
            [
              105.83397864496034,
              21.019323762435114
            ],
            [
              105.8340091025478,
              21.019322204979993
            ],
            [
              105.83416862504711,
              21.019318508977427
            ],
            [
              105.83416868467194,
              21.01931850597535
            ],
            [
              105.83417487697587,
              21.019318362706027
            ],
            [
              105.83417763554641,
              21.019317957178927
            ],
            [
              105.83425996768096,
              21.019305829558665
            ],
            [
              105.83434357405996,
              21.019299001188095
            ],
            [
              105.83435052492375,
              21.019298608514745
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Khâm Thiên",
          "maTaiSan": "04.O14.DOPP.1257",
          "duongKinh": 150,
          "chieudaiQL": 127,
          "chatLieu": "G-DI",
          "from_latitude": 21.019375571262557,
          "to_latitude": 21.019298608514745,
          "to_longitude": 105.83435052492375,
          "from_longitude": 105.83312931386205
        }
      },
      {
        "id": 1254,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83435052492375,
            21.01923060960626,
            105.83534481099471,
            21.019298608514745
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83435052492375,
              21.019298608514745
            ],
            [
              105.83436157171502,
              21.01929716065571
            ],
            [
              105.83436979431177,
              21.019295042908016
            ],
            [
              105.83457742913524,
              21.019281137121872
            ],
            [
              105.83462283307959,
              21.01927809629299
            ],
            [
              105.83485105538479,
              21.01926240374368
            ],
            [
              105.83497373044769,
              21.019253967964413
            ],
            [
              105.83501019015914,
              21.01925146138651
            ],
            [
              105.83514984097856,
              21.019246321110796
            ],
            [
              105.83518399028239,
              21.019245064159197
            ],
            [
              105.83534481099471,
              21.01923060960626
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Khâm Thiên",
          "maTaiSan": "04.O14.DOPP.1258",
          "duongKinh": 150,
          "chieudaiQL": 101,
          "chatLieu": "G-DI",
          "from_latitude": 21.019298608514745,
          "to_latitude": 21.01923060960626,
          "to_longitude": 105.83534481099471,
          "from_longitude": 105.83435052492375
        }
      },
      {
        "id": 1255,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83534481099471,
            21.019170705523667,
            105.83629527097864,
            21.01923060960626
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83534481099471,
              21.01923060960626
            ],
            [
              105.83556347598687,
              21.019221983798808
            ],
            [
              105.83556357984047,
              21.01922197696611
            ],
            [
              105.83558282153348,
              21.019221217676122
            ],
            [
              105.83563820846348,
              21.019216417735876
            ],
            [
              105.83565387025534,
              21.019215060025243
            ],
            [
              105.8356550778847,
              21.019214953834812
            ],
            [
              105.83581190154287,
              21.01920135847641
            ],
            [
              105.83584594999341,
              21.01919840653643
            ],
            [
              105.83602823666212,
              21.01918843333636
            ],
            [
              105.83606863720875,
              21.01918622329251
            ],
            [
              105.8362301587233,
              21.019174994390774
            ],
            [
              105.8362550763104,
              21.019173262350247
            ],
            [
              105.83629527097864,
              21.019170705523667
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Khâm Thiên",
          "maTaiSan": "04.O14.DOPP.1259",
          "duongKinh": 150,
          "chieudaiQL": 101,
          "chatLieu": "G-DI",
          "from_latitude": 21.01923060960626,
          "to_latitude": 21.019170705523667,
          "to_longitude": 105.83629527097864,
          "from_longitude": 105.83534481099471
        }
      },
      {
        "id": 1256,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83629527097864,
            21.019123434189567,
            105.83706305359897,
            21.019170705523667
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83629527097864,
              21.019170705523667
            ],
            [
              105.83630984324597,
              21.019170279817132
            ],
            [
              105.83656678343864,
              21.01915458378297
            ],
            [
              105.83661916984528,
              21.019151144084674
            ],
            [
              105.83668610920597,
              21.019146749437446
            ],
            [
              105.83671913750617,
              21.019144580920155
            ],
            [
              105.83687363531854,
              21.019133637957474
            ],
            [
              105.83687472190385,
              21.019133561257085
            ],
            [
              105.83687951476814,
              21.019133285677956
            ],
            [
              105.83695815079696,
              21.01912876021774
            ],
            [
              105.83704635716074,
              21.01912368453274
            ],
            [
              105.83704950810173,
              21.019123434189567
            ],
            [
              105.8370496866585,
              21.019128021687813
            ],
            [
              105.83706305359897,
              21.019127254106863
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Khâm Thiên",
          "maTaiSan": "04.O14.DOPP.1260",
          "duongKinh": 150,
          "chieudaiQL": 90.48,
          "chatLieu": "G-DI",
          "from_latitude": 21.019170705523667,
          "to_latitude": 21.019127254106863,
          "to_longitude": 105.83706305359897,
          "from_longitude": 105.83629527097864
        }
      },
      {
        "id": 1257,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83057954119646,
            21.019068391855743,
            105.8307030787056,
            21.019154716565733
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83058904552145,
              21.019104412010822
            ],
            [
              105.83057954119646,
              21.019125919945015
            ],
            [
              105.83059923447317,
              21.01913322119906
            ],
            [
              105.83065722984726,
              21.019154716565733
            ],
            [
              105.83066497040147,
              21.019141977618634
            ],
            [
              105.8306744568164,
              21.019140857359673
            ],
            [
              105.8306776302657,
              21.019136505480116
            ],
            [
              105.83068014018878,
              21.01913306277528
            ],
            [
              105.83067605023106,
              21.019123737983076
            ],
            [
              105.83069869951888,
              21.01907735968371
            ],
            [
              105.8306992645135,
              21.0190762026032
            ],
            [
              105.8307030787056,
              21.019068391855743
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D200_Khâm Thiên",
          "maTaiSan": "04.O14.DOPP.1261",
          "duongKinh": 200,
          "chieudaiQL": 19.02,
          "chatLieu": "G-DI",
          "from_latitude": 21.019104412010822,
          "to_latitude": 21.019068391855743,
          "to_longitude": 105.8307030787056,
          "from_longitude": 105.83058904552145
        }
      },
      {
        "id": 1258,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83463480628367,
            21.021196953554202,
            105.83498463584402,
            21.021246644263165
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83463480628367,
              21.021246644263165
            ],
            [
              105.83490936134523,
              21.021196953554202
            ],
            [
              105.83498463584402,
              21.021216159619755
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Ngõ 273, Văn Chương",
          "maTaiSan": "04.O14.DOPP.1262",
          "duongKinh": 150,
          "chieudaiQL": 39,
          "chatLieu": "G-DI",
          "from_latitude": 21.021246644263165,
          "to_latitude": 21.021216159619755,
          "to_longitude": 105.83498463584402,
          "from_longitude": 105.83463480628367
        }
      },
      {
        "id": 1259,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83431778744503,
            21.021246644263165,
            105.83500829989897,
            21.02189899938521
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83500829989897,
              21.02188996860555
            ],
            [
              105.83500360003309,
              21.021889686388793
            ],
            [
              105.83497111216347,
              21.021888996846496
            ],
            [
              105.83490073598739,
              21.021895928621646
            ],
            [
              105.83489509612778,
              21.021896484691617
            ],
            [
              105.83488934377526,
              21.021897051249063
            ],
            [
              105.83486121391502,
              21.02189899938521
            ],
            [
              105.83483902351088,
              21.021894247152616
            ],
            [
              105.83482074114292,
              21.021876968794544
            ],
            [
              105.83479760594042,
              21.021833658952417
            ],
            [
              105.83476334173008,
              21.021753557609753
            ],
            [
              105.83470933326524,
              21.021618893654413
            ],
            [
              105.83470623211187,
              21.021611162824694
            ],
            [
              105.83462461692041,
              21.021625804460008
            ],
            [
              105.83462183862602,
              21.02162630312427
            ],
            [
              105.83461806183708,
              21.021626981010275
            ],
            [
              105.83461525470929,
              21.021627484332036
            ],
            [
              105.83450794956443,
              21.021664527278144
            ],
            [
              105.83444366604141,
              21.021681381594426
            ],
            [
              105.83441433299052,
              21.021689071933967
            ],
            [
              105.83440352088054,
              21.021649918731566
            ],
            [
              105.83438469896112,
              21.021587526028856
            ],
            [
              105.83436558794858,
              21.02152227423085
            ],
            [
              105.83436415038764,
              21.021517366840882
            ],
            [
              105.83431778744503,
              21.021415255369796
            ],
            [
              105.83432583980273,
              21.02141225781305
            ],
            [
              105.83432999078586,
              21.021410713712736
            ],
            [
              105.83433187427737,
              21.02141001260421
            ],
            [
              105.83434791755724,
              21.0214040412742
            ],
            [
              105.83441681668018,
              21.021378398564863
            ],
            [
              105.8344493476964,
              21.02136629022365
            ],
            [
              105.83449579717431,
              21.021328540609336
            ],
            [
              105.83449862645331,
              21.021325622863237
            ],
            [
              105.83452367204443,
              21.02129116392271
            ],
            [
              105.83454711165456,
              21.021272528310707
            ],
            [
              105.83463480628367,
              21.021246644263165
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Văn Chương",
          "maTaiSan": "04.O14.DOPP.1263",
          "duongKinh": 150,
          "chieudaiQL": 156.83,
          "chatLieu": "G-DI",
          "from_latitude": 21.02188996860555,
          "to_latitude": 21.021246644263165,
          "to_longitude": 105.83463480628367,
          "from_longitude": 105.83500829989897
        }
      },
      {
        "id": 1260,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83421014935483,
            21.02257583904192,
            105.83446897937812,
            21.023266975181173
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83421014935483,
              21.02257583904192
            ],
            [
              105.83421089936374,
              21.022577334715038
            ],
            [
              105.83421947392466,
              21.022587266057542
            ],
            [
              105.83422632763866,
              21.02259929680337
            ],
            [
              105.83424330114472,
              21.022629090391266
            ],
            [
              105.83428772586012,
              21.02272667266652
            ],
            [
              105.83429456545356,
              21.022741695859107
            ],
            [
              105.83430701875565,
              21.022771684127775
            ],
            [
              105.83433468256835,
              21.022855026182956
            ],
            [
              105.83433942089003,
              21.022869301876188
            ],
            [
              105.83435440838956,
              21.022901075124242
            ],
            [
              105.83436116973131,
              21.02291880204635
            ],
            [
              105.83437574595587,
              21.022949272151155
            ],
            [
              105.83438270173403,
              21.022968747662908
            ],
            [
              105.83438329490053,
              21.022970408491286
            ],
            [
              105.8344013025513,
              21.023004685106017
            ],
            [
              105.83441136293746,
              21.023029915179254
            ],
            [
              105.83441575802561,
              21.02304093914131
            ],
            [
              105.8344245606715,
              21.02306505448319
            ],
            [
              105.83442547599985,
              21.023067560954818
            ],
            [
              105.83446897937812,
              21.023151313011585
            ],
            [
              105.83446680623301,
              21.023184668890824
            ],
            [
              105.83446624166173,
              21.02319333177079
            ],
            [
              105.83446327379245,
              21.023227229457596
            ],
            [
              105.83442840507384,
              21.023248823592418
            ],
            [
              105.83442304065836,
              21.023252579093665
            ],
            [
              105.83441465874795,
              21.02325789938665
            ],
            [
              105.83439990660536,
              21.023266975181173
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Văn Chương",
          "maTaiSan": "04.O14.DOPP.1264",
          "duongKinh": 100,
          "chieudaiQL": 84,
          "chatLieu": "G-DI",
          "from_latitude": 21.02257583904192,
          "to_latitude": 21.023266975181173,
          "to_longitude": 105.83439990660536,
          "from_longitude": 105.83421014935483
        }
      },
      {
        "id": 1261,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83218731891458,
            21.02349714147267,
            105.83235336905716,
            21.023608886703045
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83235336905716,
              21.02356907361992
            ],
            [
              105.83231187529158,
              21.023582356273806
            ],
            [
              105.8322984035024,
              21.02358666916076
            ],
            [
              105.83222899528656,
              21.023608886703045
            ],
            [
              105.83221654223276,
              21.02358165581741
            ],
            [
              105.83220701517972,
              21.02356007744179
            ],
            [
              105.83218731891458,
              21.02351440022232
            ],
            [
              105.8322070623064,
              21.023507734467294
            ],
            [
              105.8322384383767,
              21.02349714147267
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Tôn Đức Thắng",
          "maTaiSan": "04.O14.DOPP.1265",
          "duongKinh": 150,
          "chieudaiQL": 67.46,
          "chatLieu": "G-DI",
          "from_latitude": 21.02356907361992,
          "to_latitude": 21.02349714147267,
          "to_longitude": 105.8322384383767,
          "from_longitude": 105.83235336905716
        }
      },
      {
        "id": 1262,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83275067348136,
            21.02461097180502,
            105.83289895872252,
            21.02478872368997
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83289895872252,
              21.024784424554486
            ],
            [
              105.83288019222326,
              21.02478872368997
            ],
            [
              105.83287162524232,
              21.024772545549684
            ],
            [
              105.83278910241295,
              21.02461097180502
            ],
            [
              105.83278713598412,
              21.0246116444005
            ],
            [
              105.83277795002526,
              21.024614784730016
            ],
            [
              105.83275067348136,
              21.024624110402694
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Tôn Đức Thắng",
          "maTaiSan": "04.O14.DOPP.1266",
          "duongKinh": 150,
          "chieudaiQL": 36.35,
          "chatLieu": "G-DI",
          "from_latitude": 21.024784424554486,
          "to_latitude": 21.024624110402694,
          "to_longitude": 105.83275067348136,
          "from_longitude": 105.83289895872252
        }
      },
      {
        "id": 1263,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83219255182881,
            21.0213896664707,
            105.83233105287637,
            21.02156460695359
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83221016531499,
              21.0213896664707
            ],
            [
              105.83221954908409,
              21.021393947906198
            ],
            [
              105.83230521433966,
              21.021433033970045
            ],
            [
              105.83231101241635,
              21.021435680045585
            ],
            [
              105.83231288241659,
              21.021436532571077
            ],
            [
              105.83232365206045,
              21.021441446701914
            ],
            [
              105.83233105287637,
              21.021444802094
            ],
            [
              105.83233025675673,
              21.021445566504685
            ],
            [
              105.832283567916,
              21.021490387155097
            ],
            [
              105.83224729349855,
              21.02151980202169
            ],
            [
              105.83221549901631,
              21.021539862847234
            ],
            [
              105.83220950189713,
              21.02154364599031
            ],
            [
              105.83219255182881,
              21.02156460695359
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 203, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DOPP.1267",
          "duongKinh": 100,
          "chieudaiQL": 36,
          "chatLieu": "G-DI",
          "from_latitude": 21.0213896664707,
          "to_latitude": 21.02156460695359,
          "to_longitude": 105.83219255182881,
          "from_longitude": 105.83221016531499
        }
      },
      {
        "id": 1264,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83160729735586,
            21.02156460695359,
            105.83219255182881,
            21.02194025271495
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83219255182881,
              21.02156460695359
            ],
            [
              105.83218765414875,
              21.021567679298492
            ],
            [
              105.8321425923946,
              21.02159594307399
            ],
            [
              105.8320939470837,
              21.021639776086563
            ],
            [
              105.83205412129648,
              21.02167566272864
            ],
            [
              105.83205180489517,
              21.021677749669802
            ],
            [
              105.83204923292624,
              21.021680067280137
            ],
            [
              105.8320437089834,
              21.021685044875195
            ],
            [
              105.83200475133583,
              21.021720147776776
            ],
            [
              105.83195277881917,
              21.02176040309607
            ],
            [
              105.83194912457854,
              21.02176323270754
            ],
            [
              105.83191901389266,
              21.021787879043067
            ],
            [
              105.83190200354817,
              21.02180180234902
            ],
            [
              105.83187128199245,
              21.02181812574164
            ],
            [
              105.83184955304392,
              21.02182873651258
            ],
            [
              105.8318431060261,
              21.02183188507031
            ],
            [
              105.83178066096983,
              21.02186201492673
            ],
            [
              105.83164168353028,
              21.021924520223912
            ],
            [
              105.83160729735586,
              21.02194025271495
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 203, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DOPP.1268",
          "duongKinh": 100,
          "chieudaiQL": 74,
          "chatLieu": "G-DI",
          "from_latitude": 21.02156460695359,
          "to_latitude": 21.02194025271495,
          "to_longitude": 105.83160729735586,
          "from_longitude": 105.83219255182881
        }
      },
      {
        "id": 1265,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83177078105446,
            21.02156460695359,
            105.83244138373863,
            21.022305021646947
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83219255182881,
              21.02156460695359
            ],
            [
              105.83227951361182,
              21.021659964786693
            ],
            [
              105.83235678425213,
              21.021744696594713
            ],
            [
              105.83237352233559,
              21.021756079309707
            ],
            [
              105.8323895358386,
              21.021776261167403
            ],
            [
              105.83239081943778,
              21.021777878882965
            ],
            [
              105.8323916210117,
              21.021778789926394
            ],
            [
              105.83239443135791,
              21.02178198352246
            ],
            [
              105.83242313901464,
              21.021835245262082
            ],
            [
              105.8324385621394,
              21.021861885347995
            ],
            [
              105.83244000256185,
              21.02186437300385
            ],
            [
              105.83244138373863,
              21.02186675888529
            ],
            [
              105.83235272462763,
              21.02192421784343
            ],
            [
              105.83234564300956,
              21.02192863313633
            ],
            [
              105.83231949651577,
              21.021944933317087
            ],
            [
              105.8323099439351,
              21.0219513197937
            ],
            [
              105.83229307476749,
              21.021962597806354
            ],
            [
              105.83221704990356,
              21.02201342391521
            ],
            [
              105.83211679451618,
              21.02208276902249
            ],
            [
              105.83210946448087,
              21.022087839455647
            ],
            [
              105.83205923627332,
              21.02212936885352
            ],
            [
              105.83205828552778,
              21.02214162122661
            ],
            [
              105.83205684559178,
              21.022142687752893
            ],
            [
              105.83202051661033,
              21.02216958645855
            ],
            [
              105.83200725965277,
              21.02217965542084
            ],
            [
              105.83195184077186,
              21.02222174579139
            ],
            [
              105.83187744833565,
              21.022255961602152
            ],
            [
              105.8317996196164,
              21.022293462052108
            ],
            [
              105.83178237584991,
              21.022303123269744
            ],
            [
              105.83177078105446,
              21.022305021646947
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 181, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DOPP.1269",
          "duongKinh": 100,
          "chieudaiQL": 126,
          "chatLieu": "G-DI",
          "from_latitude": 21.02156460695359,
          "to_latitude": 21.022305021646947,
          "to_longitude": 105.83177078105446,
          "from_longitude": 105.83219255182881
        }
      },
      {
        "id": 1266,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83483292422456,
            21.02334395221586,
            105.83506967949432,
            21.02376140249309
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83506967949432,
              21.02376140249309
            ],
            [
              105.83506193175818,
              21.023746224828546
            ],
            [
              105.83502580063274,
              21.023675443168877
            ],
            [
              105.83503035615009,
              21.023608687927688
            ],
            [
              105.83506547155015,
              21.023568955222913
            ],
            [
              105.8350661337432,
              21.023554336006036
            ],
            [
              105.8350572327564,
              21.023525196551965
            ],
            [
              105.83505589328799,
              21.023520809848595
            ],
            [
              105.83503982776806,
              21.02347549541457
            ],
            [
              105.83503772804231,
              21.023470711412383
            ],
            [
              105.83502664398965,
              21.023457749475337
            ],
            [
              105.83497802386997,
              21.02345492354001
            ],
            [
              105.83491718823639,
              21.023457070191416
            ],
            [
              105.83489150150277,
              21.02345797667209
            ],
            [
              105.83485432862294,
              21.023455769215758
            ],
            [
              105.83483717345402,
              21.02336280873447
            ],
            [
              105.83483621923229,
              21.0233576379463
            ],
            [
              105.83483292422456,
              21.02334395221586
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Văn Hương",
          "maTaiSan": "04.O14.DOPP.1270",
          "duongKinh": 100,
          "chieudaiQL": 89,
          "chatLieu": "G-DI",
          "from_latitude": 21.02376140249309,
          "to_latitude": 21.02334395221586,
          "to_longitude": 105.83483292422456,
          "from_longitude": 105.83506967949432
        }
      },
      {
        "id": 1267,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83461239157877,
            21.025099159867285,
            105.83473418851322,
            21.025759898889284
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83473418851322,
              21.025759898889284
            ],
            [
              105.83472694871723,
              21.025745426848975
            ],
            [
              105.83468102925319,
              21.025653634529274
            ],
            [
              105.83466429968138,
              21.025623502004823
            ],
            [
              105.83465830142052,
              21.025606708002435
            ],
            [
              105.83464932520202,
              21.025567176414263
            ],
            [
              105.83463227772768,
              21.02546110421641
            ],
            [
              105.8346313957938,
              21.025455614235828
            ],
            [
              105.83462099810862,
              21.025416664071365
            ],
            [
              105.83461265561178,
              21.02538313399997
            ],
            [
              105.83461239157877,
              21.02537043958527
            ],
            [
              105.83462573880962,
              21.0253539327031
            ],
            [
              105.83466630118068,
              21.025324674219068
            ],
            [
              105.83467053031282,
              21.025316240838492
            ],
            [
              105.8346775224928,
              21.025292117548656
            ],
            [
              105.83467800774652,
              21.025269555352047
            ],
            [
              105.8346756837427,
              21.025207211815772
            ],
            [
              105.83467937872886,
              21.025162278178925
            ],
            [
              105.83465865211876,
              21.025109164679325
            ],
            [
              105.8346547474671,
              21.025099159867285
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_NGách 4, Ngõ Thông Phong, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DOPP.1271",
          "duongKinh": 100,
          "chieudaiQL": 93,
          "chatLieu": "G-DI",
          "from_latitude": 21.025759898889284,
          "to_latitude": 21.025099159867285,
          "to_longitude": 105.8346547474671,
          "from_longitude": 105.83473418851322
        }
      },
      {
        "id": 1268,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83339331401056,
            21.023504585327682,
            105.83402708463359,
            21.0237912004947
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83339331401056,
              21.0237912004947
            ],
            [
              105.83340288577435,
              21.023786868697016
            ],
            [
              105.83349233638289,
              21.02374638410821
            ],
            [
              105.83362431789982,
              21.023685742410795
            ],
            [
              105.8336258713897,
              21.02368502847527
            ],
            [
              105.83362618803879,
              21.023684883310615
            ],
            [
              105.8336280162777,
              21.023684447124594
            ],
            [
              105.83362864362553,
              21.023684297727357
            ],
            [
              105.8338058395798,
              21.02360119508862
            ],
            [
              105.83381558010414,
              21.02359694192465
            ],
            [
              105.83382233129532,
              21.023593994122688
            ],
            [
              105.8338346245085,
              21.023588626509586
            ],
            [
              105.83393056718218,
              21.02354673154762
            ],
            [
              105.83398464383286,
              21.023523118050246
            ],
            [
              105.83398656314318,
              21.02352227947924
            ],
            [
              105.83399158406637,
              21.02352008712135
            ],
            [
              105.83402000428693,
              21.023507676557823
            ],
            [
              105.83402708463359,
              21.023504585327682
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 28, Văn Hương",
          "maTaiSan": "04.O14.DOPP.1272",
          "duongKinh": 100,
          "chieudaiQL": 74,
          "chatLieu": "G-DI",
          "from_latitude": 21.0237912004947,
          "to_latitude": 21.023504585327682,
          "to_longitude": 105.83402708463359,
          "from_longitude": 105.83339331401056
        }
      },
      {
        "id": 1269,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83681960348581,
            21.021446598156516,
            105.83688278852351,
            21.021488778534096
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83681960348581,
              21.021446598156516
            ],
            [
              105.83682896649914,
              21.02145403258943
            ],
            [
              105.83683773624657,
              21.021460998457936
            ],
            [
              105.83683814974435,
              21.021461316164686
            ],
            [
              105.83687175174065,
              21.021487216970243
            ],
            [
              105.83688278852351,
              21.021488778534096
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 273, Văn Chương",
          "maTaiSan": "04.O14.DOPP.1273",
          "duongKinh": 100,
          "chieudaiQL": 10.36,
          "chatLieu": "G-CI",
          "from_latitude": 21.021446598156516,
          "to_latitude": 21.021488778534096,
          "to_longitude": 105.83688278852351,
          "from_longitude": 105.83681960348581
        }
      },
      {
        "id": 1270,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83698060983414,
            21.021048391369046,
            105.83743880548101,
            21.021216153229336
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83698060983414,
              21.021216153229336
            ],
            [
              105.83698259206355,
              21.021048391369046
            ],
            [
              105.83728076458054,
              21.021049254197393
            ],
            [
              105.83728358608474,
              21.021049261985947
            ],
            [
              105.83743880548101,
              21.021049710876508
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D80_Văn Chương 2",
          "maTaiSan": "04.O14.DOPP.1274",
          "duongKinh": 80,
          "chieudaiQL": 82,
          "chatLieu": "G-DI",
          "from_latitude": 21.021216153229336,
          "to_latitude": 21.021049710876508,
          "to_longitude": 105.83743880548101,
          "from_longitude": 105.83698060983414
        }
      },
      {
        "id": 1271,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83507828991344,
            21.02142815797853,
            105.83625530406313,
            21.02193613517409
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83625530406313,
              21.02193613517409
            ],
            [
              105.83625047899643,
              21.02193151542432
            ],
            [
              105.83615448354482,
              21.02183960565166
            ],
            [
              105.83595546274577,
              21.021729958890734
            ],
            [
              105.83574190758695,
              21.02154761869322
            ],
            [
              105.8356974985543,
              21.021520611921474
            ],
            [
              105.8356775527132,
              21.02150848117271
            ],
            [
              105.83566363097817,
              21.021500015010087
            ],
            [
              105.83565424107209,
              21.0214943048978
            ],
            [
              105.83546489789501,
              21.021492969751282
            ],
            [
              105.83541179389069,
              21.02143717199683
            ],
            [
              105.83532664446963,
              21.021434870585818
            ],
            [
              105.83524874557332,
              21.021432764753055
            ],
            [
              105.83507828991344,
              21.02142815797853
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Văn Chương",
          "maTaiSan": "04.O14.DOPP.1275",
          "duongKinh": 100,
          "chieudaiQL": 134,
          "chatLieu": "G-DI",
          "from_latitude": 21.02193613517409,
          "to_latitude": 21.02142815797853,
          "to_longitude": 105.83507828991344,
          "from_longitude": 105.83625530406313
        }
      },
      {
        "id": 1272,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83684312748582,
            21.021214461317307,
            105.83746233575562,
            21.021216636400496
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83684312748582,
              21.021216636400496
            ],
            [
              105.8369560434754,
              21.021216239790018
            ],
            [
              105.83695960847109,
              21.021216226764164
            ],
            [
              105.83698060983414,
              21.021216153229336
            ],
            [
              105.83716783477595,
              21.021215495579987
            ],
            [
              105.837351054137,
              21.021214852024464
            ],
            [
              105.83735512801152,
              21.02121483829159
            ],
            [
              105.83746233575562,
              21.021214461317307
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D80_Ngõ 273, Văn Chương",
          "maTaiSan": "04.O14.DOPP.1276",
          "duongKinh": 80,
          "chieudaiQL": 51,
          "chatLieu": "G-DI",
          "from_latitude": 21.021216636400496,
          "to_latitude": 21.021214461317307,
          "to_longitude": 105.83746233575562,
          "from_longitude": 105.83684312748582
        }
      },
      {
        "id": 1273,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83900976483821,
            21.024857792595142,
            105.83983808815972,
            21.025081192957852
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83983808815972,
              21.025081192957852
            ],
            [
              105.83983589827143,
              21.02508112428854
            ],
            [
              105.83974213012164,
              21.025078125263242
            ],
            [
              105.83965074780824,
              21.02507857645956
            ],
            [
              105.83963282246421,
              21.025078664959423
            ],
            [
              105.83963304662407,
              21.025063723645474
            ],
            [
              105.83963319307567,
              21.025053973536224
            ],
            [
              105.83963613186216,
              21.024857792595142
            ],
            [
              105.83941724290514,
              21.024863549106822
            ],
            [
              105.83941367154628,
              21.024863643508144
            ],
            [
              105.83938740910096,
              21.024864334032433
            ],
            [
              105.8393159582768,
              21.0248711058746
            ],
            [
              105.83928146694421,
              21.024874377759208
            ],
            [
              105.8392735875611,
              21.02487512206063
            ],
            [
              105.83903264187867,
              21.024880870409547
            ],
            [
              105.83900976483821,
              21.024881415916028
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D90_Ngõ 70, Trần Quý Cáp",
          "maTaiSan": "04.O14.DOPP.1277",
          "duongKinh": 90,
          "chieudaiQL": 108,
          "chatLieu": "HDPE",
          "from_latitude": 21.025081192957852,
          "to_latitude": 21.024881415916028,
          "to_longitude": 105.83900976483821,
          "from_longitude": 105.83983808815972
        }
      },
      {
        "id": 1274,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83986549047827,
            21.02628676419927,
            105.84000756292446,
            21.026289539708316
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83986549047827,
              21.026289539708316
            ],
            [
              105.84000756292446,
              21.02628676419927
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D200_Trần Quý Cáp",
          "maTaiSan": "04.O14.DOPP.1278",
          "duongKinh": 200,
          "chieudaiQL": 14.77,
          "chatLieu": "G-DI",
          "from_latitude": 21.026289539708316,
          "to_latitude": 21.02628676419927,
          "to_longitude": 105.84000756292446,
          "from_longitude": 105.83986549047827
        }
      },
      {
        "id": 1275,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8396932035334,
            21.029177536965843,
            105.84016798396704,
            21.029265431801985
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8396932035334,
              21.029265431801985
            ],
            [
              105.83972890552128,
              21.02925604894863
            ],
            [
              105.83988446727501,
              21.029223895477223
            ],
            [
              105.83994105728019,
              21.029212216381698
            ],
            [
              105.84016798396704,
              21.029177536965843
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Nguyễn Thái Học",
          "maTaiSan": "04.O14.DOPP.1279",
          "duongKinh": 100,
          "chieudaiQL": 53,
          "chatLieu": "G-DI",
          "from_latitude": 21.029265431801985,
          "to_latitude": 21.029177536965843,
          "to_longitude": 105.84016798396704,
          "from_longitude": 105.8396932035334
        }
      },
      {
        "id": 1276,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83843164741144,
            21.029265431801985,
            105.8396932035334,
            21.029658388854163
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83843164741144,
              21.029658388854163
            ],
            [
              105.83847120534617,
              21.029647624284447
            ],
            [
              105.8387162094838,
              21.02958095491754
            ],
            [
              105.83878975880305,
              21.02956094070585
            ],
            [
              105.83891796985303,
              21.029526052582515
            ],
            [
              105.83914296847735,
              21.029452030629997
            ],
            [
              105.83926638052543,
              21.029411429566476
            ],
            [
              105.83930099126222,
              21.029401291674315
            ],
            [
              105.83936446540464,
              21.029382700749764
            ],
            [
              105.83950372753472,
              21.029341910178832
            ],
            [
              105.83961867721447,
              21.029287016506128
            ],
            [
              105.83962745624663,
              21.029282824656768
            ],
            [
              105.83964233774321,
              21.029276462939837
            ],
            [
              105.8396449753023,
              21.029275892627545
            ],
            [
              105.8396932035334,
              21.029265431801985
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Nguyễn Thái Học",
          "maTaiSan": "04.O14.DOPP.1280",
          "duongKinh": 150,
          "chieudaiQL": 144,
          "chatLieu": "G-DI",
          "from_latitude": 21.029658388854163,
          "to_latitude": 21.029265431801985,
          "to_longitude": 105.8396932035334,
          "from_longitude": 105.83843164741144
        }
      },
      {
        "id": 1277,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8368331133457,
            21.029617424478385,
            105.83705775500349,
            21.030048237138637
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8368331133457,
              21.029617424478385
            ],
            [
              105.8368531701596,
              21.02966369090089
            ],
            [
              105.83687902390703,
              21.029723351582327
            ],
            [
              105.83693351936701,
              21.029849037766486
            ],
            [
              105.83700881751616,
              21.03002273258615
            ],
            [
              105.83705775500349,
              21.030048237138637
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Văn Miếu",
          "maTaiSan": "04.O14.DOPP.1281",
          "duongKinh": 150,
          "chieudaiQL": 54,
          "chatLieu": "G-DI",
          "from_latitude": 21.029617424478385,
          "to_latitude": 21.030048237138637,
          "to_longitude": 105.83705775500349,
          "from_longitude": 105.8368331133457
        }
      },
      {
        "id": 1278,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83650679784101,
            21.028761883381545,
            105.8368331133457,
            21.029617424478385
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83650679784101,
              21.028761883381545
            ],
            [
              105.83651154431107,
              21.02877254606171
            ],
            [
              105.83652181177206,
              21.028795611734672
            ],
            [
              105.83662282623703,
              21.029070069349448
            ],
            [
              105.83666806649421,
              21.02919298583239
            ],
            [
              105.83667911648497,
              21.02922300787784
            ],
            [
              105.83676941461806,
              21.029468347622938
            ],
            [
              105.83677315495258,
              21.029478511246726
            ],
            [
              105.83679115521761,
              21.02952741617706
            ],
            [
              105.83682734522463,
              21.02960505161766
            ],
            [
              105.8368331133457,
              21.029617424478385
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D200_Văn Miếu",
          "maTaiSan": "04.O14.DOPP.1282",
          "duongKinh": 200,
          "chieudaiQL": 98,
          "chatLieu": "G-DI",
          "from_latitude": 21.028761883381545,
          "to_latitude": 21.029617424478385,
          "to_longitude": 105.8368331133457,
          "from_longitude": 105.83650679784101
        }
      },
      {
        "id": 1279,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83647298806085,
            21.028685936914616,
            105.83650679784101,
            21.028761883381545
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83647298806085,
              21.028685936914616
            ],
            [
              105.83650679784101,
              21.028761883381545
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D200_Văn Miếu",
          "maTaiSan": "04.O14.DOPP.1283",
          "duongKinh": 200,
          "chieudaiQL": 16,
          "chatLieu": "G-DI",
          "from_latitude": 21.028685936914616,
          "to_latitude": 21.028761883381545,
          "to_longitude": 105.83650679784101,
          "from_longitude": 105.83647298806085
        }
      },
      {
        "id": 1280,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83620125847574,
            21.027967292664492,
            105.83647298806085,
            21.028685936914616
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83620125847574,
              21.027967292664492
            ],
            [
              105.83620541450125,
              21.027977316067425
            ],
            [
              105.8362069532637,
              21.02798102706978
            ],
            [
              105.83630714095361,
              21.028250105786665
            ],
            [
              105.83631716530468,
              21.028277027626583
            ],
            [
              105.83633566310408,
              21.02832670784828
            ],
            [
              105.8363393607173,
              21.0283366395664
            ],
            [
              105.8363831616848,
              21.028454276663503
            ],
            [
              105.83638620884581,
              21.02846246023033
            ],
            [
              105.83645116525422,
              21.02863691525987
            ],
            [
              105.83647298806085,
              21.028685936914616
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D200_Văn Miếu",
          "maTaiSan": "04.O14.DOPP.1284",
          "duongKinh": 200,
          "chieudaiQL": 89,
          "chatLieu": "G-DI",
          "from_latitude": 21.027967292664492,
          "to_latitude": 21.028685936914616,
          "to_longitude": 105.83647298806085,
          "from_longitude": 105.83620125847574
        }
      },
      {
        "id": 1281,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83647298806085,
            21.028638244802764,
            105.83698018346956,
            21.028685936914616
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83698018346956,
              21.028638244802764
            ],
            [
              105.83691316118194,
              21.028647667428295
            ],
            [
              105.83675066082152,
              21.0286632077368
            ],
            [
              105.83672602990866,
              21.028665563512558
            ],
            [
              105.8365885354125,
              21.028678712626963
            ],
            [
              105.83650440491924,
              21.028682002378485
            ],
            [
              105.83647298806085,
              21.028685936914616
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Nguyễn Khuyến",
          "maTaiSan": "04.O14.DOPP.1285",
          "duongKinh": 100,
          "chieudaiQL": 52,
          "chatLieu": "G-DI",
          "from_latitude": 21.028638244802764,
          "to_latitude": 21.028685936914616,
          "to_longitude": 105.83647298806085,
          "from_longitude": 105.83698018346956
        }
      },
      {
        "id": 1282,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83698018346956,
            21.02857250383232,
            105.83739165584512,
            21.028638244802764
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83739165584512,
              21.02857250383232
            ],
            [
              105.83735897218412,
              21.028578355085504
            ],
            [
              105.8373512059428,
              21.028579585583273
            ],
            [
              105.83717733248683,
              21.02860714097068
            ],
            [
              105.83715811103615,
              21.02861018733492
            ],
            [
              105.83698799662963,
              21.028637145961387
            ],
            [
              105.83698018346956,
              21.028638244802764
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Nguyễn Khuyến",
          "maTaiSan": "04.O14.DOPP.1286",
          "duongKinh": 100,
          "chieudaiQL": 41,
          "chatLieu": "G-DI",
          "from_latitude": 21.02857250383232,
          "to_latitude": 21.028638244802764,
          "to_longitude": 105.83698018346956,
          "from_longitude": 105.83739165584512
        }
      },
      {
        "id": 1283,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83826274219788,
            21.027366576772383,
            105.8387813669934,
            21.02755069813005
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8387813669934,
              21.027366576772383
            ],
            [
              105.83876739438544,
              21.02737046722688
            ],
            [
              105.83872766993842,
              21.02739021883394
            ],
            [
              105.83851645545747,
              21.027463124464564
            ],
            [
              105.8385138351861,
              21.02746402886581
            ],
            [
              105.83851378237796,
              21.02746404719065
            ],
            [
              105.83850993309952,
              21.027465374940913
            ],
            [
              105.83850472617053,
              21.02746717364198
            ],
            [
              105.83826274219788,
              21.02755069813005
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 38, Ngô Sĩ Liên",
          "maTaiSan": "04.O14.DOPP.1287",
          "duongKinh": 100,
          "chieudaiQL": 57,
          "chatLieu": "G-DI",
          "from_latitude": 21.027366576772383,
          "to_latitude": 21.02755069813005,
          "to_longitude": 105.83826274219788,
          "from_longitude": 105.8387813669934
        }
      },
      {
        "id": 1284,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83710993784359,
            21.02745323013614,
            105.8375767021075,
            21.02762792938378
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83710993784359,
              21.02762792938378
            ],
            [
              105.83721336692209,
              21.02760091512307
            ],
            [
              105.83721620641175,
              21.02759947043824
            ],
            [
              105.83721976920624,
              21.027597658193432
            ],
            [
              105.83722131077518,
              21.0275968729288
            ],
            [
              105.83722201585277,
              21.027596514490835
            ],
            [
              105.83722603239048,
              21.02759447149656
            ],
            [
              105.8372736104282,
              21.02757272789413
            ],
            [
              105.83739511518178,
              21.027525480848503
            ],
            [
              105.8375136875607,
              21.027474775268303
            ],
            [
              105.8375767021075,
              21.02745323013614
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngô Tất Tố",
          "maTaiSan": "04.O14.DOPP.1288",
          "duongKinh": 100,
          "chieudaiQL": 53,
          "chatLieu": "G-DI",
          "from_latitude": 21.02762792938378,
          "to_latitude": 21.02745323013614,
          "to_longitude": 105.8375767021075,
          "from_longitude": 105.83710993784359
        }
      },
      {
        "id": 1285,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83810208653018,
            21.027015228851468,
            105.83862211344693,
            21.027093937087884
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83810208653018,
              21.02707552683368
            ],
            [
              105.83817998024251,
              21.02704676004706
            ],
            [
              105.838183158893,
              21.027045586448118
            ],
            [
              105.83818625114179,
              21.027044444887803
            ],
            [
              105.83821512874496,
              21.027033780003737
            ],
            [
              105.83821619149312,
              21.027033387282753
            ],
            [
              105.83824546051824,
              21.027026315290914
            ],
            [
              105.83824821199491,
              21.02702565050443
            ],
            [
              105.83826142180672,
              21.02702934640825
            ],
            [
              105.83836168136395,
              21.027015228851468
            ],
            [
              105.83836769551628,
              21.02703030740868
            ],
            [
              105.83836878632827,
              21.027033040602337
            ],
            [
              105.83836909855225,
              21.02703382396363
            ],
            [
              105.83837210441065,
              21.027035985008247
            ],
            [
              105.83837741682348,
              21.027039804740202
            ],
            [
              105.83839608961414,
              21.02705394385848
            ],
            [
              105.83841773512792,
              21.02706724277255
            ],
            [
              105.83843998948305,
              21.027093937087884
            ],
            [
              105.838574478228,
              21.027049880415692
            ],
            [
              105.83862211344693,
              21.027029999511495
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 56, Ngô Sĩ Liên",
          "maTaiSan": "04.O14.DOPP.1289",
          "duongKinh": 100,
          "chieudaiQL": 60,
          "chatLieu": "G-DI",
          "from_latitude": 21.02707552683368,
          "to_latitude": 21.027029999511495,
          "to_longitude": 105.83862211344693,
          "from_longitude": 105.83810208653018
        }
      },
      {
        "id": 1286,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83791564798285,
            21.025988279693916,
            105.83836133300557,
            21.02648556898887
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83836133300557,
              21.02648556898887
            ],
            [
              105.8382751323017,
              21.026317110223943
            ],
            [
              105.8382000260552,
              21.026156987664304
            ],
            [
              105.83814901530039,
              21.026092714645483
            ],
            [
              105.83814059950515,
              21.026089927228856
            ],
            [
              105.83809214988146,
              21.026073877284265
            ],
            [
              105.83807706841628,
              21.02608142306808
            ],
            [
              105.83800569454888,
              21.026117911837815
            ],
            [
              105.83799725904558,
              21.026122096488816
            ],
            [
              105.83799144406815,
              21.026112174336184
            ],
            [
              105.83793263071446,
              21.02601986572209
            ],
            [
              105.83791564798285,
              21.025988279693916
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D250_Ngô Sĩ Liên",
          "maTaiSan": "04.O14.DOPP.1290",
          "duongKinh": 250,
          "chieudaiQL": 73,
          "chatLieu": "G-DI",
          "from_latitude": 21.02648556898887,
          "to_latitude": 21.025988279693916,
          "to_longitude": 105.83791564798285,
          "from_longitude": 105.83836133300557
        }
      },
      {
        "id": 1287,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83836133300557,
            21.02648556898887,
            105.83861779742823,
            21.02702097773019
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83861779742823,
              21.02702097773019
            ],
            [
              105.83846790679281,
              21.026707602168592
            ],
            [
              105.83836707447463,
              21.026496789420886
            ],
            [
              105.83836133300557,
              21.02648556898887
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D250_Ngô Sĩ Liên",
          "maTaiSan": "04.O14.DOPP.1291",
          "duongKinh": 250,
          "chieudaiQL": 65,
          "chatLieu": "G-DI",
          "from_latitude": 21.02702097773019,
          "to_latitude": 21.02648556898887,
          "to_longitude": 105.83836133300557,
          "from_longitude": 105.83861779742823
        }
      },
      {
        "id": 1288,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83861779742823,
            21.02702097773019,
            105.83878344449606,
            21.027370967928988
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83878344449606,
              21.027370967928988
            ],
            [
              105.8387813669934,
              21.027366576772383
            ],
            [
              105.83874261878472,
              21.027284678503012
            ],
            [
              105.83862350053698,
              21.027032900144935
            ],
            [
              105.83862211344693,
              21.027029999511495
            ],
            [
              105.83861779742823,
              21.02702097773019
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D250_Ngô Sĩ Liên",
          "maTaiSan": "04.O14.DOPP.1292",
          "duongKinh": 250,
          "chieudaiQL": 45,
          "chatLieu": "G-DI",
          "from_latitude": 21.027370967928988,
          "to_latitude": 21.02702097773019,
          "to_longitude": 105.83861779742823,
          "from_longitude": 105.83878344449606
        }
      },
      {
        "id": 1289,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83878344449606,
            21.027370967928988,
            105.83886539409394,
            21.027532338087873
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83886539409394,
              21.027532338087873
            ],
            [
              105.83882832361479,
              21.027460423917763
            ],
            [
              105.83882484668243,
              21.027453679544188
            ],
            [
              105.83879721014068,
              21.02740006328162
            ],
            [
              105.83878344449606,
              21.027370967928988
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D250_Ngô Sĩ Liên",
          "maTaiSan": "04.O14.DOPP.1293",
          "duongKinh": 250,
          "chieudaiQL": 17,
          "chatLieu": "G-DI",
          "from_latitude": 21.027532338087873,
          "to_latitude": 21.027370967928988,
          "to_longitude": 105.83878344449606,
          "from_longitude": 105.83886539409394
        }
      },
      {
        "id": 1290,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83786091781,
            21.025589544048966,
            105.83805010545618,
            21.025896612922484
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8378829643537,
              21.025589544048966
            ],
            [
              105.83786091781,
              21.025606859066308
            ],
            [
              105.83787929704924,
              21.025632546498997
            ],
            [
              105.83788110113125,
              21.025631228840403
            ],
            [
              105.83787707230734,
              21.025623489101303
            ],
            [
              105.83787886989055,
              21.025622041410646
            ],
            [
              105.83789825946631,
              21.025648286571194
            ],
            [
              105.83791218843308,
              21.02565399586007
            ],
            [
              105.83798307455581,
              21.025754017234803
            ],
            [
              105.83801293452048,
              21.02579573899632
            ],
            [
              105.83804081148136,
              21.025875260467984
            ],
            [
              105.83805010545618,
              21.025896612922484
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D250_Lương Sử C",
          "maTaiSan": "04.O14.DOPP.1294",
          "duongKinh": 250,
          "chieudaiQL": 34,
          "chatLieu": "G-DI",
          "from_latitude": 21.025589544048966,
          "to_latitude": 21.025896612922484,
          "to_longitude": 105.83805010545618,
          "from_longitude": 105.8378829643537
        }
      },
      {
        "id": 1291,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83725606391931,
            21.025291258528778,
            105.83804764364639,
            21.025898153307274
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83725606391931,
              21.025291258528778
            ],
            [
              105.83726738882119,
              21.025333507035388
            ],
            [
              105.83726837718064,
              21.025337193642358
            ],
            [
              105.83728788606912,
              21.025409975957395
            ],
            [
              105.83750641953203,
              21.02541531910518
            ],
            [
              105.83750932805403,
              21.025415390587906
            ],
            [
              105.83751269935533,
              21.02541547243668
            ],
            [
              105.83752932593049,
              21.025415879198988
            ],
            [
              105.83766114838107,
              21.025429951583952
            ],
            [
              105.8377058507831,
              21.025439806846812
            ],
            [
              105.83772985433197,
              21.025445098913863
            ],
            [
              105.83778515477444,
              21.025502754704824
            ],
            [
              105.83778755933339,
              21.025506463234798
            ],
            [
              105.83784012190146,
              21.025587516937055
            ],
            [
              105.83788199396237,
              21.025639852027407
            ],
            [
              105.83789121045331,
              21.025649413298048
            ],
            [
              105.83790865835188,
              21.02565442241301
            ],
            [
              105.83790987145584,
              21.025654770499383
            ],
            [
              105.83791101395468,
              21.025656427703705
            ],
            [
              105.83794435974781,
              21.025704786347443
            ],
            [
              105.83800566273136,
              21.025797972370736
            ],
            [
              105.83804653780365,
              21.025895309991675
            ],
            [
              105.83804764364639,
              21.025898153307274
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D200_Lương Sử C",
          "maTaiSan": "04.O14.DOPP.1295",
          "duongKinh": 200,
          "chieudaiQL": 114,
          "chatLieu": "G-DI",
          "from_latitude": 21.025291258528778,
          "to_latitude": 21.025898153307274,
          "to_longitude": 105.83804764364639,
          "from_longitude": 105.83725606391931
        }
      },
      {
        "id": 1292,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83688115999786,
            21.026120289065386,
            105.83738249743953,
            21.02627064506059
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83688115999786,
              21.02627064506059
            ],
            [
              105.83714382245518,
              21.02613207500418
            ],
            [
              105.83716616266301,
              21.026120289065386
            ],
            [
              105.83720099613167,
              21.026177185925928
            ],
            [
              105.83722994798265,
              21.0262089425216
            ],
            [
              105.83725801275999,
              21.026193482076255
            ],
            [
              105.83730001558926,
              21.02626044977951
            ],
            [
              105.83737690407656,
              21.026218307066355
            ],
            [
              105.83738249743953,
              21.02621956119781
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D160_Quốc Tử Giám",
          "maTaiSan": "04.O14.DOPP.1296",
          "duongKinh": 160,
          "chieudaiQL": 70,
          "chatLieu": "G-DI",
          "from_latitude": 21.02627064506059,
          "to_latitude": 21.02621956119781,
          "to_longitude": 105.83738249743953,
          "from_longitude": 105.83688115999786
        }
      },
      {
        "id": 1293,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83657237332184,
            21.02627064506059,
            105.83694607189321,
            21.026577225258748
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83657237332184,
              21.026577225258748
            ],
            [
              105.8365724817081,
              21.026577167822516
            ],
            [
              105.83672981805829,
              21.026493437229757
            ],
            [
              105.83670100316158,
              21.026445705471005
            ],
            [
              105.83677815386699,
              21.026404647406018
            ],
            [
              105.83680662235082,
              21.026452360081937
            ],
            [
              105.8369239328985,
              21.026390622845444
            ],
            [
              105.83693543546657,
              21.02638456883515
            ],
            [
              105.83694607189321,
              21.0263789716016
            ],
            [
              105.83688115999786,
              21.02627064506059
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D160_Quốc Tử Giám",
          "maTaiSan": "04.O14.DOPP.1297",
          "duongKinh": 160,
          "chieudaiQL": 69,
          "chatLieu": "G-DI",
          "from_latitude": 21.026577225258748,
          "to_latitude": 21.02627064506059,
          "to_longitude": 105.83688115999786,
          "from_longitude": 105.83657237332184
        }
      },
      {
        "id": 1294,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83385277352208,
            21.02681573057513,
            105.83421913777688,
            21.027605675930133
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83385277352208,
              21.02681573057513
            ],
            [
              105.83386394507943,
              21.026838168771864
            ],
            [
              105.8339880422167,
              21.027106699145712
            ],
            [
              105.83399025453697,
              21.02711148621908
            ],
            [
              105.83409992136797,
              21.027348789664686
            ],
            [
              105.83410799363695,
              21.027366256347072
            ],
            [
              105.83413219001756,
              21.02741861404016
            ],
            [
              105.83420155522616,
              21.027567848478597
            ],
            [
              105.83420570111758,
              21.027576768245698
            ],
            [
              105.83421913777688,
              21.027605675930133
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Tôn Đức Thắng",
          "maTaiSan": "04.O14.DOPP.1298",
          "duongKinh": 150,
          "chieudaiQL": 98,
          "chatLieu": "G-DI",
          "from_latitude": 21.02681573057513,
          "to_latitude": 21.027605675930133,
          "to_longitude": 105.83421913777688,
          "from_longitude": 105.83385277352208
        }
      },
      {
        "id": 1295,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83712774434592,
            21.02489352725283,
            105.83725606391931,
            21.025291258528778
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83712774434592,
              21.02489352725283
            ],
            [
              105.83712783434602,
              21.024900686659983
            ],
            [
              105.83712791468093,
              21.024907148826454
            ],
            [
              105.83712811072732,
              21.024922790783734
            ],
            [
              105.83713110246588,
              21.024949482560377
            ],
            [
              105.83714744227451,
              21.025045325333327
            ],
            [
              105.83715763610338,
              21.025093310368458
            ],
            [
              105.83716584103655,
              21.02511726771528
            ],
            [
              105.8371683191343,
              21.025124502075986
            ],
            [
              105.83717036303112,
              21.025130469545513
            ],
            [
              105.83717471389261,
              21.025143172735618
            ],
            [
              105.83718816937989,
              21.025185557083212
            ],
            [
              105.8372227177448,
              21.025221931415608
            ],
            [
              105.83725511191516,
              21.025287708129426
            ],
            [
              105.83725606391931,
              21.025291258528778
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D200_Lương Sử C",
          "maTaiSan": "04.O14.DOPP.1299",
          "duongKinh": 200,
          "chieudaiQL": 44,
          "chatLieu": "G-DI",
          "from_latitude": 21.02489352725283,
          "to_latitude": 21.025291258528778,
          "to_longitude": 105.83725606391931,
          "from_longitude": 105.83712774434592
        }
      },
      {
        "id": 1296,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83733603262823,
            21.023870060739643,
            105.8373814543062,
            21.024161801030335
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83736220280556,
              21.023870060739643
            ],
            [
              105.83736549245984,
              21.023877404909268
            ],
            [
              105.83736685721954,
              21.023880451084416
            ],
            [
              105.83737508131483,
              21.023915080816916
            ],
            [
              105.837375647776,
              21.023917465246928
            ],
            [
              105.8373814543062,
              21.023941916717344
            ],
            [
              105.83734044164477,
              21.02401509275895
            ],
            [
              105.83733603262823,
              21.024126326536607
            ],
            [
              105.83733926128049,
              21.02413945524826
            ],
            [
              105.83734015702181,
              21.02414309534387
            ],
            [
              105.8373447568754,
              21.024161801030335
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Linh Quang",
          "maTaiSan": "04.O14.DOPP.1300",
          "duongKinh": 150,
          "chieudaiQL": 34,
          "chatLieu": "G-DI",
          "from_latitude": 21.023870060739643,
          "to_latitude": 21.024161801030335,
          "to_longitude": 105.8373447568754,
          "from_longitude": 105.83736220280556
        }
      },
      {
        "id": 1297,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83722451794729,
            21.023537538600046,
            105.83736220280556,
            21.023870060739643
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83722451794729,
              21.023537538600046
            ],
            [
              105.83722553574307,
              21.023540297453135
            ],
            [
              105.83722637578961,
              21.023549125035395
            ],
            [
              105.83722652294132,
              21.023550669726088
            ],
            [
              105.83724482760603,
              21.02358959886443
            ],
            [
              105.8372461594204,
              21.023592431139054
            ],
            [
              105.83724911316854,
              21.02359871296803
            ],
            [
              105.83725492845629,
              21.023617331385477
            ],
            [
              105.8372559549705,
              21.02362061767781
            ],
            [
              105.83728313401116,
              21.02370192752154
            ],
            [
              105.83731850859161,
              21.02379397607748
            ],
            [
              105.83733902026957,
              21.023843957015863
            ],
            [
              105.83736220280556,
              21.023870060739643
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Linh Quang",
          "maTaiSan": "04.O14.DOPP.1301",
          "duongKinh": 150,
          "chieudaiQL": 35,
          "chatLieu": "G-DI",
          "from_latitude": 21.023537538600046,
          "to_latitude": 21.023870060739643,
          "to_longitude": 105.83736220280556,
          "from_longitude": 105.83722451794729
        }
      },
      {
        "id": 1298,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83421014935486,
            21.022333968829372,
            105.83506246938181,
            21.02257583904192
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83506246938181,
              21.02233762310178
            ],
            [
              105.83503962221737,
              21.022333968829372
            ],
            [
              105.83503807934488,
              21.022333994468653
            ],
            [
              105.83503710205069,
              21.022334008298962
            ],
            [
              105.83501890203956,
              21.02233429636084
            ],
            [
              105.83501515460159,
              21.022334374371365
            ],
            [
              105.83497851030175,
              21.022335135042127
            ],
            [
              105.83497344643978,
              21.022336344027956
            ],
            [
              105.83493615962664,
              21.022345240471154
            ],
            [
              105.8348469611872,
              21.02236652111135
            ],
            [
              105.83480257296364,
              21.022373851869602
            ],
            [
              105.83472682221003,
              21.022388602973766
            ],
            [
              105.83470818288896,
              21.022392232377218
            ],
            [
              105.83470134197063,
              21.022393564785567
            ],
            [
              105.83468038775021,
              21.022397645416554
            ],
            [
              105.8346518315413,
              21.022403205798792
            ],
            [
              105.83462059117781,
              21.022414774044584
            ],
            [
              105.8345782090293,
              21.022430467786272
            ],
            [
              105.83448917318945,
              21.022454883436964
            ],
            [
              105.83448611683063,
              21.022455721269427
            ],
            [
              105.83443309980689,
              21.02247242457072
            ],
            [
              105.83442925381972,
              21.022473636597915
            ],
            [
              105.83439958307991,
              21.022487053228463
            ],
            [
              105.8343862969309,
              21.02249306162064
            ],
            [
              105.83436929204441,
              21.02250534398344
            ],
            [
              105.8343551155519,
              21.022515582925642
            ],
            [
              105.8343072582353,
              21.022538970927705
            ],
            [
              105.83429973003948,
              21.022542649654316
            ],
            [
              105.83422374874624,
              21.02257080009246
            ],
            [
              105.83421014935486,
              21.02257583904192
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D200_Văn Chương",
          "maTaiSan": "04.O14.DOPP.1302",
          "duongKinh": 200,
          "chieudaiQL": 68,
          "chatLieu": "G-DI",
          "from_latitude": 21.02233762310178,
          "to_latitude": 21.02257583904192,
          "to_longitude": 105.83421014935486,
          "from_longitude": 105.83506246938181
        }
      },
      {
        "id": 1299,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83177078105446,
            21.022305021646947,
            105.83188499011197,
            21.022560236351314
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83177078105446,
              21.022305021646947
            ],
            [
              105.83184225236646,
              21.022464731598205
            ],
            [
              105.83188499011197,
              21.022560236351314
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Tôn Đức Thắng",
          "maTaiSan": "04.O14.DOPP.1303",
          "duongKinh": 150,
          "chieudaiQL": 33,
          "chatLieu": "G-DI",
          "from_latitude": 21.022305021646947,
          "to_latitude": 21.022560236351314,
          "to_longitude": 105.83188499011197,
          "from_longitude": 105.83177078105446
        }
      },
      {
        "id": 1300,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83160729735586,
            21.02194025271495,
            105.83177078105446,
            21.022305021646947
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83160729735586,
              21.02194025271495
            ],
            [
              105.83161439796082,
              21.021955561071348
            ],
            [
              105.83165734579265,
              21.022051534545
            ],
            [
              105.83168920409466,
              21.022122726269888
            ],
            [
              105.8317551796251,
              21.0222701576105
            ],
            [
              105.83177078105446,
              21.022305021646947
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Tôn Đức Thắng",
          "maTaiSan": "04.O14.DOPP.1304",
          "duongKinh": 150,
          "chieudaiQL": 42,
          "chatLieu": "G-DI",
          "from_latitude": 21.02194025271495,
          "to_latitude": 21.022305021646947,
          "to_longitude": 105.83177078105446,
          "from_longitude": 105.83160729735586
        }
      },
      {
        "id": 1301,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83719813093282,
            21.019109862558278,
            105.83775440152785,
            21.020006143069914
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83775440152785,
              21.020006143069914
            ],
            [
              105.8376772716127,
              21.019906311111313
            ],
            [
              105.83756619159281,
              21.019786750130752
            ],
            [
              105.8375449919642,
              21.01976007212729
            ],
            [
              105.83750211125812,
              21.01970611151574
            ],
            [
              105.83739643184519,
              21.019584459053952
            ],
            [
              105.8373962856045,
              21.019584282742024
            ],
            [
              105.83733752452125,
              21.019516635132035
            ],
            [
              105.83733516610653,
              21.019512901072662
            ],
            [
              105.83732303344047,
              21.01949370316275
            ],
            [
              105.83727846327034,
              21.019423126972256
            ],
            [
              105.83724702562101,
              21.019323740132336
            ],
            [
              105.83723757112637,
              21.019295763246816
            ],
            [
              105.83721667217627,
              21.019225392977955
            ],
            [
              105.8372148856739,
              21.01921675159677
            ],
            [
              105.83720262623494,
              21.019157434004043
            ],
            [
              105.83719813093282,
              21.019109862558278
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Văn Chương",
          "maTaiSan": "04.O14.DOPP.1305",
          "duongKinh": 150,
          "chieudaiQL": 115,
          "chatLieu": "G-DI",
          "from_latitude": 21.020006143069914,
          "to_latitude": 21.019109862558278,
          "to_longitude": 105.83719813093282,
          "from_longitude": 105.83775440152785
        }
      },
      {
        "id": 1302,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83863422948585,
            21.018983363216325,
            105.83916247783294,
            21.019042874119965
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83863422948585,
              21.019042874119965
            ],
            [
              105.83913268652684,
              21.018986721138244
            ],
            [
              105.83916247783294,
              21.018983363216325
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Khâm Thiên",
          "maTaiSan": "04.O14.DOPP.1306",
          "duongKinh": 150,
          "chieudaiQL": 51,
          "chatLieu": "G-DI",
          "from_latitude": 21.019042874119965,
          "to_latitude": 21.018983363216325,
          "to_longitude": 105.83916247783294,
          "from_longitude": 105.83863422948585
        }
      },
      {
        "id": 1303,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83914667287343,
            21.01938796867335,
            105.83929887953336,
            21.019711462217995
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83920609736035,
              21.019711462217995
            ],
            [
              105.83914667287343,
              21.019621336398654
            ],
            [
              105.83920914987343,
              21.019580340673286
            ],
            [
              105.83921083002386,
              21.01957923858067
            ],
            [
              105.83921216632578,
              21.019578403732115
            ],
            [
              105.83927866033166,
              21.019536873326533
            ],
            [
              105.83927964557886,
              21.019535877629355
            ],
            [
              105.83928109666962,
              21.019534411763992
            ],
            [
              105.83928247682039,
              21.019533505343897
            ],
            [
              105.83929887953336,
              21.01952273386092
            ],
            [
              105.83929437730106,
              21.019516500350115
            ],
            [
              105.8392900553152,
              21.01951084672244
            ],
            [
              105.83919612484691,
              21.01938796867335
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ Tương Thuận, Khâm Thiên",
          "maTaiSan": "04.O14.DOPP.1307",
          "duongKinh": 100,
          "chieudaiQL": 52,
          "chatLieu": "G-DI",
          "from_latitude": 21.019711462217995,
          "to_latitude": 21.01938796867335,
          "to_longitude": 105.83919612484691,
          "from_longitude": 105.83920609736035
        }
      },
      {
        "id": 1304,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83751923750344,
            21.01992509984172,
            105.83790854603842,
            21.020248065239898
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83790854603842,
              21.01992509984172
            ],
            [
              105.83790631520678,
              21.01992698682602
            ],
            [
              105.8377757457191,
              21.019994540738193
            ],
            [
              105.8377558232604,
              21.02000529293146
            ],
            [
              105.83756885265905,
              21.02011709373314
            ],
            [
              105.8375345336059,
              21.0202433943236
            ],
            [
              105.83751923750344,
              21.020248065239898
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D200_Văn Chương",
          "maTaiSan": "04.O14.DOPP.1308",
          "duongKinh": 200,
          "chieudaiQL": 47,
          "chatLieu": "G-DI",
          "from_latitude": 21.01992509984172,
          "to_latitude": 21.020248065239898,
          "to_longitude": 105.83751923750344,
          "from_longitude": 105.83790854603842
        }
      },
      {
        "id": 1305,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83501285764687,
            21.02075239438111,
            105.83611166517156,
            21.021446273775055
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83611166517156,
              21.02075239438111
            ],
            [
              105.83607845622831,
              21.02076380998028
            ],
            [
              105.83586894055505,
              21.020828059041825
            ],
            [
              105.83585051569621,
              21.020833708925846
            ],
            [
              105.83563179830475,
              21.020901189491475
            ],
            [
              105.83561879078403,
              21.02090520319174
            ],
            [
              105.83546779685253,
              21.02095800312209
            ],
            [
              105.83536110975717,
              21.020998111805564
            ],
            [
              105.83534222445478,
              21.02100521176477
            ],
            [
              105.8350785215716,
              21.02111063660371
            ],
            [
              105.8350758757868,
              21.021111607913898
            ],
            [
              105.83502660399898,
              21.02114899398936
            ],
            [
              105.83501285764687,
              21.021205178776068
            ],
            [
              105.83501670241472,
              21.02139982452701
            ],
            [
              105.83501680812344,
              21.02140516025492
            ],
            [
              105.83502865001904,
              21.021446273775055
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Văn Chương",
          "maTaiSan": "04.O14.DOPP.1309",
          "duongKinh": 150,
          "chieudaiQL": 156,
          "chatLieu": "G-DI",
          "from_latitude": 21.02075239438111,
          "to_latitude": 21.021446273775055,
          "to_longitude": 105.83502865001904,
          "from_longitude": 105.83611166517156
        }
      },
      {
        "id": 1306,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83611166517156,
            21.020576980856898,
            105.83657610264589,
            21.02075239438111
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83657610264589,
              21.020576980856898
            ],
            [
              105.83640498681521,
              21.020654246833022
            ],
            [
              105.83635049887646,
              21.020671646200718
            ],
            [
              105.83633140797508,
              21.02067774199041
            ],
            [
              105.83611166517156,
              21.02075239438111
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Văn Chương",
          "maTaiSan": "04.O14.DOPP.1310",
          "duongKinh": 150,
          "chieudaiQL": 51,
          "chatLieu": "G-DI",
          "from_latitude": 21.020576980856898,
          "to_latitude": 21.02075239438111,
          "to_longitude": 105.83611166517156,
          "from_longitude": 105.83657610264589
        }
      },
      {
        "id": 1307,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83506246938181,
            21.02233762310178,
            105.83597812600344,
            21.022376301578593
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83597812600344,
              21.02237597849603
            ],
            [
              105.8359457520745,
              21.022376074387665
            ],
            [
              105.83594565010742,
              21.02237607488882
            ],
            [
              105.83592830493589,
              21.022376125815004
            ],
            [
              105.83586901718438,
              21.022376301578593
            ],
            [
              105.83573416321943,
              21.022373575361105
            ],
            [
              105.83570916817284,
              21.022373070435556
            ],
            [
              105.83570758169027,
              21.022373038488876
            ],
            [
              105.83562342605306,
              21.02237308706327
            ],
            [
              105.83557306437375,
              21.022372030227295
            ],
            [
              105.83548117578228,
              21.022364899963144
            ],
            [
              105.83544921557856,
              21.022362419540276
            ],
            [
              105.83527802462734,
              21.022349134821674
            ],
            [
              105.83526921100881,
              21.022348451009595
            ],
            [
              105.83519384958565,
              21.022347425604902
            ],
            [
              105.83510918279224,
              21.022341538641157
            ],
            [
              105.83506246938181,
              21.02233762310178
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D200_Văn Chương 2",
          "maTaiSan": "04.O14.DOPP.1311",
          "duongKinh": 200,
          "chieudaiQL": 98,
          "chatLieu": "G-DI",
          "from_latitude": 21.02237597849603,
          "to_latitude": 21.02233762310178,
          "to_longitude": 105.83506246938181,
          "from_longitude": 105.83597812600344
        }
      },
      {
        "id": 1308,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83597815670537,
            21.022332676997276,
            105.83668386869824,
            21.02237705699273
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83668386869824,
              21.022332676997276
            ],
            [
              105.83668013222469,
              21.022333512795065
            ],
            [
              105.83667535981729,
              21.02233402491533
            ],
            [
              105.83667532911544,
              21.022334039517933
            ],
            [
              105.83646944734599,
              21.022356125269628
            ],
            [
              105.83646942240074,
              21.02235613713421
            ],
            [
              105.8364496635975,
              21.022358252107576
            ],
            [
              105.83641016165767,
              21.02236011733111
            ],
            [
              105.83616884351991,
              21.02237705699273
            ],
            [
              105.83600726380541,
              21.022375877730465
            ],
            [
              105.83597815670537,
              21.022375963893534
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D200_Văn Chương 2",
          "maTaiSan": "04.O14.DOPP.1312",
          "duongKinh": 200,
          "chieudaiQL": 75,
          "chatLieu": "G-DI",
          "from_latitude": 21.022332676997276,
          "to_latitude": 21.022375963893534,
          "to_longitude": 105.83597815670537,
          "from_longitude": 105.83668386869824
        }
      },
      {
        "id": 1309,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83508916326618,
            21.023418399996725,
            105.83585713957972,
            21.023755985936134
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83585713957972,
              21.023418399996725
            ],
            [
              105.83585016458089,
              21.023420536076873
            ],
            [
              105.8357656833242,
              21.02343700062067
            ],
            [
              105.83573803508447,
              21.0234458408483
            ],
            [
              105.83573445325582,
              21.02344698567193
            ],
            [
              105.83567756931781,
              21.023490697590624
            ],
            [
              105.8356740002508,
              21.023493441959257
            ],
            [
              105.83567110833307,
              21.023496342871233
            ],
            [
              105.83566957771885,
              21.0234978795494
            ],
            [
              105.8356644836809,
              21.023503000566205
            ],
            [
              105.83561393917965,
              21.02353307334932
            ],
            [
              105.83555470814395,
              21.02356042583245
            ],
            [
              105.8355507136677,
              21.023562269968174
            ],
            [
              105.83554643421374,
              21.023564245568043
            ],
            [
              105.83552731773555,
              21.023573073653925
            ],
            [
              105.83547267200473,
              21.023592575344175
            ],
            [
              105.8353715998476,
              21.02364316911264
            ],
            [
              105.835365690484,
              21.023646127292054
            ],
            [
              105.83535664416611,
              21.023650655326815
            ],
            [
              105.83534656946797,
              21.023655698733453
            ],
            [
              105.8352164624437,
              21.0237057657287
            ],
            [
              105.83514580750372,
              21.023734818943897
            ],
            [
              105.83509872084473,
              21.02375136237284
            ],
            [
              105.83509327622701,
              21.023753995810203
            ],
            [
              105.83509134494815,
              21.02375493019406
            ],
            [
              105.83508965639685,
              21.023755746870215
            ],
            [
              105.83508916326618,
              21.023755985936134
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D200_Văn Hương",
          "maTaiSan": "04.O14.DOPP.1313",
          "duongKinh": 200,
          "chieudaiQL": 92,
          "chatLieu": "G-DI",
          "from_latitude": 21.023418399996725,
          "to_latitude": 21.023755985936134,
          "to_longitude": 105.83508916326618,
          "from_longitude": 105.83585713957972
        }
      },
      {
        "id": 1310,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83421689319552,
            21.023755985936134,
            105.83508916326618,
            21.024196304245866
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83508916326618,
              21.023755985936134
            ],
            [
              105.83506967949432,
              21.02376140249309
            ],
            [
              105.8350441479505,
              21.023768073512894
            ],
            [
              105.83490640744648,
              21.02381332292776
            ],
            [
              105.83490400686492,
              21.023814111484686
            ],
            [
              105.83487946524342,
              21.02382217400444
            ],
            [
              105.83480405246122,
              21.023853005399975
            ],
            [
              105.83479048178569,
              21.023858553675073
            ],
            [
              105.83468852010641,
              21.02388353327971
            ],
            [
              105.8346766086429,
              21.02388645134442
            ],
            [
              105.83464735549369,
              21.02389802791369
            ],
            [
              105.83464481182655,
              21.02389903484612
            ],
            [
              105.8346136501832,
              21.02390786413692
            ],
            [
              105.83461241117095,
              21.02390821524864
            ],
            [
              105.83460656847978,
              21.023908466113625
            ],
            [
              105.8346014047889,
              21.02390868745288
            ],
            [
              105.83453831652011,
              21.023925448349594
            ],
            [
              105.83447699856674,
              21.02394173898981
            ],
            [
              105.8344271994432,
              21.02396666752606
            ],
            [
              105.83440717368933,
              21.023976692205427
            ],
            [
              105.83438380029295,
              21.023995375350918
            ],
            [
              105.83437844658324,
              21.023999654896826
            ],
            [
              105.83437222804464,
              21.024004626038653
            ],
            [
              105.83430832780388,
              21.024070658981486
            ],
            [
              105.83429842273864,
              21.024085280183375
            ],
            [
              105.83427803342899,
              21.024115377166602
            ],
            [
              105.83425921606846,
              21.02415222796967
            ],
            [
              105.8342585204307,
              21.02415358983051
            ],
            [
              105.8342294308898,
              21.024186113995988
            ],
            [
              105.83421689319552,
              21.024196304245866
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D200_Văn Hương",
          "maTaiSan": "04.O14.DOPP.1314",
          "duongKinh": 200,
          "chieudaiQL": 107,
          "chatLieu": "G-DI",
          "from_latitude": 21.023755985936134,
          "to_latitude": 21.024196304245866,
          "to_longitude": 105.83421689319552,
          "from_longitude": 105.83508916326618
        }
      },
      {
        "id": 1311,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83366303848193,
            21.024196304245866,
            105.83421689319552,
            21.024551223140033
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83421689319552,
              21.024196304245866
            ],
            [
              105.83415642080303,
              21.024270020303096
            ],
            [
              105.83414360829619,
              21.024286667235923
            ],
            [
              105.8341333993628,
              21.024299932373744
            ],
            [
              105.83413111479682,
              21.024302901636418
            ],
            [
              105.83412928771114,
              21.0243052752373
            ],
            [
              105.83408594129561,
              21.024338475365816
            ],
            [
              105.8340021104599,
              21.024394002999962
            ],
            [
              105.83399445917026,
              21.024399436379326
            ],
            [
              105.83398341869736,
              21.024407276308157
            ],
            [
              105.83397020405141,
              21.02441666057168
            ],
            [
              105.83394214497882,
              21.024435227539474
            ],
            [
              105.83393781463708,
              21.02443809392814
            ],
            [
              105.83393697214268,
              21.024438650832234
            ],
            [
              105.83393597533926,
              21.024439310557266
            ],
            [
              105.83393510026359,
              21.024439890201716
            ],
            [
              105.83391435806742,
              21.024457931739665
            ],
            [
              105.83389526207468,
              21.024472037986474
            ],
            [
              105.83389286309976,
              21.024473810132346
            ],
            [
              105.83384004925239,
              21.024496915224304
            ],
            [
              105.83375687824207,
              21.024534985609865
            ],
            [
              105.8337536728522,
              21.024535845838287
            ],
            [
              105.83370208305898,
              21.024549688650044
            ],
            [
              105.83366303848193,
              21.024551223140033
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D200_Văn Hương",
          "maTaiSan": "04.O14.DOPP.1315",
          "duongKinh": 200,
          "chieudaiQL": 72,
          "chatLieu": "G-DI",
          "from_latitude": 21.024196304245866,
          "to_latitude": 21.024551223140033,
          "to_longitude": 105.83366303848193,
          "from_longitude": 105.83421689319552
        }
      },
      {
        "id": 1312,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83585713957972,
            21.023418399996725,
            105.83634170031792,
            21.023966043947095
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83634170031792,
              21.023966043947095
            ],
            [
              105.83633363713416,
              21.023952285956824
            ],
            [
              105.8363330077666,
              21.02395138311863
            ],
            [
              105.83633110404169,
              21.023948978163915
            ],
            [
              105.83628039338032,
              21.02388493246685
            ],
            [
              105.83626768317689,
              21.023871925321092
            ],
            [
              105.83626764382208,
              21.023871940869405
            ],
            [
              105.83615446955142,
              21.023911426247892
            ],
            [
              105.83613862976773,
              21.023889579275764
            ],
            [
              105.83612383130676,
              21.023869192211052
            ],
            [
              105.83610985170671,
              21.023848669248643
            ],
            [
              105.83604828312727,
              21.02375200365
            ],
            [
              105.8360374983957,
              21.023732530779284
            ],
            [
              105.83603311486189,
              21.02372463918627
            ],
            [
              105.83592978934483,
              21.02353513864513
            ],
            [
              105.83588132865616,
              21.0234532430934
            ],
            [
              105.83585792376658,
              21.023423434323774
            ],
            [
              105.83585713957972,
              21.023418399996725
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Ngách 33, Văn Chương 2",
          "maTaiSan": "04.O14.DOPP.1316",
          "duongKinh": 150,
          "chieudaiQL": 88,
          "chatLieu": "G-DI",
          "from_latitude": 21.023966043947095,
          "to_latitude": 21.023418399996725,
          "to_longitude": 105.83585713957972,
          "from_longitude": 105.83634170031792
        }
      },
      {
        "id": 1313,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83682687504727,
            21.021216636400496,
            105.83688278852351,
            21.021498072352237
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83688278852351,
              21.021488778534096
            ],
            [
              105.83687368144027,
              21.021498072352237
            ],
            [
              105.83683418459641,
              21.021464265730348
            ],
            [
              105.83683161443943,
              21.021462067284
            ],
            [
              105.83682687504727,
              21.0214590097057
            ],
            [
              105.83682896649914,
              21.02145403258943
            ],
            [
              105.83682901111587,
              21.02145392578954
            ],
            [
              105.83684043295845,
              21.02142675030641
            ],
            [
              105.83684138399815,
              21.021352567486208
            ],
            [
              105.83684201214986,
              21.021325674525865
            ],
            [
              105.83684209402995,
              21.02129725328906
            ],
            [
              105.83684312748582,
              21.021216636400496
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 273, Văn Chương",
          "maTaiSan": "04.O14.DOPP.1317",
          "duongKinh": 100,
          "chieudaiQL": 46,
          "chatLieu": "G-DI",
          "from_latitude": 21.021488778534096,
          "to_latitude": 21.021216636400496,
          "to_longitude": 105.83684312748582,
          "from_longitude": 105.83688278852351
        }
      },
      {
        "id": 1314,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83625530406313,
            21.0214590097057,
            105.83682687504727,
            21.02193613517409
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83682687504727,
              21.0214590097057
            ],
            [
              105.83670674017014,
              21.021556974541685
            ],
            [
              105.83661743468349,
              21.021629798170864
            ],
            [
              105.8365293593116,
              21.02170086796736
            ],
            [
              105.83652673243637,
              21.021702987204296
            ],
            [
              105.83638112462963,
              21.021829340771514
            ],
            [
              105.83625530406313,
              21.02193613517409
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Ngõ 273, Văn Chương",
          "maTaiSan": "04.O14.DOPP.1318",
          "duongKinh": 150,
          "chieudaiQL": 78,
          "chatLieu": "G-DI",
          "from_latitude": 21.0214590097057,
          "to_latitude": 21.02193613517409,
          "to_longitude": 105.83625530406313,
          "from_longitude": 105.83682687504727
        }
      },
      {
        "id": 1315,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83983754853377,
            21.025081192957852,
            105.83985027858603,
            21.026074693725576
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83983808815972,
              21.025081192957852
            ],
            [
              105.83983864430866,
              21.025184421339382
            ],
            [
              105.83985027858603,
              21.02567277882886
            ],
            [
              105.83984854688987,
              21.026006755750032
            ],
            [
              105.83983754853377,
              21.02603341087421
            ],
            [
              105.83983897942436,
              21.026074693725576
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D200_Trần Quý Cáp",
          "maTaiSan": "04.O14.DOPP.1319",
          "duongKinh": 200,
          "chieudaiQL": 110,
          "chatLieu": "G-DI",
          "from_latitude": 21.025081192957852,
          "to_latitude": 21.026074693725576,
          "to_longitude": 105.83983897942436,
          "from_longitude": 105.83983808815972
        }
      },
      {
        "id": 1316,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8397129309006,
            21.026074693725576,
            105.83986549047827,
            21.02639015246947
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83983897942436,
              21.026074693725576
            ],
            [
              105.83986376177427,
              21.02623871578276
            ],
            [
              105.83986422597559,
              21.026258211355902
            ],
            [
              105.83986549047827,
              21.026289539708316
            ],
            [
              105.8398180287535,
              21.026353539807285
            ],
            [
              105.83977455471174,
              21.02637172591423
            ],
            [
              105.8397129309006,
              21.02639015246947
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D200_Trần Quý Cáp",
          "maTaiSan": "04.O14.DOPP.1320",
          "duongKinh": 200,
          "chieudaiQL": 56,
          "chatLieu": "G-DI",
          "from_latitude": 21.026074693725576,
          "to_latitude": 21.02639015246947,
          "to_longitude": 105.8397129309006,
          "from_longitude": 105.83983897942436
        }
      },
      {
        "id": 1317,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83878273328232,
            21.027253491073573,
            105.83900820170068,
            21.027746457816544
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83878273328232,
              21.027253491073573
            ],
            [
              105.83878616446177,
              21.02726134392823
            ],
            [
              105.83878923619525,
              21.027268055075922
            ],
            [
              105.838896049743,
              21.02750142580726
            ],
            [
              105.83890281946019,
              21.027516216100125
            ],
            [
              105.83900820170068,
              21.027746457816544
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Ngô Sĩ Liên",
          "maTaiSan": "04.O14.DOPP.1321",
          "duongKinh": 150,
          "chieudaiQL": 54,
          "chatLieu": "G-DI",
          "from_latitude": 21.027253491073573,
          "to_latitude": 21.027746457816544,
          "to_longitude": 105.83900820170068,
          "from_longitude": 105.83878273328232
        }
      },
      {
        "id": 1318,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8401888774976,
            21.027437433768718,
            105.84039258721737,
            21.027846164937863
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8401888774976,
              21.027437433768718
            ],
            [
              105.8402412005868,
              21.027457387553042
            ],
            [
              105.8402943073483,
              21.02758384633289
            ],
            [
              105.84029724900888,
              21.02759085164098
            ],
            [
              105.84036508659565,
              21.02775238469175
            ],
            [
              105.84037680352732,
              21.027780285131346
            ],
            [
              105.84038003159311,
              21.02779376061014
            ],
            [
              105.84038578305407,
              21.027817880738326
            ],
            [
              105.84039172354804,
              21.02784277218706
            ],
            [
              105.84039258721737,
              21.027846164937863
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Trần Quý Cáp",
          "maTaiSan": "04.O14.DOPP.1322",
          "duongKinh": 100,
          "chieudaiQL": 59,
          "chatLieu": "G-DI",
          "from_latitude": 21.027437433768718,
          "to_latitude": 21.027846164937863,
          "to_longitude": 105.84039258721737,
          "from_longitude": 105.8401888774976
        }
      },
      {
        "id": 1319,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84039258721737,
            21.027771216360545,
            105.84077887622931,
            21.027846164937863
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84077887622931,
              21.027771216360545
            ],
            [
              105.84074174925,
              21.027780822339455
            ],
            [
              105.84073277802594,
              21.027783143717357
            ],
            [
              105.84062711244921,
              21.027796878437904
            ],
            [
              105.84049572591863,
              21.02781394937454
            ],
            [
              105.84048935699961,
              21.027815939039883
            ],
            [
              105.84039258721737,
              21.027846164937863
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D200_Nguyễn Khuyến",
          "maTaiSan": "04.O14.DOPP.1323",
          "duongKinh": 200,
          "chieudaiQL": 40,
          "chatLieu": "G-DI",
          "from_latitude": 21.027771216360545,
          "to_latitude": 21.027846164937863,
          "to_longitude": 105.84039258721737,
          "from_longitude": 105.84077887622931
        }
      },
      {
        "id": 1320,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83706305359897,
            21.019127254106863,
            105.83717250700906,
            21.01914177247875
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83706305359897,
              21.019127254106863
            ],
            [
              105.83706343590042,
              21.019134737241203
            ],
            [
              105.83706379742476,
              21.01914177247875
            ],
            [
              105.83717250700906,
              21.019136702414208
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D200_Khâm Thiên",
          "maTaiSan": "04.O14.DOPP.1324",
          "duongKinh": 200,
          "chieudaiQL": 40,
          "chatLieu": "G-DI",
          "from_latitude": 21.019127254106863,
          "to_latitude": 21.019136702414208,
          "to_longitude": 105.83717250700906,
          "from_longitude": 105.83706305359897
        }
      },
      {
        "id": 1321,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83706305359897,
            21.019076514029372,
            105.83708170942162,
            21.019127254106863
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83706305359897,
              21.019127254106863
            ],
            [
              105.8370815422877,
              21.019126192160414
            ],
            [
              105.8370816207465,
              21.01910112731411
            ],
            [
              105.83708170942162,
              21.019076514029372
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D200_Khâm Thiên",
          "maTaiSan": "04.O14.DOPP.1325",
          "duongKinh": 200,
          "chieudaiQL": 13.7,
          "chatLieu": "G-DI",
          "from_latitude": 21.019127254106863,
          "to_latitude": 21.019076514029372,
          "to_longitude": 105.83708170942162,
          "from_longitude": 105.83706305359897
        }
      },
      {
        "id": 1322,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83859817241984,
            21.018984383897855,
            105.83863491566323,
            21.019057606969987
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83859817241984,
              21.018984383897855
            ],
            [
              105.83860000918193,
              21.019020922880276
            ],
            [
              105.83860006704394,
              21.019022841041817
            ],
            [
              105.83860064390801,
              21.01904084847532
            ],
            [
              105.83860117627208,
              21.019057606969987
            ],
            [
              105.83863491566323,
              21.019055313544413
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D200_Ngõ Trung Tiền, Khâm Thiên",
          "maTaiSan": "04.O14.DOPP.1326",
          "duongKinh": 200,
          "chieudaiQL": 144,
          "chatLieu": "G-DI",
          "from_latitude": 21.018984383897855,
          "to_latitude": 21.019055313544413,
          "to_longitude": 105.83863491566323,
          "from_longitude": 105.83859817241984
        }
      },
      {
        "id": 1323,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83176562668268,
            21.001142690883157,
            105.83180682591762,
            21.001309944155825
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83180682591762,
              21.001309944155825
            ],
            [
              105.83177000806275,
              21.00116042871506
            ],
            [
              105.83176717912468,
              21.001148944464003
            ],
            [
              105.83176562668268,
              21.001142690883157
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Tôn Thất Tùng",
          "maTaiSan": "04.O17.DOPP.1327",
          "duongKinh": 100,
          "chieudaiQL": 19,
          "chatLieu": "G",
          "from_latitude": 21.001309944155825,
          "to_latitude": 21.001142690883157,
          "to_longitude": 105.83176562668268,
          "from_longitude": 105.83180682591762
        }
      },
      {
        "id": 1324,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82948904185264,
            21.001091529603702,
            105.83307034112472,
            21.001929479870647
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82948904185264,
              21.001927515642304
            ],
            [
              105.82948941737678,
              21.001927414457402
            ],
            [
              105.8295806165293,
              21.00190277617847
            ],
            [
              105.82961778686798,
              21.001894362108228
            ],
            [
              105.82961788680281,
              21.00189434445985
            ],
            [
              105.82963051194191,
              21.001929479870647
            ],
            [
              105.8296377308437,
              21.00192795345877
            ],
            [
              105.8303450279266,
              21.00174086602393
            ],
            [
              105.83042461933145,
              21.001709639786355
            ],
            [
              105.83056579613334,
              21.00160457772693
            ],
            [
              105.8308947426631,
              21.001530296041732
            ],
            [
              105.83109857152434,
              21.00148426790575
            ],
            [
              105.83111999103738,
              21.001479431320526
            ],
            [
              105.83180682591762,
              21.001309944155825
            ],
            [
              105.83180687004621,
              21.001309923165984
            ],
            [
              105.83207606670608,
              21.0012598143063
            ],
            [
              105.83207609932282,
              21.001259798792024
            ],
            [
              105.83212878863107,
              21.001249287803923
            ],
            [
              105.8322903385433,
              21.001269131303978
            ],
            [
              105.83233508808551,
              21.00127462901981
            ],
            [
              105.83241502249385,
              21.001269996597085
            ],
            [
              105.8326301884714,
              21.001223926563075
            ],
            [
              105.83293674184321,
              21.001131716694573
            ],
            [
              105.83307034112472,
              21.001091529603702
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Tôn Thất Tùng",
          "maTaiSan": "04.O17.DOPP.1328",
          "duongKinh": 100,
          "chieudaiQL": 387,
          "chatLieu": "G",
          "from_latitude": 21.001927515642304,
          "to_latitude": 21.001091529603702,
          "to_longitude": 105.83307034112472,
          "from_longitude": 105.82948904185264
        }
      },
      {
        "id": 1325,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83180682127869,
            21.001309944155825,
            105.83180682591762,
            21.001309974888198
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83180682127869,
              21.001309974888198
            ],
            [
              105.83180682591762,
              21.001309944155825
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Tôn Thất Tùng",
          "maTaiSan": "04.O17.DOPP.1329",
          "duongKinh": 100,
          "chieudaiQL": 179.22,
          "chatLieu": "G",
          "from_latitude": 21.001309974888198,
          "to_latitude": 21.001309944155825,
          "to_longitude": 105.83180682591762,
          "from_longitude": 105.83180682127869
        }
      },
      {
        "id": 1326,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.81413548771556,
            21.021815843822985,
            105.81437609155154,
            21.021841020168313
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.81413548771556,
              21.021815843822985
            ],
            [
              105.81437609155154,
              21.021841020168313
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Thành Công",
          "maTaiSan": "04.O9C.DOPP.1330",
          "duongKinh": 100,
          "chieudaiQL": 25.17,
          "chatLieu": "G",
          "from_latitude": 21.021815843822985,
          "to_latitude": 21.021841020168313,
          "to_longitude": 105.81437609155154,
          "from_longitude": 105.81413548771556
        }
      },
      {
        "id": 1327,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82320540674057,
            21.01627387325708,
            105.825610587556,
            21.020335234519607
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82320540674057,
              21.01627387325708
            ],
            [
              105.82345697178843,
              21.016450607649862
            ],
            [
              105.82345699400177,
              21.016450623800427
            ],
            [
              105.82352011107422,
              21.01649496476045
            ],
            [
              105.82352013811212,
              21.016494983597315
            ],
            [
              105.82365508464409,
              21.01658978597553
            ],
            [
              105.82365504260733,
              21.016589838566183
            ],
            [
              105.8236575741437,
              21.016591535213898
            ],
            [
              105.82367024067925,
              21.016611965383806
            ],
            [
              105.82378104376734,
              21.016790702142444
            ],
            [
              105.8237878791397,
              21.01680187175913
            ],
            [
              105.82379663944305,
              21.016815857346074
            ],
            [
              105.82387372505525,
              21.016940203339736
            ],
            [
              105.8239891550878,
              21.017162093554838
            ],
            [
              105.82400082750542,
              21.017184530060586
            ],
            [
              105.82435855215518,
              21.01787216626089
            ],
            [
              105.82452249032433,
              21.01826800088989
            ],
            [
              105.82459114941246,
              21.01848678529793
            ],
            [
              105.82466747277337,
              21.018638243984288
            ],
            [
              105.82479314855641,
              21.01888729084086
            ],
            [
              105.82491609676681,
              21.019110301778742
            ],
            [
              105.82506373027329,
              21.019378075979223
            ],
            [
              105.8251482409027,
              21.01953617856748
            ],
            [
              105.82541144034106,
              21.01995082622538
            ],
            [
              105.82541403685478,
              21.019955924980035
            ],
            [
              105.825610587556,
              21.020335234519607
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D200_Hoàng Cầu",
          "maTaiSan": "04.O12.DOPP.1331",
          "duongKinh": 200,
          "chieudaiQL": 520.79,
          "chatLieu": "G-CI",
          "from_latitude": 21.01627387325708,
          "to_latitude": 21.020335234519607,
          "to_longitude": 105.825610587556,
          "from_longitude": 105.82320540674057
        }
      },
      {
        "id": 1328,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82037347408583,
            21.00468054982351,
            105.82043342565248,
            21.004717360037002
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82037347408583,
              21.00468054982351
            ],
            [
              105.82040222369572,
              21.0046982021318
            ],
            [
              105.82043342565248,
              21.004717360037002
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D110_Thịnh Quang",
          "maTaiSan": "04.O11.DOPP.1332",
          "duongKinh": 110,
          "chieudaiQL": 7.45,
          "chatLieu": "PVC",
          "from_latitude": 21.00468054982351,
          "to_latitude": 21.004717360037002,
          "to_longitude": 105.82043342565248,
          "from_longitude": 105.82037347408583
        }
      },
      {
        "id": 1329,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.8413120773898,
            21.016756417472433,
            105.841337584238,
            21.01884960210782
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.841337584238,
              21.016756417472433
            ],
            [
              105.84133555779017,
              21.017196169876218
            ],
            [
              105.84132318571093,
              21.01784891732567
            ],
            [
              105.84132310110631,
              21.017853384182764
            ],
            [
              105.84132037477491,
              21.017997055313387
            ],
            [
              105.84132173115195,
              21.018059650099996
            ],
            [
              105.84132331251924,
              21.018088444259398
            ],
            [
              105.84133136046573,
              21.018233217321974
            ],
            [
              105.84131477278449,
              21.01857518376766
            ],
            [
              105.84131421833955,
              21.01858660866932
            ],
            [
              105.84131229109018,
              21.018749087263778
            ],
            [
              105.84131614598839,
              21.018755664436654
            ],
            [
              105.84131616330785,
              21.018762352718024
            ],
            [
              105.84131223790375,
              21.018771329395605
            ],
            [
              105.8413120773898,
              21.018771695994825
            ],
            [
              105.84131217678711,
              21.01884960210782
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Lê Duẩn",
          "maTaiSan": "04.O16A.DOPP.1333",
          "duongKinh": 100,
          "chieudaiQL": 242.95,
          "chatLieu": "G",
          "from_latitude": 21.016756417472433,
          "to_latitude": 21.01884960210782,
          "to_longitude": 105.84131217678711,
          "from_longitude": 105.841337584238
        }
      },
      {
        "id": 1330,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.84117475184638,
            21.01884960210782,
            105.84131217678711,
            21.018878369402234
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.84117636565284,
              21.018878369402234
            ],
            [
              105.8411759688288,
              21.018871934996636
            ],
            [
              105.84117475184638,
              21.0188521929445
            ],
            [
              105.84131217678711,
              21.01884960210782
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Lê Duẩn",
          "maTaiSan": "04.O16A.DOPP.1334",
          "duongKinh": 100,
          "chieudaiQL": 22.27,
          "chatLieu": "G",
          "from_latitude": 21.018878369402234,
          "to_latitude": 21.01884960210782,
          "to_longitude": 105.84131217678711,
          "from_longitude": 105.84117636565284
        }
      },
      {
        "id": 1331,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83585713957972,
            21.023265787449898,
            105.83586675491013,
            21.023418399996725
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83585713957972,
              21.023418399996725
            ],
            [
              105.83586085008211,
              21.023373384066748
            ],
            [
              105.83586121147296,
              21.0233688408826
            ],
            [
              105.83586120642146,
              21.02336879755269
            ],
            [
              105.8358611984891,
              21.02336875514016
            ],
            [
              105.83586675491013,
              21.02330059316313
            ],
            [
              105.83586197508866,
              21.023265787449898
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D150_Ngách 33, Văn Chương 2",
          "maTaiSan": "04.O14.DOPP.1335",
          "duongKinh": 150,
          "chieudaiQL": 112,
          "chatLieu": "G-DI",
          "from_latitude": 21.023418399996725,
          "to_latitude": 21.023265787449898,
          "to_longitude": 105.83586197508866,
          "from_longitude": 105.83585713957972
        }
      },
      {
        "id": 1332,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83364268866967,
            21.025744649345786,
            105.83479775731873,
            21.02620083162179
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83364268866967,
              21.02620083162179
            ],
            [
              105.83365649545273,
              21.026195485500423
            ],
            [
              105.83392321589487,
              21.026092217391028
            ],
            [
              105.83419757416306,
              21.025985992188225
            ],
            [
              105.8342033372679,
              21.02598836649314
            ],
            [
              105.8342051195462,
              21.02598346950448
            ],
            [
              105.83420772017992,
              21.025982838942564
            ],
            [
              105.83444653354678,
              21.025924930421873
            ],
            [
              105.8345100513665,
              21.02590430524714
            ],
            [
              105.83459494424265,
              21.025855056770638
            ],
            [
              105.83460964330212,
              21.02584652955747
            ],
            [
              105.83472553653715,
              21.025779297436213
            ],
            [
              105.83473670824354,
              21.025773937969486
            ],
            [
              105.83479775731873,
              21.025744649345786
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D200_Ngõ Thông Phong, Tôn Đức Thắng",
          "maTaiSan": "04.O14.DOPP.1336",
          "duongKinh": 200,
          "chieudaiQL": 131.44,
          "chatLieu": "G-DI",
          "from_latitude": 21.02620083162179,
          "to_latitude": 21.025744649345786,
          "to_longitude": 105.83479775731873,
          "from_longitude": 105.83364268866967
        }
      },
      {
        "id": 1333,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.83620598295107,
            21.0190773807927,
            105.8362078494594,
            21.019132125052725
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.83620598295107,
              21.0190773807927
            ],
            [
              105.83620602329664,
              21.01907857826932
            ],
            [
              105.83620730892102,
              21.019116268000737
            ],
            [
              105.8362077309911,
              21.01912864822294
            ],
            [
              105.8362078494594,
              21.019132125052725
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D200_Ngõ Lệnh Cư",
          "maTaiSan": "04.O16A.DOPP.1337",
          "duongKinh": 200,
          "chieudaiQL": 6.06,
          "chatLieu": "G",
          "from_latitude": 21.0190773807927,
          "to_latitude": 21.019132125052725,
          "to_longitude": 105.8362078494594,
          "from_longitude": 105.83620598295107
        }
      },
      {
        "id": 1334,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.80510626385797,
            21.01853950694598,
            105.80600679393554,
            21.01917108329937
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.80510626385797,
              21.01917108329937
            ],
            [
              105.80511405897018,
              21.019158777843177
            ],
            [
              105.8051800030083,
              21.019050270393596
            ],
            [
              105.80520850147678,
              21.019018973663776
            ],
            [
              105.80522222943158,
              21.01901678401924
            ],
            [
              105.80526985701258,
              21.019011149480317
            ],
            [
              105.80531646038781,
              21.01901083651268
            ],
            [
              105.80539357390082,
              21.018912251766487
            ],
            [
              105.80541512768143,
              21.018898120431395
            ],
            [
              105.80542345517746,
              21.01890094604569
            ],
            [
              105.80552399631739,
              21.018950120835594
            ],
            [
              105.80560278620351,
              21.01889754229066
            ],
            [
              105.80562457915265,
              21.01888784029525
            ],
            [
              105.8057067218029,
              21.018918511117796
            ],
            [
              105.80573421444574,
              21.018917259247377
            ],
            [
              105.80577671625872,
              21.018866136704563
            ],
            [
              105.80583056503461,
              21.018782087561952
            ],
            [
              105.80585994299429,
              21.01872290627758
            ],
            [
              105.80588978256665,
              21.018678151853894
            ],
            [
              105.80595851417822,
              21.01860303950403
            ],
            [
              105.80599994113086,
              21.01855189944066
            ],
            [
              105.80600679393554,
              21.01853950694598
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D90_ngõ 103_Pháo Đài Láng",
          "maTaiSan": "04.O9A.DOPP.134",
          "duongKinh": 90,
          "chieudaiQL": 120,
          "chatLieu": "HDPE",
          "from_latitude": 21.01917108329937,
          "to_latitude": 21.01853950694598,
          "to_longitude": 105.80600679393554,
          "from_longitude": 105.80510626385797
        }
      },
      {
        "id": 1335,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82262885772036,
            21.008344069223178,
            105.82318096087661,
            21.009837058189834
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.8231250371851,
              21.008344069223178
            ],
            [
              105.823044806037,
              21.008514996940963
            ],
            [
              105.82294350532656,
              21.008618944790076
            ],
            [
              105.8227779262409,
              21.00876507523772
            ],
            [
              105.82276915783106,
              21.008787803456233
            ],
            [
              105.82276037173857,
              21.008801635506867
            ],
            [
              105.82275009831986,
              21.00882579506099
            ],
            [
              105.82269142499182,
              21.008906859117364
            ],
            [
              105.82265574871633,
              21.008952410333226
            ],
            [
              105.8226546180682,
              21.00895373722059
            ],
            [
              105.822642453953,
              21.008990234020274
            ],
            [
              105.82264348975647,
              21.009003538970607
            ],
            [
              105.82264477592192,
              21.009020050973945
            ],
            [
              105.82264750380635,
              21.009046451944286
            ],
            [
              105.82262885772036,
              21.00918992562403
            ],
            [
              105.82263163060223,
              21.00919358843183
            ],
            [
              105.82263498336641,
              21.009196718311436
            ],
            [
              105.82263939380374,
              21.009201742980142
            ],
            [
              105.82268071524663,
              21.00924881915595
            ],
            [
              105.82274723484664,
              21.009323263742303
            ],
            [
              105.82299378017451,
              21.009603392764724
            ],
            [
              105.82303080619785,
              21.00964961517985
            ],
            [
              105.823178573529,
              21.009834094508385
            ],
            [
              105.82318096087661,
              21.009837058189834
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 252/53, Tây Sơn",
          "maTaiSan": "04.O11.DOPP.1061.1",
          "duongKinh": 100,
          "chieudaiQL": "",
          "chatLieu": "G",
          "from_latitude": 21.008344069223178,
          "to_latitude": 21.009837058189834,
          "to_longitude": 105.82318096087661,
          "from_longitude": 105.8231250371851
        }
      },
      {
        "id": 1336,
        "type": "Feature",
        "geometry": {
          "bbox": [
            105.82139859078215,
            21.008536609884683,
            105.82262885772036,
            21.009266201617564
          ],
          "type": "LineString",
          "coordinates": [
            [
              105.82262885772036,
              21.00918992562403
            ],
            [
              105.82253607691325,
              21.009266201617564
            ],
            [
              105.82252575934595,
              21.009259185773768
            ],
            [
              105.8223697983776,
              21.00911197645508
            ],
            [
              105.82227070991975,
              21.009004628900048
            ],
            [
              105.82225775041255,
              21.008990784527796
            ],
            [
              105.82223446556887,
              21.008967573898946
            ],
            [
              105.82211881026393,
              21.00885929106655
            ],
            [
              105.82201552255194,
              21.008750719892596
            ],
            [
              105.82195732152654,
              21.008702142736325
            ],
            [
              105.82191035420415,
              21.008649296843362
            ],
            [
              105.82179880815447,
              21.008545501034362
            ],
            [
              105.8217892232476,
              21.008536609884683
            ],
            [
              105.82177989286683,
              21.00854661844394
            ],
            [
              105.82174467030559,
              21.008584040342196
            ],
            [
              105.82153092651829,
              21.00881007444109
            ],
            [
              105.82152317438417,
              21.008817940137952
            ],
            [
              105.82150790067072,
              21.008833265771685
            ],
            [
              105.82140543695986,
              21.00893136314716
            ],
            [
              105.82140608325724,
              21.00894382095443
            ],
            [
              105.82139859078215,
              21.008970769592242
            ]
          ]
        },
        "properties": {
          "tenDoiTuon": "D100_Ngõ 252/53, Tây Sơn",
          "maTaiSan": "04.O11.DOPP.1061.2",
          "duongKinh": 100,
          "chieudaiQL": "",
          "chatLieu": "G",
          "from_latitude": 21.00918992562403,
          "to_latitude": 21.008970769592242,
          "to_longitude": 105.82139859078215,
          "from_longitude": 105.82262885772036
        }
      }
    ]
  }