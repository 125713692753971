export const lightData ={
  "type": "FeatureCollection",
  "features": [
    {
      "id": 0,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83311592060252,
          21.016378815900893
        ]
      },
      "properties": {
        "tenDoiTuon": "TQ 29-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Quan Trạm",
        "tuDien": "TQ1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TQ",
        "diaChiLapD": "",
        "longitude": 105.83311592060252,
        "latitude": 21.016378815900893,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83365504462817,
          21.016674261994357
        ]
      },
      "properties": {
        "tenDoiTuon": "TQ 27",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Quan Trạm",
        "tuDien": "TQ1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TQ",
        "diaChiLapD": "",
        "longitude": 105.83365504462817,
        "latitude": 21.016674261994357,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83392862994361,
          21.016714322437178
        ]
      },
      "properties": {
        "tenDoiTuon": "TQ 17",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Thổ Quan",
        "tuDien": "TQ1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TQ",
        "diaChiLapD": "",
        "longitude": 105.83392862994361,
        "latitude": 21.016714322437178,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83407346923356,
          21.01655408060214
        ]
      },
      "properties": {
        "tenDoiTuon": "TQ 18-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 102 Thổ Quan",
        "tuDien": "TQ1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TQ",
        "diaChiLapD": "",
        "longitude": 105.83407346923356,
        "latitude": 21.01655408060214,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83391253668819,
          21.016468952057526
        ]
      },
      "properties": {
        "tenDoiTuon": "TQ 18-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 102 Thổ Quan",
        "tuDien": "TQ1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TQ",
        "diaChiLapD": "",
        "longitude": 105.83391253668819,
        "latitude": 21.016468952057526,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8341914864281,
          21.016498997431817
        ]
      },
      "properties": {
        "tenDoiTuon": "TQ 18",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Thổ Quan",
        "tuDien": "TQ1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TQ",
        "diaChiLapD": "",
        "longitude": 105.8341914864281,
        "latitude": 21.016498997431817,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8343738766444,
          21.01681447349588
        ]
      },
      "properties": {
        "tenDoiTuon": "TQ 18-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 107",
        "tuDien": "TQ1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TQ",
        "diaChiLapD": "",
        "longitude": 105.8343738766444,
        "latitude": 21.01681447349588,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83424513060989,
          21.017009767868462
        ]
      },
      "properties": {
        "tenDoiTuon": "TQ 18-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 107",
        "tuDien": "TQ1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TQ",
        "diaChiLapD": "",
        "longitude": 105.83424513060989,
        "latitude": 21.017009767868462,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 8,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83433632571803,
          21.01714997905459
        ]
      },
      "properties": {
        "tenDoiTuon": "TQ 18-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 107",
        "tuDien": "TQ1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TQ",
        "diaChiLapD": "",
        "longitude": 105.83433632571803,
        "latitude": 21.01714997905459,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 9,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83430279808532,
          21.016671758218354
        ]
      },
      "properties": {
        "tenDoiTuon": "TQ 18-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 107",
        "tuDien": "TQ1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TQ",
        "diaChiLapD": "",
        "longitude": 105.83430279808532,
        "latitude": 21.016671758218354,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 10,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83451335150986,
          21.016979722597092
        ]
      },
      "properties": {
        "tenDoiTuon": "TQ 18-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 107",
        "tuDien": "TQ1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TQ",
        "diaChiLapD": "",
        "longitude": 105.83451335150986,
        "latitude": 21.016979722597092,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 11,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83469037730167,
          21.01703480558985
        ]
      },
      "properties": {
        "tenDoiTuon": "TQ 18-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 107",
        "tuDien": "TQ1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TQ",
        "diaChiLapD": "",
        "longitude": 105.83469037730167,
        "latitude": 21.01703480558985,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 12,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83441142757076,
          21.016353778066836
        ]
      },
      "properties": {
        "tenDoiTuon": "TQ 19",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Thổ Quan",
        "tuDien": "TQ1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TQ",
        "diaChiLapD": "",
        "longitude": 105.83441142757076,
        "latitude": 21.016353778066836,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 13,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83438996989084,
          21.015988225245792
        ]
      },
      "properties": {
        "tenDoiTuon": "TQ 19-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 114 Thổ Quan",
        "tuDien": "TQ1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TQ",
        "diaChiLapD": "",
        "longitude": 105.83438996989084,
        "latitude": 21.015988225245792,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 14,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83422903735445,
          21.016358785632836
        ]
      },
      "properties": {
        "tenDoiTuon": "TQ 19-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 114 Thổ Quan",
        "tuDien": "TQ1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TQ",
        "diaChiLapD": "",
        "longitude": 105.83422903735445,
        "latitude": 21.016358785632836,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 15,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83413247783083,
          21.01620355098862
        ]
      },
      "properties": {
        "tenDoiTuon": "TQ 19-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 114 Thổ Quan",
        "tuDien": "TQ1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TQ",
        "diaChiLapD": "",
        "longitude": 105.83413247783083,
        "latitude": 21.01620355098862,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 16,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83399836738084,
          21.016278664546235
        ]
      },
      "properties": {
        "tenDoiTuon": "TQ 19-8",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 114 Thổ Quan",
        "tuDien": "TQ1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TQ",
        "diaChiLapD": "",
        "longitude": 105.83399836738084,
        "latitude": 21.016278664546235,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 17,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83413247783083,
          21.016048316181994
        ]
      },
      "properties": {
        "tenDoiTuon": "TQ 19-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 114 Thổ Quan",
        "tuDien": "TQ1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TQ",
        "diaChiLapD": "",
        "longitude": 105.83413247783083,
        "latitude": 21.016048316181994,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 18,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83425585944084,
          21.015782914363687
        ]
      },
      "properties": {
        "tenDoiTuon": "TQ 19-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 114 Thổ Quan",
        "tuDien": "TQ1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TQ",
        "diaChiLapD": "",
        "longitude": 105.83425585944084,
        "latitude": 21.015782914363687,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 19,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83415929991723,
          21.01579793712111
        ]
      },
      "properties": {
        "tenDoiTuon": "TQ 19-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 114 Thổ Quan",
        "tuDien": "TQ1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TQ",
        "diaChiLapD": "",
        "longitude": 105.83415929991723,
        "latitude": 21.01579793712111,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 20,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83404128272268,
          21.01562767912005
        ]
      },
      "properties": {
        "tenDoiTuon": "TQ 19-7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 114 Thổ Quan",
        "tuDien": "TQ1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TQ",
        "diaChiLapD": "",
        "longitude": 105.83404128272268,
        "latitude": 21.01562767912005,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 21,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83469574172612,
          21.01608336921708
        ]
      },
      "properties": {
        "tenDoiTuon": "TQ 20",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Thổ Quan",
        "tuDien": "TQ1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TQ",
        "diaChiLapD": "",
        "longitude": 105.83469574172612,
        "latitude": 21.01608336921708,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 22,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83453480918078,
          21.016483974745015
        ]
      },
      "properties": {
        "tenDoiTuon": "TQ 21",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 119 Thổ Quan",
        "tuDien": "TQ1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TQ",
        "diaChiLapD": "",
        "longitude": 105.83453480918078,
        "latitude": 21.016483974745015,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 23,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83483521659161,
          21.016403853725688
        ]
      },
      "properties": {
        "tenDoiTuon": "TQ 22",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 119 Thổ Quan",
        "tuDien": "TQ1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TQ",
        "diaChiLapD": "",
        "longitude": 105.83483521659161,
        "latitude": 21.016403853725688,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 24,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83506588656523,
          21.016574110840644
        ]
      },
      "properties": {
        "tenDoiTuon": "TQ 23",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 119 Thổ Quan",
        "tuDien": "TQ1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TQ",
        "diaChiLapD": "",
        "longitude": 105.83506588656523,
        "latitude": 21.016574110840644,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 25,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83538775164698,
          21.016338755365414
        ]
      },
      "properties": {
        "tenDoiTuon": "TQ 25",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 119 Thổ Quan",
        "tuDien": "TQ1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TQ",
        "diaChiLapD": "",
        "longitude": 105.83538775164698,
        "latitude": 21.016338755365414,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 26,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83524827678151,
          21.01658913351835
        ]
      },
      "properties": {
        "tenDoiTuon": "TQ 23-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 119 Thổ Quan",
        "tuDien": "TQ1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TQ",
        "diaChiLapD": "",
        "longitude": 105.83524827678151,
        "latitude": 21.01658913351835,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 27,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83558623510072,
          21.016587881630898
        ]
      },
      "properties": {
        "tenDoiTuon": "TQ 25-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 119 Thổ Quan",
        "tuDien": "TQ1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TQ",
        "diaChiLapD": "",
        "longitude": 105.83558623510072,
        "latitude": 21.016587881630898,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 28,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83513025958695,
          21.016714322437178
        ]
      },
      "properties": {
        "tenDoiTuon": "TQ 24",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 119 Thổ Quan",
        "tuDien": "TQ1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TQ",
        "diaChiLapD": "",
        "longitude": 105.83513025958695,
        "latitude": 21.016714322437178,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 29,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83521072585515,
          21.016874564099194
        ]
      },
      "properties": {
        "tenDoiTuon": "TQ 24-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 119 Thổ Quan",
        "tuDien": "TQ1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TQ",
        "diaChiLapD": "",
        "longitude": 105.83521072585515,
        "latitude": 21.016874564099194,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 30,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83386962134634,
          21.01715498659465
        ]
      },
      "properties": {
        "tenDoiTuon": "TQ 15",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 79 Thổ Quan",
        "tuDien": "TQ1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TQ",
        "diaChiLapD": "",
        "longitude": 105.83386962134634,
        "latitude": 21.01715498659465,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 31,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83399836738084,
          21.01730521271445
        ]
      },
      "properties": {
        "tenDoiTuon": "TQ 16",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 79 Thổ Quan",
        "tuDien": "TQ1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TQ",
        "diaChiLapD": "",
        "longitude": 105.83399836738084,
        "latitude": 21.01730521271445,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 32,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83414320666179,
          21.01737531818552
        ]
      },
      "properties": {
        "tenDoiTuon": "TQ 16-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 79 Thổ Quan",
        "tuDien": "TQ1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TQ",
        "diaChiLapD": "",
        "longitude": 105.83414320666179,
        "latitude": 21.01737531818552,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 33,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83427195269628,
          21.01726515243035
        ]
      },
      "properties": {
        "tenDoiTuon": "TQ 16-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 79 Thổ Quan",
        "tuDien": "TQ1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TQ",
        "diaChiLapD": "",
        "longitude": 105.83427195269628,
        "latitude": 21.01726515243035,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 34,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83440606314628,
          21.01721507706074
        ]
      },
      "properties": {
        "tenDoiTuon": "TQ 16-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 79 Thổ Quan",
        "tuDien": "TQ1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TQ",
        "diaChiLapD": "",
        "longitude": 105.83440606314628,
        "latitude": 21.01721507706074,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 35,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8345509024362,
          21.017235107210443
        ]
      },
      "properties": {
        "tenDoiTuon": "TQ 16-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 79 Thổ Quan",
        "tuDien": "TQ1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TQ",
        "diaChiLapD": "",
        "longitude": 105.8345509024362,
        "latitude": 21.017235107210443,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 36,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83441679198621,
          21.017345272987786
        ]
      },
      "properties": {
        "tenDoiTuon": "TQ 16-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 79 Thổ Quan",
        "tuDien": "TQ1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TQ",
        "diaChiLapD": "",
        "longitude": 105.83441679198621,
        "latitude": 21.017345272987786,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 37,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83442752080823,
          21.0175305516125
        ]
      },
      "properties": {
        "tenDoiTuon": "TQ 16-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 79 Thổ Quan",
        "tuDien": "TQ1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TQ",
        "diaChiLapD": "",
        "longitude": 105.83442752080823,
        "latitude": 21.0175305516125,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 38,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83466891963074,
          21.017545574191367
        ]
      },
      "properties": {
        "tenDoiTuon": "TQ 16-7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 79 Thổ Quan",
        "tuDien": "TQ1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TQ",
        "diaChiLapD": "",
        "longitude": 105.83466891963074,
        "latitude": 21.017545574191367,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 39,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83499078471253,
          21.01742038597034
        ]
      },
      "properties": {
        "tenDoiTuon": "TQ 16-8",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 79 Thổ Quan",
        "tuDien": "TQ1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TQ",
        "diaChiLapD": "",
        "longitude": 105.83499078471253,
        "latitude": 21.01742038597034,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 40,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83510880190704,
          21.017365303120005
        ]
      },
      "properties": {
        "tenDoiTuon": "TQ 16-9",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 79 Thổ Quan",
        "tuDien": "TQ1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TQ",
        "diaChiLapD": "",
        "longitude": 105.83510880190704,
        "latitude": 21.017365303120005,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 41,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83341364581462,
          21.01709990364629
        ]
      },
      "properties": {
        "tenDoiTuon": "TQ 2-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Thổ Quan",
        "tuDien": "TQ1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TQ",
        "diaChiLapD": "",
        "longitude": 105.83341364581462,
        "latitude": 21.01709990364629,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 42,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83356384952002,
          21.01720005444607
        ]
      },
      "properties": {
        "tenDoiTuon": "TQ 2-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 77 Thổ Quan",
        "tuDien": "TQ1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TQ",
        "diaChiLapD": "",
        "longitude": 105.83356384952002,
        "latitude": 21.01720005444607,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 43,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83356921393552,
          21.017370310652815
        ]
      },
      "properties": {
        "tenDoiTuon": "TQ 2-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 77 Thổ Quan",
        "tuDien": "TQ1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TQ",
        "diaChiLapD": "",
        "longitude": 105.83356921393552,
        "latitude": 21.017370310652815,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 44,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8337033243855,
          21.017370310652815
        ]
      },
      "properties": {
        "tenDoiTuon": "TQ 2-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 77 Thổ Quan",
        "tuDien": "TQ1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TQ",
        "diaChiLapD": "",
        "longitude": 105.8337033243855,
        "latitude": 21.017370310652815,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 45,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83362822253278,
          21.017530551610818
        ]
      },
      "properties": {
        "tenDoiTuon": "TQ 2-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 77 Thổ Quan",
        "tuDien": "TQ1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TQ",
        "diaChiLapD": "",
        "longitude": 105.83362822253278,
        "latitude": 21.017530551610818,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 46,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83333854396191,
          21.017195046908352
        ]
      },
      "properties": {
        "tenDoiTuon": "TQ 02",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Thổ Quan",
        "tuDien": "TQ1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TQ",
        "diaChiLapD": "",
        "longitude": 105.83333854396191,
        "latitude": 21.017195046908352,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 47,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83306495863746,
          21.017185031830742
        ]
      },
      "properties": {
        "tenDoiTuon": "TQ 3-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 60 Thổ Quan",
        "tuDien": "TQ1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TQ",
        "diaChiLapD": "",
        "longitude": 105.83306495863746,
        "latitude": 21.017185031830742,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 48,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83292548377202,
          21.017300205179428
        ]
      },
      "properties": {
        "tenDoiTuon": "TQ 3-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 60 Thổ Quan",
        "tuDien": "TQ1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TQ",
        "diaChiLapD": "",
        "longitude": 105.83292548377202,
        "latitude": 21.017300205179428,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 49,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83319906908746,
          21.017325242852053
        ]
      },
      "properties": {
        "tenDoiTuon": "TQ 3-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Thổ Quan",
        "tuDien": "TQ1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TQ",
        "diaChiLapD": "",
        "longitude": 105.83319906908746,
        "latitude": 21.017325242852053,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 50,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83312933165921,
          21.017470461271873
        ]
      },
      "properties": {
        "tenDoiTuon": "TQ 03",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Thổ Quan",
        "tuDien": "TQ1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TQ",
        "diaChiLapD": "",
        "longitude": 105.83312933165921,
        "latitude": 21.017470461271873,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 51,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83316151817009,
          21.017590641925565
        ]
      },
      "properties": {
        "tenDoiTuon": "TQ 04",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Thổ Quan",
        "tuDien": "TQ1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TQ",
        "diaChiLapD": "",
        "longitude": 105.83316151817009,
        "latitude": 21.017590641925565,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 52,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83325271326923,
          21.01776089768558
        ]
      },
      "properties": {
        "tenDoiTuon": "TQ 4-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Thổ Quan",
        "tuDien": "TQ1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TQ",
        "diaChiLapD": "",
        "longitude": 105.83325271326923,
        "latitude": 21.01776089768558,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 53,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83345119674097,
          21.017615679549426
        ]
      },
      "properties": {
        "tenDoiTuon": "TQ 4-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 41 Thổ Quan",
        "tuDien": "TQ1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TQ",
        "diaChiLapD": "",
        "longitude": 105.83345119674097,
        "latitude": 21.017615679549426,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 54,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8334672899964,
          21.017800957836478
        ]
      },
      "properties": {
        "tenDoiTuon": "TQ 4-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 39 Thổ Quan",
        "tuDien": "TQ1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TQ",
        "diaChiLapD": "",
        "longitude": 105.8334672899964,
        "latitude": 21.017800957836478,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 55,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83339755255916,
          21.018021288472088
        ]
      },
      "properties": {
        "tenDoiTuon": "TQ 05",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Thổ Quan",
        "tuDien": "TQ1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TQ",
        "diaChiLapD": "",
        "longitude": 105.83339755255916,
        "latitude": 21.018021288472088,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 56,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83351020533824,
          21.017886085620727
        ]
      },
      "properties": {
        "tenDoiTuon": "TQ 5-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 35+37 Thổ Quan",
        "tuDien": "TQ1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TQ",
        "diaChiLapD": "",
        "longitude": 105.83351020533824,
        "latitude": 21.017886085620727,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 57,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83357994277546,
          21.01797121335638
        ]
      },
      "properties": {
        "tenDoiTuon": "TQ 5-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 35+37 Thổ Quan",
        "tuDien": "TQ1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TQ",
        "diaChiLapD": "",
        "longitude": 105.83357994277546,
        "latitude": 21.01797121335638,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 58,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8336765022991,
          21.017720837523882
        ]
      },
      "properties": {
        "tenDoiTuon": "TQ 5-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 35+37 Thổ Quan",
        "tuDien": "TQ1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TQ",
        "diaChiLapD": "",
        "longitude": 105.8336765022991,
        "latitude": 21.017720837523882,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 59,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83382670600452,
          21.017770912724515
        ]
      },
      "properties": {
        "tenDoiTuon": "TQ 5-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 35+37 Thổ Quan",
        "tuDien": "TQ1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TQ",
        "diaChiLapD": "",
        "longitude": 105.83382670600452,
        "latitude": 21.017770912724515,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 60,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8336228581173,
          21.018091393606515
        ]
      },
      "properties": {
        "tenDoiTuon": "TQ 5-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 35+37 Thổ Quan",
        "tuDien": "TQ1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TQ",
        "diaChiLapD": "",
        "longitude": 105.8336228581173,
        "latitude": 21.018091393606515,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 61,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83330635744204,
          21.018090141731687
        ]
      },
      "properties": {
        "tenDoiTuon": "TQ 5-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 58 Thổ Quan",
        "tuDien": "TQ1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TQ",
        "diaChiLapD": "",
        "longitude": 105.83330635744204,
        "latitude": 21.018090141731687,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 62,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8334672899964,
          21.018216581264216
        ]
      },
      "properties": {
        "tenDoiTuon": "TQ 6-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Thổ Quan",
        "tuDien": "TQ1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TQ",
        "diaChiLapD": "",
        "longitude": 105.8334672899964,
        "latitude": 21.018216581264216,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 63,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83354775626461,
          21.018391843808118
        ]
      },
      "properties": {
        "tenDoiTuon": "TQ 06",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Thổ Quan",
        "tuDien": "TQ1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TQ",
        "diaChiLapD": "",
        "longitude": 105.83354775626461,
        "latitude": 21.018391843808118,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 64,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83360676486186,
          21.0183367613166
        ]
      },
      "properties": {
        "tenDoiTuon": "TQ 6-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 25 Thổ Quan",
        "tuDien": "TQ1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TQ",
        "diaChiLapD": "",
        "longitude": 105.83360676486186,
        "latitude": 21.0183367613166,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 65,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83378915507815,
          21.01823661127939
        ]
      },
      "properties": {
        "tenDoiTuon": "TQ 6-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 25 Thổ Quan",
        "tuDien": "TQ1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TQ",
        "diaChiLapD": "",
        "longitude": 105.83378915507815,
        "latitude": 21.01823661127939,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 66,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83399300295639,
          21.01813646117492
        ]
      },
      "properties": {
        "tenDoiTuon": "TQ 6-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 25 Thổ Quan",
        "tuDien": "TQ1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TQ",
        "diaChiLapD": "",
        "longitude": 105.83399300295639,
        "latitude": 21.01813646117492,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 67,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8341914864281,
          21.01804131851434
        ]
      },
      "properties": {
        "tenDoiTuon": "TQ 6-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 25 Thổ Quan",
        "tuDien": "TQ1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TQ",
        "diaChiLapD": "",
        "longitude": 105.8341914864281,
        "latitude": 21.01804131851434,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 68,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83433096129356,
          21.01799625091633
        ]
      },
      "properties": {
        "tenDoiTuon": "TQ 6-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 25 Thổ Quan",
        "tuDien": "TQ1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TQ",
        "diaChiLapD": "",
        "longitude": 105.83433096129356,
        "latitude": 21.01799625091633,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 69,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83363358694827,
          21.01866224847196
        ]
      },
      "properties": {
        "tenDoiTuon": "TQ 07",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Thổ Quan",
        "tuDien": "TQ1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TQ",
        "diaChiLapD": "",
        "longitude": 105.83363358694827,
        "latitude": 21.01866224847196,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 70,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83337073047277,
          21.018722338330665
        ]
      },
      "properties": {
        "tenDoiTuon": "TQ 09",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 24 Thổ Quan",
        "tuDien": "TQ1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TQ",
        "diaChiLapD": "",
        "longitude": 105.83337073047277,
        "latitude": 21.018722338330665,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 71,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83317224700106,
          21.01872734581794
        ]
      },
      "properties": {
        "tenDoiTuon": "TQ 10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 24 Thổ Quan",
        "tuDien": "TQ1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TQ",
        "diaChiLapD": "",
        "longitude": 105.83317224700106,
        "latitude": 21.01872734581794,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 72,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83291475493205,
          21.018707315868674
        ]
      },
      "properties": {
        "tenDoiTuon": "TQ 11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 24 Thổ Quan",
        "tuDien": "TQ1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TQ",
        "diaChiLapD": "",
        "longitude": 105.83291475493205,
        "latitude": 21.018707315868674,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 73,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83270554262936,
          21.018877570353723
        ]
      },
      "properties": {
        "tenDoiTuon": "TQ 12",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 24 Thổ Quan",
        "tuDien": "TQ1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TQ",
        "diaChiLapD": "",
        "longitude": 105.83270554262936,
        "latitude": 21.018877570353723,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 74,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83266262728752,
          21.018767405709227
        ]
      },
      "properties": {
        "tenDoiTuon": "TQ 11-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 24 Thổ Quan",
        "tuDien": "TQ1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TQ",
        "diaChiLapD": "",
        "longitude": 105.83266262728752,
        "latitude": 21.018767405709227,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 75,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83288793284565,
          21.018567106145998
        ]
      },
      "properties": {
        "tenDoiTuon": "TQ 13-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 24 Thổ Quan",
        "tuDien": "TQ1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TQ",
        "diaChiLapD": "",
        "longitude": 105.83288793284565,
        "latitude": 21.018567106145998,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 76,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83280210216198,
          21.018396851306473
        ]
      },
      "properties": {
        "tenDoiTuon": "TQ 13",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 24 Thổ Quan",
        "tuDien": "TQ1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TQ",
        "diaChiLapD": "",
        "longitude": 105.83280210216198,
        "latitude": 21.018396851306473,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 77,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83278064448207,
          21.018316731314044
        ]
      },
      "properties": {
        "tenDoiTuon": "TQ 13-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 24 Thổ Quan",
        "tuDien": "TQ1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TQ",
        "diaChiLapD": "",
        "longitude": 105.83278064448207,
        "latitude": 21.018316731314044,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 78,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83311860281925,
          21.01833175381621
        ]
      },
      "properties": {
        "tenDoiTuon": "TQ 14",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 24 Thổ Quan",
        "tuDien": "TQ1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TQ",
        "diaChiLapD": "",
        "longitude": 105.83311860281925,
        "latitude": 21.01833175381621,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 79,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83248560149572,
          21.01849700123524
        ]
      },
      "properties": {
        "tenDoiTuon": "TQ 13-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 24 Thổ Quan",
        "tuDien": "TQ1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TQ",
        "diaChiLapD": "",
        "longitude": 105.83248560149572,
        "latitude": 21.01849700123524,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 80,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83243732172939,
          21.01838182881086
        ]
      },
      "properties": {
        "tenDoiTuon": "TQ 13-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 24 Thổ Quan",
        "tuDien": "TQ1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TQ",
        "diaChiLapD": "",
        "longitude": 105.83243732172939,
        "latitude": 21.01838182881086,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 81,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83379988390912,
          21.01860215858903
        ]
      },
      "properties": {
        "tenDoiTuon": "TQ 7-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 15 Thổ Quan",
        "tuDien": "TQ1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TQ",
        "diaChiLapD": "",
        "longitude": 105.83379988390912,
        "latitude": 21.01860215858903,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 82,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8339876385409,
          21.018537061188407
        ]
      },
      "properties": {
        "tenDoiTuon": "7-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 15 Thổ Quan",
        "tuDien": "TQ1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TQ",
        "diaChiLapD": "",
        "longitude": 105.8339876385409,
        "latitude": 21.018537061188407,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 83,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83389644343274,
          21.018572113638463
        ]
      },
      "properties": {
        "tenDoiTuon": "TQ 7-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 15 Thổ Quan",
        "tuDien": "TQ1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TQ",
        "diaChiLapD": "",
        "longitude": 105.83389644343274,
        "latitude": 21.018572113638463,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 84,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83391253668819,
          21.018411873799735
        ]
      },
      "properties": {
        "tenDoiTuon": "TQ 7-7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 15 Thổ Quan",
        "tuDien": "TQ1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TQ",
        "diaChiLapD": "",
        "longitude": 105.83391253668819,
        "latitude": 21.018411873799735,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 85,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8341646643417,
          21.01842188879496
        ]
      },
      "properties": {
        "tenDoiTuon": "TQ 7-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 15 Thổ Quan",
        "tuDien": "TQ1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TQ",
        "diaChiLapD": "",
        "longitude": 105.8341646643417,
        "latitude": 21.01842188879496,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 86,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83433096129356,
          21.018326746315655
        ]
      },
      "properties": {
        "tenDoiTuon": "TQ 7-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 15 Thổ Quan",
        "tuDien": "TQ1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TQ",
        "diaChiLapD": "",
        "longitude": 105.83433096129356,
        "latitude": 21.018326746315655,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 87,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83459918219353,
          21.018326746315655
        ]
      },
      "properties": {
        "tenDoiTuon": "TQ 7-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 15 Thổ Quan",
        "tuDien": "TQ1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TQ",
        "diaChiLapD": "",
        "longitude": 105.83459918219353,
        "latitude": 21.018326746315655,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 88,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83461527544897,
          21.018181528730537
        ]
      },
      "properties": {
        "tenDoiTuon": "TQ 7-8",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 15 Thổ Quan",
        "tuDien": "TQ1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TQ",
        "diaChiLapD": "",
        "longitude": 105.83461527544897,
        "latitude": 21.018181528730537,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 89,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83471719939706,
          21.01805133353363
        ]
      },
      "properties": {
        "tenDoiTuon": "TQ 7-9",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 15 Thổ Quan",
        "tuDien": "TQ1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TQ",
        "diaChiLapD": "",
        "longitude": 105.83471719939706,
        "latitude": 21.01805133353363,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 90,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83372478205642,
          21.018912622723768
        ]
      },
      "properties": {
        "tenDoiTuon": "TQ 08",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Thổ Quan",
        "tuDien": "TQ1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TQ",
        "diaChiLapD": "",
        "longitude": 105.83372478205642,
        "latitude": 21.018912622723768,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 91,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83391790110366,
          21.018872562871497
        ]
      },
      "properties": {
        "tenDoiTuon": "TQ 8-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 1 Thổ Quan",
        "tuDien": "TQ1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TQ",
        "diaChiLapD": "",
        "longitude": 105.83391790110366,
        "latitude": 21.018872562871497,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 92,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8341163845754,
          21.01880245810431
        ]
      },
      "properties": {
        "tenDoiTuon": "TQ 8-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 1 Thổ Quan",
        "tuDien": "TQ1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TQ",
        "diaChiLapD": "",
        "longitude": 105.8341163845754,
        "latitude": 21.01880245810431,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 93,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83432023246262,
          21.01874236827792
        ]
      },
      "properties": {
        "tenDoiTuon": "TQ 8-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 1 Thổ Quan",
        "tuDien": "TQ1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TQ",
        "diaChiLapD": "",
        "longitude": 105.83432023246262,
        "latitude": 21.01874236827792,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 94,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83440606314628,
          21.01866224847196
        ]
      },
      "properties": {
        "tenDoiTuon": "TQ 8-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 1 Thổ Quan",
        "tuDien": "TQ1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TQ",
        "diaChiLapD": "",
        "longitude": 105.83440606314628,
        "latitude": 21.01866224847196,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 95,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83606903272783,
          21.004342645640055
        ]
      },
      "properties": {
        "tenDoiTuon": "KLC3 6-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Nhà C3 Kim Liên",
        "tuDien": "KLC3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Kim Liên C3",
        "diaChiLapD": "",
        "longitude": 105.83606903272783,
        "latitude": 21.004342645640055,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 96,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83632384258911,
          21.004415261186583
        ]
      },
      "properties": {
        "tenDoiTuon": "KLC3 6-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Nhà C3 Kim Liên",
        "tuDien": "KLC3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Kim Liên C3",
        "diaChiLapD": "",
        "longitude": 105.83632384258911,
        "latitude": 21.004415261186583,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 97,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83652500825958,
          21.004465340853862
        ]
      },
      "properties": {
        "tenDoiTuon": "KLC3 6-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Nhà C3 Kim Liên",
        "tuDien": "KLC3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Kim Liên C3",
        "diaChiLapD": "",
        "longitude": 105.83652500825958,
        "latitude": 21.004465340853862,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 98,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82913451663721,
          21.004881940429797
        ]
      },
      "properties": {
        "tenDoiTuon": "KHTL 18",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 43/59 Chùa Bộc",
        "tuDien": "KHTL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KHTL",
        "diaChiLapD": "",
        "longitude": 105.82913451663721,
        "latitude": 21.004881940429797,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 99,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83601840616676,
          21.001687524169466
        ]
      },
      "properties": {
        "tenDoiTuon": "TTBNN/11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Lương Định Của",
        "tuDien": "TTBNN",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 10,
        "tuDien2": "TTBNN",
        "diaChiLapD": "",
        "longitude": 105.83601840616676,
        "latitude": 21.001687524169466,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 100,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83610021353883,
          21.001967975212093
        ]
      },
      "properties": {
        "tenDoiTuon": "TTBNN/10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Lương Định Của",
        "tuDien": "TTBNN",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 10,
        "tuDien2": "TTBNN",
        "diaChiLapD": "",
        "longitude": 105.83610021353883,
        "latitude": 21.001967975212093,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 101,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83603315830936,
          21.002263449848094
        ]
      },
      "properties": {
        "tenDoiTuon": "TTBNN/9",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Lương Định Của",
        "tuDien": "TTBNN",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 10,
        "tuDien2": "TTBNN",
        "diaChiLapD": "",
        "longitude": 105.83603315830936,
        "latitude": 21.002263449848094,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 102,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83610960127491,
          21.00251635564057
        ]
      },
      "properties": {
        "tenDoiTuon": "TTBNN/8",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Lương Định Của",
        "tuDien": "TTBNN",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 10,
        "tuDien2": "TTBNN",
        "diaChiLapD": "",
        "longitude": 105.83610960127491,
        "latitude": 21.00251635564057,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 103,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83610691905817,
          21.002771765014625
        ]
      },
      "properties": {
        "tenDoiTuon": "TTBNN/7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Lương Định Của",
        "tuDien": "TTBNN",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 10,
        "tuDien2": "TTBNN",
        "diaChiLapD": "",
        "longitude": 105.83610691905817,
        "latitude": 21.002771765014625,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 104,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83610826016205,
          21.003079758090504
        ]
      },
      "properties": {
        "tenDoiTuon": "TTBNN/6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Lương Định Của",
        "tuDien": "TTBNN",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 10,
        "tuDien2": "TTBNN",
        "diaChiLapD": "",
        "longitude": 105.83610826016205,
        "latitude": 21.003079758090504,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 105,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83447747709135,
          21.003078506088027
        ]
      },
      "properties": {
        "tenDoiTuon": "TTBNN/1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Phương Mai",
        "tuDien": "TTBNN",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "TTBNN",
        "diaChiLapD": "",
        "longitude": 105.83447747709135,
        "latitude": 21.003078506088027,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 106,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83493077041533,
          21.003191186324056
        ]
      },
      "properties": {
        "tenDoiTuon": "TTBNN/2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Phương Mai",
        "tuDien": "TTBNN",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "TTBNN",
        "diaChiLapD": "",
        "longitude": 105.83493077041533,
        "latitude": 21.003191186324056,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 107,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83524458887386,
          21.00327381844314
        ]
      },
      "properties": {
        "tenDoiTuon": "TTBNN/3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Phương Mai",
        "tuDien": "TTBNN",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "TTBNN",
        "diaChiLapD": "",
        "longitude": 105.83524458887386,
        "latitude": 21.00327381844314,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 108,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.835984878561,
          21.003466626542487
        ]
      },
      "properties": {
        "tenDoiTuon": "TTBNN/5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Phương Mai",
        "tuDien": "TTBNN",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "TTBNN",
        "diaChiLapD": "",
        "longitude": 105.835984878561,
        "latitude": 21.003466626542487,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 109,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83564155580834,
          21.003378986527824
        ]
      },
      "properties": {
        "tenDoiTuon": "TTBNN/4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Phương Mai",
        "tuDien": "TTBNN",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "TTBNN",
        "diaChiLapD": "",
        "longitude": 105.83564155580834,
        "latitude": 21.003378986527824,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 110,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8350917029607,
          21.003446594543203
        ]
      },
      "properties": {
        "tenDoiTuon": "TTBNN/3-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 156 Phương Mai",
        "tuDien": "TTBNN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "TTBNN",
        "diaChiLapD": "",
        "longitude": 105.8350917029607,
        "latitude": 21.003446594543203,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 111,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83485835077038,
          21.003439082543196
        ]
      },
      "properties": {
        "tenDoiTuon": "TTBNN/3-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Trước của nhà C10",
        "tuDien": "TTBNN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "TTBNN",
        "diaChiLapD": "",
        "longitude": 105.83485835077038,
        "latitude": 21.003439082543196,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 112,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83446138383592,
          21.003331410498426
        ]
      },
      "properties": {
        "tenDoiTuon": "TTBNN/3-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Trước của nhà C10",
        "tuDien": "TTBNN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "TTBNN",
        "diaChiLapD": "",
        "longitude": 105.83446138383592,
        "latitude": 21.003331410498426,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 113,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83502732993895,
          21.003656930395774
        ]
      },
      "properties": {
        "tenDoiTuon": "TTBNN/3-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 156 Phương Mai",
        "tuDien": "TTBNN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "TTBNN",
        "diaChiLapD": "",
        "longitude": 105.83502732993895,
        "latitude": 21.003656930395774,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 114,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83530359747115,
          21.003594330470996
        ]
      },
      "properties": {
        "tenDoiTuon": "TTBNN/3-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Trước của nhà C9",
        "tuDien": "TTBNN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "TTBNN",
        "diaChiLapD": "",
        "longitude": 105.83530359747115,
        "latitude": 21.003594330470996,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 115,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83577030183385,
          21.003709514312817
        ]
      },
      "properties": {
        "tenDoiTuon": "TTBNN/3-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Trước của nhà C9",
        "tuDien": "TTBNN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "TTBNN",
        "diaChiLapD": "",
        "longitude": 105.83577030183385,
        "latitude": 21.003709514312817,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 116,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83595403314503,
          21.003193690327983
        ]
      },
      "properties": {
        "tenDoiTuon": "TTBNN/6-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 28A Lương Đình Của",
        "tuDien": "TTBNN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "TTBNN",
        "diaChiLapD": "",
        "longitude": 105.83595403314503,
        "latitude": 21.003193690327983,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 117,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83565362573418,
          21.00310980616229
        ]
      },
      "properties": {
        "tenDoiTuon": "TTBNN/6-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 28A Lương Đình Của",
        "tuDien": "TTBNN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "TTBNN",
        "diaChiLapD": "",
        "longitude": 105.83565362573418,
        "latitude": 21.00310980616229,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 118,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83591514111481,
          21.00285690137635
        ]
      },
      "properties": {
        "tenDoiTuon": "TTBNN/7-7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 28B Lương Đình Của",
        "tuDien": "TTBNN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "TTBNN",
        "diaChiLapD": "",
        "longitude": 105.83591514111481,
        "latitude": 21.00285690137635,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 119,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83561607481681,
          21.00286065739105
        ]
      },
      "properties": {
        "tenDoiTuon": "TTBNN/7-8",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 28B Lương Đình Của",
        "tuDien": "TTBNN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "TTBNN",
        "diaChiLapD": "",
        "longitude": 105.83561607481681,
        "latitude": 21.00286065739105,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 120,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83577030182487,
          21.002654076436098
        ]
      },
      "properties": {
        "tenDoiTuon": "TTBNN/7-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 28C Lương Đình Của",
        "tuDien": "TTBNN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "TTBNN",
        "diaChiLapD": "",
        "longitude": 105.83577030182487,
        "latitude": 21.002654076436098,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 121,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83553694964351,
          21.002756740946303
        ]
      },
      "properties": {
        "tenDoiTuon": "TTBNN/7-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 28C Lương Đình Của",
        "tuDien": "TTBNN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "TTBNN",
        "diaChiLapD": "",
        "longitude": 105.83553694964351,
        "latitude": 21.002756740946303,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 122,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83533578397304,
          21.00268412459274
        ]
      },
      "properties": {
        "tenDoiTuon": "TTBNN/7-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 28C Lương Đình Của",
        "tuDien": "TTBNN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 6,
        "tuDien2": "TTBNN",
        "diaChiLapD": "",
        "longitude": 105.83533578397304,
        "latitude": 21.00268412459274,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 123,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.835861496933,
          21.002260945828553
        ]
      },
      "properties": {
        "tenDoiTuon": "TTBNN/9-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 28D Lương Đình Của",
        "tuDien": "TTBNN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "TTBNN",
        "diaChiLapD": "",
        "longitude": 105.835861496933,
        "latitude": 21.002260945828553,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 124,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83562009812843,
          21.00226595386842
        ]
      },
      "properties": {
        "tenDoiTuon": "TTBNN/9-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 28D Lương Đình Của",
        "tuDien": "TTBNN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "TTBNN",
        "diaChiLapD": "",
        "longitude": 105.83562009812843,
        "latitude": 21.00226595386842,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 125,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83583869817618,
          21.002078152264005
        ]
      },
      "properties": {
        "tenDoiTuon": "TTBNN/10-8",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 30 Lương Đình Của",
        "tuDien": "TTBNN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 6,
        "tuDien2": "TTBNN",
        "diaChiLapD": "",
        "longitude": 105.83583869817618,
        "latitude": 21.002078152264005,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 126,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83588429572576,
          21.001947943012496
        ]
      },
      "properties": {
        "tenDoiTuon": "TTBNN/10-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 30 Lương Đình Của",
        "tuDien": "TTBNN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "TTBNN",
        "diaChiLapD": "",
        "longitude": 105.83588429572576,
        "latitude": 21.001947943012496,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 127,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83563753249672,
          21.001932918860412
        ]
      },
      "properties": {
        "tenDoiTuon": "TTBNN/10-7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 30 Lương Đình Của",
        "tuDien": "TTBNN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "TTBNN",
        "diaChiLapD": "",
        "longitude": 105.83563753249672,
        "latitude": 21.001932918860412,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 128,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83545782448816,
          21.001915390681443
        ]
      },
      "properties": {
        "tenDoiTuon": "TTBNN/10-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 30 Lương Đình Của",
        "tuDien": "TTBNN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "TTBNN",
        "diaChiLapD": "",
        "longitude": 105.83545782448816,
        "latitude": 21.001915390681443,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 129,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83527275207314,
          21.001920398732885
        ]
      },
      "properties": {
        "tenDoiTuon": "TTBNN/10-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 30 Lương Đình Của",
        "tuDien": "TTBNN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "TTBNN",
        "diaChiLapD": "",
        "longitude": 105.83527275207314,
        "latitude": 21.001920398732885,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 130,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8350742686014,
          21.001920398732885
        ]
      },
      "properties": {
        "tenDoiTuon": "TTBNN/10-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 30 Lương Đình Của",
        "tuDien": "TTBNN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "TTBNN",
        "diaChiLapD": "",
        "longitude": 105.8350742686014,
        "latitude": 21.001920398732885,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 131,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83486505629871,
          21.001895358474762
        ]
      },
      "properties": {
        "tenDoiTuon": "TTBNN/10-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 30 Lương Đình Của",
        "tuDien": "TTBNN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "TTBNN",
        "diaChiLapD": "",
        "longitude": 105.83486505629871,
        "latitude": 21.001895358474762,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 132,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83486237409097,
          21.001722580577987
        ]
      },
      "properties": {
        "tenDoiTuon": "TTBNN/10-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 30 Lương Đình Của",
        "tuDien": "TTBNN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "TTBNN",
        "diaChiLapD": "",
        "longitude": 105.83486237409097,
        "latitude": 21.001722580577987,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 133,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83561875703354,
          21.00161991535554
        ]
      },
      "properties": {
        "tenDoiTuon": "TTBNN/11-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 32 Lương Đình Của",
        "tuDien": "TTBNN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "TTBNN",
        "diaChiLapD": "",
        "longitude": 105.83561875703354,
        "latitude": 21.00161991535554,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 134,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83522179009908,
          21.00155856659239
        ]
      },
      "properties": {
        "tenDoiTuon": "TTBNN/11-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 32 Lương Đình Của",
        "tuDien": "TTBNN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "TTBNN",
        "diaChiLapD": "",
        "longitude": 105.83522179009908,
        "latitude": 21.00155856659239,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 135,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83540149809866,
          21.001587362953774
        ]
      },
      "properties": {
        "tenDoiTuon": "TTBNN/11-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 32 Lương Đình Của",
        "tuDien": "TTBNN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 6,
        "tuDien2": "TTBNN",
        "diaChiLapD": "",
        "longitude": 105.83540149809866,
        "latitude": 21.001587362953774,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 136,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8360425460544,
          21.00101268668603
        ]
      },
      "properties": {
        "tenDoiTuon": "TTBNN/17-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 36 Lương Đình Của",
        "tuDien": "TTBNN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "TTBNN",
        "diaChiLapD": "",
        "longitude": 105.8360425460544,
        "latitude": 21.00101268668603,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 137,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83603986384668,
          21.001209253762845
        ]
      },
      "properties": {
        "tenDoiTuon": "TTBNN/17-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 36 Lương Đình Của",
        "tuDien": "TTBNN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "TTBNN",
        "diaChiLapD": "",
        "longitude": 105.83603986384668,
        "latitude": 21.001209253762845,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 138,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83605327489435,
          21.00086244415967
        ]
      },
      "properties": {
        "tenDoiTuon": "TTBNN/17-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 36 Lương Đình Của",
        "tuDien": "TTBNN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "TTBNN",
        "diaChiLapD": "",
        "longitude": 105.83605327489435,
        "latitude": 21.00086244415967,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 139,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83586686136645,
          21.00069717720552
        ]
      },
      "properties": {
        "tenDoiTuon": "TTBNN/17-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 36 Lương Đình Của",
        "tuDien": "TTBNN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "TTBNN",
        "diaChiLapD": "",
        "longitude": 105.83586686136645,
        "latitude": 21.00069717720552,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 140,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8358695435742,
          21.00053691816629
        ]
      },
      "properties": {
        "tenDoiTuon": "TTBNN/17-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 36 Lương Đình Của",
        "tuDien": "TTBNN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "TTBNN",
        "diaChiLapD": "",
        "longitude": 105.8358695435742,
        "latitude": 21.00053691816629,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 141,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83603986384668,
          21.000709697435603
        ]
      },
      "properties": {
        "tenDoiTuon": "TTBNN/17-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 36 Lương Đình Của",
        "tuDien": "TTBNN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "TTBNN",
        "diaChiLapD": "",
        "longitude": 105.83603986384668,
        "latitude": 21.000709697435603,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 142,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83628662707571,
          21.001614907294822
        ]
      },
      "properties": {
        "tenDoiTuon": "TTBNN/12",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 91 Lương Đình Của",
        "tuDien": "TTBNN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "TTBNN",
        "diaChiLapD": "",
        "longitude": 105.83628662707571,
        "latitude": 21.001614907294822,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 143,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83663397314898,
          21.001613655279208
        ]
      },
      "properties": {
        "tenDoiTuon": "TTBNN/13",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 91 Lương Đình Của",
        "tuDien": "TTBNN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "TTBNN",
        "diaChiLapD": "",
        "longitude": 105.83663397314898,
        "latitude": 21.001613655279208,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 144,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83694510939078,
          21.001613655279208
        ]
      },
      "properties": {
        "tenDoiTuon": "TTBNN/14",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 91 Lương Đình Của",
        "tuDien": "TTBNN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "TTBNN",
        "diaChiLapD": "",
        "longitude": 105.83694510939078,
        "latitude": 21.001613655279208,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 145,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83722003581909,
          21.001569834734234
        ]
      },
      "properties": {
        "tenDoiTuon": "TTBNN/15",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 91 Lương Đình Của",
        "tuDien": "TTBNN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "TTBNN",
        "diaChiLapD": "",
        "longitude": 105.83722003581909,
        "latitude": 21.001569834734234,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 146,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8375526297318,
          21.001504729901768
        ]
      },
      "properties": {
        "tenDoiTuon": "TTBNN/16",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 91 Lương Đình Của",
        "tuDien": "TTBNN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "TTBNN",
        "diaChiLapD": "",
        "longitude": 105.8375526297318,
        "latitude": 21.001504729901768,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 147,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83622627738355,
          21.001324439445522
        ]
      },
      "properties": {
        "tenDoiTuon": "TTBNN/12-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 91/2 Lương Đình Của",
        "tuDien": "TTBNN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "BTLT",
        "chieuCao": 6,
        "tuDien2": "TTBNN",
        "diaChiLapD": "",
        "longitude": 105.83622627738355,
        "latitude": 21.001324439445522,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 148,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83628528598082,
          21.001048995273447
        ]
      },
      "properties": {
        "tenDoiTuon": "TTBNN/12-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 91/2 Lương Đình Của",
        "tuDien": "TTBNN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "BTLT",
        "chieuCao": 6,
        "tuDien2": "TTBNN",
        "diaChiLapD": "",
        "longitude": 105.83628528598082,
        "latitude": 21.001048995273447,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 149,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83659374001488,
          21.001124116462062
        ]
      },
      "properties": {
        "tenDoiTuon": "TTBNN/12-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 91/2 Lương Đình Của",
        "tuDien": "TTBNN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "TTBNN",
        "diaChiLapD": "",
        "longitude": 105.83659374001488,
        "latitude": 21.001124116462062,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 150,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83627992156536,
          21.000918785124867
        ]
      },
      "properties": {
        "tenDoiTuon": "TTBNN/12-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 91/2 Lương Đình Của",
        "tuDien": "TTBNN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "TTBNN",
        "diaChiLapD": "",
        "longitude": 105.83627992156536,
        "latitude": 21.000918785124867,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 151,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83647035840487,
          21.000916281081953
        ]
      },
      "properties": {
        "tenDoiTuon": "TTBNN/12-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 91/2 Lương Đình Của",
        "tuDien": "TTBNN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "TTBNN",
        "diaChiLapD": "",
        "longitude": 105.83647035840487,
        "latitude": 21.000916281081953,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 152,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83692633393659,
          21.0013820322539
        ]
      },
      "properties": {
        "tenDoiTuon": "TTBNN/15-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 91/16 Lương Đình Của",
        "tuDien": "TTBNN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "TTBNN",
        "diaChiLapD": "",
        "longitude": 105.83692633393659,
        "latitude": 21.0013820322539,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 153,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83722405913969,
          21.001372016114697
        ]
      },
      "properties": {
        "tenDoiTuon": "TTBNN/15-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 91/30 Lương Đình Của",
        "tuDien": "TTBNN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "TTBNN",
        "diaChiLapD": "",
        "longitude": 105.83722405913969,
        "latitude": 21.001372016114697,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 154,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8348878550825,
          21.00116167704179
        ]
      },
      "properties": {
        "tenDoiTuon": "TTBNN/20",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 120 Trường Chinh",
        "tuDien": "TTBNN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "TTBNN",
        "diaChiLapD": "",
        "longitude": 105.8348878550825,
        "latitude": 21.00116167704179,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 155,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83514802935922,
          21.001284374870828
        ]
      },
      "properties": {
        "tenDoiTuon": "TTBNN/19",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 120 Trường Chinh",
        "tuDien": "TTBNN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "TTBNN",
        "diaChiLapD": "",
        "longitude": 105.83514802935922,
        "latitude": 21.001284374870828,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 156,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83543502572236,
          21.00140957663201
        ]
      },
      "properties": {
        "tenDoiTuon": "TTBNN/18",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 120 Trường Chinh",
        "tuDien": "TTBNN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "TTBNN",
        "diaChiLapD": "",
        "longitude": 105.83543502572236,
        "latitude": 21.00140957663201,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 157,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83575152638866,
          21.00145965730792
        ]
      },
      "properties": {
        "tenDoiTuon": "TTBNN/17",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 120 Trường Chinh",
        "tuDien": "TTBNN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "TTBNN",
        "diaChiLapD": "",
        "longitude": 105.83575152638866,
        "latitude": 21.00145965730792,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 158,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83643146638362,
          21.001860302105996
        ]
      },
      "properties": {
        "tenDoiTuon": "TTBNN/10-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 89 Lương Đình Của",
        "tuDien": "TTBNN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "TTBNN",
        "diaChiLapD": "",
        "longitude": 105.83643146638362,
        "latitude": 21.001860302105996,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 159,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83675199036149,
          21.0018565460661
        ]
      },
      "properties": {
        "tenDoiTuon": "TTBNN/10-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 89 Lương Đình Của",
        "tuDien": "TTBNN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "TTBNN",
        "diaChiLapD": "",
        "longitude": 105.83675199036149,
        "latitude": 21.0018565460661,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 160,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83700680022275,
          21.001949195024476
        ]
      },
      "properties": {
        "tenDoiTuon": "TTBNN/10-8",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 89 Lương Đình Của",
        "tuDien": "TTBNN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "TTBNN",
        "diaChiLapD": "",
        "longitude": 105.83700680022275,
        "latitude": 21.001949195024476,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 161,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83728843217041,
          21.001996771494504
        ]
      },
      "properties": {
        "tenDoiTuon": "TTBNN/10-9",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 89 Lương Đình Của",
        "tuDien": "TTBNN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "TTBNN",
        "diaChiLapD": "",
        "longitude": 105.83728843217041,
        "latitude": 21.001996771494504,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 162,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83709263090643,
          21.001781425246126
        ]
      },
      "properties": {
        "tenDoiTuon": "TTBNN/10-7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 89 Lương Đình Của",
        "tuDien": "TTBNN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "TTBNN",
        "diaChiLapD": "",
        "longitude": 105.83709263090643,
        "latitude": 21.001781425246126,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 163,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83726161007503,
          21.001831505796442
        ]
      },
      "properties": {
        "tenDoiTuon": "TTBNN/10-10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 89 Lương Đình Của",
        "tuDien": "TTBNN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "TTBNN",
        "diaChiLapD": "",
        "longitude": 105.83726161007503,
        "latitude": 21.001831505796442,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 164,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83713018184177,
          21.002055439990034
        ]
      },
      "properties": {
        "tenDoiTuon": "TTBNN/10-11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 89 Lương Đình Của",
        "tuDien": "TTBNN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "TTBNN",
        "diaChiLapD": "",
        "longitude": 105.83713018184177,
        "latitude": 21.002055439990034,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 165,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83718784933515,
          21.002171877030563
        ]
      },
      "properties": {
        "tenDoiTuon": "TTBNN/10-12",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 89 Lương Đình Của",
        "tuDien": "TTBNN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "TTBNN",
        "diaChiLapD": "",
        "longitude": 105.83718784933515,
        "latitude": 21.002171877030563,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 166,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83689682966042,
          21.002335890342163
        ]
      },
      "properties": {
        "tenDoiTuon": "TTBNN/10-14",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 89 Lương Đình Của",
        "tuDien": "TTBNN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "TTBNN",
        "diaChiLapD": "",
        "longitude": 105.83689682966042,
        "latitude": 21.002335890342163,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 167,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83702825789368,
          21.002253257703764
        ]
      },
      "properties": {
        "tenDoiTuon": "TTBNN/10-13",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 89 Lương Đình Của",
        "tuDien": "TTBNN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "TTBNN",
        "diaChiLapD": "",
        "longitude": 105.83702825789368,
        "latitude": 21.002253257703764,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 168,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83659843390089,
          21.002885345360173
        ]
      },
      "properties": {
        "tenDoiTuon": "TTBNN/7-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 180 Phương Mai",
        "tuDien": "TTBNN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "TTBNN",
        "diaChiLapD": "",
        "longitude": 105.83659843390089,
        "latitude": 21.002885345360173,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 169,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83644957130834,
          21.002877833331084
        ]
      },
      "properties": {
        "tenDoiTuon": "TTBNN/7-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 180 Phương Mai",
        "tuDien": "TTBNN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "TTBNN",
        "diaChiLapD": "",
        "longitude": 105.83644957130834,
        "latitude": 21.002877833331084,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 170,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83628193324361,
          21.00284152518861
        ]
      },
      "properties": {
        "tenDoiTuon": "TTBNN/7-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 180 Phương Mai",
        "tuDien": "TTBNN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "TTBNN",
        "diaChiLapD": "",
        "longitude": 105.83628193324361,
        "latitude": 21.00284152518861,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 171,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83505482261774,
          21.002698796542564
        ]
      },
      "properties": {
        "tenDoiTuon": "TTBNN/3-9",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 135 Phương Mai",
        "tuDien": "TTBNN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "TTBNN",
        "diaChiLapD": "",
        "longitude": 105.83505482261774,
        "latitude": 21.002698796542564,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 172,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83500654285143,
          21.00259362797858
        ]
      },
      "properties": {
        "tenDoiTuon": "TTBNN/3-10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 135 Phương Mai",
        "tuDien": "TTBNN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "TTBNN",
        "diaChiLapD": "",
        "longitude": 105.83500654285143,
        "latitude": 21.00259362797858,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 173,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83483756368283,
          21.002741364749895
        ]
      },
      "properties": {
        "tenDoiTuon": "TTBNN/3-8",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 135 Phương Mai",
        "tuDien": "TTBNN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "TTBNN",
        "diaChiLapD": "",
        "longitude": 105.83483756368283,
        "latitude": 21.002741364749895,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 174,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83513797109366,
          21.002856549250076
        ]
      },
      "properties": {
        "tenDoiTuon": "TTBNN/3-7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 135 Phương Mai",
        "tuDien": "TTBNN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "TTBNN",
        "diaChiLapD": "",
        "longitude": 105.83513797109366,
        "latitude": 21.002856549250076,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 175,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83411336725113,
          21.002924157502978
        ]
      },
      "properties": {
        "tenDoiTuon": "TTBNN/1-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 218/281 Phương Mai",
        "tuDien": "TTBNN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TTBNN",
        "diaChiLapD": "",
        "longitude": 105.83411336725113,
        "latitude": 21.002924157502978,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 176,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83434940164022,
          21.003034333848134
        ]
      },
      "properties": {
        "tenDoiTuon": "TTBNN/1-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 218/281 Phương Mai",
        "tuDien": "TTBNN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "TTBNN",
        "diaChiLapD": "",
        "longitude": 105.83434940164022,
        "latitude": 21.003034333848134,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 177,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83401412551977,
          21.00308441397799
        ]
      },
      "properties": {
        "tenDoiTuon": "TTBNN/1-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 180 Phương Mai",
        "tuDien": "TTBNN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "TTBNN",
        "diaChiLapD": "",
        "longitude": 105.83401412551977,
        "latitude": 21.00308441397799,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 178,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83390147274069,
          21.0029416855626
        ]
      },
      "properties": {
        "tenDoiTuon": "TTBNN/1-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 180 Phương Mai",
        "tuDien": "TTBNN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "TTBNN",
        "diaChiLapD": "",
        "longitude": 105.83390147274069,
        "latitude": 21.0029416855626,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 179,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8339685279612,
          21.002601140021117
        ]
      },
      "properties": {
        "tenDoiTuon": "TTBNN/21-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 167 Phương Mai",
        "tuDien": "TTBNN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "TTBNN",
        "diaChiLapD": "",
        "longitude": 105.8339685279612,
        "latitude": 21.002601140021117,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 180,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83409459178795,
          21.002688780491717
        ]
      },
      "properties": {
        "tenDoiTuon": "TTBNN/21-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 167 Phương Mai",
        "tuDien": "TTBNN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "TTBNN",
        "diaChiLapD": "",
        "longitude": 105.83409459178795,
        "latitude": 21.002688780491717,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 181,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83430648629839,
          21.002733852714364
        ]
      },
      "properties": {
        "tenDoiTuon": "TTBNN/21-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 167 Phương Mai",
        "tuDien": "TTBNN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "TTBNN",
        "diaChiLapD": "",
        "longitude": 105.83430648629839,
        "latitude": 21.002733852714364,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 182,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83452642744105,
          21.00277391690079
        ]
      },
      "properties": {
        "tenDoiTuon": "TTBNN/21",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 167 Phương Mai",
        "tuDien": "TTBNN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "TTBNN",
        "diaChiLapD": "",
        "longitude": 105.83452642744105,
        "latitude": 21.00277391690079,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 183,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83476246183012,
          21.00291163745779
        ]
      },
      "properties": {
        "tenDoiTuon": "TTBNN/21-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Nhà Đ8 N167/17 Phương Mai",
        "tuDien": "TTBNN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TTBNN",
        "diaChiLapD": "",
        "longitude": 105.83476246183012,
        "latitude": 21.00291163745779,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 184,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83456666057513,
          21.00263619621586
        ]
      },
      "properties": {
        "tenDoiTuon": "TTBNN/22",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 167 Phương Mai",
        "tuDien": "TTBNN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "TTBNN",
        "diaChiLapD": "",
        "longitude": 105.83456666057513,
        "latitude": 21.00263619621586,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 185,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83465785568326,
          21.00265873233599
        ]
      },
      "properties": {
        "tenDoiTuon": "TTBNN/22-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Nhà Đ8 N167/17 Phương Mai",
        "tuDien": "TTBNN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TTBNN",
        "diaChiLapD": "",
        "longitude": 105.83465785568326,
        "latitude": 21.00265873233599,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 186,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83463639801236,
          21.002403322768497
        ]
      },
      "properties": {
        "tenDoiTuon": "TTBNN/23",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 167 Phương Mai",
        "tuDien": "TTBNN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "TTBNN",
        "diaChiLapD": "",
        "longitude": 105.83463639801236,
        "latitude": 21.002403322768497,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 187,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83479733054874,
          21.002180465043377
        ]
      },
      "properties": {
        "tenDoiTuon": "TTBNN/25",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 167 Phương Mai",
        "tuDien": "TTBNN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "TTBNN",
        "diaChiLapD": "",
        "longitude": 105.83479733054874,
        "latitude": 21.002180465043377,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 188,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83510042016731,
          21.002230545459803
        ]
      },
      "properties": {
        "tenDoiTuon": "TTBNN/26",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 167 Phương Mai",
        "tuDien": "TTBNN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "TTBNN",
        "diaChiLapD": "",
        "longitude": 105.83510042016731,
        "latitude": 21.002230545459803,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 189,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83458543603831,
          21.002195489169722
        ]
      },
      "properties": {
        "tenDoiTuon": "TTBNN/24",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 167/18 Phương Mai",
        "tuDien": "TTBNN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "TTBNN",
        "diaChiLapD": "",
        "longitude": 105.83458543603831,
        "latitude": 21.002195489169722,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 190,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83464176242785,
          21.002017703573213
        ]
      },
      "properties": {
        "tenDoiTuon": "TTBNN/27",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 167/18 Phương Mai",
        "tuDien": "TTBNN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TTBNN",
        "diaChiLapD": "",
        "longitude": 105.83464176242785,
        "latitude": 21.002017703573213,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 191,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83435476606469,
          21.002330706243
        ]
      },
      "properties": {
        "tenDoiTuon": "TTBNN/23-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 167/12 Phương Mai",
        "tuDien": "TTBNN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "TTBNN",
        "diaChiLapD": "",
        "longitude": 105.83435476606469,
        "latitude": 21.002330706243,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 192,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83396316354572,
          21.002478443274637
        ]
      },
      "properties": {
        "tenDoiTuon": "TTBNN/23-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 167/12 Phương Mai",
        "tuDien": "TTBNN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "TTBNN",
        "diaChiLapD": "",
        "longitude": 105.83396316354572,
        "latitude": 21.002478443274637,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 193,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83363056962402,
          21.00239080268048
        ]
      },
      "properties": {
        "tenDoiTuon": "TTBNN/23-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 167/12 Phương Mai",
        "tuDien": "TTBNN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TTBNN",
        "diaChiLapD": "",
        "longitude": 105.83363056962402,
        "latitude": 21.00239080268048,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 194,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83380491321705,
          21.00244589106013
        ]
      },
      "properties": {
        "tenDoiTuon": "TTBNN/23-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 167/12 Phương Mai",
        "tuDien": "TTBNN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "TTBNN",
        "diaChiLapD": "",
        "longitude": 105.83380491321705,
        "latitude": 21.00244589106013,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 195,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83405167644611,
          21.002248073601724
        ]
      },
      "properties": {
        "tenDoiTuon": "TTBNN/23-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 167/12 Phương Mai",
        "tuDien": "TTBNN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "TTBNN",
        "diaChiLapD": "",
        "longitude": 105.83405167644611,
        "latitude": 21.002248073601724,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 196,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83442450349294,
          21.00199266333056
        ]
      },
      "properties": {
        "tenDoiTuon": "TTBNN/23-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 167/12 Phương Mai",
        "tuDien": "TTBNN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "TTBNN",
        "diaChiLapD": "",
        "longitude": 105.83442450349294,
        "latitude": 21.00199266333056,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 197,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83290000278947,
          21.016985982030356
        ]
      },
      "properties": {
        "tenDoiTuon": "TQ 1-7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 62 Thổ Quan",
        "tuDien": "TQ1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TQ",
        "diaChiLapD": "",
        "longitude": 105.83290000278947,
        "latitude": 21.016985982030356,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 198,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84061269480775,
          21.008271346346742
        ]
      },
      "properties": {
        "tenDoiTuon": "LKL 09",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Kim Hoa",
        "tuDien": "LKL3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Làng Kim Liên 3",
        "diaChiLapD": "",
        "longitude": 105.84061269480775,
        "latitude": 21.008271346346742,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 199,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84041421133603,
          21.0084341009959
        ]
      },
      "properties": {
        "tenDoiTuon": "08",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Kim Hoa",
        "tuDien": "LKL3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Làng Kim Liên 3",
        "diaChiLapD": "",
        "longitude": 105.84041421133603,
        "latitude": 21.0084341009959,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 200,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84010307509422,
          21.008772129315243
        ]
      },
      "properties": {
        "tenDoiTuon": "07",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Kim Hoa",
        "tuDien": "LKL3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Làng Kim Liên 3",
        "diaChiLapD": "",
        "longitude": 105.84010307509422,
        "latitude": 21.008772129315243,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 201,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83979998547566,
          21.00904505532518
        ]
      },
      "properties": {
        "tenDoiTuon": "06",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Kim Hoa",
        "tuDien": "LKL3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Làng Kim Liên 3",
        "diaChiLapD": "",
        "longitude": 105.83979998547566,
        "latitude": 21.00904505532518,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 202,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83952371794346,
          21.0093129730335
        ]
      },
      "properties": {
        "tenDoiTuon": "05",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Kim Hoa",
        "tuDien": "LKL3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Làng Kim Liên 3",
        "diaChiLapD": "",
        "longitude": 105.83952371794346,
        "latitude": 21.0093129730335,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 203,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83923672158035,
          21.009610937022465
        ]
      },
      "properties": {
        "tenDoiTuon": "04",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Kim Hoa",
        "tuDien": "LKL3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Làng Kim Liên 3",
        "diaChiLapD": "",
        "longitude": 105.83923672158035,
        "latitude": 21.009610937022465,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 204,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83892290314876,
          21.009937695163742
        ]
      },
      "properties": {
        "tenDoiTuon": "03",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Kim Hoa",
        "tuDien": "LKL3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Làng Kim Liên 3",
        "diaChiLapD": "",
        "longitude": 105.83892290314876,
        "latitude": 21.009937695163742,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 205,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83865736445654,
          21.010188084061827
        ]
      },
      "properties": {
        "tenDoiTuon": "02",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Kim Hoa",
        "tuDien": "LKL3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Làng Kim Liên 3",
        "diaChiLapD": "",
        "longitude": 105.83865736445654,
        "latitude": 21.010188084061827,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 206,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83837036809338,
          21.010383387110785
        ]
      },
      "properties": {
        "tenDoiTuon": "LKL3 01",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Kim Hoa",
        "tuDien": "LKL3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Làng Kim Liên 3",
        "diaChiLapD": "",
        "longitude": 105.83837036809338,
        "latitude": 21.010383387110785,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 207,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8331360371516,
          21.006496057180765
        ]
      },
      "properties": {
        "tenDoiTuon": "B4KL 1-7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 97 Phạm Ngọc Thạch",
        "tuDien": "B4KL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "B4 Kim Liên",
        "diaChiLapD": "",
        "longitude": 105.8331360371516,
        "latitude": 21.006496057180765,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 208,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84080313166521,
          21.00829513357456
        ]
      },
      "properties": {
        "tenDoiTuon": "9-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 2 Phố Kim Hoa",
        "tuDien": "LKL3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Làng Kim Liên 3",
        "diaChiLapD": "",
        "longitude": 105.84080313166521,
        "latitude": 21.00829513357456,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 209,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8405912371458,
          21.008407809871347
        ]
      },
      "properties": {
        "tenDoiTuon": "9-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 12 Phố Kim Hoa",
        "tuDien": "LKL3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Làng Kim Liên 3",
        "diaChiLapD": "",
        "longitude": 105.8405912371458,
        "latitude": 21.008407809871347,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 210,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84089164455663,
          21.008467903861376
        ]
      },
      "properties": {
        "tenDoiTuon": "9-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 2 Phố Kim Hoa",
        "tuDien": "LKL3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Làng Kim Liên 3",
        "diaChiLapD": "",
        "longitude": 105.84089164455663,
        "latitude": 21.008467903861376,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 211,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84036727269154,
          21.008660705249284
        ]
      },
      "properties": {
        "tenDoiTuon": "7-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 26 Phố Kim Hoa",
        "tuDien": "LKL3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Làng Kim Liên 3",
        "diaChiLapD": "",
        "longitude": 105.84036727269154,
        "latitude": 21.008660705249284,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 212,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84054832180398,
          21.00885976616147
        ]
      },
      "properties": {
        "tenDoiTuon": "7-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 26 Phố Kim Hoa",
        "tuDien": "LKL3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Làng Kim Liên 3",
        "diaChiLapD": "",
        "longitude": 105.84054832180398,
        "latitude": 21.00885976616147,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 213,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84073205312413,
          21.00907134633661
        ]
      },
      "properties": {
        "tenDoiTuon": "7-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 26 Phố Kim Hoa",
        "tuDien": "LKL3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Làng Kim Liên 3",
        "diaChiLapD": "",
        "longitude": 105.84073205312413,
        "latitude": 21.00907134633661,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 214,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80151019972277,
          21.027718017730166
        ]
      },
      "properties": {
        "tenDoiTuon": "VH1 12-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Nghách 1194/61 Đường Láng",
        "tuDien": "VHA1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VH1",
        "diaChiLapD": "",
        "longitude": 105.80151019972277,
        "latitude": 21.027718017730166,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 215,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80136204577056,
          21.02775258970782
        ]
      },
      "properties": {
        "tenDoiTuon": "VH1 12-7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Nghách 1194/61 Đường Láng",
        "tuDien": "VHA1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VH1",
        "diaChiLapD": "",
        "longitude": 105.80136204577056,
        "latitude": 21.02775258970782,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 216,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80118565242428,
          21.02778060753003
        ]
      },
      "properties": {
        "tenDoiTuon": "VH1 12-18",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Nghách 1194/61 Đường Láng",
        "tuDien": "VHA1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VH1",
        "diaChiLapD": "",
        "longitude": 105.80118565242428,
        "latitude": 21.02778060753003,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 217,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80152365025644,
          21.027927264709447
        ]
      },
      "properties": {
        "tenDoiTuon": "VH1 12-8",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Nghách 1194/61 Đường Láng",
        "tuDien": "VHA1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VH1",
        "diaChiLapD": "",
        "longitude": 105.80152365025644,
        "latitude": 21.027927264709447,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 218,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80171824974025,
          21.028168037809046
        ]
      },
      "properties": {
        "tenDoiTuon": "VH1 12-9",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Nghách 1194/61 Đường Láng",
        "tuDien": "VHA1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VH1",
        "diaChiLapD": "",
        "longitude": 105.80171824974025,
        "latitude": 21.028168037809046,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 219,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80162505368142,
          21.028233723808484
        ]
      },
      "properties": {
        "tenDoiTuon": "VH1 12-13",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Nghách 1194/61 Đường Láng",
        "tuDien": "VHA1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VH1",
        "diaChiLapD": "",
        "longitude": 105.80162505368142,
        "latitude": 21.028233723808484,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 220,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80150367301307,
          21.028161778846922
        ]
      },
      "properties": {
        "tenDoiTuon": "VH1 12-14",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Nghách 1194/61 Đường Láng",
        "tuDien": "VHA1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VH1",
        "diaChiLapD": "",
        "longitude": 105.80150367301307,
        "latitude": 21.028161778846922,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 221,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80133067053285,
          21.028279447298736
        ]
      },
      "properties": {
        "tenDoiTuon": "VH1 12-15",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Nghách 1194/61 Đường Láng",
        "tuDien": "VHA1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VH1",
        "diaChiLapD": "",
        "longitude": 105.80133067053285,
        "latitude": 21.028279447298736,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 222,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80157475155417,
          21.028572366234158
        ]
      },
      "properties": {
        "tenDoiTuon": "VH1 12-16",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Nghách 1194/61 Đường Láng",
        "tuDien": "VHA1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "VH1",
        "diaChiLapD": "",
        "longitude": 105.80157475155417,
        "latitude": 21.028572366234158,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 223,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80186443012505,
          21.02879643631325
        ]
      },
      "properties": {
        "tenDoiTuon": "VH1 12-17",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Nghách 1194/61 Đường Láng",
        "tuDien": "VHA1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VH1",
        "diaChiLapD": "",
        "longitude": 105.80186443012505,
        "latitude": 21.02879643631325,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 224,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80172629636346,
          21.027721147221005
        ]
      },
      "properties": {
        "tenDoiTuon": "VH1 12-19",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Nghách 1194/61 Đường Láng",
        "tuDien": "VHA1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "VH1",
        "diaChiLapD": "",
        "longitude": 105.80172629636346,
        "latitude": 21.027721147221005,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 225,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.801844313558,
          21.027891391412222
        ]
      },
      "properties": {
        "tenDoiTuon": "VH1 12-19-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Nghách 1194/61 Đường Láng",
        "tuDien": "VHA1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VH1",
        "diaChiLapD": "",
        "longitude": 105.801844313558,
        "latitude": 21.027891391412222,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 226,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80182285588708,
          21.02808917486116
        ]
      },
      "properties": {
        "tenDoiTuon": "VH1 12-10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Nghách 1194/61 Đường Láng",
        "tuDien": "VHA1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VH1",
        "diaChiLapD": "",
        "longitude": 105.80182285588708,
        "latitude": 21.02808917486116,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 227,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80195778903239,
          21.028006008089708
        ]
      },
      "properties": {
        "tenDoiTuon": "VH1 12-11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Nghách 1194/61 Đường Láng",
        "tuDien": "VHA1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VH1",
        "diaChiLapD": "",
        "longitude": 105.80195778903239,
        "latitude": 21.028006008089708,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 228,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80193838529615,
          21.028206444710037
        ]
      },
      "properties": {
        "tenDoiTuon": "VH1 12-12",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Nghách 1194/61 Đường Láng",
        "tuDien": "VHA1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VH1",
        "diaChiLapD": "",
        "longitude": 105.80193838529615,
        "latitude": 21.028206444710037,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 229,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84087823351793,
          21.009326744490814
        ]
      },
      "properties": {
        "tenDoiTuon": "7-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 26 Phố Kim Hoa",
        "tuDien": "LKL3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Làng Kim Liên 3",
        "diaChiLapD": "",
        "longitude": 105.84087823351793,
        "latitude": 21.009326744490814,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 230,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84075216970015,
          21.009444427807384
        ]
      },
      "properties": {
        "tenDoiTuon": "7-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 26 Phố Kim Hoa",
        "tuDien": "LKL3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Làng Kim Liên 3",
        "diaChiLapD": "",
        "longitude": 105.84075216970015,
        "latitude": 21.009444427807384,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 231,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84054832181296,
          21.009587150002755
        ]
      },
      "properties": {
        "tenDoiTuon": "7-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 26 Phố Kim Hoa",
        "tuDien": "LKL3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Làng Kim Liên 3",
        "diaChiLapD": "",
        "longitude": 105.84054832181296,
        "latitude": 21.009587150002755,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 232,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84016208370946,
          21.00898871742807
        ]
      },
      "properties": {
        "tenDoiTuon": "6-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 50 phố Kim Hoa",
        "tuDien": "LKL3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Làng Kim Liên 3",
        "diaChiLapD": "",
        "longitude": 105.84016208370946,
        "latitude": 21.00898871742807,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 233,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84040616473075,
          21.009209061082213
        ]
      },
      "properties": {
        "tenDoiTuon": "6-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 50 phố Kim Hoa",
        "tuDien": "LKL3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Làng Kim Liên 3",
        "diaChiLapD": "",
        "longitude": 105.84040616473075,
        "latitude": 21.009209061082213,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 234,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84021841009898,
          21.009158983007076
        ]
      },
      "properties": {
        "tenDoiTuon": "6-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 50 phố Kim Hoa",
        "tuDien": "LKL3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Làng Kim Liên 3",
        "diaChiLapD": "",
        "longitude": 105.84021841009898,
        "latitude": 21.009158983007076,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 235,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84039811810753,
          21.009414381012082
        ]
      },
      "properties": {
        "tenDoiTuon": "6-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 50 phố Kim Hoa",
        "tuDien": "LKL3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Làng Kim Liên 3",
        "diaChiLapD": "",
        "longitude": 105.84039811810753,
        "latitude": 21.009414381012082,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 236,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8401692833268,
          21.009595584181326
        ]
      },
      "properties": {
        "tenDoiTuon": "6-7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 50 phố Kim Hoa",
        "tuDien": "LKL3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Làng Kim Liên 3",
        "diaChiLapD": "",
        "longitude": 105.8401692833268,
        "latitude": 21.009595584181326,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 237,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83977852783167,
          21.009216572790056
        ]
      },
      "properties": {
        "tenDoiTuon": "6-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 70 phố Kim Hoa",
        "tuDien": "LKL3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Làng Kim Liên 3",
        "diaChiLapD": "",
        "longitude": 105.83977852783167,
        "latitude": 21.009216572790056,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 238,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8399555536235,
          21.00932924839094
        ]
      },
      "properties": {
        "tenDoiTuon": "6-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 70 phố Kim Hoa",
        "tuDien": "LKL3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Làng Kim Liên 3",
        "diaChiLapD": "",
        "longitude": 105.8399555536235,
        "latitude": 21.00932924839094,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 239,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83977852783167,
          21.009537072053703
        ]
      },
      "properties": {
        "tenDoiTuon": "5-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 78 phố Kim Hoa",
        "tuDien": "LKL3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Làng Kim Liên 3",
        "diaChiLapD": "",
        "longitude": 105.83977852783167,
        "latitude": 21.009537072053703,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 240,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83962564190952,
          21.00938433420903
        ]
      },
      "properties": {
        "tenDoiTuon": "5-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 78 phố Kim Hoa",
        "tuDien": "LKL3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Làng Kim Liên 3",
        "diaChiLapD": "",
        "longitude": 105.83962564190952,
        "latitude": 21.00938433420903,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 241,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84006284198708,
          21.009915160142967
        ]
      },
      "properties": {
        "tenDoiTuon": "5-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Kim Hoa",
        "tuDien": "LKL3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Làng Kim Liên 3",
        "diaChiLapD": "",
        "longitude": 105.84006284198708,
        "latitude": 21.009915160142967,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 242,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83990459164944,
          21.009734879849088
        ]
      },
      "properties": {
        "tenDoiTuon": "5-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 78 phố Kim Hoa",
        "tuDien": "LKL3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Làng Kim Liên 3",
        "diaChiLapD": "",
        "longitude": 105.83990459164944,
        "latitude": 21.009734879849088,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 243,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83979730329486,
          21.008675728722334
        ]
      },
      "properties": {
        "tenDoiTuon": "6-9",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 45 phố Kim Hoa",
        "tuDien": "LKL3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Làng Kim Liên 3",
        "diaChiLapD": "",
        "longitude": 105.83979730329486,
        "latitude": 21.008675728722334,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 244,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83995018920801,
          21.008813443833116
        ]
      },
      "properties": {
        "tenDoiTuon": "6-8",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 45 phố Kim Hoa",
        "tuDien": "LKL3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Làng Kim Liên 3",
        "diaChiLapD": "",
        "longitude": 105.83995018920801,
        "latitude": 21.008813443833116,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 245,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83942984065453,
          21.00916899862194
        ]
      },
      "properties": {
        "tenDoiTuon": "5-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Kim Hoa",
        "tuDien": "LKL3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Làng Kim Liên 3",
        "diaChiLapD": "",
        "longitude": 105.83942984065453,
        "latitude": 21.00916899862194,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 246,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8390623780232,
          21.009439420007627
        ]
      },
      "properties": {
        "tenDoiTuon": "4-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Kim Hoa",
        "tuDien": "LKL3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Làng Kim Liên 3",
        "diaChiLapD": "",
        "longitude": 105.8390623780232,
        "latitude": 21.009439420007627,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 247,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83909456452508,
          21.00923910791721
        ]
      },
      "properties": {
        "tenDoiTuon": "4-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Kim Hoa",
        "tuDien": "LKL3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Làng Kim Liên 3",
        "diaChiLapD": "",
        "longitude": 105.83909456452508,
        "latitude": 21.00923910791721,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 248,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83886389455148,
          21.009707337006883
        ]
      },
      "properties": {
        "tenDoiTuon": "3-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 119 phố Kim Hoa",
        "tuDien": "LKL3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Làng Kim Liên 3",
        "diaChiLapD": "",
        "longitude": 105.83886389455148,
        "latitude": 21.009707337006883,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 249,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83321650342879,
          21.00637837153603
        ]
      },
      "properties": {
        "tenDoiTuon": "B4KL 7-8",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 97 Phạm Ngọc Thạch",
        "tuDien": "B4KL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "B4 Kim Liên",
        "diaChiLapD": "",
        "longitude": 105.83321650342879,
        "latitude": 21.00637837153603,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 250,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80209881051809,
          21.028753249648627
        ]
      },
      "properties": {
        "tenDoiTuon": "VH1 12-20",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 2 khu TTĐHGT",
        "tuDien": "VHA1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VH1",
        "diaChiLapD": "",
        "longitude": 105.80209881051809,
        "latitude": 21.028753249648627,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 251,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80215607567402,
          21.02883868412693
        ]
      },
      "properties": {
        "tenDoiTuon": "VH1 12-21",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 2 khu TTĐHGT",
        "tuDien": "VHA1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "VH1",
        "diaChiLapD": "",
        "longitude": 105.80215607567402,
        "latitude": 21.02883868412693,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 252,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80224861188604,
          21.02898013597431
        ]
      },
      "properties": {
        "tenDoiTuon": "VH1 12-22",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 2 khu TTĐHGT",
        "tuDien": "VHA1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "VH1",
        "diaChiLapD": "",
        "longitude": 105.80224861188604,
        "latitude": 21.02898013597431,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 253,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80236931128833,
          21.028906280602353
        ]
      },
      "properties": {
        "tenDoiTuon": "VH1 12-23",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 2 khu TTĐHGT",
        "tuDien": "VHA1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "VH1",
        "diaChiLapD": "",
        "longitude": 105.80236931128833,
        "latitude": 21.028906280602353,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 254,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8383247705438,
          21.01060873646403
        ]
      },
      "properties": {
        "tenDoiTuon": "LKL3 1-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Đình Kim Liên",
        "tuDien": "LKL3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Làng Kim Liên 3",
        "diaChiLapD": "",
        "longitude": 105.8383247705438,
        "latitude": 21.01060873646403,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 255,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83901946267237,
          21.01000029242809
        ]
      },
      "properties": {
        "tenDoiTuon": "3-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 110 phố Kim Hoa",
        "tuDien": "LKL3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Làng Kim Liên 3",
        "diaChiLapD": "",
        "longitude": 105.83901946267237,
        "latitude": 21.01000029242809,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 256,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8391696663778,
          21.01013550242794
        ]
      },
      "properties": {
        "tenDoiTuon": "3-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 110 phố Kim Hoa",
        "tuDien": "LKL3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Làng Kim Liên 3",
        "diaChiLapD": "",
        "longitude": 105.8391696663778,
        "latitude": 21.01013550242794,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 257,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83935742100958,
          21.01032830166158
        ]
      },
      "properties": {
        "tenDoiTuon": "3-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 110 phố Kim Hoa",
        "tuDien": "LKL3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Làng Kim Liên 3",
        "diaChiLapD": "",
        "longitude": 105.83935742100958,
        "latitude": 21.01032830166158,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 258,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83955054005682,
          21.01039841041119
        ]
      },
      "properties": {
        "tenDoiTuon": "3-7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Kim Hoa",
        "tuDien": "LKL3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Làng Kim Liên 3",
        "diaChiLapD": "",
        "longitude": 105.83955054005682,
        "latitude": 21.01039841041119,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 259,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8389872761705,
          21.010188084061827
        ]
      },
      "properties": {
        "tenDoiTuon": "3-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 116 phố Kim Hoa",
        "tuDien": "LKL3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Làng Kim Liên 3",
        "diaChiLapD": "",
        "longitude": 105.8389872761705,
        "latitude": 21.010188084061827,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 260,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83926086148594,
          21.010393402644283
        ]
      },
      "properties": {
        "tenDoiTuon": "3-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 116 phố Kim Hoa",
        "tuDien": "LKL3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Làng Kim Liên 3",
        "diaChiLapD": "",
        "longitude": 105.83926086148594,
        "latitude": 21.010393402644283,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 261,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83874855956468,
          21.01031327835326
        ]
      },
      "properties": {
        "tenDoiTuon": "2-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 126 phố Kim Hoa",
        "tuDien": "LKL3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Làng Kim Liên 3",
        "diaChiLapD": "",
        "longitude": 105.83874855956468,
        "latitude": 21.01031327835326,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 262,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83889339885462,
          21.01042094535983
        ]
      },
      "properties": {
        "tenDoiTuon": "2-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 126 phố Kim Hoa",
        "tuDien": "LKL3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Làng Kim Liên 3",
        "diaChiLapD": "",
        "longitude": 105.83889339885462,
        "latitude": 21.01042094535983,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 263,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.839054331391,
          21.010528612288674
        ]
      },
      "properties": {
        "tenDoiTuon": "2-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 126 phố Kim Hoa",
        "tuDien": "LKL3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Làng Kim Liên 3",
        "diaChiLapD": "",
        "longitude": 105.839054331391,
        "latitude": 21.010528612288674,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 264,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83911870441273,
          21.010691364475306
        ]
      },
      "properties": {
        "tenDoiTuon": "2-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Phố 126 Kim Hoa",
        "tuDien": "LKL3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Làng Kim Liên 3",
        "diaChiLapD": "",
        "longitude": 105.83911870441273,
        "latitude": 21.010691364475306,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 265,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83938960752043,
          21.010748953668415
        ]
      },
      "properties": {
        "tenDoiTuon": "2-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 126 phố Kim Hoa",
        "tuDien": "LKL3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Làng Kim Liên 3",
        "diaChiLapD": "",
        "longitude": 105.83938960752043,
        "latitude": 21.010748953668415,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 266,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83918039521774,
          21.010894178490428
        ]
      },
      "properties": {
        "tenDoiTuon": "1-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 138 phố Kim Hoa",
        "tuDien": "LKL3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Làng Kim Liên 3",
        "diaChiLapD": "",
        "longitude": 105.83918039521774,
        "latitude": 21.010894178490428,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 267,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83899264058597,
          21.010854116484364
        ]
      },
      "properties": {
        "tenDoiTuon": "1-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 138 phố Kim Hoa",
        "tuDien": "LKL3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Làng Kim Liên 3",
        "diaChiLapD": "",
        "longitude": 105.83899264058597,
        "latitude": 21.010854116484364,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 268,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83874855956468,
          21.010779000194947
        ]
      },
      "properties": {
        "tenDoiTuon": "1-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 138 phố Kim Hoa",
        "tuDien": "LKL3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Làng Kim Liên 3",
        "diaChiLapD": "",
        "longitude": 105.83874855956468,
        "latitude": 21.010779000194947,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 269,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83853130063875,
          21.010553651098057
        ]
      },
      "properties": {
        "tenDoiTuon": "1-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 138 phố Kim Hoa",
        "tuDien": "LKL3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Làng Kim Liên 3",
        "diaChiLapD": "",
        "longitude": 105.83853130063875,
        "latitude": 21.010553651098057,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 270,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83819066009382,
          21.010385890993998
        ]
      },
      "properties": {
        "tenDoiTuon": "LKL3 1-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Kim Hoa",
        "tuDien": "LKL3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Làng Kim Liên 3",
        "diaChiLapD": "",
        "longitude": 105.83819066009382,
        "latitude": 21.010385890993998,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 271,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83948759717099,
          21.008018450309653
        ]
      },
      "properties": {
        "tenDoiTuon": "LKL2 01",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Đào Duy Anh",
        "tuDien": "LKL2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "LKL2",
        "diaChiLapD": "",
        "longitude": 105.83948759717099,
        "latitude": 21.008018450309653,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 272,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83896188421099,
          21.00816117386915
        ]
      },
      "properties": {
        "tenDoiTuon": "LKL2 03",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Đào Duy Anh",
        "tuDien": "LKL2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "LKL2",
        "diaChiLapD": "",
        "longitude": 105.83896188421099,
        "latitude": 21.00816117386915,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 273,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83873121422843,
          21.008381518745495
        ]
      },
      "properties": {
        "tenDoiTuon": "LKL2 04",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Đào Duy Anh",
        "tuDien": "LKL2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "LKL2",
        "diaChiLapD": "",
        "longitude": 105.83873121422843,
        "latitude": 21.008381518745495,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 274,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83845762891295,
          21.00868198850659
        ]
      },
      "properties": {
        "tenDoiTuon": "LKL2 05",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Đào Duy Anh",
        "tuDien": "LKL2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "LKL2",
        "diaChiLapD": "",
        "longitude": 105.83845762891295,
        "latitude": 21.00868198850659,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 275,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83822695893934,
          21.008907340429875
        ]
      },
      "properties": {
        "tenDoiTuon": "LKL2 06",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Đào Duy Anh",
        "tuDien": "LKL2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "LKL2",
        "diaChiLapD": "",
        "longitude": 105.83822695893934,
        "latitude": 21.008907340429875,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 276,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83803115767539,
          21.009117668584892
        ]
      },
      "properties": {
        "tenDoiTuon": "LKL2 07",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Đào Duy Anh",
        "tuDien": "LKL2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "LKL2",
        "diaChiLapD": "",
        "longitude": 105.83803115767539,
        "latitude": 21.009117668584892,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 277,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83771465701808,
          21.0094632070534
        ]
      },
      "properties": {
        "tenDoiTuon": "LKL2 08",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Đào Duy Anh",
        "tuDien": "LKL2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "LKL2",
        "diaChiLapD": "",
        "longitude": 105.83771465701808,
        "latitude": 21.0094632070534,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 278,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8375269023863,
          21.009635975986686
        ]
      },
      "properties": {
        "tenDoiTuon": "LKL2 09",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Đào Duy Anh",
        "tuDien": "LKL2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "LKL2",
        "diaChiLapD": "",
        "longitude": 105.8375269023863,
        "latitude": 21.009635975986686,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 279,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83740620297505,
          21.00976868242404
        ]
      },
      "properties": {
        "tenDoiTuon": "LKL2 10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Đào Duy Anh",
        "tuDien": "LKL2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "LKL2",
        "diaChiLapD": "",
        "longitude": 105.83740620297505,
        "latitude": 21.00976868242404,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 280,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83729355019598,
          21.009893877066602
        ]
      },
      "properties": {
        "tenDoiTuon": "LKL2 11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Đào Duy Anh",
        "tuDien": "LKL2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "LKL2",
        "diaChiLapD": "",
        "longitude": 105.83729355019598,
        "latitude": 21.009893877066602,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 281,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83919791859111,
          21.00805225327098
        ]
      },
      "properties": {
        "tenDoiTuon": "LKL2 02",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Đào Duy Anh",
        "tuDien": "LKL2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "LKL2",
        "diaChiLapD": "",
        "longitude": 105.83919791859111,
        "latitude": 21.00805225327098,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 282,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83940301855915,
          21.009597165588875
        ]
      },
      "properties": {
        "tenDoiTuon": "4-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 88 Phố Kim Hoa",
        "tuDien": "LKL3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Làng Kim Liên 3",
        "diaChiLapD": "",
        "longitude": 105.83940301855915,
        "latitude": 21.009597165588875,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 283,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8395961376064,
          21.009744895426962
        ]
      },
      "properties": {
        "tenDoiTuon": "4-3-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 88 Phố Kim Hoa",
        "tuDien": "LKL3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Làng Kim Liên 3",
        "diaChiLapD": "",
        "longitude": 105.8395961376064,
        "latitude": 21.009744895426962,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 284,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8393949719359,
          21.01000029242809
        ]
      },
      "properties": {
        "tenDoiTuon": "4-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 88 Phố Kim Hoa",
        "tuDien": "LKL3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Làng Kim Liên 3",
        "diaChiLapD": "",
        "longitude": 105.8393949719359,
        "latitude": 21.01000029242809,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 285,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83981876095679,
          21.009970245744704
        ]
      },
      "properties": {
        "tenDoiTuon": "4-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 88 Phố Kim Hoa",
        "tuDien": "LKL3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Làng Kim Liên 3",
        "diaChiLapD": "",
        "longitude": 105.83981876095679,
        "latitude": 21.009970245744704,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 286,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84020768126803,
          21.010248177334756
        ]
      },
      "properties": {
        "tenDoiTuon": "4-9-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 354 Lê Duẩn",
        "tuDien": "LKL3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Làng Kim Liên 3",
        "diaChiLapD": "",
        "longitude": 105.84020768126803,
        "latitude": 21.010248177334756,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 287,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8398133965413,
          21.010671333446805
        ]
      },
      "properties": {
        "tenDoiTuon": "4-19",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 354 Lê Duẩn",
        "tuDien": "LKL3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Làng Kim Liên 3",
        "diaChiLapD": "",
        "longitude": 105.8398133965413,
        "latitude": 21.010671333446805,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 288,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8399340959436,
          21.010834085477704
        ]
      },
      "properties": {
        "tenDoiTuon": "4-21",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 354 Lê Duẩn",
        "tuDien": "LKL3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Làng Kim Liên 3",
        "diaChiLapD": "",
        "longitude": 105.8399340959436,
        "latitude": 21.010834085477704,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 289,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84064488133657,
          21.010130494653016
        ]
      },
      "properties": {
        "tenDoiTuon": "4-11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 354 Lê Duẩn",
        "tuDien": "LKL3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Làng Kim Liên 3",
        "diaChiLapD": "",
        "longitude": 105.84064488133657,
        "latitude": 21.010130494653016,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 290,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84024791440211,
          21.01052110064522
        ]
      },
      "properties": {
        "tenDoiTuon": "4-8",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 354 Lê Duẩn",
        "tuDien": "LKL3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Làng Kim Liên 3",
        "diaChiLapD": "",
        "longitude": 105.84024791440211,
        "latitude": 21.01052110064522,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 291,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84088896235788,
          21.009937695164584
        ]
      },
      "properties": {
        "tenDoiTuon": "4-15",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 8 Phố Kim Hoa",
        "tuDien": "LKL3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Làng Kim Liên 3",
        "diaChiLapD": "",
        "longitude": 105.84088896235788,
        "latitude": 21.009937695164584,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 292,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8409774752493,
          21.009634724035863
        ]
      },
      "properties": {
        "tenDoiTuon": "4-17",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 8 Phố Kim Hoa",
        "tuDien": "LKL3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Làng Kim Liên 3",
        "diaChiLapD": "",
        "longitude": 105.8409774752493,
        "latitude": 21.009634724035863,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 293,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8405241819253,
          21.00994771072798
        ]
      },
      "properties": {
        "tenDoiTuon": "4-12",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 354 Lê Duẩn",
        "tuDien": "LKL3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Làng Kim Liên 3",
        "diaChiLapD": "",
        "longitude": 105.8405241819253,
        "latitude": 21.00994771072798,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 294,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84060733041021,
          21.00978746162418
        ]
      },
      "properties": {
        "tenDoiTuon": "4-13",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 354 Lê Duẩn",
        "tuDien": "LKL3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Làng Kim Liên 3",
        "diaChiLapD": "",
        "longitude": 105.84060733041021,
        "latitude": 21.00978746162418,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 295,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84106867035746,
          21.009799981091593
        ]
      },
      "properties": {
        "tenDoiTuon": "4-16",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 422 Lê Duẩn",
        "tuDien": "LKL3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Làng Kim Liên 3",
        "diaChiLapD": "",
        "longitude": 105.84106867035746,
        "latitude": 21.009799981091593,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 296,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83992202600052,
          21.010259862135424
        ]
      },
      "properties": {
        "tenDoiTuon": "4-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 88 Phố Kim Hoa",
        "tuDien": "LKL3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Làng Kim Liên 3",
        "diaChiLapD": "",
        "longitude": 105.83992202600052,
        "latitude": 21.010259862135424,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 297,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84036593159666,
          21.010373371575746
        ]
      },
      "properties": {
        "tenDoiTuon": "4-9",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 354 Lê Duẩn",
        "tuDien": "LKL3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Làng Kim Liên 3",
        "diaChiLapD": "",
        "longitude": 105.84036593159666,
        "latitude": 21.010373371575746,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 298,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84056977948389,
          21.01019309183563
        ]
      },
      "properties": {
        "tenDoiTuon": "4-10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 354 Lê Duẩn",
        "tuDien": "LKL3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Làng Kim Liên 3",
        "diaChiLapD": "",
        "longitude": 105.84056977948389,
        "latitude": 21.01019309183563,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 299,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8400628419781,
          21.01068134896098
        ]
      },
      "properties": {
        "tenDoiTuon": "4-18",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 354 Lê Duẩn",
        "tuDien": "LKL3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Làng Kim Liên 3",
        "diaChiLapD": "",
        "longitude": 105.8400628419781,
        "latitude": 21.01068134896098,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 300,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84003333767495,
          21.010368363808034
        ]
      },
      "properties": {
        "tenDoiTuon": "4-7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 88 Phố Kim Hoa",
        "tuDien": "LKL3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Làng Kim Liên 3",
        "diaChiLapD": "",
        "longitude": 105.84003333767495,
        "latitude": 21.010368363808034,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 301,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84073607643575,
          21.010140510203513
        ]
      },
      "properties": {
        "tenDoiTuon": "4-14",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 354 Lê Duẩn",
        "tuDien": "LKL3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Làng Kim Liên 3",
        "diaChiLapD": "",
        "longitude": 105.84073607643575,
        "latitude": 21.010140510203513,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 302,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84077899178656,
          21.01040341817795
        ]
      },
      "properties": {
        "tenDoiTuon": "4-14-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 354 Lê Duẩn",
        "tuDien": "LKL3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Làng Kim Liên 3",
        "diaChiLapD": "",
        "longitude": 105.84077899178656,
        "latitude": 21.01040341817795,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 303,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84103380163882,
          21.010400914295015
        ]
      },
      "properties": {
        "tenDoiTuon": "4-14-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 354 Lê Duẩn",
        "tuDien": "LKL3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Làng Kim Liên 3",
        "diaChiLapD": "",
        "longitude": 105.84103380163882,
        "latitude": 21.010400914295015,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 304,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83969806155449,
          21.010779000194947
        ]
      },
      "properties": {
        "tenDoiTuon": "4-20",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 354 Lê Duẩn",
        "tuDien": "LKL3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Làng Kim Liên 3",
        "diaChiLapD": "",
        "longitude": 105.83969806155449,
        "latitude": 21.010779000194947,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 305,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83974902351954,
          21.01106694576733
        ]
      },
      "properties": {
        "tenDoiTuon": "4-22",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 354 Lê Duẩn",
        "tuDien": "LKL3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Làng Kim Liên 3",
        "diaChiLapD": "",
        "longitude": 105.83974902351954,
        "latitude": 21.01106694576733,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 306,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83960150203089,
          21.010981814090083
        ]
      },
      "properties": {
        "tenDoiTuon": "4-23",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 354 Lê Duẩn",
        "tuDien": "LKL3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Làng Kim Liên 3",
        "diaChiLapD": "",
        "longitude": 105.83960150203089,
        "latitude": 21.010981814090083,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 307,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8408809157257,
          21.010248177334756
        ]
      },
      "properties": {
        "tenDoiTuon": "4-14-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 354 Lê Duẩn",
        "tuDien": "LKL3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": "",
        "tuDien2": "Làng Kim Liên 3",
        "diaChiLapD": "",
        "longitude": 105.8408809157257,
        "latitude": 21.010248177334756,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 308,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82927200972934,
          21.021774460489812
        ]
      },
      "properties": {
        "tenDoiTuon": "HB7 9-7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Quan Thổ 1",
        "tuDien": "HB7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hàng Bột 7",
        "diaChiLapD": "",
        "longitude": 105.82927200972934,
        "latitude": 21.021774460489812,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 309,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82919154346115,
          21.021644268430954
        ]
      },
      "properties": {
        "tenDoiTuon": "HB7 9-8",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 79 Cẩm Văn",
        "tuDien": "HB7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hàng Bột 7",
        "diaChiLapD": "",
        "longitude": 105.82919154346115,
        "latitude": 21.021644268430954,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 310,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82870670428677,
          21.02122574795677
        ]
      },
      "properties": {
        "tenDoiTuon": "HB7 9-12",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 79 Cẩm Văn",
        "tuDien": "HB7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hàng Bột 7",
        "diaChiLapD": "",
        "longitude": 105.82870670428677,
        "latitude": 21.02122574795677,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 311,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83068016946763,
          21.020993306427854
        ]
      },
      "properties": {
        "tenDoiTuon": "HB7 9-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Quan Thổ 1",
        "tuDien": "HB7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hàng Bột 7",
        "diaChiLapD": "",
        "longitude": 105.83068016946763,
        "latitude": 21.020993306427854,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 312,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83038512647227,
          21.021201113837847
        ]
      },
      "properties": {
        "tenDoiTuon": "HB7 9-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Quan Thổ 1",
        "tuDien": "HB7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hàng Bột 7",
        "diaChiLapD": "",
        "longitude": 105.83038512647227,
        "latitude": 21.021201113837847,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 313,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83020810068044,
          21.021306269283738
        ]
      },
      "properties": {
        "tenDoiTuon": "HB7 9-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Quan Thổ 1",
        "tuDien": "HB7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hàng Bột 7",
        "diaChiLapD": "",
        "longitude": 105.83020810068044,
        "latitude": 21.021306269283738,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 314,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83015177429093,
          21.021203617539438
        ]
      },
      "properties": {
        "tenDoiTuon": "HB7 9-3-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Quan Thổ 1",
        "tuDien": "HB7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hàng Bột 7",
        "diaChiLapD": "",
        "longitude": 105.83015177429093,
        "latitude": 21.021203617539438,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 315,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83008740126917,
          21.02103837312004
        ]
      },
      "properties": {
        "tenDoiTuon": "HB7 9-3-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Quan Thổ 1",
        "tuDien": "HB7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hàng Bột 7",
        "diaChiLapD": "",
        "longitude": 105.83008740126917,
        "latitude": 21.02103837312004,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 316,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83001763392703,
          21.02088774786498
        ]
      },
      "properties": {
        "tenDoiTuon": "HB7 9-3-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Quan Thổ 2",
        "tuDien": "HB7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hàng Bột 7",
        "diaChiLapD": "",
        "longitude": 105.83001763392703,
        "latitude": 21.02088774786498,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 317,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83019468963275,
          21.020975780488442
        ]
      },
      "properties": {
        "tenDoiTuon": "HB7 9-3-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Quan Thổ 1",
        "tuDien": "HB7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hàng Bột 7",
        "diaChiLapD": "",
        "longitude": 105.83019468963275,
        "latitude": 21.020975780488442,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 318,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8303529399614,
          21.02090317300351
        ]
      },
      "properties": {
        "tenDoiTuon": "HB7 9-3-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Quan Thổ 1",
        "tuDien": "HB7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hàng Bột 7",
        "diaChiLapD": "",
        "longitude": 105.8303529399614,
        "latitude": 21.02090317300351,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 319,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8303931730955,
          21.020767972765228
        ]
      },
      "properties": {
        "tenDoiTuon": "HB7 9-3-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Quan Thổ 2",
        "tuDien": "HB7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hàng Bột 7",
        "diaChiLapD": "",
        "longitude": 105.8303931730955,
        "latitude": 21.020767972765228,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 320,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83054605900864,
          21.020427467916882
        ]
      },
      "properties": {
        "tenDoiTuon": "HB7 7-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Quan Thổ 3",
        "tuDien": "HB7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hàng Bột 7",
        "diaChiLapD": "",
        "longitude": 105.83054605900864,
        "latitude": 21.020427467916882,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 321,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83035830438587,
          21.02053763133489
        ]
      },
      "properties": {
        "tenDoiTuon": "HB7 7-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Quan Thổ 3",
        "tuDien": "HB7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hàng Bột 7",
        "diaChiLapD": "",
        "longitude": 105.83035830438587,
        "latitude": 21.02053763133489,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 322,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83013836324324,
          21.020552653613322
        ]
      },
      "properties": {
        "tenDoiTuon": "HB7 7-3-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Quan Thổ 3",
        "tuDien": "HB7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hàng Bột 7",
        "diaChiLapD": "",
        "longitude": 105.83013836324324,
        "latitude": 21.020552653613322,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 323,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83020541846373,
          21.020642787249567
        ]
      },
      "properties": {
        "tenDoiTuon": "HB7 7-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Quan Thổ 3",
        "tuDien": "HB7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hàng Bột 7",
        "diaChiLapD": "",
        "longitude": 105.83020541846373,
        "latitude": 21.020642787249567,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 324,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83004985034282,
          21.02075295050846
        ]
      },
      "properties": {
        "tenDoiTuon": "HB7 7-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Quan Thổ 3",
        "tuDien": "HB7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hàng Bột 7",
        "diaChiLapD": "",
        "longitude": 105.83004985034282,
        "latitude": 21.02075295050846,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 325,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82993451535602,
          21.02060272786213
        ]
      },
      "properties": {
        "tenDoiTuon": "HB7 7-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Quan Thổ 3",
        "tuDien": "HB7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hàng Bột 7",
        "diaChiLapD": "",
        "longitude": 105.82993451535602,
        "latitude": 21.02060272786213,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 326,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82981113374598,
          21.02042496420224
        ]
      },
      "properties": {
        "tenDoiTuon": "HB7 7-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Quan Thổ 3",
        "tuDien": "HB7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hàng Bột 7",
        "diaChiLapD": "",
        "longitude": 105.82981113374598,
        "latitude": 21.02042496420224,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 327,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82995060861145,
          21.02049757192003
        ]
      },
      "properties": {
        "tenDoiTuon": "HB7 7-5-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Quan Thổ 3",
        "tuDien": "HB7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hàng Bột 7",
        "diaChiLapD": "",
        "longitude": 105.82995060861145,
        "latitude": 21.02049757192003,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 328,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82983527362464,
          21.020667824360952
        ]
      },
      "properties": {
        "tenDoiTuon": "HB7 7-7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Quan Thổ 3",
        "tuDien": "HB7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hàng Bột 7",
        "diaChiLapD": "",
        "longitude": 105.82983527362464,
        "latitude": 21.020667824360952,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 329,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82973603189326,
          21.020737928251084
        ]
      },
      "properties": {
        "tenDoiTuon": "HB7 7-8",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Quan Thổ 3",
        "tuDien": "HB7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hàng Bột 7",
        "diaChiLapD": "",
        "longitude": 105.82973603189326,
        "latitude": 21.020737928251084,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 330,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82993451535602,
          21.02086060997869
        ]
      },
      "properties": {
        "tenDoiTuon": "HB7 7-9",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Quan Thổ 3",
        "tuDien": "HB7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hàng Bột 7",
        "diaChiLapD": "",
        "longitude": 105.82993451535602,
        "latitude": 21.02086060997869,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 331,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82977358281963,
          21.020970773077504
        ]
      },
      "properties": {
        "tenDoiTuon": "HB7 7-10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Quan Thổ 3",
        "tuDien": "HB7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hàng Bột 7",
        "diaChiLapD": "",
        "longitude": 105.82977358281963,
        "latitude": 21.020970773077504,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 332,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82956168830022,
          21.02103336571037
        ]
      },
      "properties": {
        "tenDoiTuon": "HB7 7-11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Quan Thổ 3",
        "tuDien": "HB7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hàng Bột 7",
        "diaChiLapD": "",
        "longitude": 105.82956168830022,
        "latitude": 21.02103336571037,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 333,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82936320483746,
          21.02111849164921
        ]
      },
      "properties": {
        "tenDoiTuon": "HB7 7-12",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Quan Thổ 3",
        "tuDien": "HB7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hàng Bột 7",
        "diaChiLapD": "",
        "longitude": 105.82936320483746,
        "latitude": 21.02111849164921,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 334,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82947585761654,
          21.021336313683342
        ]
      },
      "properties": {
        "tenDoiTuon": "HB7 7-13",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Quan Thổ 3",
        "tuDien": "HB7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "Hàng Bột 7",
        "diaChiLapD": "",
        "longitude": 105.82947585761654,
        "latitude": 21.021336313683342,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 335,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82976285397967,
          21.021211128645735
        ]
      },
      "properties": {
        "tenDoiTuon": "HB7 7-14",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Quan Thổ 3",
        "tuDien": "HB7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hàng Bột 7",
        "diaChiLapD": "",
        "longitude": 105.82976285397967,
        "latitude": 21.021211128645735,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 336,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82995865523468,
          21.021428950544543
        ]
      },
      "properties": {
        "tenDoiTuon": "HB7 9-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Quan Thổ 1",
        "tuDien": "HB7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hàng Bột 7",
        "diaChiLapD": "",
        "longitude": 105.82995865523468,
        "latitude": 21.021428950544543,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 337,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82970116317465,
          21.021561646487857
        ]
      },
      "properties": {
        "tenDoiTuon": "HB7 9-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Quan Thổ 1",
        "tuDien": "HB7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hàng Bột 7",
        "diaChiLapD": "",
        "longitude": 105.82970116317465,
        "latitude": 21.021561646487857,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 338,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82945708215335,
          21.021684327537688
        ]
      },
      "properties": {
        "tenDoiTuon": "HB7 9-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Quan Thổ 1",
        "tuDien": "HB7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hàng Bột 7",
        "diaChiLapD": "",
        "longitude": 105.82945708215335,
        "latitude": 21.021684327537688,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 339,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82909766614524,
          21.021366358076907
        ]
      },
      "properties": {
        "tenDoiTuon": "HB7 9-9",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 79 Cẩm Văn",
        "tuDien": "HB7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hàng Bột 7",
        "diaChiLapD": "",
        "longitude": 105.82909766614524,
        "latitude": 21.021366358076907,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 340,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82890991151348,
          21.02173940879337
        ]
      },
      "properties": {
        "tenDoiTuon": "HB7 9-10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 79 Cẩm Văn",
        "tuDien": "HB7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hàng Bột 7",
        "diaChiLapD": "",
        "longitude": 105.82890991151348,
        "latitude": 21.02173940879337,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 341,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82882139861309,
          21.02150406146999
        ]
      },
      "properties": {
        "tenDoiTuon": "HB7 9-11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 79 Cẩm Văn",
        "tuDien": "HB7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hàng Bột 7",
        "diaChiLapD": "",
        "longitude": 105.82882139861309,
        "latitude": 21.02150406146999,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 342,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82889918267352,
          21.021969748366224
        ]
      },
      "properties": {
        "tenDoiTuon": "HB7 9-14",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Quan Thổ 1",
        "tuDien": "HB7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hàng Bột 7",
        "diaChiLapD": "",
        "longitude": 105.82889918267352,
        "latitude": 21.021969748366224,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 343,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82878384768674,
          21.02193720040417
        ]
      },
      "properties": {
        "tenDoiTuon": "HB7 9-17",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Tập Thể Ngân Hàng",
        "tuDien": "HB7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hàng Bột 7",
        "diaChiLapD": "",
        "longitude": 105.82878384768674,
        "latitude": 21.02193720040417,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 344,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82907889068208,
          21.021874608150778
        ]
      },
      "properties": {
        "tenDoiTuon": "HB7 9-13",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Quan Thổ 1",
        "tuDien": "HB7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hàng Bột 7",
        "diaChiLapD": "",
        "longitude": 105.82907889068208,
        "latitude": 21.021874608150778,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 345,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82843395718034,
          21.02201112577958
        ]
      },
      "properties": {
        "tenDoiTuon": "HB7 9-18",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Tập Thể Ngân Hàng",
        "tuDien": "HB7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hàng Bột 7",
        "diaChiLapD": "",
        "longitude": 105.82843395718034,
        "latitude": 21.02201112577958,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 346,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8285263556267,
          21.02240038226601
        ]
      },
      "properties": {
        "tenDoiTuon": "HB7 9-19",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Tập Thể Ngân Hàng",
        "tuDien": "HB7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hàng Bột 7",
        "diaChiLapD": "",
        "longitude": 105.8285263556267,
        "latitude": 21.02240038226601,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 347,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82875166118484,
          21.02237784912748
        ]
      },
      "properties": {
        "tenDoiTuon": "HB7 9-20",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Tập Thể Ngân Hàng",
        "tuDien": "HB7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hàng Bột 7",
        "diaChiLapD": "",
        "longitude": 105.82875166118484,
        "latitude": 21.02237784912748,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 348,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82830641448408,
          21.022447952213604
        ]
      },
      "properties": {
        "tenDoiTuon": "HB7 9-21",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Tập Thể Ngân Hàng",
        "tuDien": "HB7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hàng Bột 7",
        "diaChiLapD": "",
        "longitude": 105.82830641448408,
        "latitude": 21.022447952213604,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 349,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82833860099494,
          21.02256061781753
        ]
      },
      "properties": {
        "tenDoiTuon": "HB7 9-22",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Tập Thể Ngân Hàng",
        "tuDien": "HB7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hàng Bột 7",
        "diaChiLapD": "",
        "longitude": 105.82833860099494,
        "latitude": 21.02256061781753,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 350,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82803551137636,
          21.02219508021259
        ]
      },
      "properties": {
        "tenDoiTuon": "HB7 9-23",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Tập Thể Ngân Hàng",
        "tuDien": "HB7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hàng Bột 7",
        "diaChiLapD": "",
        "longitude": 105.82803551137636,
        "latitude": 21.02219508021259,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 351,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82807038009497,
          21.022390367538016
        ]
      },
      "properties": {
        "tenDoiTuon": "HB7 9-24",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Tập Thể Ngân Hàng",
        "tuDien": "HB7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hàng Bột 7",
        "diaChiLapD": "",
        "longitude": 105.82807038009497,
        "latitude": 21.022390367538016,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 352,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82795504510817,
          21.022680794368547
        ]
      },
      "properties": {
        "tenDoiTuon": "HB7 9-26",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Tập Thể Ngân Hàng",
        "tuDien": "HB7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "Hàng Bột 7",
        "diaChiLapD": "",
        "longitude": 105.82795504510817,
        "latitude": 21.022680794368547,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 353,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82808647335041,
          21.022608187713942
        ]
      },
      "properties": {
        "tenDoiTuon": "HB7 9-25",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Tập Thể Ngân Hàng",
        "tuDien": "HB7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hàng Bột 7",
        "diaChiLapD": "",
        "longitude": 105.82808647335041,
        "latitude": 21.022608187713942,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 354,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82897696674297,
          21.02230774600843
        ]
      },
      "properties": {
        "tenDoiTuon": "HB7 9-15",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Quan Thổ 1",
        "tuDien": "HB7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hàng Bột 7",
        "diaChiLapD": "",
        "longitude": 105.82897696674297,
        "latitude": 21.02230774600843,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 355,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82903865754797,
          21.02263572817367
        ]
      },
      "properties": {
        "tenDoiTuon": "HB7 9-16",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Quan Thổ 1",
        "tuDien": "HB7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hàng Bột 7",
        "diaChiLapD": "",
        "longitude": 105.82903865754797,
        "latitude": 21.02263572817367,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 356,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83084646641949,
          21.02169934970059
        ]
      },
      "properties": {
        "tenDoiTuon": "HB7 11-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 232 Tôn Đức Thắng",
        "tuDien": "HB7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hàng Bột 7",
        "diaChiLapD": "",
        "longitude": 105.83084646641949,
        "latitude": 21.02169934970059,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 357,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83051387250677,
          21.021852074932745
        ]
      },
      "properties": {
        "tenDoiTuon": "HB7 11-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 232 Tôn Đức Thắng",
        "tuDien": "HB7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hàng Bột 7",
        "diaChiLapD": "",
        "longitude": 105.83051387250677,
        "latitude": 21.021852074932745,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 358,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83043072402182,
          21.02175943833532
        ]
      },
      "properties": {
        "tenDoiTuon": "HB7 11-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 232 Tôn Đức Thắng",
        "tuDien": "HB7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hàng Bột 7",
        "diaChiLapD": "",
        "longitude": 105.83043072402182,
        "latitude": 21.02175943833532,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 359,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83081964433308,
          21.021576668662284
        ]
      },
      "properties": {
        "tenDoiTuon": "HB7 11-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 232 Tôn Đức Thắng",
        "tuDien": "HB7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hàng Bột 7",
        "diaChiLapD": "",
        "longitude": 105.83081964433308,
        "latitude": 21.021576668662284,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 360,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83102885663577,
          21.021689334925487
        ]
      },
      "properties": {
        "tenDoiTuon": "HB7 11-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 232 Tôn Đức Thắng",
        "tuDien": "HB7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hàng Bột 7",
        "diaChiLapD": "",
        "longitude": 105.83102885663577,
        "latitude": 21.021689334925487,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 361,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83126757324159,
          21.022210102323967
        ]
      },
      "properties": {
        "tenDoiTuon": "HB7 13-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 204 Tôn Đức Thắng",
        "tuDien": "HB7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hàng Bột 7",
        "diaChiLapD": "",
        "longitude": 105.83126757324159,
        "latitude": 21.022210102323967,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 362,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8314580100811,
          21.022252664963556
        ]
      },
      "properties": {
        "tenDoiTuon": "HB7 14-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Thịnh Hào 3",
        "tuDien": "HB7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hàng Bột 7",
        "diaChiLapD": "",
        "longitude": 105.8314580100811,
        "latitude": 21.022252664963556,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 363,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83126757324159,
          21.022335286523653
        ]
      },
      "properties": {
        "tenDoiTuon": "HB7 14-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Thịnh Hào 3",
        "tuDien": "HB7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hàng Bột 7",
        "diaChiLapD": "",
        "longitude": 105.83126757324159,
        "latitude": 21.022335286523653,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 364,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83103958547574,
          21.022447952213604
        ]
      },
      "properties": {
        "tenDoiTuon": "HB7 14-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Thịnh Hào 3",
        "tuDien": "HB7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hàng Bột 7",
        "diaChiLapD": "",
        "longitude": 105.83103958547574,
        "latitude": 21.022447952213604,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 365,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83082500874858,
          21.02255310678006
        ]
      },
      "properties": {
        "tenDoiTuon": "HB7 14-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Thịnh Hào 3",
        "tuDien": "HB7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hàng Bột 7",
        "diaChiLapD": "",
        "longitude": 105.83082500874858,
        "latitude": 21.02255310678006,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 366,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83066944062766,
          21.02255310678006
        ]
      },
      "properties": {
        "tenDoiTuon": "HB7 14-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Thịnh Hào 3",
        "tuDien": "HB7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hàng Bột 7",
        "diaChiLapD": "",
        "longitude": 105.83066944062766,
        "latitude": 21.02255310678006,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 367,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83086792409041,
          21.02268830540038
        ]
      },
      "properties": {
        "tenDoiTuon": "HB7 14-20",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Thịnh Hào 3",
        "tuDien": "HB7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hàng Bột 7",
        "diaChiLapD": "",
        "longitude": 105.83086792409041,
        "latitude": 21.02268830540038,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 368,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83056215226408,
          21.022345301254507
        ]
      },
      "properties": {
        "tenDoiTuon": "HB7 14-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Thịnh Hào 3",
        "tuDien": "HB7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hàng Bột 7",
        "diaChiLapD": "",
        "longitude": 105.83056215226408,
        "latitude": 21.022345301254507,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 369,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83054874122539,
          21.022503033186304
        ]
      },
      "properties": {
        "tenDoiTuon": "HB7 14-21",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Thịnh Hào 3",
        "tuDien": "HB7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hàng Bột 7",
        "diaChiLapD": "",
        "longitude": 105.83054874122539,
        "latitude": 21.022503033186304,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 370,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8304199951909,
          21.022387863856324
        ]
      },
      "properties": {
        "tenDoiTuon": "HB7 14-7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Thịnh Hào 3",
        "tuDien": "HB7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hàng Bột 7",
        "diaChiLapD": "",
        "longitude": 105.8304199951909,
        "latitude": 21.022387863856324,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 371,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83023760497458,
          21.022447952213604
        ]
      },
      "properties": {
        "tenDoiTuon": "HB7 14-8",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Thịnh Hào 3",
        "tuDien": "HB7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hàng Bột 7",
        "diaChiLapD": "",
        "longitude": 105.83023760497458,
        "latitude": 21.022447952213604,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 372,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83017859637732,
          21.022347804937763
        ]
      },
      "properties": {
        "tenDoiTuon": "HB7 14-9",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Thịnh Hào 3",
        "tuDien": "HB7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hàng Bột 7",
        "diaChiLapD": "",
        "longitude": 105.83017859637732,
        "latitude": 21.022347804937763,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 373,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83004448592735,
          21.022129984381404
        ]
      },
      "properties": {
        "tenDoiTuon": "HB7 14-18",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Thịnh Hào 3",
        "tuDien": "HB7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hàng Bột 7",
        "diaChiLapD": "",
        "longitude": 105.83004448592735,
        "latitude": 21.022129984381404,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 374,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83008471906145,
          21.02210995488838
        ]
      },
      "properties": {
        "tenDoiTuon": "HB7 14-17",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Thịnh Hào 3",
        "tuDien": "HB7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hàng Bột 7",
        "diaChiLapD": "",
        "longitude": 105.83008471906145,
        "latitude": 21.02210995488838,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 375,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83013031662001,
          21.022087421705965
        ]
      },
      "properties": {
        "tenDoiTuon": "HB7 14-16",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Thịnh Hào 3",
        "tuDien": "HB7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hàng Bột 7",
        "diaChiLapD": "",
        "longitude": 105.83013031662001,
        "latitude": 21.022087421705965,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 376,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83018396079282,
          21.02206238483298
        ]
      },
      "properties": {
        "tenDoiTuon": "HB7 14-15",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Thịnh Hào 3",
        "tuDien": "HB7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hàng Bột 7",
        "diaChiLapD": "",
        "longitude": 105.83018396079282,
        "latitude": 21.02206238483298,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 377,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83023760497458,
          21.022117465948998
        ]
      },
      "properties": {
        "tenDoiTuon": "HB7 14-13",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Thịnh Hào 3",
        "tuDien": "HB7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hàng Bột 7",
        "diaChiLapD": "",
        "longitude": 105.83023760497458,
        "latitude": 21.022117465948998,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 378,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83040926635093,
          21.02205487377046
        ]
      },
      "properties": {
        "tenDoiTuon": "HB7 14-12",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Thịnh Hào 3",
        "tuDien": "HB7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hàng Bột 7",
        "diaChiLapD": "",
        "longitude": 105.83040926635093,
        "latitude": 21.02205487377046,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 379,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83036366880135,
          21.02192968933532
        ]
      },
      "properties": {
        "tenDoiTuon": "HB7 14-14",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Thịnh Hào 3",
        "tuDien": "HB7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hàng Bột 7",
        "diaChiLapD": "",
        "longitude": 105.83036366880135,
        "latitude": 21.02192968933532,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 380,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83050314366682,
          21.022202591268893
        ]
      },
      "properties": {
        "tenDoiTuon": "HB7 14-11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Thịnh Hào 3",
        "tuDien": "HB7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hàng Bột 7",
        "diaChiLapD": "",
        "longitude": 105.83050314366682,
        "latitude": 21.022202591268893,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 381,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8303207534595,
          21.02226518338459
        ]
      },
      "properties": {
        "tenDoiTuon": "HB7 14-10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Thịnh Hào 3",
        "tuDien": "HB7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hàng Bột 7",
        "diaChiLapD": "",
        "longitude": 105.8303207534595,
        "latitude": 21.02226518338459,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 382,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83068285167535,
          21.022247657594676
        ]
      },
      "properties": {
        "tenDoiTuon": "HB7 14-19",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Thịnh Hào 3",
        "tuDien": "HB7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hàng Bột 7",
        "diaChiLapD": "",
        "longitude": 105.83068285167535,
        "latitude": 21.022247657594676,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 383,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83147946775203,
          21.022390367538016
        ]
      },
      "properties": {
        "tenDoiTuon": "HB7 15",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 200 Tôn Đức Thắng",
        "tuDien": "HB7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hàng Bột 7",
        "diaChiLapD": "",
        "longitude": 105.83147946775203,
        "latitude": 21.022390367538016,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 384,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83029929577964,
          21.02003688786892
        ]
      },
      "properties": {
        "tenDoiTuon": "HB7 6-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 278 Tôn Đức Thắng",
        "tuDien": "HB7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hàng Bột 7",
        "diaChiLapD": "",
        "longitude": 105.83029929577964,
        "latitude": 21.02003688786892,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 385,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83011154114784,
          21.019926724080896
        ]
      },
      "properties": {
        "tenDoiTuon": "HB7 6-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 278 Tôn Đức Thắng",
        "tuDien": "HB7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hàng Bột 7",
        "diaChiLapD": "",
        "longitude": 105.83011154114784,
        "latitude": 21.019926724080896,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 386,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83002839268089,
          21.020204637117487
        ]
      },
      "properties": {
        "tenDoiTuon": "HB7 6-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 278 Tôn Đức Thắng",
        "tuDien": "HB7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hàng Bột 7",
        "diaChiLapD": "",
        "longitude": 105.83002839268089,
        "latitude": 21.020204637117487,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 387,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83000157058552,
          21.020302282115342
        ]
      },
      "properties": {
        "tenDoiTuon": "HB7 6-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 278 Tôn Đức Thắng",
        "tuDien": "HB7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hàng Bột 7",
        "diaChiLapD": "",
        "longitude": 105.83000157058552,
        "latitude": 21.020302282115342,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 388,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8298647779278,
          21.020074443686973
        ]
      },
      "properties": {
        "tenDoiTuon": "HB7 6-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 278 Tôn Đức Thắng",
        "tuDien": "HB7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hàng Bột 7",
        "diaChiLapD": "",
        "longitude": 105.8298647779278,
        "latitude": 21.020074443686973,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 389,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82974139631774,
          21.019921716633913
        ]
      },
      "properties": {
        "tenDoiTuon": "HB7 6-4-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 278 Tôn Đức Thắng",
        "tuDien": "HB7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hàng Bột 7",
        "diaChiLapD": "",
        "longitude": 105.82974139631774,
        "latitude": 21.019921716633913,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 390,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82996938408361,
          21.019708899986654
        ]
      },
      "properties": {
        "tenDoiTuon": "HB7 6-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 278 Tôn Đức Thắng",
        "tuDien": "HB7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hàng Bột 7",
        "diaChiLapD": "",
        "longitude": 105.82996938408361,
        "latitude": 21.019708899986654,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 391,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82982454479367,
          21.019794026681677
        ]
      },
      "properties": {
        "tenDoiTuon": "HB7 6-3-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 278 Tôn Đức Thắng",
        "tuDien": "HB7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hàng Bột 7",
        "diaChiLapD": "",
        "longitude": 105.82982454479367,
        "latitude": 21.019794026681677,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 392,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81834097224885,
          21.003270936219586
        ]
      },
      "properties": {
        "tenDoiTuon": "02",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 41 Đường Láng",
        "tuDien": "NTS3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "NTS3",
        "diaChiLapD": "",
        "longitude": 105.81834097224885,
        "latitude": 21.003270936219586,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 393,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81848044712329,
          21.003398640315535
        ]
      },
      "properties": {
        "tenDoiTuon": "01",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 41 Đường Láng",
        "tuDien": "NTS3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "NTS3",
        "diaChiLapD": "",
        "longitude": 105.81848044712329,
        "latitude": 21.003398640315535,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 394,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8181693108725,
          21.0033135042637
        ]
      },
      "properties": {
        "tenDoiTuon": "04",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 41 Đường Láng",
        "tuDien": "NTS3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "NTS3",
        "diaChiLapD": "",
        "longitude": 105.8181693108725,
        "latitude": 21.0033135042637,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 395,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81812907773842,
          21.003488784317625
        ]
      },
      "properties": {
        "tenDoiTuon": "05",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 41 Đường Láng",
        "tuDien": "NTS3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "NTS3",
        "diaChiLapD": "",
        "longitude": 105.81812907773842,
        "latitude": 21.003488784317625,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 396,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81806470472567,
          21.003646536190903
        ]
      },
      "properties": {
        "tenDoiTuon": "06",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 47 Đường Láng",
        "tuDien": "NTS3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "NTS3",
        "diaChiLapD": "",
        "longitude": 105.81806470472567,
        "latitude": 21.003646536190903,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 397,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81771333534077,
          21.00355889628188
        ]
      },
      "properties": {
        "tenDoiTuon": "NTS3 08",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 47 Đường Láng",
        "tuDien": "NTS3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "NTS3",
        "diaChiLapD": "",
        "longitude": 105.81771333534077,
        "latitude": 21.00355889628188,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 398,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81786085683844,
          21.003601464243907
        ]
      },
      "properties": {
        "tenDoiTuon": "07",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 47 Đường Láng",
        "tuDien": "NTS3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "NTS3",
        "diaChiLapD": "",
        "longitude": 105.81786085683844,
        "latitude": 21.003601464243907,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 399,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81847508269884,
          21.003078127867383
        ]
      },
      "properties": {
        "tenDoiTuon": "NTS3 03",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 41 Đường Láng",
        "tuDien": "NTS3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "NTS3",
        "diaChiLapD": "",
        "longitude": 105.81847508269884,
        "latitude": 21.003078127867383,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 400,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81860382873333,
          21.003498800314787
        ]
      },
      "properties": {
        "tenDoiTuon": "NTS3 1-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "",
        "tuDien": "NTS3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "NTS3",
        "diaChiLapD": "",
        "longitude": 105.81860382873333,
        "latitude": 21.003498800314787,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 401,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81802447159156,
          21.003411160319846
        ]
      },
      "properties": {
        "tenDoiTuon": "5-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 41 Đường Láng",
        "tuDien": "NTS3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "NTS3",
        "diaChiLapD": "",
        "longitude": 105.81802447159156,
        "latitude": 21.003411160319846,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 402,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81797216851365,
          21.00329222024315
        ]
      },
      "properties": {
        "tenDoiTuon": "NTS3 5-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 41 Đường Láng",
        "tuDien": "NTS3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "NTS3",
        "diaChiLapD": "",
        "longitude": 105.81797216851365,
        "latitude": 21.00329222024315,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 403,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.79934389164576,
          21.02661048686245
        ]
      },
      "properties": {
        "tenDoiTuon": "VHA2 14-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Chùa Nền",
        "tuDien": "VHA2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VHA2",
        "diaChiLapD": "",
        "longitude": 105.79934389164576,
        "latitude": 21.02661048686245,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 404,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8385340718875,
          21.00868073654943
        ]
      },
      "properties": {
        "tenDoiTuon": "LKL2B 01",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Đào Duy Anh",
        "tuDien": "LKL2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "LKL2",
        "diaChiLapD": "",
        "longitude": 105.8385340718875,
        "latitude": 21.00868073654943,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 405,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83860917373123,
          21.008583083942828
        ]
      },
      "properties": {
        "tenDoiTuon": "LKL2B 04",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Đào Duy Anh",
        "tuDien": "LKL2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Sân vườn",
        "chieuCao": 7,
        "tuDien2": "LKL2",
        "diaChiLapD": "",
        "longitude": 105.83860917373123,
        "latitude": 21.008583083942828,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 406,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83861613225574,
          21.008745993075927
        ]
      },
      "properties": {
        "tenDoiTuon": "LKL2B 02",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Đào Duy Anh",
        "tuDien": "LKL2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Sân vườn",
        "chieuCao": 7,
        "tuDien2": "LKL2",
        "diaChiLapD": "",
        "longitude": 105.83861613225574,
        "latitude": 21.008745993075927,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 407,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83871646209481,
          21.00868324046123
        ]
      },
      "properties": {
        "tenDoiTuon": "LKL2B 03",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Đào Duy Anh",
        "tuDien": "LKL2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Sân vườn",
        "chieuCao": 7,
        "tuDien2": "LKL2",
        "diaChiLapD": "",
        "longitude": 105.83871646209481,
        "latitude": 21.00868324046123,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 408,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83847132967733,
          21.008934018445014
        ]
      },
      "properties": {
        "tenDoiTuon": "LKL2B 16",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Đào Duy Anh",
        "tuDien": "LKL2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Sân vườn",
        "chieuCao": 7,
        "tuDien2": "LKL2",
        "diaChiLapD": "",
        "longitude": 105.83847132967733,
        "latitude": 21.008934018445014,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 409,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8383650927189,
          21.008856010298608
        ]
      },
      "properties": {
        "tenDoiTuon": "LKL2B 05",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Đào Duy Anh",
        "tuDien": "LKL2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Sân vườn",
        "chieuCao": 7,
        "tuDien2": "LKL2",
        "diaChiLapD": "",
        "longitude": 105.8383650927189,
        "latitude": 21.008856010298608,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 410,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83833558841579,
          21.009128936155946
        ]
      },
      "properties": {
        "tenDoiTuon": "LKL2B 15",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Đào Duy Anh",
        "tuDien": "LKL2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Sân vườn",
        "chieuCao": 7,
        "tuDien2": "LKL2",
        "diaChiLapD": "",
        "longitude": 105.83833558841579,
        "latitude": 21.009128936155946,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 411,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83821757122124,
          21.00923410011389
        ]
      },
      "properties": {
        "tenDoiTuon": "LKL2B 14",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Đào Duy Anh",
        "tuDien": "LKL2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Sân vườn",
        "chieuCao": 7,
        "tuDien2": "LKL2",
        "diaChiLapD": "",
        "longitude": 105.83821757122124,
        "latitude": 21.00923410011389,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 412,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8381076006499,
          21.009351783503572
        ]
      },
      "properties": {
        "tenDoiTuon": "LKL2B 13",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Đào Duy Anh",
        "tuDien": "LKL2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Sân vườn",
        "chieuCao": 7,
        "tuDien2": "LKL2",
        "diaChiLapD": "",
        "longitude": 105.8381076006499,
        "latitude": 21.009351783503572,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 413,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83797885461541,
          21.009481986293675
        ]
      },
      "properties": {
        "tenDoiTuon": "LKL2B 12",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Đào Duy Anh",
        "tuDien": "LKL2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Sân vườn",
        "chieuCao": 7,
        "tuDien2": "LKL2",
        "diaChiLapD": "",
        "longitude": 105.83797885461541,
        "latitude": 21.009481986293675,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 414,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83817465587941,
          21.00901375649673
        ]
      },
      "properties": {
        "tenDoiTuon": "LKL2B 06",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Đào Duy Anh",
        "tuDien": "LKL2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Sân vườn",
        "chieuCao": 7,
        "tuDien2": "LKL2",
        "diaChiLapD": "",
        "longitude": 105.83817465587941,
        "latitude": 21.00901375649673,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 415,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83807541413906,
          21.009128936155946
        ]
      },
      "properties": {
        "tenDoiTuon": "LKL2B 07",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Đào Duy Anh",
        "tuDien": "LKL2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Sân vườn",
        "chieuCao": 7,
        "tuDien2": "LKL2",
        "diaChiLapD": "",
        "longitude": 105.83807541413906,
        "latitude": 21.009128936155946,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 416,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83795471473675,
          21.009254131335375
        ]
      },
      "properties": {
        "tenDoiTuon": "LKL2B 08",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Đào Duy Anh",
        "tuDien": "LKL2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Sân vườn",
        "chieuCao": 7,
        "tuDien2": "LKL2",
        "diaChiLapD": "",
        "longitude": 105.83795471473675,
        "latitude": 21.009254131335375,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 417,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83784206195769,
          21.00937181470926
        ]
      },
      "properties": {
        "tenDoiTuon": "LKL2B 09",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Đào Duy Anh",
        "tuDien": "LKL2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Sân vườn",
        "chieuCao": 7,
        "tuDien2": "LKL2",
        "diaChiLapD": "",
        "longitude": 105.83784206195769,
        "latitude": 21.00937181470926,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 418,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83776159568949,
          21.009499513583815
        ]
      },
      "properties": {
        "tenDoiTuon": "LKL2B 10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Đào Duy Anh",
        "tuDien": "LKL2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Sân vườn",
        "chieuCao": 7,
        "tuDien2": "LKL2",
        "diaChiLapD": "",
        "longitude": 105.83776159568949,
        "latitude": 21.009499513583815,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 419,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83786351962861,
          21.009564614929026
        ]
      },
      "properties": {
        "tenDoiTuon": "LKL2B 11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Đào Duy Anh",
        "tuDien": "LKL2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Sân vườn",
        "chieuCao": 7,
        "tuDien2": "LKL2",
        "diaChiLapD": "",
        "longitude": 105.83786351962861,
        "latitude": 21.009564614929026,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 420,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83755899982921,
          21.003864383745682
        ]
      },
      "properties": {
        "tenDoiTuon": "KLE/1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Phương Mai",
        "tuDien": "KLE",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "KLE",
        "diaChiLapD": "",
        "longitude": 105.83755899982921,
        "latitude": 21.003864383745682,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 421,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8379412146211,
          21.003962039440516
        ]
      },
      "properties": {
        "tenDoiTuon": "KLE/2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Phương Mai",
        "tuDien": "KLE",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "KLE",
        "diaChiLapD": "",
        "longitude": 105.8379412146211,
        "latitude": 21.003962039440516,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 422,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83836500363299,
          21.003978315383304
        ]
      },
      "properties": {
        "tenDoiTuon": "KLE/3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Phương Mai",
        "tuDien": "KLE",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "KLE",
        "diaChiLapD": "",
        "longitude": 105.83836500363299,
        "latitude": 21.003978315383304,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 423,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83862115459812,
          21.00413105876197
        ]
      },
      "properties": {
        "tenDoiTuon": "KLE/4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Phương Mai",
        "tuDien": "KLE",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "KLE",
        "diaChiLapD": "",
        "longitude": 105.83862115459812,
        "latitude": 21.00413105876197,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 424,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8389993460694,
          21.00421118637299
        ]
      },
      "properties": {
        "tenDoiTuon": "KLE/5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Phương Mai",
        "tuDien": "KLE",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "KLE",
        "diaChiLapD": "",
        "longitude": 105.8389993460694,
        "latitude": 21.00421118637299,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 425,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83934803323756,
          21.004286305969075
        ]
      },
      "properties": {
        "tenDoiTuon": "KLE/6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Phương Mai",
        "tuDien": "KLE",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "KLE",
        "diaChiLapD": "",
        "longitude": 105.83934803323756,
        "latitude": 21.004286305969075,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 426,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8400480897906,
          21.00429882589891
        ]
      },
      "properties": {
        "tenDoiTuon": "KLE/8",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Phương Mai",
        "tuDien": "KLE",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "KLE",
        "diaChiLapD": "",
        "longitude": 105.8400480897906,
        "latitude": 21.00429882589891,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 427,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83972086029334,
          21.004296321912697
        ]
      },
      "properties": {
        "tenDoiTuon": "KLE/7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Phương Mai",
        "tuDien": "KLE",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "KLE",
        "diaChiLapD": "",
        "longitude": 105.83972086029334,
        "latitude": 21.004296321912697,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 428,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84037531929681,
          21.004286305969075
        ]
      },
      "properties": {
        "tenDoiTuon": "KLE/9",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Phương Mai",
        "tuDien": "KLE",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "KLE",
        "diaChiLapD": "",
        "longitude": 105.84037531929681,
        "latitude": 21.004286305969075,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 429,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84072400646497,
          21.004283801983483
        ]
      },
      "properties": {
        "tenDoiTuon": "KLE/10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Phương Mai",
        "tuDien": "KLE",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "KLE",
        "diaChiLapD": "",
        "longitude": 105.84072400646497,
        "latitude": 21.004283801983483,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 430,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84097076969401,
          21.00427879401132
        ]
      },
      "properties": {
        "tenDoiTuon": "KLE/11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Phương Mai",
        "tuDien": "KLE",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "KLE",
        "diaChiLapD": "",
        "longitude": 105.84097076969401,
        "latitude": 21.00427879401132,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 431,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84100697953446,
          21.004403993261256
        ]
      },
      "properties": {
        "tenDoiTuon": "KLE/12",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ cạnh đường tầu",
        "tuDien": "KLE",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KLE",
        "diaChiLapD": "",
        "longitude": 105.84100697953446,
        "latitude": 21.004403993261256,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 432,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84022779780811,
          21.004472852802223
        ]
      },
      "properties": {
        "tenDoiTuon": "KLE/13-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 2 Phương Mai",
        "tuDien": "KLE",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KLE",
        "diaChiLapD": "",
        "longitude": 105.84022779780811,
        "latitude": 21.004472852802223,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 433,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84016610699412,
          21.00465188746236
        ]
      },
      "properties": {
        "tenDoiTuon": "KLE/13",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 2 Phương Mai",
        "tuDien": "KLE",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "KLE",
        "diaChiLapD": "",
        "longitude": 105.84016610699412,
        "latitude": 21.00465188746236,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 434,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84042628126825,
          21.004873489510683
        ]
      },
      "properties": {
        "tenDoiTuon": "KLE/14-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 2 Phương Mai",
        "tuDien": "KLE",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KLE",
        "diaChiLapD": "",
        "longitude": 105.84042628126825,
        "latitude": 21.004873489510683,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 435,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84079910832666,
          21.00468318720831
        ]
      },
      "properties": {
        "tenDoiTuon": "KLE/14-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 2 Phương Mai",
        "tuDien": "KLE",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KLE",
        "diaChiLapD": "",
        "longitude": 105.84079910832666,
        "latitude": 21.00468318720831,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 436,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84020097572171,
          21.005375535867064
        ]
      },
      "properties": {
        "tenDoiTuon": "KLE/16",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 2 Phương Mai",
        "tuDien": "KLE",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KLE",
        "diaChiLapD": "",
        "longitude": 105.84020097572171,
        "latitude": 21.005375535867064,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 437,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84012319165612,
          21.005148926623708
        ]
      },
      "properties": {
        "tenDoiTuon": "KLE/15",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 2 Phương Mai",
        "tuDien": "KLE",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KLE",
        "diaChiLapD": "",
        "longitude": 105.84012319165612,
        "latitude": 21.005148926623708,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 438,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84046919662167,
          21.004629351643207
        ]
      },
      "properties": {
        "tenDoiTuon": "KLE/13-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 2 Phương Mai",
        "tuDien": "KLE",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KLE",
        "diaChiLapD": "",
        "longitude": 105.84046919662167,
        "latitude": 21.004629351643207,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 439,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84042359906313,
          21.00447160080973
        ]
      },
      "properties": {
        "tenDoiTuon": "KLE/13-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 2 Phương Mai",
        "tuDien": "KLE",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 6,
        "tuDien2": "KLE",
        "diaChiLapD": "",
        "longitude": 105.84042359906313,
        "latitude": 21.00447160080973,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 440,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84078569728796,
          21.00447160080973
        ]
      },
      "properties": {
        "tenDoiTuon": "KLE/13-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 2 Phương Mai",
        "tuDien": "KLE",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KLE",
        "diaChiLapD": "",
        "longitude": 105.84078569728796,
        "latitude": 21.00447160080973,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 441,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84044773894813,
          21.0051251388921
        ]
      },
      "properties": {
        "tenDoiTuon": "KLE/15-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 2 Phương Mai",
        "tuDien": "KLE",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "KLE",
        "diaChiLapD": "",
        "longitude": 105.84044773894813,
        "latitude": 21.0051251388921,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 442,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84043164569525,
          21.005360512059884
        ]
      },
      "properties": {
        "tenDoiTuon": "KLE/16-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 2 Phương Mai",
        "tuDien": "KLE",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KLE",
        "diaChiLapD": "",
        "longitude": 105.84043164569525,
        "latitude": 21.005360512059884,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 443,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84068913775789,
          21.005360512060236
        ]
      },
      "properties": {
        "tenDoiTuon": "KLE/16-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 2 Phương Mai",
        "tuDien": "KLE",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KLE",
        "diaChiLapD": "",
        "longitude": 105.84068913775789,
        "latitude": 21.005360512060236,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 444,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84079106170344,
          21.00516269846516
        ]
      },
      "properties": {
        "tenDoiTuon": "KLE/15-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 2 Phương Mai",
        "tuDien": "KLE",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KLE",
        "diaChiLapD": "",
        "longitude": 105.84079106170344,
        "latitude": 21.00516269846516,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 445,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84080715495888,
          21.004869733545316
        ]
      },
      "properties": {
        "tenDoiTuon": "KLE/14-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 2 Phương Mai",
        "tuDien": "KLE",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KLE",
        "diaChiLapD": "",
        "longitude": 105.84080715495888,
        "latitude": 21.004869733545316,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 446,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84010978060459,
          21.00487974944978
        ]
      },
      "properties": {
        "tenDoiTuon": "KLE/14",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 2 Phương Mai",
        "tuDien": "KLE",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KLE",
        "diaChiLapD": "",
        "longitude": 105.84010978060459,
        "latitude": 21.00487974944978,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 447,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83564306440309,
          21.008971972548782
        ]
      },
      "properties": {
        "tenDoiTuon": "B8AKL/15-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 43 Phạm Ngọc Thạch",
        "tuDien": "B8AKL",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "B8A Kim Liên",
        "diaChiLapD": "",
        "longitude": 105.83564306440309,
        "latitude": 21.008971972548782,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 448,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83527023735627,
          21.009125962767392
        ]
      },
      "properties": {
        "tenDoiTuon": "B8AKL/15-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "B5-B11",
        "tuDien": "B8AKL",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "B8A Kim Liên",
        "diaChiLapD": "",
        "longitude": 105.83527023735627,
        "latitude": 21.009125962767392,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 449,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83558673802253,
          21.009386368629816
        ]
      },
      "properties": {
        "tenDoiTuon": "B8AKL/15-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "B5-B11",
        "tuDien": "B8AKL",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "B8A Kim Liên",
        "diaChiLapD": "",
        "longitude": 105.83558673802253,
        "latitude": 21.009386368629816,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 450,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8358723932818,
          21.009616727282285
        ]
      },
      "properties": {
        "tenDoiTuon": "B8AKl/15-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "B5-B11",
        "tuDien": "B8AKL",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "B8A Kim Liên",
        "diaChiLapD": "",
        "longitude": 105.8358723932818,
        "latitude": 21.009616727282285,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 451,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83496178331322,
          21.008893099448073
        ]
      },
      "properties": {
        "tenDoiTuon": "B8AKL/15-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "B5-B11",
        "tuDien": "B8AKL",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "B8A Kim Liên",
        "diaChiLapD": "",
        "longitude": 105.83496178331322,
        "latitude": 21.008893099448073,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 452,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83607087673556,
          21.008511252896227
        ]
      },
      "properties": {
        "tenDoiTuon": "B8AKL/13",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 43 Phạm Ngoc Thạch",
        "tuDien": "B8AKL",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "B8A Kim Liên",
        "diaChiLapD": "",
        "longitude": 105.83607087673556,
        "latitude": 21.008511252896227,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 453,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83590994419919,
          21.00868402293277
        ]
      },
      "properties": {
        "tenDoiTuon": "B8AKL/14",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 43 Phạm Ngọc Thạch",
        "tuDien": "B8AKL",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "B8A Kim Liên",
        "diaChiLapD": "",
        "longitude": 105.83590994419919,
        "latitude": 21.00868402293277,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 454,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83549420180154,
          21.00914474205182
        ]
      },
      "properties": {
        "tenDoiTuon": "B8AKL/15",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 43 Phạm Ngọc Thạch",
        "tuDien": "B8AKL",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "B8A Kim Liên",
        "diaChiLapD": "",
        "longitude": 105.83549420180154,
        "latitude": 21.00914474205182,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 455,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8352930361221,
          21.009357573772622
        ]
      },
      "properties": {
        "tenDoiTuon": "B8AKL/16",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 43 Phạm Ngọc Thạch",
        "tuDien": "B8AKL",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "B8A Kim Liên",
        "diaChiLapD": "",
        "longitude": 105.8352930361221,
        "latitude": 21.009357573772622,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 456,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83589385094376,
          21.008386057092554
        ]
      },
      "properties": {
        "tenDoiTuon": "B8AKL/13-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "B5-B11",
        "tuDien": "B8AKL",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 8,
        "tuDien2": "B8A Kim Liên",
        "diaChiLapD": "",
        "longitude": 105.83589385094376,
        "latitude": 21.008386057092554,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 457,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83564976992244,
          21.008175727906135
        ]
      },
      "properties": {
        "tenDoiTuon": "B8AKL/13-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "B5-B11",
        "tuDien": "B8AKL",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 8,
        "tuDien2": "B8A Kim Liên",
        "diaChiLapD": "",
        "longitude": 105.83564976992244,
        "latitude": 21.008175727906135,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 458,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8357409650306,
          21.008603897723685
        ]
      },
      "properties": {
        "tenDoiTuon": "B8AKL/14-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "B5-B11",
        "tuDien": "B8AKL",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "B8A Kim Liên",
        "diaChiLapD": "",
        "longitude": 105.8357409650306,
        "latitude": 21.008603897723685,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 459,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83545396866744,
          21.008373537506497
        ]
      },
      "properties": {
        "tenDoiTuon": "B8AKL/14-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "B5-B11",
        "tuDien": "B8AKL",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "B8A Kim Liên",
        "diaChiLapD": "",
        "longitude": 105.83545396866744,
        "latitude": 21.008373537506497,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 460,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83607892336778,
          21.00889435140261
        ]
      },
      "properties": {
        "tenDoiTuon": "B8AKL/14-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "B5-B11",
        "tuDien": "B8AKL",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "B8A Kim Liên",
        "diaChiLapD": "",
        "longitude": 105.83607892336778,
        "latitude": 21.00889435140261,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 461,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83633373322006,
          21.00910217567121
        ]
      },
      "properties": {
        "tenDoiTuon": "B8AKL/14-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "B5-B11",
        "tuDien": "B8AKL",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "B8A Kim Liên",
        "diaChiLapD": "",
        "longitude": 105.83633373322006,
        "latitude": 21.00910217567121,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 462,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83624790253637,
          21.008679015108306
        ]
      },
      "properties": {
        "tenDoiTuon": "B8AKL/13-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "B5-B11",
        "tuDien": "B8AKL",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 8,
        "tuDien2": "B8A Kim Liên",
        "diaChiLapD": "",
        "longitude": 105.83624790253637,
        "latitude": 21.008679015108306,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 463,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8363176399646,
          21.008704054228108
        ]
      },
      "properties": {
        "tenDoiTuon": "B8AKL/13-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "B5-B11",
        "tuDien": "B8AKL",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "B8A Kim Liên",
        "diaChiLapD": "",
        "longitude": 105.8363176399646,
        "latitude": 21.008704054228108,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 464,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83651511761067,
          21.0088815188695
        ]
      },
      "properties": {
        "tenDoiTuon": "B8AKL/14-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "B5-B11",
        "tuDien": "B8AKL",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 8,
        "tuDien2": "B8A Kim Liên",
        "diaChiLapD": "",
        "longitude": 105.83651511761067,
        "latitude": 21.0088815188695,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 465,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83671460689908,
          21.00905710537216
        ]
      },
      "properties": {
        "tenDoiTuon": "B8AKL/13-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "B5-B11",
        "tuDien": "B8AKL",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 8,
        "tuDien2": "B8A Kim Liên",
        "diaChiLapD": "",
        "longitude": 105.83671460689908,
        "latitude": 21.00905710537216,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 466,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83647672852922,
          21.009343019848405
        ]
      },
      "properties": {
        "tenDoiTuon": "B8AKL/12",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 21 Phạm Ngọc Thạch",
        "tuDien": "B8AKL",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "B8A Kim Liên",
        "diaChiLapD": "",
        "longitude": 105.83647672852922,
        "latitude": 21.009343019848405,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 467,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83620716652537,
          21.009696069480345
        ]
      },
      "properties": {
        "tenDoiTuon": "B8AKL/11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 21 Phạm Ngọc Thạch",
        "tuDien": "B8AKL",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "B8A Kim Liên",
        "diaChiLapD": "",
        "longitude": 105.83620716652537,
        "latitude": 21.009696069480345,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 468,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8392514737319,
          21.004390221344543
        ]
      },
      "properties": {
        "tenDoiTuon": "KLE/17",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 4 Phương Mai",
        "tuDien": "KLE",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KLE",
        "diaChiLapD": "",
        "longitude": 105.8392514737319,
        "latitude": 21.004390221344543,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 469,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8391924651346,
          21.004635611592203
        ]
      },
      "properties": {
        "tenDoiTuon": "KLE/18",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 4 Phương Mai",
        "tuDien": "KLE",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KLE",
        "diaChiLapD": "",
        "longitude": 105.8391924651346,
        "latitude": 21.004635611592203,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 470,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8394727559784,
          21.004645627511543
        ]
      },
      "properties": {
        "tenDoiTuon": "KLE/17-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 4 Phương Mai",
        "tuDien": "KLE",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KLE",
        "diaChiLapD": "",
        "longitude": 105.8394727559784,
        "latitude": 21.004645627511543,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 471,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83979462105117,
          21.0046481314919
        ]
      },
      "properties": {
        "tenDoiTuon": "KLE/17-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 4 Phương Mai",
        "tuDien": "KLE",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KLE",
        "diaChiLapD": "",
        "longitude": 105.83979462105117,
        "latitude": 21.0046481314919,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 472,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83939765411671,
          21.004881001436335
        ]
      },
      "properties": {
        "tenDoiTuon": "KLE/18-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 4 Phương Mai",
        "tuDien": "KLE",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KLE",
        "diaChiLapD": "",
        "longitude": 105.83939765411671,
        "latitude": 21.004881001436335,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 473,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83988581615931,
          21.004883505411893
        ]
      },
      "properties": {
        "tenDoiTuon": "KLE/18-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 4 Phương Mai",
        "tuDien": "KLE",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KLE",
        "diaChiLapD": "",
        "longitude": 105.83988581615931,
        "latitude": 21.004883505411893,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 474,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83965782839344,
          21.004878497459877
        ]
      },
      "properties": {
        "tenDoiTuon": "KLE/18-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 4 Phương Mai",
        "tuDien": "KLE",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KLE",
        "diaChiLapD": "",
        "longitude": 105.83965782839344,
        "latitude": 21.004878497459877,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 475,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83911333996132,
          21.004901033242234
        ]
      },
      "properties": {
        "tenDoiTuon": "KLE/19",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 4 Phương Mai",
        "tuDien": "KLE",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KLE",
        "diaChiLapD": "",
        "longitude": 105.83911333996132,
        "latitude": 21.004901033242234,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 476,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8390114160222,
          21.004708226996183
        ]
      },
      "properties": {
        "tenDoiTuon": "KLE/18-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 4 Phương Mai",
        "tuDien": "KLE",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KLE",
        "diaChiLapD": "",
        "longitude": 105.8390114160222,
        "latitude": 21.004708226996183,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 477,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83864395339087,
          21.00462559567135
        ]
      },
      "properties": {
        "tenDoiTuon": "KLE/18-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 4 Phương Mai",
        "tuDien": "KLE",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KLE",
        "diaChiLapD": "",
        "longitude": 105.83864395339087,
        "latitude": 21.00462559567135,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 478,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8384347410792,
          21.004613075769758
        ]
      },
      "properties": {
        "tenDoiTuon": "KLE/4-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 4 Phương Mai",
        "tuDien": "KLE",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KLE",
        "diaChiLapD": "",
        "longitude": 105.8384347410792,
        "latitude": 21.004613075769758,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 479,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83903823810861,
          21.00518147824672
        ]
      },
      "properties": {
        "tenDoiTuon": "KLE/20",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 4 Phương Mai",
        "tuDien": "KLE",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KLE",
        "diaChiLapD": "",
        "longitude": 105.83903823810861,
        "latitude": 21.00518147824672,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 480,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83891485649856,
          21.0051439186779
        ]
      },
      "properties": {
        "tenDoiTuon": "KLE/20-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 4 Phương Mai",
        "tuDien": "KLE",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KLE",
        "diaChiLapD": "",
        "longitude": 105.83891485649856,
        "latitude": 21.0051439186779,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 481,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83866809326952,
          21.005083823349086
        ]
      },
      "properties": {
        "tenDoiTuon": "KLE/20-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 4 Phương Mai",
        "tuDien": "KLE",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KLE",
        "diaChiLapD": "",
        "longitude": 105.83866809326952,
        "latitude": 21.005083823349086,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 482,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8384347410792,
          21.005026231970096
        ]
      },
      "properties": {
        "tenDoiTuon": "KLE/20-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 4 Phương Mai",
        "tuDien": "KLE",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KLE",
        "diaChiLapD": "",
        "longitude": 105.8384347410792,
        "latitude": 21.005026231970096,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 483,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83896581847262,
          21.005391811654004
        ]
      },
      "properties": {
        "tenDoiTuon": "KLE/20-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 4 Phương Mai",
        "tuDien": "KLE",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KLE",
        "diaChiLapD": "",
        "longitude": 105.83896581847262,
        "latitude": 21.005391811654004,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 484,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83860908467226,
          21.00533422039304
        ]
      },
      "properties": {
        "tenDoiTuon": "KLE/20-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 4 Phương Mai",
        "tuDien": "KLE",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KLE",
        "diaChiLapD": "",
        "longitude": 105.83860908467226,
        "latitude": 21.00533422039304,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 485,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8383140416769,
          21.005261605293708
        ]
      },
      "properties": {
        "tenDoiTuon": "KLE/20-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 4 Phương Mai",
        "tuDien": "KLE",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KLE",
        "diaChiLapD": "",
        "longitude": 105.8383140416769,
        "latitude": 21.005261605293708,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 486,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83893899637724,
          21.00556458530185
        ]
      },
      "properties": {
        "tenDoiTuon": "KLE/21",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 4 Phương Mai",
        "tuDien": "KLE",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KLE",
        "diaChiLapD": "",
        "longitude": 105.83893899637724,
        "latitude": 21.00556458530185,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 487,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83952908235895,
          21.005592128908827
        ]
      },
      "properties": {
        "tenDoiTuon": "KLE/21-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 4 Phương Mai",
        "tuDien": "KLE",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KLE",
        "diaChiLapD": "",
        "longitude": 105.83952908235895,
        "latitude": 21.005592128908827,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 488,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83914552647218,
          21.005597136836887
        ]
      },
      "properties": {
        "tenDoiTuon": "KLE/21-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 4 Phương Mai",
        "tuDien": "KLE",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KLE",
        "diaChiLapD": "",
        "longitude": 105.83914552647218,
        "latitude": 21.005597136836887,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 489,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.838292584006,
          21.00546442668999
        ]
      },
      "properties": {
        "tenDoiTuon": "KLE/4-7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 6+8 Phương Mai",
        "tuDien": "KLE",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KLE",
        "diaChiLapD": "",
        "longitude": 105.838292584006,
        "latitude": 21.00546442668999,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 490,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8386064024645,
          21.005544553585
        ]
      },
      "properties": {
        "tenDoiTuon": "KLE/4-8",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 6+8 Phương Mai",
        "tuDien": "KLE",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KLE",
        "diaChiLapD": "",
        "longitude": 105.8386064024645,
        "latitude": 21.005544553585,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 491,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83886121231679,
          21.005607152692527
        ]
      },
      "properties": {
        "tenDoiTuon": "KLE/4-9",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 6+8 Phương Mai",
        "tuDien": "KLE",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KLE",
        "diaChiLapD": "",
        "longitude": 105.83886121231679,
        "latitude": 21.005607152692527,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 492,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83908115345942,
          21.005867564695635
        ]
      },
      "properties": {
        "tenDoiTuon": "KLE/22-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 4 Phương Mai",
        "tuDien": "KLE",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KLE",
        "diaChiLapD": "",
        "longitude": 105.83908115345942,
        "latitude": 21.005867564695635,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 493,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83939228970122,
          21.00583250925997
        ]
      },
      "properties": {
        "tenDoiTuon": "KLE/22-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 4 Phương Mai",
        "tuDien": "KLE",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KLE",
        "diaChiLapD": "",
        "longitude": 105.83939228970122,
        "latitude": 21.00583250925997,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 494,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83959881979617,
          21.005807469658276
        ]
      },
      "properties": {
        "tenDoiTuon": "KLE/22-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 4 Phương Mai",
        "tuDien": "KLE",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KLE",
        "diaChiLapD": "",
        "longitude": 105.83959881979617,
        "latitude": 21.005807469658276,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 495,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83877538162413,
          21.005892604287254
        ]
      },
      "properties": {
        "tenDoiTuon": "KLE/22",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 4 Phương Mai",
        "tuDien": "KLE",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KLE",
        "diaChiLapD": "",
        "longitude": 105.83877538162413,
        "latitude": 21.005892604287254,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 496,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83790902811921,
          21.005947691374214
        ]
      },
      "properties": {
        "tenDoiTuon": "KLE/22-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 4 Phương Mai",
        "tuDien": "KLE",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KLE",
        "diaChiLapD": "",
        "longitude": 105.83790902811921,
        "latitude": 21.005947691374214,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 497,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83847497422228,
          21.005867564695635
        ]
      },
      "properties": {
        "tenDoiTuon": "KLE/22-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 4 Phương Mai",
        "tuDien": "KLE",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KLE",
        "diaChiLapD": "",
        "longitude": 105.83847497422228,
        "latitude": 21.005867564695635,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 498,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83823357540871,
          21.005900116163755
        ]
      },
      "properties": {
        "tenDoiTuon": "KLE/22-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 4 Phương Mai",
        "tuDien": "KLE",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KLE",
        "diaChiLapD": "",
        "longitude": 105.83823357540871,
        "latitude": 21.005900116163755,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 499,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83792512137464,
          21.006178055336733
        ]
      },
      "properties": {
        "tenDoiTuon": "KLE/24-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 4 Phương Mai",
        "tuDien": "KLE",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KLE",
        "diaChiLapD": "",
        "longitude": 105.83792512137464,
        "latitude": 21.006178055336733,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 500,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83761934953935,
          21.00623314231829
        ]
      },
      "properties": {
        "tenDoiTuon": "KLE/24-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 4 Phương Mai",
        "tuDien": "KLE",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KLE",
        "diaChiLapD": "",
        "longitude": 105.83761934953935,
        "latitude": 21.00623314231829,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 501,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83769445139205,
          21.00649104928035
        ]
      },
      "properties": {
        "tenDoiTuon": "KLE/29",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 4 Phương Mai",
        "tuDien": "KLE",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KLE",
        "diaChiLapD": "",
        "longitude": 105.83769445139205,
        "latitude": 21.00649104928035,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 502,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83800022322735,
          21.00644848214264
        ]
      },
      "properties": {
        "tenDoiTuon": "KLE/28",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 4 Phương Mai",
        "tuDien": "KLE",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KLE",
        "diaChiLapD": "",
        "longitude": 105.83800022322735,
        "latitude": 21.00644848214264,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 503,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83801363426606,
          21.006578687466433
        ]
      },
      "properties": {
        "tenDoiTuon": "KLE/30",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 4 Phương Mai",
        "tuDien": "KLE",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KLE",
        "diaChiLapD": "",
        "longitude": 105.83801363426606,
        "latitude": 21.006578687466433,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 504,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83839182573736,
          21.006120464379258
        ]
      },
      "properties": {
        "tenDoiTuon": "KLE/24-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 4 Phương Mai",
        "tuDien": "KLE",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KLE",
        "diaChiLapD": "",
        "longitude": 105.83839182573736,
        "latitude": 21.006120464379258,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 505,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83834891039551,
          21.006413426843473
        ]
      },
      "properties": {
        "tenDoiTuon": "KLE/27",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 4 Phương Mai",
        "tuDien": "KLE",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KLE",
        "diaChiLapD": "",
        "longitude": 105.83834891039551,
        "latitude": 21.006413426843473,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 506,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83847765643002,
          21.006533616405907
        ]
      },
      "properties": {
        "tenDoiTuon": "KLE/30-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 4 Phương Mai",
        "tuDien": "KLE",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KLE",
        "diaChiLapD": "",
        "longitude": 105.83847765643002,
        "latitude": 21.006533616405907,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 507,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83824430423968,
          21.006556151938288
        ]
      },
      "properties": {
        "tenDoiTuon": "KLE/30-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 4 Phương Mai",
        "tuDien": "KLE",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KLE",
        "diaChiLapD": "",
        "longitude": 105.83824430423968,
        "latitude": 21.006556151938288,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 508,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83872173745131,
          21.006085409002992
        ]
      },
      "properties": {
        "tenDoiTuon": "KLE/24",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 4 Phương Mai",
        "tuDien": "KLE",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KLE",
        "diaChiLapD": "",
        "longitude": 105.83872173745131,
        "latitude": 21.006085409002992,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 509,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83876465279317,
          21.00626819765984
        ]
      },
      "properties": {
        "tenDoiTuon": "KLE/23",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 4 Phương Mai",
        "tuDien": "KLE",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KLE",
        "diaChiLapD": "",
        "longitude": 105.83876465279317,
        "latitude": 21.00626819765984,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 510,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83903555590086,
          21.00623063836452
        ]
      },
      "properties": {
        "tenDoiTuon": "KLE/23-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 4 Phương Mai",
        "tuDien": "KLE",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KLE",
        "diaChiLapD": "",
        "longitude": 105.83903555590086,
        "latitude": 21.00623063836452,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 511,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83944861609076,
          21.0061530157922
        ]
      },
      "properties": {
        "tenDoiTuon": "KLE/23-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 4 Phương Mai",
        "tuDien": "KLE",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KLE",
        "diaChiLapD": "",
        "longitude": 105.83944861609076,
        "latitude": 21.0061530157922,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 512,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.839384243078,
          21.00599526656948
        ]
      },
      "properties": {
        "tenDoiTuon": "KLE/22-7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 4 Phương Mai",
        "tuDien": "KLE",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KLE",
        "diaChiLapD": "",
        "longitude": 105.839384243078,
        "latitude": 21.00599526656948,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 513,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8396953793198,
          21.00610293669214
        ]
      },
      "properties": {
        "tenDoiTuon": "KLE/23-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 4 Phương Mai",
        "tuDien": "KLE",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 6,
        "tuDien2": "KLE",
        "diaChiLapD": "",
        "longitude": 105.8396953793198,
        "latitude": 21.00610293669214,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 514,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8394647093462,
          21.006466009789175
        ]
      },
      "properties": {
        "tenDoiTuon": "KLE/25-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 4 Phương Mai",
        "tuDien": "KLE",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KLE",
        "diaChiLapD": "",
        "longitude": 105.8394647093462,
        "latitude": 21.006466009789175,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 515,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8391535730954,
          21.006506072972716
        ]
      },
      "properties": {
        "tenDoiTuon": "KLE/25-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 4 Phương Mai",
        "tuDien": "KLE",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KLE",
        "diaChiLapD": "",
        "longitude": 105.8391535730954,
        "latitude": 21.006506072972716,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 516,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8387941570873,
          21.00654613614635
        ]
      },
      "properties": {
        "tenDoiTuon": "KLE/25",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 4 Phương Mai",
        "tuDien": "KLE",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KLE",
        "diaChiLapD": "",
        "longitude": 105.8387941570873,
        "latitude": 21.00654613614635,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 517,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83872978407454,
          21.00666632560188
        ]
      },
      "properties": {
        "tenDoiTuon": "KLE/26",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 4 Phương Mai",
        "tuDien": "KLE",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KLE",
        "diaChiLapD": "",
        "longitude": 105.83872978407454,
        "latitude": 21.00666632560188,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 518,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83917234855859,
          21.0066713334939
        ]
      },
      "properties": {
        "tenDoiTuon": "KLE/25-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 4 Phương Mai",
        "tuDien": "KLE",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KLE",
        "diaChiLapD": "",
        "longitude": 105.83917234855859,
        "latitude": 21.0066713334939,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 519,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83947543817717,
          21.006638782193193
        ]
      },
      "properties": {
        "tenDoiTuon": "KLE/25-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 4 Phương Mai",
        "tuDien": "KLE",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KLE",
        "diaChiLapD": "",
        "longitude": 105.83947543817717,
        "latitude": 21.006638782193193,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 520,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83854739385825,
          21.006939255463944
        ]
      },
      "properties": {
        "tenDoiTuon": "KLE/26-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 4 Phương Mai",
        "tuDien": "KLE",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KLE",
        "diaChiLapD": "",
        "longitude": 105.83854739385825,
        "latitude": 21.006939255463944,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 521,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83813969809283,
          21.006989334284103
        ]
      },
      "properties": {
        "tenDoiTuon": "KLE/26-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 4 Phương Mai",
        "tuDien": "KLE",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KLE",
        "diaChiLapD": "",
        "longitude": 105.83813969809283,
        "latitude": 21.006989334284103,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 522,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83571095783259,
          21.00651608876818
        ]
      },
      "properties": {
        "tenDoiTuon": "B8AKL/22",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 9 Lương Đình Của",
        "tuDien": "B8AKL",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "B8A Kim Liên",
        "diaChiLapD": "",
        "longitude": 105.83571095783259,
        "latitude": 21.00651608876818,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 523,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83605025727366,
          21.006816562285998
        ]
      },
      "properties": {
        "tenDoiTuon": "B8AKL/21",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 9 Lương Đình Của",
        "tuDien": "B8AKL",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "B8A Kim Liên",
        "diaChiLapD": "",
        "longitude": 105.83605025727366,
        "latitude": 21.006816562285998,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 524,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83643783647204,
          21.00711828716817
        ]
      },
      "properties": {
        "tenDoiTuon": "B8AKL/23",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 9 Lương Đình Của",
        "tuDien": "B8AKL",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "B8A Kim Liên",
        "diaChiLapD": "",
        "longitude": 105.83643783647204,
        "latitude": 21.00711828716817,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 525,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83654244262789,
          21.007240980098846
        ]
      },
      "properties": {
        "tenDoiTuon": "B8AKL/24",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 9 Lương Đình Của",
        "tuDien": "B8AKL",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "B8A Kim Liên",
        "diaChiLapD": "",
        "longitude": 105.83654244262789,
        "latitude": 21.007240980098846,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 526,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83674092609061,
          21.007418759473275
        ]
      },
      "properties": {
        "tenDoiTuon": "B8AKL/24-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 9 Lương Đình Của",
        "tuDien": "B8AKL",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "B8A Kim Liên",
        "diaChiLapD": "",
        "longitude": 105.83674092609061,
        "latitude": 21.007418759473275,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 527,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83693538625072,
          21.007642861339555
        ]
      },
      "properties": {
        "tenDoiTuon": "B8AKL/24-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 9 Lương Đình Của",
        "tuDien": "B8AKL",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "B8A Kim Liên",
        "diaChiLapD": "",
        "longitude": 105.83693538625072,
        "latitude": 21.007642861339555,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 528,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8370748611162,
          21.00775804205711
        ]
      },
      "properties": {
        "tenDoiTuon": "B8KL/24-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 9 Lương Đình Của",
        "tuDien": "B8AKL",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "B8A Kim Liên",
        "diaChiLapD": "",
        "longitude": 105.8370748611162,
        "latitude": 21.00775804205711,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 529,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83850313739458,
          21.004186146499215
        ]
      },
      "properties": {
        "tenDoiTuon": "KLE/4-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 6+8 Phương Mai",
        "tuDien": "KLE",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KLE",
        "diaChiLapD": "",
        "longitude": 105.83850313739458,
        "latitude": 21.004186146499215,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 530,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8384243475444,
          21.004292252933933
        ]
      },
      "properties": {
        "tenDoiTuon": "KLE/2-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 6+8 Phương Mai",
        "tuDien": "KLE",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KLE",
        "diaChiLapD": "",
        "longitude": 105.8384243475444,
        "latitude": 21.004292252933933,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 531,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83772294987851,
          21.00411697382374
        ]
      },
      "properties": {
        "tenDoiTuon": "KLE/2-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 6+8 Phương Mai",
        "tuDien": "KLE",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KLE",
        "diaChiLapD": "",
        "longitude": 105.83772294987851,
        "latitude": 21.00411697382374,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 532,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83799251189133,
          21.0041432657035
        ]
      },
      "properties": {
        "tenDoiTuon": "KLE/2-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 6+8 Phương Mai",
        "tuDien": "KLE",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KLE",
        "diaChiLapD": "",
        "longitude": 105.83799251189133,
        "latitude": 21.0041432657035,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 533,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8382875548777,
          21.004213377359456
        ]
      },
      "properties": {
        "tenDoiTuon": "KLE/2-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 6+8 Phương Mai",
        "tuDien": "KLE",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KLE",
        "diaChiLapD": "",
        "longitude": 105.8382875548777,
        "latitude": 21.004213377359456,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 534,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83765723576187,
          21.00438364838866
        ]
      },
      "properties": {
        "tenDoiTuon": "KLE/2-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 6+8 Phương Mai",
        "tuDien": "KLE",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KLE",
        "diaChiLapD": "",
        "longitude": 105.83765723576187,
        "latitude": 21.00438364838866,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 535,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83793350328506,
          21.00445751590698
        ]
      },
      "properties": {
        "tenDoiTuon": "KLE/2-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 6+8 Phương Mai",
        "tuDien": "KLE",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KLE",
        "diaChiLapD": "",
        "longitude": 105.83793350328506,
        "latitude": 21.00445751590698,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 536,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83822318186495,
          21.004528879406443
        ]
      },
      "properties": {
        "tenDoiTuon": "KLE/2-7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 6+8 Phương Mai",
        "tuDien": "KLE",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KLE",
        "diaChiLapD": "",
        "longitude": 105.83822318186495,
        "latitude": 21.004528879406443,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 537,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83835729231491,
          21.00486566458557
        ]
      },
      "properties": {
        "tenDoiTuon": "KLE/4-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 6+8 Phương Mai",
        "tuDien": "KLE",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KLE",
        "diaChiLapD": "",
        "longitude": 105.83835729231491,
        "latitude": 21.00486566458557,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 538,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.838243298432,
          21.005091022272833
        ]
      },
      "properties": {
        "tenDoiTuon": "KLE/4-10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 6+8 Phương Mai",
        "tuDien": "KLE",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KLE",
        "diaChiLapD": "",
        "longitude": 105.838243298432,
        "latitude": 21.005091022272833,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 539,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8380609082157,
          21.005067234533378
        ]
      },
      "properties": {
        "tenDoiTuon": "KLE/4-11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 6+8 Phương Mai",
        "tuDien": "KLE",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "KLE",
        "diaChiLapD": "",
        "longitude": 105.8380609082157,
        "latitude": 21.005067234533378,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 540,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83789729347157,
          21.00502842294963
        ]
      },
      "properties": {
        "tenDoiTuon": "KLE/4-12",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 6+8 Phương Mai",
        "tuDien": "KLE",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "KLE",
        "diaChiLapD": "",
        "longitude": 105.83789729347157,
        "latitude": 21.00502842294963,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 541,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83805822600796,
          21.005152369584685
        ]
      },
      "properties": {
        "tenDoiTuon": "KLE/4-13",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 6+8 Phương Mai",
        "tuDien": "KLE",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "KLE",
        "diaChiLapD": "",
        "longitude": 105.83805822600796,
        "latitude": 21.005152369584685,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 542,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83789058794324,
          21.00511606199475
        ]
      },
      "properties": {
        "tenDoiTuon": "KLE/4-14",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 6+8 Phương Mai",
        "tuDien": "KLE",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "KLE",
        "diaChiLapD": "",
        "longitude": 105.83789058794324,
        "latitude": 21.00511606199475,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 543,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83810382355755,
          21.005382734774223
        ]
      },
      "properties": {
        "tenDoiTuon": "KLE/4-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 6+8 Phương Mai",
        "tuDien": "KLE",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KLE",
        "diaChiLapD": "",
        "longitude": 105.83810382355755,
        "latitude": 21.005382734774223,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 544,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83825402727194,
          21.005203700990098
        ]
      },
      "properties": {
        "tenDoiTuon": "KLE/4-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 6+8 Phương Mai",
        "tuDien": "KLE",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KLE",
        "diaChiLapD": "",
        "longitude": 105.83825402727194,
        "latitude": 21.005203700990098,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 545,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83842300644054,
          21.004485059718313
        ]
      },
      "properties": {
        "tenDoiTuon": "KLE/4-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 6+8 Phương Mai",
        "tuDien": "KLE",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KLE",
        "diaChiLapD": "",
        "longitude": 105.83842300644054,
        "latitude": 21.004485059718313,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 546,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8339460643748,
          21.015320963849103
        ]
      },
      "properties": {
        "tenDoiTuon": "17-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 164 La Thành",
        "tuDien": "LAT3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "La Thành 3",
        "diaChiLapD": "",
        "longitude": 105.8339460643748,
        "latitude": 21.015320963849103,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 547,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83434303130926,
          21.015551313336754
        ]
      },
      "properties": {
        "tenDoiTuon": "17-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 164 La Thành",
        "tuDien": "LAT3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "La Thành 3",
        "diaChiLapD": "",
        "longitude": 105.83434303130926,
        "latitude": 21.015551313336754,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 548,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8345281037243,
          21.01570404486595
        ]
      },
      "properties": {
        "tenDoiTuon": "17-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 164 La Thành",
        "tuDien": "LAT3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "La Thành 3",
        "diaChiLapD": "",
        "longitude": 105.8345281037243,
        "latitude": 21.01570404486595,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 549,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.834664896391,
          21.015618915836132
        ]
      },
      "properties": {
        "tenDoiTuon": "17-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 164 La Thành",
        "tuDien": "LAT3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "La Thành 3",
        "diaChiLapD": "",
        "longitude": 105.834664896391,
        "latitude": 21.015618915836132,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 550,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83410163249569,
          21.015418612044826
        ]
      },
      "properties": {
        "tenDoiTuon": "17-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 164 La Thành",
        "tuDien": "LAT3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "La Thành 3",
        "diaChiLapD": "",
        "longitude": 105.83410163249569,
        "latitude": 21.015418612044826,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 551,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83430279816618,
          21.01539607785123
        ]
      },
      "properties": {
        "tenDoiTuon": "17-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 164 La Thành",
        "tuDien": "LAT3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "La Thành 3",
        "diaChiLapD": "",
        "longitude": 105.83430279816618,
        "latitude": 21.01539607785123,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 552,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83497603263281,
          21.015626427223705
        ]
      },
      "properties": {
        "tenDoiTuon": "17-7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 164 La Thành",
        "tuDien": "LAT3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "La Thành 3",
        "diaChiLapD": "",
        "longitude": 105.83497603263281,
        "latitude": 21.015626427223705,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 553,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83744131793985,
          21.00384904678542
        ]
      },
      "properties": {
        "tenDoiTuon": "KLE/39",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 34 Phương Mai",
        "tuDien": "KLE",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KLE",
        "diaChiLapD": "",
        "longitude": 105.83744131793985,
        "latitude": 21.00384904678542,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 554,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83740510812635,
          21.004134501743266
        ]
      },
      "properties": {
        "tenDoiTuon": "KLE/38",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 34 Phương Mai",
        "tuDien": "KLE",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KLE",
        "diaChiLapD": "",
        "longitude": 105.83740510812635,
        "latitude": 21.004134501743266,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 555,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83750703207444,
          21.004394916316354
        ]
      },
      "properties": {
        "tenDoiTuon": "KLE/37",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 34 Phương Mai",
        "tuDien": "KLE",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KLE",
        "diaChiLapD": "",
        "longitude": 105.83750703207444,
        "latitude": 21.004394916316354,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 556,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83777927627705,
          21.004786789314156
        ]
      },
      "properties": {
        "tenDoiTuon": "KLE/35",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 34 Phương Mai",
        "tuDien": "KLE",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KLE",
        "diaChiLapD": "",
        "longitude": 105.83777927627705,
        "latitude": 21.004786789314156,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 557,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83777391186155,
          21.00501214712138
        ]
      },
      "properties": {
        "tenDoiTuon": "KLE/34",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 34 Phương Mai",
        "tuDien": "KLE",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KLE",
        "diaChiLapD": "",
        "longitude": 105.83777391186155,
        "latitude": 21.00501214712138,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 558,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83773904314292,
          21.00530761573008
        ]
      },
      "properties": {
        "tenDoiTuon": "KLE/33",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 34 Phương Mai",
        "tuDien": "KLE",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KLE",
        "diaChiLapD": "",
        "longitude": 105.83773904314292,
        "latitude": 21.00530761573008,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 559,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83769612780108,
          21.005554256450854
        ]
      },
      "properties": {
        "tenDoiTuon": "KLE/32",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 34 Phương Mai",
        "tuDien": "KLE",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KLE",
        "diaChiLapD": "",
        "longitude": 105.83769612780108,
        "latitude": 21.005554256450854,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 560,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83759152165425,
          21.0058722596218
        ]
      },
      "properties": {
        "tenDoiTuon": "KLE/31",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 34 Phương Mai",
        "tuDien": "KLE",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KLE",
        "diaChiLapD": "",
        "longitude": 105.83759152165425,
        "latitude": 21.0058722596218,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 561,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83764918914765,
          21.004605250832313
        ]
      },
      "properties": {
        "tenDoiTuon": "KLE/36",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 34 Phương Mai",
        "tuDien": "KLE",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KLE",
        "diaChiLapD": "",
        "longitude": 105.83764918914765,
        "latitude": 21.004605250832313,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 562,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83832778803874,
          21.003786446940378
        ]
      },
      "properties": {
        "tenDoiTuon": "KLE/40",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 15 Phương Mai",
        "tuDien": "KLE",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KLE",
        "diaChiLapD": "",
        "longitude": 105.83832778803874,
        "latitude": 21.003786446940378,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 563,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83804481498721,
          21.003722595071135
        ]
      },
      "properties": {
        "tenDoiTuon": "KLE/40-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 15 Phương Mai",
        "tuDien": "KLE",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KLE",
        "diaChiLapD": "",
        "longitude": 105.83804481498721,
        "latitude": 21.003722595071135,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 564,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83807566038523,
          21.00360115124645
        ]
      },
      "properties": {
        "tenDoiTuon": "KLE/40-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 15 Phương Mai",
        "tuDien": "KLE",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KLE",
        "diaChiLapD": "",
        "longitude": 105.83807566038523,
        "latitude": 21.00360115124645,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 565,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83811455241545,
          21.003472195323745
        ]
      },
      "properties": {
        "tenDoiTuon": "KLE/41-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 15 Phương Mai",
        "tuDien": "KLE",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KLE",
        "diaChiLapD": "",
        "longitude": 105.83811455241545,
        "latitude": 21.003472195323745,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 566,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83755262963298,
          21.00348596732097
        ]
      },
      "properties": {
        "tenDoiTuon": "KLE/41-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 15 Phương Mai",
        "tuDien": "KLE",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KLE",
        "diaChiLapD": "",
        "longitude": 105.83755262963298,
        "latitude": 21.00348596732097,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 567,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8376102971264,
          21.003095342910438
        ]
      },
      "properties": {
        "tenDoiTuon": "KLE/41-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 15 Phương Mai",
        "tuDien": "KLE",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KLE",
        "diaChiLapD": "",
        "longitude": 105.8376102971264,
        "latitude": 21.003095342910438,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 568,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8377564775112,
          21.003136659002646
        ]
      },
      "properties": {
        "tenDoiTuon": "KLE/41-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 15 Phương Mai",
        "tuDien": "KLE",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KLE",
        "diaChiLapD": "",
        "longitude": 105.8377564775112,
        "latitude": 21.003136659002646,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 569,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83802469842016,
          21.00321177913962
        ]
      },
      "properties": {
        "tenDoiTuon": "KLE/41-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 15 Phương Mai",
        "tuDien": "KLE",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KLE",
        "diaChiLapD": "",
        "longitude": 105.83802469842016,
        "latitude": 21.00321177913962,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 570,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83830498925496,
          21.003318199270204
        ]
      },
      "properties": {
        "tenDoiTuon": "KLE/41",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 15 Phương Mai",
        "tuDien": "KLE",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KLE",
        "diaChiLapD": "",
        "longitude": 105.83830498925496,
        "latitude": 21.003318199270204,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 571,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83737828603994,
          21.00300770267857
        ]
      },
      "properties": {
        "tenDoiTuon": "KLE/41-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 15 Phương Mai",
        "tuDien": "KLE",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KLE",
        "diaChiLapD": "",
        "longitude": 105.83737828603994,
        "latitude": 21.00300770267857,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 572,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8369665669539,
          21.002910046360093
        ]
      },
      "properties": {
        "tenDoiTuon": "KLE/41-7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 15 Phương Mai",
        "tuDien": "KLE",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KLE",
        "diaChiLapD": "",
        "longitude": 105.8369665669539,
        "latitude": 21.002910046360093,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 573,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83694242707526,
          21.00300269466443
        ]
      },
      "properties": {
        "tenDoiTuon": "KLE/41-8",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 15 Phương Mai",
        "tuDien": "KLE",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "KLE",
        "diaChiLapD": "",
        "longitude": 105.83694242707526,
        "latitude": 21.00300269466443,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 574,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83743595353333,
          21.002746033679475
        ]
      },
      "properties": {
        "tenDoiTuon": "KLE/41-9",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 15 Phương Mai",
        "tuDien": "KLE",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KLE",
        "diaChiLapD": "",
        "longitude": 105.83743595353333,
        "latitude": 21.002746033679475,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 575,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83722942343839,
          21.002683433372574
        ]
      },
      "properties": {
        "tenDoiTuon": "KLE/41-10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 15 Phương Mai",
        "tuDien": "KLE",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KLE",
        "diaChiLapD": "",
        "longitude": 105.83722942343839,
        "latitude": 21.002683433372574,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 576,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83736856302565,
          21.0025166034263
        ]
      },
      "properties": {
        "tenDoiTuon": "KLE/41-11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 15 Phương Mai",
        "tuDien": "KLE",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KLE",
        "diaChiLapD": "",
        "longitude": 105.83736856302565,
        "latitude": 21.0025166034263,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 577,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83726529798265,
          21.00243647490543
        ]
      },
      "properties": {
        "tenDoiTuon": "KLE/41-12",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 15 Phương Mai",
        "tuDien": "KLE",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KLE",
        "diaChiLapD": "",
        "longitude": 105.83726529798265,
        "latitude": 21.00243647490543,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 578,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83819736561318,
          21.002919749395545
        ]
      },
      "properties": {
        "tenDoiTuon": "KLE/42-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 15 Phương Mai",
        "tuDien": "KLE",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KLE",
        "diaChiLapD": "",
        "longitude": 105.83819736561318,
        "latitude": 21.002919749395545,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 579,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83791975698611,
          21.002849637131
        ]
      },
      "properties": {
        "tenDoiTuon": "KLE/42-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 15 Phương Mai",
        "tuDien": "KLE",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KLE",
        "diaChiLapD": "",
        "longitude": 105.83791975698611,
        "latitude": 21.002849637131,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 580,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83829124292907,
          21.00302867374105
        ]
      },
      "properties": {
        "tenDoiTuon": "KLE/42",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 15 Phương Mai",
        "tuDien": "KLE",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KLE",
        "diaChiLapD": "",
        "longitude": 105.83829124292907,
        "latitude": 21.00302867374105,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 581,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8382362576434,
          21.00272443657787
        ]
      },
      "properties": {
        "tenDoiTuon": "KLE/46-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 15 Phương Mai",
        "tuDien": "KLE",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KLE",
        "diaChiLapD": "",
        "longitude": 105.8382362576434,
        "latitude": 21.00272443657787,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 582,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83821614107634,
          21.002663088267916
        ]
      },
      "properties": {
        "tenDoiTuon": "KLE/43",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 15 Phương Mai",
        "tuDien": "KLE",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KLE",
        "diaChiLapD": "",
        "longitude": 105.83821614107634,
        "latitude": 21.002663088267916,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 583,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83796401343183,
          21.00264055214761
        ]
      },
      "properties": {
        "tenDoiTuon": "KLE/44",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 15 Phương Mai",
        "tuDien": "KLE",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KLE",
        "diaChiLapD": "",
        "longitude": 105.83796401343183,
        "latitude": 21.00264055214761,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 584,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83769445142799,
          21.002621772044584
        ]
      },
      "properties": {
        "tenDoiTuon": "KLE/45",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 15 Phương Mai",
        "tuDien": "KLE",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KLE",
        "diaChiLapD": "",
        "longitude": 105.83769445142799,
        "latitude": 21.002621772044584,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 585,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83820943555699,
          21.002009539402998
        ]
      },
      "properties": {
        "tenDoiTuon": "KLE/47",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 15 Phương Mai",
        "tuDien": "KLE",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KLE",
        "diaChiLapD": "",
        "longitude": 105.83820943555699,
        "latitude": 21.002009539402998,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 586,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83821614107634,
          21.0023087699853
        ]
      },
      "properties": {
        "tenDoiTuon": "KLE/46",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 15 Phương Mai",
        "tuDien": "KLE",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KLE",
        "diaChiLapD": "",
        "longitude": 105.83821614107634,
        "latitude": 21.0023087699853,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 587,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83810482941014,
          21.001775412963564
        ]
      },
      "properties": {
        "tenDoiTuon": "KLE/47-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 15 Phương Mai",
        "tuDien": "KLE",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KLE",
        "diaChiLapD": "",
        "longitude": 105.83810482941014,
        "latitude": 21.001775412963564,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 588,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83784465513342,
          21.002033327629867
        ]
      },
      "properties": {
        "tenDoiTuon": "KLE/47-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 15 Phương Mai",
        "tuDien": "KLE",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KLE",
        "diaChiLapD": "",
        "longitude": 105.83784465513342,
        "latitude": 21.002033327629867,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 589,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83786074838885,
          21.002203601146412
        ]
      },
      "properties": {
        "tenDoiTuon": "KLE/47-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 15 Phương Mai",
        "tuDien": "KLE",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KLE",
        "diaChiLapD": "",
        "longitude": 105.83786074838885,
        "latitude": 21.002203601146412,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 590,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83767835817255,
          21.00222864135282
        ]
      },
      "properties": {
        "tenDoiTuon": "KLE/47-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 15 Phương Mai",
        "tuDien": "KLE",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KLE",
        "diaChiLapD": "",
        "longitude": 105.83767835817255,
        "latitude": 21.00222864135282,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 591,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83484058101607,
          21.007619074011103
        ]
      },
      "properties": {
        "tenDoiTuon": "6-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Sân nhà B11",
        "tuDien": "B4KL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "B4 Kim Liên",
        "diaChiLapD": "",
        "longitude": 105.83484058101607,
        "latitude": 21.007619074011103,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 592,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83500687797692,
          21.007756790097222
        ]
      },
      "properties": {
        "tenDoiTuon": "6-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Sân nhà B11",
        "tuDien": "B4KL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "B4 Kim Liên",
        "diaChiLapD": "",
        "longitude": 105.83500687797692,
        "latitude": 21.007756790097222,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 593,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8352080436564,
          21.0079170413831
        ]
      },
      "properties": {
        "tenDoiTuon": "B4KL 6-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Sân nhà B11",
        "tuDien": "B4KL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "B4 Kim Liên",
        "diaChiLapD": "",
        "longitude": 105.8352080436564,
        "latitude": 21.0079170413831,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 594,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83099697547125,
          21.00606036944707
        ]
      },
      "properties": {
        "tenDoiTuon": "T37KT/1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Tôn Thất Tùng",
        "tuDien": "T37KT",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 10,
        "tuDien2": "T37KT",
        "diaChiLapD": "",
        "longitude": 105.83099697547125,
        "latitude": 21.00606036944707,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 595,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83087493495613,
          21.00574111469604
        ]
      },
      "properties": {
        "tenDoiTuon": "T37KT/2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Tôn Thất Tùng",
        "tuDien": "T37KT",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 10,
        "tuDien2": "T37KT",
        "diaChiLapD": "",
        "longitude": 105.83087493495613,
        "latitude": 21.00574111469604,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 596,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8300256805323,
          21.003336353286233
        ]
      },
      "properties": {
        "tenDoiTuon": "T37KT/12",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Tôn Thất Tùng",
        "tuDien": "T37KT",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 10,
        "tuDien2": "T37KT",
        "diaChiLapD": "",
        "longitude": 105.8300256805323,
        "latitude": 21.003336353286233,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 597,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8307649643848,
          21.005445646945695
        ]
      },
      "properties": {
        "tenDoiTuon": "T37KT/3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Tôn Thất Tùng",
        "tuDien": "T37KT",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 10,
        "tuDien2": "T37KT",
        "diaChiLapD": "",
        "longitude": 105.8307649643848,
        "latitude": 21.005445646945695,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 598,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83068852142821,
          21.005222793763885
        ]
      },
      "properties": {
        "tenDoiTuon": "T37KT/4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Tôn Thất Tùng",
        "tuDien": "T37KT",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 10,
        "tuDien2": "T37KT",
        "diaChiLapD": "",
        "longitude": 105.83068852142821,
        "latitude": 21.005222793763885,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 599,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83058793859296,
          21.004942348837044
        ]
      },
      "properties": {
        "tenDoiTuon": "T37KT/5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Tôn Thất Tùng",
        "tuDien": "T37KT",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 10,
        "tuDien2": "T37KT",
        "diaChiLapD": "",
        "longitude": 105.83058793859296,
        "latitude": 21.004942348837044,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 600,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83050881342865,
          21.00472074689313
        ]
      },
      "properties": {
        "tenDoiTuon": "T37KT/6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Tôn Thất Tùng",
        "tuDien": "T37KT",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 10,
        "tuDien2": "T37KT",
        "diaChiLapD": "",
        "longitude": 105.83050881342865,
        "latitude": 21.00472074689313,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 601,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83043237047207,
          21.004515420504347
        ]
      },
      "properties": {
        "tenDoiTuon": "T37KT/7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Tôn Thất Tùng",
        "tuDien": "T37KT",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 10,
        "tuDien2": "T37KT",
        "diaChiLapD": "",
        "longitude": 105.83043237047207,
        "latitude": 21.004515420504347,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 602,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83035190419488,
          21.004283801982645
        ]
      },
      "properties": {
        "tenDoiTuon": "T37KT/8",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Tôn Thất Tùng",
        "tuDien": "T37KT",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 10,
        "tuDien2": "T37KT",
        "diaChiLapD": "",
        "longitude": 105.83035190419488,
        "latitude": 21.004283801982645,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 603,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83026875571896,
          21.004068459034375
        ]
      },
      "properties": {
        "tenDoiTuon": "T37KT/9",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Tôn Thất Tùng",
        "tuDien": "T37KT",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 10,
        "tuDien2": "T37KT",
        "diaChiLapD": "",
        "longitude": 105.83026875571896,
        "latitude": 21.004068459034375,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 604,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83016515540675,
          21.00375702500575
        ]
      },
      "properties": {
        "tenDoiTuon": "T37KT/10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Tôn Thất Tùng",
        "tuDien": "T37KT",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 10,
        "tuDien2": "T37KT",
        "diaChiLapD": "",
        "longitude": 105.83016515540675,
        "latitude": 21.00375702500575,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 605,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83008770660653,
          21.003521336308857
        ]
      },
      "properties": {
        "tenDoiTuon": "T37KT/11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Tôn Thất Tùng",
        "tuDien": "T37KT",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 10,
        "tuDien2": "T37KT",
        "diaChiLapD": "",
        "longitude": 105.83008770660653,
        "latitude": 21.003521336308857,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 606,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8299170510918,
          21.01915392051503
        ]
      },
      "properties": {
        "tenDoiTuon": "HB7 1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Tôn Đức Thắng",
        "tuDien": "HB7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Hàng Bột 7",
        "diaChiLapD": "",
        "longitude": 105.8299170510918,
        "latitude": 21.01915392051503,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 607,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82984597255069,
          21.019374249152385
        ]
      },
      "properties": {
        "tenDoiTuon": "HB7 1-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 8 La Thành",
        "tuDien": "HB7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hàng Bột 7",
        "diaChiLapD": "",
        "longitude": 105.82984597255069,
        "latitude": 21.019374249152385,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 608,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82748462282512,
          21.002733826621146
        ]
      },
      "properties": {
        "tenDoiTuon": "4-11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 354/96 Trường Chinh",
        "tuDien": "TB2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Tàu Bay 2",
        "diaChiLapD": "",
        "longitude": 105.82748462282512,
        "latitude": 21.002733826621146,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 609,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8274148853879,
          21.00261112998371
        ]
      },
      "properties": {
        "tenDoiTuon": "TB2 4-12",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 354/96 Trường Chinh",
        "tuDien": "TB2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Tàu Bay 2",
        "diaChiLapD": "",
        "longitude": 105.8274148853879,
        "latitude": 21.00261112998371,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 610,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82807437351066,
          21.002548216615573
        ]
      },
      "properties": {
        "tenDoiTuon": "5-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 354/144 Trường Chinh",
        "tuDien": "TB2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Tàu Bay 2",
        "diaChiLapD": "",
        "longitude": 105.82807437351066,
        "latitude": 21.002548216615573,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 611,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82811728886148,
          21.002345391255783
        ]
      },
      "properties": {
        "tenDoiTuon": "5-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 354/144 Trường Chinh",
        "tuDien": "TB2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Tàu Bay 2",
        "diaChiLapD": "",
        "longitude": 105.82811728886148,
        "latitude": 21.002345391255783,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 612,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8282889502378,
          21.00231909905923
        ]
      },
      "properties": {
        "tenDoiTuon": "TB2 5-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 354/144 Trường Chinh",
        "tuDien": "TB2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Tàu Bay 2",
        "diaChiLapD": "",
        "longitude": 105.8282889502378,
        "latitude": 21.00231909905923,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 613,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8291874902536,
          21.00425218915265
        ]
      },
      "properties": {
        "tenDoiTuon": "TB2 6-12-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 354/159 Trường Chinh",
        "tuDien": "TB2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Tàu Bay 2",
        "diaChiLapD": "",
        "longitude": 105.8291874902536,
        "latitude": 21.00425218915265,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 614,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82904667421239,
          21.003719465097678
        ]
      },
      "properties": {
        "tenDoiTuon": "6-9-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 354/159 Trường Chinh",
        "tuDien": "TB2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Tàu Bay 2",
        "diaChiLapD": "",
        "longitude": 105.82904667421239,
        "latitude": 21.003719465097678,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 615,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80680076795916,
          21.023620832237555
        ]
      },
      "properties": {
        "tenDoiTuon": "HLT 01",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "XQ Hồ Láng Thượng",
        "tuDien": "ĐHNT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 6,
        "tuDien2": "TT Đại Học Ngoại Thương",
        "diaChiLapD": "",
        "longitude": 105.80680076795916,
        "latitude": 21.023620832237555,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 616,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80681954342234,
          21.023888723760834
        ]
      },
      "properties": {
        "tenDoiTuon": "02",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "XQ Hồ Láng Thượng",
        "tuDien": "ĐHNT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 6,
        "tuDien2": "TT Đại Học Ngoại Thương",
        "diaChiLapD": "",
        "longitude": 105.80681954342234,
        "latitude": 21.023888723760834,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 617,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80683429557389,
          21.02416037028191
        ]
      },
      "properties": {
        "tenDoiTuon": "03",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "XQ Hồ Láng Thượng",
        "tuDien": "ĐHNT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 6,
        "tuDien2": "TT Đại Học Ngoại Thương",
        "diaChiLapD": "",
        "longitude": 105.80683429557389,
        "latitude": 21.02416037028191,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 618,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8068477066126,
          21.024420749892265
        ]
      },
      "properties": {
        "tenDoiTuon": "04",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "XQ Hồ Láng Thượng",
        "tuDien": "ĐHNT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 6,
        "tuDien2": "TT Đại Học Ngoại Thương",
        "diaChiLapD": "",
        "longitude": 105.8068477066126,
        "latitude": 21.024420749892265,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 619,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80685709434869,
          21.024679877226642
        ]
      },
      "properties": {
        "tenDoiTuon": "05",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "XQ Hồ Láng Thượng",
        "tuDien": "ĐHNT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 6,
        "tuDien2": "TT Đại Học Ngoại Thương",
        "diaChiLapD": "",
        "longitude": 105.80685709434869,
        "latitude": 21.024679877226642,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 620,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80687184650026,
          21.02494401138823
        ]
      },
      "properties": {
        "tenDoiTuon": "06",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "XQ Hồ Láng Thượng",
        "tuDien": "ĐHNT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 6,
        "tuDien2": "TT Đại Học Ngoại Thương",
        "diaChiLapD": "",
        "longitude": 105.80687184650026,
        "latitude": 21.02494401138823,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 621,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80686514097191,
          21.025204389630627
        ]
      },
      "properties": {
        "tenDoiTuon": "07",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "XQ Hồ Láng Thượng",
        "tuDien": "ĐHNT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 6,
        "tuDien2": "TT Đại Học Ngoại Thương",
        "diaChiLapD": "",
        "longitude": 105.80686514097191,
        "latitude": 21.025204389630627,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 622,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80686782317966,
          21.02546601923282
        ]
      },
      "properties": {
        "tenDoiTuon": "08",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "XQ Hồ Láng Thượng",
        "tuDien": "ĐHNT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 6,
        "tuDien2": "TT Đại Học Ngoại Thương",
        "diaChiLapD": "",
        "longitude": 105.80686782317966,
        "latitude": 21.02546601923282,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 623,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80688257533123,
          21.025737662879607
        ]
      },
      "properties": {
        "tenDoiTuon": "09",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "XQ Hồ Láng Thượng",
        "tuDien": "ĐHNT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 6,
        "tuDien2": "TT Đại Học Ngoại Thương",
        "diaChiLapD": "",
        "longitude": 105.80688257533123,
        "latitude": 21.025737662879607,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 624,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80688659864286,
          21.025994284302776
        ]
      },
      "properties": {
        "tenDoiTuon": "10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "XQ Hồ Láng Thượng",
        "tuDien": "ĐHNT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 6,
        "tuDien2": "TT Đại Học Ngoại Thương",
        "diaChiLapD": "",
        "longitude": 105.80688659864286,
        "latitude": 21.025994284302776,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 625,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80676992255216,
          21.02607189654875
        ]
      },
      "properties": {
        "tenDoiTuon": "11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "XQ Hồ Láng Thượng",
        "tuDien": "ĐHNT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 6,
        "tuDien2": "TT Đại Học Ngoại Thương",
        "diaChiLapD": "",
        "longitude": 105.80676992255216,
        "latitude": 21.02607189654875,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 626,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80656786280645,
          21.026075234708756
        ]
      },
      "properties": {
        "tenDoiTuon": "12",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "XQ Hồ Láng Thượng",
        "tuDien": "ĐHNT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 6,
        "tuDien2": "TT Đại Học Ngoại Thương",
        "diaChiLapD": "",
        "longitude": 105.80656786280645,
        "latitude": 21.026075234708756,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 627,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80636580306071,
          21.026078572868713
        ]
      },
      "properties": {
        "tenDoiTuon": "13",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "XQ Hồ Láng Thượng",
        "tuDien": "ĐHNT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 6,
        "tuDien2": "TT Đại Học Ngoại Thương",
        "diaChiLapD": "",
        "longitude": 105.80636580306071,
        "latitude": 21.026078572868713,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 628,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.806163743315,
          21.02608191102858
        ]
      },
      "properties": {
        "tenDoiTuon": "14",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "XQ Hồ Láng Thượng",
        "tuDien": "ĐHNT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 6,
        "tuDien2": "TT Đại Học Ngoại Thương",
        "diaChiLapD": "",
        "longitude": 105.806163743315,
        "latitude": 21.02608191102858,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 629,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80607925373519,
          21.02599303249178
        ]
      },
      "properties": {
        "tenDoiTuon": "15",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "XQ Hồ Láng Thượng",
        "tuDien": "ĐHNT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 6,
        "tuDien2": "TT Đại Học Ngoại Thương",
        "diaChiLapD": "",
        "longitude": 105.80607925373519,
        "latitude": 21.02599303249178,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 630,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80602292734567,
          21.025760195509438
        ]
      },
      "properties": {
        "tenDoiTuon": "16",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "XQ Hồ Láng Thượng",
        "tuDien": "ĐHNT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 6,
        "tuDien2": "TT Đại Học Ngoại Thương",
        "diaChiLapD": "",
        "longitude": 105.80602292734567,
        "latitude": 21.025760195509438,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 631,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8059907408348,
          21.02551233638711
        ]
      },
      "properties": {
        "tenDoiTuon": "17",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "XQ Hồ Láng Thượng",
        "tuDien": "ĐHNT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 6,
        "tuDien2": "TT Đại Học Ngoại Thương",
        "diaChiLapD": "",
        "longitude": 105.8059907408348,
        "latitude": 21.02551233638711,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 632,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8059236856143,
          21.025011609629964
        ]
      },
      "properties": {
        "tenDoiTuon": "19",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "XQ Hồ Láng Thượng",
        "tuDien": "ĐHNT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 6,
        "tuDien2": "TT Đại Học Ngoại Thương",
        "diaChiLapD": "",
        "longitude": 105.8059236856143,
        "latitude": 21.025011609629964,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 633,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80591731536417,
          21.024722752115025
        ]
      },
      "properties": {
        "tenDoiTuon": "20",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "XQ Hồ Láng Thượng",
        "tuDien": "ĐHNT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 6,
        "tuDien2": "TT Đại Học Ngoại Thương",
        "diaChiLapD": "",
        "longitude": 105.80591731536417,
        "latitude": 21.024722752115025,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 634,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80626566726309,
          21.02357826998775
        ]
      },
      "properties": {
        "tenDoiTuon": "HLT 26",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "XQ Hồ Láng Thượng",
        "tuDien": "ĐHNT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 6,
        "tuDien2": "TT Đại Học Ngoại Thương",
        "diaChiLapD": "",
        "longitude": 105.80626566726309,
        "latitude": 21.02357826998775,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 635,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80630053597272,
          21.023843657931113
        ]
      },
      "properties": {
        "tenDoiTuon": "25",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "XQ Hồ Láng Thượng",
        "tuDien": "ĐHNT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 6,
        "tuDien2": "TT Đại Học Ngoại Thương",
        "diaChiLapD": "",
        "longitude": 105.80630053597272,
        "latitude": 21.023843657931113,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 636,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80633138137972,
          21.024099030787962
        ]
      },
      "properties": {
        "tenDoiTuon": "24",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "XQ Hồ Láng Thượng",
        "tuDien": "ĐHNT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 6,
        "tuDien2": "TT Đại Học Ngoại Thương",
        "diaChiLapD": "",
        "longitude": 105.80633138137972,
        "latitude": 21.024099030787962,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 637,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80634211021967,
          21.02437067692583
        ]
      },
      "properties": {
        "tenDoiTuon": "23",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "XQ Hồ Láng Thượng",
        "tuDien": "ĐHNT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 6,
        "tuDien2": "TT Đại Học Ngoại Thương",
        "diaChiLapD": "",
        "longitude": 105.80634211021967,
        "latitude": 21.02437067692583,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 638,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80634613353129,
          21.024632307991276
        ]
      },
      "properties": {
        "tenDoiTuon": "22",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "XQ Hồ Láng Thượng",
        "tuDien": "ĐHNT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 6,
        "tuDien2": "TT Đại Học Ngoại Thương",
        "diaChiLapD": "",
        "longitude": 105.80634613353129,
        "latitude": 21.024632307991276,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 639,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80613558012472,
          21.02471492823256
        ]
      },
      "properties": {
        "tenDoiTuon": "21",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "XQ Hồ Láng Thượng",
        "tuDien": "ĐHNT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 6,
        "tuDien2": "TT Đại Học Ngoại Thương",
        "diaChiLapD": "",
        "longitude": 105.80613558012472,
        "latitude": 21.02471492823256,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 640,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80593206751574,
          21.024740277613983
        ]
      },
      "properties": {
        "tenDoiTuon": "37",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - đèn chùm",
        "tenDuongPh": "XQ Hồ Láng Thượng",
        "tuDien": "ĐHNT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "DC-05",
        "chieuCao": 3,
        "tuDien2": "TT Đại Học Ngoại Thương",
        "diaChiLapD": "",
        "longitude": 105.80593206751574,
        "latitude": 21.024740277613983,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 641,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80683060753151,
          21.02542251864738
        ]
      },
      "properties": {
        "tenDoiTuon": "30",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - đèn chùm",
        "tenDuongPh": "XQ Hồ Láng Thượng",
        "tuDien": "ĐHNT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "DC-05",
        "chieuCao": 3,
        "tuDien2": "TT Đại Học Ngoại Thương",
        "diaChiLapD": "",
        "longitude": 105.80683060753151,
        "latitude": 21.02542251864738,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 642,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80683865416373,
          21.025266041622544
        ]
      },
      "properties": {
        "tenDoiTuon": "29",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - đèn chùm",
        "tenDuongPh": "XQ Hồ Láng Thượng",
        "tuDien": "ĐHNT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "DC-05",
        "chieuCao": 3,
        "tuDien2": "TT Đại Học Ngoại Thương",
        "diaChiLapD": "",
        "longitude": 105.80683865416373,
        "latitude": 21.025266041622544,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 643,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80685072409857,
          21.025536433818942
        ]
      },
      "properties": {
        "tenDoiTuon": "31",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - đèn chùm",
        "tenDuongPh": "XQ Hồ Láng Thượng",
        "tuDien": "ĐHNT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "DC-05",
        "chieuCao": 3,
        "tuDien2": "TT Đại Học Ngoại Thương",
        "diaChiLapD": "",
        "longitude": 105.80685072409857,
        "latitude": 21.025536433818942,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 644,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80685474741017,
          21.02569541418696
        ]
      },
      "properties": {
        "tenDoiTuon": "32",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - đèn chùm",
        "tenDuongPh": "XQ Hồ Láng Thượng",
        "tuDien": "ĐHNT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "DC-05",
        "chieuCao": 3,
        "tuDien2": "TT Đại Học Ngoại Thương",
        "diaChiLapD": "",
        "longitude": 105.80685474741017,
        "latitude": 21.02569541418696,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 645,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8067340480079,
          21.0260409142441
        ]
      },
      "properties": {
        "tenDoiTuon": "33",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - đèn chùm",
        "tenDuongPh": "XQ Hồ Láng Thượng",
        "tuDien": "ĐHNT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "DC-05",
        "chieuCao": 3,
        "tuDien2": "TT Đại Học Ngoại Thương",
        "diaChiLapD": "",
        "longitude": 105.8067340480079,
        "latitude": 21.0260409142441,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 646,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80659054982183,
          21.026042166054687
        ]
      },
      "properties": {
        "tenDoiTuon": "34",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - đèn chùm",
        "tenDuongPh": "XQ Hồ Láng Thượng",
        "tuDien": "ĐHNT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "DC-05",
        "chieuCao": 3,
        "tuDien2": "TT Đại Học Ngoại Thương",
        "diaChiLapD": "",
        "longitude": 105.80659054982183,
        "latitude": 21.026042166054687,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 647,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80645643937184,
          21.026047173296103
        ]
      },
      "properties": {
        "tenDoiTuon": "35",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - đèn chùm",
        "tenDuongPh": "XQ Hồ Láng Thượng",
        "tuDien": "ĐHNT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "DC-05",
        "chieuCao": 3,
        "tuDien2": "TT Đại Học Ngoại Thương",
        "diaChiLapD": "",
        "longitude": 105.80645643937184,
        "latitude": 21.026047173296103,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 648,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80626600253233,
          21.026049676916347
        ]
      },
      "properties": {
        "tenDoiTuon": "36",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - đèn chùm",
        "tenDuongPh": "XQ Hồ Láng Thượng",
        "tuDien": "ĐHNT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "DC-05",
        "chieuCao": 3,
        "tuDien2": "TT Đại Học Ngoại Thương",
        "diaChiLapD": "",
        "longitude": 105.80626600253233,
        "latitude": 21.026049676916347,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 649,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80677562224584,
          21.02385398551643
        ]
      },
      "properties": {
        "tenDoiTuon": "28",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - đèn chùm",
        "tenDuongPh": "XQ Hồ Láng Thượng",
        "tuDien": "ĐHNT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "DC-05",
        "chieuCao": 3,
        "tuDien2": "TT Đại Học Ngoại Thương",
        "diaChiLapD": "",
        "longitude": 105.80677562224584,
        "latitude": 21.02385398551643,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 650,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80677294003812,
          21.023668714751846
        ]
      },
      "properties": {
        "tenDoiTuon": "27",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - đèn chùm",
        "tenDuongPh": "XQ Hồ Láng Thượng",
        "tuDien": "ĐHNT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "DC-05",
        "chieuCao": 3,
        "tuDien2": "TT Đại Học Ngoại Thương",
        "diaChiLapD": "",
        "longitude": 105.80677294003812,
        "latitude": 21.023668714751846,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 651,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80721181650672,
          21.025973629423802
        ]
      },
      "properties": {
        "tenDoiTuon": "ĐHNT 41-10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 1,
        "maXaPhuong": 25,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 80 Chùa Láng",
        "tuDien": "ĐHNT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "TT Đại Học Ngoại Thương",
        "diaChiLapD": "",
        "longitude": 105.80721181650672,
        "latitude": 21.025973629423802,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 652,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80754172822068,
          21.025968622179928
        ]
      },
      "properties": {
        "tenDoiTuon": "ĐHNT 41-11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 1,
        "maXaPhuong": 25,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 80 Chùa Láng",
        "tuDien": "ĐHNT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "TT Đại Học Ngoại Thương",
        "diaChiLapD": "",
        "longitude": 105.80754172822068,
        "latitude": 21.025968622179928,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 653,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80729496499164,
          21.02574329603738
        ]
      },
      "properties": {
        "tenDoiTuon": "ĐHNT 41-9",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 1,
        "maXaPhuong": 25,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 80 Chùa Láng",
        "tuDien": "ĐHNT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 6,
        "tuDien2": "TT Đại Học Ngoại Thương",
        "diaChiLapD": "",
        "longitude": 105.80729496499164,
        "latitude": 21.02574329603738,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 654,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80740493556297,
          21.02560309293153
        ]
      },
      "properties": {
        "tenDoiTuon": "ĐHNT 41-8",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 1,
        "maXaPhuong": 25,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 80 Chùa Láng",
        "tuDien": "ĐHNT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 6,
        "tuDien2": "TT Đại Học Ngoại Thương",
        "diaChiLapD": "",
        "longitude": 105.80740493556297,
        "latitude": 21.02560309293153,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 655,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82982552069645,
          21.00310316793126
        ]
      },
      "properties": {
        "tenDoiTuon": "T37KT/12-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ nhà B1 khu TT Khương Thượng",
        "tuDien": "T37KT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "T37KT",
        "diaChiLapD": "",
        "longitude": 105.82982552069645,
        "latitude": 21.00310316793126,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 656,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82961496728988,
          21.003148240027947
        ]
      },
      "properties": {
        "tenDoiTuon": "T37KT/12-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ nhà B1 khu TT Khương Thượng",
        "tuDien": "T37KT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "T37KT",
        "diaChiLapD": "",
        "longitude": 105.82961496728988,
        "latitude": 21.003148240027947,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 657,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82940843719494,
          21.00319206010945
        ]
      },
      "properties": {
        "tenDoiTuon": "T37KT/12-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ nhà B1 khu TT Khương Thượng",
        "tuDien": "T37KT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "T37KT",
        "diaChiLapD": "",
        "longitude": 105.82940843719494,
        "latitude": 21.00319206010945,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 658,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82988989371819,
          21.00338111231292
        ]
      },
      "properties": {
        "tenDoiTuon": "T37KT/12-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ nhà B2 khu TT Khương Thượng",
        "tuDien": "T37KT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "T37KT",
        "diaChiLapD": "",
        "longitude": 105.82988989371819,
        "latitude": 21.00338111231292,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 659,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82967799920777,
          21.003423680325636
        ]
      },
      "properties": {
        "tenDoiTuon": "T37KT/12-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ nhà B2 khu TT Khương Thượng",
        "tuDien": "T37KT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "T37KT",
        "diaChiLapD": "",
        "longitude": 105.82967799920777,
        "latitude": 21.003423680325636,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 660,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82946878690507,
          21.003463744326837
        ]
      },
      "properties": {
        "tenDoiTuon": "T37KT/12-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ nhà B2 khu TT Khương Thượng",
        "tuDien": "T37KT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "T37KT",
        "diaChiLapD": "",
        "longitude": 105.82946878690507,
        "latitude": 21.003463744326837,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 661,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.829582780788,
          21.00362900821656
        ]
      },
      "properties": {
        "tenDoiTuon": "T37KT/11-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ nhà B3 khu TT Khương Thượng",
        "tuDien": "T37KT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "T37KT",
        "diaChiLapD": "",
        "longitude": 105.829582780788,
        "latitude": 21.00362900821656,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 662,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8298992814453,
          21.00356140028385
        ]
      },
      "properties": {
        "tenDoiTuon": "T37KT/11-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ nhà B3 khu TT Khương Thượng",
        "tuDien": "T37KT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "T37KT",
        "diaChiLapD": "",
        "longitude": 105.8298992814453,
        "latitude": 21.00356140028385,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 663,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82966995257556,
          21.003898187646413
        ]
      },
      "properties": {
        "tenDoiTuon": "T37KT/10-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ nhà B4 khu TT Khương Thượng",
        "tuDien": "T37KT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "T37KT",
        "diaChiLapD": "",
        "longitude": 105.82966995257556,
        "latitude": 21.003898187646413,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 664,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82983356732865,
          21.003859375758626
        ]
      },
      "properties": {
        "tenDoiTuon": "T37KT/10-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ nhà B4 khu TT Khương Thượng",
        "tuDien": "T37KT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "T37KT",
        "diaChiLapD": "",
        "longitude": 105.82983356732865,
        "latitude": 21.003859375758626,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 665,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83002936858365,
          21.003813051879572
        ]
      },
      "properties": {
        "tenDoiTuon": "T37KT/10-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ nhà B4 khu TT Khương Thượng",
        "tuDien": "T37KT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "T37KT",
        "diaChiLapD": "",
        "longitude": 105.83002936858365,
        "latitude": 21.003813051879572,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 666,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83017957228908,
          21.005603396750328
        ]
      },
      "properties": {
        "tenDoiTuon": "T37KT/3-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 6 Tôn Thất Tùng",
        "tuDien": "T37KT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "T37KT",
        "diaChiLapD": "",
        "longitude": 105.83017957228908,
        "latitude": 21.005603396750328,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 667,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83048534411542,
          21.00551200204267
        ]
      },
      "properties": {
        "tenDoiTuon": "T37KT/3-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 6 Tôn Thất Tùng",
        "tuDien": "T37KT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "T37KT",
        "diaChiLapD": "",
        "longitude": 105.83048534411542,
        "latitude": 21.00551200204267,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 668,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83067309874717,
          21.005450654878693
        ]
      },
      "properties": {
        "tenDoiTuon": "T37KT/3-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 6 Tôn Thất Tùng",
        "tuDien": "T37KT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "T37KT",
        "diaChiLapD": "",
        "longitude": 105.83067309874717,
        "latitude": 21.005450654878693,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 669,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83008401859111,
          21.00425464053849
        ]
      },
      "properties": {
        "tenDoiTuon": "T37KT/8-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 10 Tôn Thất Tùng",
        "tuDien": "T37KT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "T37KT",
        "diaChiLapD": "",
        "longitude": 105.83008401859111,
        "latitude": 21.00425464053849,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 670,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82958009858027,
          21.004376449434975
        ]
      },
      "properties": {
        "tenDoiTuon": "T37KT/8-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 10 Tôn Thất Tùng",
        "tuDien": "T37KT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "T37KT",
        "diaChiLapD": "",
        "longitude": 105.82958009858027,
        "latitude": 21.004376449434975,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 671,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82941111941167,
          21.004247494182223
        ]
      },
      "properties": {
        "tenDoiTuon": "T37KT/8-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 10 Tôn Thất Tùng",
        "tuDien": "T37KT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "T37KT",
        "diaChiLapD": "",
        "longitude": 105.82941111941167,
        "latitude": 21.004247494182223,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 672,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82911875863304,
          21.004378953419
        ]
      },
      "properties": {
        "tenDoiTuon": "T37KT/8-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 10 Tôn Thất Tùng",
        "tuDien": "T37KT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "T37KT",
        "diaChiLapD": "",
        "longitude": 105.82911875863304,
        "latitude": 21.004378953419,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 673,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82870972175476,
          21.004542964305
        ]
      },
      "properties": {
        "tenDoiTuon": "T37KT/8-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 10 Tôn Thất Tùng",
        "tuDien": "T37KT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "T37KT",
        "diaChiLapD": "",
        "longitude": 105.82870972175476,
        "latitude": 21.004542964305,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 674,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.804954690868,
          21.02309624820927
        ]
      },
      "properties": {
        "tenDoiTuon": "3-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 91 Chùa Láng",
        "tuDien": "ĐHNT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "TT Đại Học Ngoại Thương",
        "diaChiLapD": "",
        "longitude": 105.804954690868,
        "latitude": 21.02309624820927,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 675,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82534958441146,
          21.02045742255822
        ]
      },
      "properties": {
        "tenDoiTuon": "HN2/48",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hoàng Cầu",
        "tuDien": "HN2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 10,
        "tuDien2": "HN2",
        "diaChiLapD": "",
        "longitude": 105.82534958441146,
        "latitude": 21.02045742255822,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 676,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82522673923938,
          21.020238597752456
        ]
      },
      "properties": {
        "tenDoiTuon": "HN2/47",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hoàng Cầu",
        "tuDien": "HN2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 10,
        "tuDien2": "HN2",
        "diaChiLapD": "",
        "longitude": 105.82522673923938,
        "latitude": 21.020238597752456,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 677,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82510389406731,
          21.02001977262556
        ]
      },
      "properties": {
        "tenDoiTuon": "HN2/46",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hoàng Cầu",
        "tuDien": "HN2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 10,
        "tuDien2": "HN2",
        "diaChiLapD": "",
        "longitude": 105.82510389406731,
        "latitude": 21.02001977262556,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 678,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82498104889524,
          21.019800947177504
        ]
      },
      "properties": {
        "tenDoiTuon": "HN2/45",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hoàng Cầu",
        "tuDien": "HN2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 10,
        "tuDien2": "HN2",
        "diaChiLapD": "",
        "longitude": 105.82498104889524,
        "latitude": 21.019800947177504,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 679,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82485820372317,
          21.019582121408316
        ]
      },
      "properties": {
        "tenDoiTuon": "HN2/44",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hoàng Cầu",
        "tuDien": "HN2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 10,
        "tuDien2": "HN2",
        "diaChiLapD": "",
        "longitude": 105.82485820372317,
        "latitude": 21.019582121408316,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 680,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82473535855108,
          21.01936329531799
        ]
      },
      "properties": {
        "tenDoiTuon": "HN2/43",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hoàng Cầu",
        "tuDien": "HN2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 10,
        "tuDien2": "HN2",
        "diaChiLapD": "",
        "longitude": 105.82473535855108,
        "latitude": 21.01936329531799,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 681,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82458783705319,
          21.018992742396765
        ]
      },
      "properties": {
        "tenDoiTuon": "HN2/42",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hoàng Cầu",
        "tuDien": "HN2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 10,
        "tuDien2": "HN2",
        "diaChiLapD": "",
        "longitude": 105.82458783705319,
        "latitude": 21.018992742396765,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 682,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82445104439547,
          21.018737360792716
        ]
      },
      "properties": {
        "tenDoiTuon": "HN2/41",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hoàng Cầu",
        "tuDien": "HN2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 10,
        "tuDien2": "HN2",
        "diaChiLapD": "",
        "longitude": 105.82445104439547,
        "latitude": 21.018737360792716,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 683,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82430352289528,
          21.018461948769296
        ]
      },
      "properties": {
        "tenDoiTuon": "HN2/40",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hoàng Cầu",
        "tuDien": "HN2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 10,
        "tuDien2": "HN2",
        "diaChiLapD": "",
        "longitude": 105.82430352289528,
        "latitude": 21.018461948769296,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 684,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82416404803253,
          21.01818403248537
        ]
      },
      "properties": {
        "tenDoiTuon": "HN2/39",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hoàng Cầu",
        "tuDien": "HN2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 10,
        "tuDien2": "HN2",
        "diaChiLapD": "",
        "longitude": 105.82416404803253,
        "latitude": 21.01818403248537,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 685,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8239923866562,
          21.01794116828123
        ]
      },
      "properties": {
        "tenDoiTuon": "HN2/38",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hoàng Cầu",
        "tuDien": "HN2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 10,
        "tuDien2": "HN2",
        "diaChiLapD": "",
        "longitude": 105.8239923866562,
        "latitude": 21.01794116828123,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 686,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82386229952681,
          21.017591893808785
        ]
      },
      "properties": {
        "tenDoiTuon": "HN2/37",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hoàng Cầu",
        "tuDien": "HN2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 10,
        "tuDien2": "HN2",
        "diaChiLapD": "",
        "longitude": 105.82386229952681,
        "latitude": 21.017591893808785,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 687,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82373221238844,
          21.017351532406547
        ]
      },
      "properties": {
        "tenDoiTuon": "HN2/36",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hoàng Cầu",
        "tuDien": "HN2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 10,
        "tuDien2": "HN2",
        "diaChiLapD": "",
        "longitude": 105.82373221238844,
        "latitude": 21.017351532406547,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 688,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82359541973071,
          21.017098651763124
        ]
      },
      "properties": {
        "tenDoiTuon": "HN2/35",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hoàng Cầu",
        "tuDien": "HN2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 10,
        "tuDien2": "HN2",
        "diaChiLapD": "",
        "longitude": 105.82359541973071,
        "latitude": 21.017098651763124,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 689,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82345594485238,
          21.01684952635195
        ]
      },
      "properties": {
        "tenDoiTuon": "HN2/34",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hoàng Cầu",
        "tuDien": "HN2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 10,
        "tuDien2": "HN2",
        "diaChiLapD": "",
        "longitude": 105.82345594485238,
        "latitude": 21.01684952635195,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 690,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82325746138456,
          21.016656735548967
        ]
      },
      "properties": {
        "tenDoiTuon": "HN2/33",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hoàng Cầu",
        "tuDien": "HN2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 10,
        "tuDien2": "HN2",
        "diaChiLapD": "",
        "longitude": 105.82325746138456,
        "latitude": 21.016656735548967,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 691,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82563121635908,
          21.020379807388984
        ]
      },
      "properties": {
        "tenDoiTuon": "HN2/5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hoàng Cầu",
        "tuDien": "HN2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 10,
        "tuDien2": "HN2",
        "diaChiLapD": "",
        "longitude": 105.82563121635908,
        "latitude": 21.020379807388984,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 692,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82552661021225,
          21.020182013712425
        ]
      },
      "properties": {
        "tenDoiTuon": "HN2/4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hoàng Cầu",
        "tuDien": "HN2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 10,
        "tuDien2": "HN2",
        "diaChiLapD": "",
        "longitude": 105.82552661021225,
        "latitude": 21.020182013712425,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 693,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82539618779458,
          21.019920687801456
        ]
      },
      "properties": {
        "tenDoiTuon": "HN2/3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hoàng Cầu",
        "tuDien": "HN2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 10,
        "tuDien2": "HN2",
        "diaChiLapD": "",
        "longitude": 105.82539618779458,
        "latitude": 21.019920687801456,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 694,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82525855694466,
          21.019667342065855
        ]
      },
      "properties": {
        "tenDoiTuon": "HN2/2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hoàng Cầu",
        "tuDien": "HN2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 10,
        "tuDien2": "HN2",
        "diaChiLapD": "",
        "longitude": 105.82525855694466,
        "latitude": 21.019667342065855,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 695,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82514841874097,
          21.019468452061687
        ]
      },
      "properties": {
        "tenDoiTuon": "HN2/1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hoàng Cầu",
        "tuDien": "HN2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 10,
        "tuDien2": "HN2",
        "diaChiLapD": "",
        "longitude": 105.82514841874097,
        "latitude": 21.019468452061687,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 696,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8250491770096,
          21.019218078743158
        ]
      },
      "properties": {
        "tenDoiTuon": "HN2/7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hoàng Cầu",
        "tuDien": "HN2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 10,
        "tuDien2": "HN2",
        "diaChiLapD": "",
        "longitude": 105.8250491770096,
        "latitude": 21.019218078743158,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 697,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82482420672275,
          21.018884768610228
        ]
      },
      "properties": {
        "tenDoiTuon": "HN2/8",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hoàng Cầu",
        "tuDien": "HN2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 10,
        "tuDien2": "HN2",
        "diaChiLapD": "",
        "longitude": 105.82482420672275,
        "latitude": 21.018884768610228,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 698,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.824703172049,
          21.018664752218598
        ]
      },
      "properties": {
        "tenDoiTuon": "HN2/9",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hoàng Cầu",
        "tuDien": "HN2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 10,
        "tuDien2": "HN2",
        "diaChiLapD": "",
        "longitude": 105.824703172049,
        "latitude": 21.018664752218598,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 699,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82455296834104,
          21.018394347559553
        ]
      },
      "properties": {
        "tenDoiTuon": "HN2/10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hoàng Cầu",
        "tuDien": "HN2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 10,
        "tuDien2": "HN2",
        "diaChiLapD": "",
        "longitude": 105.82455296834104,
        "latitude": 21.018394347559553,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 700,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82437376324692,
          21.018059627223003
        ]
      },
      "properties": {
        "tenDoiTuon": "HN2/11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hoàng Cầu",
        "tuDien": "HN2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 10,
        "tuDien2": "HN2",
        "diaChiLapD": "",
        "longitude": 105.82437376324692,
        "latitude": 21.018059627223003,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 701,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82421232779883,
          21.01776590520765
        ]
      },
      "properties": {
        "tenDoiTuon": "HN2/12",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hoàng Cầu",
        "tuDien": "HN2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 10,
        "tuDien2": "HN2",
        "diaChiLapD": "",
        "longitude": 105.82421232779883,
        "latitude": 21.01776590520765,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 702,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8240674885089,
          21.017492995153674
        ]
      },
      "properties": {
        "tenDoiTuon": "HN2/13",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hoàng Cầu",
        "tuDien": "HN2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 10,
        "tuDien2": "HN2",
        "diaChiLapD": "",
        "longitude": 105.8240674885089,
        "latitude": 21.017492995153674,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 703,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82393069585117,
          21.01724011475017
        ]
      },
      "properties": {
        "tenDoiTuon": "HN2/14",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hoàng Cầu",
        "tuDien": "HN2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 10,
        "tuDien2": "HN2",
        "diaChiLapD": "",
        "longitude": 105.82393069585117,
        "latitude": 21.01724011475017,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 704,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82382608969534,
          21.017014775414946
        ]
      },
      "properties": {
        "tenDoiTuon": "HN2/15",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hoàng Cầu",
        "tuDien": "HN2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 10,
        "tuDien2": "HN2",
        "diaChiLapD": "",
        "longitude": 105.82382608969534,
        "latitude": 21.017014775414946,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 705,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8237214835485,
          21.0167944432922
        ]
      },
      "properties": {
        "tenDoiTuon": "HN2/16",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hoàng Cầu",
        "tuDien": "HN2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 10,
        "tuDien2": "HN2",
        "diaChiLapD": "",
        "longitude": 105.8237214835485,
        "latitude": 21.0167944432922,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 706,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8235793264663,
          21.01656910328356
        ]
      },
      "properties": {
        "tenDoiTuon": "HN2/17",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hoàng Cầu",
        "tuDien": "HN2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 10,
        "tuDien2": "HN2",
        "diaChiLapD": "",
        "longitude": 105.8235793264663,
        "latitude": 21.01656910328356,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 707,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82337279637134,
          21.016396342379778
        ]
      },
      "properties": {
        "tenDoiTuon": "HN2/18",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hoàng Cầu",
        "tuDien": "HN2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 10,
        "tuDien2": "HN2",
        "diaChiLapD": "",
        "longitude": 105.82337279637134,
        "latitude": 21.016396342379778,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 708,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82456577345481,
          21.017964484512692
        ]
      },
      "properties": {
        "tenDoiTuon": "HN2/19",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hoàng Cầu",
        "tuDien": "HN2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 10,
        "tuDien2": "HN2",
        "diaChiLapD": "",
        "longitude": 105.82456577345481,
        "latitude": 21.017964484512692,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 709,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82442043126079,
          21.017705814963538
        ]
      },
      "properties": {
        "tenDoiTuon": "HN2/20",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hoàng Cầu",
        "tuDien": "HN2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 10,
        "tuDien2": "HN2",
        "diaChiLapD": "",
        "longitude": 105.82442043126079,
        "latitude": 21.017705814963538,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 710,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82427559197087,
          21.01743290479959
        ]
      },
      "properties": {
        "tenDoiTuon": "HN2/21",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hoàng Cầu",
        "tuDien": "HN2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 10,
        "tuDien2": "HN2",
        "diaChiLapD": "",
        "longitude": 105.82427559197087,
        "latitude": 21.01743290479959,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 711,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82413879931315,
          21.0171800242942
        ]
      },
      "properties": {
        "tenDoiTuon": "HN2/22",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hoàng Cầu",
        "tuDien": "HN2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 10,
        "tuDien2": "HN2",
        "diaChiLapD": "",
        "longitude": 105.82413879931315,
        "latitude": 21.0171800242942,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 712,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82400043327944,
          21.016924639586495
        ]
      },
      "properties": {
        "tenDoiTuon": "HN2/23",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hoàng Cầu",
        "tuDien": "HN2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 10,
        "tuDien2": "HN2",
        "diaChiLapD": "",
        "longitude": 105.82400043327944,
        "latitude": 21.016924639586495,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 713,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82383815963915,
          21.016680521442098
        ]
      },
      "properties": {
        "tenDoiTuon": "HN2/24",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hoàng Cầu",
        "tuDien": "HN2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 10,
        "tuDien2": "HN2",
        "diaChiLapD": "",
        "longitude": 105.82383815963915,
        "latitude": 21.016680521442098,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 714,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82366381605507,
          21.016407609401842
        ]
      },
      "properties": {
        "tenDoiTuon": "HN2/25",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hoàng Cầu",
        "tuDien": "HN2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 10,
        "tuDien2": "HN2",
        "diaChiLapD": "",
        "longitude": 105.82366381605507,
        "latitude": 21.016407609401842,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 715,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82351897676514,
          21.016162238517573
        ]
      },
      "properties": {
        "tenDoiTuon": "HN2/26",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hoàng Cầu",
        "tuDien": "HN2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 10,
        "tuDien2": "HN2",
        "diaChiLapD": "",
        "longitude": 105.82351897676514,
        "latitude": 21.016162238517573,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 716,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82367454489504,
          21.017357791822533
        ]
      },
      "properties": {
        "tenDoiTuon": "HN2/50",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Hoàng Cầu",
        "tuDien": "HN2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Sân vườn",
        "chieuCao": 6,
        "tuDien2": "HN2",
        "diaChiLapD": "",
        "longitude": 105.82367454489504,
        "latitude": 21.017357791822533,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 717,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82354713996442,
          21.01711617815742
        ]
      },
      "properties": {
        "tenDoiTuon": "HN2/51",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Hoàng Cầu",
        "tuDien": "HN2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Sân vườn",
        "chieuCao": 6,
        "tuDien2": "HN2",
        "diaChiLapD": "",
        "longitude": 105.82354713996442,
        "latitude": 21.01711617815742,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 718,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82341168841054,
          21.016864549002563
        ]
      },
      "properties": {
        "tenDoiTuon": "HN2/52",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Hoàng Cầu",
        "tuDien": "HN2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Sân vườn",
        "chieuCao": 6,
        "tuDien2": "HN2",
        "diaChiLapD": "",
        "longitude": 105.82341168841054,
        "latitude": 21.016864549002563,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 719,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82322125156976,
          21.016683025219145
        ]
      },
      "properties": {
        "tenDoiTuon": "HN2/53",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Hoàng Cầu",
        "tuDien": "HN2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Sân vườn",
        "chieuCao": 6,
        "tuDien2": "HN2",
        "diaChiLapD": "",
        "longitude": 105.82322125156976,
        "latitude": 21.016683025219145,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 720,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82300935707465,
          21.016590385410538
        ]
      },
      "properties": {
        "tenDoiTuon": "HN2/54",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Hoàng Cầu",
        "tuDien": "HN2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Sân vườn",
        "chieuCao": 6,
        "tuDien2": "HN2",
        "diaChiLapD": "",
        "longitude": 105.82300935707465,
        "latitude": 21.016590385410538,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 721,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8227183374128,
          21.016570355171996
        ]
      },
      "properties": {
        "tenDoiTuon": "HN2/55",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Hoàng Cầu",
        "tuDien": "HN2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Sân vườn",
        "chieuCao": 6,
        "tuDien2": "HN2",
        "diaChiLapD": "",
        "longitude": 105.8227183374128,
        "latitude": 21.016570355171996,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 722,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82242061220973,
          21.016567851391745
        ]
      },
      "properties": {
        "tenDoiTuon": "HN2/56",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Hoàng Cầu",
        "tuDien": "HN2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Sân vườn",
        "chieuCao": 6,
        "tuDien2": "HN2",
        "diaChiLapD": "",
        "longitude": 105.82242061220973,
        "latitude": 21.016567851391745,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 723,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82215239126484,
          21.016569103284404
        ]
      },
      "properties": {
        "tenDoiTuon": "HN2/57",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Hoàng Cầu",
        "tuDien": "HN2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Sân vườn",
        "chieuCao": 6,
        "tuDien2": "HN2",
        "diaChiLapD": "",
        "longitude": 105.82215239126484,
        "latitude": 21.016569103284404,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 724,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8256888838615,
          21.020487467128888
        ]
      },
      "properties": {
        "tenDoiTuon": "HN2/6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - đèn pha",
        "tenDuongPh": "Hoàng Cầu",
        "tuDien": "HN2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 14,
        "tuDien2": "HN2",
        "diaChiLapD": "",
        "longitude": 105.8256888838615,
        "latitude": 21.020487467128888,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 725,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8254099341216,
          21.020587615654343
        ]
      },
      "properties": {
        "tenDoiTuon": "HN2/49",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - đèn pha",
        "tenDuongPh": "Hoàng Cầu",
        "tuDien": "HN2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 14,
        "tuDien2": "HN2",
        "diaChiLapD": "",
        "longitude": 105.8254099341216,
        "latitude": 21.020587615654343,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 726,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83553493784846,
          21.011203093755782
        ]
      },
      "properties": {
        "tenDoiTuon": "",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 46 Phạm Ngọc Thạch",
        "tuDien": "HĐA",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "Trạm Hồ Đấu",
        "diaChiLapD": "",
        "longitude": 105.83553493784846,
        "latitude": 21.011203093755782,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 727,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82974706607958,
          21.008793412557498
        ]
      },
      "properties": {
        "tenDoiTuon": "28",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 82 Phạm Ngọc Thạch",
        "tuDien": "C5TT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": "",
        "tuDien2": "C5 Trung Tự",
        "diaChiLapD": "",
        "longitude": 105.82974706607958,
        "latitude": 21.008793412557498,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 728,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82949225623628,
          21.008515478257255
        ]
      },
      "properties": {
        "tenDoiTuon": "29",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 82 Phạm Ngọc Thạch",
        "tuDien": "C5TT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 7,
        "tuDien2": "C5 Trung Tự",
        "diaChiLapD": "",
        "longitude": 105.82949225623628,
        "latitude": 21.008515478257255,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 729,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8293138893406,
          21.008184961117887
        ]
      },
      "properties": {
        "tenDoiTuon": "C5TT 30",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 82 Phạm Ngọc Thạch",
        "tuDien": "C5TT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 7,
        "tuDien2": "C5 Trung Tự",
        "diaChiLapD": "",
        "longitude": 105.8293138893406,
        "latitude": 21.008184961117887,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 730,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82896386105958,
          21.008310157090293
        ]
      },
      "properties": {
        "tenDoiTuon": "C5TT 32",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 82 Phạm Ngọc Thạch",
        "tuDien": "C5TT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "C5 Trung Tự",
        "diaChiLapD": "",
        "longitude": 105.82896386105958,
        "latitude": 21.008310157090293,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 731,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82915027458749,
          21.00824380323853
        ]
      },
      "properties": {
        "tenDoiTuon": "31",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 82 Phạm Ngọc Thạch",
        "tuDien": "C5TT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "C5 Trung Tự",
        "diaChiLapD": "",
        "longitude": 105.82915027458749,
        "latitude": 21.00824380323853,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 732,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82905907947934,
          21.008469155823676
        ]
      },
      "properties": {
        "tenDoiTuon": "C5TT 34",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 82 Phạm Ngọc Thạch",
        "tuDien": "C5TT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "C5 Trung Tự",
        "diaChiLapD": "",
        "longitude": 105.82905907947934,
        "latitude": 21.008469155823676,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 733,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8292025776654,
          21.008510470427144
        ]
      },
      "properties": {
        "tenDoiTuon": "C5TT 35",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 82 Phạm Ngọc Thạch",
        "tuDien": "C5TT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "C5 Trung Tự",
        "diaChiLapD": "",
        "longitude": 105.8292025776654,
        "latitude": 21.008510470427144,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 734,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8292253764402,
          21.008390282457146
        ]
      },
      "properties": {
        "tenDoiTuon": "33",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 82 Phạm Ngọc Thạch",
        "tuDien": "C5TT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "C5 Trung Tự",
        "diaChiLapD": "",
        "longitude": 105.8292253764402,
        "latitude": 21.008390282457146,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 735,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83163746071335,
          21.013621600080477
        ]
      },
      "properties": {
        "tenDoiTuon": "TT2 33",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Xã Đàn",
        "tuDien": "TT2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Trung Tự 2",
        "diaChiLapD": "",
        "longitude": 105.83163746071335,
        "latitude": 21.013621600080477,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 736,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83181985092064,
          21.013393751445225
        ]
      },
      "properties": {
        "tenDoiTuon": "32",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Xã Đàn",
        "tuDien": "TT2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Trung Tự 2",
        "diaChiLapD": "",
        "longitude": 105.83181985092064,
        "latitude": 21.013393751445225,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 737,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83206393194196,
          21.01312834490316
        ]
      },
      "properties": {
        "tenDoiTuon": "31",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Xã Đàn",
        "tuDien": "TT2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Trung Tự 2",
        "diaChiLapD": "",
        "longitude": 105.83206393194196,
        "latitude": 21.01312834490316,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 738,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83230801296325,
          21.012862937889672
        ]
      },
      "properties": {
        "tenDoiTuon": "TT2 30",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Xã Đàn",
        "tuDien": "TT2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Trung Tự 2",
        "diaChiLapD": "",
        "longitude": 105.83230801296325,
        "latitude": 21.012862937889672,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 739,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83300538730856,
          21.013357445860926
        ]
      },
      "properties": {
        "tenDoiTuon": "TT2 34",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Xã Đàn",
        "tuDien": "TT2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Trung Tự 2",
        "diaChiLapD": "",
        "longitude": 105.83300538730856,
        "latitude": 21.013357445860926,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 740,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8329048044733,
          21.013458851090085
        ]
      },
      "properties": {
        "tenDoiTuon": "35",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Xã Đàn",
        "tuDien": "TT2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Trung Tự 2",
        "diaChiLapD": "",
        "longitude": 105.8329048044733,
        "latitude": 21.013458851090085,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 741,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83310328793604,
          21.01362785965301
        ]
      },
      "properties": {
        "tenDoiTuon": "36",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Xã Đàn",
        "tuDien": "TT2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Trung Tự 2",
        "diaChiLapD": "",
        "longitude": 105.83310328793604,
        "latitude": 21.01362785965301,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 742,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83271302652993,
          21.01362660773818
        ]
      },
      "properties": {
        "tenDoiTuon": "37",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Xã Đàn",
        "tuDien": "TT2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Trung Tự 2",
        "diaChiLapD": "",
        "longitude": 105.83271302652993,
        "latitude": 21.01362660773818,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 743,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83269827437836,
          21.013736776181055
        ]
      },
      "properties": {
        "tenDoiTuon": "TT2 38",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Xã Đàn",
        "tuDien": "TT2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Trung Tự 2",
        "diaChiLapD": "",
        "longitude": 105.83269827437836,
        "latitude": 21.013736776181055,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 744,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.818267211509,
          21.0020514818014
        ]
      },
      "properties": {
        "tenDoiTuon": "CM 01",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "CCM",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 8,
        "tuDien2": "CM",
        "diaChiLapD": "",
        "longitude": 105.818267211509,
        "latitude": 21.0020514818014,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 745,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81813578326677,
          21.002304387953046
        ]
      },
      "properties": {
        "tenDoiTuon": "02",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "CCM",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 8,
        "tuDien2": "CM",
        "diaChiLapD": "",
        "longitude": 105.81813578326677,
        "latitude": 21.002304387953046,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 746,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81800569612838,
          21.002593601886936
        ]
      },
      "properties": {
        "tenDoiTuon": "03",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "CCM",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 8,
        "tuDien2": "CM",
        "diaChiLapD": "",
        "longitude": 105.81800569612838,
        "latitude": 21.002593601886936,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 747,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81786890347067,
          21.00290660334973
        ]
      },
      "properties": {
        "tenDoiTuon": "04",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "CCM",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 8,
        "tuDien2": "CM",
        "diaChiLapD": "",
        "longitude": 105.81786890347067,
        "latitude": 21.00290660334973,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 748,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81774820406837,
          21.00318454809747
        ]
      },
      "properties": {
        "tenDoiTuon": "05",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "CCM",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 8,
        "tuDien2": "CM",
        "diaChiLapD": "",
        "longitude": 105.81774820406837,
        "latitude": 21.00318454809747,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 749,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81761677582614,
          21.00347751632569
        ]
      },
      "properties": {
        "tenDoiTuon": "06",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "CCM",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 8,
        "tuDien2": "CM",
        "diaChiLapD": "",
        "longitude": 105.81761677582614,
        "latitude": 21.00347751632569,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 750,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81750948746254,
          21.003710388096025
        ]
      },
      "properties": {
        "tenDoiTuon": "07",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "CCM",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 8,
        "tuDien2": "CM",
        "diaChiLapD": "",
        "longitude": 105.81750948746254,
        "latitude": 21.003710388096025,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 751,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81740622242854,
          21.003963291436428
        ]
      },
      "properties": {
        "tenDoiTuon": "08",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "CCM",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 8,
        "tuDien2": "CM",
        "diaChiLapD": "",
        "longitude": 105.81740622242854,
        "latitude": 21.003963291436428,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 752,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81731636842427,
          21.004228714282295
        ]
      },
      "properties": {
        "tenDoiTuon": "09",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "CCM",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 8,
        "tuDien2": "CM",
        "diaChiLapD": "",
        "longitude": 105.81731636842427,
        "latitude": 21.004228714282295,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 753,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81720371564522,
          21.004497892629555
        ]
      },
      "properties": {
        "tenDoiTuon": "10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "CCM",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 8,
        "tuDien2": "CM",
        "diaChiLapD": "",
        "longitude": 105.81720371564522,
        "latitude": 21.004497892629555,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 754,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81726574172845,
          21.004913240127504
        ]
      },
      "properties": {
        "tenDoiTuon": "11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "CCM",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 8,
        "tuDien2": "CM",
        "diaChiLapD": "",
        "longitude": 105.81726574172845,
        "latitude": 21.004913240127504,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 755,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81711226907618,
          21.00514102346561
        ]
      },
      "properties": {
        "tenDoiTuon": "12",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "CCM",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 8,
        "tuDien2": "CM",
        "diaChiLapD": "",
        "longitude": 105.81711226907618,
        "latitude": 21.00514102346561,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 756,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81695242618275,
          21.00536598949235
        ]
      },
      "properties": {
        "tenDoiTuon": "13",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "CCM",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 8,
        "tuDien2": "CM",
        "diaChiLapD": "",
        "longitude": 105.81695242618275,
        "latitude": 21.00536598949235,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 757,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81679258328934,
          21.00559095517993
        ]
      },
      "properties": {
        "tenDoiTuon": "14",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "CCM",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 8,
        "tuDien2": "CM",
        "diaChiLapD": "",
        "longitude": 105.81679258328934,
        "latitude": 21.00559095517993,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 758,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8166327403959,
          21.00581592052832
        ]
      },
      "properties": {
        "tenDoiTuon": "15",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "CCM",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 8,
        "tuDien2": "CM",
        "diaChiLapD": "",
        "longitude": 105.8166327403959,
        "latitude": 21.00581592052832,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 759,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81521100199328,
          21.007697791287992
        ]
      },
      "properties": {
        "tenDoiTuon": "CM 22",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "CCM",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 8,
        "tuDien2": "CM",
        "diaChiLapD": "",
        "longitude": 105.81521100199328,
        "latitude": 21.007697791287992,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 760,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81498971974678,
          21.007966963377164
        ]
      },
      "properties": {
        "tenDoiTuon": "23",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "CCM",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 8,
        "tuDien2": "CM",
        "diaChiLapD": "",
        "longitude": 105.81498971974678,
        "latitude": 21.007966963377164,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 761,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81475770866928,
          21.00822862322184
        ]
      },
      "properties": {
        "tenDoiTuon": "24",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "CCM",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 8,
        "tuDien2": "CM",
        "diaChiLapD": "",
        "longitude": 105.81475770866928,
        "latitude": 21.00822862322184,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 762,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81451496875187,
          21.008495290438418
        ]
      },
      "properties": {
        "tenDoiTuon": "25",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "CCM",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 8,
        "tuDien2": "CM",
        "diaChiLapD": "",
        "longitude": 105.81451496875187,
        "latitude": 21.008495290438418,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 763,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81428429877823,
          21.008770720865968
        ]
      },
      "properties": {
        "tenDoiTuon": "26",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "CCM",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 8,
        "tuDien2": "CM",
        "diaChiLapD": "",
        "longitude": 105.81428429877823,
        "latitude": 21.008770720865968,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 764,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81398791467902,
          21.00897854530592
        ]
      },
      "properties": {
        "tenDoiTuon": "CM 27",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "CCM",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 8,
        "tuDien2": "CM",
        "diaChiLapD": "",
        "longitude": 105.81398791467902,
        "latitude": 21.00897854530592,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 765,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81812253987165,
          21.001856011344774
        ]
      },
      "properties": {
        "tenDoiTuon": "28",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "CCM",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 10,
        "tuDien2": "CM",
        "diaChiLapD": "",
        "longitude": 105.81812253987165,
        "latitude": 21.001856011344774,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 766,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81792807972052,
          21.00171954182672
        ]
      },
      "properties": {
        "tenDoiTuon": "CM 29",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 9,
        "maXaPhuong": 346,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "CCM",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 10,
        "tuDien2": "CM",
        "diaChiLapD": "",
        "longitude": 105.81792807972052,
        "latitude": 21.00171954182672,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 767,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81811717547411,
          21.001992480737194
        ]
      },
      "properties": {
        "tenDoiTuon": "01",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Láng",
        "tuDien": "CCM",
        "chuDauTu": "SoXayDung",
        "loaiCot": "DC-05",
        "chieuCao": 3,
        "tuDien2": "CM",
        "diaChiLapD": "",
        "longitude": 105.81811717547411,
        "latitude": 21.001992480737194,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 768,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81799647607184,
          21.002252899048106
        ]
      },
      "properties": {
        "tenDoiTuon": "02",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Láng",
        "tuDien": "CCM",
        "chuDauTu": "SoXayDung",
        "loaiCot": "DC-05",
        "chieuCao": 3,
        "tuDien2": "CM",
        "diaChiLapD": "",
        "longitude": 105.81799647607184,
        "latitude": 21.002252899048106,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 769,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81787443555669,
          21.002553381150214
        ]
      },
      "properties": {
        "tenDoiTuon": "03",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Láng",
        "tuDien": "CCM",
        "chuDauTu": "SoXayDung",
        "loaiCot": "DC-05",
        "chieuCao": 3,
        "tuDien2": "CM",
        "diaChiLapD": "",
        "longitude": 105.81787443555669,
        "latitude": 21.002553381150214,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 770,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81508460292424,
          21.007822048695914
        ]
      },
      "properties": {
        "tenDoiTuon": "22",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Láng",
        "tuDien": "CCM",
        "chuDauTu": "SoXayDung",
        "loaiCot": "DC-05",
        "chieuCao": 3,
        "tuDien2": "CM",
        "diaChiLapD": "",
        "longitude": 105.81508460292424,
        "latitude": 21.007822048695914,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 771,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81527503976375,
          21.00757415975728
        ]
      },
      "properties": {
        "tenDoiTuon": "21",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Láng",
        "tuDien": "CCM",
        "chuDauTu": "SoXayDung",
        "loaiCot": "DC-05",
        "chieuCao": 3,
        "tuDien2": "CM",
        "diaChiLapD": "",
        "longitude": 105.81527503976375,
        "latitude": 21.00757415975728,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 772,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81485125073391,
          21.0080799529115
        ]
      },
      "properties": {
        "tenDoiTuon": "23",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Láng",
        "tuDien": "CCM",
        "chuDauTu": "SoXayDung",
        "loaiCot": "DC-05",
        "chieuCao": 3,
        "tuDien2": "CM",
        "diaChiLapD": "",
        "longitude": 105.81485125073391,
        "latitude": 21.0080799529115,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 773,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8145535255398,
          21.008410470283483
        ]
      },
      "properties": {
        "tenDoiTuon": "24",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Láng",
        "tuDien": "CCM",
        "chuDauTu": "SoXayDung",
        "loaiCot": "DC-05",
        "chieuCao": 3,
        "tuDien2": "CM",
        "diaChiLapD": "",
        "longitude": 105.8145535255398,
        "latitude": 21.008410470283483,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 774,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82477190368772,
          21.01976858652347
        ]
      },
      "properties": {
        "tenDoiTuon": "45-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 26 Hoàng Cầu",
        "tuDien": "HN2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "HN2",
        "diaChiLapD": "",
        "longitude": 105.82477190368772,
        "latitude": 21.01976858652347,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 775,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82480543129346,
          21.0198411945609
        ]
      },
      "properties": {
        "tenDoiTuon": "45-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 26 Hoàng Cầu",
        "tuDien": "HN2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "HN2",
        "diaChiLapD": "",
        "longitude": 105.82480543129346,
        "latitude": 21.0198411945609,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 776,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83724929375026,
          21.00762408187114
        ]
      },
      "properties": {
        "tenDoiTuon": "B8AKL/24-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 9 Lương Đình Của",
        "tuDien": "B8AKL",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "B8A Kim Liên",
        "diaChiLapD": "",
        "longitude": 105.83724929375026,
        "latitude": 21.00762408187114,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 777,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83659617585067,
          21.00700185399137
        ]
      },
      "properties": {
        "tenDoiTuon": "B8AKL/25",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 5 Hoàng Tích Trí",
        "tuDien": "B8AKL",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "B8A Kim Liên",
        "diaChiLapD": "",
        "longitude": 105.83659617585067,
        "latitude": 21.00700185399137,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 778,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83645804208909,
          21.006953027146217
        ]
      },
      "properties": {
        "tenDoiTuon": "B8AKL/25-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 5 Hoàng Tích Trí",
        "tuDien": "B8AKL",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 8,
        "tuDien2": "B8A Kim Liên",
        "diaChiLapD": "",
        "longitude": 105.83645804208909,
        "latitude": 21.006953027146217,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 779,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8367812482747,
          21.006854121437158
        ]
      },
      "properties": {
        "tenDoiTuon": "B8AKL/26",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 5 Hoàng Tích Trí",
        "tuDien": "B8AKL",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "B8A Kim Liên",
        "diaChiLapD": "",
        "longitude": 105.8367812482747,
        "latitude": 21.006854121437158,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 780,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83694486302781,
          21.00670388479171
        ]
      },
      "properties": {
        "tenDoiTuon": "B8AKL/27",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 5 Hoàng Tích Trí",
        "tuDien": "B8AKL",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "B8A Kim Liên",
        "diaChiLapD": "",
        "longitude": 105.83694486302781,
        "latitude": 21.00670388479171,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 781,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8371366409712,
          21.00650732495232
        ]
      },
      "properties": {
        "tenDoiTuon": "B8AKL/28",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 5 Hoàng Tích Trí",
        "tuDien": "B8AKL",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "B8A Kim Liên",
        "diaChiLapD": "",
        "longitude": 105.8371366409712,
        "latitude": 21.00650732495232,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 782,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83690462989371,
          21.006299497069275
        ]
      },
      "properties": {
        "tenDoiTuon": "B8AKL/29",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 5 Hoàng Tích Trí",
        "tuDien": "B8AKL",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "B8A Kim Liên",
        "diaChiLapD": "",
        "longitude": 105.83690462989371,
        "latitude": 21.006299497069275,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 783,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83670346421425,
          21.006124220316813
        ]
      },
      "properties": {
        "tenDoiTuon": "B8AKL/30",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 5 Hoàng Tích Trí",
        "tuDien": "B8AKL",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "B8A Kim Liên",
        "diaChiLapD": "",
        "longitude": 105.83670346421425,
        "latitude": 21.006124220316813,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 784,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83650766295926,
          21.00597147897917
        ]
      },
      "properties": {
        "tenDoiTuon": "B8AKL/31",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 5 Hoàng Tích Trí",
        "tuDien": "B8AKL",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "B8A Kim Liên",
        "diaChiLapD": "",
        "longitude": 105.83650766295926,
        "latitude": 21.00597147897917,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 785,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8352013381011,
          21.00620059092616
        ]
      },
      "properties": {
        "tenDoiTuon": "KT3 01",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Đông Tác",
        "tuDien": "KTH3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "KTH3",
        "diaChiLapD": "",
        "longitude": 105.8352013381011,
        "latitude": 21.00620059092616,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 786,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83487410860387,
          21.006127976248457
        ]
      },
      "properties": {
        "tenDoiTuon": "02",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Đông Tác",
        "tuDien": "KTH3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "KTH3",
        "diaChiLapD": "",
        "longitude": 105.83487410860387,
        "latitude": 21.006127976248457,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 787,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83457370119302,
          21.006035329884448
        ]
      },
      "properties": {
        "tenDoiTuon": "03",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Đông Tác",
        "tuDien": "KTH3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "KTH3",
        "diaChiLapD": "",
        "longitude": 105.83457370119302,
        "latitude": 21.006035329884448,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 788,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83422769623262,
          21.005957707210502
        ]
      },
      "properties": {
        "tenDoiTuon": "04",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Đông Tác",
        "tuDien": "KTH3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "KTH3",
        "diaChiLapD": "",
        "longitude": 105.83422769623262,
        "latitude": 21.005957707210502,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 789,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83387900905547,
          21.005895108250883
        ]
      },
      "properties": {
        "tenDoiTuon": "05",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Đông Tác",
        "tuDien": "KTH3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "KTH3",
        "diaChiLapD": "",
        "longitude": 105.83387900905547,
        "latitude": 21.005895108250883,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 790,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83360810594777,
          21.00588008449615
        ]
      },
      "properties": {
        "tenDoiTuon": "06",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Đông Tác",
        "tuDien": "KTH3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "KTH3",
        "diaChiLapD": "",
        "longitude": 105.83360810594777,
        "latitude": 21.00588008449615,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 791,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83330233412144,
          21.005912635962414
        ]
      },
      "properties": {
        "tenDoiTuon": "07",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Đông Tác",
        "tuDien": "KTH3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "KTH3",
        "diaChiLapD": "",
        "longitude": 105.83330233412144,
        "latitude": 21.005912635962414,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 792,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83300997333384,
          21.00594268346292
        ]
      },
      "properties": {
        "tenDoiTuon": "08",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Đông Tác",
        "tuDien": "KTH3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "KTH3",
        "diaChiLapD": "",
        "longitude": 105.83300997333384,
        "latitude": 21.00594268346292,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 793,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8327497990661,
          21.00602030614385
        ]
      },
      "properties": {
        "tenDoiTuon": "09",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Đông Tác",
        "tuDien": "KTH3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "KTH3",
        "diaChiLapD": "",
        "longitude": 105.8327497990661,
        "latitude": 21.00602030614385,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 794,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83246280269397,
          21.005987754701952
        ]
      },
      "properties": {
        "tenDoiTuon": "10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Đông Tác",
        "tuDien": "KTH3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "KTH3",
        "diaChiLapD": "",
        "longitude": 105.83246280269397,
        "latitude": 21.005987754701952,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 795,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83217044191532,
          21.005887596373288
        ]
      },
      "properties": {
        "tenDoiTuon": "11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Đông Tác",
        "tuDien": "KTH3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "KTH3",
        "diaChiLapD": "",
        "longitude": 105.83217044191532,
        "latitude": 21.005887596373288,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 796,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83178420385676,
          21.005975234913812
        ]
      },
      "properties": {
        "tenDoiTuon": "12",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Đông Tác",
        "tuDien": "KTH3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "KTH3",
        "diaChiLapD": "",
        "longitude": 105.83178420385676,
        "latitude": 21.005975234913812,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 797,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8314757498227,
          21.00607288922723
        ]
      },
      "properties": {
        "tenDoiTuon": "KT3 13",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Đông Tác",
        "tuDien": "KTH3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "KTH3",
        "diaChiLapD": "",
        "longitude": 105.8314757498227,
        "latitude": 21.00607288922723,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 798,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83137650804642,
          21.006173047432362
        ]
      },
      "properties": {
        "tenDoiTuon": "KT3 14",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Đông Tác",
        "tuDien": "KTH3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "KTH3",
        "diaChiLapD": "",
        "longitude": 105.83137650804642,
        "latitude": 21.006173047432362,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 799,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83620072883872,
          21.006641912130593
        ]
      },
      "properties": {
        "tenDoiTuon": "B8AKL/21-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 5 Hoàng Tích Trí",
        "tuDien": "B8AKL",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "B8A Kim Liên",
        "diaChiLapD": "",
        "longitude": 105.83620072883872,
        "latitude": 21.006641912130593,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 800,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83637373131894,
          21.00651921870733
        ]
      },
      "properties": {
        "tenDoiTuon": "B8AKL/21-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 5 Hoàng Tích Trí",
        "tuDien": "B8AKL",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "B8A Kim Liên",
        "diaChiLapD": "",
        "longitude": 105.83637373131894,
        "latitude": 21.00651921870733,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 801,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83653600495919,
          21.00633267443076
        ]
      },
      "properties": {
        "tenDoiTuon": "B8AKL/21-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 5 Hoàng Tích Trí",
        "tuDien": "B8AKL",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "B8A Kim Liên",
        "diaChiLapD": "",
        "longitude": 105.83653600495919,
        "latitude": 21.00633267443076,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 802,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83656685036618,
          21.0065492660857
        ]
      },
      "properties": {
        "tenDoiTuon": "B8AKL/21-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 5 Hoàng Tích Trí",
        "tuDien": "B8AKL",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "B8A Kim Liên",
        "diaChiLapD": "",
        "longitude": 105.83656685036618,
        "latitude": 21.0065492660857,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 803,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83660306018866,
          21.006664447646628
        ]
      },
      "properties": {
        "tenDoiTuon": "B8AKL/21-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 5 Hoàng Tích Trí",
        "tuDien": "B8AKL",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "B8A Kim Liên",
        "diaChiLapD": "",
        "longitude": 105.83660306018866,
        "latitude": 21.006664447646628,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 804,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83249633033564,
          21.005824997383417
        ]
      },
      "properties": {
        "tenDoiTuon": "KT3 16",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Đông Tác",
        "tuDien": "KTH3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "KTH3",
        "diaChiLapD": "",
        "longitude": 105.83249633033564,
        "latitude": 21.005824997383417,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 805,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8328812273263,
          21.00586130480001
        ]
      },
      "properties": {
        "tenDoiTuon": "KT3 15",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Đông Tác",
        "tuDien": "KTH3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "KTH3",
        "diaChiLapD": "",
        "longitude": 105.8328812273263,
        "latitude": 21.00586130480001,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 806,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81399579365865,
          21.008893568932205
        ]
      },
      "properties": {
        "tenDoiTuon": "CM 25",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Láng",
        "tuDien": "CCM",
        "chuDauTu": "SoXayDung",
        "loaiCot": "DC-05",
        "chieuCao": 3,
        "tuDien2": "CM",
        "diaChiLapD": "",
        "longitude": 105.81399579365865,
        "latitude": 21.008893568932205,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 807,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82005892713407,
          21.015461176626335
        ]
      },
      "properties": {
        "tenDoiTuon": "08",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hoàng Cầu",
        "tuDien": "NAQ10",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 8,
        "tuDien2": "Nguyễn Ái Quốc 10",
        "diaChiLapD": "",
        "longitude": 105.82005892713407,
        "latitude": 21.015461176626335,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 808,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81988860684362,
          21.015257116918146
        ]
      },
      "properties": {
        "tenDoiTuon": "07",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hoàng Cầu",
        "tuDien": "NAQ10",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 8,
        "tuDien2": "Nguyễn Ái Quốc 10",
        "diaChiLapD": "",
        "longitude": 105.81988860684362,
        "latitude": 21.015257116918146,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 809,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8197115810518,
          21.0150518050304
        ]
      },
      "properties": {
        "tenDoiTuon": "06",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hoàng Cầu",
        "tuDien": "NAQ10",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 8,
        "tuDien2": "Nguyễn Ái Quốc 10",
        "diaChiLapD": "",
        "longitude": 105.8197115810518,
        "latitude": 21.0150518050304,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 810,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8195157797968,
          21.014836477624687
        ]
      },
      "properties": {
        "tenDoiTuon": "05",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hoàng Cầu",
        "tuDien": "NAQ10",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 8,
        "tuDien2": "Nguyễn Ái Quốc 10",
        "diaChiLapD": "",
        "longitude": 105.8195157797968,
        "latitude": 21.014836477624687,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 811,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81934680062821,
          21.014656203278605
        ]
      },
      "properties": {
        "tenDoiTuon": "04",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hoàng Cầu",
        "tuDien": "NAQ10",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 8,
        "tuDien2": "Nguyễn Ái Quốc 10",
        "diaChiLapD": "",
        "longitude": 105.81934680062821,
        "latitude": 21.014656203278605,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 812,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81912283617395,
          21.01441333332931
        ]
      },
      "properties": {
        "tenDoiTuon": "03",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hoàng Cầu",
        "tuDien": "NAQ10",
        "chuDauTu": "SoGTVT-BanDuyTu",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 8,
        "tuDien2": "Nguyễn Ái Quốc 10",
        "diaChiLapD": "",
        "longitude": 105.81912283617395,
        "latitude": 21.01441333332931,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 813,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81893239933444,
          21.014209272189113
        ]
      },
      "properties": {
        "tenDoiTuon": "02",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hoàng Cầu",
        "tuDien": "NAQ10",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 8,
        "tuDien2": "Nguyễn Ái Quốc 10",
        "diaChiLapD": "",
        "longitude": 105.81893239933444,
        "latitude": 21.014209272189113,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 814,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81877549010966,
          21.0140302489964
        ]
      },
      "properties": {
        "tenDoiTuon": "NAQ10 01",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hoàng Cầu",
        "tuDien": "NAQ10",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 8,
        "tuDien2": "Nguyễn Ái Quốc 10",
        "diaChiLapD": "",
        "longitude": 105.81877549010966,
        "latitude": 21.0140302489964,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 815,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8202627750033,
          21.015661480358787
        ]
      },
      "properties": {
        "tenDoiTuon": "09",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hoàng Cầu",
        "tuDien": "NAQ10",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 8,
        "tuDien2": "Nguyễn Ái Quốc 10",
        "diaChiLapD": "",
        "longitude": 105.8202627750033,
        "latitude": 21.015661480358787,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 816,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82049711766523,
          21.01588588666738
        ]
      },
      "properties": {
        "tenDoiTuon": "10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hoàng Cầu",
        "tuDien": "NAQ10",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 8,
        "tuDien2": "Nguyễn Ái Quốc 10",
        "diaChiLapD": "",
        "longitude": 105.82049711766523,
        "latitude": 21.01588588666738,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 817,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82076166588554,
          21.016137200656672
        ]
      },
      "properties": {
        "tenDoiTuon": "11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hoàng Cầu",
        "tuDien": "NAQ10",
        "chuDauTu": "SoGTVT-BanDuyTu",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 8,
        "tuDien2": "Nguyễn Ái Quốc 10",
        "diaChiLapD": "",
        "longitude": 105.82076166588554,
        "latitude": 21.016137200656672,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 818,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82168032247766,
          21.016526539020784
        ]
      },
      "properties": {
        "tenDoiTuon": "14",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hoàng Cầu",
        "tuDien": "NAQ10",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 8,
        "tuDien2": "Nguyễn Ái Quốc 10",
        "diaChiLapD": "",
        "longitude": 105.82168032247766,
        "latitude": 21.016526539020784,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 819,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82203973849475,
          21.016529042800883
        ]
      },
      "properties": {
        "tenDoiTuon": "15",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hoàng Cầu",
        "tuDien": "NAQ10",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 8,
        "tuDien2": "Nguyễn Ái Quốc 10",
        "diaChiLapD": "",
        "longitude": 105.82203973849475,
        "latitude": 21.016529042800883,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 820,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82232405265015,
          21.016526539020784
        ]
      },
      "properties": {
        "tenDoiTuon": "16",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hoàng Cầu",
        "tuDien": "NAQ10",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 8,
        "tuDien2": "Nguyễn Ái Quốc 10",
        "diaChiLapD": "",
        "longitude": 105.82232405265015,
        "latitude": 21.016526539020784,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 821,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82261373122104,
          21.016531546581795
        ]
      },
      "properties": {
        "tenDoiTuon": "NAQ10 17",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hoàng Cầu",
        "tuDien": "NAQ10",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 8,
        "tuDien2": "Nguyễn Ái Quốc 10",
        "diaChiLapD": "",
        "longitude": 105.82261373122104,
        "latitude": 21.016531546581795,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 822,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82128603775992,
          21.016526539020784
        ]
      },
      "properties": {
        "tenDoiTuon": "13",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hoàng Cầu",
        "tuDien": "NAQ10",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 8,
        "tuDien2": "Nguyễn Ái Quốc 10",
        "diaChiLapD": "",
        "longitude": 105.82128603775992,
        "latitude": 21.016526539020784,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 823,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82033385356235,
          21.015222063688014
        ]
      },
      "properties": {
        "tenDoiTuon": "34",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hoàng Cầu",
        "tuDien": "NAQ10",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 8,
        "tuDien2": "Nguyễn Ái Quốc 10",
        "diaChiLapD": "",
        "longitude": 105.82033385356235,
        "latitude": 21.015222063688014,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 824,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82056988796043,
          21.01546618422059
        ]
      },
      "properties": {
        "tenDoiTuon": "33",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hoàng Cầu",
        "tuDien": "NAQ10",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 8,
        "tuDien2": "Nguyễn Ái Quốc 10",
        "diaChiLapD": "",
        "longitude": 105.82056988796043,
        "latitude": 21.01546618422059,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 825,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82075630146137,
          21.01567086958779
        ]
      },
      "properties": {
        "tenDoiTuon": "32",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hoàng Cầu",
        "tuDien": "NAQ10",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 8,
        "tuDien2": "Nguyễn Ái Quốc 10",
        "diaChiLapD": "",
        "longitude": 105.82075630146137,
        "latitude": 21.01567086958779,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 826,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82094942050865,
          21.015868669249148
        ]
      },
      "properties": {
        "tenDoiTuon": "31",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hoàng Cầu",
        "tuDien": "NAQ10",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 8,
        "tuDien2": "Nguyễn Ái Quốc 10",
        "diaChiLapD": "",
        "longitude": 105.82094942050865,
        "latitude": 21.015868669249148,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 827,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82111705857338,
          21.016063964859462
        ]
      },
      "properties": {
        "tenDoiTuon": "30",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hoàng Cầu",
        "tuDien": "NAQ10",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 8,
        "tuDien2": "Nguyễn Ái Quốc 10",
        "diaChiLapD": "",
        "longitude": 105.82111705857338,
        "latitude": 21.016063964859462,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 828,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8213866205772,
          21.016246741288743
        ]
      },
      "properties": {
        "tenDoiTuon": "29",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hoàng Cầu",
        "tuDien": "NAQ10",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 8,
        "tuDien2": "Nguyễn Ái Quốc 10",
        "diaChiLapD": "",
        "longitude": 105.8213866205772,
        "latitude": 21.016246741288743,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 829,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82166557031711,
          21.016291809415474
        ]
      },
      "properties": {
        "tenDoiTuon": "28",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hoàng Cầu",
        "tuDien": "NAQ10",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 8,
        "tuDien2": "Nguyễn Ái Quốc 10",
        "diaChiLapD": "",
        "longitude": 105.82166557031711,
        "latitude": 21.016291809415474,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 830,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82194854336865,
          21.016296816984347
        ]
      },
      "properties": {
        "tenDoiTuon": "27",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hoàng Cầu",
        "tuDien": "NAQ10",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 8,
        "tuDien2": "Nguyễn Ái Quốc 10",
        "diaChiLapD": "",
        "longitude": 105.82194854336865,
        "latitude": 21.016296816984347,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 831,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82212959248105,
          21.016301824552233
        ]
      },
      "properties": {
        "tenDoiTuon": "26",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hoàng Cầu",
        "tuDien": "NAQ10",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 8,
        "tuDien2": "Nguyễn Ái Quốc 10",
        "diaChiLapD": "",
        "longitude": 105.82212959248105,
        "latitude": 21.016301824552233,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 832,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82227443176201,
          21.016304328336123
        ]
      },
      "properties": {
        "tenDoiTuon": "25",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hoàng Cầu",
        "tuDien": "NAQ10",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 8,
        "tuDien2": "Nguyễn Ái Quốc 10",
        "diaChiLapD": "",
        "longitude": 105.82227443176201,
        "latitude": 21.016304328336123,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 833,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82241658884419,
          21.016301824552233
        ]
      },
      "properties": {
        "tenDoiTuon": "24",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hoàng Cầu",
        "tuDien": "NAQ10",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 8,
        "tuDien2": "Nguyễn Ái Quốc 10",
        "diaChiLapD": "",
        "longitude": 105.82241658884419,
        "latitude": 21.016301824552233,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 834,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82268212753644,
          21.016299320767477
        ]
      },
      "properties": {
        "tenDoiTuon": "23",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hoàng Cầu",
        "tuDien": "NAQ10",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 8,
        "tuDien2": "Nguyễn Ái Quốc 10",
        "diaChiLapD": "",
        "longitude": 105.82268212753644,
        "latitude": 21.016299320767477,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 835,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82295303064413,
          21.01632435860815
        ]
      },
      "properties": {
        "tenDoiTuon": "NAQ10 22",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hoàng Cầu",
        "tuDien": "NAQ10",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 8,
        "tuDien2": "Nguyễn Ái Quốc 10",
        "diaChiLapD": "",
        "longitude": 105.82295303064413,
        "latitude": 21.01632435860815,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 836,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82251046616008,
          21.016194161790636
        ]
      },
      "properties": {
        "tenDoiTuon": "21",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hoàng Cầu",
        "tuDien": "NAQ10",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 8,
        "tuDien2": "Nguyễn Ái Quốc 10",
        "diaChiLapD": "",
        "longitude": 105.82251046616008,
        "latitude": 21.016194161790636,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 837,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82281087357093,
          21.01619666557632
        ]
      },
      "properties": {
        "tenDoiTuon": "20",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hoàng Cầu",
        "tuDien": "NAQ10",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 8,
        "tuDien2": "Nguyễn Ái Quốc 10",
        "diaChiLapD": "",
        "longitude": 105.82281087357093,
        "latitude": 21.01619666557632,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 838,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82304959016777,
          21.01619666557632
        ]
      },
      "properties": {
        "tenDoiTuon": "19",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hoàng Cầu",
        "tuDien": "NAQ10",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 8,
        "tuDien2": "Nguyễn Ái Quốc 10",
        "diaChiLapD": "",
        "longitude": 105.82304959016777,
        "latitude": 21.01619666557632,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 839,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82322929817632,
          21.01619666557633
        ]
      },
      "properties": {
        "tenDoiTuon": "NAQ10 18",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hoàng Cầu",
        "tuDien": "NAQ10",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 8,
        "tuDien2": "Nguyễn Ái Quốc 10",
        "diaChiLapD": "",
        "longitude": 105.82322929817632,
        "latitude": 21.01619666557633,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 840,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82013939340224,
          21.015037408145528
        ]
      },
      "properties": {
        "tenDoiTuon": "35",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hoàng Cầu",
        "tuDien": "NAQ10",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 8,
        "tuDien2": "Nguyễn Ái Quốc 10",
        "diaChiLapD": "",
        "longitude": 105.82013939340224,
        "latitude": 21.015037408145528,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 841,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81994090993052,
          21.01483209595451
        ]
      },
      "properties": {
        "tenDoiTuon": "36",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hoàng Cầu",
        "tuDien": "NAQ10",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 8,
        "tuDien2": "Nguyễn Ái Quốc 10",
        "diaChiLapD": "",
        "longitude": 105.81994090993052,
        "latitude": 21.01483209595451,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 842,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81973169762783,
          21.014614264419375
        ]
      },
      "properties": {
        "tenDoiTuon": "37",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hoàng Cầu",
        "tuDien": "NAQ10",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 8,
        "tuDien2": "Nguyễn Ái Quốc 10",
        "diaChiLapD": "",
        "longitude": 105.81973169762783,
        "latitude": 21.014614264419375,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 843,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81956808287472,
          21.014436493619954
        ]
      },
      "properties": {
        "tenDoiTuon": "38",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hoàng Cầu",
        "tuDien": "NAQ10",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 8,
        "tuDien2": "Nguyễn Ái Quốc 10",
        "diaChiLapD": "",
        "longitude": 105.81956808287472,
        "latitude": 21.014436493619954,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 844,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81938837487516,
          21.014228676783034
        ]
      },
      "properties": {
        "tenDoiTuon": "39",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hoàng Cầu",
        "tuDien": "NAQ10",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 8,
        "tuDien2": "Nguyễn Ái Quốc 10",
        "diaChiLapD": "",
        "longitude": 105.81938837487516,
        "latitude": 21.014228676783034,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 845,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81921671349882,
          21.014043394057925
        ]
      },
      "properties": {
        "tenDoiTuon": "40",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hoàng Cầu",
        "tuDien": "NAQ10",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 8,
        "tuDien2": "Nguyễn Ái Quốc 10",
        "diaChiLapD": "",
        "longitude": 105.81921671349882,
        "latitude": 21.014043394057925,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 846,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81903432329153,
          21.013851851538952
        ]
      },
      "properties": {
        "tenDoiTuon": "41",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hoàng Cầu",
        "tuDien": "NAQ10",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 8,
        "tuDien2": "Nguyễn Ái Quốc 10",
        "diaChiLapD": "",
        "longitude": 105.81903432329153,
        "latitude": 21.013851851538952,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 847,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82133431751724,
          21.002621146040614
        ]
      },
      "properties": {
        "tenDoiTuon": "16",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 9,
        "maXaPhuong": 349,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Trường Chinh",
        "tuDien": "CVNTS3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "CV NTS tủ 3",
        "diaChiLapD": "",
        "longitude": 105.82133431751724,
        "latitude": 21.002621146040614,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 848,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81837718206236,
          21.00179732320789
        ]
      },
      "properties": {
        "tenDoiTuon": "6-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 9,
        "maXaPhuong": 349,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Nguyễn Trãi",
        "tuDien": "CVNTS2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 10,
        "tuDien2": "CV NTS Tủ 2",
        "diaChiLapD": "",
        "longitude": 105.81837718206236,
        "latitude": 21.00179732320789,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 849,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81813578324878,
          21.001627049228663
        ]
      },
      "properties": {
        "tenDoiTuon": "6-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 9,
        "maXaPhuong": 349,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Nguyễn Trãi",
        "tuDien": "CVNTS2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 10,
        "tuDien2": "CV NTS Tủ 2",
        "diaChiLapD": "",
        "longitude": 105.81813578324878,
        "latitude": 21.001627049228663,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 850,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81787158566941,
          21.001449262955013
        ]
      },
      "properties": {
        "tenDoiTuon": "6-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 9,
        "maXaPhuong": 346,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Nguyễn Trãi",
        "tuDien": "CVNTS2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 10,
        "tuDien2": "CV NTS Tủ 2",
        "diaChiLapD": "",
        "longitude": 105.81787158566941,
        "latitude": 21.001449262955013,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 851,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81763957458296,
          21.001294012795785
        ]
      },
      "properties": {
        "tenDoiTuon": "NTS2 6-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 9,
        "maXaPhuong": 346,
        "loaiChaoCh": "Đường phố - đèn pha",
        "tenDuongPh": "Nguyễn Trãi",
        "tuDien": "CVNTS2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 14,
        "tuDien2": "CV NTS Tủ 2",
        "diaChiLapD": "",
        "longitude": 105.81763957458296,
        "latitude": 21.001294012795785,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 852,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82354546357335,
          21.005770849237383
        ]
      },
      "properties": {
        "tenDoiTuon": "31-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 205 Tây Sơn",
        "tuDien": "CVNTS2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 6,
        "tuDien2": "CV NTS Tủ 2",
        "diaChiLapD": "",
        "longitude": 105.82354546357335,
        "latitude": 21.005770849237383,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 853,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82329467703269,
          21.005927346717932
        ]
      },
      "properties": {
        "tenDoiTuon": "31-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 205 Tây Sơn",
        "tuDien": "CVNTS2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "CV NTS Tủ 2",
        "diaChiLapD": "",
        "longitude": 105.82329467703269,
        "latitude": 21.005927346717932,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 854,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82185969520799,
          21.003712579091516
        ]
      },
      "properties": {
        "tenDoiTuon": "22-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 317 Tây Sơn",
        "tuDien": "CVNTS2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "CV NTS Tủ 2",
        "diaChiLapD": "",
        "longitude": 105.82185969520799,
        "latitude": 21.003712579091516,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 855,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82174972464566,
          21.00345216332772
        ]
      },
      "properties": {
        "tenDoiTuon": "22-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 317 Tây Sơn",
        "tuDien": "CVNTS2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "CV NTS Tủ 2",
        "diaChiLapD": "",
        "longitude": 105.82174972464566,
        "latitude": 21.00345216332772,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 856,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8215834276848,
          21.003279387233263
        ]
      },
      "properties": {
        "tenDoiTuon": "22-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 317 Tây Sơn",
        "tuDien": "CVNTS2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "CV NTS Tủ 2",
        "diaChiLapD": "",
        "longitude": 105.8215834276848,
        "latitude": 21.003279387233263,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 857,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82136080433442,
          21.003076562867193
        ]
      },
      "properties": {
        "tenDoiTuon": "22-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 317 Tây Sơn",
        "tuDien": "CVNTS2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "CV NTS Tủ 2",
        "diaChiLapD": "",
        "longitude": 105.82136080433442,
        "latitude": 21.003076562867193,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 858,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82175777126889,
          21.003619931227878
        ]
      },
      "properties": {
        "tenDoiTuon": "22-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 317 Tây Sơn",
        "tuDien": "CVNTS2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "CV NTS Tủ 2",
        "diaChiLapD": "",
        "longitude": 105.82175777126889,
        "latitude": 21.003619931227878,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 859,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8221118228615,
          21.00389537065501
        ]
      },
      "properties": {
        "tenDoiTuon": "22-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 317 Tây Sơn",
        "tuDien": "CVNTS2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "CV NTS Tủ 2",
        "diaChiLapD": "",
        "longitude": 105.8221118228615,
        "latitude": 21.00389537065501,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 860,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82170110960114,
          21.003060599827123
        ]
      },
      "properties": {
        "tenDoiTuon": "6-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 612 Trường Trinh",
        "tuDien": "CVNTS3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "CV NTS tủ 3",
        "diaChiLapD": "",
        "longitude": 105.82170110960114,
        "latitude": 21.003060599827123,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 861,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82167294641086,
          21.00314198001572
        ]
      },
      "properties": {
        "tenDoiTuon": "6-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 612 Trường Trinh",
        "tuDien": "CVNTS3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "CV NTS tủ 3",
        "diaChiLapD": "",
        "longitude": 105.82167294641086,
        "latitude": 21.00314198001572,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 862,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81922006626299,
          21.003795523921436
        ]
      },
      "properties": {
        "tenDoiTuon": "35-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 58 Đường Láng",
        "tuDien": "CVNTS3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "CV NTS tủ 3",
        "diaChiLapD": "",
        "longitude": 105.81922006626299,
        "latitude": 21.003795523921436,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 863,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81867557783984,
          21.003197068118904
        ]
      },
      "properties": {
        "tenDoiTuon": "20-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 35 Đường Láng",
        "tuDien": "CVNTS3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "CV NTS tủ 3",
        "diaChiLapD": "",
        "longitude": 105.81867557783984,
        "latitude": 21.003197068118904,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 864,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81862193365805,
          21.003091899905176
        ]
      },
      "properties": {
        "tenDoiTuon": "20-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 35 Đường Láng",
        "tuDien": "CVNTS3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": "",
        "tuDien2": "CV NTS tủ 3",
        "diaChiLapD": "",
        "longitude": 105.81862193365805,
        "latitude": 21.003091899905176,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 865,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81888479014253,
          21.002999251656203
        ]
      },
      "properties": {
        "tenDoiTuon": "20-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 35 Đường Láng",
        "tuDien": "CVNTS3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "CV NTS tủ 3",
        "diaChiLapD": "",
        "longitude": 105.81888479014253,
        "latitude": 21.002999251656203,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 866,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82530581239125,
          21.01028641963031
        ]
      },
      "properties": {
        "tenDoiTuon": "Đ5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Tây Sơn",
        "tuDien": "CĐ2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "DC-05",
        "chieuCao": 3,
        "tuDien2": "CĐ2",
        "diaChiLapD": "",
        "longitude": 105.82530581239125,
        "latitude": 21.01028641963031,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 867,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82505368473774,
          21.009821948197605
        ]
      },
      "properties": {
        "tenDoiTuon": "Đ4/1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Tây Sơn",
        "tuDien": "CĐ2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "DC-05",
        "chieuCao": 3,
        "tuDien2": "CĐ2",
        "diaChiLapD": "",
        "longitude": 105.82505368473774,
        "latitude": 21.009821948197605,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 868,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82553648236485,
          21.010362788109145
        ]
      },
      "properties": {
        "tenDoiTuon": "Đ2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Tây Sơn",
        "tuDien": "CĐ2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "DC-05",
        "chieuCao": 3,
        "tuDien2": "CĐ2",
        "diaChiLapD": "",
        "longitude": 105.82553648236485,
        "latitude": 21.010362788109145,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 869,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8252240050102,
          21.010515524952055
        ]
      },
      "properties": {
        "tenDoiTuon": "Đ3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Tây Sơn",
        "tuDien": "CĐ2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "DC-05",
        "chieuCao": 3,
        "tuDien2": "CĐ2",
        "diaChiLapD": "",
        "longitude": 105.8252240050102,
        "latitude": 21.010515524952055,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 870,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82505636695446,
          21.010207547223665
        ]
      },
      "properties": {
        "tenDoiTuon": "Đ4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Tây Sơn",
        "tuDien": "CĐ2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "DC-05",
        "chieuCao": 3,
        "tuDien2": "CĐ2",
        "diaChiLapD": "",
        "longitude": 105.82505636695446,
        "latitude": 21.010207547223665,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 871,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82537554981947,
          21.010063573677968
        ]
      },
      "properties": {
        "tenDoiTuon": "Đ1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Tây Sơn",
        "tuDien": "CĐ2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "DC-05",
        "chieuCao": 3,
        "tuDien2": "CĐ2",
        "diaChiLapD": "",
        "longitude": 105.82537554981947,
        "latitude": 21.010063573677968,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 872,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82495444300635,
          21.01001599977995
        ]
      },
      "properties": {
        "tenDoiTuon": "Đ3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Tây Sơn",
        "tuDien": "CĐ2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "DC-05",
        "chieuCao": 3,
        "tuDien2": "CĐ2",
        "diaChiLapD": "",
        "longitude": 105.82495444300635,
        "latitude": 21.01001599977995,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 873,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82479887488546,
          21.009740571651378
        ]
      },
      "properties": {
        "tenDoiTuon": "Đ4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Tây Sơn",
        "tuDien": "CĐ2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "DC-05",
        "chieuCao": 3,
        "tuDien2": "CĐ2",
        "diaChiLapD": "",
        "longitude": 105.82479887488546,
        "latitude": 21.009740571651378,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 874,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82512208107109,
          21.009595345706362
        ]
      },
      "properties": {
        "tenDoiTuon": "Đ1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Tây Sơn",
        "tuDien": "CĐ2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "DC-05",
        "chieuCao": 3,
        "tuDien2": "CĐ2",
        "diaChiLapD": "",
        "longitude": 105.82512208107109,
        "latitude": 21.009595345706362,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 875,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82526289704042,
          21.009863262426443
        ]
      },
      "properties": {
        "tenDoiTuon": "Đ2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Tây Sơn",
        "tuDien": "CĐ2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "DC-05",
        "chieuCao": 3,
        "tuDien2": "CĐ2",
        "diaChiLapD": "",
        "longitude": 105.82526289704042,
        "latitude": 21.009863262426443,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 876,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82495980742186,
          21.009570306736148
        ]
      },
      "properties": {
        "tenDoiTuon": "Đ5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Tây Sơn",
        "tuDien": "CĐ2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "DC-05",
        "chieuCao": 3,
        "tuDien2": "CĐ2",
        "diaChiLapD": "",
        "longitude": 105.82495980742186,
        "latitude": 21.009570306736148,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 877,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8240223754028,
          21.007840103707537
        ]
      },
      "properties": {
        "tenDoiTuon": "Đ6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Tây Sơn",
        "tuDien": "ATH",
        "chuDauTu": "SoXayDung",
        "loaiCot": "DC-05",
        "chieuCao": 3,
        "tuDien2": "ATH",
        "diaChiLapD": "",
        "longitude": 105.8240223754028,
        "latitude": 21.007840103707537,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 878,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82408674841555,
          21.007967803893965
        ]
      },
      "properties": {
        "tenDoiTuon": "Đ7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Tây Sơn",
        "tuDien": "ATH",
        "chuDauTu": "SoXayDung",
        "loaiCot": "DC-05",
        "chieuCao": 3,
        "tuDien2": "ATH",
        "diaChiLapD": "",
        "longitude": 105.82408674841555,
        "latitude": 21.007967803893965,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 879,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8241511214373,
          21.008093000048607
        ]
      },
      "properties": {
        "tenDoiTuon": "Đ8",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Tây Sơn",
        "tuDien": "ATH",
        "chuDauTu": "SoXayDung",
        "loaiCot": "DC-05",
        "chieuCao": 3,
        "tuDien2": "ATH",
        "diaChiLapD": "",
        "longitude": 105.8241511214373,
        "latitude": 21.008093000048607,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 880,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8242128122423,
          21.008225707857605
        ]
      },
      "properties": {
        "tenDoiTuon": "Đ9",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Tây Sơn",
        "tuDien": "ATH",
        "chuDauTu": "SoXayDung",
        "loaiCot": "DC-05",
        "chieuCao": 3,
        "tuDien2": "ATH",
        "diaChiLapD": "",
        "longitude": 105.8242128122423,
        "latitude": 21.008225707857605,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 881,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82428523188727,
          21.008368431218003
        ]
      },
      "properties": {
        "tenDoiTuon": "Đ10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Tây Sơn",
        "tuDien": "ATH",
        "chuDauTu": "SoXayDung",
        "loaiCot": "DC-05",
        "chieuCao": 3,
        "tuDien2": "ATH",
        "diaChiLapD": "",
        "longitude": 105.82428523188727,
        "latitude": 21.008368431218003,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 882,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82435228710777,
          21.00851616227281
        ]
      },
      "properties": {
        "tenDoiTuon": "Đ11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Tây Sơn",
        "tuDien": "ATH",
        "chuDauTu": "SoXayDung",
        "loaiCot": "DC-05",
        "chieuCao": 3,
        "tuDien2": "ATH",
        "diaChiLapD": "",
        "longitude": 105.82435228710777,
        "latitude": 21.00851616227281,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 883,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8244166601295,
          21.008648869705404
        ]
      },
      "properties": {
        "tenDoiTuon": "Đ12",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Tây Sơn",
        "tuDien": "ATH",
        "chuDauTu": "SoXayDung",
        "loaiCot": "DC-05",
        "chieuCao": 3,
        "tuDien2": "ATH",
        "diaChiLapD": "",
        "longitude": 105.8244166601295,
        "latitude": 21.008648869705404,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 884,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82417526131597,
          21.00782007229622
        ]
      },
      "properties": {
        "tenDoiTuon": "Đ1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Tây Sơn",
        "tuDien": "ATH",
        "chuDauTu": "SoXayDung",
        "loaiCot": "DC-05",
        "chieuCao": 3,
        "tuDien2": "ATH",
        "diaChiLapD": "",
        "longitude": 105.82417526131597,
        "latitude": 21.00782007229622,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 885,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82425572758416,
          21.007980323514094
        ]
      },
      "properties": {
        "tenDoiTuon": "Đ2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Tây Sơn",
        "tuDien": "ATH",
        "chuDauTu": "SoXayDung",
        "loaiCot": "DC-05",
        "chieuCao": 3,
        "tuDien2": "ATH",
        "diaChiLapD": "",
        "longitude": 105.82425572758416,
        "latitude": 21.007980323514094,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 886,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82432010060589,
          21.008123047110058
        ]
      },
      "properties": {
        "tenDoiTuon": "Đ3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Tây Sơn",
        "tuDien": "ATH",
        "chuDauTu": "SoXayDung",
        "loaiCot": "DC-05",
        "chieuCao": 3,
        "tuDien2": "ATH",
        "diaChiLapD": "",
        "longitude": 105.82432010060589,
        "latitude": 21.008123047110058,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 887,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82439252024187,
          21.008278290163773
        ]
      },
      "properties": {
        "tenDoiTuon": "Đ4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Tây Sơn",
        "tuDien": "ATH",
        "chuDauTu": "SoXayDung",
        "loaiCot": "DC-05",
        "chieuCao": 3,
        "tuDien2": "ATH",
        "diaChiLapD": "",
        "longitude": 105.82439252024187,
        "latitude": 21.008278290163773,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 888,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82445957547135,
          21.00843353305677
        ]
      },
      "properties": {
        "tenDoiTuon": "Đ5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Tây Sơn",
        "tuDien": "CĐ2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "DC-05",
        "chieuCao": 3,
        "tuDien2": "CĐ2",
        "diaChiLapD": "",
        "longitude": 105.82445957547135,
        "latitude": 21.00843353305677,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 889,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82391776924695,
          21.007937756801272
        ]
      },
      "properties": {
        "tenDoiTuon": "Đ13",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Tây Sơn",
        "tuDien": "ATH",
        "chuDauTu": "SoXayDung",
        "loaiCot": "DC-05",
        "chieuCao": 3,
        "tuDien2": "ATH",
        "diaChiLapD": "",
        "longitude": 105.82391776924695,
        "latitude": 21.007937756801272,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 890,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.823968731221,
          21.00808548828167
        ]
      },
      "properties": {
        "tenDoiTuon": "Đ14",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Tây Sơn",
        "tuDien": "ATH",
        "chuDauTu": "SoXayDung",
        "loaiCot": "DC-05",
        "chieuCao": 3,
        "tuDien2": "ATH",
        "diaChiLapD": "",
        "longitude": 105.823968731221,
        "latitude": 21.00808548828167,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 891,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82403402038551,
          21.008205285299614
        ]
      },
      "properties": {
        "tenDoiTuon": "Đ15",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Tây Sơn",
        "tuDien": "ATH",
        "chuDauTu": "SoXayDung",
        "loaiCot": "DC-05",
        "chieuCao": 3,
        "tuDien2": "ATH",
        "diaChiLapD": "",
        "longitude": 105.82403402038551,
        "latitude": 21.008205285299614,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 892,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82411625271867,
          21.008360919465773
        ]
      },
      "properties": {
        "tenDoiTuon": "Đ16",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Tây Sơn",
        "tuDien": "ATH",
        "chuDauTu": "SoXayDung",
        "loaiCot": "DC-05",
        "chieuCao": 3,
        "tuDien2": "ATH",
        "diaChiLapD": "",
        "longitude": 105.82411625271867,
        "latitude": 21.008360919465773,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 893,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82418599014692,
          21.008491123121463
        ]
      },
      "properties": {
        "tenDoiTuon": "Đ17",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Tây Sơn",
        "tuDien": "ATH",
        "chuDauTu": "SoXayDung",
        "loaiCot": "DC-05",
        "chieuCao": 3,
        "tuDien2": "ATH",
        "diaChiLapD": "",
        "longitude": 105.82418599014692,
        "latitude": 21.008491123121463,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 894,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82423636689525,
          21.008584059501935
        ]
      },
      "properties": {
        "tenDoiTuon": "Đ18",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Tây Sơn",
        "tuDien": "ATH",
        "chuDauTu": "SoXayDung",
        "loaiCot": "DC-05",
        "chieuCao": 3,
        "tuDien2": "ATH",
        "diaChiLapD": "",
        "longitude": 105.82423636689525,
        "latitude": 21.008584059501935,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 895,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82378365880597,
          21.00766733269457
        ]
      },
      "properties": {
        "tenDoiTuon": "Đ9",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Tây Sơn",
        "tuDien": "ATH",
        "chuDauTu": "SoXayDung",
        "loaiCot": "DC-05",
        "chieuCao": 3,
        "tuDien2": "ATH",
        "diaChiLapD": "",
        "longitude": 105.82378365880597,
        "latitude": 21.00766733269457,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 896,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8239325214075,
          21.00764604929503
        ]
      },
      "properties": {
        "tenDoiTuon": "Đ4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Tây Sơn",
        "tuDien": "ATH",
        "chuDauTu": "SoXayDung",
        "loaiCot": "DC-05",
        "chieuCao": 3,
        "tuDien2": "ATH",
        "diaChiLapD": "",
        "longitude": 105.8239325214075,
        "latitude": 21.00764604929503,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 897,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82404249196985,
          21.007552151907838
        ]
      },
      "properties": {
        "tenDoiTuon": "Đ1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Tây Sơn",
        "tuDien": "ATH",
        "chuDauTu": "SoXayDung",
        "loaiCot": "DC-05",
        "chieuCao": 3,
        "tuDien2": "ATH",
        "diaChiLapD": "",
        "longitude": 105.82404249196985,
        "latitude": 21.007552151907838,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 898,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82384132629936,
          21.007473278056505
        ]
      },
      "properties": {
        "tenDoiTuon": "Đ5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - đèn chùm",
        "tenDuongPh": "Phố Tây Sơn",
        "tuDien": "ATH",
        "chuDauTu": "SoXayDung",
        "loaiCot": "DC-05",
        "chieuCao": 3,
        "tuDien2": "ATH",
        "diaChiLapD": "",
        "longitude": 105.82384132629936,
        "latitude": 21.007473278056505,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 899,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82376305599611,
          21.007314664757843
        ]
      },
      "properties": {
        "tenDoiTuon": "Đ6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Tây Sơn",
        "tuDien": "ATH",
        "chuDauTu": "SoXayDung",
        "loaiCot": "DC-05",
        "chieuCao": 3,
        "tuDien2": "ATH",
        "diaChiLapD": "",
        "longitude": 105.82376305599611,
        "latitude": 21.007314664757843,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 900,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82367234713078,
          21.00713900270146
        ]
      },
      "properties": {
        "tenDoiTuon": "Đ7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Tây Sơn",
        "tuDien": "ATH",
        "chuDauTu": "SoXayDung",
        "loaiCot": "DC-05",
        "chieuCao": 3,
        "tuDien2": "ATH",
        "diaChiLapD": "",
        "longitude": 105.82367234713078,
        "latitude": 21.00713900270146,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 901,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82354896551175,
          21.006956215111387
        ]
      },
      "properties": {
        "tenDoiTuon": "Đ8",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Tây Sơn",
        "tuDien": "ATH",
        "chuDauTu": "SoXayDung",
        "loaiCot": "DC-05",
        "chieuCao": 3,
        "tuDien2": "ATH",
        "diaChiLapD": "",
        "longitude": 105.82354896551175,
        "latitude": 21.006956215111387,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 902,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82368844038618,
          21.007490805582623
        ]
      },
      "properties": {
        "tenDoiTuon": "Đ10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Tây Sơn",
        "tuDien": "ATH",
        "chuDauTu": "SoXayDung",
        "loaiCot": "DC-05",
        "chieuCao": 3,
        "tuDien2": "ATH",
        "diaChiLapD": "",
        "longitude": 105.82368844038618,
        "latitude": 21.007490805582623,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 903,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83246626492384,
          21.025585189134294
        ]
      },
      "properties": {
        "tenDoiTuon": "18",
        "ngayLapDat": "6/1/2007 12:00:00 AM",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Phan Văn Trị",
        "tuDien": "PVT",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": "",
        "tuDien2": "Phan Văn Trị",
        "diaChiLapD": "",
        "longitude": 105.83246626492384,
        "latitude": 21.025585189134294,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 904,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83223783242303,
          21.025674133363502
        ]
      },
      "properties": {
        "tenDoiTuon": "19",
        "ngayLapDat": "1/6/2007 12:00:00 AM",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Phan Văn Trị",
        "tuDien": "PVT",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": "",
        "tuDien2": "Phan Văn Trị",
        "diaChiLapD": "",
        "longitude": 105.83223783242303,
        "latitude": 21.025674133363502,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 905,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83322119732378,
          21.025427838867124
        ]
      },
      "properties": {
        "tenDoiTuon": "01",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Tôn Đức Thắng",
        "tuDien": "PVT",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Phan Văn Trị",
        "diaChiLapD": "",
        "longitude": 105.83322119732378,
        "latitude": 21.025427838867124,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 906,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83336297122831,
          21.02573578516259
        ]
      },
      "properties": {
        "tenDoiTuon": "02",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Tôn Đức Thắng",
        "tuDien": "PVT",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Phan Văn Trị",
        "diaChiLapD": "",
        "longitude": 105.83336297122831,
        "latitude": 21.02573578516259,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 907,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83350474513283,
          21.026043730821815
        ]
      },
      "properties": {
        "tenDoiTuon": "03",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Tôn Đức Thắng",
        "tuDien": "PVT",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Phan Văn Trị",
        "diaChiLapD": "",
        "longitude": 105.83350474513283,
        "latitude": 21.026043730821815,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 908,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83364651903736,
          21.026351675844907
        ]
      },
      "properties": {
        "tenDoiTuon": "04",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Tôn Đức Thắng",
        "tuDien": "PVT",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Phan Văn Trị",
        "diaChiLapD": "",
        "longitude": 105.83364651903736,
        "latitude": 21.026351675844907,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 909,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8338146360517,
          21.026720332766764
        ]
      },
      "properties": {
        "tenDoiTuon": "05",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Tôn Đức Thắng",
        "tuDien": "PVT",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Phan Văn Trị",
        "diaChiLapD": "",
        "longitude": 105.8338146360517,
        "latitude": 21.026720332766764,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 910,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8339300668464,
          21.026967563982414
        ]
      },
      "properties": {
        "tenDoiTuon": "06",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Tôn Đức Thắng",
        "tuDien": "PVT",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Phan Văn Trị",
        "diaChiLapD": "",
        "longitude": 105.8339300668464,
        "latitude": 21.026967563982414,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 911,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83407184075092,
          21.027275507096853
        ]
      },
      "properties": {
        "tenDoiTuon": "07",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Tôn Đức Thắng",
        "tuDien": "PVT",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Phan Văn Trị",
        "diaChiLapD": "",
        "longitude": 105.83407184075092,
        "latitude": 21.027275507096853,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 912,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83421361465547,
          21.027583449575054
        ]
      },
      "properties": {
        "tenDoiTuon": "08",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Tôn Đức Thắng",
        "tuDien": "PVT",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Phan Văn Trị",
        "diaChiLapD": "",
        "longitude": 105.83421361465547,
        "latitude": 21.027583449575054,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 913,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83402986086448,
          21.027648543025517
        ]
      },
      "properties": {
        "tenDoiTuon": "09",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Tôn Đức Thắng",
        "tuDien": "PVT",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Phan Văn Trị",
        "diaChiLapD": "",
        "longitude": 105.83402986086448,
        "latitude": 21.027648543025517,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 914,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83374631305541,
          21.027032657701863
        ]
      },
      "properties": {
        "tenDoiTuon": "11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Tôn Đức Thắng",
        "tuDien": "PVT",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Phan Văn Trị",
        "diaChiLapD": "",
        "longitude": 105.83374631305541,
        "latitude": 21.027032657701863,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 915,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83388808695993,
          21.027340600681775
        ]
      },
      "properties": {
        "tenDoiTuon": "10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Tôn Đức Thắng",
        "tuDien": "PVT",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Phan Văn Trị",
        "diaChiLapD": "",
        "longitude": 105.83388808695993,
        "latitude": 21.027340600681775,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 916,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83346276524638,
          21.026416769833315
        ]
      },
      "properties": {
        "tenDoiTuon": "13",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Tôn Đức Thắng",
        "tuDien": "PVT",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Phan Văn Trị",
        "diaChiLapD": "",
        "longitude": 105.83346276524638,
        "latitude": 21.026416769833315,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 917,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83360453915088,
          21.02672471408567
        ]
      },
      "properties": {
        "tenDoiTuon": "12",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Tôn Đức Thắng",
        "tuDien": "PVT",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Phan Văn Trị",
        "diaChiLapD": "",
        "longitude": 105.83360453915088,
        "latitude": 21.02672471408567,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 918,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83317921743733,
          21.025800879419933
        ]
      },
      "properties": {
        "tenDoiTuon": "15",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Tôn Đức Thắng",
        "tuDien": "PVT",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Phan Văn Trị",
        "diaChiLapD": "",
        "longitude": 105.83317921743733,
        "latitude": 21.025800879419933,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 919,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83332099134185,
          21.02610882494471
        ]
      },
      "properties": {
        "tenDoiTuon": "14",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Tôn Đức Thắng",
        "tuDien": "PVT",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Phan Văn Trị",
        "diaChiLapD": "",
        "longitude": 105.83332099134185,
        "latitude": 21.02610882494471,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 920,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8330374435328,
          21.025492933258953
        ]
      },
      "properties": {
        "tenDoiTuon": "16",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Tôn Đức Thắng",
        "tuDien": "PVT",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Phan Văn Trị",
        "diaChiLapD": "",
        "longitude": 105.8330374435328,
        "latitude": 21.025492933258953,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 921,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83347433076702,
          21.025296085228348
        ]
      },
      "properties": {
        "tenDoiTuon": "1-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 83 Tôn Đức Thắng",
        "tuDien": "PVT",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": 3,
        "tuDien2": "Phan Văn Trị",
        "diaChiLapD": "",
        "longitude": 105.83347433076702,
        "latitude": 21.025296085228348,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 922,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8332758473043,
          21.025321121558218
        ]
      },
      "properties": {
        "tenDoiTuon": "1-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 83 Tôn Đức Thắng",
        "tuDien": "PVT",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": 5,
        "tuDien2": "Phan Văn Trị",
        "diaChiLapD": "",
        "longitude": 105.8332758473043,
        "latitude": 21.025321121558218,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 923,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83348472432876,
          21.02570386392932
        ]
      },
      "properties": {
        "tenDoiTuon": "2-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 69 Tôn Đức Thắng",
        "tuDien": "PVT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Phan Văn Trị",
        "diaChiLapD": "",
        "longitude": 105.83348472432876,
        "latitude": 21.02570386392932,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 924,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83363492803417,
          21.02570136030325
        ]
      },
      "properties": {
        "tenDoiTuon": "2-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 69 Tôn Đức Thắng",
        "tuDien": "PVT",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 4,
        "tuDien2": "Phan Văn Trị",
        "diaChiLapD": "",
        "longitude": 105.83363492803417,
        "latitude": 21.02570136030325,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 925,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8337583096442,
          21.025626251491698
        ]
      },
      "properties": {
        "tenDoiTuon": "2-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 69 Tôn Đức Thắng",
        "tuDien": "PVT",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": 3,
        "tuDien2": "Phan Văn Trị",
        "diaChiLapD": "",
        "longitude": 105.8337583096442,
        "latitude": 21.025626251491698,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 926,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83405637013449,
          21.027809711780737
        ]
      },
      "properties": {
        "tenDoiTuon": "21",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Cát Linh",
        "tuDien": "PVT",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Phan Văn Trị",
        "diaChiLapD": "",
        "longitude": 105.83405637013449,
        "latitude": 21.027809711780737,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 927,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83181136118544,
          21.025721702266413
        ]
      },
      "properties": {
        "tenDoiTuon": "20",
        "ngayLapDat": "6/1/2007 12:00:00 AM",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Phan Văn Trị",
        "tuDien": "PVT",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": "",
        "tuDien2": "Phan Văn Trị",
        "diaChiLapD": "",
        "longitude": 105.83181136118544,
        "latitude": 21.025721702266413,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 928,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83163433539362,
          21.02583686902081
        ]
      },
      "properties": {
        "tenDoiTuon": "20-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 6+18+62 Phan Văn Trị",
        "tuDien": "PVT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "Phan Văn Trị",
        "diaChiLapD": "",
        "longitude": 105.83163433539362,
        "latitude": 21.02583686902081,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 929,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83199375140174,
          21.025751745776176
        ]
      },
      "properties": {
        "tenDoiTuon": "20-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 6+18+62 Phan Văn Trị",
        "tuDien": "PVT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "Phan Văn Trị",
        "diaChiLapD": "",
        "longitude": 105.83199375140174,
        "latitude": 21.025751745776176,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 930,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83261065946985,
          21.025666622482937
        ]
      },
      "properties": {
        "tenDoiTuon": "18-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 6+18+62 Phan Văn Trị",
        "tuDien": "PVT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 4,
        "tuDien2": "Phan Văn Trị",
        "diaChiLapD": "",
        "longitude": 105.83261065946985,
        "latitude": 21.025666622482937,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 931,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.832763545383,
          21.02561654993416
        ]
      },
      "properties": {
        "tenDoiTuon": "17-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 6+18+62 Phan Văn Trị",
        "tuDien": "PVT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 5,
        "tuDien2": "Phan Văn Trị",
        "diaChiLapD": "",
        "longitude": 105.832763545383,
        "latitude": 21.02561654993416,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 932,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83393265322827,
          21.025916672018912
        ]
      },
      "properties": {
        "tenDoiTuon": "3-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 61 Tôn Đức Thắng",
        "tuDien": "PVT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Phan Văn Trị",
        "diaChiLapD": "",
        "longitude": 105.83393265322827,
        "latitude": 21.025916672018912,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 933,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83369963632514,
          21.0259870863938
        ]
      },
      "properties": {
        "tenDoiTuon": "3-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 61 Tôn Đức Thắng",
        "tuDien": "PVT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Phan Văn Trị",
        "diaChiLapD": "",
        "longitude": 105.83369963632514,
        "latitude": 21.0259870863938,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 934,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83266200671146,
          21.025504702996077
        ]
      },
      "properties": {
        "tenDoiTuon": "17",
        "ngayLapDat": "6/1/2007 12:00:00 AM",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Phan Văn Trị",
        "tuDien": "PVT",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": "",
        "tuDien2": "Phan Văn Trị",
        "diaChiLapD": "",
        "longitude": 105.83266200671146,
        "latitude": 21.025504702996077,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 935,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83388169126322,
          21.026757886901212
        ]
      },
      "properties": {
        "tenDoiTuon": "22",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hồ Giám",
        "tuDien": "PVT",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Phan Văn Trị",
        "diaChiLapD": "",
        "longitude": 105.83388169126322,
        "latitude": 21.026757886901212,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 936,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83409760908975,
          21.02667777140282
        ]
      },
      "properties": {
        "tenDoiTuon": "23",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hồ Giám",
        "tuDien": "PVT",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Phan Văn Trị",
        "diaChiLapD": "",
        "longitude": 105.83409760908975,
        "latitude": 21.02667777140282,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 937,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83431352691629,
          21.026597655861323
        ]
      },
      "properties": {
        "tenDoiTuon": "24",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hồ Giám",
        "tuDien": "PVT",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Phan Văn Trị",
        "diaChiLapD": "",
        "longitude": 105.83431352691629,
        "latitude": 21.026597655861323,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 938,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83432425575624,
          21.02627218603023
        ]
      },
      "properties": {
        "tenDoiTuon": "26",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hồ Giám",
        "tuDien": "PVT",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "Phan Văn Trị",
        "diaChiLapD": "",
        "longitude": 105.83432425575624,
        "latitude": 21.02627218603023,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 939,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83422233180815,
          21.026026831379884
        ]
      },
      "properties": {
        "tenDoiTuon": "27",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hồ Giám",
        "tuDien": "PVT",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Phan Văn Trị",
        "diaChiLapD": "",
        "longitude": 105.83422233180815,
        "latitude": 21.026026831379884,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 940,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83422257922065,
          21.02683649499514
        ]
      },
      "properties": {
        "tenDoiTuon": "23-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 7+15+30 Hồ Giám",
        "tuDien": "PVT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "Phan Văn Trị",
        "diaChiLapD": "",
        "longitude": 105.83422257922065,
        "latitude": 21.02683649499514,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 941,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83444763736628,
          21.026827987927536
        ]
      },
      "properties": {
        "tenDoiTuon": "24-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 7+15+30 Hồ Giám",
        "tuDien": "PVT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Phan Văn Trị",
        "diaChiLapD": "",
        "longitude": 105.83444763736628,
        "latitude": 21.026827987927536,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 942,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83459247665621,
          21.026387352359333
        ]
      },
      "properties": {
        "tenDoiTuon": "25-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 7+15+30 Hồ Giám",
        "tuDien": "PVT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "Phan Văn Trị",
        "diaChiLapD": "",
        "longitude": 105.83459247665621,
        "latitude": 21.026387352359333,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 943,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83442081527987,
          21.02647497885409
        ]
      },
      "properties": {
        "tenDoiTuon": "25",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 7+15+30 Hồ Giám",
        "tuDien": "PVT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Phan Văn Trị",
        "diaChiLapD": "",
        "longitude": 105.83442081527987,
        "latitude": 21.02647497885409,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 944,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83435107785162,
          21.027113398907865
        ]
      },
      "properties": {
        "tenDoiTuon": "23-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 7+15+30 Hồ Giám",
        "tuDien": "PVT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "Phan Văn Trị",
        "diaChiLapD": "",
        "longitude": 105.83435107785162,
        "latitude": 21.027113398907865,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 945,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83377440291761,
          21.02616453046725
        ]
      },
      "properties": {
        "tenDoiTuon": "28",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Thông Phong",
        "tuDien": "PVT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Phan Văn Trị",
        "diaChiLapD": "",
        "longitude": 105.83377440291761,
        "latitude": 21.02616453046725,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 946,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83401580172219,
          21.026069392929738
        ]
      },
      "properties": {
        "tenDoiTuon": "29",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Thông Phong",
        "tuDien": "PVT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Phan Văn Trị",
        "diaChiLapD": "",
        "longitude": 105.83401580172219,
        "latitude": 21.026069392929738,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 947,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83427329379118,
          21.02592919013058
        ]
      },
      "properties": {
        "tenDoiTuon": "30",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Thông Phong",
        "tuDien": "PVT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Phan Văn Trị",
        "diaChiLapD": "",
        "longitude": 105.83427329379118,
        "latitude": 21.02592919013058,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 948,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83463539200703,
          21.025844066938685
        ]
      },
      "properties": {
        "tenDoiTuon": "31",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Thông Phong",
        "tuDien": "PVT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Phan Văn Trị",
        "diaChiLapD": "",
        "longitude": 105.83463539200703,
        "latitude": 21.025844066938685,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 949,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83569754677593,
          21.024977810514184
        ]
      },
      "properties": {
        "tenDoiTuon": "37",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Thông Phong",
        "tuDien": "PVT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Phan Văn Trị",
        "diaChiLapD": "",
        "longitude": 105.83569754677593,
        "latitude": 21.024977810514184,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 950,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83502967673375,
          21.025628755118138
        ]
      },
      "properties": {
        "tenDoiTuon": "32",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Thông Phong",
        "tuDien": "PVT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Phan Văn Trị",
        "diaChiLapD": "",
        "longitude": 105.83502967673375,
        "latitude": 21.025628755118138,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 951,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83526571112283,
          21.025486048274907
        ]
      },
      "properties": {
        "tenDoiTuon": "33",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Thông Phong",
        "tuDien": "PVT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Phan Văn Trị",
        "diaChiLapD": "",
        "longitude": 105.83526571112283,
        "latitude": 21.025486048274907,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 952,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83550710993642,
          21.02537588851343
        ]
      },
      "properties": {
        "tenDoiTuon": "34",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Thông Phong",
        "tuDien": "PVT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Phan Văn Trị",
        "diaChiLapD": "",
        "longitude": 105.83550710993642,
        "latitude": 21.02537588851343,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 953,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8357029112004,
          21.025305786804203
        ]
      },
      "properties": {
        "tenDoiTuon": "35",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Thông Phong",
        "tuDien": "PVT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Phan Văn Trị",
        "diaChiLapD": "",
        "longitude": 105.8357029112004,
        "latitude": 21.025305786804203,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 954,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83579678851629,
          21.025208145083454
        ]
      },
      "properties": {
        "tenDoiTuon": "36",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Thông Phong",
        "tuDien": "PVT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Phan Văn Trị",
        "diaChiLapD": "",
        "longitude": 105.83579678851629,
        "latitude": 21.025208145083454,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 955,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83452273922796,
          21.02565879864749
        ]
      },
      "properties": {
        "tenDoiTuon": "30-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 30 Thông Phong",
        "tuDien": "PVT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Phan Văn Trị",
        "diaChiLapD": "",
        "longitude": 105.83452273922796,
        "latitude": 21.02565879864749,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 956,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83442886192107,
          21.02546101197271
        ]
      },
      "properties": {
        "tenDoiTuon": "30-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 30 Thông Phong",
        "tuDien": "PVT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Phan Văn Trị",
        "diaChiLapD": "",
        "longitude": 105.83442886192107,
        "latitude": 21.02546101197271,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 957,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83461661655285,
          21.025944211864896
        ]
      },
      "properties": {
        "tenDoiTuon": "31-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 23+25 Thông Phong",
        "tuDien": "PVT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Phan Văn Trị",
        "diaChiLapD": "",
        "longitude": 105.83461661655285,
        "latitude": 21.025944211864896,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 958,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83485533315866,
          21.026119465324705
        ]
      },
      "properties": {
        "tenDoiTuon": "31-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 23+25 Thông Phong",
        "tuDien": "PVT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": "",
        "tuDien2": "Phan Văn Trị",
        "diaChiLapD": "",
        "longitude": 105.83485533315866,
        "latitude": 21.026119465324705,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 959,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8348177822323,
          21.025914168393925
        ]
      },
      "properties": {
        "tenDoiTuon": "31-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 23+25 Thông Phong",
        "tuDien": "PVT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Phan Văn Trị",
        "diaChiLapD": "",
        "longitude": 105.8348177822323,
        "latitude": 21.025914168393925,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 960,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83493311721911,
          21.025926686507486
        ]
      },
      "properties": {
        "tenDoiTuon": "31-7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 23+25 Thông Phong",
        "tuDien": "PVT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": "",
        "tuDien2": "Phan Văn Trị",
        "diaChiLapD": "",
        "longitude": 105.83493311721911,
        "latitude": 21.025926686507486,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 961,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83472122270867,
          21.026154515992108
        ]
      },
      "properties": {
        "tenDoiTuon": "31-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 23+25 Thông Phong",
        "tuDien": "PVT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 5,
        "tuDien2": "Phan Văn Trị",
        "diaChiLapD": "",
        "longitude": 105.83472122270867,
        "latitude": 21.026154515992108,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 962,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83472122270867,
          21.026086918269517
        ]
      },
      "properties": {
        "tenDoiTuon": "31-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 23+25 Thông Phong",
        "tuDien": "PVT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Phan Văn Trị",
        "diaChiLapD": "",
        "longitude": 105.83472122270867,
        "latitude": 21.026086918269517,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 963,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83470512945324,
          21.025916672017235
        ]
      },
      "properties": {
        "tenDoiTuon": "31-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 23+25 Thông Phong",
        "tuDien": "PVT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Phan Văn Trị",
        "diaChiLapD": "",
        "longitude": 105.83470512945324,
        "latitude": 21.025916672017235,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 964,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83465953190365,
          21.025576178928517
        ]
      },
      "properties": {
        "tenDoiTuon": "31-8",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 42 Thông Phong",
        "tuDien": "PVT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Phan Văn Trị",
        "diaChiLapD": "",
        "longitude": 105.83465953190365,
        "latitude": 21.025576178928517,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 965,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83472122270867,
          21.02534584492793
        ]
      },
      "properties": {
        "tenDoiTuon": "31-11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 42 Thông Phong",
        "tuDien": "PVT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Phan Văn Trị",
        "diaChiLapD": "",
        "longitude": 105.83472122270867,
        "latitude": 21.02534584492793,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 966,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83462466318504,
          21.025385903040053
        ]
      },
      "properties": {
        "tenDoiTuon": "31-9",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 42 Thông Phong",
        "tuDien": "PVT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Phan Văn Trị",
        "diaChiLapD": "",
        "longitude": 105.83462466318504,
        "latitude": 21.025385903040053,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 967,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8346622141114,
          21.025100488752788
        ]
      },
      "properties": {
        "tenDoiTuon": "31-12",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 42 Thông Phong",
        "tuDien": "PVT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "Phan Văn Trị",
        "diaChiLapD": "",
        "longitude": 105.8346622141114,
        "latitude": 21.025100488752788,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 968,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8345066459905,
          21.025395917566794
        ]
      },
      "properties": {
        "tenDoiTuon": "31-10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 42 Thông Phong",
        "tuDien": "PVT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 5,
        "tuDien2": "Phan Văn Trị",
        "diaChiLapD": "",
        "longitude": 105.8345066459905,
        "latitude": 21.025395917566794,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 969,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83446909506414,
          21.025107999661877
        ]
      },
      "properties": {
        "tenDoiTuon": "31-14",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 42 Thông Phong",
        "tuDien": "PVT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "Phan Văn Trị",
        "diaChiLapD": "",
        "longitude": 105.83446909506414,
        "latitude": 21.025107999661877,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 970,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83460320551413,
          21.024982817790512
        ]
      },
      "properties": {
        "tenDoiTuon": "31-13",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 42 Thông Phong",
        "tuDien": "PVT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "Phan Văn Trị",
        "diaChiLapD": "",
        "longitude": 105.83460320551413,
        "latitude": 21.024982817790512,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 971,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83505582824574,
          21.025512962297565
        ]
      },
      "properties": {
        "tenDoiTuon": "38",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 66 Thông Phong",
        "tuDien": "PVT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Phan Văn Trị",
        "diaChiLapD": "",
        "longitude": 105.83505582824574,
        "latitude": 21.025512962297565,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 972,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83495658650541,
          21.025250080960546
        ]
      },
      "properties": {
        "tenDoiTuon": "39",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 66 Thông Phong",
        "tuDien": "PVT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Phan Văn Trị",
        "diaChiLapD": "",
        "longitude": 105.83495658650541,
        "latitude": 21.025250080960546,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 973,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83488148465271,
          21.025044782832378
        ]
      },
      "properties": {
        "tenDoiTuon": "40",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 66 Thông Phong",
        "tuDien": "PVT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Phan Văn Trị",
        "diaChiLapD": "",
        "longitude": 105.83488148465271,
        "latitude": 21.025044782832378,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 974,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83507728591667,
          21.0249621627739
        ]
      },
      "properties": {
        "tenDoiTuon": "41",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 66 Thông Phong",
        "tuDien": "PVT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Phan Văn Trị",
        "diaChiLapD": "",
        "longitude": 105.83507728591667,
        "latitude": 21.0249621627739,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 975,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83528918042711,
          21.02488454995013
        ]
      },
      "properties": {
        "tenDoiTuon": "42",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 66 Thông Phong",
        "tuDien": "PVT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Phan Văn Trị",
        "diaChiLapD": "",
        "longitude": 105.83528918042711,
        "latitude": 21.02488454995013,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 976,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83513093009847,
          21.025167461015855
        ]
      },
      "properties": {
        "tenDoiTuon": "43",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 66 Thông Phong",
        "tuDien": "PVT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Phan Văn Trị",
        "diaChiLapD": "",
        "longitude": 105.83513093009847,
        "latitude": 21.025167461015855,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 977,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8353294135612,
          21.02508734466262
        ]
      },
      "properties": {
        "tenDoiTuon": "44",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 66 Thông Phong",
        "tuDien": "PVT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Phan Văn Trị",
        "diaChiLapD": "",
        "longitude": 105.8353294135612,
        "latitude": 21.02508734466262,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 978,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83488148465271,
          21.02540530618704
        ]
      },
      "properties": {
        "tenDoiTuon": "45",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 64 Thông Phong",
        "tuDien": "PVT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 5,
        "tuDien2": "Phan Văn Trị",
        "diaChiLapD": "",
        "longitude": 105.83488148465271,
        "latitude": 21.02540530618704,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 979,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83486539140624,
          21.025237562790153
        ]
      },
      "properties": {
        "tenDoiTuon": "46",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 64 Thông Phong",
        "tuDien": "PVT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 5,
        "tuDien2": "Phan Văn Trị",
        "diaChiLapD": "",
        "longitude": 105.83486539140624,
        "latitude": 21.025237562790153,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 980,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83475810304267,
          21.024954651857485
        ]
      },
      "properties": {
        "tenDoiTuon": "47",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 64 Thông Phong",
        "tuDien": "PVT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 5,
        "tuDien2": "Phan Văn Trị",
        "diaChiLapD": "",
        "longitude": 105.83475810304267,
        "latitude": 21.024954651857485,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 981,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83498609080853,
          21.024794418878354
        ]
      },
      "properties": {
        "tenDoiTuon": "48",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 64 Thông Phong",
        "tuDien": "PVT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 5,
        "tuDien2": "Phan Văn Trị",
        "diaChiLapD": "",
        "longitude": 105.83498609080853,
        "latitude": 21.024794418878354,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 982,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83517116323256,
          21.024766878817427
        ]
      },
      "properties": {
        "tenDoiTuon": "49",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 64 Thông Phong",
        "tuDien": "PVT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 5,
        "tuDien2": "Phan Văn Trị",
        "diaChiLapD": "",
        "longitude": 105.83517116323256,
        "latitude": 21.024766878817427,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 983,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83491903557903,
          21.02470178410926
        ]
      },
      "properties": {
        "tenDoiTuon": "48-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 64 Thông Phong",
        "tuDien": "PVT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "Phan Văn Trị",
        "diaChiLapD": "",
        "longitude": 105.83491903557903,
        "latitude": 21.02470178410926,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 984,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83486270918952,
          21.024624171149874
        ]
      },
      "properties": {
        "tenDoiTuon": "48-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 64 Thông Phong",
        "tuDien": "PVT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Phan Văn Trị",
        "diaChiLapD": "",
        "longitude": 105.83486270918952,
        "latitude": 21.024624171149874,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 985,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83504778161355,
          21.024559076378566
        ]
      },
      "properties": {
        "tenDoiTuon": "48-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 64 Thông Phong",
        "tuDien": "PVT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "Phan Văn Trị",
        "diaChiLapD": "",
        "longitude": 105.83504778161355,
        "latitude": 21.024559076378566,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 986,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83514434113718,
          21.02449147793161
        ]
      },
      "properties": {
        "tenDoiTuon": "48-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 64 Thông Phong",
        "tuDien": "PVT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "Phan Văn Trị",
        "diaChiLapD": "",
        "longitude": 105.83514434113718,
        "latitude": 21.02449147793161,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 987,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83549571052207,
          21.025114884663505
        ]
      },
      "properties": {
        "tenDoiTuon": "34-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 68 Thông Phong",
        "tuDien": "PVT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Phan Văn Trị",
        "diaChiLapD": "",
        "longitude": 105.83549571052207,
        "latitude": 21.025114884663505,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 988,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83541524425388,
          21.0250297610066
        ]
      },
      "properties": {
        "tenDoiTuon": "34-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 68 Thông Phong",
        "tuDien": "PVT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "Phan Văn Trị",
        "diaChiLapD": "",
        "longitude": 105.83541524425388,
        "latitude": 21.0250297610066,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 989,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83564323201973,
          21.024937126383712
        ]
      },
      "properties": {
        "tenDoiTuon": "34-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 68 Thông Phong",
        "tuDien": "PVT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "Phan Văn Trị",
        "diaChiLapD": "",
        "longitude": 105.83564323201973,
        "latitude": 21.024937126383712,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 990,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83564323201973,
          21.024786907953462
        ]
      },
      "properties": {
        "tenDoiTuon": "34-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 68 Thông Phong",
        "tuDien": "PVT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "Phan Văn Trị",
        "diaChiLapD": "",
        "longitude": 105.83564323201973,
        "latitude": 21.024786907953462,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 991,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83576124921429,
          21.025415320711655
        ]
      },
      "properties": {
        "tenDoiTuon": "35-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 53 Thông Phong",
        "tuDien": "PVT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Phan Văn Trị",
        "diaChiLapD": "",
        "longitude": 105.83576124921429,
        "latitude": 21.025415320711655,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 992,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83588999524879,
          21.025532991332515
        ]
      },
      "properties": {
        "tenDoiTuon": "35-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 53 Thông Phong",
        "tuDien": "PVT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Phan Văn Trị",
        "diaChiLapD": "",
        "longitude": 105.83588999524879,
        "latitude": 21.025532991332515,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 993,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83597046151698,
          21.025720763406657
        ]
      },
      "properties": {
        "tenDoiTuon": "35-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 53 Thông Phong",
        "tuDien": "PVT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Phan Văn Trị",
        "diaChiLapD": "",
        "longitude": 105.83597046151698,
        "latitude": 21.025720763406657,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 994,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83594095721385,
          21.025813397543367
        ]
      },
      "properties": {
        "tenDoiTuon": "35-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 53 Thông Phong",
        "tuDien": "PVT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "Phan Văn Trị",
        "diaChiLapD": "",
        "longitude": 105.83594095721385,
        "latitude": 21.025813397543367,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 995,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8359382750061,
          21.025933571472144
        ]
      },
      "properties": {
        "tenDoiTuon": "35-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 53 Thông Phong",
        "tuDien": "PVT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "Phan Văn Trị",
        "diaChiLapD": "",
        "longitude": 105.8359382750061,
        "latitude": 21.025933571472144,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 996,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83580952897161,
          21.025708245275794
        ]
      },
      "properties": {
        "tenDoiTuon": "35-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 53 Thông Phong",
        "tuDien": "PVT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "Phan Văn Trị",
        "diaChiLapD": "",
        "longitude": 105.83580952897161,
        "latitude": 21.025708245275794,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 997,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83571565166471,
          21.02549293325908
        ]
      },
      "properties": {
        "tenDoiTuon": "35-7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 53 Thông Phong",
        "tuDien": "PVT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "Phan Văn Trị",
        "diaChiLapD": "",
        "longitude": 105.83571565166471,
        "latitude": 21.02549293325908,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 998,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8356271387643,
          21.02599616202135
        ]
      },
      "properties": {
        "tenDoiTuon": "32-9",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 41 Thông Phong",
        "tuDien": "PVT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "Phan Văn Trị",
        "diaChiLapD": "",
        "longitude": 105.8356271387643,
        "latitude": 21.02599616202135,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 999,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83526504054844,
          21.025918549735934
        ]
      },
      "properties": {
        "tenDoiTuon": "32-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 41 Thông Phong",
        "tuDien": "PVT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Phan Văn Trị",
        "diaChiLapD": "",
        "longitude": 105.83526504054844,
        "latitude": 21.025918549735934,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1000,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83570224061701,
          21.0257432960399
        ]
      },
      "properties": {
        "tenDoiTuon": "32-7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 41 Thông Phong",
        "tuDien": "PVT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "Phan Văn Trị",
        "diaChiLapD": "",
        "longitude": 105.83570224061701,
        "latitude": 21.0257432960399,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1001,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83511148410582,
          21.02577271363678
        ]
      },
      "properties": {
        "tenDoiTuon": "31-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 41 Thông Phong",
        "tuDien": "PVT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 5,
        "tuDien2": "Phan Văn Trị",
        "diaChiLapD": "",
        "longitude": 105.83511148410582,
        "latitude": 21.02577271363678,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1002,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83546553569843,
          21.025807764385736
        ]
      },
      "properties": {
        "tenDoiTuon": "32-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 41 Thông Phong",
        "tuDien": "PVT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Phan Văn Trị",
        "diaChiLapD": "",
        "longitude": 105.83546553569843,
        "latitude": 21.025807764385736,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1003,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83557282405302,
          21.0257576918852
        ]
      },
      "properties": {
        "tenDoiTuon": "32-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 41 Thông Phong",
        "tuDien": "PVT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Phan Văn Trị",
        "diaChiLapD": "",
        "longitude": 105.83557282405302,
        "latitude": 21.0257576918852,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1004,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83572302775843,
          21.02595798178724
        ]
      },
      "properties": {
        "tenDoiTuon": "32-8",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 41 Thông Phong",
        "tuDien": "PVT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": "",
        "tuDien2": "Phan Văn Trị",
        "diaChiLapD": "",
        "longitude": 105.83572302775843,
        "latitude": 21.02595798178724,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1005,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83548699336936,
          21.026133235230866
        ]
      },
      "properties": {
        "tenDoiTuon": "32-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 41 Thông Phong",
        "tuDien": "PVT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Phan Văn Trị",
        "diaChiLapD": "",
        "longitude": 105.83548699336936,
        "latitude": 21.026133235230866,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1006,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83533678966394,
          21.02601306146305
        ]
      },
      "properties": {
        "tenDoiTuon": "32-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 41 Thông Phong",
        "tuDien": "PVT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Phan Văn Trị",
        "diaChiLapD": "",
        "longitude": 105.83533678966394,
        "latitude": 21.02601306146305,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1007,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83443791433402,
          21.02775087738018
        ]
      },
      "properties": {
        "tenDoiTuon": "11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Quốc Tử Giám",
        "tuDien": "QTG",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Quốc Tử Giám",
        "diaChiLapD": "",
        "longitude": 105.83443791433402,
        "latitude": 21.02775087738018,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1008,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83476782603898,
          21.027625697728343
        ]
      },
      "properties": {
        "tenDoiTuon": "12",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Quốc Tử Giám",
        "tuDien": "QTG",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Quốc Tử Giám",
        "diaChiLapD": "",
        "longitude": 105.83476782603898,
        "latitude": 21.027625697728343,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1009,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83507628007305,
          21.027508028759577
        ]
      },
      "properties": {
        "tenDoiTuon": "13",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Quốc Tử Giám",
        "tuDien": "QTG",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Quốc Tử Giám",
        "diaChiLapD": "",
        "longitude": 105.83507628007305,
        "latitude": 21.027508028759577,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1010,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83535522981298,
          21.02742040287218
        ]
      },
      "properties": {
        "tenDoiTuon": "14",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Quốc Tử Giám",
        "tuDien": "QTG",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Quốc Tử Giám",
        "diaChiLapD": "",
        "longitude": 105.83535522981298,
        "latitude": 21.02742040287218,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1011,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8356073574575,
          21.02733528053188
        ]
      },
      "properties": {
        "tenDoiTuon": "15",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Quốc Tử Giám",
        "tuDien": "QTG",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Quốc Tử Giám",
        "diaChiLapD": "",
        "longitude": 105.8356073574575,
        "latitude": 21.02733528053188,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1012,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83514869971802,
          21.027520546740142
        ]
      },
      "properties": {
        "tenDoiTuon": "16",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Đường phố - đèn pha",
        "tenDuongPh": "Quốc Tử Giám",
        "tuDien": "QTG",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 14,
        "tuDien2": "Quốc Tử Giám",
        "diaChiLapD": "",
        "longitude": 105.83514869971802,
        "latitude": 21.027520546740142,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1013,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83552420898157,
          21.02739286329643
        ]
      },
      "properties": {
        "tenDoiTuon": "17",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Đường phố - đèn pha",
        "tenDuongPh": "Quốc Tử Giám",
        "tuDien": "QTG",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 14,
        "tuDien2": "Quốc Tử Giám",
        "diaChiLapD": "",
        "longitude": 105.83552420898157,
        "latitude": 21.02739286329643,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1014,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83438427015223,
          21.027866042566686
        ]
      },
      "properties": {
        "tenDoiTuon": "10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Tôn Đức Thắng",
        "tuDien": "QTG",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Quốc Tử Giám",
        "diaChiLapD": "",
        "longitude": 105.83438427015223,
        "latitude": 21.027866042566686,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1015,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83456964059735,
          21.028132535580237
        ]
      },
      "properties": {
        "tenDoiTuon": "09",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Tôn Đức Thắng",
        "tuDien": "QTG",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Quốc Tử Giám",
        "diaChiLapD": "",
        "longitude": 105.83456964059735,
        "latitude": 21.028132535580237,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1016,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83475501104245,
          21.02839902811728
        ]
      },
      "properties": {
        "tenDoiTuon": "08",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Tôn Đức Thắng",
        "tuDien": "QTG",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Quốc Tử Giám",
        "diaChiLapD": "",
        "longitude": 105.83475501104245,
        "latitude": 21.02839902811728,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1017,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83494038148757,
          21.028665520177814
        ]
      },
      "properties": {
        "tenDoiTuon": "07",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Tôn Đức Thắng",
        "tuDien": "QTG",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Quốc Tử Giám",
        "diaChiLapD": "",
        "longitude": 105.83494038148757,
        "latitude": 21.028665520177814,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1018,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83512575193268,
          21.028932011761835
        ]
      },
      "properties": {
        "tenDoiTuon": "06",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Tôn Đức Thắng",
        "tuDien": "QTG",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Quốc Tử Giám",
        "diaChiLapD": "",
        "longitude": 105.83512575193268,
        "latitude": 21.028932011761835,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1019,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83531112237782,
          21.029198502869345
        ]
      },
      "properties": {
        "tenDoiTuon": "05",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Tôn Đức Thắng",
        "tuDien": "QTG",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Quốc Tử Giám",
        "diaChiLapD": "",
        "longitude": 105.83531112237782,
        "latitude": 21.029198502869345,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1020,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83548934026297,
          21.029468331584386
        ]
      },
      "properties": {
        "tenDoiTuon": "04",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Tôn Đức Thắng",
        "tuDien": "QTG",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Quốc Tử Giám",
        "diaChiLapD": "",
        "longitude": 105.83548934026297,
        "latitude": 21.029468331584386,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1021,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83567709489472,
          21.029738716157325
        ]
      },
      "properties": {
        "tenDoiTuon": "03",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Tôn Đức Thắng",
        "tuDien": "QTG",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Quốc Tử Giám",
        "diaChiLapD": "",
        "longitude": 105.83567709489472,
        "latitude": 21.029738716157325,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1022,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83586216731878,
          21.029999086022933
        ]
      },
      "properties": {
        "tenDoiTuon": "02",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Tôn Đức Thắng",
        "tuDien": "QTG",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Quốc Tử Giám",
        "diaChiLapD": "",
        "longitude": 105.83586216731878,
        "latitude": 21.029999086022933,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1023,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83605260415828,
          21.030264462534166
        ]
      },
      "properties": {
        "tenDoiTuon": "01",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Tôn Đức Thắng",
        "tuDien": "QTG",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Quốc Tử Giám",
        "diaChiLapD": "",
        "longitude": 105.83605260415828,
        "latitude": 21.030264462534166,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1024,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83591312928382,
          21.027265179744166
        ]
      },
      "properties": {
        "tenDoiTuon": "18",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Văn Miếu",
        "tuDien": "QTG",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Quốc Tử Giám",
        "diaChiLapD": "",
        "longitude": 105.83591312928382,
        "latitude": 21.027265179744166,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1025,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83602309985334,
          21.027538572628742
        ]
      },
      "properties": {
        "tenDoiTuon": "19",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Văn Miếu",
        "tuDien": "QTG",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Quốc Tử Giám",
        "diaChiLapD": "",
        "longitude": 105.83602309985334,
        "latitude": 21.027538572628742,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1026,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8361330704229,
          21.027811965011853
        ]
      },
      "properties": {
        "tenDoiTuon": "20",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Văn Miếu",
        "tuDien": "QTG",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Quốc Tử Giám",
        "diaChiLapD": "",
        "longitude": 105.8361330704229,
        "latitude": 21.027811965011853,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1027,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8362430409924,
          21.028085356893488
        ]
      },
      "properties": {
        "tenDoiTuon": "21",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Văn Miếu",
        "tuDien": "QTG",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Quốc Tử Giám",
        "diaChiLapD": "",
        "longitude": 105.8362430409924,
        "latitude": 21.028085356893488,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1028,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83635301156191,
          21.02835874827363
        ]
      },
      "properties": {
        "tenDoiTuon": "22",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Văn Miếu",
        "tuDien": "QTG",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Quốc Tử Giám",
        "diaChiLapD": "",
        "longitude": 105.83635301156191,
        "latitude": 21.02835874827363,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1029,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83646298213144,
          21.02863213915227
        ]
      },
      "properties": {
        "tenDoiTuon": "23",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Văn Miếu",
        "tuDien": "QTG",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Quốc Tử Giám",
        "diaChiLapD": "",
        "longitude": 105.83646298213144,
        "latitude": 21.02863213915227,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1030,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83657295270096,
          21.02890552952943
        ]
      },
      "properties": {
        "tenDoiTuon": "24",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Văn Miếu",
        "tuDien": "QTG",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Quốc Tử Giám",
        "diaChiLapD": "",
        "longitude": 105.83657295270096,
        "latitude": 21.02890552952943,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1031,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83668292327049,
          21.029178919405062
        ]
      },
      "properties": {
        "tenDoiTuon": "25",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Văn Miếu",
        "tuDien": "QTG",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Quốc Tử Giám",
        "diaChiLapD": "",
        "longitude": 105.83668292327049,
        "latitude": 21.029178919405062,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1032,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83679289384001,
          21.029452308779202
        ]
      },
      "properties": {
        "tenDoiTuon": "26",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Văn Miếu",
        "tuDien": "QTG",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Quốc Tử Giám",
        "diaChiLapD": "",
        "longitude": 105.83679289384001,
        "latitude": 21.029452308779202,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1033,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83690286440954,
          21.029725697651802
        ]
      },
      "properties": {
        "tenDoiTuon": "27",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Văn Miếu",
        "tuDien": "QTG",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Quốc Tử Giám",
        "diaChiLapD": "",
        "longitude": 105.83690286440954,
        "latitude": 21.029725697651802,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1034,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83701283497906,
          21.029999086022908
        ]
      },
      "properties": {
        "tenDoiTuon": "28",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Văn Miếu",
        "tuDien": "QTG",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Quốc Tử Giám",
        "diaChiLapD": "",
        "longitude": 105.83701283497906,
        "latitude": 21.029999086022908,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1035,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8366795705189,
          21.028662807968846
        ]
      },
      "properties": {
        "tenDoiTuon": "29",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Nguyễn Khuyến",
        "tuDien": "QTG",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 9,
        "tuDien2": "Quốc Tử Giám",
        "diaChiLapD": "",
        "longitude": 105.8366795705189,
        "latitude": 21.028662807968846,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1036,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8370175288471,
          21.02860772927221
        ]
      },
      "properties": {
        "tenDoiTuon": "30",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Nguyễn Khuyến",
        "tuDien": "QTG",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 9,
        "tuDien2": "Quốc Tử Giám",
        "diaChiLapD": "",
        "longitude": 105.8370175288471,
        "latitude": 21.02860772927221,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1037,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83727233870836,
          21.028562664869227
        ]
      },
      "properties": {
        "tenDoiTuon": "31",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Nguyễn Khuyến",
        "tuDien": "QTG",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 9,
        "tuDien2": "Quốc Tử Giám",
        "diaChiLapD": "",
        "longitude": 105.83727233870836,
        "latitude": 21.028562664869227,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1038,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83752178414514,
          21.02852010403134
        ]
      },
      "properties": {
        "tenDoiTuon": "32",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Nguyễn Khuyến",
        "tuDien": "QTG",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 9,
        "tuDien2": "Quốc Tử Giám",
        "diaChiLapD": "",
        "longitude": 105.83752178414514,
        "latitude": 21.02852010403134,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1039,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83779536946061,
          21.028460018121763
        ]
      },
      "properties": {
        "tenDoiTuon": "33",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Nguyễn Khuyến",
        "tuDien": "QTG",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 9,
        "tuDien2": "Quốc Tử Giám",
        "diaChiLapD": "",
        "longitude": 105.83779536946061,
        "latitude": 21.028460018121763,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1040,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83809577687143,
          21.028392421443918
        ]
      },
      "properties": {
        "tenDoiTuon": "34",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Nguyễn Khuyến",
        "tuDien": "QTG",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 9,
        "tuDien2": "Quốc Tử Giám",
        "diaChiLapD": "",
        "longitude": 105.83809577687143,
        "latitude": 21.028392421443918,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1041,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83839081986677,
          21.02832732831907
        ]
      },
      "properties": {
        "tenDoiTuon": "35",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Nguyễn Khuyến",
        "tuDien": "QTG",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 9,
        "tuDien2": "Quốc Tử Giám",
        "diaChiLapD": "",
        "longitude": 105.83839081986677,
        "latitude": 21.02832732831907,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1042,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83624505265807,
          21.027886697182577
        ]
      },
      "properties": {
        "tenDoiTuon": "36",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Ngô Tất Tố",
        "tuDien": "QTG",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Quốc Tử Giám",
        "diaChiLapD": "",
        "longitude": 105.83624505265807,
        "latitude": 21.027886697182577,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1043,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83666884167896,
          21.02773397813296
        ]
      },
      "properties": {
        "tenDoiTuon": "37",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Ngô Tất Tố",
        "tuDien": "QTG",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Quốc Tử Giám",
        "diaChiLapD": "",
        "longitude": 105.83666884167896,
        "latitude": 21.02773397813296,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1044,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83699607117619,
          21.027616309249673
        ]
      },
      "properties": {
        "tenDoiTuon": "38",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Ngô Tất Tố",
        "tuDien": "QTG",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Quốc Tử Giám",
        "diaChiLapD": "",
        "longitude": 105.83699607117619,
        "latitude": 21.027616309249673,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1045,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83727772194058,
          21.027526734992552
        ]
      },
      "properties": {
        "tenDoiTuon": "39",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Ngô Tất Tố",
        "tuDien": "QTG",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Quốc Tử Giám",
        "diaChiLapD": "",
        "longitude": 105.83727772194058,
        "latitude": 21.027526734992552,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1046,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83758079274241,
          21.027448568340976
        ]
      },
      "properties": {
        "tenDoiTuon": "40",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Ngô Tất Tố",
        "tuDien": "QTG",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Quốc Tử Giám",
        "diaChiLapD": "",
        "longitude": 105.83758079274241,
        "latitude": 21.027448568340976,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1047,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8371596859293,
          21.02745858272917
        ]
      },
      "properties": {
        "tenDoiTuon": "39-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 16 Ngô Tất Tố",
        "tuDien": "QTG",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 5,
        "tuDien2": "Quốc Tử Giám",
        "diaChiLapD": "",
        "longitude": 105.8371596859293,
        "latitude": 21.02745858272917,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1048,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8370255754793,
          21.02725078404194
        ]
      },
      "properties": {
        "tenDoiTuon": "39-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 16 Ngô Tất Tố",
        "tuDien": "QTG",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 5,
        "tuDien2": "Quốc Tử Giám",
        "diaChiLapD": "",
        "longitude": 105.8370255754793,
        "latitude": 21.02725078404194,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1049,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83773636086333,
          21.027678899093072
        ]
      },
      "properties": {
        "tenDoiTuon": "40-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 18 Ngô Tất Tố",
        "tuDien": "QTG",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Quốc Tử Giám",
        "diaChiLapD": "",
        "longitude": 105.83773636086333,
        "latitude": 21.027678899093072,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1050,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83746545775561,
          21.02727582004351
        ]
      },
      "properties": {
        "tenDoiTuon": "40-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 18 Ngô Tất Tố",
        "tuDien": "QTG",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Quốc Tử Giám",
        "diaChiLapD": "",
        "longitude": 105.83746545775561,
        "latitude": 21.02727582004351,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1051,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83733671172111,
          21.027030467046036
        ]
      },
      "properties": {
        "tenDoiTuon": "40-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 18 Ngô Tất Tố",
        "tuDien": "QTG",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Quốc Tử Giám",
        "diaChiLapD": "",
        "longitude": 105.83733671172111,
        "latitude": 21.027030467046036,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1052,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83765321238738,
          21.02761130206097
        ]
      },
      "properties": {
        "tenDoiTuon": "40-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 18 Ngô Tất Tố",
        "tuDien": "QTG",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Quốc Tử Giám",
        "diaChiLapD": "",
        "longitude": 105.83765321238738,
        "latitude": 21.02761130206097,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1053,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83714091046613,
          21.02764384878414
        ]
      },
      "properties": {
        "tenDoiTuon": "38-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 115 Nguyễn Khuyến",
        "tuDien": "QTG",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Quốc Tử Giám",
        "diaChiLapD": "",
        "longitude": 105.83714091046613,
        "latitude": 21.02764384878414,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1054,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83725356324518,
          21.028011876615167
        ]
      },
      "properties": {
        "tenDoiTuon": "38-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 115 Nguyễn Khuyến",
        "tuDien": "QTG",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Quốc Tử Giám",
        "diaChiLapD": "",
        "longitude": 105.83725356324518,
        "latitude": 21.028011876615167,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1055,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83731793625795,
          21.02829728532845
        ]
      },
      "properties": {
        "tenDoiTuon": "38-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 115 Nguyễn Khuyến",
        "tuDien": "QTG",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Quốc Tử Giám",
        "diaChiLapD": "",
        "longitude": 105.83731793625795,
        "latitude": 21.02829728532845,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1056,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83699372429157,
          21.028370828055078
        ]
      },
      "properties": {
        "tenDoiTuon": "30-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 221 Nguyễn Khuyến",
        "tuDien": "QTG",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Quốc Tử Giám",
        "diaChiLapD": "",
        "longitude": 105.83699372429157,
        "latitude": 21.028370828055078,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1057,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83701249975474,
          21.02812046968771
        ]
      },
      "properties": {
        "tenDoiTuon": "30-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 221 Nguyễn Khuyến",
        "tuDien": "QTG",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Quốc Tử Giám",
        "diaChiLapD": "",
        "longitude": 105.83701249975474,
        "latitude": 21.02812046968771,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1058,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83684620280289,
          21.028132987615976
        ]
      },
      "properties": {
        "tenDoiTuon": "30-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 221 Nguyễn Khuyến",
        "tuDien": "QTG",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Quốc Tử Giám",
        "diaChiLapD": "",
        "longitude": 105.83684620280289,
        "latitude": 21.028132987615976,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1059,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83681133408426,
          21.02825065609135
        ]
      },
      "properties": {
        "tenDoiTuon": "30-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 221 Nguyễn Khuyến",
        "tuDien": "QTG",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Quốc Tử Giám",
        "diaChiLapD": "",
        "longitude": 105.83681133408426,
        "latitude": 21.02825065609135,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1060,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83740410227372,
          21.028716321910608
        ]
      },
      "properties": {
        "tenDoiTuon": "31-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Chùa Bà Ngô",
        "tuDien": "QTG",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": "",
        "tuDien2": "Quốc Tử Giám",
        "diaChiLapD": "",
        "longitude": 105.83740410227372,
        "latitude": 21.028716321910608,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1061,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83689568006838,
          21.028854644409677
        ]
      },
      "properties": {
        "tenDoiTuon": "30-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 144 Nguyễn Khuyến",
        "tuDien": "QTG",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Quốc Tử Giám",
        "diaChiLapD": "",
        "longitude": 105.83689568006838,
        "latitude": 21.028854644409677,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1062,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83690640890832,
          21.02904741944071
        ]
      },
      "properties": {
        "tenDoiTuon": "30-7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 144 Nguyễn Khuyến",
        "tuDien": "QTG",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Quốc Tử Giám",
        "diaChiLapD": "",
        "longitude": 105.83690640890832,
        "latitude": 21.02904741944071,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1063,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83704856598152,
          21.02901487302404
        ]
      },
      "properties": {
        "tenDoiTuon": "30-8",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 144 Nguyễn Khuyến",
        "tuDien": "QTG",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 5,
        "tuDien2": "Quốc Tử Giám",
        "diaChiLapD": "",
        "longitude": 105.83704856598152,
        "latitude": 21.02901487302404,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1064,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.836930548787,
          21.028977319458285
        ]
      },
      "properties": {
        "tenDoiTuon": "30-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 144 Nguyễn Khuyến",
        "tuDien": "QTG",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": "",
        "tuDien2": "Quốc Tử Giám",
        "diaChiLapD": "",
        "longitude": 105.836930548787,
        "latitude": 21.028977319458285,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1065,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83708693105103,
          21.029526852360586
        ]
      },
      "properties": {
        "tenDoiTuon": "27-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Thanh Miến",
        "tuDien": "QTG",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Quốc Tử Giám",
        "diaChiLapD": "",
        "longitude": 105.83708693105103,
        "latitude": 21.029526852360586,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1066,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83739270287735,
          21.02941919914923
        ]
      },
      "properties": {
        "tenDoiTuon": "27-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Thanh Miến",
        "tuDien": "QTG",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Quốc Tử Giám",
        "diaChiLapD": "",
        "longitude": 105.83739270287735,
        "latitude": 21.02941919914923,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1067,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83750803786415,
          21.029321560123137
        ]
      },
      "properties": {
        "tenDoiTuon": "27-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Thanh Miến",
        "tuDien": "QTG",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Quốc Tử Giám",
        "diaChiLapD": "",
        "longitude": 105.83750803786415,
        "latitude": 21.029321560123137,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1068,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83785404282457,
          21.02921641033053
        ]
      },
      "properties": {
        "tenDoiTuon": "27-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Thanh Miến",
        "tuDien": "QTG",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Quốc Tử Giám",
        "diaChiLapD": "",
        "longitude": 105.83785404282457,
        "latitude": 21.02921641033053,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1069,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8380525262963,
          21.029213906763502
        ]
      },
      "properties": {
        "tenDoiTuon": "27-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Thanh Miến",
        "tuDien": "QTG",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Quốc Tử Giám",
        "diaChiLapD": "",
        "longitude": 105.8380525262963,
        "latitude": 21.029213906763502,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1070,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8382349165036,
          21.029316552991716
        ]
      },
      "properties": {
        "tenDoiTuon": "27-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Thanh Miến",
        "tuDien": "QTG",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 5,
        "tuDien2": "Quốc Tử Giám",
        "diaChiLapD": "",
        "longitude": 105.8382349165036,
        "latitude": 21.029316552991716,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1071,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83700646477386,
          21.029421702712824
        ]
      },
      "properties": {
        "tenDoiTuon": "27-10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Thanh Miến",
        "tuDien": "QTG",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 5,
        "tuDien2": "Quốc Tử Giám",
        "diaChiLapD": "",
        "longitude": 105.83700646477386,
        "latitude": 21.029421702712824,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1072,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83710838872194,
          21.02938665281459
        ]
      },
      "properties": {
        "tenDoiTuon": "27-11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Thanh Miến",
        "tuDien": "QTG",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Quốc Tử Giám",
        "diaChiLapD": "",
        "longitude": 105.83710838872194,
        "latitude": 21.02938665281459,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1073,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8371244819774,
          21.029276495935154
        ]
      },
      "properties": {
        "tenDoiTuon": "27-12",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Thanh Miến",
        "tuDien": "QTG",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Quốc Tử Giám",
        "diaChiLapD": "",
        "longitude": 105.8371244819774,
        "latitude": 21.029276495935154,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1074,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83780844527499,
          21.029088728340497
        ]
      },
      "properties": {
        "tenDoiTuon": "27-8",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Thanh Miến",
        "tuDien": "QTG",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Quốc Tử Giám",
        "diaChiLapD": "",
        "longitude": 105.83780844527499,
        "latitude": 21.029088728340497,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1075,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83796133118815,
          21.029086224771316
        ]
      },
      "properties": {
        "tenDoiTuon": "27-9",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Thanh Miến",
        "tuDien": "QTG",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Quốc Tử Giám",
        "diaChiLapD": "",
        "longitude": 105.83796133118815,
        "latitude": 21.029086224771316,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1076,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83773334342227,
          21.02911626760291
        ]
      },
      "properties": {
        "tenDoiTuon": "27-7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Thanh Miến",
        "tuDien": "QTG",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Quốc Tử Giám",
        "diaChiLapD": "",
        "longitude": 105.83773334342227,
        "latitude": 21.02911626760291,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1077,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83450396376482,
          21.027373773360736
        ]
      },
      "properties": {
        "tenDoiTuon": "12-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 63+65+67 Quốc Tử Giám",
        "tuDien": "QTG",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": "",
        "tuDien2": "Quốc Tử Giám",
        "diaChiLapD": "",
        "longitude": 105.83450396376482,
        "latitude": 21.027373773360736,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1078,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83441813308112,
          21.027311183390026
        ]
      },
      "properties": {
        "tenDoiTuon": "12-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 63+65+67 Quốc Tử Giám",
        "tuDien": "QTG",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": "",
        "tuDien2": "Quốc Tử Giám",
        "diaChiLapD": "",
        "longitude": 105.83441813308112,
        "latitude": 21.027311183390026,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1079,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83462198095933,
          21.027278636594204
        ]
      },
      "properties": {
        "tenDoiTuon": "12-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 63+65+67 Quốc Tử Giám",
        "tuDien": "QTG",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": "",
        "tuDien2": "Quốc Tử Giám",
        "diaChiLapD": "",
        "longitude": 105.83462198095933,
        "latitude": 21.027278636594204,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1080,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83468367177333,
          21.02734873737561
        ]
      },
      "properties": {
        "tenDoiTuon": "12-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 63+65+67 Quốc Tử Giám",
        "tuDien": "QTG",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": "",
        "tuDien2": "Quốc Tử Giám",
        "diaChiLapD": "",
        "longitude": 105.83468367177333,
        "latitude": 21.02734873737561,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1081,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83475340920158,
          21.02726361499439
        ]
      },
      "properties": {
        "tenDoiTuon": "12-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 63+65+67 Quốc Tử Giám",
        "tuDien": "QTG",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": "",
        "tuDien2": "Quốc Tử Giám",
        "diaChiLapD": "",
        "longitude": 105.83475340920158,
        "latitude": 21.02726361499439,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1082,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83482582884653,
          21.02720603217908
        ]
      },
      "properties": {
        "tenDoiTuon": "12-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 63+65+67 Quốc Tử Giám",
        "tuDien": "QTG",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": "",
        "tuDien2": "Quốc Tử Giám",
        "diaChiLapD": "",
        "longitude": 105.83482582884653,
        "latitude": 21.02720603217908,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1083,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8346809895656,
          21.02714093853524
        ]
      },
      "properties": {
        "tenDoiTuon": "12-7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 63+65+67 Quốc Tử Giám",
        "tuDien": "QTG",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": "",
        "tuDien2": "Quốc Tử Giám",
        "diaChiLapD": "",
        "longitude": 105.8346809895656,
        "latitude": 21.02714093853524,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1084,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83493311721011,
          21.02726361499439
        ]
      },
      "properties": {
        "tenDoiTuon": "12-8",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 63+65+67 Quốc Tử Giám",
        "tuDien": "QTG",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": "",
        "tuDien2": "Quốc Tử Giám",
        "diaChiLapD": "",
        "longitude": 105.83493311721011,
        "latitude": 21.02726361499439,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1085,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8358769769625,
          21.03032423477068
        ]
      },
      "properties": {
        "tenDoiTuon": "01",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Tôn Đức Thắng",
        "tuDien": "HC",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Hàng Cháo",
        "diaChiLapD": "",
        "longitude": 105.8358769769625,
        "latitude": 21.03032423477068,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1086,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83569047402928,
          21.0300611532905
        ]
      },
      "properties": {
        "tenDoiTuon": "02",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Tôn Đức Thắng",
        "tuDien": "HC",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Hàng Cháo",
        "diaChiLapD": "",
        "longitude": 105.83569047402928,
        "latitude": 21.0300611532905,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1087,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83550397109605,
          21.02979807134589
        ]
      },
      "properties": {
        "tenDoiTuon": "03",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Tôn Đức Thắng",
        "tuDien": "HC",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Hàng Cháo",
        "diaChiLapD": "",
        "longitude": 105.83550397109605,
        "latitude": 21.02979807134589,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1088,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83531746816281,
          21.02953498893683
        ]
      },
      "properties": {
        "tenDoiTuon": "04",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Tôn Đức Thắng",
        "tuDien": "HC",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Hàng Cháo",
        "diaChiLapD": "",
        "longitude": 105.83531746816281,
        "latitude": 21.02953498893683,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1089,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83515868138424,
          21.02931342353523
        ]
      },
      "properties": {
        "tenDoiTuon": "05",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Tôn Đức Thắng",
        "tuDien": "HC",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Hàng Cháo",
        "diaChiLapD": "",
        "longitude": 105.83515868138424,
        "latitude": 21.02931342353523,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1090,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83494446229636,
          21.0290088227255
        ]
      },
      "properties": {
        "tenDoiTuon": "06",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Tôn Đức Thắng",
        "tuDien": "HC",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Hàng Cháo",
        "diaChiLapD": "",
        "longitude": 105.83494446229636,
        "latitude": 21.0290088227255,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1091,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83475795936315,
          21.028745738923195
        ]
      },
      "properties": {
        "tenDoiTuon": "07",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Tôn Đức Thắng",
        "tuDien": "HC",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Hàng Cháo",
        "diaChiLapD": "",
        "longitude": 105.83475795936315,
        "latitude": 21.028745738923195,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1092,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8345714564299,
          21.02848265465654
        ]
      },
      "properties": {
        "tenDoiTuon": "08",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Tôn Đức Thắng",
        "tuDien": "HC",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Hàng Cháo",
        "diaChiLapD": "",
        "longitude": 105.8345714564299,
        "latitude": 21.02848265465654,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1093,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83438495349667,
          21.028219569925458
        ]
      },
      "properties": {
        "tenDoiTuon": "09",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Tôn Đức Thắng",
        "tuDien": "HC",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Hàng Cháo",
        "diaChiLapD": "",
        "longitude": 105.83438495349667,
        "latitude": 21.028219569925458,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1094,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83419845056345,
          21.02795648472999
        ]
      },
      "properties": {
        "tenDoiTuon": "10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Tôn Đức Thắng",
        "tuDien": "HC",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Hàng Cháo",
        "diaChiLapD": "",
        "longitude": 105.83419845056345,
        "latitude": 21.02795648472999,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1095,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83387899948842,
          21.028009685975793
        ]
      },
      "properties": {
        "tenDoiTuon": "11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Cát Linh",
        "tuDien": "HC",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 11,
        "tuDien2": "Hàng Cháo",
        "diaChiLapD": "",
        "longitude": 105.83387899948842,
        "latitude": 21.028009685975793,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1096,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83506775503407,
          21.02934909934166
        ]
      },
      "properties": {
        "tenDoiTuon": "19",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hàng Cháo",
        "tuDien": "HC",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hàng Cháo",
        "diaChiLapD": "",
        "longitude": 105.83506775503407,
        "latitude": 21.02934909934166,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1097,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83487530653872,
          21.02968144724287
        ]
      },
      "properties": {
        "tenDoiTuon": "20",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hàng Cháo",
        "tuDien": "HC",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hàng Cháo",
        "diaChiLapD": "",
        "longitude": 105.83487530653872,
        "latitude": 21.02968144724287,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1098,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83468285804342,
          21.030013794402922
        ]
      },
      "properties": {
        "tenDoiTuon": "21",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hàng Cháo",
        "tuDien": "HC",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hàng Cháo",
        "diaChiLapD": "",
        "longitude": 105.83468285804342,
        "latitude": 21.030013794402922,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1099,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83449040954811,
          21.030346140821802
        ]
      },
      "properties": {
        "tenDoiTuon": "22",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hàng Cháo",
        "tuDien": "HC",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hàng Cháo",
        "diaChiLapD": "",
        "longitude": 105.83449040954811,
        "latitude": 21.030346140821802,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1100,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83429796105278,
          21.0306784864995
        ]
      },
      "properties": {
        "tenDoiTuon": "23",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hàng Cháo",
        "tuDien": "HC",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hàng Cháo",
        "diaChiLapD": "",
        "longitude": 105.83429796105278,
        "latitude": 21.0306784864995,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1101,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83421964003335,
          21.02903865694137
        ]
      },
      "properties": {
        "tenDoiTuon": "37",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Phan Phù Tiên",
        "tuDien": "HC",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hàng Cháo",
        "diaChiLapD": "",
        "longitude": 105.83421964003335,
        "latitude": 21.02903865694137,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1102,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83405513121399,
          21.028763263948594
        ]
      },
      "properties": {
        "tenDoiTuon": "38",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Phan Phù Tiên",
        "tuDien": "HC",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hàng Cháo",
        "diaChiLapD": "",
        "longitude": 105.83405513121399,
        "latitude": 21.028763263948594,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1103,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83389062239462,
          21.028487870446952
        ]
      },
      "properties": {
        "tenDoiTuon": "39",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Phan Phù Tiên",
        "tuDien": "HC",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hàng Cháo",
        "diaChiLapD": "",
        "longitude": 105.83389062239462,
        "latitude": 21.028487870446952,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1104,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83372611357525,
          21.028212476436437
        ]
      },
      "properties": {
        "tenDoiTuon": "40",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Phan Phù Tiên",
        "tuDien": "HC",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hàng Cháo",
        "diaChiLapD": "",
        "longitude": 105.83372611357525,
        "latitude": 21.028212476436437,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1105,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83349812580938,
          21.028157397572546
        ]
      },
      "properties": {
        "tenDoiTuon": "12",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Cát Linh",
        "tuDien": "HC",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 11,
        "tuDien2": "Hàng Cháo",
        "diaChiLapD": "",
        "longitude": 105.83349812580938,
        "latitude": 21.028157397572546,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1106,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83324868036361,
          21.028247526610805
        ]
      },
      "properties": {
        "tenDoiTuon": "13",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Cát Linh",
        "tuDien": "HC",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "Hàng Cháo",
        "diaChiLapD": "",
        "longitude": 105.83324868036361,
        "latitude": 21.028247526610805,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1107,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83298743320557,
          21.028347669922844
        ]
      },
      "properties": {
        "tenDoiTuon": "14",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Cát Linh",
        "tuDien": "HC",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "Hàng Cháo",
        "diaChiLapD": "",
        "longitude": 105.83298743320557,
        "latitude": 21.028347669922844,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1108,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83272618604752,
          21.02844781316759
        ]
      },
      "properties": {
        "tenDoiTuon": "15",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Cát Linh",
        "tuDien": "HC",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "Hàng Cháo",
        "diaChiLapD": "",
        "longitude": 105.83272618604752,
        "latitude": 21.02844781316759,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1109,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8324649388895,
          21.028547956345065
        ]
      },
      "properties": {
        "tenDoiTuon": "16",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Cát Linh",
        "tuDien": "HC",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "Hàng Cháo",
        "diaChiLapD": "",
        "longitude": 105.8324649388895,
        "latitude": 21.028547956345065,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1110,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83220369173146,
          21.028648099455232
        ]
      },
      "properties": {
        "tenDoiTuon": "17",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Cát Linh",
        "tuDien": "HC",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "Hàng Cháo",
        "diaChiLapD": "",
        "longitude": 105.83220369173146,
        "latitude": 21.028648099455232,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1111,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83194244457341,
          21.02874824249812
        ]
      },
      "properties": {
        "tenDoiTuon": "18",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Cát Linh",
        "tuDien": "HC",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "Hàng Cháo",
        "diaChiLapD": "",
        "longitude": 105.83194244457341,
        "latitude": 21.02874824249812,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1112,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83504576040416,
          21.029824775957984
        ]
      },
      "properties": {
        "tenDoiTuon": "20-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 17 Hàng Cháo",
        "tuDien": "HC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Hàng Cháo",
        "diaChiLapD": "",
        "longitude": 105.83504576040416,
        "latitude": 21.029824775957984,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1113,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83523083282819,
          21.030012542625272
        ]
      },
      "properties": {
        "tenDoiTuon": "20-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 17 Hàng Cháo",
        "tuDien": "HC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Hàng Cháo",
        "diaChiLapD": "",
        "longitude": 105.83523083282819,
        "latitude": 21.030012542625272,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1114,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83417404247479,
          21.030403096535856
        ]
      },
      "properties": {
        "tenDoiTuon": "22-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 2 Hàng Cháo",
        "tuDien": "HC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Hàng Cháo",
        "diaChiLapD": "",
        "longitude": 105.83417404247479,
        "latitude": 21.030403096535856,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1115,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83454955173832,
          21.028968556955636
        ]
      },
      "properties": {
        "tenDoiTuon": "24",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Hàng Bột",
        "tuDien": "HC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "Hàng Cháo",
        "diaChiLapD": "",
        "longitude": 105.83454955173832,
        "latitude": 21.028968556955636,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1116,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.834182089107,
          21.029191374654935
        ]
      },
      "properties": {
        "tenDoiTuon": "25",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Hàng Bột",
        "tuDien": "HC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Hàng Cháo",
        "diaChiLapD": "",
        "longitude": 105.834182089107,
        "latitude": 21.029191374654935,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1117,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83400774552291,
          21.029371631426397
        ]
      },
      "properties": {
        "tenDoiTuon": "26",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Hàng Bột",
        "tuDien": "HC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Hàng Cháo",
        "diaChiLapD": "",
        "longitude": 105.83400774552291,
        "latitude": 21.029371631426397,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1118,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83378243996479,
          21.0294842917972
        ]
      },
      "properties": {
        "tenDoiTuon": "27",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Hàng Bột",
        "tuDien": "HC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Hàng Cháo",
        "diaChiLapD": "",
        "longitude": 105.83378243996479,
        "latitude": 21.0294842917972,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1119,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83366978718573,
          21.029541873732295
        ]
      },
      "properties": {
        "tenDoiTuon": "28",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Hàng Bột",
        "tuDien": "HC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Hàng Cháo",
        "diaChiLapD": "",
        "longitude": 105.83366978718573,
        "latitude": 21.029541873732295,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1120,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8338038976357,
          21.029992514192294
        ]
      },
      "properties": {
        "tenDoiTuon": "30",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Hàng Bột",
        "tuDien": "HC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Hàng Cháo",
        "diaChiLapD": "",
        "longitude": 105.8338038976357,
        "latitude": 21.029992514192294,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1121,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.833728795783,
          21.02976719413304
        ]
      },
      "properties": {
        "tenDoiTuon": "29",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Hàng Bột",
        "tuDien": "HC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Hàng Cháo",
        "diaChiLapD": "",
        "longitude": 105.833728795783,
        "latitude": 21.02976719413304,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1122,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8335866387053,
          21.02945675260365
        ]
      },
      "properties": {
        "tenDoiTuon": "31",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Hàng Bột",
        "tuDien": "HC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Hàng Cháo",
        "diaChiLapD": "",
        "longitude": 105.8335866387053,
        "latitude": 21.02945675260365,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1123,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83348739696943,
          21.02917635324705
        ]
      },
      "properties": {
        "tenDoiTuon": "32",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Hàng Bột",
        "tuDien": "HC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Hàng Cháo",
        "diaChiLapD": "",
        "longitude": 105.83348739696943,
        "latitude": 21.02917635324705,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1124,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8333693797749,
          21.028920989088967
        ]
      },
      "properties": {
        "tenDoiTuon": "33",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Hàng Bột",
        "tuDien": "HC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Hàng Cháo",
        "diaChiLapD": "",
        "longitude": 105.8333693797749,
        "latitude": 21.028920989088967,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1125,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83307433677953,
          21.02893100337738
        ]
      },
      "properties": {
        "tenDoiTuon": "34",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Hàng Bột",
        "tuDien": "HC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Hàng Cháo",
        "diaChiLapD": "",
        "longitude": 105.83307433677953,
        "latitude": 21.02893100337738,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1126,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8326585943819,
          21.029063692642612
        ]
      },
      "properties": {
        "tenDoiTuon": "35",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Hàng Bột",
        "tuDien": "HC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Hàng Cháo",
        "diaChiLapD": "",
        "longitude": 105.8326585943819,
        "latitude": 21.029063692642612,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1127,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83224016977651,
          21.029173849679346
        ]
      },
      "properties": {
        "tenDoiTuon": "36",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Hàng Bột",
        "tuDien": "HC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Hàng Cháo",
        "diaChiLapD": "",
        "longitude": 105.83224016977651,
        "latitude": 21.029173849679346,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1128,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83424914432749,
          21.02985481864069
        ]
      },
      "properties": {
        "tenDoiTuon": "30-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Hàng Bột",
        "tuDien": "HC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Hàng Cháo",
        "diaChiLapD": "",
        "longitude": 105.83424914432749,
        "latitude": 21.02985481864069,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1129,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83441275908059,
          21.02941919914923
        ]
      },
      "properties": {
        "tenDoiTuon": "24-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Hàng Bột",
        "tuDien": "HC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Hàng Cháo",
        "diaChiLapD": "",
        "longitude": 105.83441275908059,
        "latitude": 21.02941919914923,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1130,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83385217739304,
          21.02852292055683
        ]
      },
      "properties": {
        "tenDoiTuon": "39-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 23 Phan Phù Tiên",
        "tuDien": "HC",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Hàng Cháo",
        "diaChiLapD": "",
        "longitude": 105.83385217739304,
        "latitude": 21.02852292055683,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1131,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83360809637173,
          21.028270058862063
        ]
      },
      "properties": {
        "tenDoiTuon": "40-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 3 Phan Phù Tiên",
        "tuDien": "HC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Hàng Cháo",
        "diaChiLapD": "",
        "longitude": 105.83360809637173,
        "latitude": 21.028270058862063,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1132,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83360809637173,
          21.028580502863363
        ]
      },
      "properties": {
        "tenDoiTuon": "40-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 3 Phan Phù Tiên",
        "tuDien": "HC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Hàng Cháo",
        "diaChiLapD": "",
        "longitude": 105.83360809637173,
        "latitude": 21.028580502863363,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1133,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83366442276127,
          21.02886841406063
        ]
      },
      "properties": {
        "tenDoiTuon": "40-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 3 Phan Phù Tiên",
        "tuDien": "HC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Hàng Cháo",
        "diaChiLapD": "",
        "longitude": 105.83366442276127,
        "latitude": 21.02886841406063,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1134,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83276320053773,
          21.02850038834465
        ]
      },
      "properties": {
        "tenDoiTuon": "41",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Trong ngõ - đèn chùm",
        "tenDuongPh": "Khu vực xung quanh chùa Bích Câu",
        "tuDien": "HC",
        "chuDauTu": "SoXayDung",
        "loaiCot": "DC-06",
        "chieuCao": 3,
        "tuDien2": "Hàng Cháo",
        "diaChiLapD": "",
        "longitude": 105.83276320053773,
        "latitude": 21.02850038834465,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1135,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83286512447685,
          21.028465338228887
        ]
      },
      "properties": {
        "tenDoiTuon": "42",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Trong ngõ - đèn chùm",
        "tenDuongPh": "Khu vực xung quanh chùa Bích Câu",
        "tuDien": "HC",
        "chuDauTu": "SoXayDung",
        "loaiCot": "DC-06",
        "chieuCao": 3,
        "tuDien2": "Hàng Cháo",
        "diaChiLapD": "",
        "longitude": 105.83286512447685,
        "latitude": 21.028465338228887,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1136,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83285171342915,
          21.028798313993995
        ]
      },
      "properties": {
        "tenDoiTuon": "43",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Trong ngõ - đèn chùm",
        "tenDuongPh": "Khu vực xung quanh chùa Bích Câu",
        "tuDien": "HC",
        "chuDauTu": "SoXayDung",
        "loaiCot": "DC-06",
        "chieuCao": 3,
        "tuDien2": "Hàng Cháo",
        "diaChiLapD": "",
        "longitude": 105.83285171342915,
        "latitude": 21.028798313993995,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1137,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83294827296177,
          21.028775781822585
        ]
      },
      "properties": {
        "tenDoiTuon": "44",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Trong ngõ - đèn chùm",
        "tenDuongPh": "Khu vực xung quanh chùa Bích Câu",
        "tuDien": "HC",
        "chuDauTu": "SoXayDung",
        "loaiCot": "DC-06",
        "chieuCao": 3,
        "tuDien2": "Hàng Cháo",
        "diaChiLapD": "",
        "longitude": 105.83294827296177,
        "latitude": 21.028775781822585,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1138,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82958062541525,
          21.031691792002245
        ]
      },
      "properties": {
        "tenDoiTuon": "01",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 1,
        "maXaPhuong": 28,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 2 Giảng Võ",
        "tuDien": "TBLLB",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 10,
        "tuDien2": "Thiết Bị Lạnh Long Biên",
        "diaChiLapD": "",
        "longitude": 105.82958062541525,
        "latitude": 21.031691792002245,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1139,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82924534929478,
          21.03178442242773
        ]
      },
      "properties": {
        "tenDoiTuon": "02",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 1,
        "maXaPhuong": 28,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 2 Giảng Võ",
        "tuDien": "TBLLB",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 10,
        "tuDien2": "Thiết Bị Lạnh Long Biên",
        "diaChiLapD": "",
        "longitude": 105.82924534929478,
        "latitude": 21.03178442242773,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1140,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82893421304398,
          21.031882059840193
        ]
      },
      "properties": {
        "tenDoiTuon": "03",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 1,
        "maXaPhuong": 28,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 2 Giảng Võ",
        "tuDien": "TBLLB",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 10,
        "tuDien2": "TBL Long Biên",
        "diaChiLapD": "",
        "longitude": 105.82893421304398,
        "latitude": 21.031882059840193,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1141,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82901736152891,
          21.031491509805672
        ]
      },
      "properties": {
        "tenDoiTuon": "04",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 1,
        "maXaPhuong": 28,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 2 Giảng Võ",
        "tuDien": "TBLLB",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 10,
        "tuDien2": "Thiết Bị Lạnh Long Biên",
        "diaChiLapD": "",
        "longitude": 105.82901736152891,
        "latitude": 21.031491509805672,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1142,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82911928546801,
          21.031306248533784
        ]
      },
      "properties": {
        "tenDoiTuon": "05",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 1,
        "maXaPhuong": 28,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 2 Giảng Võ",
        "tuDien": "TBLLB",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 10,
        "tuDien2": "Thiết Bị Lạnh Long Biên",
        "diaChiLapD": "",
        "longitude": 105.82911928546801,
        "latitude": 21.031306248533784,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1143,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82901736152891,
          21.031196093074122
        ]
      },
      "properties": {
        "tenDoiTuon": "06",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 1,
        "maXaPhuong": 28,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 2 Giảng Võ",
        "tuDien": "TBLLB",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 10,
        "tuDien2": "Thiết Bị Lạnh Long Biên",
        "diaChiLapD": "",
        "longitude": 105.82901736152891,
        "latitude": 21.031196093074122,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1144,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82886984003123,
          21.031336290917825
        ]
      },
      "properties": {
        "tenDoiTuon": "07",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 1,
        "maXaPhuong": 28,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 2 Giảng Võ",
        "tuDien": "TBLLB",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 10,
        "tuDien2": "Thiết Bị Lạnh Long Biên",
        "diaChiLapD": "",
        "longitude": 105.82886984003123,
        "latitude": 21.031336290917825,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1145,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82865258109635,
          21.031193589539498
        ]
      },
      "properties": {
        "tenDoiTuon": "08",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 1,
        "maXaPhuong": 28,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 2 Giảng Võ",
        "tuDien": "TBLLB",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 10,
        "tuDien2": "Thiết Bị Lạnh Long Biên",
        "diaChiLapD": "",
        "longitude": 105.82865258109635,
        "latitude": 21.031193589539498,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1146,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8283333982313,
          21.031105965819048
        ]
      },
      "properties": {
        "tenDoiTuon": "09",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 1,
        "maXaPhuong": 28,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 2 Giảng Võ",
        "tuDien": "TBLLB",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 10,
        "tuDien2": "Thiết Bị Lạnh Long Biên",
        "diaChiLapD": "",
        "longitude": 105.8283333982313,
        "latitude": 21.031105965819048,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1147,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82898249281028,
          21.031619189737075
        ]
      },
      "properties": {
        "tenDoiTuon": "10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 1,
        "maXaPhuong": 28,
        "loaiChaoCh": "Trong ngõ - đèn chùm",
        "tenDuongPh": "Ngõ 2 Giảng Võ",
        "tuDien": "TBLLB",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "DC-05",
        "chieuCao": 3,
        "tuDien2": "TBL Long Biên",
        "diaChiLapD": "",
        "longitude": 105.82898249281028,
        "latitude": 21.031619189737075,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1148,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83222609776641,
          21.008161799852427
        ]
      },
      "properties": {
        "tenDoiTuon": "C5TT 01",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Đặng Văn Ngữ",
        "tuDien": "C5TT",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "C5 Trung Tự",
        "diaChiLapD": "",
        "longitude": 105.83222609776641,
        "latitude": 21.008161799852427,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1149,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83247084929923,
          21.007895758025033
        ]
      },
      "properties": {
        "tenDoiTuon": "02",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Đặng Văn Ngữ",
        "tuDien": "C5TT",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "C5 Trung Tự",
        "diaChiLapD": "",
        "longitude": 105.83247084929923,
        "latitude": 21.007895758025033,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1150,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83270151927283,
          21.00765788492685
        ]
      },
      "properties": {
        "tenDoiTuon": "03",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Đặng Văn Ngữ",
        "tuDien": "C5TT",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "C5 Trung Tự",
        "diaChiLapD": "",
        "longitude": 105.83270151927283,
        "latitude": 21.00765788492685,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1151,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83287251013765,
          21.007478227886455
        ]
      },
      "properties": {
        "tenDoiTuon": "04",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Phạm Ngọc Thạch",
        "tuDien": "C5TT",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "C5 Trung Tự",
        "diaChiLapD": "",
        "longitude": 105.83287251013765,
        "latitude": 21.007478227886455,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1152,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8321583719805,
          21.006896688459985
        ]
      },
      "properties": {
        "tenDoiTuon": "07",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Phạm Ngọc Thạch",
        "tuDien": "C5TT",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 7,
        "tuDien2": "C5 Trung Tự",
        "diaChiLapD": "",
        "longitude": 105.8321583719805,
        "latitude": 21.006896688459985,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1153,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83287251013765,
          21.007478227886455
        ]
      },
      "properties": {
        "tenDoiTuon": "C5TT 04",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Phạm Ngọc Thạch",
        "tuDien": "C5TT",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "C5 Trung Tự",
        "diaChiLapD": "",
        "longitude": 105.83287251013765,
        "latitude": 21.007478227886455,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1154,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83265323950653,
          21.007304830472602
        ]
      },
      "properties": {
        "tenDoiTuon": "05",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Phạm Ngọc Thạch",
        "tuDien": "C5TT",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 7,
        "tuDien2": "C5 Trung Tự",
        "diaChiLapD": "",
        "longitude": 105.83265323950653,
        "latitude": 21.007304830472602,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1155,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83239038303103,
          21.00708448400615
        ]
      },
      "properties": {
        "tenDoiTuon": "06",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Phạm Ngọc Thạch",
        "tuDien": "C5TT",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 7,
        "tuDien2": "C5 Trung Tự",
        "diaChiLapD": "",
        "longitude": 105.83239038303103,
        "latitude": 21.00708448400615,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1156,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83193038420565,
          21.00670889268415
        ]
      },
      "properties": {
        "tenDoiTuon": "08",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Phạm Ngọc Thạch",
        "tuDien": "C5TT",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 7,
        "tuDien2": "C5 Trung Tự",
        "diaChiLapD": "",
        "longitude": 105.83193038420565,
        "latitude": 21.00670889268415,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1157,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83167825656112,
          21.006513584825885
        ]
      },
      "properties": {
        "tenDoiTuon": "09",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Phạm Ngọc Thạch",
        "tuDien": "C5TT",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 7,
        "tuDien2": "C5 Trung Tự",
        "diaChiLapD": "",
        "longitude": 105.83167825656112,
        "latitude": 21.006513584825885,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1158,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83140064792508,
          21.006102936696315
        ]
      },
      "properties": {
        "tenDoiTuon": "C5TT 11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Đông Tác",
        "tuDien": "C5TT",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "C5 Trung Tự",
        "diaChiLapD": "",
        "longitude": 105.83140064792508,
        "latitude": 21.006102936696315,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1159,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8314046712367,
          21.00628071742926
        ]
      },
      "properties": {
        "tenDoiTuon": "10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Đường phố - đèn pha",
        "tenDuongPh": "Phạm Ngọc Thạch",
        "tuDien": "C5TT",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 15,
        "tuDien2": "C5 Trung Tự",
        "diaChiLapD": "",
        "longitude": 105.8314046712367,
        "latitude": 21.00628071742926,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1160,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83245341496685,
          21.00673393213542
        ]
      },
      "properties": {
        "tenDoiTuon": "7-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 101 Phạm Ngọc Thạch",
        "tuDien": "C5TT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "C5 Trung Tự",
        "diaChiLapD": "",
        "longitude": 105.83245341496685,
        "latitude": 21.00673393213542,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1161,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83236490206647,
          21.006981822469175
        ]
      },
      "properties": {
        "tenDoiTuon": "7-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 101 Phạm Ngọc Thạch",
        "tuDien": "C5TT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "C5 Trung Tự",
        "diaChiLapD": "",
        "longitude": 105.83236490206647,
        "latitude": 21.006981822469175,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1162,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83252315239511,
          21.006513584825885
        ]
      },
      "properties": {
        "tenDoiTuon": "7-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 101 Phạm Ngọc Thạch",
        "tuDien": "C5TT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "C5 Trung Tự",
        "diaChiLapD": "",
        "longitude": 105.83252315239511,
        "latitude": 21.006513584825885,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1163,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83281819539046,
          21.006420938700458
        ]
      },
      "properties": {
        "tenDoiTuon": "7-8",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 26 Đông Tác",
        "tuDien": "C5TT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "C5 Trung Tự",
        "diaChiLapD": "",
        "longitude": 105.83281819539046,
        "latitude": 21.006420938700458,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1164,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83287988619547,
          21.00617805534345
        ]
      },
      "properties": {
        "tenDoiTuon": "7-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 26 Đông Tác",
        "tuDien": "C5TT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "C5 Trung Tự",
        "diaChiLapD": "",
        "longitude": 105.83287988619547,
        "latitude": 21.00617805534345,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1165,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83300326781449,
          21.006220622558306
        ]
      },
      "properties": {
        "tenDoiTuon": "C5TT 7-7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 26 Đông Tác",
        "tuDien": "C5TT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "C5 Trung Tự",
        "diaChiLapD": "",
        "longitude": 105.83300326781449,
        "latitude": 21.006220622558306,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1166,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83300326781449,
          21.006425946600718
        ]
      },
      "properties": {
        "tenDoiTuon": "C5TT 7-9",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 26 Đông Tác",
        "tuDien": "C5TT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "C5 Trung Tự",
        "diaChiLapD": "",
        "longitude": 105.83300326781449,
        "latitude": 21.006425946600718,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1167,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83221134560587,
          21.007189023414476
        ]
      },
      "properties": {
        "tenDoiTuon": "12",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 80 Phạm Ngọc Thạch",
        "tuDien": "C5TT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "C5 Trung Tự",
        "diaChiLapD": "",
        "longitude": 105.83221134560587,
        "latitude": 21.007189023414476,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1168,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83208259957136,
          21.0073567871133
        ]
      },
      "properties": {
        "tenDoiTuon": "13",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 80 Phạm Ngọc Thạch",
        "tuDien": "C5TT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "C5 Trung Tự",
        "diaChiLapD": "",
        "longitude": 105.83208259957136,
        "latitude": 21.0073567871133,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1169,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83198872225546,
          21.007594660690675
        ]
      },
      "properties": {
        "tenDoiTuon": "14",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 80 Phạm Ngọc Thạch",
        "tuDien": "C5TT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "C5 Trung Tự",
        "diaChiLapD": "",
        "longitude": 105.83198872225546,
        "latitude": 21.007594660690675,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1170,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83179023878375,
          21.0078125024773
        ]
      },
      "properties": {
        "tenDoiTuon": "15",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 80 Phạm Ngọc Thạch",
        "tuDien": "C5TT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "C5 Trung Tự",
        "diaChiLapD": "",
        "longitude": 105.83179023878375,
        "latitude": 21.0078125024773,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1171,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83202694369334,
          21.007193298004918
        ]
      },
      "properties": {
        "tenDoiTuon": "16",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 82 Phạm Ngọc Thạch",
        "tuDien": "C5TT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 8,
        "tuDien2": "C5 Trung Tự",
        "diaChiLapD": "",
        "longitude": 105.83202694369334,
        "latitude": 21.007193298004918,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1172,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83188746882787,
          21.0073911089078
        ]
      },
      "properties": {
        "tenDoiTuon": "17",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 82 Phạm Ngọc Thạch",
        "tuDien": "C5TT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 8,
        "tuDien2": "C5 Trung Tự",
        "diaChiLapD": "",
        "longitude": 105.83188746882787,
        "latitude": 21.0073911089078,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1173,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83323259668424,
          21.028492877606478
        ]
      },
      "properties": {
        "tenDoiTuon": "13-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 12 Cát Linh",
        "tuDien": "HC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Hàng Cháo",
        "diaChiLapD": "",
        "longitude": 105.83323259668424,
        "latitude": 21.028492877606478,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1174,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8330609353079,
          21.028557970659868
        ]
      },
      "properties": {
        "tenDoiTuon": "13-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 12 Cát Linh",
        "tuDien": "HC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Hàng Cháo",
        "diaChiLapD": "",
        "longitude": 105.8330609353079,
        "latitude": 21.028557970659868,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1175,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83386325106927,
          21.02639142073364
        ]
      },
      "properties": {
        "tenDoiTuon": "4-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 47 Tôn Đức Thắng",
        "tuDien": "PVT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 5,
        "tuDien2": "Phan Văn Trị",
        "diaChiLapD": "",
        "longitude": 105.83386325106927,
        "latitude": 21.02639142073364,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1176,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83168630316638,
          21.0088823013509
        ]
      },
      "properties": {
        "tenDoiTuon": "20",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Đặng Văn Ngữ",
        "tuDien": "TT2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Trung Tự 2",
        "diaChiLapD": "",
        "longitude": 105.83168630316638,
        "latitude": 21.0088823013509,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1177,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83184187128727,
          21.0085918476485
        ]
      },
      "properties": {
        "tenDoiTuon": "21",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Đặng Văn Ngữ",
        "tuDien": "TT2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Trung Tự 2",
        "diaChiLapD": "",
        "longitude": 105.83184187128727,
        "latitude": 21.0085918476485,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1178,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83158437922727,
          21.008456636248788
        ]
      },
      "properties": {
        "tenDoiTuon": "21-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 69 Đặng Văn Ngữ",
        "tuDien": "TT2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Trung Tự 2",
        "diaChiLapD": "",
        "longitude": 105.83158437922727,
        "latitude": 21.008456636248788,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1179,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83140467121872,
          21.008301393379835
        ]
      },
      "properties": {
        "tenDoiTuon": "TT2 21-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 69 Đặng Văn Ngữ",
        "tuDien": "TT2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Trung Tự 2",
        "diaChiLapD": "",
        "longitude": 105.83140467121872,
        "latitude": 21.008301393379835,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1180,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83204303696675,
          21.008374006999816
        ]
      },
      "properties": {
        "tenDoiTuon": "TT2 22",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Đặng Văn Ngữ",
        "tuDien": "TT2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Trung Tự 2",
        "diaChiLapD": "",
        "longitude": 105.83204303696675,
        "latitude": 21.008374006999816,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1181,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83162997677684,
          21.00825882675861
        ]
      },
      "properties": {
        "tenDoiTuon": "TT2 22-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 47 Đặng Văn Ngữ",
        "tuDien": "TT2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Trung Tự 2",
        "diaChiLapD": "",
        "longitude": 105.83162997677684,
        "latitude": 21.00825882675861,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1182,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83183918907953,
          21.00810107976227
        ]
      },
      "properties": {
        "tenDoiTuon": "22-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 47 Đặng Văn Ngữ",
        "tuDien": "TT2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Trung Tự 2",
        "diaChiLapD": "",
        "longitude": 105.83183918907953,
        "latitude": 21.00810107976227,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1183,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83195452406633,
          21.007993411081497
        ]
      },
      "properties": {
        "tenDoiTuon": "TT2 22-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 47 Đặng Văn Ngữ",
        "tuDien": "TT2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Trung Tự 2",
        "diaChiLapD": "",
        "longitude": 105.83195452406633,
        "latitude": 21.007993411081497,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1184,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83230857565897,
          21.008651941564413
        ]
      },
      "properties": {
        "tenDoiTuon": "23",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 4 Đặng Văn Ngữ",
        "tuDien": "TT2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Trung Tự 2",
        "diaChiLapD": "",
        "longitude": 105.83230857565897,
        "latitude": 21.008651941564413,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1185,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83251510575391,
          21.00882721534744
        ]
      },
      "properties": {
        "tenDoiTuon": "24",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 4 Đặng Văn Ngữ",
        "tuDien": "TT2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Trung Tự 2",
        "diaChiLapD": "",
        "longitude": 105.83251510575391,
        "latitude": 21.00882721534744,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1186,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83273236467983,
          21.00901000064445
        ]
      },
      "properties": {
        "tenDoiTuon": "25",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 4 Đặng Văn Ngữ",
        "tuDien": "TT2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Trung Tự 2",
        "diaChiLapD": "",
        "longitude": 105.83273236467983,
        "latitude": 21.00901000064445,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1187,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83292548372708,
          21.008817199707952
        ]
      },
      "properties": {
        "tenDoiTuon": "27",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 4 Đặng Văn Ngữ",
        "tuDien": "TT2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 7,
        "tuDien2": "Trung Tự 2",
        "diaChiLapD": "",
        "longitude": 105.83292548372708,
        "latitude": 21.008817199707952,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1188,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83309178068792,
          21.00863942200067
        ]
      },
      "properties": {
        "tenDoiTuon": "28",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 4 Đặng Văn Ngữ",
        "tuDien": "TT2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 7,
        "tuDien2": "Trung Tự 2",
        "diaChiLapD": "",
        "longitude": 105.83309178068792,
        "latitude": 21.00863942200067,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1189,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83321516230694,
          21.008421581421437
        ]
      },
      "properties": {
        "tenDoiTuon": "TT2 29",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 4 Đặng Văn Ngữ",
        "tuDien": "TT2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 7,
        "tuDien2": "Trung Tự 2",
        "diaChiLapD": "",
        "longitude": 105.83321516230694,
        "latitude": 21.008421581421437,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1190,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83307032301703,
          21.00921782475196
        ]
      },
      "properties": {
        "tenDoiTuon": "TT2 26",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 4 Đặng Văn Ngữ",
        "tuDien": "TT2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Trung Tự 2",
        "diaChiLapD": "",
        "longitude": 105.83307032301703,
        "latitude": 21.00921782475196,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1191,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82582137536768,
          21.020645201027005
        ]
      },
      "properties": {
        "tenDoiTuon": "25",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - đèn pha",
        "tenDuongPh": "Ô Chợ Dừa",
        "tuDien": "HC-OCD",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 14,
        "tuDien2": "Hoàng Cầu - Ô Chợ Dừa",
        "diaChiLapD": "",
        "longitude": 105.82582137536768,
        "latitude": 21.020645201027005,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1192,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8293887133534,
          21.01854206868524
        ]
      },
      "properties": {
        "tenDoiTuon": "13",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - đèn pha",
        "tenDuongPh": "Ô Chợ Dừa",
        "tuDien": "HC-OCD",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 25,
        "tuDien2": "Hoàng Cầu - Ô Chợ Dừa",
        "diaChiLapD": "",
        "longitude": 105.8293887133534,
        "latitude": 21.01854206868524,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1193,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82608959626764,
          21.020510000553934
        ]
      },
      "properties": {
        "tenDoiTuon": "26",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Ô Chợ Dừa",
        "tuDien": "HC-OCD",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Hoàng Cầu - Ô Chợ Dừa",
        "diaChiLapD": "",
        "longitude": 105.82608959626764,
        "latitude": 21.020510000553934,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1194,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82656166505481,
          21.020239599240934
        ]
      },
      "properties": {
        "tenDoiTuon": "27",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Ô Chợ Dừa",
        "tuDien": "HC-OCD",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Hoàng Cầu - Ô Chợ Dừa",
        "diaChiLapD": "",
        "longitude": 105.82656166505481,
        "latitude": 21.020239599240934,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1195,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82703373384199,
          21.01996919743752
        ]
      },
      "properties": {
        "tenDoiTuon": "28",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Ô Chợ Dừa",
        "tuDien": "HC-OCD",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Hoàng Cầu - Ô Chợ Dừa",
        "diaChiLapD": "",
        "longitude": 105.82703373384199,
        "latitude": 21.01996919743752,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1196,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83189149219338,
          21.02253924661164
        ]
      },
      "properties": {
        "tenDoiTuon": "01",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Tôn Đức Thắng",
        "tuDien": "HB1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Hàng Bột 1",
        "diaChiLapD": "",
        "longitude": 105.83189149219338,
        "latitude": 21.02253924661164,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1197,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83201085048282,
          21.02282842131457
        ]
      },
      "properties": {
        "tenDoiTuon": "02",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Tôn Đức Thắng",
        "tuDien": "HB1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Hàng Bột 1",
        "diaChiLapD": "",
        "longitude": 105.83201085048282,
        "latitude": 21.02282842131457,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1198,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83215852100301,
          21.02311787364309
        ]
      },
      "properties": {
        "tenDoiTuon": "03",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Tôn Đức Thắng",
        "tuDien": "HB1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Hàng Bột 1",
        "diaChiLapD": "",
        "longitude": 105.83215852100301,
        "latitude": 21.02311787364309,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1199,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83227236587241,
          21.02336045123002
        ]
      },
      "properties": {
        "tenDoiTuon": "04",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Tôn Đức Thắng",
        "tuDien": "HB1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Hàng Bột 1",
        "diaChiLapD": "",
        "longitude": 105.83227236587241,
        "latitude": 21.02336045123002,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1200,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83242554981264,
          21.023696498428755
        ]
      },
      "properties": {
        "tenDoiTuon": "05",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Tôn Đức Thắng",
        "tuDien": "HB1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Hàng Bột 1",
        "diaChiLapD": "",
        "longitude": 105.83242554981264,
        "latitude": 21.023696498428755,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1201,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83258350212321,
          21.024051461368924
        ]
      },
      "properties": {
        "tenDoiTuon": "06",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Tôn Đức Thắng",
        "tuDien": "HB1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Hàng Bột 1",
        "diaChiLapD": "",
        "longitude": 105.83258350212321,
        "latitude": 21.024051461368924,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1202,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83271493036543,
          21.024334374015726
        ]
      },
      "properties": {
        "tenDoiTuon": "07",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Tôn Đức Thắng",
        "tuDien": "HB1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Hàng Bột 1",
        "diaChiLapD": "",
        "longitude": 105.83271493036543,
        "latitude": 21.024334374015726,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1203,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83282221872902,
          21.02458724238721
        ]
      },
      "properties": {
        "tenDoiTuon": "08",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Tôn Đức Thắng",
        "tuDien": "HB1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Hàng Bột 1",
        "diaChiLapD": "",
        "longitude": 105.83282221872902,
        "latitude": 21.02458724238721,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1204,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8329596074319,
          21.024853741262422
        ]
      },
      "properties": {
        "tenDoiTuon": "09",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Tôn Đức Thắng",
        "tuDien": "HB1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Hàng Bột 1",
        "diaChiLapD": "",
        "longitude": 105.8329596074319,
        "latitude": 21.024853741262422,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1205,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83309312183673,
          21.025143050567124
        ]
      },
      "properties": {
        "tenDoiTuon": "10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Tôn Đức Thắng",
        "tuDien": "HB1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Hàng Bột 1",
        "diaChiLapD": "",
        "longitude": 105.83309312183673,
        "latitude": 21.025143050567124,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1206,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83292414266812,
          21.02519813054407
        ]
      },
      "properties": {
        "tenDoiTuon": "11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Tôn Đức Thắng",
        "tuDien": "HB1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Hàng Bột 1",
        "diaChiLapD": "",
        "longitude": 105.83292414266812,
        "latitude": 21.02519813054407,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1207,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83279301244997,
          21.0249107686216
        ]
      },
      "properties": {
        "tenDoiTuon": "12",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Tôn Đức Thắng",
        "tuDien": "HB1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Hàng Bột 1",
        "diaChiLapD": "",
        "longitude": 105.83279301244997,
        "latitude": 21.0249107686216,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1208,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8327122481577,
          21.024709920947224
        ]
      },
      "properties": {
        "tenDoiTuon": "13",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Tôn Đức Thắng",
        "tuDien": "HB1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Hàng Bột 1",
        "diaChiLapD": "",
        "longitude": 105.8327122481577,
        "latitude": 21.024709920947224,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1209,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83255098031616,
          21.02435096069195
        ]
      },
      "properties": {
        "tenDoiTuon": "14",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Tôn Đức Thắng",
        "tuDien": "HB1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Hàng Bột 1",
        "diaChiLapD": "",
        "longitude": 105.83255098031616,
        "latitude": 21.02435096069195,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1210,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83241016434681,
          21.024043011536275
        ]
      },
      "properties": {
        "tenDoiTuon": "15",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Tôn Đức Thắng",
        "tuDien": "HB1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Hàng Bột 1",
        "diaChiLapD": "",
        "longitude": 105.83241016434681,
        "latitude": 21.024043011536275,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1211,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83228175359052,
          21.02376354086893
        ]
      },
      "properties": {
        "tenDoiTuon": "16",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Tôn Đức Thắng",
        "tuDien": "HB1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Hàng Bột 1",
        "diaChiLapD": "",
        "longitude": 105.83228175359052,
        "latitude": 21.02376354086893,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1212,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83212216217598,
          21.02341302820115
        ]
      },
      "properties": {
        "tenDoiTuon": "17",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Tôn Đức Thắng",
        "tuDien": "HB1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Hàng Bột 1",
        "diaChiLapD": "",
        "longitude": 105.83212216217598,
        "latitude": 21.02341302820115,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1213,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83200623114101,
          21.02318658545383
        ]
      },
      "properties": {
        "tenDoiTuon": "18",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Tôn Đức Thắng",
        "tuDien": "HB1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Hàng Bột 1",
        "diaChiLapD": "",
        "longitude": 105.83200623114101,
        "latitude": 21.02318658545383,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1214,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83187510092286,
          21.022899219653777
        ]
      },
      "properties": {
        "tenDoiTuon": "19",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Tôn Đức Thắng",
        "tuDien": "HB1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Hàng Bột 1",
        "diaChiLapD": "",
        "longitude": 105.83187510092286,
        "latitude": 21.022899219653777,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1215,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83174397070471,
          21.02262437169067
        ]
      },
      "properties": {
        "tenDoiTuon": "20",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Tôn Đức Thắng",
        "tuDien": "HB1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Hàng Bột 1",
        "diaChiLapD": "",
        "longitude": 105.83174397070471,
        "latitude": 21.02262437169067,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1216,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83208327012781,
          21.022510454293844
        ]
      },
      "properties": {
        "tenDoiTuon": "1-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ163 Tôn Đức Thắng",
        "tuDien": "HB1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Hàng Bột 1",
        "diaChiLapD": "",
        "longitude": 105.83208327012781,
        "latitude": 21.022510454293844,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1217,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83222006278552,
          21.022410307060852
        ]
      },
      "properties": {
        "tenDoiTuon": "1-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ163 Tôn Đức Thắng",
        "tuDien": "HB1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Hàng Bột 1",
        "diaChiLapD": "",
        "longitude": 105.83222006278552,
        "latitude": 21.022410307060852,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1218,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83214361984689,
          21.02233895211539
        ]
      },
      "properties": {
        "tenDoiTuon": "1-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ163 Tôn Đức Thắng",
        "tuDien": "HB1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Hàng Bột 1",
        "diaChiLapD": "",
        "longitude": 105.83214361984689,
        "latitude": 21.02233895211539,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1219,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83229516463823,
          21.022422825468656
        ]
      },
      "properties": {
        "tenDoiTuon": "1-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ163 Tôn Đức Thắng",
        "tuDien": "HB1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "Hàng Bột 1",
        "diaChiLapD": "",
        "longitude": 105.83229516463823,
        "latitude": 21.022422825468656,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1220,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83233137447866,
          21.022256330558157
        ]
      },
      "properties": {
        "tenDoiTuon": "1-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ163 Tôn Đức Thắng",
        "tuDien": "HB1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "Hàng Bột 1",
        "diaChiLapD": "",
        "longitude": 105.83233137447866,
        "latitude": 21.022256330558157,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1221,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8323176281348,
          21.022748616664888
        ]
      },
      "properties": {
        "tenDoiTuon": "2-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Huy Văn",
        "tuDien": "HB1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Hàng Bột 1",
        "diaChiLapD": "",
        "longitude": 105.8323176281348,
        "latitude": 21.022748616664888,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1222,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8325566800278,
          21.022596831228856
        ]
      },
      "properties": {
        "tenDoiTuon": "2-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Huy Văn",
        "tuDien": "HB1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": "",
        "tuDien2": "Hàng Bột 1",
        "diaChiLapD": "",
        "longitude": 105.8325566800278,
        "latitude": 21.022596831228856,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1223,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83278645593816,
          21.022403213295377
        ]
      },
      "properties": {
        "tenDoiTuon": "2-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Huy Văn",
        "tuDien": "HB1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": "",
        "tuDien2": "Hàng Bột 1",
        "diaChiLapD": "",
        "longitude": 105.83278645593816,
        "latitude": 21.022403213295377,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1224,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83266128618367,
          21.02226884897887
        ]
      },
      "properties": {
        "tenDoiTuon": "2-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Huy Văn",
        "tuDien": "HB1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": "",
        "tuDien2": "Hàng Bột 1",
        "diaChiLapD": "",
        "longitude": 105.83266128618367,
        "latitude": 21.02226884897887,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1225,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83230187017554,
          21.022869731941213
        ]
      },
      "properties": {
        "tenDoiTuon": "2-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Huy Văn",
        "tuDien": "HB1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "Hàng Bột 1",
        "diaChiLapD": "",
        "longitude": 105.83230187017554,
        "latitude": 21.022869731941213,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1226,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82575163793044,
          21.020474948559425
        ]
      },
      "properties": {
        "tenDoiTuon": "01",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Ô Chợ Dừa",
        "tuDien": "HC-OCD",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Hoàng Cầu - Ô Chợ Dừa",
        "diaChiLapD": "",
        "longitude": 105.82575163793044,
        "latitude": 21.020474948559425,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1227,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82620221069303,
          21.02024114780646
        ]
      },
      "properties": {
        "tenDoiTuon": "02",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Ô Chợ Dừa",
        "tuDien": "HC-OCD",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Hoàng Cầu - Ô Chợ Dừa",
        "diaChiLapD": "",
        "longitude": 105.82620221069303,
        "latitude": 21.02024114780646,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1228,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82669577550479,
          21.019956678823693
        ]
      },
      "properties": {
        "tenDoiTuon": "03",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Ô Chợ Dừa",
        "tuDien": "HC-OCD",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Hoàng Cầu - Ô Chợ Dừa",
        "diaChiLapD": "",
        "longitude": 105.82669577550479,
        "latitude": 21.019956678823693,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1229,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82716784429196,
          21.019697543280277
        ]
      },
      "properties": {
        "tenDoiTuon": "04",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Ô Chợ Dừa",
        "tuDien": "HC-OCD",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Hoàng Cầu - Ô Chợ Dừa",
        "diaChiLapD": "",
        "longitude": 105.82716784429196,
        "latitude": 21.019697543280277,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1230,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82763991307914,
          21.01943840728654
        ]
      },
      "properties": {
        "tenDoiTuon": "05",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Ô Chợ Dừa",
        "tuDien": "HC-OCD",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Hoàng Cầu - Ô Chợ Dừa",
        "diaChiLapD": "",
        "longitude": 105.82763991307914,
        "latitude": 21.01943840728654,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1231,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82786521863726,
          21.01930320571925
        ]
      },
      "properties": {
        "tenDoiTuon": "06",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Ô Chợ Dừa",
        "tuDien": "HC-OCD",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Hoàng Cầu - Ô Chợ Dừa",
        "diaChiLapD": "",
        "longitude": 105.82786521863726,
        "latitude": 21.01930320571925,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1232,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82813359778444,
          21.019142586581896
        ]
      },
      "properties": {
        "tenDoiTuon": "07",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Ô Chợ Dừa",
        "tuDien": "HC-OCD",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Hoàng Cầu - Ô Chợ Dừa",
        "diaChiLapD": "",
        "longitude": 105.82813359778444,
        "latitude": 21.019142586581896,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1233,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82840166044018,
          21.01898773491991
        ]
      },
      "properties": {
        "tenDoiTuon": "08",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Ô Chợ Dừa",
        "tuDien": "HC-OCD",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Hoàng Cầu - Ô Chợ Dừa",
        "diaChiLapD": "",
        "longitude": 105.82840166044018,
        "latitude": 21.01898773491991,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1234,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82862028406367,
          21.018859166505646
        ]
      },
      "properties": {
        "tenDoiTuon": "09",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Ô Chợ Dừa",
        "tuDien": "HC-OCD",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Hoàng Cầu - Ô Chợ Dừa",
        "diaChiLapD": "",
        "longitude": 105.82862028406367,
        "latitude": 21.018859166505646,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1235,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8289381022431,
          21.018672263453162
        ]
      },
      "properties": {
        "tenDoiTuon": "10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Ô Chợ Dừa",
        "tuDien": "HC-OCD",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Hoàng Cầu - Ô Chợ Dừa",
        "diaChiLapD": "",
        "longitude": 105.8289381022431,
        "latitude": 21.018672263453162,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1236,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82912065069165,
          21.018502880520618
        ]
      },
      "properties": {
        "tenDoiTuon": "11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Ô Chợ Dừa",
        "tuDien": "HC-OCD",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Hoàng Cầu - Ô Chợ Dừa",
        "diaChiLapD": "",
        "longitude": 105.82912065069165,
        "latitude": 21.018502880520618,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1237,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82953891705878,
          21.018862547909926
        ]
      },
      "properties": {
        "tenDoiTuon": "14",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Ô Chợ Dừa",
        "tuDien": "HC-OCD",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Hoàng Cầu - Ô Chợ Dừa",
        "diaChiLapD": "",
        "longitude": 105.82953891705878,
        "latitude": 21.018862547909926,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1238,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82917413663519,
          21.01900275735391
        ]
      },
      "properties": {
        "tenDoiTuon": "15",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Ô Chợ Dừa",
        "tuDien": "HC-OCD",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Hoàng Cầu - Ô Chợ Dừa",
        "diaChiLapD": "",
        "longitude": 105.82917413663519,
        "latitude": 21.01900275735391,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1239,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82887372922437,
          21.019178018973786
        ]
      },
      "properties": {
        "tenDoiTuon": "16",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Ô Chợ Dừa",
        "tuDien": "HC-OCD",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Hoàng Cầu - Ô Chợ Dừa",
        "diaChiLapD": "",
        "longitude": 105.82887372922437,
        "latitude": 21.019178018973786,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1240,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82859477948443,
          21.019323235589294
        ]
      },
      "properties": {
        "tenDoiTuon": "17",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Ô Chợ Dừa",
        "tuDien": "HC-OCD",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Hoàng Cầu - Ô Chợ Dừa",
        "diaChiLapD": "",
        "longitude": 105.82859477948443,
        "latitude": 21.019323235589294,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1241,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8283426518399,
          21.019463444600152
        ]
      },
      "properties": {
        "tenDoiTuon": "18",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Ô Chợ Dừa",
        "tuDien": "HC-OCD",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Hoàng Cầu - Ô Chợ Dừa",
        "diaChiLapD": "",
        "longitude": 105.8283426518399,
        "latitude": 21.019463444600152,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1242,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82807979535544,
          21.019608660936836
        ]
      },
      "properties": {
        "tenDoiTuon": "19",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Ô Chợ Dừa",
        "tuDien": "HC-OCD",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Hoàng Cầu - Ô Chợ Dừa",
        "diaChiLapD": "",
        "longitude": 105.82807979535544,
        "latitude": 21.019608660936836,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1243,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82783839654188,
          21.01975387713208
        ]
      },
      "properties": {
        "tenDoiTuon": "20",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Ô Chợ Dừa",
        "tuDien": "HC-OCD",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Hoàng Cầu - Ô Chợ Dừa",
        "diaChiLapD": "",
        "longitude": 105.82783839654188,
        "latitude": 21.01975387713208,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1244,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82737571548853,
          21.020016768160545
        ]
      },
      "properties": {
        "tenDoiTuon": "21",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Ô Chợ Dừa",
        "tuDien": "HC-OCD",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Hoàng Cầu - Ô Chợ Dừa",
        "diaChiLapD": "",
        "longitude": 105.82737571548853,
        "latitude": 21.020016768160545,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1245,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82691303443521,
          21.020279658725507
        ]
      },
      "properties": {
        "tenDoiTuon": "22",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Ô Chợ Dừa",
        "tuDien": "HC-OCD",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Hoàng Cầu - Ô Chợ Dừa",
        "diaChiLapD": "",
        "longitude": 105.82691303443521,
        "latitude": 21.020279658725507,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1246,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82645035338186,
          21.020542548826946
        ]
      },
      "properties": {
        "tenDoiTuon": "23",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Ô Chợ Dừa",
        "tuDien": "HC-OCD",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Hoàng Cầu - Ô Chợ Dừa",
        "diaChiLapD": "",
        "longitude": 105.82645035338186,
        "latitude": 21.020542548826946,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1247,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82599855939773,
          21.02080130282198
        ]
      },
      "properties": {
        "tenDoiTuon": "27",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Ô Chợ Dừa",
        "tuDien": "HC-OCD",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Hoàng Cầu - Ô Chợ Dừa",
        "diaChiLapD": "",
        "longitude": 105.82599855939773,
        "latitude": 21.02080130282198,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1248,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82918486547516,
          21.018156491204216
        ]
      },
      "properties": {
        "tenDoiTuon": "12",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Ô Chợ Dừa",
        "tuDien": "HC-OCD",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Hoàng Cầu - Ô Chợ Dừa",
        "diaChiLapD": "",
        "longitude": 105.82918486547516,
        "latitude": 21.018156491204216,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1249,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83289183656454,
          21.023890847346014
        ]
      },
      "properties": {
        "tenDoiTuon": "6-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 107A Tôn Đức Thắng",
        "tuDien": "HB1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Hàng Bột 1",
        "diaChiLapD": "",
        "longitude": 105.83289183656454,
        "latitude": 21.023890847346014,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1250,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8331385997936,
          21.0238733217472
        ]
      },
      "properties": {
        "tenDoiTuon": "6-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 107A Tôn Đức Thắng",
        "tuDien": "HB1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Hàng Bột 1",
        "diaChiLapD": "",
        "longitude": 105.8331385997936,
        "latitude": 21.0238733217472,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1251,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83331830780213,
          21.02379070103948
        ]
      },
      "properties": {
        "tenDoiTuon": "6-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 107A Tôn Đức Thắng",
        "tuDien": "HB1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Hàng Bột 1",
        "diaChiLapD": "",
        "longitude": 105.83331830780213,
        "latitude": 21.02379070103948,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1252,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83245050925827,
          21.024507918542575
        ]
      },
      "properties": {
        "tenDoiTuon": "14-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 162 A Tôn Đức Thắng",
        "tuDien": "HB1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 5,
        "tuDien2": "Hàng Bột 1",
        "diaChiLapD": "",
        "longitude": 105.83245050925827,
        "latitude": 21.024507918542575,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1253,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83234992641854,
          21.02439337667324
        ]
      },
      "properties": {
        "tenDoiTuon": "14-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 162 A Tôn Đức Thắng",
        "tuDien": "HB1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 5,
        "tuDien2": "Hàng Bột 1",
        "diaChiLapD": "",
        "longitude": 105.83234992641854,
        "latitude": 21.02439337667324,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1254,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83197978157497,
          21.024506666719102
        ]
      },
      "properties": {
        "tenDoiTuon": "14-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 162 A Tôn Đức Thắng",
        "tuDien": "HB1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 5,
        "tuDien2": "Hàng Bột 1",
        "diaChiLapD": "",
        "longitude": 105.83197978157497,
        "latitude": 21.024506666719102,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1255,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83300706311572,
          21.024472867488008
        ]
      },
      "properties": {
        "tenDoiTuon": "8-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 105 Tôn Đức Thắng",
        "tuDien": "HB1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Hàng Bột 1",
        "diaChiLapD": "",
        "longitude": 105.83300706311572,
        "latitude": 21.024472867488008,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1256,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8307743521029,
          21.00799090715223
        ]
      },
      "properties": {
        "tenDoiTuon": "23",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 82 Phạm Ngọc Thạch",
        "tuDien": "C5TT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "C5 Trung Tự",
        "diaChiLapD": "",
        "longitude": 105.8307743521029,
        "latitude": 21.00799090715223,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1257,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83121423436124,
          21.007784225999917
        ]
      },
      "properties": {
        "tenDoiTuon": "24",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 82 Phạm Ngọc Thạch",
        "tuDien": "C5TT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "C5 Trung Tự",
        "diaChiLapD": "",
        "longitude": 105.83121423436124,
        "latitude": 21.007784225999917,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1258,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83058927966091,
          21.008027106742343
        ]
      },
      "properties": {
        "tenDoiTuon": "22",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 82 Phạm Ngọc Thạch",
        "tuDien": "C5TT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": "",
        "tuDien2": "C5 Trung Tự",
        "diaChiLapD": "",
        "longitude": 105.83058927966091,
        "latitude": 21.008027106742343,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1259,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83091650915814,
          21.008179845976496
        ]
      },
      "properties": {
        "tenDoiTuon": "21",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 82 Phạm Ngọc Thạch",
        "tuDien": "C5TT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 8,
        "tuDien2": "C5 Trung Tự",
        "diaChiLapD": "",
        "longitude": 105.83091650915814,
        "latitude": 21.008179845976496,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1260,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83117131901942,
          21.008079689119334
        ]
      },
      "properties": {
        "tenDoiTuon": "20",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 82 Phạm Ngọc Thạch",
        "tuDien": "C5TT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 8,
        "tuDien2": "C5 Trung Tự",
        "diaChiLapD": "",
        "longitude": 105.83117131901942,
        "latitude": 21.008079689119334,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1261,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83146099759027,
          21.007924445858134
        ]
      },
      "properties": {
        "tenDoiTuon": "19",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 82 Phạm Ngọc Thạch",
        "tuDien": "C5TT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 8,
        "tuDien2": "C5 Trung Tự",
        "diaChiLapD": "",
        "longitude": 105.83146099759027,
        "latitude": 21.007924445858134,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1262,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83164070559883,
          21.007751674942
        ]
      },
      "properties": {
        "tenDoiTuon": "18",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 82 Phạm Ngọc Thạch",
        "tuDien": "C5TT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 8,
        "tuDien2": "C5 Trung Tự",
        "diaChiLapD": "",
        "longitude": 105.83164070559883,
        "latitude": 21.007751674942,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1263,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8313671202744,
          21.0076114549214
        ]
      },
      "properties": {
        "tenDoiTuon": "18-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 82 Phạm Ngọc Thạch",
        "tuDien": "C5TT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "C5 Trung Tự",
        "diaChiLapD": "",
        "longitude": 105.8313671202744,
        "latitude": 21.0076114549214,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1264,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83054099990359,
          21.008315057626238
        ]
      },
      "properties": {
        "tenDoiTuon": "25",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 82 Phạm Ngọc Thạch",
        "tuDien": "C5TT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 8,
        "tuDien2": "C5 Trung Tự",
        "diaChiLapD": "",
        "longitude": 105.83054099990359,
        "latitude": 21.008315057626238,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1265,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83018158389548,
          21.008462788733876
        ]
      },
      "properties": {
        "tenDoiTuon": "26",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 82 Phạm Ngọc Thạch",
        "tuDien": "C5TT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 8,
        "tuDien2": "C5 Trung Tự",
        "diaChiLapD": "",
        "longitude": 105.83018158389548,
        "latitude": 21.008462788733876,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1266,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83013866854466,
          21.00824745181669
        ]
      },
      "properties": {
        "tenDoiTuon": "26-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 82 Phạm Ngọc Thạch",
        "tuDien": "C5TT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "C5 Trung Tự",
        "diaChiLapD": "",
        "longitude": 105.83013866854466,
        "latitude": 21.00824745181669,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1267,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82995359612063,
          21.008400190824425
        ]
      },
      "properties": {
        "tenDoiTuon": "27",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 82 Phạm Ngọc Thạch",
        "tuDien": "C5TT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "C5 Trung Tự",
        "diaChiLapD": "",
        "longitude": 105.82995359612063,
        "latitude": 21.008400190824425,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1268,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83156996229104,
          21.009156058822207
        ]
      },
      "properties": {
        "tenDoiTuon": "19",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Đặng Văn Ngữ",
        "tuDien": "TT2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Trung Tự 2",
        "diaChiLapD": "",
        "longitude": 105.83156996229104,
        "latitude": 21.009156058822207,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1269,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83112471559924,
          21.00904839090361
        ]
      },
      "properties": {
        "tenDoiTuon": "19-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 75 Đặng Văn Ngữ",
        "tuDien": "TT2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Trung Tự 2",
        "diaChiLapD": "",
        "longitude": 105.83112471559924,
        "latitude": 21.00904839090361,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1270,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83093696096748,
          21.008765449255975
        ]
      },
      "properties": {
        "tenDoiTuon": "TT2 19-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 75 Đặng Văn Ngữ",
        "tuDien": "TT2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Trung Tự 2",
        "diaChiLapD": "",
        "longitude": 105.83093696096748,
        "latitude": 21.008765449255975,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1271,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83094768979845,
          21.008923195550686
        ]
      },
      "properties": {
        "tenDoiTuon": "TT2 19-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 75 Đặng Văn Ngữ",
        "tuDien": "TT2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Trung Tự 2",
        "diaChiLapD": "",
        "longitude": 105.83094768979845,
        "latitude": 21.008923195550686,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1272,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83131783464648,
          21.00915856272687
        ]
      },
      "properties": {
        "tenDoiTuon": "19-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 75 Đặng Văn Ngữ",
        "tuDien": "TT2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Trung Tự 2",
        "diaChiLapD": "",
        "longitude": 105.83131783464648,
        "latitude": 21.00915856272687,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1273,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83196290587794,
          21.00890942405697
        ]
      },
      "properties": {
        "tenDoiTuon": "20-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 4A Đặng Văn Ngữ",
        "tuDien": "TT2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Trung Tự 2",
        "diaChiLapD": "",
        "longitude": 105.83196290587794,
        "latitude": 21.00890942405697,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1274,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83208360528023,
          21.009017092076704
        ]
      },
      "properties": {
        "tenDoiTuon": "TT2 20-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 4A Đặng Văn Ngữ",
        "tuDien": "TT2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 5,
        "tuDien2": "Trung Tự 2",
        "diaChiLapD": "",
        "longitude": 105.83208360528023,
        "latitude": 21.009017092076704,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1275,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83148010825083,
          21.009628044018566
        ]
      },
      "properties": {
        "tenDoiTuon": "18-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 4B Đặng Văn Ngữ",
        "tuDien": "TT2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Trung Tự 2",
        "diaChiLapD": "",
        "longitude": 105.83148010825083,
        "latitude": 21.009628044018566,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1276,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83169703203328,
          21.0106325233242
        ]
      },
      "properties": {
        "tenDoiTuon": "TT2 18-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 4C Đặng Văn Ngữ",
        "tuDien": "TT2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Trung Tự 2",
        "diaChiLapD": "",
        "longitude": 105.83169703203328,
        "latitude": 21.0106325233242,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1277,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83187439297754,
          21.009760750462956
        ]
      },
      "properties": {
        "tenDoiTuon": "TT2 18-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 4B Đặng Văn Ngữ",
        "tuDien": "TT2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Trung Tự 2",
        "diaChiLapD": "",
        "longitude": 105.83187439297754,
        "latitude": 21.009760750462956,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1278,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8314945251152,
          21.01054978799027
        ]
      },
      "properties": {
        "tenDoiTuon": "TT2 15-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 4C Đặng Văn Ngữ",
        "tuDien": "TT2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Trung Tự 2",
        "diaChiLapD": "",
        "longitude": 105.8314945251152,
        "latitude": 21.01054978799027,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1279,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83143953982055,
          21.0094605958655
        ]
      },
      "properties": {
        "tenDoiTuon": "18",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Đặng Văn Ngữ",
        "tuDien": "TT2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Trung Tự 2",
        "diaChiLapD": "",
        "longitude": 105.83143953982055,
        "latitude": 21.0094605958655,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1280,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83108280602018,
          21.010339461856
        ]
      },
      "properties": {
        "tenDoiTuon": "15",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Đặng Văn Ngữ",
        "tuDien": "TT2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Trung Tự 2",
        "diaChiLapD": "",
        "longitude": 105.83108280602018,
        "latitude": 21.010339461856,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1281,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83132420483375,
          21.009753551771233
        ]
      },
      "properties": {
        "tenDoiTuon": "17",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Đặng Văn Ngữ",
        "tuDien": "TT2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Trung Tự 2",
        "diaChiLapD": "",
        "longitude": 105.83132420483375,
        "latitude": 21.009753551771233,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1282,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83145831528373,
          21.009931328150877
        ]
      },
      "properties": {
        "tenDoiTuon": "17-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Nhà D5 TT Trung Tự",
        "tuDien": "TT2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Trung Tự 2",
        "diaChiLapD": "",
        "longitude": 105.83145831528373,
        "latitude": 21.009931328150877,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1283,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83178286257323,
          21.01005151487939
        ]
      },
      "properties": {
        "tenDoiTuon": "TT2 17-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Nhà D5 TT Trung Tự",
        "tuDien": "TT2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Trung Tự 2",
        "diaChiLapD": "",
        "longitude": 105.83178286257323,
        "latitude": 21.01005151487939,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1284,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83119814100698,
          21.010061530436023
        ]
      },
      "properties": {
        "tenDoiTuon": "16",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Đặng Văn Ngữ",
        "tuDien": "TT2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Trung Tự 2",
        "diaChiLapD": "",
        "longitude": 105.83119814100698,
        "latitude": 21.010061530436023,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1285,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83081726731898,
          21.01029188804847
        ]
      },
      "properties": {
        "tenDoiTuon": "15-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 81 Phố Đặng Văn Ngữ",
        "tuDien": "TT2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Trung Tự 2",
        "diaChiLapD": "",
        "longitude": 105.83081726731898,
        "latitude": 21.01029188804847,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1286,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83074752988176,
          21.010099088768612
        ]
      },
      "properties": {
        "tenDoiTuon": "15-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 81 Phố Đặng Văn Ngữ",
        "tuDien": "TT2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Trung Tự 2",
        "diaChiLapD": "",
        "longitude": 105.83074752988176,
        "latitude": 21.010099088768612,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1287,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83065901698133,
          21.009878746429013
        ]
      },
      "properties": {
        "tenDoiTuon": "TT2 15-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 81 Phố Đặng Văn Ngữ",
        "tuDien": "TT2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Trung Tự 2",
        "diaChiLapD": "",
        "longitude": 105.83065901698133,
        "latitude": 21.009878746429013,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1288,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83096478879868,
          21.01062740827932
        ]
      },
      "properties": {
        "tenDoiTuon": "14",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Đặng Văn Ngữ",
        "tuDien": "TT2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Trung Tự 2",
        "diaChiLapD": "",
        "longitude": 105.83096478879868,
        "latitude": 21.01062740827932,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1289,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8308843225305,
          21.010920361893756
        ]
      },
      "properties": {
        "tenDoiTuon": "TT2 13",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Đặng Văn Ngữ",
        "tuDien": "TT2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Trung Tự 2",
        "diaChiLapD": "",
        "longitude": 105.8308843225305,
        "latitude": 21.010920361893756,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1290,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83113252416057,
          21.011279471517838
        ]
      },
      "properties": {
        "tenDoiTuon": "TT2 12",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Đặng Văn Ngữ",
        "tuDien": "TT2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Trung Tự 2",
        "diaChiLapD": "",
        "longitude": 105.83113252416057,
        "latitude": 21.011279471517838,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1291,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83287720383503,
          21.0127256445467
        ]
      },
      "properties": {
        "tenDoiTuon": "TT2 05",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Đặng Văn Ngữ",
        "tuDien": "TT2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Trung Tự 2",
        "diaChiLapD": "",
        "longitude": 105.83287720383503,
        "latitude": 21.0127256445467,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1292,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83263044059699,
          21.0125328484115
        ]
      },
      "properties": {
        "tenDoiTuon": "TT2 06",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Đặng Văn Ngữ",
        "tuDien": "TT2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Trung Tự 2",
        "diaChiLapD": "",
        "longitude": 105.83263044059699,
        "latitude": 21.0125328484115,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1293,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83237026632924,
          21.012347563580672
        ]
      },
      "properties": {
        "tenDoiTuon": "TT2 07",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Đặng Văn Ngữ",
        "tuDien": "TT2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Trung Tự 2",
        "diaChiLapD": "",
        "longitude": 105.83237026632924,
        "latitude": 21.012347563580672,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1294,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83210204542927,
          21.012117209144286
        ]
      },
      "properties": {
        "tenDoiTuon": "TT2 08",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Đặng Văn Ngữ",
        "tuDien": "TT2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Trung Tự 2",
        "diaChiLapD": "",
        "longitude": 105.83210204542927,
        "latitude": 21.012117209144286,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1295,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83184187115253,
          21.011874335060757
        ]
      },
      "properties": {
        "tenDoiTuon": "TT209",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Đặng Văn Ngữ",
        "tuDien": "TT2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Trung Tự 2",
        "diaChiLapD": "",
        "longitude": 105.83184187115253,
        "latitude": 21.011874335060757,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1296,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83159779013123,
          21.011661506929713
        ]
      },
      "properties": {
        "tenDoiTuon": "TT2 10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Đặng Văn Ngữ",
        "tuDien": "TT2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Trung Tự 2",
        "diaChiLapD": "",
        "longitude": 105.83159779013123,
        "latitude": 21.011661506929713,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1297,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83134432137385,
          21.01144867849669
        ]
      },
      "properties": {
        "tenDoiTuon": "TT2 11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Đặng Văn Ngữ",
        "tuDien": "TT2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Trung Tự 2",
        "diaChiLapD": "",
        "longitude": 105.83134432137385,
        "latitude": 21.01144867849669,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1298,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8330874219454,
          21.012970082146417
        ]
      },
      "properties": {
        "tenDoiTuon": "04",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Đặng Văn Ngữ",
        "tuDien": "TT2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Trung Tự 2",
        "diaChiLapD": "",
        "longitude": 105.8330874219454,
        "latitude": 21.012970082146417,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1299,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83327115326557,
          21.013117808643393
        ]
      },
      "properties": {
        "tenDoiTuon": "03",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Đặng Văn Ngữ",
        "tuDien": "TT2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Trung Tự 2",
        "diaChiLapD": "",
        "longitude": 105.83327115326557,
        "latitude": 21.013117808643393,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1300,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83380625396161,
          21.013558483428245
        ]
      },
      "properties": {
        "tenDoiTuon": "01",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Đặng Văn Ngữ",
        "tuDien": "TT2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Trung Tự 2",
        "diaChiLapD": "",
        "longitude": 105.83380625396161,
        "latitude": 21.013558483428245,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1301,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83349243551208,
          21.013286817592732
        ]
      },
      "properties": {
        "tenDoiTuon": "02",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Đặng Văn Ngữ",
        "tuDien": "TT2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Trung Tự 2",
        "diaChiLapD": "",
        "longitude": 105.83349243551208,
        "latitude": 21.013286817592732,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1302,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83403290060564,
          21.013765049286135
        ]
      },
      "properties": {
        "tenDoiTuon": "TT2 1-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 68 Phố Đặng Văn Ngữ",
        "tuDien": "TT2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Trung Tự 2",
        "diaChiLapD": "",
        "longitude": 105.83403290060564,
        "latitude": 21.013765049286135,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1303,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83401479567193,
          21.013429536114398
        ]
      },
      "properties": {
        "tenDoiTuon": "TT2 1-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 64 Phố Đặng Văn Ngữ",
        "tuDien": "TT2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Trung Tự 2",
        "diaChiLapD": "",
        "longitude": 105.83401479567193,
        "latitude": 21.013429536114398,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1304,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83399602020879,
          21.013366940289078
        ]
      },
      "properties": {
        "tenDoiTuon": "1-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 62 Phố Đặng Văn Ngữ",
        "tuDien": "TT2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Trung Tự 2",
        "diaChiLapD": "",
        "longitude": 105.83399602020879,
        "latitude": 21.013366940289078,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1305,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83423607791846,
          21.013119060564996
        ]
      },
      "properties": {
        "tenDoiTuon": "1-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 62 Phố Đặng Văn Ngữ",
        "tuDien": "TT2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Trung Tự 2",
        "diaChiLapD": "",
        "longitude": 105.83423607791846,
        "latitude": 21.013119060564996,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1306,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83439701045485,
          21.012921257257105
        ]
      },
      "properties": {
        "tenDoiTuon": "TT2 1-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 62 Phố Đặng Văn Ngữ",
        "tuDien": "TT2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Trung Tự 2",
        "diaChiLapD": "",
        "longitude": 105.83439701045485,
        "latitude": 21.012921257257105,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1307,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83295197039156,
          21.012754751735347
        ]
      },
      "properties": {
        "tenDoiTuon": "5-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 6 Phố Đặng Văn Ngữ",
        "tuDien": "TT2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Trung Tự 2",
        "diaChiLapD": "",
        "longitude": 105.83295197039156,
        "latitude": 21.012754751735347,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1308,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83308406912734,
          21.012693407554536
        ]
      },
      "properties": {
        "tenDoiTuon": "5-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 6 Phố Đặng Văn Ngữ",
        "tuDien": "TT2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 8,
        "tuDien2": "Trung Tự 2",
        "diaChiLapD": "",
        "longitude": 105.83308406912734,
        "latitude": 21.012693407554536,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1309,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83324366055984,
          21.012886203482196
        ]
      },
      "properties": {
        "tenDoiTuon": "5-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 6 Phố Đặng Văn Ngữ",
        "tuDien": "TT2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Trung Tự 2",
        "diaChiLapD": "",
        "longitude": 105.83324366055984,
        "latitude": 21.012886203482196,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1310,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83348505937342,
          21.013084006836575
        ]
      },
      "properties": {
        "tenDoiTuon": "5-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 6 Phố Đặng Văn Ngữ",
        "tuDien": "TT2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Trung Tự 2",
        "diaChiLapD": "",
        "longitude": 105.83348505937342,
        "latitude": 21.013084006836575,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1311,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83334659033359,
          21.012370098242396
        ]
      },
      "properties": {
        "tenDoiTuon": "5-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 6 Phố Đặng Văn Ngữ",
        "tuDien": "TT2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Trung Tự 2",
        "diaChiLapD": "",
        "longitude": 105.83334659033359,
        "latitude": 21.012370098242396,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1312,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83176140503704,
          21.02468863997973
        ]
      },
      "properties": {
        "tenDoiTuon": "14-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 162 A Tôn Đức Thắng",
        "tuDien": "HB1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Hàng Bột 1",
        "diaChiLapD": "",
        "longitude": 105.83176140503704,
        "latitude": 21.02468863997973,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1313,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83136086182559,
          21.024826340304113
        ]
      },
      "properties": {
        "tenDoiTuon": "14-8",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 162 A Tôn Đức Thắng",
        "tuDien": "HB1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Hàng Bột 1",
        "diaChiLapD": "",
        "longitude": 105.83136086182559,
        "latitude": 21.024826340304113,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1314,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83190356211925,
          21.024325611242656
        ]
      },
      "properties": {
        "tenDoiTuon": "14-10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 162 A Tôn Đức Thắng",
        "tuDien": "HB1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Hàng Bột 1",
        "diaChiLapD": "",
        "longitude": 105.83190356211925,
        "latitude": 21.024325611242656,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1315,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8317409531918,
          21.024076810864848
        ]
      },
      "properties": {
        "tenDoiTuon": "14-11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 162 A Tôn Đức Thắng",
        "tuDien": "HB1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Hàng Bột 1",
        "diaChiLapD": "",
        "longitude": 105.8317409531918,
        "latitude": 21.024076810864848,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1316,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83202157931379,
          21.024611027013563
        ]
      },
      "properties": {
        "tenDoiTuon": "14-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 162 A Tôn Đức Thắng",
        "tuDien": "HB1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Hàng Bột 1",
        "diaChiLapD": "",
        "longitude": 105.83202157931379,
        "latitude": 21.024611027013563,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1317,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83206985907113,
          21.024748727409623
        ]
      },
      "properties": {
        "tenDoiTuon": "14-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 162 A Tôn Đức Thắng",
        "tuDien": "HB1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Hàng Bột 1",
        "diaChiLapD": "",
        "longitude": 105.83206985907113,
        "latitude": 21.024748727409623,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1318,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83156023936657,
          21.024743720125418
        ]
      },
      "properties": {
        "tenDoiTuon": "16-7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 162 A Tôn Đức Thắng",
        "tuDien": "HB1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "Hàng Bột 1",
        "diaChiLapD": "",
        "longitude": 105.83156023936657,
        "latitude": 21.024743720125418,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1319,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83199308084531,
          21.02402047864686
        ]
      },
      "properties": {
        "tenDoiTuon": "14-12",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 162 A Tôn Đức Thắng",
        "tuDien": "HB1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "Hàng Bột 1",
        "diaChiLapD": "",
        "longitude": 105.83199308084531,
        "latitude": 21.02402047864686,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1320,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83116863684758,
          21.0247687565481
        ]
      },
      "properties": {
        "tenDoiTuon": "14-9",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 162 A Tôn Đức Thắng",
        "tuDien": "HB1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "Hàng Bột 1",
        "diaChiLapD": "",
        "longitude": 105.83116863684758,
        "latitude": 21.0247687565481,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1321,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83189953878967,
          21.023684675594065
        ]
      },
      "properties": {
        "tenDoiTuon": "16-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 162 B Tôn Đức Thắng",
        "tuDien": "HB1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Hàng Bột 1",
        "diaChiLapD": "",
        "longitude": 105.83189953878967,
        "latitude": 21.023684675594065,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1322,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8319692762179,
          21.02385867988229
        ]
      },
      "properties": {
        "tenDoiTuon": "16-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 162 B Tôn Đức Thắng",
        "tuDien": "HB1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Hàng Bột 1",
        "diaChiLapD": "",
        "longitude": 105.8319692762179,
        "latitude": 21.02385867988229,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1323,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83164472892841,
          21.023791081118578
        ]
      },
      "properties": {
        "tenDoiTuon": "16-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 162 B Tôn Đức Thắng",
        "tuDien": "HB1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Hàng Bột 1",
        "diaChiLapD": "",
        "longitude": 105.83164472892841,
        "latitude": 21.023791081118578,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1324,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8314314933141,
          21.023877457311592
        ]
      },
      "properties": {
        "tenDoiTuon": "16-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 162 B Tôn Đức Thắng",
        "tuDien": "HB1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Hàng Bột 1",
        "diaChiLapD": "",
        "longitude": 105.8314314933141,
        "latitude": 21.023877457311592,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1325,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83144624546567,
          21.023971344420936
        ]
      },
      "properties": {
        "tenDoiTuon": "16-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 162 B Tôn Đức Thắng",
        "tuDien": "HB1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": "",
        "tuDien2": "Hàng Bột 1",
        "diaChiLapD": "",
        "longitude": 105.83144624546567,
        "latitude": 21.023971344420936,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1326,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83184589459889,
          21.023536959565817
        ]
      },
      "properties": {
        "tenDoiTuon": "17-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Thịnh Hào 1",
        "tuDien": "HB1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Hàng Bột 1",
        "diaChiLapD": "",
        "longitude": 105.83184589459889,
        "latitude": 21.023536959565817,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1327,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83156962707571,
          21.0236571353298
        ]
      },
      "properties": {
        "tenDoiTuon": "17-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Thịnh Hào 1",
        "tuDien": "HB1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Hàng Bột 1",
        "diaChiLapD": "",
        "longitude": 105.83156962707571,
        "latitude": 21.0236571353298,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1328,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83118070676447,
          21.023789829289925
        ]
      },
      "properties": {
        "tenDoiTuon": "17-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Thịnh Hào 1",
        "tuDien": "HB1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Hàng Bột 1",
        "diaChiLapD": "",
        "longitude": 105.83118070676447,
        "latitude": 21.023789829289925,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1329,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83094574525488,
          21.02385943098066
        ]
      },
      "properties": {
        "tenDoiTuon": "17-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Thịnh Hào 1",
        "tuDien": "HB1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Hàng Bột 1",
        "diaChiLapD": "",
        "longitude": 105.83094574525488,
        "latitude": 21.02385943098066,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1330,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83066357686747,
          21.02395607212271
        ]
      },
      "properties": {
        "tenDoiTuon": "17-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Thịnh Hào 1",
        "tuDien": "HB1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Hàng Bột 1",
        "diaChiLapD": "",
        "longitude": 105.83066357686747,
        "latitude": 21.02395607212271,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1331,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83087761714587,
          21.02369719389654
        ]
      },
      "properties": {
        "tenDoiTuon": "17-7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Thịnh Hào 1",
        "tuDien": "HB1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Hàng Bột 1",
        "diaChiLapD": "",
        "longitude": 105.83087761714587,
        "latitude": 21.02369719389654,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1332,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83084006621954,
          21.024255509046206
        ]
      },
      "properties": {
        "tenDoiTuon": "17-5-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Thịnh Hào 1",
        "tuDien": "HB1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Hàng Bột 1",
        "diaChiLapD": "",
        "longitude": 105.83084006621954,
        "latitude": 21.024255509046206,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1333,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83074618890365,
          21.02404520223981
        ]
      },
      "properties": {
        "tenDoiTuon": "17-5-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Thịnh Hào 1",
        "tuDien": "HB1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Hàng Bột 1",
        "diaChiLapD": "",
        "longitude": 105.83074618890365,
        "latitude": 21.02404520223981,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1334,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83160181357758,
          21.023514426599064
        ]
      },
      "properties": {
        "tenDoiTuon": "17-2-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Thịnh Hào 1",
        "tuDien": "HB1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Hàng Bột 1",
        "diaChiLapD": "",
        "longitude": 105.83160181357758,
        "latitude": 21.023514426599064,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1335,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83125312640942,
          21.024027676659163
        ]
      },
      "properties": {
        "tenDoiTuon": "17-3-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Thịnh Hào 1",
        "tuDien": "HB1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Hàng Bột 1",
        "diaChiLapD": "",
        "longitude": 105.83125312640942,
        "latitude": 21.024027676659163,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1336,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83102513864355,
          21.023597047459933
        ]
      },
      "properties": {
        "tenDoiTuon": "17-8",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Thịnh Hào 1",
        "tuDien": "HB1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Hàng Bột 1",
        "diaChiLapD": "",
        "longitude": 105.83102513864355,
        "latitude": 21.023597047459933,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1337,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8304994256746,
          21.023980107215674
        ]
      },
      "properties": {
        "tenDoiTuon": "17-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Thịnh Hào 1",
        "tuDien": "HB1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Hàng Bột 1",
        "diaChiLapD": "",
        "longitude": 105.8304994256746,
        "latitude": 21.023980107215674,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1338,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83013196307022,
          21.023142632153203
        ]
      },
      "properties": {
        "tenDoiTuon": "20-10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Thịnh Hào 2",
        "tuDien": "HB1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "Hàng Bột 1",
        "diaChiLapD": "",
        "longitude": 105.83013196307022,
        "latitude": 21.023142632153203,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1339,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83118505644302,
          21.02296017713433
        ]
      },
      "properties": {
        "tenDoiTuon": "20-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Thịnh Hào 2",
        "tuDien": "HB1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Hàng Bột 1",
        "diaChiLapD": "",
        "longitude": 105.83118505644302,
        "latitude": 21.02296017713433,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1340,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83090846258882,
          21.023090055089224
        ]
      },
      "properties": {
        "tenDoiTuon": "20-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Thịnh Hào 2",
        "tuDien": "HB1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Hàng Bột 1",
        "diaChiLapD": "",
        "longitude": 105.83090846258882,
        "latitude": 21.023090055089224,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1341,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83090476559921,
          21.023171737306093
        ]
      },
      "properties": {
        "tenDoiTuon": "20-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Thịnh Hào 2",
        "tuDien": "HB1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Hàng Bột 1",
        "diaChiLapD": "",
        "longitude": 105.83090476559921,
        "latitude": 21.023171737306093,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1342,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83058826493296,
          21.023234329015764
        ]
      },
      "properties": {
        "tenDoiTuon": "20-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Thịnh Hào 2",
        "tuDien": "HB1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Hàng Bột 1",
        "diaChiLapD": "",
        "longitude": 105.83058826493296,
        "latitude": 21.023234329015764,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1343,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8304300146043,
          21.023266876693796
        ]
      },
      "properties": {
        "tenDoiTuon": "20-7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Thịnh Hào 2",
        "tuDien": "HB1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Hàng Bột 1",
        "diaChiLapD": "",
        "longitude": 105.8304300146043,
        "latitude": 21.023266876693796,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1344,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83021543788612,
          21.02330568353184
        ]
      },
      "properties": {
        "tenDoiTuon": "20-9",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Thịnh Hào 2",
        "tuDien": "HB1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "Hàng Bột 1",
        "diaChiLapD": "",
        "longitude": 105.83021543788612,
        "latitude": 21.02330568353184,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1345,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83023153114156,
          21.02293889591707
        ]
      },
      "properties": {
        "tenDoiTuon": "20-12",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Thịnh Hào 2",
        "tuDien": "HB1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Hàng Bột 1",
        "diaChiLapD": "",
        "longitude": 105.83023153114156,
        "latitude": 21.02293889591707,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1346,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83054502326011,
          21.022982397226407
        ]
      },
      "properties": {
        "tenDoiTuon": "20-13",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Thịnh Hào 2",
        "tuDien": "HB1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Hàng Bột 1",
        "diaChiLapD": "",
        "longitude": 105.83054502326011,
        "latitude": 21.022982397226407,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1347,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83108983802325,
          21.022893829800225
        ]
      },
      "properties": {
        "tenDoiTuon": "20-17",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Thịnh Hào 2",
        "tuDien": "HB1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "Hàng Bột 1",
        "diaChiLapD": "",
        "longitude": 105.83108983802325,
        "latitude": 21.022893829800225,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1348,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83097685891319,
          21.022867228266733
        ]
      },
      "properties": {
        "tenDoiTuon": "20-16",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Thịnh Hào 2",
        "tuDien": "HB1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Hàng Bột 1",
        "diaChiLapD": "",
        "longitude": 105.83097685891319,
        "latitude": 21.022867228266733,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1349,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83141975867545,
          21.022845634077488
        ]
      },
      "properties": {
        "tenDoiTuon": "20-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Thịnh Hào 2",
        "tuDien": "HB1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Hàng Bột 1",
        "diaChiLapD": "",
        "longitude": 105.83141975867545,
        "latitude": 21.022845634077488,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1350,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83032944070732,
          21.023054690740466
        ]
      },
      "properties": {
        "tenDoiTuon": "20-11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Thịnh Hào 2",
        "tuDien": "HB1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Hàng Bột 1",
        "diaChiLapD": "",
        "longitude": 105.83032944070732,
        "latitude": 21.023054690740466,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1351,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83066069352513,
          21.022871922656147
        ]
      },
      "properties": {
        "tenDoiTuon": "20-14",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Thịnh Hào 2",
        "tuDien": "HB1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "Hàng Bột 1",
        "diaChiLapD": "",
        "longitude": 105.83066069352513,
        "latitude": 21.022871922656147,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1352,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83081223833443,
          21.022834367541872
        ]
      },
      "properties": {
        "tenDoiTuon": "20-15",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Thịnh Hào 2",
        "tuDien": "HB1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "Hàng Bột 1",
        "diaChiLapD": "",
        "longitude": 105.83081223833443,
        "latitude": 21.022834367541872,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1353,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83101809788192,
          21.02339161143248
        ]
      },
      "properties": {
        "tenDoiTuon": "20-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Thịnh Hào 2",
        "tuDien": "HB1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Hàng Bột 1",
        "diaChiLapD": "",
        "longitude": 105.83101809788192,
        "latitude": 21.02339161143248,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1354,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83030463028119,
          21.02332901978969
        ]
      },
      "properties": {
        "tenDoiTuon": "20-8",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Thịnh Hào 2",
        "tuDien": "HB1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "Hàng Bột 1",
        "diaChiLapD": "",
        "longitude": 105.83030463028119,
        "latitude": 21.02332901978969,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1355,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8302831726103,
          21.02411516891977
        ]
      },
      "properties": {
        "tenDoiTuon": "01",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Nghách  74 Thịnh Hào 1",
        "tuDien": "ATL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "ATEC Thăng Long",
        "diaChiLapD": "",
        "longitude": 105.8302831726103,
        "latitude": 21.02411516891977,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1356,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8302831726103,
          21.02411516891977
        ]
      },
      "properties": {
        "tenDoiTuon": "01",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Thịnh Hào 1",
        "tuDien": "ATL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "ATEC Thăng Long",
        "diaChiLapD": "",
        "longitude": 105.8302831726103,
        "latitude": 21.02411516891977,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1357,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82996398973629,
          21.02434800847074
        ]
      },
      "properties": {
        "tenDoiTuon": "2-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Nghách  76/11 Thịnh Hào 1",
        "tuDien": "ATL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "ATEC Thăng Long",
        "diaChiLapD": "",
        "longitude": 105.82996398973629,
        "latitude": 21.02434800847074,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1358,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.829774446966,
          21.02450156553887
        ]
      },
      "properties": {
        "tenDoiTuon": "2-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Nghách  76/11 Thịnh Hào 1",
        "tuDien": "ATL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "ATEC Thăng Long",
        "diaChiLapD": "",
        "longitude": 105.829774446966,
        "latitude": 21.02450156553887,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1359,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82958490419573,
          21.024655122448785
        ]
      },
      "properties": {
        "tenDoiTuon": "2-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Nghách  76/11 Thịnh Hào 1",
        "tuDien": "ATL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "ATEC Thăng Long",
        "diaChiLapD": "",
        "longitude": 105.82958490419573,
        "latitude": 21.024655122448785,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1360,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82967967558086,
          21.025244312167246
        ]
      },
      "properties": {
        "tenDoiTuon": "3-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Nghách  74 Thịnh Hào 1",
        "tuDien": "ATL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "ATEC Thăng Long",
        "diaChiLapD": "",
        "longitude": 105.82967967558086,
        "latitude": 21.025244312167246,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1361,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82973868417814,
          21.02545962454308
        ]
      },
      "properties": {
        "tenDoiTuon": "3-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Nghách  74 Thịnh Hào 1",
        "tuDien": "ATL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "ATEC Thăng Long",
        "diaChiLapD": "",
        "longitude": 105.82973868417814,
        "latitude": 21.02545962454308,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1362,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82950801419554,
          21.024480701815083
        ]
      },
      "properties": {
        "tenDoiTuon": "2-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Nghách  76/11 Thịnh Hào 1",
        "tuDien": "ATL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "ATEC Thăng Long",
        "diaChiLapD": "",
        "longitude": 105.82950801419554,
        "latitude": 21.024480701815083,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1363,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.829647489061,
          21.024378052256562
        ]
      },
      "properties": {
        "tenDoiTuon": "2-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Nghách  76/11 Thịnh Hào 1",
        "tuDien": "ATL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "ATEC Thăng Long",
        "diaChiLapD": "",
        "longitude": 105.829647489061,
        "latitude": 21.024378052256562,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1364,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83358664824091,
          21.012554238402497
        ]
      },
      "properties": {
        "tenDoiTuon": "5-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 6 Phố Đặng Văn Ngữ",
        "tuDien": "TT2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Trung Tự 2",
        "diaChiLapD": "",
        "longitude": 105.83358664824091,
        "latitude": 21.012554238402497,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1365,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83386559798083,
          21.012716988379076
        ]
      },
      "properties": {
        "tenDoiTuon": "5-7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 6 Phố Đặng Văn Ngữ",
        "tuDien": "TT2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Trung Tự 2",
        "diaChiLapD": "",
        "longitude": 105.83386559798083,
        "latitude": 21.012716988379076,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1366,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83396215750444,
          21.012651888409597
        ]
      },
      "properties": {
        "tenDoiTuon": "5-8",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 6 Phố Đặng Văn Ngữ",
        "tuDien": "TT2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Trung Tự 2",
        "diaChiLapD": "",
        "longitude": 105.83396215750444,
        "latitude": 21.012651888409597,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1367,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83408285690675,
          21.01275704988484
        ]
      },
      "properties": {
        "tenDoiTuon": "TT2 5-9",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 6 Phố Đặng Văn Ngữ",
        "tuDien": "TT2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Trung Tự 2",
        "diaChiLapD": "",
        "longitude": 105.83408285690675,
        "latitude": 21.01275704988484,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1368,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83377708508043,
          21.012859707443447
        ]
      },
      "properties": {
        "tenDoiTuon": "5-10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 6 Phố Đặng Văn Ngữ",
        "tuDien": "TT2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Trung Tự 2",
        "diaChiLapD": "",
        "longitude": 105.83377708508043,
        "latitude": 21.012859707443447,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1369,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83359737708086,
          21.013135129812923
        ]
      },
      "properties": {
        "tenDoiTuon": "2-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 46 Đặng Văn Ngữ",
        "tuDien": "TT2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Trung Tự 2",
        "diaChiLapD": "",
        "longitude": 105.83359737708086,
        "latitude": 21.013135129812923,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1370,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83370734764321,
          21.01322526793244
        ]
      },
      "properties": {
        "tenDoiTuon": "2-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 46 Đặng Văn Ngữ",
        "tuDien": "TT2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Trung Tự 2",
        "diaChiLapD": "",
        "longitude": 105.83370734764321,
        "latitude": 21.01322526793244,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1371,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83367784334907,
          21.01309006073233
        ]
      },
      "properties": {
        "tenDoiTuon": "2-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 46 Đặng Văn Ngữ",
        "tuDien": "TT2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": "",
        "tuDien2": "Trung Tự 2",
        "diaChiLapD": "",
        "longitude": 105.83367784334907,
        "latitude": 21.01309006073233,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1372,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83364833904592,
          21.013007434049747
        ]
      },
      "properties": {
        "tenDoiTuon": "TT2 2-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 46 Đặng Văn Ngữ",
        "tuDien": "TT2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": "",
        "tuDien2": "Trung Tự 2",
        "diaChiLapD": "",
        "longitude": 105.83364833904592,
        "latitude": 21.013007434049747,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1373,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83354641510685,
          21.012173653148004
        ]
      },
      "properties": {
        "tenDoiTuon": "5-11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 6 Phố Đặng Văn Ngữ",
        "tuDien": "TT2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Trung Tự 2",
        "diaChiLapD": "",
        "longitude": 105.83354641510685,
        "latitude": 21.012173653148004,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1374,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83373416973859,
          21.0119533138735
        ]
      },
      "properties": {
        "tenDoiTuon": "5-12",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 6 Phố Đặng Văn Ngữ",
        "tuDien": "TT2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Trung Tự 2",
        "diaChiLapD": "",
        "longitude": 105.83373416973859,
        "latitude": 21.0119533138735,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1375,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83388437343504,
          21.01202091800702
        ]
      },
      "properties": {
        "tenDoiTuon": "5-15",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 6 Phố Đặng Văn Ngữ",
        "tuDien": "TT2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Trung Tự 2",
        "diaChiLapD": "",
        "longitude": 105.83388437343504,
        "latitude": 21.01202091800702,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1376,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8340721280668,
          21.012108552944728
        ]
      },
      "properties": {
        "tenDoiTuon": "5-16",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 6 Phố Đặng Văn Ngữ",
        "tuDien": "TT2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Trung Tự 2",
        "diaChiLapD": "",
        "longitude": 105.8340721280668,
        "latitude": 21.012108552944728,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1377,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83426256490633,
          21.012151118468346
        ]
      },
      "properties": {
        "tenDoiTuon": "TT2 5-17",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 6 Phố Đặng Văn Ngữ",
        "tuDien": "TT2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Trung Tự 2",
        "diaChiLapD": "",
        "longitude": 105.83426256490633,
        "latitude": 21.012151118468346,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1378,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8341257722486,
          21.012023421862743
        ]
      },
      "properties": {
        "tenDoiTuon": "TT2 5-25",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 6 Phố Đặng Văn Ngữ",
        "tuDien": "TT2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "Trung Tự 2",
        "diaChiLapD": "",
        "longitude": 105.8341257722486,
        "latitude": 21.012023421862743,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1379,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83395947528773,
          21.0122500206657
        ]
      },
      "properties": {
        "tenDoiTuon": "5-18",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 6 Phố Đặng Văn Ngữ",
        "tuDien": "TT2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Trung Tự 2",
        "diaChiLapD": "",
        "longitude": 105.83395947528773,
        "latitude": 21.0122500206657,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1380,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8337837905998,
          21.01239649595154
        ]
      },
      "properties": {
        "tenDoiTuon": "TT2 5-19",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 6 Phố Đặng Văn Ngữ",
        "tuDien": "TT2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Trung Tự 2",
        "diaChiLapD": "",
        "longitude": 105.8337837905998,
        "latitude": 21.01239649595154,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1381,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8341566176556,
          21.01240901519997
        ]
      },
      "properties": {
        "tenDoiTuon": "5-21",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 6 Phố Đặng Văn Ngữ",
        "tuDien": "TT2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Trung Tự 2",
        "diaChiLapD": "",
        "longitude": 105.8341566176556,
        "latitude": 21.01240901519997,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1382,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83408151580286,
          21.01245784025951
        ]
      },
      "properties": {
        "tenDoiTuon": "TT2 5-20",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 6 Phố Đặng Văn Ngữ",
        "tuDien": "TT2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "Trung Tự 2",
        "diaChiLapD": "",
        "longitude": 105.83408151580286,
        "latitude": 21.01245784025951,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1383,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83430548025713,
          21.012383976702047
        ]
      },
      "properties": {
        "tenDoiTuon": "5-22",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 6 Phố Đặng Văn Ngữ",
        "tuDien": "TT2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Trung Tự 2",
        "diaChiLapD": "",
        "longitude": 105.83430548025713,
        "latitude": 21.012383976702047,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1384,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83428670479394,
          21.01253545955065
        ]
      },
      "properties": {
        "tenDoiTuon": "TT2 5-24",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 6 Phố Đặng Văn Ngữ",
        "tuDien": "TT2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Trung Tự 2",
        "diaChiLapD": "",
        "longitude": 105.83428670479394,
        "latitude": 21.01253545955065,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1385,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83436180664667,
          21.0124615960325
        ]
      },
      "properties": {
        "tenDoiTuon": "TT2 5-23",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 6 Phố Đặng Văn Ngữ",
        "tuDien": "TT2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Trung Tự 2",
        "diaChiLapD": "",
        "longitude": 105.83436180664667,
        "latitude": 21.0124615960325,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1386,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83385352802804,
          21.011642835254786
        ]
      },
      "properties": {
        "tenDoiTuon": "5-13",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 6 Phố Đặng Văn Ngữ",
        "tuDien": "TT2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Trung Tự 2",
        "diaChiLapD": "",
        "longitude": 105.83385352802804,
        "latitude": 21.011642835254786,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1387,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83395411086327,
          21.011476328308483
        ]
      },
      "properties": {
        "tenDoiTuon": "TT2 5-14",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 6 Phố Đặng Văn Ngữ",
        "tuDien": "TT2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Trung Tự 2",
        "diaChiLapD": "",
        "longitude": 105.83395411086327,
        "latitude": 21.011476328308483,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1388,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8331876696192,
          21.010841597120542
        ]
      },
      "properties": {
        "tenDoiTuon": "12",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Khu Nhà G1-G3 (TT)",
        "tuDien": "TTCT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 8,
        "tuDien2": "Trung Tự Cao Tầng",
        "diaChiLapD": "",
        "longitude": 105.8331876696192,
        "latitude": 21.010841597120542,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1389,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83332580338082,
          21.010973050547445
        ]
      },
      "properties": {
        "tenDoiTuon": "13",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Khu Nhà G1-G3 (TT)",
        "tuDien": "TTCT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Trung Tự Cao Tầng",
        "diaChiLapD": "",
        "longitude": 105.83332580338082,
        "latitude": 21.010973050547445,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1390,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83346930156686,
          21.01109323643671
        ]
      },
      "properties": {
        "tenDoiTuon": "14",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Khu Nhà G1-G3 (TT)",
        "tuDien": "TTCT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 8,
        "tuDien2": "Trung Tự Cao Tầng",
        "diaChiLapD": "",
        "longitude": 105.83346930156686,
        "latitude": 21.01109323643671,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1391,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83359536539362,
          21.01124096479328
        ]
      },
      "properties": {
        "tenDoiTuon": "TTCT 15",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Khu Nhà G1-G3 (TT)",
        "tuDien": "TTCT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Trung Tự Cao Tầng",
        "diaChiLapD": "",
        "longitude": 105.83359536539362,
        "latitude": 21.01124096479328,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1392,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83383810531106,
          21.0112109183598
        ]
      },
      "properties": {
        "tenDoiTuon": "16",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Khu Nhà G1-G3 (TT)",
        "tuDien": "TTCT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": "",
        "tuDien2": "Trung Tự Cao Tầng",
        "diaChiLapD": "",
        "longitude": 105.83383810531106,
        "latitude": 21.0112109183598,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1393,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83404463539702,
          21.011341119527273
        ]
      },
      "properties": {
        "tenDoiTuon": "17",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Khu Nhà G1-G3 (TT)",
        "tuDien": "TTCT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": "",
        "tuDien2": "Trung Tự Cao Tầng",
        "diaChiLapD": "",
        "longitude": 105.83404463539702,
        "latitude": 21.011341119527273,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1394,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83315816532505,
          21.01074770174454
        ]
      },
      "properties": {
        "tenDoiTuon": "11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Khu Nhà G1-G3 (TT)",
        "tuDien": "TTCT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Trung Tự Cao Tầng",
        "diaChiLapD": "",
        "longitude": 105.83315816532505,
        "latitude": 21.01074770174454,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1395,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83296504626882,
          21.01059997289948
        ]
      },
      "properties": {
        "tenDoiTuon": "18",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 4D Đặng Văn Ngữ",
        "tuDien": "TTCT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Trung Tự Cao Tầng",
        "diaChiLapD": "",
        "longitude": 105.83296504626882,
        "latitude": 21.01059997289948,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1396,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83277326832543,
          21.0104297089651
        ]
      },
      "properties": {
        "tenDoiTuon": "29",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 4B Đặng Văn Ngữ",
        "tuDien": "TTCT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Trung Tự Cao Tầng",
        "diaChiLapD": "",
        "longitude": 105.83277326832543,
        "latitude": 21.0104297089651,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1397,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83280009042083,
          21.010768984701585
        ]
      },
      "properties": {
        "tenDoiTuon": "19",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 4D Đặng Văn Ngữ",
        "tuDien": "TTCT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Trung Tự Cao Tầng",
        "diaChiLapD": "",
        "longitude": 105.83280009042083,
        "latitude": 21.010768984701585,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1398,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83260428915685,
          21.01097931023196
        ]
      },
      "properties": {
        "tenDoiTuon": "23",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 4D Đặng Văn Ngữ",
        "tuDien": "TTCT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Trung Tự Cao Tầng",
        "diaChiLapD": "",
        "longitude": 105.83260428915685,
        "latitude": 21.01097931023196,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1399,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83240848790184,
          21.011179619985736
        ]
      },
      "properties": {
        "tenDoiTuon": "26",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 4D Đặng Văn Ngữ",
        "tuDien": "TTCT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Trung Tự Cao Tầng",
        "diaChiLapD": "",
        "longitude": 105.83240848790184,
        "latitude": 21.011179619985736,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1400,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83220732223137,
          21.011392448803477
        ]
      },
      "properties": {
        "tenDoiTuon": "27",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 4D Đặng Văn Ngữ",
        "tuDien": "TTCT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Trung Tự Cao Tầng",
        "diaChiLapD": "",
        "longitude": 105.83220732223137,
        "latitude": 21.011392448803477,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1401,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83200079213643,
          21.011610285044625
        ]
      },
      "properties": {
        "tenDoiTuon": "TTCT 28",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 4D Đặng Văn Ngữ",
        "tuDien": "TTCT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Trung Tự Cao Tầng",
        "diaChiLapD": "",
        "longitude": 105.83200079213643,
        "latitude": 21.011610285044625,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1402,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8323387504736,
          21.010768984702427
        ]
      },
      "properties": {
        "tenDoiTuon": "24",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 4D Đặng Văn Ngữ",
        "tuDien": "TTCT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Trung Tự Cao Tầng",
        "diaChiLapD": "",
        "longitude": 105.8323387504736,
        "latitude": 21.010768984702427,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1403,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83215636025733,
          21.01061124035943
        ]
      },
      "properties": {
        "tenDoiTuon": "TTCT 25",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 4D Đặng Văn Ngữ",
        "tuDien": "TTCT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Trung Tự Cao Tầng",
        "diaChiLapD": "",
        "longitude": 105.83215636025733,
        "latitude": 21.01061124035943,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1404,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8325345517286,
          21.010235657920894
        ]
      },
      "properties": {
        "tenDoiTuon": "30",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 4B Đặng Văn Ngữ",
        "tuDien": "TTCT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Trung Tự Cao Tầng",
        "diaChiLapD": "",
        "longitude": 105.8325345517286,
        "latitude": 21.010235657920894,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1405,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83229315291504,
          21.010027835231302
        ]
      },
      "properties": {
        "tenDoiTuon": "31",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 4B Đặng Văn Ngữ",
        "tuDien": "TTCT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Trung Tự Cao Tầng",
        "diaChiLapD": "",
        "longitude": 105.83229315291504,
        "latitude": 21.010027835231302,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1406,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83207589398913,
          21.009789965533347
        ]
      },
      "properties": {
        "tenDoiTuon": "TTCT 32",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 4B Đặng Văn Ngữ",
        "tuDien": "TTCT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Trung Tự Cao Tầng",
        "diaChiLapD": "",
        "longitude": 105.83207589398913,
        "latitude": 21.009789965533347,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1407,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83177012216281,
          21.00968730586135
        ]
      },
      "properties": {
        "tenDoiTuon": "TTCT 33",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 4B Đặng Văn Ngữ",
        "tuDien": "TTCT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 8,
        "tuDien2": "Trung Tự Cao Tầng",
        "diaChiLapD": "",
        "longitude": 105.83177012216281,
        "latitude": 21.00968730586135,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1408,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83196592341781,
          21.01014051021944
        ]
      },
      "properties": {
        "tenDoiTuon": "34",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 4B Đặng Văn Ngữ",
        "tuDien": "TTCT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Trung Tự Cao Tầng",
        "diaChiLapD": "",
        "longitude": 105.83196592341781,
        "latitude": 21.01014051021944,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1409,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83190959702829,
          21.01028823951865
        ]
      },
      "properties": {
        "tenDoiTuon": "35",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 4B Đặng Văn Ngữ",
        "tuDien": "TTCT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Trung Tự Cao Tầng",
        "diaChiLapD": "",
        "longitude": 105.83190959702829,
        "latitude": 21.01028823951865,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1410,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83179426204147,
          21.010558658875524
        ]
      },
      "properties": {
        "tenDoiTuon": "TTCT 36",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 4B Đặng Văn Ngữ",
        "tuDien": "TTCT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Trung Tự Cao Tầng",
        "diaChiLapD": "",
        "longitude": 105.83179426204147,
        "latitude": 21.010558658875524,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1411,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83170776086425,
          21.010335813318957
        ]
      },
      "properties": {
        "tenDoiTuon": "37",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 4B Đặng Văn Ngữ",
        "tuDien": "TTCT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Trung Tự Cao Tầng",
        "diaChiLapD": "",
        "longitude": 105.83170776086425,
        "latitude": 21.010335813318957,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1412,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8313724847348,
          21.01021312293338
        ]
      },
      "properties": {
        "tenDoiTuon": "TTCT 38",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 4B Đặng Văn Ngữ",
        "tuDien": "TTCT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Trung Tự Cao Tầng",
        "diaChiLapD": "",
        "longitude": 105.8313724847348,
        "latitude": 21.01021312293338,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1413,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83339487033346,
          21.010558658863808
        ]
      },
      "properties": {
        "tenDoiTuon": "08",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Khu Nhà G1-G3 (TT)",
        "tuDien": "TTCT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Trung Tự Cao Tầng",
        "diaChiLapD": "",
        "longitude": 105.83339487033346,
        "latitude": 21.010558658863808,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1414,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83364431577024,
          21.010581193788205
        ]
      },
      "properties": {
        "tenDoiTuon": "TTCT 03",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Khu Nhà G1-G3 (TT)",
        "tuDien": "TTCT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Trung Tự Cao Tầng",
        "diaChiLapD": "",
        "longitude": 105.83364431577024,
        "latitude": 21.010581193788205,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1415,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83353702741564,
          21.01076397693623
        ]
      },
      "properties": {
        "tenDoiTuon": "09",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Khu Nhà G1-G3 (TT)",
        "tuDien": "TTCT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Trung Tự Cao Tầng",
        "diaChiLapD": "",
        "longitude": 105.83353702741564,
        "latitude": 21.01076397693623,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1416,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83366309123343,
          21.010861628116114
        ]
      },
      "properties": {
        "tenDoiTuon": "10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Khu Nhà G1-G3 (TT)",
        "tuDien": "TTCT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Trung Tự Cao Tầng",
        "diaChiLapD": "",
        "longitude": 105.83366309123343,
        "latitude": 21.010861628116114,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1417,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83389007317365,
          21.010403105198137
        ]
      },
      "properties": {
        "tenDoiTuon": "TTCT 01",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Khu Nhà G1-G3 (TT)",
        "tuDien": "TTCT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Trung Tự Cao Tầng",
        "diaChiLapD": "",
        "longitude": 105.83389007317365,
        "latitude": 21.010403105198137,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1418,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8341059909957,
          21.01052829930913
        ]
      },
      "properties": {
        "tenDoiTuon": "TTCT 02",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Khu Nhà G1-G3 (TT)",
        "tuDien": "TTCT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Trung Tự Cao Tầng",
        "diaChiLapD": "",
        "longitude": 105.8341059909957,
        "latitude": 21.01052829930913,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1419,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83343007433028,
          21.010321728970617
        ]
      },
      "properties": {
        "tenDoiTuon": "TTCT 05",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Sân Con Voi",
        "tuDien": "TTCT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 7,
        "tuDien2": "Trung Tự Cao Tầng",
        "diaChiLapD": "",
        "longitude": 105.83343007433028,
        "latitude": 21.010321728970617,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1420,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83338447677174,
          21.010443167325317
        ]
      },
      "properties": {
        "tenDoiTuon": "TTCT 04",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Sân Con Voi",
        "tuDien": "TTCT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 7,
        "tuDien2": "Trung Tự Cao Tầng",
        "diaChiLapD": "",
        "longitude": 105.83338447677174,
        "latitude": 21.010443167325317,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1421,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83294325339156,
          21.010247864355467
        ]
      },
      "properties": {
        "tenDoiTuon": "TTCT 07",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Sân Con Voi",
        "tuDien": "TTCT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 7,
        "tuDien2": "Trung Tự Cao Tầng",
        "diaChiLapD": "",
        "longitude": 105.83294325339156,
        "latitude": 21.010247864355467,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1422,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8331725822613,
          21.010334248393203
        ]
      },
      "properties": {
        "tenDoiTuon": "TTCT 06",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Sân Con Voi",
        "tuDien": "TTCT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 7,
        "tuDien2": "Trung Tự Cao Tầng",
        "diaChiLapD": "",
        "longitude": 105.8331725822613,
        "latitude": 21.010334248393203,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1423,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83018694835587,
          21.0238399024456
        ]
      },
      "properties": {
        "tenDoiTuon": "08",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Thịnh Hào 1",
        "tuDien": "ATL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "ATEC Thăng Long",
        "diaChiLapD": "",
        "longitude": 105.83018694835587,
        "latitude": 21.0238399024456,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1424,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83001260477181,
          21.023574514496403
        ]
      },
      "properties": {
        "tenDoiTuon": "09",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Thịnh Hào 1",
        "tuDien": "ATL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "ATEC Thăng Long",
        "diaChiLapD": "",
        "longitude": 105.83001260477181,
        "latitude": 21.023574514496403,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1425,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82990531640822,
          21.02332414807307
        ]
      },
      "properties": {
        "tenDoiTuon": "10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Thịnh Hào 1",
        "tuDien": "ATL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "ATEC Thăng Long",
        "diaChiLapD": "",
        "longitude": 105.82990531640822,
        "latitude": 21.02332414807307,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1426,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82987581211408,
          21.023116343623116
        ]
      },
      "properties": {
        "tenDoiTuon": "11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Thịnh Hào 1",
        "tuDien": "ATL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "ATEC Thăng Long",
        "diaChiLapD": "",
        "longitude": 105.82987581211408,
        "latitude": 21.023116343623116,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1427,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82978193479818,
          21.022835932338975
        ]
      },
      "properties": {
        "tenDoiTuon": "12",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 75 Thịnh Hào 1",
        "tuDien": "ATL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "ATEC Thăng Long",
        "diaChiLapD": "",
        "longitude": 105.82978193479818,
        "latitude": 21.022835932338975,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1428,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83001260477181,
          21.022743296352022
        ]
      },
      "properties": {
        "tenDoiTuon": "13",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 75 Thịnh Hào 1",
        "tuDien": "ATL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "ATEC Thăng Long",
        "diaChiLapD": "",
        "longitude": 105.83001260477181,
        "latitude": 21.022743296352022,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1429,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82981143909232,
          21.02249543221352
        ]
      },
      "properties": {
        "tenDoiTuon": "15",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 75 Thịnh Hào 1",
        "tuDien": "ATL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "ATEC Thăng Long",
        "diaChiLapD": "",
        "longitude": 105.82981143909232,
        "latitude": 21.02249543221352,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1430,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83031032997489,
          21.022655667663685
        ]
      },
      "properties": {
        "tenDoiTuon": "14",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 75 Thịnh Hào 1",
        "tuDien": "ATL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "ATEC Thăng Long",
        "diaChiLapD": "",
        "longitude": 105.83031032997489,
        "latitude": 21.022655667663685,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1431,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82966391760364,
          21.02264815663106
        ]
      },
      "properties": {
        "tenDoiTuon": "16",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 75 Thịnh Hào 1",
        "tuDien": "ATL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "ATEC Thăng Long",
        "diaChiLapD": "",
        "longitude": 105.82966391760364,
        "latitude": 21.02264815663106,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1432,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82970951515323,
          21.023121350961972
        ]
      },
      "properties": {
        "tenDoiTuon": "10-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Thịnh Hào 1",
        "tuDien": "ATL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "ATEC Thăng Long",
        "diaChiLapD": "",
        "longitude": 105.82970951515323,
        "latitude": 21.023121350961972,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1433,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82964782434821,
          21.02289351684159
        ]
      },
      "properties": {
        "tenDoiTuon": "10-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Thịnh Hào 1",
        "tuDien": "ATL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "ATEC Thăng Long",
        "diaChiLapD": "",
        "longitude": 105.82964782434821,
        "latitude": 21.02289351684159,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1434,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82959820348252,
          21.022749555542074
        ]
      },
      "properties": {
        "tenDoiTuon": "10-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 148 Thịnh Hào 1",
        "tuDien": "ATL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "ATEC Thăng Long",
        "diaChiLapD": "",
        "longitude": 105.82959820348252,
        "latitude": 21.022749555542074,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1435,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82954858261685,
          21.022605594103542
        ]
      },
      "properties": {
        "tenDoiTuon": "10-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 148 Thịnh Hào 1",
        "tuDien": "ATL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "ATEC Thăng Long",
        "diaChiLapD": "",
        "longitude": 105.82954858261685,
        "latitude": 21.022605594103542,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1436,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82947884517961,
          21.02274079267627
        ]
      },
      "properties": {
        "tenDoiTuon": "10-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 148 Thịnh Hào 1",
        "tuDien": "ATL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "ATEC Thăng Long",
        "diaChiLapD": "",
        "longitude": 105.82947884517961,
        "latitude": 21.02274079267627,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1437,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83018963056362,
          21.02277834781328
        ]
      },
      "properties": {
        "tenDoiTuon": "14-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 75 Thịnh Hào 1",
        "tuDien": "ATL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "ATEC Thăng Long",
        "diaChiLapD": "",
        "longitude": 105.83018963056362,
        "latitude": 21.02277834781328,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1438,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8302912192335,
          21.024280409928938
        ]
      },
      "properties": {
        "tenDoiTuon": "02",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Nghách  74 Thịnh Hào 1",
        "tuDien": "ATL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "ATEC Thăng Long",
        "diaChiLapD": "",
        "longitude": 105.8302912192335,
        "latitude": 21.024280409928938,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1439,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83031267691341,
          21.024590862239773
        ]
      },
      "properties": {
        "tenDoiTuon": "03",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Nghách  74 Thịnh Hào 1",
        "tuDien": "ATL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "ATEC Thăng Long",
        "diaChiLapD": "",
        "longitude": 105.83031267691341,
        "latitude": 21.024590862239773,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1440,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83033413458433,
          21.025001459463635
        ]
      },
      "properties": {
        "tenDoiTuon": "04",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Nghách  74 Thịnh Hào 1",
        "tuDien": "ATL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "ATEC Thăng Long",
        "diaChiLapD": "",
        "longitude": 105.83033413458433,
        "latitude": 21.025001459463635,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1441,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83033145236759,
          21.02521426855606
        ]
      },
      "properties": {
        "tenDoiTuon": "05",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Nghách  74 Thịnh Hào 1",
        "tuDien": "ATL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "ATEC Thăng Long",
        "diaChiLapD": "",
        "longitude": 105.83033145236759,
        "latitude": 21.02521426855606,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1442,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8298429550378,
          21.025365248077613
        ]
      },
      "properties": {
        "tenDoiTuon": "07",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Nghách  74 Thịnh Hào 1",
        "tuDien": "ATL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "ATEC Thăng Long",
        "diaChiLapD": "",
        "longitude": 105.8298429550378,
        "latitude": 21.025365248077613,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1443,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83011117593776,
          21.025300153629907
        ]
      },
      "properties": {
        "tenDoiTuon": "06",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Nghách  74 Thịnh Hào 1",
        "tuDien": "ATL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "ATEC Thăng Long",
        "diaChiLapD": "",
        "longitude": 105.83011117593776,
        "latitude": 21.025300153629907,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1444,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8299613075285,
          21.024703526225036
        ]
      },
      "properties": {
        "tenDoiTuon": "3-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Nghách  74 Thịnh Hào 1",
        "tuDien": "ATL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "ATEC Thăng Long",
        "diaChiLapD": "",
        "longitude": 105.8299613075285,
        "latitude": 21.024703526225036,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1445,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8296206669836,
          21.02481368648339
        ]
      },
      "properties": {
        "tenDoiTuon": "3-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Nghách  74 Thịnh Hào 1",
        "tuDien": "ATL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "ATEC Thăng Long",
        "diaChiLapD": "",
        "longitude": 105.8296206669836,
        "latitude": 21.02481368648339,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1446,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83271425978207,
          21.026620814264437
        ]
      },
      "properties": {
        "tenDoiTuon": "06",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Đặng Trần Côn",
        "tuDien": "ĐTĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Đoàn Thị Điểm",
        "diaChiLapD": "",
        "longitude": 105.83271425978207,
        "latitude": 21.026620814264437,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1447,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83285239354367,
          21.02691248465631
        ]
      },
      "properties": {
        "tenDoiTuon": "05",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Đặng Trần Côn",
        "tuDien": "ĐTĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Đoàn Thị Điểm",
        "diaChiLapD": "",
        "longitude": 105.83285239354367,
        "latitude": 21.02691248465631,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1448,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83296772853046,
          21.027201650876602
        ]
      },
      "properties": {
        "tenDoiTuon": "04",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Đặng Trần Côn",
        "tuDien": "ĐTĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Đoàn Thị Điểm",
        "diaChiLapD": "",
        "longitude": 105.83296772853046,
        "latitude": 21.027201650876602,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1449,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83307635799792,
          21.027443248197557
        ]
      },
      "properties": {
        "tenDoiTuon": "03",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Đặng Trần Côn",
        "tuDien": "ĐTĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Đoàn Thị Điểm",
        "diaChiLapD": "",
        "longitude": 105.83307635799792,
        "latitude": 21.027443248197557,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1450,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83317130819513,
          21.027676082550112
        ]
      },
      "properties": {
        "tenDoiTuon": "02",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Đặng Trần Côn",
        "tuDien": "ĐTĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Đoàn Thị Điểm",
        "diaChiLapD": "",
        "longitude": 105.83317130819513,
        "latitude": 21.027676082550112,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1451,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83328959361222,
          21.027951477551866
        ]
      },
      "properties": {
        "tenDoiTuon": "01",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Đặng Trần Côn",
        "tuDien": "ĐTĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Đoàn Thị Điểm",
        "diaChiLapD": "",
        "longitude": 105.83328959361222,
        "latitude": 21.027951477551866,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1452,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83263245242796,
          21.028233131005138
        ]
      },
      "properties": {
        "tenDoiTuon": "07",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Bích Câu",
        "tuDien": "ĐTĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Đoàn Thị Điểm",
        "diaChiLapD": "",
        "longitude": 105.83263245242796,
        "latitude": 21.028233131005138,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1453,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83276723343438,
          21.0261269761833
        ]
      },
      "properties": {
        "tenDoiTuon": "13",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Đoàn Thị Điểm",
        "tuDien": "ĐTĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Đoàn Thị Điểm",
        "diaChiLapD": "",
        "longitude": 105.83276723343438,
        "latitude": 21.0261269761833,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1454,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83246347325938,
          21.026250279380974
        ]
      },
      "properties": {
        "tenDoiTuon": "14",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Đoàn Thị Điểm",
        "tuDien": "ĐTĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Đoàn Thị Điểm",
        "diaChiLapD": "",
        "longitude": 105.83246347325938,
        "latitude": 21.026250279380974,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1455,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8322314621819,
          21.02634541680388
        ]
      },
      "properties": {
        "tenDoiTuon": "15",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Đoàn Thị Điểm",
        "tuDien": "ĐTĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Đoàn Thị Điểm",
        "diaChiLapD": "",
        "longitude": 105.8322314621819,
        "latitude": 21.02634541680388,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1456,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83195251244197,
          21.026455575849646
        ]
      },
      "properties": {
        "tenDoiTuon": "16",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Đoàn Thị Điểm",
        "tuDien": "ĐTĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Đoàn Thị Điểm",
        "diaChiLapD": "",
        "longitude": 105.83195251244197,
        "latitude": 21.026455575849646,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1457,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83174017089992,
          21.02653861234861
        ]
      },
      "properties": {
        "tenDoiTuon": "17",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Đoàn Thị Điểm",
        "tuDien": "ĐTĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Đoàn Thị Điểm",
        "diaChiLapD": "",
        "longitude": 105.83174017089992,
        "latitude": 21.02653861234861,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1458,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83143529314134,
          21.02666170657141
        ]
      },
      "properties": {
        "tenDoiTuon": "18",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Đoàn Thị Điểm",
        "tuDien": "ĐTĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Đoàn Thị Điểm",
        "diaChiLapD": "",
        "longitude": 105.83143529314134,
        "latitude": 21.02666170657141,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1459,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83123233932189,
          21.026838628258954
        ]
      },
      "properties": {
        "tenDoiTuon": "19",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Đoàn Thị Điểm",
        "tuDien": "ĐTĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Đoàn Thị Điểm",
        "diaChiLapD": "",
        "longitude": 105.83123233932189,
        "latitude": 21.026838628258954,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1460,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83257880824618,
          21.0267109442319
        ]
      },
      "properties": {
        "tenDoiTuon": "6-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 32 Đặng Trần Côn",
        "tuDien": "ĐTĐ",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 5,
        "tuDien2": "Đoàn Thị Điểm",
        "diaChiLapD": "",
        "longitude": 105.83257880824618,
        "latitude": 21.0267109442319,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1461,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83214160817762,
          21.027905161155527
        ]
      },
      "properties": {
        "tenDoiTuon": "9-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 10 Bích Câu",
        "tuDien": "ĐTĐ",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Đoàn Thị Điểm",
        "diaChiLapD": "",
        "longitude": 105.83214160817762,
        "latitude": 21.027905161155527,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1462,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83203431981403,
          21.027845074998044
        ]
      },
      "properties": {
        "tenDoiTuon": "9-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 10 Bích Câu",
        "tuDien": "ĐTĐ",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Đoàn Thị Điểm",
        "diaChiLapD": "",
        "longitude": 105.83203431981403,
        "latitude": 21.027845074998044,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1463,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83207991737261,
          21.027209161679835
        ]
      },
      "properties": {
        "tenDoiTuon": "10-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 38+44 Bích Câu",
        "tuDien": "ĐTĐ",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Đoàn Thị Điểm",
        "diaChiLapD": "",
        "longitude": 105.83207991737261,
        "latitude": 21.027209161679835,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1464,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83254930394305,
          21.028005304697764
        ]
      },
      "properties": {
        "tenDoiTuon": "08",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Bích Câu",
        "tuDien": "ĐTĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Đoàn Thị Điểm",
        "diaChiLapD": "",
        "longitude": 105.83254930394305,
        "latitude": 21.028005304697764,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1465,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83244738000394,
          21.027729909795305
        ]
      },
      "properties": {
        "tenDoiTuon": "09",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Bích Câu",
        "tuDien": "ĐTĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Đoàn Thị Điểm",
        "diaChiLapD": "",
        "longitude": 105.83244738000394,
        "latitude": 21.027729909795305,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1466,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83227571861862,
          21.027279262493895
        ]
      },
      "properties": {
        "tenDoiTuon": "10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Bích Câu",
        "tuDien": "ĐTĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Đoàn Thị Điểm",
        "diaChiLapD": "",
        "longitude": 105.83227571861862,
        "latitude": 21.027279262493895,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1467,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83211411554377,
          21.026899340723425
        ]
      },
      "properties": {
        "tenDoiTuon": "11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Bích Câu",
        "tuDien": "ĐTĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Đoàn Thị Điểm",
        "diaChiLapD": "",
        "longitude": 105.83211411554377,
        "latitude": 21.026899340723425,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1468,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8320235909741,
          21.02663333231869
        ]
      },
      "properties": {
        "tenDoiTuon": "12",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Bích Câu",
        "tuDien": "ĐTĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Đoàn Thị Điểm",
        "diaChiLapD": "",
        "longitude": 105.8320235909741,
        "latitude": 21.02663333231869,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1469,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83183382469998,
          21.026853024000122
        ]
      },
      "properties": {
        "tenDoiTuon": "11-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 38+44 Bích Câu",
        "tuDien": "ĐTĐ",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "Đoàn Thị Điểm",
        "diaChiLapD": "",
        "longitude": 105.83183382469998,
        "latitude": 21.026853024000122,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1470,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83154951054459,
          21.02639736681798
        ]
      },
      "properties": {
        "tenDoiTuon": "17-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 35 Đoàn Thị Điểm",
        "tuDien": "ĐTĐ",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "Đoàn Thị Điểm",
        "diaChiLapD": "",
        "longitude": 105.83154951054459,
        "latitude": 21.02639736681798,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1471,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8314475865965,
          21.026363568015576
        ]
      },
      "properties": {
        "tenDoiTuon": "17-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 35 Đoàn Thị Điểm",
        "tuDien": "ĐTĐ",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "Đoàn Thị Điểm",
        "diaChiLapD": "",
        "longitude": 105.8314475865965,
        "latitude": 21.026363568015576,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1472,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83167959768295,
          21.026764145923117
        ]
      },
      "properties": {
        "tenDoiTuon": "17-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 40 Đoàn Thị Điểm",
        "tuDien": "ĐTĐ",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Đoàn Thị Điểm",
        "diaChiLapD": "",
        "longitude": 105.83167959768295,
        "latitude": 21.026764145923117,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1473,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83158706147094,
          21.026878060068515
        ]
      },
      "properties": {
        "tenDoiTuon": "18-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 52 Đoàn Thị Điểm",
        "tuDien": "ĐTĐ",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Đoàn Thị Điểm",
        "diaChiLapD": "",
        "longitude": 105.83158706147094,
        "latitude": 21.026878060068515,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1474,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83150123077827,
          21.027065830449086
        ]
      },
      "properties": {
        "tenDoiTuon": "18-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 52 Đoàn Thị Điểm",
        "tuDien": "ĐTĐ",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 5,
        "tuDien2": "Đoàn Thị Điểm",
        "diaChiLapD": "",
        "longitude": 105.83150123077827,
        "latitude": 21.027065830449086,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1475,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8311981411597,
          21.027020765579067
        ]
      },
      "properties": {
        "tenDoiTuon": "19-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 68 Đoàn Thị Điểm",
        "tuDien": "ĐTĐ",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Đoàn Thị Điểm",
        "diaChiLapD": "",
        "longitude": 105.8311981411597,
        "latitude": 21.027020765579067,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1476,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83135773260119,
          21.02704454981795
        ]
      },
      "properties": {
        "tenDoiTuon": "19-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 68 Đoàn Thị Điểm",
        "tuDien": "ĐTĐ",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Đoàn Thị Điểm",
        "diaChiLapD": "",
        "longitude": 105.83135773260119,
        "latitude": 21.02704454981795,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1477,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83116729576169,
          21.026591396832423
        ]
      },
      "properties": {
        "tenDoiTuon": "20",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ An Trạch 1",
        "tuDien": "ĐTĐ",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Đoàn Thị Điểm",
        "diaChiLapD": "",
        "longitude": 105.83116729576169,
        "latitude": 21.026591396832423,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1478,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83092455584425,
          21.02674161344421
        ]
      },
      "properties": {
        "tenDoiTuon": "21",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ An Trạch 1",
        "tuDien": "ĐTĐ",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Đoàn Thị Điểm",
        "diaChiLapD": "",
        "longitude": 105.83092455584425,
        "latitude": 21.02674161344421,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1479,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83071802574932,
          21.026839254160464
        ]
      },
      "properties": {
        "tenDoiTuon": "22",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ An Trạch 1",
        "tuDien": "ĐTĐ",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Đoàn Thị Điểm",
        "diaChiLapD": "",
        "longitude": 105.83071802574932,
        "latitude": 21.026839254160464,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1480,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83045651036869,
          21.02690184433019
        ]
      },
      "properties": {
        "tenDoiTuon": "23",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ An Trạch 1",
        "tuDien": "ĐTĐ",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Đoàn Thị Điểm",
        "diaChiLapD": "",
        "longitude": 105.83045651036869,
        "latitude": 21.02690184433019,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1481,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8305101545505,
          21.027078348465924
        ]
      },
      "properties": {
        "tenDoiTuon": "23-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 29  An Trạch 1",
        "tuDien": "ĐTĐ",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Đoàn Thị Điểm",
        "diaChiLapD": "",
        "longitude": 105.8305101545505,
        "latitude": 21.027078348465924,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1482,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8304028661779,
          21.02670906652654
        ]
      },
      "properties": {
        "tenDoiTuon": "23-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 29  An Trạch 1",
        "tuDien": "ĐTĐ",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Đoàn Thị Điểm",
        "diaChiLapD": "",
        "longitude": 105.8304028661779,
        "latitude": 21.02670906652654,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1483,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83028216676665,
          21.02688431908707
        ]
      },
      "properties": {
        "tenDoiTuon": "24",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ An Trạch 1",
        "tuDien": "ĐTĐ",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "ĐTĐ",
        "diaChiLapD": "",
        "longitude": 105.83028216676665,
        "latitude": 21.02688431908707,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1484,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83006759004847,
          21.02685177219802
        ]
      },
      "properties": {
        "tenDoiTuon": "25",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ An Trạch 1",
        "tuDien": "ĐTĐ",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Đoàn Thị Điểm",
        "diaChiLapD": "",
        "longitude": 105.83006759004847,
        "latitude": 21.02685177219802,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1485,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82979400472404,
          21.02690935515018
        ]
      },
      "properties": {
        "tenDoiTuon": "26",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ An Trạch 1",
        "tuDien": "ĐTĐ",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Đoàn Thị Điểm",
        "diaChiLapD": "",
        "longitude": 105.82979400472404,
        "latitude": 21.02690935515018,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1486,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82957406359039,
          21.027142190337432
        ]
      },
      "properties": {
        "tenDoiTuon": "27",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ An Trạch 1",
        "tuDien": "ĐTĐ",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Đoàn Thị Điểm",
        "diaChiLapD": "",
        "longitude": 105.82957406359039,
        "latitude": 21.027142190337432,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1487,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82936216907994,
          21.02727738479484
        ]
      },
      "properties": {
        "tenDoiTuon": "28",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ An Trạch 1",
        "tuDien": "ĐTĐ",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Đoàn Thị Điểm",
        "diaChiLapD": "",
        "longitude": 105.82936216907994,
        "latitude": 21.02727738479484,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1488,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8291610034005,
          21.027385039553245
        ]
      },
      "properties": {
        "tenDoiTuon": "29",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ An Trạch 1",
        "tuDien": "ĐTĐ",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Đoàn Thị Điểm",
        "diaChiLapD": "",
        "longitude": 105.8291610034005,
        "latitude": 21.027385039553245,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1489,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82970012740815,
          21.027339974780585
        ]
      },
      "properties": {
        "tenDoiTuon": "27-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 6  An Trạch 1",
        "tuDien": "ĐTĐ",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Đoàn Thị Điểm",
        "diaChiLapD": "",
        "longitude": 105.82970012740815,
        "latitude": 21.027339974780585,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1490,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82926259207936,
          21.027547460392068
        ]
      },
      "properties": {
        "tenDoiTuon": "29-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 34  An Trạch 1",
        "tuDien": "ĐTĐ",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 5,
        "tuDien2": "Đoàn Thị Điểm",
        "diaChiLapD": "",
        "longitude": 105.82926259207936,
        "latitude": 21.027547460392068,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1491,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82905606198439,
          21.027442309347812
        ]
      },
      "properties": {
        "tenDoiTuon": "29-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 34  An Trạch 1",
        "tuDien": "ĐTĐ",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Đoàn Thị Điểm",
        "diaChiLapD": "",
        "longitude": 105.82905606198439,
        "latitude": 21.027442309347812,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1492,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82970817404036,
          21.026486245116203
        ]
      },
      "properties": {
        "tenDoiTuon": "25-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 39 An Trạch 1",
        "tuDien": "ĐTĐ",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Đoàn Thị Điểm",
        "diaChiLapD": "",
        "longitude": 105.82970817404036,
        "latitude": 21.026486245116203,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1493,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82997103052485,
          21.02651378485864
        ]
      },
      "properties": {
        "tenDoiTuon": "25-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 39 An Trạch 1",
        "tuDien": "ĐTĐ",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Đoàn Thị Điểm",
        "diaChiLapD": "",
        "longitude": 105.82997103052485,
        "latitude": 21.02651378485864,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1494,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83020170049846,
          21.026646476275822
        ]
      },
      "properties": {
        "tenDoiTuon": "24-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 33 An Trạch 1",
        "tuDien": "ĐTĐ",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 5,
        "tuDien2": "Đoàn Thị Điểm",
        "diaChiLapD": "",
        "longitude": 105.83020170049846,
        "latitude": 21.026646476275822,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1495,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83013732747672,
          21.026468719822518
        ]
      },
      "properties": {
        "tenDoiTuon": "24-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 33 An Trạch 1",
        "tuDien": "ĐTĐ",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 5,
        "tuDien2": "Đoàn Thị Điểm",
        "diaChiLapD": "",
        "longitude": 105.83013732747672,
        "latitude": 21.026468719822518,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1496,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83011586980581,
          21.026248401669783
        ]
      },
      "properties": {
        "tenDoiTuon": "24-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 33 An Trạch 1",
        "tuDien": "ĐTĐ",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 5,
        "tuDien2": "Đoàn Thị Điểm",
        "diaChiLapD": "",
        "longitude": 105.83011586980581,
        "latitude": 21.026248401669783,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1497,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83006457258048,
          21.026037784720504
        ]
      },
      "properties": {
        "tenDoiTuon": "24-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 33 An Trạch 1",
        "tuDien": "ĐTĐ",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 5,
        "tuDien2": "Đoàn Thị Điểm",
        "diaChiLapD": "",
        "longitude": 105.83006457258048,
        "latitude": 21.026037784720504,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1498,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83028753118212,
          21.02624589805291
        ]
      },
      "properties": {
        "tenDoiTuon": "24-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 33 An Trạch 1",
        "tuDien": "ĐTĐ",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Đoàn Thị Điểm",
        "diaChiLapD": "",
        "longitude": 105.83028753118212,
        "latitude": 21.02624589805291,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1499,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83077837543249,
          21.02659890766808
        ]
      },
      "properties": {
        "tenDoiTuon": "21-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 11  An Trạch 1",
        "tuDien": "ĐTĐ",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "Đoàn Thị Điểm",
        "diaChiLapD": "",
        "longitude": 105.83077837543249,
        "latitude": 21.02659890766808,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1500,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83113242702512,
          21.02637858970862
        ]
      },
      "properties": {
        "tenDoiTuon": "30",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ  An Trạch 2",
        "tuDien": "ĐTĐ",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Đoàn Thị Điểm",
        "diaChiLapD": "",
        "longitude": 105.83113242702512,
        "latitude": 21.02637858970862,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1501,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83106000738013,
          21.026060630260314
        ]
      },
      "properties": {
        "tenDoiTuon": "31",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ  An Trạch 2",
        "tuDien": "ĐTĐ",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Đoàn Thị Điểm",
        "diaChiLapD": "",
        "longitude": 105.83106000738013,
        "latitude": 21.026060630260314,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1502,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83100636319834,
          21.025818989667762
        ]
      },
      "properties": {
        "tenDoiTuon": "32",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ  An Trạch 2",
        "tuDien": "ĐTĐ",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Đoàn Thị Điểm",
        "diaChiLapD": "",
        "longitude": 105.83100636319834,
        "latitude": 21.025818989667762,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1503,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83124239759643,
          21.025797749889147
        ]
      },
      "properties": {
        "tenDoiTuon": "33",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ  An Trạch 2",
        "tuDien": "ĐTĐ",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Đoàn Thị Điểm",
        "diaChiLapD": "",
        "longitude": 105.83124239759643,
        "latitude": 21.025797749889147,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1504,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8312531264274,
          21.025499818241258
        ]
      },
      "properties": {
        "tenDoiTuon": "34",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ  An Trạch 2",
        "tuDien": "ĐTĐ",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Đoàn Thị Điểm",
        "diaChiLapD": "",
        "longitude": 105.8312531264274,
        "latitude": 21.025499818241258,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1505,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83115388469602,
          21.025271987755968
        ]
      },
      "properties": {
        "tenDoiTuon": "34-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ  An Trạch 2",
        "tuDien": "ĐTĐ",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Đoàn Thị Điểm",
        "diaChiLapD": "",
        "longitude": 105.83115388469602,
        "latitude": 21.025271987755968,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1506,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83116461353598,
          21.025572423487496
        ]
      },
      "properties": {
        "tenDoiTuon": "34-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 68  An Trạch 2",
        "tuDien": "ĐTĐ",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "Đoàn Thị Điểm",
        "diaChiLapD": "",
        "longitude": 105.83116461353598,
        "latitude": 21.025572423487496,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1507,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83116193132824,
          21.02590790933886
        ]
      },
      "properties": {
        "tenDoiTuon": "33-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 59  An Trạch 2",
        "tuDien": "ĐTĐ",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "Đoàn Thị Điểm",
        "diaChiLapD": "",
        "longitude": 105.83116193132824,
        "latitude": 21.02590790933886,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1508,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83082397299106,
          21.02611570989821
        ]
      },
      "properties": {
        "tenDoiTuon": "35",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 20  An Trạch 2",
        "tuDien": "ĐTĐ",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Đoàn Thị Điểm",
        "diaChiLapD": "",
        "longitude": 105.83082397299106,
        "latitude": 21.02611570989821,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1509,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83063353615152,
          21.026105695420643
        ]
      },
      "properties": {
        "tenDoiTuon": "36",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 20  An Trạch 2",
        "tuDien": "ĐTĐ",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Đoàn Thị Điểm",
        "diaChiLapD": "",
        "longitude": 105.83063353615152,
        "latitude": 21.026105695420643,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1510,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83057452754527,
          21.02624589805291
        ]
      },
      "properties": {
        "tenDoiTuon": "36-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 20  An Trạch 2",
        "tuDien": "ĐTĐ",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Đoàn Thị Điểm",
        "diaChiLapD": "",
        "longitude": 105.83057452754527,
        "latitude": 21.02624589805291,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1511,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83038677292248,
          21.026341035478616
        ]
      },
      "properties": {
        "tenDoiTuon": "36-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 20  An Trạch 2",
        "tuDien": "ĐTĐ",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Đoàn Thị Điểm",
        "diaChiLapD": "",
        "longitude": 105.83038677292248,
        "latitude": 21.026341035478616,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1512,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83048333244612,
          21.0265062740212
        ]
      },
      "properties": {
        "tenDoiTuon": "36-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 20  An Trạch 2",
        "tuDien": "ĐTĐ",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Đoàn Thị Điểm",
        "diaChiLapD": "",
        "longitude": 105.83048333244612,
        "latitude": 21.0265062740212,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1513,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83057586864915,
          21.02599428430697
        ]
      },
      "properties": {
        "tenDoiTuon": "37",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 20  An Trạch 2",
        "tuDien": "ĐTĐ",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Đoàn Thị Điểm",
        "diaChiLapD": "",
        "longitude": 105.83057586864915,
        "latitude": 21.02599428430697,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1514,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83046321587008,
          21.025775217265817
        ]
      },
      "properties": {
        "tenDoiTuon": "38",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 20  An Trạch 2",
        "tuDien": "ĐTĐ",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Đoàn Thị Điểm",
        "diaChiLapD": "",
        "longitude": 105.83046321587008,
        "latitude": 21.025775217265817,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1515,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83034653977941,
          21.025622496051923
        ]
      },
      "properties": {
        "tenDoiTuon": "39",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 20  An Trạch 2",
        "tuDien": "ĐTĐ",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Đoàn Thị Điểm",
        "diaChiLapD": "",
        "longitude": 105.83034653977941,
        "latitude": 21.025622496051923,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1516,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82999382929064,
          21.02569384942572
        ]
      },
      "properties": {
        "tenDoiTuon": "40",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 20  An Trạch 2",
        "tuDien": "ĐTĐ",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Đoàn Thị Điểm",
        "diaChiLapD": "",
        "longitude": 105.82999382929064,
        "latitude": 21.02569384942572,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1517,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83061476067938,
          21.02557868256084
        ]
      },
      "properties": {
        "tenDoiTuon": "41",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 20  An Trạch 2",
        "tuDien": "ĐTĐ",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Đoàn Thị Điểm",
        "diaChiLapD": "",
        "longitude": 105.83061476067938,
        "latitude": 21.02557868256084,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1518,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83085615949292,
          21.025484796462997
        ]
      },
      "properties": {
        "tenDoiTuon": "43",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 20  An Trạch 2",
        "tuDien": "ĐTĐ",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Đoàn Thị Điểm",
        "diaChiLapD": "",
        "longitude": 105.83085615949292,
        "latitude": 21.025484796462997,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1519,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83094199017663,
          21.025589907855927
        ]
      },
      "properties": {
        "tenDoiTuon": "42",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 20  An Trạch 2",
        "tuDien": "ĐTĐ",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Đoàn Thị Điểm",
        "diaChiLapD": "",
        "longitude": 105.83094199017663,
        "latitude": 21.025589907855927,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1520,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83077301100802,
          21.025338334033332
        ]
      },
      "properties": {
        "tenDoiTuon": "44",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 20  An Trạch 2",
        "tuDien": "ĐTĐ",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Đoàn Thị Điểm",
        "diaChiLapD": "",
        "longitude": 105.83077301100802,
        "latitude": 21.025338334033332,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1521,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83067913370112,
          21.0251304913587
        ]
      },
      "properties": {
        "tenDoiTuon": "45",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 20  An Trạch 2",
        "tuDien": "ĐTĐ",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Đoàn Thị Điểm",
        "diaChiLapD": "",
        "longitude": 105.83067913370112,
        "latitude": 21.0251304913587,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1522,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83235819653812,
          21.025894139414397
        ]
      },
      "properties": {
        "tenDoiTuon": "14-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 23 Đoàn Thị Điểm",
        "tuDien": "ĐTĐ",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "Đoàn Thị Điểm",
        "diaChiLapD": "",
        "longitude": 105.83235819653812,
        "latitude": 21.025894139414397,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1523,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8008055278953,
          21.022847981277977
        ]
      },
      "properties": {
        "tenDoiTuon": "03",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Chùa Láng",
        "tuDien": "LTG3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 8,
        "tuDien2": "Láng Thượng 3",
        "diaChiLapD": "",
        "longitude": 105.8008055278953,
        "latitude": 21.022847981277977,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1524,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80113948967208,
          21.02297141516538
        ]
      },
      "properties": {
        "tenDoiTuon": "04",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Chùa Láng",
        "tuDien": "LTG3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 8,
        "tuDien2": "Láng Thượng 3",
        "diaChiLapD": "",
        "longitude": 105.80113948967208,
        "latitude": 21.02297141516538,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1525,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80143852921886,
          21.023060793444728
        ]
      },
      "properties": {
        "tenDoiTuon": "05",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Chùa Láng",
        "tuDien": "LTG3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 8,
        "tuDien2": "Láng Thượng 3",
        "diaChiLapD": "",
        "longitude": 105.80143852921886,
        "latitude": 21.023060793444728,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1526,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8017210274889,
          21.023155933198417
        ]
      },
      "properties": {
        "tenDoiTuon": "06",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Chùa Láng",
        "tuDien": "LTG3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 8,
        "tuDien2": "Láng Thượng 3",
        "diaChiLapD": "",
        "longitude": 105.8017210274889,
        "latitude": 21.023155933198417,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1527,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80203042288463,
          21.023224544237156
        ]
      },
      "properties": {
        "tenDoiTuon": "07",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Chùa Láng",
        "tuDien": "LTG3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 8,
        "tuDien2": "Láng Thượng 3",
        "diaChiLapD": "",
        "longitude": 105.80203042288463,
        "latitude": 21.023224544237156,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1528,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80235735347223,
          21.0233075612826
        ]
      },
      "properties": {
        "tenDoiTuon": "08",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Chùa Láng",
        "tuDien": "LTG3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 9,
        "tuDien2": "Láng Thượng 3",
        "diaChiLapD": "",
        "longitude": 105.80235735347223,
        "latitude": 21.0233075612826,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1529,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80266312529854,
          21.023357634606974
        ]
      },
      "properties": {
        "tenDoiTuon": "09",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Chùa Láng",
        "tuDien": "LTG3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 9,
        "tuDien2": "Láng Thượng 3",
        "diaChiLapD": "",
        "longitude": 105.80266312529854,
        "latitude": 21.023357634606974,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1530,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80298230817255,
          21.023400196918914
        ]
      },
      "properties": {
        "tenDoiTuon": "10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Chùa Láng",
        "tuDien": "LTG3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 8,
        "tuDien2": "Láng Thượng 3",
        "diaChiLapD": "",
        "longitude": 105.80298230817255,
        "latitude": 21.023400196918914,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1531,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80332555090276,
          21.023439802795156
        ]
      },
      "properties": {
        "tenDoiTuon": "LT3 11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Chùa Láng",
        "tuDien": "LTG3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 8,
        "tuDien2": "Láng Thượng 3",
        "diaChiLapD": "",
        "longitude": 105.80332555090276,
        "latitude": 21.023439802795156,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1532,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80516830849739,
          21.02351912096306
        ]
      },
      "properties": {
        "tenDoiTuon": "01",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 91 Chùa Láng",
        "tuDien": "ĐHNT",
        "chuDauTu": "",
        "loaiCot": "Tận dụng",
        "chieuCao": 10,
        "tuDien2": "TT Đại Học Ngoại Thương",
        "diaChiLapD": "",
        "longitude": 105.80516830849739,
        "latitude": 21.02351912096306,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1533,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80447869428782,
          21.022777950189315
        ]
      },
      "properties": {
        "tenDoiTuon": "10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 121 Chùa Láng",
        "tuDien": "ĐHNT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "TT Đại Học Ngoại Thương",
        "diaChiLapD": "",
        "longitude": 105.80447869428782,
        "latitude": 21.022777950189315,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1534,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80475793051525,
          21.023108519651302
        ]
      },
      "properties": {
        "tenDoiTuon": "3-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 91 Chùa Láng",
        "tuDien": "ĐHNT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "TT Đại Học Ngoại Thương",
        "diaChiLapD": "",
        "longitude": 105.80475793051525,
        "latitude": 21.023108519651302,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1535,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80449909735134,
          21.023308813117815
        ]
      },
      "properties": {
        "tenDoiTuon": "06",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 121 Chùa Láng",
        "tuDien": "ĐHNT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "TT Đại Học Ngoại Thương",
        "diaChiLapD": "",
        "longitude": 105.80449909735134,
        "latitude": 21.023308813117815,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1536,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80449641513461,
          21.02312228983977
        ]
      },
      "properties": {
        "tenDoiTuon": "07",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 121 Chùa Láng",
        "tuDien": "ĐHNT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "TT Đại Học Ngoại Thương",
        "diaChiLapD": "",
        "longitude": 105.80449641513461,
        "latitude": 21.02312228983977,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1537,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80425391589735,
          21.023126693681466
        ]
      },
      "properties": {
        "tenDoiTuon": "08",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 121 Chùa Láng",
        "tuDien": "ĐHNT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "TT Đại Học Ngoại Thương",
        "diaChiLapD": "",
        "longitude": 105.80425391589735,
        "latitude": 21.023126693681466,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1538,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80401137760595,
          21.023124993976836
        ]
      },
      "properties": {
        "tenDoiTuon": "09",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 121 Chùa Láng",
        "tuDien": "ĐHNT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "TT Đại Học Ngoại Thương",
        "diaChiLapD": "",
        "longitude": 105.80401137760595,
        "latitude": 21.023124993976836,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1539,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80447361635083,
          21.02261905137676
        ]
      },
      "properties": {
        "tenDoiTuon": "11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 121 Chùa Láng",
        "tuDien": "ĐHNT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TT Đại Học Ngoại Thương",
        "diaChiLapD": "",
        "longitude": 105.80447361635083,
        "latitude": 21.02261905137676,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1540,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80447361635083,
          21.02261905137676
        ]
      },
      "properties": {
        "tenDoiTuon": "11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 121 Chùa Láng",
        "tuDien": "ĐHNT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "TT Đại Học Ngoại Thương",
        "diaChiLapD": "",
        "longitude": 105.80447361635083,
        "latitude": 21.02261905137676,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1541,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80445215867992,
          21.022299832068303
        ]
      },
      "properties": {
        "tenDoiTuon": "14",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 121 Chùa Láng",
        "tuDien": "ĐHNT",
        "chuDauTu": "",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "TT Đại Học Ngoại Thương",
        "diaChiLapD": "",
        "longitude": 105.80445215867992,
        "latitude": 21.022299832068303,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1542,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8044447585685,
          21.022141469607547
        ]
      },
      "properties": {
        "tenDoiTuon": "17",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 121 Chùa Láng",
        "tuDien": "ĐHNT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "TT Đại Học Ngoại Thương",
        "diaChiLapD": "",
        "longitude": 105.8044447585685,
        "latitude": 21.022141469607547,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1543,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80419617474628,
          21.022641454038137
        ]
      },
      "properties": {
        "tenDoiTuon": "12",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 121 Chùa Láng",
        "tuDien": "ĐHNT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "TT Đại Học Ngoại Thương",
        "diaChiLapD": "",
        "longitude": 105.80419617474628,
        "latitude": 21.022641454038137,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1544,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8039559500141,
          21.02265159918999
        ]
      },
      "properties": {
        "tenDoiTuon": "13",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 121 Chùa Láng",
        "tuDien": "ĐHNT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "TT Đại Học Ngoại Thương",
        "diaChiLapD": "",
        "longitude": 105.8039559500141,
        "latitude": 21.02265159918999,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1545,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80418125557225,
          21.022344898364725
        ]
      },
      "properties": {
        "tenDoiTuon": "15",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 121 Chùa Láng",
        "tuDien": "ĐHNT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "TT Đại Học Ngoại Thương",
        "diaChiLapD": "",
        "longitude": 105.80418125557225,
        "latitude": 21.022344898364725,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1546,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80394119786253,
          21.022318609693148
        ]
      },
      "properties": {
        "tenDoiTuon": "16",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 121 Chùa Láng",
        "tuDien": "ĐHNT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "TT Đại Học Ngoại Thương",
        "diaChiLapD": "",
        "longitude": 105.80394119786253,
        "latitude": 21.022318609693148,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1547,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80419198440319,
          21.022053219035783
        ]
      },
      "properties": {
        "tenDoiTuon": "18",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 121 Chùa Láng",
        "tuDien": "ĐHNT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "TT Đại Học Ngoại Thương",
        "diaChiLapD": "",
        "longitude": 105.80419198440319,
        "latitude": 21.022053219035783,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1548,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80392912791872,
          21.02199688605229
        ]
      },
      "properties": {
        "tenDoiTuon": "19",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 121 Chùa Láng",
        "tuDien": "ĐHNT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "TT Đại Học Ngoại Thương",
        "diaChiLapD": "",
        "longitude": 105.80392912791872,
        "latitude": 21.02199688605229,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1549,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80272134308461,
          21.023588107265624
        ]
      },
      "properties": {
        "tenDoiTuon": "9-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Chùa Láng",
        "tuDien": "LTG3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "Láng Thượng 3",
        "diaChiLapD": "",
        "longitude": 105.80272134308461,
        "latitude": 21.023588107265624,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1550,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8027579636845,
          21.02387830268204
        ]
      },
      "properties": {
        "tenDoiTuon": "9-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Chùa Láng",
        "tuDien": "LTG3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "Láng Thượng 3",
        "diaChiLapD": "",
        "longitude": 105.8027579636845,
        "latitude": 21.02387830268204,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1551,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80278626202114,
          21.024196990233186
        ]
      },
      "properties": {
        "tenDoiTuon": "9-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Chùa Láng",
        "tuDien": "LTG3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "Láng Thượng 3",
        "diaChiLapD": "",
        "longitude": 105.80278626202114,
        "latitude": 21.024196990233186,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1552,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80319587902731,
          21.024313092998995
        ]
      },
      "properties": {
        "tenDoiTuon": "9-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Chùa Láng",
        "tuDien": "LTG3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "Láng Thượng 3",
        "diaChiLapD": "",
        "longitude": 105.80319587902731,
        "latitude": 21.024313092998995,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1553,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80252800898157,
          21.024483341083084
        ]
      },
      "properties": {
        "tenDoiTuon": "9-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Chùa Láng",
        "tuDien": "LTG3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "Láng Thượng 3",
        "diaChiLapD": "",
        "longitude": 105.80252800898157,
        "latitude": 21.024483341083084,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1554,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80072187646869,
          21.023014006089596
        ]
      },
      "properties": {
        "tenDoiTuon": "35",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 994 Phố Chùa Láng",
        "tuDien": "LTG3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Láng Thượng 3",
        "diaChiLapD": "",
        "longitude": 105.80072187646869,
        "latitude": 21.023014006089596,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1555,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80060385927405,
          21.023253106530213
        ]
      },
      "properties": {
        "tenDoiTuon": "36",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 994 Phố Chùa Láng",
        "tuDien": "LTG3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Láng Thượng 3",
        "diaChiLapD": "",
        "longitude": 105.80060385927405,
        "latitude": 21.023253106530213,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1556,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80042415127207,
          21.023334475702555
        ]
      },
      "properties": {
        "tenDoiTuon": "37",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 994 Phố Chùa Láng",
        "tuDien": "LTG3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Láng Thượng 3",
        "diaChiLapD": "",
        "longitude": 105.80042415127207,
        "latitude": 21.023334475702555,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1557,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80044158562681,
          21.023140441448955
        ]
      },
      "properties": {
        "tenDoiTuon": "38",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 994 Phố Chùa Láng",
        "tuDien": "LTG3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Láng Thượng 3",
        "diaChiLapD": "",
        "longitude": 105.80044158562681,
        "latitude": 21.023140441448955,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1558,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80031954511482,
          21.022650973274143
        ]
      },
      "properties": {
        "tenDoiTuon": "01",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Chùa Láng",
        "tuDien": "LTG3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 8,
        "tuDien2": "Láng Thượng 3",
        "diaChiLapD": "",
        "longitude": 105.80031954511482,
        "latitude": 21.022650973274143,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1559,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80055155620131,
          21.022749868507915
        ]
      },
      "properties": {
        "tenDoiTuon": "02",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Chùa Láng",
        "tuDien": "LTG3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 8,
        "tuDien2": "Láng Thượng 3",
        "diaChiLapD": "",
        "longitude": 105.80055155620131,
        "latitude": 21.022749868507915,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1560,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80125429490502,
          21.023139189616526
        ]
      },
      "properties": {
        "tenDoiTuon": "15",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 1194 Phố Chùa Láng",
        "tuDien": "LTG3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Láng Thượng 3",
        "diaChiLapD": "",
        "longitude": 105.80125429490502,
        "latitude": 21.023139189616526,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1561,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80120959142168,
          21.023565647367672
        ]
      },
      "properties": {
        "tenDoiTuon": "16",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 1194 Phố Chùa Láng",
        "tuDien": "LTG3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Láng Thượng 3",
        "diaChiLapD": "",
        "longitude": 105.80120959142168,
        "latitude": 21.023565647367672,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1562,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80116488793836,
          21.023992103898912
        ]
      },
      "properties": {
        "tenDoiTuon": "17",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 1194 Phố Chùa Láng",
        "tuDien": "LTG3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Láng Thượng 3",
        "diaChiLapD": "",
        "longitude": 105.80116488793836,
        "latitude": 21.023992103898912,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1563,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80112018445503,
          21.024418559210165
        ]
      },
      "properties": {
        "tenDoiTuon": "18",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 1194 Phố Chùa Láng",
        "tuDien": "LTG3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Láng Thượng 3",
        "diaChiLapD": "",
        "longitude": 105.80112018445503,
        "latitude": 21.024418559210165,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1564,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80141120412979,
          21.024446099335453
        ]
      },
      "properties": {
        "tenDoiTuon": "25",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 1194/141 Phố Chùa Láng",
        "tuDien": "LTG3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Láng Thượng 3",
        "diaChiLapD": "",
        "longitude": 105.80141120412979,
        "latitude": 21.024446099335453,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1565,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80167406060518,
          21.024469883984445
        ]
      },
      "properties": {
        "tenDoiTuon": "26",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 1194/141 Phố Chùa Láng",
        "tuDien": "LTG3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Láng Thượng 3",
        "diaChiLapD": "",
        "longitude": 105.80167406060518,
        "latitude": 21.024469883984445,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1566,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80199860790377,
          21.024511194155284
        ]
      },
      "properties": {
        "tenDoiTuon": "27",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 1194/141 Phố Chùa Láng",
        "tuDien": "LTG3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Láng Thượng 3",
        "diaChiLapD": "",
        "longitude": 105.80199860790377,
        "latitude": 21.024511194155284,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1567,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80166433759997,
          21.024728072367296
        ]
      },
      "properties": {
        "tenDoiTuon": "28",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 1194/141/17 Phố Chùa Láng",
        "tuDien": "LTG3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Láng Thượng 3",
        "diaChiLapD": "",
        "longitude": 105.80166433759997,
        "latitude": 21.024728072367296,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1568,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80164824435352,
          21.025041027382972
        ]
      },
      "properties": {
        "tenDoiTuon": "29",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 1194/141/17 Phố Chùa Láng",
        "tuDien": "LTG3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Láng Thượng 3",
        "diaChiLapD": "",
        "longitude": 105.80164824435352,
        "latitude": 21.025041027382972,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1569,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80151011058294,
          21.02508734466931
        ]
      },
      "properties": {
        "tenDoiTuon": "29-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 1194/141/17 Phố Chùa Láng",
        "tuDien": "LTG3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Láng Thượng 3",
        "diaChiLapD": "",
        "longitude": 105.80151011058294,
        "latitude": 21.02508734466931,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1570,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80111180254461,
          21.02459037195214
        ]
      },
      "properties": {
        "tenDoiTuon": "19",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 1194 Phố Chùa Láng",
        "tuDien": "LTG3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Láng Thượng 3",
        "diaChiLapD": "",
        "longitude": 105.80111180254461,
        "latitude": 21.02459037195214,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1571,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80110107371364,
          21.024743094223478
        ]
      },
      "properties": {
        "tenDoiTuon": "20",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 1194 Phố Chùa Láng",
        "tuDien": "LTG3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Láng Thượng 3",
        "diaChiLapD": "",
        "longitude": 105.80110107371364,
        "latitude": 21.024743094223478,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1572,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80108632156144,
          21.024977184613604
        ]
      },
      "properties": {
        "tenDoiTuon": "21",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 1194 Phố Chùa Láng",
        "tuDien": "LTG3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Láng Thượng 3",
        "diaChiLapD": "",
        "longitude": 105.80108632156144,
        "latitude": 21.024977184613604,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1573,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80106218167442,
          21.025066063755716
        ]
      },
      "properties": {
        "tenDoiTuon": "22",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 1194 Phố Chùa Láng",
        "tuDien": "LTG3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Láng Thượng 3",
        "diaChiLapD": "",
        "longitude": 105.80106218167442,
        "latitude": 21.025066063755716,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1574,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80077652641516,
          21.0250172428244
        ]
      },
      "properties": {
        "tenDoiTuon": "22-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 1194/... Phố Chùa Láng",
        "tuDien": "LTG3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Láng Thượng 3",
        "diaChiLapD": "",
        "longitude": 105.80077652641516,
        "latitude": 21.0250172428244,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1575,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80101658411586,
          21.025474156040804
        ]
      },
      "properties": {
        "tenDoiTuon": "23",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 1194 Phố Chùa Láng",
        "tuDien": "LTG3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Láng Thượng 3",
        "diaChiLapD": "",
        "longitude": 105.80101658411586,
        "latitude": 21.025474156040804,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1576,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80081374205432,
          21.024591310819847
        ]
      },
      "properties": {
        "tenDoiTuon": "30",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 1194/36 Phố Chùa Láng",
        "tuDien": "LTG3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Láng Thượng 3",
        "diaChiLapD": "",
        "longitude": 105.80081374205432,
        "latitude": 21.024591310819847,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1577,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80079764879888,
          21.024230785496524
        ]
      },
      "properties": {
        "tenDoiTuon": "31",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 1194/36 Phố Chùa Láng",
        "tuDien": "LTG3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Láng Thượng 3",
        "diaChiLapD": "",
        "longitude": 105.80079764879888,
        "latitude": 21.024230785496524,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1578,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80054686225822,
          21.02422703001992
        ]
      },
      "properties": {
        "tenDoiTuon": "31-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 1194/36 Phố Chùa Láng",
        "tuDien": "LTG3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Láng Thượng 3",
        "diaChiLapD": "",
        "longitude": 105.80054686225822,
        "latitude": 21.02422703001992,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1579,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.800507970228,
          21.02463637642437
        ]
      },
      "properties": {
        "tenDoiTuon": "32",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 1194/36 Phố Chùa Láng",
        "tuDien": "LTG3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Láng Thượng 3",
        "diaChiLapD": "",
        "longitude": 105.800507970228,
        "latitude": 21.02463637642437,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1580,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80018610514625,
          21.024617599090682
        ]
      },
      "properties": {
        "tenDoiTuon": "33",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 1194/36 Phố Chùa Láng",
        "tuDien": "LTG3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Láng Thượng 3",
        "diaChiLapD": "",
        "longitude": 105.80018610514625,
        "latitude": 21.024617599090682,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1581,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80045432604622,
          21.02477157315537
        ]
      },
      "properties": {
        "tenDoiTuon": "34",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 1194/36 Phố Chùa Láng",
        "tuDien": "LTG3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Láng Thượng 3",
        "diaChiLapD": "",
        "longitude": 105.80045432604622,
        "latitude": 21.02477157315537,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1582,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80044493831912,
          21.025054484435536
        ]
      },
      "properties": {
        "tenDoiTuon": "34-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 1194/36 Phố Chùa Láng",
        "tuDien": "LTG3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Láng Thượng 3",
        "diaChiLapD": "",
        "longitude": 105.80044493831912,
        "latitude": 21.025054484435536,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1583,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80080971873372,
          21.025478850349803
        ]
      },
      "properties": {
        "tenDoiTuon": "24",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 1194/112 Phố Chùa Láng",
        "tuDien": "LTG3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Láng Thượng 3",
        "diaChiLapD": "",
        "longitude": 105.80080971873372,
        "latitude": 21.025478850349803,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1584,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83094165490738,
          21.026446459219766
        ]
      },
      "properties": {
        "tenDoiTuon": "30-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 2  An Trạch 2",
        "tuDien": "ĐTĐ",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Đoàn Thị Điểm",
        "diaChiLapD": "",
        "longitude": 105.83094165490738,
        "latitude": 21.026446459219766,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1585,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80131699161655,
          21.02250294325555
        ]
      },
      "properties": {
        "tenDoiTuon": "39",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 185 Phố Chùa Láng",
        "tuDien": "LTG3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Láng Thượng 3",
        "diaChiLapD": "",
        "longitude": 105.80131699161655,
        "latitude": 21.02250294325555,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1586,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80142159777238,
          21.022278863716725
        ]
      },
      "properties": {
        "tenDoiTuon": "40",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 185 Phố Chùa Láng",
        "tuDien": "LTG3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Láng Thượng 3",
        "diaChiLapD": "",
        "longitude": 105.80142159777238,
        "latitude": 21.022278863716725,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1587,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80168981867234,
          21.022228790030834
        ]
      },
      "properties": {
        "tenDoiTuon": "40-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 185 Phố Chùa Láng",
        "tuDien": "LTG3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Láng Thượng 3",
        "diaChiLapD": "",
        "longitude": 105.80168981867234,
        "latitude": 21.022228790030834,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1588,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80192987638202,
          21.022193738440397
        ]
      },
      "properties": {
        "tenDoiTuon": "40-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 185 Phố Chùa Láng",
        "tuDien": "LTG3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Láng Thượng 3",
        "diaChiLapD": "",
        "longitude": 105.80192987638202,
        "latitude": 21.022193738440397,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1589,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80207471566298,
          21.022147420255003
        ]
      },
      "properties": {
        "tenDoiTuon": "40-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 185 Phố Chùa Láng",
        "tuDien": "LTG3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "Láng Thượng 3",
        "diaChiLapD": "",
        "longitude": 105.80207471566298,
        "latitude": 21.022147420255003,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1590,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80156107263784,
          21.021978421347544
        ]
      },
      "properties": {
        "tenDoiTuon": "41",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 185 Phố Chùa Láng",
        "tuDien": "LTG3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Láng Thượng 3",
        "diaChiLapD": "",
        "longitude": 105.80156107263784,
        "latitude": 21.021978421347544,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1591,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80172871070256,
          21.021699259770166
        ]
      },
      "properties": {
        "tenDoiTuon": "42",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 185 Phố Chùa Láng",
        "tuDien": "LTG3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Láng Thượng 3",
        "diaChiLapD": "",
        "longitude": 105.80172871070256,
        "latitude": 21.021699259770166,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1592,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80239389853699,
          21.021803163019374
        ]
      },
      "properties": {
        "tenDoiTuon": "46",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 185 Phố Chùa Láng",
        "tuDien": "LTG3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Láng Thượng 3",
        "diaChiLapD": "",
        "longitude": 105.80239389853699,
        "latitude": 21.021803163019374,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1593,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80217127518661,
          21.02177812609865
        ]
      },
      "properties": {
        "tenDoiTuon": "45",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 185 Phố Chùa Láng",
        "tuDien": "LTG3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Láng Thượng 3",
        "diaChiLapD": "",
        "longitude": 105.80217127518661,
        "latitude": 21.02177812609865,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1594,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80189500766342,
          21.02144513465095
        ]
      },
      "properties": {
        "tenDoiTuon": "43",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 185 Phố Chùa Láng",
        "tuDien": "LTG3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Láng Thượng 3",
        "diaChiLapD": "",
        "longitude": 105.80189500766342,
        "latitude": 21.02144513465095,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1595,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80191110091886,
          21.0217706150218
        ]
      },
      "properties": {
        "tenDoiTuon": "44",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 185 Phố Chùa Láng",
        "tuDien": "LTG3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Láng Thượng 3",
        "diaChiLapD": "",
        "longitude": 105.80191110091886,
        "latitude": 21.0217706150218,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1596,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80487361158394,
          21.023600673621154
        ]
      },
      "properties": {
        "tenDoiTuon": "36",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Chùa Láng",
        "tuDien": "ĐHNT",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 8,
        "tuDien2": "TT Đại Học Ngoại Thương",
        "diaChiLapD": "",
        "longitude": 105.80487361158394,
        "latitude": 21.023600673621154,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1597,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80468433733812,
          21.023589692958716
        ]
      },
      "properties": {
        "tenDoiTuon": "35",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Chùa Láng",
        "tuDien": "ĐHNT",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 8,
        "tuDien2": "TT Đại Học Ngoại Thương",
        "diaChiLapD": "",
        "longitude": 105.80468433733812,
        "latitude": 21.023589692958716,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1598,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80453011032108,
          21.023582181972273
        ]
      },
      "properties": {
        "tenDoiTuon": "34",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Chùa Láng",
        "tuDien": "ĐHNT",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 8,
        "tuDien2": "TT Đại Học Ngoại Thương",
        "diaChiLapD": "",
        "longitude": 105.80453011032108,
        "latitude": 21.023582181972273,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1599,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80436515446412,
          21.023603463097732
        ]
      },
      "properties": {
        "tenDoiTuon": "51-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 106 Chùa Láng",
        "tuDien": "ĐHNT",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "TT Đại Học Ngoại Thương",
        "diaChiLapD": "",
        "longitude": 105.80436515446412,
        "latitude": 21.023603463097732,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1600,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80433449326057,
          21.023561197969833
        ]
      },
      "properties": {
        "tenDoiTuon": "51",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Chùa Láng",
        "tuDien": "ĐHNT",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 8,
        "tuDien2": "TT Đại Học Ngoại Thương",
        "diaChiLapD": "",
        "longitude": 105.80433449326057,
        "latitude": 21.023561197969833,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1601,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80405651472128,
          21.023545640625542
        ]
      },
      "properties": {
        "tenDoiTuon": "52",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Chùa Láng",
        "tuDien": "ĐHNT",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 8,
        "tuDien2": "TT Đại Học Ngoại Thương",
        "diaChiLapD": "",
        "longitude": 105.80405651472128,
        "latitude": 21.023545640625542,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1602,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80378802940305,
          21.023523890123386
        ]
      },
      "properties": {
        "tenDoiTuon": "ĐHNT 53",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Chùa Láng",
        "tuDien": "ĐHNT",
        "chuDauTu": "",
        "loaiCot": "Thép bát giác",
        "chieuCao": 8,
        "tuDien2": "TT Đại Học Ngoại Thương",
        "diaChiLapD": "",
        "longitude": 105.80378802940305,
        "latitude": 21.023523890123386,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1603,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80436649555901,
          21.02398276735591
        ]
      },
      "properties": {
        "tenDoiTuon": "51-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 106 Chùa Láng",
        "tuDien": "ĐHNT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "TT Đại Học Ngoại Thương",
        "diaChiLapD": "",
        "longitude": 105.80436649555901,
        "latitude": 21.02398276735591,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1604,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80414640452251,
          21.023941069202714
        ]
      },
      "properties": {
        "tenDoiTuon": "51-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 106 Chùa Láng",
        "tuDien": "ĐHNT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "TT Đại Học Ngoại Thương",
        "diaChiLapD": "",
        "longitude": 105.80414640452251,
        "latitude": 21.023941069202714,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1605,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80412611972056,
          21.02416667491238
        ]
      },
      "properties": {
        "tenDoiTuon": "51-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 106 Chùa Láng",
        "tuDien": "ĐHNT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "TT Đại Học Ngoại Thương",
        "diaChiLapD": "",
        "longitude": 105.80412611972056,
        "latitude": 21.02416667491238,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1606,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80435595665793,
          21.02430125072568
        ]
      },
      "properties": {
        "tenDoiTuon": "51-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 106 Chùa Láng",
        "tuDien": "ĐHNT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "TT Đại Học Ngoại Thương",
        "diaChiLapD": "",
        "longitude": 105.80435595665793,
        "latitude": 21.02430125072568,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1607,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80436423298016,
          21.02452751720654
        ]
      },
      "properties": {
        "tenDoiTuon": "51-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 106 Chùa Láng",
        "tuDien": "ĐHNT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "TT Đại Học Ngoại Thương",
        "diaChiLapD": "",
        "longitude": 105.80436423298016,
        "latitude": 21.02452751720654,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1608,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80426393933939,
          21.024334869103985
        ]
      },
      "properties": {
        "tenDoiTuon": "51-7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 106 Chùa Láng",
        "tuDien": "ĐHNT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "TT Đại Học Ngoại Thương",
        "diaChiLapD": "",
        "longitude": 105.80426393933939,
        "latitude": 21.024334869103985,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1609,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80392929549045,
          21.0243207604342
        ]
      },
      "properties": {
        "tenDoiTuon": "51-8",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 106 Chùa Láng",
        "tuDien": "ĐHNT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "TT Đại Học Ngoại Thương",
        "diaChiLapD": "",
        "longitude": 105.80392929549045,
        "latitude": 21.0243207604342,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1610,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80538841718482,
          21.023863843683333
        ]
      },
      "properties": {
        "tenDoiTuon": "20",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 84 Chùa Láng",
        "tuDien": "ĐHNT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "TT Đại Học Ngoại Thương",
        "diaChiLapD": "",
        "longitude": 105.80538841718482,
        "latitude": 21.023863843683333,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1611,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8055439853057,
          21.02384381442411
        ]
      },
      "properties": {
        "tenDoiTuon": "20-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 84 Chùa Láng",
        "tuDien": "ĐHNT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "TT Đại Học Ngoại Thương",
        "diaChiLapD": "",
        "longitude": 105.8055439853057,
        "latitude": 21.02384381442411,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1612,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80569955342663,
          21.02382378516222
        ]
      },
      "properties": {
        "tenDoiTuon": "20-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 84 Chùa Láng",
        "tuDien": "ĐHNT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "TT Đại Học Ngoại Thương",
        "diaChiLapD": "",
        "longitude": 105.80569955342663,
        "latitude": 21.02382378516222,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1613,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80536159508941,
          21.024059128822817
        ]
      },
      "properties": {
        "tenDoiTuon": "21",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 84 Chùa Láng",
        "tuDien": "ĐHNT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "TT Đại Học Ngoại Thương",
        "diaChiLapD": "",
        "longitude": 105.80536159508941,
        "latitude": 21.024059128822817,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1614,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80571832888978,
          21.0240215740076
        ]
      },
      "properties": {
        "tenDoiTuon": "33",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 84 Chùa Láng",
        "tuDien": "ĐHNT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "TT Đại Học Ngoại Thương",
        "diaChiLapD": "",
        "longitude": 105.80571832888978,
        "latitude": 21.0240215740076,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1615,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8060643338592,
          21.023991530149136
        ]
      },
      "properties": {
        "tenDoiTuon": "32",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 84 Chùa Láng",
        "tuDien": "ĐHNT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "TT Đại Học Ngoại Thương",
        "diaChiLapD": "",
        "longitude": 105.8060643338592,
        "latitude": 21.023991530149136,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1616,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80631377929596,
          21.02396899725196
        ]
      },
      "properties": {
        "tenDoiTuon": "31",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 84 Chùa Láng",
        "tuDien": "ĐHNT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "TT Đại Học Ngoại Thương",
        "diaChiLapD": "",
        "longitude": 105.80631377929596,
        "latitude": 21.02396899725196,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1617,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80544206136659,
          21.024324515909292
        ]
      },
      "properties": {
        "tenDoiTuon": "22",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 84 Chùa Láng",
        "tuDien": "ĐHNT",
        "chuDauTu": "",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TT Đại Học Ngoại Thương",
        "diaChiLapD": "",
        "longitude": 105.80544206136659,
        "latitude": 21.024324515909292,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1618,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80544675538735,
          21.024596161621165
        ]
      },
      "properties": {
        "tenDoiTuon": "23",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 84 Chùa Láng",
        "tuDien": "ĐHNT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "TT Đại Học Ngoại Thương",
        "diaChiLapD": "",
        "longitude": 105.80544675538735,
        "latitude": 21.024596161621165,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1619,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80555806705355,
          21.02465124180018
        ]
      },
      "properties": {
        "tenDoiTuon": "25",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 84 Chùa Láng",
        "tuDien": "ĐHNT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "TT Đại Học Ngoại Thương",
        "diaChiLapD": "",
        "longitude": 105.80555806705355,
        "latitude": 21.02465124180018,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1620,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80604555839602,
          21.024637471772227
        ]
      },
      "properties": {
        "tenDoiTuon": "26",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 84 Chùa Láng",
        "tuDien": "ĐHNT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "TT Đại Học Ngoại Thương",
        "diaChiLapD": "",
        "longitude": 105.80604555839602,
        "latitude": 21.024637471772227,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1621,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80627622836963,
          21.02463246448343
        ]
      },
      "properties": {
        "tenDoiTuon": "27",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 84 Chùa Láng",
        "tuDien": "ĐHNT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "TT Đại Học Ngoại Thương",
        "diaChiLapD": "",
        "longitude": 105.80627622836963,
        "latitude": 21.02463246448343,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1622,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8062815927851,
          21.024454705628376
        ]
      },
      "properties": {
        "tenDoiTuon": "28",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 84 Chùa Láng",
        "tuDien": "ĐHNT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "TT Đại Học Ngoại Thương",
        "diaChiLapD": "",
        "longitude": 105.8062815927851,
        "latitude": 21.024454705628376,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1623,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8062815927851,
          21.024241895451627
        ]
      },
      "properties": {
        "tenDoiTuon": "29",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 84 Chùa Láng",
        "tuDien": "ĐHNT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "TT Đại Học Ngoại Thương",
        "diaChiLapD": "",
        "longitude": 105.8062815927851,
        "latitude": 21.024241895451627,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1624,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8059570454956,
          21.024196829728684
        ]
      },
      "properties": {
        "tenDoiTuon": "30",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 84 Chùa Láng",
        "tuDien": "ĐHNT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "TT Đại Học Ngoại Thương",
        "diaChiLapD": "",
        "longitude": 105.8059570454956,
        "latitude": 21.024196829728684,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1625,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80537232392939,
          21.02479269763665
        ]
      },
      "properties": {
        "tenDoiTuon": "24",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 84 Chùa Láng",
        "tuDien": "ĐHNT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "TT Đại Học Ngoại Thương",
        "diaChiLapD": "",
        "longitude": 105.80537232392939,
        "latitude": 21.02479269763665,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1626,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80559360615794,
          21.02361097408645
        ]
      },
      "properties": {
        "tenDoiTuon": "38",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Chùa Láng",
        "tuDien": "ĐHNT",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 8,
        "tuDien2": "TT Đại Học Ngoại Thương",
        "diaChiLapD": "",
        "longitude": 105.80559360615794,
        "latitude": 21.02361097408645,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1627,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80604421727418,
          21.023513331254733
        ]
      },
      "properties": {
        "tenDoiTuon": "39",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Chùa Láng",
        "tuDien": "ĐHNT",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 8,
        "tuDien2": "TT Đại Học Ngoại Thương",
        "diaChiLapD": "",
        "longitude": 105.80604421727418,
        "latitude": 21.023513331254733,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1628,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8063902222346,
          21.02346075431982
        ]
      },
      "properties": {
        "tenDoiTuon": "40",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Chùa Láng",
        "tuDien": "ĐHNT",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 8,
        "tuDien2": "TT Đại Học Ngoại Thương",
        "diaChiLapD": "",
        "longitude": 105.8063902222346,
        "latitude": 21.02346075431982,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1629,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80675768487492,
          21.02340317003547
        ]
      },
      "properties": {
        "tenDoiTuon": "41",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Chùa Láng",
        "tuDien": "ĐHNT",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 8,
        "tuDien2": "TT Đại Học Ngoại Thương",
        "diaChiLapD": "",
        "longitude": 105.80675768487492,
        "latitude": 21.02340317003547,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1630,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80717074505583,
          21.02332305273403
        ]
      },
      "properties": {
        "tenDoiTuon": "42",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Chùa Láng",
        "tuDien": "ĐHNT",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 8,
        "tuDien2": "TT Đại Học Ngoại Thương",
        "diaChiLapD": "",
        "longitude": 105.80717074505583,
        "latitude": 21.02332305273403,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1631,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80748724572209,
          21.023257957394545
        ]
      },
      "properties": {
        "tenDoiTuon": "43",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Chùa Láng",
        "tuDien": "ĐHNT",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 8,
        "tuDien2": "TT Đại Học Ngoại Thương",
        "diaChiLapD": "",
        "longitude": 105.80748724572209,
        "latitude": 21.023257957394545,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1632,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80795395009378,
          21.02315280332436
        ]
      },
      "properties": {
        "tenDoiTuon": "44",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Chùa Láng",
        "tuDien": "ĐHNT",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 8,
        "tuDien2": "TT Đại Học Ngoại Thương",
        "diaChiLapD": "",
        "longitude": 105.80795395009378,
        "latitude": 21.02315280332436,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1633,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80818462006738,
          21.02311024094181
        ]
      },
      "properties": {
        "tenDoiTuon": "45",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Chùa Láng",
        "tuDien": "ĐHNT",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 8,
        "tuDien2": "TT Đại Học Ngoại Thương",
        "diaChiLapD": "",
        "longitude": 105.80818462006738,
        "latitude": 21.02311024094181,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1634,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80839919678556,
          21.023065174876752
        ]
      },
      "properties": {
        "tenDoiTuon": "46",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Chùa Láng",
        "tuDien": "ĐHNT",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 8,
        "tuDien2": "TT Đại Học Ngoại Thương",
        "diaChiLapD": "",
        "longitude": 105.80839919678556,
        "latitude": 21.023065174876752,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1635,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80872424952902,
          21.022994262299044
        ]
      },
      "properties": {
        "tenDoiTuon": "47",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Chùa Láng",
        "tuDien": "ĐHNT",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 8,
        "tuDien2": "TT Đại Học Ngoại Thương",
        "diaChiLapD": "",
        "longitude": 105.80872424952902,
        "latitude": 21.022994262299044,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1636,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80900252628815,
          21.022940147751218
        ]
      },
      "properties": {
        "tenDoiTuon": "48",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Chùa Láng",
        "tuDien": "ĐHNT",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 8,
        "tuDien2": "TT Đại Học Ngoại Thương",
        "diaChiLapD": "",
        "longitude": 105.80900252628815,
        "latitude": 21.022940147751218,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1637,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80930159259513,
          21.02288131142945
        ]
      },
      "properties": {
        "tenDoiTuon": "49",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Chùa Láng",
        "tuDien": "ĐHNT",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 8,
        "tuDien2": "TT Đại Học Ngoại Thương",
        "diaChiLapD": "",
        "longitude": 105.80930159259513,
        "latitude": 21.02288131142945,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1638,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80955506134352,
          21.022832489782626
        ]
      },
      "properties": {
        "tenDoiTuon": "ĐHNT 50",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Chùa Láng",
        "tuDien": "ĐHNT",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 8,
        "tuDien2": "TT Đại Học Ngoại Thương",
        "diaChiLapD": "",
        "longitude": 105.80955506134352,
        "latitude": 21.022832489782626,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1639,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80223967143911,
          21.01967845236967
        ]
      },
      "properties": {
        "tenDoiTuon": "01",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "850ĐL",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 10,
        "tuDien2": "850 Đường Láng",
        "diaChiLapD": "",
        "longitude": 105.80223967143911,
        "latitude": 21.01967845236967,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1640,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80212968924059,
          21.019888990259943
        ]
      },
      "properties": {
        "tenDoiTuon": "02",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "850ĐL",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 10,
        "tuDien2": "850 Đường Láng",
        "diaChiLapD": "",
        "longitude": 105.80212968924059,
        "latitude": 21.019888990259943,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1641,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8020197070421,
          21.020099527852985
        ]
      },
      "properties": {
        "tenDoiTuon": "03",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "850ĐL",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 10,
        "tuDien2": "850 Đường Láng",
        "diaChiLapD": "",
        "longitude": 105.8020197070421,
        "latitude": 21.020099527852985,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1642,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80190972484357,
          21.020310065148696
        ]
      },
      "properties": {
        "tenDoiTuon": "04",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "850ĐL",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 10,
        "tuDien2": "850 Đường Láng",
        "diaChiLapD": "",
        "longitude": 105.80190972484357,
        "latitude": 21.020310065148696,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1643,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80179003126244,
          21.020516621655016
        ]
      },
      "properties": {
        "tenDoiTuon": "05",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "850ĐL",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 10,
        "tuDien2": "850 Đường Láng",
        "diaChiLapD": "",
        "longitude": 105.80179003126244,
        "latitude": 21.020516621655016,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1644,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8016723493416,
          21.0207031481928
        ]
      },
      "properties": {
        "tenDoiTuon": "06",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "850ĐL",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 10,
        "tuDien2": "850 Đường Láng",
        "diaChiLapD": "",
        "longitude": 105.8016723493416,
        "latitude": 21.0207031481928,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1645,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80157244275517,
          21.020888540244403
        ]
      },
      "properties": {
        "tenDoiTuon": "07",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "850ĐL",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 10,
        "tuDien2": "850 Đường Láng",
        "diaChiLapD": "",
        "longitude": 105.80157244275517,
        "latitude": 21.020888540244403,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1646,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80145877842214,
          21.021071506131122
        ]
      },
      "properties": {
        "tenDoiTuon": "08",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "850ĐL",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 10,
        "tuDien2": "850 Đường Láng",
        "diaChiLapD": "",
        "longitude": 105.80145877842214,
        "latitude": 21.021071506131122,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1647,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80132366217344,
          21.021300907839862
        ]
      },
      "properties": {
        "tenDoiTuon": "09",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "850ĐL",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 10,
        "tuDien2": "850 Đường Láng",
        "diaChiLapD": "",
        "longitude": 105.80132366217344,
        "latitude": 21.021300907839862,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1648,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80119491613895,
          21.02152185922846
        ]
      },
      "properties": {
        "tenDoiTuon": "10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "850ĐL",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 10,
        "tuDien2": "850 Đường Láng",
        "diaChiLapD": "",
        "longitude": 105.80119491613895,
        "latitude": 21.02152185922846,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1649,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80108896888825,
          21.021704628968692
        ]
      },
      "properties": {
        "tenDoiTuon": "11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "850ĐL",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 10,
        "tuDien2": "850 Đường Láng",
        "diaChiLapD": "",
        "longitude": 105.80108896888825,
        "latitude": 21.021704628968692,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1650,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8009709516847,
          21.02187738372241
        ]
      },
      "properties": {
        "tenDoiTuon": "12",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "850ĐL",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 10,
        "tuDien2": "850 Đường Láng",
        "diaChiLapD": "",
        "longitude": 105.8009709516847,
        "latitude": 21.02187738372241,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1651,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80087170994436,
          21.022011331111077
        ]
      },
      "properties": {
        "tenDoiTuon": "13",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "850ĐL",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 10,
        "tuDien2": "850 Đường Láng",
        "diaChiLapD": "",
        "longitude": 105.80087170994436,
        "latitude": 21.022011331111077,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1652,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80075503385368,
          21.022136515477662
        ]
      },
      "properties": {
        "tenDoiTuon": "14",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "850ĐL",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 10,
        "tuDien2": "850 Đường Láng",
        "diaChiLapD": "",
        "longitude": 105.80075503385368,
        "latitude": 21.022136515477662,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1653,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80063969886687,
          21.022260447896763
        ]
      },
      "properties": {
        "tenDoiTuon": "15",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "850ĐL",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 10,
        "tuDien2": "850 Đường Láng",
        "diaChiLapD": "",
        "longitude": 105.80063969886687,
        "latitude": 21.022260447896763,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1654,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80049586542057,
          21.02239595974302
        ]
      },
      "properties": {
        "tenDoiTuon": "16",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "850ĐL",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 10,
        "tuDien2": "850 Đường Láng",
        "diaChiLapD": "",
        "longitude": 105.80049586542057,
        "latitude": 21.02239595974302,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1655,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8003698015938,
          21.022528654825674
        ]
      },
      "properties": {
        "tenDoiTuon": "17",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "850ĐL",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 10,
        "tuDien2": "850 Đường Láng",
        "diaChiLapD": "",
        "longitude": 105.8003698015938,
        "latitude": 21.022528654825674,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1656,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80013276137008,
          21.022738024893773
        ]
      },
      "properties": {
        "tenDoiTuon": "18",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "850ĐL",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 10,
        "tuDien2": "850 Đường Láng",
        "diaChiLapD": "",
        "longitude": 105.80013276137008,
        "latitude": 21.022738024893773,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1657,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.7999624410976,
          21.022888245388657
        ]
      },
      "properties": {
        "tenDoiTuon": "19",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "850ĐL",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 10,
        "tuDien2": "850 Đường Láng",
        "diaChiLapD": "",
        "longitude": 105.7999624410976,
        "latitude": 21.022888245388657,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1658,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80216151719192,
          21.020377978462246
        ]
      },
      "properties": {
        "tenDoiTuon": "4-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 886 đường láng",
        "tuDien": "850ĐL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "850 Đường Láng",
        "diaChiLapD": "",
        "longitude": 105.80216151719192,
        "latitude": 21.020377978462246,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1659,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8023590298094,
          21.020002997692167
        ]
      },
      "properties": {
        "tenDoiTuon": "4-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 886 đường láng",
        "tuDien": "850ĐL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "850 Đường Láng",
        "diaChiLapD": "",
        "longitude": 105.8023590298094,
        "latitude": 21.020002997692167,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1660,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80169954166867,
          21.020929684956545
        ]
      },
      "properties": {
        "tenDoiTuon": "7-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "UBND phường Láng Thượng",
        "tuDien": "850ĐL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 6,
        "tuDien2": "850 Đường Láng",
        "diaChiLapD": "",
        "longitude": 105.80169954166867,
        "latitude": 21.020929684956545,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1661,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8016579674307,
          21.02101856651211
        ]
      },
      "properties": {
        "tenDoiTuon": "7-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "UBND phường Láng Thượng",
        "tuDien": "850ĐL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 6,
        "tuDien2": "850 Đường Láng",
        "diaChiLapD": "",
        "longitude": 105.8016579674307,
        "latitude": 21.02101856651211,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1662,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80168408398028,
          21.021185424626587
        ]
      },
      "properties": {
        "tenDoiTuon": "8-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 898 đường Láng+ 157 Chùa Láng",
        "tuDien": "850ĐL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "850 Đường Láng",
        "diaChiLapD": "",
        "longitude": 105.80168408398028,
        "latitude": 21.021185424626587,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1663,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80200231873641,
          21.02134739535843
        ]
      },
      "properties": {
        "tenDoiTuon": "8-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 898 đường Láng+ 157 Chùa Láng",
        "tuDien": "850ĐL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "850 Đường Láng",
        "diaChiLapD": "",
        "longitude": 105.80200231873641,
        "latitude": 21.02134739535843,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1664,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80228126847634,
          21.021437528514234
        ]
      },
      "properties": {
        "tenDoiTuon": "8-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 898 đường Láng+ 157 Chùa Láng",
        "tuDien": "850ĐL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "850 Đường Láng",
        "diaChiLapD": "",
        "longitude": 105.80228126847634,
        "latitude": 21.021437528514234,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1665,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80253568535159,
          21.021529683289412
        ]
      },
      "properties": {
        "tenDoiTuon": "8-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 898 đường Láng+ 157 Chùa Láng",
        "tuDien": "850ĐL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "850 Đường Láng",
        "diaChiLapD": "",
        "longitude": 105.80253568535159,
        "latitude": 21.021529683289412,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1666,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8028025651387,
          21.021648608825636
        ]
      },
      "properties": {
        "tenDoiTuon": "8-8",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 898 đường Láng+ 157 Chùa Láng",
        "tuDien": "850ĐL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "850 Đường Láng",
        "diaChiLapD": "",
        "longitude": 105.8028025651387,
        "latitude": 21.021648608825636,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1667,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80289241914298,
          21.02139698731679
        ]
      },
      "properties": {
        "tenDoiTuon": "8-7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 898 đường Láng+ 157 Chùa Láng",
        "tuDien": "850ĐL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "850 Đường Láng",
        "diaChiLapD": "",
        "longitude": 105.80289241914298,
        "latitude": 21.02139698731679,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1668,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80295939423802,
          21.02113103559487
        ]
      },
      "properties": {
        "tenDoiTuon": "8-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 898 đường Láng+ 157 Chùa Láng",
        "tuDien": "850ĐL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "850 Đường Láng",
        "diaChiLapD": "",
        "longitude": 105.80295939423802,
        "latitude": 21.02113103559487,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1669,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80317941550612,
          21.021750008417772
        ]
      },
      "properties": {
        "tenDoiTuon": "8-17",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 898 đường Láng+ 157 Chùa Láng",
        "tuDien": "850ĐL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "850 Đường Láng",
        "diaChiLapD": "",
        "longitude": 105.80317941550612,
        "latitude": 21.021750008417772,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1670,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80301982406462,
          21.021696179013098
        ]
      },
      "properties": {
        "tenDoiTuon": "8-17-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 898 đường Láng+ 157 Chùa Láng",
        "tuDien": "850ĐL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 5,
        "tuDien2": "850 Đường Láng",
        "diaChiLapD": "",
        "longitude": 105.80301982406462,
        "latitude": 21.021696179013098,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1671,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80324420861088,
          21.021497198626868
        ]
      },
      "properties": {
        "tenDoiTuon": "8-18",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 898 đường Láng+ 157 Chùa Láng",
        "tuDien": "850ĐL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "850 Đường Láng",
        "diaChiLapD": "",
        "longitude": 105.80324420861088,
        "latitude": 21.021497198626868,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1672,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80342483763128,
          21.02095883940357
        ]
      },
      "properties": {
        "tenDoiTuon": "8-20",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 898 đường Láng+ 157 Chùa Láng",
        "tuDien": "850ĐL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "850 Đường Láng",
        "diaChiLapD": "",
        "longitude": 105.80342483763128,
        "latitude": 21.02095883940357,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1673,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80340874437586,
          21.020709720443058
        ]
      },
      "properties": {
        "tenDoiTuon": "8-27",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 898 đường Láng+ 157 Chùa Láng",
        "tuDien": "850ĐL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "850 Đường Láng",
        "diaChiLapD": "",
        "longitude": 105.80340874437586,
        "latitude": 21.020709720443058,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1674,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80340170356929,
          21.02061426646008
        ]
      },
      "properties": {
        "tenDoiTuon": "8-29",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 898 đường Láng+ 157 Chùa Láng",
        "tuDien": "850ĐL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 5,
        "tuDien2": "850 Đường Láng",
        "diaChiLapD": "",
        "longitude": 105.80340170356929,
        "latitude": 21.02061426646008,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1675,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80342718456082,
          21.02066308883329
        ]
      },
      "properties": {
        "tenDoiTuon": "8-28",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 898 đường Láng+ 157 Chùa Láng",
        "tuDien": "850ĐL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 5,
        "tuDien2": "850 Đường Láng",
        "diaChiLapD": "",
        "longitude": 105.80342718456082,
        "latitude": 21.02066308883329,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1676,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80349558088518,
          21.020600496044153
        ]
      },
      "properties": {
        "tenDoiTuon": "8-30",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 898 đường Láng+ 157 Chùa Láng",
        "tuDien": "850ĐL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "850 Đường Láng",
        "diaChiLapD": "",
        "longitude": 105.80349558088518,
        "latitude": 21.020600496044153,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1677,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8036750113772,
          21.020844463860602
        ]
      },
      "properties": {
        "tenDoiTuon": "8-21",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 898 đường Láng+ 157 Chùa Láng",
        "tuDien": "850ĐL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "850 Đường Láng",
        "diaChiLapD": "",
        "longitude": 105.8036750113772,
        "latitude": 21.020844463860602,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1678,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80391247312006,
          21.020792417634073
        ]
      },
      "properties": {
        "tenDoiTuon": "8-22",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 898 đường Láng+ 157 Chùa Láng",
        "tuDien": "850ĐL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "850 Đường Láng",
        "diaChiLapD": "",
        "longitude": 105.80391247312006,
        "latitude": 21.020792417634073,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1679,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8040439351961,
          21.020592249101135
        ]
      },
      "properties": {
        "tenDoiTuon": "8-24",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 898 đường Láng+ 157 Chùa Láng",
        "tuDien": "850ĐL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "850 Đường Láng",
        "diaChiLapD": "",
        "longitude": 105.8040439351961,
        "latitude": 21.020592249101135,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1680,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80402235744147,
          21.020601603989586
        ]
      },
      "properties": {
        "tenDoiTuon": "8-23",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 898 đường Láng+ 157 Chùa Láng",
        "tuDien": "850ĐL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 5,
        "tuDien2": "850 Đường Láng",
        "diaChiLapD": "",
        "longitude": 105.80402235744147,
        "latitude": 21.020601603989586,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1681,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80397541877903,
          21.020932093615986
        ]
      },
      "properties": {
        "tenDoiTuon": "8-25",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 898 đường Láng+ 157 Chùa Láng",
        "tuDien": "850ĐL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "850 Đường Láng",
        "diaChiLapD": "",
        "longitude": 105.80397541877903,
        "latitude": 21.020932093615986,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1682,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80391641017279,
          21.02104476036626
        ]
      },
      "properties": {
        "tenDoiTuon": "8-26",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 898 đường Láng+ 157 Chùa Láng",
        "tuDien": "850ĐL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 5,
        "tuDien2": "850 Đường Láng",
        "diaChiLapD": "",
        "longitude": 105.80391641017279,
        "latitude": 21.02104476036626,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1683,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8031130247149,
          21.021993813429184
        ]
      },
      "properties": {
        "tenDoiTuon": "8-16",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 898 đường Láng+ 157 Chùa Láng",
        "tuDien": "850ĐL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "850 Đường Láng",
        "diaChiLapD": "",
        "longitude": 105.8031130247149,
        "latitude": 21.021993813429184,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1684,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80302699240147,
          21.022258617460484
        ]
      },
      "properties": {
        "tenDoiTuon": "8-15",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 898 đường Láng+ 157 Chùa Láng",
        "tuDien": "850ĐL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "850 Đường Láng",
        "diaChiLapD": "",
        "longitude": 105.80302699240147,
        "latitude": 21.022258617460484,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1685,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80293606277596,
          21.022510672210046
        ]
      },
      "properties": {
        "tenDoiTuon": "8-14",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 898 đường Láng+ 157 Chùa Láng",
        "tuDien": "850ĐL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "850 Đường Láng",
        "diaChiLapD": "",
        "longitude": 105.80293606277596,
        "latitude": 21.022510672210046,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1686,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80286698965386,
          21.022788837375007
        ]
      },
      "properties": {
        "tenDoiTuon": "8-13",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 898 đường Láng+ 157 Chùa Láng",
        "tuDien": "850ĐL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "850 Đường Láng",
        "diaChiLapD": "",
        "longitude": 105.80286698965386,
        "latitude": 21.022788837375007,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1687,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80260650974624,
          21.022412736703444
        ]
      },
      "properties": {
        "tenDoiTuon": "8-12",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 898 đường Láng+ 157 Chùa Láng",
        "tuDien": "850ĐL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "850 Đường Láng",
        "diaChiLapD": "",
        "longitude": 105.80260650974624,
        "latitude": 21.022412736703444,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1688,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80239404280245,
          21.022359145577237
        ]
      },
      "properties": {
        "tenDoiTuon": "8-11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 898 đường Láng+ 157 Chùa Láng",
        "tuDien": "850ĐL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 5,
        "tuDien2": "850 Đường Láng",
        "diaChiLapD": "",
        "longitude": 105.80239404280245,
        "latitude": 21.022359145577237,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1689,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80116345789229,
          21.02208097751236
        ]
      },
      "properties": {
        "tenDoiTuon": "12-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 942 đường láng",
        "tuDien": "850ĐL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "850 Đường Láng",
        "diaChiLapD": "",
        "longitude": 105.80116345789229,
        "latitude": 21.02208097751236,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1690,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80252903753102,
          21.021345830547368
        ]
      },
      "properties": {
        "tenDoiTuon": "8-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 898 đường Láng+ 157 Chùa Láng",
        "tuDien": "850ĐL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "850 Đường Láng",
        "diaChiLapD": "",
        "longitude": 105.80252903753102,
        "latitude": 21.021345830547368,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1691,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80184873953944,
          21.023069399811195
        ]
      },
      "properties": {
        "tenDoiTuon": "6-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 159A Phố Chùa Láng",
        "tuDien": "LTG3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Láng Thượng 3",
        "diaChiLapD": "",
        "longitude": 105.80184873953944,
        "latitude": 21.023069399811195,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1692,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80187422052197,
          21.0227977513022
        ]
      },
      "properties": {
        "tenDoiTuon": "6-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 159A Phố Chùa Láng",
        "tuDien": "LTG3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 6,
        "tuDien2": "Láng Thượng 3",
        "diaChiLapD": "",
        "longitude": 105.80187422052197,
        "latitude": 21.0227977513022,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1693,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80193457022312,
          21.02256490932903
        ]
      },
      "properties": {
        "tenDoiTuon": "6-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 159A Phố Chùa Láng",
        "tuDien": "LTG3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 6,
        "tuDien2": "Láng Thượng 3",
        "diaChiLapD": "",
        "longitude": 105.80193457022312,
        "latitude": 21.02256490932903,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1694,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83167180252511,
          21.028811692472217
        ]
      },
      "properties": {
        "tenDoiTuon": "01",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Cát Linh",
        "tuDien": "HĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "Hàng Đẫy",
        "diaChiLapD": "",
        "longitude": 105.83167180252511,
        "latitude": 21.028811692472217,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1695,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83138346504913,
          21.028868022880328
        ]
      },
      "properties": {
        "tenDoiTuon": "02",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Cát Linh",
        "tuDien": "HĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "Hàng Đẫy",
        "diaChiLapD": "",
        "longitude": 105.83138346504913,
        "latitude": 21.028868022880328,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1696,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83109646868601,
          21.02892560505352
        ]
      },
      "properties": {
        "tenDoiTuon": "03",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Cát Linh",
        "tuDien": "HĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "Hàng Đẫy",
        "diaChiLapD": "",
        "longitude": 105.83109646868601,
        "latitude": 21.02892560505352,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1697,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83081081342672,
          21.028978180060843
        ]
      },
      "properties": {
        "tenDoiTuon": "04",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Cát Linh",
        "tuDien": "HĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "Hàng Đẫy",
        "diaChiLapD": "",
        "longitude": 105.83081081342672,
        "latitude": 21.028978180060843,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1698,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83052515816746,
          21.02903075505046
        ]
      },
      "properties": {
        "tenDoiTuon": "05",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Cát Linh",
        "tuDien": "HĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "Hàng Đẫy",
        "diaChiLapD": "",
        "longitude": 105.83052515816746,
        "latitude": 21.02903075505046,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1699,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8302381618043,
          21.029084581806572
        ]
      },
      "properties": {
        "tenDoiTuon": "06",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Cát Linh",
        "tuDien": "HĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "Hàng Đẫy",
        "diaChiLapD": "",
        "longitude": 105.8302381618043,
        "latitude": 21.029084581806572,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1700,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82995110556332,
          21.029137547937754
        ]
      },
      "properties": {
        "tenDoiTuon": "07",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Cát Linh",
        "tuDien": "HĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "Hàng Đẫy",
        "diaChiLapD": "",
        "longitude": 105.82995110556332,
        "latitude": 21.029137547937754,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1701,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8296652587165,
          21.029195130006457
        ]
      },
      "properties": {
        "tenDoiTuon": "08",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Cát Linh",
        "tuDien": "HĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "Hàng Đẫy",
        "diaChiLapD": "",
        "longitude": 105.8296652587165,
        "latitude": 21.029195130006457,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1702,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8293794118697,
          21.02925271205291
        ]
      },
      "properties": {
        "tenDoiTuon": "09",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Cát Linh",
        "tuDien": "HĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "Hàng Đẫy",
        "diaChiLapD": "",
        "longitude": 105.8293794118697,
        "latitude": 21.02925271205291,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1703,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8290928585505,
          21.0293023922005
        ]
      },
      "properties": {
        "tenDoiTuon": "10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Cát Linh",
        "tuDien": "HĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "Hàng Đẫy",
        "diaChiLapD": "",
        "longitude": 105.8290928585505,
        "latitude": 21.0293023922005,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1704,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8288045210835,
          21.029356218857657
        ]
      },
      "properties": {
        "tenDoiTuon": "11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Cát Linh",
        "tuDien": "HĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "Hàng Đẫy",
        "diaChiLapD": "",
        "longitude": 105.8288045210835,
        "latitude": 21.029356218857657,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1705,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82851618361649,
          21.029410045496217
        ]
      },
      "properties": {
        "tenDoiTuon": "12",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Cát Linh",
        "tuDien": "HĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "Hàng Đẫy",
        "diaChiLapD": "",
        "longitude": 105.82851618361649,
        "latitude": 21.029410045496217,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1706,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82823321056495,
          21.029467627459148
        ]
      },
      "properties": {
        "tenDoiTuon": "13",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Cát Linh",
        "tuDien": "HĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "Hàng Đẫy",
        "diaChiLapD": "",
        "longitude": 105.82823321056495,
        "latitude": 21.029467627459148,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1707,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82794353199407,
          21.029527712962476
        ]
      },
      "properties": {
        "tenDoiTuon": "14",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Cát Linh",
        "tuDien": "HĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "Hàng Đẫy",
        "diaChiLapD": "",
        "longitude": 105.82794353199407,
        "latitude": 21.029527712962476,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1708,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82766433078874,
          21.029598203864424
        ]
      },
      "properties": {
        "tenDoiTuon": "15",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Cát Linh",
        "tuDien": "HĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "Hàng Đẫy",
        "diaChiLapD": "",
        "longitude": 105.82766433078874,
        "latitude": 21.029598203864424,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1709,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82762409765465,
          21.02968582847226
        ]
      },
      "properties": {
        "tenDoiTuon": "16",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Giảng Võ",
        "tuDien": "HĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Hàng Đẫy",
        "diaChiLapD": "",
        "longitude": 105.82762409765465,
        "latitude": 21.02968582847226,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1710,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82788427192894,
          21.02988247155969
        ]
      },
      "properties": {
        "tenDoiTuon": "17",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Giảng Võ",
        "tuDien": "HĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Hàng Đẫy",
        "diaChiLapD": "",
        "longitude": 105.82788427192894,
        "latitude": 21.02988247155969,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1711,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82814444620323,
          21.03007911438768
        ]
      },
      "properties": {
        "tenDoiTuon": "18",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Giảng Võ",
        "tuDien": "HĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Hàng Đẫy",
        "diaChiLapD": "",
        "longitude": 105.82814444620323,
        "latitude": 21.03007911438768,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1712,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82842071373132,
          21.030296695733185
        ]
      },
      "properties": {
        "tenDoiTuon": "19",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Giảng Võ",
        "tuDien": "HĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Hàng Đẫy",
        "diaChiLapD": "",
        "longitude": 105.82842071373132,
        "latitude": 21.030296695733185,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1713,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82866479475182,
          21.030472399265204
        ]
      },
      "properties": {
        "tenDoiTuon": "20",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Giảng Võ",
        "tuDien": "HĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Hàng Đẫy",
        "diaChiLapD": "",
        "longitude": 105.82866479475182,
        "latitude": 21.030472399265204,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1714,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82893838007705,
          21.030677234727857
        ]
      },
      "properties": {
        "tenDoiTuon": "21",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Giảng Võ",
        "tuDien": "HĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Hàng Đẫy",
        "diaChiLapD": "",
        "longitude": 105.82893838007705,
        "latitude": 21.030677234727857,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1715,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82918514330039,
          21.030865683104828
        ]
      },
      "properties": {
        "tenDoiTuon": "22",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Giảng Võ",
        "tuDien": "HĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Hàng Đẫy",
        "diaChiLapD": "",
        "longitude": 105.82918514330039,
        "latitude": 21.030865683104828,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1716,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82944531757467,
          21.031062324635414
        ]
      },
      "properties": {
        "tenDoiTuon": "23",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Giảng Võ",
        "tuDien": "HĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Hàng Đẫy",
        "diaChiLapD": "",
        "longitude": 105.82944531757467,
        "latitude": 21.031062324635414,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1717,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82965209912521,
          21.031235132558393
        ]
      },
      "properties": {
        "tenDoiTuon": "24",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Giảng Võ",
        "tuDien": "HĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Hàng Đẫy",
        "diaChiLapD": "",
        "longitude": 105.82965209912521,
        "latitude": 21.031235132558393,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1718,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82983180712478,
          21.031417890387857
        ]
      },
      "properties": {
        "tenDoiTuon": "25",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 1,
        "maXaPhuong": 19,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Giảng Võ",
        "tuDien": "HĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Hàng Đẫy",
        "diaChiLapD": "",
        "longitude": 105.82983180712478,
        "latitude": 21.031417890387857,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1719,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82993104885615,
          21.031690774949876
        ]
      },
      "properties": {
        "tenDoiTuon": "26",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 1,
        "maXaPhuong": 19,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Giảng Võ",
        "tuDien": "HĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Hàng Đẫy",
        "diaChiLapD": "",
        "longitude": 105.82993104885615,
        "latitude": 21.031690774949876,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1720,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83002333362073,
          21.032036652281526
        ]
      },
      "properties": {
        "tenDoiTuon": "27",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 1,
        "maXaPhuong": 28,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Giảng Võ",
        "tuDien": "HĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Hàng Đẫy",
        "diaChiLapD": "",
        "longitude": 105.83002333362073,
        "latitude": 21.032036652281526,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1721,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83181504924579,
          21.02885589619664
        ]
      },
      "properties": {
        "tenDoiTuon": "28",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Trịnh Hoài Đức",
        "tuDien": "HĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 10,
        "tuDien2": "Hàng Đẫy",
        "diaChiLapD": "",
        "longitude": 105.83181504924579,
        "latitude": 21.02885589619664,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1722,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83193344961543,
          21.029201925406937
        ]
      },
      "properties": {
        "tenDoiTuon": "29",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Trịnh Hoài Đức",
        "tuDien": "HĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 10,
        "tuDien2": "Hàng Đẫy",
        "diaChiLapD": "",
        "longitude": 105.83193344961543,
        "latitude": 21.029201925406937,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1723,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83205184998509,
          21.0295479538138
        ]
      },
      "properties": {
        "tenDoiTuon": "30",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Trịnh Hoài Đức",
        "tuDien": "HĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 10,
        "tuDien2": "Hàng Đẫy",
        "diaChiLapD": "",
        "longitude": 105.83205184998509,
        "latitude": 21.0295479538138,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1724,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83217025035474,
          21.029893981417256
        ]
      },
      "properties": {
        "tenDoiTuon": "31",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Trịnh Hoài Đức",
        "tuDien": "HĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 10,
        "tuDien2": "Hàng Đẫy",
        "diaChiLapD": "",
        "longitude": 105.83217025035474,
        "latitude": 21.029893981417256,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1725,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83228865072437,
          21.030240008217266
        ]
      },
      "properties": {
        "tenDoiTuon": "32",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Trịnh Hoài Đức",
        "tuDien": "HĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 10,
        "tuDien2": "Hàng Đẫy",
        "diaChiLapD": "",
        "longitude": 105.83228865072437,
        "latitude": 21.030240008217266,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1726,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83240705109404,
          21.03058603421383
        ]
      },
      "properties": {
        "tenDoiTuon": "33",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Trịnh Hoài Đức",
        "tuDien": "HĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 10,
        "tuDien2": "Hàng Đẫy",
        "diaChiLapD": "",
        "longitude": 105.83240705109404,
        "latitude": 21.03058603421383,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1727,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83252545146368,
          21.030932059406933
        ]
      },
      "properties": {
        "tenDoiTuon": "34",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 1,
        "maXaPhuong": 19,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Trịnh Hoài Đức",
        "tuDien": "HĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 10,
        "tuDien2": "Hàng Đẫy",
        "diaChiLapD": "",
        "longitude": 105.83252545146368,
        "latitude": 21.030932059406933,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1728,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83264385183332,
          21.031278083796554
        ]
      },
      "properties": {
        "tenDoiTuon": "35",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 1,
        "maXaPhuong": 19,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Trịnh Hoài Đức",
        "tuDien": "HĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 10,
        "tuDien2": "Hàng Đẫy",
        "diaChiLapD": "",
        "longitude": 105.83264385183332,
        "latitude": 21.031278083796554,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1729,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83134189080218,
          21.029047028259203
        ]
      },
      "properties": {
        "tenDoiTuon": "1-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 16 Trịnh Hoài Đức",
        "tuDien": "HĐ",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 6,
        "tuDien2": "Hàng Đẫy",
        "diaChiLapD": "",
        "longitude": 105.83134189080218,
        "latitude": 21.029047028259203,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1730,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80843155102087,
          21.022535491093265
        ]
      },
      "properties": {
        "tenDoiTuon": "47-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 35 Chùa Láng",
        "tuDien": "ĐHNT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "TT Đại Học Ngoại Thương",
        "diaChiLapD": "",
        "longitude": 105.80843155102087,
        "latitude": 21.022535491093265,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1731,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80819819883953,
          21.022535491093265
        ]
      },
      "properties": {
        "tenDoiTuon": "47-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 35 Chùa Láng",
        "tuDien": "ĐHNT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "TT Đại Học Ngoại Thương",
        "diaChiLapD": "",
        "longitude": 105.80819819883953,
        "latitude": 21.022535491093265,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1732,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80788438038101,
          21.023236519726986
        ]
      },
      "properties": {
        "tenDoiTuon": "44-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Tổ 77 Chùa Láng",
        "tuDien": "ĐHNT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "ĐHNT",
        "diaChiLapD": "",
        "longitude": 105.80788438038101,
        "latitude": 21.023236519726986,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1733,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80794204787439,
          21.023509419268375
        ]
      },
      "properties": {
        "tenDoiTuon": "44-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Tổ 77 Chùa Láng",
        "tuDien": "ĐHNT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "TT Đại Học Ngoại Thương",
        "diaChiLapD": "",
        "longitude": 105.80794204787439,
        "latitude": 21.023509419268375,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1734,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80834303812048,
          21.023435561184876
        ]
      },
      "properties": {
        "tenDoiTuon": "44-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Tổ 77 Chùa Láng",
        "tuDien": "ĐHNT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "ĐHNT",
        "diaChiLapD": "",
        "longitude": 105.80834303812048,
        "latitude": 21.023435561184876,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1735,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80831621603407,
          21.02318143902527
        ]
      },
      "properties": {
        "tenDoiTuon": "44-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Tổ 77 Chùa Láng",
        "tuDien": "ĐHNT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 7,
        "tuDien2": "ĐHNT",
        "diaChiLapD": "",
        "longitude": 105.80831621603407,
        "latitude": 21.02318143902527,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1736,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80853079275225,
          21.023117595459095
        ]
      },
      "properties": {
        "tenDoiTuon": "44-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Tổ 77 Chùa Láng",
        "tuDien": "ĐHNT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 7,
        "tuDien2": "ĐHNT",
        "diaChiLapD": "",
        "longitude": 105.80853079275225,
        "latitude": 21.023117595459095,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1737,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83142772148585,
          21.02869652801724
        ]
      },
      "properties": {
        "tenDoiTuon": "2-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 21 Cát Linh",
        "tuDien": "HĐ",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Hàng Đẫy",
        "diaChiLapD": "",
        "longitude": 105.83142772148585,
        "latitude": 21.02869652801724,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1738,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83126142453398,
          21.027942949707274
        ]
      },
      "properties": {
        "tenDoiTuon": "2-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 21 Cát Linh",
        "tuDien": "HĐ",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Hàng Đẫy",
        "diaChiLapD": "",
        "longitude": 105.83126142453398,
        "latitude": 21.027942949707274,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1739,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83125337790179,
          21.028691520864815
        ]
      },
      "properties": {
        "tenDoiTuon": "2-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 23 Cát Linh",
        "tuDien": "HĐ",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Hàng Đẫy",
        "diaChiLapD": "",
        "longitude": 105.83125337790179,
        "latitude": 21.028691520864815,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1740,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83119705151223,
          21.028278430223086
        ]
      },
      "properties": {
        "tenDoiTuon": "2-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 23 Cát Linh",
        "tuDien": "HĐ",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Hàng Đẫy",
        "diaChiLapD": "",
        "longitude": 105.83119705151223,
        "latitude": 21.028278430223086,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1741,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83112463186727,
          21.027910403049418
        ]
      },
      "properties": {
        "tenDoiTuon": "2-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 23 Cát Linh",
        "tuDien": "HĐ",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Hàng Đẫy",
        "diaChiLapD": "",
        "longitude": 105.83112463186727,
        "latitude": 21.027910403049418,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1742,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83133895709314,
          21.028238764071133
        ]
      },
      "properties": {
        "tenDoiTuon": "2-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 21 Cát Linh",
        "tuDien": "HĐ",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Hàng Đẫy",
        "diaChiLapD": "",
        "longitude": 105.83133895709314,
        "latitude": 21.028238764071133,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1743,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83062037657821,
          21.02854380979742
        ]
      },
      "properties": {
        "tenDoiTuon": "4-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 25 B Cát Linh",
        "tuDien": "HĐ",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Hàng Đẫy",
        "diaChiLapD": "",
        "longitude": 105.83062037657821,
        "latitude": 21.02854380979742,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1744,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83058550785961,
          21.02821083346289
        ]
      },
      "properties": {
        "tenDoiTuon": "4-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 25 B Cát Linh",
        "tuDien": "HĐ",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Hàng Đẫy",
        "diaChiLapD": "",
        "longitude": 105.83058550785961,
        "latitude": 21.02821083346289,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1745,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83054393362163,
          21.028014301969417
        ]
      },
      "properties": {
        "tenDoiTuon": "4-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 25 B Cát Linh",
        "tuDien": "HĐ",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Hàng Đẫy",
        "diaChiLapD": "",
        "longitude": 105.83054393362163,
        "latitude": 21.028014301969417,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1746,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83075180482047,
          21.02794044611801
        ]
      },
      "properties": {
        "tenDoiTuon": "4-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 25 B Cát Linh",
        "tuDien": "HĐ",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Hàng Đẫy",
        "diaChiLapD": "",
        "longitude": 105.83075180482047,
        "latitude": 21.02794044611801,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1747,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83037093114143,
          21.02803057528747
        ]
      },
      "properties": {
        "tenDoiTuon": "4-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 25 B Cát Linh",
        "tuDien": "HĐ",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Hàng Đẫy",
        "diaChiLapD": "",
        "longitude": 105.83037093114143,
        "latitude": 21.02803057528747,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1748,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8071655483558,
          21.022894768681134
        ]
      },
      "properties": {
        "tenDoiTuon": "42-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 59 Chùa Láng",
        "tuDien": "ĐHNT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "TT Đại Học Ngoại Thương",
        "diaChiLapD": "",
        "longitude": 105.8071655483558,
        "latitude": 21.022894768681134,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1749,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80727686003098,
          21.022897272354328
        ]
      },
      "properties": {
        "tenDoiTuon": "42-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 59 Chùa Láng",
        "tuDien": "ĐHNT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "TT Đại Học Ngoại Thương",
        "diaChiLapD": "",
        "longitude": 105.80727686003098,
        "latitude": 21.022897272354328,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1750,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80754642203482,
          21.022902279701366
        ]
      },
      "properties": {
        "tenDoiTuon": "42-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 59 Chùa Láng",
        "tuDien": "ĐHNT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "TT Đại Học Ngoại Thương",
        "diaChiLapD": "",
        "longitude": 105.80754642203482,
        "latitude": 21.022902279701366,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1751,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80719505265893,
          21.022740792678775
        ]
      },
      "properties": {
        "tenDoiTuon": "42-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 59 Chùa Láng",
        "tuDien": "ĐHNT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TT Đại Học Ngoại Thương",
        "diaChiLapD": "",
        "longitude": 105.80719505265893,
        "latitude": 21.022740792678775,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1752,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80733452752439,
          21.02274830370677
        ]
      },
      "properties": {
        "tenDoiTuon": "42-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 59 Chùa Láng",
        "tuDien": "ĐHNT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "TT Đại Học Ngoại Thương",
        "diaChiLapD": "",
        "longitude": 105.80733452752439,
        "latitude": 21.02274830370677,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1753,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80755178645032,
          21.02286347275831
        ]
      },
      "properties": {
        "tenDoiTuon": "42-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 59 Chùa Láng",
        "tuDien": "ĐHNT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "TT Đại Học Ngoại Thương",
        "diaChiLapD": "",
        "longitude": 105.80755178645032,
        "latitude": 21.02286347275831,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1754,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80761481836818,
          21.022767081275095
        ]
      },
      "properties": {
        "tenDoiTuon": "42-7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 59 Chùa Láng",
        "tuDien": "ĐHNT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "TT Đại Học Ngoại Thương",
        "diaChiLapD": "",
        "longitude": 105.80761481836818,
        "latitude": 21.022767081275095,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1755,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80761213615146,
          21.02233895211791
        ]
      },
      "properties": {
        "tenDoiTuon": "42-8",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 59 Chùa Láng",
        "tuDien": "ĐHNT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "TT Đại Học Ngoại Thương",
        "diaChiLapD": "",
        "longitude": 105.80761213615146,
        "latitude": 21.02233895211791,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1756,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80731977536387,
          21.02230515239532
        ]
      },
      "properties": {
        "tenDoiTuon": "42-9",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 59 Chùa Láng",
        "tuDien": "ĐHNT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "TT Đại Học Ngoại Thương",
        "diaChiLapD": "",
        "longitude": 105.80731977536387,
        "latitude": 21.02230515239532,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1757,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8076148183592,
          21.022248819507034
        ]
      },
      "properties": {
        "tenDoiTuon": "42-10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 59 Chùa Láng",
        "tuDien": "ĐHNT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "TT Đại Học Ngoại Thương",
        "diaChiLapD": "",
        "longitude": 105.8076148183592,
        "latitude": 21.022248819507034,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1758,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80757994964061,
          21.02212488707827
        ]
      },
      "properties": {
        "tenDoiTuon": "42-11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 59 Chùa Láng",
        "tuDien": "ĐHNT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "TT Đại Học Ngoại Thương",
        "diaChiLapD": "",
        "longitude": 105.80757994964061,
        "latitude": 21.02212488707827,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1759,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80735732629019,
          21.022127390765192
        ]
      },
      "properties": {
        "tenDoiTuon": "42-12",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 59 Chùa Láng",
        "tuDien": "ĐHNT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "TT Đại Học Ngoại Thương",
        "diaChiLapD": "",
        "longitude": 105.80735732629019,
        "latitude": 21.022127390765192,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1760,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80714811398751,
          21.02211362048902
        ]
      },
      "properties": {
        "tenDoiTuon": "42-13",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 59 Chùa Láng",
        "tuDien": "ĐHNT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "TT Đại Học Ngoại Thương",
        "diaChiLapD": "",
        "longitude": 105.80714811398751,
        "latitude": 21.02211362048902,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1761,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80715750172357,
          21.022225034503812
        ]
      },
      "properties": {
        "tenDoiTuon": "42-14",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 59 Chùa Láng",
        "tuDien": "ĐHNT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 8,
        "tuDien2": "TT Đại Học Ngoại Thương",
        "diaChiLapD": "",
        "longitude": 105.80715750172357,
        "latitude": 21.022225034503812,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1762,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8308885974782,
          21.029134653190273
        ]
      },
      "properties": {
        "tenDoiTuon": "4-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 20 Cát Linh",
        "tuDien": "HĐ",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Hàng Đẫy",
        "diaChiLapD": "",
        "longitude": 105.8308885974782,
        "latitude": 21.029134653190273,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1763,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83085104655181,
          21.02915718530741
        ]
      },
      "properties": {
        "tenDoiTuon": "4-7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 20 Cát Linh",
        "tuDien": "HĐ",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Hàng Đẫy",
        "diaChiLapD": "",
        "longitude": 105.83085104655181,
        "latitude": 21.02915718530741,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1764,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83088591527043,
          21.02932492429311
        ]
      },
      "properties": {
        "tenDoiTuon": "4-8",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 20 Cát Linh",
        "tuDien": "HĐ",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Hàng Đẫy",
        "diaChiLapD": "",
        "longitude": 105.83088591527043,
        "latitude": 21.02932492429311,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1765,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8309771103786,
          21.02960281980884
        ]
      },
      "properties": {
        "tenDoiTuon": "4-9",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 20 Cát Linh",
        "tuDien": "HĐ",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Hàng Đẫy",
        "diaChiLapD": "",
        "longitude": 105.8309771103786,
        "latitude": 21.02960281980884,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1766,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8310602588545,
          21.02970045865068
        ]
      },
      "properties": {
        "tenDoiTuon": "4-10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 20 Cát Linh",
        "tuDien": "HĐ",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Hàng Đẫy",
        "diaChiLapD": "",
        "longitude": 105.8310602588545,
        "latitude": 21.02970045865068,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1767,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83094760607545,
          21.02976054406013
        ]
      },
      "properties": {
        "tenDoiTuon": "4-11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 20 Cát Linh",
        "tuDien": "HĐ",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "Hàng Đẫy",
        "diaChiLapD": "",
        "longitude": 105.83094760607545,
        "latitude": 21.02976054406013,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1768,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83055332134873,
          21.029244810173715
        ]
      },
      "properties": {
        "tenDoiTuon": "5-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 30 Cát Linh",
        "tuDien": "HĐ",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 5,
        "tuDien2": "Hàng Đẫy",
        "diaChiLapD": "",
        "longitude": 105.83055332134873,
        "latitude": 21.029244810173715,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1769,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83052381705458,
          21.029374995595237
        ]
      },
      "properties": {
        "tenDoiTuon": "5-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 30 Cát Linh",
        "tuDien": "HĐ",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "Hàng Đẫy",
        "diaChiLapD": "",
        "longitude": 105.83052381705458,
        "latitude": 21.029374995595237,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1770,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82994714211159,
          21.02897192113359
        ]
      },
      "properties": {
        "tenDoiTuon": "7-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 27 Cát Linh",
        "tuDien": "HĐ",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Hàng Đẫy",
        "diaChiLapD": "",
        "longitude": 105.82994714211159,
        "latitude": 21.02897192113359,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1771,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82991227339296,
          21.028526284745684
        ]
      },
      "properties": {
        "tenDoiTuon": "7-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 27 Cát Linh",
        "tuDien": "HĐ",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Hàng Đẫy",
        "diaChiLapD": "",
        "longitude": 105.82991227339296,
        "latitude": 21.028526284745684,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1772,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82985594700345,
          21.028260905140144
        ]
      },
      "properties": {
        "tenDoiTuon": "7-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 27 Cát Linh",
        "tuDien": "HĐ",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Hàng Đẫy",
        "diaChiLapD": "",
        "longitude": 105.82985594700345,
        "latitude": 21.028260905140144,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1773,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8296494169085,
          21.028088157784225
        ]
      },
      "properties": {
        "tenDoiTuon": "7-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 27 Cát Linh",
        "tuDien": "HĐ",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Hàng Đẫy",
        "diaChiLapD": "",
        "longitude": 105.8296494169085,
        "latitude": 21.028088157784225,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1774,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82970574329802,
          21.02786033125523
        ]
      },
      "properties": {
        "tenDoiTuon": "7-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 27 Cát Linh",
        "tuDien": "HĐ",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Hàng Đẫy",
        "diaChiLapD": "",
        "longitude": 105.82970574329802,
        "latitude": 21.02786033125523,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1775,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82903787325584,
          21.027630000784345
        ]
      },
      "properties": {
        "tenDoiTuon": "7-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 27 Cát Linh",
        "tuDien": "HĐ",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 5,
        "tuDien2": "Hàng Đẫy",
        "diaChiLapD": "",
        "longitude": 105.82903787325584,
        "latitude": 21.027630000784345,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1776,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82908078859768,
          21.027947956884
        ]
      },
      "properties": {
        "tenDoiTuon": "7-7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 27 Cát Linh",
        "tuDien": "HĐ",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Hàng Đẫy",
        "diaChiLapD": "",
        "longitude": 105.82908078859768,
        "latitude": 21.027947956884,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1777,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8290941996454,
          21.030569191172518
        ]
      },
      "properties": {
        "tenDoiTuon": "21-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 59 Giảng Võ",
        "tuDien": "HĐ",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Hàng Đẫy",
        "diaChiLapD": "",
        "longitude": 105.8290941996454,
        "latitude": 21.030569191172518,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1778,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82921221684443,
          21.030445265661523
        ]
      },
      "properties": {
        "tenDoiTuon": "21-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 59 Giảng Võ",
        "tuDien": "HĐ",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Hàng Đẫy",
        "diaChiLapD": "",
        "longitude": 105.82921221684443,
        "latitude": 21.030445265661523,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1779,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82935437392213,
          21.030288793908987
        ]
      },
      "properties": {
        "tenDoiTuon": "21-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 59 Giảng Võ",
        "tuDien": "HĐ",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Hàng Đẫy",
        "diaChiLapD": "",
        "longitude": 105.82935437392213,
        "latitude": 21.030288793908987,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1780,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8289788646586,
          21.030859602067284
        ]
      },
      "properties": {
        "tenDoiTuon": "22-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 1,
        "maXaPhuong": 28,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 26,34,36,56 Giảng Võ",
        "tuDien": "HĐ",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "Hàng Đẫy",
        "diaChiLapD": "",
        "longitude": 105.8289788646586,
        "latitude": 21.030859602067284,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1781,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82886889408726,
          21.030912176393162
        ]
      },
      "properties": {
        "tenDoiTuon": "22-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 1,
        "maXaPhuong": 28,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 26,34,36,56 Giảng Võ",
        "tuDien": "HĐ",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "Hàng Đẫy",
        "diaChiLapD": "",
        "longitude": 105.82886889408726,
        "latitude": 21.030912176393162,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1782,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82874014805276,
          21.03076697106775
        ]
      },
      "properties": {
        "tenDoiTuon": "22-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 1,
        "maXaPhuong": 28,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 26,34,36,56 Giảng Võ",
        "tuDien": "HĐ",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "Hàng Đẫy",
        "diaChiLapD": "",
        "longitude": 105.82874014805276,
        "latitude": 21.03076697106775,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1783,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82866772841679,
          21.030792006478666
        ]
      },
      "properties": {
        "tenDoiTuon": "22-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 1,
        "maXaPhuong": 28,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 26,34,36,56 Giảng Võ",
        "tuDien": "HĐ",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "Hàng Đẫy",
        "diaChiLapD": "",
        "longitude": 105.82866772841679,
        "latitude": 21.030792006478666,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1784,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82949384878759,
          21.030897155159554
        ]
      },
      "properties": {
        "tenDoiTuon": "23-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Giảng Võ",
        "tuDien": "HĐ",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 5,
        "tuDien2": "Hàng Đẫy",
        "diaChiLapD": "",
        "longitude": 105.82949384878759,
        "latitude": 21.030897155159554,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1785,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8296091837744,
          21.031027339136813
        ]
      },
      "properties": {
        "tenDoiTuon": "24-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 43 Giảng Võ",
        "tuDien": "HĐ",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 8,
        "tuDien2": "Hàng Đẫy",
        "diaChiLapD": "",
        "longitude": 105.8296091837744,
        "latitude": 21.031027339136813,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1786,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82984253596473,
          21.03101732498927
        ]
      },
      "properties": {
        "tenDoiTuon": "24-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 43 Giảng Võ",
        "tuDien": "HĐ",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 8,
        "tuDien2": "Hàng Đẫy",
        "diaChiLapD": "",
        "longitude": 105.82984253596473,
        "latitude": 21.03101732498927,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1787,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82978897555981,
          21.0309009887021
        ]
      },
      "properties": {
        "tenDoiTuon": "24-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 43 Giảng Võ",
        "tuDien": "HĐ",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 8,
        "tuDien2": "Hàng Đẫy",
        "diaChiLapD": "",
        "longitude": 105.82978897555981,
        "latitude": 21.0309009887021,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1788,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82990422676974,
          21.031187565415195
        ]
      },
      "properties": {
        "tenDoiTuon": "24-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 43 Giảng Võ",
        "tuDien": "HĐ",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 8,
        "tuDien2": "Hàng Đẫy",
        "diaChiLapD": "",
        "longitude": 105.82990422676974,
        "latitude": 21.031187565415195,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1789,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84052403816791,
          21.02782053086831
        ]
      },
      "properties": {
        "tenDoiTuon": "09",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Nguyễn Khuyến",
        "tuDien": "NK",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 10,
        "tuDien2": "Nguyễn Khuyến",
        "diaChiLapD": "",
        "longitude": 105.84052403816791,
        "latitude": 21.02782053086831,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1790,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84074934372605,
          21.027785480592687
        ]
      },
      "properties": {
        "tenDoiTuon": "10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Nguyễn Khuyến",
        "tuDien": "NK",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 10,
        "tuDien2": "Nguyễn Khuyến",
        "diaChiLapD": "",
        "longitude": 105.84074934372605,
        "latitude": 21.027785480592687,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1791,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84104304559057,
          21.027751682105883
        ]
      },
      "properties": {
        "tenDoiTuon": "11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Nguyễn Khuyến",
        "tuDien": "NK",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 10,
        "tuDien2": "Nguyễn Khuyến",
        "diaChiLapD": "",
        "longitude": 105.84104304559057,
        "latitude": 21.027751682105883,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1792,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84058036455744,
          21.027600214713754
        ]
      },
      "properties": {
        "tenDoiTuon": "08",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Nguyễn Khuyến",
        "tuDien": "NK",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 10,
        "tuDien2": "Nguyễn Khuyến",
        "diaChiLapD": "",
        "longitude": 105.84058036455744,
        "latitude": 21.027600214713754,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1793,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84024106509841,
          21.027471279541384
        ]
      },
      "properties": {
        "tenDoiTuon": "12",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Trần Quý Cáp",
        "tuDien": "NK",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 10,
        "tuDien2": "Nguyễn Khuyến",
        "diaChiLapD": "",
        "longitude": 105.84024106509841,
        "latitude": 21.027471279541384,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1794,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84005331046666,
          21.027225926864826
        ]
      },
      "properties": {
        "tenDoiTuon": "13",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Trần Quý Cáp",
        "tuDien": "NK",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 10,
        "tuDien2": "Nguyễn Khuyến",
        "diaChiLapD": "",
        "longitude": 105.84005331046666,
        "latitude": 21.027225926864826,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1795,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83996211535852,
          21.027005609832166
        ]
      },
      "properties": {
        "tenDoiTuon": "14",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Trần Quý Cáp",
        "tuDien": "NK",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 10,
        "tuDien2": "Nguyễn Khuyến",
        "diaChiLapD": "",
        "longitude": 105.83996211535852,
        "latitude": 21.027005609832166,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1796,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83986555583488,
          21.026772774431567
        ]
      },
      "properties": {
        "tenDoiTuon": "15",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Trần Quý Cáp",
        "tuDien": "NK",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 10,
        "tuDien2": "Nguyễn Khuyến",
        "diaChiLapD": "",
        "longitude": 105.83986555583488,
        "latitude": 21.026772774431567,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1797,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83976094968801,
          21.026529924217353
        ]
      },
      "properties": {
        "tenDoiTuon": "16",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Trần Quý Cáp",
        "tuDien": "NK",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 10,
        "tuDien2": "Nguyễn Khuyến",
        "diaChiLapD": "",
        "longitude": 105.83976094968801,
        "latitude": 21.026529924217353,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1798,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83969389444955,
          21.02640474354012
        ]
      },
      "properties": {
        "tenDoiTuon": "17",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Nguyễn Như Đổ",
        "tuDien": "NK",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 10,
        "tuDien2": "Nguyễn Khuyến",
        "diaChiLapD": "",
        "longitude": 105.83969389444955,
        "latitude": 21.02640474354012,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1799,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8391990268876,
          21.026529924217353
        ]
      },
      "properties": {
        "tenDoiTuon": "18",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Nguyễn Như Đổ",
        "tuDien": "NK",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 10,
        "tuDien2": "Nguyễn Khuyến",
        "diaChiLapD": "",
        "longitude": 105.8391990268876,
        "latitude": 21.026529924217353,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1800,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83870415932563,
          21.026655104789437
        ]
      },
      "properties": {
        "tenDoiTuon": "19",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Nguyễn Như Đổ",
        "tuDien": "NK",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 10,
        "tuDien2": "Nguyễn Khuyến",
        "diaChiLapD": "",
        "longitude": 105.83870415932563,
        "latitude": 21.026655104789437,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1801,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83902904189334,
          21.027681894463417
        ]
      },
      "properties": {
        "tenDoiTuon": "22",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Ngô Sỹ Liên",
        "tuDien": "NK",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Nguyễn Khuyến",
        "diaChiLapD": "",
        "longitude": 105.83902904189334,
        "latitude": 21.027681894463417,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1802,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83935224807897,
          21.02759802406916
        ]
      },
      "properties": {
        "tenDoiTuon": "21",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Ngô Sỹ Liên",
        "tuDien": "NK",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Nguyễn Khuyến",
        "diaChiLapD": "",
        "longitude": 105.83935224807897,
        "latitude": 21.02759802406916,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1803,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8398310223855,
          21.027471592491246
        ]
      },
      "properties": {
        "tenDoiTuon": "20",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Ngô Sỹ Liên",
        "tuDien": "NK",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Nguyễn Khuyến",
        "diaChiLapD": "",
        "longitude": 105.8398310223855,
        "latitude": 21.027471592491246,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1804,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84005498683975,
          21.027407750762507
        ]
      },
      "properties": {
        "tenDoiTuon": "12-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Ngô Sỹ Liên",
        "tuDien": "NK",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Nguyễn Khuyến",
        "diaChiLapD": "",
        "longitude": 105.84005498683975,
        "latitude": 21.027407750762507,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1805,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83892175352977,
          21.027681894463417
        ]
      },
      "properties": {
        "tenDoiTuon": "23",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Ngô Sỹ Liên",
        "tuDien": "NK",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 10,
        "tuDien2": "Nguyễn Khuyến",
        "diaChiLapD": "",
        "longitude": 105.83892175352977,
        "latitude": 21.027681894463417,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1806,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83876484430499,
          21.027407750762507
        ]
      },
      "properties": {
        "tenDoiTuon": "24",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Ngô Sỹ Liên",
        "tuDien": "NK",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 10,
        "tuDien2": "Nguyễn Khuyến",
        "diaChiLapD": "",
        "longitude": 105.83876484430499,
        "latitude": 21.027407750762507,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1807,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83856636083327,
          21.027019692606437
        ]
      },
      "properties": {
        "tenDoiTuon": "25",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Ngô Sỹ Liên",
        "tuDien": "NK",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 10,
        "tuDien2": "Nguyễn Khuyến",
        "diaChiLapD": "",
        "longitude": 105.83856636083327,
        "latitude": 21.027019692606437,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1808,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83855697310617,
          21.026615359969227
        ]
      },
      "properties": {
        "tenDoiTuon": "26",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Ngô Sỹ Liên",
        "tuDien": "NK",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 10,
        "tuDien2": "Nguyễn Khuyến",
        "diaChiLapD": "",
        "longitude": 105.83855697310617,
        "latitude": 21.026615359969227,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1809,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.838404087193,
          21.026302408258836
        ]
      },
      "properties": {
        "tenDoiTuon": "27",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Ngô Sỹ Liên",
        "tuDien": "NK",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 10,
        "tuDien2": "Nguyễn Khuyến",
        "diaChiLapD": "",
        "longitude": 105.838404087193,
        "latitude": 21.026302408258836,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1810,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83878764307977,
          21.0271461245685
        ]
      },
      "properties": {
        "tenDoiTuon": "28",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Y Miếu",
        "tuDien": "NK",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Nguyễn Khuyến",
        "diaChiLapD": "",
        "longitude": 105.83878764307977,
        "latitude": 21.0271461245685,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1811,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83932542598357,
          21.02733890180857
        ]
      },
      "properties": {
        "tenDoiTuon": "32",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Y Miếu",
        "tuDien": "NK",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Nguyễn Khuyến",
        "diaChiLapD": "",
        "longitude": 105.83932542598357,
        "latitude": 21.02733890180857,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1812,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83899417317474,
          21.027083534501383
        ]
      },
      "properties": {
        "tenDoiTuon": "29",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Y Miếu",
        "tuDien": "NK",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Nguyễn Khuyến",
        "diaChiLapD": "",
        "longitude": 105.83899417317474,
        "latitude": 21.027083534501383,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1813,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83917790449489,
          21.02679937526809
        ]
      },
      "properties": {
        "tenDoiTuon": "31",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Y Miếu",
        "tuDien": "NK",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 6,
        "tuDien2": "Nguyễn Khuyến",
        "diaChiLapD": "",
        "longitude": 105.83917790449489,
        "latitude": 21.02679937526809,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1814,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83938692527676,
          21.026884319086207
        ]
      },
      "properties": {
        "tenDoiTuon": "36",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Y Miếu",
        "tuDien": "NK",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Nguyễn Khuyến",
        "diaChiLapD": "",
        "longitude": 105.83938692527676,
        "latitude": 21.026884319086207,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1815,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83919533884522,
          21.027018440804046
        ]
      },
      "properties": {
        "tenDoiTuon": "30",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Y Miếu",
        "tuDien": "NK",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Nguyễn Khuyến",
        "diaChiLapD": "",
        "longitude": 105.83919533884522,
        "latitude": 21.027018440804046,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1816,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83939382231692,
          21.027537937787855
        ]
      },
      "properties": {
        "tenDoiTuon": "33",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Y Miếu",
        "tuDien": "NK",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Nguyễn Khuyến",
        "diaChiLapD": "",
        "longitude": 105.83939382231692,
        "latitude": 21.027537937787855,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1817,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83948569497558,
          21.027199432032422
        ]
      },
      "properties": {
        "tenDoiTuon": "35",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Y Miếu",
        "tuDien": "NK",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Nguyễn Khuyến",
        "diaChiLapD": "",
        "longitude": 105.83948569497558,
        "latitude": 21.027199432032422,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1818,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83958828246807,
          21.027482858675526
        ]
      },
      "properties": {
        "tenDoiTuon": "34",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Y Miếu",
        "tuDien": "NK",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Nguyễn Khuyến",
        "diaChiLapD": "",
        "longitude": 105.83958828246807,
        "latitude": 21.027482858675526,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1819,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83886945045185,
          21.028649530071565
        ]
      },
      "properties": {
        "tenDoiTuon": "1-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 102 Nguyễn Khuyến",
        "tuDien": "NK",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Nguyễn Khuyến",
        "diaChiLapD": "",
        "longitude": 105.83886945045185,
        "latitude": 21.028649530071565,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1820,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84080332315013,
          21.028012368391618
        ]
      },
      "properties": {
        "tenDoiTuon": "10-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 14 Nguyễn Khuyến",
        "tuDien": "NK",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Nguyễn Khuyến",
        "diaChiLapD": "",
        "longitude": 105.84080332315013,
        "latitude": 21.028012368391618,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1821,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84070005810712,
          21.028295273520644
        ]
      },
      "properties": {
        "tenDoiTuon": "10-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 14 Nguyễn Khuyến",
        "tuDien": "NK",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Nguyễn Khuyến",
        "diaChiLapD": "",
        "longitude": 105.84070005810712,
        "latitude": 21.028295273520644,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1822,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84074297344897,
          21.028440481254325
        ]
      },
      "properties": {
        "tenDoiTuon": "10-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 14 Nguyễn Khuyến",
        "tuDien": "NK",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "Nguyễn Khuyến",
        "diaChiLapD": "",
        "longitude": 105.84074297344897,
        "latitude": 21.028440481254325,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1823,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84114530479891,
          21.027897203318137
        ]
      },
      "properties": {
        "tenDoiTuon": "11-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 8 Nguyễn Khuyến",
        "tuDien": "NK",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Nguyễn Khuyến",
        "diaChiLapD": "",
        "longitude": 105.84114530479891,
        "latitude": 21.027897203318137,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1824,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83906256949909,
          21.026544007037387
        ]
      },
      "properties": {
        "tenDoiTuon": "19-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 35 Nguyễn Như Đổ",
        "tuDien": "NK",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Nguyễn Khuyến",
        "diaChiLapD": "",
        "longitude": 105.83906256949909,
        "latitude": 21.026544007037387,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1825,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83897405660767,
          21.026256091350053
        ]
      },
      "properties": {
        "tenDoiTuon": "19-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 35 Nguyễn Như Đổ",
        "tuDien": "NK",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "Nguyễn Khuyến",
        "diaChiLapD": "",
        "longitude": 105.83897405660767,
        "latitude": 21.026256091350053,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1826,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83888822591501,
          21.02607958624061
        ]
      },
      "properties": {
        "tenDoiTuon": "19-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 35 Nguyễn Như Đổ",
        "tuDien": "NK",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 5,
        "tuDien2": "Nguyễn Khuyến",
        "diaChiLapD": "",
        "longitude": 105.83888822591501,
        "latitude": 21.02607958624061,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1827,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83896869218322,
          21.025966923295375
        ]
      },
      "properties": {
        "tenDoiTuon": "19-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 35 Nguyễn Như Đổ",
        "tuDien": "NK",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "Nguyễn Khuyến",
        "diaChiLapD": "",
        "longitude": 105.83896869218322,
        "latitude": 21.025966923295375,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1828,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83874875104955,
          21.02562893394957
        ]
      },
      "properties": {
        "tenDoiTuon": "19-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 35 Nguyễn Như Đổ",
        "tuDien": "NK",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "Nguyễn Khuyến",
        "diaChiLapD": "",
        "longitude": 105.83874875104955,
        "latitude": 21.02562893394957,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1829,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83831825650034,
          21.026475157684466
        ]
      },
      "properties": {
        "tenDoiTuon": "27-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Phổ Giác",
        "tuDien": "NK",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Nguyễn Khuyến",
        "diaChiLapD": "",
        "longitude": 105.83831825650034,
        "latitude": 21.026475157684466,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1830,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83819755709807,
          21.026405056492223
        ]
      },
      "properties": {
        "tenDoiTuon": "27-8",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Phổ Giác",
        "tuDien": "NK",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "Nguyễn Khuyến",
        "diaChiLapD": "",
        "longitude": 105.83819755709807,
        "latitude": 21.026405056492223,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1831,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83812379634924,
          21.02673803686094
        ]
      },
      "properties": {
        "tenDoiTuon": "27-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Phổ Giác",
        "tuDien": "NK",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "Nguyễn Khuyến",
        "diaChiLapD": "",
        "longitude": 105.83812379634924,
        "latitude": 21.02673803686094,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1832,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83809697425384,
          21.026590323856702
        ]
      },
      "properties": {
        "tenDoiTuon": "27-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Phổ Giác",
        "tuDien": "NK",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Nguyễn Khuyến",
        "diaChiLapD": "",
        "longitude": 105.83809697425384,
        "latitude": 21.026590323856702,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1833,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83836117184218,
          21.026734281447514
        ]
      },
      "properties": {
        "tenDoiTuon": "27-7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Phổ Giác",
        "tuDien": "NK",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "Nguyễn Khuyến",
        "diaChiLapD": "",
        "longitude": 105.83836117184218,
        "latitude": 21.026734281447514,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1834,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83785691655314,
          21.026697979111376
        ]
      },
      "properties": {
        "tenDoiTuon": "27-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Phổ Giác",
        "tuDien": "NK",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 5,
        "tuDien2": "Nguyễn Khuyến",
        "diaChiLapD": "",
        "longitude": 105.83785691655314,
        "latitude": 21.026697979111376,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1835,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83772932011065,
          21.026488748728216
        ]
      },
      "properties": {
        "tenDoiTuon": "27-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Phổ Giác",
        "tuDien": "NK",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Nguyễn Khuyến",
        "diaChiLapD": "",
        "longitude": 105.83772932011065,
        "latitude": 21.026488748728216,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1836,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83759540117252,
          21.02667294301272
        ]
      },
      "properties": {
        "tenDoiTuon": "27-4-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Phổ Giác",
        "tuDien": "NK",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "Nguyễn Khuyến",
        "diaChiLapD": "",
        "longitude": 105.83759540117252,
        "latitude": 21.02667294301272,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1837,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8376892784884,
          21.026803130680747
        ]
      },
      "properties": {
        "tenDoiTuon": "27-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Phổ Giác",
        "tuDien": "NK",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Nguyễn Khuyến",
        "diaChiLapD": "",
        "longitude": 105.8376892784884,
        "latitude": 21.026803130680747,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1838,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83844297922325,
          21.02707477188992
        ]
      },
      "properties": {
        "tenDoiTuon": "25-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 56 Ngô Sỹ Liên",
        "tuDien": "NK",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Nguyễn Khuyến",
        "diaChiLapD": "",
        "longitude": 105.83844297922325,
        "latitude": 21.02707477188992,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1839,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83834373748287,
          21.027002167375485
        ]
      },
      "properties": {
        "tenDoiTuon": "25-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 56 Ngô Sỹ Liên",
        "tuDien": "NK",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Nguyễn Khuyến",
        "diaChiLapD": "",
        "longitude": 105.83834373748287,
        "latitude": 21.027002167375485,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1840,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8385138662435,
          21.02747016186429
        ]
      },
      "properties": {
        "tenDoiTuon": "24-7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 38 Ngô Sỹ Liên",
        "tuDien": "NK",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Nguyễn Khuyến",
        "diaChiLapD": "",
        "longitude": 105.8385138662435,
        "latitude": 21.02747016186429,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1841,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83844412880629,
          21.027242334390646
        ]
      },
      "properties": {
        "tenDoiTuon": "24-12",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 38 Ngô Sỹ Liên",
        "tuDien": "NK",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "Nguyễn Khuyến",
        "diaChiLapD": "",
        "longitude": 105.83844412880629,
        "latitude": 21.027242334390646,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1842,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83826039749063,
          21.027550902835735
        ]
      },
      "properties": {
        "tenDoiTuon": "24-8",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 38 Ngô Sỹ Liên",
        "tuDien": "NK",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Nguyễn Khuyến",
        "diaChiLapD": "",
        "longitude": 105.83826039749063,
        "latitude": 21.027550902835735,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1843,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83819468336952,
          21.0273712697619
        ]
      },
      "properties": {
        "tenDoiTuon": "24-10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 38 Ngô Sỹ Liên",
        "tuDien": "NK",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Nguyễn Khuyến",
        "diaChiLapD": "",
        "longitude": 105.83819468336952,
        "latitude": 21.0273712697619,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1844,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83800692873773,
          21.027631643763463
        ]
      },
      "properties": {
        "tenDoiTuon": "24-9",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 38 Ngô Sỹ Liên",
        "tuDien": "NK",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Nguyễn Khuyến",
        "diaChiLapD": "",
        "longitude": 105.83800692873773,
        "latitude": 21.027631643763463,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1845,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83803643303189,
          21.027385039552403
        ]
      },
      "properties": {
        "tenDoiTuon": "24-11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 38 Ngô Sỹ Liên",
        "tuDien": "NK",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "Nguyễn Khuyến",
        "diaChiLapD": "",
        "longitude": 105.83803643303189,
        "latitude": 21.027385039552403,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1846,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83857958036015,
          21.027527744577554
        ]
      },
      "properties": {
        "tenDoiTuon": "24-13",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 36 Ngô Sỹ Liên",
        "tuDien": "NK",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "Nguyễn Khuyến",
        "diaChiLapD": "",
        "longitude": 105.83857958036015,
        "latitude": 21.027527744577554,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1847,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83839450793612,
          21.027609111416524
        ]
      },
      "properties": {
        "tenDoiTuon": "24-14",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 36 Ngô Sỹ Liên",
        "tuDien": "NK",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "Nguyễn Khuyến",
        "diaChiLapD": "",
        "longitude": 105.83839450793612,
        "latitude": 21.027609111416524,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1848,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83819468336952,
          21.027674204855906
        ]
      },
      "properties": {
        "tenDoiTuon": "24-15",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 36 Ngô Sỹ Liên",
        "tuDien": "NK",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "Nguyễn Khuyến",
        "diaChiLapD": "",
        "longitude": 105.83819468336952,
        "latitude": 21.027674204855906,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1849,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83799351769002,
          21.02773679467415
        ]
      },
      "properties": {
        "tenDoiTuon": "24-16",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 36 Ngô Sỹ Liên",
        "tuDien": "NK",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "Nguyễn Khuyến",
        "diaChiLapD": "",
        "longitude": 105.83799351769002,
        "latitude": 21.02773679467415,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1850,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83881427365435,
          21.027539010757593
        ]
      },
      "properties": {
        "tenDoiTuon": "24-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 34 Ngô Sỹ Liên",
        "tuDien": "NK",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Nguyễn Khuyến",
        "diaChiLapD": "",
        "longitude": 105.83881427365435,
        "latitude": 21.027539010757593,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1851,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83846960978882,
          21.02781690960334
        ]
      },
      "properties": {
        "tenDoiTuon": "24-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 34 Ngô Sỹ Liên",
        "tuDien": "NK",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 5,
        "tuDien2": "Nguyễn Khuyến",
        "diaChiLapD": "",
        "longitude": 105.83846960978882,
        "latitude": 21.02781690960334,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1852,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83858259785059,
          21.027615057452714
        ]
      },
      "properties": {
        "tenDoiTuon": "24-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 34 Ngô Sỹ Liên",
        "tuDien": "NK",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Nguyễn Khuyến",
        "diaChiLapD": "",
        "longitude": 105.83858259785059,
        "latitude": 21.027615057452714,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1853,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83814640360319,
          21.02795460703778
        ]
      },
      "properties": {
        "tenDoiTuon": "24-3-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 34 Ngô Sỹ Liên",
        "tuDien": "NK",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "Nguyễn Khuyến",
        "diaChiLapD": "",
        "longitude": 105.83814640360319,
        "latitude": 21.02795460703778,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1854,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83828051405318,
          21.027882002951948
        ]
      },
      "properties": {
        "tenDoiTuon": "24-3-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 34 Ngô Sỹ Liên",
        "tuDien": "NK",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "Nguyễn Khuyến",
        "diaChiLapD": "",
        "longitude": 105.83828051405318,
        "latitude": 21.027882002951948,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1855,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83823508414496,
          21.027729127422656
        ]
      },
      "properties": {
        "tenDoiTuon": "24-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 34 Ngô Sỹ Liên",
        "tuDien": "NK",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Nguyễn Khuyến",
        "diaChiLapD": "",
        "longitude": 105.83823508414496,
        "latitude": 21.027729127422656,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1856,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83776150661255,
          21.027848204486187
        ]
      },
      "properties": {
        "tenDoiTuon": "24-4-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 34 Ngô Sỹ Liên",
        "tuDien": "NK",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "Nguyễn Khuyến",
        "diaChiLapD": "",
        "longitude": 105.83776150661255,
        "latitude": 21.027848204486187,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1857,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8379908354823,
          21.028087297172323
        ]
      },
      "properties": {
        "tenDoiTuon": "24-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 34 Ngô Sỹ Liên",
        "tuDien": "NK",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Nguyễn Khuyến",
        "diaChiLapD": "",
        "longitude": 105.8379908354823,
        "latitude": 21.028087297172323,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1858,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8378875704393,
          21.027843197305288
        ]
      },
      "properties": {
        "tenDoiTuon": "24-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 34 Ngô Sỹ Liên",
        "tuDien": "NK",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Nguyễn Khuyến",
        "diaChiLapD": "",
        "longitude": 105.8378875704393,
        "latitude": 21.027843197305288,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1859,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83876733499191,
          21.027702996175474
        ]
      },
      "properties": {
        "tenDoiTuon": "23-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 28 Ngô Sỹ Liên",
        "tuDien": "NK",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Nguyễn Khuyến",
        "diaChiLapD": "",
        "longitude": 105.83876733499191,
        "latitude": 21.027702996175474,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1860,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83864395338189,
          21.027876995772168
        ]
      },
      "properties": {
        "tenDoiTuon": "23-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 28 Ngô Sỹ Liên",
        "tuDien": "NK",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 5,
        "tuDien2": "Nguyễn Khuyến",
        "diaChiLapD": "",
        "longitude": 105.83864395338189,
        "latitude": 21.027876995772168,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1861,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84030256436465,
          21.027776539196157
        ]
      },
      "properties": {
        "tenDoiTuon": "07",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Nguyễn Khuyến",
        "tuDien": "NK",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 9,
        "tuDien2": "Nguyễn Khuyến",
        "diaChiLapD": "",
        "longitude": 105.84030256436465,
        "latitude": 21.027776539196157,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1862,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84003501401513,
          21.027855402309182
        ]
      },
      "properties": {
        "tenDoiTuon": "06",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Nguyễn Khuyến",
        "tuDien": "NK",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 9,
        "tuDien2": "Nguyễn Khuyến",
        "diaChiLapD": "",
        "longitude": 105.84003501401513,
        "latitude": 21.027855402309182,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1863,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83976746366561,
          21.027934265380484
        ]
      },
      "properties": {
        "tenDoiTuon": "05",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Nguyễn Khuyến",
        "tuDien": "NK",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 9,
        "tuDien2": "Nguyễn Khuyến",
        "diaChiLapD": "",
        "longitude": 105.83976746366561,
        "latitude": 21.027934265380484,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1864,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83949991331609,
          21.028013128410045
        ]
      },
      "properties": {
        "tenDoiTuon": "04",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Nguyễn Khuyến",
        "tuDien": "NK",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 9,
        "tuDien2": "Nguyễn Khuyến",
        "diaChiLapD": "",
        "longitude": 105.83949991331609,
        "latitude": 21.028013128410045,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1865,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83924577401125,
          21.02808448063943
        ]
      },
      "properties": {
        "tenDoiTuon": "03",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Nguyễn Khuyến",
        "tuDien": "NK",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 9,
        "tuDien2": "Nguyễn Khuyến",
        "diaChiLapD": "",
        "longitude": 105.83924577401125,
        "latitude": 21.02808448063943,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1866,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83897084759194,
          21.028179616890732
        ]
      },
      "properties": {
        "tenDoiTuon": "02",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Nguyễn Khuyến",
        "tuDien": "NK",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 9,
        "tuDien2": "Nguyễn Khuyến",
        "diaChiLapD": "",
        "longitude": 105.83897084759194,
        "latitude": 21.028179616890732,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1867,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8386972622675,
          21.02824971724836
        ]
      },
      "properties": {
        "tenDoiTuon": "01",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Nguyễn Khuyến",
        "tuDien": "NK",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 9,
        "tuDien2": "Nguyễn Khuyến",
        "diaChiLapD": "",
        "longitude": 105.8386972622675,
        "latitude": 21.02824971724836,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1868,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83807800726085,
          21.02708648518005
        ]
      },
      "properties": {
        "tenDoiTuon": "25-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 56 Ngô Sỹ Liên",
        "tuDien": "NK",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "Nguyễn Khuyến",
        "diaChiLapD": "",
        "longitude": 105.83807800726085,
        "latitude": 21.02708648518005,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1869,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8383247704899,
          21.026891204006642
        ]
      },
      "properties": {
        "tenDoiTuon": "25-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 56 Ngô Sỹ Liên",
        "tuDien": "NK",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "Nguyễn Khuyến",
        "diaChiLapD": "",
        "longitude": 105.8383247704899,
        "latitude": 21.026891204006642,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1870,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83785270170273,
          21.02715408244935
        ]
      },
      "properties": {
        "tenDoiTuon": "25-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 56 Ngô Sỹ Liên",
        "tuDien": "NK",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "Nguyễn Khuyến",
        "diaChiLapD": "",
        "longitude": 105.83785270170273,
        "latitude": 21.02715408244935,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1871,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83761130288917,
          21.02721917608744
        ]
      },
      "properties": {
        "tenDoiTuon": "25-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 56 Ngô Sỹ Liên",
        "tuDien": "NK",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "Nguyễn Khuyến",
        "diaChiLapD": "",
        "longitude": 105.83761130288917,
        "latitude": 21.02721917608744,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1872,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83614748733574,
          21.02709399598572
        ]
      },
      "properties": {
        "tenDoiTuon": "01",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Quốc Tử Giám",
        "tuDien": "LSB",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 10,
        "tuDien2": "Lương Sử B",
        "diaChiLapD": "",
        "longitude": 105.83614748733574,
        "latitude": 21.02709399598572,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1873,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83643180149114,
          21.02693126170093
        ]
      },
      "properties": {
        "tenDoiTuon": "02",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Quốc Tử Giám",
        "tuDien": "LSB",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 10,
        "tuDien2": "Lương Sử B",
        "diaChiLapD": "",
        "longitude": 105.83643180149114,
        "latitude": 21.02693126170093,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1874,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83677467222952,
          21.02675754740718
        ]
      },
      "properties": {
        "tenDoiTuon": "03",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Quốc Tử Giám",
        "tuDien": "LSB",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 10,
        "tuDien2": "Lương Sử B",
        "diaChiLapD": "",
        "longitude": 105.83677467222952,
        "latitude": 21.02675754740718,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1875,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83703529852056,
          21.026635835929888
        ]
      },
      "properties": {
        "tenDoiTuon": "04",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Quốc Tử Giám",
        "tuDien": "LSB",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 10,
        "tuDien2": "Lương Sử B",
        "diaChiLapD": "",
        "longitude": 105.83703529852056,
        "latitude": 21.026635835929888,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1876,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83730083721278,
          21.026480611984816
        ]
      },
      "properties": {
        "tenDoiTuon": "05",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Quốc Tử Giám",
        "tuDien": "LSB",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 10,
        "tuDien2": "Lương Sử B",
        "diaChiLapD": "",
        "longitude": 105.83730083721278,
        "latitude": 21.026480611984816,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1877,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83754606775425,
          21.026360796156244
        ]
      },
      "properties": {
        "tenDoiTuon": "06",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Quốc Tử Giám",
        "tuDien": "LSB",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 10,
        "tuDien2": "Lương Sử B",
        "diaChiLapD": "",
        "longitude": 105.83754606775425,
        "latitude": 21.026360796156244,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1878,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83782578383796,
          21.026214155757557
        ]
      },
      "properties": {
        "tenDoiTuon": "07",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Quốc Tử Giám",
        "tuDien": "LSB",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 10,
        "tuDien2": "Lương Sử B",
        "diaChiLapD": "",
        "longitude": 105.83782578383796,
        "latitude": 21.026214155757557,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1879,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83811622875261,
          21.02610256589421
        ]
      },
      "properties": {
        "tenDoiTuon": "08",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Quốc Tử Giám",
        "tuDien": "LSB",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 10,
        "tuDien2": "Lương Sử B",
        "diaChiLapD": "",
        "longitude": 105.83811622875261,
        "latitude": 21.02610256589421,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1880,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82173209688804,
          21.01124817271818
        ]
      },
      "properties": {
        "tenDoiTuon": "ACD 04",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Đặng Văn Ngữ",
        "tuDien": "ACD",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "ACD",
        "diaChiLapD": "",
        "longitude": 105.82173209688804,
        "latitude": 21.01124817271818,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1881,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82051476743341,
          21.01128251657878
        ]
      },
      "properties": {
        "tenDoiTuon": "ACD 10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "",
        "tuDien": "ACD",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "ACD",
        "diaChiLapD": "",
        "longitude": 105.82051476743341,
        "latitude": 21.01128251657878,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1882,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82028270499923,
          21.011006422689952
        ]
      },
      "properties": {
        "tenDoiTuon": "ACD 11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "",
        "tuDien": "ACD",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": "",
        "tuDien2": "ACD",
        "diaChiLapD": "",
        "longitude": 105.82028270499923,
        "latitude": 21.011006422689952,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1883,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82223285748812,
          21.006075393187835
        ]
      },
      "properties": {
        "tenDoiTuon": "VH7 01",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Thái Thịnh",
        "tuDien": "VH7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "Vĩnh Hồ 7",
        "diaChiLapD": "",
        "longitude": 105.82223285748812,
        "latitude": 21.006075393187835,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1884,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82210008814202,
          21.006209354768433
        ]
      },
      "properties": {
        "tenDoiTuon": "02",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Thái Thịnh",
        "tuDien": "VH7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "Vĩnh Hồ 7",
        "diaChiLapD": "",
        "longitude": 105.82210008814202,
        "latitude": 21.006209354768433,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1885,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82192221890011,
          21.00641230728793
        ]
      },
      "properties": {
        "tenDoiTuon": "03",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Thái Thịnh",
        "tuDien": "VH7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "Vĩnh Hồ 7",
        "diaChiLapD": "",
        "longitude": 105.82192221890011,
        "latitude": 21.00641230728793,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1886,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82176738329419,
          21.006591620936813
        ]
      },
      "properties": {
        "tenDoiTuon": "04",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Thái Thịnh",
        "tuDien": "VH7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "Vĩnh Hồ 7",
        "diaChiLapD": "",
        "longitude": 105.82176738329419,
        "latitude": 21.006591620936813,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1887,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82163338378827,
          21.00674206995879
        ]
      },
      "properties": {
        "tenDoiTuon": "05",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Thái Thịnh",
        "tuDien": "VH7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "Vĩnh Hồ 7",
        "diaChiLapD": "",
        "longitude": 105.82163338378827,
        "latitude": 21.00674206995879,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1888,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82150757482182,
          21.00689402674261
        ]
      },
      "properties": {
        "tenDoiTuon": "06",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Thái Thịnh",
        "tuDien": "VH7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "Vĩnh Hồ 7",
        "diaChiLapD": "",
        "longitude": 105.82150757482182,
        "latitude": 21.00689402674261,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1889,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82128871993173,
          21.00716836593246
        ]
      },
      "properties": {
        "tenDoiTuon": "08",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Thái Thịnh",
        "tuDien": "VH7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "Vĩnh Hồ 7",
        "diaChiLapD": "",
        "longitude": 105.82128871993173,
        "latitude": 21.00716836593246,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1890,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8210515120105,
          21.00746429976513
        ]
      },
      "properties": {
        "tenDoiTuon": "09",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Thái Thịnh",
        "tuDien": "VH7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "Vĩnh Hồ 7",
        "diaChiLapD": "",
        "longitude": 105.8210515120105,
        "latitude": 21.00746429976513,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1891,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82072984490594,
          21.00784718734363
        ]
      },
      "properties": {
        "tenDoiTuon": "10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Thái Thịnh",
        "tuDien": "VH7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "Vĩnh Hồ 7",
        "diaChiLapD": "",
        "longitude": 105.82072984490594,
        "latitude": 21.00784718734363,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1892,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82044266719488,
          21.008185927463934
        ]
      },
      "properties": {
        "tenDoiTuon": "11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Thái Thịnh",
        "tuDien": "VH7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "Vĩnh Hồ 7",
        "diaChiLapD": "",
        "longitude": 105.82044266719488,
        "latitude": 21.008185927463934,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1893,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82012510888973,
          21.008573116423246
        ]
      },
      "properties": {
        "tenDoiTuon": "12",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Thái Thịnh",
        "tuDien": "VH7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "Vĩnh Hồ 7",
        "diaChiLapD": "",
        "longitude": 105.82012510888973,
        "latitude": 21.008573116423246,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1894,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81972050808271,
          21.009055018925388
        ]
      },
      "properties": {
        "tenDoiTuon": "13",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Thái Thịnh",
        "tuDien": "VH7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "Vĩnh Hồ 7",
        "diaChiLapD": "",
        "longitude": 105.81972050808271,
        "latitude": 21.009055018925388,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1895,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81948920612389,
          21.00933431986466
        ]
      },
      "properties": {
        "tenDoiTuon": "14",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Thái Thịnh",
        "tuDien": "VH7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "Vĩnh Hồ 7",
        "diaChiLapD": "",
        "longitude": 105.81948920612389,
        "latitude": 21.00933431986466,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1896,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81924744704881,
          21.009623583608082
        ]
      },
      "properties": {
        "tenDoiTuon": "15",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Thái Thịnh",
        "tuDien": "VH7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "Vĩnh Hồ 7",
        "diaChiLapD": "",
        "longitude": 105.81924744704881,
        "latitude": 21.009623583608082,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1897,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81905608599693,
          21.00985163842507
        ]
      },
      "properties": {
        "tenDoiTuon": "16",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Thái Thịnh",
        "tuDien": "VH7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "Vĩnh Hồ 7",
        "diaChiLapD": "",
        "longitude": 105.81905608599693,
        "latitude": 21.00985163842507,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1898,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8188009710383,
          21.010175564637088
        ]
      },
      "properties": {
        "tenDoiTuon": "17",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Thái Thịnh",
        "tuDien": "VH7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "Vĩnh Hồ 7",
        "diaChiLapD": "",
        "longitude": 105.8188009710383,
        "latitude": 21.010175564637088,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1899,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81858002411492,
          21.010425014178153
        ]
      },
      "properties": {
        "tenDoiTuon": "18",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Thái Thịnh",
        "tuDien": "VH7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "Vĩnh Hồ 7",
        "diaChiLapD": "",
        "longitude": 105.81858002411492,
        "latitude": 21.010425014178153,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1900,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81837349401998,
          21.01065537122765
        ]
      },
      "properties": {
        "tenDoiTuon": "19",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Thái Thịnh",
        "tuDien": "VH7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "Vĩnh Hồ 7",
        "diaChiLapD": "",
        "longitude": 105.81837349401998,
        "latitude": 21.01065537122765,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1901,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81819076848954,
          21.01088291106109
        ]
      },
      "properties": {
        "tenDoiTuon": "20",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Thái Thịnh",
        "tuDien": "VH7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "Vĩnh Hồ 7",
        "diaChiLapD": "",
        "longitude": 105.81819076848954,
        "latitude": 21.01088291106109,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1902,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81797619181627,
          21.011132046432753
        ]
      },
      "properties": {
        "tenDoiTuon": "21",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Thái Thịnh",
        "tuDien": "VH7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "Vĩnh Hồ 7",
        "diaChiLapD": "",
        "longitude": 105.81797619181627,
        "latitude": 21.011132046432753,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1903,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81775356847487,
          21.011387441059036
        ]
      },
      "properties": {
        "tenDoiTuon": "22",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Thái Thịnh",
        "tuDien": "VH7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "Vĩnh Hồ 7",
        "diaChiLapD": "",
        "longitude": 105.81775356847487,
        "latitude": 21.011387441059036,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1904,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81755910831474,
          21.011632819798457
        ]
      },
      "properties": {
        "tenDoiTuon": "23",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Thái Thịnh",
        "tuDien": "VH7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "Vĩnh Hồ 7",
        "diaChiLapD": "",
        "longitude": 105.81755910831474,
        "latitude": 21.011632819798457,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1905,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81725199538457,
          21.01176677638905
        ]
      },
      "properties": {
        "tenDoiTuon": "VH7 24",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Thái Thịnh",
        "tuDien": "VH7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "Vĩnh Hồ 7",
        "diaChiLapD": "",
        "longitude": 105.81725199538457,
        "latitude": 21.01176677638905,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1906,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8356814535205,
          21.02699322592945
        ]
      },
      "properties": {
        "tenDoiTuon": "1-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 59 Quốc Tử Giám",
        "tuDien": "LSB",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 5,
        "tuDien2": "Lương Sử B",
        "diaChiLapD": "",
        "longitude": 105.8356814535205,
        "latitude": 21.02699322592945,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1907,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83582361060267,
          21.026747872465958
        ]
      },
      "properties": {
        "tenDoiTuon": "1-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 51 Quốc Tủ Giám",
        "tuDien": "LSB",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 5,
        "tuDien2": "Lương Sử B",
        "diaChiLapD": "",
        "longitude": 105.83582361060267,
        "latitude": 21.026747872465958,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1908,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83602745848091,
          21.026770404943925
        ]
      },
      "properties": {
        "tenDoiTuon": "1-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 49 Quốc Tử Giám",
        "tuDien": "LSB",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Lương Sử B",
        "diaChiLapD": "",
        "longitude": 105.83602745848091,
        "latitude": 21.026770404943925,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1909,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83598454313906,
          21.02660266308324
        ]
      },
      "properties": {
        "tenDoiTuon": "1-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 49 Quốc Tử Giám",
        "tuDien": "LSB",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Lương Sử B",
        "diaChiLapD": "",
        "longitude": 105.83598454313906,
        "latitude": 21.02660266308324,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1910,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83582897501816,
          21.026432417420033
        ]
      },
      "properties": {
        "tenDoiTuon": "1-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 49 Quốc Tử Giám",
        "tuDien": "LSB",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Lương Sử B",
        "diaChiLapD": "",
        "longitude": 105.83582897501816,
        "latitude": 21.026432417420033,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1911,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8365414367663,
          21.026551651997746
        ]
      },
      "properties": {
        "tenDoiTuon": "3-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Lương Sử B",
        "tuDien": "LSB",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Lương Sử B",
        "diaChiLapD": "",
        "longitude": 105.8365414367663,
        "latitude": 21.026551651997746,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1912,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83659776314684,
          21.026456514706478
        ]
      },
      "properties": {
        "tenDoiTuon": "3-1-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Lương Sử B",
        "tuDien": "LSB",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "Lương Sử B",
        "diaChiLapD": "",
        "longitude": 105.83659776314684,
        "latitude": 21.026456514706478,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1913,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83615251645504,
          21.02610100113391
        ]
      },
      "properties": {
        "tenDoiTuon": "3-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Lương Sử B",
        "tuDien": "LSB",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Lương Sử B",
        "diaChiLapD": "",
        "longitude": 105.83615251645504,
        "latitude": 21.02610100113391,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1914,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83635368213453,
          21.0263363411993
        ]
      },
      "properties": {
        "tenDoiTuon": "3-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Lương Sử B",
        "tuDien": "LSB",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Lương Sử B",
        "diaChiLapD": "",
        "longitude": 105.83635368213453,
        "latitude": 21.0263363411993,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1915,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83620951341,
          21.026435233985815
        ]
      },
      "properties": {
        "tenDoiTuon": "3-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Lương Sử B",
        "tuDien": "LSB",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "Lương Sử B",
        "diaChiLapD": "",
        "longitude": 105.83620951341,
        "latitude": 21.026435233985815,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1916,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83626516923412,
          21.02550263482551
        ]
      },
      "properties": {
        "tenDoiTuon": "3-9-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Lương Sử B",
        "tuDien": "LSB",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Lương Sử B",
        "diaChiLapD": "",
        "longitude": 105.83626516923412,
        "latitude": 21.02550263482551,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1917,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83623298272323,
          21.025617801749146
        ]
      },
      "properties": {
        "tenDoiTuon": "3-9",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Lương Sử B",
        "tuDien": "LSB",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Lương Sử B",
        "diaChiLapD": "",
        "longitude": 105.83623298272323,
        "latitude": 21.025617801749146,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1918,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83609652534372,
          21.025595582051572
        ]
      },
      "properties": {
        "tenDoiTuon": "3-8-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Lương Sử B",
        "tuDien": "LSB",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "Lương Sử B",
        "diaChiLapD": "",
        "longitude": 105.83609652534372,
        "latitude": 21.025595582051572,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1919,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83620616063685,
          21.025780537467938
        ]
      },
      "properties": {
        "tenDoiTuon": "3-8",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Lương Sử B",
        "tuDien": "LSB",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Lương Sử B",
        "diaChiLapD": "",
        "longitude": 105.83620616063685,
        "latitude": 21.025780537467938,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1920,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83619811400463,
          21.026208656741982
        ]
      },
      "properties": {
        "tenDoiTuon": "3-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Lương Sử B",
        "tuDien": "LSB",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Lương Sử B",
        "diaChiLapD": "",
        "longitude": 105.83619811400463,
        "latitude": 21.026208656741982,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1921,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83609082565003,
          21.02614856990059
        ]
      },
      "properties": {
        "tenDoiTuon": "3-4-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Lương Sử B",
        "tuDien": "LSB",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Lương Sử B",
        "diaChiLapD": "",
        "longitude": 105.83609082565003,
        "latitude": 21.02614856990059,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1922,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83586015567643,
          21.02623118930146
        ]
      },
      "properties": {
        "tenDoiTuon": "3-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Lương Sử B",
        "tuDien": "LSB",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Lương Sử B",
        "diaChiLapD": "",
        "longitude": 105.83586015567643,
        "latitude": 21.02623118930146,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1923,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83569184706425,
          21.026157332563987
        ]
      },
      "properties": {
        "tenDoiTuon": "3-12",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Lương Sử B",
        "tuDien": "LSB",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Lương Sử B",
        "diaChiLapD": "",
        "longitude": 105.83569184706425,
        "latitude": 21.026157332563987,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1924,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83590038881051,
          21.026035907007504
        ]
      },
      "properties": {
        "tenDoiTuon": "3-11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Lương Sử B",
        "tuDien": "LSB",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Lương Sử B",
        "diaChiLapD": "",
        "longitude": 105.83590038881051,
        "latitude": 21.026035907007504,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1925,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83601572379732,
          21.026018381660883
        ]
      },
      "properties": {
        "tenDoiTuon": "3-10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Lương Sử B",
        "tuDien": "LSB",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Lương Sử B",
        "diaChiLapD": "",
        "longitude": 105.83601572379732,
        "latitude": 21.026018381660883,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1926,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83603986367598,
          21.02588568968452
        ]
      },
      "properties": {
        "tenDoiTuon": "3-7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Lương Sử B",
        "tuDien": "LSB",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Lương Sử B",
        "diaChiLapD": "",
        "longitude": 105.83603986367598,
        "latitude": 21.02588568968452,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1927,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83626416340846,
          21.025689468078788
        ]
      },
      "properties": {
        "tenDoiTuon": "3-9-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Lương Sử B",
        "tuDien": "LSB",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Lương Sử B",
        "diaChiLapD": "",
        "longitude": 105.83626416340846,
        "latitude": 21.025689468078788,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1928,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8361954317969,
          21.025650348907615
        ]
      },
      "properties": {
        "tenDoiTuon": "3-8-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Lương Sử B",
        "tuDien": "LSB",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Lương Sử B",
        "diaChiLapD": "",
        "longitude": 105.8361954317969,
        "latitude": 21.025650348907615,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1929,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83668728190881,
          21.026217106451032
        ]
      },
      "properties": {
        "tenDoiTuon": "4-1-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Lương Sử A",
        "tuDien": "LSB",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "Lương Sử B",
        "diaChiLapD": "",
        "longitude": 105.83668728190881,
        "latitude": 21.026217106451032,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1930,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83688710647736,
          21.026269682414554
        ]
      },
      "properties": {
        "tenDoiTuon": "4-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Lương Sử A",
        "tuDien": "LSB",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Lương Sử B",
        "diaChiLapD": "",
        "longitude": 105.83688710647736,
        "latitude": 21.026269682414554,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1931,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83683212119875,
          21.026074400171066
        ]
      },
      "properties": {
        "tenDoiTuon": "4-2-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Lương Sử A",
        "tuDien": "LSB",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "Lương Sử B",
        "diaChiLapD": "",
        "longitude": 105.83683212119875,
        "latitude": 21.026074400171066,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1932,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83677043038476,
          21.026124472566057
        ]
      },
      "properties": {
        "tenDoiTuon": "4-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Lương Sử A",
        "tuDien": "LSB",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "Lương Sử B",
        "diaChiLapD": "",
        "longitude": 105.83677043038476,
        "latitude": 21.026124472566057,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1933,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8365773113375,
          21.026101939990436
        ]
      },
      "properties": {
        "tenDoiTuon": "4-3-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Lương Sử A",
        "tuDien": "LSB",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "Lương Sử B",
        "diaChiLapD": "",
        "longitude": 105.8365773113375,
        "latitude": 21.026101939990436,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1934,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83666582423791,
          21.02601180965474
        ]
      },
      "properties": {
        "tenDoiTuon": "4-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Lương Sử A",
        "tuDien": "LSB",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Lương Sử B",
        "diaChiLapD": "",
        "longitude": 105.83666582423791,
        "latitude": 21.02601180965474,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1935,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83651830274023,
          21.025969248088465
        ]
      },
      "properties": {
        "tenDoiTuon": "4-4-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Lương Sử A",
        "tuDien": "LSB",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "Lương Sử B",
        "diaChiLapD": "",
        "longitude": 105.83651830274023,
        "latitude": 21.025969248088465,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1936,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83657194692202,
          21.025871606802276
        ]
      },
      "properties": {
        "tenDoiTuon": "4-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Lương Sử A",
        "tuDien": "LSB",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Lương Sử B",
        "diaChiLapD": "",
        "longitude": 105.83657194692202,
        "latitude": 21.025871606802276,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1937,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83637882787477,
          21.025969248088465
        ]
      },
      "properties": {
        "tenDoiTuon": "4-4-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Lương Sử A",
        "tuDien": "LSB",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "Lương Sử B",
        "diaChiLapD": "",
        "longitude": 105.83637882787477,
        "latitude": 21.025969248088465,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1938,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83669264632431,
          21.025736411068984
        ]
      },
      "properties": {
        "tenDoiTuon": "4-4-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Lương Sử A",
        "tuDien": "LSB",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "Lương Sử B",
        "diaChiLapD": "",
        "longitude": 105.83669264632431,
        "latitude": 21.025736411068984,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1939,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83655317145885,
          21.02578147632731
        ]
      },
      "properties": {
        "tenDoiTuon": "4-4-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Lương Sử A",
        "tuDien": "LSB",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "Lương Sử B",
        "diaChiLapD": "",
        "longitude": 105.83655317145885,
        "latitude": 21.02578147632731,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1940,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8365960868007,
          21.025631258747925
        ]
      },
      "properties": {
        "tenDoiTuon": "4-5-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Lương Sử A",
        "tuDien": "LSB",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "Lương Sử B",
        "diaChiLapD": "",
        "longitude": 105.8365960868007,
        "latitude": 21.025631258747925,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1941,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83650757390028,
          21.02558368981616
        ]
      },
      "properties": {
        "tenDoiTuon": "4-5-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Lương Sử A",
        "tuDien": "LSB",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "Lương Sử B",
        "diaChiLapD": "",
        "longitude": 105.83650757390028,
        "latitude": 21.02558368981616,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1942,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83636273461931,
          21.02561373335372
        ]
      },
      "properties": {
        "tenDoiTuon": "4-5-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Lương Sử A",
        "tuDien": "LSB",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "Lương Sử B",
        "diaChiLapD": "",
        "longitude": 105.83636273461931,
        "latitude": 21.02561373335372,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1943,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83645124751077,
          21.02571638206134
        ]
      },
      "properties": {
        "tenDoiTuon": "4-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Lương Sử A",
        "tuDien": "LSB",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Lương Sử B",
        "diaChiLapD": "",
        "longitude": 105.83645124751077,
        "latitude": 21.02571638206134,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1944,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83716773259744,
          21.026132296376236
        ]
      },
      "properties": {
        "tenDoiTuon": "5-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 23+25 Quốc Tử Giám",
        "tuDien": "LSB",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 5,
        "tuDien2": "Lương Sử B",
        "diaChiLapD": "",
        "longitude": 105.83716773259744,
        "latitude": 21.026132296376236,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1945,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83719991910831,
          21.025745486712594
        ]
      },
      "properties": {
        "tenDoiTuon": "5-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 23+25 Quốc Tử Giám",
        "tuDien": "LSB",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Lương Sử B",
        "diaChiLapD": "",
        "longitude": 105.83719991910831,
        "latitude": 21.025745486712594,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1946,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83724551665789,
          21.02594702844245
        ]
      },
      "properties": {
        "tenDoiTuon": "5-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 23+25 Quốc Tử Giám",
        "tuDien": "LSB",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Lương Sử B",
        "diaChiLapD": "",
        "longitude": 105.83724551665789,
        "latitude": 21.02594702844245,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1947,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83736755716406,
          21.02623118930063
        ]
      },
      "properties": {
        "tenDoiTuon": "5-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 23+25 Quốc Tử Giám",
        "tuDien": "LSB",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Lương Sử B",
        "diaChiLapD": "",
        "longitude": 105.83736755716406,
        "latitude": 21.02623118930063,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1948,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83696120250248,
          21.025824350899686
        ]
      },
      "properties": {
        "tenDoiTuon": "5-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 23+25 Quốc Tử Giám",
        "tuDien": "LSB",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Lương Sử B",
        "diaChiLapD": "",
        "longitude": 105.83696120250248,
        "latitude": 21.025824350899686,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1949,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8373823093156,
          21.026044669679198
        ]
      },
      "properties": {
        "tenDoiTuon": "5-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 17 Quốc Tử Giám",
        "tuDien": "LSB",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 5,
        "tuDien2": "Lương Sử B",
        "diaChiLapD": "",
        "longitude": 105.8373823093156,
        "latitude": 21.026044669679198,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1950,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83807700145316,
          21.025891948742213
        ]
      },
      "properties": {
        "tenDoiTuon": "09",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Lương Sử C+ Linh Quang",
        "tuDien": "LSB",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Lương Sử B",
        "diaChiLapD": "",
        "longitude": 105.83807700145316,
        "latitude": 21.025891948742213,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1951,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83725222217726,
          21.02544379951431
        ]
      },
      "properties": {
        "tenDoiTuon": "13",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Lương Sử C+ Linh Quang",
        "tuDien": "LSB",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Lương Sử B",
        "diaChiLapD": "",
        "longitude": 105.83725222217726,
        "latitude": 21.02544379951431,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1952,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83747752773537,
          21.02544004406827
        ]
      },
      "properties": {
        "tenDoiTuon": "12",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Lương Sử C+ Linh Quang",
        "tuDien": "LSB",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Lương Sử B",
        "diaChiLapD": "",
        "longitude": 105.83747752773537,
        "latitude": 21.02544004406827,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1953,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8376451658001,
          21.02529483341149
        ]
      },
      "properties": {
        "tenDoiTuon": "11-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Lương Sử C+ Linh Quang",
        "tuDien": "LSB",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "Lương Sử B",
        "diaChiLapD": "",
        "longitude": 105.8376451658001,
        "latitude": 21.02529483341149,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1954,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83777257073075,
          21.025721702266413
        ]
      },
      "properties": {
        "tenDoiTuon": "10-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Lương Sử C+ Linh Quang",
        "tuDien": "LSB",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "Lương Sử B",
        "diaChiLapD": "",
        "longitude": 105.83777257073075,
        "latitude": 21.025721702266413,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1955,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83792813885162,
          21.025646593465932
        ]
      },
      "properties": {
        "tenDoiTuon": "10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Lương Sử C+ Linh Quang",
        "tuDien": "LSB",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Lương Sử B",
        "diaChiLapD": "",
        "longitude": 105.83792813885162,
        "latitude": 21.025646593465932,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1956,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83774306642759,
          21.025650348906787
        ]
      },
      "properties": {
        "tenDoiTuon": "11-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Lương Sử C+ Linh Quang",
        "tuDien": "LSB",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Lương Sử B",
        "diaChiLapD": "",
        "longitude": 105.83774306642759,
        "latitude": 21.025650348906787,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1957,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83761163818536,
          21.025725457705363
        ]
      },
      "properties": {
        "tenDoiTuon": "11-2-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Lương Sử C+ Linh Quang",
        "tuDien": "LSB",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "Lương Sử B",
        "diaChiLapD": "",
        "longitude": 105.83761163818536,
        "latitude": 21.025725457705363,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1958,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83768003451871,
          21.025581499141083
        ]
      },
      "properties": {
        "tenDoiTuon": "11-2-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Lương Sử C+ Linh Quang",
        "tuDien": "LSB",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 5,
        "tuDien2": "Lương Sử B",
        "diaChiLapD": "",
        "longitude": 105.83768003451871,
        "latitude": 21.025581499141083,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1959,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83777491764228,
          21.025446616097916
        ]
      },
      "properties": {
        "tenDoiTuon": "11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Lương Sử C+ Linh Quang",
        "tuDien": "LSB",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Lương Sử B",
        "diaChiLapD": "",
        "longitude": 105.83777491764228,
        "latitude": 21.025446616097916,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1960,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83750703203849,
          21.025175910784796
        ]
      },
      "properties": {
        "tenDoiTuon": "11-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Lương Sử C+ Linh Quang",
        "tuDien": "LSB",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Lương Sử B",
        "diaChiLapD": "",
        "longitude": 105.83750703203849,
        "latitude": 21.025175910784796,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1961,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83739169705169,
          21.025009418948997
        ]
      },
      "properties": {
        "tenDoiTuon": "11-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Lương Sử C+ Linh Quang",
        "tuDien": "LSB",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "Lương Sử B",
        "diaChiLapD": "",
        "longitude": 105.83739169705169,
        "latitude": 21.025009418948997,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1962,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83719857799545,
          21.025190932595834
        ]
      },
      "properties": {
        "tenDoiTuon": "14",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Lương Sử C+ Linh Quang",
        "tuDien": "LSB",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Lương Sử B",
        "diaChiLapD": "",
        "longitude": 105.83719857799545,
        "latitude": 21.025190932595834,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1963,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83707251417768,
          21.024923043411864
        ]
      },
      "properties": {
        "tenDoiTuon": "16",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Lương Sử C+ Linh Quang",
        "tuDien": "LSB",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Lương Sử B",
        "diaChiLapD": "",
        "longitude": 105.83707251417768,
        "latitude": 21.024923043411864,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1964,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83651059138624,
          21.024979375289607
        ]
      },
      "properties": {
        "tenDoiTuon": "23",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Lương Sử C+ Linh Quang",
        "tuDien": "LSB",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Lương Sử B",
        "diaChiLapD": "",
        "longitude": 105.83651059138624,
        "latitude": 21.024979375289607,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1965,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83688475954591,
          21.024861704232578
        ]
      },
      "properties": {
        "tenDoiTuon": "22",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Lương Sử C+ Linh Quang",
        "tuDien": "LSB",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Lương Sử B",
        "diaChiLapD": "",
        "longitude": 105.83688475954591,
        "latitude": 21.024861704232578,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1966,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83642207848582,
          21.024692708402075
        ]
      },
      "properties": {
        "tenDoiTuon": "24",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Lương Sử C+ Linh Quang",
        "tuDien": "LSB",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Lương Sử B",
        "diaChiLapD": "",
        "longitude": 105.83642207848582,
        "latitude": 21.024692708402075,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1967,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83633222449055,
          21.02443232926676
        ]
      },
      "properties": {
        "tenDoiTuon": "25",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Lương Sử C+ Linh Quang",
        "tuDien": "LSB",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Lương Sử B",
        "diaChiLapD": "",
        "longitude": 105.83633222449055,
        "latitude": 21.02443232926676,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1968,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83727367984817,
          21.025645341652023
        ]
      },
      "properties": {
        "tenDoiTuon": "13-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Lương Sử C+ Linh Quang",
        "tuDien": "LSB",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "Lương Sử B",
        "diaChiLapD": "",
        "longitude": 105.83727367984817,
        "latitude": 21.025645341652023,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1969,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83702959883584,
          21.025690406937947
        ]
      },
      "properties": {
        "tenDoiTuon": "13-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Lương Sử C+ Linh Quang",
        "tuDien": "LSB",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "Lương Sử B",
        "diaChiLapD": "",
        "longitude": 105.83702959883584,
        "latitude": 21.025690406937947,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1970,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.837120793935,
          21.025133348979804
        ]
      },
      "properties": {
        "tenDoiTuon": "15",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Lương Sử C+ Linh Quang",
        "tuDien": "LSB",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Lương Sử B",
        "diaChiLapD": "",
        "longitude": 105.837120793935,
        "latitude": 21.025133348979804,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1971,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83751239645399,
          21.025645341652023
        ]
      },
      "properties": {
        "tenDoiTuon": "11-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Lương Sử C+ Linh Quang",
        "tuDien": "LSB",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Lương Sử B",
        "diaChiLapD": "",
        "longitude": 105.83751239645399,
        "latitude": 21.025645341652023,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1972,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83711811172726,
          21.025495123935453
        ]
      },
      "properties": {
        "tenDoiTuon": "13-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Lương Sử C+ Linh Quang",
        "tuDien": "LSB",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Lương Sử B",
        "diaChiLapD": "",
        "longitude": 105.83711811172726,
        "latitude": 21.025495123935453,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1973,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83683379757186,
          21.025585254583568
        ]
      },
      "properties": {
        "tenDoiTuon": "13-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Lương Sử C+ Linh Quang",
        "tuDien": "LSB",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Lương Sử B",
        "diaChiLapD": "",
        "longitude": 105.83683379757186,
        "latitude": 21.025585254583568,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1974,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83701618778815,
          21.02538746781205
        ]
      },
      "properties": {
        "tenDoiTuon": "14-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Lương Sử C+ Linh Quang",
        "tuDien": "LSB",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "Lương Sử B",
        "diaChiLapD": "",
        "longitude": 105.83701618778815,
        "latitude": 21.02538746781205,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1975,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8371244819684,
          21.024852628534337
        ]
      },
      "properties": {
        "tenDoiTuon": "17",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Lương Sử C+ Linh Quang",
        "tuDien": "LSB",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Lương Sử B",
        "diaChiLapD": "",
        "longitude": 105.8371244819684,
        "latitude": 21.024852628534337,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1976,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83683480339751,
          21.02477251201188
        ]
      },
      "properties": {
        "tenDoiTuon": "26",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Lương Sử C+ Linh Quang",
        "tuDien": "LSB",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Lương Sử B",
        "diaChiLapD": "",
        "longitude": 105.83683480339751,
        "latitude": 21.02477251201188,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1977,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83675433712932,
          21.024577227806947
        ]
      },
      "properties": {
        "tenDoiTuon": "27",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Lương Sử C+ Linh Quang",
        "tuDien": "LSB",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Lương Sử B",
        "diaChiLapD": "",
        "longitude": 105.83675433712932,
        "latitude": 21.024577227806947,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1978,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83672080949661,
          21.024431390398046
        ]
      },
      "properties": {
        "tenDoiTuon": "28",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Lương Sử C+ Linh Quang",
        "tuDien": "LSB",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Lương Sử B",
        "diaChiLapD": "",
        "longitude": 105.83672080949661,
        "latitude": 21.024431390398046,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1979,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.836778477008,
          21.024264271817536
        ]
      },
      "properties": {
        "tenDoiTuon": "30",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Lương Sử C+ Linh Quang",
        "tuDien": "LSB",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Lương Sử B",
        "diaChiLapD": "",
        "longitude": 105.836778477008,
        "latitude": 21.024264271817536,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1980,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83652634935449,
          21.0243869506442
        ]
      },
      "properties": {
        "tenDoiTuon": "29",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Lương Sử C+ Linh Quang",
        "tuDien": "LSB",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Lương Sử B",
        "diaChiLapD": "",
        "longitude": 105.83652634935449,
        "latitude": 21.0243869506442,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1981,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83703328686026,
          21.024507125722963
        ]
      },
      "properties": {
        "tenDoiTuon": "31",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Lương Sử C+ Linh Quang",
        "tuDien": "LSB",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Lương Sử B",
        "diaChiLapD": "",
        "longitude": 105.83703328686026,
        "latitude": 21.024507125722963,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1982,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83714593963931,
          21.024659848078752
        ]
      },
      "properties": {
        "tenDoiTuon": "18",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Lương Sử C+ Linh Quang",
        "tuDien": "LSB",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Lương Sử B",
        "diaChiLapD": "",
        "longitude": 105.83714593963931,
        "latitude": 21.024659848078752,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1983,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83733637647885,
          21.024294315621045
        ]
      },
      "properties": {
        "tenDoiTuon": "19",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Lương Sử C+ Linh Quang",
        "tuDien": "LSB",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Lương Sử B",
        "diaChiLapD": "",
        "longitude": 105.83733637647885,
        "latitude": 21.024294315621045,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1984,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83747316913656,
          21.02424924991394
        ]
      },
      "properties": {
        "tenDoiTuon": "21",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Lương Sử C+ Linh Quang",
        "tuDien": "LSB",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Lương Sử B",
        "diaChiLapD": "",
        "longitude": 105.83747316913656,
        "latitude": 21.02424924991394,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1985,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8373202832234,
          21.024136585585328
        ]
      },
      "properties": {
        "tenDoiTuon": "20",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Lương Sử C+ Linh Quang",
        "tuDien": "LSB",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "Lương Sử B",
        "diaChiLapD": "",
        "longitude": 105.8373202832234,
        "latitude": 21.024136585585328,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1986,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83729614334473,
          21.024552191352104
        ]
      },
      "properties": {
        "tenDoiTuon": "18-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Lương Sử C+ Linh Quang",
        "tuDien": "LSB",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "Lương Sử B",
        "diaChiLapD": "",
        "longitude": 105.83729614334473,
        "latitude": 21.024552191352104,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1987,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8370091469816,
          21.024662351723116
        ]
      },
      "properties": {
        "tenDoiTuon": "32",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Lương Sử C+ Linh Quang",
        "tuDien": "LSB",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "Lương Sử B",
        "diaChiLapD": "",
        "longitude": 105.8370091469816,
        "latitude": 21.024662351723116,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1988,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8372183592843,
          21.024882672219245
        ]
      },
      "properties": {
        "tenDoiTuon": "16-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Lương Sử C+ Linh Quang",
        "tuDien": "LSB",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "Lương Sử B",
        "diaChiLapD": "",
        "longitude": 105.8372183592843,
        "latitude": 21.024882672219245,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1989,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84119549883916,
          21.018897680493485
        ]
      },
      "properties": {
        "tenDoiTuon": "01",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 202,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Khâm Thiên",
        "tuDien": "KTG",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên Ga",
        "diaChiLapD": "",
        "longitude": 105.84119549883916,
        "latitude": 21.018897680493485,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1990,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84092349576235,
          21.018907302282443
        ]
      },
      "properties": {
        "tenDoiTuon": "02",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 202,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Khâm Thiên",
        "tuDien": "KTG",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên Ga",
        "diaChiLapD": "",
        "longitude": 105.84092349576235,
        "latitude": 21.018907302282443,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1991,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84074354661902,
          21.018918962287593
        ]
      },
      "properties": {
        "tenDoiTuon": "03",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 202,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Khâm Thiên",
        "tuDien": "KTG",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên Ga",
        "diaChiLapD": "",
        "longitude": 105.84074354661902,
        "latitude": 21.018918962287593,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1992,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84049678338998,
          21.018930229118236
        ]
      },
      "properties": {
        "tenDoiTuon": "04",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 202,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Khâm Thiên",
        "tuDien": "KTG",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên Ga",
        "diaChiLapD": "",
        "longitude": 105.84049678338998,
        "latitude": 21.018930229118236,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1993,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8402071048191,
          21.0189477552981
        ]
      },
      "properties": {
        "tenDoiTuon": "05",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 202,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Khâm Thiên",
        "tuDien": "KTG",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên Ga",
        "diaChiLapD": "",
        "longitude": 105.8402071048191,
        "latitude": 21.0189477552981,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1994,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83954325808855,
          21.018995326347223
        ]
      },
      "properties": {
        "tenDoiTuon": "06",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 202,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Khâm Thiên",
        "tuDien": "KTG",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên Ga",
        "diaChiLapD": "",
        "longitude": 105.83954325808855,
        "latitude": 21.018995326347223,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1995,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83923940375213,
          21.019036557830166
        ]
      },
      "properties": {
        "tenDoiTuon": "07",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 202,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Khâm Thiên",
        "tuDien": "KTG",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên Ga",
        "diaChiLapD": "",
        "longitude": 105.83923940375213,
        "latitude": 21.019036557830166,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1996,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83894167855802,
          21.01904782464941
        ]
      },
      "properties": {
        "tenDoiTuon": "08",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 202,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Khâm Thiên",
        "tuDien": "KTG",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên Ga",
        "diaChiLapD": "",
        "longitude": 105.83894167855802,
        "latitude": 21.01904782464941,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1997,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83867345765806,
          21.0190753657668
        ]
      },
      "properties": {
        "tenDoiTuon": "08+1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 202,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Khâm Thiên",
        "tuDien": "KTG",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên Ga",
        "diaChiLapD": "",
        "longitude": 105.83867345765806,
        "latitude": 21.0190753657668,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1998,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83981147898854,
          21.018982807651646
        ]
      },
      "properties": {
        "tenDoiTuon": "05+1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 202,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Khâm Thiên",
        "tuDien": "KTG",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên Ga",
        "diaChiLapD": "",
        "longitude": 105.83981147898854,
        "latitude": 21.018982807651646,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 1999,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83681468682148,
          21.025438479298476
        ]
      },
      "properties": {
        "tenDoiTuon": "14-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Lương Sử C+ Linh Quang",
        "tuDien": "LSB",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Lương Sử B",
        "diaChiLapD": "",
        "longitude": 105.83681468682148,
        "latitude": 21.025438479298476,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2000,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83728675560866,
          21.025181856918444
        ]
      },
      "properties": {
        "tenDoiTuon": "14-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Lương Sử C+ Linh Quang",
        "tuDien": "LSB",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Lương Sử B",
        "diaChiLapD": "",
        "longitude": 105.83728675560866,
        "latitude": 21.025181856918444,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2001,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83642576651025,
          21.02532393814419
        ]
      },
      "properties": {
        "tenDoiTuon": "15-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Lương Sử C+ Linh Quang",
        "tuDien": "LSB",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "Lương Sử B",
        "diaChiLapD": "",
        "longitude": 105.83642576651025,
        "latitude": 21.02532393814419,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2002,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8367972524532,
          21.0252087709935
        ]
      },
      "properties": {
        "tenDoiTuon": "15-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Lương Sử C+ Linh Quang",
        "tuDien": "LSB",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "Lương Sử B",
        "diaChiLapD": "",
        "longitude": 105.8367972524532,
        "latitude": 21.0252087709935,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2003,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8392890246223,
          21.01870230838995
        ]
      },
      "properties": {
        "tenDoiTuon": "08+1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 202,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Khâm Thiên",
        "tuDien": "KTG",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên Ga",
        "diaChiLapD": "",
        "longitude": 105.8392890246223,
        "latitude": 21.01870230838995,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2004,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8392890246223,
          21.018702308389937
        ]
      },
      "properties": {
        "tenDoiTuon": "26",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 202,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Khâm Thiên",
        "tuDien": "KTG",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên Ga",
        "diaChiLapD": "",
        "longitude": 105.8392890246223,
        "latitude": 21.018702308389937,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2005,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8392890246223,
          21.01870230838995
        ]
      },
      "properties": {
        "tenDoiTuon": "25",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 202,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Khâm Thiên",
        "tuDien": "KTG",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên Ga",
        "diaChiLapD": "",
        "longitude": 105.8392890246223,
        "latitude": 21.01870230838995,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2006,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8392890246223,
          21.01870230838995
        ]
      },
      "properties": {
        "tenDoiTuon": "08+1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 202,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Khâm Thiên",
        "tuDien": "KTG",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên Ga",
        "diaChiLapD": "",
        "longitude": 105.8392890246223,
        "latitude": 21.01870230838995,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2007,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8392890246223,
          21.01870230838995
        ]
      },
      "properties": {
        "tenDoiTuon": "25",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 202,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Khâm Thiên",
        "tuDien": "KTG",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên Ga",
        "diaChiLapD": "",
        "longitude": 105.8392890246223,
        "latitude": 21.01870230838995,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2008,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83885718900518,
          21.01772960069353
        ]
      },
      "properties": {
        "tenDoiTuon": "30",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 202,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Khâm Thiên",
        "tuDien": "KTG",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên Ga",
        "diaChiLapD": "",
        "longitude": 105.83885718900518,
        "latitude": 21.01772960069353,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2009,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8389443608017,
          21.01789860441677
        ]
      },
      "properties": {
        "tenDoiTuon": "29",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Khâm Thiên",
        "tuDien": "KTG",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên Ga",
        "diaChiLapD": "",
        "longitude": 105.8389443608017,
        "latitude": 21.01789860441677,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2010,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83905567246791,
          21.018111423647724
        ]
      },
      "properties": {
        "tenDoiTuon": "28",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 202,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Khâm Thiên",
        "tuDien": "KTG",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên Ga",
        "diaChiLapD": "",
        "longitude": 105.83905567246791,
        "latitude": 21.018111423647724,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2011,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83907847119778,
          21.018284182564614
        ]
      },
      "properties": {
        "tenDoiTuon": "27",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 202,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Khâm Thiên",
        "tuDien": "KTG",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên Ga",
        "diaChiLapD": "",
        "longitude": 105.83907847119778,
        "latitude": 21.018284182564614,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2012,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83919380618458,
          21.018499504992313
        ]
      },
      "properties": {
        "tenDoiTuon": "26",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 202,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Khâm Thiên",
        "tuDien": "KTG",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên Ga",
        "diaChiLapD": "",
        "longitude": 105.83919380618458,
        "latitude": 21.018499504992313,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2013,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82194720221986,
          21.006127976252653
        ]
      },
      "properties": {
        "tenDoiTuon": "VH7 2-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 15",
        "tuDien": "VH7",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 7",
        "diaChiLapD": "",
        "longitude": 105.82194720221986,
        "latitude": 21.006127976252653,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2014,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82217830506258,
          21.011226051347883
        ]
      },
      "properties": {
        "tenDoiTuon": "ACD 4-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "",
        "tuDien": "ACD",
        "chuDauTu": "",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "ACD",
        "diaChiLapD": "",
        "longitude": 105.82217830506258,
        "latitude": 21.011226051347883,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2015,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82206737301749,
          21.01145974950511
        ]
      },
      "properties": {
        "tenDoiTuon": "ACD 4-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "",
        "tuDien": "ACD",
        "chuDauTu": "",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "ACD",
        "diaChiLapD": "",
        "longitude": 105.82206737301749,
        "latitude": 21.01145974950511,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2016,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82222929748262,
          21.01157804780474
        ]
      },
      "properties": {
        "tenDoiTuon": "ACD 4-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "",
        "tuDien": "ACD",
        "chuDauTu": "",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "ACD",
        "diaChiLapD": "",
        "longitude": 105.82222929748262,
        "latitude": 21.01157804780474,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2017,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82244307261938,
          21.01154568352903
        ]
      },
      "properties": {
        "tenDoiTuon": "ACD 4-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "",
        "tuDien": "ACD",
        "chuDauTu": "",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "ACD",
        "diaChiLapD": "",
        "longitude": 105.82244307261938,
        "latitude": 21.01154568352903,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2018,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82267575721443,
          21.01187099955225
        ]
      },
      "properties": {
        "tenDoiTuon": "ACD 26",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "",
        "tuDien": "ACD",
        "chuDauTu": "",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "ACD",
        "diaChiLapD": "",
        "longitude": 105.82267575721443,
        "latitude": 21.01187099955225,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2019,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82281523207992,
          21.012041261842402
        ]
      },
      "properties": {
        "tenDoiTuon": "ACD 26-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "",
        "tuDien": "ACD",
        "chuDauTu": "",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "ACD",
        "diaChiLapD": "",
        "longitude": 105.82281523207992,
        "latitude": 21.012041261842402,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2020,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82296859525002,
          21.011959269841046
        ]
      },
      "properties": {
        "tenDoiTuon": "ACD 27-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "",
        "tuDien": "ACD",
        "chuDauTu": "",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "ACD",
        "diaChiLapD": "",
        "longitude": 105.82296859525002,
        "latitude": 21.011959269841046,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2021,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82293104432365,
          21.011866627098176
        ]
      },
      "properties": {
        "tenDoiTuon": "ACD 27",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "",
        "tuDien": "ACD",
        "chuDauTu": "",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "ACD",
        "diaChiLapD": "",
        "longitude": 105.82293104432365,
        "latitude": 21.011866627098176,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2022,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82326900266084,
          21.011816549915153
        ]
      },
      "properties": {
        "tenDoiTuon": "ACD 29-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "",
        "tuDien": "ACD",
        "chuDauTu": "",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "ACD",
        "diaChiLapD": "",
        "longitude": 105.82326900266084,
        "latitude": 21.011816549915153,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2023,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8231067290116,
          21.011686349162634
        ]
      },
      "properties": {
        "tenDoiTuon": "ACD 29",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "",
        "tuDien": "ACD",
        "chuDauTu": "",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "ACD",
        "diaChiLapD": "",
        "longitude": 105.8231067290116,
        "latitude": 21.011686349162634,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2024,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82301955722404,
          21.011772732366556
        ]
      },
      "properties": {
        "tenDoiTuon": "ACD 28",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "",
        "tuDien": "ACD",
        "chuDauTu": "",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "ACD",
        "diaChiLapD": "",
        "longitude": 105.82301955722404,
        "latitude": 21.011772732366556,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2025,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8231301757412,
          21.01187519551132
        ]
      },
      "properties": {
        "tenDoiTuon": "ACD 28-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "",
        "tuDien": "ACD",
        "chuDauTu": "",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "ACD",
        "diaChiLapD": "",
        "longitude": 105.8231301757412,
        "latitude": 21.01187519551132,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2026,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82151012147452,
          21.011491165712027
        ]
      },
      "properties": {
        "tenDoiTuon": "ACD 05",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "",
        "tuDien": "ACD",
        "chuDauTu": "",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "ACD",
        "diaChiLapD": "",
        "longitude": 105.82151012147452,
        "latitude": 21.011491165712027,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2027,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82124190057455,
          21.01176659060841
        ]
      },
      "properties": {
        "tenDoiTuon": "ACD 06",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "",
        "tuDien": "ACD",
        "chuDauTu": "",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "ACD",
        "diaChiLapD": "",
        "longitude": 105.82124190057455,
        "latitude": 21.01176659060841,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2028,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82106219257498,
          21.011889279717156
        ]
      },
      "properties": {
        "tenDoiTuon": "ACD 07",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "",
        "tuDien": "ACD",
        "chuDauTu": "",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "ACD",
        "diaChiLapD": "",
        "longitude": 105.82106219257498,
        "latitude": 21.011889279717156,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2029,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82087980235869,
          21.01167144388341
        ]
      },
      "properties": {
        "tenDoiTuon": "ACD 08",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "",
        "tuDien": "ACD",
        "chuDauTu": "",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "ACD",
        "diaChiLapD": "",
        "longitude": 105.82087980235869,
        "latitude": 21.01167144388341,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2030,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82068131889596,
          21.0114511038671
        ]
      },
      "properties": {
        "tenDoiTuon": "ACD 09",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "",
        "tuDien": "ACD",
        "chuDauTu": "",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "ACD",
        "diaChiLapD": "",
        "longitude": 105.82068131889596,
        "latitude": 21.0114511038671,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2031,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81997285537851,
          21.010661865092434
        ]
      },
      "properties": {
        "tenDoiTuon": "ACD 12",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "",
        "tuDien": "ACD",
        "chuDauTu": "",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "ACD",
        "diaChiLapD": "",
        "longitude": 105.81997285537851,
        "latitude": 21.010661865092434,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2032,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82011769466844,
          21.010576733184852
        ]
      },
      "properties": {
        "tenDoiTuon": "ACD 12-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "",
        "tuDien": "ACD",
        "chuDauTu": "",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "ACD",
        "diaChiLapD": "",
        "longitude": 105.82011769466844,
        "latitude": 21.010576733184852,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2033,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82027058058159,
          21.010456546879443
        ]
      },
      "properties": {
        "tenDoiTuon": "ACD 12-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "",
        "tuDien": "ACD",
        "chuDauTu": "",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "ACD",
        "diaChiLapD": "",
        "longitude": 105.82027058058159,
        "latitude": 21.010456546879443,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2034,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81985483818393,
          21.010551694378503
        ]
      },
      "properties": {
        "tenDoiTuon": "ACD 13",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "",
        "tuDien": "ACD",
        "chuDauTu": "",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "ACD",
        "diaChiLapD": "",
        "longitude": 105.81985483818393,
        "latitude": 21.010551694378503,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2035,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8196348970413,
          21.010661865092434
        ]
      },
      "properties": {
        "tenDoiTuon": "ACD 14",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "",
        "tuDien": "ACD",
        "chuDauTu": "",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "ACD",
        "diaChiLapD": "",
        "longitude": 105.8196348970413,
        "latitude": 21.010661865092434,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2036,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81955174856536,
          21.011072500670597
        ]
      },
      "properties": {
        "tenDoiTuon": "ACD 14-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "",
        "tuDien": "ACD",
        "chuDauTu": "",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "ACD",
        "diaChiLapD": "",
        "longitude": 105.81955174856536,
        "latitude": 21.011072500670597,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2037,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8196080749549,
          21.011212717439054
        ]
      },
      "properties": {
        "tenDoiTuon": "ACD 14-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "",
        "tuDien": "ACD",
        "chuDauTu": "",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "ACD",
        "diaChiLapD": "",
        "longitude": 105.8196080749549,
        "latitude": 21.011212717439054,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2038,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81944982461727,
          21.01128282577303
        ]
      },
      "properties": {
        "tenDoiTuon": "ACD 14-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "",
        "tuDien": "ACD",
        "chuDauTu": "",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "ACD",
        "diaChiLapD": "",
        "longitude": 105.81944982461727,
        "latitude": 21.01128282577303,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2039,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81953833751767,
          21.010844648141596
        ]
      },
      "properties": {
        "tenDoiTuon": "ACD 14-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "",
        "tuDien": "ACD",
        "chuDauTu": "",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "ACD",
        "diaChiLapD": "",
        "longitude": 105.81953833751767,
        "latitude": 21.010844648141596,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2040,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81939618043549,
          21.010967338008612
        ]
      },
      "properties": {
        "tenDoiTuon": "ACD 14-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "",
        "tuDien": "ACD",
        "chuDauTu": "",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "ACD",
        "diaChiLapD": "",
        "longitude": 105.81939618043549,
        "latitude": 21.010967338008612,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2041,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81944714240953,
          21.010554198259754
        ]
      },
      "properties": {
        "tenDoiTuon": "ACD 15",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "",
        "tuDien": "ACD",
        "chuDauTu": "",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "ACD",
        "diaChiLapD": "",
        "longitude": 105.81944714240953,
        "latitude": 21.010554198259754,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2042,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81928889208088,
          21.01040897310675
        ]
      },
      "properties": {
        "tenDoiTuon": "ACD 16",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "",
        "tuDien": "ACD",
        "chuDauTu": "",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "ACD",
        "diaChiLapD": "",
        "longitude": 105.81928889208088,
        "latitude": 21.01040897310675,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2043,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81900726013323,
          21.01024371672648
        ]
      },
      "properties": {
        "tenDoiTuon": "ACD 17",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "",
        "tuDien": "ACD",
        "chuDauTu": "",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "ACD",
        "diaChiLapD": "",
        "longitude": 105.81900726013323,
        "latitude": 21.01024371672648,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2044,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81888119630646,
          21.010386438156306
        ]
      },
      "properties": {
        "tenDoiTuon": "ACD 17-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "",
        "tuDien": "ACD",
        "chuDauTu": "",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "ACD",
        "diaChiLapD": "",
        "longitude": 105.81888119630646,
        "latitude": 21.010386438156306,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2045,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81875513248869,
          21.01050912840017
        ]
      },
      "properties": {
        "tenDoiTuon": "ACD 17-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "",
        "tuDien": "ACD",
        "chuDauTu": "",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "ACD",
        "diaChiLapD": "",
        "longitude": 105.81875513248869,
        "latitude": 21.01050912840017,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2046,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81866393738055,
          21.010596764226044
        ]
      },
      "properties": {
        "tenDoiTuon": "ACD 17-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "",
        "tuDien": "ACD",
        "chuDauTu": "",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "ACD",
        "diaChiLapD": "",
        "longitude": 105.81866393738055,
        "latitude": 21.010596764226044,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2047,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81860224657554,
          21.010676888365655
        ]
      },
      "properties": {
        "tenDoiTuon": "ACD 17-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "",
        "tuDien": "ACD",
        "chuDauTu": "",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "ACD",
        "diaChiLapD": "",
        "longitude": 105.81860224657554,
        "latitude": 21.010676888365655,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2048,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81880073003829,
          21.01079206673998
        ]
      },
      "properties": {
        "tenDoiTuon": "ACD 17-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "",
        "tuDien": "ACD",
        "chuDauTu": "",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "ACD",
        "diaChiLapD": "",
        "longitude": 105.81880073003829,
        "latitude": 21.01079206673998,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2049,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82140863065813,
          21.01090385685115
        ]
      },
      "properties": {
        "tenDoiTuon": "ACD 19",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "",
        "tuDien": "ACD",
        "chuDauTu": "",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "ACD",
        "diaChiLapD": "",
        "longitude": 105.82140863065813,
        "latitude": 21.01090385685115,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2050,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82122355824308,
          21.01071856999609
        ]
      },
      "properties": {
        "tenDoiTuon": "ACD 20",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "",
        "tuDien": "ACD",
        "chuDauTu": "",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "ACD",
        "diaChiLapD": "",
        "longitude": 105.82122355824308,
        "latitude": 21.01071856999609,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2051,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82108071354546,
          21.010904431954163
        ]
      },
      "properties": {
        "tenDoiTuon": "ACD 20-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "",
        "tuDien": "ACD",
        "chuDauTu": "",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "ACD",
        "diaChiLapD": "",
        "longitude": 105.82108071354546,
        "latitude": 21.010904431954163,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2052,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82099048856313,
          21.011130785186708
        ]
      },
      "properties": {
        "tenDoiTuon": "ACD 20-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "",
        "tuDien": "ACD",
        "chuDauTu": "",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "ACD",
        "diaChiLapD": "",
        "longitude": 105.82099048856313,
        "latitude": 21.011130785186708,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2053,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8206917932432,
          21.010919455202092
        ]
      },
      "properties": {
        "tenDoiTuon": "ACD 20-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "",
        "tuDien": "ACD",
        "chuDauTu": "",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "ACD",
        "diaChiLapD": "",
        "longitude": 105.8206917932432,
        "latitude": 21.010919455202092,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2054,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82076689509589,
          21.011147307616845
        ]
      },
      "properties": {
        "tenDoiTuon": "ACD 20-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "",
        "tuDien": "ACD",
        "chuDauTu": "",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "ACD",
        "diaChiLapD": "",
        "longitude": 105.82076689509589,
        "latitude": 21.011147307616845,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2055,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82064619567565,
          21.01120740050423
        ]
      },
      "properties": {
        "tenDoiTuon": "ACD 10-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "",
        "tuDien": "ACD",
        "chuDauTu": "",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "ACD",
        "diaChiLapD": "",
        "longitude": 105.82064619567565,
        "latitude": 21.01120740050423,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2056,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82165263977825,
          21.011152728226648
        ]
      },
      "properties": {
        "tenDoiTuon": "ACD 18",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "",
        "tuDien": "ACD",
        "chuDauTu": "",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "ACD",
        "diaChiLapD": "",
        "longitude": 105.82165263977825,
        "latitude": 21.011152728226648,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2057,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82224133362284,
          21.011711612643914
        ]
      },
      "properties": {
        "tenDoiTuon": "ACD 25",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "",
        "tuDien": "ACD",
        "chuDauTu": "",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "ACD",
        "diaChiLapD": "",
        "longitude": 105.82224133362284,
        "latitude": 21.011711612643914,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2058,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82193824400424,
          21.011553869298545
        ]
      },
      "properties": {
        "tenDoiTuon": "ACD 24",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "",
        "tuDien": "ACD",
        "chuDauTu": "",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "ACD",
        "diaChiLapD": "",
        "longitude": 105.82193824400424,
        "latitude": 21.011553869298545,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2059,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82001845292808,
          21.010376422621498
        ]
      },
      "properties": {
        "tenDoiTuon": "ACD 13-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "",
        "tuDien": "ACD",
        "chuDauTu": "",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "ACD",
        "diaChiLapD": "",
        "longitude": 105.82001845292808,
        "latitude": 21.010376422621498,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2060,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81988166027033,
          21.010331352708235
        ]
      },
      "properties": {
        "tenDoiTuon": "ACD 13-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "",
        "tuDien": "ACD",
        "chuDauTu": "",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "ACD",
        "diaChiLapD": "",
        "longitude": 105.81988166027033,
        "latitude": 21.010331352708235,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2061,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81973413877267,
          21.01044152358405
        ]
      },
      "properties": {
        "tenDoiTuon": "ACD 13-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "",
        "tuDien": "ACD",
        "chuDauTu": "",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "ACD",
        "diaChiLapD": "",
        "longitude": 105.81973413877267,
        "latitude": 21.01044152358405,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2062,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8220969964367,
          21.006393094299188
        ]
      },
      "properties": {
        "tenDoiTuon": "VH7 3-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 44",
        "tuDien": "VH7",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 7",
        "diaChiLapD": "",
        "longitude": 105.8220969964367,
        "latitude": 21.006393094299188,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2063,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82201962186483,
          21.006670081529098
        ]
      },
      "properties": {
        "tenDoiTuon": "VH7 4-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 58",
        "tuDien": "VH7",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 7",
        "diaChiLapD": "",
        "longitude": 105.82201962186483,
        "latitude": 21.006670081529098,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2064,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82003163643913,
          21.011283214762006
        ]
      },
      "properties": {
        "tenDoiTuon": "ACD 11-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "",
        "tuDien": "ACD",
        "chuDauTu": "",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "ACD",
        "diaChiLapD": "",
        "longitude": 105.82003163643913,
        "latitude": 21.011283214762006,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2065,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81983033656483,
          21.011436357687234
        ]
      },
      "properties": {
        "tenDoiTuon": "ACD 11-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "",
        "tuDien": "ACD",
        "chuDauTu": "",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "ACD",
        "diaChiLapD": "",
        "longitude": 105.81983033656483,
        "latitude": 21.011436357687234,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2066,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8197248055284,
          21.011519715800475
        ]
      },
      "properties": {
        "tenDoiTuon": "ACD 11-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "",
        "tuDien": "ACD",
        "chuDauTu": "",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "ACD",
        "diaChiLapD": "",
        "longitude": 105.8197248055284,
        "latitude": 21.011519715800475,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2067,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81998866782057,
          21.011588885043956
        ]
      },
      "properties": {
        "tenDoiTuon": "ACD 10-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "",
        "tuDien": "ACD",
        "chuDauTu": "",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "ACD",
        "diaChiLapD": "",
        "longitude": 105.81998866782057,
        "latitude": 21.011588885043956,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2068,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82026392953605,
          21.011409545720948
        ]
      },
      "properties": {
        "tenDoiTuon": "ACD 10-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "",
        "tuDien": "ACD",
        "chuDauTu": "",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "ACD",
        "diaChiLapD": "",
        "longitude": 105.82026392953605,
        "latitude": 21.011409545720948,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2069,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82036820041368,
          21.011477463080954
        ]
      },
      "properties": {
        "tenDoiTuon": "ACD 10-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "",
        "tuDien": "ACD",
        "chuDauTu": "",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "ACD",
        "diaChiLapD": "",
        "longitude": 105.82036820041368,
        "latitude": 21.011477463080954,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2070,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82039630905825,
          21.010921955295153
        ]
      },
      "properties": {
        "tenDoiTuon": "ACD 11-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "",
        "tuDien": "ACD",
        "chuDauTu": "",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "ACD",
        "diaChiLapD": "",
        "longitude": 105.82039630905825,
        "latitude": 21.010921955295153,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2071,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82204854981786,
          21.01092946691843
        ]
      },
      "properties": {
        "tenDoiTuon": "ACD 03",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "",
        "tuDien": "ACD",
        "chuDauTu": "",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "ACD",
        "diaChiLapD": "",
        "longitude": 105.82204854981786,
        "latitude": 21.01092946691843,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2072,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82205927864882,
          21.01084934291532
        ]
      },
      "properties": {
        "tenDoiTuon": "ACD 3-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "",
        "tuDien": "ACD",
        "chuDauTu": "",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "ACD",
        "diaChiLapD": "",
        "longitude": 105.82205927864882,
        "latitude": 21.01084934291532,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2073,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8222953130469,
          21.010676575386405
        ]
      },
      "properties": {
        "tenDoiTuon": "ACD 02",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "",
        "tuDien": "ACD",
        "chuDauTu": "",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "ACD",
        "diaChiLapD": "",
        "longitude": 105.8222953130469,
        "latitude": 21.010676575386405,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2074,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82220948235424,
          21.01060646676665
        ]
      },
      "properties": {
        "tenDoiTuon": "ACD 2-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "",
        "tuDien": "ACD",
        "chuDauTu": "",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "ACD",
        "diaChiLapD": "",
        "longitude": 105.82220948235424,
        "latitude": 21.01060646676665,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2075,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82200027005155,
          21.010293481456614
        ]
      },
      "properties": {
        "tenDoiTuon": "ACD 2-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "",
        "tuDien": "ACD",
        "chuDauTu": "",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "ACD",
        "diaChiLapD": "",
        "longitude": 105.82200027005155,
        "latitude": 21.010293481456614,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2076,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82261449591192,
          21.010318520306292
        ]
      },
      "properties": {
        "tenDoiTuon": "ACD 01",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "",
        "tuDien": "ACD",
        "chuDauTu": "",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "ACD",
        "diaChiLapD": "",
        "longitude": 105.82261449591192,
        "latitude": 21.010318520306292,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2077,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82220411793875,
          21.010140744387204
        ]
      },
      "properties": {
        "tenDoiTuon": "ACD 1-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "",
        "tuDien": "ACD",
        "chuDauTu": "",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "ACD",
        "diaChiLapD": "",
        "longitude": 105.82220411793875,
        "latitude": 21.010140744387204,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2078,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82173804674491,
          21.01023961794275
        ]
      },
      "properties": {
        "tenDoiTuon": "ACD 1-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "",
        "tuDien": "ACD",
        "chuDauTu": "",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "ACD",
        "diaChiLapD": "",
        "longitude": 105.82173804674491,
        "latitude": 21.01023961794275,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2079,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8215577055675,
          21.010358582455343
        ]
      },
      "properties": {
        "tenDoiTuon": "ACD 1-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "",
        "tuDien": "ACD",
        "chuDauTu": "",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "ACD",
        "diaChiLapD": "",
        "longitude": 105.8215577055675,
        "latitude": 21.010358582455343,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2080,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82172132031162,
          21.009587384185636
        ]
      },
      "properties": {
        "tenDoiTuon": "ACD 1-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "",
        "tuDien": "ACD",
        "chuDauTu": "",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "ACD",
        "diaChiLapD": "",
        "longitude": 105.82172132031162,
        "latitude": 21.009587384185636,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2081,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82194394366202,
          21.00983526977843
        ]
      },
      "properties": {
        "tenDoiTuon": "ACD 1-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "",
        "tuDien": "ACD",
        "chuDauTu": "",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "ACD",
        "diaChiLapD": "",
        "longitude": 105.82194394366202,
        "latitude": 21.00983526977843,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2082,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82130557791399,
          21.009820246421253
        ]
      },
      "properties": {
        "tenDoiTuon": "ACD 1-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "",
        "tuDien": "ACD",
        "chuDauTu": "",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "ACD",
        "diaChiLapD": "",
        "longitude": 105.82130557791399,
        "latitude": 21.009820246421253,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2083,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82112050548996,
          21.009612423152976
        ]
      },
      "properties": {
        "tenDoiTuon": "ACD 1-7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "",
        "tuDien": "ACD",
        "chuDauTu": "",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "ACD",
        "diaChiLapD": "",
        "longitude": 105.82112050548996,
        "latitude": 21.009612423152976,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2084,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82152015464115,
          21.009499747765982
        ]
      },
      "properties": {
        "tenDoiTuon": "ACD 1-8",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "",
        "tuDien": "ACD",
        "chuDauTu": "",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "ACD",
        "diaChiLapD": "",
        "longitude": 105.82152015464115,
        "latitude": 21.009499747765982,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2085,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82126802698762,
          21.00925937332309
        ]
      },
      "properties": {
        "tenDoiTuon": "ACD 1-9",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "",
        "tuDien": "ACD",
        "chuDauTu": "",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "ACD",
        "diaChiLapD": "",
        "longitude": 105.82126802698762,
        "latitude": 21.00925937332309,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2086,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82109610124597,
          21.009258978449044
        ]
      },
      "properties": {
        "tenDoiTuon": "ACD 1-10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "",
        "tuDien": "ACD",
        "chuDauTu": "",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "ACD",
        "diaChiLapD": "",
        "longitude": 105.82109610124597,
        "latitude": 21.009258978449044,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2087,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82186819092838,
          21.006489449067185
        ]
      },
      "properties": {
        "tenDoiTuon": "4-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 58",
        "tuDien": "VH7",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 7",
        "diaChiLapD": "",
        "longitude": 105.82186819092838,
        "latitude": 21.006489449067185,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2088,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82069939868582,
          21.01018080658652
        ]
      },
      "properties": {
        "tenDoiTuon": "ACD 22",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "",
        "tuDien": "ACD",
        "chuDauTu": "",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "ACD",
        "diaChiLapD": "",
        "longitude": 105.82069939868582,
        "latitude": 21.01018080658652,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2089,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82059445155032,
          21.009969268621926
        ]
      },
      "properties": {
        "tenDoiTuon": "ACD 23",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "",
        "tuDien": "ACD",
        "chuDauTu": "",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "ACD",
        "diaChiLapD": "",
        "longitude": 105.82059445155032,
        "latitude": 21.009969268621926,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2090,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82038289801956,
          21.010030573290955
        ]
      },
      "properties": {
        "tenDoiTuon": "23-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "",
        "tuDien": "ACD",
        "chuDauTu": "",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "ACD",
        "diaChiLapD": "",
        "longitude": 105.82038289801956,
        "latitude": 21.010030573290955,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2091,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82045799987227,
          21.010148256051473
        ]
      },
      "properties": {
        "tenDoiTuon": "ACD 23-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "",
        "tuDien": "ACD",
        "chuDauTu": "",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "ACD",
        "diaChiLapD": "",
        "longitude": 105.82045799987227,
        "latitude": 21.010148256051473,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2092,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82031428937836,
          21.0102127857883
        ]
      },
      "properties": {
        "tenDoiTuon": "ACD 23-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "",
        "tuDien": "ACD",
        "chuDauTu": "",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "ACD",
        "diaChiLapD": "",
        "longitude": 105.82031428937836,
        "latitude": 21.0102127857883,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2093,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82165064313915,
          21.00700686281974
        ]
      },
      "properties": {
        "tenDoiTuon": "VH7 5-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 88",
        "tuDien": "VH7",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 7",
        "diaChiLapD": "",
        "longitude": 105.82165064313915,
        "latitude": 21.00700686281974,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2094,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8213034720833,
          21.006969928753573
        ]
      },
      "properties": {
        "tenDoiTuon": "6-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 27",
        "tuDien": "VH7",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 7",
        "diaChiLapD": "",
        "longitude": 105.8213034720833,
        "latitude": 21.006969928753573,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2095,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82231140630233,
          21.010856854543917
        ]
      },
      "properties": {
        "tenDoiTuon": "ACD 2-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "",
        "tuDien": "ACD",
        "chuDauTu": "",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "ACD",
        "diaChiLapD": "",
        "longitude": 105.82231140630233,
        "latitude": 21.010856854543917,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2096,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82244819896005,
          21.01097203277932
        ]
      },
      "properties": {
        "tenDoiTuon": "ACD2-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "",
        "tuDien": "ACD",
        "chuDauTu": "",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "ACD",
        "diaChiLapD": "",
        "longitude": 105.82244819896005,
        "latitude": 21.01097203277932,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2097,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82261449592092,
          21.011107241899253
        ]
      },
      "properties": {
        "tenDoiTuon": "ACD 2-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "",
        "tuDien": "ACD",
        "chuDauTu": "",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "ACD",
        "diaChiLapD": "",
        "longitude": 105.82261449592092,
        "latitude": 21.011107241899253,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2098,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82278079288176,
          21.011237443158066
        ]
      },
      "properties": {
        "tenDoiTuon": "ACD 2-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "",
        "tuDien": "ACD",
        "chuDauTu": "",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "ACD",
        "diaChiLapD": "",
        "longitude": 105.82278079288176,
        "latitude": 21.011237443158066,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2099,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82086569564666,
          21.01034856692118
        ]
      },
      "properties": {
        "tenDoiTuon": "ACD 21",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "",
        "tuDien": "ACD",
        "chuDauTu": "",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "ACD",
        "diaChiLapD": "",
        "longitude": 105.82086569564666,
        "latitude": 21.01034856692118,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2100,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8207584072831,
          21.009857804813777
        ]
      },
      "properties": {
        "tenDoiTuon": "ACD 23-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "",
        "tuDien": "ACD",
        "chuDauTu": "",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "ACD",
        "diaChiLapD": "",
        "longitude": 105.8207584072831,
        "latitude": 21.009857804813777,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2101,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82060015695447,
          21.009612423154643
        ]
      },
      "properties": {
        "tenDoiTuon": "ACD 23-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "",
        "tuDien": "ACD",
        "chuDauTu": "",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "ACD",
        "diaChiLapD": "",
        "longitude": 105.82060015695447,
        "latitude": 21.009612423154643,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2102,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82139946716333,
          21.00702327479701
        ]
      },
      "properties": {
        "tenDoiTuon": "07",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Thái Thịnh",
        "tuDien": "VH7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "Vĩnh Hồ 7",
        "diaChiLapD": "",
        "longitude": 105.82139946716333,
        "latitude": 21.00702327479701,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2103,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8351785393353,
          21.01153141333041
        ]
      },
      "properties": {
        "tenDoiTuon": "05",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 29 Xã Đàn",
        "tuDien": "HĐA",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Trạm Hồ Đấu",
        "diaChiLapD": "",
        "longitude": 105.8351785393353,
        "latitude": 21.01153141333041,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2104,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83531801420077,
          21.011649094907813
        ]
      },
      "properties": {
        "tenDoiTuon": "06",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 29 Xã Đàn",
        "tuDien": "HĐA",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Trạm Hồ Đấu",
        "diaChiLapD": "",
        "longitude": 105.83531801420077,
        "latitude": 21.011649094907813,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2105,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8354762645384,
          21.011781799553482
        ]
      },
      "properties": {
        "tenDoiTuon": "07",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 29 Xã Đàn",
        "tuDien": "HĐA",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Trạm Hồ Đấu",
        "diaChiLapD": "",
        "longitude": 105.8354762645384,
        "latitude": 21.011781799553482,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2106,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83561305719611,
          21.011538924923002
        ]
      },
      "properties": {
        "tenDoiTuon": "7-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 29 Xã Đàn",
        "tuDien": "HĐA",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 5,
        "tuDien2": "Trạm Hồ Đấu",
        "diaChiLapD": "",
        "longitude": 105.83561305719611,
        "latitude": 21.011538924923002,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2107,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83558623510972,
          21.01170668373036
        ]
      },
      "properties": {
        "tenDoiTuon": "08",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 29 Xã Đàn",
        "tuDien": "HĐA",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Trạm Hồ Đấu",
        "diaChiLapD": "",
        "longitude": 105.83558623510972,
        "latitude": 21.01170668373036,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2108,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83572570997518,
          21.01155394810788
        ]
      },
      "properties": {
        "tenDoiTuon": "09",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 29 Xã Đàn",
        "tuDien": "HĐA",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Trạm Hồ Đấu",
        "diaChiLapD": "",
        "longitude": 105.83572570997518,
        "latitude": 21.01155394810788,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2109,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8349542396118,
          21.011572414100062
        ]
      },
      "properties": {
        "tenDoiTuon": "02",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 29 Xã Đàn",
        "tuDien": "HĐA",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Trạm Hồ Đấu",
        "diaChiLapD": "",
        "longitude": 105.8349542396118,
        "latitude": 21.011572414100062,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2110,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83482012916184,
          21.011738920939944
        ]
      },
      "properties": {
        "tenDoiTuon": "01",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 29 Xã Đàn",
        "tuDien": "HĐA",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Trạm Hồ Đấu",
        "diaChiLapD": "",
        "longitude": 105.83482012916184,
        "latitude": 21.011738920939944,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2111,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83489120770292,
          21.011859106212132
        ]
      },
      "properties": {
        "tenDoiTuon": "16",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 81 Xã Đàn",
        "tuDien": "HĐA",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Trạm Hồ Đấu",
        "diaChiLapD": "",
        "longitude": 105.83489120770292,
        "latitude": 21.011859106212132,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2112,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83477184939552,
          21.012039383938877
        ]
      },
      "properties": {
        "tenDoiTuon": "16-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 6/31 Đặng Văn Ngữ",
        "tuDien": "HĐA",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Trạm Hồ Đấu",
        "diaChiLapD": "",
        "longitude": 105.83477184939552,
        "latitude": 21.012039383938877,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2113,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83454252052579,
          21.011891656373017
        ]
      },
      "properties": {
        "tenDoiTuon": "16-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 6/31 Đặng Văn Ngữ",
        "tuDien": "HĐA",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Trạm Hồ Đấu",
        "diaChiLapD": "",
        "longitude": 105.83454252052579,
        "latitude": 21.011891656373017,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2114,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83429843951346,
          21.011731409357427
        ]
      },
      "properties": {
        "tenDoiTuon": "HĐ 16-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 6/31 Đặng Văn Ngữ",
        "tuDien": "HĐA",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Trạm Hồ Đấu",
        "diaChiLapD": "",
        "longitude": 105.83429843951346,
        "latitude": 21.011731409357427,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2115,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83465517330485,
          21.012225921079956
        ]
      },
      "properties": {
        "tenDoiTuon": "HĐ 16-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 6/31 Đặng Văn Ngữ",
        "tuDien": "HĐA",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "Trạm Hồ Đấu",
        "diaChiLapD": "",
        "longitude": 105.83465517330485,
        "latitude": 21.012225921079956,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2116,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82112979889395,
          21.00697180672846
        ]
      },
      "properties": {
        "tenDoiTuon": "8-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Nhà C5 Thái Thịnh",
        "tuDien": "VH7",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 7",
        "diaChiLapD": "",
        "longitude": 105.82112979889395,
        "latitude": 21.00697180672846,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2117,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82091924548737,
          21.006809050484136
        ]
      },
      "properties": {
        "tenDoiTuon": "8-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Nhà C5 Thái Thịnh",
        "tuDien": "VH7",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 7",
        "diaChiLapD": "",
        "longitude": 105.82091924548737,
        "latitude": 21.006809050484136,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2118,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82079049945288,
          21.006676341414277
        ]
      },
      "properties": {
        "tenDoiTuon": "VH7 8-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Nhà E5",
        "tuDien": "VH7",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 7",
        "diaChiLapD": "",
        "longitude": 105.82079049945288,
        "latitude": 21.006676341414277,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2119,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83508566785407,
          21.012035628154393
        ]
      },
      "properties": {
        "tenDoiTuon": "17",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 81 Xã Đàn",
        "tuDien": "HĐA",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Trạm Hồ Đấu",
        "diaChiLapD": "",
        "longitude": 105.83508566785407,
        "latitude": 21.012035628154393,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2120,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83526805807035,
          21.01215080547963
        ]
      },
      "properties": {
        "tenDoiTuon": "18",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 81 Xã Đàn",
        "tuDien": "HĐA",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Trạm Hồ Đấu",
        "diaChiLapD": "",
        "longitude": 105.83526805807035,
        "latitude": 21.01215080547963,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2121,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83547760563327,
          21.01221747057719
        ]
      },
      "properties": {
        "tenDoiTuon": "18-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 81 Xã Đàn",
        "tuDien": "HĐA",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Trạm Hồ Đấu",
        "diaChiLapD": "",
        "longitude": 105.83547760563327,
        "latitude": 21.01221747057719,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2122,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83560098724331,
          21.012092277883834
        ]
      },
      "properties": {
        "tenDoiTuon": "HĐ 18-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 81 Xã Đàn",
        "tuDien": "HĐA",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 7,
        "tuDien2": "Trạm Hồ Đấu",
        "diaChiLapD": "",
        "longitude": 105.83560098724331,
        "latitude": 21.012092277883834,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2123,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83526571112283,
          21.01233014391144
        ]
      },
      "properties": {
        "tenDoiTuon": "19",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 81 Xã Đàn",
        "tuDien": "HĐA",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Trạm Hồ Đấu",
        "diaChiLapD": "",
        "longitude": 105.83526571112283,
        "latitude": 21.01233014391144,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2124,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83502967672477,
          21.012154874244036
        ]
      },
      "properties": {
        "tenDoiTuon": "HĐ 19-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 81 Xã Đàn",
        "tuDien": "HĐA",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 7,
        "tuDien2": "Trạm Hồ Đấu",
        "diaChiLapD": "",
        "longitude": 105.83502967672477,
        "latitude": 21.012154874244036,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2125,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8213990257633,
          21.00729324976823
        ]
      },
      "properties": {
        "tenDoiTuon": "8-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Nhà E6 Thái Thịnh",
        "tuDien": "VH7",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 7",
        "diaChiLapD": "",
        "longitude": 105.8213990257633,
        "latitude": 21.00729324976823,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2126,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82157873377187,
          21.007467273180882
        ]
      },
      "properties": {
        "tenDoiTuon": "8-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Nhà E6 Thái Thịnh",
        "tuDien": "VH7",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 7",
        "diaChiLapD": "",
        "longitude": 105.82157873377187,
        "latitude": 21.007467273180882,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2127,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83470680579939,
          21.011514199264028
        ]
      },
      "properties": {
        "tenDoiTuon": "HĐ 2-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 46/23 Phạm Ngọc Thạch",
        "tuDien": "HĐA",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Trạm Hồ Đấu",
        "diaChiLapD": "",
        "longitude": 105.83470680579939,
        "latitude": 21.011514199264028,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2128,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83512254819703,
          21.01171951602129
        ]
      },
      "properties": {
        "tenDoiTuon": "03",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 29 Xã Đàn",
        "tuDien": "HĐA",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Trạm Hồ Đấu",
        "diaChiLapD": "",
        "longitude": 105.83512254819703,
        "latitude": 21.01171951602129,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2129,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83528884515788,
          21.011852220604315
        ]
      },
      "properties": {
        "tenDoiTuon": "HĐ 04",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 29 Xã Đàn",
        "tuDien": "HĐA",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Trạm Hồ Đấu",
        "diaChiLapD": "",
        "longitude": 105.83528884515788,
        "latitude": 21.011852220604315,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2130,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82146742209665,
          21.00759622565103
        ]
      },
      "properties": {
        "tenDoiTuon": "VH7 8-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Nhà C6",
        "tuDien": "VH7",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 7",
        "diaChiLapD": "",
        "longitude": 105.82146742209665,
        "latitude": 21.00759622565103,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2131,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82092058675295,
          21.007243484038163
        ]
      },
      "properties": {
        "tenDoiTuon": "9-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 35",
        "tuDien": "VH7",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 7",
        "diaChiLapD": "",
        "longitude": 105.82092058675295,
        "latitude": 21.007243484038163,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2132,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83514400586795,
          21.011233766162906
        ]
      },
      "properties": {
        "tenDoiTuon": "11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 46 Phạm Ngọc Thạch",
        "tuDien": "HĐA",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Trạm Hồ Đấu",
        "diaChiLapD": "",
        "longitude": 105.83514400586795,
        "latitude": 21.011233766162906,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2133,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83536662921833,
          21.010970859651867
        ]
      },
      "properties": {
        "tenDoiTuon": "12",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 46 Phạm Ngọc Thạch",
        "tuDien": "HĐA",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Trạm Hồ Đấu",
        "diaChiLapD": "",
        "longitude": 105.83536662921833,
        "latitude": 21.010970859651867,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2134,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83513595924472,
          21.010813115523153
        ]
      },
      "properties": {
        "tenDoiTuon": "12-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 46/15 Phạm Ngọc Thạch",
        "tuDien": "HĐA",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Trạm Hồ Đấu",
        "diaChiLapD": "",
        "longitude": 105.83513595924472,
        "latitude": 21.010813115523153,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2135,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83497502670834,
          21.010695433286255
        ]
      },
      "properties": {
        "tenDoiTuon": "12-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 46/15 Phạm Ngọc Thạch",
        "tuDien": "HĐA",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Trạm Hồ Đấu",
        "diaChiLapD": "",
        "longitude": 105.83497502670834,
        "latitude": 21.010695433286255,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2136,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83487846717573,
          21.010843162036757
        ]
      },
      "properties": {
        "tenDoiTuon": "HĐ 12-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 46/15 Phạm Ngọc Thạch",
        "tuDien": "HĐA",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Trạm Hồ Đấu",
        "diaChiLapD": "",
        "longitude": 105.83487846717573,
        "latitude": 21.010843162036757,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2137,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83483018741839,
          21.01076053410958
        ]
      },
      "properties": {
        "tenDoiTuon": "HĐ 12-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 46/15 Phạm Ngọc Thạch",
        "tuDien": "HĐA",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Trạm Hồ Đấu",
        "diaChiLapD": "",
        "longitude": 105.83483018741839,
        "latitude": 21.01076053410958,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2138,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82066845910845,
          21.007065704454874
        ]
      },
      "properties": {
        "tenDoiTuon": "VH7 9-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 35",
        "tuDien": "VH7",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 7",
        "diaChiLapD": "",
        "longitude": 105.82066845910845,
        "latitude": 21.007065704454874,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2139,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83561875687184,
          21.01072547982355
        ]
      },
      "properties": {
        "tenDoiTuon": "13",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 46 Phạm Ngọc Thạch",
        "tuDien": "HĐA",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Trạm Hồ Đấu",
        "diaChiLapD": "",
        "longitude": 105.83561875687184,
        "latitude": 21.01072547982355,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2140,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8354873286296,
          21.01060278975846
        ]
      },
      "properties": {
        "tenDoiTuon": "14",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 46 Phạm Ngọc Thạch",
        "tuDien": "HĐA",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Trạm Hồ Đấu",
        "diaChiLapD": "",
        "longitude": 105.8354873286296,
        "latitude": 21.01060278975846,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2141,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83559729919195,
          21.01047258794575
        ]
      },
      "properties": {
        "tenDoiTuon": "HĐ 15",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 46 Phạm Ngọc Thạch",
        "tuDien": "HĐA",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Trạm Hồ Đấu",
        "diaChiLapD": "",
        "longitude": 105.83559729919195,
        "latitude": 21.01047258794575,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2142,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83587088451637,
          21.010848169788567
        ]
      },
      "properties": {
        "tenDoiTuon": "13-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 46 Phạm Ngọc Thạch",
        "tuDien": "HĐA",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Trạm Hồ Đấu",
        "diaChiLapD": "",
        "longitude": 105.83587088451637,
        "latitude": 21.010848169788567,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2143,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83608277902682,
          21.01099089064014
        ]
      },
      "properties": {
        "tenDoiTuon": "13-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 46 Phạm Ngọc Thạch",
        "tuDien": "HĐA",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Trạm Hồ Đấu",
        "diaChiLapD": "",
        "longitude": 105.83608277902682,
        "latitude": 21.01099089064014,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2144,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83636441097448,
          21.011181184897605
        ]
      },
      "properties": {
        "tenDoiTuon": "HĐ 13-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 46 Phạm Ngọc Thạch",
        "tuDien": "HĐA",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Trạm Hồ Đấu",
        "diaChiLapD": "",
        "longitude": 105.83636441097448,
        "latitude": 21.011181184897605,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2145,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83546855316642,
          21.011293859014618
        ]
      },
      "properties": {
        "tenDoiTuon": "HĐ 11-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 46/28 Phạm Ngọc Thạch",
        "tuDien": "HĐA",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Trạm Hồ Đấu",
        "diaChiLapD": "",
        "longitude": 105.83546855316642,
        "latitude": 21.011293859014618,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2146,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83495390430663,
          21.01139996039635
        ]
      },
      "properties": {
        "tenDoiTuon": "5-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 46/21 Phạm Ngọc Thạch",
        "tuDien": "HĐA",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Trạm Hồ Đấu",
        "diaChiLapD": "",
        "longitude": 105.83495390430663,
        "latitude": 21.01139996039635,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2147,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83475139752332,
          21.01124346865623
        ]
      },
      "properties": {
        "tenDoiTuon": "HĐ 5-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 46/21 Phạm Ngọc Thạch",
        "tuDien": "HĐA",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Trạm Hồ Đấu",
        "diaChiLapD": "",
        "longitude": 105.83475139752332,
        "latitude": 21.01124346865623,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2148,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83580416456512,
          21.011058182222158
        ]
      },
      "properties": {
        "tenDoiTuon": "HĐ 13-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 46 Phạm Ngọc Thạch",
        "tuDien": "HĐA",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Trạm Hồ Đấu",
        "diaChiLapD": "",
        "longitude": 105.83580416456512,
        "latitude": 21.011058182222158,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2149,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8359563799488,
          21.011298553763307
        ]
      },
      "properties": {
        "tenDoiTuon": "10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 29 Xã Đàn",
        "tuDien": "HĐA",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Trạm Hồ Đấu",
        "diaChiLapD": "",
        "longitude": 105.8359563799488,
        "latitude": 21.011298553763307,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2150,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83589468914379,
          21.011248476390428
        ]
      },
      "properties": {
        "tenDoiTuon": "HĐ 10-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 29 Xã Đàn",
        "tuDien": "HĐA",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 7,
        "tuDien2": "Trạm Hồ Đấu",
        "diaChiLapD": "",
        "longitude": 105.83589468914379,
        "latitude": 21.011248476390428,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2151,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83609853703098,
          21.011408723925655
        ]
      },
      "properties": {
        "tenDoiTuon": "10-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 29 Xã Đàn",
        "tuDien": "HĐA",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Trạm Hồ Đấu",
        "diaChiLapD": "",
        "longitude": 105.83609853703098,
        "latitude": 21.011408723925655,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2152,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83623801189646,
          21.011516390140724
        ]
      },
      "properties": {
        "tenDoiTuon": "10-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 29 Xã Đàn",
        "tuDien": "HĐA",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": "",
        "tuDien2": "Trạm Hồ Đấu",
        "diaChiLapD": "",
        "longitude": 105.83623801189646,
        "latitude": 21.011516390140724,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2153,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83496932703261,
          21.012605567258746
        ]
      },
      "properties": {
        "tenDoiTuon": "HĐ 19-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 83 Xã Đàn",
        "tuDien": "HĐA",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 8,
        "tuDien2": "Trạm Hồ Đấu",
        "diaChiLapD": "",
        "longitude": 105.83496932703261,
        "latitude": 21.012605567258746,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2154,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81986899307262,
          21.00914974986986
        ]
      },
      "properties": {
        "tenDoiTuon": "13-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Nhà G1.3",
        "tuDien": "VH7",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 7",
        "diaChiLapD": "",
        "longitude": 105.81986899307262,
        "latitude": 21.00914974986986,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2155,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82002590229739,
          21.009256165765485
        ]
      },
      "properties": {
        "tenDoiTuon": "VH7 13-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Nhà G1.3",
        "tuDien": "VH7",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 7",
        "diaChiLapD": "",
        "longitude": 105.82002590229739,
        "latitude": 21.009256165765485,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2156,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81940983251913,
          21.008901080662618
        ]
      },
      "properties": {
        "tenDoiTuon": "13-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 51",
        "tuDien": "VH7",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 7",
        "diaChiLapD": "",
        "longitude": 105.81940983251913,
        "latitude": 21.008901080662618,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2157,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81914965824238,
          21.008703271761696
        ]
      },
      "properties": {
        "tenDoiTuon": "13-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 51",
        "tuDien": "VH7",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 7",
        "diaChiLapD": "",
        "longitude": 105.81914965824238,
        "latitude": 21.008703271761696,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2158,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8189002128056,
          21.008522990003367
        ]
      },
      "properties": {
        "tenDoiTuon": "13-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 51",
        "tuDien": "VH7",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 7",
        "diaChiLapD": "",
        "longitude": 105.8189002128056,
        "latitude": 21.008522990003367,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2159,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.818669542832,
          21.00827134635094
        ]
      },
      "properties": {
        "tenDoiTuon": "VH7 13-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 51",
        "tuDien": "VH7",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 7",
        "diaChiLapD": "",
        "longitude": 105.818669542832,
        "latitude": 21.00827134635094,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2160,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81867624833339,
          21.0096572591099
        ]
      },
      "properties": {
        "tenDoiTuon": "16-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 63",
        "tuDien": "VH7",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 7",
        "diaChiLapD": "",
        "longitude": 105.81867624833339,
        "latitude": 21.0096572591099,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2161,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81844658420339,
          21.009472283688293
        ]
      },
      "properties": {
        "tenDoiTuon": "16-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 63",
        "tuDien": "VH7",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 7",
        "diaChiLapD": "",
        "longitude": 105.81844658420339,
        "latitude": 21.009472283688293,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2162,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8182293252775,
          21.0092957587128
        ]
      },
      "properties": {
        "tenDoiTuon": "16-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 63",
        "tuDien": "VH7",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 7",
        "diaChiLapD": "",
        "longitude": 105.8182293252775,
        "latitude": 21.0092957587128,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2163,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81802681849413,
          21.009096698382358
        ]
      },
      "properties": {
        "tenDoiTuon": "VH7 16-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 63",
        "tuDien": "VH7",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 7",
        "diaChiLapD": "",
        "longitude": 105.81802681849413,
        "latitude": 21.009096698382358,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2164,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81888546065403,
          21.009441923911755
        ]
      },
      "properties": {
        "tenDoiTuon": "16-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 61",
        "tuDien": "VH7",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 7",
        "diaChiLapD": "",
        "longitude": 105.81888546065403,
        "latitude": 21.009441923911755,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2165,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81864808516107,
          21.009331752297737
        ]
      },
      "properties": {
        "tenDoiTuon": "16-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 61",
        "tuDien": "VH7",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 7",
        "diaChiLapD": "",
        "longitude": 105.81864808516107,
        "latitude": 21.009331752297737,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2166,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81845094279322,
          21.00916023496358
        ]
      },
      "properties": {
        "tenDoiTuon": "16-7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 61",
        "tuDien": "VH7",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 7",
        "diaChiLapD": "",
        "longitude": 105.81845094279322,
        "latitude": 21.00916023496358,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2167,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81826452926532,
          21.009006244780366
        ]
      },
      "properties": {
        "tenDoiTuon": "16-8",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 61",
        "tuDien": "VH7",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 7",
        "diaChiLapD": "",
        "longitude": 105.81826452926532,
        "latitude": 21.009006244780366,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2168,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81808884457739,
          21.008856010301965
        ]
      },
      "properties": {
        "tenDoiTuon": "16-9",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 61",
        "tuDien": "VH7",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 7",
        "diaChiLapD": "",
        "longitude": 105.81808884457739,
        "latitude": 21.008856010301965,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2169,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81804861144326,
          21.008666965035005
        ]
      },
      "properties": {
        "tenDoiTuon": "VH7 16-10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 61",
        "tuDien": "VH7",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 7",
        "diaChiLapD": "",
        "longitude": 105.81804861144326,
        "latitude": 21.008666965035005,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2170,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81916709261067,
          21.009158983012966
        ]
      },
      "properties": {
        "tenDoiTuon": "16-11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 61",
        "tuDien": "VH7",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 7",
        "diaChiLapD": "",
        "longitude": 105.81916709261067,
        "latitude": 21.009158983012966,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2171,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81893642262808,
          21.009156479107446
        ]
      },
      "properties": {
        "tenDoiTuon": "16-12",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 61",
        "tuDien": "VH7",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 7",
        "diaChiLapD": "",
        "longitude": 105.81893642262808,
        "latitude": 21.009156479107446,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2172,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81876342014787,
          21.00900749673395
        ]
      },
      "properties": {
        "tenDoiTuon": "16-13",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 61",
        "tuDien": "VH7",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 7",
        "diaChiLapD": "",
        "longitude": 105.81876342014787,
        "latitude": 21.00900749673395,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2173,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81854168402336,
          21.008857363092122
        ]
      },
      "properties": {
        "tenDoiTuon": "16-14",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 61",
        "tuDien": "VH7",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 7",
        "diaChiLapD": "",
        "longitude": 105.81854168402336,
        "latitude": 21.008857363092122,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2174,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81835572438246,
          21.008792160603786
        ]
      },
      "properties": {
        "tenDoiTuon": "16-15",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 61",
        "tuDien": "VH7",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 7",
        "diaChiLapD": "",
        "longitude": 105.81835572438246,
        "latitude": 21.008792160603786,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2175,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81837584094052,
          21.0086882482889
        ]
      },
      "properties": {
        "tenDoiTuon": "VH7 16-16",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 61",
        "tuDien": "VH7",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 7",
        "diaChiLapD": "",
        "longitude": 105.81837584094052,
        "latitude": 21.0086882482889,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2176,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82013268783798,
          21.023615199002087
        ]
      },
      "properties": {
        "tenDoiTuon": "03",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Giảng Võ",
        "tuDien": "K1HN",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "K1 Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82013268783798,
        "latitude": 21.023615199002087,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2177,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82037408664254,
          21.0238355210467
        ]
      },
      "properties": {
        "tenDoiTuon": "04",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Giảng Võ",
        "tuDien": "K1HN",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "K1 Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82037408664254,
        "latitude": 21.0238355210467,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2178,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82061280324837,
          21.024035813531427
        ]
      },
      "properties": {
        "tenDoiTuon": "05",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Giảng Võ",
        "tuDien": "K1HN",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "K1 Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82061280324837,
        "latitude": 21.024035813531427,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2179,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82085956647744,
          21.02424486852538
        ]
      },
      "properties": {
        "tenDoiTuon": "06",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Giảng Võ",
        "tuDien": "K1HN",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "K1 Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82085956647744,
        "latitude": 21.02424486852538,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2180,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82110767081033,
          21.02444891593204
        ]
      },
      "properties": {
        "tenDoiTuon": "07",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Giảng Võ",
        "tuDien": "K1HN",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "K1 Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82110767081033,
        "latitude": 21.02444891593204,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2181,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82135443403938,
          21.024662977634613
        ]
      },
      "properties": {
        "tenDoiTuon": "08",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Giảng Võ",
        "tuDien": "K1HN",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "K1 Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82135443403938,
        "latitude": 21.024662977634613,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2182,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82158644511685,
          21.024868276288604
        ]
      },
      "properties": {
        "tenDoiTuon": "09",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Giảng Võ",
        "tuDien": "K1HN",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "K1 Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82158644511685,
        "latitude": 21.024868276288604,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2183,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82184393718586,
          21.02507858193378
        ]
      },
      "properties": {
        "tenDoiTuon": "10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Giảng Võ",
        "tuDien": "K1HN",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "K1 Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82184393718586,
        "latitude": 21.02507858193378,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2184,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82207326605558,
          21.025278872748334
        ]
      },
      "properties": {
        "tenDoiTuon": "11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Giảng Võ",
        "tuDien": "K1HN",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "K1 Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82207326605558,
        "latitude": 21.025278872748334,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2185,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82232941702071,
          21.02549418507429
        ]
      },
      "properties": {
        "tenDoiTuon": "12",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Giảng Võ",
        "tuDien": "K1HN",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "K1 Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82232941702071,
        "latitude": 21.02549418507429,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2186,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82256679251368,
          21.0256907198915
        ]
      },
      "properties": {
        "tenDoiTuon": "13",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Giảng Võ",
        "tuDien": "K1HN",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "K1 Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82256679251368,
        "latitude": 21.0256907198915,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2187,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82282160237494,
          21.025912290679337
        ]
      },
      "properties": {
        "tenDoiTuon": "14",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Giảng Võ",
        "tuDien": "K1HN",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "K1 Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82282160237494,
        "latitude": 21.025912290679337,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2188,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82306836560399,
          21.026112580373674
        ]
      },
      "properties": {
        "tenDoiTuon": "15",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Giảng Võ",
        "tuDien": "K1HN",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "K1 Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82306836560399,
        "latitude": 21.026112580373674,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2189,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82331915214465,
          21.02631662522372
        ]
      },
      "properties": {
        "tenDoiTuon": "16",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Giảng Võ",
        "tuDien": "K1HN",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "K1 Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82331915214465,
        "latitude": 21.02631662522372,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2190,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82357127978916,
          21.0265331878563
        ]
      },
      "properties": {
        "tenDoiTuon": "17",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Giảng Võ",
        "tuDien": "K1HN",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "K1 Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82357127978916,
        "latitude": 21.0265331878563,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2191,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82380999639498,
          21.026742239348657
        ]
      },
      "properties": {
        "tenDoiTuon": "18",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Giảng Võ",
        "tuDien": "K1HN",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "K1 Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82380999639498,
        "latitude": 21.026742239348657,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2192,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82405810072792,
          21.026942527927762
        ]
      },
      "properties": {
        "tenDoiTuon": "19",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Giảng Võ",
        "tuDien": "K1HN",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "K1 Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82405810072792,
        "latitude": 21.026942527927762,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2193,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82430620506084,
          21.027147823442128
        ]
      },
      "properties": {
        "tenDoiTuon": "20",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Giảng Võ",
        "tuDien": "K1HN",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "K1 Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82430620506084,
        "latitude": 21.027147823442128,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2194,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82455699160147,
          21.027353118673766
        ]
      },
      "properties": {
        "tenDoiTuon": "21",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Giảng Võ",
        "tuDien": "K1HN",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "K1 Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82455699160147,
        "latitude": 21.027353118673766,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2195,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82477022722476,
          21.027552154634908
        ]
      },
      "properties": {
        "tenDoiTuon": "22",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 1,
        "maXaPhuong": 31,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Giảng Võ",
        "tuDien": "K1HN",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "K1 Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82477022722476,
        "latitude": 21.027552154634908,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2196,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82504381254022,
          21.02777497445177
        ]
      },
      "properties": {
        "tenDoiTuon": "23",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 1,
        "maXaPhuong": 28,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Giảng Võ",
        "tuDien": "K1HN",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "K1 Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82504381254022,
        "latitude": 21.02777497445177,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2197,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82530532792083,
          21.02798152061386
        ]
      },
      "properties": {
        "tenDoiTuon": "24",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 1,
        "maXaPhuong": 28,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Giảng Võ",
        "tuDien": "K1HN",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "K1 Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82530532792083,
        "latitude": 21.02798152061386,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2198,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82555209114987,
          21.028168037811575
        ]
      },
      "properties": {
        "tenDoiTuon": "25",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Giảng Võ",
        "tuDien": "K1HN",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "K1 Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82555209114987,
        "latitude": 21.028168037811575,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2199,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82582433536145,
          21.02836582089339
        ]
      },
      "properties": {
        "tenDoiTuon": "26",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Giảng Võ",
        "tuDien": "K1HN",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "K1 Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82582433536145,
        "latitude": 21.02836582089339,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2200,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82607512191109,
          21.028541071505863
        ]
      },
      "properties": {
        "tenDoiTuon": "27",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Giảng Võ",
        "tuDien": "K1HN",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "K1 Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82607512191109,
        "latitude": 21.028541071505863,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2201,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82633395507497,
          21.028730091578517
        ]
      },
      "properties": {
        "tenDoiTuon": "28",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Giảng Võ",
        "tuDien": "K1HN",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "K1 Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82633395507497,
        "latitude": 21.028730091578517,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2202,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8265860827285,
          21.028910348907885
        ]
      },
      "properties": {
        "tenDoiTuon": "29",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Giảng Võ",
        "tuDien": "K1HN",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "K1 Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.8265860827285,
        "latitude": 21.028910348907885,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2203,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82685296252457,
          21.029099368512185
        ]
      },
      "properties": {
        "tenDoiTuon": "30",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Giảng Võ",
        "tuDien": "K1HN",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "K1 Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82685296252457,
        "latitude": 21.029099368512185,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2204,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82712520673616,
          21.02928212896087
        ]
      },
      "properties": {
        "tenDoiTuon": "31",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Giảng Võ",
        "tuDien": "K1HN",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "K1 Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82712520673616,
        "latitude": 21.02928212896087,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2205,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81996102646163,
          21.023502534194293
        ]
      },
      "properties": {
        "tenDoiTuon": "02",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Giảng Võ",
        "tuDien": "K1HN",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 17,
        "tuDien2": "K1 Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.81996102646163,
        "latitude": 21.023502534194293,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2206,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82014878107545,
          21.023819247272314
        ]
      },
      "properties": {
        "tenDoiTuon": "64",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 1,
        "maXaPhuong": 31,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Giảng Võ",
        "tuDien": "K1HN",
        "chuDauTu": "SoXayDung",
        "loaiCot": "NOUVO",
        "chieuCao": 3,
        "tuDien2": "K1 Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82014878107545,
        "latitude": 21.023819247272314,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2207,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82034726454715,
          21.02399199957482
        ]
      },
      "properties": {
        "tenDoiTuon": "63",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 1,
        "maXaPhuong": 31,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Giảng Võ",
        "tuDien": "K1HN",
        "chuDauTu": "SoXayDung",
        "loaiCot": "NOUVO",
        "chieuCao": 3,
        "tuDien2": "K1 Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82034726454715,
        "latitude": 21.02399199957482,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2208,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82055111243437,
          21.024159744373325
        ]
      },
      "properties": {
        "tenDoiTuon": "62",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 1,
        "maXaPhuong": 31,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Giảng Võ",
        "tuDien": "K1HN",
        "chuDauTu": "SoXayDung",
        "loaiCot": "NOUVO",
        "chieuCao": 3,
        "tuDien2": "K1 Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82055111243437,
        "latitude": 21.024159744373325,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2209,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8209319861134,
          21.024475204229688
        ]
      },
      "properties": {
        "tenDoiTuon": "60",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 1,
        "maXaPhuong": 31,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Giảng Võ",
        "tuDien": "K1HN",
        "chuDauTu": "SoXayDung",
        "loaiCot": "NOUVO",
        "chieuCao": 3,
        "tuDien2": "K1 Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.8209319861134,
        "latitude": 21.024475204229688,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2210,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8212163002688,
          21.02470553957556
        ]
      },
      "properties": {
        "tenDoiTuon": "59",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 1,
        "maXaPhuong": 31,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Giảng Võ",
        "tuDien": "K1HN",
        "chuDauTu": "SoXayDung",
        "loaiCot": "NOUVO",
        "chieuCao": 3,
        "tuDien2": "K1 Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.8212163002688,
        "latitude": 21.02470553957556,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2211,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82141478373156,
          21.024875787211933
        ]
      },
      "properties": {
        "tenDoiTuon": "58",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 1,
        "maXaPhuong": 31,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Giảng Võ",
        "tuDien": "K1HN",
        "chuDauTu": "SoXayDung",
        "loaiCot": "NOUVO",
        "chieuCao": 3,
        "tuDien2": "K1 Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82141478373156,
        "latitude": 21.024875787211933,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2212,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82161863161875,
          21.025046034653002
        ]
      },
      "properties": {
        "tenDoiTuon": "57",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 1,
        "maXaPhuong": 31,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Giảng Võ",
        "tuDien": "K1HN",
        "chuDauTu": "SoXayDung",
        "loaiCot": "NOUVO",
        "chieuCao": 3,
        "tuDien2": "K1 Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82161863161875,
        "latitude": 21.025046034653002,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2213,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82181711508149,
          21.025223792803768
        ]
      },
      "properties": {
        "tenDoiTuon": "56",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 1,
        "maXaPhuong": 31,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Giảng Võ",
        "tuDien": "K1HN",
        "chuDauTu": "SoXayDung",
        "loaiCot": "NOUVO",
        "chieuCao": 3,
        "tuDien2": "K1 Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82181711508149,
        "latitude": 21.025223792803768,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2214,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82201023413774,
          21.025384025320506
        ]
      },
      "properties": {
        "tenDoiTuon": "55",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 1,
        "maXaPhuong": 31,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Giảng Võ",
        "tuDien": "K1HN",
        "chuDauTu": "SoXayDung",
        "loaiCot": "NOUVO",
        "chieuCao": 3,
        "tuDien2": "K1 Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82201023413774,
        "latitude": 21.025384025320506,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2215,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82220871760045,
          21.025556775810465
        ]
      },
      "properties": {
        "tenDoiTuon": "54",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 1,
        "maXaPhuong": 31,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Giảng Võ",
        "tuDien": "K1HN",
        "chuDauTu": "SoXayDung",
        "loaiCot": "NOUVO",
        "chieuCao": 3,
        "tuDien2": "K1 Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82220871760045,
        "latitude": 21.025556775810465,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2216,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82241792990315,
          21.02573703697764
        ]
      },
      "properties": {
        "tenDoiTuon": "53",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 1,
        "maXaPhuong": 31,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Giảng Võ",
        "tuDien": "K1HN",
        "chuDauTu": "SoXayDung",
        "loaiCot": "NOUVO",
        "chieuCao": 3,
        "tuDien2": "K1 Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82241792990315,
        "latitude": 21.02573703697764,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2217,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82262177779036,
          21.025904779812457
        ]
      },
      "properties": {
        "tenDoiTuon": "52",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 1,
        "maXaPhuong": 31,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Giảng Võ",
        "tuDien": "K1HN",
        "chuDauTu": "SoXayDung",
        "loaiCot": "NOUVO",
        "chieuCao": 3,
        "tuDien2": "K1 Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82262177779036,
        "latitude": 21.025904779812457,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2218,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82283635450854,
          21.02609004779876
        ]
      },
      "properties": {
        "tenDoiTuon": "51",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 1,
        "maXaPhuong": 31,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Giảng Võ",
        "tuDien": "K1HN",
        "chuDauTu": "SoXayDung",
        "loaiCot": "NOUVO",
        "chieuCao": 3,
        "tuDien2": "K1 Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82283635450854,
        "latitude": 21.02609004779876,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2219,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82304556681122,
          21.02626780470452
        ]
      },
      "properties": {
        "tenDoiTuon": "50",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 1,
        "maXaPhuong": 31,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Giảng Võ",
        "tuDien": "K1HN",
        "chuDauTu": "SoXayDung",
        "loaiCot": "NOUVO",
        "chieuCao": 3,
        "tuDien2": "K1 Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82304556681122,
        "latitude": 21.02626780470452,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2220,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82322795702751,
          21.026408007184312
        ]
      },
      "properties": {
        "tenDoiTuon": "49",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 1,
        "maXaPhuong": 31,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Giảng Võ",
        "tuDien": "K1HN",
        "chuDauTu": "SoXayDung",
        "loaiCot": "NOUVO",
        "chieuCao": 3,
        "tuDien2": "K1 Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82322795702751,
        "latitude": 21.026408007184312,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2221,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82343180491475,
          21.02658826732207
        ]
      },
      "properties": {
        "tenDoiTuon": "48",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 1,
        "maXaPhuong": 31,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Giảng Võ",
        "tuDien": "K1HN",
        "chuDauTu": "SoXayDung",
        "loaiCot": "NOUVO",
        "chieuCao": 3,
        "tuDien2": "K1 Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82343180491475,
        "latitude": 21.02658826732207,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2222,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82364101721741,
          21.026768527241817
        ]
      },
      "properties": {
        "tenDoiTuon": "47",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 1,
        "maXaPhuong": 31,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Giảng Võ",
        "tuDien": "K1HN",
        "chuDauTu": "SoXayDung",
        "loaiCot": "NOUVO",
        "chieuCao": 3,
        "tuDien2": "K1 Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82364101721741,
        "latitude": 21.026768527241817,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2223,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82384486510463,
          21.026941276127225
        ]
      },
      "properties": {
        "tenDoiTuon": "46",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 1,
        "maXaPhuong": 31,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Giảng Võ",
        "tuDien": "K1HN",
        "chuDauTu": "SoXayDung",
        "loaiCot": "NOUVO",
        "chieuCao": 3,
        "tuDien2": "K1 Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82384486510463,
        "latitude": 21.026941276127225,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2224,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82404871298284,
          21.02710651400356
        ]
      },
      "properties": {
        "tenDoiTuon": "45",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 1,
        "maXaPhuong": 31,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Giảng Võ",
        "tuDien": "K1HN",
        "chuDauTu": "SoXayDung",
        "loaiCot": "NOUVO",
        "chieuCao": 3,
        "tuDien2": "K1 Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82404871298284,
        "latitude": 21.02710651400356,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2225,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82423646761461,
          21.027266744497275
        ]
      },
      "properties": {
        "tenDoiTuon": "44",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 1,
        "maXaPhuong": 31,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Giảng Võ",
        "tuDien": "K1HN",
        "chuDauTu": "SoXayDung",
        "loaiCot": "NOUVO",
        "chieuCao": 3,
        "tuDien2": "K1 Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82423646761461,
        "latitude": 21.027266744497275,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2226,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82444031550182,
          21.02742947841577
        ]
      },
      "properties": {
        "tenDoiTuon": "43",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 1,
        "maXaPhuong": 31,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Giảng Võ",
        "tuDien": "K1HN",
        "chuDauTu": "SoXayDung",
        "loaiCot": "NOUVO",
        "chieuCao": 3,
        "tuDien2": "K1 Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82444031550182,
        "latitude": 21.02742947841577,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2227,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82466562105995,
          21.027624758883956
        ]
      },
      "properties": {
        "tenDoiTuon": "42",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 1,
        "maXaPhuong": 31,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Giảng Võ",
        "tuDien": "K1HN",
        "chuDauTu": "SoXayDung",
        "loaiCot": "NOUVO",
        "chieuCao": 3,
        "tuDien2": "K1 Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82466562105995,
        "latitude": 21.027624758883956,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2228,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8248641045227,
          21.027787492411573
        ]
      },
      "properties": {
        "tenDoiTuon": "41",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 1,
        "maXaPhuong": 28,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Giảng Võ",
        "tuDien": "K1HN",
        "chuDauTu": "SoXayDung",
        "loaiCot": "NOUVO",
        "chieuCao": 3,
        "tuDien2": "K1 Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.8248641045227,
        "latitude": 21.027787492411573,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2229,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82508672787307,
          21.02795523293882
        ]
      },
      "properties": {
        "tenDoiTuon": "40",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 1,
        "maXaPhuong": 28,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Giảng Võ",
        "tuDien": "K1HN",
        "chuDauTu": "SoXayDung",
        "loaiCot": "NOUVO",
        "chieuCao": 3,
        "tuDien2": "K1 Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82508672787307,
        "latitude": 21.02795523293882,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2230,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82530666901572,
          21.028117966105608
        ]
      },
      "properties": {
        "tenDoiTuon": "39",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 1,
        "maXaPhuong": 28,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Giảng Võ",
        "tuDien": "K1HN",
        "chuDauTu": "SoXayDung",
        "loaiCot": "NOUVO",
        "chieuCao": 3,
        "tuDien2": "K1 Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82530666901572,
        "latitude": 21.028117966105608,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2231,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82550247027072,
          21.02826567759505
        ]
      },
      "properties": {
        "tenDoiTuon": "38",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 1,
        "maXaPhuong": 28,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Giảng Võ",
        "tuDien": "K1HN",
        "chuDauTu": "SoXayDung",
        "loaiCot": "NOUVO",
        "chieuCao": 3,
        "tuDien2": "K1 Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82550247027072,
        "latitude": 21.02826567759505,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2232,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82569827152571,
          21.02840838177701
        ]
      },
      "properties": {
        "tenDoiTuon": "37",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 1,
        "maXaPhuong": 28,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Giảng Võ",
        "tuDien": "K1HN",
        "chuDauTu": "SoXayDung",
        "loaiCot": "NOUVO",
        "chieuCao": 3,
        "tuDien2": "K1 Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82569827152571,
        "latitude": 21.02840838177701,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2233,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82587797953425,
          21.028548582243268
        ]
      },
      "properties": {
        "tenDoiTuon": "36",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 1,
        "maXaPhuong": 28,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Giảng Võ",
        "tuDien": "K1HN",
        "chuDauTu": "SoXayDung",
        "loaiCot": "NOUVO",
        "chieuCao": 3,
        "tuDien2": "K1 Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82587797953425,
        "latitude": 21.028548582243268,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2234,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8264358790051,
          21.028974189995502
        ]
      },
      "properties": {
        "tenDoiTuon": "34",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 1,
        "maXaPhuong": 28,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Giảng Võ",
        "tuDien": "K1HN",
        "chuDauTu": "SoXayDung",
        "loaiCot": "NOUVO",
        "chieuCao": 3,
        "tuDien2": "K1 Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.8264358790051,
        "latitude": 21.028974189995502,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2235,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82672019316051,
          21.029171972007518
        ]
      },
      "properties": {
        "tenDoiTuon": "33",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 1,
        "maXaPhuong": 28,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Giảng Võ",
        "tuDien": "K1HN",
        "chuDauTu": "SoXayDung",
        "loaiCot": "NOUVO",
        "chieuCao": 3,
        "tuDien2": "K1 Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82672019316051,
        "latitude": 21.029171972007518,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2236,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82695086313413,
          21.029334703845763
        ]
      },
      "properties": {
        "tenDoiTuon": "32",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 1,
        "maXaPhuong": 28,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Giảng Võ",
        "tuDien": "K1HN",
        "chuDauTu": "SoXayDung",
        "loaiCot": "NOUVO",
        "chieuCao": 3,
        "tuDien2": "K1 Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82695086313413,
        "latitude": 21.029334703845763,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2237,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81837047649809,
          21.01084785680467
        ]
      },
      "properties": {
        "tenDoiTuon": "19-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ K",
        "tuDien": "VH7",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 7",
        "diaChiLapD": "",
        "longitude": 105.81837047649809,
        "latitude": 21.01084785680467,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2238,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81813310099612,
          21.011029387689337
        ]
      },
      "properties": {
        "tenDoiTuon": "20-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Thái Thịnh",
        "tuDien": "VH7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 7",
        "diaChiLapD": "",
        "longitude": 105.81813310099612,
        "latitude": 21.011029387689337,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2239,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81822563720814,
          21.01109323643083
        ]
      },
      "properties": {
        "tenDoiTuon": "20-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Thái Thịnh",
        "tuDien": "VH7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 7",
        "diaChiLapD": "",
        "longitude": 105.81822563720814,
        "latitude": 21.01109323643083,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2240,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8177441807388,
          21.011590254127775
        ]
      },
      "properties": {
        "tenDoiTuon": "22-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Thái Thịnh",
        "tuDien": "VH7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 7",
        "diaChiLapD": "",
        "longitude": 105.8177441807388,
        "latitude": 21.011590254127775,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2241,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81792657095507,
          21.011726714617303
        ]
      },
      "properties": {
        "tenDoiTuon": "22-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Thái Thịnh",
        "tuDien": "VH7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 7",
        "diaChiLapD": "",
        "longitude": 105.81792657095507,
        "latitude": 21.011726714617303,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2242,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8179359586822,
          21.011510130479618
        ]
      },
      "properties": {
        "tenDoiTuon": "22-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 2 Thịnh Liệt",
        "tuDien": "VH7",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 7",
        "diaChiLapD": "",
        "longitude": 105.8179359586822,
        "latitude": 21.011510130479618,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2243,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81797216850465,
          21.01140872392479
        ]
      },
      "properties": {
        "tenDoiTuon": "22-7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 2 Thịnh Liệt",
        "tuDien": "VH7",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 7",
        "diaChiLapD": "",
        "longitude": 105.81797216850465,
        "latitude": 21.01140872392479,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2244,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81807141023603,
          21.01130105763116
        ]
      },
      "properties": {
        "tenDoiTuon": "22-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 2 Thịnh Liệt",
        "tuDien": "VH7",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 7",
        "diaChiLapD": "",
        "longitude": 105.81807141023603,
        "latitude": 21.01130105763116,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2245,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81827391701937,
          21.01113580223913
        ]
      },
      "properties": {
        "tenDoiTuon": "22-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 2 Thịnh Liệt",
        "tuDien": "VH7",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 7",
        "diaChiLapD": "",
        "longitude": 105.81827391701937,
        "latitude": 21.01113580223913,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2246,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81827525812324,
          21.011349883053317
        ]
      },
      "properties": {
        "tenDoiTuon": "22-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 2 Thịnh Liệt",
        "tuDien": "VH7",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 7",
        "diaChiLapD": "",
        "longitude": 105.81827525812324,
        "latitude": 21.011349883053317,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2247,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82069528118585,
          21.023676538698457
        ]
      },
      "properties": {
        "tenDoiTuon": "4-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 325 Giảng Võ",
        "tuDien": "K1HN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "K1 Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82069528118585,
        "latitude": 21.023676538698457,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2248,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82099837080442,
          21.023498778703527
        ]
      },
      "properties": {
        "tenDoiTuon": "4-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 325 Giảng Võ",
        "tuDien": "K1HN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "K1 Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82099837080442,
        "latitude": 21.023498778703527,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2249,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8211727143885,
          21.023416157788233
        ]
      },
      "properties": {
        "tenDoiTuon": "4-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 325 Giảng Võ",
        "tuDien": "K1HN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "K1 Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.8211727143885,
        "latitude": 21.023416157788233,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2250,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82079720512496,
          21.02397697764546
        ]
      },
      "properties": {
        "tenDoiTuon": "5-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 307 Giảng Võ",
        "tuDien": "K1HN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "K1 Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82079720512496,
        "latitude": 21.02397697764546,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2251,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82090449348853,
          21.023861809454147
        ]
      },
      "properties": {
        "tenDoiTuon": "5-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 307 Giảng Võ",
        "tuDien": "K1HN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "K1 Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82090449348853,
        "latitude": 21.023861809454147,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2252,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82106810824165,
          21.023739130196162
        ]
      },
      "properties": {
        "tenDoiTuon": "5-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 307 Giảng Võ",
        "tuDien": "K1HN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "K1 Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82106810824165,
        "latitude": 21.023739130196162,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2253,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82143825308076,
          21.02404207267099
        ]
      },
      "properties": {
        "tenDoiTuon": "5-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 307 Giảng Võ",
        "tuDien": "K1HN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "K1 Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82143825308076,
        "latitude": 21.02404207267099,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2254,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82089376465757,
          21.023971970335356
        ]
      },
      "properties": {
        "tenDoiTuon": "5-8",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 307 Giảng Võ",
        "tuDien": "K1HN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 5,
        "tuDien2": "K1 Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82089376465757,
        "latitude": 21.023971970335356,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2255,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82080793396491,
          21.023724108238827
        ]
      },
      "properties": {
        "tenDoiTuon": "5-7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 307 Giảng Võ",
        "tuDien": "K1HN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "K1 Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82080793396491,
        "latitude": 21.023724108238827,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2256,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82115930334979,
          21.023638983787723
        ]
      },
      "properties": {
        "tenDoiTuon": "5-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 307 Giảng Võ",
        "tuDien": "K1HN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "K1 Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82115930334979,
        "latitude": 21.023638983787723,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2257,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82150262610247,
          21.02395444474607
        ]
      },
      "properties": {
        "tenDoiTuon": "5-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 307 Giảng Võ",
        "tuDien": "K1HN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "K1 Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82150262610247,
        "latitude": 21.02395444474607,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2258,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82145434633615,
          21.024247372180078
        ]
      },
      "properties": {
        "tenDoiTuon": "7-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 279 Giảng Võ",
        "tuDien": "K1HN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "K1 Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82145434633615,
        "latitude": 21.024247372180078,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2259,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8216742874788,
          21.024517766225078
        ]
      },
      "properties": {
        "tenDoiTuon": "7-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 279 Giảng Võ",
        "tuDien": "K1HN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "K1 Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.8216742874788,
        "latitude": 21.024517766225078,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2260,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82239311949502,
          21.02548667418882
        ]
      },
      "properties": {
        "tenDoiTuon": "12-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 189 Giảng Võ",
        "tuDien": "K1HN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "K1 Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82239311949502,
        "latitude": 21.02548667418882,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2261,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8225004078496,
          21.02536649989975
        ]
      },
      "properties": {
        "tenDoiTuon": "12-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 189 Giảng Võ",
        "tuDien": "K1HN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "K1 Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.8225004078496,
        "latitude": 21.02536649989975,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2262,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82272303120001,
          21.02514367648249
        ]
      },
      "properties": {
        "tenDoiTuon": "12-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 189 Giảng Võ",
        "tuDien": "K1HN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "K1 Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82272303120001,
        "latitude": 21.02514367648249,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2263,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82288932816084,
          21.025096107395118
        ]
      },
      "properties": {
        "tenDoiTuon": "12-8",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 189 Giảng Võ",
        "tuDien": "K1HN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "K1 Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82288932816084,
        "latitude": 21.025096107395118,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2264,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82244139925236,
          21.02525133278196
        ]
      },
      "properties": {
        "tenDoiTuon": "12-11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 189 Giảng Võ",
        "tuDien": "K1HN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "K1 Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82244139925236,
        "latitude": 21.02525133278196,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2265,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82230460659463,
          21.025096107395118
        ]
      },
      "properties": {
        "tenDoiTuon": "12-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 189 Giảng Võ",
        "tuDien": "K1HN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "K1 Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82230460659463,
        "latitude": 21.025096107395118,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2266,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8221946360233,
          21.024943385485447
        ]
      },
      "properties": {
        "tenDoiTuon": "12-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 189 Giảng Võ",
        "tuDien": "K1HN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "K1 Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.8221946360233,
        "latitude": 21.024943385485447,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2267,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82196128384194,
          21.024775641568734
        ]
      },
      "properties": {
        "tenDoiTuon": "12-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 189 Giảng Võ",
        "tuDien": "K1HN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "K1 Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82196128384194,
        "latitude": 21.024775641568734,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2268,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82244944588454,
          21.024923356371282
        ]
      },
      "properties": {
        "tenDoiTuon": "12-7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 189 Giảng Võ",
        "tuDien": "K1HN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "K1 Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82244944588454,
        "latitude": 21.024923356371282,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2269,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82262378946864,
          21.024838232604978
        ]
      },
      "properties": {
        "tenDoiTuon": "12-9",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 189 Giảng Võ",
        "tuDien": "K1HN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "K1 Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82262378946864,
        "latitude": 21.024838232604978,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2270,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82239043728728,
          21.02471305050708
        ]
      },
      "properties": {
        "tenDoiTuon": "12-10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 189 Giảng Võ",
        "tuDien": "K1HN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "K1 Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82239043728728,
        "latitude": 21.02471305050708,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2271,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82318437115622,
          21.025802131236244
        ]
      },
      "properties": {
        "tenDoiTuon": "15-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 187 Giảng Võ",
        "tuDien": "K1HN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 10,
        "tuDien2": "K1 Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82318437115622,
        "latitude": 21.025802131236244,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2272,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8260275127102,
          21.028258166846754
        ]
      },
      "properties": {
        "tenDoiTuon": "26-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 149 Giảng Võ",
        "tuDien": "K1HN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "K1 Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.8260275127102,
        "latitude": 21.028258166846754,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2273,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82689118400735,
          21.0287138183402
        ]
      },
      "properties": {
        "tenDoiTuon": "28-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 129 Giảng Võ",
        "tuDien": "K1HN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 5,
        "tuDien2": "K1 Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82689118400735,
        "latitude": 21.0287138183402,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2274,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82689386622411,
          21.028806450616727
        ]
      },
      "properties": {
        "tenDoiTuon": "28-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 129 Giảng Võ",
        "tuDien": "K1HN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "K1 Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82689386622411,
        "latitude": 21.028806450616727,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2275,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82673561588646,
          21.028698796885006
        ]
      },
      "properties": {
        "tenDoiTuon": "28-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 129 Giảng Võ",
        "tuDien": "K1HN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 5,
        "tuDien2": "K1 Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82673561588646,
        "latitude": 21.028698796885006,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2276,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82655322567915,
          21.028653732508726
        ]
      },
      "properties": {
        "tenDoiTuon": "28-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 129 Giảng Võ",
        "tuDien": "K1HN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "K1 Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82655322567915,
        "latitude": 21.028653732508726,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2277,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81702132534808,
          21.0122237302162
        ]
      },
      "properties": {
        "tenDoiTuon": "I17TC 19",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Thái Thịnh",
        "tuDien": "I17TC",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 10,
        "tuDien2": "I 17 Thành Công",
        "diaChiLapD": "",
        "longitude": 105.81702132534808,
        "latitude": 21.0122237302162,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2278,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81687112164266,
          21.012402755578
        ]
      },
      "properties": {
        "tenDoiTuon": "20",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Thái Thịnh",
        "tuDien": "I17TC",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 10,
        "tuDien2": "I 17 Thành Công",
        "diaChiLapD": "",
        "longitude": 105.81687112164266,
        "latitude": 21.012402755578,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2279,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81663911056519,
          21.012610574958114
        ]
      },
      "properties": {
        "tenDoiTuon": "21",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Thái Thịnh",
        "tuDien": "I17TC",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 10,
        "tuDien2": "I 17 Thành Công",
        "diaChiLapD": "",
        "longitude": 105.81663911056519,
        "latitude": 21.012610574958114,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2280,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8163990528465,
          21.012808378678837
        ]
      },
      "properties": {
        "tenDoiTuon": "22",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Thái Thịnh",
        "tuDien": "I17TC",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 10,
        "tuDien2": "I 17 Thành Công",
        "diaChiLapD": "",
        "longitude": 105.8163990528465,
        "latitude": 21.012808378678837,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2281,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81613217305042,
          21.013005556176832
        ]
      },
      "properties": {
        "tenDoiTuon": "23",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Thái Thịnh",
        "tuDien": "I17TC",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 10,
        "tuDien2": "I 17 Thành Công",
        "diaChiLapD": "",
        "longitude": 105.81613217305042,
        "latitude": 21.013005556176832,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2282,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81589747972028,
          21.01317456526091
        ]
      },
      "properties": {
        "tenDoiTuon": "24",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Thái Thịnh",
        "tuDien": "I17TC",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 10,
        "tuDien2": "I 17 Thành Công",
        "diaChiLapD": "",
        "longitude": 105.81589747972028,
        "latitude": 21.01317456526091,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2283,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81566815085054,
          21.01332604730748
        ]
      },
      "properties": {
        "tenDoiTuon": "25",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Thái Thịnh",
        "tuDien": "I17TC",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 10,
        "tuDien2": "I 17 Thành Công",
        "diaChiLapD": "",
        "longitude": 105.81566815085054,
        "latitude": 21.01332604730748,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2284,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81541199988541,
          21.0135301096552
        ]
      },
      "properties": {
        "tenDoiTuon": "26",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Thái Thịnh",
        "tuDien": "I17TC",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 10,
        "tuDien2": "I 17 Thành Công",
        "diaChiLapD": "",
        "longitude": 105.81541199988541,
        "latitude": 21.0135301096552,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2285,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81503950810782,
          21.01380396589488
        ]
      },
      "properties": {
        "tenDoiTuon": "27",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Thái Thịnh",
        "tuDien": "I17TC",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 10,
        "tuDien2": "I 17 Thành Công",
        "diaChiLapD": "",
        "longitude": 105.81503950810782,
        "latitude": 21.01380396589488,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2286,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81467204545852,
          21.01407437888077
        ]
      },
      "properties": {
        "tenDoiTuon": "28",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Thái Thịnh",
        "tuDien": "I17TC",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 10,
        "tuDien2": "I 17 Thành Công",
        "diaChiLapD": "",
        "longitude": 105.81467204545852,
        "latitude": 21.01407437888077,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2287,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81424825642867,
          21.01438234862467
        ]
      },
      "properties": {
        "tenDoiTuon": "29",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Thái Thịnh",
        "tuDien": "I17TC",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 10,
        "tuDien2": "I 17 Thành Công",
        "diaChiLapD": "",
        "longitude": 105.81424825642867,
        "latitude": 21.01438234862467,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2288,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81390728070434,
          21.01461739419531
        ]
      },
      "properties": {
        "tenDoiTuon": "I17TC 30",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Thái Thịnh",
        "tuDien": "I17TC",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 10,
        "tuDien2": "I 17 Thành Công",
        "diaChiLapD": "",
        "longitude": 105.81390728070434,
        "latitude": 21.01461739419531,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2289,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81708429324303,
          21.01234870416374
        ]
      },
      "properties": {
        "tenDoiTuon": "19-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 156",
        "tuDien": "I17TC",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "I 17 Thành Công",
        "diaChiLapD": "",
        "longitude": 105.81708429324303,
        "latitude": 21.01234870416374,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2290,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8170642407528,
          21.012547978783235
        ]
      },
      "properties": {
        "tenDoiTuon": "20-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 158",
        "tuDien": "I17TC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "I 17 Thành Công",
        "diaChiLapD": "",
        "longitude": 105.8170642407528,
        "latitude": 21.012547978783235,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2291,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81725199538457,
          21.01279210369262
        ]
      },
      "properties": {
        "tenDoiTuon": "20-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 158",
        "tuDien": "I17TC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "I 17 Thành Công",
        "diaChiLapD": "",
        "longitude": 105.81725199538457,
        "latitude": 21.01279210369262,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2292,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8168416174114,
          21.012827157492147
        ]
      },
      "properties": {
        "tenDoiTuon": "21-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 160",
        "tuDien": "I17TC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "I 17 Thành Công",
        "diaChiLapD": "",
        "longitude": 105.8168416174114,
        "latitude": 21.012827157492147,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2293,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8169261069912,
          21.01293482268426
        ]
      },
      "properties": {
        "tenDoiTuon": "21-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 160",
        "tuDien": "I17TC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "I 17 Thành Công",
        "diaChiLapD": "",
        "longitude": 105.8169261069912,
        "latitude": 21.01293482268426,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2294,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81693013030282,
          21.013014945567992
        ]
      },
      "properties": {
        "tenDoiTuon": "21-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 160",
        "tuDien": "I17TC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "I 17 Thành Công",
        "diaChiLapD": "",
        "longitude": 105.81693013030282,
        "latitude": 21.013014945567992,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2295,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.817072287385,
          21.01319772573385
        ]
      },
      "properties": {
        "tenDoiTuon": "21-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 160",
        "tuDien": "I17TC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "I 17 Thành Công",
        "diaChiLapD": "",
        "longitude": 105.817072287385,
        "latitude": 21.01319772573385,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2296,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81642051058031,
          21.012962364930583
        ]
      },
      "properties": {
        "tenDoiTuon": "22-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 172",
        "tuDien": "I17TC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "I 17 Thành Công",
        "diaChiLapD": "",
        "longitude": 105.81642051058031,
        "latitude": 21.012962364930583,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2297,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81655327992642,
          21.013112595273498
        ]
      },
      "properties": {
        "tenDoiTuon": "22-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 172",
        "tuDien": "I17TC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "I 17 Thành Công",
        "diaChiLapD": "",
        "longitude": 105.81655327992642,
        "latitude": 21.013112595273498,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2298,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81668470816865,
          21.013270336971342
        ]
      },
      "properties": {
        "tenDoiTuon": "22-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 172",
        "tuDien": "I17TC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "I 17 Thành Công",
        "diaChiLapD": "",
        "longitude": 105.81668470816865,
        "latitude": 21.013270336971342,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2299,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81680540757995,
          21.013419315087525
        ]
      },
      "properties": {
        "tenDoiTuon": "22-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 172",
        "tuDien": "I17TC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "I 17 Thành Công",
        "diaChiLapD": "",
        "longitude": 105.81680540757995,
        "latitude": 21.013419315087525,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2300,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81613485532105,
          21.013156412428508
        ]
      },
      "properties": {
        "tenDoiTuon": "23-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Thái Thịnh",
        "tuDien": "I17TC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "I 17 Thành Công",
        "diaChiLapD": "",
        "longitude": 105.81613485532105,
        "latitude": 21.013156412428508,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2301,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8162609191478,
          21.01330789449351
        ]
      },
      "properties": {
        "tenDoiTuon": "23-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Thái Thịnh",
        "tuDien": "I17TC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "I 17 Thành Công",
        "diaChiLapD": "",
        "longitude": 105.8162609191478,
        "latitude": 21.01330789449351,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2302,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81638430075783,
          21.013456872572167
        ]
      },
      "properties": {
        "tenDoiTuon": "23-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Thái Thịnh",
        "tuDien": "I17TC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "I 17 Thành Công",
        "diaChiLapD": "",
        "longitude": 105.81638430075783,
        "latitude": 21.013456872572167,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2303,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81651841120782,
          21.013617117734942
        ]
      },
      "properties": {
        "tenDoiTuon": "23-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Thái Thịnh",
        "tuDien": "I17TC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "I 17 Thành Công",
        "diaChiLapD": "",
        "longitude": 105.81651841120782,
        "latitude": 21.013617117734942,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2304,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81538786010556,
          21.013734797666853
        ]
      },
      "properties": {
        "tenDoiTuon": "26-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 196",
        "tuDien": "I17TC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "I 17 Thành Công",
        "diaChiLapD": "",
        "longitude": 105.81538786010556,
        "latitude": 21.013734797666853,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2305,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81555683925617,
          21.013936355635103
        ]
      },
      "properties": {
        "tenDoiTuon": "I17TC 26-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 196",
        "tuDien": "I17TC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "I 17 Thành Công",
        "diaChiLapD": "",
        "longitude": 105.81555683925617,
        "latitude": 21.013936355635103,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2306,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81440885379466,
          21.014021485625307
        ]
      },
      "properties": {
        "tenDoiTuon": "28-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 133 Thái Thịnh",
        "tuDien": "I17TC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "I 17 Thành Công",
        "diaChiLapD": "",
        "longitude": 105.81440885379466,
        "latitude": 21.014021485625307,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2307,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81428010776912,
          21.01381867586306
        ]
      },
      "properties": {
        "tenDoiTuon": "I17TC 28-1-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 133 Thái Thịnh",
        "tuDien": "I17TC",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "I 17 Thành Công",
        "diaChiLapD": "",
        "longitude": 105.81428010776912,
        "latitude": 21.01381867586306,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2308,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81447322681639,
          21.01431443257391
        ]
      },
      "properties": {
        "tenDoiTuon": "29-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 200",
        "tuDien": "I17TC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "I 17 Thành Công",
        "diaChiLapD": "",
        "longitude": 105.81447322681639,
        "latitude": 21.01431443257391,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2309,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81457246854777,
          21.014464661555014
        ]
      },
      "properties": {
        "tenDoiTuon": "29-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 200",
        "tuDien": "I17TC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "I 17 Thành Công",
        "diaChiLapD": "",
        "longitude": 105.81457246854777,
        "latitude": 21.014464661555014,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2310,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81466902807136,
          21.01460988275945
        ]
      },
      "properties": {
        "tenDoiTuon": "I17TC 29-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 200",
        "tuDien": "I17TC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "I 17 Thành Công",
        "diaChiLapD": "",
        "longitude": 105.81466902807136,
        "latitude": 21.01460988275945,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2311,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81470121458226,
          21.014199256919397
        ]
      },
      "properties": {
        "tenDoiTuon": "28-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 200A Thái Thịnh",
        "tuDien": "I17TC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 7,
        "tuDien2": "I 17 Thành Công",
        "diaChiLapD": "",
        "longitude": 105.81470121458226,
        "latitude": 21.014199256919397,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2312,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81487555816634,
          21.014414585245053
        ]
      },
      "properties": {
        "tenDoiTuon": "28-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 200A Thái Thịnh",
        "tuDien": "I17TC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 7,
        "tuDien2": "I 17 Thành Công",
        "diaChiLapD": "",
        "longitude": 105.81487555816634,
        "latitude": 21.014414585245053,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2313,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8148809225818,
          21.014179226361946
        ]
      },
      "properties": {
        "tenDoiTuon": "28-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 198 Thái Thịnh",
        "tuDien": "I17TC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 7,
        "tuDien2": "I 17 Thành Công",
        "diaChiLapD": "",
        "longitude": 105.8148809225818,
        "latitude": 21.014179226361946,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2314,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81507135942134,
          21.01439705853243
        ]
      },
      "properties": {
        "tenDoiTuon": "I17TC 28-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 198 Thái Thịnh",
        "tuDien": "I17TC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 7,
        "tuDien2": "I 17 Thành Công",
        "diaChiLapD": "",
        "longitude": 105.81507135942134,
        "latitude": 21.01439705853243,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2315,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81612747922729,
          21.01405653913675
        ]
      },
      "properties": {
        "tenDoiTuon": "01",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 151B Thái Hà",
        "tuDien": "I17TC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "BTLT",
        "chieuCao": 7,
        "tuDien2": "I 17 Thành Công",
        "diaChiLapD": "",
        "longitude": 105.81612747922729,
        "latitude": 21.01405653913675,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2316,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81647147252741,
          21.0137898818648
        ]
      },
      "properties": {
        "tenDoiTuon": "02",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 151B Thái Hà",
        "tuDien": "I17TC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "BTLT",
        "chieuCao": 7,
        "tuDien2": "I 17 Thành Công",
        "diaChiLapD": "",
        "longitude": 105.81647147252741,
        "latitude": 21.0137898818648,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2317,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81684429958321,
          21.0134718955706
        ]
      },
      "properties": {
        "tenDoiTuon": "03",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 151B Thái Hà",
        "tuDien": "I17TC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "BTLT",
        "chieuCao": 7,
        "tuDien2": "I 17 Thành Công",
        "diaChiLapD": "",
        "longitude": 105.81684429958321,
        "latitude": 21.0134718955706,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2318,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81710715605873,
          21.01325781780192
        ]
      },
      "properties": {
        "tenDoiTuon": "04",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 151B Thái Hà",
        "tuDien": "I17TC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "BTLT",
        "chieuCao": 7,
        "tuDien2": "I 17 Thành Công",
        "diaChiLapD": "",
        "longitude": 105.81710715605873,
        "latitude": 21.01325781780192,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2319,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81663341085351,
          21.013770790199114
        ]
      },
      "properties": {
        "tenDoiTuon": "05",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 161 Thái Hà",
        "tuDien": "I17TC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "I 17 Thành Công",
        "diaChiLapD": "",
        "longitude": 105.81663341085351,
        "latitude": 21.013770790199114,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2320,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.816834576533,
          21.013706942603733
        ]
      },
      "properties": {
        "tenDoiTuon": "06",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 161 Thái Hà",
        "tuDien": "I17TC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "BTLT",
        "chieuCao": 7,
        "tuDien2": "I 17 Thành Công",
        "diaChiLapD": "",
        "longitude": 105.816834576533,
        "latitude": 21.013706942603733,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2321,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8172422723074,
          21.01370193494787
        ]
      },
      "properties": {
        "tenDoiTuon": "07",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 161 Thái Hà",
        "tuDien": "I17TC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "BTLT",
        "chieuCao": 7,
        "tuDien2": "I 17 Thành Công",
        "diaChiLapD": "",
        "longitude": 105.8172422723074,
        "latitude": 21.01370193494787,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2322,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81759230057942,
          21.013689415807942
        ]
      },
      "properties": {
        "tenDoiTuon": "08",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 161 Thái Hà",
        "tuDien": "I17TC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "BTLT",
        "chieuCao": 7,
        "tuDien2": "I 17 Thành Công",
        "diaChiLapD": "",
        "longitude": 105.81759230057942,
        "latitude": 21.013689415807942,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2323,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81666660317208,
          21.014638676617867
        ]
      },
      "properties": {
        "tenDoiTuon": "14",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 151A Thái Hà",
        "tuDien": "I17TC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 7,
        "tuDien2": "I 17 Thành Công",
        "diaChiLapD": "",
        "longitude": 105.81666660317208,
        "latitude": 21.014638676617867,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2324,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81691336641909,
          21.014432111970724
        ]
      },
      "properties": {
        "tenDoiTuon": "15",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 151A Thái Hà",
        "tuDien": "I17TC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 7,
        "tuDien2": "I 17 Thành Công",
        "diaChiLapD": "",
        "longitude": 105.81691336641909,
        "latitude": 21.014432111970724,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2325,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81708502779543,
          21.01429440204703
        ]
      },
      "properties": {
        "tenDoiTuon": "16",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 151A Thái Hà",
        "tuDien": "I17TC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "BTLT",
        "chieuCao": 7,
        "tuDien2": "I 17 Thành Công",
        "diaChiLapD": "",
        "longitude": 105.81708502779543,
        "latitude": 21.01429440204703,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2326,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81733849654384,
          21.014101607940432
        ]
      },
      "properties": {
        "tenDoiTuon": "17",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 151A Thái Hà",
        "tuDien": "I17TC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "BTLT",
        "chieuCao": 7,
        "tuDien2": "I 17 Thành Công",
        "diaChiLapD": "",
        "longitude": 105.81733849654384,
        "latitude": 21.014101607940432,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2327,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81759196528324,
          21.01388001960036
        ]
      },
      "properties": {
        "tenDoiTuon": "18",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 151A Thái Hà",
        "tuDien": "I17TC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "BTLT",
        "chieuCao": 7,
        "tuDien2": "I 17 Thành Công",
        "diaChiLapD": "",
        "longitude": 105.81759196528324,
        "latitude": 21.01388001960036,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2328,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84004138429819,
          21.017107414963473
        ]
      },
      "properties": {
        "tenDoiTuon": "14",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 202,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "",
        "tuDien": "KTG",
        "chuDauTu": "",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên Ga",
        "diaChiLapD": "",
        "longitude": 105.84004138429819,
        "latitude": 21.017107414963473,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2329,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8401164861509,
          21.01782098791252
        ]
      },
      "properties": {
        "tenDoiTuon": "12",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 202,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "",
        "tuDien": "KTG",
        "chuDauTu": "",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên Ga",
        "diaChiLapD": "",
        "longitude": 105.8401164861509,
        "latitude": 21.01782098791252,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2330,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84012721498186,
          21.018071363576848
        ]
      },
      "properties": {
        "tenDoiTuon": "11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 202,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "",
        "tuDien": "KTG",
        "chuDauTu": "",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên Ga",
        "diaChiLapD": "",
        "longitude": 105.84012721498186,
        "latitude": 21.018071363576848,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2331,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84013526160508,
          21.018344272571483
        ]
      },
      "properties": {
        "tenDoiTuon": "10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 202,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "",
        "tuDien": "KTG",
        "chuDauTu": "",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên Ga",
        "diaChiLapD": "",
        "longitude": 105.84013526160508,
        "latitude": 21.018344272571483,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2332,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8401433082373,
          21.018659744732687
        ]
      },
      "properties": {
        "tenDoiTuon": "9",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 202,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "",
        "tuDien": "KTG",
        "chuDauTu": "",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên Ga",
        "diaChiLapD": "",
        "longitude": 105.8401433082373,
        "latitude": 21.018659744732687,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2333,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84004674871366,
          21.017437912332692
        ]
      },
      "properties": {
        "tenDoiTuon": "13",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 202,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "",
        "tuDien": "KTG",
        "chuDauTu": "",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên Ga",
        "diaChiLapD": "",
        "longitude": 105.84004674871366,
        "latitude": 21.017437912332692,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2334,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84021572788227,
          21.017445423627827
        ]
      },
      "properties": {
        "tenDoiTuon": "13-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 202,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "",
        "tuDien": "KTG",
        "chuDauTu": "",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên Ga",
        "diaChiLapD": "",
        "longitude": 105.84021572788227,
        "latitude": 21.017445423627827,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2335,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84084872920582,
          21.017437912332692
        ]
      },
      "properties": {
        "tenDoiTuon": "13-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 202,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "",
        "tuDien": "KTG",
        "chuDauTu": "",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên Ga",
        "diaChiLapD": "",
        "longitude": 105.84084872920582,
        "latitude": 21.017437912332692,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2336,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84023450333645,
          21.017068606512172
        ]
      },
      "properties": {
        "tenDoiTuon": "14-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 202,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "",
        "tuDien": "KTG",
        "chuDauTu": "",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên Ga",
        "diaChiLapD": "",
        "longitude": 105.84023450333645,
        "latitude": 21.017068606512172,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2337,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84056441505042,
          21.017445423627827
        ]
      },
      "properties": {
        "tenDoiTuon": "13-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 202,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "",
        "tuDien": "KTG",
        "chuDauTu": "",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên Ga",
        "diaChiLapD": "",
        "longitude": 105.84056441505042,
        "latitude": 21.017445423627827,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2338,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84040080030631,
          21.017452934923405
        ]
      },
      "properties": {
        "tenDoiTuon": "13-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 202,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "",
        "tuDien": "KTG",
        "chuDauTu": "",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên Ga",
        "diaChiLapD": "",
        "longitude": 105.84040080030631,
        "latitude": 21.017452934923405,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2339,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84089164451173,
          21.01770957060691
        ]
      },
      "properties": {
        "tenDoiTuon": "24",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 202,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "",
        "tuDien": "KTG",
        "chuDauTu": "",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên Ga",
        "diaChiLapD": "",
        "longitude": 105.84089164451173,
        "latitude": 21.01770957060691,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2340,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84004540756489,
          21.016721833773772
        ]
      },
      "properties": {
        "tenDoiTuon": "15",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 202,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "",
        "tuDien": "KTG",
        "chuDauTu": "",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên Ga",
        "diaChiLapD": "",
        "longitude": 105.84004540756489,
        "latitude": 21.016721833773772,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2341,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84056575610938,
          21.017101155536988
        ]
      },
      "properties": {
        "tenDoiTuon": "14-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 202,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "",
        "tuDien": "KTG",
        "chuDauTu": "",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên Ga",
        "diaChiLapD": "",
        "longitude": 105.84056575610938,
        "latitude": 21.017101155536988,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2342,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84060598925245,
          21.01719629879741
        ]
      },
      "properties": {
        "tenDoiTuon": "14-2-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 202,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "",
        "tuDien": "KTG",
        "chuDauTu": "",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên Ga",
        "diaChiLapD": "",
        "longitude": 105.84060598925245,
        "latitude": 21.01719629879741,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2343,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84071730091867,
          21.01719629879741
        ]
      },
      "properties": {
        "tenDoiTuon": "14-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 202,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "",
        "tuDien": "KTG",
        "chuDauTu": "",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên Ga",
        "diaChiLapD": "",
        "longitude": 105.84071730091867,
        "latitude": 21.01719629879741,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2344,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84089030340786,
          21.017192543143622
        ]
      },
      "properties": {
        "tenDoiTuon": "14-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 202,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "",
        "tuDien": "KTG",
        "chuDauTu": "",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên Ga",
        "diaChiLapD": "",
        "longitude": 105.84089030340786,
        "latitude": 21.017192543143622,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2345,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84093858316518,
          21.01700350844208
        ]
      },
      "properties": {
        "tenDoiTuon": "20-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 202,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "",
        "tuDien": "KTG",
        "chuDauTu": "",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên Ga",
        "diaChiLapD": "",
        "longitude": 105.84093858316518,
        "latitude": 21.01700350844208,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2346,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84119741633805,
          21.01676064231531
        ]
      },
      "properties": {
        "tenDoiTuon": "21",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 202,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "",
        "tuDien": "KTG",
        "chuDauTu": "",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên Ga",
        "diaChiLapD": "",
        "longitude": 105.84119741633805,
        "latitude": 21.01676064231531,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2347,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84111829117371,
          21.017011019759924
        ]
      },
      "properties": {
        "tenDoiTuon": "22",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 202,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "",
        "tuDien": "KTG",
        "chuDauTu": "",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên Ga",
        "diaChiLapD": "",
        "longitude": 105.84111829117371,
        "latitude": 21.017011019759924,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2348,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84102709606559,
          21.017273915623434
        ]
      },
      "properties": {
        "tenDoiTuon": "23",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 202,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "",
        "tuDien": "KTG",
        "chuDauTu": "",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên Ga",
        "diaChiLapD": "",
        "longitude": 105.84102709606559,
        "latitude": 21.017273915623434,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2349,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84081352519102,
          21.016707750028452
        ]
      },
      "properties": {
        "tenDoiTuon": "20",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 202,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "",
        "tuDien": "KTG",
        "chuDauTu": "",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên Ga",
        "diaChiLapD": "",
        "longitude": 105.84081352519102,
        "latitude": 21.016707750028452,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2350,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84023685025701,
          21.01580388363633
        ]
      },
      "properties": {
        "tenDoiTuon": "18-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 202,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "",
        "tuDien": "KTG",
        "chuDauTu": "",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên Ga",
        "diaChiLapD": "",
        "longitude": 105.84023685025701,
        "latitude": 21.01580388363633,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2351,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84016845392365,
          21.016020461539224
        ]
      },
      "properties": {
        "tenDoiTuon": "18",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 202,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "",
        "tuDien": "KTG",
        "chuDauTu": "",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên Ga",
        "diaChiLapD": "",
        "longitude": 105.84016845392365,
        "latitude": 21.016020461539224,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2352,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83983317780319,
          21.01621700884383
        ]
      },
      "properties": {
        "tenDoiTuon": "17-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 202,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "",
        "tuDien": "KTG",
        "chuDauTu": "",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên Ga",
        "diaChiLapD": "",
        "longitude": 105.83983317780319,
        "latitude": 21.01621700884383,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2353,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84000752138725,
          21.016259573194112
        ]
      },
      "properties": {
        "tenDoiTuon": "17",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 202,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "",
        "tuDien": "KTG",
        "chuDauTu": "",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên Ga",
        "diaChiLapD": "",
        "longitude": 105.84000752138725,
        "latitude": 21.016259573194112,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2354,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84004239010588,
          21.01648491367048
        ]
      },
      "properties": {
        "tenDoiTuon": "16",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 202,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "",
        "tuDien": "KTG",
        "chuDauTu": "",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên Ga",
        "diaChiLapD": "",
        "longitude": 105.84004239010588,
        "latitude": 21.01648491367048,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2355,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83971784281637,
          21.016434838038855
        ]
      },
      "properties": {
        "tenDoiTuon": "15-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 202,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "",
        "tuDien": "KTG",
        "chuDauTu": "",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên Ga",
        "diaChiLapD": "",
        "longitude": 105.83971784281637,
        "latitude": 21.016434838038855,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2356,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83975137042214,
          21.016598835670017
        ]
      },
      "properties": {
        "tenDoiTuon": "15-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "",
        "tuDien": "KTG",
        "chuDauTu": "",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên Ga",
        "diaChiLapD": "",
        "longitude": 105.83975137042214,
        "latitude": 21.016598835670017,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2357,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83996997046091,
          21.01663138479736
        ]
      },
      "properties": {
        "tenDoiTuon": "15-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 202,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "",
        "tuDien": "KTG",
        "chuDauTu": "",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên Ga",
        "diaChiLapD": "",
        "longitude": 105.83996997046091,
        "latitude": 21.01663138479736,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2358,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8404406981442,
          21.016714009472278
        ]
      },
      "properties": {
        "tenDoiTuon": "19",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 202,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "",
        "tuDien": "KTG",
        "chuDauTu": "",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên Ga",
        "diaChiLapD": "",
        "longitude": 105.8404406981442,
        "latitude": 21.016714009472278,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2359,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84009335207097,
          21.015731273632248
        ]
      },
      "properties": {
        "tenDoiTuon": "17-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 202,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 1 Khâm Thiên",
        "tuDien": "KTG",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên Ga",
        "diaChiLapD": "",
        "longitude": 105.84009335207097,
        "latitude": 21.015731273632248,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2360,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84001959133109,
          21.015907790992706
        ]
      },
      "properties": {
        "tenDoiTuon": "17-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 202,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 1 Khâm Thiên",
        "tuDien": "KTG",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên Ga",
        "diaChiLapD": "",
        "longitude": 105.84001959133109,
        "latitude": 21.015907790992706,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2361,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83983183669932,
          21.01621951262996
        ]
      },
      "properties": {
        "tenDoiTuon": "",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 202,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 1 Khâm Thiên",
        "tuDien": "KTG",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên Ga",
        "diaChiLapD": "",
        "longitude": 105.83983183669932,
        "latitude": 21.01621951262996,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2362,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84069316108491,
          21.01903780969706
        ]
      },
      "properties": {
        "tenDoiTuon": "3-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 202,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 2b Khâm Thiên",
        "tuDien": "KTG",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên Ga",
        "diaChiLapD": "",
        "longitude": 105.84069316108491,
        "latitude": 21.01903780969706,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2363,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83827213216072,
          21.018913561629766
        ]
      },
      "properties": {
        "tenDoiTuon": "1-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 202,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Sân Quần",
        "tuDien": "KTH1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 1",
        "diaChiLapD": "",
        "longitude": 105.83827213216072,
        "latitude": 21.018913561629766,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2364,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83826408552852,
          21.018760833386786
        ]
      },
      "properties": {
        "tenDoiTuon": "1-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 202,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Sân Quần",
        "tuDien": "KTH1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 1",
        "diaChiLapD": "",
        "longitude": 105.83826408552852,
        "latitude": 21.018760833386786,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2365,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.838269449944,
          21.01857430441991
        ]
      },
      "properties": {
        "tenDoiTuon": "1-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 202,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Sân Quần",
        "tuDien": "KTH1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 1",
        "diaChiLapD": "",
        "longitude": 105.838269449944,
        "latitude": 21.01857430441991,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2366,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83829627203939,
          21.018464139550524
        ]
      },
      "properties": {
        "tenDoiTuon": "1-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 202,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Sân Quần",
        "tuDien": "KTH1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 1",
        "diaChiLapD": "",
        "longitude": 105.83829627203939,
        "latitude": 21.018464139550524,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2367,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.838042803282,
          21.018606853115234
        ]
      },
      "properties": {
        "tenDoiTuon": "1-3-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Sân Quần",
        "tuDien": "KTH1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 1",
        "diaChiLapD": "",
        "longitude": 105.838042803282,
        "latitude": 21.018606853115234,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2368,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83804146217814,
          21.01848917702855
        ]
      },
      "properties": {
        "tenDoiTuon": "1-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Sân Quần",
        "tuDien": "KTH1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 1",
        "diaChiLapD": "",
        "longitude": 105.83804146217814,
        "latitude": 21.01848917702855,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2369,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84058319051361,
          21.019295694519357
        ]
      },
      "properties": {
        "tenDoiTuon": "3-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 202,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 2b Khâm Thiên",
        "tuDien": "KTG",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên Ga",
        "diaChiLapD": "",
        "longitude": 105.84058319051361,
        "latitude": 21.019295694519357,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2370,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8406945021888,
          21.019225589951972
        ]
      },
      "properties": {
        "tenDoiTuon": "3-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 202,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 2b Khâm Thiên",
        "tuDien": "KTG",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên Ga",
        "diaChiLapD": "",
        "longitude": 105.8406945021888,
        "latitude": 21.019225589951972,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2371,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8380602376413,
          21.018351470851094
        ]
      },
      "properties": {
        "tenDoiTuon": "1-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Sân Quần",
        "tuDien": "KTH1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 8,
        "tuDien2": "Khâm Thiên 1",
        "diaChiLapD": "",
        "longitude": 105.8380602376413,
        "latitude": 21.018351470851094,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2372,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84032033401115,
          21.019228093686753
        ]
      },
      "properties": {
        "tenDoiTuon": "5-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 202,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 2b Khâm Thiên",
        "tuDien": "KTG",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên Ga",
        "diaChiLapD": "",
        "longitude": 105.84032033401115,
        "latitude": 21.019228093686753,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2373,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83980937321172,
          21.01978892929951
        ]
      },
      "properties": {
        "tenDoiTuon": "6-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 202,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 2b Khâm Thiên",
        "tuDien": "KTG",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên Ga",
        "diaChiLapD": "",
        "longitude": 105.83980937321172,
        "latitude": 21.01978892929951,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2374,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83968465048885,
          21.019800196065255
        ]
      },
      "properties": {
        "tenDoiTuon": "6-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 202,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 2b Khâm Thiên",
        "tuDien": "KTG",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên Ga",
        "diaChiLapD": "",
        "longitude": 105.83968465048885,
        "latitude": 21.019800196065255,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2375,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83981741983496,
          21.019641209410565
        ]
      },
      "properties": {
        "tenDoiTuon": "6-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 202,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 2b Khâm Thiên",
        "tuDien": "KTG",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên Ga",
        "diaChiLapD": "",
        "longitude": 105.83981741983496,
        "latitude": 21.019641209410565,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2376,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83962698299543,
          21.019705054465366
        ]
      },
      "properties": {
        "tenDoiTuon": "6-7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 202,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 2b Khâm Thiên",
        "tuDien": "KTG",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên Ga",
        "diaChiLapD": "",
        "longitude": 105.83962698299543,
        "latitude": 21.019705054465366,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2377,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83957467991752,
          21.01931822812539
        ]
      },
      "properties": {
        "tenDoiTuon": "6-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 202,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 2b Khâm Thiên",
        "tuDien": "KTG",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên Ga",
        "diaChiLapD": "",
        "longitude": 105.83957467991752,
        "latitude": 21.01931822812539,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2378,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83981205541946,
          21.019442162887117
        ]
      },
      "properties": {
        "tenDoiTuon": "6-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 202,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 2b Khâm Thiên",
        "tuDien": "KTG",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên Ga",
        "diaChiLapD": "",
        "longitude": 105.83981205541946,
        "latitude": 21.019442162887117,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2379,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83980669100399,
          21.019157989088413
        ]
      },
      "properties": {
        "tenDoiTuon": "6-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 202,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 2b Khâm Thiên",
        "tuDien": "KTG",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên Ga",
        "diaChiLapD": "",
        "longitude": 105.83980669100399,
        "latitude": 21.019157989088413,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2380,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83772747609396,
          21.01887365826168
        ]
      },
      "properties": {
        "tenDoiTuon": "12",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 202,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Tô Tiền",
        "tuDien": "KTH1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 1",
        "diaChiLapD": "",
        "longitude": 105.83772747609396,
        "latitude": 21.01887365826168,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2381,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83769663068696,
          21.018773508585067
        ]
      },
      "properties": {
        "tenDoiTuon": "13",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Tô Tiền",
        "tuDien": "KTH1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 1",
        "diaChiLapD": "",
        "longitude": 105.83769663068696,
        "latitude": 21.018773508585067,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2382,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83768321963927,
          21.018370405457652
        ]
      },
      "properties": {
        "tenDoiTuon": "15",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Tô Tiền",
        "tuDien": "KTH1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 1",
        "diaChiLapD": "",
        "longitude": 105.83768321963927,
        "latitude": 21.018370405457652,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2383,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83783074113694,
          21.018277766697103
        ]
      },
      "properties": {
        "tenDoiTuon": "15-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Tô Tiền",
        "tuDien": "KTH1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 1",
        "diaChiLapD": "",
        "longitude": 105.83783074113694,
        "latitude": 21.018277766697103,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2384,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83920487033868,
          21.01941180513989
        ]
      },
      "properties": {
        "tenDoiTuon": "7-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 202,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 2b Khâm Thiên",
        "tuDien": "KTG",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên Ga",
        "diaChiLapD": "",
        "longitude": 105.83920487033868,
        "latitude": 21.01941180513989,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2385,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83766578527997,
          21.018555682806994
        ]
      },
      "properties": {
        "tenDoiTuon": "14",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Tô Tiền",
        "tuDien": "KTH1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 1",
        "diaChiLapD": "",
        "longitude": 105.83766578527997,
        "latitude": 21.018555682806994,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2386,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8389151917678,
          21.019834935253357
        ]
      },
      "properties": {
        "tenDoiTuon": "7-9-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 202,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Đền Tương Thuận",
        "tuDien": "KTG",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên Ga",
        "diaChiLapD": "",
        "longitude": 105.8389151917678,
        "latitude": 21.019834935253357,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2387,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83861746656471,
          21.01993258049333
        ]
      },
      "properties": {
        "tenDoiTuon": "7-10-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 202,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Đền Tương Thuận",
        "tuDien": "KTG",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên Ga",
        "diaChiLapD": "",
        "longitude": 105.83861746656471,
        "latitude": 21.01993258049333,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2388,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83865099417945,
          21.019832431527913
        ]
      },
      "properties": {
        "tenDoiTuon": "7-10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 202,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Đền Tương Thuận",
        "tuDien": "KTG",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên Ga",
        "diaChiLapD": "",
        "longitude": 105.83865099417945,
        "latitude": 21.019832431527913,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2389,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83879315125266,
          21.019708497090576
        ]
      },
      "properties": {
        "tenDoiTuon": "7-9",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 202,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Đền Tương Thuận",
        "tuDien": "KTG",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên Ga",
        "diaChiLapD": "",
        "longitude": 105.83879315125266,
        "latitude": 21.019708497090576,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2390,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83895676600577,
          21.01954450287845
        ]
      },
      "properties": {
        "tenDoiTuon": "7-8",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 202,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Đền Tương Thuận",
        "tuDien": "KTG",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên Ga",
        "diaChiLapD": "",
        "longitude": 105.83895676600577,
        "latitude": 21.01954450287845,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2391,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83912172186275,
          21.019789868197446
        ]
      },
      "properties": {
        "tenDoiTuon": "7-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 202,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Đền Tương Thuận",
        "tuDien": "KTG",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên Ga",
        "diaChiLapD": "",
        "longitude": 105.83912172186275,
        "latitude": 21.019789868197446,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2392,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8393510507325,
          21.019693474727937
        ]
      },
      "properties": {
        "tenDoiTuon": "7-7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 202,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Đền Tương Thuận",
        "tuDien": "KTG",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên Ga",
        "diaChiLapD": "",
        "longitude": 105.8393510507325,
        "latitude": 21.019693474727937,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2393,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83919682371545,
          21.019714756408455
        ]
      },
      "properties": {
        "tenDoiTuon": "7-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 202,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Đền Tương Thuận",
        "tuDien": "KTG",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên Ga",
        "diaChiLapD": "",
        "longitude": 105.83919682371545,
        "latitude": 21.019714756408455,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2394,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8392933832391,
          21.019541999148984
        ]
      },
      "properties": {
        "tenDoiTuon": "7-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 202,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Đền Tương Thuận",
        "tuDien": "KTG",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên Ga",
        "diaChiLapD": "",
        "longitude": 105.8392933832391,
        "latitude": 21.019541999148984,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2395,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83927594887977,
          21.019394279014577
        ]
      },
      "properties": {
        "tenDoiTuon": "7-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 202,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Đền Tương Thuận",
        "tuDien": "KTG",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên Ga",
        "diaChiLapD": "",
        "longitude": 105.83927594887977,
        "latitude": 21.019394279014577,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2396,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83920084702706,
          21.019140149966752
        ]
      },
      "properties": {
        "tenDoiTuon": "7-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 202,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Đền Tương Thuận",
        "tuDien": "KTG",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên Ga",
        "diaChiLapD": "",
        "longitude": 105.83920084702706,
        "latitude": 21.019140149966752,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2397,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83766176196836,
          21.018260240438465
        ]
      },
      "properties": {
        "tenDoiTuon": "16",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Tô Tiền",
        "tuDien": "KTH1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 1",
        "diaChiLapD": "",
        "longitude": 105.83766176196836,
        "latitude": 21.018260240438465,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2398,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83749261516515,
          21.018321425850896
        ]
      },
      "properties": {
        "tenDoiTuon": "17",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Khâm Đức",
        "tuDien": "KTH1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 1",
        "diaChiLapD": "",
        "longitude": 105.83749261516515,
        "latitude": 21.018321425850896,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2399,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83731022495785,
          21.018394034592998
        ]
      },
      "properties": {
        "tenDoiTuon": "19",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Khâm Đức",
        "tuDien": "KTH1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 8,
        "tuDien2": "Khâm Thiên 1",
        "diaChiLapD": "",
        "longitude": 105.83731022495785,
        "latitude": 21.018394034592998,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2400,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81782492897918,
          21.013933491096683
        ]
      },
      "properties": {
        "tenDoiTuon": "09",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 161 Thái Hà",
        "tuDien": "I17TC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "BTLT",
        "chieuCao": 7,
        "tuDien2": "I 17 Thành Công",
        "diaChiLapD": "",
        "longitude": 105.81782492897918,
        "latitude": 21.013933491096683,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2401,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81797781489233,
          21.014126285421444
        ]
      },
      "properties": {
        "tenDoiTuon": "I17TC 10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 161 Thái Hà",
        "tuDien": "I17TC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "BTLT",
        "chieuCao": 7,
        "tuDien2": "I 17 Thành Công",
        "diaChiLapD": "",
        "longitude": 105.81797781489233,
        "latitude": 21.014126285421444,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2402,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81749849195772,
          21.014296856332027
        ]
      },
      "properties": {
        "tenDoiTuon": "17-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "",
        "tuDien": "I17TC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "BTLT",
        "chieuCao": 7,
        "tuDien2": "I 17 Thành Công",
        "diaChiLapD": "",
        "longitude": 105.81749849195772,
        "latitude": 21.014296856332027,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2403,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81778548832085,
          21.01413786381009
        ]
      },
      "properties": {
        "tenDoiTuon": "I17TC 17-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "",
        "tuDien": "I17TC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "BTLT",
        "chieuCao": 7,
        "tuDien2": "I 17 Thành Công",
        "diaChiLapD": "",
        "longitude": 105.81778548832085,
        "latitude": 21.01413786381009,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2404,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81690706487211,
          21.014122840886284
        ]
      },
      "properties": {
        "tenDoiTuon": "I17TC 16-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 151A Thái Hà",
        "tuDien": "I17TC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 7,
        "tuDien2": "I 17 Thành Công",
        "diaChiLapD": "",
        "longitude": 105.81690706487211,
        "latitude": 21.014122840886284,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2405,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8372190298497,
          21.01849919201858
        ]
      },
      "properties": {
        "tenDoiTuon": "26",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Cống Trắng",
        "tuDien": "KTH1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 1",
        "diaChiLapD": "",
        "longitude": 105.8372190298497,
        "latitude": 21.01849919201858,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2406,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8372498752567,
          21.018573052547442
        ]
      },
      "properties": {
        "tenDoiTuon": "26-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Cống Trắng",
        "tuDien": "KTH1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 1",
        "diaChiLapD": "",
        "longitude": 105.8372498752567,
        "latitude": 21.018573052547442,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2407,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83751541394892,
          21.01842908707673
        ]
      },
      "properties": {
        "tenDoiTuon": "19-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Cống Trắng",
        "tuDien": "KTH1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 1",
        "diaChiLapD": "",
        "longitude": 105.83751541394892,
        "latitude": 21.01842908707673,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2408,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83732497710042,
          21.018718269751275
        ]
      },
      "properties": {
        "tenDoiTuon": "18",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Cống Trắng",
        "tuDien": "KTH1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 1",
        "diaChiLapD": "",
        "longitude": 105.83732497710042,
        "latitude": 21.018718269751275,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2409,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83723109979353,
          21.018777107716662
        ]
      },
      "properties": {
        "tenDoiTuon": "18-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 202,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Cống Trắng",
        "tuDien": "KTH1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 1",
        "diaChiLapD": "",
        "longitude": 105.83723109979353,
        "latitude": 21.018777107716662,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2410,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81797592516037,
          21.0139000006661
        ]
      },
      "properties": {
        "tenDoiTuon": "9-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 161 Thái Hà",
        "tuDien": "I17TC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "BTLT",
        "chieuCao": 7,
        "tuDien2": "I 17 Thành Công",
        "diaChiLapD": "",
        "longitude": 105.81797592516037,
        "latitude": 21.0139000006661,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2411,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.817969219641,
          21.01378232086453
        ]
      },
      "properties": {
        "tenDoiTuon": "9-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 161 Thái Hà",
        "tuDien": "I17TC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "BTLT",
        "chieuCao": 7,
        "tuDien2": "I 17 Thành Công",
        "diaChiLapD": "",
        "longitude": 105.817969219641,
        "latitude": 21.01378232086453,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2412,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81807382578786,
          21.013708457962977
        ]
      },
      "properties": {
        "tenDoiTuon": "I17TC 9-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 161 Thái Hà",
        "tuDien": "I17TC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "BTLT",
        "chieuCao": 7,
        "tuDien2": "I 17 Thành Công",
        "diaChiLapD": "",
        "longitude": 105.81807382578786,
        "latitude": 21.013708457962977,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2413,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83802268671496,
          21.019768586525988
        ]
      },
      "properties": {
        "tenDoiTuon": "8-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 202,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Đền Tương Thuận",
        "tuDien": "KTG",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên Ga",
        "diaChiLapD": "",
        "longitude": 105.83802268671496,
        "latitude": 21.019768586525988,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2414,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83824933337695,
          21.0196333852589
        ]
      },
      "properties": {
        "tenDoiTuon": "8-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 202,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Đền Tương Thuận",
        "tuDien": "KTG",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên Ga",
        "diaChiLapD": "",
        "longitude": 105.83824933337695,
        "latitude": 21.0196333852589,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2415,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83841563033779,
          21.01956453271442
        ]
      },
      "properties": {
        "tenDoiTuon": "8-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 202,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Đền Tương Thuận",
        "tuDien": "KTG",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên Ga",
        "diaChiLapD": "",
        "longitude": 105.83841563033779,
        "latitude": 21.01956453271442,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2416,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83851755428589,
          21.01947314656063
        ]
      },
      "properties": {
        "tenDoiTuon": "8-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 202,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Đền Tương Thuận",
        "tuDien": "KTG",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên Ga",
        "diaChiLapD": "",
        "longitude": 105.83851755428589,
        "latitude": 21.01947314656063,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2417,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83866105246297,
          21.019136394360252
        ]
      },
      "properties": {
        "tenDoiTuon": "8-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 202,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Đền Tương Thuận",
        "tuDien": "KTG",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên Ga",
        "diaChiLapD": "",
        "longitude": 105.83866105246297,
        "latitude": 21.019136394360252,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2418,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81765137787087,
          21.013544457153525
        ]
      },
      "properties": {
        "tenDoiTuon": "8-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 161 Thái Hà",
        "tuDien": "I17TC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "BTLT",
        "chieuCao": 7,
        "tuDien2": "I 17 Thành Công",
        "diaChiLapD": "",
        "longitude": 105.81765137787087,
        "latitude": 21.013544457153525,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2419,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.817454235512,
          21.013310348814027
        ]
      },
      "properties": {
        "tenDoiTuon": "I17TC 8-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 161 Thái Hà",
        "tuDien": "I17TC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "BTLT",
        "chieuCao": 7,
        "tuDien2": "I 17 Thành Công",
        "diaChiLapD": "",
        "longitude": 105.817454235512,
        "latitude": 21.013310348814027,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2420,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81719406123526,
          21.013504395859258
        ]
      },
      "properties": {
        "tenDoiTuon": "I17TC 8-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 161 Thái Hà",
        "tuDien": "I17TC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 7,
        "tuDien2": "I 17 Thành Công",
        "diaChiLapD": "",
        "longitude": 105.81719406123526,
        "latitude": 21.013504395859258,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2421,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83801464009173,
          21.019943847245692
        ]
      },
      "properties": {
        "tenDoiTuon": "8-7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 202,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Đền Tương Thuận",
        "tuDien": "KTG",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên Ga",
        "diaChiLapD": "",
        "longitude": 105.83801464009173,
        "latitude": 21.019943847245692,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2422,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81781901593558,
          21.013718473274054
        ]
      },
      "properties": {
        "tenDoiTuon": "8-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 151A Thái Hà",
        "tuDien": "I17TC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "BTLT",
        "chieuCao": 7,
        "tuDien2": "I 17 Thành Công",
        "diaChiLapD": "",
        "longitude": 105.81781901593558,
        "latitude": 21.013718473274054,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2423,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8180456625976,
          21.013565739711556
        ]
      },
      "properties": {
        "tenDoiTuon": "8-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 151A Thái Hà",
        "tuDien": "I17TC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "BTLT",
        "chieuCao": 7,
        "tuDien2": "I 17 Thành Công",
        "diaChiLapD": "",
        "longitude": 105.8180456625976,
        "latitude": 21.013565739711556,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2424,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81772916193133,
          21.013451815394347
        ]
      },
      "properties": {
        "tenDoiTuon": "8-8",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 151A Thái Hà",
        "tuDien": "I17TC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "BTLT",
        "chieuCao": 7,
        "tuDien2": "I 17 Thành Công",
        "diaChiLapD": "",
        "longitude": 105.81772916193133,
        "latitude": 21.013451815394347,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2425,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81753336067632,
          21.013227722253465
        ]
      },
      "properties": {
        "tenDoiTuon": "8-9",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 151A Thái Hà",
        "tuDien": "I17TC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "BTLT",
        "chieuCao": 7,
        "tuDien2": "I 17 Thành Công",
        "diaChiLapD": "",
        "longitude": 105.81753336067632,
        "latitude": 21.013227722253465,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2426,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81791289325147,
          21.01343929623343
        ]
      },
      "properties": {
        "tenDoiTuon": "8-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 151A Thái Hà",
        "tuDien": "I17TC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "BTLT",
        "chieuCao": 7,
        "tuDien2": "I 17 Thành Công",
        "diaChiLapD": "",
        "longitude": 105.81791289325147,
        "latitude": 21.01343929623343,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2427,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81773318524293,
          21.013235233761467
        ]
      },
      "properties": {
        "tenDoiTuon": "I17TC 8-7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 151A Thái Hà",
        "tuDien": "I17TC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "BTLT",
        "chieuCao": 7,
        "tuDien2": "I 17 Thành Công",
        "diaChiLapD": "",
        "longitude": 105.81773318524293,
        "latitude": 21.013235233761467,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2428,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83737996238611,
          21.01857806003973
        ]
      },
      "properties": {
        "tenDoiTuon": "26-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Cống Trắng",
        "tuDien": "KTH1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 1",
        "diaChiLapD": "",
        "longitude": 105.83737996238611,
        "latitude": 21.01857806003973,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2429,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81743948336043,
          21.01304494212436
        ]
      },
      "properties": {
        "tenDoiTuon": "I17TC 4-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 151B Thái Hà",
        "tuDien": "I17TC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "BTLT",
        "chieuCao": 7,
        "tuDien2": "I 17 Thành Công",
        "diaChiLapD": "",
        "longitude": 105.81743948336043,
        "latitude": 21.01304494212436,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2430,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83898157644087,
          21.018857227461744
        ]
      },
      "properties": {
        "tenDoiTuon": "7-11-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 202,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Hồ Dài",
        "tuDien": "KTG",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 0,
        "tuDien2": "Khâm Thiên Ga",
        "diaChiLapD": "",
        "longitude": 105.83898157644087,
        "latitude": 21.018857227461744,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2431,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8166549372186,
          21.014795115263475
        ]
      },
      "properties": {
        "tenDoiTuon": "12",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 165 Thái Hà",
        "tuDien": "I17TC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 7,
        "tuDien2": "I 17 Thành Công",
        "diaChiLapD": "",
        "longitude": 105.8166549372186,
        "latitude": 21.014795115263475,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2432,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83896011876995,
          21.018553022578292
        ]
      },
      "properties": {
        "tenDoiTuon": "7-11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 202,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Hồ Dài",
        "tuDien": "KTG",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên Ga",
        "diaChiLapD": "",
        "longitude": 105.83896011876995,
        "latitude": 21.018553022578292,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2433,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83698567766834,
          21.01851546637534
        ]
      },
      "properties": {
        "tenDoiTuon": "27",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Cống Trắng",
        "tuDien": "KTH1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 1",
        "diaChiLapD": "",
        "longitude": 105.83698567766834,
        "latitude": 21.01851546637534,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2434,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83884478378314,
          21.018422827706555
        ]
      },
      "properties": {
        "tenDoiTuon": "7-12",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 202,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Hồ Dài",
        "tuDien": "KTG",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên Ga",
        "diaChiLapD": "",
        "longitude": 105.83884478378314,
        "latitude": 21.018422827706555,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2435,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83873481321181,
          21.018494184525327
        ]
      },
      "properties": {
        "tenDoiTuon": "7-13",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 202,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Hồ Dài",
        "tuDien": "KTG",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên Ga",
        "diaChiLapD": "",
        "longitude": 105.83873481321181,
        "latitude": 21.018494184525327,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2436,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8393664734315,
          21.018414064586025
        ]
      },
      "properties": {
        "tenDoiTuon": "26-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 202,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ ĐÌnh Tượng Thuận",
        "tuDien": "KTG",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên Ga",
        "diaChiLapD": "",
        "longitude": 105.8393664734315,
        "latitude": 21.018414064586025,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2437,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83983183669932,
          21.017872001988696
        ]
      },
      "properties": {
        "tenDoiTuon": "26-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 202,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ ĐÌnh Tượng Thuận",
        "tuDien": "KTG",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên Ga",
        "diaChiLapD": "",
        "longitude": 105.83983183669932,
        "latitude": 21.017872001988696,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2438,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83978355693301,
          21.01796088536694
        ]
      },
      "properties": {
        "tenDoiTuon": "26-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 202,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ ĐÌnh Tượng Thuận",
        "tuDien": "KTG",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên Ga",
        "diaChiLapD": "",
        "longitude": 105.83978355693301,
        "latitude": 21.01796088536694,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2439,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83963603544433,
          21.018073554362275
        ]
      },
      "properties": {
        "tenDoiTuon": "26-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 202,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ ĐÌnh Tượng Thuận",
        "tuDien": "KTG",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên Ga",
        "diaChiLapD": "",
        "longitude": 105.83963603544433,
        "latitude": 21.018073554362275,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2440,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83979965018847,
          21.018406553338856
        ]
      },
      "properties": {
        "tenDoiTuon": "26-8",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 202,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ ĐÌnh Tượng Thuận",
        "tuDien": "KTG",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên Ga",
        "diaChiLapD": "",
        "longitude": 105.83979965018847,
        "latitude": 21.018406553338856,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2441,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83954158336962,
          21.01823415224326
        ]
      },
      "properties": {
        "tenDoiTuon": "26-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 202,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ ĐÌnh Tượng Thuận",
        "tuDien": "KTG",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên Ga",
        "diaChiLapD": "",
        "longitude": 105.83954158336962,
        "latitude": 21.01823415224326,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2442,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83946437406797,
          21.017794385473987
        ]
      },
      "properties": {
        "tenDoiTuon": "26-7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 202,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Khâm Thiên",
        "tuDien": "KTG",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên Ga",
        "diaChiLapD": "",
        "longitude": 105.83946437406797,
        "latitude": 21.017794385473987,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2443,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83975539374273,
          21.017636648561847
        ]
      },
      "properties": {
        "tenDoiTuon": "26-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 202,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ ĐÌnh Tượng Thuận",
        "tuDien": "KTG",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên Ga",
        "diaChiLapD": "",
        "longitude": 105.83975539374273,
        "latitude": 21.017636648561847,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2444,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81685104305294,
          21.014928347960137
        ]
      },
      "properties": {
        "tenDoiTuon": "I17TC 13",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 165 Thái Hà",
        "tuDien": "I17TC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "I 17 Thành Công",
        "diaChiLapD": "",
        "longitude": 105.81685104305294,
        "latitude": 21.014928347960137,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2445,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81630372040088,
          21.014289397753263
        ]
      },
      "properties": {
        "tenDoiTuon": "11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 165 Thái Hà",
        "tuDien": "I17TC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "BTLT",
        "chieuCao": 7,
        "tuDien2": "I 17 Thành Công",
        "diaChiLapD": "",
        "longitude": 105.81630372040088,
        "latitude": 21.014289397753263,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2446,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81647482530057,
          21.014210524106606
        ]
      },
      "properties": {
        "tenDoiTuon": "11-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 165 Thái Hà",
        "tuDien": "I17TC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 7,
        "tuDien2": "I 17 Thành Công",
        "diaChiLapD": "",
        "longitude": 105.81647482530057,
        "latitude": 21.014210524106606,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2447,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81632462159514,
          21.01408408117593
        ]
      },
      "properties": {
        "tenDoiTuon": "11-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 165 Thái Hà",
        "tuDien": "I17TC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 7,
        "tuDien2": "I 17 Thành Công",
        "diaChiLapD": "",
        "longitude": 105.81632462159514,
        "latitude": 21.01408408117593,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2448,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81657004372032,
          21.013897546358653
        ]
      },
      "properties": {
        "tenDoiTuon": "I17TC 11-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 165 Thái Hà",
        "tuDien": "I17TC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 7,
        "tuDien2": "I 17 Thành Công",
        "diaChiLapD": "",
        "longitude": 105.81657004372032,
        "latitude": 21.013897546358653,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2449,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81635939557596,
          21.014448855556395
        ]
      },
      "properties": {
        "tenDoiTuon": "I17TC 11-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 165 Thái Hà",
        "tuDien": "I17TC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 7,
        "tuDien2": "I 17 Thành Công",
        "diaChiLapD": "",
        "longitude": 105.81635939557596,
        "latitude": 21.014448855556395,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2450,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81647923489484,
          21.014925684566737
        ]
      },
      "properties": {
        "tenDoiTuon": "I17TC 12-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 165 Thái Hà",
        "tuDien": "I17TC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 7,
        "tuDien2": "I 17 Thành Công",
        "diaChiLapD": "",
        "longitude": 105.81647923489484,
        "latitude": 21.014925684566737,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2451,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81596252337928,
          21.013822431600968
        ]
      },
      "properties": {
        "tenDoiTuon": "1-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 192 Thái Thịnh",
        "tuDien": "I17TC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 7,
        "tuDien2": "I 17 Thành Công",
        "diaChiLapD": "",
        "longitude": 105.81596252337928,
        "latitude": 21.013822431600968,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2452,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81587401047891,
          21.013776110815332
        ]
      },
      "properties": {
        "tenDoiTuon": "1-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 192 Thái Thịnh",
        "tuDien": "I17TC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 7,
        "tuDien2": "I 17 Thành Công",
        "diaChiLapD": "",
        "longitude": 105.81587401047891,
        "latitude": 21.013776110815332,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2453,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81573453561343,
          21.01387501193523
        ]
      },
      "properties": {
        "tenDoiTuon": "I17TC 1-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 192 Thái Thịnh",
        "tuDien": "I17TC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 7,
        "tuDien2": "I 17 Thành Công",
        "diaChiLapD": "",
        "longitude": 105.81573453561343,
        "latitude": 21.01387501193523,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2454,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81620258108,
          21.0137973933403
        ]
      },
      "properties": {
        "tenDoiTuon": "2-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 188 Thái Thịnh",
        "tuDien": "I17TC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 7,
        "tuDien2": "I 17 Thành Công",
        "diaChiLapD": "",
        "longitude": 105.81620258108,
        "latitude": 21.0137973933403,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2455,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81605351140286,
          21.01360520311382
        ]
      },
      "properties": {
        "tenDoiTuon": "2-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 188 Thái Thịnh",
        "tuDien": "I17TC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "BTLT",
        "chieuCao": 7,
        "tuDien2": "I 17 Thành Công",
        "diaChiLapD": "",
        "longitude": 105.81605351140286,
        "latitude": 21.01360520311382,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2456,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81589879237555,
          21.01340955112374
        ]
      },
      "properties": {
        "tenDoiTuon": "2-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 188 Thái Thịnh",
        "tuDien": "I17TC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "BTLT",
        "chieuCao": 7,
        "tuDien2": "I 17 Thành Công",
        "diaChiLapD": "",
        "longitude": 105.81589879237555,
        "latitude": 21.01340955112374,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2457,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83831881573755,
          21.019085770390582
        ]
      },
      "properties": {
        "tenDoiTuon": "01",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 202,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Khâm Thiên",
        "tuDien": "KTH1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 1",
        "diaChiLapD": "",
        "longitude": 105.83831881573755,
        "latitude": 21.019085770390582,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2458,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8379917585491,
          21.019107506612052
        ]
      },
      "properties": {
        "tenDoiTuon": "02",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 202,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Khâm Thiên",
        "tuDien": "KTH1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 1",
        "diaChiLapD": "",
        "longitude": 105.8379917585491,
        "latitude": 21.019107506612052,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2459,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8377721309205,
          21.019120776866927
        ]
      },
      "properties": {
        "tenDoiTuon": "2+1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 202,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Khâm Thiên",
        "tuDien": "KTH1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 1",
        "diaChiLapD": "",
        "longitude": 105.8377721309205,
        "latitude": 21.019120776866927,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2460,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8375164314028,
          21.019132728202198
        ]
      },
      "properties": {
        "tenDoiTuon": "03",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 202,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Khâm Thiên",
        "tuDien": "KTH1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 1",
        "diaChiLapD": "",
        "longitude": 105.8375164314028,
        "latitude": 21.019132728202198,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2461,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8372474622605,
          21.01914842955395
        ]
      },
      "properties": {
        "tenDoiTuon": "04",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 202,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Khâm Thiên",
        "tuDien": "KTH1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 1",
        "diaChiLapD": "",
        "longitude": 105.8372474622605,
        "latitude": 21.01914842955395,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2462,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83692713595248,
          21.019170941948627
        ]
      },
      "properties": {
        "tenDoiTuon": "05",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 202,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Khâm Thiên",
        "tuDien": "KTH1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 1",
        "diaChiLapD": "",
        "longitude": 105.83692713595248,
        "latitude": 21.019170941948627,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2463,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8365092363495,
          21.019190866103887
        ]
      },
      "properties": {
        "tenDoiTuon": "06",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 202,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Khâm Thiên",
        "tuDien": "KTH1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 1",
        "diaChiLapD": "",
        "longitude": 105.8365092363495,
        "latitude": 21.019190866103887,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2464,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83628124858362,
          21.019208392253116
        ]
      },
      "properties": {
        "tenDoiTuon": "6+1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 202,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Khâm Thiên",
        "tuDien": "KTH1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 1",
        "diaChiLapD": "",
        "longitude": 105.83628124858362,
        "latitude": 21.019208392253116,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2465,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83605057861001,
          21.019218407194852
        ]
      },
      "properties": {
        "tenDoiTuon": "07",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Khâm Thiên",
        "tuDien": "KTH1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 1",
        "diaChiLapD": "",
        "longitude": 105.83605057861001,
        "latitude": 21.019218407194852,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2466,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83577298305715,
          21.01923908201201
        ]
      },
      "properties": {
        "tenDoiTuon": "08",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Khâm Thiên",
        "tuDien": "KTH1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 1",
        "diaChiLapD": "",
        "longitude": 105.83577298305715,
        "latitude": 21.01923908201201,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2467,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83539258566059,
          21.01926942793389
        ]
      },
      "properties": {
        "tenDoiTuon": "09",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Khâm Thiên",
        "tuDien": "KTH1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 1",
        "diaChiLapD": "",
        "longitude": 105.83539258566059,
        "latitude": 21.01926942793389,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2468,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8350382349299,
          21.019286718585306
        ]
      },
      "properties": {
        "tenDoiTuon": "10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Khâm Thiên",
        "tuDien": "KTH1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 1",
        "diaChiLapD": "",
        "longitude": 105.8350382349299,
        "latitude": 21.019286718585306,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2469,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83470670644154,
          21.01930886937503
        ]
      },
      "properties": {
        "tenDoiTuon": "11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Khâm Thiên",
        "tuDien": "KTH1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 1",
        "diaChiLapD": "",
        "longitude": 105.83470670644154,
        "latitude": 21.01930886937503,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2470,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83677288407007,
          21.019339863145937
        ]
      },
      "properties": {
        "tenDoiTuon": "6-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 202,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Liên Hòa",
        "tuDien": "KTH1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 1",
        "diaChiLapD": "",
        "longitude": 105.83677288407007,
        "latitude": 21.019339863145937,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2471,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83691235893555,
          21.01964531828845
        ]
      },
      "properties": {
        "tenDoiTuon": "6-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 202,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Tô Tiền",
        "tuDien": "KTH1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 1",
        "diaChiLapD": "",
        "longitude": 105.83691235893555,
        "latitude": 21.01964531828845,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2472,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83700087183595,
          21.019722933839745
        ]
      },
      "properties": {
        "tenDoiTuon": "6-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 202,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Tô Tiền",
        "tuDien": "KTH1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 1",
        "diaChiLapD": "",
        "longitude": 105.83700087183595,
        "latitude": 21.019722933839745,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2473,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83684798592279,
          21.019818075428212
        ]
      },
      "properties": {
        "tenDoiTuon": "6-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 202,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Tô Tiền",
        "tuDien": "KTH1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 1",
        "diaChiLapD": "",
        "longitude": 105.83684798592279,
        "latitude": 21.019818075428212,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2474,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83718326205222,
          21.01992823929646
        ]
      },
      "properties": {
        "tenDoiTuon": "6-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 202,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Tô Tiền",
        "tuDien": "KTH1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 1",
        "diaChiLapD": "",
        "longitude": 105.83718326205222,
        "latitude": 21.01992823929646,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2475,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83701428288364,
          21.020018373310172
        ]
      },
      "properties": {
        "tenDoiTuon": "6-7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 202,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Tô Tiền",
        "tuDien": "KTH1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 1",
        "diaChiLapD": "",
        "longitude": 105.83701428288364,
        "latitude": 21.020018373310172,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2476,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83710547798279,
          21.019745467379522
        ]
      },
      "properties": {
        "tenDoiTuon": "6-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 202,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Tô Tiền",
        "tuDien": "KTH1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 1",
        "diaChiLapD": "",
        "longitude": 105.83710547798279,
        "latitude": 21.019745467379522,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2477,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8366521846588,
          21.019435004978835
        ]
      },
      "properties": {
        "tenDoiTuon": "6-1-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 202,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Liên Hoa",
        "tuDien": "KTH1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 1",
        "diaChiLapD": "",
        "longitude": 105.8366521846588,
        "latitude": 21.019435004978835,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2478,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8368694435937,
          21.019264751130237
        ]
      },
      "properties": {
        "tenDoiTuon": "5-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 202,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 132",
        "tuDien": "KTH1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 1",
        "diaChiLapD": "",
        "longitude": 105.8368694435937,
        "latitude": 21.019264751130237,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2479,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83719667309096,
          21.019595243718097
        ]
      },
      "properties": {
        "tenDoiTuon": "5-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 202,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 132",
        "tuDien": "KTH1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 1",
        "diaChiLapD": "",
        "longitude": 105.83719667309096,
        "latitude": 21.019595243718097,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2480,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83630081529189,
          21.019299803410128
        ]
      },
      "properties": {
        "tenDoiTuon": "6-8-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 202,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Đại Đồng",
        "tuDien": "KTH1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 1",
        "diaChiLapD": "",
        "longitude": 105.83630081529189,
        "latitude": 21.019299803410128,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2481,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83637859935234,
          21.019457538563803
        ]
      },
      "properties": {
        "tenDoiTuon": "6-8",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 202,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Đại Đồng",
        "tuDien": "KTH1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 1",
        "diaChiLapD": "",
        "longitude": 105.83637859935234,
        "latitude": 21.019457538563803,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2482,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83634104843497,
          21.01968537793478
        ]
      },
      "properties": {
        "tenDoiTuon": "6-9",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 202,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Đại Đồng",
        "tuDien": "KTH1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 1",
        "diaChiLapD": "",
        "longitude": 105.83634104843497,
        "latitude": 21.01968537793478,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2483,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83616670484192,
          21.01987065365023
        ]
      },
      "properties": {
        "tenDoiTuon": "6-10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 202,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Đại Đồng",
        "tuDien": "KTH1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 1",
        "diaChiLapD": "",
        "longitude": 105.83616670484192,
        "latitude": 21.01987065365023,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2484,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8362337600714,
          21.020073455182168
        ]
      },
      "properties": {
        "tenDoiTuon": "6-11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 202,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Đại Đồng",
        "tuDien": "KTH1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 1",
        "diaChiLapD": "",
        "longitude": 105.8362337600714,
        "latitude": 21.020073455182168,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2485,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83608087415823,
          21.02015107051061
        ]
      },
      "properties": {
        "tenDoiTuon": "6-12",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 202,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Đại Đồng",
        "tuDien": "KTH1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 1",
        "diaChiLapD": "",
        "longitude": 105.83608087415823,
        "latitude": 21.02015107051061,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2486,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83629813308416,
          21.020208656051054
        ]
      },
      "properties": {
        "tenDoiTuon": "6-11-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 202,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Đại Đồng",
        "tuDien": "KTH1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 1",
        "diaChiLapD": "",
        "longitude": 105.83629813308416,
        "latitude": 21.020208656051054,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2487,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83609428519694,
          21.01965282947406
        ]
      },
      "properties": {
        "tenDoiTuon": "7-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 202,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 176",
        "tuDien": "KTH1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 1",
        "diaChiLapD": "",
        "longitude": 105.83609428519694,
        "latitude": 21.01965282947406,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2488,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82072084580153,
          21.00658502561732
        ]
      },
      "properties": {
        "tenDoiTuon": "01",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Vĩnh Hồ",
        "tuDien": "VHO1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "Vĩnh Hồ 1",
        "diaChiLapD": "",
        "longitude": 105.82072084580153,
        "latitude": 21.00658502561732,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2489,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82086769794815,
          21.006332152211808
        ]
      },
      "properties": {
        "tenDoiTuon": "02",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Vĩnh Hồ",
        "tuDien": "VHO1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "Vĩnh Hồ 1",
        "diaChiLapD": "",
        "longitude": 105.82086769794815,
        "latitude": 21.006332152211808,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2490,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82105737941966,
          21.00612735026167
        ]
      },
      "properties": {
        "tenDoiTuon": "03",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Vĩnh Hồ",
        "tuDien": "VHO1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "Vĩnh Hồ 1",
        "diaChiLapD": "",
        "longitude": 105.82105737941966,
        "latitude": 21.00612735026167,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2491,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82123837312973,
          21.005923070586267
        ]
      },
      "properties": {
        "tenDoiTuon": "04",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Vĩnh Hồ",
        "tuDien": "VHO1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "Vĩnh Hồ 1",
        "diaChiLapD": "",
        "longitude": 105.82123837312973,
        "latitude": 21.005923070586267,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2492,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82143020646647,
          21.00568790550543
        ]
      },
      "properties": {
        "tenDoiTuon": "05",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Vĩnh Hồ",
        "tuDien": "VHO1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "Vĩnh Hồ 1",
        "diaChiLapD": "",
        "longitude": 105.82143020646647,
        "latitude": 21.00568790550543,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2493,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8216689230723,
          21.005440013022042
        ]
      },
      "properties": {
        "tenDoiTuon": "06",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Vĩnh Hồ",
        "tuDien": "VHO1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "Vĩnh Hồ 1",
        "diaChiLapD": "",
        "longitude": 105.8216689230723,
        "latitude": 21.005440013022042,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2494,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8218379022409,
          21.00525597136811
        ]
      },
      "properties": {
        "tenDoiTuon": "VH107",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Vĩnh Hồ",
        "tuDien": "VHO1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "Vĩnh Hồ 1",
        "diaChiLapD": "",
        "longitude": 105.8218379022409,
        "latitude": 21.00525597136811,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2495,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82055044190489,
          21.00638776135776
        ]
      },
      "properties": {
        "tenDoiTuon": "1-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 107 Vĩnh Hồ",
        "tuDien": "VHO1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 1",
        "diaChiLapD": "",
        "longitude": 105.82055044190489,
        "latitude": 21.00638776135776,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2496,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82043108360648,
          21.00624879203216
        ]
      },
      "properties": {
        "tenDoiTuon": "1-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 107 Vĩnh Hồ",
        "tuDien": "VHO1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 1",
        "diaChiLapD": "",
        "longitude": 105.82043108360648,
        "latitude": 21.00624879203216,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2497,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82081195728551,
          21.00629761910692
        ]
      },
      "properties": {
        "tenDoiTuon": "1-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 107 Vĩnh Hồ",
        "tuDien": "VHO1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 1",
        "diaChiLapD": "",
        "longitude": 105.82081195728551,
        "latitude": 21.00629761910692,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2498,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82067867867352,
          21.006198082228643
        ]
      },
      "properties": {
        "tenDoiTuon": "1-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 107 Vĩnh Hồ",
        "tuDien": "VHO1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 1",
        "diaChiLapD": "",
        "longitude": 105.82067867867352,
        "latitude": 21.006198082228643,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2499,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82084594487189,
          21.006003734660524
        ]
      },
      "properties": {
        "tenDoiTuon": "3-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Nhà A6 Vĩnh Hồ",
        "tuDien": "VHO1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 1",
        "diaChiLapD": "",
        "longitude": 105.82084594487189,
        "latitude": 21.006003734660524,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2500,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82070838818487,
          21.005899996175142
        ]
      },
      "properties": {
        "tenDoiTuon": "3-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Nhà A6 Vĩnh Hồ",
        "tuDien": "VHO1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 1",
        "diaChiLapD": "",
        "longitude": 105.82070838818487,
        "latitude": 21.005899996175142,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2501,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82104799168359,
          21.005780552088513
        ]
      },
      "properties": {
        "tenDoiTuon": "4-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 49 Vĩnh Hồ",
        "tuDien": "VHO1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 1",
        "diaChiLapD": "",
        "longitude": 105.82104799168359,
        "latitude": 21.005780552088513,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2502,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82117941992583,
          21.005706685225082
        ]
      },
      "properties": {
        "tenDoiTuon": "5-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 45 Vĩnh Hồ",
        "tuDien": "VHO1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 1",
        "diaChiLapD": "",
        "longitude": 105.82117941992583,
        "latitude": 21.005706685225082,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2503,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82096484319865,
          21.005537667688273
        ]
      },
      "properties": {
        "tenDoiTuon": "5-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 45 Vĩnh Hồ",
        "tuDien": "VHO1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 1",
        "diaChiLapD": "",
        "longitude": 105.82096484319865,
        "latitude": 21.005537667688273,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2504,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82132560031064,
          21.005659109937806
        ]
      },
      "properties": {
        "tenDoiTuon": "5-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 37 Vĩnh Hồ",
        "tuDien": "VHO1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 1",
        "diaChiLapD": "",
        "longitude": 105.82132560031064,
        "latitude": 21.005659109937806,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2505,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82119947377075,
          21.005528148823565
        ]
      },
      "properties": {
        "tenDoiTuon": "5-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 49 Vĩnh Hồ",
        "tuDien": "VHO1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 1",
        "diaChiLapD": "",
        "longitude": 105.82119947377075,
        "latitude": 21.005528148823565,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2506,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82107282227774,
          21.005434304269624
        ]
      },
      "properties": {
        "tenDoiTuon": "5-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 37 Vĩnh Hồ",
        "tuDien": "VHO1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 1",
        "diaChiLapD": "",
        "longitude": 105.82107282227774,
        "latitude": 21.005434304269624,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2507,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82172860222599,
          21.00558086107655
        ]
      },
      "properties": {
        "tenDoiTuon": "6-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 7 Vĩnh Hồ",
        "tuDien": "VHO1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 1",
        "diaChiLapD": "",
        "longitude": 105.82172860222599,
        "latitude": 21.00558086107655,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2508,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82199816422983,
          21.00583876916604
        ]
      },
      "properties": {
        "tenDoiTuon": "6-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 7 Vĩnh Hồ",
        "tuDien": "VHO1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 1",
        "diaChiLapD": "",
        "longitude": 105.82199816422983,
        "latitude": 21.00583876916604,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2509,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82187344150695,
          21.005910132004928
        ]
      },
      "properties": {
        "tenDoiTuon": "6-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 7 Vĩnh Hồ",
        "tuDien": "VHO1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 1",
        "diaChiLapD": "",
        "longitude": 105.82187344150695,
        "latitude": 21.005910132004928,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2510,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82178224639881,
          21.006014046253796
        ]
      },
      "properties": {
        "tenDoiTuon": "VH1 6-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 7 Vĩnh Hồ",
        "tuDien": "VHO1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 1",
        "diaChiLapD": "",
        "longitude": 105.82178224639881,
        "latitude": 21.006014046253796,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2511,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82202833907144,
          21.005955829242144
        ]
      },
      "properties": {
        "tenDoiTuon": "VH1 6-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 7 Vĩnh Hồ",
        "tuDien": "VHO1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 1",
        "diaChiLapD": "",
        "longitude": 105.82202833907144,
        "latitude": 21.005955829242144,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2512,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82141813649571,
          21.005172088362535
        ]
      },
      "properties": {
        "tenDoiTuon": "08",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Tập thể Vĩnh Hồ A1 đến A5",
        "tuDien": "VHO1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "Vĩnh Hồ 1",
        "diaChiLapD": "",
        "longitude": 105.82141813649571,
        "latitude": 21.005172088362535,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2513,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82117137325767,
          21.00489539930472
        ]
      },
      "properties": {
        "tenDoiTuon": "10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Tập thể Vĩnh Hồ A1 đến A5",
        "tuDien": "VHO1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 1",
        "diaChiLapD": "",
        "longitude": 105.82117137325767,
        "latitude": 21.00489539930472,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2514,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82127306938895,
          21.004762378400645
        ]
      },
      "properties": {
        "tenDoiTuon": "09",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Tập thể Vĩnh Hồ A1 đến A5",
        "tuDien": "VHO1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 1",
        "diaChiLapD": "",
        "longitude": 105.82127306938895,
        "latitude": 21.004762378400645,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2515,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8214649514483,
          21.00469085349629
        ]
      },
      "properties": {
        "tenDoiTuon": "VH1 9-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Tập thể Vĩnh Hồ A1 đến A5",
        "tuDien": "VHO1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 1",
        "diaChiLapD": "",
        "longitude": 105.8214649514483,
        "latitude": 21.00469085349629,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2516,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82112553355998,
          21.004595514511035
        ]
      },
      "properties": {
        "tenDoiTuon": "9-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Tập thể Vĩnh Hồ A1 đến A5",
        "tuDien": "VHO1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 1",
        "diaChiLapD": "",
        "longitude": 105.82112553355998,
        "latitude": 21.004595514511035,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2517,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82091929869006,
          21.00440856861934
        ]
      },
      "properties": {
        "tenDoiTuon": "9-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Tập thể Vĩnh Hồ A1 đến A5",
        "tuDien": "VHO1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 1",
        "diaChiLapD": "",
        "longitude": 105.82091929869006,
        "latitude": 21.00440856861934,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2518,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82086917823926,
          21.00475711388517
        ]
      },
      "properties": {
        "tenDoiTuon": "10-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Tập thể Vĩnh Hồ A1 đến A5",
        "tuDien": "VHO1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 1",
        "diaChiLapD": "",
        "longitude": 105.82086917823926,
        "latitude": 21.00475711388517,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2519,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8206493548373,
          21.004584188961754
        ]
      },
      "properties": {
        "tenDoiTuon": "10-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Tập thể Vĩnh Hồ A1 đến A5",
        "tuDien": "VHO1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 1",
        "diaChiLapD": "",
        "longitude": 105.8206493548373,
        "latitude": 21.004584188961754,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2520,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82101848734453,
          21.005093213253943
        ]
      },
      "properties": {
        "tenDoiTuon": "11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Tập thể Vĩnh Hồ A1 đến A5",
        "tuDien": "VHO1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 1",
        "diaChiLapD": "",
        "longitude": 105.82101848734453,
        "latitude": 21.005093213253943,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2521,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82088437689454,
          21.005030613930813
        ]
      },
      "properties": {
        "tenDoiTuon": "11-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Tập thể Vĩnh Hồ A1 đến A5",
        "tuDien": "VHO1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 1",
        "diaChiLapD": "",
        "longitude": 105.82088437689454,
        "latitude": 21.005030613930813,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2522,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82063917432006,
          21.004830746442767
        ]
      },
      "properties": {
        "tenDoiTuon": "11-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Tập thể Vĩnh Hồ A1 đến A5",
        "tuDien": "VHO1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 1",
        "diaChiLapD": "",
        "longitude": 105.82063917432006,
        "latitude": 21.004830746442767,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2523,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82746417088104,
          21.01533504773209
        ]
      },
      "properties": {
        "tenDoiTuon": "BVND2 02",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "",
        "tuDien": "BVNĐ2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "BVND2",
        "diaChiLapD": "",
        "longitude": 105.82746417088104,
        "latitude": 21.01533504773209,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2524,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82752049727057,
          21.015517825055564
        ]
      },
      "properties": {
        "tenDoiTuon": "BVND2 03",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "",
        "tuDien": "BVNĐ2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "BVND2",
        "diaChiLapD": "",
        "longitude": 105.82752049727057,
        "latitude": 21.015517825055564,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2525,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82753390830928,
          21.01567806800401
        ]
      },
      "properties": {
        "tenDoiTuon": "BVND2 04",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "",
        "tuDien": "BVNĐ2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "BVND2",
        "diaChiLapD": "",
        "longitude": 105.82753390830928,
        "latitude": 21.01567806800401,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2526,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82779944700151,
          21.015718128713605
        ]
      },
      "properties": {
        "tenDoiTuon": "BVND2 05",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "",
        "tuDien": "BVNĐ2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "BVND2",
        "diaChiLapD": "",
        "longitude": 105.82779944700151,
        "latitude": 21.015718128713605,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2527,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82789064210965,
          21.015863348697536
        ]
      },
      "properties": {
        "tenDoiTuon": "BVND2 06",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "",
        "tuDien": "BVNĐ2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "BVND2",
        "diaChiLapD": "",
        "longitude": 105.82789064210965,
        "latitude": 21.015863348697536,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2528,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82797915501007,
          21.016006064751206
        ]
      },
      "properties": {
        "tenDoiTuon": "BVND2 07",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "",
        "tuDien": "BVNĐ2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "BVND2",
        "diaChiLapD": "",
        "longitude": 105.82797915501007,
        "latitude": 21.016006064751206,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2529,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82804621023054,
          21.01612875037269
        ]
      },
      "properties": {
        "tenDoiTuon": "BVND2 08",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "",
        "tuDien": "BVNĐ2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "BVND2",
        "diaChiLapD": "",
        "longitude": 105.82804621023054,
        "latitude": 21.01612875037269,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2530,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82805962127823,
          21.01618633746564
        ]
      },
      "properties": {
        "tenDoiTuon": "BVND2 09",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "",
        "tuDien": "BVNĐ2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "BVND2",
        "diaChiLapD": "",
        "longitude": 105.82805962127823,
        "latitude": 21.01618633746564,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2531,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82818836731275,
          21.016151284454764
        ]
      },
      "properties": {
        "tenDoiTuon": "BVND2 10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "",
        "tuDien": "BVNĐ2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "BVND2",
        "diaChiLapD": "",
        "longitude": 105.82818836731275,
        "latitude": 21.016151284454764,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2532,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82719326776436,
          21.014866836307498
        ]
      },
      "properties": {
        "tenDoiTuon": "BVND2 12",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "",
        "tuDien": "BVNĐ2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "BVND2",
        "diaChiLapD": "",
        "longitude": 105.82719326776436,
        "latitude": 21.014866836307498,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2533,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82735151810199,
          21.015137247363022
        ]
      },
      "properties": {
        "tenDoiTuon": "BVND2 11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "",
        "tuDien": "BVNĐ2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "BVND2",
        "diaChiLapD": "",
        "longitude": 105.82735151810199,
        "latitude": 21.015137247363022,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2534,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82705647510662,
          21.01456637900415
        ]
      },
      "properties": {
        "tenDoiTuon": "BVND2 13",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "",
        "tuDien": "BVNĐ2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "BVND2",
        "diaChiLapD": "",
        "longitude": 105.82705647510662,
        "latitude": 21.01456637900415,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2535,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82692504686439,
          21.014278440187073
        ]
      },
      "properties": {
        "tenDoiTuon": "BVND2 14",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "",
        "tuDien": "BVNĐ2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "BVND2",
        "diaChiLapD": "",
        "longitude": 105.82692504686439,
        "latitude": 21.014278440187073,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2536,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8263376430904,
          21.01435605853167
        ]
      },
      "properties": {
        "tenDoiTuon": "BVND2 15-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "",
        "tuDien": "BVNĐ2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "BVND2",
        "diaChiLapD": "",
        "longitude": 105.8263376430904,
        "latitude": 21.01435605853167,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2537,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82653344435441,
          21.014178287425324
        ]
      },
      "properties": {
        "tenDoiTuon": "BVND2 15-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "",
        "tuDien": "BVNĐ2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "BVND2",
        "diaChiLapD": "",
        "longitude": 105.82653344435441,
        "latitude": 21.014178287425324,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2538,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82684189838845,
          21.014068119308554
        ]
      },
      "properties": {
        "tenDoiTuon": "BVND2 15",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "",
        "tuDien": "BVNĐ2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "BVND2",
        "diaChiLapD": "",
        "longitude": 105.82684189838845,
        "latitude": 21.014068119308554,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2539,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82642615599082,
          21.01392540140127
        ]
      },
      "properties": {
        "tenDoiTuon": "BVND2 15-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "",
        "tuDien": "BVNĐ2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "BVND2",
        "diaChiLapD": "",
        "longitude": 105.82642615599082,
        "latitude": 21.01392540140127,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2540,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82649589342803,
          21.013564850288812
        ]
      },
      "properties": {
        "tenDoiTuon": "BVND2 17",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "",
        "tuDien": "BVNĐ2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "BVND2",
        "diaChiLapD": "",
        "longitude": 105.82649589342803,
        "latitude": 21.013564850288812,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2541,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82663268608576,
          21.01376265274322
        ]
      },
      "properties": {
        "tenDoiTuon": "BVND2 16",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "",
        "tuDien": "BVNĐ2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "BVND2",
        "diaChiLapD": "",
        "longitude": 105.82663268608576,
        "latitude": 21.01376265274322,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2542,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82634032529816,
          21.01332698623016
        ]
      },
      "properties": {
        "tenDoiTuon": "BVND2 18",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "",
        "tuDien": "BVNĐ2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "BVND2",
        "diaChiLapD": "",
        "longitude": 105.82634032529816,
        "latitude": 21.01332698623016,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2543,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82634568972261,
          21.01363495751728
        ]
      },
      "properties": {
        "tenDoiTuon": "BVND2 17-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "",
        "tuDien": "BVNĐ2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "BVND2",
        "diaChiLapD": "",
        "longitude": 105.82634568972261,
        "latitude": 21.01363495751728,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2544,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82619012159272,
          21.013404605069926
        ]
      },
      "properties": {
        "tenDoiTuon": "BVND2 18-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "",
        "tuDien": "BVNĐ2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "BVND2",
        "diaChiLapD": "",
        "longitude": 105.82619012159272,
        "latitude": 21.013404605069926,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2545,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82598895592224,
          21.013484727700455
        ]
      },
      "properties": {
        "tenDoiTuon": "BVND2 18-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "",
        "tuDien": "BVNĐ2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "BVND2",
        "diaChiLapD": "",
        "longitude": 105.82598895592224,
        "latitude": 21.013484727700455,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2546,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8260050491777,
          21.013817736924157
        ]
      },
      "properties": {
        "tenDoiTuon": "BVND2 17-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "",
        "tuDien": "BVNĐ2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "BVND2",
        "diaChiLapD": "",
        "longitude": 105.8260050491777,
        "latitude": 21.013817736924157,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2547,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82596749825133,
          21.01394042434496
        ]
      },
      "properties": {
        "tenDoiTuon": "BVND2 17-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "",
        "tuDien": "BVNĐ2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "BVND2",
        "diaChiLapD": "",
        "longitude": 105.82596749825133,
        "latitude": 21.01394042434496,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2548,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8259245829005,
          21.01408814988174
        ]
      },
      "properties": {
        "tenDoiTuon": "BVND2 17-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "",
        "tuDien": "BVNĐ2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "BVND2",
        "diaChiLapD": "",
        "longitude": 105.8259245829005,
        "latitude": 21.01408814988174,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2549,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8258977608141,
          21.014200821802927
        ]
      },
      "properties": {
        "tenDoiTuon": "BVND2 17-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "",
        "tuDien": "BVNĐ2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "BVND2",
        "diaChiLapD": "",
        "longitude": 105.8258977608141,
        "latitude": 21.014200821802927,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2550,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82586557430322,
          21.01378017952955
        ]
      },
      "properties": {
        "tenDoiTuon": "BVND2 17-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "",
        "tuDien": "BVNĐ2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "BVND2",
        "diaChiLapD": "",
        "longitude": 105.82586557430322,
        "latitude": 21.01378017952955,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2551,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82574755710868,
          21.013592392417937
        ]
      },
      "properties": {
        "tenDoiTuon": "BVND2 18-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "",
        "tuDien": "BVNĐ2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "BVND2",
        "diaChiLapD": "",
        "longitude": 105.82574755710868,
        "latitude": 21.013592392417937,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2552,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82558662456331,
          21.01368002643324
        ]
      },
      "properties": {
        "tenDoiTuon": "BVND2 18-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "",
        "tuDien": "BVNĐ2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "BVND2",
        "diaChiLapD": "",
        "longitude": 105.82558662456331,
        "latitude": 21.01368002643324,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2553,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82542837423469,
          21.01380772161976
        ]
      },
      "properties": {
        "tenDoiTuon": "BVND2 18-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "",
        "tuDien": "BVNĐ2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "BVND2",
        "diaChiLapD": "",
        "longitude": 105.82542837423469,
        "latitude": 21.01380772161976,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2554,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82764924329608,
          21.015163537302385
        ]
      },
      "properties": {
        "tenDoiTuon": "BVND2 01",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "",
        "tuDien": "BVNĐ2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "BVND2",
        "diaChiLapD": "",
        "longitude": 105.82764924329608,
        "latitude": 21.015163537302385,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2555,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8269867376604,
          21.014908149142425
        ]
      },
      "properties": {
        "tenDoiTuon": "BVND2 12-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "",
        "tuDien": "BVNĐ2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "BVND2",
        "diaChiLapD": "",
        "longitude": 105.8269867376604,
        "latitude": 21.014908149142425,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2556,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82680702966087,
          21.01500579760843
        ]
      },
      "properties": {
        "tenDoiTuon": "BVND2 12-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "",
        "tuDien": "BVNĐ2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "BVND2",
        "diaChiLapD": "",
        "longitude": 105.82680702966087,
        "latitude": 21.01500579760843,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2557,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82660586398137,
          21.01511095742406
        ]
      },
      "properties": {
        "tenDoiTuon": "BVND2 12-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "",
        "tuDien": "BVNĐ2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "BVND2",
        "diaChiLapD": "",
        "longitude": 105.82660586398137,
        "latitude": 21.01511095742406,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2558,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82647175353141,
          21.01490063771951
        ]
      },
      "properties": {
        "tenDoiTuon": "BVND2 12-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "",
        "tuDien": "BVNĐ2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "BVND2",
        "diaChiLapD": "",
        "longitude": 105.82647175353141,
        "latitude": 21.01490063771951,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2559,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8263295964582,
          21.01472036345098
        ]
      },
      "properties": {
        "tenDoiTuon": "BVND2 12-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "",
        "tuDien": "BVNĐ2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "BVND2",
        "diaChiLapD": "",
        "longitude": 105.8263295964582,
        "latitude": 21.01472036345098,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2560,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82628131669188,
          21.014800485374742
        ]
      },
      "properties": {
        "tenDoiTuon": "BVND2 12-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "",
        "tuDien": "BVNĐ2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "BVND2",
        "diaChiLapD": "",
        "longitude": 105.82628131669188,
        "latitude": 21.014800485374742,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2561,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82621962588688,
          21.014652760543484
        ]
      },
      "properties": {
        "tenDoiTuon": "BVND2 12-7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "",
        "tuDien": "BVNĐ2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "BVND2",
        "diaChiLapD": "",
        "longitude": 105.82621962588688,
        "latitude": 21.014652760543484,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2562,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82631216208992,
          21.013198038717874
        ]
      },
      "properties": {
        "tenDoiTuon": "BVND2 19",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "",
        "tuDien": "BVNĐ2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "BVND2",
        "diaChiLapD": "",
        "longitude": 105.82631216208992,
        "latitude": 21.013198038717874,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2563,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82625851790813,
          21.013110404419372
        ]
      },
      "properties": {
        "tenDoiTuon": "BVND2 20",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "",
        "tuDien": "BVNĐ2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "BVND2",
        "diaChiLapD": "",
        "longitude": 105.82625851790813,
        "latitude": 21.013110404419372,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2564,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82622633139725,
          21.013210557899047
        ]
      },
      "properties": {
        "tenDoiTuon": "BVND2 19-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "",
        "tuDien": "BVNĐ2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "BVND2",
        "diaChiLapD": "",
        "longitude": 105.82622633139725,
        "latitude": 21.013210557899047,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2565,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82457174379796,
          21.010443480310066
        ]
      },
      "properties": {
        "tenDoiTuon": "TH6 16",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "",
        "tuDien": "TH6",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": "",
        "tuDien2": "TH6",
        "diaChiLapD": "",
        "longitude": 105.82457174379796,
        "latitude": 21.010443480310066,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2566,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82444031555572,
          21.010293247279034
        ]
      },
      "properties": {
        "tenDoiTuon": "TH6 16-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "",
        "tuDien": "TH6",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": "",
        "tuDien2": "TH6",
        "diaChiLapD": "",
        "longitude": 105.82444031555572,
        "latitude": 21.010293247279034,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2567,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82449395972853,
          21.01020060350065
        ]
      },
      "properties": {
        "tenDoiTuon": "TH6 16-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "",
        "tuDien": "TH6",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": "",
        "tuDien2": "TH6",
        "diaChiLapD": "",
        "longitude": 105.82449395972853,
        "latitude": 21.01020060350065,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2568,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82432766277667,
          21.010280727853022
        ]
      },
      "properties": {
        "tenDoiTuon": "TH6 16-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "",
        "tuDien": "TH6",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": "",
        "tuDien2": "TH6",
        "diaChiLapD": "",
        "longitude": 105.82432766277667,
        "latitude": 21.010280727853022,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2569,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82408626396311,
          21.010380883231708
        ]
      },
      "properties": {
        "tenDoiTuon": "TH6 17-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "",
        "tuDien": "TH6",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": "",
        "tuDien2": "TH6",
        "diaChiLapD": "",
        "longitude": 105.82408626396311,
        "latitude": 21.010380883231708,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2570,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82408358175536,
          21.010303262818578
        ]
      },
      "properties": {
        "tenDoiTuon": "TH6 17-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "",
        "tuDien": "TH6",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": "",
        "tuDien2": "TH6",
        "diaChiLapD": "",
        "longitude": 105.82408358175536,
        "latitude": 21.010303262818578,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2571,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82431425172896,
          21.01061624810896
        ]
      },
      "properties": {
        "tenDoiTuon": "TH6 17",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "",
        "tuDien": "TH6",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": "",
        "tuDien2": "TH6",
        "diaChiLapD": "",
        "longitude": 105.82431425172896,
        "latitude": 21.01061624810896,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2572,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82445372659446,
          21.010703883871894
        ]
      },
      "properties": {
        "tenDoiTuon": "TH6 17-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "",
        "tuDien": "TH6",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": "",
        "tuDien2": "TH6",
        "diaChiLapD": "",
        "longitude": 105.82445372659446,
        "latitude": 21.010703883871894,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2573,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82457442600571,
          21.01087915524415
        ]
      },
      "properties": {
        "tenDoiTuon": "TH6 17-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "",
        "tuDien": "TH6",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": "",
        "tuDien2": "TH6",
        "diaChiLapD": "",
        "longitude": 105.82457442600571,
        "latitude": 21.01087915524415,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2574,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8241747768635,
          21.010794023460548
        ]
      },
      "properties": {
        "tenDoiTuon": "TH6 18",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "",
        "tuDien": "TH6",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": "",
        "tuDien2": "TH6",
        "diaChiLapD": "",
        "longitude": 105.8241747768635,
        "latitude": 21.010794023460548,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2575,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82427938301035,
          21.010926728866902
        ]
      },
      "properties": {
        "tenDoiTuon": "TH6 19",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "",
        "tuDien": "TH6",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": "",
        "tuDien2": "TH6",
        "diaChiLapD": "",
        "longitude": 105.82427938301035,
        "latitude": 21.010926728866902,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2576,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82408089954761,
          21.011247224456476
        ]
      },
      "properties": {
        "tenDoiTuon": "TH6 20",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "",
        "tuDien": "TH6",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": "",
        "tuDien2": "TH6",
        "diaChiLapD": "",
        "longitude": 105.82408089954761,
        "latitude": 21.011247224456476,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2577,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82426060754716,
          21.011409975859088
        ]
      },
      "properties": {
        "tenDoiTuon": "TH6 20-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "",
        "tuDien": "TH6",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": "",
        "tuDien2": "TH6",
        "diaChiLapD": "",
        "longitude": 105.82426060754716,
        "latitude": 21.011409975859088,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2578,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82392533141775,
          21.011379929459665
        ]
      },
      "properties": {
        "tenDoiTuon": "TH6 21",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "",
        "tuDien": "TH6",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": "",
        "tuDien2": "TH6",
        "diaChiLapD": "",
        "longitude": 105.82392533141775,
        "latitude": 21.011379929459665,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2579,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82377512771232,
          21.011517642074118
        ]
      },
      "properties": {
        "tenDoiTuon": "TH6 22",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "",
        "tuDien": "TH6",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": "",
        "tuDien2": "TH6",
        "diaChiLapD": "",
        "longitude": 105.82377512771232,
        "latitude": 21.011517642074118,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2580,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8239628823441,
          21.01172546268738
        ]
      },
      "properties": {
        "tenDoiTuon": "TH6  4-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "",
        "tuDien": "TH6",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": "",
        "tuDien2": "TH6",
        "diaChiLapD": "",
        "longitude": 105.8239628823441,
        "latitude": 21.01172546268738,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2581,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82365442831002,
          21.0116002695811
        ]
      },
      "properties": {
        "tenDoiTuon": "TH6 23",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "",
        "tuDien": "TH6",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": "",
        "tuDien2": "TH6",
        "diaChiLapD": "",
        "longitude": 105.82365442831002,
        "latitude": 21.0116002695811,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2582,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82359541970376,
          21.011666621940833
        ]
      },
      "properties": {
        "tenDoiTuon": "TH6 24",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "",
        "tuDien": "TH6",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": "",
        "tuDien2": "TH6",
        "diaChiLapD": "",
        "longitude": 105.82359541970376,
        "latitude": 21.011666621940833,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2583,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82350690680336,
          21.011244720586863
        ]
      },
      "properties": {
        "tenDoiTuon": "TH6 22-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "",
        "tuDien": "TH6",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": "",
        "tuDien2": "TH6",
        "diaChiLapD": "",
        "longitude": 105.82350690680336,
        "latitude": 21.011244720586863,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2584,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82330037670843,
          21.01140371619265
        ]
      },
      "properties": {
        "tenDoiTuon": "TH6 22-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "",
        "tuDien": "TH6",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": "",
        "tuDien2": "TH6",
        "diaChiLapD": "",
        "longitude": 105.82330037670843,
        "latitude": 21.01140371619265,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2585,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82306970673481,
          21.011247224456476
        ]
      },
      "properties": {
        "tenDoiTuon": "TH6 22-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "",
        "tuDien": "TH6",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": "",
        "tuDien2": "TH6",
        "diaChiLapD": "",
        "longitude": 105.82306970673481,
        "latitude": 21.011247224456476,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2586,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82347203809374,
          21.011025631876063
        ]
      },
      "properties": {
        "tenDoiTuon": "TH6 22-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "",
        "tuDien": "TH6",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": "",
        "tuDien2": "TH6",
        "diaChiLapD": "",
        "longitude": 105.82347203809374,
        "latitude": 21.011025631876063,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2587,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82332317549219,
          21.01087164361833
        ]
      },
      "properties": {
        "tenDoiTuon": "TH6 22-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "",
        "tuDien": "TH6",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": "",
        "tuDien2": "TH6",
        "diaChiLapD": "",
        "longitude": 105.82332317549219,
        "latitude": 21.01087164361833,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2588,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8237553894947,
          21.011824572168226
        ]
      },
      "properties": {
        "tenDoiTuon": "TH6 24-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "",
        "tuDien": "TH6",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": "",
        "tuDien2": "TH6",
        "diaChiLapD": "",
        "longitude": 105.8237553894947,
        "latitude": 21.011824572168226,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2589,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82353104669102,
          21.011558955834992
        ]
      },
      "properties": {
        "tenDoiTuon": "TH6 24-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "",
        "tuDien": "TH6",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": "",
        "tuDien2": "TH6",
        "diaChiLapD": "",
        "longitude": 105.82353104669102,
        "latitude": 21.011558955834992,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2590,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82354848105032,
          21.0118456479721
        ]
      },
      "properties": {
        "tenDoiTuon": "TH6 24-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "",
        "tuDien": "TH6",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": "",
        "tuDien2": "TH6",
        "diaChiLapD": "",
        "longitude": 105.82354848105032,
        "latitude": 21.0118456479721,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2591,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82338084298559,
          21.01186317498453
        ]
      },
      "properties": {
        "tenDoiTuon": "TH6 25",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "",
        "tuDien": "TH6",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": "",
        "tuDien2": "TH6",
        "diaChiLapD": "",
        "longitude": 105.82338084298559,
        "latitude": 21.01186317498453,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2592,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82310859877401,
          21.011967085087072
        ]
      },
      "properties": {
        "tenDoiTuon": "TH6 26-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "",
        "tuDien": "TH6",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": "",
        "tuDien2": "TH6",
        "diaChiLapD": "",
        "longitude": 105.82310859877401,
        "latitude": 21.011967085087072,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2593,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82321991044921,
          21.012002139080472
        ]
      },
      "properties": {
        "tenDoiTuon": "TH6 26",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "",
        "tuDien": "TH6",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": "",
        "tuDien2": "TH6",
        "diaChiLapD": "",
        "longitude": 105.82321991044921,
        "latitude": 21.012002139080472,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2594,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82339023072166,
          21.012128583776864
        ]
      },
      "properties": {
        "tenDoiTuon": "TH6 26-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "",
        "tuDien": "TH6",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": "",
        "tuDien2": "TH6",
        "diaChiLapD": "",
        "longitude": 105.82339023072166,
        "latitude": 21.012128583776864,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2595,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82261641341083,
          21.012941082286424
        ]
      },
      "properties": {
        "tenDoiTuon": "TH6 8-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "",
        "tuDien": "TH6",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "TH6",
        "diaChiLapD": "",
        "longitude": 105.82261641341083,
        "latitude": 21.012941082286424,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2596,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82247157412087,
          21.01279085177078
        ]
      },
      "properties": {
        "tenDoiTuon": "TH6 8-1-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "",
        "tuDien": "TH6",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "TH6",
        "diaChiLapD": "",
        "longitude": 105.82247157412087,
        "latitude": 21.01279085177078,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2597,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82260568457086,
          21.01261558264473
        ]
      },
      "properties": {
        "tenDoiTuon": "TH6 8-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "",
        "tuDien": "TH6",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "TH6",
        "diaChiLapD": "",
        "longitude": 105.82260568457086,
        "latitude": 21.01261558264473,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2598,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8227773459472,
          21.01243530561436
        ]
      },
      "properties": {
        "tenDoiTuon": "TH6 8-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "",
        "tuDien": "TH6",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "TH6",
        "diaChiLapD": "",
        "longitude": 105.8227773459472,
        "latitude": 21.01243530561436,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2599,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8224930317918,
          21.012179912782127
        ]
      },
      "properties": {
        "tenDoiTuon": "TH6 8-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "",
        "tuDien": "TH6",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": "",
        "tuDien2": "TH6",
        "diaChiLapD": "",
        "longitude": 105.8224930317918,
        "latitude": 21.012179912782127,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2600,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82268078642356,
          21.012330143913122
        ]
      },
      "properties": {
        "tenDoiTuon": "TH6 8-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "",
        "tuDien": "TH6",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": "",
        "tuDien2": "TH6",
        "diaChiLapD": "",
        "longitude": 105.82268078642356,
        "latitude": 21.012330143913122,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2601,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82234819251086,
          21.012690698010182
        ]
      },
      "properties": {
        "tenDoiTuon": "TH6 8-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "",
        "tuDien": "TH6",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "TH6",
        "diaChiLapD": "",
        "longitude": 105.82234819251086,
        "latitude": 21.012690698010182,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2602,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82257349805998,
          21.01248037489278
        ]
      },
      "properties": {
        "tenDoiTuon": "TH6 8-2-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "",
        "tuDien": "TH6",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "TH6",
        "diaChiLapD": "",
        "longitude": 105.82257349805998,
        "latitude": 21.01248037489278,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2603,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82311262207665,
          21.0124302979175
        ]
      },
      "properties": {
        "tenDoiTuon": "TH6 7-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "",
        "tuDien": "TH6",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "TH6",
        "diaChiLapD": "",
        "longitude": 105.82311262207665,
        "latitude": 21.0124302979175,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2604,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.822892680934,
          21.01224000525352
        ]
      },
      "properties": {
        "tenDoiTuon": "TH6  7-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "",
        "tuDien": "TH6",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "TH6",
        "diaChiLapD": "",
        "longitude": 105.822892680934,
        "latitude": 21.01224000525352,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2605,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82269419747126,
          21.013617117736626
        ]
      },
      "properties": {
        "tenDoiTuon": "TH6 10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "",
        "tuDien": "TH6",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": "",
        "tuDien2": "TH6",
        "diaChiLapD": "",
        "longitude": 105.82269419747126,
        "latitude": 21.013617117736626,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2606,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82299549895134,
          21.01385831976614
        ]
      },
      "properties": {
        "tenDoiTuon": "TH6 11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "",
        "tuDien": "TH6",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": "",
        "tuDien2": "TH6",
        "diaChiLapD": "",
        "longitude": 105.82299549895134,
        "latitude": 21.01385831976614,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2607,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82329680043144,
          21.01409952140556
        ]
      },
      "properties": {
        "tenDoiTuon": "TH6 12",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "",
        "tuDien": "TH6",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": "",
        "tuDien2": "TH6",
        "diaChiLapD": "",
        "longitude": 105.82329680043144,
        "latitude": 21.01409952140556,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2608,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8235981019115,
          21.014340722654964
        ]
      },
      "properties": {
        "tenDoiTuon": "TH6 13",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "",
        "tuDien": "TH6",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": "",
        "tuDien2": "TH6",
        "diaChiLapD": "",
        "longitude": 105.8235981019115,
        "latitude": 21.014340722654964,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2609,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8231313975488,
          21.01438829517283
        ]
      },
      "properties": {
        "tenDoiTuon": "TH6 12-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "",
        "tuDien": "TH6",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 11,
        "tuDien2": "TH6",
        "diaChiLapD": "",
        "longitude": 105.8231313975488,
        "latitude": 21.01438829517283,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2610,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82299728708986,
          21.01450847831033
        ]
      },
      "properties": {
        "tenDoiTuon": "TH6 12-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "",
        "tuDien": "TH6",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "TH6",
        "diaChiLapD": "",
        "longitude": 105.82299728708986,
        "latitude": 21.01450847831033,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2611,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82276125270074,
          21.014591104161347
        ]
      },
      "properties": {
        "tenDoiTuon": "TH6 12-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "",
        "tuDien": "TH6",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "TH6",
        "diaChiLapD": "",
        "longitude": 105.82276125270074,
        "latitude": 21.014591104161347,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2612,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82290340977396,
          21.01443336386117
        ]
      },
      "properties": {
        "tenDoiTuon": "TH6 12-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "",
        "tuDien": "TH6",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "TH6",
        "diaChiLapD": "",
        "longitude": 105.82290340977396,
        "latitude": 21.01443336386117,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2613,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82300533372202,
          21.01463116516377
        ]
      },
      "properties": {
        "tenDoiTuon": "TH6 12-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "",
        "tuDien": "TH6",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "TH6",
        "diaChiLapD": "",
        "longitude": 105.82300533372202,
        "latitude": 21.01463116516377,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2614,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82301069813754,
          21.014803928112308
        ]
      },
      "properties": {
        "tenDoiTuon": "TH6 12-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "",
        "tuDien": "TH6",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "TH6",
        "diaChiLapD": "",
        "longitude": 105.82301069813754,
        "latitude": 21.014803928112308,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2615,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82490433769273,
          21.010812802539167
        ]
      },
      "properties": {
        "tenDoiTuon": "TH6 01",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "",
        "tuDien": "TH6",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": "",
        "tuDien2": "TH6",
        "diaChiLapD": "",
        "longitude": 105.82490433769273,
        "latitude": 21.010812802539167,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2616,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82461734132846,
          21.01113204643746
        ]
      },
      "properties": {
        "tenDoiTuon": "TH6 02",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "",
        "tuDien": "TH6",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": "",
        "tuDien2": "TH6",
        "diaChiLapD": "",
        "longitude": 105.82461734132846,
        "latitude": 21.01113204643746,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2617,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8243303449642,
          21.011451289652506
        ]
      },
      "properties": {
        "tenDoiTuon": "TH6 03",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "",
        "tuDien": "TH6",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": "",
        "tuDien2": "TH6",
        "diaChiLapD": "",
        "longitude": 105.8243303449642,
        "latitude": 21.011451289652506,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2618,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82404334859991,
          21.01177053218437
        ]
      },
      "properties": {
        "tenDoiTuon": "TH6 04",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "",
        "tuDien": "TH6",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": "",
        "tuDien2": "TH6",
        "diaChiLapD": "",
        "longitude": 105.82404334859991,
        "latitude": 21.01177053218437,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2619,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82375635223566,
          21.012089774033026
        ]
      },
      "properties": {
        "tenDoiTuon": "TH6 05",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "",
        "tuDien": "TH6",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": "",
        "tuDien2": "TH6",
        "diaChiLapD": "",
        "longitude": 105.82375635223566,
        "latitude": 21.012089774033026,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2620,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8234693558714,
          21.012409015198422
        ]
      },
      "properties": {
        "tenDoiTuon": "TH6 06",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "",
        "tuDien": "TH6",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": "",
        "tuDien2": "TH6",
        "diaChiLapD": "",
        "longitude": 105.8234693558714,
        "latitude": 21.012409015198422,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2621,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82318235950713,
          21.01272825568058
        ]
      },
      "properties": {
        "tenDoiTuon": "TH6 07",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "",
        "tuDien": "TH6",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": "",
        "tuDien2": "TH6",
        "diaChiLapD": "",
        "longitude": 105.82318235950713,
        "latitude": 21.01272825568058,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2622,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82289536314285,
          21.013047495479494
        ]
      },
      "properties": {
        "tenDoiTuon": "TH6 08",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "",
        "tuDien": "TH6",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": "",
        "tuDien2": "TH6",
        "diaChiLapD": "",
        "longitude": 105.82289536314285,
        "latitude": 21.013047495479494,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2623,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82260836677861,
          21.013366734595152
        ]
      },
      "properties": {
        "tenDoiTuon": "TH6 09",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "",
        "tuDien": "TH6",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": "",
        "tuDien2": "TH6",
        "diaChiLapD": "",
        "longitude": 105.82260836677861,
        "latitude": 21.013366734595152,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2624,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82371209578545,
          21.01431192875577
        ]
      },
      "properties": {
        "tenDoiTuon": "TH6 14",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "",
        "tuDien": "TH6",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": "",
        "tuDien2": "TH6",
        "diaChiLapD": "",
        "longitude": 105.82371209578545,
        "latitude": 21.01431192875577,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2625,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82348142580285,
          21.014542279801866
        ]
      },
      "properties": {
        "tenDoiTuon": "TH6 15",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "",
        "tuDien": "TH6",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": "",
        "tuDien2": "TH6",
        "diaChiLapD": "",
        "longitude": 105.82348142580285,
        "latitude": 21.014542279801866,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2626,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82390521482374,
          21.010474778840255
        ]
      },
      "properties": {
        "tenDoiTuon": "TH6 18-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "",
        "tuDien": "TH6",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": "",
        "tuDien2": "TH6",
        "diaChiLapD": "",
        "longitude": 105.82390521482374,
        "latitude": 21.010474778840255,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2627,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8240312786505,
          21.0106175000499
        ]
      },
      "properties": {
        "tenDoiTuon": "TH6 18-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "",
        "tuDien": "TH6",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": "",
        "tuDien2": "TH6",
        "diaChiLapD": "",
        "longitude": 105.8240312786505,
        "latitude": 21.0106175000499,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2628,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82445774988808,
          21.01012423493962
        ]
      },
      "properties": {
        "tenDoiTuon": "TH6 16-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "",
        "tuDien": "TH6",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": "",
        "tuDien2": "TH6",
        "diaChiLapD": "",
        "longitude": 105.82445774988808,
        "latitude": 21.01012423493962,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2629,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82369600252105,
          21.010807794782817
        ]
      },
      "properties": {
        "tenDoiTuon": "TH6 20-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "",
        "tuDien": "TH6",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": "",
        "tuDien2": "TH6",
        "diaChiLapD": "",
        "longitude": 105.82369600252105,
        "latitude": 21.010807794782817,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2630,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82390521482374,
          21.011050670603705
        ]
      },
      "properties": {
        "tenDoiTuon": "TH6 20-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "",
        "tuDien": "TH6",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": "",
        "tuDien2": "TH6",
        "diaChiLapD": "",
        "longitude": 105.82390521482374,
        "latitude": 21.011050670603705,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2631,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82394276575008,
          21.011694164422117
        ]
      },
      "properties": {
        "tenDoiTuon": "TH6 22-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "",
        "tuDien": "TH6",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": "",
        "tuDien2": "TH6",
        "diaChiLapD": "",
        "longitude": 105.82394276575008,
        "latitude": 21.011694164422117,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2632,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82304422573431,
          21.011979604370737
        ]
      },
      "properties": {
        "tenDoiTuon": "TH6 26-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "",
        "tuDien": "TH6",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": "",
        "tuDien2": "TH6",
        "diaChiLapD": "",
        "longitude": 105.82304422573431,
        "latitude": 21.011979604370737,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2633,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82085123142564,
          21.005271945416858
        ]
      },
      "properties": {
        "tenDoiTuon": "12",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Tập thể Vĩnh Hồ A1 đến A5",
        "tuDien": "VHO1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 1",
        "diaChiLapD": "",
        "longitude": 105.82085123142564,
        "latitude": 21.005271945416858,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2634,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82073350918314,
          21.005244751956802
        ]
      },
      "properties": {
        "tenDoiTuon": "12-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Tập thể Vĩnh Hồ A1 đến A5",
        "tuDien": "VHO1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 1",
        "diaChiLapD": "",
        "longitude": 105.82073350918314,
        "latitude": 21.005244751956802,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2635,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82045694168704,
          21.005041262608298
        ]
      },
      "properties": {
        "tenDoiTuon": "12-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Tập thể Vĩnh Hồ A1 đến A5",
        "tuDien": "VHO1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 1",
        "diaChiLapD": "",
        "longitude": 105.82045694168704,
        "latitude": 21.005041262608298,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2636,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82065940666853,
          21.00549729124872
        ]
      },
      "properties": {
        "tenDoiTuon": "13",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Tập thể Vĩnh Hồ A1 đến A5",
        "tuDien": "VHO1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 1",
        "diaChiLapD": "",
        "longitude": 105.82065940666853,
        "latitude": 21.00549729124872,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2637,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82049310970768,
          21.00543719606223
        ]
      },
      "properties": {
        "tenDoiTuon": "13-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Tập thể Vĩnh Hồ A1 đến A5",
        "tuDien": "VHO1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 1",
        "diaChiLapD": "",
        "longitude": 105.82049310970768,
        "latitude": 21.00543719606223,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2638,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82025171089411,
          21.005256910357556
        ]
      },
      "properties": {
        "tenDoiTuon": "13-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Tập thể Vĩnh Hồ A1 đến A5",
        "tuDien": "VHO1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 1",
        "diaChiLapD": "",
        "longitude": 105.82025171089411,
        "latitude": 21.005256910357556,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2639,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82054138947399,
          21.005630001366406
        ]
      },
      "properties": {
        "tenDoiTuon": "14",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Tập thể Vĩnh Hồ A1 đến A5",
        "tuDien": "VHO1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 1",
        "diaChiLapD": "",
        "longitude": 105.82054138947399,
        "latitude": 21.005630001366406,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2640,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8204126434395,
          21.005680080626014
        ]
      },
      "properties": {
        "tenDoiTuon": "15",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Tập thể Vĩnh Hồ A1 đến A5",
        "tuDien": "VHO1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 1",
        "diaChiLapD": "",
        "longitude": 105.8204126434395,
        "latitude": 21.005680080626014,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2641,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82019270229684,
          21.005519826937622
        ]
      },
      "properties": {
        "tenDoiTuon": "15-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Tập thể Vĩnh Hồ A1 đến A5",
        "tuDien": "VHO1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 1",
        "diaChiLapD": "",
        "longitude": 105.82019270229684,
        "latitude": 21.005519826937622,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2642,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82024634647863,
          21.005910445000872
        ]
      },
      "properties": {
        "tenDoiTuon": "15-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Tập thể Vĩnh Hồ A1 đến A5",
        "tuDien": "VHO1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 1",
        "diaChiLapD": "",
        "longitude": 105.82024634647863,
        "latitude": 21.005910445000872,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2643,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8200210409205,
          21.00572765590661
        ]
      },
      "properties": {
        "tenDoiTuon": "15-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Tập thể Vĩnh Hồ A1 đến A5",
        "tuDien": "VHO1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 1",
        "diaChiLapD": "",
        "longitude": 105.8200210409205,
        "latitude": 21.00572765590661,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2644,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82050718422825,
          21.004370458767767
        ]
      },
      "properties": {
        "tenDoiTuon": "10-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Tập thể Vĩnh Hồ A1 đến A5",
        "tuDien": "VHO1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 1",
        "diaChiLapD": "",
        "longitude": 105.82050718422825,
        "latitude": 21.004370458767767,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2645,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83540431528724,
          21.01941477530547
        ]
      },
      "properties": {
        "tenDoiTuon": "9-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Hòa Bình",
        "tuDien": "KTH1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 1",
        "diaChiLapD": "",
        "longitude": 105.83540431528724,
        "latitude": 21.01941477530547,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2646,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83533726006674,
          21.019685178114322
        ]
      },
      "properties": {
        "tenDoiTuon": "9-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Hòa Bình",
        "tuDien": "KTH1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 1",
        "diaChiLapD": "",
        "longitude": 105.83533726006674,
        "latitude": 21.019685178114322,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2647,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83559206991902,
          21.019928039478838
        ]
      },
      "properties": {
        "tenDoiTuon": "9-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Hòa Bình",
        "tuDien": "KTH1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 1",
        "diaChiLapD": "",
        "longitude": 105.83559206991902,
        "latitude": 21.019928039478838,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2648,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83537919763731,
          21.019946071027725
        ]
      },
      "properties": {
        "tenDoiTuon": "9-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Hòa Bình",
        "tuDien": "KTH1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 1",
        "diaChiLapD": "",
        "longitude": 105.83537919763731,
        "latitude": 21.019946071027725,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2649,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83543650179811,
          21.019660140837946
        ]
      },
      "properties": {
        "tenDoiTuon": "9-7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Hòa Bình",
        "tuDien": "KTH1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 1",
        "diaChiLapD": "",
        "longitude": 105.83543650179811,
        "latitude": 21.019660140837946,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2650,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8354284551749,
          21.01996058787866
        ]
      },
      "properties": {
        "tenDoiTuon": "9-9",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Hòa Bình",
        "tuDien": "KTH1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 1",
        "diaChiLapD": "",
        "longitude": 105.8354284551749,
        "latitude": 21.01996058787866,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2651,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8353667643609,
          21.020168396727982
        ]
      },
      "properties": {
        "tenDoiTuon": "9-10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Hòa Bình",
        "tuDien": "KTH1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 1",
        "diaChiLapD": "",
        "longitude": 105.8353667643609,
        "latitude": 21.020168396727982,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2652,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83545795946904,
          21.020366190423726
        ]
      },
      "properties": {
        "tenDoiTuon": "9-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Hòa Bình",
        "tuDien": "KTH1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 1",
        "diaChiLapD": "",
        "longitude": 105.83545795946904,
        "latitude": 21.020366190423726,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2653,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83565814736824,
          21.019540467340672
        ]
      },
      "properties": {
        "tenDoiTuon": "9-8",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Hòa Bình",
        "tuDien": "KTH1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 1",
        "diaChiLapD": "",
        "longitude": 105.83565814736824,
        "latitude": 21.019540467340672,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2654,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8351977851923,
          21.020411257305437
        ]
      },
      "properties": {
        "tenDoiTuon": "9-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Hòa Bình",
        "tuDien": "KTH1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 1",
        "diaChiLapD": "",
        "longitude": 105.8351977851923,
        "latitude": 21.020411257305437,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2655,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83557468211839,
          21.020369635616408
        ]
      },
      "properties": {
        "tenDoiTuon": "9-11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Hòa Bình",
        "tuDien": "KTH1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 1",
        "diaChiLapD": "",
        "longitude": 105.83557468211839,
        "latitude": 21.020369635616408,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2656,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83579899282181,
          21.020379876203542
        ]
      },
      "properties": {
        "tenDoiTuon": "9-12",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Hòa Bình",
        "tuDien": "KTH1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 1",
        "diaChiLapD": "",
        "longitude": 105.83579899282181,
        "latitude": 21.020379876203542,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2657,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82121730613937,
          21.00458083702548
        ]
      },
      "properties": {
        "tenDoiTuon": "9-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Chùa Phúc Khánh Thịnh Quang",
        "tuDien": "VHO1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 1",
        "diaChiLapD": "",
        "longitude": 105.82121730613937,
        "latitude": 21.00458083702548,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2658,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82112879324798,
          21.004443118008098
        ]
      },
      "properties": {
        "tenDoiTuon": "9-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Chùa Phúc Khánh Thịnh Quang",
        "tuDien": "VHO1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 1",
        "diaChiLapD": "",
        "longitude": 105.82112879324798,
        "latitude": 21.004443118008098,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2659,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82127095032116,
          21.004330438717343
        ]
      },
      "properties": {
        "tenDoiTuon": "VH1 9-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Chùa Phúc Khánh Thịnh Quang",
        "tuDien": "VHO1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 1",
        "diaChiLapD": "",
        "longitude": 105.82127095032116,
        "latitude": 21.004330438717343,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2660,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82052127288895,
          21.003856558771485
        ]
      },
      "properties": {
        "tenDoiTuon": "16",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Thịnh Quang",
        "tuDien": "VHO1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "BTLT",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 1",
        "diaChiLapD": "",
        "longitude": 105.82052127288895,
        "latitude": 21.003856558771485,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2661,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82046336880568,
          21.004102270958086
        ]
      },
      "properties": {
        "tenDoiTuon": "17",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Thịnh Quang",
        "tuDien": "VHO1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "BTLT",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 1",
        "diaChiLapD": "",
        "longitude": 105.82046336880568,
        "latitude": 21.004102270958086,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2662,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82038448023123,
          21.003849046791284
        ]
      },
      "properties": {
        "tenDoiTuon": "16-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 17 Thịnh Quang",
        "tuDien": "VHO1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 1",
        "diaChiLapD": "",
        "longitude": 105.82038448023123,
        "latitude": 21.003849046791284,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2663,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8201551513615,
          21.003756399012374
        ]
      },
      "properties": {
        "tenDoiTuon": "VH1 16-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 17 Thịnh Quang",
        "tuDien": "VHO1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 1",
        "diaChiLapD": "",
        "longitude": 105.8201551513615,
        "latitude": 21.003756399012374,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2664,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8202651219328,
          21.004035594172706
        ]
      },
      "properties": {
        "tenDoiTuon": "16-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 17 Thịnh Quang",
        "tuDien": "VHO1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 1",
        "diaChiLapD": "",
        "longitude": 105.8202651219328,
        "latitude": 21.004035594172706,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2665,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82013400432356,
          21.003887483901757
        ]
      },
      "properties": {
        "tenDoiTuon": "16-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 17 Thịnh Quang",
        "tuDien": "VHO1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 1",
        "diaChiLapD": "",
        "longitude": 105.82013400432356,
        "latitude": 21.003887483901757,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2666,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8199551020883,
          21.003902903247326
        ]
      },
      "properties": {
        "tenDoiTuon": "16-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 17 Thịnh Quang",
        "tuDien": "VHO1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 1",
        "diaChiLapD": "",
        "longitude": 105.8199551020883,
        "latitude": 21.003902903247326,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2667,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81982523964753,
          21.003929174554546
        ]
      },
      "properties": {
        "tenDoiTuon": "16-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 17 Thịnh Quang",
        "tuDien": "VHO1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 1",
        "diaChiLapD": "",
        "longitude": 105.81982523964753,
        "latitude": 21.003929174554546,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2668,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81968040036658,
          21.003891614670493
        ]
      },
      "properties": {
        "tenDoiTuon": "VH1 16-7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 17 Thịnh Quang",
        "tuDien": "VHO1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 1",
        "diaChiLapD": "",
        "longitude": 105.81968040036658,
        "latitude": 21.003891614670493,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2669,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80274392683597,
          21.02191582910976
        ]
      },
      "properties": {
        "tenDoiTuon": "8-9",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 898 đường Láng+ 157 Chùa Láng",
        "tuDien": "850ĐL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "850 Đường Láng",
        "diaChiLapD": "",
        "longitude": 105.80274392683597,
        "latitude": 21.02191582910976,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2670,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80263932068911,
          21.02218372369767
        ]
      },
      "properties": {
        "tenDoiTuon": "8-10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 898 đường Láng+ 157 Chùa Láng",
        "tuDien": "850ĐL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "850 Đường Láng",
        "diaChiLapD": "",
        "longitude": 105.80263932068911,
        "latitude": 21.02218372369767,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2671,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83625627998215,
          21.01888949615261
        ]
      },
      "properties": {
        "tenDoiTuon": "35-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Lệnh Cư",
        "tuDien": "KTH1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 1",
        "diaChiLapD": "",
        "longitude": 105.83625627998215,
        "latitude": 21.01888949615261,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2672,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83615691046542,
          21.01868588629176
        ]
      },
      "properties": {
        "tenDoiTuon": "35",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Lệnh Cư",
        "tuDien": "KTH1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 1",
        "diaChiLapD": "",
        "longitude": 105.83615691046542,
        "latitude": 21.01868588629176,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2673,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83611412289997,
          21.018483889591522
        ]
      },
      "properties": {
        "tenDoiTuon": "36",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Lệnh Cư",
        "tuDien": "KTH1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 1",
        "diaChiLapD": "",
        "longitude": 105.83611412289997,
        "latitude": 21.018483889591522,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2674,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83638988474027,
          21.01864794423549
        ]
      },
      "properties": {
        "tenDoiTuon": "35-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Lệnh Cư",
        "tuDien": "KTH1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 1",
        "diaChiLapD": "",
        "longitude": 105.83638988474027,
        "latitude": 21.01864794423549,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2675,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83652986529762,
          21.018626603136486
        ]
      },
      "properties": {
        "tenDoiTuon": "35-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Lệnh Cư",
        "tuDien": "KTH1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 1",
        "diaChiLapD": "",
        "longitude": 105.83652986529762,
        "latitude": 21.018626603136486,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2676,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8368463659639,
          21.01855149076152
        ]
      },
      "properties": {
        "tenDoiTuon": "32",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Công Trắng",
        "tuDien": "KTH1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 1",
        "diaChiLapD": "",
        "longitude": 105.8368463659639,
        "latitude": 21.01855149076152,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2677,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83688391689024,
          21.01867417428808
        ]
      },
      "properties": {
        "tenDoiTuon": "33",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Công Trắng",
        "tuDien": "KTH1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 1",
        "diaChiLapD": "",
        "longitude": 105.83688391689024,
        "latitude": 21.01867417428808,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2678,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83657009843171,
          21.018771820289
        ]
      },
      "properties": {
        "tenDoiTuon": "34",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Công Trắng",
        "tuDien": "KTH1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 1",
        "diaChiLapD": "",
        "longitude": 105.83657009843171,
        "latitude": 21.018771820289,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2679,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82035564647556,
          21.004438110045484
        ]
      },
      "properties": {
        "tenDoiTuon": "18",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Thịnh Quang",
        "tuDien": "VHO1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "BTLT",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 1",
        "diaChiLapD": "",
        "longitude": 105.82035564647556,
        "latitude": 21.004438110045484,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2680,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8203204774489,
          21.00473264580425
        ]
      },
      "properties": {
        "tenDoiTuon": "19",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Thịnh Quang",
        "tuDien": "VHO1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "BTLT",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 1",
        "diaChiLapD": "",
        "longitude": 105.8203204774489,
        "latitude": 21.00473264580425,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2681,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82041598653403,
          21.00479538022274
        ]
      },
      "properties": {
        "tenDoiTuon": "19-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Thịnh Quang",
        "tuDien": "VHO1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "BTLT",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 1",
        "diaChiLapD": "",
        "longitude": 105.82041598653403,
        "latitude": 21.00479538022274,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2682,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82009547219882,
          21.00517177537008
        ]
      },
      "properties": {
        "tenDoiTuon": "20",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Thịnh Quang",
        "tuDien": "VHO1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "BTLT",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 1",
        "diaChiLapD": "",
        "longitude": 105.82009547219882,
        "latitude": 21.00517177537008,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2683,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82015045748449,
          21.005046576764325
        ]
      },
      "properties": {
        "tenDoiTuon": "20-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 89/Thịnh Quang",
        "tuDien": "VHO1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "BTLT",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 1",
        "diaChiLapD": "",
        "longitude": 105.82015045748449,
        "latitude": 21.005046576764325,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2684,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81993722187018,
          21.005288209979124
        ]
      },
      "properties": {
        "tenDoiTuon": "21",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Thịnh Quang",
        "tuDien": "VHO1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "BTLT",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 1",
        "diaChiLapD": "",
        "longitude": 105.81993722187018,
        "latitude": 21.005288209979124,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2685,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8198245690911,
          21.005494787287635
        ]
      },
      "properties": {
        "tenDoiTuon": "22",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Thịnh Quang",
        "tuDien": "VHO1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "BTLT",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 1",
        "diaChiLapD": "",
        "longitude": 105.8198245690911,
        "latitude": 21.005494787287635,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2686,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81954226659599,
          21.005921086828156
        ]
      },
      "properties": {
        "tenDoiTuon": "24",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Thịnh Quang",
        "tuDien": "VHO1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "BTLT",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 1",
        "diaChiLapD": "",
        "longitude": 105.81954226659599,
        "latitude": 21.005921086828156,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2687,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81972331570842,
          21.005705746243514
        ]
      },
      "properties": {
        "tenDoiTuon": "23",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Thịnh Quang",
        "tuDien": "VHO1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "BTLT",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 1",
        "diaChiLapD": "",
        "longitude": 105.81972331570842,
        "latitude": 21.005705746243514,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2688,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83605261098826,
          21.018247587281323
        ]
      },
      "properties": {
        "tenDoiTuon": "37",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Lệnh Cư",
        "tuDien": "KTH1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 1",
        "diaChiLapD": "",
        "longitude": 105.83605261098826,
        "latitude": 21.018247587281323,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2689,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83617964880386,
          21.01820565160925
        ]
      },
      "properties": {
        "tenDoiTuon": "37-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Lệnh Cư",
        "tuDien": "KTH1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 1",
        "diaChiLapD": "",
        "longitude": 105.83617964880386,
        "latitude": 21.01820565160925,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2690,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83615969681652,
          21.018309148247546
        ]
      },
      "properties": {
        "tenDoiTuon": "37-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Lệnh Cư",
        "tuDien": "KTH1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 1",
        "diaChiLapD": "",
        "longitude": 105.83615969681652,
        "latitude": 21.018309148247546,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2691,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.835784211195,
          21.018333664659124
        ]
      },
      "properties": {
        "tenDoiTuon": "45",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Lệnh Cư",
        "tuDien": "KTH1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 1",
        "diaChiLapD": "",
        "longitude": 105.835784211195,
        "latitude": 21.018333664659124,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2692,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83596432844327,
          21.01805482730564
        ]
      },
      "properties": {
        "tenDoiTuon": "38",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Lệnh Cư",
        "tuDien": "KTH1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 1",
        "diaChiLapD": "",
        "longitude": 105.83596432844327,
        "latitude": 21.01805482730564,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2693,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8359694142882,
          21.017794920529504
        ]
      },
      "properties": {
        "tenDoiTuon": "39",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Lệnh Cư",
        "tuDien": "KTH1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 1",
        "diaChiLapD": "",
        "longitude": 105.8359694142882,
        "latitude": 21.017794920529504,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2694,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81997527035419,
          21.004676849030364
        ]
      },
      "properties": {
        "tenDoiTuon": "19-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 65/Thịnh Quang",
        "tuDien": "VHO1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 1",
        "diaChiLapD": "",
        "longitude": 105.81997527035419,
        "latitude": 21.004676849030364,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2695,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82017459736313,
          21.004628412659905
        ]
      },
      "properties": {
        "tenDoiTuon": "19-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 65/Thịnh Quang",
        "tuDien": "VHO1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 1",
        "diaChiLapD": "",
        "longitude": 105.82017459736313,
        "latitude": 21.004628412659905,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2696,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83572081617068,
          21.017867618412414
        ]
      },
      "properties": {
        "tenDoiTuon": "39-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Lệnh Cư",
        "tuDien": "KTH1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 1",
        "diaChiLapD": "",
        "longitude": 105.83572081617068,
        "latitude": 21.017867618412414,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2697,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83551330808729,
          21.017915537800096
        ]
      },
      "properties": {
        "tenDoiTuon": "39-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Lệnh Cư",
        "tuDien": "KTH1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 1",
        "diaChiLapD": "",
        "longitude": 105.83551330808729,
        "latitude": 21.017915537800096,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2698,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82001232372288,
          21.00442433813612
        ]
      },
      "properties": {
        "tenDoiTuon": "18-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 41/Thịnh Quang",
        "tuDien": "VHO1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 1",
        "diaChiLapD": "",
        "longitude": 105.82001232372288,
        "latitude": 21.00442433813612,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2699,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81974276171006,
          21.004420582160765
        ]
      },
      "properties": {
        "tenDoiTuon": "18-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 41/Thịnh Quang",
        "tuDien": "VHO1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 1",
        "diaChiLapD": "",
        "longitude": 105.81974276171006,
        "latitude": 21.004420582160765,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2700,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81957914696594,
          21.004336698683897
        ]
      },
      "properties": {
        "tenDoiTuon": "18-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 41/Thịnh Quang",
        "tuDien": "VHO1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 1",
        "diaChiLapD": "",
        "longitude": 105.81957914696594,
        "latitude": 21.004336698683897,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2701,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8193766401826,
          21.004251563167223
        ]
      },
      "properties": {
        "tenDoiTuon": "18-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 41/Thịnh Quang",
        "tuDien": "VHO1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 1",
        "diaChiLapD": "",
        "longitude": 105.8193766401826,
        "latitude": 21.004251563167223,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2702,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81943296657214,
          21.00453451337373
        ]
      },
      "properties": {
        "tenDoiTuon": "18-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 41/Thịnh Quang",
        "tuDien": "VHO1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 1",
        "diaChiLapD": "",
        "longitude": 105.81943296657214,
        "latitude": 21.00453451337373,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2703,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81927739845122,
          21.00443435406966
        ]
      },
      "properties": {
        "tenDoiTuon": "VH1 18-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 41/Thịnh Quang",
        "tuDien": "VHO1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 1",
        "diaChiLapD": "",
        "longitude": 105.81927739845122,
        "latitude": 21.00443435406966,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2704,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81999232825785,
          21.004986605575652
        ]
      },
      "properties": {
        "tenDoiTuon": "20-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 89/Thịnh Quang",
        "tuDien": "VHO1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 1",
        "diaChiLapD": "",
        "longitude": 105.81999232825785,
        "latitude": 21.004986605575652,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2705,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81973355403774,
          21.004958566294302
        ]
      },
      "properties": {
        "tenDoiTuon": "20-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 89/Thịnh Quang",
        "tuDien": "VHO1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 1",
        "diaChiLapD": "",
        "longitude": 105.81973355403774,
        "latitude": 21.004958566294302,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2706,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83563440013832,
          21.01839685784479
        ]
      },
      "properties": {
        "tenDoiTuon": "45-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Lệnh Cư",
        "tuDien": "KTH1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 1",
        "diaChiLapD": "",
        "longitude": 105.83563440013832,
        "latitude": 21.01839685784479,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2707,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8358230104991,
          21.018393424997267
        ]
      },
      "properties": {
        "tenDoiTuon": "45-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Lệnh Cư",
        "tuDien": "KTH1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 1",
        "diaChiLapD": "",
        "longitude": 105.8358230104991,
        "latitude": 21.018393424997267,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2708,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83557473679316,
          21.018178326656642
        ]
      },
      "properties": {
        "tenDoiTuon": "46",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Lệnh Cư",
        "tuDien": "KTH1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 1",
        "diaChiLapD": "",
        "longitude": 105.83557473679316,
        "latitude": 21.018178326656642,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2709,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.835557365883,
          21.018036698760064
        ]
      },
      "properties": {
        "tenDoiTuon": "47",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Lệnh Cư",
        "tuDien": "KTH1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 1",
        "diaChiLapD": "",
        "longitude": 105.835557365883,
        "latitude": 21.018036698760064,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2710,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83550856138878,
          21.01832970474289
        ]
      },
      "properties": {
        "tenDoiTuon": "59",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Toàn Thắng",
        "tuDien": "KTH1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 1",
        "diaChiLapD": "",
        "longitude": 105.83550856138878,
        "latitude": 21.01832970474289,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2711,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83542391502553,
          21.018047049477527
        ]
      },
      "properties": {
        "tenDoiTuon": "60",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Toàn Thắng",
        "tuDien": "KTH1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 1",
        "diaChiLapD": "",
        "longitude": 105.83542391502553,
        "latitude": 21.018047049477527,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2712,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83530683951217,
          21.01808992747601
        ]
      },
      "properties": {
        "tenDoiTuon": "60-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Toàn Thắng",
        "tuDien": "KTH1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 1",
        "diaChiLapD": "",
        "longitude": 105.83530683951217,
        "latitude": 21.01808992747601,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2713,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84023182111973,
          21.016519027684406
        ]
      },
      "properties": {
        "tenDoiTuon": "15-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 202,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 1 Khâm Thiên",
        "tuDien": "KTG",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên Ga",
        "diaChiLapD": "",
        "longitude": 105.84023182111973,
        "latitude": 21.016519027684406,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2714,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84039275366511,
          21.016509012562214
        ]
      },
      "properties": {
        "tenDoiTuon": "19-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 202,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "",
        "tuDien": "KTG",
        "chuDauTu": "",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên Ga",
        "diaChiLapD": "",
        "longitude": 105.84039275366511,
        "latitude": 21.016509012562214,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2715,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8404048236089,
          21.016417624535876
        ]
      },
      "properties": {
        "tenDoiTuon": "19-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 202,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "",
        "tuDien": "KTG",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên Ga",
        "diaChiLapD": "",
        "longitude": 105.8404048236089,
        "latitude": 21.016417624535876,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2716,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81922128675629,
          21.005812625031787
        ]
      },
      "properties": {
        "tenDoiTuon": "24-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 22 Đường Láng",
        "tuDien": "VHO1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 1",
        "diaChiLapD": "",
        "longitude": 105.81922128675629,
        "latitude": 21.005812625031787,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2717,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8190520928931,
          21.005765215331873
        ]
      },
      "properties": {
        "tenDoiTuon": "24-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 22 Đường Láng",
        "tuDien": "VHO1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 1",
        "diaChiLapD": "",
        "longitude": 105.8190520928931,
        "latitude": 21.005765215331873,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2718,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81905879842144,
          21.005567402274348
        ]
      },
      "properties": {
        "tenDoiTuon": "24-3-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 22 Đường Láng",
        "tuDien": "VHO1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 1",
        "diaChiLapD": "",
        "longitude": 105.81905879842144,
        "latitude": 21.005567402274348,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2719,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81917681561599,
          21.005249398454545
        ]
      },
      "properties": {
        "tenDoiTuon": "24-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 22 Đường Láng",
        "tuDien": "VHO1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 1",
        "diaChiLapD": "",
        "longitude": 105.81917681561599,
        "latitude": 21.005249398454545,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2720,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81897028552103,
          21.00517928728454
        ]
      },
      "properties": {
        "tenDoiTuon": "24-7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 22 Đường Láng",
        "tuDien": "VHO1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 1",
        "diaChiLapD": "",
        "longitude": 105.81897028552103,
        "latitude": 21.00517928728454,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2721,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81869670019661,
          21.00510166416528
        ]
      },
      "properties": {
        "tenDoiTuon": "24-8",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 22 Đường Láng",
        "tuDien": "VHO1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 1",
        "diaChiLapD": "",
        "longitude": 105.81869670019661,
        "latitude": 21.00510166416528,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2722,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81831750281881,
          21.004896025310092
        ]
      },
      "properties": {
        "tenDoiTuon": "24-9",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 22 Đường Láng",
        "tuDien": "VHO1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 1",
        "diaChiLapD": "",
        "longitude": 105.81831750281881,
        "latitude": 21.004896025310092,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2723,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81844009368841,
          21.004645248261877
        ]
      },
      "properties": {
        "tenDoiTuon": "VH1 24-10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 22 Đường Láng",
        "tuDien": "VHO1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 1",
        "diaChiLapD": "",
        "longitude": 105.81844009368841,
        "latitude": 21.004645248261877,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2724,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81890008109144,
          21.006041150724336
        ]
      },
      "properties": {
        "tenDoiTuon": "24-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 22 Đường Láng",
        "tuDien": "VHO1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 1",
        "diaChiLapD": "",
        "longitude": 105.81890008109144,
        "latitude": 21.006041150724336,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2725,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81873961554743,
          21.005472251596633
        ]
      },
      "properties": {
        "tenDoiTuon": "24-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 22 Đường Láng",
        "tuDien": "VHO1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 1",
        "diaChiLapD": "",
        "longitude": 105.81873961554743,
        "latitude": 21.005472251596633,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2726,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81861623392841,
          21.005740175719726
        ]
      },
      "properties": {
        "tenDoiTuon": "24-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 22 Đường Láng",
        "tuDien": "VHO1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 1",
        "diaChiLapD": "",
        "longitude": 105.81861623392841,
        "latitude": 21.005740175719726,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2727,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81831750281881,
          21.004896025310092
        ]
      },
      "properties": {
        "tenDoiTuon": "24-9",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 122 Đường Láng",
        "tuDien": "VHO1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 1",
        "diaChiLapD": "",
        "longitude": 105.81831750281881,
        "latitude": 21.004896025310092,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2728,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83536042217412,
          21.01801819180807
        ]
      },
      "properties": {
        "tenDoiTuon": "48",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Lệnh Cư",
        "tuDien": "KTH1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 1",
        "diaChiLapD": "",
        "longitude": 105.83536042217412,
        "latitude": 21.01801819180807,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2729,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82069058733576,
          21.003789263946445
        ]
      },
      "properties": {
        "tenDoiTuon": "VH1 16-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 438 Thịnh Quang",
        "tuDien": "VHO1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 1",
        "diaChiLapD": "",
        "longitude": 105.82069058733576,
        "latitude": 21.003789263946445,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2730,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82064767199394,
          21.003909455622527
        ]
      },
      "properties": {
        "tenDoiTuon": "16-7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 438 Thịnh Quang",
        "tuDien": "VHO1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 1",
        "diaChiLapD": "",
        "longitude": 105.82064767199394,
        "latitude": 21.003909455622527,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2731,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82056586461289,
          21.004103514895373
        ]
      },
      "properties": {
        "tenDoiTuon": "16-8",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 438 Thịnh Quang",
        "tuDien": "VHO1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 1",
        "diaChiLapD": "",
        "longitude": 105.82056586461289,
        "latitude": 21.004103514895373,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2732,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8351587768087,
          21.018492325294442
        ]
      },
      "properties": {
        "tenDoiTuon": "58",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Toàn Thắng",
        "tuDien": "KTH1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 1",
        "diaChiLapD": "",
        "longitude": 105.8351587768087,
        "latitude": 21.018492325294442,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2733,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83931584674464,
          21.018065104192512
        ]
      },
      "properties": {
        "tenDoiTuon": "27-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 202,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Miếu Chợ",
        "tuDien": "KTG",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên Ga",
        "diaChiLapD": "",
        "longitude": 105.83931584674464,
        "latitude": 21.018065104192512,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2734,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83916564303922,
          21.01781973603542
        ]
      },
      "properties": {
        "tenDoiTuon": "27-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 202,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Miếu Chợ",
        "tuDien": "KTG",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên Ga",
        "diaChiLapD": "",
        "longitude": 105.83916564303922,
        "latitude": 21.01781973603542,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2735,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83874855953772,
          21.017516780918502
        ]
      },
      "properties": {
        "tenDoiTuon": "31",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Khâm Thiên",
        "tuDien": "KTG",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên Ga",
        "diaChiLapD": "",
        "longitude": 105.83874855953772,
        "latitude": 21.017516780918502,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2736,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83883841354199,
          21.018206566263572
        ]
      },
      "properties": {
        "tenDoiTuon": "28-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 202,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 42 Chợ Khâm Thiên",
        "tuDien": "KTG",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên Ga",
        "diaChiLapD": "",
        "longitude": 105.83883841354199,
        "latitude": 21.018206566263572,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2737,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83868820983659,
          21.01785729241361
        ]
      },
      "properties": {
        "tenDoiTuon": "30-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Khâm Đức",
        "tuDien": "KTG",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên Ga",
        "diaChiLapD": "",
        "longitude": 105.83868820983659,
        "latitude": 21.01785729241361,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2738,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83862517792768,
          21.017684533002154
        ]
      },
      "properties": {
        "tenDoiTuon": "30-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Khâm Đức",
        "tuDien": "KTG",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên Ga",
        "diaChiLapD": "",
        "longitude": 105.83862517792768,
        "latitude": 21.017684533002154,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2739,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83849643189319,
          21.017762149574065
        ]
      },
      "properties": {
        "tenDoiTuon": "30-2-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Ngõ Khâm Đức",
        "tuDien": "KTG",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "Khâm Thiên Ga",
        "diaChiLapD": "",
        "longitude": 105.83849643189319,
        "latitude": 21.017762149574065,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2740,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83852191287572,
          21.01765699162789
        ]
      },
      "properties": {
        "tenDoiTuon": "30-2-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Ngõ Khâm Đức",
        "tuDien": "KTG",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên Ga",
        "diaChiLapD": "",
        "longitude": 105.83852191287572,
        "latitude": 21.01765699162789,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2741,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83860908467226,
          21.017543070436698
        ]
      },
      "properties": {
        "tenDoiTuon": "31-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Khâm Đức",
        "tuDien": "KTG",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên Ga",
        "diaChiLapD": "",
        "longitude": 105.83860908467226,
        "latitude": 21.017543070436698,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2742,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83844144660752,
          21.01797872463302
        ]
      },
      "properties": {
        "tenDoiTuon": "30-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Khâm Đức",
        "tuDien": "KTG",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên Ga",
        "diaChiLapD": "",
        "longitude": 105.83844144660752,
        "latitude": 21.01797872463302,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2743,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83827917295828,
          21.017868559324466
        ]
      },
      "properties": {
        "tenDoiTuon": "30-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Khâm Đức",
        "tuDien": "KTG",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên Ga",
        "diaChiLapD": "",
        "longitude": 105.83827917295828,
        "latitude": 21.017868559324466,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2744,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83812762814901,
          21.018058844807047
        ]
      },
      "properties": {
        "tenDoiTuon": "30-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Khâm Đức",
        "tuDien": "KTG",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên Ga",
        "diaChiLapD": "",
        "longitude": 105.83812762814901,
        "latitude": 21.018058844807047,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2745,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83797474223584,
          21.018145224320993
        ]
      },
      "properties": {
        "tenDoiTuon": "30-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Khâm Đức",
        "tuDien": "KTG",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên Ga",
        "diaChiLapD": "",
        "longitude": 105.83797474223584,
        "latitude": 21.018145224320993,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2746,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83827112633506,
          21.017961198340096
        ]
      },
      "properties": {
        "tenDoiTuon": "30-3-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Khâm Đức",
        "tuDien": "KTG",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "Khâm Thiên Ga",
        "diaChiLapD": "",
        "longitude": 105.83827112633506,
        "latitude": 21.017961198340096,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2747,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83521138996332,
          21.018670147695058
        ]
      },
      "properties": {
        "tenDoiTuon": "55",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Toàn Thắng",
        "tuDien": "KTH1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 1",
        "diaChiLapD": "",
        "longitude": 105.83521138996332,
        "latitude": 21.018670147695058,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2748,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83511443830174,
          21.01843542696622
        ]
      },
      "properties": {
        "tenDoiTuon": "56",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Toàn Thắng",
        "tuDien": "KTH1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 1",
        "diaChiLapD": "",
        "longitude": 105.83511443830174,
        "latitude": 21.01843542696622,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2749,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83500368836478,
          21.01819345458679
        ]
      },
      "properties": {
        "tenDoiTuon": "57",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Toàn Thắng",
        "tuDien": "KTH1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 1",
        "diaChiLapD": "",
        "longitude": 105.83500368836478,
        "latitude": 21.01819345458679,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2750,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83497686627838,
          21.018764309061535
        ]
      },
      "properties": {
        "tenDoiTuon": "54",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Toàn Thắng",
        "tuDien": "KTH1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 1",
        "diaChiLapD": "",
        "longitude": 105.83497686627838,
        "latitude": 21.018764309061535,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2751,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83498088959,
          21.018654144333354
        ]
      },
      "properties": {
        "tenDoiTuon": "54-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Toàn Thắng",
        "tuDien": "KTH1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 1",
        "diaChiLapD": "",
        "longitude": 105.83498088959,
        "latitude": 21.018654144333354,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2752,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83479531032623,
          21.018718900593914
        ]
      },
      "properties": {
        "tenDoiTuon": "54-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Toàn Thắng",
        "tuDien": "KTH1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 1",
        "diaChiLapD": "",
        "longitude": 105.83479531032623,
        "latitude": 21.018718900593914,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2753,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83505226532284,
          21.019027590457696
        ]
      },
      "properties": {
        "tenDoiTuon": "53",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Toàn Thắng",
        "tuDien": "KTH1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 1",
        "diaChiLapD": "",
        "longitude": 105.83505226532284,
        "latitude": 21.019027590457696,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2754,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83515925649465,
          21.01894833405128
        ]
      },
      "properties": {
        "tenDoiTuon": "53-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Toàn Thắng",
        "tuDien": "KTH1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 1",
        "diaChiLapD": "",
        "longitude": 105.83515925649465,
        "latitude": 21.01894833405128,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2755,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83527727368921,
          21.018930807871513
        ]
      },
      "properties": {
        "tenDoiTuon": "53-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Toàn Thắng",
        "tuDien": "KTH1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 1",
        "diaChiLapD": "",
        "longitude": 105.83527727368921,
        "latitude": 21.018930807871513,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2756,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83544088844232,
          21.01886070313171
        ]
      },
      "properties": {
        "tenDoiTuon": "53-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Toàn Thắng",
        "tuDien": "KTH1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 1",
        "diaChiLapD": "",
        "longitude": 105.83544088844232,
        "latitude": 21.01886070313171,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2757,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83549319151126,
          21.018811880169192
        ]
      },
      "properties": {
        "tenDoiTuon": "53-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Toàn Thắng",
        "tuDien": "KTH1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 1",
        "diaChiLapD": "",
        "longitude": 105.83549319151126,
        "latitude": 21.018811880169192,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2758,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82621426151631,
          21.01304280078134
        ]
      },
      "properties": {
        "tenDoiTuon": "BVND2 20-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "",
        "tuDien": "BVNĐ2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": "",
        "tuDien2": "BVND2",
        "diaChiLapD": "",
        "longitude": 105.82621426151631,
        "latitude": 21.01304280078134,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2759,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82627997564192,
          21.013000235513008
        ]
      },
      "properties": {
        "tenDoiTuon": "BVND2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "",
        "tuDien": "BVNĐ2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": "",
        "tuDien2": "BVND2",
        "diaChiLapD": "",
        "longitude": 105.82627997564192,
        "latitude": 21.013000235513008,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2760,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82623571918725,
          21.01292261646285
        ]
      },
      "properties": {
        "tenDoiTuon": "BVND2 20-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "",
        "tuDien": "BVNĐ2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "BVND2",
        "diaChiLapD": "",
        "longitude": 105.82623571918725,
        "latitude": 21.01292261646285,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2761,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81341526292186,
          21.01496151153926
        ]
      },
      "properties": {
        "tenDoiTuon": "01",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hoàng Ngọc Phách",
        "tuDien": "NHNgoaiThuong",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "NHNgoaiThuong",
        "diaChiLapD": "",
        "longitude": 105.81341526292186,
        "latitude": 21.01496151153926,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2762,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.813125584342,
          21.01513427410431
        ]
      },
      "properties": {
        "tenDoiTuon": "02",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hoàng Ngọc Phách",
        "tuDien": "NHNgoaiThuong",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "NHNgoaiThuong",
        "diaChiLapD": "",
        "longitude": 105.813125584342,
        "latitude": 21.01513427410431,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2763,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8128278591389,
          21.015329570677125
        ]
      },
      "properties": {
        "tenDoiTuon": "03",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hoàng Ngọc Phách",
        "tuDien": "NHNgoaiThuong",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "NHNgoaiThuong",
        "diaChiLapD": "",
        "longitude": 105.8128278591389,
        "latitude": 21.015329570677125,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2764,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8126401045161,
          21.015467279645033
        ]
      },
      "properties": {
        "tenDoiTuon": "04",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hoàng Ngọc Phách",
        "tuDien": "NHNgoaiThuong",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "NHNgoaiThuong",
        "diaChiLapD": "",
        "longitude": 105.8126401045161,
        "latitude": 21.015467279645033,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2765,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.812353108144,
          21.015645049214996
        ]
      },
      "properties": {
        "tenDoiTuon": "05",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hoàng Ngọc Phách",
        "tuDien": "NHNgoaiThuong",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "NHNgoaiThuong",
        "diaChiLapD": "",
        "longitude": 105.812353108144,
        "latitude": 21.015645049214996,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2766,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81214657804904,
          21.01572767443637
        ]
      },
      "properties": {
        "tenDoiTuon": "06",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hoàng Ngọc Phách",
        "tuDien": "NHNgoaiThuong",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "NHNgoaiThuong",
        "diaChiLapD": "",
        "longitude": 105.81214657804904,
        "latitude": 21.01572767443637,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2767,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81193468353861,
          21.01583032995035
        ]
      },
      "properties": {
        "tenDoiTuon": "07",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hoàng Ngọc Phách",
        "tuDien": "NHNgoaiThuong",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "NHNgoaiThuong",
        "diaChiLapD": "",
        "longitude": 105.81193468353861,
        "latitude": 21.01583032995035,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2768,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81186226389363,
          21.01596303087596
        ]
      },
      "properties": {
        "tenDoiTuon": "08",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hoàng Ngọc Phách",
        "tuDien": "NHNgoaiThuong",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "NHNgoaiThuong",
        "diaChiLapD": "",
        "longitude": 105.81186226389363,
        "latitude": 21.01596303087596,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2769,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81160047925616,
          21.016128775439025
        ]
      },
      "properties": {
        "tenDoiTuon": "09",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hoàng Ngọc Phách",
        "tuDien": "NHNgoaiThuong",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "NHNgoaiThuong",
        "diaChiLapD": "",
        "longitude": 105.81160047925616,
        "latitude": 21.016128775439025,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2770,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81131777547049,
          21.016276004289498
        ]
      },
      "properties": {
        "tenDoiTuon": "10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hoàng Ngọc Phách",
        "tuDien": "NHNgoaiThuong",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "NHNgoaiThuong",
        "diaChiLapD": "",
        "longitude": 105.81131777547049,
        "latitude": 21.016276004289498,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2771,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8110200502674,
          21.016443757768904
        ]
      },
      "properties": {
        "tenDoiTuon": "11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hoàng Ngọc Phách",
        "tuDien": "NHNgoaiThuong",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "NHNgoaiThuong",
        "diaChiLapD": "",
        "longitude": 105.8110200502674,
        "latitude": 21.016443757768904,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2772,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8106686808825,
          21.0163961859058
        ]
      },
      "properties": {
        "tenDoiTuon": "12",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Nguyên Hồng",
        "tuDien": "NHNgoaiThuong",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "NHNgoaiThuong",
        "diaChiLapD": "",
        "longitude": 105.8106686808825,
        "latitude": 21.0163961859058,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2773,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81039836727084,
          21.01619605369228
        ]
      },
      "properties": {
        "tenDoiTuon": "13",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Nguyên Hồng",
        "tuDien": "NHNgoaiThuong",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "NHNgoaiThuong",
        "diaChiLapD": "",
        "longitude": 105.81039836727084,
        "latitude": 21.01619605369228,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2774,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81013189304622,
          21.016007722511166
        ]
      },
      "properties": {
        "tenDoiTuon": "14",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Nguyên Hồng",
        "tuDien": "NHNgoaiThuong",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "NHNgoaiThuong",
        "diaChiLapD": "",
        "longitude": 105.81013189304622,
        "latitude": 21.016007722511166,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2775,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80985362769937,
          21.015790800473983
        ]
      },
      "properties": {
        "tenDoiTuon": "15",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Nguyên Hồng",
        "tuDien": "NHNgoaiThuong",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "NHNgoaiThuong",
        "diaChiLapD": "",
        "longitude": 105.80985362769937,
        "latitude": 21.015790800473983,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2776,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80955641721337,
          21.01555281528325
        ]
      },
      "properties": {
        "tenDoiTuon": "16",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Nguyên Hồng",
        "tuDien": "NHNgoaiThuong",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "NHNgoaiThuong",
        "diaChiLapD": "",
        "longitude": 105.80955641721337,
        "latitude": 21.01555281528325,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2777,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80933830521366,
          21.015189357778688
        ]
      },
      "properties": {
        "tenDoiTuon": "17",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Nguyên Hồng",
        "tuDien": "NHNgoaiThuong",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "NHNgoaiThuong",
        "diaChiLapD": "",
        "longitude": 105.80933830521366,
        "latitude": 21.015189357778688,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2778,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81250062964169,
          21.013158759784474
        ]
      },
      "properties": {
        "tenDoiTuon": "31",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Vũ Ngọc Phan",
        "tuDien": "NHNgoaiThuong",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "NHNgoaiThuong",
        "diaChiLapD": "",
        "longitude": 105.81250062964169,
        "latitude": 21.013158759784474,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2779,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81225881818283,
          21.01329935979663
        ]
      },
      "properties": {
        "tenDoiTuon": "30",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Vũ Ngọc Phan",
        "tuDien": "NHNgoaiThuong",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "NHNgoaiThuong",
        "diaChiLapD": "",
        "longitude": 105.81225881818283,
        "latitude": 21.01329935979663,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2780,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.812017006724,
          21.013439959676234
        ]
      },
      "properties": {
        "tenDoiTuon": "29",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Vũ Ngọc Phan",
        "tuDien": "NHNgoaiThuong",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "NHNgoaiThuong",
        "diaChiLapD": "",
        "longitude": 105.812017006724,
        "latitude": 21.013439959676234,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2781,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81177519526517,
          21.013580559423296
        ]
      },
      "properties": {
        "tenDoiTuon": "28",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Vũ Ngọc Phan",
        "tuDien": "NHNgoaiThuong",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "NHNgoaiThuong",
        "diaChiLapD": "",
        "longitude": 105.81177519526517,
        "latitude": 21.013580559423296,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2782,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81153338380633,
          21.01372115903785
        ]
      },
      "properties": {
        "tenDoiTuon": "27",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Vũ Ngọc Phan",
        "tuDien": "NHNgoaiThuong",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "NHNgoaiThuong",
        "diaChiLapD": "",
        "longitude": 105.81153338380633,
        "latitude": 21.01372115903785,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2783,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81129157234751,
          21.01386175851985
        ]
      },
      "properties": {
        "tenDoiTuon": "26",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Vũ Ngọc Phan",
        "tuDien": "NHNgoaiThuong",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "NHNgoaiThuong",
        "diaChiLapD": "",
        "longitude": 105.81129157234751,
        "latitude": 21.01386175851985,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2784,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81107486802624,
          21.01398893592166
        ]
      },
      "properties": {
        "tenDoiTuon": "25",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Vũ Ngọc Phan",
        "tuDien": "NHNgoaiThuong",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "NHNgoaiThuong",
        "diaChiLapD": "",
        "longitude": 105.81107486802624,
        "latitude": 21.01398893592166,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2785,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81080794942983,
          21.01414295708625
        ]
      },
      "properties": {
        "tenDoiTuon": "24",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Vũ Ngọc Phan",
        "tuDien": "NHNgoaiThuong",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "NHNgoaiThuong",
        "diaChiLapD": "",
        "longitude": 105.81080794942983,
        "latitude": 21.01414295708625,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2786,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81061084586229,
          21.014261852386994
        ]
      },
      "properties": {
        "tenDoiTuon": "23",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Vũ Ngọc Phan",
        "tuDien": "NHNgoaiThuong",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "NHNgoaiThuong",
        "diaChiLapD": "",
        "longitude": 105.81061084586229,
        "latitude": 21.014261852386994,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2787,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8103614004255,
          21.01439455470816
        ]
      },
      "properties": {
        "tenDoiTuon": "22",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Vũ Ngọc Phan",
        "tuDien": "NHNgoaiThuong",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "NHNgoaiThuong",
        "diaChiLapD": "",
        "longitude": 105.8103614004255,
        "latitude": 21.01439455470816,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2788,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81012000162092,
          21.014542279795155
        ]
      },
      "properties": {
        "tenDoiTuon": "21",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Vũ Ngọc Phan",
        "tuDien": "NHNgoaiThuong",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "NHNgoaiThuong",
        "diaChiLapD": "",
        "longitude": 105.81012000162092,
        "latitude": 21.014542279795155,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2789,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80985982734417,
          21.01469000473499
        ]
      },
      "properties": {
        "tenDoiTuon": "20",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Vũ Ngọc Phan",
        "tuDien": "NHNgoaiThuong",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "NHNgoaiThuong",
        "diaChiLapD": "",
        "longitude": 105.80985982734417,
        "latitude": 21.01469000473499,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2790,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80959889213567,
          21.014845951182817
        ]
      },
      "properties": {
        "tenDoiTuon": "19",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Vũ Ngọc Phan",
        "tuDien": "NHNgoaiThuong",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "NHNgoaiThuong",
        "diaChiLapD": "",
        "longitude": 105.80959889213567,
        "latitude": 21.014845951182817,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2791,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80935708067683,
          21.014986549604515
        ]
      },
      "properties": {
        "tenDoiTuon": "18",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Vũ Ngọc Phan",
        "tuDien": "NHNgoaiThuong",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "NHNgoaiThuong",
        "diaChiLapD": "",
        "longitude": 105.80935708067683,
        "latitude": 21.014986549604515,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2792,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81292961554222,
          21.014801424298213
        ]
      },
      "properties": {
        "tenDoiTuon": "2-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 1 Hoàng Ngọc Phách",
        "tuDien": "NHNgoaiThuong",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "NHNgoaiThuong",
        "diaChiLapD": "",
        "longitude": 105.81292961554222,
        "latitude": 21.014801424298213,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2793,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81283590576211,
          21.01504413713931
        ]
      },
      "properties": {
        "tenDoiTuon": "3-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 9 Hoàng Ngọc Phách",
        "tuDien": "NHNgoaiThuong",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "NHNgoaiThuong",
        "diaChiLapD": "",
        "longitude": 105.81283590576211,
        "latitude": 21.01504413713931,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2794,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8129619695889,
          21.015196869187914
        ]
      },
      "properties": {
        "tenDoiTuon": "3-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 9 Hoàng Ngọc Phách",
        "tuDien": "NHNgoaiThuong",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "NHNgoaiThuong",
        "diaChiLapD": "",
        "longitude": 105.8129619695889,
        "latitude": 21.015196869187914,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2795,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81279030821253,
          21.014896412549415
        ]
      },
      "properties": {
        "tenDoiTuon": "3-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 9 Hoàng Ngọc Phách",
        "tuDien": "NHNgoaiThuong",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "NHNgoaiThuong",
        "diaChiLapD": "",
        "longitude": 105.81279030821253,
        "latitude": 21.014896412549415,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2796,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81266960880127,
          21.01472114589756
        ]
      },
      "properties": {
        "tenDoiTuon": "3-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 9 Hoàng Ngọc Phách",
        "tuDien": "NHNgoaiThuong",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "NHNgoaiThuong",
        "diaChiLapD": "",
        "longitude": 105.81266960880127,
        "latitude": 21.01472114589756,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2797,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81283054134664,
          21.01462850486926
        ]
      },
      "properties": {
        "tenDoiTuon": "3-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 9 Hoàng Ngọc Phách",
        "tuDien": "NHNgoaiThuong",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "NHNgoaiThuong",
        "diaChiLapD": "",
        "longitude": 105.81283054134664,
        "latitude": 21.01462850486926,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2798,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81301293156294,
          21.014525848528027
        ]
      },
      "properties": {
        "tenDoiTuon": "3-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 9 Hoàng Ngọc Phách",
        "tuDien": "NHNgoaiThuong",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "NHNgoaiThuong",
        "diaChiLapD": "",
        "longitude": 105.81301293156294,
        "latitude": 21.014525848528027,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2799,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8126293756672,
          21.015602484690138
        ]
      },
      "properties": {
        "tenDoiTuon": "4-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 8A Hoàng Ngọc Phách",
        "tuDien": "NHNgoaiThuong",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "NHNgoaiThuong",
        "diaChiLapD": "",
        "longitude": 105.8126293756672,
        "latitude": 21.015602484690138,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2800,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81253465828752,
          21.015311499288778
        ]
      },
      "properties": {
        "tenDoiTuon": "4-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 15 Hoàng Ngọc Phách",
        "tuDien": "NHNgoaiThuong",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "NHNgoaiThuong",
        "diaChiLapD": "",
        "longitude": 105.81253465828752,
        "latitude": 21.015311499288778,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2801,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81242401915827,
          21.015140690089474
        ]
      },
      "properties": {
        "tenDoiTuon": "4-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 15 Hoàng Ngọc Phách",
        "tuDien": "NHNgoaiThuong",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "NHNgoaiThuong",
        "diaChiLapD": "",
        "longitude": 105.81242401915827,
        "latitude": 21.015140690089474,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2802,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81230868417147,
          21.014967927531853
        ]
      },
      "properties": {
        "tenDoiTuon": "4-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 15 Hoàng Ngọc Phách",
        "tuDien": "NHNgoaiThuong",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "NHNgoaiThuong",
        "diaChiLapD": "",
        "longitude": 105.81230868417147,
        "latitude": 21.014967927531853,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2803,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81229259091602,
          21.015080598787563
        ]
      },
      "properties": {
        "tenDoiTuon": "4-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 15 Hoàng Ngọc Phách",
        "tuDien": "NHNgoaiThuong",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 6,
        "tuDien2": "NHNgoaiThuong",
        "diaChiLapD": "",
        "longitude": 105.81229259091602,
        "latitude": 21.015080598787563,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2804,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81203241664826,
          21.01587430278233
        ]
      },
      "properties": {
        "tenDoiTuon": "7-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 8C Hoàng Ngọc Phách",
        "tuDien": "NHNgoaiThuong",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "NHNgoaiThuong",
        "diaChiLapD": "",
        "longitude": 105.81203241664826,
        "latitude": 21.01587430278233,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2805,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81213434058738,
          21.01601952261418
        ]
      },
      "properties": {
        "tenDoiTuon": "7-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 8C Hoàng Ngọc Phách",
        "tuDien": "NHNgoaiThuong",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "NHNgoaiThuong",
        "diaChiLapD": "",
        "longitude": 105.81213434058738,
        "latitude": 21.01601952261418,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2806,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81223894673424,
          21.01619729152588
        ]
      },
      "properties": {
        "tenDoiTuon": "NHNT 7-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 8C Hoàng Ngọc Phách",
        "tuDien": "NHNgoaiThuong",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "NHNgoaiThuong",
        "diaChiLapD": "",
        "longitude": 105.81223894673424,
        "latitude": 21.01619729152588,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2807,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81143160182658,
          21.016092132477084
        ]
      },
      "properties": {
        "tenDoiTuon": "9-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "TT C7 Hoàng Ngọc Phách",
        "tuDien": "NHNgoaiThuong",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "NHNgoaiThuong",
        "diaChiLapD": "",
        "longitude": 105.81143160182658,
        "latitude": 21.016092132477084,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2808,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81123311835486,
          21.01577915867765
        ]
      },
      "properties": {
        "tenDoiTuon": "NHNT 9-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "TT C7 Hoàng Ngọc Phách",
        "tuDien": "NHNgoaiThuong",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "NHNgoaiThuong",
        "diaChiLapD": "",
        "longitude": 105.81123311835486,
        "latitude": 21.01577915867765,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2809,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81107218581849,
          21.016084621113794
        ]
      },
      "properties": {
        "tenDoiTuon": "10-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 21 Hoàng Ngọc Phách",
        "tuDien": "NHNgoaiThuong",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 6,
        "tuDien2": "NHNgoaiThuong",
        "diaChiLapD": "",
        "longitude": 105.81107218581849,
        "latitude": 21.016084621113794,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2810,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81099708396579,
          21.016134696862988
        ]
      },
      "properties": {
        "tenDoiTuon": "11-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 23 Hoàng Ngọc Phách",
        "tuDien": "NHNgoaiThuong",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "NHNgoaiThuong",
        "diaChiLapD": "",
        "longitude": 105.81099708396579,
        "latitude": 21.016134696862988,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2811,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81050355750769,
          21.016470203951023
        ]
      },
      "properties": {
        "tenDoiTuon": "12-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Khu TT Công An",
        "tuDien": "NHNgoaiThuong",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 6,
        "tuDien2": "NHNgoaiThuong",
        "diaChiLapD": "",
        "longitude": 105.81050355750769,
        "latitude": 21.016470203951023,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2812,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81057597715267,
          21.016127185501862
        ]
      },
      "properties": {
        "tenDoiTuon": "13-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 71 Nguyên Hồng",
        "tuDien": "NHNgoaiThuong",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "NHNgoaiThuong",
        "diaChiLapD": "",
        "longitude": 105.81057597715267,
        "latitude": 21.016127185501862,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2813,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81074495632124,
          21.01599448472235
        ]
      },
      "properties": {
        "tenDoiTuon": "13-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 71 Nguyên Hồng",
        "tuDien": "NHNgoaiThuong",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "NHNgoaiThuong",
        "diaChiLapD": "",
        "longitude": 105.81074495632124,
        "latitude": 21.01599448472235,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2814,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80902029591668,
          21.0149403856559
        ]
      },
      "properties": {
        "tenDoiTuon": "17-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 25+27 Vũ Ngọc Phan",
        "tuDien": "NHNgoaiThuong",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "NHNgoaiThuong",
        "diaChiLapD": "",
        "longitude": 105.80902029591668,
        "latitude": 21.0149403856559,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2815,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81046332437359,
          21.01408157734638
        ]
      },
      "properties": {
        "tenDoiTuon": "23-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 25+27 Vũ Ngọc Phan",
        "tuDien": "NHNgoaiThuong",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "NHNgoaiThuong",
        "diaChiLapD": "",
        "longitude": 105.81046332437359,
        "latitude": 21.01408157734638,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2816,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80961038190742,
          21.01448969969613
        ]
      },
      "properties": {
        "tenDoiTuon": "23-8",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 25+27 Vũ Ngọc Phan",
        "tuDien": "NHNgoaiThuong",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "NHNgoaiThuong",
        "diaChiLapD": "",
        "longitude": 105.80961038190742,
        "latitude": 21.01448969969613,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2817,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80932338553527,
          21.014707531413187
        ]
      },
      "properties": {
        "tenDoiTuon": "23-10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 25+27 Vũ Ngọc Phan",
        "tuDien": "NHNgoaiThuong",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "NHNgoaiThuong",
        "diaChiLapD": "",
        "longitude": 105.80932338553527,
        "latitude": 21.014707531413187,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2818,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80999393779418,
          21.014214279827936
        ]
      },
      "properties": {
        "tenDoiTuon": "23-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 25+27 Vũ Ngọc Phan",
        "tuDien": "NHNgoaiThuong",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "NHNgoaiThuong",
        "diaChiLapD": "",
        "longitude": 105.80999393779418,
        "latitude": 21.014214279827936,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2819,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81020045724408,
          21.014064994495474
        ]
      },
      "properties": {
        "tenDoiTuon": "23-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 25+27 Vũ Ngọc Phan",
        "tuDien": "NHNgoaiThuong",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "NHNgoaiThuong",
        "diaChiLapD": "",
        "longitude": 105.81020045724408,
        "latitude": 21.014064994495474,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2820,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80982495862557,
          21.01434197455706
        ]
      },
      "properties": {
        "tenDoiTuon": "23-7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 25+27 Vũ Ngọc Phan",
        "tuDien": "NHNgoaiThuong",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "NHNgoaiThuong",
        "diaChiLapD": "",
        "longitude": 105.80982495862557,
        "latitude": 21.01434197455706,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2821,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8097525389806,
          21.014534768352974
        ]
      },
      "properties": {
        "tenDoiTuon": "23-12",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 25+27 Vũ Ngọc Phan",
        "tuDien": "NHNgoaiThuong",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "NHNgoaiThuong",
        "diaChiLapD": "",
        "longitude": 105.8097525389806,
        "latitude": 21.014534768352974,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2822,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80952186900699,
          21.014667470432137
        ]
      },
      "properties": {
        "tenDoiTuon": "23-11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 25+27 Vũ Ngọc Phan",
        "tuDien": "NHNgoaiThuong",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "NHNgoaiThuong",
        "diaChiLapD": "",
        "longitude": 105.80952186900699,
        "latitude": 21.014667470432137,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2823,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81003148872051,
          21.014364508909928
        ]
      },
      "properties": {
        "tenDoiTuon": "23-13",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 25+27 Vũ Ngọc Phan",
        "tuDien": "NHNgoaiThuong",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "NHNgoaiThuong",
        "diaChiLapD": "",
        "longitude": 105.81003148872051,
        "latitude": 21.014364508909928,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2824,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80952455121475,
          21.01436701272632
        ]
      },
      "properties": {
        "tenDoiTuon": "23-9",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 25+27 Vũ Ngọc Phan",
        "tuDien": "NHNgoaiThuong",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "NHNgoaiThuong",
        "diaChiLapD": "",
        "longitude": 105.80952455121475,
        "latitude": 21.01436701272632,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2825,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81042845565497,
          21.013891286789057
        ]
      },
      "properties": {
        "tenDoiTuon": "23-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 25+27 Vũ Ngọc Phan",
        "tuDien": "NHNgoaiThuong",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "NHNgoaiThuong",
        "diaChiLapD": "",
        "longitude": 105.81042845565497,
        "latitude": 21.013891286789057,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2826,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81010659057323,
          21.01365342325184
        ]
      },
      "properties": {
        "tenDoiTuon": "23-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 25+27 Vũ Ngọc Phan",
        "tuDien": "NHNgoaiThuong",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 6,
        "tuDien2": "NHNgoaiThuong",
        "diaChiLapD": "",
        "longitude": 105.81010659057323,
        "latitude": 21.01365342325184,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2827,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81027556974182,
          21.01374856871258
        ]
      },
      "properties": {
        "tenDoiTuon": "23-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 25+27 Vũ Ngọc Phan",
        "tuDien": "NHNgoaiThuong",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "NHNgoaiThuong",
        "diaChiLapD": "",
        "longitude": 105.81027556974182,
        "latitude": 21.01374856871258,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2828,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80988396722286,
          21.014011470327016
        ]
      },
      "properties": {
        "tenDoiTuon": "23-6-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 25+27 Vũ Ngọc Phan",
        "tuDien": "NHNgoaiThuong",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "NHNgoaiThuong",
        "diaChiLapD": "",
        "longitude": 105.80988396722286,
        "latitude": 21.014011470327016,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2829,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81032116729139,
          21.014727561900514
        ]
      },
      "properties": {
        "tenDoiTuon": "21-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 42 Vũ Ngọc Phan",
        "tuDien": "NHNgoaiThuong",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "NHNgoaiThuong",
        "diaChiLapD": "",
        "longitude": 105.81032116729139,
        "latitude": 21.014727561900514,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2830,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81047405320457,
          21.014955408497343
        ]
      },
      "properties": {
        "tenDoiTuon": "21-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 42 Vũ Ngọc Phan",
        "tuDien": "NHNgoaiThuong",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "NHNgoaiThuong",
        "diaChiLapD": "",
        "longitude": 105.81047405320457,
        "latitude": 21.014955408497343,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2831,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81062425690997,
          21.015228323195043
        ]
      },
      "properties": {
        "tenDoiTuon": "21-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 42 Vũ Ngọc Phan",
        "tuDien": "NHNgoaiThuong",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "NHNgoaiThuong",
        "diaChiLapD": "",
        "longitude": 105.81062425690997,
        "latitude": 21.015228323195043,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2832,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81049014646,
          21.015358520856573
        ]
      },
      "properties": {
        "tenDoiTuon": "21-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 42 Vũ Ngọc Phan",
        "tuDien": "NHNgoaiThuong",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "NHNgoaiThuong",
        "diaChiLapD": "",
        "longitude": 105.81049014646,
        "latitude": 21.015358520856573,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2833,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81039090472864,
          21.01559888547103
        ]
      },
      "properties": {
        "tenDoiTuon": "21-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 42 Vũ Ngọc Phan",
        "tuDien": "NHNgoaiThuong",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "NHNgoaiThuong",
        "diaChiLapD": "",
        "longitude": 105.81039090472864,
        "latitude": 21.01559888547103,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2834,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8102594764864,
          21.01590184448497
        ]
      },
      "properties": {
        "tenDoiTuon": "21-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 42 Vũ Ngọc Phan",
        "tuDien": "NHNgoaiThuong",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "NHNgoaiThuong",
        "diaChiLapD": "",
        "longitude": 105.8102594764864,
        "latitude": 21.01590184448497,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2835,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81091125327313,
          21.01493287423459
        ]
      },
      "properties": {
        "tenDoiTuon": "21-8",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 42 Vũ Ngọc Phan",
        "tuDien": "NHNgoaiThuong",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "NHNgoaiThuong",
        "diaChiLapD": "",
        "longitude": 105.81091125327313,
        "latitude": 21.01493287423459,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2836,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81104268151536,
          21.015118155854736
        ]
      },
      "properties": {
        "tenDoiTuon": "21-9",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 42 Vũ Ngọc Phan",
        "tuDien": "NHNgoaiThuong",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "NHNgoaiThuong",
        "diaChiLapD": "",
        "longitude": 105.81104268151536,
        "latitude": 21.015118155854736,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2837,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81072349864137,
          21.01501549984969
        ]
      },
      "properties": {
        "tenDoiTuon": "21-7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 42 Vũ Ngọc Phan",
        "tuDien": "NHNgoaiThuong",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "NHNgoaiThuong",
        "diaChiLapD": "",
        "longitude": 105.81072349864137,
        "latitude": 21.01501549984969,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2838,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81058536488874,
          21.014469669177704
        ]
      },
      "properties": {
        "tenDoiTuon": "22-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 36 Vũ Ngọc Phan",
        "tuDien": "NHNgoaiThuong",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "NHNgoaiThuong",
        "diaChiLapD": "",
        "longitude": 105.81058536488874,
        "latitude": 21.014469669177704,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2839,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81070338208329,
          21.014647439937587
        ]
      },
      "properties": {
        "tenDoiTuon": "22-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 36 Vũ Ngọc Phan",
        "tuDien": "NHNgoaiThuong",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "NHNgoaiThuong",
        "diaChiLapD": "",
        "longitude": 105.81070338208329,
        "latitude": 21.014647439937587,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2840,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81084151584487,
          21.0148565080947
        ]
      },
      "properties": {
        "tenDoiTuon": "22-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 36 Vũ Ngọc Phan",
        "tuDien": "NHNgoaiThuong",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "NHNgoaiThuong",
        "diaChiLapD": "",
        "longitude": 105.81084151584487,
        "latitude": 21.0148565080947,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2841,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81082408148556,
          21.014722554278926
        ]
      },
      "properties": {
        "tenDoiTuon": "22-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 36 Vũ Ngọc Phan",
        "tuDien": "NHNgoaiThuong",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "NHNgoaiThuong",
        "diaChiLapD": "",
        "longitude": 105.81082408148556,
        "latitude": 21.014722554278926,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2842,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81069131213948,
          21.014522249283793
        ]
      },
      "properties": {
        "tenDoiTuon": "22-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 36 Vũ Ngọc Phan",
        "tuDien": "NHNgoaiThuong",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "NHNgoaiThuong",
        "diaChiLapD": "",
        "longitude": 105.81069131213948,
        "latitude": 21.014522249283793,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2843,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8107999416069,
          21.014353241734344
        ]
      },
      "properties": {
        "tenDoiTuon": "24-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "TT C7 Vũ Ngọc Phan",
        "tuDien": "NHNgoaiThuong",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "NHNgoaiThuong",
        "diaChiLapD": "",
        "longitude": 105.8107999416069,
        "latitude": 21.014353241734344,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2844,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81092466432979,
          21.01453226453939
        ]
      },
      "properties": {
        "tenDoiTuon": "24-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "TT C7 Vũ Ngọc Phan",
        "tuDien": "NHNgoaiThuong",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "NHNgoaiThuong",
        "diaChiLapD": "",
        "longitude": 105.81092466432979,
        "latitude": 21.01453226453939,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2845,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81103865821272,
          21.01464118040701
        ]
      },
      "properties": {
        "tenDoiTuon": "24-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "TT C7 Vũ Ngọc Phan",
        "tuDien": "NHNgoaiThuong",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 6,
        "tuDien2": "NHNgoaiThuong",
        "diaChiLapD": "",
        "longitude": 105.81103865821272,
        "latitude": 21.01464118040701,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2846,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81196804363552,
          21.013143893239445
        ]
      },
      "properties": {
        "tenDoiTuon": "29-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 11 Vũ Ngọc Phan",
        "tuDien": "NHNgoaiThuong",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "NHNgoaiThuong",
        "diaChiLapD": "",
        "longitude": 105.81196804363552,
        "latitude": 21.013143893239445,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2847,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81192512829367,
          21.015422367745813
        ]
      },
      "properties": {
        "tenDoiTuon": "01",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 17 Hoàng Ngọc Phách",
        "tuDien": "VHH",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "TT Viện Hóa Học",
        "diaChiLapD": "",
        "longitude": 105.81192512829367,
        "latitude": 21.015422367745813,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2848,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81171591599097,
          21.015545053846633
        ]
      },
      "properties": {
        "tenDoiTuon": "02",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 17 Hoàng Ngọc Phách",
        "tuDien": "VHH",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "TT Viện Hóa Học",
        "diaChiLapD": "",
        "longitude": 105.81171591599097,
        "latitude": 21.015545053846633,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2849,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81163813193054,
          21.015727830912724
        ]
      },
      "properties": {
        "tenDoiTuon": "05",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 17 Hoàng Ngọc Phách",
        "tuDien": "VHH",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "TT Viện Hóa Học",
        "diaChiLapD": "",
        "longitude": 105.81163813193054,
        "latitude": 21.015727830912724,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2850,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81149597484834,
          21.015540046252504
        ]
      },
      "properties": {
        "tenDoiTuon": "06",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 17 Hoàng Ngọc Phách",
        "tuDien": "VHH",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "TT Viện Hóa Học",
        "diaChiLapD": "",
        "longitude": 105.81149597484834,
        "latitude": 21.015540046252504,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2851,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81127066929022,
          21.01534725375608
        ]
      },
      "properties": {
        "tenDoiTuon": "09",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 17 Hoàng Ngọc Phách",
        "tuDien": "VHH",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "TT Viện Hóa Học",
        "diaChiLapD": "",
        "longitude": 105.81127066929022,
        "latitude": 21.01534725375608,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2852,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81122775394837,
          21.015660228461893
        ]
      },
      "properties": {
        "tenDoiTuon": "07",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 17 Hoàng Ngọc Phách",
        "tuDien": "VHH",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "TT Viện Hóa Học",
        "diaChiLapD": "",
        "longitude": 105.81122775394837,
        "latitude": 21.015660228461893,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2853,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81145305950649,
          21.01535726895712
        ]
      },
      "properties": {
        "tenDoiTuon": "14",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 17 Hoàng Ngọc Phách",
        "tuDien": "VHH",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 7,
        "tuDien2": "TT Viện Hóa Học",
        "diaChiLapD": "",
        "longitude": 105.81145305950649,
        "latitude": 21.01535726895712,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2854,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81173200924643,
          21.01519201805817
        ]
      },
      "properties": {
        "tenDoiTuon": "15",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 17 Hoàng Ngọc Phách",
        "tuDien": "VHH",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 7,
        "tuDien2": "TT Viện Hóa Học",
        "diaChiLapD": "",
        "longitude": 105.81173200924643,
        "latitude": 21.01519201805817,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2855,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81190367062275,
          21.01508435449538
        ]
      },
      "properties": {
        "tenDoiTuon": "16",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 17 Hoàng Ngọc Phách",
        "tuDien": "VHH",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 7,
        "tuDien2": "TT Viện Hóa Học",
        "diaChiLapD": "",
        "longitude": 105.81190367062275,
        "latitude": 21.01508435449538,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2856,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81130017359334,
          21.01514945340318
        ]
      },
      "properties": {
        "tenDoiTuon": "13",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 17 Hoàng Ngọc Phách",
        "tuDien": "VHH",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 7,
        "tuDien2": "TT Viện Hóa Học",
        "diaChiLapD": "",
        "longitude": 105.81130017359334,
        "latitude": 21.01514945340318,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2857,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81112314780152,
          21.014818950962475
        ]
      },
      "properties": {
        "tenDoiTuon": "19",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 17 Hoàng Ngọc Phách",
        "tuDien": "VHH",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 7,
        "tuDien2": "TT Viện Hóa Học",
        "diaChiLapD": "",
        "longitude": 105.81112314780152,
        "latitude": 21.014818950962475,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2858,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81158180554098,
          21.014916599486888
        ]
      },
      "properties": {
        "tenDoiTuon": "17",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 17 Hoàng Ngọc Phách",
        "tuDien": "VHH",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 7,
        "tuDien2": "TT Viện Hóa Học",
        "diaChiLapD": "",
        "longitude": 105.81158180554098,
        "latitude": 21.014916599486888,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2859,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81140746195693,
          21.014671226150362
        ]
      },
      "properties": {
        "tenDoiTuon": "18",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 17 Hoàng Ngọc Phách",
        "tuDien": "VHH",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 7,
        "tuDien2": "TT Viện Hóa Học",
        "diaChiLapD": "",
        "longitude": 105.81140746195693,
        "latitude": 21.014671226150362,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2860,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81207801420683,
          21.015404841151614
        ]
      },
      "properties": {
        "tenDoiTuon": "03",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 17 Hoàng Ngọc Phách",
        "tuDien": "VHH",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "TT Viện Hóa Học",
        "diaChiLapD": "",
        "longitude": 105.81207801420683,
        "latitude": 21.015404841151614,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2861,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81171055157552,
          21.015642701895644
        ]
      },
      "properties": {
        "tenDoiTuon": "04",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 17 Hoàng Ngọc Phách",
        "tuDien": "VHH",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "TT Viện Hóa Học",
        "diaChiLapD": "",
        "longitude": 105.81171055157552,
        "latitude": 21.015642701895644,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2862,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8111338766325,
          21.015760380229327
        ]
      },
      "properties": {
        "tenDoiTuon": "TTVHH  08",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 17 Hoàng Ngọc Phách",
        "tuDien": "VHH",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "TT Viện Hóa Học",
        "diaChiLapD": "",
        "longitude": 105.8111338766325,
        "latitude": 21.015760380229327,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2863,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81146915276194,
          21.014258096660143
        ]
      },
      "properties": {
        "tenDoiTuon": "12",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 4 Vũ Ngọc Phan",
        "tuDien": "VHH",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "TT Viện Hóa Học",
        "diaChiLapD": "",
        "longitude": 105.81146915276194,
        "latitude": 21.014258096660143,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2864,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81156839449329,
          21.01455104314403
        ]
      },
      "properties": {
        "tenDoiTuon": "11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 4 Vũ Ngọc Phan",
        "tuDien": "VHH",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "TT Viện Hóa Học",
        "diaChiLapD": "",
        "longitude": 105.81156839449329,
        "latitude": 21.01455104314403,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2865,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81174005586965,
          21.014828966198987
        ]
      },
      "properties": {
        "tenDoiTuon": "10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 4 Vũ Ngọc Phan",
        "tuDien": "VHH",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "TT Viện Hóa Học",
        "diaChiLapD": "",
        "longitude": 105.81174005586965,
        "latitude": 21.014828966198987,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2866,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81172128037953,
          21.017912375078872
        ]
      },
      "properties": {
        "tenDoiTuon": "B24TC 07",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Nguyên Hồng",
        "tuDien": "B24TC",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "B24 Thành Công",
        "diaChiLapD": "",
        "longitude": 105.81172128037953,
        "latitude": 21.017912375078872,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2867,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81157214955898,
          21.01764697657861
        ]
      },
      "properties": {
        "tenDoiTuon": "08",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Nguyên Hồng",
        "tuDien": "B24TC",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "B24 Thành Công",
        "diaChiLapD": "",
        "longitude": 105.81157214955898,
        "latitude": 21.01764697657861,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2868,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81142301873848,
          21.017381577606024
        ]
      },
      "properties": {
        "tenDoiTuon": "09",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Nguyên Hồng",
        "tuDien": "B24TC",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "B24 Thành Công",
        "diaChiLapD": "",
        "longitude": 105.81142301873848,
        "latitude": 21.017381577606024,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2869,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81127388791795,
          21.01711617816111
        ]
      },
      "properties": {
        "tenDoiTuon": "10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Nguyên Hồng",
        "tuDien": "B24TC",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "B24 Thành Công",
        "diaChiLapD": "",
        "longitude": 105.81127388791795,
        "latitude": 21.01711617816111,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2870,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81112475709743,
          21.01685077824387
        ]
      },
      "properties": {
        "tenDoiTuon": "11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Nguyên Hồng",
        "tuDien": "B24TC",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "B24 Thành Công",
        "diaChiLapD": "",
        "longitude": 105.81112475709743,
        "latitude": 21.01685077824387,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2871,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8109756262769,
          21.016585377854295
        ]
      },
      "properties": {
        "tenDoiTuon": "B24TC 12",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Nguyên Hồng",
        "tuDien": "B24TC",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "B24 Thành Công",
        "diaChiLapD": "",
        "longitude": 105.8109756262769,
        "latitude": 21.016585377854295,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2872,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83338011818192,
          21.00792330119582
        ]
      },
      "properties": {
        "tenDoiTuon": "B4KL 02",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Phạm Ngọc Thạch",
        "tuDien": "B4KL",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 7,
        "tuDien2": "B4 Kim Liên",
        "diaChiLapD": "",
        "longitude": 105.83338011818192,
        "latitude": 21.00792330119582,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2873,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83363895135477,
          21.008123615052888
        ]
      },
      "properties": {
        "tenDoiTuon": "B4KL 03",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Phạm Ngọc Thạch",
        "tuDien": "B4KL",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 7,
        "tuDien2": "B4 Kim Liên",
        "diaChiLapD": "",
        "longitude": 105.83363895135477,
        "latitude": 21.008123615052888,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2874,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83313033744895,
          21.007720796134002
        ]
      },
      "properties": {
        "tenDoiTuon": "B4KL 01",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Phạm Ngọc Thạch",
        "tuDien": "B4KL",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 7,
        "tuDien2": "B4 Kim Liên",
        "diaChiLapD": "",
        "longitude": 105.83313033744895,
        "latitude": 21.007720796134002,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2875,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83316922947915,
          21.007389025267035
        ]
      },
      "properties": {
        "tenDoiTuon": "1-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 97 Phạm Ngọc Thạch",
        "tuDien": "B4KL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "B4 Kim Liên",
        "diaChiLapD": "",
        "longitude": 105.83316922947915,
        "latitude": 21.007389025267035,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2876,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.834860362251,
          21.007307021412835
        ]
      },
      "properties": {
        "tenDoiTuon": "B4KL 07",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Lương Định Của",
        "tuDien": "B4KL",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "B4 Kim Liên",
        "diaChiLapD": "",
        "longitude": 105.834860362251,
        "latitude": 21.007307021412835,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2877,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83510712548006,
          21.007031588283162
        ]
      },
      "properties": {
        "tenDoiTuon": "08",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Lương Định Của",
        "tuDien": "B4KL",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "B4 Kim Liên",
        "diaChiLapD": "",
        "longitude": 105.83510712548006,
        "latitude": 21.007031588283162,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2878,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83531097336726,
          21.00681374535721
        ]
      },
      "properties": {
        "tenDoiTuon": "09",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Lương Định Của",
        "tuDien": "B4KL",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "B4 Kim Liên",
        "diaChiLapD": "",
        "longitude": 105.83531097336726,
        "latitude": 21.00681374535721,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2879,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8354826347436,
          21.006533303420486
        ]
      },
      "properties": {
        "tenDoiTuon": "B4KL 10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Lương Định Của",
        "tuDien": "B4KL",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "B4 Kim Liên",
        "diaChiLapD": "",
        "longitude": 105.8354826347436,
        "latitude": 21.006533303420486,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2880,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83512590094323,
          21.006625949476145
        ]
      },
      "properties": {
        "tenDoiTuon": "9-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Nhà B15 - B19",
        "tuDien": "B4KL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "B4 Kim Liên",
        "diaChiLapD": "",
        "longitude": 105.83512590094323,
        "latitude": 21.006625949476145,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2881,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83497569723782,
          21.006500752090506
        ]
      },
      "properties": {
        "tenDoiTuon": "9-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Nhà B15 - B19",
        "tuDien": "B4KL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "B4 Kim Liên",
        "diaChiLapD": "",
        "longitude": 105.83497569723782,
        "latitude": 21.006500752090506,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2882,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83477721377507,
          21.006350515089263
        ]
      },
      "properties": {
        "tenDoiTuon": "9-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Nhà B15 - B19",
        "tuDien": "B4KL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "B4 Kim Liên",
        "diaChiLapD": "",
        "longitude": 105.83477721377507,
        "latitude": 21.006350515089263,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2883,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83462432785294,
          21.006285412342415
        ]
      },
      "properties": {
        "tenDoiTuon": "B4KL 9-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Nhà B15 - B19",
        "tuDien": "B4KL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "B4 Kim Liên",
        "diaChiLapD": "",
        "longitude": 105.83462432785294,
        "latitude": 21.006285412342415,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2884,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83484695120332,
          21.006816249301185
        ]
      },
      "properties": {
        "tenDoiTuon": "8-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Nhà B15 - B19",
        "tuDien": "B4KL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "B4 Kim Liên",
        "diaChiLapD": "",
        "longitude": 105.83484695120332,
        "latitude": 21.006816249301185,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2885,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83470479412112,
          21.0066860441847
        ]
      },
      "properties": {
        "tenDoiTuon": "8-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Nhà B15 - B19",
        "tuDien": "B4KL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "B4 Kim Liên",
        "diaChiLapD": "",
        "longitude": 105.83470479412112,
        "latitude": 21.0066860441847,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2886,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83452508612156,
          21.006488232347085
        ]
      },
      "properties": {
        "tenDoiTuon": "B4KL",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Nhà B15 - B19",
        "tuDien": "B4KL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "B4 Kim Liên",
        "diaChiLapD": "",
        "longitude": 105.83452508612156,
        "latitude": 21.006488232347085,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2887,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83481476469244,
          21.00716179309977
        ]
      },
      "properties": {
        "tenDoiTuon": "7-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Nhà B15 - B19",
        "tuDien": "B4KL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "B4 Kim Liên",
        "diaChiLapD": "",
        "longitude": 105.83481476469244,
        "latitude": 21.00716179309977,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2888,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83462701006067,
          21.006931430656014
        ]
      },
      "properties": {
        "tenDoiTuon": "7-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Nhà B15 - B19",
        "tuDien": "B4KL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "B4 Kim Liên",
        "diaChiLapD": "",
        "longitude": 105.83462701006067,
        "latitude": 21.006931430656014,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2889,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83430782719564,
          21.006681036294044
        ]
      },
      "properties": {
        "tenDoiTuon": "7-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Nhà B15 - B19",
        "tuDien": "B4KL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "B4 Kim Liên",
        "diaChiLapD": "",
        "longitude": 105.83430782719564,
        "latitude": 21.006681036294044,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2890,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82752217367961,
          21.01465870709514
        ]
      },
      "properties": {
        "tenDoiTuon": "BVND 06",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "",
        "tuDien": "BVNĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 11,
        "tuDien2": "BVND",
        "diaChiLapD": "",
        "longitude": 105.82752217367961,
        "latitude": 21.01465870709514,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2891,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8273909370279,
          21.01444528678826
        ]
      },
      "properties": {
        "tenDoiTuon": "BVND07",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "",
        "tuDien": "BVNĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 11,
        "tuDien2": "BVND",
        "diaChiLapD": "",
        "longitude": 105.8273909370279,
        "latitude": 21.01444528678826,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2892,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82722540641427,
          21.014175828314347
        ]
      },
      "properties": {
        "tenDoiTuon": "BVND 08",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "",
        "tuDien": "BVNĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 11,
        "tuDien2": "BVND",
        "diaChiLapD": "",
        "longitude": 105.82722540641427,
        "latitude": 21.014175828314347,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2893,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82705987580063,
          21.013906369353606
        ]
      },
      "properties": {
        "tenDoiTuon": "BVND 09",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "",
        "tuDien": "BVNĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 11,
        "tuDien2": "BVND",
        "diaChiLapD": "",
        "longitude": 105.82705987580063,
        "latitude": 21.013906369353606,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2894,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82689434518699,
          21.013636909906065
        ]
      },
      "properties": {
        "tenDoiTuon": "BVND 10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "",
        "tuDien": "BVNĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 11,
        "tuDien2": "BVND",
        "diaChiLapD": "",
        "longitude": 105.82689434518699,
        "latitude": 21.013636909906065,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2895,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82672881457333,
          21.013367449971742
        ]
      },
      "properties": {
        "tenDoiTuon": "BVND 11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "",
        "tuDien": "BVNĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 11,
        "tuDien2": "BVND",
        "diaChiLapD": "",
        "longitude": 105.82672881457333,
        "latitude": 21.013367449971742,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2896,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82656328395969,
          21.01309798955062
        ]
      },
      "properties": {
        "tenDoiTuon": "BVND 12",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "",
        "tuDien": "BVNĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 11,
        "tuDien2": "BVND",
        "diaChiLapD": "",
        "longitude": 105.82656328395969,
        "latitude": 21.01309798955062,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2897,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82639775334604,
          21.012828528642693
        ]
      },
      "properties": {
        "tenDoiTuon": "BVND 13",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "",
        "tuDien": "BVNĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 11,
        "tuDien2": "BVND",
        "diaChiLapD": "",
        "longitude": 105.82639775334604,
        "latitude": 21.012828528642693,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2898,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8262322227324,
          21.012559067247988
        ]
      },
      "properties": {
        "tenDoiTuon": "BVND 14",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "",
        "tuDien": "BVNĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 11,
        "tuDien2": "BVND",
        "diaChiLapD": "",
        "longitude": 105.8262322227324,
        "latitude": 21.012559067247988,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2899,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82606669211877,
          21.012289605366494
        ]
      },
      "properties": {
        "tenDoiTuon": "BVND 15",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "",
        "tuDien": "BVNĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 11,
        "tuDien2": "BVND",
        "diaChiLapD": "",
        "longitude": 105.82606669211877,
        "latitude": 21.012289605366494,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2900,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82590116150512,
          21.012020142998235
        ]
      },
      "properties": {
        "tenDoiTuon": "BVND 16",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "",
        "tuDien": "BVNĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 11,
        "tuDien2": "BVND",
        "diaChiLapD": "",
        "longitude": 105.82590116150512,
        "latitude": 21.012020142998235,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2901,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82573563089146,
          21.011750680143187
        ]
      },
      "properties": {
        "tenDoiTuon": "BVND 17",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "",
        "tuDien": "BVNĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 11,
        "tuDien2": "BVND",
        "diaChiLapD": "",
        "longitude": 105.82573563089146,
        "latitude": 21.011750680143187,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2902,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82457691659596,
          21.009864426528832
        ]
      },
      "properties": {
        "tenDoiTuon": "BVND 24",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "",
        "tuDien": "BVNĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 11,
        "tuDien2": "BVND",
        "diaChiLapD": "",
        "longitude": 105.82457691659596,
        "latitude": 21.009864426528832,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2903,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83408252163753,
          21.00648072050104
        ]
      },
      "properties": {
        "tenDoiTuon": "7-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Nhà B15 - B19",
        "tuDien": "B4KL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "B4 Kim Liên",
        "diaChiLapD": "",
        "longitude": 105.83408252163753,
        "latitude": 21.00648072050104,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2904,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83387900905547,
          21.006393395239748
        ]
      },
      "properties": {
        "tenDoiTuon": "7-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Nhà B15 - B19",
        "tuDien": "B4KL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "B4 Kim Liên",
        "diaChiLapD": "",
        "longitude": 105.83387900905547,
        "latitude": 21.006393395239748,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2905,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83364029245863,
          21.00635333202595
        ]
      },
      "properties": {
        "tenDoiTuon": "7-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Nhà B15 - B19",
        "tuDien": "B4KL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "B4 Kim Liên",
        "diaChiLapD": "",
        "longitude": 105.83364029245863,
        "latitude": 21.00635333202595,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2906,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83341766910824,
          21.006508576921856
        ]
      },
      "properties": {
        "tenDoiTuon": "7-7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Nhà B15 - B19",
        "tuDien": "B4KL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "B4 Kim Liên",
        "diaChiLapD": "",
        "longitude": 105.83341766910824,
        "latitude": 21.006508576921856,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2907,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8242351265377,
          21.009758666850278
        ]
      },
      "properties": {
        "tenDoiTuon": "BVND 25",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "",
        "tuDien": "BVNĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 11,
        "tuDien2": "BVND",
        "diaChiLapD": "",
        "longitude": 105.8242351265377,
        "latitude": 21.009758666850278,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2908,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82499687389574,
          21.010715151323968
        ]
      },
      "properties": {
        "tenDoiTuon": "BVND 21",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "",
        "tuDien": "BVNĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 11,
        "tuDien2": "BVND",
        "diaChiLapD": "",
        "longitude": 105.82499687389574,
        "latitude": 21.010715151323968,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2909,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82488958554114,
          21.01045975554668
        ]
      },
      "properties": {
        "tenDoiTuon": "BVND 22",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "",
        "tuDien": "BVNĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 11,
        "tuDien2": "BVND",
        "diaChiLapD": "",
        "longitude": 105.82488958554114,
        "latitude": 21.01045975554668,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2910,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82472865299577,
          21.010164297140857
        ]
      },
      "properties": {
        "tenDoiTuon": "BVND 23",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "",
        "tuDien": "BVNĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 11,
        "tuDien2": "BVND",
        "diaChiLapD": "",
        "longitude": 105.82472865299577,
        "latitude": 21.010164297140857,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2911,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82518462852752,
          21.011000593146743
        ]
      },
      "properties": {
        "tenDoiTuon": "BVND 20",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "",
        "tuDien": "BVNĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 11,
        "tuDien2": "BVND",
        "diaChiLapD": "",
        "longitude": 105.82518462852752,
        "latitude": 21.011000593146743,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2912,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82535628990388,
          21.01128102668622
        ]
      },
      "properties": {
        "tenDoiTuon": "BVND 19",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "",
        "tuDien": "BVNĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 11,
        "tuDien2": "BVND",
        "diaChiLapD": "",
        "longitude": 105.82535628990388,
        "latitude": 21.01128102668622,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2913,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82554404453563,
          21.011511382413037
        ]
      },
      "properties": {
        "tenDoiTuon": "BVND 18",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "",
        "tuDien": "BVNĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 11,
        "tuDien2": "BVND",
        "diaChiLapD": "",
        "longitude": 105.82554404453563,
        "latitude": 21.011511382413037,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2914,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83395645781077,
          21.006823761132864
        ]
      },
      "properties": {
        "tenDoiTuon": "7-9",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Nhà B15 - B19",
        "tuDien": "B4KL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "B4 Kim Liên",
        "diaChiLapD": "",
        "longitude": 105.83395645781077,
        "latitude": 21.006823761132864,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2915,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83373651667709,
          21.006623445530646
        ]
      },
      "properties": {
        "tenDoiTuon": "B4KL 7-10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Nhà B15 - B19",
        "tuDien": "B4KL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "B4 Kim Liên",
        "diaChiLapD": "",
        "longitude": 105.83373651667709,
        "latitude": 21.006623445530646,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2916,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83463773890064,
          21.00754489507204
        ]
      },
      "properties": {
        "tenDoiTuon": "06",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Lương Định Của",
        "tuDien": "B4KL",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "B4 Kim Liên",
        "diaChiLapD": "",
        "longitude": 105.83463773890064,
        "latitude": 21.00754489507204,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2917,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83442718551203,
          21.007342076505413
        ]
      },
      "properties": {
        "tenDoiTuon": "6-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Nhà B15 - B19",
        "tuDien": "B4KL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "B4 Kim Liên",
        "diaChiLapD": "",
        "longitude": 105.83442718551203,
        "latitude": 21.007342076505413,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2918,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83430648610077,
          21.007229399403766
        ]
      },
      "properties": {
        "tenDoiTuon": "6-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Nhà B15 - B19",
        "tuDien": "B4KL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "B4 Kim Liên",
        "diaChiLapD": "",
        "longitude": 105.83430648610077,
        "latitude": 21.007229399403766,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2919,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83433062597943,
          21.007870405779407
        ]
      },
      "properties": {
        "tenDoiTuon": "05",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Lương Định Của",
        "tuDien": "B4KL",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "B4 Kim Liên",
        "diaChiLapD": "",
        "longitude": 105.83433062597943,
        "latitude": 21.007870405779407,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2920,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83405704066396,
          21.0081608608863
        ]
      },
      "properties": {
        "tenDoiTuon": "B4KL 04",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Lương Định Của",
        "tuDien": "B4KL",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "B4 Kim Liên",
        "diaChiLapD": "",
        "longitude": 105.83405704066396,
        "latitude": 21.0081608608863,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2921,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83427966401437,
          21.007577446175915
        ]
      },
      "properties": {
        "tenDoiTuon": "5-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Nhà B15 - B19",
        "tuDien": "B4KL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "B4 Kim Liên",
        "diaChiLapD": "",
        "longitude": 105.83427966401437,
        "latitude": 21.007577446175915,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2922,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83412677810121,
          21.0074572574543
        ]
      },
      "properties": {
        "tenDoiTuon": "5-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Nhà B15 - B19",
        "tuDien": "B4KL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "B4 Kim Liên",
        "diaChiLapD": "",
        "longitude": 105.83412677810121,
        "latitude": 21.0074572574543,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2923,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83398730322675,
          21.007317037156174
        ]
      },
      "properties": {
        "tenDoiTuon": "B4KL 5-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Nhà B15 - B19",
        "tuDien": "B4KL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "B4 Kim Liên",
        "diaChiLapD": "",
        "longitude": 105.83398730322675,
        "latitude": 21.007317037156174,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2924,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82462404683994,
          21.012810882513822
        ]
      },
      "properties": {
        "tenDoiTuon": "BVND 16-17",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "",
        "tuDien": "BVNĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "BVND",
        "diaChiLapD": "",
        "longitude": 105.82462404683994,
        "latitude": 21.012810882513822,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2925,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82469378426819,
          21.012735767209755
        ]
      },
      "properties": {
        "tenDoiTuon": "BVND 16-16",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "",
        "tuDien": "BVNĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "BVND",
        "diaChiLapD": "",
        "longitude": 105.82469378426819,
        "latitude": 21.012735767209755,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2926,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82453285173182,
          21.012570513406306
        ]
      },
      "properties": {
        "tenDoiTuon": "BVND 16-15",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "",
        "tuDien": "BVNĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "BVND",
        "diaChiLapD": "",
        "longitude": 105.82453285173182,
        "latitude": 21.012570513406306,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2927,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82428608850276,
          21.012820897885188
        ]
      },
      "properties": {
        "tenDoiTuon": "BVND 16-18",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "",
        "tuDien": "BVNĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "BVND",
        "diaChiLapD": "",
        "longitude": 105.82428608850276,
        "latitude": 21.012820897885188,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2928,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82413588479734,
          21.01297112837059
        ]
      },
      "properties": {
        "tenDoiTuon": "BVND 16-19",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "",
        "tuDien": "BVNĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "BVND",
        "diaChiLapD": "",
        "longitude": 105.82413588479734,
        "latitude": 21.01297112837059,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2929,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82403396084925,
          21.013076289619928
        ]
      },
      "properties": {
        "tenDoiTuon": "BVND 16-20",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "",
        "tuDien": "BVNĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "BVND",
        "diaChiLapD": "",
        "longitude": 105.82403396084925,
        "latitude": 21.013076289619928,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2930,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.823840841802,
          21.013291619566825
        ]
      },
      "properties": {
        "tenDoiTuon": "BVND 16-21",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "",
        "tuDien": "BVNĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "BVND",
        "diaChiLapD": "",
        "longitude": 105.823840841802,
        "latitude": 21.013291619566825,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2931,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82384084181098,
          21.013359223091246
        ]
      },
      "properties": {
        "tenDoiTuon": "BVND 16-25",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "",
        "tuDien": "BVNĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "BVND",
        "diaChiLapD": "",
        "longitude": 105.82384084181098,
        "latitude": 21.013359223091246,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2932,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82369063810555,
          21.01347064364694
        ]
      },
      "properties": {
        "tenDoiTuon": "BVND 16-22",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "",
        "tuDien": "BVNĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "BVND",
        "diaChiLapD": "",
        "longitude": 105.82369063810555,
        "latitude": 21.01347064364694,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2933,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82351227120985,
          21.013673453883243
        ]
      },
      "properties": {
        "tenDoiTuon": "BVND 16-23",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "",
        "tuDien": "BVNĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "BVND",
        "diaChiLapD": "",
        "longitude": 105.82351227120985,
        "latitude": 21.013673453883243,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2934,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82333926872069,
          21.013880019581073
        ]
      },
      "properties": {
        "tenDoiTuon": "BVND 16-24",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "",
        "tuDien": "BVNĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "BVND",
        "diaChiLapD": "",
        "longitude": 105.82333926872069,
        "latitude": 21.013880019581073,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2935,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82470451310814,
          21.01233014391144
        ]
      },
      "properties": {
        "tenDoiTuon": "BVND 16-14",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "",
        "tuDien": "BVNĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "BVND",
        "diaChiLapD": "",
        "longitude": 105.82470451310814,
        "latitude": 21.01233014391144,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2936,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82496736959263,
          21.012149866536095
        ]
      },
      "properties": {
        "tenDoiTuon": "BVND 16-13",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "",
        "tuDien": "BVNĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "BVND",
        "diaChiLapD": "",
        "longitude": 105.82496736959263,
        "latitude": 21.012149866536095,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2937,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82512293771353,
          21.012415274818355
        ]
      },
      "properties": {
        "tenDoiTuon": "BVND 16-8",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "",
        "tuDien": "BVNĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "BVND",
        "diaChiLapD": "",
        "longitude": 105.82512293771353,
        "latitude": 21.012415274818355,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2938,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82534824328961,
          21.012772072945356
        ]
      },
      "properties": {
        "tenDoiTuon": "BVND 16-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "",
        "tuDien": "BVNĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 11,
        "tuDien2": "BVND",
        "diaChiLapD": "",
        "longitude": 105.82534824328961,
        "latitude": 21.012772072945356,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2939,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82548771813711,
          21.01294108228561
        ]
      },
      "properties": {
        "tenDoiTuon": "BVND  16-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "",
        "tuDien": "BVNĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "BVND",
        "diaChiLapD": "",
        "longitude": 105.82548771813711,
        "latitude": 21.01294108228561,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2940,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82580958321887,
          21.012815890199587
        ]
      },
      "properties": {
        "tenDoiTuon": "BVND  16-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "",
        "tuDien": "BVNĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "BVND",
        "diaChiLapD": "",
        "longitude": 105.82580958321887,
        "latitude": 21.012815890199587,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2941,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82593296483789,
          21.01276581333608
        ]
      },
      "properties": {
        "tenDoiTuon": "BVND  16-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "",
        "tuDien": "BVNĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "BVND",
        "diaChiLapD": "",
        "longitude": 105.82593296483789,
        "latitude": 21.01276581333608,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2942,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82542200403846,
          21.0128997689092
        ]
      },
      "properties": {
        "tenDoiTuon": "BVND 16-4-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "",
        "tuDien": "BVNĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "BVND",
        "diaChiLapD": "",
        "longitude": 105.82542200403846,
        "latitude": 21.0128997689092,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2943,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82546894268293,
          21.012715736455785
        ]
      },
      "properties": {
        "tenDoiTuon": "BVND 16-4-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "",
        "tuDien": "BVNĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": "",
        "tuDien2": "BVND",
        "diaChiLapD": "",
        "longitude": 105.82546894268293,
        "latitude": 21.012715736455785,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2944,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82565133290818,
          21.012593048026798
        ]
      },
      "properties": {
        "tenDoiTuon": "BVND 16-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "",
        "tuDien": "BVNĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": "",
        "tuDien2": "BVND",
        "diaChiLapD": "",
        "longitude": 105.82565133290818,
        "latitude": 21.012593048026798,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2945,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82571704702482,
          21.012681934551146
        ]
      },
      "properties": {
        "tenDoiTuon": "BVND  16-5-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "",
        "tuDien": "BVNĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": "",
        "tuDien2": "BVND",
        "diaChiLapD": "",
        "longitude": 105.82571704702482,
        "latitude": 21.012681934551146,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2946,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82581360655743,
          21.012507917221328
        ]
      },
      "properties": {
        "tenDoiTuon": "BVND 16-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "",
        "tuDien": "BVNĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": "",
        "tuDien2": "BVND",
        "diaChiLapD": "",
        "longitude": 105.82581360655743,
        "latitude": 21.012507917221328,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2947,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82597453909382,
          21.012425290216274
        ]
      },
      "properties": {
        "tenDoiTuon": "BVND 16-7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "",
        "tuDien": "BVNĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": "",
        "tuDien2": "BVND",
        "diaChiLapD": "",
        "longitude": 105.82597453909382,
        "latitude": 21.012425290216274,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2948,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8257237525352,
          21.01208476631909
        ]
      },
      "properties": {
        "tenDoiTuon": "BVND 16-11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "",
        "tuDien": "BVNĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 11,
        "tuDien2": "BVND",
        "diaChiLapD": "",
        "longitude": 105.8257237525352,
        "latitude": 21.01208476631909,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2949,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82556281998983,
          21.01216488965946
        ]
      },
      "properties": {
        "tenDoiTuon": "BVND 16-10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "",
        "tuDien": "BVNĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "BVND",
        "diaChiLapD": "",
        "longitude": 105.82556281998983,
        "latitude": 21.01216488965946,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2950,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82544748501202,
          21.012202447459956
        ]
      },
      "properties": {
        "tenDoiTuon": "BVND 16--9",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "",
        "tuDien": "BVNĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "BVND",
        "diaChiLapD": "",
        "longitude": 105.82544748501202,
        "latitude": 21.012202447459956,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2951,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8252570481635,
          21.01198461208439
        ]
      },
      "properties": {
        "tenDoiTuon": "BVND 16-12",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "",
        "tuDien": "BVNĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "BVND",
        "diaChiLapD": "",
        "longitude": 105.8252570481635,
        "latitude": 21.01198461208439,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2952,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82516585305537,
          21.011829372886687
        ]
      },
      "properties": {
        "tenDoiTuon": "BVND 16-12-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "",
        "tuDien": "BVNĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "BVND",
        "diaChiLapD": "",
        "longitude": 105.82516585305537,
        "latitude": 21.011829372886687,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2953,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82800094798613,
          21.01478890525309
        ]
      },
      "properties": {
        "tenDoiTuon": "BVND 1-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "",
        "tuDien": "BVNĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "BVND",
        "diaChiLapD": "",
        "longitude": 105.82800094798613,
        "latitude": 21.01478890525309,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2954,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82790707067022,
          21.014596111785703
        ]
      },
      "properties": {
        "tenDoiTuon": "BVND 1-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "",
        "tuDien": "BVNĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "BVND",
        "diaChiLapD": "",
        "longitude": 105.82790707067022,
        "latitude": 21.014596111785703,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2955,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83427731713871,
          21.01933325052331
        ]
      },
      "properties": {
        "tenDoiTuon": "01",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Khâm Thiên",
        "tuDien": "KT3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 3",
        "diaChiLapD": "",
        "longitude": 105.83427731713871,
        "latitude": 21.01933325052331,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2956,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8334699722221,
          21.019380821449445
        ]
      },
      "properties": {
        "tenDoiTuon": "03",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Khâm Thiên",
        "tuDien": "KT3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 3",
        "diaChiLapD": "",
        "longitude": 105.8334699722221,
        "latitude": 21.019380821449445,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2957,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8338749857798,
          21.019353280389343
        ]
      },
      "properties": {
        "tenDoiTuon": "",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Khâm Thiên",
        "tuDien": "KT3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 3",
        "diaChiLapD": "",
        "longitude": 105.8338749857798,
        "latitude": 21.019353280389343,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2958,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8327994199722,
          21.019420881165193
        ]
      },
      "properties": {
        "tenDoiTuon": "4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Khâm Thiên",
        "tuDien": "KT3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 3",
        "diaChiLapD": "",
        "longitude": 105.8327994199722,
        "latitude": 21.019420881165193,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2959,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8325204702323,
          21.01944091101857
        ]
      },
      "properties": {
        "tenDoiTuon": "5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Khâm Thiên",
        "tuDien": "KT3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 3",
        "diaChiLapD": "",
        "longitude": 105.8325204702323,
        "latitude": 21.01944091101857,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2960,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83219324073504,
          21.019453429675703
        ]
      },
      "properties": {
        "tenDoiTuon": "6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Khâm Thiên",
        "tuDien": "KT3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 3",
        "diaChiLapD": "",
        "longitude": 105.83219324073504,
        "latitude": 21.019453429675703,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2961,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83185260019012,
          21.019488481910408
        ]
      },
      "properties": {
        "tenDoiTuon": "7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Khâm Thiên",
        "tuDien": "KT3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 3",
        "diaChiLapD": "",
        "longitude": 105.83185260019012,
        "latitude": 21.019488481910408,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2962,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83159242591337,
          21.019478466987625
        ]
      },
      "properties": {
        "tenDoiTuon": "8",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Khâm Thiên",
        "tuDien": "KT3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 3",
        "diaChiLapD": "",
        "longitude": 105.83159242591337,
        "latitude": 21.019478466987625,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2963,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83122228106532,
          21.019393340111606
        ]
      },
      "properties": {
        "tenDoiTuon": "9",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Khâm Thiên",
        "tuDien": "KT3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 3",
        "diaChiLapD": "",
        "longitude": 105.83122228106532,
        "latitude": 21.019393340111606,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2964,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83099697550719,
          21.01930070198654
        ]
      },
      "properties": {
        "tenDoiTuon": "10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Khâm Thiên",
        "tuDien": "KT3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 3",
        "diaChiLapD": "",
        "longitude": 105.83099697550719,
        "latitude": 21.01930070198654,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2965,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83111499271072,
          21.018977719963807
        ]
      },
      "properties": {
        "tenDoiTuon": "10-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Chiến Thắng",
        "tuDien": "KT3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 3",
        "diaChiLapD": "",
        "longitude": 105.83111499271072,
        "latitude": 21.018977719963807,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2966,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83118607125184,
          21.018833754883772
        ]
      },
      "properties": {
        "tenDoiTuon": "10-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "",
        "tuDien": "KT3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 3",
        "diaChiLapD": "",
        "longitude": 105.83118607125184,
        "latitude": 21.018833754883772,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2967,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83098222336461,
          21.018768657584253
        ]
      },
      "properties": {
        "tenDoiTuon": "10-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Chiến Thắng",
        "tuDien": "KT3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 3",
        "diaChiLapD": "",
        "longitude": 105.83098222336461,
        "latitude": 21.018768657584253,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2968,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8310184331871,
          21.018648477879946
        ]
      },
      "properties": {
        "tenDoiTuon": "10-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Chiến Thắng",
        "tuDien": "KT3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 3",
        "diaChiLapD": "",
        "longitude": 105.8310184331871,
        "latitude": 21.018648477879946,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2969,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8278453798832,
          21.013977668717708
        ]
      },
      "properties": {
        "tenDoiTuon": "BVND 4-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "",
        "tuDien": "BVNĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "BVND",
        "diaChiLapD": "",
        "longitude": 105.8278453798832,
        "latitude": 21.013977668717708,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2970,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82762543874055,
          21.01411287511236
        ]
      },
      "properties": {
        "tenDoiTuon": "BVND 4-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "",
        "tuDien": "BVNĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "BVND",
        "diaChiLapD": "",
        "longitude": 105.82762543874055,
        "latitude": 21.01411287511236,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2971,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8280545921859,
          21.014198005001806
        ]
      },
      "properties": {
        "tenDoiTuon": "BVND 4-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "",
        "tuDien": "BVNĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "BVND",
        "diaChiLapD": "",
        "longitude": 105.8280545921859,
        "latitude": 21.014198005001806,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2972,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82847301679128,
          21.01399769930219
        ]
      },
      "properties": {
        "tenDoiTuon": "BVND 4-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "",
        "tuDien": "BVNĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "BVND",
        "diaChiLapD": "",
        "longitude": 105.82847301679128,
        "latitude": 21.01399769930219,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2973,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82830001427513,
          21.014087836908825
        ]
      },
      "properties": {
        "tenDoiTuon": "BVND 4-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "",
        "tuDien": "BVNĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "BVND",
        "diaChiLapD": "",
        "longitude": 105.82830001427513,
        "latitude": 21.014087836908825,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2974,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82795266824678,
          21.014132905678714
        ]
      },
      "properties": {
        "tenDoiTuon": "BVND 4-2-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "",
        "tuDien": "BVNĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "BVND",
        "diaChiLapD": "",
        "longitude": 105.82795266824678,
        "latitude": 21.014132905678714,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2975,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8280492277704,
          21.01385748515251
        ]
      },
      "properties": {
        "tenDoiTuon": "BVND 4-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "",
        "tuDien": "BVNĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "BVND",
        "diaChiLapD": "",
        "longitude": 105.8280492277704,
        "latitude": 21.01385748515251,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2976,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83124373874521,
          21.018659744731018
        ]
      },
      "properties": {
        "tenDoiTuon": "10-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Chiến Thắng",
        "tuDien": "KT3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 3",
        "diaChiLapD": "",
        "longitude": 105.83124373874521,
        "latitude": 21.018659744731018,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2977,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83134834489208,
          21.01872984956528
        ]
      },
      "properties": {
        "tenDoiTuon": "10-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Chiến Thắng",
        "tuDien": "KT3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 3",
        "diaChiLapD": "",
        "longitude": 105.83134834489208,
        "latitude": 21.01872984956528,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2978,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83149050197426,
          21.018804961850403
        ]
      },
      "properties": {
        "tenDoiTuon": "10-7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Chiến Thắng",
        "tuDien": "KT3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 3",
        "diaChiLapD": "",
        "longitude": 105.83149050197426,
        "latitude": 21.018804961850403,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2979,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82782124001353,
          21.014859011903678
        ]
      },
      "properties": {
        "tenDoiTuon": "BVND 01",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "",
        "tuDien": "BVNĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 11,
        "tuDien2": "BVND",
        "diaChiLapD": "",
        "longitude": 105.82782124001353,
        "latitude": 21.014859011903678,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2980,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82765494305266,
          21.014588600343693
        ]
      },
      "properties": {
        "tenDoiTuon": "BVND 02",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "",
        "tuDien": "BVNĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 11,
        "tuDien2": "BVND",
        "diaChiLapD": "",
        "longitude": 105.82765494305266,
        "latitude": 21.014588600343693,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2981,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82752887922591,
          21.01437327226925
        ]
      },
      "properties": {
        "tenDoiTuon": "BVND 03",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "",
        "tuDien": "BVNĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 11,
        "tuDien2": "BVND",
        "diaChiLapD": "",
        "longitude": 105.82752887922591,
        "latitude": 21.01437327226925,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2982,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82736258227403,
          21.014107867470994
        ]
      },
      "properties": {
        "tenDoiTuon": "BVND 04",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "",
        "tuDien": "BVNĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 11,
        "tuDien2": "BVND",
        "diaChiLapD": "",
        "longitude": 105.82736258227403,
        "latitude": 21.014107867470994,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2983,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8314408810951,
          21.019061595207067
        ]
      },
      "properties": {
        "tenDoiTuon": "10-8",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 261",
        "tuDien": "KT3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 3",
        "diaChiLapD": "",
        "longitude": 105.8314408810951,
        "latitude": 21.019061595207067,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2984,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83196122964858,
          21.019176767106384
        ]
      },
      "properties": {
        "tenDoiTuon": "7-15",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 251",
        "tuDien": "KT3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 3",
        "diaChiLapD": "",
        "longitude": 105.83196122964858,
        "latitude": 21.019176767106384,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2985,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83190490325903,
          21.019027794741532
        ]
      },
      "properties": {
        "tenDoiTuon": "7-16",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Ngõ 251",
        "tuDien": "KT3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 3",
        "diaChiLapD": "",
        "longitude": 105.83190490325903,
        "latitude": 21.019027794741532,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2986,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83204437812452,
          21.01898272744251
        ]
      },
      "properties": {
        "tenDoiTuon": "7-17",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 251",
        "tuDien": "KT3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 3",
        "diaChiLapD": "",
        "longitude": 105.83204437812452,
        "latitude": 21.01898272744251,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2987,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83235014995083,
          21.01928818331661
        ]
      },
      "properties": {
        "tenDoiTuon": "7-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 241 Khâm Thiên",
        "tuDien": "KT3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 3",
        "diaChiLapD": "",
        "longitude": 105.83235014995083,
        "latitude": 21.01928818331661,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2988,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83229114135355,
          21.019119181168254
        ]
      },
      "properties": {
        "tenDoiTuon": "7-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 241 Khâm Thiên",
        "tuDien": "KT3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 3",
        "diaChiLapD": "",
        "longitude": 105.83229114135355,
        "latitude": 21.019119181168254,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2989,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83223213275629,
          21.019035305956567
        ]
      },
      "properties": {
        "tenDoiTuon": "7-7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 241",
        "tuDien": "KT3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 3",
        "diaChiLapD": "",
        "longitude": 105.83223213275629,
        "latitude": 21.019035305956567,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2990,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8239266725216,
          21.00967103052956
        ]
      },
      "properties": {
        "tenDoiTuon": "BVND 26",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "",
        "tuDien": "BVNĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 11,
        "tuDien2": "BVND",
        "diaChiLapD": "",
        "longitude": 105.8239266725216,
        "latitude": 21.00967103052956,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2991,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82411710935214,
          21.009687305847926
        ]
      },
      "properties": {
        "tenDoiTuon": "BVND 27",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "",
        "tuDien": "BVNĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "BVND",
        "diaChiLapD": "",
        "longitude": 105.82411710935214,
        "latitude": 21.009687305847926,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2992,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8202058357202,
          21.008163500835067
        ]
      },
      "properties": {
        "tenDoiTuon": "VH5 01",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Vĩnh Hồ",
        "tuDien": "VH5",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 10,
        "tuDien2": "Vĩnh Hồ 5",
        "diaChiLapD": "",
        "longitude": 105.8202058357202,
        "latitude": 21.008163500835067,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2993,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81999611875972,
          21.00791206160904
        ]
      },
      "properties": {
        "tenDoiTuon": "02",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Vĩnh Hồ",
        "tuDien": "VH5",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 10,
        "tuDien2": "Vĩnh Hồ 5",
        "diaChiLapD": "",
        "longitude": 105.81999611875972,
        "latitude": 21.00791206160904,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2994,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81977265895425,
          21.007740337613143
        ]
      },
      "properties": {
        "tenDoiTuon": "03",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Vĩnh Hồ",
        "tuDien": "VH5",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 10,
        "tuDien2": "Vĩnh Hồ 5",
        "diaChiLapD": "",
        "longitude": 105.81977265895425,
        "latitude": 21.007740337613143,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2995,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81984776080697,
          21.007585093998852
        ]
      },
      "properties": {
        "tenDoiTuon": "04",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Vĩnh Hồ",
        "tuDien": "VH5",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 10,
        "tuDien2": "Vĩnh Hồ 5",
        "diaChiLapD": "",
        "longitude": 105.81984776080697,
        "latitude": 21.007585093998852,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2996,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81998187125696,
          21.00742233842355
        ]
      },
      "properties": {
        "tenDoiTuon": "05",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Vĩnh Hồ",
        "tuDien": "VH5",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 10,
        "tuDien2": "Vĩnh Hồ 5",
        "diaChiLapD": "",
        "longitude": 105.81998187125696,
        "latitude": 21.00742233842355,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2997,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8201749903132,
          21.007220771655543
        ]
      },
      "properties": {
        "tenDoiTuon": "06",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Vĩnh Hồ",
        "tuDien": "VH5",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 10,
        "tuDien2": "Vĩnh Hồ 5",
        "diaChiLapD": "",
        "longitude": 105.8201749903132,
        "latitude": 21.007220771655543,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2998,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82037347378493,
          21.006985401422362
        ]
      },
      "properties": {
        "tenDoiTuon": "07",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Vĩnh Hồ",
        "tuDien": "VH5",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 10,
        "tuDien2": "Vĩnh Hồ 5",
        "diaChiLapD": "",
        "longitude": 105.82037347378493,
        "latitude": 21.006985401422362,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 2999,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82059743823918,
          21.00672624334291
        ]
      },
      "properties": {
        "tenDoiTuon": "08",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Vĩnh Hồ",
        "tuDien": "VH5",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 10,
        "tuDien2": "Vĩnh Hồ 5",
        "diaChiLapD": "",
        "longitude": 105.82059743823918,
        "latitude": 21.00672624334291,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3000,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83216507750883,
          21.019022321261037
        ]
      },
      "properties": {
        "tenDoiTuon": "7-13",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Ngõ 241 Khâm Thiên",
        "tuDien": "KT3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 3",
        "diaChiLapD": "",
        "longitude": 105.83216507750883,
        "latitude": 21.019022321261037,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3001,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83245096658868,
          21.01919670622028
        ]
      },
      "properties": {
        "tenDoiTuon": "7-14",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 235 Khâm Thiên",
        "tuDien": "KT3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 3",
        "diaChiLapD": "",
        "longitude": 105.83245096658868,
        "latitude": 21.01919670622028,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3002,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83336167796101,
          21.007247552940623
        ]
      },
      "properties": {
        "tenDoiTuon": "1-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 97 Phạm Ngọc Thạch",
        "tuDien": "B4KL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "B4 Kim Liên",
        "diaChiLapD": "",
        "longitude": 105.83336167796101,
        "latitude": 21.007247552940623,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3003,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8331444190261,
          21.00710232456887
        ]
      },
      "properties": {
        "tenDoiTuon": "1-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 97 Phạm Ngọc Thạch",
        "tuDien": "B4KL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "B4 Kim Liên",
        "diaChiLapD": "",
        "longitude": 105.8331444190261,
        "latitude": 21.00710232456887,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3004,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83293788893116,
          21.006934560583925
        ]
      },
      "properties": {
        "tenDoiTuon": "B4KL 1-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 97 Phạm Ngọc Thạch",
        "tuDien": "B4KL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "B4 Kim Liên",
        "diaChiLapD": "",
        "longitude": 105.83293788893116,
        "latitude": 21.006934560583925,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3005,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.833495788411,
          21.006972119701196
        ]
      },
      "properties": {
        "tenDoiTuon": "1-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 97 Phạm Ngọc Thạch",
        "tuDien": "B4KL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "B4 Kim Liên",
        "diaChiLapD": "",
        "longitude": 105.833495788411,
        "latitude": 21.006972119701196,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3006,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83310150368428,
          21.006651614927595
        ]
      },
      "properties": {
        "tenDoiTuon": "1-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 97 Phạm Ngọc Thạch",
        "tuDien": "B4KL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "B4 Kim Liên",
        "diaChiLapD": "",
        "longitude": 105.83310150368428,
        "latitude": 21.006651614927595,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3007,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83252583462081,
          21.019102440873535
        ]
      },
      "properties": {
        "tenDoiTuon": "4-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 231 Khâm Thiên",
        "tuDien": "KT3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 3",
        "diaChiLapD": "",
        "longitude": 105.83252583462081,
        "latitude": 21.019102440873535,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3008,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83242525178558,
          21.01878196216446
        ]
      },
      "properties": {
        "tenDoiTuon": "4-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 231 Khâm Thiên",
        "tuDien": "KT3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 3",
        "diaChiLapD": "",
        "longitude": 105.83242525178558,
        "latitude": 21.01878196216446,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3009,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83251644689373,
          21.01881325894401
        ]
      },
      "properties": {
        "tenDoiTuon": "4-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 231 Khâm Thiên",
        "tuDien": "KT3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 3",
        "diaChiLapD": "",
        "longitude": 105.83251644689373,
        "latitude": 21.01881325894401,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3010,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83299388009638,
          21.019033588083868
        ]
      },
      "properties": {
        "tenDoiTuon": "3-11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 221 Khâm Thiên",
        "tuDien": "KT3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 3",
        "diaChiLapD": "",
        "longitude": 105.83299388009638,
        "latitude": 21.019033588083868,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3011,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83440338092954,
          21.019620713579275
        ]
      },
      "properties": {
        "tenDoiTuon": "1-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "",
        "tuDien": "KT3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 3",
        "diaChiLapD": "",
        "longitude": 105.83440338092954,
        "latitude": 21.019620713579275,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3012,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83410967904705,
          21.01963323222132
        ]
      },
      "properties": {
        "tenDoiTuon": "1-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "",
        "tuDien": "KT3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 3",
        "diaChiLapD": "",
        "longitude": 105.83410967904705,
        "latitude": 21.01963323222132,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3013,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83438494072662,
          21.01992506932179
        ]
      },
      "properties": {
        "tenDoiTuon": "1-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "",
        "tuDien": "KT3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 3",
        "diaChiLapD": "",
        "longitude": 105.83438494072662,
        "latitude": 21.01992506932179,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3014,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83451100455338,
          21.019920061874746
        ]
      },
      "properties": {
        "tenDoiTuon": "1-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "",
        "tuDien": "KT3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 3",
        "diaChiLapD": "",
        "longitude": 105.83451100455338,
        "latitude": 21.019920061874746,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3015,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83488349634895,
          21.01985981946135
        ]
      },
      "properties": {
        "tenDoiTuon": "1-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "",
        "tuDien": "KT3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 3",
        "diaChiLapD": "",
        "longitude": 105.83488349634895,
        "latitude": 21.01985981946135,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3016,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83495323377717,
          21.0201264658832
        ]
      },
      "properties": {
        "tenDoiTuon": "1-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "",
        "tuDien": "KT3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 3",
        "diaChiLapD": "",
        "longitude": 105.83495323377717,
        "latitude": 21.0201264658832,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3017,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83471854049195,
          21.02015651052056
        ]
      },
      "properties": {
        "tenDoiTuon": "1-7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "",
        "tuDien": "KT3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 3",
        "diaChiLapD": "",
        "longitude": 105.83471854049195,
        "latitude": 21.02015651052056,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3018,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83348472434672,
          21.019525571864886
        ]
      },
      "properties": {
        "tenDoiTuon": "3-12",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 292 Khâm Thiên",
        "tuDien": "KT3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 3",
        "diaChiLapD": "",
        "longitude": 105.83348472434672,
        "latitude": 21.019525571864886,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3019,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83347935993125,
          21.019634484085696
        ]
      },
      "properties": {
        "tenDoiTuon": "3-13",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 292 Khâm Thiên",
        "tuDien": "KT3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 3",
        "diaChiLapD": "",
        "longitude": 105.83347935993125,
        "latitude": 21.019634484085696,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3020,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8331923635591,
          21.01973713691029
        ]
      },
      "properties": {
        "tenDoiTuon": "3-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Tiến Bộ",
        "tuDien": "KT3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 3",
        "diaChiLapD": "",
        "longitude": 105.8331923635591,
        "latitude": 21.01973713691029,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3021,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83299656230413,
          21.019762174173742
        ]
      },
      "properties": {
        "tenDoiTuon": "3-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Tiến Bộ",
        "tuDien": "KT3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 3",
        "diaChiLapD": "",
        "longitude": 105.83299656230413,
        "latitude": 21.019762174173742,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3022,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83488256826853,
          21.019081367437625
        ]
      },
      "properties": {
        "tenDoiTuon": "10-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 185KT",
        "tuDien": "KTH1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 1",
        "diaChiLapD": "",
        "longitude": 105.83488256826853,
        "latitude": 21.019081367437625,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3023,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8332151623429,
          21.019939938623814
        ]
      },
      "properties": {
        "tenDoiTuon": "3-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Tiến Bộ",
        "tuDien": "KT3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 3",
        "diaChiLapD": "",
        "longitude": 105.8332151623429,
        "latitude": 21.019939938623814,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3024,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83307836967619,
          21.019958716546657
        ]
      },
      "properties": {
        "tenDoiTuon": "3-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Tiến Bộ",
        "tuDien": "KT3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 3",
        "diaChiLapD": "",
        "longitude": 105.83307836967619,
        "latitude": 21.019958716546657,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3025,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8331963868797,
          21.02013147332334
        ]
      },
      "properties": {
        "tenDoiTuon": "3-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Tiến Bộ",
        "tuDien": "KT3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 3",
        "diaChiLapD": "",
        "longitude": 105.8331963868797,
        "latitude": 21.02013147332334,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3026,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83331976848973,
          21.020216599777097
        ]
      },
      "properties": {
        "tenDoiTuon": "3-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Tiến Bộ",
        "tuDien": "KT3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 3",
        "diaChiLapD": "",
        "longitude": 105.83331976848973,
        "latitude": 21.020216599777097,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3027,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83323259670222,
          21.020262918562302
        ]
      },
      "properties": {
        "tenDoiTuon": "3-9",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Ngõ Tiến Bộ",
        "tuDien": "KT3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 3",
        "diaChiLapD": "",
        "longitude": 105.83323259670222,
        "latitude": 21.020262918562302,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3028,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83337207156765,
          21.020383096965407
        ]
      },
      "properties": {
        "tenDoiTuon": "3-8",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Tiến Bộ",
        "tuDien": "KT3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 3",
        "diaChiLapD": "",
        "longitude": 105.83337207156765,
        "latitude": 21.020383096965407,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3029,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83376400929298,
          21.010980875147595
        ]
      },
      "properties": {
        "tenDoiTuon": "TTCT 10-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Khu Nhà G1-G3 (TT)",
        "tuDien": "TTCT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Trung Tự Cao Tầng",
        "diaChiLapD": "",
        "longitude": 105.83376400929298,
        "latitude": 21.010980875147595,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3030,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83306495863746,
          21.02024163696003
        ]
      },
      "properties": {
        "tenDoiTuon": "3-7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Tiến Bộ",
        "tuDien": "KT3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 3",
        "diaChiLapD": "",
        "longitude": 105.83306495863746,
        "latitude": 21.02024163696003,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3031,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83308909851613,
          21.020329267068053
        ]
      },
      "properties": {
        "tenDoiTuon": "3-10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Tiến Bộ",
        "tuDien": "KT3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 3",
        "diaChiLapD": "",
        "longitude": 105.83308909851613,
        "latitude": 21.020329267068053,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3032,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83579041826614,
          21.018836571595628
        ]
      },
      "properties": {
        "tenDoiTuon": "8-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 151",
        "tuDien": "KTH1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 1",
        "diaChiLapD": "",
        "longitude": 105.83579041826614,
        "latitude": 21.018836571595628,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3033,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83420120936155,
          21.01143908330699
        ]
      },
      "properties": {
        "tenDoiTuon": "TTCT 17-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Khu Nhà G1-G3 (TT)",
        "tuDien": "TTCT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Trung Tự Cao Tầng",
        "diaChiLapD": "",
        "longitude": 105.83420120936155,
        "latitude": 21.01143908330699,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3034,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81979889476754,
          21.0074123232099
        ]
      },
      "properties": {
        "tenDoiTuon": "5-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "B1,B2,B3 Tập Thể Vĩnh Hồ",
        "tuDien": "VH5",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 5",
        "diaChiLapD": "",
        "longitude": 105.81979889476754,
        "latitude": 21.0074123232099,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3035,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8196521434166,
          21.007301663215895
        ]
      },
      "properties": {
        "tenDoiTuon": "5-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "B1,B2,B3 Tập Thể Vĩnh Hồ",
        "tuDien": "VH5",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 5",
        "diaChiLapD": "",
        "longitude": 105.8196521434166,
        "latitude": 21.007301663215895,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3036,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82002136498055,
          21.007125972114807
        ]
      },
      "properties": {
        "tenDoiTuon": "6-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "B1,B2,B3 Tập Thể Vĩnh Hồ",
        "tuDien": "VH5",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 5",
        "diaChiLapD": "",
        "longitude": 105.82002136498055,
        "latitude": 21.007125972114807,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3037,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81965118828236,
          21.0068333090597
        ]
      },
      "properties": {
        "tenDoiTuon": "6-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "B1,B2,B3 Tập Thể Vĩnh Hồ",
        "tuDien": "VH5",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 5",
        "diaChiLapD": "",
        "longitude": 105.81965118828236,
        "latitude": 21.0068333090597,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3038,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82015541021855,
          21.006935268423938
        ]
      },
      "properties": {
        "tenDoiTuon": "7-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "B1,B2,B3 Tập Thể Vĩnh Hồ",
        "tuDien": "VH5",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 5",
        "diaChiLapD": "",
        "longitude": 105.82015541021855,
        "latitude": 21.006935268423938,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3039,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81994969705718,
          21.0067663251726
        ]
      },
      "properties": {
        "tenDoiTuon": "7-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "B1,B2,B3 Tập Thể Vĩnh Hồ",
        "tuDien": "VH5",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 5",
        "diaChiLapD": "",
        "longitude": 105.81994969705718,
        "latitude": 21.0067663251726,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3040,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83220497535574,
          21.01969097099927
        ]
      },
      "properties": {
        "tenDoiTuon": "6-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Kiến Thiết",
        "tuDien": "KT3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 3",
        "diaChiLapD": "",
        "longitude": 105.83220497535574,
        "latitude": 21.01969097099927,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3041,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83210171030377,
          21.01960584424541
        ]
      },
      "properties": {
        "tenDoiTuon": "6-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Kiến Thiết",
        "tuDien": "KT3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 3",
        "diaChiLapD": "",
        "longitude": 105.83210171030377,
        "latitude": 21.01960584424541,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3042,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83250270055883,
          21.01961961475323
        ]
      },
      "properties": {
        "tenDoiTuon": "6-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Kiến Thiết",
        "tuDien": "KT3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 3",
        "diaChiLapD": "",
        "longitude": 105.83250270055883,
        "latitude": 21.01961961475323,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3043,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83266095088749,
          21.019683459817276
        ]
      },
      "properties": {
        "tenDoiTuon": "6-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Kiến Thiết",
        "tuDien": "KT3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 3",
        "diaChiLapD": "",
        "longitude": 105.83266095088749,
        "latitude": 21.019683459817276,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3044,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83232031034254,
          21.019998929145206
        ]
      },
      "properties": {
        "tenDoiTuon": "6-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Kiến Thiết",
        "tuDien": "KT3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 3",
        "diaChiLapD": "",
        "longitude": 105.83232031034254,
        "latitude": 21.019998929145206,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3045,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82768847066741,
          21.014909088065245
        ]
      },
      "properties": {
        "tenDoiTuon": "BVND 05",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "",
        "tuDien": "BVNĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 11,
        "tuDien2": "BVND",
        "diaChiLapD": "",
        "longitude": 105.82768847066741,
        "latitude": 21.014909088065245,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3046,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83690638205233,
          21.01824882957433
        ]
      },
      "properties": {
        "tenDoiTuon": "28",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Cống Thắng",
        "tuDien": "KTH1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 1",
        "diaChiLapD": "",
        "longitude": 105.83690638205233,
        "latitude": 21.01824882957433,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3047,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83679088222107,
          21.01801784581548
        ]
      },
      "properties": {
        "tenDoiTuon": "29",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Cống Thắng",
        "tuDien": "KTH1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 1",
        "diaChiLapD": "",
        "longitude": 105.83679088222107,
        "latitude": 21.01801784581548,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3048,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83668627607418,
          21.018095462213907
        ]
      },
      "properties": {
        "tenDoiTuon": "30",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Cống Thắng",
        "tuDien": "KTH1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 1",
        "diaChiLapD": "",
        "longitude": 105.83668627607418,
        "latitude": 21.018095462213907,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3049,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83655484783195,
          21.018128011013744
        ]
      },
      "properties": {
        "tenDoiTuon": "31",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Cống Thắng",
        "tuDien": "KTH1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 1",
        "diaChiLapD": "",
        "longitude": 105.83655484783195,
        "latitude": 21.018128011013744,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3050,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.832490630615,
          21.019972640059972
        ]
      },
      "properties": {
        "tenDoiTuon": "6-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Kiến Thiết",
        "tuDien": "KT3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 3",
        "diaChiLapD": "",
        "longitude": 105.832490630615,
        "latitude": 21.019972640059972,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3051,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83258987234638,
          21.020006440311324
        ]
      },
      "properties": {
        "tenDoiTuon": "6-9",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Kiến Thiết",
        "tuDien": "KT3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 3",
        "diaChiLapD": "",
        "longitude": 105.83258987234638,
        "latitude": 21.020006440311324,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3052,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83214194343786,
          21.020076544511607
        ]
      },
      "properties": {
        "tenDoiTuon": "6-7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Kiến Thiết",
        "tuDien": "KT3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 3",
        "diaChiLapD": "",
        "longitude": 105.83214194343786,
        "latitude": 21.020076544511607,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3053,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83210975693599,
          21.02038825743455
        ]
      },
      "properties": {
        "tenDoiTuon": "6-8",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Kiến Thiết",
        "tuDien": "KT3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 3",
        "diaChiLapD": "",
        "longitude": 105.83210975693599,
        "latitude": 21.02038825743455,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3054,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83180264400579,
          21.019580806955723
        ]
      },
      "properties": {
        "tenDoiTuon": "7-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Vạn Anh",
        "tuDien": "KT3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 3",
        "diaChiLapD": "",
        "longitude": 105.83180264400579,
        "latitude": 21.019580806955723,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3055,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83180264400579,
          21.01981240172628
        ]
      },
      "properties": {
        "tenDoiTuon": "7-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Vạn Anh",
        "tuDien": "KT3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 3",
        "diaChiLapD": "",
        "longitude": 105.83180264400579,
        "latitude": 21.01981240172628,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3056,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83181203173288,
          21.019910046980968
        ]
      },
      "properties": {
        "tenDoiTuon": "7-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Vạn Anh",
        "tuDien": "KT3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 3",
        "diaChiLapD": "",
        "longitude": 105.83181203173288,
        "latitude": 21.019910046980968,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3057,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83198771642084,
          21.01991505442837
        ]
      },
      "properties": {
        "tenDoiTuon": "7-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Vạn Anh",
        "tuDien": "KT3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 3",
        "diaChiLapD": "",
        "longitude": 105.83198771642084,
        "latitude": 21.01991505442837,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3058,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83137617276819,
          21.019504443195945
        ]
      },
      "properties": {
        "tenDoiTuon": "8-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Đoàn Kết",
        "tuDien": "KT3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 3",
        "diaChiLapD": "",
        "longitude": 105.83137617276819,
        "latitude": 21.019504443195945,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3059,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83142311142164,
          21.019659674404792
        ]
      },
      "properties": {
        "tenDoiTuon": "8-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Đoàn Kết",
        "tuDien": "KT3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 3",
        "diaChiLapD": "",
        "longitude": 105.83142311142164,
        "latitude": 21.019659674404792,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3060,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83151698873753,
          21.019851209463482
        ]
      },
      "properties": {
        "tenDoiTuon": "8-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Đoàn Kết",
        "tuDien": "KT3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 3",
        "diaChiLapD": "",
        "longitude": 105.83151698873753,
        "latitude": 21.019851209463482,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3061,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83155051635228,
          21.019986410533047
        ]
      },
      "properties": {
        "tenDoiTuon": "8-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Đoàn Kết",
        "tuDien": "KT3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 3",
        "diaChiLapD": "",
        "longitude": 105.83155051635228,
        "latitude": 21.019986410533047,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3062,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83163500594105,
          21.020182952611265
        ]
      },
      "properties": {
        "tenDoiTuon": "8-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Đoàn Kết",
        "tuDien": "KT3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 3",
        "diaChiLapD": "",
        "longitude": 105.83163500594105,
        "latitude": 21.020182952611265,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3063,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83144859241317,
          21.020238034420778
        ]
      },
      "properties": {
        "tenDoiTuon": "8-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Đoàn Kết",
        "tuDien": "KT3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 3",
        "diaChiLapD": "",
        "longitude": 105.83144859241317,
        "latitude": 21.020238034420778,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3064,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83125949667753,
          21.020334427538252
        ]
      },
      "properties": {
        "tenDoiTuon": "8-7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Đoàn Kết",
        "tuDien": "KT3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 3",
        "diaChiLapD": "",
        "longitude": 105.83125949667753,
        "latitude": 21.020334427538252,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3065,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83176106975884,
          21.02038325000309
        ]
      },
      "properties": {
        "tenDoiTuon": "8-12",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Đoàn Kết",
        "tuDien": "KT3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 3",
        "diaChiLapD": "",
        "longitude": 105.83176106975884,
        "latitude": 21.02038325000309,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3066,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83170072005768,
          21.02052345801715
        ]
      },
      "properties": {
        "tenDoiTuon": "8-13",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Đoàn Kết",
        "tuDien": "KT3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 3",
        "diaChiLapD": "",
        "longitude": 105.83170072005768,
        "latitude": 21.02052345801715,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3067,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83117098377711,
          21.020144144960383
        ]
      },
      "properties": {
        "tenDoiTuon": "8-8",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Đoàn Kết",
        "tuDien": "KT3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 3",
        "diaChiLapD": "",
        "longitude": 105.83117098377711,
        "latitude": 21.020144144960383,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3068,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83100066350465,
          21.020199226784236
        ]
      },
      "properties": {
        "tenDoiTuon": "8-9",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Đoàn Kết",
        "tuDien": "KT3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 3",
        "diaChiLapD": "",
        "longitude": 105.83100066350465,
        "latitude": 21.020199226784236,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3069,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83720529409558,
          21.018099639175038
        ]
      },
      "properties": {
        "tenDoiTuon": "20",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "",
        "tuDien": "KTH1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 1",
        "diaChiLapD": "",
        "longitude": 105.83720529409558,
        "latitude": 21.018099639175038,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3070,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8371087239822,
          21.01794148125444
        ]
      },
      "properties": {
        "tenDoiTuon": "21",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "",
        "tuDien": "KTH1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 1",
        "diaChiLapD": "",
        "longitude": 105.8371087239822,
        "latitude": 21.01794148125444,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3071,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83706806924445,
          21.017772044465627
        ]
      },
      "properties": {
        "tenDoiTuon": "22",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "",
        "tuDien": "KTH1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 1",
        "diaChiLapD": "",
        "longitude": 105.83706806924445,
        "latitude": 21.017772044465627,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3072,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83713406061483,
          21.01763424784434
        ]
      },
      "properties": {
        "tenDoiTuon": "23",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "",
        "tuDien": "KTH1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 1",
        "diaChiLapD": "",
        "longitude": 105.83713406061483,
        "latitude": 21.01763424784434,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3073,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83728785214143,
          21.017763042637828
        ]
      },
      "properties": {
        "tenDoiTuon": "24",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "",
        "tuDien": "KTH1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 1",
        "diaChiLapD": "",
        "longitude": 105.83728785214143,
        "latitude": 21.017763042637828,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3074,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83736579266179,
          21.017875771200185
        ]
      },
      "properties": {
        "tenDoiTuon": "25",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "",
        "tuDien": "KTH1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 1",
        "diaChiLapD": "",
        "longitude": 105.83736579266179,
        "latitude": 21.017875771200185,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3075,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83130107091547,
          21.020532221013724
        ]
      },
      "properties": {
        "tenDoiTuon": "8-10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Đoàn Kết",
        "tuDien": "KT3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 3",
        "diaChiLapD": "",
        "longitude": 105.83130107091547,
        "latitude": 21.020532221013724,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3076,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83132386969028,
          21.020623606518416
        ]
      },
      "properties": {
        "tenDoiTuon": "8-1-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Đoàn Kết",
        "tuDien": "KT3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 3",
        "diaChiLapD": "",
        "longitude": 105.83132386969028,
        "latitude": 21.020623606518416,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3077,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83144054578095,
          21.0206048286793
        ]
      },
      "properties": {
        "tenDoiTuon": "8-11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Đoàn Kết",
        "tuDien": "KT3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 3",
        "diaChiLapD": "",
        "longitude": 105.83144054578095,
        "latitude": 21.0206048286793,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3078,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8315263764736,
          21.020632369509066
        ]
      },
      "properties": {
        "tenDoiTuon": "8-11-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Đoàn Kết",
        "tuDien": "KT3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 3",
        "diaChiLapD": "",
        "longitude": 105.8315263764736,
        "latitude": 21.020632369509066,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3079,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83150357769883,
          21.020473383741724
        ]
      },
      "properties": {
        "tenDoiTuon": "8-11-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Đoàn Kết",
        "tuDien": "KT3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 3",
        "diaChiLapD": "",
        "longitude": 105.83150357769883,
        "latitude": 21.020473383741724,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3080,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81954416182568,
          21.007637676532465
        ]
      },
      "properties": {
        "tenDoiTuon": "09",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Thịnh Quang",
        "tuDien": "VH5",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 5",
        "diaChiLapD": "",
        "longitude": 105.81954416182568,
        "latitude": 21.007637676532465,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3081,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81931131735531,
          21.007511008846276
        ]
      },
      "properties": {
        "tenDoiTuon": "10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Thịnh Quang",
        "tuDien": "VH5",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 5",
        "diaChiLapD": "",
        "longitude": 105.81931131735531,
        "latitude": 21.007511008846276,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3082,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81908989582493,
          21.007367597143354
        ]
      },
      "properties": {
        "tenDoiTuon": "11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Thịnh Quang",
        "tuDien": "VH5",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 5",
        "diaChiLapD": "",
        "longitude": 105.81908989582493,
        "latitude": 21.007367597143354,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3083,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83221302197896,
          21.018906050411083
        ]
      },
      "properties": {
        "tenDoiTuon": "7-13-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 241 Khâm Thiên",
        "tuDien": "KT3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 3",
        "diaChiLapD": "",
        "longitude": 105.83221302197896,
        "latitude": 21.018906050411083,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3084,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83233640359798,
          21.018923576593807
        ]
      },
      "properties": {
        "tenDoiTuon": "7-8",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 241 Khâm Thiên",
        "tuDien": "KT3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 3",
        "diaChiLapD": "",
        "longitude": 105.83233640359798,
        "latitude": 21.018923576593807,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3085,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8191471948912,
          21.007305905480646
        ]
      },
      "properties": {
        "tenDoiTuon": "11-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Tập Thể Binh Đoàn 12",
        "tuDien": "VH5",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 5",
        "diaChiLapD": "",
        "longitude": 105.8191471948912,
        "latitude": 21.007305905480646,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3086,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81926521209024,
          21.007173822838027
        ]
      },
      "properties": {
        "tenDoiTuon": "11-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Tập Thể Binh Đoàn 12",
        "tuDien": "VH5",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 5",
        "diaChiLapD": "",
        "longitude": 105.81926521209024,
        "latitude": 21.007173822838027,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3087,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81938322928927,
          21.007041740078456
        ]
      },
      "properties": {
        "tenDoiTuon": "11-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Tập Thể Binh Đoàn 12",
        "tuDien": "VH5",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 5",
        "diaChiLapD": "",
        "longitude": 105.81938322928927,
        "latitude": 21.007041740078456,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3088,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81885536306808,
          21.00782062876361
        ]
      },
      "properties": {
        "tenDoiTuon": "11-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Tập Thể Binh Đoàn 12",
        "tuDien": "VH5",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 5",
        "diaChiLapD": "",
        "longitude": 105.81885536306808,
        "latitude": 21.00782062876361,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3089,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83481164817623,
          21.020026598155816
        ]
      },
      "properties": {
        "tenDoiTuon": "1-8",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "",
        "tuDien": "KT3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 3",
        "diaChiLapD": "",
        "longitude": 105.83481164817623,
        "latitude": 21.020026598155816,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3090,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82493384198685,
          21.012112308724873
        ]
      },
      "properties": {
        "tenDoiTuon": "BVND  16-3-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "",
        "tuDien": "BVNĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "BVND",
        "diaChiLapD": "",
        "longitude": 105.82493384198685,
        "latitude": 21.012112308724873,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3091,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81877570890333,
          21.00722577953567
        ]
      },
      "properties": {
        "tenDoiTuon": "16",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Thịnh Quang",
        "tuDien": "VH5",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 5",
        "diaChiLapD": "",
        "longitude": 105.81877570890333,
        "latitude": 21.00722577953567,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3092,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81887003795504,
          21.00699622012561
        ]
      },
      "properties": {
        "tenDoiTuon": "15",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Thịnh Quang",
        "tuDien": "VH5",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 5",
        "diaChiLapD": "",
        "longitude": 105.81887003795504,
        "latitude": 21.00699622012561,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3093,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81861490116708,
          21.006859518466715
        ]
      },
      "properties": {
        "tenDoiTuon": "VH5 15-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 161 Thịnh Quang",
        "tuDien": "VH5",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 5",
        "diaChiLapD": "",
        "longitude": 105.81861490116708,
        "latitude": 21.006859518466715,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3094,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81900513402267,
          21.00676216524288
        ]
      },
      "properties": {
        "tenDoiTuon": "14",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Thịnh Quang",
        "tuDien": "VH5",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 5",
        "diaChiLapD": "",
        "longitude": 105.81900513402267,
        "latitude": 21.00676216524288,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3095,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81914987706301,
          21.006534691434613
        ]
      },
      "properties": {
        "tenDoiTuon": "13",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Thịnh Quang",
        "tuDien": "VH5",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 5",
        "diaChiLapD": "",
        "longitude": 105.81914987706301,
        "latitude": 21.006534691434613,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3096,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81933124436311,
          21.00626031097636
        ]
      },
      "properties": {
        "tenDoiTuon": "12",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Thịnh Quang",
        "tuDien": "VH5",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 5",
        "diaChiLapD": "",
        "longitude": 105.81933124436311,
        "latitude": 21.00626031097636,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3097,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81902503780807,
          21.006548903919192
        ]
      },
      "properties": {
        "tenDoiTuon": "13-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 141 Thịnh Quang",
        "tuDien": "VH5",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 5",
        "diaChiLapD": "",
        "longitude": 105.81902503780807,
        "latitude": 21.006548903919192,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3098,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8188583162317,
          21.006514787606147
        ]
      },
      "properties": {
        "tenDoiTuon": "13-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 141 Thịnh Quang",
        "tuDien": "VH5",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 5",
        "diaChiLapD": "",
        "longitude": 105.8188583162317,
        "latitude": 21.006514787606147,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3099,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81862877963822,
          21.00644163064738
        ]
      },
      "properties": {
        "tenDoiTuon": "13-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 141 Thịnh Quang",
        "tuDien": "VH5",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 5",
        "diaChiLapD": "",
        "longitude": 105.81862877963822,
        "latitude": 21.00644163064738,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3100,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81852451674149,
          21.00613818561187
        ]
      },
      "properties": {
        "tenDoiTuon": "VH5 13-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 141 Thịnh Quang",
        "tuDien": "VH5",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 5",
        "diaChiLapD": "",
        "longitude": 105.81852451674149,
        "latitude": 21.00613818561187,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3101,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81870038825696,
          21.0074901215607
        ]
      },
      "properties": {
        "tenDoiTuon": "17",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "",
        "tuDien": "VH5",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 5",
        "diaChiLapD": "",
        "longitude": 105.81870038825696,
        "latitude": 21.0074901215607,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3102,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81853262507813,
          21.00775265271826
        ]
      },
      "properties": {
        "tenDoiTuon": "17-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 88 Thịnh Quang",
        "tuDien": "VH5",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 5",
        "diaChiLapD": "",
        "longitude": 105.81853262507813,
        "latitude": 21.00775265271826,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3103,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81850064621685,
          21.007900620228767
        ]
      },
      "properties": {
        "tenDoiTuon": "17-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 88 Thịnh Quang",
        "tuDien": "VH5",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 5",
        "diaChiLapD": "",
        "longitude": 105.81850064621685,
        "latitude": 21.007900620228767,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3104,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81825514155618,
          21.007279791111255
        ]
      },
      "properties": {
        "tenDoiTuon": "18",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 175 Thịnh Quang",
        "tuDien": "VH5",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 5",
        "diaChiLapD": "",
        "longitude": 105.81825514155618,
        "latitude": 21.007279791111255,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3105,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81836533700255,
          21.007135464329544
        ]
      },
      "properties": {
        "tenDoiTuon": "18-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 175 Thịnh Quang",
        "tuDien": "VH5",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 5",
        "diaChiLapD": "",
        "longitude": 105.81836533700255,
        "latitude": 21.007135464329544,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3106,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81843250259926,
          21.00691578101798
        ]
      },
      "properties": {
        "tenDoiTuon": "18-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 175 Thịnh Quang",
        "tuDien": "VH5",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 5",
        "diaChiLapD": "",
        "longitude": 105.81843250259926,
        "latitude": 21.00691578101798,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3107,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81805162892024,
          21.007045985934813
        ]
      },
      "properties": {
        "tenDoiTuon": "18-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 175 Thịnh Quang",
        "tuDien": "VH5",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 5",
        "diaChiLapD": "",
        "longitude": 105.81805162892024,
        "latitude": 21.007045985934813,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3108,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81793327647445,
          21.007232216325555
        ]
      },
      "properties": {
        "tenDoiTuon": "18-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 175 Thịnh Quang",
        "tuDien": "VH5",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 5",
        "diaChiLapD": "",
        "longitude": 105.81793327647445,
        "latitude": 21.007232216325555,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3109,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81770655998659,
          21.007237699321326
        ]
      },
      "properties": {
        "tenDoiTuon": "18-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 175 Thịnh Quang",
        "tuDien": "VH5",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 5",
        "diaChiLapD": "",
        "longitude": 105.81770655998659,
        "latitude": 21.007237699321326,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3110,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81750596554706,
          21.007379364085814
        ]
      },
      "properties": {
        "tenDoiTuon": "VH5 18-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 175 Thịnh Quang",
        "tuDien": "VH5",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 5",
        "diaChiLapD": "",
        "longitude": 105.81750596554706,
        "latitude": 21.007379364085814,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3111,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81806202249096,
          21.007636601521767
        ]
      },
      "properties": {
        "tenDoiTuon": "19",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Thịnh Qung",
        "tuDien": "VH5",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 5",
        "diaChiLapD": "",
        "longitude": 105.81806202249096,
        "latitude": 21.007636601521767,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3112,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81783269361225,
          21.007953348293103
        ]
      },
      "properties": {
        "tenDoiTuon": "20",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Thịnh Qung",
        "tuDien": "VH5",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 5",
        "diaChiLapD": "",
        "longitude": 105.81783269361225,
        "latitude": 21.007953348293103,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3113,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81795122052043,
          21.007992615276805
        ]
      },
      "properties": {
        "tenDoiTuon": "20-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 51 Thái Thịnh",
        "tuDien": "VH5",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 5",
        "diaChiLapD": "",
        "longitude": 105.81795122052043,
        "latitude": 21.007992615276805,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3114,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81816350258734,
          21.008102138936597
        ]
      },
      "properties": {
        "tenDoiTuon": "20-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 51 Thái Thịnh",
        "tuDien": "VH5",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 5",
        "diaChiLapD": "",
        "longitude": 105.81816350258734,
        "latitude": 21.008102138936597,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3115,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81773297965249,
          21.008154442483036
        ]
      },
      "properties": {
        "tenDoiTuon": "21",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Thịnh Qung",
        "tuDien": "VH5",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 5",
        "diaChiLapD": "",
        "longitude": 105.81773297965249,
        "latitude": 21.008154442483036,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3116,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81749104724167,
          21.007957417170335
        ]
      },
      "properties": {
        "tenDoiTuon": "21-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 221 Thịnh Quang",
        "tuDien": "VH5",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 5",
        "diaChiLapD": "",
        "longitude": 105.81749104724167,
        "latitude": 21.007957417170335,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3117,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81756929494725,
          21.007821701843486
        ]
      },
      "properties": {
        "tenDoiTuon": "VH5 21-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 221 Thịnh Quang",
        "tuDien": "VH5",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 5",
        "diaChiLapD": "",
        "longitude": 105.81756929494725,
        "latitude": 21.007821701843486,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3118,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81782330588514,
          21.007724239035834
        ]
      },
      "properties": {
        "tenDoiTuon": "19-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 209 Thịnh Quang",
        "tuDien": "VH5",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 5",
        "diaChiLapD": "",
        "longitude": 105.81782330588514,
        "latitude": 21.007724239035834,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3119,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81807945684128,
          21.007408743745113
        ]
      },
      "properties": {
        "tenDoiTuon": "19-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 209 Thịnh Quang",
        "tuDien": "VH5",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 5",
        "diaChiLapD": "",
        "longitude": 105.81807945684128,
        "latitude": 21.007408743745113,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3120,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8176488286185,
          21.00842853189763
        ]
      },
      "properties": {
        "tenDoiTuon": "22",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Thịnh Quang",
        "tuDien": "VH5",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 5",
        "diaChiLapD": "",
        "longitude": 105.8176488286185,
        "latitude": 21.00842853189763,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3121,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81789258207819,
          21.008486461220322
        ]
      },
      "properties": {
        "tenDoiTuon": "22-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 224 Thịnh Quang",
        "tuDien": "VH5",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 5",
        "diaChiLapD": "",
        "longitude": 105.81789258207819,
        "latitude": 21.008486461220322,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3122,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81757386044836,
          21.008683240465416
        ]
      },
      "properties": {
        "tenDoiTuon": "23",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Thịnh Quang",
        "tuDien": "VH5",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 5",
        "diaChiLapD": "",
        "longitude": 105.81757386044836,
        "latitude": 21.008683240465416,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3123,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81767700040879,
          21.00876470766936
        ]
      },
      "properties": {
        "tenDoiTuon": "23-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 224 Thịnh Quang",
        "tuDien": "VH5",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 5",
        "diaChiLapD": "",
        "longitude": 105.81767700040879,
        "latitude": 21.00876470766936,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3124,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8174375152603,
          21.00866400985537
        ]
      },
      "properties": {
        "tenDoiTuon": "VH5 24",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Thịnh Quang",
        "tuDien": "VH5",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 5",
        "diaChiLapD": "",
        "longitude": 105.8174375152603,
        "latitude": 21.00866400985537,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3125,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81730966805165,
          21.00876957145476
        ]
      },
      "properties": {
        "tenDoiTuon": "VH5 25",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Thịnh Quang",
        "tuDien": "VH5",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 5",
        "diaChiLapD": "",
        "longitude": 105.81730966805165,
        "latitude": 21.00876957145476,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3126,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8174382497844,
          21.008886711392886
        ]
      },
      "properties": {
        "tenDoiTuon": "VH5 25-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 224 Thịnh Quang",
        "tuDien": "VH5",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 5",
        "diaChiLapD": "",
        "longitude": 105.8174382497844,
        "latitude": 21.008886711392886,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3127,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81712459044496,
          21.008917356066668
        ]
      },
      "properties": {
        "tenDoiTuon": "VH5 26",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Thịnh Quang",
        "tuDien": "VH5",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 5",
        "diaChiLapD": "",
        "longitude": 105.81712459044496,
        "latitude": 21.008917356066668,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3128,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81724792202924,
          21.008551636931113
        ]
      },
      "properties": {
        "tenDoiTuon": "27",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Thịnh Quang",
        "tuDien": "VH5",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 5",
        "diaChiLapD": "",
        "longitude": 105.81724792202924,
        "latitude": 21.008551636931113,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3129,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81701557113333,
          21.0084416577999
        ]
      },
      "properties": {
        "tenDoiTuon": "28",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Thịnh Quang",
        "tuDien": "VH5",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 5",
        "diaChiLapD": "",
        "longitude": 105.81701557113333,
        "latitude": 21.0084416577999,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3130,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81689086302302,
          21.008626012450932
        ]
      },
      "properties": {
        "tenDoiTuon": "VH5 30",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Thịnh Quang",
        "tuDien": "VH5",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 5",
        "diaChiLapD": "",
        "longitude": 105.81689086302302,
        "latitude": 21.008626012450932,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3131,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81676251170398,
          21.008355145112095
        ]
      },
      "properties": {
        "tenDoiTuon": "VH5 29",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Thịnh Quang",
        "tuDien": "VH5",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 5",
        "diaChiLapD": "",
        "longitude": 105.81676251170398,
        "latitude": 21.008355145112095,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3132,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.817144707012,
          21.00826633851261
        ]
      },
      "properties": {
        "tenDoiTuon": "31",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Thịnh Quang",
        "tuDien": "VH5",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 5",
        "diaChiLapD": "",
        "longitude": 105.817144707012,
        "latitude": 21.00826633851261,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3133,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81690639938904,
          21.008194854976075
        ]
      },
      "properties": {
        "tenDoiTuon": "VH5 32",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Thịnh Quang",
        "tuDien": "VH5",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 5",
        "diaChiLapD": "",
        "longitude": 105.81690639938904,
        "latitude": 21.008194854976075,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3134,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83231695757837,
          21.01880965636855
        ]
      },
      "properties": {
        "tenDoiTuon": "7-9",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 241 Khâm Thiên",
        "tuDien": "KT3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 3",
        "diaChiLapD": "",
        "longitude": 105.83231695757837,
        "latitude": 21.01880965636855,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3135,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83219894038383,
          21.01871576600877
        ]
      },
      "properties": {
        "tenDoiTuon": "7-10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 241 Khâm Thiên",
        "tuDien": "KT3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 3",
        "diaChiLapD": "",
        "longitude": 105.83219894038383,
        "latitude": 21.01871576600877,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3136,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83221503363927,
          21.018549266958676
        ]
      },
      "properties": {
        "tenDoiTuon": "7-11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 241 Khâm Thiên",
        "tuDien": "KT3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 3",
        "diaChiLapD": "",
        "longitude": 105.83221503363927,
        "latitude": 21.018549266958676,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3137,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83227001892492,
          21.018476658291316
        ]
      },
      "properties": {
        "tenDoiTuon": "7-12",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 241 Khâm Thiên",
        "tuDien": "KT3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 3",
        "diaChiLapD": "",
        "longitude": 105.83227001892492,
        "latitude": 21.018476658291316,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3138,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82000940588712,
          21.00758127821229
        ]
      },
      "properties": {
        "tenDoiTuon": "4-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "C1 Tập Thể Vĩnh Hồ",
        "tuDien": "VH5",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 5",
        "diaChiLapD": "",
        "longitude": 105.82000940588712,
        "latitude": 21.00758127821229,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3139,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82016285074624,
          21.00769754721982
        ]
      },
      "properties": {
        "tenDoiTuon": "4-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "C1 Tập Thể Vĩnh Hồ",
        "tuDien": "VH5",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 5",
        "diaChiLapD": "",
        "longitude": 105.82016285074624,
        "latitude": 21.00769754721982,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3140,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82027888180197,
          21.007790236355714
        ]
      },
      "properties": {
        "tenDoiTuon": "VH5 4-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "C1 Tập Thể Vĩnh Hồ",
        "tuDien": "VH5",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 5",
        "diaChiLapD": "",
        "longitude": 105.82027888180197,
        "latitude": 21.007790236355714,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3141,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82064974302676,
          21.006814236832504
        ]
      },
      "properties": {
        "tenDoiTuon": "8-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 35 Thái Thịnh",
        "tuDien": "VH5",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 5",
        "diaChiLapD": "",
        "longitude": 105.82064974302676,
        "latitude": 21.006814236832504,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3142,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82076725036106,
          21.006905291296015
        ]
      },
      "properties": {
        "tenDoiTuon": "8-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 35 Thái Thịnh",
        "tuDien": "VH5",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 5",
        "diaChiLapD": "",
        "longitude": 105.82076725036106,
        "latitude": 21.006905291296015,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3143,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82095729207214,
          21.007059114311836
        ]
      },
      "properties": {
        "tenDoiTuon": "VH1 8-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 35 Thái Thịnh",
        "tuDien": "VH5",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 7,
        "tuDien2": "Vĩnh Hồ 5",
        "diaChiLapD": "",
        "longitude": 105.82095729207214,
        "latitude": 21.007059114311836,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3144,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83254326897112,
          21.01056241468797
        ]
      },
      "properties": {
        "tenDoiTuon": "20",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 4D Đặng Văn Ngữ",
        "tuDien": "TTCT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 8,
        "tuDien2": "Trung Tự Cao Tầng",
        "diaChiLapD": "",
        "longitude": 105.83254326897112,
        "latitude": 21.01056241468797,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3145,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8324252517766,
          21.01047227495936
        ]
      },
      "properties": {
        "tenDoiTuon": "21",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 4D Đặng Văn Ngữ",
        "tuDien": "TTCT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 8,
        "tuDien2": "Trung Tự Cao Tầng",
        "diaChiLapD": "",
        "longitude": 105.8324252517766,
        "latitude": 21.01047227495936,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3146,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8322428615693,
          21.010289491453953
        ]
      },
      "properties": {
        "tenDoiTuon": "TTCT 22",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 4D Đặng Văn Ngữ",
        "tuDien": "TTCT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 8,
        "tuDien2": "Trung Tự Cao Tầng",
        "diaChiLapD": "",
        "longitude": 105.8322428615693,
        "latitude": 21.010289491453953,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3147,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84100262089073,
          21.007360856012664
        ]
      },
      "properties": {
        "tenDoiTuon": "04",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Xã Đàn",
        "tuDien": "NKLT5",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 8,
        "tuDien2": "Nút Giao Thông Kim Liên Tủ 5",
        "diaChiLapD": "",
        "longitude": 105.84100262089073,
        "latitude": 21.007360856012664,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3148,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.841123320302,
          21.007225643497364
        ]
      },
      "properties": {
        "tenDoiTuon": "03",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Xã Đàn",
        "tuDien": "NKLT5",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 8,
        "tuDien2": "Nút Giao Thông Kim Liên Tủ 5",
        "diaChiLapD": "",
        "longitude": 105.841123320302,
        "latitude": 21.007225643497364,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3149,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84117428226706,
          21.007180572632254
        ]
      },
      "properties": {
        "tenDoiTuon": "02",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Xã Đàn",
        "tuDien": "NKLT5",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 8,
        "tuDien2": "Nút Giao Thông Kim Liên Tủ 5",
        "diaChiLapD": "",
        "longitude": 105.84117428226706,
        "latitude": 21.007180572632254,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3150,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84112231443144,
          21.007463830277345
        ]
      },
      "properties": {
        "tenDoiTuon": "01",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Đường phố - đèn pha",
        "tenDuongPh": "Xã Đàn",
        "tuDien": "NKLT5",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 25,
        "tuDien2": "Nút Giao Thông Kim Liên Tủ 5",
        "diaChiLapD": "",
        "longitude": 105.84112231443144,
        "latitude": 21.007463830277345,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3151,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84048126643076,
          21.00794208064502
        ]
      },
      "properties": {
        "tenDoiTuon": "25",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Xã Đàn",
        "tuDien": "NKLT5",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 8,
        "tuDien2": "Nút Giao Thông Kim Liên Tủ 5",
        "diaChiLapD": "",
        "longitude": 105.84048126643076,
        "latitude": 21.00794208064502,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3152,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84017013018895,
          21.0080622689769
        ]
      },
      "properties": {
        "tenDoiTuon": "24",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Xã Đàn",
        "tuDien": "NKLT5",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 8,
        "tuDien2": "Nút Giao Thông Kim Liên Tủ 5",
        "diaChiLapD": "",
        "longitude": 105.84017013018895,
        "latitude": 21.0080622689769,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3153,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8398750871936,
          21.008212504254573
        ]
      },
      "properties": {
        "tenDoiTuon": "23",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Xã Đàn",
        "tuDien": "NKLT5",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 8,
        "tuDien2": "Nút Giao Thông Kim Liên Tủ 5",
        "diaChiLapD": "",
        "longitude": 105.8398750871936,
        "latitude": 21.008212504254573,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3154,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83955858652733,
          21.008367747216028
        ]
      },
      "properties": {
        "tenDoiTuon": "22",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Xã Đàn",
        "tuDien": "NKLT5",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 8,
        "tuDien2": "Nút Giao Thông Kim Liên Tủ 5",
        "diaChiLapD": "",
        "longitude": 105.83955858652733,
        "latitude": 21.008367747216028,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3155,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83930109446732,
          21.008573068303733
        ]
      },
      "properties": {
        "tenDoiTuon": "21",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Xã Đàn",
        "tuDien": "NKLT5",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 8,
        "tuDien2": "Nút Giao Thông Kim Liên Tủ 5",
        "diaChiLapD": "",
        "longitude": 105.83930109446732,
        "latitude": 21.008573068303733,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3156,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83904360239832,
          21.00878840475096
        ]
      },
      "properties": {
        "tenDoiTuon": "20",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Xã Đàn",
        "tuDien": "NKLT5",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 8,
        "tuDien2": "Nút Giao Thông Kim Liên Tủ 5",
        "diaChiLapD": "",
        "longitude": 105.83904360239832,
        "latitude": 21.00878840475096,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3157,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83878611032932,
          21.00900374088654
        ]
      },
      "properties": {
        "tenDoiTuon": "19",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Xã Đàn",
        "tuDien": "NKLT5",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 8,
        "tuDien2": "Nút Giao Thông Kim Liên Tủ 5",
        "diaChiLapD": "",
        "longitude": 105.83878611032932,
        "latitude": 21.00900374088654,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3158,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83852861826932,
          21.009249123546812
        ]
      },
      "properties": {
        "tenDoiTuon": "18",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Xã Đàn",
        "tuDien": "NKLT5",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 8,
        "tuDien2": "Nút Giao Thông Kim Liên Tủ 5",
        "diaChiLapD": "",
        "longitude": 105.83852861826932,
        "latitude": 21.009249123546812,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3159,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83830867712666,
          21.009489498006232
        ]
      },
      "properties": {
        "tenDoiTuon": "17",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Xã Đàn",
        "tuDien": "NKLT5",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 8,
        "tuDien2": "Nút Giao Thông Kim Liên Tủ 5",
        "diaChiLapD": "",
        "longitude": 105.83830867712666,
        "latitude": 21.009489498006232,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3160,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83806191389762,
          21.00972987207834
        ]
      },
      "properties": {
        "tenDoiTuon": "16",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Xã Đàn",
        "tuDien": "NKLT5",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 8,
        "tuDien2": "Nút Giao Thông Kim Liên Tủ 5",
        "diaChiLapD": "",
        "longitude": 105.83806191389762,
        "latitude": 21.00972987207834,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3161,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83779905741315,
          21.01003033912385
        ]
      },
      "properties": {
        "tenDoiTuon": "15",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Xã Đàn",
        "tuDien": "NKLT5",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 8,
        "tuDien2": "Nút Giao Thông Kim Liên Tủ 5",
        "diaChiLapD": "",
        "longitude": 105.83779905741315,
        "latitude": 21.01003033912385,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3162,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83762203162132,
          21.010305766717405
        ]
      },
      "properties": {
        "tenDoiTuon": "14",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Xã Đàn",
        "tuDien": "NKLT5",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 8,
        "tuDien2": "Nút Giao Thông Kim Liên Tủ 5",
        "diaChiLapD": "",
        "longitude": 105.83762203162132,
        "latitude": 21.010305766717405,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3163,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83743964140503,
          21.01057618604168
        ]
      },
      "properties": {
        "tenDoiTuon": "13",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Xã Đàn",
        "tuDien": "NKLT5",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 8,
        "tuDien2": "Nút Giao Thông Kim Liên Tủ 5",
        "diaChiLapD": "",
        "longitude": 105.83743964140503,
        "latitude": 21.01057618604168,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3164,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83726261561323,
          21.01082657386809
        ]
      },
      "properties": {
        "tenDoiTuon": "12",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Xã Đàn",
        "tuDien": "NKLT5",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 8,
        "tuDien2": "Nút Giao Thông Kim Liên Tủ 5",
        "diaChiLapD": "",
        "longitude": 105.83726261561323,
        "latitude": 21.01082657386809,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3165,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84071864195066,
          21.007638479479045
        ]
      },
      "properties": {
        "tenDoiTuon": "05",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Xã Đàn",
        "tuDien": "NKLT5",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 8,
        "tuDien2": "Nút Giao Thông Kim Liên Tủ 5",
        "diaChiLapD": "",
        "longitude": 105.84071864195066,
        "latitude": 21.007638479479045,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3166,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84113170216752,
          21.008021580223147
        ]
      },
      "properties": {
        "tenDoiTuon": "01",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - đèn pha",
        "tenDuongPh": "Xã Đàn",
        "tuDien": "NKLT4",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 8,
        "tuDien2": "Nút Kim Liên Tủ 4",
        "diaChiLapD": "",
        "longitude": 105.84113170216752,
        "latitude": 21.008021580223147,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3167,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84098954506737,
          21.008154288096506
        ]
      },
      "properties": {
        "tenDoiTuon": "03",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Xã Đàn",
        "tuDien": "NKLT4",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 8,
        "tuDien2": "Nút Kim Liên Tủ 4",
        "diaChiLapD": "",
        "longitude": 105.84098954506737,
        "latitude": 21.008154288096506,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3168,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84111560889411,
          21.008261956660352
        ]
      },
      "properties": {
        "tenDoiTuon": "02",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Xã Đàn",
        "tuDien": "NKLT5",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 8,
        "tuDien2": "Nút Giao Thông Kim Liên Tủ 5",
        "diaChiLapD": "",
        "longitude": 105.84111560889411,
        "latitude": 21.008261956660352,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3169,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8407079131287,
          21.00809669790179
        ]
      },
      "properties": {
        "tenDoiTuon": "04",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Xã Đàn",
        "tuDien": "NKLT4",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 8,
        "tuDien2": "Nút Kim Liên Tủ 4",
        "diaChiLapD": "",
        "longitude": 105.8407079131287,
        "latitude": 21.00809669790179,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3170,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84041823454882,
          21.00820436650804
        ]
      },
      "properties": {
        "tenDoiTuon": "05",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Xã Đàn",
        "tuDien": "NKLT4",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 8,
        "tuDien2": "Nút Kim Liên Tủ 4",
        "diaChiLapD": "",
        "longitude": 105.84041823454882,
        "latitude": 21.00820436650804,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3171,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84012855597793,
          21.008317042873372
        ]
      },
      "properties": {
        "tenDoiTuon": "06",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Xã Đàn",
        "tuDien": "NKLT4",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 8,
        "tuDien2": "Nút Kim Liên Tủ 4",
        "diaChiLapD": "",
        "longitude": 105.84012855597793,
        "latitude": 21.008317042873372,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3172,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83984424182252,
          21.00847228572521
        ]
      },
      "properties": {
        "tenDoiTuon": "07",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Xã Đàn",
        "tuDien": "NKLT4",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 8,
        "tuDien2": "Nút Kim Liên Tủ 4",
        "diaChiLapD": "",
        "longitude": 105.83984424182252,
        "latitude": 21.00847228572521,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3173,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8395706564981,
          21.00863754407026
        ]
      },
      "properties": {
        "tenDoiTuon": "08",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Xã Đàn",
        "tuDien": "NKLT4",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 8,
        "tuDien2": "Nút Kim Liên Tủ 4",
        "diaChiLapD": "",
        "longitude": 105.8395706564981,
        "latitude": 21.00863754407026,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3174,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8404235989643,
          21.007728620919845
        ]
      },
      "properties": {
        "tenDoiTuon": "06",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Xã Đàn",
        "tuDien": "NKLT5",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 8,
        "tuDien2": "Nút Giao Thông Kim Liên Tủ 5",
        "diaChiLapD": "",
        "longitude": 105.8404235989643,
        "latitude": 21.007728620919845,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3175,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8400963694581,
          21.00787384868199
        ]
      },
      "properties": {
        "tenDoiTuon": "07",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Xã Đàn",
        "tuDien": "NKLT5",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 8,
        "tuDien2": "Nút Giao Thông Kim Liên Tủ 5",
        "diaChiLapD": "",
        "longitude": 105.8400963694581,
        "latitude": 21.00787384868199,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3176,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83979327983951,
          21.007994037068002
        ]
      },
      "properties": {
        "tenDoiTuon": "08",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Xã Đàn",
        "tuDien": "NKLT5",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 8,
        "tuDien2": "Nút Giao Thông Kim Liên Tủ 5",
        "diaChiLapD": "",
        "longitude": 105.83979327983951,
        "latitude": 21.007994037068002,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3177,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83953310557176,
          21.008134256729036
        ]
      },
      "properties": {
        "tenDoiTuon": "09",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Xã Đàn",
        "tuDien": "NKLT5",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 8,
        "tuDien2": "Nút Giao Thông Kim Liên Tủ 5",
        "diaChiLapD": "",
        "longitude": 105.83953310557176,
        "latitude": 21.008134256729036,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3178,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83926488467179,
          21.00829701152774
        ]
      },
      "properties": {
        "tenDoiTuon": "10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Xã Đàn",
        "tuDien": "NKLT5",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 8,
        "tuDien2": "Nút Giao Thông Kim Liên Tủ 5",
        "diaChiLapD": "",
        "longitude": 105.83926488467179,
        "latitude": 21.00829701152774,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3179,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83906908340781,
          21.008532379692635
        ]
      },
      "properties": {
        "tenDoiTuon": "11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Xã Đàn",
        "tuDien": "NKLT5",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 8,
        "tuDien2": "Nút Giao Thông Kim Liên Tủ 5",
        "diaChiLapD": "",
        "longitude": 105.83906908340781,
        "latitude": 21.008532379692635,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3180,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83083906041183,
          21.020265575319144
        ]
      },
      "properties": {
        "tenDoiTuon": "05",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Tôn Đức Thắng",
        "tuDien": "KTO",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên Ô",
        "diaChiLapD": "",
        "longitude": 105.83083906041183,
        "latitude": 21.020265575319144,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3181,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83095439539865,
          21.020513443165598
        ]
      },
      "properties": {
        "tenDoiTuon": "6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Tôn Đức Thắng",
        "tuDien": "KTO",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên Ô",
        "diaChiLapD": "",
        "longitude": 105.83095439539865,
        "latitude": 21.020513443165598,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3182,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83162997681278,
          21.02195213261049
        ]
      },
      "properties": {
        "tenDoiTuon": "12",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Tôn Đức Thắng",
        "tuDien": "KTO",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên Ô",
        "diaChiLapD": "",
        "longitude": 105.83162997681278,
        "latitude": 21.02195213261049,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3183,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83588207001075,
          21.01755693458324
        ]
      },
      "properties": {
        "tenDoiTuon": "40",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Lệnh Cư",
        "tuDien": "KTH1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 1",
        "diaChiLapD": "",
        "longitude": 105.83588207001075,
        "latitude": 21.01755693458324,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3184,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83566133695344,
          21.017593145692
        ]
      },
      "properties": {
        "tenDoiTuon": "40-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Lệnh Cư",
        "tuDien": "KTH1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 1",
        "diaChiLapD": "",
        "longitude": 105.83566133695344,
        "latitude": 21.017593145692,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3185,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83550040441705,
          21.017528047850885
        ]
      },
      "properties": {
        "tenDoiTuon": "40-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Lệnh Cư",
        "tuDien": "KTH1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 1",
        "diaChiLapD": "",
        "longitude": 105.83550040441705,
        "latitude": 21.017528047850885,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3186,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83585425368562,
          21.017348910990044
        ]
      },
      "properties": {
        "tenDoiTuon": "41",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Lệnh Cư",
        "tuDien": "KTH1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 1",
        "diaChiLapD": "",
        "longitude": 105.83585425368562,
        "latitude": 21.017348910990044,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3187,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83596979098749,
          21.017187535604798
        ]
      },
      "properties": {
        "tenDoiTuon": "42",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Lệnh Cư",
        "tuDien": "KTH1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 1",
        "diaChiLapD": "",
        "longitude": 105.83596979098749,
        "latitude": 21.017187535604798,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3188,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8358356805375,
          21.01691963204038
        ]
      },
      "properties": {
        "tenDoiTuon": "43",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Lệnh Cư",
        "tuDien": "KTH1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 1",
        "diaChiLapD": "",
        "longitude": 105.8358356805375,
        "latitude": 21.01691963204038,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3189,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83574180322162,
          21.016751879096276
        ]
      },
      "properties": {
        "tenDoiTuon": "44",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Lệnh Cư",
        "tuDien": "KTH1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 1",
        "diaChiLapD": "",
        "longitude": 105.83574180322162,
        "latitude": 21.016751879096276,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3190,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83136175591281,
          21.021363764446583
        ]
      },
      "properties": {
        "tenDoiTuon": "10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Tôn Đức Thắng",
        "tuDien": "KTO",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên Ô",
        "diaChiLapD": "",
        "longitude": 105.83136175591281,
        "latitude": 21.021363764446583,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3191,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83148379642796,
          21.021646682193825
        ]
      },
      "properties": {
        "tenDoiTuon": "11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Tôn Đức Thắng",
        "tuDien": "KTO",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên Ô",
        "diaChiLapD": "",
        "longitude": 105.83148379642796,
        "latitude": 21.021646682193825,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3192,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83123569209504,
          21.021102127644955
        ]
      },
      "properties": {
        "tenDoiTuon": "9",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Tôn Đức Thắng",
        "tuDien": "KTO",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên Ô",
        "diaChiLapD": "",
        "longitude": 105.83123569209504,
        "latitude": 21.021102127644955,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3193,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8311310859392,
          21.020873038584867
        ]
      },
      "properties": {
        "tenDoiTuon": "7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Tôn Đức Thắng",
        "tuDien": "KTO",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên Ô",
        "diaChiLapD": "",
        "longitude": 105.8311310859392,
        "latitude": 21.020873038584867,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3194,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83073009569313,
          21.020034294214575
        ]
      },
      "properties": {
        "tenDoiTuon": "4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Tôn Đức Thắng",
        "tuDien": "KTO",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên Ô",
        "diaChiLapD": "",
        "longitude": 105.83073009569313,
        "latitude": 21.020034294214575,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3195,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81461739558584,
          21.008678858617852
        ]
      },
      "properties": {
        "tenDoiTuon": "",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Đường phố - đèn pha",
        "tenDuongPh": "Láng",
        "tuDien": "TQ2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 25,
        "tuDien2": "Thịnh Quang 2",
        "diaChiLapD": "",
        "longitude": 105.81461739558584,
        "latitude": 21.008678858617852,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3196,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81473241306746,
          21.008550012363866
        ]
      },
      "properties": {
        "tenDoiTuon": "TQ2 01",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "TQ2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "Thịnh Quang 2",
        "diaChiLapD": "",
        "longitude": 105.81473241306746,
        "latitude": 21.008550012363866,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3197,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81493902294825,
          21.008317761422425
        ]
      },
      "properties": {
        "tenDoiTuon": "02",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "TQ2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "Thịnh Quang 2",
        "diaChiLapD": "",
        "longitude": 105.81493902294825,
        "latitude": 21.008317761422425,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3198,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81510728467941,
          21.00812124232172
        ]
      },
      "properties": {
        "tenDoiTuon": "03",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "TQ2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 10,
        "tuDien2": "Thịnh Quang 2",
        "diaChiLapD": "",
        "longitude": 105.81510728467941,
        "latitude": 21.00812124232172,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3199,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81522737718767,
          21.00797471926834
        ]
      },
      "properties": {
        "tenDoiTuon": "04",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "TQ2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 10,
        "tuDien2": "Thịnh Quang 2",
        "diaChiLapD": "",
        "longitude": 105.81522737718767,
        "latitude": 21.00797471926834,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3200,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81539946848582,
          21.00775976704494
        ]
      },
      "properties": {
        "tenDoiTuon": "05",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "TQ2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 10,
        "tuDien2": "Thịnh Quang 2",
        "diaChiLapD": "",
        "longitude": 105.81539946848582,
        "latitude": 21.00775976704494,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3201,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.815536654266,
          21.00758330408431
        ]
      },
      "properties": {
        "tenDoiTuon": "06",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "TQ2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 10,
        "tuDien2": "Thịnh Quang 2",
        "diaChiLapD": "",
        "longitude": 105.815536654266,
        "latitude": 21.00758330408431,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3202,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81566286760506,
          21.007414986701807
        ]
      },
      "properties": {
        "tenDoiTuon": "07",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "TQ2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 10,
        "tuDien2": "Thịnh Quang 2",
        "diaChiLapD": "",
        "longitude": 105.81566286760506,
        "latitude": 21.007414986701807,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3203,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81580133047395,
          21.00722908394661
        ]
      },
      "properties": {
        "tenDoiTuon": "08",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "TQ2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 10,
        "tuDien2": "Thịnh Quang 2",
        "diaChiLapD": "",
        "longitude": 105.81580133047395,
        "latitude": 21.00722908394661,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3204,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81594189759737,
          21.00703436314667
        ]
      },
      "properties": {
        "tenDoiTuon": "09",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "TQ2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 10,
        "tuDien2": "Thịnh Quang 2",
        "diaChiLapD": "",
        "longitude": 105.81594189759737,
        "latitude": 21.00703436314667,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3205,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81608201644238,
          21.00683936072421
        ]
      },
      "properties": {
        "tenDoiTuon": "10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "TQ2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 10,
        "tuDien2": "Thịnh Quang 2",
        "diaChiLapD": "",
        "longitude": 105.81608201644238,
        "latitude": 21.00683936072421,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3206,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81621482063635,
          21.006649893431053
        ]
      },
      "properties": {
        "tenDoiTuon": "11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "TQ2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 10,
        "tuDien2": "Thịnh Quang 2",
        "diaChiLapD": "",
        "longitude": 105.81621482063635,
        "latitude": 21.006649893431053,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3207,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81634826016048,
          21.006450849402636
        ]
      },
      "properties": {
        "tenDoiTuon": "12",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "TQ2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 10,
        "tuDien2": "Thịnh Quang 2",
        "diaChiLapD": "",
        "longitude": 105.81634826016048,
        "latitude": 21.006450849402636,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3208,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81648002728431,
          21.006260751630208
        ]
      },
      "properties": {
        "tenDoiTuon": "13",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "TQ2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 10,
        "tuDien2": "Thịnh Quang 2",
        "diaChiLapD": "",
        "longitude": 105.81648002728431,
        "latitude": 21.006260751630208,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3209,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81661832350458,
          21.00604722367959
        ]
      },
      "properties": {
        "tenDoiTuon": "14",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "TQ2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 10,
        "tuDien2": "Thịnh Quang 2",
        "diaChiLapD": "",
        "longitude": 105.81661832350458,
        "latitude": 21.00604722367959,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3210,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81677120941777,
          21.005830631295897
        ]
      },
      "properties": {
        "tenDoiTuon": "15",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "TQ2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 10,
        "tuDien2": "Thịnh Quang 2",
        "diaChiLapD": "",
        "longitude": 105.81677120941777,
        "latitude": 21.005830631295897,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3211,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81686871532327,
          21.00568600164012
        ]
      },
      "properties": {
        "tenDoiTuon": "16",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "TQ2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 10,
        "tuDien2": "Thịnh Quang 2",
        "diaChiLapD": "",
        "longitude": 105.81686871532327,
        "latitude": 21.00568600164012,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3212,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81700258031465,
          21.005497184595523
        ]
      },
      "properties": {
        "tenDoiTuon": "17",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "TQ2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 10,
        "tuDien2": "Thịnh Quang 2",
        "diaChiLapD": "",
        "longitude": 105.81700258031465,
        "latitude": 21.005497184595523,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3213,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81714940088006,
          21.005301042814576
        ]
      },
      "properties": {
        "tenDoiTuon": "18",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "TQ2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 10,
        "tuDien2": "Thịnh Quang 2",
        "diaChiLapD": "",
        "longitude": 105.81714940088006,
        "latitude": 21.005301042814576,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3214,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81729002730722,
          21.00508797705573
        ]
      },
      "properties": {
        "tenDoiTuon": "TQ2 19",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "TQ2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 10,
        "tuDien2": "Thịnh Quang 2",
        "diaChiLapD": "",
        "longitude": 105.81729002730722,
        "latitude": 21.00508797705573,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3215,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83060269076249,
          21.019771403217682
        ]
      },
      "properties": {
        "tenDoiTuon": "3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Tôn Đức Thắng",
        "tuDien": "KTO",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên Ô",
        "diaChiLapD": "",
        "longitude": 105.83060269076249,
        "latitude": 21.019771403217682,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3216,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83042164165906,
          21.01939959944356
        ]
      },
      "properties": {
        "tenDoiTuon": "2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Tôn Đức Thắng",
        "tuDien": "KTO",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên Ô",
        "diaChiLapD": "",
        "longitude": 105.83042164165906,
        "latitude": 21.01939959944356,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3217,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83037470299662,
          21.019194293258987
        ]
      },
      "properties": {
        "tenDoiTuon": "02",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Tôn Đức Thắng",
        "tuDien": "KTO",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên Ô",
        "diaChiLapD": "",
        "longitude": 105.83037470299662,
        "latitude": 21.019194293258987,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3218,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83043639380165,
          21.019129196116836
        ]
      },
      "properties": {
        "tenDoiTuon": "01",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Đường phố - đèn pha",
        "tenDuongPh": "Khâm Thiên",
        "tuDien": "KTO",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Kham Thien O",
        "diaChiLapD": "",
        "longitude": 105.83043639380165,
        "latitude": 21.019129196116836,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3219,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83174397069571,
          21.022196242124505
        ]
      },
      "properties": {
        "tenDoiTuon": "13",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Tôn Đức Thắng",
        "tuDien": "KTO",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên Ô",
        "diaChiLapD": "",
        "longitude": 105.83174397069571,
        "latitude": 21.022196242124505,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3220,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8352429123211,
          21.017775920248774
        ]
      },
      "properties": {
        "tenDoiTuon": "49",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Lệnh Cư",
        "tuDien": "KTH1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 1",
        "diaChiLapD": "",
        "longitude": 105.8352429123211,
        "latitude": 21.017775920248774,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3221,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83514635279748,
          21.01769830368403
        ]
      },
      "properties": {
        "tenDoiTuon": "50",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Lệnh Cư",
        "tuDien": "KTH1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 1",
        "diaChiLapD": "",
        "longitude": 105.83514635279748,
        "latitude": 21.01769830368403,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3222,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83526897395579,
          21.017666547335967
        ]
      },
      "properties": {
        "tenDoiTuon": "51",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Lệnh Cư",
        "tuDien": "KTH1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 1",
        "diaChiLapD": "",
        "longitude": 105.83526897395579,
        "latitude": 21.017666547335967,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3223,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.835140988382,
          21.017412874678985
        ]
      },
      "properties": {
        "tenDoiTuon": "52",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Lệnh Cư",
        "tuDien": "KTH1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 1",
        "diaChiLapD": "",
        "longitude": 105.835140988382,
        "latitude": 21.017412874678985,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3224,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83497561603811,
          21.017842023236682
        ]
      },
      "properties": {
        "tenDoiTuon": "50-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Lệnh Cư",
        "tuDien": "KTH1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 1",
        "diaChiLapD": "",
        "longitude": 105.83497561603811,
        "latitude": 21.017842023236682,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3225,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83481107666802,
          21.017923641985558
        ]
      },
      "properties": {
        "tenDoiTuon": "50-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Ngõ Lệnh Cư",
        "tuDien": "KTH1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 1",
        "diaChiLapD": "",
        "longitude": 105.83481107666802,
        "latitude": 21.017923641985558,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3226,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83473329260758,
          21.01776340144998
        ]
      },
      "properties": {
        "tenDoiTuon": "50-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Lệnh Cư",
        "tuDien": "KTH1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên 1",
        "diaChiLapD": "",
        "longitude": 105.83473329260758,
        "latitude": 21.01776340144998,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3227,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81547631399842,
          21.007882965654364
        ]
      },
      "properties": {
        "tenDoiTuon": "5-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 318 Đường Láng",
        "tuDien": "TQ2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 7,
        "tuDien2": "Thịnh Quang 2",
        "diaChiLapD": "",
        "longitude": 105.81547631399842,
        "latitude": 21.007882965654364,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3228,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8305304024692,
          21.018934027141757
        ]
      },
      "properties": {
        "tenDoiTuon": "14",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Đê La Thành",
        "tuDien": "KTO",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên Ô",
        "diaChiLapD": "",
        "longitude": 105.8305304024692,
        "latitude": 21.018934027141757,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3229,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83028498034402,
          21.01878505453441
        ]
      },
      "properties": {
        "tenDoiTuon": "15",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Đê La Thành",
        "tuDien": "KTO",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên Ô",
        "diaChiLapD": "",
        "longitude": 105.83028498034402,
        "latitude": 21.01878505453441,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3230,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83041372636954,
          21.01856910658445
        ]
      },
      "properties": {
        "tenDoiTuon": "17",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Đê La Thành",
        "tuDien": "KTO",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Kham Thien O",
        "diaChiLapD": "",
        "longitude": 105.83041372636954,
        "latitude": 21.01856910658445,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3231,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83110900237608,
          21.018021288485496
        ]
      },
      "properties": {
        "tenDoiTuon": "19",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Đê La Thành",
        "tuDien": "KTO",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Kham Thien O",
        "diaChiLapD": "",
        "longitude": 105.83110900237608,
        "latitude": 21.018021288485496,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3232,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81583780064742,
          21.007519542764292
        ]
      },
      "properties": {
        "tenDoiTuon": "7-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 302 Đường Láng",
        "tuDien": "TQ2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Thịnh Quang 2",
        "diaChiLapD": "",
        "longitude": 105.81583780064742,
        "latitude": 21.007519542764292,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3233,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81595179453036,
          21.00759841659116
        ]
      },
      "properties": {
        "tenDoiTuon": "7-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 302 Đường Láng",
        "tuDien": "TQ2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Thịnh Quang 2",
        "diaChiLapD": "",
        "longitude": 105.81595179453036,
        "latitude": 21.00759841659116,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3234,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81611750573889,
          21.00737055175817
        ]
      },
      "properties": {
        "tenDoiTuon": "7-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 302 Đường Láng",
        "tuDien": "TQ2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Thịnh Quang 2",
        "diaChiLapD": "",
        "longitude": 105.81611750573889,
        "latitude": 21.00737055175817,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3235,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8162715358456,
          21.007229726505443
        ]
      },
      "properties": {
        "tenDoiTuon": "7-3-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 302 Đường Láng",
        "tuDien": "TQ2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "Thịnh Quang 2",
        "diaChiLapD": "",
        "longitude": 105.8162715358456,
        "latitude": 21.007229726505443,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3236,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81622269764705,
          21.007643487330043
        ]
      },
      "properties": {
        "tenDoiTuon": "7-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 302 Đường Láng",
        "tuDien": "TQ2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Thịnh Quang 2",
        "diaChiLapD": "",
        "longitude": 105.81622269764705,
        "latitude": 21.007643487330043,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3237,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81613016143504,
          21.007712345377733
        ]
      },
      "properties": {
        "tenDoiTuon": "7-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 302 Đường Láng",
        "tuDien": "TQ2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Thịnh Quang 2",
        "diaChiLapD": "",
        "longitude": 105.81613016143504,
        "latitude": 21.007712345377733,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3238,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81630155627283,
          21.007531308833396
        ]
      },
      "properties": {
        "tenDoiTuon": "7-4-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 302 Đường Láng",
        "tuDien": "TQ2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "Thịnh Quang 2",
        "diaChiLapD": "",
        "longitude": 105.81630155627283,
        "latitude": 21.007531308833396,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3239,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81637424245633,
          21.00782877800429
        ]
      },
      "properties": {
        "tenDoiTuon": "7-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 302 Đường Láng",
        "tuDien": "TQ2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Thịnh Quang 2",
        "diaChiLapD": "",
        "longitude": 105.81637424245633,
        "latitude": 21.00782877800429,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3240,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81647565162912,
          21.00763489893448
        ]
      },
      "properties": {
        "tenDoiTuon": "7-6-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 302 Đường Láng",
        "tuDien": "TQ2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "Thịnh Quang 2",
        "diaChiLapD": "",
        "longitude": 105.81647565162912,
        "latitude": 21.00763489893448,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3241,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81663039341247,
          21.007890124190528
        ]
      },
      "properties": {
        "tenDoiTuon": "7-7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 302 Đường Láng",
        "tuDien": "TQ2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Thịnh Quang 2",
        "diaChiLapD": "",
        "longitude": 105.81663039341247,
        "latitude": 21.007890124190528,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3242,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81670779445905,
          21.00772555609598
        ]
      },
      "properties": {
        "tenDoiTuon": "7-7-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 302 Đường Láng",
        "tuDien": "TQ2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "Thịnh Quang 2",
        "diaChiLapD": "",
        "longitude": 105.81670779445905,
        "latitude": 21.00772555609598,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3243,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81683155909194,
          21.007901391854283
        ]
      },
      "properties": {
        "tenDoiTuon": "7-8",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 302 Đường Láng",
        "tuDien": "TQ2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Thịnh Quang 2",
        "diaChiLapD": "",
        "longitude": 105.81683155909194,
        "latitude": 21.007901391854283,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3244,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81689459100083,
          21.007804990701292
        ]
      },
      "properties": {
        "tenDoiTuon": "TQ2 7-8-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 302 Đường Láng",
        "tuDien": "TQ2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "Thịnh Quang 2",
        "diaChiLapD": "",
        "longitude": 105.81689459100083,
        "latitude": 21.007804990701292,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3245,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83122970177838,
          21.018088889866885
        ]
      },
      "properties": {
        "tenDoiTuon": "19-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 316 Đê La Thành",
        "tuDien": "KTO",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Kham Thien O",
        "diaChiLapD": "",
        "longitude": 105.83122970177838,
        "latitude": 21.018088889866885,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3246,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8313316257175,
          21.01819029187899
        ]
      },
      "properties": {
        "tenDoiTuon": "19-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 316 Đê La Thành",
        "tuDien": "KTO",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Kham Thien O",
        "diaChiLapD": "",
        "longitude": 105.8313316257175,
        "latitude": 21.01819029187899,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3247,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8314429373927,
          21.01830796820237
        ]
      },
      "properties": {
        "tenDoiTuon": "19-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 316 Đê La Thành",
        "tuDien": "KTO",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Kham Thien O",
        "diaChiLapD": "",
        "longitude": 105.8314429373927,
        "latitude": 21.01830796820237,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3248,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83153815581244,
          21.01840811819083
        ]
      },
      "properties": {
        "tenDoiTuon": "19-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 316 Đê La Thành",
        "tuDien": "KTO",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Kham Thien O",
        "diaChiLapD": "",
        "longitude": 105.83153815581244,
        "latitude": 21.01840811819083,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3249,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83143086745784,
          21.017628198373192
        ]
      },
      "properties": {
        "tenDoiTuon": "20",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Đê La Thành",
        "tuDien": "KTO",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Kham Thien O",
        "diaChiLapD": "",
        "longitude": 105.83143086745784,
        "latitude": 21.017628198373192,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3250,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83164007976056,
          21.017415378453435
        ]
      },
      "properties": {
        "tenDoiTuon": "21-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Đê La Thành",
        "tuDien": "KTO",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Kham Thien O",
        "diaChiLapD": "",
        "longitude": 105.83164007976056,
        "latitude": 21.017415378453435,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3251,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81647379258527,
          21.006457973064563
        ]
      },
      "properties": {
        "tenDoiTuon": "12-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 232 Đường Láng",
        "tuDien": "TQ2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 7,
        "tuDien2": "Thịnh Quang 2",
        "diaChiLapD": "",
        "longitude": 105.81647379258527,
        "latitude": 21.006457973064563,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3252,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8319163472927,
          21.01713495644762
        ]
      },
      "properties": {
        "tenDoiTuon": "22",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Đê La Thành",
        "tuDien": "KTO",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Kham Thien O",
        "diaChiLapD": "",
        "longitude": 105.8319163472927,
        "latitude": 21.01713495644762,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3253,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83291444491445,
          21.01615881942764
        ]
      },
      "properties": {
        "tenDoiTuon": "26",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Đê La Thành",
        "tuDien": "KTO",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Kham Thien O",
        "diaChiLapD": "",
        "longitude": 105.83291444491445,
        "latitude": 21.01615881942764,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3254,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83259794420327,
          21.016480555670856
        ]
      },
      "properties": {
        "tenDoiTuon": "25",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Đê La Thành",
        "tuDien": "KTO",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Kham Thien O",
        "diaChiLapD": "",
        "longitude": 105.83259794420327,
        "latitude": 21.016480555670856,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3255,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8324142128921,
          21.016652064587703
        ]
      },
      "properties": {
        "tenDoiTuon": "24",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Đê La Thành",
        "tuDien": "KTO",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Kham Thien O",
        "diaChiLapD": "",
        "longitude": 105.8324142128921,
        "latitude": 21.016652064587703,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3256,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81651774064241,
          21.006712022614206
        ]
      },
      "properties": {
        "tenDoiTuon": "11-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 242 Đường Láng",
        "tuDien": "TQ2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Thịnh Quang 2",
        "diaChiLapD": "",
        "longitude": 105.81651774064241,
        "latitude": 21.006712022614206,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3257,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81674706951213,
          21.00688479473423
        ]
      },
      "properties": {
        "tenDoiTuon": "11-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 242 Đường Láng",
        "tuDien": "TQ2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Thịnh Quang 2",
        "diaChiLapD": "",
        "longitude": 105.81674706951213,
        "latitude": 21.00688479473423,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3258,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81663575784593,
          21.00698996027399
        ]
      },
      "properties": {
        "tenDoiTuon": "11-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 242 Đường Láng",
        "tuDien": "TQ2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Thịnh Quang 2",
        "diaChiLapD": "",
        "longitude": 105.81663575784593,
        "latitude": 21.00698996027399,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3259,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81680741922226,
          21.007132684817325
        ]
      },
      "properties": {
        "tenDoiTuon": "11-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 242 Đường Láng",
        "tuDien": "TQ2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Thịnh Quang 2",
        "diaChiLapD": "",
        "longitude": 105.81680741922226,
        "latitude": 21.007132684817325,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3260,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81697798318409,
          21.007327467929304
        ]
      },
      "properties": {
        "tenDoiTuon": "11-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 242 Đường Láng",
        "tuDien": "TQ2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Thịnh Quang 2",
        "diaChiLapD": "",
        "longitude": 105.81697798318409,
        "latitude": 21.007327467929304,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3261,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81697052027687,
          21.00708267556627
        ]
      },
      "properties": {
        "tenDoiTuon": "11-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 242 Đường Láng",
        "tuDien": "TQ2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Thịnh Quang 2",
        "diaChiLapD": "",
        "longitude": 105.81697052027687,
        "latitude": 21.00708267556627,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3262,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81707832232996,
          21.007131432848006
        ]
      },
      "properties": {
        "tenDoiTuon": "TQ2 11-7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 242 Đường Láng",
        "tuDien": "TQ2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Thịnh Quang 2",
        "diaChiLapD": "",
        "longitude": 105.81707832232996,
        "latitude": 21.007131432848006,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3263,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81684608485128,
          21.007418856498617
        ]
      },
      "properties": {
        "tenDoiTuon": "11-8",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 242 Đường Láng",
        "tuDien": "TQ2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Thịnh Quang 2",
        "diaChiLapD": "",
        "longitude": 105.81684608485128,
        "latitude": 21.007418856498617,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3264,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8169912317954,
          21.00753134539563
        ]
      },
      "properties": {
        "tenDoiTuon": "TQ2 11-9",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 242 Đường Láng",
        "tuDien": "TQ2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Thịnh Quang 2",
        "diaChiLapD": "",
        "longitude": 105.8169912317954,
        "latitude": 21.00753134539563,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3265,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8169468941057,
          21.005699173338012
        ]
      },
      "properties": {
        "tenDoiTuon": "16-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 210 Đường Láng",
        "tuDien": "TQ2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Thịnh Quang 2",
        "diaChiLapD": "",
        "longitude": 105.8169468941057,
        "latitude": 21.005699173338012,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3266,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81684094685497,
          21.00580058377365
        ]
      },
      "properties": {
        "tenDoiTuon": "15-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 218 Đường Láng",
        "tuDien": "TQ2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 7,
        "tuDien2": "Thịnh Quang 2",
        "diaChiLapD": "",
        "longitude": 105.81684094685497,
        "latitude": 21.00580058377365,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3267,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8170662524131,
          21.0059120099747
        ]
      },
      "properties": {
        "tenDoiTuon": "15-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 218 Đường Láng",
        "tuDien": "TQ2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Thịnh Quang 2",
        "diaChiLapD": "",
        "longitude": 105.8170662524131,
        "latitude": 21.0059120099747,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3268,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81728753465065,
          21.006013420264846
        ]
      },
      "properties": {
        "tenDoiTuon": "15-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 218 Đường Láng",
        "tuDien": "TQ2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Thịnh Quang 2",
        "diaChiLapD": "",
        "longitude": 105.81728753465065,
        "latitude": 21.006013420264846,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3269,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81750345248167,
          21.00614112201386
        ]
      },
      "properties": {
        "tenDoiTuon": "15-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 218 Đường Láng",
        "tuDien": "TQ2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Thịnh Quang 2",
        "diaChiLapD": "",
        "longitude": 105.81750345248167,
        "latitude": 21.00614112201386,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3270,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81772071140757,
          21.00626882365441
        ]
      },
      "properties": {
        "tenDoiTuon": "15-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 218 Đường Láng",
        "tuDien": "TQ2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Thịnh Quang 2",
        "diaChiLapD": "",
        "longitude": 105.81772071140757,
        "latitude": 21.00626882365441,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3271,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8178923727839,
          21.006426572587912
        ]
      },
      "properties": {
        "tenDoiTuon": "15-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 218 Đường Láng",
        "tuDien": "TQ2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Thịnh Quang 2",
        "diaChiLapD": "",
        "longitude": 105.8178923727839,
        "latitude": 21.006426572587912,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3272,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81810426730333,
          21.006546762139738
        ]
      },
      "properties": {
        "tenDoiTuon": "TQ2 15-7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 218 Đường Láng",
        "tuDien": "TQ2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Thịnh Quang 2",
        "diaChiLapD": "",
        "longitude": 105.81810426730333,
        "latitude": 21.006546762139738,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3273,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83215940303086,
          21.01688992334331
        ]
      },
      "properties": {
        "tenDoiTuon": "23",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Đê La Thành",
        "tuDien": "KTO",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Kham Thien O",
        "diaChiLapD": "",
        "longitude": 105.83215940303086,
        "latitude": 21.01688992334331,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3274,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81535614406715,
          21.00803433036558
        ]
      },
      "properties": {
        "tenDoiTuon": "4-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 326 Đường Láng",
        "tuDien": "TQ2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "Thịnh Quang 2",
        "diaChiLapD": "",
        "longitude": 105.81535614406715,
        "latitude": 21.00803433036558,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3275,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81528666207628,
          21.008130696515167
        ]
      },
      "properties": {
        "tenDoiTuon": "3-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 336 Đường Láng",
        "tuDien": "TQ2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "Thịnh Quang 2",
        "diaChiLapD": "",
        "longitude": 105.81528666207628,
        "latitude": 21.008130696515167,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3276,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83159580384833,
          21.02132998812261
        ]
      },
      "properties": {
        "tenDoiTuon": "10-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 221 Tôn Đức Thắng",
        "tuDien": "KTO",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên Ô",
        "diaChiLapD": "",
        "longitude": 105.83159580384833,
        "latitude": 21.02132998812261,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3277,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83175673639371,
          21.02120730678042
        ]
      },
      "properties": {
        "tenDoiTuon": "10-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 221 Tôn Đức Thắng",
        "tuDien": "KTO",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên Ô",
        "diaChiLapD": "",
        "longitude": 105.83175673639371,
        "latitude": 21.02120730678042,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3278,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83194046770488,
          21.021226084543663
        ]
      },
      "properties": {
        "tenDoiTuon": "10-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 221 Tôn Đức Thắng",
        "tuDien": "KTO",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên Ô",
        "diaChiLapD": "",
        "longitude": 105.83194046770488,
        "latitude": 21.021226084543663,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3279,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83217247879134,
          21.02134125477107
        ]
      },
      "properties": {
        "tenDoiTuon": "10-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 221 Tôn Đức Thắng",
        "tuDien": "KTO",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên Ô",
        "diaChiLapD": "",
        "longitude": 105.83217247879134,
        "latitude": 21.02134125477107,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3280,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83174332534601,
          21.021088380893833
        ]
      },
      "properties": {
        "tenDoiTuon": "10-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 221 Tôn Đức Thắng",
        "tuDien": "KTO",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên Ô",
        "diaChiLapD": "",
        "longitude": 105.83174332534601,
        "latitude": 21.021088380893833,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3281,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83166151797394,
          21.020993240115935
        ]
      },
      "properties": {
        "tenDoiTuon": "10-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 221 Tôn Đức Thắng",
        "tuDien": "KTO",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên Ô",
        "diaChiLapD": "",
        "longitude": 105.83166151797394,
        "latitude": 21.020993240115935,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3282,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83236023341412,
          21.021461432305003
        ]
      },
      "properties": {
        "tenDoiTuon": "10-7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 221 Tôn Đức Thắng",
        "tuDien": "KTO",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên Ô",
        "diaChiLapD": "",
        "longitude": 105.83236023341412,
        "latitude": 21.021461432305003,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3283,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83252384816726,
          21.0215277802774
        ]
      },
      "properties": {
        "tenDoiTuon": "10-8",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 221 Tôn Đức Thắng",
        "tuDien": "KTO",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Kham Thien O",
        "diaChiLapD": "",
        "longitude": 105.83252384816726,
        "latitude": 21.0215277802774,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3284,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83220198308548,
          21.02155532093586
        ]
      },
      "properties": {
        "tenDoiTuon": "10-9",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 221 Tôn Đức Thắng",
        "tuDien": "KTO",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên Ô",
        "diaChiLapD": "",
        "longitude": 105.83220198308548,
        "latitude": 21.02155532093586,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3285,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83197935973512,
          21.02170429077628
        ]
      },
      "properties": {
        "tenDoiTuon": "10-10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 221 Tôn Đức Thắng",
        "tuDien": "KTO",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên Ô",
        "diaChiLapD": "",
        "longitude": 105.83197935973512,
        "latitude": 21.02170429077628,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3286,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83232938801613,
          21.021720564783205
        ]
      },
      "properties": {
        "tenDoiTuon": "10-11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 221 Tôn Đức Thắng",
        "tuDien": "KTO",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên Ô",
        "diaChiLapD": "",
        "longitude": 105.83232938801613,
        "latitude": 21.021720564783205,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3287,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83246483956998,
          21.021879549220678
        ]
      },
      "properties": {
        "tenDoiTuon": "10-12",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 221 Tôn Đức Thắng",
        "tuDien": "KTO",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên Ô",
        "diaChiLapD": "",
        "longitude": 105.83246483956998,
        "latitude": 21.021879549220678,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3288,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83219527756614,
          21.022027266891975
        ]
      },
      "properties": {
        "tenDoiTuon": "10-13",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 221 Tôn Đức Thắng",
        "tuDien": "KTO",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên Ô",
        "diaChiLapD": "",
        "longitude": 105.83219527756614,
        "latitude": 21.022027266891975,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3289,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83233207022388,
          21.021217321587873
        ]
      },
      "properties": {
        "tenDoiTuon": "10-14",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 221 Tôn Đức Thắng",
        "tuDien": "KTO",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên Ô",
        "diaChiLapD": "",
        "longitude": 105.83233207022388,
        "latitude": 21.021217321587873,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3290,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83282827888969,
          21.02124611415453
        ]
      },
      "properties": {
        "tenDoiTuon": "10-15",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 221 Tôn Đức Thắng",
        "tuDien": "KTO",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên Ô",
        "diaChiLapD": "",
        "longitude": 105.83282827888969,
        "latitude": 21.02124611415453,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3291,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81537109631168,
          21.00852611989624
        ]
      },
      "properties": {
        "tenDoiTuon": "21",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 1 Thái Thịnh",
        "tuDien": "TQ2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Thịnh Quang 2",
        "diaChiLapD": "",
        "longitude": 105.81537109631168,
        "latitude": 21.00852611989624,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3292,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81515882269761,
          21.008408775044746
        ]
      },
      "properties": {
        "tenDoiTuon": "20",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 1 Thái Thịnh",
        "tuDien": "TQ2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Thịnh Quang 2",
        "diaChiLapD": "",
        "longitude": 105.81515882269761,
        "latitude": 21.008408775044746,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3293,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81545955234309,
          21.008303378892684
        ]
      },
      "properties": {
        "tenDoiTuon": "21-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 1 Thái Thịnh",
        "tuDien": "TQ2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 7,
        "tuDien2": "Thịnh Quang 2",
        "diaChiLapD": "",
        "longitude": 105.81545955234309,
        "latitude": 21.008303378892684,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3294,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81534561532014,
          21.00835460163507
        ]
      },
      "properties": {
        "tenDoiTuon": "21-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 1 Thái Thịnh",
        "tuDien": "TQ2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 7,
        "tuDien2": "Thịnh Quang 2",
        "diaChiLapD": "",
        "longitude": 105.81534561532014,
        "latitude": 21.00835460163507,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3295,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81554275768801,
          21.008693882090775
        ]
      },
      "properties": {
        "tenDoiTuon": "22",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 1 Thái Thịnh",
        "tuDien": "TQ2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Thịnh Quang 2",
        "diaChiLapD": "",
        "longitude": 105.81554275768801,
        "latitude": 21.008693882090775,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3296,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8157247668694,
          21.008873689984725
        ]
      },
      "properties": {
        "tenDoiTuon": "23",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 1 Thái Thịnh",
        "tuDien": "TQ2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Thịnh Quang 2",
        "diaChiLapD": "",
        "longitude": 105.8157247668694,
        "latitude": 21.008873689984725,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3297,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81581153402898,
          21.009006007064386
        ]
      },
      "properties": {
        "tenDoiTuon": "TQ2 24",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 1 Thái Thịnh",
        "tuDien": "TQ2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Thịnh Quang 2",
        "diaChiLapD": "",
        "longitude": 105.81581153402898,
        "latitude": 21.009006007064386,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3298,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81594911234957,
          21.00861375688697
        ]
      },
      "properties": {
        "tenDoiTuon": "23-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 1 Thái Thịnh",
        "tuDien": "TQ2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Thịnh Quang 2",
        "diaChiLapD": "",
        "longitude": 105.81594911234957,
        "latitude": 21.00861375688697,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3299,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81621777035762,
          21.008880733146647
        ]
      },
      "properties": {
        "tenDoiTuon": "TQ2 23-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 1 Thái Thịnh",
        "tuDien": "TQ2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Thịnh Quang 2",
        "diaChiLapD": "",
        "longitude": 105.81621777035762,
        "latitude": 21.008880733146647,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3300,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81579433504388,
          21.00849635975455
        ]
      },
      "properties": {
        "tenDoiTuon": "23-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 1 Thái Thịnh",
        "tuDien": "TQ2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Thịnh Quang 2",
        "diaChiLapD": "",
        "longitude": 105.81579433504388,
        "latitude": 21.00849635975455,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3301,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81563831137699,
          21.00833676122544
        ]
      },
      "properties": {
        "tenDoiTuon": "23-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 1 Thái Thịnh",
        "tuDien": "TQ2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Thịnh Quang 2",
        "diaChiLapD": "",
        "longitude": 105.81563831137699,
        "latitude": 21.00833676122544,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3302,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81588909791765,
          21.00817901431068
        ]
      },
      "properties": {
        "tenDoiTuon": "TQ2 23-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 1 Thái Thịnh",
        "tuDien": "TQ2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "Thịnh Quang 2",
        "diaChiLapD": "",
        "longitude": 105.81588909791765,
        "latitude": 21.00817901431068,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3303,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81564322439468,
          21.008100632147404
        ]
      },
      "properties": {
        "tenDoiTuon": "23-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 1 Thái Thịnh",
        "tuDien": "TQ2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "Thịnh Quang 2",
        "diaChiLapD": "",
        "longitude": 105.81564322439468,
        "latitude": 21.008100632147404,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3304,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83271428500676,
          21.021067099409372
        ]
      },
      "properties": {
        "tenDoiTuon": "10-16",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 221 Tôn Đức Thắng",
        "tuDien": "KTO",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên Ô",
        "diaChiLapD": "",
        "longitude": 105.83271428500676,
        "latitude": 21.021067099409372,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3305,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83292752062106,
          21.021176010504178
        ]
      },
      "properties": {
        "tenDoiTuon": "10-17",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 221 Tôn Đức Thắng",
        "tuDien": "KTO",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên Ô",
        "diaChiLapD": "",
        "longitude": 105.83292752062106,
        "latitude": 21.021176010504178,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3306,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83298518812346,
          21.021058336444266
        ]
      },
      "properties": {
        "tenDoiTuon": "10--18",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 221 Tôn Đức Thắng",
        "tuDien": "KTO",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên Ô",
        "diaChiLapD": "",
        "longitude": 105.83298518812346,
        "latitude": 21.021058336444266,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3307,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83295300161258,
          21.020834254733376
        ]
      },
      "properties": {
        "tenDoiTuon": "10-20",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 221 Tôn Đức Thắng",
        "tuDien": "KTO",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên Ô",
        "diaChiLapD": "",
        "longitude": 105.83295300161258,
        "latitude": 21.020834254733376,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3308,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83286180650444,
          21.020985728999523
        ]
      },
      "properties": {
        "tenDoiTuon": "10-19",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 221 Tôn Đức Thắng",
        "tuDien": "KTO",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên Ô",
        "diaChiLapD": "",
        "longitude": 105.83286180650444,
        "latitude": 21.020985728999523,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3309,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83241119538818,
          21.020918128933154
        ]
      },
      "properties": {
        "tenDoiTuon": "10-21",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 221 Tôn Đức Thắng",
        "tuDien": "KTO",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên Ô",
        "diaChiLapD": "",
        "longitude": 105.83241119538818,
        "latitude": 21.020918128933154,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3310,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83216845547074,
          21.020849276981913
        ]
      },
      "properties": {
        "tenDoiTuon": "10-22",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 221 Tôn Đức Thắng",
        "tuDien": "KTO",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên Ô",
        "diaChiLapD": "",
        "longitude": 105.83216845547074,
        "latitude": 21.020849276981913,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3311,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83246886288161,
          21.020533809453163
        ]
      },
      "properties": {
        "tenDoiTuon": "10-23",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 221 Tôn Đức Thắng",
        "tuDien": "KTO",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên Ô",
        "diaChiLapD": "",
        "longitude": 105.83246886288161,
        "latitude": 21.020533809453163,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3312,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83239644323662,
          21.02036230500093
        ]
      },
      "properties": {
        "tenDoiTuon": "10-24",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 221 Tôn Đức Thắng",
        "tuDien": "KTO",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên Ô",
        "diaChiLapD": "",
        "longitude": 105.83239644323662,
        "latitude": 21.02036230500093,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3313,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83271830831836,
          21.02050877231926
        ]
      },
      "properties": {
        "tenDoiTuon": "10-25",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 221 Tôn Đức Thắng",
        "tuDien": "KTO",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên Ô",
        "diaChiLapD": "",
        "longitude": 105.83271830831836,
        "latitude": 21.02050877231926,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3314,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8327035561758,
          21.020396105163965
        ]
      },
      "properties": {
        "tenDoiTuon": "10-26",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 221 Tôn Đức Thắng",
        "tuDien": "KTO",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên Ô",
        "diaChiLapD": "",
        "longitude": 105.8327035561758,
        "latitude": 21.020396105163965,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3315,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83267405187266,
          21.020304719519835
        ]
      },
      "properties": {
        "tenDoiTuon": "10-27",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 221 Tôn Đức Thắng",
        "tuDien": "KTO",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên Ô",
        "diaChiLapD": "",
        "longitude": 105.83267405187266,
        "latitude": 21.020304719519835,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3316,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83284303104125,
          21.020431157177036
        ]
      },
      "properties": {
        "tenDoiTuon": "10-28",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 221 Tôn Đức Thắng",
        "tuDien": "KTO",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên Ô",
        "diaChiLapD": "",
        "longitude": 105.83284303104125,
        "latitude": 21.020431157177036,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3317,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83300798689824,
          21.02039109773273
        ]
      },
      "properties": {
        "tenDoiTuon": "10-29",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 221 Tôn Đức Thắng",
        "tuDien": "KTO",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khâm Thiên Ô",
        "diaChiLapD": "",
        "longitude": 105.83300798689824,
        "latitude": 21.02039109773273,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3318,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81520949320988,
          21.016306206178395
        ]
      },
      "properties": {
        "tenDoiTuon": "21",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Thái Hà",
        "tuDien": "F3TC",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "F3 Thành Công",
        "diaChiLapD": "",
        "longitude": 105.81520949320988,
        "latitude": 21.016306206178395,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3319,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8155635448025,
          21.016060835127302
        ]
      },
      "properties": {
        "tenDoiTuon": "22",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Thái Hà",
        "tuDien": "F3TC",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "F3 Thành Công",
        "diaChiLapD": "",
        "longitude": 105.8155635448025,
        "latitude": 21.016060835127302,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3320,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81597124056792,
          21.01578541816044
        ]
      },
      "properties": {
        "tenDoiTuon": "23",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Thái Hà",
        "tuDien": "F3TC",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "F3 Thành Công",
        "diaChiLapD": "",
        "longitude": 105.81597124056792,
        "latitude": 21.01578541816044,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3321,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8163091989051,
          21.015555069034395
        ]
      },
      "properties": {
        "tenDoiTuon": "24",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Thái Hà",
        "tuDien": "F3TC",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "F3 Thành Công",
        "diaChiLapD": "",
        "longitude": 105.8163091989051,
        "latitude": 21.015555069034395,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3322,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81659351306051,
          21.015334734754262
        ]
      },
      "properties": {
        "tenDoiTuon": "25",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Thái Hà",
        "tuDien": "F3TC",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "F3 Thành Công",
        "diaChiLapD": "",
        "longitude": 105.81659351306051,
        "latitude": 21.015334734754262,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3323,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81689928488684,
          21.01513443058129
        ]
      },
      "properties": {
        "tenDoiTuon": "26",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Thái Hà",
        "tuDien": "F3TC",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "F3 Thành Công",
        "diaChiLapD": "",
        "longitude": 105.81689928488684,
        "latitude": 21.01513443058129,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3324,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81715677695584,
          21.014959164209156
        ]
      },
      "properties": {
        "tenDoiTuon": "27",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Thái Hà",
        "tuDien": "F3TC",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "F3 Thành Công",
        "diaChiLapD": "",
        "longitude": 105.81715677695584,
        "latitude": 21.014959164209156,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3325,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81756447273025,
          21.014663714714676
        ]
      },
      "properties": {
        "tenDoiTuon": "28",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Thái Hà",
        "tuDien": "F3TC",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "F3 Thành Công",
        "diaChiLapD": "",
        "longitude": 105.81756447273025,
        "latitude": 21.014663714714676,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3326,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81790672259442,
          21.014403318065717
        ]
      },
      "properties": {
        "tenDoiTuon": "29",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Thái Hà",
        "tuDien": "F3TC",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "F3 Thành Công",
        "diaChiLapD": "",
        "longitude": 105.81790672259442,
        "latitude": 21.014403318065717,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3327,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8182532639991,
          21.014160447704203
        ]
      },
      "properties": {
        "tenDoiTuon": "30",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Thái Hà",
        "tuDien": "F3TC",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "F3 Thành Công",
        "diaChiLapD": "",
        "longitude": 105.8182532639991,
        "latitude": 21.014160447704203,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3328,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81859980540375,
          21.013917576947208
        ]
      },
      "properties": {
        "tenDoiTuon": "F3TC 31",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Thái Hà",
        "tuDien": "F3TC",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "F3 Thành Công",
        "diaChiLapD": "",
        "longitude": 105.81859980540375,
        "latitude": 21.013917576947208,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3329,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81501637416262,
          21.01635127428632
        ]
      },
      "properties": {
        "tenDoiTuon": "F3TC 20",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - đèn pha",
        "tenDuongPh": "Thái Hà",
        "tuDien": "F3TC",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 14,
        "tuDien2": "F3 Thành Công",
        "diaChiLapD": "",
        "longitude": 105.81501637416262,
        "latitude": 21.01635127428632,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3330,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8268771024302,
          21.013296627242312
        ]
      },
      "properties": {
        "tenDoiTuon": "ATH 01",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "",
        "tuDien": "ATH",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 11,
        "tuDien2": "ATH",
        "diaChiLapD": "",
        "longitude": 105.8268771024302,
        "latitude": 21.013296627242312,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3331,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82671080546933,
          21.013026212849727
        ]
      },
      "properties": {
        "tenDoiTuon": "ATH 02",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "",
        "tuDien": "ATH",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 11,
        "tuDien2": "ATH",
        "diaChiLapD": "",
        "longitude": 105.82671080546933,
        "latitude": 21.013026212849727,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3332,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82653377966854,
          21.012745782591185
        ]
      },
      "properties": {
        "tenDoiTuon": "ATH 03",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "",
        "tuDien": "ATH",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 11,
        "tuDien2": "ATH",
        "diaChiLapD": "",
        "longitude": 105.82653377966854,
        "latitude": 21.012745782591185,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3333,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82638357596313,
          21.01248538259384
        ]
      },
      "properties": {
        "tenDoiTuon": "ATH 04",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "",
        "tuDien": "ATH",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 11,
        "tuDien2": "ATH",
        "diaChiLapD": "",
        "longitude": 105.82638357596313,
        "latitude": 21.01248538259384,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3334,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82620923237904,
          21.012209959025437
        ]
      },
      "properties": {
        "tenDoiTuon": "ATH 05",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "",
        "tuDien": "ATH",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 11,
        "tuDien2": "ATH",
        "diaChiLapD": "",
        "longitude": 105.82620923237904,
        "latitude": 21.012209959025437,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3335,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82604561762594,
          21.011944550377017
        ]
      },
      "properties": {
        "tenDoiTuon": "ATH 06",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "",
        "tuDien": "ATH",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 11,
        "tuDien2": "ATH",
        "diaChiLapD": "",
        "longitude": 105.82604561762594,
        "latitude": 21.011944550377017,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3336,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82587932067406,
          21.011691660566733
        ]
      },
      "properties": {
        "tenDoiTuon": "ATH 07",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "",
        "tuDien": "ATH",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 11,
        "tuDien2": "ATH",
        "diaChiLapD": "",
        "longitude": 105.82587932067406,
        "latitude": 21.011691660566733,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3337,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82571302371322,
          21.01143376259569
        ]
      },
      "properties": {
        "tenDoiTuon": "ATH 08",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "",
        "tuDien": "ATH",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 11,
        "tuDien2": "ATH",
        "diaChiLapD": "",
        "longitude": 105.82571302371322,
        "latitude": 21.01143376259569,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3338,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82556013780004,
          21.011198399008265
        ]
      },
      "properties": {
        "tenDoiTuon": "ATH 09",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "",
        "tuDien": "ATH",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 11,
        "tuDien2": "ATH",
        "diaChiLapD": "",
        "longitude": 105.82556013780004,
        "latitude": 21.011198399008265,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3339,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82536433653605,
          21.01090544593151
        ]
      },
      "properties": {
        "tenDoiTuon": "ATH 10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "",
        "tuDien": "ATH",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 11,
        "tuDien2": "ATH",
        "diaChiLapD": "",
        "longitude": 105.82536433653605,
        "latitude": 21.01090544593151,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3340,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8251980395842,
          21.010549895281194
        ]
      },
      "properties": {
        "tenDoiTuon": "ATH 11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "",
        "tuDien": "ATH",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 11,
        "tuDien2": "ATH",
        "diaChiLapD": "",
        "longitude": 105.8251980395842,
        "latitude": 21.010549895281194,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3341,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82503174263233,
          21.010229398203517
        ]
      },
      "properties": {
        "tenDoiTuon": "ATH 12",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "",
        "tuDien": "ATH",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 11,
        "tuDien2": "ATH",
        "diaChiLapD": "",
        "longitude": 105.82503174263233,
        "latitude": 21.010229398203517,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3342,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82486008124701,
          21.009906396536827
        ]
      },
      "properties": {
        "tenDoiTuon": "ATH 13",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "",
        "tuDien": "ATH",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 11,
        "tuDien2": "ATH",
        "diaChiLapD": "",
        "longitude": 105.82486008124701,
        "latitude": 21.009906396536827,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3343,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82349483685054,
          21.007278539144853
        ]
      },
      "properties": {
        "tenDoiTuon": "ATH 25",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "",
        "tuDien": "ATH",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 11,
        "tuDien2": "ATH",
        "diaChiLapD": "",
        "longitude": 105.82349483685054,
        "latitude": 21.007278539144853,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3344,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82469378428617,
          21.009598417550105
        ]
      },
      "properties": {
        "tenDoiTuon": "ATH 14",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "",
        "tuDien": "ATH",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 11,
        "tuDien2": "ATH",
        "diaChiLapD": "",
        "longitude": 105.82469378428617,
        "latitude": 21.009598417550105,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3345,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82460258918701,
          21.009363051067854
        ]
      },
      "properties": {
        "tenDoiTuon": "ATH 15",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "",
        "tuDien": "ATH",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 11,
        "tuDien2": "ATH",
        "diaChiLapD": "",
        "longitude": 105.82460258918701,
        "latitude": 21.009363051067854,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3346,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82439337685734,
          21.00876962541918
        ]
      },
      "properties": {
        "tenDoiTuon": "ATH 20",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "",
        "tuDien": "ATH",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 11,
        "tuDien2": "ATH",
        "diaChiLapD": "",
        "longitude": 105.82439337685734,
        "latitude": 21.00876962541918,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3347,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82371746018298,
          21.00770796351171
        ]
      },
      "properties": {
        "tenDoiTuon": "ATH 24",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "",
        "tuDien": "ATH",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 11,
        "tuDien2": "ATH",
        "diaChiLapD": "",
        "longitude": 105.82371746018298,
        "latitude": 21.00770796351171,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3348,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82415734246827,
          21.00852924979993
        ]
      },
      "properties": {
        "tenDoiTuon": "ATH 21",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "",
        "tuDien": "ATH",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 11,
        "tuDien2": "ATH",
        "diaChiLapD": "",
        "longitude": 105.82415734246827,
        "latitude": 21.00852924979993,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3349,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81657607870122,
          21.0157666397146
        ]
      },
      "properties": {
        "tenDoiTuon": "24-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Thái Hà",
        "tuDien": "F3TC",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 8,
        "tuDien2": "F3 Thành Công",
        "diaChiLapD": "",
        "longitude": 105.81657607870122,
        "latitude": 21.0157666397146,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3350,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.816763833333,
          21.015944408927727
        ]
      },
      "properties": {
        "tenDoiTuon": "24-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Thái Hà",
        "tuDien": "F3TC",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 8,
        "tuDien2": "F3 Thành Công",
        "diaChiLapD": "",
        "longitude": 105.816763833333,
        "latitude": 21.015944408927727,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3351,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81692342478345,
          21.016162238518415
        ]
      },
      "properties": {
        "tenDoiTuon": "24-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Thái Hà",
        "tuDien": "F3TC",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 8,
        "tuDien2": "F3 Thành Công",
        "diaChiLapD": "",
        "longitude": 105.81692342478345,
        "latitude": 21.016162238518415,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3352,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81709642726366,
          21.016383823466867
        ]
      },
      "properties": {
        "tenDoiTuon": "F3TC 24-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Thái Hà",
        "tuDien": "F3TC",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 8,
        "tuDien2": "F3 Thành Công",
        "diaChiLapD": "",
        "longitude": 105.81709642726366,
        "latitude": 21.016383823466867,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3353,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82468305542824,
          21.00888981308358
        ]
      },
      "properties": {
        "tenDoiTuon": "ATH 19",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "",
        "tuDien": "ATH",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "ATH",
        "diaChiLapD": "",
        "longitude": 105.82468305542824,
        "latitude": 21.00888981308358,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3354,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82496200517714,
          21.008946151006306
        ]
      },
      "properties": {
        "tenDoiTuon": "ATH 18",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "",
        "tuDien": "ATH",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "ATH",
        "diaChiLapD": "",
        "longitude": 105.82496200517714,
        "latitude": 21.008946151006306,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3355,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82322963343655,
          21.006708892687506
        ]
      },
      "properties": {
        "tenDoiTuon": "ATH 27",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "",
        "tuDien": "ATH",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "ATH",
        "diaChiLapD": "",
        "longitude": 105.82322963343655,
        "latitude": 21.006708892687506,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3356,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8239964099229,
          21.008228779731205
        ]
      },
      "properties": {
        "tenDoiTuon": "ATH 22",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "",
        "tuDien": "ATH",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 11,
        "tuDien2": "ATH",
        "diaChiLapD": "",
        "longitude": 105.8239964099229,
        "latitude": 21.008228779731205,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3357,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82329903559555,
          21.006889176633635
        ]
      },
      "properties": {
        "tenDoiTuon": "ATH 26",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "",
        "tuDien": "ATH",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 11,
        "tuDien2": "ATH",
        "diaChiLapD": "",
        "longitude": 105.82329903559555,
        "latitude": 21.006889176633635,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3358,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82385157063295,
          21.00793832475656
        ]
      },
      "properties": {
        "tenDoiTuon": "ATH 23",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "",
        "tuDien": "ATH",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 11,
        "tuDien2": "ATH",
        "diaChiLapD": "",
        "longitude": 105.82385157063295,
        "latitude": 21.00793832475656,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3359,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82317297178678,
          21.006435962395685
        ]
      },
      "properties": {
        "tenDoiTuon": "32",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "",
        "tuDien": "CVNTS2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 11,
        "tuDien2": "CV NTS Tủ 2",
        "diaChiLapD": "",
        "longitude": 105.82317297178678,
        "latitude": 21.006435962395685,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3360,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8258524985607,
          21.01125598800984
        ]
      },
      "properties": {
        "tenDoiTuon": "ATH 8-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "",
        "tuDien": "ATH",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "ATH",
        "diaChiLapD": "",
        "longitude": 105.8258524985607,
        "latitude": 21.01125598800984,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3361,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8258524985607,
          21.011005600904177
        ]
      },
      "properties": {
        "tenDoiTuon": "ATH 8-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "",
        "tuDien": "ATH",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "ATH",
        "diaChiLapD": "",
        "longitude": 105.8258524985607,
        "latitude": 21.011005600904177,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3362,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82599733785064,
          21.01080028316431
        ]
      },
      "properties": {
        "tenDoiTuon": "ATH 8-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "",
        "tuDien": "ATH",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "ATH",
        "diaChiLapD": "",
        "longitude": 105.82599733785064,
        "latitude": 21.01080028316431,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3363,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82644526675017,
          21.012312616965623
        ]
      },
      "properties": {
        "tenDoiTuon": "ATH 4-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "",
        "tuDien": "ATH",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "ATH",
        "diaChiLapD": "",
        "longitude": 105.82644526675017,
        "latitude": 21.012312616965623,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3364,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82674030974552,
          21.01214235498517
        ]
      },
      "properties": {
        "tenDoiTuon": "ATH 4-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "",
        "tuDien": "ATH",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "ATH",
        "diaChiLapD": "",
        "longitude": 105.82674030974552,
        "latitude": 21.01214235498517,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3365,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.826549872906,
          21.01177428798235
        ]
      },
      "properties": {
        "tenDoiTuon": "ATH 4-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "",
        "tuDien": "ATH",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "ATH",
        "diaChiLapD": "",
        "longitude": 105.826549872906,
        "latitude": 21.01177428798235,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3366,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82647208884555,
          21.011513886289073
        ]
      },
      "properties": {
        "tenDoiTuon": "ATH 4-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "",
        "tuDien": "ATH",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "ATH",
        "diaChiLapD": "",
        "longitude": 105.82647208884555,
        "latitude": 21.011513886289073,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3367,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82665716126061,
          21.01170417987852
        ]
      },
      "properties": {
        "tenDoiTuon": "ATH 4-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "",
        "tuDien": "ATH",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "ATH",
        "diaChiLapD": "",
        "longitude": 105.82665716126061,
        "latitude": 21.01170417987852,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3368,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82679663613504,
          21.011626560153942
        ]
      },
      "properties": {
        "tenDoiTuon": "ATH 4-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "",
        "tuDien": "ATH",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "ATH",
        "diaChiLapD": "",
        "longitude": 105.82679663613504,
        "latitude": 21.011626560153942,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3369,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82693074658503,
          21.011551444253495
        ]
      },
      "properties": {
        "tenDoiTuon": "ATH 4-7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "",
        "tuDien": "ATH",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "ATH",
        "diaChiLapD": "",
        "longitude": 105.82693074658503,
        "latitude": 21.011551444253495,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3370,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82710240796138,
          21.011458801257337
        ]
      },
      "properties": {
        "tenDoiTuon": "ATH 4-8",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "",
        "tuDien": "ATH",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "ATH",
        "diaChiLapD": "",
        "longitude": 105.82710240796138,
        "latitude": 21.011458801257337,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3371,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8269280643773,
          21.01302370901586
        ]
      },
      "properties": {
        "tenDoiTuon": "ATH 2-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "",
        "tuDien": "ATH",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "ATH",
        "diaChiLapD": "",
        "longitude": 105.8269280643773,
        "latitude": 21.01302370901586,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3372,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82676444962419,
          21.012720744155647
        ]
      },
      "properties": {
        "tenDoiTuon": "ATH 3-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "",
        "tuDien": "ATH",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "ATH",
        "diaChiLapD": "",
        "longitude": 105.82676444962419,
        "latitude": 21.012720744155647,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3373,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82696561530365,
          21.012623094192783
        ]
      },
      "properties": {
        "tenDoiTuon": "ATH 3-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "",
        "tuDien": "ATH",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "ATH",
        "diaChiLapD": "",
        "longitude": 105.82696561530365,
        "latitude": 21.012623094192783,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3374,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82552091044671,
          21.010936431392768
        ]
      },
      "properties": {
        "tenDoiTuon": "ATH 10-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "",
        "tuDien": "ATH",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "ATH",
        "diaChiLapD": "",
        "longitude": 105.82552091044671,
        "latitude": 21.010936431392768,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3375,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8256496564812,
          21.010861315143966
        ]
      },
      "properties": {
        "tenDoiTuon": "ATH 10-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "",
        "tuDien": "ATH",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "ATH",
        "diaChiLapD": "",
        "longitude": 105.8256496564812,
        "latitude": 21.010861315143966,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3376,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82479134959053,
          21.009566805854487
        ]
      },
      "properties": {
        "tenDoiTuon": "ATH 15-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "",
        "tuDien": "ATH",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "ATH",
        "diaChiLapD": "",
        "longitude": 105.82479134959053,
        "latitude": 21.009566805854487,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3377,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82496837539132,
          21.009459138231513
        ]
      },
      "properties": {
        "tenDoiTuon": "ATH 15-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "",
        "tuDien": "ATH",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "ATH",
        "diaChiLapD": "",
        "longitude": 105.82496837539132,
        "latitude": 21.009459138231513,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3378,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82506493491496,
          21.00932142371673
        ]
      },
      "properties": {
        "tenDoiTuon": "ATH 15-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "",
        "tuDien": "ATH",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "ATH",
        "diaChiLapD": "",
        "longitude": 105.82506493491496,
        "latitude": 21.00932142371673,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3379,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8245274873253,
          21.008842238797016
        ]
      },
      "properties": {
        "tenDoiTuon": "ATH 17",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "",
        "tuDien": "ATH",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "ATH",
        "diaChiLapD": "",
        "longitude": 105.8245274873253,
        "latitude": 21.008842238797016,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3380,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81659485416438,
          21.015258368821783
        ]
      },
      "properties": {
        "tenDoiTuon": "01",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 169 Thái Hà",
        "tuDien": "F3TC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 8,
        "tuDien2": "F3 Thành Công",
        "diaChiLapD": "",
        "longitude": 105.81659485416438,
        "latitude": 21.015258368821783,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3381,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81647951917758,
          21.015123163464704
        ]
      },
      "properties": {
        "tenDoiTuon": "02",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 169 Thái Hà",
        "tuDien": "F3TC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 8,
        "tuDien2": "F3 Thành Công",
        "diaChiLapD": "",
        "longitude": 105.81647951917758,
        "latitude": 21.015123163464704,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3382,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81619520502218,
          21.01532346765364
        ]
      },
      "properties": {
        "tenDoiTuon": "03",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 169 Thái Hà",
        "tuDien": "F3TC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 8,
        "tuDien2": "F3 Thành Công",
        "diaChiLapD": "",
        "longitude": 105.81619520502218,
        "latitude": 21.01532346765364,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3383,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81599940376718,
          21.01540609305235
        ]
      },
      "properties": {
        "tenDoiTuon": "04",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 171 Thái Hà",
        "tuDien": "F3TC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 8,
        "tuDien2": "F3 Thành Công",
        "diaChiLapD": "",
        "longitude": 105.81599940376718,
        "latitude": 21.01540609305235,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3384,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8159846516246,
          21.015670243641907
        ]
      },
      "properties": {
        "tenDoiTuon": "05",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 171 Thái Hà",
        "tuDien": "F3TC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 8,
        "tuDien2": "F3 Thành Công",
        "diaChiLapD": "",
        "longitude": 105.8159846516246,
        "latitude": 21.015670243641907,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3385,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81589479761135,
          21.015476199416163
        ]
      },
      "properties": {
        "tenDoiTuon": "06",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 171 Thái Hà",
        "tuDien": "F3TC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 8,
        "tuDien2": "F3 Thành Công",
        "diaChiLapD": "",
        "longitude": 105.81589479761135,
        "latitude": 21.015476199416163,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3386,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81569631415759,
          21.015268384026285
        ]
      },
      "properties": {
        "tenDoiTuon": "07",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 171 Thái Hà",
        "tuDien": "F3TC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 8,
        "tuDien2": "F3 Thành Công",
        "diaChiLapD": "",
        "longitude": 105.81569631415759,
        "latitude": 21.015268384026285,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3387,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8154790552227,
          21.01539107025629
        ]
      },
      "properties": {
        "tenDoiTuon": "08",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Thái Hà",
        "tuDien": "F3TC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 8,
        "tuDien2": "F3 Thành Công",
        "diaChiLapD": "",
        "longitude": 105.8154790552227,
        "latitude": 21.01539107025629,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3388,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81540931778548,
          21.015258368821783
        ]
      },
      "properties": {
        "tenDoiTuon": "09",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Thái Hà",
        "tuDien": "F3TC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 8,
        "tuDien2": "F3 Thành Công",
        "diaChiLapD": "",
        "longitude": 105.81540931778548,
        "latitude": 21.015258368821783,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3389,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81546832638274,
          21.01511815585724
        ]
      },
      "properties": {
        "tenDoiTuon": "10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Thái Hà",
        "tuDien": "F3TC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 8,
        "tuDien2": "F3 Thành Công",
        "diaChiLapD": "",
        "longitude": 105.81546832638274,
        "latitude": 21.01511815585724,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3390,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81544955091957,
          21.014827714295937
        ]
      },
      "properties": {
        "tenDoiTuon": "11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Thái Hà",
        "tuDien": "F3TC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 8,
        "tuDien2": "F3 Thành Công",
        "diaChiLapD": "",
        "longitude": 105.81544955091957,
        "latitude": 21.014827714295937,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3391,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81541468220095,
          21.014587348438617
        ]
      },
      "properties": {
        "tenDoiTuon": "12",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Thái Hà",
        "tuDien": "F3TC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 8,
        "tuDien2": "F3 Thành Công",
        "diaChiLapD": "",
        "longitude": 105.81541468220095,
        "latitude": 21.014587348438617,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3392,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81532885151728,
          21.01439705852658
        ]
      },
      "properties": {
        "tenDoiTuon": "13",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Thái Hà",
        "tuDien": "F3TC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 8,
        "tuDien2": "F3 Thành Công",
        "diaChiLapD": "",
        "longitude": 105.81532885151728,
        "latitude": 21.01439705852658,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3393,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81513036804556,
          21.01414918051444
        ]
      },
      "properties": {
        "tenDoiTuon": "F3TC 14",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Thái Hà",
        "tuDien": "F3TC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 8,
        "tuDien2": "F3 Thành Công",
        "diaChiLapD": "",
        "longitude": 105.81513036804556,
        "latitude": 21.01414918051444,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3394,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8156078012482,
          21.014449638658256
        ]
      },
      "properties": {
        "tenDoiTuon": "15",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Thái Hà",
        "tuDien": "F3TC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 8,
        "tuDien2": "F3 Thành Công",
        "diaChiLapD": "",
        "longitude": 105.8156078012482,
        "latitude": 21.014449638658256,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3395,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8158250601831,
          21.014271867663442
        ]
      },
      "properties": {
        "tenDoiTuon": "16",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Thái Hà",
        "tuDien": "F3TC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 8,
        "tuDien2": "F3 Thành Công",
        "diaChiLapD": "",
        "longitude": 105.8158250601831,
        "latitude": 21.014271867663442,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3396,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81606377677996,
          21.014569821746303
        ]
      },
      "properties": {
        "tenDoiTuon": "17",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 165/30 Thái Hà",
        "tuDien": "F3TC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 8,
        "tuDien2": "F3 Thành Công",
        "diaChiLapD": "",
        "longitude": 105.81606377677996,
        "latitude": 21.014569821746303,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3397,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81583042459859,
          21.014765119058254
        ]
      },
      "properties": {
        "tenDoiTuon": "18",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 165/30 Thái Hà",
        "tuDien": "F3TC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 8,
        "tuDien2": "F3 Thành Công",
        "diaChiLapD": "",
        "longitude": 105.81583042459859,
        "latitude": 21.014765119058254,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3398,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8156212122959,
          21.014940385658406
        ]
      },
      "properties": {
        "tenDoiTuon": "19",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 165/30 Thái Hà",
        "tuDien": "F3TC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 8,
        "tuDien2": "F3 Thành Công",
        "diaChiLapD": "",
        "longitude": 105.8156212122959,
        "latitude": 21.014940385658406,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3399,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81614960748159,
          21.0144220966849
        ]
      },
      "properties": {
        "tenDoiTuon": "F3TC 17-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 165/30 Thái Hà",
        "tuDien": "F3TC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 5,
        "tuDien2": "F3 Thành Công",
        "diaChiLapD": "",
        "longitude": 105.81614960748159,
        "latitude": 21.0144220966849,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3400,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81504185514514,
          21.014607378941236
        ]
      },
      "properties": {
        "tenDoiTuon": "F3TC 8-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "TT vật liệu điện Ngõ Thái Hà",
        "tuDien": "F3TC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 10,
        "tuDien2": "F3 Thành Công",
        "diaChiLapD": "",
        "longitude": 105.81504185514514,
        "latitude": 21.014607378941236,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3401,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81520546989826,
          21.01546618422311
        ]
      },
      "properties": {
        "tenDoiTuon": "8-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "TT vật liệu điện Ngõ Thái Hà",
        "tuDien": "F3TC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 10,
        "tuDien2": "F3 Thành Công",
        "diaChiLapD": "",
        "longitude": 105.81520546989826,
        "latitude": 21.01546618422311,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3402,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81502307968196,
          21.0152508574165
        ]
      },
      "properties": {
        "tenDoiTuon": "8-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "TT vật liệu điện Ngõ Thái Hà",
        "tuDien": "F3TC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 10,
        "tuDien2": "F3 Thành Công",
        "diaChiLapD": "",
        "longitude": 105.81502307968196,
        "latitude": 21.0152508574165,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3403,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81504453736187,
          21.014972935146872
        ]
      },
      "properties": {
        "tenDoiTuon": "8-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "TT vật liệu điện Ngõ Thái Hà",
        "tuDien": "F3TC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 10,
        "tuDien2": "F3 Thành Công",
        "diaChiLapD": "",
        "longitude": 105.81504453736187,
        "latitude": 21.014972935146872,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3404,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81566144543,
          21.015175743340393
        ]
      },
      "properties": {
        "tenDoiTuon": "7-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 171 Thái Hà",
        "tuDien": "F3TC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 5,
        "tuDien2": "F3 Thành Công",
        "diaChiLapD": "",
        "longitude": 105.81566144543,
        "latitude": 21.015175743340393,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3405,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82508672791799,
          21.01667050633244
        ]
      },
      "properties": {
        "tenDoiTuon": "04",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hoàng Cầu",
        "tuDien": "AP",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 10,
        "tuDien2": "Ao Phe",
        "diaChiLapD": "",
        "longitude": 105.82508672791799,
        "latitude": 21.01667050633244,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3406,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82478632050716,
          21.01680737947007
        ]
      },
      "properties": {
        "tenDoiTuon": "03",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hoàng Cầu",
        "tuDien": "AP",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 10,
        "tuDien2": "Ao Phe",
        "diaChiLapD": "",
        "longitude": 105.82478632050716,
        "latitude": 21.01680737947007,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3407,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82448591309631,
          21.016944252482066
        ]
      },
      "properties": {
        "tenDoiTuon": "02",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hoàng Cầu",
        "tuDien": "AP",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 10,
        "tuDien2": "Ao Phe",
        "diaChiLapD": "",
        "longitude": 105.82448591309631,
        "latitude": 21.016944252482066,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3408,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82418550568549,
          21.017081125368463
        ]
      },
      "properties": {
        "tenDoiTuon": "01",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hoàng Cầu",
        "tuDien": "AP",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 10,
        "tuDien2": "Ao Phe",
        "diaChiLapD": "",
        "longitude": 105.82418550568549,
        "latitude": 21.017081125368463,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3409,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82568754273068,
          21.01748673574591
        ]
      },
      "properties": {
        "tenDoiTuon": "08",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hoàng Cầu",
        "tuDien": "AP",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 10,
        "tuDien2": "Ao Phe",
        "diaChiLapD": "",
        "longitude": 105.82568754273068,
        "latitude": 21.01748673574591,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3410,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82553197460979,
          21.01723802827935
        ]
      },
      "properties": {
        "tenDoiTuon": "07",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hoàng Cầu",
        "tuDien": "AP",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 10,
        "tuDien2": "Ao Phe",
        "diaChiLapD": "",
        "longitude": 105.82553197460979,
        "latitude": 21.01723802827935,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3411,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82537640648887,
          21.016989320398014
        ]
      },
      "properties": {
        "tenDoiTuon": "06",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hoàng Cầu",
        "tuDien": "AP",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 10,
        "tuDien2": "Ao Phe",
        "diaChiLapD": "",
        "longitude": 105.82537640648887,
        "latitude": 21.016989320398014,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3412,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82519669848932,
          21.01671807810888
        ]
      },
      "properties": {
        "tenDoiTuon": "05",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hoàng Cầu",
        "tuDien": "AP",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 10,
        "tuDien2": "Ao Phe",
        "diaChiLapD": "",
        "longitude": 105.82519669848932,
        "latitude": 21.01671807810888,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3413,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82564462738884,
          21.017646976578774
        ]
      },
      "properties": {
        "tenDoiTuon": "09",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hoàng Cầu",
        "tuDien": "AP",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 10,
        "tuDien2": "Ao Phe",
        "diaChiLapD": "",
        "longitude": 105.82564462738884,
        "latitude": 21.017646976578774,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3414,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82538177090434,
          21.017752134531186
        ]
      },
      "properties": {
        "tenDoiTuon": "10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hoàng Cầu",
        "tuDien": "AP",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 10,
        "tuDien2": "Ao Phe",
        "diaChiLapD": "",
        "longitude": 105.82538177090434,
        "latitude": 21.017752134531186,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3415,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82510282117343,
          21.017902360049415
        ]
      },
      "properties": {
        "tenDoiTuon": "11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hoàng Cầu",
        "tuDien": "AP",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 10,
        "tuDien2": "Ao Phe",
        "diaChiLapD": "",
        "longitude": 105.82510282117343,
        "latitude": 21.017902360049415,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3416,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82485605794437,
          21.01805759292608
        ]
      },
      "properties": {
        "tenDoiTuon": "12",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hoàng Cầu",
        "tuDien": "AP",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 10,
        "tuDien2": "Ao Phe",
        "diaChiLapD": "",
        "longitude": 105.82485605794437,
        "latitude": 21.01805759292608,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3417,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82660385239296,
          21.02244692339906
        ]
      },
      "properties": {
        "tenDoiTuon": "06",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hào Nam",
        "tuDien": "DDHN2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 11,
        "tuDien2": "Di Dân Hào Nam 2",
        "diaChiLapD": "",
        "longitude": 105.82660385239296,
        "latitude": 21.02244692339906,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3418,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81788365558631,
          21.01085662036306
        ]
      },
      "properties": {
        "tenDoiTuon": "CĐX 22",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Thái Thinh 1",
        "tuDien": "CĐX",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 7,
        "tuDien2": "Cánh Đồng Xi",
        "diaChiLapD": "",
        "longitude": 105.81788365558631,
        "latitude": 21.01085662036306,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3419,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81767328039525,
          21.011080353206232
        ]
      },
      "properties": {
        "tenDoiTuon": "CĐX 28",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "N2/Thái Thịnh 1",
        "tuDien": "CĐX",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Cánh Đồng Xi",
        "diaChiLapD": "",
        "longitude": 105.81767328039525,
        "latitude": 21.011080353206232,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3420,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81745460534604,
          21.01130276503445
        ]
      },
      "properties": {
        "tenDoiTuon": "CĐX 39",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 113 Thái Thịnh 1",
        "tuDien": "CĐX",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Cánh Đồng Xi",
        "diaChiLapD": "",
        "longitude": 105.81745460534604,
        "latitude": 21.01130276503445,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3421,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81736565395335,
          21.010793710477376
        ]
      },
      "properties": {
        "tenDoiTuon": "30",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "N2/Thái Thịnh 1",
        "tuDien": "CĐX",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Cánh Đồng Xi",
        "diaChiLapD": "",
        "longitude": 105.81736565395335,
        "latitude": 21.010793710477376,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3422,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81713632508362,
          21.011019059210795
        ]
      },
      "properties": {
        "tenDoiTuon": "40",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 113 Thái Thịnh 1",
        "tuDien": "CĐX",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Cánh Đồng Xi",
        "diaChiLapD": "",
        "longitude": 105.81713632508362,
        "latitude": 21.011019059210795,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3423,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81761409358245,
          21.010558658862955
        ]
      },
      "properties": {
        "tenDoiTuon": "23",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Thái Thinh 1",
        "tuDien": "CĐX",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Cánh Đồng Xi",
        "diaChiLapD": "",
        "longitude": 105.81761409358245,
        "latitude": 21.010558658862955,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3424,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81781891540066,
          21.010348840882177
        ]
      },
      "properties": {
        "tenDoiTuon": "13",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ ̣̃ Thái Thịnh",
        "tuDien": "CĐX",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "Cánh Đồng Xi",
        "diaChiLapD": "",
        "longitude": 105.81781891540066,
        "latitude": 21.010348840882177,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3425,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81796900517365,
          21.010481392790766
        ]
      },
      "properties": {
        "tenDoiTuon": "12",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ ̣̃ Thái Thịnh",
        "tuDien": "CĐX",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 7,
        "tuDien2": "Cánh Đồng Xi",
        "diaChiLapD": "",
        "longitude": 105.81796900517365,
        "latitude": 21.010481392790766,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3426,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81803796933328,
          21.010082943273854
        ]
      },
      "properties": {
        "tenDoiTuon": "08",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 79 Thái Thịnh",
        "tuDien": "CĐX",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Cánh Đồng Xi",
        "diaChiLapD": "",
        "longitude": 105.81803796933328,
        "latitude": 21.010082943273854,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3427,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8182035648726,
          21.01019828778358
        ]
      },
      "properties": {
        "tenDoiTuon": "07",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 79 Thái Thịnh",
        "tuDien": "CĐX",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 7,
        "tuDien2": "Cánh Đồng Xi",
        "diaChiLapD": "",
        "longitude": 105.8182035648726,
        "latitude": 21.01019828778358,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3428,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81816662863783,
          21.00995897823972
        ]
      },
      "properties": {
        "tenDoiTuon": "09",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 67 Thái Thịnh",
        "tuDien": "CĐX",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Cánh Đồng Xi",
        "diaChiLapD": "",
        "longitude": 105.81816662863783,
        "latitude": 21.00995897823972,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3429,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8182739170014,
          21.00984630311446
        ]
      },
      "properties": {
        "tenDoiTuon": "02",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 67 Thái Thịnh",
        "tuDien": "CĐX",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Cánh Đồng Xi",
        "diaChiLapD": "",
        "longitude": 105.8182739170014,
        "latitude": 21.00984630311446,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3430,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81853064055517,
          21.010016872429357
        ]
      },
      "properties": {
        "tenDoiTuon": "CĐX 01",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 67 Thái Thịnh",
        "tuDien": "CĐX",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Cánh Đồng Xi",
        "diaChiLapD": "",
        "longitude": 105.81853064055517,
        "latitude": 21.010016872429357,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3431,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81798021510991,
          21.00956837077573
        ]
      },
      "properties": {
        "tenDoiTuon": "03",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 67 Thái Thịnh",
        "tuDien": "CĐX",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Cánh Đồng Xi",
        "diaChiLapD": "",
        "longitude": 105.81798021510991,
        "latitude": 21.00956837077573,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3432,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81777770832659,
          21.009822516133138
        ]
      },
      "properties": {
        "tenDoiTuon": "10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 79 Thái Thịnh",
        "tuDien": "CĐX",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Cánh Đồng Xi",
        "diaChiLapD": "",
        "longitude": 105.81777770832659,
        "latitude": 21.009822516133138,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3433,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81792026651891,
          21.009935212999178
        ]
      },
      "properties": {
        "tenDoiTuon": "09",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 79 Thái Thịnh",
        "tuDien": "CĐX",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 7,
        "tuDien2": "Cánh Đồng Xi",
        "diaChiLapD": "",
        "longitude": 105.81792026651891,
        "latitude": 21.009935212999178,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3434,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81754301504137,
          21.010064141613384
        ]
      },
      "properties": {
        "tenDoiTuon": "15",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ ̣̃ Thái Thịnh",
        "tuDien": "CĐX",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "Cánh Đồng Xi",
        "diaChiLapD": "",
        "longitude": 105.81754301504137,
        "latitude": 21.010064141613384,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3435,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81741556775452,
          21.009946915723976
        ]
      },
      "properties": {
        "tenDoiTuon": "16",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ ̣̃ Thái Thịnh",
        "tuDien": "CĐX",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 7,
        "tuDien2": "Cánh Đồng Xi",
        "diaChiLapD": "",
        "longitude": 105.81741556775452,
        "latitude": 21.009946915723976,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3436,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81768453908836,
          21.010175574284663
        ]
      },
      "properties": {
        "tenDoiTuon": "14",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ ̣̃ Thái Thịnh",
        "tuDien": "CĐX",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 7,
        "tuDien2": "Cánh Đồng Xi",
        "diaChiLapD": "",
        "longitude": 105.81768453908836,
        "latitude": 21.010175574284663,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3437,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81732575611545,
          21.01028072785049
        ]
      },
      "properties": {
        "tenDoiTuon": "24",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Thái Thinh 1",
        "tuDien": "CĐX",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Cánh Đồng Xi",
        "diaChiLapD": "",
        "longitude": 105.81732575611545,
        "latitude": 21.01028072785049,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3438,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81706289963095,
          21.010522352588627
        ]
      },
      "properties": {
        "tenDoiTuon": "32",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "N2/Thái Thịnh 1",
        "tuDien": "CĐX",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Cánh Đồng Xi",
        "diaChiLapD": "",
        "longitude": 105.81706289963095,
        "latitude": 21.010522352588627,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3439,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81721410914407,
          21.010691051496945
        ]
      },
      "properties": {
        "tenDoiTuon": "31",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "N2/Thái Thịnh 1",
        "tuDien": "CĐX",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Cánh Đồng Xi",
        "diaChiLapD": "",
        "longitude": 105.81721410914407,
        "latitude": 21.010691051496945,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3440,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81690029068554,
          21.010418128497406
        ]
      },
      "properties": {
        "tenDoiTuon": "33",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "N2/Thái Thịnh 1",
        "tuDien": "CĐX",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Cánh Đồng Xi",
        "diaChiLapD": "",
        "longitude": 105.81690029068554,
        "latitude": 21.010418128497406,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3441,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81765945342195,
          21.009293129870336
        ]
      },
      "properties": {
        "tenDoiTuon": "04",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 67 Thái Thịnh",
        "tuDien": "CĐX",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Cánh Đồng Xi",
        "diaChiLapD": "",
        "longitude": 105.81765945342195,
        "latitude": 21.009293129870336,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3442,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81748505936227,
          21.009360279278074
        ]
      },
      "properties": {
        "tenDoiTuon": "5-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 67 Thái Thịnh",
        "tuDien": "CĐX",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Cánh Đồng Xi",
        "diaChiLapD": "",
        "longitude": 105.81748505936227,
        "latitude": 21.009360279278074,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3443,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81746237067803,
          21.009573069842165
        ]
      },
      "properties": {
        "tenDoiTuon": "17-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 79 Thái Thịnh",
        "tuDien": "CĐX",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Cánh Đồng Xi",
        "diaChiLapD": "",
        "longitude": 105.81746237067803,
        "latitude": 21.009573069842165,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3444,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81764699846997,
          21.009695535789696
        ]
      },
      "properties": {
        "tenDoiTuon": "11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 79 Thái Thịnh",
        "tuDien": "CĐX",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 7,
        "tuDien2": "Cánh Đồng Xi",
        "diaChiLapD": "",
        "longitude": 105.81764699846997,
        "latitude": 21.009695535789696,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3445,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8171984639318,
          21.009788171576517
        ]
      },
      "properties": {
        "tenDoiTuon": "17",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ ̣̃ Thái Thịnh",
        "tuDien": "CĐX",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "Cánh Đồng Xi",
        "diaChiLapD": "",
        "longitude": 105.8171984639318,
        "latitude": 21.009788171576517,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3446,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82654593888692,
          21.02216369420937
        ]
      },
      "properties": {
        "tenDoiTuon": "05",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hào Nam",
        "tuDien": "DDHN2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 11,
        "tuDien2": "Di Dân Hào Nam 2",
        "diaChiLapD": "",
        "longitude": 105.82654593888692,
        "latitude": 21.02216369420937,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3447,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82638902966215,
          21.02187451821627
        ]
      },
      "properties": {
        "tenDoiTuon": "04",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hào Nam",
        "tuDien": "DDHN2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 11,
        "tuDien2": "Di Dân Hào Nam 2",
        "diaChiLapD": "",
        "longitude": 105.82638902966215,
        "latitude": 21.02187451821627,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3448,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82624553147609,
          21.021624148938034
        ]
      },
      "properties": {
        "tenDoiTuon": "03",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hào Nam",
        "tuDien": "DDHN2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 11,
        "tuDien2": "Di Dân Hào Nam 2",
        "diaChiLapD": "",
        "longitude": 105.82624553147609,
        "latitude": 21.021624148938034,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3449,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82614360753699,
          21.02133747559863
        ]
      },
      "properties": {
        "tenDoiTuon": "02",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hào Nam",
        "tuDien": "DDHN2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 11,
        "tuDien2": "Di Dân Hào Nam 2",
        "diaChiLapD": "",
        "longitude": 105.82614360753699,
        "latitude": 21.02133747559863,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3450,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82613434124416,
          21.021116858215418
        ]
      },
      "properties": {
        "tenDoiTuon": "01",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hào Nam",
        "tuDien": "DDHN2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 11,
        "tuDien2": "Di Dân Hào Nam 2",
        "diaChiLapD": "",
        "longitude": 105.82613434124416,
        "latitude": 21.021116858215418,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3451,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81596855831526,
          21.010009056053015
        ]
      },
      "properties": {
        "tenDoiTuon": "45",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 113 Thái Thịnh 1",
        "tuDien": "CĐX",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Cánh Đồng Xi",
        "diaChiLapD": "",
        "longitude": 105.81596855831526,
        "latitude": 21.010009056053015,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3452,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81621263933658,
          21.010226894313462
        ]
      },
      "properties": {
        "tenDoiTuon": "44",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 113 Thái Thịnh 1",
        "tuDien": "CĐX",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Cánh Đồng Xi",
        "diaChiLapD": "",
        "longitude": 105.81621263933658,
        "latitude": 21.010226894313462,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3453,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81648354244429,
          21.010489802135666
        ]
      },
      "properties": {
        "tenDoiTuon": "43",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 113 Thái Thịnh 1",
        "tuDien": "CĐX",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Cánh Đồng Xi",
        "diaChiLapD": "",
        "longitude": 105.81648354244429,
        "latitude": 21.010489802135666,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3454,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81679736090283,
          21.01076022112633
        ]
      },
      "properties": {
        "tenDoiTuon": "42",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 113 Thái Thịnh 1",
        "tuDien": "CĐX",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Cánh Đồng Xi",
        "diaChiLapD": "",
        "longitude": 105.81679736090283,
        "latitude": 21.01076022112633,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3455,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8308246882207,
          21.018274167565167
        ]
      },
      "properties": {
        "tenDoiTuon": "18",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Tôn Đức Thắng",
        "tuDien": "KTO",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Kham Thien O",
        "diaChiLapD": "",
        "longitude": 105.8308246882207,
        "latitude": 21.018274167565167,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3456,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83175407364345,
          21.017293945774448
        ]
      },
      "properties": {
        "tenDoiTuon": "21",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Tôn Đức Thắng",
        "tuDien": "KTO",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Kham Thien O",
        "diaChiLapD": "",
        "longitude": 105.83175407364345,
        "latitude": 21.017293945774448,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3457,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82669614259234,
          21.022645652950324
        ]
      },
      "properties": {
        "tenDoiTuon": "07",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hào Nam",
        "tuDien": "DDHN2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 11,
        "tuDien2": "Di Dân Hào Nam 2",
        "diaChiLapD": "",
        "longitude": 105.82669614259234,
        "latitude": 21.022645652950324,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3458,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82679672542758,
          21.022957360502048
        ]
      },
      "properties": {
        "tenDoiTuon": "08",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hào Nam",
        "tuDien": "DDHN2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 11,
        "tuDien2": "Di Dân Hào Nam 2",
        "diaChiLapD": "",
        "longitude": 105.82679672542758,
        "latitude": 21.022957360502048,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3459,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82688926163961,
          21.02324528256074
        ]
      },
      "properties": {
        "tenDoiTuon": "09",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hào Nam",
        "tuDien": "DDHN2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 11,
        "tuDien2": "Di Dân Hào Nam 2",
        "diaChiLapD": "",
        "longitude": 105.82688926163961,
        "latitude": 21.02324528256074,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3460,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8269737512284,
          21.023539463219826
        ]
      },
      "properties": {
        "tenDoiTuon": "10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hào Nam",
        "tuDien": "DDHN2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 11,
        "tuDien2": "Di Dân Hào Nam 2",
        "diaChiLapD": "",
        "longitude": 105.8269737512284,
        "latitude": 21.023539463219826,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3461,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8270488530811,
          21.02385367255981
        ]
      },
      "properties": {
        "tenDoiTuon": "11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hào Nam",
        "tuDien": "DDHN2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 11,
        "tuDien2": "Di Dân Hào Nam 2",
        "diaChiLapD": "",
        "longitude": 105.8270488530811,
        "latitude": 21.02385367255981,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3462,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82717223469115,
          21.024242990783083
        ]
      },
      "properties": {
        "tenDoiTuon": "12",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hào Nam",
        "tuDien": "DDHN2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 11,
        "tuDien2": "Di Dân Hào Nam 2",
        "diaChiLapD": "",
        "longitude": 105.82717223469115,
        "latitude": 21.024242990783083,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3463,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82725940648766,
          21.024545932234798
        ]
      },
      "properties": {
        "tenDoiTuon": "13",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hào Nam",
        "tuDien": "DDHN2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 11,
        "tuDien2": "Di Dân Hào Nam 2",
        "diaChiLapD": "",
        "longitude": 105.82725940648766,
        "latitude": 21.024545932234798,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3464,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82734657827521,
          21.02485638399266
        ]
      },
      "properties": {
        "tenDoiTuon": "14",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hào Nam",
        "tuDien": "DDHN2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 11,
        "tuDien2": "Di Dân Hào Nam 2",
        "diaChiLapD": "",
        "longitude": 105.82734657827521,
        "latitude": 21.02485638399266,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3465,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82743643227948,
          21.0251768496446
        ]
      },
      "properties": {
        "tenDoiTuon": "15",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hào Nam",
        "tuDien": "DDHN2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 11,
        "tuDien2": "Di Dân Hào Nam 2",
        "diaChiLapD": "",
        "longitude": 105.82743643227948,
        "latitude": 21.0251768496446,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3466,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82755042616242,
          21.025473530122657
        ]
      },
      "properties": {
        "tenDoiTuon": "16",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hào Nam",
        "tuDien": "DDHN2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 11,
        "tuDien2": "Di Dân Hào Nam 2",
        "diaChiLapD": "",
        "longitude": 105.82755042616242,
        "latitude": 21.025473530122657,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3467,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82765905562988,
          21.025879117668328
        ]
      },
      "properties": {
        "tenDoiTuon": "17",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hào Nam",
        "tuDien": "DDHN2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Di Dân Hào Nam 2",
        "diaChiLapD": "",
        "longitude": 105.82765905562988,
        "latitude": 21.025879117668328,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3468,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82771269981167,
          21.0261983293131
        ]
      },
      "properties": {
        "tenDoiTuon": "18",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hào Nam",
        "tuDien": "DDHN2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 11,
        "tuDien2": "Di Dân Hào Nam 2",
        "diaChiLapD": "",
        "longitude": 105.82771269981167,
        "latitude": 21.0261983293131,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3469,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82780523602368,
          21.026476230658492
        ]
      },
      "properties": {
        "tenDoiTuon": "19",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hào Nam",
        "tuDien": "DDHN2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 11,
        "tuDien2": "Di Dân Hào Nam 2",
        "diaChiLapD": "",
        "longitude": 105.82780523602368,
        "latitude": 21.026476230658492,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3470,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8278977722267,
          21.0267190809602
        ]
      },
      "properties": {
        "tenDoiTuon": "20",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hào Nam",
        "tuDien": "DDHN2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 11,
        "tuDien2": "Di Dân Hào Nam 2",
        "diaChiLapD": "",
        "longitude": 105.8278977722267,
        "latitude": 21.0267190809602,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3471,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82797823850389,
          21.026928132191006
        ]
      },
      "properties": {
        "tenDoiTuon": "21",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hào Nam",
        "tuDien": "DDHN2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 11,
        "tuDien2": "Di Dân Hào Nam 2",
        "diaChiLapD": "",
        "longitude": 105.82797823850389,
        "latitude": 21.026928132191006,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3472,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81612647337468,
          21.009223771526152
        ]
      },
      "properties": {
        "tenDoiTuon": "73",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Thái Thinh 1",
        "tuDien": "CĐX",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Cánh Đồng Xi",
        "diaChiLapD": "",
        "longitude": 105.81612647337468,
        "latitude": 21.009223771526152,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3473,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81644431513584,
          21.009496696710702
        ]
      },
      "properties": {
        "tenDoiTuon": "27",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Thái Thinh 1",
        "tuDien": "CĐX",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Cánh Đồng Xi",
        "diaChiLapD": "",
        "longitude": 105.81644431513584,
        "latitude": 21.009496696710702,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3474,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8167031483087,
          21.0097984161728
        ]
      },
      "properties": {
        "tenDoiTuon": "26",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Thái Thinh 1",
        "tuDien": "CĐX",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Cánh Đồng Xi",
        "diaChiLapD": "",
        "longitude": 105.8167031483087,
        "latitude": 21.0097984161728,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3475,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81696332257647,
          21.010021262520386
        ]
      },
      "properties": {
        "tenDoiTuon": "25",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Thái Thinh 1",
        "tuDien": "CĐX",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Cánh Đồng Xi",
        "diaChiLapD": "",
        "longitude": 105.81696332257647,
        "latitude": 21.010021262520386,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3476,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81652897235027,
          21.009418293369013
        ]
      },
      "properties": {
        "tenDoiTuon": "27-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Thái Thinh 1",
        "tuDien": "CĐX",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Cánh Đồng Xi",
        "diaChiLapD": "",
        "longitude": 105.81652897235027,
        "latitude": 21.009418293369013,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3477,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83131821467877,
          21.017994999053276
        ]
      },
      "properties": {
        "tenDoiTuon": "20-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Đê La Thành",
        "tuDien": "KTO",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Kham Thien O",
        "diaChiLapD": "",
        "longitude": 105.83131821467877,
        "latitude": 21.017994999053276,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3478,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83141611530627,
          21.017937412657925
        ]
      },
      "properties": {
        "tenDoiTuon": "20-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Đê La Thành",
        "tuDien": "KTO",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Kham Thien O",
        "diaChiLapD": "",
        "longitude": 105.83141611530627,
        "latitude": 21.017937412657925,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3479,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83182381107171,
          21.017839766111447
        ]
      },
      "properties": {
        "tenDoiTuon": "20-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 298 Đê La Thành",
        "tuDien": "KTO",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Kham Thien O",
        "diaChiLapD": "",
        "longitude": 105.83182381107171,
        "latitude": 21.017839766111447,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3480,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83158509447487,
          21.01770456309467
        ]
      },
      "properties": {
        "tenDoiTuon": "20-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Đê La Thành",
        "tuDien": "KTO",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Kham Thien O",
        "diaChiLapD": "",
        "longitude": 105.83158509447487,
        "latitude": 21.01770456309467,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3481,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83171115829265,
          21.01778969093394
        ]
      },
      "properties": {
        "tenDoiTuon": "20-4-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 298 Đê La Thành",
        "tuDien": "KTO",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Kham Thien O",
        "diaChiLapD": "",
        "longitude": 105.83171115829265,
        "latitude": 21.01778969093394,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3482,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83191902949146,
          21.018056341057672
        ]
      },
      "properties": {
        "tenDoiTuon": "20-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 298 Đê La Thành",
        "tuDien": "KTO",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Kham Thien O",
        "diaChiLapD": "",
        "longitude": 105.83191902949146,
        "latitude": 21.018056341057672,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3483,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83208398534845,
          21.01827041193937
        ]
      },
      "properties": {
        "tenDoiTuon": "20-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 298 Đê La Thành",
        "tuDien": "KTO",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Kham Thien O",
        "diaChiLapD": "",
        "longitude": 105.83208398534845,
        "latitude": 21.01827041193937,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3484,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83234952404067,
          21.017534307270356
        ]
      },
      "properties": {
        "tenDoiTuon": "22-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 268 Đê La Thành",
        "tuDien": "KTO",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Kham Thien O",
        "diaChiLapD": "",
        "longitude": 105.83234952404067,
        "latitude": 21.017534307270356,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3485,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83201156570348,
          21.01716374980342
        ]
      },
      "properties": {
        "tenDoiTuon": "22-1-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 298 Đê La Thành",
        "tuDien": "KTO",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Kham Thien O",
        "diaChiLapD": "",
        "longitude": 105.83201156570348,
        "latitude": 21.01716374980342,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3486,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83212958289805,
          21.01722384026596
        ]
      },
      "properties": {
        "tenDoiTuon": "22-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 268 Đê La Thành",
        "tuDien": "KTO",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Kham Thien O",
        "diaChiLapD": "",
        "longitude": 105.83212958289805,
        "latitude": 21.01722384026596,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3487,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83243133141275,
          21.017673266083285
        ]
      },
      "properties": {
        "tenDoiTuon": "22-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 268 Đê La Thành",
        "tuDien": "KTO",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Kham Thien O",
        "diaChiLapD": "",
        "longitude": 105.83243133141275,
        "latitude": 21.017673266083285,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3488,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83251179768992,
          21.01781347664554
        ]
      },
      "properties": {
        "tenDoiTuon": "22-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Đê La Thành",
        "tuDien": "KTO",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Kham Thien O",
        "diaChiLapD": "",
        "longitude": 105.83251179768992,
        "latitude": 21.01781347664554,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3489,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83238305165541,
          21.01793991641435
        ]
      },
      "properties": {
        "tenDoiTuon": "22-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 268 Đê La Thành",
        "tuDien": "KTO",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Kham Thien O",
        "diaChiLapD": "",
        "longitude": 105.83238305165541,
        "latitude": 21.01793991641435,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3490,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83263115598834,
          21.017969961492266
        ]
      },
      "properties": {
        "tenDoiTuon": "22-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 268 Đê La Thành",
        "tuDien": "KTO",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Kham Thien O",
        "diaChiLapD": "",
        "longitude": 105.83263115598834,
        "latitude": 21.017969961492266,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3491,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83250240995383,
          21.018041318528624
        ]
      },
      "properties": {
        "tenDoiTuon": "22-7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 268 Đê La Thành",
        "tuDien": "KTO",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Kham Thien O",
        "diaChiLapD": "",
        "longitude": 105.83250240995383,
        "latitude": 21.018041318528624,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3492,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82637363578394,
          21.02251818619776
        ]
      },
      "properties": {
        "tenDoiTuon": "35",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hào Nam",
        "tuDien": "DDHN2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 11,
        "tuDien2": "Di Dân Hào Nam 2",
        "diaChiLapD": "",
        "longitude": 105.82637363578394,
        "latitude": 21.02251818619776,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3493,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82626303415546,
          21.02222561326595
        ]
      },
      "properties": {
        "tenDoiTuon": "36",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hào Nam",
        "tuDien": "DDHN2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 11,
        "tuDien2": "Di Dân Hào Nam 2",
        "diaChiLapD": "",
        "longitude": 105.82626303415546,
        "latitude": 21.02222561326595,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3494,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82614710270664,
          21.021917011974114
        ]
      },
      "properties": {
        "tenDoiTuon": "37",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hào Nam",
        "tuDien": "DDHN2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 11,
        "tuDien2": "Di Dân Hào Nam 2",
        "diaChiLapD": "",
        "longitude": 105.82614710270664,
        "latitude": 21.021917011974114,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3495,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82602180598421,
          21.021638720213723
        ]
      },
      "properties": {
        "tenDoiTuon": "38",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hào Nam",
        "tuDien": "DDHN2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 11,
        "tuDien2": "Di Dân Hào Nam 2",
        "diaChiLapD": "",
        "longitude": 105.82602180598421,
        "latitude": 21.021638720213723,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3496,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82581630865388,
          21.021265282069187
        ]
      },
      "properties": {
        "tenDoiTuon": "39",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hào Nam",
        "tuDien": "DDHN2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 11,
        "tuDien2": "Di Dân Hào Nam 2",
        "diaChiLapD": "",
        "longitude": 105.82581630865388,
        "latitude": 21.021265282069187,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3497,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83218590928756,
          21.01696845563333
        ]
      },
      "properties": {
        "tenDoiTuon": "23-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Đê La Thành",
        "tuDien": "KTO",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Kham Thien O",
        "diaChiLapD": "",
        "longitude": 105.83218590928756,
        "latitude": 21.01696845563333,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3498,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83227576329185,
          21.016927143371685
        ]
      },
      "properties": {
        "tenDoiTuon": "23-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Đê La Thành",
        "tuDien": "KTO",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Kham Thien O",
        "diaChiLapD": "",
        "longitude": 105.83227576329185,
        "latitude": 21.016927143371685,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3499,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83202765895894,
          21.016791939528307
        ]
      },
      "properties": {
        "tenDoiTuon": "23-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Đê La Thành",
        "tuDien": "KTO",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Kham Thien O",
        "diaChiLapD": "",
        "longitude": 105.83202765895894,
        "latitude": 21.016791939528307,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3500,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83256946518333,
          21.01674687155273
        ]
      },
      "properties": {
        "tenDoiTuon": "24-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Đê La Thành",
        "tuDien": "KTO",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Kham Thien O",
        "diaChiLapD": "",
        "longitude": 105.83256946518333,
        "latitude": 21.01674687155273,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3501,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83053769184858,
          21.018798702506068
        ]
      },
      "properties": {
        "tenDoiTuon": "14-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 295 Khâm Thiên",
        "tuDien": "KTO",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Kham Thien O",
        "diaChiLapD": "",
        "longitude": 105.83053769184858,
        "latitude": 21.018798702506068,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3502,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83059535934197,
          21.018711071498547
        ]
      },
      "properties": {
        "tenDoiTuon": "14-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 295 Khâm Thiên",
        "tuDien": "KTO",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Kham Thien O",
        "diaChiLapD": "",
        "longitude": 105.83059535934197,
        "latitude": 21.018711071498547,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3503,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8300964684684,
          21.018509519986658
        ]
      },
      "properties": {
        "tenDoiTuon": "KTO 16",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Đường phố - đèn pha",
        "tenDuongPh": "Khâm Thiên",
        "tuDien": "KTO",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Kham Thien O",
        "diaChiLapD": "",
        "longitude": 105.8300964684684,
        "latitude": 21.018509519986658,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3504,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81634742040586,
          21.009056948884595
        ]
      },
      "properties": {
        "tenDoiTuon": "74",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 97 Thái Thịnh",
        "tuDien": "CĐX",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "Cánh Đồng Xi",
        "diaChiLapD": "",
        "longitude": 105.81634742040586,
        "latitude": 21.009056948884595,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3505,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81654053944415,
          21.009182144125283
        ]
      },
      "properties": {
        "tenDoiTuon": "21",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 97 Thái Thịnh",
        "tuDien": "CĐX",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 7,
        "tuDien2": "Cánh Đồng Xi",
        "diaChiLapD": "",
        "longitude": 105.81654053944415,
        "latitude": 21.009182144125283,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3506,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81669610756506,
          21.009319858768603
        ]
      },
      "properties": {
        "tenDoiTuon": "20",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 97 Thái Thịnh",
        "tuDien": "CĐX",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "Cánh Đồng Xi",
        "diaChiLapD": "",
        "longitude": 105.81669610756506,
        "latitude": 21.009319858768603,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3507,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81678462046546,
          21.00941751089293
        ]
      },
      "properties": {
        "tenDoiTuon": "20",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 97 Thái Thịnh",
        "tuDien": "CĐX",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "Cánh Đồng Xi",
        "diaChiLapD": "",
        "longitude": 105.81678462046546,
        "latitude": 21.00941751089293,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3508,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8169240953309,
          21.009536446087093
        ]
      },
      "properties": {
        "tenDoiTuon": "19",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 97 Thái Thịnh",
        "tuDien": "CĐX",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "Cánh Đồng Xi",
        "diaChiLapD": "",
        "longitude": 105.8169240953309,
        "latitude": 21.009536446087093,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3509,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8170434536383,
          21.009649121445538
        ]
      },
      "properties": {
        "tenDoiTuon": "18",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 97 Thái Thịnh",
        "tuDien": "CĐX",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 7,
        "tuDien2": "Cánh Đồng Xi",
        "diaChiLapD": "",
        "longitude": 105.8170434536383,
        "latitude": 21.009649121445538,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3510,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81647348421465,
          21.008826589367803
        ]
      },
      "properties": {
        "tenDoiTuon": "CĐX 75",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 97 Thái Thịnh",
        "tuDien": "CĐX",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 7,
        "tuDien2": "Cánh Đồng Xi",
        "diaChiLapD": "",
        "longitude": 105.81647348421465,
        "latitude": 21.008826589367803,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3511,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82648244764492,
          21.02288159411798
        ]
      },
      "properties": {
        "tenDoiTuon": "34",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hào Nam",
        "tuDien": "DDHN2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 11,
        "tuDien2": "Di Dân Hào Nam 2",
        "diaChiLapD": "",
        "longitude": 105.82648244764492,
        "latitude": 21.02288159411798,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3512,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82654337087168,
          21.023083363316317
        ]
      },
      "properties": {
        "tenDoiTuon": "33",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hào Nam",
        "tuDien": "DDHN2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 11,
        "tuDien2": "Di Dân Hào Nam 2",
        "diaChiLapD": "",
        "longitude": 105.82654337087168,
        "latitude": 21.023083363316317,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3513,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82662802668139,
          21.023374703862245
        ]
      },
      "properties": {
        "tenDoiTuon": "32",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hào Nam",
        "tuDien": "DDHN2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 11,
        "tuDien2": "Di Dân Hào Nam 2",
        "diaChiLapD": "",
        "longitude": 105.82662802668139,
        "latitude": 21.023374703862245,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3514,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82674365072356,
          21.023674505403434
        ]
      },
      "properties": {
        "tenDoiTuon": "31",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hào Nam",
        "tuDien": "DDHN2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 11,
        "tuDien2": "Di Dân Hào Nam 2",
        "diaChiLapD": "",
        "longitude": 105.82674365072356,
        "latitude": 21.023674505403434,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3515,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82683199833981,
          21.02398228477998
        ]
      },
      "properties": {
        "tenDoiTuon": "30",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hào Nam",
        "tuDien": "DDHN2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 11,
        "tuDien2": "Di Dân Hào Nam 2",
        "diaChiLapD": "",
        "longitude": 105.82683199833981,
        "latitude": 21.02398228477998,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3516,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8269378271852,
          21.024285220764398
        ]
      },
      "properties": {
        "tenDoiTuon": "29",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hào Nam",
        "tuDien": "DDHN2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 11,
        "tuDien2": "Di Dân Hào Nam 2",
        "diaChiLapD": "",
        "longitude": 105.8269378271852,
        "latitude": 21.024285220764398,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3517,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82701374733301,
          21.02458749967852
        ]
      },
      "properties": {
        "tenDoiTuon": "28",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hào Nam",
        "tuDien": "DDHN2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 11,
        "tuDien2": "Di Dân Hào Nam 2",
        "diaChiLapD": "",
        "longitude": 105.82701374733301,
        "latitude": 21.02458749967852,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3518,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8270952266284,
          21.024927068521837
        ]
      },
      "properties": {
        "tenDoiTuon": "27",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hào Nam",
        "tuDien": "DDHN2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 11,
        "tuDien2": "Di Dân Hào Nam 2",
        "diaChiLapD": "",
        "longitude": 105.8270952266284,
        "latitude": 21.024927068521837,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3519,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8271076246489,
          21.025252215974465
        ]
      },
      "properties": {
        "tenDoiTuon": "26",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hào Nam",
        "tuDien": "DDHN2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 11,
        "tuDien2": "Di Dân Hào Nam 2",
        "diaChiLapD": "",
        "longitude": 105.8271076246489,
        "latitude": 21.025252215974465,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3520,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82720418417254,
          21.025607731571416
        ]
      },
      "properties": {
        "tenDoiTuon": "25",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hào Nam",
        "tuDien": "DDHN2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 11,
        "tuDien2": "Di Dân Hào Nam 2",
        "diaChiLapD": "",
        "longitude": 105.82720418417254,
        "latitude": 21.025607731571416,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3521,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82730744921551,
          21.025949476398598
        ]
      },
      "properties": {
        "tenDoiTuon": "24",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hào Nam",
        "tuDien": "DDHN2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 11,
        "tuDien2": "Di Dân Hào Nam 2",
        "diaChiLapD": "",
        "longitude": 105.82730744921551,
        "latitude": 21.025949476398598,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3522,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82736243450118,
          21.026144758805806
        ]
      },
      "properties": {
        "tenDoiTuon": "23",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hào Nam",
        "tuDien": "DDHN2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 11,
        "tuDien2": "Di Dân Hào Nam 2",
        "diaChiLapD": "",
        "longitude": 105.82736243450118,
        "latitude": 21.026144758805806,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3523,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81733212636557,
          21.009268841771515
        ]
      },
      "properties": {
        "tenDoiTuon": "5-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 67 Thái Thịnh",
        "tuDien": "CĐX",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Cánh Đồng Xi",
        "diaChiLapD": "",
        "longitude": 105.81733212636557,
        "latitude": 21.009268841771515,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3524,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81750646994966,
          21.009148654412453
        ]
      },
      "properties": {
        "tenDoiTuon": "05",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 67 Thái Thịnh",
        "tuDien": "CĐX",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Cánh Đồng Xi",
        "diaChiLapD": "",
        "longitude": 105.81750646994966,
        "latitude": 21.009148654412453,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3525,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81720708436411,
          21.00888063174718
        ]
      },
      "properties": {
        "tenDoiTuon": "CĐX 06",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 67 Thái Thịnh",
        "tuDien": "CĐX",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Cánh Đồng Xi",
        "diaChiLapD": "",
        "longitude": 105.81720708436411,
        "latitude": 21.00888063174718,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3526,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8176720476674,
          21.009167890860343
        ]
      },
      "properties": {
        "tenDoiTuon": "4-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 67 Thái Thịnh",
        "tuDien": "CĐX",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Cánh Đồng Xi",
        "diaChiLapD": "",
        "longitude": 105.8176720476674,
        "latitude": 21.009167890860343,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3527,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82671442366373,
          21.024685877298406
        ]
      },
      "properties": {
        "tenDoiTuon": "28-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 101",
        "tuDien": "DDHN2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 11,
        "tuDien2": "Di Dân Hào Nam 2",
        "diaChiLapD": "",
        "longitude": 105.82671442366373,
        "latitude": 21.024685877298406,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3528,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82664920393889,
          21.024490852016868
        ]
      },
      "properties": {
        "tenDoiTuon": "28-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 101",
        "tuDien": "DDHN2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Di Dân Hào Nam 2",
        "diaChiLapD": "",
        "longitude": 105.82664920393889,
        "latitude": 21.024490852016868,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3529,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82674576346254,
          21.024852628530965
        ]
      },
      "properties": {
        "tenDoiTuon": "28-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 101",
        "tuDien": "DDHN2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Di Dân Hào Nam 2",
        "diaChiLapD": "",
        "longitude": 105.82674576346254,
        "latitude": 21.024852628530965,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3530,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83486614579346,
          21.02122480907204
        ]
      },
      "properties": {
        "tenDoiTuon": "01",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ven hồ Văn Chương",
        "tuDien": "VC1B",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "DC-06",
        "chieuCao": 11,
        "tuDien2": "Văn Chương 1B",
        "diaChiLapD": "",
        "longitude": 105.83486614579346,
        "latitude": 21.02122480907204,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3531,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83453440124683,
          21.021283646049202
        ]
      },
      "properties": {
        "tenDoiTuon": "02",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ven hồ Văn Chương",
        "tuDien": "VC1B",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "DC-06",
        "chieuCao": 11,
        "tuDien2": "Văn Chương 1B",
        "diaChiLapD": "",
        "longitude": 105.83453440124683,
        "latitude": 21.021283646049202,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3532,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83427959139455,
          21.021281142348084
        ]
      },
      "properties": {
        "tenDoiTuon": "03",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ven hồ Văn Chương",
        "tuDien": "VC1B",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "DC-06",
        "chieuCao": 11,
        "tuDien2": "Văn Chương 1B",
        "diaChiLapD": "",
        "longitude": 105.83427959139455,
        "latitude": 21.021281142348084,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3533,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83412134105691,
          21.021308683052958
        ]
      },
      "properties": {
        "tenDoiTuon": "04",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ven hồ Văn Chương",
        "tuDien": "VC1B",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "DC-06",
        "chieuCao": 11,
        "tuDien2": "Văn Chương 1B",
        "diaChiLapD": "",
        "longitude": 105.83412134105691,
        "latitude": 21.021308683052958,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3534,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83407976681896,
          21.021569067643945
        ]
      },
      "properties": {
        "tenDoiTuon": "05",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ven hồ Văn Chương",
        "tuDien": "VC1B",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "DC-06",
        "chieuCao": 11,
        "tuDien2": "Văn Chương 1B",
        "diaChiLapD": "",
        "longitude": 105.83407976681896,
        "latitude": 21.021569067643945,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3535,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83401807601395,
          21.021859496075223
        ]
      },
      "properties": {
        "tenDoiTuon": "06",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ven hồ Văn Chương",
        "tuDien": "VC1B",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "DC-06",
        "chieuCao": 11,
        "tuDien2": "Văn Chương 1B",
        "diaChiLapD": "",
        "longitude": 105.83401807601395,
        "latitude": 21.021859496075223,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3536,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83389201218716,
          21.022084828088186
        ]
      },
      "properties": {
        "tenDoiTuon": "07",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ven hồ Văn Chương",
        "tuDien": "VC1B",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "DC-06",
        "chieuCao": 11,
        "tuDien2": "Văn Chương 1B",
        "diaChiLapD": "",
        "longitude": 105.83389201218716,
        "latitude": 21.022084828088186,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3537,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83356075937834,
          21.022287626609245
        ]
      },
      "properties": {
        "tenDoiTuon": "08",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ven hồ Văn Chương",
        "tuDien": "VC1B",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "DC-06",
        "chieuCao": 11,
        "tuDien2": "Văn Chương 1B",
        "diaChiLapD": "",
        "longitude": 105.83356075937834,
        "latitude": 21.022287626609245,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3538,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8332375531927,
          21.022270100821963
        ]
      },
      "properties": {
        "tenDoiTuon": "09",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ven hồ Văn Chương",
        "tuDien": "VC1B",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "DC-06",
        "chieuCao": 11,
        "tuDien2": "Văn Chương 1B",
        "diaChiLapD": "",
        "longitude": 105.8332375531927,
        "latitude": 21.022270100821963,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3539,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83296396786827,
          21.021811925939886
        ]
      },
      "properties": {
        "tenDoiTuon": "11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ven hồ Văn Chương",
        "tuDien": "VC1B",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "DC-06",
        "chieuCao": 11,
        "tuDien2": "Văn Chương 1B",
        "diaChiLapD": "",
        "longitude": 105.83296396786827,
        "latitude": 21.021811925939886,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3540,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83300420100238,
          21.022091087306304
        ]
      },
      "properties": {
        "tenDoiTuon": "10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ven hồ Văn Chương",
        "tuDien": "VC1B",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "DC-06",
        "chieuCao": 11,
        "tuDien2": "Văn Chương 1B",
        "diaChiLapD": "",
        "longitude": 105.83300420100238,
        "latitude": 21.022091087306304,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3541,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83294251019736,
          21.02162414894222
        ]
      },
      "properties": {
        "tenDoiTuon": "14",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ven hồ Văn Chương",
        "tuDien": "VC1B",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "DC-06",
        "chieuCao": 11,
        "tuDien2": "Văn Chương 1B",
        "diaChiLapD": "",
        "longitude": 105.83294251019736,
        "latitude": 21.02162414894222,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3542,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83301358873845,
          21.02153526774682
        ]
      },
      "properties": {
        "tenDoiTuon": "15",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ven hồ Văn Chương",
        "tuDien": "VC1B",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "DC-06",
        "chieuCao": 11,
        "tuDien2": "Văn Chương 1B",
        "diaChiLapD": "",
        "longitude": 105.83301358873845,
        "latitude": 21.02153526774682,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3543,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83313831145236,
          21.02123857943115
        ]
      },
      "properties": {
        "tenDoiTuon": "16",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ven hồ Văn Chương",
        "tuDien": "VC1B",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "DC-06",
        "chieuCao": 11,
        "tuDien2": "Văn Chương 1B",
        "diaChiLapD": "",
        "longitude": 105.83313831145236,
        "latitude": 21.02123857943115,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3544,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83349772746047,
          21.020715304929464
        ]
      },
      "properties": {
        "tenDoiTuon": "18",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ven hồ Văn Chương",
        "tuDien": "VC1B",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "DC-06",
        "chieuCao": 11,
        "tuDien2": "Văn Chương 1B",
        "diaChiLapD": "",
        "longitude": 105.83349772746047,
        "latitude": 21.020715304929464,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3545,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8332697396946,
          21.02094439423191
        ]
      },
      "properties": {
        "tenDoiTuon": "17",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ven hồ Văn Chương",
        "tuDien": "VC1B",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "DC-06",
        "chieuCao": 11,
        "tuDien2": "Văn Chương 1B",
        "diaChiLapD": "",
        "longitude": 105.8332697396946,
        "latitude": 21.02094439423191,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3546,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83411195332982,
          21.021043290597884
        ]
      },
      "properties": {
        "tenDoiTuon": "21",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ven hồ Văn Chương",
        "tuDien": "VC1B",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "DC-06",
        "chieuCao": 11,
        "tuDien2": "Văn Chương 1B",
        "diaChiLapD": "",
        "longitude": 105.83411195332982,
        "latitude": 21.021043290597884,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3547,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83403953368486,
          21.02083423111361
        ]
      },
      "properties": {
        "tenDoiTuon": "20",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ven hồ Văn Chương",
        "tuDien": "VC1B",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "DC-06",
        "chieuCao": 11,
        "tuDien2": "Văn Chương 1B",
        "diaChiLapD": "",
        "longitude": 105.83403953368486,
        "latitude": 21.02083423111361,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3548,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8338330035899,
          21.020641445461738
        ]
      },
      "properties": {
        "tenDoiTuon": "19",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ven hồ Văn Chương",
        "tuDien": "VC1B",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "DC-06",
        "chieuCao": 11,
        "tuDien2": "Văn Chương 1B",
        "diaChiLapD": "",
        "longitude": 105.8338330035899,
        "latitude": 21.020641445461738,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3549,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8323001211377,
          21.021405075479077
        ]
      },
      "properties": {
        "tenDoiTuon": "12",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ven hồ Văn Chương",
        "tuDien": "VC1B",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "DC-06",
        "chieuCao": 11,
        "tuDien2": "Văn Chương 1B",
        "diaChiLapD": "",
        "longitude": 105.8323001211377,
        "latitude": 21.021405075479077,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3550,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83255090767838,
          21.02151148262977
        ]
      },
      "properties": {
        "tenDoiTuon": "13",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ven hồ Văn Chương",
        "tuDien": "VC1B",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "DC-06",
        "chieuCao": 11,
        "tuDien2": "Văn Chương 1B",
        "diaChiLapD": "",
        "longitude": 105.83255090767838,
        "latitude": 21.02151148262977,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3551,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83399166184353,
          21.020473696703764
        ]
      },
      "properties": {
        "tenDoiTuon": "20",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Trung Tả",
        "tuDien": "VC1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Văn Chương 1",
        "diaChiLapD": "",
        "longitude": 105.83399166184353,
        "latitude": 21.020473696703764,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3552,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83411329443369,
          21.02073408275468
        ]
      },
      "properties": {
        "tenDoiTuon": "19",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Trung Tả",
        "tuDien": "VC1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Văn Chương 1",
        "diaChiLapD": "",
        "longitude": 105.83411329443369,
        "latitude": 21.02073408275468,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3553,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83436005766275,
          21.020658971440504
        ]
      },
      "properties": {
        "tenDoiTuon": "18",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Trung Tả",
        "tuDien": "VC1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Văn Chương 1",
        "diaChiLapD": "",
        "longitude": 105.83436005766275,
        "latitude": 21.020658971440504,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3554,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83394297416123,
          21.020373548103503
        ]
      },
      "properties": {
        "tenDoiTuon": "21",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Trung Tả",
        "tuDien": "VC1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Văn Chương 1",
        "diaChiLapD": "",
        "longitude": 105.83394297416123,
        "latitude": 21.020373548103503,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3555,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83394565636897,
          21.020302192183248
        ]
      },
      "properties": {
        "tenDoiTuon": "22",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Trung Tả",
        "tuDien": "VC1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Văn Chương 1",
        "diaChiLapD": "",
        "longitude": 105.83394565636897,
        "latitude": 21.020302192183248,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3556,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83404489810034,
          21.020268391998943
        ]
      },
      "properties": {
        "tenDoiTuon": "23",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Trung Tả",
        "tuDien": "VC1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Văn Chương 1",
        "diaChiLapD": "",
        "longitude": 105.83404489810034,
        "latitude": 21.020268391998943,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3557,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.833615744664,
          21.02030845147621
        ]
      },
      "properties": {
        "tenDoiTuon": "24",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Trung Tả",
        "tuDien": "VC1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Văn Chương 1",
        "diaChiLapD": "",
        "longitude": 105.833615744664,
        "latitude": 21.02030845147621,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3558,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83350175077207,
          21.020279658728445
        ]
      },
      "properties": {
        "tenDoiTuon": "24-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Trung Tả",
        "tuDien": "VC1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Văn Chương 1",
        "diaChiLapD": "",
        "longitude": 105.83350175077207,
        "latitude": 21.020279658728445,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3559,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83360099251243,
          21.02008186491792
        ]
      },
      "properties": {
        "tenDoiTuon": "25",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Trung Tả",
        "tuDien": "VC1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Văn Chương 1",
        "diaChiLapD": "",
        "longitude": 105.83360099251243,
        "latitude": 21.02008186491792,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3560,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8336425667504,
          21.020049316543755
        ]
      },
      "properties": {
        "tenDoiTuon": "25-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Trung Tả",
        "tuDien": "VC1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Văn Chương 1",
        "diaChiLapD": "",
        "longitude": 105.8336425667504,
        "latitude": 21.020049316543755,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3561,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8337042575554,
          21.020458674419057
        ]
      },
      "properties": {
        "tenDoiTuon": "26",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Trung Tả",
        "tuDien": "VC1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Văn Chương 1",
        "diaChiLapD": "",
        "longitude": 105.8337042575554,
        "latitude": 21.020458674419057,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3562,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83465912396971,
          21.020677749272778
        ]
      },
      "properties": {
        "tenDoiTuon": "15-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Trung Tả",
        "tuDien": "VC1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Văn Chương 1",
        "diaChiLapD": "",
        "longitude": 105.83465912396971,
        "latitude": 21.020677749272778,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3563,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83459475094794,
          21.020786660652
        ]
      },
      "properties": {
        "tenDoiTuon": "14",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Trung Tả",
        "tuDien": "VC1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Văn Chương 1",
        "diaChiLapD": "",
        "longitude": 105.83459475094794,
        "latitude": 21.020786660652,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3564,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83456524665382,
          21.02101449799118
        ]
      },
      "properties": {
        "tenDoiTuon": "13",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Trung Tả",
        "tuDien": "VC1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Văn Chương 1",
        "diaChiLapD": "",
        "longitude": 105.83456524665382,
        "latitude": 21.02101449799118,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3565,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83449685032046,
          21.02093062384648
        ]
      },
      "properties": {
        "tenDoiTuon": "13-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Trung Tả",
        "tuDien": "VC1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Văn Chương 1",
        "diaChiLapD": "",
        "longitude": 105.83449685032046,
        "latitude": 21.02093062384648,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3566,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8346108442034,
          21.020910594193186
        ]
      },
      "properties": {
        "tenDoiTuon": "13-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Trung Tả",
        "tuDien": "VC1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Văn Chương 1",
        "diaChiLapD": "",
        "longitude": 105.8346108442034,
        "latitude": 21.020910594193186,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3567,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83477580006037,
          21.020883053414796
        ]
      },
      "properties": {
        "tenDoiTuon": "13-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Trung Tả",
        "tuDien": "VC1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Văn Chương 1",
        "diaChiLapD": "",
        "longitude": 105.83477580006037,
        "latitude": 21.020883053414796,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3568,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83453842455843,
          21.020427377984877
        ]
      },
      "properties": {
        "tenDoiTuon": "16",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Trung Tả",
        "tuDien": "VC1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Văn Chương 1",
        "diaChiLapD": "",
        "longitude": 105.83453842455843,
        "latitude": 21.020427377984877,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3569,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83448343927277,
          21.020220821355966
        ]
      },
      "properties": {
        "tenDoiTuon": "17",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Trung Tả",
        "tuDien": "VC1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Văn Chương 1",
        "diaChiLapD": "",
        "longitude": 105.83448343927277,
        "latitude": 21.020220821355966,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3570,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8338120376568,
          21.02203725802393
        ]
      },
      "properties": {
        "tenDoiTuon": "22",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - đèn chùm",
        "tenDuongPh": "Ven hồ Văn Chương",
        "tuDien": "VC1B",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 11,
        "tuDien2": "Văn Chương 1B",
        "diaChiLapD": "",
        "longitude": 105.8338120376568,
        "latitude": 21.02203725802393,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3571,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83364305848818,
          21.022201249493424
        ]
      },
      "properties": {
        "tenDoiTuon": "23",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - đèn chùm",
        "tenDuongPh": "Ven hồ Văn Chương",
        "tuDien": "VC1B",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 11,
        "tuDien2": "Văn Chương 1B",
        "diaChiLapD": "",
        "longitude": 105.83364305848818,
        "latitude": 21.022201249493424,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3572,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83352906460524,
          21.022099850209052
        ]
      },
      "properties": {
        "tenDoiTuon": "24",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - đèn chùm",
        "tenDuongPh": "Ven hồ Văn Chương",
        "tuDien": "VC1B",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 11,
        "tuDien2": "Văn Chương 1B",
        "diaChiLapD": "",
        "longitude": 105.83352906460524,
        "latitude": 21.022099850209052,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3573,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83324206824211,
          21.02206479858827
        ]
      },
      "properties": {
        "tenDoiTuon": "25",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - đèn chùm",
        "tenDuongPh": "Ven hồ Văn Chương",
        "tuDien": "VC1B",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 11,
        "tuDien2": "Văn Chương 1B",
        "diaChiLapD": "",
        "longitude": 105.83324206824211,
        "latitude": 21.02206479858827,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3574,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83306906575291,
          21.022003458232103
        ]
      },
      "properties": {
        "tenDoiTuon": "26",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - đèn chùm",
        "tenDuongPh": "Ven hồ Văn Chương",
        "tuDien": "VC1B",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 11,
        "tuDien2": "Văn Chương 1B",
        "diaChiLapD": "",
        "longitude": 105.83306906575291,
        "latitude": 21.022003458232103,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3575,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8330999111599,
          21.021848229464254
        ]
      },
      "properties": {
        "tenDoiTuon": "27",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - đèn chùm",
        "tenDuongPh": "Ven hồ Văn Chương",
        "tuDien": "VC1B",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 11,
        "tuDien2": "Văn Chương 1B",
        "diaChiLapD": "",
        "longitude": 105.8330999111599,
        "latitude": 21.021848229464254,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3576,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83311600441534,
          21.021698007921426
        ]
      },
      "properties": {
        "tenDoiTuon": "28",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - đèn chùm",
        "tenDuongPh": "Ven hồ Văn Chương",
        "tuDien": "VC1B",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 11,
        "tuDien2": "Văn Chương 1B",
        "diaChiLapD": "",
        "longitude": 105.83311600441534,
        "latitude": 21.021698007921426,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3577,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83309588784829,
          21.02158534166479
        ]
      },
      "properties": {
        "tenDoiTuon": "29",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - đèn chùm",
        "tenDuongPh": "Ven hồ Văn Chương",
        "tuDien": "VC1B",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 11,
        "tuDien2": "Văn Chương 1B",
        "diaChiLapD": "",
        "longitude": 105.83309588784829,
        "latitude": 21.02158534166479,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3578,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81547335549305,
          21.009091064596554
        ]
      },
      "properties": {
        "tenDoiTuon": "CĐX 59",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "N2/Thái Thịnh 1",
        "tuDien": "CĐX",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Cánh Đồng Xi",
        "diaChiLapD": "",
        "longitude": 105.81547335549305,
        "latitude": 21.009091064596554,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3579,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8156503812849,
          21.009302644443665
        ]
      },
      "properties": {
        "tenDoiTuon": "58",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "N2/Thái Thịnh 1",
        "tuDien": "CĐX",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Cánh Đồng Xi",
        "diaChiLapD": "",
        "longitude": 105.8156503812849,
        "latitude": 21.009302644443665,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3580,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81589446233312,
          21.009522987633318
        ]
      },
      "properties": {
        "tenDoiTuon": "57",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "N2/Thái Thịnh 1",
        "tuDien": "CĐX",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Cánh Đồng Xi",
        "diaChiLapD": "",
        "longitude": 105.81589446233312,
        "latitude": 21.009522987633318,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3581,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81621900959568,
          21.00975334607569
        ]
      },
      "properties": {
        "tenDoiTuon": "38",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "N2/Thái Thịnh 1",
        "tuDien": "CĐX",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Cánh Đồng Xi",
        "diaChiLapD": "",
        "longitude": 105.81621900959568,
        "latitude": 21.00975334607569,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3582,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81643358634982,
          21.010009995006673
        ]
      },
      "properties": {
        "tenDoiTuon": "36",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "N2/Thái Thịnh 1",
        "tuDien": "CĐX",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Cánh Đồng Xi",
        "diaChiLapD": "",
        "longitude": 105.81643358634982,
        "latitude": 21.010009995006673,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3583,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81659083085276,
          21.010146769927974
        ]
      },
      "properties": {
        "tenDoiTuon": "35",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "N2/Thái Thịnh 1",
        "tuDien": "CĐX",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Cánh Đồng Xi",
        "diaChiLapD": "",
        "longitude": 105.81659083085276,
        "latitude": 21.010146769927974,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3584,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81674472256464,
          21.010281666810815
        ]
      },
      "properties": {
        "tenDoiTuon": "34",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "N2/Thái Thịnh 1",
        "tuDien": "CĐX",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Cánh Đồng Xi",
        "diaChiLapD": "",
        "longitude": 105.81674472256464,
        "latitude": 21.010281666810815,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3585,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81526884924325,
          21.009227766521605
        ]
      },
      "properties": {
        "tenDoiTuon": "CĐX 60",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "N2/Thái Thịnh 1",
        "tuDien": "CĐX",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Cánh Đồng Xi",
        "diaChiLapD": "",
        "longitude": 105.81526884924325,
        "latitude": 21.009227766521605,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3586,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83523579890372,
          21.021023260958025
        ]
      },
      "properties": {
        "tenDoiTuon": "01",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Văn Chương",
        "tuDien": "VC1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "BTLT",
        "chieuCao": 7,
        "tuDien2": "Văn Chương 1",
        "diaChiLapD": "",
        "longitude": 105.83523579890372,
        "latitude": 21.021023260958025,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3587,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83558985048737,
          21.02088555712091
        ]
      },
      "properties": {
        "tenDoiTuon": "02",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Văn Chương",
        "tuDien": "VC1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "BTLT",
        "chieuCao": 7,
        "tuDien2": "Văn Chương 1",
        "diaChiLapD": "",
        "longitude": 105.83558985048737,
        "latitude": 21.02088555712091,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3588,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83583124930094,
          21.020802934757395
        ]
      },
      "properties": {
        "tenDoiTuon": "03",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Văn Chương",
        "tuDien": "VC1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "BTLT",
        "chieuCao": 7,
        "tuDien2": "Văn Chương 1",
        "diaChiLapD": "",
        "longitude": 105.83583124930094,
        "latitude": 21.020802934757395,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3589,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83612495119242,
          21.02068651225859
        ]
      },
      "properties": {
        "tenDoiTuon": "04",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Văn Chương",
        "tuDien": "VC1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "BTLT",
        "chieuCao": 7,
        "tuDien2": "Văn Chương 1",
        "diaChiLapD": "",
        "longitude": 105.83612495119242,
        "latitude": 21.02068651225859,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3590,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8363703733086,
          21.02058886751237
        ]
      },
      "properties": {
        "tenDoiTuon": "05",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 202,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Văn Chương",
        "tuDien": "VC1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "BTLT",
        "chieuCao": 7,
        "tuDien2": "Văn Chương 1",
        "diaChiLapD": "",
        "longitude": 105.8363703733086,
        "latitude": 21.02058886751237,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3591,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83664261752917,
          21.02047870413138
        ]
      },
      "properties": {
        "tenDoiTuon": "06",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 202,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Văn Chương",
        "tuDien": "VC1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "BTLT",
        "chieuCao": 7,
        "tuDien2": "Văn Chương 1",
        "diaChiLapD": "",
        "longitude": 105.83664261752917,
        "latitude": 21.02047870413138,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3592,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83722331578379,
          21.02019828788719
        ]
      },
      "properties": {
        "tenDoiTuon": "08",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 202,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Văn Chương",
        "tuDien": "VC1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "BTLT",
        "chieuCao": 7,
        "tuDien2": "Văn Chương 1",
        "diaChiLapD": "",
        "longitude": 105.83722331578379,
        "latitude": 21.02019828788719,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3593,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83745666796514,
          21.020078109335135
        ]
      },
      "properties": {
        "tenDoiTuon": "09",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 202,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Văn Chương",
        "tuDien": "VC1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "BTLT",
        "chieuCao": 7,
        "tuDien2": "Văn Chương 1",
        "diaChiLapD": "",
        "longitude": 105.83745666796514,
        "latitude": 21.020078109335135,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3594,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8375559096965,
          21.01972758800448
        ]
      },
      "properties": {
        "tenDoiTuon": "10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 202,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Nhà H1 TT Văn Chương",
        "tuDien": "VC1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Văn Chương 1",
        "diaChiLapD": "",
        "longitude": 105.8375559096965,
        "latitude": 21.01972758800448,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3595,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83716028386591,
          21.01925688663674
        ]
      },
      "properties": {
        "tenDoiTuon": "12",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 202,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Văn Chương",
        "tuDien": "VC1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Văn Chương 1",
        "diaChiLapD": "",
        "longitude": 105.83716028386591,
        "latitude": 21.01925688663674,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3596,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83735876733765,
          21.01949599310223
        ]
      },
      "properties": {
        "tenDoiTuon": "11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 202,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Văn Chương",
        "tuDien": "VC1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Văn Chương 1",
        "diaChiLapD": "",
        "longitude": 105.83735876733765,
        "latitude": 21.01949599310223,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3597,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81145976499892,
          21.017042316909492
        ]
      },
      "properties": {
        "tenDoiTuon": "13",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 47 Nguyên Hồng",
        "tuDien": "B24TC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "B24 Thành Công",
        "diaChiLapD": "",
        "longitude": 105.81145976499892,
        "latitude": 21.017042316909492,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3598,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81147317604659,
          21.01693715845655
        ]
      },
      "properties": {
        "tenDoiTuon": "14",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 47/2 Nguyên Hồng",
        "tuDien": "B24TC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "B24 Thành Công",
        "diaChiLapD": "",
        "longitude": 105.81147317604659,
        "latitude": 21.01693715845655,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3599,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8112693281594,
          21.016599148639344
        ]
      },
      "properties": {
        "tenDoiTuon": "16",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 47/2 Nguyên Hồng",
        "tuDien": "B24TC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "B24 Thành Công",
        "diaChiLapD": "",
        "longitude": 105.8112693281594,
        "latitude": 21.016599148639344,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3600,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81125859931944,
          21.016491486092374
        ]
      },
      "properties": {
        "tenDoiTuon": "B24TC 17",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 47/2 Nguyên Hồng",
        "tuDien": "B24TC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "B24 Thành Công",
        "diaChiLapD": "",
        "longitude": 105.81125859931944,
        "latitude": 21.016491486092374,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3601,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81143562512025,
          21.016383823468534
        ]
      },
      "properties": {
        "tenDoiTuon": "18",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 36 Hoàng Ngọc Phách",
        "tuDien": "B24TC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "B24 Thành Công",
        "diaChiLapD": "",
        "longitude": 105.81143562512025,
        "latitude": 21.016383823468534,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3602,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81174407915431,
          21.016887082975963
        ]
      },
      "properties": {
        "tenDoiTuon": "27",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 47 Nguyên Hồng",
        "tuDien": "B24TC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "B24 Thành Công",
        "diaChiLapD": "",
        "longitude": 105.81174407915431,
        "latitude": 21.016887082975963,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3603,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81160460428886,
          21.016641712880574
        ]
      },
      "properties": {
        "tenDoiTuon": "25",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 36 Hoàng Ngọc Phách",
        "tuDien": "B24TC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "B24 Thành Công",
        "diaChiLapD": "",
        "longitude": 105.81160460428886,
        "latitude": 21.016641712880574,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3604,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81153486685162,
          21.01653405036517
        ]
      },
      "properties": {
        "tenDoiTuon": "25-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 36 Hoàng Ngọc Phách",
        "tuDien": "B24TC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "B24 Thành Công",
        "diaChiLapD": "",
        "longitude": 105.81153486685162,
        "latitude": 21.01653405036517,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3605,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81157778219345,
          21.016311213746796
        ]
      },
      "properties": {
        "tenDoiTuon": "19",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 10 Hoàng Ngọc Phách",
        "tuDien": "B24TC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "B24 Thành Công",
        "diaChiLapD": "",
        "longitude": 105.81157778219345,
        "latitude": 21.016311213746796,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3606,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81181381659152,
          21.016171001772094
        ]
      },
      "properties": {
        "tenDoiTuon": "20",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 10 Hoàng Ngọc Phách",
        "tuDien": "B24TC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "B24 Thành Công",
        "diaChiLapD": "",
        "longitude": 105.81181381659152,
        "latitude": 21.016171001772094,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3607,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81198547796787,
          21.016135948757604
        ]
      },
      "properties": {
        "tenDoiTuon": "21",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 10 Hoàng Ngọc Phách",
        "tuDien": "B24TC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "B24 Thành Công",
        "diaChiLapD": "",
        "longitude": 105.81198547796787,
        "latitude": 21.016135948757604,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3608,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81212495283333,
          21.01636128942077
        ]
      },
      "properties": {
        "tenDoiTuon": "B24TC 22",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 10 Hoàng Ngọc Phách",
        "tuDien": "B24TC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "B24 Thành Công",
        "diaChiLapD": "",
        "longitude": 105.81212495283333,
        "latitude": 21.01636128942077,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3609,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81172798589887,
          21.01655658438598
        ]
      },
      "properties": {
        "tenDoiTuon": "24",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 10 Hoàng Ngọc Phách",
        "tuDien": "B24TC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "B24 Thành Công",
        "diaChiLapD": "",
        "longitude": 105.81172798589887,
        "latitude": 21.01655658438598,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3610,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.811953291457,
          21.016426387771183
        ]
      },
      "properties": {
        "tenDoiTuon": "23",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 10 Hoàng Ngọc Phách",
        "tuDien": "B24TC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "B24 Thành Công",
        "diaChiLapD": "",
        "longitude": 105.811953291457,
        "latitude": 21.016426387771183,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3611,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81137661652296,
          21.01678192441357
        ]
      },
      "properties": {
        "tenDoiTuon": "15",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 47/2 Nguyên Hồng",
        "tuDien": "B24TC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "B24 Thành Công",
        "diaChiLapD": "",
        "longitude": 105.81137661652296,
        "latitude": 21.01678192441357,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3612,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81171993927565,
          21.01682198482661
        ]
      },
      "properties": {
        "tenDoiTuon": "26",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 36 Hoàng Ngọc Phách",
        "tuDien": "B24TC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "B24 Thành Công",
        "diaChiLapD": "",
        "longitude": 105.81171993927565,
        "latitude": 21.01682198482661,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3613,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81202034668648,
          21.01674436776622
        ]
      },
      "properties": {
        "tenDoiTuon": "30",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 47 Nguyên Hồng",
        "tuDien": "B24TC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "B24 Thành Công",
        "diaChiLapD": "",
        "longitude": 105.81202034668648,
        "latitude": 21.01674436776622,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3614,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81221346573375,
          21.016639209102358
        ]
      },
      "properties": {
        "tenDoiTuon": "45",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 47 Nguyên Hồng",
        "tuDien": "B24TC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "B24 Thành Công",
        "diaChiLapD": "",
        "longitude": 105.81221346573375,
        "latitude": 21.016639209102358,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3615,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81245218233057,
          21.016596644860417
        ]
      },
      "properties": {
        "tenDoiTuon": "46",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 8A+8B Hoàng Ngọc Phách",
        "tuDien": "B24TC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "B24 Thành Công",
        "diaChiLapD": "",
        "longitude": 105.81245218233057,
        "latitude": 21.016596644860417,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3616,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81272040323952,
          21.016451425590507
        ]
      },
      "properties": {
        "tenDoiTuon": "47",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 8A+8B Hoàng Ngọc Phách",
        "tuDien": "B24TC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "B24 Thành Công",
        "diaChiLapD": "",
        "longitude": 105.81272040323952,
        "latitude": 21.016451425590507,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3617,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81286792472822,
          21.01661917887262
        ]
      },
      "properties": {
        "tenDoiTuon": "48",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 8A+8B Hoàng Ngọc Phách",
        "tuDien": "B24TC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "B24 Thành Công",
        "diaChiLapD": "",
        "longitude": 105.81286792472822,
        "latitude": 21.01661917887262,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3618,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8127391787027,
          21.01624861913175
        ]
      },
      "properties": {
        "tenDoiTuon": "49",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 8A+8B Hoàng Ngọc Phách",
        "tuDien": "B24TC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "B24 Thành Công",
        "diaChiLapD": "",
        "longitude": 105.8127391787027,
        "latitude": 21.01624861913175,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3619,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8128947468236,
          21.016020774509748
        ]
      },
      "properties": {
        "tenDoiTuon": "B24TC 50",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 8A+8B Hoàng Ngọc Phách",
        "tuDien": "B24TC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "B24 Thành Công",
        "diaChiLapD": "",
        "longitude": 105.8128947468236,
        "latitude": 21.016020774509748,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3620,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81261847929143,
          21.016068346492606
        ]
      },
      "properties": {
        "tenDoiTuon": "51",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 8A+8B Hoàng Ngọc Phách",
        "tuDien": "B24TC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "B24 Thành Công",
        "diaChiLapD": "",
        "longitude": 105.81261847929143,
        "latitude": 21.016068346492606,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3621,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81244950012282,
          21.015825478842896
        ]
      },
      "properties": {
        "tenDoiTuon": "B24TC 52",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 8A+8B Hoàng Ngọc Phách",
        "tuDien": "B24TC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "B24 Thành Công",
        "diaChiLapD": "",
        "longitude": 105.81244950012282,
        "latitude": 21.015825478842896,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3622,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81256215290189,
          21.016754382872595
        ]
      },
      "properties": {
        "tenDoiTuon": "44",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 9 Huỳnh Thúc Kháng",
        "tuDien": "B24TC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 6,
        "tuDien2": "B24 Thành Công",
        "diaChiLapD": "",
        "longitude": 105.81256215290189,
        "latitude": 21.016754382872595,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3623,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81242536024418,
          21.016837007479374
        ]
      },
      "properties": {
        "tenDoiTuon": "43",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 9 Huỳnh Thúc Kháng",
        "tuDien": "B24TC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "B24 Thành Công",
        "diaChiLapD": "",
        "longitude": 105.81242536024418,
        "latitude": 21.016837007479374,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3624,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81275258974141,
          21.016912120717947
        ]
      },
      "properties": {
        "tenDoiTuon": "42",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 9 Huỳnh Thúc Kháng",
        "tuDien": "B24TC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "B24 Thành Công",
        "diaChiLapD": "",
        "longitude": 105.81275258974141,
        "latitude": 21.016912120717947,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3625,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81304226832128,
          21.017382829487982
        ]
      },
      "properties": {
        "tenDoiTuon": "B24TC 38",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 9 Huỳnh Thúc Kháng",
        "tuDien": "B24TC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "B24 Thành Công",
        "diaChiLapD": "",
        "longitude": 105.81304226832128,
        "latitude": 21.017382829487982,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3626,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81277672962906,
          21.017037309365612
        ]
      },
      "properties": {
        "tenDoiTuon": "34",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 45 Nguyên Hồng",
        "tuDien": "B24TC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "B24 Thành Công",
        "diaChiLapD": "",
        "longitude": 105.81277672962906,
        "latitude": 21.017037309365612,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3627,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81296716646858,
          21.01714246774877
        ]
      },
      "properties": {
        "tenDoiTuon": "39",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 5 Huỳnh Thúc Kháng",
        "tuDien": "B24TC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 6,
        "tuDien2": "B24 Thành Công",
        "diaChiLapD": "",
        "longitude": 105.81296716646858,
        "latitude": 21.01714246774877,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3628,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81292156891,
          21.017084881024193
        ]
      },
      "properties": {
        "tenDoiTuon": "37",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 9 Huỳnh Thúc Kháng",
        "tuDien": "B24TC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "B24 Thành Công",
        "diaChiLapD": "",
        "longitude": 105.81292156891,
        "latitude": 21.017084881024193,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3629,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.813179060979,
          21.017029798049943
        ]
      },
      "properties": {
        "tenDoiTuon": "40",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 5 Huỳnh Thúc Kháng",
        "tuDien": "B24TC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 6,
        "tuDien2": "B24 Thành Công",
        "diaChiLapD": "",
        "longitude": 105.813179060979,
        "latitude": 21.017029798049943,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3630,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81306104378444,
          21.016771909308186
        ]
      },
      "properties": {
        "tenDoiTuon": "B24TC 41",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 5 Huỳnh Thúc Kháng",
        "tuDien": "B24TC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 6,
        "tuDien2": "B24 Thành Công",
        "diaChiLapD": "",
        "longitude": 105.81306104378444,
        "latitude": 21.016771909308186,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3631,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81234221176824,
          21.017257641130357
        ]
      },
      "properties": {
        "tenDoiTuon": "33",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 45 Nguyên Hồng",
        "tuDien": "B24TC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "B24 Thành Công",
        "diaChiLapD": "",
        "longitude": 105.81234221176824,
        "latitude": 21.017257641130357,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3632,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81244681791509,
          21.016999752782606
        ]
      },
      "properties": {
        "tenDoiTuon": "36",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ15 Huỳnh Thúc Kháng",
        "tuDien": "B24TC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 6,
        "tuDien2": "B24 Thành Công",
        "diaChiLapD": "",
        "longitude": 105.81244681791509,
        "latitude": 21.016999752782606,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3633,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81266407684997,
          21.017375318189707
        ]
      },
      "properties": {
        "tenDoiTuon": "B24TC 35",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ15 Huỳnh Thúc Kháng",
        "tuDien": "B24TC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 6,
        "tuDien2": "B24 Thành Công",
        "diaChiLapD": "",
        "longitude": 105.81266407684997,
        "latitude": 21.017375318189707,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3634,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81232075408833,
          21.017207565758202
        ]
      },
      "properties": {
        "tenDoiTuon": "32",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ17 Huỳnh Thúc Kháng",
        "tuDien": "B24TC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "B24 Thành Công",
        "diaChiLapD": "",
        "longitude": 105.81232075408833,
        "latitude": 21.017207565758202,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3635,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81216786817517,
          21.016947173549973
        ]
      },
      "properties": {
        "tenDoiTuon": "31",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ17 Huỳnh Thúc Kháng",
        "tuDien": "B24TC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "B24 Thành Công",
        "diaChiLapD": "",
        "longitude": 105.81216786817517,
        "latitude": 21.016947173549973,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3636,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81224833445235,
          21.017077369710904
        ]
      },
      "properties": {
        "tenDoiTuon": "32-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ17 Huỳnh Thúc Kháng",
        "tuDien": "B24TC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "B24 Thành Công",
        "diaChiLapD": "",
        "longitude": 105.81224833445235,
        "latitude": 21.017077369710904,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3637,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81206057982058,
          21.017377821955566
        ]
      },
      "properties": {
        "tenDoiTuon": "29",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 45 Nguyên Hồng",
        "tuDien": "B24TC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "B24 Thành Công",
        "diaChiLapD": "",
        "longitude": 105.81206057982058,
        "latitude": 21.017377821955566,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3638,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81189696506746,
          21.017097399879116
        ]
      },
      "properties": {
        "tenDoiTuon": "28",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 45 Nguyên Hồng",
        "tuDien": "B24TC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "B24 Thành Công",
        "diaChiLapD": "",
        "longitude": 105.81189696506746,
        "latitude": 21.017097399879116,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3639,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81255947069415,
          21.01760065697931
        ]
      },
      "properties": {
        "tenDoiTuon": "B24TC 01",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ17 Huỳnh Thúc Kháng",
        "tuDien": "B24TC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "B24 Thành Công",
        "diaChiLapD": "",
        "longitude": 105.81255947069415,
        "latitude": 21.01760065697931,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3640,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81244950012282,
          21.017415378443374
        ]
      },
      "properties": {
        "tenDoiTuon": "02",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ17 Huỳnh Thúc Kháng",
        "tuDien": "B24TC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "B24 Thành Công",
        "diaChiLapD": "",
        "longitude": 105.81244950012282,
        "latitude": 21.017415378443374,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3641,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81224028782013,
          21.017573115590377
        ]
      },
      "properties": {
        "tenDoiTuon": "03",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ17 Huỳnh Thúc Kháng",
        "tuDien": "B24TC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "B24 Thành Công",
        "diaChiLapD": "",
        "longitude": 105.81224028782013,
        "latitude": 21.017573115590377,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3642,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81196670250469,
          21.017718333767995
        ]
      },
      "properties": {
        "tenDoiTuon": "04",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ17 Huỳnh Thúc Kháng",
        "tuDien": "B24TC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "B24 Thành Công",
        "diaChiLapD": "",
        "longitude": 105.81196670250469,
        "latitude": 21.017718333767995,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3643,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81184600309342,
          21.017535559141464
        ]
      },
      "properties": {
        "tenDoiTuon": "05",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 45 Nguyên Hồng",
        "tuDien": "B24TC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "B24 Thành Công",
        "diaChiLapD": "",
        "longitude": 105.81184600309342,
        "latitude": 21.017535559141464,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3644,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81162438558665,
          21.017308029453513
        ]
      },
      "properties": {
        "tenDoiTuon": "06",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 45 Nguyên Hồng",
        "tuDien": "B24TC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "B24 Thành Công",
        "diaChiLapD": "",
        "longitude": 105.81162438558665,
        "latitude": 21.017308029453513,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3645,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81093941644542,
          21.016809465948594
        ]
      },
      "properties": {
        "tenDoiTuon": "B24TC 11-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 28 Nguyên Hồng",
        "tuDien": "B24TC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 6,
        "tuDien2": "B24 Thành Công",
        "diaChiLapD": "",
        "longitude": 105.81093941644542,
        "latitude": 21.016809465948594,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3646,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82287792874651,
          21.0100635156532
        ]
      },
      "properties": {
        "tenDoiTuon": "TH7 21",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Tây Sơn",
        "tuDien": "TH7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 11,
        "tuDien2": "TH7",
        "diaChiLapD": "",
        "longitude": 105.82287792874651,
        "latitude": 21.0100635156532,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3647,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82305897785895,
          21.01045161793869
        ]
      },
      "properties": {
        "tenDoiTuon": "TH7 21-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 16 + 48 Thái Hà",
        "tuDien": "TH7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TH7",
        "diaChiLapD": "",
        "longitude": 105.82305897785895,
        "latitude": 21.01045161793869,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3648,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82290743304964,
          21.01051922275105
        ]
      },
      "properties": {
        "tenDoiTuon": "TH7 21-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 16 + 48 Thái Hà",
        "tuDien": "TH7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TH7",
        "diaChiLapD": "",
        "longitude": 105.82290743304964,
        "latitude": 21.01051922275105,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3649,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82268749191596,
          21.010742068021923
        ]
      },
      "properties": {
        "tenDoiTuon": "TH7 21-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 16 + 48 Thái Hà",
        "tuDien": "TH7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TH7",
        "diaChiLapD": "",
        "longitude": 105.82268749191596,
        "latitude": 21.010742068021923,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3650,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82259093239234,
          21.010899812225762
        ]
      },
      "properties": {
        "tenDoiTuon": "TH7 21-7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 16 + 48 Thái Hà",
        "tuDien": "TH7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TH7",
        "diaChiLapD": "",
        "longitude": 105.82259093239234,
        "latitude": 21.010899812225762,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3651,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82324807359457,
          21.010634401247128
        ]
      },
      "properties": {
        "tenDoiTuon": "TH7 21-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 16 + 48 Thái Hà",
        "tuDien": "TH7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TH7",
        "diaChiLapD": "",
        "longitude": 105.82324807359457,
        "latitude": 21.010634401247128,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3652,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82318101837406,
          21.010819688206727
        ]
      },
      "properties": {
        "tenDoiTuon": "TH7 21-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 16 + 48 Thái Hà",
        "tuDien": "TH7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TH7",
        "diaChiLapD": "",
        "longitude": 105.82318101837406,
        "latitude": 21.010819688206727,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3653,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82300935699773,
          21.010959905211998
        ]
      },
      "properties": {
        "tenDoiTuon": "TH7 21-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 16 + 48 Thái Hà",
        "tuDien": "TH7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TH7",
        "diaChiLapD": "",
        "longitude": 105.82300935699773,
        "latitude": 21.010959905211998,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3654,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82364235832128,
          21.010213748917277
        ]
      },
      "properties": {
        "tenDoiTuon": "TH7 22-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 2 Thái Hà",
        "tuDien": "TH7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TH7",
        "diaChiLapD": "",
        "longitude": 105.82364235832128,
        "latitude": 21.010213748917277,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3655,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82349215461588,
          21.010163671180226
        ]
      },
      "properties": {
        "tenDoiTuon": "TH7 22-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 2 Thái Hà",
        "tuDien": "TH7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TH7",
        "diaChiLapD": "",
        "longitude": 105.82349215461588,
        "latitude": 21.010163671180226,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3656,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82343046381085,
          21.010223764463003
        ]
      },
      "properties": {
        "tenDoiTuon": "TH7 22-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 2 Thái Hà",
        "tuDien": "TH7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TH7",
        "diaChiLapD": "",
        "longitude": 105.82343046381085,
        "latitude": 21.010223764463003,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3657,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8232909889364,
          21.00977056035782
        ]
      },
      "properties": {
        "tenDoiTuon": "TH7 22",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Tây Sơn",
        "tuDien": "TH7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 11,
        "tuDien2": "TH7",
        "diaChiLapD": "",
        "longitude": 105.8232909889364,
        "latitude": 21.00977056035782,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3658,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82352165891899,
          21.009647869507386
        ]
      },
      "properties": {
        "tenDoiTuon": "TH7 23",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Tây Sơn",
        "tuDien": "TH7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 11,
        "tuDien2": "TH7",
        "diaChiLapD": "",
        "longitude": 105.82352165891899,
        "latitude": 21.009647869507386,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3659,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82377646877127,
          21.00952267465755
        ]
      },
      "properties": {
        "tenDoiTuon": "TH7 24",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Tây Sơn",
        "tuDien": "TH7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 11,
        "tuDien2": "TH7",
        "diaChiLapD": "",
        "longitude": 105.82377646877127,
        "latitude": 21.00952267465755,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3660,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82301203920548,
          21.00966539677803
        ]
      },
      "properties": {
        "tenDoiTuon": "TH7 03",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 3 Thái Hà",
        "tuDien": "TH7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TH7",
        "diaChiLapD": "",
        "longitude": 105.82301203920548,
        "latitude": 21.00966539677803,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3661,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8227974624783,
          21.009415007002683
        ]
      },
      "properties": {
        "tenDoiTuon": "TH7 02",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 3 Thái Hà",
        "tuDien": "TH7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TH7",
        "diaChiLapD": "",
        "longitude": 105.8227974624783,
        "latitude": 21.009415007002683,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3662,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82262580110198,
          21.009257261228825
        ]
      },
      "properties": {
        "tenDoiTuon": "TH7 01",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 3 Thái Hà",
        "tuDien": "TH7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TH7",
        "diaChiLapD": "",
        "longitude": 105.82262580110198,
        "latitude": 21.009257261228825,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3663,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82240854217605,
          21.00936242509631
        ]
      },
      "properties": {
        "tenDoiTuon": "TH7 27-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 3 Thái Hà",
        "tuDien": "TH7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TH7",
        "diaChiLapD": "",
        "longitude": 105.82240854217605,
        "latitude": 21.00936242509631,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3664,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82202766849704,
          21.009655381194822
        ]
      },
      "properties": {
        "tenDoiTuon": "TH7 27-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 3 Thái Hà",
        "tuDien": "TH7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TH7",
        "diaChiLapD": "",
        "longitude": 105.82202766849704,
        "latitude": 21.009655381194822,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3665,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82178358747574,
          21.00937744850038
        ]
      },
      "properties": {
        "tenDoiTuon": "TH7 27-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 3 Thái Hà",
        "tuDien": "TH7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TH7",
        "diaChiLapD": "",
        "longitude": 105.82178358747574,
        "latitude": 21.00937744850038,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3666,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82173262550168,
          21.009324866580776
        ]
      },
      "properties": {
        "tenDoiTuon": "TH7 27-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 3 Thái Hà",
        "tuDien": "TH7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TH7",
        "diaChiLapD": "",
        "longitude": 105.82173262550168,
        "latitude": 21.009324866580776,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3667,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82159583284395,
          21.00914208166869
        ]
      },
      "properties": {
        "tenDoiTuon": "TH7 27-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 3 Thái Hà",
        "tuDien": "TH7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TH7",
        "diaChiLapD": "",
        "longitude": 105.82159583284395,
        "latitude": 21.00914208166869,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3668,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8213973493902,
          21.008952410778377
        ]
      },
      "properties": {
        "tenDoiTuon": "TH7 32",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 3 Thái Hà",
        "tuDien": "TH7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TH7",
        "diaChiLapD": "",
        "longitude": 105.8213973493902,
        "latitude": 21.008952410778377,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3669,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82118277267203,
          21.008779641052637
        ]
      },
      "properties": {
        "tenDoiTuon": "TH7 32-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 3 Thái Hà",
        "tuDien": "TH7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TH7",
        "diaChiLapD": "",
        "longitude": 105.82118277267203,
        "latitude": 21.008779641052637,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3670,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82148351537904,
          21.00881313084745
        ]
      },
      "properties": {
        "tenDoiTuon": "TH7 31-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 3 Thái Hà",
        "tuDien": "TH7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TH7",
        "diaChiLapD": "",
        "longitude": 105.82148351537904,
        "latitude": 21.00881313084745,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3671,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82130347207432,
          21.00861187895371
        ]
      },
      "properties": {
        "tenDoiTuon": "TH7 31-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 3 Thái Hà",
        "tuDien": "TH7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TH7",
        "diaChiLapD": "",
        "longitude": 105.82130347207432,
        "latitude": 21.00861187895371,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3672,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8216414304115,
          21.008684492422564
        ]
      },
      "properties": {
        "tenDoiTuon": "TH7 31",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 3 Thái Hà",
        "tuDien": "TH7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TH7",
        "diaChiLapD": "",
        "longitude": 105.8216414304115,
        "latitude": 21.008684492422564,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3673,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82180772735438,
          21.008511096417337
        ]
      },
      "properties": {
        "tenDoiTuon": "TH7 30",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 3 Thái Hà",
        "tuDien": "TH7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TH7",
        "diaChiLapD": "",
        "longitude": 105.82180772735438,
        "latitude": 21.008511096417337,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3674,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82149390890484,
          21.008380892779915
        ]
      },
      "properties": {
        "tenDoiTuon": "TH7 30-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 3 Thái Hà",
        "tuDien": "TH7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TH7",
        "diaChiLapD": "",
        "longitude": 105.82149390890484,
        "latitude": 21.008380892779915,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3675,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82160924389164,
          21.00825319294703
        ]
      },
      "properties": {
        "tenDoiTuon": "TH7 30-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 3 Thái Hà",
        "tuDien": "TH7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TH7",
        "diaChiLapD": "",
        "longitude": 105.82160924389164,
        "latitude": 21.00825319294703,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3676,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82201425744934,
          21.008751472065867
        ]
      },
      "properties": {
        "tenDoiTuon": "TH7 29",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 3 Thái Hà",
        "tuDien": "TH7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TH7",
        "diaChiLapD": "",
        "longitude": 105.82201425744934,
        "latitude": 21.008751472065867,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3677,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82180504514665,
          21.008944273087312
        ]
      },
      "properties": {
        "tenDoiTuon": "TH7 29-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 3 Thái Hà",
        "tuDien": "TH7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TH7",
        "diaChiLapD": "",
        "longitude": 105.82180504514665,
        "latitude": 21.008944273087312,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3678,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82225029184742,
          21.008974319977305
        ]
      },
      "properties": {
        "tenDoiTuon": "TH7 28",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 3 Thái Hà",
        "tuDien": "TH7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TH7",
        "diaChiLapD": "",
        "longitude": 105.82225029184742,
        "latitude": 21.008974319977305,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3679,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82210813476523,
          21.009094507476807
        ]
      },
      "properties": {
        "tenDoiTuon": "TH7 28-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 3 Thái Hà",
        "tuDien": "TH7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TH7",
        "diaChiLapD": "",
        "longitude": 105.82210813476523,
        "latitude": 21.009094507476807,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3680,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82254533483378,
          21.009322362678912
        ]
      },
      "properties": {
        "tenDoiTuon": "TH7 27",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 3 Thái Hà",
        "tuDien": "TH7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TH7",
        "diaChiLapD": "",
        "longitude": 105.82254533483378,
        "latitude": 21.009322362678912,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3681,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82267676307603,
          21.009021894207503
        ]
      },
      "properties": {
        "tenDoiTuon": "TH7 04",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 252 Tây Sơn",
        "tuDien": "TH7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TH7",
        "diaChiLapD": "",
        "longitude": 105.82267676307603,
        "latitude": 21.009021894207503,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3682,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82274381830551,
          21.009092003571904
        ]
      },
      "properties": {
        "tenDoiTuon": "TH7 4-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "N252/59,85,10,109,92,52",
        "tuDien": "TH7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TH7",
        "diaChiLapD": "",
        "longitude": 105.82274381830551,
        "latitude": 21.009092003571904,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3683,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82241122438381,
          21.008864148018006
        ]
      },
      "properties": {
        "tenDoiTuon": "TH7 4-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 252/53 Tây Sơn",
        "tuDien": "TH7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TH7",
        "diaChiLapD": "",
        "longitude": 105.82241122438381,
        "latitude": 21.008864148018006,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3684,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82264994098963,
          21.00887666756287
        ]
      },
      "properties": {
        "tenDoiTuon": "TH7 4-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "N252/59,85,10,109,92,52",
        "tuDien": "TH7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TH7",
        "diaChiLapD": "",
        "longitude": 105.82264994098963,
        "latitude": 21.00887666756287,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3685,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82256947471245,
          21.00892674573278
        ]
      },
      "properties": {
        "tenDoiTuon": "TH7 4-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 252/53 Tây Sơn",
        "tuDien": "TH7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TH7",
        "diaChiLapD": "",
        "longitude": 105.82256947471245,
        "latitude": 21.00892674573278,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3686,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82217250777798,
          21.008626276464618
        ]
      },
      "properties": {
        "tenDoiTuon": "TH7 4-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 252/53 Tây Sơn",
        "tuDien": "TH7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TH7",
        "diaChiLapD": "",
        "longitude": 105.82217250777798,
        "latitude": 21.008626276464618,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3687,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82280014469504,
          21.008784022905584
        ]
      },
      "properties": {
        "tenDoiTuon": "TH7 05",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 252 Tây Sơn",
        "tuDien": "TH7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TH7",
        "diaChiLapD": "",
        "longitude": 105.82280014469504,
        "latitude": 21.008784022905584,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3688,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82288865758646,
          21.008914226191262
        ]
      },
      "properties": {
        "tenDoiTuon": "TH7 5-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "N252/59,85,10,109,92,52",
        "tuDien": "TH7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TH7",
        "diaChiLapD": "",
        "longitude": 105.82288865758646,
        "latitude": 21.008914226191262,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3689,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82289402201093,
          21.00879654245724
        ]
      },
      "properties": {
        "tenDoiTuon": "TH7 5-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "N252/59,85,10,109,92,52",
        "tuDien": "TH7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TH7",
        "diaChiLapD": "",
        "longitude": 105.82289402201093,
        "latitude": 21.00879654245724,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3690,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82250241949195,
          21.008653819505536
        ]
      },
      "properties": {
        "tenDoiTuon": "TH7 5-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "N252/59,85,10,109,92,52",
        "tuDien": "TH7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TH7",
        "diaChiLapD": "",
        "longitude": 105.82250241949195,
        "latitude": 21.008653819505536,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3691,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82289133979421,
          21.008678858629583
        ]
      },
      "properties": {
        "tenDoiTuon": "TH7 07",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 252 Tây Sơn",
        "tuDien": "TH7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TH7",
        "diaChiLapD": "",
        "longitude": 105.82289133979421,
        "latitude": 21.008678858629583,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3692,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82295839502368,
          21.00857369428025
        ]
      },
      "properties": {
        "tenDoiTuon": "TH7 06",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 252 Tây Sơn",
        "tuDien": "TH7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TH7",
        "diaChiLapD": "",
        "longitude": 105.82295839502368,
        "latitude": 21.00857369428025,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3693,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82304422570735,
          21.008338326181374
        ]
      },
      "properties": {
        "tenDoiTuon": "TH7 08",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 252 Tây Sơn",
        "tuDien": "TH7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TH7",
        "diaChiLapD": "",
        "longitude": 105.82304422570735,
        "latitude": 21.008338326181374,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3694,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83698594028183,
          21.020314710767227
        ]
      },
      "properties": {
        "tenDoiTuon": "7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 202,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Văn Chương",
        "tuDien": "VC1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "BTLT",
        "chieuCao": 7,
        "tuDien2": "Văn Chương 1",
        "diaChiLapD": "",
        "longitude": 105.83698594028183,
        "latitude": 21.020314710767227,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3695,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8366050666028,
          21.020946897936174
        ]
      },
      "properties": {
        "tenDoiTuon": "6-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 253 Văn CHương",
        "tuDien": "VC1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Văn Chương 1",
        "diaChiLapD": "",
        "longitude": 105.8366050666028,
        "latitude": 21.020946897936174,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3696,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83654203469392,
          21.02104704615301
        ]
      },
      "properties": {
        "tenDoiTuon": "6-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 253 Văn CHương",
        "tuDien": "VC1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Văn Chương 1",
        "diaChiLapD": "",
        "longitude": 105.83654203469392,
        "latitude": 21.02104704615301,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3697,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83613141083389,
          21.020961294247996
        ]
      },
      "properties": {
        "tenDoiTuon": "4-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 253 Văn CHương",
        "tuDien": "VC1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Văn Chương 1",
        "diaChiLapD": "",
        "longitude": 105.83613141083389,
        "latitude": 21.020961294247996,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3698,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83595170282534,
          21.021260486816104
        ]
      },
      "properties": {
        "tenDoiTuon": "4-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 253 Văn CHương",
        "tuDien": "VC1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Văn Chương 1",
        "diaChiLapD": "",
        "longitude": 105.83595170282534,
        "latitude": 21.021260486816104,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3699,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83574114941878,
          21.021410708799735
        ]
      },
      "properties": {
        "tenDoiTuon": "4-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 253 Văn CHương",
        "tuDien": "VC1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Văn Chương 1",
        "diaChiLapD": "",
        "longitude": 105.83574114941878,
        "latitude": 21.021410708799735,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3700,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83613141083389,
          21.020599508289944
        ]
      },
      "properties": {
        "tenDoiTuon": "4-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 202,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Nhà H1 TT Văn Chương",
        "tuDien": "VC1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Văn Chương 1",
        "diaChiLapD": "",
        "longitude": 105.83613141083389,
        "latitude": 21.020599508289944,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3701,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83638085627068,
          21.02049059677403
        ]
      },
      "properties": {
        "tenDoiTuon": "5-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 202,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Nhà H1 TT Văn Chương",
        "tuDien": "VC1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Văn Chương 1",
        "diaChiLapD": "",
        "longitude": 105.83638085627068,
        "latitude": 21.02049059677403,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3702,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83656458759084,
          21.02040421861877
        ]
      },
      "properties": {
        "tenDoiTuon": "5-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 202,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Nhà H1 TT Văn Chương",
        "tuDien": "VC1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Văn Chương 1",
        "diaChiLapD": "",
        "longitude": 105.83656458759084,
        "latitude": 21.02040421861877,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3703,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83569823407694,
          21.021041412818604
        ]
      },
      "properties": {
        "tenDoiTuon": "2-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 247 Văn CHương",
        "tuDien": "VC1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Văn Chương 1",
        "diaChiLapD": "",
        "longitude": 105.83569823407694,
        "latitude": 21.021041412818604,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3704,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83663834833966,
          21.020705916015565
        ]
      },
      "properties": {
        "tenDoiTuon": "6-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 253 Văn CHương",
        "tuDien": "VC1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Văn Chương 1",
        "diaChiLapD": "",
        "longitude": 105.83663834833966,
        "latitude": 21.020705916015565,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3705,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83603216909354,
          21.021104005421808
        ]
      },
      "properties": {
        "tenDoiTuon": "4-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Văn Chương",
        "tuDien": "VC1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Văn Chương 1",
        "diaChiLapD": "",
        "longitude": 105.83603216909354,
        "latitude": 21.021104005421808,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3706,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82267944531071,
          21.008233787558215
        ]
      },
      "properties": {
        "tenDoiTuon": "TH7 5-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 252 Tây Sơn",
        "tuDien": "TH7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TH7",
        "diaChiLapD": "",
        "longitude": 105.82267944531071,
        "latitude": 21.008233787558215,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3707,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82249169067894,
          21.008238795397627
        ]
      },
      "properties": {
        "tenDoiTuon": "TH7 5-1-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "N252/59,85,10,109,92,52",
        "tuDien": "TH7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TH7",
        "diaChiLapD": "",
        "longitude": 105.82249169067894,
        "latitude": 21.008238795397627,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3708,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82283501344061,
          21.00808856014643
        ]
      },
      "properties": {
        "tenDoiTuon": "TH7 5-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 252 Tây Sơn",
        "tuDien": "TH7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TH7",
        "diaChiLapD": "",
        "longitude": 105.82283501344061,
        "latitude": 21.00808856014643,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3709,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82237903790886,
          21.008098575834808
        ]
      },
      "properties": {
        "tenDoiTuon": "TH7 5-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 252/53 Tây Sơn",
        "tuDien": "TH7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TH7",
        "diaChiLapD": "",
        "longitude": 105.82237903790886,
        "latitude": 21.008098575834808,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3710,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82212691025535,
          21.008078544457348
        ]
      },
      "properties": {
        "tenDoiTuon": "TH7 5-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 252/53 Tây Sơn",
        "tuDien": "TH7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TH7",
        "diaChiLapD": "",
        "longitude": 105.82212691025535,
        "latitude": 21.008078544457348,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3711,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82212691025535,
          21.00821876403981
        ]
      },
      "properties": {
        "tenDoiTuon": "TH7 5-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 252/53 Tây Sơn",
        "tuDien": "TH7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TH7",
        "diaChiLapD": "",
        "longitude": 105.82212691025535,
        "latitude": 21.00821876403981,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3712,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82295303063515,
          21.00802846600359
        ]
      },
      "properties": {
        "tenDoiTuon": "TH7 5-2-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 253/39 Tây sơn",
        "tuDien": "TH7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TH7",
        "diaChiLapD": "",
        "longitude": 105.82295303063515,
        "latitude": 21.00802846600359,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3713,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82332853989868,
          21.008168685632274
        ]
      },
      "properties": {
        "tenDoiTuon": "TH7 09",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 252 Tây Sơn",
        "tuDien": "TH7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TH7",
        "diaChiLapD": "",
        "longitude": 105.82332853989868,
        "latitude": 21.008168685632274,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3714,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82348947243507,
          21.00805350523259
        ]
      },
      "properties": {
        "tenDoiTuon": "TH7 10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 252 Tây Sơn",
        "tuDien": "TH7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TH7",
        "diaChiLapD": "",
        "longitude": 105.82348947243507,
        "latitude": 21.00805350523259,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3715,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82316492514558,
          21.007858199134407
        ]
      },
      "properties": {
        "tenDoiTuon": "TH7 9-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 252/15 Tây Sơn",
        "tuDien": "TH7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TH7",
        "diaChiLapD": "",
        "longitude": 105.82316492514558,
        "latitude": 21.007858199134407,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3716,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82314078526693,
          21.007803112752807
        ]
      },
      "properties": {
        "tenDoiTuon": "TH7 9-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 252/15 Tây Sơn",
        "tuDien": "TH7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TH7",
        "diaChiLapD": "",
        "longitude": 105.82314078526693,
        "latitude": 21.007803112752807,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3717,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82314883189012,
          21.007685428142377
        ]
      },
      "properties": {
        "tenDoiTuon": "TH7 6-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 286 Tây Sơn",
        "tuDien": "TH7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TH7",
        "diaChiLapD": "",
        "longitude": 105.82314883189012,
        "latitude": 21.007685428142377,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3718,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8230978699161,
          21.007417507511384
        ]
      },
      "properties": {
        "tenDoiTuon": "TH7-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 278 Tây Sơn",
        "tuDien": "TH7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TH7",
        "diaChiLapD": "",
        "longitude": 105.8230978699161,
        "latitude": 21.007417507511384,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3719,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82181577401354,
          21.007157098213348
        ]
      },
      "properties": {
        "tenDoiTuon": "TH7 11-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 306 Tây Sơn",
        "tuDien": "TH7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TH7",
        "diaChiLapD": "",
        "longitude": 105.82181577401354,
        "latitude": 21.007157098213348,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3720,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82199011759764,
          21.007302326531782
        ]
      },
      "properties": {
        "tenDoiTuon": "TH7 11-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 306 Tây Sơn",
        "tuDien": "TH7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TH7",
        "diaChiLapD": "",
        "longitude": 105.82199011759764,
        "latitude": 21.007302326531782,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3721,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82212422804761,
          21.00714958639929
        ]
      },
      "properties": {
        "tenDoiTuon": "TH7 11-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 306 Tây Sơn",
        "tuDien": "TH7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TH7",
        "diaChiLapD": "",
        "longitude": 105.82212422804761,
        "latitude": 21.00714958639929,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3722,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82234685139798,
          21.006909208169972
        ]
      },
      "properties": {
        "tenDoiTuon": "TH7 11-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 306 Tây Sơn",
        "tuDien": "TH7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TH7",
        "diaChiLapD": "",
        "longitude": 105.82234685139798,
        "latitude": 21.006909208169972,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3723,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82250241951891,
          21.006736436078203
        ]
      },
      "properties": {
        "tenDoiTuon": "TH7 11-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 306 Tây Sơn",
        "tuDien": "TH7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TH7",
        "diaChiLapD": "",
        "longitude": 105.82250241951891,
        "latitude": 21.006736436078203,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3724,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82269017415067,
          21.00651859272134
        ]
      },
      "properties": {
        "tenDoiTuon": "TH7 11-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 306 Tây Sơn",
        "tuDien": "TH7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TH7",
        "diaChiLapD": "",
        "longitude": 105.82269017415067,
        "latitude": 21.00651859272134,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3725,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82258020357936,
          21.006353332031814
        ]
      },
      "properties": {
        "tenDoiTuon": "TH7 11-7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 308 Tây Sơn",
        "tuDien": "TH7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TH7",
        "diaChiLapD": "",
        "longitude": 105.82258020357936,
        "latitude": 21.006353332031814,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3726,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82397092895835,
          21.009224084501728
        ]
      },
      "properties": {
        "tenDoiTuon": "",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Thái Hà",
        "tuDien": "TH7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": 25,
        "tuDien2": "TH7",
        "diaChiLapD": "",
        "longitude": 105.82397092895835,
        "latitude": 21.009224084501728,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3727,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82403396085824,
          21.008914852157094
        ]
      },
      "properties": {
        "tenDoiTuon": "TH7 02",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Tây Sơn",
        "tuDien": "TH7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 11,
        "tuDien2": "TH7",
        "diaChiLapD": "",
        "longitude": 105.82403396085824,
        "latitude": 21.008914852157094,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3728,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8239052148327,
          21.008634414169663
        ]
      },
      "properties": {
        "tenDoiTuon": "TH7 03",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Tây Sơn",
        "tuDien": "TH7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 11,
        "tuDien2": "TH7",
        "diaChiLapD": "",
        "longitude": 105.8239052148327,
        "latitude": 21.008634414169663,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3729,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82374428228735,
          21.008313912967736
        ]
      },
      "properties": {
        "tenDoiTuon": "TH7 04",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Tây Sơn",
        "tuDien": "TH7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 11,
        "tuDien2": "TH7",
        "diaChiLapD": "",
        "longitude": 105.82374428228735,
        "latitude": 21.008313912967736,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3730,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82359407858193,
          21.008038481696858
        ]
      },
      "properties": {
        "tenDoiTuon": "TH7 05",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Tây Sơn",
        "tuDien": "CVNTS1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 11,
        "tuDien2": "CV NTS1",
        "diaChiLapD": "",
        "longitude": 105.82359407858193,
        "latitude": 21.008038481696858,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3731,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82347069697191,
          21.0077830813365
        ]
      },
      "properties": {
        "tenDoiTuon": "TH7 06",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Tây Sơn",
        "tuDien": "TH7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 11,
        "tuDien2": "TH7",
        "diaChiLapD": "",
        "longitude": 105.82347069697191,
        "latitude": 21.0077830813365,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3732,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82325995197651,
          21.007353120639273
        ]
      },
      "properties": {
        "tenDoiTuon": "TH7 07",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Tây Sơn",
        "tuDien": "TH7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 11,
        "tuDien2": "TH7",
        "diaChiLapD": "",
        "longitude": 105.82325995197651,
        "latitude": 21.007353120639273,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3733,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82307296369109,
          21.006979676233016
        ]
      },
      "properties": {
        "tenDoiTuon": "TH7 08",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Tây Sơn",
        "tuDien": "TH7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 11,
        "tuDien2": "TH7",
        "diaChiLapD": "",
        "longitude": 105.82307296369109,
        "latitude": 21.006979676233016,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3734,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82296107727635,
          21.0067401919936
        ]
      },
      "properties": {
        "tenDoiTuon": "TH7 09",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Tây Sơn",
        "tuDien": "TH7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 11,
        "tuDien2": "TH7",
        "diaChiLapD": "",
        "longitude": 105.82296107727635,
        "latitude": 21.0067401919936,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3735,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82398031668542,
          21.009195289616564
        ]
      },
      "properties": {
        "tenDoiTuon": "TH7 01",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Tây Sơn",
        "tuDien": "TH7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 11,
        "tuDien2": "TH7",
        "diaChiLapD": "",
        "longitude": 105.82398031668542,
        "latitude": 21.009195289616564,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3736,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82278673365634,
          21.00916273886986
        ]
      },
      "properties": {
        "tenDoiTuon": "TH7 1-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "",
        "tuDien": "TH7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TH7",
        "diaChiLapD": "",
        "longitude": 105.82278673365634,
        "latitude": 21.00916273886986,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3737,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82363114660994,
          21.008790808274657
        ]
      },
      "properties": {
        "tenDoiTuon": "TH7 19-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "NGõ 218 Tây Sơn",
        "tuDien": "TH7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TH7",
        "diaChiLapD": "",
        "longitude": 105.82363114660994,
        "latitude": 21.008790808274657,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3738,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82346484964907,
          21.00896608189451
        ]
      },
      "properties": {
        "tenDoiTuon": "TH7 19-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "NGõ 218 Tây Sơn",
        "tuDien": "TH7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TH7",
        "diaChiLapD": "",
        "longitude": 105.82346484964907,
        "latitude": 21.00896608189451,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3739,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82335219687,
          21.00905872643871
        ]
      },
      "properties": {
        "tenDoiTuon": "TH7 19-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "NGõ 218 Tây Sơn",
        "tuDien": "TH7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TH7",
        "diaChiLapD": "",
        "longitude": 105.82335219687,
        "latitude": 21.00905872643871,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3740,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82324490850642,
          21.008951058449018
        ]
      },
      "properties": {
        "tenDoiTuon": "TH7 19-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "NGõ 218 Tây Sơn",
        "tuDien": "TH7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TH7",
        "diaChiLapD": "",
        "longitude": 105.82324490850642,
        "latitude": 21.008951058449018,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3741,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82333342140683,
          21.008798320005248
        ]
      },
      "properties": {
        "tenDoiTuon": "TH7 19-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "NGõ 218 Tây Sơn",
        "tuDien": "TH7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TH7",
        "diaChiLapD": "",
        "longitude": 105.82333342140683,
        "latitude": 21.008798320005248,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3742,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82306520050686,
          21.009098788926906
        ]
      },
      "properties": {
        "tenDoiTuon": "TH7 19-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "NGõ 218 Tây Sơn",
        "tuDien": "TH7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TH7",
        "diaChiLapD": "",
        "longitude": 105.82306520050686,
        "latitude": 21.009098788926906,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3743,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82308934038554,
          21.009309116812023
        ]
      },
      "properties": {
        "tenDoiTuon": "TH7 19-7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "NGõ 218 Tây Sơn",
        "tuDien": "TH7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TH7",
        "diaChiLapD": "",
        "longitude": 105.82308934038554,
        "latitude": 21.009309116812023,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3744,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8229927808619,
          21.009394249442977
        ]
      },
      "properties": {
        "tenDoiTuon": "TH7 19-8",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "NGõ 218 Tây Sơn",
        "tuDien": "TH7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TH7",
        "diaChiLapD": "",
        "longitude": 105.8229927808619,
        "latitude": 21.009394249442977,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3745,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82322613304325,
          21.009284077793744
        ]
      },
      "properties": {
        "tenDoiTuon": "TH7 19-9",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "NGõ 218 Tây Sơn",
        "tuDien": "TH7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TH7",
        "diaChiLapD": "",
        "longitude": 105.82322613304325,
        "latitude": 21.009284077793744,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3746,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82311884468865,
          21.009499413214733
        ]
      },
      "properties": {
        "tenDoiTuon": "TH7 3-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 3-1 Thái Hà",
        "tuDien": "TH7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TH7",
        "diaChiLapD": "",
        "longitude": 105.82311884468865,
        "latitude": 21.009499413214733,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3747,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81627835344423,
          21.011169604511725
        ]
      },
      "properties": {
        "tenDoiTuon": "14",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Khu B TT Yên Lãng",
        "tuDien": "YL3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "Tập Thể Yên Lãng 3",
        "diaChiLapD": "",
        "longitude": 105.81627835344423,
        "latitude": 21.011169604511725,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3748,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8160396368384,
          21.011341119534812
        ]
      },
      "properties": {
        "tenDoiTuon": "15",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Khu B TT Yên Lãng",
        "tuDien": "YL3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "Tập Thể Yên Lãng 3",
        "diaChiLapD": "",
        "longitude": 105.8160396368384,
        "latitude": 21.011341119534812,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3749,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81575666378687,
          21.011523901752536
        ]
      },
      "properties": {
        "tenDoiTuon": "16",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Khu B TT Yên Lãng",
        "tuDien": "YL3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "Tập Thể Yên Lãng 3",
        "diaChiLapD": "",
        "longitude": 105.81575666378687,
        "latitude": 21.011523901752536,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3750,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81553135822875,
          21.011706683745448
        ]
      },
      "properties": {
        "tenDoiTuon": "17",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Khu B TT Yên Lãng",
        "tuDien": "YL3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "Tập Thể Yên Lãng 3",
        "diaChiLapD": "",
        "longitude": 105.81553135822875,
        "latitude": 21.011706683745448,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3751,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81515987228579,
          21.011923267598615
        ]
      },
      "properties": {
        "tenDoiTuon": "18",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Khu B TT Yên Lãng",
        "tuDien": "YL3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "Tập Thể Yên Lãng 3",
        "diaChiLapD": "",
        "longitude": 105.81515987228579,
        "latitude": 21.011923267598615,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3752,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81500296305204,
          21.012035941154256
        ]
      },
      "properties": {
        "tenDoiTuon": "60",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Khu B TT Yên Lãng",
        "tuDien": "YL3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Tập Thể Yên Lãng 3",
        "diaChiLapD": "",
        "longitude": 105.81500296305204,
        "latitude": 21.012035941154256,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3753,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81624866803628,
          21.011667051089344
        ]
      },
      "properties": {
        "tenDoiTuon": "19",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "",
        "tuDien": "YL3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Tập Thể Yên Lãng 3",
        "diaChiLapD": "",
        "longitude": 105.81624866803628,
        "latitude": 21.011667051089344,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3754,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81614365087997,
          21.011527880631636
        ]
      },
      "properties": {
        "tenDoiTuon": "15-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "",
        "tuDien": "YL3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 7,
        "tuDien2": "Tập Thể Yên Lãng 3",
        "diaChiLapD": "",
        "longitude": 105.81614365087997,
        "latitude": 21.011527880631636,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3755,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81592251164204,
          21.01178891433682
        ]
      },
      "properties": {
        "tenDoiTuon": "20",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Khu B TT Yên Lãng",
        "tuDien": "YL3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Tập Thể Yên Lãng 3",
        "diaChiLapD": "",
        "longitude": 105.81592251164204,
        "latitude": 21.01178891433682,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3756,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81559835287413,
          21.011928651980103
        ]
      },
      "properties": {
        "tenDoiTuon": "21",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Khu B TT Yên Lãng",
        "tuDien": "YL3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 7,
        "tuDien2": "Tập Thể Yên Lãng 3",
        "diaChiLapD": "",
        "longitude": 105.81559835287413,
        "latitude": 21.011928651980103,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3757,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81530134644716,
          21.012092514633853
        ]
      },
      "properties": {
        "tenDoiTuon": "22",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Khu B TT Yên Lãng",
        "tuDien": "YL3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 7,
        "tuDien2": "Tập Thể Yên Lãng 3",
        "diaChiLapD": "",
        "longitude": 105.81530134644716,
        "latitude": 21.012092514633853,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3758,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81535179006637,
          21.012270891647255
        ]
      },
      "properties": {
        "tenDoiTuon": "22-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Khu B TT Yên Lãng",
        "tuDien": "YL3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Tập Thể Yên Lãng 3",
        "diaChiLapD": "",
        "longitude": 105.81535179006637,
        "latitude": 21.012270891647255,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3759,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8159055306956,
          21.011229510026272
        ]
      },
      "properties": {
        "tenDoiTuon": "23",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Khu B TT Yên Lãng",
        "tuDien": "YL3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 7,
        "tuDien2": "Tập Thể Yên Lãng 3",
        "diaChiLapD": "",
        "longitude": 105.8159055306956,
        "latitude": 21.011229510026272,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3760,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81565589192735,
          21.011400538681606
        ]
      },
      "properties": {
        "tenDoiTuon": "24",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Khu B TT Yên Lãng",
        "tuDien": "YL3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Tập Thể Yên Lãng 3",
        "diaChiLapD": "",
        "longitude": 105.81565589192735,
        "latitude": 21.011400538681606,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3761,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81527567209673,
          21.011523212662492
        ]
      },
      "properties": {
        "tenDoiTuon": "26",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Khu B TT Yên Lãng",
        "tuDien": "YL3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Tập Thể Yên Lãng 3",
        "diaChiLapD": "",
        "longitude": 105.81527567209673,
        "latitude": 21.011523212662492,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3762,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81510770967179,
          21.01181184897933
        ]
      },
      "properties": {
        "tenDoiTuon": "27",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Khu B TT Yên Lãng",
        "tuDien": "YL3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Tập Thể Yên Lãng 3",
        "diaChiLapD": "",
        "longitude": 105.81510770967179,
        "latitude": 21.01181184897933,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3763,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81501425379066,
          21.01169910143666
        ]
      },
      "properties": {
        "tenDoiTuon": "28",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Khu B TT Yên Lãng",
        "tuDien": "YL3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Tập Thể Yên Lãng 3",
        "diaChiLapD": "",
        "longitude": 105.81501425379066,
        "latitude": 21.01169910143666,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3764,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81463717678483,
          21.011596826788857
        ]
      },
      "properties": {
        "tenDoiTuon": "61",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Khu B TT Yên Lãng",
        "tuDien": "YL3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Tập Thể Yên Lãng 3",
        "diaChiLapD": "",
        "longitude": 105.81463717678483,
        "latitude": 21.011596826788857,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3765,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81475117066776,
          21.011738294994302
        ]
      },
      "properties": {
        "tenDoiTuon": "61-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Khu B TT Yên Lãng",
        "tuDien": "YL3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Tập Thể Yên Lãng 3",
        "diaChiLapD": "",
        "longitude": 105.81475117066776,
        "latitude": 21.011738294994302,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3766,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81487723449452,
          21.011897290074014
        ]
      },
      "properties": {
        "tenDoiTuon": "62",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Khu B TT Yên Lãng",
        "tuDien": "YL3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Tập Thể Yên Lãng 3",
        "diaChiLapD": "",
        "longitude": 105.81487723449452,
        "latitude": 21.011897290074014,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3767,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81606411195934,
          21.010811863602328
        ]
      },
      "properties": {
        "tenDoiTuon": "29",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Khu B TT Yên Lãng",
        "tuDien": "YL3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Tập Thể Yên Lãng 3",
        "diaChiLapD": "",
        "longitude": 105.81606411195934,
        "latitude": 21.010811863602328,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3768,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8157771155962,
          21.011028448754672
        ]
      },
      "properties": {
        "tenDoiTuon": "30",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Khu B TT Yên Lãng",
        "tuDien": "YL3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 7,
        "tuDien2": "Tập Thể Yên Lãng 3",
        "diaChiLapD": "",
        "longitude": 105.8157771155962,
        "latitude": 21.011028448754672,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3769,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81551828242334,
          21.011203719745584
        ]
      },
      "properties": {
        "tenDoiTuon": "31",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Khu B TT Yên Lãng",
        "tuDien": "YL3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Tập Thể Yên Lãng 3",
        "diaChiLapD": "",
        "longitude": 105.81551828242334,
        "latitude": 21.011203719745584,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3770,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81559338425807,
          21.010321103022402
        ]
      },
      "properties": {
        "tenDoiTuon": "33",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Khu B TT Yên Lãng",
        "tuDien": "YL3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Tập Thể Yên Lãng 3",
        "diaChiLapD": "",
        "longitude": 105.81559338425807,
        "latitude": 21.010321103022402,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3771,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81533991550968,
          21.01050388648908
        ]
      },
      "properties": {
        "tenDoiTuon": "34",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Khu B TT Yên Lãng",
        "tuDien": "YL3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 7,
        "tuDien2": "Tập Thể Yên Lãng 3",
        "diaChiLapD": "",
        "longitude": 105.81533991550968,
        "latitude": 21.01050388648908,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3772,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81508376454454,
          21.010691677488897
        ]
      },
      "properties": {
        "tenDoiTuon": "35",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Khu B TT Yên Lãng",
        "tuDien": "YL3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Tập Thể Yên Lãng 3",
        "diaChiLapD": "",
        "longitude": 105.81508376454454,
        "latitude": 21.010691677488897,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3773,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8147753105105,
          21.01085442949761
        ]
      },
      "properties": {
        "tenDoiTuon": "41",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Khu B TT Yên Lãng",
        "tuDien": "YL3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Tập Thể Yên Lãng 3",
        "diaChiLapD": "",
        "longitude": 105.8147753105105,
        "latitude": 21.01085442949761,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3774,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81453257059306,
          21.011059747163813
        ]
      },
      "properties": {
        "tenDoiTuon": "45",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Khu B TT Yên Lãng",
        "tuDien": "YL3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Tập Thể Yên Lãng 3",
        "diaChiLapD": "",
        "longitude": 105.81453257059306,
        "latitude": 21.011059747163813,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3775,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81542574619334,
          21.01111733621387
        ]
      },
      "properties": {
        "tenDoiTuon": "32",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Khu B TT Yên Lãng",
        "tuDien": "YL3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 7,
        "tuDien2": "Tập Thể Yên Lãng 3",
        "diaChiLapD": "",
        "longitude": 105.81542574619334,
        "latitude": 21.01111733621387,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3776,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8153016940269,
          21.010965851924702
        ]
      },
      "properties": {
        "tenDoiTuon": "37",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Khu B TT Yên Lãng",
        "tuDien": "YL3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Tập Thể Yên Lãng 3",
        "diaChiLapD": "",
        "longitude": 105.8153016940269,
        "latitude": 21.010965851924702,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3777,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81517764186043,
          21.010814367481725
        ]
      },
      "properties": {
        "tenDoiTuon": "36",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Khu B TT Yên Lãng",
        "tuDien": "YL3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 7,
        "tuDien2": "Tập Thể Yên Lãng 3",
        "diaChiLapD": "",
        "longitude": 105.81517764186043,
        "latitude": 21.010814367481725,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3778,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81498854611581,
          21.011174925242518
        ]
      },
      "properties": {
        "tenDoiTuon": "38",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Khu B TT Yên Lãng",
        "tuDien": "YL3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Tập Thể Yên Lãng 3",
        "diaChiLapD": "",
        "longitude": 105.81498854611581,
        "latitude": 21.011174925242518,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3779,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81476115794216,
          21.011372939331046
        ]
      },
      "properties": {
        "tenDoiTuon": "42",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Khu B TT Yên Lãng",
        "tuDien": "YL3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Tập Thể Yên Lãng 3",
        "diaChiLapD": "",
        "longitude": 105.81476115794216,
        "latitude": 21.011372939331046,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3780,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8148486392315,
          21.011500881752813
        ]
      },
      "properties": {
        "tenDoiTuon": "43",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Khu B TT Yên Lãng",
        "tuDien": "YL3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 7,
        "tuDien2": "Tập Thể Yên Lãng 3",
        "diaChiLapD": "",
        "longitude": 105.8148486392315,
        "latitude": 21.011500881752813,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3781,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81473413697161,
          21.011339446073155
        ]
      },
      "properties": {
        "tenDoiTuon": "44",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Khu B TT Yên Lãng",
        "tuDien": "YL3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Tập Thể Yên Lãng 3",
        "diaChiLapD": "",
        "longitude": 105.81473413697161,
        "latitude": 21.011339446073155,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3782,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81513874982123,
          21.010222199546973
        ]
      },
      "properties": {
        "tenDoiTuon": "49",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Khu B TT Yên Lãng",
        "tuDien": "YL3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Tập Thể Yên Lãng 3",
        "diaChiLapD": "",
        "longitude": 105.81513874982123,
        "latitude": 21.010222199546973,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3783,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81498574309171,
          21.010087645434496
        ]
      },
      "properties": {
        "tenDoiTuon": "54",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Khu B TT Yên Lãng",
        "tuDien": "YL3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Tập Thể Yên Lãng 3",
        "diaChiLapD": "",
        "longitude": 105.81498574309171,
        "latitude": 21.010087645434496,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3784,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81485175345809,
          21.009981826268124
        ]
      },
      "properties": {
        "tenDoiTuon": "54-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 88 Thái Thịnh 2",
        "tuDien": "YL3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Tập Thể Yên Lãng 3",
        "diaChiLapD": "",
        "longitude": 105.81485175345809,
        "latitude": 21.009981826268124,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3785,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81536807869995,
          21.010064454627457
        ]
      },
      "properties": {
        "tenDoiTuon": "48",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Khu B TT Yên Lãng",
        "tuDien": "YL3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 7,
        "tuDien2": "Tập Thể Yên Lãng 3",
        "diaChiLapD": "",
        "longitude": 105.81536807869995,
        "latitude": 21.010064454627457,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3786,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81496342711729,
          21.01059855632414
        ]
      },
      "properties": {
        "tenDoiTuon": "56",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Khu B TT Yên Lãng",
        "tuDien": "YL3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Tập Thể Yên Lãng 3",
        "diaChiLapD": "",
        "longitude": 105.81496342711729,
        "latitude": 21.01059855632414,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3787,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81481554364458,
          21.010414998668573
        ]
      },
      "properties": {
        "tenDoiTuon": "50",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Khu B TT Yên Lãng",
        "tuDien": "YL3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Tập Thể Yên Lãng 3",
        "diaChiLapD": "",
        "longitude": 105.81481554364458,
        "latitude": 21.010414998668573,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3788,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81469249730378,
          21.01021813073319
        ]
      },
      "properties": {
        "tenDoiTuon": "55",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Khu B TT Yên Lãng",
        "tuDien": "YL3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Tập Thể Yên Lãng 3",
        "diaChiLapD": "",
        "longitude": 105.81469249730378,
        "latitude": 21.01021813073319,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3789,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81438907240701,
          21.010426266142478
        ]
      },
      "properties": {
        "tenDoiTuon": "57",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Khu B TT Yên Lãng",
        "tuDien": "YL3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Tập Thể Yên Lãng 3",
        "diaChiLapD": "",
        "longitude": 105.81438907240701,
        "latitude": 21.010426266142478,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3790,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81454195832015,
          21.010613431270365
        ]
      },
      "properties": {
        "tenDoiTuon": "51",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Khu B TT Yên Lãng",
        "tuDien": "YL3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Tập Thể Yên Lãng 3",
        "diaChiLapD": "",
        "longitude": 105.81454195832015,
        "latitude": 21.010613431270365,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3791,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8146867976101,
          21.01074300699024
        ]
      },
      "properties": {
        "tenDoiTuon": "58",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Khu B TT Yên Lãng",
        "tuDien": "YL3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Tập Thể Yên Lãng 3",
        "diaChiLapD": "",
        "longitude": 105.8146867976101,
        "latitude": 21.01074300699024,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3792,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8143314049136,
          21.010806855854312
        ]
      },
      "properties": {
        "tenDoiTuon": "47",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Khu B TT Yên Lãng",
        "tuDien": "YL3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Tập Thể Yên Lãng 3",
        "diaChiLapD": "",
        "longitude": 105.8143314049136,
        "latitude": 21.010806855854312,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3793,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81443198774883,
          21.010697937186883
        ]
      },
      "properties": {
        "tenDoiTuon": "52",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Khu B TT Yên Lãng",
        "tuDien": "YL3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Tập Thể Yên Lãng 3",
        "diaChiLapD": "",
        "longitude": 105.81443198774883,
        "latitude": 21.010697937186883,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3794,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81423082207834,
          21.01072923566284
        ]
      },
      "properties": {
        "tenDoiTuon": "47-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Khu B TT Yên Lãng",
        "tuDien": "YL3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Tập Thể Yên Lãng 3",
        "diaChiLapD": "",
        "longitude": 105.81423082207834,
        "latitude": 21.01072923566284,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3795,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81505861884023,
          21.009871342067704
        ]
      },
      "properties": {
        "tenDoiTuon": "59",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Khu B TT Yên Lãng",
        "tuDien": "YL3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 7,
        "tuDien2": "Tập Thể Yên Lãng 3",
        "diaChiLapD": "",
        "longitude": 105.81505861884023,
        "latitude": 21.009871342067704,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3796,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81455436354219,
          21.009782453867782
        ]
      },
      "properties": {
        "tenDoiTuon": "54-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 88 Thái Thịnh 2",
        "tuDien": "YL3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Tập Thể Yên Lãng 3",
        "diaChiLapD": "",
        "longitude": 105.81455436354219,
        "latitude": 21.009782453867782,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3797,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81466567521737,
          21.010080416919067
        ]
      },
      "properties": {
        "tenDoiTuon": "54-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 88 Thái Thịnh 2",
        "tuDien": "YL3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Tập Thể Yên Lãng 3",
        "diaChiLapD": "",
        "longitude": 105.81466567521737,
        "latitude": 21.010080416919067,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3798,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81446182733019,
          21.009940199087147
        ]
      },
      "properties": {
        "tenDoiTuon": "54-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 88 Thái Thịnh 2",
        "tuDien": "YL3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Tập Thể Yên Lãng 3",
        "diaChiLapD": "",
        "longitude": 105.81446182733019,
        "latitude": 21.009940199087147,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3799,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82631249741304,
          21.0216279044844
        ]
      },
      "properties": {
        "tenDoiTuon": "4-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 20",
        "tuDien": "DDHN2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Di Dân Hào Nam 2",
        "diaChiLapD": "",
        "longitude": 105.82631249741304,
        "latitude": 21.0216279044844,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3800,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82649354652546,
          21.021570319492103
        ]
      },
      "properties": {
        "tenDoiTuon": "4-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 20",
        "tuDien": "DDHN2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Di Dân Hào Nam 2",
        "diaChiLapD": "",
        "longitude": 105.82649354652546,
        "latitude": 21.021570319492103,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3801,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82639966920956,
          21.021599111991442
        ]
      },
      "properties": {
        "tenDoiTuon": "4-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 20",
        "tuDien": "DDHN2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Di Dân Hào Nam 2",
        "diaChiLapD": "",
        "longitude": 105.82639966920956,
        "latitude": 21.021599111991442,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3802,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82713861779284,
          21.025423457513206
        ]
      },
      "properties": {
        "tenDoiTuon": "25-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 137",
        "tuDien": "DDHN2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Di Dân Hào Nam 2",
        "diaChiLapD": "",
        "longitude": 105.82713861779284,
        "latitude": 21.025423457513206,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3803,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82688514904444,
          21.025481041017155
        ]
      },
      "properties": {
        "tenDoiTuon": "25-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 137",
        "tuDien": "DDHN2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Di Dân Hào Nam 2",
        "diaChiLapD": "",
        "longitude": 105.82688514904444,
        "latitude": 21.025481041017155,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3804,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82667996005337,
          21.025513588204653
        ]
      },
      "properties": {
        "tenDoiTuon": "25-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 137",
        "tuDien": "DDHN2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Di Dân Hào Nam 2",
        "diaChiLapD": "",
        "longitude": 105.82667996005337,
        "latitude": 21.025513588204653,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3805,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82666663458481,
          21.02231982823701
        ]
      },
      "properties": {
        "tenDoiTuon": "02",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 32 Tổ 75",
        "tuDien": "DDHN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Di Dân Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82666663458481,
        "latitude": 21.02231982823701,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3806,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82683348733207,
          21.02222096486088
        ]
      },
      "properties": {
        "tenDoiTuon": "03",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 32 Tổ 75",
        "tuDien": "DDHN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Di Dân Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82683348733207,
        "latitude": 21.02222096486088,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3807,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82700279623545,
          21.022125809375034
        ]
      },
      "properties": {
        "tenDoiTuon": "04",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 32 Tổ 75",
        "tuDien": "DDHN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Di Dân Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82700279623545,
        "latitude": 21.022125809375034,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3808,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82717954051678,
          21.02202592585841
        ]
      },
      "properties": {
        "tenDoiTuon": "05",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 32 Tổ 75",
        "tuDien": "DDHN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Di Dân Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82717954051678,
        "latitude": 21.02202592585841,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3809,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82732529329702,
          21.021943023957835
        ]
      },
      "properties": {
        "tenDoiTuon": "07",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 32 Tổ 75",
        "tuDien": "DDHN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 7,
        "tuDien2": "Di Dân Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82732529329702,
        "latitude": 21.021943023957835,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3810,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82705651550975,
          21.02181157701128
        ]
      },
      "properties": {
        "tenDoiTuon": "06",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 32 Tổ 75",
        "tuDien": "DDHN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Di Dân Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82705651550975,
        "latitude": 21.02181157701128,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3811,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82661692811752,
          21.02248416565815
        ]
      },
      "properties": {
        "tenDoiTuon": "01",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 32A Tổ 75 Hào Nam",
        "tuDien": "DDHN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Di Dân Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82661692811752,
        "latitude": 21.02248416565815,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3812,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8267778606539,
          21.022593075716962
        ]
      },
      "properties": {
        "tenDoiTuon": "13",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 32A Tổ 75 Hào Nam",
        "tuDien": "DDHN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Di Dân Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.8267778606539,
        "latitude": 21.022593075716962,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3813,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82704339934612,
          21.022494180379006
        ]
      },
      "properties": {
        "tenDoiTuon": "14",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 32A Tổ 75 Hào Nam",
        "tuDien": "DDHN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Di Dân Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82704339934612,
        "latitude": 21.022494180379006,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3814,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82693559204203,
          21.022329655578996
        ]
      },
      "properties": {
        "tenDoiTuon": "19",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 32A Tổ 75 Hào Nam",
        "tuDien": "DDHN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Di Dân Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82693559204203,
        "latitude": 21.022329655578996,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3815,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82693559204203,
          21.022329655578996
        ]
      },
      "properties": {
        "tenDoiTuon": "19",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 32A Tổ 75 Hào Nam",
        "tuDien": "DDHN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Di Dân Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82693559204203,
        "latitude": 21.022329655578996,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3816,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8273599000124,
          21.02237650736031
        ]
      },
      "properties": {
        "tenDoiTuon": "15",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 32A Tổ 75 Hào Nam",
        "tuDien": "DDHN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Di Dân Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.8273599000124,
        "latitude": 21.02237650736031,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3817,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82726581889091,
          21.02220480122623
        ]
      },
      "properties": {
        "tenDoiTuon": "18",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 32A Tổ 75 Hào Nam",
        "tuDien": "DDHN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Di Dân Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82726581889091,
        "latitude": 21.02220480122623,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3818,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82740731419436,
          21.022109072599605
        ]
      },
      "properties": {
        "tenDoiTuon": "17",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 32A Tổ 75 Hào Nam",
        "tuDien": "DDHN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Di Dân Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82740731419436,
        "latitude": 21.022109072599605,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3819,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8275463135403,
          21.022271352668426
        ]
      },
      "properties": {
        "tenDoiTuon": "16",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 32A Tổ 75 Hào Nam",
        "tuDien": "DDHN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Di Dân Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.8275463135403,
        "latitude": 21.022271352668426,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3820,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82670821253619,
          21.022669437887302
        ]
      },
      "properties": {
        "tenDoiTuon": "12",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Ngõ 32A Tổ 75 Hào Nam",
        "tuDien": "DDHN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Di Dân Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82670821253619,
        "latitude": 21.022669437887302,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3821,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82692135883099,
          21.022787110684835
        ]
      },
      "properties": {
        "tenDoiTuon": "08",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 34 Hào Nam",
        "tuDien": "DDHN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Di Dân Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82692135883099,
        "latitude": 21.022787110684835,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3822,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82711984229373,
          21.022767081280946
        ]
      },
      "properties": {
        "tenDoiTuon": "09",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 34 Hào Nam",
        "tuDien": "DDHN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Di Dân Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82711984229373,
        "latitude": 21.022767081280946,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3823,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82737263623956,
          21.022698409620478
        ]
      },
      "properties": {
        "tenDoiTuon": "10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 34 Hào Nam",
        "tuDien": "DDHN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Di Dân Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82737263623956,
        "latitude": 21.022698409620478,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3824,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82761336876081,
          21.022636890088055
        ]
      },
      "properties": {
        "tenDoiTuon": "11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 34 Hào Nam",
        "tuDien": "DDHN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Di Dân Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82761336876081,
        "latitude": 21.022636890088055,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3825,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81281428057336,
          21.013051251233993
        ]
      },
      "properties": {
        "tenDoiTuon": "CBĐ 01",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 105 Phố Láng Hạ",
        "tuDien": "CBĐ",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "Cục Bản Đồ",
        "diaChiLapD": "",
        "longitude": 105.81281428057336,
        "latitude": 21.013051251233993,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3826,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81295777872349,
          21.01295547936863
        ]
      },
      "properties": {
        "tenDoiTuon": "02",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 105 Phố Láng Hạ",
        "tuDien": "CBĐ",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "Cục Bản Đồ",
        "diaChiLapD": "",
        "longitude": 105.81295777872349,
        "latitude": 21.01295547936863,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3827,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81324879840726,
          21.012798989260414
        ]
      },
      "properties": {
        "tenDoiTuon": "03",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 105 Phố Láng Hạ",
        "tuDien": "CBĐ",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "Cục Bản Đồ",
        "diaChiLapD": "",
        "longitude": 105.81324879840726,
        "latitude": 21.012798989260414,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3828,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81359983250492,
          21.01259836870011
        ]
      },
      "properties": {
        "tenDoiTuon": "04",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 105 Phố Láng Hạ",
        "tuDien": "CBĐ",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "Cục Bản Đồ",
        "diaChiLapD": "",
        "longitude": 105.81359983250492,
        "latitude": 21.01259836870011,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3829,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81387207672549,
          21.012446885914546
        ]
      },
      "properties": {
        "tenDoiTuon": "05",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 105 Phố Láng Hạ",
        "tuDien": "CBĐ",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "Cục Bản Đồ",
        "diaChiLapD": "",
        "longitude": 105.81387207672549,
        "latitude": 21.012446885914546,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3830,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81405580803667,
          21.01234047229668
        ]
      },
      "properties": {
        "tenDoiTuon": "06",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 105 Phố Láng Hạ",
        "tuDien": "CBĐ",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "Cục Bản Đồ",
        "diaChiLapD": "",
        "longitude": 105.81405580803667,
        "latitude": 21.01234047229668,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3831,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81421674058201,
          21.01228789142148
        ]
      },
      "properties": {
        "tenDoiTuon": "CBĐ 07",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 105 Phố Láng Hạ",
        "tuDien": "CBĐ",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "Cục Bản Đồ",
        "diaChiLapD": "",
        "longitude": 105.81421674058201,
        "latitude": 21.01228789142148,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3832,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81367359325375,
          21.01213766024794
        ]
      },
      "properties": {
        "tenDoiTuon": "CBĐ 08",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 105 Phố Lãng Hạ",
        "tuDien": "CBĐ",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "Cục Bản Đồ",
        "diaChiLapD": "",
        "longitude": 105.81367359325375,
        "latitude": 21.01213766024794,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3833,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81349254415932,
          21.01236551080193
        ]
      },
      "properties": {
        "tenDoiTuon": "9-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 105 Phố Láng Hạ",
        "tuDien": "CBĐ",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "Cục Bản Đồ",
        "diaChiLapD": "",
        "longitude": 105.81349254415932,
        "latitude": 21.01236551080193,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3834,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81329271958371,
          21.012230302823326
        ]
      },
      "properties": {
        "tenDoiTuon": "CBĐ 09",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 105 Phố Láng Hạ",
        "tuDien": "CBĐ",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "Cục Bản Đồ",
        "diaChiLapD": "",
        "longitude": 105.81329271958371,
        "latitude": 21.012230302823326,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3835,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81290648148021,
          21.01238303775327
        ]
      },
      "properties": {
        "tenDoiTuon": "10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 105 Phố Láng Hạ",
        "tuDien": "CBĐ",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "Cục Bản Đồ",
        "diaChiLapD": "",
        "longitude": 105.81290648148021,
        "latitude": 21.01238303775327,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3836,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81305132077014,
          21.01216895842181
        ]
      },
      "properties": {
        "tenDoiTuon": "10-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 105 Phố Láng Hạ",
        "tuDien": "CBĐ",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "Cục Bản Đồ",
        "diaChiLapD": "",
        "longitude": 105.81305132077014,
        "latitude": 21.01216895842181,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3837,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81288616751911,
          21.012014787064963
        ]
      },
      "properties": {
        "tenDoiTuon": "10-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 105 Phố Láng Hạ",
        "tuDien": "CBĐ",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "Cục Bản Đồ",
        "diaChiLapD": "",
        "longitude": 105.81288616751911,
        "latitude": 21.012014787064963,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3838,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81262484954154,
          21.0120800715918
        ]
      },
      "properties": {
        "tenDoiTuon": "11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 105 Phố Láng Hạ",
        "tuDien": "CBĐ",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "Cục Bản Đồ",
        "diaChiLapD": "",
        "longitude": 105.81262484954154,
        "latitude": 21.0120800715918,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3839,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81277102992638,
          21.01195112299735
        ]
      },
      "properties": {
        "tenDoiTuon": "12",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 105 Phố Láng Hạ",
        "tuDien": "CBĐ",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "Cục Bản Đồ",
        "diaChiLapD": "",
        "longitude": 105.81277102992638,
        "latitude": 21.01195112299735,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3840,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81285015509069,
          21.011837197447782
        ]
      },
      "properties": {
        "tenDoiTuon": "13",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 105 Phố Láng Hạ",
        "tuDien": "CBĐ",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "Cục Bản Đồ",
        "diaChiLapD": "",
        "longitude": 105.81285015509069,
        "latitude": 21.011837197447782,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3841,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81318409011627,
          21.01203625412058
        ]
      },
      "properties": {
        "tenDoiTuon": "CBĐ 13-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 105 Phố Láng Hạ",
        "tuDien": "CBĐ",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "Cục Bản Đồ",
        "diaChiLapD": "",
        "longitude": 105.81318409011627,
        "latitude": 21.01203625412058,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3842,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81280254589879,
          21.012737958071035
        ]
      },
      "properties": {
        "tenDoiTuon": "2-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 105 Phố Láng Hạ",
        "tuDien": "CBĐ",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "Cục Bản Đồ",
        "diaChiLapD": "",
        "longitude": 105.81280254589879,
        "latitude": 21.012737958071035,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3843,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8127086685829,
          21.012605254276025
        ]
      },
      "properties": {
        "tenDoiTuon": "CBĐ 2-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 105 Phố Láng Hạ",
        "tuDien": "CBĐ",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "Cục Bản Đồ",
        "diaChiLapD": "",
        "longitude": 105.8127086685829,
        "latitude": 21.012605254276025,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3844,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82691934724254,
          21.02316829476087
        ]
      },
      "properties": {
        "tenDoiTuon": "DDHN 21",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ44+ngách 55+27HN",
        "tuDien": "DDHN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Di Dân Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82691934724254,
        "latitude": 21.02316829476087,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3845,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82711783070529,
          21.023129487887047
        ]
      },
      "properties": {
        "tenDoiTuon": "22",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 46 Hào Nam",
        "tuDien": "DDHN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Di Dân Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82711783070529,
        "latitude": 21.023129487887047,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3846,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82735444700985,
          21.02304511308552
        ]
      },
      "properties": {
        "tenDoiTuon": "23",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngâ44+ngách 55+27HN",
        "tuDien": "DDHN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Di Dân Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82735444700985,
        "latitude": 21.02304511308552,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3847,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82763627909019,
          21.022973982278923
        ]
      },
      "properties": {
        "tenDoiTuon": "24",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngâ44+ngách 55+27HN",
        "tuDien": "DDHN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Di Dân Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82763627909019,
        "latitude": 21.022973982278923,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3848,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82788646637466,
          21.022906203390242
        ]
      },
      "properties": {
        "tenDoiTuon": "25",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngâ44+ngách 55+27HN",
        "tuDien": "DDHN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Di Dân Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82788646637466,
        "latitude": 21.022906203390242,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3849,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82818467932425,
          21.022829673156327
        ]
      },
      "properties": {
        "tenDoiTuon": "26",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngâ44+ngách 55+27HN",
        "tuDien": "DDHN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "Di Dân Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82818467932425,
        "latitude": 21.022829673156327,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3850,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82843432394496,
          21.022774429617474
        ]
      },
      "properties": {
        "tenDoiTuon": "27",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngâ44+ngách 55+27HN",
        "tuDien": "DDHN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Di Dân Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82843432394496,
        "latitude": 21.022774429617474,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3851,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82825240538654,
          21.023064482871323
        ]
      },
      "properties": {
        "tenDoiTuon": "26-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngâ44+ngách 55+27HN",
        "tuDien": "DDHN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Di Dân Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82825240538654,
        "latitude": 21.023064482871323,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3852,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82837109287011,
          21.02292230908374
        ]
      },
      "properties": {
        "tenDoiTuon": "27-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngâ44+ngách 55+27HN",
        "tuDien": "DDHN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Di Dân Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82837109287011,
        "latitude": 21.02292230908374,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3853,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81960362211383,
          21.01317832100163
        ]
      },
      "properties": {
        "tenDoiTuon": "05",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Thái Hà",
        "tuDien": "XDN",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "Xây Dựng Nhà",
        "diaChiLapD": "",
        "longitude": 105.81960362211383,
        "latitude": 21.01317832100163,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3854,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81991475835564,
          21.012917921759414
        ]
      },
      "properties": {
        "tenDoiTuon": "04",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Thái Hà",
        "tuDien": "XDN",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "Xây Dựng Nhà",
        "diaChiLapD": "",
        "longitude": 105.81991475835564,
        "latitude": 21.012917921759414,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3855,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82028490320369,
          21.012624972068
        ]
      },
      "properties": {
        "tenDoiTuon": "03",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Thái Hà",
        "tuDien": "XDN",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "Xây Dựng Nhà",
        "diaChiLapD": "",
        "longitude": 105.82028490320369,
        "latitude": 21.012624972068,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3856,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8205692173591,
          21.01238710651053
        ]
      },
      "properties": {
        "tenDoiTuon": "02",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Thái Hà",
        "tuDien": "XDN",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "Xây Dựng Nhà",
        "diaChiLapD": "",
        "longitude": 105.8205692173591,
        "latitude": 21.01238710651053,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3857,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82081329838039,
          21.01212920974098
        ]
      },
      "properties": {
        "tenDoiTuon": "01",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Thái Hà",
        "tuDien": "XDN",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "Xây Dựng Nhà",
        "diaChiLapD": "",
        "longitude": 105.82081329838039,
        "latitude": 21.01212920974098,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3858,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81931818860045,
          21.013382077972842
        ]
      },
      "properties": {
        "tenDoiTuon": "06",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Thái Hà",
        "tuDien": "XDN",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "Xây Dựng Nhà",
        "diaChiLapD": "",
        "longitude": 105.81931818860045,
        "latitude": 21.013382077972842,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3859,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81902644529315,
          21.013605406843652
        ]
      },
      "properties": {
        "tenDoiTuon": "07",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Thái Hà",
        "tuDien": "XDN",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "Xây Dựng Nhà",
        "diaChiLapD": "",
        "longitude": 105.81902644529315,
        "latitude": 21.013605406843652,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3860,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83352361636796,
          21.020625171337045
        ]
      },
      "properties": {
        "tenDoiTuon": "20-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Trung Tả",
        "tuDien": "VC1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Văn Chương 1",
        "diaChiLapD": "",
        "longitude": 105.83352361636796,
        "latitude": 21.020625171337045,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3861,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83906506021297,
          21.021183497993807
        ]
      },
      "properties": {
        "tenDoiTuon": "10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Trần Quý Cáp",
        "tuDien": "TQC3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Trần Quý Cáp 3",
        "diaChiLapD": "",
        "longitude": 105.83906506021297,
        "latitude": 21.021183497993807,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3862,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83921526391839,
          21.021398816234687
        ]
      },
      "properties": {
        "tenDoiTuon": "11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Trần Quý Cáp",
        "tuDien": "TQC3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Trần Quý Cáp 3",
        "diaChiLapD": "",
        "longitude": 105.83921526391839,
        "latitude": 21.021398816234687,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3863,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83914552648116,
          21.022019732210566
        ]
      },
      "properties": {
        "tenDoiTuon": "12",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Trần Quý Cáp",
        "tuDien": "TQC3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Trần Quý Cáp 3",
        "diaChiLapD": "",
        "longitude": 105.83914552648116,
        "latitude": 21.022019732210566,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3864,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83918039519978,
          21.02267820076359
        ]
      },
      "properties": {
        "tenDoiTuon": "14-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Trần Quý Cáp",
        "tuDien": "TQC3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Trần Quý Cáp 3",
        "diaChiLapD": "",
        "longitude": 105.83918039519978,
        "latitude": 21.02267820076359,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3865,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83910797555481,
          21.022332692911863
        ]
      },
      "properties": {
        "tenDoiTuon": "13",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Trần Quý Cáp",
        "tuDien": "TQC3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Trần Quý Cáp 3",
        "diaChiLapD": "",
        "longitude": 105.83910797555481,
        "latitude": 21.022332692911863,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3866,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83831672389363,
          21.020444903991276
        ]
      },
      "properties": {
        "tenDoiTuon": "6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Trần Quý Cáp",
        "tuDien": "TQC3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Trần Quý Cáp 3",
        "diaChiLapD": "",
        "longitude": 105.83831672389363,
        "latitude": 21.020444903991276,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3867,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83845351655135,
          21.020602637932736
        ]
      },
      "properties": {
        "tenDoiTuon": "7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Trần Quý Cáp",
        "tuDien": "TQC3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Trần Quý Cáp 3",
        "diaChiLapD": "",
        "longitude": 105.83845351655135,
        "latitude": 21.020602637932736,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3868,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83860103804903,
          21.020760371707336
        ]
      },
      "properties": {
        "tenDoiTuon": "8",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Trần Quý Cáp",
        "tuDien": "TQC3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Trần Quý Cáp 3",
        "diaChiLapD": "",
        "longitude": 105.83860103804903,
        "latitude": 21.020760371707336,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3869,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8387485595467,
          21.020832979261804
        ]
      },
      "properties": {
        "tenDoiTuon": "9",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Trần Quý Cáp",
        "tuDien": "TQC3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Trần Quý Cáp 3",
        "diaChiLapD": "",
        "longitude": 105.8387485595467,
        "latitude": 21.020832979261804,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3870,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83816115577271,
          21.02026964386052
        ]
      },
      "properties": {
        "tenDoiTuon": "5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Trần Quý Cáp",
        "tuDien": "TQC3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Trần Quý Cáp 3",
        "diaChiLapD": "",
        "longitude": 105.83816115577271,
        "latitude": 21.02026964386052,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3871,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83800826985957,
          21.020089376082385
        ]
      },
      "properties": {
        "tenDoiTuon": "4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Trần Quý Cáp",
        "tuDien": "TQC3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Trần Quý Cáp 3",
        "diaChiLapD": "",
        "longitude": 105.83800826985957,
        "latitude": 21.020089376082385,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3872,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83781246860457,
          21.01991912298014
        ]
      },
      "properties": {
        "tenDoiTuon": "3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 202,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Trần Quý Cáp",
        "tuDien": "TQC3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Trần Quý Cáp 3",
        "diaChiLapD": "",
        "longitude": 105.83781246860457,
        "latitude": 21.01991912298014,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3873,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82049713299233,
          21.012044391652225
        ]
      },
      "properties": {
        "tenDoiTuon": "1-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 89 Thái Hà",
        "tuDien": "XDN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 10,
        "tuDien2": "Xây Dựng Nhà",
        "diaChiLapD": "",
        "longitude": 105.82049713299233,
        "latitude": 21.012044391652225,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3874,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82032949492762,
          21.011902923736223
        ]
      },
      "properties": {
        "tenDoiTuon": "1-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 89 Thái Hà",
        "tuDien": "XDN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 10,
        "tuDien2": "Xây Dựng Nhà",
        "diaChiLapD": "",
        "longitude": 105.82032949492762,
        "latitude": 21.011902923736223,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3875,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82023286919211,
          21.012567208593893
        ]
      },
      "properties": {
        "tenDoiTuon": "3-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 117 Thái Hà",
        "tuDien": "XDN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Xây Dựng Nhà",
        "diaChiLapD": "",
        "longitude": 105.82023286919211,
        "latitude": 21.012567208593893,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3876,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81993620177394,
          21.012252393820066
        ]
      },
      "properties": {
        "tenDoiTuon": "3-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 117 Thái Hà",
        "tuDien": "XDN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Xây Dựng Nhà",
        "diaChiLapD": "",
        "longitude": 105.81993620177394,
        "latitude": 21.012252393820066,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3877,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81974488359134,
          21.012041008292847
        ]
      },
      "properties": {
        "tenDoiTuon": "3-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 117 Thái Hà",
        "tuDien": "XDN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Xây Dựng Nhà",
        "diaChiLapD": "",
        "longitude": 105.81974488359134,
        "latitude": 21.012041008292847,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3878,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81960353918274,
          21.011889035276962
        ]
      },
      "properties": {
        "tenDoiTuon": "3-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 117 Thái Hà",
        "tuDien": "XDN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Xây Dựng Nhà",
        "diaChiLapD": "",
        "longitude": 105.81960353918274,
        "latitude": 21.011889035276962,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3879,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81951725632135,
          21.011844075453425
        ]
      },
      "properties": {
        "tenDoiTuon": "3-5-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 117 Thái Hà",
        "tuDien": "XDN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Xây Dựng Nhà",
        "diaChiLapD": "",
        "longitude": 105.81951725632135,
        "latitude": 21.011844075453425,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3880,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81942376259626,
          21.01170161720231
        ]
      },
      "properties": {
        "tenDoiTuon": "3-8",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 117 Thái Hà",
        "tuDien": "XDN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Xây Dựng Nhà",
        "diaChiLapD": "",
        "longitude": 105.81942376259626,
        "latitude": 21.01170161720231,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3881,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81927438093834,
          21.011495733260443
        ]
      },
      "properties": {
        "tenDoiTuon": "3-9",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 117 Thái Hà",
        "tuDien": "XDN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Xây Dựng Nhà",
        "diaChiLapD": "",
        "longitude": 105.81927438093834,
        "latitude": 21.011495733260443,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3882,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8194131174741,
          21.01139007429799
        ]
      },
      "properties": {
        "tenDoiTuon": "3-10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 117 Thái Hà",
        "tuDien": "XDN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Xây Dựng Nhà",
        "diaChiLapD": "",
        "longitude": 105.8194131174741,
        "latitude": 21.01139007429799,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3883,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81907007148983,
          21.011577166785454
        ]
      },
      "properties": {
        "tenDoiTuon": "3-11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 117 Thái Hà",
        "tuDien": "XDN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Xây Dựng Nhà",
        "diaChiLapD": "",
        "longitude": 105.81907007148983,
        "latitude": 21.011577166785454,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3884,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81901220056923,
          21.011641305361216
        ]
      },
      "properties": {
        "tenDoiTuon": "3-15",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 117 Thái Hà",
        "tuDien": "XDN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Xây Dựng Nhà",
        "diaChiLapD": "",
        "longitude": 105.81901220056923,
        "latitude": 21.011641305361216,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3885,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81922164328853,
          21.011837147958616
        ]
      },
      "properties": {
        "tenDoiTuon": "3-12",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 117 Thái Hà",
        "tuDien": "XDN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 10,
        "tuDien2": "Xây Dựng Nhà",
        "diaChiLapD": "",
        "longitude": 105.81922164328853,
        "latitude": 21.011837147958616,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3886,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81880564292484,
          21.01149311512191
        ]
      },
      "properties": {
        "tenDoiTuon": "3-13",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 117 Thái Hà",
        "tuDien": "XDN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Xây Dựng Nhà",
        "diaChiLapD": "",
        "longitude": 105.81880564292484,
        "latitude": 21.01149311512191,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3887,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81860446758974,
          21.01172948224529
        ]
      },
      "properties": {
        "tenDoiTuon": "3-14",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 117 Thái Hà",
        "tuDien": "XDN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Xây Dựng Nhà",
        "diaChiLapD": "",
        "longitude": 105.81860446758974,
        "latitude": 21.01172948224529,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3888,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8199644648252,
          21.01186782388677
        ]
      },
      "properties": {
        "tenDoiTuon": "3-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 117 Thái Hà",
        "tuDien": "XDN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Xây Dựng Nhà",
        "diaChiLapD": "",
        "longitude": 105.8199644648252,
        "latitude": 21.01186782388677,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3889,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81928100858238,
          21.011924668343493
        ]
      },
      "properties": {
        "tenDoiTuon": "3-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 117 Thái Hà",
        "tuDien": "XDN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Xây Dựng Nhà",
        "diaChiLapD": "",
        "longitude": 105.81928100858238,
        "latitude": 21.011924668343493,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3890,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81906490234086,
          21.012101620801353
        ]
      },
      "properties": {
        "tenDoiTuon": "3-7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 117 Thái Hà",
        "tuDien": "XDN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Xây Dựng Nhà",
        "diaChiLapD": "",
        "longitude": 105.81906490234086,
        "latitude": 21.012101620801353,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3891,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81142422574185,
          21.01115520723621
        ]
      },
      "properties": {
        "tenDoiTuon": "CBĐ 37",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng Hạ",
        "tuDien": "CBĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "Cục Bản Đồ",
        "diaChiLapD": "",
        "longitude": 105.81142422574185,
        "latitude": 21.01115520723621,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3892,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81162941473292,
          21.01145692334394
        ]
      },
      "properties": {
        "tenDoiTuon": "38",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng Hạ",
        "tuDien": "CBĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "Cục Bản Đồ",
        "diaChiLapD": "",
        "longitude": 105.81162941473292,
        "latitude": 21.01145692334394,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3893,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81189428287769,
          21.01181184586862
        ]
      },
      "properties": {
        "tenDoiTuon": "39",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng Hạ",
        "tuDien": "CBĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "Cục Bản Đồ",
        "diaChiLapD": "",
        "longitude": 105.81189428287769,
        "latitude": 21.01181184586862,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3894,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81212528811155,
          21.012120759229408
        ]
      },
      "properties": {
        "tenDoiTuon": "40",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng Hạ",
        "tuDien": "CBĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "Cục Bản Đồ",
        "diaChiLapD": "",
        "longitude": 105.81212528811155,
        "latitude": 21.012120759229408,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3895,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81236366944815,
          21.0124277940644
        ]
      },
      "properties": {
        "tenDoiTuon": "41",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng Hạ",
        "tuDien": "CBĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "Cục Bản Đồ",
        "diaChiLapD": "",
        "longitude": 105.81236366944815,
        "latitude": 21.0124277940644,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3896,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8125380130412,
          21.012665659556987
        ]
      },
      "properties": {
        "tenDoiTuon": "42",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng Hạ",
        "tuDien": "CBĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "Cục Bản Đồ",
        "diaChiLapD": "",
        "longitude": 105.8125380130412,
        "latitude": 21.012665659556987,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3897,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81125859934639,
          21.011352386928706
        ]
      },
      "properties": {
        "tenDoiTuon": "CBĐ 36",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng Hạ",
        "tuDien": "CBĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "Cục Bản Đồ",
        "diaChiLapD": "",
        "longitude": 105.81125859934639,
        "latitude": 21.011352386928706,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3898,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81152313219499,
          21.011703866882996
        ]
      },
      "properties": {
        "tenDoiTuon": "35",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng Hạ",
        "tuDien": "CBĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "Cục Bản Đồ",
        "diaChiLapD": "",
        "longitude": 105.81152313219499,
        "latitude": 21.011703866882996,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3899,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81173066813356,
          21.011994627510386
        ]
      },
      "properties": {
        "tenDoiTuon": "34",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng Hạ",
        "tuDien": "CBĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "Cục Bản Đồ",
        "diaChiLapD": "",
        "longitude": 105.81173066813356,
        "latitude": 21.011994627510386,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3900,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81195329148396,
          21.012297593845414
        ]
      },
      "properties": {
        "tenDoiTuon": "33",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng Hạ",
        "tuDien": "CBĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "Cục Bản Đồ",
        "diaChiLapD": "",
        "longitude": 105.81195329148396,
        "latitude": 21.012297593845414,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3901,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81217859704206,
          21.01259054417978
        ]
      },
      "properties": {
        "tenDoiTuon": "32",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng Hạ",
        "tuDien": "CBĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "Cục Bản Đồ",
        "diaChiLapD": "",
        "longitude": 105.81217859704206,
        "latitude": 21.01259054417978,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3902,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81237439829707,
          21.012863463204667
        ]
      },
      "properties": {
        "tenDoiTuon": "CBĐ 31",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng Hạ",
        "tuDien": "CBĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "Cục Bản Đồ",
        "diaChiLapD": "",
        "longitude": 105.81237439829707,
        "latitude": 21.012863463204667,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3903,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8127552719761,
          21.01322401601173
        ]
      },
      "properties": {
        "tenDoiTuon": "29",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng Hạ",
        "tuDien": "CBĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "Cục Bản Đồ",
        "diaChiLapD": "",
        "longitude": 105.8127552719761,
        "latitude": 21.01322401601173,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3904,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81256751734433,
          21.01294608996616
        ]
      },
      "properties": {
        "tenDoiTuon": "CBĐ 30",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng Hạ",
        "tuDien": "CBĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "Cục Bản Đồ",
        "diaChiLapD": "",
        "longitude": 105.81256751734433,
        "latitude": 21.01294608996616,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3905,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81302262085161,
          21.013641477227075
        ]
      },
      "properties": {
        "tenDoiTuon": "28",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng Hạ",
        "tuDien": "CBĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "Cục Bản Đồ",
        "diaChiLapD": "",
        "longitude": 105.81302262085161,
        "latitude": 21.013641477227075,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3906,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81320588309235,
          21.013947622838838
        ]
      },
      "properties": {
        "tenDoiTuon": "27",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng Hạ",
        "tuDien": "CBĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "Cục Bản Đồ",
        "diaChiLapD": "",
        "longitude": 105.81320588309235,
        "latitude": 21.013947622838838,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3907,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8134124131873,
          21.014260600482544
        ]
      },
      "properties": {
        "tenDoiTuon": "26",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng Hạ",
        "tuDien": "CBĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "Cục Bản Đồ",
        "diaChiLapD": "",
        "longitude": 105.8134124131873,
        "latitude": 21.014260600482544,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3908,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81354920584504,
          21.014475928718827
        ]
      },
      "properties": {
        "tenDoiTuon": "25",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng Hạ",
        "tuDien": "CBĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "Cục Bản Đồ",
        "diaChiLapD": "",
        "longitude": 105.81354920584504,
        "latitude": 21.014475928718827,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3909,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81369940955045,
          21.014713790944345
        ]
      },
      "properties": {
        "tenDoiTuon": "CBĐ 24",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng Hạ",
        "tuDien": "CBĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "Cục Bản Đồ",
        "diaChiLapD": "",
        "longitude": 105.81369940955045,
        "latitude": 21.014713790944345,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3910,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81388045865387,
          21.014977942764233
        ]
      },
      "properties": {
        "tenDoiTuon": "23",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng Hạ",
        "tuDien": "CBĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "Cục Bản Đồ",
        "diaChiLapD": "",
        "longitude": 105.81388045865387,
        "latitude": 21.014977942764233,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3911,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81482727843596,
          21.01619228396035
        ]
      },
      "properties": {
        "tenDoiTuon": "CBĐ 19",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng Hạ",
        "tuDien": "CBĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "Cục Bản Đồ",
        "diaChiLapD": "",
        "longitude": 105.81482727843596,
        "latitude": 21.01619228396035,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3912,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81460733729332,
          21.01586929520512
        ]
      },
      "properties": {
        "tenDoiTuon": "20",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng Hạ",
        "tuDien": "CBĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "Cục Bản Đồ",
        "diaChiLapD": "",
        "longitude": 105.81460733729332,
        "latitude": 21.01586929520512,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3913,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81439276057515,
          21.015556320938057
        ]
      },
      "properties": {
        "tenDoiTuon": "21",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng Hạ",
        "tuDien": "CBĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "Cục Bản Đồ",
        "diaChiLapD": "",
        "longitude": 105.81439276057515,
        "latitude": 21.015556320938057,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3914,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81419427710343,
          21.015248353618297
        ]
      },
      "properties": {
        "tenDoiTuon": "22",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng Hạ",
        "tuDien": "CBĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "Cục Bản Đồ",
        "diaChiLapD": "",
        "longitude": 105.81419427710343,
        "latitude": 21.015248353618297,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3915,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81460733729332,
          21.016337503483424
        ]
      },
      "properties": {
        "tenDoiTuon": "15",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng Hạ",
        "tuDien": "CBĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "Cục Bản Đồ",
        "diaChiLapD": "",
        "longitude": 105.81460733729332,
        "latitude": 21.016337503483424,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3916,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81440617161385,
          21.016034545353637
        ]
      },
      "properties": {
        "tenDoiTuon": "16",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng Hạ",
        "tuDien": "CBĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "Cục Bản Đồ",
        "diaChiLapD": "",
        "longitude": 105.81440617161385,
        "latitude": 21.016034545353637,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3917,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81420500594336,
          21.015716563845995
        ]
      },
      "properties": {
        "tenDoiTuon": "17",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng Hạ",
        "tuDien": "CBĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "Cục Bản Đồ",
        "diaChiLapD": "",
        "longitude": 105.81420500594336,
        "latitude": 21.015716563845995,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3918,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81400652247166,
          21.015406093057376
        ]
      },
      "properties": {
        "tenDoiTuon": "18",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng Hạ",
        "tuDien": "CBĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "Cục Bản Đồ",
        "diaChiLapD": "",
        "longitude": 105.81400652247166,
        "latitude": 21.015406093057376,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3919,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81465561705963,
          21.01647771530244
        ]
      },
      "properties": {
        "tenDoiTuon": "CBĐ 14",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - đèn pha",
        "tenDuongPh": "Láng Hạ",
        "tuDien": "CBĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 14,
        "tuDien2": "Cục Bản Đồ",
        "diaChiLapD": "",
        "longitude": 105.81465561705963,
        "latitude": 21.01647771530244,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3920,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81483800726693,
          21.015623923437666
        ]
      },
      "properties": {
        "tenDoiTuon": "CBĐ 20-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 93 Phố Láng Hạ",
        "tuDien": "CBĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Cục Bản Đồ",
        "diaChiLapD": "",
        "longitude": 105.81483800726693,
        "latitude": 21.015623923437666,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3921,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81495334225376,
          21.015393574062163
        ]
      },
      "properties": {
        "tenDoiTuon": "CBĐ 20-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 93 Phố Láng Hạ",
        "tuDien": "CBĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Cục Bản Đồ",
        "diaChiLapD": "",
        "longitude": 105.81495334225376,
        "latitude": 21.015393574062163,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3922,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8149104269119,
          21.01522081179663
        ]
      },
      "properties": {
        "tenDoiTuon": "CBĐ 20-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 93 Phố Láng Hạ",
        "tuDien": "CBĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Cục Bản Đồ",
        "diaChiLapD": "",
        "longitude": 105.8149104269119,
        "latitude": 21.01522081179663,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3923,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81474949436652,
          21.015000477022678
        ]
      },
      "properties": {
        "tenDoiTuon": "CBĐ 20-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 93 Phố Láng Hạ",
        "tuDien": "CBĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Cục Bản Đồ",
        "diaChiLapD": "",
        "longitude": 105.81474949436652,
        "latitude": 21.015000477022678,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3924,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81377585249804,
          21.016254878601565
        ]
      },
      "properties": {
        "tenDoiTuon": "16-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 22 Phố Láng Hạ",
        "tuDien": "CBĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Cục Bản Đồ",
        "diaChiLapD": "",
        "longitude": 105.81377585249804,
        "latitude": 21.016254878601565,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3925,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8136792929744,
          21.016107155211735
        ]
      },
      "properties": {
        "tenDoiTuon": "16-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 22 Phố Láng Hạ",
        "tuDien": "CBĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Cục Bản Đồ",
        "diaChiLapD": "",
        "longitude": 105.8136792929744,
        "latitude": 21.016107155211735,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3926,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8135827334508,
          21.015956927885092
        ]
      },
      "properties": {
        "tenDoiTuon": "CBĐ 16-7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 22 Phố Láng Hạ",
        "tuDien": "CBĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Cục Bản Đồ",
        "diaChiLapD": "",
        "longitude": 105.8135827334508,
        "latitude": 21.015956927885092,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3927,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82660184072368,
          21.002726001597715
        ]
      },
      "properties": {
        "tenDoiTuon": "01",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 354 Trường Chinh",
        "tuDien": "TB2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Tàu Bay 2",
        "diaChiLapD": "",
        "longitude": 105.82660184072368,
        "latitude": 21.002726001597715,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3928,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82695857452404,
          21.002791105898268
        ]
      },
      "properties": {
        "tenDoiTuon": "02",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 354 Trường Chinh",
        "tuDien": "TB2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Tàu Bay 2",
        "diaChiLapD": "",
        "longitude": 105.82695857452404,
        "latitude": 21.002791105898268,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3929,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8269518690047,
          21.00296137855026
        ]
      },
      "properties": {
        "tenDoiTuon": "2-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 354/99 Trường Chinh",
        "tuDien": "TB2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Tàu Bay 2",
        "diaChiLapD": "",
        "longitude": 105.8269518690047,
        "latitude": 21.00296137855026,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3930,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82689554259719,
          21.003209275151104
        ]
      },
      "properties": {
        "tenDoiTuon": "TB2 2-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 354/99 Trường Chinh",
        "tuDien": "TB2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Tàu Bay 2",
        "diaChiLapD": "",
        "longitude": 105.82689554259719,
        "latitude": 21.003209275151104,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3931,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82670108244608,
          21.002912550382995
        ]
      },
      "properties": {
        "tenDoiTuon": "2-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 354/99 Trường Chinh",
        "tuDien": "TB2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Tàu Bay 2",
        "diaChiLapD": "",
        "longitude": 105.82670108244608,
        "latitude": 21.002912550382995,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3932,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82663134500883,
          21.00307531087725
        ]
      },
      "properties": {
        "tenDoiTuon": "2-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 354/99 Trường Chinh",
        "tuDien": "TB2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Tàu Bay 2",
        "diaChiLapD": "",
        "longitude": 105.82663134500883,
        "latitude": 21.00307531087725,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3933,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82622096703567,
          21.003010206700697
        ]
      },
      "properties": {
        "tenDoiTuon": "TB2 2-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 354/99 Trường Chinh",
        "tuDien": "TB2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Tàu Bay 2",
        "diaChiLapD": "",
        "longitude": 105.82622096703567,
        "latitude": 21.003010206700697,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3934,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82653076217359,
          21.003387059328134
        ]
      },
      "properties": {
        "tenDoiTuon": "2-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 354/99 Trường Chinh",
        "tuDien": "TB2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Tàu Bay 2",
        "diaChiLapD": "",
        "longitude": 105.82653076217359,
        "latitude": 21.003387059328134,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3935,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8262102382047,
          21.00329691526542
        ]
      },
      "properties": {
        "tenDoiTuon": "2-7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 354/99 Trường Chinh",
        "tuDien": "TB2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Tàu Bay 2",
        "diaChiLapD": "",
        "longitude": 105.8262102382047,
        "latitude": 21.00329691526542,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3936,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81151139753837,
          21.011796196744207
        ]
      },
      "properties": {
        "tenDoiTuon": "35-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 90 Phố Láng Hạ",
        "tuDien": "CBĐ",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Cục Bản Đồ",
        "diaChiLapD": "",
        "longitude": 105.81151139753837,
        "latitude": 21.011796196744207,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3937,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8112834097725,
          21.011946428261638
        ]
      },
      "properties": {
        "tenDoiTuon": "35-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 90 Phố Láng Hạ",
        "tuDien": "CBĐ",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Cục Bản Đồ",
        "diaChiLapD": "",
        "longitude": 105.8112834097725,
        "latitude": 21.011946428261638,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3938,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81110638397168,
          21.01201904010744
        ]
      },
      "properties": {
        "tenDoiTuon": "CBĐ 35-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 90 Phố Láng Hạ",
        "tuDien": "CBĐ",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Cục Bản Đồ",
        "diaChiLapD": "",
        "longitude": 105.81110638397168,
        "latitude": 21.01201904010744,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3939,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82644627259378,
          21.00367501916721
        ]
      },
      "properties": {
        "tenDoiTuon": "2-9",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 354/99 Trường Chinh",
        "tuDien": "TB2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Tàu Bay 2",
        "diaChiLapD": "",
        "longitude": 105.82644627259378,
        "latitude": 21.00367501916721,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3940,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82634971307016,
          21.00386532275561
        ]
      },
      "properties": {
        "tenDoiTuon": "TB2 2-10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 354/99 Trường Chinh",
        "tuDien": "TB2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Tàu Bay 2",
        "diaChiLapD": "",
        "longitude": 105.82634971307016,
        "latitude": 21.00386532275561,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3941,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8260680811225,
          21.00337453932345
        ]
      },
      "properties": {
        "tenDoiTuon": "TB2 2-8",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 354/99 Trường Chinh",
        "tuDien": "TB2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Tàu Bay 2",
        "diaChiLapD": "",
        "longitude": 105.8260680811225,
        "latitude": 21.00337453932345,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3942,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81225939856154,
          21.01184283112821
        ]
      },
      "properties": {
        "tenDoiTuon": "CBĐ 40-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 113 Phố Láng Hạ",
        "tuDien": "CBĐ",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Cục Bản Đồ",
        "diaChiLapD": "",
        "longitude": 105.81225939856154,
        "latitude": 21.01184283112821,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3943,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81240960226694,
          21.012065674421763
        ]
      },
      "properties": {
        "tenDoiTuon": "40-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 113 Phố Láng Hạ",
        "tuDien": "CBĐ",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "Cục Bản Đồ",
        "diaChiLapD": "",
        "longitude": 105.81240960226694,
        "latitude": 21.012065674421763,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3944,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81257321702006,
          21.012225921079104
        ]
      },
      "properties": {
        "tenDoiTuon": "40-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 113 Phố Láng Hạ",
        "tuDien": "CBĐ",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Cục Bản Đồ",
        "diaChiLapD": "",
        "longitude": 105.81257321702006,
        "latitude": 21.012225921079104,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3945,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81269928084681,
          21.01247630613558
        ]
      },
      "properties": {
        "tenDoiTuon": "40-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 113 Phố Láng Hạ",
        "tuDien": "CBĐ",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "Cục Bản Đồ",
        "diaChiLapD": "",
        "longitude": 105.81269928084681,
        "latitude": 21.01247630613558,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3946,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8272053377441,
          21.002823658040818
        ]
      },
      "properties": {
        "tenDoiTuon": "03",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 354 Trường Chinh",
        "tuDien": "TB2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Tàu Bay 2",
        "diaChiLapD": "",
        "longitude": 105.8272053377441,
        "latitude": 21.002823658040818,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3947,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82747892305956,
          21.002886258288893
        ]
      },
      "properties": {
        "tenDoiTuon": "04",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 354 Trường Chinh",
        "tuDien": "TB2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Tàu Bay 2",
        "diaChiLapD": "",
        "longitude": 105.82747892305956,
        "latitude": 21.002886258288893,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3948,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82779944703744,
          21.002879998265687
        ]
      },
      "properties": {
        "tenDoiTuon": "05",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 354 Trường Chinh",
        "tuDien": "TB2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Tàu Bay 2",
        "diaChiLapD": "",
        "longitude": 105.82779944703744,
        "latitude": 21.002879998265687,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3949,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82800061270792,
          21.002878746260684
        ]
      },
      "properties": {
        "tenDoiTuon": "06",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 354 Trường Chinh",
        "tuDien": "TB2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Tàu Bay 2",
        "diaChiLapD": "",
        "longitude": 105.82800061270792,
        "latitude": 21.002878746260684,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3950,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82790539428817,
          21.002669661317224
        ]
      },
      "properties": {
        "tenDoiTuon": "5-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 22 Tôn Thất Tùng",
        "tuDien": "TB2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Tàu Bay 2",
        "diaChiLapD": "",
        "longitude": 105.82790539428817,
        "latitude": 21.002669661317224,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3951,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82836304618401,
          21.002855271171747
        ]
      },
      "properties": {
        "tenDoiTuon": "07",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 354 Trường Chinh",
        "tuDien": "TB2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Tàu Bay 2",
        "diaChiLapD": "",
        "longitude": 105.82836304618401,
        "latitude": 21.002855271171747,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3952,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8287895174216,
          21.002795174921296
        ]
      },
      "properties": {
        "tenDoiTuon": "08",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 354 Trường Chinh",
        "tuDien": "TB2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Tàu Bay 2",
        "diaChiLapD": "",
        "longitude": 105.8287895174216,
        "latitude": 21.002795174921296,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3953,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8290389628584,
          21.0027526067293
        ]
      },
      "properties": {
        "tenDoiTuon": "09",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 354 Trường Chinh",
        "tuDien": "TB2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Tàu Bay 2",
        "diaChiLapD": "",
        "longitude": 105.8290389628584,
        "latitude": 21.0027526067293,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3954,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8293152303816,
          21.002707534513178
        ]
      },
      "properties": {
        "tenDoiTuon": "10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 354 Trường Chinh",
        "tuDien": "TB2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Tàu Bay 2",
        "diaChiLapD": "",
        "longitude": 105.8293152303816,
        "latitude": 21.002707534513178,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3955,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82938496781881,
          21.00260987799658
        ]
      },
      "properties": {
        "tenDoiTuon": "14",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Sân D22 Tôn Thất Tùng",
        "tuDien": "TB2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Tàu Bay 2",
        "diaChiLapD": "",
        "longitude": 105.82938496781881,
        "latitude": 21.00260987799658,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3956,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82953517152423,
          21.002584837854123
        ]
      },
      "properties": {
        "tenDoiTuon": "TB2 13",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Sân D22 Tôn Thất Tùng",
        "tuDien": "TB2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Tàu Bay 2",
        "diaChiLapD": "",
        "longitude": 105.82953517152423,
        "latitude": 21.002584837854123,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3957,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82917575551613,
          21.002642430176135
        ]
      },
      "properties": {
        "tenDoiTuon": "15",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Sân D22 Tôn Thất Tùng",
        "tuDien": "TB2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Tàu Bay 2",
        "diaChiLapD": "",
        "longitude": 105.82917575551613,
        "latitude": 21.002642430176135,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3958,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82900409413979,
          21.002522237479628
        ]
      },
      "properties": {
        "tenDoiTuon": "11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 354 Trường Chinh",
        "tuDien": "TB2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Tàu Bay 2",
        "diaChiLapD": "",
        "longitude": 105.82900409413979,
        "latitude": 21.002522237479628,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3959,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82930718375836,
          21.002459637078015
        ]
      },
      "properties": {
        "tenDoiTuon": "TB2 12",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 354 Trường Chinh",
        "tuDien": "TB2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Tàu Bay 2",
        "diaChiLapD": "",
        "longitude": 105.82930718375836,
        "latitude": 21.002459637078015,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3960,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82874123765531,
          21.00256480573731
        ]
      },
      "properties": {
        "tenDoiTuon": "8-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 22 Tôn Thất Tùng",
        "tuDien": "TB2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Tàu Bay 2",
        "diaChiLapD": "",
        "longitude": 105.82874123765531,
        "latitude": 21.00256480573731,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3961,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82843814803672,
          21.003075623883333
        ]
      },
      "properties": {
        "tenDoiTuon": "7-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 22 Tôn Thất Tùng",
        "tuDien": "TB2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Tàu Bay 2",
        "diaChiLapD": "",
        "longitude": 105.82843814803672,
        "latitude": 21.003075623883333,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3962,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82799826576043,
          21.00311819198317
        ]
      },
      "properties": {
        "tenDoiTuon": "6-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 354/159 Trường Chinh",
        "tuDien": "TB2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Tàu Bay 2",
        "diaChiLapD": "",
        "longitude": 105.82799826576043,
        "latitude": 21.00311819198317,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3963,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82764153196004,
          21.00313321601596
        ]
      },
      "properties": {
        "tenDoiTuon": "6-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 354/159 Trường Chinh",
        "tuDien": "TB2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Tàu Bay 2",
        "diaChiLapD": "",
        "longitude": 105.82764153196004,
        "latitude": 21.00313321601596,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3964,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82768008871207,
          21.003440269345877
        ]
      },
      "properties": {
        "tenDoiTuon": "TB2 6-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 354/159 Trường Chinh",
        "tuDien": "TB2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Tàu Bay 2",
        "diaChiLapD": "",
        "longitude": 105.82768008871207,
        "latitude": 21.003440269345877,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3965,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82803279920081,
          21.00330380127732
        ]
      },
      "properties": {
        "tenDoiTuon": "6-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 354/159 Trường Chinh",
        "tuDien": "TB2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Tàu Bay 2",
        "diaChiLapD": "",
        "longitude": 105.82803279920081,
        "latitude": 21.00330380127732,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3966,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82807303233491,
          21.00351789332932
        ]
      },
      "properties": {
        "tenDoiTuon": "6-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 354/159 Trường Chinh",
        "tuDien": "TB2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Tàu Bay 2",
        "diaChiLapD": "",
        "longitude": 105.82807303233491,
        "latitude": 21.00351789332932,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3967,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82832113666785,
          21.003526657324958
        ]
      },
      "properties": {
        "tenDoiTuon": "6-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 354/159 Trường Chinh",
        "tuDien": "TB2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Tàu Bay 2",
        "diaChiLapD": "",
        "longitude": 105.82832113666785,
        "latitude": 21.003526657324958,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3968,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82863629623023,
          21.00351413733118
        ]
      },
      "properties": {
        "tenDoiTuon": "6-7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 354/159 Trường Chinh",
        "tuDien": "TB2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Tàu Bay 2",
        "diaChiLapD": "",
        "longitude": 105.82863629623023,
        "latitude": 21.00351413733118,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3969,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82890719933796,
          21.003486593340654
        ]
      },
      "properties": {
        "tenDoiTuon": "6-8",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 354/159 Trường Chinh",
        "tuDien": "TB2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Tàu Bay 2",
        "diaChiLapD": "",
        "longitude": 105.82890719933796,
        "latitude": 21.003486593340654,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3970,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82895413800038,
          21.003730733077187
        ]
      },
      "properties": {
        "tenDoiTuon": "6-9",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 354/159 Trường Chinh",
        "tuDien": "TB2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Tàu Bay 2",
        "diaChiLapD": "",
        "longitude": 105.82895413800038,
        "latitude": 21.003730733077187,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3971,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82907349629879,
          21.003830892852722
        ]
      },
      "properties": {
        "tenDoiTuon": "6-10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 354/159 Trường Chinh",
        "tuDien": "TB2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Tàu Bay 2",
        "diaChiLapD": "",
        "longitude": 105.82907349629879,
        "latitude": 21.003830892852722,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3972,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8290600852511,
          21.003957344474365
        ]
      },
      "properties": {
        "tenDoiTuon": "6-11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 354/159 Trường Chinh",
        "tuDien": "TB2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Tàu Bay 2",
        "diaChiLapD": "",
        "longitude": 105.8290600852511,
        "latitude": 21.003957344474365,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3973,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82916603251083,
          21.004127615794868
        ]
      },
      "properties": {
        "tenDoiTuon": "TB2 6-12",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 354/159 Trường Chinh",
        "tuDien": "TB2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Tàu Bay 2",
        "diaChiLapD": "",
        "longitude": 105.82916603251083,
        "latitude": 21.004127615794868,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3974,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82808778448648,
          21.00373448906991
        ]
      },
      "properties": {
        "tenDoiTuon": "6-13",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 354/159 Trường Chinh",
        "tuDien": "TB2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Tàu Bay 2",
        "diaChiLapD": "",
        "longitude": 105.82808778448648,
        "latitude": 21.00373448906991,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3975,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82818434401011,
          21.00373448906991
        ]
      },
      "properties": {
        "tenDoiTuon": "6-14",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 354/159 Trường Chinh",
        "tuDien": "TB2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Tàu Bay 2",
        "diaChiLapD": "",
        "longitude": 105.82818434401011,
        "latitude": 21.00373448906991,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3976,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8281897084256,
          21.00387721672711
        ]
      },
      "properties": {
        "tenDoiTuon": "6-15",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 354/159 Trường Chinh",
        "tuDien": "TB2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Tàu Bay 2",
        "diaChiLapD": "",
        "longitude": 105.8281897084256,
        "latitude": 21.00387721672711,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3977,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82838819189732,
          21.003889736690407
        ]
      },
      "properties": {
        "tenDoiTuon": "6-18",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 354/159 Trường Chinh",
        "tuDien": "TB2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Tàu Bay 2",
        "diaChiLapD": "",
        "longitude": 105.82838819189732,
        "latitude": 21.003889736690407,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3978,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82853034897053,
          21.00383715283686
        ]
      },
      "properties": {
        "tenDoiTuon": "TB2 6-20",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 354/159 Trường Chinh",
        "tuDien": "TB2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Tàu Bay 2",
        "diaChiLapD": "",
        "longitude": 105.82853034897053,
        "latitude": 21.00383715283686,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3979,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82839892072829,
          21.00407002404683
        ]
      },
      "properties": {
        "tenDoiTuon": "TB2 6-19",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 354/159 Trường Chinh",
        "tuDien": "TB2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Tàu Bay 2",
        "diaChiLapD": "",
        "longitude": 105.82839892072829,
        "latitude": 21.00407002404683,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3980,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82804889245627,
          21.003912272622966
        ]
      },
      "properties": {
        "tenDoiTuon": "6-16",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 354/159 Trường Chinh",
        "tuDien": "TB2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Tàu Bay 2",
        "diaChiLapD": "",
        "longitude": 105.82804889245627,
        "latitude": 21.003912272622966,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3981,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82804889245627,
          21.004080040004958
        ]
      },
      "properties": {
        "tenDoiTuon": "TB2 6-17",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 354/159 Trường Chinh",
        "tuDien": "TB2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Tàu Bay 2",
        "diaChiLapD": "",
        "longitude": 105.82804889245627,
        "latitude": 21.004080040004958,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3982,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82715169353537,
          21.003052148825365
        ]
      },
      "properties": {
        "tenDoiTuon": "3-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 22 Tôn Thất Tùng",
        "tuDien": "TB2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Tàu Bay 2",
        "diaChiLapD": "",
        "longitude": 105.82715169353537,
        "latitude": 21.003052148825365,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3983,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82748428745707,
          21.003217413170887
        ]
      },
      "properties": {
        "tenDoiTuon": "4-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 354/137 Trường Chinh",
        "tuDien": "TB2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Tàu Bay 2",
        "diaChiLapD": "",
        "longitude": 105.82748428745707,
        "latitude": 21.003217413170887,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3984,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82749903960865,
          21.00349660933962
        ]
      },
      "properties": {
        "tenDoiTuon": "4-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 354/137 Trường Chinh",
        "tuDien": "TB2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Tàu Bay 2",
        "diaChiLapD": "",
        "longitude": 105.82749903960865,
        "latitude": 21.00349660933962,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3985,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82712084813734,
          21.003506625336236
        ]
      },
      "properties": {
        "tenDoiTuon": "4-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 354/137 Trường Chinh",
        "tuDien": "TB2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Tàu Bay 2",
        "diaChiLapD": "",
        "longitude": 105.82712084813734,
        "latitude": 21.003506625336236,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3986,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82707022146846,
          21.003781751954442
        ]
      },
      "properties": {
        "tenDoiTuon": "4-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 354/137 Trường Chinh",
        "tuDien": "TB2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Tàu Bay 2",
        "diaChiLapD": "",
        "longitude": 105.82707022146846,
        "latitude": 21.003781751954442,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3987,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82691197113085,
          21.003756712008634
        ]
      },
      "properties": {
        "tenDoiTuon": "TB2 4-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 354/137 Trường Chinh",
        "tuDien": "TB2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Tàu Bay 2",
        "diaChiLapD": "",
        "longitude": 105.82691197113085,
        "latitude": 21.003756712008634,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3988,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82749669269707,
          21.003791767931947
        ]
      },
      "properties": {
        "tenDoiTuon": "4-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 354/137 Trường Chinh",
        "tuDien": "TB2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Tàu Bay 2",
        "diaChiLapD": "",
        "longitude": 105.82749669269707,
        "latitude": 21.003791767931947,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3989,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82752887920795,
          21.004034655173108
        ]
      },
      "properties": {
        "tenDoiTuon": "TB2 4-7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 354/137 Trường Chinh",
        "tuDien": "TB2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Tàu Bay 2",
        "diaChiLapD": "",
        "longitude": 105.82752887920795,
        "latitude": 21.004034655173108,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3990,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82734112457618,
          21.003741688039476
        ]
      },
      "properties": {
        "tenDoiTuon": "4-8",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 354/137 Trường Chinh",
        "tuDien": "TB2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Tàu Bay 2",
        "diaChiLapD": "",
        "longitude": 105.82734112457618,
        "latitude": 21.003741688039476,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3991,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8272821159789,
          21.003929487550465
        ]
      },
      "properties": {
        "tenDoiTuon": "4-9",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 354/137 Trường Chinh",
        "tuDien": "TB2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Tàu Bay 2",
        "diaChiLapD": "",
        "longitude": 105.8272821159789,
        "latitude": 21.003929487550465,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3992,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82712923006575,
          21.004282549991938
        ]
      },
      "properties": {
        "tenDoiTuon": "TB2 4-10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 354/137 Trường Chinh",
        "tuDien": "TB2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Tàu Bay 2",
        "diaChiLapD": "",
        "longitude": 105.82712923006575,
        "latitude": 21.004282549991938,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3993,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82602147774726,
          21.00460431184382
        ]
      },
      "properties": {
        "tenDoiTuon": "01",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 123 Khương Thượng",
        "tuDien": "KTC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khương Thượng C",
        "diaChiLapD": "",
        "longitude": 105.82602147774726,
        "latitude": 21.00460431184382,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3994,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82582299427554,
          21.004644375528073
        ]
      },
      "properties": {
        "tenDoiTuon": "15",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Khương Thượng",
        "tuDien": "KTC",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khương Thượng C",
        "diaChiLapD": "",
        "longitude": 105.82582299427554,
        "latitude": 21.004644375528073,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3995,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82601711915744,
          21.004426216120407
        ]
      },
      "properties": {
        "tenDoiTuon": "1-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 123 Khương Thượng",
        "tuDien": "KTC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Khương Thượng C",
        "diaChiLapD": "",
        "longitude": 105.82601711915744,
        "latitude": 21.004426216120407,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3996,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8262719290187,
          21.004460019894708
        ]
      },
      "properties": {
        "tenDoiTuon": "02",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 123 Khương Thượng",
        "tuDien": "KTC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Khương Thượng C",
        "diaChiLapD": "",
        "longitude": 105.8262719290187,
        "latitude": 21.004460019894708,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3997,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82641676829965,
          21.004396168314504
        ]
      },
      "properties": {
        "tenDoiTuon": "KTC 03",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 123 Khương Thượng",
        "tuDien": "KTC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Khương Thượng C",
        "diaChiLapD": "",
        "longitude": 105.82641676829965,
        "latitude": 21.004396168314504,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3998,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82630277441672,
          21.00467911824682
        ]
      },
      "properties": {
        "tenDoiTuon": "KTC 1-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 123 Khương Thượng",
        "tuDien": "KTC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Khương Thượng C",
        "diaChiLapD": "",
        "longitude": 105.82630277441672,
        "latitude": 21.00467911824682,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 3999,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83776150662153,
          21.019654979910904
        ]
      },
      "properties": {
        "tenDoiTuon": "2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 202,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Trần Quý Cáp",
        "tuDien": "TQC3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Trần Quý Cáp 3",
        "diaChiLapD": "",
        "longitude": 105.83776150662153,
        "latitude": 21.019654979910904,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4000,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83761398512385,
          21.019349524788186
        ]
      },
      "properties": {
        "tenDoiTuon": "1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 202,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Trần Quý Cáp",
        "tuDien": "TQC3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Trần Quý Cáp 3",
        "diaChiLapD": "",
        "longitude": 105.83761398512385,
        "latitude": 21.019349524788186,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4001,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83988715726319,
          21.025703863927614
        ]
      },
      "properties": {
        "tenDoiTuon": "23",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Trần Quý Cáp",
        "tuDien": "TQC3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Trần Quý Cáp 3",
        "diaChiLapD": "",
        "longitude": 105.83988715726319,
        "latitude": 21.025703863927614,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4002,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83945263940237,
          21.023237771558613
        ]
      },
      "properties": {
        "tenDoiTuon": "15",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Trần Quý Cáp",
        "tuDien": "TQC3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Trần Quý Cáp 3",
        "diaChiLapD": "",
        "longitude": 105.83945263940237,
        "latitude": 21.023237771558613,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4003,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83930780012142,
          21.022962367847928
        ]
      },
      "properties": {
        "tenDoiTuon": "14",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Trần Quý Cáp",
        "tuDien": "TQC3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Trần Quý Cáp 3",
        "diaChiLapD": "",
        "longitude": 105.83930780012142,
        "latitude": 21.022962367847928,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4004,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83950628358417,
          21.023575766326015
        ]
      },
      "properties": {
        "tenDoiTuon": "16",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Trần Quý Cáp",
        "tuDien": "TQC3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Trần Quý Cáp 3",
        "diaChiLapD": "",
        "longitude": 105.83950628358417,
        "latitude": 21.023575766326015,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4005,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83953310567956,
          21.024299322919482
        ]
      },
      "properties": {
        "tenDoiTuon": "18",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Trần Quý Cáp",
        "tuDien": "TQC3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Trần Quý Cáp 3",
        "diaChiLapD": "",
        "longitude": 105.83953310567956,
        "latitude": 21.024299322919482,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4006,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83987374622448,
          21.024634811636098
        ]
      },
      "properties": {
        "tenDoiTuon": "20",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Trần Quý Cáp",
        "tuDien": "TQC3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Trần Quý Cáp 3",
        "diaChiLapD": "",
        "longitude": 105.83987374622448,
        "latitude": 21.024634811636098,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4007,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83988715726319,
          21.02502537963846
        ]
      },
      "properties": {
        "tenDoiTuon": "21",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Trần Quý Cáp",
        "tuDien": "TQC3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Trần Quý Cáp 3",
        "diaChiLapD": "",
        "longitude": 105.83988715726319,
        "latitude": 21.02502537963846,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4008,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83951164799066,
          21.02387119816177
        ]
      },
      "properties": {
        "tenDoiTuon": "17",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Trần Quý Cáp",
        "tuDien": "TQC3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Trần Quý Cáp 3",
        "diaChiLapD": "",
        "longitude": 105.83951164799066,
        "latitude": 21.02387119816177,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4009,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83954383450153,
          21.02615952322976
        ]
      },
      "properties": {
        "tenDoiTuon": "25",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Phố Trần Quý Cáp",
        "tuDien": "TQC3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Trần Quý Cáp 3",
        "diaChiLapD": "",
        "longitude": 105.83954383450153,
        "latitude": 21.02615952322976,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4010,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83988715726319,
          21.026064385688205
        ]
      },
      "properties": {
        "tenDoiTuon": "24",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Trần Quý Cáp",
        "tuDien": "TQC3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Trần Quý Cáp 3",
        "diaChiLapD": "",
        "longitude": 105.83988715726319,
        "latitude": 21.026064385688205,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4011,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83988179283872,
          21.02534334129504
        ]
      },
      "properties": {
        "tenDoiTuon": "22",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Trần Quý Cáp",
        "tuDien": "TQC3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Trần Quý Cáp 3",
        "diaChiLapD": "",
        "longitude": 105.83988179283872,
        "latitude": 21.02534334129504,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4012,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83987911063996,
          21.024301826569076
        ]
      },
      "properties": {
        "tenDoiTuon": "19",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Trần Quý Cáp",
        "tuDien": "TQC3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Trần Quý Cáp 3",
        "diaChiLapD": "",
        "longitude": 105.83987911063996,
        "latitude": 21.024301826569076,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4013,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8389591129353,
          21.02557617892768
        ]
      },
      "properties": {
        "tenDoiTuon": "27-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Phố Trần Quý Cáp",
        "tuDien": "TQC3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Trần Quý Cáp 3",
        "diaChiLapD": "",
        "longitude": 105.8389591129353,
        "latitude": 21.02557617892768,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4014,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83909322338528,
          21.024905204977507
        ]
      },
      "properties": {
        "tenDoiTuon": "29",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Phố Trần Quý Cáp",
        "tuDien": "TQC3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Trần Quý Cáp 3",
        "diaChiLapD": "",
        "longitude": 105.83909322338528,
        "latitude": 21.024905204977507,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4015,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83892156200893,
          21.02509798511575
        ]
      },
      "properties": {
        "tenDoiTuon": "27-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Phố Trần Quý Cáp",
        "tuDien": "TQC3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Trần Quý Cáp 3",
        "diaChiLapD": "",
        "longitude": 105.83892156200893,
        "latitude": 21.02509798511575,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4016,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83904226141122,
          21.025458508341753
        ]
      },
      "properties": {
        "tenDoiTuon": "27-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Phố Trần Quý Cáp",
        "tuDien": "TQC3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Trần Quý Cáp 3",
        "diaChiLapD": "",
        "longitude": 105.83904226141122,
        "latitude": 21.025458508341753,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4017,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83936144428525,
          21.02529326863767
        ]
      },
      "properties": {
        "tenDoiTuon": "28-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Phố Trần Quý Cáp",
        "tuDien": "TQC3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Trần Quý Cáp 3",
        "diaChiLapD": "",
        "longitude": 105.83936144428525,
        "latitude": 21.02529326863767,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4018,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83924610929843,
          21.025328319499327
        ]
      },
      "properties": {
        "tenDoiTuon": "28",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Phố Trần Quý Cáp",
        "tuDien": "TQC3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Trần Quý Cáp 3",
        "diaChiLapD": "",
        "longitude": 105.83924610929843,
        "latitude": 21.025328319499327,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4019,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8391549141903,
          21.025626251490017
        ]
      },
      "properties": {
        "tenDoiTuon": "27",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Phố Trần Quý Cáp",
        "tuDien": "TQC3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Trần Quý Cáp 3",
        "diaChiLapD": "",
        "longitude": 105.8391549141903,
        "latitude": 21.025626251490017,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4020,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83932657556663,
          21.025551142640605
        ]
      },
      "properties": {
        "tenDoiTuon": "26",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Phố Trần Quý Cáp",
        "tuDien": "TQC3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Trần Quý Cáp 3",
        "diaChiLapD": "",
        "longitude": 105.83932657556663,
        "latitude": 21.025551142640605,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4021,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83891619759346,
          21.02446957101894
        ]
      },
      "properties": {
        "tenDoiTuon": "21-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Phố Trần Quý Cáp",
        "tuDien": "TQC3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Trần Quý Cáp 3",
        "diaChiLapD": "",
        "longitude": 105.83891619759346,
        "latitude": 21.02446957101894,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4022,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83887328224264,
          21.024925234094102
        ]
      },
      "properties": {
        "tenDoiTuon": "30",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Phố Trần Quý Cáp",
        "tuDien": "TQC3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Trần Quý Cáp 3",
        "diaChiLapD": "",
        "longitude": 105.83887328224264,
        "latitude": 21.024925234094102,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4023,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83878208714347,
          21.02470992094637
        ]
      },
      "properties": {
        "tenDoiTuon": "31",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Phố Trần Quý Cáp",
        "tuDien": "TQC3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Trần Quý Cáp 3",
        "diaChiLapD": "",
        "longitude": 105.83878208714347,
        "latitude": 21.02470992094637,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4024,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83876331168031,
          21.02450211842727
        ]
      },
      "properties": {
        "tenDoiTuon": "32",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Phố Trần Quý Cáp",
        "tuDien": "TQC3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Trần Quý Cáp 3",
        "diaChiLapD": "",
        "longitude": 105.83876331168031,
        "latitude": 21.02450211842727,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4025,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83891083316898,
          21.02440197253224
        ]
      },
      "properties": {
        "tenDoiTuon": "33",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Phố Trần Quý Cáp",
        "tuDien": "TQC3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Trần Quý Cáp 3",
        "diaChiLapD": "",
        "longitude": 105.83891083316898,
        "latitude": 21.02440197253224,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4026,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83900202827714,
          21.024810066635396
        ]
      },
      "properties": {
        "tenDoiTuon": "21-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Phố Trần Quý Cáp",
        "tuDien": "TQC3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Trần Quý Cáp 3",
        "diaChiLapD": "",
        "longitude": 105.83900202827714,
        "latitude": 21.024810066635396,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4027,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83956529217245,
          21.025082963295343
        ]
      },
      "properties": {
        "tenDoiTuon": "21-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Phố Trần Quý Cáp",
        "tuDien": "TQC3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Trần Quý Cáp 3",
        "diaChiLapD": "",
        "longitude": 105.83956529217245,
        "latitude": 21.025082963295343,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4028,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83958406763561,
          21.02488016857689
        ]
      },
      "properties": {
        "tenDoiTuon": "21-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Phố Trần Quý Cáp",
        "tuDien": "TQC3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Trần Quý Cáp 3",
        "diaChiLapD": "",
        "longitude": 105.83958406763561,
        "latitude": 21.02488016857689,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4029,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83934266882207,
          21.02488517585734
        ]
      },
      "properties": {
        "tenDoiTuon": "21-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Phố Trần Quý Cáp",
        "tuDien": "TQC3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Trần Quý Cáp 3",
        "diaChiLapD": "",
        "longitude": 105.83934266882207,
        "latitude": 21.02488517585734,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4030,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83928366022481,
          21.024334374014874
        ]
      },
      "properties": {
        "tenDoiTuon": "18-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Phố Trần Quý Cáp",
        "tuDien": "TQC3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Trần Quý Cáp 3",
        "diaChiLapD": "",
        "longitude": 105.83928366022481,
        "latitude": 21.024334374014874,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4031,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83922196941977,
          21.02453466582937
        ]
      },
      "properties": {
        "tenDoiTuon": "18-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Phố Trần Quý Cáp",
        "tuDien": "TQC3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Trần Quý Cáp 3",
        "diaChiLapD": "",
        "longitude": 105.83922196941977,
        "latitude": 21.02453466582937,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4032,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81990355178303,
          21.012784157838773
        ]
      },
      "properties": {
        "tenDoiTuon": "4-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 121+131 Thái Hà",
        "tuDien": "XDN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Xây Dựng Nhà",
        "diaChiLapD": "",
        "longitude": 105.81990355178303,
        "latitude": 21.012784157838773,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4033,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8195984533065,
          21.01247223738661
        ]
      },
      "properties": {
        "tenDoiTuon": "10-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 121+131 Thái Hà",
        "tuDien": "XDN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Xây Dựng Nhà",
        "diaChiLapD": "",
        "longitude": 105.8195984533065,
        "latitude": 21.01247223738661,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4034,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81943361532188,
          21.01234008428809
        ]
      },
      "properties": {
        "tenDoiTuon": "4-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 121+131 Thái Hà",
        "tuDien": "XDN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Xây Dựng Nhà",
        "diaChiLapD": "",
        "longitude": 105.81943361532188,
        "latitude": 21.01234008428809,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4035,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81925781276156,
          21.01218429452687
        ]
      },
      "properties": {
        "tenDoiTuon": "4-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 121+131 Thái Hà",
        "tuDien": "XDN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Xây Dựng Nhà",
        "diaChiLapD": "",
        "longitude": 105.81925781276156,
        "latitude": 21.01218429452687,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4036,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81907641381666,
          21.01231629933597
        ]
      },
      "properties": {
        "tenDoiTuon": "4-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 121+131 Thái Hà",
        "tuDien": "XDN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Xây Dựng Nhà",
        "diaChiLapD": "",
        "longitude": 105.81907641381666,
        "latitude": 21.01231629933597,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4037,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81942568233136,
          21.012631147622265
        ]
      },
      "properties": {
        "tenDoiTuon": "4-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 121+131 Thái Hà",
        "tuDien": "XDN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Xây Dựng Nhà",
        "diaChiLapD": "",
        "longitude": 105.81942568233136,
        "latitude": 21.012631147622265,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4038,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81920148637202,
          21.012832791140198
        ]
      },
      "properties": {
        "tenDoiTuon": "4-8",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 121+131 Thái Hà",
        "tuDien": "XDN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Xây Dựng Nhà",
        "diaChiLapD": "",
        "longitude": 105.81920148637202,
        "latitude": 21.012832791140198,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4039,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81936464070601,
          21.013011495094432
        ]
      },
      "properties": {
        "tenDoiTuon": "4-7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 121+131 Thái Hà",
        "tuDien": "XDN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Xây Dựng Nhà",
        "diaChiLapD": "",
        "longitude": 105.81936464070601,
        "latitude": 21.013011495094432,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4040,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8190123534271,
          21.012630709259632
        ]
      },
      "properties": {
        "tenDoiTuon": "4-9",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 121+131 Thái Hà",
        "tuDien": "XDN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Xây Dựng Nhà",
        "diaChiLapD": "",
        "longitude": 105.8190123534271,
        "latitude": 21.012630709259632,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4041,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8187870457818,
          21.012402600790722
        ]
      },
      "properties": {
        "tenDoiTuon": "4-10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 121+131 Thái Hà",
        "tuDien": "XDN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Xây Dựng Nhà",
        "diaChiLapD": "",
        "longitude": 105.8187870457818,
        "latitude": 21.012402600790722,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4042,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81871868874491,
          21.012236875438596
        ]
      },
      "properties": {
        "tenDoiTuon": "4-13",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 121+131 Thái Hà",
        "tuDien": "XDN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 7,
        "tuDien2": "Xây Dựng Nhà",
        "diaChiLapD": "",
        "longitude": 105.81871868874491,
        "latitude": 21.012236875438596,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4043,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8185899427104,
          21.01221183691091
        ]
      },
      "properties": {
        "tenDoiTuon": "4-11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 121+131 Thái Hà",
        "tuDien": "XDN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 7,
        "tuDien2": "Xây Dựng Nhà",
        "diaChiLapD": "",
        "longitude": 105.8185899427104,
        "latitude": 21.01221183691091,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4044,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8184452967067,
          21.012062595770335
        ]
      },
      "properties": {
        "tenDoiTuon": "4-12",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 121+131 Thái Hà",
        "tuDien": "XDN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 7,
        "tuDien2": "Xây Dựng Nhà",
        "diaChiLapD": "",
        "longitude": 105.8184452967067,
        "latitude": 21.012062595770335,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4045,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83931048230221,
          21.026182055796667
        ]
      },
      "properties": {
        "tenDoiTuon": "25-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Phố Trần Quý Cáp",
        "tuDien": "TQC3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Trần Quý Cáp 3",
        "diaChiLapD": "",
        "longitude": 105.83931048230221,
        "latitude": 21.026182055796667,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4046,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83907713012087,
          21.02619207026995
        ]
      },
      "properties": {
        "tenDoiTuon": "25-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Phố Trần Quý Cáp",
        "tuDien": "TQC3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Trần Quý Cáp 3",
        "diaChiLapD": "",
        "longitude": 105.83907713012087,
        "latitude": 21.02619207026995,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4047,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83916832522902,
          21.02385617621939
        ]
      },
      "properties": {
        "tenDoiTuon": "17-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 51 Trần Quý Cáp",
        "tuDien": "TQC3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Trần Quý Cáp 3",
        "diaChiLapD": "",
        "longitude": 105.83916832522902,
        "latitude": 21.02385617621939,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4048,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83913882092588,
          21.023593291960644
        ]
      },
      "properties": {
        "tenDoiTuon": "17-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 51 Trần Quý Cáp",
        "tuDien": "TQC3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Trần Quý Cáp 3",
        "diaChiLapD": "",
        "longitude": 105.83913882092588,
        "latitude": 21.023593291960644,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4049,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83917637185225,
          21.02337046589286
        ]
      },
      "properties": {
        "tenDoiTuon": "17-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 51 Trần Quý Cáp",
        "tuDien": "TQC3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Trần Quý Cáp 3",
        "diaChiLapD": "",
        "longitude": 105.83917637185225,
        "latitude": 21.02337046589286,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4050,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83918710069219,
          21.02325028989778
        ]
      },
      "properties": {
        "tenDoiTuon": "17-3-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 51 Trần Quý Cáp",
        "tuDien": "TQC3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Trần Quý Cáp 3",
        "diaChiLapD": "",
        "longitude": 105.83918710069219,
        "latitude": 21.02325028989778,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4051,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8385782392473,
          21.023608313930346
        ]
      },
      "properties": {
        "tenDoiTuon": "17-8",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 51 Trần Quý Cáp",
        "tuDien": "TQC3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Trần Quý Cáp 3",
        "diaChiLapD": "",
        "longitude": 105.8385782392473,
        "latitude": 21.023608313930346,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4052,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83882500247633,
          21.02349815278127
        ]
      },
      "properties": {
        "tenDoiTuon": "17-7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 51 Trần Quý Cáp",
        "tuDien": "TQC3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Trần Quý Cáp 3",
        "diaChiLapD": "",
        "longitude": 105.83882500247633,
        "latitude": 21.02349815278127,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4053,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8388089092209,
          21.023580773651123
        ]
      },
      "properties": {
        "tenDoiTuon": "17-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 51 Trần Quý Cáp",
        "tuDien": "TQC3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Trần Quý Cáp 3",
        "diaChiLapD": "",
        "longitude": 105.8388089092209,
        "latitude": 21.023580773651123,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4054,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8389778883895,
          21.023715971338635
        ]
      },
      "properties": {
        "tenDoiTuon": "17-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 51 Trần Quý Cáp",
        "tuDien": "TQC3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Trần Quý Cáp 3",
        "diaChiLapD": "",
        "longitude": 105.8389778883895,
        "latitude": 21.023715971338635,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4055,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83907176570537,
          21.023287844906434
        ]
      },
      "properties": {
        "tenDoiTuon": "17-3-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 51 Trần Quý Cáp",
        "tuDien": "TQC3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Trần Quý Cáp 3",
        "diaChiLapD": "",
        "longitude": 105.83907176570537,
        "latitude": 21.023287844906434,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4056,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83908249453634,
          21.023923774953605
        ]
      },
      "properties": {
        "tenDoiTuon": "17-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 51 Trần Quý Cáp",
        "tuDien": "TQC3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Trần Quý Cáp 3",
        "diaChiLapD": "",
        "longitude": 105.83908249453634,
        "latitude": 21.023923774953605,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4057,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83845485762828,
          21.023650876170727
        ]
      },
      "properties": {
        "tenDoiTuon": "17-9",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 51 Trần Quý Cáp",
        "tuDien": "TQC3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Trần Quý Cáp 3",
        "diaChiLapD": "",
        "longitude": 105.83845485762828,
        "latitude": 21.023650876170727,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4058,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83827246741198,
          21.023873701820214
        ]
      },
      "properties": {
        "tenDoiTuon": "17-10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 51 Trần Quý Cáp",
        "tuDien": "TQC3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Trần Quý Cáp 3",
        "diaChiLapD": "",
        "longitude": 105.83827246741198,
        "latitude": 21.023873701820214,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4059,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83859969691821,
          21.02412657097312
        ]
      },
      "properties": {
        "tenDoiTuon": "17-12",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 51 Trần Quý Cáp",
        "tuDien": "TQC3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Trần Quý Cáp 3",
        "diaChiLapD": "",
        "longitude": 105.83859969691821,
        "latitude": 21.02412657097312,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4060,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83864261226005,
          21.02424924991226
        ]
      },
      "properties": {
        "tenDoiTuon": "17-12-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 51 Trần Quý Cáp",
        "tuDien": "TQC3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Trần Quý Cáp 3",
        "diaChiLapD": "",
        "longitude": 105.83864261226005,
        "latitude": 21.02424924991226,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4061,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83791305140389,
          21.023741007933918
        ]
      },
      "properties": {
        "tenDoiTuon": "17-13",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 51 Trần Quý Cáp",
        "tuDien": "TQC3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Trần Quý Cáp 3",
        "diaChiLapD": "",
        "longitude": 105.83791305140389,
        "latitude": 21.023741007933918,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4062,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83770115689343,
          21.02377105184288
        ]
      },
      "properties": {
        "tenDoiTuon": "17-14",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 51 Trần Quý Cáp",
        "tuDien": "TQC3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Trần Quý Cáp 3",
        "diaChiLapD": "",
        "longitude": 105.83770115689343,
        "latitude": 21.02377105184288,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4063,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8383690269446,
          21.024091519828875
        ]
      },
      "properties": {
        "tenDoiTuon": "17-11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 51 Trần Quý Cáp",
        "tuDien": "TQC3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Trần Quý Cáp 3",
        "diaChiLapD": "",
        "longitude": 105.8383690269446,
        "latitude": 21.024091519828875,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4064,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83813031033878,
          21.023933789578596
        ]
      },
      "properties": {
        "tenDoiTuon": "17-16",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 51 Trần Quý Cáp",
        "tuDien": "TQC3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Trần Quý Cáp 3",
        "diaChiLapD": "",
        "longitude": 105.83813031033878,
        "latitude": 21.023933789578596,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4065,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8385111840178,
          21.02351818208692
        ]
      },
      "properties": {
        "tenDoiTuon": "17-17",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 51 Trần Quý Cáp",
        "tuDien": "TQC3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Trần Quý Cáp 3",
        "diaChiLapD": "",
        "longitude": 105.8385111840178,
        "latitude": 21.02351818208692,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4066,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83780039863379,
          21.023633350544547
        ]
      },
      "properties": {
        "tenDoiTuon": "17-15",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 51 Trần Quý Cáp",
        "tuDien": "TQC3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Trần Quý Cáp 3",
        "diaChiLapD": "",
        "longitude": 105.83780039863379,
        "latitude": 21.023633350544547,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4067,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83933059889621,
          21.02204727277482
        ]
      },
      "properties": {
        "tenDoiTuon": "12-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 88/33  Trần Quý Cáp",
        "tuDien": "TQC3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Trần Quý Cáp 3",
        "diaChiLapD": "",
        "longitude": 105.83933059889621,
        "latitude": 21.02204727277482,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4068,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81433207563174,
          21.009007183742828
        ]
      },
      "properties": {
        "tenDoiTuon": "LH4 01",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "LH4",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 10,
        "tuDien2": "Láng Hạ 4",
        "diaChiLapD": "",
        "longitude": 105.81433207563174,
        "latitude": 21.009007183742828,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4069,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81414414608898,
          21.0091138718217
        ]
      },
      "properties": {
        "tenDoiTuon": "02",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "LH4",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 10,
        "tuDien2": "Láng Hạ 4",
        "diaChiLapD": "",
        "longitude": 105.81414414608898,
        "latitude": 21.0091138718217,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4070,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81395236815457,
          21.00923155530621
        ]
      },
      "properties": {
        "tenDoiTuon": "03",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "LH4",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 10,
        "tuDien2": "Láng Hạ 4",
        "diaChiLapD": "",
        "longitude": 105.81395236815457,
        "latitude": 21.00923155530621,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4071,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81376461351381,
          21.009347986747173
        ]
      },
      "properties": {
        "tenDoiTuon": "04",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "LH4",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 10,
        "tuDien2": "Láng Hạ 4",
        "diaChiLapD": "",
        "longitude": 105.81376461351381,
        "latitude": 21.009347986747173,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4072,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81355003679565,
          21.009471929793925
        ]
      },
      "properties": {
        "tenDoiTuon": "05",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "LH4",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 10,
        "tuDien2": "Láng Hạ 4",
        "diaChiLapD": "",
        "longitude": 105.81355003679565,
        "latitude": 21.009471929793925,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4073,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8133260723414,
          21.0095996285828
        ]
      },
      "properties": {
        "tenDoiTuon": "06",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "LH4",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 10,
        "tuDien2": "Láng Hạ 4",
        "diaChiLapD": "",
        "longitude": 105.8133260723414,
        "latitude": 21.0095996285828,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4074,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8130538281298,
          21.009759877889227
        ]
      },
      "properties": {
        "tenDoiTuon": "07",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "LH4",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 10,
        "tuDien2": "Láng Hạ 4",
        "diaChiLapD": "",
        "longitude": 105.8130538281298,
        "latitude": 21.009759877889227,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4075,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81278694833371,
          21.00992513480548
        ]
      },
      "properties": {
        "tenDoiTuon": "08",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "LH4",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 10,
        "tuDien2": "Láng Hạ 4",
        "diaChiLapD": "",
        "longitude": 105.81278694833371,
        "latitude": 21.00992513480548,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4076,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81252275074537,
          21.01008162792941
        ]
      },
      "properties": {
        "tenDoiTuon": "09",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "LH4",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 10,
        "tuDien2": "Láng Hạ 4",
        "diaChiLapD": "",
        "longitude": 105.81252275074537,
        "latitude": 21.01008162792941,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4077,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81224245990158,
          21.01025064031793
        ]
      },
      "properties": {
        "tenDoiTuon": "10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "LH4",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 10,
        "tuDien2": "Láng Hạ 4",
        "diaChiLapD": "",
        "longitude": 105.81224245990158,
        "latitude": 21.01025064031793,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4078,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81197021569001,
          21.01040963698324
        ]
      },
      "properties": {
        "tenDoiTuon": "11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "LH4",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 10,
        "tuDien2": "Láng Hạ 4",
        "diaChiLapD": "",
        "longitude": 105.81197021569001,
        "latitude": 21.01040963698324,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4079,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81166578495856,
          21.010591168401998
        ]
      },
      "properties": {
        "tenDoiTuon": "12",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "LH4",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 10,
        "tuDien2": "Láng Hạ 4",
        "diaChiLapD": "",
        "longitude": 105.81166578495856,
        "latitude": 21.010591168401998,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4080,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81137208307607,
          21.01078772286106
        ]
      },
      "properties": {
        "tenDoiTuon": "13",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "LH4",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 10,
        "tuDien2": "Láng Hạ 4",
        "diaChiLapD": "",
        "longitude": 105.81137208307607,
        "latitude": 21.01078772286106,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4081,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81133319104585,
          21.01087160271114
        ]
      },
      "properties": {
        "tenDoiTuon": "LH4 14",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "LH4",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 10,
        "tuDien2": "Láng Hạ 4",
        "diaChiLapD": "",
        "longitude": 105.81133319104585,
        "latitude": 21.01087160271114,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4082,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83945263939339,
          21.022532987413165
        ]
      },
      "properties": {
        "tenDoiTuon": "14-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 88/33  Trần Quý Cáp",
        "tuDien": "TQC3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Trần Quý Cáp 3",
        "diaChiLapD": "",
        "longitude": 105.83945263939339,
        "latitude": 21.022532987413165,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4083,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83946202712949,
          21.02301494496113
        ]
      },
      "properties": {
        "tenDoiTuon": "14-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 88/33  Trần Quý Cáp",
        "tuDien": "TQC3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Trần Quý Cáp 3",
        "diaChiLapD": "",
        "longitude": 105.83946202712949,
        "latitude": 21.02301494496113,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4084,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83949019031974,
          21.022788362515204
        ]
      },
      "properties": {
        "tenDoiTuon": "14-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 88/33  Trần Quý Cáp",
        "tuDien": "TQC3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Trần Quý Cáp 3",
        "diaChiLapD": "",
        "longitude": 105.83949019031974,
        "latitude": 21.022788362515204,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4085,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83889339881867,
          21.02270949672196
        ]
      },
      "properties": {
        "tenDoiTuon": "14-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 88/33  Trần Quý Cáp",
        "tuDien": "TQC3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Trần Quý Cáp 3",
        "diaChiLapD": "",
        "longitude": 105.83889339881867,
        "latitude": 21.02270949672196,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4086,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83922331052365,
          21.022377759195805
        ]
      },
      "properties": {
        "tenDoiTuon": "13-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 88/33  Trần Quý Cáp",
        "tuDien": "TQC3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Trần Quý Cáp 3",
        "diaChiLapD": "",
        "longitude": 105.83922331052365,
        "latitude": 21.022377759195805,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4087,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8393010945931,
          21.022167449739626
        ]
      },
      "properties": {
        "tenDoiTuon": "12-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 88/33  Trần Quý Cáp",
        "tuDien": "TQC3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Trần Quý Cáp 3",
        "diaChiLapD": "",
        "longitude": 105.8393010945931,
        "latitude": 21.022167449739626,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4088,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83948884922486,
          21.022688215468058
        ]
      },
      "properties": {
        "tenDoiTuon": "14-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 88/33  Trần Quý Cáp",
        "tuDien": "TQC3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Trần Quý Cáp 3",
        "diaChiLapD": "",
        "longitude": 105.83948884922486,
        "latitude": 21.022688215468058,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4089,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83923672158035,
          21.02226509345284
        ]
      },
      "properties": {
        "tenDoiTuon": "13-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 88/33  Trần Quý Cáp",
        "tuDien": "TQC3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Trần Quý Cáp 3",
        "diaChiLapD": "",
        "longitude": 105.83923672158035,
        "latitude": 21.02226509345284,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4090,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83944995718566,
          21.02231892265213
        ]
      },
      "properties": {
        "tenDoiTuon": "14-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 88/33  Trần Quý Cáp",
        "tuDien": "TQC3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Trần Quý Cáp 3",
        "diaChiLapD": "",
        "longitude": 105.83944995718566,
        "latitude": 21.02231892265213,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4091,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83927427250667,
          21.021080846160814
        ]
      },
      "properties": {
        "tenDoiTuon": "10-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 88/105  Trần Quý Cáp",
        "tuDien": "TQC3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Trần Quý Cáp 3",
        "diaChiLapD": "",
        "longitude": 105.83927427250667,
        "latitude": 21.021080846160814,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4092,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8394164295799,
          21.02146891977693
        ]
      },
      "properties": {
        "tenDoiTuon": "11-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 88/97  Trần Quý Cáp",
        "tuDien": "TQC3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Trần Quý Cáp 3",
        "diaChiLapD": "",
        "longitude": 105.8394164295799,
        "latitude": 21.02146891977693,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4093,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83941374737213,
          21.021328712651925
        ]
      },
      "properties": {
        "tenDoiTuon": "11-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 88/97  Trần Quý Cáp",
        "tuDien": "TQC3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Trần Quý Cáp 3",
        "diaChiLapD": "",
        "longitude": 105.83941374737213,
        "latitude": 21.021328712651925,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4094,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83926890808219,
          21.021341231150565
        ]
      },
      "properties": {
        "tenDoiTuon": "11-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 88/97  Trần Quý Cáp",
        "tuDien": "TQC3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Trần Quý Cáp 3",
        "diaChiLapD": "",
        "longitude": 105.83926890808219,
        "latitude": 21.021341231150565,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4095,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83948080260163,
          21.021549038074735
        ]
      },
      "properties": {
        "tenDoiTuon": "11-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 88/95  Trần Quý Cáp",
        "tuDien": "TQC3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Trần Quý Cáp 3",
        "diaChiLapD": "",
        "longitude": 105.83948080260163,
        "latitude": 21.021549038074735,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4096,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.839188441814,
          21.02108835727324
        ]
      },
      "properties": {
        "tenDoiTuon": "10-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 88/99  Trần Quý Cáp",
        "tuDien": "TQC3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Trần Quý Cáp 3",
        "diaChiLapD": "",
        "longitude": 105.839188441814,
        "latitude": 21.02108835727324,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4097,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83922331053263,
          21.020935631257917
        ]
      },
      "properties": {
        "tenDoiTuon": "10-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 202,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 88/105 Trần Quý Cáp",
        "tuDien": "TQC3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Trần Quý Cáp 3",
        "diaChiLapD": "",
        "longitude": 105.83922331053263,
        "latitude": 21.020935631257917,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4098,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83848838526097,
          21.020399837116372
        ]
      },
      "properties": {
        "tenDoiTuon": "9-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 202,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 78 Trung Tiền",
        "tuDien": "TQC3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Trần Quý Cáp 3",
        "diaChiLapD": "",
        "longitude": 105.83848838526097,
        "latitude": 21.020399837116372,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4099,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81915204432948,
          21.01329224552856
        ]
      },
      "properties": {
        "tenDoiTuon": "08",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 133 Thái Hà",
        "tuDien": "XDN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Xây Dựng Nhà",
        "diaChiLapD": "",
        "longitude": 105.81915204432948,
        "latitude": 21.01329224552856,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4100,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8189537701989,
          21.013075374163467
        ]
      },
      "properties": {
        "tenDoiTuon": "09",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 133 Thái Hà",
        "tuDien": "XDN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Xây Dựng Nhà",
        "diaChiLapD": "",
        "longitude": 105.8189537701989,
        "latitude": 21.013075374163467,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4101,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8187691255118,
          21.01284825910584
        ]
      },
      "properties": {
        "tenDoiTuon": "10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 133 Thái Hà",
        "tuDien": "XDN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Xây Dựng Nhà",
        "diaChiLapD": "",
        "longitude": 105.8187691255118,
        "latitude": 21.01284825910584,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4102,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81858421933873,
          21.012665242891423
        ]
      },
      "properties": {
        "tenDoiTuon": "11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 133 Thái Hà",
        "tuDien": "XDN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Xây Dựng Nhà",
        "diaChiLapD": "",
        "longitude": 105.81858421933873,
        "latitude": 21.012665242891423,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4103,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81844513542812,
          21.012511463803964
        ]
      },
      "properties": {
        "tenDoiTuon": "12",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 133 Thái Hà",
        "tuDien": "XDN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Xây Dựng Nhà",
        "diaChiLapD": "",
        "longitude": 105.81844513542812,
        "latitude": 21.012511463803964,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4104,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81813816931792,
          21.01216050791833
        ]
      },
      "properties": {
        "tenDoiTuon": "13",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 133 Thái Hà",
        "tuDien": "XDN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Xây Dựng Nhà",
        "diaChiLapD": "",
        "longitude": 105.81813816931792,
        "latitude": 21.01216050791833,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4105,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81801746991562,
          21.012315746770586
        ]
      },
      "properties": {
        "tenDoiTuon": "13-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 133 Thái Hà",
        "tuDien": "XDN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Xây Dựng Nhà",
        "diaChiLapD": "",
        "longitude": 105.81801746991562,
        "latitude": 21.012315746770586,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4106,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81788604167338,
          21.011955191767232
        ]
      },
      "properties": {
        "tenDoiTuon": "14-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 133 Thái Hà",
        "tuDien": "XDN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Xây Dựng Nhà",
        "diaChiLapD": "",
        "longitude": 105.81788604167338,
        "latitude": 21.011955191767232,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4107,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81795577911062,
          21.01199525347758
        ]
      },
      "properties": {
        "tenDoiTuon": "14",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 133 Thái Hà",
        "tuDien": "XDN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Xây Dựng Nhà",
        "diaChiLapD": "",
        "longitude": 105.81795577911062,
        "latitude": 21.01199525347758,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4108,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81809525397608,
          21.01186004516336
        ]
      },
      "properties": {
        "tenDoiTuon": "14-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 133 Thái Hà",
        "tuDien": "XDN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Xây Dựng Nhà",
        "diaChiLapD": "",
        "longitude": 105.81809525397608,
        "latitude": 21.01186004516336,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4109,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81819717791518,
          21.011769906218856
        ]
      },
      "properties": {
        "tenDoiTuon": "14-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 133 Thái Hà",
        "tuDien": "XDN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Xây Dựng Nhà",
        "diaChiLapD": "",
        "longitude": 105.81819717791518,
        "latitude": 21.011769906218856,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4110,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83878342825632,
          21.020369792528044
        ]
      },
      "properties": {
        "tenDoiTuon": "9-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 202,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 78 Trung Tiền",
        "tuDien": "TQC3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Trần Quý Cáp 3",
        "diaChiLapD": "",
        "longitude": 105.83878342825632,
        "latitude": 21.020369792528044,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4111,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83954249340664,
          21.02000925699602
        ]
      },
      "properties": {
        "tenDoiTuon": "9-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 202,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 78 Trung Tiền",
        "tuDien": "TQC3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Trần Quý Cáp 3",
        "diaChiLapD": "",
        "longitude": 105.83954249340664,
        "latitude": 21.02000925699602,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4112,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83954785782213,
          21.020567585956623
        ]
      },
      "properties": {
        "tenDoiTuon": "9-8",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 202,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 78 Trung Tiền",
        "tuDien": "TQC3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Trần Quý Cáp 3",
        "diaChiLapD": "",
        "longitude": 105.83954785782213,
        "latitude": 21.020567585956623,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4113,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8392072172772,
          21.02061766020041
        ]
      },
      "properties": {
        "tenDoiTuon": "9-9",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 202,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 78 Trung Tiền",
        "tuDien": "TQC3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Trần Quý Cáp 3",
        "diaChiLapD": "",
        "longitude": 105.8392072172772,
        "latitude": 21.02061766020041,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4114,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83895240742493,
          21.020585111944026
        ]
      },
      "properties": {
        "tenDoiTuon": "9-10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 202,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 78 Trung Tiền",
        "tuDien": "TQC3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Trần Quý Cáp 3",
        "diaChiLapD": "",
        "longitude": 105.83895240742493,
        "latitude": 21.020585111944026,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4115,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83954517561439,
          21.02029217731347
        ]
      },
      "properties": {
        "tenDoiTuon": "9-7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 202,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 78 Trung Tiền",
        "tuDien": "TQC3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Trần Quý Cáp 3",
        "diaChiLapD": "",
        "longitude": 105.83954517561439,
        "latitude": 21.02029217731347,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4116,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83893899637724,
          21.020049316542902
        ]
      },
      "properties": {
        "tenDoiTuon": "9-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 202,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 78 Trung Tiền",
        "tuDien": "TQC3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Trần Quý Cáp 3",
        "diaChiLapD": "",
        "longitude": 105.83893899637724,
        "latitude": 21.020049316542902,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4117,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83892826753727,
          21.0203046958991
        ]
      },
      "properties": {
        "tenDoiTuon": "9-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 202,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 78 Trung Tiền",
        "tuDien": "TQC3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Trần Quý Cáp 3",
        "diaChiLapD": "",
        "longitude": 105.83892826753727,
        "latitude": 21.0203046958991,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4118,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83919917065397,
          21.019976708606805
        ]
      },
      "properties": {
        "tenDoiTuon": "9-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 202,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 78 Trung Tiền",
        "tuDien": "TQC3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Trần Quý Cáp 3",
        "diaChiLapD": "",
        "longitude": 105.83919917065397,
        "latitude": 21.019976708606805,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4119,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81940685418175,
          21.01358769773893
        ]
      },
      "properties": {
        "tenDoiTuon": "6-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 218 Thái Hà",
        "tuDien": "XDN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Xây Dựng Nhà",
        "diaChiLapD": "",
        "longitude": 105.81940685418175,
        "latitude": 21.01358769773893,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4120,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81968899117157,
          21.01380870236251
        ]
      },
      "properties": {
        "tenDoiTuon": "6-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 218 Thái Hà",
        "tuDien": "XDN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Xây Dựng Nhà",
        "diaChiLapD": "",
        "longitude": 105.81968899117157,
        "latitude": 21.01380870236251,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4121,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8397409768694,
          21.02598301800429
        ]
      },
      "properties": {
        "tenDoiTuon": "24-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 181,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "ngách 62/1 Trần Quý Cáp",
        "tuDien": "TQC3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "Trần Quý Cáp 3",
        "diaChiLapD": "",
        "longitude": 105.8397409768694,
        "latitude": 21.02598301800429,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4122,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81991416509982,
          21.013269848700396
        ]
      },
      "properties": {
        "tenDoiTuon": "15",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 178 Thái Hà",
        "tuDien": "XDN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Xây Dựng Nhà",
        "diaChiLapD": "",
        "longitude": 105.81991416509982,
        "latitude": 21.013269848700396,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4123,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8200843846353,
          21.01348086221182
        ]
      },
      "properties": {
        "tenDoiTuon": "16",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 178 Thái Hà",
        "tuDien": "XDN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 7,
        "tuDien2": "Xây Dựng Nhà",
        "diaChiLapD": "",
        "longitude": 105.8200843846353,
        "latitude": 21.01348086221182,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4124,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82025421402692,
          21.013665269952597
        ]
      },
      "properties": {
        "tenDoiTuon": "17",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 178 Thái Hà",
        "tuDien": "XDN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Xây Dựng Nhà",
        "diaChiLapD": "",
        "longitude": 105.82025421402692,
        "latitude": 21.013665269952597,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4125,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82042838717689,
          21.01383466816742
        ]
      },
      "properties": {
        "tenDoiTuon": "18",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 178 Thái Hà",
        "tuDien": "XDN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Xây Dựng Nhà",
        "diaChiLapD": "",
        "longitude": 105.82042838717689,
        "latitude": 21.01383466816742,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4126,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82069699672549,
          21.014119760625906
        ]
      },
      "properties": {
        "tenDoiTuon": "19",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 178 Thái Hà",
        "tuDien": "XDN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Xây Dựng Nhà",
        "diaChiLapD": "",
        "longitude": 105.82069699672549,
        "latitude": 21.014119760625906,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4127,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82085792926188,
          21.014295027984442
        ]
      },
      "properties": {
        "tenDoiTuon": "20",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 178 Thái Hà",
        "tuDien": "XDN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 7,
        "tuDien2": "Xây Dựng Nhà",
        "diaChiLapD": "",
        "longitude": 105.82085792926188,
        "latitude": 21.014295027984442,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4128,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81986819413797,
          21.013639026241222
        ]
      },
      "properties": {
        "tenDoiTuon": "16-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 178 Thái Hà",
        "tuDien": "XDN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Xây Dựng Nhà",
        "diaChiLapD": "",
        "longitude": 105.81986819413797,
        "latitude": 21.013639026241222,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4129,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81973944810348,
          21.013761713809842
        ]
      },
      "properties": {
        "tenDoiTuon": "16-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 178 Thái Hà",
        "tuDien": "XDN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Xây Dựng Nhà",
        "diaChiLapD": "",
        "longitude": 105.81973944810348,
        "latitude": 21.013761713809842,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4130,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81997431079381,
          21.014015573891452
        ]
      },
      "properties": {
        "tenDoiTuon": "16-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 178 Thái Hà",
        "tuDien": "XDN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Xây Dựng Nhà",
        "diaChiLapD": "",
        "longitude": 105.81997431079381,
        "latitude": 21.014015573891452,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4131,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82007440716792,
          21.013860581366288
        ]
      },
      "properties": {
        "tenDoiTuon": "16-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 178 Thái Hà",
        "tuDien": "XDN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Xây Dựng Nhà",
        "diaChiLapD": "",
        "longitude": 105.82007440716792,
        "latitude": 21.013860581366288,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4132,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82020883468289,
          21.014067180377925
        ]
      },
      "properties": {
        "tenDoiTuon": "16-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 178 Thái Hà",
        "tuDien": "XDN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Xây Dựng Nhà",
        "diaChiLapD": "",
        "longitude": 105.82020883468289,
        "latitude": 21.014067180377925,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4133,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82053338197238,
          21.014237440160404
        ]
      },
      "properties": {
        "tenDoiTuon": "19-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 178 Thái Hà",
        "tuDien": "XDN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Xây Dựng Nhà",
        "diaChiLapD": "",
        "longitude": 105.82053338197238,
        "latitude": 21.014237440160404,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4134,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82071660118517,
          21.01447903339663
        ]
      },
      "properties": {
        "tenDoiTuon": "20-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 178 Thái Hà",
        "tuDien": "XDN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Xây Dựng Nhà",
        "diaChiLapD": "",
        "longitude": 105.82071660118517,
        "latitude": 21.01447903339663,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4135,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82098123459429,
          21.01448019584994
        ]
      },
      "properties": {
        "tenDoiTuon": "20-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 178 Thái Hà",
        "tuDien": "XDN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Xây Dựng Nhà",
        "diaChiLapD": "",
        "longitude": 105.82098123459429,
        "latitude": 21.01448019584994,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4136,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8210290819566,
          21.014146670520503
        ]
      },
      "properties": {
        "tenDoiTuon": "20-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 178 Thái Hà",
        "tuDien": "XDN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Xây Dựng Nhà",
        "diaChiLapD": "",
        "longitude": 105.8210290819566,
        "latitude": 21.014146670520503,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4137,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82087697566247,
          21.01395966853034
        ]
      },
      "properties": {
        "tenDoiTuon": "20-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 178 Thái Hà",
        "tuDien": "XDN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Xây Dựng Nhà",
        "diaChiLapD": "",
        "longitude": 105.82087697566247,
        "latitude": 21.01395966853034,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4138,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82119557159731,
          21.01401880356695
        ]
      },
      "properties": {
        "tenDoiTuon": "20-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 178 Thái Hà",
        "tuDien": "XDN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Xây Dựng Nhà",
        "diaChiLapD": "",
        "longitude": 105.82119557159731,
        "latitude": 21.01401880356695,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4139,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82101794199083,
          21.013840915091098
        ]
      },
      "properties": {
        "tenDoiTuon": "20-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 178 Thái Hà",
        "tuDien": "XDN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Xây Dựng Nhà",
        "diaChiLapD": "",
        "longitude": 105.82101794199083,
        "latitude": 21.013840915091098,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4140,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82041606413593,
          21.01432870278575
        ]
      },
      "properties": {
        "tenDoiTuon": "19-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 178 Thái Hà",
        "tuDien": "XDN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Xây Dựng Nhà",
        "diaChiLapD": "",
        "longitude": 105.82041606413593,
        "latitude": 21.01432870278575,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4141,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82021523159062,
          21.01416095042452
        ]
      },
      "properties": {
        "tenDoiTuon": "19-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 178 Thái Hà",
        "tuDien": "XDN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Xây Dựng Nhà",
        "diaChiLapD": "",
        "longitude": 105.82021523159062,
        "latitude": 21.01416095042452,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4142,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81155849661296,
          21.01090039727526
        ]
      },
      "properties": {
        "tenDoiTuon": "13-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 528 Đường Láng",
        "tuDien": "LH4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Láng Hạ 4",
        "diaChiLapD": "",
        "longitude": 105.81155849661296,
        "latitude": 21.01090039727526,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4143,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8120184954653,
          21.01062872659873
        ]
      },
      "properties": {
        "tenDoiTuon": "12-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 516 Đường Láng",
        "tuDien": "LH4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Láng Hạ 4",
        "diaChiLapD": "",
        "longitude": 105.8120184954653,
        "latitude": 21.01062872659873,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4144,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81216467585011,
          21.010879114336113
        ]
      },
      "properties": {
        "tenDoiTuon": "11-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 151 Đường Láng",
        "tuDien": "LH4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Láng Hạ 4",
        "diaChiLapD": "",
        "longitude": 105.81216467585011,
        "latitude": 21.010879114336113,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4145,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81218060870236,
          21.010538001846662
        ]
      },
      "properties": {
        "tenDoiTuon": "11-10-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 508 Đường Láng",
        "tuDien": "LH4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 7,
        "tuDien2": "Láng Hạ 4",
        "diaChiLapD": "",
        "longitude": 105.81218060870236,
        "latitude": 21.010538001846662,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4146,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81226509828218,
          21.01071202147454
        ]
      },
      "properties": {
        "tenDoiTuon": "11-10-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ151 Đượng Láng",
        "tuDien": "LH4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "Láng Hạ 4",
        "diaChiLapD": "",
        "longitude": 105.81226509828218,
        "latitude": 21.01071202147454,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4147,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8124193252992,
          21.010878529273946
        ]
      },
      "properties": {
        "tenDoiTuon": "11-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 508 Đường Láng",
        "tuDien": "LH4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Láng Hạ 4",
        "diaChiLapD": "",
        "longitude": 105.8124193252992,
        "latitude": 21.010878529273946,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4148,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81267916429773,
          21.011035960349123
        ]
      },
      "properties": {
        "tenDoiTuon": "11-10-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 508 Đường Láng",
        "tuDien": "LH4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Láng Hạ 4",
        "diaChiLapD": "",
        "longitude": 105.81267916429773,
        "latitude": 21.011035960349123,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4149,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8129071520636,
          21.011132359415225
        ]
      },
      "properties": {
        "tenDoiTuon": "11-10-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 508 Đường Láng",
        "tuDien": "LH4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Láng Hạ 4",
        "diaChiLapD": "",
        "longitude": 105.8129071520636,
        "latitude": 21.011132359415225,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4150,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81310965884693,
          21.01122750648404
        ]
      },
      "properties": {
        "tenDoiTuon": "11-10-4-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 508 Đường Láng",
        "tuDien": "LH4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Láng Hạ 4",
        "diaChiLapD": "",
        "longitude": 105.81310965884693,
        "latitude": 21.01122750648404,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4151,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81331618894188,
          21.01129761481106
        ]
      },
      "properties": {
        "tenDoiTuon": "11-10-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 508 Đường Láng",
        "tuDien": "LH4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Láng Hạ 4",
        "diaChiLapD": "",
        "longitude": 105.81331618894188,
        "latitude": 21.01129761481106,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4152,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81365951169455,
          21.011482900946906
        ]
      },
      "properties": {
        "tenDoiTuon": "11-10-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 508 Đường Láng",
        "tuDien": "LH4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 7,
        "tuDien2": "Láng Hạ 4",
        "diaChiLapD": "",
        "longitude": 105.81365951169455,
        "latitude": 21.011482900946906,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4153,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81319280732286,
          21.011517955055787
        ]
      },
      "properties": {
        "tenDoiTuon": "LH4 11-10-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 508 Đường Láng",
        "tuDien": "LH4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Láng Hạ 4",
        "diaChiLapD": "",
        "longitude": 105.81319280732286,
        "latitude": 21.011517955055787,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4154,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81343018281584,
          21.011795883763124
        ]
      },
      "properties": {
        "tenDoiTuon": "11-10-7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 508 Đường Láng",
        "tuDien": "LH4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Láng Hạ 4",
        "diaChiLapD": "",
        "longitude": 105.81343018281584,
        "latitude": 21.011795883763124,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4155,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8136984037158,
          21.011929840207266
        ]
      },
      "properties": {
        "tenDoiTuon": "LH4 11-10-9",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 508 Đường Láng",
        "tuDien": "LH4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Láng Hạ 4",
        "diaChiLapD": "",
        "longitude": 105.8136984037158,
        "latitude": 21.011929840207266,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4156,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81344895827901,
          21.012012467485924
        ]
      },
      "properties": {
        "tenDoiTuon": "LH4 11-10-8",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 508 Đường Láng",
        "tuDien": "LH4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Láng Hạ 4",
        "diaChiLapD": "",
        "longitude": 105.81344895827901,
        "latitude": 21.012012467485924,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4157,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81251152625995,
          21.010568987370846
        ]
      },
      "properties": {
        "tenDoiTuon": "5-7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 426 Đường Láng",
        "tuDien": "LH4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Láng Hạ 4",
        "diaChiLapD": "",
        "longitude": 105.81251152625995,
        "latitude": 21.010568987370846,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4158,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81277438274444,
          21.010364920972247
        ]
      },
      "properties": {
        "tenDoiTuon": "5-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 426 Đường Láng",
        "tuDien": "LH4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Láng Hạ 4",
        "diaChiLapD": "",
        "longitude": 105.81277438274444,
        "latitude": 21.010364920972247,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4159,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81294604412078,
          21.01014583096933
        ]
      },
      "properties": {
        "tenDoiTuon": "5-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 426 Đường Láng",
        "tuDien": "LH4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Láng Hạ 4",
        "diaChiLapD": "",
        "longitude": 105.81294604412078,
        "latitude": 21.01014583096933,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4160,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81311368218552,
          21.009995597638536
        ]
      },
      "properties": {
        "tenDoiTuon": "5-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 426 Đường Láng",
        "tuDien": "LH4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Láng Hạ 4",
        "diaChiLapD": "",
        "longitude": 105.81311368218552,
        "latitude": 21.009995597638536,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4161,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81331350675211,
          21.009825333013502
        ]
      },
      "properties": {
        "tenDoiTuon": "5-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 426 Đường Láng",
        "tuDien": "LH4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Láng Hạ 4",
        "diaChiLapD": "",
        "longitude": 105.81331350675211,
        "latitude": 21.009825333013502,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4162,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81350930800711,
          21.009637540923137
        ]
      },
      "properties": {
        "tenDoiTuon": "5-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 426 Đường Láng",
        "tuDien": "LH4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Láng Hạ 4",
        "diaChiLapD": "",
        "longitude": 105.81350930800711,
        "latitude": 21.009637540923137,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4163,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81360452643584,
          21.009566179867043
        ]
      },
      "properties": {
        "tenDoiTuon": "5-1-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 426 Đường Láng",
        "tuDien": "LH4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Láng Hạ 4",
        "diaChiLapD": "",
        "longitude": 105.81360452643584,
        "latitude": 21.009566179867043,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4164,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8128565570002,
          21.010069424484012
        ]
      },
      "properties": {
        "tenDoiTuon": "5-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 426 Đường Láng",
        "tuDien": "LH4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "Láng Hạ 4",
        "diaChiLapD": "",
        "longitude": 105.8128565570002,
        "latitude": 21.010069424484012,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4165,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81204616299213,
          21.01098087514172
        ]
      },
      "properties": {
        "tenDoiTuon": "11-2-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 151 Đường Láng",
        "tuDien": "LH4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Láng Hạ 4",
        "diaChiLapD": "",
        "longitude": 105.81204616299213,
        "latitude": 21.01098087514172,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4166,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8119496034685,
          21.01107101456301
        ]
      },
      "properties": {
        "tenDoiTuon": "11-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 151 Đường Láng",
        "tuDien": "LH4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Láng Hạ 4",
        "diaChiLapD": "",
        "longitude": 105.8119496034685,
        "latitude": 21.01107101456301,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4167,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81170820466393,
          21.011317645756307
        ]
      },
      "properties": {
        "tenDoiTuon": "11-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 151 Đường Láng",
        "tuDien": "LH4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Láng Hạ 4",
        "diaChiLapD": "",
        "longitude": 105.81170820466393,
        "latitude": 21.011317645756307,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4168,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81211187711774,
          21.011706996709115
        ]
      },
      "properties": {
        "tenDoiTuon": "LH4 11-3-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 151/1",
        "tuDien": "LH4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Láng Hạ 4",
        "diaChiLapD": "",
        "longitude": 105.81211187711774,
        "latitude": 21.011706996709115,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4169,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81200157124127,
          21.011570223218314
        ]
      },
      "properties": {
        "tenDoiTuon": "11-3-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 151/1",
        "tuDien": "LH4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "Láng Hạ 4",
        "diaChiLapD": "",
        "longitude": 105.81200157124127,
        "latitude": 21.011570223218314,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4170,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81206728536688,
          21.011230949304093
        ]
      },
      "properties": {
        "tenDoiTuon": "11-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 151 Đường Láng",
        "tuDien": "LH4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Láng Hạ 4",
        "diaChiLapD": "",
        "longitude": 105.81206728536688,
        "latitude": 21.011230949304093,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4171,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81230734307657,
          21.011445029981733
        ]
      },
      "properties": {
        "tenDoiTuon": "11-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 151 Đường Láng",
        "tuDien": "LH4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Láng Hạ 4",
        "diaChiLapD": "",
        "longitude": 105.81230734307657,
        "latitude": 21.011445029981733,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4172,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81250180322769,
          21.011592757989895
        ]
      },
      "properties": {
        "tenDoiTuon": "LH4 11-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 151 Đường Láng",
        "tuDien": "LH4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Láng Hạ 4",
        "diaChiLapD": "",
        "longitude": 105.81250180322769,
        "latitude": 21.011592757989895,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4173,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8126828523401,
          21.011412479722573
        ]
      },
      "properties": {
        "tenDoiTuon": "11-8",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 151 Đường Láng",
        "tuDien": "LH4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Láng Hạ 4",
        "diaChiLapD": "",
        "longitude": 105.8126828523401,
        "latitude": 21.011412479722573,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4174,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81258361060874,
          21.01153016139381
        ]
      },
      "properties": {
        "tenDoiTuon": "11-7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 151 Đường Láng",
        "tuDien": "LH4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Láng Hạ 4",
        "diaChiLapD": "",
        "longitude": 105.81258361060874,
        "latitude": 21.01153016139381,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4175,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81280891615788,
          21.011532665258628
        ]
      },
      "properties": {
        "tenDoiTuon": "LH4 11-9",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 151 Đường Láng",
        "tuDien": "LH4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Láng Hạ 4",
        "diaChiLapD": "",
        "longitude": 105.81280891615788,
        "latitude": 21.011532665258628,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4176,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81413929185368,
          21.009393097863448
        ]
      },
      "properties": {
        "tenDoiTuon": "4-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 414 Đường Láng",
        "tuDien": "LH4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Láng Hạ 4",
        "diaChiLapD": "",
        "longitude": 105.81413929185368,
        "latitude": 21.009393097863448,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4177,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81427474340754,
          21.009559607134943
        ]
      },
      "properties": {
        "tenDoiTuon": "LH4 4-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 414 Đường Láng",
        "tuDien": "LH4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Láng Hạ 4",
        "diaChiLapD": "",
        "longitude": 105.81427474340754,
        "latitude": 21.009559607134943,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4178,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81366185865105,
          21.009773690211926
        ]
      },
      "properties": {
        "tenDoiTuon": "5-1-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 426/6",
        "tuDien": "LH4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Láng Hạ 4",
        "diaChiLapD": "",
        "longitude": 105.81366185865105,
        "latitude": 21.009773690211926,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4179,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8137476893437,
          21.009895129013586
        ]
      },
      "properties": {
        "tenDoiTuon": "5-1-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 426/6",
        "tuDien": "LH4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Láng Hạ 4",
        "diaChiLapD": "",
        "longitude": 105.8137476893437,
        "latitude": 21.009895129013586,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4180,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81390459856844,
          21.00999528465117
        ]
      },
      "properties": {
        "tenDoiTuon": "5-1-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 426/6",
        "tuDien": "LH4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "Láng Hạ 4",
        "diaChiLapD": "",
        "longitude": 105.81390459856844,
        "latitude": 21.00999528465117,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4181,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81417866470939,
          21.0102116745229
        ]
      },
      "properties": {
        "tenDoiTuon": "LH4 5-1-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 426/6",
        "tuDien": "LH4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "Láng Hạ 4",
        "diaChiLapD": "",
        "longitude": 105.81417866470939,
        "latitude": 21.0102116745229,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4182,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81403736791458,
          21.009802484987958
        ]
      },
      "properties": {
        "tenDoiTuon": "5-1-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 426/6",
        "tuDien": "LH4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "Láng Hạ 4",
        "diaChiLapD": "",
        "longitude": 105.81403736791458,
        "latitude": 21.009802484987958,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4183,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81409503540797,
          21.009907648471962
        ]
      },
      "properties": {
        "tenDoiTuon": "LH4 5-1-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 426/6",
        "tuDien": "LH4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "Láng Hạ 4",
        "diaChiLapD": "",
        "longitude": 105.81409503540797,
        "latitude": 21.009907648471962,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4184,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81334437223953,
          21.010125653177514
        ]
      },
      "properties": {
        "tenDoiTuon": "5-3-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 426/20",
        "tuDien": "LH4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Láng Hạ 4",
        "diaChiLapD": "",
        "longitude": 105.81334437223953,
        "latitude": 21.010125653177514,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4185,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81358744094449,
          21.010268914888368
        ]
      },
      "properties": {
        "tenDoiTuon": "5-3-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 426/20",
        "tuDien": "LH4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Láng Hạ 4",
        "diaChiLapD": "",
        "longitude": 105.81358744094449,
        "latitude": 21.010268914888368,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4186,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81380401570627,
          21.010425953128326
        ]
      },
      "properties": {
        "tenDoiTuon": "LH4 5-3-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 426/20",
        "tuDien": "LH4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Láng Hạ 4",
        "diaChiLapD": "",
        "longitude": 105.81380401570627,
        "latitude": 21.010425953128326,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4187,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81454162320367,
          21.011409975855727
        ]
      },
      "properties": {
        "tenDoiTuon": "LH4 5-5-10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 426/48",
        "tuDien": "LH4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Láng Hạ 4",
        "diaChiLapD": "",
        "longitude": 105.81454162320367,
        "latitude": 21.011409975855727,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4188,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81430692991842,
          21.011333607912157
        ]
      },
      "properties": {
        "tenDoiTuon": "LH4 5-5-9",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 426/48",
        "tuDien": "LH4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Láng Hạ 4",
        "diaChiLapD": "",
        "longitude": 105.81430692991842,
        "latitude": 21.011333607912157,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4189,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81408296546415,
          21.011228445434213
        ]
      },
      "properties": {
        "tenDoiTuon": "5-5-8",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 426/48",
        "tuDien": "LH4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Láng Hạ 4",
        "diaChiLapD": "",
        "longitude": 105.81408296546415,
        "latitude": 21.011228445434213,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4190,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81389252862465,
          21.011089480617507
        ]
      },
      "properties": {
        "tenDoiTuon": "5-5-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 426/48",
        "tuDien": "LH4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Láng Hạ 4",
        "diaChiLapD": "",
        "longitude": 105.81389252862465,
        "latitude": 21.011089480617507,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4191,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81376110038241,
          21.011264751536704
        ]
      },
      "properties": {
        "tenDoiTuon": "5-5-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 426/48",
        "tuDien": "LH4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Láng Hạ 4",
        "diaChiLapD": "",
        "longitude": 105.81376110038241,
        "latitude": 21.011264751536704,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4192,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8136457653956,
          21.0110106086363
        ]
      },
      "properties": {
        "tenDoiTuon": "5-5-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 426/48",
        "tuDien": "LH4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Láng Hạ 4",
        "diaChiLapD": "",
        "longitude": 105.8136457653956,
        "latitude": 21.0110106086363,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4193,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81345532855607,
          21.01091045368039
        ]
      },
      "properties": {
        "tenDoiTuon": "5-5-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 426/48",
        "tuDien": "LH4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Láng Hạ 4",
        "diaChiLapD": "",
        "longitude": 105.81345532855607,
        "latitude": 21.01091045368039,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4194,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81324075183791,
          21.01076522887338
        ]
      },
      "properties": {
        "tenDoiTuon": "5-5-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 426/48",
        "tuDien": "LH4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Láng Hạ 4",
        "diaChiLapD": "",
        "longitude": 105.81324075183791,
        "latitude": 21.01076522887338,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4195,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81300203524107,
          21.01055740692153
        ]
      },
      "properties": {
        "tenDoiTuon": "5-5-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 426/48",
        "tuDien": "LH4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Láng Hạ 4",
        "diaChiLapD": "",
        "longitude": 105.81300203524107,
        "latitude": 21.01055740692153,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4196,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81378255805333,
          21.011030639620078
        ]
      },
      "properties": {
        "tenDoiTuon": "5-5-5-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 426/48",
        "tuDien": "LH4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "Láng Hạ 4",
        "diaChiLapD": "",
        "longitude": 105.81378255805333,
        "latitude": 21.011030639620078,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4197,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81405480227389,
          21.010965538914625
        ]
      },
      "properties": {
        "tenDoiTuon": "LH4 5-5-7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 426/48",
        "tuDien": "LH4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Láng Hạ 4",
        "diaChiLapD": "",
        "longitude": 105.81405480227389,
        "latitude": 21.010965538914625,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4198,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81376780591074,
          21.010910453680403
        ]
      },
      "properties": {
        "tenDoiTuon": "LH4 5-5-5-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 426/48",
        "tuDien": "LH4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "Láng Hạ 4",
        "diaChiLapD": "",
        "longitude": 105.81376780591074,
        "latitude": 21.010910453680403,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4199,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81444104037739,
          21.01158900219503
        ]
      },
      "properties": {
        "tenDoiTuon": "LH4 5-5-11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 426/48",
        "tuDien": "LH4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Láng Hạ 4",
        "diaChiLapD": "",
        "longitude": 105.81444104037739,
        "latitude": 21.01158900219503,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4200,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8143471630615,
          21.01181434972704
        ]
      },
      "properties": {
        "tenDoiTuon": "LH4 5-5-13",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 426/48",
        "tuDien": "LH4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Láng Hạ 4",
        "diaChiLapD": "",
        "longitude": 105.8143471630615,
        "latitude": 21.01181434972704,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4201,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81422646365023,
          21.011483839896304
        ]
      },
      "properties": {
        "tenDoiTuon": "5-5-12",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 426/48",
        "tuDien": "LH4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Láng Hạ 4",
        "diaChiLapD": "",
        "longitude": 105.81422646365023,
        "latitude": 21.011483839896304,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4202,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81411649308788,
          21.011714195309924
        ]
      },
      "properties": {
        "tenDoiTuon": "LH4 5-5-14",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 426/48",
        "tuDien": "LH4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Láng Hạ 4",
        "diaChiLapD": "",
        "longitude": 105.81411649308788,
        "latitude": 21.011714195309924,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4203,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82350194917231,
          21.015457130558936
        ]
      },
      "properties": {
        "tenDoiTuon": "HC1 02",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 20/1 Võ Văn Dũng",
        "tuDien": "HCA1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hồ Cá 1",
        "diaChiLapD": "",
        "longitude": 105.82350194917231,
        "latitude": 21.015457130558936,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4204,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82359784452547,
          21.015633628261046
        ]
      },
      "properties": {
        "tenDoiTuon": "HC1 32",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 20/1 Võ Văn Dũng",
        "tuDien": "HCA1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hồ Cá 1",
        "diaChiLapD": "",
        "longitude": 105.82359784452547,
        "latitude": 21.015633628261046,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4205,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82372047852961,
          21.015349589093447
        ]
      },
      "properties": {
        "tenDoiTuon": "HC1 03",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 20/1 Võ Văn Dũng",
        "tuDien": "HCA1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hồ Cá 1",
        "diaChiLapD": "",
        "longitude": 105.82372047852961,
        "latitude": 21.015349589093447,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4206,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82383030518616,
          21.015527628959447
        ]
      },
      "properties": {
        "tenDoiTuon": "HC1 04",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 20/1 Võ Văn Dũng",
        "tuDien": "HCA1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hồ Cá 1",
        "diaChiLapD": "",
        "longitude": 105.82383030518616,
        "latitude": 21.015527628959447,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4207,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82339625877295,
          21.015766924540177
        ]
      },
      "properties": {
        "tenDoiTuon": "HC1 33",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 20/1 Võ Văn Dũng",
        "tuDien": "HCA1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hồ Cá 1",
        "diaChiLapD": "",
        "longitude": 105.82339625877295,
        "latitude": 21.015766924540177,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4208,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82318648980501,
          21.01587198360708
        ]
      },
      "properties": {
        "tenDoiTuon": "HC1 34",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 20/1 Võ Văn Dũng",
        "tuDien": "HCA1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hồ Cá 1",
        "diaChiLapD": "",
        "longitude": 105.82318648980501,
        "latitude": 21.01587198360708,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4209,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82397356618743,
          21.015796839918014
        ]
      },
      "properties": {
        "tenDoiTuon": "HC1 05",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Võ Văn Dũng",
        "tuDien": "HCA1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hồ Cá 1",
        "diaChiLapD": "",
        "longitude": 105.82397356618743,
        "latitude": 21.015796839918014,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4210,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8237522839409,
          21.01590199917585
        ]
      },
      "properties": {
        "tenDoiTuon": "HC1 06",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Võ Văn Dũng",
        "tuDien": "HCA1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hồ Cá 1",
        "diaChiLapD": "",
        "longitude": 105.8237522839409,
        "latitude": 21.01590199917585,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4211,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82354144185786,
          21.016013914716815
        ]
      },
      "properties": {
        "tenDoiTuon": "HC1 07",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Võ Văn Dũng",
        "tuDien": "HCA1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hồ Cá 1",
        "diaChiLapD": "",
        "longitude": 105.82354144185786,
        "latitude": 21.016013914716815,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4212,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82334516678979,
          21.016115795590434
        ]
      },
      "properties": {
        "tenDoiTuon": "HC1 08",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Võ Văn Dũng",
        "tuDien": "HCA1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hồ Cá 1",
        "diaChiLapD": "",
        "longitude": 105.82334516678979,
        "latitude": 21.016115795590434,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4213,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82234010089101,
          21.015361179347718
        ]
      },
      "properties": {
        "tenDoiTuon": "HC1 15",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 86 Hoàng Cầu",
        "tuDien": "HCA1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hồ Cá 1",
        "diaChiLapD": "",
        "longitude": 105.82234010089101,
        "latitude": 21.015361179347718,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4214,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82232937206004,
          21.015919525711617
        ]
      },
      "properties": {
        "tenDoiTuon": "HC1 13",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 86 Hoàng Cầu",
        "tuDien": "HCA1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hồ Cá 1",
        "diaChiLapD": "",
        "longitude": 105.82232937206004,
        "latitude": 21.015919525711617,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4215,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82233741868328,
          21.01574426026211
        ]
      },
      "properties": {
        "tenDoiTuon": "HC1 14",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 86 Hoàng Cầu",
        "tuDien": "HCA1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hồ Cá 1",
        "diaChiLapD": "",
        "longitude": 105.82233741868328,
        "latitude": 21.01574426026211,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4216,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82242324937592,
          21.0148954718127
        ]
      },
      "properties": {
        "tenDoiTuon": "HC1 16",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 86 Hoàng Cầu",
        "tuDien": "HCA1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hồ Cá 1",
        "diaChiLapD": "",
        "longitude": 105.82242324937592,
        "latitude": 21.0148954718127,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4217,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82248494018094,
          21.014831624698747
        ]
      },
      "properties": {
        "tenDoiTuon": "HC1 17",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 86 Hoàng Cầu",
        "tuDien": "HCA1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hồ Cá 1",
        "diaChiLapD": "",
        "longitude": 105.82248494018094,
        "latitude": 21.014831624698747,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4218,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82341821206812,
          21.015515525606894
        ]
      },
      "properties": {
        "tenDoiTuon": "HC1 1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 56 Trần Quang Diệu",
        "tuDien": "HCA1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hồ Cá 1",
        "diaChiLapD": "",
        "longitude": 105.82341821206812,
        "latitude": 21.015515525606894,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4219,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82319068952637,
          21.01563397021256
        ]
      },
      "properties": {
        "tenDoiTuon": "HC1 30",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 56 Trần Quang Diệu",
        "tuDien": "HCA1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hồ Cá 1",
        "diaChiLapD": "",
        "longitude": 105.82319068952637,
        "latitude": 21.01563397021256,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4220,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82296773780806,
          21.01564285658594
        ]
      },
      "properties": {
        "tenDoiTuon": "HC1 22",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 56 Trần Quang Diệu",
        "tuDien": "HCA1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hồ Cá 1",
        "diaChiLapD": "",
        "longitude": 105.82296773780806,
        "latitude": 21.01564285658594,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4221,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82311411680664,
          21.015349310567025
        ]
      },
      "properties": {
        "tenDoiTuon": "HC1 31",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 56 Trần Quang Diệu",
        "tuDien": "HCA1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hồ Cá 1",
        "diaChiLapD": "",
        "longitude": 105.82311411680664,
        "latitude": 21.015349310567025,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4222,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82294351964467,
          21.01545553939633
        ]
      },
      "properties": {
        "tenDoiTuon": "HC1 21",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 56 Trần Quang Diệu",
        "tuDien": "HCA1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hồ Cá 1",
        "diaChiLapD": "",
        "longitude": 105.82294351964467,
        "latitude": 21.01545553939633,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4223,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82297032077688,
          21.01523051380647
        ]
      },
      "properties": {
        "tenDoiTuon": "HC1 20",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 56 Trần Quang Diệu",
        "tuDien": "HCA1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hồ Cá 1",
        "diaChiLapD": "",
        "longitude": 105.82297032077688,
        "latitude": 21.01523051380647,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4224,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82268342365268,
          21.015078249674858
        ]
      },
      "properties": {
        "tenDoiTuon": "HC1 19",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 56 Trần Quang Diệu",
        "tuDien": "HCA1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hồ Cá 1",
        "diaChiLapD": "",
        "longitude": 105.82268342365268,
        "latitude": 21.015078249674858,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4225,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82250148505506,
          21.01491541475733
        ]
      },
      "properties": {
        "tenDoiTuon": "HC1 18",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 56 Trần Quang Diệu",
        "tuDien": "HCA1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hồ Cá 1",
        "diaChiLapD": "",
        "longitude": 105.82250148505506,
        "latitude": 21.01491541475733,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4226,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82295508694199,
          21.01587735649159
        ]
      },
      "properties": {
        "tenDoiTuon": "HC1 23",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 56 Trần Quang Diệu",
        "tuDien": "HCA1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hồ Cá 1",
        "diaChiLapD": "",
        "longitude": 105.82295508694199,
        "latitude": 21.01587735649159,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4227,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82270361002792,
          21.015872611586115
        ]
      },
      "properties": {
        "tenDoiTuon": "HC1 24",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 56 Trần Quang Diệu",
        "tuDien": "HCA1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hồ Cá 1",
        "diaChiLapD": "",
        "longitude": 105.82270361002792,
        "latitude": 21.015872611586115,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4228,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82247903700078,
          21.01587411746002
        ]
      },
      "properties": {
        "tenDoiTuon": "HC1 25",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 56 Trần Quang Diệu",
        "tuDien": "HCA1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hồ Cá 1",
        "diaChiLapD": "",
        "longitude": 105.82247903700078,
        "latitude": 21.01587411746002,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4229,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82257747639295,
          21.015632841404923
        ]
      },
      "properties": {
        "tenDoiTuon": "HC1 27",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 56 Trần Quang Diệu",
        "tuDien": "HCA1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hồ Cá 1",
        "diaChiLapD": "",
        "longitude": 105.82257747639295,
        "latitude": 21.015632841404923,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4230,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82237055587177,
          21.015630868680944
        ]
      },
      "properties": {
        "tenDoiTuon": "HC1 26",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 56 Trần Quang Diệu",
        "tuDien": "HCA1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hồ Cá 1",
        "diaChiLapD": "",
        "longitude": 105.82237055587177,
        "latitude": 21.015630868680944,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4231,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82263949806548,
          21.015402093382512
        ]
      },
      "properties": {
        "tenDoiTuon": "HC1 28",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 56 Trần Quang Diệu",
        "tuDien": "HCA1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hồ Cá 1",
        "diaChiLapD": "",
        "longitude": 105.82263949806548,
        "latitude": 21.015402093382512,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4232,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82264274452208,
          21.015175701484257
        ]
      },
      "properties": {
        "tenDoiTuon": "HC1 29",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 56 Trần Quang Diệu",
        "tuDien": "HCA1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hồ Cá 1",
        "diaChiLapD": "",
        "longitude": 105.82264274452208,
        "latitude": 21.015175701484257,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4233,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81301075237582,
          21.010275094111098
        ]
      },
      "properties": {
        "tenDoiTuon": "5-4-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 426/44",
        "tuDien": "LH4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 7,
        "tuDien2": "Láng Hạ 4",
        "diaChiLapD": "",
        "longitude": 105.81301075237582,
        "latitude": 21.010275094111098,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4234,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8127452136836,
          21.010550521251936
        ]
      },
      "properties": {
        "tenDoiTuon": "5-7-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 426/56",
        "tuDien": "LH4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 7,
        "tuDien2": "Láng Hạ 4",
        "diaChiLapD": "",
        "longitude": 105.8127452136836,
        "latitude": 21.010550521251936,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4235,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80514048058532,
          21.01522331558692
        ]
      },
      "properties": {
        "tenDoiTuon": "CTK 01",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "CTK",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "CTK",
        "diaChiLapD": "",
        "longitude": 105.80514048058532,
        "latitude": 21.01522331558692,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4236,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80538992602212,
          21.015038034097444
        ]
      },
      "properties": {
        "tenDoiTuon": "02",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "CTK",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "CTK",
        "diaChiLapD": "",
        "longitude": 105.80538992602212,
        "latitude": 21.015038034097444,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4237,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80562864261896,
          21.01480518000803
        ]
      },
      "properties": {
        "tenDoiTuon": "03",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "CTK",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "CTK",
        "diaChiLapD": "",
        "longitude": 105.80562864261896,
        "latitude": 21.01480518000803,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4238,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80582176167518,
          21.014614890373895
        ]
      },
      "properties": {
        "tenDoiTuon": "04",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "CTK",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "CTK",
        "diaChiLapD": "",
        "longitude": 105.80582176167518,
        "latitude": 21.014614890373895,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4239,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80607925373519,
          21.014379531806863
        ]
      },
      "properties": {
        "tenDoiTuon": "05",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "CTK",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "CTK",
        "diaChiLapD": "",
        "longitude": 105.80607925373519,
        "latitude": 21.014379531806863,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4240,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80634479242742,
          21.014144172868434
        ]
      },
      "properties": {
        "tenDoiTuon": "06",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "CTK",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "CTK",
        "diaChiLapD": "",
        "longitude": 105.80634479242742,
        "latitude": 21.014144172868434,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4241,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8065942378642,
          21.01394136327218
        ]
      },
      "properties": {
        "tenDoiTuon": "07",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "CTK",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "CTK",
        "diaChiLapD": "",
        "longitude": 105.8065942378642,
        "latitude": 21.01394136327218,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4242,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80682222563007,
          21.013753576362674
        ]
      },
      "properties": {
        "tenDoiTuon": "08",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "CTK",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "CTK",
        "diaChiLapD": "",
        "longitude": 105.80682222563007,
        "latitude": 21.013753576362674,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4243,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80709312874677,
          21.013560781556997
        ]
      },
      "properties": {
        "tenDoiTuon": "09",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "CTK",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "CTK",
        "diaChiLapD": "",
        "longitude": 105.80709312874677,
        "latitude": 21.013560781556997,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4244,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80734793859905,
          21.013375498003022
        ]
      },
      "properties": {
        "tenDoiTuon": "10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "CTK",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "CTK",
        "diaChiLapD": "",
        "longitude": 105.80734793859905,
        "latitude": 21.013375498003022,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4245,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80760006624358,
          21.013195221890268
        ]
      },
      "properties": {
        "tenDoiTuon": "11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "CTK",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "CTK",
        "diaChiLapD": "",
        "longitude": 105.80760006624358,
        "latitude": 21.013195221890268,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4246,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80786560493578,
          21.013007434042205
        ]
      },
      "properties": {
        "tenDoiTuon": "12",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "CTK",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "CTK",
        "diaChiLapD": "",
        "longitude": 105.80786560493578,
        "latitude": 21.013007434042205,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4247,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80813650805246,
          21.012809630586265
        ]
      },
      "properties": {
        "tenDoiTuon": "13.",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "CTK",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "CTK",
        "diaChiLapD": "",
        "longitude": 105.80813650805246,
        "latitude": 21.012809630586265,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4248,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8083926590176,
          21.012620590329494
        ]
      },
      "properties": {
        "tenDoiTuon": "14",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "CTK",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "CTK",
        "diaChiLapD": "",
        "longitude": 105.8083926590176,
        "latitude": 21.012620590329494,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4249,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80865551550208,
          21.012445321003387
        ]
      },
      "properties": {
        "tenDoiTuon": "15",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "CTK",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "CTK",
        "diaChiLapD": "",
        "longitude": 105.80865551550208,
        "latitude": 21.012445321003387,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4250,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80890496093888,
          21.012270051470495
        ]
      },
      "properties": {
        "tenDoiTuon": "16",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "CTK",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "CTK",
        "diaChiLapD": "",
        "longitude": 105.80890496093888,
        "latitude": 21.012270051470495,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4251,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80915306527177,
          21.012103545225173
        ]
      },
      "properties": {
        "tenDoiTuon": "17",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "CTK",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "CTK",
        "diaChiLapD": "",
        "longitude": 105.80915306527177,
        "latitude": 21.012103545225173,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4252,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80938105304665,
          21.011950810008212
        ]
      },
      "properties": {
        "tenDoiTuon": "18",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "CTK",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "CTK",
        "diaChiLapD": "",
        "longitude": 105.80938105304665,
        "latitude": 21.011950810008212,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4253,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.809676096033,
          21.011745493569297
        ]
      },
      "properties": {
        "tenDoiTuon": "19",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "CTK",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "CTK",
        "diaChiLapD": "",
        "longitude": 105.809676096033,
        "latitude": 21.011745493569297,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4254,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80993761141362,
          21.01156271162393
        ]
      },
      "properties": {
        "tenDoiTuon": "20",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "CTK",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "CTK",
        "diaChiLapD": "",
        "longitude": 105.80993761141362,
        "latitude": 21.01156271162393,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4255,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81019778569036,
          21.01138493718758
        ]
      },
      "properties": {
        "tenDoiTuon": "21",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "CTK",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "CTK",
        "diaChiLapD": "",
        "longitude": 105.81019778569036,
        "latitude": 21.01138493718758,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4256,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81045527775935,
          21.011200902865244
        ]
      },
      "properties": {
        "tenDoiTuon": "22",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "CTK",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "CTK",
        "diaChiLapD": "",
        "longitude": 105.81045527775935,
        "latitude": 21.011200902865244,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4257,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81070740540389,
          21.011026883807663
        ]
      },
      "properties": {
        "tenDoiTuon": "CTK 23",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "CTK",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "CTK",
        "diaChiLapD": "",
        "longitude": 105.81070740540389,
        "latitude": 21.011026883807663,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4258,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8112800570263,
          21.01062751556097
        ]
      },
      "properties": {
        "tenDoiTuon": "CTK 24",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "CTK",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "CTK",
        "diaChiLapD": "",
        "longitude": 105.8112800570263,
        "latitude": 21.01062751556097,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4259,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81154201944054,
          21.010469492859848
        ]
      },
      "properties": {
        "tenDoiTuon": "25",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "CTK",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "CTK",
        "diaChiLapD": "",
        "longitude": 105.81154201944054,
        "latitude": 21.010469492859848,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4260,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81180398185477,
          21.010311469991315
        ]
      },
      "properties": {
        "tenDoiTuon": "26",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "CTK",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "CTK",
        "diaChiLapD": "",
        "longitude": 105.81180398185477,
        "latitude": 21.010311469991315,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4261,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.812065944269,
          21.010153446955382
        ]
      },
      "properties": {
        "tenDoiTuon": "27",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "CTK",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "CTK",
        "diaChiLapD": "",
        "longitude": 105.812065944269,
        "latitude": 21.010153446955382,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4262,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81232790668324,
          21.00999542375209
        ]
      },
      "properties": {
        "tenDoiTuon": "28",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "CTK",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "CTK",
        "diaChiLapD": "",
        "longitude": 105.81232790668324,
        "latitude": 21.00999542375209,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4263,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81258986909747,
          21.00983740038141
        ]
      },
      "properties": {
        "tenDoiTuon": "29",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "CTK",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "CTK",
        "diaChiLapD": "",
        "longitude": 105.81258986909747,
        "latitude": 21.00983740038141,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4264,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81285183151171,
          21.00967937684332
        ]
      },
      "properties": {
        "tenDoiTuon": "30",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "CTK",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "CTK",
        "diaChiLapD": "",
        "longitude": 105.81285183151171,
        "latitude": 21.00967937684332,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4265,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81311379392594,
          21.009521353137856
        ]
      },
      "properties": {
        "tenDoiTuon": "31",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "CTK",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "CTK",
        "diaChiLapD": "",
        "longitude": 105.81311379392594,
        "latitude": 21.009521353137856,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4266,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81337575634016,
          21.009363329265017
        ]
      },
      "properties": {
        "tenDoiTuon": "32",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "CTK",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "CTK",
        "diaChiLapD": "",
        "longitude": 105.81337575634016,
        "latitude": 21.009363329265017,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4267,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8136377187544,
          21.0092053052248
        ]
      },
      "properties": {
        "tenDoiTuon": "CTK 33",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "CTK",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "CTK",
        "diaChiLapD": "",
        "longitude": 105.8136377187544,
        "latitude": 21.0092053052248,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4268,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80514852722652,
          21.015014247944016
        ]
      },
      "properties": {
        "tenDoiTuon": "CTK 01",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Láng",
        "tuDien": "CTK",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Sân vườn",
        "chieuCao": 7,
        "tuDien2": "CTK",
        "diaChiLapD": "",
        "longitude": 105.80514852722652,
        "latitude": 21.015014247944016,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4269,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80537919720012,
          21.014773882387324
        ]
      },
      "properties": {
        "tenDoiTuon": "02",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Láng",
        "tuDien": "CTK",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Sân vườn",
        "chieuCao": 7,
        "tuDien2": "CTK",
        "diaChiLapD": "",
        "longitude": 105.80537919720012,
        "latitude": 21.014773882387324,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4270,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80559913834279,
          21.014573577461096
        ]
      },
      "properties": {
        "tenDoiTuon": "03",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Láng",
        "tuDien": "CTK",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Sân vườn",
        "chieuCao": 7,
        "tuDien2": "CTK",
        "diaChiLapD": "",
        "longitude": 105.80559913834279,
        "latitude": 21.014573577461096,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4271,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80582444390089,
          21.014365760815192
        ]
      },
      "properties": {
        "tenDoiTuon": "04",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Láng",
        "tuDien": "CTK",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Sân vườn",
        "chieuCao": 7,
        "tuDien2": "CTK",
        "diaChiLapD": "",
        "longitude": 105.80582444390089,
        "latitude": 21.014365760815192,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4272,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80607925375317,
          21.014127898033824
        ]
      },
      "properties": {
        "tenDoiTuon": "05",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Láng",
        "tuDien": "CTK",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Sân vườn",
        "chieuCao": 7,
        "tuDien2": "CTK",
        "diaChiLapD": "",
        "longitude": 105.80607925375317,
        "latitude": 21.014127898033824,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4273,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80633138139768,
          21.013892538698304
        ]
      },
      "properties": {
        "tenDoiTuon": "06",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Láng",
        "tuDien": "CTK",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Sân vườn",
        "chieuCao": 7,
        "tuDien2": "CTK",
        "diaChiLapD": "",
        "longitude": 105.80633138139768,
        "latitude": 21.013892538698304,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4274,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80656741579577,
          21.013682217275594
        ]
      },
      "properties": {
        "tenDoiTuon": "07",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Láng",
        "tuDien": "CTK",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Sân vườn",
        "chieuCao": 7,
        "tuDien2": "CTK",
        "diaChiLapD": "",
        "longitude": 105.80656741579577,
        "latitude": 21.013682217275594,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4275,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80681417902481,
          21.013481910883307
        ]
      },
      "properties": {
        "tenDoiTuon": "08",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Láng",
        "tuDien": "CTK",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Sân vườn",
        "chieuCao": 7,
        "tuDien2": "CTK",
        "diaChiLapD": "",
        "longitude": 105.80681417902481,
        "latitude": 21.013481910883307,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4276,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80706630667832,
          21.01327033696381
        ]
      },
      "properties": {
        "tenDoiTuon": "09",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Láng",
        "tuDien": "CTK",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Sân vườn",
        "chieuCao": 7,
        "tuDien2": "CTK",
        "diaChiLapD": "",
        "longitude": 105.80706630667832,
        "latitude": 21.01327033696381,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4277,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8073130699074,
          21.013072533856523
        ]
      },
      "properties": {
        "tenDoiTuon": "10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Láng",
        "tuDien": "CTK",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Sân vườn",
        "chieuCao": 7,
        "tuDien2": "CTK",
        "diaChiLapD": "",
        "longitude": 105.8073130699074,
        "latitude": 21.013072533856523,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4278,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80755983313642,
          21.01287222664462
        ]
      },
      "properties": {
        "tenDoiTuon": "11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Láng",
        "tuDien": "CTK",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Sân vườn",
        "chieuCao": 7,
        "tuDien2": "CTK",
        "diaChiLapD": "",
        "longitude": 105.80755983313642,
        "latitude": 21.01287222664462,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4279,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80783878287633,
          21.012669415319728
        ]
      },
      "properties": {
        "tenDoiTuon": "12",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Láng",
        "tuDien": "CTK",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Sân vườn",
        "chieuCao": 7,
        "tuDien2": "CTK",
        "diaChiLapD": "",
        "longitude": 105.80783878287633,
        "latitude": 21.012669415319728,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4280,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80809895714408,
          21.012489138354418
        ]
      },
      "properties": {
        "tenDoiTuon": "13",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Láng",
        "tuDien": "CTK",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Sân vườn",
        "chieuCao": 7,
        "tuDien2": "CTK",
        "diaChiLapD": "",
        "longitude": 105.80809895714408,
        "latitude": 21.012489138354418,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4281,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80838327129949,
          21.012303853468477
        ]
      },
      "properties": {
        "tenDoiTuon": "14",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Láng",
        "tuDien": "CTK",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Sân vườn",
        "chieuCao": 7,
        "tuDien2": "CTK",
        "diaChiLapD": "",
        "longitude": 105.80838327129949,
        "latitude": 21.012303853468477,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4282,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80863271674527,
          21.012143606894835
        ]
      },
      "properties": {
        "tenDoiTuon": "15",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Láng",
        "tuDien": "CTK",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Sân vườn",
        "chieuCao": 7,
        "tuDien2": "CTK",
        "diaChiLapD": "",
        "longitude": 105.80863271674527,
        "latitude": 21.012143606894835,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4283,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80887411554984,
          21.01197584857886
        ]
      },
      "properties": {
        "tenDoiTuon": "16",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Láng",
        "tuDien": "CTK",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Sân vườn",
        "chieuCao": 7,
        "tuDien2": "CTK",
        "diaChiLapD": "",
        "longitude": 105.80887411554984,
        "latitude": 21.01197584857886,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4284,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80912624320337,
          21.011810593934374
        ]
      },
      "properties": {
        "tenDoiTuon": "17",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Láng",
        "tuDien": "CTK",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Sân vườn",
        "chieuCao": 7,
        "tuDien2": "CTK",
        "diaChiLapD": "",
        "longitude": 105.80912624320337,
        "latitude": 21.011810593934374,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4285,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80939982851879,
          21.011622804342302
        ]
      },
      "properties": {
        "tenDoiTuon": "18",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Láng",
        "tuDien": "CTK",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Sân vườn",
        "chieuCao": 7,
        "tuDien2": "CTK",
        "diaChiLapD": "",
        "longitude": 105.80939982851879,
        "latitude": 21.011622804342302,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4286,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8096841426742,
          21.0114450299784
        ]
      },
      "properties": {
        "tenDoiTuon": "19",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Láng",
        "tuDien": "CTK",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Sân vườn",
        "chieuCao": 7,
        "tuDien2": "CTK",
        "diaChiLapD": "",
        "longitude": 105.8096841426742,
        "latitude": 21.0114450299784,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4287,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80996309241412,
          21.01125723992611
        ]
      },
      "properties": {
        "tenDoiTuon": "20",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Láng",
        "tuDien": "CTK",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Sân vườn",
        "chieuCao": 7,
        "tuDien2": "CTK",
        "diaChiLapD": "",
        "longitude": 105.80996309241412,
        "latitude": 21.01125723992611,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4288,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81021790226639,
          21.011081968998084
        ]
      },
      "properties": {
        "tenDoiTuon": "21",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Láng",
        "tuDien": "CTK",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Sân vườn",
        "chieuCao": 7,
        "tuDien2": "CTK",
        "diaChiLapD": "",
        "longitude": 105.81021790226639,
        "latitude": 21.011081968998084,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4289,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81062023362531,
          21.01081655834356
        ]
      },
      "properties": {
        "tenDoiTuon": "CTK  22",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Láng",
        "tuDien": "CTK",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Sân vườn",
        "chieuCao": 7,
        "tuDien2": "CTK",
        "diaChiLapD": "",
        "longitude": 105.81062023362531,
        "latitude": 21.01081655834356,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4290,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81128005703528,
          21.010390898761187
        ]
      },
      "properties": {
        "tenDoiTuon": "CTK 23",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Láng",
        "tuDien": "CTK",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Sân vườn",
        "chieuCao": 7,
        "tuDien2": "CTK",
        "diaChiLapD": "",
        "longitude": 105.81128005703528,
        "latitude": 21.010390898761187,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4291,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8115590067662,
          21.010208115155212
        ]
      },
      "properties": {
        "tenDoiTuon": "24",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Láng",
        "tuDien": "CTK",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Sân vườn",
        "chieuCao": 7,
        "tuDien2": "CTK",
        "diaChiLapD": "",
        "longitude": 105.8115590067662,
        "latitude": 21.010208115155212,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4292,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81181918103397,
          21.01004035466261
        ]
      },
      "properties": {
        "tenDoiTuon": "25",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Láng",
        "tuDien": "CTK",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Sân vườn",
        "chieuCao": 7,
        "tuDien2": "CTK",
        "diaChiLapD": "",
        "longitude": 105.81181918103397,
        "latitude": 21.01004035466261,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4293,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81207667310296,
          21.009882609549127
        ]
      },
      "properties": {
        "tenDoiTuon": "26",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Láng",
        "tuDien": "CTK",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Sân vườn",
        "chieuCao": 7,
        "tuDien2": "CTK",
        "diaChiLapD": "",
        "longitude": 105.81207667310296,
        "latitude": 21.009882609549127,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4294,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81234489400292,
          21.009717352585774
        ]
      },
      "properties": {
        "tenDoiTuon": "27",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Láng",
        "tuDien": "CTK",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Sân vườn",
        "chieuCao": 7,
        "tuDien2": "CTK",
        "diaChiLapD": "",
        "longitude": 105.81234489400292,
        "latitude": 21.009717352585774,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4295,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81260506827967,
          21.009554599336532
        ]
      },
      "properties": {
        "tenDoiTuon": "28",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Láng",
        "tuDien": "CTK",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Sân vườn",
        "chieuCao": 7,
        "tuDien2": "CTK",
        "diaChiLapD": "",
        "longitude": 105.81260506827967,
        "latitude": 21.009554599336532,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4296,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81285451371646,
          21.009406869310002
        ]
      },
      "properties": {
        "tenDoiTuon": "29",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Láng",
        "tuDien": "CTK",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Sân vườn",
        "chieuCao": 7,
        "tuDien2": "CTK",
        "diaChiLapD": "",
        "longitude": 105.81285451371646,
        "latitude": 21.009406869310002,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4297,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81311200578546,
          21.009244115722044
        ]
      },
      "properties": {
        "tenDoiTuon": "30",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Láng",
        "tuDien": "CTK",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Sân vườn",
        "chieuCao": 7,
        "tuDien2": "CTK",
        "diaChiLapD": "",
        "longitude": 105.81311200578546,
        "latitude": 21.009244115722044,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4298,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81337486226994,
          21.009086369767473
        ]
      },
      "properties": {
        "tenDoiTuon": "CTK 31",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Láng",
        "tuDien": "CTK",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Sân vườn",
        "chieuCao": 7,
        "tuDien2": "CTK",
        "diaChiLapD": "",
        "longitude": 105.81337486226994,
        "latitude": 21.009086369767473,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4299,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82437161278006,
          21.014999443314068
        ]
      },
      "properties": {
        "tenDoiTuon": "17",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Khu TT VP Chính Phủ",
        "tuDien": "HCA2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hồ Cá 2",
        "diaChiLapD": "",
        "longitude": 105.82437161278006,
        "latitude": 21.014999443314068,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4300,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82408998083241,
          21.01516344252363
        ]
      },
      "properties": {
        "tenDoiTuon": "18",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 56 Trần Quang Diệu",
        "tuDien": "HCA2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hồ Cá 2",
        "diaChiLapD": "",
        "longitude": 105.82408998083241,
        "latitude": 21.01516344252363,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4301,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82384992313172,
          21.015276113632492
        ]
      },
      "properties": {
        "tenDoiTuon": "19",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 56 Trần Quang Diệu",
        "tuDien": "HCA2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hồ Cá 2",
        "diaChiLapD": "",
        "longitude": 105.82384992313172,
        "latitude": 21.015276113632492,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4302,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8237225182011,
          21.015251075615847
        ]
      },
      "properties": {
        "tenDoiTuon": "20",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 56 Trần Quang Diệu",
        "tuDien": "HCA2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hồ Cá 2",
        "diaChiLapD": "",
        "longitude": 105.8237225182011,
        "latitude": 21.015251075615847,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4303,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82359645437433,
          21.01503825230381
        ]
      },
      "properties": {
        "tenDoiTuon": "21",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 56 Trần Quang Diệu",
        "tuDien": "HCA2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hồ Cá 2",
        "diaChiLapD": "",
        "longitude": 105.82359645437433,
        "latitude": 21.01503825230381,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4304,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82377832747312,
          21.014852609219883
        ]
      },
      "properties": {
        "tenDoiTuon": "22",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 56 Trần Quang Diệu",
        "tuDien": "HCA2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hồ Cá 2",
        "diaChiLapD": "",
        "longitude": 105.82377832747312,
        "latitude": 21.014852609219883,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4305,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82349375989601,
          21.0149216610353
        ]
      },
      "properties": {
        "tenDoiTuon": "23",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 56 Trần Quang Diệu",
        "tuDien": "HCA2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hồ Cá 2",
        "diaChiLapD": "",
        "longitude": 105.82349375989601,
        "latitude": 21.0149216610353,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4306,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82326117824486,
          21.014767841068817
        ]
      },
      "properties": {
        "tenDoiTuon": "24",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 56 Trần Quang Diệu",
        "tuDien": "HCA2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hồ Cá 2",
        "diaChiLapD": "",
        "longitude": 105.82326117824486,
        "latitude": 21.014767841068817,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4307,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82311231565232,
          21.014921825304306
        ]
      },
      "properties": {
        "tenDoiTuon": "25",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 56 Trần Quang Diệu",
        "tuDien": "HCA2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hồ Cá 2",
        "diaChiLapD": "",
        "longitude": 105.82311231565232,
        "latitude": 21.014921825304306,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4308,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82309085797242,
          21.015044511816868
        ]
      },
      "properties": {
        "tenDoiTuon": "26",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 56 Trần Quang Diệu",
        "tuDien": "HCA2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hồ Cá 2",
        "diaChiLapD": "",
        "longitude": 105.82309085797242,
        "latitude": 21.015044511816868,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4309,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82327861260418,
          21.01519098435841
        ]
      },
      "properties": {
        "tenDoiTuon": "27",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 56 Trần Quang Diệu",
        "tuDien": "HCA2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hồ Cá 2",
        "diaChiLapD": "",
        "longitude": 105.82327861260418,
        "latitude": 21.01519098435841,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4310,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82336846660846,
          21.01530866304852
        ]
      },
      "properties": {
        "tenDoiTuon": "28",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 56 Trần Quang Diệu",
        "tuDien": "HCA2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hồ Cá 2",
        "diaChiLapD": "",
        "longitude": 105.82336846660846,
        "latitude": 21.01530866304852,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4311,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81099574288886,
          21.011088228683885
        ]
      },
      "properties": {
        "tenDoiTuon": "LH2 01",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "LH2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 10,
        "tuDien2": "LH2",
        "diaChiLapD": "",
        "longitude": 105.81099574288886,
        "latitude": 21.011088228683885,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4312,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81065912566453,
          21.011278522816326
        ]
      },
      "properties": {
        "tenDoiTuon": "02",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "LH2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 10,
        "tuDien2": "LH2",
        "diaChiLapD": "",
        "longitude": 105.81065912566453,
        "latitude": 21.011278522816326,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4313,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81047807655212,
          21.01140121232651
        ]
      },
      "properties": {
        "tenDoiTuon": "03",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "LH2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 10,
        "tuDien2": "LH2",
        "diaChiLapD": "",
        "longitude": 105.81047807655212,
        "latitude": 21.01140121232651,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4314,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81027691087264,
          21.011540176852833
        ]
      },
      "properties": {
        "tenDoiTuon": "04",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "LH2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 10,
        "tuDien2": "LH2",
        "diaChiLapD": "",
        "longitude": 105.81027691087264,
        "latitude": 21.011540176852833,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4315,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81003148874747,
          21.01170668372867
        ]
      },
      "properties": {
        "tenDoiTuon": "05",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "LH2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 10,
        "tuDien2": "LH2",
        "diaChiLapD": "",
        "longitude": 105.81003148874747,
        "latitude": 21.01170668372867,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4316,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80986116848399,
          21.011825617096925
        ]
      },
      "properties": {
        "tenDoiTuon": "06",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "LH2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 10,
        "tuDien2": "LH2",
        "diaChiLapD": "",
        "longitude": 105.80986116848399,
        "latitude": 21.011825617096925,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4317,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80965732059677,
          21.011959573514357
        ]
      },
      "properties": {
        "tenDoiTuon": "07",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "LH2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 10,
        "tuDien2": "LH2",
        "diaChiLapD": "",
        "longitude": 105.80965732059677,
        "latitude": 21.011959573514357,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4318,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80941189847161,
          21.01213609533858
        ]
      },
      "properties": {
        "tenDoiTuon": "08",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "LH2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 10,
        "tuDien2": "LH2",
        "diaChiLapD": "",
        "longitude": 105.80941189847161,
        "latitude": 21.01213609533858,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4319,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80920268616892,
          21.012281318810153
        ]
      },
      "properties": {
        "tenDoiTuon": "09",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "LH2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 10,
        "tuDien2": "LH2",
        "diaChiLapD": "",
        "longitude": 105.80920268616892,
        "latitude": 21.012281318810153,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4320,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80902029595264,
          21.01240651134401
        ]
      },
      "properties": {
        "tenDoiTuon": "10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "LH2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 10,
        "tuDien2": "LH2",
        "diaChiLapD": "",
        "longitude": 105.80902029595264,
        "latitude": 21.01240651134401,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4321,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80880437813057,
          21.012557994170557
        ]
      },
      "properties": {
        "tenDoiTuon": "11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "LH2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 10,
        "tuDien2": "LH2",
        "diaChiLapD": "",
        "longitude": 105.80880437813057,
        "latitude": 21.012557994170557,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4322,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8086300345465,
          21.012680682627543
        ]
      },
      "properties": {
        "tenDoiTuon": "12",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "LH2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 10,
        "tuDien2": "LH2",
        "diaChiLapD": "",
        "longitude": 105.8086300345465,
        "latitude": 21.012680682627543,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4323,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8084355743864,
          21.01281463827711
        ]
      },
      "properties": {
        "tenDoiTuon": "13",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "LH2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 10,
        "tuDien2": "LH2",
        "diaChiLapD": "",
        "longitude": 105.8084355743864,
        "latitude": 21.01281463827711,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4324,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80821697436558,
          21.01296862452991
        ]
      },
      "properties": {
        "tenDoiTuon": "14",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "LH2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 10,
        "tuDien2": "LH2",
        "diaChiLapD": "",
        "longitude": 105.80821697436558,
        "latitude": 21.01296862452991,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4325,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80799703322295,
          21.013130122135376
        ]
      },
      "properties": {
        "tenDoiTuon": "15",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "LH2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 10,
        "tuDien2": "LH2",
        "diaChiLapD": "",
        "longitude": 105.80799703322295,
        "latitude": 21.013130122135376,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4326,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80780659638343,
          21.013261573546394
        ]
      },
      "properties": {
        "tenDoiTuon": "16",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "LH2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 10,
        "tuDien2": "LH2",
        "diaChiLapD": "",
        "longitude": 105.80780659638343,
        "latitude": 21.013261573546394,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4327,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80759067856138,
          21.01341681125356
        ]
      },
      "properties": {
        "tenDoiTuon": "17",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "LH2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 10,
        "tuDien2": "LH2",
        "diaChiLapD": "",
        "longitude": 105.80759067856138,
        "latitude": 21.01341681125356,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4328,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80734257422847,
          21.013594583267487
        ]
      },
      "properties": {
        "tenDoiTuon": "18",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "LH2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 10,
        "tuDien2": "LH2",
        "diaChiLapD": "",
        "longitude": 105.80734257422847,
        "latitude": 21.013594583267487,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4329,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80713872634126,
          21.01374856871426
        ]
      },
      "properties": {
        "tenDoiTuon": "19",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "LH2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 10,
        "tuDien2": "LH2",
        "diaChiLapD": "",
        "longitude": 105.80713872634126,
        "latitude": 21.01374856871426,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4330,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80690671526379,
          21.013916325036497
        ]
      },
      "properties": {
        "tenDoiTuon": "20",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "LH2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 10,
        "tuDien2": "LH2",
        "diaChiLapD": "",
        "longitude": 105.80690671526379,
        "latitude": 21.013916325036497,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4331,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80670689068819,
          21.014077821616667
        ]
      },
      "properties": {
        "tenDoiTuon": "21",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "LH2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 10,
        "tuDien2": "LH2",
        "diaChiLapD": "",
        "longitude": 105.80670689068819,
        "latitude": 21.014077821616667,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4332,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80652986489638,
          21.01422554701653
        ]
      },
      "properties": {
        "tenDoiTuon": "22",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "LH2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 10,
        "tuDien2": "LH2",
        "diaChiLapD": "",
        "longitude": 105.80652986489638,
        "latitude": 21.01422554701653,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4333,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80632869921689,
          21.0143870432619
        ]
      },
      "properties": {
        "tenDoiTuon": "23",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "LH2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 10,
        "tuDien2": "LH2",
        "diaChiLapD": "",
        "longitude": 105.80632869921689,
        "latitude": 21.0143870432619,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4334,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80611278138588,
          21.014584844625098
        ]
      },
      "properties": {
        "tenDoiTuon": "24",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "LH2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 10,
        "tuDien2": "LH2",
        "diaChiLapD": "",
        "longitude": 105.80611278138588,
        "latitude": 21.014584844625098,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4335,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80594246111342,
          21.01474258476591
        ]
      },
      "properties": {
        "tenDoiTuon": "25",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "LH2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 10,
        "tuDien2": "LH2",
        "diaChiLapD": "",
        "longitude": 105.80594246111342,
        "latitude": 21.01474258476591,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4336,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80574934206615,
          21.01492285900758
        ]
      },
      "properties": {
        "tenDoiTuon": "LH2 26",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "LH2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 10,
        "tuDien2": "LH2",
        "diaChiLapD": "",
        "longitude": 105.80574934206615,
        "latitude": 21.01492285900758,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4337,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82420785727892,
          21.01521455228174
        ]
      },
      "properties": {
        "tenDoiTuon": "07",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "",
        "tuDien": "HCA2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hồ Cá 2",
        "diaChiLapD": "",
        "longitude": 105.82420785727892,
        "latitude": 21.01521455228174,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4338,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82405899467737,
          21.015002980820874
        ]
      },
      "properties": {
        "tenDoiTuon": "08",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "",
        "tuDien": "HCA2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hồ Cá 2",
        "diaChiLapD": "",
        "longitude": 105.82405899467737,
        "latitude": 21.015002980820874,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4339,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82395036521892,
          21.0148051800114
        ]
      },
      "properties": {
        "tenDoiTuon": "09",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "",
        "tuDien": "HCA2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hồ Cá 2",
        "diaChiLapD": "",
        "longitude": 105.82395036521892,
        "latitude": 21.0148051800114,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4340,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8237395193566,
          21.01459086253541
        ]
      },
      "properties": {
        "tenDoiTuon": "10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "",
        "tuDien": "HCA2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hồ Cá 2",
        "diaChiLapD": "",
        "longitude": 105.8237395193566,
        "latitude": 21.01459086253541,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4341,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82431240801307,
          21.015390559663796
        ]
      },
      "properties": {
        "tenDoiTuon": "06",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "",
        "tuDien": "HCA2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hồ Cá 2",
        "diaChiLapD": "",
        "longitude": 105.82431240801307,
        "latitude": 21.015390559663796,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4342,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82441335608857,
          21.01557782756755
        ]
      },
      "properties": {
        "tenDoiTuon": "05",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "",
        "tuDien": "HCA2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hồ Cá 2",
        "diaChiLapD": "",
        "longitude": 105.82441335608857,
        "latitude": 21.01557782756755,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4343,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82433526220956,
          21.01572657901467
        ]
      },
      "properties": {
        "tenDoiTuon": "01",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "",
        "tuDien": "HCA2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hồ Cá 2",
        "diaChiLapD": "",
        "longitude": 105.82433526220956,
        "latitude": 21.01572657901467,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4344,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82455133561858,
          21.01549912940455
        ]
      },
      "properties": {
        "tenDoiTuon": "02",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "",
        "tuDien": "HCA2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hồ Cá 2",
        "diaChiLapD": "",
        "longitude": 105.82455133561858,
        "latitude": 21.01549912940455,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4345,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82479563967881,
          21.01537968842695
        ]
      },
      "properties": {
        "tenDoiTuon": "03",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "",
        "tuDien": "HCA2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hồ Cá 2",
        "diaChiLapD": "",
        "longitude": 105.82479563967881,
        "latitude": 21.01537968842695,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4346,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82505706197132,
          21.01523225647875
        ]
      },
      "properties": {
        "tenDoiTuon": "04",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "",
        "tuDien": "HCA2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hồ Cá 2",
        "diaChiLapD": "",
        "longitude": 105.82505706197132,
        "latitude": 21.01523225647875,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4347,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8107530029355,
          21.01146380897505
        ]
      },
      "properties": {
        "tenDoiTuon": "2-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 530 Đường Láng",
        "tuDien": "LH2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 7,
        "tuDien2": "LH2",
        "diaChiLapD": "",
        "longitude": 105.8107530029355,
        "latitude": 21.01146380897505,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4348,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8240897221265,
          21.0144960064646
        ]
      },
      "properties": {
        "tenDoiTuon": "16",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 71 Trần Quang Diệu",
        "tuDien": "HCA2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "Hồ Cá 2",
        "diaChiLapD": "",
        "longitude": 105.8240897221265,
        "latitude": 21.0144960064646,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4349,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81020180898399,
          21.011937038798166
        ]
      },
      "properties": {
        "tenDoiTuon": "5-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 538 Đường Láng",
        "tuDien": "LH2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "LH2",
        "diaChiLapD": "",
        "longitude": 105.81020180898399,
        "latitude": 21.011937038798166,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4350,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8104190679099,
          21.012229989840638
        ]
      },
      "properties": {
        "tenDoiTuon": "5-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 538 Đường Láng",
        "tuDien": "LH2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "LH2",
        "diaChiLapD": "",
        "longitude": 105.8104190679099,
        "latitude": 21.012229989840638,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4351,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8106127530135,
          21.012108142402624
        ]
      },
      "properties": {
        "tenDoiTuon": "5-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 538 Đường Láng",
        "tuDien": "LH2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "BTLT",
        "chieuCao": 7,
        "tuDien2": "LH2",
        "diaChiLapD": "",
        "longitude": 105.8106127530135,
        "latitude": 21.012108142402624,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4352,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81060949071436,
          21.012494462440863
        ]
      },
      "properties": {
        "tenDoiTuon": "5-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 538 Đường Láng",
        "tuDien": "LH2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "LH2",
        "diaChiLapD": "",
        "longitude": 105.81060949071436,
        "latitude": 21.012494462440863,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4353,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81056390720883,
          21.012338907389438
        ]
      },
      "properties": {
        "tenDoiTuon": "5-3-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 538 Đường Láng",
        "tuDien": "LH2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 7,
        "tuDien2": "LH2",
        "diaChiLapD": "",
        "longitude": 105.81056390720883,
        "latitude": 21.012338907389438,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4354,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82851358520358,
          21.019857468772855
        ]
      },
      "properties": {
        "tenDoiTuon": "6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Đê La Thành",
        "tuDien": "LT1B",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "La Thành 1B",
        "diaChiLapD": "",
        "longitude": 105.82851358520358,
        "latitude": 21.019857468772855,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4355,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8273173199847,
          21.020550998859473
        ]
      },
      "properties": {
        "tenDoiTuon": "11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Đê La Thành",
        "tuDien": "LT1B",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "La Thành 1B",
        "diaChiLapD": "",
        "longitude": 105.8273173199847,
        "latitude": 21.020550998859473,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4356,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8260975406088,
          21.02113495930773
        ]
      },
      "properties": {
        "tenDoiTuon": "LT1B 15",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Đê La Thành",
        "tuDien": "LT1B",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "La Thành 1B",
        "diaChiLapD": "",
        "longitude": 105.8260975406088,
        "latitude": 21.02113495930773,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4357,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82948722708105,
          21.01928661848227
        ]
      },
      "properties": {
        "tenDoiTuon": "2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Đê La Thành",
        "tuDien": "LT1B",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "La Thành 1B",
        "diaChiLapD": "",
        "longitude": 105.82948722708105,
        "latitude": 21.01928661848227,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4358,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82652338611578,
          21.020916540495307
        ]
      },
      "properties": {
        "tenDoiTuon": "14",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Đê La Thành",
        "tuDien": "LT1B",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "La Thành 1B",
        "diaChiLapD": "",
        "longitude": 105.82652338611578,
        "latitude": 21.020916540495307,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4359,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82873789055051,
          21.019731036380872
        ]
      },
      "properties": {
        "tenDoiTuon": "5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Đê La Thành",
        "tuDien": "LT1B",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "La Thành 1B",
        "diaChiLapD": "",
        "longitude": 105.82873789055051,
        "latitude": 21.019731036380872,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4360,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82694181072117,
          21.020731266079498
        ]
      },
      "properties": {
        "tenDoiTuon": "13",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Đê La Thành",
        "tuDien": "LT1B",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "La Thành 1B",
        "diaChiLapD": "",
        "longitude": 105.82694181072117,
        "latitude": 21.020731266079498,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4361,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82759626972462,
          21.020385753716752
        ]
      },
      "properties": {
        "tenDoiTuon": "10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Đê La Thành",
        "tuDien": "LT1B",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "La Thành 1B",
        "diaChiLapD": "",
        "longitude": 105.82759626972462,
        "latitude": 21.020385753716752,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4362,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82789131271099,
          21.02020298235977
        ]
      },
      "properties": {
        "tenDoiTuon": "9",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Đê La Thành",
        "tuDien": "LT1B",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "La Thành 1B",
        "diaChiLapD": "",
        "longitude": 105.82789131271099,
        "latitude": 21.02020298235977,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4363,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82974572497571,
          21.019178018974625
        ]
      },
      "properties": {
        "tenDoiTuon": "LT1B 1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Đê La Thành",
        "tuDien": "LT1B",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "La Thành 1B",
        "diaChiLapD": "",
        "longitude": 105.82974572497571,
        "latitude": 21.019178018974625,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4364,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82896687852757,
          21.01957705135948
        ]
      },
      "properties": {
        "tenDoiTuon": "4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Đê La Thành",
        "tuDien": "LT1B",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "La Thành 1B",
        "diaChiLapD": "",
        "longitude": 105.82896687852757,
        "latitude": 21.01957705135948,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4365,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82922973501206,
          21.019426827528395
        ]
      },
      "properties": {
        "tenDoiTuon": "3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Đê La Thành",
        "tuDien": "LT1B",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "La Thành 1B",
        "diaChiLapD": "",
        "longitude": 105.82922973501206,
        "latitude": 21.019426827528395,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4366,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82815953361096,
          21.020045247995522
        ]
      },
      "properties": {
        "tenDoiTuon": "8",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Đê La Thành",
        "tuDien": "LT1B",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "La Thành 1B",
        "diaChiLapD": "",
        "longitude": 105.82815953361096,
        "latitude": 21.020045247995522,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4367,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82833924161949,
          21.019950106552013
        ]
      },
      "properties": {
        "tenDoiTuon": "7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Đê La Thành",
        "tuDien": "LT1B",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "La Thành 1B",
        "diaChiLapD": "",
        "longitude": 105.82833924161949,
        "latitude": 21.019950106552013,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4368,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82670007663837,
          21.021019505399806
        ]
      },
      "properties": {
        "tenDoiTuon": "14-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 212 Đê La Thành",
        "tuDien": "LT1B",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "La Thành 1B",
        "diaChiLapD": "",
        "longitude": 105.82670007663837,
        "latitude": 21.021019505399806,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4369,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82689721899723,
          21.021179742436253
        ]
      },
      "properties": {
        "tenDoiTuon": "14-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 212 Đê La Thành",
        "tuDien": "LT1B",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "La Thành 1B",
        "diaChiLapD": "",
        "longitude": 105.82689721899723,
        "latitude": 21.021179742436253,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4370,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82659815269925,
          21.021092112828104
        ]
      },
      "properties": {
        "tenDoiTuon": "14-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 212 Đê La Thành",
        "tuDien": "LT1B",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "La Thành 1B",
        "diaChiLapD": "",
        "longitude": 105.82659815269925,
        "latitude": 21.021092112828104,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4371,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82680468278524,
          21.020406096404386
        ]
      },
      "properties": {
        "tenDoiTuon": "13-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 177 Đê La Thành",
        "tuDien": "LT1B",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "La Thành 1B",
        "diaChiLapD": "",
        "longitude": 105.82680468278524,
        "latitude": 21.020406096404386,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4372,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82693611102748,
          21.020453666988256
        ]
      },
      "properties": {
        "tenDoiTuon": "13-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 175  Đê La Thành",
        "tuDien": "LT1B",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "La Thành 1B",
        "diaChiLapD": "",
        "longitude": 105.82693611102748,
        "latitude": 21.020453666988256,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4373,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82716812211393,
          21.020319718200184
        ]
      },
      "properties": {
        "tenDoiTuon": "11-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Đê La Thành",
        "tuDien": "LT1B",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "La Thành 1B",
        "diaChiLapD": "",
        "longitude": 105.82716812211393,
        "latitude": 21.020319718200184,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4374,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82726602274143,
          21.02026213270346
        ]
      },
      "properties": {
        "tenDoiTuon": "11-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 173  Đê La Thành",
        "tuDien": "LT1B",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "La Thành 1B",
        "diaChiLapD": "",
        "longitude": 105.82726602274143,
        "latitude": 21.02026213270346,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4375,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82744573074099,
          21.020581356374752
        ]
      },
      "properties": {
        "tenDoiTuon": "11-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 180  Đê La Thành",
        "tuDien": "LT1B",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "La Thành 1B",
        "diaChiLapD": "",
        "longitude": 105.82744573074099,
        "latitude": 21.020581356374752,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4376,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82753290253753,
          21.020706541941998
        ]
      },
      "properties": {
        "tenDoiTuon": "11-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 180  Đê La Thành",
        "tuDien": "LT1B",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "La Thành 1B",
        "diaChiLapD": "",
        "longitude": 105.82753290253753,
        "latitude": 21.020706541941998,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4377,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82747657614799,
          21.020757867994078
        ]
      },
      "properties": {
        "tenDoiTuon": "11-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 180  Đê La Thành",
        "tuDien": "LT1B",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "La Thành 1B",
        "diaChiLapD": "",
        "longitude": 105.82747657614799,
        "latitude": 21.020757867994078,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4378,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82857494073936,
          21.020108153981386
        ]
      },
      "properties": {
        "tenDoiTuon": "6-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 96  Đê La Thành",
        "tuDien": "LT1B",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "La Thành 1B",
        "diaChiLapD": "",
        "longitude": 105.82857494073936,
        "latitude": 21.020108153981386,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4379,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82886596041412,
          21.019981716051117
        ]
      },
      "properties": {
        "tenDoiTuon": "6-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 96  Đê La Thành",
        "tuDien": "LT1B",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "La Thành 1B",
        "diaChiLapD": "",
        "longitude": 105.82886596041412,
        "latitude": 21.019981716051117,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4380,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8284891100467,
          21.020287169878763
        ]
      },
      "properties": {
        "tenDoiTuon": "6-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 96  Đê La Thành",
        "tuDien": "LT1B",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "La Thành 1B",
        "diaChiLapD": "",
        "longitude": 105.8284891100467,
        "latitude": 21.020287169878763,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4381,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82875733095564,
          21.020552563679704
        ]
      },
      "properties": {
        "tenDoiTuon": "6-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 96  Đê La Thành",
        "tuDien": "LT1B",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "La Thành 1B",
        "diaChiLapD": "",
        "longitude": 105.82875733095564,
        "latitude": 21.020552563679704,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4382,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82858969289094,
          21.0206139046328
        ]
      },
      "properties": {
        "tenDoiTuon": "6-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 96  Đê La Thành",
        "tuDien": "LT1B",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "La Thành 1B",
        "diaChiLapD": "",
        "longitude": 105.82858969289094,
        "latitude": 21.0206139046328,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4383,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82886596041412,
          21.020842994090213
        ]
      },
      "properties": {
        "tenDoiTuon": "6-8",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 96  Đê La Thành",
        "tuDien": "LT1B",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "La Thành 1B",
        "diaChiLapD": "",
        "longitude": 105.82886596041412,
        "latitude": 21.020842994090213,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4384,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82897593098544,
          21.021087105421064
        ]
      },
      "properties": {
        "tenDoiTuon": "6-10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 96  Đê La Thành",
        "tuDien": "LT1B",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "La Thành 1B",
        "diaChiLapD": "",
        "longitude": 105.82897593098544,
        "latitude": 21.021087105421064,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4385,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82868759351842,
          21.02078415694088
        ]
      },
      "properties": {
        "tenDoiTuon": "6-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 96  Đê La Thành",
        "tuDien": "LT1B",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "La Thành 1B",
        "diaChiLapD": "",
        "longitude": 105.82868759351842,
        "latitude": 21.02078415694088,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4386,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82872514444479,
          21.020954409054536
        ]
      },
      "properties": {
        "tenDoiTuon": "6-7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 96  Đê La Thành",
        "tuDien": "LT1B",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "La Thành 1B",
        "diaChiLapD": "",
        "longitude": 105.82872514444479,
        "latitude": 21.020954409054536,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4387,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82877476531495,
          21.02096692758542
        ]
      },
      "properties": {
        "tenDoiTuon": "6-9",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 96  Đê La Thành",
        "tuDien": "LT1B",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "La Thành 1B",
        "diaChiLapD": "",
        "longitude": 105.82877476531495,
        "latitude": 21.02096692758542,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4388,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8280089946363,
          21.021238579429497
        ]
      },
      "properties": {
        "tenDoiTuon": "14",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Đê La Thành",
        "tuDien": "LT1B",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "La Thành 1B",
        "diaChiLapD": "",
        "longitude": 105.8280089946363,
        "latitude": 21.021238579429497,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4389,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82918648439201,
          21.02102326095721
        ]
      },
      "properties": {
        "tenDoiTuon": "6-11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 96  Đê La Thành",
        "tuDien": "LT1B",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "La Thành 1B",
        "diaChiLapD": "",
        "longitude": 105.82918648439201,
        "latitude": 21.02102326095721,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4390,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8293956966947,
          21.020955660907838
        ]
      },
      "properties": {
        "tenDoiTuon": "6-12",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 96  Đê La Thành",
        "tuDien": "LT1B",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "La Thành 1B",
        "diaChiLapD": "",
        "longitude": 105.8293956966947,
        "latitude": 21.020955660907838,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4391,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82931188513675,
          21.019489032824577
        ]
      },
      "properties": {
        "tenDoiTuon": "3-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 54  Đê La Thành",
        "tuDien": "LT1B",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "La Thành 1B",
        "diaChiLapD": "",
        "longitude": 105.82931188513675,
        "latitude": 21.019489032824577,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4392,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82947079854739,
          21.01961492025891
        ]
      },
      "properties": {
        "tenDoiTuon": "3-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 54  Đê La Thành",
        "tuDien": "LT1B",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "La Thành 1B",
        "diaChiLapD": "",
        "longitude": 105.82947079854739,
        "latitude": 21.01961492025891,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4393,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82947348075514,
          21.01951101548559
        ]
      },
      "properties": {
        "tenDoiTuon": "3-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 54  Đê La Thành",
        "tuDien": "LT1B",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "La Thành 1B",
        "diaChiLapD": "",
        "longitude": 105.82947348075514,
        "latitude": 21.01951101548559,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4394,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82921196538352,
          21.019626187037797
        ]
      },
      "properties": {
        "tenDoiTuon": "3-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 54  Đê La Thành",
        "tuDien": "LT1B",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "La Thành 1B",
        "diaChiLapD": "",
        "longitude": 105.82921196538352,
        "latitude": 21.019626187037797,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4395,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82905639725362,
          21.019736351047822
        ]
      },
      "properties": {
        "tenDoiTuon": "3-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 54  Đê La Thành",
        "tuDien": "LT1B",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "La Thành 1B",
        "diaChiLapD": "",
        "longitude": 105.82905639725362,
        "latitude": 21.019736351047822,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4396,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82900275308083,
          21.019667498550888
        ]
      },
      "properties": {
        "tenDoiTuon": "3-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 54  Đê La Thành",
        "tuDien": "LT1B",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "La Thành 1B",
        "diaChiLapD": "",
        "longitude": 105.82900275308083,
        "latitude": 21.019667498550888,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4397,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82902018743114,
          21.01981146282679
        ]
      },
      "properties": {
        "tenDoiTuon": "3-7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 54  Đê La Thành",
        "tuDien": "LT1B",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "La Thành 1B",
        "diaChiLapD": "",
        "longitude": 105.82902018743114,
        "latitude": 21.01981146282679,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4398,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8108009246228,
          21.012768367164092
        ]
      },
      "properties": {
        "tenDoiTuon": "5-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 538 Đường Láng",
        "tuDien": "LH2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "LH2",
        "diaChiLapD": "",
        "longitude": 105.8108009246228,
        "latitude": 21.012768367164092,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4399,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81099858140824,
          21.013043455667486
        ]
      },
      "properties": {
        "tenDoiTuon": "5-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 538 Đường Láng",
        "tuDien": "LH2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "LH2",
        "diaChiLapD": "",
        "longitude": 105.81099858140824,
        "latitude": 21.013043455667486,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4400,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80621202309929,
          21.014810187627667
        ]
      },
      "properties": {
        "tenDoiTuon": "25-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ tổ 640 Đường Láng",
        "tuDien": "LH2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 7,
        "tuDien2": "LH2",
        "diaChiLapD": "",
        "longitude": 105.80621202309929,
        "latitude": 21.014810187627667,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4401,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80644135196901,
          21.015075591176995
        ]
      },
      "properties": {
        "tenDoiTuon": "25-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ tổ 640 Đường Láng",
        "tuDien": "LH2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "LH2",
        "diaChiLapD": "",
        "longitude": 105.80644135196901,
        "latitude": 21.015075591176995,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4402,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80647219737601,
          21.015217056083035
        ]
      },
      "properties": {
        "tenDoiTuon": "25-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ tổ 640 Đường Láng",
        "tuDien": "LH2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "LH2",
        "diaChiLapD": "",
        "longitude": 105.80647219737601,
        "latitude": 21.015217056083035,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4403,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8071518020748,
          21.014297218816957
        ]
      },
      "properties": {
        "tenDoiTuon": "9-32",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 594 Đường Láng",
        "tuDien": "LH2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "LH2",
        "diaChiLapD": "",
        "longitude": 105.8071518020748,
        "latitude": 21.014297218816957,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4404,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80695197750521,
          21.01452840449088
        ]
      },
      "properties": {
        "tenDoiTuon": "9-30",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 594 Đường Láng",
        "tuDien": "LH2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "LH2",
        "diaChiLapD": "",
        "longitude": 105.80695197750521,
        "latitude": 21.01452840449088,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4405,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80669314433533,
          21.014807996795145
        ]
      },
      "properties": {
        "tenDoiTuon": "9-31",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 594 Đường Láng",
        "tuDien": "LH2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "LH2",
        "diaChiLapD": "",
        "longitude": 105.80669314433533,
        "latitude": 21.014807996795145,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4406,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8071169333652,
          21.014651508629722
        ]
      },
      "properties": {
        "tenDoiTuon": "9-29",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 594 Đường Láng",
        "tuDien": "LH2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "LH2",
        "diaChiLapD": "",
        "longitude": 105.8071169333652,
        "latitude": 21.014651508629722,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4407,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83159472321098,
          21.022296389499605
        ]
      },
      "properties": {
        "tenDoiTuon": "HB7 14",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Tôn Đức Thắng",
        "tuDien": "HB7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Hàng Bột 7",
        "diaChiLapD": "",
        "longitude": 105.83159472321098,
        "latitude": 21.022296389499605,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4408,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83146329495077,
          21.02205853936906
        ]
      },
      "properties": {
        "tenDoiTuon": "HB7 13",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Tôn Đức Thắng",
        "tuDien": "HB7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Hàng Bột 7",
        "diaChiLapD": "",
        "longitude": 105.83146329495077,
        "latitude": 21.02205853936906,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4409,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83134527775623,
          21.021795651940444
        ]
      },
      "properties": {
        "tenDoiTuon": "HB7 12",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Tôn Đức Thắng",
        "tuDien": "HB7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Hàng Bột 7",
        "diaChiLapD": "",
        "longitude": 105.83134527775623,
        "latitude": 21.021795651940444,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4410,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8312084850985,
          21.02150271968907
        ]
      },
      "properties": {
        "tenDoiTuon": "HB7 11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Tôn Đức Thắng",
        "tuDien": "HB7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Hàng Bột 7",
        "diaChiLapD": "",
        "longitude": 105.8312084850985,
        "latitude": 21.02150271968907,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4411,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83106364580858,
          21.021157209113237
        ]
      },
      "properties": {
        "tenDoiTuon": "HB7 10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Tôn Đức Thắng",
        "tuDien": "HB7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Hàng Bột 7",
        "diaChiLapD": "",
        "longitude": 105.83106364580858,
        "latitude": 21.021157209113237,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4412,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83091344210314,
          21.020861771899348
        ]
      },
      "properties": {
        "tenDoiTuon": "HB7 9",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Tôn Đức Thắng",
        "tuDien": "HB7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Hàng Bột 7",
        "diaChiLapD": "",
        "longitude": 105.83091344210314,
        "latitude": 21.020861771899348,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4413,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83079274270087,
          21.02060639349644
        ]
      },
      "properties": {
        "tenDoiTuon": "HB7 8",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Tôn Đức Thắng",
        "tuDien": "HB7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Hàng Bột 7",
        "diaChiLapD": "",
        "longitude": 105.83079274270087,
        "latitude": 21.02060639349644,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4414,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83069081876175,
          21.020413607550022
        ]
      },
      "properties": {
        "tenDoiTuon": "HB7 7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Tôn Đức Thắng",
        "tuDien": "HB7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Hàng Bột 7",
        "diaChiLapD": "",
        "longitude": 105.83069081876175,
        "latitude": 21.020413607550022,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4415,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83056743714273,
          21.020160732099445
        ]
      },
      "properties": {
        "tenDoiTuon": "HB7 6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Tôn Đức Thắng",
        "tuDien": "HB7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Hàng Bột 7",
        "diaChiLapD": "",
        "longitude": 105.83056743714273,
        "latitude": 21.020160732099445,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4416,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83044405553271,
          21.019865292911074
        ]
      },
      "properties": {
        "tenDoiTuon": "HB7 5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Tôn Đức Thắng",
        "tuDien": "HB7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Hàng Bột 7",
        "diaChiLapD": "",
        "longitude": 105.83044405553271,
        "latitude": 21.019865292911074,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4417,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83032335612143,
          21.019587379242022
        ]
      },
      "properties": {
        "tenDoiTuon": "HB7 4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Tôn Đức Thắng",
        "tuDien": "HB7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Hàng Bột 7",
        "diaChiLapD": "",
        "longitude": 105.83032335612143,
        "latitude": 21.019587379242022,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4418,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8302053389269,
          21.019324487456277
        ]
      },
      "properties": {
        "tenDoiTuon": "HB7 3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Tôn Đức Thắng",
        "tuDien": "HB7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Hàng Bột 7",
        "diaChiLapD": "",
        "longitude": 105.8302053389269,
        "latitude": 21.019324487456277,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4419,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83014096591411,
          21.019231849287618
        ]
      },
      "properties": {
        "tenDoiTuon": "HB7 2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Đường phố - đèn pha",
        "tenDuongPh": "Tôn Đức Thắng",
        "tuDien": "HB7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Hàng Bột 7",
        "diaChiLapD": "",
        "longitude": 105.83014096591411,
        "latitude": 21.019231849287618,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4420,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80894988794101,
          21.013717896826762
        ]
      },
      "properties": {
        "tenDoiTuon": "9-44",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 25/43 Vũ Ngoc Phan",
        "tuDien": "LH2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "LH2",
        "diaChiLapD": "",
        "longitude": 105.80894988794101,
        "latitude": 21.013717896826762,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4421,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80917787570688,
          21.013849347719784
        ]
      },
      "properties": {
        "tenDoiTuon": "9-45",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 25/43 Vũ Ngoc Phan",
        "tuDien": "LH2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "LH2",
        "diaChiLapD": "",
        "longitude": 105.80917787570688,
        "latitude": 21.013849347719784,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4422,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8093535604038,
          21.014090966676523
        ]
      },
      "properties": {
        "tenDoiTuon": "9-46",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 25/43 Vũ Ngoc Phan",
        "tuDien": "LH2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "LH2",
        "diaChiLapD": "",
        "longitude": 105.8093535604038,
        "latitude": 21.014090966676523,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4423,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80950912852471,
          21.014286264614647
        ]
      },
      "properties": {
        "tenDoiTuon": "LH2 9-47",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 25/43 Vũ Ngoc Phan",
        "tuDien": "LH2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "LH2",
        "diaChiLapD": "",
        "longitude": 105.80950912852471,
        "latitude": 21.014286264614647,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4424,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80853816889092,
          21.014173592755526
        ]
      },
      "properties": {
        "tenDoiTuon": "9-41",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 25/59 Vũ Ngọc Phan",
        "tuDien": "LH2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "LH2",
        "diaChiLapD": "",
        "longitude": 105.80853816889092,
        "latitude": 21.014173592755526,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4425,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80873397015492,
          21.01438516539377
        ]
      },
      "properties": {
        "tenDoiTuon": "9-42",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 25/59 Vũ Ngọc Phan",
        "tuDien": "LH2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "LH2",
        "diaChiLapD": "",
        "longitude": 105.80873397015492,
        "latitude": 21.01438516539377,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4426,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80898341559167,
          21.014663088757843
        ]
      },
      "properties": {
        "tenDoiTuon": "LH2 9-43",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 25/59 Vũ Ngọc Phan",
        "tuDien": "LH2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "LH2",
        "diaChiLapD": "",
        "longitude": 105.80898341559167,
        "latitude": 21.014663088757843,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4427,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80822300934649,
          21.014254966883374
        ]
      },
      "properties": {
        "tenDoiTuon": "9-35",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 25/73 Vũ Ngọc Phan",
        "tuDien": "LH2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "LH2",
        "diaChiLapD": "",
        "longitude": 105.80822300934649,
        "latitude": 21.014254966883374,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4428,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8081599774376,
          21.01449408136966
        ]
      },
      "properties": {
        "tenDoiTuon": "9-36",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 25/73 Vũ Ngọc Phan",
        "tuDien": "LH2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "LH2",
        "diaChiLapD": "",
        "longitude": 105.8081599774376,
        "latitude": 21.01449408136966,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4429,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80832225107787,
          21.014675607816876
        ]
      },
      "properties": {
        "tenDoiTuon": "9-37",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 25/73 Vũ Ngọc Phan",
        "tuDien": "LH2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "LH2",
        "diaChiLapD": "",
        "longitude": 105.80832225107787,
        "latitude": 21.014675607816876,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4430,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80853951001276,
          21.014854630235828
        ]
      },
      "properties": {
        "tenDoiTuon": "9-38",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 25/73 Vũ Ngọc Phan",
        "tuDien": "LH2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "LH2",
        "diaChiLapD": "",
        "longitude": 105.80853951001276,
        "latitude": 21.014854630235828,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4431,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80846038484843,
          21.015004858672825
        ]
      },
      "properties": {
        "tenDoiTuon": "9-39",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 25/73 Vũ Ngọc Phan",
        "tuDien": "LH2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "LH2",
        "diaChiLapD": "",
        "longitude": 105.80846038484843,
        "latitude": 21.015004858672825,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4432,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80857571983523,
          21.01510751468434
        ]
      },
      "properties": {
        "tenDoiTuon": "LH2 9-40",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 25/73 Vũ Ngọc Phan",
        "tuDien": "LH2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 7,
        "tuDien2": "LH2",
        "diaChiLapD": "",
        "longitude": 105.80857571983523,
        "latitude": 21.01510751468434,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4433,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83309815096501,
          21.014799233463055
        ]
      },
      "properties": {
        "tenDoiTuon": "QKTĐ 1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Xã Đàn",
        "tuDien": "QKTĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Tập thể Quân Khu Thủ Đô",
        "diaChiLapD": "",
        "longitude": 105.83309815096501,
        "latitude": 21.014799233463055,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4434,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8327293472298,
          21.014740393929042
        ]
      },
      "properties": {
        "tenDoiTuon": "7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Xã Đàn",
        "tuDien": "QKTĐ",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Tập thể Quân Khu Thủ Đô",
        "diaChiLapD": "",
        "longitude": 105.8327293472298,
        "latitude": 21.014740393929042,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4435,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8325737791089,
          21.014879355344426
        ]
      },
      "properties": {
        "tenDoiTuon": "8",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Xã Đàn",
        "tuDien": "QKTĐ",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Tập thể Quân Khu Thủ Đô",
        "diaChiLapD": "",
        "longitude": 105.8325737791089,
        "latitude": 21.014879355344426,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4436,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83279908466703,
          21.015067140836514
        ]
      },
      "properties": {
        "tenDoiTuon": "2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Xã Đàn",
        "tuDien": "QKTĐ",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Tập thể Quân Khu Thủ Đô",
        "diaChiLapD": "",
        "longitude": 105.83279908466703,
        "latitude": 21.015067140836514,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4437,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83222106862016,
          21.01544521490885
        ]
      },
      "properties": {
        "tenDoiTuon": "6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Xã Đàn",
        "tuDien": "QKTĐ",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Tập thể Quân Khu Thủ Đô",
        "diaChiLapD": "",
        "longitude": 105.83222106862016,
        "latitude": 21.01544521490885,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4438,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83240748214804,
          21.015018316631174
        ]
      },
      "properties": {
        "tenDoiTuon": "9",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Xã Đàn",
        "tuDien": "QKTĐ",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Tập thể Quân Khu Thủ Đô",
        "diaChiLapD": "",
        "longitude": 105.83240748214804,
        "latitude": 21.015018316631174,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4439,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83265290427323,
          21.01519859053955
        ]
      },
      "properties": {
        "tenDoiTuon": "3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Xã Đàn",
        "tuDien": "QKTĐ",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Tập thể Quân Khu Thủ Đô",
        "diaChiLapD": "",
        "longitude": 105.83265290427323,
        "latitude": 21.01519859053955,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4440,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83222375082788,
          21.015184819623503
        ]
      },
      "properties": {
        "tenDoiTuon": "10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Xã Đàn",
        "tuDien": "QKTĐ",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Tập thể Quân Khu Thủ Đô",
        "diaChiLapD": "",
        "longitude": 105.83222375082788,
        "latitude": 21.015184819623503,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4441,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8323296980876,
          21.01547275669075
        ]
      },
      "properties": {
        "tenDoiTuon": "5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Xã Đàn",
        "tuDien": "QKTĐ",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Tập thể Quân Khu Thủ Đô",
        "diaChiLapD": "",
        "longitude": 105.8323296980876,
        "latitude": 21.01547275669075,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4442,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83248794841624,
          21.01533880342847
        ]
      },
      "properties": {
        "tenDoiTuon": "4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Xã Đàn",
        "tuDien": "QKTĐ",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Tập thể Quân Khu Thủ Đô",
        "diaChiLapD": "",
        "longitude": 105.83248794841624,
        "latitude": 21.01533880342847,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4443,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83131079394774,
          21.015555694979934
        ]
      },
      "properties": {
        "tenDoiTuon": "12",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 11 Xã Đàn 2",
        "tuDien": "XĐ2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Xã Đàn 2",
        "diaChiLapD": "",
        "longitude": 105.83131079394774,
        "latitude": 21.015555694979934,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4444,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83225761372982,
          21.015700915122867
        ]
      },
      "properties": {
        "tenDoiTuon": "14-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "ngách 115 ngõ xã Đàn 2",
        "tuDien": "XĐ2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Xã Đàn 2",
        "diaChiLapD": "",
        "longitude": 105.83225761372982,
        "latitude": 21.015700915122867,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4445,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83105732519934,
          21.015568213964052
        ]
      },
      "properties": {
        "tenDoiTuon": "11-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "ngách 106  ngõ xã Đàn 2",
        "tuDien": "XĐ2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Xã Đàn 2",
        "diaChiLapD": "",
        "longitude": 105.83105732519934,
        "latitude": 21.015568213964052,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4446,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83117802460163,
          21.015215178230402
        ]
      },
      "properties": {
        "tenDoiTuon": "12-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "ngách 108 ngõ xã Đàn 2",
        "tuDien": "XĐ2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Xã Đàn 2",
        "diaChiLapD": "",
        "longitude": 105.83117802460163,
        "latitude": 21.015215178230402,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4447,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8314998896834,
          21.015360398704058
        ]
      },
      "properties": {
        "tenDoiTuon": "13",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "ngõ xã Đàn 2",
        "tuDien": "XĐ2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Xã Đàn 2",
        "diaChiLapD": "",
        "longitude": 105.8314998896834,
        "latitude": 21.015360398704058,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4448,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83127458412527,
          21.01534036830258
        ]
      },
      "properties": {
        "tenDoiTuon": "12-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "ngách 108  ngõ xã Đàn 2",
        "tuDien": "XĐ2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Xã Đàn 2",
        "diaChiLapD": "",
        "longitude": 105.83127458412527,
        "latitude": 21.01534036830258,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4449,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83187003452245,
          21.015345375903404
        ]
      },
      "properties": {
        "tenDoiTuon": "14-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "ngách 115 ngõ xã Đàn 2",
        "tuDien": "XĐ2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Xã Đàn 2",
        "diaChiLapD": "",
        "longitude": 105.83187003452245,
        "latitude": 21.015345375903404,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4450,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83173343345615,
          21.01515365621359
        ]
      },
      "properties": {
        "tenDoiTuon": "14",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "ngách 115 ngõ xã Đàn 2",
        "tuDien": "XĐ2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Xã Đàn 2",
        "diaChiLapD": "",
        "longitude": 105.83173343345615,
        "latitude": 21.01515365621359,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4451,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83085884172762,
          21.01533285690141
        ]
      },
      "properties": {
        "tenDoiTuon": "11-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "ngách 106  ngõ xã Đàn 2",
        "tuDien": "XĐ2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Xã Đàn 2",
        "diaChiLapD": "",
        "longitude": 105.83085884172762,
        "latitude": 21.01533285690141,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4452,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83154816944072,
          21.014934752088283
        ]
      },
      "properties": {
        "tenDoiTuon": "15",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "ngách 150  ngõ xã Đàn 2",
        "tuDien": "XĐ2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Xã Đàn 2",
        "diaChiLapD": "",
        "longitude": 105.83154816944072,
        "latitude": 21.014934752088283,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4453,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83068718035129,
          21.015297803689943
        ]
      },
      "properties": {
        "tenDoiTuon": "11-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "ngách 106 ngõ xã Đàn 2",
        "tuDien": "XĐ2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Xã Đàn 2",
        "diaChiLapD": "",
        "longitude": 105.83068718035129,
        "latitude": 21.015297803689943,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4454,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83067108709584,
          21.015049927174836
        ]
      },
      "properties": {
        "tenDoiTuon": "11-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "ngách 106  ngõ xã Đàn 2",
        "tuDien": "XĐ2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Xã Đàn 2",
        "diaChiLapD": "",
        "longitude": 105.83067108709584,
        "latitude": 21.015049927174836,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4455,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83131481725935,
          21.014709409269702
        ]
      },
      "properties": {
        "tenDoiTuon": "16",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "ngách 150 ngõ xã Đàn 2",
        "tuDien": "XĐ2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Xã Đàn 2",
        "diaChiLapD": "",
        "longitude": 105.83131481725935,
        "latitude": 21.014709409269702,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4456,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83045919258542,
          21.014934752088283
        ]
      },
      "properties": {
        "tenDoiTuon": "11-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "ngách 108  ngõ xã Đàn 2",
        "tuDien": "XĐ2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Xã Đàn 2",
        "diaChiLapD": "",
        "longitude": 105.83045919258542,
        "latitude": 21.014934752088283,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4457,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83118070680938,
          21.014664340664794
        ]
      },
      "properties": {
        "tenDoiTuon": "17",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "ngách 150 ngõ xã Đàn 2",
        "tuDien": "XĐ2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Xã Đàn 2",
        "diaChiLapD": "",
        "longitude": 105.83118070680938,
        "latitude": 21.014664340664794,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4458,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83108414728574,
          21.014706905459047
        ]
      },
      "properties": {
        "tenDoiTuon": "16-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "ngách 115 ngõ xã Đàn 2",
        "tuDien": "XĐ2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Xã Đàn 2",
        "diaChiLapD": "",
        "longitude": 105.83108414728574,
        "latitude": 21.014706905459047,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4459,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8313872369043,
          21.014444005069592
        ]
      },
      "properties": {
        "tenDoiTuon": "18",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "ngách 115 ngõ xã Đàn 2",
        "tuDien": "XĐ2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Xã Đàn 2",
        "diaChiLapD": "",
        "longitude": 105.8313872369043,
        "latitude": 21.014444005069592,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4460,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83159108479153,
          21.014248707337174
        ]
      },
      "properties": {
        "tenDoiTuon": "19",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "ngách 150 ngõ xã Đàn 2",
        "tuDien": "XĐ2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Xã Đàn 2",
        "diaChiLapD": "",
        "longitude": 105.83159108479153,
        "latitude": 21.014248707337174,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4461,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83067913372805,
          21.01470440164751
        ]
      },
      "properties": {
        "tenDoiTuon": "16-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "ngách 115 ngõ xã Đàn 2",
        "tuDien": "XĐ2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Xã Đàn 2",
        "diaChiLapD": "",
        "longitude": 105.83067913372805,
        "latitude": 21.01470440164751,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4462,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83179761488648,
          21.014058416993922
        ]
      },
      "properties": {
        "tenDoiTuon": "20",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "ngách 150 ngõ xã Đàn 2",
        "tuDien": "XĐ2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Xã Đàn 2",
        "diaChiLapD": "",
        "longitude": 105.83179761488648,
        "latitude": 21.014058416993922,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4463,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83115388472298,
          21.014531638583673
        ]
      },
      "properties": {
        "tenDoiTuon": "16-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "ngách 115 ngõ xã Đàn 2",
        "tuDien": "XĐ2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Xã Đàn 2",
        "diaChiLapD": "",
        "longitude": 105.83115388472298,
        "latitude": 21.014531638583673,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4464,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83086688835084,
          21.01441395928132
        ]
      },
      "properties": {
        "tenDoiTuon": "16-3-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "ngách 115 ngõ xã Đàn 2",
        "tuDien": "XĐ2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Xã Đàn 2",
        "diaChiLapD": "",
        "longitude": 105.83086688835084,
        "latitude": 21.01441395928132,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4465,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83085347731213,
          21.014276249340874
        ]
      },
      "properties": {
        "tenDoiTuon": "16-3-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "ngách 115 ngõ xã Đàn 2",
        "tuDien": "XĐ2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Xã Đàn 2",
        "diaChiLapD": "",
        "longitude": 105.83085347731213,
        "latitude": 21.014276249340874,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4466,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83112974483532,
          21.014381409670023
        ]
      },
      "properties": {
        "tenDoiTuon": "16-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "ngách 115 ngõ xã Đàn 2",
        "tuDien": "XĐ2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Xã Đàn 2",
        "diaChiLapD": "",
        "longitude": 105.83112974483532,
        "latitude": 21.014381409670023,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4467,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83126117307759,
          21.014504096627988
        ]
      },
      "properties": {
        "tenDoiTuon": "16-3-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "ngách 150 ngõ xã Đàn 2",
        "tuDien": "XĐ2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Xã Đàn 2",
        "diaChiLapD": "",
        "longitude": 105.83126117307759,
        "latitude": 21.014504096627988,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4468,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83112438041985,
          21.014213653870883
        ]
      },
      "properties": {
        "tenDoiTuon": "16-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "ngách 115 ngõ xã Đàn 2",
        "tuDien": "XĐ2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Xã Đàn 2",
        "diaChiLapD": "",
        "longitude": 105.83112438041985,
        "latitude": 21.014213653870883,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4469,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83182175476514,
          21.016036423186748
        ]
      },
      "properties": {
        "tenDoiTuon": "12-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "ngách 111 ngõ xã Đàn 2",
        "tuDien": "XĐ2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Xã Đàn 2",
        "diaChiLapD": "",
        "longitude": 105.83182175476514,
        "latitude": 21.016036423186748,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4470,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83196659405507,
          21.016149093636074
        ]
      },
      "properties": {
        "tenDoiTuon": "12-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "ngách 111  ngõ xã Đàn 2",
        "tuDien": "XĐ2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Xã Đàn 2",
        "diaChiLapD": "",
        "longitude": 105.83196659405507,
        "latitude": 21.016149093636074,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4471,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83203901369106,
          21.01606146107154
        ]
      },
      "properties": {
        "tenDoiTuon": "12-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "ngách 111  ngõ xã Đàn 2",
        "tuDien": "XĐ2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Xã Đàn 2",
        "diaChiLapD": "",
        "longitude": 105.83203901369106,
        "latitude": 21.01606146107154,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4472,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8310600074071,
          21.015680884767104
        ]
      },
      "properties": {
        "tenDoiTuon": "11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "ngõ xã Đàn 2",
        "tuDien": "XĐ2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Xã Đàn 2",
        "diaChiLapD": "",
        "longitude": 105.8310600074071,
        "latitude": 21.015680884767104,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4473,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8313040884284,
          21.015928760233543
        ]
      },
      "properties": {
        "tenDoiTuon": "11-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "ngách 105  ngõ xã Đàn 2",
        "tuDien": "XĐ2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Xã Đàn 2",
        "diaChiLapD": "",
        "longitude": 105.8313040884284,
        "latitude": 21.015928760233543,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4474,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83089371044623,
          21.015863661665886
        ]
      },
      "properties": {
        "tenDoiTuon": "10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "ngõ xã Đàn 2",
        "tuDien": "XĐ2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Xã Đàn 2",
        "diaChiLapD": "",
        "longitude": 105.83089371044623,
        "latitude": 21.015863661665886,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4475,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83105464298262,
          21.01598885119371
        ]
      },
      "properties": {
        "tenDoiTuon": "10-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "ngách 103 ngõ xã Đàn 2",
        "tuDien": "XĐ2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Xã Đàn 2",
        "diaChiLapD": "",
        "longitude": 105.83105464298262,
        "latitude": 21.01598885119371,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4476,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83123435099115,
          21.016033919398367
        ]
      },
      "properties": {
        "tenDoiTuon": "10-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "ngách 103 ngõ xã Đàn 2",
        "tuDien": "XĐ2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Xã Đàn 2",
        "diaChiLapD": "",
        "longitude": 105.83123435099115,
        "latitude": 21.016033919398367,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4477,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83141405899073,
          21.016186650433333
        ]
      },
      "properties": {
        "tenDoiTuon": "10-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "ngách 103 ngõ xã Đàn 2",
        "tuDien": "XĐ2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Xã Đàn 2",
        "diaChiLapD": "",
        "longitude": 105.83141405899073,
        "latitude": 21.016186650433333,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4478,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83072473127764,
          21.016033919398367
        ]
      },
      "properties": {
        "tenDoiTuon": "9",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "ngõ xã Đàn 2",
        "tuDien": "XĐ2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Xã Đàn 2",
        "diaChiLapD": "",
        "longitude": 105.83072473127764,
        "latitude": 21.016033919398367,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4479,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83056111652455,
          21.01623672614827
        ]
      },
      "properties": {
        "tenDoiTuon": "8",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "ngõ xã Đàn 2",
        "tuDien": "XĐ2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Xã Đàn 2",
        "diaChiLapD": "",
        "longitude": 105.83056111652455,
        "latitude": 21.01623672614827,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4480,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83098758776212,
          21.01624423750474
        ]
      },
      "properties": {
        "tenDoiTuon": "9-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "ngách 95  ngõ xã Đàn 2",
        "tuDien": "XĐ2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Xã Đàn 2",
        "diaChiLapD": "",
        "longitude": 105.83098758776212,
        "latitude": 21.01624423750474,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4481,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83044578153773,
          21.016444540187155
        ]
      },
      "properties": {
        "tenDoiTuon": "4-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "ngõ xã Đàn 2",
        "tuDien": "XĐ2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Xã Đàn 2",
        "diaChiLapD": "",
        "longitude": 105.83044578153773,
        "latitude": 21.016444540187155,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4482,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83101172764079,
          21.016429517494863
        ]
      },
      "properties": {
        "tenDoiTuon": "9-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "ngách 97 ngõ xã Đàn 2",
        "tuDien": "XĐ2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Xã Đàn 2",
        "diaChiLapD": "",
        "longitude": 105.83101172764079,
        "latitude": 21.016429517494863,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4483,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83123703319892,
          21.01645205153231
        ]
      },
      "properties": {
        "tenDoiTuon": "9-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "ngách 97 ngõ xã Đàn 2",
        "tuDien": "XĐ2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Xã Đàn 2",
        "diaChiLapD": "",
        "longitude": 105.83123703319892,
        "latitude": 21.01645205153231,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4484,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80952991564818,
          21.01242904598843
        ]
      },
      "properties": {
        "tenDoiTuon": "9-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 562 Đường Láng",
        "tuDien": "LH2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "LH2",
        "diaChiLapD": "",
        "longitude": 105.80952991564818,
        "latitude": 21.01242904598843,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4485,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80980350096364,
          21.012615582642198
        ]
      },
      "properties": {
        "tenDoiTuon": "9-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 562 Đường Láng",
        "tuDien": "LH2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "LH2",
        "diaChiLapD": "",
        "longitude": 105.80980350096364,
        "latitude": 21.012615582642198,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4486,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81001941879467,
          21.012882242016904
        ]
      },
      "properties": {
        "tenDoiTuon": "9-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 562 Đường Láng",
        "tuDien": "LH2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "LH2",
        "diaChiLapD": "",
        "longitude": 105.81001941879467,
        "latitude": 21.012882242016904,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4487,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81022326667288,
          21.013102579919217
        ]
      },
      "properties": {
        "tenDoiTuon": "9-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 562 Đường Láng",
        "tuDien": "LH2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "LH2",
        "diaChiLapD": "",
        "longitude": 105.81022326667288,
        "latitude": 21.013102579919217,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4488,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81045930107096,
          21.013341696252944
        ]
      },
      "properties": {
        "tenDoiTuon": "9-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 562 Đường Láng",
        "tuDien": "LH2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "LH2",
        "diaChiLapD": "",
        "longitude": 105.81045930107096,
        "latitude": 21.013341696252944,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4489,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81064973791047,
          21.013535743256572
        ]
      },
      "properties": {
        "tenDoiTuon": "9-7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 562 Đường Láng",
        "tuDien": "LH2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "LH2",
        "diaChiLapD": "",
        "longitude": 105.81064973791047,
        "latitude": 21.013535743256572,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4490,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83151866514658,
          21.016427013713084
        ]
      },
      "properties": {
        "tenDoiTuon": "9-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "ngách 97 ngõ xã Đàn 2",
        "tuDien": "XĐ2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Xã Đàn 2",
        "diaChiLapD": "",
        "longitude": 105.83151866514658,
        "latitude": 21.016427013713084,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4491,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83167691547521,
          21.016286801847247
        ]
      },
      "properties": {
        "tenDoiTuon": "8-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "ngách 85 ngõ xã Đàn 2",
        "tuDien": "XĐ2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Xã Đàn 2",
        "diaChiLapD": "",
        "longitude": 105.83167691547521,
        "latitude": 21.016286801847247,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4492,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83078105766718,
          21.016354404013217
        ]
      },
      "properties": {
        "tenDoiTuon": "8-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "ngách 97 ngõ xã Đàn 2",
        "tuDien": "XĐ2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Xã Đàn 2",
        "diaChiLapD": "",
        "longitude": 105.83078105766718,
        "latitude": 21.016354404013217,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4493,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83134968597797,
          21.01655470654768
        ]
      },
      "properties": {
        "tenDoiTuon": "9-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "ngách 97 ngõ xã Đàn 2",
        "tuDien": "XĐ2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Xã Đàn 2",
        "diaChiLapD": "",
        "longitude": 105.83134968597797,
        "latitude": 21.01655470654768,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4494,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81013877709307,
          21.013175191202187
        ]
      },
      "properties": {
        "tenDoiTuon": "9-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 562 Đường Láng",
        "tuDien": "LH2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "LH2",
        "diaChiLapD": "",
        "longitude": 105.81013877709307,
        "latitude": 21.013175191202187,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4495,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80964256842725,
          21.01274828642637
        ]
      },
      "properties": {
        "tenDoiTuon": "9-8",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 562 Đường Láng",
        "tuDien": "LH2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "LH2",
        "diaChiLapD": "",
        "longitude": 105.80964256842725,
        "latitude": 21.01274828642637,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4496,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8093810530646,
          21.012953601485016
        ]
      },
      "properties": {
        "tenDoiTuon": "9-9",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 562 Đường Láng",
        "tuDien": "LH2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "LH2",
        "diaChiLapD": "",
        "longitude": 105.8093810530646,
        "latitude": 21.012953601485016,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4497,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8302955778323,
          21.016386953193077
        ]
      },
      "properties": {
        "tenDoiTuon": "5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "ngõ xã Đàn 2",
        "tuDien": "XĐ2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Xã Đàn 2",
        "diaChiLapD": "",
        "longitude": 105.8302955778323,
        "latitude": 21.016386953193077,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4498,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8303492220141,
          21.01662230859306
        ]
      },
      "properties": {
        "tenDoiTuon": "4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "ngõ xã Đàn 2",
        "tuDien": "XĐ2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Xã Đàn 2",
        "diaChiLapD": "",
        "longitude": 105.8303492220141,
        "latitude": 21.01662230859306,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4499,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83058793861993,
          21.01696282212975
        ]
      },
      "properties": {
        "tenDoiTuon": "1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "ngõ xã Đàn 2",
        "tuDien": "XĐ2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Xã Đàn 2",
        "diaChiLapD": "",
        "longitude": 105.83058793861993,
        "latitude": 21.01696282212975,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4500,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83063621837725,
          21.01660728591868
        ]
      },
      "properties": {
        "tenDoiTuon": "2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "ngõ xã Đàn 2",
        "tuDien": "XĐ2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Xã Đàn 2",
        "diaChiLapD": "",
        "longitude": 105.83063621837725,
        "latitude": 21.01660728591868,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4501,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83069254476678,
          21.016499623377573
        ]
      },
      "properties": {
        "tenDoiTuon": "3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "ngõ xã Đàn 2",
        "tuDien": "XĐ2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Xã Đàn 2",
        "diaChiLapD": "",
        "longitude": 105.83069254476678,
        "latitude": 21.016499623377573,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4502,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83017487843003,
          21.016033919398367
        ]
      },
      "properties": {
        "tenDoiTuon": "7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "ngõ xã Đàn 2",
        "tuDien": "XĐ2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Xã Đàn 2",
        "diaChiLapD": "",
        "longitude": 105.83017487843003,
        "latitude": 21.016033919398367,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4503,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8301534207591,
          21.016301824553057
        ]
      },
      "properties": {
        "tenDoiTuon": "6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "ngõ xã Đàn 2",
        "tuDien": "XĐ2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Xã Đàn 2",
        "diaChiLapD": "",
        "longitude": 105.8301534207591,
        "latitude": 21.016301824553057,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4504,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83024729807498,
          21.016041430764197
        ]
      },
      "properties": {
        "tenDoiTuon": "8-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "ngõ xã Đàn 2",
        "tuDien": "XĐ2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Xã Đàn 2",
        "diaChiLapD": "",
        "longitude": 105.83024729807498,
        "latitude": 21.016041430764197,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4505,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83040823061138,
          21.016131567127285
        ]
      },
      "properties": {
        "tenDoiTuon": "8-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "ngõ xã Đàn 2",
        "tuDien": "XĐ2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Xã Đàn 2",
        "diaChiLapD": "",
        "longitude": 105.83040823061138,
        "latitude": 21.016131567127285,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4506,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80921911472953,
          21.01311290824929
        ]
      },
      "properties": {
        "tenDoiTuon": "9-13",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 562 Đường Láng",
        "tuDien": "LH2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "LH2",
        "diaChiLapD": "",
        "longitude": 105.80921911472953,
        "latitude": 21.01311290824929,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4507,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80899380917138,
          21.013321978558224
        ]
      },
      "properties": {
        "tenDoiTuon": "9-14",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 562 Đường Láng",
        "tuDien": "LH2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "LH2",
        "diaChiLapD": "",
        "longitude": 105.80899380917138,
        "latitude": 21.013321978558224,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4508,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80878057354809,
          21.013516025588338
        ]
      },
      "properties": {
        "tenDoiTuon": "9-15",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 562 Đường Láng",
        "tuDien": "LH2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "LH2",
        "diaChiLapD": "",
        "longitude": 105.80878057354809,
        "latitude": 21.013516025588338,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4509,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80852308148809,
          21.01374011829523
        ]
      },
      "properties": {
        "tenDoiTuon": "9-16",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 562 Đường Láng",
        "tuDien": "LH2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "LH2",
        "diaChiLapD": "",
        "longitude": 105.80852308148809,
        "latitude": 21.01374011829523,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4510,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80829107041062,
          21.013931660957684
        ]
      },
      "properties": {
        "tenDoiTuon": "9-17",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 562 Đường Láng",
        "tuDien": "LH2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "LH2",
        "diaChiLapD": "",
        "longitude": 105.80829107041062,
        "latitude": 21.013931660957684,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4511,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80806308264474,
          21.01407062312657
        ]
      },
      "properties": {
        "tenDoiTuon": "9-18",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 562 Đường Láng",
        "tuDien": "LH2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "LH2",
        "diaChiLapD": "",
        "longitude": 105.80806308264474,
        "latitude": 21.01407062312657,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4512,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8077211010049,
          21.014432424925307
        ]
      },
      "properties": {
        "tenDoiTuon": "9-19",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 562 Đường Láng",
        "tuDien": "LH2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "LH2",
        "diaChiLapD": "",
        "longitude": 105.8077211010049,
        "latitude": 21.014432424925307,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4513,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80749043102232,
          21.014684058183757
        ]
      },
      "properties": {
        "tenDoiTuon": "9-20",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 562 Đường Láng",
        "tuDien": "LH2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "LH2",
        "diaChiLapD": "",
        "longitude": 105.80749043102232,
        "latitude": 21.014684058183757,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4514,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80711894508836,
          21.015063385129928
        ]
      },
      "properties": {
        "tenDoiTuon": "9-21",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 562 Đường Láng",
        "tuDien": "LH2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "LH2",
        "diaChiLapD": "",
        "longitude": 105.80711894508836,
        "latitude": 21.015063385129928,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4515,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8069043683612,
          21.015286223611938
        ]
      },
      "properties": {
        "tenDoiTuon": "9-22",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 562 Đường Láng",
        "tuDien": "LH2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "LH2",
        "diaChiLapD": "",
        "longitude": 105.8069043683612,
        "latitude": 21.015286223611938,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4516,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80670320269071,
          21.01549904657096
        ]
      },
      "properties": {
        "tenDoiTuon": "9-23",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 562 Đường Láng",
        "tuDien": "LH2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "LH2",
        "diaChiLapD": "",
        "longitude": 105.80670320269071,
        "latitude": 21.01549904657096,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4517,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80644839282947,
          21.015789486824076
        ]
      },
      "properties": {
        "tenDoiTuon": "9-24",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 562 Đường Láng",
        "tuDien": "LH2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "LH2",
        "diaChiLapD": "",
        "longitude": 105.80644839282947,
        "latitude": 21.015789486824076,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4518,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8061533498431,
          21.01611497953921
        ]
      },
      "properties": {
        "tenDoiTuon": "LH2 9-25",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 562 Đường Láng",
        "tuDien": "LH2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "LH2",
        "diaChiLapD": "",
        "longitude": 105.8061533498431,
        "latitude": 21.01611497953921,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4519,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80953259793677,
          21.013130122131177
        ]
      },
      "properties": {
        "tenDoiTuon": "9-10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 25/7 Vũ Ngọc Phan",
        "tuDien": "LH2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "LH2",
        "diaChiLapD": "",
        "longitude": 105.80953259793677,
        "latitude": 21.013130122131177,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4520,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.809694871595,
          21.01326783312983
        ]
      },
      "properties": {
        "tenDoiTuon": "9-13",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 25/7 Vũ Ngọc Phan",
        "tuDien": "LH2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "LH2",
        "diaChiLapD": "",
        "longitude": 105.809694871595,
        "latitude": 21.01326783312983,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4521,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80958892433529,
          21.013340444332375
        ]
      },
      "properties": {
        "tenDoiTuon": "9-11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 25/7 Vũ Ngọc Phan",
        "tuDien": "LH2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "LH2",
        "diaChiLapD": "",
        "longitude": 105.80958892433529,
        "latitude": 21.013340444332375,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4522,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80968146054731,
          21.01339928441948
        ]
      },
      "properties": {
        "tenDoiTuon": "9-12",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 25/7 Vũ Ngọc Phan",
        "tuDien": "LH2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "LH2",
        "diaChiLapD": "",
        "longitude": 105.80968146054731,
        "latitude": 21.01339928441948,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4523,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80945883719693,
          21.013559529643274
        ]
      },
      "properties": {
        "tenDoiTuon": "9-12-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 25/7 Vũ Ngọc Phan",
        "tuDien": "LH2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "LH2",
        "diaChiLapD": "",
        "longitude": 105.80945883719693,
        "latitude": 21.013559529643274,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4524,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8096320454889,
          21.013794363739855
        ]
      },
      "properties": {
        "tenDoiTuon": "LH2 9-12-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 25/7 Vũ Ngọc Phan",
        "tuDien": "LH2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "LH2",
        "diaChiLapD": "",
        "longitude": 105.8096320454889,
        "latitude": 21.013794363739855,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4525,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80780890153747,
          21.014559176558166
        ]
      },
      "properties": {
        "tenDoiTuon": "9-33",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 107/51 Vũ Ngọc Phan",
        "tuDien": "LH2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "LH2",
        "diaChiLapD": "",
        "longitude": 105.80780890153747,
        "latitude": 21.014559176558166,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4526,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80792242849915,
          21.014678148833017
        ]
      },
      "properties": {
        "tenDoiTuon": "LH2 9-34",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 107/51 Vũ Ngọc Phan",
        "tuDien": "LH2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "LH2",
        "diaChiLapD": "",
        "longitude": 105.80792242849915,
        "latitude": 21.014678148833017,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4527,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80739890073478,
          21.014917851387523
        ]
      },
      "properties": {
        "tenDoiTuon": "9-48",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 65/25 Vũ Ngọc Phan",
        "tuDien": "LH2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "LH2",
        "diaChiLapD": "",
        "longitude": 105.80739890073478,
        "latitude": 21.014917851387523,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4528,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80757324431887,
          21.015098125417346
        ]
      },
      "properties": {
        "tenDoiTuon": "9-49",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 65/25 Vũ Ngọc Phan",
        "tuDien": "LH2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "LH2",
        "diaChiLapD": "",
        "longitude": 105.80757324431887,
        "latitude": 21.015098125417346,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4529,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80773954127972,
          21.015265880221644
        ]
      },
      "properties": {
        "tenDoiTuon": "9-50",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 65/25 Vũ Ngọc Phan",
        "tuDien": "LH2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "LH2",
        "diaChiLapD": "",
        "longitude": 105.80773954127972,
        "latitude": 21.015265880221644,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4530,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80790583824056,
          21.01545116142808
        ]
      },
      "properties": {
        "tenDoiTuon": "9-51",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 65/25 Vũ Ngọc Phan",
        "tuDien": "LH2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "LH2",
        "diaChiLapD": "",
        "longitude": 105.80790583824056,
        "latitude": 21.01545116142808,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4531,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80807213518345,
          21.015556320928834
        ]
      },
      "properties": {
        "tenDoiTuon": "9-52",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 65/25 Vũ Ngọc Phan",
        "tuDien": "LH2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "LH2",
        "diaChiLapD": "",
        "longitude": 105.80807213518345,
        "latitude": 21.015556320928834,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4532,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80834303830014,
          21.015382306951743
        ]
      },
      "properties": {
        "tenDoiTuon": "LH2 9-53",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 65/25 Vũ Ngọc Phan",
        "tuDien": "LH2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "LH2",
        "diaChiLapD": "",
        "longitude": 105.80834303830014,
        "latitude": 21.015382306951743,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4533,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8069674003779,
          21.015659289532902
        ]
      },
      "properties": {
        "tenDoiTuon": "9-27",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "TT Cơ Yếu",
        "tuDien": "LH2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "LH2",
        "diaChiLapD": "",
        "longitude": 105.8069674003779,
        "latitude": 21.015659289532902,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4534,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80709528265874,
          21.01559804451161
        ]
      },
      "properties": {
        "tenDoiTuon": "9-28",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "TT Cơ Yếu",
        "tuDien": "LH2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "LH2",
        "diaChiLapD": "",
        "longitude": 105.80709528265874,
        "latitude": 21.01559804451161,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4535,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80682256108796,
          21.015759441300972
        ]
      },
      "properties": {
        "tenDoiTuon": "9-26",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "TT Cơ Yếu",
        "tuDien": "LH2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "LH2",
        "diaChiLapD": "",
        "longitude": 105.80682256108796,
        "latitude": 21.015759441300972,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4536,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8246740249791,
          21.01487716451124
        ]
      },
      "properties": {
        "tenDoiTuon": "12",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Khu TT VP Chính Phủ",
        "tuDien": "HCA2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hồ Cá 2",
        "diaChiLapD": "",
        "longitude": 105.8246740249791,
        "latitude": 21.01487716451124,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4537,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82470352927324,
          21.01483960738337
        ]
      },
      "properties": {
        "tenDoiTuon": "13",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Khu TT VP Chính Phủ",
        "tuDien": "HCA2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hồ Cá 2",
        "diaChiLapD": "",
        "longitude": 105.82470352927324,
        "latitude": 21.01483960738337,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4538,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82441485135423,
          21.014455238108546
        ]
      },
      "properties": {
        "tenDoiTuon": "14",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Khu TT VP Chính Phủ",
        "tuDien": "HCA2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hồ Cá 2",
        "diaChiLapD": "",
        "longitude": 105.82441485135423,
        "latitude": 21.014455238108546,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4539,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82478593081397,
          21.015092116415016
        ]
      },
      "properties": {
        "tenDoiTuon": "11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Khu TT VP Chính Phủ",
        "tuDien": "HCA2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hồ Cá 2",
        "diaChiLapD": "",
        "longitude": 105.82478593081397,
        "latitude": 21.015092116415016,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4540,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82698170859501,
          21.026721584569973
        ]
      },
      "properties": {
        "tenDoiTuon": "01",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - đèn chùm",
        "tenDuongPh": "Hồ Hào Nam",
        "tuDien": "HHN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "Hồ Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82698170859501,
        "latitude": 21.026721584569973,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4541,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8272177429841,
          21.026636461830595
        ]
      },
      "properties": {
        "tenDoiTuon": "02",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - đèn chùm",
        "tenDuongPh": "Hồ Hào Nam",
        "tuDien": "HHN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "Hồ Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.8272177429841,
        "latitude": 21.026636461830595,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4542,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82733173686704,
          21.026575123355546
        ]
      },
      "properties": {
        "tenDoiTuon": "1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - đèn chùm",
        "tenDuongPh": "Hồ Hào Nam",
        "tuDien": "HHN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "Hồ Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82733173686704,
        "latitude": 21.026575123355546,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4543,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82748730498794,
          21.026701555693904
        ]
      },
      "properties": {
        "tenDoiTuon": "03",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - đèn chùm",
        "tenDuongPh": "Hồ Hào Nam",
        "tuDien": "HHN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "Hồ Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82748730498794,
        "latitude": 21.026701555693904,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4544,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8274980338279,
          21.02697444889181
        ]
      },
      "properties": {
        "tenDoiTuon": "2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - đèn chùm",
        "tenDuongPh": "Hồ Hào Nam",
        "tuDien": "HHN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "Hồ Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.8274980338279,
        "latitude": 21.02697444889181,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4545,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82747791726085,
          21.027256104190535
        ]
      },
      "properties": {
        "tenDoiTuon": "04",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - đèn chùm",
        "tenDuongPh": "Hồ Hào Nam",
        "tuDien": "HHN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "Hồ Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82747791726085,
        "latitude": 21.027256104190535,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4546,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8274524362783,
          21.02745639207996
        ]
      },
      "properties": {
        "tenDoiTuon": "3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - đèn chùm",
        "tenDuongPh": "Hồ Hào Nam",
        "tuDien": "HHN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "Hồ Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.8274524362783,
        "latitude": 21.02745639207996,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4547,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82737197000115,
          21.027627888370862
        ]
      },
      "properties": {
        "tenDoiTuon": "05",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - đèn chùm",
        "tenDuongPh": "Hồ Hào Nam",
        "tuDien": "HHN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "Hồ Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82737197000115,
        "latitude": 21.027627888370862,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4548,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82704608160776,
          21.02718850696753
        ]
      },
      "properties": {
        "tenDoiTuon": "5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - đèn chùm",
        "tenDuongPh": "Hồ Hào Nam",
        "tuDien": "HHN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "Hồ Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82704608160776,
        "latitude": 21.02718850696753,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4549,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82698975521824,
          21.026812966280612
        ]
      },
      "properties": {
        "tenDoiTuon": "6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - đèn chùm",
        "tenDuongPh": "Hồ Hào Nam",
        "tuDien": "HHN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "Hồ Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82698975521824,
        "latitude": 21.026812966280612,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4550,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82707424480704,
          21.02697820429919
        ]
      },
      "properties": {
        "tenDoiTuon": "08",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - đèn chùm",
        "tenDuongPh": "Hồ Hào Nam",
        "tuDien": "HHN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "Hồ Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82707424480704,
        "latitude": 21.02697820429919,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4551,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82716275769843,
          21.027410075528927
        ]
      },
      "properties": {
        "tenDoiTuon": "07",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - đèn chùm",
        "tenDuongPh": "Hồ Hào Nam",
        "tuDien": "HHN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "Hồ Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82716275769843,
        "latitude": 21.027410075528927,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4552,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82715202886747,
          21.02769673719132
        ]
      },
      "properties": {
        "tenDoiTuon": "4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - đèn chùm",
        "tenDuongPh": "Hồ Hào Nam",
        "tuDien": "HHN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "Hồ Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82715202886747,
        "latitude": 21.02769673719132,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4553,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82724858839111,
          21.027804391646832
        ]
      },
      "properties": {
        "tenDoiTuon": "06",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - đèn chùm",
        "tenDuongPh": "Hồ Hào Nam",
        "tuDien": "HHN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "Hồ Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82724858839111,
        "latitude": 21.027804391646832,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4554,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8414914750478,
          21.014934299184965
        ]
      },
      "properties": {
        "tenDoiTuon": "CV3 01",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Lê Duẩn",
        "tuDien": "CVLN3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Sân vườn",
        "chieuCao": 7,
        "tuDien2": "CV3",
        "diaChiLapD": "",
        "longitude": 105.8414914750478,
        "latitude": 21.014934299184965,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4555,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84148528551779,
          21.014643373997956
        ]
      },
      "properties": {
        "tenDoiTuon": "03",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Lê Duẩn",
        "tuDien": "CVLN3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Sân vườn",
        "chieuCao": 7,
        "tuDien2": "CV3",
        "diaChiLapD": "",
        "longitude": 105.84148528551779,
        "latitude": 21.014643373997956,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4556,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84148898183355,
          21.01435828173611
        ]
      },
      "properties": {
        "tenDoiTuon": "05",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Lê Duẩn",
        "tuDien": "CVLN3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Sân vườn",
        "chieuCao": 7,
        "tuDien2": "CV3",
        "diaChiLapD": "",
        "longitude": 105.84148898183355,
        "latitude": 21.01435828173611,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4557,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84148123166405,
          21.01408164834162
        ]
      },
      "properties": {
        "tenDoiTuon": "07",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Lê Duẩn",
        "tuDien": "CVLN3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Sân vườn",
        "chieuCao": 7,
        "tuDien2": "CV3",
        "diaChiLapD": "",
        "longitude": 105.84148123166405,
        "latitude": 21.01408164834162,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4558,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84148851669177,
          21.013805003419336
        ]
      },
      "properties": {
        "tenDoiTuon": "09",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Lê Duẩn",
        "tuDien": "CVLN3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Sân vườn",
        "chieuCao": 7,
        "tuDien2": "CV3",
        "diaChiLapD": "",
        "longitude": 105.84148851669177,
        "latitude": 21.013805003419336,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4559,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84148208805887,
          21.013470643643593
        ]
      },
      "properties": {
        "tenDoiTuon": "11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Lê Duẩn",
        "tuDien": "CVLN3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Sân vườn",
        "chieuCao": 7,
        "tuDien2": "CV3",
        "diaChiLapD": "",
        "longitude": 105.84148208805887,
        "latitude": 21.013470643643593,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4560,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84147357987175,
          21.013118531772665
        ]
      },
      "properties": {
        "tenDoiTuon": "13",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Lê Duẩn",
        "tuDien": "CVLN3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Sân vườn",
        "chieuCao": 7,
        "tuDien2": "CV3",
        "diaChiLapD": "",
        "longitude": 105.84147357987175,
        "latitude": 21.013118531772665,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4561,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8414716350558,
          21.012850193405434
        ]
      },
      "properties": {
        "tenDoiTuon": "15",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Lê Duẩn",
        "tuDien": "CVLN3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Sân vườn",
        "chieuCao": 7,
        "tuDien2": "CV3",
        "diaChiLapD": "",
        "longitude": 105.8414716350558,
        "latitude": 21.012850193405434,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4562,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84146146528505,
          21.012565234931767
        ]
      },
      "properties": {
        "tenDoiTuon": "17",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Lê Duẩn",
        "tuDien": "CVLN3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Sân vườn",
        "chieuCao": 7,
        "tuDien2": "CV3",
        "diaChiLapD": "",
        "longitude": 105.84146146528505,
        "latitude": 21.012565234931767,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4563,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84146859954812,
          21.012255031882326
        ]
      },
      "properties": {
        "tenDoiTuon": "19",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Lê Duẩn",
        "tuDien": "CVLN3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Sân vườn",
        "chieuCao": 7,
        "tuDien2": "CV3",
        "diaChiLapD": "",
        "longitude": 105.84146859954812,
        "latitude": 21.012255031882326,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4564,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84147054529903,
          21.01196991996103
        ]
      },
      "properties": {
        "tenDoiTuon": "21",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Lê Duẩn",
        "tuDien": "CVLN3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Sân vườn",
        "chieuCao": 7,
        "tuDien2": "CV3",
        "diaChiLapD": "",
        "longitude": 105.84147054529903,
        "latitude": 21.01196991996103,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4565,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84148283966417,
          21.011676640345527
        ]
      },
      "properties": {
        "tenDoiTuon": "23",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Lê Duẩn",
        "tuDien": "CVLN3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Sân vườn",
        "chieuCao": 7,
        "tuDien2": "CV3",
        "diaChiLapD": "",
        "longitude": 105.84148283966417,
        "latitude": 21.011676640345527,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4566,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84151803046267,
          21.011401864169297
        ]
      },
      "properties": {
        "tenDoiTuon": "25",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Lê Duẩn",
        "tuDien": "CVLN3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "CV3",
        "diaChiLapD": "",
        "longitude": 105.84151803046267,
        "latitude": 21.011401864169297,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4567,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8415276181308,
          21.011153804070986
        ]
      },
      "properties": {
        "tenDoiTuon": "27",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Lê Duẩn",
        "tuDien": "CVLN3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "CV3",
        "diaChiLapD": "",
        "longitude": 105.8415276181308,
        "latitude": 21.011153804070986,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4568,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84146416196582,
          21.01056992632448
        ]
      },
      "properties": {
        "tenDoiTuon": "29",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Lê Duẩn",
        "tuDien": "CVLN3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Sân vườn",
        "chieuCao": 7,
        "tuDien2": "CV3",
        "diaChiLapD": "",
        "longitude": 105.84146416196582,
        "latitude": 21.01056992632448,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4569,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8414562913909,
          21.01028154461065
        ]
      },
      "properties": {
        "tenDoiTuon": "31",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Lê Duẩn",
        "tuDien": "CVLN3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Sân vườn",
        "chieuCao": 7,
        "tuDien2": "CV3",
        "diaChiLapD": "",
        "longitude": 105.8414562913909,
        "latitude": 21.01028154461065,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4570,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84144054823219,
          21.010019476911214
        ]
      },
      "properties": {
        "tenDoiTuon": "33",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Lê Duẩn",
        "tuDien": "CVLN3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Sân vườn",
        "chieuCao": 7,
        "tuDien2": "CV3",
        "diaChiLapD": "",
        "longitude": 105.84144054823219,
        "latitude": 21.010019476911214,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4571,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84144552073384,
          21.00975240711202
        ]
      },
      "properties": {
        "tenDoiTuon": "CV3 35",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Lê Duẩn",
        "tuDien": "CVLN3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Sân vườn",
        "chieuCao": 7,
        "tuDien2": "CV3",
        "diaChiLapD": "",
        "longitude": 105.84144552073384,
        "latitude": 21.00975240711202,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4572,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80817774706613,
          21.01940742359911
        ]
      },
      "properties": {
        "tenDoiTuon": "LT3 01",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Nguyễn Chí Thanh",
        "tuDien": "LT3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "LT3",
        "diaChiLapD": "",
        "longitude": 105.80817774706613,
        "latitude": 21.01940742359911,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4573,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80802620225684,
          21.01914453149611
        ]
      },
      "properties": {
        "tenDoiTuon": "02",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Nguyễn Chí Thanh",
        "tuDien": "LT3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "LT3",
        "diaChiLapD": "",
        "longitude": 105.80802620225684,
        "latitude": 21.01914453149611,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4574,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80788806849525,
          21.01890918007769
        ]
      },
      "properties": {
        "tenDoiTuon": "03",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Nguyễn Chí Thanh",
        "tuDien": "LT3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "LT3",
        "diaChiLapD": "",
        "longitude": 105.80788806849525,
        "latitude": 21.01890918007769,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4575,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80770701938283,
          21.018587449080087
        ]
      },
      "properties": {
        "tenDoiTuon": "04",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Nguyễn Chí Thanh",
        "tuDien": "LT3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "LT3",
        "diaChiLapD": "",
        "longitude": 105.80770701938283,
        "latitude": 21.018587449080087,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4576,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80758631998054,
          21.018387149275135
        ]
      },
      "properties": {
        "tenDoiTuon": "05",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Nguyễn Chí Thanh",
        "tuDien": "LT3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "LT3",
        "diaChiLapD": "",
        "longitude": 105.80758631998054,
        "latitude": 21.018387149275135,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4577,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80746562056927,
          21.01816807105598
        ]
      },
      "properties": {
        "tenDoiTuon": "06",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Nguyễn Chí Thanh",
        "tuDien": "LT3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "LT3",
        "diaChiLapD": "",
        "longitude": 105.80746562056927,
        "latitude": 21.01816807105598,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4578,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80734089785537,
          21.017943985001562
        ]
      },
      "properties": {
        "tenDoiTuon": "07",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Nguyễn Chí Thanh",
        "tuDien": "LT3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "LT3",
        "diaChiLapD": "",
        "longitude": 105.80734089785537,
        "latitude": 21.017943985001562,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4579,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80720946961313,
          21.01771864673077
        ]
      },
      "properties": {
        "tenDoiTuon": "08",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Nguyễn Chí Thanh",
        "tuDien": "LT3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "LT3",
        "diaChiLapD": "",
        "longitude": 105.80720946961313,
        "latitude": 21.01771864673077,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4580,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80706463033218,
          21.017492056237103
        ]
      },
      "properties": {
        "tenDoiTuon": "09",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Nguyễn Chí Thanh",
        "tuDien": "LT3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "LT3",
        "diaChiLapD": "",
        "longitude": 105.80706463033218,
        "latitude": 21.017492056237103,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4581,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80692783767445,
          21.017269221051077
        ]
      },
      "properties": {
        "tenDoiTuon": "10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Nguyễn Chí Thanh",
        "tuDien": "LT3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "LT3",
        "diaChiLapD": "",
        "longitude": 105.80692783767445,
        "latitude": 21.017269221051077,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4582,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80680713826318,
          21.017075178904154
        ]
      },
      "properties": {
        "tenDoiTuon": "11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Nguyễn Chí Thanh",
        "tuDien": "LT3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "LT3",
        "diaChiLapD": "",
        "longitude": 105.80680713826318,
        "latitude": 21.017075178904154,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4583,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80662608915974,
          21.01684608365719
        ]
      },
      "properties": {
        "tenDoiTuon": "LT3 12",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Nguyễn Chí Thanh",
        "tuDien": "LT3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "LT3",
        "diaChiLapD": "",
        "longitude": 105.80662608915974,
        "latitude": 21.01684608365719,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4584,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8064329701125,
          21.016639522068225
        ]
      },
      "properties": {
        "tenDoiTuon": "13",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Nguyễn Chí Thanh",
        "tuDien": "LT3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "LT3",
        "diaChiLapD": "",
        "longitude": 105.8064329701125,
        "latitude": 21.016639522068225,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4585,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80625192100008,
          21.016470516918925
        ]
      },
      "properties": {
        "tenDoiTuon": "14",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Nguyễn Chí Thanh",
        "tuDien": "LT3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "LT3",
        "diaChiLapD": "",
        "longitude": 105.80625192100008,
        "latitude": 21.016470516918925,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4586,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80603466207415,
          21.016261451024384
        ]
      },
      "properties": {
        "tenDoiTuon": "15",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Nguyễn Chí Thanh",
        "tuDien": "LT3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "LT3",
        "diaChiLapD": "",
        "longitude": 105.80603466207415,
        "latitude": 21.016261451024384,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4587,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80583886081017,
          21.01607491893035
        ]
      },
      "properties": {
        "tenDoiTuon": "16",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Nguyễn Chí Thanh",
        "tuDien": "LT3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "LT3",
        "diaChiLapD": "",
        "longitude": 105.80583886081017,
        "latitude": 21.01607491893035,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4588,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80563769513071,
          21.0158808752303
        ]
      },
      "properties": {
        "tenDoiTuon": "17",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Nguyễn Chí Thanh",
        "tuDien": "LT3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "LT3",
        "diaChiLapD": "",
        "longitude": 105.80563769513071,
        "latitude": 21.0158808752303,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4589,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80542445951642,
          21.015594190848468
        ]
      },
      "properties": {
        "tenDoiTuon": "LT3 18",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Nguyễn Chí Thanh",
        "tuDien": "LT3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "LT3",
        "diaChiLapD": "",
        "longitude": 105.80542445951642,
        "latitude": 21.015594190848468,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4590,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84134156267655,
          21.014464035592297
        ]
      },
      "properties": {
        "tenDoiTuon": "CV3 26",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Khâm Thiên",
        "tuDien": "CVLN3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "CV3",
        "diaChiLapD": "",
        "longitude": 105.84134156267655,
        "latitude": 21.014464035592297,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4591,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84133325401675,
          21.014156378469494
        ]
      },
      "properties": {
        "tenDoiTuon": "28",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Khâm Thiên",
        "tuDien": "CVLN3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "CV3",
        "diaChiLapD": "",
        "longitude": 105.84133325401675,
        "latitude": 21.014156378469494,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4592,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84132106029146,
          21.013871491707473
        ]
      },
      "properties": {
        "tenDoiTuon": "30",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Khâm Thiên",
        "tuDien": "CVLN3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "CV3",
        "diaChiLapD": "",
        "longitude": 105.84132106029146,
        "latitude": 21.013871491707473,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4593,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8413161075711,
          21.013578027774667
        ]
      },
      "properties": {
        "tenDoiTuon": "32",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Khâm Thiên",
        "tuDien": "CVLN3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "CV3",
        "diaChiLapD": "",
        "longitude": 105.8413161075711,
        "latitude": 21.013578027774667,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4594,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84131038682042,
          21.013318124698575
        ]
      },
      "properties": {
        "tenDoiTuon": "34",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Khâm Thiên",
        "tuDien": "CVLN3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "CV3",
        "diaChiLapD": "",
        "longitude": 105.84131038682042,
        "latitude": 21.013318124698575,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4595,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84130785670204,
          21.012999474720285
        ]
      },
      "properties": {
        "tenDoiTuon": "36",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Khâm Thiên",
        "tuDien": "CVLN3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "CV3",
        "diaChiLapD": "",
        "longitude": 105.84130785670204,
        "latitude": 21.012999474720285,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4596,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84130083098316,
          21.012714433878728
        ]
      },
      "properties": {
        "tenDoiTuon": "38",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Khâm Thiên",
        "tuDien": "CVLN3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "CV3",
        "diaChiLapD": "",
        "longitude": 105.84130083098316,
        "latitude": 21.012714433878728,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4597,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84129745050423,
          21.01241507782658
        ]
      },
      "properties": {
        "tenDoiTuon": "40",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Khâm Thiên",
        "tuDien": "CVLN3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "CV3",
        "diaChiLapD": "",
        "longitude": 105.84129745050423,
        "latitude": 21.01241507782658,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4598,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84129976853741,
          21.012104810527315
        ]
      },
      "properties": {
        "tenDoiTuon": "42",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Khâm Thiên",
        "tuDien": "CVLN3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "CV3",
        "diaChiLapD": "",
        "longitude": 105.84129976853741,
        "latitude": 21.012104810527315,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4599,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84129692617111,
          21.011823897979728
        ]
      },
      "properties": {
        "tenDoiTuon": "44",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Khâm Thiên",
        "tuDien": "CVLN3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "CV3",
        "diaChiLapD": "",
        "longitude": 105.84129692617111,
        "latitude": 21.011823897979728,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4600,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84129442802885,
          21.01153711176067
        ]
      },
      "properties": {
        "tenDoiTuon": "46",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Khâm Thiên",
        "tuDien": "CVLN3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "CV3",
        "diaChiLapD": "",
        "longitude": 105.84129442802885,
        "latitude": 21.01153711176067,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4601,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8412924365142,
          21.011212584443804
        ]
      },
      "properties": {
        "tenDoiTuon": "48",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Khâm Thiên",
        "tuDien": "CVLN3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "CV3",
        "diaChiLapD": "",
        "longitude": 105.8412924365142,
        "latitude": 21.011212584443804,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4602,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8412859770982,
          21.010934238756487
        ]
      },
      "properties": {
        "tenDoiTuon": "50",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Khâm Thiên",
        "tuDien": "CVLN3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "CV3",
        "diaChiLapD": "",
        "longitude": 105.8412859770982,
        "latitude": 21.010934238756487,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4603,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.827074244825,
          21.026274689645042
        ]
      },
      "properties": {
        "tenDoiTuon": "10-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Vũ Thạch",
        "tuDien": "LB3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Long Biên 3",
        "diaChiLapD": "",
        "longitude": 105.827074244825,
        "latitude": 21.026274689645042,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4604,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82685028035277,
          21.026363568013885
        ]
      },
      "properties": {
        "tenDoiTuon": "10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Vũ Thạch",
        "tuDien": "LB3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Long Biên 3",
        "diaChiLapD": "",
        "longitude": 105.82685028035277,
        "latitude": 21.026363568013885,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4605,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82634200176109,
          21.026575123355546
        ]
      },
      "properties": {
        "tenDoiTuon": "8",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Vũ Thạch",
        "tuDien": "LB3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Long Biên 3",
        "diaChiLapD": "",
        "longitude": 105.82634200176109,
        "latitude": 21.026575123355546,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4606,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.826136812779,
          21.02666024612996
        ]
      },
      "properties": {
        "tenDoiTuon": "7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Vũ Thạch",
        "tuDien": "LB3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Long Biên 3",
        "diaChiLapD": "",
        "longitude": 105.826136812779,
        "latitude": 21.02666024612996,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4607,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82588066181387,
          21.026765397725956
        ]
      },
      "properties": {
        "tenDoiTuon": "4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Vũ Thạch",
        "tuDien": "LB3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Long Biên 3",
        "diaChiLapD": "",
        "longitude": 105.82588066181387,
        "latitude": 21.026765397725956,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4608,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82655121406377,
          21.026479986079273
        ]
      },
      "properties": {
        "tenDoiTuon": "9",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Vũ Thạch",
        "tuDien": "LB3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Long Biên 3",
        "diaChiLapD": "",
        "longitude": 105.82655121406377,
        "latitude": 21.026479986079273,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4609,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82556818446818,
          21.02692938399498
        ]
      },
      "properties": {
        "tenDoiTuon": "3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Vũ Thạch",
        "tuDien": "LB3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Long Biên 3",
        "diaChiLapD": "",
        "longitude": 105.82556818446818,
        "latitude": 21.02692938399498,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4610,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82536701878873,
          21.027077096663184
        ]
      },
      "properties": {
        "tenDoiTuon": "2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Vũ Thạch",
        "tuDien": "LB3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Long Biên 3",
        "diaChiLapD": "",
        "longitude": 105.82536701878873,
        "latitude": 21.027077096663184,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4611,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8247863205341,
          21.026772908551994
        ]
      },
      "properties": {
        "tenDoiTuon": "2-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 2 Vũ Thạch",
        "tuDien": "LB3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Long Biên 3",
        "diaChiLapD": "",
        "longitude": 105.8247863205341,
        "latitude": 21.026772908551994,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4612,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82503710707476,
          21.027378780555754
        ]
      },
      "properties": {
        "tenDoiTuon": "1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Vũ Thạch",
        "tuDien": "LB3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Long Biên 3",
        "diaChiLapD": "",
        "longitude": 105.82503710707476,
        "latitude": 21.027378780555754,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4613,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82497675737362,
          21.026936894812764
        ]
      },
      "properties": {
        "tenDoiTuon": "2-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 2 Vũ Thạch",
        "tuDien": "LB3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Long Biên 3",
        "diaChiLapD": "",
        "longitude": 105.82497675737362,
        "latitude": 21.026936894812764,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4614,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80786929305005,
          21.019278481339914
        ]
      },
      "properties": {
        "tenDoiTuon": "LT3 36",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Nguyễn Chí Thanh",
        "tuDien": "LT3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Sân vườn",
        "chieuCao": 7,
        "tuDien2": "LT3",
        "diaChiLapD": "",
        "longitude": 105.80786929305005,
        "latitude": 21.019278481339914,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4615,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80776522333036,
          21.019083690686635
        ]
      },
      "properties": {
        "tenDoiTuon": "35",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Nguyễn Chí Thanh",
        "tuDien": "LT3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Sân vườn",
        "chieuCao": 7,
        "tuDien2": "LT3",
        "diaChiLapD": "",
        "longitude": 105.80776522333036,
        "latitude": 21.019083690686635,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4616,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8076598125158,
          21.01889641100092
        ]
      },
      "properties": {
        "tenDoiTuon": "34",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Nguyễn Chí Thanh",
        "tuDien": "LT3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Sân vườn",
        "chieuCao": 7,
        "tuDien2": "LT3",
        "diaChiLapD": "",
        "longitude": 105.8076598125158,
        "latitude": 21.01889641100092,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4617,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80755440170122,
          21.01870913108001
        ]
      },
      "properties": {
        "tenDoiTuon": "33",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Nguyễn Chí Thanh",
        "tuDien": "LT3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Sân vườn",
        "chieuCao": 7,
        "tuDien2": "LT3",
        "diaChiLapD": "",
        "longitude": 105.80755440170122,
        "latitude": 21.01870913108001,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4618,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80744899088664,
          21.018521850923864
        ]
      },
      "properties": {
        "tenDoiTuon": "32",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Nguyễn Chí Thanh",
        "tuDien": "LT3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Sân vườn",
        "chieuCao": 7,
        "tuDien2": "LT3",
        "diaChiLapD": "",
        "longitude": 105.80744899088664,
        "latitude": 21.018521850923864,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4619,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8073435800721,
          21.018334570532524
        ]
      },
      "properties": {
        "tenDoiTuon": "31",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Nguyễn Chí Thanh",
        "tuDien": "LT3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Sân vườn",
        "chieuCao": 7,
        "tuDien2": "LT3",
        "diaChiLapD": "",
        "longitude": 105.8073435800721,
        "latitude": 21.018334570532524,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4620,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80723816925753,
          21.018147289905972
        ]
      },
      "properties": {
        "tenDoiTuon": "30",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Nguyễn Chí Thanh",
        "tuDien": "LT3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Sân vườn",
        "chieuCao": 7,
        "tuDien2": "LT3",
        "diaChiLapD": "",
        "longitude": 105.80723816925753,
        "latitude": 21.018147289905972,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4621,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80713275844296,
          21.01796000904422
        ]
      },
      "properties": {
        "tenDoiTuon": "29",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Nguyễn Chí Thanh",
        "tuDien": "LT3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Sân vườn",
        "chieuCao": 7,
        "tuDien2": "LT3",
        "diaChiLapD": "",
        "longitude": 105.80713275844296,
        "latitude": 21.01796000904422,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4622,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80702734762839,
          21.01777272794725
        ]
      },
      "properties": {
        "tenDoiTuon": "28",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Nguyễn Chí Thanh",
        "tuDien": "LT3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Sân vườn",
        "chieuCao": 7,
        "tuDien2": "LT3",
        "diaChiLapD": "",
        "longitude": 105.80702734762839,
        "latitude": 21.01777272794725,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4623,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80692193681382,
          21.017585446615076
        ]
      },
      "properties": {
        "tenDoiTuon": "27",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Nguyễn Chí Thanh",
        "tuDien": "LT3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Sân vườn",
        "chieuCao": 7,
        "tuDien2": "LT3",
        "diaChiLapD": "",
        "longitude": 105.80692193681382,
        "latitude": 21.017585446615076,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4624,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80680579717726,
          21.017390653749352
        ]
      },
      "properties": {
        "tenDoiTuon": "LT3 26",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Nguyễn Chí Thanh",
        "tuDien": "LT3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Sân vườn",
        "chieuCao": 7,
        "tuDien2": "LT3",
        "diaChiLapD": "",
        "longitude": 105.80680579717726,
        "latitude": 21.017390653749352,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4625,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82173094920942,
          21.008586839806696
        ]
      },
      "properties": {
        "tenDoiTuon": "TH7 32-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 3 Thái Hà",
        "tuDien": "TH7",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TH7",
        "diaChiLapD": "",
        "longitude": 105.82173094920942,
        "latitude": 21.008586839806696,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4626,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84136436948228,
          21.01518557404451
        ]
      },
      "properties": {
        "tenDoiTuon": "CV3 24",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Lê Duẩn",
        "tuDien": "CVLN3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "CV3",
        "diaChiLapD": "",
        "longitude": 105.84136436948228,
        "latitude": 21.01518557404451,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4627,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84137352651257,
          21.015483980201616
        ]
      },
      "properties": {
        "tenDoiTuon": "22",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Lê Duẩn",
        "tuDien": "CVLN3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "CV3",
        "diaChiLapD": "",
        "longitude": 105.84137352651257,
        "latitude": 21.015483980201616,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4628,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84137142969693,
          21.01577747027351
        ]
      },
      "properties": {
        "tenDoiTuon": "20",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Lê Duẩn",
        "tuDien": "CVLN3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "CV3",
        "diaChiLapD": "",
        "longitude": 105.84137142969693,
        "latitude": 21.01577747027351,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4629,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8413668528614,
          21.01605416212614
        ]
      },
      "properties": {
        "tenDoiTuon": "18",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Lê Duẩn",
        "tuDien": "CVLN3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "CV3",
        "diaChiLapD": "",
        "longitude": 105.8413668528614,
        "latitude": 21.01605416212614,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4630,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84136533042503,
          21.01635603983414
        ]
      },
      "properties": {
        "tenDoiTuon": "16",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 202,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Lê Duẩn",
        "tuDien": "CVLN3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "CV3",
        "diaChiLapD": "",
        "longitude": 105.84136533042503,
        "latitude": 21.01635603983414,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4631,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84134455957313,
          21.016770685186835
        ]
      },
      "properties": {
        "tenDoiTuon": "14",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 202,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Lê Duẩn",
        "tuDien": "CVLN3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "CV3",
        "diaChiLapD": "",
        "longitude": 105.84134455957313,
        "latitude": 21.016770685186835,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4632,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8413499239886,
          21.01706112296426
        ]
      },
      "properties": {
        "tenDoiTuon": "12",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 202,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Lê Duẩn",
        "tuDien": "CVLN3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "CV3",
        "diaChiLapD": "",
        "longitude": 105.8413499239886,
        "latitude": 21.01706112296426,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4633,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84134493458228,
          21.017270709490578
        ]
      },
      "properties": {
        "tenDoiTuon": "10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 202,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Lê Duẩn",
        "tuDien": "CVLN3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "CV3",
        "diaChiLapD": "",
        "longitude": 105.84134493458228,
        "latitude": 21.017270709490578,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4634,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84135196392613,
          21.01766896733963
        ]
      },
      "properties": {
        "tenDoiTuon": "08",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 202,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Lê Duẩn",
        "tuDien": "CVLN3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "CV3",
        "diaChiLapD": "",
        "longitude": 105.84135196392613,
        "latitude": 21.01766896733963,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4635,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84134246712858,
          21.017950580460898
        ]
      },
      "properties": {
        "tenDoiTuon": "06",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 202,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Lê Duẩn",
        "tuDien": "CVLN3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "CV3",
        "diaChiLapD": "",
        "longitude": 105.84134246712858,
        "latitude": 21.017950580460898,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4636,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84135270147638,
          21.0183311611823
        ]
      },
      "properties": {
        "tenDoiTuon": "04",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 202,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Lê Duẩn",
        "tuDien": "CVLN3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "CV3",
        "diaChiLapD": "",
        "longitude": 105.84135270147638,
        "latitude": 21.0183311611823,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4637,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8413559652145,
          21.01864308528601
        ]
      },
      "properties": {
        "tenDoiTuon": "CV3 02",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 202,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Lê Duẩn",
        "tuDien": "CVLN3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "CV3",
        "diaChiLapD": "",
        "longitude": 105.8413559652145,
        "latitude": 21.01864308528601,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4638,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8271024079973,
          21.02609818455846
        ]
      },
      "properties": {
        "tenDoiTuon": "10-7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 8C Vũ Thạch",
        "tuDien": "LB3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Long Biên 3",
        "diaChiLapD": "",
        "longitude": 105.8271024079973,
        "latitude": 21.02609818455846,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4639,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82698170858605,
          21.02576269913533
        ]
      },
      "properties": {
        "tenDoiTuon": "10-8",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 8C Vũ Thạch",
        "tuDien": "LB3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Long Biên 3",
        "diaChiLapD": "",
        "longitude": 105.82698170858605,
        "latitude": 21.02576269913533,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4640,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82677786070781,
          21.02619332207845
        ]
      },
      "properties": {
        "tenDoiTuon": "10-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 8B Vũ Thạch",
        "tuDien": "LB3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Long Biên 3",
        "diaChiLapD": "",
        "longitude": 105.82677786070781,
        "latitude": 21.02619332207845,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4641,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8266491146733,
          21.025840311501888
        ]
      },
      "properties": {
        "tenDoiTuon": "10-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 8B Vũ Thạch",
        "tuDien": "LB3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Long Biên 3",
        "diaChiLapD": "",
        "longitude": 105.8266491146733,
        "latitude": 21.025840311501888,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4642,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82662765700239,
          21.026158271420154
        ]
      },
      "properties": {
        "tenDoiTuon": "10-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Tập thể Bộ Tư Lệnh Thông Tin",
        "tuDien": "LB3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Long Biên 3",
        "diaChiLapD": "",
        "longitude": 105.82662765700239,
        "latitude": 21.026158271420154,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4643,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8264720888725,
          21.02576520276034
        ]
      },
      "properties": {
        "tenDoiTuon": "10-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Tập thể Bộ Tư Lệnh Thông Tin",
        "tuDien": "LB3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Long Biên 3",
        "diaChiLapD": "",
        "longitude": 105.8264720888725,
        "latitude": 21.02576520276034,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4644,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8263165207516,
          21.02543472384978
        ]
      },
      "properties": {
        "tenDoiTuon": "10-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Tập thể Bộ Tư Lệnh Thông Tin",
        "tuDien": "LB3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Long Biên 3",
        "diaChiLapD": "",
        "longitude": 105.8263165207516,
        "latitude": 21.02543472384978,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4645,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8264506312016,
          21.026218358257637
        ]
      },
      "properties": {
        "tenDoiTuon": "11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Tập thể Bộ Tư Lệnh Thông Tin",
        "tuDien": "LB3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Long Biên 3",
        "diaChiLapD": "",
        "longitude": 105.8264506312016,
        "latitude": 21.026218358257637,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4646,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82635407167795,
          21.02598552162728
        ]
      },
      "properties": {
        "tenDoiTuon": "12",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Tập thể Bộ Tư Lệnh Thông Tin",
        "tuDien": "LB3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Long Biên 3",
        "diaChiLapD": "",
        "longitude": 105.82635407167795,
        "latitude": 21.02598552162728,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4647,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8262360544834,
          21.025727648375806
        ]
      },
      "properties": {
        "tenDoiTuon": "13",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Tập thể Bộ Tư Lệnh Thông Tin",
        "tuDien": "LB3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Long Biên 3",
        "diaChiLapD": "",
        "longitude": 105.8262360544834,
        "latitude": 21.025727648375806,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4648,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82614217716753,
          21.025517343646325
        ]
      },
      "properties": {
        "tenDoiTuon": "14",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Tập thể Bộ Tư Lệnh Thông Tin",
        "tuDien": "LB3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Long Biên 3",
        "diaChiLapD": "",
        "longitude": 105.82614217716753,
        "latitude": 21.025517343646325,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4649,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82582567650124,
          21.025592452512758
        ]
      },
      "properties": {
        "tenDoiTuon": "20",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 127/28  Hào Nam",
        "tuDien": "LB3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Long Biên 3",
        "diaChiLapD": "",
        "longitude": 105.82582567650124,
        "latitude": 21.025592452512758,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4650,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82572911697763,
          21.025437227481135
        ]
      },
      "properties": {
        "tenDoiTuon": "21",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 127/38  Hào Nam",
        "tuDien": "LB3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Long Biên 3",
        "diaChiLapD": "",
        "longitude": 105.82572911697763,
        "latitude": 21.025437227481135,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4651,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8260456176439,
          21.025251958683445
        ]
      },
      "properties": {
        "tenDoiTuon": "15",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 127 Hảo Nam",
        "tuDien": "LB3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Long Biên 3",
        "diaChiLapD": "",
        "longitude": 105.8260456176439,
        "latitude": 21.025251958683445,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4652,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82627360540975,
          21.025101740570424
        ]
      },
      "properties": {
        "tenDoiTuon": "16",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 127 Hảo Nam",
        "tuDien": "LB3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Long Biên 3",
        "diaChiLapD": "",
        "longitude": 105.82627360540975,
        "latitude": 21.025101740570424,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4653,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80779720860244,
          21.01854019087531
        ]
      },
      "properties": {
        "tenDoiTuon": "4-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 97 Nguyễn Chí Thanh",
        "tuDien": "LT3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "LT3",
        "diaChiLapD": "",
        "longitude": 105.80779720860244,
        "latitude": 21.01854019087531,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4654,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80803726631213,
          21.018415003490137
        ]
      },
      "properties": {
        "tenDoiTuon": "4-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 97 Nguyễn Chí Thanh",
        "tuDien": "LT3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "LT3",
        "diaChiLapD": "",
        "longitude": 105.80803726631213,
        "latitude": 21.018415003490137,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4655,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80825050192642,
          21.018267282239236
        ]
      },
      "properties": {
        "tenDoiTuon": "4-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 97 Nguyễn Chí Thanh",
        "tuDien": "LT3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "LT3",
        "diaChiLapD": "",
        "longitude": 105.80825050192642,
        "latitude": 21.018267282239236,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4656,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80724601465096,
          21.017647602518032
        ]
      },
      "properties": {
        "tenDoiTuon": "8-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 99 Nguyễn Chí Thanh",
        "tuDien": "LT3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "LT3",
        "diaChiLapD": "",
        "longitude": 105.80724601465096,
        "latitude": 21.017647602518032,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4657,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8074874134645,
          21.017524918146083
        ]
      },
      "properties": {
        "tenDoiTuon": "8-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 99 Nguyễn Chí Thanh",
        "tuDien": "LT3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "LT3",
        "diaChiLapD": "",
        "longitude": 105.8074874134645,
        "latitude": 21.017524918146083,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4658,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80772344785362,
          21.017407241205614
        ]
      },
      "properties": {
        "tenDoiTuon": "8-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 99 Nguyễn Chí Thanh",
        "tuDien": "LT3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "LT3",
        "diaChiLapD": "",
        "longitude": 105.80772344785362,
        "latitude": 21.017407241205614,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4659,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80797021108263,
          21.01727954910098
        ]
      },
      "properties": {
        "tenDoiTuon": "8-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 99 Nguyễn Chí Thanh",
        "tuDien": "LT3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "LT3",
        "diaChiLapD": "",
        "longitude": 105.80797021108263,
        "latitude": 21.01727954910098,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4660,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80790583806987,
          21.017141841806538
        ]
      },
      "properties": {
        "tenDoiTuon": "8-7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 99 Nguyễn Chí Thanh",
        "tuDien": "LT3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "LT3",
        "diaChiLapD": "",
        "longitude": 105.80790583806987,
        "latitude": 21.017141841806538,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4661,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80807347613461,
          21.017514903091474
        ]
      },
      "properties": {
        "tenDoiTuon": "8-10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 99 Nguyễn Chí Thanh",
        "tuDien": "LT3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "LT3",
        "diaChiLapD": "",
        "longitude": 105.80807347613461,
        "latitude": 21.017514903091474,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4662,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80798898654582,
          21.016989111748998
        ]
      },
      "properties": {
        "tenDoiTuon": "8-8",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 99 Nguyễn Chí Thanh",
        "tuDien": "LT3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "LT3",
        "diaChiLapD": "",
        "longitude": 105.80798898654582,
        "latitude": 21.016989111748998,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4663,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80817942338534,
          21.016997874953226
        ]
      },
      "properties": {
        "tenDoiTuon": "8-9",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 99 Nguyễn Chí Thanh",
        "tuDien": "LT3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "LT3",
        "diaChiLapD": "",
        "longitude": 105.80817942338534,
        "latitude": 21.016997874953226,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4664,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80841009336793,
          21.01751114744569
        ]
      },
      "properties": {
        "tenDoiTuon": "8-14",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 99 Nguyễn Chí Thanh",
        "tuDien": "LT3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "LT3",
        "diaChiLapD": "",
        "longitude": 105.80841009336793,
        "latitude": 21.01751114744569,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4665,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80833633261908,
          21.01737344036508
        ]
      },
      "properties": {
        "tenDoiTuon": "8-11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 99 Nguyễn Chí Thanh",
        "tuDien": "LT3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "LT3",
        "diaChiLapD": "",
        "longitude": 105.80833633261908,
        "latitude": 21.01737344036508,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4666,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80852408725086,
          21.01731084619505
        ]
      },
      "properties": {
        "tenDoiTuon": "8-12",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 99 Nguyễn Chí Thanh",
        "tuDien": "LT3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "LT3",
        "diaChiLapD": "",
        "longitude": 105.80852408725086,
        "latitude": 21.01731084619505,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4667,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8084114344718,
          21.017060469254655
        ]
      },
      "properties": {
        "tenDoiTuon": "8-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 99 Nguyễn Chí Thanh",
        "tuDien": "LT3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "LT3",
        "diaChiLapD": "",
        "longitude": 105.8084114344718,
        "latitude": 21.017060469254655,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4668,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80815930681828,
          21.01766262508681
        ]
      },
      "properties": {
        "tenDoiTuon": "8-13",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 99 Nguyễn Chí Thanh",
        "tuDien": "LT3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "LT3",
        "diaChiLapD": "",
        "longitude": 105.80815930681828,
        "latitude": 21.01766262508681,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4669,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80861662345389,
          21.017434782625156
        ]
      },
      "properties": {
        "tenDoiTuon": "8-15",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 99 Nguyễn Chí Thanh",
        "tuDien": "LT3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "LT3",
        "diaChiLapD": "",
        "longitude": 105.80861662345389,
        "latitude": 21.017434782625156,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4670,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80819316970226,
          21.01719410799658
        ]
      },
      "properties": {
        "tenDoiTuon": "8-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 99 Nguyễn Chí Thanh",
        "tuDien": "LT3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "LT3",
        "diaChiLapD": "",
        "longitude": 105.80819316970226,
        "latitude": 21.01719410799658,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4671,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80828671174893,
          21.019189911720176
        ]
      },
      "properties": {
        "tenDoiTuon": "LT3 1-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "TT Daaif Khí Nguyễn Chí Thanh",
        "tuDien": "LT3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "LT3",
        "diaChiLapD": "",
        "longitude": 105.80828671174893,
        "latitude": 21.019189911720176,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4672,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8265123220156,
          21.025234433244663
        ]
      },
      "properties": {
        "tenDoiTuon": "17",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 127 Hảo Nam",
        "tuDien": "LB3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Long Biên 3",
        "diaChiLapD": "",
        "longitude": 105.8265123220156,
        "latitude": 21.025234433244663,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4673,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82681272941744,
          21.02516683510479
        ]
      },
      "properties": {
        "tenDoiTuon": "18",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 127 Hảo Nam",
        "tuDien": "LB3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Long Biên 3",
        "diaChiLapD": "",
        "longitude": 105.82681272941744,
        "latitude": 21.02516683510479,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4674,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82630042749615,
          21.024806311173382
        ]
      },
      "properties": {
        "tenDoiTuon": "16-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Vũ Thạnh",
        "tuDien": "LB3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Long Biên 3",
        "diaChiLapD": "",
        "longitude": 105.82630042749615,
        "latitude": 21.024806311173382,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4675,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82625482994659,
          21.02454843588191
        ]
      },
      "properties": {
        "tenDoiTuon": "16-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Vũ Thạnh",
        "tuDien": "LB3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Long Biên 3",
        "diaChiLapD": "",
        "longitude": 105.82625482994659,
        "latitude": 21.02454843588191,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4676,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82559232431993,
          21.02505166783188
        ]
      },
      "properties": {
        "tenDoiTuon": "22",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 127/38  Hào Nam",
        "tuDien": "LB3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Long Biên 3",
        "diaChiLapD": "",
        "longitude": 105.82559232431993,
        "latitude": 21.02505166783188,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4677,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82547430712535,
          21.025474781938854
        ]
      },
      "properties": {
        "tenDoiTuon": "21-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 127/28  Hào Nam",
        "tuDien": "LB3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Long Biên 3",
        "diaChiLapD": "",
        "longitude": 105.82547430712535,
        "latitude": 21.025474781938854,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4678,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82535092550634,
          21.02563751781378
        ]
      },
      "properties": {
        "tenDoiTuon": "21-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 127/28  Hào Nam",
        "tuDien": "LB3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Long Biên 3",
        "diaChiLapD": "",
        "longitude": 105.82535092550634,
        "latitude": 21.02563751781378,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4679,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82531069237224,
          21.025492307349392
        ]
      },
      "properties": {
        "tenDoiTuon": "21-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 127/28  Hào Nam",
        "tuDien": "LB3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Long Biên 3",
        "diaChiLapD": "",
        "longitude": 105.82531069237224,
        "latitude": 21.025492307349392,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4680,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82542602735906,
          21.0251643314689
        ]
      },
      "properties": {
        "tenDoiTuon": "22-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 127/38  Hào Nam",
        "tuDien": "LB3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Long Biên 3",
        "diaChiLapD": "",
        "longitude": 105.82542602735906,
        "latitude": 21.0251643314689,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4681,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8251443954114,
          21.025299527721074
        ]
      },
      "properties": {
        "tenDoiTuon": "22-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 127/38  Hào Nam",
        "tuDien": "LB3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Long Biên 3",
        "diaChiLapD": "",
        "longitude": 105.8251443954114,
        "latitude": 21.025299527721074,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4682,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8249485941564,
          21.025502321869006
        ]
      },
      "properties": {
        "tenDoiTuon": "22-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 127/38  Hào Nam",
        "tuDien": "LB3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Long Biên 3",
        "diaChiLapD": "",
        "longitude": 105.8249485941564,
        "latitude": 21.025502321869006,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4683,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82498882729048,
          21.02518436055154
        ]
      },
      "properties": {
        "tenDoiTuon": "22-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 127/38  Hào Nam",
        "tuDien": "LB3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Long Biên 3",
        "diaChiLapD": "",
        "longitude": 105.82498882729048,
        "latitude": 21.02518436055154,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4684,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82483594137733,
          21.025069193292175
        ]
      },
      "properties": {
        "tenDoiTuon": "22-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 127/38  Hào Nam",
        "tuDien": "LB3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Long Biên 3",
        "diaChiLapD": "",
        "longitude": 105.82483594137733,
        "latitude": 21.025069193292175,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4685,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82558695990441,
          21.02485388035323
        ]
      },
      "properties": {
        "tenDoiTuon": "23-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 127/56  Hào Nam",
        "tuDien": "LB3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Long Biên 3",
        "diaChiLapD": "",
        "longitude": 105.82558695990441,
        "latitude": 21.02485388035323,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4686,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82549308258854,
          21.024643574391003
        ]
      },
      "properties": {
        "tenDoiTuon": "24",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 127/56  Hào Nam",
        "tuDien": "LB3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Long Biên 3",
        "diaChiLapD": "",
        "longitude": 105.82549308258854,
        "latitude": 21.024643574391003,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4687,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80690034497772,
          21.017125254327468
        ]
      },
      "properties": {
        "tenDoiTuon": "10-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 107 Nguyễn Chí Thanh",
        "tuDien": "LT3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "LT3",
        "diaChiLapD": "",
        "longitude": 105.80690034497772,
        "latitude": 21.017125254327468,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4688,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80717527139704,
          21.016980035572338
        ]
      },
      "properties": {
        "tenDoiTuon": "10-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 107 Nguyễn Chí Thanh",
        "tuDien": "LT3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "LT3",
        "diaChiLapD": "",
        "longitude": 105.80717527139704,
        "latitude": 21.016980035572338,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4689,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80740728247454,
          21.01686361008871
        ]
      },
      "properties": {
        "tenDoiTuon": "LT3 10-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 107 Nguyễn Chí Thanh",
        "tuDien": "LT3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "LT3",
        "diaChiLapD": "",
        "longitude": 105.80740728247454,
        "latitude": 21.01686361008871,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4690,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82538177091334,
          21.024817577559116
        ]
      },
      "properties": {
        "tenDoiTuon": "24-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 127/56  Hào Nam",
        "tuDien": "LB3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Long Biên 3",
        "diaChiLapD": "",
        "longitude": 105.82538177091334,
        "latitude": 21.024817577559116,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4691,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82523424941569,
          21.02464107074632
        ]
      },
      "properties": {
        "tenDoiTuon": "24-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 127/56  Hào Nam",
        "tuDien": "LB3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Long Biên 3",
        "diaChiLapD": "",
        "longitude": 105.82523424941569,
        "latitude": 21.02464107074632,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4692,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82515244204359,
          21.024437023603497
        ]
      },
      "properties": {
        "tenDoiTuon": "24-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 127/56  Hào Nam",
        "tuDien": "LB3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Long Biên 3",
        "diaChiLapD": "",
        "longitude": 105.82515244204359,
        "latitude": 21.024437023603497,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4693,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8255172224672,
          21.02448459290119
        ]
      },
      "properties": {
        "tenDoiTuon": "25",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 127/66  Hào Nam",
        "tuDien": "LB3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Long Biên 3",
        "diaChiLapD": "",
        "longitude": 105.8255172224672,
        "latitude": 21.02448459290119,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4694,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82544882613384,
          21.02424674626096
        ]
      },
      "properties": {
        "tenDoiTuon": "26",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 127/66  Hào Nam",
        "tuDien": "LB3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Long Biên 3",
        "diaChiLapD": "",
        "longitude": 105.82544882613384,
        "latitude": 21.02424674626096,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4695,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82536299545018,
          21.024060224157274
        ]
      },
      "properties": {
        "tenDoiTuon": "27",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 127/66  Hào Nam",
        "tuDien": "LB3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Long Biên 3",
        "diaChiLapD": "",
        "longitude": 105.82536299545018,
        "latitude": 21.024060224157274,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4696,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82498480397888,
          21.02389373107458
        ]
      },
      "properties": {
        "tenDoiTuon": "29",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 127/66  Hào Nam",
        "tuDien": "LB3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Long Biên 3",
        "diaChiLapD": "",
        "longitude": 105.82498480397888,
        "latitude": 21.02389373107458,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4697,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82526107150207,
          21.023872449990584
        ]
      },
      "properties": {
        "tenDoiTuon": "28",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 127/66  Hào Nam",
        "tuDien": "LB3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Long Biên 3",
        "diaChiLapD": "",
        "longitude": 105.82526107150207,
        "latitude": 21.023872449990584,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4698,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82562048751016,
          21.024286804669256
        ]
      },
      "properties": {
        "tenDoiTuon": "26-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 127/66  Hào Nam",
        "tuDien": "LB3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Long Biên 3",
        "diaChiLapD": "",
        "longitude": 105.82562048751016,
        "latitude": 21.024286804669256,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4699,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82585115749276,
          21.024271782767098
        ]
      },
      "properties": {
        "tenDoiTuon": "26-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 127/66  Hào Nam",
        "tuDien": "LB3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Long Biên 3",
        "diaChiLapD": "",
        "longitude": 105.82585115749276,
        "latitude": 21.024271782767098,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4700,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82574252802533,
          21.026682778622025
        ]
      },
      "properties": {
        "tenDoiTuon": "4-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 8 Vũ Thạch",
        "tuDien": "LB3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Long Biên 3",
        "diaChiLapD": "",
        "longitude": 105.82574252802533,
        "latitude": 21.026682778622025,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4701,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82583372313346,
          21.02596674446435
        ]
      },
      "properties": {
        "tenDoiTuon": "4-9",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 8 Vũ Thạch",
        "tuDien": "LB3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Long Biên 3",
        "diaChiLapD": "",
        "longitude": 105.82583372313346,
        "latitude": 21.02596674446435,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4702,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82607512194703,
          21.02585658505814
        ]
      },
      "properties": {
        "tenDoiTuon": "4-10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 8 Vũ Thạch",
        "tuDien": "LB3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Long Biên 3",
        "diaChiLapD": "",
        "longitude": 105.82607512194703,
        "latitude": 21.02585658505814,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4703,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82558695990441,
          21.026069392928886
        ]
      },
      "properties": {
        "tenDoiTuon": "4-8",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 8 Vũ Thạch",
        "tuDien": "LB3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Long Biên 3",
        "diaChiLapD": "",
        "longitude": 105.82558695990441,
        "latitude": 21.026069392928886,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4704,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82618241030163,
          21.026059378447375
        ]
      },
      "properties": {
        "tenDoiTuon": "4-7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 8 Vũ Thạch",
        "tuDien": "LB3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Long Biên 3",
        "diaChiLapD": "",
        "longitude": 105.82618241030163,
        "latitude": 21.026059378447375,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4705,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82593564707257,
          21.026106947227323
        ]
      },
      "properties": {
        "tenDoiTuon": "4-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 8 Vũ Thạch",
        "tuDien": "LB3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Long Biên 3",
        "diaChiLapD": "",
        "longitude": 105.82593564707257,
        "latitude": 21.026106947227323,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4706,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82572643476989,
          21.02619457388775
        ]
      },
      "properties": {
        "tenDoiTuon": "4-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 8 Vũ Thạch",
        "tuDien": "LB3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Long Biên 3",
        "diaChiLapD": "",
        "longitude": 105.82572643476989,
        "latitude": 21.02619457388775,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4707,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82620655018926,
          21.02628470411293
        ]
      },
      "properties": {
        "tenDoiTuon": "4-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 8 Vũ Thạch",
        "tuDien": "LB3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Long Biên 3",
        "diaChiLapD": "",
        "longitude": 105.82620655018926,
        "latitude": 21.02628470411293,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4708,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82582567650124,
          21.02643492103379
        ]
      },
      "properties": {
        "tenDoiTuon": "4-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 8 Vũ Thạch",
        "tuDien": "LB3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Long Biên 3",
        "diaChiLapD": "",
        "longitude": 105.82582567650124,
        "latitude": 21.02643492103379,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4709,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82567279058809,
          21.02651503666275
        ]
      },
      "properties": {
        "tenDoiTuon": "4-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 8 Vũ Thạch",
        "tuDien": "LB3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Long Biên 3",
        "diaChiLapD": "",
        "longitude": 105.82567279058809,
        "latitude": 21.02651503666275,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4710,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82457710821345,
          21.02584782237454
        ]
      },
      "properties": {
        "tenDoiTuon": "2-9",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 2 Vũ Thạch",
        "tuDien": "LB3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Long Biên 3",
        "diaChiLapD": "",
        "longitude": 105.82457710821345,
        "latitude": 21.02584782237454,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4711,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82435180265533,
          21.025512336387948
        ]
      },
      "properties": {
        "tenDoiTuon": "2-11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 2 Vũ Thạch",
        "tuDien": "LB3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Long Biên 3",
        "diaChiLapD": "",
        "longitude": 105.82435180265533,
        "latitude": 21.025512336387948,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4712,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82467098552935,
          21.02570261211417
        ]
      },
      "properties": {
        "tenDoiTuon": "2-10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 2 Vũ Thạch",
        "tuDien": "LB3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Long Biên 3",
        "diaChiLapD": "",
        "longitude": 105.82467098552935,
        "latitude": 21.02570261211417,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4713,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82398702223173,
          21.025685086728384
        ]
      },
      "properties": {
        "tenDoiTuon": "2-8",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 2 Vũ Thạch",
        "tuDien": "LB3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Long Biên 3",
        "diaChiLapD": "",
        "longitude": 105.82398702223173,
        "latitude": 21.025685086728384,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4714,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82418282348674,
          21.02584281512643
        ]
      },
      "properties": {
        "tenDoiTuon": "2-7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 2 Vũ Thạch",
        "tuDien": "LB3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Long Biên 3",
        "diaChiLapD": "",
        "longitude": 105.82418282348674,
        "latitude": 21.02584281512643,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4715,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82447518427433,
          21.026060630257806
        ]
      },
      "properties": {
        "tenDoiTuon": "2-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 2 Vũ Thạch",
        "tuDien": "LB3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Long Biên 3",
        "diaChiLapD": "",
        "longitude": 105.82447518427433,
        "latitude": 21.026060630257806,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4716,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82394410688988,
          21.026078155599443
        ]
      },
      "properties": {
        "tenDoiTuon": "2-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 2 Vũ Thạch",
        "tuDien": "LB3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Long Biên 3",
        "diaChiLapD": "",
        "longitude": 105.82394410688988,
        "latitude": 21.026078155599443,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4717,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82421769220534,
          21.026265926986863
        ]
      },
      "properties": {
        "tenDoiTuon": "2-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 2 Vũ Thạch",
        "tuDien": "LB3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Long Biên 3",
        "diaChiLapD": "",
        "longitude": 105.82421769220534,
        "latitude": 21.026265926986863,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4718,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82450737077622,
          21.026526302920157
        ]
      },
      "properties": {
        "tenDoiTuon": "2-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 2 Vũ Thạch",
        "tuDien": "LB3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Long Biên 3",
        "diaChiLapD": "",
        "longitude": 105.82450737077622,
        "latitude": 21.026526302920157,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4719,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8409219924946,
          21.016150889652895
        ]
      },
      "properties": {
        "tenDoiTuon": "CV3 18-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 202,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 226 Lê Duẩn",
        "tuDien": "CVLN3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "CV3",
        "diaChiLapD": "",
        "longitude": 105.8409219924946,
        "latitude": 21.016150889652895,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4720,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84078385483433,
          21.016024408892903
        ]
      },
      "properties": {
        "tenDoiTuon": "18-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Lê Duẩn",
        "tuDien": "CVLN3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "CV3",
        "diaChiLapD": "",
        "longitude": 105.84078385483433,
        "latitude": 21.016024408892903,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4721,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84066181822685,
          21.016000662370296
        ]
      },
      "properties": {
        "tenDoiTuon": "18-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 226 Lê Duẩn",
        "tuDien": "CVLN3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "CV3",
        "diaChiLapD": "",
        "longitude": 105.84066181822685,
        "latitude": 21.016000662370296,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4722,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84075971494667,
          21.015834120815207
        ]
      },
      "properties": {
        "tenDoiTuon": "CV3 18-8",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 226 Lê Duẩn",
        "tuDien": "CVLN3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "CV3",
        "diaChiLapD": "",
        "longitude": 105.84075971494667,
        "latitude": 21.015834120815207,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4723,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84068461309397,
          21.01579406013597
        ]
      },
      "properties": {
        "tenDoiTuon": "18-9",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 226 Lê Duẩn",
        "tuDien": "CVLN3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "CV3",
        "diaChiLapD": "",
        "longitude": 105.84068461309397,
        "latitude": 21.01579406013597,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4724,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84060146461805,
          21.015781541171634
        ]
      },
      "properties": {
        "tenDoiTuon": "18-10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 226 Lê Duẩn",
        "tuDien": "CVLN3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "CV3",
        "diaChiLapD": "",
        "longitude": 105.84060146461805,
        "latitude": 21.015781541171634,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4725,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84045394312035,
          21.01597683689604
        ]
      },
      "properties": {
        "tenDoiTuon": "18-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 202,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 226 Lê Duẩn",
        "tuDien": "CVLN3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "CV3",
        "diaChiLapD": "",
        "longitude": 105.84045394312035,
        "latitude": 21.01597683689604,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4726,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84047271858356,
          21.01563632110806
        ]
      },
      "properties": {
        "tenDoiTuon": "18-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 226 Lê Duẩn",
        "tuDien": "CVLN3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "CV3",
        "diaChiLapD": "",
        "longitude": 105.84047271858356,
        "latitude": 21.01563632110806,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4727,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84064706216762,
          21.01564132869896
        ]
      },
      "properties": {
        "tenDoiTuon": "18-7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 226 Lê Duẩn",
        "tuDien": "CVLN3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "CV3",
        "diaChiLapD": "",
        "longitude": 105.84064706216762,
        "latitude": 21.01564132869896,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4728,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84049954067892,
          21.016142086925388
        ]
      },
      "properties": {
        "tenDoiTuon": "18-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 202,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 226 Lê Duẩn",
        "tuDien": "CVLN3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "CV3",
        "diaChiLapD": "",
        "longitude": 105.84049954067892,
        "latitude": 21.016142086925388,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4729,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82395617682474,
          21.026428661998068
        ]
      },
      "properties": {
        "tenDoiTuon": "2-16",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 2 Vũ Thạch",
        "tuDien": "LB3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Long Biên 3",
        "diaChiLapD": "",
        "longitude": 105.82395617682474,
        "latitude": 21.026428661998068,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4730,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82424585539562,
          21.026633958221062
        ]
      },
      "properties": {
        "tenDoiTuon": "2-15",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 2 Vũ Thạch",
        "tuDien": "LB3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Long Biên 3",
        "diaChiLapD": "",
        "longitude": 105.82424585539562,
        "latitude": 21.026633958221062,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4731,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82441751677194,
          21.02678292298557
        ]
      },
      "properties": {
        "tenDoiTuon": "2-14",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 2 Vũ Thạch",
        "tuDien": "LB3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Long Biên 3",
        "diaChiLapD": "",
        "longitude": 105.82441751677194,
        "latitude": 21.02678292298557,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4732,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8245569916464,
          21.026908103345193
        ]
      },
      "properties": {
        "tenDoiTuon": "2-13",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 2 Vũ Thạch",
        "tuDien": "LB3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Long Biên 3",
        "diaChiLapD": "",
        "longitude": 105.8245569916464,
        "latitude": 21.026908103345193,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4733,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82475547510914,
          21.02709337008443
        ]
      },
      "properties": {
        "tenDoiTuon": "2-12",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 2 Vũ Thạch",
        "tuDien": "LB3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Long Biên 3",
        "diaChiLapD": "",
        "longitude": 105.82475547510914,
        "latitude": 21.02709337008443,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4734,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82547698933308,
          21.02760160063178
        ]
      },
      "properties": {
        "tenDoiTuon": "1-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 3 Vũ Thạch",
        "tuDien": "LB3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Long Biên 3",
        "diaChiLapD": "",
        "longitude": 105.82547698933308,
        "latitude": 21.02760160063178,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4735,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82578007895167,
          21.027815657806798
        ]
      },
      "properties": {
        "tenDoiTuon": "1-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 3 Vũ Thạch",
        "tuDien": "LB3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Long Biên 3",
        "diaChiLapD": "",
        "longitude": 105.82578007895167,
        "latitude": 21.027815657806798,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4736,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82536031324243,
          21.02726111139287
        ]
      },
      "properties": {
        "tenDoiTuon": "1-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 3 Vũ Thạch",
        "tuDien": "LB3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Long Biên 3",
        "diaChiLapD": "",
        "longitude": 105.82536031324243,
        "latitude": 21.02726111139287,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4737,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82567949611644,
          21.027079600266966
        ]
      },
      "properties": {
        "tenDoiTuon": "5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 29 Vũ Thạch",
        "tuDien": "LB3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Long Biên 3",
        "diaChiLapD": "",
        "longitude": 105.82567949611644,
        "latitude": 21.027079600266966,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4738,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82603354770906,
          21.027367514363576
        ]
      },
      "properties": {
        "tenDoiTuon": "6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 29 Vũ Thạch",
        "tuDien": "LB3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Long Biên 3",
        "diaChiLapD": "",
        "longitude": 105.82603354770906,
        "latitude": 21.027367514363576,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4739,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82647745329697,
          21.02698070790545
        ]
      },
      "properties": {
        "tenDoiTuon": "6-4-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 29 Vũ Thạch",
        "tuDien": "LB3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Long Biên 3",
        "diaChiLapD": "",
        "longitude": 105.82647745329697,
        "latitude": 21.02698070790545,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4740,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82631652076059,
          21.02708210386986
        ]
      },
      "properties": {
        "tenDoiTuon": "6-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 29 Vũ Thạch",
        "tuDien": "LB3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Long Biên 3",
        "diaChiLapD": "",
        "longitude": 105.82631652076059,
        "latitude": 21.02708210386986,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4741,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82660351712374,
          21.027013254765492
        ]
      },
      "properties": {
        "tenDoiTuon": "6-4-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 29 Vũ Thạch",
        "tuDien": "LB3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Long Biên 3",
        "diaChiLapD": "",
        "longitude": 105.82660351712374,
        "latitude": 21.027013254765492,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4742,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82678456622716,
          21.027068334051368
        ]
      },
      "properties": {
        "tenDoiTuon": "6-4-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 29 Vũ Thạch",
        "tuDien": "LB3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Long Biên 3",
        "diaChiLapD": "",
        "longitude": 105.82678456622716,
        "latitude": 21.027068334051368,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4743,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8727459424898,
          21.027367514363576
        ]
      },
      "properties": {
        "tenDoiTuon": "6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 4,
        "maXaPhuong": 139,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 29 Vũ Thạch",
        "tuDien": "LB3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Long Biên 3",
        "diaChiLapD": "",
        "longitude": 105.8727459424898,
        "latitude": 21.027367514363576,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4744,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.87310526860348,
          21.027367514363576
        ]
      },
      "properties": {
        "tenDoiTuon": "6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 4,
        "maXaPhuong": 139,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 29 Vũ Thạch",
        "tuDien": "LB3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Long Biên 3",
        "diaChiLapD": "",
        "longitude": 105.87310526860348,
        "latitude": 21.027367514363576,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4745,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.87346459471718,
          21.027367514363576
        ]
      },
      "properties": {
        "tenDoiTuon": "6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 4,
        "maXaPhuong": 139,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 29 Vũ Thạch",
        "tuDien": "LB3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Long Biên 3",
        "diaChiLapD": "",
        "longitude": 105.87346459471718,
        "latitude": 21.027367514363576,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4746,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.87382392083087,
          21.027367514363576
        ]
      },
      "properties": {
        "tenDoiTuon": "6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 4,
        "maXaPhuong": 139,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 29 Vũ Thạch",
        "tuDien": "LB3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Long Biên 3",
        "diaChiLapD": "",
        "longitude": 105.87382392083087,
        "latitude": 21.027367514363576,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4747,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82617838699001,
          21.027248593391366
        ]
      },
      "properties": {
        "tenDoiTuon": "6-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 29 Vũ Thạch",
        "tuDien": "LB3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Long Biên 3",
        "diaChiLapD": "",
        "longitude": 105.82617838699001,
        "latitude": 21.027248593391366,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4748,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82611937839273,
          21.027438866901207
        ]
      },
      "properties": {
        "tenDoiTuon": "6-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 29 Vũ Thạch",
        "tuDien": "LB3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Long Biên 3",
        "diaChiLapD": "",
        "longitude": 105.82611937839273,
        "latitude": 21.027438866901207,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4749,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8263299317993,
          21.027585327266884
        ]
      },
      "properties": {
        "tenDoiTuon": "6-7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 29 Vũ Thạch",
        "tuDien": "LB3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Long Biên 3",
        "diaChiLapD": "",
        "longitude": 105.8263299317993,
        "latitude": 21.027585327266884,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4750,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82646404224928,
          21.028042232611135
        ]
      },
      "properties": {
        "tenDoiTuon": "6-9",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 29 Vũ Thạch",
        "tuDien": "LB3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Long Biên 3",
        "diaChiLapD": "",
        "longitude": 105.82646404224928,
        "latitude": 21.028042232611135,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4751,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8266491146733,
          21.028054750545977
        ]
      },
      "properties": {
        "tenDoiTuon": "6-10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 29 Vũ Thạch",
        "tuDien": "LB3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Long Biên 3",
        "diaChiLapD": "",
        "longitude": 105.8266491146733,
        "latitude": 21.028054750545977,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4752,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82704876381553,
          21.028050995165728
        ]
      },
      "properties": {
        "tenDoiTuon": "6-11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 29 Vũ Thạch",
        "tuDien": "LB3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Long Biên 3",
        "diaChiLapD": "",
        "longitude": 105.82704876381553,
        "latitude": 21.028050995165728,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4753,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82693476993258,
          21.027958362419632
        ]
      },
      "properties": {
        "tenDoiTuon": "6-12",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 29 Vũ Thạch",
        "tuDien": "LB3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Long Biên 3",
        "diaChiLapD": "",
        "longitude": 105.82693476993258,
        "latitude": 21.027958362419632,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4754,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82685966807986,
          21.027885758335618
        ]
      },
      "properties": {
        "tenDoiTuon": "6-13",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 29 Vũ Thạch",
        "tuDien": "LB3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Long Biên 3",
        "diaChiLapD": "",
        "longitude": 105.82685966807986,
        "latitude": 21.027885758335618,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4755,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82609389741022,
          21.027804391647674
        ]
      },
      "properties": {
        "tenDoiTuon": "6-8",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 29 Vũ Thạch",
        "tuDien": "LB3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Long Biên 3",
        "diaChiLapD": "",
        "longitude": 105.82609389741022,
        "latitude": 21.027804391647674,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4756,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82594101149705,
          21.027497701424064
        ]
      },
      "properties": {
        "tenDoiTuon": "6-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 29 Vũ Thạch",
        "tuDien": "LB3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Long Biên 3",
        "diaChiLapD": "",
        "longitude": 105.82594101149705,
        "latitude": 21.027497701424064,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4757,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82636882382951,
          21.027492694232222
        ]
      },
      "properties": {
        "tenDoiTuon": "6-2-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 29 Vũ Thạch",
        "tuDien": "LB3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Long Biên 3",
        "diaChiLapD": "",
        "longitude": 105.82636882382951,
        "latitude": 21.027492694232222,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4758,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82648281771245,
          21.027485183442455
        ]
      },
      "properties": {
        "tenDoiTuon": "6-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 29 Vũ Thạch",
        "tuDien": "LB3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Long Biên 3",
        "diaChiLapD": "",
        "longitude": 105.82648281771245,
        "latitude": 21.027485183442455,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4759,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82625617105046,
          21.027377528756375
        ]
      },
      "properties": {
        "tenDoiTuon": "6-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 29 Vũ Thạch",
        "tuDien": "LB3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Long Biên 3",
        "diaChiLapD": "",
        "longitude": 105.82625617105046,
        "latitude": 21.027377528756375,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4760,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80681417898889,
          21.016919632038704
        ]
      },
      "properties": {
        "tenDoiTuon": "27",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Trúc Khê",
        "tuDien": "BX",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "BX T",
        "diaChiLapD": "",
        "longitude": 105.80681417898889,
        "latitude": 21.016919632038704,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4761,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80706630663342,
          21.016791939515723
        ]
      },
      "properties": {
        "tenDoiTuon": "26",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Trúc Khê",
        "tuDien": "BX",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "BX T",
        "diaChiLapD": "",
        "longitude": 105.80706630663342,
        "latitude": 21.016791939515723,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4762,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80729295329542,
          21.016673010107613
        ]
      },
      "properties": {
        "tenDoiTuon": "25",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Trúc Khê",
        "tuDien": "BX",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "BX T",
        "diaChiLapD": "",
        "longitude": 105.80729295329542,
        "latitude": 21.016673010107613,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4763,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80745925025627,
          21.016587881630898
        ]
      },
      "properties": {
        "tenDoiTuon": "24",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Trúc Khê",
        "tuDien": "BX",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "BX T",
        "diaChiLapD": "",
        "longitude": 105.80745925025627,
        "latitude": 21.016587881630898,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4764,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80769394355048,
          21.016468952060055
        ]
      },
      "properties": {
        "tenDoiTuon": "23",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Trúc Khê",
        "tuDien": "BX",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "BX T",
        "diaChiLapD": "",
        "longitude": 105.80769394355048,
        "latitude": 21.016468952060055,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4765,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80794204788339,
          21.016319976989227
        ]
      },
      "properties": {
        "tenDoiTuon": "22",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Trúc Khê",
        "tuDien": "BX",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "BX T",
        "diaChiLapD": "",
        "longitude": 105.80794204788339,
        "latitude": 21.016319976989227,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4766,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80823977307749,
          21.0161184222456
        ]
      },
      "properties": {
        "tenDoiTuon": "21",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Trúc Khê",
        "tuDien": "BX",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "BX T",
        "diaChiLapD": "",
        "longitude": 105.80823977307749,
        "latitude": 21.0161184222456,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4767,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80849055962713,
          21.01597946198472
        ]
      },
      "properties": {
        "tenDoiTuon": "20",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Trúc Khê",
        "tuDien": "BX",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "BX T",
        "diaChiLapD": "",
        "longitude": 105.80849055962713,
        "latitude": 21.01597946198472,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4768,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80875073389488,
          21.015840501593516
        ]
      },
      "properties": {
        "tenDoiTuon": "19",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Trúc Khê",
        "tuDien": "BX",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "BX T",
        "diaChiLapD": "",
        "longitude": 105.80875073389488,
        "latitude": 21.015840501593516,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4769,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80902700142707,
          21.0156927777924
        ]
      },
      "properties": {
        "tenDoiTuon": "18",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Trúc Khê",
        "tuDien": "BX",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "BX T",
        "diaChiLapD": "",
        "longitude": 105.80902700142707,
        "latitude": 21.0156927777924,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4770,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80930997447858,
          21.01556132852492
        ]
      },
      "properties": {
        "tenDoiTuon": "17",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Trúc Khê",
        "tuDien": "BX",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "BX T",
        "diaChiLapD": "",
        "longitude": 105.80930997447858,
        "latitude": 21.01556132852492,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4771,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8093582542449,
          21.01546618422059
        ]
      },
      "properties": {
        "tenDoiTuon": "BX 16",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Trúc Khê",
        "tuDien": "BX",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "BX T",
        "diaChiLapD": "",
        "longitude": 105.8093582542449,
        "latitude": 21.01546618422059,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4772,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80921475605885,
          21.01569653348312
        ]
      },
      "properties": {
        "tenDoiTuon": "15",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Trúc Khê",
        "tuDien": "BX",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 10,
        "tuDien2": "BX T",
        "diaChiLapD": "",
        "longitude": 105.80921475605885,
        "latitude": 21.01569653348312,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4773,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82753826696198,
          21.02920639605962
        ]
      },
      "properties": {
        "tenDoiTuon": "21",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hào Nam",
        "tuDien": "CL3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Cát Linh 3",
        "diaChiLapD": "",
        "longitude": 105.82753826696198,
        "latitude": 21.02920639605962,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4774,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82756106574577,
          21.028933506949993
        ]
      },
      "properties": {
        "tenDoiTuon": "22",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hào Nam",
        "tuDien": "CL3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Cát Linh 3",
        "diaChiLapD": "",
        "longitude": 105.82756106574577,
        "latitude": 21.028933506949993,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4775,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8275825234167,
          21.02863307799326
        ]
      },
      "properties": {
        "tenDoiTuon": "23",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hào Nam",
        "tuDien": "CL3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Cát Linh 3",
        "diaChiLapD": "",
        "longitude": 105.8275825234167,
        "latitude": 21.02863307799326,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4776,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82759057003992,
          21.02838021648539
        ]
      },
      "properties": {
        "tenDoiTuon": "24",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hào Nam",
        "tuDien": "CL3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Cát Linh 3",
        "diaChiLapD": "",
        "longitude": 105.82759057003992,
        "latitude": 21.02838021648539,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4777,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82760666329534,
          21.02810231868913
        ]
      },
      "properties": {
        "tenDoiTuon": "25",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hào Nam",
        "tuDien": "CL3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Cát Linh 3",
        "diaChiLapD": "",
        "longitude": 105.82760666329534,
        "latitude": 21.02810231868913,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4778,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82763884980623,
          21.027709255156847
        ]
      },
      "properties": {
        "tenDoiTuon": "26",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hào Nam",
        "tuDien": "CL3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Cát Linh 3",
        "diaChiLapD": "",
        "longitude": 105.82763884980623,
        "latitude": 21.027709255156847,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4779,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82767620913604,
          21.027369481475922
        ]
      },
      "properties": {
        "tenDoiTuon": "27",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hào Nam",
        "tuDien": "CL3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Cát Linh 3",
        "diaChiLapD": "",
        "longitude": 105.82767620913604,
        "latitude": 21.027369481475922,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4780,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82769919949838,
          21.027063326844235
        ]
      },
      "properties": {
        "tenDoiTuon": "28",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hoàng Cầu",
        "tuDien": "CL3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Cát Linh 3",
        "diaChiLapD": "",
        "longitude": 105.82769919949838,
        "latitude": 21.027063326844235,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4781,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82739476878493,
          21.029151317564725
        ]
      },
      "properties": {
        "tenDoiTuon": "35",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hào Nam",
        "tuDien": "CL3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Cát Linh 3",
        "diaChiLapD": "",
        "longitude": 105.82739476878493,
        "latitude": 21.029151317564725,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4782,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82741622645582,
          21.028883435499537
        ]
      },
      "properties": {
        "tenDoiTuon": "34",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hào Nam",
        "tuDien": "CL3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Cát Linh 3",
        "diaChiLapD": "",
        "longitude": 105.82741622645582,
        "latitude": 21.028883435499537,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4783,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82744036634347,
          21.02858801359709
        ]
      },
      "properties": {
        "tenDoiTuon": "33",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hào Nam",
        "tuDien": "CL3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Cát Linh 3",
        "diaChiLapD": "",
        "longitude": 105.82744036634347,
        "latitude": 21.02858801359709,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4784,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82745914180666,
          21.028345166341378
        ]
      },
      "properties": {
        "tenDoiTuon": "32",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hào Nam",
        "tuDien": "CL3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Cát Linh 3",
        "diaChiLapD": "",
        "longitude": 105.82745914180666,
        "latitude": 21.028345166341378,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4785,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8274886461008,
          21.028049743372836
        ]
      },
      "properties": {
        "tenDoiTuon": "31",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hào Nam",
        "tuDien": "CL3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Cát Linh 3",
        "diaChiLapD": "",
        "longitude": 105.8274886461008,
        "latitude": 21.028049743372836,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4786,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82751278597945,
          21.027694233600425
        ]
      },
      "properties": {
        "tenDoiTuon": "30",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hào Nam",
        "tuDien": "CL3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Cát Linh 3",
        "diaChiLapD": "",
        "longitude": 105.82751278597945,
        "latitude": 21.027694233600425,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4787,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82753156144264,
          21.027373773359894
        ]
      },
      "properties": {
        "tenDoiTuon": "29",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hào Nam",
        "tuDien": "CL3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Cát Linh 3",
        "diaChiLapD": "",
        "longitude": 105.82753156144264,
        "latitude": 21.027373773359894,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4788,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84119353847723,
          21.014574714996392
        ]
      },
      "properties": {
        "tenDoiTuon": "CV3 26-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 304 Lê Duẩn",
        "tuDien": "CVLN3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "CV3",
        "diaChiLapD": "",
        "longitude": 105.84119353847723,
        "latitude": 21.014574714996392,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4789,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84107553204237,
          21.014571827944614
        ]
      },
      "properties": {
        "tenDoiTuon": "26-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 304 Lê Duẩn",
        "tuDien": "CVLN3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "CV3",
        "diaChiLapD": "",
        "longitude": 105.84107553204237,
        "latitude": 21.014571827944614,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4790,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84098866594907,
          21.014569702747682
        ]
      },
      "properties": {
        "tenDoiTuon": "26-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 304 Lê Duẩn",
        "tuDien": "CVLN3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "CV3",
        "diaChiLapD": "",
        "longitude": 105.84098866594907,
        "latitude": 21.014569702747682,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4791,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84089747084093,
          21.01459348897201
        ]
      },
      "properties": {
        "tenDoiTuon": "26-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 304 Lê Duẩn",
        "tuDien": "CVLN3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "CV3",
        "diaChiLapD": "",
        "longitude": 105.84089747084093,
        "latitude": 21.01459348897201,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4792,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84075602750387,
          21.01461180050838
        ]
      },
      "properties": {
        "tenDoiTuon": "26-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 304 Lê Duẩn",
        "tuDien": "CVLN3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "CV3",
        "diaChiLapD": "",
        "longitude": 105.84075602750387,
        "latitude": 21.01461180050838,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4793,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84071461981824,
          21.014592388208523
        ]
      },
      "properties": {
        "tenDoiTuon": "26-7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 304 Lê Duẩn",
        "tuDien": "CVLN3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "CV3",
        "diaChiLapD": "",
        "longitude": 105.84071461981824,
        "latitude": 21.014592388208523,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4794,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84070806218614,
          21.014349367010997
        ]
      },
      "properties": {
        "tenDoiTuon": "26-8",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 304 Lê Duẩn",
        "tuDien": "CVLN3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "CV3",
        "diaChiLapD": "",
        "longitude": 105.84070806218614,
        "latitude": 21.014349367010997,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4795,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84087064875453,
          21.014244206660067
        ]
      },
      "properties": {
        "tenDoiTuon": "CV3 26-9",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 304 Lê Duẩn",
        "tuDien": "CVLN3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "CV3",
        "diaChiLapD": "",
        "longitude": 105.84087064875453,
        "latitude": 21.014244206660067,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4796,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84081724566315,
          21.01460387508373
        ]
      },
      "properties": {
        "tenDoiTuon": "26-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 304 Lê Duẩn",
        "tuDien": "CVLN3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "CV3",
        "diaChiLapD": "",
        "longitude": 105.84081724566315,
        "latitude": 21.01460387508373,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4797,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82806934434643,
          21.027028276390563
        ]
      },
      "properties": {
        "tenDoiTuon": "1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "An Trạch",
        "tuDien": "CL3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Cát Linh 3",
        "diaChiLapD": "",
        "longitude": 105.82806934434643,
        "latitude": 21.027028276390563,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4798,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82848240452735,
          21.02720978757728
        ]
      },
      "properties": {
        "tenDoiTuon": "2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "An Trạch",
        "tuDien": "CL3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Cát Linh 3",
        "diaChiLapD": "",
        "longitude": 105.82848240452735,
        "latitude": 21.02720978757728,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4799,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.828825727289,
          21.02734623377384
        ]
      },
      "properties": {
        "tenDoiTuon": "3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "An Trạch",
        "tuDien": "CL3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Cát Linh 3",
        "diaChiLapD": "",
        "longitude": 105.828825727289,
        "latitude": 21.02734623377384,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4800,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82914491016302,
          21.027601600630952
        ]
      },
      "properties": {
        "tenDoiTuon": "4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "An Trạch",
        "tuDien": "CL3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Cát Linh 3",
        "diaChiLapD": "",
        "longitude": 105.82914491016302,
        "latitude": 21.027601600630952,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4801,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82943995315837,
          21.027731787486992
        ]
      },
      "properties": {
        "tenDoiTuon": "5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "An Trạch",
        "tuDien": "CL3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Cát Linh 3",
        "diaChiLapD": "",
        "longitude": 105.82943995315837,
        "latitude": 21.027731787486992,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4802,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82976718265562,
          21.027731787486992
        ]
      },
      "properties": {
        "tenDoiTuon": "6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "An Trạch",
        "tuDien": "CL3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Cát Linh 3",
        "diaChiLapD": "",
        "longitude": 105.82976718265562,
        "latitude": 21.027731787486992,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4803,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83013464528693,
          21.027669197667436
        ]
      },
      "properties": {
        "tenDoiTuon": "7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "An Trạch",
        "tuDien": "CL3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Cát Linh 3",
        "diaChiLapD": "",
        "longitude": 105.83013464528693,
        "latitude": 21.027669197667436,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4804,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83041359502685,
          21.027624132979852
        ]
      },
      "properties": {
        "tenDoiTuon": "8",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "An Trạch",
        "tuDien": "CL3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Cát Linh 3",
        "diaChiLapD": "",
        "longitude": 105.83041359502685,
        "latitude": 21.027624132979852,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4805,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83077569324271,
          21.02756905389854
        ]
      },
      "properties": {
        "tenDoiTuon": "9",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "An Trạch",
        "tuDien": "CL3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Cát Linh 3",
        "diaChiLapD": "",
        "longitude": 105.83077569324271,
        "latitude": 21.02756905389854,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4806,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83108951170124,
          21.027523989181493
        ]
      },
      "properties": {
        "tenDoiTuon": "10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "An Trạch",
        "tuDien": "CL3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Cát Linh 3",
        "diaChiLapD": "",
        "longitude": 105.83108951170124,
        "latitude": 21.027523989181493,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4807,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83145160991708,
          21.027634147355403
        ]
      },
      "properties": {
        "tenDoiTuon": "11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "An Trạch",
        "tuDien": "CL3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Cát Linh 3",
        "diaChiLapD": "",
        "longitude": 105.83145160991708,
        "latitude": 21.027634147355403,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4808,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83151866513758,
          21.02792706755905
        ]
      },
      "properties": {
        "tenDoiTuon": "12",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "An Trạch",
        "tuDien": "CL3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Cát Linh 3",
        "diaChiLapD": "",
        "longitude": 105.83151866513758,
        "latitude": 21.02792706755905,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4809,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83160717803798,
          21.028240015858223
        ]
      },
      "properties": {
        "tenDoiTuon": "13",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "An Trạch",
        "tuDien": "CL3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Cát Linh 3",
        "diaChiLapD": "",
        "longitude": 105.83160717803798,
        "latitude": 21.028240015858223,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4810,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83174128848796,
          21.028585510016985
        ]
      },
      "properties": {
        "tenDoiTuon": "14",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "An Trạch",
        "tuDien": "CL3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Cát Linh 3",
        "diaChiLapD": "",
        "longitude": 105.83174128848796,
        "latitude": 21.028585510016985,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4811,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80816265958245,
          21.01657598867783
        ]
      },
      "properties": {
        "tenDoiTuon": "22-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 92/62 Nguyên Hồng",
        "tuDien": "BX",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 7,
        "tuDien2": "BX T",
        "diaChiLapD": "",
        "longitude": 105.80816265958245,
        "latitude": 21.01657598867783,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4812,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80812108534448,
          21.016718704049545
        ]
      },
      "properties": {
        "tenDoiTuon": "22-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 92/62 Nguyên Hồng",
        "tuDien": "BX",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "BX T",
        "diaChiLapD": "",
        "longitude": 105.80812108534448,
        "latitude": 21.016718704049545,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4813,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80791455524954,
          21.016823862657354
        ]
      },
      "properties": {
        "tenDoiTuon": "BX 22-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 92/62 Nguyên Hồng",
        "tuDien": "BX",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "BX T",
        "diaChiLapD": "",
        "longitude": 105.80791455524954,
        "latitude": 21.016823862657354,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4814,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80764767545345,
          21.016934028737676
        ]
      },
      "properties": {
        "tenDoiTuon": "22-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 92/62 Nguyên Hồng",
        "tuDien": "BX",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "BX T",
        "diaChiLapD": "",
        "longitude": 105.80764767545345,
        "latitude": 21.016934028737676,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4815,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8076007368,
          21.016797573012354
        ]
      },
      "properties": {
        "tenDoiTuon": "22-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 92/62 Nguyên Hồng",
        "tuDien": "BX",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 7,
        "tuDien2": "BX T",
        "diaChiLapD": "",
        "longitude": 105.8076007368,
        "latitude": 21.016797573012354,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4816,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80778044479956,
          21.016780046579775
        ]
      },
      "properties": {
        "tenDoiTuon": "22-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 92/62 Nguyên Hồng",
        "tuDien": "BX",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 7,
        "tuDien2": "BX T",
        "diaChiLapD": "",
        "longitude": 105.80778044479956,
        "latitude": 21.016780046579775,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4817,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80785688775615,
          21.017309594309463
        ]
      },
      "properties": {
        "tenDoiTuon": "BX 22-7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 92/62 Nguyên Hồng",
        "tuDien": "BX",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "BX T",
        "diaChiLapD": "",
        "longitude": 105.80785688775615,
        "latitude": 21.017309594309463,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4818,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82758118230383,
          21.0292639781004
        ]
      },
      "properties": {
        "tenDoiTuon": "20",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hào Nam",
        "tuDien": "CL3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Cát Linh 3",
        "diaChiLapD": "",
        "longitude": 105.82758118230383,
        "latitude": 21.0292639781004,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4819,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80992487091336,
          21.017289564169776
        ]
      },
      "properties": {
        "tenDoiTuon": "05",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 49 Huỳnh Thúc Kháng",
        "tuDien": "BX",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 7,
        "tuDien2": "BX T",
        "diaChiLapD": "",
        "longitude": 105.80992487091336,
        "latitude": 21.017289564169776,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4820,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8098122181343,
          21.017081751309266
        ]
      },
      "properties": {
        "tenDoiTuon": "06",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 49 Huỳnh Thúc Kháng",
        "tuDien": "BX",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 7,
        "tuDien2": "BX T",
        "diaChiLapD": "",
        "longitude": 105.8098122181343,
        "latitude": 21.017081751309266,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4821,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80965396779668,
          21.016795069235908
        ]
      },
      "properties": {
        "tenDoiTuon": "07",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 49 Huỳnh Thúc Kháng",
        "tuDien": "BX",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 7,
        "tuDien2": "BX T",
        "diaChiLapD": "",
        "longitude": 105.80965396779668,
        "latitude": 21.016795069235908,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4822,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80956947821687,
          21.016647346381173
        ]
      },
      "properties": {
        "tenDoiTuon": "11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 49 Huỳnh Thúc Kháng",
        "tuDien": "BX",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 7,
        "tuDien2": "BX T",
        "diaChiLapD": "",
        "longitude": 105.80956947821687,
        "latitude": 21.016647346381173,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4823,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80929321068469,
          21.016244237506406
        ]
      },
      "properties": {
        "tenDoiTuon": "12",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 49 Huỳnh Thúc Kháng",
        "tuDien": "BX",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 7,
        "tuDien2": "BX T",
        "diaChiLapD": "",
        "longitude": 105.80929321068469,
        "latitude": 21.016244237506406,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4824,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80916848797081,
          21.016016392877713
        ]
      },
      "properties": {
        "tenDoiTuon": "13",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 49 Huỳnh Thúc Kháng",
        "tuDien": "BX",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 7,
        "tuDien2": "BX T",
        "diaChiLapD": "",
        "longitude": 105.80916848797081,
        "latitude": 21.016016392877713,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4825,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80903437751184,
          21.01579480738251
        ]
      },
      "properties": {
        "tenDoiTuon": "14",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 49 Huỳnh Thúc Kháng",
        "tuDien": "BX",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 7,
        "tuDien2": "BX T",
        "diaChiLapD": "",
        "longitude": 105.80903437751184,
        "latitude": 21.01579480738251,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4826,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84117660187884,
          21.01423537858578
        ]
      },
      "properties": {
        "tenDoiTuon": "30-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 324 Lê Duẩn",
        "tuDien": "CVLN3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "CV3",
        "diaChiLapD": "",
        "longitude": 105.84117660187884,
        "latitude": 21.01423537858578,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4827,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84115922902106,
          21.01409328073128
        ]
      },
      "properties": {
        "tenDoiTuon": "30-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 342 Lê Duẩn",
        "tuDien": "CVLN3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "CV3",
        "diaChiLapD": "",
        "longitude": 105.84115922902106,
        "latitude": 21.01409328073128,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4828,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82821016032476,
          21.027478924452495
        ]
      },
      "properties": {
        "tenDoiTuon": "15-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 35/75 Cát Linh",
        "tuDien": "CL3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Cát Linh 3",
        "diaChiLapD": "",
        "longitude": 105.82821016032476,
        "latitude": 21.027478924452495,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4829,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80911082045944,
          21.016226711008805
        ]
      },
      "properties": {
        "tenDoiTuon": "12-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 49 Huỳnh Thúc Kháng",
        "tuDien": "BX",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 7,
        "tuDien2": "BX T",
        "diaChiLapD": "",
        "longitude": 105.80911082045944,
        "latitude": 21.016226711008805,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4830,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80889087932577,
          21.01633437374693
        ]
      },
      "properties": {
        "tenDoiTuon": "12-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 49 Huỳnh Thúc Kháng",
        "tuDien": "BX",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 7,
        "tuDien2": "BX T",
        "diaChiLapD": "",
        "longitude": 105.80889087932577,
        "latitude": 21.01633437374693,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4831,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80864813940833,
          21.01640197589133
        ]
      },
      "properties": {
        "tenDoiTuon": "12-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 49 Huỳnh Thúc Kháng",
        "tuDien": "BX",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "BX T",
        "diaChiLapD": "",
        "longitude": 105.80864813940833,
        "latitude": 21.01640197589133,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4832,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80925968306097,
          21.0166072859212
        ]
      },
      "properties": {
        "tenDoiTuon": "11-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 49 Huỳnh Thúc Kháng",
        "tuDien": "BX",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "BX T",
        "diaChiLapD": "",
        "longitude": 105.80925968306097,
        "latitude": 21.0166072859212,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4833,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82844083029836,
          21.027561543115137
        ]
      },
      "properties": {
        "tenDoiTuon": "15-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 35/75 Cát Linh",
        "tuDien": "CL3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Cát Linh 3",
        "diaChiLapD": "",
        "longitude": 105.82844083029836,
        "latitude": 21.027561543115137,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4834,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82865272481777,
          21.027654176107834
        ]
      },
      "properties": {
        "tenDoiTuon": "15-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 35/75 Cát Linh",
        "tuDien": "CL3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Cát Linh 3",
        "diaChiLapD": "",
        "longitude": 105.82865272481777,
        "latitude": 21.027654176107834,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4835,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82787220198757,
          21.027511471203752
        ]
      },
      "properties": {
        "tenDoiTuon": "15-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 35/92 Cát Linh",
        "tuDien": "CL3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Cát Linh 3",
        "diaChiLapD": "",
        "longitude": 105.82787220198757,
        "latitude": 21.027511471203752,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4836,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82812969405656,
          21.02793457832826
        ]
      },
      "properties": {
        "tenDoiTuon": "16-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 35/64 Cát Linh",
        "tuDien": "CL3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Cát Linh 3",
        "diaChiLapD": "",
        "longitude": 105.82812969405656,
        "latitude": 21.02793457832826,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4837,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82789634187522,
          21.027994664449636
        ]
      },
      "properties": {
        "tenDoiTuon": "16-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 35/64 Cát Linh",
        "tuDien": "CL3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Cát Linh 3",
        "diaChiLapD": "",
        "longitude": 105.82789634187522,
        "latitude": 21.027994664449636,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4838,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82904566843165,
          21.028839622967023
        ]
      },
      "properties": {
        "tenDoiTuon": "19-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 33 Cát Linh",
        "tuDien": "CL3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Cát Linh 3",
        "diaChiLapD": "",
        "longitude": 105.82904566843165,
        "latitude": 21.028839622967023,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4839,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82763348539073,
          21.028074779240203
        ]
      },
      "properties": {
        "tenDoiTuon": "16-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 35/64 Cát Linh",
        "tuDien": "CL3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Cát Linh 3",
        "diaChiLapD": "",
        "longitude": 105.82763348539073,
        "latitude": 21.028074779240203,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4840,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82923878747891,
          21.028689408469727
        ]
      },
      "properties": {
        "tenDoiTuon": "19-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 33 Cát Linh",
        "tuDien": "CL3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Cát Linh 3",
        "diaChiLapD": "",
        "longitude": 105.82923878747891,
        "latitude": 21.028689408469727,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4841,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82821552474023,
          21.027897024489427
        ]
      },
      "properties": {
        "tenDoiTuon": "16-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 35/61 Cát Linh",
        "tuDien": "CL3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Cát Linh 3",
        "diaChiLapD": "",
        "longitude": 105.82821552474023,
        "latitude": 21.027897024489427,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4842,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.828341588567,
          21.027689226414335
        ]
      },
      "properties": {
        "tenDoiTuon": "16-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 35/71 Cát Linh",
        "tuDien": "CL3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Cát Linh 3",
        "diaChiLapD": "",
        "longitude": 105.828341588567,
        "latitude": 21.027689226414335,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4843,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82813237626432,
          21.027624132981533
        ]
      },
      "properties": {
        "tenDoiTuon": "16",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 35/71 Cát Linh",
        "tuDien": "CL3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Cát Linh 3",
        "diaChiLapD": "",
        "longitude": 105.82813237626432,
        "latitude": 21.027624132981533,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4844,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8107590379254,
          21.01691399854753
        ]
      },
      "properties": {
        "tenDoiTuon": "Bx 01",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 28 Nguyên Hồng",
        "tuDien": "BX",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 7,
        "tuDien2": "BX T",
        "diaChiLapD": "",
        "longitude": 105.8107590379254,
        "latitude": 21.01691399854753,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4845,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81052032131956,
          21.017022912675785
        ]
      },
      "properties": {
        "tenDoiTuon": "02",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 28 Nguyên Hồng",
        "tuDien": "BX",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 7,
        "tuDien2": "BX T",
        "diaChiLapD": "",
        "longitude": 105.81052032131956,
        "latitude": 21.017022912675785,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4846,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81031423825922,
          21.017119307871916
        ]
      },
      "properties": {
        "tenDoiTuon": "03",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 28 Nguyên Hồng",
        "tuDien": "BX",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 7,
        "tuDien2": "BX T",
        "diaChiLapD": "",
        "longitude": 105.81031423825922,
        "latitude": 21.017119307871916,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4847,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81010860223353,
          21.017219458659227
        ]
      },
      "properties": {
        "tenDoiTuon": "04",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 28 Nguyên Hồng",
        "tuDien": "BX",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 7,
        "tuDien2": "BX T",
        "diaChiLapD": "",
        "longitude": 105.81010860223353,
        "latitude": 21.017219458659227,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4848,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8282396646279,
          21.028230001525078
        ]
      },
      "properties": {
        "tenDoiTuon": "17",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 35/46 Cát Linh",
        "tuDien": "CL3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Cát Linh 3",
        "diaChiLapD": "",
        "longitude": 105.8282396646279,
        "latitude": 21.028230001525078,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4849,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82806800325153,
          21.028260044529222
        ]
      },
      "properties": {
        "tenDoiTuon": "17-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 35/46 Cát Linh",
        "tuDien": "CL3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Cát Linh 3",
        "diaChiLapD": "",
        "longitude": 105.82806800325153,
        "latitude": 21.028260044529222,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4850,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82795803268024,
          21.028310116189136
        ]
      },
      "properties": {
        "tenDoiTuon": "17-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 35/46 Cát Linh",
        "tuDien": "CL3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Cát Linh 3",
        "diaChiLapD": "",
        "longitude": 105.82795803268024,
        "latitude": 21.028310116189136,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4851,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82862053830694,
          21.028204965683766
        ]
      },
      "properties": {
        "tenDoiTuon": "17-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 35/45 Cát Linh",
        "tuDien": "CL3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Cát Linh 3",
        "diaChiLapD": "",
        "longitude": 105.82862053830694,
        "latitude": 21.028204965683766,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4852,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82829330880966,
          21.028575495707045
        ]
      },
      "properties": {
        "tenDoiTuon": "18-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 35/26 Cát Linh",
        "tuDien": "CL3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Cát Linh 3",
        "diaChiLapD": "",
        "longitude": 105.82829330880966,
        "latitude": 21.028575495707045,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4853,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82833622415151,
          21.028760760373665
        ]
      },
      "properties": {
        "tenDoiTuon": "18",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 35/16 Cát Linh",
        "tuDien": "CL3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Cát Linh 3",
        "diaChiLapD": "",
        "longitude": 105.82833622415151,
        "latitude": 21.028760760373665,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4854,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82846228797827,
          21.02857048855056
        ]
      },
      "properties": {
        "tenDoiTuon": "18-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 35/31 Cát Linh",
        "tuDien": "CL3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Cát Linh 3",
        "diaChiLapD": "",
        "longitude": 105.82846228797827,
        "latitude": 21.02857048855056,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4855,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82810018975341,
          21.028605538641585
        ]
      },
      "properties": {
        "tenDoiTuon": "18-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 35/26 Cát Linh",
        "tuDien": "CL3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Cát Linh 3",
        "diaChiLapD": "",
        "longitude": 105.82810018975341,
        "latitude": 21.028605538641585,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4856,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82809750754568,
          21.028838371179077
        ]
      },
      "properties": {
        "tenDoiTuon": "18-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 35/16 Cát Linh",
        "tuDien": "CL3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Cát Linh 3",
        "diaChiLapD": "",
        "longitude": 105.82809750754568,
        "latitude": 21.028838371179077,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4857,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82791243512168,
          21.028638085147644
        ]
      },
      "properties": {
        "tenDoiTuon": "18-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 35/26 Cát Linh",
        "tuDien": "CL3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Cát Linh 3",
        "diaChiLapD": "",
        "longitude": 105.82791243512168,
        "latitude": 21.028638085147644,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4858,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82838316280495,
          21.02903490158756
        ]
      },
      "properties": {
        "tenDoiTuon": "19-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 35/2 Cát Linh",
        "tuDien": "CL3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Cát Linh 3",
        "diaChiLapD": "",
        "longitude": 105.82838316280495,
        "latitude": 21.02903490158756,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4859,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82814981062363,
          21.02904741943903
        ]
      },
      "properties": {
        "tenDoiTuon": "19-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 35/2 Cát Linh",
        "tuDien": "CL3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Cát Linh 3",
        "diaChiLapD": "",
        "longitude": 105.82814981062363,
        "latitude": 21.02904741943903,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4860,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82844217140223,
          21.02926522988727
        ]
      },
      "properties": {
        "tenDoiTuon": "19",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 35Cát Linh",
        "tuDien": "CL3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Cát Linh 3",
        "diaChiLapD": "",
        "longitude": 105.82844217140223,
        "latitude": 21.02926522988727,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4861,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83660611772152,
          21.01760297703925
        ]
      },
      "properties": {
        "tenDoiTuon": "02",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Hẻm 26/11 Ngõ Cống Trắng",
        "tuDien": "BCA",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "BCA",
        "diaChiLapD": "",
        "longitude": 105.83660611772152,
        "latitude": 21.01760297703925,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4862,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8364693250638,
          21.01766431920575
        ]
      },
      "properties": {
        "tenDoiTuon": "BCA 07",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Hẻm 26/11 Ngõ Cống Trắng",
        "tuDien": "BCA",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "BCA",
        "diaChiLapD": "",
        "longitude": 105.8364693250638,
        "latitude": 21.01766431920575,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4863,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83666780852656,
          21.01774193578818
        ]
      },
      "properties": {
        "tenDoiTuon": "03",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Hẻm 26/11 Ngõ Cống Trắng",
        "tuDien": "BCA",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "BCA",
        "diaChiLapD": "",
        "longitude": 105.83666780852656,
        "latitude": 21.01774193578818,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4864,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83646396063932,
          21.017829567364384
        ]
      },
      "properties": {
        "tenDoiTuon": "06",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Hẻm 26/11 Ngõ Cống Trắng",
        "tuDien": "BCA",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "BCA",
        "diaChiLapD": "",
        "longitude": 105.83646396063932,
        "latitude": 21.017829567364384,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4865,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83648407720638,
          21.017954755241885
        ]
      },
      "properties": {
        "tenDoiTuon": "05",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Hẻm 26/11 Ngõ Cống Trắng",
        "tuDien": "BCA",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "BCA",
        "diaChiLapD": "",
        "longitude": 105.83648407720638,
        "latitude": 21.017954755241885,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4866,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83674156927536,
          21.017904680103843
        ]
      },
      "properties": {
        "tenDoiTuon": "04",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Hẻm 26/11 Ngõ Cống Trắng",
        "tuDien": "BCA",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "BCA",
        "diaChiLapD": "",
        "longitude": 105.83674156927536,
        "latitude": 21.017904680103843,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4867,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83654308581265,
          21.017484048277936
        ]
      },
      "properties": {
        "tenDoiTuon": "BCA 01",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Hẻm 26/11 Ngõ Cống Trắng",
        "tuDien": "BCA",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "BCA",
        "diaChiLapD": "",
        "longitude": 105.83654308581265,
        "latitude": 21.017484048277936,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4868,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81032317897865,
          21.01644954775259
        ]
      },
      "properties": {
        "tenDoiTuon": "BX 10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 36 Nguyên Hồng",
        "tuDien": "BX",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 7,
        "tuDien2": "BX T",
        "diaChiLapD": "",
        "longitude": 105.81032317897865,
        "latitude": 21.01644954775259,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4869,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8100683691174,
          21.016574736788602
        ]
      },
      "properties": {
        "tenDoiTuon": "09",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 36 Nguyên Hồng",
        "tuDien": "BX",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 7,
        "tuDien2": "BX T",
        "diaChiLapD": "",
        "longitude": 105.8100683691174,
        "latitude": 21.016574736788602,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4870,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80977869054652,
          21.01663107182055
        ]
      },
      "properties": {
        "tenDoiTuon": "08",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 36 Nguyên Hồng",
        "tuDien": "BX",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 7,
        "tuDien2": "BX T",
        "diaChiLapD": "",
        "longitude": 105.80977869054652,
        "latitude": 21.01663107182055,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4871,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81025746485304,
          21.01656972922904
        ]
      },
      "properties": {
        "tenDoiTuon": "9-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 36 Nguyên Hồng",
        "tuDien": "BX",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 7,
        "tuDien2": "BX T",
        "diaChiLapD": "",
        "longitude": 105.81025746485304,
        "latitude": 21.01656972922904,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4872,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81040431576734,
          21.01681822916257
        ]
      },
      "properties": {
        "tenDoiTuon": "9-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 36 Nguyên Hồng",
        "tuDien": "BX",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "BX T",
        "diaChiLapD": "",
        "longitude": 105.81040431576734,
        "latitude": 21.01681822916257,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4873,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81009586173329,
          21.01696344807525
        ]
      },
      "properties": {
        "tenDoiTuon": "9-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 36 Nguyên Hồng",
        "tuDien": "BX",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 7,
        "tuDien2": "BX T",
        "diaChiLapD": "",
        "longitude": 105.81009586173329,
        "latitude": 21.01696344807525,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4874,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81002746540891,
          21.01677190930652
        ]
      },
      "properties": {
        "tenDoiTuon": "9-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 36 Nguyên Hồng",
        "tuDien": "BX",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 7,
        "tuDien2": "BX T",
        "diaChiLapD": "",
        "longitude": 105.81002746540891,
        "latitude": 21.01677190930652,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4875,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80972169357361,
          21.01634251104321
        ]
      },
      "properties": {
        "tenDoiTuon": "8-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 36/45 Nguyên Hồng",
        "tuDien": "BX",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 7,
        "tuDien2": "BX T",
        "diaChiLapD": "",
        "longitude": 105.80972169357361,
        "latitude": 21.01634251104321,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4876,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81002478319218,
          21.016202299097923
        ]
      },
      "properties": {
        "tenDoiTuon": "8-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 36/45 Nguyên Hồng",
        "tuDien": "BX",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 7,
        "tuDien2": "BX T",
        "diaChiLapD": "",
        "longitude": 105.81002478319218,
        "latitude": 21.016202299097923,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4877,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80960769969069,
          21.016158482837742
        ]
      },
      "properties": {
        "tenDoiTuon": "8-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 36/45 Nguyên Hồng",
        "tuDien": "BX",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 7,
        "tuDien2": "BX T",
        "diaChiLapD": "",
        "longitude": 105.80960769969069,
        "latitude": 21.016158482837742,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4878,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80959697085969,
          21.01579418143463
        ]
      },
      "properties": {
        "tenDoiTuon": "8-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 36/45 Nguyên Hồng",
        "tuDien": "BX",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 7,
        "tuDien2": "BX T",
        "diaChiLapD": "",
        "longitude": 105.80959697085969,
        "latitude": 21.01579418143463,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4879,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80944408494655,
          21.01586679140721
        ]
      },
      "properties": {
        "tenDoiTuon": "8-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 36/45 Nguyên Hồng",
        "tuDien": "BX",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 7,
        "tuDien2": "BX T",
        "diaChiLapD": "",
        "longitude": 105.80944408494655,
        "latitude": 21.01586679140721,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4880,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82798083145504,
          21.027209787581466
        ]
      },
      "properties": {
        "tenDoiTuon": "15",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Hào Nam Mới",
        "tuDien": "CL3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Cát Linh 3",
        "diaChiLapD": "",
        "longitude": 105.82798083145504,
        "latitude": 21.027209787581466,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4881,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82874526102081,
          21.028912226586353
        ]
      },
      "properties": {
        "tenDoiTuon": "19-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 33 Cát Linh",
        "tuDien": "CL3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Cát Linh 3",
        "diaChiLapD": "",
        "longitude": 105.82874526102081,
        "latitude": 21.028912226586353,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4882,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82886059600762,
          21.02875450143728
        ]
      },
      "properties": {
        "tenDoiTuon": "19-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 33 Cát Linh",
        "tuDien": "CL3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Cát Linh 3",
        "diaChiLapD": "",
        "longitude": 105.82886059600762,
        "latitude": 21.02875450143728,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4883,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84091043862392,
          21.01386568099969
        ]
      },
      "properties": {
        "tenDoiTuon": "HBM3 L4-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hồ Ba Mẫu",
        "tuDien": "HBM3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "HBM 3",
        "diaChiLapD": "",
        "longitude": 105.84091043862392,
        "latitude": 21.01386568099969,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4884,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84075487049404,
          21.014068490698005
        ]
      },
      "properties": {
        "tenDoiTuon": "L4-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hồ Ba Mẫu",
        "tuDien": "HBM3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "HBM 3",
        "diaChiLapD": "",
        "longitude": 105.84075487049404,
        "latitude": 21.014068490698005,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4885,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8405563870313,
          21.014293834484924
        ]
      },
      "properties": {
        "tenDoiTuon": "HBM3 L4-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hồ Ba Mẫu",
        "tuDien": "HBM3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "HBM 3",
        "diaChiLapD": "",
        "longitude": 105.8405563870313,
        "latitude": 21.014293834484924,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4886,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83383475263672,
          21.01408908881391
        ]
      },
      "properties": {
        "tenDoiTuon": "DVN3 26",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Nam Đồng",
        "tuDien": "ĐVN3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Đặng Văn Ngữ 3",
        "diaChiLapD": "",
        "longitude": 105.83383475263672,
        "latitude": 21.01408908881391,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4887,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83381597717353,
          21.013923836510656
        ]
      },
      "properties": {
        "tenDoiTuon": "20",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Nam Đồng",
        "tuDien": "ĐVN3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Đặng Văn Ngữ 3",
        "diaChiLapD": "",
        "longitude": 105.83381597717353,
        "latitude": 21.013923836510656,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4888,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83400909622083,
          21.014124142309466
        ]
      },
      "properties": {
        "tenDoiTuon": "DVN3 21",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Nam Đồng",
        "tuDien": "ĐVN3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Đặng Văn Ngữ 3",
        "diaChiLapD": "",
        "longitude": 105.83400909622083,
        "latitude": 21.014124142309466,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4889,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83355848511354,
          21.01383870646389
        ]
      },
      "properties": {
        "tenDoiTuon": "25",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Nam Đồng",
        "tuDien": "ĐVN3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Đặng Văn Ngữ 3",
        "diaChiLapD": "",
        "longitude": 105.83355848511354,
        "latitude": 21.01383870646389,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4890,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83325271327821,
          21.013548262411906
        ]
      },
      "properties": {
        "tenDoiTuon": "24",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Nam Đồng",
        "tuDien": "ĐVN3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Đặng Văn Ngữ 3",
        "diaChiLapD": "",
        "longitude": 105.83325271327821,
        "latitude": 21.013548262411906,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4891,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83295767029185,
          21.013292871485284
        ]
      },
      "properties": {
        "tenDoiTuon": "23",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Nam Đồng",
        "tuDien": "ĐVN3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Đặng Văn Ngữ 3",
        "diaChiLapD": "",
        "longitude": 105.83295767029185,
        "latitude": 21.013292871485284,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4892,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83354239185809,
          21.01368346919667
        ]
      },
      "properties": {
        "tenDoiTuon": "19",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Nam Đồng",
        "tuDien": "ĐVN3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Đặng Văn Ngữ 3",
        "diaChiLapD": "",
        "longitude": 105.83354239185809,
        "latitude": 21.01368346919667,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4893,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83321248014413,
          21.0133754980089
        ]
      },
      "properties": {
        "tenDoiTuon": "18",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Nam Đồng",
        "tuDien": "ĐVN3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Đặng Văn Ngữ 3",
        "diaChiLapD": "",
        "longitude": 105.83321248014413,
        "latitude": 21.0133754980089,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4894,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83287720402366,
          21.013100076083255
        ]
      },
      "properties": {
        "tenDoiTuon": "17",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Nam Đồng",
        "tuDien": "ĐVN3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Đặng Văn Ngữ 3",
        "diaChiLapD": "",
        "longitude": 105.83287720402366,
        "latitude": 21.013100076083255,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4895,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8326277585779,
          21.012994914850687
        ]
      },
      "properties": {
        "tenDoiTuon": "22",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Nam Đồng",
        "tuDien": "ĐVN3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Đặng Văn Ngữ 3",
        "diaChiLapD": "",
        "longitude": 105.8326277585779,
        "latitude": 21.012994914850687,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4896,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83256338556514,
          21.012814638278776
        ]
      },
      "properties": {
        "tenDoiTuon": "DVN3 16",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Nam Đồng",
        "tuDien": "ĐVN3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Đặng Văn Ngữ 3",
        "diaChiLapD": "",
        "longitude": 105.83256338556514,
        "latitude": 21.012814638278776,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4897,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83240781744422,
          21.012752042195423
        ]
      },
      "properties": {
        "tenDoiTuon": "07",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Nam Đồng",
        "tuDien": "ĐVN3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Đặng Văn Ngữ 3",
        "diaChiLapD": "",
        "longitude": 105.83240781744422,
        "latitude": 21.012752042195423,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4898,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8323166223361,
          21.012566757636876
        ]
      },
      "properties": {
        "tenDoiTuon": "DVN3 10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Nam Đồng",
        "tuDien": "ĐVN3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Đặng Văn Ngữ 3",
        "diaChiLapD": "",
        "longitude": 105.8323166223361,
        "latitude": 21.012566757636876,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4899,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83206181247482,
          21.01234141124068
        ]
      },
      "properties": {
        "tenDoiTuon": "DVN3 11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Nam Đồng",
        "tuDien": "ĐVN3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Đặng Văn Ngữ 3",
        "diaChiLapD": "",
        "longitude": 105.83206181247482,
        "latitude": 21.01234141124068,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4900,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83178554495163,
          21.012106049086235
        ]
      },
      "properties": {
        "tenDoiTuon": "DVN3 12",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Nam Đồng",
        "tuDien": "ĐVN3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Đặng Văn Ngữ 3",
        "diaChiLapD": "",
        "longitude": 105.83178554495163,
        "latitude": 21.012106049086235,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4901,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83152537067491,
          21.01188070199405
        ]
      },
      "properties": {
        "tenDoiTuon": "DVN3 13",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Nam Đồng",
        "tuDien": "ĐVN3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Đặng Văn Ngữ 3",
        "diaChiLapD": "",
        "longitude": 105.83152537067491,
        "latitude": 21.01188070199405,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4902,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83123837431175,
          21.01164533911267
        ]
      },
      "properties": {
        "tenDoiTuon": "DVN3 14",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Nam Đồng",
        "tuDien": "ĐVN3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Đặng Văn Ngữ 3",
        "diaChiLapD": "",
        "longitude": 105.83123837431175,
        "latitude": 21.01164533911267,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4903,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83096747120406,
          21.011422495191173
        ]
      },
      "properties": {
        "tenDoiTuon": "DVN3 15",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Nam Đồng",
        "tuDien": "ĐVN3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Đặng Văn Ngữ 3",
        "diaChiLapD": "",
        "longitude": 105.83096747120406,
        "latitude": 21.011422495191173,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4904,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8325619087176,
          21.01248404300128
        ]
      },
      "properties": {
        "tenDoiTuon": "DVN3 02",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Nam Đồng",
        "tuDien": "ĐVN3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Đặng Văn Ngữ 3",
        "diaChiLapD": "",
        "longitude": 105.8325619087176,
        "latitude": 21.01248404300128,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4905,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8323073068914,
          21.012293492562158
        ]
      },
      "properties": {
        "tenDoiTuon": "DVN3 03",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Nam Đồng",
        "tuDien": "ĐVN3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Đặng Văn Ngữ 3",
        "diaChiLapD": "",
        "longitude": 105.8323073068914,
        "latitude": 21.012293492562158,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4906,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83204376494098,
          21.012062804030077
        ]
      },
      "properties": {
        "tenDoiTuon": "DVN3 04",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Nam Đồng",
        "tuDien": "ĐVN3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Đặng Văn Ngữ 3",
        "diaChiLapD": "",
        "longitude": 105.83204376494098,
        "latitude": 21.012062804030077,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4907,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8317426296098,
          21.011750501297517
        ]
      },
      "properties": {
        "tenDoiTuon": "DVN3 05",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Nam Đồng",
        "tuDien": "ĐVN3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Đặng Văn Ngữ 3",
        "diaChiLapD": "",
        "longitude": 105.8317426296098,
        "latitude": 21.011750501297517,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4908,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83144346995935,
          21.011531929969063
        ]
      },
      "properties": {
        "tenDoiTuon": "DVN3 06",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Nam Đồng",
        "tuDien": "ĐVN3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Đặng Văn Ngữ 3",
        "diaChiLapD": "",
        "longitude": 105.83144346995935,
        "latitude": 21.011531929969063,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4909,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83114104844435,
          21.011350598445457
        ]
      },
      "properties": {
        "tenDoiTuon": "07",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Nam Đồng",
        "tuDien": "ĐVN3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Đặng Văn Ngữ 3",
        "diaChiLapD": "",
        "longitude": 105.83114104844435,
        "latitude": 21.011350598445457,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4910,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83120082702675,
          21.01133403940328
        ]
      },
      "properties": {
        "tenDoiTuon": "DVN3 07",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Nam Đồng",
        "tuDien": "ĐVN3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Đặng Văn Ngữ 3",
        "diaChiLapD": "",
        "longitude": 105.83120082702675,
        "latitude": 21.01133403940328,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4911,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83089505137045,
          21.011068090421492
        ]
      },
      "properties": {
        "tenDoiTuon": "DVN3 08",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Nam Đồng",
        "tuDien": "ĐVN3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Đặng Văn Ngữ 3",
        "diaChiLapD": "",
        "longitude": 105.83089505137045,
        "latitude": 21.011068090421492,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4912,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84102666738434,
          21.013724159514826
        ]
      },
      "properties": {
        "tenDoiTuon": "HBM3 L3-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hồ Ba Mẫu",
        "tuDien": "HBM3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "HBM 3",
        "diaChiLapD": "",
        "longitude": 105.84102666738434,
        "latitude": 21.013724159514826,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4913,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84114939652,
          21.01348150221777
        ]
      },
      "properties": {
        "tenDoiTuon": "L3-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hồ Ba Mẫu",
        "tuDien": "HBM3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "HBM 3",
        "diaChiLapD": "",
        "longitude": 105.84114939652,
        "latitude": 21.01348150221777,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4914,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84115304666754,
          21.013234146211108
        ]
      },
      "properties": {
        "tenDoiTuon": "L3-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hồ Ba Mẫu",
        "tuDien": "HBM3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "HBM 3",
        "diaChiLapD": "",
        "longitude": 105.84115304666754,
        "latitude": 21.013234146211108,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4915,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84114584503048,
          21.012982663284262
        ]
      },
      "properties": {
        "tenDoiTuon": "L3-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hồ Ba Mẫu",
        "tuDien": "HBM3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "HBM 3",
        "diaChiLapD": "",
        "longitude": 105.84114584503048,
        "latitude": 21.012982663284262,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4916,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84114048548787,
          21.012752108819978
        ]
      },
      "properties": {
        "tenDoiTuon": "L3-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hồ Ba Mẫu",
        "tuDien": "HBM3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "HBM 3",
        "diaChiLapD": "",
        "longitude": 105.84114048548787,
        "latitude": 21.012752108819978,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4917,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84113932535197,
          21.012492151701807
        ]
      },
      "properties": {
        "tenDoiTuon": "L3-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hồ Ba Mẫu",
        "tuDien": "HBM3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "HBM 3",
        "diaChiLapD": "",
        "longitude": 105.84113932535197,
        "latitude": 21.012492151701807,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4918,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84112895967124,
          21.01222398058591
        ]
      },
      "properties": {
        "tenDoiTuon": "L3-7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hồ Ba Mẫu",
        "tuDien": "HBM3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "HBM 3",
        "diaChiLapD": "",
        "longitude": 105.84112895967124,
        "latitude": 21.01222398058591,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4919,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84112509084738,
          21.011982496106715
        ]
      },
      "properties": {
        "tenDoiTuon": "L3-8",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hồ Ba Mẫu",
        "tuDien": "HBM3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "HBM 3",
        "diaChiLapD": "",
        "longitude": 105.84112509084738,
        "latitude": 21.011982496106715,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4920,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84112502059781,
          21.011775366164485
        ]
      },
      "properties": {
        "tenDoiTuon": "L3-9",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hồ Ba Mẫu",
        "tuDien": "HBM3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "HBM 3",
        "diaChiLapD": "",
        "longitude": 105.84112502059781,
        "latitude": 21.011775366164485,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4921,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84112501600333,
          21.011515405088243
        ]
      },
      "properties": {
        "tenDoiTuon": "L3-10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hồ Ba Mẫu",
        "tuDien": "HBM3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "HBM 3",
        "diaChiLapD": "",
        "longitude": 105.84112501600333,
        "latitude": 21.011515405088243,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4922,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8411115834604,
          21.01123224064662
        ]
      },
      "properties": {
        "tenDoiTuon": "L3-11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hồ Ba Mẫu",
        "tuDien": "HBM3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "HBM 3",
        "diaChiLapD": "",
        "longitude": 105.8411115834604,
        "latitude": 21.01123224064662,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4923,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84110695945029,
          21.010913606696377
        ]
      },
      "properties": {
        "tenDoiTuon": "L3-12",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hồ Ba Mẫu",
        "tuDien": "HBM3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "HBM 3",
        "diaChiLapD": "",
        "longitude": 105.84110695945029,
        "latitude": 21.010913606696377,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4924,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8411942610685,
          21.01070206437941
        ]
      },
      "properties": {
        "tenDoiTuon": "L3-12-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hồ Ba Mẫu",
        "tuDien": "HBM3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "HBM 3",
        "diaChiLapD": "",
        "longitude": 105.8411942610685,
        "latitude": 21.01070206437941,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4925,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84100460033763,
          21.010718159909846
        ]
      },
      "properties": {
        "tenDoiTuon": "L3-13",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hồ Ba Mẫu",
        "tuDien": "HBM3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "HBM 3",
        "diaChiLapD": "",
        "longitude": 105.84100460033763,
        "latitude": 21.010718159909846,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4926,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84075316691573,
          21.010711711920873
        ]
      },
      "properties": {
        "tenDoiTuon": "L3-14",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hồ Ba Mẫu",
        "tuDien": "HBM3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "HBM 3",
        "diaChiLapD": "",
        "longitude": 105.84075316691573,
        "latitude": 21.010711711920873,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4927,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84051512137039,
          21.010709889498205
        ]
      },
      "properties": {
        "tenDoiTuon": "L3-15",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hồ Ba Mẫu",
        "tuDien": "HBM3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "HBM 3",
        "diaChiLapD": "",
        "longitude": 105.84051512137039,
        "latitude": 21.010709889498205,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4928,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84022305681079,
          21.01093942224385
        ]
      },
      "properties": {
        "tenDoiTuon": "L3-16",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hồ Ba Mẫu",
        "tuDien": "HBM3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "HBM 3",
        "diaChiLapD": "",
        "longitude": 105.84022305681079,
        "latitude": 21.01093942224385,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4929,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84004271883099,
          21.01111724020281
        ]
      },
      "properties": {
        "tenDoiTuon": "L3-17",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hồ Ba Mẫu",
        "tuDien": "HBM3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "HBM 3",
        "diaChiLapD": "",
        "longitude": 105.84004271883099,
        "latitude": 21.01111724020281,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4930,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83984972082352,
          21.01129282726787
        ]
      },
      "properties": {
        "tenDoiTuon": "L4-17",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hồ Ba Mẫu",
        "tuDien": "HBM3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "HBM 3",
        "diaChiLapD": "",
        "longitude": 105.83984972082352,
        "latitude": 21.01129282726787,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4931,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83964023522414,
          21.01147902830112
        ]
      },
      "properties": {
        "tenDoiTuon": "L4-16",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hồ Ba Mẫu",
        "tuDien": "HBM3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "HBM 3",
        "diaChiLapD": "",
        "longitude": 105.83964023522414,
        "latitude": 21.01147902830112,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4932,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83944341194923,
          21.011673455201382
        ]
      },
      "properties": {
        "tenDoiTuon": "L4-15",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hồ Ba Mẫu",
        "tuDien": "HBM3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "HBM 3",
        "diaChiLapD": "",
        "longitude": 105.83944341194923,
        "latitude": 21.011673455201382,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4933,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83922015523612,
          21.011886394090183
        ]
      },
      "properties": {
        "tenDoiTuon": "L4-14",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hồ Ba Mẫu",
        "tuDien": "HBM3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "HBM 3",
        "diaChiLapD": "",
        "longitude": 105.83922015523612,
        "latitude": 21.011886394090183,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4934,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8391554745124,
          21.01208311512119
        ]
      },
      "properties": {
        "tenDoiTuon": "HBM3 L4-13",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hồ Ba Mẫu",
        "tuDien": "HBM3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "HBM 3",
        "diaChiLapD": "",
        "longitude": 105.8391554745124,
        "latitude": 21.01208311512119,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4935,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83237563093337,
          21.01278208831848
        ]
      },
      "properties": {
        "tenDoiTuon": "DVN3 09",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Nam Đồng",
        "tuDien": "ĐVN3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Đặng Văn Ngữ 3",
        "diaChiLapD": "",
        "longitude": 105.83237563093337,
        "latitude": 21.01278208831848,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4936,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8097713144887,
          21.01748423197769
        ]
      },
      "properties": {
        "tenDoiTuon": "BW 18",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 97 Nguyễn Chí Thanh",
        "tuDien": "BW",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "BW",
        "diaChiLapD": "",
        "longitude": 105.8097713144887,
        "latitude": 21.01748423197769,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4937,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80957283101696,
          21.017578123112905
        ]
      },
      "properties": {
        "tenDoiTuon": "17",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 97 Nguyễn Chí Thanh",
        "tuDien": "BW",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "BW",
        "diaChiLapD": "",
        "longitude": 105.80957283101696,
        "latitude": 21.017578123112905,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4938,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80939848743289,
          21.017664502905276
        ]
      },
      "properties": {
        "tenDoiTuon": "16",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 97 Nguyễn Chí Thanh",
        "tuDien": "BW",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "BW",
        "diaChiLapD": "",
        "longitude": 105.80939848743289,
        "latitude": 21.017664502905276,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4939,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80913428984455,
          21.01779094280123
        ]
      },
      "properties": {
        "tenDoiTuon": "15",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 97 Nguyễn Chí Thanh",
        "tuDien": "BW",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "BW",
        "diaChiLapD": "",
        "longitude": 105.80913428984455,
        "latitude": 21.01779094280123,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4940,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80986787402131,
          21.018921385814995
        ]
      },
      "properties": {
        "tenDoiTuon": "BW 11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 55 Huỳnh Thúc Kháng",
        "tuDien": "BW",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "BW",
        "diaChiLapD": "",
        "longitude": 105.80986787402131,
        "latitude": 21.018921385814995,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4941,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80953259790085,
          21.018300456936434
        ]
      },
      "properties": {
        "tenDoiTuon": "12",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 55 Huỳnh Thúc Kháng",
        "tuDien": "BW",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "BW",
        "diaChiLapD": "",
        "longitude": 105.80953259790085,
        "latitude": 21.018300456936434,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4942,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80939982855473,
          21.018050081657602
        ]
      },
      "properties": {
        "tenDoiTuon": "13",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 55 Huỳnh Thúc Kháng",
        "tuDien": "BW",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "BW",
        "diaChiLapD": "",
        "longitude": 105.80939982855473,
        "latitude": 21.018050081657602,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4943,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8092549892648,
          21.017794698438276
        ]
      },
      "properties": {
        "tenDoiTuon": "14",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 55 Huỳnh Thúc Kháng",
        "tuDien": "BW",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "BW",
        "diaChiLapD": "",
        "longitude": 105.8092549892648,
        "latitude": 21.017794698438276,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4944,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80976595009118,
          21.018498253109996
        ]
      },
      "properties": {
        "tenDoiTuon": "09",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 55 Huỳnh Thúc Kháng",
        "tuDien": "BW",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "BW",
        "diaChiLapD": "",
        "longitude": 105.80976595009118,
        "latitude": 21.018498253109996,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4945,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81003146801794,
          21.018375193869993
        ]
      },
      "properties": {
        "tenDoiTuon": "10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 55 Huỳnh Thúc Kháng",
        "tuDien": "BW",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "BW",
        "diaChiLapD": "",
        "longitude": 105.81003146801794,
        "latitude": 21.018375193869993,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4946,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81009071783761,
          21.018438222908856
        ]
      },
      "properties": {
        "tenDoiTuon": "10-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - đèn chùm",
        "tenDuongPh": "Ngõ 55 Huỳnh Thúc Kháng",
        "tuDien": "BW",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Sân vườn",
        "chieuCao": 7,
        "tuDien2": "BW",
        "diaChiLapD": "",
        "longitude": 105.81009071783761,
        "latitude": 21.018438222908856,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4947,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83286882214918,
          21.012701652328797
        ]
      },
      "properties": {
        "tenDoiTuon": "DVN3 10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Nam Đồng",
        "tuDien": "ĐVN3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Đặng Văn Ngữ 3",
        "diaChiLapD": "",
        "longitude": 105.83286882214918,
        "latitude": 21.012701652328797,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4948,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82935680471837,
          21.00077012056677
        ]
      },
      "properties": {
        "tenDoiTuon": "01",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Quanh hồ Hố Mẻ",
        "tuDien": "KT4B",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "KT4B",
        "diaChiLapD": "",
        "longitude": 105.82935680471837,
        "latitude": 21.00077012056677,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4949,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82974304282185,
          21.00063114600978
        ]
      },
      "properties": {
        "tenDoiTuon": "02",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Quanh hồ Hố Mẻ",
        "tuDien": "KT4B",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "KT4B",
        "diaChiLapD": "",
        "longitude": 105.82974304282185,
        "latitude": 21.00063114600978,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4950,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82950298506725,
          21.000713075281933
        ]
      },
      "properties": {
        "tenDoiTuon": "1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - đèn chùm",
        "tenDuongPh": "Quanh hồ Hố Mẻ",
        "tuDien": "KT4B",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "KT4B",
        "diaChiLapD": "",
        "longitude": 105.82950298506725,
        "latitude": 21.000713075281933,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4951,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82997103058773,
          21.00058356910443
        ]
      },
      "properties": {
        "tenDoiTuon": "2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - đèn chùm",
        "tenDuongPh": "Quanh hồ Hố Mẻ",
        "tuDien": "KT4B",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "KT4B",
        "diaChiLapD": "",
        "longitude": 105.82997103058773,
        "latitude": 21.00058356910443,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4952,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83018292509816,
          21.00063615410434
        ]
      },
      "properties": {
        "tenDoiTuon": "03",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Quanh hồ Hố Mẻ",
        "tuDien": "KT4B",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "KT4B",
        "diaChiLapD": "",
        "longitude": 105.83018292509816,
        "latitude": 21.00063615410434,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4953,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83034117542681,
          21.00089407073938
        ]
      },
      "properties": {
        "tenDoiTuon": "3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - đèn chùm",
        "tenDuongPh": "Quanh hồ Hố Mẻ",
        "tuDien": "KT4B",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "KT4B",
        "diaChiLapD": "",
        "longitude": 105.83034117542681,
        "latitude": 21.00089407073938,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4954,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.830483332509,
          21.00111442634574
        ]
      },
      "properties": {
        "tenDoiTuon": "04",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Quanh hồ Hố Mẻ",
        "tuDien": "KT4B",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "KT4B",
        "diaChiLapD": "",
        "longitude": 105.830483332509,
        "latitude": 21.00111442634574,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4955,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83063890062989,
          21.00136483004989
        ]
      },
      "properties": {
        "tenDoiTuon": "05",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Quanh hồ Hố Mẻ",
        "tuDien": "KT4B",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "KT4B",
        "diaChiLapD": "",
        "longitude": 105.83063890062989,
        "latitude": 21.00136483004989,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4956,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8305584343617,
          21.001580176899353
        ]
      },
      "properties": {
        "tenDoiTuon": "4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - đèn chùm",
        "tenDuongPh": "Quanh hồ Hố Mẻ",
        "tuDien": "KT4B",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "KT4B",
        "diaChiLapD": "",
        "longitude": 105.8305584343617,
        "latitude": 21.001580176899353,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4957,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8304028662408,
          21.001666565955183
        ]
      },
      "properties": {
        "tenDoiTuon": "06",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Quanh hồ Hố Mẻ",
        "tuDien": "KT4B",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "KT4B",
        "diaChiLapD": "",
        "longitude": 105.8304028662408,
        "latitude": 21.001666565955183,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4958,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8301091643583,
          21.001732922731666
        ]
      },
      "properties": {
        "tenDoiTuon": "07",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Quanh hồ Hố Mẻ",
        "tuDien": "KT4B",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "KT4B",
        "diaChiLapD": "",
        "longitude": 105.8301091643583,
        "latitude": 21.001732922731666,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4959,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82987581216797,
          21.00175545898992
        ]
      },
      "properties": {
        "tenDoiTuon": "08",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Quanh hồ Hố Mẻ",
        "tuDien": "KT4B",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "KT4B",
        "diaChiLapD": "",
        "longitude": 105.82987581216797,
        "latitude": 21.00175545898992,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4960,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82969775055575,
          21.001718384981658
        ]
      },
      "properties": {
        "tenDoiTuon": "5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - đèn chùm",
        "tenDuongPh": "Quanh hồ Hố Mẻ",
        "tuDien": "KT4B",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "KT4B",
        "diaChiLapD": "",
        "longitude": 105.82969775055575,
        "latitude": 21.001718384981658,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4961,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82956199371844,
          21.00160772124175
        ]
      },
      "properties": {
        "tenDoiTuon": "09",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Quanh hồ Hố Mẻ",
        "tuDien": "KT4B",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "KT4B",
        "diaChiLapD": "",
        "longitude": 105.82956199371844,
        "latitude": 21.00160772124175,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4962,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82949091517732,
          21.001488779727747
        ]
      },
      "properties": {
        "tenDoiTuon": "10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Quanh hồ Hố Mẻ",
        "tuDien": "KT4B",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "KT4B",
        "diaChiLapD": "",
        "longitude": 105.82949091517732,
        "latitude": 21.001488779727747,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4963,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82944665872263,
          21.001392374431195
        ]
      },
      "properties": {
        "tenDoiTuon": "6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - đèn chùm",
        "tenDuongPh": "Quanh hồ Hố Mẻ",
        "tuDien": "KT4B",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "KT4B",
        "diaChiLapD": "",
        "longitude": 105.82944665872263,
        "latitude": 21.001392374431195,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4964,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82939837896532,
          21.001282196873863
        ]
      },
      "properties": {
        "tenDoiTuon": "11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Quanh hồ Hố Mẻ",
        "tuDien": "KT4B",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "KT4B",
        "diaChiLapD": "",
        "longitude": 105.82939837896532,
        "latitude": 21.001282196873863,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4965,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82936082803897,
          21.001172019235188
        ]
      },
      "properties": {
        "tenDoiTuon": "12",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Quanh hồ Hố Mẻ",
        "tuDien": "KT4B",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "KT4B",
        "diaChiLapD": "",
        "longitude": 105.82936082803897,
        "latitude": 21.001172019235188,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4966,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82929377278253,
          21.000935935201518
        ]
      },
      "properties": {
        "tenDoiTuon": "13",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Quanh hồ Hố Mẻ",
        "tuDien": "KT4B",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "KT4B",
        "diaChiLapD": "",
        "longitude": 105.82929377278253,
        "latitude": 21.000935935201518,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4967,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82932059489588,
          21.001061841514357
        ]
      },
      "properties": {
        "tenDoiTuon": "7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - đèn chùm",
        "tenDuongPh": "Quanh hồ Hố Mẻ",
        "tuDien": "KT4B",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "KT4B",
        "diaChiLapD": "",
        "longitude": 105.82932059489588,
        "latitude": 21.001061841514357,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4968,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83007259615245,
          21.003071963634632
        ]
      },
      "properties": {
        "tenDoiTuon": "11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Tôn Thất Tùng",
        "tuDien": "KT4",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 10,
        "tuDien2": "KT4",
        "diaChiLapD": "",
        "longitude": 105.83007259615245,
        "latitude": 21.003071963634632,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4969,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82998645332268,
          21.002833987064903
        ]
      },
      "properties": {
        "tenDoiTuon": "10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Tôn Thất Tùng",
        "tuDien": "KT4",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 10,
        "tuDien2": "KT4",
        "diaChiLapD": "",
        "longitude": 105.82998645332268,
        "latitude": 21.002833987064903,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4970,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82989585084579,
          21.00259850830412
        ]
      },
      "properties": {
        "tenDoiTuon": "09",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Tôn Thất Tùng",
        "tuDien": "KT4",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 10,
        "tuDien2": "KT4",
        "diaChiLapD": "",
        "longitude": 105.82989585084579,
        "latitude": 21.00259850830412,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4971,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82980511104162,
          21.002355703509235
        ]
      },
      "properties": {
        "tenDoiTuon": "08",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Tôn Thất Tùng",
        "tuDien": "KT4",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 10,
        "tuDien2": "KT4",
        "diaChiLapD": "",
        "longitude": 105.82980511104162,
        "latitude": 21.002355703509235,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4972,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82971372622863,
          21.002122095032796
        ]
      },
      "properties": {
        "tenDoiTuon": "07",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Tôn Thất Tùng",
        "tuDien": "KT4",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 10,
        "tuDien2": "KT4",
        "diaChiLapD": "",
        "longitude": 105.82971372622863,
        "latitude": 21.002122095032796,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4973,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8296201627161,
          21.001883523466002
        ]
      },
      "properties": {
        "tenDoiTuon": "06",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Tôn Thất Tùng",
        "tuDien": "KT4",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 10,
        "tuDien2": "KT4",
        "diaChiLapD": "",
        "longitude": 105.8296201627161,
        "latitude": 21.001883523466002,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4974,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82951149606113,
          21.00164877427125
        ]
      },
      "properties": {
        "tenDoiTuon": "05",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Tôn Thất Tùng",
        "tuDien": "KT4",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 10,
        "tuDien2": "KT4",
        "diaChiLapD": "",
        "longitude": 105.82951149606113,
        "latitude": 21.00164877427125,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4975,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82940060897403,
          21.001420348739533
        ]
      },
      "properties": {
        "tenDoiTuon": "04",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Tôn Thất Tùng",
        "tuDien": "KT4",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 10,
        "tuDien2": "KT4",
        "diaChiLapD": "",
        "longitude": 105.82940060897403,
        "latitude": 21.001420348739533,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4976,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82930461080566,
          21.00118440226249
        ]
      },
      "properties": {
        "tenDoiTuon": "03",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Tôn Thất Tùng",
        "tuDien": "KT4",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 10,
        "tuDien2": "KT4",
        "diaChiLapD": "",
        "longitude": 105.82930461080566,
        "latitude": 21.00118440226249,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4977,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82923409361086,
          21.000932179138367
        ]
      },
      "properties": {
        "tenDoiTuon": "02",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Tôn Thất Tùng",
        "tuDien": "KT4",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 10,
        "tuDien2": "KT4",
        "diaChiLapD": "",
        "longitude": 105.82923409361086,
        "latitude": 21.000932179138367,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4978,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81096422690747,
          21.01764635063615
        ]
      },
      "properties": {
        "tenDoiTuon": "02",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 26 Nguyên Hồng",
        "tuDien": "BW",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "BW",
        "diaChiLapD": "",
        "longitude": 105.81096422690747,
        "latitude": 21.01764635063615,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4979,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81083682198583,
          21.01770518902365
        ]
      },
      "properties": {
        "tenDoiTuon": "03",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 26 Nguyên Hồng",
        "tuDien": "BW",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "BW",
        "diaChiLapD": "",
        "longitude": 105.81083682198583,
        "latitude": 21.01770518902365,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4980,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81066516060949,
          21.01778781310363
        ]
      },
      "properties": {
        "tenDoiTuon": "04",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 26 Nguyên Hồng",
        "tuDien": "BW",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "BW",
        "diaChiLapD": "",
        "longitude": 105.81066516060949,
        "latitude": 21.01778781310363,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4981,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81040498633276,
          21.01791174913734
        ]
      },
      "properties": {
        "tenDoiTuon": "05",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 26 Nguyên Hồng",
        "tuDien": "BW",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "BW",
        "diaChiLapD": "",
        "longitude": 105.81040498633276,
        "latitude": 21.01791174913734,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4982,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8102547826363,
          21.017980602445828
        ]
      },
      "properties": {
        "tenDoiTuon": "06",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 26 Nguyên Hồng",
        "tuDien": "BW",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "BW",
        "diaChiLapD": "",
        "longitude": 105.8102547826363,
        "latitude": 21.017980602445828,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4983,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81004389395153,
          21.01807605811473
        ]
      },
      "properties": {
        "tenDoiTuon": "07",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 26 Nguyên Hồng",
        "tuDien": "BW",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "BW",
        "diaChiLapD": "",
        "longitude": 105.81004389395153,
        "latitude": 21.01807605811473,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4984,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80981490034206,
          21.01819091780593
        ]
      },
      "properties": {
        "tenDoiTuon": "08",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 26 Nguyên Hồng",
        "tuDien": "BW",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "BW",
        "diaChiLapD": "",
        "longitude": 105.80981490034206,
        "latitude": 21.01819091780593,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4985,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81109565514971,
          21.017517406854118
        ]
      },
      "properties": {
        "tenDoiTuon": "BW 01",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 26 Nguyên Hồng",
        "tuDien": "BW",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "BW",
        "diaChiLapD": "",
        "longitude": 105.81109565514971,
        "latitude": 21.017517406854118,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4986,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81098568458287,
          21.017317731554222
        ]
      },
      "properties": {
        "tenDoiTuon": "27",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 26 Nguyên Hồng",
        "tuDien": "BW",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "BW",
        "diaChiLapD": "",
        "longitude": 105.81098568458287,
        "latitude": 21.017317731554222,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4987,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81087571401605,
          21.017118055986966
        ]
      },
      "properties": {
        "tenDoiTuon": "28",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 26 Nguyên Hồng",
        "tuDien": "BW",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "BW",
        "diaChiLapD": "",
        "longitude": 105.81087571401605,
        "latitude": 21.017118055986966,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4988,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80994924163173,
          21.01789523878195
        ]
      },
      "properties": {
        "tenDoiTuon": "7-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 26 Nguyên Hồng",
        "tuDien": "BW",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "BW",
        "diaChiLapD": "",
        "longitude": 105.80994924163173,
        "latitude": 21.01789523878195,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4989,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81123043615612,
          21.017804713478306
        ]
      },
      "properties": {
        "tenDoiTuon": "2-3.",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 18 Nguyên Hồng",
        "tuDien": "BW",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 7,
        "tuDien2": "BW",
        "diaChiLapD": "",
        "longitude": 105.81123043615612,
        "latitude": 21.017804713478306,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4990,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81109230241249,
          21.017872314955365
        ]
      },
      "properties": {
        "tenDoiTuon": "2-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 18 Nguyên Hồng",
        "tuDien": "BW",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 7,
        "tuDien2": "BW",
        "diaChiLapD": "",
        "longitude": 105.81109230241249,
        "latitude": 21.017872314955365,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4991,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81117947420003,
          21.01803505912873
        ]
      },
      "properties": {
        "tenDoiTuon": "2-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 18 Nguyên Hồng",
        "tuDien": "BW",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 7,
        "tuDien2": "BW",
        "diaChiLapD": "",
        "longitude": 105.81117947420003,
        "latitude": 21.01803505912873,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4992,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81093807536851,
          21.017894848775615
        ]
      },
      "properties": {
        "tenDoiTuon": "2-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 18 Nguyên Hồng",
        "tuDien": "BW",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "BW",
        "diaChiLapD": "",
        "longitude": 105.81093807536851,
        "latitude": 21.017894848775615,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4993,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81079457719143,
          21.01822158877098
        ]
      },
      "properties": {
        "tenDoiTuon": "2-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 18 Nguyên Hồng",
        "tuDien": "BW",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "BW",
        "diaChiLapD": "",
        "longitude": 105.81079457719143,
        "latitude": 21.01822158877098,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4994,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81057865936039,
          21.018330501944227
        ]
      },
      "properties": {
        "tenDoiTuon": "2-7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 18 Nguyên Hồng",
        "tuDien": "BW",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "BW",
        "diaChiLapD": "",
        "longitude": 105.81057865936039,
        "latitude": 21.018330501944227,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4995,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81069399436517,
          21.01803505912873
        ]
      },
      "properties": {
        "tenDoiTuon": "2-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 18 Nguyên Hồng",
        "tuDien": "BW",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "BW",
        "diaChiLapD": "",
        "longitude": 105.81069399436517,
        "latitude": 21.01803505912873,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4996,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8110346349101,
          21.01811142364186
        ]
      },
      "properties": {
        "tenDoiTuon": "2-8",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 18 Nguyên Hồng",
        "tuDien": "BW",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 7,
        "tuDien2": "BW",
        "diaChiLapD": "",
        "longitude": 105.8110346349101,
        "latitude": 21.01811142364186,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4997,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81055217253423,
          21.018308907097904
        ]
      },
      "properties": {
        "tenDoiTuon": "5-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 49 Huỳnh Thúc Kháng",
        "tuDien": "BW",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "BW",
        "diaChiLapD": "",
        "longitude": 105.81055217253423,
        "latitude": 21.018308907097904,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4998,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8104341553397,
          21.01809483627231
        ]
      },
      "properties": {
        "tenDoiTuon": "5-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 49 Huỳnh Thúc Kháng",
        "tuDien": "BW",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "BW",
        "diaChiLapD": "",
        "longitude": 105.8104341553397,
        "latitude": 21.01809483627231,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 4999,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81026115285948,
          21.017903298956732
        ]
      },
      "properties": {
        "tenDoiTuon": "21",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 49 Huỳnh Thúc Kháng",
        "tuDien": "BW",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "BW",
        "diaChiLapD": "",
        "longitude": 105.81026115285948,
        "latitude": 21.017903298956732,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5000,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81010424363471,
          21.0176015958902
        ]
      },
      "properties": {
        "tenDoiTuon": "20",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 49 Huỳnh Thúc Kháng",
        "tuDien": "BW",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "BW",
        "diaChiLapD": "",
        "longitude": 105.81010424363471,
        "latitude": 21.0176015958902,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5001,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81000634300722,
          21.01742257676868
        ]
      },
      "properties": {
        "tenDoiTuon": "BW 19",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 49 Huỳnh Thúc Kháng",
        "tuDien": "BW",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "BW",
        "diaChiLapD": "",
        "longitude": 105.81000634300722,
        "latitude": 21.01742257676868,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5002,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81068527716755,
          21.017332128215603
        ]
      },
      "properties": {
        "tenDoiTuon": "25",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 49 Huỳnh Thúc Kháng",
        "tuDien": "BW",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "BW",
        "diaChiLapD": "",
        "longitude": 105.81068527716755,
        "latitude": 21.017332128215603,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5003,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8104787470726,
          21.017533681318852
        ]
      },
      "properties": {
        "tenDoiTuon": "26",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 49 Huỳnh Thúc Kháng",
        "tuDien": "BW",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "BW",
        "diaChiLapD": "",
        "longitude": 105.8104787470726,
        "latitude": 21.017533681318852,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5004,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81055653113305,
          21.017439790155674
        ]
      },
      "properties": {
        "tenDoiTuon": "26-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - đèn chùm",
        "tenDuongPh": "Ngõ 49 Huỳnh Thúc Kháng",
        "tuDien": "BW",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "BW",
        "diaChiLapD": "",
        "longitude": 105.81055653113305,
        "latitude": 21.017439790155674,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5005,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82601711918439,
          21.004980221451987
        ]
      },
      "properties": {
        "tenDoiTuon": "04",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Khương Thượng",
        "tuDien": "KTC",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 8,
        "tuDien2": "Khương Thượng C",
        "diaChiLapD": "",
        "longitude": 105.82601711918439,
        "latitude": 21.004980221451987,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5006,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82672388126576,
          21.004703532038416
        ]
      },
      "properties": {
        "tenDoiTuon": "KTC 4-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 111 Khương Thượng",
        "tuDien": "KTC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Khương Thượng C",
        "diaChiLapD": "",
        "longitude": 105.82672388126576,
        "latitude": 21.004703532038416,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5007,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82639128734408,
          21.004866290579315
        ]
      },
      "properties": {
        "tenDoiTuon": "KTC 4-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 111 Khương Thượng",
        "tuDien": "KTC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Khương Thượng C",
        "diaChiLapD": "",
        "longitude": 105.82639128734408,
        "latitude": 21.004866290579315,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5008,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81001606601254,
          21.01760253480107
        ]
      },
      "properties": {
        "tenDoiTuon": "24",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 49 Huỳnh Thúc Kháng",
        "tuDien": "BW",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "BW",
        "diaChiLapD": "",
        "longitude": 105.81001606601254,
        "latitude": 21.01760253480107,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5009,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80983367580522,
          21.01769267022059
        ]
      },
      "properties": {
        "tenDoiTuon": "23",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 49 Huỳnh Thúc Kháng",
        "tuDien": "BW",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "BW",
        "diaChiLapD": "",
        "longitude": 105.80983367580522,
        "latitude": 21.01769267022059,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5010,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8096217812858,
          21.017810346936667
        ]
      },
      "properties": {
        "tenDoiTuon": "22",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 49 Huỳnh Thúc Kháng",
        "tuDien": "BW",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "BW",
        "diaChiLapD": "",
        "longitude": 105.8096217812858,
        "latitude": 21.017810346936667,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5011,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80976125616026,
          21.017810346936667
        ]
      },
      "properties": {
        "tenDoiTuon": "22-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - đèn chùm",
        "tenDuongPh": "Ngõ 49 Huỳnh Thúc Kháng",
        "tuDien": "BW",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "BW",
        "diaChiLapD": "",
        "longitude": 105.80976125616026,
        "latitude": 21.017810346936667,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5012,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82662195731768,
          21.0044844337213
        ]
      },
      "properties": {
        "tenDoiTuon": "4-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 111 Khương Thượng",
        "tuDien": "KTC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Khương Thượng C",
        "diaChiLapD": "",
        "longitude": 105.82662195731768,
        "latitude": 21.0044844337213,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5013,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82679093648628,
          21.00438302239204
        ]
      },
      "properties": {
        "tenDoiTuon": "KTC 4-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 111 Khương Thượng",
        "tuDien": "KTC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Khương Thượng C",
        "diaChiLapD": "",
        "longitude": 105.82679093648628,
        "latitude": 21.00438302239204,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5014,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82653344442626,
          21.004493197660146
        ]
      },
      "properties": {
        "tenDoiTuon": "KTC 3-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 123 Khương Thượng",
        "tuDien": "KTC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 8,
        "tuDien2": "Khương Thượng C",
        "diaChiLapD": "",
        "longitude": 105.82653344442626,
        "latitude": 21.004493197660146,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5015,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82597286273868,
          21.00506786052627
        ]
      },
      "properties": {
        "tenDoiTuon": "05",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Khương Thượng",
        "tuDien": "KTC",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 8,
        "tuDien2": "Khương Thượng C",
        "diaChiLapD": "",
        "longitude": 105.82597286273868,
        "latitude": 21.00506786052627,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5016,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82572073509415,
          21.005180539260188
        ]
      },
      "properties": {
        "tenDoiTuon": "06",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Khương Thượng",
        "tuDien": "KTC",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Khương Thượng C",
        "diaChiLapD": "",
        "longitude": 105.82572073509415,
        "latitude": 21.005180539260188,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5017,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82542032768332,
          21.00526567424688
        ]
      },
      "properties": {
        "tenDoiTuon": "6-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 46 Khương Thượng",
        "tuDien": "KTC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Khương Thượng C",
        "diaChiLapD": "",
        "longitude": 105.82542032768332,
        "latitude": 21.00526567424688,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5018,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82532645036743,
          21.005065356552958
        ]
      },
      "properties": {
        "tenDoiTuon": "6-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 46 Khương Thượng",
        "tuDien": "KTC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Khương Thượng C",
        "diaChiLapD": "",
        "longitude": 105.82532645036743,
        "latitude": 21.005065356552958,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5019,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82554907371781,
          21.004962693630954
        ]
      },
      "properties": {
        "tenDoiTuon": "6-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 46 Khương Thượng",
        "tuDien": "KTC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Khương Thượng C",
        "diaChiLapD": "",
        "longitude": 105.82554907371781,
        "latitude": 21.004962693630954,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5020,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82547665407284,
          21.00514798763425
        ]
      },
      "properties": {
        "tenDoiTuon": "6-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 46 Khương Thượng",
        "tuDien": "KTC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Khương Thượng C",
        "diaChiLapD": "",
        "longitude": 105.82547665407284,
        "latitude": 21.00514798763425,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5021,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8256831841678,
          21.005035308875765
        ]
      },
      "properties": {
        "tenDoiTuon": "6-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 46 Khương Thượng",
        "tuDien": "KTC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Khương Thượng C",
        "diaChiLapD": "",
        "longitude": 105.8256831841678,
        "latitude": 21.005035308875765,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5022,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84077348856678,
          21.013800212993495
        ]
      },
      "properties": {
        "tenDoiTuon": "HBM3 L1-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Hồ Ba Mẫu",
        "tuDien": "HBM3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Sân vườn",
        "chieuCao": 7,
        "tuDien2": "HBM 3",
        "diaChiLapD": "",
        "longitude": 105.84077348856678,
        "latitude": 21.013800212993495,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5023,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8406585908931,
          21.013994836907553
        ]
      },
      "properties": {
        "tenDoiTuon": "L1-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Hồ Ba Mẫu",
        "tuDien": "HBM3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Sân vườn",
        "chieuCao": 7,
        "tuDien2": "HBM 3",
        "diaChiLapD": "",
        "longitude": 105.8406585908931,
        "latitude": 21.013994836907553,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5024,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84053142749823,
          21.01416763417341
        ]
      },
      "properties": {
        "tenDoiTuon": "L1-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Hồ Ba Mẫu",
        "tuDien": "HBM3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Sân vườn",
        "chieuCao": 7,
        "tuDien2": "HBM 3",
        "diaChiLapD": "",
        "longitude": 105.84053142749823,
        "latitude": 21.01416763417341,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5025,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84034298397385,
          21.014281675673256
        ]
      },
      "properties": {
        "tenDoiTuon": "HBM3 L1-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Hồ Ba Mẫu",
        "tuDien": "HBM3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Sân vườn",
        "chieuCao": 7,
        "tuDien2": "HBM 3",
        "diaChiLapD": "",
        "longitude": 105.84034298397385,
        "latitude": 21.014281675673256,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5026,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8251547889911,
          21.00510166415523
        ]
      },
      "properties": {
        "tenDoiTuon": "7-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 44 Khương Thuợng",
        "tuDien": "KTC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Khương Thượng C",
        "diaChiLapD": "",
        "longitude": 105.8251547889911,
        "latitude": 21.00510166415523,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5027,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82527548840237,
          21.005343297280795
        ]
      },
      "properties": {
        "tenDoiTuon": "07",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Khương Thượng",
        "tuDien": "KTC",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Khương Thượng C",
        "diaChiLapD": "",
        "longitude": 105.82527548840237,
        "latitude": 21.005343297280795,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5028,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8250340895888,
          21.00487004654346
        ]
      },
      "properties": {
        "tenDoiTuon": "7-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 44 Khương Thuợng",
        "tuDien": "KTC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Khương Thượng C",
        "diaChiLapD": "",
        "longitude": 105.8250340895888,
        "latitude": 21.00487004654346,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5029,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8248919325066,
          21.004605876828858
        ]
      },
      "properties": {
        "tenDoiTuon": "KTC 7-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 44 Khương Thuợng",
        "tuDien": "KTC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Khương Thượng C",
        "diaChiLapD": "",
        "longitude": 105.8248919325066,
        "latitude": 21.004605876828858,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5030,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82507566382678,
          21.005434692091768
        ]
      },
      "properties": {
        "tenDoiTuon": "08",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Khương Thượng",
        "tuDien": "KTC",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Khương Thượng C",
        "diaChiLapD": "",
        "longitude": 105.82507566382678,
        "latitude": 21.005434692091768,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5031,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82481683065392,
          21.005591189924875
        ]
      },
      "properties": {
        "tenDoiTuon": "09",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Khương Thượng",
        "tuDien": "KTC",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 8,
        "tuDien2": "Khương Thượng C",
        "diaChiLapD": "",
        "longitude": 105.82481683065392,
        "latitude": 21.005591189924875,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5032,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82452983429076,
          21.00572389995903
        ]
      },
      "properties": {
        "tenDoiTuon": "10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Khương Thượng",
        "tuDien": "KTC",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 8,
        "tuDien2": "Khương Thượng C",
        "diaChiLapD": "",
        "longitude": 105.82452983429076,
        "latitude": 21.00572389995903,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5033,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82423613240826,
          21.0058578618552
        ]
      },
      "properties": {
        "tenDoiTuon": "11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Khương Thượng",
        "tuDien": "KTC",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 8,
        "tuDien2": "Khương Thượng C",
        "diaChiLapD": "",
        "longitude": 105.82423613240826,
        "latitude": 21.0058578618552,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5034,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82399071028308,
          21.005970539992585
        ]
      },
      "properties": {
        "tenDoiTuon": "12",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Khương Thượng",
        "tuDien": "KTC",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 8,
        "tuDien2": "Khương Thượng C",
        "diaChiLapD": "",
        "longitude": 105.82399071028308,
        "latitude": 21.005970539992585,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5035,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82367152740909,
          21.00607821013312
        ]
      },
      "properties": {
        "tenDoiTuon": "13",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Khương Thượng",
        "tuDien": "KTC",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 8,
        "tuDien2": "Khương Thượng C",
        "diaChiLapD": "",
        "longitude": 105.82367152740909,
        "latitude": 21.00607821013312,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5036,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82337916662148,
          21.006432519464976
        ]
      },
      "properties": {
        "tenDoiTuon": "KTC 14",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Khương Thượng",
        "tuDien": "KTC",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Khương Thượng C",
        "diaChiLapD": "",
        "longitude": 105.82337916662148,
        "latitude": 21.006432519464976,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5037,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84096880311711,
          21.013551717575577
        ]
      },
      "properties": {
        "tenDoiTuon": "HBM3 L2-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Hồ Ba Mẫu",
        "tuDien": "HBM3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Sân vườn",
        "chieuCao": 7,
        "tuDien2": "HBM 3",
        "diaChiLapD": "",
        "longitude": 105.84096880311711,
        "latitude": 21.013551717575577,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5038,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.841057098438,
          21.01335895662954
        ]
      },
      "properties": {
        "tenDoiTuon": "L2-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Hồ Ba Mẫu",
        "tuDien": "HBM3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Sân vườn",
        "chieuCao": 7,
        "tuDien2": "HBM 3",
        "diaChiLapD": "",
        "longitude": 105.841057098438,
        "latitude": 21.01335895662954,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5039,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84105024331792,
          21.013149410550405
        ]
      },
      "properties": {
        "tenDoiTuon": "L2-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Hồ Ba Mẫu",
        "tuDien": "HBM3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Sân vườn",
        "chieuCao": 7,
        "tuDien2": "HBM 3",
        "diaChiLapD": "",
        "longitude": 105.84105024331792,
        "latitude": 21.013149410550405,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5040,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84104732944051,
          21.01293978413489
        ]
      },
      "properties": {
        "tenDoiTuon": "L2-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Hồ Ba Mẫu",
        "tuDien": "HBM3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Sân vườn",
        "chieuCao": 7,
        "tuDien2": "HBM 3",
        "diaChiLapD": "",
        "longitude": 105.84104732944051,
        "latitude": 21.01293978413489,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5041,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84104697125836,
          21.012720077095395
        ]
      },
      "properties": {
        "tenDoiTuon": "L2-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Hồ Ba Mẫu",
        "tuDien": "HBM3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Sân vườn",
        "chieuCao": 7,
        "tuDien2": "HBM 3",
        "diaChiLapD": "",
        "longitude": 105.84104697125836,
        "latitude": 21.012720077095395,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5042,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84089669570263,
          21.012494459033952
        ]
      },
      "properties": {
        "tenDoiTuon": "L2-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Hồ Ba Mẫu",
        "tuDien": "HBM3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Sân vườn",
        "chieuCao": 7,
        "tuDien2": "HBM 3",
        "diaChiLapD": "",
        "longitude": 105.84089669570263,
        "latitude": 21.012494459033952,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5043,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84077063187588,
          21.01235173962013
        ]
      },
      "properties": {
        "tenDoiTuon": "L2-7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Hồ Ba Mẫu",
        "tuDien": "HBM3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Sân vườn",
        "chieuCao": 7,
        "tuDien2": "HBM 3",
        "diaChiLapD": "",
        "longitude": 105.84077063187588,
        "latitude": 21.01235173962013,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5044,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84079745396228,
          21.012088835079457
        ]
      },
      "properties": {
        "tenDoiTuon": "L2-8",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Hồ Ba Mẫu",
        "tuDien": "HBM3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Sân vườn",
        "chieuCao": 7,
        "tuDien2": "HBM 3",
        "diaChiLapD": "",
        "longitude": 105.84079745396228,
        "latitude": 21.012088835079457,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5045,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84101203067146,
          21.012017475196817
        ]
      },
      "properties": {
        "tenDoiTuon": "L2-9",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Hồ Ba Mẫu",
        "tuDien": "HBM3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Sân vườn",
        "chieuCao": 7,
        "tuDien2": "HBM 3",
        "diaChiLapD": "",
        "longitude": 105.84101203067146,
        "latitude": 21.012017475196817,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5046,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84102888151266,
          21.011667881027684
        ]
      },
      "properties": {
        "tenDoiTuon": "L2-10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Hồ Ba Mẫu",
        "tuDien": "HBM3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Sân vườn",
        "chieuCao": 7,
        "tuDien2": "HBM 3",
        "diaChiLapD": "",
        "longitude": 105.84102888151266,
        "latitude": 21.011667881027684,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5047,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84101412070605,
          21.011458688207625
        ]
      },
      "properties": {
        "tenDoiTuon": "L2-11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Hồ Ba Mẫu",
        "tuDien": "HBM3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Sân vườn",
        "chieuCao": 7,
        "tuDien2": "HBM 3",
        "diaChiLapD": "",
        "longitude": 105.84101412070605,
        "latitude": 21.011458688207625,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5048,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84100348785763,
          21.01124927687429
        ]
      },
      "properties": {
        "tenDoiTuon": "L2-12",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Hồ Ba Mẫu",
        "tuDien": "HBM3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Sân vườn",
        "chieuCao": 7,
        "tuDien2": "HBM 3",
        "diaChiLapD": "",
        "longitude": 105.84100348785763,
        "latitude": 21.01124927687429,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5049,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84090612200994,
          21.01105478938751
        ]
      },
      "properties": {
        "tenDoiTuon": "L2-13",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Hồ Ba Mẫu",
        "tuDien": "HBM3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Sân vườn",
        "chieuCao": 7,
        "tuDien2": "HBM 3",
        "diaChiLapD": "",
        "longitude": 105.84090612200994,
        "latitude": 21.01105478938751,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5050,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8406986435928,
          21.011000083717036
        ]
      },
      "properties": {
        "tenDoiTuon": "L2-14",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Hồ Ba Mẫu",
        "tuDien": "HBM3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Sân vườn",
        "chieuCao": 7,
        "tuDien2": "HBM 3",
        "diaChiLapD": "",
        "longitude": 105.8406986435928,
        "latitude": 21.011000083717036,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5051,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8404956261779,
          21.011000890454337
        ]
      },
      "properties": {
        "tenDoiTuon": "L2-15",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Hồ Ba Mẫu",
        "tuDien": "HBM3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Sân vườn",
        "chieuCao": 7,
        "tuDien2": "HBM 3",
        "diaChiLapD": "",
        "longitude": 105.8404956261779,
        "latitude": 21.011000890454337,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5052,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84026360118186,
          21.011091546259635
        ]
      },
      "properties": {
        "tenDoiTuon": "L2-16",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Hồ Ba Mẫu",
        "tuDien": "HBM3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Sân vườn",
        "chieuCao": 7,
        "tuDien2": "HBM 3",
        "diaChiLapD": "",
        "longitude": 105.84026360118186,
        "latitude": 21.011091546259635,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5053,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84008967405288,
          21.011224173779066
        ]
      },
      "properties": {
        "tenDoiTuon": "L2-17",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Hồ Ba Mẫu",
        "tuDien": "HBM3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Sân vườn",
        "chieuCao": 7,
        "tuDien2": "HBM 3",
        "diaChiLapD": "",
        "longitude": 105.84008967405288,
        "latitude": 21.011224173779066,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5054,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83990870902221,
          21.011399111257443
        ]
      },
      "properties": {
        "tenDoiTuon": "L2-18",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Hồ Ba Mẫu",
        "tuDien": "HBM3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Sân vườn",
        "chieuCao": 7,
        "tuDien2": "HBM 3",
        "diaChiLapD": "",
        "longitude": 105.83990870902221,
        "latitude": 21.011399111257443,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5055,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83974700681152,
          21.011563340835977
        ]
      },
      "properties": {
        "tenDoiTuon": "L2-19",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Hồ Ba Mẫu",
        "tuDien": "HBM3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Sân vườn",
        "chieuCao": 7,
        "tuDien2": "HBM 3",
        "diaChiLapD": "",
        "longitude": 105.83974700681152,
        "latitude": 21.011563340835977,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5056,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83956233296408,
          21.011731281182612
        ]
      },
      "properties": {
        "tenDoiTuon": "L2-20",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Hồ Ba Mẫu",
        "tuDien": "HBM3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Sân vườn",
        "chieuCao": 7,
        "tuDien2": "HBM 3",
        "diaChiLapD": "",
        "longitude": 105.83956233296408,
        "latitude": 21.011731281182612,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5057,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83939756641952,
          21.01187373695673
        ]
      },
      "properties": {
        "tenDoiTuon": "L2-21",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Hồ Ba Mẫu",
        "tuDien": "HBM3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Sân vườn",
        "chieuCao": 7,
        "tuDien2": "HBM 3",
        "diaChiLapD": "",
        "longitude": 105.83939756641952,
        "latitude": 21.01187373695673,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5058,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8393163357242,
          21.012079945017543
        ]
      },
      "properties": {
        "tenDoiTuon": "HBM3 L2-22",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Hồ Ba Mẫu",
        "tuDien": "HBM3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Sân vườn",
        "chieuCao": 7,
        "tuDien2": "HBM 3",
        "diaChiLapD": "",
        "longitude": 105.8393163357242,
        "latitude": 21.012079945017543,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5059,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8243890183484,
          21.003377669307543
        ]
      },
      "properties": {
        "tenDoiTuon": "02",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Khương Thượng",
        "tuDien": "LKT2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Làng Khương Thượng 2",
        "diaChiLapD": "",
        "longitude": 105.8243890183484,
        "latitude": 21.003377669307543,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5060,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82454190426154,
          21.003527909301912
        ]
      },
      "properties": {
        "tenDoiTuon": "LKT2 01",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Khương Thượng",
        "tuDien": "LKT2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Làng Khương Thượng 2",
        "diaChiLapD": "",
        "longitude": 105.82454190426154,
        "latitude": 21.003527909301912,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5061,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82551688723389,
          21.004527001414242
        ]
      },
      "properties": {
        "tenDoiTuon": "15-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 100 Khương Thượng",
        "tuDien": "KTC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khương Thượng C",
        "diaChiLapD": "",
        "longitude": 105.82551688723389,
        "latitude": 21.004527001414242,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5062,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8253881411994,
          21.004276603015768
        ]
      },
      "properties": {
        "tenDoiTuon": "KTC 15-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 100 Khương Thượng",
        "tuDien": "KTC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khương Thượng C",
        "diaChiLapD": "",
        "longitude": 105.8253881411994,
        "latitude": 21.004276603015768,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5063,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82533449701761,
          21.004605876822968
        ]
      },
      "properties": {
        "tenDoiTuon": "15-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 100 Khương Thượng",
        "tuDien": "KTC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khương Thượng C",
        "diaChiLapD": "",
        "longitude": 105.82533449701761,
        "latitude": 21.004605876822968,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5064,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82544714979667,
          21.004823722971416
        ]
      },
      "properties": {
        "tenDoiTuon": "KTC 15-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 100 Khương Thượng",
        "tuDien": "KTC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khương Thượng C",
        "diaChiLapD": "",
        "longitude": 105.82544714979667,
        "latitude": 21.004823722971416,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5065,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82562149338075,
          21.004277855008205
        ]
      },
      "properties": {
        "tenDoiTuon": "16",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Khương Thượng",
        "tuDien": "KTC",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khương Thượng C",
        "diaChiLapD": "",
        "longitude": 105.82562149338075,
        "latitude": 21.004277855008205,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5066,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8257341461598,
          21.004007424268483
        ]
      },
      "properties": {
        "tenDoiTuon": "16-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 139 Khương Thượng",
        "tuDien": "KTC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khương Thượng C",
        "diaChiLapD": "",
        "longitude": 105.8257341461598,
        "latitude": 21.004007424268483,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5067,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82591921858385,
          21.004032464173093
        ]
      },
      "properties": {
        "tenDoiTuon": "KTC 16-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 139 Khương Thượng",
        "tuDien": "KTC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khương Thượng C",
        "diaChiLapD": "",
        "longitude": 105.82591921858385,
        "latitude": 21.004032464173093,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5068,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82538009457618,
          21.003933556527336
        ]
      },
      "properties": {
        "tenDoiTuon": "17",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Khương Thượng",
        "tuDien": "KTC",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khương Thượng C",
        "diaChiLapD": "",
        "longitude": 105.82538009457618,
        "latitude": 21.003933556527336,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5069,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82538143568003,
          21.00387346071112
        ]
      },
      "properties": {
        "tenDoiTuon": "17-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 149 Khương Thượng",
        "tuDien": "KTC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khương Thượng C",
        "diaChiLapD": "",
        "longitude": 105.82538143568003,
        "latitude": 21.00387346071112,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5070,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82560271792656,
          21.003721969067556
        ]
      },
      "properties": {
        "tenDoiTuon": "17-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 149 Khương Thượng",
        "tuDien": "KTC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khương Thượng C",
        "diaChiLapD": "",
        "longitude": 105.82560271792656,
        "latitude": 21.003721969067556,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5071,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82573280506493,
          21.003626817212297
        ]
      },
      "properties": {
        "tenDoiTuon": "17-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 149 Khương Thượng",
        "tuDien": "KTC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khương Thượng C",
        "diaChiLapD": "",
        "longitude": 105.82573280506493,
        "latitude": 21.003626817212297,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5072,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82585484558007,
          21.003330093275203
        ]
      },
      "properties": {
        "tenDoiTuon": "KTC 17-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 149 Khương Thượng",
        "tuDien": "KTC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khương Thượng C",
        "diaChiLapD": "",
        "longitude": 105.82585484558007,
        "latitude": 21.003330093275203,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5073,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82516719424008,
          21.00405906906675
        ]
      },
      "properties": {
        "tenDoiTuon": "18",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Khương Thượng",
        "tuDien": "KTC",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Khương Thượng C",
        "diaChiLapD": "",
        "longitude": 105.82516719424008,
        "latitude": 21.00405906906675,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5074,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82482253038353,
          21.003988957337466
        ]
      },
      "properties": {
        "tenDoiTuon": "19",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Khương Thượng",
        "tuDien": "KTC",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 7,
        "tuDien2": "Khương Thượng C",
        "diaChiLapD": "",
        "longitude": 105.82482253038353,
        "latitude": 21.003988957337466,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5075,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82470451319799,
          21.00374481802339
        ]
      },
      "properties": {
        "tenDoiTuon": "KTC 20",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Khương Thượng",
        "tuDien": "KTC",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 8,
        "tuDien2": "Khương Thượng C",
        "diaChiLapD": "",
        "longitude": 105.82470451319799,
        "latitude": 21.00374481802339,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5076,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82495529973863,
          21.003650918181222
        ]
      },
      "properties": {
        "tenDoiTuon": "KTC 20-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Đình Khương Thượng",
        "tuDien": "KTC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 7,
        "tuDien2": "Khương Thượng C",
        "diaChiLapD": "",
        "longitude": 105.82495529973863,
        "latitude": 21.003650918181222,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5077,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82479570829713,
          21.00366093416752
        ]
      },
      "properties": {
        "tenDoiTuon": "KTC 20-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Đình Khương Thượng",
        "tuDien": "KTC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 7,
        "tuDien2": "Khương Thượng C",
        "diaChiLapD": "",
        "longitude": 105.82479570829713,
        "latitude": 21.00366093416752,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5078,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82469378435802,
          21.003473134318547
        ]
      },
      "properties": {
        "tenDoiTuon": "LKT2 1-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Đình Khương Thượng",
        "tuDien": "LKT2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Làng Khương Thượng 2",
        "diaChiLapD": "",
        "longitude": 105.82469378435802,
        "latitude": 21.003473134318547,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5079,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81143294294843,
          21.020099390958233
        ]
      },
      "properties": {
        "tenDoiTuon": "06",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 1,
        "maXaPhuong": 34,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 16 Huỳnh Thúc Kháng",
        "tuDien": "G22B",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "G22B",
        "diaChiLapD": "",
        "longitude": 105.81143294294843,
        "latitude": 21.020099390958233,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5080,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81127737482754,
          21.01985778173534
        ]
      },
      "properties": {
        "tenDoiTuon": "05",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 1,
        "maXaPhuong": 34,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 16 Huỳnh Thúc Kháng",
        "tuDien": "G22B",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "G22B",
        "diaChiLapD": "",
        "longitude": 105.81127737482754,
        "latitude": 21.01985778173534,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5081,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8110654803171,
          21.01960490534213
        ]
      },
      "properties": {
        "tenDoiTuon": "04",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 16 Huỳnh Thúc Kháng",
        "tuDien": "G22B",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "G22B",
        "diaChiLapD": "",
        "longitude": 105.8110654803171,
        "latitude": 21.01960490534213,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5082,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81093405207486,
          21.019384577045603
        ]
      },
      "properties": {
        "tenDoiTuon": "03",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 16 Huỳnh Thúc Kháng",
        "tuDien": "G22B",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "G22B",
        "diaChiLapD": "",
        "longitude": 105.81093405207486,
        "latitude": 21.019384577045603,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5083,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8107892127939,
          21.01912919611348
        ]
      },
      "properties": {
        "tenDoiTuon": "02",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 16 Huỳnh Thúc Kháng",
        "tuDien": "G22B",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "G22B",
        "diaChiLapD": "",
        "longitude": 105.8107892127939,
        "latitude": 21.01912919611348,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5084,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81068997105356,
          21.018951430696003
        ]
      },
      "properties": {
        "tenDoiTuon": "01",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 16 Huỳnh Thúc Kháng",
        "tuDien": "G22B",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "G22B",
        "diaChiLapD": "",
        "longitude": 105.81068997105356,
        "latitude": 21.018951430696003,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5085,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81159762662027,
          21.0201558079334
        ]
      },
      "properties": {
        "tenDoiTuon": "07",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 1,
        "maXaPhuong": 34,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 16 Huỳnh Thúc Kháng",
        "tuDien": "G22B",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "G22B",
        "diaChiLapD": "",
        "longitude": 105.81159762662027,
        "latitude": 21.0201558079334,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5086,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81189025959301,
          21.02017825813301
        ]
      },
      "properties": {
        "tenDoiTuon": "08",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 1,
        "maXaPhuong": 34,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 16 Huỳnh Thúc Kháng",
        "tuDien": "G22B",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "G22B",
        "diaChiLapD": "",
        "longitude": 105.81189025959301,
        "latitude": 21.02017825813301,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5087,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82418952905999,
          21.003436826320044
        ]
      },
      "properties": {
        "tenDoiTuon": "LKT2 2-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 156 Khương Thượng",
        "tuDien": "LKT2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Làng Khương Thượng 2",
        "diaChiLapD": "",
        "longitude": 105.82418952905999,
        "latitude": 21.003436826320044,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5088,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82406748855384,
          21.003276570222624
        ]
      },
      "properties": {
        "tenDoiTuon": "LKT2 2-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 156 Khương Thượng",
        "tuDien": "LKT2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 7,
        "tuDien2": "Làng Khương Thượng 2",
        "diaChiLapD": "",
        "longitude": 105.82406748855384,
        "latitude": 21.003276570222624,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5089,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82441215234748,
          21.00329284624014
        ]
      },
      "properties": {
        "tenDoiTuon": "3-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 156 Khương Thượng",
        "tuDien": "LKT2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Làng Khương Thượng 2",
        "diaChiLapD": "",
        "longitude": 105.82441215234748,
        "latitude": 21.00329284624014,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5090,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81127972177502,
          21.01952822862815
        ]
      },
      "properties": {
        "tenDoiTuon": "4-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 16 Huỳnh Thúc Kháng",
        "tuDien": "G22B",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "G22B",
        "diaChiLapD": "",
        "longitude": 105.81127972177502,
        "latitude": 21.01952822862815,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5091,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81144467763201,
          21.01944560551251
        ]
      },
      "properties": {
        "tenDoiTuon": "4-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 1,
        "maXaPhuong": 34,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 16 Huỳnh Thúc Kháng",
        "tuDien": "G22B",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 7,
        "tuDien2": "G22B",
        "diaChiLapD": "",
        "longitude": 105.81144467763201,
        "latitude": 21.01944560551251,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5092,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81167400650176,
          21.019329181954706
        ]
      },
      "properties": {
        "tenDoiTuon": "4-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 1,
        "maXaPhuong": 34,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 16 Huỳnh Thúc Kháng",
        "tuDien": "G22B",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 7,
        "tuDien2": "G22B",
        "diaChiLapD": "",
        "longitude": 105.81167400650176,
        "latitude": 21.019329181954706,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5093,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81187517218122,
          21.019516961842683
        ]
      },
      "properties": {
        "tenDoiTuon": "4-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 1,
        "maXaPhuong": 34,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 16 Huỳnh Thúc Kháng",
        "tuDien": "G22B",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "G22B",
        "diaChiLapD": "",
        "longitude": 105.81187517218122,
        "latitude": 21.019516961842683,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5094,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81181214027232,
          21.019766082796078
        ]
      },
      "properties": {
        "tenDoiTuon": "4-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 1,
        "maXaPhuong": 34,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 16 Huỳnh Thúc Kháng",
        "tuDien": "G22B",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "G22B",
        "diaChiLapD": "",
        "longitude": 105.81181214027232,
        "latitude": 21.019766082796078,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5095,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8117692249215,
          21.01999892914018
        ]
      },
      "properties": {
        "tenDoiTuon": "4-7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 1,
        "maXaPhuong": 34,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 16 Huỳnh Thúc Kháng",
        "tuDien": "G22B",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "G22B",
        "diaChiLapD": "",
        "longitude": 105.8117692249215,
        "latitude": 21.01999892914018,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5096,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81114622590988,
          21.019271799724617
        ]
      },
      "properties": {
        "tenDoiTuon": "4-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 16 Huỳnh Thúc Kháng",
        "tuDien": "G22B",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "G22B",
        "diaChiLapD": "",
        "longitude": 105.81114622590988,
        "latitude": 21.019271799724617,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5097,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81132949165647,
          21.019227490582164
        ]
      },
      "properties": {
        "tenDoiTuon": "4-8",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 1,
        "maXaPhuong": 34,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 16 Huỳnh Thúc Kháng",
        "tuDien": "G22B",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "G22B",
        "diaChiLapD": "",
        "longitude": 105.81132949165647,
        "latitude": 21.019227490582164,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5098,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82489897327724,
          21.00315074403342
        ]
      },
      "properties": {
        "tenDoiTuon": "3-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 156 Khương Thượng",
        "tuDien": "LKT2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Làng Khương Thượng 2",
        "diaChiLapD": "",
        "longitude": 105.82489897327724,
        "latitude": 21.00315074403342,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5099,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82491908984429,
          21.003263424214907
        ]
      },
      "properties": {
        "tenDoiTuon": "LKT2 3-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 156 Khương Thượng",
        "tuDien": "LKT2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Làng Khương Thượng 2",
        "diaChiLapD": "",
        "longitude": 105.82491908984429,
        "latitude": 21.003263424214907,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5100,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82503308372723,
          21.00306811184704
        ]
      },
      "properties": {
        "tenDoiTuon": "3-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 156 Khương Thượng",
        "tuDien": "LKT2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Làng Khương Thượng 2",
        "diaChiLapD": "",
        "longitude": 105.82503308372723,
        "latitude": 21.00306811184704,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5101,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82522352056674,
          21.002987983621427
        ]
      },
      "properties": {
        "tenDoiTuon": "3-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 156 Khương Thượng",
        "tuDien": "LKT2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Làng Khương Thượng 2",
        "diaChiLapD": "",
        "longitude": 105.82522352056674,
        "latitude": 21.002987983621427,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5102,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82526509480469,
          21.003311000265963
        ]
      },
      "properties": {
        "tenDoiTuon": "LKT2 3-7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 156 Khương Thượng",
        "tuDien": "LKT2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Làng Khương Thượng 2",
        "diaChiLapD": "",
        "longitude": 105.82526509480469,
        "latitude": 21.003311000265963,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5103,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82532946782644,
          21.002962943542435
        ]
      },
      "properties": {
        "tenDoiTuon": "LKT2 3-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 156 Khương Thượng",
        "tuDien": "LKT2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Làng Khương Thượng 2",
        "diaChiLapD": "",
        "longitude": 105.82532946782644,
        "latitude": 21.002962943542435,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5104,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82520608620743,
          21.002833987068247
        ]
      },
      "properties": {
        "tenDoiTuon": "LKT2 3-8",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 156 Khương Thượng",
        "tuDien": "LKT2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Làng Khương Thượng 2",
        "diaChiLapD": "",
        "longitude": 105.82520608620743,
        "latitude": 21.002833987068247,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5105,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8384239083184,
          21.015221911703023
        ]
      },
      "properties": {
        "tenDoiTuon": "T8A 28",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 165 Phố Chợ Khâm Thiên",
        "tuDien": "T8A",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "T8A",
        "diaChiLapD": "",
        "longitude": 105.8384239083184,
        "latitude": 21.015221911703023,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5106,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83833571989321,
          21.015063839895422
        ]
      },
      "properties": {
        "tenDoiTuon": "29",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 165 Phố Chợ Khâm Thiên",
        "tuDien": "T8A",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "T8A",
        "diaChiLapD": "",
        "longitude": 105.83833571989321,
        "latitude": 21.015063839895422,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5107,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83807333348571,
          21.015225543867512
        ]
      },
      "properties": {
        "tenDoiTuon": "29-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 165 Phố Chợ Khâm Thiên",
        "tuDien": "T8A",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "T8A",
        "diaChiLapD": "",
        "longitude": 105.83807333348571,
        "latitude": 21.015225543867512,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5108,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83788895128856,
          21.015372642053585
        ]
      },
      "properties": {
        "tenDoiTuon": "29-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 165 Phố Chợ Khâm Thiên",
        "tuDien": "T8A",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "T8A",
        "diaChiLapD": "",
        "longitude": 105.83788895128856,
        "latitude": 21.015372642053585,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5109,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83820475629916,
          21.014903970854167
        ]
      },
      "properties": {
        "tenDoiTuon": "30",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 165 Phố Chợ Khâm Thiên",
        "tuDien": "T8A",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "T8A",
        "diaChiLapD": "",
        "longitude": 105.83820475629916,
        "latitude": 21.014903970854167,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5110,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83810102660797,
          21.014931138169768
        ]
      },
      "properties": {
        "tenDoiTuon": "34",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 165 Phố Chợ Khâm Thiên",
        "tuDien": "T8A",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "T8A",
        "diaChiLapD": "",
        "longitude": 105.83810102660797,
        "latitude": 21.014931138169768,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5111,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8380245836514,
          21.014921122940965
        ]
      },
      "properties": {
        "tenDoiTuon": "34-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 165 Phố Chợ Khâm Thiên",
        "tuDien": "T8A",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "T8A",
        "diaChiLapD": "",
        "longitude": 105.8380245836514,
        "latitude": 21.014921122940965,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5112,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83797764499795,
          21.014760879179192
        ]
      },
      "properties": {
        "tenDoiTuon": "35",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 165 Phố Chợ Khâm Thiên",
        "tuDien": "T8A",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "T8A",
        "diaChiLapD": "",
        "longitude": 105.83797764499795,
        "latitude": 21.014760879179192,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5113,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83818738138524,
          21.014664943804497
        ]
      },
      "properties": {
        "tenDoiTuon": "35-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 165 Phố Chợ Khâm Thiên",
        "tuDien": "T8A",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "T8A",
        "diaChiLapD": "",
        "longitude": 105.83818738138524,
        "latitude": 21.014664943804497,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5114,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8383491309409,
          21.014548055167673
        ]
      },
      "properties": {
        "tenDoiTuon": "35-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 165 Phố Chợ Khâm Thiên",
        "tuDien": "T8A",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "T8A",
        "diaChiLapD": "",
        "longitude": 105.8383491309409,
        "latitude": 21.014548055167673,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5115,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83779525478167,
          21.014549307073903
        ]
      },
      "properties": {
        "tenDoiTuon": "36",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 165 Phố Chợ Khâm Thiên",
        "tuDien": "T8A",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "T8A",
        "diaChiLapD": "",
        "longitude": 105.83779525478167,
        "latitude": 21.014549307073903,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5116,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83791729528782,
          21.014381551463615
        ]
      },
      "properties": {
        "tenDoiTuon": "39",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 165 Phố Chợ Khâm Thiên",
        "tuDien": "T8A",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "T8A",
        "diaChiLapD": "",
        "longitude": 105.83791729528782,
        "latitude": 21.014381551463615,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5117,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83784353453899,
          21.014277643043076
        ]
      },
      "properties": {
        "tenDoiTuon": "39-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 165 Phố Chợ Khâm Thiên",
        "tuDien": "T8A",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "T8A",
        "diaChiLapD": "",
        "longitude": 105.83784353453899,
        "latitude": 21.014277643043076,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5118,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83810639102347,
          21.0143915667295
        ]
      },
      "properties": {
        "tenDoiTuon": "40",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 165 Phố Chợ Khâm Thiên",
        "tuDien": "T8A",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "T8A",
        "diaChiLapD": "",
        "longitude": 105.83810639102347,
        "latitude": 21.0143915667295,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5119,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.838224408227,
          21.014243841493244
        ]
      },
      "properties": {
        "tenDoiTuon": "41",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 165 Phố Chợ Khâm Thiên",
        "tuDien": "T8A",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "T8A",
        "diaChiLapD": "",
        "longitude": 105.838224408227,
        "latitude": 21.014243841493244,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5120,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83841886837813,
          21.01412365814252
        ]
      },
      "properties": {
        "tenDoiTuon": "42",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 165 Phố Chợ Khâm Thiên",
        "tuDien": "T8A",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "T8A",
        "diaChiLapD": "",
        "longitude": 105.83841886837813,
        "latitude": 21.01412365814252,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5121,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83854895551649,
          21.014196268928433
        ]
      },
      "properties": {
        "tenDoiTuon": "42-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 165 Phố Chợ Khâm Thiên",
        "tuDien": "T8A",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "T8A",
        "diaChiLapD": "",
        "longitude": 105.83854895551649,
        "latitude": 21.014196268928433,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5122,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83868977148585,
          21.014015993808535
        ]
      },
      "properties": {
        "tenDoiTuon": "43",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 165 Phố Chợ Khâm Thiên",
        "tuDien": "T8A",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "T8A",
        "diaChiLapD": "",
        "longitude": 105.83868977148585,
        "latitude": 21.014015993808535,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5123,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8388332696719,
          21.013900817923563
        ]
      },
      "properties": {
        "tenDoiTuon": "45",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 165 Phố Chợ Khâm Thiên",
        "tuDien": "T8A",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "T8A",
        "diaChiLapD": "",
        "longitude": 105.8388332696719,
        "latitude": 21.013900817923563,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5124,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83870854694902,
          21.013753092202062
        ]
      },
      "properties": {
        "tenDoiTuon": "44",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 165 Phố Chợ Khâm Thiên",
        "tuDien": "T8A",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "T8A",
        "diaChiLapD": "",
        "longitude": 105.83870854694902,
        "latitude": 21.013753092202062,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5125,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83865490276722,
          21.013662954401518
        ]
      },
      "properties": {
        "tenDoiTuon": "44-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 165 Phố Chợ Khâm Thiên",
        "tuDien": "T8A",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "T8A",
        "diaChiLapD": "",
        "longitude": 105.83865490276722,
        "latitude": 21.013662954401518,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5126,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8389701804135,
          21.0140219666291
        ]
      },
      "properties": {
        "tenDoiTuon": "47",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 165 Phố Chợ Khâm Thiên",
        "tuDien": "T8A",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "T8A",
        "diaChiLapD": "",
        "longitude": 105.8389701804135,
        "latitude": 21.0140219666291,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5127,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83890703042073,
          21.013967169259942
        ]
      },
      "properties": {
        "tenDoiTuon": "46",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 165 Phố Chợ Khâm Thiên",
        "tuDien": "T8A",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "T8A",
        "diaChiLapD": "",
        "longitude": 105.83890703042073,
        "latitude": 21.013967169259942,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5128,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83911221940282,
          21.01404478776649
        ]
      },
      "properties": {
        "tenDoiTuon": "48",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 165 Phố Chợ Khâm Thiên",
        "tuDien": "T8A",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "T8A",
        "diaChiLapD": "",
        "longitude": 105.83911221940282,
        "latitude": 21.01404478776649,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5129,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.837670380231,
          21.014385246816758
        ]
      },
      "properties": {
        "tenDoiTuon": "37",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 165 Phố Chợ Khâm Thiên",
        "tuDien": "T8A",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "T8A",
        "diaChiLapD": "",
        "longitude": 105.837670380231,
        "latitude": 21.014385246816758,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5130,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83754264996047,
          21.014477802809758
        ]
      },
      "properties": {
        "tenDoiTuon": "37-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 165 Phố Chợ Khâm Thiên",
        "tuDien": "T8A",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "T8A",
        "diaChiLapD": "",
        "longitude": 105.83754264996047,
        "latitude": 21.014477802809758,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5131,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83756726703376,
          21.014331475125765
        ]
      },
      "properties": {
        "tenDoiTuon": "37-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 165 Phố Chợ Khâm Thiên",
        "tuDien": "T8A",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "T8A",
        "diaChiLapD": "",
        "longitude": 105.83756726703376,
        "latitude": 21.014331475125765,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5132,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83755385596811,
          21.014158711629957
        ]
      },
      "properties": {
        "tenDoiTuon": "38",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 165 Phố Chợ Khâm Thiên",
        "tuDien": "T8A",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "T8A",
        "diaChiLapD": "",
        "longitude": 105.83755385596811,
        "latitude": 21.014158711629957,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5133,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8391147157132,
          21.014297565612285
        ]
      },
      "properties": {
        "tenDoiTuon": "49",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 165 Phố Chợ Khâm Thiên",
        "tuDien": "T8A",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "T8A",
        "diaChiLapD": "",
        "longitude": 105.8391147157132,
        "latitude": 21.014297565612285,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5134,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8111375647108,
          21.02018545632494
        ]
      },
      "properties": {
        "tenDoiTuon": "09",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 1,
        "maXaPhuong": 34,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 16/27 Huỳnh Thúc Kháng",
        "tuDien": "G22B",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "G22B",
        "diaChiLapD": "",
        "longitude": 105.8111375647108,
        "latitude": 21.02018545632494,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5135,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81090689473722,
          21.0202931162049
        ]
      },
      "properties": {
        "tenDoiTuon": "10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 1,
        "maXaPhuong": 34,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 16/27 Huỳnh Thúc Kháng",
        "tuDien": "G22B",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "G22B",
        "diaChiLapD": "",
        "longitude": 105.81090689473722,
        "latitude": 21.0202931162049,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5136,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81067890697132,
          21.02041830201414
        ]
      },
      "properties": {
        "tenDoiTuon": "11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 16/27 Huỳnh Thúc Kháng",
        "tuDien": "G22B",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "G22B",
        "diaChiLapD": "",
        "longitude": 105.81067890697132,
        "latitude": 21.02041830201414,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5137,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81043214374228,
          21.02055475442615
        ]
      },
      "properties": {
        "tenDoiTuon": "12",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 16/27 Huỳnh Thúc Kháng",
        "tuDien": "G22B",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "G22B",
        "diaChiLapD": "",
        "longitude": 105.81043214374228,
        "latitude": 21.02055475442615,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5138,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81103027634722,
          21.019995173556136
        ]
      },
      "properties": {
        "tenDoiTuon": "9-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 1,
        "maXaPhuong": 34,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 16/27 Huỳnh Thúc Kháng",
        "tuDien": "G22B",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "G22B",
        "diaChiLapD": "",
        "longitude": 105.81103027634722,
        "latitude": 21.019995173556136,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5139,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81062157475614,
          21.019888452357538
        ]
      },
      "properties": {
        "tenDoiTuon": "16",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 91 Nguyễn Chí Thanh",
        "tuDien": "G22B",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "G22B",
        "diaChiLapD": "",
        "longitude": 105.81062157475614,
        "latitude": 21.019888452357538,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5140,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.810342289738,
          21.01997076226218
        ]
      },
      "properties": {
        "tenDoiTuon": "17",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 91 Nguyễn Chí Thanh",
        "tuDien": "G22B",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "G22B",
        "diaChiLapD": "",
        "longitude": 105.810342289738,
        "latitude": 21.01997076226218,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5141,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81016794615394,
          21.020077170436487
        ]
      },
      "properties": {
        "tenDoiTuon": "14",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 91 Nguyễn Chí Thanh",
        "tuDien": "G22B",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "G22B",
        "diaChiLapD": "",
        "longitude": 105.81016794615394,
        "latitude": 21.020077170436487,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5142,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80984071665671,
          21.020163548781213
        ]
      },
      "properties": {
        "tenDoiTuon": "18",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 91 Nguyễn Chí Thanh",
        "tuDien": "G22B",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "G22B",
        "diaChiLapD": "",
        "longitude": 105.80984071665671,
        "latitude": 21.020163548781213,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5143,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8094276564668,
          21.02037135734684
        ]
      },
      "properties": {
        "tenDoiTuon": "19",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 91 Nguyễn Chí Thanh",
        "tuDien": "G22B",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "G22B",
        "diaChiLapD": "",
        "longitude": 105.8094276564668,
        "latitude": 21.02037135734684,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5144,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80917318188375,
          21.020509374623344
        ]
      },
      "properties": {
        "tenDoiTuon": "20",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 91 Nguyễn Chí Thanh",
        "tuDien": "G22B",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "G22B",
        "diaChiLapD": "",
        "longitude": 105.80917318188375,
        "latitude": 21.020509374623344,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5145,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83850282797756,
          21.01476668939999
        ]
      },
      "properties": {
        "tenDoiTuon": "31",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 165 Phố Chợ Khâm Thiên",
        "tuDien": "T8A",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "T8A",
        "diaChiLapD": "",
        "longitude": 105.83850282797756,
        "latitude": 21.01476668939999,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5146,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83865203466212,
          21.014822114539253
        ]
      },
      "properties": {
        "tenDoiTuon": "31-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 165 Phố Chợ Khâm Thiên",
        "tuDien": "T8A",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "T8A",
        "diaChiLapD": "",
        "longitude": 105.83865203466212,
        "latitude": 21.014822114539253,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5147,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83849497674821,
          21.014631932902343
        ]
      },
      "properties": {
        "tenDoiTuon": "31-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 165 Phố Chợ Khâm Thiên",
        "tuDien": "T8A",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "T8A",
        "diaChiLapD": "",
        "longitude": 105.83849497674821,
        "latitude": 21.014631932902343,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5148,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83869077801216,
          21.014634436714246
        ]
      },
      "properties": {
        "tenDoiTuon": "32",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 165 Phố Chợ Khâm Thiên",
        "tuDien": "T8A",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "T8A",
        "diaChiLapD": "",
        "longitude": 105.83869077801216,
        "latitude": 21.014634436714246,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5149,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8385566675622,
          21.014459169754552
        ]
      },
      "properties": {
        "tenDoiTuon": "32-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 165 Phố Chợ Khâm Thiên",
        "tuDien": "T8A",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "T8A",
        "diaChiLapD": "",
        "longitude": 105.8385566675622,
        "latitude": 21.014459169754552,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5150,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83871223568309,
          21.01453428419071
        ]
      },
      "properties": {
        "tenDoiTuon": "33",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 165 Phố Chợ Khâm Thiên",
        "tuDien": "T8A",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "T8A",
        "diaChiLapD": "",
        "longitude": 105.83871223568309,
        "latitude": 21.01453428419071,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5151,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81078351313614,
          21.02006590369168
        ]
      },
      "properties": {
        "tenDoiTuon": "10-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 1,
        "maXaPhuong": 34,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 91 Nguyễn Chí Thanh",
        "tuDien": "G22B",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "G22B",
        "diaChiLapD": "",
        "longitude": 105.81078351313614,
        "latitude": 21.02006590369168,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5152,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81055820757801,
          21.020163548780385
        ]
      },
      "properties": {
        "tenDoiTuon": "11-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 91 Nguyễn Chí Thanh",
        "tuDien": "G22B",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "G22B",
        "diaChiLapD": "",
        "longitude": 105.81055820757801,
        "latitude": 21.020163548780385,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5153,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81030443189647,
          21.020286430342537
        ]
      },
      "properties": {
        "tenDoiTuon": "13",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 91 Nguyễn Chí Thanh",
        "tuDien": "G22B",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "G22B",
        "diaChiLapD": "",
        "longitude": 105.81030443189647,
        "latitude": 21.020286430342537,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5154,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80961675221141,
          21.020281223545663
        ]
      },
      "properties": {
        "tenDoiTuon": "19-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 91 Nguyễn Chí Thanh",
        "tuDien": "G22B",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "G22B",
        "diaChiLapD": "",
        "longitude": 105.80961675221141,
        "latitude": 21.020281223545663,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5155,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80902060128378,
          21.020323332149264
        ]
      },
      "properties": {
        "tenDoiTuon": "21",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 91 Nguyễn Chí Thanh",
        "tuDien": "G22B",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "G22B",
        "diaChiLapD": "",
        "longitude": 105.80902060128378,
        "latitude": 21.020323332149264,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5156,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82440276462039,
          21.00310191592895
        ]
      },
      "properties": {
        "tenDoiTuon": "03",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Khương Thượng",
        "tuDien": "LKT2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Làng Khương Thượng 2",
        "diaChiLapD": "",
        "longitude": 105.82440276462039,
        "latitude": 21.00310191592895,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5157,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82422037440409,
          21.002661210258943
        ]
      },
      "properties": {
        "tenDoiTuon": "04",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Khương Thượng",
        "tuDien": "LKT2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Làng Khương Thượng 2",
        "diaChiLapD": "",
        "longitude": 105.82422037440409,
        "latitude": 21.002661210258943,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5158,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82452346402268,
          21.00280894696339
        ]
      },
      "properties": {
        "tenDoiTuon": "3-9",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 189 Khương Thượng",
        "tuDien": "LKT2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Làng Khương Thượng 2",
        "diaChiLapD": "",
        "longitude": 105.82452346402268,
        "latitude": 21.00280894696339,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5159,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82466562110486,
          21.002753858717767
        ]
      },
      "properties": {
        "tenDoiTuon": "3-10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 189 Khương Thượng",
        "tuDien": "LKT2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Làng Khương Thượng 2",
        "diaChiLapD": "",
        "longitude": 105.82466562110486,
        "latitude": 21.002753858717767,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5160,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82488288003078,
          21.002686250388557
        ]
      },
      "properties": {
        "tenDoiTuon": "LKT2 3-11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 189 Khương Thượng",
        "tuDien": "LKT2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Làng Khương Thượng 2",
        "diaChiLapD": "",
        "longitude": 105.82488288003078,
        "latitude": 21.002686250388557,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5161,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82457174378897,
          21.002581081814878
        ]
      },
      "properties": {
        "tenDoiTuon": "07",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 191 Khương Thượng",
        "tuDien": "LKT2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Làng Khương Thượng 2",
        "diaChiLapD": "",
        "longitude": 105.82457174378897,
        "latitude": 21.002581081814878,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5162,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82492311316489,
          21.002498449313062
        ]
      },
      "properties": {
        "tenDoiTuon": "08",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 191 Khương Thượng",
        "tuDien": "LKT2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Làng Khương Thượng 2",
        "diaChiLapD": "",
        "longitude": 105.82492311316489,
        "latitude": 21.002498449313062,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5163,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82520742732027,
          21.002440856935483
        ]
      },
      "properties": {
        "tenDoiTuon": "09",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 191 Khương Thượng",
        "tuDien": "LKT2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Làng Khương Thượng 2",
        "diaChiLapD": "",
        "longitude": 105.82520742732027,
        "latitude": 21.002440856935483,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5164,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82523961383114,
          21.002626154070057
        ]
      },
      "properties": {
        "tenDoiTuon": "LKT2 9-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 191 Khương Thượng",
        "tuDien": "LKT2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Làng Khương Thượng 2",
        "diaChiLapD": "",
        "longitude": 105.82523961383114,
        "latitude": 21.002626154070057,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5165,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82562853414238,
          21.002475913167892
        ]
      },
      "properties": {
        "tenDoiTuon": "10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 191 Khương Thượng",
        "tuDien": "LKT2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Làng Khương Thượng 2",
        "diaChiLapD": "",
        "longitude": 105.82562853414238,
        "latitude": 21.002475913167892,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5166,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82562853414238,
          21.002706282489097
        ]
      },
      "properties": {
        "tenDoiTuon": "LKT2 10-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 191 Khương Thượng",
        "tuDien": "LKT2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Làng Khương Thượng 2",
        "diaChiLapD": "",
        "longitude": 105.82562853414238,
        "latitude": 21.002706282489097,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5167,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82583104094368,
          21.00245087300128
        ]
      },
      "properties": {
        "tenDoiTuon": "10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 388 Trường Chinh",
        "tuDien": "LKT2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Làng Khương Thượng 2",
        "diaChiLapD": "",
        "longitude": 105.82583104094368,
        "latitude": 21.00245087300128,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5168,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82613413056225,
          21.002518481437953
        ]
      },
      "properties": {
        "tenDoiTuon": "11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 191 Khương Thượng",
        "tuDien": "LKT2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Làng Khương Thượng 2",
        "diaChiLapD": "",
        "longitude": 105.82613413056225,
        "latitude": 21.002518481437953,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5169,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82651366316438,
          21.0026073739644
        ]
      },
      "properties": {
        "tenDoiTuon": "LKT2 11-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 191 Khương Thượng",
        "tuDien": "LKT2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Làng Khương Thượng 2",
        "diaChiLapD": "",
        "longitude": 105.82651366316438,
        "latitude": 21.0026073739644,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5170,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81085693860676,
          21.019686902434575
        ]
      },
      "properties": {
        "tenDoiTuon": "15",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 16/1 Huỳnh Thúc Kháng",
        "tuDien": "G22B",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "G22B",
        "diaChiLapD": "",
        "longitude": 105.81085693860676,
        "latitude": 21.019686902434575,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5171,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81056860113976,
          21.019246245757476
        ]
      },
      "properties": {
        "tenDoiTuon": "27",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 16/1 Huỳnh Thúc Kháng",
        "tuDien": "G22B",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "G22B",
        "diaChiLapD": "",
        "longitude": 105.81056860113976,
        "latitude": 21.019246245757476,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5172,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8103620710448,
          21.01935265444799
        ]
      },
      "properties": {
        "tenDoiTuon": "26",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 16/1 Huỳnh Thúc Kháng",
        "tuDien": "G22B",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "G22B",
        "diaChiLapD": "",
        "longitude": 105.8103620710448,
        "latitude": 21.01935265444799,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5173,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81002545381149,
          21.019517900736485
        ]
      },
      "properties": {
        "tenDoiTuon": "25",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 16/1 Huỳnh Thúc Kháng",
        "tuDien": "G22B",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "G22B",
        "diaChiLapD": "",
        "longitude": 105.81002545381149,
        "latitude": 21.019517900736485,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5174,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80962178135766,
          21.019679391249845
        ]
      },
      "properties": {
        "tenDoiTuon": "24",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 16/1 Huỳnh Thúc Kháng",
        "tuDien": "G22B",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "G22B",
        "diaChiLapD": "",
        "longitude": 105.80962178135766,
        "latitude": 21.019679391249845,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5175,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80937770033637,
          21.019828362963725
        ]
      },
      "properties": {
        "tenDoiTuon": "23",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 16/1 Huỳnh Thúc Kháng",
        "tuDien": "G22B",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "G22B",
        "diaChiLapD": "",
        "longitude": 105.80937770033637,
        "latitude": 21.019828362963725,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5176,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80903840089532,
          21.02002365338914
        ]
      },
      "properties": {
        "tenDoiTuon": "22",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 16/1 Huỳnh Thúc Kháng",
        "tuDien": "G22B",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "G22B",
        "diaChiLapD": "",
        "longitude": 105.80903840089532,
        "latitude": 21.02002365338914,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5177,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80930796289915,
          21.02015384686399
        ]
      },
      "properties": {
        "tenDoiTuon": "19-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 16/1 Huỳnh Thúc Kháng",
        "tuDien": "G22B",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "G22B",
        "diaChiLapD": "",
        "longitude": 105.80930796289915,
        "latitude": 21.02015384686399,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5178,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.809505105267,
          21.020073727816452
        ]
      },
      "properties": {
        "tenDoiTuon": "12",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 16/1 Huỳnh Thúc Kháng",
        "tuDien": "G22B",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "G22B",
        "diaChiLapD": "",
        "longitude": 105.809505105267,
        "latitude": 21.020073727816452,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5179,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80975455070377,
          21.01994103054803
        ]
      },
      "properties": {
        "tenDoiTuon": "25-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 16/1 Huỳnh Thúc Kháng",
        "tuDien": "G22B",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "G22B",
        "diaChiLapD": "",
        "longitude": 105.80975455070377,
        "latitude": 21.01994103054803,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5180,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81016895199755,
          21.019748243741297
        ]
      },
      "properties": {
        "tenDoiTuon": "25-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 16/1 Huỳnh Thúc Kháng",
        "tuDien": "G22B",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "G22B",
        "diaChiLapD": "",
        "longitude": 105.81016895199755,
        "latitude": 21.019748243741297,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5181,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81054714346884,
          21.019740732562166
        ]
      },
      "properties": {
        "tenDoiTuon": "25-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 16/1 Huỳnh Thúc Kháng",
        "tuDien": "G22B",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "G22B",
        "diaChiLapD": "",
        "longitude": 105.81054714346884,
        "latitude": 21.019740732562166,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5182,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80969956541814,
          21.019679391249834
        ]
      },
      "properties": {
        "tenDoiTuon": "24-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 16/1 Huỳnh Thúc Kháng",
        "tuDien": "G22B",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "G22B",
        "diaChiLapD": "",
        "longitude": 105.80969956541814,
        "latitude": 21.019679391249834,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5183,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82586222160195,
          21.00214819967602
        ]
      },
      "properties": {
        "tenDoiTuon": "12",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 388 Trường Chinh",
        "tuDien": "LKT2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Làng Khương Thượng 2",
        "diaChiLapD": "",
        "longitude": 105.82586222160195,
        "latitude": 21.00214819967602,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5184,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82618274557984,
          21.002228328352523
        ]
      },
      "properties": {
        "tenDoiTuon": "LKT2 12-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 388 Trường Chinh",
        "tuDien": "LKT2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Làng Khương Thượng 2",
        "diaChiLapD": "",
        "longitude": 105.82618274557984,
        "latitude": 21.002228328352523,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5185,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82577102650279,
          21.002144443643406
        ]
      },
      "properties": {
        "tenDoiTuon": "12-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 388 Trường Chinh",
        "tuDien": "LKT2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 7,
        "tuDien2": "Làng Khương Thượng 2",
        "diaChiLapD": "",
        "longitude": 105.82577102650279,
        "latitude": 21.002144443643406,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5186,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8255752252388,
          21.002145695654598
        ]
      },
      "properties": {
        "tenDoiTuon": "LKT2 12-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 388 Trường Chinh",
        "tuDien": "LKT2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 7,
        "tuDien2": "Làng Khương Thượng 2",
        "diaChiLapD": "",
        "longitude": 105.8255752252388,
        "latitude": 21.002145695654598,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5187,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82588099706513,
          21.001917829493383
        ]
      },
      "properties": {
        "tenDoiTuon": "13",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 388 Trường Chinh",
        "tuDien": "LKT2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Làng Khương Thượng 2",
        "diaChiLapD": "",
        "longitude": 105.82588099706513,
        "latitude": 21.001917829493383,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5188,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82584076393104,
          21.001820172460043
        ]
      },
      "properties": {
        "tenDoiTuon": "14",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 388 Trường Chinh",
        "tuDien": "LKT2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Làng Khương Thượng 2",
        "diaChiLapD": "",
        "longitude": 105.82584076393104,
        "latitude": 21.001820172460043,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5189,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82574956882289,
          21.001661166641874
        ]
      },
      "properties": {
        "tenDoiTuon": "LKT2 15",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 388 Trường Chinh",
        "tuDien": "LKT2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": "",
        "tuDien2": "Làng Khương Thượng 2",
        "diaChiLapD": "",
        "longitude": 105.82574956882289,
        "latitude": 21.001661166641874,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5190,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82560204732522,
          21.001891537221525
        ]
      },
      "properties": {
        "tenDoiTuon": "13-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 388 Trường Chinh",
        "tuDien": "LKT2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 5,
        "tuDien2": "Làng Khương Thượng 2",
        "diaChiLapD": "",
        "longitude": 105.82560204732522,
        "latitude": 21.001891537221525,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5191,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82538612950317,
          21.001955389873345
        ]
      },
      "properties": {
        "tenDoiTuon": "LKT2 13-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 388 Trường Chinh",
        "tuDien": "LKT2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 5,
        "tuDien2": "Làng Khương Thượng 2",
        "diaChiLapD": "",
        "longitude": 105.82538612950317,
        "latitude": 21.001955389873345,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5192,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82568519580114,
          21.001984186158158
        ]
      },
      "properties": {
        "tenDoiTuon": "13-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 388 Trường Chinh",
        "tuDien": "LKT2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 5,
        "tuDien2": "Làng Khương Thượng 2",
        "diaChiLapD": "",
        "longitude": 105.82568519580114,
        "latitude": 21.001984186158158,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5193,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8386037077792,
          21.01537751841
        ]
      },
      "properties": {
        "tenDoiTuon": "25",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 143 Phố Chợ Khâm Thiên",
        "tuDien": "T8A",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "T8A",
        "diaChiLapD": "",
        "longitude": 105.8386037077792,
        "latitude": 21.01537751841,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5194,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83846696223807,
          21.015506904753746
        ]
      },
      "properties": {
        "tenDoiTuon": "26",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 143 Phố Chợ Khâm Thiên",
        "tuDien": "T8A",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "T8A",
        "diaChiLapD": "",
        "longitude": 105.83846696223807,
        "latitude": 21.015506904753746,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5195,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83832346405202,
          21.015649621147674
        ]
      },
      "properties": {
        "tenDoiTuon": "27",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 143 Phố Chợ Khâm Thiên",
        "tuDien": "T8A",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "T8A",
        "diaChiLapD": "",
        "longitude": 105.83832346405202,
        "latitude": 21.015649621147674,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5196,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83867076300866,
          21.015602860219122
        ]
      },
      "properties": {
        "tenDoiTuon": "26-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 165 Phố Chợ Khâm Thiên",
        "tuDien": "T8A",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "T8A",
        "diaChiLapD": "",
        "longitude": 105.83867076300866,
        "latitude": 21.015602860219122,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5197,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8388066681585,
          21.015287774028035
        ]
      },
      "properties": {
        "tenDoiTuon": "24",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 165 Phố Chợ Khâm Thiên",
        "tuDien": "T8A",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "T8A",
        "diaChiLapD": "",
        "longitude": 105.8388066681585,
        "latitude": 21.015287774028035,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5198,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83908252921132,
          21.015098785187487
        ]
      },
      "properties": {
        "tenDoiTuon": "23",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 165 Phố Chợ Khâm Thiên",
        "tuDien": "T8A",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "T8A",
        "diaChiLapD": "",
        "longitude": 105.83908252921132,
        "latitude": 21.015098785187487,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5199,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83889606856678,
          21.015024482225787
        ]
      },
      "properties": {
        "tenDoiTuon": "23-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 165 Phố Chợ Khâm Thiên",
        "tuDien": "T8A",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "T8A",
        "diaChiLapD": "",
        "longitude": 105.83889606856678,
        "latitude": 21.015024482225787,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5200,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83904766049271,
          21.014912251639252
        ]
      },
      "properties": {
        "tenDoiTuon": "23-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 165 Phố Chợ Khâm Thiên",
        "tuDien": "T8A",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "T8A",
        "diaChiLapD": "",
        "longitude": 105.83904766049271,
        "latitude": 21.014912251639252,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5201,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83889075125894,
          21.014763275014264
        ]
      },
      "properties": {
        "tenDoiTuon": "23-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 165 Phố Chợ Khâm Thiên",
        "tuDien": "T8A",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "T8A",
        "diaChiLapD": "",
        "longitude": 105.83889075125894,
        "latitude": 21.014763275014264,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5202,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83913483228025,
          21.014839641201952
        ]
      },
      "properties": {
        "tenDoiTuon": "23-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 165 Phố Chợ Khâm Thiên",
        "tuDien": "T8A",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "T8A",
        "diaChiLapD": "",
        "longitude": 105.83913483228025,
        "latitude": 21.014839641201952,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5203,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83929229486073,
          21.01450550433472
        ]
      },
      "properties": {
        "tenDoiTuon": "23-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 165 Phố Chợ Khâm Thiên",
        "tuDien": "T8A",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "T8A",
        "diaChiLapD": "",
        "longitude": 105.83929229486073,
        "latitude": 21.01450550433472,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5204,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83946742620195,
          21.01462806920837
        ]
      },
      "properties": {
        "tenDoiTuon": "23-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 165 Phố Chợ Khâm Thiên",
        "tuDien": "T8A",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "T8A",
        "diaChiLapD": "",
        "longitude": 105.83946742620195,
        "latitude": 21.01462806920837,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5205,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83922602738839,
          21.014994877266616
        ]
      },
      "properties": {
        "tenDoiTuon": "23-7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 165 Phố Chợ Khâm Thiên",
        "tuDien": "T8A",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "T8A",
        "diaChiLapD": "",
        "longitude": 105.83922602738839,
        "latitude": 21.014994877266616,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5206,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83917826851979,
          21.015284251681955
        ]
      },
      "properties": {
        "tenDoiTuon": "22",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 165 Phố Chợ Khâm Thiên",
        "tuDien": "T8A",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "T8A",
        "diaChiLapD": "",
        "longitude": 105.83917826851979,
        "latitude": 21.015284251681955,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5207,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83934002127133,
          21.015277807097625
        ]
      },
      "properties": {
        "tenDoiTuon": "21",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 165 Phố Chợ Khâm Thiên",
        "tuDien": "T8A",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "T8A",
        "diaChiLapD": "",
        "longitude": 105.83934002127133,
        "latitude": 21.015277807097625,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5208,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83927707454967,
          21.015452447145975
        ]
      },
      "properties": {
        "tenDoiTuon": "22-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 165 Phố Chợ Khâm Thiên",
        "tuDien": "T8A",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "T8A",
        "diaChiLapD": "",
        "longitude": 105.83927707454967,
        "latitude": 21.015452447145975,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5209,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8394406892938,
          21.015399867368664
        ]
      },
      "properties": {
        "tenDoiTuon": "20",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 165 Phố Chợ Khâm Thiên",
        "tuDien": "T8A",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "T8A",
        "diaChiLapD": "",
        "longitude": 105.8394406892938,
        "latitude": 21.015399867368664,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5210,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83951310893876,
          21.015484996523515
        ]
      },
      "properties": {
        "tenDoiTuon": "10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 165 Phố Chợ Khâm Thiên",
        "tuDien": "T8A",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "T8A",
        "diaChiLapD": "",
        "longitude": 105.83951310893876,
        "latitude": 21.015484996523515,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5211,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83968687920468,
          21.015315240013276
        ]
      },
      "properties": {
        "tenDoiTuon": "10-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 165 Phố Chợ Khâm Thiên",
        "tuDien": "T8A",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "T8A",
        "diaChiLapD": "",
        "longitude": 105.83968687920468,
        "latitude": 21.015315240013276,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5212,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83976420213882,
          21.01512737358295
        ]
      },
      "properties": {
        "tenDoiTuon": "10-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 165 Phố Chợ Khâm Thiên",
        "tuDien": "T8A",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "T8A",
        "diaChiLapD": "",
        "longitude": 105.83976420213882,
        "latitude": 21.01512737358295,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5213,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83978937647095,
          21.015026800792914
        ]
      },
      "properties": {
        "tenDoiTuon": "10-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 165 Phố Chợ Khâm Thiên",
        "tuDien": "T8A",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "T8A",
        "diaChiLapD": "",
        "longitude": 105.83978937647095,
        "latitude": 21.015026800792914,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5214,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.824100010298,
          21.002441169938717
        ]
      },
      "properties": {
        "tenDoiTuon": "05",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Khương Thượng",
        "tuDien": "LKT2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Làng Khương Thượng 2",
        "diaChiLapD": "",
        "longitude": 105.824100010298,
        "latitude": 21.002441169938717,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5215,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82393639554488,
          21.00208810314085
        ]
      },
      "properties": {
        "tenDoiTuon": "LKT2 06",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Khương Thượng",
        "tuDien": "LKT2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Làng Khương Thượng 2",
        "diaChiLapD": "",
        "longitude": 105.82393639554488,
        "latitude": 21.00208810314085,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5216,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82431056372252,
          21.002373561467017
        ]
      },
      "properties": {
        "tenDoiTuon": "5-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 199 Khương Thượng",
        "tuDien": "LKT2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Làng Khương Thượng 2",
        "diaChiLapD": "",
        "longitude": 105.82431056372252,
        "latitude": 21.002373561467017,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5217,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82442455760544,
          21.00234100922964
        ]
      },
      "properties": {
        "tenDoiTuon": "5-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 199 Khương Thượng",
        "tuDien": "LKT2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Làng Khương Thượng 2",
        "diaChiLapD": "",
        "longitude": 105.82442455760544,
        "latitude": 21.00234100922964,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5218,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82472228279956,
          21.002278408752005
        ]
      },
      "properties": {
        "tenDoiTuon": "5-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 199 Khương Thượng",
        "tuDien": "LKT2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Làng Khương Thượng 2",
        "diaChiLapD": "",
        "longitude": 105.82472228279956,
        "latitude": 21.002278408752005,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5219,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82488053313719,
          21.0022421004637
        ]
      },
      "properties": {
        "tenDoiTuon": "LKT2 5-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 199 Khương Thượng",
        "tuDien": "LKT2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Làng Khương Thượng 2",
        "diaChiLapD": "",
        "longitude": 105.82488053313719,
        "latitude": 21.0022421004637,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5220,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80799133347533,
          21.01981740917358
        ]
      },
      "properties": {
        "tenDoiTuon": "LT5 01",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Nguyễn Chí Thanh",
        "tuDien": "LTG5",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "LT5",
        "diaChiLapD": "",
        "longitude": 105.80799133347533,
        "latitude": 21.01981740917358,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5221,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80785990523312,
          21.01958957000432
        ]
      },
      "properties": {
        "tenDoiTuon": "02",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Nguyễn Chí Thanh",
        "tuDien": "LTG5",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "LT5",
        "diaChiLapD": "",
        "longitude": 105.80785990523312,
        "latitude": 21.01958957000432,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5222,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8077365236141,
          21.019375501017283
        ]
      },
      "properties": {
        "tenDoiTuon": "03",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Nguyễn Chí Thanh",
        "tuDien": "LTG5",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "LT5",
        "diaChiLapD": "",
        "longitude": 105.8077365236141,
        "latitude": 21.019375501017283,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5223,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80762387083503,
          21.019168942931206
        ]
      },
      "properties": {
        "tenDoiTuon": "04",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Nguyễn Chí Thanh",
        "tuDien": "LTG5",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "LT5",
        "diaChiLapD": "",
        "longitude": 105.80762387083503,
        "latitude": 21.019168942931206,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5224,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80750853584823,
          21.0189673920382
        ]
      },
      "properties": {
        "tenDoiTuon": "05",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Nguyễn Chí Thanh",
        "tuDien": "LTG5",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "LT5",
        "diaChiLapD": "",
        "longitude": 105.80750853584823,
        "latitude": 21.0189673920382,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5225,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80736637875704,
          21.01873955157097
        ]
      },
      "properties": {
        "tenDoiTuon": "06",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Nguyễn Chí Thanh",
        "tuDien": "LTG5",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "LT5",
        "diaChiLapD": "",
        "longitude": 105.80736637875704,
        "latitude": 21.01873955157097,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5226,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80723897383541,
          21.01851045888094
        ]
      },
      "properties": {
        "tenDoiTuon": "07",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Nguyễn Chí Thanh",
        "tuDien": "LTG5",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "LT5",
        "diaChiLapD": "",
        "longitude": 105.80723897383541,
        "latitude": 21.01851045888094,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5227,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80709815785708,
          21.01827135083513
        ]
      },
      "properties": {
        "tenDoiTuon": "08",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Nguyễn Chí Thanh",
        "tuDien": "LTG5",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "LT5",
        "diaChiLapD": "",
        "longitude": 105.80709815785708,
        "latitude": 21.01827135083513,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5228,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8069640474071,
          21.018041005548877
        ]
      },
      "properties": {
        "tenDoiTuon": "09",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Nguyễn Chí Thanh",
        "tuDien": "LTG5",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "LT5",
        "diaChiLapD": "",
        "longitude": 105.8069640474071,
        "latitude": 21.018041005548877,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5229,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80682457254163,
          21.017808156147385
        ]
      },
      "properties": {
        "tenDoiTuon": "10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Nguyễn Chí Thanh",
        "tuDien": "LTG5",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "LT5",
        "diaChiLapD": "",
        "longitude": 105.80682457254163,
        "latitude": 21.017808156147385,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5230,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80667705104398,
          21.017546513106783
        ]
      },
      "properties": {
        "tenDoiTuon": "11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Nguyễn Chí Thanh",
        "tuDien": "LTG5",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "LT5",
        "diaChiLapD": "",
        "longitude": 105.80667705104398,
        "latitude": 21.017546513106783,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5231,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80653087065015,
          21.017329937419284
        ]
      },
      "properties": {
        "tenDoiTuon": "12",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Nguyễn Chí Thanh",
        "tuDien": "LTG5",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "LT5",
        "diaChiLapD": "",
        "longitude": 105.80653087065015,
        "latitude": 21.017329937419284,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5232,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80636457368932,
          21.017104598220623
        ]
      },
      "properties": {
        "tenDoiTuon": "13",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Nguyễn Chí Thanh",
        "tuDien": "LTG5",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "LT5",
        "diaChiLapD": "",
        "longitude": 105.80636457368932,
        "latitude": 21.017104598220623,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5233,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8061526791789,
          21.016878006793092
        ]
      },
      "properties": {
        "tenDoiTuon": "14",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Nguyễn Chí Thanh",
        "tuDien": "LTG5",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "LT5",
        "diaChiLapD": "",
        "longitude": 105.8061526791789,
        "latitude": 21.016878006793092,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5234,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80590725705369,
          21.016641399908803
        ]
      },
      "properties": {
        "tenDoiTuon": "15",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Nguyễn Chí Thanh",
        "tuDien": "LTG5",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "LT5",
        "diaChiLapD": "",
        "longitude": 105.80590725705369,
        "latitude": 21.016641399908803,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5235,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80571011469483,
          21.01644610505383
        ]
      },
      "properties": {
        "tenDoiTuon": "16",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Nguyễn Chí Thanh",
        "tuDien": "LTG5",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "LT5",
        "diaChiLapD": "",
        "longitude": 105.80571011469483,
        "latitude": 21.01644610505383,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5236,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8054673747774,
          21.016220764519666
        ]
      },
      "properties": {
        "tenDoiTuon": "17",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Nguyễn Chí Thanh",
        "tuDien": "LTG5",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "LT5",
        "diaChiLapD": "",
        "longitude": 105.8054673747774,
        "latitude": 21.016220764519666,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5237,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80524475142701,
          21.016024217219154
        ]
      },
      "properties": {
        "tenDoiTuon": "18",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Nguyễn Chí Thanh",
        "tuDien": "LTG5",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "LT5",
        "diaChiLapD": "",
        "longitude": 105.80524475142701,
        "latitude": 21.016024217219154,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5238,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80501944586888,
          21.015912798571133
        ]
      },
      "properties": {
        "tenDoiTuon": "LT5 19",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Nguyễn Chí Thanh",
        "tuDien": "LTG5",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "LT5",
        "diaChiLapD": "",
        "longitude": 105.80501944586888,
        "latitude": 21.015912798571133,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5239,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80701903271074,
          21.018858479332646
        ]
      },
      "properties": {
        "tenDoiTuon": "6-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 68 Nguyễn Chí Thanh",
        "tuDien": "LTG5",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "LT5",
        "diaChiLapD": "",
        "longitude": 105.80701903271074,
        "latitude": 21.018858479332646,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5240,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80672130749865,
          21.017883268898448
        ]
      },
      "properties": {
        "tenDoiTuon": "10-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 72 Nguyễn Chí Thanh",
        "tuDien": "LTG5",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "LT5",
        "diaChiLapD": "",
        "longitude": 105.80672130749865,
        "latitude": 21.017883268898448,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5241,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80629617737392,
          21.01816494137384
        ]
      },
      "properties": {
        "tenDoiTuon": "10-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 72 Nguyễn Chí Thanh",
        "tuDien": "LTG5",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "LT5",
        "diaChiLapD": "",
        "longitude": 105.80629617737392,
        "latitude": 21.01816494137384,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5242,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80649600194053,
          21.017728035840324
        ]
      },
      "properties": {
        "tenDoiTuon": "11-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "NGõ 76 Nguyễn Chí Thanh",
        "tuDien": "LTG5",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "BTLT",
        "chieuCao": 7,
        "tuDien2": "LT5",
        "diaChiLapD": "",
        "longitude": 105.80649600194053,
        "latitude": 21.017728035840324,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5243,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80633909271575,
          21.01754150558153
        ]
      },
      "properties": {
        "tenDoiTuon": "11-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "NGõ 76 Nguyễn Chí Thanh",
        "tuDien": "LTG5",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "BTLT",
        "chieuCao": 7,
        "tuDien2": "LT5",
        "diaChiLapD": "",
        "longitude": 105.80633909271575,
        "latitude": 21.01754150558153,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5244,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80618486569873,
          21.017343708138927
        ]
      },
      "properties": {
        "tenDoiTuon": "11-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "NGõ 76 Nguyễn Chí Thanh",
        "tuDien": "LTG5",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "BTLT",
        "chieuCao": 7,
        "tuDien2": "LT5",
        "diaChiLapD": "",
        "longitude": 105.80618486569873,
        "latitude": 21.017343708138927,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5245,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80603332088944,
          21.017137147238405
        ]
      },
      "properties": {
        "tenDoiTuon": "11-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "NGõ 76 Nguyễn Chí Thanh",
        "tuDien": "LTG5",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "BTLT",
        "chieuCao": 7,
        "tuDien2": "LT5",
        "diaChiLapD": "",
        "longitude": 105.80603332088944,
        "latitude": 21.017137147238405,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5246,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80664620564593,
          21.017639152323323
        ]
      },
      "properties": {
        "tenDoiTuon": "11-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 76 Nguyễn Chí Thanh",
        "tuDien": "LTG5",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 7,
        "tuDien2": "LT5",
        "diaChiLapD": "",
        "longitude": 105.80664620564593,
        "latitude": 21.017639152323323,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5247,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83937588658992,
          21.015775912664658
        ]
      },
      "properties": {
        "tenDoiTuon": "11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 202,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Lan Bá",
        "tuDien": "T8A",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "T8A",
        "diaChiLapD": "",
        "longitude": 105.83937588658992,
        "latitude": 21.015775912664658,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5248,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83924177613993,
          21.01607636753132
        ]
      },
      "properties": {
        "tenDoiTuon": "12",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Lan Bá",
        "tuDien": "T8A",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "T8A",
        "diaChiLapD": "",
        "longitude": 105.83924177613993,
        "latitude": 21.01607636753132,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5249,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83896819081549,
          21.01618903795048
        ]
      },
      "properties": {
        "tenDoiTuon": "12-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Lan Bá",
        "tuDien": "T8A",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "T8A",
        "diaChiLapD": "",
        "longitude": 105.83896819081549,
        "latitude": 21.01618903795048,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5250,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83891722884144,
          21.016081375107433
        ]
      },
      "properties": {
        "tenDoiTuon": "12-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Lan Bá",
        "tuDien": "T8A",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "T8A",
        "diaChiLapD": "",
        "longitude": 105.83891722884144,
        "latitude": 21.016081375107433,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5251,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83880189385464,
          21.016096397834797
        ]
      },
      "properties": {
        "tenDoiTuon": "12-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Lan Bá",
        "tuDien": "T8A",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "T8A",
        "diaChiLapD": "",
        "longitude": 105.83880189385464,
        "latitude": 21.016096397834797,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5252,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83869460550002,
          21.016266655300733
        ]
      },
      "properties": {
        "tenDoiTuon": "12-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Lan Bá",
        "tuDien": "T8A",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "T8A",
        "diaChiLapD": "",
        "longitude": 105.83869460550002,
        "latitude": 21.016266655300733,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5253,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83902451720502,
          21.016201556880592
        ]
      },
      "properties": {
        "tenDoiTuon": "12-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Lan Bá",
        "tuDien": "T8A",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "T8A",
        "diaChiLapD": "",
        "longitude": 105.83902451720502,
        "latitude": 21.016201556880592,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5254,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83931687800161,
          21.016221587167248
        ]
      },
      "properties": {
        "tenDoiTuon": "13",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Lan Bá",
        "tuDien": "T8A",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "T8A",
        "diaChiLapD": "",
        "longitude": 105.83931687800161,
        "latitude": 21.016221587167248,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5255,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83935442892796,
          21.016409370968475
        ]
      },
      "properties": {
        "tenDoiTuon": "13-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Lan Bá",
        "tuDien": "T8A",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "T8A",
        "diaChiLapD": "",
        "longitude": 105.83935442892796,
        "latitude": 21.016409370968475,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5256,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83944562403612,
          21.016592146975277
        ]
      },
      "properties": {
        "tenDoiTuon": "14",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Lan Bá",
        "tuDien": "T8A",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "T8A",
        "diaChiLapD": "",
        "longitude": 105.83944562403612,
        "latitude": 21.016592146975277,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5257,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83957705227834,
          21.01675990009906
        ]
      },
      "properties": {
        "tenDoiTuon": "14-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Lan Bá",
        "tuDien": "T8A",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "T8A",
        "diaChiLapD": "",
        "longitude": 105.83957705227834,
        "latitude": 21.01675990009906,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5258,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83972189156829,
          21.01687257000097
        ]
      },
      "properties": {
        "tenDoiTuon": "15",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Lan Bá",
        "tuDien": "T8A",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "T8A",
        "diaChiLapD": "",
        "longitude": 105.83972189156829,
        "latitude": 21.01687257000097,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5259,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83976480691013,
          21.01709039823846
        ]
      },
      "properties": {
        "tenDoiTuon": "16",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Lan Bá",
        "tuDien": "T8A",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "T8A",
        "diaChiLapD": "",
        "longitude": 105.83976480691013,
        "latitude": 21.01709039823846,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5260,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83979982465588,
          21.017329050054567
        ]
      },
      "properties": {
        "tenDoiTuon": "17",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 202,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Lan Bá",
        "tuDien": "T8A",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "T8A",
        "diaChiLapD": "",
        "longitude": 105.83979982465588,
        "latitude": 21.017329050054567,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5261,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83985868422602,
          21.017581136548163
        ]
      },
      "properties": {
        "tenDoiTuon": "18",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 202,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Lan Bá",
        "tuDien": "T8A",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "T8A",
        "diaChiLapD": "",
        "longitude": 105.83985868422602,
        "latitude": 21.017581136548163,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5262,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83991266190563,
          21.017842212356342
        ]
      },
      "properties": {
        "tenDoiTuon": "19",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 202,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Lan Bá",
        "tuDien": "T8A",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "T8A",
        "diaChiLapD": "",
        "longitude": 105.83991266190563,
        "latitude": 21.017842212356342,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5263,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81117813306923,
          21.02459976061219
        ]
      },
      "properties": {
        "tenDoiTuon": "TCĐC 01",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Nguyễn Chí Thanh",
        "tuDien": "TCĐC",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "TCĐC",
        "diaChiLapD": "",
        "longitude": 105.81117813306923,
        "latitude": 21.02459976061219,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5264,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81093271097099,
          21.02420543601756
        ]
      },
      "properties": {
        "tenDoiTuon": "02",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Nguyễn Chí Thanh",
        "tuDien": "TCĐC",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "TCĐC",
        "diaChiLapD": "",
        "longitude": 105.81093271097099,
        "latitude": 21.02420543601756,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5265,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81073959192373,
          21.023857428048334
        ]
      },
      "properties": {
        "tenDoiTuon": "03",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Nguyễn Chí Thanh",
        "tuDien": "TCĐC",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "TCĐC",
        "diaChiLapD": "",
        "longitude": 105.81073959192373,
        "latitude": 21.023857428048334,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5266,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81060548147376,
          21.02363209871373
        ]
      },
      "properties": {
        "tenDoiTuon": "04",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Nguyễn Chí Thanh",
        "tuDien": "TCĐC",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "TCĐC",
        "diaChiLapD": "",
        "longitude": 105.81060548147376,
        "latitude": 21.02363209871373,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5267,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81046868880705,
          21.023389243383647
        ]
      },
      "properties": {
        "tenDoiTuon": "05",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Nguyễn Chí Thanh",
        "tuDien": "TCĐC",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "TCĐC",
        "diaChiLapD": "",
        "longitude": 105.81046868880705,
        "latitude": 21.023389243383647,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5268,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81032116731836,
          21.02313637297923
        ]
      },
      "properties": {
        "tenDoiTuon": "06",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Nguyễn Chí Thanh",
        "tuDien": "TCĐC",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "TCĐC",
        "diaChiLapD": "",
        "longitude": 105.81032116731836,
        "latitude": 21.02313637297923,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5269,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81018705685939,
          21.022913546228494
        ]
      },
      "properties": {
        "tenDoiTuon": "07",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Nguyễn Chí Thanh",
        "tuDien": "TCĐC",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "TCĐC",
        "diaChiLapD": "",
        "longitude": 105.81018705685939,
        "latitude": 21.022913546228494,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5270,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81004758199393,
          21.022663178695982
        ]
      },
      "properties": {
        "tenDoiTuon": "08",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Nguyễn Chí Thanh",
        "tuDien": "TCĐC",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "TCĐC",
        "diaChiLapD": "",
        "longitude": 105.81004758199393,
        "latitude": 21.022663178695982,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5271,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80994297584706,
          21.022482913811814
        ]
      },
      "properties": {
        "tenDoiTuon": "09",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Nguyễn Chí Thanh",
        "tuDien": "TCĐC",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "TCĐC",
        "diaChiLapD": "",
        "longitude": 105.80994297584706,
        "latitude": 21.022482913811814,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5272,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80981691202031,
          21.02226008608438
        ]
      },
      "properties": {
        "tenDoiTuon": "10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Nguyễn Chí Thanh",
        "tuDien": "TCĐC",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "TCĐC",
        "diaChiLapD": "",
        "longitude": 105.80981691202031,
        "latitude": 21.02226008608438,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5273,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80967743715485,
          21.02204226539903
        ]
      },
      "properties": {
        "tenDoiTuon": "11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Nguyễn Chí Thanh",
        "tuDien": "TCĐC",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "TCĐC",
        "diaChiLapD": "",
        "longitude": 105.80967743715485,
        "latitude": 21.02204226539903,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5274,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80955137332808,
          21.021814429629583
        ]
      },
      "properties": {
        "tenDoiTuon": "12",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Nguyễn Chí Thanh",
        "tuDien": "TCĐC",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "TCĐC",
        "diaChiLapD": "",
        "longitude": 105.80955137332808,
        "latitude": 21.021814429629583,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5275,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80942530950134,
          21.021589097207944
        ]
      },
      "properties": {
        "tenDoiTuon": "13",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Nguyễn Chí Thanh",
        "tuDien": "TCĐC",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "TCĐC",
        "diaChiLapD": "",
        "longitude": 105.80942530950134,
        "latitude": 21.021589097207944,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5276,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80930461009905,
          21.021363764444903
        ]
      },
      "properties": {
        "tenDoiTuon": "14",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Nguyễn Chí Thanh",
        "tuDien": "TCĐC",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "TCĐC",
        "diaChiLapD": "",
        "longitude": 105.80930461009905,
        "latitude": 21.021363764444903,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5277,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80917586406456,
          21.021125912826495
        ]
      },
      "properties": {
        "tenDoiTuon": "15",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Nguyễn Chí Thanh",
        "tuDien": "TCĐC",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "TCĐC",
        "diaChiLapD": "",
        "longitude": 105.80917586406456,
        "latitude": 21.021125912826495,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5278,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80903102477463,
          21.02088806082782
        ]
      },
      "properties": {
        "tenDoiTuon": "16",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Nguyễn Chí Thanh",
        "tuDien": "TCĐC",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "TCĐC",
        "diaChiLapD": "",
        "longitude": 105.80903102477463,
        "latitude": 21.02088806082782,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5279,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80889020879629,
          21.02064394917194
        ]
      },
      "properties": {
        "tenDoiTuon": "17",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Nguyễn Chí Thanh",
        "tuDien": "TCĐC",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "TCĐC",
        "diaChiLapD": "",
        "longitude": 105.80889020879629,
        "latitude": 21.02064394917194,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5280,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80876570957875,
          21.02042925577048
        ]
      },
      "properties": {
        "tenDoiTuon": "18",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Nguyễn Chí Thanh",
        "tuDien": "TCĐC",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "TCĐC",
        "diaChiLapD": "",
        "longitude": 105.80876570957875,
        "latitude": 21.02042925577048,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5281,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8086206467745,
          21.020179509995337
        ]
      },
      "properties": {
        "tenDoiTuon": "TCĐC 19",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Nguyễn Chí Thanh",
        "tuDien": "TCĐC",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "TCĐC",
        "diaChiLapD": "",
        "longitude": 105.8086206467745,
        "latitude": 21.020179509995337,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5282,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81129212697908,
          21.024437023604335
        ]
      },
      "properties": {
        "tenDoiTuon": "1-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 59 Nguyễn Chí Thanh",
        "tuDien": "TCĐC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "TCĐC",
        "diaChiLapD": "",
        "longitude": 105.81129212697908,
        "latitude": 21.024437023604335,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5283,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81132699569773,
          21.02415035567382
        ]
      },
      "properties": {
        "tenDoiTuon": "2-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 65 Nguyễn Chí Thanh",
        "tuDien": "TCĐC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "TCĐC",
        "diaChiLapD": "",
        "longitude": 105.81132699569773,
        "latitude": 21.02415035567382,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5284,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83972092666626,
          21.015564465721962
        ]
      },
      "properties": {
        "tenDoiTuon": "09",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 202,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Hoàng An",
        "tuDien": "T8A",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "T8A",
        "diaChiLapD": "",
        "longitude": 105.83972092666626,
        "latitude": 21.015564465721962,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5285,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83990987739048,
          21.015635074467035
        ]
      },
      "properties": {
        "tenDoiTuon": "08",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 202,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Hoàng An",
        "tuDien": "T8A",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "T8A",
        "diaChiLapD": "",
        "longitude": 105.83990987739048,
        "latitude": 21.015635074467035,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5286,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83979720334484,
          21.01576468859456
        ]
      },
      "properties": {
        "tenDoiTuon": "8-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 202,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Hoàng An",
        "tuDien": "T8A",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "T8A",
        "diaChiLapD": "",
        "longitude": 105.83979720334484,
        "latitude": 21.01576468859456,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5287,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8396886036946,
          21.0159385458315
        ]
      },
      "properties": {
        "tenDoiTuon": "8-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 202,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Hoàng An",
        "tuDien": "T8A",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "T8A",
        "diaChiLapD": "",
        "longitude": 105.8396886036946,
        "latitude": 21.0159385458315,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5288,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84008745214712,
          21.01553440349069
        ]
      },
      "properties": {
        "tenDoiTuon": "07",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Hoàng An",
        "tuDien": "T8A",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "T8A",
        "diaChiLapD": "",
        "longitude": 105.84008745214712,
        "latitude": 21.01553440349069,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5289,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84022081176117,
          21.015584444228846
        ]
      },
      "properties": {
        "tenDoiTuon": "7-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Hoàng An",
        "tuDien": "T8A",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "T8A",
        "diaChiLapD": "",
        "longitude": 105.84022081176117,
        "latitude": 21.015584444228846,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5290,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84013597578424,
          21.015312568749717
        ]
      },
      "properties": {
        "tenDoiTuon": "06",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Hoàng An",
        "tuDien": "T8A",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "T8A",
        "diaChiLapD": "",
        "longitude": 105.84013597578424,
        "latitude": 21.015312568749717,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5291,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84016488398383,
          21.015155543497148
        ]
      },
      "properties": {
        "tenDoiTuon": "6-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Hoàng An",
        "tuDien": "T8A",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "T8A",
        "diaChiLapD": "",
        "longitude": 105.84016488398383,
        "latitude": 21.015155543497148,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5292,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84016334613887,
          21.015062956110924
        ]
      },
      "properties": {
        "tenDoiTuon": "6-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Hoàng An",
        "tuDien": "T8A",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "T8A",
        "diaChiLapD": "",
        "longitude": 105.84016334613887,
        "latitude": 21.015062956110924,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5293,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83995090209775,
          21.015028670066332
        ]
      },
      "properties": {
        "tenDoiTuon": "6-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Hoàng An",
        "tuDien": "T8A",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "T8A",
        "diaChiLapD": "",
        "longitude": 105.83995090209775,
        "latitude": 21.015028670066332,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5294,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8405173977315,
          21.015196909620943
        ]
      },
      "properties": {
        "tenDoiTuon": "5-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Hoàng An",
        "tuDien": "T8A",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "T8A",
        "diaChiLapD": "",
        "longitude": 105.8405173977315,
        "latitude": 21.015196909620943,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5295,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84053885540241,
          21.01540848080757
        ]
      },
      "properties": {
        "tenDoiTuon": "04",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Hoàng An",
        "tuDien": "T8A",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "T8A",
        "diaChiLapD": "",
        "longitude": 105.84053885540241,
        "latitude": 21.01540848080757,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5296,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84042620262336,
          21.015398465609962
        ]
      },
      "properties": {
        "tenDoiTuon": "05",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Hoàng An",
        "tuDien": "T8A",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "T8A",
        "diaChiLapD": "",
        "longitude": 105.84042620262336,
        "latitude": 21.015398465609962,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5297,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84059518179197,
          21.015273275586566
        ]
      },
      "properties": {
        "tenDoiTuon": "03",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Hoàng An",
        "tuDien": "T8A",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "T8A",
        "diaChiLapD": "",
        "longitude": 105.84059518179197,
        "latitude": 21.015273275586566,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5298,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84086876711639,
          21.015241978064076
        ]
      },
      "properties": {
        "tenDoiTuon": "02",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Hoàng An",
        "tuDien": "T8A",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "T8A",
        "diaChiLapD": "",
        "longitude": 105.84086876711639,
        "latitude": 21.015241978064076,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5299,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84114235243183,
          21.015265764182033
        ]
      },
      "properties": {
        "tenDoiTuon": "T8A 01",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Hoàng An",
        "tuDien": "T8A",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "T8A",
        "diaChiLapD": "",
        "longitude": 105.84114235243183,
        "latitude": 21.015265764182033,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5300,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84088486036283,
          21.015074223232894
        ]
      },
      "properties": {
        "tenDoiTuon": "2-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Hoàng An",
        "tuDien": "T8A",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "T8A",
        "diaChiLapD": "",
        "longitude": 105.84088486036283,
        "latitude": 21.015074223232894,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5301,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84087815484348,
          21.014964055778748
        ]
      },
      "properties": {
        "tenDoiTuon": "2-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Hoàng An",
        "tuDien": "T8A",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "T8A",
        "diaChiLapD": "",
        "longitude": 105.84087815484348,
        "latitude": 21.014964055778748,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5302,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84086474379578,
          21.014906468213017
        ]
      },
      "properties": {
        "tenDoiTuon": "2-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Hoàng An",
        "tuDien": "T8A",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "T8A",
        "diaChiLapD": "",
        "longitude": 105.84086474379578,
        "latitude": 21.014906468213017,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5303,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84089156589118,
          21.01486515538099
        ]
      },
      "properties": {
        "tenDoiTuon": "2-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Hoàng An",
        "tuDien": "T8A",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "T8A",
        "diaChiLapD": "",
        "longitude": 105.84089156589118,
        "latitude": 21.01486515538099,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5304,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84084328612487,
          21.014756239676917
        ]
      },
      "properties": {
        "tenDoiTuon": "2-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Hoàng An",
        "tuDien": "T8A",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "T8A",
        "diaChiLapD": "",
        "longitude": 105.84084328612487,
        "latitude": 21.014756239676917,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5305,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.84107659291813,
          21.014766428641337
        ]
      },
      "properties": {
        "tenDoiTuon": "2-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Hoàng An",
        "tuDien": "T8A",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "T8A",
        "diaChiLapD": "",
        "longitude": 105.84107659291813,
        "latitude": 21.014766428641337,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5306,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81028093418425,
          21.022609349621824
        ]
      },
      "properties": {
        "tenDoiTuon": "8-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 79 Nguyễn Chí Thanh",
        "tuDien": "TCĐC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "BTLT",
        "chieuCao": 7,
        "tuDien2": "TCĐC",
        "diaChiLapD": "",
        "longitude": 105.81028093418425,
        "latitude": 21.022609349621824,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5307,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81044454892839,
          21.022455373326363
        ]
      },
      "properties": {
        "tenDoiTuon": "8-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 79 Nguyễn Chí Thanh",
        "tuDien": "TCĐC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "BTLT",
        "chieuCao": 7,
        "tuDien2": "TCĐC",
        "diaChiLapD": "",
        "longitude": 105.81044454892839,
        "latitude": 21.022455373326363,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5308,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81072886308377,
          21.02224381213804
        ]
      },
      "properties": {
        "tenDoiTuon": "8-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 79 Nguyễn Chí Thanh",
        "tuDien": "TCĐC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "BTLT",
        "chieuCao": 7,
        "tuDien2": "TCĐC",
        "diaChiLapD": "",
        "longitude": 105.81072886308377,
        "latitude": 21.02224381213804,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5309,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81098099073728,
          21.0221161241753
        ]
      },
      "properties": {
        "tenDoiTuon": "8-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 79 Nguyễn Chí Thanh",
        "tuDien": "TCĐC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "BTLT",
        "chieuCao": 7,
        "tuDien2": "TCĐC",
        "diaChiLapD": "",
        "longitude": 105.81098099073728,
        "latitude": 21.0221161241753,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5310,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81120361408769,
          21.022017228520973
        ]
      },
      "properties": {
        "tenDoiTuon": "8-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 79 Nguyễn Chí Thanh",
        "tuDien": "TCĐC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "BTLT",
        "chieuCao": 7,
        "tuDien2": "TCĐC",
        "diaChiLapD": "",
        "longitude": 105.81120361408769,
        "latitude": 21.022017228520973,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5311,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81067790111871,
          21.021674222832008
        ]
      },
      "properties": {
        "tenDoiTuon": "8-7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 79 Nguyễn Chí Thanh",
        "tuDien": "TCĐC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "TCĐC",
        "diaChiLapD": "",
        "longitude": 105.81067790111871,
        "latitude": 21.021674222832008,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5312,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81081871708805,
          21.021913325420943
        ]
      },
      "properties": {
        "tenDoiTuon": "8-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 79 Nguyễn Chí Thanh",
        "tuDien": "TCĐC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "TCĐC",
        "diaChiLapD": "",
        "longitude": 105.81081871708805,
        "latitude": 21.021913325420943,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5313,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81084956249506,
          21.02154403068476
        ]
      },
      "properties": {
        "tenDoiTuon": "8-8",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 79 Nguyễn Chí Thanh",
        "tuDien": "TCĐC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "TCĐC",
        "diaChiLapD": "",
        "longitude": 105.81084956249506,
        "latitude": 21.02154403068476,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5314,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81085995603883,
          21.02479160227653
        ]
      },
      "properties": {
        "tenDoiTuon": "02",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Nguyễn Chí Thanh",
        "tuDien": "LTG4",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "LT4",
        "diaChiLapD": "",
        "longitude": 105.81085995603883,
        "latitude": 21.02479160227653,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5315,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81073389221207,
          21.02457879258073
        ]
      },
      "properties": {
        "tenDoiTuon": "03",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Nguyễn Chí Thanh",
        "tuDien": "LTG4",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "LT4",
        "diaChiLapD": "",
        "longitude": 105.81073389221207,
        "latitude": 21.02457879258073,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5316,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8105944173466,
          21.02436347893261
        ]
      },
      "properties": {
        "tenDoiTuon": "04",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Nguyễn Chí Thanh",
        "tuDien": "LTG4",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "LT4",
        "diaChiLapD": "",
        "longitude": 105.8105944173466,
        "latitude": 21.02436347893261,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5317,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81043348480122,
          21.024068048072436
        ]
      },
      "properties": {
        "tenDoiTuon": "05",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Nguyễn Chí Thanh",
        "tuDien": "LTG4",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "LT4",
        "diaChiLapD": "",
        "longitude": 105.81043348480122,
        "latitude": 21.024068048072436,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5318,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81028059888808,
          21.02379264589558
        ]
      },
      "properties": {
        "tenDoiTuon": "06",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Nguyễn Chí Thanh",
        "tuDien": "LTG4",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "LT4",
        "diaChiLapD": "",
        "longitude": 105.81028059888808,
        "latitude": 21.02379264589558,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5319,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8101330773904,
          21.023529761525626
        ]
      },
      "properties": {
        "tenDoiTuon": "07",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Nguyễn Chí Thanh",
        "tuDien": "LTG4",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "LT4",
        "diaChiLapD": "",
        "longitude": 105.8101330773904,
        "latitude": 21.023529761525626,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5320,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80991313625672,
          21.023179248309862
        ]
      },
      "properties": {
        "tenDoiTuon": "08",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Nguyễn Chí Thanh",
        "tuDien": "LTG4",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "LT4",
        "diaChiLapD": "",
        "longitude": 105.80991313625672,
        "latitude": 21.023179248309862,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5321,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80980048347767,
          21.02297645100165
        ]
      },
      "properties": {
        "tenDoiTuon": "09",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Nguyễn Chí Thanh",
        "tuDien": "LTG4",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "LT4",
        "diaChiLapD": "",
        "longitude": 105.80980048347767,
        "latitude": 21.02297645100165,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5322,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80965296198004,
          21.022738601956057
        ]
      },
      "properties": {
        "tenDoiTuon": "10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Nguyễn Chí Thanh",
        "tuDien": "LTG4",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "LT4",
        "diaChiLapD": "",
        "longitude": 105.80965296198004,
        "latitude": 21.022738601956057,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5323,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80956981350407,
          21.02256835187898
        ]
      },
      "properties": {
        "tenDoiTuon": "11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Nguyễn Chí Thanh",
        "tuDien": "LTG4",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "LT4",
        "diaChiLapD": "",
        "longitude": 105.80956981350407,
        "latitude": 21.02256835187898,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5324,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8094290681064,
          21.022320882768742
        ]
      },
      "properties": {
        "tenDoiTuon": "12",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Nguyễn Chí Thanh",
        "tuDien": "LTG4",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "LT4",
        "diaChiLapD": "",
        "longitude": 105.8094290681064,
        "latitude": 21.022320882768742,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5325,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80928034667507,
          21.022065045659904
        ]
      },
      "properties": {
        "tenDoiTuon": "13",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Nguyễn Chí Thanh",
        "tuDien": "LTG4",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "LT4",
        "diaChiLapD": "",
        "longitude": 105.80928034667507,
        "latitude": 21.022065045659904,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5326,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8091316252438,
          21.02180920811208
        ]
      },
      "properties": {
        "tenDoiTuon": "14",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Nguyễn Chí Thanh",
        "tuDien": "LTG4",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "LT4",
        "diaChiLapD": "",
        "longitude": 105.8091316252438,
        "latitude": 21.02180920811208,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5327,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80898290381248,
          21.02155337012518
        ]
      },
      "properties": {
        "tenDoiTuon": "15",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Nguyễn Chí Thanh",
        "tuDien": "LTG4",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "LT4",
        "diaChiLapD": "",
        "longitude": 105.80898290381248,
        "latitude": 21.02155337012518,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5328,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80883418238118,
          21.02129753169932
        ]
      },
      "properties": {
        "tenDoiTuon": "16",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Nguyễn Chí Thanh",
        "tuDien": "LTG4",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "LT4",
        "diaChiLapD": "",
        "longitude": 105.80883418238118,
        "latitude": 21.02129753169932,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5329,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80868546094987,
          21.021041692834434
        ]
      },
      "properties": {
        "tenDoiTuon": "17",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Nguyễn Chí Thanh",
        "tuDien": "LTG4",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "LT4",
        "diaChiLapD": "",
        "longitude": 105.80868546094987,
        "latitude": 21.021041692834434,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5330,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80853673951859,
          21.020785853530565
        ]
      },
      "properties": {
        "tenDoiTuon": "18",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Nguyễn Chí Thanh",
        "tuDien": "LTG4",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "LT4",
        "diaChiLapD": "",
        "longitude": 105.80853673951859,
        "latitude": 21.020785853530565,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5331,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80838801808727,
          21.020530013787667
        ]
      },
      "properties": {
        "tenDoiTuon": "19",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Nguyễn Chí Thanh",
        "tuDien": "LTG4",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "LT4",
        "diaChiLapD": "",
        "longitude": 105.80838801808727,
        "latitude": 21.020530013787667,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5332,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80823929665598,
          21.0202741736058
        ]
      },
      "properties": {
        "tenDoiTuon": "20",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Nguyễn Chí Thanh",
        "tuDien": "LTG4",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "LT4",
        "diaChiLapD": "",
        "longitude": 105.80823929665598,
        "latitude": 21.0202741736058,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5333,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80809057522468,
          21.020018332984925
        ]
      },
      "properties": {
        "tenDoiTuon": "21",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Nguyễn Chí Thanh",
        "tuDien": "LTG4",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "LT4",
        "diaChiLapD": "",
        "longitude": 105.80809057522468,
        "latitude": 21.020018332984925,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5334,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81112415362718,
          21.02486170422758
        ]
      },
      "properties": {
        "tenDoiTuon": "01",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - đèn pha",
        "tenDuongPh": "Nguyễn Chí Thanh",
        "tuDien": "LTG4",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 17,
        "tuDien2": "LT4",
        "diaChiLapD": "",
        "longitude": 105.81112415362718,
        "latitude": 21.02486170422758,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5335,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82998980604195,
          21.001827371535366
        ]
      },
      "properties": {
        "tenDoiTuon": "6-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Tập thể đại học Y ( ngõ 1 Tôn Thất Tùng)",
        "tuDien": "KT4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "KT4",
        "diaChiLapD": "",
        "longitude": 105.82998980604195,
        "latitude": 21.001827371535366,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5336,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83044846378141,
          21.001714690269612
        ]
      },
      "properties": {
        "tenDoiTuon": "6-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Tập thể đại học Y ( ngõ 1 Tôn Thất Tùng)",
        "tuDien": "KT4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "KT4",
        "diaChiLapD": "",
        "longitude": 105.83044846378141,
        "latitude": 21.001714690269612,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5337,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83073867088459,
          21.00152103690147
        ]
      },
      "properties": {
        "tenDoiTuon": "6-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Tập thể đại học Y ( ngõ 1 Tôn Thất Tùng)",
        "tuDien": "KT4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "KT4",
        "diaChiLapD": "",
        "longitude": 105.83073867088459,
        "latitude": 21.00152103690147,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5338,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83223900597314,
          21.001041887437744
        ]
      },
      "properties": {
        "tenDoiTuon": "6-8",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Tập thể đại học Y ( ngõ 1 Tôn Thất Tùng)",
        "tuDien": "KT4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "KT4",
        "diaChiLapD": "",
        "longitude": 105.83223900597314,
        "latitude": 21.001041887437744,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5339,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83194396297779,
          21.001129528824812
        ]
      },
      "properties": {
        "tenDoiTuon": "6-7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Tập thể đại học Y ( ngõ 1 Tôn Thất Tùng)",
        "tuDien": "KT4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "KT4",
        "diaChiLapD": "",
        "longitude": 105.83194396297779,
        "latitude": 21.001129528824812,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5340,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83165160219914,
          21.00121216208538
        ]
      },
      "properties": {
        "tenDoiTuon": "6-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Tập thể đại học Y ( ngõ 1 Tôn Thất Tùng)",
        "tuDien": "KT4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "KT4",
        "diaChiLapD": "",
        "longitude": 105.83165160219914,
        "latitude": 21.00121216208538,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5341,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83135119478833,
          21.00129980337165
        ]
      },
      "properties": {
        "tenDoiTuon": "6-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Tập thể đại học Y ( ngõ 1 Tôn Thất Tùng)",
        "tuDien": "KT4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "KT4",
        "diaChiLapD": "",
        "longitude": 105.83135119478833,
        "latitude": 21.00129980337165,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5342,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83105454584258,
          21.001390083203205
        ]
      },
      "properties": {
        "tenDoiTuon": "6-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Tập thể đại học Y ( ngõ 1 Tôn Thất Tùng)",
        "tuDien": "KT4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "KT4",
        "diaChiLapD": "",
        "longitude": 105.83105454584258,
        "latitude": 21.001390083203205,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5343,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8082662599396,
          21.02003210345463
        ]
      },
      "properties": {
        "tenDoiTuon": "22",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Nguyễn Chí Thanh",
        "tuDien": "LTG4",
        "chuDauTu": "SoXayDung",
        "loaiCot": "DC-05",
        "chieuCao": 4,
        "tuDien2": "LT4",
        "diaChiLapD": "",
        "longitude": 105.8082662599396,
        "latitude": 21.02003210345463,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5344,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8084486501469,
          21.02016229692125
        ]
      },
      "properties": {
        "tenDoiTuon": "23",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Nguyễn Chí Thanh",
        "tuDien": "LTG4",
        "chuDauTu": "SoXayDung",
        "loaiCot": "DC-05",
        "chieuCao": 4,
        "tuDien2": "LT4",
        "diaChiLapD": "",
        "longitude": 105.8084486501469,
        "latitude": 21.02016229692125,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5345,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80851838758412,
          21.020492787519718
        ]
      },
      "properties": {
        "tenDoiTuon": "24",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Nguyễn Chí Thanh",
        "tuDien": "LTG4",
        "chuDauTu": "SoXayDung",
        "loaiCot": "DC-05",
        "chieuCao": 4,
        "tuDien2": "LT4",
        "diaChiLapD": "",
        "longitude": 105.80851838758412,
        "latitude": 21.020492787519718,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5346,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80876515081316,
          21.02066804738903
        ]
      },
      "properties": {
        "tenDoiTuon": "25",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Nguyễn Chí Thanh",
        "tuDien": "LTG4",
        "chuDauTu": "SoXayDung",
        "loaiCot": "DC-05",
        "chieuCao": 4,
        "tuDien2": "LT4",
        "diaChiLapD": "",
        "longitude": 105.80876515081316,
        "latitude": 21.02066804738903,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5347,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80879733732402,
          21.020948462749764
        ]
      },
      "properties": {
        "tenDoiTuon": "26",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Nguyễn Chí Thanh",
        "tuDien": "LTG4",
        "chuDauTu": "SoXayDung",
        "loaiCot": "DC-05",
        "chieuCao": 4,
        "tuDien2": "LT4",
        "diaChiLapD": "",
        "longitude": 105.80879733732402,
        "latitude": 21.020948462749764,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5348,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8090923803104,
          21.02146422534084
        ]
      },
      "properties": {
        "tenDoiTuon": "27",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Nguyễn Chí Thanh",
        "tuDien": "LTG4",
        "chuDauTu": "SoXayDung",
        "loaiCot": "DC-05",
        "chieuCao": 4,
        "tuDien2": "LT4",
        "diaChiLapD": "",
        "longitude": 105.8090923803104,
        "latitude": 21.02146422534084,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5349,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80932305029299,
          21.021684550564263
        ]
      },
      "properties": {
        "tenDoiTuon": "28",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Nguyễn Chí Thanh",
        "tuDien": "LTG4",
        "chuDauTu": "SoXayDung",
        "loaiCot": "DC-05",
        "chieuCao": 4,
        "tuDien2": "LT4",
        "diaChiLapD": "",
        "longitude": 105.80932305029299,
        "latitude": 21.021684550564263,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5350,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80964491537473,
          21.022225347458807
        ]
      },
      "properties": {
        "tenDoiTuon": "30",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Nguyễn Chí Thanh",
        "tuDien": "LTG4",
        "chuDauTu": "SoXayDung",
        "loaiCot": "DC-05",
        "chieuCao": 4,
        "tuDien2": "LT4",
        "diaChiLapD": "",
        "longitude": 105.80964491537473,
        "latitude": 21.022225347458807,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5351,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80968648961272,
          21.02252578932954
        ]
      },
      "properties": {
        "tenDoiTuon": "31",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Nguyễn Chí Thanh",
        "tuDien": "LTG4",
        "chuDauTu": "SoXayDung",
        "loaiCot": "DC-05",
        "chieuCao": 4,
        "tuDien2": "LT4",
        "diaChiLapD": "",
        "longitude": 105.80968648961272,
        "latitude": 21.02252578932954,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5352,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80989704301926,
          21.022736098280227
        ]
      },
      "properties": {
        "tenDoiTuon": "32",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Nguyễn Chí Thanh",
        "tuDien": "LTG4",
        "chuDauTu": "SoXayDung",
        "loaiCot": "DC-05",
        "chieuCao": 4,
        "tuDien2": "LT4",
        "diaChiLapD": "",
        "longitude": 105.80989704301926,
        "latitude": 21.022736098280227,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5353,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80995068720107,
          21.02293639224208
        ]
      },
      "properties": {
        "tenDoiTuon": "33",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Nguyễn Chí Thanh",
        "tuDien": "LTG4",
        "chuDauTu": "SoXayDung",
        "loaiCot": "DC-05",
        "chieuCao": 4,
        "tuDien2": "LT4",
        "diaChiLapD": "",
        "longitude": 105.80995068720107,
        "latitude": 21.02293639224208,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5354,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81010089090648,
          21.023066583173442
        ]
      },
      "properties": {
        "tenDoiTuon": "34",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Nguyễn Chí Thanh",
        "tuDien": "LTG4",
        "chuDauTu": "SoXayDung",
        "loaiCot": "DC-05",
        "chieuCao": 4,
        "tuDien2": "LT4",
        "diaChiLapD": "",
        "longitude": 105.81010089090648,
        "latitude": 21.023066583173442,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5355,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81046567133009,
          21.023642426312875
        ]
      },
      "properties": {
        "tenDoiTuon": "35",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Nguyễn Chí Thanh",
        "tuDien": "LTG4",
        "chuDauTu": "SoXayDung",
        "loaiCot": "DC-05",
        "chieuCao": 4,
        "tuDien2": "LT4",
        "diaChiLapD": "",
        "longitude": 105.81046567133009,
        "latitude": 21.023642426312875,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5356,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81054613759828,
          21.023992938438752
        ]
      },
      "properties": {
        "tenDoiTuon": "36",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Nguyễn Chí Thanh",
        "tuDien": "LTG4",
        "chuDauTu": "SoXayDung",
        "loaiCot": "DC-05",
        "chieuCao": 4,
        "tuDien2": "LT4",
        "diaChiLapD": "",
        "longitude": 105.81054613759828,
        "latitude": 21.023992938438752,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5357,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81080899408273,
          21.02423328913468
        ]
      },
      "properties": {
        "tenDoiTuon": "37",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Nguyễn Chí Thanh",
        "tuDien": "LTG4",
        "chuDauTu": "SoXayDung",
        "loaiCot": "DC-05",
        "chieuCao": 4,
        "tuDien2": "LT4",
        "diaChiLapD": "",
        "longitude": 105.81080899408273,
        "latitude": 21.02423328913468,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5358,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81083581617811,
          21.02450117955735
        ]
      },
      "properties": {
        "tenDoiTuon": "38",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Nguyễn Chí Thanh",
        "tuDien": "LTG4",
        "chuDauTu": "SoXayDung",
        "loaiCot": "DC-05",
        "chieuCao": 4,
        "tuDien2": "LT4",
        "diaChiLapD": "",
        "longitude": 105.81083581617811,
        "latitude": 21.02450117955735,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5359,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81107922661606,
          21.024689891799156
        ]
      },
      "properties": {
        "tenDoiTuon": "39",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 199,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Nguyễn Chí Thanh",
        "tuDien": "LTG4",
        "chuDauTu": "SoXayDung",
        "loaiCot": "DC-05",
        "chieuCao": 4,
        "tuDien2": "LT4",
        "diaChiLapD": "",
        "longitude": 105.81107922661606,
        "latitude": 21.024689891799156,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5360,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80937803557865,
          21.021991252747092
        ]
      },
      "properties": {
        "tenDoiTuon": "29",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Nguyễn Chí Thanh",
        "tuDien": "LTG4",
        "chuDauTu": "SoXayDung",
        "loaiCot": "DC-05",
        "chieuCao": 4,
        "tuDien2": "LT4",
        "diaChiLapD": "",
        "longitude": 105.80937803557865,
        "latitude": 21.021991252747092,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5361,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8097139822286,
          21.02441949806724
        ]
      },
      "properties": {
        "tenDoiTuon": "7-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 54 Nguyễn Chí Thanh",
        "tuDien": "LTG4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "LT4",
        "diaChiLapD": "",
        "longitude": 105.8097139822286,
        "latitude": 21.02441949806724,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5362,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81008949149214,
          21.023628343219517
        ]
      },
      "properties": {
        "tenDoiTuon": "7-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 54 Nguyễn Chí Thanh",
        "tuDien": "LTG4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "LT4",
        "diaChiLapD": "",
        "longitude": 105.81008949149214,
        "latitude": 21.023628343219517,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5363,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8096657024623,
          21.023826132325073
        ]
      },
      "properties": {
        "tenDoiTuon": "7-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 54 Nguyễn Chí Thanh",
        "tuDien": "LTG4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "LT4",
        "diaChiLapD": "",
        "longitude": 105.8096657024623,
        "latitude": 21.023826132325073,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5364,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80929555762322,
          21.024006395585054
        ]
      },
      "properties": {
        "tenDoiTuon": "7-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 54 Nguyễn Chí Thanh",
        "tuDien": "LTG4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "LT4",
        "diaChiLapD": "",
        "longitude": 105.80929555762322,
        "latitude": 21.024006395585054,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5365,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80929823983094,
          21.024131578276382
        ]
      },
      "properties": {
        "tenDoiTuon": "7-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 54 Nguyễn Chí Thanh",
        "tuDien": "LTG4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "LT4",
        "diaChiLapD": "",
        "longitude": 105.80929823983094,
        "latitude": 21.024131578276382,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5366,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80950745213366,
          21.024514636658616
        ]
      },
      "properties": {
        "tenDoiTuon": "7-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 54 Nguyễn Chí Thanh",
        "tuDien": "LTG4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "LT4",
        "diaChiLapD": "",
        "longitude": 105.80950745213366,
        "latitude": 21.024514636658616,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5367,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80968179571772,
          21.024867650374627
        ]
      },
      "properties": {
        "tenDoiTuon": "7-7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 879 Đê La Thành",
        "tuDien": "LTG4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 6,
        "tuDien2": "LT4",
        "diaChiLapD": "",
        "longitude": 105.80968179571772,
        "latitude": 21.024867650374627,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5368,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80984272825413,
          21.025165583285172
        ]
      },
      "properties": {
        "tenDoiTuon": "7-8",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 879 Đê La Thành",
        "tuDien": "LTG4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 6,
        "tuDien2": "LT4",
        "diaChiLapD": "",
        "longitude": 105.80984272825413,
        "latitude": 21.025165583285172,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5369,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81001170742272,
          21.02545099744864
        ]
      },
      "properties": {
        "tenDoiTuon": "7-9",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 879 Đê La Thành",
        "tuDien": "LTG4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 6,
        "tuDien2": "LT4",
        "diaChiLapD": "",
        "longitude": 105.81001170742272,
        "latitude": 21.02545099744864,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5370,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81059911119667,
          21.024835103045227
        ]
      },
      "properties": {
        "tenDoiTuon": "7-11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 879 Đê La Thành",
        "tuDien": "LTG4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "LT4",
        "diaChiLapD": "",
        "longitude": 105.81059911119667,
        "latitude": 21.024835103045227,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5371,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81037380564753,
          21.02513303602172
        ]
      },
      "properties": {
        "tenDoiTuon": "7-10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 879 Đê La Thành",
        "tuDien": "LTG4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "LT4",
        "diaChiLapD": "",
        "longitude": 105.81037380564753,
        "latitude": 21.02513303602172,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5372,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8097810374491,
          21.025388406669727
        ]
      },
      "properties": {
        "tenDoiTuon": "7-12",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 879 Đê La Thành",
        "tuDien": "LTG4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "LT4",
        "diaChiLapD": "",
        "longitude": 105.8097810374491,
        "latitude": 21.025388406669727,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5373,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80963351595142,
          21.02513553965727
        ]
      },
      "properties": {
        "tenDoiTuon": "7-13",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 879 Đê La Thành",
        "tuDien": "LTG4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "LT4",
        "diaChiLapD": "",
        "longitude": 105.80963351595142,
        "latitude": 21.02513553965727,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5374,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80961474048824,
          21.02607690378751
        ]
      },
      "properties": {
        "tenDoiTuon": "7-14",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 879 Đê La Thành",
        "tuDien": "LTG4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "LT4",
        "diaChiLapD": "",
        "longitude": 105.80961474048824,
        "latitude": 21.02607690378751,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5375,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80946990120731,
          21.026294718576782
        ]
      },
      "properties": {
        "tenDoiTuon": "7-15",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 879 Đê La Thành",
        "tuDien": "LTG4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "LT4",
        "diaChiLapD": "",
        "longitude": 105.80946990120731,
        "latitude": 21.026294718576782,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5376,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80924996006463,
          21.026477482465417
        ]
      },
      "properties": {
        "tenDoiTuon": "7-16",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 1,
        "maXaPhuong": 25,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 879 Đê La Thành",
        "tuDien": "LTG4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "LT4",
        "diaChiLapD": "",
        "longitude": 105.80924996006463,
        "latitude": 21.026477482465417,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5377,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80912657845462,
          21.026282200494148
        ]
      },
      "properties": {
        "tenDoiTuon": "7-17",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 1,
        "maXaPhuong": 25,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 879 Đê La Thành",
        "tuDien": "LTG4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "LT4",
        "diaChiLapD": "",
        "longitude": 105.80912657845462,
        "latitude": 21.026282200494148,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5378,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80899515021237,
          21.02607690378751
        ]
      },
      "properties": {
        "tenDoiTuon": "7-18",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 1,
        "maXaPhuong": 25,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 879 Đê La Thành",
        "tuDien": "LTG4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "LT4",
        "diaChiLapD": "",
        "longitude": 105.80899515021237,
        "latitude": 21.02607690378751,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5379,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8088476287147,
          21.02586409592747
        ]
      },
      "properties": {
        "tenDoiTuon": "7-19",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 1,
        "maXaPhuong": 25,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 879 Đê La Thành",
        "tuDien": "LTG4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "LT4",
        "diaChiLapD": "",
        "longitude": 105.8088476287147,
        "latitude": 21.02586409592747,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5380,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8082870470361,
          21.02099697205173
        ]
      },
      "properties": {
        "tenDoiTuon": "18-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 62 Nguyễn Chí Thanh",
        "tuDien": "LTG4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 6,
        "tuDien2": "LT4",
        "diaChiLapD": "",
        "longitude": 105.8082870470361,
        "latitude": 21.02099697205173,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5381,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80788706260671,
          21.021063320229995
        ]
      },
      "properties": {
        "tenDoiTuon": "18-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 62 Nguyễn Chí Thanh",
        "tuDien": "LTG4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "LT4",
        "diaChiLapD": "",
        "longitude": 105.80788706260671,
        "latitude": 21.021063320229995,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5382,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80789008010163,
          21.021672970981385
        ]
      },
      "properties": {
        "tenDoiTuon": "18-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 62 Nguyễn Chí Thanh",
        "tuDien": "LTG4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "LT4",
        "diaChiLapD": "",
        "longitude": 105.80789008010163,
        "latitude": 21.021672970981385,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5383,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80787935127069,
          21.021445134647603
        ]
      },
      "properties": {
        "tenDoiTuon": "18-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 62 Nguyễn Chí Thanh",
        "tuDien": "LTG4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "LT4",
        "diaChiLapD": "",
        "longitude": 105.80787935127069,
        "latitude": 21.021445134647603,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5384,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8080966101966,
          21.021695504225622
        ]
      },
      "properties": {
        "tenDoiTuon": "18-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 62 Nguyễn Chí Thanh",
        "tuDien": "LTG4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "LT4",
        "diaChiLapD": "",
        "longitude": 105.8080966101966,
        "latitude": 21.021695504225622,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5385,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80809929240435,
          21.02184322208012
        ]
      },
      "properties": {
        "tenDoiTuon": "18-5-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 62 Nguyễn Chí Thanh",
        "tuDien": "LTG4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "LT4",
        "diaChiLapD": "",
        "longitude": 105.80809929240435,
        "latitude": 21.02184322208012,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5386,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80783643592883,
          21.02187827375298
        ]
      },
      "properties": {
        "tenDoiTuon": "18-8",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 62 Nguyễn Chí Thanh",
        "tuDien": "LTG4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 6,
        "tuDien2": "LT4",
        "diaChiLapD": "",
        "longitude": 105.80783643592883,
        "latitude": 21.02187827375298,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5387,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80747433770401,
          21.021883281134258
        ]
      },
      "properties": {
        "tenDoiTuon": "18-7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 62 Nguyễn Chí Thanh",
        "tuDien": "LTG4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "LT4",
        "diaChiLapD": "",
        "longitude": 105.80747433770401,
        "latitude": 21.021883281134258,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5388,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8074206935312,
          21.021718037467267
        ]
      },
      "properties": {
        "tenDoiTuon": "18",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 62 Nguyễn Chí Thanh",
        "tuDien": "LTG4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "LT4",
        "diaChiLapD": "",
        "longitude": 105.8074206935312,
        "latitude": 21.021718037467267,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5389,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8072570787781,
          21.021820688857357
        ]
      },
      "properties": {
        "tenDoiTuon": "18-9",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 62 Nguyễn Chí Thanh",
        "tuDien": "LTG4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "LT4",
        "diaChiLapD": "",
        "longitude": 105.8072570787781,
        "latitude": 21.021820688857357,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5390,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80708541740175,
          21.021818185166133
        ]
      },
      "properties": {
        "tenDoiTuon": "18-10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 62 Nguyễn Chí Thanh",
        "tuDien": "LTG4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "LT4",
        "diaChiLapD": "",
        "longitude": 105.80708541740175,
        "latitude": 21.021818185166133,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5391,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80667772162732,
          21.021833207314685
        ]
      },
      "properties": {
        "tenDoiTuon": "18-13",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 62 Nguyễn Chí Thanh",
        "tuDien": "LTG4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "LT4",
        "diaChiLapD": "",
        "longitude": 105.80667772162732,
        "latitude": 21.021833207314685,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5392,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80627807248513,
          21.02174808178391
        ]
      },
      "properties": {
        "tenDoiTuon": "18-15",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 62 Nguyễn Chí Thanh",
        "tuDien": "LTG4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "LT4",
        "diaChiLapD": "",
        "longitude": 105.80627807248513,
        "latitude": 21.02174808178391,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5393,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8059025632216,
          21.02174557809143
        ]
      },
      "properties": {
        "tenDoiTuon": "18-16",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 62 Nguyễn Chí Thanh",
        "tuDien": "LTG4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "LT4",
        "diaChiLapD": "",
        "longitude": 105.8059025632216,
        "latitude": 21.02174557809143,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5394,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80594011414794,
          21.021497712293332
        ]
      },
      "properties": {
        "tenDoiTuon": "18-18",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 62 Nguyễn Chí Thanh",
        "tuDien": "LTG4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "LT4",
        "diaChiLapD": "",
        "longitude": 105.80594011414794,
        "latitude": 21.021497712293332,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5395,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8059508429879,
          21.02114218689487
        ]
      },
      "properties": {
        "tenDoiTuon": "18-19",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 62 Nguyễn Chí Thanh",
        "tuDien": "LTG4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "LT4",
        "diaChiLapD": "",
        "longitude": 105.8059508429879,
        "latitude": 21.02114218689487,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5396,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80571749080653,
          21.021134675785973
        ]
      },
      "properties": {
        "tenDoiTuon": "18-17",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 62 Nguyễn Chí Thanh",
        "tuDien": "LTG4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "LT4",
        "diaChiLapD": "",
        "longitude": 105.80571749080653,
        "latitude": 21.021134675785973,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5397,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80591329206153,
          21.020936883109684
        ]
      },
      "properties": {
        "tenDoiTuon": "18-20",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 62 Nguyễn Chí Thanh",
        "tuDien": "LTG4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "LT4",
        "diaChiLapD": "",
        "longitude": 105.80591329206153,
        "latitude": 21.020936883109684,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5398,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80622174609562,
          21.02088680897311
        ]
      },
      "properties": {
        "tenDoiTuon": "18-21",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 62 Nguyễn Chí Thanh",
        "tuDien": "LTG4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 6,
        "tuDien2": "LT4",
        "diaChiLapD": "",
        "longitude": 105.80622174609562,
        "latitude": 21.02088680897311,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5399,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80627270806964,
          21.02066397886021
        ]
      },
      "properties": {
        "tenDoiTuon": "18-22",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 62 Nguyễn Chí Thanh",
        "tuDien": "LTG4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "LT4",
        "diaChiLapD": "",
        "longitude": 105.80627270806964,
        "latitude": 21.02066397886021,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5400,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80605544913476,
          21.020556319248023
        ]
      },
      "properties": {
        "tenDoiTuon": "18-23",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 62 Nguyễn Chí Thanh",
        "tuDien": "LTG4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 6,
        "tuDien2": "LT4",
        "diaChiLapD": "",
        "longitude": 105.80605544913476,
        "latitude": 21.020556319248023,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5401,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80621369947238,
          21.02048871898612
        ]
      },
      "properties": {
        "tenDoiTuon": "18-24",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 62 Nguyễn Chí Thanh",
        "tuDien": "LTG4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "LT4",
        "diaChiLapD": "",
        "longitude": 105.80621369947238,
        "latitude": 21.02048871898612,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5402,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80613859761966,
          21.02036102952026
        ]
      },
      "properties": {
        "tenDoiTuon": "18-25",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 62 Nguyễn Chí Thanh",
        "tuDien": "LTG4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "LT4",
        "diaChiLapD": "",
        "longitude": 105.80613859761966,
        "latitude": 21.02036102952026,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5403,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80637463200875,
          21.020481207844284
        ]
      },
      "properties": {
        "tenDoiTuon": "18-26",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 62 Nguyễn Chí Thanh",
        "tuDien": "LTG4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "LT4",
        "diaChiLapD": "",
        "longitude": 105.80637463200875,
        "latitude": 21.020481207844284,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5404,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8062619792297,
          21.020376051816477
        ]
      },
      "properties": {
        "tenDoiTuon": "18-27",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 62 Nguyễn Chí Thanh",
        "tuDien": "LTG4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "LT4",
        "diaChiLapD": "",
        "longitude": 105.8062619792297,
        "latitude": 21.020376051816477,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5405,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8062297927278,
          21.0202708957137
        ]
      },
      "properties": {
        "tenDoiTuon": "18-28",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 62 Nguyễn Chí Thanh",
        "tuDien": "LTG4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "LT4",
        "diaChiLapD": "",
        "longitude": 105.8062297927278,
        "latitude": 21.0202708957137,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5406,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80628611911735,
          21.020165739537568
        ]
      },
      "properties": {
        "tenDoiTuon": "18-30",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 62 Nguyễn Chí Thanh",
        "tuDien": "LTG4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "LT4",
        "diaChiLapD": "",
        "longitude": 105.80628611911735,
        "latitude": 21.020165739537568,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5407,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80650606025101,
          21.02027590314898
        ]
      },
      "properties": {
        "tenDoiTuon": "18-29",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 62 Nguyễn Chí Thanh",
        "tuDien": "LTG4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "LT4",
        "diaChiLapD": "",
        "longitude": 105.80650606025101,
        "latitude": 21.02027590314898,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5408,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80673404801688,
          21.0216128823118
        ]
      },
      "properties": {
        "tenDoiTuon": "18-14",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 62 Nguyễn Chí Thanh",
        "tuDien": "LTG4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "LT4",
        "diaChiLapD": "",
        "longitude": 105.80673404801688,
        "latitude": 21.0216128823118,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5409,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80710687507268,
          21.021552793617992
        ]
      },
      "properties": {
        "tenDoiTuon": "18-11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 62 Nguyễn Chí Thanh",
        "tuDien": "LTG4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "LT4",
        "diaChiLapD": "",
        "longitude": 105.80710687507268,
        "latitude": 21.021552793617992,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5410,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80678232778318,
          21.021317446
        ]
      },
      "properties": {
        "tenDoiTuon": "18-12-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 62 Nguyễn Chí Thanh",
        "tuDien": "LTG4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "LT4",
        "diaChiLapD": "",
        "longitude": 105.80678232778318,
        "latitude": 21.021317446,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5411,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80710419286493,
          21.021344986698146
        ]
      },
      "properties": {
        "tenDoiTuon": "18-12",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 62 Nguyễn Chí Thanh",
        "tuDien": "LTG4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "LT4",
        "diaChiLapD": "",
        "longitude": 105.80710419286493,
        "latitude": 21.021344986698146,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5412,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83109252926805,
          21.001538782119304
        ]
      },
      "properties": {
        "tenDoiTuon": "6-9",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Tập thể đại học Y ( ngõ 1 Tôn Thất Tùng)",
        "tuDien": "KT4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 5,
        "tuDien2": "KT4",
        "diaChiLapD": "",
        "longitude": 105.83109252926805,
        "latitude": 21.001538782119304,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5413,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83137684343245,
          21.00146366114022
        ]
      },
      "properties": {
        "tenDoiTuon": "6-10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Tập thể đại học Y ( ngõ 1 Tôn Thất Tùng)",
        "tuDien": "KT4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 5,
        "tuDien2": "KT4",
        "diaChiLapD": "",
        "longitude": 105.83137684343245,
        "latitude": 21.00146366114022,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5414,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83167322752267,
          21.00137601994934
        ]
      },
      "properties": {
        "tenDoiTuon": "6-11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Tập thể đại học Y ( ngõ 1 Tôn Thất Tùng)",
        "tuDien": "KT4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 5,
        "tuDien2": "KT4",
        "diaChiLapD": "",
        "longitude": 105.83167322752267,
        "latitude": 21.00137601994934,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5415,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83197095272575,
          21.001305906959605
        ]
      },
      "properties": {
        "tenDoiTuon": "6-12",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Tập thể đại học Y ( ngõ 1 Tôn Thất Tùng)",
        "tuDien": "KT4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 5,
        "tuDien2": "KT4",
        "diaChiLapD": "",
        "longitude": 105.83197095272575,
        "latitude": 21.001305906959605,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5416,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83230086443074,
          21.001303402923995
        ]
      },
      "properties": {
        "tenDoiTuon": "6-13",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Tập thể đại học Y ( ngõ 1 Tôn Thất Tùng)",
        "tuDien": "KT4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 5,
        "tuDien2": "KT4",
        "diaChiLapD": "",
        "longitude": 105.83230086443074,
        "latitude": 21.001303402923995,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5417,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83269380806256,
          21.001230785862468
        ]
      },
      "properties": {
        "tenDoiTuon": "6-14",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Tập thể đại học Y ( ngõ 1 Tôn Thất Tùng)",
        "tuDien": "KT4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 5,
        "tuDien2": "KT4",
        "diaChiLapD": "",
        "longitude": 105.83269380806256,
        "latitude": 21.001230785862468,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5418,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83309211608294,
          21.001103079911502
        ]
      },
      "properties": {
        "tenDoiTuon": "6-15",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Tập thể đại học Y ( ngõ 1 Tôn Thất Tùng)",
        "tuDien": "KT4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 5,
        "tuDien2": "KT4",
        "diaChiLapD": "",
        "longitude": 105.83309211608294,
        "latitude": 21.001103079911502,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5419,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83303981302298,
          21.00083264341887
        ]
      },
      "properties": {
        "tenDoiTuon": "6-16",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Tập thể đại học Y ( ngõ 1 Tôn Thất Tùng)",
        "tuDien": "KT4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 5,
        "tuDien2": "KT4",
        "diaChiLapD": "",
        "longitude": 105.83303981302298,
        "latitude": 21.00083264341887,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5420,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83750468511796,
          21.02029530696103
        ]
      },
      "properties": {
        "tenDoiTuon": "VC2 01",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Văn Chương",
        "tuDien": "VC2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "BTLT",
        "chieuCao": 7,
        "tuDien2": "VC2",
        "diaChiLapD": "",
        "longitude": 105.83750468511796,
        "latitude": 21.02029530696103,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5421,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83751541393993,
          21.02062078984199
        ]
      },
      "properties": {
        "tenDoiTuon": "02",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Văn Chương",
        "tuDien": "VC2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "BTLT",
        "chieuCao": 7,
        "tuDien2": "VC2",
        "diaChiLapD": "",
        "longitude": 105.83751541393993,
        "latitude": 21.02062078984199,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5422,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83748859185353,
          21.020856138558795
        ]
      },
      "properties": {
        "tenDoiTuon": "03",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Văn Chương",
        "tuDien": "VC2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "BTLT",
        "chieuCao": 7,
        "tuDien2": "VC2",
        "diaChiLapD": "",
        "longitude": 105.83748859185353,
        "latitude": 21.020856138558795,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5423,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8374724985981,
          21.021156583189526
        ]
      },
      "properties": {
        "tenDoiTuon": "04",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Văn Chương",
        "tuDien": "VC2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "BTLT",
        "chieuCao": 7,
        "tuDien2": "VC2",
        "diaChiLapD": "",
        "longitude": 105.8374724985981,
        "latitude": 21.021156583189526,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5424,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83749663847676,
          21.02141446434813
        ]
      },
      "properties": {
        "tenDoiTuon": "05",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Văn Chương",
        "tuDien": "VC2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "BTLT",
        "chieuCao": 7,
        "tuDien2": "VC2",
        "diaChiLapD": "",
        "longitude": 105.83749663847676,
        "latitude": 21.02141446434813,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5425,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83743740723764,
          21.02164626141471
        ]
      },
      "properties": {
        "tenDoiTuon": "06",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Văn Chương",
        "tuDien": "VC2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "BTLT",
        "chieuCao": 7,
        "tuDien2": "VC2",
        "diaChiLapD": "",
        "longitude": 105.83743740723764,
        "latitude": 21.02164626141471,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5426,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83725124090138,
          21.021816364348627
        ]
      },
      "properties": {
        "tenDoiTuon": "07",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Văn Chương",
        "tuDien": "VC2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "BTLT",
        "chieuCao": 7,
        "tuDien2": "VC2",
        "diaChiLapD": "",
        "longitude": 105.83725124090138,
        "latitude": 21.021816364348627,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5427,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83710195240279,
          21.02198403151525
        ]
      },
      "properties": {
        "tenDoiTuon": "08",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Văn Chương",
        "tuDien": "VC2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "BTLT",
        "chieuCao": 7,
        "tuDien2": "VC2",
        "diaChiLapD": "",
        "longitude": 105.83710195240279,
        "latitude": 21.02198403151525,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5428,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83689183418777,
          21.022235154937512
        ]
      },
      "properties": {
        "tenDoiTuon": "VC2 09",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Văn Chương",
        "tuDien": "VC2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "BTLT",
        "chieuCao": 7,
        "tuDien2": "VC2",
        "diaChiLapD": "",
        "longitude": 105.83689183418777,
        "latitude": 21.022235154937512,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5429,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83738752201464,
          21.020483085633423
        ]
      },
      "properties": {
        "tenDoiTuon": "VC2 2-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "TT A1 Văn Chương",
        "tuDien": "VC2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VC2",
        "diaChiLapD": "",
        "longitude": 105.83738752201464,
        "latitude": 21.020483085633423,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5430,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83111935130056,
          21.005346427242877
        ]
      },
      "properties": {
        "tenDoiTuon": "1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Chợ Khương Thượng",
        "tuDien": "KT1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "KT1",
        "diaChiLapD": "",
        "longitude": 105.83111935130056,
        "latitude": 21.005346427242877,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5431,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83115153781142,
          21.00546536568145
        ]
      },
      "properties": {
        "tenDoiTuon": "1-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Chợ Khương Thượng",
        "tuDien": "KT1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "KT1",
        "diaChiLapD": "",
        "longitude": 105.83115153781142,
        "latitude": 21.00546536568145,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5432,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8312454151273,
          21.00577585715944
        ]
      },
      "properties": {
        "tenDoiTuon": "1-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Chợ Khương Thượng",
        "tuDien": "KT1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "KT1",
        "diaChiLapD": "",
        "longitude": 105.8312454151273,
        "latitude": 21.00577585715944,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5433,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83133661023544,
          21.00582218042919
        ]
      },
      "properties": {
        "tenDoiTuon": "1-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Chợ Khương Thượng",
        "tuDien": "KT1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "KT1",
        "diaChiLapD": "",
        "longitude": 105.83133661023544,
        "latitude": 21.00582218042919,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5434,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8313164936684,
          21.005305111763292
        ]
      },
      "properties": {
        "tenDoiTuon": "2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Chợ Khương Thượng",
        "tuDien": "KT1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "KT1",
        "diaChiLapD": "",
        "longitude": 105.8313164936684,
        "latitude": 21.005305111763292,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5435,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83144792191064,
          21.00528758398043
        ]
      },
      "properties": {
        "tenDoiTuon": "3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Khu A4 A5 A6 A7 Khương Thượng",
        "tuDien": "KT1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "KT1",
        "diaChiLapD": "",
        "longitude": 105.83144792191064,
        "latitude": 21.00528758398043,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5436,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83179258576718,
          21.005224984738838
        ]
      },
      "properties": {
        "tenDoiTuon": "24",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Khu A4 A5 A6 A7 Khương Thượng",
        "tuDien": "KT1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "KT1",
        "diaChiLapD": "",
        "longitude": 105.83179258576718,
        "latitude": 21.005224984738838,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5437,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83211042753732,
          21.00516238547185
        ]
      },
      "properties": {
        "tenDoiTuon": "23",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Khu A4 A5 A6 A7 Khương Thượng",
        "tuDien": "KT1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "KT1",
        "diaChiLapD": "",
        "longitude": 105.83211042753732,
        "latitude": 21.00516238547185,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5438,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83201386801369,
          21.00530010382544
        ]
      },
      "properties": {
        "tenDoiTuon": "22",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 43 Đông Tác",
        "tuDien": "KT1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "KT1",
        "diaChiLapD": "",
        "longitude": 105.83201386801369,
        "latitude": 21.00530010382544,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5439,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83189585081917,
          21.005332655418194
        ]
      },
      "properties": {
        "tenDoiTuon": "22-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 43 Đông Tác",
        "tuDien": "KT1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "KT1",
        "diaChiLapD": "",
        "longitude": 105.83189585081917,
        "latitude": 21.005332655418194,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5440,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83209433428189,
          21.005540484647007
        ]
      },
      "properties": {
        "tenDoiTuon": "21",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 43 Đông Tác",
        "tuDien": "KT1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "KT1",
        "diaChiLapD": "",
        "longitude": 105.83209433428189,
        "latitude": 21.005540484647007,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5441,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83178051583235,
          21.005685714538927
        ]
      },
      "properties": {
        "tenDoiTuon": "21-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 43 Đông Tác",
        "tuDien": "KT1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "KT1",
        "diaChiLapD": "",
        "longitude": 105.83178051583235,
        "latitude": 21.005685714538927,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5442,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83225526682726,
          21.00573328981774
        ]
      },
      "properties": {
        "tenDoiTuon": "19",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 43 Đông Tác",
        "tuDien": "KT1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "KT1",
        "diaChiLapD": "",
        "longitude": 105.83225526682726,
        "latitude": 21.00573328981774,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5443,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83195754162416,
          21.005823432409514
        ]
      },
      "properties": {
        "tenDoiTuon": "20",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Phố Đông Tác",
        "tuDien": "KT1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "KT1",
        "diaChiLapD": "",
        "longitude": 105.83195754162416,
        "latitude": 21.005823432409514,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5444,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83237060181406,
          21.005605587719693
        ]
      },
      "properties": {
        "tenDoiTuon": "18",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 43 Đông Tác",
        "tuDien": "KT1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "KT1",
        "diaChiLapD": "",
        "longitude": 105.83237060181406,
        "latitude": 21.005605587719693,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5445,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8323464619354,
          21.005397758581537
        ]
      },
      "properties": {
        "tenDoiTuon": "17",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 43 Đông Tác",
        "tuDien": "KT1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "KT1",
        "diaChiLapD": "",
        "longitude": 105.8323464619354,
        "latitude": 21.005397758581537,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5446,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83232232204776,
          21.005212464888423
        ]
      },
      "properties": {
        "tenDoiTuon": "16",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 43 Đông Tác",
        "tuDien": "KT1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "KT1",
        "diaChiLapD": "",
        "longitude": 105.83232232204776,
        "latitude": 21.005212464888423,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5447,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83228208891366,
          21.004984603409877
        ]
      },
      "properties": {
        "tenDoiTuon": "15",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 43 Đông Tác",
        "tuDien": "KT1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "KT1",
        "diaChiLapD": "",
        "longitude": 105.83228208891366,
        "latitude": 21.004984603409877,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5448,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83213188520824,
          21.00411321784316
        ]
      },
      "properties": {
        "tenDoiTuon": "12",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Khu A4 A5 A6 A7 Khương Thượng",
        "tuDien": "KT1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "BTLT",
        "chieuCao": 5,
        "tuDien2": "KT1",
        "diaChiLapD": "",
        "longitude": 105.83213188520824,
        "latitude": 21.00411321784316,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5449,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83128967158198,
          21.004260953110183
        ]
      },
      "properties": {
        "tenDoiTuon": "7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Khu A4 A5 A6 A7 Khương Thượng",
        "tuDien": "KT1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "KT1",
        "diaChiLapD": "",
        "longitude": 105.83128967158198,
        "latitude": 21.004260953110183,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5450,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83193206064162,
          21.00412949376948
        ]
      },
      "properties": {
        "tenDoiTuon": "11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Khu A4 A5 A6 A7 Khương Thượng",
        "tuDien": "KT1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "KT1",
        "diaChiLapD": "",
        "longitude": 105.83193206064162,
        "latitude": 21.00412949376948,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5451,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83168663851646,
          21.00417331356284
        ]
      },
      "properties": {
        "tenDoiTuon": "10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Khu A4 A5 A6 A7 Khương Thượng",
        "tuDien": "KT1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "KT1",
        "diaChiLapD": "",
        "longitude": 105.83168663851646,
        "latitude": 21.00417331356284,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5452,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83149888388469,
          21.004212125368973
        ]
      },
      "properties": {
        "tenDoiTuon": "9",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Khu A4 A5 A6 A7 Khương Thượng",
        "tuDien": "KT1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "KT1",
        "diaChiLapD": "",
        "longitude": 105.83149888388469,
        "latitude": 21.004212125368973,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5453,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83104022614519,
          21.004292252936462
        ]
      },
      "properties": {
        "tenDoiTuon": "7-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Khu A8 A9 A10 A11 Khương Thượng",
        "tuDien": "KT1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "KT1",
        "diaChiLapD": "",
        "longitude": 105.83104022614519,
        "latitude": 21.004292252936462,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5454,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83070226780801,
          21.004363616514958
        ]
      },
      "properties": {
        "tenDoiTuon": "8",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Khu A8 A9 A10 A11 Khương Thượng",
        "tuDien": "KT1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "KT1",
        "diaChiLapD": "",
        "longitude": 105.83070226780801,
        "latitude": 21.004363616514958,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5455,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83131649367739,
          21.004451255951395
        ]
      },
      "properties": {
        "tenDoiTuon": "6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Khu A8 A9 A10 A11 Khương Thượng",
        "tuDien": "KT1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "KT1",
        "diaChiLapD": "",
        "longitude": 105.83131649367739,
        "latitude": 21.004451255951395,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5456,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83217211835132,
          21.004336072682072
        ]
      },
      "properties": {
        "tenDoiTuon": "13",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Khu A4 A5 A6 A7 Khương Thượng",
        "tuDien": "KT1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "BTLT",
        "chieuCao": 5,
        "tuDien2": "KT1",
        "diaChiLapD": "",
        "longitude": 105.83217211835132,
        "latitude": 21.004336072682072,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5457,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83194681279319,
          21.004381144406366
        ]
      },
      "properties": {
        "tenDoiTuon": "6-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Khu A4 A5 A6 A7 Khương Thượng",
        "tuDien": "KT1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "KT1",
        "diaChiLapD": "",
        "longitude": 105.83194681279319,
        "latitude": 21.004381144406366,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5458,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83154179923551,
          21.004465027858238
        ]
      },
      "properties": {
        "tenDoiTuon": "6-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Khu A4 A5 A6 A7 Khương Thượng",
        "tuDien": "KT1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "KT1",
        "diaChiLapD": "",
        "longitude": 105.83154179923551,
        "latitude": 21.004465027858238,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5459,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83078139298132,
          21.00458396699934
        ]
      },
      "properties": {
        "tenDoiTuon": "6-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Khu A8 A9 A10 A11 Khương Thượng",
        "tuDien": "KT1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 5,
        "tuDien2": "KT1",
        "diaChiLapD": "",
        "longitude": 105.83078139298132,
        "latitude": 21.00458396699934,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5460,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83104424945681,
          21.004532635381594
        ]
      },
      "properties": {
        "tenDoiTuon": "6-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Khu A8 A9 A10 A11 Khương Thượng",
        "tuDien": "KT1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "KT1",
        "diaChiLapD": "",
        "longitude": 105.83104424945681,
        "latitude": 21.004532635381594,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5461,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83136209124494,
          21.004684126202196
        ]
      },
      "properties": {
        "tenDoiTuon": "5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Khu A8 A9 A10 A11 Khương Thượng",
        "tuDien": "KT1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "KT1",
        "diaChiLapD": "",
        "longitude": 105.83136209124494,
        "latitude": 21.004684126202196,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5462,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83153509372514,
          21.00474797765916
        ]
      },
      "properties": {
        "tenDoiTuon": "5-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Khu A4 A5 A6 A7 Khương Thượng",
        "tuDien": "KT1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "KT1",
        "diaChiLapD": "",
        "longitude": 105.83153509372514,
        "latitude": 21.00474797765916,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5463,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83184891218367,
          21.004682874212318
        ]
      },
      "properties": {
        "tenDoiTuon": "5-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Khu A4 A5 A6 A7 Khương Thượng",
        "tuDien": "KT1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "KT1",
        "diaChiLapD": "",
        "longitude": 105.83184891218367,
        "latitude": 21.004682874212318,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5464,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83141439432285,
          21.004970831550104
        ]
      },
      "properties": {
        "tenDoiTuon": "4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Khu A4 A5 A6 A7 Khương Thượng",
        "tuDien": "KT1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "KT1",
        "diaChiLapD": "",
        "longitude": 105.83141439432285,
        "latitude": 21.004970831550104,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5465,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83162092441778,
          21.00493577590377
        ]
      },
      "properties": {
        "tenDoiTuon": "4-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Khu A4 A5 A6 A7 Khương Thượng",
        "tuDien": "KT1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "KT1",
        "diaChiLapD": "",
        "longitude": 105.83162092441778,
        "latitude": 21.00493577590377,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5466,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83196424717046,
          21.004868168563092
        ]
      },
      "properties": {
        "tenDoiTuon": "4-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Khu A4 A5 A6 A7 Khương Thượng",
        "tuDien": "KT1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "KT1",
        "diaChiLapD": "",
        "longitude": 105.83196424717046,
        "latitude": 21.004868168563092,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5467,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83222978586268,
          21.004662842376284
        ]
      },
      "properties": {
        "tenDoiTuon": "14",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 43 Đông Tác",
        "tuDien": "KT1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "KT1",
        "diaChiLapD": "",
        "longitude": 105.83222978586268,
        "latitude": 21.004662842376284,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5468,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83119177097248,
          21.004800561190898
        ]
      },
      "properties": {
        "tenDoiTuon": "5-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Khu A8 A9 A10 A11 Khương Thượng",
        "tuDien": "KT1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "BTLT",
        "chieuCao": 5,
        "tuDien2": "KT1",
        "diaChiLapD": "",
        "longitude": 105.83119177097248,
        "latitude": 21.004800561190898,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5469,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83100669854842,
          21.004838120846156
        ]
      },
      "properties": {
        "tenDoiTuon": "5-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Khu A8 A9 A10 A11 Khương Thượng",
        "tuDien": "KT1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "BTLT",
        "chieuCao": 5,
        "tuDien2": "KT1",
        "diaChiLapD": "",
        "longitude": 105.83100669854842,
        "latitude": 21.004838120846156,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5470,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83081626170892,
          21.004883192418838
        ]
      },
      "properties": {
        "tenDoiTuon": "5-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Khu A8 A9 A10 A11 Khương Thượng",
        "tuDien": "KT1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "KT1",
        "diaChiLapD": "",
        "longitude": 105.83081626170892,
        "latitude": 21.004883192418838,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5471,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82119216043506,
          21.012106049082877
        ]
      },
      "properties": {
        "tenDoiTuon": "TH2 01",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Trung Liệt",
        "tuDien": "TH2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TH2",
        "diaChiLapD": "",
        "longitude": 105.82119216043506,
        "latitude": 21.012106049082877,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5472,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82140271384162,
          21.012336403535652
        ]
      },
      "properties": {
        "tenDoiTuon": "TH2 02",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Trung Liệt",
        "tuDien": "TH2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TH2",
        "diaChiLapD": "",
        "longitude": 105.82140271384162,
        "latitude": 21.012336403535652,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5473,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82099099475558,
          21.012838424779645
        ]
      },
      "properties": {
        "tenDoiTuon": "TH2 2-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Sân UB Phường TL",
        "tuDien": "TH2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TH2",
        "diaChiLapD": "",
        "longitude": 105.82099099475558,
        "latitude": 21.012838424779645,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5474,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82117338497187,
          21.012557994168894
        ]
      },
      "properties": {
        "tenDoiTuon": "TH2 2-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Sân UB Phường TL",
        "tuDien": "TH2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TH2",
        "diaChiLapD": "",
        "longitude": 105.82117338497187,
        "latitude": 21.012557994168894,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5475,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8208394499463,
          21.01282465364569
        ]
      },
      "properties": {
        "tenDoiTuon": "TH2 2-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Sân UB Phường TL",
        "tuDien": "TH2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TH2",
        "diaChiLapD": "",
        "longitude": 105.8208394499463,
        "latitude": 21.01282465364569,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5476,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82166422922224,
          21.012616834563826
        ]
      },
      "properties": {
        "tenDoiTuon": "TH2 03",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Trung Liệt",
        "tuDien": "TH2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TH2",
        "diaChiLapD": "",
        "longitude": 105.82166422922224,
        "latitude": 21.012616834563826,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5477,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82180772739932,
          21.012512924913914
        ]
      },
      "properties": {
        "tenDoiTuon": "TH2 3-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Sân UB Phường TL",
        "tuDien": "TH2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TH2",
        "diaChiLapD": "",
        "longitude": 105.82180772739932,
        "latitude": 21.012512924913914,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5478,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82193781453768,
          21.01259054417726
        ]
      },
      "properties": {
        "tenDoiTuon": "TH2 3-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Sân UB Phường TL",
        "tuDien": "TH2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TH2",
        "diaChiLapD": "",
        "longitude": 105.82193781453768,
        "latitude": 21.01259054417726,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5479,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82653377966854,
          21.00197636107951
        ]
      },
      "properties": {
        "tenDoiTuon": "TB2 36",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 72 Tôn Thất Tùng",
        "tuDien": "TB2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Tàu Bay 2",
        "diaChiLapD": "",
        "longitude": 105.82653377966854,
        "latitude": 21.00197636107951,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5480,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82188551145977,
          21.012879738173893
        ]
      },
      "properties": {
        "tenDoiTuon": "TH2 04",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Trung Liệt",
        "tuDien": "TH2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TH2",
        "diaChiLapD": "",
        "longitude": 105.82188551145977,
        "latitude": 21.012879738173893,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5481,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82172457892337,
          21.01300367828628
        ]
      },
      "properties": {
        "tenDoiTuon": "TH2 4-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 16 TL",
        "tuDien": "TH2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TH2",
        "diaChiLapD": "",
        "longitude": 105.82172457892337,
        "latitude": 21.01300367828628,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5482,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82165886480676,
          21.01313763364578
        ]
      },
      "properties": {
        "tenDoiTuon": "TH2 4-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 16 TL",
        "tuDien": "TH2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TH2",
        "diaChiLapD": "",
        "longitude": 105.82165886480676,
        "latitude": 21.01313763364578,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5483,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82161594945593,
          21.01292856307847
        ]
      },
      "properties": {
        "tenDoiTuon": "TH2 4-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 16 TL",
        "tuDien": "TH2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TH2",
        "diaChiLapD": "",
        "longitude": 105.82161594945593,
        "latitude": 21.01292856307847,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5484,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82156230527413,
          21.01304624355362
        ]
      },
      "properties": {
        "tenDoiTuon": "TH2 4-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 16 TL",
        "tuDien": "TH2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TH2",
        "diaChiLapD": "",
        "longitude": 105.82156230527413,
        "latitude": 21.01304624355362,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5485,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82141478378544,
          21.01315015283202
        ]
      },
      "properties": {
        "tenDoiTuon": "TH2 4-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 16 TL",
        "tuDien": "TH2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TH2",
        "diaChiLapD": "",
        "longitude": 105.82141478378544,
        "latitude": 21.01315015283202,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5486,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82119350153893,
          21.01323277943469
        ]
      },
      "properties": {
        "tenDoiTuon": "TH2 4-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 16 TL",
        "tuDien": "TH2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TH2",
        "diaChiLapD": "",
        "longitude": 105.82119350153893,
        "latitude": 21.01323277943469,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5487,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82209338265858,
          21.01310132799914
        ]
      },
      "properties": {
        "tenDoiTuon": "TH2 05",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Trung Liệt",
        "tuDien": "TH2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TH2",
        "diaChiLapD": "",
        "longitude": 105.82209338265858,
        "latitude": 21.01310132799914,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5488,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82232002932058,
          21.013324169412567
        ]
      },
      "properties": {
        "tenDoiTuon": "TH2 06",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Trung Liệt",
        "tuDien": "TH2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TH2",
        "diaChiLapD": "",
        "longitude": 105.82232002932058,
        "latitude": 21.013324169412567,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5489,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82210813481014,
          21.013431834245957
        ]
      },
      "properties": {
        "tenDoiTuon": "TH2 5-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 2 TL",
        "tuDien": "TH2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TH2",
        "diaChiLapD": "",
        "longitude": 105.82210813481014,
        "latitude": 21.013431834245957,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5490,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82196865994466,
          21.013305390654306
        ]
      },
      "properties": {
        "tenDoiTuon": "TH2 5-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 2 TL",
        "tuDien": "TH2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TH2",
        "diaChiLapD": "",
        "longitude": 105.82196865994466,
        "latitude": 21.013305390654306,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5491,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82225699741169,
          21.013763591674056
        ]
      },
      "properties": {
        "tenDoiTuon": "TH2 07",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Đặng Tiến Đông",
        "tuDien": "TH2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TH2",
        "diaChiLapD": "",
        "longitude": 105.82225699741169,
        "latitude": 21.013763591674056,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5492,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82208801824308,
          21.013603346668667
        ]
      },
      "properties": {
        "tenDoiTuon": "TH2 7-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 171",
        "tuDien": "TH2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TH2",
        "diaChiLapD": "",
        "longitude": 105.82208801824308,
        "latitude": 21.013603346668667,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5493,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82197268325628,
          21.01354701049307
        ]
      },
      "properties": {
        "tenDoiTuon": "TH2 7-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 171",
        "tuDien": "TH2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TH2",
        "diaChiLapD": "",
        "longitude": 105.82197268325628,
        "latitude": 21.01354701049307,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5494,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82204107958964,
          21.01400270694726
        ]
      },
      "properties": {
        "tenDoiTuon": "TH2 08",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Đặng Tiến Đông",
        "tuDien": "TH2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TH2",
        "diaChiLapD": "",
        "longitude": 105.82204107958964,
        "latitude": 21.01400270694726,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5495,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82179163415285,
          21.014263104295644
        ]
      },
      "properties": {
        "tenDoiTuon": "TH2 09",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Đặng Tiến Đông",
        "tuDien": "TH2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TH2",
        "diaChiLapD": "",
        "longitude": 105.82179163415285,
        "latitude": 21.014263104295644,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5496,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82154352981095,
          21.014490951602863
        ]
      },
      "properties": {
        "tenDoiTuon": "TH2 10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Đặng Tiến Đông",
        "tuDien": "TH2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TH2",
        "diaChiLapD": "",
        "longitude": 105.82154352981095,
        "latitude": 21.014490951602863,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5497,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82143355924862,
          21.014518493561802
        ]
      },
      "properties": {
        "tenDoiTuon": "TH2 11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Đặng Tiến Đông",
        "tuDien": "TH2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TH2",
        "diaChiLapD": "",
        "longitude": 105.82143355924862,
        "latitude": 21.014518493561802,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5498,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8213946672184,
          21.014708783318934
        ]
      },
      "properties": {
        "tenDoiTuon": "TH2 11-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Ngõ 24 Đặng Tiến Đông",
        "tuDien": "TH2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TH2",
        "diaChiLapD": "",
        "longitude": 105.8213946672184,
        "latitude": 21.014708783318934,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5499,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82126323897614,
          21.01454979123613
        ]
      },
      "properties": {
        "tenDoiTuon": "TH2 12",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Đặng Tiến Đông",
        "tuDien": "TH2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TH2",
        "diaChiLapD": "",
        "longitude": 105.82126323897614,
        "latitude": 21.01454979123613,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5500,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82103122788969,
          21.014623653721006
        ]
      },
      "properties": {
        "tenDoiTuon": "TH2 13",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Đặng Tiến Đông",
        "tuDien": "TH2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TH2",
        "diaChiLapD": "",
        "longitude": 105.82103122788969,
        "latitude": 21.014623653721006,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5501,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82084347325794,
          21.014783897629382
        ]
      },
      "properties": {
        "tenDoiTuon": "TH2 14",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Đặng Tiến Đông",
        "tuDien": "TH2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TH2",
        "diaChiLapD": "",
        "longitude": 105.82084347325794,
        "latitude": 21.014783897629382,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5502,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82065571863514,
          21.014942889462645
        ]
      },
      "properties": {
        "tenDoiTuon": "TH2 15",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Đặng Tiến Đông",
        "tuDien": "TH2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TH2",
        "diaChiLapD": "",
        "longitude": 105.82065571863514,
        "latitude": 21.014942889462645,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5503,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82040359098163,
          21.015163224321658
        ]
      },
      "properties": {
        "tenDoiTuon": "TH2 16",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Đặng Tiến Đông",
        "tuDien": "TH2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TH2",
        "diaChiLapD": "",
        "longitude": 105.82040359098163,
        "latitude": 21.015163224321658,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5504,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82021717745371,
          21.015026766976096
        ]
      },
      "properties": {
        "tenDoiTuon": "TH2 16-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 235 DTD",
        "tuDien": "TH2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": "",
        "tuDien2": "TH2",
        "diaChiLapD": "",
        "longitude": 105.82021717745371,
        "latitude": 21.015026766976096,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5505,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82032580692115,
          21.0145873484361
        ]
      },
      "properties": {
        "tenDoiTuon": "TH2 15-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 223 DTD",
        "tuDien": "TH2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TH2",
        "diaChiLapD": "",
        "longitude": 105.82032580692115,
        "latitude": 21.0145873484361,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5506,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82051356155293,
          21.014770126675053
        ]
      },
      "properties": {
        "tenDoiTuon": "TH2 15-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 223 DTD",
        "tuDien": "TH2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TH2",
        "diaChiLapD": "",
        "longitude": 105.82051356155293,
        "latitude": 21.014770126675053,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5507,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82010452467466,
          21.014762615245164
        ]
      },
      "properties": {
        "tenDoiTuon": "TH2 15-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 223 DTD",
        "tuDien": "TH2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TH2",
        "diaChiLapD": "",
        "longitude": 105.82010452467466,
        "latitude": 21.014762615245164,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5508,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81980679948055,
          21.014439623393766
        ]
      },
      "properties": {
        "tenDoiTuon": "TH2 15-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 223 DTD",
        "tuDien": "TH2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TH2",
        "diaChiLapD": "",
        "longitude": 105.81980679948055,
        "latitude": 21.014439623393766,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5509,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8201554866487,
          21.014404569973184
        ]
      },
      "properties": {
        "tenDoiTuon": "TH2 15-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 223 DTD",
        "tuDien": "TH2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TH2",
        "diaChiLapD": "",
        "longitude": 105.8201554866487,
        "latitude": 21.014404569973184,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5510,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82124446351298,
          21.01496041611112
        ]
      },
      "properties": {
        "tenDoiTuon": "TH2 11-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 24 Đặng Tiến Đông",
        "tuDien": "TH2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TH2",
        "diaChiLapD": "",
        "longitude": 105.82124446351298,
        "latitude": 21.01496041611112,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5511,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82094673830989,
          21.015212048478755
        ]
      },
      "properties": {
        "tenDoiTuon": "TH2 11-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 24 Đặng Tiến Đông",
        "tuDien": "TH2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TH2",
        "diaChiLapD": "",
        "longitude": 105.82094673830989,
        "latitude": 21.015212048478755,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5512,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82113583404552,
          21.015368536056013
        ]
      },
      "properties": {
        "tenDoiTuon": "TH2 11-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 24 Đặng Tiến Đông",
        "tuDien": "TH2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TH2",
        "diaChiLapD": "",
        "longitude": 105.82113583404552,
        "latitude": 21.015368536056013,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5513,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82128201443932,
          21.015525023469113
        ]
      },
      "properties": {
        "tenDoiTuon": "TH2 11-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 24 Đặng Tiến Đông",
        "tuDien": "TH2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TH2",
        "diaChiLapD": "",
        "longitude": 105.82128201443932,
        "latitude": 21.015525023469113,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5514,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82143221814475,
          21.01568777020406
        ]
      },
      "properties": {
        "tenDoiTuon": "TH2 11-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 24 Đặng Tiến Đông",
        "tuDien": "TH2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TH2",
        "diaChiLapD": "",
        "longitude": 105.82143221814475,
        "latitude": 21.01568777020406,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5515,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82167093474159,
          21.015936897554504
        ]
      },
      "properties": {
        "tenDoiTuon": "TH2 11-8",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 24 Đặng Tiến Đông",
        "tuDien": "TH2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TH2",
        "diaChiLapD": "",
        "longitude": 105.82167093474159,
        "latitude": 21.015936897554504,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5516,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82181979734312,
          21.016100895734045
        ]
      },
      "properties": {
        "tenDoiTuon": "TH2 11-9",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 24 Đặng Tiến Đông",
        "tuDien": "TH2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TH2",
        "diaChiLapD": "",
        "longitude": 105.82181979734312,
        "latitude": 21.016100895734045,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5517,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82155157644317,
          21.015819219360093
        ]
      },
      "properties": {
        "tenDoiTuon": "TH2 11-7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 24 Đặng Tiến Đông",
        "tuDien": "TH2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TH2",
        "diaChiLapD": "",
        "longitude": 105.82155157644317,
        "latitude": 21.015819219360093,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5518,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82153816539548,
          21.01402899708663
        ]
      },
      "properties": {
        "tenDoiTuon": "TH2 9-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 183 ĐTĐ",
        "tuDien": "TH2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TH2",
        "diaChiLapD": "",
        "longitude": 105.82153816539548,
        "latitude": 21.01402899708663,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5519,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82139332611452,
          21.01420676837091
        ]
      },
      "properties": {
        "tenDoiTuon": "TH2 9-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 183/5",
        "tuDien": "TH2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TH2",
        "diaChiLapD": "",
        "longitude": 105.82139332611452,
        "latitude": 21.01420676837091,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5520,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82127530891098,
          21.0142994096612
        ]
      },
      "properties": {
        "tenDoiTuon": "TH2 9-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 183/5",
        "tuDien": "TH2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TH2",
        "diaChiLapD": "",
        "longitude": 105.82127530891098,
        "latitude": 21.0142994096612,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5521,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82113851625327,
          21.014314432567193
        ]
      },
      "properties": {
        "tenDoiTuon": "TH2 9-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 183/5",
        "tuDien": "TH2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TH2",
        "diaChiLapD": "",
        "longitude": 105.82113851625327,
        "latitude": 21.014314432567193,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5522,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82135041076371,
          21.01382618733376
        ]
      },
      "properties": {
        "tenDoiTuon": "TH2 9-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 183 ĐTĐ",
        "tuDien": "TH2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TH2",
        "diaChiLapD": "",
        "longitude": 105.82135041076371,
        "latitude": 21.01382618733376,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5523,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82120020705827,
          21.01364841559503
        ]
      },
      "properties": {
        "tenDoiTuon": "TH2 9-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 183 ĐTĐ",
        "tuDien": "TH2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TH2",
        "diaChiLapD": "",
        "longitude": 105.82120020705827,
        "latitude": 21.01364841559503,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5524,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82100977021878,
          21.013460628317542
        ]
      },
      "properties": {
        "tenDoiTuon": "TH2 9-7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 183 ĐTĐ",
        "tuDien": "TH2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TH2",
        "diaChiLapD": "",
        "longitude": 105.82100977021878,
        "latitude": 21.013460628317542,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5525,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82089175302423,
          21.013350459671514
        ]
      },
      "properties": {
        "tenDoiTuon": "TH2 9-8",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 183 ĐTĐ",
        "tuDien": "TH2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TH2",
        "diaChiLapD": "",
        "longitude": 105.82089175302423,
        "latitude": 21.013350459671514,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5526,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.821017816851,
          21.013115099108393
        ]
      },
      "properties": {
        "tenDoiTuon": "TH2 9-9",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 183/7",
        "tuDien": "TH2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TH2",
        "diaChiLapD": "",
        "longitude": 105.821017816851,
        "latitude": 21.013115099108393,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5527,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82120288927501,
          21.01290477658947
        ]
      },
      "properties": {
        "tenDoiTuon": "TH2 9-10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 183/7",
        "tuDien": "TH2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TH2",
        "diaChiLapD": "",
        "longitude": 105.82120288927501,
        "latitude": 21.01290477658947,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5528,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82680635918528,
          21.001917829490864
        ]
      },
      "properties": {
        "tenDoiTuon": "35",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 72 Tôn Thất Tùng",
        "tuDien": "TB2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Tàu Bay 2",
        "diaChiLapD": "",
        "longitude": 105.82680635918528,
        "latitude": 21.001917829490864,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5529,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82700216044927,
          21.001932853644487
        ]
      },
      "properties": {
        "tenDoiTuon": "34",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 72 Tôn Thất Tùng",
        "tuDien": "TB2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Tàu Bay 2",
        "diaChiLapD": "",
        "longitude": 105.82700216044927,
        "latitude": 21.001932853644487,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5530,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82715906967402,
          21.00196415395905
        ]
      },
      "properties": {
        "tenDoiTuon": "32",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 72 Tôn Thất Tùng",
        "tuDien": "TB2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Tàu Bay 2",
        "diaChiLapD": "",
        "longitude": 105.82715906967402,
        "latitude": 21.00196415395905,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5531,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8271376120031,
          21.002081843081655
        ]
      },
      "properties": {
        "tenDoiTuon": "33",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 72 Tôn Thất Tùng",
        "tuDien": "TB2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Tàu Bay 2",
        "diaChiLapD": "",
        "longitude": 105.8271376120031,
        "latitude": 21.002081843081655,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5532,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82749568690734,
          21.001926593580443
        ]
      },
      "properties": {
        "tenDoiTuon": "26",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 72 Tôn Thất Tùng",
        "tuDien": "TB2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Tàu Bay 2",
        "diaChiLapD": "",
        "longitude": 105.82749568690734,
        "latitude": 21.001926593580443,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5533,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82755905409444,
          21.00162955325967
        ]
      },
      "properties": {
        "tenDoiTuon": "TB2 27",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 72 Tôn Thất Tùng",
        "tuDien": "TB2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Tàu Bay 2",
        "diaChiLapD": "",
        "longitude": 105.82755905409444,
        "latitude": 21.00162955325967,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5534,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82752418537585,
          21.002095302205763
        ]
      },
      "properties": {
        "tenDoiTuon": "28",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 72 Tôn Thất Tùng",
        "tuDien": "TB2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Tàu Bay 2",
        "diaChiLapD": "",
        "longitude": 105.82752418537585,
        "latitude": 21.002095302205763,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5535,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82754698415064,
          21.002246795500557
        ]
      },
      "properties": {
        "tenDoiTuon": "29",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 72 Tôn Thất Tùng",
        "tuDien": "TB2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Tàu Bay 2",
        "diaChiLapD": "",
        "longitude": 105.82754698415064,
        "latitude": 21.002246795500557,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5536,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82785275597693,
          21.002045221743156
        ]
      },
      "properties": {
        "tenDoiTuon": "30",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 72 Tôn Thất Tùng",
        "tuDien": "TB2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Tàu Bay 2",
        "diaChiLapD": "",
        "longitude": 105.82785275597693,
        "latitude": 21.002045221743156,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5537,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82807537932734,
          21.002229267358484
        ]
      },
      "properties": {
        "tenDoiTuon": "TB2 31",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 72 Tôn Thất Tùng",
        "tuDien": "TB2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 7,
        "tuDien2": "Tàu Bay 2",
        "diaChiLapD": "",
        "longitude": 105.82807537932734,
        "latitude": 21.002229267358484,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5538,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82786884923239,
          21.00188496415221
        ]
      },
      "properties": {
        "tenDoiTuon": "25",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 72 Tôn Thất Tùng",
        "tuDien": "TB2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Tàu Bay 2",
        "diaChiLapD": "",
        "longitude": 105.82786884923239,
        "latitude": 21.00188496415221,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5539,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82821351308893,
          21.00183613563362
        ]
      },
      "properties": {
        "tenDoiTuon": "24",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 72 Tôn Thất Tùng",
        "tuDien": "TB2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Tàu Bay 2",
        "diaChiLapD": "",
        "longitude": 105.82821351308893,
        "latitude": 21.00183613563362,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5540,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82848307510176,
          21.001752250704055
        ]
      },
      "properties": {
        "tenDoiTuon": "22",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 72 Tôn Thất Tùng",
        "tuDien": "TB2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Tàu Bay 2",
        "diaChiLapD": "",
        "longitude": 105.82848307510176,
        "latitude": 21.001752250704055,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5541,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82844418306253,
          21.00158448070435
        ]
      },
      "properties": {
        "tenDoiTuon": "23",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 72 Tôn Thất Tùng",
        "tuDien": "TB2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Tàu Bay 2",
        "diaChiLapD": "",
        "longitude": 105.82844418306253,
        "latitude": 21.00158448070435,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5542,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82839992661683,
          21.00144175085448
        ]
      },
      "properties": {
        "tenDoiTuon": "TB2 23-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 72 Tôn Thất Tùng",
        "tuDien": "TB2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Tàu Bay 2",
        "diaChiLapD": "",
        "longitude": 105.82839992661683,
        "latitude": 21.00144175085448,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5543,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82890015860325,
          21.00168213789104
        ]
      },
      "properties": {
        "tenDoiTuon": "21",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 72 Tôn Thất Tùng",
        "tuDien": "TB2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Tàu Bay 2",
        "diaChiLapD": "",
        "longitude": 105.82890015860325,
        "latitude": 21.00168213789104,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5544,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82925119270095,
          21.001543477187553
        ]
      },
      "properties": {
        "tenDoiTuon": "TB2 20",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 72 Tôn Thất Tùng",
        "tuDien": "TB2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Tàu Bay 2",
        "diaChiLapD": "",
        "longitude": 105.82925119270095,
        "latitude": 21.001543477187553,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5545,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8293182479394,
          21.00204177871198
        ]
      },
      "properties": {
        "tenDoiTuon": "16",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 54 Tôn Thất Tùng",
        "tuDien": "TB2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Tàu Bay 2",
        "diaChiLapD": "",
        "longitude": 105.8293182479394,
        "latitude": 21.00204177871198,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5546,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82923241723778,
          21.002259628605138
        ]
      },
      "properties": {
        "tenDoiTuon": "TB2 19",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 54 Tôn Thất Tùng",
        "tuDien": "TB2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Tàu Bay 2",
        "diaChiLapD": "",
        "longitude": 105.82923241723778,
        "latitude": 21.002259628605138,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5547,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8289896773293,
          21.00212190744562
        ]
      },
      "properties": {
        "tenDoiTuon": "17",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 54 Tôn Thất Tùng",
        "tuDien": "TB2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Tàu Bay 2",
        "diaChiLapD": "",
        "longitude": 105.8289896773293,
        "latitude": 21.00212190744562,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5548,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82885824908708,
          21.002141939621865
        ]
      },
      "properties": {
        "tenDoiTuon": "TB2 18",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 54 Tôn Thất Tùng",
        "tuDien": "TB2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Tàu Bay 2",
        "diaChiLapD": "",
        "longitude": 105.82885824908708,
        "latitude": 21.002141939621865,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5549,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82230963577679,
          21.01371195023591
        ]
      },
      "properties": {
        "tenDoiTuon": "TH2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Đặng Tiến Đông",
        "tuDien": "TH2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TH2",
        "diaChiLapD": "",
        "longitude": 105.82230963577679,
        "latitude": 21.01371195023591,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5550,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82236327994961,
          21.013661873673247
        ]
      },
      "properties": {
        "tenDoiTuon": "TH2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Đặng Tiến Đông",
        "tuDien": "TH2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TH2",
        "diaChiLapD": "",
        "longitude": 105.82236327994961,
        "latitude": 21.013661873673247,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5551,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8224115597159,
          21.01360678943474
        ]
      },
      "properties": {
        "tenDoiTuon": "TH2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Đặng Tiến Đông",
        "tuDien": "TH2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TH2",
        "diaChiLapD": "",
        "longitude": 105.8224115597159,
        "latitude": 21.01360678943474,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5552,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82259897903353,
          21.003217100151698
        ]
      },
      "properties": {
        "tenDoiTuon": "19",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 580 Trường Chinh",
        "tuDien": "LKT1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Làng Khương Thượng 1",
        "diaChiLapD": "",
        "longitude": 105.82259897903353,
        "latitude": 21.003217100151698,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5553,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82239915446694,
          21.00330724426346
        ]
      },
      "properties": {
        "tenDoiTuon": "23",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 592 Trường Chinh",
        "tuDien": "LKT1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Làng Khương Thượng 1",
        "diaChiLapD": "",
        "longitude": 105.82239915446694,
        "latitude": 21.00330724426346,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5554,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82223822192155,
          21.003053087808528
        ]
      },
      "properties": {
        "tenDoiTuon": "24",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 592 Trường Chinh",
        "tuDien": "LKT1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Làng Khương Thượng 1",
        "diaChiLapD": "",
        "longitude": 105.82223822192155,
        "latitude": 21.003053087808528,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5555,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82210679367934,
          21.00282647503824
        ]
      },
      "properties": {
        "tenDoiTuon": "LKT1 25",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 592 Trường Chinh",
        "tuDien": "LKT1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Làng Khương Thượng 1",
        "diaChiLapD": "",
        "longitude": 105.82210679367934,
        "latitude": 21.00282647503824,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5556,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8221027703677,
          21.00311067994984
        ]
      },
      "properties": {
        "tenDoiTuon": "24-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 592 Trường Chinh",
        "tuDien": "LKT1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Làng Khương Thượng 1",
        "diaChiLapD": "",
        "longitude": 105.8221027703677,
        "latitude": 21.00311067994984,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5557,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82214032129406,
          21.003408656324602
        ]
      },
      "properties": {
        "tenDoiTuon": "23-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 592 Trường Chinh",
        "tuDien": "LKT1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Làng Khương Thượng 1",
        "diaChiLapD": "",
        "longitude": 105.82214032129406,
        "latitude": 21.003408656324602,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5558,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8221456857275,
          21.00357517227452
        ]
      },
      "properties": {
        "tenDoiTuon": "23-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 592 Trường Chinh",
        "tuDien": "LKT1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Làng Khương Thượng 1",
        "diaChiLapD": "",
        "longitude": 105.8221456857275,
        "latitude": 21.00357517227452,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5559,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82231332380123,
          21.00378801193982
        ]
      },
      "properties": {
        "tenDoiTuon": "LKT1 23-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 592 Trường Chinh",
        "tuDien": "LKT1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Làng Khương Thượng 1",
        "diaChiLapD": "",
        "longitude": 105.82231332380123,
        "latitude": 21.00378801193982,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5560,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82254399379279,
          21.003560148283704
        ]
      },
      "properties": {
        "tenDoiTuon": "22",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 592 Trường Chinh",
        "tuDien": "LKT1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Làng Khương Thượng 1",
        "diaChiLapD": "",
        "longitude": 105.82254399379279,
        "latitude": 21.003560148283704,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5561,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82267944534665,
          21.003814303875064
        ]
      },
      "properties": {
        "tenDoiTuon": "21",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 592 Trường Chinh",
        "tuDien": "LKT1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Làng Khương Thượng 1",
        "diaChiLapD": "",
        "longitude": 105.82267944534665,
        "latitude": 21.003814303875064,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5562,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82284306009976,
          21.00408723895368
        ]
      },
      "properties": {
        "tenDoiTuon": "LKT1 20",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 592 Trường Chinh",
        "tuDien": "LKT1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Làng Khương Thượng 1",
        "diaChiLapD": "",
        "longitude": 105.82284306009976,
        "latitude": 21.00408723895368,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5563,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82284306009976,
          21.00335231629512
        ]
      },
      "properties": {
        "tenDoiTuon": "18",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 580 Trường Chinh",
        "tuDien": "LKT1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Làng Khương Thượng 1",
        "diaChiLapD": "",
        "longitude": 105.82284306009976,
        "latitude": 21.00335231629512,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5564,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82299058159744,
          21.00361022823629
        ]
      },
      "properties": {
        "tenDoiTuon": "17",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 580 Trường Chinh",
        "tuDien": "LKT1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Làng Khương Thượng 1",
        "diaChiLapD": "",
        "longitude": 105.82299058159744,
        "latitude": 21.00361022823629,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5565,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82317163070985,
          21.00394075550911
        ]
      },
      "properties": {
        "tenDoiTuon": "16",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 580 Trường Chinh",
        "tuDien": "LKT1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Làng Khương Thượng 1",
        "diaChiLapD": "",
        "longitude": 105.82317163070985,
        "latitude": 21.00394075550911,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5566,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82330708226371,
          21.00420743038893
        ]
      },
      "properties": {
        "tenDoiTuon": "15",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 580 Trường Chinh",
        "tuDien": "LKT1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Làng Khương Thượng 1",
        "diaChiLapD": "",
        "longitude": 105.82330708226371,
        "latitude": 21.00420743038893,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5567,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82343448719433,
          21.004462836868758
        ]
      },
      "properties": {
        "tenDoiTuon": "15-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 580 Trường Chinh",
        "tuDien": "LKT1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Làng Khương Thượng 1",
        "diaChiLapD": "",
        "longitude": 105.82343448719433,
        "latitude": 21.004462836868758,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5568,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82359407862684,
          21.004679431237815
        ]
      },
      "properties": {
        "tenDoiTuon": "15-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 580 Trường Chinh",
        "tuDien": "LKT1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Làng Khương Thượng 1",
        "diaChiLapD": "",
        "longitude": 105.82359407862684,
        "latitude": 21.004679431237815,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5569,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82295437177494,
          21.003009267682014
        ]
      },
      "properties": {
        "tenDoiTuon": "13",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 554 Trường Chinh",
        "tuDien": "LKT1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Làng Khương Thượng 1",
        "diaChiLapD": "",
        "longitude": 105.82295437177494,
        "latitude": 21.003009267682014,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5570,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82277734598316,
          21.002731322607797
        ]
      },
      "properties": {
        "tenDoiTuon": "LKT1 14",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 554 Trường Chinh",
        "tuDien": "LKT1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Làng Khương Thượng 1",
        "diaChiLapD": "",
        "longitude": 105.82277734598316,
        "latitude": 21.002731322607797,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5571,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82328696568767,
          21.002946667485542
        ]
      },
      "properties": {
        "tenDoiTuon": "13-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 554 Trường Chinh",
        "tuDien": "LKT1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Làng Khương Thượng 1",
        "diaChiLapD": "",
        "longitude": 105.82328696568767,
        "latitude": 21.002946667485542,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5572,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82317967733307,
          21.003228368164123
        ]
      },
      "properties": {
        "tenDoiTuon": "12",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 554 Trường Chinh",
        "tuDien": "LKT1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Làng Khương Thượng 1",
        "diaChiLapD": "",
        "longitude": 105.82317967733307,
        "latitude": 21.003228368164123,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5573,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83464109170073,
          21.00489383431519
        ]
      },
      "properties": {
        "tenDoiTuon": "KLC7/5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 156 Phương Mai",
        "tuDien": "KLC7",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KLC7",
        "diaChiLapD": "",
        "longitude": 105.83464109170073,
        "latitude": 21.00489383431519,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5574,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81927538682686,
          21.019117616332146
        ]
      },
      "properties": {
        "tenDoiTuon": "54",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 174 phố Mai Anh Tuấn",
        "tuDien": "HC6",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "HC6",
        "diaChiLapD": "",
        "longitude": 105.81927538682686,
        "latitude": 21.019117616332146,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5575,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8191318886408,
          21.019435590588568
        ]
      },
      "properties": {
        "tenDoiTuon": "55",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 1,
        "maXaPhuong": 34,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 174 phố Mai Anh Tuấn",
        "tuDien": "HC6",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "HC6",
        "diaChiLapD": "",
        "longitude": 105.8191318886408,
        "latitude": 21.019435590588568,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5576,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81917346287877,
          21.019721015721032
        ]
      },
      "properties": {
        "tenDoiTuon": "56",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 1,
        "maXaPhuong": 34,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 174 phố Mai Anh Tuấn",
        "tuDien": "HC6",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "HC6",
        "diaChiLapD": "",
        "longitude": 105.81917346287877,
        "latitude": 21.019721015721032,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5577,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81929818560167,
          21.019624622206994
        ]
      },
      "properties": {
        "tenDoiTuon": "56-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 1,
        "maXaPhuong": 34,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 174 phố Mai Anh Tuấn",
        "tuDien": "HC6",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "HC6",
        "diaChiLapD": "",
        "longitude": 105.81929818560167,
        "latitude": 21.019624622206994,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5578,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81952617336752,
          21.01971851199452
        ]
      },
      "properties": {
        "tenDoiTuon": "56-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 1,
        "maXaPhuong": 34,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 174 phố Mai Anh Tuấn",
        "tuDien": "HC6",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "HC6",
        "diaChiLapD": "",
        "longitude": 105.81952617336752,
        "latitude": 21.01971851199452,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5579,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8199177758865,
          21.019778601427575
        ]
      },
      "properties": {
        "tenDoiTuon": "56-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 174 phố Mai Anh Tuấn",
        "tuDien": "HC6",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "HC6",
        "diaChiLapD": "",
        "longitude": 105.8199177758865,
        "latitude": 21.019778601427575,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5580,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81967637707294,
          21.02000143286365
        ]
      },
      "properties": {
        "tenDoiTuon": "58",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 1,
        "maXaPhuong": 34,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 174 phố Mai Anh Tuấn",
        "tuDien": "HC6",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "HC6",
        "diaChiLapD": "",
        "longitude": 105.81967637707294,
        "latitude": 21.02000143286365,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5581,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81973404456635,
          21.0197623272087
        ]
      },
      "properties": {
        "tenDoiTuon": "56-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 174 phố Mai Anh Tuấn",
        "tuDien": "HC6",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "HC6",
        "diaChiLapD": "",
        "longitude": 105.81973404456635,
        "latitude": 21.0197623272087,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5582,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8197179513109,
          21.020202982358942
        ]
      },
      "properties": {
        "tenDoiTuon": "59",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 1,
        "maXaPhuong": 34,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 174 phố Mai Anh Tuấn",
        "tuDien": "HC6",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "HC6",
        "diaChiLapD": "",
        "longitude": 105.8197179513109,
        "latitude": 21.020202982358942,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5583,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81928477455395,
          21.020052759158485
        ]
      },
      "properties": {
        "tenDoiTuon": "57",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 1,
        "maXaPhuong": 34,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 174 phố Mai Anh Tuấn",
        "tuDien": "HC6",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "HC6",
        "diaChiLapD": "",
        "longitude": 105.81928477455395,
        "latitude": 21.020052759158485,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5584,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81919089724705,
          21.02002271450021
        ]
      },
      "properties": {
        "tenDoiTuon": "60",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 1,
        "maXaPhuong": 34,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 29 Láng Hạ",
        "tuDien": "HC6",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "HC6",
        "diaChiLapD": "",
        "longitude": 105.81919089724705,
        "latitude": 21.02002271450021,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5585,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81900850703079,
          21.019674696767485
        ]
      },
      "properties": {
        "tenDoiTuon": "62",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 1,
        "maXaPhuong": 34,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 29 Láng Hạ",
        "tuDien": "HC6",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "HC6",
        "diaChiLapD": "",
        "longitude": 105.81900850703079,
        "latitude": 21.019674696767485,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5586,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81891999413936,
          21.019381760349138
        ]
      },
      "properties": {
        "tenDoiTuon": "63",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 1,
        "maXaPhuong": 34,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 29 Láng Hạ",
        "tuDien": "HC6",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "HC6",
        "diaChiLapD": "",
        "longitude": 105.81891999413936,
        "latitude": 21.019381760349138,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5587,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81907556226025,
          21.019839942699083
        ]
      },
      "properties": {
        "tenDoiTuon": "61",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 1,
        "maXaPhuong": 34,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 29 Láng Hạ",
        "tuDien": "HC6",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "HC6",
        "diaChiLapD": "",
        "longitude": 105.81907556226025,
        "latitude": 21.019839942699083,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5588,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82093433311219,
          21.016370991579002
        ]
      },
      "properties": {
        "tenDoiTuon": "48A",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Mai Anh Tuấn",
        "tuDien": "HC6",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Sân vườn",
        "chieuCao": 5,
        "tuDien2": "HC6",
        "diaChiLapD": "",
        "longitude": 105.82093433311219,
        "latitude": 21.016370991579002,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5589,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82054004838807,
          21.0159766452214
        ]
      },
      "properties": {
        "tenDoiTuon": "46B",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Mai Anh Tuấn",
        "tuDien": "HC6",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Sân vườn",
        "chieuCao": 5,
        "tuDien2": "HC6",
        "diaChiLapD": "",
        "longitude": 105.82054004838807,
        "latitude": 21.0159766452214,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5590,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8207465784804,
          21.016178200156737
        ]
      },
      "properties": {
        "tenDoiTuon": "47C",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Mai Anh Tuấn",
        "tuDien": "HC6",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Sân vườn",
        "chieuCao": 5,
        "tuDien2": "HC6",
        "diaChiLapD": "",
        "longitude": 105.8207465784804,
        "latitude": 21.016178200156737,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5591,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82030669620673,
          21.01587774549512
        ]
      },
      "properties": {
        "tenDoiTuon": "45A",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Mai Anh Tuấn",
        "tuDien": "HC6",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 10,
        "tuDien2": "HC6",
        "diaChiLapD": "",
        "longitude": 105.82030669620673,
        "latitude": 21.01587774549512,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5592,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82004786303385,
          21.016090567610046
        ]
      },
      "properties": {
        "tenDoiTuon": "44C",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Mai Anh Tuấn",
        "tuDien": "HC6",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 10,
        "tuDien2": "HC6",
        "diaChiLapD": "",
        "longitude": 105.82004786303385,
        "latitude": 21.016090567610046,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5593,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81971526911215,
          21.016069285412296
        ]
      },
      "properties": {
        "tenDoiTuon": "43A",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Mai Anh Tuấn",
        "tuDien": "HC6",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 10,
        "tuDien2": "HC6",
        "diaChiLapD": "",
        "longitude": 105.81971526911215,
        "latitude": 21.016069285412296,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5594,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81972197464049,
          21.015944095952
        ]
      },
      "properties": {
        "tenDoiTuon": "50",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 255 Đặng Tiến Đông",
        "tuDien": "HC6",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "HC6",
        "diaChiLapD": "",
        "longitude": 105.81972197464049,
        "latitude": 21.015944095952,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5595,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81990704705554,
          21.01559606870051
        ]
      },
      "properties": {
        "tenDoiTuon": "49",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 255 Đặng Tiến Đông",
        "tuDien": "HC6",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "HC6",
        "diaChiLapD": "",
        "longitude": 105.81990704705554,
        "latitude": 21.01559606870051,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5596,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8193706052556,
          21.015949103532567
        ]
      },
      "properties": {
        "tenDoiTuon": "51",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 255 Đặng Tiến Đông",
        "tuDien": "HC6",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "HC6",
        "diaChiLapD": "",
        "longitude": 105.8193706052556,
        "latitude": 21.015949103532567,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5597,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81877129919938,
          21.016001839602346
        ]
      },
      "properties": {
        "tenDoiTuon": "53",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 255 Đặng Tiến Đông",
        "tuDien": "HC6",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "HC6",
        "diaChiLapD": "",
        "longitude": 105.81877129919938,
        "latitude": 21.016001839602346,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5598,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8190809266847,
          21.015944095952
        ]
      },
      "properties": {
        "tenDoiTuon": "52",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 255 Đặng Tiến Đông",
        "tuDien": "HC6",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "HC6",
        "diaChiLapD": "",
        "longitude": 105.8190809266847,
        "latitude": 21.015944095952,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5599,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81900230443324,
          21.016112945211017
        ]
      },
      "properties": {
        "tenDoiTuon": "52-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 255 Đặng Tiến Đông",
        "tuDien": "HC6",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "HC6",
        "diaChiLapD": "",
        "longitude": 105.81900230443324,
        "latitude": 21.016112945211017,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5600,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.819125350792,
          21.016272248599947
        ]
      },
      "properties": {
        "tenDoiTuon": "52-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 255 Đặng Tiến Đông",
        "tuDien": "HC6",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "HC6",
        "diaChiLapD": "",
        "longitude": 105.819125350792,
        "latitude": 21.016272248599947,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5601,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81913507377934,
          21.016468482601457
        ]
      },
      "properties": {
        "tenDoiTuon": "52-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 255 Đặng Tiến Đông",
        "tuDien": "HC6",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "HC6",
        "diaChiLapD": "",
        "longitude": 105.81913507377934,
        "latitude": 21.016468482601457,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5602,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81871497280983,
          21.01682057645083
        ]
      },
      "properties": {
        "tenDoiTuon": "52-8",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 255 Đặng Tiến Đông",
        "tuDien": "HC6",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "HC6",
        "diaChiLapD": "",
        "longitude": 105.81871497280983,
        "latitude": 21.01682057645083,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5603,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81893524920375,
          21.0168039889377
        ]
      },
      "properties": {
        "tenDoiTuon": "52-9",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 255 Đặng Tiến Đông",
        "tuDien": "HC6",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 5,
        "tuDien2": "HC6",
        "diaChiLapD": "",
        "longitude": 105.81893524920375,
        "latitude": 21.0168039889377,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5604,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81867641603985,
          21.016513550660328
        ]
      },
      "properties": {
        "tenDoiTuon": "52-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 255 Đặng Tiến Đông",
        "tuDien": "HC6",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "HC6",
        "diaChiLapD": "",
        "longitude": 105.81867641603985,
        "latitude": 21.016513550660328,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5605,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81883198415176,
          21.01655486303573
        ]
      },
      "properties": {
        "tenDoiTuon": "52-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 255 Đặng Tiến Đông",
        "tuDien": "HC6",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "HC6",
        "diaChiLapD": "",
        "longitude": 105.81883198415176,
        "latitude": 21.01655486303573,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5606,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81898487006494,
          21.01656988571541
        ]
      },
      "properties": {
        "tenDoiTuon": "52-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 255 Đặng Tiến Đông",
        "tuDien": "HC6",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "HC6",
        "diaChiLapD": "",
        "longitude": 105.81898487006494,
        "latitude": 21.01656988571541,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5607,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81881589090533,
          21.016680051983347
        ]
      },
      "properties": {
        "tenDoiTuon": "52-7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 255 Đặng Tiến Đông",
        "tuDien": "HC6",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "HC6",
        "diaChiLapD": "",
        "longitude": 105.81881589090533,
        "latitude": 21.016680051983347,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5608,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83727736789055,
          21.02083172740328
        ]
      },
      "properties": {
        "tenDoiTuon": "3-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "TT E2 Văn Chương",
        "tuDien": "VC2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VC2",
        "diaChiLapD": "",
        "longitude": 105.83727736789055,
        "latitude": 21.02083172740328,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5609,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83771783200211,
          21.020780065462198
        ]
      },
      "properties": {
        "tenDoiTuon": "3-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 109 Văn Chương",
        "tuDien": "VC2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VC2",
        "diaChiLapD": "",
        "longitude": 105.83771783200211,
        "latitude": 21.020780065462198,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5610,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83795709867668,
          21.02074296627116
        ]
      },
      "properties": {
        "tenDoiTuon": "3-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 109 Văn Chương",
        "tuDien": "VC2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 7,
        "tuDien2": "VC2",
        "diaChiLapD": "",
        "longitude": 105.83795709867668,
        "latitude": 21.02074296627116,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5611,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82799658942326,
          21.01513067487473
        ]
      },
      "properties": {
        "tenDoiTuon": "LT1 16",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "",
        "tuDien": "LT1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "LT1",
        "diaChiLapD": "",
        "longitude": 105.82799658942326,
        "latitude": 21.01513067487473,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5612,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8281664626611,
          21.015415551847564
        ]
      },
      "properties": {
        "tenDoiTuon": "LT1 17",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "",
        "tuDien": "LT1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "LT1",
        "diaChiLapD": "",
        "longitude": 105.8281664626611,
        "latitude": 21.015415551847564,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5613,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82833633589895,
          21.01570042827625
        ]
      },
      "properties": {
        "tenDoiTuon": "LT1 18",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "",
        "tuDien": "LT1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "LT1",
        "diaChiLapD": "",
        "longitude": 105.82833633589895,
        "latitude": 21.01570042827625,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5614,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82850620913678,
          21.015985304160775
        ]
      },
      "properties": {
        "tenDoiTuon": "LT1 19",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "",
        "tuDien": "LT1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "LT1",
        "diaChiLapD": "",
        "longitude": 105.82850620913678,
        "latitude": 21.015985304160775,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5615,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82867608237461,
          21.016270179501134
        ]
      },
      "properties": {
        "tenDoiTuon": "LT1 20",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "",
        "tuDien": "LT1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "LT1",
        "diaChiLapD": "",
        "longitude": 105.82867608237461,
        "latitude": 21.016270179501134,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5616,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82884595561246,
          21.016555054297342
        ]
      },
      "properties": {
        "tenDoiTuon": "LT1 21",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "",
        "tuDien": "LT1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "LT1",
        "diaChiLapD": "",
        "longitude": 105.82884595561246,
        "latitude": 21.016555054297342,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5617,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8290158288503,
          21.016839928549373
        ]
      },
      "properties": {
        "tenDoiTuon": "LT1 22",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "",
        "tuDien": "LT1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "LT1",
        "diaChiLapD": "",
        "longitude": 105.8290158288503,
        "latitude": 21.016839928549373,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5618,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82918570208814,
          21.01712480225724
        ]
      },
      "properties": {
        "tenDoiTuon": "LT1 23",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "",
        "tuDien": "LT1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "LT1",
        "diaChiLapD": "",
        "longitude": 105.82918570208814,
        "latitude": 21.01712480225724,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5619,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82935557532598,
          21.017409675420886
        ]
      },
      "properties": {
        "tenDoiTuon": "LT1 24",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "",
        "tuDien": "LT1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "LT1",
        "diaChiLapD": "",
        "longitude": 105.82935557532598,
        "latitude": 21.017409675420886,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5620,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82952544856383,
          21.01769454804037
        ]
      },
      "properties": {
        "tenDoiTuon": "LT1 25",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "",
        "tuDien": "LT1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "LT1",
        "diaChiLapD": "",
        "longitude": 105.82952544856383,
        "latitude": 21.01769454804037,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5621,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82785175013332,
          21.015195773761498
        ]
      },
      "properties": {
        "tenDoiTuon": "LT1 15",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "",
        "tuDien": "LT1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "LT1",
        "diaChiLapD": "",
        "longitude": 105.82785175013332,
        "latitude": 21.015195773761498,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5622,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82802162337117,
          21.015478981411327
        ]
      },
      "properties": {
        "tenDoiTuon": "LT1 14",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "",
        "tuDien": "LT1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "LT1",
        "diaChiLapD": "",
        "longitude": 105.82802162337117,
        "latitude": 21.015478981411327,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5623,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82819149660901,
          21.015762188523347
        ]
      },
      "properties": {
        "tenDoiTuon": "LT1 13",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "",
        "tuDien": "LT1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "LT1",
        "diaChiLapD": "",
        "longitude": 105.82819149660901,
        "latitude": 21.015762188523347,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5624,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82836136984685,
          21.016045395097624
        ]
      },
      "properties": {
        "tenDoiTuon": "LT1 12",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "",
        "tuDien": "LT1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "LT1",
        "diaChiLapD": "",
        "longitude": 105.82836136984685,
        "latitude": 21.016045395097624,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5625,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82853124308468,
          21.016328601134045
        ]
      },
      "properties": {
        "tenDoiTuon": "LT1 11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "",
        "tuDien": "LT1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "LT1",
        "diaChiLapD": "",
        "longitude": 105.82853124308468,
        "latitude": 21.016328601134045,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5626,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82870111632253,
          21.01661180663267
        ]
      },
      "properties": {
        "tenDoiTuon": "LT1 10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "",
        "tuDien": "LT1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "LT1",
        "diaChiLapD": "",
        "longitude": 105.82870111632253,
        "latitude": 21.01661180663267,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5627,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82887098956036,
          21.016895011593494
        ]
      },
      "properties": {
        "tenDoiTuon": "LT1 9",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "",
        "tuDien": "LT1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "LT1",
        "diaChiLapD": "",
        "longitude": 105.82887098956036,
        "latitude": 21.016895011593494,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5628,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8290408627982,
          21.017178216016482
        ]
      },
      "properties": {
        "tenDoiTuon": "LT1 8",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "",
        "tuDien": "LT1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "LT1",
        "diaChiLapD": "",
        "longitude": 105.8290408627982,
        "latitude": 21.017178216016482,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5629,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82921073603605,
          21.017461419901654
        ]
      },
      "properties": {
        "tenDoiTuon": "LT1 7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "",
        "tuDien": "LT1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "LT1",
        "diaChiLapD": "",
        "longitude": 105.82921073603605,
        "latitude": 21.017461419901654,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5630,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82938060927388,
          21.017744623248966
        ]
      },
      "properties": {
        "tenDoiTuon": "LT1 6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "",
        "tuDien": "LT1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "LT1",
        "diaChiLapD": "",
        "longitude": 105.82938060927388,
        "latitude": 21.017744623248966,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5631,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82859203982045,
          21.01690335750779
        ]
      },
      "properties": {
        "tenDoiTuon": "LT1 10-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 92 NL Bằng",
        "tuDien": "LT1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "LT1",
        "diaChiLapD": "",
        "longitude": 105.82859203982045,
        "latitude": 21.01690335750779,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5632,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8285330312232,
          21.016768153642033
        ]
      },
      "properties": {
        "tenDoiTuon": "LT1 10-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 92 NL Bằng",
        "tuDien": "LT1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "LT1",
        "diaChiLapD": "",
        "longitude": 105.8285330312232,
        "latitude": 21.016768153642033,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5633,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82877979445222,
          21.017409119028148
        ]
      },
      "properties": {
        "tenDoiTuon": "LT1 7-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 64 NL Bằng",
        "tuDien": "LT1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "LT1",
        "diaChiLapD": "",
        "longitude": 105.82877979445222,
        "latitude": 21.017409119028148,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5634,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82901448774643,
          21.01734902864109
        ]
      },
      "properties": {
        "tenDoiTuon": "LT1 7-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 64 NL Bằng",
        "tuDien": "LT1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "LT1",
        "diaChiLapD": "",
        "longitude": 105.82901448774643,
        "latitude": 21.01734902864109,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5635,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82850218582517,
          21.0175643524191
        ]
      },
      "properties": {
        "tenDoiTuon": "LT1 7-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 64 NL Bằng",
        "tuDien": "LT1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "LT1",
        "diaChiLapD": "",
        "longitude": 105.82850218582517,
        "latitude": 21.0175643524191,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5636,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82866982388991,
          21.017305212718636
        ]
      },
      "properties": {
        "tenDoiTuon": "LT1 7-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 64 NL Bằng",
        "tuDien": "LT1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "LT1",
        "diaChiLapD": "",
        "longitude": 105.82866982388991,
        "latitude": 21.017305212718636,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5637,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82843626571587,
          21.017232893257738
        ]
      },
      "properties": {
        "tenDoiTuon": "LT1 7-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 64 NL Bằng",
        "tuDien": "LT1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "LT1",
        "diaChiLapD": "",
        "longitude": 105.82843626571587,
        "latitude": 21.017232893257738,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5638,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82969845104405,
          21.017541818549752
        ]
      },
      "properties": {
        "tenDoiTuon": "LT1 25-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 3 Xã Đàn",
        "tuDien": "LT1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 4,
        "tuDien2": "LT1",
        "diaChiLapD": "",
        "longitude": 105.82969845104405,
        "latitude": 21.017541818549752,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5639,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82991034556348,
          21.01742914915346
        ]
      },
      "properties": {
        "tenDoiTuon": "LT1 25-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 3 Xã Đàn",
        "tuDien": "LT1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 4,
        "tuDien2": "LT1",
        "diaChiLapD": "",
        "longitude": 105.82991034556348,
        "latitude": 21.01742914915346,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5640,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8301168756584,
          21.01706359896836
        ]
      },
      "properties": {
        "tenDoiTuon": "LT1 25-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 3 Xã Đàn",
        "tuDien": "LT1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 4,
        "tuDien2": "LT1",
        "diaChiLapD": "",
        "longitude": 105.8301168756584,
        "latitude": 21.01706359896836,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5641,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83010614681845,
          21.017303960834692
        ]
      },
      "properties": {
        "tenDoiTuon": "LT1 25-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 3 Xã Đàn",
        "tuDien": "LT1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 4,
        "tuDien2": "LT1",
        "diaChiLapD": "",
        "longitude": 105.83010614681845,
        "latitude": 21.017303960834692,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5642,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82935512829137,
          21.017141215863465
        ]
      },
      "properties": {
        "tenDoiTuon": "LT1 23-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 71 Nguyễn Lương Bằng",
        "tuDien": "LT1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "LT1",
        "diaChiLapD": "",
        "longitude": 105.82935512829137,
        "latitude": 21.017141215863465,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5643,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82949192095808,
          21.01706359896836
        ]
      },
      "properties": {
        "tenDoiTuon": "LT1 23-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 71 Nguyễn Lương Bằng",
        "tuDien": "LT1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "LT1",
        "diaChiLapD": "",
        "longitude": 105.82949192095808,
        "latitude": 21.01706359896836,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5644,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82929611969408,
          21.01670806299754
        ]
      },
      "properties": {
        "tenDoiTuon": "LT1 40",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 73 NL Bằng",
        "tuDien": "LT1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "LT1",
        "diaChiLapD": "",
        "longitude": 105.82929611969408,
        "latitude": 21.01670806299754,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5645,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82908958959915,
          21.016500249325876
        ]
      },
      "properties": {
        "tenDoiTuon": "LT1 40-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 73 NL Bằng",
        "tuDien": "LT1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "LT1",
        "diaChiLapD": "",
        "longitude": 105.82908958959915,
        "latitude": 21.016500249325876,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5646,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82920760679369,
          21.016863297116608
        ]
      },
      "properties": {
        "tenDoiTuon": "LT1 39",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 73 NL Bằng",
        "tuDien": "LT1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "LT1",
        "diaChiLapD": "",
        "longitude": 105.82920760679369,
        "latitude": 21.016863297116608,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5647,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82946509886268,
          21.01644766991714
        ]
      },
      "properties": {
        "tenDoiTuon": "LT1 41",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 73 NL Bằng",
        "tuDien": "LT1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "LT1",
        "diaChiLapD": "",
        "longitude": 105.82946509886268,
        "latitude": 21.01644766991714,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5648,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82925052214452,
          21.016187276382926
        ]
      },
      "properties": {
        "tenDoiTuon": "LT1 41-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 73 NL Bằng",
        "tuDien": "LT1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "LT1",
        "diaChiLapD": "",
        "longitude": 105.82925052214452,
        "latitude": 21.016187276382926,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5649,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82945705223946,
          21.016224833170543
        ]
      },
      "properties": {
        "tenDoiTuon": "LT1 41-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 73 NL Bằng",
        "tuDien": "LT1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "LT1",
        "diaChiLapD": "",
        "longitude": 105.82945705223946,
        "latitude": 21.016224833170543,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5650,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82938463259448,
          21.016127185502715
        ]
      },
      "properties": {
        "tenDoiTuon": "LT1 41-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 73 NL Bằng",
        "tuDien": "LT1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "LT1",
        "diaChiLapD": "",
        "longitude": 105.82938463259448,
        "latitude": 21.016127185502715,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5651,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82928807307087,
          21.01599698851223
        ]
      },
      "properties": {
        "tenDoiTuon": "LT1 41-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 73 NL Bằng",
        "tuDien": "LT1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "LT1",
        "diaChiLapD": "",
        "longitude": 105.82928807307087,
        "latitude": 21.01599698851223,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5652,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82980842161535,
          21.016477715295707
        ]
      },
      "properties": {
        "tenDoiTuon": "LT1 42",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 73 NL Bằng",
        "tuDien": "LT1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "LT1",
        "diaChiLapD": "",
        "longitude": 105.82980842161535,
        "latitude": 21.016477715295707,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5653,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82991570997895,
          21.016207306670676
        ]
      },
      "properties": {
        "tenDoiTuon": "LT1 43",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 73 NL Bằng",
        "tuDien": "LT1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "LT1",
        "diaChiLapD": "",
        "longitude": 105.82991570997895,
        "latitude": 21.016207306670676,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5654,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82988888788356,
          21.01588181415704
        ]
      },
      "properties": {
        "tenDoiTuon": "LT1 44",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 73 NL Bằng",
        "tuDien": "LT1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "LT1",
        "diaChiLapD": "",
        "longitude": 105.82988888788356,
        "latitude": 21.01588181415704,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5655,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82964748907898,
          21.015939401346174
        ]
      },
      "properties": {
        "tenDoiTuon": "LT1 44-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 73 NL Bằng",
        "tuDien": "LT1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "LT1",
        "diaChiLapD": "",
        "longitude": 105.82964748907898,
        "latitude": 21.015939401346174,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5656,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82958579826497,
          21.01581921936176
        ]
      },
      "properties": {
        "tenDoiTuon": "LT1 44-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 73 NL Bằng",
        "tuDien": "LT1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "LT1",
        "diaChiLapD": "",
        "longitude": 105.82958579826497,
        "latitude": 21.01581921936176,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5657,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8298593835894,
          21.015681510718814
        ]
      },
      "properties": {
        "tenDoiTuon": "LT1 44-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 73 NL Bằng",
        "tuDien": "LT1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "LT1",
        "diaChiLapD": "",
        "longitude": 105.8298593835894,
        "latitude": 21.015681510718814,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5658,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82982719707854,
          21.01546618422227
        ]
      },
      "properties": {
        "tenDoiTuon": "LT1 45",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 73 NL Bằng",
        "tuDien": "LT1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "LT1",
        "diaChiLapD": "",
        "longitude": 105.82982719707854,
        "latitude": 21.01546618422227,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5659,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82976818848127,
          21.01501800365685
        ]
      },
      "properties": {
        "tenDoiTuon": "LT1 46",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 73 NL Bằng",
        "tuDien": "LT1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "LT1",
        "diaChiLapD": "",
        "longitude": 105.82976818848127,
        "latitude": 21.01501800365685,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5660,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82959920931266,
          21.014815195248815
        ]
      },
      "properties": {
        "tenDoiTuon": "LT1 47",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 73 NL Bằng",
        "tuDien": "LT1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "LT1",
        "diaChiLapD": "",
        "longitude": 105.82959920931266,
        "latitude": 21.014815195248815,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5661,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82975477743359,
          21.014757607626446
        ]
      },
      "properties": {
        "tenDoiTuon": "LT1 47-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 73 NL Bằng",
        "tuDien": "LT1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "LT1",
        "diaChiLapD": "",
        "longitude": 105.82975477743359,
        "latitude": 21.014757607626446,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5662,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82988888788356,
          21.01472756190135
        ]
      },
      "properties": {
        "tenDoiTuon": "LT1 47-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 73 NL Bằng",
        "tuDien": "LT1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "LT1",
        "diaChiLapD": "",
        "longitude": 105.82988888788356,
        "latitude": 21.01472756190135,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5663,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82949460316581,
          21.014609882752758
        ]
      },
      "properties": {
        "tenDoiTuon": "LT1 48",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 73 NL Bằng",
        "tuDien": "LT1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "LT1",
        "diaChiLapD": "",
        "longitude": 105.82949460316581,
        "latitude": 21.014609882752758,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5664,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82936585713132,
          21.014942889464308
        ]
      },
      "properties": {
        "tenDoiTuon": "LT1 47-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 73 NL Bằng",
        "tuDien": "LT1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "LT1",
        "diaChiLapD": "",
        "longitude": 105.82936585713132,
        "latitude": 21.014942889464308,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5665,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82927197981545,
          21.01479015715478
        ]
      },
      "properties": {
        "tenDoiTuon": "LT1 47-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 73 NL Bằng",
        "tuDien": "LT1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "LT1",
        "diaChiLapD": "",
        "longitude": 105.82927197981545,
        "latitude": 21.01479015715478,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5666,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8284968214097,
          21.015431131042114
        ]
      },
      "properties": {
        "tenDoiTuon": "LT1 17-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 85 NLB",
        "tuDien": "LT1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": "",
        "tuDien2": "LT1",
        "diaChiLapD": "",
        "longitude": 105.8284968214097,
        "latitude": 21.015431131042114,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5667,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82845390606785,
          21.015476199414476
        ]
      },
      "properties": {
        "tenDoiTuon": "LT1 17-7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 85 NLB",
        "tuDien": "LT1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": "",
        "tuDien2": "LT1",
        "diaChiLapD": "",
        "longitude": 105.82845390606785,
        "latitude": 21.015476199414476,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5668,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82854778338374,
          21.01515320910928
        ]
      },
      "properties": {
        "tenDoiTuon": "LT1 17-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 85 NLB",
        "tuDien": "LT1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 11,
        "tuDien2": "LT1",
        "diaChiLapD": "",
        "longitude": 105.82854778338374,
        "latitude": 21.01515320910928,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5669,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8285236434961,
          21.015258368820103
        ]
      },
      "properties": {
        "tenDoiTuon": "LT1 17-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 85 NLB",
        "tuDien": "LT1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": "",
        "tuDien2": "LT1",
        "diaChiLapD": "",
        "longitude": 105.8285236434961,
        "latitude": 21.015258368820103,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5670,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82866043616278,
          21.015203285171207
        ]
      },
      "properties": {
        "tenDoiTuon": "LT1 17-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 85 NLB",
        "tuDien": "LT1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": "",
        "tuDien2": "LT1",
        "diaChiLapD": "",
        "longitude": 105.82866043616278,
        "latitude": 21.015203285171207,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5671,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82877577114961,
          21.014985454178216
        ]
      },
      "properties": {
        "tenDoiTuon": "LT1 17-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 85 NLB",
        "tuDien": "LT1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": "",
        "tuDien2": "LT1",
        "diaChiLapD": "",
        "longitude": 105.82877577114961,
        "latitude": 21.014985454178216,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5672,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82871139812785,
          21.014765119056573
        ]
      },
      "properties": {
        "tenDoiTuon": "LT1 17-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 85 NLB",
        "tuDien": "LT1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": "",
        "tuDien2": "LT1",
        "diaChiLapD": "",
        "longitude": 105.82871139812785,
        "latitude": 21.014765119056573,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5673,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82887367178608,
          21.01582297504931
        ]
      },
      "properties": {
        "tenDoiTuon": "LT1 19-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 115 Nam Đồng",
        "tuDien": "LT1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "LT1",
        "diaChiLapD": "",
        "longitude": 105.82887367178608,
        "latitude": 21.01582297504931,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5674,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82877979447018,
          21.015605144961416
        ]
      },
      "properties": {
        "tenDoiTuon": "LT1 19-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 115 Nam Đồng",
        "tuDien": "LT1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "LT1",
        "diaChiLapD": "",
        "longitude": 105.82877979447018,
        "latitude": 21.015605144961416,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5675,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82908824851323,
          21.015705296765834
        ]
      },
      "properties": {
        "tenDoiTuon": "LT1 19-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 115 Nam Đồng",
        "tuDien": "LT1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "LT1",
        "diaChiLapD": "",
        "longitude": 105.82908824851323,
        "latitude": 21.015705296765834,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5676,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82925990988957,
          21.015600137369287
        ]
      },
      "properties": {
        "tenDoiTuon": "LT1 19-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 115 Nam Đồng",
        "tuDien": "LT1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "LT1",
        "diaChiLapD": "",
        "longitude": 105.82925990988957,
        "latitude": 21.015600137369287,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5677,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82911775280739,
          21.015429879142445
        ]
      },
      "properties": {
        "tenDoiTuon": "LT1 19-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 115 Nam Đồng",
        "tuDien": "LT1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "LT1",
        "diaChiLapD": "",
        "longitude": 105.82911775280739,
        "latitude": 21.015429879142445,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5678,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8289165871279,
          21.015229575096416
        ]
      },
      "properties": {
        "tenDoiTuon": "LT1 19-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 115 Nam Đồng",
        "tuDien": "LT1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "LT1",
        "diaChiLapD": "",
        "longitude": 105.8289165871279,
        "latitude": 21.015229575096416,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5679,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82940206698974,
          21.014403318065717
        ]
      },
      "properties": {
        "tenDoiTuon": "LT1 49",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 73 NL Bằng",
        "tuDien": "LT1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "LT1",
        "diaChiLapD": "",
        "longitude": 105.82940206698974,
        "latitude": 21.014403318065717,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5680,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82929477862616,
          21.014253089022837
        ]
      },
      "properties": {
        "tenDoiTuon": "LT1 50",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 73 NL Bằng",
        "tuDien": "LT1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "LT1",
        "diaChiLapD": "",
        "longitude": 105.82929477862616,
        "latitude": 21.014253089022837,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5681,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82947180441799,
          21.01417296680417
        ]
      },
      "properties": {
        "tenDoiTuon": "LT1 51",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 73 NL Bằng",
        "tuDien": "LT1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "LT1",
        "diaChiLapD": "",
        "longitude": 105.82947180441799,
        "latitude": 21.01417296680417,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5682,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82971856764702,
          21.0144634096405
        ]
      },
      "properties": {
        "tenDoiTuon": "LT1 48-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 73 NL Bằng",
        "tuDien": "LT1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "LT1",
        "diaChiLapD": "",
        "longitude": 105.82971856764702,
        "latitude": 21.0144634096405,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5683,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82990095786332,
          21.014303165386995
        ]
      },
      "properties": {
        "tenDoiTuon": "LT1 48-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 73 NL Bằng",
        "tuDien": "LT1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "LT1",
        "diaChiLapD": "",
        "longitude": 105.82990095786332,
        "latitude": 21.014303165386995,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5684,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83000288180241,
          21.014398310433247
        ]
      },
      "properties": {
        "tenDoiTuon": "LT1 48-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 73 NL Bằng",
        "tuDien": "LT1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "LT1",
        "diaChiLapD": "",
        "longitude": 105.83000288180241,
        "latitude": 21.014398310433247,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5685,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83741348999185,
          21.020981323892794
        ]
      },
      "properties": {
        "tenDoiTuon": "3-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Sân Chơi Nhà E1",
        "tuDien": "VC2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 7,
        "tuDien2": "VC2",
        "diaChiLapD": "",
        "longitude": 105.83741348999185,
        "latitude": 21.020981323892794,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5686,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83719488995307,
          21.020990086862437
        ]
      },
      "properties": {
        "tenDoiTuon": "4-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Sân Chơi Nhà E1",
        "tuDien": "VC2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VC2",
        "diaChiLapD": "",
        "longitude": 105.83719488995307,
        "latitude": 21.020990086862437,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5687,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83706712276924,
          21.0211416516633
        ]
      },
      "properties": {
        "tenDoiTuon": "4-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Sân Chơi Nhà E1",
        "tuDien": "VC2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 7,
        "tuDien2": "VC2",
        "diaChiLapD": "",
        "longitude": 105.83706712276924,
        "latitude": 21.0211416516633,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5688,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83730754273213,
          21.021234197951614
        ]
      },
      "properties": {
        "tenDoiTuon": "16",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Nhà D1 TT Văn Chương",
        "tuDien": "VC2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VC2",
        "diaChiLapD": "",
        "longitude": 105.83730754273213,
        "latitude": 21.021234197951614,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5689,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83696421997945,
          21.021234197951614
        ]
      },
      "properties": {
        "tenDoiTuon": "17",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Nhà D1 TT Văn Chương",
        "tuDien": "VC2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VC2",
        "diaChiLapD": "",
        "longitude": 105.83696421997945,
        "latitude": 21.021234197951614,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5690,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83691594021315,
          21.02098633130419
        ]
      },
      "properties": {
        "tenDoiTuon": "4-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Sân Chơi Nhà E1",
        "tuDien": "VC2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VC2",
        "diaChiLapD": "",
        "longitude": 105.83691594021315,
        "latitude": 21.02098633130419,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5691,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8367094101272,
          21.020965049805184
        ]
      },
      "properties": {
        "tenDoiTuon": "4-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Sân Chơi Nhà E1",
        "tuDien": "VC2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "VC2",
        "diaChiLapD": "",
        "longitude": 105.8367094101272,
        "latitude": 21.020965049805184,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5692,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83676573651672,
          21.02124045720459
        ]
      },
      "properties": {
        "tenDoiTuon": "18",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Nhà D1 TT Văn Chương",
        "tuDien": "VC2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 7,
        "tuDien2": "VC2",
        "diaChiLapD": "",
        "longitude": 105.83676573651672,
        "latitude": 21.02124045720459,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5693,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83654311316636,
          21.02124922015903
        ]
      },
      "properties": {
        "tenDoiTuon": "19",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Nhà D1 TT Văn Chương",
        "tuDien": "VC2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VC2",
        "diaChiLapD": "",
        "longitude": 105.83654311316636,
        "latitude": 21.02124922015903,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5694,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83640900271635,
          21.021294286773827
        ]
      },
      "properties": {
        "tenDoiTuon": "20",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Nhà D1 TT Văn Chương",
        "tuDien": "VC2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VC2",
        "diaChiLapD": "",
        "longitude": 105.83640900271635,
        "latitude": 21.021294286773827,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5695,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81950086005553,
          21.016304797797737
        ]
      },
      "properties": {
        "tenDoiTuon": "42B",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Mai Anh Tuấn",
        "tuDien": "HC6",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 10,
        "tuDien2": "HC6",
        "diaChiLapD": "",
        "longitude": 105.81950086005553,
        "latitude": 21.016304797797737,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5696,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81931042321602,
          21.016540153327366
        ]
      },
      "properties": {
        "tenDoiTuon": "41C",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Mai Anh Tuấn",
        "tuDien": "HC6",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 10,
        "tuDien2": "HC6",
        "diaChiLapD": "",
        "longitude": 105.81931042321602,
        "latitude": 21.016540153327366,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5697,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81913607963195,
          21.016747966942592
        ]
      },
      "properties": {
        "tenDoiTuon": "40B",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Mai Anh Tuấn",
        "tuDien": "HC6",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 10,
        "tuDien2": "HC6",
        "diaChiLapD": "",
        "longitude": 105.81913607963195,
        "latitude": 21.016747966942592,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5698,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81877129919938,
          21.017123532983806
        ]
      },
      "properties": {
        "tenDoiTuon": "38C",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Mai Anh Tuấn",
        "tuDien": "HC6",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 10,
        "tuDien2": "HC6",
        "diaChiLapD": "",
        "longitude": 105.81877129919938,
        "latitude": 21.017123532983806,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5699,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81853526481027,
          21.017048419850873
        ]
      },
      "properties": {
        "tenDoiTuon": "37B",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Mai Anh Tuấn",
        "tuDien": "HC6",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 10,
        "tuDien2": "HC6",
        "diaChiLapD": "",
        "longitude": 105.81853526481027,
        "latitude": 21.017048419850873,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5700,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81778424628318,
          21.01665783095205
        ]
      },
      "properties": {
        "tenDoiTuon": "34B",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Mai Anh Tuấn",
        "tuDien": "HC6",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 10,
        "tuDien2": "HC6",
        "diaChiLapD": "",
        "longitude": 105.81778424628318,
        "latitude": 21.01665783095205,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5701,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8180551493909,
          21.016775508484717
        ]
      },
      "properties": {
        "tenDoiTuon": "35C",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Mai Anh Tuấn",
        "tuDien": "HC6",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 10,
        "tuDien2": "HC6",
        "diaChiLapD": "",
        "longitude": 105.8180551493909,
        "latitude": 21.016775508484717,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5702,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81829386599672,
          21.016903201020938
        ]
      },
      "properties": {
        "tenDoiTuon": "36A",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Mai Anh Tuấn",
        "tuDien": "HC6",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 10,
        "tuDien2": "HC6",
        "diaChiLapD": "",
        "longitude": 105.81829386599672,
        "latitude": 21.016903201020938,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5703,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81895905383115,
          21.01695578026908
        ]
      },
      "properties": {
        "tenDoiTuon": "39A",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Mai Anh Tuấn",
        "tuDien": "HC6",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 10,
        "tuDien2": "HC6",
        "diaChiLapD": "",
        "longitude": 105.81895905383115,
        "latitude": 21.01695578026908,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5704,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81760722049137,
          21.016735448058345
        ]
      },
      "properties": {
        "tenDoiTuon": "33A",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Mai Anh Tuấn",
        "tuDien": "HC6",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 10,
        "tuDien2": "HC6",
        "diaChiLapD": "",
        "longitude": 105.81760722049137,
        "latitude": 21.016735448058345,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5705,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81740873702864,
          21.017050923622246
        ]
      },
      "properties": {
        "tenDoiTuon": "32B",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Mai Anh Tuấn",
        "tuDien": "HC6",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 10,
        "tuDien2": "HC6",
        "diaChiLapD": "",
        "longitude": 105.81740873702864,
        "latitude": 21.017050923622246,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5706,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81730144866505,
          21.01722368376772
        ]
      },
      "properties": {
        "tenDoiTuon": "31A",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Mai Anh Tuấn",
        "tuDien": "HC6",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 10,
        "tuDien2": "HC6",
        "diaChiLapD": "",
        "longitude": 105.81730144866505,
        "latitude": 21.01722368376772,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5707,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81739800818869,
          21.017403955011112
        ]
      },
      "properties": {
        "tenDoiTuon": "30C",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Mai Anh Tuấn",
        "tuDien": "HC6",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 10,
        "tuDien2": "HC6",
        "diaChiLapD": "",
        "longitude": 105.81739800818869,
        "latitude": 21.017403955011112,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5708,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81755357630958,
          21.017590485441932
        ]
      },
      "properties": {
        "tenDoiTuon": "29B",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 1,
        "maXaPhuong": 34,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Mai Anh Tuấn",
        "tuDien": "HC6",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 10,
        "tuDien2": "HC6",
        "diaChiLapD": "",
        "longitude": 105.81755357630958,
        "latitude": 21.017590485441932,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5709,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8177078033356,
          21.01776574872253
        ]
      },
      "properties": {
        "tenDoiTuon": "28A",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 1,
        "maXaPhuong": 34,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Mai Anh Tuấn",
        "tuDien": "HC6",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 10,
        "tuDien2": "HC6",
        "diaChiLapD": "",
        "longitude": 105.8177078033356,
        "latitude": 21.01776574872253,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5710,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81787141807973,
          21.017954782457828
        ]
      },
      "properties": {
        "tenDoiTuon": "27C",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 1,
        "maXaPhuong": 34,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Mai Anh Tuấn",
        "tuDien": "HC6",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 10,
        "tuDien2": "HC6",
        "diaChiLapD": "",
        "longitude": 105.81787141807973,
        "latitude": 21.017954782457828,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5711,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81804844388054,
          21.018130045309395
        ]
      },
      "properties": {
        "tenDoiTuon": "26B",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 1,
        "maXaPhuong": 34,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Mai Anh Tuấn",
        "tuDien": "HC6",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 10,
        "tuDien2": "HC6",
        "diaChiLapD": "",
        "longitude": 105.81804844388054,
        "latitude": 21.018130045309395,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5712,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81823888072005,
          21.018305307955025
        ]
      },
      "properties": {
        "tenDoiTuon": "25A",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 1,
        "maXaPhuong": 34,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Mai Anh Tuấn",
        "tuDien": "HC6",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 10,
        "tuDien2": "HC6",
        "diaChiLapD": "",
        "longitude": 105.81823888072005,
        "latitude": 21.018305307955025,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5713,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81842663535183,
          21.01847431102578
        ]
      },
      "properties": {
        "tenDoiTuon": "24C",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 1,
        "maXaPhuong": 34,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Mai Anh Tuấn",
        "tuDien": "HC6",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 10,
        "tuDien2": "HC6",
        "diaChiLapD": "",
        "longitude": 105.81842663535183,
        "latitude": 21.01847431102578,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5714,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81863316543777,
          21.01864331390414
        ]
      },
      "properties": {
        "tenDoiTuon": "23B",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 1,
        "maXaPhuong": 34,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Mai Anh Tuấn",
        "tuDien": "HC6",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 10,
        "tuDien2": "HC6",
        "diaChiLapD": "",
        "longitude": 105.81863316543777,
        "latitude": 21.01864331390414,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5715,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81885042437267,
          21.018793538524587
        ]
      },
      "properties": {
        "tenDoiTuon": "22A",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 1,
        "maXaPhuong": 34,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Mai Anh Tuấn",
        "tuDien": "HC6",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 10,
        "tuDien2": "HC6",
        "diaChiLapD": "",
        "longitude": 105.81885042437267,
        "latitude": 21.018793538524587,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5716,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81908645876176,
          21.01892248120313
        ]
      },
      "properties": {
        "tenDoiTuon": "21C",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 1,
        "maXaPhuong": 34,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Mai Anh Tuấn",
        "tuDien": "HC6",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 10,
        "tuDien2": "HC6",
        "diaChiLapD": "",
        "longitude": 105.81908645876176,
        "latitude": 21.01892248120313,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5717,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81933322199082,
          21.0190251345179
        ]
      },
      "properties": {
        "tenDoiTuon": "20B",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Mai Anh Tuấn",
        "tuDien": "HC6",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 10,
        "tuDien2": "HC6",
        "diaChiLapD": "",
        "longitude": 105.81933322199082,
        "latitude": 21.0190251345179,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5718,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81957730303009,
          21.019090231705448
        ]
      },
      "properties": {
        "tenDoiTuon": "19A",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Mai Anh Tuấn",
        "tuDien": "HC6",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 10,
        "tuDien2": "HC6",
        "diaChiLapD": "",
        "longitude": 105.81957730303009,
        "latitude": 21.019090231705448,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5719,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8198160196269,
          21.019137802709956
        ]
      },
      "properties": {
        "tenDoiTuon": "18C",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Mai Anh Tuấn",
        "tuDien": "HC6",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 10,
        "tuDien2": "HC6",
        "diaChiLapD": "",
        "longitude": 105.8198160196269,
        "latitude": 21.019137802709956,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5720,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82006010064822,
          21.019152825128586
        ]
      },
      "properties": {
        "tenDoiTuon": "17B",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Mai Anh Tuấn",
        "tuDien": "HC6",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 10,
        "tuDien2": "HC6",
        "diaChiLapD": "",
        "longitude": 105.82006010064822,
        "latitude": 21.019152825128586,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5721,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82031390467485,
          21.019150008425225
        ]
      },
      "properties": {
        "tenDoiTuon": "16A",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Mai Anh Tuấn",
        "tuDien": "HC6",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 10,
        "tuDien2": "HC6",
        "diaChiLapD": "",
        "longitude": 105.82031390467485,
        "latitude": 21.019150008425225,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5722,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82056167372954,
          21.01914030644624
        ]
      },
      "properties": {
        "tenDoiTuon": "15C",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Mai Anh Tuấn",
        "tuDien": "HC6",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 10,
        "tuDien2": "HC6",
        "diaChiLapD": "",
        "longitude": 105.82056167372954,
        "latitude": 21.01914030644624,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5723,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82083794126171,
          21.01906269059167
        ]
      },
      "properties": {
        "tenDoiTuon": "14B",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Mai Anh Tuấn",
        "tuDien": "HC6",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 10,
        "tuDien2": "HC6",
        "diaChiLapD": "",
        "longitude": 105.82083794126171,
        "latitude": 21.01906269059167,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5724,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8211752290874,
          21.01895753356096
        ]
      },
      "properties": {
        "tenDoiTuon": "13A",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Mai Anh Tuấn",
        "tuDien": "HC6",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 10,
        "tuDien2": "HC6",
        "diaChiLapD": "",
        "longitude": 105.8211752290874,
        "latitude": 21.01895753356096,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5725,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82143003894865,
          21.01890745875964
        ]
      },
      "properties": {
        "tenDoiTuon": "12C",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Mai Anh Tuấn",
        "tuDien": "HC6",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 10,
        "tuDien2": "HC6",
        "diaChiLapD": "",
        "longitude": 105.82143003894865,
        "latitude": 21.01890745875964,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5726,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82168082549067,
          21.018834850267044
        ]
      },
      "properties": {
        "tenDoiTuon": "11B",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Mai Anh Tuấn",
        "tuDien": "HC6",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 10,
        "tuDien2": "HC6",
        "diaChiLapD": "",
        "longitude": 105.82168082549067,
        "latitude": 21.018834850267044,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5727,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82193027093645,
          21.018781019810763
        ]
      },
      "properties": {
        "tenDoiTuon": "10A",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Mai Anh Tuấn",
        "tuDien": "HC6",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 10,
        "tuDien2": "HC6",
        "diaChiLapD": "",
        "longitude": 105.82193027093645,
        "latitude": 21.018781019810763,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5728,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8221636231268,
          21.018717174360305
        ]
      },
      "properties": {
        "tenDoiTuon": "09C",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Mai Anh Tuấn",
        "tuDien": "HC6",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 10,
        "tuDien2": "HC6",
        "diaChiLapD": "",
        "longitude": 105.8221636231268,
        "latitude": 21.018717174360305,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5729,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82225615933741,
          21.01881857594566
        ]
      },
      "properties": {
        "tenDoiTuon": "9-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 38 Mai Anh Tuấn",
        "tuDien": "HC6",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "HC6",
        "diaChiLapD": "",
        "longitude": 105.82225615933741,
        "latitude": 21.01881857594566,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5730,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82261959865714,
          21.018585727757504
        ]
      },
      "properties": {
        "tenDoiTuon": "07A",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Mai Anh Tuấn",
        "tuDien": "HC6",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 10,
        "tuDien2": "HC6",
        "diaChiLapD": "",
        "longitude": 105.82261959865714,
        "latitude": 21.018585727757504,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5731,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82239965751451,
          21.01865583262739
        ]
      },
      "properties": {
        "tenDoiTuon": "08B",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Mai Anh Tuấn",
        "tuDien": "HC6",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 10,
        "tuDien2": "HC6",
        "diaChiLapD": "",
        "longitude": 105.82239965751451,
        "latitude": 21.01865583262739,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5732,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82338402823191,
          21.018302804202214
        ]
      },
      "properties": {
        "tenDoiTuon": "03C",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Mai Anh Tuấn",
        "tuDien": "HC6",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 10,
        "tuDien2": "HC6",
        "diaChiLapD": "",
        "longitude": 105.82338402823191,
        "latitude": 21.018302804202214,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5733,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82357446507145,
          21.018225187911707
        ]
      },
      "properties": {
        "tenDoiTuon": "02B",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Mai Anh Tuấn",
        "tuDien": "HC6",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 10,
        "tuDien2": "HC6",
        "diaChiLapD": "",
        "longitude": 105.82357446507145,
        "latitude": 21.018225187911707,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5734,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8237729485342,
          21.01814757158082
        ]
      },
      "properties": {
        "tenDoiTuon": "01",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Mai Anh Tuấn",
        "tuDien": "HC6",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 10,
        "tuDien2": "HC6",
        "diaChiLapD": "",
        "longitude": 105.8237729485342,
        "latitude": 21.01814757158082,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5735,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82318822696796,
          21.018380420452317
        ]
      },
      "properties": {
        "tenDoiTuon": "04A",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Mai Anh Tuấn",
        "tuDien": "HC6",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 10,
        "tuDien2": "HC6",
        "diaChiLapD": "",
        "longitude": 105.82318822696796,
        "latitude": 21.018380420452317,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5736,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82300315451698,
          21.018448021670046
        ]
      },
      "properties": {
        "tenDoiTuon": "05B",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Mai Anh Tuấn",
        "tuDien": "HC6",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 10,
        "tuDien2": "HC6",
        "diaChiLapD": "",
        "longitude": 105.82300315451698,
        "latitude": 21.018448021670046,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5737,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82281271771338,
          21.018515622855475
        ]
      },
      "properties": {
        "tenDoiTuon": "06C",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Mai Anh Tuấn",
        "tuDien": "HC6",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 10,
        "tuDien2": "HC6",
        "diaChiLapD": "",
        "longitude": 105.82281271771338,
        "latitude": 21.018515622855475,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5738,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81846485689748,
          21.018615772706127
        ]
      },
      "properties": {
        "tenDoiTuon": "23-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 1,
        "maXaPhuong": 34,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 38 Mai Anh Tuấn",
        "tuDien": "HC6",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "HC6",
        "diaChiLapD": "",
        "longitude": 105.81846485689748,
        "latitude": 21.018615772706127,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5739,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8368515672004,
          21.021284271971524
        ]
      },
      "properties": {
        "tenDoiTuon": "21",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Nhà D1 TT Văn Chương",
        "tuDien": "VC2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VC2",
        "diaChiLapD": "",
        "longitude": 105.8368515672004,
        "latitude": 21.021284271971524,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5740,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83685056138374,
          21.021451080930056
        ]
      },
      "properties": {
        "tenDoiTuon": "22",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Nhà D1 TT Văn Chương",
        "tuDien": "VC2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VC2",
        "diaChiLapD": "",
        "longitude": 105.83685056138374,
        "latitude": 21.021451080930056,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5741,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8366684091469,
          21.021594145172802
        ]
      },
      "properties": {
        "tenDoiTuon": "23",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Nhà D1 TT Văn Chương",
        "tuDien": "VC2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VC2",
        "diaChiLapD": "",
        "longitude": 105.8366684091469,
        "latitude": 21.021594145172802,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5742,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83650857973491,
          21.021690183877705
        ]
      },
      "properties": {
        "tenDoiTuon": "24",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Nhà D1 TT Văn Chương",
        "tuDien": "VC2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VC2",
        "diaChiLapD": "",
        "longitude": 105.83650857973491,
        "latitude": 21.021690183877705,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5743,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83673924970853,
          21.02177530944156
        ]
      },
      "properties": {
        "tenDoiTuon": "25",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Nhà D1 TT Văn Chương",
        "tuDien": "VC2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VC2",
        "diaChiLapD": "",
        "longitude": 105.83673924970853,
        "latitude": 21.02177530944156,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5744,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83638620395953,
          21.021817559241
        ]
      },
      "properties": {
        "tenDoiTuon": "26",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Nhà D1 TT Văn Chương",
        "tuDien": "VC2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VC2",
        "diaChiLapD": "",
        "longitude": 105.83638620395953,
        "latitude": 21.021817559241,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5745,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83626718092137,
          21.021941804889238
        ]
      },
      "properties": {
        "tenDoiTuon": "27",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Nhà D1 TT Văn Chương",
        "tuDien": "VC2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VC2",
        "diaChiLapD": "",
        "longitude": 105.83626718092137,
        "latitude": 21.021941804889238,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5746,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8364643232892,
          21.022093278030155
        ]
      },
      "properties": {
        "tenDoiTuon": "28",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Nhà D1 TT Văn Chương",
        "tuDien": "VC2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VC2",
        "diaChiLapD": "",
        "longitude": 105.8364643232892,
        "latitude": 21.022093278030155,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5747,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8360432164671,
          21.02212958148683
        ]
      },
      "properties": {
        "tenDoiTuon": "VC2 29",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Nhà D1 TT Văn Chương",
        "tuDien": "VC2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VC2",
        "diaChiLapD": "",
        "longitude": 105.8360432164671,
        "latitude": 21.02212958148683,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5748,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83591715264934,
          21.02204445612526
        ]
      },
      "properties": {
        "tenDoiTuon": "VC2 30",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Nhà D1 TT Văn Chương",
        "tuDien": "VC2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VC2",
        "diaChiLapD": "",
        "longitude": 105.83591715264934,
        "latitude": 21.02204445612526,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5749,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83613508920975,
          21.021816087089153
        ]
      },
      "properties": {
        "tenDoiTuon": "31",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Nhà D1 TT Văn Chương",
        "tuDien": "VC2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VC2",
        "diaChiLapD": "",
        "longitude": 105.83613508920975,
        "latitude": 21.021816087089153,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5750,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8359193603607,
          21.021712608566343
        ]
      },
      "properties": {
        "tenDoiTuon": "32",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Nhà D1 TT Văn Chương",
        "tuDien": "VC2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 7,
        "tuDien2": "VC2",
        "diaChiLapD": "",
        "longitude": 105.8359193603607,
        "latitude": 21.021712608566343,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5751,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83593427972883,
          21.02158005942863
        ]
      },
      "properties": {
        "tenDoiTuon": "33",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Nhà D1 TT Văn Chương",
        "tuDien": "VC2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VC2",
        "diaChiLapD": "",
        "longitude": 105.83593427972883,
        "latitude": 21.02158005942863,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5752,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83559126424699,
          21.02150991781708
        ]
      },
      "properties": {
        "tenDoiTuon": "VC2 34",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Nhà D1 TT Văn Chương",
        "tuDien": "VC2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VC2",
        "diaChiLapD": "",
        "longitude": 105.83559126424699,
        "latitude": 21.02150991781708,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5753,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83529298840038,
          21.021417109671717
        ]
      },
      "properties": {
        "tenDoiTuon": "VC2 35",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Nhà D1 TT Văn Chương",
        "tuDien": "VC2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VC2",
        "diaChiLapD": "",
        "longitude": 105.83529298840038,
        "latitude": 21.021417109671717,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5754,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83769249654559,
          21.02134093769431
        ]
      },
      "properties": {
        "tenDoiTuon": "5-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 71 Văn Chương",
        "tuDien": "VC2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VC2",
        "diaChiLapD": "",
        "longitude": 105.83769249654559,
        "latitude": 21.02134093769431,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5755,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83796870729088,
          21.021175360953283
        ]
      },
      "properties": {
        "tenDoiTuon": "5-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 71 Văn Chương",
        "tuDien": "VC2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VC2",
        "diaChiLapD": "",
        "longitude": 105.83796870729088,
        "latitude": 21.021175360953283,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5756,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8379003109665,
          21.021087731343385
        ]
      },
      "properties": {
        "tenDoiTuon": "5-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 71 Văn Chương",
        "tuDien": "VC2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VC2",
        "diaChiLapD": "",
        "longitude": 105.8379003109665,
        "latitude": 21.021087731343385,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5757,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83801832816106,
          21.021026390585234
        ]
      },
      "properties": {
        "tenDoiTuon": "5-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 71 Văn Chương",
        "tuDien": "VC2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VC2",
        "diaChiLapD": "",
        "longitude": 105.83801832816106,
        "latitude": 21.021026390585234,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5758,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83818888336036,
          21.020975800128276
        ]
      },
      "properties": {
        "tenDoiTuon": "5-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 71 Văn Chương",
        "tuDien": "VC2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VC2",
        "diaChiLapD": "",
        "longitude": 105.83818888336036,
        "latitude": 21.020975800128276,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5759,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83840451695625,
          21.020824911570628
        ]
      },
      "properties": {
        "tenDoiTuon": "5-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 71 Văn Chương",
        "tuDien": "VC2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VC2",
        "diaChiLapD": "",
        "longitude": 105.83840451695625,
        "latitude": 21.020824911570628,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5760,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83824899809872,
          21.020983827598098
        ]
      },
      "properties": {
        "tenDoiTuon": "5-5-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 71 Văn Chương",
        "tuDien": "VC2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "VC2",
        "diaChiLapD": "",
        "longitude": 105.83824899809872,
        "latitude": 21.020983827598098,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5761,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83703529852954,
          21.02178000386532
        ]
      },
      "properties": {
        "tenDoiTuon": "7-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Nhà A7 TT Văn Chương",
        "tuDien": "VC2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VC2",
        "diaChiLapD": "",
        "longitude": 105.83703529852954,
        "latitude": 21.02178000386532,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5762,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8367348911187,
          21.02200909153099
        ]
      },
      "properties": {
        "tenDoiTuon": "8-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Nhà A8 TT Văn Chương",
        "tuDien": "VC2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VC2",
        "diaChiLapD": "",
        "longitude": 105.8367348911187,
        "latitude": 21.02200909153099,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5763,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83668895830887,
          21.022577740677285
        ]
      },
      "properties": {
        "tenDoiTuon": "10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Linh Quang",
        "tuDien": "VC2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VC2",
        "diaChiLapD": "",
        "longitude": 105.83668895830887,
        "latitude": 21.022577740677285,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5764,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83669298162047,
          21.02282059732931
        ]
      },
      "properties": {
        "tenDoiTuon": "11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Linh Quang",
        "tuDien": "VC2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VC2",
        "diaChiLapD": "",
        "longitude": 105.83669298162047,
        "latitude": 21.02282059732931,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5765,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83673321475459,
          21.02294077367078
        ]
      },
      "properties": {
        "tenDoiTuon": "12",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Linh Quang",
        "tuDien": "VC2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VC2",
        "diaChiLapD": "",
        "longitude": 105.83673321475459,
        "latitude": 21.02294077367078,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5766,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83685123195811,
          21.02316610405031
        ]
      },
      "properties": {
        "tenDoiTuon": "13",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Linh Quang",
        "tuDien": "VC2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VC2",
        "diaChiLapD": "",
        "longitude": 105.83685123195811,
        "latitude": 21.02316610405031,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5767,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83660178652133,
          21.022869418980026
        ]
      },
      "properties": {
        "tenDoiTuon": "11-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Linh Quang",
        "tuDien": "VC2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 7,
        "tuDien2": "VC2",
        "diaChiLapD": "",
        "longitude": 105.83660178652133,
        "latitude": 21.022869418980026,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5768,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83641269078569,
          21.02298208426629
        ]
      },
      "properties": {
        "tenDoiTuon": "11-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Linh Quang",
        "tuDien": "VC2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 7,
        "tuDien2": "VC2",
        "diaChiLapD": "",
        "longitude": 105.83641269078569,
        "latitude": 21.02298208426629,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5769,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83705742675689,
          21.023216490380985
        ]
      },
      "properties": {
        "tenDoiTuon": "14",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Linh Quang",
        "tuDien": "VC2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VC2",
        "diaChiLapD": "",
        "longitude": 105.83705742675689,
        "latitude": 21.023216490380985,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5770,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83720226603783,
          21.02350065644347
        ]
      },
      "properties": {
        "tenDoiTuon": "15",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Linh Quang",
        "tuDien": "VC2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VC2",
        "diaChiLapD": "",
        "longitude": 105.83720226603783,
        "latitude": 21.02350065644347,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5771,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83717142063982,
          21.023423042858777
        ]
      },
      "properties": {
        "tenDoiTuon": "15-16",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Linh Quang",
        "tuDien": "VC2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "VC2",
        "diaChiLapD": "",
        "longitude": 105.83717142063982,
        "latitude": 21.023423042858777,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5772,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83730821329755,
          21.0236120694217
        ]
      },
      "properties": {
        "tenDoiTuon": "15-7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Linh Quang",
        "tuDien": "VC2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VC2",
        "diaChiLapD": "",
        "longitude": 105.83730821329755,
        "latitude": 21.0236120694217,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5773,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8373578341677,
          21.023847413417375
        ]
      },
      "properties": {
        "tenDoiTuon": "VC2 15-9",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Linh Quang",
        "tuDien": "VC2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VC2",
        "diaChiLapD": "",
        "longitude": 105.8373578341677,
        "latitude": 21.023847413417375,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5774,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83655786531791,
          21.02302808923316
        ]
      },
      "properties": {
        "tenDoiTuon": "12-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 94 Linh Quang",
        "tuDien": "VC2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "VC2",
        "diaChiLapD": "",
        "longitude": 105.83655786531791,
        "latitude": 21.02302808923316,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5775,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83661285060356,
          21.02323964930772
        ]
      },
      "properties": {
        "tenDoiTuon": "13-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 70 Linh Quang",
        "tuDien": "VC2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VC2",
        "diaChiLapD": "",
        "longitude": 105.83661285060356,
        "latitude": 21.02323964930772,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5776,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83673355000585,
          21.02321586446173
        ]
      },
      "properties": {
        "tenDoiTuon": "13-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 70 Linh Quang",
        "tuDien": "VC2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VC2",
        "diaChiLapD": "",
        "longitude": 105.83673355000585,
        "latitude": 21.02321586446173,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5777,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8365887107249,
          21.02331350748745
        ]
      },
      "properties": {
        "tenDoiTuon": "13-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 70 Linh Quang",
        "tuDien": "VC2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "VC2",
        "diaChiLapD": "",
        "longitude": 105.8365887107249,
        "latitude": 21.02331350748745,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5778,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83647203462523,
          21.023285967153758
        ]
      },
      "properties": {
        "tenDoiTuon": "13-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 70 Linh Quang",
        "tuDien": "VC2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "VC2",
        "diaChiLapD": "",
        "longitude": 105.83647203462523,
        "latitude": 21.023285967153758,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5779,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.837185502208,
          21.02319708694994
        ]
      },
      "properties": {
        "tenDoiTuon": "14-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 75 Linh Quang",
        "tuDien": "VC2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VC2",
        "diaChiLapD": "",
        "longitude": 105.837185502208,
        "latitude": 21.02319708694994,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5780,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83700847642517,
          21.023491267704962
        ]
      },
      "properties": {
        "tenDoiTuon": "15-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 48 Linh Quang",
        "tuDien": "VC2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VC2",
        "diaChiLapD": "",
        "longitude": 105.83700847642517,
        "latitude": 21.023491267704962,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5781,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83681803958565,
          21.02348125305025
        ]
      },
      "properties": {
        "tenDoiTuon": "15-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 48 Linh Quang",
        "tuDien": "VC2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VC2",
        "diaChiLapD": "",
        "longitude": 105.83681803958565,
        "latitude": 21.02348125305025,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5782,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83655518310118,
          21.023553859280465
        ]
      },
      "properties": {
        "tenDoiTuon": "15-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 48 Linh Quang",
        "tuDien": "VC2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VC2",
        "diaChiLapD": "",
        "longitude": 105.83655518310118,
        "latitude": 21.023553859280465,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5783,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83737996237711,
          21.023443698090308
        ]
      },
      "properties": {
        "tenDoiTuon": "15-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 46 Linh Quang",
        "tuDien": "VC2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VC2",
        "diaChiLapD": "",
        "longitude": 105.83737996237711,
        "latitude": 21.023443698090308,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5784,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83706346171083,
          21.023582651395913
        ]
      },
      "properties": {
        "tenDoiTuon": "15-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 46 Linh Quang",
        "tuDien": "VC2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VC2",
        "diaChiLapD": "",
        "longitude": 105.83706346171083,
        "latitude": 21.023582651395913,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5785,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8368475438798,
          21.023686553332507
        ]
      },
      "properties": {
        "tenDoiTuon": "15-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 46 Linh Quang",
        "tuDien": "VC2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VC2",
        "diaChiLapD": "",
        "longitude": 105.8368475438798,
        "latitude": 21.023686553332507,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5786,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83749932067552,
          21.023535081810405
        ]
      },
      "properties": {
        "tenDoiTuon": "15-8",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 55 Linh Quang",
        "tuDien": "VC2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VC2",
        "diaChiLapD": "",
        "longitude": 105.83749932067552,
        "latitude": 21.023535081810405,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5787,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83762270228556,
          21.024049583625864
        ]
      },
      "properties": {
        "tenDoiTuon": "VC2 15-15",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 49 Linh Quang",
        "tuDien": "VC2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VC2",
        "diaChiLapD": "",
        "longitude": 105.83762270228556,
        "latitude": 21.024049583625864,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5788,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8371144236759,
          21.023951941082267
        ]
      },
      "properties": {
        "tenDoiTuon": "15-10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 36 Linh Quang",
        "tuDien": "VC2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VC2",
        "diaChiLapD": "",
        "longitude": 105.8371144236759,
        "latitude": 21.023951941082267,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5789,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83695349113951,
          21.02394443011492
        ]
      },
      "properties": {
        "tenDoiTuon": "15-11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 36 Linh Quang",
        "tuDien": "VC2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VC2",
        "diaChiLapD": "",
        "longitude": 105.83695349113951,
        "latitude": 21.02394443011492,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5790,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83713588134681,
          21.0241722626292
        ]
      },
      "properties": {
        "tenDoiTuon": "VC2 15-12",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 36 Linh Quang",
        "tuDien": "VC2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VC2",
        "diaChiLapD": "",
        "longitude": 105.83713588134681,
        "latitude": 21.0241722626292,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5791,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83692532794025,
          21.02415098158495
        ]
      },
      "properties": {
        "tenDoiTuon": "15-13",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 36 Linh Quang",
        "tuDien": "VC2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VC2",
        "diaChiLapD": "",
        "longitude": 105.83692532794025,
        "latitude": 21.02415098158495,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5792,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83669734017438,
          21.024155988889902
        ]
      },
      "properties": {
        "tenDoiTuon": "VC2 15-14",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 36 Linh Quang",
        "tuDien": "VC2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VC2",
        "diaChiLapD": "",
        "longitude": 105.83669734017438,
        "latitude": 21.024155988889902,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5793,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83505649885609,
          21.022308907916145
        ]
      },
      "properties": {
        "tenDoiTuon": "06",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Văn Chương",
        "tuDien": "VC4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VC4",
        "diaChiLapD": "",
        "longitude": 105.83505649885609,
        "latitude": 21.022308907916145,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5794,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.835407868232,
          21.022343959479514
        ]
      },
      "properties": {
        "tenDoiTuon": "05",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Văn Chương",
        "tuDien": "VC4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VC4",
        "diaChiLapD": "",
        "longitude": 105.835407868232,
        "latitude": 21.022343959479514,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5795,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83569218238739,
          21.022351470527493
        ]
      },
      "properties": {
        "tenDoiTuon": "04",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Văn Chương",
        "tuDien": "VC4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VC4",
        "diaChiLapD": "",
        "longitude": 105.83569218238739,
        "latitude": 21.022351470527493,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5796,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83598186096727,
          21.022351470527493
        ]
      },
      "properties": {
        "tenDoiTuon": "VC4 03",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Văn Chương",
        "tuDien": "VC4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VC4",
        "diaChiLapD": "",
        "longitude": 105.83598186096727,
        "latitude": 21.022351470527493,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5797,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83634127697536,
          21.022355226052188
        ]
      },
      "properties": {
        "tenDoiTuon": "02",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Văn Chương",
        "tuDien": "VC4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VC4",
        "diaChiLapD": "",
        "longitude": 105.83634127697536,
        "latitude": 21.022355226052188,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5798,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83661754449857,
          21.022326433698847
        ]
      },
      "properties": {
        "tenDoiTuon": "VC4 01",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Văn Chương",
        "tuDien": "VC4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VC4",
        "diaChiLapD": "",
        "longitude": 105.83661754449857,
        "latitude": 21.022326433698847,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5799,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83460588772186,
          21.014425852405964
        ]
      },
      "properties": {
        "tenDoiTuon": "01",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 126 Đê La Thành",
        "tuDien": "LAT3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "La Thành 3",
        "diaChiLapD": "",
        "longitude": 105.83460588772186,
        "latitude": 21.014425852405964,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5800,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83609049043471,
          21.022422825466975
        ]
      },
      "properties": {
        "tenDoiTuon": "2-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Văn Chương",
        "tuDien": "VC4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VC4",
        "diaChiLapD": "",
        "longitude": 105.83609049043471,
        "latitude": 21.022422825466975,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5801,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83499242649572,
          21.022154051334745
        ]
      },
      "properties": {
        "tenDoiTuon": "07",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Văn Chương",
        "tuDien": "VC2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VC2",
        "diaChiLapD": "",
        "longitude": 105.83499242649572,
        "latitude": 21.022154051334745,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5802,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.834990653716,
          21.021927655453215
        ]
      },
      "properties": {
        "tenDoiTuon": "08",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Văn Chương",
        "tuDien": "VC2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VC2",
        "diaChiLapD": "",
        "longitude": 105.834990653716,
        "latitude": 21.021927655453215,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5803,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83498637901599,
          21.02160904010344
        ]
      },
      "properties": {
        "tenDoiTuon": "09",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Văn Chương",
        "tuDien": "VC2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VC2",
        "diaChiLapD": "",
        "longitude": 105.83498637901599,
        "latitude": 21.02160904010344,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5804,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83498177293076,
          21.021433001597792
        ]
      },
      "properties": {
        "tenDoiTuon": "VC4 10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Văn Chương",
        "tuDien": "VC2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VC2",
        "diaChiLapD": "",
        "longitude": 105.83498177293076,
        "latitude": 21.021433001597792,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5805,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8376188702522,
          21.01567236104821
        ]
      },
      "properties": {
        "tenDoiTuon": "CN10 1-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 178 Phố Chợ Khâm Thiên",
        "tuDien": "CN10",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "CN10",
        "diaChiLapD": "",
        "longitude": 105.8376188702522,
        "latitude": 21.01567236104821,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5806,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82953383050122,
          21.0122713033967
        ]
      },
      "properties": {
        "tenDoiTuon": "BVNĐ 01",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Đặng Văn Ngữ",
        "tuDien": "B1NĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Sân vườn",
        "chieuCao": 7,
        "tuDien2": "B1 Nam Đồng",
        "diaChiLapD": "",
        "longitude": 105.82953383050122,
        "latitude": 21.0122713033967,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5807,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82964380107252,
          21.01248162680885
        ]
      },
      "properties": {
        "tenDoiTuon": "02",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Đặng Văn Ngữ",
        "tuDien": "B1NĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Sân vườn",
        "chieuCao": 7,
        "tuDien2": "B1 Nam Đồng",
        "diaChiLapD": "",
        "longitude": 105.82964380107252,
        "latitude": 21.01248162680885,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5808,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82976181826707,
          21.012704469148083
        ]
      },
      "properties": {
        "tenDoiTuon": "03",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Đặng Văn Ngữ",
        "tuDien": "B1NĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Sân vườn",
        "chieuCao": 7,
        "tuDien2": "B1 Nam Đồng",
        "diaChiLapD": "",
        "longitude": 105.82976181826707,
        "latitude": 21.012704469148083,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5809,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82987983546163,
          21.012922303473243
        ]
      },
      "properties": {
        "tenDoiTuon": "04",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Đặng Văn Ngữ",
        "tuDien": "B1NĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Sân vườn",
        "chieuCao": 7,
        "tuDien2": "B1 Nam Đồng",
        "diaChiLapD": "",
        "longitude": 105.82987983546163,
        "latitude": 21.012922303473243,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5810,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83036531529648,
          21.0138061567212
        ]
      },
      "properties": {
        "tenDoiTuon": "08",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Đặng Văn Ngữ",
        "tuDien": "B1NĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Sân vườn",
        "chieuCao": 7,
        "tuDien2": "B1 Nam Đồng",
        "diaChiLapD": "",
        "longitude": 105.83036531529648,
        "latitude": 21.0138061567212,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5811,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83023388705425,
          21.01361085815358
        ]
      },
      "properties": {
        "tenDoiTuon": "07",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Đặng Văn Ngữ",
        "tuDien": "B1NĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Sân vườn",
        "chieuCao": 7,
        "tuDien2": "B1 Nam Đồng",
        "diaChiLapD": "",
        "longitude": 105.83023388705425,
        "latitude": 21.01361085815358,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5812,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83010514101973,
          21.013383009501915
        ]
      },
      "properties": {
        "tenDoiTuon": "06",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Đặng Văn Ngữ",
        "tuDien": "B1NĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Sân vườn",
        "chieuCao": 7,
        "tuDien2": "B1 Nam Đồng",
        "diaChiLapD": "",
        "longitude": 105.83010514101973,
        "latitude": 21.013383009501915,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5813,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8300139459116,
          21.013190214217236
        ]
      },
      "properties": {
        "tenDoiTuon": "05",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Đặng Văn Ngữ",
        "tuDien": "B1NĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Sân vườn",
        "chieuCao": 7,
        "tuDien2": "B1 Nam Đồng",
        "diaChiLapD": "",
        "longitude": 105.8300139459116,
        "latitude": 21.013190214217236,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5814,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83058257422239,
          21.013983928271934
        ]
      },
      "properties": {
        "tenDoiTuon": "09",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Đặng Văn Ngữ",
        "tuDien": "B1NĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Sân vườn",
        "chieuCao": 7,
        "tuDien2": "B1 Nam Đồng",
        "diaChiLapD": "",
        "longitude": 105.83058257422239,
        "latitude": 21.013983928271934,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5815,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83086957058555,
          21.013981424448247
        ]
      },
      "properties": {
        "tenDoiTuon": "10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Đặng Văn Ngữ",
        "tuDien": "B1NĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Sân vườn",
        "chieuCao": 7,
        "tuDien2": "B1 Nam Đồng",
        "diaChiLapD": "",
        "longitude": 105.83086957058555,
        "latitude": 21.013981424448247,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5816,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8311297448623,
          21.01380866054697
        ]
      },
      "properties": {
        "tenDoiTuon": "11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Đặng Văn Ngữ",
        "tuDien": "B1NĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Sân vườn",
        "chieuCao": 7,
        "tuDien2": "B1 Nam Đồng",
        "diaChiLapD": "",
        "longitude": 105.8311297448623,
        "latitude": 21.01380866054697,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5817,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83197195849753,
          21.01284468438252
        ]
      },
      "properties": {
        "tenDoiTuon": "B1NĐ 16B",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Đặng Văn Ngữ",
        "tuDien": "B1NĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Sân vườn",
        "chieuCao": 7,
        "tuDien2": "B1 Nam Đồng",
        "diaChiLapD": "",
        "longitude": 105.83197195849753,
        "latitude": 21.01284468438252,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5818,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82966794096018,
          21.01214611074936
        ]
      },
      "properties": {
        "tenDoiTuon": "25",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Đặng Văn Ngữ",
        "tuDien": "B1NĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Sân vườn",
        "chieuCao": 7,
        "tuDien2": "B1 Nam Đồng",
        "diaChiLapD": "",
        "longitude": 105.82966794096018,
        "latitude": 21.01214611074936,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5819,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82987715326286,
          21.0120259257084
        ]
      },
      "properties": {
        "tenDoiTuon": "26",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Đặng Văn Ngữ",
        "tuDien": "B1NĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Sân vườn",
        "chieuCao": 7,
        "tuDien2": "B1 Nam Đồng",
        "diaChiLapD": "",
        "longitude": 105.82987715326286,
        "latitude": 21.0120259257084,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5820,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83014000974735,
          21.01188570970433
        ]
      },
      "properties": {
        "tenDoiTuon": "27",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Đặng Văn Ngữ",
        "tuDien": "B1NĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Sân vườn",
        "chieuCao": 7,
        "tuDien2": "B1 Nam Đồng",
        "diaChiLapD": "",
        "longitude": 105.83014000974735,
        "latitude": 21.01188570970433,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5821,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83210875115523,
          21.0127019653038
        ]
      },
      "properties": {
        "tenDoiTuon": "B1NĐ 17",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Đặng Văn Ngữ",
        "tuDien": "B1NĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Sân vườn",
        "chieuCao": 7,
        "tuDien2": "B1 Nam Đồng",
        "diaChiLapD": "",
        "longitude": 105.83210875115523,
        "latitude": 21.0127019653038,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5822,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83179493269671,
          21.013027464757002
        ]
      },
      "properties": {
        "tenDoiTuon": "15",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Đặng Văn Ngữ",
        "tuDien": "B1NĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Sân vườn",
        "chieuCao": 7,
        "tuDien2": "B1 Nam Đồng",
        "diaChiLapD": "",
        "longitude": 105.83179493269671,
        "latitude": 21.013027464757002,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5823,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83160986027269,
          21.013197725727146
        ]
      },
      "properties": {
        "tenDoiTuon": "14",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Đặng Văn Ngữ",
        "tuDien": "B1NĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Sân vườn",
        "chieuCao": 7,
        "tuDien2": "B1 Nam Đồng",
        "diaChiLapD": "",
        "longitude": 105.83160986027269,
        "latitude": 21.013197725727146,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5824,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83145697435953,
          21.013360475001612
        ]
      },
      "properties": {
        "tenDoiTuon": "13",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Đặng Văn Ngữ",
        "tuDien": "B1NĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Sân vườn",
        "chieuCao": 7,
        "tuDien2": "B1 Nam Đồng",
        "diaChiLapD": "",
        "longitude": 105.83145697435953,
        "latitude": 21.013360475001612,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5825,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83126653752002,
          21.01357580453823
        ]
      },
      "properties": {
        "tenDoiTuon": "12",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Đặng Văn Ngữ",
        "tuDien": "B1NĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Sân vườn",
        "chieuCao": 7,
        "tuDien2": "B1 Nam Đồng",
        "diaChiLapD": "",
        "longitude": 105.83126653752002,
        "latitude": 21.01357580453823,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5826,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8374405033565,
          21.015772512807512
        ]
      },
      "properties": {
        "tenDoiTuon": "1-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 178 Phố Chợ Khâm Thiên",
        "tuDien": "CN10",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "CN10",
        "diaChiLapD": "",
        "longitude": 105.8374405033565,
        "latitude": 21.015772512807512,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5827,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83723129105383,
          21.015918984634077
        ]
      },
      "properties": {
        "tenDoiTuon": "1-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 178 Phố Chợ Khâm Thiên",
        "tuDien": "CN10",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "CN10",
        "diaChiLapD": "",
        "longitude": 105.83723129105383,
        "latitude": 21.015918984634077,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5828,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8370770640368,
          21.015744971080142
        ]
      },
      "properties": {
        "tenDoiTuon": "1-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 178 Phố Chợ Khâm Thiên",
        "tuDien": "CN10",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "CN10",
        "diaChiLapD": "",
        "longitude": 105.8370770640368,
        "latitude": 21.015744971080142,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5829,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83696307015386,
          21.01594277064321
        ]
      },
      "properties": {
        "tenDoiTuon": "1-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 178 Phố Chợ Khâm Thiên",
        "tuDien": "CN10",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "CN10",
        "diaChiLapD": "",
        "longitude": 105.83696307015386,
        "latitude": 21.01594277064321,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5830,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83683164191163,
          21.0161330585831
        ]
      },
      "properties": {
        "tenDoiTuon": "1-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 178 Phố Chợ Khâm Thiên",
        "tuDien": "CN10",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "CN10",
        "diaChiLapD": "",
        "longitude": 105.83683164191163,
        "latitude": 21.0161330585831,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5831,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83661304187284,
          21.016279530055566
        ]
      },
      "properties": {
        "tenDoiTuon": "1-7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 178 Phố Chợ Khâm Thiên",
        "tuDien": "CN10",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "CN10",
        "diaChiLapD": "",
        "longitude": 105.83661304187284,
        "latitude": 21.016279530055566,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5832,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8365124590376,
          21.016345880332832
        ]
      },
      "properties": {
        "tenDoiTuon": "1-8",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 178 Phố Chợ Khâm Thiên",
        "tuDien": "CN10",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "CN10",
        "diaChiLapD": "",
        "longitude": 105.8365124590376,
        "latitude": 21.016345880332832,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5833,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83639980625854,
          21.016322094388013
        ]
      },
      "properties": {
        "tenDoiTuon": "1-9",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 178 Phố Chợ Khâm Thiên",
        "tuDien": "CN10",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "CN10",
        "diaChiLapD": "",
        "longitude": 105.83639980625854,
        "latitude": 21.016322094388013,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5834,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83668814372557,
          21.01637842951539
        ]
      },
      "properties": {
        "tenDoiTuon": "1-10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 178 Phố Chợ Khâm Thiên",
        "tuDien": "CN10",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "CN10",
        "diaChiLapD": "",
        "longitude": 105.83668814372557,
        "latitude": 21.01637842951539,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5835,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83658487868259,
          21.016419741928225
        ]
      },
      "properties": {
        "tenDoiTuon": "1-11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 178 Phố Chợ Khâm Thiên",
        "tuDien": "CN10",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "CN10",
        "diaChiLapD": "",
        "longitude": 105.83658487868259,
        "latitude": 21.016419741928225,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5836,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8367391056996,
          21.016676379390585
        ]
      },
      "properties": {
        "tenDoiTuon": "1-12",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 178 Phố Chợ Khâm Thiên",
        "tuDien": "CN10",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "CN10",
        "diaChiLapD": "",
        "longitude": 105.8367391056996,
        "latitude": 21.016676379390585,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5837,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83651359597695,
          21.01681411908115
        ]
      },
      "properties": {
        "tenDoiTuon": "CN10 1-13",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 178 Phố Chợ Khâm Thiên",
        "tuDien": "CN10",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "CN10",
        "diaChiLapD": "",
        "longitude": 105.83651359597695,
        "latitude": 21.01681411908115,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5838,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83292615435539,
          21.023154524579983
        ]
      },
      "properties": {
        "tenDoiTuon": "23",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Văn Chương",
        "tuDien": "VC4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "BTLT",
        "chieuCao": 7,
        "tuDien2": "VC4",
        "diaChiLapD": "",
        "longitude": 105.83292615435539,
        "latitude": 21.023154524579983,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5839,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83262881195553,
          21.023310046663916
        ]
      },
      "properties": {
        "tenDoiTuon": "VC4 24",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Văn Chương",
        "tuDien": "VC4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "BTLT",
        "chieuCao": 7,
        "tuDien2": "VC4",
        "diaChiLapD": "",
        "longitude": 105.83262881195553,
        "latitude": 21.023310046663916,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5840,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83331373356275,
          21.02294296438381
        ]
      },
      "properties": {
        "tenDoiTuon": "22",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Văn Chương",
        "tuDien": "VC4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "BTLT",
        "chieuCao": 7,
        "tuDien2": "VC4",
        "diaChiLapD": "",
        "longitude": 105.83331373356275,
        "latitude": 21.02294296438381,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5841,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83367449067472,
          21.02278898843123
        ]
      },
      "properties": {
        "tenDoiTuon": "21",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Văn Chương",
        "tuDien": "VC4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VC4",
        "diaChiLapD": "",
        "longitude": 105.83367449067472,
        "latitude": 21.02278898843123,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5842,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83392561244867,
          21.022728587251624
        ]
      },
      "properties": {
        "tenDoiTuon": "20",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Văn Chương",
        "tuDien": "VC4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "BTLT",
        "chieuCao": 7,
        "tuDien2": "VC4",
        "diaChiLapD": "",
        "longitude": 105.83392561244867,
        "latitude": 21.022728587251624,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5843,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83424747753044,
          21.022585877632864
        ]
      },
      "properties": {
        "tenDoiTuon": "19",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Văn Chương",
        "tuDien": "VC4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "BTLT",
        "chieuCao": 7,
        "tuDien2": "VC4",
        "diaChiLapD": "",
        "longitude": 105.83424747753044,
        "latitude": 21.022585877632864,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5844,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83040823064731,
          21.011745493569297
        ]
      },
      "properties": {
        "tenDoiTuon": "28",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Đặng Văn Ngữ",
        "tuDien": "B1NĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Sân vườn",
        "chieuCao": 7,
        "tuDien2": "B1 Nam Đồng",
        "diaChiLapD": "",
        "longitude": 105.83040823064731,
        "latitude": 21.011745493569297,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5845,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83212886773127,
          21.012507917214624
        ]
      },
      "properties": {
        "tenDoiTuon": "19",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Đặng Văn Ngữ",
        "tuDien": "B1NĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Sân vườn",
        "chieuCao": 7,
        "tuDien2": "B1 Nam Đồng",
        "diaChiLapD": "",
        "longitude": 105.83212886773127,
        "latitude": 21.012507917214624,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5846,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83200682721612,
          21.01235518241255
        ]
      },
      "properties": {
        "tenDoiTuon": "B1NĐ 20",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Đặng Văn Ngữ",
        "tuDien": "B1NĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Sân vườn",
        "chieuCao": 7,
        "tuDien2": "B1 Nam Đồng",
        "diaChiLapD": "",
        "longitude": 105.83200682721612,
        "latitude": 21.01235518241255,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5847,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82927197985137,
          21.012311052040484
        ]
      },
      "properties": {
        "tenDoiTuon": "B1NĐ 47",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Đặng Văn Ngữ",
        "tuDien": "B1NĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Sân vườn",
        "chieuCao": 7,
        "tuDien2": "B1 Nam Đồng",
        "diaChiLapD": "",
        "longitude": 105.82927197985137,
        "latitude": 21.012311052040484,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5848,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83127190195346,
          21.011730470404547
        ]
      },
      "properties": {
        "tenDoiTuon": "B1NĐ 23",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Đặng Văn Ngữ",
        "tuDien": "B1NĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Sân vườn",
        "chieuCao": 7,
        "tuDien2": "B1 Nam Đồng",
        "diaChiLapD": "",
        "longitude": 105.83127190195346,
        "latitude": 21.011730470404547,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5849,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83153744064568,
          21.011955817722846
        ]
      },
      "properties": {
        "tenDoiTuon": "B1NĐ 22",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Đặng Văn Ngữ",
        "tuDien": "B1NĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Sân vườn",
        "chieuCao": 7,
        "tuDien2": "B1 Nam Đồng",
        "diaChiLapD": "",
        "longitude": 105.83153744064568,
        "latitude": 21.011955817722846,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5850,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83177079282703,
          21.012143606894835
        ]
      },
      "properties": {
        "tenDoiTuon": "B1NĐ 21",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Đặng Văn Ngữ",
        "tuDien": "B1NĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Sân vườn",
        "chieuCao": 7,
        "tuDien2": "B1 Nam Đồng",
        "diaChiLapD": "",
        "longitude": 105.83177079282703,
        "latitude": 21.012143606894835,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5851,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83077837549536,
          21.011547688439954
        ]
      },
      "properties": {
        "tenDoiTuon": "B1NĐ 29",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Đặng Văn Ngữ",
        "tuDien": "B1NĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Sân vườn",
        "chieuCao": 7,
        "tuDien2": "B1 Nam Đồng",
        "diaChiLapD": "",
        "longitude": 105.83077837549536,
        "latitude": 21.011547688439954,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5852,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83456531929158,
          21.02244692339654
        ]
      },
      "properties": {
        "tenDoiTuon": "18",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Văn Chương",
        "tuDien": "VC4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "BTLT",
        "chieuCao": 7,
        "tuDien2": "VC4",
        "diaChiLapD": "",
        "longitude": 105.83456531929158,
        "latitude": 21.02244692339654,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5853,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83483219909667,
          21.022390590562672
        ]
      },
      "properties": {
        "tenDoiTuon": "17",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Văn Chương",
        "tuDien": "VC4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "BTLT",
        "chieuCao": 7,
        "tuDien2": "VC4",
        "diaChiLapD": "",
        "longitude": 105.83483219909667,
        "latitude": 21.022390590562672,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5854,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82332552244867,
          21.0034834633186
        ]
      },
      "properties": {
        "tenDoiTuon": "11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 554 Trường Chinh",
        "tuDien": "LKT1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Làng Khương Thượng 1",
        "diaChiLapD": "",
        "longitude": 105.82332552244867,
        "latitude": 21.0034834633186,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5855,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82345460373443,
          21.003702876108456
        ]
      },
      "properties": {
        "tenDoiTuon": "10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 554 Trường Chinh",
        "tuDien": "LKT1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Làng Khương Thượng 1",
        "diaChiLapD": "",
        "longitude": 105.82345460373443,
        "latitude": 21.003702876108456,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5856,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82350690681234,
          21.003789263935555
        ]
      },
      "properties": {
        "tenDoiTuon": "10-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 554 Trường Chinh",
        "tuDien": "LKT1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Làng Khương Thượng 1",
        "diaChiLapD": "",
        "longitude": 105.82350690681234,
        "latitude": 21.003789263935555,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5857,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82368158569261,
          21.004082543973855
        ]
      },
      "properties": {
        "tenDoiTuon": "09",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 554 Trường Chinh",
        "tuDien": "LKT1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Làng Khương Thượng 1",
        "diaChiLapD": "",
        "longitude": 105.82368158569261,
        "latitude": 21.004082543973855,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5858,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82383178939803,
          21.0043492186003
        ]
      },
      "properties": {
        "tenDoiTuon": "08",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 554 Trường Chinh",
        "tuDien": "LKT1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Làng Khương Thượng 1",
        "diaChiLapD": "",
        "longitude": 105.82383178939803,
        "latitude": 21.0043492186003,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5859,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82402356734141,
          21.00467974423633
        ]
      },
      "properties": {
        "tenDoiTuon": "07",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 554 Trường Chinh",
        "tuDien": "LKT1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Làng Khương Thượng 1",
        "diaChiLapD": "",
        "longitude": 105.82402356734141,
        "latitude": 21.00467974423633,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5860,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8242461906918,
          21.004518237483246
        ]
      },
      "properties": {
        "tenDoiTuon": "06",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 554 Trường Chinh",
        "tuDien": "LKT1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Làng Khương Thượng 1",
        "diaChiLapD": "",
        "longitude": 105.8242461906918,
        "latitude": 21.004518237483246,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5861,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82449563612857,
          21.004342958638478
        ]
      },
      "properties": {
        "tenDoiTuon": "LKT1 01",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 554 Trường Chinh",
        "tuDien": "LKT1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Làng Khương Thượng 1",
        "diaChiLapD": "",
        "longitude": 105.82449563612857,
        "latitude": 21.004342958638478,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5862,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82378753295231,
          21.003532917300927
        ]
      },
      "properties": {
        "tenDoiTuon": "04",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 554 Trường Chinh",
        "tuDien": "LKT1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Làng Khương Thượng 1",
        "diaChiLapD": "",
        "longitude": 105.82378753295231,
        "latitude": 21.003532917300927,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5863,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8240155207182,
          21.003763284991322
        ]
      },
      "properties": {
        "tenDoiTuon": "LKT1 03",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 554 Trường Chinh",
        "tuDien": "LKT1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Làng Khương Thượng 1",
        "diaChiLapD": "",
        "longitude": 105.8240155207182,
        "latitude": 21.003763284991322,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5864,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82941547803743,
          21.012552673489953
        ]
      },
      "properties": {
        "tenDoiTuon": "B1NĐ 48",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Trần Hữu Tước",
        "tuDien": "B1NĐ",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "B1 Nam Đồng",
        "diaChiLapD": "",
        "longitude": 105.82941547803743,
        "latitude": 21.012552673489953,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5865,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82956836395059,
          21.012833104110733
        ]
      },
      "properties": {
        "tenDoiTuon": "B1NĐ 49",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Trần Hữu Tước",
        "tuDien": "B1NĐ",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "B1 Nam Đồng",
        "diaChiLapD": "",
        "longitude": 105.82956836395059,
        "latitude": 21.012833104110733,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5866,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8295281308165,
          21.013085991987538
        ]
      },
      "properties": {
        "tenDoiTuon": "50-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Trần Hữu Tước",
        "tuDien": "B1NĐ",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "B1 Nam Đồng",
        "diaChiLapD": "",
        "longitude": 105.8295281308165,
        "latitude": 21.013085991987538,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5867,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82972594373402,
          21.013040296932324
        ]
      },
      "properties": {
        "tenDoiTuon": "32",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Trần Hữu Tước",
        "tuDien": "B1NĐ",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "B1 Nam Đồng",
        "diaChiLapD": "",
        "longitude": 105.82972594373402,
        "latitude": 21.013040296932324,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5868,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82993314438316,
          21.013351398604133
        ]
      },
      "properties": {
        "tenDoiTuon": "33",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Trần Hữu Tước",
        "tuDien": "B1NĐ",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "B1 Nam Đồng",
        "diaChiLapD": "",
        "longitude": 105.82993314438316,
        "latitude": 21.013351398604133,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5869,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83005384378545,
          21.013561720493303
        ]
      },
      "properties": {
        "tenDoiTuon": "34",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Trần Hữu Tước",
        "tuDien": "B1NĐ",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "B1 Nam Đồng",
        "diaChiLapD": "",
        "longitude": 105.83005384378545,
        "latitude": 21.013561720493303,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5870,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83023221069013,
          21.013759522951858
        ]
      },
      "properties": {
        "tenDoiTuon": "35",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Trần Hữu Tước",
        "tuDien": "B1NĐ",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "B1 Nam Đồng",
        "diaChiLapD": "",
        "longitude": 105.83023221069013,
        "latitude": 21.013759522951858,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5871,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83037168555559,
          21.01406498952443
        ]
      },
      "properties": {
        "tenDoiTuon": "36",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Trần Hữu Tước",
        "tuDien": "B1NĐ",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "B1 Nam Đồng",
        "diaChiLapD": "",
        "longitude": 105.83037168555559,
        "latitude": 21.01406498952443,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5872,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82986340694595,
          21.013233718369737
        ]
      },
      "properties": {
        "tenDoiTuon": "50",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Trần Hữu Tước",
        "tuDien": "B1NĐ",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "B1 Nam Đồng",
        "diaChiLapD": "",
        "longitude": 105.82986340694595,
        "latitude": 21.013233718369737,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5873,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8242488729175,
          21.00409756790956
        ]
      },
      "properties": {
        "tenDoiTuon": "LKT1 02",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Phố Khương Thượng",
        "tuDien": "LKT1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Làng Khương Thượng 1",
        "diaChiLapD": "",
        "longitude": 105.8242488729175,
        "latitude": 21.00409756790956,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5874,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83777678531163,
          21.01586390122917
        ]
      },
      "properties": {
        "tenDoiTuon": "CN10 01",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Khâm Thiên",
        "tuDien": "CN10",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "CN10",
        "diaChiLapD": "",
        "longitude": 105.83777678531163,
        "latitude": 21.01586390122917,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5875,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83791357796935,
          21.016081730939966
        ]
      },
      "properties": {
        "tenDoiTuon": "02",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Khâm Thiên",
        "tuDien": "CN10",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "CN10",
        "diaChiLapD": "",
        "longitude": 105.83791357796935,
        "latitude": 21.016081730939966,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5876,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83817172302938,
          21.016424844650743
        ]
      },
      "properties": {
        "tenDoiTuon": "03",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Khâm Thiên",
        "tuDien": "CN10",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "CN10",
        "diaChiLapD": "",
        "longitude": 105.83817172302938,
        "latitude": 21.016424844650743,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5877,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83829847495998,
          21.016641326494913
        ]
      },
      "properties": {
        "tenDoiTuon": "04",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Khâm Thiên",
        "tuDien": "CN10",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "CN10",
        "diaChiLapD": "",
        "longitude": 105.83829847495998,
        "latitude": 21.016641326494913,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5878,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83842369182811,
          21.016864089191944
        ]
      },
      "properties": {
        "tenDoiTuon": "05",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Khâm Thiên",
        "tuDien": "CN10",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "CN10",
        "diaChiLapD": "",
        "longitude": 105.83842369182811,
        "latitude": 21.016864089191944,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5879,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83856133144448,
          21.017107028578177
        ]
      },
      "properties": {
        "tenDoiTuon": "06",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Khâm Thiên",
        "tuDien": "CN10",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "CN10",
        "diaChiLapD": "",
        "longitude": 105.83856133144448,
        "latitude": 21.017107028578177,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5880,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83869538470564,
          21.01740007982971
        ]
      },
      "properties": {
        "tenDoiTuon": "CN10 07",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Khâm Thiên",
        "tuDien": "CN10",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "CN10",
        "diaChiLapD": "",
        "longitude": 105.83869538470564,
        "latitude": 21.01740007982971,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5881,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8331554831802,
          21.022733907566682
        ]
      },
      "properties": {
        "tenDoiTuon": "22-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 70 Văn Chương",
        "tuDien": "VC4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VC4",
        "diaChiLapD": "",
        "longitude": 105.8331554831802,
        "latitude": 21.022733907566682,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5882,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83307635801587,
          21.02264001967716
        ]
      },
      "properties": {
        "tenDoiTuon": "22-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 70 Văn Chương",
        "tuDien": "VC4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "VC4",
        "diaChiLapD": "",
        "longitude": 105.83307635801587,
        "latitude": 21.02264001967716,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5883,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83330836910235,
          21.02247227316949
        ]
      },
      "properties": {
        "tenDoiTuon": "VC4 22-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 70 Văn Chương",
        "tuDien": "VC4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VC4",
        "diaChiLapD": "",
        "longitude": 105.83330836910235,
        "latitude": 21.02247227316949,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5884,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8329556586136,
          21.022501065493824
        ]
      },
      "properties": {
        "tenDoiTuon": "VC4 22-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 70 Văn Chương",
        "tuDien": "VC4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "VC4",
        "diaChiLapD": "",
        "longitude": 105.8329556586136,
        "latitude": 21.022501065493824,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5885,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83291676658337,
          21.022651286227468
        ]
      },
      "properties": {
        "tenDoiTuon": "22-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 70 Văn Chương",
        "tuDien": "VC4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "VC4",
        "diaChiLapD": "",
        "longitude": 105.83291676658337,
        "latitude": 21.022651286227468,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5886,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82367756240794,
          21.00333134528231
        ]
      },
      "properties": {
        "tenDoiTuon": "LKT1 05",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 554 Trường Chinh",
        "tuDien": "LKT1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Làng Khương Thượng 1",
        "diaChiLapD": "",
        "longitude": 105.82367756240794,
        "latitude": 21.00333134528231,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5887,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82961127930142,
          21.013331367932846
        ]
      },
      "properties": {
        "tenDoiTuon": "50-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Trần Hữu Tước",
        "tuDien": "B1NĐ",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "B1 Nam Đồng",
        "diaChiLapD": "",
        "longitude": 105.82961127930142,
        "latitude": 21.013331367932846,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5888,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82943693570836,
          21.013424009765963
        ]
      },
      "properties": {
        "tenDoiTuon": "50-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Trần Hữu Tước",
        "tuDien": "B1NĐ",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "B1 Nam Đồng",
        "diaChiLapD": "",
        "longitude": 105.82943693570836,
        "latitude": 21.013424009765963,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5889,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82973734311918,
          21.013589262623842
        ]
      },
      "properties": {
        "tenDoiTuon": "50-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Trần Hữu Tước",
        "tuDien": "B1NĐ",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "B1 Nam Đồng",
        "diaChiLapD": "",
        "longitude": 105.82973734311918,
        "latitude": 21.013589262623842,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5890,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82951740198553,
          21.013636835382258
        ]
      },
      "properties": {
        "tenDoiTuon": "50-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Trần Hữu Tước",
        "tuDien": "B1NĐ",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "B1 Nam Đồng",
        "diaChiLapD": "",
        "longitude": 105.82951740198553,
        "latitude": 21.013636835382258,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5891,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82963810138781,
          21.013834637741198
        ]
      },
      "properties": {
        "tenDoiTuon": "50-7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Trần Hữu Tước",
        "tuDien": "B1NĐ",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "B1 Nam Đồng",
        "diaChiLapD": "",
        "longitude": 105.82963810138781,
        "latitude": 21.013834637741198,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5892,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82983658485054,
          21.013792072698013
        ]
      },
      "properties": {
        "tenDoiTuon": "50-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Trần Hữu Tước",
        "tuDien": "B1NĐ",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "B1 Nam Đồng",
        "diaChiLapD": "",
        "longitude": 105.82983658485054,
        "latitude": 21.013792072698013,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5893,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83353903907596,
          21.023044363095586
        ]
      },
      "properties": {
        "tenDoiTuon": "VC4 22-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 69 Văn Chương",
        "tuDien": "VC4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "VC4",
        "diaChiLapD": "",
        "longitude": 105.83353903907596,
        "latitude": 21.023044363095586,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5894,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8302603738804,
          21.014275310409044
        ]
      },
      "properties": {
        "tenDoiTuon": "37",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Trần Hữu Tước",
        "tuDien": "B1NĐ",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "B1 Nam Đồng",
        "diaChiLapD": "",
        "longitude": 105.8302603738804,
        "latitude": 21.014275310409044,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5895,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8301584499413,
          21.014550730164537
        ]
      },
      "properties": {
        "tenDoiTuon": "38",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Trần Hữu Tước",
        "tuDien": "B1NĐ",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "B1 Nam Đồng",
        "diaChiLapD": "",
        "longitude": 105.8301584499413,
        "latitude": 21.014550730164537,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5896,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8300243394913,
          21.014886240815873
        ]
      },
      "properties": {
        "tenDoiTuon": "39",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Trần Hữu Tước",
        "tuDien": "B1NĐ",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "B1 Nam Đồng",
        "diaChiLapD": "",
        "longitude": 105.8300243394913,
        "latitude": 21.014886240815873,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5897,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82996801310178,
          21.0152117355023
        ]
      },
      "properties": {
        "tenDoiTuon": "40",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Trần Hữu Tước",
        "tuDien": "B1NĐ",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "B1 Nam Đồng",
        "diaChiLapD": "",
        "longitude": 105.82996801310178,
        "latitude": 21.0152117355023,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5898,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8299438732231,
          21.01551970289768
        ]
      },
      "properties": {
        "tenDoiTuon": "B1 NĐ 40-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Trần Hữu Tước",
        "tuDien": "B1NĐ",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "B1 Nam Đồng",
        "diaChiLapD": "",
        "longitude": 105.8299438732231,
        "latitude": 21.01551970289768,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5899,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83005116158668,
          21.016015453955184
        ]
      },
      "properties": {
        "tenDoiTuon": "B1NĐ 42",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Trần Hữu Tước",
        "tuDien": "B1NĐ",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "B1 Nam Đồng",
        "diaChiLapD": "",
        "longitude": 105.83005116158668,
        "latitude": 21.016015453955184,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5900,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83003506833127,
          21.016365984010815
        ]
      },
      "properties": {
        "tenDoiTuon": "B1NĐ 43",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Trần Hữu Tước",
        "tuDien": "B1NĐ",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "B1 Nam Đồng",
        "diaChiLapD": "",
        "longitude": 105.83003506833127,
        "latitude": 21.016365984010815,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5901,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82999215298044,
          21.016641399904618
        ]
      },
      "properties": {
        "tenDoiTuon": "B1NĐ 44",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Trần Hữu Tước",
        "tuDien": "B1NĐ",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "B1 Nam Đồng",
        "diaChiLapD": "",
        "longitude": 105.82999215298044,
        "latitude": 21.016641399904618,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5902,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82994119101537,
          21.015682449637612
        ]
      },
      "properties": {
        "tenDoiTuon": "B1NĐ 41",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Trần Hữu Tước",
        "tuDien": "B1NĐ",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "B1 Nam Đồng",
        "diaChiLapD": "",
        "longitude": 105.82994119101537,
        "latitude": 21.015682449637612,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5903,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82978562288548,
          21.017024477530533
        ]
      },
      "properties": {
        "tenDoiTuon": "B1NĐ 45",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Trần Hữu Tước",
        "tuDien": "B1NĐ",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "B1 Nam Đồng",
        "diaChiLapD": "",
        "longitude": 105.82978562288548,
        "latitude": 21.017024477530533,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5904,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82964883022775,
          21.017314914812836
        ]
      },
      "properties": {
        "tenDoiTuon": "B1NĐ 46",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Trần Hữu Tước",
        "tuDien": "B1NĐ",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "B1 Nam Đồng",
        "diaChiLapD": "",
        "longitude": 105.82964883022775,
        "latitude": 21.017314914812836,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5905,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83358865993713,
          21.022682582193152
        ]
      },
      "properties": {
        "tenDoiTuon": "21-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 92 Văn Chương",
        "tuDien": "VC4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "VC4",
        "diaChiLapD": "",
        "longitude": 105.83358865993713,
        "latitude": 21.022682582193152,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5906,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83349612372514,
          21.02272013734483
        ]
      },
      "properties": {
        "tenDoiTuon": "21-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 92 Văn Chương",
        "tuDien": "VC4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "VC4",
        "diaChiLapD": "",
        "longitude": 105.83349612372514,
        "latitude": 21.02272013734483,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5907,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83358061331391,
          21.022567413001042
        ]
      },
      "properties": {
        "tenDoiTuon": "21-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 92 Văn Chương",
        "tuDien": "VC4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "VC4",
        "diaChiLapD": "",
        "longitude": 105.83358061331391,
        "latitude": 21.022567413001042,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5908,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83373215812321,
          21.02250482101222
        ]
      },
      "properties": {
        "tenDoiTuon": "VC4 21-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 92 Văn Chương",
        "tuDien": "VC4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "VC4",
        "diaChiLapD": "",
        "longitude": 105.83373215812321,
        "latitude": 21.02250482101222,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5909,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83378459243133,
          21.02303685308345
        ]
      },
      "properties": {
        "tenDoiTuon": "VC4 21-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 105 Văn Chương",
        "tuDien": "VC4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "VC4",
        "diaChiLapD": "",
        "longitude": 105.83378459243133,
        "latitude": 21.02303685308345,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5910,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80383860338495,
          21.01813865196275
        ]
      },
      "properties": {
        "tenDoiTuon": "LT1 9-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Đường Láng",
        "tuDien": "LTG1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Láng Trung 1",
        "diaChiLapD": "",
        "longitude": 105.80383860338495,
        "latitude": 21.01813865196275,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5911,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83816168230226,
          21.015633552224315
        ]
      },
      "properties": {
        "tenDoiTuon": "CN10 1-14",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 153 Phố Chợ Khâm Thiên",
        "tuDien": "CN10",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "CN10",
        "diaChiLapD": "",
        "longitude": 105.83816168230226,
        "latitude": 21.015633552224315,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5912,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80354221929473,
          21.017957129729893
        ]
      },
      "properties": {
        "tenDoiTuon": "9-14",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Đường Láng",
        "tuDien": "LTG1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Láng Trung 1",
        "diaChiLapD": "",
        "longitude": 105.80354221929473,
        "latitude": 21.017957129729893,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5913,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83415997045569,
          21.022778973731008
        ]
      },
      "properties": {
        "tenDoiTuon": "20-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 139 Văn Chương",
        "tuDien": "VC4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VC4",
        "diaChiLapD": "",
        "longitude": 105.83415997045569,
        "latitude": 21.022778973731008,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5914,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83394539373752,
          21.022469769487504
        ]
      },
      "properties": {
        "tenDoiTuon": "VC4 20-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 124 Văn Chương",
        "tuDien": "VC4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VC4",
        "diaChiLapD": "",
        "longitude": 105.83394539373752,
        "latitude": 21.022469769487504,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5915,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80346141773933,
          21.017415378443374
        ]
      },
      "properties": {
        "tenDoiTuon": "08",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "LTG1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Láng Trung 1",
        "diaChiLapD": "",
        "longitude": 105.80346141773933,
        "latitude": 21.017415378443374,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5916,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80330316741069,
          21.01764071717159
        ]
      },
      "properties": {
        "tenDoiTuon": "09",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "LTG1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Láng Trung 1",
        "diaChiLapD": "",
        "longitude": 105.80330316741069,
        "latitude": 21.01764071717159,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5917,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83768822560394,
          21.016139027939527
        ]
      },
      "properties": {
        "tenDoiTuon": "CN10 2-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 154 Phố Chợ Khâm Thiên",
        "tuDien": "CN10",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "CN10",
        "diaChiLapD": "",
        "longitude": 105.83768822560394,
        "latitude": 21.016139027939527,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5918,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83747503679692,
          21.016219439212517
        ]
      },
      "properties": {
        "tenDoiTuon": "2-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 154 Phố Chợ Khâm Thiên",
        "tuDien": "CN10",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "CN10",
        "diaChiLapD": "",
        "longitude": 105.83747503679692,
        "latitude": 21.016219439212517,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5919,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83724704903105,
          21.01623446192596
        ]
      },
      "properties": {
        "tenDoiTuon": "2-7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 154 Phố Chợ Khâm Thiên",
        "tuDien": "CN10",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "CN10",
        "diaChiLapD": "",
        "longitude": 105.83724704903105,
        "latitude": 21.01623446192596,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5920,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83711887428694,
          21.01632265904732
        ]
      },
      "properties": {
        "tenDoiTuon": "2-8",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 154 Phố Chợ Khâm Thiên",
        "tuDien": "CN10",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "CN10",
        "diaChiLapD": "",
        "longitude": 105.83711887428694,
        "latitude": 21.01632265904732,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5921,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83713976066747,
          21.016219439212517
        ]
      },
      "properties": {
        "tenDoiTuon": "2-12",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 154 Phố Chợ Khâm Thiên",
        "tuDien": "CN10",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "CN10",
        "diaChiLapD": "",
        "longitude": 105.83713976066747,
        "latitude": 21.016219439212517,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5922,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83718804043377,
          21.016422245710967
        ]
      },
      "properties": {
        "tenDoiTuon": "2-9",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 154 Phố Chợ Khâm Thiên",
        "tuDien": "CN10",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "CN10",
        "diaChiLapD": "",
        "longitude": 105.83718804043377,
        "latitude": 21.016422245710967,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5923,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83706734103149,
          21.01648484025314
        ]
      },
      "properties": {
        "tenDoiTuon": "2-10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 154 Phố Chợ Khâm Thiên",
        "tuDien": "CN10",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "CN10",
        "diaChiLapD": "",
        "longitude": 105.83706734103149,
        "latitude": 21.01648484025314,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5924,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83700565021748,
          21.016459802439428
        ]
      },
      "properties": {
        "tenDoiTuon": "2-11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 154 Phố Chợ Khâm Thiên",
        "tuDien": "CN10",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "CN10",
        "diaChiLapD": "",
        "longitude": 105.83700565021748,
        "latitude": 21.016459802439428,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5925,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83694932382795,
          21.01650487050177
        ]
      },
      "properties": {
        "tenDoiTuon": "2-12",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 154 Phố Chợ Khâm Thiên",
        "tuDien": "CN10",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "CN10",
        "diaChiLapD": "",
        "longitude": 105.83694932382795,
        "latitude": 21.01650487050177,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5926,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8368983618629,
          21.016432260839817
        ]
      },
      "properties": {
        "tenDoiTuon": "CN10 2-13",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 154 Phố Chợ Khâm Thiên",
        "tuDien": "CN10",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "CN10",
        "diaChiLapD": "",
        "longitude": 105.8368983618629,
        "latitude": 21.016432260839817,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5927,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83430883305724,
          21.022766455353104
        ]
      },
      "properties": {
        "tenDoiTuon": "19-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 145 Văn Chương",
        "tuDien": "VC4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VC4",
        "diaChiLapD": "",
        "longitude": 105.83430883305724,
        "latitude": 21.022766455353104,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5928,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83435711282354,
          21.022900401942987
        ]
      },
      "properties": {
        "tenDoiTuon": "19-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 145 Văn Chương",
        "tuDien": "VC4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "VC4",
        "diaChiLapD": "",
        "longitude": 105.83435711282354,
        "latitude": 21.022900401942987,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5929,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83422970789292,
          21.022951727239825
        ]
      },
      "properties": {
        "tenDoiTuon": "19-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 145 Văn Chương",
        "tuDien": "VC4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VC4",
        "diaChiLapD": "",
        "longitude": 105.83422970789292,
        "latitude": 21.022951727239825,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5930,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83440405147701,
          21.023015570877025
        ]
      },
      "properties": {
        "tenDoiTuon": "19-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 145 Văn Chương",
        "tuDien": "VC4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VC4",
        "diaChiLapD": "",
        "longitude": 105.83440405147701,
        "latitude": 21.023015570877025,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5931,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83431687968944,
          21.02314450990433
        ]
      },
      "properties": {
        "tenDoiTuon": "VC4 19-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 145 Văn Chương",
        "tuDien": "VC4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "VC4",
        "diaChiLapD": "",
        "longitude": 105.83431687968944,
        "latitude": 21.02314450990433,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5932,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80358345826346,
          21.017591893808785
        ]
      },
      "properties": {
        "tenDoiTuon": "LT1 8-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 766 Đường Láng",
        "tuDien": "LTG1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Láng Trung 1",
        "diaChiLapD": "",
        "longitude": 105.80358345826346,
        "latitude": 21.017591893808785,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5933,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80376987179136,
          21.01734652487305
        ]
      },
      "properties": {
        "tenDoiTuon": "LT1 8-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 766 Đường Láng",
        "tuDien": "LTG1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Láng Trung 1",
        "diaChiLapD": "",
        "longitude": 105.80376987179136,
        "latitude": 21.01734652487305,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5934,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8035861404712,
          21.01722884779182
        ]
      },
      "properties": {
        "tenDoiTuon": "07",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "LTG1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Láng Trung 1",
        "diaChiLapD": "",
        "longitude": 105.8035861404712,
        "latitude": 21.01722884779182,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5935,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80373634417661,
          21.017032301819917
        ]
      },
      "properties": {
        "tenDoiTuon": "06",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "LTG1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Láng Trung 1",
        "diaChiLapD": "",
        "longitude": 105.80373634417661,
        "latitude": 21.017032301819917,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5936,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80380742271771,
          21.01694592166135
        ]
      },
      "properties": {
        "tenDoiTuon": "05",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "LTG1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Láng Trung 1",
        "diaChiLapD": "",
        "longitude": 105.80380742271771,
        "latitude": 21.01694592166135,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5937,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80403675160541,
          21.016640460991045
        ]
      },
      "properties": {
        "tenDoiTuon": "04",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "LTG1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Láng Trung 1",
        "diaChiLapD": "",
        "longitude": 105.80403675160541,
        "latitude": 21.016640460991045,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5938,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83414253609638,
          21.022493554451696
        ]
      },
      "properties": {
        "tenDoiTuon": "19-8",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 128 Văn Chương",
        "tuDien": "VC4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "VC4",
        "diaChiLapD": "",
        "longitude": 105.83414253609638,
        "latitude": 21.022493554451696,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5939,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83414655941698,
          21.022390903524876
        ]
      },
      "properties": {
        "tenDoiTuon": "19-9",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 128 Văn Chương",
        "tuDien": "VC4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 7,
        "tuDien2": "VC4",
        "diaChiLapD": "",
        "longitude": 105.83414655941698,
        "latitude": 21.022390903524876,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5940,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83413314836929,
          21.022293259894894
        ]
      },
      "properties": {
        "tenDoiTuon": "19-10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 128 Văn Chương",
        "tuDien": "VC4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "VC4",
        "diaChiLapD": "",
        "longitude": 105.83413314836929,
        "latitude": 21.022293259894894,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5941,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8342967631224,
          21.02238339247888
        ]
      },
      "properties": {
        "tenDoiTuon": "19-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 138 Văn Chương",
        "tuDien": "VC4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VC4",
        "diaChiLapD": "",
        "longitude": 105.8342967631224,
        "latitude": 21.02238339247888,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5942,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83421897905296,
          21.022230667790968
        ]
      },
      "properties": {
        "tenDoiTuon": "19-7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 138 Văn Chương",
        "tuDien": "VC4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VC4",
        "diaChiLapD": "",
        "longitude": 105.83421897905296,
        "latitude": 21.022230667790968,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5943,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83797929208598,
          21.01643351273191
        ]
      },
      "properties": {
        "tenDoiTuon": "CN10 3-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 138 Phố Chợ Khâm Thiên",
        "tuDien": "CN10",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "CN10",
        "diaChiLapD": "",
        "longitude": 105.83797929208598,
        "latitude": 21.01643351273191,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5944,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83782104174836,
          21.016375925733577
        ]
      },
      "properties": {
        "tenDoiTuon": "3-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 138 Phố Chợ Khâm Thiên",
        "tuDien": "CN10",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "CN10",
        "diaChiLapD": "",
        "longitude": 105.83782104174836,
        "latitude": 21.016375925733577,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5945,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83787736813788,
          21.016436016513573
        ]
      },
      "properties": {
        "tenDoiTuon": "3+6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 138 Phố Chợ Khâm Thiên",
        "tuDien": "CN10",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "CN10",
        "diaChiLapD": "",
        "longitude": 105.83787736813788,
        "latitude": 21.016436016513573,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5946,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83769923710294,
          21.016549390105745
        ]
      },
      "properties": {
        "tenDoiTuon": "3-7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 138 Phố Chợ Khâm Thiên",
        "tuDien": "CN10",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "CN10",
        "diaChiLapD": "",
        "longitude": 105.83769923710294,
        "latitude": 21.016549390105745,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5947,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83760378282244,
          21.01659375469567
        ]
      },
      "properties": {
        "tenDoiTuon": "3-8",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 138 Phố Chợ Khâm Thiên",
        "tuDien": "CN10",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "CN10",
        "diaChiLapD": "",
        "longitude": 105.83760378282244,
        "latitude": 21.01659375469567,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5948,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83755282084839,
          21.016478580801365
        ]
      },
      "properties": {
        "tenDoiTuon": "3-9",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 138 Phố Chợ Khâm Thiên",
        "tuDien": "CN10",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "CN10",
        "diaChiLapD": "",
        "longitude": 105.83755282084839,
        "latitude": 21.016478580801365,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5949,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83735433738565,
          21.016588747136762
        ]
      },
      "properties": {
        "tenDoiTuon": "3-10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 138 Phố Chợ Khâm Thiên",
        "tuDien": "CN10",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "CN10",
        "diaChiLapD": "",
        "longitude": 105.83735433738565,
        "latitude": 21.016588747136762,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5950,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83714512508297,
          21.01666636427899
        ]
      },
      "properties": {
        "tenDoiTuon": "CN10 3-11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 138 Phố Chợ Khâm Thiên",
        "tuDien": "CN10",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "CN10",
        "diaChiLapD": "",
        "longitude": 105.83714512508297,
        "latitude": 21.01666636427899,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5951,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83429307513391,
          21.014724745110193
        ]
      },
      "properties": {
        "tenDoiTuon": "16",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "La Thành",
        "tuDien": "LAT3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "La Thành 3",
        "diaChiLapD": "",
        "longitude": 105.83429307513391,
        "latitude": 21.014724745110193,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5952,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83395511679672,
          21.015100316246436
        ]
      },
      "properties": {
        "tenDoiTuon": "17",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "La Thành",
        "tuDien": "LAT3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "La Thành 3",
        "diaChiLapD": "",
        "longitude": 105.83395511679672,
        "latitude": 21.015100316246436,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5953,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83362788729949,
          21.015400772474173
        ]
      },
      "properties": {
        "tenDoiTuon": "18",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "La Thành",
        "tuDien": "LAT3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "La Thành 3",
        "diaChiLapD": "",
        "longitude": 105.83362788729949,
        "latitude": 21.015400772474173,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5954,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83339587621975,
          21.015641137020527
        ]
      },
      "properties": {
        "tenDoiTuon": "19",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "La Thành",
        "tuDien": "LAT3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "La Thành 3",
        "diaChiLapD": "",
        "longitude": 105.83339587621975,
        "latitude": 21.015641137020527,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5955,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83306998782864,
          21.015971637639243
        ]
      },
      "properties": {
        "tenDoiTuon": "20",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "La Thành",
        "tuDien": "LAT3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "La Thành 3",
        "diaChiLapD": "",
        "longitude": 105.83306998782864,
        "latitude": 21.015971637639243,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5956,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80419533723023,
          21.016466135306825
        ]
      },
      "properties": {
        "tenDoiTuon": "03",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "LTG1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Láng Trung 1",
        "diaChiLapD": "",
        "longitude": 105.80419533723023,
        "latitude": 21.016466135306825,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5957,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80435090535114,
          21.016299633744985
        ]
      },
      "properties": {
        "tenDoiTuon": "02",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "LTG1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Láng Trung 1",
        "diaChiLapD": "",
        "longitude": 105.80435090535114,
        "latitude": 21.016299633744985,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5958,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80455877654997,
          21.016081804352478
        ]
      },
      "properties": {
        "tenDoiTuon": "LT1 01",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "LTG1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Láng Trung 1",
        "diaChiLapD": "",
        "longitude": 105.80455877654997,
        "latitude": 21.016081804352478,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5959,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80475860112556,
          21.016257069406127
        ]
      },
      "properties": {
        "tenDoiTuon": "1-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 678 Đường Láng",
        "tuDien": "LTG1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Láng Trung 1",
        "diaChiLapD": "",
        "longitude": 105.80475860112556,
        "latitude": 21.016257069406127,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5960,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80460303299567,
          21.016443601271504
        ]
      },
      "properties": {
        "tenDoiTuon": "LT1 1-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 678 Đường Láng",
        "tuDien": "LTG1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Láng Trung 1",
        "diaChiLapD": "",
        "longitude": 105.80460303299567,
        "latitude": 21.016443601271504,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5961,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80497586005147,
          21.01619823044646
        ]
      },
      "properties": {
        "tenDoiTuon": "LT1 1-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 678 Đường Láng",
        "tuDien": "LTG1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Láng Trung 1",
        "diaChiLapD": "",
        "longitude": 105.80497586005147,
        "latitude": 21.01619823044646,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5962,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80498524778754,
          21.016462379634653
        ]
      },
      "properties": {
        "tenDoiTuon": "LT1 1-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 678 Đường Láng",
        "tuDien": "LTG1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Láng Trung 1",
        "diaChiLapD": "",
        "longitude": 105.80498524778754,
        "latitude": 21.016462379634653,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5963,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80421679491013,
          21.01676158122981
        ]
      },
      "properties": {
        "tenDoiTuon": "4-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 718 Đường Láng",
        "tuDien": "LTG1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Láng Trung 1",
        "diaChiLapD": "",
        "longitude": 105.80421679491013,
        "latitude": 21.01676158122981,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5964,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80442466610897,
          21.01693684548376
        ]
      },
      "properties": {
        "tenDoiTuon": "LT1 4-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 718 Đường Láng",
        "tuDien": "LTG1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Láng Trung 1",
        "diaChiLapD": "",
        "longitude": 105.80442466610897,
        "latitude": 21.01693684548376,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5965,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83434403710797,
          21.014539463000506
        ]
      },
      "properties": {
        "tenDoiTuon": "1-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Cống Chẹm",
        "tuDien": "LAT3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "La Thành 3",
        "diaChiLapD": "",
        "longitude": 105.83434403710797,
        "latitude": 21.014539463000506,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5966,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83456934266607,
          21.014772317506445
        ]
      },
      "properties": {
        "tenDoiTuon": "1-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 126 Đê La Thành",
        "tuDien": "LAT3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "La Thành 3",
        "diaChiLapD": "",
        "longitude": 105.83456934266607,
        "latitude": 21.014772317506445,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5967,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83483756356605,
          21.01456575304356
        ]
      },
      "properties": {
        "tenDoiTuon": "02",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 126 Đê La Thành",
        "tuDien": "LAT3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "La Thành 3",
        "diaChiLapD": "",
        "longitude": 105.83483756356605,
        "latitude": 21.01456575304356,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5968,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83495155744899,
          21.01487872938933
        ]
      },
      "properties": {
        "tenDoiTuon": "03",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 126 Đê La Thành",
        "tuDien": "LAT3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "La Thành 3",
        "diaChiLapD": "",
        "longitude": 105.83495155744899,
        "latitude": 21.01487872938933,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5969,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83513528876016,
          21.01505900346643
        ]
      },
      "properties": {
        "tenDoiTuon": "04",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 126 Đê La Thành",
        "tuDien": "LAT3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "La Thành 3",
        "diaChiLapD": "",
        "longitude": 105.83513528876016,
        "latitude": 21.01505900346643,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5970,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83523050717992,
          21.015174178457862
        ]
      },
      "properties": {
        "tenDoiTuon": "4-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 126 Đê La Thành",
        "tuDien": "LAT3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "La Thành 3",
        "diaChiLapD": "",
        "longitude": 105.83523050717992,
        "latitude": 21.015174178457862,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5971,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83538473420593,
          21.01508028580836
        ]
      },
      "properties": {
        "tenDoiTuon": "05",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 126 Đê La Thành",
        "tuDien": "LAT3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "La Thành 3",
        "diaChiLapD": "",
        "longitude": 105.83538473420593,
        "latitude": 21.01508028580836,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5972,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83552689127914,
          21.01498764500395
        ]
      },
      "properties": {
        "tenDoiTuon": "5-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 126 Đê La Thành",
        "tuDien": "LAT3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "La Thành 3",
        "diaChiLapD": "",
        "longitude": 105.83552689127914,
        "latitude": 21.01498764500395,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5973,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83562881521826,
          21.015023950191416
        ]
      },
      "properties": {
        "tenDoiTuon": "06",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 126 Đê La Thành",
        "tuDien": "LAT3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "La Thành 3",
        "diaChiLapD": "",
        "longitude": 105.83562881521826,
        "latitude": 21.015023950191416,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5974,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83579645328297,
          21.015125354287548
        ]
      },
      "properties": {
        "tenDoiTuon": "07",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 126 Đê La Thành",
        "tuDien": "LAT3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "La Thành 3",
        "diaChiLapD": "",
        "longitude": 105.83579645328297,
        "latitude": 21.015125354287548,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5975,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83558589987642,
          21.015329414173518
        ]
      },
      "properties": {
        "tenDoiTuon": "08",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 126 Đê La Thành",
        "tuDien": "LAT3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "La Thành 3",
        "diaChiLapD": "",
        "longitude": 105.83558589987642,
        "latitude": 21.015329414173518,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5976,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8356395440582,
          21.015525962389695
        ]
      },
      "properties": {
        "tenDoiTuon": "09",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 126 Đê La Thành",
        "tuDien": "LAT3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "La Thành 3",
        "diaChiLapD": "",
        "longitude": 105.8356395440582,
        "latitude": 21.015525962389695,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5977,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83574415021405,
          21.01566241927853
        ]
      },
      "properties": {
        "tenDoiTuon": "10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 126 Đê La Thành",
        "tuDien": "LAT3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "La Thành 3",
        "diaChiLapD": "",
        "longitude": 105.83574415021405,
        "latitude": 21.01566241927853,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5978,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83587423734342,
          21.015805135524484
        ]
      },
      "properties": {
        "tenDoiTuon": "11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 126 Đê La Thành",
        "tuDien": "LAT3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "La Thành 3",
        "diaChiLapD": "",
        "longitude": 105.83587423734342,
        "latitude": 21.015805135524484,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5979,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8359989600663,
          21.01552095479493
        ]
      },
      "properties": {
        "tenDoiTuon": "12",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 126 Đê La Thành",
        "tuDien": "LAT3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "La Thành 3",
        "diaChiLapD": "",
        "longitude": 105.8359989600663,
        "latitude": 21.01552095479493,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5980,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80390029424387,
          21.01700945490085
        ]
      },
      "properties": {
        "tenDoiTuon": "LT1 5-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 726 Đường Láng",
        "tuDien": "LTG1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Láng Trung 1",
        "diaChiLapD": "",
        "longitude": 105.80390029424387,
        "latitude": 21.01700945490085,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5981,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8344442846111,
          21.022387148002764
        ]
      },
      "properties": {
        "tenDoiTuon": "18-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 142 Văn Chương",
        "tuDien": "VC4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 7,
        "tuDien2": "VC4",
        "diaChiLapD": "",
        "longitude": 105.8344442846111,
        "latitude": 21.022387148002764,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5982,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83438661711769,
          21.022250697267747
        ]
      },
      "properties": {
        "tenDoiTuon": "18-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 142 Văn Chương",
        "tuDien": "VC4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "VC4",
        "diaChiLapD": "",
        "longitude": 105.83438661711769,
        "latitude": 21.022250697267747,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5983,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83806244056191,
          21.016641326495748
        ]
      },
      "properties": {
        "tenDoiTuon": "CN10 3-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 126 Phố Chợ Khâm Thiên",
        "tuDien": "CN10",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "CN10",
        "diaChiLapD": "",
        "longitude": 105.83806244056191,
        "latitude": 21.016641326495748,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5984,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83790687244102,
          21.016738973826946
        ]
      },
      "properties": {
        "tenDoiTuon": "3-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 126 Phố Chợ Khâm Thiên",
        "tuDien": "CN10",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "CN10",
        "diaChiLapD": "",
        "longitude": 105.83790687244102,
        "latitude": 21.016738973826946,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5985,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83774057548015,
          21.016851643745508
        ]
      },
      "properties": {
        "tenDoiTuon": "3-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 126 Phố Chợ Khâm Thiên",
        "tuDien": "CN10",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "CN10",
        "diaChiLapD": "",
        "longitude": 105.83774057548015,
        "latitude": 21.016851643745508,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5986,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83758500735925,
          21.01695930603247
        ]
      },
      "properties": {
        "tenDoiTuon": "CN10 3-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 126 Phố Chợ Khâm Thiên",
        "tuDien": "CN10",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "CN10",
        "diaChiLapD": "",
        "longitude": 105.83758500735925,
        "latitude": 21.01695930603247,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5987,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83447915333869,
          21.022676323001548
        ]
      },
      "properties": {
        "tenDoiTuon": "18-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 163+177 Văn Chương",
        "tuDien": "VC4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VC4",
        "diaChiLapD": "",
        "longitude": 105.83447915333869,
        "latitude": 21.022676323001548,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5988,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83453547972822,
          21.022816528859227
        ]
      },
      "properties": {
        "tenDoiTuon": "18-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 163+177 Văn Chương",
        "tuDien": "VC4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VC4",
        "diaChiLapD": "",
        "longitude": 105.83453547972822,
        "latitude": 21.022816528859227,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5989,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8345757128623,
          21.02289038724942
        ]
      },
      "properties": {
        "tenDoiTuon": "18-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 163+177 Văn Chương",
        "tuDien": "VC4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "VC4",
        "diaChiLapD": "",
        "longitude": 105.8345757128623,
        "latitude": 21.02289038724942,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5990,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83460253494871,
          21.022635012321306
        ]
      },
      "properties": {
        "tenDoiTuon": "18-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 163+177 Văn Chương",
        "tuDien": "VC4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "VC4",
        "diaChiLapD": "",
        "longitude": 105.83460253494871,
        "latitude": 21.022635012321306,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5991,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83465752023439,
          21.022496058133274
        ]
      },
      "properties": {
        "tenDoiTuon": "18-7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 163+177 Văn Chương",
        "tuDien": "VC4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "VC4",
        "diaChiLapD": "",
        "longitude": 105.83465752023439,
        "latitude": 21.022496058133274,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5992,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80317743892502,
          21.017854475681574
        ]
      },
      "properties": {
        "tenDoiTuon": "10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "LTG1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Láng Trung 1",
        "diaChiLapD": "",
        "longitude": 105.80317743892502,
        "latitude": 21.017854475681574,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5993,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80306478614597,
          21.018047264936747
        ]
      },
      "properties": {
        "tenDoiTuon": "11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "LTG1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Láng Trung 1",
        "diaChiLapD": "",
        "longitude": 105.80306478614597,
        "latitude": 21.018047264936747,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5994,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80295749778239,
          21.018247565197395
        ]
      },
      "properties": {
        "tenDoiTuon": "12",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "LTG1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Láng Trung 1",
        "diaChiLapD": "",
        "longitude": 105.80295749778239,
        "latitude": 21.018247565197395,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5995,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8028502094278,
          21.01845162081202
        ]
      },
      "properties": {
        "tenDoiTuon": "13",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "LTG1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Láng Trung 1",
        "diaChiLapD": "",
        "longitude": 105.8028502094278,
        "latitude": 21.01845162081202,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5996,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80274157996033,
          21.018670698614596
        ]
      },
      "properties": {
        "tenDoiTuon": "14",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "LTG1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Láng Trung 1",
        "diaChiLapD": "",
        "longitude": 105.80274157996033,
        "latitude": 21.018670698614596,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5997,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83669096999611,
          21.01595411110877
        ]
      },
      "properties": {
        "tenDoiTuon": "15-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 218 Khâm Thiên",
        "tuDien": "LAT3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "La Thành 3",
        "diaChiLapD": "",
        "longitude": 105.83669096999611,
        "latitude": 21.01595411110877,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5998,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83600030117019,
          21.016290870495503
        ]
      },
      "properties": {
        "tenDoiTuon": "11-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 126 Đê La Thành",
        "tuDien": "LAT3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "La Thành 3",
        "diaChiLapD": "",
        "longitude": 105.83600030117019,
        "latitude": 21.016290870495503,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 5999,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83600298337792,
          21.016009194480343
        ]
      },
      "properties": {
        "tenDoiTuon": "11-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 126 Đê La Thành",
        "tuDien": "LAT3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "La Thành 3",
        "diaChiLapD": "",
        "longitude": 105.83600298337792,
        "latitude": 21.016009194480343,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6000,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83613977603565,
          21.016220764514642
        ]
      },
      "properties": {
        "tenDoiTuon": "11-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 126 Đê La Thành",
        "tuDien": "LAT3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "La Thành 3",
        "diaChiLapD": "",
        "longitude": 105.83613977603565,
        "latitude": 21.016220764514642,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6001,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83631579594791,
          21.015865539512046
        ]
      },
      "properties": {
        "tenDoiTuon": "14",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 218 Khâm Thiên",
        "tuDien": "LAT3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "La Thành 3",
        "diaChiLapD": "",
        "longitude": 105.83631579594791,
        "latitude": 21.015865539512046,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6002,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83652869634687,
          21.015867730325684
        ]
      },
      "properties": {
        "tenDoiTuon": "15",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 218 Khâm Thiên",
        "tuDien": "LAT3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "La Thành 3",
        "diaChiLapD": "",
        "longitude": 105.83652869634687,
        "latitude": 21.015867730325684,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6003,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83646432333413,
          21.016045499419132
        ]
      },
      "properties": {
        "tenDoiTuon": "14-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 218 Khâm Thiên",
        "tuDien": "LAT3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "La Thành 3",
        "diaChiLapD": "",
        "longitude": 105.83646432333413,
        "latitude": 21.016045499419132,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6004,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83480906504366,
          21.022233171476135
        ]
      },
      "properties": {
        "tenDoiTuon": "17-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 168 Văn Chương",
        "tuDien": "VC4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "VC4",
        "diaChiLapD": "",
        "longitude": 105.83480906504366,
        "latitude": 21.022233171476135,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6005,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83615318708334,
          21.015738785005787
        ]
      },
      "properties": {
        "tenDoiTuon": "13",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 126 Đê La Thành",
        "tuDien": "LAT3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "La Thành 3",
        "diaChiLapD": "",
        "longitude": 105.83615318708334,
        "latitude": 21.015738785005787,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6006,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83846208970412,
          21.016558701780554
        ]
      },
      "properties": {
        "tenDoiTuon": "CN10 4-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Xã Đằng",
        "tuDien": "CN10",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "CN10",
        "diaChiLapD": "",
        "longitude": 105.83846208970412,
        "latitude": 21.016558701780554,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6007,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83270353096907,
          21.02302808923486
        ]
      },
      "properties": {
        "tenDoiTuon": "23-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 34+27 Văn Chương",
        "tuDien": "VC4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VC4",
        "diaChiLapD": "",
        "longitude": 105.83270353096907,
        "latitude": 21.02302808923486,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6008,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83296102302907,
          21.023328529488687
        ]
      },
      "properties": {
        "tenDoiTuon": "23-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 34+27 Văn Chương",
        "tuDien": "VC4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VC4",
        "diaChiLapD": "",
        "longitude": 105.83296102302907,
        "latitude": 21.023328529488687,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6009,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83303612488179,
          21.02354259280005
        ]
      },
      "properties": {
        "tenDoiTuon": "23-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 34+27 Văn Chương",
        "tuDien": "VC4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VC4",
        "diaChiLapD": "",
        "longitude": 105.83303612488179,
        "latitude": 21.02354259280005,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6010,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80262892718127,
          21.018868494297045
        ]
      },
      "properties": {
        "tenDoiTuon": "15",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "LTG1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Láng Trung 1",
        "diaChiLapD": "",
        "longitude": 105.80262892718127,
        "latitude": 21.018868494297045,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6011,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80252834434603,
          21.01906253411043
        ]
      },
      "properties": {
        "tenDoiTuon": "16",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "LTG1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Láng Trung 1",
        "diaChiLapD": "",
        "longitude": 105.80252834434603,
        "latitude": 21.01906253411043,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6012,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80243178481342,
          21.019254069936167
        ]
      },
      "properties": {
        "tenDoiTuon": "17",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "LTG1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Láng Trung 1",
        "diaChiLapD": "",
        "longitude": 105.80243178481342,
        "latitude": 21.019254069936167,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6013,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80231343234965,
          21.01949035970923
        ]
      },
      "properties": {
        "tenDoiTuon": "18",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "LTG1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Láng Trung 1",
        "diaChiLapD": "",
        "longitude": 105.80231343234965,
        "latitude": 21.01949035970923,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6014,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83822605531503,
          21.015915228948955
        ]
      },
      "properties": {
        "tenDoiTuon": "CN10 2-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 143 Phố Chợ Khâm Thiên",
        "tuDien": "CN10",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "CN10",
        "diaChiLapD": "",
        "longitude": 105.83822605531503,
        "latitude": 21.015915228948955,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6015,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83832261483865,
          21.016047929799
        ]
      },
      "properties": {
        "tenDoiTuon": "2-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 143 Phố Chợ Khâm Thiên",
        "tuDien": "CN10",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "CN10",
        "diaChiLapD": "",
        "longitude": 105.83832261483865,
        "latitude": 21.016047929799,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6016,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83846477191186,
          21.016130554797186
        ]
      },
      "properties": {
        "tenDoiTuon": "CN10 2-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 143 Phố Chợ Khâm Thiên",
        "tuDien": "CN10",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "CN10",
        "diaChiLapD": "",
        "longitude": 105.83846477191186,
        "latitude": 21.016130554797186,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6017,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83835480134952,
          21.01581006970127
        ]
      },
      "properties": {
        "tenDoiTuon": "2-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 143 Phố Chợ Khâm Thiên",
        "tuDien": "CN10",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "CN10",
        "diaChiLapD": "",
        "longitude": 105.83835480134952,
        "latitude": 21.01581006970127,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6018,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83842453877777,
          21.015767505222698
        ]
      },
      "properties": {
        "tenDoiTuon": "2-2-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 143 Phố Chợ Khâm Thiên",
        "tuDien": "CN10",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "CN10",
        "diaChiLapD": "",
        "longitude": 105.83842453877777,
        "latitude": 21.015767505222698,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6019,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82570900041956,
          21.021293660849004
        ]
      },
      "properties": {
        "tenDoiTuon": "LAT5 1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "La Thành",
        "tuDien": "LT5",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "LT5",
        "diaChiLapD": "",
        "longitude": 105.82570900041956,
        "latitude": 21.021293660849004,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6020,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82539786417776,
          21.021423853214053
        ]
      },
      "properties": {
        "tenDoiTuon": "LAT5 2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "La Thành",
        "tuDien": "LT5",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "LT5",
        "diaChiLapD": "",
        "longitude": 105.82539786417776,
        "latitude": 21.021423853214053,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6021,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82512696107004,
          21.02155404546626
        ]
      },
      "properties": {
        "tenDoiTuon": "LAT5 3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "La Thành",
        "tuDien": "LT5",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "LT5",
        "diaChiLapD": "",
        "longitude": 105.82512696107004,
        "latitude": 21.02155404546626,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6022,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82484801133012,
          21.021686741298268
        ]
      },
      "properties": {
        "tenDoiTuon": "LAT5 4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "La Thành",
        "tuDien": "LT5",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "LT5",
        "diaChiLapD": "",
        "longitude": 105.82484801133012,
        "latitude": 21.021686741298268,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6023,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82455565054254,
          21.021829451778515
        ]
      },
      "properties": {
        "tenDoiTuon": "LAT5 5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "La Thành",
        "tuDien": "LT5",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "LT5",
        "diaChiLapD": "",
        "longitude": 105.82455565054254,
        "latitude": 21.021829451778515,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6024,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82427133638714,
          21.021972162122175
        ]
      },
      "properties": {
        "tenDoiTuon": "LAT5 6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "La Thành",
        "tuDien": "LT5",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "LT5",
        "diaChiLapD": "",
        "longitude": 105.82427133638714,
        "latitude": 21.021972162122175,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6025,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82403563732117,
          21.022087018815487
        ]
      },
      "properties": {
        "tenDoiTuon": "LAT5 7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "La Thành",
        "tuDien": "LT5",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "LT5",
        "diaChiLapD": "",
        "longitude": 105.82403563732117,
        "latitude": 21.022087018815487,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6026,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82379692072433,
          21.022194677322364
        ]
      },
      "properties": {
        "tenDoiTuon": "LAT5 8",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "La Thành",
        "tuDien": "LT5",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "LT5",
        "diaChiLapD": "",
        "longitude": 105.82379692072433,
        "latitude": 21.022194677322364,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6027,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82352869981538,
          21.022327372584176
        ]
      },
      "properties": {
        "tenDoiTuon": "LAT5 9",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "La Thành",
        "tuDien": "LT5",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "LT5",
        "diaChiLapD": "",
        "longitude": 105.82352869981538,
        "latitude": 21.022327372584176,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6028,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82326316112317,
          21.0224350309175
        ]
      },
      "properties": {
        "tenDoiTuon": "LAT5 10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "La Thành",
        "tuDien": "LT5",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "LT5",
        "diaChiLapD": "",
        "longitude": 105.82326316112317,
        "latitude": 21.0224350309175,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6029,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82303517335728,
          21.022525163415768
        ]
      },
      "properties": {
        "tenDoiTuon": "LAT5 11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "La Thành",
        "tuDien": "LT5",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "LT5",
        "diaChiLapD": "",
        "longitude": 105.82303517335728,
        "latitude": 21.022525163415768,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6030,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82274281257867,
          21.022642836317125
        ]
      },
      "properties": {
        "tenDoiTuon": "LAT5 12",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "La Thành",
        "tuDien": "LT5",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "LT5",
        "diaChiLapD": "",
        "longitude": 105.82274281257867,
        "latitude": 21.022642836317125,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6031,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82244240516783,
          21.02276802015345
        ]
      },
      "properties": {
        "tenDoiTuon": "LAT5 13",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "La Thành",
        "tuDien": "LT5",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "LT5",
        "diaChiLapD": "",
        "longitude": 105.82244240516783,
        "latitude": 21.02276802015345,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6032,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82218759531557,
          21.022853145101866
        ]
      },
      "properties": {
        "tenDoiTuon": "LAT5 14",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "La Thành",
        "tuDien": "LT5",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "LT5",
        "diaChiLapD": "",
        "longitude": 105.82218759531557,
        "latitude": 21.022853145101866,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6033,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82182281488298,
          21.022968314073243
        ]
      },
      "properties": {
        "tenDoiTuon": "LAT5 15",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "La Thành",
        "tuDien": "LT5",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "LT5",
        "diaChiLapD": "",
        "longitude": 105.82182281488298,
        "latitude": 21.022968314073243,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6034,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82152240747214,
          21.02305594257781
        ]
      },
      "properties": {
        "tenDoiTuon": "LAT5 16",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "La Thành",
        "tuDien": "LT5",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "LT5",
        "diaChiLapD": "",
        "longitude": 105.82152240747214,
        "latitude": 21.02305594257781,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6035,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82111739391446,
          21.023173615061065
        ]
      },
      "properties": {
        "tenDoiTuon": "LAT5 17",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "La Thành",
        "tuDien": "LT5",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "LT5",
        "diaChiLapD": "",
        "longitude": 105.82111739391446,
        "latitude": 21.023173615061065,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6036,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82074423153553,
          21.023244030728335
        ]
      },
      "properties": {
        "tenDoiTuon": "LAT5 18",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 1,
        "maXaPhuong": 34,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "La Thành",
        "tuDien": "LT5",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "LT5",
        "diaChiLapD": "",
        "longitude": 105.82074423153553,
        "latitude": 21.023244030728335,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6037,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82042504866152,
          21.02331413340628
        ]
      },
      "properties": {
        "tenDoiTuon": "LAT5 19",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 1,
        "maXaPhuong": 34,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "La Thành",
        "tuDien": "LT5",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "LT5",
        "diaChiLapD": "",
        "longitude": 105.82042504866152,
        "latitude": 21.02331413340628,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6038,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82014073450613,
          21.02340426537308
        ]
      },
      "properties": {
        "tenDoiTuon": "LAT5 20",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 1,
        "maXaPhuong": 34,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "La Thành",
        "tuDien": "LT5",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "LT5",
        "diaChiLapD": "",
        "longitude": 105.82014073450613,
        "latitude": 21.02340426537308,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6039,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8348010184025,
          21.021856366459655
        ]
      },
      "properties": {
        "tenDoiTuon": "8-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 190 Văn Chương",
        "tuDien": "VC4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VC4",
        "diaChiLapD": "",
        "longitude": 105.8348010184025,
        "latitude": 21.021856366459655,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6040,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83471786992654,
          21.021579708349055
        ]
      },
      "properties": {
        "tenDoiTuon": "8-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 190 Văn Chương",
        "tuDien": "VC4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VC4",
        "diaChiLapD": "",
        "longitude": 105.83471786992654,
        "latitude": 21.021579708349055,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6041,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83440673368473,
          21.021691122762842
        ]
      },
      "properties": {
        "tenDoiTuon": "8-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 190 Văn Chương",
        "tuDien": "VC4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VC4",
        "diaChiLapD": "",
        "longitude": 105.83440673368473,
        "latitude": 21.021691122762842,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6042,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83417472259828,
          21.021796277863974
        ]
      },
      "properties": {
        "tenDoiTuon": "VC4 8-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 190 Văn Chương",
        "tuDien": "VC4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VC4",
        "diaChiLapD": "",
        "longitude": 105.83417472259828,
        "latitude": 21.021796277863974,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6043,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83437186496612,
          21.021520871490438
        ]
      },
      "properties": {
        "tenDoiTuon": "8-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 190 Văn Chương",
        "tuDien": "VC4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VC4",
        "diaChiLapD": "",
        "longitude": 105.83437186496612,
        "latitude": 21.021520871490438,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6044,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83449256436842,
          21.02145953091046
        ]
      },
      "properties": {
        "tenDoiTuon": "VC4 8-7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 190 Văn Chương",
        "tuDien": "VC4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VC4",
        "diaChiLapD": "",
        "longitude": 105.83449256436842,
        "latitude": 21.02145953091046,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6045,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83533746021138,
          21.021841344312598
        ]
      },
      "properties": {
        "tenDoiTuon": "VC4 8-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 199 Văn Chương",
        "tuDien": "VC4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VC4",
        "diaChiLapD": "",
        "longitude": 105.83533746021138,
        "latitude": 21.021841344312598,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6046,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80298230823544,
          21.018788374558017
        ]
      },
      "properties": {
        "tenDoiTuon": "14-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 814 Đường Láng",
        "tuDien": "LTG1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Láng Trung 1",
        "diaChiLapD": "",
        "longitude": 105.80298230823544,
        "latitude": 21.018788374558017,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6047,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80312312420476,
          21.01853800009843
        ]
      },
      "properties": {
        "tenDoiTuon": "LT1 14-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 814 Đường Láng",
        "tuDien": "LTG1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Láng Trung 1",
        "diaChiLapD": "",
        "longitude": 105.80312312420476,
        "latitude": 21.01853800009843,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6048,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80319152053812,
          21.01887099803783
        ]
      },
      "properties": {
        "tenDoiTuon": "14-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 814 Đường Láng",
        "tuDien": "LTG1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Láng Trung 1",
        "diaChiLapD": "",
        "longitude": 105.80319152053812,
        "latitude": 21.01887099803783,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6049,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80334574755517,
          21.018917317241026
        ]
      },
      "properties": {
        "tenDoiTuon": "LT1 14-7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 814 Đường Láng",
        "tuDien": "LTG1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Láng Trung 1",
        "diaChiLapD": "",
        "longitude": 105.80334574755517,
        "latitude": 21.018917317241026,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6050,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80327601011793,
          21.01868321733637
        ]
      },
      "properties": {
        "tenDoiTuon": "14-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 814 Đường Láng",
        "tuDien": "LTG1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "Láng Trung 1",
        "diaChiLapD": "",
        "longitude": 105.80327601011793,
        "latitude": 21.01868321733637,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6051,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80335781749898,
          21.01855177070482
        ]
      },
      "properties": {
        "tenDoiTuon": "LT1 14-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 814 Đường Láng",
        "tuDien": "LTG1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Láng Trung 1",
        "diaChiLapD": "",
        "longitude": 105.80335781749898,
        "latitude": 21.01855177070482,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6052,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8381965510119,
          21.016929260750917
        ]
      },
      "properties": {
        "tenDoiTuon": "CN10 5-13",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Nam Lai",
        "tuDien": "CN10",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "CN10",
        "diaChiLapD": "",
        "longitude": 105.8381965510119,
        "latitude": 21.016929260750917,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6053,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80309764322223,
          21.019106349516502
        ]
      },
      "properties": {
        "tenDoiTuon": "LT1 14-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 814 Đường Láng",
        "tuDien": "LTG1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "Láng Trung 1",
        "diaChiLapD": "",
        "longitude": 105.80309764322223,
        "latitude": 21.019106349516502,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6054,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80341548499239,
          21.01876333713106
        ]
      },
      "properties": {
        "tenDoiTuon": "14-8",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 814 Đường Láng",
        "tuDien": "LTG1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Láng Trung 1",
        "diaChiLapD": "",
        "longitude": 105.80341548499239,
        "latitude": 21.01876333713106,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6055,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80358580526485,
          21.018543007591873
        ]
      },
      "properties": {
        "tenDoiTuon": "LT1 14-9",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 814 Đường Láng",
        "tuDien": "LTG1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Láng Trung 1",
        "diaChiLapD": "",
        "longitude": 105.80358580526485,
        "latitude": 21.018543007591873,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6056,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83513629453192,
          21.022527354132393
        ]
      },
      "properties": {
        "tenDoiTuon": "6-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 191 Văn Chương",
        "tuDien": "VC4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VC4",
        "diaChiLapD": "",
        "longitude": 105.83513629453192,
        "latitude": 21.022527354132393,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6057,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8351322712203,
          21.022730152051516
        ]
      },
      "properties": {
        "tenDoiTuon": "6-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 191 Văn Chương",
        "tuDien": "VC4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VC4",
        "diaChiLapD": "",
        "longitude": 105.8351322712203,
        "latitude": 21.022730152051516,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6058,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83511886017261,
          21.022934201531445
        ]
      },
      "properties": {
        "tenDoiTuon": "6-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 191 Văn Chương",
        "tuDien": "VC4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VC4",
        "diaChiLapD": "",
        "longitude": 105.83511886017261,
        "latitude": 21.022934201531445,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6059,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8349646331556,
          21.022942964386328
        ]
      },
      "properties": {
        "tenDoiTuon": "6-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 191 Văn Chương",
        "tuDien": "VC4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VC4",
        "diaChiLapD": "",
        "longitude": 105.8349646331556,
        "latitude": 21.022942964386328,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6060,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83480638282694,
          21.022945468058676
        ]
      },
      "properties": {
        "tenDoiTuon": "6-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 191 Văn Chương",
        "tuDien": "VC4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VC4",
        "diaChiLapD": "",
        "longitude": 105.83480638282694,
        "latitude": 21.022945468058676,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6061,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83544608967884,
          21.022442228997956
        ]
      },
      "properties": {
        "tenDoiTuon": "5-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 29 Văn Chương",
        "tuDien": "VC4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "VC4",
        "diaChiLapD": "",
        "longitude": 105.83544608967884,
        "latitude": 21.022442228997956,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6062,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8025169449766,
          21.019682207951604
        ]
      },
      "properties": {
        "tenDoiTuon": "LT1 18-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 850 Đường Láng",
        "tuDien": "LTG1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Láng Trung 1",
        "diaChiLapD": "",
        "longitude": 105.8025169449766,
        "latitude": 21.019682207951604,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6063,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80272213396768,
          21.01977109025165
        ]
      },
      "properties": {
        "tenDoiTuon": "18-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 850 Đường Láng",
        "tuDien": "LTG1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Láng Trung 1",
        "diaChiLapD": "",
        "longitude": 105.80272213396768,
        "latitude": 21.01977109025165,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6064,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80267251309752,
          21.019413057004467
        ]
      },
      "properties": {
        "tenDoiTuon": "18-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 850 Đường Láng",
        "tuDien": "LTG1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Láng Trung 1",
        "diaChiLapD": "",
        "longitude": 105.80267251309752,
        "latitude": 21.019413057004467,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6065,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80282942232229,
          21.0191689429337
        ]
      },
      "properties": {
        "tenDoiTuon": "LT1 18-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 850 Đường Láng",
        "tuDien": "LTG1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Láng Trung 1",
        "diaChiLapD": "",
        "longitude": 105.80282942232229,
        "latitude": 21.0191689429337,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6066,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83589670080407,
          21.02255739828594
        ]
      },
      "properties": {
        "tenDoiTuon": "11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 33 Văn Chương 2",
        "tuDien": "VC4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VC4",
        "diaChiLapD": "",
        "longitude": 105.83589670080407,
        "latitude": 21.02255739828594,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6067,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83588060754863,
          21.022799003133294
        ]
      },
      "properties": {
        "tenDoiTuon": "12",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 33 Văn Chương 2",
        "tuDien": "VC4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VC4",
        "diaChiLapD": "",
        "longitude": 105.83588060754863,
        "latitude": 21.022799003133294,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6068,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83584842103777,
          21.022962993765734
        ]
      },
      "properties": {
        "tenDoiTuon": "13",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 33 Văn Chương 2",
        "tuDien": "VC4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VC4",
        "diaChiLapD": "",
        "longitude": 105.83584842103777,
        "latitude": 21.022962993765734,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6069,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8359047474273,
          21.023372343641125
        ]
      },
      "properties": {
        "tenDoiTuon": "14",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 33 Văn Chương 2",
        "tuDien": "VC4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VC4",
        "diaChiLapD": "",
        "longitude": 105.8359047474273,
        "latitude": 21.023372343641125,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6070,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8359704615529,
          21.023625213644447
        ]
      },
      "properties": {
        "tenDoiTuon": "15",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 33 Văn Chương 2",
        "tuDien": "VC4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VC4",
        "diaChiLapD": "",
        "longitude": 105.8359704615529,
        "latitude": 21.023625213644447,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6071,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83613407629704,
          21.023910630756337
        ]
      },
      "properties": {
        "tenDoiTuon": "16",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 33 Văn Chương 2",
        "tuDien": "VC4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VC4",
        "diaChiLapD": "",
        "longitude": 105.83613407629704,
        "latitude": 21.023910630756337,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6072,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.835814893441,
          21.02283530641894
        ]
      },
      "properties": {
        "tenDoiTuon": "12-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Hẻm 33/3 Văn Chương",
        "tuDien": "VC4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VC4",
        "diaChiLapD": "",
        "longitude": 105.835814893441,
        "latitude": 21.02283530641894,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6073,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82119484266974,
          21.023218994050424
        ]
      },
      "properties": {
        "tenDoiTuon": "LAT5 17-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 446 La Thành",
        "tuDien": "LAT5",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "LAT5",
        "diaChiLapD": "",
        "longitude": 105.82119484266974,
        "latitude": 21.023218994050424,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6074,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82129140219337,
          21.023359199397767
        ]
      },
      "properties": {
        "tenDoiTuon": "LAT5 17-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 446 La Thành",
        "tuDien": "LAT5",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "LAT5",
        "diaChiLapD": "",
        "longitude": 105.82129140219337,
        "latitude": 21.023359199397767,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6075,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82136650404608,
          21.02346184965867
        ]
      },
      "properties": {
        "tenDoiTuon": "LAT5 17-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 446 La Thành",
        "tuDien": "LAT5",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "LAT5",
        "diaChiLapD": "",
        "longitude": 105.82136650404608,
        "latitude": 21.02346184965867,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6076,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.821449652531,
          21.02351943391955
        ]
      },
      "properties": {
        "tenDoiTuon": "LAT5 17-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 446 La Thành",
        "tuDien": "LAT5",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "LAT5",
        "diaChiLapD": "",
        "longitude": 105.821449652531,
        "latitude": 21.02351943391955,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6077,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82152475438372,
          21.02366965362704
        ]
      },
      "properties": {
        "tenDoiTuon": "LAT5 17-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 446 La Thành",
        "tuDien": "LAT5",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "LAT5",
        "diaChiLapD": "",
        "longitude": 105.82152475438372,
        "latitude": 21.02366965362704,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6078,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82099904141475,
          21.023076284900288
        ]
      },
      "properties": {
        "tenDoiTuon": "LAT5 21",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 371",
        "tuDien": "LAT5",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "LAT5",
        "diaChiLapD": "",
        "longitude": 105.82099904141475,
        "latitude": 21.023076284900288,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6079,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82079955213536,
          21.022888196535607
        ]
      },
      "properties": {
        "tenDoiTuon": "LAT5 22",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 1,
        "maXaPhuong": 34,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 371",
        "tuDien": "LAT5",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "LAT5",
        "diaChiLapD": "",
        "longitude": 105.82079955213536,
        "latitude": 21.022888196535607,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6080,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82067885273304,
          21.02272295398327
        ]
      },
      "properties": {
        "tenDoiTuon": "LAT5 23",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 1,
        "maXaPhuong": 34,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 371",
        "tuDien": "LAT5",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "LAT5",
        "diaChiLapD": "",
        "longitude": 105.82067885273304,
        "latitude": 21.02272295398327,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6081,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82083810886938,
          21.022402796015818
        ]
      },
      "properties": {
        "tenDoiTuon": "LAT5 24-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 371",
        "tuDien": "LAT5",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "LAT5",
        "diaChiLapD": "",
        "longitude": 105.82083810886938,
        "latitude": 21.022402796015818,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6082,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82116265615888,
          21.022297641343318
        ]
      },
      "properties": {
        "tenDoiTuon": "LAT5 24-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 371",
        "tuDien": "LAT5",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "LAT5",
        "diaChiLapD": "",
        "longitude": 105.82116265615888,
        "latitude": 21.022297641343318,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6083,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.820419684264,
          21.022189982910803
        ]
      },
      "properties": {
        "tenDoiTuon": "LAT5 25",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 371",
        "tuDien": "LAT5",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "LAT5",
        "diaChiLapD": "",
        "longitude": 105.820419684264,
        "latitude": 21.022189982910803,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6084,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82033653578807,
          21.021939614162335
        ]
      },
      "properties": {
        "tenDoiTuon": "LAT5 26",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 371",
        "tuDien": "LAT5",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "LAT5",
        "diaChiLapD": "",
        "longitude": 105.82033653578807,
        "latitude": 21.021939614162335,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6085,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82024802288767,
          21.021719289315843
        ]
      },
      "properties": {
        "tenDoiTuon": "LAT5 27",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 371",
        "tuDien": "LAT5",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "LAT5",
        "diaChiLapD": "",
        "longitude": 105.82024802288767,
        "latitude": 21.021719289315843,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6086,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82008709035128,
          21.021686741299934
        ]
      },
      "properties": {
        "tenDoiTuon": "LAT5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 1,
        "maXaPhuong": 34,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 371",
        "tuDien": "LAT5",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "LAT5",
        "diaChiLapD": "",
        "longitude": 105.82008709035128,
        "latitude": 21.021686741299934,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6087,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82031507811715,
          21.0214413791025
        ]
      },
      "properties": {
        "tenDoiTuon": "LAT5 28-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 371",
        "tuDien": "LAT5",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "LAT5",
        "diaChiLapD": "",
        "longitude": 105.82031507811715,
        "latitude": 21.0214413791025,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6088,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8201836498749,
          21.02152650480837
        ]
      },
      "properties": {
        "tenDoiTuon": "LAT5 28",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 371",
        "tuDien": "LAT5",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "LAT5",
        "diaChiLapD": "",
        "longitude": 105.8201836498749,
        "latitude": 21.02152650480837,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6089,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82055379471397,
          21.022520469014612
        ]
      },
      "properties": {
        "tenDoiTuon": "LAT5 24",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 1,
        "maXaPhuong": 34,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 371",
        "tuDien": "LAT5",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "LAT5",
        "diaChiLapD": "",
        "longitude": 105.82055379471397,
        "latitude": 21.022520469014612,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6090,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82004417500045,
          21.021516490022517
        ]
      },
      "properties": {
        "tenDoiTuon": "LAT5 28-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 1,
        "maXaPhuong": 34,
        "loaiChaoCh": "Đường phố - đèn pha",
        "tenDuongPh": "Ngõ 371",
        "tuDien": "LAT5",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "LAT5",
        "diaChiLapD": "",
        "longitude": 105.82004417500045,
        "latitude": 21.021516490022517,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6091,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82087834200348,
          21.021446386498457
        ]
      },
      "properties": {
        "tenDoiTuon": "LAT5 28-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - đèn pha",
        "tenDuongPh": "Ngõ 371",
        "tuDien": "LAT5",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "LAT5",
        "diaChiLapD": "",
        "longitude": 105.82087834200348,
        "latitude": 21.021446386498457,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6092,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82071472725933,
          21.02143637170637
        ]
      },
      "properties": {
        "tenDoiTuon": "LAT5 28-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - đèn pha",
        "tenDuongPh": "Ngõ 371",
        "tuDien": "LAT5",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "LAT5",
        "diaChiLapD": "",
        "longitude": 105.82071472725933,
        "latitude": 21.02143637170637,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6093,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82051892599536,
          21.02143637170637
        ]
      },
      "properties": {
        "tenDoiTuon": "LAT5 28-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 371",
        "tuDien": "LAT5",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "LAT5",
        "diaChiLapD": "",
        "longitude": 105.82051892599536,
        "latitude": 21.02143637170637,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6094,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80285523859199,
          21.01953667872089
        ]
      },
      "properties": {
        "tenDoiTuon": "LT1 18-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 850 Đường Láng",
        "tuDien": "LTG1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Láng Trung 1",
        "diaChiLapD": "",
        "longitude": 105.80285523859199,
        "latitude": 21.01953667872089,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6095,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80293302265247,
          21.019878437465835
        ]
      },
      "properties": {
        "tenDoiTuon": "18-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 850 Đường Láng",
        "tuDien": "LTG1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Láng Trung 1",
        "diaChiLapD": "",
        "longitude": 105.80293302265247,
        "latitude": 21.019878437465835,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6096,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8030416521199,
          21.019625561107663
        ]
      },
      "properties": {
        "tenDoiTuon": "18-7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 850 Đường Láng",
        "tuDien": "LTG1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Láng Trung 1",
        "diaChiLapD": "",
        "longitude": 105.8030416521199,
        "latitude": 21.019625561107663,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6097,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8031878325137,
          21.019370180587423
        ]
      },
      "properties": {
        "tenDoiTuon": "18-8",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 850 Đường Láng",
        "tuDien": "LTG1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Láng Trung 1",
        "diaChiLapD": "",
        "longitude": 105.8031878325137,
        "latitude": 21.019370180587423,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6098,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80322806564782,
          21.019167378098125
        ]
      },
      "properties": {
        "tenDoiTuon": "18-9",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 850 Đường Láng",
        "tuDien": "LTG1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Láng Trung 1",
        "diaChiLapD": "",
        "longitude": 105.80322806564782,
        "latitude": 21.019167378098125,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6099,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83560702224217,
          21.022989282323696
        ]
      },
      "properties": {
        "tenDoiTuon": "13-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Hem 33/9",
        "tuDien": "VC4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VC4",
        "diaChiLapD": "",
        "longitude": 105.83560702224217,
        "latitude": 21.022989282323696,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6100,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8357210161251,
          21.02297676396346
        ]
      },
      "properties": {
        "tenDoiTuon": "13-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Hem 33/9",
        "tuDien": "VC4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VC4",
        "diaChiLapD": "",
        "longitude": 105.8357210161251,
        "latitude": 21.02297676396346,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6101,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83575320262699,
          21.023094436509208
        ]
      },
      "properties": {
        "tenDoiTuon": "13-7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Hem 33/9",
        "tuDien": "VC4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VC4",
        "diaChiLapD": "",
        "longitude": 105.83575320262699,
        "latitude": 21.023094436509208,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6102,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80306009231384,
          21.019946976899732
        ]
      },
      "properties": {
        "tenDoiTuon": "18-17",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 850 Đường Láng",
        "tuDien": "LTG1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Láng Trung 1",
        "diaChiLapD": "",
        "longitude": 105.80306009231384,
        "latitude": 21.019946976899732,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6103,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82075227818571,
          21.022072309651172
        ]
      },
      "properties": {
        "tenDoiTuon": "LAT5 25-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 371",
        "tuDien": "LAT5",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "LAT5",
        "diaChiLapD": "",
        "longitude": 105.82075227818571,
        "latitude": 21.022072309651172,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6104,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82048405727674,
          21.021861999764955
        ]
      },
      "properties": {
        "tenDoiTuon": "LAT5 26-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 371",
        "tuDien": "LAT5",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "LAT5",
        "diaChiLapD": "",
        "longitude": 105.82048405727674,
        "latitude": 21.021861999764955,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6105,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82065571865309,
          21.021801911171526
        ]
      },
      "properties": {
        "tenDoiTuon": "LAT5 26-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 371",
        "tuDien": "LAT5",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "LAT5",
        "diaChiLapD": "",
        "longitude": 105.82065571865309,
        "latitude": 21.021801911171526,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6106,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82085956654029,
          21.02174682993977
        ]
      },
      "properties": {
        "tenDoiTuon": "LAT5 26-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 371",
        "tuDien": "LAT5",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "LAT5",
        "diaChiLapD": "",
        "longitude": 105.82085956654029,
        "latitude": 21.02174682993977,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6107,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82060207448029,
          21.02161663785591
        ]
      },
      "properties": {
        "tenDoiTuon": "LAT5 27-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 371",
        "tuDien": "LAT5",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "LAT5",
        "diaChiLapD": "",
        "longitude": 105.82060207448029,
        "latitude": 21.02161663785591,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6108,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82041431984852,
          21.021471423474882
        ]
      },
      "properties": {
        "tenDoiTuon": "LAT5 29",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 371",
        "tuDien": "LAT5",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "LAT5",
        "diaChiLapD": "",
        "longitude": 105.82041431984852,
        "latitude": 21.021471423474882,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6109,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82060207448029,
          21.021471423474882
        ]
      },
      "properties": {
        "tenDoiTuon": "LAT5 30",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 371",
        "tuDien": "LAT5",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "LAT5",
        "diaChiLapD": "",
        "longitude": 105.82060207448029,
        "latitude": 21.021471423474882,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6110,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83616626281689,
          21.022988030487465
        ]
      },
      "properties": {
        "tenDoiTuon": "13-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Hẻm 33/20",
        "tuDien": "VC4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VC4",
        "diaChiLapD": "",
        "longitude": 105.83616626281689,
        "latitude": 21.022988030487465,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6111,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83604422231072,
          21.02306814796978
        ]
      },
      "properties": {
        "tenDoiTuon": "13-7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Hẻm 33/20",
        "tuDien": "VC4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "VC4",
        "diaChiLapD": "",
        "longitude": 105.83604422231072,
        "latitude": 21.02306814796978,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6112,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83636206408087,
          21.022902905616053
        ]
      },
      "properties": {
        "tenDoiTuon": "VC4 13-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Hẻm 33/20",
        "tuDien": "VC4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "VC4",
        "diaChiLapD": "",
        "longitude": 105.83636206408087,
        "latitude": 21.022902905616053,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6113,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83618503828005,
          21.022839061930636
        ]
      },
      "properties": {
        "tenDoiTuon": "13-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Hẻm 33/20",
        "tuDien": "VC4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "VC4",
        "diaChiLapD": "",
        "longitude": 105.83618503828005,
        "latitude": 21.022839061930636,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6114,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83612200637118,
          21.022621242092
        ]
      },
      "properties": {
        "tenDoiTuon": "13-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Hẻm 33/20",
        "tuDien": "VC4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "VC4",
        "diaChiLapD": "",
        "longitude": 105.83612200637118,
        "latitude": 21.022621242092,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6115,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82232137046039,
          21.023031218824965
        ]
      },
      "properties": {
        "tenDoiTuon": "LAT5 14-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 418 La Thành",
        "tuDien": "LAT5",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "LAT5",
        "diaChiLapD": "",
        "longitude": 105.82232137046039,
        "latitude": 21.023031218824965,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6116,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8220316918895,
          21.02314638765795
        ]
      },
      "properties": {
        "tenDoiTuon": "LAT5 14-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 418 La Thành",
        "tuDien": "LAT5",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "LAT5",
        "diaChiLapD": "",
        "longitude": 105.8220316918895,
        "latitude": 21.02314638765795,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6117,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82175274214957,
          21.023239023394428
        ]
      },
      "properties": {
        "tenDoiTuon": "LAT5 14-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 418 La Thành",
        "tuDien": "LAT5",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "LAT5",
        "diaChiLapD": "",
        "longitude": 105.82175274214957,
        "latitude": 21.023239023394428,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6118,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82152743659145,
          21.023339170071587
        ]
      },
      "properties": {
        "tenDoiTuon": "LAT5 14-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 418 La Thành",
        "tuDien": "LAT5",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "LAT5",
        "diaChiLapD": "",
        "longitude": 105.82152743659145,
        "latitude": 21.023339170071587,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6119,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82342375837234,
          21.022405299698104
        ]
      },
      "properties": {
        "tenDoiTuon": "LAT5 9-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 360 La Thành",
        "tuDien": "LAT5",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "LAT5",
        "diaChiLapD": "",
        "longitude": 105.82342375837234,
        "latitude": 21.022405299698104,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6120,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82352031789598,
          21.02268821546891
        ]
      },
      "properties": {
        "tenDoiTuon": "LAT5 9-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 360 La Thành",
        "tuDien": "LAT5",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "LAT5",
        "diaChiLapD": "",
        "longitude": 105.82352031789598,
        "latitude": 21.02268821546891,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6121,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82362760625057,
          21.022660675019704
        ]
      },
      "properties": {
        "tenDoiTuon": "LAT5 9-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 39 Hào Nam",
        "tuDien": "LAT5",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "LAT5",
        "diaChiLapD": "",
        "longitude": 105.82362760625057,
        "latitude": 21.022660675019704,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6122,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82385827622419,
          21.022583060997697
        ]
      },
      "properties": {
        "tenDoiTuon": "LAT5 9-7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 360 La Thành",
        "tuDien": "LAT5",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "LAT5",
        "diaChiLapD": "",
        "longitude": 105.82385827622419,
        "latitude": 21.022583060997697,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6123,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82334865651964,
          21.022738289001325
        ]
      },
      "properties": {
        "tenDoiTuon": "LAT5 9-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 360 La Thành",
        "tuDien": "LAT5",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "LAT5",
        "diaChiLapD": "",
        "longitude": 105.82334865651964,
        "latitude": 21.022738289001325,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6124,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82352031789598,
          21.022783355165163
        ]
      },
      "properties": {
        "tenDoiTuon": "LAT5 9-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 360 La Thành",
        "tuDien": "LAT5",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "LAT5",
        "diaChiLapD": "",
        "longitude": 105.82352031789598,
        "latitude": 21.022783355165163,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6125,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82325477920374,
          21.02287849480072
        ]
      },
      "properties": {
        "tenDoiTuon": "LAT5 9-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 360 La Thành",
        "tuDien": "LAT5",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "LAT5",
        "diaChiLapD": "",
        "longitude": 105.82325477920374,
        "latitude": 21.02287849480072,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6126,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82373221240641,
          21.021867007146763
        ]
      },
      "properties": {
        "tenDoiTuon": "LAT 5 8-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 241 La Thành",
        "tuDien": "LAT5",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "LAT5",
        "diaChiLapD": "",
        "longitude": 105.82373221240641,
        "latitude": 21.021867007146763,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6127,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82358737311647,
          21.021922088334954
        ]
      },
      "properties": {
        "tenDoiTuon": "LAT 5 8-1-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 241 La Thành",
        "tuDien": "LAT5",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "LAT5",
        "diaChiLapD": "",
        "longitude": 105.82358737311647,
        "latitude": 21.021922088334954,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6128,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82344521604327,
          21.02197716950197
        ]
      },
      "properties": {
        "tenDoiTuon": "LAT 5 8-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 241 La Thành",
        "tuDien": "LAT5",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "LAT5",
        "diaChiLapD": "",
        "longitude": 105.82344521604327,
        "latitude": 21.02197716950197,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6129,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82325209698703,
          21.022047272775648
        ]
      },
      "properties": {
        "tenDoiTuon": "LAT 5 8-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 241 La Thành",
        "tuDien": "LAT5",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "LAT5",
        "diaChiLapD": "",
        "longitude": 105.82325209698703,
        "latitude": 21.022047272775648,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6130,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82308580003516,
          21.022117376017185
        ]
      },
      "properties": {
        "tenDoiTuon": "LAT 5 8-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 241 La Thành",
        "tuDien": "LAT5",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "LAT5",
        "diaChiLapD": "",
        "longitude": 105.82308580003516,
        "latitude": 21.022117376017185,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6131,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82292754969755,
          21.022172457112045
        ]
      },
      "properties": {
        "tenDoiTuon": "LAT 5 8-4-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 241 La Thành",
        "tuDien": "LAT5",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "LAT5",
        "diaChiLapD": "",
        "longitude": 105.82292754969755,
        "latitude": 21.022172457112045,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6132,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82358737311647,
          21.02167923021846
        ]
      },
      "properties": {
        "tenDoiTuon": "LAT 5 8-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 241 La Thành",
        "tuDien": "LAT5",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "LAT5",
        "diaChiLapD": "",
        "longitude": 105.82358737311647,
        "latitude": 21.02167923021846,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6133,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82319040618201,
          21.021829451780192
        ]
      },
      "properties": {
        "tenDoiTuon": "LAT 5 8-7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 241 La Thành",
        "tuDien": "LAT5",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "LAT5",
        "diaChiLapD": "",
        "longitude": 105.82319040618201,
        "latitude": 21.021829451780192,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6134,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82295705400064,
          21.02191207357485
        ]
      },
      "properties": {
        "tenDoiTuon": "LAT 5 8-8",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 241 La Thành",
        "tuDien": "LAT5",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "LAT5",
        "diaChiLapD": "",
        "longitude": 105.82295705400064,
        "latitude": 21.02191207357485,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6135,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82282026134293,
          21.021974665812518
        ]
      },
      "properties": {
        "tenDoiTuon": "LAT 5 8-9",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 241 La Thành",
        "tuDien": "LAT5",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "LAT5",
        "diaChiLapD": "",
        "longitude": 105.82282026134293,
        "latitude": 21.021974665812518,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6136,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8231769951433,
          21.02225758240051
        ]
      },
      "properties": {
        "tenDoiTuon": "LAT 5 8-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 241 La Thành",
        "tuDien": "LAT5",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "LAT5",
        "diaChiLapD": "",
        "longitude": 105.8231769951433,
        "latitude": 21.02225758240051,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6137,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80316235153117,
          21.01973134359612
        ]
      },
      "properties": {
        "tenDoiTuon": "18-19",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 102 Pháo Đài Láng",
        "tuDien": "LTG1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Láng Trung 1",
        "diaChiLapD": "",
        "longitude": 105.80316235153117,
        "latitude": 21.01973134359612,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6138,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80328841535794,
          21.019533549058227
        ]
      },
      "properties": {
        "tenDoiTuon": "18-20",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 102 Pháo Đài Láng",
        "tuDien": "LTG1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Láng Trung 1",
        "diaChiLapD": "",
        "longitude": 105.80328841535794,
        "latitude": 21.019533549058227,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6139,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80342118470405,
          21.019343265457504
        ]
      },
      "properties": {
        "tenDoiTuon": "18-21",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 102 Pháo Đài Láng",
        "tuDien": "LTG1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Láng Trung 1",
        "diaChiLapD": "",
        "longitude": 105.80342118470405,
        "latitude": 21.019343265457504,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6140,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80356468288113,
          21.01912418864306
        ]
      },
      "properties": {
        "tenDoiTuon": "18-22",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 102 Pháo Đài Láng",
        "tuDien": "LTG1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Láng Trung 1",
        "diaChiLapD": "",
        "longitude": 105.80356468288113,
        "latitude": 21.01912418864306,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6141,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80360893933582,
          21.019016527919096
        ]
      },
      "properties": {
        "tenDoiTuon": "18-23",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 102/14 Pháo Đài Láng",
        "tuDien": "LTG1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "Láng Trung 1",
        "diaChiLapD": "",
        "longitude": 105.80360893933582,
        "latitude": 21.019016527919096,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6142,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80384765593266,
          21.019049076518723
        ]
      },
      "properties": {
        "tenDoiTuon": "18-24",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 102/14 Pháo Đài Láng",
        "tuDien": "LTG1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Láng Trung 1",
        "diaChiLapD": "",
        "longitude": 105.80384765593266,
        "latitude": 21.019049076518723,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6143,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82441349349627,
          21.022340203957896
        ]
      },
      "properties": {
        "tenDoiTuon": "LAT5 7-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 39 Hào Nam",
        "tuDien": "LAT5",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "LAT5",
        "diaChiLapD": "",
        "longitude": 105.82441349349627,
        "latitude": 21.022340203957896,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6144,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82444031558266,
          21.022390277607208
        ]
      },
      "properties": {
        "tenDoiTuon": "LAT5 7-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 39 Hào Nam",
        "tuDien": "LAT5",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "LAT5",
        "diaChiLapD": "",
        "longitude": 105.82444031558266,
        "latitude": 21.022390277607208,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6145,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82462807021443,
          21.0224278328325
        ]
      },
      "properties": {
        "tenDoiTuon": "LAT5 7-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 39 Hào Nam",
        "tuDien": "LAT5",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "LAT5",
        "diaChiLapD": "",
        "longitude": 105.82462807021443,
        "latitude": 21.0224278328325,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6146,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82470048985941,
          21.022457877006083
        ]
      },
      "properties": {
        "tenDoiTuon": "LAT5 7-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 39 Hào Nam",
        "tuDien": "LAT5",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "LAT5",
        "diaChiLapD": "",
        "longitude": 105.82470048985941,
        "latitude": 21.022457877006083,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6147,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82478632055206,
          21.022382766560323
        ]
      },
      "properties": {
        "tenDoiTuon": "LAT5 7-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 39 Hào Nam",
        "tuDien": "LAT5",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "LAT5",
        "diaChiLapD": "",
        "longitude": 105.82478632055206,
        "latitude": 21.022382766560323,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6148,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82505454145203,
          21.02226008608522
        ]
      },
      "properties": {
        "tenDoiTuon": "LAT5 7-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 39 Hào Nam",
        "tuDien": "LAT5",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "LAT5",
        "diaChiLapD": "",
        "longitude": 105.82505454145203,
        "latitude": 21.02226008608522,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6149,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8252744825857,
          21.022189982910803
        ]
      },
      "properties": {
        "tenDoiTuon": "LAT5 7-8",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 39 Hào Nam",
        "tuDien": "LAT5",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "LAT5",
        "diaChiLapD": "",
        "longitude": 105.8252744825857,
        "latitude": 21.022189982910803,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6150,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8252074273652,
          21.02202223589592
        ]
      },
      "properties": {
        "tenDoiTuon": "LAT5 7-9",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 39 Hào Nam",
        "tuDien": "LAT5",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "LAT5",
        "diaChiLapD": "",
        "longitude": 105.8252074273652,
        "latitude": 21.02202223589592,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6151,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82499016843029,
          21.02203225064866
        ]
      },
      "properties": {
        "tenDoiTuon": "LAT5 7-7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 39 Hào Nam",
        "tuDien": "LAT5",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "LAT5",
        "diaChiLapD": "",
        "longitude": 105.82499016843029,
        "latitude": 21.02203225064866,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6152,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82545955500973,
          21.022177464483445
        ]
      },
      "properties": {
        "tenDoiTuon": "LAT5 7-11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 39 Hào Nam",
        "tuDien": "LAT5",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "LAT5",
        "diaChiLapD": "",
        "longitude": 105.82545955500973,
        "latitude": 21.022177464483445,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6153,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8254649194252,
          21.022037258024767
        ]
      },
      "properties": {
        "tenDoiTuon": "LAT5 7-12",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 39 Hào Nam",
        "tuDien": "LAT5",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "LAT5",
        "diaChiLapD": "",
        "longitude": 105.8254649194252,
        "latitude": 21.022037258024767,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6154,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82573850474964,
          21.02195213261049
        ]
      },
      "properties": {
        "tenDoiTuon": "LAT5 7-13",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 39 Hào Nam",
        "tuDien": "LAT5",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "LAT5",
        "diaChiLapD": "",
        "longitude": 105.82573850474964,
        "latitude": 21.02195213261049,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6155,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82579751334691,
          21.022107361270162
        ]
      },
      "properties": {
        "tenDoiTuon": "LAT5 7-14",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 39 Hào Nam",
        "tuDien": "LAT5",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "LAT5",
        "diaChiLapD": "",
        "longitude": 105.82579751334691,
        "latitude": 21.022107361270162,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6156,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82604427657596,
          21.022039761712342
        ]
      },
      "properties": {
        "tenDoiTuon": "LAT5 7-15",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 39 Hào Nam",
        "tuDien": "LAT5",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "LAT5",
        "diaChiLapD": "",
        "longitude": 105.82604427657596,
        "latitude": 21.022039761712342,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6157,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8252342494516,
          21.02175684471101
        ]
      },
      "properties": {
        "tenDoiTuon": "LAT 5 2-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 181 La Thành",
        "tuDien": "LAT5",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "LAT5",
        "diaChiLapD": "",
        "longitude": 105.8252342494516,
        "latitude": 21.02175684471101,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6158,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82498614511869,
          21.021427608763812
        ]
      },
      "properties": {
        "tenDoiTuon": "LAT5 2-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 256 La Thành",
        "tuDien": "LAT5",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "LAT5",
        "diaChiLapD": "",
        "longitude": 105.82498614511869,
        "latitude": 21.021427608763812,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6159,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80406357376367,
          21.019157989086747
        ]
      },
      "properties": {
        "tenDoiTuon": "18-25",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 102/14 Pháo Đài Láng",
        "tuDien": "LTG1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Láng Trung 1",
        "diaChiLapD": "",
        "longitude": 105.80406357376367,
        "latitude": 21.019157989086747,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6160,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80378596512763,
          21.019281923981666
        ]
      },
      "properties": {
        "tenDoiTuon": "18-26",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 102/20 Pháo Đài Láng",
        "tuDien": "LTG1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Láng Trung 1",
        "diaChiLapD": "",
        "longitude": 105.80378596512763,
        "latitude": 21.019281923981666,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6161,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8040099295819,
          21.019400851309936
        ]
      },
      "properties": {
        "tenDoiTuon": "18-27",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 102/20 Pháo Đài Láng",
        "tuDien": "LTG1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Láng Trung 1",
        "diaChiLapD": "",
        "longitude": 105.8040099295819,
        "latitude": 21.019400851309936,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6162,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80430363146439,
          21.019511015486444
        ]
      },
      "properties": {
        "tenDoiTuon": "18-28",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 102/20 Pháo Đài Láng",
        "tuDien": "LTG1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Láng Trung 1",
        "diaChiLapD": "",
        "longitude": 105.80430363146439,
        "latitude": 21.019511015486444,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6163,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80449675052063,
          21.019631194495545
        ]
      },
      "properties": {
        "tenDoiTuon": "18-29",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 102/20 Pháo Đài Láng",
        "tuDien": "LTG1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "Láng Trung 1",
        "diaChiLapD": "",
        "longitude": 105.80449675052063,
        "latitude": 21.019631194495545,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6164,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80470596282333,
          21.01975387713461
        ]
      },
      "properties": {
        "tenDoiTuon": "18-30",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 102/20 Pháo Đài Láng",
        "tuDien": "LTG1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Láng Trung 1",
        "diaChiLapD": "",
        "longitude": 105.80470596282333,
        "latitude": 21.01975387713461,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6165,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80496345488332,
          21.01988407084416
        ]
      },
      "properties": {
        "tenDoiTuon": "18-31",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 102/20 Pháo Đài Láng",
        "tuDien": "LTG1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Láng Trung 1",
        "diaChiLapD": "",
        "longitude": 105.80496345488332,
        "latitude": 21.01988407084416,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6166,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80506806103917,
          21.01970004700925
        ]
      },
      "properties": {
        "tenDoiTuon": "18-32",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 102/20 Pháo Đài Láng",
        "tuDien": "LTG1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Láng Trung 1",
        "diaChiLapD": "",
        "longitude": 105.80506806103917,
        "latitude": 21.01970004700925,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6167,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8051686438744,
          21.019517274812586
        ]
      },
      "properties": {
        "tenDoiTuon": "18-33",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 102/20 Pháo Đài Láng",
        "tuDien": "LTG1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Láng Trung 1",
        "diaChiLapD": "",
        "longitude": 105.8051686438744,
        "latitude": 21.019517274812586,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6168,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80359016387266,
          21.019720076826847
        ]
      },
      "properties": {
        "tenDoiTuon": "18-34",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 102/44 Pháo Đài Láng",
        "tuDien": "LTG1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Láng Trung 1",
        "diaChiLapD": "",
        "longitude": 105.80359016387266,
        "latitude": 21.019720076826847,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6169,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8035029920761,
          21.01986779663849
        ]
      },
      "properties": {
        "tenDoiTuon": "18-35",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 102/44 Pháo Đài Láng",
        "tuDien": "LTG1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 7,
        "tuDien2": "Láng Trung 1",
        "diaChiLapD": "",
        "longitude": 105.8035029920761,
        "latitude": 21.01986779663849,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6170,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80378060071216,
          21.01981647028001
        ]
      },
      "properties": {
        "tenDoiTuon": "18-36",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 102/44 Pháo Đài Láng",
        "tuDien": "LTG1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Láng Trung 1",
        "diaChiLapD": "",
        "longitude": 105.80378060071216,
        "latitude": 21.01981647028001,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6171,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80362905590287,
          21.01999673838807
        ]
      },
      "properties": {
        "tenDoiTuon": "18-37",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 102/44 Pháo Đài Láng",
        "tuDien": "LTG1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 7,
        "tuDien2": "Láng Trung 1",
        "diaChiLapD": "",
        "longitude": 105.80362905590287,
        "latitude": 21.01999673838807,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6172,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8039992007599,
          21.01982961483654
        ]
      },
      "properties": {
        "tenDoiTuon": "18-38",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 102/44 Pháo Đài Láng",
        "tuDien": "LTG1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 7,
        "tuDien2": "Láng Trung 1",
        "diaChiLapD": "",
        "longitude": 105.8039992007599,
        "latitude": 21.01982961483654,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6173,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80389459460407,
          21.02005369805727
        ]
      },
      "properties": {
        "tenDoiTuon": "18-39",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 102/44 Pháo Đài Láng",
        "tuDien": "LTG1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 7,
        "tuDien2": "Láng Trung 1",
        "diaChiLapD": "",
        "longitude": 105.80389459460407,
        "latitude": 21.02005369805727,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6174,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80410246580288,
          21.019986097567482
        ]
      },
      "properties": {
        "tenDoiTuon": "18-40",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 102/44 Pháo Đài Láng",
        "tuDien": "LTG1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Láng Trung 1",
        "diaChiLapD": "",
        "longitude": 105.80410246580288,
        "latitude": 21.019986097567482,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6175,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80398478388656,
          21.020233026983387
        ]
      },
      "properties": {
        "tenDoiTuon": "18-41",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 102/44 Pháo Đài Láng",
        "tuDien": "LTG1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Láng Trung 1",
        "diaChiLapD": "",
        "longitude": 105.80398478388656,
        "latitude": 21.020233026983387,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6176,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8041470575358,
          21.02030939037003
        ]
      },
      "properties": {
        "tenDoiTuon": "LT1 18-42",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 102/44 Pháo Đài Láng",
        "tuDien": "LTG1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 7,
        "tuDien2": "Láng Trung 1",
        "diaChiLapD": "",
        "longitude": 105.8041470575358,
        "latitude": 21.02030939037003,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6177,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80434822320628,
          21.02006527776674
        ]
      },
      "properties": {
        "tenDoiTuon": "18-43",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 102/44 Pháo Đài Láng",
        "tuDien": "LTG1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Láng Trung 1",
        "diaChiLapD": "",
        "longitude": 105.80434822320628,
        "latitude": 21.02006527776674,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6178,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80454536558312,
          21.02017919703269
        ]
      },
      "properties": {
        "tenDoiTuon": "18-44",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 102/44 Pháo Đài Láng",
        "tuDien": "LTG1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Láng Trung 1",
        "diaChiLapD": "",
        "longitude": 105.80454536558312,
        "latitude": 21.02017919703269,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6179,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80465265394669,
          21.020215500955466
        ]
      },
      "properties": {
        "tenDoiTuon": "18-49",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 102/44 Pháo Đài Láng",
        "tuDien": "LTG1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 7,
        "tuDien2": "Láng Trung 1",
        "diaChiLapD": "",
        "longitude": 105.80465265394669,
        "latitude": 21.020215500955466,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6180,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80474116683811,
          21.020057766604413
        ]
      },
      "properties": {
        "tenDoiTuon": "LT1 18-50",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 102/44 Pháo Đài Láng",
        "tuDien": "LTG1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 7,
        "tuDien2": "Láng Trung 1",
        "diaChiLapD": "",
        "longitude": 105.80474116683811,
        "latitude": 21.020057766604413,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6181,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80447831035362,
          21.0203231608137
        ]
      },
      "properties": {
        "tenDoiTuon": "18-45",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 102/44 Pháo Đài Láng",
        "tuDien": "LTG1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "2",
        "diaChiLapD": "",
        "longitude": 105.80447831035362,
        "latitude": 21.0203231608137,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6182,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80429927289255,
          21.020608584247974
        ]
      },
      "properties": {
        "tenDoiTuon": "18-46",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 102/44 Pháo Đài Láng",
        "tuDien": "LTG1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Láng Trung 1",
        "diaChiLapD": "",
        "longitude": 105.80429927289255,
        "latitude": 21.020608584247974,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6183,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80451384961071,
          21.020761310598324
        ]
      },
      "properties": {
        "tenDoiTuon": "18-47",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 102/44 Pháo Đài Láng",
        "tuDien": "LTG1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 7,
        "tuDien2": "Láng Trung 1",
        "diaChiLapD": "",
        "longitude": 105.80451384961071,
        "latitude": 21.020761310598324,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6184,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80438376247233,
          21.020992903534424
        ]
      },
      "properties": {
        "tenDoiTuon": "LT1 18-48",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 102/44 Pháo Đài Láng",
        "tuDien": "LTG1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 7,
        "tuDien2": "Láng Trung 1",
        "diaChiLapD": "",
        "longitude": 105.80438376247233,
        "latitude": 21.020992903534424,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6185,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82155409102528,
          21.02294343382537
        ]
      },
      "properties": {
        "tenDoiTuon": "HC1/01",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Nguyễn Phúc Lai",
        "tuDien": "HC1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 10,
        "tuDien2": "HC1",
        "diaChiLapD": "",
        "longitude": 105.82155409102528,
        "latitude": 21.02294343382537,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6186,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82195642237522,
          21.02278570235952
        ]
      },
      "properties": {
        "tenDoiTuon": "HC1/02",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Nguyễn Phúc Lai",
        "tuDien": "HC1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 10,
        "tuDien2": "HC1",
        "diaChiLapD": "",
        "longitude": 105.82195642237522,
        "latitude": 21.02278570235952,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6187,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82208080981988,
          21.022473681489384
        ]
      },
      "properties": {
        "tenDoiTuon": "HC1/03",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Nguyễn Phúc Lai",
        "tuDien": "HC1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 10,
        "tuDien2": "HC1",
        "diaChiLapD": "",
        "longitude": 105.82208080981988,
        "latitude": 21.022473681489384,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6188,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82219078038223,
          21.02218826162645
        ]
      },
      "properties": {
        "tenDoiTuon": "HC1/04",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Nguyễn Phúc Lai",
        "tuDien": "HC1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 10,
        "tuDien2": "HC1",
        "diaChiLapD": "",
        "longitude": 105.82219078038223,
        "latitude": 21.02218826162645,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6189,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82235573623922,
          21.021747612345287
        ]
      },
      "properties": {
        "tenDoiTuon": "HC1/06",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Nguyễn Phúc Lai",
        "tuDien": "HC1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 10,
        "tuDien2": "HC1",
        "diaChiLapD": "",
        "longitude": 105.82235573623922,
        "latitude": 21.021747612345287,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6190,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82225951200277,
          21.021555140836735
        ]
      },
      "properties": {
        "tenDoiTuon": "HC1/07",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Nguyễn Phúc Lai",
        "tuDien": "HC1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 10,
        "tuDien2": "HC1",
        "diaChiLapD": "",
        "longitude": 105.82225951200277,
        "latitude": 21.021555140836735,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6191,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82209992056129,
          21.02129600807188
        ]
      },
      "properties": {
        "tenDoiTuon": "HC1/08",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Nguyễn Phúc Lai",
        "tuDien": "HC1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 10,
        "tuDien2": "HC1",
        "diaChiLapD": "",
        "longitude": 105.82209992056129,
        "latitude": 21.02129600807188,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6192,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82191753035397,
          21.020988052604952
        ]
      },
      "properties": {
        "tenDoiTuon": "HC1/09",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Nguyễn Phúc Lai",
        "tuDien": "HC1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 10,
        "tuDien2": "HC1",
        "diaChiLapD": "",
        "longitude": 105.82191753035397,
        "latitude": 21.020988052604952,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6193,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82183438186907,
          21.02075270409636
        ]
      },
      "properties": {
        "tenDoiTuon": "HC1/10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Nguyễn Phúc Lai",
        "tuDien": "HC1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 10,
        "tuDien2": "HC1",
        "diaChiLapD": "",
        "longitude": 105.82183438186907,
        "latitude": 21.02075270409636,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6194,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82171904689125,
          21.020494821794017
        ]
      },
      "properties": {
        "tenDoiTuon": "HC1/11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Nguyễn Phúc Lai",
        "tuDien": "HC1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 10,
        "tuDien2": "HC1",
        "diaChiLapD": "",
        "longitude": 105.82171904689125,
        "latitude": 21.020494821794017,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6195,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82157957201679,
          21.020189368390966
        ]
      },
      "properties": {
        "tenDoiTuon": "HC1/12",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Nguyễn Phúc Lai",
        "tuDien": "HC1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 10,
        "tuDien2": "HC1",
        "diaChiLapD": "",
        "longitude": 105.82157957201679,
        "latitude": 21.020189368390966,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6196,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82131151873347,
          21.01971319157439
        ]
      },
      "properties": {
        "tenDoiTuon": "HC1/13",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Nguyễn Phúc Lai",
        "tuDien": "HC1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 10,
        "tuDien2": "HC1",
        "diaChiLapD": "",
        "longitude": 105.82131151873347,
        "latitude": 21.01971319157439,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6197,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82116919403462,
          21.01941321348871
        ]
      },
      "properties": {
        "tenDoiTuon": "HC1/14",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Nguyễn Phúc Lai",
        "tuDien": "HC1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 10,
        "tuDien2": "HC1",
        "diaChiLapD": "",
        "longitude": 105.82116919403462,
        "latitude": 21.01941321348871,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6198,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82148301249315,
          21.019247967084205
        ]
      },
      "properties": {
        "tenDoiTuon": "HC1/14-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 91 Nguyễn Phúc Lai",
        "tuDien": "HC1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "HC1",
        "diaChiLapD": "",
        "longitude": 105.82148301249315,
        "latitude": 21.019247967084205,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6199,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82176196223308,
          21.019140306449586
        ]
      },
      "properties": {
        "tenDoiTuon": "HC1/14-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 91 Nguyễn Phúc Lai",
        "tuDien": "HC1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "HC1",
        "diaChiLapD": "",
        "longitude": 105.82176196223308,
        "latitude": 21.019140306449586,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6200,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82104313021685,
          21.01935062017486
        ]
      },
      "properties": {
        "tenDoiTuon": "HC1/15",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 148 Nguyễn Phúc Lai",
        "tuDien": "HC1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "HC1",
        "diaChiLapD": "",
        "longitude": 105.82104313021685,
        "latitude": 21.01935062017486,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6201,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82075881606147,
          21.019400694828196
        ]
      },
      "properties": {
        "tenDoiTuon": "HC1/16",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 148 Nguyễn Phúc Lai",
        "tuDien": "HC1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "HC1",
        "diaChiLapD": "",
        "longitude": 105.82075881606147,
        "latitude": 21.019400694828196,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6202,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82049059516152,
          21.01944325826978
        ]
      },
      "properties": {
        "tenDoiTuon": "HC1/17",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 148 Nguyễn Phúc Lai",
        "tuDien": "HC1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "HC1",
        "diaChiLapD": "",
        "longitude": 105.82049059516152,
        "latitude": 21.01944325826978,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6203,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82024383192346,
          21.019453273195733
        ]
      },
      "properties": {
        "tenDoiTuon": "HC1/18",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 148 Nguyễn Phúc Lai",
        "tuDien": "HC1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "HC1",
        "diaChiLapD": "",
        "longitude": 105.82024383192346,
        "latitude": 21.019453273195733,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6204,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81998097544796,
          21.01944325826978
        ]
      },
      "properties": {
        "tenDoiTuon": "HC1/19",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 148 Nguyễn Phúc Lai",
        "tuDien": "HC1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "HC1",
        "diaChiLapD": "",
        "longitude": 105.81998097544796,
        "latitude": 21.01944325826978,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6205,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81993269568166,
          21.0192755081646
        ]
      },
      "properties": {
        "tenDoiTuon": "HC1/20",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 148 Nguyễn Phúc Lai",
        "tuDien": "HC1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "HC1",
        "diaChiLapD": "",
        "longitude": 105.81993269568166,
        "latitude": 21.0192755081646,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6206,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81969666129258,
          21.019450769463877
        ]
      },
      "properties": {
        "tenDoiTuon": "HC1/20-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 148 Nguyễn Phúc Lai",
        "tuDien": "HC1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "HC1",
        "diaChiLapD": "",
        "longitude": 105.81969666129258,
        "latitude": 21.019450769463877,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6207,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81943648701584,
          21.019398191095487
        ]
      },
      "properties": {
        "tenDoiTuon": "HC1/20-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 148 Nguyễn Phúc Lai",
        "tuDien": "HC1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "HC1",
        "diaChiLapD": "",
        "longitude": 105.81943648701584,
        "latitude": 21.019398191095487,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6208,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8209572995242,
          21.019543407496535
        ]
      },
      "properties": {
        "tenDoiTuon": "HC1/21",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 104 Nguyễn Phúc Lai",
        "tuDien": "HC1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "HC1",
        "diaChiLapD": "",
        "longitude": 105.8209572995242,
        "latitude": 21.019543407496535,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6209,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82071858292736,
          21.0196610827521
        ]
      },
      "properties": {
        "tenDoiTuon": "HC1/22",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 104 Nguyễn Phúc Lai",
        "tuDien": "HC1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "HC1",
        "diaChiLapD": "",
        "longitude": 105.82071858292736,
        "latitude": 21.0196610827521,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6210,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82057374363744,
          21.019713661027797
        ]
      },
      "properties": {
        "tenDoiTuon": "HC1/23",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 104 Nguyễn Phúc Lai",
        "tuDien": "HC1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "HC1",
        "diaChiLapD": "",
        "longitude": 105.82057374363744,
        "latitude": 21.019713661027797,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6211,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82025456076342,
          21.019703646119325
        ]
      },
      "properties": {
        "tenDoiTuon": "HC1/24",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 104 Nguyễn Phúc Lai",
        "tuDien": "HC1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "HC1",
        "diaChiLapD": "",
        "longitude": 105.82025456076342,
        "latitude": 21.019703646119325,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6212,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82001852637434,
          21.019723675934753
        ]
      },
      "properties": {
        "tenDoiTuon": "HC1/25",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 104 Nguyễn Phúc Lai",
        "tuDien": "HC1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "HC1",
        "diaChiLapD": "",
        "longitude": 105.82001852637434,
        "latitude": 21.019723675934753,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6213,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82040744667657,
          21.0200516637845
        ]
      },
      "properties": {
        "tenDoiTuon": "HC1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 104 Nguyễn Phúc Lai",
        "tuDien": "HC1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "HC1",
        "diaChiLapD": "",
        "longitude": 105.82040744667657,
        "latitude": 21.0200516637845,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6214,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82033234482387,
          21.019881410640018
        ]
      },
      "properties": {
        "tenDoiTuon": "HC1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 104 Nguyễn Phúc Lai",
        "tuDien": "HC1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "HC1",
        "diaChiLapD": "",
        "longitude": 105.82033234482387,
        "latitude": 21.019881410640018,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6215,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8200373018375,
          21.02045726608096
        ]
      },
      "properties": {
        "tenDoiTuon": "HC1/28",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 104 Nguyễn Phúc Lai",
        "tuDien": "HC1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "HC1",
        "diaChiLapD": "",
        "longitude": 105.8200373018375,
        "latitude": 21.02045726608096,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6216,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81997292881574,
          21.020249457635085
        ]
      },
      "properties": {
        "tenDoiTuon": "HC1/27",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 104 Nguyễn Phúc Lai",
        "tuDien": "HC1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "HC1",
        "diaChiLapD": "",
        "longitude": 105.81997292881574,
        "latitude": 21.020249457635085,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6217,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82012313252119,
          21.020126775403778
        ]
      },
      "properties": {
        "tenDoiTuon": "HC1/27-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 104 Nguyễn Phúc Lai",
        "tuDien": "HC1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "HC1",
        "diaChiLapD": "",
        "longitude": 105.82012313252119,
        "latitude": 21.020126775403778,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6218,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82059268672624,
          21.019880941187132
        ]
      },
      "properties": {
        "tenDoiTuon": "HC1/23-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 104 Nguyễn Phúc Lai",
        "tuDien": "HC1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "HC1",
        "diaChiLapD": "",
        "longitude": 105.82059268672624,
        "latitude": 21.019880941187132,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6219,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82067298536882,
          21.0200541675054
        ]
      },
      "properties": {
        "tenDoiTuon": "HC1/12-3-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 82 Nguyễn Phúc Lai",
        "tuDien": "HC1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "HC1",
        "diaChiLapD": "",
        "longitude": 105.82067298536882,
        "latitude": 21.0200541675054,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6220,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8207614982692,
          21.020254465071062
        ]
      },
      "properties": {
        "tenDoiTuon": "HC1/12-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 82 Nguyễn Phúc Lai",
        "tuDien": "HC1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "HC1",
        "diaChiLapD": "",
        "longitude": 105.8207614982692,
        "latitude": 21.020254465071062,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6221,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82052278166337,
          21.02031956172192
        ]
      },
      "properties": {
        "tenDoiTuon": "HC1/12-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 82 Nguyễn Phúc Lai",
        "tuDien": "HC1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "HC1",
        "diaChiLapD": "",
        "longitude": 105.82052278166337,
        "latitude": 21.02031956172192,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6222,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82100289708276,
          21.02022442045345
        ]
      },
      "properties": {
        "tenDoiTuon": "HC1/12-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 82 Nguyễn Phúc Lai",
        "tuDien": "HC1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "HC1",
        "diaChiLapD": "",
        "longitude": 105.82100289708276,
        "latitude": 21.02022442045345,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6223,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82125234251954,
          21.02018436095361
        ]
      },
      "properties": {
        "tenDoiTuon": "HC1/12-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 82 Nguyễn Phúc Lai",
        "tuDien": "HC1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "HC1",
        "diaChiLapD": "",
        "longitude": 105.82125234251954,
        "latitude": 21.02018436095361,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6224,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82183169967031,
          21.02039216949106
        ]
      },
      "properties": {
        "tenDoiTuon": "HC1/11-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 85 Nguyễn Phúc Lai",
        "tuDien": "HC1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 6,
        "tuDien2": "HC1",
        "diaChiLapD": "",
        "longitude": 105.82183169967031,
        "latitude": 21.02039216949106,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6225,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82125234251954,
          21.020632525992024
        ]
      },
      "properties": {
        "tenDoiTuon": "HC1/11-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 66 Nguyễn Phúc Lai",
        "tuDien": "HC1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 6,
        "tuDien2": "HC1",
        "diaChiLapD": "",
        "longitude": 105.82125234251954,
        "latitude": 21.020632525992024,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6226,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8216358984063,
          21.020807785696228
        ]
      },
      "properties": {
        "tenDoiTuon": "HC1/10-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 56 Nguyễn Phúc Lai",
        "tuDien": "HC1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 6,
        "tuDien2": "HC1",
        "diaChiLapD": "",
        "longitude": 105.8216358984063,
        "latitude": 21.020807785696228,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6227,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82129006106254,
          21.020883679338816
        ]
      },
      "properties": {
        "tenDoiTuon": "HC1/9-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 44 Nguyễn Phúc Lai",
        "tuDien": "HC1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "HC1",
        "diaChiLapD": "",
        "longitude": 105.82129006106254,
        "latitude": 21.020883679338816,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6228,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82163858061406,
          21.021023104479696
        ]
      },
      "properties": {
        "tenDoiTuon": "HC1/9-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 44 Nguyễn Phúc Lai",
        "tuDien": "HC1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "HC1",
        "diaChiLapD": "",
        "longitude": 105.82163858061406,
        "latitude": 21.021023104479696,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6229,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82106743771216,
          21.021137805415012
        ]
      },
      "properties": {
        "tenDoiTuon": "HC1/9-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 44 Nguyễn Phúc Lai",
        "tuDien": "HC1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "HC1",
        "diaChiLapD": "",
        "longitude": 105.82106743771216,
        "latitude": 21.021137805415012,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6230,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82123624926413,
          21.021103223017086
        ]
      },
      "properties": {
        "tenDoiTuon": "HC1/9-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 44 Nguyễn Phúc Lai",
        "tuDien": "HC1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "HC1",
        "diaChiLapD": "",
        "longitude": 105.82123624926413,
        "latitude": 21.021103223017086,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6231,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80332697212792,
          21.020120359620552
        ]
      },
      "properties": {
        "tenDoiTuon": "18-18",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 850 Đường Láng",
        "tuDien": "LTG1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Láng Trung 1",
        "diaChiLapD": "",
        "longitude": 105.80332697212792,
        "latitude": 21.020120359620552,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6232,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80319286167793,
          21.02037448699776
        ]
      },
      "properties": {
        "tenDoiTuon": "LT1 18-18-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 850 Đường Láng",
        "tuDien": "LTG1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "Láng Trung 1",
        "diaChiLapD": "",
        "longitude": 105.80319286167793,
        "latitude": 21.02037448699776,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6233,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80282674015046,
          21.020122863340323
        ]
      },
      "properties": {
        "tenDoiTuon": "18-16",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 185 Chùa Láng",
        "tuDien": "LTG1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Láng Trung 1",
        "diaChiLapD": "",
        "longitude": 105.80282674015046,
        "latitude": 21.020122863340323,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6234,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80253035605125,
          21.020388257434565
        ]
      },
      "properties": {
        "tenDoiTuon": "LT1 18-13",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 185 Chùa Láng",
        "tuDien": "LTG1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Láng Trung 1",
        "diaChiLapD": "",
        "longitude": 105.80253035605125,
        "latitude": 21.020388257434565,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6235,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80237747013808,
          21.020577287845256
        ]
      },
      "properties": {
        "tenDoiTuon": "18-12",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 185 Chùa Láng",
        "tuDien": "LTG1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 7,
        "tuDien2": "Láng Trung 1",
        "diaChiLapD": "",
        "longitude": 105.80237747013808,
        "latitude": 21.020577287845256,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6236,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80223665416874,
          21.020815140338865
        ]
      },
      "properties": {
        "tenDoiTuon": "18-11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 185 Chùa Láng",
        "tuDien": "LTG1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Láng Trung 1",
        "diaChiLapD": "",
        "longitude": 105.80223665416874,
        "latitude": 21.020815140338865,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6237,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80209852040716,
          21.021061755420156
        ]
      },
      "properties": {
        "tenDoiTuon": "LT1 18-10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 185 Chùa Láng",
        "tuDien": "LTG1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Láng Trung 1",
        "diaChiLapD": "",
        "longitude": 105.80209852040716,
        "latitude": 21.021061755420156,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6238,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80231041492657,
          21.021027955408023
        ]
      },
      "properties": {
        "tenDoiTuon": "LT1 18-10-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 65+91/185 Chùa Láng",
        "tuDien": "LTG1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 7,
        "tuDien2": "Láng Trung 1",
        "diaChiLapD": "",
        "longitude": 105.80231041492657,
        "latitude": 21.021027955408023,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6239,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.79842691140908,
          21.02394755968498
        ]
      },
      "properties": {
        "tenDoiTuon": "C361 01",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "C361",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 8,
        "tuDien2": "C 361",
        "diaChiLapD": "",
        "longitude": 105.79842691140908,
        "latitude": 21.02394755968498,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6240,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.798667304397,
          21.023726924850752
        ]
      },
      "properties": {
        "tenDoiTuon": "02",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "C361",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 8,
        "tuDien2": "C 361",
        "diaChiLapD": "",
        "longitude": 105.798667304397,
        "latitude": 21.023726924850752,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6241,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.7989207731454,
          21.02351160997308
        ]
      },
      "properties": {
        "tenDoiTuon": "03",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "C361",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 8,
        "tuDien2": "C 361",
        "diaChiLapD": "",
        "longitude": 105.7989207731454,
        "latitude": 21.02351160997308,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6242,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.79914071428803,
          21.023318827783292
        ]
      },
      "properties": {
        "tenDoiTuon": "04",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "C361",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 8,
        "tuDien2": "C 361",
        "diaChiLapD": "",
        "longitude": 105.79914071428803,
        "latitude": 21.023318827783292,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6243,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.79945151525162,
          21.023046240849638
        ]
      },
      "properties": {
        "tenDoiTuon": "05",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "C361",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 8,
        "tuDien2": "C 361",
        "diaChiLapD": "",
        "longitude": 105.79945151525162,
        "latitude": 21.023046240849638,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6244,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.79969827848066,
          21.022840939686418
        ]
      },
      "properties": {
        "tenDoiTuon": "06",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "C361",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 8,
        "tuDien2": "C 361",
        "diaChiLapD": "",
        "longitude": 105.79969827848066,
        "latitude": 21.022840939686418,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6245,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.79996649938063,
          21.02262562352828
        ]
      },
      "properties": {
        "tenDoiTuon": "07",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "C361",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 8,
        "tuDien2": "C 361",
        "diaChiLapD": "",
        "longitude": 105.79996649938063,
        "latitude": 21.02262562352828,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6246,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80022935586511,
          21.02236524078255
        ]
      },
      "properties": {
        "tenDoiTuon": "08",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "C361",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 8,
        "tuDien2": "C 361",
        "diaChiLapD": "",
        "longitude": 105.80022935586511,
        "latitude": 21.02236524078255,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6247,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80042783933683,
          21.022169953424182
        ]
      },
      "properties": {
        "tenDoiTuon": "09",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "C361",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 8,
        "tuDien2": "C 361",
        "diaChiLapD": "",
        "longitude": 105.80042783933683,
        "latitude": 21.022169953424182,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6248,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80066923814141,
          21.02190956988261
        ]
      },
      "properties": {
        "tenDoiTuon": "10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "C361",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 8,
        "tuDien2": "C 361",
        "diaChiLapD": "",
        "longitude": 105.80066923814141,
        "latitude": 21.02190956988261,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6249,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80091063695498,
          21.021589097206263
        ]
      },
      "properties": {
        "tenDoiTuon": "11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "C361",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 8,
        "tuDien2": "C 361",
        "diaChiLapD": "",
        "longitude": 105.80091063695498,
        "latitude": 21.021589097206263,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6250,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80104474740496,
          21.02133372004998
        ]
      },
      "properties": {
        "tenDoiTuon": "12",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "C361",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 8,
        "tuDien2": "C 361",
        "diaChiLapD": "",
        "longitude": 105.80104474740496,
        "latitude": 21.02133372004998,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6251,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80121640878129,
          21.021058312822007
        ]
      },
      "properties": {
        "tenDoiTuon": "13",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "C361",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 8,
        "tuDien2": "C 361",
        "diaChiLapD": "",
        "longitude": 105.80121640878129,
        "latitude": 21.021058312822007,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6252,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8013397904003,
          21.020842994089374
        ]
      },
      "properties": {
        "tenDoiTuon": "14",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "C361",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 8,
        "tuDien2": "C 361",
        "diaChiLapD": "",
        "longitude": 105.8013397904003,
        "latitude": 21.020842994089374,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6253,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80148462968127,
          21.0206026379277
        ]
      },
      "properties": {
        "tenDoiTuon": "15",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "C361",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 8,
        "tuDien2": "C 361",
        "diaChiLapD": "",
        "longitude": 105.80148462968127,
        "latitude": 21.0206026379277,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6254,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80165092664211,
          21.020297184746354
        ]
      },
      "properties": {
        "tenDoiTuon": "16",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "C361",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 8,
        "tuDien2": "C 361",
        "diaChiLapD": "",
        "longitude": 105.80165092664211,
        "latitude": 21.020297184746354,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6255,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80184941011383,
          21.01996168626868
        ]
      },
      "properties": {
        "tenDoiTuon": "17",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "C361",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 8,
        "tuDien2": "C 361",
        "diaChiLapD": "",
        "longitude": 105.80184941011383,
        "latitude": 21.01996168626868,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6256,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80201034265022,
          21.019681269052153
        ]
      },
      "properties": {
        "tenDoiTuon": "18",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "C361",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 8,
        "tuDien2": "C 361",
        "diaChiLapD": "",
        "longitude": 105.80201034265022,
        "latitude": 21.019681269052153,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6257,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80213908868471,
          21.019425888627318
        ]
      },
      "properties": {
        "tenDoiTuon": "19",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "C361",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 8,
        "tuDien2": "C 361",
        "diaChiLapD": "",
        "longitude": 105.80213908868471,
        "latitude": 21.019425888627318,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6258,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8022678347192,
          21.019170507765942
        ]
      },
      "properties": {
        "tenDoiTuon": "20",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "C361",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 8,
        "tuDien2": "C 361",
        "diaChiLapD": "",
        "longitude": 105.8022678347192,
        "latitude": 21.019170507765942,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6259,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80240730958468,
          21.018890089060648
        ]
      },
      "properties": {
        "tenDoiTuon": "21",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "C361",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 8,
        "tuDien2": "C 361",
        "diaChiLapD": "",
        "longitude": 105.80240730958468,
        "latitude": 21.018890089060648,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6260,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80255214887461,
          21.018619684809973
        ]
      },
      "properties": {
        "tenDoiTuon": "22",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "C361",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 8,
        "tuDien2": "C 361",
        "diaChiLapD": "",
        "longitude": 105.80255214887461,
        "latitude": 21.018619684809973,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6261,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80270235258003,
          21.018354287568727
        ]
      },
      "properties": {
        "tenDoiTuon": "23",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "C361",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 8,
        "tuDien2": "C 361",
        "diaChiLapD": "",
        "longitude": 105.80270235258003,
        "latitude": 21.018354287568727,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6262,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80285129899693,
          21.018068468607748
        ]
      },
      "properties": {
        "tenDoiTuon": "24",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "C361",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 8,
        "tuDien2": "C 361",
        "diaChiLapD": "",
        "longitude": 105.80285129899693,
        "latitude": 21.018068468607748,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6263,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80300275999086,
          21.017788439042313
        ]
      },
      "properties": {
        "tenDoiTuon": "25",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "C361",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 8,
        "tuDien2": "C 361",
        "diaChiLapD": "",
        "longitude": 105.80300275999086,
        "latitude": 21.017788439042313,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6264,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80315832811178,
          21.017513025266062
        ]
      },
      "properties": {
        "tenDoiTuon": "26",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "C361",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 8,
        "tuDien2": "C 361",
        "diaChiLapD": "",
        "longitude": 105.80315832811178,
        "latitude": 21.017513025266062,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6265,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80334071832809,
          21.01726765620143
        ]
      },
      "properties": {
        "tenDoiTuon": "27",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "C361",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 8,
        "tuDien2": "C 361",
        "diaChiLapD": "",
        "longitude": 105.80334071832809,
        "latitude": 21.01726765620143,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6266,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80354993063075,
          21.017007264098122
        ]
      },
      "properties": {
        "tenDoiTuon": "28",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "C361",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 8,
        "tuDien2": "C 361",
        "diaChiLapD": "",
        "longitude": 105.80354993063075,
        "latitude": 21.017007264098122,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6267,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80374841409349,
          21.016746871540988
        ]
      },
      "properties": {
        "tenDoiTuon": "29",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "C361",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 8,
        "tuDien2": "C 361",
        "diaChiLapD": "",
        "longitude": 105.80374841409349,
        "latitude": 21.016746871540988,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6268,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80394153314073,
          21.016486478529185
        ]
      },
      "properties": {
        "tenDoiTuon": "30",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "C361",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 8,
        "tuDien2": "C 361",
        "diaChiLapD": "",
        "longitude": 105.80394153314073,
        "latitude": 21.016486478529185,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6269,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80414538102795,
          21.01622608506189
        ]
      },
      "properties": {
        "tenDoiTuon": "31",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "C361",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 8,
        "tuDien2": "C 361",
        "diaChiLapD": "",
        "longitude": 105.80414538102795,
        "latitude": 21.01622608506189,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6270,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80436263996285,
          21.016000744194447
        ]
      },
      "properties": {
        "tenDoiTuon": "32",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "C361",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 8,
        "tuDien2": "C 361",
        "diaChiLapD": "",
        "longitude": 105.80436263996285,
        "latitude": 21.016000744194447,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6271,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80458258109651,
          21.015767891608096
        ]
      },
      "properties": {
        "tenDoiTuon": "C361 33",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "C361",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 8,
        "tuDien2": "C 361",
        "diaChiLapD": "",
        "longitude": 105.80458258109651,
        "latitude": 21.015767891608096,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6272,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8028160113195,
          21.020548495150248
        ]
      },
      "properties": {
        "tenDoiTuon": "18-14",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 65+91/185 Chùa Láng",
        "tuDien": "LTG1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Láng Trung 1",
        "diaChiLapD": "",
        "longitude": 105.8028160113195,
        "latitude": 21.020548495150248,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6273,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80282539905558,
          21.02061859909567
        ]
      },
      "properties": {
        "tenDoiTuon": "18-15-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 65+91/185 Chùa Láng",
        "tuDien": "LTG1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Láng Trung 1",
        "diaChiLapD": "",
        "longitude": 105.80282539905558,
        "latitude": 21.02061859909567,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6274,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80294743956175,
          21.0206861992987
        ]
      },
      "properties": {
        "tenDoiTuon": "18-15",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 65+91/185 Chùa Láng",
        "tuDien": "LTG1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Láng Trung 1",
        "diaChiLapD": "",
        "longitude": 105.80294743956175,
        "latitude": 21.0206861992987,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6275,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80295548619394,
          21.020537228441064
        ]
      },
      "properties": {
        "tenDoiTuon": "18-15-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 65+91/185 Chùa Láng",
        "tuDien": "LTG1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 7,
        "tuDien2": "Láng Trung 1",
        "diaChiLapD": "",
        "longitude": 105.80295548619394,
        "latitude": 21.020537228441064,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6276,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80308155001175,
          21.020683695587568
        ]
      },
      "properties": {
        "tenDoiTuon": "LT1 18-15-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 65+91/185 Chùa Láng",
        "tuDien": "LTG1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "Láng Trung 1",
        "diaChiLapD": "",
        "longitude": 105.80308155001175,
        "latitude": 21.020683695587568,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6277,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8031003254749,
          21.02077633285421
        ]
      },
      "properties": {
        "tenDoiTuon": "LT1 18-15-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 65+91/185 Chùa Láng",
        "tuDien": "LTG1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "Láng Trung 1",
        "diaChiLapD": "",
        "longitude": 105.8031003254749,
        "latitude": 21.02077633285421,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6278,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.79819624143548,
          21.023907501185516
        ]
      },
      "properties": {
        "tenDoiTuon": "01",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 5,
        "maXaPhuong": 172,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Láng",
        "tuDien": "C361",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BANIAN",
        "chieuCao": 3,
        "tuDien2": "C 361",
        "diaChiLapD": "",
        "longitude": 105.79819624143548,
        "latitude": 21.023907501185516,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6279,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.79841081815364,
          21.023717223167925
        ]
      },
      "properties": {
        "tenDoiTuon": "02",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 5,
        "maXaPhuong": 172,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Láng",
        "tuDien": "C361",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BANIAN",
        "chieuCao": 3,
        "tuDien2": "C 361",
        "diaChiLapD": "",
        "longitude": 105.79841081815364,
        "latitude": 21.023717223167925,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6280,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.79869513230905,
          21.02350190827538
        ]
      },
      "properties": {
        "tenDoiTuon": "03",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 5,
        "maXaPhuong": 172,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Láng",
        "tuDien": "C361",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BANIAN",
        "chieuCao": 3,
        "tuDien2": "C 361",
        "diaChiLapD": "",
        "longitude": 105.79869513230905,
        "latitude": 21.02350190827538,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6281,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.79892580228265,
          21.02330662240596
        ]
      },
      "properties": {
        "tenDoiTuon": "04",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 5,
        "maXaPhuong": 172,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Láng",
        "tuDien": "C361",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BANIAN",
        "chieuCao": 3,
        "tuDien2": "C 361",
        "diaChiLapD": "",
        "longitude": 105.79892580228265,
        "latitude": 21.02330662240596,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6282,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.799220845278,
          21.023081292239652
        ]
      },
      "properties": {
        "tenDoiTuon": "05",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 5,
        "maXaPhuong": 172,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Láng",
        "tuDien": "C361",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BANIAN",
        "chieuCao": 3,
        "tuDien2": "C 361",
        "diaChiLapD": "",
        "longitude": 105.799220845278,
        "latitude": 21.023081292239652,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6283,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.79949443059346,
          21.02287098377675
        ]
      },
      "properties": {
        "tenDoiTuon": "06",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 5,
        "maXaPhuong": 172,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Láng",
        "tuDien": "C361",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BANIAN",
        "chieuCao": 3,
        "tuDien2": "C 361",
        "diaChiLapD": "",
        "longitude": 105.79949443059346,
        "latitude": 21.02287098377675,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6284,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.79976801591789,
          21.022640645595487
        ]
      },
      "properties": {
        "tenDoiTuon": "07",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 5,
        "maXaPhuong": 172,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Láng",
        "tuDien": "C361",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BANIAN",
        "chieuCao": 3,
        "tuDien2": "C 361",
        "diaChiLapD": "",
        "longitude": 105.79976801591789,
        "latitude": 21.022640645595487,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6285,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80002014356242,
          21.02242532914891
        ]
      },
      "properties": {
        "tenDoiTuon": "08",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 5,
        "maXaPhuong": 172,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Láng",
        "tuDien": "C361",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BANIAN",
        "chieuCao": 3,
        "tuDien2": "C 361",
        "diaChiLapD": "",
        "longitude": 105.80002014356242,
        "latitude": 21.02242532914891,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6286,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80021326260969,
          21.022205005020233
        ]
      },
      "properties": {
        "tenDoiTuon": "09",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 5,
        "maXaPhuong": 172,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Láng",
        "tuDien": "C361",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BANIAN",
        "chieuCao": 3,
        "tuDien2": "C 361",
        "diaChiLapD": "",
        "longitude": 105.80021326260969,
        "latitude": 21.022205005020233,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6287,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80046002583873,
          21.021934606781265
        ]
      },
      "properties": {
        "tenDoiTuon": "10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 5,
        "maXaPhuong": 172,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Láng",
        "tuDien": "C361",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BANIAN",
        "chieuCao": 3,
        "tuDien2": "C 361",
        "diaChiLapD": "",
        "longitude": 105.80046002583873,
        "latitude": 21.021934606781265,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6288,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80071751790771,
          21.021639171107818
        ]
      },
      "properties": {
        "tenDoiTuon": "11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Láng",
        "tuDien": "C361",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BANIAN",
        "chieuCao": 3,
        "tuDien2": "C 361",
        "diaChiLapD": "",
        "longitude": 105.80071751790771,
        "latitude": 21.021639171107818,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6289,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8008784504441,
          21.021368771842628
        ]
      },
      "properties": {
        "tenDoiTuon": "12",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Láng",
        "tuDien": "C361",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BANIAN",
        "chieuCao": 3,
        "tuDien2": "C 361",
        "diaChiLapD": "",
        "longitude": 105.8008784504441,
        "latitude": 21.021368771842628,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6290,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80102328973405,
          21.021098372087042
        ]
      },
      "properties": {
        "tenDoiTuon": "13",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 5,
        "maXaPhuong": 172,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Láng",
        "tuDien": "C361",
        "chuDauTu": "SoXayDung",
        "loaiCot": "ARLEQUIN",
        "chieuCao": 3,
        "tuDien2": "C 361",
        "diaChiLapD": "",
        "longitude": 105.80102328973405,
        "latitude": 21.021098372087042,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6291,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80114667135305,
          21.020883053412298
        ]
      },
      "properties": {
        "tenDoiTuon": "14",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 5,
        "maXaPhuong": 172,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Láng",
        "tuDien": "C361",
        "chuDauTu": "SoXayDung",
        "loaiCot": "ARLEQUIN",
        "chieuCao": 3,
        "tuDien2": "C 361",
        "diaChiLapD": "",
        "longitude": 105.80114667135305,
        "latitude": 21.020883053412298,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6292,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.801291510634,
          21.02063268246913
        ]
      },
      "properties": {
        "tenDoiTuon": "15",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Láng",
        "tuDien": "C361",
        "chuDauTu": "SoXayDung",
        "loaiCot": "ARLEQUIN",
        "chieuCao": 3,
        "tuDien2": "C 361",
        "diaChiLapD": "",
        "longitude": 105.801291510634,
        "latitude": 21.02063268246913,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6293,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8014685364348,
          21.02037730367382
        ]
      },
      "properties": {
        "tenDoiTuon": "16",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Láng",
        "tuDien": "C361",
        "chuDauTu": "SoXayDung",
        "loaiCot": "ARLEQUIN",
        "chieuCao": 3,
        "tuDien2": "C 361",
        "diaChiLapD": "",
        "longitude": 105.8014685364348,
        "latitude": 21.02037730367382,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6294,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80169920640841,
          21.019946663931584
        ]
      },
      "properties": {
        "tenDoiTuon": "17",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Láng",
        "tuDien": "C361",
        "chuDauTu": "SoXayDung",
        "loaiCot": "ARLEQUIN",
        "chieuCao": 3,
        "tuDien2": "C 361",
        "diaChiLapD": "",
        "longitude": 105.80169920640841,
        "latitude": 21.019946663931584,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6295,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80183868127389,
          21.01976639576294
        ]
      },
      "properties": {
        "tenDoiTuon": "18",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Láng",
        "tuDien": "C361",
        "chuDauTu": "SoXayDung",
        "loaiCot": "ARLEQUIN",
        "chieuCao": 3,
        "tuDien2": "C 361",
        "diaChiLapD": "",
        "longitude": 105.80183868127389,
        "latitude": 21.01976639576294,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6296,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80201034265022,
          21.01950100056268
        ]
      },
      "properties": {
        "tenDoiTuon": "19",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Láng",
        "tuDien": "C361",
        "chuDauTu": "SoXayDung",
        "loaiCot": "ARLEQUIN",
        "chieuCao": 3,
        "tuDien2": "C 361",
        "diaChiLapD": "",
        "longitude": 105.80201034265022,
        "latitude": 21.01950100056268,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6297,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80210690217386,
          21.019255634768317
        ]
      },
      "properties": {
        "tenDoiTuon": "20",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Láng",
        "tuDien": "C361",
        "chuDauTu": "SoXayDung",
        "loaiCot": "ARLEQUIN",
        "chieuCao": 3,
        "tuDien2": "C 361",
        "diaChiLapD": "",
        "longitude": 105.80210690217386,
        "latitude": 21.019255634768317,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6298,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80224637704829,
          21.018985231180537
        ]
      },
      "properties": {
        "tenDoiTuon": "21",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Láng",
        "tuDien": "C361",
        "chuDauTu": "SoXayDung",
        "loaiCot": "ARLEQUIN",
        "chieuCao": 3,
        "tuDien2": "C 361",
        "diaChiLapD": "",
        "longitude": 105.80224637704829,
        "latitude": 21.018985231180537,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6299,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8024019451692,
          21.018684782174528
        ]
      },
      "properties": {
        "tenDoiTuon": "22",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Láng",
        "tuDien": "C361",
        "chuDauTu": "SoXayDung",
        "loaiCot": "ARLEQUIN",
        "chieuCao": 3,
        "tuDien2": "C 361",
        "diaChiLapD": "",
        "longitude": 105.8024019451692,
        "latitude": 21.018684782174528,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6300,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80252532677925,
          21.018439415037108
        ]
      },
      "properties": {
        "tenDoiTuon": "23",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Láng",
        "tuDien": "C361",
        "chuDauTu": "SoXayDung",
        "loaiCot": "ARLEQUIN",
        "chieuCao": 3,
        "tuDien2": "C 361",
        "diaChiLapD": "",
        "longitude": 105.80252532677925,
        "latitude": 21.018439415037108,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6301,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80267016606918,
          21.01817401747498
        ]
      },
      "properties": {
        "tenDoiTuon": "24",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Láng",
        "tuDien": "C361",
        "chuDauTu": "SoXayDung",
        "loaiCot": "ARLEQUIN",
        "chieuCao": 3,
        "tuDien2": "C 361",
        "diaChiLapD": "",
        "longitude": 105.80267016606918,
        "latitude": 21.01817401747498,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6302,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80282573419007,
          21.01787857434946
        ]
      },
      "properties": {
        "tenDoiTuon": "25",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 5,
        "maXaPhuong": 172,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Láng",
        "tuDien": "C361",
        "chuDauTu": "SoXayDung",
        "loaiCot": "ARLEQUIN",
        "chieuCao": 3,
        "tuDien2": "C 361",
        "diaChiLapD": "",
        "longitude": 105.80282573419007,
        "latitude": 21.01787857434946,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6303,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80296973529317,
          21.01759345866012
        ]
      },
      "properties": {
        "tenDoiTuon": "26",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 5,
        "maXaPhuong": 172,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Láng",
        "tuDien": "C361",
        "chuDauTu": "SoXayDung",
        "loaiCot": "ARLEQUIN",
        "chieuCao": 3,
        "tuDien2": "C 361",
        "diaChiLapD": "",
        "longitude": 105.80296973529317,
        "latitude": 21.01759345866012,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6304,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80313687044085,
          21.01733275415536
        ]
      },
      "properties": {
        "tenDoiTuon": "27",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 5,
        "maXaPhuong": 175,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Láng",
        "tuDien": "C361",
        "chuDauTu": "SoXayDung",
        "loaiCot": "ARLEQUIN",
        "chieuCao": 3,
        "tuDien2": "C 361",
        "diaChiLapD": "",
        "longitude": 105.80313687044085,
        "latitude": 21.01733275415536,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6305,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80333535390359,
          21.01706234708069
        ]
      },
      "properties": {
        "tenDoiTuon": "28",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 5,
        "maXaPhuong": 175,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Láng",
        "tuDien": "C361",
        "chuDauTu": "SoXayDung",
        "loaiCot": "ARLEQUIN",
        "chieuCao": 3,
        "tuDien2": "C 361",
        "diaChiLapD": "",
        "longitude": 105.80333535390359,
        "latitude": 21.01706234708069,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6306,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80351774411989,
          21.01680696217151
        ]
      },
      "properties": {
        "tenDoiTuon": "29",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 5,
        "maXaPhuong": 175,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Láng",
        "tuDien": "C361",
        "chuDauTu": "SoXayDung",
        "loaiCot": "ARLEQUIN",
        "chieuCao": 3,
        "tuDien2": "C 361",
        "diaChiLapD": "",
        "longitude": 105.80351774411989,
        "latitude": 21.01680696217151,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6307,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80370013433617,
          21.016561591944296
        ]
      },
      "properties": {
        "tenDoiTuon": "30",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 5,
        "maXaPhuong": 175,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Láng",
        "tuDien": "C361",
        "chuDauTu": "SoXayDung",
        "loaiCot": "ARLEQUIN",
        "chieuCao": 3,
        "tuDien2": "C 361",
        "diaChiLapD": "",
        "longitude": 105.80370013433617,
        "latitude": 21.016561591944296,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6308,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80390934663886,
          21.01631121374514
        ]
      },
      "properties": {
        "tenDoiTuon": "31",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 5,
        "maXaPhuong": 175,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Láng",
        "tuDien": "C361",
        "chuDauTu": "SoXayDung",
        "loaiCot": "ARLEQUIN",
        "chieuCao": 3,
        "tuDien2": "C 361",
        "diaChiLapD": "",
        "longitude": 105.80390934663886,
        "latitude": 21.01631121374514,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6309,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8041024656861,
          21.01606083512642
        ]
      },
      "properties": {
        "tenDoiTuon": "32",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 5,
        "maXaPhuong": 175,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Láng",
        "tuDien": "C361",
        "chuDauTu": "SoXayDung",
        "loaiCot": "ARLEQUIN",
        "chieuCao": 3,
        "tuDien2": "C 361",
        "diaChiLapD": "",
        "longitude": 105.8041024656861,
        "latitude": 21.01606083512642,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6310,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80430631357333,
          21.01582547884039
        ]
      },
      "properties": {
        "tenDoiTuon": "33",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 5,
        "maXaPhuong": 175,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Láng",
        "tuDien": "C361",
        "chuDauTu": "SoXayDung",
        "loaiCot": "ARLEQUIN",
        "chieuCao": 3,
        "tuDien2": "C 361",
        "diaChiLapD": "",
        "longitude": 105.80430631357333,
        "latitude": 21.01582547884039,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6311,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.804090395886,
          21.0178817040544
        ]
      },
      "properties": {
        "tenDoiTuon": "01",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 159 Pháo Đài Láng",
        "tuDien": "DDLT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Di Dân Láng Trung",
        "diaChiLapD": "",
        "longitude": 105.804090395886,
        "latitude": 21.0178817040544,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6312,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80386777255362,
          21.01779156874915
        ]
      },
      "properties": {
        "tenDoiTuon": "1-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 159 Pháo Đài Láng",
        "tuDien": "DDLT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 7,
        "tuDien2": "Di Dân Láng Trung",
        "diaChiLapD": "",
        "longitude": 105.80386777255362,
        "latitude": 21.01779156874915,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6313,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80396164986949,
          21.018092019554828
        ]
      },
      "properties": {
        "tenDoiTuon": "DDLT 02",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 159 Pháo Đài Láng",
        "tuDien": "DDLT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Di Dân Láng Trung",
        "diaChiLapD": "",
        "longitude": 105.80396164986949,
        "latitude": 21.018092019554828,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6314,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80399517747526,
          21.018331127889027
        ]
      },
      "properties": {
        "tenDoiTuon": "27",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Phố Pháo Đài Láng",
        "tuDien": "DDLT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Di Dân Láng Trung",
        "diaChiLapD": "",
        "longitude": 105.80399517747526,
        "latitude": 21.018331127889027,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6315,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8038597259214,
          21.01851515341339
        ]
      },
      "properties": {
        "tenDoiTuon": "28",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Phố Pháo Đài Láng",
        "tuDien": "DDLT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Di Dân Láng Trung",
        "diaChiLapD": "",
        "longitude": 105.8038597259214,
        "latitude": 21.01851515341339,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6316,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80370281669661,
          21.01878430598209
        ]
      },
      "properties": {
        "tenDoiTuon": "DDLT 29",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 102 Pháo Đài Láng",
        "tuDien": "DDLT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Di Dân Láng Trung",
        "diaChiLapD": "",
        "longitude": 105.80370281669661,
        "latitude": 21.01878430598209,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6317,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80412526460462,
          21.018692919350126
        ]
      },
      "properties": {
        "tenDoiTuon": "30",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Phố Pháo Đài Láng",
        "tuDien": "DDLT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Di Dân Láng Trung",
        "diaChiLapD": "",
        "longitude": 105.80412526460462,
        "latitude": 21.018692919350126,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6318,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80450345607592,
          21.018876944428
        ]
      },
      "properties": {
        "tenDoiTuon": "31",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Phố Pháo Đài Láng",
        "tuDien": "DDLT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Di Dân Láng Trung",
        "diaChiLapD": "",
        "longitude": 105.80450345607592,
        "latitude": 21.018876944428,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6319,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80456246467318,
          21.01873047550672
        ]
      },
      "properties": {
        "tenDoiTuon": "31-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 131 Pháo Đài Láng",
        "tuDien": "DDLT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 7,
        "tuDien2": "Di Dân Láng Trung",
        "diaChiLapD": "",
        "longitude": 105.80456246467318,
        "latitude": 21.01873047550672,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6320,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80462683768596,
          21.018553961488212
        ]
      },
      "properties": {
        "tenDoiTuon": "DDLT 31-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 131 Pháo Đài Láng",
        "tuDien": "DDLT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Di Dân Láng Trung",
        "diaChiLapD": "",
        "longitude": 105.80462683768596,
        "latitude": 21.018553961488212,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6321,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80455039473836,
          21.018958315988673
        ]
      },
      "properties": {
        "tenDoiTuon": "31-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 76 Pháo Đài Láng",
        "tuDien": "DDLT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 7,
        "tuDien2": "Di Dân Láng Trung",
        "diaChiLapD": "",
        "longitude": 105.80455039473836,
        "latitude": 21.018958315988673,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6322,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80447126957402,
          21.019086006655733
        ]
      },
      "properties": {
        "tenDoiTuon": "DDLT 31-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 76 Pháo Đài Láng",
        "tuDien": "DDLT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 7,
        "tuDien2": "Di Dân Láng Trung",
        "diaChiLapD": "",
        "longitude": 105.80447126957402,
        "latitude": 21.019086006655733,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6323,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80476497145652,
          21.019013398250564
        ]
      },
      "properties": {
        "tenDoiTuon": "32",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Phố Pháo Đài Láng",
        "tuDien": "DDLT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Di Dân Láng Trung",
        "diaChiLapD": "",
        "longitude": 105.80476497145652,
        "latitude": 21.019013398250564,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6324,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80468349937166,
          21.01876271119982
        ]
      },
      "properties": {
        "tenDoiTuon": "32-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 127 Pháo Đài Láng",
        "tuDien": "DDLT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 7,
        "tuDien2": "Di Dân Láng Trung",
        "diaChiLapD": "",
        "longitude": 105.80468349937166,
        "latitude": 21.01876271119982,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6325,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80504693869138,
          21.019139524034596
        ]
      },
      "properties": {
        "tenDoiTuon": "33",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Phố Pháo Đài Láng",
        "tuDien": "DDLT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Di Dân Láng Trung",
        "diaChiLapD": "",
        "longitude": 105.80504693869138,
        "latitude": 21.019139524034596,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6326,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8048417497003,
          21.019215887982117
        ]
      },
      "properties": {
        "tenDoiTuon": "DDLT 33-8",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 56 Pháo Đài Láng",
        "tuDien": "DDLT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 7,
        "tuDien2": "Di Dân Láng Trung",
        "diaChiLapD": "",
        "longitude": 105.8048417497003,
        "latitude": 21.019215887982117,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6327,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80531515959134,
          21.019301014958586
        ]
      },
      "properties": {
        "tenDoiTuon": "34",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Phố Pháo Đài Láng",
        "tuDien": "DDLT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Di Dân Láng Trung",
        "diaChiLapD": "",
        "longitude": 105.80531515959134,
        "latitude": 21.019301014958586,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6328,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80571614983741,
          21.01952134337774
        ]
      },
      "properties": {
        "tenDoiTuon": "35",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Phố Pháo Đài Láng",
        "tuDien": "DDLT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Di Dân Láng Trung",
        "diaChiLapD": "",
        "longitude": 105.80571614983741,
        "latitude": 21.01952134337774,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6329,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80614530328276,
          21.019697856251923
        ]
      },
      "properties": {
        "tenDoiTuon": "36",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Phố Pháo Đài Láng",
        "tuDien": "DDLT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Di Dân Láng Trung",
        "diaChiLapD": "",
        "longitude": 105.80614530328276,
        "latitude": 21.019697856251923,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6330,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80643900516525,
          21.019846827948182
        ]
      },
      "properties": {
        "tenDoiTuon": "37",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Phố Pháo Đài Láng",
        "tuDien": "DDLT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Di Dân Láng Trung",
        "diaChiLapD": "",
        "longitude": 105.80643900516525,
        "latitude": 21.019846827948182,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6331,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80667235735561,
          21.01996074737937
        ]
      },
      "properties": {
        "tenDoiTuon": "38",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Phố Pháo Đài Láng",
        "tuDien": "DDLT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Di Dân Láng Trung",
        "diaChiLapD": "",
        "longitude": 105.80667235735561,
        "latitude": 21.01996074737937,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6332,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80698617580514,
          21.02017105994742
        ]
      },
      "properties": {
        "tenDoiTuon": "39",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Phố Pháo Đài Láng",
        "tuDien": "DDLT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Di Dân Láng Trung",
        "diaChiLapD": "",
        "longitude": 105.80698617580514,
        "latitude": 21.02017105994742,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6333,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80725003812425,
          21.020217065781704
        ]
      },
      "properties": {
        "tenDoiTuon": "40",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Phố Pháo Đài Láng",
        "tuDien": "DDLT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Di Dân Láng Trung",
        "diaChiLapD": "",
        "longitude": 105.80725003812425,
        "latitude": 21.020217065781704,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6334,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80752764675131,
          21.020091879804344
        ]
      },
      "properties": {
        "tenDoiTuon": "DDLT 41",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Phố Pháo Đài Láng",
        "tuDien": "DDLT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Di Dân Láng Trung",
        "diaChiLapD": "",
        "longitude": 105.80752764675131,
        "latitude": 21.020091879804344,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6335,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80711726877816,
          21.02004556096516
        ]
      },
      "properties": {
        "tenDoiTuon": "39-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 39 Pháo Đài Láng",
        "tuDien": "DDLT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Di Dân Láng Trung",
        "diaChiLapD": "",
        "longitude": 105.80711726877816,
        "latitude": 21.02004556096516,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6336,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80697779391268,
          21.019845263118867
        ]
      },
      "properties": {
        "tenDoiTuon": "42",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 39 Pháo Đài Láng",
        "tuDien": "DDLT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Di Dân Láng Trung",
        "diaChiLapD": "",
        "longitude": 105.80697779391268,
        "latitude": 21.019845263118867,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6337,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80707703564406,
          21.01979518861569
        ]
      },
      "properties": {
        "tenDoiTuon": "42-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 39 Pháo Đài Láng",
        "tuDien": "DDLT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Di Dân Láng Trung",
        "diaChiLapD": "",
        "longitude": 105.80707703564406,
        "latitude": 21.01979518861569,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6338,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80684100125495,
          21.019678765329992
        ]
      },
      "properties": {
        "tenDoiTuon": "43",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 39 Pháo Đài Láng",
        "tuDien": "DDLT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Di Dân Láng Trung",
        "diaChiLapD": "",
        "longitude": 105.80684100125495,
        "latitude": 21.019678765329992,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6339,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8067457828352,
          21.01952603787164
        ]
      },
      "properties": {
        "tenDoiTuon": "44",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 39 Pháo Đài Láng",
        "tuDien": "DDLT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Di Dân Láng Trung",
        "diaChiLapD": "",
        "longitude": 105.8067457828352,
        "latitude": 21.01952603787164,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6340,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80664922331157,
          21.01956609754753
        ]
      },
      "properties": {
        "tenDoiTuon": "DDLT 44-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 39 Pháo Đài Láng",
        "tuDien": "DDLT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Di Dân Láng Trung",
        "diaChiLapD": "",
        "longitude": 105.80664922331157,
        "latitude": 21.01956609754753,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6341,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82211618142438,
          21.02084549779869
        ]
      },
      "properties": {
        "tenDoiTuon": "HC1/9-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 69 Nguyễn Phúc Lai",
        "tuDien": "HC1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "HC1",
        "diaChiLapD": "",
        "longitude": 105.82211618142438,
        "latitude": 21.02084549779869,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6342,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82237367349337,
          21.020863023753446
        ]
      },
      "properties": {
        "tenDoiTuon": "HC1/9-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 69 Nguyễn Phúc Lai",
        "tuDien": "HC1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "HC1",
        "diaChiLapD": "",
        "longitude": 105.82237367349337,
        "latitude": 21.020863023753446,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6343,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82250778394337,
          21.021020757253407
        ]
      },
      "properties": {
        "tenDoiTuon": "HC1/9-8",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 69 Nguyễn Phúc Lai",
        "tuDien": "HC1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 6,
        "tuDien2": "HC1",
        "diaChiLapD": "",
        "longitude": 105.82250778394337,
        "latitude": 21.021020757253407,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6344,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82263384777012,
          21.020795423631416
        ]
      },
      "properties": {
        "tenDoiTuon": "HC1/9-7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 69 Nguyễn Phúc Lai",
        "tuDien": "HC1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "HC1",
        "diaChiLapD": "",
        "longitude": 105.82263384777012,
        "latitude": 21.020795423631416,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6345,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82229052500846,
          21.020627675047496
        ]
      },
      "properties": {
        "tenDoiTuon": "HC1/9-9",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 69 Nguyễn Phúc Lai",
        "tuDien": "HC1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "HC1",
        "diaChiLapD": "",
        "longitude": 105.82229052500846,
        "latitude": 21.020627675047496,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6346,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82245682196931,
          21.020427377984024
        ]
      },
      "properties": {
        "tenDoiTuon": "HC1/9-11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 69 Nguyễn Phúc Lai",
        "tuDien": "HC1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "HC1",
        "diaChiLapD": "",
        "longitude": 105.82245682196931,
        "latitude": 21.020427377984024,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6347,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82253192382204,
          21.020622667624032
        ]
      },
      "properties": {
        "tenDoiTuon": "HC1/9-10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 69 Nguyễn Phúc Lai",
        "tuDien": "HC1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "HC1",
        "diaChiLapD": "",
        "longitude": 105.82253192382204,
        "latitude": 21.020622667624032,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6348,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80620531771467,
          21.019606157213467
        ]
      },
      "properties": {
        "tenDoiTuon": "36-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 63 Pháo Đài Láng",
        "tuDien": "DDLT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 7,
        "tuDien2": "Di Dân Láng Trung",
        "diaChiLapD": "",
        "longitude": 105.80620531771467,
        "latitude": 21.019606157213467,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6349,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80629383060608,
          21.019529793466663
        ]
      },
      "properties": {
        "tenDoiTuon": "DDLT 36-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 63 Pháo Đài Láng",
        "tuDien": "DDLT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 7,
        "tuDien2": "Di Dân Láng Trung",
        "diaChiLapD": "",
        "longitude": 105.80629383060608,
        "latitude": 21.019529793466663,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6350,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80586467716076,
          21.019319479994103
        ]
      },
      "properties": {
        "tenDoiTuon": "DDLT 35-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 81 Pháo Đài Láng",
        "tuDien": "DDLT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Di Dân Láng Trung",
        "diaChiLapD": "",
        "longitude": 105.80586467716076,
        "latitude": 21.019319479994103,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6351,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8058794293213,
          21.019720076829355
        ]
      },
      "properties": {
        "tenDoiTuon": "45",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 14 Pháo Đài Láng",
        "tuDien": "DDLT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Di Dân Láng Trung",
        "diaChiLapD": "",
        "longitude": 105.8058794293213,
        "latitude": 21.019720076829355,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6352,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80580835078023,
          21.019885322710675
        ]
      },
      "properties": {
        "tenDoiTuon": "45-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 14 Pháo Đài Láng",
        "tuDien": "DDLT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Di Dân Láng Trung",
        "diaChiLapD": "",
        "longitude": 105.80580835078023,
        "latitude": 21.019885322710675,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6353,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80571313236943,
          21.020120672589208
        ]
      },
      "properties": {
        "tenDoiTuon": "46",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 14 Pháo Đài Láng",
        "tuDien": "DDLT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Di Dân Láng Trung",
        "diaChiLapD": "",
        "longitude": 105.80571313236943,
        "latitude": 21.020120672589208,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6354,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80571179126557,
          21.020451163279926
        ]
      },
      "properties": {
        "tenDoiTuon": "47",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 14 Pháo Đài Láng",
        "tuDien": "DDLT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Di Dân Láng Trung",
        "diaChiLapD": "",
        "longitude": 105.80571179126557,
        "latitude": 21.020451163279926,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6355,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8053724918245,
          21.02032722935686
        ]
      },
      "properties": {
        "tenDoiTuon": "48",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 14/25 Pháo Đài Láng",
        "tuDien": "DDLT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Di Dân Láng Trung",
        "diaChiLapD": "",
        "longitude": 105.8053724918245,
        "latitude": 21.02032722935686,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6356,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83868453716828,
          21.017037933932997
        ]
      },
      "properties": {
        "tenDoiTuon": "CN10 6-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Mỹ Quang ,Vạn Ứng",
        "tuDien": "CN10",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "CN10",
        "diaChiLapD": "",
        "longitude": 105.83868453716828,
        "latitude": 21.017037933932997,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6357,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83884386703443,
          21.016933402792887
        ]
      },
      "properties": {
        "tenDoiTuon": "6-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Mỹ Quang ,Vạn Ứng",
        "tuDien": "CN10",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "CN10",
        "diaChiLapD": "",
        "longitude": 105.83884386703443,
        "latitude": 21.016933402792887,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6358,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83874241025333,
          21.016755826462283
        ]
      },
      "properties": {
        "tenDoiTuon": "6-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Mỹ Quang ,Vạn Ứng",
        "tuDien": "CN10",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "CN10",
        "diaChiLapD": "",
        "longitude": 105.83874241025333,
        "latitude": 21.016755826462283,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6359,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83889426745314,
          21.016666202060257
        ]
      },
      "properties": {
        "tenDoiTuon": "6-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Mỹ Quang ,Vạn Ứng",
        "tuDien": "CN10",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "CN10",
        "diaChiLapD": "",
        "longitude": 105.83889426745314,
        "latitude": 21.016666202060257,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6360,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83865946427215,
          21.016607058439025
        ]
      },
      "properties": {
        "tenDoiTuon": "6-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Mỹ Quang ,Vạn Ứng",
        "tuDien": "CN10",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "CN10",
        "diaChiLapD": "",
        "longitude": 105.83865946427215,
        "latitude": 21.016607058439025,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6361,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83872316763212,
          21.016552828713568
        ]
      },
      "properties": {
        "tenDoiTuon": "6-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Mỹ Quang ,Vạn Ứng",
        "tuDien": "CN10",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "CN10",
        "diaChiLapD": "",
        "longitude": 105.83872316763212,
        "latitude": 21.016552828713568,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6362,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8386748878748,
          21.016470203949343
        ]
      },
      "properties": {
        "tenDoiTuon": "6-7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Mỹ Quang ,Vạn Ứng",
        "tuDien": "CN10",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "CN10",
        "diaChiLapD": "",
        "longitude": 105.8386748878748,
        "latitude": 21.016470203949343,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6363,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83916573211619,
          21.016713070549102
        ]
      },
      "properties": {
        "tenDoiTuon": "6-9",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Mỹ Quang ,Vạn Ứng",
        "tuDien": "CN10",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "CN10",
        "diaChiLapD": "",
        "longitude": 105.83916573211619,
        "latitude": 21.016713070549102,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6364,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8390262572507,
          21.016760642311112
        ]
      },
      "properties": {
        "tenDoiTuon": "6-9-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Mỹ Quang ,Vạn Ứng",
        "tuDien": "CN10",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "CN10",
        "diaChiLapD": "",
        "longitude": 105.8390262572507,
        "latitude": 21.016760642311112,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6365,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83910940572665,
          21.016650476102715
        ]
      },
      "properties": {
        "tenDoiTuon": "6-8",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Mỹ Quang ,Vạn Ứng",
        "tuDien": "CN10",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "CN10",
        "diaChiLapD": "",
        "longitude": 105.83910940572665,
        "latitude": 21.016650476102715,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6366,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83896724865342,
          21.016530294691357
        ]
      },
      "properties": {
        "tenDoiTuon": "6-8-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Mỹ Quang ,Vạn Ứng",
        "tuDien": "CN10",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "CN10",
        "diaChiLapD": "",
        "longitude": 105.83896724865342,
        "latitude": 21.016530294691357,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6367,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8391147701601,
          21.016432647223482
        ]
      },
      "properties": {
        "tenDoiTuon": "6-8-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Mỹ Quang ,Vạn Ứng",
        "tuDien": "CN10",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "CN10",
        "diaChiLapD": "",
        "longitude": 105.8391147701601,
        "latitude": 21.016432647223482,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6368,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83899407074883,
          21.01628993157804
        ]
      },
      "properties": {
        "tenDoiTuon": "6-8-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Mỹ Quang ,Vạn Ứng",
        "tuDien": "CN10",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "CN10",
        "diaChiLapD": "",
        "longitude": 105.83899407074883,
        "latitude": 21.01628993157804,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6369,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83930932223291,
          21.016863192925225
        ]
      },
      "properties": {
        "tenDoiTuon": "6-10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Mỹ Quang ,Vạn Ứng",
        "tuDien": "CN10",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "CN10",
        "diaChiLapD": "",
        "longitude": 105.83930932223291,
        "latitude": 21.016863192925225,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6370,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83933202908601,
          21.016758138534893
        ]
      },
      "properties": {
        "tenDoiTuon": "6-9-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Mỹ Quang ,Vạn Ứng",
        "tuDien": "CN10",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "CN10",
        "diaChiLapD": "",
        "longitude": 105.83933202908601,
        "latitude": 21.016758138534893,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6371,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83941517756193,
          21.016963448074424
        ]
      },
      "properties": {
        "tenDoiTuon": "6-11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Mỹ Quang ,Vạn Ứng",
        "tuDien": "CN10",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "CN10",
        "diaChiLapD": "",
        "longitude": 105.83941517756193,
        "latitude": 21.016963448074424,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6372,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83950905487782,
          21.01687080843898
        ]
      },
      "properties": {
        "tenDoiTuon": "6-12",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Mỹ Quang ,Vạn Ứng",
        "tuDien": "CN10",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "CN10",
        "diaChiLapD": "",
        "longitude": 105.83950905487782,
        "latitude": 21.01687080843898,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6373,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83958156754929,
          21.016945266049884
        ]
      },
      "properties": {
        "tenDoiTuon": "6-13",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Mỹ Quang ,Vạn Ứng",
        "tuDien": "CN10",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "CN10",
        "diaChiLapD": "",
        "longitude": 105.83958156754929,
        "latitude": 21.016945266049884,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6374,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83960024998598,
          21.01699349334909
        ]
      },
      "properties": {
        "tenDoiTuon": "6-13-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Mỹ Quang ,Vạn Ứng",
        "tuDien": "CN10",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "CN10",
        "diaChiLapD": "",
        "longitude": 105.83960024998598,
        "latitude": 21.01699349334909,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6375,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83963780091231,
          21.017056087651497
        ]
      },
      "properties": {
        "tenDoiTuon": "6-13-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Mỹ Quang ,Vạn Ứng",
        "tuDien": "CN10",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "CN10",
        "diaChiLapD": "",
        "longitude": 105.83963780091231,
        "latitude": 21.017056087651497,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6376,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83920511243055,
          21.017134104057398
        ]
      },
      "properties": {
        "tenDoiTuon": "6-14",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Mỹ Quang ,Vạn Ứng",
        "tuDien": "CN10",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "CN10",
        "diaChiLapD": "",
        "longitude": 105.83920511243055,
        "latitude": 21.017134104057398,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6377,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83899894342653,
          21.017349654580777
        ]
      },
      "properties": {
        "tenDoiTuon": "6-15",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Mỹ Quang ,Vạn Ứng",
        "tuDien": "CN10",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "CN10",
        "diaChiLapD": "",
        "longitude": 105.83899894342653,
        "latitude": 21.017349654580777,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6378,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83888182768342,
          21.017251762899306
        ]
      },
      "properties": {
        "tenDoiTuon": "6-16",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Mỹ Quang ,Vạn Ứng",
        "tuDien": "CN10",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "CN10",
        "diaChiLapD": "",
        "longitude": 105.83888182768342,
        "latitude": 21.017251762899306,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6379,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83883398756954,
          21.017446049565425
        ]
      },
      "properties": {
        "tenDoiTuon": "6-15-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Mỹ Quang ,Vạn Ứng",
        "tuDien": "CN10",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "CN10",
        "diaChiLapD": "",
        "longitude": 105.83883398756954,
        "latitude": 21.017446049565425,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6380,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83921263009557,
          21.0175414530085
        ]
      },
      "properties": {
        "tenDoiTuon": "6-17",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 202,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Mỹ Quang ,Vạn Ứng",
        "tuDien": "CN10",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "CN10",
        "diaChiLapD": "",
        "longitude": 105.83921263009557,
        "latitude": 21.0175414530085,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6381,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83933339175304,
          21.017687486619796
        ]
      },
      "properties": {
        "tenDoiTuon": "6-17-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 202,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Mỹ Quang ,Vạn Ứng",
        "tuDien": "CN10",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "CN10",
        "diaChiLapD": "",
        "longitude": 105.83933339175304,
        "latitude": 21.017687486619796,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6382,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8393220805007,
          21.017442039217464
        ]
      },
      "properties": {
        "tenDoiTuon": "6-18",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 202,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Mỹ Quang ,Vạn Ứng",
        "tuDien": "CN10",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "CN10",
        "diaChiLapD": "",
        "longitude": 105.8393220805007,
        "latitude": 21.017442039217464,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6383,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83942005023965,
          21.017324616911424
        ]
      },
      "properties": {
        "tenDoiTuon": "6-19",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Mỹ Quang ,Vạn Ứng",
        "tuDien": "CN10",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "CN10",
        "diaChiLapD": "",
        "longitude": 105.83942005023965,
        "latitude": 21.017324616911424,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6384,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83960646376755,
          21.017228221848278
        ]
      },
      "properties": {
        "tenDoiTuon": "CN10 6-20",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Mỹ Quang ,Vạn Ứng",
        "tuDien": "CN10",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "CN10",
        "diaChiLapD": "",
        "longitude": 105.83960646376755,
        "latitude": 21.017228221848278,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6385,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80495943163461,
          21.020110657707225
        ]
      },
      "properties": {
        "tenDoiTuon": "DDLT 49",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 14/25 Pháo Đài Láng",
        "tuDien": "DDLT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 7,
        "tuDien2": "Di Dân Láng Trung",
        "diaChiLapD": "",
        "longitude": 105.80495943163461,
        "latitude": 21.020110657707225,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6386,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80541138384575,
          21.020039301662017
        ]
      },
      "properties": {
        "tenDoiTuon": "50",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 14/25 Pháo Đài Láng",
        "tuDien": "DDLT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Di Dân Láng Trung",
        "diaChiLapD": "",
        "longitude": 105.80541138384575,
        "latitude": 21.020039301662017,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6387,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80521021817526,
          21.01991286367251
        ]
      },
      "properties": {
        "tenDoiTuon": "DDLT 51",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 14/25 Pháo Đài Láng",
        "tuDien": "DDLT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 7,
        "tuDien2": "Di Dân Láng Trung",
        "diaChiLapD": "",
        "longitude": 105.80521021817526,
        "latitude": 21.01991286367251,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6388,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8052088770624,
          21.02063643804296
        ]
      },
      "properties": {
        "tenDoiTuon": "52",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 14/31 Pháo Đài Láng",
        "tuDien": "DDLT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Di Dân Láng Trung",
        "diaChiLapD": "",
        "longitude": 105.8052088770624,
        "latitude": 21.02063643804296,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6389,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80524508688491,
          21.02050874870363
        ]
      },
      "properties": {
        "tenDoiTuon": "52-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 14/25 Pháo Đài Láng",
        "tuDien": "DDLT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 7,
        "tuDien2": "Di Dân Láng Trung",
        "diaChiLapD": "",
        "longitude": 105.80524508688491,
        "latitude": 21.02050874870363,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6390,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80494467947406,
          21.020516259844072
        ]
      },
      "properties": {
        "tenDoiTuon": "DDLT 53",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 14/31 Pháo Đài Láng",
        "tuDien": "DDLT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Di Dân Láng Trung",
        "diaChiLapD": "",
        "longitude": 105.80494467947406,
        "latitude": 21.020516259844072,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6391,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80548514459458,
          21.020791668073322
        ]
      },
      "properties": {
        "tenDoiTuon": "54",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 14/31 Pháo Đài Láng",
        "tuDien": "DDLT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Di Dân Láng Trung",
        "diaChiLapD": "",
        "longitude": 105.80548514459458,
        "latitude": 21.020791668073322,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6392,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82189355808298,
          21.021518993718843
        ]
      },
      "properties": {
        "tenDoiTuon": "HC1/5-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 24 Nguyễn Phúc Lai",
        "tuDien": "HC1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "HC1",
        "diaChiLapD": "",
        "longitude": 105.82189355808298,
        "latitude": 21.021518993718843,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6393,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82219128327709,
          21.02198217687911
        ]
      },
      "properties": {
        "tenDoiTuon": "HC1/5-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 24 Nguyễn Phúc Lai",
        "tuDien": "HC1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "HC1",
        "diaChiLapD": "",
        "longitude": 105.82219128327709,
        "latitude": 21.02198217687911,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6394,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82227979617748,
          21.021962147366256
        ]
      },
      "properties": {
        "tenDoiTuon": "HC1/05",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Nguyễn Phúc Lai",
        "tuDien": "HC1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 10,
        "tuDien2": "HC1",
        "diaChiLapD": "",
        "longitude": 105.82227979617748,
        "latitude": 21.021962147366256,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6395,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82161729055979,
          21.02151649002168
        ]
      },
      "properties": {
        "tenDoiTuon": "HC1/4-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 16 Nguyễn Phúc Lai",
        "tuDien": "HC1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "HC1",
        "diaChiLapD": "",
        "longitude": 105.82161729055979,
        "latitude": 21.02151649002168,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6396,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82196061331247,
          21.02212238338958
        ]
      },
      "properties": {
        "tenDoiTuon": "HC1/4-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 16 Nguyễn Phúc Lai",
        "tuDien": "HC1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "HC1",
        "diaChiLapD": "",
        "longitude": 105.82196061331247,
        "latitude": 21.02212238338958,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6397,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8217326255466,
          21.021744326246456
        ]
      },
      "properties": {
        "tenDoiTuon": "HC1/4-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 16 Nguyễn Phúc Lai",
        "tuDien": "HC1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "HC1",
        "diaChiLapD": "",
        "longitude": 105.8217326255466,
        "latitude": 21.021744326246456,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6398,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82204644400514,
          21.021744326246456
        ]
      },
      "properties": {
        "tenDoiTuon": "HC1/5-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 24 Nguyễn Phúc Lai",
        "tuDien": "HC1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "HC1",
        "diaChiLapD": "",
        "longitude": 105.82204644400514,
        "latitude": 21.021744326246456,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6399,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82165752369391,
          21.02212238338958
        ]
      },
      "properties": {
        "tenDoiTuon": "HC1/3-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 12 Nguyễn Phúc Lai",
        "tuDien": "HC1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "HC1",
        "diaChiLapD": "",
        "longitude": 105.82165752369391,
        "latitude": 21.02212238338958,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6400,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82154218870708,
          21.02191708095416
        ]
      },
      "properties": {
        "tenDoiTuon": "HC1/3-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 12 Nguyễn Phúc Lai",
        "tuDien": "HC1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "HC1",
        "diaChiLapD": "",
        "longitude": 105.82154218870708,
        "latitude": 21.02191708095416,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6401,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82140003163387,
          21.021676726524017
        ]
      },
      "properties": {
        "tenDoiTuon": "HC1/3-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 12 Nguyễn Phúc Lai",
        "tuDien": "HC1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "HC1",
        "diaChiLapD": "",
        "longitude": 105.82140003163387,
        "latitude": 21.021676726524017,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6402,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82136248070752,
          21.021569067643107
        ]
      },
      "properties": {
        "tenDoiTuon": "HC1/3-8",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 12 Nguyễn Phúc Lai",
        "tuDien": "HC1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "HC1",
        "diaChiLapD": "",
        "longitude": 105.82136248070752,
        "latitude": 21.021569067643107,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6403,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82120691257764,
          21.02229513765925
        ]
      },
      "properties": {
        "tenDoiTuon": "HC1/3-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 12 Nguyễn Phúc Lai",
        "tuDien": "HC1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "HC1",
        "diaChiLapD": "",
        "longitude": 105.82120691257764,
        "latitude": 21.02229513765925,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6404,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82110230643077,
          21.022054783838573
        ]
      },
      "properties": {
        "tenDoiTuon": "HC1/3-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 12 Nguyễn Phúc Lai",
        "tuDien": "HC1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "HC1",
        "diaChiLapD": "",
        "longitude": 105.82110230643077,
        "latitude": 21.022054783838573,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6405,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8209950180672,
          21.021819437013857
        ]
      },
      "properties": {
        "tenDoiTuon": "HC1/3-7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 12 Nguyễn Phúc Lai",
        "tuDien": "HC1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "HC1",
        "diaChiLapD": "",
        "longitude": 105.8209950180672,
        "latitude": 21.021819437013857,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6406,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82093600946992,
          21.021664208053558
        ]
      },
      "properties": {
        "tenDoiTuon": "HC1/3-9",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 12 Nguyễn Phúc Lai",
        "tuDien": "HC1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "HC1",
        "diaChiLapD": "",
        "longitude": 105.82093600946992,
        "latitude": 21.021664208053558,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6407,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82179699855935,
          21.02234020395707
        ]
      },
      "properties": {
        "tenDoiTuon": "HC1/3-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 12 Nguyễn Phúc Lai",
        "tuDien": "HC1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "HC1",
        "diaChiLapD": "",
        "longitude": 105.82179699855935,
        "latitude": 21.02234020395707,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6408,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80556426975889,
          21.020646452888773
        ]
      },
      "properties": {
        "tenDoiTuon": "56",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 14/31 Pháo Đài Láng",
        "tuDien": "DDLT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Di Dân Láng Trung",
        "diaChiLapD": "",
        "longitude": 105.80556426975889,
        "latitude": 21.020646452888773,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6409,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80570508573722,
          21.020673993710894
        ]
      },
      "properties": {
        "tenDoiTuon": "DDLT 55",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 14/31 Pháo Đài Láng",
        "tuDien": "DDLT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Di Dân Láng Trung",
        "diaChiLapD": "",
        "longitude": 105.80570508573722,
        "latitude": 21.020673993710894,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6410,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82238138485258,
          21.022113933446736
        ]
      },
      "properties": {
        "tenDoiTuon": "HC1/4-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 23 Nguyễn Phúc Lai",
        "tuDien": "HC1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "BTLT",
        "chieuCao": 5,
        "tuDien2": "HC1",
        "diaChiLapD": "",
        "longitude": 105.82238138485258,
        "latitude": 21.022113933446736,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6411,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8225718216921,
          21.022073874454588
        ]
      },
      "properties": {
        "tenDoiTuon": "HC1/4-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 23 Nguyễn Phúc Lai",
        "tuDien": "HC1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "HC1",
        "diaChiLapD": "",
        "longitude": 105.8225718216921,
        "latitude": 21.022073874454588,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6412,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80522362922295,
          21.0190027573598
        ]
      },
      "properties": {
        "tenDoiTuon": "33-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 103 Pháo Đài Láng",
        "tuDien": "DDLT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Di Dân Láng Trung",
        "diaChiLapD": "",
        "longitude": 105.80522362922295,
        "latitude": 21.0190027573598,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6413,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80542211268569,
          21.01890385964041
        ]
      },
      "properties": {
        "tenDoiTuon": "33-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 103 Pháo Đài Láng",
        "tuDien": "DDLT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Di Dân Láng Trung",
        "diaChiLapD": "",
        "longitude": 105.80542211268569,
        "latitude": 21.01890385964041,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6414,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80521558259078,
          21.018724842082293
        ]
      },
      "properties": {
        "tenDoiTuon": "33-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 103 Pháo Đài Láng",
        "tuDien": "DDLT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Di Dân Láng Trung",
        "diaChiLapD": "",
        "longitude": 105.80521558259078,
        "latitude": 21.018724842082293,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6415,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80557097528725,
          21.01869980464466
        ]
      },
      "properties": {
        "tenDoiTuon": "33-7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 103 Pháo Đài Láng",
        "tuDien": "DDLT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Di Dân Láng Trung",
        "diaChiLapD": "",
        "longitude": 105.80557097528725,
        "latitude": 21.01869980464466,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6416,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80563266609227,
          21.018887585326056
        ]
      },
      "properties": {
        "tenDoiTuon": "33-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 103 Pháo Đài Láng",
        "tuDien": "DDLT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Di Dân Láng Trung",
        "diaChiLapD": "",
        "longitude": 105.80563266609227,
        "latitude": 21.018887585326056,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6417,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80583919618721,
          21.01873110144146
        ]
      },
      "properties": {
        "tenDoiTuon": "33-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 103 Pháo Đài Láng",
        "tuDien": "DDLT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Di Dân Láng Trung",
        "diaChiLapD": "",
        "longitude": 105.80583919618721,
        "latitude": 21.01873110144146,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6418,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80598939989262,
          21.018554587422877
        ]
      },
      "properties": {
        "tenDoiTuon": "DDLT33-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 103 Pháo Đài Láng",
        "tuDien": "DDLT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Di Dân Láng Trung",
        "diaChiLapD": "",
        "longitude": 105.80598939989262,
        "latitude": 21.018554587422877,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6419,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82265765237956,
          21.021665772861272
        ]
      },
      "properties": {
        "tenDoiTuon": "HC1/6-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 39 Nguyễn Phúc Lai",
        "tuDien": "HC1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 8,
        "tuDien2": "HC1",
        "diaChiLapD": "",
        "longitude": 105.82265765237956,
        "latitude": 21.021665772861272,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6420,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8224564867001,
          21.02008593346178
        ]
      },
      "properties": {
        "tenDoiTuon": "HC1/43",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 34 Hoàng Cầu",
        "tuDien": "HC1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "HC1",
        "diaChiLapD": "",
        "longitude": 105.8224564867001,
        "latitude": 21.02008593346178,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6421,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82208097743654,
          21.020088437182974
        ]
      },
      "properties": {
        "tenDoiTuon": "HC1/42",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 34 Hoàng Cầu",
        "tuDien": "HC1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "HC1",
        "diaChiLapD": "",
        "longitude": 105.82208097743654,
        "latitude": 21.020088437182974,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6422,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82273543644,
          21.02003085159608
        ]
      },
      "properties": {
        "tenDoiTuon": "HC1/44",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 34 Hoàng Cầu",
        "tuDien": "HC1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "HC1",
        "diaChiLapD": "",
        "longitude": 105.82273543644,
        "latitude": 21.02003085159608,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6423,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82297147082909,
          21.01999329576702
        ]
      },
      "properties": {
        "tenDoiTuon": "HC1/45",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 34 Hoàng Cầu",
        "tuDien": "HC1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "HC1",
        "diaChiLapD": "",
        "longitude": 105.82297147082909,
        "latitude": 21.01999329576702,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6424,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82333625126168,
          21.020013325543527
        ]
      },
      "properties": {
        "tenDoiTuon": "HC1/46",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 34 Hoàng Cầu",
        "tuDien": "HC1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "HC1",
        "diaChiLapD": "",
        "longitude": 105.82333625126168,
        "latitude": 21.020013325543527,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6425,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82292319107177,
          21.020311268156025
        ]
      },
      "properties": {
        "tenDoiTuon": "HC1/45-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 34 Hoàng Cầu",
        "tuDien": "HC1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "HC1",
        "diaChiLapD": "",
        "longitude": 105.82292319107177,
        "latitude": 21.020311268156025,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6426,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8229446487427,
          21.02054912107401
        ]
      },
      "properties": {
        "tenDoiTuon": "HC1/45-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 34 Hoàng Cầu",
        "tuDien": "HC1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "HC1",
        "diaChiLapD": "",
        "longitude": 105.8229446487427,
        "latitude": 21.02054912107401,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6427,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82361788320932,
          21.019950732482464
        ]
      },
      "properties": {
        "tenDoiTuon": "HC1/47",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 34 Hoàng Cầu",
        "tuDien": "HC1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "HC1",
        "diaChiLapD": "",
        "longitude": 105.82361788320932,
        "latitude": 21.019950732482464,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6428,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82391560840344,
          21.019752938235598
        ]
      },
      "properties": {
        "tenDoiTuon": "HC1/48",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 34 Hoàng Cầu",
        "tuDien": "HC1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "HC1",
        "diaChiLapD": "",
        "longitude": 105.82391560840344,
        "latitude": 21.019752938235598,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6429,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82460493612551,
          21.01929475561832
        ]
      },
      "properties": {
        "tenDoiTuon": "HC1/51",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 34 Hoàng Cầu",
        "tuDien": "HC1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "HC1",
        "diaChiLapD": "",
        "longitude": 105.82460493612551,
        "latitude": 21.01929475561832,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6430,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8243876771906,
          21.01944247585048
        ]
      },
      "properties": {
        "tenDoiTuon": "HC1/50",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 34 Hoàng Cầu",
        "tuDien": "HC1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "HC1",
        "diaChiLapD": "",
        "longitude": 105.8243876771906,
        "latitude": 21.01944247585048,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6431,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82416773605695,
          21.019590195937145
        ]
      },
      "properties": {
        "tenDoiTuon": "HC1/49",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 34 Hoàng Cầu",
        "tuDien": "HC1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "HC1",
        "diaChiLapD": "",
        "longitude": 105.82416773605695,
        "latitude": 21.019590195937145,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6432,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82342744636082,
          21.020183578538216
        ]
      },
      "properties": {
        "tenDoiTuon": "HC1/46-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 34 Hoàng Cầu",
        "tuDien": "HC1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "HC1",
        "diaChiLapD": "",
        "longitude": 105.82342744636082,
        "latitude": 21.020183578538216,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6433,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8235132770535,
          21.020313771872587
        ]
      },
      "properties": {
        "tenDoiTuon": "HC1/46-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 34 Hoàng Cầu",
        "tuDien": "HC1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "HC1",
        "diaChiLapD": "",
        "longitude": 105.8235132770535,
        "latitude": 21.020313771872587,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6434,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82332552242171,
          21.020346320189045
        ]
      },
      "properties": {
        "tenDoiTuon": "HC1/46-7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 34 Hoàng Cầu",
        "tuDien": "HC1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "HC1",
        "diaChiLapD": "",
        "longitude": 105.82332552242171,
        "latitude": 21.020346320189045,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6435,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82321823405813,
          21.020456483667015
        ]
      },
      "properties": {
        "tenDoiTuon": "HC1/46-8",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 34 Hoàng Cầu",
        "tuDien": "HC1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "HC1",
        "diaChiLapD": "",
        "longitude": 105.82321823405813,
        "latitude": 21.020456483667015,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6436,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82361084242073,
          21.020668986281457
        ]
      },
      "properties": {
        "tenDoiTuon": "HC1/46-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 34 Hoàng Cầu",
        "tuDien": "HC1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "HC1",
        "diaChiLapD": "",
        "longitude": 105.82361084242073,
        "latitude": 21.020668986281457,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6437,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8235615568198,
          21.02053409879607
        ]
      },
      "properties": {
        "tenDoiTuon": "HC1/46-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 34 Hoàng Cầu",
        "tuDien": "HC1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "HC1",
        "diaChiLapD": "",
        "longitude": 105.8235615568198,
        "latitude": 21.02053409879607,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6438,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82394243049882,
          21.020010821821938
        ]
      },
      "properties": {
        "tenDoiTuon": "HC1/46-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 34 Hoàng Cầu",
        "tuDien": "HC1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "HC1",
        "diaChiLapD": "",
        "longitude": 105.82394243049882,
        "latitude": 21.020010821821938,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6439,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82373858261161,
          21.020188585975568
        ]
      },
      "properties": {
        "tenDoiTuon": "HC1/46-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 34 Hoàng Cầu",
        "tuDien": "HC1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "HC1",
        "diaChiLapD": "",
        "longitude": 105.82373858261161,
        "latitude": 21.020188585975568,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6440,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80426742172276,
          21.017608168271263
        ]
      },
      "properties": {
        "tenDoiTuon": "03",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 159 Pháo Đài Láng",
        "tuDien": "DDLT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Di Dân Láng Trung",
        "diaChiLapD": "",
        "longitude": 105.80426742172276,
        "latitude": 21.017608168271263,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6441,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80451284384795,
          21.01734527299617
        ]
      },
      "properties": {
        "tenDoiTuon": "04",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 159 Pháo Đài Láng",
        "tuDien": "DDLT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Di Dân Láng Trung",
        "diaChiLapD": "",
        "longitude": 105.80451284384795,
        "latitude": 21.01734527299617,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6442,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8047331202598,
          21.017110231709605
        ]
      },
      "properties": {
        "tenDoiTuon": "05",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 159 Pháo Đài Láng",
        "tuDien": "DDLT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Di Dân Láng Trung",
        "diaChiLapD": "",
        "longitude": 105.8047331202598,
        "latitude": 21.017110231709605,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6443,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80493026262765,
          21.016912433694145
        ]
      },
      "properties": {
        "tenDoiTuon": "06",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 159 Pháo Đài Láng",
        "tuDien": "DDLT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Di Dân Láng Trung",
        "diaChiLapD": "",
        "longitude": 105.80493026262765,
        "latitude": 21.016912433694145,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6444,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80516897923347,
          21.01668459008632
        ]
      },
      "properties": {
        "tenDoiTuon": "07",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 159 Pháo Đài Láng",
        "tuDien": "DDLT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Di Dân Láng Trung",
        "diaChiLapD": "",
        "longitude": 105.80516897923347,
        "latitude": 21.01668459008632,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6445,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80544658786951,
          21.01640291481564
        ]
      },
      "properties": {
        "tenDoiTuon": "7-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 159 Pháo Đài Láng",
        "tuDien": "DDLT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Di Dân Láng Trung",
        "diaChiLapD": "",
        "longitude": 105.80544658786951,
        "latitude": 21.01640291481564,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6446,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80527760870092,
          21.016228901827194
        ]
      },
      "properties": {
        "tenDoiTuon": "DDLT  7-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 159 Pháo Đài Láng",
        "tuDien": "DDLT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Di Dân Láng Trung",
        "diaChiLapD": "",
        "longitude": 105.80527760870092,
        "latitude": 21.016228901827194,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6447,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.838472264191,
          21.01748885964408
        ]
      },
      "properties": {
        "tenDoiTuon": "CN10 7-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 80 Phố Chợ Khâm Thiên",
        "tuDien": "CN10",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "CN10",
        "diaChiLapD": "",
        "longitude": 105.838472264191,
        "latitude": 21.01748885964408,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6448,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8382150300634,
          21.017563874547808
        ]
      },
      "properties": {
        "tenDoiTuon": "7-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 80 Phố Chợ Khâm Thiên",
        "tuDien": "CN10",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "CN10",
        "diaChiLapD": "",
        "longitude": 105.8382150300634,
        "latitude": 21.017563874547808,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6449,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83802353950713,
          21.017673401328636
        ]
      },
      "properties": {
        "tenDoiTuon": "7-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 80 Phố Chợ Khâm Thiên",
        "tuDien": "CN10",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "CN10",
        "diaChiLapD": "",
        "longitude": 105.83802353950713,
        "latitude": 21.017673401328636,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6450,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83785149427604,
          21.017780197624276
        ]
      },
      "properties": {
        "tenDoiTuon": "7-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 80 Phố Chợ Khâm Thiên",
        "tuDien": "CN10",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "CN10",
        "diaChiLapD": "",
        "longitude": 105.83785149427604,
        "latitude": 21.017780197624276,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6451,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.837618862045,
          21.017923088776403
        ]
      },
      "properties": {
        "tenDoiTuon": "7-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 80 Phố Chợ Khâm Thiên",
        "tuDien": "CN10",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "CN10",
        "diaChiLapD": "",
        "longitude": 105.837618862045,
        "latitude": 21.017923088776403,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6452,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83740220068647,
          21.018042354895613
        ]
      },
      "properties": {
        "tenDoiTuon": "00",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 80 Phố Chợ Khâm Thiên",
        "tuDien": "CN10",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "CN10",
        "diaChiLapD": "",
        "longitude": 105.83740220068647,
        "latitude": 21.018042354895613,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6453,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83726064860817,
          21.0181514836986
        ]
      },
      "properties": {
        "tenDoiTuon": "CN10 00",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 80 Phố Chợ Khâm Thiên",
        "tuDien": "CN10",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "CN10",
        "diaChiLapD": "",
        "longitude": 105.83726064860817,
        "latitude": 21.0181514836986,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6454,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83815650641621,
          21.01744041609582
        ]
      },
      "properties": {
        "tenDoiTuon": "7-2-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 80 Phố Chợ Khâm Thiên",
        "tuDien": "CN10",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "CN10",
        "diaChiLapD": "",
        "longitude": 105.83815650641621,
        "latitude": 21.01744041609582,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6455,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80485247858519,
          21.016752192072804
        ]
      },
      "properties": {
        "tenDoiTuon": "DDLT 6-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 159 Pháo Đài Láng",
        "tuDien": "DDLT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 7,
        "tuDien2": "Di Dân Láng Trung",
        "diaChiLapD": "",
        "longitude": 105.80485247858519,
        "latitude": 21.016752192072804,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6456,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80431737788012,
          21.017182841040633
        ]
      },
      "properties": {
        "tenDoiTuon": "DDLT 4-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 159 Pháo Đài Láng",
        "tuDien": "DDLT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Di Dân Láng Trung",
        "diaChiLapD": "",
        "longitude": 105.80431737788012,
        "latitude": 21.017182841040633,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6457,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82365543413569,
          21.020892129352198
        ]
      },
      "properties": {
        "tenDoiTuon": "HC1/33-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 16 Hoàng Cầu",
        "tuDien": "HC1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "HC1",
        "diaChiLapD": "",
        "longitude": 105.82365543413569,
        "latitude": 21.020892129352198,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6458,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82343817520079,
          21.020947210899713
        ]
      },
      "properties": {
        "tenDoiTuon": "HC1/32-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 16 Hoàng Cầu",
        "tuDien": "HC1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "HC1",
        "diaChiLapD": "",
        "longitude": 105.82343817520079,
        "latitude": 21.020947210899713,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6459,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82318604755626,
          21.020949714605603
        ]
      },
      "properties": {
        "tenDoiTuon": "HC1/32-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 16 Hoàng Cầu",
        "tuDien": "HC1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "HC1",
        "diaChiLapD": "",
        "longitude": 105.82318604755626,
        "latitude": 21.020949714605603,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6460,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82338453101899,
          21.02112998134357
        ]
      },
      "properties": {
        "tenDoiTuon": "HC1/32",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 16 Hoàng Cầu",
        "tuDien": "HC1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "HC1",
        "diaChiLapD": "",
        "longitude": 105.82338453101899,
        "latitude": 21.02112998134357,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6461,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82349450159032,
          21.02130524046301
        ]
      },
      "properties": {
        "tenDoiTuon": "HC1/32-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 16 Hoàng Cầu",
        "tuDien": "HC1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "HC1",
        "diaChiLapD": "",
        "longitude": 105.82349450159032,
        "latitude": 21.02130524046301,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6462,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82281858491594,
          21.021260173852372
        ]
      },
      "properties": {
        "tenDoiTuon": "HC1/31",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 16 Hoàng Cầu",
        "tuDien": "HC1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "HC1",
        "diaChiLapD": "",
        "longitude": 105.82281858491594,
        "latitude": 21.021260173852372,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6463,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82341939973759,
          21.0214529587042
        ]
      },
      "properties": {
        "tenDoiTuon": "HC1/31-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 16 Hoàng Cầu",
        "tuDien": "HC1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "HC1",
        "diaChiLapD": "",
        "longitude": 105.82341939973759,
        "latitude": 21.0214529587042,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6464,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82260937261324,
          21.021415403233316
        ]
      },
      "properties": {
        "tenDoiTuon": "HC1/29",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 16 Hoàng Cầu",
        "tuDien": "HC1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "HC1",
        "diaChiLapD": "",
        "longitude": 105.82260937261324,
        "latitude": 21.021415403233316,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6465,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82248330879547,
          21.021422914328063
        ]
      },
      "properties": {
        "tenDoiTuon": "HC1/28",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 16 Hoàng Cầu",
        "tuDien": "HC1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "HC1",
        "diaChiLapD": "",
        "longitude": 105.82248330879547,
        "latitude": 21.021422914328063,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6466,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82269788551363,
          21.021315255263914
        ]
      },
      "properties": {
        "tenDoiTuon": "HC1/30",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 16 Hoàng Cầu",
        "tuDien": "HC1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "HC1",
        "diaChiLapD": "",
        "longitude": 105.82269788551363,
        "latitude": 21.021315255263914,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6467,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8236903028453,
          21.021067388751284
        ]
      },
      "properties": {
        "tenDoiTuon": "HC1/",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 16 Hoàng Cầu",
        "tuDien": "HC1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "HC1",
        "diaChiLapD": "",
        "longitude": 105.8236903028453,
        "latitude": 21.021067388751284,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6468,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82356423902753,
          21.021069892455127
        ]
      },
      "properties": {
        "tenDoiTuon": "HC1/33",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 16 Hoàng Cầu",
        "tuDien": "HC1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "HC1",
        "diaChiLapD": "",
        "longitude": 105.82356423902753,
        "latitude": 21.021069892455127,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6469,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82380832004883,
          21.020874603400877
        ]
      },
      "properties": {
        "tenDoiTuon": "HC1/34",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 16 Hoàng Cầu",
        "tuDien": "HC1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "HC1",
        "diaChiLapD": "",
        "longitude": 105.82380832004883,
        "latitude": 21.020874603400877,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6470,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8239933924639,
          21.020736899426442
        ]
      },
      "properties": {
        "tenDoiTuon": "HC1/35",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 16 Hoàng Cầu",
        "tuDien": "HC1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "HC1",
        "diaChiLapD": "",
        "longitude": 105.8239933924639,
        "latitude": 21.020736899426442,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6471,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82397729921743,
          21.020875855254005
        ]
      },
      "properties": {
        "tenDoiTuon": "HC1/35-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 16 Hoàng Cầu",
        "tuDien": "HC1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "HC1",
        "diaChiLapD": "",
        "longitude": 105.82397729921743,
        "latitude": 21.020875855254005,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6472,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82410604524294,
          21.020574158201935
        ]
      },
      "properties": {
        "tenDoiTuon": "HC1/36",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 16 Hoàng Cầu",
        "tuDien": "HC1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "HC1",
        "diaChiLapD": "",
        "longitude": 105.82410604524294,
        "latitude": 21.020574158201935,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6473,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82418382930341,
          21.0207293882963
        ]
      },
      "properties": {
        "tenDoiTuon": "HC1/36-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 16 Hoàng Cầu",
        "tuDien": "HC1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "HC1",
        "diaChiLapD": "",
        "longitude": 105.82418382930341,
        "latitude": 21.0207293882963,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6474,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82437694835964,
          21.020646765847097
        ]
      },
      "properties": {
        "tenDoiTuon": "HC1/36-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 16 Hoàng Cầu",
        "tuDien": "HC1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "HC1",
        "diaChiLapD": "",
        "longitude": 105.82437694835964,
        "latitude": 21.020646765847097,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6475,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82454592752822,
          21.020559135925673
        ]
      },
      "properties": {
        "tenDoiTuon": "HC1/36-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 16 Hoàng Cầu",
        "tuDien": "HC1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "HC1",
        "diaChiLapD": "",
        "longitude": 105.82454592752822,
        "latitude": 21.020559135925673,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6476,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82437158393519,
          21.02095722572381
        ]
      },
      "properties": {
        "tenDoiTuon": "HC1/35-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 16 Hoàng Cầu",
        "tuDien": "HC1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "HC1",
        "diaChiLapD": "",
        "longitude": 105.82437158393519,
        "latitude": 21.02095722572381,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6477,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82456738519916,
          21.02086208486309
        ]
      },
      "properties": {
        "tenDoiTuon": "HC1/35-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 16 Hoàng Cầu",
        "tuDien": "HC1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "HC1",
        "diaChiLapD": "",
        "longitude": 105.82456738519916,
        "latitude": 21.02086208486309,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6478,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82469613123365,
          21.020726884586704
        ]
      },
      "properties": {
        "tenDoiTuon": "HC1/35-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 16 Hoàng Cầu",
        "tuDien": "HC1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "HC1",
        "diaChiLapD": "",
        "longitude": 105.82469613123365,
        "latitude": 21.020726884586704,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6479,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8241033630442,
          21.021048610968016
        ]
      },
      "properties": {
        "tenDoiTuon": "HC1/35-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 16 Hoàng Cầu",
        "tuDien": "HC1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "HC1",
        "diaChiLapD": "",
        "longitude": 105.8241033630442,
        "latitude": 21.021048610968016,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6480,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.824311234243,
          21.021213855376953
        ]
      },
      "properties": {
        "tenDoiTuon": "HC1/35-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 16 Hoàng Cầu",
        "tuDien": "HC1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "HC1",
        "diaChiLapD": "",
        "longitude": 105.824311234243,
        "latitude": 21.021213855376953,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6481,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82450167108253,
          21.021119966531053
        ]
      },
      "properties": {
        "tenDoiTuon": "HC1/35-7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 16 Hoàng Cầu",
        "tuDien": "HC1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "HC1",
        "diaChiLapD": "",
        "longitude": 105.82450167108253,
        "latitude": 21.021119966531053,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6482,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82471893000844,
          21.02100479613186
        ]
      },
      "properties": {
        "tenDoiTuon": "HC1/35-8",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 16 Hoàng Cầu",
        "tuDien": "HC1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "HC1",
        "diaChiLapD": "",
        "longitude": 105.82471893000844,
        "latitude": 21.02100479613186,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6483,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82418919372788,
          21.02030751257984
        ]
      },
      "properties": {
        "tenDoiTuon": "HC1/37",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 16 Hoàng Cầu",
        "tuDien": "HC1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "HC1",
        "diaChiLapD": "",
        "longitude": 105.82418919372788,
        "latitude": 21.02030751257984,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6484,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82390219736472,
          21.020382624071086
        ]
      },
      "properties": {
        "tenDoiTuon": "HC1/37-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 16 Hoàng Cầu",
        "tuDien": "HC1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "HC1",
        "diaChiLapD": "",
        "longitude": 105.82390219736472,
        "latitude": 21.020382624071086,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6485,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82403899002246,
          21.020137259727502
        ]
      },
      "properties": {
        "tenDoiTuon": "HC1/37-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 16 Hoàng Cầu",
        "tuDien": "HC1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "HC1",
        "diaChiLapD": "",
        "longitude": 105.82403899002246,
        "latitude": 21.020137259727502,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6486,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82465053368408,
          21.0200596443919
        ]
      },
      "properties": {
        "tenDoiTuon": "HC1/39",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 16 Hoàng Cầu",
        "tuDien": "HC1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "HC1",
        "diaChiLapD": "",
        "longitude": 105.82465053368408,
        "latitude": 21.0200596443919,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6487,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82437963056738,
          21.020207363867115
        ]
      },
      "properties": {
        "tenDoiTuon": "HC1/38",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 16 Hoàng Cầu",
        "tuDien": "HC1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "HC1",
        "diaChiLapD": "",
        "longitude": 105.82437963056738,
        "latitude": 21.020207363867115,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6488,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82469613123365,
          21.020154785765502
        ]
      },
      "properties": {
        "tenDoiTuon": "HC1/40",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 16 Hoàng Cầu",
        "tuDien": "HC1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "HC1",
        "diaChiLapD": "",
        "longitude": 105.82469613123365,
        "latitude": 21.020154785765502,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6489,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82490802574408,
          21.020024592291488
        ]
      },
      "properties": {
        "tenDoiTuon": "HC1/41",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 16 Hoàng Cầu",
        "tuDien": "HC1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "HC1",
        "diaChiLapD": "",
        "longitude": 105.82490802574408,
        "latitude": 21.020024592291488,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6490,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82214660538676,
          21.023479823582377
        ]
      },
      "properties": {
        "tenDoiTuon": "LAT5 14-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 418 La Thành",
        "tuDien": "LAT5",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "LAT5",
        "diaChiLapD": "",
        "longitude": 105.82214660538676,
        "latitude": 21.023479823582377,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6491,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82221097839953,
          21.023745211700152
        ]
      },
      "properties": {
        "tenDoiTuon": "LAT5 14-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 418 La Thành",
        "tuDien": "LAT5",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "LAT5",
        "diaChiLapD": "",
        "longitude": 105.82221097839953,
        "latitude": 21.023745211700152,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6492,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82233704222628,
          21.023943000650675
        ]
      },
      "properties": {
        "tenDoiTuon": "LAT5 14-7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 418 La Thành",
        "tuDien": "LAT5",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "LAT5",
        "diaChiLapD": "",
        "longitude": 105.82233704222628,
        "latitude": 21.023943000650675,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6493,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8224389661654,
          21.024210891595843
        ]
      },
      "properties": {
        "tenDoiTuon": "LAT5 14-8",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 418 La Thành",
        "tuDien": "LAT5",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "LAT5",
        "diaChiLapD": "",
        "longitude": 105.8224389661654,
        "latitude": 21.024210891595843,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6494,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82253820790574,
          21.024413687223937
        ]
      },
      "properties": {
        "tenDoiTuon": "LAT5 14-9",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 418 La Thành",
        "tuDien": "LAT5",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "LAT5",
        "diaChiLapD": "",
        "longitude": 105.82253820790574,
        "latitude": 21.024413687223937,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6495,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82286275519523,
          21.024904401431282
        ]
      },
      "properties": {
        "tenDoiTuon": "LAT5 14-11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 418 La Thành",
        "tuDien": "LAT5",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "LAT5",
        "diaChiLapD": "",
        "longitude": 105.82286275519523,
        "latitude": 21.024904401431282,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6496,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82268841160219,
          21.024716628328772
        ]
      },
      "properties": {
        "tenDoiTuon": "LAT5 14-10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 418 La Thành",
        "tuDien": "LAT5",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "LAT5",
        "diaChiLapD": "",
        "longitude": 105.82268841160219,
        "latitude": 21.024716628328772,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6497,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82311220063202,
          21.02489438687149
        ]
      },
      "properties": {
        "tenDoiTuon": "LAT5 14-12",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 418 La Thành",
        "tuDien": "LAT5",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "LAT5",
        "diaChiLapD": "",
        "longitude": 105.82311220063202,
        "latitude": 21.02489438687149,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6498,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82317389143704,
          21.025054619743152
        ]
      },
      "properties": {
        "tenDoiTuon": "LAT5 14-13",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 418 La Thành",
        "tuDien": "LAT5",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "LAT5",
        "diaChiLapD": "",
        "longitude": 105.82317389143704,
        "latitude": 21.025054619743152,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6499,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82334823502111,
          21.025037094281167
        ]
      },
      "properties": {
        "tenDoiTuon": "LAT5 14-14",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 418 La Thành",
        "tuDien": "LAT5",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "LAT5",
        "diaChiLapD": "",
        "longitude": 105.82334823502111,
        "latitude": 21.025037094281167,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6500,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82340724361839,
          21.025219859711797
        ]
      },
      "properties": {
        "tenDoiTuon": "LAT5 14-15",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 418 La Thành",
        "tuDien": "LAT5",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "LAT5",
        "diaChiLapD": "",
        "longitude": 105.82340724361839,
        "latitude": 21.025219859711797,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6501,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82325972212969,
          21.02483179586051
        ]
      },
      "properties": {
        "tenDoiTuon": "LAT5 14-16",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 418 La Thành",
        "tuDien": "LAT5",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "LAT5",
        "diaChiLapD": "",
        "longitude": 105.82325972212969,
        "latitude": 21.02483179586051,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6502,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82036201675264,
          21.002869043207465
        ]
      },
      "properties": {
        "tenDoiTuon": "NTS1 01",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 9,
        "maXaPhuong": 349,
        "loaiChaoCh": "Đường phố - đèn pha",
        "tenDuongPh": "Nút GT Ngã Tư Sở",
        "tuDien": "CVNTS1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 25,
        "tuDien2": "CV NTS Tủ 1",
        "diaChiLapD": "",
        "longitude": 105.82036201675264,
        "latitude": 21.002869043207465,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6503,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82013134677902,
          21.003272188224646
        ]
      },
      "properties": {
        "tenDoiTuon": "NTS1 03",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Đường phố - đèn pha",
        "tenDuongPh": "Nút GT Ngã Tư Sở",
        "tuDien": "CVNTS1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 25,
        "tuDien2": "CV NTS Tủ 1",
        "diaChiLapD": "",
        "longitude": 105.82013134677902,
        "latitude": 21.003272188224646,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6504,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82057391127205,
          21.003101915928138
        ]
      },
      "properties": {
        "tenDoiTuon": "NTS1 02",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Đường phố - đèn pha",
        "tenDuongPh": "Nút GT Ngã Tư Sở",
        "tuDien": "CVNTS1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 25,
        "tuDien2": "CV NTS Tủ 1",
        "diaChiLapD": "",
        "longitude": 105.82057391127205,
        "latitude": 21.003101915928138,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6505,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82073216160067,
          21.00277389080837
        ]
      },
      "properties": {
        "tenDoiTuon": "04",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 9,
        "maXaPhuong": 349,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Nút GT Ngã Tư Sở",
        "tuDien": "CVNTS1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "DC-05",
        "chieuCao": 3,
        "tuDien2": "CV NTS Tủ 1",
        "diaChiLapD": "",
        "longitude": 105.82073216160067,
        "latitude": 21.00277389080837,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6506,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82060877998168,
          21.002751354705627
        ]
      },
      "properties": {
        "tenDoiTuon": "05",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 9,
        "maXaPhuong": 349,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Nút GT Ngã Tư Sở",
        "tuDien": "CVNTS1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "DC-05",
        "chieuCao": 3,
        "tuDien2": "CV NTS Tủ 1",
        "diaChiLapD": "",
        "longitude": 105.82060877998168,
        "latitude": 21.002751354705627,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6507,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82052563150575,
          21.002701274463163
        ]
      },
      "properties": {
        "tenDoiTuon": "06",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 9,
        "maXaPhuong": 349,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Nút GT Ngã Tư Sở",
        "tuDien": "CVNTS1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "DC-05",
        "chieuCao": 3,
        "tuDien2": "CV NTS Tủ 1",
        "diaChiLapD": "",
        "longitude": 105.82052563150575,
        "latitude": 21.002701274463163,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6508,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82042471340127,
          21.00266903529833
        ]
      },
      "properties": {
        "tenDoiTuon": "07",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 9,
        "maXaPhuong": 349,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Nút GT Ngã Tư Sở",
        "tuDien": "CVNTS1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "DC-05",
        "chieuCao": 3,
        "tuDien2": "CV NTS Tủ 1",
        "diaChiLapD": "",
        "longitude": 105.82042471340127,
        "latitude": 21.00266903529833,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6509,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82031105478757,
          21.002623650054932
        ]
      },
      "properties": {
        "tenDoiTuon": "08",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 9,
        "maXaPhuong": 349,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Nút GT Ngã Tư Sở",
        "tuDien": "CVNTS1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "DC-05",
        "chieuCao": 3,
        "tuDien2": "CV NTS Tủ 1",
        "diaChiLapD": "",
        "longitude": 105.82031105478757,
        "latitude": 21.002623650054932,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6510,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82021181304722,
          21.0025910978721
        ]
      },
      "properties": {
        "tenDoiTuon": "09",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 9,
        "maXaPhuong": 349,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Nút GT Ngã Tư Sở",
        "tuDien": "CVNTS1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "DC-05",
        "chieuCao": 3,
        "tuDien2": "CV NTS Tủ 1",
        "diaChiLapD": "",
        "longitude": 105.82021181304722,
        "latitude": 21.0025910978721,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6511,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82071305086829,
          21.003212405142
        ]
      },
      "properties": {
        "tenDoiTuon": "13",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Vườn hoa Ngã Tư Sở",
        "tuDien": "CVNTS1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "DC-05",
        "chieuCao": 3,
        "tuDien2": "CV NTS Tủ 1",
        "diaChiLapD": "",
        "longitude": 105.82071305086829,
        "latitude": 21.003212405142,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6512,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82074557264839,
          21.003146988026018
        ]
      },
      "properties": {
        "tenDoiTuon": "11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Vườn hoa Ngã Tư Sở",
        "tuDien": "CVNTS1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "DC-05",
        "chieuCao": 3,
        "tuDien2": "CV NTS Tủ 1",
        "diaChiLapD": "",
        "longitude": 105.82074557264839,
        "latitude": 21.003146988026018,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6513,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82066510637121,
          21.00312194797285
        ]
      },
      "properties": {
        "tenDoiTuon": "12",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Vườn hoa Ngã Tư Sở",
        "tuDien": "CVNTS1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "DC-05",
        "chieuCao": 3,
        "tuDien2": "CV NTS Tủ 1",
        "diaChiLapD": "",
        "longitude": 105.82066510637121,
        "latitude": 21.00312194797285,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6514,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82074825485613,
          21.00306685984277
        ]
      },
      "properties": {
        "tenDoiTuon": "10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Vườn hoa Ngã Tư Sở",
        "tuDien": "CVNTS1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "DC-05",
        "chieuCao": 3,
        "tuDien2": "CV NTS Tủ 1",
        "diaChiLapD": "",
        "longitude": 105.82074825485613,
        "latitude": 21.00306685984277,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6515,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81995365042178,
          21.003381738312896
        ]
      },
      "properties": {
        "tenDoiTuon": "16",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Vườn hoa Ngã Tư Sở",
        "tuDien": "CVNTS1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "DC-05",
        "chieuCao": 3,
        "tuDien2": "CV NTS Tủ 1",
        "diaChiLapD": "",
        "longitude": 105.81995365042178,
        "latitude": 21.003381738312896,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6516,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82013402898674,
          21.003427436326092
        ]
      },
      "properties": {
        "tenDoiTuon": "15",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Vườn hoa Ngã Tư Sở",
        "tuDien": "CVNTS1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "DC-05",
        "chieuCao": 3,
        "tuDien2": "CV NTS Tủ 1",
        "diaChiLapD": "",
        "longitude": 105.82013402898674,
        "latitude": 21.003427436326092,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6517,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82028222107678,
          21.003475012327364
        ]
      },
      "properties": {
        "tenDoiTuon": "14",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Vườn hoa Ngã Tư Sở",
        "tuDien": "CVNTS1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "DC-05",
        "chieuCao": 3,
        "tuDien2": "CV NTS Tủ 1",
        "diaChiLapD": "",
        "longitude": 105.82028222107678,
        "latitude": 21.003475012327364,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6518,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81936021166692,
          21.003055591813098
        ]
      },
      "properties": {
        "tenDoiTuon": "21",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Nút GT Ngã Tư Sở",
        "tuDien": "CVNTS1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "DC-05",
        "chieuCao": 3,
        "tuDien2": "CV NTS Tủ 1",
        "diaChiLapD": "",
        "longitude": 105.81936021166692,
        "latitude": 21.003055591813098,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6519,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81927035766265,
          21.003011771692346
        ]
      },
      "properties": {
        "tenDoiTuon": "20",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Nút GT Ngã Tư Sở",
        "tuDien": "CVNTS1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "DC-05",
        "chieuCao": 3,
        "tuDien2": "CV NTS Tủ 1",
        "diaChiLapD": "",
        "longitude": 105.81927035766265,
        "latitude": 21.003011771692346,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6520,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81918855029058,
          21.003058095820148
        ]
      },
      "properties": {
        "tenDoiTuon": "19",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Nút GT Ngã Tư Sở",
        "tuDien": "CVNTS1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "DC-05",
        "chieuCao": 3,
        "tuDien2": "CV NTS Tủ 1",
        "diaChiLapD": "",
        "longitude": 105.81918855029058,
        "latitude": 21.003058095820148,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6521,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81919927912156,
          21.003144484020467
        ]
      },
      "properties": {
        "tenDoiTuon": "18",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Nút GT Ngã Tư Sở",
        "tuDien": "CVNTS1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "DC-05",
        "chieuCao": 3,
        "tuDien2": "CV NTS Tủ 1",
        "diaChiLapD": "",
        "longitude": 105.81919927912156,
        "latitude": 21.003144484020467,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6522,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81930120306964,
          21.003110679947337
        ]
      },
      "properties": {
        "tenDoiTuon": "17",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Nút GT Ngã Tư Sở",
        "tuDien": "CVNTS1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "DC-05",
        "chieuCao": 3,
        "tuDien2": "CV NTS Tủ 1",
        "diaChiLapD": "",
        "longitude": 105.81930120306964,
        "latitude": 21.003110679947337,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6523,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81931059079675,
          21.002894083302223
        ]
      },
      "properties": {
        "tenDoiTuon": "23",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Nút GT Ngã Tư Sở",
        "tuDien": "CVNTS1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "DC-05",
        "chieuCao": 3,
        "tuDien2": "CV NTS Tủ 1",
        "diaChiLapD": "",
        "longitude": 105.81931059079675,
        "latitude": 21.002894083302223,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6524,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81925426440722,
          21.002929139428172
        ]
      },
      "properties": {
        "tenDoiTuon": "22",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Nút GT Ngã Tư Sở",
        "tuDien": "CVNTS1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "DC-05",
        "chieuCao": 3,
        "tuDien2": "CV NTS Tủ 1",
        "diaChiLapD": "",
        "longitude": 105.81925426440722,
        "latitude": 21.002929139428172,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6525,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8192904742297,
          21.002835239072784
        ]
      },
      "properties": {
        "tenDoiTuon": "24",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Nút GT Ngã Tư Sở",
        "tuDien": "CVNTS1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "DC-05",
        "chieuCao": 3,
        "tuDien2": "CV NTS Tủ 1",
        "diaChiLapD": "",
        "longitude": 105.8192904742297,
        "latitude": 21.002835239072784,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6526,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81922476011307,
          21.002831483056603
        ]
      },
      "properties": {
        "tenDoiTuon": "25",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Nút GT Ngã Tư Sở",
        "tuDien": "CVNTS1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "DC-05",
        "chieuCao": 3,
        "tuDien2": "CV NTS Tủ 1",
        "diaChiLapD": "",
        "longitude": 105.81922476011307,
        "latitude": 21.002831483056603,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6527,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81923683004791,
          21.00276763077962
        ]
      },
      "properties": {
        "tenDoiTuon": "26",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Nút GT Ngã Tư Sở",
        "tuDien": "CVNTS1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "DC-05",
        "chieuCao": 3,
        "tuDien2": "CV NTS Tủ 1",
        "diaChiLapD": "",
        "longitude": 105.81923683004791,
        "latitude": 21.00276763077962,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6528,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83588199317302,
          21.013063770424974
        ]
      },
      "properties": {
        "tenDoiTuon": "CNC 01",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Khâm Thiên, Đê La Thành",
        "tuDien": "CNC",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "CNC",
        "diaChiLapD": "",
        "longitude": 105.83588199317302,
        "latitude": 21.013063770424974,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6529,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83600269258427,
          21.013208992993373
        ]
      },
      "properties": {
        "tenDoiTuon": "CNC 02",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Khâm Thiên, Đê La Thành",
        "tuDien": "CNC",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "CNC",
        "diaChiLapD": "",
        "longitude": 105.83600269258427,
        "latitude": 21.013208992993373,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6530,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83629773557065,
          21.013542002832693
        ]
      },
      "properties": {
        "tenDoiTuon": "CNC 03",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Khâm Thiên, Đê La Thành",
        "tuDien": "CNC",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "CNC",
        "diaChiLapD": "",
        "longitude": 105.83629773557065,
        "latitude": 21.013542002832693,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6531,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83642427760168,
          21.01381068234426
        ]
      },
      "properties": {
        "tenDoiTuon": "CNC 04",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Khâm Thiên, Đê La Thành",
        "tuDien": "CNC",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "CNC",
        "diaChiLapD": "",
        "longitude": 105.83642427760168,
        "latitude": 21.01381068234426,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6532,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83663837611557,
          21.01409785218521
        ]
      },
      "properties": {
        "tenDoiTuon": "CNC 05",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Khâm Thiên, Đê La Thành",
        "tuDien": "CNC",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "CNC",
        "diaChiLapD": "",
        "longitude": 105.83663837611557,
        "latitude": 21.01409785218521,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6533,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8368344007187,
          21.01440394401861
        ]
      },
      "properties": {
        "tenDoiTuon": "CNC 6-8",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 252+260 Phố Chợ Khâm Thiên",
        "tuDien": "CNC",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "CNC",
        "diaChiLapD": "",
        "longitude": 105.8368344007187,
        "latitude": 21.01440394401861,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6534,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83696269854674,
          21.014561142093573
        ]
      },
      "properties": {
        "tenDoiTuon": "CNC 06",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Khâm Thiên, Đê La Thành",
        "tuDien": "CNC",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "CNC",
        "diaChiLapD": "",
        "longitude": 105.83696269854674,
        "latitude": 21.014561142093573,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6535,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83713032626262,
          21.014809447969917
        ]
      },
      "properties": {
        "tenDoiTuon": "CNC 07",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Khâm Thiên, Đê La Thành",
        "tuDien": "CNC",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "CNC",
        "diaChiLapD": "",
        "longitude": 105.83713032626262,
        "latitude": 21.014809447969917,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6536,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83728478848684,
          21.015061820251276
        ]
      },
      "properties": {
        "tenDoiTuon": "CNC 08",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Khâm Thiên, Đê La Thành",
        "tuDien": "CNC",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "CNC",
        "diaChiLapD": "",
        "longitude": 105.83728478848684,
        "latitude": 21.015061820251276,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6537,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83741934086332,
          21.015270056300068
        ]
      },
      "properties": {
        "tenDoiTuon": "CNC 09",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Khâm Thiên, Đê La Thành",
        "tuDien": "CNC",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "CNC",
        "diaChiLapD": "",
        "longitude": 105.83741934086332,
        "latitude": 21.015270056300068,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6538,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8375798314822,
          21.015522519672146
        ]
      },
      "properties": {
        "tenDoiTuon": "CNC 10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Khâm Thiên, Đê La Thành",
        "tuDien": "CNC",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "CNC",
        "diaChiLapD": "",
        "longitude": 105.8375798314822,
        "latitude": 21.015522519672146,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6539,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82465178863052,
          21.023781514754575
        ]
      },
      "properties": {
        "tenDoiTuon": "MNTH 1-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "",
        "tuDien": "MNTH",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "MNHN",
        "diaChiLapD": "",
        "longitude": 105.82465178863052,
        "latitude": 21.023781514754575,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6540,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82437552109835,
          21.023906697634754
        ]
      },
      "properties": {
        "tenDoiTuon": "MNTH 1-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "",
        "tuDien": "MNTH",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "MNHN",
        "diaChiLapD": "",
        "longitude": 105.82437552109835,
        "latitude": 21.023906697634754,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6541,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82461155549643,
          21.023511119374987
        ]
      },
      "properties": {
        "tenDoiTuon": "MNTH 01",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 318 La Thành",
        "tuDien": "MNTH",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "MNHN",
        "diaChiLapD": "",
        "longitude": 105.82461155549643,
        "latitude": 21.023511119374987,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6542,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82454718247469,
          21.023308322518236
        ]
      },
      "properties": {
        "tenDoiTuon": "MNTH 02",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 318 La Thành",
        "tuDien": "MNTH",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "MNHN",
        "diaChiLapD": "",
        "longitude": 105.82454718247469,
        "latitude": 21.023308322518236,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6543,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82449890271737,
          21.02314558410057
        ]
      },
      "properties": {
        "tenDoiTuon": "MNTH 03",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 318 La Thành",
        "tuDien": "MNTH",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "MNHN",
        "diaChiLapD": "",
        "longitude": 105.82449890271737,
        "latitude": 21.02314558410057,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6544,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82445732850636,
          21.022995363866663
        ]
      },
      "properties": {
        "tenDoiTuon": "MNTH 04",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 318 La Thành",
        "tuDien": "MNTH",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "MNHN",
        "diaChiLapD": "",
        "longitude": 105.82445732850636,
        "latitude": 21.022995363866663,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6545,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82468933955688,
          21.023110532725667
        ]
      },
      "properties": {
        "tenDoiTuon": "MNTH 3-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 318/40",
        "tuDien": "MNTH",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "MNHN",
        "diaChiLapD": "",
        "longitude": 105.82468933955688,
        "latitude": 21.023110532725667,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6546,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82473493710647,
          21.02324573084049
        ]
      },
      "properties": {
        "tenDoiTuon": "MNTH 3-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 318/40",
        "tuDien": "MNTH",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "MNHN",
        "diaChiLapD": "",
        "longitude": 105.82473493710647,
        "latitude": 21.02324573084049,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6547,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82477517024056,
          21.023403461818763
        ]
      },
      "properties": {
        "tenDoiTuon": "MNTH 12",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Chùa Thanh Nhàn",
        "tuDien": "MNTH",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "MNHN",
        "diaChiLapD": "",
        "longitude": 105.82477517024056,
        "latitude": 21.023403461818763,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6548,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82496560708007,
          21.02334087018011
        ]
      },
      "properties": {
        "tenDoiTuon": "MNTH 13",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Chùa Thanh Nhàn",
        "tuDien": "MNTH",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "MNHN",
        "diaChiLapD": "",
        "longitude": 105.82496560708007,
        "latitude": 21.02334087018011,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6549,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82500584022316,
          21.023185642804773
        ]
      },
      "properties": {
        "tenDoiTuon": "MNTH 14",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Chùa Thanh Nhàn",
        "tuDien": "MNTH",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "MNHN",
        "diaChiLapD": "",
        "longitude": 105.82500584022316,
        "latitude": 21.023185642804773,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6550,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82464910642278,
          21.023911704947906
        ]
      },
      "properties": {
        "tenDoiTuon": "MNTH 06",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 318 La Thành",
        "tuDien": "MNTH",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "MNHN",
        "diaChiLapD": "",
        "longitude": 105.82464910642278,
        "latitude": 21.023911704947906,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6551,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8247590769941,
          21.024109493677496
        ]
      },
      "properties": {
        "tenDoiTuon": "MNTH 6-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 318/84",
        "tuDien": "MNTH",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "MNHN",
        "diaChiLapD": "",
        "longitude": 105.8247590769941,
        "latitude": 21.024109493677496,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6552,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8243862499383,
          21.02399182193297
        ]
      },
      "properties": {
        "tenDoiTuon": "MNTH 07",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 318 La Thành",
        "tuDien": "MNTH",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "MNHN",
        "diaChiLapD": "",
        "longitude": 105.8243862499383,
        "latitude": 21.02399182193297,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6553,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82415289775695,
          21.024036887717894
        ]
      },
      "properties": {
        "tenDoiTuon": "MNTH 08",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 318 La Thành",
        "tuDien": "MNTH",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "MNHN",
        "diaChiLapD": "",
        "longitude": 105.82415289775695,
        "latitude": 21.024036887717894,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6554,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82395173207749,
          21.02409697541049
        ]
      },
      "properties": {
        "tenDoiTuon": "MNTH 09",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 318 La Thành",
        "tuDien": "MNTH",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "MNHN",
        "diaChiLapD": "",
        "longitude": 105.82395173207749,
        "latitude": 21.02409697541049,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6555,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8237827529089,
          21.024339829588452
        ]
      },
      "properties": {
        "tenDoiTuon": "MNTH 9-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 318/90",
        "tuDien": "MNTH",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "MNHN",
        "diaChiLapD": "",
        "longitude": 105.8237827529089,
        "latitude": 21.024339829588452,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6556,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82402683393019,
          21.02439240621416
        ]
      },
      "properties": {
        "tenDoiTuon": "MNTH 9-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 318/90",
        "tuDien": "MNTH",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "MNHN",
        "diaChiLapD": "",
        "longitude": 105.82402683393019,
        "latitude": 21.02439240621416,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6557,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82407779590426,
          21.024592697950656
        ]
      },
      "properties": {
        "tenDoiTuon": "MNTH 9-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 318/90",
        "tuDien": "MNTH",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "MNHN",
        "diaChiLapD": "",
        "longitude": 105.82407779590426,
        "latitude": 21.024592697950656,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6558,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82412607566157,
          21.024855580447007
        ]
      },
      "properties": {
        "tenDoiTuon": "MNTH 9-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 318/90",
        "tuDien": "MNTH",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "MNHN",
        "diaChiLapD": "",
        "longitude": 105.82412607566157,
        "latitude": 21.024855580447007,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6559,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82413948670926,
          21.025063382472542
        ]
      },
      "properties": {
        "tenDoiTuon": "MNTH 9-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 318/90",
        "tuDien": "MNTH",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "MNHN",
        "diaChiLapD": "",
        "longitude": 105.82413948670926,
        "latitude": 21.025063382472542,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6560,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82441575424143,
          21.024167077688194
        ]
      },
      "properties": {
        "tenDoiTuon": "MNTH 10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 318/100",
        "tuDien": "MNTH",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "MNHN",
        "diaChiLapD": "",
        "longitude": 105.82441575424143,
        "latitude": 21.024167077688194,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6561,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82448817388641,
          21.024372377025152
        ]
      },
      "properties": {
        "tenDoiTuon": "MNTH 11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 318/100",
        "tuDien": "MNTH",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "MNHN",
        "diaChiLapD": "",
        "longitude": 105.82448817388641,
        "latitude": 21.024372377025152,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6562,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82467056409371,
          21.02435735513242
        ]
      },
      "properties": {
        "tenDoiTuon": "MNTH 11-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 318/100",
        "tuDien": "MNTH",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "MNHN",
        "diaChiLapD": "",
        "longitude": 105.82467056409371,
        "latitude": 21.02435735513242,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6563,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82469470397236,
          21.024434968230683
        ]
      },
      "properties": {
        "tenDoiTuon": "MNTH 11-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 318/100",
        "tuDien": "MNTH",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "MNHN",
        "diaChiLapD": "",
        "longitude": 105.82469470397236,
        "latitude": 21.024434968230683,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6564,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82456327573011,
          21.02458017972509
        ]
      },
      "properties": {
        "tenDoiTuon": "MNTH 11-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 318/100",
        "tuDien": "MNTH",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "MNHN",
        "diaChiLapD": "",
        "longitude": 105.82456327573011,
        "latitude": 21.02458017972509,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6565,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82458205119329,
          21.024750427503754
        ]
      },
      "properties": {
        "tenDoiTuon": "MNTH 11-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 318/100",
        "tuDien": "MNTH",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "MNHN",
        "diaChiLapD": "",
        "longitude": 105.82458205119329,
        "latitude": 21.024750427503754,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6566,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8245498646914,
          21.024895638690868
        ]
      },
      "properties": {
        "tenDoiTuon": "MNTH 11-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 318/100",
        "tuDien": "MNTH",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "MNHN",
        "diaChiLapD": "",
        "longitude": 105.8245498646914,
        "latitude": 21.024895638690868,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6567,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82471079724577,
          21.023740204381422
        ]
      },
      "properties": {
        "tenDoiTuon": "MNTH 05",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 318 La Thành",
        "tuDien": "MNTH",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "MNHN",
        "diaChiLapD": "",
        "longitude": 105.82471079724577,
        "latitude": 21.023740204381422,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6568,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82185231912322,
          21.0219455604234
        ]
      },
      "properties": {
        "tenDoiTuon": "HC1/4-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 16 Nguyễn Phúc Lai",
        "tuDien": "HC1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "HC1",
        "diaChiLapD": "",
        "longitude": 105.82185231912322,
        "latitude": 21.0219455604234,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6569,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81999723632006,
          21.01991348959726
        ]
      },
      "properties": {
        "tenDoiTuon": "HC1/26",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 104 Nguyễn Phúc Lai",
        "tuDien": "HC1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "HC1",
        "diaChiLapD": "",
        "longitude": 105.81999723632006,
        "latitude": 21.01991348959726,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6570,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82393429775412,
          21.024615230755266
        ]
      },
      "properties": {
        "tenDoiTuon": "MNTH 9-3-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 318/90",
        "tuDien": "MNTH",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "MNHN",
        "diaChiLapD": "",
        "longitude": 105.82393429775412,
        "latitude": 21.024615230755266,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6571,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82381091613512,
          21.024898142332944
        ]
      },
      "properties": {
        "tenDoiTuon": "MNTH 9-4-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 318/90",
        "tuDien": "MNTH",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "MNHN",
        "diaChiLapD": "",
        "longitude": 105.82381091613512,
        "latitude": 21.024898142332944,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6572,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8239208867064,
          21.024890631412443
        ]
      },
      "properties": {
        "tenDoiTuon": "MNTH 9-4-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 318/90",
        "tuDien": "MNTH",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "MNHN",
        "diaChiLapD": "",
        "longitude": 105.8239208867064,
        "latitude": 21.024890631412443,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6573,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82404426831644,
          21.02487060229119
        ]
      },
      "properties": {
        "tenDoiTuon": "MNTH 9-4-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 318/90",
        "tuDien": "MNTH",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "MNHN",
        "diaChiLapD": "",
        "longitude": 105.82404426831644,
        "latitude": 21.02487060229119,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6574,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82412625221644,
          21.025218552981247
        ]
      },
      "properties": {
        "tenDoiTuon": "MNTH 9-5-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 318/90",
        "tuDien": "MNTH",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "MNHN",
        "diaChiLapD": "",
        "longitude": 105.82412625221644,
        "latitude": 21.025218552981247,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6575,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82636589678596,
          21.018362192194903
        ]
      },
      "properties": {
        "tenDoiTuon": "TCNHN 06",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 97",
        "tuDien": "TCNHN",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TCNHN",
        "diaChiLapD": "",
        "longitude": 105.82636589678596,
        "latitude": 21.018362192194903,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6576,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82609767588598,
          21.018512417098563
        ]
      },
      "properties": {
        "tenDoiTuon": "TCNHN 05",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 97",
        "tuDien": "TCNHN",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TCNHN",
        "diaChiLapD": "",
        "longitude": 105.82609767588598,
        "latitude": 21.018512417098563,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6577,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82586700591237,
          21.018622581931446
        ]
      },
      "properties": {
        "tenDoiTuon": "TCNHN 04",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 97",
        "tuDien": "TCNHN",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TCNHN",
        "diaChiLapD": "",
        "longitude": 105.82586700591237,
        "latitude": 21.018622581931446,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6578,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8255987850124,
          21.018777814058353
        ]
      },
      "properties": {
        "tenDoiTuon": "TCNHN 03",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 97",
        "tuDien": "TCNHN",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TCNHN",
        "diaChiLapD": "",
        "longitude": 105.8255987850124,
        "latitude": 21.018777814058353,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6579,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82537884386977,
          21.018892986176866
        ]
      },
      "properties": {
        "tenDoiTuon": "TCNHN 02",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 97",
        "tuDien": "TCNHN",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TCNHN",
        "diaChiLapD": "",
        "longitude": 105.82537884386977,
        "latitude": 21.018892986176866,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6580,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82508916529889,
          21.018993135773265
        ]
      },
      "properties": {
        "tenDoiTuon": "TCNHN 01",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 97",
        "tuDien": "TCNHN",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TCNHN",
        "diaChiLapD": "",
        "longitude": 105.82508916529889,
        "latitude": 21.018993135773265,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6581,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82632298144411,
          21.018146869568906
        ]
      },
      "properties": {
        "tenDoiTuon": "TCNHN 6-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "TT bộ công an",
        "tuDien": "TCNHN",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TCNHN",
        "diaChiLapD": "",
        "longitude": 105.82632298144411,
        "latitude": 21.018146869568906,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6582,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82615668448325,
          21.01788147148533
        ]
      },
      "properties": {
        "tenDoiTuon": "TCNHN 6-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "TT bộ công an",
        "tuDien": "TCNHN",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TCNHN",
        "diaChiLapD": "",
        "longitude": 105.82615668448325,
        "latitude": 21.01788147148533,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6583,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82598502310692,
          21.017606057881746
        ]
      },
      "properties": {
        "tenDoiTuon": "TCNHN 6-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "TT bộ công an",
        "tuDien": "TCNHN",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TCNHN",
        "diaChiLapD": "",
        "longitude": 105.82598502310692,
        "latitude": 21.017606057881746,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6584,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82584554824145,
          21.018051726914123
        ]
      },
      "properties": {
        "tenDoiTuon": "TCNHN 6-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "TT bộ công an",
        "tuDien": "TCNHN",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TCNHN",
        "diaChiLapD": "",
        "longitude": 105.82584554824145,
        "latitude": 21.018051726914123,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6585,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8259974892437,
          21.018168663712807
        ]
      },
      "properties": {
        "tenDoiTuon": "TCNHN 6-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "TT bộ công an",
        "tuDien": "TCNHN",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TCNHN",
        "diaChiLapD": "",
        "longitude": 105.8259974892437,
        "latitude": 21.018168663712807,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6586,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82580168798872,
          21.017843175480085
        ]
      },
      "properties": {
        "tenDoiTuon": "TCNHN 6-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "TT bộ công an",
        "tuDien": "TCNHN",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TCNHN",
        "diaChiLapD": "",
        "longitude": 105.82580168798872,
        "latitude": 21.017843175480085,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6587,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8257721836856,
          21.018286340052406
        ]
      },
      "properties": {
        "tenDoiTuon": "TCNHN 6-8",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "TT bộ công an",
        "tuDien": "TCNHN",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TCNHN",
        "diaChiLapD": "",
        "longitude": 105.8257721836856,
        "latitude": 21.018286340052406,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6588,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82571680220695,
          21.018131847048114
        ]
      },
      "properties": {
        "tenDoiTuon": "TCNHN 6-7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "TT bộ công an",
        "tuDien": "TCNHN",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TCNHN",
        "diaChiLapD": "",
        "longitude": 105.82571680220695,
        "latitude": 21.018131847048114,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6589,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82559247568604,
          21.018000912173754
        ]
      },
      "properties": {
        "tenDoiTuon": "TCNHN 6-9",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "TT bộ công an",
        "tuDien": "TCNHN",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TCNHN",
        "diaChiLapD": "",
        "longitude": 105.82559247568604,
        "latitude": 21.018000912173754,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6590,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82673604163399,
          21.01893304602364
        ]
      },
      "properties": {
        "tenDoiTuon": "TCNHN 08",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "",
        "tuDien": "TCNHN",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TCNHN",
        "diaChiLapD": "",
        "longitude": 105.82673604163399,
        "latitude": 21.01893304602364,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6591,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82657510908864,
          21.018647619382016
        ]
      },
      "properties": {
        "tenDoiTuon": "TCNHN 07",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "",
        "tuDien": "TCNHN",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TCNHN",
        "diaChiLapD": "",
        "longitude": 105.82657510908864,
        "latitude": 21.018647619382016,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6592,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82690770301035,
          21.019118322673062
        ]
      },
      "properties": {
        "tenDoiTuon": "TCNHN 8-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "",
        "tuDien": "TCNHN",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TCNHN",
        "diaChiLapD": "",
        "longitude": 105.82690770301035,
        "latitude": 21.019118322673062,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6593,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82712764415298,
          21.019018173161612
        ]
      },
      "properties": {
        "tenDoiTuon": "TCNHN 8-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "",
        "tuDien": "TCNHN",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TCNHN",
        "diaChiLapD": "",
        "longitude": 105.82712764415298,
        "latitude": 21.019018173161612,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6594,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82692916068127,
          21.018807858967445
        ]
      },
      "properties": {
        "tenDoiTuon": "TCNHN 09",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "",
        "tuDien": "TCNHN",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TCNHN",
        "diaChiLapD": "",
        "longitude": 105.82692916068127,
        "latitude": 21.018807858967445,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6595,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82719201716574,
          21.018672656828414
        ]
      },
      "properties": {
        "tenDoiTuon": "TCNHN 10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "",
        "tuDien": "TCNHN",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TCNHN",
        "diaChiLapD": "",
        "longitude": 105.82719201716574,
        "latitude": 21.018672656828414,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6596,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82748169573664,
          21.01847736463442
        ]
      },
      "properties": {
        "tenDoiTuon": "TCNHN 11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Giếng",
        "tuDien": "TCNHN",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TCNHN",
        "diaChiLapD": "",
        "longitude": 105.82748169573664,
        "latitude": 21.01847736463442,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6597,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82780356081838,
          21.01837220719262
        ]
      },
      "properties": {
        "tenDoiTuon": "TCNHN 12",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Giếng",
        "tuDien": "TCNHN",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TCNHN",
        "diaChiLapD": "",
        "longitude": 105.82780356081838,
        "latitude": 21.01837220719262,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6598,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82810933265371,
          21.018211967139877
        ]
      },
      "properties": {
        "tenDoiTuon": "TCNHN 13",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Giếng",
        "tuDien": "TCNHN",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TCNHN",
        "diaChiLapD": "",
        "longitude": 105.82810933265371,
        "latitude": 21.018211967139877,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6599,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82835073145826,
          21.018111817018866
        ]
      },
      "properties": {
        "tenDoiTuon": "TCNHN 14",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Giếng",
        "tuDien": "TCNHN",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TCNHN",
        "diaChiLapD": "",
        "longitude": 105.82835073145826,
        "latitude": 21.018111817018866,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6600,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82659656676854,
          21.019303599093092
        ]
      },
      "properties": {
        "tenDoiTuon": "TCNHN 8-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "",
        "tuDien": "TCNHN",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TCNHN",
        "diaChiLapD": "",
        "longitude": 105.82659656676854,
        "latitude": 21.019303599093092,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6601,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82633371028405,
          21.019453823048334
        ]
      },
      "properties": {
        "tenDoiTuon": "TCNHN 8-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "",
        "tuDien": "TCNHN",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TCNHN",
        "diaChiLapD": "",
        "longitude": 105.82633371028405,
        "latitude": 21.019453823048334,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6602,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82606548938412,
          21.01960404685223
        ]
      },
      "properties": {
        "tenDoiTuon": "TCNHN 8-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "",
        "tuDien": "TCNHN",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TCNHN",
        "diaChiLapD": "",
        "longitude": 105.82606548938412,
        "latitude": 21.01960404685223,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6603,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82639808329682,
          21.019218472118105
        ]
      },
      "properties": {
        "tenDoiTuon": "TCNHN 8-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "",
        "tuDien": "TCNHN",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TCNHN",
        "diaChiLapD": "",
        "longitude": 105.82639808329682,
        "latitude": 21.019218472118105,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6604,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82637662562593,
          21.019541453619354
        ]
      },
      "properties": {
        "tenDoiTuon": "TCNHN 8-7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "",
        "tuDien": "TCNHN",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TCNHN",
        "diaChiLapD": "",
        "longitude": 105.82637662562593,
        "latitude": 21.019541453619354,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6605,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82617814215418,
          21.019651617691974
        ]
      },
      "properties": {
        "tenDoiTuon": "TCNHN 8-8",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "",
        "tuDien": "TCNHN",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TCNHN",
        "diaChiLapD": "",
        "longitude": 105.82617814215418,
        "latitude": 21.019651617691974,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6606,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82582582785838,
          21.019728493613687
        ]
      },
      "properties": {
        "tenDoiTuon": "TCNHN 8-9",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "",
        "tuDien": "TCNHN",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TCNHN",
        "diaChiLapD": "",
        "longitude": 105.82582582785838,
        "latitude": 21.019728493613687,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6607,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82814042287855,
          21.011803082358817
        ]
      },
      "properties": {
        "tenDoiTuon": "TTNĐ 31",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 119 Hồ Đắc Di",
        "tuDien": "TTNĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TTNĐ",
        "diaChiLapD": "",
        "longitude": 105.82814042287855,
        "latitude": 21.011803082358817,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6608,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82800631242856,
          21.011612788894684
        ]
      },
      "properties": {
        "tenDoiTuon": "TTNĐ 32",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 119 Hồ Đắc Di",
        "tuDien": "TTNĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TTNĐ",
        "diaChiLapD": "",
        "longitude": 105.82800631242856,
        "latitude": 21.011612788894684,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6609,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82788024860182,
          21.011394952658026
        ]
      },
      "properties": {
        "tenDoiTuon": "TTNĐ 33",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 119 Hồ Đắc Di",
        "tuDien": "TTNĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TTNĐ",
        "diaChiLapD": "",
        "longitude": 105.82788024860182,
        "latitude": 21.011394952658026,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6610,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82777027803048,
          21.01118212384301
        ]
      },
      "properties": {
        "tenDoiTuon": "TTNĐ 34",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 119 Hồ Đắc Di",
        "tuDien": "TTNĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TTNĐ",
        "diaChiLapD": "",
        "longitude": 105.82777027803048,
        "latitude": 21.01118212384301,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6611,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82780782895684,
          21.011034395429032
        ]
      },
      "properties": {
        "tenDoiTuon": "TTNĐ 34-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 119 Hồ Đắc Di",
        "tuDien": "TTNĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TTNĐ",
        "diaChiLapD": "",
        "longitude": 105.82780782895684,
        "latitude": 21.011034395429032,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6612,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82794730382233,
          21.01080153508862
        ]
      },
      "properties": {
        "tenDoiTuon": "TTNĐ 35",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 119 Hồ Đắc Di",
        "tuDien": "TTNĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TTNĐ",
        "diaChiLapD": "",
        "longitude": 105.82794730382233,
        "latitude": 21.01080153508862,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6613,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8281833382204,
          21.01069637223471
        ]
      },
      "properties": {
        "tenDoiTuon": "TTNĐ 43",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 119 Hồ Đắc Di",
        "tuDien": "TTNĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TTNĐ",
        "diaChiLapD": "",
        "longitude": 105.8281833382204,
        "latitude": 21.01069637223471,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6614,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82783465105223,
          21.010723914894328
        ]
      },
      "properties": {
        "tenDoiTuon": "TTNĐ 36-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 119 Hồ Đắc Di",
        "tuDien": "TTNĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TTNĐ",
        "diaChiLapD": "",
        "longitude": 105.82783465105223,
        "latitude": 21.010723914894328,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6615,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82777027803048,
          21.010496061832903
        ]
      },
      "properties": {
        "tenDoiTuon": "TTNĐ 36",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 119 Hồ Đắc Di",
        "tuDien": "TTNĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TTNĐ",
        "diaChiLapD": "",
        "longitude": 105.82777027803048,
        "latitude": 21.010496061832903,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6616,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8276012988619,
          21.01020060349896
        ]
      },
      "properties": {
        "tenDoiTuon": "TTNĐ 37",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 119 Hồ Đắc Di",
        "tuDien": "TTNĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TTNĐ",
        "diaChiLapD": "",
        "longitude": 105.8276012988619,
        "latitude": 21.01020060349896,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6617,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82764421420373,
          21.010077913001354
        ]
      },
      "properties": {
        "tenDoiTuon": "TTNĐ 38",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 119 Hồ Đắc Di",
        "tuDien": "TTNĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TTNĐ",
        "diaChiLapD": "",
        "longitude": 105.82764421420373,
        "latitude": 21.010077913001354,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6618,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82781051116457,
          21.010077913001354
        ]
      },
      "properties": {
        "tenDoiTuon": "TTNĐ 40",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 119 Hồ Đắc Di",
        "tuDien": "TTNĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TTNĐ",
        "diaChiLapD": "",
        "longitude": 105.82781051116457,
        "latitude": 21.010077913001354,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6619,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82793657499134,
          21.010300758932285
        ]
      },
      "properties": {
        "tenDoiTuon": "TTNĐ 41",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 119 Hồ Đắc Di",
        "tuDien": "TTNĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TTNĐ",
        "diaChiLapD": "",
        "longitude": 105.82793657499134,
        "latitude": 21.010300758932285,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6620,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82806800323358,
          21.010506077359672
        ]
      },
      "properties": {
        "tenDoiTuon": "TTNĐ 42",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 119 Hồ Đắc Di",
        "tuDien": "TTNĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TTNĐ",
        "diaChiLapD": "",
        "longitude": 105.82806800323358,
        "latitude": 21.010506077359672,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6621,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82797949033318,
          21.009915160145468
        ]
      },
      "properties": {
        "tenDoiTuon": "TTNĐ 39",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 119 Hồ Đắc Di",
        "tuDien": "TTNĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TTNĐ",
        "diaChiLapD": "",
        "longitude": 105.82797949033318,
        "latitude": 21.009915160145468,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6622,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82756374793554,
          21.010969294725214
        ]
      },
      "properties": {
        "tenDoiTuon": "TTNĐ 34-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 119 Hồ Đắc Di",
        "tuDien": "TTNĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TTNĐ",
        "diaChiLapD": "",
        "longitude": 105.82756374793554,
        "latitude": 21.010969294725214,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6623,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82737867551151,
          21.011056930280752
        ]
      },
      "properties": {
        "tenDoiTuon": "TTNĐ 34-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 119 Hồ Đắc Di",
        "tuDien": "TTNĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TTNĐ",
        "diaChiLapD": "",
        "longitude": 105.82737867551151,
        "latitude": 21.011056930280752,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6624,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82721237855965,
          21.011142061915148
        ]
      },
      "properties": {
        "tenDoiTuon": "TTNĐ 34-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 119 Hồ Đắc Di",
        "tuDien": "TTNĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TTNĐ",
        "diaChiLapD": "",
        "longitude": 105.82721237855965,
        "latitude": 21.011142061915148,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6625,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8283104078728,
          21.011939229670357
        ]
      },
      "properties": {
        "tenDoiTuon": "TTNĐ 01",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 119 Hồ Đắc Di",
        "tuDien": "TTNĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TTNĐ",
        "diaChiLapD": "",
        "longitude": 105.8283104078728,
        "latitude": 21.011939229670357,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6626,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82846329378596,
          21.012204638328207
        ]
      },
      "properties": {
        "tenDoiTuon": "TTNĐ 04",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 119 Hồ Đắc Di",
        "tuDien": "TTNĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TTNĐ",
        "diaChiLapD": "",
        "longitude": 105.82846329378596,
        "latitude": 21.012204638328207,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6627,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82859472202819,
          21.012437496477546
        ]
      },
      "properties": {
        "tenDoiTuon": "TTNĐ 05",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 119 Hồ Đắc Di",
        "tuDien": "TTNĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TTNĐ",
        "diaChiLapD": "",
        "longitude": 105.82859472202819,
        "latitude": 21.012437496477546,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6628,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82817093300734,
          21.011946741243236
        ]
      },
      "properties": {
        "tenDoiTuon": "TTNĐ 02",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 119 Hồ Đắc Di",
        "tuDien": "TTNĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TTNĐ",
        "diaChiLapD": "",
        "longitude": 105.82817093300734,
        "latitude": 21.011946741243236,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6629,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82799658941428,
          21.012034376224538
        ]
      },
      "properties": {
        "tenDoiTuon": "TTNĐ 03",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 119 Hồ Đắc Di",
        "tuDien": "TTNĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TTNĐ",
        "diaChiLapD": "",
        "longitude": 105.82799658941428,
        "latitude": 21.012034376224538,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6630,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82921967672854,
          21.01223218070852
        ]
      },
      "properties": {
        "tenDoiTuon": "TTNĐ 19",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Hồ Đắc Di",
        "tuDien": "TTNĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TTNĐ",
        "diaChiLapD": "",
        "longitude": 105.82921967672854,
        "latitude": 21.01223218070852,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6631,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82876101898906,
          21.012440000327135
        ]
      },
      "properties": {
        "tenDoiTuon": "TTNĐ 17",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Hồ Đắc Di",
        "tuDien": "TTNĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TTNĐ",
        "diaChiLapD": "",
        "longitude": 105.82876101898906,
        "latitude": 21.012440000327135,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6632,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82900510001035,
          21.01230980011798
        ]
      },
      "properties": {
        "tenDoiTuon": "TTNĐ 18",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Hồ Đắc Di",
        "tuDien": "TTNĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TTNĐ",
        "diaChiLapD": "",
        "longitude": 105.82900510001035,
        "latitude": 21.01230980011798,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6633,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82904533314446,
          21.01264781965623
        ]
      },
      "properties": {
        "tenDoiTuon": "TTNĐ 17-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 10 Hồ Đắc Di",
        "tuDien": "TTNĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TTNĐ",
        "diaChiLapD": "",
        "longitude": 105.82904533314446,
        "latitude": 21.01264781965623,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6634,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82897827791497,
          21.012610261967
        ]
      },
      "properties": {
        "tenDoiTuon": "TTNĐ 17-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 10 Hồ Đắc Di",
        "tuDien": "TTNĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TTNĐ",
        "diaChiLapD": "",
        "longitude": 105.82897827791497,
        "latitude": 21.012610261967,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6635,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82913921046034,
          21.01288192903559
        ]
      },
      "properties": {
        "tenDoiTuon": "TTNĐ 17-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 10 Hồ Đắc Di",
        "tuDien": "TTNĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TTNĐ",
        "diaChiLapD": "",
        "longitude": 105.82913921046034,
        "latitude": 21.01288192903559,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6636,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82926259207039,
          21.013092251586702
        ]
      },
      "properties": {
        "tenDoiTuon": "TTNĐ 17-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 10 Hồ Đắc Di",
        "tuDien": "TTNĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TTNĐ",
        "diaChiLapD": "",
        "longitude": 105.82926259207039,
        "latitude": 21.013092251586702,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6637,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82838282751776,
          21.01260024658234
        ]
      },
      "properties": {
        "tenDoiTuon": "TTNĐ 13",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Hồ Đắc Di",
        "tuDien": "TTNĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TTNĐ",
        "diaChiLapD": "",
        "longitude": 105.82838282751776,
        "latitude": 21.01260024658234,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6638,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82845256495499,
          21.012720431160567
        ]
      },
      "properties": {
        "tenDoiTuon": "TTNĐ 14",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 14 Hồ Đắc Di",
        "tuDien": "TTNĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TTNĐ",
        "diaChiLapD": "",
        "longitude": 105.82845256495499,
        "latitude": 21.012720431160567,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6639,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82856521773405,
          21.012903211687306
        ]
      },
      "properties": {
        "tenDoiTuon": "TTNĐ 15",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 14 Hồ Đắc Di",
        "tuDien": "TTNĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TTNĐ",
        "diaChiLapD": "",
        "longitude": 105.82856521773405,
        "latitude": 21.012903211687306,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6640,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8286617772577,
          21.013083488152148
        ]
      },
      "properties": {
        "tenDoiTuon": "TTNĐ 16",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 14 Hồ Đắc Di",
        "tuDien": "TTNĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TTNĐ",
        "diaChiLapD": "",
        "longitude": 105.8286617772577,
        "latitude": 21.013083488152148,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6641,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82819239067825,
          21.012710415782458
        ]
      },
      "properties": {
        "tenDoiTuon": "TTNĐ 12",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Hồ Đắc Di",
        "tuDien": "TTNĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TTNĐ",
        "diaChiLapD": "",
        "longitude": 105.82819239067825,
        "latitude": 21.012710415782458,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6642,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82794830965696,
          21.012833104114055
        ]
      },
      "properties": {
        "tenDoiTuon": "TTNĐ 11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Hồ Đắc Di",
        "tuDien": "TTNĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TTNĐ",
        "diaChiLapD": "",
        "longitude": 105.82794830965696,
        "latitude": 21.012833104114055,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6643,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82764522003838,
          21.013008372985368
        ]
      },
      "properties": {
        "tenDoiTuon": "TTNĐ 08",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Hồ Đắc Di",
        "tuDien": "TTNĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TTNĐ",
        "diaChiLapD": "",
        "longitude": 105.82764522003838,
        "latitude": 21.013008372985368,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6644,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82738772796938,
          21.01315609944442
        ]
      },
      "properties": {
        "tenDoiTuon": "TTNĐ 07",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Hồ Đắc Di",
        "tuDien": "TTNĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TTNĐ",
        "diaChiLapD": "",
        "longitude": 105.82738772796938,
        "latitude": 21.01315609944442,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6645,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82716510462798,
          21.013301321922935
        ]
      },
      "properties": {
        "tenDoiTuon": "TTNĐ 06",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Hồ Đắc Di",
        "tuDien": "TTNĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TTNĐ",
        "diaChiLapD": "",
        "longitude": 105.82716510462798,
        "latitude": 21.013301321922935,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6646,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82887098956036,
          21.013296314253466
        ]
      },
      "properties": {
        "tenDoiTuon": "TTNĐ 16-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 14 Hồ Đắc Di",
        "tuDien": "TTNĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TTNĐ",
        "diaChiLapD": "",
        "longitude": 105.82887098956036,
        "latitude": 21.013296314253466,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6647,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82890049385452,
          21.01337393310917
        ]
      },
      "properties": {
        "tenDoiTuon": "TTNĐ 16-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 14 Hồ Đắc Di",
        "tuDien": "TTNĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TTNĐ",
        "diaChiLapD": "",
        "longitude": 105.82890049385452,
        "latitude": 21.01337393310917,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6648,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82850084471231,
          21.01326626823399
        ]
      },
      "properties": {
        "tenDoiTuon": "TTNĐ 16-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 14 Hồ Đắc Di",
        "tuDien": "TTNĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TTNĐ",
        "diaChiLapD": "",
        "longitude": 105.82850084471231,
        "latitude": 21.01326626823399,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6649,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8282835857864,
          21.013383948442712
        ]
      },
      "properties": {
        "tenDoiTuon": "TTNĐ 16-1-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 14 Hồ Đắc Di",
        "tuDien": "TTNĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TTNĐ",
        "diaChiLapD": "",
        "longitude": 105.8282835857864,
        "latitude": 21.013383948442712,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6650,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82809046673016,
          21.013496620895847
        ]
      },
      "properties": {
        "tenDoiTuon": "TTNĐ 16-1-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 14 Hồ Đắc Di",
        "tuDien": "TTNĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TTNĐ",
        "diaChiLapD": "",
        "longitude": 105.82809046673016,
        "latitude": 21.013496620895847,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6651,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82784906792558,
          21.01323872604464
        ]
      },
      "properties": {
        "tenDoiTuon": "TTNĐ 09",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Nhà VH Nam Đồng",
        "tuDien": "TTNĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TTNĐ",
        "diaChiLapD": "",
        "longitude": 105.82784906792558,
        "latitude": 21.01323872604464,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6652,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82833991217592,
          21.011755196032695
        ]
      },
      "properties": {
        "tenDoiTuon": "TTNĐ 20",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 119/1 Hồ Đắc Di",
        "tuDien": "TTNĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TTNĐ",
        "diaChiLapD": "",
        "longitude": 105.82833991217592,
        "latitude": 21.011755196032695,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6653,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82858935761271,
          21.011899167945785
        ]
      },
      "properties": {
        "tenDoiTuon": "TTNĐ 20-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 119/1 Hồ Đắc Di",
        "tuDien": "TTNĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TTNĐ",
        "diaChiLapD": "",
        "longitude": 105.82858935761271,
        "latitude": 21.011899167945785,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6654,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82873687911038,
          21.012164576674103
        ]
      },
      "properties": {
        "tenDoiTuon": "TTNĐ 20-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 119/1 Hồ Đắc Di",
        "tuDien": "TTNĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TTNĐ",
        "diaChiLapD": "",
        "longitude": 105.82873687911038,
        "latitude": 21.012164576674103,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6655,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82930014299671,
          21.011274454028012
        ]
      },
      "properties": {
        "tenDoiTuon": "TTNĐ 23",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 119/1 Hồ Đắc Di",
        "tuDien": "TTNĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TTNĐ",
        "diaChiLapD": "",
        "longitude": 105.82930014299671,
        "latitude": 21.011274454028012,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6656,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82865104841773,
          21.01161998750006
        ]
      },
      "properties": {
        "tenDoiTuon": "TTNĐ 21",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 119/1 Hồ Đắc Di",
        "tuDien": "TTNĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TTNĐ",
        "diaChiLapD": "",
        "longitude": 105.82865104841773,
        "latitude": 21.01161998750006,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6657,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82987681793973,
          21.010957714307807
        ]
      },
      "properties": {
        "tenDoiTuon": "TTNĐ 25",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 119/1 Hồ Đắc Di",
        "tuDien": "TTNĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TTNĐ",
        "diaChiLapD": "",
        "longitude": 105.82987681793973,
        "latitude": 21.010957714307807,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6658,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82891390490221,
          21.011489786575982
        ]
      },
      "properties": {
        "tenDoiTuon": "TTNĐ 22",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 119/1 Hồ Đắc Di",
        "tuDien": "TTNĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TTNĐ",
        "diaChiLapD": "",
        "longitude": 105.82891390490221,
        "latitude": 21.011489786575982,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6659,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8301664965106,
          21.010827512805694
        ]
      },
      "properties": {
        "tenDoiTuon": "TTNĐ 26",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 119/1 Hồ Đắc Di",
        "tuDien": "TTNĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TTNĐ",
        "diaChiLapD": "",
        "longitude": 105.8301664965106,
        "latitude": 21.010827512805694,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6660,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82960591483204,
          21.011109198605652
        ]
      },
      "properties": {
        "tenDoiTuon": "TTNĐ 24",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 119/1 Hồ Đắc Di",
        "tuDien": "TTNĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TTNĐ",
        "diaChiLapD": "",
        "longitude": 105.82960591483204,
        "latitude": 21.011109198605652,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6661,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82857058214952,
          21.01144972472986
        ]
      },
      "properties": {
        "tenDoiTuon": "TTNĐ 21-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 119/1 Hồ Đắc Di",
        "tuDien": "TTNĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TTNĐ",
        "diaChiLapD": "",
        "longitude": 105.82857058214952,
        "latitude": 21.01144972472986,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6662,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8283238189205,
          21.01128947723869
        ]
      },
      "properties": {
        "tenDoiTuon": "TTNĐ 21-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 119/1 Hồ Đắc Di",
        "tuDien": "TTNĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TTNĐ",
        "diaChiLapD": "",
        "longitude": 105.8283238189205,
        "latitude": 21.01128947723869,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6663,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82898900675492,
          21.011665057024835
        ]
      },
      "properties": {
        "tenDoiTuon": "TTNĐ 22-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 119/1 Hồ Đắc Di",
        "tuDien": "TTNĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TTNĐ",
        "diaChiLapD": "",
        "longitude": 105.82898900675492,
        "latitude": 21.011665057024835,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6664,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82879052328319,
          21.011775226915656
        ]
      },
      "properties": {
        "tenDoiTuon": "TTNĐ 22-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 119/1 Hồ Đắc Di",
        "tuDien": "TTNĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TTNĐ",
        "diaChiLapD": "",
        "longitude": 105.82879052328319,
        "latitude": 21.011775226915656,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6665,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8290748374386,
          21.011845334986106
        ]
      },
      "properties": {
        "tenDoiTuon": "TTNĐ 22-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 119/1 Hồ Đắc Di",
        "tuDien": "TTNĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TTNĐ",
        "diaChiLapD": "",
        "longitude": 105.8290748374386,
        "latitude": 21.011845334986106,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6666,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82890854048674,
          21.011925458454222
        ]
      },
      "properties": {
        "tenDoiTuon": "TTNĐ 22-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 119/1 Hồ Đắc Di",
        "tuDien": "TTNĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TTNĐ",
        "diaChiLapD": "",
        "longitude": 105.82890854048674,
        "latitude": 21.011925458454222,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6667,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82895145582857,
          21.011179306989106
        ]
      },
      "properties": {
        "tenDoiTuon": "TTNĐ 22-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 119/1 Hồ Đắc Di",
        "tuDien": "TTNĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TTNĐ",
        "diaChiLapD": "",
        "longitude": 105.82895145582857,
        "latitude": 21.011179306989106,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6668,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.828710057015,
          21.011054113424468
        ]
      },
      "properties": {
        "tenDoiTuon": "TTNĐ 22-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 119/1 Hồ Đắc Di",
        "tuDien": "TTNĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TTNĐ",
        "diaChiLapD": "",
        "longitude": 105.828710057015,
        "latitude": 21.011054113424468,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6669,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8290694730231,
          21.0111442528015
        ]
      },
      "properties": {
        "tenDoiTuon": "TTNĐ 23-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 119/1 Hồ Đắc Di",
        "tuDien": "TTNĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TTNĐ",
        "diaChiLapD": "",
        "longitude": 105.8290694730231,
        "latitude": 21.0111442528015,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6670,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82884953188946,
          21.01103909019009
        ]
      },
      "properties": {
        "tenDoiTuon": "TTNĐ 23-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 119/1 Hồ Đắc Di",
        "tuDien": "TTNĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TTNĐ",
        "diaChiLapD": "",
        "longitude": 105.82884953188946,
        "latitude": 21.01103909019009,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6671,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82934305834753,
          21.011029074699092
        ]
      },
      "properties": {
        "tenDoiTuon": "TTNĐ 24-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 119/1 Hồ Đắc Di",
        "tuDien": "TTNĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TTNĐ",
        "diaChiLapD": "",
        "longitude": 105.82934305834753,
        "latitude": 21.011029074699092,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6672,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82905874419217,
          21.010873834506974
        ]
      },
      "properties": {
        "tenDoiTuon": "TTNĐ 24-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 119/1 Hồ Đắc Di",
        "tuDien": "TTNĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TTNĐ",
        "diaChiLapD": "",
        "longitude": 105.82905874419217,
        "latitude": 21.010873834506974,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6673,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8288656251359,
          21.010788702720355
        ]
      },
      "properties": {
        "tenDoiTuon": "TTNĐ 24-7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 119/1 Hồ Đắc Di",
        "tuDien": "TTNĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TTNĐ",
        "diaChiLapD": "",
        "longitude": 105.8288656251359,
        "latitude": 21.010788702720355,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6674,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82940206694481,
          21.011524840682405
        ]
      },
      "properties": {
        "tenDoiTuon": "TTNĐ 23-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 119/1 Hồ Đắc Di",
        "tuDien": "TTNĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TTNĐ",
        "diaChiLapD": "",
        "longitude": 105.82940206694481,
        "latitude": 21.011524840682405,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6675,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82954154181029,
          21.011760203753894
        ]
      },
      "properties": {
        "tenDoiTuon": "TTNĐ 23-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 119/1 Hồ Đắc Di",
        "tuDien": "TTNĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TTNĐ",
        "diaChiLapD": "",
        "longitude": 105.82954154181029,
        "latitude": 21.011760203753894,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6676,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82977221178389,
          21.011269446290505
        ]
      },
      "properties": {
        "tenDoiTuon": "TTNĐ 24-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 119/1 Hồ Đắc Di",
        "tuDien": "TTNĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TTNĐ",
        "diaChiLapD": "",
        "longitude": 105.82977221178389,
        "latitude": 21.011269446290505,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6677,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82990095781838,
          21.011479771115265
        ]
      },
      "properties": {
        "tenDoiTuon": "TTNĐ 24-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 119/1 Hồ Đắc Di",
        "tuDien": "TTNĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TTNĐ",
        "diaChiLapD": "",
        "longitude": 105.82990095781838,
        "latitude": 21.011479771115265,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6678,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82970247435566,
          21.011594948869682
        ]
      },
      "properties": {
        "tenDoiTuon": "TTNĐ 24-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 119/1 Hồ Đắc Di",
        "tuDien": "TTNĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TTNĐ",
        "diaChiLapD": "",
        "longitude": 105.82970247435566,
        "latitude": 21.011594948869682,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6679,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82957372832115,
          21.011374608740347
        ]
      },
      "properties": {
        "tenDoiTuon": "TTNĐ 24-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 119/1 Hồ Đắc Di",
        "tuDien": "TTNĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TTNĐ",
        "diaChiLapD": "",
        "longitude": 105.82957372832115,
        "latitude": 21.011374608740347,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6680,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83001361059746,
          21.01112422183381
        ]
      },
      "properties": {
        "tenDoiTuon": "TTNĐ 25-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 119/1 Hồ Đắc Di",
        "tuDien": "TTNĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TTNĐ",
        "diaChiLapD": "",
        "longitude": 105.83001361059746,
        "latitude": 21.01112422183381,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6681,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.830131627792,
          21.011314515921182
        ]
      },
      "properties": {
        "tenDoiTuon": "TTNĐ 25-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 119/1 Hồ Đắc Di",
        "tuDien": "TTNĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TTNĐ",
        "diaChiLapD": "",
        "longitude": 105.830131627792,
        "latitude": 21.011314515921182,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6682,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83022818731563,
          21.011479771115265
        ]
      },
      "properties": {
        "tenDoiTuon": "TTNĐ 25-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 119/1 Hồ Đắc Di",
        "tuDien": "TTNĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TTNĐ",
        "diaChiLapD": "",
        "longitude": 105.83022818731563,
        "latitude": 21.011479771115265,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6683,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83029792475286,
          21.01099902822294
        ]
      },
      "properties": {
        "tenDoiTuon": "TTNĐ 26-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 119/1 Hồ Đắc Di",
        "tuDien": "TTNĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TTNĐ",
        "diaChiLapD": "",
        "longitude": 105.83029792475286,
        "latitude": 21.01099902822294,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6684,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83039984869197,
          21.01121936890783
        ]
      },
      "properties": {
        "tenDoiTuon": "TTNĐ 26-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 119/1 Hồ Đắc Di",
        "tuDien": "TTNĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TTNĐ",
        "diaChiLapD": "",
        "longitude": 105.83039984869197,
        "latitude": 21.01121936890783,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6685,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83037302660557,
          21.01141467060579
        ]
      },
      "properties": {
        "tenDoiTuon": "TTNĐ 26-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 119/1 Hồ Đắc Di",
        "tuDien": "TTNĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TTNĐ",
        "diaChiLapD": "",
        "longitude": 105.83037302660557,
        "latitude": 21.01141467060579,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6686,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83012626337651,
          21.01055333811603
        ]
      },
      "properties": {
        "tenDoiTuon": "TTNĐ 27",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 119/1 Hồ Đắc Di",
        "tuDien": "TTNĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TTNĐ",
        "diaChiLapD": "",
        "longitude": 105.83012626337651,
        "latitude": 21.01055333811603,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6687,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83024964499553,
          21.01047822167434
        ]
      },
      "properties": {
        "tenDoiTuon": "TTNĐ 28",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 119/1 Hồ Đắc Di",
        "tuDien": "TTNĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TTNĐ",
        "diaChiLapD": "",
        "longitude": 105.83024964499553,
        "latitude": 21.01047822167434,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6688,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83012089896104,
          21.010232841035695
        ]
      },
      "properties": {
        "tenDoiTuon": "TTNĐ 29",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 119/1 Hồ Đắc Di",
        "tuDien": "TTNĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TTNĐ",
        "diaChiLapD": "",
        "longitude": 105.83012089896104,
        "latitude": 21.010232841035695,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6689,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82981512713471,
          21.010343011985093
        ]
      },
      "properties": {
        "tenDoiTuon": "TTNĐ 30",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 119/1 Hồ Đắc Di",
        "tuDien": "TTNĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TTNĐ",
        "diaChiLapD": "",
        "longitude": 105.82981512713471,
        "latitude": 21.010343011985093,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6690,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82965419458934,
          21.010423136260137
        ]
      },
      "properties": {
        "tenDoiTuon": "TTNĐ 30-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 119/1 Hồ Đắc Di",
        "tuDien": "TTNĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TTNĐ",
        "diaChiLapD": "",
        "longitude": 105.82965419458934,
        "latitude": 21.010423136260137,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6691,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82980976271025,
          21.010723601909337
        ]
      },
      "properties": {
        "tenDoiTuon": "TTNĐ 30-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 119/1 Hồ Đắc Di",
        "tuDien": "TTNĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TTNĐ",
        "diaChiLapD": "",
        "longitude": 105.82980976271025,
        "latitude": 21.010723601909337,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6692,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82344387491244,
          21.01634125915203
        ]
      },
      "properties": {
        "tenDoiTuon": "HT 03",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "khu TT Hà Thủy-Tổ 71 Hoàng Cầu",
        "tuDien": "HT",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "HT",
        "diaChiLapD": "",
        "longitude": 105.82344387491244,
        "latitude": 21.01634125915203,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6693,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82377646883415,
          21.016506508777585
        ]
      },
      "properties": {
        "tenDoiTuon": "HT 02",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "khu TT Hà Thủy-Tổ 71 Hoàng Cầu",
        "tuDien": "HT",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "HT",
        "diaChiLapD": "",
        "longitude": 105.82377646883415,
        "latitude": 21.016506508777585,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6694,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82400713880776,
          21.01640635751118
        ]
      },
      "properties": {
        "tenDoiTuon": "HT 01",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "khu TT Hà Thủy-Tổ 71 Hoàng Cầu",
        "tuDien": "HT",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "HT",
        "diaChiLapD": "",
        "longitude": 105.82400713880776,
        "latitude": 21.01640635751118,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6695,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82428608854767,
          21.016281168333837
        ]
      },
      "properties": {
        "tenDoiTuon": "HT 04",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "khu TT Hà Thủy-Tổ 71 Hoàng Cầu",
        "tuDien": "HT",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "HT",
        "diaChiLapD": "",
        "longitude": 105.82428608854767,
        "latitude": 21.016281168333837,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6696,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82390521486865,
          21.01671682621748
        ]
      },
      "properties": {
        "tenDoiTuon": "HT 08",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "khu TT Hà Thủy-Tổ 71 Hoàng Cầu",
        "tuDien": "HT",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "HT",
        "diaChiLapD": "",
        "longitude": 105.82390521486865,
        "latitude": 21.01671682621748,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6697,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82410369833138,
          21.016631697765774
        ]
      },
      "properties": {
        "tenDoiTuon": "HT 07",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "khu TT Hà Thủy-Tổ 71 Hoàng Cầu",
        "tuDien": "HT",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "HT",
        "diaChiLapD": "",
        "longitude": 105.82410369833138,
        "latitude": 21.016631697765774,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6698,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82433973272947,
          21.01651151633927
        ]
      },
      "properties": {
        "tenDoiTuon": "HT 06",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "khu TT Hà Thủy-Tổ 71 Hoàng Cầu",
        "tuDien": "HT",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "HT",
        "diaChiLapD": "",
        "longitude": 105.82433973272947,
        "latitude": 21.01651151633927,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6699,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82444702108405,
          21.01655157682494
        ]
      },
      "properties": {
        "tenDoiTuon": "HT 05",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "khu TT Hà Thủy-Tổ 71 Hoàng Cầu",
        "tuDien": "HT",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "HT",
        "diaChiLapD": "",
        "longitude": 105.82444702108405,
        "latitude": 21.01655157682494,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6700,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82449530085036,
          21.016831999927795
        ]
      },
      "properties": {
        "tenDoiTuon": "HT 16",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "khu TT Hà Thủy-Tổ 71 Hoàng Cầu",
        "tuDien": "HT",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "HT",
        "diaChiLapD": "",
        "longitude": 105.82449530085036,
        "latitude": 21.016831999927795,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6701,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82471524199302,
          21.016736856434115
        ]
      },
      "properties": {
        "tenDoiTuon": "HT 17",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "khu TT Hà Thủy-Tổ 71 Hoàng Cầu",
        "tuDien": "HT",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "HT",
        "diaChiLapD": "",
        "longitude": 105.82471524199302,
        "latitude": 21.016736856434115,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6702,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82496200522206,
          21.016626690208145
        ]
      },
      "properties": {
        "tenDoiTuon": "HT 18",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "khu TT Hà Thủy-Tổ 71 Hoàng Cầu",
        "tuDien": "HT",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "HT",
        "diaChiLapD": "",
        "longitude": 105.82496200522206,
        "latitude": 21.016626690208145,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6703,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82526777704837,
          21.016626690208145
        ]
      },
      "properties": {
        "tenDoiTuon": "HT 19",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "khu TT Hà Thủy-Tổ 71 Hoàng Cầu",
        "tuDien": "HT",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "HT",
        "diaChiLapD": "",
        "longitude": 105.82526777704837,
        "latitude": 21.016626690208145,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6704,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82548235376655,
          21.016842015029123
        ]
      },
      "properties": {
        "tenDoiTuon": "HT 20",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "khu TT Hà Thủy-Tổ 71 Hoàng Cầu",
        "tuDien": "HT",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "HT",
        "diaChiLapD": "",
        "longitude": 105.82548235376655,
        "latitude": 21.016842015029123,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6705,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82572375258012,
          21.01671682621748
        ]
      },
      "properties": {
        "tenDoiTuon": "HT 21",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "khu TT Hà Thủy-Tổ 71 Hoàng Cầu",
        "tuDien": "HT",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "HT",
        "diaChiLapD": "",
        "longitude": 105.82572375258012,
        "latitude": 21.01671682621748,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6706,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82468841989761,
          21.016461440715737
        ]
      },
      "properties": {
        "tenDoiTuon": "HT 09",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "khu TT Hà Thủy-Tổ 71 Hoàng Cầu",
        "tuDien": "HT",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "HT",
        "diaChiLapD": "",
        "longitude": 105.82468841989761,
        "latitude": 21.016461440715737,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6707,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8244094701577,
          21.0159606835616
        ]
      },
      "properties": {
        "tenDoiTuon": "HT 12",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "khu TT Hà Thủy-Tổ 71 Hoàng Cầu",
        "tuDien": "HT",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "HT",
        "diaChiLapD": "",
        "longitude": 105.8244094701577,
        "latitude": 21.0159606835616,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6708,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82457040270307,
          21.016236100204754
        ]
      },
      "properties": {
        "tenDoiTuon": "HT 11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "khu TT Hà Thủy-Tổ 71 Hoàng Cầu",
        "tuDien": "HT",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "HT",
        "diaChiLapD": "",
        "longitude": 105.82457040270307,
        "latitude": 21.016236100204754,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6709,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8241788001841,
          21.016040804819024
        ]
      },
      "properties": {
        "tenDoiTuon": "HT 14",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "khu TT Hà Thủy-Tổ 71 Hoàng Cầu",
        "tuDien": "HT",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "HT",
        "diaChiLapD": "",
        "longitude": 105.8241788001841,
        "latitude": 21.016040804819024,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6710,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82462404688485,
          21.015835494010137
        ]
      },
      "properties": {
        "tenDoiTuon": "HT 13",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "khu TT Hà Thủy-Tổ 71 Hoàng Cầu",
        "tuDien": "HT",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "HT",
        "diaChiLapD": "",
        "longitude": 105.82462404688485,
        "latitude": 21.015835494010137,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6711,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82400177439229,
          21.016135948756776
        ]
      },
      "properties": {
        "tenDoiTuon": "HT 15",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "khu TT Hà Thủy-Tổ 71 Hoàng Cầu",
        "tuDien": "HT",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "HT",
        "diaChiLapD": "",
        "longitude": 105.82400177439229,
        "latitude": 21.016135948756776,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6712,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82495664079758,
          21.01634125915203
        ]
      },
      "properties": {
        "tenDoiTuon": "HT 10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "khu TT Hà Thủy-Tổ 71 Hoàng Cầu",
        "tuDien": "HT",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "HT",
        "diaChiLapD": "",
        "longitude": 105.82495664079758,
        "latitude": 21.01634125915203,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6713,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82485471685847,
          21.0161760093442
        ]
      },
      "properties": {
        "tenDoiTuon": "HT 10-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "khu TT Hà Thủy-Tổ 71 Hoàng Cầu",
        "tuDien": "HT",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "HT",
        "diaChiLapD": "",
        "longitude": 105.82485471685847,
        "latitude": 21.0161760093442,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6714,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82494591196662,
          21.016105903309356
        ]
      },
      "properties": {
        "tenDoiTuon": "HT 10-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "khu TT Hà Thủy-Tổ 71 Hoàng Cầu",
        "tuDien": "HT",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "HT",
        "diaChiLapD": "",
        "longitude": 105.82494591196662,
        "latitude": 21.016105903309356,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6715,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82985569554702,
          21.01808513422133
        ]
      },
      "properties": {
        "tenDoiTuon": "KLOCD/47",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Đường phố - đèn pha",
        "tenDuongPh": "Xã Đàn",
        "tuDien": "KLOCD",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 14,
        "tuDien2": "Kim Liên OCD",
        "diaChiLapD": "",
        "longitude": 105.82985569554702,
        "latitude": 21.01808513422133,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6716,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82996030170285,
          21.018067607940083
        ]
      },
      "properties": {
        "tenDoiTuon": "KLOCD/55",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Xã Đàn",
        "tuDien": "KLOCD",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Sân vườn",
        "chieuCao": 6,
        "tuDien2": "Kim Liên OCD",
        "diaChiLapD": "",
        "longitude": 105.82996030170285,
        "latitude": 21.018067607940083,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6717,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83004836756565,
          21.01800209301367
        ]
      },
      "properties": {
        "tenDoiTuon": "KLOCD/54",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Xã Đàn",
        "tuDien": "KLOCD",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Sân vườn",
        "chieuCao": 6,
        "tuDien2": "Kim Liên OCD",
        "diaChiLapD": "",
        "longitude": 105.83004836756565,
        "latitude": 21.01800209301367,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6718,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83013643342841,
          21.01793657805847
        ]
      },
      "properties": {
        "tenDoiTuon": "KLOCD/53",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Xã Đàn",
        "tuDien": "KLOCD",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Sân vườn",
        "chieuCao": 6,
        "tuDien2": "Kim Liên OCD",
        "diaChiLapD": "",
        "longitude": 105.83013643342841,
        "latitude": 21.01793657805847,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6719,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83022449929122,
          21.0178710630745
        ]
      },
      "properties": {
        "tenDoiTuon": "KLOCD/52",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Xã Đàn",
        "tuDien": "KLOCD",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Sân vườn",
        "chieuCao": 6,
        "tuDien2": "Kim Liên OCD",
        "diaChiLapD": "",
        "longitude": 105.83022449929122,
        "latitude": 21.0178710630745,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6720,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.829897269785,
          21.0179975027953
        ]
      },
      "properties": {
        "tenDoiTuon": "KLOCD/48",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Xã Đàn",
        "tuDien": "KLOCD",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Sân vườn",
        "chieuCao": 6,
        "tuDien2": "Kim Liên OCD",
        "diaChiLapD": "",
        "longitude": 105.829897269785,
        "latitude": 21.0179975027953,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6721,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82998622971702,
          21.01793282242364
        ]
      },
      "properties": {
        "tenDoiTuon": "KLOCD/49",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Xã Đàn",
        "tuDien": "KLOCD",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Sân vườn",
        "chieuCao": 6,
        "tuDien2": "Kim Liên OCD",
        "diaChiLapD": "",
        "longitude": 105.82998622971702,
        "latitude": 21.01793282242364,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6722,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83007518964905,
          21.01786814202384
        ]
      },
      "properties": {
        "tenDoiTuon": "KLOCD/50",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Xã Đàn",
        "tuDien": "KLOCD",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Sân vườn",
        "chieuCao": 6,
        "tuDien2": "Kim Liên OCD",
        "diaChiLapD": "",
        "longitude": 105.83007518964905,
        "latitude": 21.01786814202384,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6723,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8301641495811,
          21.01780346159603
        ]
      },
      "properties": {
        "tenDoiTuon": "KLOCD/51",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Xã Đàn",
        "tuDien": "KLOCD",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Sân vườn",
        "chieuCao": 6,
        "tuDien2": "Kim Liên OCD",
        "diaChiLapD": "",
        "longitude": 105.8301641495811,
        "latitude": 21.01780346159603,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6724,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83007161336907,
          21.018351783819263
        ]
      },
      "properties": {
        "tenDoiTuon": "KLOCD/46",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Xã Đàn",
        "tuDien": "KLOCD",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Kim Liên OCD",
        "diaChiLapD": "",
        "longitude": 105.83007161336907,
        "latitude": 21.018351783819263,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6725,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8301279397586,
          21.01811643114964
        ]
      },
      "properties": {
        "tenDoiTuon": "KLOCD/45",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Xã Đàn",
        "tuDien": "KLOCD",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Kim Liên OCD",
        "diaChiLapD": "",
        "longitude": 105.8301279397586,
        "latitude": 21.01811643114964,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6726,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83055038768458,
          21.017775920245416
        ]
      },
      "properties": {
        "tenDoiTuon": "KLOCD/44",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Xã Đàn",
        "tuDien": "KLOCD",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Kim Liên OCD",
        "diaChiLapD": "",
        "longitude": 105.83055038768458,
        "latitude": 21.017775920245416,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6727,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8310519607659,
          21.01737031065537
        ]
      },
      "properties": {
        "tenDoiTuon": "KLOCD/43",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Xã Đàn",
        "tuDien": "KLOCD",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Kim Liên OCD",
        "diaChiLapD": "",
        "longitude": 105.8310519607659,
        "latitude": 21.01737031065537,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6728,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83153341728017,
          21.016987233917796
        ]
      },
      "properties": {
        "tenDoiTuon": "KLOCD/27",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Xã Đàn",
        "tuDien": "KLOCD",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Kim Liên OCD",
        "diaChiLapD": "",
        "longitude": 105.83153341728017,
        "latitude": 21.016987233917796,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6729,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83202157932277,
          21.016614171313694
        ]
      },
      "properties": {
        "tenDoiTuon": "KLOCD/26",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Xã Đàn",
        "tuDien": "KLOCD",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Kim Liên OCD",
        "diaChiLapD": "",
        "longitude": 105.83202157932277,
        "latitude": 21.016614171313694,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6730,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83250169474218,
          21.016186024488743
        ]
      },
      "properties": {
        "tenDoiTuon": "KLOCD/25",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Xã Đàn",
        "tuDien": "KLOCD",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Kim Liên OCD",
        "diaChiLapD": "",
        "longitude": 105.83250169474218,
        "latitude": 21.016186024488743,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6731,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83292280155527,
          21.015745357468123
        ]
      },
      "properties": {
        "tenDoiTuon": "KLOCD/24",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Xã Đàn",
        "tuDien": "KLOCD",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Kim Liên OCD",
        "diaChiLapD": "",
        "longitude": 105.83292280155527,
        "latitude": 21.015745357468123,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6732,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83334122616066,
          21.01532221575054
        ]
      },
      "properties": {
        "tenDoiTuon": "KLOCD/23",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Xã Đàn",
        "tuDien": "KLOCD",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Kim Liên OCD",
        "diaChiLapD": "",
        "longitude": 105.83334122616066,
        "latitude": 21.01532221575054,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6733,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8337167354242,
          21.014921607101957
        ]
      },
      "properties": {
        "tenDoiTuon": "KLOCD/22",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Xã Đàn",
        "tuDien": "KLOCD",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Kim Liên OCD",
        "diaChiLapD": "",
        "longitude": 105.8337167354242,
        "latitude": 21.014921607101957,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6734,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83412845450124,
          21.0144972111406
        ]
      },
      "properties": {
        "tenDoiTuon": "KLOCD/21",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Xã Đàn",
        "tuDien": "KLOCD",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Kim Liên OCD",
        "diaChiLapD": "",
        "longitude": 105.83412845450124,
        "latitude": 21.0144972111406,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6735,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83458308893809,
          21.0140302489964
        ]
      },
      "properties": {
        "tenDoiTuon": "KLOCD/20",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Xã Đàn",
        "tuDien": "KLOCD",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Kim Liên OCD",
        "diaChiLapD": "",
        "longitude": 105.83458308893809,
        "latitude": 21.0140302489964,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6736,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8350149245822,
          21.013579560286455
        ]
      },
      "properties": {
        "tenDoiTuon": "KLOCD/19",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Xã Đàn",
        "tuDien": "KLOCD",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Kim Liên OCD",
        "diaChiLapD": "",
        "longitude": 105.8350149245822,
        "latitude": 21.013579560286455,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6737,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83544944244301,
          21.0131338778898
        ]
      },
      "properties": {
        "tenDoiTuon": "KLOCD/18",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Xã Đàn",
        "tuDien": "KLOCD",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Kim Liên OCD",
        "diaChiLapD": "",
        "longitude": 105.83544944244301,
        "latitude": 21.0131338778898,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6738,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83587323147287,
          21.012685690315983
        ]
      },
      "properties": {
        "tenDoiTuon": "KLOCD/17",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Xã Đàn",
        "tuDien": "KLOCD",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Kim Liên OCD",
        "diaChiLapD": "",
        "longitude": 105.83587323147287,
        "latitude": 21.012685690315983,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6739,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83631043154139,
          21.012234997543388
        ]
      },
      "properties": {
        "tenDoiTuon": "KLOCD/16",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Xã Đàn",
        "tuDien": "KLOCD",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Kim Liên OCD",
        "diaChiLapD": "",
        "longitude": 105.83631043154139,
        "latitude": 21.012234997543388,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6740,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83674092608163,
          21.011778043759165
        ]
      },
      "properties": {
        "tenDoiTuon": "KLOCD/15",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Xã Đàn",
        "tuDien": "KLOCD",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Kim Liên OCD",
        "diaChiLapD": "",
        "longitude": 105.83674092608163,
        "latitude": 21.011778043759165,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6741,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83604757508388,
          21.02360080294233
        ]
      },
      "properties": {
        "tenDoiTuon": "15-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Hẻm 33/38",
        "tuDien": "VC4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 7,
        "tuDien2": "VC4",
        "diaChiLapD": "",
        "longitude": 105.83604757508388,
        "latitude": 21.02360080294233,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6742,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83617229780677,
          21.023529448566666
        ]
      },
      "properties": {
        "tenDoiTuon": "15-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Hẻm 33/38",
        "tuDien": "VC4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 7,
        "tuDien2": "VC4",
        "diaChiLapD": "",
        "longitude": 105.83617229780677,
        "latitude": 21.023529448566666,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6743,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83630104384126,
          21.023416783694053
        ]
      },
      "properties": {
        "tenDoiTuon": "VC4 15-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Hẻm 33/38",
        "tuDien": "VC4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "VC4",
        "diaChiLapD": "",
        "longitude": 105.83630104384126,
        "latitude": 21.023416783694053,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6744,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83628327420374,
          21.023849604113735
        ]
      },
      "properties": {
        "tenDoiTuon": "16-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Linh Quang",
        "tuDien": "VC4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VC4",
        "diaChiLapD": "",
        "longitude": 105.83628327420374,
        "latitude": 21.023849604113735,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6745,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83640799691766,
          21.023885907143566
        ]
      },
      "properties": {
        "tenDoiTuon": "16-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Linh Quang",
        "tuDien": "VC4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VC4",
        "diaChiLapD": "",
        "longitude": 105.83640799691766,
        "latitude": 21.023885907143566,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6746,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83638385703898,
          21.024047392925738
        ]
      },
      "properties": {
        "tenDoiTuon": "16-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Linh Quang",
        "tuDien": "VC4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VC4",
        "diaChiLapD": "",
        "longitude": 105.83638385703898,
        "latitude": 21.024047392925738,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6747,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83651528528124,
          21.02418133836469
        ]
      },
      "properties": {
        "tenDoiTuon": "VC4 16-3-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Linh Quang",
        "tuDien": "VC4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "VC4",
        "diaChiLapD": "",
        "longitude": 105.83651528528124,
        "latitude": 21.02418133836469,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6748,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83561985176547,
          21.013276596554423
        ]
      },
      "properties": {
        "tenDoiTuon": "CN 11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Khâm Thiên, Đê La Thành",
        "tuDien": "CNC",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "CNC",
        "diaChiLapD": "",
        "longitude": 105.83561985176547,
        "latitude": 21.013276596554423,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6749,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83544819038912,
          21.013399284421148
        ]
      },
      "properties": {
        "tenDoiTuon": "CN 12",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Khâm Thiên, Đê La Thành",
        "tuDien": "CNC",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "CNC",
        "diaChiLapD": "",
        "longitude": 105.83544819038912,
        "latitude": 21.013399284421148,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6750,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83534090202554,
          21.01358957560619
        ]
      },
      "properties": {
        "tenDoiTuon": "CN 13",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Khâm Thiên, Đê La Thành",
        "tuDien": "CNC",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "CNC",
        "diaChiLapD": "",
        "longitude": 105.83534090202554,
        "latitude": 21.01358957560619,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6751,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83516054636155,
          21.013787647460546
        ]
      },
      "properties": {
        "tenDoiTuon": "CN 14",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Khâm Thiên, Đê La Thành",
        "tuDien": "CNC",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "CNC",
        "diaChiLapD": "",
        "longitude": 105.83516054636155,
        "latitude": 21.013787647460546,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6752,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83504317683143,
          21.0139275922434
        ]
      },
      "properties": {
        "tenDoiTuon": "CN 15",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Khâm Thiên, Đê La Thành",
        "tuDien": "CNC",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "CNC",
        "diaChiLapD": "",
        "longitude": 105.83504317683143,
        "latitude": 21.0139275922434,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6753,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8348635802899,
          21.014145684697276
        ]
      },
      "properties": {
        "tenDoiTuon": "CN 16",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Khâm Thiên, Đê La Thành",
        "tuDien": "CNC",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "CNC",
        "diaChiLapD": "",
        "longitude": 105.8348635802899,
        "latitude": 21.014145684697276,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6754,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83478843212944,
          21.014217830543828
        ]
      },
      "properties": {
        "tenDoiTuon": "CNC 17",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Khâm Thiên, Đê La Thành",
        "tuDien": "CNC",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "CNC",
        "diaChiLapD": "",
        "longitude": 105.83478843212944,
        "latitude": 21.014217830543828,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6755,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83572781173851,
          21.013109253216076
        ]
      },
      "properties": {
        "tenDoiTuon": "CN 1-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Phố Chợ Khâm Thiên, Đê La Thành",
        "tuDien": "CNC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "CNC",
        "diaChiLapD": "",
        "longitude": 105.83572781173851,
        "latitude": 21.013109253216076,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6756,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83616391590532,
          21.024017349071634
        ]
      },
      "properties": {
        "tenDoiTuon": "16-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 46 Linh Quang",
        "tuDien": "VC4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VC4",
        "diaChiLapD": "",
        "longitude": 105.83616391590532,
        "latitude": 21.024017349071634,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6757,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83552420905343,
          21.02461446951959
        ]
      },
      "properties": {
        "tenDoiTuon": "VC4 16-13",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 46 Linh Quang",
        "tuDien": "VC4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VC4",
        "diaChiLapD": "",
        "longitude": 105.83552420905343,
        "latitude": 21.02461446951959,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6758,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83590508274145,
          21.02443295517083
        ]
      },
      "properties": {
        "tenDoiTuon": "16-11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 46 Linh Quang",
        "tuDien": "VC4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VC4",
        "diaChiLapD": "",
        "longitude": 105.83590508274145,
        "latitude": 21.02443295517083,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6759,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83571174856051,
          21.024522015022175
        ]
      },
      "properties": {
        "tenDoiTuon": "16-12",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 46 Linh Quang",
        "tuDien": "VC4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "VC4",
        "diaChiLapD": "",
        "longitude": 105.83571174856051,
        "latitude": 21.024522015022175,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6760,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83579779437785,
          21.024281484407126
        ]
      },
      "properties": {
        "tenDoiTuon": "16-9",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 46 Linh Quang",
        "tuDien": "VC4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VC4",
        "diaChiLapD": "",
        "longitude": 105.83579779437785,
        "latitude": 21.024281484407126,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6761,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83564356736083,
          21.02438788950481
        ]
      },
      "properties": {
        "tenDoiTuon": "16-10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 46 Linh Quang",
        "tuDien": "VC4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VC4",
        "diaChiLapD": "",
        "longitude": 105.83564356736083,
        "latitude": 21.02438788950481,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6762,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83591715267627,
          21.024216389486153
        ]
      },
      "properties": {
        "tenDoiTuon": "16-8",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 46 Linh Quang",
        "tuDien": "VC4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VC4",
        "diaChiLapD": "",
        "longitude": 105.83591715267627,
        "latitude": 21.024216389486153,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6763,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83585680297513,
          21.024121250705278
        ]
      },
      "properties": {
        "tenDoiTuon": "16-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 46 Linh Quang",
        "tuDien": "VC4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VC4",
        "diaChiLapD": "",
        "longitude": 105.83585680297513,
        "latitude": 21.024121250705278,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6764,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83548263481546,
          21.024370363965268
        ]
      },
      "properties": {
        "tenDoiTuon": "16-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 46 Linh Quang",
        "tuDien": "VC4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VC4",
        "diaChiLapD": "",
        "longitude": 105.83548263481546,
        "latitude": 21.024370363965268,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6765,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83638223295317,
          21.022271284087132
        ]
      },
      "properties": {
        "tenDoiTuon": "VC4 2-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Khu A TT Văn Chương",
        "tuDien": "VC4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VC4",
        "diaChiLapD": "",
        "longitude": 105.83638223295317,
        "latitude": 21.022271284087132,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6766,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83611764779931,
          21.022242873248832
        ]
      },
      "properties": {
        "tenDoiTuon": "VC4 3-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Khu A TT Văn Chương",
        "tuDien": "VC4",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VC4",
        "diaChiLapD": "",
        "longitude": 105.83611764779931,
        "latitude": 21.022242873248832,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6767,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83102245646276,
          21.011402464257493
        ]
      },
      "properties": {
        "tenDoiTuon": "B1NĐ 30",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Đặng Văn Ngữ",
        "tuDien": "B1NĐ",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Sân vườn",
        "chieuCao": 7,
        "tuDien2": "B1 Nam Đồng",
        "diaChiLapD": "",
        "longitude": 105.83102245646276,
        "latitude": 21.011402464257493,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6768,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83734987659449,
          21.01562893101977
        ]
      },
      "properties": {
        "tenDoiTuon": "CNC 10-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 188 + 192 Phố Chợ Khâm Thiên",
        "tuDien": "CNC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "CNC",
        "diaChiLapD": "",
        "longitude": 105.83734987659449,
        "latitude": 21.01562893101977,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6769,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83732305449912,
          21.015533786758542
        ]
      },
      "properties": {
        "tenDoiTuon": "CNC 9-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 188 + 192 Phố Chợ Khâm Thiên",
        "tuDien": "CNC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "CNC",
        "diaChiLapD": "",
        "longitude": 105.83732305449912,
        "latitude": 21.015533786758542,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6770,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83709238452549,
          21.015205788971866
        ]
      },
      "properties": {
        "tenDoiTuon": "CNC 8-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 218 Phố Chợ Khâm Thiêm",
        "tuDien": "CNC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "CNC",
        "diaChiLapD": "",
        "longitude": 105.83709238452549,
        "latitude": 21.015205788971866,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6771,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83690116199814,
          21.01536487383762
        ]
      },
      "properties": {
        "tenDoiTuon": "CNC 8-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 218 Phố Chợ Khâm Thiêm",
        "tuDien": "CNC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "CNC",
        "diaChiLapD": "",
        "longitude": 105.83690116199814,
        "latitude": 21.01536487383762,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6772,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83666323108017,
          21.015508748785166
        ]
      },
      "properties": {
        "tenDoiTuon": "CNC 8-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 218 Phố Chợ Khâm Thiêm",
        "tuDien": "CNC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "CNC",
        "diaChiLapD": "",
        "longitude": 105.83666323108017,
        "latitude": 21.015508748785166,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6773,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83653984947013,
          21.01560138926586
        ]
      },
      "properties": {
        "tenDoiTuon": "CNC 8-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 218 Phố Chợ Khâm Thiêm",
        "tuDien": "CNC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "CNC",
        "diaChiLapD": "",
        "longitude": 105.83653984947013,
        "latitude": 21.01560138926586,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6774,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83628481288969,
          21.015444513751643
        ]
      },
      "properties": {
        "tenDoiTuon": "CNC 8-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 218 Phố Chợ Khâm Thiêm",
        "tuDien": "CNC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "CNC",
        "diaChiLapD": "",
        "longitude": 105.83628481288969,
        "latitude": 21.015444513751643,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6775,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81859092065235,
          21.001545198709294
        ]
      },
      "properties": {
        "tenDoiTuon": "NTS2 14",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 9,
        "maXaPhuong": 349,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Nguyễn Trãi",
        "tuDien": "CVNTS2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "CV NTS Tủ 2",
        "diaChiLapD": "",
        "longitude": 105.81859092065235,
        "latitude": 21.001545198709294,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6776,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81888730474262,
          21.001781829538327
        ]
      },
      "properties": {
        "tenDoiTuon": "15",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 9,
        "maXaPhuong": 349,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Nguyễn Trãi",
        "tuDien": "CVNTS2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "CV NTS Tủ 2",
        "diaChiLapD": "",
        "longitude": 105.81888730474262,
        "latitude": 21.001781829538327,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6777,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81917698332248,
          21.00201971200275
        ]
      },
      "properties": {
        "tenDoiTuon": "16",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 9,
        "maXaPhuong": 349,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Nguyễn Trãi",
        "tuDien": "CVNTS2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "CV NTS Tủ 2",
        "diaChiLapD": "",
        "longitude": 105.81917698332248,
        "latitude": 21.00201971200275,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6778,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8194559330534,
          21.00225008202897
        ]
      },
      "properties": {
        "tenDoiTuon": "17",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 9,
        "maXaPhuong": 349,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Nguyễn Trãi",
        "tuDien": "CVNTS2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "CV NTS Tủ 2",
        "diaChiLapD": "",
        "longitude": 105.8194559330534,
        "latitude": 21.00225008202897,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6779,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81972951837783,
          21.00247043563327
        ]
      },
      "properties": {
        "tenDoiTuon": "18",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 9,
        "maXaPhuong": 349,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Nguyễn Trãi",
        "tuDien": "CVNTS2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "CV NTS Tủ 2",
        "diaChiLapD": "",
        "longitude": 105.81972951837783,
        "latitude": 21.00247043563327,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6780,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81997896381462,
          21.002660740757737
        ]
      },
      "properties": {
        "tenDoiTuon": "NTS2 19",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 9,
        "maXaPhuong": 349,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Nguyễn Trãi",
        "tuDien": "CVNTS2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "CV NTS Tủ 2",
        "diaChiLapD": "",
        "longitude": 105.81997896381462,
        "latitude": 21.002660740757737,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6781,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8184259647954,
          21.002104848792634
        ]
      },
      "properties": {
        "tenDoiTuon": "NTS2 01",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Nguyễn Trãi",
        "tuDien": "CVNTS2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "CV NTS Tủ 2",
        "diaChiLapD": "",
        "longitude": 105.8184259647954,
        "latitude": 21.002104848792634,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6782,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8185951115447,
          21.002222381301635
        ]
      },
      "properties": {
        "tenDoiTuon": "02",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Nguyễn Trãi",
        "tuDien": "CVNTS2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "CV NTS Tủ 2",
        "diaChiLapD": "",
        "longitude": 105.8185951115447,
        "latitude": 21.002222381301635,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6783,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81887339073715,
          21.002408304711103
        ]
      },
      "properties": {
        "tenDoiTuon": "03",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Nguyễn Trãi",
        "tuDien": "CVNTS2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "CV NTS Tủ 2",
        "diaChiLapD": "",
        "longitude": 105.81887339073715,
        "latitude": 21.002408304711103,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6784,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81912283617395,
          21.002578577799824
        ]
      },
      "properties": {
        "tenDoiTuon": "04",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Nguyễn Trãi",
        "tuDien": "CVNTS2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "CV NTS Tủ 2",
        "diaChiLapD": "",
        "longitude": 105.81912283617395,
        "latitude": 21.002578577799824,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6785,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8193615527708,
          21.002776394820152
        ]
      },
      "properties": {
        "tenDoiTuon": "NTS2 05",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Nguyễn Trãi",
        "tuDien": "CVNTS2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "CV NTS Tủ 2",
        "diaChiLapD": "",
        "longitude": 105.8193615527708,
        "latitude": 21.002776394820152,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6786,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82067851740094,
          21.003692860125007
        ]
      },
      "properties": {
        "tenDoiTuon": "NTS2 48",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Tây Sơn",
        "tuDien": "CVNTS2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "CV NTS Tủ 2",
        "diaChiLapD": "",
        "longitude": 105.82067851740094,
        "latitude": 21.003692860125007,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6787,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82092259842223,
          21.00388316369063
        ]
      },
      "properties": {
        "tenDoiTuon": "49",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Tây Sơn",
        "tuDien": "CVNTS2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "CV NTS Tủ 2",
        "diaChiLapD": "",
        "longitude": 105.82092259842223,
        "latitude": 21.00388316369063,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6788,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82117472606676,
          21.00408849094939
        ]
      },
      "properties": {
        "tenDoiTuon": "50",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Tây Sơn",
        "tuDien": "CVNTS2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "CV NTS Tủ 2",
        "diaChiLapD": "",
        "longitude": 105.82117472606676,
        "latitude": 21.00408849094939,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6789,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8214214892958,
          21.004286305968233
        ]
      },
      "properties": {
        "tenDoiTuon": "51",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Tây Sơn",
        "tuDien": "CVNTS2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "CV NTS Tủ 2",
        "diaChiLapD": "",
        "longitude": 105.8214214892958,
        "latitude": 21.004286305968233,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6790,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82166557031711,
          21.004511664531282
        ]
      },
      "properties": {
        "tenDoiTuon": "52",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Tây Sơn",
        "tuDien": "CVNTS2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "CV NTS Tủ 2",
        "diaChiLapD": "",
        "longitude": 105.82166557031711,
        "latitude": 21.004511664531282,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6791,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8217996807671,
          21.004742030709938
        ]
      },
      "properties": {
        "tenDoiTuon": "53",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Tây Sơn",
        "tuDien": "CVNTS2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "CV NTS Tủ 2",
        "diaChiLapD": "",
        "longitude": 105.8217996807671,
        "latitude": 21.004742030709938,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6792,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8219820709834,
          21.005014964091743
        ]
      },
      "properties": {
        "tenDoiTuon": "54",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Tây Sơn",
        "tuDien": "CVNTS2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "CV NTS Tủ 2",
        "diaChiLapD": "",
        "longitude": 105.8219820709834,
        "latitude": 21.005014964091743,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6793,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82218055444612,
          21.005280385067852
        ]
      },
      "properties": {
        "tenDoiTuon": "55",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Tây Sơn",
        "tuDien": "CVNTS2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "CV NTS Tủ 2",
        "diaChiLapD": "",
        "longitude": 105.82218055444612,
        "latitude": 21.005280385067852,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6794,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82230125385739,
          21.00552326988604
        ]
      },
      "properties": {
        "tenDoiTuon": "56",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Tây Sơn",
        "tuDien": "CVNTS2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "CV NTS Tủ 2",
        "diaChiLapD": "",
        "longitude": 105.82230125385739,
        "latitude": 21.00552326988604,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6795,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82237099128564,
          21.005731098850234
        ]
      },
      "properties": {
        "tenDoiTuon": "57",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Tây Sơn",
        "tuDien": "CVNTS2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "CV NTS Tủ 2",
        "diaChiLapD": "",
        "longitude": 105.82237099128564,
        "latitude": 21.005731098850234,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6796,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82262446004302,
          21.006052857579487
        ]
      },
      "properties": {
        "tenDoiTuon": "58",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Tây Sơn",
        "tuDien": "CVNTS2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "CV NTS Tủ 2",
        "diaChiLapD": "",
        "longitude": 105.82262446004302,
        "latitude": 21.006052857579487,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6797,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82274113613369,
          21.006290733235666
        ]
      },
      "properties": {
        "tenDoiTuon": "59",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Tây Sơn",
        "tuDien": "CVNTS2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "CV NTS Tủ 2",
        "diaChiLapD": "",
        "longitude": 105.82274113613369,
        "latitude": 21.006290733235666,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6798,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82288597541465,
          21.006576183522675
        ]
      },
      "properties": {
        "tenDoiTuon": "NTS2 60",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Tây Sơn",
        "tuDien": "CVNTS2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "CV NTS Tủ 2",
        "diaChiLapD": "",
        "longitude": 105.82288597541465,
        "latitude": 21.006576183522675,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6799,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83685086095524,
          21.01467015743084
        ]
      },
      "properties": {
        "tenDoiTuon": "CNC 6-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 252+260 Phố Chợ Khâm Thiêm",
        "tuDien": "CNC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "CNC",
        "diaChiLapD": "",
        "longitude": 105.83685086095524,
        "latitude": 21.01467015743084,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6800,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8365538527242,
          21.014887352779354
        ]
      },
      "properties": {
        "tenDoiTuon": "CNC 6-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 252+260 Phố Chợ Khâm Thiêm",
        "tuDien": "CNC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "CNC",
        "diaChiLapD": "",
        "longitude": 105.8365538527242,
        "latitude": 21.014887352779354,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6801,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83632984911215,
          21.015041794022462
        ]
      },
      "properties": {
        "tenDoiTuon": "CNC 6-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 252+260 Phố Chợ Khâm Thiêm",
        "tuDien": "CNC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "CNC",
        "diaChiLapD": "",
        "longitude": 105.83632984911215,
        "latitude": 21.015041794022462,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6802,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8362657724079,
          21.01513380462893
        ]
      },
      "properties": {
        "tenDoiTuon": "CNC 6-10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 252+260 Phố Chợ Khâm Thiêm",
        "tuDien": "CNC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "CNC",
        "diaChiLapD": "",
        "longitude": 105.8362657724079,
        "latitude": 21.01513380462893,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6803,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83611422759863,
          21.015243971957698
        ]
      },
      "properties": {
        "tenDoiTuon": "CNC 6-11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 252+260 Phố Chợ Khâm Thiêm",
        "tuDien": "CNC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "CNC",
        "diaChiLapD": "",
        "longitude": 105.83611422759863,
        "latitude": 21.015243971957698,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6804,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83616250735595,
          21.014873408800725
        ]
      },
      "properties": {
        "tenDoiTuon": "CNC 6-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 252+260 Phố Chợ Khâm Thiêm",
        "tuDien": "CNC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "CNC",
        "diaChiLapD": "",
        "longitude": 105.83616250735595,
        "latitude": 21.014873408800725,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6805,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83596138214043,
          21.014658839675953
        ]
      },
      "properties": {
        "tenDoiTuon": "CNC 6-9",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 252+260 Phố Chợ Khâm Thiêm",
        "tuDien": "CNC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "CNC",
        "diaChiLapD": "",
        "longitude": 105.83596138214043,
        "latitude": 21.014658839675953,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6806,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83635693163136,
          21.014653523369006
        ]
      },
      "properties": {
        "tenDoiTuon": "CNC 6-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 252+260 Phố Chợ Khâm Thiêm",
        "tuDien": "CNC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "CNC",
        "diaChiLapD": "",
        "longitude": 105.83635693163136,
        "latitude": 21.014653523369006,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6807,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83617556522937,
          21.014490837044217
        ]
      },
      "properties": {
        "tenDoiTuon": "CNC 6-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 252+260 Phố Chợ Khâm Thiêm",
        "tuDien": "CNC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "CNC",
        "diaChiLapD": "",
        "longitude": 105.83617556522937,
        "latitude": 21.014490837044217,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6808,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83654874545945,
          21.014522875236178
        ]
      },
      "properties": {
        "tenDoiTuon": "CNC 6-7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 252+260 Phố Chợ Khâm Thiêm",
        "tuDien": "CNC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "CNC",
        "diaChiLapD": "",
        "longitude": 105.83654874545945,
        "latitude": 21.014522875236178,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6809,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8363966223351,
          21.014350421053816
        ]
      },
      "properties": {
        "tenDoiTuon": "CNC 6-9",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 252+260 Phố Chợ Khâm Thiêm",
        "tuDien": "CNC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "CNC",
        "diaChiLapD": "",
        "longitude": 105.8363966223351,
        "latitude": 21.014350421053816,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6810,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80427091091407,
          21.017996283486394
        ]
      },
      "properties": {
        "tenDoiTuon": "08",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 157 Pháo Đài Láng",
        "tuDien": "DDLT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Di Dân Láng Trung",
        "diaChiLapD": "",
        "longitude": 105.80427091091407,
        "latitude": 21.017996283486394,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6811,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80417468665966,
          21.018116150746838
        ]
      },
      "properties": {
        "tenDoiTuon": "8-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 157 Pháo Đài Láng",
        "tuDien": "DDLT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Di Dân Láng Trung",
        "diaChiLapD": "",
        "longitude": 105.80417468665966,
        "latitude": 21.018116150746838,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6812,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8041851834257,
          21.017941869152853
        ]
      },
      "properties": {
        "tenDoiTuon": "8-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 157 Pháo Đài Láng",
        "tuDien": "DDLT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Di Dân Láng Trung",
        "diaChiLapD": "",
        "longitude": 105.8041851834257,
        "latitude": 21.017941869152853,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6813,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8044093799449,
          21.017918354067277
        ]
      },
      "properties": {
        "tenDoiTuon": "10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 157 Pháo Đài Láng",
        "tuDien": "DDLT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Di Dân Láng Trung",
        "diaChiLapD": "",
        "longitude": 105.8044093799449,
        "latitude": 21.017918354067277,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6814,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80416261671584,
          21.01821630086495
        ]
      },
      "properties": {
        "tenDoiTuon": "09",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 157 Pháo Đài Láng",
        "tuDien": "DDLT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Di Dân Láng Trung",
        "diaChiLapD": "",
        "longitude": 105.80416261671584,
        "latitude": 21.01821630086495,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6815,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80470576404412,
          21.017485203457813
        ]
      },
      "properties": {
        "tenDoiTuon": "8-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 157 Pháo Đài Láng",
        "tuDien": "DDLT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Di Dân Láng Trung",
        "diaChiLapD": "",
        "longitude": 105.80470576404412,
        "latitude": 21.017485203457813,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6816,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8049055886287,
          21.017263620145858
        ]
      },
      "properties": {
        "tenDoiTuon": "8-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 157 Pháo Đài Láng",
        "tuDien": "DDLT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Di Dân Láng Trung",
        "diaChiLapD": "",
        "longitude": 105.8049055886287,
        "latitude": 21.017263620145858,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6817,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80512016534686,
          21.01701324312618
        ]
      },
      "properties": {
        "tenDoiTuon": "8-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 157 Pháo Đài Láng",
        "tuDien": "DDLT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Di Dân Láng Trung",
        "diaChiLapD": "",
        "longitude": 105.80512016534686,
        "latitude": 21.01701324312618,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6818,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80531194329025,
          21.01679666666422
        ]
      },
      "properties": {
        "tenDoiTuon": "8-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 157 Pháo Đài Láng",
        "tuDien": "DDLT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Di Dân Láng Trung",
        "diaChiLapD": "",
        "longitude": 105.80531194329025,
        "latitude": 21.01679666666422,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6819,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82085631309015,
          21.003306206760293
        ]
      },
      "properties": {
        "tenDoiTuon": "NTS2 20",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Tây Sơn",
        "tuDien": "CVNTS2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "CV NTS Tủ 2",
        "diaChiLapD": "",
        "longitude": 105.82085631309015,
        "latitude": 21.003306206760293,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6820,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82109342163449,
          21.003504590819347
        ]
      },
      "properties": {
        "tenDoiTuon": "21",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Tây Sơn",
        "tuDien": "CVNTS2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "CV NTS Tủ 2",
        "diaChiLapD": "",
        "longitude": 105.82109342163449,
        "latitude": 21.003504590819347,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6821,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82132811492869,
          21.003709918599803
        ]
      },
      "properties": {
        "tenDoiTuon": "22",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Tây Sơn",
        "tuDien": "CVNTS2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "CV NTS Tủ 2",
        "diaChiLapD": "",
        "longitude": 105.82132811492869,
        "latitude": 21.003709918599803,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6822,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8216835076162,
          21.003982853869328
        ]
      },
      "properties": {
        "tenDoiTuon": "23",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Tây Sơn",
        "tuDien": "CVNTS2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "CV NTS Tủ 2",
        "diaChiLapD": "",
        "longitude": 105.8216835076162,
        "latitude": 21.003982853869328,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6823,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82194904630842,
          21.004219480832436
        ]
      },
      "properties": {
        "tenDoiTuon": "24",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Tây Sơn",
        "tuDien": "CVNTS2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "CV NTS Tủ 2",
        "diaChiLapD": "",
        "longitude": 105.82194904630842,
        "latitude": 21.004219480832436,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6824,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82218457779368,
          21.004484746723584
        ]
      },
      "properties": {
        "tenDoiTuon": "25",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Tây Sơn",
        "tuDien": "CVNTS2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "CV NTS Tủ 2",
        "diaChiLapD": "",
        "longitude": 105.82218457779368,
        "latitude": 21.004484746723584,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6825,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82239379009637,
          21.004817776036113
        ]
      },
      "properties": {
        "tenDoiTuon": "26",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Tây Sơn",
        "tuDien": "CVNTS2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "CV NTS Tủ 2",
        "diaChiLapD": "",
        "longitude": 105.82239379009637,
        "latitude": 21.004817776036113,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6826,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8225654514727,
          21.005125764890145
        ]
      },
      "properties": {
        "tenDoiTuon": "27",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Tây Sơn",
        "tuDien": "CVNTS2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "CV NTS Tủ 2",
        "diaChiLapD": "",
        "longitude": 105.8225654514727,
        "latitude": 21.005125764890145,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6827,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82271565517811,
          21.005426241207683
        ]
      },
      "properties": {
        "tenDoiTuon": "28",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Tây Sơn",
        "tuDien": "CVNTS2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "CV NTS Tủ 2",
        "diaChiLapD": "",
        "longitude": 105.82271565517811,
        "latitude": 21.005426241207683,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6828,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82282964906105,
          21.005689157488554
        ]
      },
      "properties": {
        "tenDoiTuon": "29",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Tây Sơn",
        "tuDien": "CVNTS2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "CV NTS Tủ 2",
        "diaChiLapD": "",
        "longitude": 105.82282964906105,
        "latitude": 21.005689157488554,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6829,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82294239667026,
          21.005949729560065
        ]
      },
      "properties": {
        "tenDoiTuon": "30",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Tây Sơn",
        "tuDien": "CVNTS2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "CV NTS Tủ 2",
        "diaChiLapD": "",
        "longitude": 105.82294239667026,
        "latitude": 21.005949729560065,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6830,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8230674870484,
          21.00622110263231
        ]
      },
      "properties": {
        "tenDoiTuon": "31",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Tây Sơn",
        "tuDien": "CVNTS2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "CV NTS Tủ 2",
        "diaChiLapD": "",
        "longitude": 105.8230674870484,
        "latitude": 21.00622110263231,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6831,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8055704411939,
          21.01674502627626
        ]
      },
      "properties": {
        "tenDoiTuon": "14",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 157 Pháo Đài Láng",
        "tuDien": "DDLT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Di Dân Láng Trung",
        "diaChiLapD": "",
        "longitude": 105.8055704411939,
        "latitude": 21.01674502627626,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6832,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80576892465662,
          21.016926549984568
        ]
      },
      "properties": {
        "tenDoiTuon": "15",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 76 Nguyễn Chí Thanh",
        "tuDien": "DDLT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Di Dân Láng Trung",
        "diaChiLapD": "",
        "longitude": 105.80576892465662,
        "latitude": 21.016926549984568,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6833,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80598484248766,
          21.017145630027652
        ]
      },
      "properties": {
        "tenDoiTuon": "DDLT 16",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 76 Nguyễn Chí Thanh",
        "tuDien": "DDLT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Di Dân Láng Trung",
        "diaChiLapD": "",
        "longitude": 105.80598484248766,
        "latitude": 21.017145630027652,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6834,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80588023633182,
          21.017257047754626
        ]
      },
      "properties": {
        "tenDoiTuon": "17",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 76 Nguyễn Chí Thanh",
        "tuDien": "DDLT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Di Dân Láng Trung",
        "diaChiLapD": "",
        "longitude": 105.80588023633182,
        "latitude": 21.017257047754626,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6835,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80574612588184,
          21.01713811871743
        ]
      },
      "properties": {
        "tenDoiTuon": "171",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 76 Nguyễn Chí Thanh",
        "tuDien": "DDLT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Di Dân Láng Trung",
        "diaChiLapD": "",
        "longitude": 105.80574612588184,
        "latitude": 21.01713811871743,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6836,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80552216142759,
          21.017240773260532
        ]
      },
      "properties": {
        "tenDoiTuon": "DDLT 17-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 76 Nguyễn Chí Thanh",
        "tuDien": "DDLT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Di Dân Láng Trung",
        "diaChiLapD": "",
        "longitude": 105.80552216142759,
        "latitude": 21.017240773260532,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6837,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80558636681471,
          21.017309783363594
        ]
      },
      "properties": {
        "tenDoiTuon": "17-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 76 Nguyễn Chí Thanh",
        "tuDien": "DDLT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Di Dân Láng Trung",
        "diaChiLapD": "",
        "longitude": 105.80558636681471,
        "latitude": 21.017309783363594,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6838,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8054133643345,
          21.017513840260605
        ]
      },
      "properties": {
        "tenDoiTuon": "17-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 76 Nguyễn Chí Thanh",
        "tuDien": "DDLT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Di Dân Láng Trung",
        "diaChiLapD": "",
        "longitude": 105.8054133643345,
        "latitude": 21.017513840260605,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6839,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80532887474571,
          21.017460009327444
        ]
      },
      "properties": {
        "tenDoiTuon": "DDLT 17-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 76 Nguyễn Chí Thanh",
        "tuDien": "DDLT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Di Dân Láng Trung",
        "diaChiLapD": "",
        "longitude": 105.80532887474571,
        "latitude": 21.017460009327444,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6840,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82861785608121,
          21.00724974387663
        ]
      },
      "properties": {
        "tenDoiTuon": "TL3 07",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 95 Chùa Bộc",
        "tuDien": "TL3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "TL3",
        "diaChiLapD": "",
        "longitude": 105.82861785608121,
        "latitude": 21.00724974387663,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6841,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82844083028641,
          21.006910877460545
        ]
      },
      "properties": {
        "tenDoiTuon": "06",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 95 Chùa Bộc",
        "tuDien": "TL3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "TL3",
        "diaChiLapD": "",
        "longitude": 105.82844083028641,
        "latitude": 21.006910877460545,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6842,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82826380449158,
          21.006572010274844
        ]
      },
      "properties": {
        "tenDoiTuon": "05",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 95 Chùa Bộc",
        "tuDien": "TL3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "TL3",
        "diaChiLapD": "",
        "longitude": 105.82826380449158,
        "latitude": 21.006572010274844,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6843,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82810823636767,
          21.006082905049272
        ]
      },
      "properties": {
        "tenDoiTuon": "04",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 95 Chùa Bộc",
        "tuDien": "TL3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "TL3",
        "diaChiLapD": "",
        "longitude": 105.82810823636767,
        "latitude": 21.006082905049272,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6844,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82797949033318,
          21.005787437975723
        ]
      },
      "properties": {
        "tenDoiTuon": "03",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 95 Chùa Bộc",
        "tuDien": "TL3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "TL3",
        "diaChiLapD": "",
        "longitude": 105.82797949033318,
        "latitude": 21.005787437975723,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6845,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82782928662778,
          21.005517009970937
        ]
      },
      "properties": {
        "tenDoiTuon": "02",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 95 Chùa Bộc",
        "tuDien": "TL3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "TL3",
        "diaChiLapD": "",
        "longitude": 105.82782928662778,
        "latitude": 21.005517009970937,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6846,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82765024918463,
          21.005154560559014
        ]
      },
      "properties": {
        "tenDoiTuon": "TL3 01",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 95 Chùa Bộc",
        "tuDien": "TL3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "TL3",
        "diaChiLapD": "",
        "longitude": 105.82765024918463,
        "latitude": 21.005154560559014,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6847,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80565073982747,
          21.017518847788462
        ]
      },
      "properties": {
        "tenDoiTuon": "18",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 76 Nguyễn Chí Thanh",
        "tuDien": "DDLT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Di Dân Láng Trung",
        "diaChiLapD": "",
        "longitude": 105.80565073982747,
        "latitude": 21.017518847788462,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6848,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80543616310929,
          21.01775420140125
        ]
      },
      "properties": {
        "tenDoiTuon": "DDLT 19",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 76 Nguyễn Chí Thanh",
        "tuDien": "DDLT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Di Dân Láng Trung",
        "diaChiLapD": "",
        "longitude": 105.80543616310929,
        "latitude": 21.01775420140125,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6849,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80585190550693,
          21.017644035926832
        ]
      },
      "properties": {
        "tenDoiTuon": "18-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 76 Nguyễn Chí Thanh",
        "tuDien": "DDLT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "Di Dân Láng Trung",
        "diaChiLapD": "",
        "longitude": 105.80585190550693,
        "latitude": 21.017644035926832,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6850,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80586531655463,
          21.01784684048792
        ]
      },
      "properties": {
        "tenDoiTuon": "DDLT 18-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 76 Nguyễn Chí Thanh",
        "tuDien": "DDLT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 7,
        "tuDien2": "Di Dân Láng Trung",
        "diaChiLapD": "",
        "longitude": 105.80586531655463,
        "latitude": 21.01784684048792,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6851,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80590823190545,
          21.017417445319783
        ]
      },
      "properties": {
        "tenDoiTuon": "17-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 76 Nguyễn Chí Thanh",
        "tuDien": "DDLT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 7,
        "tuDien2": "Di Dân Láng Trung",
        "diaChiLapD": "",
        "longitude": 105.80590823190545,
        "latitude": 21.017417445319783,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6852,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80610403316943,
          21.017517595906302
        ]
      },
      "properties": {
        "tenDoiTuon": "DDLT 17-7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 76 Nguyễn Chí Thanh",
        "tuDien": "DDLT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 7,
        "tuDien2": "Di Dân Láng Trung",
        "diaChiLapD": "",
        "longitude": 105.80610403316943,
        "latitude": 21.017517595906302,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6853,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82573859441948,
          21.02487766493703
        ]
      },
      "properties": {
        "tenDoiTuon": "23-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 127/93 Hào Nam",
        "tuDien": "LB3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Long Biên 3",
        "diaChiLapD": "",
        "longitude": 105.82573859441948,
        "latitude": 21.02487766493703,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6854,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8253818606191,
          21.024419498068053
        ]
      },
      "properties": {
        "tenDoiTuon": "25-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "ngách 115 ngõ xã Đàn 2",
        "tuDien": "XĐ2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Xã Đàn 2",
        "diaChiLapD": "",
        "longitude": 105.8253818606191,
        "latitude": 21.024419498068053,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6855,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82543014038541,
          21.024579731450178
        ]
      },
      "properties": {
        "tenDoiTuon": "25-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "ngách 115 ngõ xã Đàn 2",
        "tuDien": "LB3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Long Biên 3",
        "diaChiLapD": "",
        "longitude": 105.82543014038541,
        "latitude": 21.024579731450178,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6856,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8253818606191,
          21.024419498068053
        ]
      },
      "properties": {
        "tenDoiTuon": "25-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 127/66 Hào Nam",
        "tuDien": "LB3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Long Biên 3",
        "diaChiLapD": "",
        "longitude": 105.8253818606191,
        "latitude": 21.024419498068053,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6857,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82543014038541,
          21.024579731450167
        ]
      },
      "properties": {
        "tenDoiTuon": "25-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 127/66 Hào Nam",
        "tuDien": "XĐ2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Xã Đàn 2",
        "diaChiLapD": "",
        "longitude": 105.82543014038541,
        "latitude": 21.024579731450167,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6858,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83708581327733,
          21.01402586730757
        ]
      },
      "properties": {
        "tenDoiTuon": "CNC 5-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 241 Phố Chợ Khâm Thiên",
        "tuDien": "CNC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "CNC",
        "diaChiLapD": "",
        "longitude": 105.83708581327733,
        "latitude": 21.01402586730757,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6859,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83727490901298,
          21.014164829389497
        ]
      },
      "properties": {
        "tenDoiTuon": "CNC 5-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 241 Phố Chợ Khâm Thiên",
        "tuDien": "CNC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "CNC",
        "diaChiLapD": "",
        "longitude": 105.83727490901298,
        "latitude": 21.014164829389497,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6860,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83743047714285,
          21.01377798867854
        ]
      },
      "properties": {
        "tenDoiTuon": "CNC 5-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 241 Phố Chợ Khâm Thiên",
        "tuDien": "CNC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "CNC",
        "diaChiLapD": "",
        "longitude": 105.83743047714285,
        "latitude": 21.01377798867854,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6861,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83726991066453,
          21.013586486561557
        ]
      },
      "properties": {
        "tenDoiTuon": "CNC 5-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 241 Phố Chợ Khâm Thiên",
        "tuDien": "CNC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "CNC",
        "diaChiLapD": "",
        "longitude": 105.83726991066453,
        "latitude": 21.013586486561557,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6862,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83713686733714,
          21.013417590545373
        ]
      },
      "properties": {
        "tenDoiTuon": "CNC 5-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 241 Phố Chợ Khâm Thiên",
        "tuDien": "CNC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "CNC",
        "diaChiLapD": "",
        "longitude": 105.83713686733714,
        "latitude": 21.013417590545373,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6863,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83763566612495,
          21.013485040676336
        ]
      },
      "properties": {
        "tenDoiTuon": "CNC 5-7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 241 Phố Chợ Khâm Thiên",
        "tuDien": "CNC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "CNC",
        "diaChiLapD": "",
        "longitude": 105.83763566612495,
        "latitude": 21.013485040676336,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6864,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.837450540553,
          21.01327975143078
        ]
      },
      "properties": {
        "tenDoiTuon": "CNC 5-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 249 Phố Chợ Khâm Thiên",
        "tuDien": "CNC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "CNC",
        "diaChiLapD": "",
        "longitude": 105.837450540553,
        "latitude": 21.01327975143078,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6865,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83784219621992,
          21.013366108631214
        ]
      },
      "properties": {
        "tenDoiTuon": "CNC 5-8",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 241 Phố Chợ Khâm Thiên",
        "tuDien": "CNC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "CNC",
        "diaChiLapD": "",
        "longitude": 105.83784219621992,
        "latitude": 21.013366108631214,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6866,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83797508632418,
          21.01355752005602
        ]
      },
      "properties": {
        "tenDoiTuon": "CNC 5-9",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 241 Phố Chợ Khâm Thiên",
        "tuDien": "CNC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "CNC",
        "diaChiLapD": "",
        "longitude": 105.83797508632418,
        "latitude": 21.01355752005602,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6867,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83814591778795,
          21.013680295450605
        ]
      },
      "properties": {
        "tenDoiTuon": "CNC 5-10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 241 Phố Chợ Khâm Thiên",
        "tuDien": "CNC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "CNC",
        "diaChiLapD": "",
        "longitude": 105.83814591778795,
        "latitude": 21.013680295450605,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6868,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83784621954048,
          21.01326219950323
        ]
      },
      "properties": {
        "tenDoiTuon": "CNC 5-8-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 241 Phố Chợ Khâm Thiên",
        "tuDien": "CNC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "CNC",
        "diaChiLapD": "",
        "longitude": 105.83784621954048,
        "latitude": 21.01326219950323,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6869,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83797686454656,
          21.013282889840582
        ]
      },
      "properties": {
        "tenDoiTuon": "CNC 5-11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 241 Phố Chợ Khâm Thiên",
        "tuDien": "CNC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "CNC",
        "diaChiLapD": "",
        "longitude": 105.83797686454656,
        "latitude": 21.013282889840582,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6870,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83822709321953,
          21.01294796784484
        ]
      },
      "properties": {
        "tenDoiTuon": "CNC 5-12",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 241 Phố Chợ Khâm Thiên",
        "tuDien": "CNC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "CNC",
        "diaChiLapD": "",
        "longitude": 105.83822709321953,
        "latitude": 21.01294796784484,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6871,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83839607238811,
          21.01311322123013
        ]
      },
      "properties": {
        "tenDoiTuon": "CNC 5-13",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 241 Phố Chợ Khâm Thiên",
        "tuDien": "CNC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "CNC",
        "diaChiLapD": "",
        "longitude": 105.83839607238811,
        "latitude": 21.01311322123013,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6872,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83852542978832,
          21.013022655008324
        ]
      },
      "properties": {
        "tenDoiTuon": "CNC 5-14",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 241 Phố Chợ Khâm Thiên",
        "tuDien": "CNC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "CNC",
        "diaChiLapD": "",
        "longitude": 105.83852542978832,
        "latitude": 21.013022655008324,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6873,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83863613008884,
          21.013133251930718
        ]
      },
      "properties": {
        "tenDoiTuon": "CNC 5-14-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 241 Phố Chợ Khâm Thiên",
        "tuDien": "CNC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "CNC",
        "diaChiLapD": "",
        "longitude": 105.83863613008884,
        "latitude": 21.013133251930718,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6874,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83875414729233,
          21.01315954222165
        ]
      },
      "properties": {
        "tenDoiTuon": "CNC 5-15",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 241 Phố Chợ Khâm Thiên",
        "tuDien": "CNC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "CNC",
        "diaChiLapD": "",
        "longitude": 105.83875414729233,
        "latitude": 21.01315954222165,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6875,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83900091052139,
          21.01340867379511
        ]
      },
      "properties": {
        "tenDoiTuon": "CNC 5-18",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 241 Phố Chợ Khâm Thiên",
        "tuDien": "CNC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "CNC",
        "diaChiLapD": "",
        "longitude": 105.83900091052139,
        "latitude": 21.01340867379511,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6876,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83895799517057,
          21.013457498526535
        ]
      },
      "properties": {
        "tenDoiTuon": "CNC 5-17",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 241 Phố Chợ Khâm Thiên",
        "tuDien": "CNC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "CNC",
        "diaChiLapD": "",
        "longitude": 105.83895799517057,
        "latitude": 21.013457498526535,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6877,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83925035595817,
          21.01374418701547
        ]
      },
      "properties": {
        "tenDoiTuon": "CNC 5-18",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 241 Phố Chợ Khâm Thiên",
        "tuDien": "CNC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "CNC",
        "diaChiLapD": "",
        "longitude": 105.83925035595817,
        "latitude": 21.01374418701547,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6878,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83913904428299,
          21.01362149943244
        ]
      },
      "properties": {
        "tenDoiTuon": "CNC 8-18-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 241 Phố Chợ Khâm Thiên",
        "tuDien": "CNC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "CNC",
        "diaChiLapD": "",
        "longitude": 105.83913904428299,
        "latitude": 21.01362149943244,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6879,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8393982077653,
          21.01362177025463
        ]
      },
      "properties": {
        "tenDoiTuon": "CNC 5-19",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 241 Phố Chợ Khâm Thiên",
        "tuDien": "CNC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "CNC",
        "diaChiLapD": "",
        "longitude": 105.8393982077653,
        "latitude": 21.01362177025463,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6880,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83945420384539,
          21.013602720712427
        ]
      },
      "properties": {
        "tenDoiTuon": "CNC 5-20",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 241 Phố Chợ Khâm Thiên",
        "tuDien": "CNC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "CNC",
        "diaChiLapD": "",
        "longitude": 105.83945420384539,
        "latitude": 21.013602720712427,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6881,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83753653506146,
          21.013182934079587
        ]
      },
      "properties": {
        "tenDoiTuon": "CNC 5-6-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 241 Phố Chợ Khâm Thiên",
        "tuDien": "CNC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "CNC",
        "diaChiLapD": "",
        "longitude": 105.83753653506146,
        "latitude": 21.013182934079587,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6882,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8052450557403,
          21.01695956849742
        ]
      },
      "properties": {
        "tenDoiTuon": "13",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 157 Pháo Đài Láng",
        "tuDien": "DDLT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Di Dân Láng Trung",
        "diaChiLapD": "",
        "longitude": 105.8052450557403,
        "latitude": 21.01695956849742,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6883,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80538453060576,
          21.017049704305578
        ]
      },
      "properties": {
        "tenDoiTuon": "DDLT 13-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 157 Pháo Đài Láng",
        "tuDien": "DDLT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 7,
        "tuDien2": "Di Dân Láng Trung",
        "diaChiLapD": "",
        "longitude": 105.80538453060576,
        "latitude": 21.017049704305578,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6884,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80503852564536,
          21.017196174877732
        ]
      },
      "properties": {
        "tenDoiTuon": "12",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 157 Pháo Đài Láng",
        "tuDien": "DDLT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Di Dân Láng Trung",
        "diaChiLapD": "",
        "longitude": 105.80503852564536,
        "latitude": 21.017196174877732,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6885,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80484272438137,
          21.01743152899976
        ]
      },
      "properties": {
        "tenDoiTuon": "11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 157 Pháo Đài Láng",
        "tuDien": "DDLT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Di Dân Láng Trung",
        "diaChiLapD": "",
        "longitude": 105.80484272438137,
        "latitude": 21.01743152899976,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6886,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80506132441114,
          21.017787063245756
        ]
      },
      "properties": {
        "tenDoiTuon": "DDLT 10-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 157 Pháo Đài Láng",
        "tuDien": "DDLT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 7,
        "tuDien2": "Di Dân Láng Trung",
        "diaChiLapD": "",
        "longitude": 105.80506132441114,
        "latitude": 21.017787063245756,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6887,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80455237525406,
          21.017786124336038
        ]
      },
      "properties": {
        "tenDoiTuon": "20",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 157/23  Pháo Đài Láng",
        "tuDien": "DDLT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Di Dân Láng Trung",
        "diaChiLapD": "",
        "longitude": 105.80455237525406,
        "latitude": 21.017786124336038,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6888,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80468782680794,
          21.017872504007936
        ]
      },
      "properties": {
        "tenDoiTuon": "21",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 157/23  Pháo Đài Láng",
        "tuDien": "DDLT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Di Dân Láng Trung",
        "diaChiLapD": "",
        "longitude": 105.80468782680794,
        "latitude": 21.017872504007936,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6889,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80493056672536,
          21.018027736915677
        ]
      },
      "properties": {
        "tenDoiTuon": "22",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 157/23  Pháo Đài Láng",
        "tuDien": "DDLT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Di Dân Láng Trung",
        "diaChiLapD": "",
        "longitude": 105.80493056672536,
        "latitude": 21.018027736915677,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6890,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81859896728457,
          21.001920803023875
        ]
      },
      "properties": {
        "tenDoiTuon": "NTS2 06",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 9,
        "maXaPhuong": 349,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Tây Sơn",
        "tuDien": "CVNTS2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "CV NTS Tủ 2",
        "diaChiLapD": "",
        "longitude": 105.81859896728457,
        "latitude": 21.001920803023875,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6891,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81884813768319,
          21.002098145061108
        ]
      },
      "properties": {
        "tenDoiTuon": "07",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 9,
        "maXaPhuong": 349,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Tây Sơn",
        "tuDien": "CVNTS2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "CV NTS Tủ 2",
        "diaChiLapD": "",
        "longitude": 105.81884813768319,
        "latitude": 21.002098145061108,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6892,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81912468024454,
          21.00229891041213
        ]
      },
      "properties": {
        "tenDoiTuon": "08",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 9,
        "maXaPhuong": 349,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Tây Sơn",
        "tuDien": "CVNTS2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "CV NTS Tủ 2",
        "diaChiLapD": "",
        "longitude": 105.81912468024454,
        "latitude": 21.00229891041213,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6893,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81942240542968,
          21.00252301996754
        ]
      },
      "properties": {
        "tenDoiTuon": "09",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 9,
        "maXaPhuong": 349,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Tây Sơn",
        "tuDien": "CVNTS2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "CV NTS Tủ 2",
        "diaChiLapD": "",
        "longitude": 105.81942240542968,
        "latitude": 21.00252301996754,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6894,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8196745330742,
          21.00270831700011
        ]
      },
      "properties": {
        "tenDoiTuon": "10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 9,
        "maXaPhuong": 349,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Tây Sơn",
        "tuDien": "CVNTS2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "CV NTS Tủ 2",
        "diaChiLapD": "",
        "longitude": 105.8196745330742,
        "latitude": 21.00270831700011,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6895,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81982473677064,
          21.00281974551014
        ]
      },
      "properties": {
        "tenDoiTuon": "NTS 11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 9,
        "maXaPhuong": 349,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Tây Sơn",
        "tuDien": "CVNTS2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "CV NTS Tủ 2",
        "diaChiLapD": "",
        "longitude": 105.81982473677064,
        "latitude": 21.00281974551014,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6896,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82005872906544,
          21.002928785706054
        ]
      },
      "properties": {
        "tenDoiTuon": "13",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 9,
        "maXaPhuong": 349,
        "loaiChaoCh": "Đường phố - đèn pha",
        "tenDuongPh": "Tây Sơn",
        "tuDien": "CVNTS2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "CV NTS Tủ 2",
        "diaChiLapD": "",
        "longitude": 105.82005872906544,
        "latitude": 21.002928785706054,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6897,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82011437769506,
          21.00296930222273
        ]
      },
      "properties": {
        "tenDoiTuon": "13-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 9,
        "maXaPhuong": 349,
        "loaiChaoCh": "Đường phố - đèn pha",
        "tenDuongPh": "Tây Sơn",
        "tuDien": "CVNTS2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "CV NTS Tủ 2",
        "diaChiLapD": "",
        "longitude": 105.82011437769506,
        "latitude": 21.00296930222273,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6898,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.820176376382,
          21.003013845075564
        ]
      },
      "properties": {
        "tenDoiTuon": "13-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Đường phố - đèn pha",
        "tenDuongPh": "Tây Sơn",
        "tuDien": "CVNTS2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "CV NTS Tủ 2",
        "diaChiLapD": "",
        "longitude": 105.820176376382,
        "latitude": 21.003013845075564,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6899,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82023114406334,
          21.003053442260075
        ]
      },
      "properties": {
        "tenDoiTuon": "13-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Đường phố - đèn pha",
        "tenDuongPh": "Tây Sơn",
        "tuDien": "CVNTS2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "CV NTS Tủ 2",
        "diaChiLapD": "",
        "longitude": 105.82023114406334,
        "latitude": 21.003053442260075,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6900,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82029586432442,
          21.003100235173594
        ]
      },
      "properties": {
        "tenDoiTuon": "13-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Đường phố - đèn pha",
        "tenDuongPh": "Tây Sơn",
        "tuDien": "CVNTS2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "CV NTS Tủ 2",
        "diaChiLapD": "",
        "longitude": 105.82029586432442,
        "latitude": 21.003100235173594,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6901,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82035817674435,
          21.003145287197967
        ]
      },
      "properties": {
        "tenDoiTuon": "13-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Đường phố - đèn pha",
        "tenDuongPh": "Tây Sơn",
        "tuDien": "CVNTS2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "CV NTS Tủ 2",
        "diaChiLapD": "",
        "longitude": 105.82035817674435,
        "latitude": 21.003145287197967,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6902,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82042178761859,
          21.0031912779938
        ]
      },
      "properties": {
        "tenDoiTuon": "13-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Đường phố - đèn pha",
        "tenDuongPh": "Tây Sơn",
        "tuDien": "CVNTS2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "CV NTS Tủ 2",
        "diaChiLapD": "",
        "longitude": 105.82042178761859,
        "latitude": 21.0031912779938,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6903,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82047785466708,
          21.003229150669952
        ]
      },
      "properties": {
        "tenDoiTuon": "46",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Đường phố - đèn pha",
        "tenDuongPh": "Tây Sơn",
        "tuDien": "CVNTS2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "CV NTS Tủ 2",
        "diaChiLapD": "",
        "longitude": 105.82047785466708,
        "latitude": 21.003229150669952,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6904,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81998449364025,
          21.003013680810092
        ]
      },
      "properties": {
        "tenDoiTuon": "12",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Đường phố - đèn pha",
        "tenDuongPh": "Tây Sơn",
        "tuDien": "CVNTS2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "CV NTS Tủ 2",
        "diaChiLapD": "",
        "longitude": 105.81998449364025,
        "latitude": 21.003013680810092,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6905,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82004058171742,
          21.003053568079753
        ]
      },
      "properties": {
        "tenDoiTuon": "13-12",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Đường phố - đèn pha",
        "tenDuongPh": "Tây Sơn",
        "tuDien": "CVNTS2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "CV NTS Tủ 2",
        "diaChiLapD": "",
        "longitude": 105.82004058171742,
        "latitude": 21.003053568079753,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6906,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82010054861087,
          21.00309621377394
        ]
      },
      "properties": {
        "tenDoiTuon": "13-11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Đường phố - đèn pha",
        "tenDuongPh": "Tây Sơn",
        "tuDien": "CVNTS2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "CV NTS Tủ 2",
        "diaChiLapD": "",
        "longitude": 105.82010054861087,
        "latitude": 21.00309621377394,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6907,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82015877827354,
          21.00313762401791
        ]
      },
      "properties": {
        "tenDoiTuon": "13-10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Đường phố - đèn pha",
        "tenDuongPh": "Tây Sơn",
        "tuDien": "CVNTS2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "CV NTS Tủ 2",
        "diaChiLapD": "",
        "longitude": 105.82015877827354,
        "latitude": 21.00313762401791,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6908,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82022323052036,
          21.003183459462658
        ]
      },
      "properties": {
        "tenDoiTuon": "13-9",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Đường phố - đèn pha",
        "tenDuongPh": "Tây Sơn",
        "tuDien": "CVNTS2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "CV NTS Tủ 2",
        "diaChiLapD": "",
        "longitude": 105.82022323052036,
        "latitude": 21.003183459462658,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6909,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8202850968841,
          21.003227455934223
        ]
      },
      "properties": {
        "tenDoiTuon": "13-8",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Đường phố - đèn pha",
        "tenDuongPh": "Tây Sơn",
        "tuDien": "CVNTS2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "CV NTS Tủ 2",
        "diaChiLapD": "",
        "longitude": 105.8202850968841,
        "latitude": 21.003227455934223,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6910,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8203465588936,
          21.003271164835045
        ]
      },
      "properties": {
        "tenDoiTuon": "13-7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Đường phố - đèn pha",
        "tenDuongPh": "Tây Sơn",
        "tuDien": "CVNTS2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "CV NTS Tủ 2",
        "diaChiLapD": "",
        "longitude": 105.8203465588936,
        "latitude": 21.003271164835045,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6911,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82040543502526,
          21.00331303476874
        ]
      },
      "properties": {
        "tenDoiTuon": "47",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Đường phố - đèn pha",
        "tenDuongPh": "Tây Sơn",
        "tuDien": "CVNTS2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "CV NTS Tủ 2",
        "diaChiLapD": "",
        "longitude": 105.82040543502526,
        "latitude": 21.00331303476874,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6912,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82560180176175,
          21.02492273045415
        ]
      },
      "properties": {
        "tenDoiTuon": "23",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 127/56  Hào Nam",
        "tuDien": "LB3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Long Biên 3",
        "diaChiLapD": "",
        "longitude": 105.82560180176175,
        "latitude": 21.02492273045415,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6913,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80477633970831,
          21.01821051093811
        ]
      },
      "properties": {
        "tenDoiTuon": "DDLT 22-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 157/23  Pháo Đài Láng",
        "tuDien": "DDLT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Di Dân Láng Trung",
        "diaChiLapD": "",
        "longitude": 105.80477633970831,
        "latitude": 21.01821051093811,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6914,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80515587228346,
          21.01801772189398
        ]
      },
      "properties": {
        "tenDoiTuon": "23",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 157/23  Pháo Đài Láng",
        "tuDien": "DDLT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Di Dân Láng Trung",
        "diaChiLapD": "",
        "longitude": 105.80515587228346,
        "latitude": 21.01801772189398,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6915,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.805119662461,
          21.01819048091859
        ]
      },
      "properties": {
        "tenDoiTuon": "DDLT 23-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 157/23  Pháo Đài Láng",
        "tuDien": "DDLT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 7,
        "tuDien2": "Di Dân Láng Trung",
        "diaChiLapD": "",
        "longitude": 105.805119662461,
        "latitude": 21.01819048091859,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6916,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80545762079818,
          21.01817796215543
        ]
      },
      "properties": {
        "tenDoiTuon": "24",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 157/23  Pháo Đài Láng",
        "tuDien": "DDLT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Di Dân Láng Trung",
        "diaChiLapD": "",
        "longitude": 105.80545762079818,
        "latitude": 21.01817796215543,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6917,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80568728495513,
          21.018257299800275
        ]
      },
      "properties": {
        "tenDoiTuon": "DDLT 25",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 157/23  Pháo Đài Láng",
        "tuDien": "DDLT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Di Dân Láng Trung",
        "diaChiLapD": "",
        "longitude": 105.80568728495513,
        "latitude": 21.018257299800275,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6918,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80568728495513,
          21.018082037098246
        ]
      },
      "properties": {
        "tenDoiTuon": "26",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 157/23  Pháo Đài Láng",
        "tuDien": "DDLT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 7,
        "tuDien2": "Di Dân Láng Trung",
        "diaChiLapD": "",
        "longitude": 105.80568728495513,
        "latitude": 21.018082037098246,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6919,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80588442731398,
          21.018226002904036
        ]
      },
      "properties": {
        "tenDoiTuon": "26-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 157/23  Pháo Đài Láng",
        "tuDien": "DDLT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 7,
        "tuDien2": "Di Dân Láng Trung",
        "diaChiLapD": "",
        "longitude": 105.80588442731398,
        "latitude": 21.018226002904036,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6920,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80600378562139,
          21.018253544173202
        ]
      },
      "properties": {
        "tenDoiTuon": "26-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 157/23  Pháo Đài Láng",
        "tuDien": "DDLT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 7,
        "tuDien2": "Di Dân Láng Trung",
        "diaChiLapD": "",
        "longitude": 105.80600378562139,
        "latitude": 21.018253544173202,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6921,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80600244451752,
          21.018372472320834
        ]
      },
      "properties": {
        "tenDoiTuon": "DDLT 26-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 157/23  Pháo Đài Láng",
        "tuDien": "DDLT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 5,
        "tuDien2": "Di Dân Láng Trung",
        "diaChiLapD": "",
        "longitude": 105.80600244451752,
        "latitude": 21.018372472320834,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6922,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83881438094356,
          21.013109199334377
        ]
      },
      "properties": {
        "tenDoiTuon": "CNC 5-15-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - đèn pha",
        "tenDuongPh": "Ngõ 249 Khâm Thiên",
        "tuDien": "CNC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "CNC",
        "diaChiLapD": "",
        "longitude": 105.83881438094356,
        "latitude": 21.013109199334377,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6923,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82053149885202,
          21.00333682278951
        ]
      },
      "properties": {
        "tenDoiTuon": "NTS2 45",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Tây Sơn",
        "tuDien": "CVNTS2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "CV NTS Tủ 2",
        "diaChiLapD": "",
        "longitude": 105.82053149885202,
        "latitude": 21.00333682278951,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6924,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82070450133223,
          21.003469534828582
        ]
      },
      "properties": {
        "tenDoiTuon": "44",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Tây Sơn",
        "tuDien": "CVNTS2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "CV NTS Tủ 2",
        "diaChiLapD": "",
        "longitude": 105.82070450133223,
        "latitude": 21.003469534828582,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6925,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8209457584235,
          21.003648523288305
        ]
      },
      "properties": {
        "tenDoiTuon": "43",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Tây Sơn",
        "tuDien": "CVNTS2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "CV NTS Tủ 2",
        "diaChiLapD": "",
        "longitude": 105.8209457584235,
        "latitude": 21.003648523288305,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6926,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8211875127014,
          21.00384347033419
        ]
      },
      "properties": {
        "tenDoiTuon": "42",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Tây Sơn",
        "tuDien": "CVNTS2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "CV NTS Tủ 2",
        "diaChiLapD": "",
        "longitude": 105.8211875127014,
        "latitude": 21.00384347033419,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6927,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82139248794144,
          21.00401039776827
        ]
      },
      "properties": {
        "tenDoiTuon": "41",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Tây Sơn",
        "tuDien": "CVNTS2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "CV NTS Tủ 2",
        "diaChiLapD": "",
        "longitude": 105.82139248794144,
        "latitude": 21.00401039776827,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6928,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82169021314454,
          21.004255788640506
        ]
      },
      "properties": {
        "tenDoiTuon": "40",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Tây Sơn",
        "tuDien": "CVNTS2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "CV NTS Tủ 2",
        "diaChiLapD": "",
        "longitude": 105.82169021314454,
        "latitude": 21.004255788640506,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6929,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82191551870267,
          21.004493667161466
        ]
      },
      "properties": {
        "tenDoiTuon": "39",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Tây Sơn",
        "tuDien": "CVNTS2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "CV NTS Tủ 2",
        "diaChiLapD": "",
        "longitude": 105.82191551870267,
        "latitude": 21.004493667161466,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6930,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82212204879761,
          21.00476660099836
        ]
      },
      "properties": {
        "tenDoiTuon": "38",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Tây Sơn",
        "tuDien": "CVNTS2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "CV NTS Tủ 2",
        "diaChiLapD": "",
        "longitude": 105.82212204879761,
        "latitude": 21.00476660099836,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6931,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82232053226036,
          21.005134685289704
        ]
      },
      "properties": {
        "tenDoiTuon": "37",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Tây Sơn",
        "tuDien": "CVNTS2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "CV NTS Tủ 2",
        "diaChiLapD": "",
        "longitude": 105.82232053226036,
        "latitude": 21.005134685289704,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6932,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82247073596578,
          21.005425145722093
        ]
      },
      "properties": {
        "tenDoiTuon": "36",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Tây Sơn",
        "tuDien": "CVNTS2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "CV NTS Tủ 2",
        "diaChiLapD": "",
        "longitude": 105.82247073596578,
        "latitude": 21.005425145722093,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6933,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82270677035486,
          21.005905906575148
        ]
      },
      "properties": {
        "tenDoiTuon": "35",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Tây Sơn",
        "tuDien": "CVNTS2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "CV NTS Tủ 2",
        "diaChiLapD": "",
        "longitude": 105.82270677035486,
        "latitude": 21.005905906575148,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6934,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82296426242385,
          21.006404193532994
        ]
      },
      "properties": {
        "tenDoiTuon": "33",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Tây Sơn",
        "tuDien": "CVNTS2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "CV NTS Tủ 2",
        "diaChiLapD": "",
        "longitude": 105.82296426242385,
        "latitude": 21.006404193532994,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6935,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82283551638936,
          21.006138774556714
        ]
      },
      "properties": {
        "tenDoiTuon": "34",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Tây Sơn",
        "tuDien": "CVNTS2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "CV NTS Tủ 2",
        "diaChiLapD": "",
        "longitude": 105.82283551638936,
        "latitude": 21.006138774556714,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6936,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82207000748876,
          21.009970479928665
        ]
      },
      "properties": {
        "tenDoiTuon": "ACD 1-1-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "",
        "tuDien": "ACD",
        "chuDauTu": "",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "ACD",
        "diaChiLapD": "",
        "longitude": 105.82207000748876,
        "latitude": 21.009970479928665,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6937,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82184201972291,
          21.00971758677013
        ]
      },
      "properties": {
        "tenDoiTuon": "ACD 1-4-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "",
        "tuDien": "ACD",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "ACD",
        "diaChiLapD": "",
        "longitude": 105.82184201972291,
        "latitude": 21.00971758677013,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6938,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82118219630395,
          21.0101582716016
        ]
      },
      "properties": {
        "tenDoiTuon": "ACD 21-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "",
        "tuDien": "ACD",
        "chuDauTu": "",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "ACD",
        "diaChiLapD": "",
        "longitude": 105.82118219630395,
        "latitude": 21.0101582716016,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6939,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82639698701979,
          21.017783431523515
        ]
      },
      "properties": {
        "tenDoiTuon": "YHBNV 01",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 64/65 Nguyễn Lương Bằng",
        "tuDien": "YHBNV",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "YHBNV",
        "diaChiLapD": "",
        "longitude": 105.82639698701979,
        "latitude": 21.017783431523515,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6940,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82664643246555,
          21.0180813785916
        ]
      },
      "properties": {
        "tenDoiTuon": "YHBNV 02",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 64 Nguyễn Lương Bằng",
        "tuDien": "YHBNV",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "YHBNV",
        "diaChiLapD": "",
        "longitude": 105.82664643246555,
        "latitude": 21.0180813785916,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6941,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82682614046512,
          21.017996250918863
        ]
      },
      "properties": {
        "tenDoiTuon": "YHBNV 03",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 64 Nguyễn Lương Bằng",
        "tuDien": "YHBNV",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "YHBNV",
        "diaChiLapD": "",
        "longitude": 105.82682614046512,
        "latitude": 21.017996250918863,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6942,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82690928894105,
          21.017750882648464
        ]
      },
      "properties": {
        "tenDoiTuon": "YHBNV 3-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 64 Nguyễn Lương Bằng",
        "tuDien": "YHBNV",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "YHBNV",
        "diaChiLapD": "",
        "longitude": 105.82690928894105,
        "latitude": 21.017750882648464,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6943,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82708631474188,
          21.017663251025162
        ]
      },
      "properties": {
        "tenDoiTuon": "YHBNV 3-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 64 Nguyễn Lương Bằng",
        "tuDien": "YHBNV",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "YHBNV",
        "diaChiLapD": "",
        "longitude": 105.82708631474188,
        "latitude": 21.017663251025162,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6944,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82737331110502,
          21.017670762309802
        ]
      },
      "properties": {
        "tenDoiTuon": "YHBNV 04",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 64 Nguyễn Lương Bằng",
        "tuDien": "YHBNV",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "YHBNV",
        "diaChiLapD": "",
        "longitude": 105.82737331110502,
        "latitude": 21.017670762309802,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6945,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82718019205775,
          21.01747797256786
        ]
      },
      "properties": {
        "tenDoiTuon": "YHBNV 4-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 64 Nguyễn Lương Bằng",
        "tuDien": "YHBNV",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "YHBNV",
        "diaChiLapD": "",
        "longitude": 105.82718019205775,
        "latitude": 21.01747797256786,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6946,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82764689642045,
          21.017523040321667
        ]
      },
      "properties": {
        "tenDoiTuon": "YHBNV 05",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 64 Nguyễn Lương Bằng",
        "tuDien": "YHBNV",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "YHBNV",
        "diaChiLapD": "",
        "longitude": 105.82764689642045,
        "latitude": 21.017523040321667,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6947,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82746987062863,
          21.017460446215257
        ]
      },
      "properties": {
        "tenDoiTuon": "YHBNV 5-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 64 Nguyễn Lương Bằng",
        "tuDien": "YHBNV",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "YHBNV",
        "diaChiLapD": "",
        "longitude": 105.82746987062863,
        "latitude": 21.017460446215257,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6948,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82792316395263,
          21.01744041609498
        ]
      },
      "properties": {
        "tenDoiTuon": "YHBNV 06",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 64 Nguyễn Lương Bằng",
        "tuDien": "YHBNV",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "YHBNV",
        "diaChiLapD": "",
        "longitude": 105.82792316395263,
        "latitude": 21.01744041609498,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6949,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82802240568401,
          21.01760316073969
        ]
      },
      "properties": {
        "tenDoiTuon": "YHBNV 6-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 64 Nguyễn Lương Bằng",
        "tuDien": "YHBNV",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "YHBNV",
        "diaChiLapD": "",
        "longitude": 105.82802240568401,
        "latitude": 21.01760316073969,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6950,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8280089946453,
          21.017405363378877
        ]
      },
      "properties": {
        "tenDoiTuon": "YHBNV 07",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 64 Nguyễn Lương Bằng",
        "tuDien": "YHBNV",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "YHBNV",
        "diaChiLapD": "",
        "longitude": 105.8280089946453,
        "latitude": 21.017405363378877,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6951,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82805727440262,
          21.01748798762498
        ]
      },
      "properties": {
        "tenDoiTuon": "YHBNV 7-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 64 Nguyễn Lương Bằng",
        "tuDien": "YHBNV",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "YHBNV",
        "diaChiLapD": "",
        "longitude": 105.82805727440262,
        "latitude": 21.01748798762498,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6952,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8281779738049,
          21.017608168264545
        ]
      },
      "properties": {
        "tenDoiTuon": "YHBNV 7-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 64 Nguyễn Lương Bằng",
        "tuDien": "YHBNV",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "YHBNV",
        "diaChiLapD": "",
        "longitude": 105.8281779738049,
        "latitude": 21.017608168264545,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6953,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82778368908716,
          21.017235107212933
        ]
      },
      "properties": {
        "tenDoiTuon": "YHBNV 08",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 64 Nguyễn Lương Bằng",
        "tuDien": "YHBNV",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "YHBNV",
        "diaChiLapD": "",
        "longitude": 105.82778368908716,
        "latitude": 21.017235107212933,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6954,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82755301910457,
          21.017235107212933
        ]
      },
      "properties": {
        "tenDoiTuon": "YHBNV 5-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 64 Nguyễn Lương Bằng",
        "tuDien": "YHBNV",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "YHBNV",
        "diaChiLapD": "",
        "longitude": 105.82755301910457,
        "latitude": 21.017235107212933,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6955,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82741086203139,
          21.017315227785673
        ]
      },
      "properties": {
        "tenDoiTuon": "YHBNV 5-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 64 Nguyễn Lương Bằng",
        "tuDien": "YHBNV",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "YHBNV",
        "diaChiLapD": "",
        "longitude": 105.82741086203139,
        "latitude": 21.017315227785673,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6956,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82763616758947,
          21.017002256553088
        ]
      },
      "properties": {
        "tenDoiTuon": "YHBNV 09",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 64/49/22 Nguyễn Lương Bằng",
        "tuDien": "YHBNV",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "YHBNV",
        "diaChiLapD": "",
        "longitude": 105.82763616758947,
        "latitude": 21.017002256553088,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6957,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82799021918211,
          21.017132452665944
        ]
      },
      "properties": {
        "tenDoiTuon": "YHBNV 10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 64 Nguyễn Lương Bằng",
        "tuDien": "YHBNV",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "YHBNV",
        "diaChiLapD": "",
        "longitude": 105.82799021918211,
        "latitude": 21.017132452665944,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6958,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82825039344986,
          21.01701978295956
        ]
      },
      "properties": {
        "tenDoiTuon": "YHBNV 12",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 64 Nguyễn Lương Bằng",
        "tuDien": "YHBNV",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "YHBNV",
        "diaChiLapD": "",
        "longitude": 105.82825039344986,
        "latitude": 21.01701978295956,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6959,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82790438848946,
          21.016957188641896
        ]
      },
      "properties": {
        "tenDoiTuon": "YHBNV 11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 64/49/22 Nguyễn Lương Bằng",
        "tuDien": "YHBNV",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "YHBNV",
        "diaChiLapD": "",
        "longitude": 105.82790438848946,
        "latitude": 21.016957188641896,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6960,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82616899925395,
          21.017490491389772
        ]
      },
      "properties": {
        "tenDoiTuon": "YHBNV 13",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 64/65 Nguyễn Lương Bằng",
        "tuDien": "YHBNV",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "YHBNV",
        "diaChiLapD": "",
        "longitude": 105.82616899925395,
        "latitude": 21.017490491389772,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6961,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8264184446997,
          21.017345272989477
        ]
      },
      "properties": {
        "tenDoiTuon": "YHBNV 14",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 64/49/22 Nguyễn Lương Bằng",
        "tuDien": "YHBNV",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "YHBNV",
        "diaChiLapD": "",
        "longitude": 105.8264184446997,
        "latitude": 21.017345272989477,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6962,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82669203001514,
          21.01725012982409
        ]
      },
      "properties": {
        "tenDoiTuon": "YHBNV 15",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 64/49/22 Nguyễn Lương Bằng",
        "tuDien": "YHBNV",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "YHBNV",
        "diaChiLapD": "",
        "longitude": 105.82669203001514,
        "latitude": 21.01725012982409,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6963,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82698170858605,
          21.017137460206598
        ]
      },
      "properties": {
        "tenDoiTuon": "YHBNV 16",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 64/49/22 Nguyễn Lương Bằng",
        "tuDien": "YHBNV",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "YHBNV",
        "diaChiLapD": "",
        "longitude": 105.82698170858605,
        "latitude": 21.017137460206598,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6964,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82659815269925,
          21.017009767870995
        ]
      },
      "properties": {
        "tenDoiTuon": "YHBNV 17-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "TT LaZe",
        "tuDien": "YHBNV",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "YHBNV",
        "diaChiLapD": "",
        "longitude": 105.82659815269925,
        "latitude": 21.017009767870995,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6965,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82633261400703,
          21.017142467747078
        ]
      },
      "properties": {
        "tenDoiTuon": "YHBNV 17-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "TT LaZe",
        "tuDien": "YHBNV",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "YHBNV",
        "diaChiLapD": "",
        "longitude": 105.82633261400703,
        "latitude": 21.017142467747078,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6966,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82619582134932,
          21.017127445125112
        ]
      },
      "properties": {
        "tenDoiTuon": "YHBNV 17",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 51/10 Hoàng Cầu",
        "tuDien": "YHBNV",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "YHBNV",
        "diaChiLapD": "",
        "longitude": 105.82619582134932,
        "latitude": 21.017127445125112,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6967,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82592223602488,
          21.017272663736705
        ]
      },
      "properties": {
        "tenDoiTuon": "YHBNV 17-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 64/65 Nguyễn Lương Bằng",
        "tuDien": "YHBNV",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "YHBNV",
        "diaChiLapD": "",
        "longitude": 105.82592223602488,
        "latitude": 21.017272663736705,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6968,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82602415997299,
          21.01741788220775
        ]
      },
      "properties": {
        "tenDoiTuon": "YHBNV 17-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 64/65 Nguyễn Lương Bằng",
        "tuDien": "YHBNV",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "YHBNV",
        "diaChiLapD": "",
        "longitude": 105.82602415997299,
        "latitude": 21.01741788220775,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6969,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8260375710117,
          21.016944669775206
        ]
      },
      "properties": {
        "tenDoiTuon": "YHBNV 18",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 51/10 Hoàng Cầu",
        "tuDien": "YHBNV",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "YHBNV",
        "diaChiLapD": "",
        "longitude": 105.8260375710117,
        "latitude": 21.016944669775206,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6970,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82595174032802,
          21.016809465946903
        ]
      },
      "properties": {
        "tenDoiTuon": "YHBNV 19",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 51/10 Hoàng Cầu",
        "tuDien": "YHBNV",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "YHBNV",
        "diaChiLapD": "",
        "longitude": 105.82595174032802,
        "latitude": 21.016809465946903,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6971,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82584445196443,
          21.016596644858737
        ]
      },
      "properties": {
        "tenDoiTuon": "YHBNV 20",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 51/10 Hoàng Cầu",
        "tuDien": "YHBNV",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "YHBNV",
        "diaChiLapD": "",
        "longitude": 105.82584445196443,
        "latitude": 21.016596644858737,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6972,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82600002008533,
          21.0165090125572
        ]
      },
      "properties": {
        "tenDoiTuon": "YHBNV 21-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 51/10 Hoàng Cầu",
        "tuDien": "YHBNV",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "YHBNV",
        "diaChiLapD": "",
        "longitude": 105.82600002008533,
        "latitude": 21.0165090125572,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6973,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82626019436206,
          21.016366296984824
        ]
      },
      "properties": {
        "tenDoiTuon": "YHBNV 21-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 51/10 Hoàng Cầu",
        "tuDien": "YHBNV",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "YHBNV",
        "diaChiLapD": "",
        "longitude": 105.82626019436206,
        "latitude": 21.016366296984824,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6974,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8264989109679,
          21.016246115344412
        ]
      },
      "properties": {
        "tenDoiTuon": "YHBNV 21-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 51/10 Hoàng Cầu",
        "tuDien": "YHBNV",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "YHBNV",
        "diaChiLapD": "",
        "longitude": 105.8264989109679,
        "latitude": 21.016246115344412,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6975,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82664643246555,
          21.01647395962215
        ]
      },
      "properties": {
        "tenDoiTuon": "YHBNV 21-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 51/10 Hoàng Cầu",
        "tuDien": "YHBNV",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "YHBNV",
        "diaChiLapD": "",
        "longitude": 105.82664643246555,
        "latitude": 21.01647395962215,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6976,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82641308027524,
          21.016586629740935
        ]
      },
      "properties": {
        "tenDoiTuon": "YHBNV 21-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 51/10 Hoàng Cầu",
        "tuDien": "YHBNV",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "YHBNV",
        "diaChiLapD": "",
        "longitude": 105.82641308027524,
        "latitude": 21.016586629740935,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6977,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82653109746978,
          21.01677691685928
        ]
      },
      "properties": {
        "tenDoiTuon": "YHBNV 21-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 51/10 Hoàng Cầu",
        "tuDien": "YHBNV",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "YHBNV",
        "diaChiLapD": "",
        "longitude": 105.82653109746978,
        "latitude": 21.01677691685928,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6978,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82568620163578,
          21.01631371752969
        ]
      },
      "properties": {
        "tenDoiTuon": "YHBNV 21",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "N51/16 Hoàng Cầu",
        "tuDien": "YHBNV",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "YHBNV",
        "diaChiLapD": "",
        "longitude": 105.82568620163578,
        "latitude": 21.01631371752969,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6979,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82545821386992,
          21.01642388398685
        ]
      },
      "properties": {
        "tenDoiTuon": "YHBNV 26",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "N51/16 Hoàng Cầu",
        "tuDien": "YHBNV",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "YHBNV",
        "diaChiLapD": "",
        "longitude": 105.82545821386992,
        "latitude": 21.01642388398685,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6980,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82575325685629,
          21.01622858884788
        ]
      },
      "properties": {
        "tenDoiTuon": "YHBNV 22",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "N51/16 Hoàng Cầu",
        "tuDien": "YHBNV",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "YHBNV",
        "diaChiLapD": "",
        "longitude": 105.82575325685629,
        "latitude": 21.01622858884788,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6981,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82611535508113,
          21.01604330860729
        ]
      },
      "properties": {
        "tenDoiTuon": "YHBNV 24",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "N51/16 Hoàng Cầu",
        "tuDien": "YHBNV",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "YHBNV",
        "diaChiLapD": "",
        "longitude": 105.82611535508113,
        "latitude": 21.01604330860729,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6982,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82632724959156,
          21.015940653239912
        ]
      },
      "properties": {
        "tenDoiTuon": "YHBNV 25",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "N51/16 Hoàng Cầu",
        "tuDien": "YHBNV",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "YHBNV",
        "diaChiLapD": "",
        "longitude": 105.82632724959156,
        "latitude": 21.015940653239912,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6983,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82597856241442,
          21.015772899194577
        ]
      },
      "properties": {
        "tenDoiTuon": "YHBNV 24-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 19 Trần Quang Diệu",
        "tuDien": "YHBNV",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "YHBNV",
        "diaChiLapD": "",
        "longitude": 105.82597856241442,
        "latitude": 21.015772899194577,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6984,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8257961722071,
          21.01598071387897
        ]
      },
      "properties": {
        "tenDoiTuon": "YHBNV 23",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 19 Trần Quang Diệu",
        "tuDien": "YHBNV",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "YHBNV",
        "diaChiLapD": "",
        "longitude": 105.8257961722071,
        "latitude": 21.01598071387897,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6985,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82565937954041,
          21.016028285874587
        ]
      },
      "properties": {
        "tenDoiTuon": "YHBNV 23-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 19 Trần Quang Diệu",
        "tuDien": "YHBNV",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "YHBNV",
        "diaChiLapD": "",
        "longitude": 105.82565937954041,
        "latitude": 21.016028285874587,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6986,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8254635782854,
          21.016133444969235
        ]
      },
      "properties": {
        "tenDoiTuon": "YHBNV 23-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 19 Trần Quang Diệu",
        "tuDien": "YHBNV",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "YHBNV",
        "diaChiLapD": "",
        "longitude": 105.8254635782854,
        "latitude": 21.016133444969235,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6987,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82532946783543,
          21.016213566133825
        ]
      },
      "properties": {
        "tenDoiTuon": "YHBNV 27",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 19 Trần Quang Diệu",
        "tuDien": "YHBNV",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "YHBNV",
        "diaChiLapD": "",
        "longitude": 105.82532946783543,
        "latitude": 21.016213566133825,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6988,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82521145064086,
          21.01601075935158
        ]
      },
      "properties": {
        "tenDoiTuon": "YHBNV 28",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 19 Trần Quang Diệu",
        "tuDien": "YHBNV",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "YHBNV",
        "diaChiLapD": "",
        "longitude": 105.82521145064086,
        "latitude": 21.01601075935158,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6989,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82508806902186,
          21.015840501593516
        ]
      },
      "properties": {
        "tenDoiTuon": "YHBNV 29",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 19 Trần Quang Diệu",
        "tuDien": "YHBNV",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "YHBNV",
        "diaChiLapD": "",
        "longitude": 105.82508806902186,
        "latitude": 21.015840501593516,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6990,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82522217947184,
          21.015895585007048
        ]
      },
      "properties": {
        "tenDoiTuon": "YHBNV 30",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 19 Trần Quang Diệu",
        "tuDien": "YHBNV",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "YHBNV",
        "diaChiLapD": "",
        "longitude": 105.82522217947184,
        "latitude": 21.015895585007048,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6991,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82516853529005,
          21.015807952293553
        ]
      },
      "properties": {
        "tenDoiTuon": "YHBNV 31",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 19 Trần Quang Diệu",
        "tuDien": "YHBNV",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "YHBNV",
        "diaChiLapD": "",
        "longitude": 105.82516853529005,
        "latitude": 21.015807952293553,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6992,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82591418940166,
          21.015627679121746
        ]
      },
      "properties": {
        "tenDoiTuon": "YHBNV 34",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 19 Trần Quang Diệu",
        "tuDien": "YHBNV",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "YHBNV",
        "diaChiLapD": "",
        "longitude": 105.82591418940166,
        "latitude": 21.015627679121746,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6993,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82551990467493,
          21.01584300538514
        ]
      },
      "properties": {
        "tenDoiTuon": "YHBNV 32",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 19 Trần Quang Diệu",
        "tuDien": "YHBNV",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "YHBNV",
        "diaChiLapD": "",
        "longitude": 105.82551990467493,
        "latitude": 21.01584300538514,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6994,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82604829985164,
          21.0155650842198
        ]
      },
      "properties": {
        "tenDoiTuon": "YHBNV 35",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 19 Trần Quang Diệu",
        "tuDien": "YHBNV",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "YHBNV",
        "diaChiLapD": "",
        "longitude": 105.82604829985164,
        "latitude": 21.0155650842198,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6995,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8257478924408,
          21.01572282332305
        ]
      },
      "properties": {
        "tenDoiTuon": "YHBNV 33",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 19 Trần Quang Diệu",
        "tuDien": "YHBNV",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "YHBNV",
        "diaChiLapD": "",
        "longitude": 105.8257478924408,
        "latitude": 21.01572282332305,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6996,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82616631704619,
          21.015675251229993
        ]
      },
      "properties": {
        "tenDoiTuon": "YHBNV 35-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 19 Trần Quang Diệu",
        "tuDien": "YHBNV",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "YHBNV",
        "diaChiLapD": "",
        "longitude": 105.82616631704619,
        "latitude": 21.015675251229993,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6997,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82566206175713,
          21.015530031062898
        ]
      },
      "properties": {
        "tenDoiTuon": "YHBNV 33-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 19 Trần Quang Diệu",
        "tuDien": "YHBNV",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "YHBNV",
        "diaChiLapD": "",
        "longitude": 105.82566206175713,
        "latitude": 21.015530031062898,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6998,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82546659583424,
          21.015084041519525
        ]
      },
      "properties": {
        "tenDoiTuon": "YHBNV 33-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "",
        "tuDien": "YHBNV",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "YHBNV",
        "diaChiLapD": "",
        "longitude": 105.82546659583424,
        "latitude": 21.015084041519525,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 6999,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82531069237224,
          21.01487653856033
        ]
      },
      "properties": {
        "tenDoiTuon": "YHBNV 33-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "",
        "tuDien": "YHBNV",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "YHBNV",
        "diaChiLapD": "",
        "longitude": 105.82531069237224,
        "latitude": 21.01487653856033,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7000,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82822189499038,
          21.00488319241548
        ]
      },
      "properties": {
        "tenDoiTuon": "TL3 1-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 95 Cự Lộc",
        "tuDien": "TL3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "TL3",
        "diaChiLapD": "",
        "longitude": 105.82822189499038,
        "latitude": 21.00488319241548,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7001,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82826648671428,
          21.00504375978367
        ]
      },
      "properties": {
        "tenDoiTuon": "TL3 1-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 95 Cự Lộc",
        "tuDien": "TL3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "TL3",
        "diaChiLapD": "",
        "longitude": 105.82826648671428,
        "latitude": 21.00504375978367,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7002,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82848341037098,
          21.004771765446172
        ]
      },
      "properties": {
        "tenDoiTuon": "TL3 1-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 95 Cự Lộc",
        "tuDien": "TL3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "TL3",
        "diaChiLapD": "",
        "longitude": 105.82848341037098,
        "latitude": 21.004771765446172,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7003,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82102452237034,
          21.003051835802463
        ]
      },
      "properties": {
        "tenDoiTuon": "NTS3 08",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Trường Chinh",
        "tuDien": "CVNTS3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "CV NTS tủ 3",
        "diaChiLapD": "",
        "longitude": 105.82102452237034,
        "latitude": 21.003051835802463,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7004,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82137186844359,
          21.00298547961238
        ]
      },
      "properties": {
        "tenDoiTuon": "07",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Trường Chinh",
        "tuDien": "CVNTS3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "CV NTS tủ 3",
        "diaChiLapD": "",
        "longitude": 105.82137186844359,
        "latitude": 21.00298547961238,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7005,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82167227585445,
          21.002915367378712
        ]
      },
      "properties": {
        "tenDoiTuon": "06",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Trường Chinh",
        "tuDien": "CVNTS3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "CV NTS tủ 3",
        "diaChiLapD": "",
        "longitude": 105.82167227585445,
        "latitude": 21.002915367378712,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7006,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82200218755942,
          21.002831483056603
        ]
      },
      "properties": {
        "tenDoiTuon": "05",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Trường Chinh",
        "tuDien": "CVNTS3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "CV NTS tủ 3",
        "diaChiLapD": "",
        "longitude": 105.82200218755942,
        "latitude": 21.002831483056603,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7007,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82230259497025,
          21.0027538587161
        ]
      },
      "properties": {
        "tenDoiTuon": "04",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Trường Chinh",
        "tuDien": "CVNTS3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "CV NTS tủ 3",
        "diaChiLapD": "",
        "longitude": 105.82230259497025,
        "latitude": 21.0027538587161,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7008,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82261641342878,
          21.002647438183924
        ]
      },
      "properties": {
        "tenDoiTuon": "03",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Trường Chinh",
        "tuDien": "CVNTS3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "CV NTS tủ 3",
        "diaChiLapD": "",
        "longitude": 105.82261641342878,
        "latitude": 21.002647438183924,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7009,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82288597543261,
          21.00251472541297
        ]
      },
      "properties": {
        "tenDoiTuon": "02",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Trường Chinh",
        "tuDien": "CVNTS3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "CV NTS tủ 3",
        "diaChiLapD": "",
        "longitude": 105.82288597543261,
        "latitude": 21.00251472541297,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7010,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82308311779147,
          21.002358224353518
        ]
      },
      "properties": {
        "tenDoiTuon": "NTS3 01",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Trường Chinh",
        "tuDien": "CVNTS3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "CV NTS tủ 3",
        "diaChiLapD": "",
        "longitude": 105.82308311779147,
        "latitude": 21.002358224353518,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7011,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8225748391908,
          21.002301883931683
        ]
      },
      "properties": {
        "tenDoiTuon": "NTS3 12",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 9,
        "maXaPhuong": 349,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Trường Chinh",
        "tuDien": "CVNTS3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "CV NTS tủ 3",
        "diaChiLapD": "",
        "longitude": 105.8225748391908,
        "latitude": 21.002301883931683,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7012,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82227174957224,
          21.002378256498062
        ]
      },
      "properties": {
        "tenDoiTuon": "13",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 9,
        "maXaPhuong": 349,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Trường Chinh",
        "tuDien": "CVNTS3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "CV NTS tủ 3",
        "diaChiLapD": "",
        "longitude": 105.82227174957224,
        "latitude": 21.002378256498062,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7013,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82195793111372,
          21.002458385051042
        ]
      },
      "properties": {
        "tenDoiTuon": "14",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 9,
        "maXaPhuong": 349,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Trường Chinh",
        "tuDien": "CVNTS3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "CV NTS tủ 3",
        "diaChiLapD": "",
        "longitude": 105.82195793111372,
        "latitude": 21.002458385051042,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7014,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82166162743323,
          21.002535038536365
        ]
      },
      "properties": {
        "tenDoiTuon": "15",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 9,
        "maXaPhuong": 349,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Trường Chinh",
        "tuDien": "CVNTS3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "CV NTS tủ 3",
        "diaChiLapD": "",
        "longitude": 105.82166162743323,
        "latitude": 21.002535038536365,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7015,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8210526855696,
          21.00269751844444
        ]
      },
      "properties": {
        "tenDoiTuon": "17",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 9,
        "maXaPhuong": 349,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Trường Chinh",
        "tuDien": "CVNTS3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "CV NTS tủ 3",
        "diaChiLapD": "",
        "longitude": 105.8210526855696,
        "latitude": 21.00269751844444,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7016,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82069729288209,
          21.0027513547048
        ]
      },
      "properties": {
        "tenDoiTuon": "NTS3 18",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 9,
        "maXaPhuong": 349,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Trường Chinh",
        "tuDien": "CVNTS3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "CV NTS tủ 3",
        "diaChiLapD": "",
        "longitude": 105.82069729288209,
        "latitude": 21.0027513547048,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7017,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82121495921884,
          21.002842751103184
        ]
      },
      "properties": {
        "tenDoiTuon": "NTS3 09",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Trường Chinh",
        "tuDien": "CVNTS3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "CV NTS tủ 3",
        "diaChiLapD": "",
        "longitude": 105.82121495921884,
        "latitude": 21.002842751103184,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7018,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82151402551682,
          21.00276011874544
        ]
      },
      "properties": {
        "tenDoiTuon": "10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Trường Chinh",
        "tuDien": "CVNTS3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "CV NTS tủ 3",
        "diaChiLapD": "",
        "longitude": 105.82151402551682,
        "latitude": 21.00276011874544,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7019,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8218171151354,
          21.002676234336043
        ]
      },
      "properties": {
        "tenDoiTuon": "NTS3 11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Trường Chinh",
        "tuDien": "CVNTS3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "CV NTS tủ 3",
        "diaChiLapD": "",
        "longitude": 105.8218171151354,
        "latitude": 21.002676234336043,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7020,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8197523171616,
          21.003455762829823
        ]
      },
      "properties": {
        "tenDoiTuon": "NTS3 33",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "CVNTS3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "CV NTS tủ 3",
        "diaChiLapD": "",
        "longitude": 105.8197523171616,
        "latitude": 21.003455762829823,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7021,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81950555393256,
          21.003463274829002
        ]
      },
      "properties": {
        "tenDoiTuon": "34",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "CVNTS3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "CV NTS tủ 3",
        "diaChiLapD": "",
        "longitude": 105.81950555393256,
        "latitude": 21.003463274829002,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7022,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8192292864004,
          21.00357845877199
        ]
      },
      "properties": {
        "tenDoiTuon": "35",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "CVNTS3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "CV NTS tủ 3",
        "diaChiLapD": "",
        "longitude": 105.8192292864004,
        "latitude": 21.00357845877199,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7023,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81900129863452,
          21.00370365860947
        ]
      },
      "properties": {
        "tenDoiTuon": "36",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "CVNTS3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "CV NTS tủ 3",
        "diaChiLapD": "",
        "longitude": 105.81900129863452,
        "latitude": 21.00370365860947,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7024,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81877331086865,
          21.00384889028905
        ]
      },
      "properties": {
        "tenDoiTuon": "37",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "CVNTS3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "CV NTS tủ 3",
        "diaChiLapD": "",
        "longitude": 105.81877331086865,
        "latitude": 21.00384889028905,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7025,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81860701391679,
          21.00404920961509
        ]
      },
      "properties": {
        "tenDoiTuon": "38",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "CVNTS3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "CV NTS tủ 3",
        "diaChiLapD": "",
        "longitude": 105.81860701391679,
        "latitude": 21.00404920961509,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7026,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8184246237005,
          21.00421697684405
        ]
      },
      "properties": {
        "tenDoiTuon": "39",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "CVNTS3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "CV NTS tủ 3",
        "diaChiLapD": "",
        "longitude": 105.8184246237005,
        "latitude": 21.00421697684405,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7027,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81824491569195,
          21.00435720005538
        ]
      },
      "properties": {
        "tenDoiTuon": "40",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "CVNTS3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "CV NTS tủ 3",
        "diaChiLapD": "",
        "longitude": 105.81824491569195,
        "latitude": 21.00435720005538,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7028,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81808398315557,
          21.004477391274033
        ]
      },
      "properties": {
        "tenDoiTuon": "41",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "CVNTS3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "CV NTS tủ 3",
        "diaChiLapD": "",
        "longitude": 105.81808398315557,
        "latitude": 21.004477391274033,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7029,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81787477085287,
          21.004587566472487
        ]
      },
      "properties": {
        "tenDoiTuon": "NTS3 42",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "CVNTS3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "CV NTS tủ 3",
        "diaChiLapD": "",
        "longitude": 105.81787477085287,
        "latitude": 21.004587566472487,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7030,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81926029939709,
          21.003192060110287
        ]
      },
      "properties": {
        "tenDoiTuon": "NTS3 19",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Trường Chinh",
        "tuDien": "CVNTS3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "CV NTS tủ 3",
        "diaChiLapD": "",
        "longitude": 105.81926029939709,
        "latitude": 21.003192060110287,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7031,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81894111653206,
          21.003334788286235
        ]
      },
      "properties": {
        "tenDoiTuon": "20",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Trường Chinh",
        "tuDien": "CVNTS3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "CV NTS tủ 3",
        "diaChiLapD": "",
        "longitude": 105.81894111653206,
        "latitude": 21.003334788286235,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7032,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81870893785592,
          21.003483306825206
        ]
      },
      "properties": {
        "tenDoiTuon": "21",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Trường Chinh",
        "tuDien": "CVNTS3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "CV NTS tủ 3",
        "diaChiLapD": "",
        "longitude": 105.81870893785592,
        "latitude": 21.003483306825206,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7033,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81848363229777,
          21.003638554707017
        ]
      },
      "properties": {
        "tenDoiTuon": "22",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Trường Chinh",
        "tuDien": "CVNTS3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "CV NTS tủ 3",
        "diaChiLapD": "",
        "longitude": 105.81848363229777,
        "latitude": 21.003638554707017,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7034,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81826369115512,
          21.003841378309296
        ]
      },
      "properties": {
        "tenDoiTuon": "23",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Trường Chinh",
        "tuDien": "CVNTS3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "CV NTS tủ 3",
        "diaChiLapD": "",
        "longitude": 105.81826369115512,
        "latitude": 21.003841378309296,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7035,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81814030954511,
          21.00396407393439
        ]
      },
      "properties": {
        "tenDoiTuon": "24",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Trường Chinh",
        "tuDien": "CVNTS3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "CV NTS tủ 3",
        "diaChiLapD": "",
        "longitude": 105.81814030954511,
        "latitude": 21.00396407393439,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7036,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81797669479198,
          21.004141857213064
        ]
      },
      "properties": {
        "tenDoiTuon": "25",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Trường Chinh",
        "tuDien": "CVNTS3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "CV NTS tủ 3",
        "diaChiLapD": "",
        "longitude": 105.81797669479198,
        "latitude": 21.004141857213064,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7037,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81780235120792,
          21.00433716817565
        ]
      },
      "properties": {
        "tenDoiTuon": "26",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Trường Chinh",
        "tuDien": "CVNTS3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "CV NTS tủ 3",
        "diaChiLapD": "",
        "longitude": 105.81780235120792,
        "latitude": 21.00433716817565,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7038,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81765482971022,
          21.00448991118703
        ]
      },
      "properties": {
        "tenDoiTuon": "NTS3 27",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 223,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Trường Chinh",
        "tuDien": "CVNTS3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "CV NTS tủ 3",
        "diaChiLapD": "",
        "longitude": 105.81765482971022,
        "latitude": 21.00448991118703,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7039,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81955400131552,
          21.003237132180118
        ]
      },
      "properties": {
        "tenDoiTuon": "NTS3 32",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "CVNTS3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "CV NTS tủ 3",
        "diaChiLapD": "",
        "longitude": 105.81955400131552,
        "latitude": 21.003237132180118,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7040,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81928829500663,
          21.00334308279357
        ]
      },
      "properties": {
        "tenDoiTuon": "31",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "CVNTS3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "CV NTS tủ 3",
        "diaChiLapD": "",
        "longitude": 105.81928829500663,
        "latitude": 21.00334308279357,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7041,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81900398084225,
          21.003480802826147
        ]
      },
      "properties": {
        "tenDoiTuon": "30",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "CVNTS3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "CV NTS tủ 3",
        "diaChiLapD": "",
        "longitude": 105.81900398084225,
        "latitude": 21.003480802826147,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7042,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81874648878227,
          21.003641058703433
        ]
      },
      "properties": {
        "tenDoiTuon": "29",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "CVNTS3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "CV NTS tủ 3",
        "diaChiLapD": "",
        "longitude": 105.81874648878227,
        "latitude": 21.003641058703433,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7043,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81851045438418,
          21.003853898275622
        ]
      },
      "properties": {
        "tenDoiTuon": "NTS3 28",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "CVNTS3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "CV NTS tủ 3",
        "diaChiLapD": "",
        "longitude": 105.81851045438418,
        "latitude": 21.003853898275622,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7044,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.81976488997334,
          21.003028986738865
        ]
      },
      "properties": {
        "tenDoiTuon": "NTS3 43",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 235,
        "loaiChaoCh": "Đường phố - đèn pha",
        "tenDuongPh": "Láng",
        "tuDien": "CVNTS3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 25,
        "tuDien2": "NTS tủ 3",
        "diaChiLapD": "",
        "longitude": 105.81976488997334,
        "latitude": 21.003028986738865,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7045,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83687454594366,
          21.01391313831439
        ]
      },
      "properties": {
        "tenDoiTuon": "CNC 5-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 249 Phố Chợ Khâm Thiên",
        "tuDien": "CNC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "CNC",
        "diaChiLapD": "",
        "longitude": 105.83687454594366,
        "latitude": 21.01391313831439,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7046,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83753705157035,
          21.01502733598864
        ]
      },
      "properties": {
        "tenDoiTuon": "CNC 8-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 191 Phố Chợ Khâm Thiên",
        "tuDien": "CNC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "CNC",
        "diaChiLapD": "",
        "longitude": 105.83753705157035,
        "latitude": 21.01502733598864,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7047,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83770066632347,
          21.014999794123653
        ]
      },
      "properties": {
        "tenDoiTuon": "CNC 8-8",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 191 Phố Chợ Khâm Thiên",
        "tuDien": "CNC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "CNC",
        "diaChiLapD": "",
        "longitude": 105.83770066632347,
        "latitude": 21.014999794123653,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7048,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83741366995136,
          21.014897138038
        ]
      },
      "properties": {
        "tenDoiTuon": "CNC 8-7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 191 Phố Chợ Khâm Thiên",
        "tuDien": "CNC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "CNC",
        "diaChiLapD": "",
        "longitude": 105.83741366995136,
        "latitude": 21.014897138038,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7049,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83725541962271,
          21.01472437519741
        ]
      },
      "properties": {
        "tenDoiTuon": "CNC 7-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 207 Phố Chợ Khâm Thiên",
        "tuDien": "CNC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "CNC",
        "diaChiLapD": "",
        "longitude": 105.83725541962271,
        "latitude": 21.01472437519741,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7050,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83740562332812,
          21.01462672654716
        ]
      },
      "properties": {
        "tenDoiTuon": "CNC 7-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 207 Phố Chợ Khâm Thiên",
        "tuDien": "CNC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "CNC",
        "diaChiLapD": "",
        "longitude": 105.83740562332812,
        "latitude": 21.01462672654716,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7051,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83606500940726,
          21.012014658358677
        ]
      },
      "properties": {
        "tenDoiTuon": "KLOCD/29",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Xã Đàn",
        "tuDien": "KLOCD",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Kim Liên OCD",
        "diaChiLapD": "",
        "longitude": 105.83606500940726,
        "latitude": 21.012014658358677,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7052,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83561842160263,
          21.01247912296333
        ]
      },
      "properties": {
        "tenDoiTuon": "KLOCD/30",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Xã Đàn",
        "tuDien": "KLOCD",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Kim Liên OCD",
        "diaChiLapD": "",
        "longitude": 105.83561842160263,
        "latitude": 21.01247912296333,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7053,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8351959736856,
          21.012913540032034
        ]
      },
      "properties": {
        "tenDoiTuon": "KLOCD/31",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Xã Đàn",
        "tuDien": "KLOCD",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Kim Liên OCD",
        "diaChiLapD": "",
        "longitude": 105.8351959736856,
        "latitude": 21.012913540032034,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7054,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8347627969287,
          21.013357971170826
        ]
      },
      "properties": {
        "tenDoiTuon": "KLOCD/32",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Xã Đàn",
        "tuDien": "KLOCD",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Kim Liên OCD",
        "diaChiLapD": "",
        "longitude": 105.8347627969287,
        "latitude": 21.013357971170826,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7055,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83433900790781,
          21.013794889505924
        ]
      },
      "properties": {
        "tenDoiTuon": "KLOCD/33",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Xã Đàn",
        "tuDien": "KLOCD",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Kim Liên OCD",
        "diaChiLapD": "",
        "longitude": 105.83433900790781,
        "latitude": 21.013794889505924,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7056,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83388437347995,
          21.014256844749788
        ]
      },
      "properties": {
        "tenDoiTuon": "KLOCD/34",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Xã Đàn",
        "tuDien": "KLOCD",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Kim Liên OCD",
        "diaChiLapD": "",
        "longitude": 105.83388437347995,
        "latitude": 21.014256844749788,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7057,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83346997218618,
          21.0146837452062
        ]
      },
      "properties": {
        "tenDoiTuon": "KLOCD/35",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Xã Đàn",
        "tuDien": "KLOCD",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Kim Liên OCD",
        "diaChiLapD": "",
        "longitude": 105.83346997218618,
        "latitude": 21.0146837452062,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7058,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83307702856332,
          21.015091865908182
        ]
      },
      "properties": {
        "tenDoiTuon": "KLOCD/36",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Xã Đàn",
        "tuDien": "KLOCD",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Kim Liên OCD",
        "diaChiLapD": "",
        "longitude": 105.83307702856332,
        "latitude": 21.015091865908182,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7059,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83266262726954,
          21.01552752726602
        ]
      },
      "properties": {
        "tenDoiTuon": "KLOCD/37",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Xã Đàn",
        "tuDien": "KLOCD",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Kim Liên OCD",
        "diaChiLapD": "",
        "longitude": 105.83266262726954,
        "latitude": 21.01552752726602,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7060,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83223615604093,
          21.01596944682546
        ]
      },
      "properties": {
        "tenDoiTuon": "KLOCD/38",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Xã Đàn",
        "tuDien": "KLOCD",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Kim Liên OCD",
        "diaChiLapD": "",
        "longitude": 105.83223615604093,
        "latitude": 21.01596944682546,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7061,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83181370811498,
          21.016356281852367
        ]
      },
      "properties": {
        "tenDoiTuon": "KLOCD/39",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Xã Đàn",
        "tuDien": "KLOCD",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Kim Liên OCD",
        "diaChiLapD": "",
        "longitude": 105.83181370811498,
        "latitude": 21.016356281852367,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7062,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83133359270457,
          21.016726841324875
        ]
      },
      "properties": {
        "tenDoiTuon": "KLOCD/40",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Xã Đàn",
        "tuDien": "KLOCD",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Kim Liên OCD",
        "diaChiLapD": "",
        "longitude": 105.83133359270457,
        "latitude": 21.016726841324875,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7063,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83070729690037,
          21.017206313871753
        ]
      },
      "properties": {
        "tenDoiTuon": "KLOCD/41",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Xã Đàn",
        "tuDien": "KLOCD",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Kim Liên OCD",
        "diaChiLapD": "",
        "longitude": 105.83070729690037,
        "latitude": 21.017206313871753,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7064,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83649952726806,
          21.011550192307812
        ]
      },
      "properties": {
        "tenDoiTuon": "KLOCD/28",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Xã Đàn",
        "tuDien": "KLOCD",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Kim Liên OCD",
        "diaChiLapD": "",
        "longitude": 105.83649952726806,
        "latitude": 21.011550192307812,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7065,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83690990525022,
          21.01131983664093
        ]
      },
      "properties": {
        "tenDoiTuon": "KLOCD/1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - đèn pha",
        "tenDuongPh": "Xã Đàn",
        "tuDien": "KLOCD",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 14,
        "tuDien2": "Kim Liên OCD",
        "diaChiLapD": "",
        "longitude": 105.83690990525022,
        "latitude": 21.01131983664093,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7066,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83630274367837,
          21.013981993469823
        ]
      },
      "properties": {
        "tenDoiTuon": "CNC 4-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 292 Phố Chợ Khâm Thiên",
        "tuDien": "CNC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "CNC",
        "diaChiLapD": "",
        "longitude": 105.83630274367837,
        "latitude": 21.013981993469823,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7067,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83612840009428,
          21.01409466547118
        ]
      },
      "properties": {
        "tenDoiTuon": "CNC 4-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 292 Phố Chợ Khâm Thiên",
        "tuDien": "CNC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "CNC",
        "diaChiLapD": "",
        "longitude": 105.83612840009428,
        "latitude": 21.01409466547118,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7068,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83606670928926,
          21.014168528181468
        ]
      },
      "properties": {
        "tenDoiTuon": "CNC 4-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 292 Phố Chợ Khâm Thiên",
        "tuDien": "CNC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "CNC",
        "diaChiLapD": "",
        "longitude": 105.83606670928926,
        "latitude": 21.014168528181468,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7069,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83607609701637,
          21.014028314191528
        ]
      },
      "properties": {
        "tenDoiTuon": "CNC 4-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 292 Phố Chợ Khâm Thiên",
        "tuDien": "CNC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "CNC",
        "diaChiLapD": "",
        "longitude": 105.83607609701637,
        "latitude": 21.014028314191528,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7070,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83601842952295,
          21.013969474353395
        ]
      },
      "properties": {
        "tenDoiTuon": "CNC 4-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 292 Phố Chợ Khâm Thiên",
        "tuDien": "CNC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "CNC",
        "diaChiLapD": "",
        "longitude": 105.83601842952295,
        "latitude": 21.013969474353395,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7071,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83643783646305,
          21.011825617095234
        ]
      },
      "properties": {
        "tenDoiTuon": "KLOCD/2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Xã Đàn",
        "tuDien": "KLOCD",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Kim Liên OCD",
        "diaChiLapD": "",
        "longitude": 105.83643783646305,
        "latitude": 21.011825617095234,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7072,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83599795418675,
          21.012291334215387
        ]
      },
      "properties": {
        "tenDoiTuon": "KLOCD/3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Xã Đàn",
        "tuDien": "KLOCD",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Kim Liên OCD",
        "diaChiLapD": "",
        "longitude": 105.83599795418675,
        "latitude": 21.012291334215387,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7073,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8355822117981,
          21.012719492218945
        ]
      },
      "properties": {
        "tenDoiTuon": "KLOCD/4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Xã Đàn",
        "tuDien": "KLOCD",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Kim Liên OCD",
        "diaChiLapD": "",
        "longitude": 105.8355822117981,
        "latitude": 21.012719492218945,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7074,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83517183381595,
          21.013152656669646
        ]
      },
      "properties": {
        "tenDoiTuon": "KLOCD/5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Xã Đàn",
        "tuDien": "KLOCD",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Kim Liên OCD",
        "diaChiLapD": "",
        "longitude": 105.83517183381595,
        "latitude": 21.013152656669646,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7075,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83475877363502,
          21.01358581986156
        ]
      },
      "properties": {
        "tenDoiTuon": "KLOCD/6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Xã Đàn",
        "tuDien": "KLOCD",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Kim Liên OCD",
        "diaChiLapD": "",
        "longitude": 105.83475877363502,
        "latitude": 21.01358581986156,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7076,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83434034902963,
          21.014016477972465
        ]
      },
      "properties": {
        "tenDoiTuon": "KLOCD/7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Xã Đàn",
        "tuDien": "KLOCD",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Kim Liên OCD",
        "diaChiLapD": "",
        "longitude": 105.83434034902963,
        "latitude": 21.014016477972465,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7077,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83392460663202,
          21.01445214247156
        ]
      },
      "properties": {
        "tenDoiTuon": "KLOCD/8",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Xã Đàn",
        "tuDien": "KLOCD",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Kim Liên OCD",
        "diaChiLapD": "",
        "longitude": 105.83392460663202,
        "latitude": 21.01445214247156,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7078,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83350618201766,
          21.01489030950569
        ]
      },
      "properties": {
        "tenDoiTuon": "KLOCD/9",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Xã Đàn",
        "tuDien": "KLOCD",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Kim Liên OCD",
        "diaChiLapD": "",
        "longitude": 105.83350618201766,
        "latitude": 21.01489030950569,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7079,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83309312183673,
          21.015320963850783
        ]
      },
      "properties": {
        "tenDoiTuon": "KLOCD/10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Xã Đàn",
        "tuDien": "KLOCD",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Kim Liên OCD",
        "diaChiLapD": "",
        "longitude": 105.83309312183673,
        "latitude": 21.015320963850783,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7080,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83268006164683,
          21.01574911315836
        ]
      },
      "properties": {
        "tenDoiTuon": "KLOCD/11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Xã Đàn",
        "tuDien": "KLOCD",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Kim Liên OCD",
        "diaChiLapD": "",
        "longitude": 105.83268006164683,
        "latitude": 21.01574911315836,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7081,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83225359040922,
          21.016164742304618
        ]
      },
      "properties": {
        "tenDoiTuon": "KLOCD/12",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Xã Đàn",
        "tuDien": "KLOCD",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Kim Liên OCD",
        "diaChiLapD": "",
        "longitude": 105.83225359040922,
        "latitude": 21.016164742304618,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7082,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83173592408147,
          21.016599148637667
        ]
      },
      "properties": {
        "tenDoiTuon": "KLOCD/13",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Xã Đàn",
        "tuDien": "KLOCD",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Kim Liên OCD",
        "diaChiLapD": "",
        "longitude": 105.83173592408147,
        "latitude": 21.016599148637667,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7083,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83119143564035,
          21.01702854616285
        ]
      },
      "properties": {
        "tenDoiTuon": "KLOCD/14",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 208,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Xã Đàn",
        "tuDien": "KLOCD",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Kim Liên OCD",
        "diaChiLapD": "",
        "longitude": 105.83119143564035,
        "latitude": 21.01702854616285,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7084,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8281177138185,
          21.028450316744486
        ]
      },
      "properties": {
        "tenDoiTuon": "18-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 178,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 35/26 an trạch",
        "tuDien": "CL3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Cát Linh 3",
        "diaChiLapD": "",
        "longitude": 105.8281177138185,
        "latitude": 21.028450316744486,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7085,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82759392280407,
          21.023381106470225
        ]
      },
      "properties": {
        "tenDoiTuon": "44",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 46/12 Hào Nam",
        "tuDien": "DDHN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Di Dân Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82759392280407,
        "latitude": 21.023381106470225,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7086,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82779508847456,
          21.02328095982126
        ]
      },
      "properties": {
        "tenDoiTuon": "45",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 46/12 Hào Nam",
        "tuDien": "DDHN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Di Dân Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82779508847456,
        "latitude": 21.02328095982126,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7087,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82802173513656,
          21.02315828008529
        ]
      },
      "properties": {
        "tenDoiTuon": "46",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 46/12 Hào Nam",
        "tuDien": "DDHN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Di Dân Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82802173513656,
        "latitude": 21.02315828008529,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7088,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82815316337881,
          21.02329973732333
        ]
      },
      "properties": {
        "tenDoiTuon": "47",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 46/12 Hào Nam",
        "tuDien": "DDHN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Di Dân Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82815316337881,
        "latitude": 21.02329973732333,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7089,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82754161973513,
          21.02410341218165
        ]
      },
      "properties": {
        "tenDoiTuon": "41",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 86 Hào Nam",
        "tuDien": "DDHN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Di Dân Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82754161973513,
        "latitude": 21.02410341218165,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7090,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82791042347932,
          21.02408213112759
        ]
      },
      "properties": {
        "tenDoiTuon": "42",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 86 Hào Nam",
        "tuDien": "DDHN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Di Dân Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82791042347932,
        "latitude": 21.02408213112759,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7091,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82849648614943,
          21.02400451784562
        ]
      },
      "properties": {
        "tenDoiTuon": "43-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 86 Hào Nam",
        "tuDien": "DDHN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Di Dân Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82849648614943,
        "latitude": 21.02400451784562,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7092,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82818400879479,
          21.02406335372407
        ]
      },
      "properties": {
        "tenDoiTuon": "43",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 86 Hào Nam",
        "tuDien": "DDHN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Di Dân Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82818400879479,
        "latitude": 21.02406335372407,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7093,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82847368736564,
          21.024182277238346
        ]
      },
      "properties": {
        "tenDoiTuon": "43-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 86 Hào Nam",
        "tuDien": "DDHN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Di Dân Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82847368736564,
        "latitude": 21.024182277238346,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7094,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82851526161262,
          21.02435502912015
        ]
      },
      "properties": {
        "tenDoiTuon": "43-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 86 Hào Nam",
        "tuDien": "DDHN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Di Dân Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82851526161262,
        "latitude": 21.02435502912015,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7095,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82824033518432,
          21.024427634923967
        ]
      },
      "properties": {
        "tenDoiTuon": "43-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 86 Hào Nam",
        "tuDien": "DDHN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Di Dân Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82824033518432,
        "latitude": 21.024427634923967,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7096,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8051669673935,
          21.02327501361513
        ]
      },
      "properties": {
        "tenDoiTuon": "02",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 91 Chùa Láng",
        "tuDien": "ĐHNT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "TT Đại Học Ngoại Thương",
        "diaChiLapD": "",
        "longitude": 105.8051669673935,
        "latitude": 21.02327501361513,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7097,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80515221524195,
          21.02310226048092
        ]
      },
      "properties": {
        "tenDoiTuon": "03",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 91 Chùa Láng",
        "tuDien": "ĐHNT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "TT Đại Học Ngoại Thương",
        "diaChiLapD": "",
        "longitude": 105.80515221524195,
        "latitude": 21.02310226048092,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7098,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8278768958646,
          21.023874327733353
        ]
      },
      "properties": {
        "tenDoiTuon": "42-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 86/20 Hào Nam",
        "tuDien": "DDHN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Di Dân Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.8278768958646,
        "latitude": 21.023874327733353,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7099,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82824569959979,
          21.023716597253273
        ]
      },
      "properties": {
        "tenDoiTuon": "42-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 86/20 Hào Nam",
        "tuDien": "DDHN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Di Dân Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82824569959979,
        "latitude": 21.023716597253273,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7100,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82774014364203,
          21.02450369421323
        ]
      },
      "properties": {
        "tenDoiTuon": "31-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 46/54 Hào Nam",
        "tuDien": "DDHN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Di Dân Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82774014364203,
        "latitude": 21.02450369421323,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7101,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80512539315555,
          21.02276802015345
        ]
      },
      "properties": {
        "tenDoiTuon": "04",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 91 Chùa Láng",
        "tuDien": "ĐHNT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "TT Đại Học Ngoại Thương",
        "diaChiLapD": "",
        "longitude": 105.80512539315555,
        "latitude": 21.02276802015345,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7102,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80480203621138,
          21.022772559005244
        ]
      },
      "properties": {
        "tenDoiTuon": "05",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 91 Chùa Láng",
        "tuDien": "ĐHNT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "TT Đại Học Ngoại Thương",
        "diaChiLapD": "",
        "longitude": 105.80480203621138,
        "latitude": 21.022772559005244,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7103,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8278540970898,
          21.0247418423919
        ]
      },
      "properties": {
        "tenDoiTuon": "38",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 46/58 Hào Nam",
        "tuDien": "DDHN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Di Dân Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.8278540970898,
        "latitude": 21.0247418423919,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7104,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82807929880428,
          21.02468403489286
        ]
      },
      "properties": {
        "tenDoiTuon": "39",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 46/58 Hào Nam",
        "tuDien": "DDHN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Di Dân Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82807929880428,
        "latitude": 21.02468403489286,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7105,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82822826524048,
          21.02467549585074
        ]
      },
      "properties": {
        "tenDoiTuon": "40",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 46/58 Hào Nam",
        "tuDien": "DDHN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Di Dân Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82822826524048,
        "latitude": 21.02467549585074,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7106,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82848441620563,
          21.02471430232212
        ]
      },
      "properties": {
        "tenDoiTuon": "40-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 46/58 Hào Nam",
        "tuDien": "DDHN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Di Dân Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82848441620563,
        "latitude": 21.02471430232212,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7107,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82857695241763,
          21.024927111823626
        ]
      },
      "properties": {
        "tenDoiTuon": "40-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 46/58 Hào Nam",
        "tuDien": "DDHN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Di Dân Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82857695241763,
        "latitude": 21.024927111823626,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7108,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82806632691438,
          21.006227821417134
        ]
      },
      "properties": {
        "tenDoiTuon": "TL08",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 95/8 Cự Lộc",
        "tuDien": "TL3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "TL3",
        "diaChiLapD": "",
        "longitude": 105.82806632691438,
        "latitude": 21.006227821417134,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7109,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82780078822218,
          21.006345507177233
        ]
      },
      "properties": {
        "tenDoiTuon": "TL09",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 95/8 Cự Lộc",
        "tuDien": "TL3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "TL3",
        "diaChiLapD": "",
        "longitude": 105.82780078822218,
        "latitude": 21.006345507177233,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7110,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8275419550493,
          21.006463192845352
        ]
      },
      "properties": {
        "tenDoiTuon": "TL10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 95/8 Cự Lộc",
        "tuDien": "TL3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "TL3",
        "diaChiLapD": "",
        "longitude": 105.8275419550493,
        "latitude": 21.006463192845352,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7111,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82701758319318,
          21.006703571793246
        ]
      },
      "properties": {
        "tenDoiTuon": "TL3 11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 95/8 Cự Lộc",
        "tuDien": "TL3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "TL3",
        "diaChiLapD": "",
        "longitude": 105.82701758319318,
        "latitude": 21.006703571793246,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7112,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82662329846646,
          21.006880099836565
        ]
      },
      "properties": {
        "tenDoiTuon": "TL3 12",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 95/8 Cự Lộc",
        "tuDien": "TL3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "TL3",
        "diaChiLapD": "",
        "longitude": 105.82662329846646,
        "latitude": 21.006880099836565,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7113,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82634837203817,
          21.006997785082202
        ]
      },
      "properties": {
        "tenDoiTuon": "TL03 13",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 95/8 Cự Lộc",
        "tuDien": "TL3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "TL3",
        "diaChiLapD": "",
        "longitude": 105.82634837203817,
        "latitude": 21.006997785082202,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7114,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82613513641488,
          21.007109210390905
        ]
      },
      "properties": {
        "tenDoiTuon": "TL03 14",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 95/8 Cự Lộc",
        "tuDien": "TL3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "TL3",
        "diaChiLapD": "",
        "longitude": 105.82613513641488,
        "latitude": 21.007109210390905,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7115,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8258132713421,
          21.007245674977593
        ]
      },
      "properties": {
        "tenDoiTuon": "15",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 95/8 Cự Lộc",
        "tuDien": "TL3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "TL3",
        "diaChiLapD": "",
        "longitude": 105.8258132713421,
        "latitude": 21.007245674977593,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7116,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82556650811307,
          21.007363359935724
        ]
      },
      "properties": {
        "tenDoiTuon": "16",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 95/8 Cự Lộc",
        "tuDien": "TL3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "TL3",
        "diaChiLapD": "",
        "longitude": 105.82556650811307,
        "latitude": 21.007363359935724,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7117,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82530901604406,
          21.007481044801004
        ]
      },
      "properties": {
        "tenDoiTuon": "17",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 95/8 Cự Lộc",
        "tuDien": "TL3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "TL3",
        "diaChiLapD": "",
        "longitude": 105.82530901604406,
        "latitude": 21.007481044801004,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7118,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82493216564968,
          21.00765131210483
        ]
      },
      "properties": {
        "tenDoiTuon": "TL3 18",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 95/8 Cự Lộc",
        "tuDien": "TL3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "TL3",
        "diaChiLapD": "",
        "longitude": 105.82493216564968,
        "latitude": 21.00765131210483,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7119,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83667494716188,
          21.013426926144206
        ]
      },
      "properties": {
        "tenDoiTuon": "CNC 4-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 273 Phố Chợ Khâm Thiên",
        "tuDien": "CNC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "CNC",
        "diaChiLapD": "",
        "longitude": 105.83667494716188,
        "latitude": 21.013426926144206,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7120,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82697147047419,
          21.02333176089045
        ]
      },
      "properties": {
        "tenDoiTuon": "DDHN 20",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ44+ngách 55+27HN",
        "tuDien": "DDHN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Di Dân Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82697147047419,
        "latitude": 21.02333176089045,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7121,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83644935442199,
          21.01338470320012
        ]
      },
      "properties": {
        "tenDoiTuon": "CNC 3-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 308 Phố Chợ Khâm Thiên",
        "tuDien": "CNC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "CNC",
        "diaChiLapD": "",
        "longitude": 105.83644935442199,
        "latitude": 21.01338470320012,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7122,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82725328225915,
          21.02347499389217
        ]
      },
      "properties": {
        "tenDoiTuon": "28",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 46 Hào Nam",
        "tuDien": "DDHN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Di Dân Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82725328225915,
        "latitude": 21.02347499389217,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7123,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82739141602973,
          21.02412469323268
        ]
      },
      "properties": {
        "tenDoiTuon": "30",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 46 Hào Nam",
        "tuDien": "DDHN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Di Dân Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82739141602973,
        "latitude": 21.02412469323268,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7124,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82733777184794,
          21.023779188733336
        ]
      },
      "properties": {
        "tenDoiTuon": "29",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 46 Hào Nam",
        "tuDien": "DDHN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Di Dân Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82733777184794,
        "latitude": 21.023779188733336,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7125,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8275130903433,
          21.02446247874849
        ]
      },
      "properties": {
        "tenDoiTuon": "31",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 46 Hào Nam",
        "tuDien": "DDHN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Di Dân Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.8275130903433,
        "latitude": 21.02446247874849,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7126,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82763549705103,
          21.024826966213258
        ]
      },
      "properties": {
        "tenDoiTuon": "32",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 46 Hào Nam",
        "tuDien": "DDHN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Di Dân Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82763549705103,
        "latitude": 21.024826966213258,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7127,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83600737065149,
          21.01375311542903
        ]
      },
      "properties": {
        "tenDoiTuon": "CNC 3+1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 308 Phố Chợ Khâm Thiên",
        "tuDien": "CNC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "CNC",
        "diaChiLapD": "",
        "longitude": 105.83600737065149,
        "latitude": 21.01375311542903,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7128,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82767707128004,
          21.02507858193214
        ]
      },
      "properties": {
        "tenDoiTuon": "33",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 126 Hào Nam",
        "tuDien": "DDHN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Di Dân Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82767707128004,
        "latitude": 21.02507858193214,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7129,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82807135600673,
          21.024953400036065
        ]
      },
      "properties": {
        "tenDoiTuon": "34",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 126 Hào Nam",
        "tuDien": "DDHN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Di Dân Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82807135600673,
        "latitude": 21.024953400036065,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7130,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82822424191988,
          21.0249070827081
        ]
      },
      "properties": {
        "tenDoiTuon": "35",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 126 Hào Nam",
        "tuDien": "DDHN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Di Dân Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82822424191988,
        "latitude": 21.0249070827081,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7131,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82838249225752,
          21.025323938143337
        ]
      },
      "properties": {
        "tenDoiTuon": "37",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 126 Hào Nam",
        "tuDien": "DDHN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Di Dân Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82838249225752,
        "latitude": 21.025323938143337,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7132,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82864803094974,
          21.025340211755132
        ]
      },
      "properties": {
        "tenDoiTuon": "36",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 126 Hào Nam",
        "tuDien": "DDHN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Di Dân Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82864803094974,
        "latitude": 21.025340211755132,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7133,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82841467875942,
          21.025527984072134
        ]
      },
      "properties": {
        "tenDoiTuon": "37-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 152 Hào Nam",
        "tuDien": "DDHN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Di Dân Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82841467875942,
        "latitude": 21.025527984072134,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7134,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82870076145039,
          21.02545546642937
        ]
      },
      "properties": {
        "tenDoiTuon": "37-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 152 Hào Nam",
        "tuDien": "DDHN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Di Dân Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82870076145039,
        "latitude": 21.02545546642937,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7135,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82816319523972,
          21.025532413127177
        ]
      },
      "properties": {
        "tenDoiTuon": "37-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 152 Hào Nam",
        "tuDien": "DDHN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Di Dân Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82816319523972,
        "latitude": 21.025532413127177,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7136,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82798758614238,
          21.02558416852652
        ]
      },
      "properties": {
        "tenDoiTuon": "37-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 152 Hào Nam",
        "tuDien": "DDHN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Di Dân Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82798758614238,
        "latitude": 21.02558416852652,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7137,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82780836587096,
          21.025639133316396
        ]
      },
      "properties": {
        "tenDoiTuon": "37-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 152 Hào Nam",
        "tuDien": "DDHN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Di Dân Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82780836587096,
        "latitude": 21.025639133316396,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7138,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82894039172837,
          21.025425335234768
        ]
      },
      "properties": {
        "tenDoiTuon": "37-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 152 Hào Nam",
        "tuDien": "DDHN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Di Dân Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82894039172837,
        "latitude": 21.025425335234768,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7139,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8354524834199,
          21.01366025184245
        ]
      },
      "properties": {
        "tenDoiTuon": "CNC 13-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 84 La Thành",
        "tuDien": "CNC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "CNC",
        "diaChiLapD": "",
        "longitude": 105.8354524834199,
        "latitude": 21.01366025184245,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7140,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8250354307286,
          21.00783910669112
        ]
      },
      "properties": {
        "tenDoiTuon": "TL3 18-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 95/8 Cự Lộc",
        "tuDien": "TL3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "TL3",
        "diaChiLapD": "",
        "longitude": 105.8250354307286,
        "latitude": 21.00783910669112,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7141,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82490400248636,
          21.00796179902987
        ]
      },
      "properties": {
        "tenDoiTuon": "18-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 95/8 Cự Lộc",
        "tuDien": "TL3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "TL3",
        "diaChiLapD": "",
        "longitude": 105.82490400248636,
        "latitude": 21.00796179902987,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7142,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82892798651532,
          21.025712313666958
        ]
      },
      "properties": {
        "tenDoiTuon": "50-12",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 160 Hào Nam",
        "tuDien": "DDHN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Di Dân Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82892798651532,
        "latitude": 21.025712313666958,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7143,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82864635456767,
          21.02574486080479
        ]
      },
      "properties": {
        "tenDoiTuon": "50-13",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 160 Hào Nam",
        "tuDien": "DDHN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Di Dân Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82864635456767,
        "latitude": 21.02574486080479,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7144,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8283499704774,
          21.0258212213682
        ]
      },
      "properties": {
        "tenDoiTuon": "50-14",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 160 Hào Nam",
        "tuDien": "DDHN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Di Dân Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.8283499704774,
        "latitude": 21.0258212213682,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7145,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82800128330028,
          21.025853768481415
        ]
      },
      "properties": {
        "tenDoiTuon": "50-15",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 160 Hào Nam",
        "tuDien": "DDHN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Di Dân Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82800128330028,
        "latitude": 21.025853768481415,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7146,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83525295270655,
          21.013879196039568
        ]
      },
      "properties": {
        "tenDoiTuon": "CNC 14-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 96 La Thành",
        "tuDien": "CNC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "CNC",
        "diaChiLapD": "",
        "longitude": 105.83525295270655,
        "latitude": 21.013879196039568,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7147,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83535830679497,
          21.01401504841948
        ]
      },
      "properties": {
        "tenDoiTuon": "CNC 14-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 96 La Thành",
        "tuDien": "CNC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "CNC",
        "diaChiLapD": "",
        "longitude": 105.83535830679497,
        "latitude": 21.01401504841948,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7148,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83545384289586,
          21.014126264534184
        ]
      },
      "properties": {
        "tenDoiTuon": "CNC 14-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 96 La Thành",
        "tuDien": "CNC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "CNC",
        "diaChiLapD": "",
        "longitude": 105.83545384289586,
        "latitude": 21.014126264534184,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7149,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83564292025942,
          21.01428620767748
        ]
      },
      "properties": {
        "tenDoiTuon": "CNC 14-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 96 La Thành",
        "tuDien": "CNC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "CNC",
        "diaChiLapD": "",
        "longitude": 105.83564292025942,
        "latitude": 21.01428620767748,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7150,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83544309569282,
          21.01450278778511
        ]
      },
      "properties": {
        "tenDoiTuon": "CNC 14-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 96 La Thành",
        "tuDien": "CNC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "CNC",
        "diaChiLapD": "",
        "longitude": 105.83544309569282,
        "latitude": 21.01450278778511,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7151,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83523595461531,
          21.01462057295226
        ]
      },
      "properties": {
        "tenDoiTuon": "CNC 14-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 96 La Thành",
        "tuDien": "CNC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "CNC",
        "diaChiLapD": "",
        "longitude": 105.83523595461531,
        "latitude": 21.01462057295226,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7152,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83558338837203,
          21.01469767124136
        ]
      },
      "properties": {
        "tenDoiTuon": "CNC 14-7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 96 La Thành",
        "tuDien": "CNC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "CNC",
        "diaChiLapD": "",
        "longitude": 105.83558338837203,
        "latitude": 21.01469767124136,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7153,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83572204543273,
          21.014708100428628
        ]
      },
      "properties": {
        "tenDoiTuon": "CNC 14-8",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 211,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 96 La Thành",
        "tuDien": "CNC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "CNC",
        "diaChiLapD": "",
        "longitude": 105.83572204543273,
        "latitude": 21.014708100428628,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7154,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83578105403,
          21.01438886418364
        ]
      },
      "properties": {
        "tenDoiTuon": "CNC 14-8",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 96 La Thành",
        "tuDien": "CNC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "CNC",
        "diaChiLapD": "",
        "longitude": 105.83578105403,
        "latitude": 21.01438886418364,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7155,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83562784483344,
          21.01450539839293
        ]
      },
      "properties": {
        "tenDoiTuon": "CNC 14-9",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 96 La Thành",
        "tuDien": "CNC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "CNC",
        "diaChiLapD": "",
        "longitude": 105.83562784483344,
        "latitude": 21.01450539839293,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7156,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82900256066378,
          21.023175532636564
        ]
      },
      "properties": {
        "tenDoiTuon": "49",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 127 QT1",
        "tuDien": "DDHN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Di Dân Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82900256066378,
        "latitude": 21.023175532636564,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7157,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8288129868157,
          21.023254671314735
        ]
      },
      "properties": {
        "tenDoiTuon": "48",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 127 QT1",
        "tuDien": "DDHN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Di Dân Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.8288129868157,
        "latitude": 21.023254671314735,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7158,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82880091687188,
          21.023406143121765
        ]
      },
      "properties": {
        "tenDoiTuon": "48-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 127 QT1",
        "tuDien": "DDHN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Di Dân Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82880091687188,
        "latitude": 21.023406143121765,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7159,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8288532199498,
          21.023607688014398
        ]
      },
      "properties": {
        "tenDoiTuon": "48-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 127 QT1",
        "tuDien": "DDHN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Di Dân Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.8288532199498,
        "latitude": 21.023607688014398,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7160,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82853537817967,
          21.02326218231765
        ]
      },
      "properties": {
        "tenDoiTuon": "48-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 127 QT1",
        "tuDien": "DDHN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Di Dân Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82853537817967,
        "latitude": 21.02326218231765,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7161,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82860377451303,
          21.02341866144593
        ]
      },
      "properties": {
        "tenDoiTuon": "48-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 127 QT1",
        "tuDien": "DDHN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Di Dân Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82860377451303,
        "latitude": 21.02341866144593,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7162,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8283824922665,
          21.023434935265723
        ]
      },
      "properties": {
        "tenDoiTuon": "48-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 127 QT1",
        "tuDien": "DDHN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "Di Dân Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.8283824922665,
        "latitude": 21.023434935265723,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7163,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82836774011494,
          21.023626465475324
        ]
      },
      "properties": {
        "tenDoiTuon": "48-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 127 QT1",
        "tuDien": "DDHN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "Di Dân Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82836774011494,
        "latitude": 21.023626465475324,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7164,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82847771068624,
          21.023724108232095
        ]
      },
      "properties": {
        "tenDoiTuon": "48-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 127 QT1",
        "tuDien": "DDHN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "Di Dân Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82847771068624,
        "latitude": 21.023724108232095,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7165,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82903351387321,
          21.023732105223477
        ]
      },
      "properties": {
        "tenDoiTuon": "50-2-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 143 QT1",
        "tuDien": "DDHN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 7,
        "tuDien2": "Di Dân Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82903351387321,
        "latitude": 21.023732105223477,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7166,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83513238119207,
          21.014025168821732
        ]
      },
      "properties": {
        "tenDoiTuon": "CNC 15-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 104 La Thành",
        "tuDien": "CNC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "CNC",
        "diaChiLapD": "",
        "longitude": 105.83513238119207,
        "latitude": 21.014025168821732,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7167,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83519004868546,
          21.0141528637136
        ]
      },
      "properties": {
        "tenDoiTuon": "CNC 15-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 104 La Thành",
        "tuDien": "CNC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "CNC",
        "diaChiLapD": "",
        "longitude": 105.83519004868546,
        "latitude": 21.0141528637136,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7168,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82896685073979,
          21.024010469928157
        ]
      },
      "properties": {
        "tenDoiTuon": "50-3-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 153 QT1",
        "tuDien": "DDHN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Di Dân Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82896685073979,
        "latitude": 21.024010469928157,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7169,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82923208195055,
          21.026964434471093
        ]
      },
      "properties": {
        "tenDoiTuon": "3-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 127 An Trạch",
        "tuDien": "CL3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Cát Linh 3",
        "diaChiLapD": "",
        "longitude": 105.82923208195055,
        "latitude": 21.026964434471093,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7170,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82901214080793,
          21.02724734159008
        ]
      },
      "properties": {
        "tenDoiTuon": "3-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 127 An Trạch",
        "tuDien": "CL3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Cát Linh 3",
        "diaChiLapD": "",
        "longitude": 105.82901214080793,
        "latitude": 21.02724734159008,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7171,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82930517216992,
          21.02412970053851
        ]
      },
      "properties": {
        "tenDoiTuon": "50-3-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 116 QT1",
        "tuDien": "DDHN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "Di Dân Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82930517216992,
        "latitude": 21.02412970053851,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7172,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8349278375955,
          21.013810148167035
        ]
      },
      "properties": {
        "tenDoiTuon": "CNC 15-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 104 La Thành",
        "tuDien": "CNC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "CNC",
        "diaChiLapD": "",
        "longitude": 105.8349278375955,
        "latitude": 21.013810148167035,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7173,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82899671813587,
          21.024402598444002
        ]
      },
      "properties": {
        "tenDoiTuon": "50-22",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 163 QT1",
        "tuDien": "DDHN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Di Dân Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82899671813587,
        "latitude": 21.024402598444002,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7174,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82870838065986,
          21.02440885756481
        ]
      },
      "properties": {
        "tenDoiTuon": "50-23",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 163 QT1",
        "tuDien": "DDHN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Di Dân Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82870838065986,
        "latitude": 21.02440885756481,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7175,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82881164571184,
          21.02466673309688
        ]
      },
      "properties": {
        "tenDoiTuon": "50-5-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 199 QT1",
        "tuDien": "DDHN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 7,
        "tuDien2": "Di Dân Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82881164571184,
        "latitude": 21.02466673309688,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7176,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8360086830267,
          21.01010420382994
        ]
      },
      "properties": {
        "tenDoiTuon": "B1KL/9",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Phạm Ngọc Thạch",
        "tuDien": "B1KL",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "B1KL",
        "diaChiLapD": "",
        "longitude": 105.8360086830267,
        "latitude": 21.01010420382994,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7177,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8363372536368,
          21.01036210410039
        ]
      },
      "properties": {
        "tenDoiTuon": "B1KL/8",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Phạm Ngọc Thạch",
        "tuDien": "B1KL",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "B1KL",
        "diaChiLapD": "",
        "longitude": 105.8363372536368,
        "latitude": 21.01036210410039,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7178,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83661620336773,
          21.0103934026468
        ]
      },
      "properties": {
        "tenDoiTuon": "B1KL/7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Phạm Ngọc Thạch",
        "tuDien": "B1KL",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "B1KL",
        "diaChiLapD": "",
        "longitude": 105.83661620336773,
        "latitude": 21.0103934026468,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7179,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8357646020144,
          21.00989888484944
        ]
      },
      "properties": {
        "tenDoiTuon": "B1KL/10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Phạm Ngọc Thạch",
        "tuDien": "B1KL",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "B1KL",
        "diaChiLapD": "",
        "longitude": 105.8357646020144,
        "latitude": 21.00989888484944,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7180,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83482526826187,
          21.014032680289585
        ]
      },
      "properties": {
        "tenDoiTuon": "CNC 16-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 96 La Thành",
        "tuDien": "CNC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "CNC",
        "diaChiLapD": "",
        "longitude": 105.83482526826187,
        "latitude": 21.014032680289585,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7181,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83461924900827,
          21.01413539525583
        ]
      },
      "properties": {
        "tenDoiTuon": "CNC 16-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 96 La Thành",
        "tuDien": "CNC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "CNC",
        "diaChiLapD": "",
        "longitude": 105.83461924900827,
        "latitude": 21.01413539525583,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7182,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82865071322036,
          21.00732360997281
        ]
      },
      "properties": {
        "tenDoiTuon": "TL3 19",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Chùa Bộc",
        "tuDien": "TL3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 11,
        "tuDien2": "TL3",
        "diaChiLapD": "",
        "longitude": 105.82865071322036,
        "latitude": 21.00732360997281,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7183,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82833823588368,
          21.007451310599663
        ]
      },
      "properties": {
        "tenDoiTuon": "TL3 20",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Chùa Bộc",
        "tuDien": "TL3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 11,
        "tuDien2": "TL3",
        "diaChiLapD": "",
        "longitude": 105.82833823588368,
        "latitude": 21.007451310599663,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7184,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82805123951155,
          21.00758401897938
        ]
      },
      "properties": {
        "tenDoiTuon": "TL3 21",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Chùa Bộc",
        "tuDien": "TL3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 11,
        "tuDien2": "TL3",
        "diaChiLapD": "",
        "longitude": 105.82805123951155,
        "latitude": 21.00758401897938,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7185,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82773339775937,
          21.0077292468806
        ]
      },
      "properties": {
        "tenDoiTuon": "TL3 22",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Chùa Bộc",
        "tuDien": "TL3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 11,
        "tuDien2": "TL3",
        "diaChiLapD": "",
        "longitude": 105.82773339775937,
        "latitude": 21.0077292468806,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7186,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82739275721444,
          21.00789200212114
        ]
      },
      "properties": {
        "tenDoiTuon": "TL3 23",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Chùa Bộc",
        "tuDien": "TL3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 11,
        "tuDien2": "TL3",
        "diaChiLapD": "",
        "longitude": 105.82739275721444,
        "latitude": 21.00789200212114,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7187,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82717683937443,
          21.0079696237913
        ]
      },
      "properties": {
        "tenDoiTuon": "TL3 24",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Chùa Bộc",
        "tuDien": "TL3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 11,
        "tuDien2": "TL3",
        "diaChiLapD": "",
        "longitude": 105.82717683937443,
        "latitude": 21.0079696237913,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7188,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82682546999851,
          21.008136134651544
        ]
      },
      "properties": {
        "tenDoiTuon": "TL3 25",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Chùa Bộc",
        "tuDien": "TL3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 11,
        "tuDien2": "TL3",
        "diaChiLapD": "",
        "longitude": 105.82682546999851,
        "latitude": 21.008136134651544,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7189,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82653310921094,
          21.008262582623892
        ]
      },
      "properties": {
        "tenDoiTuon": "TL3 26",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Chùa Bộc",
        "tuDien": "TL3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 11,
        "tuDien2": "TL3",
        "diaChiLapD": "",
        "longitude": 105.82653310921094,
        "latitude": 21.008262582623892,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7190,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82627964046252,
          21.008371503069377
        ]
      },
      "properties": {
        "tenDoiTuon": "TL3 27",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Chùa Bộc",
        "tuDien": "TL3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 11,
        "tuDien2": "TL3",
        "diaChiLapD": "",
        "longitude": 105.82627964046252,
        "latitude": 21.008371503069377,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7191,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82605969931988,
          21.00847666756209
        ]
      },
      "properties": {
        "tenDoiTuon": "TL3 28",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Chùa Bộc",
        "tuDien": "TL3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 11,
        "tuDien2": "TL3",
        "diaChiLapD": "",
        "longitude": 105.82605969931988,
        "latitude": 21.00847666756209,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7192,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82585585144166,
          21.008569312410167
        ]
      },
      "properties": {
        "tenDoiTuon": "TL3 29",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Chùa Bộc",
        "tuDien": "TL3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 11,
        "tuDien2": "TL3",
        "diaChiLapD": "",
        "longitude": 105.82585585144166,
        "latitude": 21.008569312410167,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7193,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82566005017767,
          21.00865319350671
        ]
      },
      "properties": {
        "tenDoiTuon": "TL3 30",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Chùa Bộc",
        "tuDien": "TL3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 11,
        "tuDien2": "TL3",
        "diaChiLapD": "",
        "longitude": 105.82566005017767,
        "latitude": 21.00865319350671,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7194,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82550984648124,
          21.00870953153056
        ]
      },
      "properties": {
        "tenDoiTuon": "TL3 31",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Chùa Bộc",
        "tuDien": "TL3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 11,
        "tuDien2": "TL3",
        "diaChiLapD": "",
        "longitude": 105.82550984648124,
        "latitude": 21.00870953153056,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7195,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82529795195283,
          21.008808436009655
        ]
      },
      "properties": {
        "tenDoiTuon": "TL3 32",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Chùa Bộc",
        "tuDien": "TL3",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 11,
        "tuDien2": "TL3",
        "diaChiLapD": "",
        "longitude": 105.82529795195283,
        "latitude": 21.008808436009655,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7196,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82931321879315,
          21.026762268215066
        ]
      },
      "properties": {
        "tenDoiTuon": "DDHN 50-19",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 160/23 Hào Nam",
        "tuDien": "DDHN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Di Dân Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82931321879315,
        "latitude": 21.026762268215066,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7197,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82942855377995,
          21.02672471408173
        ]
      },
      "properties": {
        "tenDoiTuon": "50-20",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 160/23 Hào Nam",
        "tuDien": "DDHN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "Di Dân Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82942855377995,
        "latitude": 21.02672471408173,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7198,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8295599820222,
          21.02673723212724
        ]
      },
      "properties": {
        "tenDoiTuon": "DDHN 50-21",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 160/23 Hào Nam",
        "tuDien": "DDHN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Di Dân Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.8295599820222,
        "latitude": 21.02673723212724,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7199,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82923945804433,
          21.02648311559609
        ]
      },
      "properties": {
        "tenDoiTuon": "50-18",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 160/23 Hào Nam",
        "tuDien": "DDHN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Di Dân Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82923945804433,
        "latitude": 21.02648311559609,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7200,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82920190711796,
          21.026260293847983
        ]
      },
      "properties": {
        "tenDoiTuon": "50-16",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 160/23 Hào Nam",
        "tuDien": "DDHN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Di Dân Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82920190711796,
        "latitude": 21.026260293847983,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7201,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82954522987063,
          21.026198955218156
        ]
      },
      "properties": {
        "tenDoiTuon": "50-17",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 160/23 Hào Nam",
        "tuDien": "DDHN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Di Dân Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82954522987063,
        "latitude": 21.026198955218156,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7202,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83546151239582,
          21.009638479882458
        ]
      },
      "properties": {
        "tenDoiTuon": "B1KL/11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Phạm Ngọc Thạch",
        "tuDien": "B1KL",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "B1KL",
        "diaChiLapD": "",
        "longitude": 105.83546151239582,
        "latitude": 21.009638479882458,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7203,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83491685632904,
          21.00919951495318
        ]
      },
      "properties": {
        "tenDoiTuon": "B1KL/13",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Phạm Ngọc Thạch",
        "tuDien": "B1KL",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "B1KL",
        "diaChiLapD": "",
        "longitude": 105.83491685632904,
        "latitude": 21.00919951495318,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7204,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83518792707137,
          21.009424396612296
        ]
      },
      "properties": {
        "tenDoiTuon": "B1KL/12",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Phạm Ngọc Thạch",
        "tuDien": "B1KL",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "B1KL",
        "diaChiLapD": "",
        "longitude": 105.83518792707137,
        "latitude": 21.009424396612296,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7205,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8344260120877,
          21.00879513399478
        ]
      },
      "properties": {
        "tenDoiTuon": "B1KL/15",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Phạm Ngọc Thạch",
        "tuDien": "B1KL",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "B1KL",
        "diaChiLapD": "",
        "longitude": 105.8344260120877,
        "latitude": 21.00879513399478,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7206,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8342089207874,
          21.008609375035665
        ]
      },
      "properties": {
        "tenDoiTuon": "B1KL/16",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Phạm Ngọc Thạch",
        "tuDien": "B1KL",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "B1KL",
        "diaChiLapD": "",
        "longitude": 105.8342089207874,
        "latitude": 21.008609375035665,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7207,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83395344038769,
          21.008385900600956
        ]
      },
      "properties": {
        "tenDoiTuon": "B1KL/17",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Phạm Ngọc Thạch",
        "tuDien": "B1KL",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "B1KL",
        "diaChiLapD": "",
        "longitude": 105.83395344038769,
        "latitude": 21.008385900600956,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7208,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83672013897612,
          21.010639408992525
        ]
      },
      "properties": {
        "tenDoiTuon": "B1KL/3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Phạm Ngọc Thạch",
        "tuDien": "B1KL",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "B1KL",
        "diaChiLapD": "",
        "longitude": 105.83672013897612,
        "latitude": 21.010639408992525,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7209,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83695885558194,
          21.01080716881148
        ]
      },
      "properties": {
        "tenDoiTuon": "B1KL/4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Phạm Ngọc Thạch",
        "tuDien": "B1KL",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "B1KL",
        "diaChiLapD": "",
        "longitude": 105.83695885558194,
        "latitude": 21.01080716881148,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7210,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8367925586211,
          21.010992455556508
        ]
      },
      "properties": {
        "tenDoiTuon": "B1KL/6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Phạm Ngọc Thạch",
        "tuDien": "B1KL",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "B1KL",
        "diaChiLapD": "",
        "longitude": 105.8367925586211,
        "latitude": 21.010992455556508,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7211,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83658602852613,
          21.01079339749001
        ]
      },
      "properties": {
        "tenDoiTuon": "B1KL/5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Phạm Ngọc Thạch",
        "tuDien": "B1KL",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "B1KL",
        "diaChiLapD": "",
        "longitude": 105.83658602852613,
        "latitude": 21.01079339749001,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7212,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83681669850873,
          21.010392776677065
        ]
      },
      "properties": {
        "tenDoiTuon": "B1KL/2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Đào Duy Anh",
        "tuDien": "B1KL",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "B1KL",
        "diaChiLapD": "",
        "longitude": 105.83681669850873,
        "latitude": 21.010392776677065,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7213,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83699908871603,
          21.01019121392395
        ]
      },
      "properties": {
        "tenDoiTuon": "B1KL/1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Đào Duy Anh",
        "tuDien": "B1KL",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "B1KL",
        "diaChiLapD": "",
        "longitude": 105.83699908871603,
        "latitude": 21.01019121392395,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7214,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83524542694813,
          21.009777915533007
        ]
      },
      "properties": {
        "tenDoiTuon": "BXKL/32",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 46A Phạm Ngọc Thạch",
        "tuDien": "B1KL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "B1KL",
        "diaChiLapD": "",
        "longitude": 105.83524542694813,
        "latitude": 21.009777915533007,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7215,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83509388213885,
          21.009943172429274
        ]
      },
      "properties": {
        "tenDoiTuon": "BXKL/33",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 46A Phạm Ngọc Thạch",
        "tuDien": "B1KL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "B1KL",
        "diaChiLapD": "",
        "longitude": 105.83509388213885,
        "latitude": 21.009943172429274,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7216,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83529370671442,
          21.0100683669271
        ]
      },
      "properties": {
        "tenDoiTuon": "BXKL/33-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 46A Phạm Ngọc Thạch",
        "tuDien": "B1KL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "B1KL",
        "diaChiLapD": "",
        "longitude": 105.83529370671442,
        "latitude": 21.0100683669271,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7217,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83547877913847,
          21.010231119615828
        ]
      },
      "properties": {
        "tenDoiTuon": "BXKL/33-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 46A Phạm Ngọc Thạch",
        "tuDien": "B1KL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "B1KL",
        "diaChiLapD": "",
        "longitude": 105.83547877913847,
        "latitude": 21.010231119615828,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7218,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8352856600912,
          21.010306236181137
        ]
      },
      "properties": {
        "tenDoiTuon": "BXKL/34-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 46A Phạm Ngọc Thạch",
        "tuDien": "B1KL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "B1KL",
        "diaChiLapD": "",
        "longitude": 105.8352856600912,
        "latitude": 21.010306236181137,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7219,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83513545638577,
          21.010178537997117
        ]
      },
      "properties": {
        "tenDoiTuon": "BXKL/34-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 46A Phạm Ngọc Thạch",
        "tuDien": "B1KL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "B1KL",
        "diaChiLapD": "",
        "longitude": 105.83513545638577,
        "latitude": 21.010178537997117,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7220,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83495574838624,
          21.010093405813716
        ]
      },
      "properties": {
        "tenDoiTuon": "BXKL/34",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 46A Phạm Ngọc Thạch",
        "tuDien": "B1KL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "B1KL",
        "diaChiLapD": "",
        "longitude": 105.83495574838624,
        "latitude": 21.010093405813716,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7221,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83478140479318,
          21.01027618955939
        ]
      },
      "properties": {
        "tenDoiTuon": "BXKL/35",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 46A Phạm Ngọc Thạch",
        "tuDien": "B1KL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "B1KL",
        "diaChiLapD": "",
        "longitude": 105.83478140479318,
        "latitude": 21.01027618955939,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7222,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83468752747729,
          21.01060169430669
        ]
      },
      "properties": {
        "tenDoiTuon": "BXKL/36-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 46A Phạm Ngọc Thạch",
        "tuDien": "B1KL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "B1KL",
        "diaChiLapD": "",
        "longitude": 105.83468752747729,
        "latitude": 21.01060169430669,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7223,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8346016967936,
          21.010479004138972
        ]
      },
      "properties": {
        "tenDoiTuon": "BXKL/37",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 46A Phạm Ngọc Thạch",
        "tuDien": "B1KL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "B1KL",
        "diaChiLapD": "",
        "longitude": 105.8346016967936,
        "latitude": 21.010479004138972,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7224,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83472776062037,
          21.010499035194115
        ]
      },
      "properties": {
        "tenDoiTuon": "BXKL/36",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 46A Phạm Ngọc Thạch",
        "tuDien": "B1KL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "B1KL",
        "diaChiLapD": "",
        "longitude": 105.83472776062037,
        "latitude": 21.010499035194115,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7225,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83494334316418,
          21.00981766484033
        ]
      },
      "properties": {
        "tenDoiTuon": "BXKL/33-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 46A Phạm Ngọc Thạch",
        "tuDien": "B1KL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "B1KL",
        "diaChiLapD": "",
        "longitude": 105.83494334316418,
        "latitude": 21.00981766484033,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7226,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83465366458432,
          21.009502173977353
        ]
      },
      "properties": {
        "tenDoiTuon": "BXKL/33-2-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 46A Phạm Ngọc Thạch",
        "tuDien": "B1KL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "B1KL",
        "diaChiLapD": "",
        "longitude": 105.83465366458432,
        "latitude": 21.009502173977353,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7227,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83475022411693,
          21.009659919492243
        ]
      },
      "properties": {
        "tenDoiTuon": "BXKL/33-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 46A Phạm Ngọc Thạch",
        "tuDien": "B1KL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "B1KL",
        "diaChiLapD": "",
        "longitude": 105.83475022411693,
        "latitude": 21.009659919492243,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7228,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83443372345067,
          21.009469623294258
        ]
      },
      "properties": {
        "tenDoiTuon": "B1KL/21-8",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 46B Phạm Ngọc Thạch",
        "tuDien": "B1KL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "B1KL",
        "diaChiLapD": "",
        "longitude": 105.83443372345067,
        "latitude": 21.009469623294258,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7229,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83425133323438,
          21.009649903909484
        ]
      },
      "properties": {
        "tenDoiTuon": "B1KL/21-7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 46B Phạm Ngọc Thạch",
        "tuDien": "B1KL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "B1KL",
        "diaChiLapD": "",
        "longitude": 105.83425133323438,
        "latitude": 21.009649903909484,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7230,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83405821418714,
          21.009840199877527
        ]
      },
      "properties": {
        "tenDoiTuon": "B1KL/21-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 46B Phạm Ngọc Thạch",
        "tuDien": "B1KL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "B1KL",
        "diaChiLapD": "",
        "longitude": 105.83405821418714,
        "latitude": 21.009840199877527,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7231,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83422048782738,
          21.00997415807614
        ]
      },
      "properties": {
        "tenDoiTuon": "B1KL/21-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 46B Phạm Ngọc Thạch",
        "tuDien": "B1KL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "B1KL",
        "diaChiLapD": "",
        "longitude": 105.83422048782738,
        "latitude": 21.00997415807614,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7232,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83451016640724,
          21.010207019708087
        ]
      },
      "properties": {
        "tenDoiTuon": "B1KL/21-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 46B Phạm Ngọc Thạch",
        "tuDien": "B1KL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "B1KL",
        "diaChiLapD": "",
        "longitude": 105.83451016640724,
        "latitude": 21.010207019708087,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7233,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83397640680609,
          21.009961638623373
        ]
      },
      "properties": {
        "tenDoiTuon": "B1KL/21-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 46B Phạm Ngọc Thạch",
        "tuDien": "B1KL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "B1KL",
        "diaChiLapD": "",
        "longitude": 105.83397640680609,
        "latitude": 21.009961638623373,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7234,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83413733935146,
          21.01018698861456
        ]
      },
      "properties": {
        "tenDoiTuon": "B1KL/21-3-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 46B Phạm Ngọc Thạch",
        "tuDien": "B1KL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "B1KL",
        "diaChiLapD": "",
        "longitude": 105.83413733935146,
        "latitude": 21.01018698861456,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7235,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83401831631329,
          21.00868245799052
        ]
      },
      "properties": {
        "tenDoiTuon": "B1KL/18",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 46C Phạm Ngọc Thạch",
        "tuDien": "B1KL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "B1KL",
        "diaChiLapD": "",
        "longitude": 105.83401831631329,
        "latitude": 21.00868245799052,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7236,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83380105738736,
          21.008927841179233
        ]
      },
      "properties": {
        "tenDoiTuon": "B1KL/19",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 46C Phạm Ngọc Thạch",
        "tuDien": "B1KL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "B1KL",
        "diaChiLapD": "",
        "longitude": 105.83380105738736,
        "latitude": 21.008927841179233,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7237,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83341079597497,
          21.00839575977433
        ]
      },
      "properties": {
        "tenDoiTuon": "B1KL/19-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 46C Phạm Ngọc Thạch",
        "tuDien": "B1KL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "B1KL",
        "diaChiLapD": "",
        "longitude": 105.83341079597497,
        "latitude": 21.00839575977433,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7238,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83370583897032,
          21.00864114343458
        ]
      },
      "properties": {
        "tenDoiTuon": "B1KL/19-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 46C Phạm Ngọc Thạch",
        "tuDien": "B1KL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "B1KL",
        "diaChiLapD": "",
        "longitude": 105.83370583897032,
        "latitude": 21.00864114343458,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7239,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83354356531835,
          21.00894787244266
        ]
      },
      "properties": {
        "tenDoiTuon": "B1KL/20-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 46C Phạm Ngọc Thạch",
        "tuDien": "B1KL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "B1KL",
        "diaChiLapD": "",
        "longitude": 105.83354356531835,
        "latitude": 21.00894787244266,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7240,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83329411988159,
          21.008742551869943
        ]
      },
      "properties": {
        "tenDoiTuon": "B1KL/20-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 46C Phạm Ngọc Thạch",
        "tuDien": "B1KL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "B1KL",
        "diaChiLapD": "",
        "longitude": 105.83329411988159,
        "latitude": 21.008742551869943,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7241,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83383324389823,
          21.00917572786873
        ]
      },
      "properties": {
        "tenDoiTuon": "B1KL/20-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 46C Phạm Ngọc Thạch",
        "tuDien": "B1KL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "B1KL",
        "diaChiLapD": "",
        "longitude": 105.83383324389823,
        "latitude": 21.00917572786873,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7242,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83451988940358,
          21.009303426911117
        ]
      },
      "properties": {
        "tenDoiTuon": "B1KL/19-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 46C Phạm Ngọc Thạch",
        "tuDien": "B1KL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "B1KL",
        "diaChiLapD": "",
        "longitude": 105.83451988940358,
        "latitude": 21.009303426911117,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7243,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8342838550055,
          21.00911563416379
        ]
      },
      "properties": {
        "tenDoiTuon": "B1KL/19-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 46C Phạm Ngọc Thạch",
        "tuDien": "B1KL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "B1KL",
        "diaChiLapD": "",
        "longitude": 105.8342838550055,
        "latitude": 21.00911563416379,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7244,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8340424562009,
          21.00892032945517
        ]
      },
      "properties": {
        "tenDoiTuon": "B1KL/19-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 46C Phạm Ngọc Thạch",
        "tuDien": "B1KL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "B1KL",
        "diaChiLapD": "",
        "longitude": 105.8340424562009,
        "latitude": 21.00892032945517,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7245,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83401295189779,
          21.009325962025155
        ]
      },
      "properties": {
        "tenDoiTuon": "B1KL/20-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 46C Phạm Ngọc Thạch",
        "tuDien": "B1KL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "B1KL",
        "diaChiLapD": "",
        "longitude": 105.83401295189779,
        "latitude": 21.009325962025155,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7246,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83426507954233,
          21.009518762304168
        ]
      },
      "properties": {
        "tenDoiTuon": "B1KL/20-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 46B Phạm Ngọc Thạch",
        "tuDien": "B1KL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "B1KL",
        "diaChiLapD": "",
        "longitude": 105.83426507954233,
        "latitude": 21.009518762304168,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7247,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83356770520601,
          21.009193255193985
        ]
      },
      "properties": {
        "tenDoiTuon": "B1KL/20",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 46C Phạm Ngọc Thạch",
        "tuDien": "B1KL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "B1KL",
        "diaChiLapD": "",
        "longitude": 105.83356770520601,
        "latitude": 21.009193255193985,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7248,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83333435301567,
          21.009433629743416
        ]
      },
      "properties": {
        "tenDoiTuon": "B1KL/21",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 46C Phạm Ngọc Thạch",
        "tuDien": "B1KL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "B1KL",
        "diaChiLapD": "",
        "longitude": 105.83333435301567,
        "latitude": 21.009433629743416,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7249,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83314928060062,
          21.009598887021934
        ]
      },
      "properties": {
        "tenDoiTuon": "B1KL/22",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 46C Phạm Ngọc Thạch",
        "tuDien": "B1KL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "B1KL",
        "diaChiLapD": "",
        "longitude": 105.83314928060062,
        "latitude": 21.009598887021934,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7250,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83344968800247,
          21.009613910402177
        ]
      },
      "properties": {
        "tenDoiTuon": "B1KL/21-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 46C Phạm Ngọc Thạch",
        "tuDien": "B1KL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "B1KL",
        "diaChiLapD": "",
        "longitude": 105.83344968800247,
        "latitude": 21.009613910402177,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7251,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83362671380328,
          21.009756632434538
        ]
      },
      "properties": {
        "tenDoiTuon": "B1KL/21-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 46C Phạm Ngọc Thạch",
        "tuDien": "B1KL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "B1KL",
        "diaChiLapD": "",
        "longitude": 105.83362671380328,
        "latitude": 21.009756632434538,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7252,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8333504462711,
          21.009806710309064
        ]
      },
      "properties": {
        "tenDoiTuon": "B1KL/22-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 46C Phạm Ngọc Thạch",
        "tuDien": "B1KL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "B1KL",
        "diaChiLapD": "",
        "longitude": 105.8333504462711,
        "latitude": 21.009806710309064,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7253,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83356770520601,
          21.009989494406014
        ]
      },
      "properties": {
        "tenDoiTuon": "B1KL/22-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 46C Phạm Ngọc Thạch",
        "tuDien": "B1KL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "B1KL",
        "diaChiLapD": "",
        "longitude": 105.83356770520601,
        "latitude": 21.009989494406014,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7254,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83378680813067,
          21.010181511364692
        ]
      },
      "properties": {
        "tenDoiTuon": "B1KL/22-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 46C Phạm Ngọc Thạch",
        "tuDien": "B1KL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "BTLT",
        "chieuCao": 6,
        "tuDien2": "B1KL",
        "diaChiLapD": "",
        "longitude": 105.83378680813067,
        "latitude": 21.010181511364692,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7255,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83266111856697,
          21.009228309839223
        ]
      },
      "properties": {
        "tenDoiTuon": "B1KL/22-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 46C Phạm Ngọc Thạch",
        "tuDien": "B1KL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "B1KL",
        "diaChiLapD": "",
        "longitude": 105.83266111856697,
        "latitude": 21.009228309839223,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7256,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.832918610636,
          21.009416102444696
        ]
      },
      "properties": {
        "tenDoiTuon": "B1KL/22-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 46C Phạm Ngọc Thạch",
        "tuDien": "B1KL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "B1KL",
        "diaChiLapD": "",
        "longitude": 105.832918610636,
        "latitude": 21.009416102444696,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7257,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83548682576169,
          21.00803644725853
        ]
      },
      "properties": {
        "tenDoiTuon": "B1KL/27",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 65 Phạm Ngọc Thạch",
        "tuDien": "B1KL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "B1KL",
        "diaChiLapD": "",
        "longitude": 105.83548682576169,
        "latitude": 21.00803644725853,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7258,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83536076194392,
          21.00819419432319
        ]
      },
      "properties": {
        "tenDoiTuon": "B1KL/26",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 65 Phạm Ngọc Thạch",
        "tuDien": "B1KL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "B1KL",
        "diaChiLapD": "",
        "longitude": 105.83536076194392,
        "latitude": 21.00819419432319,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7259,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83509254104395,
          21.007971345246485
        ]
      },
      "properties": {
        "tenDoiTuon": "B1KL/28",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 65 Phạm Ngọc Thạch",
        "tuDien": "B1KL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "B1KL",
        "diaChiLapD": "",
        "longitude": 105.83509254104395,
        "latitude": 21.007971345246485,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7260,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83485114223039,
          21.007725960484617
        ]
      },
      "properties": {
        "tenDoiTuon": "B1KL/29",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 65 Phạm Ngọc Thạch",
        "tuDien": "B1KL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "B1KL",
        "diaChiLapD": "",
        "longitude": 105.83485114223039,
        "latitude": 21.007725960484617,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7261,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83518641835086,
          21.008336917714576
        ]
      },
      "properties": {
        "tenDoiTuon": "B1KL/25",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 65 Phạm Ngọc Thạch",
        "tuDien": "B1KL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "B1KL",
        "diaChiLapD": "",
        "longitude": 105.83518641835086,
        "latitude": 21.008336917714576,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7262,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8347358072436,
          21.00794129815449
        ]
      },
      "properties": {
        "tenDoiTuon": "B1KL/31",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 65 Phạm Ngọc Thạch",
        "tuDien": "B1KL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "B1KL",
        "diaChiLapD": "",
        "longitude": 105.8347358072436,
        "latitude": 21.00794129815449,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7263,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83499329930359,
          21.00815913943417
        ]
      },
      "properties": {
        "tenDoiTuon": "B1KL/30",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 65 Phạm Ngọc Thạch",
        "tuDien": "B1KL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "B1KL",
        "diaChiLapD": "",
        "longitude": 105.83499329930359,
        "latitude": 21.00815913943417,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7264,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83494770175402,
          21.00856978190117
        ]
      },
      "properties": {
        "tenDoiTuon": "B1KL/24",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 65 Phạm Ngọc Thạch",
        "tuDien": "B1KL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "B1KL",
        "diaChiLapD": "",
        "longitude": 105.83494770175402,
        "latitude": 21.00856978190117,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7265,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83475458270676,
          21.00879513399478
        ]
      },
      "properties": {
        "tenDoiTuon": "B1KL/23",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 65 Phạm Ngọc Thạch",
        "tuDien": "B1KL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "B1KL",
        "diaChiLapD": "",
        "longitude": 105.83475458270676,
        "latitude": 21.00879513399478,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7266,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8279741259267,
          21.00792830904146
        ]
      },
      "properties": {
        "tenDoiTuon": "TL3 22-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 12 Chùa Bộc",
        "tuDien": "TL3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "TL3",
        "diaChiLapD": "",
        "longitude": 105.8279741259267,
        "latitude": 21.00792830904146,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7267,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82809482532898,
          21.00835647957072
        ]
      },
      "properties": {
        "tenDoiTuon": "22-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 12 Chùa Bộc",
        "tuDien": "TL3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "TL3",
        "diaChiLapD": "",
        "longitude": 105.82809482532898,
        "latitude": 21.00835647957072,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7268,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82785074430767,
          21.008931127557137
        ]
      },
      "properties": {
        "tenDoiTuon": "22-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 12 Chùa Bộc",
        "tuDien": "TL3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "TL3",
        "diaChiLapD": "",
        "longitude": 105.82785074430767,
        "latitude": 21.008931127557137,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7269,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82793523390545,
          21.009181518142086
        ]
      },
      "properties": {
        "tenDoiTuon": "22-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 12 Chùa Bộc",
        "tuDien": "TL3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "TL3",
        "diaChiLapD": "",
        "longitude": 105.82793523390545,
        "latitude": 21.009181518142086,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7270,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82805727441159,
          21.00938558616029
        ]
      },
      "properties": {
        "tenDoiTuon": "22-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 12 Chùa Bộc",
        "tuDien": "TL3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "TL3",
        "diaChiLapD": "",
        "longitude": 105.82805727441159,
        "latitude": 21.00938558616029,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7271,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82817797382287,
          21.009627212348224
        ]
      },
      "properties": {
        "tenDoiTuon": "TL3 22-7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 12 Chùa Bộc",
        "tuDien": "TL3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "TL3",
        "diaChiLapD": "",
        "longitude": 105.82817797382287,
        "latitude": 21.009627212348224,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7272,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82801435906077,
          21.008823459475366
        ]
      },
      "properties": {
        "tenDoiTuon": "22-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 12 Chùa Bộc",
        "tuDien": "TL3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "TL3",
        "diaChiLapD": "",
        "longitude": 105.82801435906077,
        "latitude": 21.008823459475366,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7273,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83465282637532,
          21.008982457661716
        ]
      },
      "properties": {
        "tenDoiTuon": "B1KL/14",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Phạm Ngọc Thạch",
        "tuDien": "B1KL",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "B1KL",
        "diaChiLapD": "",
        "longitude": 105.83465282637532,
        "latitude": 21.008982457661716,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7274,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83240245300179,
          21.00903754360786
        ]
      },
      "properties": {
        "tenDoiTuon": "B1KL/22-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 46C Phạm Ngọc Thạch",
        "tuDien": "B1KL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "B1KL",
        "diaChiLapD": "",
        "longitude": 105.83240245300179,
        "latitude": 21.00903754360786,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7275,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82718287427447,
          21.00838402266064
        ]
      },
      "properties": {
        "tenDoiTuon": "25-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 14 Chùa Bộc",
        "tuDien": "TL3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "TL3",
        "diaChiLapD": "",
        "longitude": 105.82718287427447,
        "latitude": 21.00838402266064,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7276,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82663033919216,
          21.008717043271098
        ]
      },
      "properties": {
        "tenDoiTuon": "TL3 26-11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 20 Chùa Bộc",
        "tuDien": "TL3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 6,
        "tuDien2": "TL3",
        "diaChiLapD": "",
        "longitude": 105.82663033919216,
        "latitude": 21.008717043271098,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7277,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82613144830063,
          21.008783396913227
        ]
      },
      "properties": {
        "tenDoiTuon": "TL3 28-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 60 Chùa Bộc",
        "tuDien": "TL3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "TL3",
        "diaChiLapD": "",
        "longitude": 105.82613144830063,
        "latitude": 21.008783396913227,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7278,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82574789241384,
          21.008977449846437
        ]
      },
      "properties": {
        "tenDoiTuon": "TL3 28-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 80 Chùa Bộc",
        "tuDien": "TL3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "TL3",
        "diaChiLapD": "",
        "longitude": 105.82574789241384,
        "latitude": 21.008977449846437,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7279,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82628701645744,
          21.00819873267764
        ]
      },
      "properties": {
        "tenDoiTuon": "26-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 203 Chùa Bộc",
        "tuDien": "TL3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "TL3",
        "diaChiLapD": "",
        "longitude": 105.82628701645744,
        "latitude": 21.00819873267764,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7280,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82602952438846,
          21.007815632385057
        ]
      },
      "properties": {
        "tenDoiTuon": "26-7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 203 Chùa Bộc",
        "tuDien": "TL3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "TL3",
        "diaChiLapD": "",
        "longitude": 105.82602952438846,
        "latitude": 21.007815632385057,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7281,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82587395626754,
          21.007507649084022
        ]
      },
      "properties": {
        "tenDoiTuon": "26-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 203 Chùa Bộc",
        "tuDien": "TL3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "TL3",
        "diaChiLapD": "",
        "longitude": 105.82587395626754,
        "latitude": 21.007507649084022,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7282,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8263027744347,
          21.007785272270528
        ]
      },
      "properties": {
        "tenDoiTuon": "26-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 203 Chùa Bộc",
        "tuDien": "TL3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "TL3",
        "diaChiLapD": "",
        "longitude": 105.8263027744347,
        "latitude": 21.007785272270528,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7283,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82601343113302,
          21.00812611897236
        ]
      },
      "properties": {
        "tenDoiTuon": "26-10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 203 Chùa Bộc",
        "tuDien": "TL3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "TL3",
        "diaChiLapD": "",
        "longitude": 105.82601343113302,
        "latitude": 21.00812611897236,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7284,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82589273173073,
          21.007870718762856
        ]
      },
      "properties": {
        "tenDoiTuon": "26-9",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 203 Chùa Bộc",
        "tuDien": "TL3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "TL3",
        "diaChiLapD": "",
        "longitude": 105.82589273173073,
        "latitude": 21.007870718762856,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7285,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82615391183313,
          21.00758370599453
        ]
      },
      "properties": {
        "tenDoiTuon": "26-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 203 Chùa Bộc",
        "tuDien": "TL3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "TL3",
        "diaChiLapD": "",
        "longitude": 105.82615391183313,
        "latitude": 21.00758370599453,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7286,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82570229489123,
          21.007582767021407
        ]
      },
      "properties": {
        "tenDoiTuon": "26-8",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 203 Chùa Bộc",
        "tuDien": "TL3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "TL3",
        "diaChiLapD": "",
        "longitude": 105.82570229489123,
        "latitude": 21.007582767021407,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7287,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82609926182568,
          21.007364924899353
        ]
      },
      "properties": {
        "tenDoiTuon": "26-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 203 Chùa Bộc",
        "tuDien": "TL3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "TL3",
        "diaChiLapD": "",
        "longitude": 105.82609926182568,
        "latitude": 21.007364924899353,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7288,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82577203232844,
          21.00841907749769
        ]
      },
      "properties": {
        "tenDoiTuon": "28-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 231 Chùa Bộc",
        "tuDien": "TL3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "TL3",
        "diaChiLapD": "",
        "longitude": 105.82577203232844,
        "latitude": 21.00841907749769,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7289,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82558159548894,
          21.007682924210464
        ]
      },
      "properties": {
        "tenDoiTuon": "28-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 231 Chùa Bộc",
        "tuDien": "TL3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "TL3",
        "diaChiLapD": "",
        "longitude": 105.82558159548894,
        "latitude": 21.007682924210464,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7290,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82594101149705,
          21.008341456063842
        ]
      },
      "properties": {
        "tenDoiTuon": "28-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 231 Chùa Bộc",
        "tuDien": "TL3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 6,
        "tuDien2": "TL3",
        "diaChiLapD": "",
        "longitude": 105.82594101149705,
        "latitude": 21.008341456063842,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7291,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.825375065394,
          21.007707963498287
        ]
      },
      "properties": {
        "tenDoiTuon": "17-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 231 Chùa Bộc",
        "tuDien": "TL3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "TL3",
        "diaChiLapD": "",
        "longitude": 105.825375065394,
        "latitude": 21.007707963498287,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7292,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82534019667538,
          21.007565239505265
        ]
      },
      "properties": {
        "tenDoiTuon": "17-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 231 Chùa Bộc",
        "tuDien": "TL3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "TL3",
        "diaChiLapD": "",
        "longitude": 105.82534019667538,
        "latitude": 21.007565239505265,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7293,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82545553166217,
          21.007855695206224
        ]
      },
      "properties": {
        "tenDoiTuon": "17-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 231 Chùa Bộc",
        "tuDien": "TL3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "TL3",
        "diaChiLapD": "",
        "longitude": 105.82545553166217,
        "latitude": 21.007855695206224,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7294,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8252677770304,
          21.00772298706814
        ]
      },
      "properties": {
        "tenDoiTuon": "17-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 231 Chùa Bộc",
        "tuDien": "TL3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "TL3",
        "diaChiLapD": "",
        "longitude": 105.8252677770304,
        "latitude": 21.00772298706814,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7295,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82558159548894,
          21.007682924210464
        ]
      },
      "properties": {
        "tenDoiTuon": "28-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 231 Chùa Bộc",
        "tuDien": "TL3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "TL3",
        "diaChiLapD": "",
        "longitude": 105.82558159548894,
        "latitude": 21.007682924210464,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7296,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8271469997392,
          21.009759605806323
        ]
      },
      "properties": {
        "tenDoiTuon": "CĐ2/6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 167 Tây Sơn",
        "tuDien": "CĐ2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "CĐ2",
        "diaChiLapD": "",
        "longitude": 105.8271469997392,
        "latitude": 21.009759605806323,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7297,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82740851511082,
          21.00954552270999
        ]
      },
      "properties": {
        "tenDoiTuon": "CĐ2/7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 167 Tây Sơn",
        "tuDien": "CĐ2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "CĐ2",
        "diaChiLapD": "",
        "longitude": 105.82740851511082,
        "latitude": 21.00954552270999,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7298,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82762711514958,
          21.009409060226037
        ]
      },
      "properties": {
        "tenDoiTuon": "CĐ2/15",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 167 Tây Sơn",
        "tuDien": "CĐ2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "CĐ2",
        "diaChiLapD": "",
        "longitude": 105.82762711514958,
        "latitude": 21.009409060226037,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7299,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82749032249187,
          21.00905851382037
        ]
      },
      "properties": {
        "tenDoiTuon": "CĐ2/16",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 167 Tây Sơn",
        "tuDien": "CĐ2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "CĐ2",
        "diaChiLapD": "",
        "longitude": 105.82749032249187,
        "latitude": 21.00905851382037,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7300,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82720600833646,
          21.009093568498123
        ]
      },
      "properties": {
        "tenDoiTuon": "CĐ2/8",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 167 Tây Sơn",
        "tuDien": "CĐ2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "CĐ2",
        "diaChiLapD": "",
        "longitude": 105.82720600833646,
        "latitude": 21.009093568498123,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7301,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82733475437098,
          21.00884568167296
        ]
      },
      "properties": {
        "tenDoiTuon": "CĐ2/17",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 167 Tây Sơn",
        "tuDien": "CĐ2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "CĐ2",
        "diaChiLapD": "",
        "longitude": 105.82733475437098,
        "latitude": 21.00884568167296,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7302,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82661055793928,
          21.0088406738539
        ]
      },
      "properties": {
        "tenDoiTuon": "CĐ2/10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 167 Tây Sơn",
        "tuDien": "CĐ2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "BTLT",
        "chieuCao": 6,
        "tuDien2": "CĐ2",
        "diaChiLapD": "",
        "longitude": 105.82661055793928,
        "latitude": 21.0088406738539,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7303,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82697936169245,
          21.008714226370632
        ]
      },
      "properties": {
        "tenDoiTuon": "CĐ2/9",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 167 Tây Sơn",
        "tuDien": "CĐ2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "BTLT",
        "chieuCao": 6,
        "tuDien2": "CĐ2",
        "diaChiLapD": "",
        "longitude": 105.82697936169245,
        "latitude": 21.008714226370632,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7304,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82518898716228,
          21.0095868370162
        ]
      },
      "properties": {
        "tenDoiTuon": "CĐ2/14",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 167 Tây Sơn",
        "tuDien": "CĐ2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 6,
        "tuDien2": "CĐ2",
        "diaChiLapD": "",
        "longitude": 105.82518898716228,
        "latitude": 21.0095868370162,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7305,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82545452585448,
          21.00946915381193
        ]
      },
      "properties": {
        "tenDoiTuon": "CĐ2/13",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 167 Tây Sơn",
        "tuDien": "CĐ2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 6,
        "tuDien2": "CĐ2",
        "diaChiLapD": "",
        "longitude": 105.82545452585448,
        "latitude": 21.00946915381193,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7306,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82571201791448,
          21.009338951009788
        ]
      },
      "properties": {
        "tenDoiTuon": "CĐ2/12",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 167 Tây Sơn",
        "tuDien": "CĐ2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 6,
        "tuDien2": "CĐ2",
        "diaChiLapD": "",
        "longitude": 105.82571201791448,
        "latitude": 21.009338951009788,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7307,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8260446118362,
          21.009158670019637
        ]
      },
      "properties": {
        "tenDoiTuon": "CĐ2/11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 167 Tây Sơn",
        "tuDien": "CĐ2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 6,
        "tuDien2": "CĐ2",
        "diaChiLapD": "",
        "longitude": 105.8260446118362,
        "latitude": 21.009158670019637,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7308,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82649254074471,
          21.010047553346375
        ]
      },
      "properties": {
        "tenDoiTuon": "CĐ2/5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 167 Tây Sơn",
        "tuDien": "CĐ2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "CĐ2",
        "diaChiLapD": "",
        "longitude": 105.82649254074471,
        "latitude": 21.010047553346375,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7309,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8266977297448,
          21.010419380434957
        ]
      },
      "properties": {
        "tenDoiTuon": "CĐ2/22",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 167 Tây Sơn",
        "tuDien": "CĐ2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "CĐ2",
        "diaChiLapD": "",
        "longitude": 105.8266977297448,
        "latitude": 21.010419380434957,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7310,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82633529624174,
          21.010114219382164
        ]
      },
      "properties": {
        "tenDoiTuon": "CĐ2/4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 167 Tây Sơn",
        "tuDien": "CĐ2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "CĐ2",
        "diaChiLapD": "",
        "longitude": 105.82633529624174,
        "latitude": 21.010114219382164,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7311,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82612910143396,
          21.010207802172474
        ]
      },
      "properties": {
        "tenDoiTuon": "CĐ2/3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 167 Tây Sơn",
        "tuDien": "CĐ2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "CĐ2",
        "diaChiLapD": "",
        "longitude": 105.82612910143396,
        "latitude": 21.010207802172474,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7312,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82584478726959,
          21.010334248389007
        ]
      },
      "properties": {
        "tenDoiTuon": "CĐ2/2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 167 Tây Sơn",
        "tuDien": "CĐ2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "CĐ2",
        "diaChiLapD": "",
        "longitude": 105.82584478726959,
        "latitude": 21.010334248389007,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7313,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82555376760382,
          21.01038057024346
        ]
      },
      "properties": {
        "tenDoiTuon": "CĐ2/1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 167 Tây Sơn",
        "tuDien": "CĐ2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "BTLT",
        "chieuCao": 6,
        "tuDien2": "CĐ2",
        "diaChiLapD": "",
        "longitude": 105.82555376760382,
        "latitude": 21.01038057024346,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7314,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82591318361193,
          21.010676028221805
        ]
      },
      "properties": {
        "tenDoiTuon": "CĐ2/18",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 167 Tây Sơn",
        "tuDien": "CĐ2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "BTLT",
        "chieuCao": 6,
        "tuDien2": "CĐ2",
        "diaChiLapD": "",
        "longitude": 105.82591318361193,
        "latitude": 21.010676028221805,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7315,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82620152107896,
          21.010502008551104
        ]
      },
      "properties": {
        "tenDoiTuon": "CĐ2/19",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 167 Tây Sơn",
        "tuDien": "CĐ2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 6,
        "tuDien2": "CĐ2",
        "diaChiLapD": "",
        "longitude": 105.82620152107896,
        "latitude": 21.010502008551104,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7316,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82641878001385,
          21.010395593545027
        ]
      },
      "properties": {
        "tenDoiTuon": "CĐ2/20",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 167 Tây Sơn",
        "tuDien": "CĐ2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "CĐ2",
        "diaChiLapD": "",
        "longitude": 105.82641878001385,
        "latitude": 21.010395593545027,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7317,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82704105250643,
          21.010368050824788
        ]
      },
      "properties": {
        "tenDoiTuon": "CĐ2/23",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 167 Tây Sơn",
        "tuDien": "CĐ2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "CĐ2",
        "diaChiLapD": "",
        "longitude": 105.82704105250643,
        "latitude": 21.010368050824788,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7318,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82686402670564,
          21.01077618333508
        ]
      },
      "properties": {
        "tenDoiTuon": "CĐ2/25",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 167 Tây Sơn",
        "tuDien": "CĐ2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "CĐ2",
        "diaChiLapD": "",
        "longitude": 105.82686402670564,
        "latitude": 21.01077618333508,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7319,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82688414327268,
          21.01061718706047
        ]
      },
      "properties": {
        "tenDoiTuon": "CĐ2/24",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 167 Tây Sơn",
        "tuDien": "CĐ2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "CĐ2",
        "diaChiLapD": "",
        "longitude": 105.82688414327268,
        "latitude": 21.01061718706047,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7320,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82658775918243,
          21.01021406188826
        ]
      },
      "properties": {
        "tenDoiTuon": "CĐ2/21",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 167 Tây Sơn",
        "tuDien": "CĐ2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "CĐ2",
        "diaChiLapD": "",
        "longitude": 105.82658775918243,
        "latitude": 21.01021406188826,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7321,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8271426411314,
          21.017056087653163
        ]
      },
      "properties": {
        "tenDoiTuon": "VH 15",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Vườn Hoa 1/6",
        "tuDien": "VH1/6",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 8,
        "tuDien2": "VH1/6",
        "diaChiLapD": "",
        "longitude": 105.8271426411314,
        "latitude": 21.017056087653163,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7322,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82766030746515,
          21.016812804315443
        ]
      },
      "properties": {
        "tenDoiTuon": "17",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Vườn Hoa 1/6",
        "tuDien": "VH1/6",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 8,
        "tuDien2": "VH1/6",
        "diaChiLapD": "",
        "longitude": 105.82766030746515,
        "latitude": 21.016812804315443,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7323,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82787354310042,
          21.016715574327748
        ]
      },
      "properties": {
        "tenDoiTuon": "18",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Vườn Hoa 1/6",
        "tuDien": "VH1/6",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 8,
        "tuDien2": "VH1/6",
        "diaChiLapD": "",
        "longitude": 105.82787354310042,
        "latitude": 21.016715574327748,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7324,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82761470990958,
          21.015868043304078
        ]
      },
      "properties": {
        "tenDoiTuon": "VH 22",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Vườn Hoa 1/6",
        "tuDien": "VH1/6",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 8,
        "tuDien2": "VH1/6",
        "diaChiLapD": "",
        "longitude": 105.82761470990958,
        "latitude": 21.015868043304078,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7325,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82773719745636,
          21.016090045987973
        ]
      },
      "properties": {
        "tenDoiTuon": "21",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Vườn Hoa 1/6",
        "tuDien": "VH1/6",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 8,
        "tuDien2": "VH1/6",
        "diaChiLapD": "",
        "longitude": 105.82773719745636,
        "latitude": 21.016090045987973,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7326,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82785968500313,
          21.01631204834139
        ]
      },
      "properties": {
        "tenDoiTuon": "20",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Vườn Hoa 1/6",
        "tuDien": "VH1/6",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 8,
        "tuDien2": "VH1/6",
        "diaChiLapD": "",
        "longitude": 105.82785968500313,
        "latitude": 21.01631204834139,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7327,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82798217254994,
          21.016534050364324
        ]
      },
      "properties": {
        "tenDoiTuon": "19",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Vườn Hoa 1/6",
        "tuDien": "VH1/6",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 8,
        "tuDien2": "VH1/6",
        "diaChiLapD": "",
        "longitude": 105.82798217254994,
        "latitude": 21.016534050364324,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7328,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83779664321777,
          20.999449152776254
        ]
      },
      "properties": {
        "tenDoiTuon": "NLBM 03",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 102 Trường Chinh",
        "tuDien": "NLBM",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "NLBM",
        "diaChiLapD": "",
        "longitude": 105.83779664321777,
        "latitude": 20.999449152776254,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7329,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83760122623443,
          20.999594318540975
        ]
      },
      "properties": {
        "tenDoiTuon": "NLBM 04",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 102 Trường Chinh",
        "tuDien": "NLBM",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "NLBM",
        "diaChiLapD": "",
        "longitude": 105.83760122623443,
        "latitude": 20.999594318540975,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7330,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83731089552325,
          21.01163657559055
        ]
      },
      "properties": {
        "tenDoiTuon": "01",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "La Thành",
        "tuDien": "HBTT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "Hợp Bộ Trung Tự",
        "diaChiLapD": "",
        "longitude": 105.83731089552325,
        "latitude": 21.01163657559055,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7331,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83717946726306,
          21.011776791828822
        ]
      },
      "properties": {
        "tenDoiTuon": "17",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "La Thành",
        "tuDien": "HBTT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "Hợp Bộ Trung Tự",
        "diaChiLapD": "",
        "longitude": 105.83717946726306,
        "latitude": 21.011776791828822,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7332,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83705876785177,
          21.011899480928335
        ]
      },
      "properties": {
        "tenDoiTuon": "18",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "La Thành",
        "tuDien": "HBTT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hợp Bộ Trung Tự",
        "diaChiLapD": "",
        "longitude": 105.83705876785177,
        "latitude": 21.011899480928335,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7333,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83679591138976,
          21.012157378094617
        ]
      },
      "properties": {
        "tenDoiTuon": "19",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "La Thành",
        "tuDien": "HBTT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hợp Bộ Trung Tự",
        "diaChiLapD": "",
        "longitude": 105.83679591138976,
        "latitude": 21.012157378094617,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7334,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83662425001191,
          21.012330978525224
        ]
      },
      "properties": {
        "tenDoiTuon": "20",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "La Thành",
        "tuDien": "HBTT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hợp Bộ Trung Tự",
        "diaChiLapD": "",
        "longitude": 105.83662425001191,
        "latitude": 21.012330978525224,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7335,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83645527084033,
          21.01252294030443
        ]
      },
      "properties": {
        "tenDoiTuon": "21",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "La Thành",
        "tuDien": "HBTT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hợp Bộ Trung Tự",
        "diaChiLapD": "",
        "longitude": 105.83645527084033,
        "latitude": 21.01252294030443,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7336,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8362970205117,
          21.01268819416058
        ]
      },
      "properties": {
        "tenDoiTuon": "22",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "La Thành",
        "tuDien": "HBTT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hợp Bộ Trung Tự",
        "diaChiLapD": "",
        "longitude": 105.8362970205117,
        "latitude": 21.01268819416058,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7337,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83707050313451,
          20.99956269986013
        ]
      },
      "properties": {
        "tenDoiTuon": "NLBM 10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Nghách 102/33 Trường Chinh",
        "tuDien": "NLBM",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "NLBM",
        "diaChiLapD": "",
        "longitude": 105.83707050313451,
        "latitude": 20.99956269986013,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7338,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83682084934802,
          20.999568521363216
        ]
      },
      "properties": {
        "tenDoiTuon": "NLBM 11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Nghách 102/33 Trường Chinh",
        "tuDien": "NLBM",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "NLBM",
        "diaChiLapD": "",
        "longitude": 105.83682084934802,
        "latitude": 20.999568521363216,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7339,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83657402099756,
          20.999577991510083
        ]
      },
      "properties": {
        "tenDoiTuon": "NLBM 12",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Nghách 102/33 Trường Chinh",
        "tuDien": "NLBM",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "NLBM",
        "diaChiLapD": "",
        "longitude": 105.83657402099756,
        "latitude": 20.999577991510083,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7340,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83626644297331,
          20.99957059997949
        ]
      },
      "properties": {
        "tenDoiTuon": "NLBM 13",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Nghách 102/33 Trường Chinh",
        "tuDien": "NLBM",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "NLBM",
        "diaChiLapD": "",
        "longitude": 105.83626644297331,
        "latitude": 20.99957059997949,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7341,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8360590631564,
          21.00004006186172
        ]
      },
      "properties": {
        "tenDoiTuon": "NLBM 14",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Nghách 102/33 Trường Chinh",
        "tuDien": "NLBM",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "NLBM",
        "diaChiLapD": "",
        "longitude": 105.8360590631564,
        "latitude": 21.00004006186172,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7342,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83593250795674,
          21.00017282640684
        ]
      },
      "properties": {
        "tenDoiTuon": "NLBM 15",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Nghách 102/33 Trường Chinh",
        "tuDien": "NLBM",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "NLBM",
        "diaChiLapD": "",
        "longitude": 105.83593250795674,
        "latitude": 21.00017282640684,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7343,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8374691458519,
          21.011371165921922
        ]
      },
      "properties": {
        "tenDoiTuon": "02",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Kim Hoa",
        "tuDien": "HBTT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "Hợp Bộ Trung Tự",
        "diaChiLapD": "",
        "longitude": 105.8374691458519,
        "latitude": 21.011371165921922,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7344,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83772127349641,
          21.01105567834434
        ]
      },
      "properties": {
        "tenDoiTuon": "03",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Kim Hoa",
        "tuDien": "HBTT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "BTLT",
        "chieuCao": 8,
        "tuDien2": "Hợp Bộ Trung Tự",
        "diaChiLapD": "",
        "longitude": 105.83772127349641,
        "latitude": 21.01105567834434,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7345,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83790668120766,
          21.01085755931639
        ]
      },
      "properties": {
        "tenDoiTuon": "04",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Kim Hoa",
        "tuDien": "HBTT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Hợp Bộ Trung Tự",
        "diaChiLapD": "",
        "longitude": 105.83790668120766,
        "latitude": 21.01085755931639,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7346,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83803006281768,
          21.010970233677003
        ]
      },
      "properties": {
        "tenDoiTuon": "4-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 150 Kim Hoa",
        "tuDien": "HBTT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hợp Bộ Trung Tự",
        "diaChiLapD": "",
        "longitude": 105.83803006281768,
        "latitude": 21.010970233677003,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7347,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83828487267894,
          21.011175551182983
        ]
      },
      "properties": {
        "tenDoiTuon": "4-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 150 Kim Hoa",
        "tuDien": "HBTT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hợp Bộ Trung Tự",
        "diaChiLapD": "",
        "longitude": 105.83828487267894,
        "latitude": 21.011175551182983,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7348,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8383707033626,
          21.01105786923282
        ]
      },
      "properties": {
        "tenDoiTuon": "4-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 150 Kim Hoa",
        "tuDien": "HBTT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hợp Bộ Trung Tự",
        "diaChiLapD": "",
        "longitude": 105.8383707033626,
        "latitude": 21.01105786923282,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7349,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83848067393393,
          21.0110779002094
        ]
      },
      "properties": {
        "tenDoiTuon": "4-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 150 Kim Hoa",
        "tuDien": "HBTT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hợp Bộ Trung Tự",
        "diaChiLapD": "",
        "longitude": 105.83848067393393,
        "latitude": 21.0110779002094,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7350,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8378691302813,
          21.010682287917934
        ]
      },
      "properties": {
        "tenDoiTuon": "05",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Kim Hoa",
        "tuDien": "HBTT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Hợp Bộ Trung Tự",
        "diaChiLapD": "",
        "longitude": 105.8378691302813,
        "latitude": 21.010682287917934,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7351,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83774038424681,
          21.01078745078173
        ]
      },
      "properties": {
        "tenDoiTuon": "5-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Kim Hoa",
        "tuDien": "HBTT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hợp Bộ Trung Tự",
        "diaChiLapD": "",
        "longitude": 105.83774038424681,
        "latitude": 21.01078745078173,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7352,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83777525296541,
          21.010479473615565
        ]
      },
      "properties": {
        "tenDoiTuon": "5-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Kim Hoa",
        "tuDien": "HBTT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "Hợp Bộ Trung Tự",
        "diaChiLapD": "",
        "longitude": 105.83777525296541,
        "latitude": 21.010479473615565,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7353,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83794154991728,
          21.010582132741597
        ]
      },
      "properties": {
        "tenDoiTuon": "5-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - đèn chùm",
        "tenDuongPh": "Kim Hoa",
        "tuDien": "HBTT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Sân vườn",
        "chieuCao": 6,
        "tuDien2": "Hợp Bộ Trung Tự",
        "diaChiLapD": "",
        "longitude": 105.83794154991728,
        "latitude": 21.010582132741597,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7354,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83808907141496,
          21.0106672646461
        ]
      },
      "properties": {
        "tenDoiTuon": "5-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - đèn chùm",
        "tenDuongPh": "Kim Hoa",
        "tuDien": "HBTT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Sân vườn",
        "chieuCao": 6,
        "tuDien2": "Hợp Bộ Trung Tự",
        "diaChiLapD": "",
        "longitude": 105.83808907141496,
        "latitude": 21.0106672646461,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7355,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8380112873545,
          21.010479473615565
        ]
      },
      "properties": {
        "tenDoiTuon": "5-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - đèn chùm",
        "tenDuongPh": "Kim Hoa",
        "tuDien": "HBTT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Sân vườn",
        "chieuCao": 6,
        "tuDien2": "Hợp Bộ Trung Tự",
        "diaChiLapD": "",
        "longitude": 105.8380112873545,
        "latitude": 21.010479473615565,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7356,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83818294873083,
          21.010552086175423
        ]
      },
      "properties": {
        "tenDoiTuon": "5-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - đèn chùm",
        "tenDuongPh": "Kim Hoa",
        "tuDien": "HBTT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Sân vườn",
        "chieuCao": 6,
        "tuDien2": "Hợp Bộ Trung Tự",
        "diaChiLapD": "",
        "longitude": 105.83818294873083,
        "latitude": 21.010552086175423,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7357,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83786108364909,
          21.01038182218553
        ]
      },
      "properties": {
        "tenDoiTuon": "5-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Kim Hoa",
        "tuDien": "HBTT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "Hợp Bộ Trung Tự",
        "diaChiLapD": "",
        "longitude": 105.83786108364909,
        "latitude": 21.01038182218553,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7358,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8381453978045,
          21.010814993423676
        ]
      },
      "properties": {
        "tenDoiTuon": "HBTT 5.7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 150 Kim Hoa",
        "tuDien": "HBTT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hợp Bộ Trung Tự",
        "diaChiLapD": "",
        "longitude": 105.8381453978045,
        "latitude": 21.010814993423676,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7359,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8386603819335,
          21.011258178880276
        ]
      },
      "properties": {
        "tenDoiTuon": "4-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 150 Kim Hoa",
        "tuDien": "HBTT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hợp Bộ Trung Tự",
        "diaChiLapD": "",
        "longitude": 105.8386603819335,
        "latitude": 21.011258178880276,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7360,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83884277214977,
          21.01140841093957
        ]
      },
      "properties": {
        "tenDoiTuon": "4-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 150 Kim Hoa",
        "tuDien": "HBTT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hợp Bộ Trung Tự",
        "diaChiLapD": "",
        "longitude": 105.83884277214977,
        "latitude": 21.01140841093957,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7361,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83900638690288,
          21.01118556666413
        ]
      },
      "properties": {
        "tenDoiTuon": "4-7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 150 Kim Hoa",
        "tuDien": "HBTT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hợp Bộ Trung Tự",
        "diaChiLapD": "",
        "longitude": 105.83900638690288,
        "latitude": 21.01118556666413,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7362,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83886154761295,
          21.011586185347944
        ]
      },
      "properties": {
        "tenDoiTuon": "4-8",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 150 Kim Hoa",
        "tuDien": "HBTT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hợp Bộ Trung Tự",
        "diaChiLapD": "",
        "longitude": 105.83886154761295,
        "latitude": 21.011586185347944,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7363,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83870866169981,
          21.011688843712356
        ]
      },
      "properties": {
        "tenDoiTuon": "4-9",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 150 Kim Hoa",
        "tuDien": "HBTT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hợp Bộ Trung Tự",
        "diaChiLapD": "",
        "longitude": 105.83870866169981,
        "latitude": 21.011688843712356,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7364,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83908417096335,
          21.01148853464321
        ]
      },
      "properties": {
        "tenDoiTuon": "4-10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 150 Kim Hoa",
        "tuDien": "HBTT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hợp Bộ Trung Tự",
        "diaChiLapD": "",
        "longitude": 105.83908417096335,
        "latitude": 21.01148853464321,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7365,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83925315013194,
          21.011583681484034
        ]
      },
      "properties": {
        "tenDoiTuon": "4-11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 150 Kim Hoa",
        "tuDien": "HBTT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hợp Bộ Trung Tự",
        "diaChiLapD": "",
        "longitude": 105.83925315013194,
        "latitude": 21.011583681484034,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7366,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83937384953423,
          21.011398395474217
        ]
      },
      "properties": {
        "tenDoiTuon": "4-12",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 150 Kim Hoa",
        "tuDien": "HBTT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hợp Bộ Trung Tự",
        "diaChiLapD": "",
        "longitude": 105.83937384953423,
        "latitude": 21.011398395474217,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7367,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83927997221835,
          21.011255675011693
        ]
      },
      "properties": {
        "tenDoiTuon": "4-13",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 150 Kim Hoa",
        "tuDien": "HBTT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hợp Bộ Trung Tự",
        "diaChiLapD": "",
        "longitude": 105.83927997221835,
        "latitude": 21.011255675011693,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7368,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83883472552655,
          21.01178649428599
        ]
      },
      "properties": {
        "tenDoiTuon": "4-9-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 150 Kim Hoa",
        "tuDien": "HBTT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hợp Bộ Trung Tự",
        "diaChiLapD": "",
        "longitude": 105.83883472552655,
        "latitude": 21.01178649428599,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7369,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83864428868704,
          21.011092923440742
        ]
      },
      "properties": {
        "tenDoiTuon": "4-4-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 150 Kim Hoa",
        "tuDien": "HBTT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hợp Bộ Trung Tự",
        "diaChiLapD": "",
        "longitude": 105.83864428868704,
        "latitude": 21.011092923440742,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7370,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83812394013357,
          21.011460992124785
        ]
      },
      "properties": {
        "tenDoiTuon": "2-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 178 Kim Hoa",
        "tuDien": "HBTT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hợp Bộ Trung Tự",
        "diaChiLapD": "",
        "longitude": 105.83812394013357,
        "latitude": 21.011460992124785,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7371,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83797910084364,
          21.01134080653182
        ]
      },
      "properties": {
        "tenDoiTuon": "2-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 178 Kim Hoa",
        "tuDien": "HBTT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hợp Bộ Trung Tự",
        "diaChiLapD": "",
        "longitude": 105.83797910084364,
        "latitude": 21.01134080653182,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7372,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83835461010717,
          21.01160120852724
        ]
      },
      "properties": {
        "tenDoiTuon": "2-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 178 Kim Hoa",
        "tuDien": "HBTT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hợp Bộ Trung Tự",
        "diaChiLapD": "",
        "longitude": 105.83835461010717,
        "latitude": 21.01160120852724,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7373,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82920626571678,
          21.025670377921593
        ]
      },
      "properties": {
        "tenDoiTuon": "50-8",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Quan Thổ 1",
        "tuDien": "DDHN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Di Dân Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82920626571678,
        "latitude": 21.025670377921593,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7374,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82915156359454,
          21.02547208776117
        ]
      },
      "properties": {
        "tenDoiTuon": "50-7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Quan Thổ 1",
        "tuDien": "DDHN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Di Dân Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82915156359454,
        "latitude": 21.02547208776117,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7375,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82954657096552,
          21.02564440278928
        ]
      },
      "properties": {
        "tenDoiTuon": "50-9",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 184,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Quan Thổ 1",
        "tuDien": "DDHN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Di Dân Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82954657096552,
        "latitude": 21.02564440278928,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7376,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82936954516472,
          21.0253602408133
        ]
      },
      "properties": {
        "tenDoiTuon": "50-10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Quan Thổ 1",
        "tuDien": "DDHN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Di Dân Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82936954516472,
        "latitude": 21.0253602408133,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7377,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82928812879965,
          21.02506810080481
        ]
      },
      "properties": {
        "tenDoiTuon": "50-11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Quan Thổ 1",
        "tuDien": "DDHN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Di Dân Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82928812879965,
        "latitude": 21.02506810080481,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7378,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82895380276707,
          21.025037271917576
        ]
      },
      "properties": {
        "tenDoiTuon": "50-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Quan Thổ 1",
        "tuDien": "DDHN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Di Dân Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82895380276707,
        "latitude": 21.025037271917576,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7379,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82894173283223,
          21.024625422969876
        ]
      },
      "properties": {
        "tenDoiTuon": "50-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Quan Thổ 1",
        "tuDien": "DDHN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Di Dân Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82894173283223,
        "latitude": 21.024625422969876,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7380,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82901415246822,
          21.02449523339987
        ]
      },
      "properties": {
        "tenDoiTuon": "50-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Quan Thổ 1",
        "tuDien": "DDHN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Di Dân Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82901415246822,
        "latitude": 21.02449523339987,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7381,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82915362734268,
          21.02398949591584
        ]
      },
      "properties": {
        "tenDoiTuon": "50-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Quan Thổ 1",
        "tuDien": "DDHN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Di Dân Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82915362734268,
        "latitude": 21.02398949591584,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7382,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82921263593994,
          21.02372035274357
        ]
      },
      "properties": {
        "tenDoiTuon": "50-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Quan Thổ 1",
        "tuDien": "DDHN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Di Dân Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82921263593994,
        "latitude": 21.02372035274357,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7383,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82912814635115,
          21.02313324339215
        ]
      },
      "properties": {
        "tenDoiTuon": "50",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Quan Thổ 1",
        "tuDien": "DDHN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Di Dân Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82912814635115,
        "latitude": 21.02313324339215,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7384,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82907718438611,
          21.022951727240677
        ]
      },
      "properties": {
        "tenDoiTuon": "DDHN 50-27",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Quan Thổ 1",
        "tuDien": "DDHN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Di Dân Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82907718438611,
        "latitude": 21.022951727240677,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7385,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82919386047676,
          21.023252167648398
        ]
      },
      "properties": {
        "tenDoiTuon": "50-28",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Quan Thổ 1",
        "tuDien": "DDHN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Di Dân Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82919386047676,
        "latitude": 21.023252167648398,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7386,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82926359790501,
          21.0234850085473
        ]
      },
      "properties": {
        "tenDoiTuon": "50-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Quan Thổ 1",
        "tuDien": "DDHN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Di Dân Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82926359790501,
        "latitude": 21.0234850085473,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7387,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82672823983764,
          21.00711327929536
        ]
      },
      "properties": {
        "tenDoiTuon": "17-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 231 Chùa Bộc",
        "tuDien": "TL3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "TL3",
        "diaChiLapD": "",
        "longitude": 105.82672823983764,
        "latitude": 21.00711327929536,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7388,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82683552820122,
          21.007222200578994
        ]
      },
      "properties": {
        "tenDoiTuon": "12-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 165 Chùa Bộc",
        "tuDien": "TL3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "TL3",
        "diaChiLapD": "",
        "longitude": 105.82683552820122,
        "latitude": 21.007222200578994,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7389,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82695488649964,
          21.007445050774656
        ]
      },
      "properties": {
        "tenDoiTuon": "12-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 165 Chùa Bộc",
        "tuDien": "TL3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "TL3",
        "diaChiLapD": "",
        "longitude": 105.82695488649964,
        "latitude": 21.007445050774656,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7390,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82671616989381,
          21.007550215920375
        ]
      },
      "properties": {
        "tenDoiTuon": "12-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 165 Chùa Bộc",
        "tuDien": "TL3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "TL3",
        "diaChiLapD": "",
        "longitude": 105.82671616989381,
        "latitude": 21.007550215920375,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7391,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82699109632212,
          21.00760655436067
        ]
      },
      "properties": {
        "tenDoiTuon": "12-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 165 Chùa Bộc",
        "tuDien": "TL3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "TL3",
        "diaChiLapD": "",
        "longitude": 105.82699109632212,
        "latitude": 21.00760655436067,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7392,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82715202885849,
          21.007770561698173
        ]
      },
      "properties": {
        "tenDoiTuon": "12-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 165 Chùa Bộc",
        "tuDien": "TL3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "TL3",
        "diaChiLapD": "",
        "longitude": 105.82715202885849,
        "latitude": 21.007770561698173,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7393,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82707022148644,
          21.007818136312213
        ]
      },
      "properties": {
        "tenDoiTuon": "12-7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 165 Chùa Bộc",
        "tuDien": "TL3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "TL3",
        "diaChiLapD": "",
        "longitude": 105.82707022148644,
        "latitude": 21.007818136312213,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7394,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82741622644684,
          21.007705459570968
        ]
      },
      "properties": {
        "tenDoiTuon": "12-8",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 165 Chùa Bộc",
        "tuDien": "TL3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "TL3",
        "diaChiLapD": "",
        "longitude": 105.82741622644684,
        "latitude": 21.007705459570968,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7395,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82766030746815,
          21.00759153077829
        ]
      },
      "properties": {
        "tenDoiTuon": "TL3 12-9",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 165 Chùa Bộc",
        "tuDien": "TL3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "TL3",
        "diaChiLapD": "",
        "longitude": 105.82766030746815,
        "latitude": 21.00759153077829,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7396,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82672823983764,
          21.00711327929536
        ]
      },
      "properties": {
        "tenDoiTuon": "17-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 231 Chùa Bộc",
        "tuDien": "TL3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "TL3",
        "diaChiLapD": "",
        "longitude": 105.82672823983764,
        "latitude": 21.00711327929536,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7397,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82672823983764,
          21.00711327929536
        ]
      },
      "properties": {
        "tenDoiTuon": "12-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 165 Chùa Bộc",
        "tuDien": "TL3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "TL3",
        "diaChiLapD": "",
        "longitude": 105.82672823983764,
        "latitude": 21.00711327929536,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7398,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83765824157857,
          21.011435014518
        ]
      },
      "properties": {
        "tenDoiTuon": "2-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Đình Kim Liên",
        "tuDien": "HBTT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hợp Bộ Trung Tự",
        "diaChiLapD": "",
        "longitude": 105.83765824157857,
        "latitude": 21.011435014518,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7399,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83778061738089,
          21.01146349598992
        ]
      },
      "properties": {
        "tenDoiTuon": "2-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Đình Kim Liên",
        "tuDien": "HBTT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hợp Bộ Trung Tự",
        "diaChiLapD": "",
        "longitude": 105.83778061738089,
        "latitude": 21.01146349598992,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7400,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83743729462823,
          21.011778982705852
        ]
      },
      "properties": {
        "tenDoiTuon": "07",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Đình Kim Liên",
        "tuDien": "HBTT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 6,
        "tuDien2": "Hợp Bộ Trung Tự",
        "diaChiLapD": "",
        "longitude": 105.83743729462823,
        "latitude": 21.011778982705852,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7401,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83761432042004,
          21.01179901358563
        ]
      },
      "properties": {
        "tenDoiTuon": "7-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Đình Kim Liên",
        "tuDien": "HBTT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hợp Bộ Trung Tự",
        "diaChiLapD": "",
        "longitude": 105.83761432042004,
        "latitude": 21.01179901358563,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7402,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8377001511127,
          21.011653789644505
        ]
      },
      "properties": {
        "tenDoiTuon": "7-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Đình Kim Liên",
        "tuDien": "HBTT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hợp Bộ Trung Tự",
        "diaChiLapD": "",
        "longitude": 105.8377001511127,
        "latitude": 21.011653789644505,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7403,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83788254132,
          21.011768967264548
        ]
      },
      "properties": {
        "tenDoiTuon": "7-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Đình Kim Liên",
        "tuDien": "HBTT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hợp Bộ Trung Tự",
        "diaChiLapD": "",
        "longitude": 105.83788254132,
        "latitude": 21.011768967264548,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7404,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8375365363596,
          21.012169584381578
        ]
      },
      "properties": {
        "tenDoiTuon": "7-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Đình Kim Liên",
        "tuDien": "HBTT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hợp Bộ Trung Tự",
        "diaChiLapD": "",
        "longitude": 105.8375365363596,
        "latitude": 21.012169584381578,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7405,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83757408728596,
          21.01186912164488
        ]
      },
      "properties": {
        "tenDoiTuon": "7+1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Đình Kim Liên",
        "tuDien": "HBTT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hợp Bộ Trung Tự",
        "diaChiLapD": "",
        "longitude": 105.83757408728596,
        "latitude": 21.01186912164488,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7406,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83743193020376,
          21.01236738868626
        ]
      },
      "properties": {
        "tenDoiTuon": "7-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Đình Kim Liên",
        "tuDien": "HBTT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hợp Bộ Trung Tự",
        "diaChiLapD": "",
        "longitude": 105.83743193020376,
        "latitude": 21.01236738868626,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7407,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83780743946728,
          21.012046895504206
        ]
      },
      "properties": {
        "tenDoiTuon": "8",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Đình Kim Liên",
        "tuDien": "HBTT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hợp Bộ Trung Tự",
        "diaChiLapD": "",
        "longitude": 105.83780743946728,
        "latitude": 21.012046895504206,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7408,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83785035481812,
          21.01217459208889
        ]
      },
      "properties": {
        "tenDoiTuon": "8-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Đình Kim Liên",
        "tuDien": "HBTT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 6,
        "tuDien2": "Hợp Bộ Trung Tự",
        "diaChiLapD": "",
        "longitude": 105.83785035481812,
        "latitude": 21.01217459208889,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7409,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83811857571807,
          21.012279753899772
        ]
      },
      "properties": {
        "tenDoiTuon": "9",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Đình Kim Liên",
        "tuDien": "HBTT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 6,
        "tuDien2": "Hợp Bộ Trung Tự",
        "diaChiLapD": "",
        "longitude": 105.83811857571807,
        "latitude": 21.012279753899772,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7410,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83804883828084,
          21.012009337663724
        ]
      },
      "properties": {
        "tenDoiTuon": "8-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Đình Kim Liên",
        "tuDien": "HBTT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 6,
        "tuDien2": "Hợp Bộ Trung Tự",
        "diaChiLapD": "",
        "longitude": 105.83804883828084,
        "latitude": 21.012009337663724,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7411,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83816953768314,
          21.01179901358563
        ]
      },
      "properties": {
        "tenDoiTuon": "8-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Đình Kim Liên",
        "tuDien": "HBTT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 6,
        "tuDien2": "Hợp Bộ Trung Tự",
        "diaChiLapD": "",
        "longitude": 105.83816953768314,
        "latitude": 21.01179901358563,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7412,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83831169476537,
          21.011886648654553
        ]
      },
      "properties": {
        "tenDoiTuon": "8-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Đình Kim Liên",
        "tuDien": "HBTT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 6,
        "tuDien2": "Hợp Bộ Trung Tự",
        "diaChiLapD": "",
        "longitude": 105.83831169476537,
        "latitude": 21.011886648654553,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7413,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82928505557592,
          21.023177057602805
        ]
      },
      "properties": {
        "tenDoiTuon": "50-26",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 130 Thịnh Hào 1",
        "tuDien": "DDHN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Di Dân Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82928505557592,
        "latitude": 21.023177057602805,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7414,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8294862212554,
          21.023105703024328
        ]
      },
      "properties": {
        "tenDoiTuon": "DDHN 50-27",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 130 Thịnh Hào 1",
        "tuDien": "DDHN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "Di Dân Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.8294862212554,
        "latitude": 21.023105703024328,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7415,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82937088626859,
          21.0232484121463
        ]
      },
      "properties": {
        "tenDoiTuon": "50-24",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "TT X81",
        "tuDien": "DDHN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 7,
        "tuDien2": "Di Dân Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82937088626859,
        "latitude": 21.0232484121463,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7416,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82962301391312,
          21.02333353682042
        ]
      },
      "properties": {
        "tenDoiTuon": "DDHN 50-25",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "TT X81",
        "tuDien": "DDHN",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 7,
        "tuDien2": "Di Dân Hào Nam",
        "diaChiLapD": "",
        "longitude": 105.82962301391312,
        "latitude": 21.02333353682042,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7417,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.79979644701274,
          21.025875988137987
        ]
      },
      "properties": {
        "tenDoiTuon": "VH1 01",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Chùa Nên + Ngõ 1194 Đường Láng",
        "tuDien": "VHA1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VH1",
        "diaChiLapD": "",
        "longitude": 105.79979644701274,
        "latitude": 21.025875988137987,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7418,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8000215990315,
          21.025818012663017
        ]
      },
      "properties": {
        "tenDoiTuon": "VH1 02",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "",
        "tuDien": "VHA1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VH1",
        "diaChiLapD": "",
        "longitude": 105.8000215990315,
        "latitude": 21.025818012663017,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7419,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80000565931542,
          21.025638143718673
        ]
      },
      "properties": {
        "tenDoiTuon": "VH1 2-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Nghách 4+ 41 Chùa Nền",
        "tuDien": "VHA1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VH1",
        "diaChiLapD": "",
        "longitude": 105.80000565931542,
        "latitude": 21.025638143718673,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7420,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80031776602992,
          21.025805999892633
        ]
      },
      "properties": {
        "tenDoiTuon": "VH1 03",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "",
        "tuDien": "VHA1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 7,
        "tuDien2": "VH1",
        "diaChiLapD": "",
        "longitude": 105.80031776602992,
        "latitude": 21.025805999892633,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7421,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80029802009403,
          21.025901024370494
        ]
      },
      "properties": {
        "tenDoiTuon": "VH1 3-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Nghách 4+ 41 Chùa Nền",
        "tuDien": "VHA1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "VH1",
        "diaChiLapD": "",
        "longitude": 105.80029802009403,
        "latitude": 21.025901024370494,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7422,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80039994404216,
          21.025931067844112
        ]
      },
      "properties": {
        "tenDoiTuon": "VH1 3-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Nghách 4+ 41 Chùa Nền",
        "tuDien": "VHA1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VH1",
        "diaChiLapD": "",
        "longitude": 105.80039994404216,
        "latitude": 21.025931067844112,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7423,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83912440409745,
          21.012287265455505
        ]
      },
      "properties": {
        "tenDoiTuon": "10-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Đình Kim Liên",
        "tuDien": "HBTT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hợp Bộ Trung Tự",
        "diaChiLapD": "",
        "longitude": 105.83912440409745,
        "latitude": 21.012287265455505,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7424,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83769445139205,
          21.012868470886154
        ]
      },
      "properties": {
        "tenDoiTuon": "15-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Đình Kim Liên",
        "tuDien": "HBTT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hợp Bộ Trung Tự",
        "diaChiLapD": "",
        "longitude": 105.83769445139205,
        "latitude": 21.012868470886154,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7425,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8373377175917,
          21.013131374052676
        ]
      },
      "properties": {
        "tenDoiTuon": "16",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Đình Kim Liên",
        "tuDien": "HBTT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hợp Bộ Trung Tự",
        "diaChiLapD": "",
        "longitude": 105.8373377175917,
        "latitude": 21.013131374052676,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7426,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83752547222346,
          21.012911036192065
        ]
      },
      "properties": {
        "tenDoiTuon": "15",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Đình Kim Liên",
        "tuDien": "HBTT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hợp Bộ Trung Tự",
        "diaChiLapD": "",
        "longitude": 105.83752547222346,
        "latitude": 21.012911036192065,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7427,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83768908696759,
          21.012803370982766
        ]
      },
      "properties": {
        "tenDoiTuon": "15-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Đình Kim Liên",
        "tuDien": "HBTT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hợp Bộ Trung Tự",
        "diaChiLapD": "",
        "longitude": 105.83768908696759,
        "latitude": 21.012803370982766,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7428,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83725758661967,
          21.01255768118773
        ]
      },
      "properties": {
        "tenDoiTuon": "14-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Đình Kim Liên",
        "tuDien": "HBTT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hợp Bộ Trung Tự",
        "diaChiLapD": "",
        "longitude": 105.83725758661967,
        "latitude": 21.01255768118773,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7429,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8373273240569,
          21.012667850419295
        ]
      },
      "properties": {
        "tenDoiTuon": "14-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Đình Kim Liên",
        "tuDien": "HBTT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hợp Bộ Trung Tự",
        "diaChiLapD": "",
        "longitude": 105.8373273240569,
        "latitude": 21.012667850419295,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7430,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83753117194411,
          21.012535146561895
        ]
      },
      "properties": {
        "tenDoiTuon": "14-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Đình Kim Liên",
        "tuDien": "HBTT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hợp Bộ Trung Tự",
        "diaChiLapD": "",
        "longitude": 105.83753117194411,
        "latitude": 21.012535146561895,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7431,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83763544279478,
          21.01260431533367
        ]
      },
      "properties": {
        "tenDoiTuon": "14-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Đình Kim Liên",
        "tuDien": "HBTT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hợp Bộ Trung Tự",
        "diaChiLapD": "",
        "longitude": 105.83763544279478,
        "latitude": 21.01260431533367,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7432,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83783962597816,
          21.01255768118773
        ]
      },
      "properties": {
        "tenDoiTuon": "14",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Đình Kim Liên",
        "tuDien": "HBTT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hợp Bộ Trung Tự",
        "diaChiLapD": "",
        "longitude": 105.83783962597816,
        "latitude": 21.01255768118773,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7433,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8384243475444,
          21.012562688881154
        ]
      },
      "properties": {
        "tenDoiTuon": "11-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Đình Kim Liên",
        "tuDien": "HBTT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hợp Bộ Trung Tự",
        "diaChiLapD": "",
        "longitude": 105.8384243475444,
        "latitude": 21.012562688881154,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7434,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83838947882579,
          21.0126453158101
        ]
      },
      "properties": {
        "tenDoiTuon": "11-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Đình Kim Liên",
        "tuDien": "HBTT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hợp Bộ Trung Tự",
        "diaChiLapD": "",
        "longitude": 105.83838947882579,
        "latitude": 21.0126453158101,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7435,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83796300759718,
          21.012480061907283
        ]
      },
      "properties": {
        "tenDoiTuon": "13",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Đình Kim Liên",
        "tuDien": "HBTT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hợp Bộ Trung Tự",
        "diaChiLapD": "",
        "longitude": 105.83796300759718,
        "latitude": 21.012480061907283,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7436,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83885618319746,
          21.012279753899772
        ]
      },
      "properties": {
        "tenDoiTuon": "10-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Đình Kim Liên",
        "tuDien": "HBTT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hợp Bộ Trung Tự",
        "diaChiLapD": "",
        "longitude": 105.83885618319746,
        "latitude": 21.012279753899772,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7437,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8382070886095,
          21.012690385025078
        ]
      },
      "properties": {
        "tenDoiTuon": "12",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Đình Kim Liên",
        "tuDien": "HBTT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hợp Bộ Trung Tự",
        "diaChiLapD": "",
        "longitude": 105.8382070886095,
        "latitude": 21.012690385025078,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7438,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83873280157846,
          21.012227173003986
        ]
      },
      "properties": {
        "tenDoiTuon": "10-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Đình Kim Liên",
        "tuDien": "HBTT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hợp Bộ Trung Tự",
        "diaChiLapD": "",
        "longitude": 105.83873280157846,
        "latitude": 21.012227173003986,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7439,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83831169476537,
          21.012497588845164
        ]
      },
      "properties": {
        "tenDoiTuon": "11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Đình Kim Liên",
        "tuDien": "HBTT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hợp Bộ Trung Tự",
        "diaChiLapD": "",
        "longitude": 105.83831169476537,
        "latitude": 21.012497588845164,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7440,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83849408498162,
          21.012046895504206
        ]
      },
      "properties": {
        "tenDoiTuon": "10-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Đình Kim Liên",
        "tuDien": "HBTT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hợp Bộ Trung Tự",
        "diaChiLapD": "",
        "longitude": 105.83849408498162,
        "latitude": 21.012046895504206,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7441,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83860137333623,
          21.012157065113023
        ]
      },
      "properties": {
        "tenDoiTuon": "10-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Đình Kim Liên",
        "tuDien": "HBTT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hợp Bộ Trung Tự",
        "diaChiLapD": "",
        "longitude": 105.83860137333623,
        "latitude": 21.012157065113023,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7442,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83841898312893,
          21.01230228856419
        ]
      },
      "properties": {
        "tenDoiTuon": "10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Đình Kim Liên",
        "tuDien": "HBTT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hợp Bộ Trung Tự",
        "diaChiLapD": "",
        "longitude": 105.83841898312893,
        "latitude": 21.01230228856419,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7443,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80044264117423,
          21.026243612112793
        ]
      },
      "properties": {
        "tenDoiTuon": "VH1 3-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Nghách 4+ 41 Chùa Nền",
        "tuDien": "VHA1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VH1",
        "diaChiLapD": "",
        "longitude": 105.80044264117423,
        "latitude": 21.026243612112793,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7444,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82561629655495,
          21.008518138663298
        ]
      },
      "properties": {
        "tenDoiTuon": "TL3 30-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 257 Chùa Bộc",
        "tuDien": "TL3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "TL3",
        "diaChiLapD": "",
        "longitude": 105.82561629655495,
        "latitude": 21.008518138663298,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7445,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82555594685381,
          21.008382927197626
        ]
      },
      "properties": {
        "tenDoiTuon": "TL3 30-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 257 Chùa Bộc",
        "tuDien": "TL3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "TL3",
        "diaChiLapD": "",
        "longitude": 105.82555594685381,
        "latitude": 21.008382927197626,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7446,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8379412146211,
          21.012956105325927
        ]
      },
      "properties": {
        "tenDoiTuon": "14-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Đình Kim Liên",
        "tuDien": "HBTT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hợp Bộ Trung Tự",
        "diaChiLapD": "",
        "longitude": 105.8379412146211,
        "latitude": 21.012956105325927,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7447,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8378473373052,
          21.01279085176742
        ]
      },
      "properties": {
        "tenDoiTuon": "14-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Đình Kim Liên",
        "tuDien": "HBTT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hợp Bộ Trung Tự",
        "diaChiLapD": "",
        "longitude": 105.8378473373052,
        "latitude": 21.01279085176742,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7448,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82884316163933,
          21.007014373690012
        ]
      },
      "properties": {
        "tenDoiTuon": "TL3 7-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 165 Chùa Bộc",
        "tuDien": "TL3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "TL3",
        "diaChiLapD": "",
        "longitude": 105.82884316163933,
        "latitude": 21.007014373690012,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7449,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82917039113656,
          21.006857877349578
        ]
      },
      "properties": {
        "tenDoiTuon": "7-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 95 Chùa Bộc",
        "tuDien": "TL3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "TL3",
        "diaChiLapD": "",
        "longitude": 105.82917039113656,
        "latitude": 21.006857877349578,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7450,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82904298621493,
          21.00654864009734
        ]
      },
      "properties": {
        "tenDoiTuon": "TL3 7-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 95 Chùa Bộc",
        "tuDien": "TL3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "TL3",
        "diaChiLapD": "",
        "longitude": 105.82904298621493,
        "latitude": 21.00654864009734,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7451,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83716605621537,
          21.011977100510894
        ]
      },
      "properties": {
        "tenDoiTuon": "18-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 188 Kim Hoa",
        "tuDien": "HBTT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hợp Bộ Trung Tự",
        "diaChiLapD": "",
        "longitude": 105.83716605621537,
        "latitude": 21.011977100510894,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7452,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83690856415534,
          21.01190073285763
        ]
      },
      "properties": {
        "tenDoiTuon": "18-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 10 Đê La Thành đất",
        "tuDien": "HBTT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hợp Bộ Trung Tự",
        "diaChiLapD": "",
        "longitude": 105.83690856415534,
        "latitude": 21.01190073285763,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7453,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83663497883092,
          21.012079758606276
        ]
      },
      "properties": {
        "tenDoiTuon": "19-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 10 Đê La Thành đất",
        "tuDien": "HBTT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hợp Bộ Trung Tự",
        "diaChiLapD": "",
        "longitude": 105.83663497883092,
        "latitude": 21.012079758606276,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7454,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8368307800859,
          21.012204951310128
        ]
      },
      "properties": {
        "tenDoiTuon": "19-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 10 Đê La Thành đất",
        "tuDien": "HBTT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hợp Bộ Trung Tự",
        "diaChiLapD": "",
        "longitude": 105.8368307800859,
        "latitude": 21.012204951310128,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7455,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83632116037239,
          21.012445321006744
        ]
      },
      "properties": {
        "tenDoiTuon": "21-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "",
        "tuDien": "HBTT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hợp Bộ Trung Tự",
        "diaChiLapD": "",
        "longitude": 105.83632116037239,
        "latitude": 21.012445321006744,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7456,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83686028438903,
          21.012322632356177
        ]
      },
      "properties": {
        "tenDoiTuon": "19-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 10 Đê La Thành đất",
        "tuDien": "HBTT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hợp Bộ Trung Tự",
        "diaChiLapD": "",
        "longitude": 105.83686028438903,
        "latitude": 21.012322632356177,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7457,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83696220832815,
          21.01249790182645
        ]
      },
      "properties": {
        "tenDoiTuon": "19-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 10 Đê La Thành đất",
        "tuDien": "HBTT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hợp Bộ Trung Tự",
        "diaChiLapD": "",
        "longitude": 105.83696220832815,
        "latitude": 21.01249790182645,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7458,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83710168319361,
          21.012342663162944
        ]
      },
      "properties": {
        "tenDoiTuon": "19-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 10 Đê La Thành đất",
        "tuDien": "HBTT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hợp Bộ Trung Tự",
        "diaChiLapD": "",
        "longitude": 105.83710168319361,
        "latitude": 21.012342663162944,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7459,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.836551830346,
          21.012615582641374
        ]
      },
      "properties": {
        "tenDoiTuon": "21-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 10 Đê La Thành đất",
        "tuDien": "HBTT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hợp Bộ Trung Tự",
        "diaChiLapD": "",
        "longitude": 105.836551830346,
        "latitude": 21.012615582641374,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7460,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83670739847588,
          21.01256550570979
        ]
      },
      "properties": {
        "tenDoiTuon": "21-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 10 Đê La Thành đất",
        "tuDien": "HBTT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hợp Bộ Trung Tự",
        "diaChiLapD": "",
        "longitude": 105.83670739847588,
        "latitude": 21.01256550570979,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7461,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83505649884711,
          21.00743378307557
        ]
      },
      "properties": {
        "tenDoiTuon": "B8AKL/1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hoàng Tích Trí",
        "tuDien": "B8AKL",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 10,
        "tuDien2": "B8A Kim Liên",
        "diaChiLapD": "",
        "longitude": 105.83505649884711,
        "latitude": 21.00743378307557,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7462,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8352697344614,
          21.007610310255025
        ]
      },
      "properties": {
        "tenDoiTuon": "B8aKl/2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hoàng Tích Trí",
        "tuDien": "B8AKL",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 10,
        "tuDien2": "B8A Kim Liên",
        "diaChiLapD": "",
        "longitude": 105.8352697344614,
        "latitude": 21.007610310255025,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7463,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83544541914935,
          21.007809372568666
        ]
      },
      "properties": {
        "tenDoiTuon": "B8AKL/3-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Hoàng Tích Trí",
        "tuDien": "B8AKL",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "B8A Kim Liên",
        "diaChiLapD": "",
        "longitude": 105.83544541914935,
        "latitude": 21.007809372568666,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7464,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83559562286375,
          21.007885742314606
        ]
      },
      "properties": {
        "tenDoiTuon": "B8AKL/3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hoàng Tích Trí",
        "tuDien": "B8AKL",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": "",
        "tuDien2": "B8A Kim Liên",
        "diaChiLapD": "",
        "longitude": 105.83559562286375,
        "latitude": 21.007885742314606,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7465,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83584372718768,
          21.008101079755566
        ]
      },
      "properties": {
        "tenDoiTuon": "B8AKL/4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hoàng Tích Trí",
        "tuDien": "B8AKL",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": "",
        "tuDien2": "B8A Kim Liên",
        "diaChiLapD": "",
        "longitude": 105.83584372718768,
        "latitude": 21.008101079755566,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7466,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83616156895785,
          21.008375258951986
        ]
      },
      "properties": {
        "tenDoiTuon": "B8AKL-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hoàng Tích Trí",
        "tuDien": "B8AKL",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": "",
        "tuDien2": "B8A Kim Liên",
        "diaChiLapD": "",
        "longitude": 105.83616156895785,
        "latitude": 21.008375258951986,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7467,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83718751388628,
          21.012855951676265
        ]
      },
      "properties": {
        "tenDoiTuon": "21-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 10 Đê La Thành đất",
        "tuDien": "HBTT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hợp Bộ Trung Tự",
        "diaChiLapD": "",
        "longitude": 105.83718751388628,
        "latitude": 21.012855951676265,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7468,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83706145005951,
          21.012998670606827
        ]
      },
      "properties": {
        "tenDoiTuon": "21-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 10 Đê La Thành đất",
        "tuDien": "HBTT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hợp Bộ Trung Tự",
        "diaChiLapD": "",
        "longitude": 105.83706145005951,
        "latitude": 21.012998670606827,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7469,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83684687334136,
          21.01281839403946
        ]
      },
      "properties": {
        "tenDoiTuon": "21-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 10 Đê La Thành đất",
        "tuDien": "HBTT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hợp Bộ Trung Tự",
        "diaChiLapD": "",
        "longitude": 105.83684687334136,
        "latitude": 21.01281839403946,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7470,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80075865010271,
          21.025754780034006
        ]
      },
      "properties": {
        "tenDoiTuon": "VH1 04",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Nghách 4+ 41 Chùa Nền",
        "tuDien": "VHA1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VH1",
        "diaChiLapD": "",
        "longitude": 105.80075865010271,
        "latitude": 21.025754780034006,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7471,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80102016548331,
          21.025697196636646
        ]
      },
      "properties": {
        "tenDoiTuon": "VH1 05",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Nghách 4+ 41 Chùa Nền",
        "tuDien": "VHA1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VH1",
        "diaChiLapD": "",
        "longitude": 105.80102016548331,
        "latitude": 21.025697196636646,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7472,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80130582074258,
          21.025836147840845
        ]
      },
      "properties": {
        "tenDoiTuon": "VH1 5-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Nghách 1194/103 Đường Láng",
        "tuDien": "VHA1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VH1",
        "diaChiLapD": "",
        "longitude": 105.80130582074258,
        "latitude": 21.025836147840845,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7473,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80169876436543,
          21.025793586224403
        ]
      },
      "properties": {
        "tenDoiTuon": "VH1 5-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Nghách 1194/103 Đường Láng",
        "tuDien": "VHA1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VH1",
        "diaChiLapD": "",
        "longitude": 105.80169876436543,
        "latitude": 21.025793586224403,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7474,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80194955090609,
          21.025762290910986
        ]
      },
      "properties": {
        "tenDoiTuon": "VH1 5-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Nghách 4+ 41 Chùa Nền",
        "tuDien": "VHA1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VH1",
        "diaChiLapD": "",
        "longitude": 105.80194955090609,
        "latitude": 21.025762290910986,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7475,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8021936319274,
          21.025742261906004
        ]
      },
      "properties": {
        "tenDoiTuon": "VH1 5-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Nghách 1194/103 Đường Láng",
        "tuDien": "VHA1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "VH1",
        "diaChiLapD": "",
        "longitude": 105.8021936319274,
        "latitude": 21.025742261906004,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7476,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80229019145104,
          21.025742261906004
        ]
      },
      "properties": {
        "tenDoiTuon": "VH1 5-7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Nghách 1194/103 Đường Láng",
        "tuDien": "VHA1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VH1",
        "diaChiLapD": "",
        "longitude": 105.80229019145104,
        "latitude": 21.025742261906004,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7477,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80215256784562,
          21.02557721208177
        ]
      },
      "properties": {
        "tenDoiTuon": "VH1 5-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Nghách 1194/103 Đường Láng",
        "tuDien": "VHA1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "VH1",
        "diaChiLapD": "",
        "longitude": 105.80215256784562,
        "latitude": 21.02557721208177,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7478,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80194212827415,
          21.025582151811857
        ]
      },
      "properties": {
        "tenDoiTuon": "VH1 5-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Nghách 1194/103 Đường Láng",
        "tuDien": "VHA1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "VH1",
        "diaChiLapD": "",
        "longitude": 105.80194212827415,
        "latitude": 21.025582151811857,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7479,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83633188923031,
          21.008512974338434
        ]
      },
      "properties": {
        "tenDoiTuon": "BA8KL/6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hoàng Tích Trí",
        "tuDien": "B8AKL",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": "",
        "tuDien2": "B8A Kim Liên",
        "diaChiLapD": "",
        "longitude": 105.83633188923031,
        "latitude": 21.008512974338434,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7480,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83667521198296,
          21.008792160598748
        ]
      },
      "properties": {
        "tenDoiTuon": "BA8KL/7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hoàng Tích Trí",
        "tuDien": "B8AKL",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": "",
        "tuDien2": "B8A Kim Liên",
        "diaChiLapD": "",
        "longitude": 105.83667521198296,
        "latitude": 21.008792160598748,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7481,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83647270520862,
          21.00864067394815
        ]
      },
      "properties": {
        "tenDoiTuon": "BA8KL/6-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hoàng Tích Trí",
        "tuDien": "B8AKL",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 10,
        "tuDien2": "B8A Kim Liên",
        "diaChiLapD": "",
        "longitude": 105.83647270520862,
        "latitude": 21.00864067394815,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7482,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83685626109539,
          21.00893613537229
        ]
      },
      "properties": {
        "tenDoiTuon": "B8AKL/8",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hoàng Tích Trí",
        "tuDien": "B8AKL",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": "",
        "tuDien2": "B8A Kim Liên",
        "diaChiLapD": "",
        "longitude": 105.83685626109539,
        "latitude": 21.00893613537229,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7483,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8372424991899,
          21.00923535206474
        ]
      },
      "properties": {
        "tenDoiTuon": "B8AKL/9",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hoàng Tích Trí",
        "tuDien": "B8AKL",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 10,
        "tuDien2": "B8A Kim Liên",
        "diaChiLapD": "",
        "longitude": 105.8372424991899,
        "latitude": 21.00923535206474,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7484,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83751474341047,
          21.009481986292844
        ]
      },
      "properties": {
        "tenDoiTuon": "B8AKL/10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Hoàng Tích Trí",
        "tuDien": "B8AKL",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 10,
        "tuDien2": "B8A Kim Liên",
        "diaChiLapD": "",
        "longitude": 105.83751474341047,
        "latitude": 21.009481986292844,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7485,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83688978871011,
          21.00935178350272
        ]
      },
      "properties": {
        "tenDoiTuon": "B8AKL/10-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Nhà B1 TT Kim Liên",
        "tuDien": "B8AKL",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Thép bát giác",
        "chieuCao": 6,
        "tuDien2": "B8A Kim Liên",
        "diaChiLapD": "",
        "longitude": 105.83688978871011,
        "latitude": 21.00935178350272,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7486,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82738940436045,
          21.01693215090832
        ]
      },
      "properties": {
        "tenDoiTuon": "16",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Vườn Hoa 1/6",
        "tuDien": "VH1/6",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 8,
        "tuDien2": "VH1/6",
        "diaChiLapD": "",
        "longitude": 105.82738940436045,
        "latitude": 21.01693215090832,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7487,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82671080547833,
          21.016318725097843
        ]
      },
      "properties": {
        "tenDoiTuon": "VH 05",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - đèn chùm",
        "tenDuongPh": "Vườn Hoa 1/6",
        "tuDien": "VH1/6",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "NOUVO",
        "chieuCao": 3,
        "tuDien2": "VH1/6",
        "diaChiLapD": "",
        "longitude": 105.82671080547833,
        "latitude": 21.016318725097843,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7488,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82677598315699,
          21.01645518126147
        ]
      },
      "properties": {
        "tenDoiTuon": "06",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - đèn chùm",
        "tenDuongPh": "Vườn Hoa 1/6",
        "tuDien": "VH1/6",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "NOUVO",
        "chieuCao": 3,
        "tuDien2": "VH1/6",
        "diaChiLapD": "",
        "longitude": 105.82677598315699,
        "latitude": 21.01645518126147,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7489,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82684116083567,
          21.016591637300245
        ]
      },
      "properties": {
        "tenDoiTuon": "07",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - đèn chùm",
        "tenDuongPh": "Vườn Hoa 1/6",
        "tuDien": "VH1/6",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "NOUVO",
        "chieuCao": 3,
        "tuDien2": "VH1/6",
        "diaChiLapD": "",
        "longitude": 105.82684116083567,
        "latitude": 21.016591637300245,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7490,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82690633851435,
          21.016728093214162
        ]
      },
      "properties": {
        "tenDoiTuon": "08",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - đèn chùm",
        "tenDuongPh": "Vườn Hoa 1/6",
        "tuDien": "VH1/6",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "NOUVO",
        "chieuCao": 3,
        "tuDien2": "VH1/6",
        "diaChiLapD": "",
        "longitude": 105.82690633851435,
        "latitude": 21.016728093214162,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7491,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82697151619301,
          21.016864549003234
        ]
      },
      "properties": {
        "tenDoiTuon": "09",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - đèn chùm",
        "tenDuongPh": "Vườn Hoa 1/6",
        "tuDien": "VH1/6",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "NOUVO",
        "chieuCao": 3,
        "tuDien2": "VH1/6",
        "diaChiLapD": "",
        "longitude": 105.82697151619301,
        "latitude": 21.016864549003234,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7492,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82703132945622,
          21.01698097448629
        ]
      },
      "properties": {
        "tenDoiTuon": "10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - đèn chùm",
        "tenDuongPh": "Vườn Hoa 1/6",
        "tuDien": "VH1/6",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "NOUVO",
        "chieuCao": 3,
        "tuDien2": "VH1/6",
        "diaChiLapD": "",
        "longitude": 105.82703132945622,
        "latitude": 21.01698097448629,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7493,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80111672500693,
          21.0261178052914
        ]
      },
      "properties": {
        "tenDoiTuon": "VH1 06",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Nghách 1194/103 Đường Láng",
        "tuDien": "VHA1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VH1",
        "diaChiLapD": "",
        "longitude": 105.80111672500693,
        "latitude": 21.0261178052914,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7494,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80120792011508,
          21.02636691521481
        ]
      },
      "properties": {
        "tenDoiTuon": "VH1 07",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Nghách 1194/103 Đường Láng",
        "tuDien": "VHA1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VH1",
        "diaChiLapD": "",
        "longitude": 105.80120792011508,
        "latitude": 21.02636691521481,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7495,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80145630545748,
          21.026269320572503
        ]
      },
      "properties": {
        "tenDoiTuon": "VH1 7-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Nghách 1194/91 Đường Láng",
        "tuDien": "VHA1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VH1",
        "diaChiLapD": "",
        "longitude": 105.80145630545748,
        "latitude": 21.026269320572503,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7496,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80170378612864,
          21.026169743602164
        ]
      },
      "properties": {
        "tenDoiTuon": "VH1 7-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Nghách 1194/91 Đường Láng",
        "tuDien": "VHA1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VH1",
        "diaChiLapD": "",
        "longitude": 105.80170378612864,
        "latitude": 21.026169743602164,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7497,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80196560923468,
          21.02605897557375
        ]
      },
      "properties": {
        "tenDoiTuon": "VH1 7-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Nghách 1194/91 Đường Láng",
        "tuDien": "VHA1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VH1",
        "diaChiLapD": "",
        "longitude": 105.80196560923468,
        "latitude": 21.02605897557375,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7498,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80223607733846,
          21.0259457043619
        ]
      },
      "properties": {
        "tenDoiTuon": "VH1 7-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Nghách 1194/91 Đường Láng",
        "tuDien": "VHA1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VH1",
        "diaChiLapD": "",
        "longitude": 105.80223607733846,
        "latitude": 21.0259457043619,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7499,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8271868975771,
          21.01688708297511
        ]
      },
      "properties": {
        "tenDoiTuon": "12",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - đèn chùm",
        "tenDuongPh": "Vườn Hoa 1/6",
        "tuDien": "VH1/6",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "NOUVO",
        "chieuCao": 3,
        "tuDien2": "VH1/6",
        "diaChiLapD": "",
        "longitude": 105.8271868975771,
        "latitude": 21.01688708297511,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7500,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82714532333915,
          21.01697972259961
        ]
      },
      "properties": {
        "tenDoiTuon": "11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - đèn chùm",
        "tenDuongPh": "Vườn Hoa 1/6",
        "tuDien": "VH1/6",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "NOUVO",
        "chieuCao": 3,
        "tuDien2": "VH1/6",
        "diaChiLapD": "",
        "longitude": 105.82714532333915,
        "latitude": 21.01697972259961,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7501,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82737867552049,
          21.016868304665472
        ]
      },
      "properties": {
        "tenDoiTuon": "13",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - đèn chùm",
        "tenDuongPh": "Vườn Hoa 1/6",
        "tuDien": "VH1/6",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "NOUVO",
        "chieuCao": 13,
        "tuDien2": "VH1/6",
        "diaChiLapD": "",
        "longitude": 105.82737867552049,
        "latitude": 21.016868304665472,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7502,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8275785000961,
          21.01676690175442
        ]
      },
      "properties": {
        "tenDoiTuon": "14",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - đèn chùm",
        "tenDuongPh": "Vườn Hoa 1/6",
        "tuDien": "VH1/6",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "NOUVO",
        "chieuCao": 3,
        "tuDien2": "VH1/6",
        "diaChiLapD": "",
        "longitude": 105.8275785000961,
        "latitude": 21.01676690175442,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7503,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82773675043371,
          21.016674261996023
        ]
      },
      "properties": {
        "tenDoiTuon": "32",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - đèn chùm",
        "tenDuongPh": "Vườn Hoa 1/6",
        "tuDien": "VH1/6",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "NOUVO",
        "chieuCao": 3,
        "tuDien2": "VH1/6",
        "diaChiLapD": "",
        "longitude": 105.82773675043371,
        "latitude": 21.016674261996023,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7504,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82786415536434,
          21.01660791186483
        ]
      },
      "properties": {
        "tenDoiTuon": "VH 33",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - đèn chùm",
        "tenDuongPh": "Vườn Hoa 1/6",
        "tuDien": "VH1/6",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "NOUVO",
        "chieuCao": 3,
        "tuDien2": "VH1/6",
        "diaChiLapD": "",
        "longitude": 105.82786415536434,
        "latitude": 21.01660791186483,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7505,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80121462564342,
          21.02652839830925
        ]
      },
      "properties": {
        "tenDoiTuon": "VH1 08",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Nghách 1194/103 Đường Láng",
        "tuDien": "VHA1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VH1",
        "diaChiLapD": "",
        "longitude": 105.80121462564342,
        "latitude": 21.02652839830925,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7506,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82699377853884,
          21.016521531461272
        ]
      },
      "properties": {
        "tenDoiTuon": "23-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - đèn chùm",
        "tenDuongPh": "Vườn Hoa 1/6",
        "tuDien": "VH1/6",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "NOUVO",
        "chieuCao": 3,
        "tuDien2": "VH1/6",
        "diaChiLapD": "",
        "longitude": 105.82699377853884,
        "latitude": 21.016521531461272,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7507,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82692148867349,
          21.016375188365597
        ]
      },
      "properties": {
        "tenDoiTuon": "23-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - đèn chùm",
        "tenDuongPh": "Vườn Hoa 1/6",
        "tuDien": "VH1/6",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "NOUVO",
        "chieuCao": 3,
        "tuDien2": "VH1/6",
        "diaChiLapD": "",
        "longitude": 105.82692148867349,
        "latitude": 21.016375188365597,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7508,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82765477115983,
          21.01657843588381
        ]
      },
      "properties": {
        "tenDoiTuon": "31",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - đèn chùm",
        "tenDuongPh": "Vườn Hoa 1/6",
        "tuDien": "VH1/6",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "NOUVO",
        "chieuCao": 3,
        "tuDien2": "VH1/6",
        "diaChiLapD": "",
        "longitude": 105.82765477115983,
        "latitude": 21.01657843588381,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7509,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82758959348115,
          21.016441979832933
        ]
      },
      "properties": {
        "tenDoiTuon": "30",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - đèn chùm",
        "tenDuongPh": "Vườn Hoa 1/6",
        "tuDien": "VH1/6",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "NOUVO",
        "chieuCao": 3,
        "tuDien2": "VH1/6",
        "diaChiLapD": "",
        "longitude": 105.82758959348115,
        "latitude": 21.016441979832933,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7510,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82752441580247,
          21.01630552365726
        ]
      },
      "properties": {
        "tenDoiTuon": "29",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - đèn chùm",
        "tenDuongPh": "Vườn Hoa 1/6",
        "tuDien": "VH1/6",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "NOUVO",
        "chieuCao": 3,
        "tuDien2": "VH1/6",
        "diaChiLapD": "",
        "longitude": 105.82752441580247,
        "latitude": 21.01630552365726,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7511,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82745923812378,
          21.01616906735665
        ]
      },
      "properties": {
        "tenDoiTuon": "28",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - đèn chùm",
        "tenDuongPh": "Vườn Hoa 1/6",
        "tuDien": "VH1/6",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "NOUVO",
        "chieuCao": 3,
        "tuDien2": "VH1/6",
        "diaChiLapD": "",
        "longitude": 105.82745923812378,
        "latitude": 21.01616906735665,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7512,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8278500737692,
          21.016481157990384
        ]
      },
      "properties": {
        "tenDoiTuon": "38",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - đèn chùm",
        "tenDuongPh": "Vườn Hoa 1/6",
        "tuDien": "VH1/6",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "NOUVO",
        "chieuCao": 3,
        "tuDien2": "VH1/6",
        "diaChiLapD": "",
        "longitude": 105.8278500737692,
        "latitude": 21.016481157990384,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7513,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82778435965257,
          21.016340946175447
        ]
      },
      "properties": {
        "tenDoiTuon": "37",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - đèn chùm",
        "tenDuongPh": "Vườn Hoa 1/6",
        "tuDien": "VH1/6",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "NOUVO",
        "chieuCao": 3,
        "tuDien2": "VH1/6",
        "diaChiLapD": "",
        "longitude": 105.82778435965257,
        "latitude": 21.016340946175447,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7514,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8277072803871,
          21.0162130766188
        ]
      },
      "properties": {
        "tenDoiTuon": "36",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - đèn chùm",
        "tenDuongPh": "Vườn Hoa 1/6",
        "tuDien": "VH1/6",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "NOUVO",
        "chieuCao": 3,
        "tuDien2": "VH1/6",
        "diaChiLapD": "",
        "longitude": 105.8277072803871,
        "latitude": 21.0162130766188,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7515,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82763405608138,
          21.01608413159755
        ]
      },
      "properties": {
        "tenDoiTuon": "35",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - đèn chùm",
        "tenDuongPh": "Vườn Hoa 1/6",
        "tuDien": "VH1/6",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "NOUVO",
        "chieuCao": 3,
        "tuDien2": "VH1/6",
        "diaChiLapD": "",
        "longitude": 105.82763405608138,
        "latitude": 21.01608413159755,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7516,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8010389409465,
          21.026553434432167
        ]
      },
      "properties": {
        "tenDoiTuon": "VH1 8-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Nghách 1194/90 Đường Láng",
        "tuDien": "VHA1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VH1",
        "diaChiLapD": "",
        "longitude": 105.8010389409465,
        "latitude": 21.026553434432167,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7517,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80079083661356,
          21.026573463328155
        ]
      },
      "properties": {
        "tenDoiTuon": "VH1 8-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Nghách 1194/90 Đường Láng",
        "tuDien": "VHA1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VH1",
        "diaChiLapD": "",
        "longitude": 105.80079083661356,
        "latitude": 21.026573463328155,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7518,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8007116948394,
          21.0264136231349
        ]
      },
      "properties": {
        "tenDoiTuon": "VH1 8-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Nghách 1194/90 Đường Láng",
        "tuDien": "VHA1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VH1",
        "diaChiLapD": "",
        "longitude": 105.8007116948394,
        "latitude": 21.0264136231349,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7519,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80068086604226,
          21.026195417473037
        ]
      },
      "properties": {
        "tenDoiTuon": "VH1 8-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Nghách 1194/90 Đường Láng",
        "tuDien": "VHA1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VH1",
        "diaChiLapD": "",
        "longitude": 105.80068086604226,
        "latitude": 21.026195417473037,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7520,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80075901844782,
          21.02600059607915
        ]
      },
      "properties": {
        "tenDoiTuon": "VH1 8-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Nghách 1194/90 Đường Láng",
        "tuDien": "VHA1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VH1",
        "diaChiLapD": "",
        "longitude": 105.80075901844782,
        "latitude": 21.02600059607915,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7521,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82685162145665,
          21.016256130485115
        ]
      },
      "properties": {
        "tenDoiTuon": "23",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - đèn chùm",
        "tenDuongPh": "Vườn Hoa 1/6",
        "tuDien": "VH1/6",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "NOUVO",
        "chieuCao": 3,
        "tuDien2": "VH1/6",
        "diaChiLapD": "",
        "longitude": 105.82685162145665,
        "latitude": 21.016256130485115,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7522,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82727138717489,
          21.016062087019154
        ]
      },
      "properties": {
        "tenDoiTuon": "VH 26",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - đèn chùm",
        "tenDuongPh": "Vườn Hoa 1/6",
        "tuDien": "VH1/6",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "NOUVO",
        "chieuCao": 3,
        "tuDien2": "VH1/6",
        "diaChiLapD": "",
        "longitude": 105.82727138717489,
        "latitude": 21.016062087019154,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7523,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82702194173808,
          21.016177261235114
        ]
      },
      "properties": {
        "tenDoiTuon": "VH 24",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - đèn chùm",
        "tenDuongPh": "Vườn Hoa 1/6",
        "tuDien": "VH1/6",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "NOUVO",
        "chieuCao": 3,
        "tuDien2": "VH1/6",
        "diaChiLapD": "",
        "longitude": 105.82702194173808,
        "latitude": 21.016177261235114,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7524,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82740281541714,
          21.016004499878242
        ]
      },
      "properties": {
        "tenDoiTuon": "27",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - đèn chùm",
        "tenDuongPh": "Vườn Hoa 1/6",
        "tuDien": "VH1/6",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "NOUVO",
        "chieuCao": 3,
        "tuDien2": "VH1/6",
        "diaChiLapD": "",
        "longitude": 105.82740281541714,
        "latitude": 21.016004499878242,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7525,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82751546819618,
          21.015956927875028
        ]
      },
      "properties": {
        "tenDoiTuon": "VH 34",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - đèn chùm",
        "tenDuongPh": "Vườn Hoa 1/6",
        "tuDien": "VH1/6",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "NOUVO",
        "chieuCao": 3,
        "tuDien2": "VH1/6",
        "diaChiLapD": "",
        "longitude": 105.82751546819618,
        "latitude": 21.015956927875028,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7526,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8271372767249,
          21.016124681713322
        ]
      },
      "properties": {
        "tenDoiTuon": "VH 25",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Vườn Hoa 1/6",
        "tuDien": "VH1/6",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 17,
        "tuDien2": "VH1/6",
        "diaChiLapD": "",
        "longitude": 105.8271372767249,
        "latitude": 21.016124681713322,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7527,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8261358069713,
          21.01699443226463
        ]
      },
      "properties": {
        "tenDoiTuon": "YHBNV 17-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 190,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 51/10 Hoàng Cầu",
        "tuDien": "YHBNV",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "YHBNV",
        "diaChiLapD": "",
        "longitude": 105.8261358069713,
        "latitude": 21.01699443226463,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7528,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83355446176597,
          21.010274468135837
        ]
      },
      "properties": {
        "tenDoiTuon": "TTCT2 01",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - đèn chùm",
        "tenDuongPh": "Vuờn Hoa Con Voi",
        "tuDien": "TTCT2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Sân vườn",
        "chieuCao": 3,
        "tuDien2": "Trung Tự Cao Tầng 2",
        "diaChiLapD": "",
        "longitude": 105.83355446176597,
        "latitude": 21.010274468135837,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7529,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83351556973575,
          21.010245673450907
        ]
      },
      "properties": {
        "tenDoiTuon": "TTCT2 08",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - đèn chùm",
        "tenDuongPh": "Vuờn Hoa Con Voi",
        "tuDien": "TTCT2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Sân vườn",
        "chieuCao": 3,
        "tuDien2": "Trung Tự Cao Tầng 2",
        "diaChiLapD": "",
        "longitude": 105.83351556973575,
        "latitude": 21.010245673450907,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7530,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83351112732413,
          21.010159524101528
        ]
      },
      "properties": {
        "tenDoiTuon": "TTCT2 14",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - đèn chùm",
        "tenDuongPh": "Vuờn Hoa Con Voi",
        "tuDien": "TTCT2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Sân vườn",
        "chieuCao": 3,
        "tuDien2": "Trung Tự Cao Tầng 2",
        "diaChiLapD": "",
        "longitude": 105.83351112732413,
        "latitude": 21.010159524101528,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7531,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83355404266597,
          21.01011946189901
        ]
      },
      "properties": {
        "tenDoiTuon": "TTCT2 15",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - đèn chùm",
        "tenDuongPh": "Vuờn Hoa Con Voi",
        "tuDien": "TTCT2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Sân vườn",
        "chieuCao": 3,
        "tuDien2": "Trung Tự Cao Tầng 2",
        "diaChiLapD": "",
        "longitude": 105.83355404266597,
        "latitude": 21.01011946189901,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7532,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83327777514278,
          21.010537610613245
        ]
      },
      "properties": {
        "tenDoiTuon": "TTCT2 03",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - đèn chùm",
        "tenDuongPh": "Vuờn Hoa Con Voi",
        "tuDien": "TTCT2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Sân vườn",
        "chieuCao": 3,
        "tuDien2": "Trung Tự Cao Tầng 2",
        "diaChiLapD": "",
        "longitude": 105.83327777514278,
        "latitude": 21.010537610613245,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7533,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83276815542925,
          21.010282214532012
        ]
      },
      "properties": {
        "tenDoiTuon": "TTCT2 07",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - đèn chùm",
        "tenDuongPh": "Vuờn Hoa Con Voi",
        "tuDien": "TTCT2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Sân vườn",
        "chieuCao": 3,
        "tuDien2": "Trung Tự Cao Tầng 2",
        "diaChiLapD": "",
        "longitude": 105.83276815542925,
        "latitude": 21.010282214532012,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7534,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83289153703929,
          21.01037736214309
        ]
      },
      "properties": {
        "tenDoiTuon": "TTCT2 06",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - đèn chùm",
        "tenDuongPh": "Vuờn Hoa Con Voi",
        "tuDien": "TTCT2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Sân vườn",
        "chieuCao": 3,
        "tuDien2": "Trung Tự Cao Tầng 2",
        "diaChiLapD": "",
        "longitude": 105.83289153703929,
        "latitude": 21.01037736214309,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7535,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83301760086603,
          21.010471257752172
        ]
      },
      "properties": {
        "tenDoiTuon": "TTCT2 05",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - đèn chùm",
        "tenDuongPh": "Vuờn Hoa Con Voi",
        "tuDien": "TTCT2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Sân vườn",
        "chieuCao": 3,
        "tuDien2": "Trung Tự Cao Tầng 2",
        "diaChiLapD": "",
        "longitude": 105.83301760086603,
        "latitude": 21.010471257752172,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7536,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83312354812574,
          21.010562649421104
        ]
      },
      "properties": {
        "tenDoiTuon": "TTCT2 04",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - đèn chùm",
        "tenDuongPh": "Vuờn Hoa Con Voi",
        "tuDien": "TTCT2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Sân vườn",
        "chieuCao": 3,
        "tuDien2": "Trung Tự Cao Tầng 2",
        "diaChiLapD": "",
        "longitude": 105.83312354812574,
        "latitude": 21.010562649421104,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7537,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83268500695331,
          21.01017079159473
        ]
      },
      "properties": {
        "tenDoiTuon": "TTCT2 19",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - đèn chùm",
        "tenDuongPh": "Vuờn Hoa Con Voi",
        "tuDien": "TTCT2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Sân vườn",
        "chieuCao": 3,
        "tuDien2": "Trung Tự Cao Tầng 2",
        "diaChiLapD": "",
        "longitude": 105.83268500695331,
        "latitude": 21.01017079159473,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7538,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83289824256761,
          21.01008816329512
        ]
      },
      "properties": {
        "tenDoiTuon": "TTCT2 18",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - đèn chùm",
        "tenDuongPh": "Vuờn Hoa Con Voi",
        "tuDien": "TTCT2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Sân vườn",
        "chieuCao": 3,
        "tuDien2": "Trung Tự Cao Tầng 2",
        "diaChiLapD": "",
        "longitude": 105.83289824256761,
        "latitude": 21.01008816329512,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7539,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83316244015597,
          21.01003307773691
        ]
      },
      "properties": {
        "tenDoiTuon": "TTCT2 17",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - đèn chùm",
        "tenDuongPh": "Vuờn Hoa Con Voi",
        "tuDien": "TTCT2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Sân vườn",
        "chieuCao": 3,
        "tuDien2": "Trung Tự Cao Tầng 2",
        "diaChiLapD": "",
        "longitude": 105.83316244015597,
        "latitude": 21.01003307773691,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7540,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83344273099976,
          21.0100255660685
        ]
      },
      "properties": {
        "tenDoiTuon": "TTCT2 16",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - đèn chùm",
        "tenDuongPh": "Vuờn Hoa Con Voi",
        "tuDien": "TTCT2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Sân vườn",
        "chieuCao": 3,
        "tuDien2": "Trung Tự Cao Tầng 2",
        "diaChiLapD": "",
        "longitude": 105.83344273099976,
        "latitude": 21.0100255660685,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7541,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83339981564895,
          21.010358583013847
        ]
      },
      "properties": {
        "tenDoiTuon": "TTCT2 09",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - đèn chùm",
        "tenDuongPh": "Vuờn Hoa Con Voi",
        "tuDien": "TTCT2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Sân vườn",
        "chieuCao": 3,
        "tuDien2": "Trung Tự Cao Tầng 2",
        "diaChiLapD": "",
        "longitude": 105.83339981564895,
        "latitude": 21.010358583013847,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7542,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8334494365191,
          21.01038862961899
        ]
      },
      "properties": {
        "tenDoiTuon": "TTCT2 02",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - đèn chùm",
        "tenDuongPh": "Vuờn Hoa Con Voi",
        "tuDien": "TTCT2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Sân vườn",
        "chieuCao": 3,
        "tuDien2": "Trung Tự Cao Tầng 2",
        "diaChiLapD": "",
        "longitude": 105.8334494365191,
        "latitude": 21.01038862961899,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7543,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83339981564895,
          21.010283466474057
        ]
      },
      "properties": {
        "tenDoiTuon": "TTCT2 10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Vuờn Hoa Con Voi",
        "tuDien": "TTCT2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Sân vườn",
        "chieuCao": 3,
        "tuDien2": "Trung Tự Cao Tầng 2",
        "diaChiLapD": "",
        "longitude": 105.83339981564895,
        "latitude": 21.010283466474057,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7544,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83333678374005,
          21.01023714458944
        ]
      },
      "properties": {
        "tenDoiTuon": "TTCT2 11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Vuờn Hoa Con Voi",
        "tuDien": "TTCT2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Sân vườn",
        "chieuCao": 3,
        "tuDien2": "Trung Tự Cao Tầng 2",
        "diaChiLapD": "",
        "longitude": 105.83333678374005,
        "latitude": 21.01023714458944,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7545,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8332817984544,
          21.010192074634094
        ]
      },
      "properties": {
        "tenDoiTuon": "TTCT2 12",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Vuờn Hoa Con Voi",
        "tuDien": "TTCT2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Sân vườn",
        "chieuCao": 3,
        "tuDien2": "Trung Tự Cao Tầng 2",
        "diaChiLapD": "",
        "longitude": 105.8332817984544,
        "latitude": 21.010192074634094,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7546,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8332187665455,
          21.010145752721098
        ]
      },
      "properties": {
        "tenDoiTuon": "TTCT2 13",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Đường phố - đèn chùm",
        "tenDuongPh": "Vuờn Hoa Con Voi",
        "tuDien": "TTCT2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Sân vườn",
        "chieuCao": 3,
        "tuDien2": "Trung Tự Cao Tầng 2",
        "diaChiLapD": "",
        "longitude": 105.8332187665455,
        "latitude": 21.010145752721098,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7547,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82710777243074,
          21.01618978016706
        ]
      },
      "properties": {
        "tenDoiTuon": "24-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Đèn khác",
        "tenDuongPh": "Hoàng Cầu",
        "tuDien": "VH1/6",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "VH1/6",
        "diaChiLapD": "",
        "longitude": 105.82710777243074,
        "latitude": 21.01618978016706,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7548,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82714697555375,
          21.016257970875817
        ]
      },
      "properties": {
        "tenDoiTuon": "24-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Đèn khác",
        "tenDuongPh": "Hoàng Cầu",
        "tuDien": "VH1/6",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "VH1/6",
        "diaChiLapD": "",
        "longitude": 105.82714697555375,
        "latitude": 21.016257970875817,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7549,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82720969636985,
          21.01614471200949
        ]
      },
      "properties": {
        "tenDoiTuon": "26-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Đèn khác",
        "tenDuongPh": "Hoàng Cầu",
        "tuDien": "VH1/6",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "VH1/6",
        "diaChiLapD": "",
        "longitude": 105.82720969636985,
        "latitude": 21.01614471200949,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7550,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8272501598756,
          21.016215953828425
        ]
      },
      "properties": {
        "tenDoiTuon": "26-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Đèn khác",
        "tenDuongPh": "Hoàng Cầu",
        "tuDien": "VH1/6",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "VH1/6",
        "diaChiLapD": "",
        "longitude": 105.8272501598756,
        "latitude": 21.016215953828425,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7551,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82740667109411,
          21.01634892698731
        ]
      },
      "properties": {
        "tenDoiTuon": "29-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Đèn khác",
        "tenDuongPh": "Hoàng Cầu",
        "tuDien": "VH1/6",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "VH1/6",
        "diaChiLapD": "",
        "longitude": 105.82740667109411,
        "latitude": 21.01634892698731,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7552,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82717197780889,
          21.01645784152742
        ]
      },
      "properties": {
        "tenDoiTuon": "29-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Đèn khác",
        "tenDuongPh": "Hoàng Cầu",
        "tuDien": "VH1/6",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "VH1/6",
        "diaChiLapD": "",
        "longitude": 105.82717197780889,
        "latitude": 21.01645784152742,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7553,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82718270663986,
          21.016399002647773
        ]
      },
      "properties": {
        "tenDoiTuon": "29-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Đèn khác",
        "tenDuongPh": "Hoàng Cầu",
        "tuDien": "VH1/6",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "VH1/6",
        "diaChiLapD": "",
        "longitude": 105.82718270663986,
        "latitude": 21.016399002647773,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7554,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82735973244066,
          21.016317629690562
        ]
      },
      "properties": {
        "tenDoiTuon": "29-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Đèn khác",
        "tenDuongPh": "Hoàng Cầu",
        "tuDien": "VH1/6",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "VH1/6",
        "diaChiLapD": "",
        "longitude": 105.82735973244066,
        "latitude": 21.016317629690562,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7555,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82729872241627,
          21.016301455117766
        ]
      },
      "properties": {
        "tenDoiTuon": "29-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Đèn khác",
        "tenDuongPh": "Hoàng Cầu",
        "tuDien": "VH1/6",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "VH1/6",
        "diaChiLapD": "",
        "longitude": 105.82729872241627,
        "latitude": 21.016301455117766,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7556,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8272028232069,
          21.01634767509535
        ]
      },
      "properties": {
        "tenDoiTuon": "29-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Đèn khác",
        "tenDuongPh": "Hoàng Cầu",
        "tuDien": "VH1/6",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "VH1/6",
        "diaChiLapD": "",
        "longitude": 105.8272028232069,
        "latitude": 21.01634767509535,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7557,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80124889573109,
          21.02677790605939
        ]
      },
      "properties": {
        "tenDoiTuon": "VH1 09",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Nghách 1194/103 Đường Láng",
        "tuDien": "VHA1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VH1",
        "diaChiLapD": "",
        "longitude": 105.80124889573109,
        "latitude": 21.02677790605939,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7558,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80129240970388,
          21.026901436107263
        ]
      },
      "properties": {
        "tenDoiTuon": "VH1 10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Nghách 1194/103 Đường Láng",
        "tuDien": "VHA1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VH1",
        "diaChiLapD": "",
        "longitude": 105.80129240970388,
        "latitude": 21.026901436107263,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7559,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80133800725345,
          21.02702160915883
        ]
      },
      "properties": {
        "tenDoiTuon": "VH1 11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Nghách 1194/103 Đường Láng",
        "tuDien": "VHA1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VH1",
        "diaChiLapD": "",
        "longitude": 105.80133800725345,
        "latitude": 21.02702160915883,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7560,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80143993120154,
          21.026982803288156
        ]
      },
      "properties": {
        "tenDoiTuon": "VH1 11-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Nghách 1194/73 Đường Láng",
        "tuDien": "VHA1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VH1",
        "diaChiLapD": "",
        "longitude": 105.80143993120154,
        "latitude": 21.026982803288156,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7561,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8015684634751,
          21.027164831952696
        ]
      },
      "properties": {
        "tenDoiTuon": "VH1 11-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Nghách 1194/73 Đường Láng",
        "tuDien": "VHA1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VH1",
        "diaChiLapD": "",
        "longitude": 105.8015684634751,
        "latitude": 21.027164831952696,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7562,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80165104224804,
          21.026871338646046
        ]
      },
      "properties": {
        "tenDoiTuon": "VH1 11-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Nghách 1194/73 Đường Láng",
        "tuDien": "VHA1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VH1",
        "diaChiLapD": "",
        "longitude": 105.80165104224804,
        "latitude": 21.026871338646046,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7563,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80176487726088,
          21.02705203817261
        ]
      },
      "properties": {
        "tenDoiTuon": "VH1 11-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Nghách 1194/73 Đường Láng",
        "tuDien": "VHA1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VH1",
        "diaChiLapD": "",
        "longitude": 105.80176487726088,
        "latitude": 21.02705203817261,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7564,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80176850180264,
          21.02680004001996
        ]
      },
      "properties": {
        "tenDoiTuon": "VH1 11-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Nghách 1194/73 Đường Láng",
        "tuDien": "VHA1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VH1",
        "diaChiLapD": "",
        "longitude": 105.80176850180264,
        "latitude": 21.02680004001996,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7565,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80166496827971,
          21.026614020198746
        ]
      },
      "properties": {
        "tenDoiTuon": "VH1 11-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Nghách 1194/73 Đường Láng",
        "tuDien": "VHA1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VH1",
        "diaChiLapD": "",
        "longitude": 105.80166496827971,
        "latitude": 21.026614020198746,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7566,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80144529561703,
          21.026757478678736
        ]
      },
      "properties": {
        "tenDoiTuon": "VH1 11-9",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Nghách 1194/73 Đường Láng",
        "tuDien": "VHA1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VH1",
        "diaChiLapD": "",
        "longitude": 105.80144529561703,
        "latitude": 21.026757478678736,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7567,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80133648848525,
          21.02660348420578
        ]
      },
      "properties": {
        "tenDoiTuon": "VH1 11-8",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Nghách 1194/73 Đường Láng",
        "tuDien": "VHA1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VH1",
        "diaChiLapD": "",
        "longitude": 105.80133648848525,
        "latitude": 21.02660348420578,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7568,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80167456627183,
          21.02638945199202
        ]
      },
      "properties": {
        "tenDoiTuon": "VH1 11-7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Nghách 1194/73 Đường Láng",
        "tuDien": "VHA1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VH1",
        "diaChiLapD": "",
        "longitude": 105.80167456627183,
        "latitude": 21.02638945199202,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7569,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82967464644359,
          21.006763979468037
        ]
      },
      "properties": {
        "tenDoiTuon": "KHTL 7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 43 Chùa Bộc",
        "tuDien": "KHTL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KHTL",
        "diaChiLapD": "",
        "longitude": 105.82967464644359,
        "latitude": 21.006763979468037,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7570,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82952578385103,
          21.00666507363368
        ]
      },
      "properties": {
        "tenDoiTuon": "KHTL 6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 43 Chùa Bộc",
        "tuDien": "KHTL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KHTL",
        "diaChiLapD": "",
        "longitude": 105.82952578385103,
        "latitude": 21.00666507363368,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7571,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82954992373868,
          21.006524852591905
        ]
      },
      "properties": {
        "tenDoiTuon": "KHTL 8",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 43 Chùa Bộc",
        "tuDien": "KHTL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KHTL",
        "diaChiLapD": "",
        "longitude": 105.82954992373868,
        "latitude": 21.006524852591905,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7572,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8294815274053,
          21.006532364435746
        ]
      },
      "properties": {
        "tenDoiTuon": "KHTL 5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 43 Chùa Bộc",
        "tuDien": "KHTL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KHTL",
        "diaChiLapD": "",
        "longitude": 105.8294815274053,
        "latitude": 21.006532364435746,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7573,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82941715439254,
          21.00624691406491
        ]
      },
      "properties": {
        "tenDoiTuon": "KHTL 9",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 43 Chùa Bộc",
        "tuDien": "KHTL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KHTL",
        "diaChiLapD": "",
        "longitude": 105.82941715439254,
        "latitude": 21.00624691406491,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7574,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82923476417628,
          21.00585128896311
        ]
      },
      "properties": {
        "tenDoiTuon": "KHTL 10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 43 Chùa Bộc",
        "tuDien": "KHTL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KHTL",
        "diaChiLapD": "",
        "longitude": 105.82923476417628,
        "latitude": 21.00585128896311,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7575,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82910467704689,
          21.005638452240635
        ]
      },
      "properties": {
        "tenDoiTuon": "KHTL 11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 43 Chùa Bộc",
        "tuDien": "KHTL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KHTL",
        "diaChiLapD": "",
        "longitude": 105.82910467704689,
        "latitude": 21.005638452240635,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7576,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82985703666887,
          21.006514836797866
        ]
      },
      "properties": {
        "tenDoiTuon": "KHTL 7-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 43 Chùa Bộc",
        "tuDien": "KHTL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KHTL",
        "diaChiLapD": "",
        "longitude": 105.82985703666887,
        "latitude": 21.006514836797866,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7577,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82976852376846,
          21.00655364799415
        ]
      },
      "properties": {
        "tenDoiTuon": "KHTL 7-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 43 Chùa Bộc",
        "tuDien": "KHTL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KHTL",
        "diaChiLapD": "",
        "longitude": 105.82976852376846,
        "latitude": 21.00655364799415,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7578,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82897727211625,
          21.005425615214595
        ]
      },
      "properties": {
        "tenDoiTuon": "KHTL 12",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 43 Chùa Bộc",
        "tuDien": "KHTL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KHTL",
        "diaChiLapD": "",
        "longitude": 105.82897727211625,
        "latitude": 21.005425615214595,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7579,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82887823669473,
          21.00522308268481
        ]
      },
      "properties": {
        "tenDoiTuon": "KHTL 13",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 43 Chùa Bộc",
        "tuDien": "KHTL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KHTL",
        "diaChiLapD": "",
        "longitude": 105.82887823669473,
        "latitude": 21.00522308268481,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7580,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82894642670927,
          21.00481840202921
        ]
      },
      "properties": {
        "tenDoiTuon": "KHTL 16",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 43/59 Chùa Bộc",
        "tuDien": "KHTL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KHTL",
        "diaChiLapD": "",
        "longitude": 105.82894642670927,
        "latitude": 21.00481840202921,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7581,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82873319109495,
          21.004941096851006
        ]
      },
      "properties": {
        "tenDoiTuon": "KHTL 14",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 43 Chùa Bộc",
        "tuDien": "KHTL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KHTL",
        "diaChiLapD": "",
        "longitude": 105.82873319109495,
        "latitude": 21.004941096851006,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7582,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82860846837207,
          21.00471073097966
        ]
      },
      "properties": {
        "tenDoiTuon": "KHTL 15",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 43 Chùa Bộc",
        "tuDien": "KHTL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KHTL",
        "diaChiLapD": "",
        "longitude": 105.82860846837207,
        "latitude": 21.00471073097966,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7583,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82741208078843,
          21.016497140282926
        ]
      },
      "properties": {
        "tenDoiTuon": "31-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Đèn khác",
        "tenDuongPh": "Hoàng Cầu",
        "tuDien": "VH1/6",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "VH1/6",
        "diaChiLapD": "",
        "longitude": 105.82741208078843,
        "latitude": 21.016497140282926,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7584,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82940106113712,
          21.006374615614085
        ]
      },
      "properties": {
        "tenDoiTuon": "KHTL 4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 45 Chùa Bộc",
        "tuDien": "KHTL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KHTL",
        "diaChiLapD": "",
        "longitude": 105.82940106113712,
        "latitude": 21.006374615614085,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7585,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82914356906812,
          21.005888848353056
        ]
      },
      "properties": {
        "tenDoiTuon": "KHTL 3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 45 Chùa Bộc",
        "tuDien": "KHTL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KHTL",
        "diaChiLapD": "",
        "longitude": 105.82914356906812,
        "latitude": 21.005888848353056,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7586,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82902555187358,
          21.005633444313947
        ]
      },
      "properties": {
        "tenDoiTuon": "KHTL 2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 45 Chùa Bộc",
        "tuDien": "KHTL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KHTL",
        "diaChiLapD": "",
        "longitude": 105.82902555187358,
        "latitude": 21.005633444313947,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7587,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82892631014221,
          21.005425615213742
        ]
      },
      "properties": {
        "tenDoiTuon": "KHTL 1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 45 Chùa Bộc",
        "tuDien": "KHTL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KHTL",
        "diaChiLapD": "",
        "longitude": 105.82892631014221,
        "latitude": 21.005425615213742,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7588,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82738121933953,
          21.01644280437096
        ]
      },
      "properties": {
        "tenDoiTuon": "31-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Đèn khác",
        "tenDuongPh": "Hoàng Cầu",
        "tuDien": "VH1/6",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "VH1/6",
        "diaChiLapD": "",
        "longitude": 105.82738121933953,
        "latitude": 21.01644280437096,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7589,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82746165637907,
          21.016586786226007
        ]
      },
      "properties": {
        "tenDoiTuon": "31-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Đèn khác",
        "tenDuongPh": "Hoàng Cầu",
        "tuDien": "VH1/6",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "VH1/6",
        "diaChiLapD": "",
        "longitude": 105.82746165637907,
        "latitude": 21.016586786226007,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7590,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82743727401555,
          21.01654314194514
        ]
      },
      "properties": {
        "tenDoiTuon": "31-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Đèn khác",
        "tenDuongPh": "Hoàng Cầu",
        "tuDien": "VH1/6",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "VH1/6",
        "diaChiLapD": "",
        "longitude": 105.82743727401555,
        "latitude": 21.01654314194514,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7591,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82731683989483,
          21.01654971967399
        ]
      },
      "properties": {
        "tenDoiTuon": "31-7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Đèn khác",
        "tenDuongPh": "Hoàng Cầu",
        "tuDien": "VH1/6",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "VH1/6",
        "diaChiLapD": "",
        "longitude": 105.82731683989483,
        "latitude": 21.01654971967399,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7592,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82736641548544,
          21.016639365585487
        ]
      },
      "properties": {
        "tenDoiTuon": "31-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Đèn khác",
        "tenDuongPh": "Hoàng Cầu",
        "tuDien": "VH1/6",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "VH1/6",
        "diaChiLapD": "",
        "longitude": 105.82736641548544,
        "latitude": 21.016639365585487,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7593,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82734203312197,
          21.016595721319998
        ]
      },
      "properties": {
        "tenDoiTuon": "31-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Đèn khác",
        "tenDuongPh": "Hoàng Cầu",
        "tuDien": "VH1/6",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "VH1/6",
        "diaChiLapD": "",
        "longitude": 105.82734203312197,
        "latitude": 21.016595721319998,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7594,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82728597844591,
          21.016495383781198
        ]
      },
      "properties": {
        "tenDoiTuon": "31-8",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Đèn khác",
        "tenDuongPh": "Hoàng Cầu",
        "tuDien": "VH1/6",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "VH1/6",
        "diaChiLapD": "",
        "longitude": 105.82728597844591,
        "latitude": 21.016495383781198,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7595,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8343287164935,
          21.01328747258681
        ]
      },
      "properties": {
        "tenDoiTuon": "HĐ 25",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 89 Xã Đàn",
        "tuDien": "HĐA",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Trạm Hồ Đấu",
        "diaChiLapD": "",
        "longitude": 105.8343287164935,
        "latitude": 21.01328747258681,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7596,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83444673368805,
          21.01330750326484
        ]
      },
      "properties": {
        "tenDoiTuon": "24",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 89 Xã Đàn",
        "tuDien": "HĐA",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Trạm Hồ Đấu",
        "diaChiLapD": "",
        "longitude": 105.83444673368805,
        "latitude": 21.01330750326484,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7597,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83461303064891,
          21.013161028873633
        ]
      },
      "properties": {
        "tenDoiTuon": "23",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 89 Xã Đàn",
        "tuDien": "HĐA",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Trạm Hồ Đấu",
        "diaChiLapD": "",
        "longitude": 105.83461303064891,
        "latitude": 21.013161028873633,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7598,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83466801593455,
          21.013002035140946
        ]
      },
      "properties": {
        "tenDoiTuon": "22",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 89 Xã Đàn",
        "tuDien": "HĐA",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Trạm Hồ Đấu",
        "diaChiLapD": "",
        "longitude": 105.83466801593455,
        "latitude": 21.013002035140946,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7599,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83473238894734,
          21.012950706419453
        ]
      },
      "properties": {
        "tenDoiTuon": "21",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 89 Xã Đàn",
        "tuDien": "HĐA",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Trạm Hồ Đấu",
        "diaChiLapD": "",
        "longitude": 105.83473238894734,
        "latitude": 21.012950706419453,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7600,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83454999874002,
          21.01281925473447
        ]
      },
      "properties": {
        "tenDoiTuon": "HĐ 21-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 89 Xã Đàn",
        "tuDien": "HĐA",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "Trạm Hồ Đấu",
        "diaChiLapD": "",
        "longitude": 105.83454999874002,
        "latitude": 21.01281925473447,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7601,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83486918161401,
          21.012741635590128
        ]
      },
      "properties": {
        "tenDoiTuon": "20",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 89 Xã Đàn",
        "tuDien": "HĐA",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Trạm Hồ Đấu",
        "diaChiLapD": "",
        "longitude": 105.83486918161401,
        "latitude": 21.012741635590128,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7602,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83498049328922,
          21.012888110393195
        ]
      },
      "properties": {
        "tenDoiTuon": "20-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 89 Xã Đàn",
        "tuDien": "HĐA",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "Trạm Hồ Đấu",
        "diaChiLapD": "",
        "longitude": 105.83498049328922,
        "latitude": 21.012888110393195,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7603,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82654582715035,
          21.016015766930376
        ]
      },
      "properties": {
        "tenDoiTuon": "02",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - đèn chùm",
        "tenDuongPh": "Vườn Hoa 1/6",
        "tuDien": "VH1/6",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "NOUVO",
        "chieuCao": 3,
        "tuDien2": "VH1/6",
        "diaChiLapD": "",
        "longitude": 105.82654582715035,
        "latitude": 21.016015766930376,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7604,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82661100482902,
          21.016152223371197
        ]
      },
      "properties": {
        "tenDoiTuon": "01",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - đèn chùm",
        "tenDuongPh": "Vườn Hoa 1/6",
        "tuDien": "VH1/6",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "NOUVO",
        "chieuCao": 3,
        "tuDien2": "VH1/6",
        "diaChiLapD": "",
        "longitude": 105.82661100482902,
        "latitude": 21.016152223371197,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7605,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82686379870619,
          21.016034673486544
        ]
      },
      "properties": {
        "tenDoiTuon": "03",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - đèn chùm",
        "tenDuongPh": "Vườn Hoa 1/6",
        "tuDien": "VH1/6",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "NOUVO",
        "chieuCao": 3,
        "tuDien2": "VH1/6",
        "diaChiLapD": "",
        "longitude": 105.82686379870619,
        "latitude": 21.016034673486544,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7606,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82679393148933,
          21.01591561533421
        ]
      },
      "properties": {
        "tenDoiTuon": "04",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 214,
        "loaiChaoCh": "Trong ngõ - đèn chùm",
        "tenDuongPh": "Vườn Hoa 1/6",
        "tuDien": "VH1/6",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "NOUVO",
        "chieuCao": 3,
        "tuDien2": "VH1/6",
        "diaChiLapD": "",
        "longitude": 105.82679393148933,
        "latitude": 21.01591561533421,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7607,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82915429790809,
          21.00552326988687
        ]
      },
      "properties": {
        "tenDoiTuon": "KHTL 11-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 43/43 Chùa Bộc",
        "tuDien": "KHTL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KHTL",
        "diaChiLapD": "",
        "longitude": 105.82915429790809,
        "latitude": 21.00552326988687,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7608,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8291046770379,
          21.00481965401711
        ]
      },
      "properties": {
        "tenDoiTuon": "KHTL 17",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 43/59 Chùa Bộc",
        "tuDien": "KHTL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KHTL",
        "diaChiLapD": "",
        "longitude": 105.8291046770379,
        "latitude": 21.00481965401711,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7609,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82948823293366,
          21.005440639013397
        ]
      },
      "properties": {
        "tenDoiTuon": "KHTL 22-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 43/59 Chùa Bộc",
        "tuDien": "KHTL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KHTL",
        "diaChiLapD": "",
        "longitude": 105.82948823293366,
        "latitude": 21.005440639013397,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7610,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82953919489873,
          21.005517009973442
        ]
      },
      "properties": {
        "tenDoiTuon": "KHTL 33",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 43/43 Chùa Bộc",
        "tuDien": "KHTL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KHTL",
        "diaChiLapD": "",
        "longitude": 105.82953919489873,
        "latitude": 21.005517009973442,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7611,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82965050657391,
          21.0054694346257
        ]
      },
      "properties": {
        "tenDoiTuon": "KHTL 32",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 43/43 Chùa Bộc",
        "tuDien": "KHTL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KHTL",
        "diaChiLapD": "",
        "longitude": 105.82965050657391,
        "latitude": 21.0054694346257,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7612,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8296961041235,
          21.005567089270137
        ]
      },
      "properties": {
        "tenDoiTuon": "KHTL 17",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 43/43 Chùa Bộc",
        "tuDien": "KHTL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KHTL",
        "diaChiLapD": "",
        "longitude": 105.8296961041235,
        "latitude": 21.005567089270137,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7613,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82959149797662,
          21.005614664586762
        ]
      },
      "properties": {
        "tenDoiTuon": "KHTL 17",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 43/43 Chùa Bộc",
        "tuDien": "KHTL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KHTL",
        "diaChiLapD": "",
        "longitude": 105.82959149797662,
        "latitude": 21.005614664586762,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7614,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82921732981697,
          21.0053530001589
        ]
      },
      "properties": {
        "tenDoiTuon": "KHTL 19",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 43/43 Chùa Bộc",
        "tuDien": "KHTL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KHTL",
        "diaChiLapD": "",
        "longitude": 105.82921732981697,
        "latitude": 21.0053530001589,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7615,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82942788322353,
          21.00526786522203
        ]
      },
      "properties": {
        "tenDoiTuon": "KHTL 22-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 43/43 Chùa Bộc",
        "tuDien": "KHTL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KHTL",
        "diaChiLapD": "",
        "longitude": 105.82942788322353,
        "latitude": 21.00526786522203,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7616,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82971890290726,
          21.00533547238166
        ]
      },
      "properties": {
        "tenDoiTuon": "KHTL 22-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 43/43 Chùa Bộc",
        "tuDien": "KHTL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KHTL",
        "diaChiLapD": "",
        "longitude": 105.82971890290726,
        "latitude": 21.00533547238166,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7617,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83005954345221,
          21.005340480318335
        ]
      },
      "properties": {
        "tenDoiTuon": "KHTL 22-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 43/43 Chùa Bộc",
        "tuDien": "KHTL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KHTL",
        "diaChiLapD": "",
        "longitude": 105.83005954345221,
        "latitude": 21.005340480318335,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7618,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.829598203496,
          21.005395567609103
        ]
      },
      "properties": {
        "tenDoiTuon": "KHTL 20",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 43/43 Chùa Bộc",
        "tuDien": "KHTL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KHTL",
        "diaChiLapD": "",
        "longitude": 105.829598203496,
        "latitude": 21.005395567609103,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7619,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8296692820371,
          21.005216533839533
        ]
      },
      "properties": {
        "tenDoiTuon": "KHTL 21",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 43/43 Chùa Bộc",
        "tuDien": "KHTL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KHTL",
        "diaChiLapD": "",
        "longitude": 105.8296692820371,
        "latitude": 21.005216533839533,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7620,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82972829063435,
          21.004666911344096
        ]
      },
      "properties": {
        "tenDoiTuon": "KHTL 24",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 43/43 Chùa Bộc",
        "tuDien": "KHTL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KHTL",
        "diaChiLapD": "",
        "longitude": 105.82972829063435,
        "latitude": 21.004666911344096,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7621,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82985837777272,
          21.004909797160828
        ]
      },
      "properties": {
        "tenDoiTuon": "KHTL 23",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 43/43 Chùa Bộc",
        "tuDien": "KHTL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KHTL",
        "diaChiLapD": "",
        "longitude": 105.82985837777272,
        "latitude": 21.004909797160828,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7622,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82994152624866,
          21.00512013095104
        ]
      },
      "properties": {
        "tenDoiTuon": "KHTL 22",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 43/43 Chùa Bộc",
        "tuDien": "KHTL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KHTL",
        "diaChiLapD": "",
        "longitude": 105.82994152624866,
        "latitude": 21.00512013095104,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7623,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82950566728397,
          21.0060215581225
        ]
      },
      "properties": {
        "tenDoiTuon": "KHTL 36",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "TT Viện năng lượng ( ngõ 4 TTT)",
        "tuDien": "KHTL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KHTL",
        "diaChiLapD": "",
        "longitude": 105.82950566728397,
        "latitude": 21.0060215581225,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7624,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.829371556834,
          21.005983998765945
        ]
      },
      "properties": {
        "tenDoiTuon": "KHTL 37",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "TT Viện năng lượng",
        "tuDien": "KHTL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KHTL",
        "diaChiLapD": "",
        "longitude": 105.829371556834,
        "latitude": 21.005983998765945,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7625,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82885120829849,
          21.007247239943293
        ]
      },
      "properties": {
        "tenDoiTuon": "KHTL 25",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Chùa Bộc",
        "tuDien": "KHTL",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "KHTL",
        "diaChiLapD": "",
        "longitude": 105.82885120829849,
        "latitude": 21.007247239943293,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7626,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82914625128485,
          21.00711703520209
        ]
      },
      "properties": {
        "tenDoiTuon": "KHTL 26",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Chùa Bộc",
        "tuDien": "KHTL",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "KHTL",
        "diaChiLapD": "",
        "longitude": 105.82914625128485,
        "latitude": 21.00711703520209,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7627,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82939837893836,
          21.006991838228508
        ]
      },
      "properties": {
        "tenDoiTuon": "KHTL 27",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Chùa Bộc",
        "tuDien": "KHTL",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "KHTL",
        "diaChiLapD": "",
        "longitude": 105.82939837893836,
        "latitude": 21.006991838228508,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7628,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82961832007203,
          21.00688667269007
        ]
      },
      "properties": {
        "tenDoiTuon": "KHTL 28",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 217,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Chùa Bộc",
        "tuDien": "KHTL",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "KHTL",
        "diaChiLapD": "",
        "longitude": 105.82961832007203,
        "latitude": 21.00688667269007,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7629,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82990531643966,
          21.006757719606252
        ]
      },
      "properties": {
        "tenDoiTuon": "KHTL 29",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Chùa Bộc",
        "tuDien": "KHTL",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "KHTL",
        "diaChiLapD": "",
        "longitude": 105.82990531643966,
        "latitude": 21.006757719606252,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7630,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83018761893813,
          21.006625949471427
        ]
      },
      "properties": {
        "tenDoiTuon": "KHTL 30",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Chùa Bộc",
        "tuDien": "KHTL",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "KHTL",
        "diaChiLapD": "",
        "longitude": 105.83018761893813,
        "latitude": 21.006625949471427,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7631,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8304699214366,
          21.00649417922023
        ]
      },
      "properties": {
        "tenDoiTuon": "KHTL 31",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Chùa Bộc",
        "tuDien": "KHTL",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "KHTL",
        "diaChiLapD": "",
        "longitude": 105.8304699214366,
        "latitude": 21.00649417922023,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7632,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83067511042542,
          21.006375867589757
        ]
      },
      "properties": {
        "tenDoiTuon": "KHTL 31-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Chùa Bộc",
        "tuDien": "KHTL",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "KHTL",
        "diaChiLapD": "",
        "longitude": 105.83067511042542,
        "latitude": 21.006375867589757,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7633,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83089773377581,
          21.00625567790025
        ]
      },
      "properties": {
        "tenDoiTuon": "KHTL 31-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Đường phố - đèn pha",
        "tenDuongPh": "Chùa Bộc",
        "tuDien": "KHTL",
        "chuDauTu": "SoXayDung",
        "loaiCot": "BTLT",
        "chieuCao": 10,
        "tuDien2": "KHTL",
        "diaChiLapD": "",
        "longitude": 105.83089773377581,
        "latitude": 21.00625567790025,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7634,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83642978986678,
          21.00985256284457
        ]
      },
      "properties": {
        "tenDoiTuon": "B8AKL/11-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 21 Phạm Ngọc Thạch",
        "tuDien": "B8AKL",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "B8A Kim Liên",
        "diaChiLapD": "",
        "longitude": 105.83642978986678,
        "latitude": 21.00985256284457,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7635,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83668459972803,
          21.010061637722245
        ]
      },
      "properties": {
        "tenDoiTuon": "B8AKL/11-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 21 Phạm Ngọc Thạch",
        "tuDien": "B8AKL",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "B8A Kim Liên",
        "diaChiLapD": "",
        "longitude": 105.83668459972803,
        "latitude": 21.010061637722245,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7636,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83630909046448,
          21.007132058829715
        ]
      },
      "properties": {
        "tenDoiTuon": "B8AKL/20",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 5 Hoàng Tích Trí",
        "tuDien": "B8AKL",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "B8A Kim Liên",
        "diaChiLapD": "",
        "longitude": 105.83630909046448,
        "latitude": 21.007132058829715,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7637,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83667269743678,
          21.00955319089715
        ]
      },
      "properties": {
        "tenDoiTuon": "B8AKL/12-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 21 Phạm Ngọc Thạch",
        "tuDien": "B8AKL",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "B8A Kim Liên",
        "diaChiLapD": "",
        "longitude": 105.83667269743678,
        "latitude": 21.00955319089715,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7638,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83691946066585,
          21.009763518141614
        ]
      },
      "properties": {
        "tenDoiTuon": "B8AKL/12-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "NGõ 21 Phạm Ngọc Thạch",
        "tuDien": "B8AKL",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "B8A Kim Liên",
        "diaChiLapD": "",
        "longitude": 105.83691946066585,
        "latitude": 21.009763518141614,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7639,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83725456912474,
          21.00952330061749
        ]
      },
      "properties": {
        "tenDoiTuon": "B8AKL/10-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Nhà B1 TT Kim Liên",
        "tuDien": "B8AKL",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "B8A Kim Liên",
        "diaChiLapD": "",
        "longitude": 105.83725456912474,
        "latitude": 21.00952330061749,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7640,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83705072123752,
          21.00936805885929
        ]
      },
      "properties": {
        "tenDoiTuon": "B8AKL/10-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Nhà B1 TT Kim Liên",
        "tuDien": "B8AKL",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "B8A Kim Liên",
        "diaChiLapD": "",
        "longitude": 105.83705072123752,
        "latitude": 21.00936805885929,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7641,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8368468733593,
          21.009215320841587
        ]
      },
      "properties": {
        "tenDoiTuon": "B8AkL/10-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Nhà B1 TT Kim Liên",
        "tuDien": "B8AKL",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "B8A Kim Liên",
        "diaChiLapD": "",
        "longitude": 105.8368468733593,
        "latitude": 21.009215320841587,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7642,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83570961672872,
          21.00774552242087
        ]
      },
      "properties": {
        "tenDoiTuon": "B8AKL/17",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 5 Hoàng Tích Trí",
        "tuDien": "B8AKL",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "B8A Kim Liên",
        "diaChiLapD": "",
        "longitude": 105.83570961672872,
        "latitude": 21.00774552242087,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7643,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8359054179927,
          21.00752768053729
        ]
      },
      "properties": {
        "tenDoiTuon": "B8AKL/18",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 5 Hoàng Tích Trí",
        "tuDien": "B8AKL",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "B8A Kim Liên",
        "diaChiLapD": "",
        "longitude": 105.8359054179927,
        "latitude": 21.00752768053729,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7644,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83610926587991,
          21.007314846206057
        ]
      },
      "properties": {
        "tenDoiTuon": "B8AKL/19",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 5 Hoàng Tích Trí",
        "tuDien": "B8AKL",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "B8A Kim Liên",
        "diaChiLapD": "",
        "longitude": 105.83610926587991,
        "latitude": 21.007314846206057,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7645,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8347413392847,
          21.004593043926892
        ]
      },
      "properties": {
        "tenDoiTuon": "KLC7/6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 156 Phương Mai",
        "tuDien": "KLC7",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KLC7",
        "diaChiLapD": "",
        "longitude": 105.8347413392847,
        "latitude": 21.004593043926892,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7646,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83453883250138,
          21.00458427999387
        ]
      },
      "properties": {
        "tenDoiTuon": "KLC7/9",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 156 Phương Mai",
        "tuDien": "KLC7",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KLC7",
        "diaChiLapD": "",
        "longitude": 105.83453883250138,
        "latitude": 21.00458427999387,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7647,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83416600544555,
          21.004481616740915
        ]
      },
      "properties": {
        "tenDoiTuon": "KLC7/10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 156 Phương Mai",
        "tuDien": "KLC7",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KLC7",
        "diaChiLapD": "",
        "longitude": 105.83416600544555,
        "latitude": 21.004481616740915,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7648,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83437655886111,
          21.00490604119518
        ]
      },
      "properties": {
        "tenDoiTuon": "KLC7/3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 156 Phương Mai",
        "tuDien": "KLC7",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KLC7",
        "diaChiLapD": "",
        "longitude": 105.83437655886111,
        "latitude": 21.00490604119518,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7649,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83399568518207,
          21.00481089009491
        ]
      },
      "properties": {
        "tenDoiTuon": "KLC7/4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 156 Phương Mai",
        "tuDien": "KLC7",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KLC7",
        "diaChiLapD": "",
        "longitude": 105.83399568518207,
        "latitude": 21.00481089009491,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7650,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8348754497347,
          21.005028735944922
        ]
      },
      "properties": {
        "tenDoiTuon": "KLC7/2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 156 Phương Mai",
        "tuDien": "KLC7",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KLC7",
        "diaChiLapD": "",
        "longitude": 105.8348754497347,
        "latitude": 21.005028735944922,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7651,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83525900563043,
          21.005131398821486
        ]
      },
      "properties": {
        "tenDoiTuon": "KLC7/1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 156 Phương Mai",
        "tuDien": "KLC7",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KLC7",
        "diaChiLapD": "",
        "longitude": 105.83525900563043,
        "latitude": 21.005131398821486,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7652,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83498005589051,
          21.00467317128985
        ]
      },
      "properties": {
        "tenDoiTuon": "KLC7/8",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 156 Phương Mai",
        "tuDien": "KLC7",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KLC7",
        "diaChiLapD": "",
        "longitude": 105.83498005589051,
        "latitude": 21.00467317128985,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7653,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83536092956955,
          21.00475830656597
        ]
      },
      "properties": {
        "tenDoiTuon": "KLC7/7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 156 Phương Mai",
        "tuDien": "KLC7",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KLC7",
        "diaChiLapD": "",
        "longitude": 105.83536092956955,
        "latitude": 21.00475830656597,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7654,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83484460433668,
          21.004257510125104
        ]
      },
      "properties": {
        "tenDoiTuon": "KLC7/15",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 156 Phương Mai",
        "tuDien": "KLC7",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KLC7",
        "diaChiLapD": "",
        "longitude": 105.83484460433668,
        "latitude": 21.004257510125104,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7655,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83506588658318,
          21.0043075898453
        ]
      },
      "properties": {
        "tenDoiTuon": "KLC7/17",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 156 Phương Mai",
        "tuDien": "KLC7",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KLC7",
        "diaChiLapD": "",
        "longitude": 105.83506588658318,
        "latitude": 21.0043075898453,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7656,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83546285351764,
          21.004406497242304
        ]
      },
      "properties": {
        "tenDoiTuon": "KLC7/16",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 156 Phương Mai",
        "tuDien": "KLC7",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KLC7",
        "diaChiLapD": "",
        "longitude": 105.83546285351764,
        "latitude": 21.004406497242304,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7657,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83493714054869,
          21.003962039437145
        ]
      },
      "properties": {
        "tenDoiTuon": "KLC7/18",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 156 Phương Mai",
        "tuDien": "KLC7",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KLC7",
        "diaChiLapD": "",
        "longitude": 105.83493714054869,
        "latitude": 21.003962039437145,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7658,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83458308896505,
          21.003869391728422
        ]
      },
      "properties": {
        "tenDoiTuon": "KLC7/19",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 156 Phương Mai",
        "tuDien": "KLC7",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KLC7",
        "diaChiLapD": "",
        "longitude": 105.83458308896505,
        "latitude": 21.003869391728422,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7659,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83522547802468,
          21.00393825151772
        ]
      },
      "properties": {
        "tenDoiTuon": "KLC7/21",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 156 Phương Mai",
        "tuDien": "KLC7",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KLC7",
        "diaChiLapD": "",
        "longitude": 105.83522547802468,
        "latitude": 21.00393825151772,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7660,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83559025844828,
          21.00402714319848
        ]
      },
      "properties": {
        "tenDoiTuon": "KLC7/20",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 156 Phương Mai",
        "tuDien": "KLC7",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KLC7",
        "diaChiLapD": "",
        "longitude": 105.83559025844828,
        "latitude": 21.00402714319848,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7661,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83485533317662,
          21.00380804388911
        ]
      },
      "properties": {
        "tenDoiTuon": "KLC7/22",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 156 Phương Mai",
        "tuDien": "KLC7",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KLC7",
        "diaChiLapD": "",
        "longitude": 105.83485533317662,
        "latitude": 21.00380804388911,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7662,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83440338095652,
          21.003696616117008
        ]
      },
      "properties": {
        "tenDoiTuon": "KLC7/23",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 167 Phương Mai",
        "tuDien": "KLC7",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KLC7",
        "diaChiLapD": "",
        "longitude": 105.83440338095652,
        "latitude": 21.003696616117008,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7663,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83407212814764,
          21.003655300179812
        ]
      },
      "properties": {
        "tenDoiTuon": "KLC7/24",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 167 Phương Mai",
        "tuDien": "KLC7",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KLC7",
        "diaChiLapD": "",
        "longitude": 105.83407212814764,
        "latitude": 21.003655300179812,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7664,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83412309011271,
          21.00348753232023
        ]
      },
      "properties": {
        "tenDoiTuon": "KLC7/25",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 167 Phương Mai",
        "tuDien": "KLC7",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KLC7",
        "diaChiLapD": "",
        "longitude": 105.83412309011271,
        "latitude": 21.00348753232023,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7665,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83436180671852,
          21.003297228250077
        ]
      },
      "properties": {
        "tenDoiTuon": "KLC7/48",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 167 Phương Mai",
        "tuDien": "KLC7",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KLC7",
        "diaChiLapD": "",
        "longitude": 105.83436180671852,
        "latitude": 21.003297228250077,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7666,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83388973793134,
          21.003149492028157
        ]
      },
      "properties": {
        "tenDoiTuon": "KLC7/50",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 167 Phương Mai",
        "tuDien": "KLC7",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KLC7",
        "diaChiLapD": "",
        "longitude": 105.83388973793134,
        "latitude": 21.003149492028157,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7667,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8340520115806,
          21.003287212239407
        ]
      },
      "properties": {
        "tenDoiTuon": "KLC7/49",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 167 Phương Mai",
        "tuDien": "KLC7",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KLC7",
        "diaChiLapD": "",
        "longitude": 105.8340520115806,
        "latitude": 21.003287212239407,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7668,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83384950479726,
          21.00339738831741
        ]
      },
      "properties": {
        "tenDoiTuon": "KLC7/26",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 167 Phương Mai",
        "tuDien": "KLC7",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KLC7",
        "diaChiLapD": "",
        "longitude": 105.83384950479726,
        "latitude": 21.00339738831741,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7669,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83362688144689,
          21.003275944227212
        ]
      },
      "properties": {
        "tenDoiTuon": "KLC7/27",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 167 Phương Mai",
        "tuDien": "KLC7",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KLC7",
        "diaChiLapD": "",
        "longitude": 105.83362688144689,
        "latitude": 21.003275944227212,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7670,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83372075876277,
          21.003508816312927
        ]
      },
      "properties": {
        "tenDoiTuon": "KLC7/28",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 167 Phương Mai",
        "tuDien": "KLC7",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KLC7",
        "diaChiLapD": "",
        "longitude": 105.83372075876277,
        "latitude": 21.003508816312927,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7671,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83384950479726,
          21.003747948022323
        ]
      },
      "properties": {
        "tenDoiTuon": "KLC7/29",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 167 Phương Mai",
        "tuDien": "KLC7",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KLC7",
        "diaChiLapD": "",
        "longitude": 105.83384950479726,
        "latitude": 21.003747948022323,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7672,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83372478207438,
          21.00362775621617
        ]
      },
      "properties": {
        "tenDoiTuon": "KLC7/30",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 167 Phương Mai",
        "tuDien": "KLC7",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KLC7",
        "diaChiLapD": "",
        "longitude": 105.83372478207438,
        "latitude": 21.00362775621617,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7673,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.833533004131,
          21.003789263933026
        ]
      },
      "properties": {
        "tenDoiTuon": "KLC7/31",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 167 Phương Mai",
        "tuDien": "KLC7",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KLC7",
        "diaChiLapD": "",
        "longitude": 105.833533004131,
        "latitude": 21.003789263933026,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7674,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83333452066825,
          21.00396329143308
        ]
      },
      "properties": {
        "tenDoiTuon": "KLC7/32",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 167 Phương Mai",
        "tuDien": "KLC7",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KLC7",
        "diaChiLapD": "",
        "longitude": 105.83333452066825,
        "latitude": 21.00396329143308,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7675,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83339084705777,
          21.004154846647324
        ]
      },
      "properties": {
        "tenDoiTuon": "KLC7/39",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 41 Đông Tác",
        "tuDien": "KLC7",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KLC7",
        "diaChiLapD": "",
        "longitude": 105.83339084705777,
        "latitude": 21.004154846647324,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7676,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83314944824421,
          21.004363929509147
        ]
      },
      "properties": {
        "tenDoiTuon": "KLC7/40",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 41 Đông Tác",
        "tuDien": "KLC7",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KLC7",
        "diaChiLapD": "",
        "longitude": 105.83314944824421,
        "latitude": 21.004363929509147,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7677,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83327819427872,
          21.004620587712367
        ]
      },
      "properties": {
        "tenDoiTuon": "KLC7/41",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 41 Đông Tác",
        "tuDien": "KLC7",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KLC7",
        "diaChiLapD": "",
        "longitude": 105.83327819427872,
        "latitude": 21.004620587712367,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7678,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83319638690666,
          21.004072215016105
        ]
      },
      "properties": {
        "tenDoiTuon": "KLC7/34",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 41 Đông Tác",
        "tuDien": "KLC7",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KLC7",
        "diaChiLapD": "",
        "longitude": 105.83319638690666,
        "latitude": 21.004072215016105,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7679,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83306764087216,
          21.004104766876694
        ]
      },
      "properties": {
        "tenDoiTuon": "KLC7/35",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 41 Đông Tác",
        "tuDien": "KLC7",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KLC7",
        "diaChiLapD": "",
        "longitude": 105.83306764087216,
        "latitude": 21.004104766876694,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7680,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83301399669037,
          21.00431510180161
        ]
      },
      "properties": {
        "tenDoiTuon": "KLC7/38",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 41 Đông Tác",
        "tuDien": "KLC7",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KLC7",
        "diaChiLapD": "",
        "longitude": 105.83301399669037,
        "latitude": 21.00431510180161,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7681,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83301131448263,
          21.00400961526552
        ]
      },
      "properties": {
        "tenDoiTuon": "KLC7/37",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 41 Đông Tác",
        "tuDien": "KLC7",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KLC7",
        "diaChiLapD": "",
        "longitude": 105.83301131448263,
        "latitude": 21.00400961526552,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7682,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83280076107606,
          21.003957031454217
        ]
      },
      "properties": {
        "tenDoiTuon": "KLC7/36",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 41 Đông Tác",
        "tuDien": "KLC7",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KLC7",
        "diaChiLapD": "",
        "longitude": 105.83280076107606,
        "latitude": 21.003957031454217,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7683,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83264921626679,
          21.00397455939338
        ]
      },
      "properties": {
        "tenDoiTuon": "KLC7/42",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 41 Đông Tác",
        "tuDien": "KLC7",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KLC7",
        "diaChiLapD": "",
        "longitude": 105.83264921626679,
        "latitude": 21.00397455939338,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7684,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8327283414311,
          21.004197414439325
        ]
      },
      "properties": {
        "tenDoiTuon": "KLC7/43",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 41 Đông Tác",
        "tuDien": "KLC7",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KLC7",
        "diaChiLapD": "",
        "longitude": 105.8327283414311,
        "latitude": 21.004197414439325,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7685,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83283160647407,
          21.004482868731632
        ]
      },
      "properties": {
        "tenDoiTuon": "KLC7/45",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 41 Đông Tác",
        "tuDien": "KLC7",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KLC7",
        "diaChiLapD": "",
        "longitude": 105.83283160647407,
        "latitude": 21.004482868731632,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7686,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8327846678206,
          21.00434640161654
        ]
      },
      "properties": {
        "tenDoiTuon": "KLC7/44",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 41 Đông Tác",
        "tuDien": "KLC7",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KLC7",
        "diaChiLapD": "",
        "longitude": 105.8327846678206,
        "latitude": 21.00434640161654,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7687,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83290804943066,
          21.00482340997905
        ]
      },
      "properties": {
        "tenDoiTuon": "KLC7/46",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 41 Đông Tác",
        "tuDien": "KLC7",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KLC7",
        "diaChiLapD": "",
        "longitude": 105.83290804943066,
        "latitude": 21.00482340997905,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7688,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83296437582021,
          21.005052523690523
        ]
      },
      "properties": {
        "tenDoiTuon": "KLC7/47",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 41 Đông Tác",
        "tuDien": "KLC7",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KLC7",
        "diaChiLapD": "",
        "longitude": 105.83296437582021,
        "latitude": 21.005052523690523,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7689,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83280344328381,
          21.005275377126647
        ]
      },
      "properties": {
        "tenDoiTuon": "KLC7/48",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 41 Đông Tác",
        "tuDien": "KLC7",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KLC7",
        "diaChiLapD": "",
        "longitude": 105.83280344328381,
        "latitude": 21.005275377126647,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7690,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83276723346134,
          21.005468182639888
        ]
      },
      "properties": {
        "tenDoiTuon": "KLC7/49",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 41 Đông Tác",
        "tuDien": "KLC7",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KLC7",
        "diaChiLapD": "",
        "longitude": 105.83276723346134,
        "latitude": 21.005468182639888,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7691,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83323662004973,
          21.005711067152408
        ]
      },
      "properties": {
        "tenDoiTuon": "KLC7/12-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 37+39 Đông Tác",
        "tuDien": "KLC7",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "KLC7",
        "diaChiLapD": "",
        "longitude": 105.83323662004973,
        "latitude": 21.005711067152408,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7692,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83323125562526,
          21.00559212890968
        ]
      },
      "properties": {
        "tenDoiTuon": "KLC7/12-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 37+39 Đông Tác",
        "tuDien": "KLC7",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KLC7",
        "diaChiLapD": "",
        "longitude": 105.83323125562526,
        "latitude": 21.00559212890968,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7693,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8330609353528,
          21.005587120981428
        ]
      },
      "properties": {
        "tenDoiTuon": "KLC7/12-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 37+39 Đông Tác",
        "tuDien": "KLC7",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 6,
        "tuDien2": "KLC7",
        "diaChiLapD": "",
        "longitude": 105.8330609353528,
        "latitude": 21.005587120981428,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7694,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83344985566403,
          21.005558325391835
        ]
      },
      "properties": {
        "tenDoiTuon": "KLC7/12-7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 37+39 Đông Tác",
        "tuDien": "KLC7",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KLC7",
        "diaChiLapD": "",
        "longitude": 105.83344985566403,
        "latitude": 21.005558325391835,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7695,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83325271330517,
          21.005470686605623
        ]
      },
      "properties": {
        "tenDoiTuon": "KLC7/12-8",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 37+39 Đông Tác",
        "tuDien": "KLC7",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "KLC7",
        "diaChiLapD": "",
        "longitude": 105.83325271330517,
        "latitude": 21.005470686605623,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7696,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83359469495397,
          21.00574111469352
        ]
      },
      "properties": {
        "tenDoiTuon": "KLC7/12-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 19 Đông Tác",
        "tuDien": "KLC7",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KLC7",
        "diaChiLapD": "",
        "longitude": 105.83359469495397,
        "latitude": 21.00574111469352,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7697,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83360810599268,
          21.005439387026517
        ]
      },
      "properties": {
        "tenDoiTuon": "KLC7/12-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 19 Đông Tác",
        "tuDien": "KLC7",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KLC7",
        "diaChiLapD": "",
        "longitude": 105.83360810599268,
        "latitude": 21.005439387026517,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7698,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8337006422047,
          21.005156438535785
        ]
      },
      "properties": {
        "tenDoiTuon": "KLC7/12-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 156 Phương Mai",
        "tuDien": "KLC7",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KLC7",
        "diaChiLapD": "",
        "longitude": 105.8337006422047,
        "latitude": 21.005156438535785,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7699,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83381463608764,
          21.004753298608914
        ]
      },
      "properties": {
        "tenDoiTuon": "KLC7/12",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 156 Phương Mai",
        "tuDien": "KLC7",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 6,
        "tuDien2": "KLC7",
        "diaChiLapD": "",
        "longitude": 105.83381463608764,
        "latitude": 21.004753298608914,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7700,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83391086034203,
          21.004710417979528
        ]
      },
      "properties": {
        "tenDoiTuon": "KLC7/11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 156 Phương Mai",
        "tuDien": "KLC7",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KLC7",
        "diaChiLapD": "",
        "longitude": 105.83391086034203,
        "latitude": 21.004710417979528,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7701,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83387498578878,
          21.004222454311215
        ]
      },
      "properties": {
        "tenDoiTuon": "KLC7/13",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 156 Phương Mai",
        "tuDien": "KLC7",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KLC7",
        "diaChiLapD": "",
        "longitude": 105.83387498578878,
        "latitude": 21.004222454311215,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7702,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83399434409618,
          21.004106018870566
        ]
      },
      "properties": {
        "tenDoiTuon": "KLC7/14",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 156 Phương Mai",
        "tuDien": "KLC7",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KLC7",
        "diaChiLapD": "",
        "longitude": 105.83399434409618,
        "latitude": 21.004106018870566,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7703,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83052993587526,
          21.00617711635982
        ]
      },
      "properties": {
        "tenDoiTuon": "KHTL 38",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 1 Chùa Bộc",
        "tuDien": "KHTL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KHTL",
        "diaChiLapD": "",
        "longitude": 105.83052993587526,
        "latitude": 21.00617711635982,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7704,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8304119186807,
          21.006207163807094
        ]
      },
      "properties": {
        "tenDoiTuon": "KHTL 39",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 226,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 1 Chùa Bộc",
        "tuDien": "KHTL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KHTL",
        "diaChiLapD": "",
        "longitude": 105.8304119186807,
        "latitude": 21.006207163807094,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7705,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83309848627017,
          21.00377549196042
        ]
      },
      "properties": {
        "tenDoiTuon": "KLC7/33",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 41 Đông Tác",
        "tuDien": "KLC7",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "KLC7",
        "diaChiLapD": "",
        "longitude": 105.83309848627017,
        "latitude": 21.00377549196042,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7706,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.834109679074,
          21.007064452483327
        ]
      },
      "properties": {
        "tenDoiTuon": "B4KL 6-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Nhà B15 - B19",
        "tuDien": "B4KL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "B4 Kim Liên",
        "diaChiLapD": "",
        "longitude": 105.834109679074,
        "latitude": 21.007064452483327,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7707,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.79966449612168,
          21.023123598004123
        ]
      },
      "properties": {
        "tenDoiTuon": "BQLL 16",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "BQLL",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 10,
        "tuDien2": "BQLL",
        "diaChiLapD": "",
        "longitude": 105.79966449612168,
        "latitude": 21.023123598004123,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7708,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.79948076480153,
          21.023275069943466
        ]
      },
      "properties": {
        "tenDoiTuon": "15",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "BQLL",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 10,
        "tuDien2": "BQLL",
        "diaChiLapD": "",
        "longitude": 105.79948076480153,
        "latitude": 21.023275069943466,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7709,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.79928898685813,
          21.023431549059033
        ]
      },
      "properties": {
        "tenDoiTuon": "14",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "BQLL",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 10,
        "tuDien2": "BQLL",
        "diaChiLapD": "",
        "longitude": 105.79928898685813,
        "latitude": 21.023431549059033,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7710,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.79909720891474,
          21.02359178350222
        ]
      },
      "properties": {
        "tenDoiTuon": "13",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "BQLL",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 10,
        "tuDien2": "BQLL",
        "diaChiLapD": "",
        "longitude": 105.79909720891474,
        "latitude": 21.02359178350222,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7711,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.79891615980235,
          21.023747010455498
        ]
      },
      "properties": {
        "tenDoiTuon": "12",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "BQLL",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 10,
        "tuDien2": "BQLL",
        "diaChiLapD": "",
        "longitude": 105.79891615980235,
        "latitude": 21.023747010455498,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7712,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.79873242848218,
          21.02390724456045
        ]
      },
      "properties": {
        "tenDoiTuon": "11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "BQLL",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 10,
        "tuDien2": "BQLL",
        "diaChiLapD": "",
        "longitude": 105.79873242848218,
        "latitude": 21.02390724456045,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7713,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.79855540269035,
          21.024074989454334
        ]
      },
      "properties": {
        "tenDoiTuon": "10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "BQLL",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 10,
        "tuDien2": "BQLL",
        "diaChiLapD": "",
        "longitude": 105.79855540269035,
        "latitude": 21.024074989454334,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7714,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.79855540269035,
          21.025900141845078
        ]
      },
      "properties": {
        "tenDoiTuon": "BQLL 01",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "BQLL",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 10,
        "tuDien2": "BQLL",
        "diaChiLapD": "",
        "longitude": 105.79855540269035,
        "latitude": 21.025900141845078,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7715,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.79847493642215,
          21.025718629061863
        ]
      },
      "properties": {
        "tenDoiTuon": "02",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "BQLL",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 10,
        "tuDien2": "BQLL",
        "diaChiLapD": "",
        "longitude": 105.79847493642215,
        "latitude": 21.025718629061863,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7716,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.79839170743024,
          21.02550764208254
        ]
      },
      "properties": {
        "tenDoiTuon": "03",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "BQLL",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 10,
        "tuDien2": "BQLL",
        "diaChiLapD": "",
        "longitude": 105.79839170743024,
        "latitude": 21.02550764208254,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7717,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.79831660557751,
          21.025279811610048
        ]
      },
      "properties": {
        "tenDoiTuon": "04",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "BQLL",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 10,
        "tuDien2": "BQLL",
        "diaChiLapD": "",
        "longitude": 105.79831660557751,
        "latitude": 21.025279811610048,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7718,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.79828710127438,
          21.025107060799094
        ]
      },
      "properties": {
        "tenDoiTuon": "05",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "BQLL",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 10,
        "tuDien2": "BQLL",
        "diaChiLapD": "",
        "longitude": 105.79828710127438,
        "latitude": 21.025107060799094,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7719,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.79825525004173,
          21.02485388035406
        ]
      },
      "properties": {
        "tenDoiTuon": "06",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "BQLL",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 10,
        "tuDien2": "BQLL",
        "diaChiLapD": "",
        "longitude": 105.79825525004173,
        "latitude": 21.02485388035406,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7720,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.79825901859114,
          21.024662095096016
        ]
      },
      "properties": {
        "tenDoiTuon": "07",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "BQLL",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 10,
        "tuDien2": "BQLL",
        "diaChiLapD": "",
        "longitude": 105.79825901859114,
        "latitude": 21.024662095096016,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7721,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.79832875602835,
          21.02442925603559
        ]
      },
      "properties": {
        "tenDoiTuon": "08",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "BQLL",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 10,
        "tuDien2": "BQLL",
        "diaChiLapD": "",
        "longitude": 105.79832875602835,
        "latitude": 21.02442925603559,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7722,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.79842799775972,
          21.02425274876301
        ]
      },
      "properties": {
        "tenDoiTuon": "09",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "BQLL",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 10,
        "tuDien2": "BQLL",
        "diaChiLapD": "",
        "longitude": 105.79842799775972,
        "latitude": 21.02425274876301,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7723,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80058985822387,
          21.023596790825952
        ]
      },
      "properties": {
        "tenDoiTuon": "BQLL 20",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 1002 Đường Láng",
        "tuDien": "BQLL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 6,
        "tuDien2": "BQLL",
        "diaChiLapD": "",
        "longitude": 105.80058985822387,
        "latitude": 21.023596790825952,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7724,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80044501894291,
          21.02358176885509
        ]
      },
      "properties": {
        "tenDoiTuon": "BQLL 19",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 1002 Đường Láng",
        "tuDien": "BQLL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "BQLL",
        "diaChiLapD": "",
        "longitude": 105.80044501894291,
        "latitude": 21.02358176885509,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7725,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80014595263596,
          21.023500399817706
        ]
      },
      "properties": {
        "tenDoiTuon": "18",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 1002 Đường Láng",
        "tuDien": "BQLL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "BQLL",
        "diaChiLapD": "",
        "longitude": 105.80014595263596,
        "latitude": 21.023500399817706,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7726,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80041953795138,
          21.023834638503182
        ]
      },
      "properties": {
        "tenDoiTuon": "19-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 1002 Đường Láng",
        "tuDien": "BQLL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "BQLL",
        "diaChiLapD": "",
        "longitude": 105.80041953795138,
        "latitude": 21.023834638503182,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7727,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80059656375218,
          21.023859675078526
        ]
      },
      "properties": {
        "tenDoiTuon": "19-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 1002 Đường Láng",
        "tuDien": "BQLL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "BQLL",
        "diaChiLapD": "",
        "longitude": 105.80059656375218,
        "latitude": 21.023859675078526,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7728,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80058851711999,
          21.023997376168662
        ]
      },
      "properties": {
        "tenDoiTuon": "19-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 1002 Đường Láng",
        "tuDien": "BQLL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "BQLL",
        "diaChiLapD": "",
        "longitude": 105.80058851711999,
        "latitude": 21.023997376168662,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7729,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80082857482968,
          21.02402241271668
        ]
      },
      "properties": {
        "tenDoiTuon": "BQLL 19-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 1002 Đường Láng",
        "tuDien": "BQLL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "BQLL",
        "diaChiLapD": "",
        "longitude": 105.80082857482968,
        "latitude": 21.02402241271668,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7730,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80003464096974,
          21.023695685433307
        ]
      },
      "properties": {
        "tenDoiTuon": "18-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 1002 Đường Láng",
        "tuDien": "BQLL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "BQLL",
        "diaChiLapD": "",
        "longitude": 105.80003464096974,
        "latitude": 21.023695685433307,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7731,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.79998367899569,
          21.023923518327738
        ]
      },
      "properties": {
        "tenDoiTuon": "18-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 1002 Đường Láng",
        "tuDien": "BQLL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "BQLL",
        "diaChiLapD": "",
        "longitude": 105.79998367899569,
        "latitude": 21.023923518327738,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7732,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.79994344586159,
          21.024118803389044
        ]
      },
      "properties": {
        "tenDoiTuon": "18-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 1002 Đường Láng",
        "tuDien": "BQLL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "BQLL",
        "diaChiLapD": "",
        "longitude": 105.79994344586159,
        "latitude": 21.024118803389044,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7733,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80005341643292,
          21.024274029792732
        ]
      },
      "properties": {
        "tenDoiTuon": "18-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 1002 Đường Láng",
        "tuDien": "BQLL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 6,
        "tuDien2": "BQLL",
        "diaChiLapD": "",
        "longitude": 105.80005341643292,
        "latitude": 21.024274029792732,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7734,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80015265816428,
          21.024116299735542
        ]
      },
      "properties": {
        "tenDoiTuon": "18-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 1002 Đường Láng",
        "tuDien": "BQLL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "BQLL",
        "diaChiLapD": "",
        "longitude": 105.80015265816428,
        "latitude": 21.024116299735542,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7735,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.79988443726434,
          21.024218949474324
        ]
      },
      "properties": {
        "tenDoiTuon": "BQLL 18-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 1002 Đường Láng",
        "tuDien": "BQLL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 6,
        "tuDien2": "BQLL",
        "diaChiLapD": "",
        "longitude": 105.79988443726434,
        "latitude": 21.024218949474324,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7736,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.79990857713399,
          21.023242522267203
        ]
      },
      "properties": {
        "tenDoiTuon": "BQLL 17",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 1002 Đường Láng",
        "tuDien": "BQLL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "BQLL",
        "diaChiLapD": "",
        "longitude": 105.79990857713399,
        "latitude": 21.023242522267203,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7737,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.79968620854584,
          21.02322149771899
        ]
      },
      "properties": {
        "tenDoiTuon": "BQLL 16-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 1008 Đường Láng",
        "tuDien": "BQLL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "BQLL",
        "diaChiLapD": "",
        "longitude": 105.79968620854584,
        "latitude": 21.02322149771899,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7738,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.79953466373654,
          21.023430553853238
        ]
      },
      "properties": {
        "tenDoiTuon": "BQLL 16-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 1008 Đường Láng",
        "tuDien": "BQLL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "BQLL",
        "diaChiLapD": "",
        "longitude": 105.79953466373654,
        "latitude": 21.023430553853238,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7739,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.79967950301749,
          21.023582025480703
        ]
      },
      "properties": {
        "tenDoiTuon": "BQLL 16-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 1008 Đường Láng",
        "tuDien": "BQLL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "BQLL",
        "diaChiLapD": "",
        "longitude": 105.79967950301749,
        "latitude": 21.023582025480703,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7740,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.79952259379272,
          21.023751022570362
        ]
      },
      "properties": {
        "tenDoiTuon": "BQLL 16-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 1008 Đường Láng",
        "tuDien": "BQLL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "BQLL",
        "diaChiLapD": "",
        "longitude": 105.79952259379272,
        "latitude": 21.023751022570362,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7741,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.79969157296132,
          21.023910004842513
        ]
      },
      "properties": {
        "tenDoiTuon": "BQLL 16-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 1008 Đường Láng",
        "tuDien": "BQLL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "BQLL",
        "diaChiLapD": "",
        "longitude": 105.79969157296132,
        "latitude": 21.023910004842513,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7742,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.79865456391471,
          21.024140654020208
        ]
      },
      "properties": {
        "tenDoiTuon": "BQLL 10-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 1074 Đường Láng",
        "tuDien": "BQLL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "BQLL",
        "diaChiLapD": "",
        "longitude": 105.79865456391471,
        "latitude": 21.024140654020208,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7743,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.79894692469334,
          21.02430839865145
        ]
      },
      "properties": {
        "tenDoiTuon": "10-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 1074 Đường Láng",
        "tuDien": "BQLL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "BQLL",
        "diaChiLapD": "",
        "longitude": 105.79894692469334,
        "latitude": 21.02430839865145,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7744,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.79906225968014,
          21.024170697848636
        ]
      },
      "properties": {
        "tenDoiTuon": "BQLL 10-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 1074 Đường Láng",
        "tuDien": "BQLL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 6,
        "tuDien2": "BQLL",
        "diaChiLapD": "",
        "longitude": 105.79906225968014,
        "latitude": 21.024170697848636,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7745,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.79872430134294,
          21.024578792584915
        ]
      },
      "properties": {
        "tenDoiTuon": "BQLL 8-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 1096 Đường Láng",
        "tuDien": "BQLL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "BQLL",
        "diaChiLapD": "",
        "longitude": 105.79872430134294,
        "latitude": 21.024578792584915,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7746,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.79886645842514,
          21.02466892378707
        ]
      },
      "properties": {
        "tenDoiTuon": "BQLL 8-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 1096 Đường Láng",
        "tuDien": "BQLL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 6,
        "tuDien2": "BQLL",
        "diaChiLapD": "",
        "longitude": 105.79886645842514,
        "latitude": 21.02466892378707,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7747,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.79855532217435,
          21.025174658966087
        ]
      },
      "properties": {
        "tenDoiTuon": "BQLL 4-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 1132 Đường Láng",
        "tuDien": "BQLL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "BQLL",
        "diaChiLapD": "",
        "longitude": 105.79855532217435,
        "latitude": 21.025174658966087,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7748,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.79871223140138,
          21.025172155330715
        ]
      },
      "properties": {
        "tenDoiTuon": "4-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 1132 Đường Láng",
        "tuDien": "BQLL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "BQLL",
        "diaChiLapD": "",
        "longitude": 105.79871223140138,
        "latitude": 21.025172155330715,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7749,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.79872430134294,
          21.02502444077517
        ]
      },
      "properties": {
        "tenDoiTuon": "BQLL 4-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 1132 Đường Láng",
        "tuDien": "BQLL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "BQLL",
        "diaChiLapD": "",
        "longitude": 105.79872430134294,
        "latitude": 21.02502444077517,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7750,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.79853922891891,
          21.02541751138748
        ]
      },
      "properties": {
        "tenDoiTuon": "BQLL 3-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 1142 Đường Láng",
        "tuDien": "BQLL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "BQLL",
        "diaChiLapD": "",
        "longitude": 105.79853922891891,
        "latitude": 21.02541751138748,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7751,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.79857409763754,
          21.025309855208867
        ]
      },
      "properties": {
        "tenDoiTuon": "BQLL 3-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 1142 Đường Láng",
        "tuDien": "BQLL",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 6,
        "tuDien2": "BQLL",
        "diaChiLapD": "",
        "longitude": 105.79857409763754,
        "latitude": 21.025309855208867,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7752,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80144261340931,
          21.027435955083703
        ]
      },
      "properties": {
        "tenDoiTuon": "VH1 12",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Nghách 1194/103 Đường Láng",
        "tuDien": "VHA1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VH1",
        "diaChiLapD": "",
        "longitude": 105.80144261340931,
        "latitude": 21.027435955083703,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7753,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80183748418203,
          21.027219110478676
        ]
      },
      "properties": {
        "tenDoiTuon": "VH1 12-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Nghách 1194/63 Đường Láng",
        "tuDien": "VHA1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "VH1",
        "diaChiLapD": "",
        "longitude": 105.80183748418203,
        "latitude": 21.027219110478676,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7754,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80221374849444,
          21.026985306893504
        ]
      },
      "properties": {
        "tenDoiTuon": "VH1 12-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Nghách 1194/63 Đường Láng",
        "tuDien": "VHA1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VH1",
        "diaChiLapD": "",
        "longitude": 105.80221374849444,
        "latitude": 21.026985306893504,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7755,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80244140135329,
          21.026850683428545
        ]
      },
      "properties": {
        "tenDoiTuon": "VH1 12-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Nghách 1194/63 Đường Láng",
        "tuDien": "VHA1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "VH1",
        "diaChiLapD": "",
        "longitude": 105.80244140135329,
        "latitude": 21.026850683428545,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7756,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80261956717524,
          21.026723066453666
        ]
      },
      "properties": {
        "tenDoiTuon": "VH1 12-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Nghách 1194/63 Đường Láng",
        "tuDien": "VHA1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "VH1",
        "diaChiLapD": "",
        "longitude": 105.80261956717524,
        "latitude": 21.026723066453666,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7757,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80264118592689,
          21.02707729300726
        ]
      },
      "properties": {
        "tenDoiTuon": "VH1 12-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Nghách 1194/63 Đường Láng",
        "tuDien": "VHA1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "VH1",
        "diaChiLapD": "",
        "longitude": 105.80264118592689,
        "latitude": 21.02707729300726,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7758,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8387311251874,
          21.011357394653352
        ]
      },
      "properties": {
        "tenDoiTuon": "4-6-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 220,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 150 Kim Hoa",
        "tuDien": "HBTT",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Hợp Bộ Trung Tự",
        "diaChiLapD": "",
        "longitude": 105.8387311251874,
        "latitude": 21.011357394653352,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7759,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8355895878649,
          21.023525067159472
        ]
      },
      "properties": {
        "tenDoiTuon": "VH5 01",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Văn Hương",
        "tuDien": "VHG5",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Văn Hương 5",
        "diaChiLapD": "",
        "longitude": 105.8355895878649,
        "latitude": 21.023525067159472,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7760,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83534148353198,
          21.023628969136205
        ]
      },
      "properties": {
        "tenDoiTuon": "02",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Văn Hương",
        "tuDien": "VHG5",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Văn Hương 5",
        "diaChiLapD": "",
        "longitude": 105.83534148353198,
        "latitude": 21.023628969136205,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7761,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83509874361454,
          21.023736626529583
        ]
      },
      "properties": {
        "tenDoiTuon": "03",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Văn Hương",
        "tuDien": "VHG5",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Văn Hương 5",
        "diaChiLapD": "",
        "longitude": 105.83509874361454,
        "latitude": 21.023736626529583,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7762,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8349056245673,
          21.023789203368036
        ]
      },
      "properties": {
        "tenDoiTuon": "04",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Văn Hương",
        "tuDien": "VHG5",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Văn Hương 5",
        "diaChiLapD": "",
        "longitude": 105.8349056245673,
        "latitude": 21.023789203368036,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7763,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83452206867153,
          21.023893105160692
        ]
      },
      "properties": {
        "tenDoiTuon": "05",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Văn Hương",
        "tuDien": "VHG5",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Văn Hương 5",
        "diaChiLapD": "",
        "longitude": 105.83452206867153,
        "latitude": 21.023893105160692,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7764,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83437454718283,
          21.023961955706202
        ]
      },
      "properties": {
        "tenDoiTuon": "06",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Văn Hương",
        "tuDien": "VHG5",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Văn Hương 5",
        "diaChiLapD": "",
        "longitude": 105.83437454718283,
        "latitude": 21.023961955706202,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7765,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83423239010065,
          21.024187284541398
        ]
      },
      "properties": {
        "tenDoiTuon": "07",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Văn Hương",
        "tuDien": "VHG5",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Văn Hương 5",
        "diaChiLapD": "",
        "longitude": 105.83423239010065,
        "latitude": 21.024187284541398,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7766,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83394539373752,
          21.024450167752672
        ]
      },
      "properties": {
        "tenDoiTuon": "08",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Văn Hương",
        "tuDien": "VHG5",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Văn Hương 5",
        "diaChiLapD": "",
        "longitude": 105.83394539373752,
        "latitude": 21.024450167752672,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7767,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83367046730922,
          21.02452402533349
        ]
      },
      "properties": {
        "tenDoiTuon": "09",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Văn Hương",
        "tuDien": "VHG5",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Văn Hương 5",
        "diaChiLapD": "",
        "longitude": 105.83367046730922,
        "latitude": 21.02452402533349,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7768,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83340224640925,
          21.024604141989478
        ]
      },
      "properties": {
        "tenDoiTuon": "10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Văn Hương",
        "tuDien": "VHG5",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Văn Hương 5",
        "diaChiLapD": "",
        "longitude": 105.83340224640925,
        "latitude": 21.024604141989478,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7769,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83317533714552,
          21.024675431271106
        ]
      },
      "properties": {
        "tenDoiTuon": "VH5 11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Văn Hương",
        "tuDien": "VHG5",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Văn Hương 5",
        "diaChiLapD": "",
        "longitude": 105.83317533714552,
        "latitude": 21.024675431271106,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7770,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83554667251407,
          21.023438690762276
        ]
      },
      "properties": {
        "tenDoiTuon": "1-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "TT Bộ Công An",
        "tuDien": "VHG5",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "Văn Hương 5",
        "diaChiLapD": "",
        "longitude": 105.83554667251407,
        "latitude": 21.023438690762276,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7771,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8354568185098,
          21.023307248322315
        ]
      },
      "properties": {
        "tenDoiTuon": "1-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "TT Bộ Công An",
        "tuDien": "VHG5",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Văn Hương 5",
        "diaChiLapD": "",
        "longitude": 105.8354568185098,
        "latitude": 21.023307248322315,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7772,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83525565283932,
          21.023356069813662
        ]
      },
      "properties": {
        "tenDoiTuon": "1-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "TT Bộ Công An",
        "tuDien": "VHG5",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "Văn Hương 5",
        "diaChiLapD": "",
        "longitude": 105.83525565283932,
        "latitude": 21.023356069813662,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7773,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83567944186018,
          21.02372160457198
        ]
      },
      "properties": {
        "tenDoiTuon": "1-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 107 Văn hương",
        "tuDien": "VHG5",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 7,
        "tuDien2": "Văn Hương 5",
        "diaChiLapD": "",
        "longitude": 105.83567944186018,
        "latitude": 21.02372160457198,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7774,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83584976213264,
          21.023853046645762
        ]
      },
      "properties": {
        "tenDoiTuon": "VH5 1-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 107 Văn hương",
        "tuDien": "VHG5",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "Văn Hương 5",
        "diaChiLapD": "",
        "longitude": 105.83584976213264,
        "latitude": 21.023853046645762,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7775,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83554130810758,
          21.023716597251596
        ]
      },
      "properties": {
        "tenDoiTuon": "1-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 107 Văn hương",
        "tuDien": "VHG5",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Văn Hương 5",
        "diaChiLapD": "",
        "longitude": 105.83554130810758,
        "latitude": 21.023716597251596,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7776,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80121194343567,
          21.02744471767393
        ]
      },
      "properties": {
        "tenDoiTuon": "VH1 13",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Nghách 1194/63 Đường Láng",
        "tuDien": "VHA1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VH1",
        "diaChiLapD": "",
        "longitude": 105.80121194343567,
        "latitude": 21.02744471767393,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7777,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80112161395202,
          21.027172382180414
        ]
      },
      "properties": {
        "tenDoiTuon": "VH1 13-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Nghách 1194/50 Đường Láng",
        "tuDien": "VHA1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VH1",
        "diaChiLapD": "",
        "longitude": 105.80112161395202,
        "latitude": 21.027172382180414,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7778,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80108206803956,
          21.026906614121465
        ]
      },
      "properties": {
        "tenDoiTuon": "VH1 13-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Nghách 1194/50 Đường Láng",
        "tuDien": "VHA1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VH1",
        "diaChiLapD": "",
        "longitude": 105.80108206803956,
        "latitude": 21.026906614121465,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7779,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83514165895639,
          21.023914386240907
        ]
      },
      "properties": {
        "tenDoiTuon": "3-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 93 Văn Hương",
        "tuDien": "VHG5",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "Văn Hương 5",
        "diaChiLapD": "",
        "longitude": 105.83514165895639,
        "latitude": 21.023914386240907,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7780,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83533075469204,
          21.0239193935538
        ]
      },
      "properties": {
        "tenDoiTuon": "3-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 93 Văn Hương",
        "tuDien": "VHG5",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "Văn Hương 5",
        "diaChiLapD": "",
        "longitude": 105.83533075469204,
        "latitude": 21.0239193935538,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7781,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83549571054002,
          21.023941926459333
        ]
      },
      "properties": {
        "tenDoiTuon": "3-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 93 Văn Hương",
        "tuDien": "VHG5",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "Văn Hương 5",
        "diaChiLapD": "",
        "longitude": 105.83549571054002,
        "latitude": 21.023941926459333,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7782,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83518189209049,
          21.024050835453185
        ]
      },
      "properties": {
        "tenDoiTuon": "3-1-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 93 Văn Hương",
        "tuDien": "VHG5",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "Văn Hương 5",
        "diaChiLapD": "",
        "longitude": 105.83518189209049,
        "latitude": 21.024050835453185,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7783,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.82596682777576,
          21.004316666794697
        ]
      },
      "properties": {
        "tenDoiTuon": "KTC 1-7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 238,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 123 Khương Thượng",
        "tuDien": "KTC",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 5,
        "tuDien2": "Khương Thượng C",
        "diaChiLapD": "",
        "longitude": 105.82596682777576,
        "latitude": 21.004316666794697,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7784,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83502364175285,
          21.023611443506613
        ]
      },
      "properties": {
        "tenDoiTuon": "3-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 100 Văn Hương",
        "tuDien": "VHG5",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Văn Hương 5",
        "diaChiLapD": "",
        "longitude": 105.83502364175285,
        "latitude": 21.023611443506613,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7785,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83503437059281,
          21.023412402283547
        ]
      },
      "properties": {
        "tenDoiTuon": "3-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 100 Văn Hương",
        "tuDien": "VHG5",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Văn Hương 5",
        "diaChiLapD": "",
        "longitude": 105.83503437059281,
        "latitude": 21.023412402283547,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7786,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83484929816878,
          21.023442446258716
        ]
      },
      "properties": {
        "tenDoiTuon": "3-8",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 100 Văn Hương",
        "tuDien": "VHG5",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Văn Hương 5",
        "diaChiLapD": "",
        "longitude": 105.83484929816878,
        "latitude": 21.023442446258716,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7787,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83494183438079,
          21.02334981064951
        ]
      },
      "properties": {
        "tenDoiTuon": "3-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 100 Văn Hương",
        "tuDien": "VHG5",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Văn Hương 5",
        "diaChiLapD": "",
        "longitude": 105.83494183438079,
        "latitude": 21.02334981064951,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7788,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83477821962768,
          21.02336483264373
        ]
      },
      "properties": {
        "tenDoiTuon": "3-9",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 100 Văn Hương",
        "tuDien": "VHG5",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "Văn Hương 5",
        "diaChiLapD": "",
        "longitude": 105.83477821962768,
        "latitude": 21.02336483264373,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7789,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83464276807382,
          21.023387365633077
        ]
      },
      "properties": {
        "tenDoiTuon": "3-10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 100 Văn Hương",
        "tuDien": "VHG5",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "Văn Hương 5",
        "diaChiLapD": "",
        "longitude": 105.83464276807382,
        "latitude": 21.023387365633077,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7790,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8348747791603,
          21.023268441484678
        ]
      },
      "properties": {
        "tenDoiTuon": "VH5 3-7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 100 Văn Hương",
        "tuDien": "VHG5",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "Văn Hương 5",
        "diaChiLapD": "",
        "longitude": 105.8348747791603,
        "latitude": 21.023268441484678,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7791,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80101109908514,
          21.027489969010585
        ]
      },
      "properties": {
        "tenDoiTuon": "VH1 14",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Nghách 1194/63 Đường Láng",
        "tuDien": "VHA1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VH1",
        "diaChiLapD": "",
        "longitude": 105.80101109908514,
        "latitude": 21.027489969010585,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7792,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80062185744498,
          21.027606199600672
        ]
      },
      "properties": {
        "tenDoiTuon": "VH1 15",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Nghách 1194/63 Đường Láng",
        "tuDien": "VHA1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VH1",
        "diaChiLapD": "",
        "longitude": 105.80062185744498,
        "latitude": 21.027606199600672,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7793,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80067818383452,
          21.027862817806824
        ]
      },
      "properties": {
        "tenDoiTuon": "VH1 15-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Nghách 1194/21 Đường Láng",
        "tuDien": "VHA1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VH1",
        "diaChiLapD": "",
        "longitude": 105.80067818383452,
        "latitude": 21.027862817806824,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7794,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80084582371494,
          21.027832883250575
        ]
      },
      "properties": {
        "tenDoiTuon": "VH1 15-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Nghách 1194/21 Đường Láng",
        "tuDien": "VHA1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VH1",
        "diaChiLapD": "",
        "longitude": 105.80084582371494,
        "latitude": 21.027832883250575,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7795,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80100729962243,
          21.027824997727357
        ]
      },
      "properties": {
        "tenDoiTuon": "VH1 15-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Nghách 1194/21 Đường Láng",
        "tuDien": "VHA1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VH1",
        "diaChiLapD": "",
        "longitude": 105.80100729962243,
        "latitude": 21.027824997727357,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7796,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83496731536331,
          21.02397071849981
        ]
      },
      "properties": {
        "tenDoiTuon": "4-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 87 Văn Hương",
        "tuDien": "VHG5",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "Văn Hương 5",
        "diaChiLapD": "",
        "longitude": 105.83496731536331,
        "latitude": 21.02397071849981,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7797,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83493378775758,
          21.02409464939416
        ]
      },
      "properties": {
        "tenDoiTuon": "4-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 87 Văn Hương",
        "tuDien": "VHG5",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "Văn Hương 5",
        "diaChiLapD": "",
        "longitude": 105.83493378775758,
        "latitude": 21.02409464939416,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7798,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83504966600385,
          21.0242543202547
        ]
      },
      "properties": {
        "tenDoiTuon": "4-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 87 Văn Hương",
        "tuDien": "VHG5",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 7,
        "tuDien2": "Văn Hương 5",
        "diaChiLapD": "",
        "longitude": 105.83504966600385,
        "latitude": 21.0242543202547,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7799,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83474335091805,
          21.02369656797344
        ]
      },
      "properties": {
        "tenDoiTuon": "4-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 84 Văn Hương",
        "tuDien": "VHG5",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "Văn Hương 5",
        "diaChiLapD": "",
        "longitude": 105.83474335091805,
        "latitude": 21.02369656797344,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7800,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83463874476222,
          21.023829261898467
        ]
      },
      "properties": {
        "tenDoiTuon": "5-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 80 Văn Hương",
        "tuDien": "VHG5",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "Văn Hương 5",
        "diaChiLapD": "",
        "longitude": 105.83463874476222,
        "latitude": 21.023829261898467,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7801,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83451536315218,
          21.023740382018556
        ]
      },
      "properties": {
        "tenDoiTuon": "5-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 78 Văn Hương",
        "tuDien": "VHG5",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "Văn Hương 5",
        "diaChiLapD": "",
        "longitude": 105.83451536315218,
        "latitude": 21.023740382018556,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7802,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80045019606864,
          21.0276399981213
        ]
      },
      "properties": {
        "tenDoiTuon": "VH1 16",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Nghách 1194/63 Đường Láng",
        "tuDien": "VHA1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VH1",
        "diaChiLapD": "",
        "longitude": 105.80045019606864,
        "latitude": 21.0276399981213,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7803,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80053208715951,
          21.02785309713111
        ]
      },
      "properties": {
        "tenDoiTuon": "VH1 16-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Nghách 1194/9 Đường Láng",
        "tuDien": "VHA1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 10,
        "tuDien2": "VH1",
        "diaChiLapD": "",
        "longitude": 105.80053208715951,
        "latitude": 21.02785309713111,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7804,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8346783073399,
          21.024031803234582
        ]
      },
      "properties": {
        "tenDoiTuon": "5-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 71 Văn Hương",
        "tuDien": "VHG5",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "Văn Hương 5",
        "diaChiLapD": "",
        "longitude": 105.8346783073399,
        "latitude": 21.024031803234582,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7805,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83475475029648,
          21.02413445303153
        ]
      },
      "properties": {
        "tenDoiTuon": "5-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 71 Văn Hương",
        "tuDien": "VHG5",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "Văn Hương 5",
        "diaChiLapD": "",
        "longitude": 105.83475475029648,
        "latitude": 21.02413445303153,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7806,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83452005701125,
          21.024071861700662
        ]
      },
      "properties": {
        "tenDoiTuon": "5-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 69 Văn Hương",
        "tuDien": "VHG5",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 7,
        "tuDien2": "Văn Hương 5",
        "diaChiLapD": "",
        "longitude": 105.83452005701125,
        "latitude": 21.024071861700662,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7807,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80020463771348,
          21.027690845859844
        ]
      },
      "properties": {
        "tenDoiTuon": "VH1 17",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Nghách 1194/63 Đường Láng",
        "tuDien": "VHA1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VH1",
        "diaChiLapD": "",
        "longitude": 105.80020463771348,
        "latitude": 21.027690845859844,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7808,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80028694390109,
          21.027894869145356
        ]
      },
      "properties": {
        "tenDoiTuon": "VH1 17-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Nghách 1194/7 Đường Láng",
        "tuDien": "VHA1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VH1",
        "diaChiLapD": "",
        "longitude": 105.80028694390109,
        "latitude": 21.027894869145356,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7809,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83435174839009,
          21.02433349329052
        ]
      },
      "properties": {
        "tenDoiTuon": "7-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 53 Văn Hương",
        "tuDien": "VHG5",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Văn Hương 5",
        "diaChiLapD": "",
        "longitude": 105.83435174839009,
        "latitude": 21.02433349329052,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7810,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83447513000911,
          21.024512504113584
        ]
      },
      "properties": {
        "tenDoiTuon": "7-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 53 Văn Hương",
        "tuDien": "VHG5",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 7,
        "tuDien2": "Văn Hương 5",
        "diaChiLapD": "",
        "longitude": 105.83447513000911,
        "latitude": 21.024512504113584,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7811,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83469909446336,
          21.02452627416819
        ]
      },
      "properties": {
        "tenDoiTuon": "7-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 53 Văn Hương",
        "tuDien": "VHG5",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Văn Hương 5",
        "diaChiLapD": "",
        "longitude": 105.83469909446336,
        "latitude": 21.02452627416819,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7812,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83476749078773,
          21.024663974642543
        ]
      },
      "properties": {
        "tenDoiTuon": "VH5 7-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 53 Văn Hương",
        "tuDien": "VHG5",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Văn Hương 5",
        "diaChiLapD": "",
        "longitude": 105.83476749078773,
        "latitude": 21.024663974642543,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7813,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80013369541135,
          21.027681307414532
        ]
      },
      "properties": {
        "tenDoiTuon": "VH1 18",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Nghách 1194/63 Đường Láng",
        "tuDien": "VHA1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VH1",
        "diaChiLapD": "",
        "longitude": 105.80013369541135,
        "latitude": 21.027681307414532,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7814,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80000360827299,
          21.02732579676248
        ]
      },
      "properties": {
        "tenDoiTuon": "VH1 18-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Nghách 1194/6 Đường Láng",
        "tuDien": "VHA1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VH1",
        "diaChiLapD": "",
        "longitude": 105.80000360827299,
        "latitude": 21.02732579676248,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7815,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80027704763144,
          21.02724301915852
        ]
      },
      "properties": {
        "tenDoiTuon": "VH1 18-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Nghách 1194/6 Đường Láng",
        "tuDien": "VHA1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VH1",
        "diaChiLapD": "",
        "longitude": 105.80027704763144,
        "latitude": 21.02724301915852,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7816,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83417606370213,
          21.02441361004979
        ]
      },
      "properties": {
        "tenDoiTuon": "8-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 47 Văn Hương",
        "tuDien": "VHG5",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "Văn Hương 5",
        "diaChiLapD": "",
        "longitude": 105.83417606370213,
        "latitude": 21.02441361004979,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7817,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.79864919948125,
          21.026057187780967
        ]
      },
      "properties": {
        "tenDoiTuon": "VHA2 17",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "VHA2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 10,
        "tuDien2": "VHA2",
        "diaChiLapD": "",
        "longitude": 105.79864919948125,
        "latitude": 21.026057187780967,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7818,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.79897374677074,
          21.026625508534796
        ]
      },
      "properties": {
        "tenDoiTuon": "14",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "VHA2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 10,
        "tuDien2": "VHA2",
        "diaChiLapD": "",
        "longitude": 105.79897374677074,
        "latitude": 21.026625508534796,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7819,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.79875917005258,
          21.026244959194788
        ]
      },
      "properties": {
        "tenDoiTuon": "16",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "VHA2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 10,
        "tuDien2": "VHA2",
        "diaChiLapD": "",
        "longitude": 105.79875917005258,
        "latitude": 21.026244959194788,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7820,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.79886377619944,
          21.02643773760036
        ]
      },
      "properties": {
        "tenDoiTuon": "15",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "VHA2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 10,
        "tuDien2": "VHA2",
        "diaChiLapD": "",
        "longitude": 105.79886377619944,
        "latitude": 21.02643773760036,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7821,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.79909444617304,
          21.02682079005632
        ]
      },
      "properties": {
        "tenDoiTuon": "13",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "VHA2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 10,
        "tuDien2": "VHA2",
        "diaChiLapD": "",
        "longitude": 105.79909444617304,
        "latitude": 21.02682079005632,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7822,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.79920978116886,
          21.026993538880305
        ]
      },
      "properties": {
        "tenDoiTuon": "VH2 12",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Láng",
        "tuDien": "VHA2",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Tận dụng",
        "chieuCao": 10,
        "tuDien2": "VHA2",
        "diaChiLapD": "",
        "longitude": 105.79920978116886,
        "latitude": 21.026993538880305,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7823,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.79872698354171,
          21.025962050173252
        ]
      },
      "properties": {
        "tenDoiTuon": "VHA 18",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 1150 Đường Láng",
        "tuDien": "VHA2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VHA2",
        "diaChiLapD": "",
        "longitude": 105.79872698354171,
        "latitude": 21.025962050173252,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7824,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.79903007317824,
          21.025860027539615
        ]
      },
      "properties": {
        "tenDoiTuon": "19",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 1150 Đường Láng",
        "tuDien": "VHA2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VHA2",
        "diaChiLapD": "",
        "longitude": 105.79903007317824,
        "latitude": 21.025860027539615,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7825,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.79924464987846,
          21.025789300152983
        ]
      },
      "properties": {
        "tenDoiTuon": "20",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 1150 Đường Láng",
        "tuDien": "VHA2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VHA2",
        "diaChiLapD": "",
        "longitude": 105.79924464987846,
        "latitude": 21.025789300152983,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7826,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.79963491131154,
          21.025595895000315
        ]
      },
      "properties": {
        "tenDoiTuon": "28",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 1150 Đường Láng",
        "tuDien": "VHA2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VHA2",
        "diaChiLapD": "",
        "longitude": 105.79963491131154,
        "latitude": 21.025595895000315,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7827,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.79989106225868,
          21.025491368489025
        ]
      },
      "properties": {
        "tenDoiTuon": "29",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 1150 Đường Láng",
        "tuDien": "VHA2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VHA2",
        "diaChiLapD": "",
        "longitude": 105.79989106225868,
        "latitude": 21.025491368489025,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7828,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80003053712416,
          21.025461324926788
        ]
      },
      "properties": {
        "tenDoiTuon": "VHA 30",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 1150 Đường Láng",
        "tuDien": "VHA2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VHA2",
        "diaChiLapD": "",
        "longitude": 105.80003053712416,
        "latitude": 21.025461324926788,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7829,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.79986021686966,
          21.02514524124696
        ]
      },
      "properties": {
        "tenDoiTuon": "31",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 1150 Đường Láng",
        "tuDien": "VHA2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VHA2",
        "diaChiLapD": "",
        "longitude": 105.79986021686966,
        "latitude": 21.02514524124696,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7830,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80022365617141,
          21.02505823988035
        ]
      },
      "properties": {
        "tenDoiTuon": "VHA 33",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 1150 Đường Láng",
        "tuDien": "VHA2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VHA2",
        "diaChiLapD": "",
        "longitude": 105.80022365617141,
        "latitude": 21.02505823988035,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7831,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.79992324876058,
          21.02506074351805
        ]
      },
      "properties": {
        "tenDoiTuon": "32",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 1150 Đường Láng",
        "tuDien": "VHA2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VHA2",
        "diaChiLapD": "",
        "longitude": 105.79992324876058,
        "latitude": 21.02506074351805,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7832,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80000103282103,
          21.024910525212352
        ]
      },
      "properties": {
        "tenDoiTuon": "34",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 1150 Đường Láng",
        "tuDien": "VHA2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VHA2",
        "diaChiLapD": "",
        "longitude": 105.80000103282103,
        "latitude": 21.024910525212352,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7833,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.79971805977848,
          21.024975619829377
        ]
      },
      "properties": {
        "tenDoiTuon": "35",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 1150 Đường Láng",
        "tuDien": "VHA2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VHA2",
        "diaChiLapD": "",
        "longitude": 105.79971805977848,
        "latitude": 21.024975619829377,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7834,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.79999566840554,
          21.024747788543745
        ]
      },
      "properties": {
        "tenDoiTuon": "36",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 1150 Đường Láng",
        "tuDien": "VHA2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VHA2",
        "diaChiLapD": "",
        "longitude": 105.79999566840554,
        "latitude": 21.024747788543745,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7835,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.79993934201603,
          21.02459256263257
        ]
      },
      "properties": {
        "tenDoiTuon": "VHA27",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 1150 Đường Láng",
        "tuDien": "VHA2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VHA2",
        "diaChiLapD": "",
        "longitude": 105.79993934201603,
        "latitude": 21.02459256263257,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7836,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.7991802768747,
          21.02541625956996
        ]
      },
      "properties": {
        "tenDoiTuon": "21",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 1150 Đường Láng",
        "tuDien": "VHA2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VHA2",
        "diaChiLapD": "",
        "longitude": 105.7991802768747,
        "latitude": 21.02541625956996,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7837,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.79907567071886,
          21.025033203505483
        ]
      },
      "properties": {
        "tenDoiTuon": "22",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 1150 Đường Láng",
        "tuDien": "VHA2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VHA2",
        "diaChiLapD": "",
        "longitude": 105.79907567071886,
        "latitude": 21.025033203505483,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7838,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.7992419676797,
          21.024983130743937
        ]
      },
      "properties": {
        "tenDoiTuon": "23",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 1150 Đường Láng",
        "tuDien": "VHA2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VHA2",
        "diaChiLapD": "",
        "longitude": 105.7992419676797,
        "latitude": 21.024983130743937,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7839,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.79958529043238,
          21.024875474250788
        ]
      },
      "properties": {
        "tenDoiTuon": "VHA 24",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 1150 Đường Láng",
        "tuDien": "VHA2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VHA2",
        "diaChiLapD": "",
        "longitude": 105.79958529043238,
        "latitude": 21.024875474250788,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7840,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.79917759465795,
          21.024700219328366
        ]
      },
      "properties": {
        "tenDoiTuon": "23-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 1150 Đường Láng",
        "tuDien": "VHA2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VHA2",
        "diaChiLapD": "",
        "longitude": 105.79917759465795,
        "latitude": 21.024700219328366,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7841,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.79936803150646,
          21.024667671963265
        ]
      },
      "properties": {
        "tenDoiTuon": "25-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 1150 Đường Láng",
        "tuDien": "VHA2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VHA2",
        "diaChiLapD": "",
        "longitude": 105.79936803150646,
        "latitude": 21.024667671963265,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7842,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.79954237509052,
          21.024524964199937
        ]
      },
      "properties": {
        "tenDoiTuon": "25",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 1150 Đường Láng",
        "tuDien": "VHA2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VHA2",
        "diaChiLapD": "",
        "longitude": 105.79954237509052,
        "latitude": 21.024524964199937,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7843,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.79964429902965,
          21.024404789135577
        ]
      },
      "properties": {
        "tenDoiTuon": "26",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 1150 Đường Láng",
        "tuDien": "VHA2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VHA2",
        "diaChiLapD": "",
        "longitude": 105.79964429902965,
        "latitude": 21.024404789135577,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7844,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.79932779836336,
          21.02431465777367
        ]
      },
      "properties": {
        "tenDoiTuon": "26-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 1150 Đường Láng",
        "tuDien": "VHA2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VHA2",
        "diaChiLapD": "",
        "longitude": 105.79932779836336,
        "latitude": 21.02431465777367,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7845,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.79972476529784,
          21.024207000797755
        ]
      },
      "properties": {
        "tenDoiTuon": "27",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 1150 Đường Láng",
        "tuDien": "VHA2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VHA2",
        "diaChiLapD": "",
        "longitude": 105.79972476529784,
        "latitude": 21.024207000797755,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7846,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.79936061450056,
          21.025704518697896
        ]
      },
      "properties": {
        "tenDoiTuon": "20-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 1150 Đường Láng",
        "tuDien": "VHA2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VHA2",
        "diaChiLapD": "",
        "longitude": 105.79936061450056,
        "latitude": 21.025704518697896,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7847,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83400297826537,
          21.02455741487544
        ]
      },
      "properties": {
        "tenDoiTuon": "8-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 35 Văn Hương",
        "tuDien": "VHG5",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "Văn Hương 5",
        "diaChiLapD": "",
        "longitude": 105.83400297826537,
        "latitude": 21.02455741487544,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7848,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83395880477622,
          21.02465896735549
        ]
      },
      "properties": {
        "tenDoiTuon": "8-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 35 Văn Hương",
        "tuDien": "VHG5",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Văn Hương 5",
        "diaChiLapD": "",
        "longitude": 105.83395880477622,
        "latitude": 21.02465896735549,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7849,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83385255867744,
          21.024675978761255
        ]
      },
      "properties": {
        "tenDoiTuon": "8-8",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 35 Văn Hương",
        "tuDien": "VHG5",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "Văn Hương 5",
        "diaChiLapD": "",
        "longitude": 105.83385255867744,
        "latitude": 21.024675978761255,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7850,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83400574342967,
          21.02475410579405
        ]
      },
      "properties": {
        "tenDoiTuon": "8-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 35 Văn Hương",
        "tuDien": "VHG5",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Văn Hương 5",
        "diaChiLapD": "",
        "longitude": 105.83400574342967,
        "latitude": 21.02475410579405,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7851,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83413717167191,
          21.02465896735549
        ]
      },
      "properties": {
        "tenDoiTuon": "8-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 35 Văn Hương",
        "tuDien": "VHG5",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "Văn Hương 5",
        "diaChiLapD": "",
        "longitude": 105.83413717167191,
        "latitude": 21.02465896735549,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7852,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83441075698737,
          21.024498734059403
        ]
      },
      "properties": {
        "tenDoiTuon": "8-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 35 Văn Hương",
        "tuDien": "VHG5",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "Văn Hương 5",
        "diaChiLapD": "",
        "longitude": 105.83441075698737,
        "latitude": 21.024498734059403,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7853,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83407413976302,
          21.024600131712138
        ]
      },
      "properties": {
        "tenDoiTuon": "8-7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 35 Văn Hương",
        "tuDien": "VHG5",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "Văn Hương 5",
        "diaChiLapD": "",
        "longitude": 105.83407413976302,
        "latitude": 21.024600131712138,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7854,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83422032014786,
          21.02453253328463
        ]
      },
      "properties": {
        "tenDoiTuon": "8-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 35 Văn Hương",
        "tuDien": "VHG5",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "Văn Hương 5",
        "diaChiLapD": "",
        "longitude": 105.83422032014786,
        "latitude": 21.02453253328463,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7855,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.79993543985023,
          21.027760378742766
        ]
      },
      "properties": {
        "tenDoiTuon": "VH1 19",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Nghách 1194/63 Đường Láng",
        "tuDien": "VHA1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VH1",
        "diaChiLapD": "",
        "longitude": 105.79993543985023,
        "latitude": 21.027760378742766,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7856,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.79982282739753,
          21.027805861121326
        ]
      },
      "properties": {
        "tenDoiTuon": "VH1 20",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Nghách 1194/63 Đường Láng",
        "tuDien": "VHA1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VH1",
        "diaChiLapD": "",
        "longitude": 105.79982282739753,
        "latitude": 21.027805861121326,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7857,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83364230411894,
          21.024615408390492
        ]
      },
      "properties": {
        "tenDoiTuon": "9-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 23 Văn Hương",
        "tuDien": "VHG5",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "Văn Hương 5",
        "diaChiLapD": "",
        "longitude": 105.83364230411894,
        "latitude": 21.024615408390492,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7858,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83383274095846,
          21.02442137580395
        ]
      },
      "properties": {
        "tenDoiTuon": "9-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 30 Văn Hương",
        "tuDien": "VHG5",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "Văn Hương 5",
        "diaChiLapD": "",
        "longitude": 105.83383274095846,
        "latitude": 21.02442137580395,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7859,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83380055445656,
          21.024348769997093
        ]
      },
      "properties": {
        "tenDoiTuon": "9-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 30 Văn Hương",
        "tuDien": "VHG5",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "Văn Hương 5",
        "diaChiLapD": "",
        "longitude": 105.83380055445656,
        "latitude": 21.024348769997093,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7860,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83543066700679,
          21.0057836820372
        ]
      },
      "properties": {
        "tenDoiTuon": "KLC3 01",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Lương Định Của",
        "tuDien": "KLC3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Kim Liên C3",
        "diaChiLapD": "",
        "longitude": 105.83543066700679,
        "latitude": 21.0057836820372,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7861,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83553527315362,
          21.005430623148253
        ]
      },
      "properties": {
        "tenDoiTuon": "KLC3 03",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Lương Định Của",
        "tuDien": "KLC3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Kim Liên C3",
        "diaChiLapD": "",
        "longitude": 105.83553527315362,
        "latitude": 21.005430623148253,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7862,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83566401918812,
          21.005125138895792
        ]
      },
      "properties": {
        "tenDoiTuon": "KLC3 04",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Lương Định Của",
        "tuDien": "KLC3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Kim Liên C3",
        "diaChiLapD": "",
        "longitude": 105.83566401918812,
        "latitude": 21.005125138895792,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7863,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83573107440863,
          21.004807134131806
        ]
      },
      "properties": {
        "tenDoiTuon": "KLC3 05",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Lương Định Của",
        "tuDien": "KLC3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Kim Liên C3",
        "diaChiLapD": "",
        "longitude": 105.83573107440863,
        "latitude": 21.004807134131806,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7864,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83580081184584,
          21.004481616743437
        ]
      },
      "properties": {
        "tenDoiTuon": "KLC3 06",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Lương Định Của",
        "tuDien": "KLC3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Kim Liên C3",
        "diaChiLapD": "",
        "longitude": 105.83580081184584,
        "latitude": 21.004481616743437,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7865,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83591614683264,
          21.004063451056698
        ]
      },
      "properties": {
        "tenDoiTuon": "KLC3 07",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Lương Định Của",
        "tuDien": "KLC3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Kim Liên C3",
        "diaChiLapD": "",
        "longitude": 105.83591614683264,
        "latitude": 21.004063451056698,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7866,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83605293949037,
          21.003562652283946
        ]
      },
      "properties": {
        "tenDoiTuon": "KLC3 08",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Lương Định Của",
        "tuDien": "KLC3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Kim Liên C3",
        "diaChiLapD": "",
        "longitude": 105.83605293949037,
        "latitude": 21.003562652283946,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7867,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83618168552489,
          21.003517580312423
        ]
      },
      "properties": {
        "tenDoiTuon": "KLC3 09",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Phương Mai",
        "tuDien": "KLC3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Kim Liên C3",
        "diaChiLapD": "",
        "longitude": 105.83618168552489,
        "latitude": 21.003517580312423,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7868,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.837179467281,
          21.003757964005437
        ]
      },
      "properties": {
        "tenDoiTuon": "KLC3 12",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Phương Mai",
        "tuDien": "KLC3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Kim Liên C3",
        "diaChiLapD": "",
        "longitude": 105.837179467281,
        "latitude": 21.003757964005437,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7869,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83681468684843,
          21.003702876110133
        ]
      },
      "properties": {
        "tenDoiTuon": "KLC3 11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Phương Mai",
        "tuDien": "KLC3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Kim Liên C3",
        "diaChiLapD": "",
        "longitude": 105.83681468684843,
        "latitude": 21.003702876110133,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7870,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83651025611701,
          21.00361523623253
        ]
      },
      "properties": {
        "tenDoiTuon": "KLC3 10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Phương Mai",
        "tuDien": "KLC3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Kim Liên C3",
        "diaChiLapD": "",
        "longitude": 105.83651025611701,
        "latitude": 21.00361523623253,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7871,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.79970733095651,
          21.026521608644988
        ]
      },
      "properties": {
        "tenDoiTuon": "VHA2 14-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Chùa Nền",
        "tuDien": "VHA2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VHA2",
        "diaChiLapD": "",
        "longitude": 105.79970733095651,
        "latitude": 21.026521608644988,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7872,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.7998320536704,
          21.026487809870787
        ]
      },
      "properties": {
        "tenDoiTuon": "14-9",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Chùa Nền",
        "tuDien": "VHA2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VHA2",
        "diaChiLapD": "",
        "longitude": 105.7998320536704,
        "latitude": 21.026487809870787,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7873,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.7998763101251,
          21.02622743387106
        ]
      },
      "properties": {
        "tenDoiTuon": "VHA1 1-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Chùa Nền",
        "tuDien": "VHA1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VH1",
        "diaChiLapD": "",
        "longitude": 105.7998763101251,
        "latitude": 21.02622743387106,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7874,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.79998091627195,
          21.026449003861124
        ]
      },
      "properties": {
        "tenDoiTuon": "VH2 14-10",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Chùa Nền",
        "tuDien": "VHA2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 6,
        "tuDien2": "VHA2",
        "diaChiLapD": "",
        "longitude": 105.79998091627195,
        "latitude": 21.026449003861124,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7875,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80001176167896,
          21.026570429083634
        ]
      },
      "properties": {
        "tenDoiTuon": "14-11",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Chùa Nền",
        "tuDien": "VHA2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 6,
        "tuDien2": "VHA2",
        "diaChiLapD": "",
        "longitude": 105.80001176167896,
        "latitude": 21.026570429083634,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7876,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8001297788735,
          21.02661424228403
        ]
      },
      "properties": {
        "tenDoiTuon": "VH2 14-12",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Chùa Nền",
        "tuDien": "VHA2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 6,
        "tuDien2": "VHA2",
        "diaChiLapD": "",
        "longitude": 105.8001297788735,
        "latitude": 21.02661424228403,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7877,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80006138254014,
          21.02630880141992
        ]
      },
      "properties": {
        "tenDoiTuon": "VHA 1-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Chùa Nền",
        "tuDien": "VHA1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 6,
        "tuDien2": "VH1",
        "diaChiLapD": "",
        "longitude": 105.80006138254014,
        "latitude": 21.02630880141992,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7878,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8001472132328,
          21.02651910503271
        ]
      },
      "properties": {
        "tenDoiTuon": "VHA1 1-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Chùa Nền",
        "tuDien": "VHA1",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 6,
        "tuDien2": "VH1",
        "diaChiLapD": "",
        "longitude": 105.8001472132328,
        "latitude": 21.02651910503271,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7879,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.79977706838474,
          21.026690602402063
        ]
      },
      "properties": {
        "tenDoiTuon": "14-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Chùa Nền",
        "tuDien": "VHA2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VHA2",
        "diaChiLapD": "",
        "longitude": 105.79977706838474,
        "latitude": 21.026690602402063,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7880,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.79979986716853,
          21.026898401869435
        ]
      },
      "properties": {
        "tenDoiTuon": "14-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Chùa Nền",
        "tuDien": "VHA2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VHA2",
        "diaChiLapD": "",
        "longitude": 105.79979986716853,
        "latitude": 21.026898401869435,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7881,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.79987496902122,
          21.02713123707378
        ]
      },
      "properties": {
        "tenDoiTuon": "VHA2 14-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Chùa Nền",
        "tuDien": "VHA2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VHA2",
        "diaChiLapD": "",
        "longitude": 105.79987496902122,
        "latitude": 21.02713123707378,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7882,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.79989776779603,
          21.02678448773957
        ]
      },
      "properties": {
        "tenDoiTuon": "14-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Chùa Nền",
        "tuDien": "VHA2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VHA2",
        "diaChiLapD": "",
        "longitude": 105.79989776779603,
        "latitude": 21.02678448773957,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7883,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80001176167896,
          21.027082416819685
        ]
      },
      "properties": {
        "tenDoiTuon": "14-7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Chùa Nền",
        "tuDien": "VHA2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VHA2",
        "diaChiLapD": "",
        "longitude": 105.80001176167896,
        "latitude": 21.027082416819685,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7884,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.80015794206378,
          21.027032344746516
        ]
      },
      "properties": {
        "tenDoiTuon": "VHA2 14-8",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 187,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Chùa Nền",
        "tuDien": "VHA2",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "VHA2",
        "diaChiLapD": "",
        "longitude": 105.80015794206378,
        "latitude": 21.027032344746516,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7885,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8355755062967,
          21.005691035458593
        ]
      },
      "properties": {
        "tenDoiTuon": "KLC3 02",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Đường phố - chóa",
        "tenDuongPh": "Lương Định Của",
        "tuDien": "KLC3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Kim Liên C3",
        "diaChiLapD": "",
        "longitude": 105.8355755062967,
        "latitude": 21.005691035458593,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7886,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83359402436162,
          21.02435252547061
        ]
      },
      "properties": {
        "tenDoiTuon": "13",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 28 Văn Hương",
        "tuDien": "VHG5",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "Văn Hương 5",
        "diaChiLapD": "",
        "longitude": 105.83359402436162,
        "latitude": 21.02435252547061,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7887,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83358329552166,
          21.024214824708565
        ]
      },
      "properties": {
        "tenDoiTuon": "14",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 28 Văn Hương",
        "tuDien": "VHG5",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Văn Hương 5",
        "diaChiLapD": "",
        "longitude": 105.83358329552166,
        "latitude": 21.024214824708565,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7888,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83365973847825,
          21.02415223341144
        ]
      },
      "properties": {
        "tenDoiTuon": "14-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 28 Văn Hương",
        "tuDien": "VHG5",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Văn Hương 5",
        "diaChiLapD": "",
        "longitude": 105.83365973847825,
        "latitude": 21.02415223341144,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7889,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83386224526157,
          21.02404207266344
        ]
      },
      "properties": {
        "tenDoiTuon": "14-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 28 Văn Hương",
        "tuDien": "VHG5",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Văn Hương 5",
        "diaChiLapD": "",
        "longitude": 105.83386224526157,
        "latitude": 21.02404207266344,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7890,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83404061215727,
          21.023930660006567
        ]
      },
      "properties": {
        "tenDoiTuon": "14-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 28 Văn Hương",
        "tuDien": "VHG5",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Văn Hương 5",
        "diaChiLapD": "",
        "longitude": 105.83404061215727,
        "latitude": 21.023930660006567,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7891,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83358865994613,
          21.024020791600634
        ]
      },
      "properties": {
        "tenDoiTuon": "14-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 28 Văn Hương",
        "tuDien": "VHG5",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Văn Hương 5",
        "diaChiLapD": "",
        "longitude": 105.83358865994613,
        "latitude": 21.024020791600634,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7892,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83380055445656,
          21.023936919147182
        ]
      },
      "properties": {
        "tenDoiTuon": "14-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 28 Văn Hương",
        "tuDien": "VHG5",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "Văn Hương 5",
        "diaChiLapD": "",
        "longitude": 105.83380055445656,
        "latitude": 21.023936919147182,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7893,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83398696798447,
          21.023831765555947
        ]
      },
      "properties": {
        "tenDoiTuon": "14-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 28 Văn Hương",
        "tuDien": "VHG5",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "Văn Hương 5",
        "diaChiLapD": "",
        "longitude": 105.83398696798447,
        "latitude": 21.023831765555947,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7894,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83349478263024,
          21.02398198494871
        ]
      },
      "properties": {
        "tenDoiTuon": "15",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 28 Văn Hương",
        "tuDien": "VHG5",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Văn Hương 5",
        "diaChiLapD": "",
        "longitude": 105.83349478263024,
        "latitude": 21.02398198494871,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7895,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83343040960852,
          21.023995755052304
        ]
      },
      "properties": {
        "tenDoiTuon": "15-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 28 Văn Hương",
        "tuDien": "VHG5",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "Văn Hương 5",
        "diaChiLapD": "",
        "longitude": 105.83343040960852,
        "latitude": 21.023995755052304,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7896,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83345052617555,
          21.02390311978692
        ]
      },
      "properties": {
        "tenDoiTuon": "15-8",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 28 Văn Hương",
        "tuDien": "VHG5",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "Văn Hương 5",
        "diaChiLapD": "",
        "longitude": 105.83345052617555,
        "latitude": 21.02390311978692,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7897,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83364632743954,
          21.023834269214195
        ]
      },
      "properties": {
        "tenDoiTuon": "15-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 28 Văn Hương",
        "tuDien": "VHG5",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Văn Hương 5",
        "diaChiLapD": "",
        "longitude": 105.83364632743954,
        "latitude": 21.023834269214195,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7898,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83373618143482,
          21.023914386240897
        ]
      },
      "properties": {
        "tenDoiTuon": "15-7",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 28 Văn Hương",
        "tuDien": "VHG5",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "Văn Hương 5",
        "diaChiLapD": "",
        "longitude": 105.83373618143482,
        "latitude": 21.023914386240897,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7899,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83585445603663,
          21.006069133294215
        ]
      },
      "properties": {
        "tenDoiTuon": "KLC3 1-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 11 Lương Đình Của",
        "tuDien": "KLC3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Kim Liên C3",
        "diaChiLapD": "",
        "longitude": 105.83585445603663,
        "latitude": 21.006069133294215,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7900,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83609853705792,
          21.005868816679765
        ]
      },
      "properties": {
        "tenDoiTuon": "KLC3 1-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 11 Lương Đình Của",
        "tuDien": "KLC3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Kim Liên C3",
        "diaChiLapD": "",
        "longitude": 105.83609853705792,
        "latitude": 21.005868816679765,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7901,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83632652482379,
          21.00584628104356
        ]
      },
      "properties": {
        "tenDoiTuon": "KLC3 1-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ 11 Lương Đình Của",
        "tuDien": "KLC3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Kim Liên C3",
        "diaChiLapD": "",
        "longitude": 105.83632652482379,
        "latitude": 21.00584628104356,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7902,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83644722419015,
          21.00520401398668
        ]
      },
      "properties": {
        "tenDoiTuon": "KLC3 4-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Nhà C3 Kim Liên",
        "tuDien": "KLC3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Kim Liên C3",
        "diaChiLapD": "",
        "longitude": 105.83644722419015,
        "latitude": 21.00520401398668,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7903,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83586518486759,
          21.005813729563698
        ]
      },
      "properties": {
        "tenDoiTuon": "KLC3 1-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ 11 Lương Đình Của",
        "tuDien": "KLC3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép bát giác",
        "chieuCao": 11,
        "tuDien2": "Kim Liên C3",
        "diaChiLapD": "",
        "longitude": 105.83586518486759,
        "latitude": 21.005813729563698,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7904,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83389443177245,
          21.023845535673416
        ]
      },
      "properties": {
        "tenDoiTuon": "15-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 28 Văn Hương",
        "tuDien": "VHG5",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "Văn Hương 5",
        "diaChiLapD": "",
        "longitude": 105.83389443177245,
        "latitude": 21.023845535673416,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7905,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83406341094103,
          21.023785447879476
        ]
      },
      "properties": {
        "tenDoiTuon": "15-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 28 Văn Hương",
        "tuDien": "VHG5",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "Văn Hương 5",
        "diaChiLapD": "",
        "longitude": 105.83406341094103,
        "latitude": 21.023785447879476,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7906,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83403792994953,
          21.02368905699325
        ]
      },
      "properties": {
        "tenDoiTuon": "15-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 28 Văn Hương",
        "tuDien": "VHG5",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "Văn Hương 5",
        "diaChiLapD": "",
        "longitude": 105.83403792994953,
        "latitude": 21.02368905699325,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7907,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83386358636547,
          21.023707834443055
        ]
      },
      "properties": {
        "tenDoiTuon": "15-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 28 Văn Hương",
        "tuDien": "VHG5",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "Văn Hương 5",
        "diaChiLapD": "",
        "longitude": 105.83386358636547,
        "latitude": 21.023707834443055,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7908,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83339151757829,
          21.023794210684297
        ]
      },
      "properties": {
        "tenDoiTuon": "VH5 16",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 28 Văn Hương",
        "tuDien": "VHG5",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Văn Hương 5",
        "diaChiLapD": "",
        "longitude": 105.83339151757829,
        "latitude": 21.023794210684297,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7909,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83361011761704,
          21.02370407895327
        ]
      },
      "properties": {
        "tenDoiTuon": "16-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 28 Văn Hương",
        "tuDien": "VHG5",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Văn Hương 5",
        "diaChiLapD": "",
        "longitude": 105.83361011761704,
        "latitude": 21.02370407895327,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7910,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83383810538292,
          21.02357514040993
        ]
      },
      "properties": {
        "tenDoiTuon": "16-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 28 Văn Hương",
        "tuDien": "VHG5",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Văn Hương 5",
        "diaChiLapD": "",
        "longitude": 105.83383810538292,
        "latitude": 21.02357514040993,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7911,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83403927105341,
          21.02352381532774
        ]
      },
      "properties": {
        "tenDoiTuon": "16-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 28 Văn Hương",
        "tuDien": "VHG5",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Văn Hương 5",
        "diaChiLapD": "",
        "longitude": 105.83403927105341,
        "latitude": 21.02352381532774,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7912,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83398160355999,
          21.023397380293275
        ]
      },
      "properties": {
        "tenDoiTuon": "16-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 28 Văn Hương",
        "tuDien": "VHG5",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Văn Hương 5",
        "diaChiLapD": "",
        "longitude": 105.83398160355999,
        "latitude": 21.023397380293275,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7913,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83418679255107,
          21.02344995725138
        ]
      },
      "properties": {
        "tenDoiTuon": "16-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 28 Văn Hương",
        "tuDien": "VHG5",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "Văn Hương 5",
        "diaChiLapD": "",
        "longitude": 105.83418679255107,
        "latitude": 21.02344995725138,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7914,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.833785802305,
          21.02347374206003
        ]
      },
      "properties": {
        "tenDoiTuon": "16-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 28 Văn Hương",
        "tuDien": "VHG5",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Văn Hương 5",
        "diaChiLapD": "",
        "longitude": 105.833785802305,
        "latitude": 21.02347374206003,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7915,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83345723170389,
          21.023611443506613
        ]
      },
      "properties": {
        "tenDoiTuon": "17-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 28 Văn Hương",
        "tuDien": "VHG5",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "Văn Hương 5",
        "diaChiLapD": "",
        "longitude": 105.83345723170389,
        "latitude": 21.023611443506613,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7916,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83334726113257,
          21.023656509406525
        ]
      },
      "properties": {
        "tenDoiTuon": "17",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 28 Văn Hương",
        "tuDien": "VHG5",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Văn Hương 5",
        "diaChiLapD": "",
        "longitude": 105.83334726113257,
        "latitude": 21.023656509406525,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7917,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83338212985119,
          21.023492519536973
        ]
      },
      "properties": {
        "tenDoiTuon": "18",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 28 Văn Hương",
        "tuDien": "VHG5",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Văn Hương 5",
        "diaChiLapD": "",
        "longitude": 105.83338212985119,
        "latitude": 21.023492519536973,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7918,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83326411264764,
          21.023376099138403
        ]
      },
      "properties": {
        "tenDoiTuon": "19",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 28 Văn Hương",
        "tuDien": "VHG5",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "Văn Hương 5",
        "diaChiLapD": "",
        "longitude": 105.83326411264764,
        "latitude": 21.023376099138403,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7919,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83591078239023,
          21.005016216079664
        ]
      },
      "properties": {
        "tenDoiTuon": "KLC3 4-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Nhà C3 Kim Liên",
        "tuDien": "KLC3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Kim Liên C3",
        "diaChiLapD": "",
        "longitude": 105.83591078239023,
        "latitude": 21.005016216079664,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7920,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83649282177566,
          21.004927324988067
        ]
      },
      "properties": {
        "tenDoiTuon": "KLC3 5-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Nhà C3 Kim Liên",
        "tuDien": "KLC3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Kim Liên C3",
        "diaChiLapD": "",
        "longitude": 105.83649282177566,
        "latitude": 21.004927324988067,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7921,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83596174439121,
          21.00470947899002
        ]
      },
      "properties": {
        "tenDoiTuon": "KLC3 5-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Nhà C3 Kim Liên",
        "tuDien": "KLC3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Kim Liên C3",
        "diaChiLapD": "",
        "longitude": 105.83596174439121,
        "latitude": 21.00470947899002,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7922,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83624337633889,
          21.004772078447026
        ]
      },
      "properties": {
        "tenDoiTuon": "KLC3 5-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Nhà C3 Kim Liên",
        "tuDien": "KLC3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Kim Liên C3",
        "diaChiLapD": "",
        "longitude": 105.83624337633889,
        "latitude": 21.004772078447026,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7923,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83652500828653,
          21.004752046623803
        ]
      },
      "properties": {
        "tenDoiTuon": "KLC3 5-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Nhà C3 Kim Liên",
        "tuDien": "KLC3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Kim Liên C3",
        "diaChiLapD": "",
        "longitude": 105.83652500828653,
        "latitude": 21.004752046623803,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7924,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83617363887471,
          21.005066295544417
        ]
      },
      "properties": {
        "tenDoiTuon": "KLC3 4-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Nhà C3 Kim Liên",
        "tuDien": "KLC3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Kim Liên C3",
        "diaChiLapD": "",
        "longitude": 105.83617363887471,
        "latitude": 21.005066295544417,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7925,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83671008071056,
          21.004787102313298
        ]
      },
      "properties": {
        "tenDoiTuon": "KLC3 5-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 229,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Nhà C3 Kim Liên",
        "tuDien": "KLC3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Kim Liên C3",
        "diaChiLapD": "",
        "longitude": 105.83671008071056,
        "latitude": 21.004787102313298,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7926,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83350987009595,
          21.02340676903517
        ]
      },
      "properties": {
        "tenDoiTuon": "18-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Sân Chơi Đồ Lợi",
        "tuDien": "VHG5",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 7,
        "tuDien2": "Văn Hương 5",
        "diaChiLapD": "",
        "longitude": 105.83350987009595,
        "latitude": 21.02340676903517,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7927,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83367314952595,
          21.023378602804335
        ]
      },
      "properties": {
        "tenDoiTuon": "18-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Sân Chơi Đồ Lợi",
        "tuDien": "VHG5",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 7,
        "tuDien2": "Văn Hương 5",
        "diaChiLapD": "",
        "longitude": 105.83367314952595,
        "latitude": 21.023378602804335,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7928,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83397087472903,
          21.023269693318547
        ]
      },
      "properties": {
        "tenDoiTuon": "18-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 193,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Sân Chơi Đồ Lợi",
        "tuDien": "VHG5",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 7,
        "tuDien2": "Văn Hương 5",
        "diaChiLapD": "",
        "longitude": 105.83397087472903,
        "latitude": 21.023269693318547,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7929,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83649148065382,
          21.003677836149393
        ]
      },
      "properties": {
        "tenDoiTuon": "KLC3 10-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Nhà 46 Phương Mai",
        "tuDien": "KLC3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Kim Liên C3",
        "diaChiLapD": "",
        "longitude": 105.83649148065382,
        "latitude": 21.003677836149393,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7930,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83636273461931,
          21.00406595504431
        ]
      },
      "properties": {
        "tenDoiTuon": "KLC3 10-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Nhà 46 Phương Mai",
        "tuDien": "KLC3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Kim Liên C3",
        "diaChiLapD": "",
        "longitude": 105.83636273461931,
        "latitude": 21.00406595504431,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7931,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83641637880112,
          21.003788011938155
        ]
      },
      "properties": {
        "tenDoiTuon": "KLC3 10-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Nhà 46 Phương Mai",
        "tuDien": "KLC3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Kim Liên C3",
        "diaChiLapD": "",
        "longitude": 105.83641637880112,
        "latitude": 21.003788011938155,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7932,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83344784396782,
          21.024725568713976
        ]
      },
      "properties": {
        "tenDoiTuon": "10-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 11 Văn Hương",
        "tuDien": "VHG5",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "Văn Hương 5",
        "diaChiLapD": "",
        "longitude": 105.83344784396782,
        "latitude": 21.024725568713976,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7933,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83329629915852,
          21.024455175046736
        ]
      },
      "properties": {
        "tenDoiTuon": "10-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 12 Văn Hương",
        "tuDien": "VHG5",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Cột khác",
        "chieuCao": 7,
        "tuDien2": "Văn Hương 5",
        "diaChiLapD": "",
        "longitude": 105.83329629915852,
        "latitude": 21.024455175046736,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7934,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83334994334031,
          21.024800677979364
        ]
      },
      "properties": {
        "tenDoiTuon": "10-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 196,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngách 7A Văn Hương",
        "tuDien": "VHG5",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Thép tròn côn",
        "chieuCao": 7,
        "tuDien2": "Văn Hương 5",
        "diaChiLapD": "",
        "longitude": 105.83334994334031,
        "latitude": 21.024800677979364,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7935,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83730150777821,
          21.00344496432742
        ]
      },
      "properties": {
        "tenDoiTuon": "KLC3 12-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Nhà 71 Phương Mai",
        "tuDien": "KLC3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Kim Liên C3",
        "diaChiLapD": "",
        "longitude": 105.83730150777821,
        "latitude": 21.00344496432742,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7936,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83734978753552,
          21.00312695598381
        ]
      },
      "properties": {
        "tenDoiTuon": "KLC3 12-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Nhà 71 Phương Mai",
        "tuDien": "KLC3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Kim Liên C3",
        "diaChiLapD": "",
        "longitude": 105.83734978753552,
        "latitude": 21.00312695598381,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7937,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8370922954755,
          21.003372348308755
        ]
      },
      "properties": {
        "tenDoiTuon": "KLC3 12-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Nhà 71 Phương Mai",
        "tuDien": "KLC3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Kim Liên C3",
        "diaChiLapD": "",
        "longitude": 105.8370922954755,
        "latitude": 21.003372348308755,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7938,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.8363332303162,
          21.00315450003988
        ]
      },
      "properties": {
        "tenDoiTuon": "KLC3 12-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Nhà 71 Phương Mai",
        "tuDien": "KLC3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Kim Liên C3",
        "diaChiLapD": "",
        "longitude": 105.8363332303162,
        "latitude": 21.00315450003988,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7939,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83673019725066,
          21.003259668209516
        ]
      },
      "properties": {
        "tenDoiTuon": "KLC3 12-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 232,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Nhà 71 Phương Mai",
        "tuDien": "KLC3",
        "chuDauTu": "UBNDQuanDongDa",
        "loaiCot": "Tận dụng",
        "chieuCao": 7,
        "tuDien2": "Kim Liên C3",
        "diaChiLapD": "",
        "longitude": 105.83673019725066,
        "latitude": 21.003259668209516,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7940,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83370734768812,
          21.01697221128019
        ]
      },
      "properties": {
        "tenDoiTuon": "TQ 01",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Thổ Quan",
        "tuDien": "TQ1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TQ",
        "diaChiLapD": "",
        "longitude": 105.83370734768812,
        "latitude": 21.01697221128019,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7941,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83351959305635,
          21.01704106502164
        ]
      },
      "properties": {
        "tenDoiTuon": "TQ 1-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Thổ Quan",
        "tuDien": "TQ1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TQ",
        "diaChiLapD": "",
        "longitude": 105.83351959305635,
        "latitude": 21.01704106502164,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7942,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83332781512195,
          21.01682949614939
        ]
      },
      "properties": {
        "tenDoiTuon": "TQ 1-2",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 62 Thổ Quan",
        "tuDien": "TQ1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TQ",
        "diaChiLapD": "",
        "longitude": 105.83332781512195,
        "latitude": 21.01682949614939,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7943,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83322589118283,
          21.01674436776202
        ]
      },
      "properties": {
        "tenDoiTuon": "TQ 1-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 62 Thổ Quan",
        "tuDien": "TQ1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TQ",
        "diaChiLapD": "",
        "longitude": 105.83322589118283,
        "latitude": 21.01674436776202,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7944,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83306495863746,
          21.016874564099194
        ]
      },
      "properties": {
        "tenDoiTuon": "TQ 1-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 62 Thổ Quan",
        "tuDien": "TQ1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TQ",
        "diaChiLapD": "",
        "longitude": 105.83306495863746,
        "latitude": 21.016874564099194,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7945,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83294157702744,
          21.01681447349588
        ]
      },
      "properties": {
        "tenDoiTuon": "TQ 1-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 62 Thổ Quan",
        "tuDien": "TQ1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TQ",
        "diaChiLapD": "",
        "longitude": 105.83294157702744,
        "latitude": 21.01681447349588,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7946,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83280210216198,
          21.016944669772688
        ]
      },
      "properties": {
        "tenDoiTuon": "TQ 1-6",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngách 62 Thổ Quan",
        "tuDien": "TQ1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TQ",
        "diaChiLapD": "",
        "longitude": 105.83280210216198,
        "latitude": 21.016944669772688,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7947,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83330099303555,
          21.01664421665537
        ]
      },
      "properties": {
        "tenDoiTuon": "TQ 28-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Quan Trạm",
        "tuDien": "TQ1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TQ",
        "diaChiLapD": "",
        "longitude": 105.83330099303555,
        "latitude": 21.01664421665537,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7948,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83345119674097,
          21.016478967182405
        ]
      },
      "properties": {
        "tenDoiTuon": "TQ 28",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Quan Trạm",
        "tuDien": "TQ1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TQ",
        "diaChiLapD": "",
        "longitude": 105.83345119674097,
        "latitude": 21.016478967182405,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7949,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83324734885375,
          21.016248619127566
        ]
      },
      "properties": {
        "tenDoiTuon": "TQ 29",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chóa",
        "tenDuongPh": "Ngõ Quan Trạm",
        "tuDien": "TQ1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TQ",
        "diaChiLapD": "",
        "longitude": 105.83324734885375,
        "latitude": 21.016248619127566,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7950,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83333854396191,
          21.01614346011495
        ]
      },
      "properties": {
        "tenDoiTuon": "TQ 29-3",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Quan Trạm",
        "tuDien": "TQ1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TQ",
        "diaChiLapD": "",
        "longitude": 105.83333854396191,
        "latitude": 21.01614346011495,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7951,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83347265441189,
          21.016003247981665
        ]
      },
      "properties": {
        "tenDoiTuon": "TQ 29-4",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Quan Trạm",
        "tuDien": "TQ1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TQ",
        "diaChiLapD": "",
        "longitude": 105.83347265441189,
        "latitude": 21.016003247981665,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7952,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83360676486186,
          21.01608336921708
        ]
      },
      "properties": {
        "tenDoiTuon": "TQ 29-5",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Quan Trạm",
        "tuDien": "TQ1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TQ",
        "diaChiLapD": "",
        "longitude": 105.83360676486186,
        "latitude": 21.01608336921708,
        "color": "#EDD5DE"
      }
    },
    {
      "id": 7953,
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          105.83317761141653,
          21.01620355098862
        ]
      },
      "properties": {
        "tenDoiTuon": "TQ 29-1",
        "ngayLapDat": "",
        "doiQuanLy": "Đội Đống Đa",
        "maQuanHuye": 6,
        "maXaPhuong": 205,
        "loaiChaoCh": "Trong ngõ - chao",
        "tenDuongPh": "Ngõ Quan Trạm",
        "tuDien": "TQ1",
        "chuDauTu": "SoXayDung",
        "loaiCot": "Cột khác",
        "chieuCao": "",
        "tuDien2": "TQ",
        "diaChiLapD": "",
        "longitude": 105.83317761141653,
        "latitude": 21.01620355098862,
        "color": "#EDD5DE"
      }
    }
  ]
}